import { useState } from 'react';
import Box from '@mui/material/Box';
import { Avatar, IconButton, Menu, MenuItem, Pagination, PaginationItem, Stack, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { DataGridPremium, GridToolbar, gridClasses } from '@mui/x-data-grid-premium';
import '../../ClientModule/ClientContacts/ContactsTable.css';
import {
  GridColumns,
  gridPageCountSelector,
  gridPageSelector,
  GridRenderEditCellParams,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import { formatToUSD, getStatusColor } from '../../../utils/dataHelpers';
import { Link } from 'react-router-dom';
import StatusBadge from '../../shared/LabeledBadge/LabeledBadge';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const ODD_OPACITY = 0.2;

interface Props {
  data: any[];
  columns: GridColumns<any>;
  uniquRowId: string;
  hideHeader: boolean;
  hideFooter: boolean;
  pageSize?: number;
}

function stringToHslColor(str: string, s: number, l: number) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const h = hash % 360;
  return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
}

export const linkCell = (
  params: GridRenderEditCellParams<any, any, any>,
  func: (rowData: Record<string, string>) => void
) => {
  return (
    <Typography variant="subtitle2" className="cognisaas-pointer link-text" onClick={() => func(params.row)}>
      {params.row[params.field]}
    </Typography>
  );
};

export const CustomTextlinkCell = (
  params: GridRenderEditCellParams<any, any, any>,
  func: (rowData: Record<string, string>) => void,
  linkValue: string
) => {
  return (
    <Typography variant="subtitle2" className="cognisaas-pointer link-text" onClick={() => func(params.row)}>
      {/* {params.row[params.field]} */}
      {linkValue}
    </Typography>
  );
};

export const StatusCell = (params: GridRenderEditCellParams<any, any, any>) => {
  return (
    <Stack>
      <Stack spacing={1} direction="row">
        <span
          style={{ marginRight: '4px' }}
          className={
            params.value == 'red'
              ? 'red_status_box_task'
              : params.value == 'amber'
              ? 'amber_status_box_task'
              : params.value == 'green'
              ? 'green_status_box_task'
              : params.value == 'blue'
              ? 'blue_status_box_task'
              : ''
          }
        ></span>
        {params.value == 'red'
          ? 'Overdue'
          : params.value == 'amber'
          ? 'At Risk'
          : params.value == 'green'
          ? 'On Track'
          : params.value == 'blue'
          ? 'Completed'
          : ''}
      </Stack>
    </Stack>
  );
};

export const CostCell = (params: GridRenderEditCellParams<any, any, any>) => {
  // console.log(params.row[params.field]);
  return `${formatToUSD(params.row[params.field])}`;
};

export const AvatarCell = (params: GridRenderEditCellParams<any, any, any>) => {
  return (
    <Stack direction="row">
      <Avatar style={{ height: '34px', width: '34px', marginTop: '3px' }} {...stringAvatar(params.value)} />

      {/* <Avatar>{params.value ? params.value.slice(0, 1) : 'no name'}</Avatar> */}
      <Typography m={1} variant="subtitle1">
        {params.value}
      </Typography>
    </Stack>
  );
};

export const FieldWithStatus = (
  params: GridRenderEditCellParams<any, any, any>,
  fieldDataKey: string,
  fieldStatusKey: string
) => {
  const dataKeyForName = fieldDataKey;
  const dataKeyForStatus = fieldStatusKey;
  let statusName = '';
  switch (params.row[dataKeyForStatus]) {
    case 'red':
      statusName = 'Overdue';
      break;
    case 'amber':
      statusName = 'At Risk';
      break;
    case 'green':
      statusName = 'On Track';
      break;
    case 'blue':
      statusName = 'Completed';
      break;
    default:
      statusName = 'Unknown';
  }

  return (
    <Typography variant="subtitle2" className="cognisaas-pointer link-text">
      <Link style={{ textDecoration: 'none', color: '#2D60F6' }} to="#">
        {params.row[dataKeyForName]}
      </Link>
      <StatusBadge
        statusColor={getStatusColor(params.row[dataKeyForStatus])}
        textColor="#7A7A7A"
        text={statusName}
        isActive={false}
      />
    </Typography>
  );
};

export const MenuCell = (
  params: any,
  menuOptions: string[],
  func: (rowData: Record<string, any>, action: string) => void
) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: '160px',
            width: '20ch',
          },
        }}
      >
        {menuOptions.map((item) => (
          <MenuItem
            key={item}
            dense={true}
            onClick={() => {
              func(params.row, item);
              handleClose();
            }}
          >
            {item}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

// (
//   params: GridRenderEditCellParams<any, any, any>,
//   menuOptions: string[],
//   func: (rowData: Record<string, string>, action: string) => void
// ) => {
//   return <>aman</>;
// };

// const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
// const open = Boolean(anchorEl);
// const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
//   setAnchorEl(event.currentTarget);
// };
// const handleClose = () => {
//   setAnchorEl(null);
//   // console.log(e.target.value);
// };

// return (
//   <div>
//     <Typography onClick={handleClick}>
//       <MoreVertIcon />
//     </Typography>
//     <Menu
//       anchorEl={anchorEl}
//       open={open}
//       onClose={handleClose}
//       MenuListProps={{
//         'aria-labelledby': 'basic-button',
//       }}
//       PaperProps={{
//         elevation: 0,
//         sx: {
//           maxHeight: '167px',
//           padding: '0px',
//           borderRadius: '4px',
//           boxShadow: '0px 4px 26px rgba(0, 0, 0, 0.05)',
//         },
//       }}
//     >
//       {menuOptions.map((item, index) => {
//         return (
//           <MenuItem
//             // sx={index !== menuOptions.length - 1 ? { borderBottom: '1px solid #E5E5E5' } : {}}
//             dense={true}
//             key={index}
//             onClick={() => {
//               handleClose();
//               func(params.row, item);
//             }}
//           >
//             {item}
//           </MenuItem>
//         );
//       })}
//     </Menu>
//   </div>
// );

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToHslColor(name, 30, 80),
    },
    children: (
      <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#000000' }}>
        {name.split(' ')[0][0]}
        {name.indexOf(' ') >= 0 ? name.split(' ')[1][0] : null}
      </Typography>
    ),
  };
}

const StripedDataGrid = styled(DataGridPremium)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    border: 'none',
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
        },
      },
    },
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  border: 'none',
  flexDirection: 'column',
  // height: 600,
  width: '100%',
  '& .MuiFormGroup-options': {
    alignItems: 'center',
    paddingBottom: theme.spacing(1),
    '& > div': {
      minWidth: 100,
      margin: theme.spacing(2),
      marginLeft: 0,
    },
    border: 'none',
  },
}));

interface GridPaginationSettings {
  pagination: boolean;
  autoPageSize: boolean;
  pageSize: number | undefined;
}

const Table = (props: Props) => {
  // console.log(props.data);
  // console.log(props.columns);

  const initialState: any = {
    columns: {
      columnVisibilityModel: {},
    },
  };

  function getPaginationItemStyle(item: any) {
    switch (item.type) {
      case 'page':
        if (item.selected) {
          return { background: '#c0c0c0', color: '#2D60F6' };
        }
        return { background: '#ffffff', color: '#000000' };
      case 'previous':
        return {};
      case 'next':
        return {};
    }
  }

  const CustomPagination = () => {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
    return (
      <Box
        component={'span'}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          margin: '20px 0px',
        }}
      >
        <Typography variant="subtitle1">
          {(page === 0 ? (pageCount === 0 ? 0 : 1) : page) * (props.pageSize ? props.pageSize : 10)} of{' '}
          {props.data.length}
        </Typography>
        <Pagination
          count={pageCount}
          onChange={(event, value) => apiRef.current.setPage(value)}
          shape="rounded"
          page={page === 0 ? 1 : page}
          renderItem={(item) => {
            return <PaginationItem {...item} sx={getPaginationItemStyle(item)} />;
          }}
        />
      </Box>
    );
  };

  props.columns.forEach((item: any) => {
    if (item.hide) {
      initialState.columns.columnVisibilityModel[item.field] = false;
    }
  });

  const rows = [...props.data];

  const columns = [...props.columns];

  const data = {
    columns,
    rows,
    initialState,
  };

  const [pagination] = useState<GridPaginationSettings>({
    pagination: true,
    autoPageSize: false,
    pageSize: props.pageSize ? props.pageSize : 5,
  });

  return (
    <StyledBox>
      <StripedDataGrid
        hideFooter={props.hideFooter}
        autoHeight
        sx={{
          boxShadow: 0,
          border: 0,
          borderColor: 'primary.light',
          '& .MuiDataGrid-cell:hover': {
            color: 'primary.main',
          },
        }}
        {...data}
        components={{
          Toolbar: (() => {
            if (props.hideHeader) {
              return null;
            } else {
              return GridToolbar;
            }
          })(),
          Footer: CustomPagination,
        }}
        componentsProps={{
          toolbar: { showQuickFilter: true },
        }}
        loading={false}
        // checkboxSelection
        getRowId={(row) => row[props.uniquRowId]}
        disableSelectionOnClick
        rowThreshold={0}
        // initialState={{
        //   ...data.initialState,
        //   pinnedColumns: { left: ['contact_name'] },
        // }}
        {...pagination}
        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'odd' : 'odd')}
      />
    </StyledBox>
  );
};

export default Table;
