import styled from 'styled-components';

export const Container = styled.div`
    padding: 22px;
    font-family: 'Noto Sans';
`;

export const Title = styled.p`
    font-weight: 500;
    font-size: 18px;
    margin:0;
    margin-bottom: 20px;
`;

