import { Stack, Typography, Menu, IconButton, MenuItem, TextField } from '@mui/material';
import CognisaasTaskCard from '../../shared/CognisaasCard/CognisaasTaskCard/CognisaasTaskCard';
import React, { useState } from 'react';
import {
  cloneProjectPhase,
  cloneUsecasePhase,
  deleteProjectSection,
  deleteUsecaseSection,
  updatePhase,
} from '../../../store/modules/Task/slice';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { getProjectInformation } from '../../../store/modules/Project/slice';
import { useParams } from 'react-router-dom';

import { Droppable, Draggable } from 'react-beautiful-dnd';
import { useAppDispatch } from 'store/hooks';
import { Container, TaskList, TaskButton, TaskWrapper } from './Phase.styles';
import { getCustomerCompanyName, getCustomerSubDomain, getCustomerUid, getLoggedInUserName } from 'utils/getUid';
import { userPermissionSchema } from 'components/Reports/SideDrawer/types';
import { label_task } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import BasicModal from 'components/shared/CognisaasModal/CognisaasModal';
import CognisaasButton from 'components/shared/CognisaasButton/CognisaasButton';
import DeleteModal from 'components/shared/CognisaasModal/DeleteModal';
import CognisaasToast from 'components/shared/CognisaasToast/CognisaasToast';

const PhaseCard = (props: any) => {
  let phase = props.phase;
  const projectInfo = props.projectInfo;
  const dispatch = useAppDispatch();
  const params = useParams();

  console.log(props);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openRenameModal, setOpenRenameModal] = useState<boolean>(false);
  const [phaseName, setPhaseName] = useState<string>('');

  const open = Boolean(anchorEl);
  const [addScrollbar, setAddScrollbar] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [alertDetails, setAlertDetails] = React.useState<{
    isVisible: boolean;
    type: 'success' | 'failure' | 'info';
    message: string;
  }>({ isVisible: false, type: 'success', message: '' });

  let isScrolling: any;

  const detail: any = projectInfo;
  if (params.caseId) {
    let uc: any = null;
    for (let i = 0; i < detail.usecase.length; i++) {
      if (params.caseId == detail.usecase[i].case_uid) {
        uc = detail.usecase[i];
      }
    }
    for (let i = 0; i < uc.section.length; i++) {
      if (phase.section_uid == uc.section[i].section_uid) {
        phase = uc.section[i];
      }
    }
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleScroll = () => {
    setAddScrollbar(true);
    clearTimeout(isScrolling);
    isScrolling = window.setTimeout(() => {
      setAddScrollbar(false);
    }, 500);
  };

  const handleDelete = async () => {
    const details = {
      section_uid: phase.section_uid,
      customer_uid: getCustomerUid(),
      customer_company: getCustomerCompanyName(),

      deleted_by_user_name: getLoggedInUserName(),
      base_com_url: window.location.host + '/customer-portal/' + getCustomerSubDomain() + '/',
    };
    if (phase.project_uid) {
      dispatch(deleteProjectSection(details));
    } else {
      dispatch(deleteUsecaseSection(details));
    }

    const getUpdatedInfo = {
      association_uid: params.associationId,
      project_uid: projectInfo.project_uid,
    };
    handleClose();
    await dispatch(getProjectInformation(getUpdatedInfo));
    setAlertDetails({ isVisible: true, type: 'success', message: 'Phase deleted successfully' });
  };

  const handlePhaseCloning = async () => {
    if (phase.project_uid) {
      await dispatch(cloneProjectPhase({ section_uid: phase.section_uid }));
    } else {
      await dispatch(cloneUsecasePhase({ section_uid: phase.section_uid }));
    }
    const getUpdatedInfo = {
      association_uid: params.associationId,
      project_uid: projectInfo.project_uid,
    };
    handleClose();
    await dispatch(getProjectInformation(getUpdatedInfo));
    setAlertDetails({ isVisible: true, type: 'success', message: 'Phase cloned successfully' });
  };

  const handlePhaseRename = async () => {
    if (phase.project_uid) {
      await dispatch(updatePhase({ section_uid: phase.section_uid, section_name: phaseName, type: 'Project' }));
    } else {
      await dispatch(updatePhase({ section_uid: phase.section_uid, section_name: phaseName, type: 'Case' }));
    }
    const getUpdatedInfo = {
      association_uid: params.associationId,
      project_uid: projectInfo.project_uid,
    };
    handleClose();
    setOpenRenameModal(false);
    await dispatch(getProjectInformation(getUpdatedInfo));
    setAlertDetails({ isVisible: true, type: 'success', message: 'Phase renamed successfully' });
  };

  return (
    <>
      <BasicModal
        height={274}
        width={570}
        open={openRenameModal}
        onClose={() => {
          setOpenRenameModal(false);
          handleClose();
        }}
      >
        <Stack>
          <Stack my={2}>
            <Typography variant="h2">Rename Phase</Typography>
          </Stack>
          <TextField
            autoComplete=""
            autoFocus={true}
            value={phaseName}
            onChange={(e) => setPhaseName(e.target.value)}
            style={{ width: '410px', marginBottom: '15px' }}
            size="small"
            placeholder="Enter Template name"
          />
          <Stack mt={3} spacing={2} justifyContent="flex-end" direction="row">
            <CognisaasButton
              isOutlined={true}
              clickHandler={() => {
                setOpenRenameModal(false);
                handleClose();
              }}
              label="Cancel"
            />
            <CognisaasButton
              clickHandler={() => handlePhaseRename()}
              isOutlined={false}
              label="Save"
              disabled={phaseName.length === 0}
            />
          </Stack>
        </Stack>
      </BasicModal>
      <DeleteModal
        isOpen={openDeleteModal}
        setIsOpen={setOpenDeleteModal}
        message={'Are you sure you want to delete this phase'}
        onDelete={handleDelete}
      />
      <Draggable draggableId={phase.section_uid} index={props.index}>
        {(provided) => (
          <Container {...provided.draggableProps} ref={provided.innerRef}>
            <Stack direction="row" justifyContent="space-between" {...provided.dragHandleProps}>
              <Typography variant="h2" sx={{ ml: '10px', mt: '10px' }}>
                {phase.section_detail}
              </Typography>
              {props?.userPermissions?.includes(userPermissionSchema.EDIT) && (
                <IconButton disableRipple onClick={handleClick}>
                  <MoreVertIcon />
                </IconButton>
              )}
            </Stack>

            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              style={{ padding: '0px' }}
            >
              <MenuItem
                onClick={() => {
                  setPhaseName(phase.section_detail);
                  setOpenRenameModal(true);
                  handleClose();
                }}
              >
                <Typography variant="inherit">Rename</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handlePhaseCloning();
                }}
              >
                <Typography variant="inherit">Clone Phase</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setOpenDeleteModal(true);
                  // handleDelete();
                }}
              >
                <Typography variant="inherit">Delete Phase</Typography>
              </MenuItem>
            </Menu>
            <TaskWrapper addScrollbar={addScrollbar} onScroll={handleScroll}>
              <Droppable droppableId={phase.section_uid} type="task">
                {(provided: any) => (
                  <TaskList ref={provided.innerRef} {...provided.droppableProps}>
                    {phase.task.map((task: any, index: number) => (
                      <CognisaasTaskCard
                        clickHandler={(e: React.MouseEvent<HTMLElement>) => {
                          if (
                            (e.target as HTMLInputElement).className.includes('not_defined')
                            // (e.target as HTMLInputElement).tagName === 'INPUT' ||
                            // (e.target as HTMLInputElement).tagName === 'SPAN' ||
                            // (e.target as HTMLInputElement).tagName === 'svg'
                          )
                            return;
                          if ((e.target as HTMLInputElement).className.includes('is_defined')) {
                            props.openUpdateTaskDrawer(task);
                            props.setPhase(phase.section_uid);
                          }
                        }}
                        task={task}
                        key={task.task_uid}
                        index={index}
                        clientInformation={props.clientInformation}
                        projectInformation={props.projectInformation}
                        userPermissions={props.userPermissions}
                      />
                    ))}
                    {props.projectInfo.__user_permissions?.includes(userPermissionSchema.EDIT) && (
                      <TaskButton
                        onClick={() => {
                          props.setPhase(phase.section_uid);
                          props.addTaskDrawerToggle();
                        }}
                      >
                        Add {label_task}
                      </TaskButton>
                    )}
                  </TaskList>
                )}
              </Droppable>
            </TaskWrapper>
          </Container>
        )}
      </Draggable>
      <CognisaasToast
        open={alertDetails.isVisible}
        onClose={() => setAlertDetails({ isVisible: false, type: alertDetails.type, message: '' })}
        message={alertDetails.message}
        type={alertDetails.type}
      />
    </>
  );
};

export default PhaseCard;
