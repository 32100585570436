import { apis } from '../axiosInstance';
import { clientRoutes } from '../route';
import Cookies from 'universal-cookie';
import { clientRoutes2 } from 'api/route-new';

const cookies = new Cookies();

const getClientList = () =>
  apis.phpAxiosInstance.get(clientRoutes.clientList(cookies.get('cogni_customer_uid')), { headers: { JWT: 'true' } });
const getCRMClientList = () =>
  apis.phpAxiosInstance.get(clientRoutes.crmClientList(cookies.get('cogni_customer_uid')), {
    headers: { JWT: 'true' },
  });
const getTotalTrailUnderClient = (client_uid: any) =>
  apis.phpAxiosInstance.get(clientRoutes.totalTrailUnderClient(client_uid), { headers: { JWT: 'true' } });
const updateClient = (clientDetail: any) =>
  apis.phpAxiosInstance.post(clientRoutes.updateClient(), clientDetail, { headers: { JWT: 'true' } });

const deleteClient = (clientDetail: any) =>
  apis.phpAxiosInstance.post(clientRoutes.deleteClient(), clientDetail, { headers: { JWT: 'true' } });

const updateClientCustomField = (fieldDetail: any) =>
  apis.phpAxiosInstance.post(clientRoutes.updateClientCustomField(), fieldDetail, { headers: { JWT: 'true' } });

const getAllRenewalTasks = () =>
  apis.phpAxiosInstance.post(
    clientRoutes.renewalTasks(),
    { customer_uid: cookies.get('cogni_customer_uid') },
    { headers: { JWT: 'true' } }
  );
const getAllRenewalTasksOfClient = (clientDetail: any) =>
  apis.phpAxiosInstance.post(
    clientRoutes.renewalTasksOfClient(),
    { client_uid: clientDetail },
    { headers: { JWT: 'true' } }
  );
const doneRenewalStage = (clientDetail: any) =>
  apis.phpAxiosInstance.post(clientRoutes.doneRenewalStage(), clientDetail, { headers: { JWT: 'true' } });
const taskRenewalOwner = (clientDetail: any) =>
  apis.phpAxiosInstance.post(clientRoutes.taskRenewalOwner(), clientDetail, { headers: { JWT: 'true' } });
const getAllRevenueUsecaseTasks = () =>
  apis.phpAxiosInstance.get(clientRoutes.revenueUsecaseTasks(cookies.get('cogni_customer_uid')), {
    headers: { JWT: 'true' },
  });

const getClientAccessListMembers = (client_uid: string) =>
  apis.phpAxiosInstance.get(clientRoutes.getClientAccessListMembers(client_uid), { headers: { JWT: 'true' } });

const addUserToClientAccessList = (detail: any) =>
  apis.phpAxiosInstance.post(clientRoutes.addUserToClientAccessList(), detail, { headers: { JWT: 'true' } });

const removeUserFromClientAccessList = (detail: any) =>
  apis.phpAxiosInstance.post(clientRoutes.removeUserFromClientAccessList(), detail, { headers: { JWT: 'true' } });

const getRevenueProjectTasks = () =>
  apis.phpAxiosInstance.get(clientRoutes.revenueProjectTasks(cookies.get('cogni_customer_uid')), {
    headers: { JWT: 'true' },
  });

const addClientInformation = (clientDetail: any) =>
  apis.phpAxiosInstance.post(clientRoutes.addClientInfo(), clientDetail, { headers: { JWT: 'true' } });

const sendReportToAllClientContacts = (clientDetail: any) => {
  const formData = new FormData();
  const metaData = { ...clientDetail, attachments: [] }; // removing attachment from normal data

  // attching files
  for (let i = 0; i < clientDetail.attachments.length; i++) {
    formData.append('file_' + i + 1, clientDetail.attachments[i]);
  }

  formData.append('metadata', JSON.stringify(metaData));
  return apis.phpAxiosInstance.post(clientRoutes.sendReportToAllClientContacts(), formData, {
    headers: { JWT: 'true' },
  });
};

const sendDynamicReportToClientContact = (clientDetail: any) => {
  const formData = new FormData();
  const metaData = { ...clientDetail, attachments: [] }; // removing attachment from normal data

  // attching files
  for (let i = 0; i < clientDetail.attachments.length; i++) {
    formData.append('file_' + i + 1, clientDetail.attachments[i]);
  }

  formData.append('metadata', JSON.stringify(metaData));

  return apis.phpAxiosInstance.post(clientRoutes.sendDynamicReportToClientContact(), formData, {
    headers: { JWT: 'true' },
  });
};

const getClientInformation = (client_uid: any) =>
  apis.phpAxiosInstance.get(clientRoutes.getSingleClientInformation(client_uid), { headers: { JWT: 'true' } });
const updateSingleClientInformationFromCRM = (details: any) =>
  apis.phpAxiosInstance.post(clientRoutes.updateSingleClientInformationfromCRM(), details, {
    headers: { JWT: 'true' },
  });

const getClientContacts = (client_uid: any) =>
  apis.phpAxiosInstance.get(clientRoutes.getClientContacts(client_uid), { headers: { JWT: 'true' } });

const getClientContactsByAssociationUid = (client_uid: any) =>
  apis.phpAxiosInstance.get(clientRoutes.getClientContacts(client_uid), { headers: { JWT: 'true' } });

const addClientContact = (detail: any) =>
  apis.phpAxiosInstance.post(clientRoutes.addClientContact(), detail, { headers: { JWT: 'true' } });

const updateClientContact = (detail: any) =>
  apis.phpAxiosInstance.post(clientRoutes.updateClientContact(), detail, { headers: { JWT: 'true' } });

const updateRenewalDateForClient = (detail: any) =>
  apis.phpAxiosInstance.post(clientRoutes.updateClientRenewal(), detail, { headers: { JWT: 'true' } });

const sendClientContactAdditionMail = (detail: any) =>
  apis.phpAxiosInstance.post(clientRoutes.addClientContactMail(), detail);

const getClientDetail = (client_uid: string) =>
  apis.phpAxiosInstance.get(clientRoutes.clientInformation(client_uid), { headers: { JWT: 'true' } });

const getClientDomain = (client_uid: string) =>
  apis.phpAxiosInstance.get(clientRoutes.clientDomains(client_uid), { headers: { JWT: 'true' } });

const getClientyUidByAssociationUid = (association_uid: string) =>
  apis.phpAxiosInstance.get(clientRoutes.getClientUidByAssociationUid(association_uid), { headers: { JWT: 'true' } });

const addClientWhitelistDomain = (detail: any) =>
  apis.phpAxiosInstance.post(clientRoutes.addClientDomain(), detail, { headers: { JWT: 'true' } });
const getAllUsers = () =>
  apis.phpAxiosInstance.get(clientRoutes.revenueProjectTasks(cookies.get('cogni_customer_uid')), {
    headers: { JWT: 'true' },
  });

const getClientByUid = (client_uid: string) =>
  apis.phpAxiosInstance.get(clientRoutes.getClientDetailByUid(client_uid), { headers: { JWT: 'true' } });

const addClientInformationApi = (payload: any) =>
  apis.phpAxiosInstance.post(clientRoutes.addClientInformation(), payload, { headers: { JWT: 'true' } });

const updateClientStatusById = (client_status: 0 | 1, client_uid: string) =>
  apis.phpAxiosInstance.post(
    clientRoutes.updateClientStatus(),
    { client_status, client_uid },
    { headers: { JWT: 'true' } }
  );

const addClientAuditTrail = (payload: any) =>
  apis.phpAxiosInstance.post(clientRoutes.addClientAuditTrail(), payload, { headers: { JWT: 'true' } });

const getClientAudit = (client_uid: string) =>
  apis.phpAxiosInstance.get(clientRoutes.getClientAudit(client_uid), { headers: { JWT: 'true' } });

const getVerifiedClientContacts = (client_uid: string) =>
  apis.phpAxiosInstance.get(clientRoutes.getVerifiedClientContacts(client_uid), { headers: { JWT: 'true' } });

const updatedcasewithfeaturestatusClient = (client_uid: any) =>
  apis.phpAxiosInstance.get(clientRoutes.updatedcasewithfeaturestatusClient(client_uid), { headers: { JWT: 'true' } });

const getProjectInformationWithTaskClientReport = (client_uid: any) =>
  apis.phpAxiosInstance.get(clientRoutes.getProjectInformationWithTaskClientReport(client_uid), {
    headers: { JWT: 'true' },
  });

const getProjecWithClientUid = (client_uid: any) =>
  apis.phpAxiosInstance.get(clientRoutes.getProjecWithClientUid(client_uid), { headers: { JWT: 'true' } });
const addClientContactWithSignup = (payload: any) =>
  apis.phpAxiosInstance.post(clientRoutes.addClientInfoWhenSignup(), payload, { headers: { JWT: 'true' } });

const deleteClientContactByContactUid = (deleteContactDetails: any) =>
  apis.phpAxiosInstance.post(
    clientRoutes.deleteClientContact(),
    { deleteContactDetails },
    { headers: { JWT: 'true' } }
  );

const updateClientContactbyUid = (payload: any) =>
  apis.phpAxiosInstance.post(clientRoutes.updateClientContactbyUid(), payload, { headers: { JWT: 'true' } });

const sendAccessLinkToContact = (payload: any) =>
  apis.phpAxiosInstance.post(clientRoutes.sendAccessLinkToContact(), payload, { headers: { JWT: 'true' } });

const updateClientSettings = (payload: any) =>
  apis.phpAxiosInstance.post(clientRoutes.updateClientSettings(), payload, { headers: { JWT: 'true' } });

// UPDATE_CUSTOMER_DISPLAY_ARR_ON_CIENT_LIST
const updateDisplayArrOnClientList = (payload: any) =>
  apis.phpAxiosInstance.post(clientRoutes.updateDisplayArrOnClientList(), payload, { headers: { JWT: 'true' } });

// NEW API
const getClient = (clientId: string) => apis.phpAxiosInstance.get(clientRoutes2.clientDetail(clientId));

export const clientAPI = {
  getCRMClientList,
  getClientList,
  getTotalTrailUnderClient,
  updateClient,
  deleteClient,
  updateClientCustomField,
  getAllRenewalTasks,
  getAllRenewalTasksOfClient,
  doneRenewalStage,
  taskRenewalOwner,
  getRevenueProjectTasks,
  addClientInformation,
  getClientInformation,
  updateSingleClientInformationFromCRM,
  getClientyUidByAssociationUid,
  getClientContacts,
  getClientContactsByAssociationUid,
  addClientContact,
  updateClientContact,
  sendClientContactAdditionMail,
  updateRenewalDateForClient,
  getClientDetail,
  getClientDomain,
  addClientWhitelistDomain,
  getAllRevenueUsecaseTasks,
  getClientAccessListMembers,
  addUserToClientAccessList,
  removeUserFromClientAccessList,
  getAllUsers,
  getClientByUid,
  addClientInformationApi,
  updateClientStatusById,
  addClientAuditTrail,
  getClientAudit,
  getVerifiedClientContacts,
  updatedcasewithfeaturestatusClient,
  getProjectInformationWithTaskClientReport,
  getProjecWithClientUid,
  sendReportToAllClientContacts,
  addClientContactWithSignup,
  deleteClientContactByContactUid,
  updateClientContactbyUid,
  sendAccessLinkToContact,
  updateClientSettings,
  updateDisplayArrOnClientList,
  sendDynamicReportToClientContact,

  //
  getClient,
};
