import { Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from '../../../store/hooks';
import UpdateTaskDrawer from 'components/TaskModule/TaskView/UpdateTaskDrawer';
import ProductClientMapPanel from 'components/shared/ProductClientMapPanel/ProductClientMapPanel';
import useCompanyUsers from 'components/shared/Hooks/useCompanyUsers';
import {
  selectProjectRecommendations,
  selectRevenueImpactRecommendations,
  selectTaskRecommendations,
  selectUsecaseRecommendations,
} from '../../../store/modules/ActionableInsights/selector';
import {
  fetchProjectRecommendations,
  fetchRecommendations,
  fetchTaskRecommendations,
  fetchUsecaseRecommendation,
} from '../../../store/modules/ActionableInsights/slice';
import ClientCard from './ClientCard';
import { ReactComponent as NoRecordsFoundIcon } from '../../../assets/svgs/no_records_mini.svg';
import UsecaseTaskRecommendationCard from './UsecaseTaskRecommendationCard';
import { formatDuration, intervalToDuration } from 'date-fns';
import { apis } from 'api/axiosInstance';
import { Link, useNavigate } from 'react-router-dom';
import { filterRecommendationDataInRealTime } from '../ActionableInsightUtil';
import { getUserNameByUserUid } from 'utils/getUid';
import {
  label_client,
  label_due_date,
  label_project,
  label_task,
  label_use_case,
  label_use_case_delivery_manager,
} from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { requestState } from 'store/modules/types';
import OverlaySpinner from 'components/shared/CognisaasOverlaySpinner/CognisaasOverlaySpinner';
import * as _swal from 'sweetalert';
import { SweetAlert } from 'sweetalert/typings/core';
type Props = {
  for: 'revenueImpact' | 'usecase' | 'project' | 'task';
  selectedIndexForButtonGroup?: number;
};
const swal: SweetAlert = _swal as any;
interface Size {
  width: number;
  height: number;
}

// Hook
function useWindowSize(): Size {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<Size>({
    width: 0,
    height: 0,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

export default function RecommendationPanel(props: Props) {
  const navigate = useNavigate();
  const companyUsers = useCompanyUsers();
  const revenueImpactRecommendationState = useAppSelector(selectRevenueImpactRecommendations);
  const [selectedrecommendationsList, setSelectedRecommendationsList] = useState<any[]>([]);
  const [updateTaskDrawer, setUpdateTaskDrawer] = useState<boolean>(false);
  const [clientMapDrawer, setClientMapDrawer] = useState<boolean>(false);
  const [selectedFeatureUid, setSelectedFeatureUid] = useState<string>('');
  const [selectedFeatureName, setSelectedFeatureName] = useState<string>('');
  const [users, setUsers] = useState<any[]>([]);
  const size: Size = useWindowSize();

  const [clientUid, setClientUid] = useState<any>(null);
  const [taskToOpen, setTaskToOpen] = useState<any>({});
  const fetchingRecommendations: boolean = revenueImpactRecommendationState.status === requestState.loading;
  const dispatch = useAppDispatch();

  const projectRecommendationsState = useAppSelector(selectProjectRecommendations);
  const usecaseRecommendationsState = useAppSelector(selectUsecaseRecommendations);
  const taskRecommendationsState = useAppSelector(selectTaskRecommendations);

  useEffect(() => {
    // what to fetch from server on basis of type of panel requested
    if (props.for === 'revenueImpact') {
      if (revenueImpactRecommendationState.data.length === 0 && revenueImpactRecommendationState.status === 'idle') {
        dispatch(fetchRecommendations());
      }
    } else if (props.for === 'usecase') {
      // usecase
      if (usecaseRecommendationsState.data.length === 0 && usecaseRecommendationsState.status === 'idle') {
        dispatch(fetchUsecaseRecommendation());
      }
    } else if (props.for === 'project') {
      // project
      if (projectRecommendationsState.data.length === 0 && projectRecommendationsState.status === 'idle') {
        dispatch(fetchProjectRecommendations());
      }
    } else if (props.for === 'task') {
      //task
      if (taskRecommendationsState.data.length === 0 && taskRecommendationsState.status === 'idle') {
        dispatch(fetchTaskRecommendations());
      }
    }
  }, [props.for]);

  const getOnlyNames = () => {
    const usersList = companyUsers.map((user: any) => user.user_username);
    return usersList;
  };

  useEffect(() => {
    if (companyUsers.length > 0) {
      const users = getOnlyNames();
      setUsers([...users]);
    }
  }, [companyUsers]);

  useEffect(() => {
    // setting the data state
    if (
      revenueImpactRecommendationState.status === 'success' &&
      revenueImpactRecommendationState.data.length > 0 &&
      props.for === 'revenueImpact'
    ) {
      const recomendations = filterRecommendationDataInRealTime(
        users,
        users,
        [],
        revenueImpactRecommendationState.data
      );

      setSelectedRecommendationsList(recomendations.slice(0, 4));
    } else if (
      usecaseRecommendationsState.status === 'success' &&
      usecaseRecommendationsState.data.length > 0 &&
      props.for === 'usecase'
    ) {
      setSelectedRecommendationsList(usecaseRecommendationsState.data);
    } else if (
      projectRecommendationsState.status === 'success' &&
      projectRecommendationsState.data.length > 0 &&
      props.for === 'project'
    ) {
      setSelectedRecommendationsList(projectRecommendationsState.data);
    } else if (
      taskRecommendationsState.status === 'success' &&
      taskRecommendationsState.data.length > 0 &&
      props.for === 'task'
    ) {
      setSelectedRecommendationsList(taskRecommendationsState.data);
    }
  }, [
    users,
    revenueImpactRecommendationState,
    usecaseRecommendationsState,
    projectRecommendationsState,
    taskRecommendationsState,
    props.for,
  ]);
  const handleAddMoreRecommendations = () => {
    switch (props.for) {
      case 'revenueImpact':
        if (revenueImpactRecommendationState.data.length < selectedrecommendationsList.length + 20) {
          setSelectedRecommendationsList(revenueImpactRecommendationState.data);
        } else {
          setSelectedRecommendationsList([
            ...selectedrecommendationsList,
            ...revenueImpactRecommendationState.data.slice(
              selectedrecommendationsList.length,
              selectedrecommendationsList.length + 20
            ),
          ]);
        }
    }
  };
  const checkDueDate = (due_date: string | number | Date) => {
    const a = new Date(due_date);
    const today = new Date(new Date().setHours(0, 0, 0, 0));
    if (a < today) {
      return true;
    } else {
      return false;
    }
  };

  const openUsecase = (a: string, p: string, c: string) => {
    const route = `/projects/project/${a}/${p}/${c}`;
    return route;
  };

  const openTask = async (task_uid: string, client_uid: string) => {
    const client = {
      client_uid: client_uid,
    };
    setClientUid(client);
    const { data } = await apis.phpAxiosInstance.get(`getTaskByTaskUid?task_uid=${task_uid}`, {
      headers: { JWT: 'true' },
    });
    setTaskToOpen({ ...data.result });
    setUpdateTaskDrawer(!updateTaskDrawer);
  };
  const openProductClientMappingDrawer = async (id: string, description: string) => {
    setSelectedFeatureUid(id);
    setSelectedFeatureName(description);
    setClientMapDrawer(!clientMapDrawer);
    return;
  };
  const handleTaskDrawerClose = async () => {
    swal({
      closeOnClickOutside: false,
      timer: 5000,
      title: 'Please wait',
      text: 'The system is updating the recommendations with the next thing to prioritise across clients, projects and use cases.',
      buttons: [false],
    });
    setUpdateTaskDrawer(!updateTaskDrawer);
    setTaskToOpen({});
    await dispatch(fetchRecommendations());
  };
  return (
    <>
      {fetchingRecommendations && <OverlaySpinner />}
      {selectedrecommendationsList.length === 0 ? (
        <Box
          component="span"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '20px 0px',
            paddingTop: '100px',
          }}
        >
          <NoRecordsFoundIcon />
        </Box>
      ) : (
        <>
          <Box sx={{ height: `${size.height - 220}px`, overflow: 'scroll' }}>
            <Stack alignItems="center" sx={{ overflowY: 'scroll' }}>
              {selectedrecommendationsList?.map((recommendation: any, index) => {
                if (props.for === 'revenueImpact') {
                  if (recommendation.TYPE == 'feature' && checkDueDate(recommendation.due_date)) {
                    return (
                      <ClientCard
                        key={index}
                        customHeader={
                          <Typography variant="subtitle1">
                            Release product functionality{' '}
                            <Link
                              to="/actionable-insights"
                              onClick={() =>
                                openProductClientMappingDrawer(recommendation.id, recommendation.description)
                              }
                            >
                              {recommendation.description}
                            </Link>{' '}
                            at the earliest ({label_due_date} for this or a dependent {label_use_case} was{' '}
                            {recommendation.due_date})
                          </Typography>
                        }
                        prodOrClientUid={recommendation.prod_or_client_uid}
                        ownerName={recommendation.client_account_owner}
                        menuRequired={false}
                      />
                    );
                  } else if (recommendation.TYPE == 'feature' && !checkDueDate(recommendation.due_date)) {
                    return (
                      <ClientCard
                        key={index}
                        customHeader={
                          <Typography variant="subtitle1">
                            Release product functionality{' '}
                            <Link
                              to="/actionable-insights"
                              onClick={() =>
                                openProductClientMappingDrawer(recommendation.id, recommendation.description)
                              }
                            >
                              {recommendation.description}
                            </Link>{' '}
                            by {recommendation.due_date}
                          </Typography>
                        }
                        prodOrClientUid={recommendation.prod_or_client_uid}
                        ownerName={recommendation.client_account_owner}
                        menuRequired={false}
                      />
                    );
                  }
                  if (recommendation.TYPE == 'projecttask' && checkDueDate(recommendation.due_date)) {
                    return (
                      <ClientCard
                        key={index}
                        customHeader={
                          <Typography variant="subtitle1">
                            Complete {label_task.toLowerCase()}{' '}
                            <Link
                              to="/actionable-insights"
                              onClick={() => openTask(recommendation.id, recommendation.prod_or_client_uid)}
                            >
                              {recommendation.description}
                            </Link>{' '}
                            for {label_client} &nbsp;
                            <Link to={`/client-list/client/${recommendation.prod_or_client_uid}`} target="_blank">
                              {recommendation.client_name}
                            </Link>
                            &nbsp; at the earliest ({label_due_date} for this or it&apos;s dependent{' '}
                            {label_use_case.toLowerCase()} was {recommendation.due_date})
                          </Typography>
                        }
                        prodOrClientUid={recommendation.prod_or_client_uid}
                        menuRequired={false}
                        ownerName={recommendation.client_account_owner}
                      />
                    );
                  } else if (recommendation.TYPE == 'projecttask' && !checkDueDate(recommendation.due_date)) {
                    return (
                      <ClientCard
                        key={index}
                        customHeader={
                          <Typography variant="subtitle1">
                            Complete {label_task.toLowerCase()}{' '}
                            <Link
                              to="/actionable-insights"
                              onClick={() => openTask(recommendation.id, recommendation.prod_or_client_uid)}
                              // color="#2D60F6"
                              // className="cursor-pointer"
                              // variant="subtitle1"
                            >
                              {recommendation.description}
                            </Link>{' '}
                            for {label_client} &nbsp;
                            <Link to={`/client-list/client/${recommendation.prod_or_client_uid}`} target="_blank">
                              {recommendation.client_name}
                            </Link>
                            &nbsp; by {recommendation.due_date}
                          </Typography>
                        }
                        prodOrClientUid={recommendation.prod_or_client_uid}
                        menuRequired={false}
                        ownerName={recommendation.client_account_owner}
                      />
                    );
                  }
                  if (recommendation.TYPE == 'projectclienttask' && checkDueDate(recommendation.due_date)) {
                    return (
                      <ClientCard
                        key={index}
                        customHeader={
                          <Typography variant="subtitle1">
                            Complete {label_task.toLowerCase()}{' '}
                            <Link
                              to="/actionable-insights"
                              onClick={() => openTask(recommendation.id, recommendation.prod_or_client_uid)}
                            >
                              {recommendation.description}
                            </Link>{' '}
                            for {label_client} &nbsp;
                            <Link to={`/client-list/client/${recommendation.prod_or_client_uid}`} target="_blank">
                              {recommendation.client_name}
                            </Link>
                            &nbsp; at the earliest ({label_due_date} for this or it&apos;s dependent{' '}
                            {label_use_case.toLowerCase()} was {recommendation.due_date})
                          </Typography>
                        }
                        prodOrClientUid={recommendation.prod_or_client_uid}
                        menuRequired={false}
                        ownerName={recommendation.client_account_owner}
                      />
                    );
                  } else if (recommendation.TYPE == 'projectclienttask' && !checkDueDate(recommendation.due_date)) {
                    return (
                      <ClientCard
                        key={index}
                        customHeader={
                          <Typography variant="subtitle1">
                            Complete {label_task.toLowerCase()}{' '}
                            <Link
                              to="/actionable-insights"
                              onClick={() => openTask(recommendation.id, recommendation.prod_or_client_uid)}
                              // color="#2D60F6"
                              // className="cursor-pointer"
                              // variant="subtitle1"
                            >
                              {recommendation.description}
                            </Link>{' '}
                            for {label_client} &nbsp;
                            <Link to={`/client-list/client/${recommendation.prod_or_client_uid}`} target="_blank">
                              {recommendation.client_name}
                            </Link>
                            &nbsp; by {recommendation.due_date}
                          </Typography>
                        }
                        menuRequired={false}
                        prodOrClientUid={recommendation.prod_or_client_uid}
                        ownerName={recommendation.client_account_owner}
                      />
                    );
                  }
                  if (recommendation.TYPE == 'task' && checkDueDate(recommendation.due_date)) {
                    return (
                      <ClientCard
                        key={index}
                        customHeader={
                          <Typography variant="subtitle1">
                            Complete {label_task.toLowerCase()}{' '}
                            <Link
                              to="/actionable-insights"
                              onClick={() => openTask(recommendation.id, recommendation.prod_or_client_uid)}
                            >
                              {recommendation.description}
                            </Link>{' '}
                            for {label_client} &nbsp;
                            <Link to={`/client-list/client/${recommendation.prod_or_client_uid}`} target="_blank">
                              {recommendation.client_name}
                            </Link>
                            &nbsp; at the earliest ({label_due_date.toLowerCase()} for this or it&apos;s dependent{' '}
                            {label_use_case.toLowerCase()} was {recommendation.due_date})
                          </Typography>
                        }
                        prodOrClientUid={recommendation.prod_or_client_uid}
                        menuRequired={false}
                        ownerName={recommendation.client_account_owner}
                      />
                    );
                  } else if (recommendation.TYPE == 'task' && !checkDueDate(recommendation.due_date)) {
                    return (
                      <ClientCard
                        key={index}
                        customHeader={
                          <Typography variant="subtitle1">
                            Complete {label_task.toLowerCase()}{' '}
                            <Link
                              to="/actionable-insights"
                              onClick={() => openTask(recommendation.id, recommendation.prod_or_client_uid)}
                              // color="#2D60F6"
                              // className="cursor-pointer"
                              // variant="subtitle1"
                            >
                              {recommendation.description}
                            </Link>{' '}
                            for {label_client} &nbsp;
                            <Link to={`/client-list/client/${recommendation.prod_or_client_uid}`} target="_blank">
                              {recommendation.client_name}
                            </Link>
                            &nbsp; by {recommendation.due_date}
                          </Typography>
                        }
                        prodOrClientUid={recommendation.prod_or_client_uid}
                        menuRequired={false}
                        ownerName={recommendation.client_account_owner}
                      />
                    );
                  }
                  if (recommendation.TYPE == 'clienttask' && checkDueDate(recommendation.due_date)) {
                    return (
                      <ClientCard
                        key={index}
                        customHeader={
                          <Typography variant="subtitle1">
                            Complete {label_task.toLowerCase()}{' '}
                            <Link
                              to="/actionable-insights"
                              onClick={() => openTask(recommendation.id, recommendation.prod_or_client_uid)}
                            >
                              {recommendation.description}
                            </Link>{' '}
                            for {label_client} &nbsp;
                            <Link to={`/client-list/client/${recommendation.prod_or_client_uid}`} target="_blank">
                              {recommendation.client_name}
                            </Link>
                            &nbsp; at the earliest ({label_due_date.toLowerCase()} for this or it&apos;s dependent{' '}
                            {label_use_case.toLowerCase()} was {recommendation.due_date})
                          </Typography>
                        }
                        prodOrClientUid={recommendation.prod_or_client_uid}
                        menuRequired={false}
                        ownerName={recommendation.client_account_owner}
                      />
                    );
                  } else if (recommendation.TYPE == 'clienttask' && !checkDueDate(recommendation.due_date)) {
                    return (
                      <ClientCard
                        key={index}
                        customHeader={
                          <Typography variant="subtitle1">
                            Complete {label_task.toLowerCase()}{' '}
                            <Link
                              to="/actionable-insights"
                              onClick={() => openTask(recommendation.id, recommendation.prod_or_client_uid)}
                              // color="#2D60F6"
                              // className="cursor-pointer"
                              // variant="subtitle1"
                            >
                              {recommendation.description}
                            </Link>{' '}
                            for {label_client} &nbsp;
                            <Link to={`/client-list/client/${recommendation.prod_or_client_uid}`} target="_blank">
                              {recommendation.client_name}
                            </Link>
                            &nbsp; by {recommendation.due_date}
                          </Typography>
                        }
                        prodOrClientUid={recommendation.prod_or_client_uid}
                        menuRequired={false}
                        ownerName={recommendation.client_account_owner}
                      />
                    );
                  }
                  if (recommendation.TYPE == 'usecase' && checkDueDate(recommendation.due_date)) {
                    return (
                      <ClientCard
                        key={index}
                        customHeader={
                          <Typography variant="subtitle1">
                            Complete the solution mapping of {label_use_case.toLowerCase()}
                            &nbsp;
                            <Link
                              state={{
                                clientUid: recommendation.prod_or_client_uid,
                                clientName: recommendation.client_name,
                              }}
                              to={openUsecase(
                                recommendation.association_uid,
                                recommendation.project_uid,
                                recommendation.case_uid
                              )}
                            >
                              {recommendation.description}
                            </Link>
                            &nbsp; at the earliest ({label_due_date.toLowerCase()} for this was{' '}
                            {recommendation.due_date})
                          </Typography>
                        }
                        prodOrClientUid={recommendation.prod_or_client_uid}
                        menuRequired={false}
                        ownerName={recommendation.client_account_owner}
                      />
                    );
                  } else if (recommendation.TYPE == 'usecase' && !checkDueDate(recommendation.due_date)) {
                    return (
                      <ClientCard
                        key={index}
                        customHeader={
                          <Typography variant="subtitle1">
                            Complete the solution mapping of {label_use_case.toLowerCase()} &nbsp;
                            <Link
                              state={{
                                clientUid: recommendation.prod_or_client_uid,
                                clientName: recommendation.client_name,
                              }}
                              to={openUsecase(
                                recommendation.association_uid,
                                recommendation.project_uid,
                                recommendation.case_uid
                              )}
                            >
                              {recommendation.description}
                            </Link>
                            &nbsp; by {recommendation.due_date})
                          </Typography>
                        }
                        prodOrClientUid={recommendation.prod_or_client_uid}
                        ownerName={recommendation.client_account_owner}
                      />
                    );
                  }
                } else if (props.for === 'usecase') {
                  return (
                    <UsecaseTaskRecommendationCard
                      type={`${label_use_case.toLowerCase()}s`}
                      key={index}
                      customHeader={true}
                      usecaseOrTaskName={
                        <Typography variant="subtitle1">
                          {label_use_case}
                          <Typography
                            style={{ color: '#2D60F6' }}
                            className="cognisaas-pointer"
                            variant="subtitle1"
                            onClick={() => {
                              navigate(
                                `/projects/project/${recommendation.association_uid}/${recommendation.project_uid}/${recommendation.case_uid}`,
                                {
                                  state: {
                                    clientUid: recommendation.client_uid,
                                    clientName: recommendation.client_name,
                                  },
                                }
                              );
                            }}
                          >
                            {recommendation.case_name}
                          </Typography>
                          delayed by{' '}
                          {formatDuration(
                            intervalToDuration({
                              start: new Date(recommendation.case_requiredbydate.replace(/-/g, '/')),
                              end: new Date(),
                            }),
                            {
                              delimiter: ', ',
                              format: ['years', 'months', 'weeks', 'days'],
                            }
                          )}{' '}
                          and {label_use_case_delivery_manager} is assigned on {recommendation.count} {label_use_case}s
                        </Typography>
                      }
                      // usecaseOrTaskName={recommendation.case_name}
                      deliveryManagerName={recommendation.case_deliverymanager}
                      NumOfProjectsAssignedToManager={recommendation.count}
                      // prodOrClientUid={recommendation.prod_or_client_uid}
                      status={recommendation.case_readinessstate_status}
                      delayedBy={formatDuration(
                        intervalToDuration({
                          start: new Date(recommendation.case_requiredbydate.replace(/-/g, '/')),
                          end: new Date(),
                        }),
                        {
                          delimiter: ', ',
                          format: ['years', 'months', 'weeks', 'days'],
                        }
                      )}
                    />
                  );
                } else if (props.for === 'task') {
                  return (
                    <UsecaseTaskRecommendationCard
                      type={`${label_task.toLowerCase()}s`}
                      key={index}
                      // customHeader={true}
                      // usecaseOrTaskName = {

                      // }
                      usecaseOrTaskName={recommendation.task_name}
                      deliveryManagerName={getUserNameByUserUid(recommendation.task_owner)}
                      NumOfProjectsAssignedToManager={recommendation.count}
                      // prodOrClientUid={recommendation.prod_or_client_uid}
                      status={recommendation.task_status}
                      delayedBy={formatDuration(
                        intervalToDuration({
                          start: new Date(recommendation.task_due_date.replace(/-/g, '/')),
                          end: new Date(),
                        }),
                        {
                          delimiter: ', ',
                          format: ['years', 'months', 'weeks', 'days'],
                        }
                      )}
                    />
                  );
                } else if (props.for === 'project') {
                  return (
                    <UsecaseTaskRecommendationCard
                      type={`${label_project.toLowerCase()}s`}
                      key={index}
                      usecaseOrTaskName={
                        <Typography>
                          {label_project}
                          <Typography
                            style={{ color: '#2D60F6' }}
                            className="cognisaas-pointer"
                            variant="subtitle1"
                            onClick={() => {
                              navigate(
                                `/projects/project/${recommendation.association_uid}/${recommendation.project_uid}`
                              );
                            }}
                          >
                            {recommendation.project_name}
                          </Typography>
                          {label_project.toLowerCase()} is in &apos;{recommendation.project_readinessstate_status}&apos;
                          status
                        </Typography>
                      }
                      // usecaseOrTaskName={`Project ${recommendation.project_name} project is in 'Red' status`}
                      customHeader={true}
                      deliveryManagerName={recommendation.project_deliverymanager}
                      NumOfProjectsAssignedToManager={recommendation.count}
                      // prodOrClientUid={recommendation.prod_or_client_uid}
                      status={recommendation.project_readinessstate_status}
                      delayedBy={formatDuration(
                        intervalToDuration({
                          start: new Date(recommendation.project_requiredbydate.replace(/-/g, '/')),
                          end: new Date(),
                        }),
                        {
                          delimiter: ', ',
                          format: ['years', 'months', 'weeks', 'days'],
                        }
                      )}
                    />
                  );
                }
              })}
            </Stack>
            {/* view more button */}
            {props.for === 'revenueImpact' ? (
              <Typography
                variant="subtitle1"
                className="link-text cognisaas-pointer"
                sx={{ padding: '0px 0px 0px 10px', lineHeight: '3.35' }}
                onClick={handleAddMoreRecommendations}
              >
                View More
              </Typography>
            ) : null}
          </Box>
        </>
      )}
      {updateTaskDrawer && (
        <UpdateTaskDrawer
          client={clientUid}
          taskDetail={taskToOpen}
          drawerState={updateTaskDrawer}
          onClose={() => handleTaskDrawerClose()}
        />
      )}
      {clientMapDrawer && (
        <ProductClientMapPanel
          featureName={selectedFeatureName}
          featureId={selectedFeatureUid}
          drawerState={clientMapDrawer}
          onClose={() => setClientMapDrawer(!clientMapDrawer)}
        ></ProductClientMapPanel>
      )}
    </>
  );
}
