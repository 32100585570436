import { useEffect, useState } from 'react';
import { getProjectMembers } from 'store/modules/Project/slice';
import { useAppDispatch } from '../../../store/hooks';

export const usePreselectedProjectMembers = (projectUid: string, clientUid: string) => {
  const dispatch = useAppDispatch();

  const [preselectedProjectMembers, setPreselectedProjectMembers] = useState<any>([]);
  const [preselectedProjectMemberNameList, setPreselectedProjectMemberNameList] = useState<any>([]);
  const [preselectedClientContacts, setPreselectedClientContacts] = useState<any>([]);
  const [preSelectedCombinedMembers, setPreSelectedCombinedMembers] = useState<any>();

  function getPreselectedProjectMembers(projectUid: string) {
    (async () => {
      const details = {
        project_uid: projectUid as string,
        client_uid: clientUid as string
      };
      await dispatch(getProjectMembers(details))
        .unwrap()
        .then((projectMembers) => {
          if (projectMembers.length > 0) {
            const array: any = [];
            for (let i = 0; i < projectMembers.length; i++) {
              const data: any = {};
              data.id = projectMembers[i].user_uid;
              data.name = projectMembers[i].user_username;
              data.user_uid = projectMembers[i].user_uid;
              data.user_username = projectMembers[i].user_username;
              data.contact_uid = projectMembers[i].user_uid;
              data.contact_name = projectMembers[i].user_username;
              data.is_client_contact = projectMembers[i].is_client_contact;
              data.is_active = projectMembers[i].is_active;
              array.push(data);
            }

            const onlyProjectMembers = array.filter((item: any) => item.is_client_contact === '0');
            const onlyClientContacts = array.filter((item: any) => item.is_client_contact === '1');

            const nameList = onlyProjectMembers.map((item: any) => item.name);

            setPreSelectedCombinedMembers([...array]);
            setPreselectedProjectMembers([...onlyProjectMembers]);
            setPreselectedProjectMemberNameList([...nameList]);
            setPreselectedClientContacts([...onlyClientContacts]);
          }
        });
    })();
  }

  useEffect(() => {
    if (projectUid) {
      getPreselectedProjectMembers(projectUid);
    }
  }, [projectUid]);

  // console.log('usePre', {
  //   projectUid,
  //   preSelectedCombinedMembers,
  //   preselectedClientContacts,
  //   preselectedProjectMembers,
  //   preselectedProjectMemberNameList,
  // });

  return {
    preSelectedCombinedMembers,
    preselectedClientContacts,
    preselectedProjectMembers,
    preselectedProjectMemberNameList,
  };
};
