import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../index';

const selectchartState = (state: RootState) => state.chartsState;

export const selectAllChartsConfig = createSelector(
  selectchartState,
  (allChartsState) => allChartsState.allChartConfigs
);
 
