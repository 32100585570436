import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../';

export const globalSearchSlice = (state: RootState) => state.globalSearchState;

export const selectResultState = createSelector(globalSearchSlice, (globalSearchSlice) => globalSearchSlice.results);
export const selectLoadingStatus = createSelector(
  globalSearchSlice,
  (globalSearchSlice) => globalSearchSlice.isLoading
);
export const selectDataStatus = createSelector(globalSearchSlice, (globalSearchSlice) => globalSearchSlice.showData);
export const selectIsClient = createSelector(globalSearchSlice, (globalSearchSlice) => globalSearchSlice.isClient);
export const selectIsProject = createSelector(globalSearchSlice, (globalSearchSlice) => globalSearchSlice.isProjects);
export const selectIsUseCase = createSelector(globalSearchSlice, (globalSearchSlice) => globalSearchSlice.useCase);
export const selectIsTeamTask = createSelector(globalSearchSlice, (globalSearchSlice) => globalSearchSlice.teamTask);
export const selectIsClientTask = createSelector(
  globalSearchSlice,
  (globalSearchSlice) => globalSearchSlice.clientTask
);
