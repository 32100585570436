import { useEffect, useState } from 'react';
import { Grid, Stack } from '@mui/material';
import CognisaasButton from '../../shared/CognisaasButton/CognisaasButton';
import ContactsTable from './ContactsTable';
import InviteContactModal from './InviteContactModal';
import BasicModal from '../../shared/CognisaasModal/CognisaasModal';
import {
  fetchClientContacts,
  getClientWhitelistDomains,
  removeClientContactByContactUid,
  sendAccessLinkToContact,
} from '../../../store/modules/ManageClient/ClientList/slice';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../store/hooks';
import // selectClientContacts,
// selectCustomerDetail,
'../../../store/modules/ManageClient/ClientList/selector';
// import { Link } from 'react-router-dom';
import { getCustomerUid, getCustomerSubDomain } from 'utils/getUid';
import { userPermissionSchema } from 'components/Reports/SideDrawer/types';
import CognisaasToast from 'components/shared/CognisaasToast/CognisaasToast';
import DeleteModal from 'components/shared/CognisaasModal/DeleteModal';
import { label_client } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { getClientContacts, addClientContacts, deleteClientContact } from 'store/modules/features/client/client';
import { selectClientContacts } from 'store/modules/features/client/selector';
import { useParams } from 'react-router-dom';
import { selectClientDetail } from 'store/modules/features/client/selector';
import CLientContactsTable from './ClientContactsTable';
const SEND_ACCESS_LINK = 'Send access link';

const ClientContacts = (props: any) => {
  const clientContacts = useAppSelector(selectClientContacts);
  const clientDetail = useAppSelector(selectClientDetail);
  const [inviteContactsModal, setInviteContactsModal] = useState<boolean>(false);
  const params = useParams();
  const { clientInfo } = props;
  // const customerDetail = useAppSelector(selectCustomerDetail);
  // const [clientContacts, setClientContacts] = useState<any[]>([]);
  const dispatch = useDispatch();
  // const customerCompanyUrlForContact = `https://${window.location.host}/customer-portal/${customerDetail.customer_url_name}`;
  const [alertDetails, setAlertDetails] = useState<{
    isVisible: boolean;
    type: 'success' | 'failure';
    message: string;
  }>({ isVisible: false, type: 'success', message: '' });
  const [contactUidForDeletion, setContactUidForDeletion] = useState<string>('');
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const deleteClientCotact = async (contact_uid: string, client_uid: string) => {
    const contact = {
      contactId: contact_uid,
      clientId: client_uid,
    };
    dispatch(deleteClientContact(contact));
    // await dispatch(removeClientContactByContactUid(deleteClientContact));
    //
    // await dispatch(fetchClientContacts(client_uid));
  };

  const performThisActionOnClientContact = (rowData: Record<string, any>, action: string) => {
    console.log(action, rowData);
    if (action === 'Delete') {
      setOpenDeleteModal(true);

      setContactUidForDeletion(rowData.id);
      // dispatch(removeClientContactByContactUid(rowData.contact_uid));
      // setTimeout(() => {
      //   dispatch(fetchClientContacts(props.clientUid));
      // }, 1500);
    } else if (action === SEND_ACCESS_LINK) {
      const detail = {
        customer_uid: getCustomerUid(),
        user_uid: rowData.contact_uid,
        user_email: rowData.contact_email,
        base_url: window.location.host + '/customer-portal/' + getCustomerSubDomain() + '/magic-link/',
        client_uid: props.clientUid,
      };
      dispatch(sendAccessLinkToContact(detail));
    }
  };

  useEffect(() => {
    console.log(clientContacts);
    if (!clientContacts.data) {
      dispatch(getClientContacts(params.clientId as string));
    }
    if (clientContacts.data) {
      console.log(clientContacts.data);
    }
  }, [clientContacts]);

  // useEffect(() => {
  //   dispatch(fetchClientContacts(props.clientUid));
  //   dispatch(getClientWhitelistDomains(props.clientUid));
  // }, []);

  // const getClientContacts = useAppSelector(selectClientContacts);

  // useEffect(() => {
  //   if (getClientContacts) {
  //     setClientContacts([...getClientContacts]);
  //   } else {
  //     dispatch(fetchClientContacts(props.clientUid));
  //   }
  // }, [getClientContacts]);

  return (
    <>
      {console.log(clientContacts.data)}
      {clientContacts.data && (
        <>
          <CognisaasToast
            message={alertDetails.message}
            type={alertDetails.type}
            open={alertDetails.isVisible}
            onClose={() => {
              setAlertDetails({ isVisible: false, type: alertDetails.type, message: '' });
            }}
          />
          {clientDetail?.data.user_permissions?.includes(userPermissionSchema.EDIT) && (
            <Stack direction="row" spacing={2} style={{ marginBottom: '10px' }}>
              <CognisaasButton
                clickHandler={() => {
                  setInviteContactsModal(true);
                }}
                isOutlined={true}
                label="Invite Member"
              />
            </Stack>
          )}
          <Grid container>
            <Grid item xs={12} md={12}>
              <CLientContactsTable performThisActionOnClientContact={performThisActionOnClientContact} />
            </Grid>
          </Grid>
          {/* </Grid> */}
          <BasicModal height={430} open={inviteContactsModal} onClose={() => setInviteContactsModal(false)}>
            <InviteContactModal
              clientContacts={clientContacts}
              setInviteContactsModal={setInviteContactsModal}
              inviteContactsModal={inviteContactsModal}
              setAlertDetails={setAlertDetails}
            />
          </BasicModal>
          {/* delete modal */}
          <DeleteModal
            isOpen={openDeleteModal}
            setIsOpen={setOpenDeleteModal}
            message={'Are you sure you want to delete this Client contact'}
            onDelete={() => {
              console.log('DELETE CALL');

              deleteClientCotact(contactUidForDeletion, params.clientId as string);
            }}
          />
        </>
      )}
    </>
  );
};

export default ClientContacts;
