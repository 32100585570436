import { Box, Grid, IconButton, Paper, Stack, Tab, Tabs, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import { ReactComponent as NoRecord } from '../../assets/svgs/no_records.svg';
import { useNavigate } from 'react-router-dom';
import DonutChart from '../shared/Charts/DonutCharts/DonutChart';
import CircleIcon from '@mui/icons-material/Circle';
import CognisaasCard from '../shared/CognisaasCard/CognisaasCard';
import { ReactComponent as NoRecords } from '../../assets/svgs/no_records.svg';

import {
  getAllReporties,
  getAllTeamTasksByUseruidForCustomer,
  getClientDegradationAlert,
  getClientUpgradationAlert,
  getCountByUserUid,
  getProjectDegradationAlert,
  getProjectStatusCountByCustomerUid,
  getProjectUpgradationAlert,
  getTaskStatusCount,
  getTeamClientDegradationAlert,
  getTeamClientUpgradationAlert,
  getTeamCountByUserUid,
  getTeamProjectDegradationAlert,
  getTeamProjectStatusCountByCustomerUid,
  getTeamProjectUpgradationAlert,
  getTeamTaskStatusCount,
  getTeamUseCaseCountUid,
  getTeamUsecaseDegradationAlert,
  getUseCaseCountUid,
  getUsecaseDegradationAlert,
  getUsecaseUpgradationAlert,
  getClientsbyUserTeam,
  getTeamUsecaseUpgradationAlert,
} from '../../store/modules/Dashboard/Dashboard';

import { fetchClientList } from '../../store/modules/ManageClient/ClientList/slice';
import {
  selectClientCount,
  selectClientDegradationAlert,
  selectClientUpgradationAlert,
  selectProjectCount,
  selectProjectDegradationAlert,
  selectProjectUpgradationAlert,
  selectTaskStatus,
  selectUseCaseCount,
  selectUsecaseDegradationAlert,
  selectUsecaseUpgradationAlert,
  selectDashboardStatus,
  selectTeamClientCount,
  selectTeamUseCaseCount,
  selectTeamTaskStatus,
  selectTeamProjectCount,
  selectTeamProjectUpgradationAlert,
  selectTeamClientUpgradationAlert,
  selectTeamUsecaseUpgradationAlert,
  selectTeamProjectDegradationAlert,
  selectTeamClientDegradationAlert,
  selectAllReportees,
  selectAllTeamClients,
  // selectAllTeamTasksLoading,
} from '../../store/modules/Dashboard/selector';
import { selectClientList } from '../../store/modules/ManageClient/ClientList/selector';

import { selectCustomerDetail } from 'store/modules/Common/selector';
import { getAllUsers, getCustomerDetail } from 'store/modules/Common/slice';

import { useAppSelector } from '../../store/hooks';
import { useDispatch } from 'react-redux';
import { usecaseAPI } from '../../api/modules/usecase';
import DeliverablesTimeline from './DeliverablesTimeline/DeliverablesTimeline';
import CognisaaasTooltip from 'components/shared/CognisaasTooltip/CognisaasTooltip';
import { ReactComponent as Info } from '../../assets/svgs/info.svg';

import { getCustomerUid, getLoggedInUserUid } from 'utils/getUid';
import Cookies from 'universal-cookie';
import { requestState } from 'store/modules/types';
import CognisaasLoader from 'components/shared/CognisaasLoader/CognisaasLoader';
import { format } from 'date-fns';
import {
  label_arr,
  label_client,
  label_project,
  label_task,
  label_use_case,
} from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { formatCurrency } from 'utils/dataHelpers';
import { getUserDetails_slice } from 'store/modules/Settings/slice';
import useCommonAppConfig from 'components/Common/useCommonAppConfig';
import {
  intialStateForClient,
  intialStateForProjectHomePage,
  intialStateForTaskReport,
  intialStateForUsecaseReport,
} from './constants';

const cookies = new Cookies();

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function stringToHslColor(str: string, s: number, l: number) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const h = hash % 360;
  return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToHslColor(name, 30, 65),
    },
    children: (
      <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#000000' }}>
        {name.split(' ')[0][0]}
        {name.indexOf(' ') >= 0 ? name.split(' ')[1][0] : null}
      </Typography>
    ),
  };
}

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [value, setValue] = React.useState(0);

  const handleChangeNew = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [value1, setValue1] = React.useState(0);

  const handleChangeNew1 = (event: React.SyntheticEvent, newValue: number) => {
    setValue1(newValue);
  };

  const [totalClients, setTotalClients] = React.useState(0);
  const [totalTask, setTotalTask] = React.useState(0);
  const [totalUseCase, setTotalUseCase] = React.useState(0);
  const [totalProjects, setTotalProjects] = React.useState(0);
  const [filteredClientList, setFilteredClientList] = React.useState<any>([]);
  const [threeClientsToDisplay, setThreeClientsToDisplay] = React.useState<any>([]);
  const [showOnboardNewAccount, setNoDataFound] = React.useState(false);
  const [showSwitchTabForTeamDashboard, setShowSwitchTabForTeamDashboard] = React.useState(false);

  const [valueForTeamDashboard, setValueForTeamDashboard] = React.useState(0);

  const handleChangeForTeamDashboard = (event: React.SyntheticEvent, newValue: number) => {
    setValueForTeamDashboard(newValue);
  };

  useEffect(() => {
    dispatch(getUserDetails_slice((cookies.get('cogni_user_uid') as string) || ''));
  }, []);

  const clientCountApi = useAppSelector(selectClientCount);
  const teamClientCountApi = useAppSelector(selectTeamClientCount);
  const useCaseCountApi = useAppSelector(selectUseCaseCount);
  const teamUseCaseCountApi = useAppSelector(selectTeamUseCaseCount);
  const taskStatusCountApi = useAppSelector(selectTaskStatus);
  const teamTaskStatusCountApi = useAppSelector(selectTeamTaskStatus);
  const projectStatusCountApi = useAppSelector(selectProjectCount);
  const teamProjectStatusCountApi = useAppSelector(selectTeamProjectCount);
  const clientListData = useAppSelector(selectClientList);
  const projectUpgradeAlerts = useAppSelector(selectProjectUpgradationAlert);
  const teamProjectUpgradeAlerts = useAppSelector(selectTeamProjectUpgradationAlert);
  const clientUpgradeAlerts = useAppSelector(selectClientUpgradationAlert);
  const teamClientUpgradeAlerts = useAppSelector(selectTeamClientUpgradationAlert);
  const usecaseUpgradeAlerts = useAppSelector(selectUsecaseUpgradationAlert);
  const teamUseCaseUpgradeAlerts = useAppSelector(selectTeamUsecaseUpgradationAlert);
  const projectDegradeAlerts = useAppSelector(selectProjectDegradationAlert);
  const teamProjectDegradeAlerts = useAppSelector(selectTeamProjectDegradationAlert);
  const clientDegradeAlerts = useAppSelector(selectClientDegradationAlert);
  const teamClientDegradeAlerts = useAppSelector(selectTeamClientDegradationAlert);
  const usecaseDegradeAlerts = useAppSelector(selectUsecaseDegradationAlert);
  const teamUseCaseDegradeAlerts = useAppSelector(selectUsecaseDegradationAlert);
  const customerDetail = useAppSelector(selectCustomerDetail);
  const dashboardStatus = useAppSelector(selectDashboardStatus);
  const allReportes = useAppSelector(selectAllReportees);
  const allTeamclients = useAppSelector(selectAllTeamClients);

  const taskGraphColors: string[] = ['#4390E1', '#E75B5C', '#EFA958', '#4F9F52'];
  const emptyGraphColors: string[] = ['#E5E5E5', '#a2a2a2', '#808080', '#4d4d4d'];

  const { saveAppTableState } = useCommonAppConfig();
  const handleTableSave = (idName: string, tblState: any) => {
    saveAppTableState(idName, tblState);
  };

  useEffect(() => {
    dispatch(getCustomerDetail());
  }, []);

  function isMyDashboardDataPresent(
    clientCountApi: any,
    useCaseCountApi: any,
    projectStatusCountApi: any,
    taskStatusCountApi: any
  ) {
    if (
      clientCountApi == null &&
      useCaseCountApi == null &&
      projectStatusCountApi == null &&
      taskStatusCountApi == null
    ) {
      return false;
    } else {
      return true;
    }
  }

  function isMyTeamDashboardDataPresent() {
    if (cookies.get('showTeamDashboardTab') == 'true') {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    const showMyDashboard = isMyDashboardDataPresent(
      clientCountApi,
      useCaseCountApi,
      projectStatusCountApi,
      taskStatusCountApi
    );
    const showMyTeamDashboard = isMyTeamDashboardDataPresent();

    if (!showMyDashboard && !showMyTeamDashboard) {
      setNoDataFound(true);
    } else if (showMyDashboard == true && showMyTeamDashboard == false) {
      setValueForTeamDashboard(0);
      setShowSwitchTabForTeamDashboard(false);
    } else if (showMyDashboard == false && showMyTeamDashboard == true) {
      setValueForTeamDashboard(1);
      setShowSwitchTabForTeamDashboard(false);
    } else if (showMyDashboard == true && showMyTeamDashboard == true) {
      setShowSwitchTabForTeamDashboard(true);
    }

    if (
      !isMyDashboardDataPresent(clientCountApi, useCaseCountApi, projectStatusCountApi, taskStatusCountApi) &&
      !isMyTeamDashboardDataPresent()
    )
      setNoDataFound(true);
    else {
      setNoDataFound(false);
    }
  }, [
    clientCountApi,
    useCaseCountApi,
    taskStatusCountApi,
    projectStatusCountApi,
    teamClientCountApi,
    teamUseCaseCountApi,
    teamTaskStatusCountApi,
    teamProjectStatusCountApi,
  ]);

  const [dataTaskCountApi, setDataTaskCountApi] = React.useState([
    {
      id: 'Completed',
      label: 'blue',
      value: 99999999999,
      type: 'task',
    },
    {
      id: 'Overdue',
      label: 'red',
      value: 99999999999,
      type: 'task',
    },
    {
      id: 'At risk',
      label: 'amber',
      value: 99999999999,
      type: 'task',
    },
    {
      id: 'On track',
      label: 'green',
      value: 99999999999,
      type: 'task',
    },
  ]);

  const [dataClientCountApi, setDataClientCountApi] = React.useState([
    {
      id: 'Completed',
      label: 'blue',
      value: 99999999999,
      type: 'client',
    },
    {
      id: 'Overdue',
      label: 'red',
      value: 99999999999,
      type: 'client',
    },
    {
      id: 'At risk',
      label: 'amber',
      value: 99999999999,
      type: 'client',
    },
    {
      id: 'On track',
      label: 'green',
      value: 99999999999,
      type: 'client',
    },
  ]);

  const [dataUseCaseApi, setDataUseCaseApi] = React.useState([
    {
      id: 'Completed',
      label: 'blue',
      value: 99999999999,
      type: 'usecase',
    },
    {
      id: 'Overdue',
      label: 'red',
      value: 99999999999,
      type: 'usecase',
    },
    {
      id: 'At risk',
      label: 'amber',
      value: 99999999999,
      type: 'usecase',
    },
    {
      id: 'On track',
      label: 'green',
      value: 99999999999,
      type: 'usecase',
    },
  ]);

  const [dataProjectApi, setDataProjectApi] = React.useState([
    {
      id: 'Completed',
      label: 'blue',
      value: 99999999999,
      type: 'project',
    },
    {
      id: 'Overdue',
      label: 'red',
      value: 99999999999,
      type: 'project',
    },
    {
      id: 'At risk',
      label: 'amber',
      value: 99999999999,
      type: 'project',
    },
    {
      id: 'On track',
      label: 'green',
      value: 99999999999,
      type: 'project',
    },
  ]);

  useEffect(() => {
    if (valueForTeamDashboard == 0) {
      if (taskStatusCountApi)
        setDataTaskCountApi([
          {
            id: 'Completed',
            label: 'blue',
            value: taskStatusCountApi.blue ? taskStatusCountApi.blue : 0,
            type: 'task',
          },
          {
            id: 'Overdue',
            label: 'red',
            value: taskStatusCountApi.red ? taskStatusCountApi.red : 0,
            type: 'task',
          },
          {
            id: 'At risk',
            label: 'amber',
            value: taskStatusCountApi.amber ? taskStatusCountApi.amber : 0,
            type: 'task',
          },
          {
            id: 'On track',
            label: 'green',
            value: taskStatusCountApi.green ? taskStatusCountApi.green : 0,
            type: 'task',
          },
        ]);
      if (taskStatusCountApi == null || Object.keys(taskStatusCountApi).length === 0)
        setDataTaskCountApi([
          {
            id: 'Completed',
            label: 'blue',
            value: 99999999999,
            type: 'task',
          },
          {
            id: 'Overdue',
            label: 'red',
            value: 99999999999,
            type: 'task',
          },
          {
            id: 'At risk',
            label: 'amber',
            value: 99999999999,
            type: 'task',
          },
          {
            id: 'On track',
            label: 'green',
            value: 99999999999,
            type: 'task',
          },
        ]);
      if (clientCountApi)
        setDataClientCountApi([
          {
            id: 'Completed',
            label: 'blue',
            value: clientCountApi.blue ? clientCountApi.blue : 0,
            type: 'client',
          },
          {
            id: 'Overdue',
            label: 'red',
            value: clientCountApi.red ? clientCountApi.red : 0,
            type: 'client',
          },
          {
            id: 'At risk',
            label: 'amber',
            value: clientCountApi.amber ? clientCountApi.amber : 0,
            type: 'client',
          },
          {
            id: 'On track',
            label: 'green',
            value: clientCountApi.green ? clientCountApi.green : 0,
            type: 'client',
          },
        ]);
      if (clientCountApi == null || Object.keys(clientCountApi).length === 0)
        setDataClientCountApi([
          {
            id: 'Completed',
            label: 'blue',
            value: 99999999999,
            type: 'client',
          },
          {
            id: 'Overdue',
            label: 'red',
            value: 99999999999,
            type: 'client',
          },
          {
            id: 'At risk',
            label: 'amber',
            value: 99999999999,
            type: 'client',
          },
          {
            id: 'On track',
            label: 'green',
            value: 99999999999,
            type: 'client',
          },
        ]);
      if (useCaseCountApi)
        setDataUseCaseApi([
          {
            id: 'Completed',
            label: 'blue',
            value: useCaseCountApi.blue ? useCaseCountApi.blue : 0,
            type: 'usecase',
          },
          {
            id: 'Overdue',
            label: 'red',
            value: useCaseCountApi.red ? useCaseCountApi.red : 0,
            type: 'usecase',
          },
          {
            id: 'At risk',
            label: 'amber',
            value: useCaseCountApi.amber ? useCaseCountApi.amber : 0,
            type: 'usecase',
          },
          {
            id: 'On track',
            label: 'green',
            value: useCaseCountApi.green ? useCaseCountApi.green : 0,
            type: 'usecase',
          },
        ]);
      if (useCaseCountApi == null || Object.keys(useCaseCountApi).length === 0)
        setDataUseCaseApi([
          {
            id: 'Completed',
            label: 'blue',
            value: 99999999999,
            type: 'usecase',
          },
          {
            id: 'Overdue',
            label: 'red',
            value: 99999999999,
            type: 'usecase',
          },
          {
            id: 'At risk',
            label: 'amber',
            value: 99999999999,
            type: 'usecase',
          },
          {
            id: 'On track',
            label: 'green',
            value: 99999999999,
            type: 'usecase',
          },
        ]);
      if (projectStatusCountApi) {
        setDataProjectApi([
          {
            id: 'Completed',
            label: 'blue',
            value: projectStatusCountApi.blue ? projectStatusCountApi.blue : 0,
            type: 'project',
          },
          {
            id: 'Overdue',
            label: 'red',
            value: projectStatusCountApi.red ? projectStatusCountApi.red : 0,
            type: 'project',
          },
          {
            id: 'At risk',
            label: 'amber',
            value: projectStatusCountApi.amber ? projectStatusCountApi.amber : 0,
            type: 'project',
          },
          {
            id: 'On track',
            label: 'green',
            value: projectStatusCountApi.green ? projectStatusCountApi.green : 0,
            type: 'project',
          },
        ]);
        if (projectStatusCountApi == null || Object.keys(projectStatusCountApi).length === 0) {
          setDataProjectApi([
            {
              id: 'Completed',
              label: 'blue',
              value: 99999999999,
              type: 'project',
            },
            {
              id: 'Overdue',
              label: 'red',
              value: 99999999999,
              type: 'project',
            },
            {
              id: 'At risk',
              label: 'amber',
              value: 99999999999,
              type: 'project',
            },
            {
              id: 'On track',
              label: 'green',
              value: 99999999999,
              type: 'project',
            },
          ]);
        }
      }
    }
    if (valueForTeamDashboard == 1) {
      if (teamTaskStatusCountApi)
        setDataTaskCountApi([
          {
            id: 'Completed',
            label: 'blue',
            value: teamTaskStatusCountApi.blue ? teamTaskStatusCountApi.blue : 0,
            type: 'task',
          },
          {
            id: 'Overdue',
            label: 'red',
            value: teamTaskStatusCountApi.red ? teamTaskStatusCountApi.red : 0,
            type: 'task',
          },
          {
            id: 'At risk',
            label: 'amber',
            value: teamTaskStatusCountApi.amber ? teamTaskStatusCountApi.amber : 0,
            type: 'task',
          },
          {
            id: 'On track',
            label: 'green',
            value: teamTaskStatusCountApi.green ? teamTaskStatusCountApi.green : 0,
            type: 'task',
          },
        ]);

      if (teamTaskStatusCountApi == null || Object.keys(teamTaskStatusCountApi).length === 0)
        setDataTaskCountApi([
          {
            id: 'Completed',
            label: 'blue',
            value: 99999999999,
            type: 'task',
          },
          {
            id: 'Overdue',
            label: 'red',
            value: 99999999999,
            type: 'task',
          },
          {
            id: 'At risk',
            label: 'amber',
            value: 99999999999,
            type: 'task',
          },
          {
            id: 'On track',
            label: 'green',
            value: 99999999999,
            type: 'task',
          },
        ]);
      if (teamClientCountApi)
        setDataClientCountApi([
          {
            id: 'Completed',
            label: 'blue',
            value: teamClientCountApi.blue ? teamClientCountApi.blue : 0,
            type: 'client',
          },
          {
            id: 'Overdue',
            label: 'red',
            value: teamClientCountApi.red ? teamClientCountApi.red : 0,
            type: 'client',
          },
          {
            id: 'At risk',
            label: 'amber',
            value: teamClientCountApi.amber ? teamClientCountApi.amber : 0,
            type: 'client',
          },
          {
            id: 'On track',
            label: 'green',
            value: teamClientCountApi.green ? teamClientCountApi.green : 0,
            type: 'client',
          },
        ]);

      if (teamClientCountApi == null || Object.keys(teamClientCountApi).length === 0)
        setDataClientCountApi([
          {
            id: 'Completed',
            label: 'blue',
            value: 99999999999,
            type: 'client',
          },
          {
            id: 'Overdue',
            label: 'red',
            value: 99999999999,
            type: 'client',
          },
          {
            id: 'At risk',
            label: 'amber',
            value: 99999999999,
            type: 'client',
          },
          {
            id: 'On track',
            label: 'green',
            value: 99999999999,
            type: 'client',
          },
        ]);

      if (teamUseCaseCountApi)
        setDataUseCaseApi([
          {
            id: 'Completed',
            label: 'blue',
            value: teamUseCaseCountApi.blue ? teamUseCaseCountApi.blue : 0,
            type: 'usecase',
          },
          {
            id: 'Overdue',
            label: 'red',
            value: teamUseCaseCountApi.red ? teamUseCaseCountApi.red : 0,
            type: 'usecase',
          },
          {
            id: 'At risk',
            label: 'amber',
            value: teamUseCaseCountApi.amber ? teamUseCaseCountApi.amber : 0,
            type: 'usecase',
          },
          {
            id: 'On track',
            label: 'green',
            value: teamUseCaseCountApi.green ? teamUseCaseCountApi.green : 0,
            type: 'usecase',
          },
        ]);

      if (teamUseCaseCountApi == null || Object.keys(teamUseCaseCountApi).length === 0)
        setDataUseCaseApi([
          {
            id: 'Completed',
            label: 'blue',
            value: 99999999999,
            type: 'usecase',
          },
          {
            id: 'Overdue',
            label: 'red',
            value: 99999999999,
            type: 'usecase',
          },
          {
            id: 'At risk',
            label: 'amber',
            value: 99999999999,
            type: 'usecase',
          },
          {
            id: 'On track',
            label: 'green',
            value: 99999999999,
            type: 'usecase',
          },
        ]);

      if (teamProjectStatusCountApi) {
        setDataProjectApi([
          {
            id: 'Completed',
            label: 'blue',
            value: teamProjectStatusCountApi.blue ? teamProjectStatusCountApi.blue : 0,
            type: 'project',
          },
          {
            id: 'Overdue',
            label: 'red',
            value: teamProjectStatusCountApi.red ? teamProjectStatusCountApi.red : 0,
            type: 'project',
          },
          {
            id: 'At risk',
            label: 'amber',
            value: teamProjectStatusCountApi.amber ? teamProjectStatusCountApi.amber : 0,
            type: 'project',
          },
          {
            id: 'On track',
            label: 'green',
            value: teamProjectStatusCountApi.green ? teamProjectStatusCountApi.green : 0,
            type: 'project',
          },
        ]);

        if (teamProjectStatusCountApi == null || Object.keys(teamProjectStatusCountApi).length === 0) {
          setDataProjectApi([
            {
              id: 'Completed',
              label: 'blue',
              value: 99999999999,
              type: 'project',
            },
            {
              id: 'Overdue',
              label: 'red',
              value: 99999999999,
              type: 'project',
            },
            {
              id: 'At risk',
              label: 'amber',
              value: 99999999999,
              type: 'project',
            },
            {
              id: 'On track',
              label: 'green',
              value: 99999999999,
              type: 'project',
            },
          ]);
        }
      }
    }
  }, [
    taskStatusCountApi,
    clientCountApi,
    usecaseAPI,
    projectStatusCountApi,
    teamTaskStatusCountApi,
    teamClientCountApi,
    teamUseCaseCountApi,
    teamProjectStatusCountApi,
    valueForTeamDashboard,
  ]);

  const detail = {
    customer_uid: getCustomerUid(),
    user_uid: getLoggedInUserUid(),
  };
  useEffect(() => {
    dispatch(getProjectStatusCountByCustomerUid(detail));
    dispatch(fetchClientList());
    dispatch(getAllReporties());
    dispatch(getCountByUserUid());
    dispatch(getUseCaseCountUid());
    dispatch(getTaskStatusCount());
    dispatch(getClientUpgradationAlert());
    dispatch(getUsecaseUpgradationAlert());
    dispatch(getProjectUpgradationAlert());
    dispatch(getClientDegradationAlert());
    dispatch(getUsecaseDegradationAlert());
    dispatch(getProjectDegradationAlert());
    dispatch(getTeamCountByUserUid());
    dispatch(getTeamUseCaseCountUid());
    dispatch(getTeamTaskStatusCount());
    dispatch(getTeamClientUpgradationAlert());
    dispatch(getTeamProjectUpgradationAlert());
    dispatch(getTeamUsecaseUpgradationAlert());
    dispatch(getTeamClientDegradationAlert());
    dispatch(getTeamUsecaseDegradationAlert());
    dispatch(getTeamProjectDegradationAlert());
    dispatch(getTeamProjectStatusCountByCustomerUid(detail));
    dispatch(getClientsbyUserTeam(detail));
    dispatch(getAllTeamTasksByUseruidForCustomer(detail));
  }, []);

  // useEffect(() => {
  //   if (valueForTeamDashboard == 0) {
  //     dispatch(getCountByUserUid());
  //     dispatch(getUseCaseCountUid());
  //     dispatch(getTaskStatusCount());
  //     dispatch(getClientUpgradationAlert());
  //     dispatch(getUsecaseUpgradationAlert());
  //     dispatch(getProjectUpgradationAlert());
  //     dispatch(getClientDegradationAlert());
  //     dispatch(getUsecaseDegradationAlert());
  //     dispatch(getProjectDegradationAlert());
  //   }
  //   if (valueForTeamDashboard == 1) {
  //     dispatch(getTeamCountByUserUid());
  //     dispatch(getTeamUseCaseCountUid());
  //     dispatch(getTeamTaskStatusCount());
  //     dispatch(getTeamClientUpgradationAlert());
  //     dispatch(getTeamProjectUpgradationAlert());
  //     dispatch(getTeamUsecaseUpgradationAlert());
  //     dispatch(getTeamClientDegradationAlert());
  //     dispatch(getTeamUsecaseDegradationAlert());
  //     dispatch(getTeamProjectDegradationAlert());
  //     dispatch(getTeamProjectStatusCountByCustomerUid(detail));
  //     dispatch(getClientsbyUserTeam(detail));
  //     dispatch(getAllTeamTasksByUseruidForCustomer(detail));
  //   }
  // }, [valueForTeamDashboard]);

  const setTotalClientsCount = () => {
    let tempValue = 0;
    if (clientCountApi?.blue > 0) tempValue = tempValue + Number(clientCountApi.blue);
    if (clientCountApi?.red > 0) tempValue = tempValue + Number(clientCountApi.red);
    if (clientCountApi?.amber > 0) tempValue = tempValue + Number(clientCountApi.amber);
    if (clientCountApi?.green > 0) tempValue = tempValue + Number(clientCountApi.green);
    setTotalClients(tempValue);
  };

  const setTotalTeamClientsCount = () => {
    let tempValue = 0;
    if (teamClientCountApi?.blue > 0) tempValue = tempValue + Number(teamClientCountApi.blue);
    if (teamClientCountApi?.red > 0) tempValue = tempValue + Number(teamClientCountApi.red);
    if (teamClientCountApi?.amber > 0) tempValue = tempValue + Number(teamClientCountApi.amber);
    if (teamClientCountApi?.green > 0) tempValue = tempValue + Number(teamClientCountApi.green);
    setTotalClients(tempValue);
  };

  useEffect(() => {
    if (valueForTeamDashboard == 0) setTotalClientsCount();
    if (valueForTeamDashboard == 1) setTotalTeamClientsCount();
  }, [clientCountApi, teamClientCountApi, valueForTeamDashboard]);

  const setTotalTaskCount = () => {
    let tempValue = 0;
    if (taskStatusCountApi?.blue > 0) tempValue = tempValue + Number(taskStatusCountApi.blue);
    if (taskStatusCountApi?.red > 0) tempValue = tempValue + Number(taskStatusCountApi.red);
    if (taskStatusCountApi?.amber > 0) tempValue = tempValue + Number(taskStatusCountApi.amber);
    if (taskStatusCountApi?.green > 0) tempValue = tempValue + Number(taskStatusCountApi.green);
    setTotalTask(tempValue);
  };

  const setTotalTeamTaskCount = () => {
    let tempValue = 0;
    if (teamTaskStatusCountApi?.blue > 0) tempValue = tempValue + Number(teamTaskStatusCountApi.blue);
    if (teamTaskStatusCountApi?.red > 0) tempValue = tempValue + Number(teamTaskStatusCountApi.red);
    if (teamTaskStatusCountApi?.amber > 0) tempValue = tempValue + Number(teamTaskStatusCountApi.amber);
    if (teamTaskStatusCountApi?.green > 0) tempValue = tempValue + Number(teamTaskStatusCountApi.green);
    setTotalTask(tempValue);
  };

  useEffect(() => {
    if (valueForTeamDashboard == 0) setTotalTaskCount();
    if (valueForTeamDashboard == 1) setTotalTeamTaskCount();
  }, [taskStatusCountApi, teamTaskStatusCountApi, valueForTeamDashboard]);

  const setTotalUseCaseCount = () => {
    let tempValue = 0;
    if (useCaseCountApi?.blue > 0) tempValue = tempValue + Number(useCaseCountApi.blue);
    if (useCaseCountApi?.red > 0) tempValue = tempValue + Number(useCaseCountApi.red);
    if (useCaseCountApi?.amber > 0) tempValue = tempValue + Number(useCaseCountApi.amber);
    if (useCaseCountApi?.green > 0) tempValue = tempValue + Number(useCaseCountApi.green);
    setTotalUseCase(tempValue);
  };

  const setTotalTeamUseCaseCount = () => {
    let tempValue = 0;
    if (teamUseCaseCountApi?.blue > 0) tempValue = tempValue + Number(teamUseCaseCountApi.blue);
    if (teamUseCaseCountApi?.red > 0) tempValue = tempValue + Number(teamUseCaseCountApi.red);
    if (teamUseCaseCountApi?.amber > 0) tempValue = tempValue + Number(teamUseCaseCountApi.amber);
    if (teamUseCaseCountApi?.green > 0) tempValue = tempValue + Number(teamUseCaseCountApi.green);
    setTotalUseCase(tempValue);
  };

  useEffect(() => {
    if (valueForTeamDashboard == 0) setTotalUseCaseCount();
    if (valueForTeamDashboard == 1) setTotalTeamUseCaseCount();
  }, [useCaseCountApi, teamUseCaseCountApi, valueForTeamDashboard]);

  const setTotalProjectsCount = () => {
    let tempValue = 0;
    if (projectStatusCountApi?.blue > 0) tempValue = tempValue + Number(projectStatusCountApi.blue);
    if (projectStatusCountApi?.red > 0) tempValue = tempValue + Number(projectStatusCountApi.red);
    if (projectStatusCountApi?.amber > 0) tempValue = tempValue + Number(projectStatusCountApi.amber);
    if (projectStatusCountApi?.green > 0) tempValue = tempValue + Number(projectStatusCountApi.green);
    setTotalProjects(tempValue);
  };

  const setTotalTeamProjectsCount = () => {
    let tempValue = 0;
    if (teamProjectStatusCountApi?.blue > 0) tempValue = tempValue + Number(teamProjectStatusCountApi.blue);
    if (teamProjectStatusCountApi?.red > 0) tempValue = tempValue + Number(teamProjectStatusCountApi.red);
    if (teamProjectStatusCountApi?.amber > 0) tempValue = tempValue + Number(teamProjectStatusCountApi.amber);
    if (teamProjectStatusCountApi?.green > 0) tempValue = tempValue + Number(teamProjectStatusCountApi.green);
    setTotalProjects(tempValue);
  };

  useEffect(() => {
    if (valueForTeamDashboard == 0) setTotalProjectsCount();
    if (valueForTeamDashboard == 1) setTotalTeamProjectsCount();
  }, [projectStatusCountApi, teamProjectStatusCountApi, valueForTeamDashboard]);

  useEffect(() => {
    if (valueForTeamDashboard == 0) {
      setFilteredClientList(
        clientListData?.filter((ele: any) => {
          if (ele.user_uid === cookies.get('cogni_user_uid') && ele.client_status == 1) return ele;
        })
      );
    }
    if (valueForTeamDashboard == 1) {
      setFilteredClientList(
        allTeamclients?.filter((ele: any) => {
          if (ele.client_status == 1) return ele;
        })
      );
    }
  }, [clientListData, allTeamclients, valueForTeamDashboard]);

  useEffect(() => {
    arrangeTasksBasedOnPriority(filteredClientList);
  }, [filteredClientList]);

  const arrangeTasksBasedOnPriority = (tasks: any) => {
    if (tasks?.length > 0) {
      const redTasks = tasks?.filter((task: any) => {
        return task.client_readiness_status == 'red';
      });

      const amberTasks = tasks?.filter((task: any) => {
        return task.client_readiness_status == 'amber';
      });

      const greenTasks = tasks?.filter((task: any) => {
        return task.task_stage == 'blue';
      });

      const blueTasks = tasks?.filter((task: any) => {
        return task.task_stage == 'green';
      });

      const orderedTasks = [...redTasks, ...amberTasks, ...greenTasks, ...blueTasks];
      const threeTasks = orderedTasks.filter((ele, i) => i < 3);
      setThreeClientsToDisplay(threeTasks);
      return;
    } else setThreeClientsToDisplay([]);
  };

  const CenteredTaskMetric = ({ centerX, centerY }: any) => {
    return (
      <>
        <text
          x={centerX}
          y={centerY - 6}
          textAnchor="middle"
          fill="#7A7A7A"
          dominantBaseline="central"
          style={{
            fontSize: '14px',
            fontWeight: 400,
            fontFamily: 'Noto Sans',
          }}
        >
          {label_task}s
        </text>
        <text
          x={centerX}
          y={centerY + 20}
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            fontSize: '16px',
            fontWeight: 400,
            fontFamily: 'Noto Sans',
          }}
        >
          {totalTask}
        </text>
      </>
    );
  };

  const CenteredUsecaseMetric = ({ centerX, centerY }: any) => {
    return (
      <>
        <text
          x={centerX}
          y={centerY - 6}
          textAnchor="middle"
          fill="#7A7A7A"
          dominantBaseline="central"
          style={{
            fontSize: '14px',
            fontWeight: 400,
            fontFamily: 'Noto Sans',
          }}
        >
          {label_use_case}s
        </text>
        <text
          x={centerX}
          y={centerY + 20}
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            fontSize: '16px',
            fontWeight: 400,
            fontFamily: 'Noto Sans',
          }}
        >
          {totalUseCase}
        </text>
      </>
    );
  };

  const CenteredClientMetric = ({ centerX, centerY }: any) => {
    return (
      <>
        <text
          x={centerX}
          y={centerY - 6}
          textAnchor="middle"
          fill="#7A7A7A"
          dominantBaseline="central"
          style={{
            fontSize: '14px',
            fontWeight: 400,
            fontFamily: 'Noto Sans',
          }}
        >
          {label_client}s
        </text>
        <text
          x={centerX}
          y={centerY + 20}
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            fontSize: '16px',
            fontWeight: 400,
            fontFamily: 'Noto Sans',
          }}
        >
          {totalClients}
        </text>
      </>
    );
  };

  const CenteredProjectMetric = ({ centerX, centerY }: any) => {
    return (
      <>
        <text
          x={centerX}
          y={centerY - 6}
          textAnchor="middle"
          fill="#7A7A7A"
          dominantBaseline="central"
          style={{
            fontSize: '14px',
            fontWeight: 400,
            fontFamily: 'Noto Sans',
          }}
        >
          {label_project}s
        </text>
        <text
          x={centerX}
          y={centerY + 20}
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            fontSize: '16px',
            fontWeight: 400,
            fontFamily: 'Noto Sans',
          }}
        >
          {totalProjects}
        </text>
      </>
    );
  };

  const handleChartClick = async (data: any, chartType: 'client' | 'project' | 'usecase' | 'task') => {
    const username = cookies.get('user_name');
    if (valueForTeamDashboard == 0) {
      const payload = Object.assign(data, { user: username, task_owner: username, task_status: data?.label });
      if (chartType === 'client') {
        handleTableSave('clientListPage', intialStateForClient);
        navigate('/client-list', { state: payload });
      } else if (chartType === 'project') {
        handleTableSave('projectListTablePage', intialStateForProjectHomePage);
        navigate('/projects', { state: payload });
      } else if (chartType === 'usecase') {
        handleTableSave('projectReportAllUsecasePage', intialStateForUsecaseReport);
        navigate('/reports/Usecases', { state: payload });
      } else if (chartType === 'task') {
        handleTableSave('projectReportAllTasksPage', intialStateForTaskReport);
        navigate('/reports/Tasks', { state: payload });
      }
    }
    if (valueForTeamDashboard == 1) {
      const payload = Object.assign(data, {
        user: username,
        task_owner: username,
        task_status: data?.label,
        reportees: allReportes,
      });
      if (chartType === 'client') {
        handleTableSave('clientListPage', intialStateForClient);
        await dispatch(getAllUsers());
        navigate('/client-list', { state: payload });
      } else if (chartType === 'project') {
        handleTableSave('projectListTablePage', intialStateForProjectHomePage);
        navigate('/projects', { state: payload });
      } else if (chartType === 'usecase') {
        handleTableSave('projectReportAllUsecasePage', intialStateForUsecaseReport);
        navigate('/reports/Usecases', { state: payload });
      } else if (chartType === 'task') {
        handleTableSave('projectReportAllTasksPage', intialStateForTaskReport);
        navigate('/reports/Tasks', { state: payload });
      }
    }
  };

  return dashboardStatus === requestState.loading ? (
    <CognisaasLoader />
  ) : showOnboardNewAccount == true ? (
    <Stack style={{ marginTop: '32px' }} justifyContent={'center'} alignItems={'center'}>
      <Typography variant="h5">Nothing to show!</Typography>
      <NoRecords style={{ width: '300px', height: '300px' }} />
    </Stack>
  ) : (
    <Box sx={{ padding: '20px' }}>
      {showSwitchTabForTeamDashboard ? (
        <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '24%' }}>
          <Tabs value={valueForTeamDashboard} onChange={handleChangeForTeamDashboard} aria-label="basic tabs example">
            <Tab label="My Dashboard" {...a11yProps(0)} />
            <Tab label="My Team Dashboard" {...a11yProps(1)} />
          </Tabs>
        </Box>
      ) : null}
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography sx={{ fontSize: '18px', fontWeight: '500', mt: '12px' }}>
            Hello, {cookies.get('user_name')}! Welcome to your
            {cookies.get('showTeamDashboardTab') == 'true' ? ' Team' : null} Dashboard
          </Typography>
        </Grid>
        <Grid item></Grid>
      </Grid>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ height: '236px', mt: '12px' }}>
          <Paper
            // elevation={0}
            style={{
              padding: '20px',
              maxHeight: '236px',
              boxShadow: '0px 4px 26px rgba(0, 0, 0, 0.05)',
              backgroundColor: '#FFFFFF',
            }}
          >
            <Grid container justifyContent="space-between" sx={{ marginBottom: '25px' }}>
              <Grid item>
                <Typography sx={{ fontSize: '18px', fontWeight: '500' }}>Status Overview</Typography>
              </Grid>
              <Grid item>
                <Stack direction="row">
                  <CircleIcon fontSize="small" sx={{ mt: '7px', height: '12px', width: '12px', color: '#4390E1' }} />
                  <Typography sx={{ ml: '4px' }}>Completed</Typography>
                  <CircleIcon
                    fontSize="small"
                    sx={{ mt: '7px', height: '12px', width: '12px', color: '#E75B5C', marginLeft: '24px' }}
                  />

                  <Typography sx={{ ml: '4px' }}>Overdue</Typography>
                  <CircleIcon
                    fontSize="small"
                    sx={{ mt: '7px', height: '12px', width: '12px', color: '#EFA958', marginLeft: '24px' }}
                  />
                  <Typography sx={{ ml: '4px' }}>At Risk</Typography>
                  <CircleIcon
                    fontSize="small"
                    sx={{ mt: '7px', height: '12px', width: '12px', color: '#4F9F52', marginLeft: '24px' }}
                  />
                  <Typography sx={{ ml: '4px' }}>On Track</Typography>
                </Stack>
              </Grid>
            </Grid>
            <Grid container spacing={6}>
              <Grid item xs={3}>
                <Grid container>
                  <Grid item xs={6}>
                    <DonutChart
                      height={200}
                      width={200}
                      data={dataTaskCountApi}
                      dataConfig={{
                        idKey: 'id',
                        dataKey: 'value',
                      }}
                      colors={
                        valueForTeamDashboard == 0
                          ? taskStatusCountApi == null || Object.keys(taskStatusCountApi).length === 0
                            ? emptyGraphColors
                            : taskGraphColors
                          : teamTaskStatusCountApi == null || Object.keys(teamTaskStatusCountApi).length === 0
                          ? emptyGraphColors
                          : taskGraphColors
                      }
                      legend="none"
                      onClick={(data: any) => handleChartClick(data, 'task')}
                      layers={['arcs', 'arcLabels', 'arcLinkLabels', 'legends', CenteredTaskMetric]}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Grid
                      container
                      spacing={0}
                      alignItems="center"
                      justifyContent="center"
                      sx={{ paddingTop: '30px', paddingLeft: '20px' }}
                    >
                      <Grid item xs={6}>
                        <Stack direction="row">
                          <Box
                            sx={{
                              backgroundColor: '#4390E1',
                              height: '17px',
                              width: '4px',
                              mt: '5px',
                              borderRadius: '4px',
                            }}
                          />
                          <Typography sx={{ mt: '1px', ml: '5px' }}>
                            {valueForTeamDashboard == 0
                              ? taskStatusCountApi?.blue > 0
                                ? taskStatusCountApi?.blue
                                : 0
                              : teamTaskStatusCountApi?.blue > 0
                              ? teamTaskStatusCountApi?.blue
                              : 0}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={6}>
                        <Stack direction="row">
                          <Box
                            sx={{
                              backgroundColor: '#E75B5C',
                              height: '17px',
                              width: '4px',
                              mt: '5px',
                              borderRadius: '4px',
                            }}
                          />
                          <Typography sx={{ mt: '1px', ml: '5px' }}>
                            {valueForTeamDashboard == 0
                              ? taskStatusCountApi?.red > 0
                                ? taskStatusCountApi?.red
                                : 0
                              : teamTaskStatusCountApi?.red > 0
                              ? teamTaskStatusCountApi?.red
                              : 0}
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      spacing={0}
                      alignItems="center"
                      justifyContent="center"
                      sx={{ paddingTop: '20px', paddingLeft: '20px' }}
                    >
                      <Grid item xs={6}>
                        <Stack direction="row">
                          <Box
                            sx={{
                              backgroundColor: '#EFA958',
                              height: '17px',
                              width: '4px',
                              mt: '5px',
                              borderRadius: '4px',
                            }}
                          />
                          <Typography sx={{ mt: '1px', ml: '5px' }}>
                            {valueForTeamDashboard == 0
                              ? taskStatusCountApi?.amber > 0
                                ? taskStatusCountApi?.amber
                                : 0
                              : teamTaskStatusCountApi?.amber > 0
                              ? teamTaskStatusCountApi?.amber
                              : 0}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={6}>
                        <Stack direction="row">
                          <Box
                            sx={{
                              backgroundColor: '#4F9F52',
                              height: '17px',
                              width: '4px',
                              mt: '5px',
                              borderRadius: '4px',
                            }}
                          />
                          <Typography sx={{ mt: '1px', ml: '5px' }}>
                            {valueForTeamDashboard == 0
                              ? taskStatusCountApi?.green > 0
                                ? taskStatusCountApi?.green
                                : 0
                              : teamTaskStatusCountApi?.green > 0
                              ? teamTaskStatusCountApi?.green
                              : 0}
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Grid container>
                  <Grid item xs={6}>
                    <DonutChart
                      height={200}
                      width={200}
                      data={dataUseCaseApi}
                      dataConfig={{
                        idKey: 'id',
                        dataKey: 'value',
                      }}
                      colors={
                        valueForTeamDashboard == 0
                          ? useCaseCountApi == null || Object.keys(useCaseCountApi).length === 0
                            ? emptyGraphColors
                            : taskGraphColors
                          : teamUseCaseCountApi == null || Object.keys(teamUseCaseCountApi).length === 0
                          ? emptyGraphColors
                          : taskGraphColors
                      }
                      legend="none"
                      onClick={(data: any) => handleChartClick(data, 'usecase')}
                      layers={['arcs', 'arcLabels', 'arcLinkLabels', 'legends', CenteredUsecaseMetric]}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Grid
                      container
                      spacing={0}
                      alignItems="center"
                      justifyContent="center"
                      sx={{ paddingTop: '30px', paddingLeft: '20px' }}
                    >
                      <Grid item xs={6}>
                        <Stack direction="row">
                          <Box
                            sx={{
                              backgroundColor: '#4390E1',
                              height: '17px',
                              width: '4px',
                              mt: '5px',
                              borderRadius: '4px',
                            }}
                          />
                          <Typography sx={{ mt: '1px', ml: '5px' }}>
                            {valueForTeamDashboard == 0
                              ? useCaseCountApi?.blue > 0
                                ? useCaseCountApi?.blue
                                : 0
                              : teamUseCaseCountApi?.blue > 0
                              ? teamUseCaseCountApi?.blue
                              : 0}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={6}>
                        <Stack direction="row">
                          <Box
                            sx={{
                              backgroundColor: '#E75B5C',
                              height: '17px',
                              width: '4px',
                              mt: '5px',
                              borderRadius: '4px',
                            }}
                          />
                          <Typography sx={{ mt: '1px', ml: '5px' }}>
                            {valueForTeamDashboard == 0
                              ? useCaseCountApi?.red > 0
                                ? useCaseCountApi?.red
                                : 0
                              : teamUseCaseCountApi?.red > 0
                              ? teamUseCaseCountApi?.red
                              : 0}
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      spacing={0}
                      alignItems="center"
                      justifyContent="center"
                      sx={{ paddingTop: '20px', paddingLeft: '20px' }}
                    >
                      <Grid item xs={6}>
                        <Stack direction="row">
                          <Box
                            sx={{
                              backgroundColor: '#EFA958',
                              height: '17px',
                              width: '4px',
                              mt: '5px',
                              borderRadius: '4px',
                            }}
                          />
                          <Typography sx={{ mt: '1px', ml: '5px' }}>
                            {valueForTeamDashboard == 0
                              ? useCaseCountApi?.amber > 0
                                ? useCaseCountApi?.amber
                                : 0
                              : teamUseCaseCountApi?.amber > 0
                              ? teamUseCaseCountApi?.amber
                              : 0}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={6}>
                        <Stack direction="row">
                          <Box
                            sx={{
                              backgroundColor: '#4F9F52',
                              height: '17px',
                              width: '4px',
                              mt: '5px',
                              borderRadius: '4px',
                            }}
                          />
                          <Typography sx={{ mt: '1px', ml: '5px' }}>
                            {valueForTeamDashboard == 0
                              ? useCaseCountApi?.green > 0
                                ? useCaseCountApi?.green
                                : 0
                              : teamUseCaseCountApi?.green > 0
                              ? teamUseCaseCountApi?.green
                              : 0}
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Grid container>
                  <Grid item xs={6}>
                    <DonutChart
                      height={200}
                      width={200}
                      data={dataProjectApi}
                      dataConfig={{
                        idKey: 'id',
                        dataKey: 'value',
                      }}
                      colors={
                        valueForTeamDashboard == 0
                          ? projectStatusCountApi == null || Object.keys(projectStatusCountApi).length === 0
                            ? emptyGraphColors
                            : taskGraphColors
                          : teamProjectStatusCountApi == null || Object.keys(teamProjectStatusCountApi).length === 0
                          ? emptyGraphColors
                          : taskGraphColors
                      }
                      legend="none"
                      onClick={(data: any) => handleChartClick(data, 'project')}
                      layers={['arcs', 'arcLabels', 'arcLinkLabels', 'legends', CenteredProjectMetric]}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Grid
                      container
                      spacing={0}
                      alignItems="center"
                      justifyContent="center"
                      sx={{ paddingTop: '30px', paddingLeft: '20px' }}
                    >
                      <Grid item xs={6}>
                        <Stack direction="row">
                          <Box
                            sx={{
                              backgroundColor: '#4390E1',
                              height: '17px',
                              width: '4px',
                              mt: '5px',
                              borderRadius: '4px',
                            }}
                          />
                          <Typography sx={{ mt: '1px', ml: '5px' }}>
                            {valueForTeamDashboard == 0
                              ? projectStatusCountApi?.blue > 0
                                ? projectStatusCountApi?.blue
                                : 0
                              : teamProjectStatusCountApi?.blue > 0
                              ? teamProjectStatusCountApi?.blue
                              : 0}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={6}>
                        <Stack direction="row">
                          <Box
                            sx={{
                              backgroundColor: '#E75B5C',
                              height: '17px',
                              width: '4px',
                              mt: '5px',
                              borderRadius: '4px',
                            }}
                          />
                          <Typography sx={{ mt: '1px', ml: '5px' }}>
                            {valueForTeamDashboard == 0
                              ? projectStatusCountApi?.red > 0
                                ? projectStatusCountApi?.red
                                : 0
                              : teamProjectStatusCountApi?.red > 0
                              ? teamProjectStatusCountApi?.red
                              : 0}
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      spacing={0}
                      alignItems="center"
                      justifyContent="center"
                      sx={{ paddingTop: '20px', paddingLeft: '20px' }}
                    >
                      <Grid item xs={6}>
                        <Stack direction="row">
                          <Box
                            sx={{
                              backgroundColor: '#EFA958',
                              height: '17px',
                              width: '4px',
                              mt: '5px',
                              borderRadius: '4px',
                            }}
                          />
                          <Typography sx={{ mt: '1px', ml: '5px' }}>
                            {valueForTeamDashboard == 0
                              ? projectStatusCountApi?.amber > 0
                                ? projectStatusCountApi?.amber
                                : 0
                              : teamProjectStatusCountApi?.amber > 0
                              ? teamProjectStatusCountApi?.amber
                              : 0}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={6}>
                        <Stack direction="row">
                          <Box
                            sx={{
                              backgroundColor: '#4F9F52',
                              height: '17px',
                              width: '4px',
                              mt: '5px',
                              borderRadius: '4px',
                            }}
                          />
                          <Typography sx={{ mt: '1px', ml: '5px' }}>
                            {valueForTeamDashboard == 0
                              ? projectStatusCountApi?.green > 0
                                ? projectStatusCountApi?.green
                                : 0
                              : teamProjectStatusCountApi?.green > 0
                              ? teamProjectStatusCountApi?.green
                              : 0}
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Grid container>
                  <Grid item xs={6}>
                    <DonutChart
                      height={200}
                      width={200}
                      data={dataClientCountApi}
                      dataConfig={{
                        idKey: 'id',
                        dataKey: 'value',
                      }}
                      colors={
                        valueForTeamDashboard == 0
                          ? clientCountApi == null || Object.keys(clientCountApi).length === 0
                            ? emptyGraphColors
                            : taskGraphColors
                          : teamClientCountApi == null || Object.keys(teamClientCountApi).length === 0
                          ? emptyGraphColors
                          : taskGraphColors
                      }
                      legend="none"
                      onClick={(data: any) => handleChartClick(data, 'client')}
                      layers={['arcs', 'arcLabels', 'arcLinkLabels', 'legends', CenteredClientMetric]}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Grid
                      container
                      spacing={0}
                      alignItems="center"
                      justifyContent="center"
                      sx={{ paddingTop: '30px', paddingLeft: '20px' }}
                    >
                      <Grid item xs={6}>
                        <Stack direction="row">
                          <Box
                            sx={{
                              backgroundColor: '#4390E1',
                              height: '17px',
                              width: '4px',
                              mt: '5px',
                              borderRadius: '4px',
                            }}
                          />
                          <Typography sx={{ mt: '1px', ml: '5px' }}>
                            {valueForTeamDashboard == 0
                              ? clientCountApi?.blue > 0
                                ? clientCountApi?.blue
                                : 0
                              : teamClientCountApi?.blue > 0
                              ? teamClientCountApi?.blue
                              : 0}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={6}>
                        <Stack direction="row">
                          <Box
                            sx={{
                              backgroundColor: '#E75B5C',
                              height: '17px',
                              width: '4px',
                              mt: '5px',
                              borderRadius: '4px',
                            }}
                          />
                          <Typography sx={{ mt: '1px', ml: '5px' }}>
                            {valueForTeamDashboard == 0
                              ? clientCountApi?.red > 0
                                ? clientCountApi?.red
                                : 0
                              : teamClientCountApi?.red > 0
                              ? teamClientCountApi?.red
                              : 0}
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      spacing={0}
                      alignItems="center"
                      justifyContent="center"
                      sx={{ paddingTop: '20px', paddingLeft: '20px' }}
                    >
                      <Grid item xs={6}>
                        <Stack direction="row">
                          <Box
                            sx={{
                              backgroundColor: '#EFA958',
                              height: '17px',
                              width: '4px',
                              mt: '5px',
                              borderRadius: '4px',
                            }}
                          />
                          <Typography sx={{ mt: '1px', ml: '5px' }}>
                            {valueForTeamDashboard == 0
                              ? clientCountApi?.amber > 0
                                ? clientCountApi?.amber
                                : 0
                              : teamClientCountApi?.amber > 0
                              ? teamClientCountApi?.amber
                              : 0}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={6}>
                        <Stack direction="row">
                          <Box
                            sx={{
                              backgroundColor: '#4F9F52',
                              height: '17px',
                              width: '4px',
                              mt: '5px',
                              borderRadius: '4px',
                            }}
                          />
                          <Typography sx={{ mt: '1px', ml: '5px' }}>
                            {valueForTeamDashboard == 0
                              ? clientCountApi?.green > 0
                                ? clientCountApi?.green
                                : 0
                              : teamClientCountApi?.green > 0
                              ? teamClientCountApi?.green
                              : 0}
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={9}>
            {threeClientsToDisplay?.length > 0 ? (
              <Grid container justifyContent="space-between" sx={{ marginTop: '22px' }}>
                <Grid item>
                  <Stack direction="row">
                    <Typography sx={{ fontSize: '18px', fontWeight: '500' }}>Focus {label_client}s</Typography>
                    <CognisaaasTooltip
                      title={
                        <Box sx={{ padding: '8px' }}>
                          <Typography>
                            This section shows the top 3 {label_client.toLowerCase()}s that need your attention. The
                            status of these {label_client.toLowerCase()}s has become Red/Amber recently or the status is
                            going to be Red/Amber in the near future.
                          </Typography>
                        </Box>
                      }
                    >
                      <IconButton disableRipple size="small">
                        <Info />
                      </IconButton>
                    </CognisaaasTooltip>
                  </Stack>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{ fontSize: '14px', fontWeight: '400', color: '#2D60F6', mt: '10px', cursor: 'pointer' }}
                    onClick={() => navigate('/client-list')}
                  >
                    View All
                  </Typography>
                </Grid>
              </Grid>
            ) : null}
            <Grid container sx={{ marginTop: '5px' }}>
              {threeClientsToDisplay?.length < 3 &&
                threeClientsToDisplay.map((ele: any, i: any) => {
                  return (
                    <Grid
                      item
                      key={i}
                      sx={{
                        boxShadow: '0px 4px 26px rgba(0, 0, 0, 0.05)',
                        height: '156px',
                        minWidtg: '315px',
                        mr: '5%',
                      }}
                    >
                      <div>
                        <CognisaasCard clientStatusColor={ele.client_readiness_status} height="156px" width="315px">
                          <Box
                            onClick={() => navigate(`/client-list/client/${ele.client_uid}`)}
                            className="cognisaas-pointer"
                            sx={{ padding: '20px' }}
                          >
                            <Stack direction="row">
                              <Avatar style={{ height: '30px', width: '30px' }} {...stringAvatar(ele.client_name)} />
                              <Typography sx={{ marginTop: '0px', fontSize: '18px', fontWeight: '500', ml: '10px' }}>
                                {ele.client_name}
                              </Typography>
                            </Stack>
                            <Typography sx={{ fontSize: '14px', fontWeight: '400', mt: '9px' }}>
                              {ele.stage_journerystage}
                            </Typography>
                            <Stack direction="row">
                              <Box sx={{ mt: '12px' }}>
                                <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#7A7A7A' }}>
                                  {label_arr}
                                </Typography>
                                <Typography sx={{ fontSize: '14px', fontWeight: '400' }}>
                                  {formatCurrency(ele.client_account_worth, customerDetail?.currency_code)}
                                </Typography>
                              </Box>
                              <Box sx={{ ml: '80px', mt: '12px' }}>
                                <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#7A7A7A' }}>
                                  Industry
                                </Typography>
                                <Typography sx={{ fontSize: '14px', fontWeight: '400' }}>
                                  {ele.industry_name}
                                </Typography>
                              </Box>
                            </Stack>
                          </Box>
                        </CognisaasCard>
                      </div>
                    </Grid>
                  );
                })}
            </Grid>
            <Grid container justifyContent="space-between" sx={{ marginTop: '5px' }}>
              {threeClientsToDisplay &&
                threeClientsToDisplay?.length > 2 &&
                threeClientsToDisplay.map((ele: any, i: any) => {
                  return (
                    <Grid
                      item
                      key={i}
                      sx={{ boxShadow: '0px 4px 26px rgba(0, 0, 0, 0.05)', height: '156px', minWidtg: '315px' }}
                    >
                      <div>
                        <CognisaasCard clientStatusColor={ele.client_readiness_status} height="156px" width="315px">
                          <Box
                            onClick={() => navigate(`/client-list/client/${ele.client_uid}`)}
                            className="cognisaas-pointer"
                            sx={{ padding: '20px' }}
                          >
                            <Stack direction="row">
                              <Avatar style={{ height: '30px', width: '30px' }} {...stringAvatar(ele.client_name)} />
                              <Typography sx={{ marginTop: '0px', fontSize: '18px', fontWeight: '500', ml: '10px' }}>
                                {ele.client_name}
                              </Typography>
                            </Stack>
                            <Typography sx={{ fontSize: '14px', fontWeight: '400', mt: '9px' }}>
                              {ele.stage_journerystage}
                            </Typography>
                            <Stack direction="row">
                              <Box sx={{ mt: '12px' }}>
                                <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#7A7A7A' }}>
                                  {label_arr}
                                </Typography>
                                <Typography sx={{ fontSize: '14px', fontWeight: '400' }}>
                                  {formatCurrency(ele.client_account_worth, customerDetail?.currency_code)}
                                </Typography>
                              </Box>
                              <Box sx={{ ml: '80px', mt: '12px' }}>
                                <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#7A7A7A' }}>
                                  Industry
                                </Typography>
                                <Typography sx={{ fontSize: '14px', fontWeight: '400' }}>
                                  {ele.industry_name}
                                </Typography>
                              </Box>
                            </Stack>
                          </Box>
                        </CognisaasCard>
                      </div>
                    </Grid>
                  );
                })}
            </Grid>
            <Grid container sx={{ marginTop: '3px' }} spacing={3}>
              <Grid item xs={6}>
                <Paper sx={{ height: '405px', boxShadow: '0px 4px 26px rgba(0, 0, 0, 0.05)', position: 'relative' }}>
                  <Box sx={{ paddingLeft: '20px', paddingRight: '20px', paddingBottom: '20px' }}>
                    <Grid container justifyContent="space-between">
                      <Grid item>
                        <Typography variant="subtitle1" sx={{ mt: '15px', fontSize: '18px', fontWeight: '500' }}>
                          Alerts
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Box sx={{ borderColor: 'divider', width: '100%' }}>
                          <Tabs value={value} onChange={handleChangeNew} aria-label="basic tabs example">
                            <Tab label={`${label_client}s`} {...a11yProps(0)} />
                            <Tab label={`${label_project}s`} {...a11yProps(1)} />
                            <Tab label={`${label_use_case}s`} {...a11yProps(2)} />
                          </Tabs>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box sx={{ padding: '0px 20px 20px 12px', overflow: 'scroll', height: '320px' }}>
                    {valueForTeamDashboard == 0 ? (
                      <>
                        <TabPanel value={value} index={0}>
                          {clientDegradeAlerts &&
                            clientDegradeAlerts.length > 0 &&
                            clientDegradeAlerts.map((ele: any, i: number) => {
                              return (
                                <Box style={{ marginTop: '3px' }} key={i}>
                                  <CognisaasCard
                                    clientStatusColor={ele.client_readiness_status}
                                    height="95px"
                                    width="100%"
                                  >
                                    <Box
                                      className="cognisaas-pointer"
                                      onClick={() => navigate(`/client-list/client/${ele.client_uid}`)}
                                      sx={{ padding: '20px 10px' }}
                                    >
                                      <Grid container justifyContent="space-between">
                                        <Grid item>
                                          <Stack direction="row">
                                            <Box>
                                              <Typography>
                                                <Avatar
                                                  style={{ height: '30px', width: '30px' }}
                                                  {...stringAvatar(ele.client_name)}
                                                />
                                              </Typography>
                                            </Box>
                                            <Box sx={{ ml: '10px' }}>
                                              <Typography>{ele.client_name}</Typography>
                                              <Stack direction="row" style={{ marginTop: '8px' }}>
                                                {ele.new_value === 'amber' ? (
                                                  <>
                                                    <Typography
                                                      sx={{ fontSize: '12px', fontWeight: '400', color: '#7A7A7A' }}
                                                    >
                                                      Status changed from
                                                    </Typography>
                                                    <CircleIcon
                                                      sx={{
                                                        color: '#4F9F52',
                                                        height: '8px',
                                                        width: '8px',
                                                        marginTop: '5px',
                                                        ml: '8px',
                                                      }}
                                                    />
                                                    <Typography
                                                      sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}
                                                    >
                                                      On Track
                                                    </Typography>
                                                    <Typography
                                                      sx={{
                                                        fontSize: '12px',
                                                        fontWeight: '400',
                                                        marginLeft: '13px',
                                                        color: '#7A7A7A',
                                                      }}
                                                    >
                                                      to
                                                    </Typography>
                                                    <CircleIcon
                                                      sx={{
                                                        color: '#EFA958',
                                                        height: '8px',
                                                        width: '8px',
                                                        marginTop: '5px',
                                                        marginLeft: '12px',
                                                      }}
                                                    />
                                                    <Typography
                                                      sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}
                                                    >
                                                      At Risk
                                                    </Typography>
                                                  </>
                                                ) : null}
                                                {ele.new_value === 'red' ? (
                                                  <>
                                                    <Typography
                                                      sx={{
                                                        fontSize: '12px',
                                                        fontWeight: '400',
                                                        color: '#7A7A7A',
                                                      }}
                                                    >
                                                      Status changed to
                                                    </Typography>

                                                    <CircleIcon
                                                      sx={{
                                                        color: '#E75B5C',
                                                        height: '8px',
                                                        width: '8px',
                                                        marginTop: '5px',
                                                        ml: '8px',
                                                      }}
                                                    />
                                                    <Typography
                                                      sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}
                                                    >
                                                      Overdue
                                                    </Typography>
                                                  </>
                                                ) : null}
                                              </Stack>
                                            </Box>
                                          </Stack>
                                        </Grid>
                                        <Grid item>
                                          <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#7A7A7A' }}>
                                            {format(new Date(ele?.trail_date.replace(/-/g, '/')), 'dd MMM yy')}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  </CognisaasCard>
                                </Box>
                              );
                            })}

                          {clientDegradeAlerts && Object.keys(clientDegradeAlerts).length <= 0 ? (
                            <Grid container alignItems="center" justifyContent="center">
                              <Grid item>
                                <NoRecord style={{ width: '200px', height: '200px' }} />
                              </Grid>
                            </Grid>
                          ) : null}
                          {clientDegradeAlerts == null ? (
                            <Grid container alignItems="center" justifyContent="center">
                              <Grid item>
                                <NoRecord style={{ width: '200px', height: '200px' }} />
                              </Grid>
                            </Grid>
                          ) : null}
                          {clientDegradeAlerts && clientDegradeAlerts == undefined ? (
                            <Grid container alignItems="center" justifyContent="center">
                              <Grid item>
                                <NoRecord style={{ width: '200px', height: '200px' }} />
                              </Grid>
                            </Grid>
                          ) : null}
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                          {projectDegradeAlerts &&
                            projectDegradeAlerts.length > 0 &&
                            projectDegradeAlerts.map((ele: any, i: number) => {
                              return (
                                <Box style={{ marginTop: '3px' }} key={i}>
                                  <CognisaasCard
                                    clientStatusColor={ele.project_readinessstate_status}
                                    height="95px"
                                    width="100%"
                                  >
                                    <Box
                                      className="cognisaas-pointer"
                                      onClick={() =>
                                        navigate(`/projects/project/${ele.association_uid}/${ele.project_uid}`)
                                      }
                                      sx={{ padding: '20px 10px' }}
                                    >
                                      <Grid container justifyContent="space-between">
                                        <Grid item>
                                          <Stack direction="row">
                                            <Box>
                                              <Typography>
                                                <Avatar
                                                  style={{ height: '30px', width: '30px' }}
                                                  {...stringAvatar(ele.project_name)}
                                                />
                                              </Typography>
                                            </Box>
                                            <Box sx={{ ml: '10px' }}>
                                              <Typography>{ele.project_name}</Typography>
                                              <Stack direction="row" style={{ marginTop: '8px' }}>
                                                {ele.new_value === 'amber' ? (
                                                  <>
                                                    <Typography
                                                      sx={{ fontSize: '12px', fontWeight: '400', color: '#7A7A7A' }}
                                                    >
                                                      Status changed from
                                                    </Typography>
                                                    <CircleIcon
                                                      sx={{
                                                        color: '#4F9F52',
                                                        height: '8px',
                                                        width: '8px',
                                                        marginTop: '5px',
                                                        ml: '8px',
                                                      }}
                                                    />
                                                    <Typography
                                                      sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}
                                                    >
                                                      On Track
                                                    </Typography>
                                                    <Typography
                                                      sx={{
                                                        fontSize: '12px',
                                                        fontWeight: '400',
                                                        marginLeft: '13px',
                                                        color: '#7A7A7A',
                                                      }}
                                                    >
                                                      to
                                                    </Typography>
                                                    <CircleIcon
                                                      sx={{
                                                        color: '#EFA958',
                                                        height: '8px',
                                                        width: '8px',
                                                        marginTop: '5px',
                                                        marginLeft: '12px',
                                                      }}
                                                    />
                                                    <Typography
                                                      sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}
                                                    >
                                                      At Risk
                                                    </Typography>
                                                  </>
                                                ) : null}
                                                {ele.new_value === 'red' ? (
                                                  <>
                                                    <Typography
                                                      sx={{
                                                        fontSize: '12px',
                                                        fontWeight: '400',
                                                        color: '#7A7A7A',
                                                      }}
                                                    >
                                                      Status changed to
                                                    </Typography>

                                                    <CircleIcon
                                                      sx={{
                                                        color: '#E75B5C',
                                                        height: '8px',
                                                        width: '8px',
                                                        marginTop: '5px',
                                                        ml: '8px',
                                                      }}
                                                    />
                                                    <Typography
                                                      sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}
                                                    >
                                                      Overdue
                                                    </Typography>
                                                  </>
                                                ) : null}
                                              </Stack>
                                            </Box>
                                          </Stack>
                                        </Grid>
                                        <Grid item>
                                          <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#7A7A7A' }}>
                                            {format(new Date(ele?.trail_date.replace(/-/g, '/')), 'dd MMM yy')}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  </CognisaasCard>
                                </Box>
                              );
                            })}
                          {projectDegradeAlerts && Object.keys(projectDegradeAlerts).length <= 0 ? (
                            <Grid container alignItems="center" justifyContent="center">
                              <Grid item>
                                <NoRecord style={{ width: '200px', height: '200px' }} />
                              </Grid>
                            </Grid>
                          ) : null}
                          {projectDegradeAlerts == null ? (
                            <Grid container alignItems="center" justifyContent="center">
                              <Grid item>
                                <NoRecord style={{ width: '200px', height: '200px' }} />
                              </Grid>
                            </Grid>
                          ) : null}
                          {projectDegradeAlerts && projectDegradeAlerts == undefined ? (
                            <Grid container alignItems="center" justifyContent="center">
                              <Grid item>
                                <NoRecord style={{ width: '200px', height: '200px' }} />
                              </Grid>
                            </Grid>
                          ) : null}
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                          {usecaseDegradeAlerts &&
                            usecaseDegradeAlerts.length > 0 &&
                            usecaseDegradeAlerts.map((ele: any, i: number) => {
                              return (
                                <Box style={{ marginTop: '3px' }} key={i}>
                                  <CognisaasCard
                                    clientStatusColor={ele.case_readinessstate_status}
                                    height="95px"
                                    width="100%"
                                  >
                                    <Box
                                      className="cognisaas-pointer"
                                      onClick={() =>
                                        navigate(
                                          `/projects/project/${ele.association_uid}/${ele.project_uid}/${ele.case_uid}`
                                        )
                                      }
                                      sx={{ padding: '20px 10px' }}
                                    >
                                      <Grid container justifyContent="space-between">
                                        <Grid item>
                                          <Stack direction="row">
                                            <Box>
                                              <Typography>
                                                <Avatar
                                                  style={{ height: '30px', width: '30px' }}
                                                  {...stringAvatar(ele.case_name)}
                                                />
                                              </Typography>
                                            </Box>
                                            <Box sx={{ ml: '10px' }}>
                                              <Typography>{ele.case_name}</Typography>

                                              <Stack direction="row" style={{ marginTop: '8px' }}>
                                                {ele.new_value === 'amber' ? (
                                                  <>
                                                    <Typography
                                                      sx={{ fontSize: '12px', fontWeight: '400', color: '#7A7A7A' }}
                                                    >
                                                      Status changed from
                                                    </Typography>
                                                    <CircleIcon
                                                      sx={{
                                                        color: '#4F9F52',
                                                        height: '8px',
                                                        width: '8px',
                                                        marginTop: '5px',
                                                        ml: '8px',
                                                      }}
                                                    />
                                                    <Typography
                                                      sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}
                                                    >
                                                      On Track
                                                    </Typography>
                                                    <Typography
                                                      sx={{
                                                        fontSize: '12px',
                                                        fontWeight: '400',
                                                        marginLeft: '13px',
                                                        color: '#7A7A7A',
                                                      }}
                                                    >
                                                      to
                                                    </Typography>
                                                    <CircleIcon
                                                      sx={{
                                                        color: '#EFA958',
                                                        height: '8px',
                                                        width: '8px',
                                                        marginTop: '5px',
                                                        marginLeft: '12px',
                                                      }}
                                                    />
                                                    <Typography
                                                      sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}
                                                    >
                                                      At Risk
                                                    </Typography>
                                                  </>
                                                ) : null}
                                                {ele.new_value === 'red' ? (
                                                  <>
                                                    <Typography
                                                      sx={{
                                                        fontSize: '12px',
                                                        fontWeight: '400',
                                                        color: '#7A7A7A',
                                                      }}
                                                    >
                                                      Status changed to
                                                    </Typography>

                                                    <CircleIcon
                                                      sx={{
                                                        color: '#E75B5C',
                                                        height: '8px',
                                                        width: '8px',
                                                        marginTop: '5px',
                                                        ml: '8px',
                                                      }}
                                                    />
                                                    <Typography
                                                      sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}
                                                    >
                                                      Overdue
                                                    </Typography>
                                                  </>
                                                ) : null}
                                              </Stack>
                                            </Box>
                                          </Stack>
                                        </Grid>
                                        <Grid item>
                                          <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#7A7A7A' }}>
                                            {format(new Date(ele?.trail_date.replace(/-/g, '/')), 'dd MMM yy')}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  </CognisaasCard>
                                </Box>
                              );
                            })}
                          {usecaseDegradeAlerts && Object.keys(usecaseDegradeAlerts).length <= 0 ? (
                            <Grid container alignItems="center" justifyContent="center">
                              <Grid item>
                                <NoRecord style={{ width: '200px', height: '200px' }} />
                              </Grid>
                            </Grid>
                          ) : null}
                          {usecaseDegradeAlerts == null ? (
                            <Grid container alignItems="center" justifyContent="center">
                              <Grid item>
                                <NoRecord style={{ width: '200px', height: '200px' }} />
                              </Grid>
                            </Grid>
                          ) : null}
                          {usecaseDegradeAlerts && usecaseDegradeAlerts == undefined ? (
                            <Grid container alignItems="center" justifyContent="center">
                              <Grid item>
                                <NoRecord style={{ width: '200px', height: '200px' }} />
                              </Grid>
                            </Grid>
                          ) : null}
                        </TabPanel>
                      </>
                    ) : (
                      <>
                        <TabPanel value={value} index={0}>
                          {teamClientDegradeAlerts &&
                            teamClientDegradeAlerts.length > 0 &&
                            teamClientDegradeAlerts.map((ele: any, i: number) => {
                              return (
                                <Box style={{ marginTop: '3px' }} key={i}>
                                  <CognisaasCard
                                    clientStatusColor={ele.client_readiness_status}
                                    height="95px"
                                    width="100%"
                                  >
                                    <Box
                                      className="cognisaas-pointer"
                                      onClick={() => navigate(`/client-list/client/${ele.client_uid}`)}
                                      sx={{ padding: '20px 10px' }}
                                    >
                                      <Grid container justifyContent="space-between">
                                        <Grid item>
                                          <Stack direction="row">
                                            <Box>
                                              <Typography>
                                                <Avatar
                                                  style={{ height: '30px', width: '30px' }}
                                                  {...stringAvatar(ele.client_name)}
                                                />
                                              </Typography>
                                            </Box>
                                            <Box sx={{ ml: '10px' }}>
                                              <Typography>{ele.client_name}</Typography>
                                              <Stack direction="row" style={{ marginTop: '8px' }}>
                                                {ele.new_value === 'amber' ? (
                                                  <>
                                                    <Typography
                                                      sx={{ fontSize: '12px', fontWeight: '400', color: '#7A7A7A' }}
                                                    >
                                                      Status changed from
                                                    </Typography>
                                                    <CircleIcon
                                                      sx={{
                                                        color: '#4F9F52',
                                                        height: '8px',
                                                        width: '8px',
                                                        marginTop: '5px',
                                                        ml: '8px',
                                                      }}
                                                    />
                                                    <Typography
                                                      sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}
                                                    >
                                                      On Track
                                                    </Typography>
                                                    <Typography
                                                      sx={{
                                                        fontSize: '12px',
                                                        fontWeight: '400',
                                                        marginLeft: '13px',
                                                        color: '#7A7A7A',
                                                      }}
                                                    >
                                                      to
                                                    </Typography>
                                                    <CircleIcon
                                                      sx={{
                                                        color: '#EFA958',
                                                        height: '8px',
                                                        width: '8px',
                                                        marginTop: '5px',
                                                        marginLeft: '12px',
                                                      }}
                                                    />
                                                    <Typography
                                                      sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}
                                                    >
                                                      At Risk
                                                    </Typography>
                                                  </>
                                                ) : null}
                                                {ele.new_value === 'red' ? (
                                                  <>
                                                    <Typography
                                                      sx={{
                                                        fontSize: '12px',
                                                        fontWeight: '400',
                                                        color: '#7A7A7A',
                                                      }}
                                                    >
                                                      Status changed to
                                                    </Typography>

                                                    <CircleIcon
                                                      sx={{
                                                        color: '#E75B5C',
                                                        height: '8px',
                                                        width: '8px',
                                                        marginTop: '5px',
                                                        ml: '8px',
                                                      }}
                                                    />
                                                    <Typography
                                                      sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}
                                                    >
                                                      Overdue
                                                    </Typography>
                                                  </>
                                                ) : null}
                                              </Stack>
                                            </Box>
                                          </Stack>
                                        </Grid>
                                        <Grid item>
                                          <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#7A7A7A' }}>
                                            {format(new Date(ele?.trail_date.replace(/-/g, '/')), 'dd MMM yy')}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  </CognisaasCard>
                                </Box>
                              );
                            })}

                          {teamClientDegradeAlerts && Object.keys(teamClientDegradeAlerts).length <= 0 ? (
                            <Grid container alignItems="center" justifyContent="center">
                              <Grid item>
                                <NoRecord style={{ width: '200px', height: '200px' }} />
                              </Grid>
                            </Grid>
                          ) : null}
                          {teamClientDegradeAlerts == null ? (
                            <Grid container alignItems="center" justifyContent="center">
                              <Grid item>
                                <NoRecord style={{ width: '200px', height: '200px' }} />
                              </Grid>
                            </Grid>
                          ) : null}
                          {teamClientDegradeAlerts && teamClientDegradeAlerts == undefined ? (
                            <Grid container alignItems="center" justifyContent="center">
                              <Grid item>
                                <NoRecord style={{ width: '200px', height: '200px' }} />
                              </Grid>
                            </Grid>
                          ) : null}
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                          {teamProjectDegradeAlerts &&
                            teamProjectDegradeAlerts.length > 0 &&
                            teamProjectDegradeAlerts.map((ele: any, i: number) => {
                              return (
                                <Box style={{ marginTop: '3px' }} key={i}>
                                  <CognisaasCard
                                    clientStatusColor={ele.project_readinessstate_status}
                                    height="95px"
                                    width="100%"
                                  >
                                    <Box
                                      className="cognisaas-pointer"
                                      onClick={() =>
                                        navigate(`/projects/project/${ele.association_uid}/${ele.project_uid}`)
                                      }
                                      sx={{ padding: '20px 10px' }}
                                    >
                                      <Grid container justifyContent="space-between">
                                        <Grid item>
                                          <Stack direction="row">
                                            <Box>
                                              <Typography>
                                                <Avatar
                                                  style={{ height: '30px', width: '30px' }}
                                                  {...stringAvatar(ele.project_name)}
                                                />
                                              </Typography>
                                            </Box>
                                            <Box sx={{ ml: '10px' }}>
                                              <Typography>{ele.project_name}</Typography>
                                              <Stack direction="row" style={{ marginTop: '8px' }}>
                                                {ele.new_value === 'amber' ? (
                                                  <>
                                                    <Typography
                                                      sx={{ fontSize: '12px', fontWeight: '400', color: '#7A7A7A' }}
                                                    >
                                                      Status changed from
                                                    </Typography>
                                                    <CircleIcon
                                                      sx={{
                                                        color: '#4F9F52',
                                                        height: '8px',
                                                        width: '8px',
                                                        marginTop: '5px',
                                                        ml: '8px',
                                                      }}
                                                    />
                                                    <Typography
                                                      sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}
                                                    >
                                                      On Track
                                                    </Typography>
                                                    <Typography
                                                      sx={{
                                                        fontSize: '12px',
                                                        fontWeight: '400',
                                                        marginLeft: '13px',
                                                        color: '#7A7A7A',
                                                      }}
                                                    >
                                                      to
                                                    </Typography>
                                                    <CircleIcon
                                                      sx={{
                                                        color: '#EFA958',
                                                        height: '8px',
                                                        width: '8px',
                                                        marginTop: '5px',
                                                        marginLeft: '12px',
                                                      }}
                                                    />
                                                    <Typography
                                                      sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}
                                                    >
                                                      At Risk
                                                    </Typography>
                                                  </>
                                                ) : null}
                                                {ele.new_value === 'red' ? (
                                                  <>
                                                    <Typography
                                                      sx={{
                                                        fontSize: '12px',
                                                        fontWeight: '400',
                                                        color: '#7A7A7A',
                                                      }}
                                                    >
                                                      Status changed to
                                                    </Typography>

                                                    <CircleIcon
                                                      sx={{
                                                        color: '#E75B5C',
                                                        height: '8px',
                                                        width: '8px',
                                                        marginTop: '5px',
                                                        ml: '8px',
                                                      }}
                                                    />
                                                    <Typography
                                                      sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}
                                                    >
                                                      Overdue
                                                    </Typography>
                                                  </>
                                                ) : null}
                                              </Stack>
                                            </Box>
                                          </Stack>
                                        </Grid>
                                        <Grid item>
                                          <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#7A7A7A' }}>
                                            {format(new Date(ele?.trail_date.replace(/-/g, '/')), 'dd MMM yy')}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  </CognisaasCard>
                                </Box>
                              );
                            })}
                          {teamProjectDegradeAlerts && Object.keys(teamProjectDegradeAlerts).length <= 0 ? (
                            <Grid container alignItems="center" justifyContent="center">
                              <Grid item>
                                <NoRecord style={{ width: '200px', height: '200px' }} />
                              </Grid>
                            </Grid>
                          ) : null}
                          {teamProjectDegradeAlerts == null ? (
                            <Grid container alignItems="center" justifyContent="center">
                              <Grid item>
                                <NoRecord style={{ width: '200px', height: '200px' }} />
                              </Grid>
                            </Grid>
                          ) : null}
                          {teamProjectDegradeAlerts && teamProjectDegradeAlerts == undefined ? (
                            <Grid container alignItems="center" justifyContent="center">
                              <Grid item>
                                <NoRecord style={{ width: '200px', height: '200px' }} />
                              </Grid>
                            </Grid>
                          ) : null}
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                          {teamUseCaseDegradeAlerts &&
                            teamUseCaseDegradeAlerts.length > 0 &&
                            teamUseCaseDegradeAlerts.map((ele: any, i: number) => {
                              return (
                                <Box style={{ marginTop: '3px' }} key={i}>
                                  <CognisaasCard
                                    clientStatusColor={ele.case_readinessstate_status}
                                    height="95px"
                                    width="100%"
                                  >
                                    <Box
                                      className="cognisaas-pointer"
                                      onClick={() =>
                                        navigate(
                                          `/projects/project/${ele.association_uid}/${ele.project_uid}/${ele.case_uid}`
                                        )
                                      }
                                      sx={{ padding: '20px 10px' }}
                                    >
                                      <Grid container justifyContent="space-between">
                                        <Grid item>
                                          <Stack direction="row">
                                            <Box>
                                              <Typography>
                                                <Avatar
                                                  style={{ height: '30px', width: '30px' }}
                                                  {...stringAvatar(ele.case_name)}
                                                />
                                              </Typography>
                                            </Box>
                                            <Box sx={{ ml: '10px' }}>
                                              <Typography>{ele.case_name}</Typography>

                                              <Stack direction="row" style={{ marginTop: '8px' }}>
                                                {ele.new_value === 'amber' ? (
                                                  <>
                                                    <Typography
                                                      sx={{ fontSize: '12px', fontWeight: '400', color: '#7A7A7A' }}
                                                    >
                                                      Status changed from
                                                    </Typography>
                                                    <CircleIcon
                                                      sx={{
                                                        color: '#4F9F52',
                                                        height: '8px',
                                                        width: '8px',
                                                        marginTop: '5px',
                                                        ml: '8px',
                                                      }}
                                                    />
                                                    <Typography
                                                      sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}
                                                    >
                                                      On Track
                                                    </Typography>
                                                    <Typography
                                                      sx={{
                                                        fontSize: '12px',
                                                        fontWeight: '400',
                                                        marginLeft: '13px',
                                                        color: '#7A7A7A',
                                                      }}
                                                    >
                                                      to
                                                    </Typography>
                                                    <CircleIcon
                                                      sx={{
                                                        color: '#EFA958',
                                                        height: '8px',
                                                        width: '8px',
                                                        marginTop: '5px',
                                                        marginLeft: '12px',
                                                      }}
                                                    />
                                                    <Typography
                                                      sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}
                                                    >
                                                      At Risk
                                                    </Typography>
                                                  </>
                                                ) : null}
                                                {ele.new_value === 'red' ? (
                                                  <>
                                                    <Typography
                                                      sx={{
                                                        fontSize: '12px',
                                                        fontWeight: '400',
                                                        color: '#7A7A7A',
                                                      }}
                                                    >
                                                      Status changed to
                                                    </Typography>

                                                    <CircleIcon
                                                      sx={{
                                                        color: '#E75B5C',
                                                        height: '8px',
                                                        width: '8px',
                                                        marginTop: '5px',
                                                        ml: '8px',
                                                      }}
                                                    />
                                                    <Typography
                                                      sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}
                                                    >
                                                      Overdue
                                                    </Typography>
                                                  </>
                                                ) : null}
                                              </Stack>
                                            </Box>
                                          </Stack>
                                        </Grid>
                                        <Grid item>
                                          <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#7A7A7A' }}>
                                            {format(new Date(ele?.trail_date.replace(/-/g, '/')), 'dd MMM yy')}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  </CognisaasCard>
                                </Box>
                              );
                            })}
                          {teamUseCaseDegradeAlerts && Object.keys(teamUseCaseDegradeAlerts).length <= 0 ? (
                            <Grid container alignItems="center" justifyContent="center">
                              <Grid item>
                                <NoRecord style={{ width: '200px', height: '200px' }} />
                              </Grid>
                            </Grid>
                          ) : null}
                          {teamUseCaseDegradeAlerts == null ? (
                            <Grid container alignItems="center" justifyContent="center">
                              <Grid item>
                                <NoRecord style={{ width: '200px', height: '200px' }} />
                              </Grid>
                            </Grid>
                          ) : null}
                          {teamUseCaseDegradeAlerts && teamUseCaseDegradeAlerts == undefined ? (
                            <Grid container alignItems="center" justifyContent="center">
                              <Grid item>
                                <NoRecord style={{ width: '200px', height: '200px' }} />
                              </Grid>
                            </Grid>
                          ) : null}
                        </TabPanel>
                      </>
                    )}
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper sx={{ height: '405px', boxShadow: '0px 4px 26px rgba(0, 0, 0, 0.05)', position: 'relative' }}>
                  <Box sx={{ paddingLeft: '20px', paddingRight: '20px', paddingBottom: '20px' }}>
                    <Grid container justifyContent="space-between">
                      <Grid item>
                        <Typography sx={{ mt: '15px', fontSize: '18px', fontWeight: '500' }}>Progress</Typography>
                      </Grid>
                      <Grid item>
                        <Box sx={{ borderColor: 'divider', width: '100%' }}>
                          <Tabs value={value1} onChange={handleChangeNew1} aria-label="basic tabs example">
                            <Tab label={`${label_client}s`} {...a11yProps(0)} />
                            <Tab label={`${label_project}s`} {...a11yProps(1)} />
                            <Tab label={`${label_use_case}s`} {...a11yProps(2)} />
                          </Tabs>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box sx={{ padding: '0px 20px 20px 12px', overflow: 'scroll', height: '320px' }}>
                    {valueForTeamDashboard == 0 ? (
                      <>
                        <TabPanel value={value1} index={0}>
                          {clientUpgradeAlerts &&
                            clientUpgradeAlerts.length > 0 &&
                            clientUpgradeAlerts.map((ele: any, i: any) => {
                              return (
                                <Box style={{ marginTop: '3px' }} key={i}>
                                  <CognisaasCard
                                    clientStatusColor={ele.client_readiness_status}
                                    height="95px"
                                    width="100%"
                                  >
                                    <Box
                                      className="cognisaas-pointer"
                                      onClick={() => navigate(`/client-list/client/${ele.client_uid}`)}
                                      sx={{ padding: '20px 10px' }}
                                    >
                                      <Grid container justifyContent="space-between">
                                        <Grid item>
                                          <Stack direction="row">
                                            <Box>
                                              <Typography>
                                                <Avatar
                                                  style={{ height: '30px', width: '30px' }}
                                                  {...stringAvatar(ele.client_name)}
                                                />
                                              </Typography>
                                            </Box>
                                            <Box sx={{ ml: '10px' }}>
                                              <Typography>{ele.client_name}</Typography>

                                              <Stack direction="row" style={{ marginTop: '8px' }}>
                                                {ele.new_value === 'amber' && (
                                                  <>
                                                    <Typography
                                                      sx={{ fontSize: '12px', fontWeight: '400', color: '#7A7A7A' }}
                                                    >
                                                      Status changed from
                                                    </Typography>
                                                    <CircleIcon
                                                      sx={{
                                                        color: '#E75B5C',
                                                        height: '8px',
                                                        width: '8px',
                                                        marginTop: '5px',
                                                        ml: '8px',
                                                      }}
                                                    />
                                                    <Typography
                                                      sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}
                                                    >
                                                      Overdue
                                                    </Typography>
                                                    <Typography
                                                      sx={{
                                                        fontSize: '12px',
                                                        fontWeight: '400',
                                                        marginLeft: '13px',
                                                        color: '#7A7A7A',
                                                      }}
                                                    >
                                                      to
                                                    </Typography>
                                                    <CircleIcon
                                                      sx={{
                                                        color: '#EFA958',
                                                        height: '8px',
                                                        width: '8px',
                                                        marginTop: '5px',
                                                        marginLeft: '12px',
                                                      }}
                                                    />
                                                    <Typography
                                                      sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}
                                                    >
                                                      At Risk
                                                    </Typography>
                                                  </>
                                                )}
                                                {ele.new_value === 'green' && (
                                                  <>
                                                    <Typography
                                                      sx={{ fontSize: '12px', fontWeight: '400', color: '#7A7A7A' }}
                                                    >
                                                      Status changed from
                                                    </Typography>
                                                    <CircleIcon
                                                      sx={{
                                                        color: '#EFA958',
                                                        height: '8px',
                                                        width: '8px',
                                                        marginTop: '5px',
                                                        ml: '8px',
                                                      }}
                                                    />
                                                    <Typography
                                                      sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}
                                                    >
                                                      At Risk
                                                    </Typography>
                                                    <Typography
                                                      sx={{
                                                        fontSize: '12px',
                                                        fontWeight: '400',
                                                        marginLeft: '13px',
                                                        color: '#7A7A7A',
                                                      }}
                                                    >
                                                      to
                                                    </Typography>
                                                    <CircleIcon
                                                      sx={{
                                                        color: '#4F9F52',
                                                        height: '8px',
                                                        width: '8px',
                                                        marginTop: '5px',
                                                        marginLeft: '12px',
                                                      }}
                                                    />
                                                    <Typography
                                                      sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}
                                                    >
                                                      On Track
                                                    </Typography>
                                                  </>
                                                )}
                                                {ele.new_value === 'blue' && (
                                                  <>
                                                    <Typography
                                                      sx={{
                                                        fontSize: '12px',
                                                        fontWeight: '400',
                                                        color: '#7A7A7A',
                                                      }}
                                                    >
                                                      Status changed to
                                                    </Typography>
                                                    <CircleIcon
                                                      sx={{
                                                        color: '#4390E1',
                                                        height: '8px',
                                                        width: '8px',
                                                        marginTop: '5px',
                                                        ml: '8px',
                                                      }}
                                                    />
                                                    <Typography
                                                      sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}
                                                    >
                                                      Completed!
                                                    </Typography>
                                                  </>
                                                )}
                                              </Stack>
                                            </Box>
                                          </Stack>
                                        </Grid>
                                        <Grid item>
                                          <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#7A7A7A' }}>
                                            {format(new Date(ele?.trail_date.replace(/-/g, '/')), 'dd MMM yy')}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  </CognisaasCard>
                                </Box>
                              );
                            })}
                          {clientUpgradeAlerts && Object.keys(clientUpgradeAlerts).length <= 0 ? (
                            <Grid container alignItems="center" justifyContent="center">
                              <Grid item>
                                <NoRecord style={{ width: '200px', height: '200px' }} />
                              </Grid>
                            </Grid>
                          ) : null}
                          {clientUpgradeAlerts === null ? (
                            <Grid container alignItems="center" justifyContent="center">
                              <Grid item>
                                <NoRecord style={{ width: '200px', height: '200px' }} />
                              </Grid>
                            </Grid>
                          ) : null}
                          {clientUpgradeAlerts && clientUpgradeAlerts == undefined ? (
                            <Grid container alignItems="center" justifyContent="center">
                              <Grid item>
                                <NoRecord style={{ width: '200px', height: '200px' }} />
                              </Grid>
                            </Grid>
                          ) : null}
                        </TabPanel>
                        <TabPanel value={value1} index={1}>
                          {projectUpgradeAlerts &&
                            projectUpgradeAlerts.length > 0 &&
                            projectUpgradeAlerts.map((ele: any, i: any) => {
                              return (
                                <Box style={{ marginTop: '3px' }} key={i}>
                                  <CognisaasCard
                                    clientStatusColor={ele.project_readinessstate_status}
                                    height="95px"
                                    width="100%"
                                  >
                                    <Box
                                      className="cognisaas-pointer"
                                      onClick={() =>
                                        navigate(`/projects/project/${ele.association_uid}/${ele.project_uid}`)
                                      }
                                      sx={{ padding: '20px 10px' }}
                                    >
                                      <Grid container justifyContent="space-between">
                                        <Grid item>
                                          <Stack direction="row">
                                            <Box>
                                              <Typography>
                                                <Avatar
                                                  style={{ height: '30px', width: '30px' }}
                                                  {...stringAvatar(ele.project_name)}
                                                />
                                              </Typography>
                                            </Box>
                                            <Box sx={{ ml: '10px' }}>
                                              <Typography>{ele.project_name}</Typography>

                                              <Stack direction="row" style={{ marginTop: '8px' }}>
                                                {ele.new_value === 'amber' && (
                                                  <>
                                                    <Typography
                                                      sx={{ fontSize: '12px', fontWeight: '400', color: '#7A7A7A' }}
                                                    >
                                                      Status changed from
                                                    </Typography>
                                                    <CircleIcon
                                                      sx={{
                                                        color: '#E75B5C',
                                                        height: '8px',
                                                        width: '8px',
                                                        marginTop: '5px',
                                                        ml: '8px',
                                                      }}
                                                    />
                                                    <Typography
                                                      sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}
                                                    >
                                                      Overdue
                                                    </Typography>
                                                    <Typography
                                                      sx={{
                                                        fontSize: '12px',
                                                        fontWeight: '400',
                                                        marginLeft: '13px',
                                                        color: '#7A7A7A',
                                                      }}
                                                    >
                                                      to
                                                    </Typography>
                                                    <CircleIcon
                                                      sx={{
                                                        color: '#EFA958',
                                                        height: '8px',
                                                        width: '8px',
                                                        marginTop: '5px',
                                                        marginLeft: '12px',
                                                      }}
                                                    />
                                                    <Typography
                                                      sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}
                                                    >
                                                      At Risk
                                                    </Typography>
                                                  </>
                                                )}
                                                {ele.new_value === 'green' && (
                                                  <>
                                                    <Typography
                                                      sx={{ fontSize: '12px', fontWeight: '400', color: '#7A7A7A' }}
                                                    >
                                                      Status changed from
                                                    </Typography>
                                                    <CircleIcon
                                                      sx={{
                                                        color: '#EFA958',
                                                        height: '8px',
                                                        width: '8px',
                                                        marginTop: '5px',
                                                        ml: '8px',
                                                      }}
                                                    />
                                                    <Typography
                                                      sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}
                                                    >
                                                      At Risk
                                                    </Typography>
                                                    <Typography
                                                      sx={{
                                                        fontSize: '12px',
                                                        fontWeight: '400',
                                                        marginLeft: '13px',
                                                        color: '#7A7A7A',
                                                      }}
                                                    >
                                                      to
                                                    </Typography>
                                                    <CircleIcon
                                                      sx={{
                                                        color: '#4F9F52',
                                                        height: '8px',
                                                        width: '8px',
                                                        marginTop: '5px',
                                                        marginLeft: '12px',
                                                      }}
                                                    />
                                                    <Typography
                                                      sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}
                                                    >
                                                      On Track
                                                    </Typography>
                                                  </>
                                                )}
                                                {ele.new_value === 'blue' && (
                                                  <>
                                                    <Typography
                                                      sx={{
                                                        fontSize: '12px',
                                                        fontWeight: '400',
                                                        color: '#7A7A7A',
                                                      }}
                                                    >
                                                      Status changed to
                                                    </Typography>
                                                    <CircleIcon
                                                      sx={{
                                                        color: '#4390E1',
                                                        height: '8px',
                                                        width: '8px',
                                                        marginTop: '5px',
                                                        ml: '8px',
                                                      }}
                                                    />
                                                    <Typography
                                                      sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}
                                                    >
                                                      Completed!
                                                    </Typography>
                                                  </>
                                                )}
                                              </Stack>
                                            </Box>
                                          </Stack>
                                        </Grid>
                                        <Grid item>
                                          {' '}
                                          <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#7A7A7A' }}>
                                            {format(new Date(ele?.trail_date.replace(/-/g, '/')), 'dd MMM yy')}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  </CognisaasCard>
                                </Box>
                              );
                            })}
                          {projectUpgradeAlerts && Object.keys(projectUpgradeAlerts).length <= 0 ? (
                            <Grid container alignItems="center" justifyContent="center">
                              <Grid item>
                                <NoRecord style={{ width: '200px', height: '200px' }} />
                              </Grid>
                            </Grid>
                          ) : null}
                          {projectUpgradeAlerts == null ? (
                            <Grid container alignItems="center" justifyContent="center">
                              <Grid item>
                                <NoRecord style={{ width: '200px', height: '200px' }} />
                              </Grid>
                            </Grid>
                          ) : null}
                          {projectUpgradeAlerts && projectUpgradeAlerts == undefined ? (
                            <Grid container alignItems="center" justifyContent="center">
                              <Grid item>
                                <NoRecord style={{ width: '200px', height: '200px' }} />
                              </Grid>
                            </Grid>
                          ) : null}
                        </TabPanel>
                        <TabPanel value={value1} index={2}>
                          {usecaseUpgradeAlerts &&
                            usecaseUpgradeAlerts.length > 0 &&
                            usecaseUpgradeAlerts.map((ele: any, i: any) => {
                              return (
                                <Box style={{ marginTop: '3px' }} key={i}>
                                  <CognisaasCard
                                    clientStatusColor={ele.case_readinessstate_status}
                                    height="95px"
                                    width="100%"
                                  >
                                    <Box
                                      className="cognisaas-pointer"
                                      onClick={() =>
                                        navigate(
                                          `/projects/project/${ele.association_uid}/${ele.project_uid}/${ele.case_uid}`
                                        )
                                      }
                                      sx={{ padding: '20px 10px' }}
                                    >
                                      <Grid container justifyContent="space-between">
                                        <Grid item>
                                          <Stack direction="row">
                                            <Box>
                                              <Typography>
                                                <Avatar
                                                  style={{ height: '30px', width: '30px' }}
                                                  {...stringAvatar(ele.case_name)}
                                                />
                                              </Typography>
                                            </Box>
                                            <Box sx={{ ml: '10px' }}>
                                              <Typography>{ele.case_name}</Typography>
                                              <Stack direction="row" style={{ marginTop: '8px' }}>
                                                {ele.new_value === 'amber' && (
                                                  <>
                                                    <Typography
                                                      sx={{ fontSize: '12px', fontWeight: '400', color: '#7A7A7A' }}
                                                    >
                                                      Status changed from
                                                    </Typography>
                                                    <CircleIcon
                                                      sx={{
                                                        color: '#E75B5C',
                                                        height: '8px',
                                                        width: '8px',
                                                        marginTop: '5px',
                                                      }}
                                                    />
                                                    <Typography
                                                      sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}
                                                    >
                                                      Overdue
                                                    </Typography>
                                                    <Typography
                                                      sx={{
                                                        fontSize: '12px',
                                                        fontWeight: '400',
                                                        marginLeft: '13px',
                                                        color: '#7A7A7A',
                                                      }}
                                                    >
                                                      to
                                                    </Typography>
                                                    <CircleIcon
                                                      sx={{
                                                        color: '#EFA958',
                                                        height: '8px',
                                                        width: '8px',
                                                        marginTop: '5px',
                                                        marginLeft: '12px',
                                                      }}
                                                    />
                                                    <Typography
                                                      sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}
                                                    >
                                                      At Risk
                                                    </Typography>
                                                  </>
                                                )}
                                                {ele.new_value === 'green' && (
                                                  <>
                                                    <Typography
                                                      sx={{ fontSize: '12px', fontWeight: '400', color: '#7A7A7A' }}
                                                    >
                                                      Status changed from
                                                    </Typography>
                                                    <CircleIcon
                                                      sx={{
                                                        color: '#EFA958',
                                                        height: '8px',
                                                        width: '8px',
                                                        marginTop: '5px',
                                                        ml: '8px',
                                                      }}
                                                    />
                                                    <Typography
                                                      sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}
                                                    >
                                                      At Risk
                                                    </Typography>
                                                    <Typography
                                                      sx={{
                                                        fontSize: '12px',
                                                        fontWeight: '400',
                                                        marginLeft: '13px',
                                                        color: '#7A7A7A',
                                                      }}
                                                    >
                                                      to
                                                    </Typography>
                                                    <CircleIcon
                                                      sx={{
                                                        color: '#4F9F52',
                                                        height: '8px',
                                                        width: '8px',
                                                        marginTop: '5px',
                                                        marginLeft: '12px',
                                                      }}
                                                    />
                                                    <Typography
                                                      sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}
                                                    >
                                                      On Track
                                                    </Typography>
                                                  </>
                                                )}
                                                {ele.new_value === 'blue' && (
                                                  <>
                                                    <Typography
                                                      sx={{
                                                        fontSize: '12px',
                                                        fontWeight: '400',
                                                        color: '#7A7A7A',
                                                      }}
                                                    >
                                                      Status changed to
                                                    </Typography>
                                                    <CircleIcon
                                                      sx={{
                                                        color: '#4390E1',
                                                        height: '8px',
                                                        width: '8px',
                                                        marginTop: '5px',
                                                        ml: '8px',
                                                      }}
                                                    />
                                                    <Typography
                                                      sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}
                                                    >
                                                      Completed!
                                                    </Typography>
                                                  </>
                                                )}
                                              </Stack>
                                            </Box>
                                          </Stack>
                                        </Grid>
                                        <Grid item>
                                          <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#7A7A7A' }}>
                                            {format(new Date(ele?.trail_date.replace(/-/g, '/')), 'dd MMM yy')}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  </CognisaasCard>
                                </Box>
                              );
                            })}
                          {usecaseUpgradeAlerts && Object.keys(usecaseUpgradeAlerts).length <= 0 ? (
                            <Grid container alignItems="center" justifyContent="center">
                              <Grid item>
                                <NoRecord style={{ width: '200px', height: '200px' }} />
                              </Grid>
                            </Grid>
                          ) : null}
                          {usecaseUpgradeAlerts == null ? (
                            <Grid container alignItems="center" justifyContent="center">
                              <Grid item>
                                <NoRecord style={{ width: '200px', height: '200px' }} />
                              </Grid>
                            </Grid>
                          ) : null}
                          {usecaseUpgradeAlerts && usecaseUpgradeAlerts == undefined ? (
                            <Grid container alignItems="center" justifyContent="center">
                              <Grid item>
                                <NoRecord style={{ width: '200px', height: '200px' }} />
                              </Grid>
                            </Grid>
                          ) : null}
                        </TabPanel>
                      </>
                    ) : (
                      <>
                        <TabPanel value={value1} index={0}>
                          {teamClientUpgradeAlerts &&
                            teamClientUpgradeAlerts.length > 0 &&
                            teamClientUpgradeAlerts.map((ele: any, i: any) => {
                              return (
                                <Box style={{ marginTop: '3px' }} key={i}>
                                  <CognisaasCard
                                    clientStatusColor={ele.client_readiness_status}
                                    height="95px"
                                    width="100%"
                                  >
                                    <Box
                                      className="cognisaas-pointer"
                                      onClick={() => navigate(`/client-list/client/${ele.client_uid}`)}
                                      sx={{ padding: '20px 10px' }}
                                    >
                                      <Grid container justifyContent="space-between">
                                        <Grid item>
                                          <Stack direction="row">
                                            <Box>
                                              <Typography>
                                                <Avatar
                                                  style={{ height: '30px', width: '30px' }}
                                                  {...stringAvatar(ele.client_name)}
                                                />
                                              </Typography>
                                            </Box>
                                            <Box sx={{ ml: '10px' }}>
                                              <Typography>{ele.client_name}</Typography>

                                              <Stack direction="row" style={{ marginTop: '8px' }}>
                                                {ele.new_value === 'amber' && (
                                                  <>
                                                    <Typography
                                                      sx={{ fontSize: '12px', fontWeight: '400', color: '#7A7A7A' }}
                                                    >
                                                      Status changed from
                                                    </Typography>
                                                    <CircleIcon
                                                      sx={{
                                                        color: '#E75B5C',
                                                        height: '8px',
                                                        width: '8px',
                                                        marginTop: '5px',
                                                        ml: '8px',
                                                      }}
                                                    />
                                                    <Typography
                                                      sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}
                                                    >
                                                      Overdue
                                                    </Typography>
                                                    <Typography
                                                      sx={{
                                                        fontSize: '12px',
                                                        fontWeight: '400',
                                                        marginLeft: '13px',
                                                        color: '#7A7A7A',
                                                      }}
                                                    >
                                                      to
                                                    </Typography>
                                                    <CircleIcon
                                                      sx={{
                                                        color: '#EFA958',
                                                        height: '8px',
                                                        width: '8px',
                                                        marginTop: '5px',
                                                        marginLeft: '12px',
                                                      }}
                                                    />
                                                    <Typography
                                                      sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}
                                                    >
                                                      At Risk
                                                    </Typography>
                                                  </>
                                                )}
                                                {ele.new_value === 'green' && (
                                                  <>
                                                    <Typography
                                                      sx={{ fontSize: '12px', fontWeight: '400', color: '#7A7A7A' }}
                                                    >
                                                      Status changed from
                                                    </Typography>
                                                    <CircleIcon
                                                      sx={{
                                                        color: '#EFA958',
                                                        height: '8px',
                                                        width: '8px',
                                                        marginTop: '5px',
                                                        ml: '8px',
                                                      }}
                                                    />
                                                    <Typography
                                                      sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}
                                                    >
                                                      At Risk
                                                    </Typography>
                                                    <Typography
                                                      sx={{
                                                        fontSize: '12px',
                                                        fontWeight: '400',
                                                        marginLeft: '13px',
                                                        color: '#7A7A7A',
                                                      }}
                                                    >
                                                      to
                                                    </Typography>
                                                    <CircleIcon
                                                      sx={{
                                                        color: '#4F9F52',
                                                        height: '8px',
                                                        width: '8px',
                                                        marginTop: '5px',
                                                        marginLeft: '12px',
                                                      }}
                                                    />
                                                    <Typography
                                                      sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}
                                                    >
                                                      On Track
                                                    </Typography>
                                                  </>
                                                )}
                                                {ele.new_value === 'blue' && (
                                                  <>
                                                    <Typography
                                                      sx={{
                                                        fontSize: '12px',
                                                        fontWeight: '400',
                                                        color: '#7A7A7A',
                                                      }}
                                                    >
                                                      Status changed to
                                                    </Typography>
                                                    <CircleIcon
                                                      sx={{
                                                        color: '#4390E1',
                                                        height: '8px',
                                                        width: '8px',
                                                        marginTop: '5px',
                                                        ml: '8px',
                                                      }}
                                                    />
                                                    <Typography
                                                      sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}
                                                    >
                                                      Completed!
                                                    </Typography>
                                                  </>
                                                )}
                                              </Stack>
                                            </Box>
                                          </Stack>
                                        </Grid>
                                        <Grid item>
                                          <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#7A7A7A' }}>
                                            {format(new Date(ele?.trail_date.replace(/-/g, '/')), 'dd MMM yy')}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  </CognisaasCard>
                                </Box>
                              );
                            })}
                          {teamClientUpgradeAlerts && Object.keys(teamClientUpgradeAlerts).length <= 0 ? (
                            <Grid container alignItems="center" justifyContent="center">
                              <Grid item>
                                <NoRecord style={{ width: '200px', height: '200px' }} />
                              </Grid>
                            </Grid>
                          ) : null}
                          {teamClientUpgradeAlerts === null ? (
                            <Grid container alignItems="center" justifyContent="center">
                              <Grid item>
                                <NoRecord style={{ width: '200px', height: '200px' }} />
                              </Grid>
                            </Grid>
                          ) : null}
                          {teamClientUpgradeAlerts && teamClientUpgradeAlerts == undefined ? (
                            <Grid container alignItems="center" justifyContent="center">
                              <Grid item>
                                <NoRecord style={{ width: '200px', height: '200px' }} />
                              </Grid>
                            </Grid>
                          ) : null}
                        </TabPanel>
                        <TabPanel value={value1} index={1}>
                          {teamProjectUpgradeAlerts &&
                            teamProjectUpgradeAlerts.length > 0 &&
                            teamProjectUpgradeAlerts.map((ele: any, i: any) => {
                              return (
                                <Box style={{ marginTop: '3px' }} key={i}>
                                  <CognisaasCard
                                    clientStatusColor={ele.project_readinessstate_status}
                                    height="95px"
                                    width="100%"
                                  >
                                    <Box
                                      className="cognisaas-pointer"
                                      onClick={() =>
                                        navigate(`/projects/project/${ele.association_uid}/${ele.project_uid}`)
                                      }
                                      sx={{ padding: '20px 10px' }}
                                    >
                                      <Grid container justifyContent="space-between">
                                        <Grid item>
                                          <Stack direction="row">
                                            <Box>
                                              <Typography>
                                                <Avatar
                                                  style={{ height: '30px', width: '30px' }}
                                                  {...stringAvatar(ele.project_name)}
                                                />
                                              </Typography>
                                            </Box>
                                            <Box sx={{ ml: '10px' }}>
                                              <Typography>{ele.project_name}</Typography>

                                              <Stack direction="row" style={{ marginTop: '8px' }}>
                                                {ele.new_value === 'amber' && (
                                                  <>
                                                    <Typography
                                                      sx={{ fontSize: '12px', fontWeight: '400', color: '#7A7A7A' }}
                                                    >
                                                      Status changed from
                                                    </Typography>
                                                    <CircleIcon
                                                      sx={{
                                                        color: '#E75B5C',
                                                        height: '8px',
                                                        width: '8px',
                                                        marginTop: '5px',
                                                        ml: '8px',
                                                      }}
                                                    />
                                                    <Typography
                                                      sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}
                                                    >
                                                      Overdue
                                                    </Typography>
                                                    <Typography
                                                      sx={{
                                                        fontSize: '12px',
                                                        fontWeight: '400',
                                                        marginLeft: '13px',
                                                        color: '#7A7A7A',
                                                      }}
                                                    >
                                                      to
                                                    </Typography>
                                                    <CircleIcon
                                                      sx={{
                                                        color: '#EFA958',
                                                        height: '8px',
                                                        width: '8px',
                                                        marginTop: '5px',
                                                        marginLeft: '12px',
                                                      }}
                                                    />
                                                    <Typography
                                                      sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}
                                                    >
                                                      At Risk
                                                    </Typography>
                                                  </>
                                                )}
                                                {ele.new_value === 'green' && (
                                                  <>
                                                    <Typography
                                                      sx={{ fontSize: '12px', fontWeight: '400', color: '#7A7A7A' }}
                                                    >
                                                      Status changed from
                                                    </Typography>
                                                    <CircleIcon
                                                      sx={{
                                                        color: '#EFA958',
                                                        height: '8px',
                                                        width: '8px',
                                                        marginTop: '5px',
                                                        ml: '8px',
                                                      }}
                                                    />
                                                    <Typography
                                                      sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}
                                                    >
                                                      At Risk
                                                    </Typography>
                                                    <Typography
                                                      sx={{
                                                        fontSize: '12px',
                                                        fontWeight: '400',
                                                        marginLeft: '13px',
                                                        color: '#7A7A7A',
                                                      }}
                                                    >
                                                      to
                                                    </Typography>
                                                    <CircleIcon
                                                      sx={{
                                                        color: '#4F9F52',
                                                        height: '8px',
                                                        width: '8px',
                                                        marginTop: '5px',
                                                        marginLeft: '12px',
                                                      }}
                                                    />
                                                    <Typography
                                                      sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}
                                                    >
                                                      On Track
                                                    </Typography>
                                                  </>
                                                )}
                                                {ele.new_value === 'blue' && (
                                                  <>
                                                    <Typography
                                                      sx={{
                                                        fontSize: '12px',
                                                        fontWeight: '400',
                                                        color: '#7A7A7A',
                                                      }}
                                                    >
                                                      Status changed to
                                                    </Typography>
                                                    <CircleIcon
                                                      sx={{
                                                        color: '#4390E1',
                                                        height: '8px',
                                                        width: '8px',
                                                        marginTop: '5px',
                                                        ml: '8px',
                                                      }}
                                                    />
                                                    <Typography
                                                      sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}
                                                    >
                                                      Completed!
                                                    </Typography>
                                                  </>
                                                )}
                                              </Stack>
                                            </Box>
                                          </Stack>
                                        </Grid>
                                        <Grid item>
                                          {' '}
                                          <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#7A7A7A' }}>
                                            {format(new Date(ele?.trail_date.replace(/-/g, '/')), 'dd MMM yy')}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  </CognisaasCard>
                                </Box>
                              );
                            })}
                          {teamProjectUpgradeAlerts && Object.keys(teamProjectUpgradeAlerts).length <= 0 ? (
                            <Grid container alignItems="center" justifyContent="center">
                              <Grid item>
                                <NoRecord style={{ width: '200px', height: '200px' }} />
                              </Grid>
                            </Grid>
                          ) : null}
                          {teamProjectUpgradeAlerts == null ? (
                            <Grid container alignItems="center" justifyContent="center">
                              <Grid item>
                                <NoRecord style={{ width: '200px', height: '200px' }} />
                              </Grid>
                            </Grid>
                          ) : null}
                          {teamProjectUpgradeAlerts && teamProjectUpgradeAlerts == undefined ? (
                            <Grid container alignItems="center" justifyContent="center">
                              <Grid item>
                                <NoRecord style={{ width: '200px', height: '200px' }} />
                              </Grid>
                            </Grid>
                          ) : null}
                        </TabPanel>
                        <TabPanel value={value1} index={2}>
                          {teamUseCaseUpgradeAlerts &&
                            teamUseCaseUpgradeAlerts.length > 0 &&
                            teamUseCaseUpgradeAlerts.map((ele: any, i: any) => {
                              return (
                                <Box style={{ marginTop: '3px' }} key={i}>
                                  <CognisaasCard
                                    clientStatusColor={ele.case_readinessstate_status}
                                    height="95px"
                                    width="100%"
                                  >
                                    <Box
                                      className="cognisaas-pointer"
                                      onClick={() =>
                                        navigate(
                                          `/projects/project/${ele.association_uid}/${ele.project_uid}/${ele.case_uid}`
                                        )
                                      }
                                      sx={{ padding: '20px 10px' }}
                                    >
                                      <Grid container justifyContent="space-between">
                                        <Grid item>
                                          <Stack direction="row">
                                            <Box>
                                              <Typography>
                                                <Avatar
                                                  style={{ height: '30px', width: '30px' }}
                                                  {...stringAvatar(ele.case_name)}
                                                />
                                              </Typography>
                                            </Box>
                                            <Box sx={{ ml: '10px' }}>
                                              <Typography>{ele.case_name}</Typography>
                                              <Stack direction="row" style={{ marginTop: '8px' }}>
                                                {ele.new_value === 'amber' && (
                                                  <>
                                                    <Typography
                                                      sx={{ fontSize: '12px', fontWeight: '400', color: '#7A7A7A' }}
                                                    >
                                                      Status changed from
                                                    </Typography>
                                                    <CircleIcon
                                                      sx={{
                                                        color: '#E75B5C',
                                                        height: '8px',
                                                        width: '8px',
                                                        marginTop: '5px',
                                                      }}
                                                    />
                                                    <Typography
                                                      sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}
                                                    >
                                                      Overdue
                                                    </Typography>
                                                    <Typography
                                                      sx={{
                                                        fontSize: '12px',
                                                        fontWeight: '400',
                                                        marginLeft: '13px',
                                                        color: '#7A7A7A',
                                                      }}
                                                    >
                                                      to
                                                    </Typography>
                                                    <CircleIcon
                                                      sx={{
                                                        color: '#EFA958',
                                                        height: '8px',
                                                        width: '8px',
                                                        marginTop: '5px',
                                                        marginLeft: '12px',
                                                      }}
                                                    />
                                                    <Typography
                                                      sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}
                                                    >
                                                      At Risk
                                                    </Typography>
                                                  </>
                                                )}
                                                {ele.new_value === 'green' && (
                                                  <>
                                                    <Typography
                                                      sx={{ fontSize: '12px', fontWeight: '400', color: '#7A7A7A' }}
                                                    >
                                                      Status changed from
                                                    </Typography>
                                                    <CircleIcon
                                                      sx={{
                                                        color: '#EFA958',
                                                        height: '8px',
                                                        width: '8px',
                                                        marginTop: '5px',
                                                        ml: '8px',
                                                      }}
                                                    />
                                                    <Typography
                                                      sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}
                                                    >
                                                      At Risk
                                                    </Typography>
                                                    <Typography
                                                      sx={{
                                                        fontSize: '12px',
                                                        fontWeight: '400',
                                                        marginLeft: '13px',
                                                        color: '#7A7A7A',
                                                      }}
                                                    >
                                                      to
                                                    </Typography>
                                                    <CircleIcon
                                                      sx={{
                                                        color: '#4F9F52',
                                                        height: '8px',
                                                        width: '8px',
                                                        marginTop: '5px',
                                                        marginLeft: '12px',
                                                      }}
                                                    />
                                                    <Typography
                                                      sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}
                                                    >
                                                      On Track
                                                    </Typography>
                                                  </>
                                                )}
                                                {ele.new_value === 'blue' && (
                                                  <>
                                                    <Typography
                                                      sx={{
                                                        fontSize: '12px',
                                                        fontWeight: '400',
                                                        color: '#7A7A7A',
                                                      }}
                                                    >
                                                      Status changed to
                                                    </Typography>
                                                    <CircleIcon
                                                      sx={{
                                                        color: '#4390E1',
                                                        height: '8px',
                                                        width: '8px',
                                                        marginTop: '5px',
                                                        ml: '8px',
                                                      }}
                                                    />
                                                    <Typography
                                                      sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}
                                                    >
                                                      Completed!
                                                    </Typography>
                                                  </>
                                                )}
                                              </Stack>
                                            </Box>
                                          </Stack>
                                        </Grid>
                                        <Grid item>
                                          <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#7A7A7A' }}>
                                            {format(new Date(ele?.trail_date.replace(/-/g, '/')), 'dd MMM yy')}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  </CognisaasCard>
                                </Box>
                              );
                            })}
                          {teamUseCaseUpgradeAlerts && Object.keys(teamUseCaseUpgradeAlerts).length <= 0 ? (
                            <Grid container alignItems="center" justifyContent="center">
                              <Grid item>
                                <NoRecord style={{ width: '200px', height: '200px' }} />
                              </Grid>
                            </Grid>
                          ) : null}
                          {teamUseCaseUpgradeAlerts == null ? (
                            <Grid container alignItems="center" justifyContent="center">
                              <Grid item>
                                <NoRecord style={{ width: '200px', height: '200px' }} />
                              </Grid>
                            </Grid>
                          ) : null}
                          {teamUseCaseUpgradeAlerts && teamUseCaseUpgradeAlerts == undefined ? (
                            <Grid container alignItems="center" justifyContent="center">
                              <Grid item>
                                <NoRecord style={{ width: '200px', height: '200px' }} />
                              </Grid>
                            </Grid>
                          ) : null}
                        </TabPanel>
                      </>
                    )}
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Paper sx={{ padding: '20px', marginTop: '22px', boxShadow: '0px 4px 26px rgba(0, 0, 0, 0.05)' }}>
              <DeliverablesTimeline valueForTeamDashboard={valueForTeamDashboard} />
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Dashboard;
