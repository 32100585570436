import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { getFilteredData } from './helperFunction';
import { GridColDef, GridRenderCellParams, GridToolbar, GridColumnOrderChangeParams } from '@mui/x-data-grid';
import { DataGridPro } from '@mui/x-data-grid-pro';
import CognisaasLoader from '../../../shared/CognisaasLoader/CognisaasLoader';
import { FilterableData, generateOptionsForThisDataKey } from 'utils/filteringFunctions';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectAllFilters } from 'store/modules/AllFilters/selector';
import { generateOrderedColumn, isNull, isUndefined } from 'utils/dataHelpers';
import { addFilterGroup } from 'store/modules/AllFilters/slice';
import { Link } from 'react-router-dom';
import {
  label_client,
  label_project,
  label_project_delivery_manager,
} from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { selectJourneyStage, selectUsersOfCompany, selectUsersOfCompanyStatus } from 'store/modules/Common/selector';
import { fetchAllJourneyStages, getAllUsers } from 'store/modules/Common/slice';
import { CustomizedProgressBars } from 'components/shared/CognisaasProgressBar/ProgressBar';
import { fetchClientList } from 'store/modules/ManageClient/ClientList/slice';
import { selectClientList } from 'store/modules/ManageClient/ClientList/selector';
import checkAccessForRouting from 'components/shared/Hooks/checkAccessForRouting';
import CognisaaasTooltip from 'components/shared/CognisaasTooltip/CognisaasTooltip';
import useCommonAppConfig from 'components/Common/useCommonAppConfig';

interface PerformaCards_payload {
  total_projects: string;
  average_delay: string;
  percent_value: string;
  top_text: string;
}

const PerformaCards = (props: PerformaCards_payload) => {
  return (
    <Box
      sx={{
        display: 'grid',
        borderRadius: '4px',
        backgroundColor: '#EBEBEB93',
        gridTemplateColumns: '150px 1fr',
        padding: '5px',
        gridTemplateRows: '120px',
      }}
    >
      <Box
        sx={{
          margin: '5px',
          padding: '25px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'white',
          borderRadius: '4px',
        }}
      >
        <Typography variant="h2">{props.total_projects}</Typography>
        <Typography variant="h3">Total {label_project}s</Typography>
      </Box>
      <Box sx={{ display: 'grid', justifyContent: 'flex-start', alignItems: 'center', margin: '5px' }}>
        <Typography variant="h2">{props.top_text}</Typography>
        <Typography variant="h2">{props.percent_value}</Typography>
        <Typography variant="subtitle1">
          <span style={{ color: 'red' }}>{props.average_delay}</span> Average Delay
        </Typography>
      </Box>
    </Box>
  );
};

interface projectsPayload {
  data: any;
}

const ProjectsTable = (props: any) => {
  const dispatch = useAppDispatch();

  const [tableData, setTableData] = useState<any>([]);
  // const [pageSize, setPageSize] = useState(5);
  const [tableColumns, setTableColumns] = useState<any[]>([]);
  let myTableState: any = {};
  const { saveAppTableState, getTableState } = useCommonAppConfig();
  const initialState: any = {
    columns: {},
    pinnedColumns: {
      left: ['project_name'],
    },
    pagination: {
      page: 0,
      pageSize: 10,
    },
    ...getTableState('performaneProjectReportProject'),
  };

  const handleTableSave = (idName: string, thisTableState: any) => {
    saveAppTableState(idName, thisTableState);
  };

  const clientList = useAppSelector(selectClientList);

  const handleClientAccess = (params: GridRenderCellParams) => {
    const arrayToCheck = clientList.map((ele) => ele.client_uid);
    const hasAccessToClient = checkAccessForRouting({ arrayToCheck: arrayToCheck, uid: params.row.client_uid });
    console.log(params.value);
    if (hasAccessToClient == true) {
      return <Link to={getClientPageURL(params.row)}>{params.value}</Link>;
    }
    if (hasAccessToClient == false) {
      return (
        <CognisaaasTooltip
          title={<Typography sx={{ padding: '5px' }}>You do not have access to this {label_client}</Typography>}
        >
          <Typography variant="subtitle1">{params.value}</Typography>
        </CognisaaasTooltip>
      );
    }
  };

  const uniqueColumnId = 'reportPerformanceProjectListTable';

  useEffect(() => {
    setTableData(props.data);
    setTableColumns(generateOrderedColumn(ProjectTableColumnsMapping, uniqueColumnId));
  }, [props]);

  useEffect(() => {
    dispatch(fetchClientList());
  }, []);

  const getClientPageURL = (row: any) => {
    return `/client-list/client/${row.client_uid}`;
  };

  const getProjectPageURL = (row: any) => {
    return `/projects/project/${row.association_uid}/${row.project_uid}`;
  };
  const ProjectTableColumnsMapping: GridColDef[] = [
    {
      field: 'project_name',
      headerName: `${label_project} Name`,
      width: 190,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderHeader: (header: any) => <Typography variant="h3">{header.colDef.headerName}</Typography>,
      renderCell: (params: GridRenderCellParams) => {
        return <Link to={getProjectPageURL(params.row)}>{params.value}</Link>;
      },
    },
    {
      field: 'client_name',
      headerName: `${label_client} Name`,
      width: 160,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderHeader: (header: any) => <Typography variant="h3">{header.colDef.headerName}</Typography>,
      renderCell: (params: GridRenderCellParams) => handleClientAccess(params),
    },
    {
      field: 'project_progress',
      headerName: 'Progress (in %)',
      width: 220,
      renderCell: (params: GridRenderCellParams) => (
        <CustomizedProgressBars value={params.value} statusColor={'grees'} hidePercentage={false} />
      ),
    },
    {
      field: 'project_planned_startdate',
      headerName: 'Planned Start Date',
      width: 160,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      type: 'date',
      // valueGetter: ({ value }: any) => value && new Date(value),
      renderHeader: (header: any) => <Typography variant="h3">{header.colDef.headerName}</Typography>,
      renderCell: (obj: any) => <Typography variant="h3">{obj.row.project_planned_startdate.slice(0, 10)}</Typography>,
    },
    {
      field: 'project_startdate',
      headerName: 'Actual Start Date',
      width: 160,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      type: 'date',
      // valueGetter: ({ value }: any) => value && new Date(value),
      renderHeader: (header: any) => <Typography variant="h3">{header.colDef.headerName}</Typography>,
      renderCell: (obj: any) => <Typography variant="h3">{obj.row.project_startdate.slice(0, 10)}</Typography>,
    },
    {
      field: 'starting_delay',
      headerName: 'Starting Delay',
      width: 160,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderHeader: (header: any) => <Typography variant="h3">{header.colDef.headerName}</Typography>,
      renderCell: (obj: any) => <Typography variant="h3">{obj.row.starting_delay}</Typography>,
    },
    {
      field: 'eta_forprojectdelivery',
      headerName: 'Planned End Date',
      width: 160,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      type: 'date',
      // valueGetter: ({ value }: any) => value && new Date(value),
      renderHeader: (header: any) => <Typography variant="h3">{header.colDef.headerName}</Typography>,
      renderCell: (obj: any) => <Typography variant="h3">{obj.row.eta_forprojectdelivery.slice(0, 10)}</Typography>,
    },
    {
      field: 'project_deliverydate',
      headerName: 'Actual End Date',
      width: 160,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      type: 'date',
      // valueGetter: ({ value }: any) => value && new Date(value),
      renderHeader: (header: any) => <Typography variant="h3">{header.colDef.headerName}</Typography>,
      renderCell: (obj: any) => <Typography variant="h3">{obj.row.project_deliverydate.slice(0, 10)}</Typography>,
    },
    {
      field: 'ending_delay',
      headerName: 'Ending Delay',
      width: 160,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderHeader: (header: any) => <Typography variant="h3">{header.colDef.headerName}</Typography>,
      renderCell: (obj: any) => <Typography variant="h3">{obj.row.ending_delay}</Typography>,
    },
    {
      field: 'planned_duration',
      headerName: 'Duration Planned',
      width: 160,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderHeader: (header: any) => <Typography variant="h3">{header.colDef.headerName}</Typography>,
      renderCell: (obj: any) => <Typography variant="h3">{obj.row.planned_duration}</Typography>,
    },
    {
      field: 'actual_duration',
      headerName: 'Duration Actual',
      width: 160,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderHeader: (header: any) => <Typography variant="h3">{header.colDef.headerName}</Typography>,
      renderCell: (obj: any) => <Typography variant="h3">{obj.row.actual_duration}</Typography>,
    },
    {
      field: 'project_stage',
      headerName: 'Stage',
      width: 160,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderHeader: (header: any) => <Typography variant="h3">{header.colDef.headerName}</Typography>,
      renderCell: (obj: any) => <Typography variant="h3">{obj.row.project_stage}</Typography>,
    },
    {
      field: 'project_deliverymanager',
      headerName: label_project_delivery_manager,
      width: 160,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderHeader: (header: any) => <Typography variant="h3">{header.colDef.headerName}</Typography>,
      renderCell: (obj: any) => <Typography variant="h3">{obj.row.project_deliverymanager}</Typography>,
    },
  ];

  const handleColumnOrderChange = (params: GridColumnOrderChangeParams) => {
    const oldArrayIndex = params.oldIndex;
    const targetArrayIndex = params.targetIndex;

    setTableColumns(generateOrderedColumn(tableColumns, uniqueColumnId, oldArrayIndex, targetArrayIndex));
  };

  return !props.showLoader ? (
    <DataGridPro
      rows={tableData}
      initialState={initialState}
      onStateChange={(tblState: any) => {
        myTableState = tblState;
      }}
      onColumnVisibilityModelChange={() => {
        handleTableSave('performaneProjectReportProject', myTableState);
      }}
      onFilterModelChange={() => {
        handleTableSave('performaneProjectReportProject', myTableState);
      }}
      onPageSizeChange={() => {
        handleTableSave('performaneProjectReportProject', myTableState);
      }}
      components={{
        Toolbar: GridToolbar,
      }}
      componentsProps={{
        toolbar: { showQuickFilter: true },
      }}
      autoHeight={true}
      columns={tableColumns}
      getRowId={(obj: any) => obj.project_uid}
      pagination
      rowsPerPageOptions={[5, 10, 25, 50, 100]}
      // pageSize={pageSize}
      // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      rowHeight={52}
      onColumnOrderChange={handleColumnOrderChange}
    />
  ) : (
    <CognisaasLoader />
  );
};

const Projects = (props: projectsPayload) => {
  const dispatch = useAppDispatch();
  const allFilters = useAppSelector(selectAllFilters);
  const [finalData, setFinalData] = useState<any>([]);
  const [showLoader, setShowLoader] = useState(true);
  const [project_completedOnTime, setProject_completedOnTime] = useState<Record<string, any>>({
    total_projects: 0,
    average_delay: 0,
    percent_value: 0,
    top_text: '',
  });
  const [project_starteddOnTime, setProject_starteddOnTime] = useState<Record<string, any>>({
    total_projects: 0,
    average_delay: 0,
    percent_value: 0,
    top_text: '',
  });
  const [project_averageDuartion, setProject_averageDuartion] = useState<Record<string, any>>({
    total_projects: 0,
    average_delay: 0,
    total_days: 0,
    top_text: '',
  });
  const [filteredPerfProjects, setFilteredPerfProjects] = useState<any>([]);
  const allUsers = useAppSelector(selectUsersOfCompany);
  const allUsersStatus = useAppSelector(selectUsersOfCompanyStatus);
  const journeyStages = useAppSelector(selectJourneyStage);

  useEffect(() => {
    if (props.data != undefined && props.data?.length != 0) {
      const finalData_getting = getFilteredData(props.data);
      setFinalData(finalData_getting);
      setShowLoader(false);
    }
  }, [props.data]);

  useEffect(() => {
    if (allUsersStatus === 'idle' || allUsersStatus === 'failed') {
      dispatch(getAllUsers());
    }

    if (journeyStages.status === 'idle' || journeyStages.status === 'failed') {
      dispatch(fetchAllJourneyStages());
    }
  }, [allUsersStatus, journeyStages]);

  useEffect(() => {
    if (allUsersStatus === 'success' && journeyStages.status === 'success' && finalData.length > 0) {
      if (
        isUndefined(allFilters['performanceProjectsOfProjectPage']) ||
        isNull(allFilters['performanceProjectsOfProjectPage'])
      ) {
        dispatch(
          addFilterGroup({
            idName: 'performanceProjectsOfProjectPage',
            appliedFilters: [
              {
                name: `${label_project} Name`,
                dataKey: 'project_name',
                isSelected: false,
                equalToValue: [],
                canBeCleared: true,
                availableOptions: generateOptionsForThisDataKey(finalData, 'project_name'),
              },
              {
                name: `${label_client} Name`,
                dataKey: 'client_name',
                isSelected: false,
                equalToValue: [],
                canBeCleared: true,
                availableOptions: generateOptionsForThisDataKey(finalData, 'client_name'),
              },
              {
                name: 'Planned Start Date$$date-range-picker',
                dataKey: 'project_planned_startdate',
                isSelected: false,
                equalToValue: [],
                canBeCleared: true,
                availableOptions: [],
              },
              {
                name: 'Actual Start Date$$date-range-picker',
                dataKey: 'project_startdate',
                isSelected: false,
                equalToValue: [],
                canBeCleared: true,
                availableOptions: [],
              },
              {
                name: 'Starting Delay$$number-range',
                dataKey: 'starting_delay',
                isSelected: false,
                equalToValue: [],
                canBeCleared: true,
                availableOptions: [],
              },
              {
                name: 'Planned End Date$$date-range-picker',
                dataKey: 'eta_forprojectdelivery',
                isSelected: false,
                equalToValue: [],
                canBeCleared: true,
                availableOptions: [],
              },

              {
                name: 'Actual End Date$$date-range-picker',
                dataKey: 'project_deliverydate',
                isSelected: false,
                equalToValue: [],
                canBeCleared: true,
                availableOptions: [],
              },

              {
                name: 'Ending Delay$$number-range',
                dataKey: 'ending_delay',
                isSelected: false,
                equalToValue: [],
                canBeCleared: true,
                availableOptions: [],
              },

              {
                name: 'Duration Planned$$number-range',
                dataKey: 'planned_duration',
                isSelected: false,
                equalToValue: [],
                canBeCleared: true,
                availableOptions: [],
              },

              {
                name: 'Duration Actual$$number-range',
                dataKey: 'actual_duration',
                isSelected: false,
                equalToValue: [],
                canBeCleared: true,
                availableOptions: [],
              },
              {
                name: 'Stage',
                dataKey: 'project_stage',
                isSelected: false,
                equalToValue: [],
                canBeCleared: true,
                availableOptions: journeyStages.data.map((item: any) => item.stage_journerystage),
              },

              {
                name: label_project_delivery_manager,
                dataKey: 'project_deliverymanager',
                isSelected: false,
                equalToValue: [],
                canBeCleared: true,
                availableOptions: ['Not Assigned', ...allUsers.map((item: any) => item.user_username)],
              },
            ],
          })
        );
      }
    }
  }, [allFilters, allUsersStatus, journeyStages, finalData]);

  useEffect(() => {
    if (allFilters['performanceProjectsOfProjectPage'] && finalData) {
      const newFilteredData = new FilterableData([...finalData]);
      newFilteredData.bulkFilter(allFilters['performanceProjectsOfProjectPage'].appliedFilters);
      setFilteredPerfProjects([...newFilteredData.toArray()]);
    }
  }, [allFilters, dispatch, finalData]);

  useEffect(() => {
    const calculateFor_project_completed_onTime = () => {
      let projectCompletedOnTime_count = 0;
      filteredPerfProjects?.map((obj: any) => {
        if (obj.isProjectCompletedOnTime == 0) {
          projectCompletedOnTime_count = projectCompletedOnTime_count + 1;
        }
      });
      let endingDelayAverage = 0;
      let endingDelayAverage_count = 0;
      filteredPerfProjects?.map((obj: any) => {
        if (obj.ending_delay <= 0) {
          endingDelayAverage = endingDelayAverage + (isNaN(obj.ending_delay) ? 0 : Math.abs(obj.ending_delay));
          endingDelayAverage_count = endingDelayAverage_count + 1;
        }
      });
      endingDelayAverage = endingDelayAverage / endingDelayAverage_count;
      setProject_completedOnTime({
        total_projects: projectCompletedOnTime_count,
        average_delay: endingDelayAverage,
        percent_value: (projectCompletedOnTime_count / filteredPerfProjects?.length) * 100,
        top_text: `${label_project}s Completed on Time`,
      });
    };

    const calculateFor_project_started_onTime = () => {
      let projectStartedOnTime_count = 0;
      filteredPerfProjects?.map((obj: any) => {
        if (obj.isProjectStartedOnTime == 1) {
          projectStartedOnTime_count = projectStartedOnTime_count + 1;
        }
      });
      let startingDelayAverage = 0;
      let startingDelayAverage_count = 0;
      filteredPerfProjects?.map((obj: any) => {
        if (obj.starting_delay <= 0) {
          startingDelayAverage = startingDelayAverage + (isNaN(obj.starting_delay) ? 0 : Math.abs(obj.starting_delay));
          startingDelayAverage_count = startingDelayAverage_count + 1;
        }
      });
      startingDelayAverage = startingDelayAverage / startingDelayAverage_count;
      setProject_starteddOnTime({
        total_projects: projectStartedOnTime_count,
        average_delay: startingDelayAverage,
        percent_value: (projectStartedOnTime_count * 100) / filteredPerfProjects?.length,
        top_text: `${label_project}s Started on Time`,
      });
    };
    const calculateFor_project_average_duration = () => {
      let projectAverageDuration_count = 0;
      let actualDuartionOfproject = 0;
      let actualDuartionOfproject_count = 0;
      let plannedDurationOfProject = 0;
      let plannedDurationOfProject_count = 0;
      filteredPerfProjects?.map((obj: any) => {
        if (obj.actualDuration != 0 && obj.planned_duration != 0) {
          projectAverageDuration_count = projectAverageDuration_count + 1;
          actualDuartionOfproject =
            actualDuartionOfproject + (isNaN(obj.starting_delay) ? 0 : Math.abs(obj.starting_delay));
          actualDuartionOfproject_count = actualDuartionOfproject_count + 1;
          plannedDurationOfProject =
            plannedDurationOfProject + (isNaN(obj.planned_duration) ? 0 : Math.abs(obj.planned_duration));
          plannedDurationOfProject_count = plannedDurationOfProject_count + 1;
        }
      });
      actualDuartionOfproject = actualDuartionOfproject / actualDuartionOfproject_count;
      plannedDurationOfProject = plannedDurationOfProject / plannedDurationOfProject_count;
      setProject_averageDuartion({
        top_text: `Average ${label_project} Duration`,
        total_projects: filteredPerfProjects.length ? filteredPerfProjects.length : 0,
        total_days: Math.abs(actualDuartionOfproject) ? Math.abs(actualDuartionOfproject) : 0,
        average_delay: Math.abs(actualDuartionOfproject - plannedDurationOfProject)
          ? Math.abs(actualDuartionOfproject - plannedDurationOfProject) / projectAverageDuration_count
          : 0,
      });
    };

    calculateFor_project_completed_onTime();
    calculateFor_project_started_onTime();
    calculateFor_project_average_duration();
  }, [filteredPerfProjects]);

  return (
    <Box
      sx={{
        display: 'grid',
        width: '100%',
        backgroundColor: 'white',
        gridGap: '20px',
        justifyContent: 'space-between',
        alignItems: 'center',
        gridTemplateRows: 'auto auto',
        gridTemplateColumns: '1fr',
        padding: '20px',
      }}
    >
      <Box
        sx={{
          display: 'grid',
          justifyContent: 'space-evenly',
          alignItems: 'stretch',
          gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 400px))',
          gridRowGap: '10px',
        }}
      >
        <PerformaCards
          total_projects={project_completedOnTime.total_projects}
          average_delay={project_completedOnTime.average_delay.toFixed(2)}
          percent_value={`${project_completedOnTime.percent_value?.toFixed(1)}%`}
          top_text={project_completedOnTime.top_text}
        />
        <PerformaCards
          total_projects={project_starteddOnTime.total_projects}
          average_delay={project_starteddOnTime.average_delay.toFixed(2)}
          percent_value={`${project_starteddOnTime.percent_value.toFixed(1)}%`}
          top_text={project_starteddOnTime.top_text}
        />
        <PerformaCards
          total_projects={project_averageDuartion.total_projects}
          average_delay={`${project_averageDuartion.average_delay.toFixed(2)} Days`}
          percent_value={`${project_averageDuartion.total_days.toFixed(1)} Days`}
          top_text={`Average ${label_project} Duration`}
        />
      </Box>
      <Box sx={{ display: 'grid', justifyContent: 'center', alignItems: 'center', gridTemplateColumns: '1fr' }}>
        <ProjectsTable data={filteredPerfProjects} showLoader={showLoader} />
      </Box>
    </Box>
  );
};

export default Projects;
