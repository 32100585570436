import { apis } from '../axiosInstance';
import { raidRoutes } from '../route';

const createRaid = (detail: any) => {
    return apis.phpAxiosInstance.post(raidRoutes.createRaid(), detail, {
        headers: {
            JWT:'true'
        }
    });
};

const getRaid = (id: string) => {
    return apis.phpAxiosInstance.get(raidRoutes.getRaidByUid(id), {
        headers: {
            JWT:'true'
        }
    });
};

const updateRaid = ( detail: any) => {
    return apis.phpAxiosInstance.post(raidRoutes.updateRaid(), detail, {
        headers: {
            JWT:'true'
        }
    });
};

const deleteRaid = (detail:any) => {
    return apis.phpAxiosInstance.delete(raidRoutes.deleteRaid(), {
        headers: {
            JWT:'true'
        },
        data: detail
    });
};

const getRaidByProjectUid = (id: string) => {
    return apis.phpAxiosInstance.get(raidRoutes.getRaidByProjectUid(id), {
        headers: {
            JWT:'true'
        }
    });
};

const bulkDeleteRaid = (detail:any) => {
    return apis.phpAxiosInstance.post(raidRoutes.bulkDeleteRaid(), detail,{
        headers: {
            JWT:'true'
        }
    });
};

const addRaidComment = (detail: any) => {
    return apis.phpAxiosInstance.post(raidRoutes.addRaidComment(), detail, {
        headers: {
            JWT:'true'
        }
    });
};

const getProjectRaidCommentByRaidUid = (raid_uid: string) => {
    return apis.phpAxiosInstance.get(raidRoutes.getRaidComments(raid_uid), {
        headers: {
            JWT:'true'
        }
    });
};

const updateRaidComment = (detail: any) => {
    return apis.phpAxiosInstance.post(raidRoutes.updateRaidComment(), detail, {
        headers: {
            JWT:'true'
        }
    });
};

const deleteRaidComment = (detail: any) => {
    return apis.phpAxiosInstance.post(raidRoutes.deleteRaidComment(),detail, {
        headers: {
            JWT:'true'
        }
    });
};
    


export const raidApi = {
    createRaid,
    getRaid,
    updateRaid,
    deleteRaid,
    getRaidByProjectUid,
    bulkDeleteRaid,
    addRaidComment,
    getProjectRaidCommentByRaidUid,
    updateRaidComment,
    deleteRaidComment
};
