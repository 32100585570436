import styled from 'styled-components';

export const Input = styled('input')`
  display: inline-block;
  vertical-align: middle;
  appearance: none;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSizes[7]};
  padding: ${({ theme }) => theme.space[4]};
  border-radius: ${({ theme }) => theme.space[2]};
  border: none;
  /* width: 4; */
  /* border: 1;
  border-radius: 2;
  color: ${({ theme }) => theme.colors.grey_3};
  background-color: ${({ theme }) => theme.colors.grey_5};
  border-color: ${({ theme }) => theme.colors.grey_1};
  &:focus {
    outline: 'none';
    border-color: ${({ theme }) => theme.colors.grey_4};
  }
  &:disabled {
    opacity: 0.25;
    background-color: ${({ theme }) => theme.colors.grey_4};
  } */
`;
