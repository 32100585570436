import { useState, useEffect } from 'react';

import { Drawer, Box, Stack, Grid, Typography } from '@mui/material';
import CognisaasTabs from 'components/shared/CognisaasTabs';
import Resources from './Resources';
import Pages from './Pages';
import { RolesDetailProps } from 'components/RBACv2/interfaces';

export default function RolesDetail(props: RolesDetailProps) {
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [selectedPages, setSelectedPages] = useState<any[]>(props?.selectedRole?.pages);
  const [selectedPolicies, setSelectedPolicies] = useState<any[]>(props?.selectedRole?.policies);
  const switchTabs = (event: React.SyntheticEvent, value: number) => setTabIndex(value);

  useEffect(() => {
    setTabIndex(0);
  }, [props.drawerState]);

  useEffect(() => {
    setSelectedPages(props?.selectedRole?.pages);
    setSelectedPolicies(props?.selectedRole?.policies);
  }, [props]);



  const addPage = (page:any) => setSelectedPages(() => [...selectedPages, page]);
  const removePage = (selectedPage:any) => {
    const filteredPages = selectedPages.filter((page:any) => page.id !== selectedPage.id);
    setSelectedPages([...filteredPages]);
  };

  const addPolicy = (policy:any) => {
    setSelectedPolicies(() => [...selectedPolicies, policy]);
  };
  const removePolicy = (selectedPolicy:any) => {
    const filteredPolices = selectedPolicies.filter((policy:any) => policy.id !== selectedPolicy.id);
    setSelectedPolicies([...filteredPolices]);
  };


  return (
    <Drawer
      anchor={'right'}
      open={props.drawerState}
      onClose={() => props.onDrawerClose()}
      sx={{
        width: '700px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '20px',
          marginTop: '70px',
          width: '600px',
        }}
      >
        {props.selectedRole && Object.keys(props.selectedRole).length > 0 && (
          <>
            <Grid>
              <Stack direction="row" alignItems="center" justifyContent={'space-between'}>
                <Typography variant="h2" style={{ marginRight: '15px' }}>
                  {props.selectedRole.name}
                </Typography>
                {props?.selectedRole.is_public && (
                  <>
                    <Stack direction="row">
                      <div
                        style={{
                          padding: '2px 10px',
                          textAlign: 'center',
                          borderRadius: '4px',
                          border: '1px solid #3D38BA',
                          width: 'fit-content',
                        }}
                      >
                        <Typography variant="subtitle1" color="#3D38BA" style={{ fontSize: '11px' }}>
                          Cognisaas Managed
                        </Typography>
                      </div>
                      <div
                        style={{
                          width: 'fit-content',
                          padding: '2px 10px',
                          borderRadius: '4px',
                          border: '1px solid #0061bb',
                          backgroundColor: '#d8ecff',
                          marginLeft: '5px',
                        }}
                      >
                        <Typography variant="subtitle1" color="#0061bb" style={{ fontSize: '11px' }}>
                          The policy is managed by Cognisaas, cannot be edited
                        </Typography>
                      </div>
                    </Stack>
                  </>
                )}
              </Stack>
              <Typography variant={'h3'} sx={{ mt: '15px' }}>
                {props.selectedRole.description}
              </Typography>
              <Typography variant={'h2'} sx={{ mt: '30px' }}>
                Permissions
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: '20px' }}>
              <CognisaasTabs handleTabSwitch={switchTabs} tabs={['Resources', 'Pages']} />
            </Grid>
            <Grid>
              {tabIndex === 0 ? (
                <Resources 
                  selectedRole={props.selectedRole} 
                  selectedPolices={selectedPolicies}
                  addPolicy = {addPolicy}
                  removePolicy = {removePolicy}
                />
              ) : (
                <Pages 
                  selectedRole={props.selectedRole} 
                  addPage={addPage}
                  removePage={removePage}
                  selectedPages={selectedPages}
                />
              )}
            </Grid>
          </>
        )}
      </Box>
    </Drawer>
  );
}
