import { useAppDispatch } from 'store/hooks';
import { useState, useEffect } from 'react';
import { templateHomeTabs } from '../../types';
import {
  getUseCaseTemplatesbycustomer,
  getProjectTemplates,
  getTemplateDocumentByCustomerUid,
} from 'store/modules/Templates/Templates';
import { getCustomerUid } from 'utils/getUid';
import {
  selectProjectTemplates,
  selectUsecaseTemplates,
  selectTemplateDocuments,
} from 'store/modules/Templates/selector';
import { useAppSelector } from 'store/hooks';

const useTemplateListData = () => {
  const dispatch = useAppDispatch();
  const projectTemplates = useAppSelector(selectProjectTemplates);
  const usecaseTemplates = useAppSelector(selectUsecaseTemplates);
  const documentTemplates = useAppSelector(selectTemplateDocuments);

  const [selectedSectionInTemplates, setSelectedSectionInTemplate] = useState<string>(templateHomeTabs[0]);
  const [templateList, setTemplateList] = useState<any[]>([]);

  useEffect(() => {
    dispatch(getProjectTemplates());
    dispatch(getUseCaseTemplatesbycustomer());
    dispatch(getTemplateDocumentByCustomerUid(getCustomerUid()));
  }, []);

  useEffect(() => {
    console.log(selectedSectionInTemplates);
    templateListForSelectedTab(selectedSectionInTemplates);
  }, [selectedSectionInTemplates, usecaseTemplates, projectTemplates, documentTemplates]);

  const templateListForSelectedTab = async (selectedTab: string) => {
    console.log(selectedTab, templateHomeTabs[2]);

    if (selectedTab == templateHomeTabs[0]) {
      setTemplateList([...projectTemplates]);
    } else if (selectedTab == templateHomeTabs[1]) {
      setTemplateList([...usecaseTemplates]);
    } else if (selectedTab == templateHomeTabs[2]) {
      setTemplateList([...documentTemplates]);
    }
  };

  const templateSectionChange = (index: number) => {
    console.log('FEEE', index);
    console.log(index, templateHomeTabs[2]);
    setSelectedSectionInTemplate(templateHomeTabs[index]);
  };

  return {
    templateSectionChange,
    templateListForSelectedTab,
    selectedSectionInTemplates,
    templateList,
  };
};

export default useTemplateListData;
