import { Box, Button, FormControlLabel, Stack, Typography } from '@mui/material';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { StyledIcon } from 'components/ResourceManagement/design-system/primitives/icon';
import excelIcon from 'assets/svgs/excel.svg';
import Checkbox from '@mui/material/Checkbox';
import {
  selectHighlightCriticalPathInProjectGantt,
  selectHighlightCriticalPathInUsecaseGantt,
} from 'store/modules/Project/selector';
import { useAppSelector } from 'store/hooks';
import { useParams } from 'react-router-dom';

type TGanttToolbarProps = {
  isWorkTimeOn: boolean;
  handleOnZoomIn: () => void;
  handleOnZoomOut: () => void;
  handleOnExportToExcel: () => void;
  handleOnTodayClick: () => void;
  handleOnToogleWorkTime: () => void;
  handleCriticalPathHighlight: () => void;
};

const zoomButtonStyles = { color: '#3D38BA', cursor: 'pointer', padding: '10px 20px 0px 0px' };
const excelButtonStyles = { cursor: 'pointer', padding: '10px 20px 0px 0px' };
const workTimeStyles = { cursor: 'pointer', padding: '5px 5px 0px 0px' };

export const GanttToolbar = ({
  isWorkTimeOn,
  handleOnZoomIn,
  handleOnZoomOut,
  handleOnExportToExcel,
  handleOnTodayClick,
  handleOnToogleWorkTime,
  handleCriticalPathHighlight,
}: TGanttToolbarProps) => {
  const highlightCriticalPathInProjectGantt = useAppSelector(selectHighlightCriticalPathInProjectGantt);
  const highlightCriticalPathInUsecaseGantt = useAppSelector(selectHighlightCriticalPathInUsecaseGantt);
  const params = useParams();
  console.log(params);

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center" mt="10px" mx="10px">
        <Stack direction="row" justifyContent="flex-start" alignItems="center">
          <Button variant="outlined" onClick={handleOnTodayClick}>
            Today
          </Button>
        </Stack>
        <Stack direction="row" justifyContent="flex-start" alignItems="center">
          <Box title="Work time" style={workTimeStyles}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={params.caseId ? highlightCriticalPathInUsecaseGantt : highlightCriticalPathInProjectGantt}
                  onChange={handleCriticalPathHighlight}
                />
              }
              label={<Typography sx={{ fontSize: '14px' }}>Highlight Critical Path</Typography>}
            />
          </Box>
          <Box title="Work time" style={workTimeStyles}>
            <FormControlLabel
              control={<Checkbox checked={isWorkTimeOn} onChange={handleOnToogleWorkTime} />}
              label={<Typography sx={{ fontSize: '14px' }}>Work time</Typography>}
            />
          </Box>
          <Box title="Export to Excel" style={excelButtonStyles} onClick={handleOnExportToExcel}>
            <StyledIcon src={excelIcon} />
          </Box>
          <Box title="Zoom In" style={zoomButtonStyles} onClick={handleOnZoomIn}>
            <ZoomInIcon />
          </Box>
          <Box title="Zoom Out" style={zoomButtonStyles} onClick={handleOnZoomOut}>
            <ZoomOutIcon />
          </Box>
        </Stack>
      </Stack>
    </>
  );
};
