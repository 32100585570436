import {
  Box,
  Button,
  CardContent,
  Drawer,
  Grid,
  Paper,
  Select,
  MenuItem,
  Slider,
  Stack,
  Typography,
  SelectChangeEvent,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Card,
  Avatar,
  Rating,
  Link,
} from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import { DataGridPremium, gridClasses, GridToolbar } from '@mui/x-data-grid-premium';
import { alpha, styled, useTheme } from '@mui/material/styles';
import { GridRenderCellParams, GridColumnOrderChangeParams } from '@mui/x-data-grid';
import { ReactComponent as CsatVerySatisfied } from '../../../../assets/svgs/CsatVerySatisfied.svg';
import { ReactComponent as CsatSatisfied } from '../../../../assets/svgs/CsatSatisfied.svg';
import { ReactComponent as CsatNeutral } from '../../../../assets/svgs/CsatNeutral.svg';
import { ReactComponent as CsatDissatisfied } from '../../../../assets/svgs/CsatDissatisfied.svg';
import { ReactComponent as CsatVeryDissatisfied } from '../../../../assets/svgs/CsatVeryDissatisfied.svg';
import { ReactComponent as CsatNoResponse } from '../../../../assets/svgs/CsatNoResponse.svg';
import { ReactComponent as CsatFirstStar } from '../../../../assets/svgs/CsatFirstStar.svg';
import { ReactComponent as CsatSecondStar } from '../../../../assets/svgs/CsatSecondStar.svg';
import { ReactComponent as CsatThirdStar } from '../../../../assets/svgs/CsatThirdStar.svg';
import { ReactComponent as CsatFourthStar } from '../../../../assets/svgs/CsatFourthStar.svg';
import { ReactComponent as CsatFifthStar } from '../../../../assets/svgs/CsatFifthStar.svg';
import { useDispatch } from 'react-redux';
import {
  getCsatReviewsByCustomerUid,
  getCsatTargetByCustomerUid_slice,
  updateCsatTargetByCustomerUid_slice,
} from '../../../../store/modules/Reports/Performance/slice';
import { useAppSelector } from '../../../../store/hooks';
import {
  selectCsatReviews,
  selectCsatReviewsStatus,
  selectCsatTarget,
} from '../../../../store/modules/Reports/Performance/selector';
import Cookies from 'universal-cookie';
import { format } from 'date-fns';
import FilterTypeSelector from 'components/shared/CognisaasFilters/FilterTypeSelector/FilterTypeSelector';
import SelectedFilterList from 'components/shared/CognisaasFilters/SelectedFilterList/SelectedFilterList';
import { selectAllFilters } from 'store/modules/AllFilters/selector';
import { generateOrderedColumn, isNull, isUndefined } from 'utils/dataHelpers';
import { addFilterGroup } from 'store/modules/AllFilters/slice';
import { availabeTimePeriodOptions, FilterableData } from 'utils/filteringFunctions';
import CognisaasSelect from 'components/shared/CognisaasSelect/CognisaasSelect';
import {
  label_client,
  label_delivery_date,
  label_due_date,
  label_project,
  label_project_delivery_manager,
  label_task,
} from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { selectUsersOfCompany, selectUsersOfCompanyStatus } from 'store/modules/Common/selector';
import { getAllUsers } from 'store/modules/Common/slice';
import { fetchClientList } from 'store/modules/ManageClient/ClientList/slice';
import { selectClientList } from 'store/modules/ManageClient/ClientList/selector';
import checkAccessForRouting from 'components/shared/Hooks/checkAccessForRouting';
import { linkCell } from 'components/shared/Tables/CogniTable';
import { useNavigate } from 'react-router-dom';
import CognisaaasTooltip from 'components/shared/CognisaasTooltip/CognisaasTooltip';
import useCommonAppConfig from 'components/Common/useCommonAppConfig';

const cookies = new Cookies();

const ODD_OPACITY = 0.2;

function stringToHslColor(str: string, s: number, l: number) {
  let hash = 0;

  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const h = hash % 360;
  return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToHslColor(name, 30, 80),
    },
    children: (
      <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#000000' }}>
        {name.split(' ')[0][0]}
        {name.indexOf(' ') >= 0 ? name.split(' ')[1][0] : null}
      </Typography>
    ),
  };
}

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: 600,
  width: '100%',
  '& .MuiFormGroup-options': {
    alignItems: 'center',
    paddingBottom: theme.spacing(1),
    '& > div': {
      minWidth: 100,
      margin: theme.spacing(2),
      marginLeft: 0,
    },
  },
}));

const StripedDataGrid = styled(DataGridPremium)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
        },
      },
    },
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const marks = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 5,
    label: '5',
  },
];

function valuetext(value: number) {
  return `${value}°C`;
}

const CSATSlider = styled(Slider)({
  color: '#52af77',
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-mark': {
    backgroundColor: '#52af77',
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#52af77',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
});

const Csat = () => {
  // for api calls
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const csatReviews = useAppSelector(selectCsatReviews);
  const csatReviewsStatus = useAppSelector(selectCsatReviewsStatus);
  const clientList = useAppSelector(selectClientList);
  const csatTargetState = useAppSelector(selectCsatTarget);
  const [averageCsatRatingOfAllProjects, setAverageCsatRatingOfAllProjects] = useState<any>(0);

  // for opening and closing the drawer
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  // for storing the project data which is to be passed to the drawer
  const [drawerData, setDrawerData] = useState<any>(null);
  // for storing the filtered data that is to be displayed in the drawer
  const [finalDrawerData, setFinalDrawerData] = useState<any>(null);
  // for time range filtering
  const [timeRange, setTimeRange] = useState<string>('');
  // for total number of links send
  const [totalLinksSent, setTotalLinksSent] = useState<number>(0);
  // for total number of response received
  const [totalResponseReceived, setTotalResponseReceived] = useState<number>(0);

  const [csatTargetToDisplay, setCsatTargetToDisplay] = useState<number>(csatTargetState);
  const [csatTargetToUpdate, setCsatTargetToUpdate] = useState<number>(csatTargetState);

  // froshowing more details in the drawer
  const [showMoreDetailsOf, setShowMoreDetailsOf] = useState<any>(null);

  // for filters
  const allFilters = useAppSelector(selectAllFilters);
  const [filteredCsatData, setFilteredCsatData] = useState<any[]>([]);
  const [tableColumns, setTableColumns] = useState<any[]>([]);
  const allUsersStatus = useAppSelector(selectUsersOfCompanyStatus);
  const allUsers = useAppSelector(selectUsersOfCompany);

  const uniqueCSATColumnId = 'reportCSATListTable';

  let myTableState: any = {};
  const { saveAppTableState, getTableState } = useCommonAppConfig();
  const initialState: any = {
    columns: {
      columnVisibilityModel: {
        project_uid: false,
      },
    },
    pinnedColumns: {
      left: ['section_name'],
    },
    pagination: {
      page: 0,
      pageSize: 10,
    },
    ...getTableState('performanceReportCsatReport'),
  };

  const handleTableSave = (idName: string, thisTableState: any) => {
    saveAppTableState(idName, thisTableState);
  };

  function handleClientAccountClick(rowData: any) {
    navigate(`/client-list/client/${rowData.client_uid}`);
  }

  // for client list filter
  const handleClientAccess = (params: GridRenderCellParams) => {
    const arrayToCheck = clientList.map((ele) => ele.client_uid);
    const hasAccessToClient = checkAccessForRouting({ arrayToCheck: arrayToCheck, uid: params.row.client_uid });
    console.log(params.value);
    if (hasAccessToClient == true) {
      return linkCell(params, handleClientAccountClick);
    }
    if (hasAccessToClient == false) {
      return (
        <CognisaaasTooltip
          title={<Typography sx={{ padding: '5px' }}>You do not have access to this {label_client}</Typography>}
        >
          <Typography variant="subtitle1">{params.value}</Typography>
        </CognisaaasTooltip>
      );
    }
  };

  // for filter
  useEffect(() => {
    if (isUndefined(allFilters['reportCsat']) || isNull(allFilters['reportCsat'])) {
      if (allUsersStatus == 'success') {
        dispatch(
          addFilterGroup({
            idName: 'reportCsat',
            appliedFilters: [
              {
                name: label_project_delivery_manager,
                dataKey: 'project_deliverymanager',
                isSelected: false,
                equalToValue: [],
                canBeCleared: true,
                availableOptions: ['Not Assigned', ...allUsers.map((item: any) => item.user_username)],
              },
            ],
          })
        );
      }
    }
  }, [allUsersStatus]);

  useEffect(() => {
    if (allUsersStatus === 'idle' || allUsersStatus === 'failed') {
      dispatch(getAllUsers());
    }
  }, [allUsersStatus]);

  // for filter
  useEffect(() => {
    if (!isUndefined(allFilters['reportCsat']) && csatReviews.length > 0) {
      const newFilteredData = new FilterableData(csatReviews);
      newFilteredData.bulkFilter(allFilters['reportCsat'].appliedFilters);
      // custom filter logic

      const csatDataList: any = [];
      const tempRatingDistribution = {
        very_satisfied: 0,
        satisfied: 0,
        neutral: 0,
        dissatisfied: 0,
        very_dissatisfied: 0,
        no_response: 0,
      };

      let sumCsatOfAllProjects = 0;
      let totalLinksSent = 0;
      let totalResponseReceived = 0;

      if (csatReviewsStatus === 'success') {
        // start the counting here
        newFilteredData.toArray().forEach((rating: any) => {
          // looping throught the csat ratings of tasks

          let average_csat_rating = 0;
          let sum_of_csat_ratings = 0;
          let response_received = 0;
          let above_three_star_rating = 0;

          const remainingTask = new FilterableData(rating.task);
          remainingTask.filterInDateOptions('last_updated', timeRange);
          remainingTask.toArray().forEach((taskRating: any) => {
            if (taskRating.csat_rating) {
              // counting here
              sum_of_csat_ratings += Number(taskRating.csat_rating);
              response_received++;
              totalResponseReceived++;
              if (Number(taskRating.csat_rating) > 3.0) above_three_star_rating++;
            }
            totalLinksSent++;
            // calculating the rating distribution
            if (taskRating.csat_rating == null) tempRatingDistribution.no_response++;
            else if (Number(taskRating.csat_rating) >= 4.0) tempRatingDistribution.very_satisfied++;
            else if (Number(taskRating.csat_rating) >= 3.0) tempRatingDistribution.satisfied++;
            else if (Number(taskRating.csat_rating) >= 2.0) tempRatingDistribution.neutral++;
            else if (Number(taskRating.csat_rating) >= 1.0) tempRatingDistribution.dissatisfied++;
            else if (Number(taskRating.csat_rating) < 1.0) tempRatingDistribution.very_dissatisfied++;
          });

          average_csat_rating = (sum_of_csat_ratings * 1.0) / (response_received * 1.0);
          average_csat_rating = Math.round(average_csat_rating * 100) / 100;

          if (response_received > 0) {
            // adding the data to the array
            const csatDataObject: any = {};
            csatDataObject['customer_uid'] = rating.customer_uid;
            csatDataObject['client_uid'] = rating.client_uid;
            csatDataObject['client_name'] = rating.client_name;
            csatDataObject['project_uid'] = rating.project_uid;
            csatDataObject['project_name'] = rating.project_name;
            csatDataObject['project_deliverymanager'] = rating.project_deliverymanager;
            csatDataObject['average_csat_rating'] = average_csat_rating;
            csatDataObject['response_received'] = response_received;
            csatDataObject['above_three_star_rating'] = above_three_star_rating;
            csatDataObject['last_csat_rating_time'] = rating.last_csat_rating_time;
            csatDataObject['tasks'] = remainingTask.toArray();

            // pushing to the final array
            csatDataList.push(csatDataObject);
            sumCsatOfAllProjects += average_csat_rating;
          }
        });
        // calculating the average csat rating of all projects
        let averageCsatOfAllProjects = (sumCsatOfAllProjects * 1.0) / (csatDataList.length * 1.0);
        averageCsatOfAllProjects = Math.round(averageCsatOfAllProjects * 100) / 100;
        setAverageCsatRatingOfAllProjects(averageCsatOfAllProjects);
        setCsatRatingDistribution(calculatePercentage(tempRatingDistribution));
        setTotalLinksSent(totalLinksSent);
        setTotalResponseReceived(totalResponseReceived);
        // setFinalCsatData([...csatDataList]);
        setFilteredCsatData([...csatDataList]);
      }
      // custom filter logic
      // setFilteredCsatData(newFilteredData.toArray());
    }
  }, [allFilters, csatReviews, timeRange]);

  useEffect(() => {
    dispatch(getCsatReviewsByCustomerUid());
    dispatch(fetchClientList());
    dispatch(getCsatTargetByCustomerUid_slice());
    setTableColumns(generateOrderedColumn(columns, uniqueCSATColumnId));
  }, []);

  useEffect(() => {
    setCsatTargetToDisplay(csatTargetState);
  }, [csatTargetState]);

  const columns = [
    {
      field: 'project_uid',
      headerName: 'Project Uid',
      flex: 1,
      hide: true,
    },
    {
      field: 'project_name',
      headerName: `${label_project} Name`,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Grid>
            <Link
              onClick={() => {
                setShowDrawer(!showDrawer);
                setDrawerData(params.row);
              }}
              sx={{
                textDecoration: 'none',
                cursor: 'pointer',
              }}
            >
              <Typography variant="h3">{params.row.project_name}</Typography>
            </Link>
          </Grid>
        );
      },
    },
    {
      field: 'average_csat_rating',
      headerName: 'Average Rating',
      flex: 2,
      renderCell: (params: GridRenderCellParams) => (
        <Stack>
          <Stack spacing={1} direction="row">
            <span style={{ backgroundColor: 'violet', marginRight: '4px' }}></span>
            <Fragment>
              <Rating name="half-rating-read" defaultValue={params.value} precision={0.01} readOnly /> &nbsp;&nbsp;(
              {params.value})
            </Fragment>
          </Stack>
        </Stack>
      ),
    },
    {
      field: 'client_name',
      headerName: `${label_client} Name`,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => handleClientAccess(params),
    },
    {
      field: 'response_received',
      headerName: 'Responses Received',
      flex: 1,
    },
    {
      field: 'above_three_star_rating',
      headerName: 'People rated below 3 stars',
      type: 'number',
      flex: 1,
    },
    {
      field: 'project_deliverymanager',
      headerName: label_project_delivery_manager,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <Stack direction="row">
          <Avatar style={{ height: '34px', width: '34px', marginTop: '3px' }} {...stringAvatar(params.value)} />

          <Typography m={1} variant="subtitle1">
            {params.value}
          </Typography>
        </Stack>
      ),
    },
    {
      field: 'last_csat_rating_time',
      headerName: 'Last Reviewed On',
      type: 'date',
      width: 200,
    },
  ];

  const [csatRatingDistribution, setCsatRatingDistribution] = useState({
    very_satisfied: 0,
    satisfied: 0,
    neutral: 0,
    dissatisfied: 0,
    very_dissatisfied: 0,
    no_response: 0,
  });

  function calculatePercentage(tempRatingDistribution: any) {
    const count =
      tempRatingDistribution.very_satisfied +
      tempRatingDistribution.satisfied +
      tempRatingDistribution.neutral +
      tempRatingDistribution.dissatisfied +
      tempRatingDistribution.very_dissatisfied +
      tempRatingDistribution.no_response;
    // assigning values
    tempRatingDistribution.very_satisfied = Math.round((tempRatingDistribution.very_satisfied / count) * 100);
    tempRatingDistribution.satisfied = Math.round((tempRatingDistribution.satisfied / count) * 100);
    tempRatingDistribution.neutral = Math.round((tempRatingDistribution.neutral / count) * 100);
    tempRatingDistribution.dissatisfied = Math.round((tempRatingDistribution.dissatisfied / count) * 100);
    tempRatingDistribution.very_dissatisfied = Math.round((tempRatingDistribution.very_dissatisfied / count) * 100);
    tempRatingDistribution.no_response = Math.round((tempRatingDistribution.no_response / count) * 100);
    return tempRatingDistribution;
  }

  // for recently Reviewed select box inside the drawer
  const [taskDrawerSelectBox, setTaskDrawerSelectBox] = useState<string>('Recently Reviewed');

  // function for recently reviewed select box
  const taskDrawerSelectBoxHandleChange = (event: SelectChangeEvent) => {
    setTaskDrawerSelectBox(event.target.value);
  };

  useEffect(() => {
    if (drawerData != null) {
      const newTaskList: any = [];
      drawerData?.tasks.forEach((task: any) => {
        if (task.csat_rating) {
          if (taskDrawerSelectBox === 'Very Satisfied' && Number(task.csat_rating) >= 4.0) {
            newTaskList.push(task);
          } else if (
            taskDrawerSelectBox === 'Satisfied' &&
            Number(task.csat_rating) >= 3.0 &&
            Number(task.csat_rating) < 4.0
          ) {
            newTaskList.push(task);
          } else if (
            taskDrawerSelectBox === 'Neutral' &&
            Number(task.csat_rating) >= 2.0 &&
            Number(task.csat_rating) < 3.0
          ) {
            newTaskList.push(task);
          } else if (
            taskDrawerSelectBox === 'Dissatisfied' &&
            Number(task.csat_rating) >= 1.0 &&
            Number(task.csat_rating) < 2.0
          ) {
            newTaskList.push(task);
          } else if (taskDrawerSelectBox === 'Very Dissatisfied' && Number(task.csat_rating) < 1.0) {
            newTaskList.push(task);
          } else if (taskDrawerSelectBox === 'Recently Reviewed') {
            newTaskList.push(task);
          }
        }
      });

      // sorting the rating according to date in case of recently reviewed
      if (taskDrawerSelectBox === 'Recently Reviewed') {
        newTaskList.sort(function (a: any, b: any) {
          const key1: Date = new Date(a.last_updated);
          const key2: Date = new Date(b.last_updated);

          if (key1 < key2) {
            return 1;
          } else if (key1 == key2) {
            return 0;
          } else {
            return -1;
          }
        });
      }

      const newDrawerData = { ...drawerData };
      newDrawerData.tasks = [];
      newTaskList ? (newDrawerData.tasks = [...newTaskList]) : (newDrawerData.tasks = []);
      setFinalDrawerData(newDrawerData);
    }
  }, [taskDrawerSelectBox, drawerData]);

  // start of code for dialog box
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseAndSave = async () => {
    const updateCsatTarget = {
      customer_uid: cookies.get('cogni_customer_uid') as string,
      csat_target: csatTargetToUpdate,
    };
    await dispatch(updateCsatTargetByCustomerUid_slice(updateCsatTarget));
    await dispatch(getCsatTargetByCustomerUid_slice());
    setOpen(false);
  };

  const handleColumnOrderChange = (params: GridColumnOrderChangeParams) => {
    const oldArrayIndex = params.oldIndex;
    const targetArrayIndex = params.targetIndex;
    setTableColumns(generateOrderedColumn(tableColumns, uniqueCSATColumnId, oldArrayIndex, targetArrayIndex));
  };

  // end of code for dialog box

  return (
    <React.Fragment>
      <Drawer
        anchor="right"
        open={showDrawer}
        onClose={() => setShowDrawer(false)}
        PaperProps={{
          sx: { width: '35%' },
        }}
      >
        <Box sx={{ padding: '0px', height: 'auto', width: '100%', marginTop: '65px' }}>
          <Grid container sx={{ padding: '15px' }}>
            <Grid container sx={{ marginTop: '10px' }}>
              <Grid>
                <Typography sx={{ fontSize: '18px' }}>
                  <b>{drawerData?.project_name}</b>
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid>
                <Typography color="text.secondary" sx={{ fontSize: '12px' }}>
                  {label_project} Name
                </Typography>
              </Grid>
            </Grid>
            <Grid container sx={{ marginTop: '10px', paddingRight: '' }} justifyContent="space-between">
              <Grid>
                <Typography sx={{ fontSize: '14px', marginTop: '15px' }}>
                  <b>Delivery Performance</b>
                </Typography>
              </Grid>
              <Grid>
                <Typography>
                  <Select
                    labelId="Recently Reviewed"
                    id="recently_reviewed"
                    value={taskDrawerSelectBox}
                    label={taskDrawerSelectBox}
                    onChange={taskDrawerSelectBoxHandleChange}
                  >
                    <MenuItem value={'Recently Reviewed'}>Recently Reviewed</MenuItem>
                    <MenuItem value={'Very Satisfied'}>Very Satisfied</MenuItem>
                    <MenuItem value={'Satisfied'}>Satisfied</MenuItem>
                    <MenuItem value={'Neutral'}>Neutral</MenuItem>
                    <MenuItem value={'Dissatisfied'}>Dissatisfied</MenuItem>
                    <MenuItem value={'Very Dissatisfied'}>Very Dissatisfied</MenuItem>
                  </Select>
                </Typography>
              </Grid>
            </Grid>
            <Grid container sx={{ marginTop: '10px' }}>
              {/* card start */}
              {finalDrawerData?.tasks?.map((task: any) => {
                // if rating is given then only show it
                if (task.csat_rating != null) {
                  return (
                    <Card key={task.csat_uid} sx={{ width: '100%', marginBottom: '10px' }}>
                      <CardContent>
                        <Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>{task.task_name}</Typography>
                        <Grid container justifyContent="space-between" sx={{ marginBottom: '20px' }}>
                          <Grid>
                            <Typography color="text.secondary" sx={{ fontSize: 12, fontWeight: 'normal' }}>
                              {label_task} Name
                            </Typography>
                          </Grid>
                          <Grid>
                            <Typography color="text.secondary" sx={{ fontSize: 12, fontWeight: 'normal' }}>
                              Reviewed on <b>{task.last_updated}</b>
                            </Typography>
                          </Grid>
                        </Grid>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                          <Rating name="half-rating-read" defaultValue={task.csat_rating} precision={0.01} readOnly />{' '}
                          &nbsp;&nbsp; <span style={{ verticalAlign: 'super' }}>({task.csat_rating})</span>
                        </Typography>
                        <Typography color="text.secondary" sx={{ fontSize: 12, fontWeight: 'normal' }}>
                          Reviewed by{' '}
                          <span style={{ fontSize: 14, fontWeight: 'bold' }}>{task.csat_reviewer_name}</span>{' '}
                          &nbsp;&nbsp; &#9679; {task.contact_role}
                        </Typography>

                        <Link
                          onClick={() => setShowMoreDetailsOf(task.csat_uid)}
                          underline="none"
                          display={showMoreDetailsOf != task.csat_uid ? '' : 'none'}
                        >
                          <Typography sx={{ marginTop: '20px' }}>more details </Typography>
                        </Link>

                        <Grid display={showMoreDetailsOf != task.csat_uid ? 'none' : ''}>
                          <Stack sx={{ border: 'none', boxShadow: 'none' }}>
                            {task.csat_comment ? (
                              <Item sx={{ border: 'none', boxShadow: 'none', marginY: '15px' }}>
                                <Typography sx={{ fontSize: 14, fontWeight: 'normal' }}>
                                  &#8220; <b>{task.csat_comment}</b> &#8221;
                                </Typography>
                              </Item>
                            ) : (
                              ''
                            )}

                            <Item sx={{ border: 'none', boxShadow: 'none' }}>
                              <Grid container justifyContent="space-between">
                                <Grid>
                                  <Typography color="text.secondary" sx={{ fontSize: 12, fontWeight: 'normal' }}>
                                    Start Date
                                  </Typography>
                                  <Typography sx={{ fontSize: 14, fontWeight: 'bold', marginTop: '5px' }}>
                                    {/* {task.task_start_date} */}
                                    {task.task_start_date != '0000-00-00 00:00:00'
                                      ? format(new Date(task.task_start_date), 'dd MMM yyyy')
                                      : format(new Date(), 'dd MMM yyyy')}
                                  </Typography>
                                </Grid>
                                <Grid>
                                  <Typography color="text.secondary" sx={{ fontSize: 12, fontWeight: 'normal' }}>
                                    {label_due_date}
                                  </Typography>
                                  <Typography sx={{ fontSize: 14, fontWeight: 'bold', marginTop: '5px' }}>
                                    {task.task_start_date != '0000-00-00 00:00:00'
                                      ? format(new Date(task.task_due_date), 'dd MMM yyyy')
                                      : format(new Date(), 'dd MMM yyyy')}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Item>
                            <Item sx={{ border: 'none', boxShadow: 'none' }}>
                              <Grid container justifyContent="space-between">
                                <Grid>
                                  <Typography color="text.secondary" sx={{ fontSize: 12, fontWeight: 'normal' }}>
                                    {label_delivery_date}
                                  </Typography>
                                  <Typography sx={{ fontSize: 14, fontWeight: 'bold', marginTop: '5px' }}>
                                    {task.task_start_date != '0000-00-00 00:00:00'
                                      ? format(new Date(task.task_closed_date), 'dd MMM yyyy')
                                      : format(new Date(), 'dd MMM yyyy')}
                                  </Typography>
                                </Grid>
                                <Grid>
                                  <Stack direction="row">
                                    <Avatar
                                      style={{ height: '34px', width: '34px', marginTop: '3px' }}
                                      {...stringAvatar(task.csat_reviewer_name)}
                                    />
                                    <Typography m={1} variant="subtitle1">
                                      {task.csat_reviewer_name}
                                    </Typography>
                                  </Stack>
                                </Grid>
                              </Grid>
                            </Item>
                          </Stack>
                        </Grid>
                      </CardContent>
                    </Card>
                  );
                }
              })}
              {/* card end */}
            </Grid>
          </Grid>
        </Box>
      </Drawer>
      {/* End of Drawer Code */}

      {/* for dialog Box */}
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        fullWidth={true}
      >
        <DialogTitle id="responsive-dialog-title">{'Set CSAT Score Target'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography sx={{ textAlign: 'center' }}>Target: 4 Star Satisfied</Typography>
            <Grid container justifyContent="space-between" sx={{ marginTop: '25px' }}>
              <Grid>
                <CsatFirstStar></CsatFirstStar>
              </Grid>
              <Grid>
                <CsatSecondStar></CsatSecondStar>
              </Grid>
              <Grid>
                <CsatThirdStar></CsatThirdStar>
              </Grid>
              <Grid>
                <CsatFourthStar></CsatFourthStar>
              </Grid>
              <Grid>
                <CsatFifthStar></CsatFifthStar>
              </Grid>
            </Grid>
            <Box sx={{ width: '100%', marginTop: '37px' }}>
              <CSATSlider
                aria-label="Always visible"
                defaultValue={csatTargetToDisplay}
                getAriaValueText={valuetext}
                step={0.1}
                marks={marks}
                min={0}
                max={5}
                onChange={(e: any) => setCsatTargetToUpdate(e.target.value)}
                valueLabelDisplay="on"
                sx={{
                  width: '100%',
                  color: '#60A862',
                }}
              />
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleCloseAndSave} autoFocus variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      {/* for dialog Box */}

      <Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifySelf: 'flex-end',
            marginLeft: 'auto',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifySelf: 'flex-end',
              marginLeft: 'auto',
            }}
          >
            <Typography variant="subtitle1">Duration &nbsp;&nbsp;</Typography>
            <CognisaasSelect
              width="200px"
              validValues={availabeTimePeriodOptions}
              onChange={(event: any) => setTimeRange(event.target.value)}
              value={timeRange}
            />
            <FilterTypeSelector idName="reportCsat" />
          </Box>
        </Box>
        <Box sx={{ margin: '8px 0px' }}>
          {allFilters['reportCsat']?.appliedFilters.filter((item: any) => item.isSelected).length > 0 && (
            <SelectedFilterList idName="reportCsat" isResetEnabled={true} />
          )}
        </Box>
        <Box sx={{ display: 'grid', justifyContent: 'center', alignItems: 'center', gridTemplateColumns: '1fr' }}>
          <Paper>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fit, minmax(400px, 1fr))',
                gridGap: '10px',
                m: '20px',
              }}
            >
              <Box sx={{ display: 'grid', gridColumn: 'span 2' }}>
                <Item>
                  <CardContent>
                    <Grid container justifyContent="space-between" sx={{ backgroundColor: '', marginBottom: '50px' }}>
                      <Grid sx={{ fontSize: 14, fontWeight: 'bold' }}>{label_task} Rating Distribution</Grid>
                      <Grid sx={{ fontSize: 14, fontWeight: 'bold' }}>
                        Response Received Rate:{' '}
                        {!isNaN(Math.round((totalResponseReceived * 1000.0) / totalLinksSent) / 10)
                          ? Math.round((totalResponseReceived * 1000.0) / totalLinksSent) / 10
                          : 0}
                        % &nbsp;&nbsp;
                        <span color="text.secondary" style={{ fontWeight: 'normal' }}>
                          ({totalResponseReceived}/{totalLinksSent})
                        </span>
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Total Task: {totalLinksSent} </span>
                      </Grid>
                    </Grid>
                    <Grid container justifyContent="space-between" sx={{ backgroundColor: '' }}>
                      <Grid>
                        <Typography>Very Satisfied</Typography>
                        <Typography sx={{ fontSize: 14, fontWeight: 'bold', marginTop: '5px' }}>
                          <CsatVerySatisfied></CsatVerySatisfied>
                          &nbsp;{csatRatingDistribution.very_satisfied}%
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography>Satisfied</Typography>
                        <Typography sx={{ fontSize: 14, fontWeight: 'bold', marginTop: '5px' }}>
                          <CsatSatisfied></CsatSatisfied>&nbsp;{csatRatingDistribution.satisfied}%
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography>Neutral</Typography>
                        <Typography sx={{ fontSize: 14, fontWeight: 'bold', marginTop: '5px' }}>
                          <CsatNeutral></CsatNeutral>&nbsp;{csatRatingDistribution.neutral}%
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography>Dissatisfied</Typography>
                        <Typography sx={{ fontSize: 14, fontWeight: 'bold', marginTop: '5px' }}>
                          <CsatDissatisfied></CsatDissatisfied>&nbsp;{csatRatingDistribution.dissatisfied}%
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography>Very Dissatisfied</Typography>
                        <Typography sx={{ fontSize: 14, fontWeight: 'bold', marginTop: '5px' }}>
                          <CsatVeryDissatisfied></CsatVeryDissatisfied>&nbsp;{csatRatingDistribution.very_dissatisfied}%
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography>No Response</Typography>
                        <Typography sx={{ fontSize: 14, fontWeight: 'bold', marginTop: '5px' }}>
                          <CsatNoResponse></CsatNoResponse>&nbsp;{csatRatingDistribution.no_response}%
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={0} sx={{ backgroundColor: '', marginTop: '67px', marginBottom: '30px' }}>
                      <Grid
                        width={csatRatingDistribution.very_satisfied + '%'}
                        sx={{ backgroundColor: '#60A862', borderRadius: '3px' }}
                      >
                        &nbsp;
                      </Grid>
                      <Grid
                        width={csatRatingDistribution.satisfied + '%'}
                        sx={{ backgroundColor: '#C3F2C4', borderRadius: '3px' }}
                      >
                        &nbsp;
                      </Grid>
                      <Grid
                        width={csatRatingDistribution.neutral + '%'}
                        sx={{ backgroundColor: '#E2F4CF', borderRadius: '3px' }}
                      >
                        &nbsp;
                      </Grid>
                      <Grid
                        width={csatRatingDistribution.dissatisfied + '%'}
                        sx={{ backgroundColor: '#F9D5AA', borderRadius: '3px' }}
                      >
                        &nbsp;
                      </Grid>
                      <Grid
                        width={csatRatingDistribution.very_dissatisfied + '%'}
                        sx={{ backgroundColor: '#F0B4B4', borderRadius: '3px' }}
                      >
                        &nbsp;
                      </Grid>
                      <Grid
                        width={csatRatingDistribution.no_response + '%'}
                        sx={{ backgroundColor: '#A3CAF2', borderRadius: '3px' }}
                      >
                        &nbsp;
                      </Grid>
                    </Grid>
                  </CardContent>
                </Item>
              </Box>
              <Box>
                <Item>
                  <CardContent>
                    <Grid container justifyContent="space-between" sx={{ marginBottom: '13px' }}>
                      <Grid sx={{ fontSize: 14, fontWeight: 'bold' }}>
                        Average CSAT Score
                        <span color="text.secondary" style={{ fontWeight: 'normal' }}>
                          (Based on {totalResponseReceived} Response)
                        </span>
                      </Grid>
                      <Grid sx={{ fontSize: 14, fontWeight: 'bold', marginTop: '-8px' }} onClick={handleClickOpen}>
                        {' '}
                        <Button>Set Target</Button>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid
                        sx={{
                          backgroundColor: 'lavender',
                          height: '40px',
                          width: '60px',
                          fontSize: '24px',
                          FontWeight: 'bold',
                          // lineHeight: '32px',
                        }}
                      >
                        <b>&nbsp;{averageCsatRatingOfAllProjects}&nbsp;</b>
                      </Grid>
                      <Grid
                        sx={{
                          fontSize: '18px',
                          FontWeight: 'bold',
                          lineHeight: '28px',
                        }}
                      >
                        <b>
                          {averageCsatRatingOfAllProjects >= csatTargetToDisplay ? (
                            <>
                              <span style={{ color: '#4F9F52' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Surpassed target!</span>
                            </>
                          ) : (
                            <>
                              <span style={{ color: '#E75B5C' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Below targets set!</span>
                            </>
                          )}
                        </b>
                      </Grid>
                    </Grid>
                    <Grid container justifyContent="space-between" sx={{ marginTop: '13px' }}>
                      <Grid>
                        <CsatFirstStar></CsatFirstStar>
                      </Grid>
                      <Grid>
                        <CsatSecondStar></CsatSecondStar>
                      </Grid>
                      <Grid>
                        <CsatThirdStar></CsatThirdStar>
                      </Grid>
                      <Grid>
                        <CsatFourthStar></CsatFourthStar>
                      </Grid>
                      <Grid>
                        <CsatFifthStar></CsatFifthStar>
                      </Grid>
                    </Grid>
                    <Box sx={{ width: '100%', marginTop: '37px' }}>
                      <CSATSlider
                        aria-label="Always visible"
                        // defaultValue={csatTargetToDisplay}
                        value={csatTargetToDisplay}
                        getAriaValueText={valuetext}
                        step={0.1}
                        marks={marks}
                        min={0}
                        max={5}
                        valueLabelDisplay="on"
                        sx={{
                          width: '100%',
                          color: '#60A862',
                        }}
                      />
                    </Box>
                  </CardContent>
                </Item>
              </Box>
            </Box>
            <Grid container justifyContent="space-between" sx={{ padding: '20px' }}>
              <Box sx={{ height: '100%', width: '100%' }}>
                <StyledBox>
                  <StripedDataGrid
                    columns={tableColumns}
                    rows={filteredCsatData}
                    initialState={initialState}
                    onStateChange={(tblState: any) => {
                      myTableState = tblState;
                    }}
                    onColumnVisibilityModelChange={() => {
                      handleTableSave('performanceReportCsatReport', myTableState);
                    }}
                    onFilterModelChange={() => {
                      handleTableSave('performanceReportCsatReport', myTableState);
                    }}
                    onPageSizeChange={() => {
                      handleTableSave('performanceReportCsatReport', myTableState);
                    }}
                    components={{
                      Toolbar: GridToolbar,
                    }}
                    componentsProps={{
                      toolbar: { showQuickFilter: true },
                    }}
                    loading={false}
                    getRowId={(csatReviews) => csatReviews.project_uid}
                    disableSelectionOnClick
                    rowThreshold={0}
                    // pageSize={pageSize}
                    autoHeight={true}
                    rowsPerPageOptions={[5, 10, 20, 50, 100]}
                    // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    pagination
                    getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'odd' : 'odd')}
                    onColumnOrderChange={handleColumnOrderChange}
                  />
                </StyledBox>
              </Box>
            </Grid>
          </Paper>
        </Box>
      </Box>
    </React.Fragment>
  );
};
export default Csat;
