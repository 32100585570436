import { Typography, Stack, TextField, Grid } from '@mui/material';
import CognisaasSelect from 'components/shared/CognisaasSelect/CognisaasSelect';
// import { Formik } from 'formik';
import CognisaasButton from 'components/shared/CognisaasButton/CognisaasButton';
import { useState } from 'react';

interface Props {
  handleCustomFieldsSave: (newMandatoryFieldsWithValue: any[]) => void;
  fields: any[];
  onClose: () => void;
}

const JiraServiceDeskCustomFields = (props: Props & any) => {
  console.log('inside jira custom fields service desk modal props = ', props);
  const mandatoryFields = props.fields;
  const close = props.onClose;
  const [mandatoryFieldsWithValue, setMandatoryFieldsWithValue] = useState<any[]>(
    props.fields.map((item: any) => ({ ...item, newValue: '' }))
  );

  const handleChange = (mandatoryField: Record<string, any>, newValue: string) => {
    setMandatoryFieldsWithValue((prevMfs: any) => {
      return prevMfs.map((item: any) => {
        if (item.fieldId === mandatoryField.fieldId) {
          return { ...item, newValue: newValue };
        } else {
          return { ...item };
        }
      });
    });
  };

  const initialValues: any = {};

  for (let i = 0; i < props.fields.length; i++) {
    initialValues[props.fields[i].name] = '';
  }
  return (
    <form style={{ minWidth: '100%' }}>
      <Typography variant="h6">Update jira Mandatory fields</Typography>
      {mandatoryFields.map((field: any, index: number) => {
        const { name } = field as any;

        name.trim();
        return (
          <>
            {/* <Typography variant="subtitle1">{field.name}</Typography> */}
            <Grid container mt={3} key={index} direction="row">
              <Grid xs={4}>
                <Typography variant="subtitle1">{field.name}</Typography>
              </Grid>
              {field.jiraSchema.type == 'string' && (
                <Stack>
                  <TextField
                    name={name}
                    autoComplete="off"
                    onChange={(e: any) => handleChange(field, e.target.value)}
                    autoFocus={true}
                    style={{ width: '200px' }}
                    size="small"
                    placeholder=""
                  ></TextField>
                </Stack>
              )}
              {field.jiraSchema.type == 'any' && (
                <Stack>
                  <TextField
                    name={name}
                    autoComplete="off"
                    onChange={(e: any) => handleChange(field, e.target.value)}
                    autoFocus={true}
                    style={{ width: '200px' }}
                    size="small"
                    placeholder=""
                  ></TextField>
                </Stack>
              )}
              {field.jiraSchema.type == 'array' && field.fieldId.startsWith('customfield') && (
                <Stack>
                  <CognisaasSelect
                    onChange={(e: any) => console.log(e.target.value)}
                    value={''}
                    validValues={field.validValues.map((item: any) => item.label)}
                  ></CognisaasSelect>
                </Stack>
              )}
              {field.jiraSchema.type == 'option' && (
                <Stack>
                  <CognisaasSelect
                    name={name}
                    value={mandatoryFieldsWithValue[index].newValue}
                    onChange={(e: any) => handleChange(field, e.target.value)}
                    validValues={field.validValues.map((item: any) => item.label)}
                  ></CognisaasSelect>
                </Stack>
              )}

              {field.jiraSchema.type == 'priority' && (
                <Stack>
                  <CognisaasSelect
                    name={name}
                    onChange={(e: any) => handleChange(field, e.target.value)}
                    validValues={field.validValues.map((item: any) => item.label)}
                  ></CognisaasSelect>
                </Stack>
              )}
              {field.jiraSchema.type == 'date' && (
                <Stack>
                  <CognisaasSelect
                    name={name}
                    onChange={(e: any) => handleChange(field, e.target.value)}
                    value={''}
                    validValues={[field.name]}
                  ></CognisaasSelect>
                </Stack>
              )}
            </Grid>
          </>
        );
      })}
      <Stack sx={{ marginTop: '24px' }} spacing={2} justifyContent="flex-end" alignItems="flex-end" direction="row">
        <CognisaasButton clickHandler={close} isOutlined={true} label="Cancel" />
        <CognisaasButton
          disabled={mandatoryFieldsWithValue.filter((item: any) => item.newValue == '').length > 0}
          clickHandler={() => {
            props.handleCustomFieldsSave(mandatoryFieldsWithValue);
          }}
          isOutlined={false}
          label="Continue"
        />
      </Stack>
    </form>
  );
};

export default JiraServiceDeskCustomFields;
