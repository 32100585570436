import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addGraphConfigurations, updateGraphConfigurations } from '../../../../store/modules/Charts/slice';
import { selectAllChartsConfig } from '../../../../store/modules/Charts/selector';
import { useAppSelector } from '../../../../store/hooks';
import {
  Wrapper,
  Container,
  Title,
  SubTitle,
  Small,
  Frame,
  Box,
  Text,
  TextField,
  Group,
} from './CustomiseDrawer.styles';
import Divider from '@mui/material/Divider';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

import CognisaasButton from '../../../shared/CognisaasButton/CognisaasButton';
import CognisaasCheckBox from '../../../shared/CognisaasCheckbox/CognisaasCheckbox';
import CognisaasRadioButton from '../../../shared/CognisaasRadioMenu/CognisaasRadioMenu';
import ColourPalette from 'components/shared/ColourPalette/ColourPalette';

import { ReactComponent as Bar } from '../../../../assets/svgs/bar.svg';
import { ReactComponent as Donut } from '../../../../assets/svgs/donut.svg';
import { ReactComponent as LineChartSvg } from '../../../../assets/svgs/line_chart_logo.svg';
import { ReactComponent as Arrow } from '../../../../assets/svgs/arrow.svg';
import { ReactComponent as BackArrow } from '../../../../assets/svgs/backArrow.svg';
import { ReactComponent as DownArrow } from '../../../../assets/svgs/downArrow.svg';
import { ReactComponent as VerticalBar } from '../../../../assets/svgs/verticalBar.svg';
import { ReactComponent as HorizontalBar } from '../../../../assets/svgs/horizontalBar.svg';
import { ReactComponent as StackedChartSvg } from '../../../../assets/svgs/grouped/stacked_chart.svg';

import { Drawer, Stack, Typography } from '@mui/material';

import PreviewPage from './Preview';
import { chart, labelPositionType, graphSchema, layoutType } from './types';
import CognisaasSelect from 'components/shared/CognisaasSelect/CognisaasSelect';
import useChartCustomizeDrawer from '../useChartCustomizeDrawer';
import { TIME_OPTION_VALUES_FOR_CHART } from '../constants';
import EqualizerRoundedIcon from '@mui/icons-material/EqualizerRounded';
import { GRAPH_COLORS } from 'components/shared/ColourPalette/constants';
import { isNull, isUndefined } from 'utils/dataHelpers';
import ChartInfoBox from './ChartInfoBox';
import { chartGroupType } from '../types';
interface CustomiseDrawerProps {
  idName: string;
  drawerState: boolean;
  configType: 'project' | 'usecase' | 'task' | 'any';
  setDrawerState: React.Dispatch<React.SetStateAction<boolean>>;
  sampleData: Record<string, string>;
  formActionType: 'save' | 'update';
  setFormActionType: React.Dispatch<React.SetStateAction<'save' | 'update'>>;
  graphData: any;
  customReportConfig?: any;
  data?: any[];
}

const CustomiseDrawer = (props: CustomiseDrawerProps) => {
  const { graphData } = props;
  console.log('my gr data', props);
  const [preview, setPreview] = useState<boolean>(false);
  const [chartType, setChartType] = useState<chart>('');
  const [numberOfCharts, setNumberOfCharts] = useState<1 | 2>(1);
  const [layout, setLayout] = useState<layoutType>('vertical');
  // const [openLabels, setOpenLabels] = useState<boolean>(false);
  const [openColours, setOpenColours] = useState<boolean>(false);

  const [chartName, setChartName] = useState<string>('');
  const [trendLine, setTrendLine] = useState<boolean>(false);
  const [labelPosition, setLabelPosition] = useState<labelPositionType>(graphData.labelPosition || 'bottom');
  const [flipAxis, setFlipAxis] = useState<boolean>(false);
  const { drawerState, setDrawerState } = props;
  const [xAxisOptionsMap, setXAxisOptionsMap] = useState<any>([]);
  const [yAxisOptionsMap, setYAxisOptionsMap] = useState<any>([]);
  const [selectedColours, setSelectedColours] = useState<string[]>([]);
  const [xAxisValue, setXAxisValue] = useState<string>('');
  const [yAxisValue, setYAxisValue] = useState<string>('');
  const [selectedTimeDataKey, setSelectedTimeDataKey] = useState<string>('');
  const [selectedTimeBasis, setSelectedTimeBasis] = useState<string>('');

  const graphConfigs: graphSchema[] = useAppSelector(selectAllChartsConfig) || [];
  const graphs: graphSchema[] = graphConfigs.filter((graph: graphSchema) => graph.idName === props.idName);
  const [oldGraphs, setOldGraphs] = useState<graphSchema[]>([]);
  const [chartGroupType, setChartGroupType] = useState<chartGroupType>('grouped');
  const [selectedGroupbyConfig, setSelectedGroupbyConfig] = useState<string>('');

  const {
    chartXaxisConfigs,
    chartYaxisConfigs,
    getTimeDataKeysFor,
    getTimeActualDataKeyFromName,
    getAllChartXConfigsforTypeAny,
    getAllChartYConfigsForTypeAny,
    addRealtimeChartXaxisConfigAsPerData,
  } = useChartCustomizeDrawer();

  useEffect(() => {
    if (props.formActionType === 'update') {
      // setChartType(graphData.chartType);
      // setFlipAxis(graphData.flipAxis);
      // setLabelPosition(graphData.labelPosition);
      setChartInitialData(graphData);
    }
  }, [props, graphData]);

  console.log('sample data', props.customReportConfig);

  useEffect(() => {
    if (props.formActionType === 'save') {
      resetChartDrawerData();
    }
    return () => {
      resetChartDrawerData();
    };
  }, []);

  // useEffect(() => {
  //   if (props.data && props.data.length > 0 && chartXaxisConfigs.length > 1) {
  //     addRealtimeChartXaxisConfigAsPerData(props.data);
  //   }
  // }, [props.data]);

  useEffect(() => {
    if ((props.configType === 'any' && !isNull(props.customReportConfig)) || !isUndefined(props.customReportConfig)) {
      setXAxisOptionsMap(getAllChartXConfigsforTypeAny(props.customReportConfig));
      setYAxisOptionsMap(getAllChartYConfigsForTypeAny(props.customReportConfig));
    } else {
      setYAxisOptionsMap(chartYaxisConfigs.filter((item: any) => item.type === props.configType));
      setXAxisOptionsMap(
        [...addRealtimeChartXaxisConfigAsPerData(props.data as any[]), ...chartXaxisConfigs].filter(
          (item: any) => item.type === props.configType
        )
      );
    }
  }, [chartYaxisConfigs, chartXaxisConfigs, props.customReportConfig]);

  const setChartInitialData = (graphData: any) => {
    console.log('initial data', graphData);
    setChartName(graphData.chartName);
    setPreview(false);
    setChartType(graphData.chartType);
    setLayout(graphData.layout);
    setLabelPosition(graphData.labelPosition);
    setSelectedColours(graphData.colors);
    setTrendLine(graphData.trendLine == '0' ? false : true);
    setFlipAxis(graphData.flipAxis == '0' ? false : true);
    setYAxisValue(graphData.yAxisKeys.name);
    if (graphData.chartType === 'Grouped Chart') {
      setChartGroupType(graphData.xAxisKeys.groupOn.groupType);
      setSelectedGroupbyConfig(graphData.xAxisKeys.groupOn.name);
    }
    if (graphData.xAxisKeys.key === 'Time') {
      setXAxisValue(graphData.xAxisKeys.key);
      setSelectedTimeDataKey(graphData.xAxisKeys.possibleOptions[0][0]);
      setSelectedTimeBasis(graphData.xAxisKeys.possibleOptions[1][0]);
    } else {
      setXAxisValue(graphData.xAxisKeys.name);
    }
  };

  const dispatch = useDispatch();

  const onClose = () => {
    setDrawerState(false);
  };

  console.log('props', props);

  const handleSave = () => {
    console.log('sdfsdfsdfsdfsd', xAxisOptionsMap);
    const graphValues: graphSchema = {
      idName: props.idName,
      chartType: chartType,
      layout: layout,
      labelPosition: labelPosition,
      flipAxis: flipAxis,
      chartName: chartName,
      trendLine: trendLine,
      colors: selectedColours.length > 0 ? selectedColours : GRAPH_COLORS,
      xAxisKeys:
        xAxisValue === 'Time'
          ? {
              ...xAxisOptionsMap.filter((item: any) => item.name === xAxisValue)[0],
              name: ` ${selectedTimeDataKey}(${xAxisValue})`,
              possibleOptions: [
                [selectedTimeDataKey, getTimeActualDataKeyFromName(selectedTimeDataKey)],
                [selectedTimeBasis],
              ],
            }
          : {
              ...xAxisOptionsMap.filter((item: any) => item.name === xAxisValue)[0],
              possibleOptions: [...xAxisOptionsMap.filter((item: any) => item.name === xAxisValue)[0].options],
              name: xAxisValue,
              groupOn:
                chartType === 'Grouped Chart'
                  ? {
                      ...xAxisOptionsMap.filter((item: any) => item.name === selectedGroupbyConfig)[0],
                      groupType: chartGroupType,
                    }
                  : {},
            },

      yAxisKeys: { ...yAxisOptionsMap.filter((item: any) => item.name === yAxisValue)[0], possibleOptions: ['number'] },
      project_chart_uid: graphData.project_chart_uid || '',
      case_chart_uid: graphData.case_chart_uid || '',
      task_chart_uid: graphData.task_chart_uid || '',
      entity_chart_uid: graphData.entity_chart_uid || '',
    };
    if (props.formActionType === 'update') {
      dispatch(updateGraphConfigurations({ ...graphValues, type: props.configType }));
    } else {
      dispatch(addGraphConfigurations({ ...graphValues, type: props.configType }));
    }
    if (numberOfCharts === 2) {
      setPreview(true);
      setOldGraphs([graphValues]);
    }
    console.log('sdsdfdfdf', graphValues);
    props.setFormActionType('save');
    setPreview(true);
    setChartType('');
    setLayout('vertical');
    // setOpenLabels(false);
    setOpenColours(false);
    setChartName('');
    setLabelPosition('bottom');
    setSelectedColours([]);
    setTrendLine(false);
    setFlipAxis(false);
    setDrawerState(() => (numberOfCharts === 1 || (numberOfCharts === 2 && graphs.length > 0) ? false : true));
    setXAxisValue('');
    setYAxisValue('');
    setSelectedTimeDataKey('');
    setSelectedTimeBasis('');
  };

  const resetChartDrawerData = () => {
    props.setFormActionType('save');
    setPreview(false);
    setChartType('');
    setLayout('vertical');
    // setOpenLabels(false);
    setOpenColours(false);
    setChartName('');
    setLabelPosition('bottom');
    setSelectedColours([]);
    setTrendLine(false);
    setFlipAxis(false);
    setXAxisValue('');
    setYAxisValue('');
    setSelectedTimeDataKey('');
    setSelectedTimeBasis('');
    setOldGraphs([]);
  };

  return (
    <Drawer
      anchor="right"
      open={drawerState}
      onClose={() => {
        onClose();
        resetChartDrawerData();
      }}
    >
      {graphs.length === 2 && props.formActionType == 'save' ? (
        <Wrapper>
          <Container>
            <Stack alignItems={'center'} justifyContent={'center'} sx={{ textAlign: 'center', marginTop: '56px' }}>
              <EqualizerRoundedIcon sx={{ fontSize: '80px', color: '#8D74F8' }} />
              <Typography variant="h6" sx={{ width: '328px' }} className={'label-text'}>
                You can have a maximum of 2 charts, try removing or updating an existing one!
              </Typography>
              <CognisaasButton
                sx={{ marginTop: '8px' }}
                isOutlined={true}
                label="Go Back"
                clickHandler={() => {
                  onClose();
                  resetChartDrawerData();
                }}
              />
            </Stack>
          </Container>
        </Wrapper>
      ) : (
        <Wrapper>
          {preview && (
            <PreviewPage
              idName="reportsPageClosedProjects"
              graphInfo={[
                ...oldGraphs,
                {
                  idName: props.idName,
                  chartType: chartType,
                  layout: layout,
                  labelPosition: labelPosition,
                  flipAxis: flipAxis,
                  chartName: chartName,
                  trendLine: trendLine,
                  colors: selectedColours,
                  xAxisKeys: [xAxisValue],
                  yAxisKeys: [yAxisValue],
                },
              ]}
            />
          )}
          <Container>
            <Title>Customise Your View</Title>
            <Frame style={{ marginTop: '10px' }}>
              <Frame>
                <CognisaasCheckBox onClick={() => setPreview((prevState) => !prevState)} checked={preview} />
                <SubTitle style={{ marginLeft: '5px' }}>Show preview</SubTitle>
              </Frame>
            </Frame>
            {chartType.length === 0 && (
              <>
                <Box>
                  <SubTitle>Create charts</SubTitle>
                  <Frame style={{ width: '150px', marginTop: '10px' }}>
                    <Frame>
                      <CognisaasRadioButton checked={numberOfCharts === 1} onClick={() => setNumberOfCharts(1)} />
                      <label style={{ marginLeft: '5px' }}>
                        <SubTitle>1 chart</SubTitle>
                      </label>
                    </Frame>
                    {graphs.length === 0 && (
                      <Frame>
                        <CognisaasRadioButton checked={numberOfCharts === 2} onClick={() => setNumberOfCharts(2)} />
                        <label style={{ marginLeft: '5px' }}>
                          <SubTitle>2 chart</SubTitle>
                        </label>
                      </Frame>
                    )}
                  </Frame>
                </Box>
                <Box onClick={() => setChartType('Bar Chart')} style={{ justifyContent: 'center' }}>
                  <SubTitle>Bar Chart</SubTitle>
                  <Small>Comparison</Small>
                  <Frame style={{ marginTop: '5px', alignItems: 'center' }}>
                    <Bar />
                    <Text style={{ marginLeft: '10px' }}>
                      A bar chart or bar graph is a chart or graph that presents categorical data with rectangular bars
                      with heights or lengths proportional to the values that they represent.
                    </Text>
                    <Arrow />
                  </Frame>
                </Box>
                <Box style={{ height: '140px' }} onClick={() => setChartType('Donut Chart')}>
                  <SubTitle>Donut Chart</SubTitle>
                  <Small>Part to whole</Small>
                  <Frame style={{ marginTop: '15px', alignItems: 'center' }}>
                    <Donut />
                    <Text style={{ marginLeft: '10px' }}>
                      Doughnut charts are meant to express a part-to-whole relationship, where all pieces together
                      represent 100%.
                    </Text>
                    <Arrow />
                  </Frame>
                </Box>
                <Box style={{ height: '140px' }} onClick={() => setChartType('Line Chart')}>
                  <SubTitle>Line Chart</SubTitle>
                  <Small>Comparison</Small>
                  <Frame style={{ marginTop: '15px', alignItems: 'center' }}>
                    <LineChartSvg />
                    <Text style={{ marginLeft: '10px' }}>
                      Used to plot data points on a horizontal and a vertical axis in the attempt to show how much one
                      variable is affected by another.
                    </Text>
                    <Arrow />
                  </Frame>
                </Box>
                <ChartInfoBox
                  heading="Grouped Chart"
                  subHeading="comparision"
                  description="A grouped chart is a group of bar charts or multiset bar charts. Instead of a single bar, you can see multiple bars in this chart."
                  icon={<StackedChartSvg />}
                  onClick={() => setChartType('Grouped Chart')}
                />
              </>
            )}
            {chartType.length > 0 && (
              <>
                <div>
                  <div style={{ margin: '20px 0', display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                    <BackArrow onClick={() => setChartType('')} />
                    <SubTitle style={{ display: 'inline', marginLeft: '10px' }}>{chartType}</SubTitle>
                  </div>
                  {chartType === 'Bar Chart' ||
                    (chartType === 'Grouped Chart' && (
                      <Frame style={{ width: '150px', marginLeft: '14px' }}>
                        <Frame>
                          <CognisaasRadioButton
                            checked={layout === 'horizontal'}
                            onClick={() => setLayout('horizontal')}
                          />
                          <span style={{ marginLeft: '5px' }}>
                            <HorizontalBar />
                          </span>
                        </Frame>
                        <Frame>
                          <CognisaasRadioButton checked={layout === 'vertical'} onClick={() => setLayout('vertical')} />
                          <span style={{ marginLeft: '5px' }}>
                            <VerticalBar />
                          </span>
                        </Frame>
                      </Frame>
                    ))}
                  <Box>
                    <Group>
                      <label htmlFor="chart_name">
                        <Text>
                          Chart Name<span style={{ color: 'red', marginLeft: '3px' }}>*</span>
                        </Text>
                      </label>
                      <TextField
                        placeholder="Chart Name"
                        value={chartName}
                        onChange={(e) => setChartName(e.target.value)}
                      />
                    </Group>

                    {chartType === 'Grouped Chart' ? (
                      <Group>
                        <Stack>
                          <label htmlFor="group type">
                            <Text>
                              Group type <span style={{ color: 'red', marginLeft: '3px' }}>*</span>
                            </Text>
                          </label>
                          <FormControl sx={{ marginLeft: '12px' }}>
                            <RadioGroup
                              aria-labelledby="demo-radio-buttons-group-label"
                              defaultValue="grouped"
                              name="radio-buttons-group"
                              row={true}
                              onChange={(e: any) => {
                                setChartGroupType(e.target.value);
                              }}
                              value={chartGroupType}
                            >
                              <FormControlLabel value="grouped" control={<Radio />} label="Grouped" />
                              <FormControlLabel
                                sx={{ marginLeft: '16px' }}
                                value="stacked"
                                control={<Radio />}
                                label="Stacked"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Stack>
                      </Group>
                    ) : (
                      ''
                    )}

                    <Divider sx={{ marginTop: '15px' }} />

                    <Group>
                      <label htmlFor="chart_name">
                        <Text>
                          X axis data <span style={{ color: 'red', marginLeft: '3px' }}>*</span>
                        </Text>
                      </label>
                      <Frame style={{ width: '380px' }}>
                        {/* <CognisaasDropdown
                          validvalues={['Time', 'Value', 'Project']}
                          value="Time"
                          sx={{ width: '267px', height: '44px' }}
                        /> */}
                        <CognisaasSelect
                          width="271px"
                          onChange={(e: any) => {
                            setXAxisValue(e.target.value);
                          }}
                          value={xAxisValue}
                          validValues={xAxisOptionsMap.map((item: any) => item.name)}
                          placeholder={'Select X axis'}
                        />
                      </Frame>
                    </Group>

                    {/* for time */}
                    {xAxisValue === 'Time' ? (
                      <Group style={{ marginBottom: '10px' }}>
                        <label htmlFor="chart_name">
                          <Text>Time Constraints</Text>
                        </label>
                        <Stack direction={'column'}>
                          <Stack>
                            <Typography variant={'subtitle2'} className={'label-text'}>
                              Data Key
                            </Typography>
                            <CognisaasSelect
                              width="271px"
                              onChange={(e: any) => {
                                setSelectedTimeDataKey(e.target.value);
                              }}
                              value={selectedTimeDataKey}
                              validValues={getTimeDataKeysFor(props.configType, props.customReportConfig).map(
                                (item: any) => item.name
                              )}
                              placeholder={'select data key for time'}
                            />
                          </Stack>
                          <Stack sx={{ marginTop: '16px' }}>
                            <Typography variant={'subtitle2'} className={'label-text'}>
                              On basis of
                            </Typography>

                            <CognisaasSelect
                              width="271px"
                              onChange={(e: any) => {
                                setSelectedTimeBasis(e.target.value);
                              }}
                              value={selectedTimeBasis}
                              validValues={TIME_OPTION_VALUES_FOR_CHART}
                              placeholder={'select data basis for time'}
                            />
                          </Stack>
                        </Stack>
                      </Group>
                    ) : (
                      ''
                    )}

                    {/* for grouping */}
                    {chartType === 'Grouped Chart' ? (
                      <Group>
                        <Stack>
                          <label htmlFor="group by">
                            <Text>
                              Group by <span style={{ color: 'red', marginLeft: '3px' }}>*</span>
                            </Text>
                          </label>
                          <CognisaasSelect
                            width="271px"
                            onChange={(e: any) => {
                              // const foundConfig = getGroupedDataKeysFor(props.configType).filter(
                              //   (item: GroupConfig) => item.name === e.target.value
                              // );
                              // if (foundConfig.length > 0) {
                              //   setSelectedGroupbyConfig(foundConfig[0]);
                              // }
                              setSelectedGroupbyConfig(e.target.value);
                            }}
                            value={selectedGroupbyConfig}
                            // validValues={getGroupedDataKeysFor(props.configType).map((item: any) => item.name)}
                            validValues={xAxisOptionsMap
                              .filter((item: any) => item.name !== xAxisValue)
                              .map((item: any) => item.name)}
                            placeholder={'Select group by'}
                          />
                        </Stack>
                      </Group>
                    ) : (
                      ''
                    )}

                    <Divider sx={{ marginTop: '15px' }} />

                    <Group style={{ marginBottom: '10px' }}>
                      <label htmlFor="chart_name">
                        <Text>
                          Y axis data <span style={{ color: 'red', marginLeft: '3px' }}>*</span>
                        </Text>
                      </label>
                      <Frame style={{ width: '380px' }}>
                        <CognisaasSelect
                          width="271px"
                          onChange={(e: any) => {
                            setYAxisValue(e.target.value);
                          }}
                          value={yAxisValue}
                          validValues={yAxisOptionsMap.map((item: any) => item.name)}
                          placeholder={'Select Y axis'}
                        />
                      </Frame>
                    </Group>
                  </Box>

                  {chartType === 'Bar Chart' && (
                    <>
                      <Box>
                        <Frame onClick={() => setTrendLine((prevState) => !prevState)}>
                          <SubTitle>Show trendline</SubTitle>
                          <CognisaasCheckBox checked={trendLine} />
                        </Frame>
                      </Box>
                    </>
                  )}
                  {/* legends positioning deprecated */}
                  {/* <Box>
                    <Frame onClick={() => setOpenLabels((prevState) => !prevState)}>
                      <SubTitle>Show Labels</SubTitle>
                      <DownArrow style={{ marginTop: '5px' }} />
                    </Frame>
                    {openLabels && (
                      <Group style={{ marginTop: '10px' }}>
                        <Text>Label Position</Text>
                        <Frame style={{ width: '60%' }}>
                          <Frame>
                            <CognisaasRadioButton
                              onClick={() => setLabelPosition('left')}
                              checked={labelPosition === 'left'}
                            />
                            <Text style={{ marginLeft: '5px' }}>Left</Text>
                          </Frame>
                          <Frame>
                            <CognisaasRadioButton
                              onClick={() => setLabelPosition('right')}
                              checked={labelPosition === 'right'}
                            />
                            <Text style={{ marginLeft: '5px' }}>Right</Text>
                          </Frame>
                          <Frame>
                            <CognisaasRadioButton
                              onClick={() => setLabelPosition('top')}
                              checked={labelPosition === 'top'}
                            />
                            <Text style={{ marginLeft: '5px' }}>Top</Text>
                          </Frame>
                          <Frame>
                            <CognisaasRadioButton
                              onClick={() => setLabelPosition('bottom')}
                              checked={labelPosition === 'bottom'}
                            />
                            <Text style={{ marginLeft: '5px' }}>Bottom</Text>
                          </Frame>
                        </Frame>
                      </Group>
                    )}
                  </Box> */}
                  <Box>
                    <Frame onClick={() => setOpenColours((prevState) => !prevState)}>
                      <SubTitle>Colours</SubTitle>
                      <DownArrow style={{ marginTop: '5px' }} />
                    </Frame>
                    {openColours && (
                      <span style={{ marginTop: '15px' }}>
                        <ColourPalette selectedColours={selectedColours} setSelectedColours={setSelectedColours} />
                      </span>
                    )}
                  </Box>
                  {/* <Frame style={{ width: '110px', marginTop: '15px' }}>
                  <CognisaasCheckBox checked={flipAxis} onClick={() => setFlipAxis((prevState) => !prevState)} />
                  <Text>Flip X and Y axis</Text>
                </Frame> */}
                  <Stack alignItems={'flex-end'} sx={{ marginTop: '16px' }}>
                    <CognisaasButton
                      label="Save"
                      isOutlined={false}
                      clickHandler={() => handleSave()}
                      disabled={
                        (graphs?.length === 2 && props.formActionType === 'save') ||
                        xAxisValue.length === 0 ||
                        yAxisValue.length === 0 ||
                        chartName.length === 0
                      }
                    />
                  </Stack>
                </div>
              </>
            )}
          </Container>
        </Wrapper>
      )}
    </Drawer>
  );
};

export default CustomiseDrawer;
