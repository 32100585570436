import { Typography, Stack, TextField } from '@mui/material';
import { useState } from 'react';
import CognisaasButton from 'components/shared/CognisaasButton/CognisaasButton';
import { mimicLoginForPasswordCheck } from '../../../store/modules/auth/auth';
import { useAppDispatch } from 'store/hooks';

interface Props {
  email: string;
  closeModal: () => void;
  closeModalAfterAction: (updateDone: boolean, newEmail: string) => void;
}

const EmailUpdateModal = (props: Props) => {
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState<string>(props.email);
  const [password, setPassword] = useState<string>('');

  const saveNewEmail = async () => {
    const details = {
      email: props.email,
      password: password,
      application: 'cognisaas_app',
    };
    const res = await dispatch(mimicLoginForPasswordCheck(details)).unwrap();
    props.closeModalAfterAction(res ? true : false, email);
  };
  return (
    <Stack>
      <Typography sx={{ paddingLeft: '16px' }} variant="h6">
        Update Email
      </Typography>
      <Stack m={2}>
        <TextField
          style={{ width: '410px', marginBottom: '16px' }}
          size="small"
          autoFocus={true}
          autoComplete=""
          value={email}
          onChange={(e: any) => {
            setEmail(e.target.value);
          }}
        />
        <TextField
          style={{ width: '410px' }}
          size="small"
          autoComplete=""
          value={password}
          type="password"
          onChange={(e: any) => {
            setPassword(e.target.value);
          }}
          placeholder="Enter current password"
        />
      </Stack>
      <Stack mt={3} spacing={2} justifyContent="flex-end" direction="row">
        <CognisaasButton isOutlined={true} clickHandler={() => props.closeModal()} label="Cancel" />
        <CognisaasButton clickHandler={saveNewEmail} isOutlined={false} label="Save" />
      </Stack>
    </Stack>
  );
};

export default EmailUpdateModal;
