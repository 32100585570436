// import { useState, useEffect } from 'react';
// import Typography from '@mui/material/Typography';
// import { Button } from '@mui/material';
// import Profile from './Profile/Profile';
// import Configuration from './Configuration/Configuration';
// import Fields from './Fields/Fields';
// import Notifications from './Notifications/Notification';
// import EmailConfiguration from './EmailConfigurations/EmailConfiguration';
// import TimeTracking from './Time Tracking/TimeTracking';
// import Integration from './Integration/Integration';
// import Preferences from './Preferences/Preferences';
// import Users from './Users/Users';
// import Jira from './Integration/Jira';
// import Styles from './Settings.module.css';
// import { useDispatch } from 'react-redux';
// import { getCustomerDetails, getUserDetails_slice } from '../../store/modules/Settings/slice';
// import { ReactComponent as ConfigurationIcon } from '../../assets/svgs/confi_setting_icon.svg';
// import { ReactComponent as NitificationIcon } from '../../assets/svgs/noti_bell.svg';
// import { ReactComponent as TimeTrackingIcon } from '../../assets/svgs/time_tracking.svg';
// import { ReactComponent as IntegrationIcon } from '../../assets/svgs/integration.svg';
// import { ReactComponent as AvatarIcon } from '../../assets/svgs/avatar_setting.svg';
// import { ReactComponent as FieldIcon } from '../../assets/svgs/settingField.svg';
// import { ReactComponent as UsersIcon } from '../../assets/svgs/users.svg';
// import { ReactComponent as EmailSettingsIcon } from '../../assets/svgs/email_settings.svg';
// import Cookies from 'universal-cookie';

// const cookies = new Cookies();

// interface TabTouchablePropTypes {
//   tabString: string;
//   tabId: number;
//   handleChange: (value: number) => void;
//   buttonColor: any;
//   buttonTextColor: any;
//   FrontIcon: React.ReactNode;
// }

// const TabTouchable = (prop: TabTouchablePropTypes) => {
//   return (
//     <Button
//       className={Styles.touchable_button}
//       style={{ backgroundColor: prop.buttonColor, color: prop.buttonTextColor }}
//       onClick={() => {
//         prop.handleChange(prop.tabId);
//       }}
//     >
//       {prop.FrontIcon} {prop.tabString}
//     </Button>
//   );
// };

// const Settings = () => {
//   const dispatch = useDispatch();
//   const [currentTab, setCurrentTab] = useState(1);
//   const user_uid = cookies.get('cogni_user_uid');

//   // const changeTabFromOthertab = (tabNumber: number) => {
//   //   setCurrentTab(tabNumber);
//   // };

//   useEffect(() => {
//     dispatch(getCustomerDetails());
//   }, []);

//   useEffect(() => {
//     if (user_uid != null) {
//       dispatch(getUserDetails_slice(user_uid));
//     }
//   }, [user_uid]);

//   const handleChange = (newtabId: number) => {
//     setCurrentTab(newtabId);
//   };
//   console.log(currentTab);

//   return (
//     <div className={Styles.settings_main}>
//       <div className={Styles.settings_left_pannel}>
//         <div className={Styles.setting_left_header}>
//           <Typography marginLeft={'30px'} variant="h1" sx={{ fontWeight: '500' }}>
//             Settings
//           </Typography>
//         </div>
//         <div className={Styles.setting_tabs_touchables}>
//           <TabTouchable
//             FrontIcon={<AvatarIcon style={{ margin: '8px', transform: 'scale(1.15)' }} />}
//             tabString={'Profile'}
//             tabId={1}
//             handleChange={handleChange}
//             buttonTextColor={currentTab == 1 ? '#2D60F6' : 'black'}
//             buttonColor={currentTab == 1 ? '#2D5FF62A' : null}
//           />
//           {cookies.get('user_type') === 'S' && (
//             <>
//               <TabTouchable
//                 FrontIcon={<AvatarIcon style={{ margin: '8px', transform: 'scale(1.15)' }} />}
//                 tabString={'Preferences'}
//                 tabId={2}
//                 handleChange={handleChange}
//                 buttonTextColor={currentTab == 2 ? '#2D60F6' : 'black'}
//                 buttonColor={currentTab == 2 ? '#2D5FF62A' : null}
//               />
//               <TabTouchable
//                 FrontIcon={<ConfigurationIcon style={{ margin: '8px', transform: 'scale(1.15)' }} />}
//                 tabString={'Configuration'}
//                 tabId={3}
//                 handleChange={handleChange}
//                 buttonTextColor={currentTab == 3 ? '#2D60F6' : 'black'}
//                 buttonColor={currentTab == 3 ? '#2D5FF62A' : null}
//               />
//               <TabTouchable
//                 FrontIcon={<FieldIcon style={{ margin: '8px', transform: 'scale(1.15)' }} />}
//                 tabString={'Custom Fields'}
//                 tabId={4}
//                 handleChange={handleChange}
//                 buttonTextColor={currentTab == 4 ? '#2D60F6' : 'black'}
//                 buttonColor={currentTab == 4 ? '#2D5FF62A' : null}
//               />
//               <TabTouchable
//                 FrontIcon={<NitificationIcon style={{ margin: '8px', transform: 'scale(1.15)' }} />}
//                 tabString={'Notifications'}
//                 tabId={5}
//                 handleChange={handleChange}
//                 buttonTextColor={currentTab == 5 ? '#2D60F6' : 'black'}
//                 buttonColor={currentTab == 5 ? '#2D5FF62A' : null}
//               />
//               <TabTouchable
//                 FrontIcon={<EmailSettingsIcon style={{ margin: '8px', width: '18px', height: '18px' }} />}
//                 tabString={'Email Configuration'}
//                 tabId={6}
//                 handleChange={handleChange}
//                 buttonTextColor={currentTab == 6 ? '#2D60F6' : 'black'}
//                 buttonColor={currentTab == 6 ? '#2D5FF62A' : null}
//               />
//               <TabTouchable
//                 FrontIcon={<TimeTrackingIcon style={{ margin: '8px', transform: 'scale(1.15)' }} />}
//                 tabString={'Time Tracking'}
//                 tabId={7}
//                 handleChange={handleChange}
//                 buttonTextColor={currentTab == 7 ? '#2D60F6' : 'black'}
//                 buttonColor={currentTab == 7 ? '#2D5FF62A' : null}
//               />
//               <TabTouchable
//                 FrontIcon={<IntegrationIcon style={{ margin: '8px', transform: 'scale(1.15)' }} />}
//                 tabString={'Integrations'}
//                 tabId={8}
//                 handleChange={handleChange}
//                 buttonTextColor={currentTab == 8 || currentTab == 10 ? '#2D60F6' : 'black'}
//                 buttonColor={currentTab == 8 || currentTab == 10 ? '#2D5FF62A' : null}
//               />
//               <TabTouchable
//                 FrontIcon={<UsersIcon style={{ margin: '8px', transform: 'scale(1.15)' }} />}
//                 tabString={'Users'}
//                 tabId={9}
//                 handleChange={handleChange}
//                 buttonTextColor={currentTab == 9 || currentTab == 9 ? '#2D60F6' : 'black'}
//                 buttonColor={currentTab == 9 || currentTab == 9 ? '#2D5FF62A' : null}
//               />
//             </>
//           )}
//         </div>
//       </div>
//       <div className={Styles.settings_right_pannel}>
//         {currentTab == 1 ? (
//           <Profile />
//         ) : currentTab == 2 ? (
//           <Preferences />
//         ) : currentTab == 3 ? (
//           <Configuration />
//         ) : currentTab == 4 ? (
//           <Fields />
//         ) : currentTab == 5 ? (
//           <Notifications />
//         ) : currentTab == 6 ? (
//           <EmailConfiguration />
//         ) : currentTab == 7 ? (
//           <TimeTracking />
//         ) : currentTab == 8 ? (
//           <Integration />
//         ) : currentTab == 10 ? (
//           <Jira />
//         ) : currentTab == 9 ? (
//           <Users />
//         ) : (
//           <Profile />
//         )}
//       </div>
//     </div>
//   );
// };

const Settings = () => <div>component not needed</div>;
export default Settings;
