import { Stack, Typography } from '@mui/material';
import { useAppDispatch } from 'store/hooks';
import { cloneCustomReport, deleteCustomReport, getCustomReports } from 'store/modules/Reports/CustomReports/slice';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCustomerUid, getLoggedInUserUid } from 'utils/getUid';
import { ReactComponent as NoRecords } from '../../../assets/svgs/no_records.svg';
import ReportInfoCard from './components/ReportInfoCard';
import { isUndefined } from 'utils/dataHelpers';
import {
  label_client,
  label_project,
  label_task,
  label_use_case,
} from 'components/Settings/Preferences/CustomLabelCookieHelper';
import CognisaasLoader from 'components/shared/CognisaasLoader/CognisaasLoader';
import useAggregatedCustomReports from '../AggregatedCustomReports/View/useAggregatedCustomReports';
import { REPORT_TYPE_AGGREGATED_REPORT } from '../Shared/constants';

const CustomReports = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [customReports, setCustomReports] = useState<any[] | null>(null);
  const { aggregatedReports, getAggregatedReports } = useAggregatedCustomReports();

  const getReports = async () => {
    const payload = {
      user_uid: getLoggedInUserUid(),
      customer_uid: getCustomerUid(),
    };
    const custom_reports = await dispatch(getCustomReports(payload)).unwrap();
    if (custom_reports.status == 1) {
      setCustomReports(custom_reports.result);
    }
  };

  useEffect(() => {
    getReports();
  }, []);

  const getEntitiesFromConfig = (config: Record<string, any>) => {
    const entities: string[] = [];
    if (!isUndefined(config.client) && config.client.length > 0) {
      entities.push(label_client);
    }
    if (!isUndefined(config.project) && config.project.length > 0) {
      entities.push(label_project);
    }
    if (!isUndefined(config.usecase) && config.usecase.length > 0) {
      entities.push(label_use_case);
    }
    if (!isUndefined(config.task) && config.task.length > 0) {
      entities.push(label_task);
    }
    return entities;
  };
  const handleReportDelete = async (reportUid: string, isAggregated: boolean) => {
    await dispatch(
      deleteCustomReport({
        report_uid: reportUid,
        is_aggregated_report: isAggregated,
      })
    );
    getReports();
    getAggregatedReports();
  };

  const handleReportClone = async (reportUid: string, isAggregated: boolean) => {
    await dispatch(
      cloneCustomReport({
        report_uid: reportUid,
        is_aggregated_report: isAggregated,
        user_uid: getLoggedInUserUid(),
        customer_uid: getCustomerUid(),
      })
    );
    getReports();
    getAggregatedReports();
  };

  return (
    <>
      {!customReports ? (
        <CognisaasLoader />
      ) : (
        <>
          {Array.isArray(customReports) && customReports.length == 0 ? (
            <Stack style={{ marginTop: '32px' }} justifyContent={'center'} alignItems={'center'}>
              <Typography variant="h5">Nothing to show!</Typography>
              <NoRecords style={{ width: '300px', height: '300px' }} />
            </Stack>
          ) : (
            <Stack direction={'row'} flexWrap={'wrap'} mt={3}>
              {/* {customReports?.map((reportInfo: any, index: number) => {
                return (
                  <ReportInfoCard
                    title={reportInfo.report_name}
                    description={reportInfo.report_description}
                    onClick={() => navigate(`/reports/custom-report/${reportInfo.report_uid}`)}
                    private={reportInfo.is_private === '1'}
                    editableByEveryone={reportInfo.global_write_access === '1'}
                    entities={getEntitiesFromConfig(JSON.parse(reportInfo.report_config))}
                    key={index}
                    onDelete={() => {
                      handleReportDelete(reportInfo.report_uid, false);
                    }}
                    onClone={() => {
                      handleReportClone(reportInfo.report_uid, false);
                    }}
                  />
                );
              })} */}

              {aggregatedReports?.map((reportInfo: any, index: number) => {
                return (
                  <ReportInfoCard
                    title={reportInfo.report_name}
                    description={reportInfo.report_description}
                    onClick={() => navigate(`/reports/aggregated-custom-report/${reportInfo.report_uid}`)}
                    private={reportInfo.is_private === '1'}
                    editableByEveryone={reportInfo.global_write_access === '1'}
                    entities={
                      reportInfo.report_type === REPORT_TYPE_AGGREGATED_REPORT
                        ? [...getEntitiesFromConfig(JSON.parse(reportInfo.report_config)), 'aggregated report']
                        : getEntitiesFromConfig(JSON.parse(reportInfo.report_config))
                    }
                    key={index}
                    onDelete={() => {
                      handleReportDelete(reportInfo.report_uid, true);
                    }}
                    onClone={() => {
                      handleReportClone(reportInfo.report_uid, true);
                    }}
                  />
                );
              })}
            </Stack>
          )}
        </>
      )}
    </>
  );
};

export default CustomReports;
