import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import ProductRequirementMappingV2 from '../../../../prodReqMapping_v2/ProductRequirementMappingV2';
import {
  //   getCustDetails,
  //   saveTemplateProjectSection,
  //   getAllSectionByTemplateProjectuid,
  //   getProjectTemplates,
  //   addTemplateProjectTask,
  //   addTemplateProjectClientTask,
  //   updateTemplateProjectByUid,
  //   getTemplateProjectinformation,
  //   deleteProjectTemplatesectionByUid,
  //   updateTemplateProjectTaskbyuid,
  //   updateTemplateProjectClienttaskByuid,
  //   deleteTemplateProjectTaskbyUid,
  //   deleteTemplateProjectClientTaskbyUid,
  getTemplateUsecaseInformation,
  getAllSectionByTemplateCaseuid,
  updateTemplateUsecaseByUid,
  getUseCaseTemplatesbycustomer,
  getTemplateUsecaseDependency,
  toggleUpdateUsecaseTemplateExcelViewSelectedState,
} from '../../../../../../store/modules/Templates/Templates';
import TemplateOverviewSection from '../../Shared/TemplateOverviewSection';
import { usecaseTemplateTabs } from 'components/Templates/TemplatesIndex/types';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import UpdateTemplatePlanSection from '../../Shared/UpdateTemplatePlanSection';
import { selectPrAndReqDataState } from '../../../../../../store/modules/ProductRequirements/ProductDetails/selector';
import { label_use_case } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import {
  selectToggleUpdateUsecaseTemplateExcelViewSelectedState,
  selectUpdateUsecaseTemplateExcelData,
  // selectUserDetails,
  //   selectTemplateProjectUid,
  //   selectTemplateSectionSequence,
  //   selectAllSectionTemplates,
  //   selectredirectToTemplates,
  //   selectprojectTemplateInformation,
  selectUsecaseTemplateInformation,
  selectusecaseTemplateSections,
} from '../../../../../../store/modules/Templates/selector';
import { useNavigate } from 'react-router-dom';
import { Typography, Grid, Stack, Button, ButtonGroup } from '@mui/material';
import CognisaasTabs from 'components/shared/CognisaasTabs';
import { updateUsecaseTemplate } from '../../Project/AddProjectTemplate/helper';
import { ReactComponent as TableViewUnselected } from '../../../../../../assets/svgs/table_view_unselected.svg';
import { ReactComponent as TableViewSelected } from '../../../../../../assets/svgs/table_view_unselected.svg';
import { ReactComponent as KanbanViewUnselected } from '../../../../../../assets/svgs/kanban_view_unselected.svg';
import { ReactComponent as KanbanViewSelected } from '../../../../../../assets/svgs/kanban_view_selected.svg';
// import { ReactComponent as GanttViewUnselected } from '../../../assets/svgs/gantt_chart_unselected.svg';
// import { useCreatePlanExcelForUpdate } from 'components/shared/Hooks/useCreatePlanExcelForUpdate';
import { updatePlanFromExcelUsecase } from '../../Shared/updatePlanFromExcel';
import UpdateTemplateExcelViewUsecase from '../../Shared/UpdateTemplateExcelViewUsecase';

const UpdateUsecaseTemplateIndex = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { projectUid } = location.state as any;
  const { userPermissions } = location.state as any;
  const projectTemplateInformation = useAppSelector(selectUsecaseTemplateInformation);
  const productsAndRequirementData = useAppSelector(selectPrAndReqDataState);
  const projectTemplateSections = useAppSelector(selectusecaseTemplateSections);
  const isExcelViewSelected = useAppSelector(selectToggleUpdateUsecaseTemplateExcelViewSelectedState);

  const [selectedTab, setSelectedTab] = useState<string>(usecaseTemplateTabs[0]);
  const [mappedFeatures, setMappedFeatures] = useState<any[]>([]);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [templateName, setTemplateName] = useState<string>('');
  const [templateOwner, setTemplateOwner] = useState<any>('');
  const [templateTargetDays, setTemplateTargetDays] = useState<number>(0);
  const [dependencyData, setDependencyData] = useState<any[]>([]);
  const excelData = useAppSelector(selectUpdateUsecaseTemplateExcelData);

  console.log(projectUid);

  useEffect(() => {
    makeTemplateDetailsReady();
  }, []);

  useEffect(() => {
    console.log(projectTemplateInformation);
    patchFormValues();
  }, [projectTemplateInformation]);

  useEffect(() => {
    const tempRealSelectedFeatures: any = [];
    if (projectTemplateInformation && projectTemplateInformation?.product?.length > 0) {
      productsAndRequirementData &&
        productsAndRequirementData?.data?.map((ele: any) => {
          ele?.feature?.map((newEle: any) => {
            projectTemplateInformation?.product?.map((aurEkEle: any) => {
              aurEkEle?.feature?.map((aurEkNayaEle: any) => {
                if (newEle?.feature_uid == aurEkNayaEle?.feature_uid) tempRealSelectedFeatures.push(newEle);
              });
            });
          });
        });
    }
    setSelectedFeatures(tempRealSelectedFeatures);
    // console.log(projectTemplateInformationApi.product, selectedFeatures, 'pro req dat');
    // console.log(productsAndRequirementData, projectTemplateInformationApi, 'test');
  }, [productsAndRequirementData, projectTemplateInformation]);

  useEffect(() => {
    // console.log('proj tmplate info ', projectTemplateInformationApi);
    if (projectTemplateInformation && projectTemplateInformation?.product?.length > 0) {
      const tempFeatures: any[] = [];
      projectTemplateInformation.product.forEach((product: Record<string, any>) => {
        product.feature.forEach((feature: Record<string, any>) => {
          tempFeatures.push({ ...feature, product_name: product.product_name });
        });
      });
      setMappedFeatures(tempFeatures);
    }
  }, [projectTemplateInformation]);

  const makeTemplateDetailsReady = async () => {
    await dispatch(getTemplateUsecaseInformation(projectUid));
    await dispatch(getAllSectionByTemplateCaseuid(projectUid));
    const dependency = await dispatch(getTemplateUsecaseDependency(projectUid));
    console.log(dependency);
    setDependencyData([...dependency.payload]);
  };

  const patchFormValues = () => {
    setTemplateName(projectTemplateInformation.template_case_name);
    setTemplateOwner(projectTemplateInformation.template_owner_name);
    setTemplateTargetDays(projectTemplateInformation.template_case_target_days);
  };

  const featuresListForDb = mappedFeatures.map((feEle) => ({
    dependency_case_feature_config: '',
    feature_checked_status: true,
    feature_creaded_on: feEle.feature_creaded_on,
    feature_description: feEle.feature_description,
    feature_estimateddeliverydate: feEle.feature_estimateddeliverydate,
    feature_id: feEle.feature_id,
    feature_issue_type_name: feEle.feature_issue_type_name,
    feature_modified_on: feEle.feature_modified_on,
    feature_readinessstate_status: feEle.feature_readinessstate_status,
    feature_uid: feEle.feature_uid,
    product_issue_id: feEle.product_issue_id,
    product_uid: feEle.product_uid,
  }));

  const handleSubmitProject = async () => {
    if (isExcelViewSelected) {
      const sections = await dispatch(getAllSectionByTemplateCaseuid(projectUid)).unwrap();
      await updatePlanFromExcelUsecase(excelData, projectUid, sections);
      // await dispatch(getAllSectionByTemplateProjectuid(projectUid));
      console.log('update');
      makeTemplateDetailsReady();
      // useCreatePlanExcelForUpdate(sections, []);
      return;
    } else {
      console.log(templateName, templateOwner, templateTargetDays, selectedFeatures);
      const sections = await dispatch(getAllSectionByTemplateCaseuid(projectUid)).unwrap();
      console.log(templateName, templateOwner, [...sections], templateTargetDays, projectUid);
      const payload = {
        templateName,
        templateOwner,
        sections: [...sections],
        features: [...featuresListForDb],
        templateTargetDays,
        newTemplateUid: projectUid,
      };
      const postData = updateUsecaseTemplate(payload);
      console.log(postData);
      await dispatch(updateTemplateUsecaseByUid(postData));
      await dispatch(getUseCaseTemplatesbycustomer());
      navigate('/templates');
    }
  };

  return (
    <>
      <Stack ml={2} my={2}>
        <Typography sx={{ fontSize: '12px', fontWeight: '400', ml: '25px', color: '#2D60F6' }}>Templates</Typography>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Stack direction="row">
              <ArrowBackIosNewIcon
                onClick={() => navigate(-1)}
                fontSize="small"
                sx={{ mt: '5px', cursor: 'pointer' }}
              />
              <Typography sx={{ fontSize: '20px', fontWeight: '500', ml: '5px' }}>{label_use_case} Template</Typography>
            </Stack>
          </Grid>
          <Grid item>
            <Button
              disableRipple
              disabled={templateTargetDays < 0 || templateName?.trim() == '' ? true : false}
              variant="contained"
              onClick={() => {
                handleSubmitProject();
                // setStateDrawerOpen(false);
              }}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Stack>
      <Stack ml={2} direction="row" justifyContent={'space-between'}>
        <CognisaasTabs
          size="squeezed"
          tabs={[...usecaseTemplateTabs]}
          handleTabSwitch={(e: any, index: number) => setSelectedTab(usecaseTemplateTabs[index])}
        />
        <Stack direction={'row'}>
          {/* {tabIndex == 1 && ( */}
          <Stack direction="row" justifyContent={'space-between'}>
            <ButtonGroup sx={{ backgroundColor: 'white' }} variant="outlined" aria-label="outlined button group">
              <Button
                className={`${isExcelViewSelected ? 'active-btn' : null} `}
                onClick={() => {
                  dispatch(toggleUpdateUsecaseTemplateExcelViewSelectedState());
                }}
              >
                {console.log(isExcelViewSelected)}
                {isExcelViewSelected ? <TableViewSelected /> : <TableViewUnselected />}
              </Button>
              <Button
                className={`${!isExcelViewSelected ? 'active-btn' : null} `}
                onClick={() => {
                  dispatch(toggleUpdateUsecaseTemplateExcelViewSelectedState());
                }}
              >
                {isExcelViewSelected ? <KanbanViewSelected /> : <KanbanViewUnselected />}
              </Button>
            </ButtonGroup>
          </Stack>
        </Stack>
      </Stack>
      <Stack ml={2} my={2}>
        {selectedTab == usecaseTemplateTabs[0] ? (
          <TemplateOverviewSection
            userPermissions={userPermissions}
            name={templateName}
            owner={templateOwner}
            days={templateTargetDays}
            newTemplateName={(name: string) => setTemplateName(name)}
            newTemplateOwner={(name: any) => {
              setTemplateOwner(name);
              console.log(name);
            }}
            newTemplateTargetDays={(days: any) => setTemplateTargetDays(days)}
          />
        ) : selectedTab == usecaseTemplateTabs[1] ? (
          <>
            {!isExcelViewSelected ? (
              <UpdateTemplatePlanSection
                userPermissions={userPermissions}
                type="usecase"
                projectTemplateUid={projectUid}
                sectionsData={projectTemplateSections}
              />
            ) : (
              <UpdateTemplateExcelViewUsecase data={projectTemplateSections} dependency={dependencyData} />
            )}
          </>
        ) : selectedTab == usecaseTemplateTabs[2] ? (
          <ProductRequirementMappingV2
            userPermissions={userPermissions}
            mappedFeatures={mappedFeatures}
            setMappedFeatures={setMappedFeatures}
          />
        ) : null}
      </Stack>
    </>
  );
};

export default UpdateUsecaseTemplateIndex;
