import { Box, Typography, Grid, Divider } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getClientDetail } from '../../../../store/modules/ui/DataViews/slice';
import { selectClientDetails } from '../../../../store/modules/ui/DataViews/selector';
import { useAppSelector } from '../../../../store/hooks';
import { formatToUSD, getStatus, getStatusColor, isNull } from '../../../../utils/dataHelpers';
import CognisaasLoader from '../../CognisaasLoader/CognisaasLoader';
import Avatar from '@mui/material/Avatar';
import { daysToFullTimeLength, parseServerDateToFullDateWithFullMonths } from '../../../../utils/DateHelper';
import { add, format } from 'date-fns';
import { Link } from 'react-router-dom';
import { label_arr, label_client } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import useCustomNames from 'components/shared/CustomNames/useCustomNames';
import NAMED_CONSTATNTS from 'components/shared/CustomNames/Constants';

interface BasicClientViewProps {
  clientUid: string;
}

const BasicClientView = (props: BasicClientViewProps) => {
  const dispatch = useDispatch();
  const clientDetailsState = useAppSelector(selectClientDetails);
  const { getCustomNameMappedValue } = useCustomNames();

  useEffect(() => {
    dispatch(getClientDetail(props.clientUid));
  }, []);

  useEffect(() => {
    console.log(clientDetailsState);
  }, [clientDetailsState]);

  const formatCountdownDays = (numberOfDays: number) => {
    const result = add(new Date(), {
      years: 0,
      months: 0,
      weeks: 0,
      days: numberOfDays,
      hours: 0,
      minutes: 0,
      seconds: 0,
    });
    console.log(format(result, 'd MMM yyyy'));
    return format(result, 'd MMM yyyy');
  };

  return (
    <>
      {clientDetailsState.status === 'success' ? (
        <>
          <Typography variant="h2">{clientDetailsState.data.client_name}</Typography>
          <Typography variant="subtitle2" className="label-text">
            {label_client} Name
          </Typography>
          <Box
            sx={{
              borderRadius: '4px',
              boxShadow: '0px 4px 26px rgba(0, 0, 0, 0.05)',
              border: '1px solid #E5E5E5',
              marginTop: '20px',
              padding: '20px',
            }}
          >
            <Grid container>
              {/* 1st line */}
              <Grid item xs={12} sx={{ marginBottom: '24px' }}>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" className="label-text">
                      Industry
                    </Typography>
                    <Typography variant="subtitle1">{clientDetailsState.data.industry_name}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" className="label-text">
                      Status
                    </Typography>
                    <Typography variant="subtitle1">
                      <Box
                        sx={{
                          width: '8px',
                          height: '8px',
                          background: getStatusColor(clientDetailsState.data.client_readiness_status),
                          borderRadius: '50%',
                          marginRight: '10px',
                          display: 'inline-block',
                        }}
                      ></Box>
                      {getStatus(clientDetailsState.data.client_readiness_status)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {/* 2nd line  */}
              <Grid item xs={12} sx={{ marginBottom: '24px' }}>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" className="label-text">
                      Account {label_arr}
                    </Typography>
                    <Typography variant="subtitle1">
                      {formatToUSD(parseInt(clientDetailsState.data.client_account_worth))}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" className="label-text">
                      Journey Stage
                    </Typography>
                    <Typography variant="subtitle1">
                      {getCustomNameMappedValue(
                        clientDetailsState.data.journey_stage,
                        NAMED_CONSTATNTS.client_stage_key
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {/* third line */}
              <Grid item xs={12} sx={{ marginBottom: '24px' }}>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" className="label-text">
                      Segment
                    </Typography>
                    <Typography variant="subtitle1">
                      {getCustomNameMappedValue(clientDetailsState.data.segment_name, NAMED_CONSTATNTS.segment_name)}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    {/* <Typography variant="subtitle2" className="label-text">
                      Industry
                    </Typography>
                    <Typography variant="subtitle1">{clientDetailsState.data.industry_name}</Typography> */}
                  </Grid>
                </Grid>
              </Grid>
              {/* fourth line */}
              <Grid item xs={12} sx={{ marginBottom: '20px' }}>
                <Grid container>
                  <Grid item xs={6}>
                    <Grid container alignItems={'center'}>
                      <Grid item xs={3}>
                        <Avatar sx={{ width: 30, height: 30, color: 'black', background: 'rgba(243, 219, 158, 0.5)' }}>
                          {clientDetailsState.data.client_account_owner[0]}
                        </Avatar>
                      </Grid>
                      <Grid item xs={9}>
                        <Typography variant="subtitle2" className="label-text">
                          Sales Manager
                        </Typography>
                        <Typography variant="subtitle1">{clientDetailsState.data.client_account_owner}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container alignItems={'center'}>
                      <Grid item xs={3}>
                        <Avatar sx={{ width: 30, height: 30, color: 'black', background: 'rgba(243, 219, 158, 0.5)' }}>
                          {clientDetailsState.data.client_sales_owner[0]}
                        </Avatar>
                      </Grid>
                      <Grid item xs={9}>
                        <Typography variant="subtitle2" className="label-text">
                          CS Manager
                        </Typography>
                        <Typography variant="subtitle1">{clientDetailsState.data.client_sales_owner}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ marginBottom: '20px' }}>
                <Divider orientation="horizontal" />
              </Grid>
              {/* fifth line */}
              <Grid item xs={12} sx={{ marginBottom: '24px' }}>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" className="label-text">
                      Renewal Date
                    </Typography>
                    <Typography variant="subtitle1">
                      {isNull(clientDetailsState.data.renewal_date)
                        ? '-'
                        : parseServerDateToFullDateWithFullMonths(clientDetailsState.data.renewal_date)}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" className="label-text">
                      Renewal Duration
                    </Typography>
                    <Typography variant="subtitle1">
                      {isNull(clientDetailsState.data.renewal_duration)
                        ? '-'
                        : clientDetailsState.data.renewal_duration}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {/* sixth line */}
              <Grid item xs={12} sx={{ marginBottom: '24px' }}>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" className="label-text">
                      Renewal Reminder
                    </Typography>
                    <Typography variant="subtitle1">
                      {isNull(clientDetailsState.data.renewal_reminder_days_before)
                        ? '-'
                        : `Starts on ${formatCountdownDays(
                            parseInt(clientDetailsState.data.renewal_reminder_days_before)
                          )}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" className="label-text">
                      Renewal Frequency
                    </Typography>
                    <Typography variant="subtitle1">
                      {isNull(clientDetailsState.data.renewal_reminder_days_before_frequency)
                        ? '-'
                        : daysToFullTimeLength(
                            parseInt(clientDetailsState.data.renewal_reminder_days_before_frequency)
                          )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {/* seventh line */}
              <Grid item xs={12}>
                <Link to={`/client-list/client/${props.clientUid}`}>
                  <Typography variant="subtitle1" className="link-text cognisaas-pointer">
                    Go to clients page {'>'}
                  </Typography>
                </Link>
              </Grid>
            </Grid>
          </Box>
        </>
      ) : (
        <CognisaasLoader />
      )}
    </>
  );
};

export default BasicClientView;
