import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../index';

export const selectUsecaseState = (state: RootState) => state.usecaseReducer;

export const selectUsecaseCustomFields = createSelector(
  selectUsecaseState,
  (usecaseState) => usecaseState.usecaseCustomFields
);

export const selectUsecaseCustomfieldsStatus = createSelector(
  selectUsecaseState,
  (usecaseState) => usecaseState.getUsecaseCustomFieldsStatus
);

export const selectUsecaseCustomFieldsDetail = createSelector(
  selectUsecaseState,
  (usecaseState) => usecaseState.usecaseCustomFieldsWithValues
);

export const selectClientDetails = createSelector(selectUsecaseState, (usecaseState) => usecaseState.clientInformation);

export const selectUsecaseAudit = createSelector(selectUsecaseState, (usecaseState) => usecaseState.getUsecaseAudit);

export const selectUsecaseInfo = createSelector(selectUsecaseState, (usecaseState) => usecaseState.getUsecaseInfo);

export const selectAllCustomFieldsOfAllUsecases = createSelector(
  selectUsecaseState,
  (usecaseState) => usecaseState.allUsecaseCustomFields
);

export const selectUsecaseLevelComments = createSelector(
  selectUsecaseState,
  (usecaseState) => usecaseState.allUsecaseComments
);
