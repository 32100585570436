import { operation } from './Operations';

export const allowedOperationsList = {
  string: [
    operation.count,
    // operation.uniqueList,
    // operation.uniqueListWithFrequency,
    // operation.list,
    // operation.maxOccurance,
    // operation.minOccurance,
    // operation.nothing,
  ],
  date: [
    operation.count,
    // operation.max,
    // operation.min,
    // operation.list,
    // operation.uniqueList,
    // operation.uniqueListWithFrequency,
    // operation.maxOccurance,
    // operation.minOccurance,
    // operation.nothing,
  ],
  link: [
    operation.count,
    // operation.list,
    // operation.uniqueList,
    // operation.uniqueListWithFrequency,
    // operation.maxOccurance,
    // operation.minOccurance,
    // operation.nothing,
  ],
  rag: [
    operation.count,
    // operation.uniqueList,
    // operation.uniqueListWithFrequency,
    // operation.maxOccurance,
    // operation.minOccurance,
    // operation.nothing,
  ],
  progressBar: [
    operation.count,
    // operation.max,
    // operation.min,
    // operation.average,
    // operation.list,
    // operation.uniqueList,
    // operation.uniqueListWithFrequency,
    // operation.maxOccurance,
    // operation.minOccurance,
    // operation.nothing,
  ],
  number: [
    operation.count,
    operation.sum,
    operation.average,
    // operation.max,
    // operation.min,
    // operation.list,
    // operation.uniqueList,
    // operation.uniqueListWithFrequency,
    // operation.mean,
    // operation.median,
    // operation.maxOccurance,
    // operation.minOccurance,
    // operation.nothing,
  ],
  dropDown: [
    operation.count,
    // operation.uniqueList,
    // operation.uniqueListWithFrequency,
    // operation.maxOccurance,
    // operation.minOccurance,
    // operation.nothing,
  ],
  userName: [
    operation.count,
    // operation.list,
    // operation.uniqueList,
    // operation.uniqueListWithFrequency,
    // operation.maxOccurance,
    // operation.minOccurance,
    // operation.nothing,
  ],
  taskPanel: ['view', 'edit', 'count'],
  dialog: ['view', 'edit', 'count'],
  boolean: ['view', 'edit'],
  nothing: [operation.nothing],
};
