import React, { useState, useMemo } from 'react';
import {
  FormControl,
  Select,
  MenuItem,
  ListSubheader,
  TextField,
  InputAdornment,
  // SelectChangeEvent,
  ListItemText,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CognisaasCheckBox from '../../CognisaasCheckbox/CognisaasCheckbox';
import { Box } from '@mui/system';

const containsText = (text: string, searchText: string) => text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

interface Props {
  optionSubLevelKey: string;
  dataToGenerateoptions: Record<string, any>[];
  dataKeyWithUniqueDataAtOptionLevel: string;
  dataKeysWhoseDataRequiredForFutureUse: string[];
  placeholder?: string;
  selectedOption: Record<string, string>[];
  setSelectedOptions: React.Dispatch<React.SetStateAction<Record<string, string>[]>>;
  groupHeaderKey: string;
  optionKeyForDataDisplay: string;
}

const ProductAndRequirementSelect = (props: Props) => {
  //   const [selectedOption, setSelectedOption] = useState<string[]>([]);
  // console.log('prod req menu', props.dataToGenerateoptions);
  const [searchText, setSearchText] = useState('');
  // const [filtereData, setFiltereData] = useState(props.dataToGenerateoptions);

  const displayedOptions = useMemo(
    () => {
      const finalOptionsArray: any[] = [];

      props.dataToGenerateoptions.forEach((item) => {
        const filteredSubLevelOptionsData = item[props.optionSubLevelKey].filter((record: Record<string, string>) =>
          containsText(record[props.optionKeyForDataDisplay], searchText)
        );
        if (filteredSubLevelOptionsData.length > 0) {
          finalOptionsArray.push({ ...item, [props.optionSubLevelKey]: filteredSubLevelOptionsData });
        }
      });

      return finalOptionsArray;
    },
    // () => props.allOptions.filter((option: string) => containsText(option, searchText)),
    [searchText]
  );

  // const handleChange = (event: SelectChangeEvent<typeof props.selectedOption>) => {
  //   // const {
  //   //   target: { value },
  //   // } = event;
  //   // props.setSelectedOptions(
  //   //   // On autofill we get a stringified value.
  //   //   typeof value === 'string' ? value.split(',') : value
  //   // );
  // };

  const handleOptionClicked = (subLevelDataObj: Record<string, string>) => {
    const tempSelectedOptionWithOptionRemovedIfExist = props.selectedOption.filter(
      (item) =>
        item[props.dataKeyWithUniqueDataAtOptionLevel] !== subLevelDataObj[props.dataKeyWithUniqueDataAtOptionLevel]
    );
    if (tempSelectedOptionWithOptionRemovedIfExist.length < props.selectedOption.length) {
      // that means option need to be removed
      props.setSelectedOptions(tempSelectedOptionWithOptionRemovedIfExist);
    } else {
      // option need to be added to the list
      const optionObj: Record<string, string> = {};
      optionObj[props.dataKeyWithUniqueDataAtOptionLevel] = subLevelDataObj[props.dataKeyWithUniqueDataAtOptionLevel];
      props.dataKeysWhoseDataRequiredForFutureUse.map((item) => {
        optionObj[item] = subLevelDataObj[item];
      });
      props.setSelectedOptions((prevState) => [...prevState, optionObj]);
      console.log(optionObj);
    }
  };

  return (
    <FormControl fullWidth size="small">
      {/* <InputLabel id="search-select-label">Options</InputLabel> */}
      <Select
        // Disables auto focus on MenuItems and allows TextField to be in focus
        displayEmpty
        MenuProps={{ autoFocus: false, PaperProps: { sx: { maxHeight: 250 } } }}
        inputProps={{ 'aria-label': 'Without label' }}
        labelId="search-select-label"
        id="search-select"
        multiple
        value={props.selectedOption}
        // label="Options"
        // onChange={handleChange}
        onClose={() => setSearchText('')}
        // This prevents rendering empty string in Select's value
        // if search text would exclude currently selected option.
        renderValue={(selected) => {
          if (selected.length === 0) {
            return props?.placeholder ? <em>Placeholder</em> : <em>Select</em>;
          }
          return selected.map((item) => item[props.optionKeyForDataDisplay]).join(', ');
        }}
      >
        {/* TextField is put into ListSubheader so that it doesn't
              act as a selectable item in the menu
              i.e. we can click the TextField without triggering any selection.*/}
        <ListSubheader>
          <TextField
            size="small"
            // Autofocus on textfield
            autoFocus
            placeholder="Type to search..."
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key !== 'Escape') {
                // Prevents autoselecting item while typing (default Select behaviour)
                e.stopPropagation();
              }
            }}
          />
        </ListSubheader>

        {displayedOptions.map((item: any, index: number) => (
          <Box key={index} component={'span'}>
            <ListSubheader sx={{ textTransform: 'uppercase' }}>{item[props.groupHeaderKey]}</ListSubheader>
            {item[props.optionSubLevelKey].map((record: any, idx: number) => (
              <MenuItem
                key={idx}
                value={record[props.optionKeyForDataDisplay]}
                sx={{ paddingLeft: '48px' }}
                onClick={() => handleOptionClicked(record)}
              >
                {/* <Checkbox checked={selectedOption.indexOf(option) > -1} /> */}
                <CognisaasCheckBox
                  checked={
                    props.selectedOption.filter(
                      (eachItem) =>
                        eachItem[props.dataKeyWithUniqueDataAtOptionLevel] ===
                        record[props.dataKeyWithUniqueDataAtOptionLevel]
                    ).length > 0
                  }
                />
                {/* <CognisaasCheckBox checked={props.selectedOption.indexOf(record[props.optionKeyForDataDisplay]) > -1} /> */}
                <ListItemText sx={{ marginLeft: '8px' }} primary={record[props.optionKeyForDataDisplay]} />
              </MenuItem>
            ))}
          </Box>
        ))}
      </Select>
    </FormControl>
  );
};

// {displayedOptions.map((option, i) => (
// <MenuItem key={i} value={option}>
//   {/* <Checkbox checked={selectedOption.indexOf(option) > -1} /> */}
//   <CognisaasCheckBox checked={props.selectedOption.indexOf(option) > -1} />
//   <ListItemText sx={{ marginLeft: '8px' }} primary={option} />
// </MenuItem>
// ))}

export default ProductAndRequirementSelect;
