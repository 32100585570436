import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CognisaasTabs from '../../shared/CognisaasTabs';
import Client from './MicroComponents/Client';
import Task from './MicroComponents/Task';
import Project from './MicroComponents/Project';
import Usecase from './MicroComponents/Usecase';
import './fields.module.css';
import { label_client, label_project, label_task, label_use_case } from '../Preferences/CustomLabelCookieHelper';

const Fields = () => {
  const [tabValue, setTabValue] = React.useState(0);
  console.log('tabValue');

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };
  const tabs = [label_client, `${label_project}s`, label_use_case, `${label_task}s`];

  return (
    <Box sx={{ width: '100%', paddingTop: '15px' }}>
      <CognisaasTabs selectedTabIndex={tabValue} handleTabSwitch={handleChange} tabs={tabs} />
      {tabValue == 0 ? (
        <Typography>
          <Client />
        </Typography>
      ) : tabValue == 1 ? (
        <Typography>
          <Project />
        </Typography>
      ) : tabValue == 2 ? (
        <Typography>
          <Usecase />
        </Typography>
      ) : tabValue == 3 ? (
        <Typography>
          <Task />
        </Typography>
      ) : null}
    </Box>
  );
};
export default Fields;
