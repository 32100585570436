import { LoadingButton } from '@mui/lab';
import { Drawer, Grid, Stack, Typography, TextField, Switch } from '@mui/material';
import CognisaasButton from 'components/shared/CognisaasButton/CognisaasButton';
import CognisaasCalenderNew from 'components/shared/CognisaasCalenderNew/CognisaasCalenderNew';
import CognisaasSelect from 'components/shared/CognisaasSelect/CognisaasSelect';
import { usePreselectedProjectMembers } from 'components/shared/Hooks/usePreselectedProjectMembers';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import SaveIcon from '@mui/icons-material/Save';
import { selectProjectInformation } from '../../../../store/modules/Project/selector';
import CognisaasToast from 'components/shared/CognisaasToast/CognisaasToast';
import { raidApi } from '../../../../api/modules/raid';
import { format } from 'date-fns';
import { getRaidByProjectID } from 'store/modules/RAID/slice';
import { doesValueExists } from 'components/TaskModule/AddTask/utils';
import { getProjectInformation } from 'store/modules/Project/slice';
import { updateCaseWithfeatureStatus } from 'store/modules/Common/slice';
import CognisaasAutocompleteSelect from 'components/shared/CognisaasSelect/CognisaasAutocompleteSelect';

const NewRaidDrawer = (props: any) => {
    const { onClose } = props;
    const projectInfo = useAppSelector(selectProjectInformation);
    const clientUid = projectInfo.client_uid;
    const projectUid = projectInfo.project_uid;
    const phaseOptions = projectInfo.section.map((phase: any) => ({ id: phase.section_uid, name: phase.section_detail }));
    const [raidName, setRaidName] = useState('');
    const [raidType, setRaidType] = useState('');
    const [raidStage, setRaidStage] = useState('Not Mitigated');
    const [dueDate, setDueDate] = useState(new Date());
    const [shareWithClient, setShareWithClient] = useState(false);
    const [taskOwner, setTaskOwner] = useState('Not Assigned');
    const [selectedPhase, setSelectedPhase] = useState<{ id: string; name: string }>({ id: '', name: '' });

    const { preSelectedCombinedMembers } = usePreselectedProjectMembers(projectUid, clientUid);
    const [isSavingInProgress, setIsSavingInProgress] = useState<boolean>(false);
    const [alertDetails, setAlertDetails] = useState<{
        isVisible: boolean;
        type: 'success' | 'failure';
        message: string;
    }>({ isVisible: false, type: 'success', message: '' });
    const [disableSave, setDisableSave] = useState<boolean>(true);
    const dispatch = useAppDispatch();

    // const taskOwnerDropdownOptions = preselectedProjectMembers.filter((user: any) => user.is_active === '1').map((user: any) => user.user_username);
    // const taskOwnerDropdownOptionsWithNotAssigned = ['Not Assigned', ...taskOwnerDropdownOptions];
    const notAssignedStr = 'Not Assigned';
    const taskOwnerDropdownOptionsWithNotAssigned = preSelectedCombinedMembers?.length
        ? [
            ...preSelectedCombinedMembers.filter((user: any) => user.is_active === '1').map((item: any) => item.name),
            notAssignedStr,
        ]
        : [notAssignedStr];

    const resetValues = () => {
        setRaidName('');
        setRaidType('');
        setDueDate(new Date());
        setShareWithClient(false);
        setTaskOwner('Not Assigned');
        setRaidStage('Not Mitigated');
        setSelectedPhase({ id: '', name: '' });
    };

    const validateAllFields = () => {
        if (doesValueExists(raidName) && doesValueExists(raidType) && doesValueExists(selectedPhase.id)) {
            setDisableSave(false);
        }
        else {
            setDisableSave(true);
        }
    };

    useEffect(() => {
        validateAllFields();
    }, [raidName, raidType, selectedPhase]);


    const saveTask = async (values: any) => {
        setIsSavingInProgress(true);
        const uid = preSelectedCombinedMembers?.find((user: any) => user.name === values.taskOwner)?.id || '15243';
        const data = {
            name: values.raidName,
            type: values.raidType,
            due_date: format(new Date(values.dueDate), 'yyyy-MM-dd'),
            share_with_client: values.shareWithClient,
            owner_uid: uid,
            project_uid: projectUid,
            client_uid: clientUid,
            section_uid: selectedPhase.id,
            stage: values.raidType!=='Assumption'? raidStage:'',
        };
        const response = await raidApi.createRaid(data);
        if (response?.data?.status === 0) {
            setAlertDetails({ isVisible: true, type: 'failure', message: response?.data?.message });
            setIsSavingInProgress(false);
            return;
        }
        setAlertDetails({ isVisible: true, type: 'success', message: 'RAID created successfully' });
        resetValues();
        await dispatch(updateCaseWithfeatureStatus(projectInfo.client_uid));
        const detail = {
            project_uid: projectInfo.project_uid,
            association_uid: projectInfo.association_uid,
        };
        await dispatch(getProjectInformation(detail));
        await dispatch(getRaidByProjectID(projectInfo?.project_uid));
        setIsSavingInProgress(false);
        onClose();
    };


    return (
        <>
            <Drawer
                anchor="right"
                open={props.open}
                onClose={props.onClose}
                sx={{ width: '300px' }}
            >

                <form>
                    <Grid spacing={2} mx={2}>
                        <Typography my={1} sx={{ marginTop: '70px' }} variant="h2">
                            Add RAID
                        </Typography>
                        <Stack spacing={2} direction="column">
                            <Stack spacing={2} direction="row">
                                <Stack>
                                    <Typography variant="subtitle2"> Name <sup style={{ color: 'red' }}>*</sup></Typography>
                                    <TextField
                                        autoComplete="off"
                                        onChange={(e) => {
                                            setRaidName(e.target.value);
                                        }}
                                        autoFocus={true}
                                        style={{ width: '220px' }}
                                        size="small"
                                        placeholder={'Enter RAID name'}
                                        value={raidName}
                                        required
                                    ></TextField>
                                </Stack>

                                <Stack>
                                    <Typography variant="subtitle2"> Type <sup style={{ color: 'red' }}>*</sup></Typography>
                                    <CognisaasSelect
                                        validValues={[
                                            'Risk',
                                            'Assumption',
                                            'Issue',
                                            'Dependency',
                                        ]}
                                        value={raidType}
                                        onChange={(e: any) => {
                                            setRaidType(e.target.value);
                                        }}
                                        style={{ width: '220px' }}
                                    />

                                </Stack>
                            </Stack>
                            <Stack spacing={2} direction="row">
                                <Stack>
                                    <Typography variant="subtitle2">
                                        Phase Name<sup style={{ color: 'red' }}>*</sup>
                                    </Typography>
                                    <CognisaasAutocompleteSelect
                                        width="220px"
                                        value={{ id: selectedPhase.id, name: selectedPhase.name }}
                                        allOptions={phaseOptions}
                                        onChange={(data: any) => {
                                            setSelectedPhase({ id: data.id, name: data.name });
                                        }}
                                    />
                                </Stack>
                                <Stack>
                                    <Typography variant="subtitle2"> Due Date</Typography>
                                    <Stack className='customDatePickerWidth'>
                                        <CognisaasCalenderNew
                                            handleChange={(date: any) => {
                                                setDueDate(date);
                                            }}
                                            value={dueDate}
                                            width="220px"
                                        />
                                    </Stack>
                                </Stack>

                            </Stack>
                            <Stack spacing={2} direction="row">
                                <Stack>
                                    <Typography variant="subtitle2"> Owner </Typography>
                                    <CognisaasSelect
                                        value={taskOwner}
                                        onChange={(e: any) => setTaskOwner(e.target.value)}
                                        validValues={taskOwnerDropdownOptionsWithNotAssigned}
                                        width="220px" xx
                                    ></CognisaasSelect>
                                </Stack>
                                <Stack>
                                    <Typography variant="subtitle2"> Stage</Typography>
                                    <CognisaasSelect
                                        disabled={raidType === 'Assumption'}
                                        onChange={(e: any) =>
                                            setRaidStage(e.target.value)
                                        }
                                        value={raidStage}
                                        validValues={['Mitigated', 'Not Mitigated']}
                                        width="220px"
                                    />
                                </Stack>


                            </Stack>
                            <Stack>
                                <Stack spacing={1} direction="row">
                                    <Typography variant="subtitle2">Share with client</Typography>
                                </Stack>
                                <Stack>
                                    <Switch
                                        checked={shareWithClient}
                                        onChange={() => setShareWithClient(!shareWithClient)}
                                    ></Switch>
                                </Stack>
                            </Stack>

                            <Stack justifyContent="flex-end" direction="row">
                                <Stack mb={2} spacing={1} direction="row">
                                    <CognisaasButton
                                        clickHandler={() => {
                                            resetValues();
                                            onClose();
                                        }}
                                        isOutlined={true}
                                        label="Cancel"
                                    />

                                    {isSavingInProgress ? (
                                        <LoadingButton loading loadingPosition="start" startIcon={<SaveIcon />} variant="outlined">
                                            Save
                                        </LoadingButton>
                                    ) : (
                                        <CognisaasButton
                                            clickHandler={() => {
                                                saveTask({
                                                    raidName,
                                                    raidType,
                                                    dueDate,
                                                    shareWithClient,
                                                    taskOwner,
                                                });
                                            }}
                                            disabled={disableSave}
                                            isOutlined={false}
                                            label="Save"
                                        />
                                    )}
                                </Stack>
                            </Stack>
                        </Stack>
                    </Grid>
                </form>
            </Drawer>
            <CognisaasToast
                open={alertDetails.isVisible}
                onClose={() => setAlertDetails({ isVisible: false, type: alertDetails.type, message: '' })}
                message={alertDetails.message}
                type={alertDetails.type}
            />
        </>
    );
};


export default NewRaidDrawer;