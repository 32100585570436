import { Box, Stack, Button, Badge } from '@mui/material';
// import BasicModal from 'components/shared/CognisaasModal/CognisaasModal';
// import CognisaasSelect from 'components/shared/CognisaasSelect/CognisaasSelect';
import { useEffect, useState } from 'react';
// import CognisaasSelect from 'components/shared/CognisaasSelect/CognisaasSelect';
// import AddConditionModal from './AddConditionModal';
import Checkbox from '@mui/material/Checkbox';
// import CognisaasButton from 'components/shared/CognisaasButton/CognisaasButton';
// import DeleteIcon from '@mui/icons-material/Delete';
import AddAggregationModal from './AddAggregationModal';
import TypographyWithTooltip from 'components/shared/UI/TypographyWithTooltip';

// import { getKeyByDisplayName } from '../UtilityFunction';

const AddFields = (props: any) => {
  // const [allowedOperations, setAllowedOperations] = useState<string[]>([]);
  // const [conditionModal, setConditionModal] = useState<boolean>(false);
  // const [selectedFieldType, setSelectedFieldType] = useState<string>('');
  // const [selectedField, setSelectedField] = useState<string>('');
  // const [validValuesAvailable, setValidValuesAvailable] = useState<any>(null);
  // const [conditionsOnField, setConditionsOnField] = useState<any[]>([]);
  // const [selectedAggregationIndex, setSelectedAggregationIndex] = useState<number | null>(null);
  const [openAddAgreegationModal, setOpenAddAgreegationModal] = useState<boolean>(false);

  useEffect(() => {
    // makeDataForAllowedOperation(props.fieldValue.fieldName);
  }, [props]);

  // const makeDataForAllowedOperation = (value: string) => {
  //   // console.log(value, props, setConditionsOnField);
  //   // const key: string = getKeyByDisplayName(props.dataForChoosingAllowedOperations, value) as string;
  //   // console.log(key);
  //   setAllowedOperations([...props.dataForChoosingAllowedOperations[value].allowedOperationsList]);
  //   setSelectedFieldType(props.dataForChoosingAllowedOperations[value].type);
  //   setValidValuesAvailable(
  //     props.dataForChoosingAllowedOperations[value].validValues
  //       ? props.dataForChoosingAllowedOperations[value].validValues
  //       : null
  //   );
  //   setSelectedField(value);
  //   // console.log(props.dataForChoosingAllowedOperations[value].type, value);
  // };

  return (
    <Box>
      {/* <BasicModal
        height={550}
        width={750}
        open={conditionModal}
        onClose={() => {
          // setAlertModalOpened(true);
          setConditionModal((prevState) => !prevState);
        }}
      >
        <AddConditionModal
          allColumns={props.dataForChoosingAllowedOperations}
          validValues={validValuesAvailable}
          onClose={closeModal}
          field={selectedField}
          type={selectedFieldType}
          conditionsApplied={conditionsOnField}
        />
      </BasicModal> */}

      {/* <Typography variant="subtitle1">Reporttt</Typography> */}

      {/* {props.index == 0 && (
        <Grid container spacing={2}>
          <Grid item xs={1}>
          </Grid>
          <Grid item xs={3}>
            <InputLabel htmlFor="component-simple">
              Field *{' '}
              <Tooltip title="Select a field from the list." placement="right-start">
                <IconButton sx={{ color: '#7a7a7a' }} aria-label="SMTP">
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </InputLabel>

          </Grid>
          <Grid item xs={2}>
            <InputLabel htmlFor="component-simple">
              Column Name*{' '}
              <Tooltip title="Provide a name to be shown in the grid header." placement="right-start">
                <IconButton sx={{ color: '#7a7a7a' }} aria-label="SMTP">
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </InputLabel>

          </Grid>

          {props.currentEntityIndex >= props.topEntityIndex && (
            <>
              <Grid item xs={3}>
                <InputLabel sx={{ fontSize: '100' }} htmlFor="component-simple">
                  Aggregation{' '}
                  <Tooltip title="Select an aggregation to appy on the field." placement="right-start">
                    <IconButton sx={{ color: '#7a7a7a' }} aria-label="SMTP">
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </InputLabel>

              </Grid>
              <Grid item xs={3}>
                <InputLabel htmlFor="component-simple">
                  Conditions
                  <Tooltip title="Add Conditions to be applied on the selected field." placement="right-start">
                    <IconButton sx={{ color: '#7a7a7a' }} aria-label="SMTP">
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </InputLabel>

              </Grid>
            </>
          )}
        </Grid>
      )} */}

      {/* <Paper sx={{ elevation: 0, boxShadow: 1 }}> */}
      {/* <Stack direction={'row'} my={1} spacing={3}> */}
      <Stack flexWrap={'wrap'} direction="row" alignItems={'center'} sx={{ margin: '0px 16px 10px 16px' }}>
        <Checkbox
          sx={{ marginRight: '8px' }}
          size="small"
          checked={props.fieldValue.selected ? true : false}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            props.markFieldSelected(event.target.checked, props.index);
          }}
        />

        <TypographyWithTooltip maxLength={25} variant="subtitle1">
          {props.fieldValue.columnName}
        </TypographyWithTooltip>

        {props.fieldValue?.aggregations.length > 0 &&
          props.fieldValue?.aggregations.filter((item: any) => item.aggregationType !== 'nothing').length > 0 &&
          props.topEntityIndex > 0 && (
            <Badge color={'primary'} badgeContent={'A'} sx={{ margin: '0px 8px 0px 20px' }}></Badge>
          )}

        {props.topEntityIndex > 0 && props.currentEntityIndex > props.topEntityIndex && (
          <Button
            size="small"
            sx={{ marginLeft: '8px' }}
            onClick={() => {
              // if (props.fieldValue?.aggregations.length === 0) {
              //   props.addAggregation(props.index);
              // }
              setOpenAddAgreegationModal(true);
            }}
          >
            Add Aggregation
          </Button>
        )}
      </Stack>
      {/* </Stack> */}
      {/* {props.currentEntityIndex > props.topEntityIndex && (
        <>
          {props.fieldValue?.aggregations.map((field: any, index: number) => {
            console.log(field);
            return (
              <Stack direction="row" spacing={2} ml={4} mb={1} key={index}>
                <TextField
                  key={index}
                  style={{ margin: '0px 6px 6px 6px' }}
                  size="small"
                  placeholder={'Enter Column Name'}
                  required={true}
                  value={field.columnName}
                  onChange={(e: any) => {
                    console.log(e.target.value, field.fieldName, index, props.index);
                    props.columnNameChangeHandler(e.target.value, field.fieldName, props.index, index);
                  }}
                />
                <CognisaasSelect
                  // width="310px"
                  name="field"
                  value={props.currentEntityIndex < props.topEntityIndex ? 'nothing' : field.aggregationType}
                  onChange={(e: any) => props.operationTypeChange(e.target.value, field.fieldName, props.index, index)}
                  validValues={props.currentEntityIndex < props.topEntityIndex ? ['nothing'] : [...allowedOperations]}
                />
                <TextField
                  disabled={props.currentEntityIndex < props.topEntityIndex}
                  className={'cognisaas-pointer'}
                  size="small"
                  placeholder={'Add Condition'}
                  required={true}
                  value={`${field.conditions.length} ${
                    field.conditions.length == 1 ? 'condition' : 'conditions'
                  } applied`}
                  onClick={() => {
                    setSelectedAggregationIndex(index);
                    setConditionsOnField([...field.conditions]);
                    props.currentEntityIndex >= props.topEntityIndex ? setConditionModal(true) : null;
                    makeDataForAllowedOperation(field.fieldName);
                  }}
                />
                <IconButton onClick={() => props.removeField(props.index, index)} aria-label="delete">
                  <DeleteIcon />
                </IconButton>
              </Stack>
            );
          })}
        </>
      )} */}
      <AddAggregationModal
        title={props.fieldValue.columnName}
        // allowedOperations={allowedOperations}
        columnNameChangeHandler={props.columnNameChangeHandler}
        currentEntityIndex={props.currentEntityIndex}
        topEntityIndex={props.topEntityIndex}
        data={props.fieldValue}
        isOpen={openAddAgreegationModal}
        // makeDataForAllowedOperation={makeDataForAllowedOperation}
        operationTypeChange={props.operationTypeChange}
        parentIndex={props.index}
        removeField={props.removeField}
        // setConditionsOnField={setConditionsOnField}
        // setSelectedAggregationIndex={setSelectedAggregationIndex}
        key={Date.now()}
        setIsOpen={setOpenAddAgreegationModal}
        addAggregation={props.addAggregation}
        dataForChoosingAllowedOperations={props.dataForChoosingAllowedOperations}
        // validValuesAvailable={validValuesAvailable}
        // conditionsOnField={conditionsOnField}
        // selectedFieldType={selectedFieldType}
        // selectedField={selectedField}
        conditionsChangeHandler={props.conditionsChangeHandler}
      />
      {/* </Paper> */}
    </Box>
  );
};

export default AddFields;
