import { Stack, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import { ReactNode } from 'react';
import { ResponsiveLine } from '@nivo/line';
import { formatCurrency, formatNumberWithReadableCommas } from 'utils/dataHelpers';
import { animated } from '@react-spring/web';
import theme from 'plugins/materialUI';
import { KEYS_REPRESENTING_REVENUE } from '../constants';
import getSymbolFromCurrency from 'currency-symbol-map';
import { useAppSelector } from 'store/hooks';
import { selectCustomerDetail } from 'store/modules/Common/selector';

interface LineChartProps {
  data: any[];
  gridXValues?: string;
  gridYValues?: string[];
  colors: string[];
  axisTopLegend?: string;
  axisRightLegend?: string;
  axisBottomLegend?: string;
  axisLeftLegend?: string;
  legend: 'left' | 'right' | 'top' | 'bottom' | 'none';
  customLegendComponent?: ReactNode;
  showTrendLine?: boolean;
  indexAxisKey: string;
  otherAxisDataKey: string;
}

const LineChart = (props: LineChartProps) => {
  console.log('datttt', props.data);
  const chartProps = props;
  const shouldTruncateLabelOnBottomAxis = (val: string): boolean =>
    props.data[0].data.length > 4 && val.length > 5 ? true : false;
  const shouldTruncateLabelOnLeftAxis = (val: string): boolean => (val.length > 5 ? true : false);
  const mediumScreensAndSmaller = useMediaQuery(theme.breakpoints.down('md'));
  const customerDetail = useAppSelector(selectCustomerDetail);

  const checkTypeNumAndFormatAccordingly = (str: any) => {
    if (isNaN(parseInt(str)) || chartProps.indexAxisKey === 'Time') {
      return str;
    } else {
      if (KEYS_REPRESENTING_REVENUE.includes(chartProps.otherAxisDataKey)) {
        return formatCurrency(parseInt(str), customerDetail?.currency_code);
      }
      return formatNumberWithReadableCommas(parseInt(str), customerDetail?.currency_code);
    }
  };

  return (
    <Box>
      <Stack
        alignItems={'center'}
        justifyContent={'center'}
        direction={
          props.legend === 'bottom'
            ? 'column'
            : props.legend === 'right'
            ? 'row'
            : props.legend === 'top'
            ? 'column-reverse'
            : props.legend === 'left'
            ? 'row-reverse'
            : 'column'
        }
      >
        <Box
          sx={
            mediumScreensAndSmaller
              ? { height: '350px', width: '490px', marginLeft: '100px' }
              : { height: '350px', width: '690px', marginLeft: '100px' }
          }
        >
          <ResponsiveLine
            data={props.data}
            margin={{ top: 50, right: 60, bottom: 70, left: 100 }}
            xScale={{ type: 'point' }}
            yScale={{
              type: 'linear',
              min: 'auto',
              max: 'auto',
              stacked: true,
              reverse: false,
            }}
            theme={{
              axis: {
                ticks: {
                  line: {
                    stroke: 'green',
                    strokeWidth: 0,
                  },
                  text: {
                    fill: '#7A7A7A',
                    wordWrap: 'break-word',
                    maxWidth: '2',
                  },
                },
                legend: {
                  text: {
                    fontFamily: 'Noto Sans',
                    fontSize: 18,
                    fill: '#000000',
                  },
                },
                domain: {
                  line: {
                    strokeWidth: 1,
                    stroke: '#E5E5E5',
                  },
                },
              },
              fontFamily: 'Noto Sans',
              // background: 'rgba(243, 243, 243,0.3)',

              grid: {
                line: {
                  stroke: '#E5E5E5',
                  strokeWidth: 1,
                  strokeDasharray: '4 4',
                },
              },
            }}
            // yFormat=" >-.2g"
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 0,
              tickRotation: 0,
              legendOffset: 40,
              legendPosition: 'middle',
              legend: KEYS_REPRESENTING_REVENUE.includes(props.otherAxisDataKey)
                ? `${props.axisBottomLegend} (${getSymbolFromCurrency(customerDetail?.currency_code)})`
                : props.axisBottomLegend,
              renderTick: (oProps: any) => {
                const value = checkTypeNumAndFormatAccordingly(oProps.value);
                return (
                  <animated.g transform={oProps.animatedProps.transform} {...oProps}>
                    <line x1={0} x2={oProps.lineX} y1={0} y2={oProps.lineY} />
                    <animated.text
                      dominantBaseline={oProps.textBaseline}
                      textAnchor={oProps.textAnchor}
                      transform={oProps.animatedProps.textTransform}
                      style={{ fontSize: '0.8rem' }}
                    >
                      <title>{value}</title>
                      {isNaN(oProps.value)
                        ? `${shouldTruncateLabelOnBottomAxis(`${value}`) ? `${value}`.slice(0, 6) : value}${
                            shouldTruncateLabelOnBottomAxis(`${value}`) ? '...' : ''
                          }`
                        : value}
                    </animated.text>
                  </animated.g>
                );
              },
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: KEYS_REPRESENTING_REVENUE.includes(props.otherAxisDataKey)
                ? `${props.axisLeftLegend} (${getSymbolFromCurrency(customerDetail?.currency_code)})`
                : props.axisLeftLegend,
              legendOffset: -80,
              legendPosition: 'middle',
              format: (e) => Math.floor(e) === e && e,
              renderTick: (oProps: any) => {
                const value = checkTypeNumAndFormatAccordingly(oProps.value);
                return (
                  <animated.g transform={oProps.animatedProps.transform} {...oProps}>
                    <line x1={0} x2={oProps.lineX} y1={0} y2={oProps.lineY} />
                    <animated.text
                      dominantBaseline={oProps.textBaseline}
                      textAnchor={oProps.textAnchor}
                      transform={oProps.animatedProps.textTransform}
                      style={{ fontSize: '0.7rem' }}
                    >
                      <title>{value}</title>
                      {isNaN(oProps.value)
                        ? `${shouldTruncateLabelOnLeftAxis(`${value}`) ? `${value}`.slice(0, 6) : value}
                          ${shouldTruncateLabelOnLeftAxis(`${value}`) ? '...' : ''}`
                        : value}
                    </animated.text>
                  </animated.g>
                );
              },
            }}
            // colors={{ scheme: 'nivo' }}

            colors={props.colors}
            pointSize={10}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabelYOffset={-12}
            useMesh={true}
            // legends={[
            //   {
            //     anchor: 'bottom-right',
            //     direction: 'column',
            //     justify: false,
            //     translateX: 100,
            //     translateY: 0,
            //     itemsSpacing: 0,
            //     itemDirection: 'left-to-right',
            //     itemWidth: 80,
            //     itemHeight: 20,
            //     itemOpacity: 0.75,
            //     symbolSize: 12,
            //     symbolShape: 'circle',
            //     symbolBorderColor: 'rgba(0, 0, 0, .5)',
            //     effects: [
            //       {
            //         on: 'hover',
            //         style: {
            //           itemBackground: 'rgba(0, 0, 0, .03)',
            //           itemOpacity: 1,
            //         },
            //       },
            //     ],
            //   },
            // ]}
          />
        </Box>
      </Stack>
    </Box>
  );
};

export default LineChart;
