import { Stack, Typography, Grid } from '@mui/material';
import { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { getCustomerDetails } from 'store/modules/Settings/slice';
import { selectCustomerDetails } from 'store/modules/Settings/selector';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../store/hooks';
import { getAllUsers } from 'store/modules/Common/slice';
import { selectUsersOfCompany } from 'store/modules/Common/selector';
import {
  getVerifiedClientContacts,
  getClientDetail,
  updatedcasewithfeaturestatusClient,
  getProjectInformationWithTaskClientReport,
  sendReportToAllClientContacts,
  sendDynamicReportToClientContact,
} from 'store/modules/ManageClient/ClientList/slice';
import {
  selectVerifiedClientContacts,
  selectClientDetail,
  selectProjectInformationWithTaskClientReport,
  selectsendReportToAllClientContacts,
  selectsendDynamicReportToAllClientContacts,
} from 'store/modules/ManageClient/ClientList/selector';
import { format } from 'date-fns';
import {
  getUserNameByUserUid,
  getContactNameByContactUid,
  getContactUidByContactEmail,
  getCustomerSubDomain,
} from '../../../utils/getUid';
import BasicModal from 'components/shared/CognisaasModal/CognisaasModal';
import ClientStatusReportMailSelection from './ClientStatusReportMailSelection';
import Cookies from 'universal-cookie';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/system';
import {
  label_delivery_date,
  label_due_date,
  label_project,
  label_task,
  label_use_case,
} from 'components/Settings/Preferences/CustomLabelCookieHelper';
import CognisaasToast from 'components/shared/CognisaasToast/CognisaasToast';

import './ClientStatusReport.css';
import { dateRangeFilteringProject, groupByPhase, normalFilteringProject, summaryOfTheReport } from './helperFunction';
import CognisaasButton from 'components/shared/CognisaasButton/CognisaasButton';
import { isNull } from 'utils/dataHelpers';
import NAMED_CONSTATNTS from 'components/shared/CustomNames/Constants';
import useCustomNames from 'components/shared/CustomNames/useCustomNames';
const cookies = new Cookies();

const ClientStatusReport = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const location: any = useLocation();
  const [projectFilter, setProjectFilters] = useState([{}]);
  const [minDate, setminDate] = useState('');
  const [maxDate, setmaxDate] = useState('');
  const [projectTotalCount, setprojectTotalCount] = useState<number>(0);
  const [taskTotalCount, settaskTotalCount] = useState<number>(0);
  const [doneTotalTaskCount, setdoneTotalTaskCount] = useState<number>(0);
  const [pendingTotalTaskCount, setpendingTotalTaskCount] = useState<number>(0);
  const [redTotalTaskCount, setredTotalTaskCount] = useState<number>(0);
  const [usecaseTotalCount, setusecaseTotalCount] = useState<number>(0);
  const today = format(new Date(), 'yyyy-MM-dd');
  const elementRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const customerDetails = useAppSelector(selectCustomerDetails);
  const allUsers = useAppSelector(selectUsersOfCompany);
  const verifiedClientContacts = useAppSelector(selectVerifiedClientContacts);
  const [projectInformationWithTasks, setprojectInformationWithTasks] = useState<any>([]);
  const [clientInformation, setclientInformation] = useState<any>([]);
  const [statusReportClient, setstatusReportClient] = useState<boolean>(false);
  const [openMailListModal, setopenMailListModal] = useState<boolean>(false);
  const clientInfo = useAppSelector(selectClientDetail);
  const projectInformationWithTaskClientReport: [] = useAppSelector(selectProjectInformationWithTaskClientReport);
  const reportSentStatus: any = useAppSelector(selectsendReportToAllClientContacts);
  const reportDynamicSentStatus: any = useAppSelector(selectsendDynamicReportToAllClientContacts);
  const [mailList, setmailList] = useState<any>([]);
  const [ccMailList, setccMailList] = useState<any>([]);
  const [subjectText, setsubjectText] = useState<any>(
    `${cookies.get('customerCompanyName').charAt(0).toUpperCase() + cookies.get('customerCompanyName').slice(1)
    } Weekly Status Report on ${format(new Date(), 'dd-MMM-yyyy')}`
  );
  const reply_to = cookies.get('cogni_user_email') as string;
  let clientMail = '';
  let userMail = '';
  const [alertDetails, setAlertDetails] = useState<{
    isVisible: boolean;
    type: 'success' | 'failure';
    message: string;
  }>({ isVisible: false, type: 'success', message: '' });
  const { getCustomNameMappedValue } = useCustomNames();
  useEffect(() => {
    filteringProjectAndDateSet();
  }, [location]);

  useEffect(() => {
    dispatch(getCustomerDetails());
    dispatch(getAllUsers());
  }, []);
  useEffect(() => {
    if (customerDetails?.data) {
      if (customerDetails?.data?.status_report_client == '1' || customerDetails?.data?.status_report_client == 1) {
        setstatusReportClient(true);
      }
    }
  }, [customerDetails?.data]);

  const clientUid = params.clientId as string;
  useEffect(() => {
    dispatch(getVerifiedClientContacts(clientUid));
    dispatch(getClientDetail(clientUid));
    dispatch(updatedcasewithfeaturestatusClient(clientUid));
    dispatch(getProjectInformationWithTaskClientReport(clientUid));
  }, [clientUid]);

  useEffect(() => {
    setclientInformation(clientInfo);
  }, [clientInfo]);

  const filteringProjectAndDateSet = () => {
    if (location?.state?.filterInformations) {
      const { report_selected_projects, report_mindate_projects, report_maxdate_projects } =
        location.state.filterInformations;
      if (report_selected_projects) {
        const testJSON: any = [];
        report_selected_projects?.forEach((item: any) => {
          const element: any = {};
          element['project_uid'] = item;
          testJSON.push(element);
        });
        setProjectFilters(testJSON);
      }
      if (report_mindate_projects) {
        const res1 = report_mindate_projects as string;
        const minimumDate = new Date(res1);
        setminDate(format(minimumDate, 'yyyy-MM-dd'));
      }
      if (report_maxdate_projects) {
        const res2 = report_maxdate_projects as string;
        const nextDay = new Date(res2);
        setmaxDate(format(nextDay.setDate(nextDay.getDate() + 1), 'yyyy-MM-dd'));
      }
    } else if (location?.state?.filterStatus && location?.state?.reportType === 'project-report') {
      const { report_selected_projects } = location.state.filterStatus;
      if (report_selected_projects) {
        const newProjectFilters: any = [];
        report_selected_projects?.forEach((item: any) => {
          const element: any = {};
          element['project_uid'] = item;
          newProjectFilters.push(element);
        });

        setProjectFilters(newProjectFilters);
      }
    }
  };

  useEffect(() => {
    if (projectInformationWithTaskClientReport?.length != 0) {
      filterProjectInfo();
    }
  }, [projectInformationWithTaskClientReport, statusReportClient, projectFilter]);

  const filterProjectInfo = () => {
    if (
      projectInformationWithTaskClientReport?.length != 0 &&
      (customerDetails?.data?.status_report_client == '1' || customerDetails?.data?.status_report_client == 1) &&
      location?.state?.filterInformations
    ) {
      const arr = location?.state?.filterInformations?.report_task_status;
      const statuses = arr.map((element: string) => {
        return element.toLowerCase();
      });
      const projectInfo: any = projectInformationWithTaskClientReport;
      let finalarray: any = [];
      let filteredArray1: any = [];
      projectFilter.forEach((item: any) => {
        const rand_array = projectInfo.filter((item1: any) => {
          return item1.project_uid == item.project_uid;
        });
        filteredArray1 = filteredArray1.concat(rand_array);
      });
      finalarray = filteredArray1;
      const finalFilteredProject = dateRangeFilteringProject(finalarray, today, maxDate, minDate, statuses);
      const { projectCount, taskCount, doneTaskCount, pendingTaskCount, redTaskCount, usecaseCount } =
        summaryOfTheReport(finalFilteredProject);
      setprojectTotalCount(projectCount);
      settaskTotalCount(taskCount);
      setdoneTotalTaskCount(doneTaskCount);
      setpendingTotalTaskCount(pendingTaskCount);
      setredTotalTaskCount(redTaskCount);
      setusecaseTotalCount(usecaseCount);
      const items = groupByPhase(finalFilteredProject);
      setprojectInformationWithTasks(items);
    } else {
      const arr = location?.state?.filterStatus?.report_task_status;
      const statuses = arr?.map((element: string) => {
        return element.toLowerCase();
      });
      const projectInfo: any = projectInformationWithTaskClientReport;
      // filter the current project if project report
      let finalarray: any = projectInfo;
      if (location?.state?.reportType === 'project-report') {
        let filteredArray1: any = [];
        projectFilter.forEach((item: any) => {
          const rand_array = projectInfo.filter((item1: any) => {
            return item1.project_uid == item.project_uid;
          });
          filteredArray1 = filteredArray1.concat(rand_array);
        });
        finalarray = filteredArray1;
      }
      const filteredArray1 = normalFilteringProject(finalarray, statuses);
      const { projectCount, taskCount, doneTaskCount, pendingTaskCount, redTaskCount, usecaseCount } =
        summaryOfTheReport(filteredArray1);
      setprojectTotalCount(projectCount);
      settaskTotalCount(taskCount);
      setdoneTotalTaskCount(doneTaskCount);
      setpendingTotalTaskCount(pendingTaskCount);
      setredTotalTaskCount(redTaskCount);
      setusecaseTotalCount(usecaseCount);
      const items = groupByPhase(filteredArray1);
      setprojectInformationWithTasks(items);
    }
  };

  const sendMail = async (emailsText: any, bccEmailText: any, type: 'Dynamic' | 'Stactic', attachments: any) => {
    const reportOfProjectUids: string[] = projectInformationWithTasks.map((item: any) => item.project_uid);
    const inputElement: any = (document.getElementById('top') as HTMLInputElement).children[1].children[0];
    const report = (document.getElementById('top') as HTMLInputElement).children[1];
    const parentReport: any = document.getElementById('top') as HTMLInputElement;
    parentReport.removeChild(report);
    parentReport.textContent = inputElement.value;
    for (let i = 0; i < document.getElementsByClassName('textinputProject').length; i++) {
      const id = 'projecttext' + i;
      const textElement: any = (document.getElementById(id) as HTMLInputElement).children[0];
      const val = textElement.value.replace(/\n\r?/g, '<br />');
      const el = 'projecttext' + i;
      const elem: any = document.getElementById(el);
      const parent: any = elem.parentNode;
      parent.removeChild(elem);
      const node = document.createElement('span');
      node.style.fontSize = '14px';
      node.style.fontWeight = '400';
      node.style.lineHeight = '20px';
      if (val == '') {
        node.innerHTML = 'No Comments';
      } else {
        node.innerHTML = val;
      }
      parent.appendChild(node);
    }

    for (let i = 0; i < document.getElementsByClassName('textinputProject').length; i++) {
      const usecaseTextBox = 'textinputUsecase' + i;
      let j = 0;
      for (j = 0; j < document.getElementsByClassName(usecaseTextBox).length; j++) {
        const id = 'usecasetext' + j;
        const elementuse: any = (document.getElementById(id) as HTMLInputElement).children[0];
        const val = elementuse.value.replace(/\n\r?/g, '<br />');
        const elem: any = document.getElementById(id);

        const parent: any = elem.parentNode;
        parent.removeChild(elem);
        const node: any = document.createElement('span');
        node.style.fontSize = '14px';
        node.style.fontWeight = '400';
        node.style.lineHeight = '20px';
        if (val == '') {
          node.innerHTML = 'No Comments';
        } else {
          node.innerHTML = val;
        }
        parent.appendChild(node);
      }
    }

    const mailmessage = elementRef.current.innerHTML;
    const message = `<!DOCTYPE html>
        <html lang="en" xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml"
            xmlns:o="urn:schemas-microsoft-com:office:office">
        <head>
            <!-- <link href="https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;1,100;1,200&display=swap" rel="stylesheet"> -->
            <link href="https://fonts.googleapis.com/css?family=Work+Sans:200,300,400,500,600,700" rel="stylesheet">
            <script type="text/javascript" src="https://code.jquery.com/jquery-2.1.1.min.js"></script>
            <script src="https://cdnjs.cloudflare.com/ajax/libs/materialize/0.97.3/js/materialize.min.js"> </script>
        </head>
        <style>
        body {
          background-color: '#f5f5f5';
        }
        </style>
        <body>
        ${mailmessage}
        </body>
        </html>`;

    mailList.forEach((element: any) => {
      clientMail += element + ', ';
    });

    ccMailList.forEach((element: any) => {
      userMail += element + ', ';
    });
    if (clientMail != '') clientMail = clientMail.slice(0, -2);
    if (userMail != '') {
      userMail = userMail.slice(0, -2);
      if (emailsText != '') {
        userMail += ',' + emailsText;
      }
      userMail += ',' + reply_to;
    } else {
      if (emailsText != '') {
        userMail += emailsText;
        userMail += ',' + reply_to;
      } else {
        userMail += reply_to;
      }
    }
    if (type === 'Dynamic') {
      const contact_uid = getContactUidByContactEmail(mailList[0]);
      const base_com_url = window.location.host + '/customer-portal/' + getCustomerSubDomain() + '/';
      const totalData = {
        message: message,
        customer_Name: customerDetails?.data?.customer_company,
        customer_uid: customerDetails?.data?.customer_uid,
        recepient: clientMail,
        reply_to: reply_to,
        Subject: subjectText,
        client_uid: clientUid,
        contact_uid: contact_uid,
        base_url: base_com_url,
        attachments: attachments,
        reportOfProjectUids: reportOfProjectUids,
      };
      dispatch(sendDynamicReportToClientContact(totalData));
    } else {
      const totalData = {
        message: message,
        customer_Name: customerDetails?.data?.customer_company,
        recepient: clientMail,
        reply_to: reply_to,
        ccList: userMail,
        bccMailList: bccEmailText,
        Subject: subjectText,
        client_uid: clientUid,
        attachments: attachments,
        reportOfProjectUids: reportOfProjectUids,
      };
      console.log(cookies.get('cogni_user_email') as string);
      dispatch(sendReportToAllClientContacts(totalData));
    }
    setAlertDetails({
      isVisible: true,
      type: 'success',
      message: 'Please wait the email is being sent.',
    });
  };
  useEffect(() => {
    if (reportSentStatus?.status == 1) {
      setAlertDetails({
        isVisible: true,
        type: 'success',
        message: 'Report sent to contacts.',
      });
      setTimeout(() => {
        afterClosingModal();
        window.location.reload();
      }, 1000);
    } else if (reportSentStatus?.status == 0) {
      setAlertDetails({
        isVisible: true,
        type: 'failure',
        message: 'Please try again later.',
      });
      setTimeout(() => {
        afterClosingModal();
        window.location.reload();
      }, 1000);
    }
  }, [reportSentStatus]);
  useEffect(() => {
    if (reportDynamicSentStatus?.status == 1) {
      setAlertDetails({
        isVisible: true,
        type: 'success',
        message: 'Report sent to contacts.',
      });
      setTimeout(() => {
        afterClosingModal();
        window.location.reload();
      }, 1000);
    } else if (reportDynamicSentStatus?.status == 0) {
      setAlertDetails({
        isVisible: true,
        type: 'failure',
        message: 'Please try again later.',
      });
      setTimeout(() => {
        afterClosingModal();
        window.location.reload();
      }, 1000);
    }
  }, [reportDynamicSentStatus]);

  const afterClosingModal = () => {
    setopenMailListModal(false);
    setmailList([]);
    setccMailList([]);
  };
  // useEffect(() => {
  //   if (verifiedClientContacts?.length == 0) {
  //     setnoRecord(true);
  //   }
  // }, [verifiedClientContacts]);

  return (
    <>
      <CognisaasToast
        message={alertDetails.message}
        type={alertDetails.type}
        open={alertDetails.isVisible}
        onClose={() => {
          setAlertDetails({ isVisible: false, type: alertDetails.type, message: '' });
        }}
      />
      <Box sx={{ paddingLeft: '8px' }}>
        <Stack direction="row" spacing={1} mt={2} mb={3} justifyContent="space-between" alignItems="center">
          <Stack direction="row" spacing={1}>
            <Typography variant="h2">Generate Report</Typography>
          </Stack>
          <Stack direction={'row'}>
            <CognisaasButton
              disabled={projectInformationWithTasks.length != 0 ? false : true}
              label={'Send Email'}
              isOutlined={false}
              clickHandler={() => setopenMailListModal(true)}
            />
          </Stack>
        </Stack>
        <Grid container id="mycomponent">
          <div ref={elementRef} style={{ fontFamily: 'Noto Sans' }}>
            <div className="row" id="top">
              <div style={{ width: '93vw', fontWeight: '500' }}>
                <h4 style={{ margin: '0px 0px 5px 0px' }}>Report Name</h4>
              </div>
              <div id="input">
                <input
                  style={{
                    width: '600px',
                    background: '#FFFFFF',
                    fontSize: '15px',
                    border: '1px solid',
                    height: '38px',
                  }}
                  value={subjectText}
                  onChange={(e) => setsubjectText(e.target.value)}
                />
              </div>
            </div>
            {projectInformationWithTasks?.length > 0 && (
              <>
                <div
                  style={{
                    borderTopLeftRadius: '4px',
                    borderTopRightRadius: '4px',
                    fontWeight: '700',
                    fontSize: '14px',
                    paddingTop: '1rem',
                    background: '#FCFCFD',
                    marginTop: '8px',
                  }}
                >
                  <div className="row">
                    <span
                      style={{
                        fontSize: '14px',
                        fontWeight: 'bold',
                        lineHeight: '20px',
                        color: '#546E7A',
                        paddingLeft: '1rem',
                      }}
                    >
                      Summary :
                    </span>
                  </div>
                  <div
                    style={{
                      borderTopLeftRadius: '4px',
                      borderTopRightRadius: '4px',
                      padding: '0.5rem',
                      // background: '#E1E1E5',
                      display: 'flex',
                    }}
                  >
                    <div
                      style={{
                        width: '140px',
                        fontWeight: 'bold',
                        fontSize: '18px',
                        color: '#000000',
                        textAlign: 'center',
                      }}
                    >
                      <span>Total {label_project}s</span>
                    </div>
                    <div
                      style={{
                        width: '140px',
                        fontWeight: 'bold',
                        fontSize: '18px',
                        color: '#000000',
                        textAlign: 'center',
                      }}
                    >
                      <span>Total {label_task}s</span>
                    </div>
                    <div
                      style={{
                        width: '140px',
                        fontWeight: 'bold',
                        fontSize: '18px',
                        color: '#000000',
                        textAlign: 'center',
                      }}
                    >
                      <span>{label_task}s Done</span>
                    </div>
                    <div
                      style={{
                        width: '140px',
                        fontWeight: 'bold',
                        fontSize: '18px',
                        color: '#000000',
                        textAlign: 'center',
                      }}
                    >
                      <span>{label_task}s Pending</span>
                    </div>
                    <div
                      style={{
                        width: '140px',
                        fontWeight: 'bold',
                        fontSize: '18px',
                        color: '#000000',
                        textAlign: 'center',
                      }}
                    >
                      <span>{label_task}s in Red</span>
                    </div>
                    {usecaseTotalCount != 0 ? (
                      <div
                        style={{
                          width: '140px',
                          fontWeight: 'bold',
                          fontSize: '18px',
                          color: '#000000',
                          textAlign: 'center',
                        }}
                      >
                        <span>Total {label_use_case}s</span>
                      </div>
                    ) : null}
                  </div>
                  <div
                    style={{
                      // padding: '1rem',
                      // background: '#FCFCFD',
                      width: '93vw',
                      display: 'flex',
                      paddingBottom: '0.5rem',
                    }}
                  >
                    <div
                      style={{
                        width: '140px',
                        fontWeight: 'bold',
                        fontSize: '26px',
                        color: '#546E7A',
                        textAlign: 'center',
                      }}
                    >
                      {projectTotalCount}
                    </div>
                    <div
                      style={{
                        width: '140px',
                        fontWeight: 'bold',
                        fontSize: '26px',
                        color: '#546E7A',
                        textAlign: 'center',
                      }}
                    >
                      <span>{taskTotalCount}</span>
                    </div>
                    <div
                      style={{
                        width: '140px',
                        fontWeight: 'bold',
                        fontSize: '26px',
                        color: '#4390E1',
                        textAlign: 'center',
                      }}
                    >
                      <span>{doneTotalTaskCount}</span>
                    </div>
                    <div
                      style={{
                        width: '140px',
                        fontWeight: 'bold',
                        fontSize: '26px',
                        color: '#4F9F52',
                        textAlign: 'center',
                      }}
                    >
                      <span>{pendingTotalTaskCount}</span>
                    </div>
                    <div
                      style={{
                        width: '140px',
                        fontWeight: 'bold',
                        fontSize: '26px',
                        color: '#E75B5C',
                        textAlign: 'center',
                      }}
                    >
                      <span>{redTotalTaskCount}</span>
                    </div>
                    {usecaseTotalCount != 0 ? (
                      <div
                        style={{
                          width: '140px',
                          fontWeight: 'bold',
                          fontSize: '26px',
                          color: '#546E7A',
                          textAlign: 'center',
                        }}
                      >
                        <span>{usecaseTotalCount}</span>
                      </div>
                    ) : null}
                  </div>
                </div>
              </>
            )}
            <div id="second">
              {projectInformationWithTasks?.length > 0 ? (
                projectInformationWithTasks?.map((element: any, index: number) => {
                  return (
                    <div key={element.project_uid} style={{ marginBottom: '20px', marginTop: '10px' }}>
                      <div
                        id={`button${index}`}
                        style={{
                          borderTopLeftRadius: '4px',
                          borderTopRightRadius: '4px',
                          fontWeight: '700',
                          fontSize: '14px',
                          padding: '1rem',
                          background: '#FCFCFD',
                          width: '93vw',
                        }}
                      >
                        <div className="row">
                          <span style={{ fontSize: '14px', fontWeight: '400', lineHeight: '20px', color: '#546E7A' }}>
                            {label_project} {index + 1} :
                          </span>
                        </div>
                        <div className="row" style={{ lineHeight: '30px' }}>
                          <span style={{ fontWeight: '400', fontSize: '14px', font: 'Noto Sans' }}>
                            {element.project_name}
                          </span>
                          &nbsp; &nbsp; &nbsp; &nbsp;
                          <span>
                            {element.project_readinessstate_status == 'red' ? (
                              <>
                                <span
                                  style={{
                                    backgroundColor: '#E75B5C',
                                    display: 'inline-block',
                                    width: '8px',
                                    height: '8px',
                                    borderRadius: '60%',
                                    marginRight: '8px',
                                  }}
                                ></span>
                                <span style={{ fontWeight: '400', fontSize: '14px' }}>Overdue</span>
                              </>
                            ) : element.project_readinessstate_status == 'green' ? (
                              <>
                                <span
                                  style={{
                                    backgroundColor: '#4F9F52',
                                    display: 'inline-block',
                                    width: '8px',
                                    height: '8px',
                                    borderRadius: '60%',
                                    marginRight: '8px',
                                  }}
                                ></span>
                                <span style={{ fontWeight: '400', fontSize: '14px' }}>On Track</span>
                              </>
                            ) : element.project_readinessstate_status == 'amber' ? (
                              <>
                                <span
                                  style={{
                                    backgroundColor: '#EFA958',
                                    display: 'inline-block',
                                    width: '8px',
                                    height: '8px',
                                    borderRadius: '60%',
                                    marginRight: '8px',
                                  }}
                                ></span>
                                <span style={{ fontWeight: '400', fontSize: '14px' }}>At Risk</span>
                              </>
                            ) : element.project_readinessstate_status == 'blue' ? (
                              <>
                                <span
                                  style={{
                                    backgroundColor: '#4390E1',
                                    display: 'inline-block',
                                    width: '8px',
                                    height: '8px',
                                    borderRadius: '60%',
                                    marginRight: '8px',
                                  }}
                                ></span>
                                <span style={{ fontWeight: '400', fontSize: '14px' }}>Completed</span>
                              </>
                            ) : (
                              ''
                            )}
                          </span>
                        </div>
                        <div className="row" style={{ display: 'flex', lineHeight: '30px' }}>
                          <div style={{ width: '400px' }}>
                            <span style={{ fontSize: '14px', fontWeight: '400', lineHeight: '20px', color: '#546E7A' }}>
                              Delivery Status Summary
                            </span>
                          </div>
                          <div style={{ width: '150px' }}>
                            <span style={{ fontSize: '14px', fontWeight: '400', lineHeight: '20px', color: '#546E7A' }}>
                              Start Date
                            </span>
                          </div>
                          <div style={{ width: '150px' }}>
                            <span style={{ fontSize: '14px', fontWeight: '400', lineHeight: '20px', color: '#546E7A' }}>
                              {label_due_date}
                            </span>
                          </div>
                          <div>
                            <span style={{ fontSize: '14px', fontWeight: '400', lineHeight: '20px', color: '#546E7A' }}>
                              Expected {label_delivery_date}
                            </span>
                          </div>
                        </div>
                        <div className="row" style={{ display: 'flex', lineHeight: '30px' }}>
                          <div style={{ width: '400px' }}>
                            <span style={{ fontSize: '14px', fontWeight: '400', lineHeight: '20px' }}>
                              {element.project_readinessstate_status == 'red' ? (
                                <>
                                  <span>
                                    One or more {label_task}s for this {label_project} are overdue
                                  </span>
                                </>
                              ) : element.project_readinessstate_status == 'green' ? (
                                <>
                                  <span>Delivery of this {label_project} is on track</span>
                                </>
                              ) : element.project_readinessstate_status == 'amber' ? (
                                <>
                                  <span>Completion of one or more {label_task}s by the due date is at risk</span>
                                </>
                              ) : element.project_readinessstate_status == 'blue' ? (
                                <>
                                  <span>Delivery of this {label_project} is completed</span>
                                </>
                              ) : (
                                ''
                              )}
                            </span>
                          </div>
                          <div style={{ width: '150px' }}>
                            <span style={{ fontSize: '14px', fontWeight: '400', lineHeight: '20px' }}>
                              {element.project_startdate !== '0000-00-00 00:00:00' &&
                                !isNull(element.project_startdate) ? (
                                format(new Date(element.project_startdate.replace(/-/g, '/')), 'dd-MMM-yyyy')
                              ) : (
                                <>--</>
                              )}
                            </span>
                          </div>
                          <div style={{ width: '150px' }}>
                            <span style={{ fontSize: '14px', fontWeight: '400', lineHeight: '20px' }}>
                              {element.project_requiredbydate !== '0000-00-00 00:00:00' &&
                                !isNull(element.project_requiredbydate) ? (
                                format(new Date(element.project_requiredbydate.replace(/-/g, '/')), 'dd-MMM-yyyy')
                              ) : (
                                <>--</>
                              )}
                            </span>
                          </div>
                          <div>
                            <span style={{ fontSize: '14px', fontWeight: '400', lineHeight: '20px' }}>
                              {element.eta_forprojectdelivery !== '0000-00-00 00:00:00' &&
                                !isNull(element.eta_forprojectdelivery) ? (
                                format(new Date(element.eta_forprojectdelivery.replace(/-/g, '/')), 'dd-MMM-yyyy')
                              ) : (
                                <>--</>
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="row" style={{ display: 'flex', lineHeight: '30px', paddingBottom: '10px' }}>
                          <span
                            className="textinputProject"
                            style={{
                              whiteSpace: 'pre',
                              fontSize: '14px',
                              fontWeight: '400',
                              lineHeight: '20px',
                              color: '#546E7A',
                            }}
                          >
                            Comments:
                          </span>
                        </div>
                        <div id={`projecttext${index}`} className="row" style={{ display: 'flex', lineHeight: '30px' }}>
                          <textarea id={`protext${index}`} style={{ width: '600px' }}></textarea>
                        </div>
                      </div>

                      <div
                        style={{
                          borderTopLeftRadius: '4px',
                          borderTopRightRadius: '4px',
                          padding: '1rem',
                          background: '#E1E1E5',
                          width: '93vw',
                          display: 'flex',
                        }}
                      >
                        <div style={{ width: '405px', fontWeight: '400', fontSize: '14px', color: '#000000' }}>
                          <span>{label_task} Name</span>
                        </div>
                        <div style={{ width: '160px', fontWeight: '400', fontSize: '14px', color: '#000000' }}>
                          <span>Priority</span>
                        </div>
                        <div style={{ width: '160px', fontWeight: '400', fontSize: '14px', color: '#000000' }}>
                          <span>Status</span>
                        </div>
                        <div style={{ width: '180px', fontWeight: '400', fontSize: '14px', color: '#000000' }}>
                          <span>Owner</span>
                        </div>
                        <div style={{ width: '160px', fontWeight: '400', fontSize: '14px', color: '#000000' }}>
                          <span>Stage</span>
                        </div>
                        <div style={{ width: '160px', fontWeight: '400', fontSize: '14px', color: '#000000' }}>
                          <span>{label_due_date}</span>
                        </div>
                        <div style={{ width: '160px', fontWeight: '400', fontSize: '14px', color: '#000000' }}>
                          <span>{label_delivery_date}</span>
                        </div>
                      </div>
                      {Object.keys(element.clientTask).length > 0 && (
                        <p
                          style={{
                            borderTopLeftRadius: '4px',
                            borderTopRightRadius: '4px',
                            padding: '16px 16px 0px 16px',
                            background: '#FCFCFD',
                            width: '93vw',
                            margin: '0px 0px 0px 0px',
                            fontWeight: '500',
                            border: '1px solid #E5E5E5',
                          }}
                        >
                          <p style={{ fontWeight: 500, fontSize: '20px', margin: '0px 16px 0px 0px' }}>
                            Dependencies from {clientInformation.client_name}
                          </p>
                          <div>
                            {element?.clientTask ? (
                              <div>
                                {Object.keys(element.clientTask).map((item: any, index: number) => {
                                  return (
                                    <>
                                      <p
                                        style={{ paddingTop: '5px', marginBottom: '3px', marginTop: '8px' }}
                                        className="label-text"
                                        key={index}
                                      >
                                        {item}
                                      </p>
                                      {element.clientTask[item].map((task: any) => {
                                        return (
                                          <div
                                            key={task.task_uid}
                                            style={{
                                              borderTopLeftRadius: '4px',
                                              borderTopRightRadius: '4px',
                                              padding: '1rem',
                                              background: '#FCFCFD',
                                              width: '93vw',
                                              margin: '0px 0px 0px -16px',
                                              border: '1px solid #E5E5E5',
                                              display: 'flex',
                                            }}
                                          >
                                            <div
                                              style={{
                                                width: '405px',
                                                fontWeight: '400',
                                                fontSize: '14px',
                                                color: '#000000',
                                              }}
                                            >
                                              {task.milestone_task == 1 ? (
                                                <>
                                                  <div style={{ display: 'flex' }}>
                                                    <div style={{ width: '10%' }}>
                                                      <span
                                                        style={{
                                                          display: 'block',
                                                          fontSize: '0px',
                                                          lineHeight: '0%',
                                                          width: '0px',
                                                          borderBottom: '8px solid #546E7A',
                                                          borderLeft: '8px solid transparent',
                                                          borderRight: '8px solid transparent',
                                                        }}
                                                      ></span>
                                                      <b
                                                        style={{
                                                          display: 'block',
                                                          fontSize: '0px',
                                                          lineHeight: '0%',
                                                          width: '0px',
                                                          borderTop: '8px solid #546E7A',
                                                          borderLeft: '8px solid transparent',
                                                          borderRight: '8px solid transparent',
                                                        }}
                                                      ></b>
                                                    </div>
                                                    <a
                                                      href={'{{user_token}}/taskUid/' + task.task_uid}
                                                      className="disabled"
                                                    >
                                                      {task.task_name}
                                                    </a>
                                                  </div>
                                                </>
                                              ) : (
                                                <>
                                                  <div style={{ display: 'flex' }}>
                                                    <div style={{ width: '10%' }}></div>
                                                    <a
                                                      href={'{{user_token}}/taskUid/' + task.task_uid}
                                                      className="disabled"
                                                    >
                                                      {task.task_name}
                                                    </a>
                                                  </div>
                                                </>
                                              )}
                                            </div>
                                            <div
                                              style={{
                                                width: '160px',
                                                fontWeight: '400',
                                                fontSize: '14px',
                                                color: '#000000',
                                              }}
                                            >
                                              <span>
                                                {getCustomNameMappedValue(
                                                  task.task_priority,
                                                  NAMED_CONSTATNTS.task_priority_key
                                                )}
                                              </span>
                                            </div>
                                            <div
                                              style={{
                                                width: '160px',
                                                fontWeight: '400',
                                                fontSize: '14px',
                                                color: '#000000',
                                              }}
                                            >
                                              <span>
                                                {task.task_status == 'red' ? (
                                                  <>
                                                    <span
                                                      style={{
                                                        backgroundColor: '#E75B5C',
                                                        display: 'inline-block',
                                                        width: '8px',
                                                        height: '8px',
                                                        borderRadius: '60%',
                                                        marginRight: '8px',
                                                      }}
                                                    ></span>
                                                    <span style={{ fontWeight: '400', fontSize: '14px' }}>Overdue</span>
                                                  </>
                                                ) : task.task_status == 'green' ? (
                                                  <>
                                                    <span
                                                      style={{
                                                        backgroundColor: '#4F9F52',
                                                        display: 'inline-block',
                                                        width: '8px',
                                                        height: '8px',
                                                        borderRadius: '60%',
                                                        marginRight: '8px',
                                                      }}
                                                    ></span>
                                                    <span style={{ fontWeight: '400', fontSize: '14px' }}>
                                                      On Track
                                                    </span>
                                                  </>
                                                ) : task.task_status == 'amber' ? (
                                                  <>
                                                    <span
                                                      style={{
                                                        backgroundColor: '#EFA958',
                                                        display: 'inline-block',
                                                        width: '8px',
                                                        height: '8px',
                                                        borderRadius: '60%',
                                                        marginRight: '8px',
                                                      }}
                                                    ></span>
                                                    <span style={{ fontWeight: '400', fontSize: '14px' }}>At Risk</span>
                                                  </>
                                                ) : task.task_status == 'blue' ? (
                                                  <>
                                                    <span
                                                      style={{
                                                        backgroundColor: '#4390E1',
                                                        display: 'inline-block',
                                                        width: '8px',
                                                        height: '8px',
                                                        borderRadius: '60%',
                                                        marginRight: '8px',
                                                      }}
                                                    ></span>
                                                    <span style={{ fontWeight: '400', fontSize: '14px' }}>
                                                      Completed
                                                    </span>
                                                  </>
                                                ) : task.task_status == 'grey' ? (
                                                  <>
                                                    <span
                                                      style={{
                                                        backgroundColor: '#7a7a7a',
                                                        display: 'inline-block',
                                                        width: '8px',
                                                        height: '8px',
                                                        borderRadius: '60%',
                                                        marginRight: '8px',
                                                      }}
                                                    ></span>
                                                    <span style={{ fontWeight: '400', fontSize: '14px' }}>
                                                      Deferred
                                                    </span>
                                                  </>
                                                ) : (
                                                  ''
                                                )}
                                              </span>
                                            </div>
                                            <div
                                              style={{
                                                width: '180px',
                                                fontWeight: '400',
                                                fontSize: '14px',
                                                color: '#000000',
                                              }}
                                            >
                                              <span>{getContactNameByContactUid(task.contact_uid)}</span>
                                            </div>
                                            <div
                                              style={{
                                                width: '160px',
                                                fontWeight: '400',
                                                fontSize: '14px',
                                                color: '#000000',
                                              }}
                                            >
                                              <span>
                                                {getCustomNameMappedValue(
                                                  task.task_stage,
                                                  NAMED_CONSTATNTS.task_stage_key
                                                )}
                                              </span>
                                            </div>
                                            <div
                                              style={{
                                                width: '160px',
                                                fontWeight: '400',
                                                fontSize: '14px',
                                                color: '#000000',
                                              }}
                                            >
                                              <span>
                                                {task.task_due_date != '0000:00:00 00-00-00' &&
                                                  !isNull(task.task_due_date) ? (
                                                  <>
                                                    {format(
                                                      new Date(task.task_due_date.replace(/-/g, '/')),
                                                      'dd-MMM-yyyy'
                                                    )}
                                                  </>
                                                ) : (
                                                  <>--</>
                                                )}
                                              </span>
                                            </div>
                                            <div
                                              style={{
                                                width: '160px',
                                                fontWeight: '400',
                                                fontSize: '14px',
                                                color: '#000000',
                                              }}
                                            >
                                              <span>
                                                {task.task_closed_date != '0000:00:00 00-00-00' &&
                                                  !isNull(task.task_closed_date) ? (
                                                  <>
                                                    {format(
                                                      new Date(task.task_closed_date.replace(/-/g, '/')),
                                                      'dd-MMM-yyyy'
                                                    )}
                                                  </>
                                                ) : (
                                                  <>--</>
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </>
                                  );
                                })}
                              </div>
                            ) : (
                              <>
                                <div
                                  style={{
                                    fontWeight: '400',
                                    padding: '1rem',
                                    background: '#FCFCFD',
                                    margin: '0px 0px 0px 0px',
                                    color: '#7a7a7a',
                                  }}
                                >
                                  No {label_task} exist for the selected date range
                                </div>
                              </>
                            )}
                          </div>
                        </p>
                      )}
                      {Object.keys(element.task).length > 0 && (
                        <>
                          <p
                            style={{
                              borderTopLeftRadius: '4px',
                              borderTopRightRadius: '4px',
                              padding: '16px 16px 0px 16px',
                              background: '#FCFCFD',
                              width: '93vw',
                              margin: '0px 0px 0px 0px',
                              border: '0px solid #E5E5E5',
                              fontWeight: '500',
                            }}
                          >
                            <p style={{ fontWeight: 500, fontSize: '20px', margin: '0px 16px 0px 0px' }}>
                              {' '}
                              Dependencies from {customerDetails?.data?.customer_company}{' '}
                            </p>
                            <div>
                              {element.task ? (
                                <div>
                                  {Object.keys(element.task).map((item: any, index: number) => {
                                    return (
                                      <>
                                        <p
                                          style={{ paddingTop: '5px', marginBottom: '3px', marginTop: '8px' }}
                                          className="label-text"
                                          key={index}
                                        >
                                          {item}
                                        </p>
                                        {element.task[item].map((task: any) => {
                                          return (
                                            <div
                                              key={task.task_uid}
                                              style={{
                                                borderTopLeftRadius: '4px',
                                                borderTopRightRadius: '4px',
                                                padding: '1rem',
                                                background: '#FCFCFD',
                                                width: '93vw',
                                                margin: '0px 0px 0px -16px',
                                                border: '1px solid #E5E5E5',
                                                display: 'flex',
                                              }}
                                            >
                                              <div
                                                style={{
                                                  width: '405px',
                                                  fontWeight: '400',
                                                  fontSize: '14px',
                                                  color: '#000000',
                                                }}
                                              >
                                                {task.milestone_task == 1 ? (
                                                  <>
                                                    <div style={{ display: 'flex' }}>
                                                      <div style={{ width: '10%' }}>
                                                        <span
                                                          style={{
                                                            display: 'block',
                                                            fontSize: '0px',
                                                            lineHeight: '0%',
                                                            width: '0px',
                                                            borderBottom: '8px solid #546E7A',
                                                            borderLeft: '8px solid transparent',
                                                            borderRight: '8px solid transparent',
                                                          }}
                                                        ></span>
                                                        <b
                                                          style={{
                                                            display: 'block',
                                                            fontSize: '0px',
                                                            lineHeight: '0%',
                                                            width: '0px',
                                                            borderTop: '8px solid #546E7A',
                                                            borderLeft: '8px solid transparent',
                                                            borderRight: '8px solid transparent',
                                                          }}
                                                        ></b>
                                                      </div>
                                                      <a className="disabled">{task.task_name}</a>
                                                    </div>
                                                  </>
                                                ) : (
                                                  <>
                                                    <div style={{ display: 'flex' }}>
                                                      <div style={{ width: '10%' }}></div>
                                                      <a className="disabled">{task.task_name}</a>
                                                    </div>
                                                  </>
                                                )}
                                              </div>
                                              <div
                                                style={{
                                                  width: '160px',
                                                  fontWeight: '400',
                                                  fontSize: '14px',
                                                  color: '#000000',
                                                }}
                                              >
                                                <span>
                                                  {getCustomNameMappedValue(
                                                    task.task_priority,
                                                    NAMED_CONSTATNTS.task_priority_key
                                                  )}
                                                </span>
                                              </div>
                                              <div
                                                style={{
                                                  width: '160px',
                                                  fontWeight: '400',
                                                  fontSize: '14px',
                                                  color: '#000000',
                                                }}
                                              >
                                                <span>
                                                  {task.task_status == 'red' ? (
                                                    <>
                                                      <span
                                                        style={{
                                                          backgroundColor: '#E75B5C',
                                                          display: 'inline-block',
                                                          width: '8px',
                                                          height: '8px',
                                                          borderRadius: '60%',
                                                          marginRight: '8px',
                                                        }}
                                                      ></span>
                                                      <span style={{ fontWeight: '400', fontSize: '14px' }}>
                                                        Overdue
                                                      </span>
                                                    </>
                                                  ) : task.task_status == 'green' ? (
                                                    <>
                                                      <span
                                                        style={{
                                                          backgroundColor: '#4F9F52',
                                                          display: 'inline-block',
                                                          width: '8px',
                                                          height: '8px',
                                                          borderRadius: '60%',
                                                          marginRight: '8px',
                                                        }}
                                                      ></span>
                                                      <span style={{ fontWeight: '400', fontSize: '14px' }}>
                                                        On Track
                                                      </span>
                                                    </>
                                                  ) : task.task_status == 'amber' ? (
                                                    <>
                                                      <span
                                                        style={{
                                                          backgroundColor: '#EFA958',
                                                          display: 'inline-block',
                                                          width: '8px',
                                                          height: '8px',
                                                          borderRadius: '60%',
                                                          marginRight: '8px',
                                                        }}
                                                      ></span>
                                                      <span style={{ fontWeight: '400', fontSize: '14px' }}>
                                                        At Risk
                                                      </span>
                                                    </>
                                                  ) : task.task_status == 'blue' ? (
                                                    <>
                                                      <span
                                                        style={{
                                                          backgroundColor: '#4390E1',
                                                          display: 'inline-block',
                                                          width: '8px',
                                                          height: '8px',
                                                          borderRadius: '60%',
                                                          marginRight: '8px',
                                                        }}
                                                      ></span>
                                                      <span style={{ fontWeight: '400', fontSize: '14px' }}>
                                                        Completed
                                                      </span>
                                                    </>
                                                  ) : task.task_status == 'grey' ? (
                                                    <>
                                                      <span
                                                        style={{
                                                          backgroundColor: '#7a7a7a',
                                                          display: 'inline-block',
                                                          width: '8px',
                                                          height: '8px',
                                                          borderRadius: '60%',
                                                          marginRight: '8px',
                                                        }}
                                                      ></span>
                                                      <span style={{ fontWeight: '400', fontSize: '14px' }}>
                                                        Deferred
                                                      </span>
                                                    </>
                                                  ) : (
                                                    ''
                                                  )}
                                                </span>
                                              </div>
                                              <div
                                                style={{
                                                  width: '180px',
                                                  fontWeight: '400',
                                                  fontSize: '14px',
                                                  color: '#000000',
                                                }}
                                              >
                                                <span>{getUserNameByUserUid(task.task_owner)}</span>
                                              </div>
                                              <div
                                                style={{
                                                  width: '160px',
                                                  fontWeight: '400',
                                                  fontSize: '14px',
                                                  color: '#000000',
                                                }}
                                              >
                                                <span>
                                                  {getCustomNameMappedValue(
                                                    task.task_stage,
                                                    NAMED_CONSTATNTS.task_stage_key
                                                  )}
                                                </span>
                                              </div>
                                              <div
                                                style={{
                                                  width: '160px',
                                                  fontWeight: '400',
                                                  fontSize: '14px',
                                                  color: '#000000',
                                                }}
                                              >
                                                <span>
                                                  {task.task_due_date != '0000:00:00 00-00-00' &&
                                                    !isNull(task.task_due_date) ? (
                                                    <>
                                                      {format(
                                                        new Date(task.task_due_date.replace(/-/g, '/')),
                                                        'dd-MMM-yyyy'
                                                      )}
                                                    </>
                                                  ) : (
                                                    <>--</>
                                                  )}
                                                </span>
                                              </div>
                                              <div
                                                style={{
                                                  width: '160px',
                                                  fontWeight: '400',
                                                  fontSize: '14px',
                                                  color: '#000000',
                                                }}
                                              >
                                                <span>
                                                  {task.task_closed_date != '0000:00:00 00-00-00' &&
                                                    !isNull(task.task_closed_date) ? (
                                                    <>
                                                      {format(
                                                        new Date(task.task_closed_date.replace(/-/g, '/')),
                                                        'dd-MMM-yyyy'
                                                      )}
                                                    </>
                                                  ) : (
                                                    <>--</>
                                                  )}
                                                </span>
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </>
                                    );
                                  })}
                                </div>
                              ) : (
                                <>
                                  <div
                                    style={{
                                      fontWeight: '400',
                                      padding: '1rem',
                                      background: '#FCFCFD',
                                      margin: '0px 0px 0px 0px',
                                      color: '#7a7a7a',
                                    }}
                                  >
                                    No {label_task} exist for the selected date range
                                  </div>
                                </>
                              )}
                            </div>
                          </p>
                        </>
                      )}

                      {Object.keys(element.clientTask).length == 0 && Object.keys(element.task).length == 0 && (
                        <p
                          style={{
                            borderTopLeftRadius: '4px',
                            borderTopRightRadius: '4px',
                            background: '#FCFCFD',
                            margin: '0px 0px 0px 0px',
                            border: '1px solid #E5E5E5',
                            fontWeight: '500',
                          }}
                        >
                          <div
                            style={{
                              fontWeight: '400',
                              padding: '0.5rem',
                              background: '#FCFCFD',
                              margin: '0px 0px 0px 0px',
                              color: '#7a7a7a',
                            }}
                          >
                            No task exist for the selected date range
                          </div>
                        </p>
                      )}
                      {element.usecase.length > 0 ? (
                        <>
                          {element.usecase.map((usecase: any, index1: number) => {
                            return (
                              <>
                                <div
                                  key={usecase.case_uid}
                                  id={`collapse${index1}`}
                                  style={{
                                    borderTopLeftRadius: '4px',
                                    borderTopRightRadius: '4px',
                                    fontWeight: '700',
                                    fontSize: '14px',
                                    padding: '1rem',
                                    background: '#FCFCFD',
                                    width: '93vw',
                                  }}
                                >
                                  <div className="row">
                                    <span
                                      style={{
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        lineHeight: '20px',
                                        color: '#546E7A',
                                      }}
                                    >
                                      {label_use_case} {index1 + 1} :
                                    </span>
                                  </div>
                                  <div className="row" style={{ lineHeight: '30px' }}>
                                    <span style={{ fontWeight: '400', fontSize: '14px', font: 'Noto Sans' }}>
                                      {usecase.case_name}
                                    </span>
                                    &nbsp; &nbsp; &nbsp; &nbsp;
                                    <span>
                                      {usecase.case_readinessstate_status == 'red' ? (
                                        <>
                                          <span
                                            style={{
                                              backgroundColor: '#E75B5C',
                                              display: 'inline-block',
                                              width: '8px',
                                              height: '8px',
                                              borderRadius: '60%',
                                              marginRight: '8px',
                                            }}
                                          ></span>
                                          <span style={{ fontWeight: '400', fontSize: '14px' }}>Overdue</span>
                                        </>
                                      ) : usecase.case_readinessstate_status == 'green' ? (
                                        <>
                                          <span
                                            style={{
                                              backgroundColor: '#4F9F52',
                                              display: 'inline-block',
                                              width: '8px',
                                              height: '8px',
                                              borderRadius: '60%',
                                              marginRight: '8px',
                                            }}
                                          ></span>
                                          <span style={{ fontWeight: '400', fontSize: '14px' }}>On Track</span>
                                        </>
                                      ) : usecase.case_readinessstate_status == 'amber' ? (
                                        <>
                                          <span
                                            style={{
                                              backgroundColor: '#EFA958',
                                              display: 'inline-block',
                                              width: '8px',
                                              height: '8px',
                                              borderRadius: '60%',
                                              marginRight: '8px',
                                            }}
                                          ></span>
                                          <span style={{ fontWeight: '400', fontSize: '14px' }}>At Risk</span>
                                        </>
                                      ) : usecase.case_readinessstate_status == 'blue' ? (
                                        <>
                                          <span
                                            style={{
                                              backgroundColor: '#4390E1',
                                              display: 'inline-block',
                                              width: '8px',
                                              height: '8px',
                                              borderRadius: '60%',
                                              marginRight: '8px',
                                            }}
                                          ></span>
                                          <span style={{ fontWeight: '400', fontSize: '14px' }}>Completed</span>
                                        </>
                                      ) : (
                                        ''
                                      )}
                                    </span>
                                  </div>
                                  <div className="row" style={{ display: 'flex', lineHeight: '30px' }}>
                                    <div style={{ width: '400px' }}>
                                      <span
                                        style={{
                                          fontSize: '14px',
                                          fontWeight: '400',
                                          lineHeight: '20px',
                                          color: '#546E7A',
                                        }}
                                      >
                                        Delivery Status Summary
                                      </span>
                                    </div>
                                    <div style={{ width: '150px' }}>
                                      <span
                                        style={{
                                          fontSize: '14px',
                                          fontWeight: '400',
                                          lineHeight: '20px',
                                          color: '#546E7A',
                                        }}
                                      >
                                        Start Date
                                      </span>
                                    </div>
                                    <div style={{ width: '150px' }}>
                                      <span
                                        style={{
                                          fontSize: '14px',
                                          fontWeight: '400',
                                          lineHeight: '20px',
                                          color: '#546E7A',
                                        }}
                                      >
                                        {label_due_date}
                                      </span>
                                    </div>
                                    <div>
                                      <span
                                        style={{
                                          fontSize: '14px',
                                          fontWeight: '400',
                                          lineHeight: '20px',
                                          color: '#546E7A',
                                        }}
                                      >
                                        Expected {label_delivery_date}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="row" style={{ display: 'flex', lineHeight: '30px' }}>
                                    <div style={{ width: '400px' }}>
                                      <span style={{ fontSize: '14px', fontWeight: '400', lineHeight: '20px' }}>
                                        {usecase.case_readinessstate_status == 'red' ? (
                                          <>
                                            <span>
                                              One or more {label_task}s for this {label_project} are overdue
                                            </span>
                                          </>
                                        ) : usecase.case_readinessstate_status == 'green' ? (
                                          <>
                                            <span>Delivery of this {label_project} is On Track and on track</span>
                                          </>
                                        ) : usecase.case_readinessstate_status == 'amber' ? (
                                          <>
                                            <span>
                                              Completion of one or more {label_task}s by the {label_due_date} is at risk
                                            </span>
                                          </>
                                        ) : usecase.case_readinessstate_status == 'blue' ? (
                                          <>
                                            <span>Delivery of this {label_project} is completed</span>
                                          </>
                                        ) : (
                                          ''
                                        )}
                                      </span>
                                    </div>
                                    <div style={{ width: '150px' }}>
                                      <span style={{ fontSize: '14px', fontWeight: '400', lineHeight: '20px' }}>
                                        {usecase.case_startdate !== '0000-00-00 00:00:00' &&
                                          !isNull(usecase.case_startdate) ? (
                                          format(new Date(usecase.case_startdate.replace(/-/g, '/')), 'dd-MMM-yyyy')
                                        ) : (
                                          <>--</>
                                        )}
                                      </span>
                                    </div>
                                    <div style={{ width: '150px' }}>
                                      <span style={{ fontSize: '14px', fontWeight: '400', lineHeight: '20px' }}>
                                        {usecase.case_requiredbydate !== '0000-00-00 00:00:00' &&
                                          !isNull(usecase.case_requiredbydate) ? (
                                          format(
                                            new Date(usecase.case_requiredbydate.replace(/-/g, '/')),
                                            'dd-MMM-yyyy'
                                          )
                                        ) : (
                                          <>--</>
                                        )}
                                      </span>
                                    </div>
                                    <div>
                                      <span style={{ fontSize: '14px', fontWeight: '400', lineHeight: '20px' }}>
                                        {usecase.eta_forcasedelivery !== '0000-00-00 00:00:00' &&
                                          !isNull(usecase.eta_forcasedelivery) ? (
                                          format(
                                            new Date(usecase.eta_forcasedelivery.replace(/-/g, '/')),
                                            'dd-MMM-yyyy'
                                          )
                                        ) : (
                                          <>--</>
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="row" style={{ display: 'flex', lineHeight: '30px' }}>
                                    <span
                                      className={`textinputUsecase${index}`}
                                      style={{
                                        whiteSpace: 'pre',
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        lineHeight: '20px',
                                        color: '#546E7A',
                                        paddingBottom: '10px',
                                      }}
                                    >
                                      Comments:
                                    </span>
                                  </div>
                                  <div
                                    id={`usecasetext${index1}`}
                                    className="row"
                                    style={{ display: 'flex', lineHeight: '30px' }}
                                  >
                                    <textarea id={`usetext${index1}`} style={{ width: '600px' }}></textarea>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    borderTopLeftRadius: '4px',
                                    borderTopRightRadius: '4px',
                                    padding: '1rem',
                                    background: '#E1E1E5',
                                    width: '93vw',
                                    display: 'flex',
                                  }}
                                >
                                  <div
                                    style={{ width: '405px', fontWeight: '400', fontSize: '14px', color: '#000000' }}
                                  >
                                    <span>{label_task} Name</span>
                                  </div>
                                  <div
                                    style={{ width: '160px', fontWeight: '400', fontSize: '14px', color: '#000000' }}
                                  >
                                    <span>Priority</span>
                                  </div>
                                  <div
                                    style={{ width: '160px', fontWeight: '400', fontSize: '14px', color: '#000000' }}
                                  >
                                    <span>Status</span>
                                  </div>
                                  <div
                                    style={{ width: '180px', fontWeight: '400', fontSize: '14px', color: '#000000' }}
                                  >
                                    <span>Owner</span>
                                  </div>
                                  <div
                                    style={{ width: '160px', fontWeight: '400', fontSize: '14px', color: '#000000' }}
                                  >
                                    <span>Stage</span>
                                  </div>
                                  <div
                                    style={{ width: '160px', fontWeight: '400', fontSize: '14px', color: '#000000' }}
                                  >
                                    <span>{label_due_date}</span>
                                  </div>
                                  <div
                                    style={{ width: '160px', fontWeight: '400', fontSize: '14px', color: '#000000' }}
                                  >
                                    <span>{label_delivery_date}</span>
                                  </div>
                                </div>
                                {Object.keys(usecase.clientTask).length > 0 && (
                                  <>
                                    <p
                                      style={{
                                        borderTopLeftRadius: '4px',
                                        borderTopRightRadius: '4px',
                                        padding: '16px 16px 0px 16px',
                                        background: '#FCFCFD',
                                        width: '93vw',
                                        margin: '0px 0px 0px 0px',
                                        border: '1px solid #E5E5E5',
                                        fontWeight: '500',
                                      }}
                                    >
                                      <p style={{ fontWeight: 500, fontSize: '20px', margin: '0px 16px 0px 0px' }}>
                                        {' '}
                                        Dependencies from {clientInformation.client_name}{' '}
                                      </p>
                                      <div>
                                        {usecase.clientTask ? (
                                          <div>
                                            {Object.keys(usecase.clientTask).map((item: any, index: number) => {
                                              return (
                                                <>
                                                  <p
                                                    style={{
                                                      paddingTop: '5px',
                                                      marginBottom: '3px',
                                                      marginTop: '8px',
                                                    }}
                                                    className="label-text"
                                                    key={index}
                                                  // variant="subtitle2"
                                                  >
                                                    {item}
                                                  </p>
                                                  {usecase?.clientTask[item].map((task: any) => {
                                                    return (
                                                      <div
                                                        key={task.task_uid}
                                                        style={{
                                                          borderTopLeftRadius: '4px',
                                                          borderTopRightRadius: '4px',
                                                          padding: '1rem',
                                                          background: '#FCFCFD',
                                                          width: '93vw',
                                                          margin: '0px 0px 0px -16px',
                                                          border: '1px solid #E5E5E5',
                                                          display: 'flex',
                                                        }}
                                                      >
                                                        <div
                                                          style={{
                                                            width: '405px',
                                                            fontWeight: '400',
                                                            fontSize: '14px',
                                                            color: '#000000',
                                                          }}
                                                        >
                                                          {task.milestone_task == 1 ? (
                                                            <>
                                                              <div style={{ display: 'flex' }}>
                                                                <div style={{ width: '10%' }}>
                                                                  <span
                                                                    style={{
                                                                      display: 'block',
                                                                      fontSize: '0px',
                                                                      lineHeight: '0%',
                                                                      width: '0px',
                                                                      borderBottom: '8px solid #546E7A',
                                                                      borderLeft: '8px solid transparent',
                                                                      borderRight: '8px solid transparent',
                                                                    }}
                                                                  ></span>
                                                                  <b
                                                                    style={{
                                                                      display: 'block',
                                                                      fontSize: '0px',
                                                                      lineHeight: '0%',
                                                                      width: '0px',
                                                                      borderTop: '8px solid #546E7A',
                                                                      borderLeft: '8px solid transparent',
                                                                      borderRight: '8px solid transparent',
                                                                    }}
                                                                  ></b>
                                                                </div>
                                                                <a
                                                                  href={'{{user_token}}/taskUid/' + task.task_uid}
                                                                  className="disabled"
                                                                >
                                                                  {task.task_name}
                                                                </a>
                                                              </div>
                                                            </>
                                                          ) : (
                                                            <>
                                                              <div style={{ display: 'flex' }}>
                                                                <div style={{ width: '10%' }}></div>
                                                                <a
                                                                  href={'{{user_token}}/taskUid/' + task.task_uid}
                                                                  className="disabled"
                                                                >
                                                                  {task.task_name}
                                                                </a>
                                                              </div>
                                                            </>
                                                          )}
                                                        </div>
                                                        <div
                                                          style={{
                                                            width: '160px',
                                                            fontWeight: '400',
                                                            fontSize: '14px',
                                                            color: '#000000',
                                                          }}
                                                        >
                                                          <span>
                                                            {getCustomNameMappedValue(
                                                              task.task_priority,
                                                              NAMED_CONSTATNTS.task_priority_key
                                                            )}
                                                          </span>
                                                        </div>
                                                        <div
                                                          style={{
                                                            width: '160px',
                                                            fontWeight: '400',
                                                            fontSize: '14px',
                                                            color: '#000000',
                                                          }}
                                                        >
                                                          <span>
                                                            {task.task_status == 'red' ? (
                                                              <>
                                                                <span
                                                                  style={{
                                                                    backgroundColor: '#E75B5C',
                                                                    display: 'inline-block',
                                                                    width: '8px',
                                                                    height: '8px',
                                                                    borderRadius: '60%',
                                                                    marginRight: '8px',
                                                                  }}
                                                                ></span>
                                                                <span style={{ fontWeight: '400', fontSize: '14px' }}>
                                                                  Overdue
                                                                </span>
                                                              </>
                                                            ) : task.task_status == 'green' ? (
                                                              <>
                                                                <span
                                                                  style={{
                                                                    backgroundColor: '#4F9F52',
                                                                    display: 'inline-block',
                                                                    width: '8px',
                                                                    height: '8px',
                                                                    borderRadius: '60%',
                                                                    marginRight: '8px',
                                                                  }}
                                                                ></span>
                                                                <span style={{ fontWeight: '400', fontSize: '14px' }}>
                                                                  On Track
                                                                </span>
                                                              </>
                                                            ) : task.task_status == 'amber' ? (
                                                              <>
                                                                <span
                                                                  style={{
                                                                    backgroundColor: '#EFA958',
                                                                    display: 'inline-block',
                                                                    width: '8px',
                                                                    height: '8px',
                                                                    borderRadius: '60%',
                                                                    marginRight: '8px',
                                                                  }}
                                                                ></span>
                                                                <span style={{ fontWeight: '400', fontSize: '14px' }}>
                                                                  At Risk
                                                                </span>
                                                              </>
                                                            ) : task.task_status == 'blue' ? (
                                                              <>
                                                                <span
                                                                  style={{
                                                                    backgroundColor: '#4390E1',
                                                                    display: 'inline-block',
                                                                    width: '8px',
                                                                    height: '8px',
                                                                    borderRadius: '60%',
                                                                    marginRight: '8px',
                                                                  }}
                                                                ></span>
                                                                <span style={{ fontWeight: '400', fontSize: '14px' }}>
                                                                  Completed
                                                                </span>
                                                              </>
                                                            ) : task.task_status == 'grey' ? (
                                                              <>
                                                                <span
                                                                  style={{
                                                                    backgroundColor: '#7a7a7a',
                                                                    display: 'inline-block',
                                                                    width: '8px',
                                                                    height: '8px',
                                                                    borderRadius: '60%',
                                                                    marginRight: '8px',
                                                                  }}
                                                                ></span>
                                                                <span style={{ fontWeight: '400', fontSize: '14px' }}>
                                                                  Deferred
                                                                </span>
                                                              </>
                                                            ) : (
                                                              ''
                                                            )}
                                                          </span>
                                                        </div>
                                                        <div
                                                          style={{
                                                            width: '180px',
                                                            fontWeight: '400',
                                                            fontSize: '14px',
                                                            color: '#000000',
                                                          }}
                                                        >
                                                          <span>{getContactNameByContactUid(task.contact_uid)}</span>
                                                        </div>
                                                        <div
                                                          style={{
                                                            width: '160px',
                                                            fontWeight: '400',
                                                            fontSize: '14px',
                                                            color: '#000000',
                                                          }}
                                                        >
                                                          <span>
                                                            {getCustomNameMappedValue(
                                                              task.task_stage,
                                                              NAMED_CONSTATNTS.task_stage_key
                                                            )}
                                                          </span>
                                                        </div>
                                                        <div
                                                          style={{
                                                            width: '160px',
                                                            fontWeight: '400',
                                                            fontSize: '14px',
                                                            color: '#000000',
                                                          }}
                                                        >
                                                          <span>
                                                            {task.task_due_date != '0000:00:00 00-00-00' &&
                                                              !isNull(task.task_due_date) ? (
                                                              <>
                                                                {format(
                                                                  new Date(task.task_due_date.replace(/-/g, '/')),
                                                                  'dd-MMM-yyyy'
                                                                )}
                                                              </>
                                                            ) : (
                                                              <>--</>
                                                            )}
                                                          </span>
                                                        </div>
                                                        <div
                                                          style={{
                                                            width: '160px',
                                                            fontWeight: '400',
                                                            fontSize: '14px',
                                                            color: '#000000',
                                                          }}
                                                        >
                                                          <span>
                                                            {task.task_closed_date != '0000:00:00 00-00-00' &&
                                                              !isNull(task.task_closed_date) ? (
                                                              <>
                                                                {format(
                                                                  new Date(task.task_closed_date.replace(/-/g, '/')),
                                                                  'dd-MMM-yyyy'
                                                                )}
                                                              </>
                                                            ) : (
                                                              <>--</>
                                                            )}
                                                          </span>
                                                        </div>
                                                      </div>
                                                    );
                                                  })}
                                                </>
                                              );
                                            })}
                                          </div>
                                        ) : (
                                          <>
                                            <div
                                              style={{
                                                fontWeight: '400',
                                                padding: '1rem',
                                                background: '#FCFCFD',
                                                margin: '0px 0px 0px 0px',
                                                color: '#7a7a7a',
                                              }}
                                            >
                                              No {label_task} exist for the selected date range
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </p>
                                  </>
                                )}

                                {Object.keys(usecase.task).length > 0 && (
                                  <>
                                    <p
                                      style={{
                                        borderTopLeftRadius: '4px',
                                        borderTopRightRadius: '4px',
                                        padding: '16px 16px 0px 16px',
                                        background: '#FCFCFD',
                                        width: '93vw',
                                        margin: '0px 0px 0px 0px',
                                        border: '1px solid #E5E5E5',
                                        fontWeight: '500',
                                      }}
                                    >
                                      <p style={{ fontWeight: 500, fontSize: '20px', margin: '0px 16px 0px 0px' }}>
                                        {' '}
                                        Dependencies from {customerDetails?.data?.customer_company}{' '}
                                      </p>
                                      <div>
                                        {usecase.task ? (
                                          <div>
                                            {Object.keys(usecase.task).map((item: any, index: number) => {
                                              return (
                                                <>
                                                  <p
                                                    style={
                                                      index == 0
                                                        ? { paddingTop: '4px', marginTop: '8px' }
                                                        : { paddingTop: '3px', marginBottom: '3px' }
                                                    }
                                                    className="label-text"
                                                    key={index}
                                                  >
                                                    {item}
                                                  </p>
                                                  {usecase.task[item].map((task: any) => {
                                                    return (
                                                      <div
                                                        key={task.task_uid}
                                                        style={{
                                                          borderTopLeftRadius: '4px',
                                                          borderTopRightRadius: '4px',
                                                          padding: '1rem',
                                                          background: '#FCFCFD',
                                                          width: '93vw',
                                                          margin: '0px 0px 0px -16px',
                                                          border: '1px solid #E5E5E5',
                                                          display: 'flex',
                                                        }}
                                                      >
                                                        <div
                                                          style={{
                                                            width: '405px',
                                                            fontWeight: '400',
                                                            fontSize: '14px',
                                                            color: '#000000',
                                                          }}
                                                        >
                                                          {task.milestone_task == 1 ? (
                                                            <>
                                                              <div style={{ display: 'flex' }}>
                                                                <div style={{ width: '10%' }}>
                                                                  <span
                                                                    style={{
                                                                      display: 'block',
                                                                      fontSize: '0px',
                                                                      lineHeight: '0%',
                                                                      width: '0px',
                                                                      borderBottom: '8px solid #546E7A',
                                                                      borderLeft: '8px solid transparent',
                                                                      borderRight: '8px solid transparent',
                                                                    }}
                                                                  ></span>
                                                                  <b
                                                                    style={{
                                                                      display: 'block',
                                                                      fontSize: '0px',
                                                                      lineHeight: '0%',
                                                                      width: '0px',
                                                                      borderTop: '8px solid #546E7A',
                                                                      borderLeft: '8px solid transparent',
                                                                      borderRight: '8px solid transparent',
                                                                    }}
                                                                  ></b>
                                                                </div>
                                                                <a className="disabled">{task.task_name}</a>
                                                              </div>
                                                            </>
                                                          ) : (
                                                            <>
                                                              <div style={{ display: 'flex' }}>
                                                                <div style={{ width: '10%' }}></div>
                                                                <a className="disabled">{task.task_name}</a>
                                                              </div>
                                                            </>
                                                          )}
                                                        </div>
                                                        <div
                                                          style={{
                                                            width: '160px',
                                                            fontWeight: '400',
                                                            fontSize: '14px',
                                                            color: '#000000',
                                                          }}
                                                        >
                                                          <span>
                                                            {getCustomNameMappedValue(
                                                              task.task_priority,
                                                              NAMED_CONSTATNTS.task_priority_key
                                                            )}
                                                          </span>
                                                        </div>
                                                        <div
                                                          style={{
                                                            width: '160px',
                                                            fontWeight: '400',
                                                            fontSize: '14px',
                                                            color: '#000000',
                                                          }}
                                                        >
                                                          <span>
                                                            {task.task_status == 'red' ? (
                                                              <>
                                                                <span
                                                                  style={{
                                                                    backgroundColor: '#E75B5C',
                                                                    display: 'inline-block',
                                                                    width: '8px',
                                                                    height: '8px',
                                                                    borderRadius: '60%',
                                                                    marginRight: '8px',
                                                                  }}
                                                                ></span>
                                                                <span style={{ fontWeight: '400', fontSize: '14px' }}>
                                                                  Overdue
                                                                </span>
                                                              </>
                                                            ) : task.task_status == 'green' ? (
                                                              <>
                                                                <span
                                                                  style={{
                                                                    backgroundColor: '#4F9F52',
                                                                    display: 'inline-block',
                                                                    width: '8px',
                                                                    height: '8px',
                                                                    borderRadius: '60%',
                                                                    marginRight: '8px',
                                                                  }}
                                                                ></span>
                                                                <span style={{ fontWeight: '400', fontSize: '14px' }}>
                                                                  On Track
                                                                </span>
                                                              </>
                                                            ) : task.task_status == 'amber' ? (
                                                              <>
                                                                <span
                                                                  style={{
                                                                    backgroundColor: '#EFA958',
                                                                    display: 'inline-block',
                                                                    width: '8px',
                                                                    height: '8px',
                                                                    borderRadius: '60%',
                                                                    marginRight: '8px',
                                                                  }}
                                                                ></span>
                                                                <span style={{ fontWeight: '400', fontSize: '14px' }}>
                                                                  At Risk
                                                                </span>
                                                              </>
                                                            ) : task.task_status == 'blue' ? (
                                                              <>
                                                                <span
                                                                  style={{
                                                                    backgroundColor: '#4390E1',
                                                                    display: 'inline-block',
                                                                    width: '8px',
                                                                    height: '8px',
                                                                    borderRadius: '60%',
                                                                    marginRight: '8px',
                                                                  }}
                                                                ></span>
                                                                <span style={{ fontWeight: '400', fontSize: '14px' }}>
                                                                  Completed
                                                                </span>
                                                              </>
                                                            ) : task.task_status == 'grey' ? (
                                                              <>
                                                                <span
                                                                  style={{
                                                                    backgroundColor: '#7a7a7a',
                                                                    display: 'inline-block',
                                                                    width: '8px',
                                                                    height: '8px',
                                                                    borderRadius: '60%',
                                                                    marginRight: '8px',
                                                                  }}
                                                                ></span>
                                                                <span style={{ fontWeight: '400', fontSize: '14px' }}>
                                                                  Deferred
                                                                </span>
                                                              </>
                                                            ) : (
                                                              ''
                                                            )}
                                                          </span>
                                                        </div>
                                                        <div
                                                          style={{
                                                            width: '180px',
                                                            fontWeight: '400',
                                                            fontSize: '14px',
                                                            color: '#000000',
                                                          }}
                                                        >
                                                          <span>{getUserNameByUserUid(task.task_owner)}</span>
                                                        </div>
                                                        <div
                                                          style={{
                                                            width: '160px',
                                                            fontWeight: '400',
                                                            fontSize: '14px',
                                                            color: '#000000',
                                                          }}
                                                        >
                                                          <span>
                                                            {getCustomNameMappedValue(
                                                              task.task_stage,
                                                              NAMED_CONSTATNTS.task_stage_key
                                                            )}
                                                          </span>
                                                        </div>
                                                        <div
                                                          style={{
                                                            width: '160px',
                                                            fontWeight: '400',
                                                            fontSize: '14px',
                                                            color: '#000000',
                                                          }}
                                                        >
                                                          <span>
                                                            {' '}
                                                            {task.task_due_date != '0000:00:00 00-00-00' &&
                                                              !isNull(task.task_due_date) ? (
                                                              <>
                                                                {format(
                                                                  new Date(task.task_due_date.replace(/-/g, '/')),
                                                                  'dd-MMM-yyyy'
                                                                )}
                                                              </>
                                                            ) : (
                                                              <>--</>
                                                            )}
                                                          </span>
                                                        </div>
                                                        <div
                                                          style={{
                                                            width: '160px',
                                                            fontWeight: '400',
                                                            fontSize: '14px',
                                                            color: '#000000',
                                                          }}
                                                        >
                                                          <span>
                                                            {task.task_closed_date != '0000:00:00 00-00-00' &&
                                                              !isNull(task.task_closed_date) ? (
                                                              <>
                                                                {format(
                                                                  new Date(task.task_closed_date.replace(/-/g, '/')),
                                                                  'dd-MMM-yyyy'
                                                                )}
                                                              </>
                                                            ) : (
                                                              <>--</>
                                                            )}
                                                          </span>
                                                        </div>
                                                      </div>
                                                    );
                                                  })}
                                                </>
                                              );
                                            })}
                                          </div>
                                        ) : (
                                          <>
                                            <div
                                              style={{
                                                fontWeight: '400',
                                                padding: '1rem',
                                                background: '#FCFCFD',
                                                margin: '0px 0px 0px 0px',
                                                color: '#7a7a7a',
                                              }}
                                            >
                                              No {label_task} exist for the selected date range
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </p>
                                  </>
                                )}
                                {Object.keys(usecase.clientTask).length == 0 && Object.keys(usecase.task).length == 0 && (
                                  <p
                                    style={{
                                      borderTopLeftRadius: '4px',
                                      borderTopRightRadius: '4px',
                                      background: '#FCFCFD',
                                      margin: '0px 0px 0px 0px',
                                      border: '1px solid #E5E5E5',
                                      fontWeight: '500',
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontWeight: '400',
                                        padding: '0.5rem',
                                        background: '#FCFCFD',
                                        margin: '0px 0px 0px 0px',
                                        color: '#7a7a7a',
                                      }}
                                    >
                                      No task exist for the selected date range
                                    </div>
                                  </p>
                                )}
                              </>
                            );
                          })}
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  );
                })
              ) : (
                <>No records found</>
              )}
            </div>
          </div>
        </Grid>
        {openMailListModal == true ? (
          <BasicModal open={true} onClose={() => afterClosingModal()}>
            <ClientStatusReportMailSelection
              verifiedClientContacts={verifiedClientContacts}
              allUsers={allUsers}
              setmailList={setmailList}
              mailList={mailList}
              sendMail={sendMail}
              ccMailList={ccMailList}
              setccMailList={setccMailList}
            ></ClientStatusReportMailSelection>
          </BasicModal>
        ) : null}
      </Box>
    </>
  );
};

export default ClientStatusReport;
