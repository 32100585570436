import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../..';

export const selectReportsAllProjectsState = (state: RootState) => state.reportsAllProjectsState;
export const selectReportsAllProjects = createSelector(
  selectReportsAllProjectsState,
  (reportsAllProjectsState) => reportsAllProjectsState.projects
);
export const selectReportsAllProjectsStatus = createSelector(
  selectReportsAllProjectsState,
  (reportsAllProjectsState) => reportsAllProjectsState.status
);
export const selectReportsAllProjectsError = createSelector(
  selectReportsAllProjectsState,
  (reportsAllProjectsState) => reportsAllProjectsState.error
);
