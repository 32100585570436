import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../store/hooks';
import { fetchClientContacts } from '../../../store/modules/ManageClient/ClientList/slice';
import { selectClientContacts } from '../../../store/modules/ManageClient/ClientList/selector';

const useClientContacts = (clientUid: string) => {
  const dispatch = useDispatch();
  const clientContactsListState = useAppSelector(selectClientContacts);
  const [contactsList, setContactList] = useState<any>([]);

  useEffect(() => {
    console.log(clientUid);
    if (clientUid) {
      dispatch(fetchClientContacts(clientUid));
    }
  }, []);

  useEffect(() => {
    const users = clientContactsListState.map((contact: any) => {
      return {
        user_username: contact.contact_name,
        user_uid: contact.contact_uid,
      };
    });
    setContactList([...users, { user_username: 'Not Assigned', user_uid: '15243' }]);
  }, [clientContactsListState]);

  return contactsList;
};

export default useClientContacts;
