// constants
export const availableSegments = ['Enterprise', 'Mid-Market', 'SMB'];
export const availableIndusries = [
  'Agriculture',
  'Automobile',
  'Banking & Finance',
  'E-Commerce',
  'Education',
  'Healthcare',
  'Hospitality',
  'Insurance',
  'Manufacturing',
  'Media',
  'Telecom',
  'Retail',
  'Other',
  'Not-Specified',
];
export const availablestages = ['Pre-Sales', 'Pilot', 'Onboarding', 'Adoption', 'expansion', 'Churned'];
export const availableCategories = ['Land', 'Expand'];
export const availablePriorities = ['Critical', 'High', 'Medium', 'Low'];
