import CognisaasMultiSelectWithSearchUid from 'components/shared/CognisaasMultiSelectWithSearch/CognisaasMultiSelectWithSearchUid';
import { Stack, Typography, Button, TextField } from '@mui/material';
import { useState, useEffect } from 'react';
import CognisaasTabs from 'components/shared/CognisaasTabs';
import BulkAttachmentLocalUploader from 'components/shared/Attachment/BulkAttachmentLocalUploader';

const ClientStatusReportMailSelection = (props: any) => {
  const contact_array: any = [];
  const users_array: any = [];
  const [sendButton, setsendButton] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [value, setValue] = useState('');
  const [bccValue, setbccValue] = useState('');
  const [attachments, setAttachments] = useState<any[]>([]);

  props.verifiedClientContacts.forEach((element: any) => {
    const obj: any = {};
    obj.name = element.contact_name;
    obj.id = element.contact_email;
    contact_array.push(obj);
  });
  props.allUsers.forEach((element: any) => {
    const obj: any = {};
    obj.name = element.user_username;
    obj.id = element.user_email;
    users_array.push(obj);
  });
  const validateEmails = (emailInput: any) => {
    // Get value on emails input as a string
    if (emailInput.trim() == '') {
      return true;
    } else {
      let emails = emailInput;
      // Split string by comma into an array
      emails = emails.split(',');

      /* eslint-disable */
      const regex =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      /* eslint-enable */

      const invalidEmails = [];

      for (let i = 0; i < emails.length; i++) {
        // Trim whitespaces from email address
        emails[i] = emails[i].trim();

        // Check email against our regex to determine if email is valid
        if (emails[i] == '' || !regex.test(emails[i])) {
          invalidEmails.push(emails[i]);
        }
      }

      if (invalidEmails.length != 0) {
        return false;
      } else {
        return true;
      }
    }
  };
  useEffect(() => {
    if (props.mailList.length != 0 && validateEmails(value) && validateEmails(bccValue)) setsendButton(true);
    else setsendButton(false);
  }, [props.mailList, value, bccValue]);
  useEffect(() => {
    props.setmailList([]);
  }, [selectedTab]);

  console.log('attach', attachments);

  return (
    <Stack>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <CognisaasTabs
          selectedTabIndex={selectedTab}
          handleTabSwitch={(e: any, index: number) => setSelectedTab(index)}
          tabs={['Send Static email', 'Send Dynamic email']}
        />
      </Stack>
      {selectedTab === 0 ? (
        <Stack direction="column" justifyContent="space-evenly">
          <Stack direction="column">
            <Typography variant="subtitle2" sx={{ fontSize: '1rem' }}>
              Select client contacts
            </Typography>
            <CognisaasMultiSelectWithSearchUid
              allOptions={contact_array}
              selectedOption={props.mailList}
              setSelectedOptions={props.setmailList}
            />
          </Stack>
          <hr style={{ border: 'none' }} />
          <Stack direction="column">
            <Typography variant="subtitle2" sx={{ fontSize: '1rem' }}>
              Select colleagues
            </Typography>

            <CognisaasMultiSelectWithSearchUid
              allOptions={users_array}
              selectedOption={props.ccMailList}
              setSelectedOptions={props.setccMailList}
            />
          </Stack>
          <hr style={{ border: 'none' }} />
          <Stack>
            <Typography variant="subtitle2">CC users not accessing the tool</Typography>
            <TextField
              name="useremails"
              size="small"
              placeholder="use comma , to separate email ids"
              onChange={(e) => {
                setValue(e.target.value);
              }}
            ></TextField>
          </Stack>
          <Stack style={{ marginTop: '20px' }}>
            <Typography variant="subtitle2">BCC users not accessing the tool</Typography>
            <TextField
              name="useremails"
              size="small"
              placeholder="use comma , to separate email ids"
              onChange={(e) => {
                setbccValue(e.target.value);
              }}
            ></TextField>
          </Stack>
          {/* attachment */}
          <Stack mt={2}>
            <BulkAttachmentLocalUploader attachments={attachments} setAttachments={setAttachments} />
          </Stack>
          <hr style={{ border: 'none' }} />
          {sendButton == true ? (
            <Button
              variant="contained"
              color="primary"
              onClick={() => props.sendMail(value, bccValue, 'Stactic', attachments)}
            >
              Send Mail
            </Button>
          ) : (
            <Button variant="contained" color="primary" disabled>
              Send Mail
            </Button>
          )}
        </Stack>
      ) : (
        <Stack direction="column">
          <Typography variant="subtitle2" sx={{ fontSize: '1rem', marginBottom: '8px' }}>
            Select client contact
          </Typography>
          <CognisaasMultiSelectWithSearchUid
            allOptions={contact_array}
            selectedOption={props.mailList}
            setSelectedOptions={props.setmailList}
            multiple={false}
          />
          {/* attachment */}
          <Stack mt={2}>
            <BulkAttachmentLocalUploader attachments={attachments} setAttachments={setAttachments} />
          </Stack>
          <Stack style={{ marginTop: '15px' }}>
            {sendButton == true ? (
              <Button
                variant="contained"
                color="primary"
                onClick={() => props.sendMail(value, '', 'Dynamic', attachments)}
              >
                Send Mail
              </Button>
            ) : (
              <Button variant="contained" color="primary" disabled>
                Send Mail
              </Button>
            )}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};
export default ClientStatusReportMailSelection;
