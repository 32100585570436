import { Accordion, AccordionDetails, AccordionSummary, IconButton, Stack, TextField, Typography } from '@mui/material';
import BasicModal from 'components/shared/CognisaasModal/CognisaasModal';
import CognisaasSelect from 'components/shared/CognisaasSelect/CognisaasSelect';
import React, { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import CognisaasButton from 'components/shared/CognisaasButton/CognisaasButton';
import AddConditionModal from './AddConditionModal';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NoRecordsFound from 'components/shared/NoRecords/NoRecordsFound';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  data: any;
  columnNameChangeHandler: (value: string, key: string, mainIndex: number, index: number) => void;
  parentIndex: number;
  removeField: (mainIndex: number, index: number) => void;
  topEntityIndex: any;
  currentEntityIndex: any;
  operationTypeChange: (value: string, key: string, mainIndex: number, index: number) => void;
  //   allowedOperations: string[];
  //   setSelectedAggregationIndex: React.Dispatch<React.SetStateAction<number | null>>;
  //   setConditionsOnField: React.Dispatch<React.SetStateAction<any[]>>;
  //   makeDataForAllowedOperation: (value: string) => void;
  addAggregation: (index: number) => void;
  title: string;
  dataForChoosingAllowedOperations: any;
  //   validValuesAvailable: any;
  //   conditionsOnField: any;
  //   selectedFieldType: any;
  //   selectedField: any;
  conditionsChangeHandler: any;
}
const AddAggregationModal = (props: Props) => {
  const {
    isOpen,
    setIsOpen,
    data,
    columnNameChangeHandler,
    parentIndex,
    removeField,
    topEntityIndex,
    currentEntityIndex,
    // allowedOperations,
    // setSelectedAggregationIndex,
    // setConditionsOnField,
    // makeDataForAllowedOperation,
    addAggregation,
    title,
    dataForChoosingAllowedOperations,
    // validValuesAvailable,
    // conditionsOnField,
    // selectedFieldType,
    // selectedField,
    // closeModal,
    // conditionsChangeHandler,
  } = props;
  //   const [conditionModal, setConditionModal] = useState<boolean>(false);
  const [allowedOperations, setAllowedOperations] = useState<string[]>([]);
  const [selectedFieldType, setSelectedFieldType] = useState<string>('');
  const [validValuesAvailable, setValidValuesAvailable] = useState<any>(null);
  const [selectedField, setSelectedField] = useState<string>('');
  const [conditionsOnField, setConditionsOnField] = useState<any[]>([]);
  const [selectedAggregationIndex, setSelectedAggregationIndex] = useState<number | null>(null);

  useEffect(() => {
    makeDataForAllowedOperation(data.fieldName);
  }, []);

  // console.log('data', data);

  const makeDataForAllowedOperation = (value: string): void => {
    // console.log(value, props, setConditionsOnField);
    // const key: string = getKeyByDisplayName(props.dataForChoosingAllowedOperations, value) as string;
    // console.log(key);
    // console.log('thisssss', props.dataForChoosingAllowedOperations, value);
    setAllowedOperations([...props.dataForChoosingAllowedOperations[value].allowedOperationsList]);
    setSelectedFieldType(props.dataForChoosingAllowedOperations[value].type);
    setValidValuesAvailable(
      props.dataForChoosingAllowedOperations[value].validValues
        ? props.dataForChoosingAllowedOperations[value].validValues
        : null
    );
    setSelectedField(value);
    // console.log(props.dataForChoosingAllowedOperations[value].type, value);
  };

  const closeModal = (condition: any) => {
    if (condition) {
      // console.log(condition, selectedAggregationIndex);
      props.conditionsChangeHandler(condition, parentIndex, selectedAggregationIndex);
      // setConditionModal(false);

      // setConditionsOnField([...condition]);
    } else {
      props.conditionsChangeHandler(null);
      // console.log(condition);
      // setConditionModal(false);
    }
  };

  return (
    <>
      <BasicModal
        height={550}
        width={800}
        open={isOpen}
        onClose={() => {
          setIsOpen((prevState) => !prevState);
        }}
      >
        <Stack mx={1} my={1} sx={{ position: 'relative' }}>
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            position={'fixed'}
            sx={{ width: '93%', zIndex: '3', background: 'white' }}
          >
            <Typography variant="h2">{title}</Typography>
            <CognisaasButton
              label="Add Aggregation"
              sx={{ marginLeft: '0px' }}
              clickHandler={() => {
                addAggregation(parentIndex);
              }}
              isOutlined={false}
            />
          </Stack>

          {data?.aggregations.length === 0 ? (
            <Stack sx={{ height: '250px' }}>
              <NoRecordsFound />
              <Typography variant="h2" className="label-text" sx={{ marginTop: '-24px', textAlign: 'center' }}>
                No aggregations added
              </Typography>
            </Stack>
          ) : (
            <Stack mt={6} sx={{ maxHeight: '60vh', overflow: 'scroll' }}>
              {data?.aggregations.map((field: any, index: number) => {
                // console.log(field);
                // let columnName: string = field.columnName;
                const [columnName, setColumnName] = useState<string>(field.columnName);
                return (
                  <Stack
                    key={index}
                    sx={
                      {
                        // border: '1px solid rgba(45,96,246,0.59)',
                        // borderRadius: '4px',
                        // boxShadow: '2px 3px 6px -3px rgba(45,96,246,0.59)',
                        // width: '98%',
                      }
                    }
                    mb={1}
                  >
                    <Accordion
                      disableGutters
                      onChange={(event: React.SyntheticEvent, expanded: boolean) => {
                        if (expanded) {
                          setSelectedAggregationIndex(index);
                          setConditionsOnField([...field.conditions]);
                          // props.currentEntityIndex >= props.topEntityIndex ? setConditionModal(true) : null;
                          makeDataForAllowedOperation(field.fieldName);
                        }
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{ margin: '0px' }}
                      >
                        <Stack direction="row" spacing={1} key={index} alignItems={'center'}>
                          <TextField
                            key={index}
                            size="small"
                            placeholder={'Enter Column Name'}
                            required={true}
                            value={columnName}
                            onChange={(e: any) => {
                              setColumnName(e.target.value);
                            }}
                            onBlurCapture={(e: any) => {
                              columnNameChangeHandler(e.target.value, field.fieldName, parentIndex, index);
                            }}
                          />
                          <CognisaasSelect
                            // width="310px"
                            name="field"
                            value={currentEntityIndex < topEntityIndex ? 'nothing' : field.aggregationType}
                            onChange={(e: any) =>
                              props.operationTypeChange(e.target.value, field.fieldName, parentIndex, index)
                            }
                            validValues={currentEntityIndex < topEntityIndex ? ['nothing'] : [...allowedOperations]}
                          />
                          <TextField
                            disabled={currentEntityIndex < topEntityIndex}
                            className={'cognisaas-pointer'}
                            size="small"
                            placeholder={'Add Condition'}
                            required={true}
                            value={`${field.conditions.length} ${
                              field.conditions.length == 1 ? 'condition' : 'conditions'
                            } applied`}
                            //   onClick={() => {
                            //     setSelectedAggregationIndex(index);
                            //     setConditionsOnField([...field.conditions]);
                            //     currentEntityIndex >= topEntityIndex ? setConditionModal(true) : null;
                            //     makeDataForAllowedOperation(field.fieldName);
                            //   }}
                          />
                          <IconButton onClick={() => removeField(parentIndex, index)} aria-label="delete">
                            <DeleteIcon />
                          </IconButton>
                        </Stack>
                      </AccordionSummary>
                      <AccordionDetails>
                        <AddConditionModal
                          allColumns={dataForChoosingAllowedOperations}
                          validValues={validValuesAvailable}
                          onClose={closeModal}
                          field={selectedField}
                          type={selectedFieldType}
                          conditionsApplied={conditionsOnField}
                        />
                      </AccordionDetails>
                    </Accordion>
                  </Stack>
                );
              })}
            </Stack>
          )}
        </Stack>
      </BasicModal>
    </>
  );
};

export default AddAggregationModal;
