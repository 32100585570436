import styled from 'styled-components';

import { FlexBox, Box, Text } from 'components/ResourceManagement/design-system/primitives';
import { Component } from 'components/ResourceManagement/types/public-types';
import { getUserLetters, stringToHslColor } from 'components/ResourceManagement/utils/transformers';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectMainViewData, setProjectViewModalState, setSelectedProjectById, setSelectedResourceById, setSelectedTaskById, setUpdateTaskDrawerState } from 'store/modules/ResourceManagement';
const StyledProjectText = styled(Text)``;
const StyledMemberText = styled(Text)``;
const StyledProjectNameTile = styled(Box) <{
    itemNameLetters: string
}>`
    ${({ theme, itemNameLetters }) => {
        return `
        background-color: ${stringToHslColor(itemNameLetters, 30, 80)};
        color: ${theme.colors.grey_1};
        font-size: ${theme.fontSizes[6]};
        font-weight: ${theme.fontWeights[2]};
        border-radius: 50%;
        width: ${theme.space[16]};
        height: ${theme.space[16]};
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        `;
    }}
    `;
const StyledProjectBox = styled(Box)(({ theme }) => {
    return {
        display: 'grid',
        gridTemplateColumns: 'auto auto auto',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gridGap: `${theme.space[5]}`,
    };
});
const StyledColorTile = styled(Box) <{
    itemNameLetters: string
}>`
        ${({ itemNameLetters }) => {
        return `
        width: 5px;
        height: 40px;
        background-color: ${stringToHslColor(itemNameLetters, 30, 60)};
        border-radius: 15%;
        justify-self: center;
      `;
    }}
    `;

export const ProjectTile = ({ rowHeight, item, isItemAvailable }: { rowHeight: number, item: Component, isItemAvailable: boolean }) => {
    const dispatch = useAppDispatch();
    const selectedMainViewData = useAppSelector(selectMainViewData);
    const itemNameLetters: string = item.name !== undefined ? getUserLetters(item.name) : 'N/A';
    const textVariant = isItemAvailable ? 'link' : 'paragraph1';

    const itemClickedFunc = ({ id, entity }: { id: string, entity: string }) => {
        if (entity === 'project') {
            const projectId = id;
            const projectObj = selectedMainViewData?.events?.length ? selectedMainViewData.events.find((item: any) => item.id === projectId) : null;
            if (projectObj) {
                const resourceId = projectObj?.componentLinkedTo;
                dispatch(setSelectedResourceById({ resourceId }));
            }
            dispatch(setSelectedProjectById({ projectId }));
            dispatch(setProjectViewModalState(true));
        } else if (entity === 'task') {
            const taskId = id;
            dispatch(setSelectedTaskById({ taskId }));
            dispatch(setUpdateTaskDrawerState(true));
        }
    };

    return (
        <>
            <FlexBox variant='flexRow' alignItems='center' justifyContent='flex-start' mx={20} height={rowHeight}>
                <StyledProjectBox title={item.name}>
                    <StyledColorTile itemNameLetters={itemNameLetters} />
                    <StyledProjectNameTile itemNameLetters={itemNameLetters}>
                        <StyledMemberText variant="paragraph2">{itemNameLetters}</StyledMemberText>
                    </StyledProjectNameTile>
                    <StyledProjectText variant={textVariant} onClick={() => itemClickedFunc(item)}>{item.name}</StyledProjectText>
                </StyledProjectBox>
            </FlexBox>
        </>
    );
};
