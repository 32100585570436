import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
  Stack,
  Grid,
  Typography,
  ButtonGroup,
  Button,
  SelectChangeEvent,
  MenuItem,
  ListItemText,
  Checkbox,
  FormControl,
  Select,
  Autocomplete,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useAppSelector } from 'store/hooks';
import {
  getAllConditions_selector,
  getAllResources_selector,
  getGeneralFieldValues_selector,
  getPolicyOnResources_selector,
  getResourcesFieldValues_selector,
  getResourcesField_selector,
} from 'store/modules/RBAC/selector';
import {
  addConditionsToPolicy_slice,
  getAllConditions_slice,
  getAllResources_slice,
  getGeneralFieldValue_slice,
  getPolicyOnResources_slice,
  getResourcesField_slice,
} from 'store/modules/RBAC/slice';
import CognisaasDropdown from 'components/shared/CognisaasDropdown/CognisaasDropdown';
import { requestState } from 'store/modules/types';
import CognisaasSpinner from 'components/shared/CognisaasSpinner/CognisaasSpinner';
import ConditionDetail from 'components/RBACv2/shared/ConditionDetail';
import { PolicyProps } from 'components/RBACv2/interfaces';

export default function Policy(props: PolicyProps) {
  const [effectActiveButton, setEffectActiveButton] = useState<number>(0);
  const [resourcesDropdown, setResourcesDropdown] = useState<string>('');
  const [allResources, setAllResources] = useState<string[]>([]);
  const [selectedAccessDropdown, setSelectedAccessDropdown] = useState<any[]>([]);
  const [autoCompleteValue, setAutoCompleteValue] = useState<string>('');
  const [selectedConditions, setSelectedConditions] = useState<any[]>([]);
  //   const [conditions, setConditions] = useState<any[]>(props.selectedPolicy?.conditions);
  const [resourceField, setResourceField] = useState<string>('');
  const [fieldValue, setFieldValue] = useState<string>('');

  const getAllResources = useAppSelector(getAllResources_selector).data;
  const getPolicyOnResources = useAppSelector(getPolicyOnResources_selector).data;
  const getAllConditions = useAppSelector(getAllConditions_selector).data;
  const getAllConditionsStatus = useAppSelector(getAllConditions_selector).status;
  const getResourcesField = useAppSelector(getResourcesField_selector).data;
  const getGeneralFieldValues = useAppSelector(getGeneralFieldValues_selector).data;
  const getResourcesFieldValues = useAppSelector(getResourcesFieldValues_selector).data;

  const dispatch = useDispatch();

  const isCheckBoxChecked = (option: any) => {
    // To check if access values are checked or not
    const temp = selectedAccessDropdown?.filter((obj: any) => {
      return obj?.id === option.id;
    });
    return temp?.length > 0;
  };

  useEffect(() => {
    dispatch(getAllResources_slice());
    dispatch(getGeneralFieldValue_slice());
    setSelectedConditions(props.selectedPolicy?.conditions || []);
  }, [props.selectedPolicy]);

  useEffect(() => {
    const temp = getAllResources?.map((obj: any) =>  obj.display_name);
    const resourceOnPolicy = getAllResources?.find((resource:any) => resource.id === props.selectedPolicy.resource.id);
    setAllResources(temp);
    setResourcesDropdown(resourceOnPolicy?.display_name || 'Select Option');
  }, [getAllResources]);

  useEffect(() => {
    const resourceFieldId = getAllResources?.filter((obj: any) => {
      return obj.display_name === resourcesDropdown;
    });
    if (resourceFieldId.length > 0) {
      dispatch(getPolicyOnResources_slice({ resource: resourceFieldId[0]?.id }));
      dispatch(getAllConditions_slice({ resource: resourceFieldId[0]?.id }));
      dispatch(getResourcesField_slice({ resource: resourceFieldId[0]?.id || '' }));
    }
  }, [resourcesDropdown]);

  const onClickAutoCompleteDropdown = async (e: React.SyntheticEvent, value: any) => {
    const condition = getAllConditions?.find((condition: any) => {
      return condition?.id === value.id;
    });
    const selectedCondition = selectedConditions?.find((condition: any) => {
      return condition?.id === value.id;
    }); // checks if condition is already added to the policy

    if (condition && selectedCondition === undefined) {
      setSelectedConditions((prevState) => [...prevState, condition]);
    }
    const payload = {
      policyId: props?.selectedPolicy?.id,
      conditionIds: [condition?.id],
    };
    setAutoCompleteValue('');
    await dispatch(addConditionsToPolicy_slice(payload));
  };

  useEffect(() => {
    if (getPolicyOnResources) {
      setSelectedAccessDropdown(getPolicyOnResources[0]?.resource?.actions || []);
    }
  }, [getPolicyOnResources]);

  return (
    <Stack>
      <Grid sx={{ marginTop: '35px' }}>
        <Typography variant={'h2'}>Effect </Typography>
        <ButtonGroup sx={{ marginTop: '15px' }} variant="outlined" aria-label="outlined button group">
          <Button
            className={effectActiveButton === 0 ? 'active-btn' : undefined}
            onClick={() => setEffectActiveButton(0)}
          >
            <Typography variant={'subtitle1'}>Allow</Typography>
          </Button>
          <Button
            className={effectActiveButton === 1 ? 'active-btn' : undefined}
            onClick={() => setEffectActiveButton(1)}
          >
            <Typography variant={'subtitle1'}>Deny</Typography>
          </Button>
        </ButtonGroup>
      </Grid>
      <Grid sx={{ marginTop: '25px' }}>
        <Typography variant={'h2'}>Resources</Typography>
        <CognisaasDropdown
          sx={{ marginTop: '10px' }}
          name="resources-dropdown"
          type="dropdown"
          validvalues={allResources}
          onChange={(e: SelectChangeEvent<unknown | string>) => setResourcesDropdown(e.target.value as string)}
          value={resourcesDropdown}
          readOnly={props?.selectedPolicy?.is_public || false}
        />
      </Grid>
      <Grid sx={{ marginTop: '20px' }}>
        <Typography variant={'h2'}>Access</Typography>
        <FormControl>
          <Select
            sx={{ marginTop: '10px', height: '40px' }}
            multiple
            value={selectedAccessDropdown || []}
            placeholder="Select"
            onChange={(event: SelectChangeEvent<any>) => setSelectedAccessDropdown(event.target.value)}
            renderValue={(selected: any) => {
              const temp = selected?.map((obj: any) => {
                return obj?.name + ', ';
              });
              return temp;
            }}
            readOnly={props?.selectedPolicy?.is_public || false}
          >
            {getPolicyOnResources[0]?.resource?.actions?.map((obj: any) => (
              <MenuItem key={obj.id} value={obj}>
                <Checkbox checked={isCheckBoxChecked(obj)} />
                <ListItemText primary={obj.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid sx={{ marginTop: '20px', marginBottom: '20px' }}>
        <Typography variant={'h2'} marginBottom="10px">
          Add Condition{' '}
        </Typography>
        <Autocomplete
          size="small"
          options={getAllConditions}
          value={autoCompleteValue}
          getOptionLabel={(option: any) => option['name'] || ''}
          renderInput={(params: any) => {
            return <TextField {...params} placeholder="Search condition..." />;
          }}
          onChange={onClickAutoCompleteDropdown}
          readOnly={props?.selectedPolicy?.is_public || false}
        />
        {getAllConditionsStatus === requestState.loading ? (
          <Stack style={{ marginTop: '20px' }}>
            <CognisaasSpinner />
          </Stack>
        ) : (
          selectedConditions.map((condition: any) => (
            <Accordion
              elevation={0}
              key={condition.id}
              style={{ marginTop: '15px', boxShadow: 'none', border: '1px solid #E5E5E5' }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography variant="h2">{condition.name}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ConditionDetail
                  conditionData={condition}
                  resourceName={resourcesDropdown}
                  resourcesFieldObject={getResourcesField}
                  selectedPermission={props.selectedPolicy}
                  selectedOperator={condition.operator}
                  resourceField={resourceField}
                  setResourceField={setResourceField}
                  conditionResourceGeneralFieldValue={getGeneralFieldValues}
                  conditionResourceFieldValue={getResourcesFieldValues}
                  fieldValue={fieldValue}
                  setFieldValue={setFieldValue}
                />
              </AccordionDetails>
            </Accordion>
          ))
        )}
      </Grid>
    </Stack>
  );
}
