import { Avatar, Box, Grid, Paper, Stack, Typography } from '@mui/material';
import {
  label_arr,
  label_client,
  label_use_case,
  label_use_case_delivery_manager,
} from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { useEffect, useState } from 'react';
import { ReactComponent as NoRecordsIcon } from '../../../assets/svgs/no_records_mini.svg';
import { DataGridPremium, GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium';
import { useAppSelector } from 'store/hooks';
import { Link } from 'react-router-dom';
import { getUserLogoLinkByUid, getUserUid } from 'utils/getUid';
import { default as AvatarImage } from '../../shared/Avatar/Avatar';
import { selectCustomerDetail } from 'store/modules/Common/selector';
import useCustomNames from 'components/shared/CustomNames/useCustomNames';

interface Props {
  data: any[];
  name: string;
  category: 'Land' | 'Expand';
  arr: number;
  dependentClientCount: number;
  type: 'usecase' | 'project';
}
function stringToHslColor(str: string, s: number, l: number) {
  let hash = 0;

  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const h = hash % 360;
  return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToHslColor(name, 30, 80),
    },
    children: (
      <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#000000' }}>
        {name.split(' ')[0][0]}
        {name.indexOf(' ') >= 0 ? name.split(' ')[1][0] : null}
      </Typography>
    ),
  };
}
const MappedClientView = (props: Props) => {
  const [filteredData, setFilteredData] = useState<any[]>(props.data);
  const customerDetail = useAppSelector(selectCustomerDetail);
  // let arr = 0;
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: `${customerDetail?.currency_code}`,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  const { displayDataWithCustomNames } = useCustomNames();

  const columnsUsecaseClientMapping: GridColDef[] = [
    {
      field: 'client_name',
      headerName: `${label_client} Name`,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <Link state={{ clientName: params.row.client_name }} to={`/client-list/client/${params.row.client_uid}`}>
          {params.value}
        </Link>
      ),
    },
    {
      field: 'case_name',
      headerName: `${label_use_case} Name`,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <Link state={{ clientName: params.row.client_name }} to={`/client-list/client/${params.row.client_uid}`}>
          {params.value}
        </Link>
      ),
    },
    {
      field: 'case_deliverymanager',
      headerName: label_use_case_delivery_manager,
      width: 240,
      renderCell: (params: GridRenderCellParams) => {
        const userUid = getUserUid(params.row.case_deliverymanager);
        const deliveryManagerProfileLink = getUserLogoLinkByUid(userUid);
        return (
          <Stack direction="row">
            {deliveryManagerProfileLink.length > 0 ? (
              <AvatarImage src={deliveryManagerProfileLink} height="34px" width="34px" alt="Delivery manager profile" />
            ) : (
              <Avatar style={{ height: '34px', width: '34px', marginTop: '3px' }} {...stringAvatar(params.value)} />
            )}

            <Typography m={1} variant="subtitle1">
              {params.value}
            </Typography>
          </Stack>
        );
      },
    },
    {
      headerName: `${label_client} Status`,
      field: 'case_readinessstate_status',
      // width: 185,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <Stack>
          <Stack spacing={1} direction="row">
            <span
              style={{ marginRight: '4px' }}
              className={
                params.value == 'red'
                  ? 'red_status_box_task'
                  : params.value == 'amber'
                  ? 'amber_status_box_task'
                  : params.value == 'green'
                  ? 'green_status_box_task'
                  : params.value == 'blue'
                  ? 'blue_status_box_task'
                  : params.value == 'grey'
                  ? 'grey_status_box_task'
                  : ''
              }
            ></span>
            {params.value == 'red'
              ? 'Overdue'
              : params.value == 'amber'
              ? 'At Risk'
              : params.value == 'green'
              ? 'On Track'
              : params.value == 'blue'
              ? 'Completed'
              : params.value == 'grey'
              ? 'Deferred'
              : ''}
          </Stack>
        </Stack>
      ),
    },
    {
      field: 'client_account_worth',
      headerName: `${label_arr}`,
      groupable: false,
      flex: 1,
      valueFormatter: ({ value }) => {
        if (!value) {
          return value;
        }
        return currencyFormatter.format(value);
      },
    },
    {
      field: 'case_stage',
      headerName: 'Stage',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <Typography variant="subtitle1">{params.value}</Typography>,
    },
  ];
  useEffect(() => {
    setFilteredData(props.data);
  }, [props]);

  return (
    <Stack>
      <Stack sx={{ margin: '0px 20px 10px 20px' }} direction={'row'} spacing={1}>
        <Typography variant="h2">{label_client}s</Typography>
        <Typography variant="subtitle2">
          <Box
            component={'span'}
            sx={{
              width: '21px',
              height: '21px',
              borderRadius: '50%',
              background: '#7B7B7C',
              padding: '2px',
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: 'white',
            }}
          >
            {props.dependentClientCount}
          </Box>
        </Typography>
      </Stack>

      <Grid container sx={{ margin: '0px 20px 24px 20px' }}>
        <Grid item xs={6}>
          <Stack>
            <Typography variant="subtitle2" className={'label-text'}>
              {props.type === 'usecase' ? label_use_case : 'Product'}
            </Typography>
            <Typography variant="subtitle1">{props.name}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack>
            <Typography variant="subtitle2" className={'label-text'}>
              Category
            </Typography>
            <Typography variant="subtitle1">{props.category}</Typography>
          </Stack>
        </Grid>
      </Grid>

      <Grid container sx={{ margin: '0px 20px 0px 20px' }}>
        <Grid item xs={6}>
          <Stack>
            <Typography variant="subtitle2" className={'label-text'}>
              Total {label_arr}
            </Typography>
            <Typography variant="subtitle1">{currencyFormatter.format(props.arr)}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack>
            <Typography variant="subtitle2" className={'label-text'}>
              Depended {label_client}s
            </Typography>
            <Typography variant="subtitle1">{props.dependentClientCount}</Typography>
          </Stack>
        </Grid>
      </Grid>

      {/* clients list */}
      <Paper elevation={0} sx={{ overflowY: 'scroll', maxHeight: '62vh', overflowX: 'clip' }}>
        <Stack m={2}>
          {filteredData.length !== 0 && (
            <DataGridPremium
              sx={{ borderRadius: 0 }}
              columns={columnsUsecaseClientMapping}
              rows={displayDataWithCustomNames(filteredData)}
              getRowId={(row) => row.case_uid}
              hideFooterRowCount
              disableSelectionOnClick
              autoHeight
            />
          )}
          {filteredData.length === 0 && (
            <Stack alignItems={'center'} justifyContent={'center'} sx={{ margin: '20px 0px' }}>
              <Typography variant="subtitle1" className="label-text" sx={{ marginBottom: '20px' }}>
                No Records Found!
              </Typography>
              <NoRecordsIcon />
            </Stack>
          )}
        </Stack>
      </Paper>
    </Stack>
  );
};

export default MappedClientView;
