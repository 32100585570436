import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { requestState } from '../../types';
import { isNull } from '../../../../utils/dataHelpers';
import { phaseAPI } from '../../../../api/modules/Phase';

interface StateProps {
  phaseDetailsByCustomerUid: {
    data: any;
    status: requestState;
    error: null | undefined | string;
  };
}

const initialState: StateProps = {
  phaseDetailsByCustomerUid: {
    data: [],
    status: requestState.idle,
    error: null,
  },
};

export const getPhasesDetailsByCustomerUid = createAsyncThunk(
  'phase/getPhasesDetailsByCustomerUid_createAsyncThunk',
  async () => {
    const { data } = await phaseAPI.getPhasesDetailsByCustomerUid();
    console.log(data);
    return data.result ?? [];
  }
);

const allPhasesSlice = createSlice({
  name: 'allPhases',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder

      // phases -> getPhasesDetailsByCustomerUid
      .addCase(getPhasesDetailsByCustomerUid.pending, (state) => {
        state.phaseDetailsByCustomerUid.status = requestState.loading;
      })
      .addCase(getPhasesDetailsByCustomerUid.fulfilled, (state, action) => {
        state.phaseDetailsByCustomerUid.status = requestState.success;
        console.log('phase details  = ', action.payload);
        if (isNull(action.payload)) state.phaseDetailsByCustomerUid.data = '';
        else state.phaseDetailsByCustomerUid.data = action.payload;
      })
      .addCase(getPhasesDetailsByCustomerUid.rejected, (state, action) => {
        state.phaseDetailsByCustomerUid.status = requestState.failed;
        state.phaseDetailsByCustomerUid.error = action.error.message;
      });
  },
});

export const phaseReducer = allPhasesSlice.reducer;
export default phaseReducer;
