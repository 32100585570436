import { ReactElement, useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { withStyles } from '@mui/styles';

interface tabsProps {
  tabs: string[];
  selectedTabIndex?: number;
  handleTabSwitch: (event: any, tabIndex: any) => void;
  tabComponent?: ReactElement;
  tabComponentIndex?: number;
  size?: 'squeezed';
}

const cognisaasTabs = (prop: tabsProps) => {
  // console.log(prop);

  const handleChange = (event: React.SyntheticEvent, tabIndex: number) => {
    // const input = event.target as HTMLElement;
    // console.log(input.textContent, tabIndex);
    prop.handleTabSwitch(event, tabIndex);

    setValue(tabIndex);
  };

  const CustomTab = withStyles({
    root: {
      // textTransform: 'none',
      minHeight: '60px',
      padding: '0px',
    },
  })(Tab);

  const [value, setValue] = useState(prop.selectedTabIndex ? prop.selectedTabIndex : 0);

  useEffect(() => {
    setValue(prop.selectedTabIndex ? prop.selectedTabIndex : 0);
  }, [prop.selectedTabIndex]);
  {
    /* {index === prop.tabComponentIndex ? prop.tabComponent :  ''} */
  }

  console.log({ prop });
  return (
    <Box
      sx={
        prop.size === 'squeezed'
          ? { width: 'fit-content', position: 'relative', top: '-20px', marginBottom: '-20px' }
          : { position: 'relative', top: '-20px', marginBottom: '-20px' }
      }
    >
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          {prop.tabs.map((tab: string, index: number) => (
            // <div              key={index}>
            <CustomTab
              key={index}
              value={index}
              icon={index === prop.tabComponentIndex ? prop.tabComponent : ''}
              iconPosition="end"
              label={tab}
              sx={index === prop.tabs.length - 1 ? { marginRight: '2px' } : { marginRight: '32px' }}
            />
            // </div>
          ))}
        </Tabs>
      </Box>
    </Box>
  );
};

export default cognisaasTabs;
