import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../index';

const selectAttachmentState = (state: RootState) => state.attachmentState;
// export const selectAttachmentQueueState = createSelector(selectAttachmentState, (selectattachmentState) =>())
export const selectAttachmentQueue = createSelector(
  selectAttachmentState,
  (selectAttachmentState) => selectAttachmentState.attachmentQueue
);

export const selectAttachmentOldVersion = createSelector( selectAttachmentState,
  (selectAttachmentState) => selectAttachmentState.attachmentOldVersions);
export const selectAttachmentDataDownloadQueue = createSelector(
  selectAttachmentState,
  (selectAttachmentState) => selectAttachmentState.attachmentDataDownloadQueue
);
export const selectAttachmentDownloading = createSelector(
  selectAttachmentState,
  (selectAttachmentState) => selectAttachmentState.downloadAttachment
);

export const selectDeleteAttachment = createSelector(
  selectAttachmentState,
  (selectAttachmentState) => selectAttachmentState.deleteAttachmentFromServer
);

export const selectAttachmentCountInUploadQueue = createSelector(
  selectAttachmentState,
  (selectAttachmentState) => selectAttachmentState.attachmentInUploadQueueCount
);
