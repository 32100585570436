import { apis } from '../axiosInstance';
import { settingRoutes } from '../route';

export const getNotificationData = (payload: any) =>
  apis.phpAxiosInstance.post(settingRoutes.getNotificationData(), payload, {
    headers: { JWT: 'true' },
  });

export const settingsAPI = {
  getNotificationData,
};
