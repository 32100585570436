import { useEffect, useState } from 'react';
import { Autocomplete, Box, Stack, TextField, Typography, Avatar } from '@mui/material';
import CognisaasTabs from '../../shared/CognisaasTabs';
import ReportsHeader, { ReportsPages } from '../ReportsHeader/ReportsHeader';
import { selectReportsClients, selectReportsClientsStatus } from '../../../store/modules/Reports/Clients/selector';
import { fetchAllClients } from '../../../store/modules/Reports/Clients/slice';
import { useAppSelector, useAppDispatch } from '../../../store/hooks';
import UpdateTaskDrawer from 'components/TaskModule/TaskView/UpdateTaskDrawer';
import {
  selectReportsAllProjects,
  selectReportsAllProjectsStatus,
} from '../../../store/modules/Reports/AllProjects/selector';
import {
  selectReportsUsecases,
  selectReportsUsecasesStatus,
} from '../../../store/modules/Reports/AllUsecases/selector';
import { useNavigate } from 'react-router-dom';
import { requestState } from '../../../store/modules/types';
import CognisaasMultiBarGraph from '../../shared/CognisaasGraph/CognisaasMultiBarGraph';
import { fetchAllProjects } from '../../../store/modules/Reports/AllProjects/slice';
import { fetchAllUsecases } from '../../../store/modules/Reports/AllUsecases/slice';
import CognisaasLoader from '../../shared/CognisaasLoader/CognisaasLoader';
import { getLoggedInUserName, getUserNameByUserUid } from 'utils/getUid';
import CogniTable, { CostCell, linkCell, StatusCell } from 'components/shared/Tables/CogniTable';
import { formatCurrency, generateOrderedColumn, isNull, isUndefined } from 'utils/dataHelpers';
import FilterTypeSelector from 'components/shared/CognisaasFilters/FilterTypeSelector/FilterTypeSelector';
import SelectedFilterList from 'components/shared/CognisaasFilters/SelectedFilterList/SelectedFilterList';
import { addFilterGroup } from 'store/modules/AllFilters/slice';
import { availabeTimePeriodOptions, FilterableData } from 'utils/filteringFunctions';
import { selectAllFilters } from 'store/modules/AllFilters/selector';
import { selectOpenTasks, selectOpenTasksStatus } from 'store/modules/Reports/OpenTasks/selector';
import { selectClosedTasks, selectClosedTasksStatus } from 'store/modules/Reports/ClosedTasks/selector';
import { getOpenTasks } from 'store/modules/Reports/OpenTasks/slice';
import { getClosedTasks } from 'store/modules/Reports/ClosedTasks/slice';
import {
  selectAllCategories,
  selectAllGeneralStatus,
  selectAllIndustries,
  selectCustomClientJourneyStage,
  selectJourneyStage,
  selectSegmentValues,
  selectUsersOfCompany,
  selectUsersOfCompanyStatus,
} from 'store/modules/Common/selector';
import { getTaskByTaskUid } from 'store/modules/Task/slice';
import {
  fetchAllCustomJourneyStages,
  fetchAllIndustries,
  fetchAllJourneyStages,
  getAllSegmentValues,
  getAllUsers,
} from 'store/modules/Common/slice';
import { selectCustomerDetails } from 'store/modules/Settings/selector';
import { getCustomerDetails } from 'store/modules/Settings/slice';
import { GridColumnOrderChangeParams, GridRenderCellParams } from '@mui/x-data-grid-premium';
import ViewDate from 'components/shared/ViewDate/ViewDate';
import {
  label_arr,
  label_client,
  label_cs_owner,
  label_delivery_date,
  label_due_date,
  label_project,
  label_project_delivery_manager,
  label_sales_owner,
  label_task,
  label_use_case,
} from 'components/Settings/Preferences/CustomLabelCookieHelper';
import useCustomNames from 'components/shared/CustomNames/useCustomNames';
import { fetchClientList } from 'store/modules/ManageClient/ClientList/slice';
import { selectClientList } from 'store/modules/ManageClient/ClientList/selector';
import checkAccessForRouting from 'components/shared/Hooks/checkAccessForRouting';
import CognisaaasTooltip from 'components/shared/CognisaasTooltip/CognisaasTooltip';

const Resources = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const tabs = [`${label_client}s`, `${label_project}s`, `${label_use_case}s`, `${label_task}s`];
  const [selectedTab, setSelectedTab] = useState(0);
  const [updateTaskDrawer, setUpdateTaskDrawer] = useState<boolean>(false);
  const customJourneyStages = useAppSelector(selectCustomClientJourneyStage);
  const [openTaskWithDetail, setOpenTaskWithDetail] = useState<any>(null);
  const [value, setValue] = useState<string | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [clientListColumns, setClientListColumns] = useState<any[]>([]);
  const [projectListColumns, setProjectListColumns] = useState<any[]>([]);
  const [usecaseListColumns, setUsecaseListColumns] = useState<any[]>([]);
  const [taskListColumns, setTaskListColumns] = useState<any[]>([]);

  const clients = useAppSelector(selectReportsClients);
  const clientsState = useAppSelector(selectReportsClientsStatus);
  const [filteredClients, setFilteredClients] = useState<any[]>([]);
  const allProjects = useAppSelector(selectReportsAllProjects);
  const allProjectsState = useAppSelector(selectReportsAllProjectsStatus);
  const [filteredProjects, setFilteredProjects] = useState<any[]>([]);

  const allUsecases = useAppSelector(selectReportsUsecases);
  const allUsecasesState = useAppSelector(selectReportsUsecasesStatus);
  const [filteredUsecase, setFilteredUsecase] = useState<any[]>([]);

  const openTasks = useAppSelector(selectOpenTasks);
  const openTasksStatus = useAppSelector(selectOpenTasksStatus);

  const closedTasks = useAppSelector(selectClosedTasks);
  const closedTasksStatus = useAppSelector(selectClosedTasksStatus);

  const [allTasks, setAllTasks] = useState<any[]>([]);
  const [filteredTasks, setFilteredTasks] = useState<any[]>([]);

  const allFilters = useAppSelector(selectAllFilters);
  const allUsers = useAppSelector(selectUsersOfCompany);
  const allUsersStatus = useAppSelector(selectUsersOfCompanyStatus);
  const allSegments = useAppSelector(selectSegmentValues);
  const journeyStages = useAppSelector(selectJourneyStage);
  const allIndustries = useAppSelector(selectAllIndustries);
  const allGeneralStatus = useAppSelector(selectAllGeneralStatus);
  const allCategories = useAppSelector(selectAllCategories);
  const clientList = useAppSelector(selectClientList);
  const customerDetail = useAppSelector(selectCustomerDetails).data;

  const uniqueClientColumnId = 'reportResourceClientListTable';
  const uniqueProjectColumnId = 'reportResourceProjectListTable';
  const uniqueUsecaseColumnId = 'reportResourceUsecaseListTable';
  const uniqueTaskColumnId = 'reportResourceTaskListTable';
  const { displayDataWithCustomNames } = useCustomNames();

  function handleTabSelection(event: any, tabIndex: number) {
    setSelectedTab(tabIndex);
  }

  function stringToHslColor(str: string, s: number, l: number) {
    let hash = 0;

    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    const h = hash % 360;
    return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
  }

  function stringAvatar(name: string) {
    return {
      sx: {
        bgcolor: stringToHslColor(name, 30, 80),
      },
      children: (
        <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#000000' }}>
          {name.split(' ')[0][0]}
          {name.indexOf(' ') >= 0 ? name.split(' ')[1][0] : null}
        </Typography>
      ),
    };
  }

  const getTaskDetail = async (task: any) => {
    const taskResult = await dispatch(getTaskByTaskUid(task.task_uid)).unwrap();
    setOpenTaskWithDetail({ ...taskResult, client_uid: task.client_uid });
    setUpdateTaskDrawer(!updateTaskDrawer);
  };

  useEffect(() => {
    dispatch(fetchAllClients());
    dispatch(fetchClientList());
    dispatch(fetchAllProjects());
    dispatch(getOpenTasks());
    dispatch(getClosedTasks());
    dispatch(fetchAllUsecases());
    dispatch(getCustomerDetails());
    dispatch(fetchAllCustomJourneyStages());
    setClientListColumns(generateOrderedColumn(clientListTableColumns, uniqueClientColumnId));
    setProjectListColumns(generateOrderedColumn(projectTableColumns, uniqueProjectColumnId));
    setUsecaseListColumns(generateOrderedColumn(usecaseTableColumns, uniqueUsecaseColumnId));
    setTaskListColumns(generateOrderedColumn(taskColumns, uniqueTaskColumnId));
  }, []);

  useEffect(() => {
    if (allUsersStatus !== 'success') {
      dispatch(getAllUsers());
    }
    if (allSegments.status !== 'success') {
      dispatch(getAllSegmentValues());
    }

    if (journeyStages.status != 'success') {
      dispatch(fetchAllJourneyStages());
    }

    if (allIndustries.status != 'success') {
      dispatch(fetchAllIndustries());
    }
  }, [allUsersStatus, allSegments, journeyStages, allIndustries]);

  useEffect(() => {
    if (clients.length > 0) {
      if (isUndefined(allFilters['resourceClientsReportsPage']) || isNull(allFilters['resourceClientsReportsPage'])) {
        if (
          allUsersStatus === 'success' &&
          allSegments.status == 'success' &&
          journeyStages.status == 'success' &&
          customJourneyStages.status == 'success' &&
          allIndustries.status == 'success'
        ) {
          dispatch(
            addFilterGroup({
              idName: 'resourceClientsReportsPage',
              appliedFilters: [
                {
                  name: label_sales_owner,
                  dataKey: 'client_sales_owner',
                  isSelected: false,
                  equalToValue: [],
                  canBeCleared: true,
                  availableOptions: allUsers.map((item: any) => item.user_username),
                },
                {
                  name: 'Status',
                  dataKey: 'client_readiness_status',
                  isSelected: false,
                  equalToValue: [],
                  canBeCleared: true,
                  availableOptions: allGeneralStatus.data,
                },
                {
                  name: 'Stage',
                  dataKey: 'stage_journerystage',
                  isSelected: false,
                  equalToValue: [],
                  canBeCleared: true,
                  availableOptions:
                    customJourneyStages.data.length == 0
                      ? journeyStages.data.map((item: any) => item.stage_journerystage)
                      : customJourneyStages.data.map((item: any) => item.stage_journerystage),
                },
                {
                  name: 'Segment',
                  dataKey: 'segment_name',
                  isSelected: false,
                  equalToValue: [],
                  canBeCleared: true,
                  availableOptions: allSegments.data.map((item: any) => item.segment_name),
                },
                {
                  name: 'Industry',
                  dataKey: 'industry_name',
                  isSelected: false,
                  equalToValue: [],
                  canBeCleared: true,
                  availableOptions: allIndustries.data.map((item: any) => item.industry_name),
                },
              ],
            })
          );
        }
      }
    }
  }, [clients, allUsersStatus, allSegments, journeyStages, allIndustries]);

  useEffect(() => {
    if (allProjects.length > 0) {
      if (isUndefined(allFilters['resourceProjectReportsPage']) || isNull(allFilters['resourceClientsReportsPage'])) {
        if (
          allUsersStatus === 'success' &&
          allSegments.status == 'success' &&
          journeyStages.status == 'success' &&
          allIndustries.status == 'success'
        ) {
          dispatch(
            addFilterGroup({
              idName: 'resourceProjectReportsPage',
              appliedFilters: [
                {
                  name: 'Category',
                  dataKey: 'project_needed_status',
                  isSelected: false,
                  equalToValue: [],
                  canBeCleared: true,
                  availableOptions: allCategories.data,
                },
                {
                  name: 'Status',
                  dataKey: 'project_readinessstate_status',
                  isSelected: false,
                  equalToValue: [],
                  canBeCleared: true,
                  availableOptions: allGeneralStatus.data,
                },
                {
                  name: 'Stage',
                  dataKey: 'project_stage',
                  isSelected: false,
                  equalToValue: [],
                  canBeCleared: true,
                  availableOptions: journeyStages.data.map((item: any) => item.stage_journerystage),
                },
                {
                  name: 'Time Period',
                  dataKey: 'project_requiredbydate',
                  isSelected: false,
                  equalToValue: [],
                  canBeCleared: true,
                  availableOptions: availabeTimePeriodOptions,
                },
              ],
            })
          );
        }
      }
    }
  }, [allProjects, allUsersStatus, allSegments, journeyStages, allIndustries]);

  useEffect(() => {
    if (openTasks.length > 0 && closedTasks.length > 0) {
      setAllTasks([...openTasks, ...closedTasks]);
    } else if (openTasks.length > 0) {
      setAllTasks([...openTasks]);
    } else if (closedTasks.length > 0) {
      setAllTasks([...closedTasks]);
    }
  }, [openTasks, closedTasks]);

  useEffect(() => {
    setFilteredTasks(allTasks);
    if (allTasks.length > 0) {
      if (isUndefined(allFilters['resourceTasksReportsPage']) || isNull(allFilters['resourceTasksReportsPage'])) {
        if (
          allUsersStatus === 'success' &&
          allSegments.status == 'success' &&
          journeyStages.status == 'success' &&
          allIndustries.status == 'success'
        ) {
          dispatch(
            addFilterGroup({
              idName: 'resourceTasksReportsPage',
              appliedFilters: [
                {
                  name: 'Status',
                  dataKey: 'case_readinessstate_status',
                  isSelected: false,
                  equalToValue: [],
                  canBeCleared: true,
                  availableOptions: allGeneralStatus.data,
                },
                {
                  name: 'Stage',
                  dataKey: 'task_stage',
                  isSelected: false,
                  equalToValue: [],
                  canBeCleared: true,
                  availableOptions: journeyStages.data.map((item: any) => item.stage_journerystage),
                },
                {
                  name: 'Time Period',
                  dataKey: 'task_start_date',
                  isSelected: false,
                  equalToValue: [],
                  canBeCleared: true,
                  availableOptions: availabeTimePeriodOptions,
                },
              ],
            })
          );
        }
      }
    }
  }, [allTasks, allUsersStatus, allSegments, journeyStages, allIndustries]);

  useEffect(() => {
    if (allUsecases.length > 0) {
      if (isUndefined(allFilters['resourceUsecaseReportsPage']) || isNull(allFilters['resourceClientsReportsPage'])) {
        if (
          allUsersStatus === 'success' &&
          allSegments.status == 'success' &&
          journeyStages.status == 'success' &&
          allIndustries.status == 'success'
        ) {
          dispatch(
            addFilterGroup({
              idName: 'resourceUsecaseReportsPage',
              appliedFilters: [
                {
                  name: 'Status',
                  dataKey: 'client_readiness_status',
                  isSelected: false,
                  equalToValue: [],
                  canBeCleared: true,
                  availableOptions: allGeneralStatus.data,
                },
                {
                  name: 'Category',
                  dataKey: 'case_needed_status',
                  isSelected: false,
                  equalToValue: [],
                  canBeCleared: true,
                  availableOptions: allCategories.data,
                },
                {
                  name: 'Time Period',
                  dataKey: 'case_requiredbydate',
                  isSelected: false,
                  equalToValue: [],
                  canBeCleared: true,
                  availableOptions: availabeTimePeriodOptions,
                },
              ],
            })
          );
        }
      }
    }
  }, [allUsecases, allUsersStatus, allSegments, journeyStages, allIndustries]);

  useEffect(() => {
    if (allFilters['resourceClientsReportsPage']) {
      const newFilteredData = new FilterableData(clients);
      newFilteredData
        .bulkFilter(allFilters['resourceClientsReportsPage'].appliedFilters)
        .customFilter('client_status', '1');
      setFilteredClients(newFilteredData.toArray());
    }

    if (allFilters['resourceProjectReportsPage']) {
      const newFilteredData = new FilterableData(allProjects);
      newFilteredData.bulkFilter(allFilters['resourceProjectReportsPage'].appliedFilters);
      setFilteredProjects(newFilteredData.toArray());
    }

    if (allFilters['resourceUsecaseReportsPage']) {
      const newFilteredData = new FilterableData(allUsecases);
      newFilteredData.bulkFilter(allFilters['resourceUsecaseReportsPage'].appliedFilters);
      setFilteredUsecase(newFilteredData.toArray());
    }

    if (allFilters['resourceTasksReportsPage']) {
      const newFilteredData = new FilterableData(allTasks);
      newFilteredData.bulkFilter(allFilters['resourceTasksReportsPage'].appliedFilters);
      setFilteredTasks(newFilteredData.toArray());
    }
  }, [value, allFilters, allProjects, allTasks]);

  function getInitialSelectedUser() {
    const selectedUser = getLoggedInUserName();
    setValue(selectedUser);
  }

  //table helpers

  function handleClientAccountClick(rowData: any) {
    navigate(`/client-list/client/${rowData.client_uid}`);
  }

  function handleProjectClick(data: any) {
    navigate(`/projects/project/${data.association_uid}/${data.project_uid}`);
  }

  function handleUsecaseClick(data: any) {
    navigate(`/projects/project/${data.association_uid}/${data.project_uid}/${data.case_uid}`);
  }

  function handleTaskClick(rowData: any) {
    getTaskDetail(rowData);
  }

  const handleClientAccess = (params: GridRenderCellParams) => {
    const arrayToCheck = clientList.map((ele) => ele.client_uid);
    const hasAccessToClient = checkAccessForRouting({ arrayToCheck: arrayToCheck, uid: params.row.client_uid });
    console.log(params.row.client_uid);
    if (hasAccessToClient == true) {
      return linkCell(params, handleClientAccountClick);
    }
    if (hasAccessToClient == false) {
      return (
        <CognisaaasTooltip
          title={<Typography sx={{ padding: '5px' }}>You do not have access to this {label_client}</Typography>}
        >
          <Typography variant="subtitle1">{params.value}</Typography>
        </CognisaaasTooltip>
      );
    }
  };

  const clientListTableColumns = [
    // {
    //   field: 'client_uid',
    //   hide: true,
    // },
    {
      field: 'client_name',
      headerName: label_client,
      minWidth: 240,
      flex: 1,
      renderCell: (params: any) => handleClientAccess(params),
    },

    {
      field: 'client_account_worth',
      headerName: `${label_arr}`,
      minWidth: 200,
      flex: 1,
      renderCell: CostCell,
    },
    {
      field: 'stage_journerystage',
      headerName: 'Stage',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'segment_name',
      headerName: 'Segment',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'industry_name',
      headerName: 'Industry',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'client_sales_owner',
      headerName: label_sales_owner,
      minWidth: 200,
      flex: 1,
      // renderCell: AvatarCell,
    },
    {
      field: 'client_account_owner',
      headerName: label_cs_owner,
      minWidth: 140,
      flex: 1,
    },
  ];

  const projectTableColumns = [
    // {
    //   field: 'client_uid',
    //   hide: true,
    // },
    {
      field: 'project_name',
      headerName: label_project,
      minWidth: 240,
      flex: 1,
      renderCell: (params: any) => linkCell(params, handleProjectClick),
    },

    {
      field: 'client_name',
      headerName: `${label_client} Name`,
      minWidth: 240,
      flex: 1,
      renderCell: (params: any) => handleClientAccess(params),
    },
    {
      field: 'project_deliverymanager',
      headerName: label_project_delivery_manager,
      minWidth: 140,
      flex: 1,
    },
    {
      field: 'project_needed_status',
      headerName: 'Category',
      minWidth: 200,
      flex: 1,
    },

    {
      field: 'project_stage',
      headerName: 'Stage',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'project_planned_startdate',
      headerName: 'Planned Start Date',
      minWidth: 200,
      flex: 1,
      type: 'date',
      // valueGetter: ({ value }: any) => value && new Date(value),
      renderCell: (params: GridRenderCellParams) => {
        return <ViewDate date={params.row.project_planned_startdate} />;
      },
    },
    {
      field: 'project_startdate',
      headerName: 'Start Date',
      minWidth: 200,
      flex: 1,
      type: 'date',
      // valueGetter: ({ value }: any) => value && new Date(value),
      renderCell: (params: GridRenderCellParams) => {
        return <ViewDate date={params.row.project_startdate} />;
      },
    },
    {
      field: 'project_requiredbydate',
      headerName: label_due_date,
      minWidth: 200,
      flex: 1,
      type: 'date',
      // valueGetter: ({ value }: any) => value && new Date(value),
      renderCell: (params: GridRenderCellParams) => {
        return <ViewDate date={params.row.project_requiredbydate} />;
      },
    },
    {
      field: 'project_deliverydate',
      headerName: label_delivery_date,
      minWidth: 200,
      flex: 1,
      type: 'date',
      // valueGetter: ({ value }: any) => value && new Date(value),
      renderCell: (params: GridRenderCellParams) => {
        return <ViewDate date={params.row.project_deliverydate} />;
      },
    },
    {
      field: 'eta_forprojectdelivery',
      headerName: 'ETA for delivery',
      minWidth: 200,
      flex: 1,
      type: 'date',
      // valueGetter: ({ value }: any) => value && new Date(value),
      renderCell: (params: GridRenderCellParams) => {
        return <ViewDate date={params.row.eta_forprojectdelivery} />;
      },
    },
    {
      field: 'client_account_worth',
      headerName: 'Revenue Impact',
      minWidth: 200,
      flex: 1,
      renderCell: (params: any) => {
        return formatCurrency(params.row[params.field], customerDetail?.currency_code);
      },
    },

    {
      field: 'project_comment',
      headerName: 'Comment',
      minWidth: 300,
      flex: 1,
    },
  ];

  //Usecase Table helpers

  const usecaseTableColumns = [
    // {
    //   field: 'client_uid',
    //   hide: true,
    // },
    {
      field: 'case_name',
      headerName: label_use_case,
      minWidth: 240,
      flex: 1,
      renderCell: (params: any) => linkCell(params, handleClientAccountClick),
    },

    {
      field: 'client_name',
      headerName: label_client,
      minWidth: 200,
      flex: 1,
      renderCell: (params: any) => handleClientAccess(params),
    },
    {
      field: 'project_name',
      headerName: `${label_project} Name`,
      minWidth: 200,
      flex: 1,
      renderCell: (params: any) => linkCell(params, handleProjectClick),
    },
    {
      field: 'case_needed_status',
      headerName: 'Category',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'case_deliverymanager',
      headerName: label_project_delivery_manager,
      minWidth: 140,
      flex: 1,
    },
    {
      field: 'client_account_worth',
      headerName: 'Revenue Impact',
      minWidth: 200,
      flex: 1,
      renderCell: (params: any) => {
        return formatCurrency(params.row[params.field], customerDetail?.currency_code);
      },
    },
    {
      field: 'case_planned_startdate',
      headerName: 'Planned Start Date',
      minWidth: 200,
      flex: 1,
      type: 'date',
      // valueGetter: ({ value }: any) => value && new Date(value),
      renderCell: (params: GridRenderCellParams) => {
        return <ViewDate date={params.row.case_planned_startdate} />;
      },
    },
    {
      field: 'case_startdate',
      headerName: 'Start Date',
      minWidth: 200,
      flex: 1,
      type: 'date',
      // valueGetter: ({ value }: any) => value && new Date(value),
      renderCell: (params: GridRenderCellParams) => {
        return <ViewDate date={params.row.case_startdate} />;
      },
    },
    {
      field: 'case_requiredbydate',
      headerName: label_due_date,
      minWidth: 200,
      flex: 1,
      type: 'date',
      // valueGetter: ({ value }: any) => value && new Date(value),
      renderCell: (params: GridRenderCellParams) => {
        return <ViewDate date={params.row.case_requiredbydate} />;
      },
    },
    {
      field: 'case_deliverydate',
      headerName: label_delivery_date,
      minWidth: 200,
      flex: 1,
      type: 'date',
      // valueGetter: ({ value }: any) => value && new Date(value),
      renderCell: (params: GridRenderCellParams) => {
        return <ViewDate date={params.row.case_deliverydate} />;
      },
    },
    {
      field: 'eta_forcasedelivery',
      headerName: 'ETA for delivery',
      minWidth: 200,
      flex: 1,
      type: 'date',
      // valueGetter: ({ value }: any) => value && new Date(value),
      renderCell: (params: GridRenderCellParams) => {
        return <ViewDate date={params.row.eta_forcasedelivery} />;
      },
    },
  ];

  const taskColumns = [
    {
      headerName: `${label_task} Description`,
      field: 'task_name',
      minWidth: 200,
      flex: 1,
      renderCell: (params: any) => linkCell(params, handleTaskClick),
      // renderCell: (params: GridRenderCellParams) => <Link to={getClient(params.row)}>{params.value}</Link>,
    },
    {
      headerName: `${label_client} Name`,
      field: 'client_name',
      minWidth: 200,
      flex: 1,
      renderCell: (params: any) => handleClientAccess(params),
      // renderCell: (params: GridRenderCellParams) => <Link to={getClient(params.row)}>{params.value}</Link>,
    },
    {
      headerName: `${label_project} Name`,
      field: 'project_name',
      minWidth: 200,
      flex: 1,
      renderCell: (params: any) => linkCell(params, handleProjectClick),
      // renderCell: (params: GridRenderCellParams) => <Link to={getClient(params.row)}>{params.value}</Link>,
    },
    {
      headerName: `${label_use_case} Name`,
      field: 'case_name',
      minWidth: 200,
      flex: 1,
      renderCell: (params: any) => linkCell(params, handleUsecaseClick),
      // renderCell: (params: GridRenderCellParams) => <Link to={getClient(params.row)}>{params.value}</Link>,
    },
    {
      headerName: `${label_task} Owner`,
      field: 'task_owner',
      minWidth: 200,
      flex: 1,
      editable: true,
      // renderEditCell: (params: any) => SelectEditInputCell(params, usersOfCompany),
      renderCell: (params: GridRenderCellParams) => (
        <Stack direction="row">
          <Stack spacing={1} direction={'row'}>
            <Avatar
              style={{ height: '34px', width: '34px', marginTop: '3px' }}
              {...stringAvatar(getUserNameByUserUid(params.value))}
            />
            <Typography style={{ marginTop: '8px' }} variant="subtitle1">
              {getUserNameByUserUid(params.value)}
            </Typography>
          </Stack>
        </Stack>
      ),
    },
    {
      headerName: `${label_task} Status`,
      field: 'task_status',
      minWidth: 200,
      flex: 1,
      renderCell: StatusCell,
    },
    {
      headerName: `${label_task} Stage`,
      field: 'task_stage',
      minWidth: 200,
      flex: 1,
    },
    {
      headerName: `${label_task} Priority`,
      field: 'task_priority',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'task_planned_start_date',
      headerName: 'Planned Start Date',
      minWidth: 200,
      flex: 1,
      type: 'date',
      // valueGetter: ({ value }: any) => value && new Date(value),
      renderCell: (params: GridRenderCellParams) => {
        return <ViewDate date={params.row.task_planned_start_date} />;
      },
    },
    {
      headerName: 'Start Date',
      field: 'task_start_date',
      minWidth: 200,
      flex: 1,
      type: 'date',
      // valueGetter: ({ value }: any) => value && new Date(value),
      renderCell: (params: GridRenderCellParams) => {
        return <ViewDate date={params.row.task_start_date} />;
      },
    },
    {
      field: 'task_due_date',
      headerName: label_due_date,
      minWidth: 200,
      flex: 1,
      type: 'date',
      // valueGetter: ({ value }: any) => value && new Date(value),
      renderCell: (params: GridRenderCellParams) => {
        return <ViewDate date={params.row.task_due_date} />;
      },
    },
    {
      field: 'task_closed_date',
      headerName: label_delivery_date,
      minWidth: 200,
      flex: 1,
      type: 'date',
      // valueGetter: ({ value }: any) => value && new Date(value),
      renderCell: (params: GridRenderCellParams) => {
        return <ViewDate date={params.row.task_closed_date} />;
      },
    },
  ];

  // Graph Helpers

  // Clients Graph Helpers

  function graphBarClientsFilter(data: any[]): any[] {
    const clientsStatusFiltered = [0, 0, 0];
    data.map((item) => {
      if (item['client_account_owner'] == value) {
        const clientStatus = item['client_readiness_status'];

        if (clientStatus == 'green') {
          clientsStatusFiltered[0] = clientsStatusFiltered[0] + 1;
        }
        if (clientStatus == 'amber') {
          clientsStatusFiltered[1] = clientsStatusFiltered[1] + 1;
        }
        if (clientStatus == 'red') {
          clientsStatusFiltered[2] = clientsStatusFiltered[2] + 1;
        }
      }
    });
    return clientsStatusFiltered;
  }

  function calculateTotalClientsLegend(data: any[]): number {
    let totalClients = 0;
    data.map((item) => {
      if (item['client_account_owner'] == value) {
        totalClients = totalClients + 1;
      }
    });
    return totalClients;
  }

  function calculateOnTrackClientsLegend(data: any[]): number {
    let onTrackValue = 0;
    data.map((item) => {
      if (item['client_account_owner'] == value) {
        if (item['client_readiness_status'] == 'green') {
          onTrackValue = onTrackValue + 1;
        }
      }
    });
    return onTrackValue;
  }

  function calculateAtRiskClientsLegend(data: any[]): number {
    let atRiskValue = 0;
    data.map((item) => {
      if (item['client_account_owner'] == value) {
        if (item['client_readiness_status'] == 'amber') {
          atRiskValue = atRiskValue + 1;
        }
      }
    });
    return atRiskValue;
  }

  function calculateOverdueClientsLegend(data: any[]): number {
    let overdueValue = 0;
    data.map((item) => {
      if (item['client_account_owner'] == value) {
        if (item['client_readiness_status'] == 'red') {
          overdueValue = overdueValue + 1;
        }
      }
    });
    return overdueValue;
  }
  // Projects Graph Helpers

  function graphProjectFilter(data: any[]): any[] {
    const projectStatusFiltered = [0, 0, 0];
    data.map((item) => {
      if (item['project_deliverymanager'] == value) {
        const clientStatus = item['project_readinessstate_status'];

        if (clientStatus == 'green') {
          projectStatusFiltered[0] = projectStatusFiltered[0] + 1;
        }
        if (clientStatus == 'amber') {
          projectStatusFiltered[1] = projectStatusFiltered[1] + 1;
        }
        if (clientStatus == 'red') {
          projectStatusFiltered[2] = projectStatusFiltered[2] + 1;
        }
      }
    });
    return projectStatusFiltered;
  }

  function calculateTotalProjectsLegend(data: any[]): number {
    let totalProjects = 0;
    data.map((item) => {
      if (item['project_deliverymanager'] == value) {
        totalProjects = totalProjects + 1;
      }
    });
    return totalProjects;
  }

  function calculateOnTrackProjectsLegend(data: any[]): number {
    let onTrackValue = 0;
    data.map((item) => {
      if (item['project_deliverymanager'] == value) {
        if (item['project_readinessstate_status'] == 'green') {
          onTrackValue = onTrackValue + 1;
        }
      }
    });
    return onTrackValue;
  }

  function calculateAtRiskProjectsLegend(data: any[]): number {
    let atRiskValue = 0;
    data.map((item) => {
      if (item['project_deliverymanager'] == value) {
        if (item['project_readinessstate_status'] == 'amber') {
          atRiskValue = atRiskValue + 1;
        }
      }
    });
    return atRiskValue;
  }

  function calculateOverdueProjectsLegend(data: any[]): number {
    let overdueValue = 0;
    data.map((item) => {
      if (item['project_deliverymanager'] == value) {
        if (item['project_readinessstate_status'] == 'red') {
          overdueValue = overdueValue + 1;
        }
      }
    });
    return overdueValue;
  }

  // Usecase Graph Helpers

  function graphUsecaseFilter(data: any[]): any[] {
    const usecaseStatusFiltered = [0, 0, 0];
    data.map((item) => {
      if (item['case_deliverymanager'] == value) {
        const clientStatus = item['case_readinessstate_status'];

        if (clientStatus == 'green') {
          usecaseStatusFiltered[0] = usecaseStatusFiltered[0] + 1;
        }
        if (clientStatus == 'amber') {
          usecaseStatusFiltered[1] = usecaseStatusFiltered[1] + 1;
        }
        if (clientStatus == 'red') {
          usecaseStatusFiltered[2] = usecaseStatusFiltered[2] + 1;
        }
      }
    });
    return usecaseStatusFiltered;
  }

  function calculateTotalUsecaseLegend(data: any[]): number {
    let totalUsecases = 0;
    data.map((item) => {
      if (item['case_deliverymanager'] == value) {
        totalUsecases = totalUsecases + 1;
      }
    });
    return totalUsecases;
  }

  function calculateOnTrackUsecaseLegend(data: any[]): number {
    let onTrackValue = 0;
    data.map((item) => {
      if (item['case_deliverymanager'] == value) {
        if (item['case_readinessstate_status'] == 'green') {
          onTrackValue = onTrackValue + 1;
        }
      }
    });
    return onTrackValue;
  }

  function calculateAtRiskUsecaseLegend(data: any[]): number {
    let atRiskValue = 0;
    data.map((item) => {
      if (item['case_deliverymanager'] == value) {
        if (item['case_readinessstate_status'] == 'amber') {
          atRiskValue = atRiskValue + 1;
        }
      }
    });
    return atRiskValue;
  }

  function calculateOverdueUsecaseLegend(data: any[]): number {
    let overdueValue = 0;
    data.map((item) => {
      if (item['case_deliverymanager'] == value) {
        if (item['case_readinessstate_status'] == 'red') {
          overdueValue = overdueValue + 1;
        }
      }
    });
    return overdueValue;
  }

  // Task Graph Helpers
  function graphTaskFilter(data: any[]): any[] {
    const taskStatusFiltered = [0, 0, 0];
    data.map((item) => {
      if (getUserNameByUserUid(item['task_owner']) == value) {
        const taskStatus = item['task_status'];

        if (taskStatus == 'green') {
          taskStatusFiltered[0] = taskStatusFiltered[0] + 1;
        }
        if (taskStatus == 'amber') {
          taskStatusFiltered[1] = taskStatusFiltered[1] + 1;
        }
        if (taskStatus == 'red') {
          taskStatusFiltered[2] = taskStatusFiltered[2] + 1;
        }
      }
    });
    return taskStatusFiltered;
  }

  function calculateTotalTaskLegend(data: any[]): number {
    let totalTasks = 0;
    data.map((item) => {
      if (getUserNameByUserUid(item['task_owner']) == value) {
        totalTasks = totalTasks + 1;
      }
    });
    return totalTasks;
  }

  function calculateOnTrackTaskLegend(data: any[]): number {
    let onTrackValue = 0;
    data.map((item) => {
      if (getUserNameByUserUid(item['task_owner']) == value) {
        if (item['task_status'] == 'green') {
          onTrackValue = onTrackValue + 1;
        }
      }
    });
    return onTrackValue;
  }

  function calculateAtRiskTaskLegend(data: any[]): number {
    let atRiskValue = 0;
    data.map((item) => {
      if (getUserNameByUserUid(item['task_owner']) == value) {
        if (item['task_status'] == 'amber') {
          atRiskValue = atRiskValue + 1;
        }
      }
    });
    return atRiskValue;
  }

  function calculateOverdueTaskLegend(data: any[]): number {
    let overdueValue = 0;
    data.map((item) => {
      if (getUserNameByUserUid(item['task_owner']) == value) {
        if (item['task_status'] == 'red') {
          overdueValue = overdueValue + 1;
        }
      }
    });
    return overdueValue;
  }

  const handleClientColumnOrderChange = (params: GridColumnOrderChangeParams) => {
    const oldArrayIndex = params.oldIndex;
    const targetArrayIndex = params.targetIndex;
    setClientListColumns(
      generateOrderedColumn(clientListColumns, uniqueClientColumnId, oldArrayIndex, targetArrayIndex)
    );
  };
  const handleProjectColumnOrderChange = (params: GridColumnOrderChangeParams) => {
    const oldArrayIndex = params.oldIndex;
    const targetArrayIndex = params.targetIndex;
    setProjectListColumns(
      generateOrderedColumn(projectListColumns, uniqueProjectColumnId, oldArrayIndex, targetArrayIndex)
    );
  };

  const handleUsecaseColumnOrderChange = (params: GridColumnOrderChangeParams) => {
    const oldArrayIndex = params.oldIndex;
    const targetArrayIndex = params.targetIndex;
    setUsecaseListColumns(
      generateOrderedColumn(usecaseListColumns, uniqueUsecaseColumnId, oldArrayIndex, targetArrayIndex)
    );
  };

  const handleTaskColumnOrderChange = (params: GridColumnOrderChangeParams) => {
    const oldArrayIndex = params.oldIndex;
    const targetArrayIndex = params.targetIndex;
    setTaskListColumns(generateOrderedColumn(taskListColumns, uniqueTaskColumnId, oldArrayIndex, targetArrayIndex));
  };

  return (
    <Box sx={{ display: 'grid', gridTemplateRows: '70px auto' }}>
      <ReportsHeader pageName={ReportsPages.Resources} />

      <Box sx={{ display: 'grid', m: '0px 20px' }}>
        <CognisaasTabs
          size="squeezed"
          selectedTabIndex={selectedTab}
          handleTabSwitch={handleTabSelection}
          tabs={tabs}
        />
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: '20px' }}>
          <Autocomplete
            value={value ? value : getInitialSelectedUser()}
            onChange={(event: any, newValue: string | null) => {
              setValue(newValue);
            }}
            size={'small'}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            id="controllable-states-demo"
            options={allUsers.map((user) => user.user_username)}
            sx={{ width: 300, background: 'white' }}
            renderInput={(params) => <TextField {...params} />}
          />
          <Box component={'span'} sx={{ margin: '0px 8px' }}>
            {selectedTab === 0 && <FilterTypeSelector idName="resourceClientsReportsPage" />}
            {selectedTab === 1 && <FilterTypeSelector idName="resourceProjectReportsPage" />}
            {selectedTab === 2 && <FilterTypeSelector idName="resourceUsecaseReportsPage" />}
            {selectedTab === 3 && <FilterTypeSelector idName="resourceTasksReportsPage" />}
          </Box>
        </Box>

        {selectedTab === 0 &&
          allFilters['resourceClientsReportsPage']?.appliedFilters.filter((filter) => filter.isSelected).length > 0 && (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
              <SelectedFilterList idName="resourceClientsReportsPage" isResetEnabled={true} />
            </Box>
          )}
        {selectedTab === 1 &&
          allFilters['resourceProjectReportsPage']?.appliedFilters.filter((filter) => filter.isSelected).length > 0 && (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
              <SelectedFilterList idName="resourceProjectReportsPage" isResetEnabled={true} />
            </Box>
          )}
        {selectedTab === 2 &&
          allFilters['resourceUsecaseReportsPage']?.appliedFilters.filter((filter) => filter.isSelected).length > 0 && (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
              <SelectedFilterList idName="resourceUsecaseReportsPage" isResetEnabled={true} />
            </Box>
          )}
        {selectedTab === 3 &&
          allFilters['resourceTasksReportsPage']?.appliedFilters.filter((filter) => filter.isSelected).length > 0 && (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
              <SelectedFilterList idName="resourceTasksReportsPage" isResetEnabled={true} />
            </Box>
          )}
        <Box sx={{ display: 'grid', gridRowGap: '20px' }}>
          {/* Clients */}
          {selectedTab == 0 ? (
            <>
              {clientsState === requestState.success ? (
                <CognisaasMultiBarGraph
                  key={'clients'}
                  title={label_client + 's'}
                  legend={[
                    {
                      name: `Total ${label_client}s`,
                      color: '#E5E5E5',
                      calculateValue: calculateTotalClientsLegend,
                    },
                    {
                      name: 'On Track',
                      color: '#4F9F52',
                      calculateValue: calculateOnTrackClientsLegend,
                    },
                    {
                      name: 'At Risk',
                      color: '#FFA100',
                      calculateValue: calculateAtRiskClientsLegend,
                    },
                    {
                      name: 'Overdue',
                      color: '#E75B5C',
                      calculateValue: calculateOverdueClientsLegend,
                    },
                  ]}
                  labels={['On Track', 'At Risk', 'Overdue']}
                  options={{ height: 200, width: 700 }}
                  data={filteredClients}
                  bars={[
                    {
                      label: `${label_client}s`,
                      colors: ['#4F9F52', '#FFA100', '#E75B5C'],
                      filter: graphBarClientsFilter,
                    },
                  ]}
                />
              ) : (
                <div>
                  <CognisaasLoader />
                </div>
              )}

              <Box sx={{ background: 'white', padding: '20px', borderRadius: '4px' }}>
                {clientsState === requestState.success ? (
                  <>
                    <Stack>
                      <Stack direction="row" justifyContent={'space-between'}>
                        <Typography variant="h2">{label_client}s</Typography>
                      </Stack>
                      {/* <SelectedFilterList idName="resourceClientsReportsPage" /> */}
                    </Stack>

                    <CogniTable
                      key={1}
                      hideFooter={false}
                      hideHeader={false}
                      pageSize={10}
                      columns={clientListColumns}
                      data={displayDataWithCustomNames(
                        filteredClients.filter((client) => client.client_account_owner == value)
                      )}
                      onColumnOrderChange={handleClientColumnOrderChange}
                      idName="resourseReportClientPage"
                    />
                  </>
                ) : (
                  <div>
                    <CognisaasLoader />
                  </div>
                )}
              </Box>
            </>
          ) : (
            ''
          )}
          {/* Projects */}
          {selectedTab == 1 ? (
            <>
              {allProjectsState === requestState.success ? (
                <CognisaasMultiBarGraph
                  key={'projects'}
                  title={label_project + 's'}
                  legend={[
                    {
                      name: `Total ${label_project}s`,
                      color: '#E5E5E5',
                      calculateValue: calculateTotalProjectsLegend,
                    },
                    {
                      name: 'On Track',
                      color: '#4F9F52',
                      calculateValue: calculateOnTrackProjectsLegend,
                    },
                    {
                      name: 'At Risk',
                      color: '#FFA100',
                      calculateValue: calculateAtRiskProjectsLegend,
                    },
                    {
                      name: 'Overdue',
                      color: '#E75B5C',
                      calculateValue: calculateOverdueProjectsLegend,
                    },
                  ]}
                  labels={['On Track', 'At Risk', 'Overdue']}
                  options={{ height: 200, width: 700 }}
                  data={allProjects}
                  bars={[
                    {
                      label: `${label_project}s`,
                      colors: ['#4F9F52', '#FFA100', '#E75B5C'],
                      filter: graphProjectFilter,
                    },
                  ]}
                />
              ) : (
                <div>
                  <CognisaasLoader />
                </div>
              )}

              <Box sx={{ background: 'white', padding: '20px', borderRadius: '4px' }}>
                {allProjectsState === requestState.success ? (
                  <>
                    {/* <CognisaasTable
                    key={'projects'}
                    uniqueTableName={'projects'}
                    tableTitle={<h2>Projects</h2>}
                    columnMappings={getProjectsTableColumnMappings()}
                    data={allProjects.filter((project) => project.client_account_owner == value)}
                    pageSize={5}
                  /> */}
                    <Stack>
                      <Stack direction="row" justifyContent={'space-between'}>
                        <Typography variant="h2">{label_project}s</Typography>
                      </Stack>
                      {/* <SelectedFilterList idName="resourceProjectReportsPage" /> */}
                    </Stack>

                    <CogniTable
                      key={1}
                      hideFooter={false}
                      hideHeader={false}
                      pageSize={10}
                      columns={projectListColumns}
                      data={filteredProjects.filter((project) => {
                        return project.project_deliverymanager == value;
                      })}
                      onColumnOrderChange={handleProjectColumnOrderChange}
                      idName="resourseReportProjectPage"
                    />
                  </>
                ) : (
                  <div>
                    <CognisaasLoader />
                  </div>
                )}
              </Box>
            </>
          ) : (
            ''
          )}
          {/* Usecases */}
          {selectedTab == 2 ? (
            <>
              {allUsecasesState === requestState.success ? (
                <CognisaasMultiBarGraph
                  title={label_use_case + 's'}
                  legend={[
                    {
                      name: `Total ${label_use_case}s`,
                      color: '#E5E5E5',
                      calculateValue: calculateTotalUsecaseLegend,
                    },
                    {
                      name: 'On Track',
                      color: '#4F9F52',
                      calculateValue: calculateOnTrackUsecaseLegend,
                    },
                    {
                      name: 'At Risk',
                      color: '#FFA100',
                      calculateValue: calculateAtRiskUsecaseLegend,
                    },
                    {
                      name: 'Overdue',
                      color: '#E75B5C',
                      calculateValue: calculateOverdueUsecaseLegend,
                    },
                  ]}
                  labels={['On Track', 'At Risk', 'Overdue']}
                  options={{ height: 200, width: 700 }}
                  data={allUsecases.filter((usecase) => usecase.case_deliverymanager == value)}
                  bars={[
                    {
                      label: `${label_use_case}s`,
                      colors: ['#4F9F52', '#FFA100', '#E75B5C'],
                      filter: graphUsecaseFilter,
                    },
                  ]}
                />
              ) : (
                <div>
                  <CognisaasLoader />
                </div>
              )}

              <Box sx={{ background: 'white', padding: '20px', borderRadius: '4px' }}>
                {clientsState === requestState.success ? (
                  <>
                    {/* <CognisaasTable
                    uniqueTableName={'usecase'}
                    tableTitle={<h2>Usecase</h2>}
                    columnMappings={getUsecasesTableColumnMappings()}
                    data={allUsecases.filter((usecase) => usecase.client_account_owner == value)}
                    pageSize={5}
                  /> */}
                    <Stack>
                      <Stack direction="row" justifyContent={'space-between'}>
                        <Typography variant="h2">{label_use_case}</Typography>
                      </Stack>
                      {/* <SelectedFilterList idName="resourceUsecaseReportsPage" /> */}
                    </Stack>

                    <CogniTable
                      key={1}
                      hideFooter={false}
                      hideHeader={false}
                      pageSize={10}
                      columns={usecaseListColumns}
                      data={filteredUsecase.filter((usecase) => usecase.case_deliverymanager == value)}
                      onColumnOrderChange={handleUsecaseColumnOrderChange}
                      idName="resourseReportUsecasePage"
                    />
                  </>
                ) : (
                  <div>
                    <CognisaasLoader />
                  </div>
                )}
              </Box>
            </>
          ) : (
            ''
          )}
          {/* Tasks */}
          {selectedTab == 3 ? (
            <>
              {clientsState === requestState.success ? (
                <CognisaasMultiBarGraph
                  title={label_task + 's'}
                  legend={[
                    {
                      name: `Total ${label_task}s`,
                      color: '#E5E5E5',
                      calculateValue: calculateTotalTaskLegend,
                    },
                    {
                      name: 'On Track',
                      color: '#4F9F52',
                      calculateValue: calculateOnTrackTaskLegend,
                    },
                    {
                      name: 'At Risk',
                      color: '#FFA100',
                      calculateValue: calculateAtRiskTaskLegend,
                    },
                    {
                      name: 'Overdue',
                      color: '#E75B5C',
                      calculateValue: calculateOverdueTaskLegend,
                    },
                  ]}
                  labels={['On Track', 'At Risk', 'Overdue']}
                  options={{ height: 200, width: 700 }}
                  data={allTasks.filter((task) => {
                    return getUserNameByUserUid(task.task_owner) == value;
                  })}
                  bars={[
                    {
                      label: `${label_task}s`,
                      colors: ['#4F9F52', '#FFA100', '#E75B5C'],
                      filter: graphTaskFilter,
                    },
                  ]}
                />
              ) : (
                <div>
                  <CognisaasLoader />
                </div>
              )}

              <Box sx={{ background: 'white', padding: '20px', borderRadius: '4px' }}>
                {openTasksStatus === requestState.success && closedTasksStatus === requestState.success ? (
                  <>
                    {/* <CognisaasTable
                    uniqueTableName={'usecase'}
                    tableTitle={<h2>Usecase</h2>}
                    columnMappings={getUsecasesTableColumnMappings()}
                    data={allUsecases.filter((usecase) => usecase.client_account_owner == value)}
                    pageSize={5}
                  /> */}
                    <Stack>
                      <Stack direction="row" justifyContent={'space-between'}>
                        <Typography variant="h2">{label_task}s</Typography>
                      </Stack>
                      {/* <SelectedFilterList idName="resourceTasksReportsPage" /> */}
                    </Stack>

                    <CogniTable
                      key={1}
                      hideFooter={false}
                      hideHeader={false}
                      pageSize={10}
                      columns={taskListColumns}
                      data={displayDataWithCustomNames(
                        filteredTasks.filter((task) => getUserNameByUserUid(task.task_owner) == value)
                      )}
                      onColumnOrderChange={handleTaskColumnOrderChange}
                      idName="resourseReportTaskPage"
                    />
                  </>
                ) : (
                  <div>
                    <CognisaasLoader />
                  </div>
                )}
              </Box>
            </>
          ) : (
            ''
          )}
        </Box>
      </Box>
      {updateTaskDrawer && (
        <UpdateTaskDrawer
          client={{ client_uid: openTaskWithDetail.client_uid }}
          taskDetail={openTaskWithDetail}
          drawerState={updateTaskDrawer}
          onClose={() => setUpdateTaskDrawer(!updateTaskDrawer)}
        />
      )}
    </Box>
  );
};

export default Resources;
