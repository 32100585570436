import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  getAllProjectCustomFields,
  getAllProjectsOfCustomer,
  getProjectCustomFieldsDetail,
} from '../../store/modules/Project/slice';
import ProjectsTable from './ProjectsTable/ProjectsTable';
import {
  selectAllProjectCustomFields,
  selectProjectCustomFieldsDetail,
  selectProjectCustomFieldsDetailStatus,
  selectProjectList,
  selectProjectListStatus,
} from '../../store/modules/Project/selector';
import { useAppSelector } from '../../store/hooks';
import { Stack, Button, ButtonGroup, Box, Typography } from '@mui/material';
import { ReactComponent as MCIcon1 } from '../../assets/svgs/manageclientIcon1.svg';
import { ReactComponent as MCIcon2 } from '../../assets/svgs/manageclientIcon2.svg';
// import StatusBadge from '../shared/LabeledBadge/LabeledBadge';
// import CognisaasProjectCard from '../shared/CognisaasCard/CognisaasProjectCard/CognisaasProjectCard';
import ProjectsCard from './ProjectsTable/ProjectsCard';
import CognisaasButton from '../shared/CognisaasButton/CognisaasButton';
import { useLocation, useNavigate } from 'react-router-dom';
import FilterTypeSelector from 'components/shared/CognisaasFilters/FilterTypeSelector/FilterTypeSelector';
import SelectedFilterList from 'components/shared/CognisaasFilters/SelectedFilterList/SelectedFilterList';
import {
  generateDataWhileMappingCustomFieldsAsNormalFields,
  generateMappedCustomFieldKey,
  getOriginalStatusFromStatusName,
  getStatusColor,
  isNull,
  isUndefined,
} from 'utils/dataHelpers';
import { selectAllFilters } from 'store/modules/AllFilters/selector';
import { addFilterGroup, updateFilter } from 'store/modules/AllFilters/slice';
import {
  selectAllGeneralStatus,
  selectUsersOfCompany,
  selectUsersOfCompanyStatus,
} from 'store/modules/Common/selector';
import { getAllUsers } from 'store/modules/Common/slice';
import { availableDateRangeOptions, FilterableData } from 'utils/filteringFunctions';
import StatusFilterGroup from 'components/shared/CognisaasFilters/StatusFilterGroup/StatusFilterGroup';
import { requestState } from 'store/modules/types';
import CognisaasLoader from 'components/shared/CognisaasLoader/CognisaasLoader';
import {
  label_delivery_date,
  label_due_date,
  label_project,
  label_project_delivery_manager,
} from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { selectClientList } from '../../store/modules/ManageClient/ClientList/selector';
import { transformDataToHaveCustomFields } from './utils';
import { fetchClientList } from 'store/modules/ManageClient/ClientList/slice';
import CognisaasToast from 'components/shared/CognisaasToast/CognisaasToast';
import useCustomNames from 'components/shared/CustomNames/useCustomNames';
import AddAggregationCardButton from 'components/shared/AggregationCards/AddAggregationCardButton';
import DisplayAggregatedCards from 'components/shared/AggregationCards/DisplayAggregatedCards';
import { PROJECT_HOME_TABLE_ID_NAME } from 'components/shared/AggregationCards/constants';

interface LocationParams {
  pathname: string;
  state: any;
  search: string;
  hash: string;
  key: string;
}

const ProjectModule = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { projectStagesDropdownValues, projectCategoryDropdownValues, projectPrioritiesDropdownValues } =
    useCustomNames();
  const customProjectPriorities = projectPrioritiesDropdownValues.map((item: any) => item.priority_name);
  const customProjectCategories = projectCategoryDropdownValues.map((item: any) => item.category_name);
  const customProjectStages = projectStagesDropdownValues.map((item: any) => item.stage_name);
  const projectsList = useAppSelector(selectProjectList);
  const projectListStatus = useAppSelector(selectProjectListStatus);
  const allProjectCustomFields = useAppSelector(selectAllProjectCustomFields);
  const projectCustomFields = useAppSelector(selectProjectCustomFieldsDetail);
  const projectCustomFieldsStatus = useAppSelector(selectProjectCustomFieldsDetailStatus);
  const [selectedStatus, setSelectedStatus] = useState<string>('All');

  const [tableView, setTableView] = useState<boolean>(true);
  const allFilters = useAppSelector(selectAllFilters);
  const allUsers = useAppSelector(selectUsersOfCompany);
  const allUsersStatus = useAppSelector(selectUsersOfCompanyStatus);
  const allGeneralStatus = useAppSelector(selectAllGeneralStatus);
  const [filteredProjectsList, setFilteredProjectsList] = useState<any[]>([]);
  const [cfMappedProjectsList, setCfMappedProjectsList] = useState<any[]>([]);
  const [projectStatusCounts, setProjectStatusCounts] = useState<{
    all: number;
    overdue: number;
    atRisk: number;
    onTrack: number;
    completed: number;
    onHold: number;
  }>({ all: 0, overdue: 0, atRisk: 0, onTrack: 0, completed: 0, onHold: 0 });
  const allClients = useAppSelector(selectClientList);
  // const [customerProjects, setCustomerProjects] = useState([]);
  const location: LocationParams = useLocation();
  const [countCameFromChartClick, setCountCameFromChartClick] = useState<number>(-1);
  const [alertDetails, setAlertDetails] = useState<{
    isVisible: boolean;
    type: 'success' | 'failure';
    message: string;
  }>({ isVisible: false, type: 'success', message: '' });

  useEffect(() => {
    dispatch(fetchClientList());
    dispatch(getAllProjectCustomFields());
    dispatch(getProjectCustomFieldsDetail());
    dispatch(getAllProjectsOfCustomer());
  }, []);

  useEffect(() => {
    if (allUsersStatus !== 'success') {
      dispatch(getAllUsers());
    }
  }, [allUsersStatus]);

  useEffect(() => {
    if (projectsList.length > 0 && allProjectCustomFields.status === requestState.success) {
      if (allProjectCustomFields.data.length > 0 || projectCustomFields.length > 0) {
        const transformedProjectsList = transformDataToHaveCustomFields(
          projectsList,
          allProjectCustomFields.data,
          'project_uid',
          projectCustomFields
        );
        setCfMappedProjectsList([
          ...generateDataWhileMappingCustomFieldsAsNormalFields(
            [...transformedProjectsList],
            'custom_values',
            'field_name',
            'field_value',
            'field_uid',
            'field_type'
          ),
        ]);
        // setFilteredProjectsList([
        //   ...generateDataWhileMappingCustomFieldsAsNormalFields(
        //     [...transformedProjectsList],
        //     'custom_values',
        //     'field_name',
        //     'field_value',
        //     'field_uid',
        //     'field_type'
        //   ),
        // ]);
      } else {
        setCfMappedProjectsList(projectsList);
        // setFilteredProjectsList(projectsList);
      }
    }
  }, [projectsList, allProjectCustomFields, projectCustomFields]);

  useEffect(() => {
    if (isUndefined(allFilters['projectsModuleHome']) || isNull(allFilters['projectsModuleHome'])) {
      if (allUsersStatus == 'success' && cfMappedProjectsList.length > 0 && projectCustomFieldsStatus === 'success') {
        const defaultFilters = [
          {
            name: label_project_delivery_manager,
            dataKey: 'project_deliverymanager',
            isSelected: false,
            equalToValue: [],
            canBeCleared: true,
            availableOptions: allUsers.map((item: any) => item.user_username),
          },
          {
            name: 'Status',
            dataKey: 'project_readinessstate_status',
            isSelected: false,
            equalToValue: [],
            canBeCleared: true,
            availableOptions: allGeneralStatus.data,
          },
          {
            name: `${label_project} age$$number-range`,
            dataKey: 'project_age',
            isSelected: false,
            equalToValue: [],
            canBeCleared: true,
            availableOptions: availableDateRangeOptions,
          },
          {
            name: 'Stage',
            dataKey: 'project_stage',
            isSelected: false,
            equalToValue: [],
            canBeCleared: true,
            availableOptions: customProjectStages,
          },
          {
            name: 'Priority',
            dataKey: 'project_priority',
            isSelected: false,
            equalToValue: [],
            canBeCleared: true,
            availableOptions: customProjectPriorities,
          },
          {
            name: 'Category',
            dataKey: 'project_needed_status',
            isSelected: false,
            equalToValue: [],
            canBeCleared: true,
            availableOptions: customProjectCategories,
          },
          {
            name: 'Last status email sent$$date-range-picker',
            dataKey: 'project_last_status_report_sent_time',
            isSelected: false,
            equalToValue: [],
            canBeCleared: true,
            availableOptions: availableDateRangeOptions,
          },
          {
            name: `${label_delivery_date}$$date-range-picker`,
            dataKey: 'project_deliverydate',
            isSelected: false,
            equalToValue: [],
            canBeCleared: true,
            availableOptions: availableDateRangeOptions,
          },
          {
            name: `${label_due_date}$$date-range-picker`,
            dataKey: 'project_requiredbydate',
            isSelected: false,
            equalToValue: [],
            canBeCleared: true,
            availableOptions: availableDateRangeOptions,
          },
          {
            name: 'Start Date$$date-range-picker',
            dataKey: 'project_startdate',
            isSelected: false,
            equalToValue: [],
            canBeCleared: true,
            availableOptions: availableDateRangeOptions,
          },
          {
            name: 'Delivery Time Range$$date-range-picker',
            dataKey: 'project_deliverydate',
            isSelected: false,
            equalToValue: [],
            canBeCleared: true,
            availableOptions: availableDateRangeOptions,
          },
        ];

        const cfProjects: any[] = [];

        projectCustomFields.forEach((cf: any) => {
          if (['RAG status', 'Dropdown'].includes(cf.field_type)) {
            cfProjects.push({
              name: cf.field_name,
              dataKey: generateMappedCustomFieldKey(cf.field_name, cf.field_uid, cf.field_type),
              isSelected: false,
              equalToValue: [],
              canBeCleared: true,
              availableOptions: cf.valid_values.split(','),
            });
          } else if (cf.field_type === 'Date') {
            cfProjects.push({
              name: `${cf.field_name}$$date-range-picker`,
              dataKey: generateMappedCustomFieldKey(cf.field_name, cf.field_uid, cf.field_type),
              isSelected: false,
              equalToValue: [],
              canBeCleared: true,
              availableOptions: availableDateRangeOptions,
            });
          }
        });

        if (isUndefined(allFilters['projectsModuleHome']) || isNull(allFilters['projectsModuleHome'])) {
          dispatch(
            addFilterGroup({
              idName: 'projectsModuleHome',
              appliedFilters: [...defaultFilters, ...cfProjects],
            })
          );
        }
      }
    }
  }, [allUsersStatus, cfMappedProjectsList, projectCustomFieldsStatus]);

  useEffect(() => {
    if (!isUndefined(allFilters['projectsModuleHome']) && projectsList.length > 0) {
      const newFilteredData = new FilterableData(cfMappedProjectsList);
      newFilteredData.bulkFilter(allFilters['projectsModuleHome'].appliedFilters);
      calculateAndUpdateTotalStatusDataCount(newFilteredData);
      if (selectedStatus === 'On Hold') {
        newFilteredData.customFilter('project_stage', 'On Hold');
      } else if (!(selectedStatus.toLowerCase() === 'all')) {
        newFilteredData.customFilter('project_readinessstate_status', getOriginalStatusFromStatusName(selectedStatus));
      }
      setFilteredProjectsList(newFilteredData.toArray());
      if (countCameFromChartClick >= 0) {
        if (countCameFromChartClick !== newFilteredData.toArray().length) {
          setAlertDetails({
            isVisible: true,
            message: `You can't see all ${label_project}s of your reportees as role not properly configured in RBAC, please contact admin.`,
            type: 'failure',
          });
        }
        setCountCameFromChartClick(-1);
      }
    } else {
      setFilteredProjectsList(cfMappedProjectsList);
    }
  }, [allFilters, selectedStatus, cfMappedProjectsList]);

  useEffect(() => {
    if (!isNull(location.state)) {
      if (
        !isNull(location.state.user) &&
        !isNull(location.state.data.label) &&
        !isUndefined(allFilters['projectsModuleHome']) &&
        !isNull(allFilters['projectsModuleHome'])
      ) {
        dispatch(
          updateFilter({
            idName: 'projectsModuleHome',
            updatedFilter: {
              name: 'Status',
              dataKey: 'project_readinessstate_status',
              isSelected: true,
              equalToValue: location.state.task_status ? [location.state.task_status] : [],
              canBeCleared: true,
              availableOptions: allGeneralStatus.data,
            },
          })
        );
        if (location.state.reportees) {
          dispatch(
            updateFilter({
              idName: 'projectsModuleHome',
              updatedFilter: {
                name: label_project_delivery_manager,
                dataKey: 'project_deliverymanager',
                isSelected: true,
                equalToValue: [...location.state.reportees.map((ele: any) => ele.user_name)],
                canBeCleared: true,
                availableOptions: allUsers.map((item: any) => item.user_username),
              },
            })
          );
        } else {
          dispatch(
            updateFilter({
              idName: 'projectsModuleHome',
              updatedFilter: {
                name: label_project_delivery_manager,
                dataKey: 'project_deliverymanager',
                isSelected: true,
                equalToValue: location.state.user ? [location.state.user] : [],
                canBeCleared: true,
                availableOptions: allUsers.map((item: any) => item.user_username),
              },
            })
          );
        }
        setCountCameFromChartClick(parseInt(location.state.value));
        location.state = null;
      }
    }
  }, [location, allFilters]);

  const calculateAndUpdateTotalStatusDataCount = (data: FilterableData) => {
    const newData = data.toArray();
    const all = newData.length;
    const overdue = newData.filter((item: any) => item.project_readinessstate_status == 'red').length;
    const atrisk = newData.filter((item: any) => item.project_readinessstate_status == 'amber').length;
    const ontrack = newData.filter((item: any) => item.project_readinessstate_status == 'green').length;
    const completed = newData.filter((item: any) => item.project_readinessstate_status == 'blue').length;
    const onHold = newData.filter((item: any) => item.project_readinessstate_status == 'grey').length;

    setProjectStatusCounts({
      all: all,
      overdue: overdue,
      atRisk: atrisk,
      onTrack: ontrack,
      completed: completed,
      onHold: onHold,
    });
  };

  return (
    <>
      {projectListStatus === requestState.loading ? (
        <CognisaasLoader />
      ) : (
        <Box sx={{ paddingLeft: '20px', paddingRight: '20px', paddingTop: '20px' }}>
          <Stack justifyContent={'space-between'} direction="row" alignItems={'center'}>
            <Typography component="span" sx={{ fontWeight: '500' }} variant="h1">
              {label_project}s
            </Typography>
            {allClients.length > 0 ? (
              <CognisaasButton
                clickHandler={() => navigate('/projects/add-project')}
                isOutlined={false}
                label={'New ' + label_project}
              />
            ) : (
              ''
            )}
          </Stack>

          <Stack my={1} justifyContent={'space-between'} direction="row">
            <Stack>
              <StatusFilterGroup
                selectedBadge={selectedStatus}
                setSelectedBadge={setSelectedStatus}
                config={[
                  {
                    id: '1',
                    name: 'All',
                    color: 'transparent',
                    count: projectStatusCounts.all,
                  },
                  {
                    id: '2',
                    name: 'Overdue',
                    color: getStatusColor('red'),
                    count: projectStatusCounts.overdue,
                  },
                  {
                    id: '3',
                    name: 'At Risk',
                    color: getStatusColor('amber'),
                    count: projectStatusCounts.atRisk,
                  },
                  {
                    id: '4',
                    name: 'On Track',
                    color: getStatusColor('green'),
                    count: projectStatusCounts.onTrack,
                  },
                  {
                    id: '5',
                    name: 'Completed',
                    color: getStatusColor('blue'),
                    count: projectStatusCounts.completed,
                  },
                  {
                    id: '6',
                    name: 'On Hold',
                    color: getStatusColor('grey'),
                    count: projectStatusCounts.onHold,
                  },
                ]}
              />
            </Stack>
            <Stack direction={'row'} spacing={1}>
              <AddAggregationCardButton
                idName={PROJECT_HOME_TABLE_ID_NAME}
                sampleData={cfMappedProjectsList.length > 0 ? cfMappedProjectsList[0] : {}}
              />
              <FilterTypeSelector idName="projectsModuleHome" />

              <ButtonGroup sx={{ backgroundColor: 'white' }} variant="outlined" aria-label="outlined button group">
                <Button className={`${!tableView ? 'active-btn' : null} `} onClick={() => setTableView(false)}>
                  <MCIcon1 />
                </Button>
                <Button className={`${tableView ? 'active-btn' : null} `} onClick={() => setTableView(true)}>
                  <MCIcon2 />
                </Button>
              </ButtonGroup>
            </Stack>
          </Stack>
          {/* <CognisaasProjectCard /> */}
          <Stack direction="row" justifyContent="flex-end"></Stack>
          <Box sx={{ margin: '8px 0px' }}>
            {allFilters['projectsModuleHome']?.appliedFilters.filter((item) => item.isSelected).length > 0 && (
              <SelectedFilterList idName="projectsModuleHome" isResetEnabled={true} />
            )}
            <DisplayAggregatedCards idName={PROJECT_HOME_TABLE_ID_NAME} data={filteredProjectsList} />
          </Box>

          {tableView && <ProjectsTable data={filteredProjectsList} />}
          {!tableView && <ProjectsCard data={filteredProjectsList} />}
        </Box>
      )}
      <CognisaasToast
        message={alertDetails.message}
        type={alertDetails.type}
        open={alertDetails.isVisible}
        onClose={() => {
          setAlertDetails({ isVisible: false, type: alertDetails.type, message: '' });
        }}
      />
    </>
  );
};

export default ProjectModule;
