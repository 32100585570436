import {
  Box,
  Button,
  CardContent,
  CardHeader,
  Checkbox,
  Drawer,
  Grid,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { ReactComponent as NoRecord } from '../../../assets/svgs/no_records.svg';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import React from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from 'react-router-dom';

import {
  addNewRequirement,
  // deleteRequirementById,
  fetchProductAndRequirements,
  // removeThisRequirementFromStore,
} from '../../../store/modules/ProductRequirements/ProductDetails/slice';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../store/hooks';
import DeleteIcon from '@mui/icons-material/Delete';
import { selectPrAndReqDataState } from '../../../store/modules/ProductRequirements/ProductDetails/selector';
import { format } from 'date-fns';
import CognisaasCard from '../../shared/CognisaasCard/CognisaasCard';
import { parseProductRequirementDate } from '../../../utils/DateHelper';
import CognisaasTooltip from '../../shared/CognisaasTooltip/CognisaasTooltip';
import CognisaasRequirementStatusDropdown from '../../shared/CognisaasDropdown/CognisaasRequirementStatusDropdown/CognisaasRequirementStatusDropdown';
import BasicModal from 'components/shared/CognisaasModal/CognisaasModal';
import CognisaasButton from 'components/shared/CognisaasButton/CognisaasButton';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

const ProductTemplate = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const productsAndRequirementData = useAppSelector(selectPrAndReqDataState);
  const [requirementStatus, setRequirementStatus] = React.useState('red');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [features, setFeatures] = React.useState<any>([]);
  const [selectedProjectListDisplay, setSelectedProjectListDisplay] = React.useState<any>([]);
  const [selectedProjectName, setSelectedProjectName] = React.useState('');
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [openAddNewRequirementModal, setOpenAddNewRequirementModal] = React.useState(false);
  const [newRequirementNamefield, setNewRequirementNamefield] = React.useState('');

  React.useEffect(() => {
    const tempFeatures: any = [];
    console.log(productsAndRequirementData, 'api dat');
    productsAndRequirementData &&
      productsAndRequirementData.data.map((ele: any) => {
        ele.feature.map((ele1: any) => {
          tempFeatures.push(ele1);
        });
      });
    setFeatures(tempFeatures);
  }, [productsAndRequirementData]);

  React.useEffect(() => {
    const tempFeatures: any = [];
    console.log(productsAndRequirementData, 'api dat');
    productsAndRequirementData &&
      productsAndRequirementData.data.map((ele: any) => {
        ele.feature.map((ele1: any) => {
          tempFeatures.push(ele1);
        });
      });
    setFeatures(tempFeatures);
  }, [props]);

  React.useEffect(() => {
    console.log(features, 'newFeatures');
  }, [features]);

  React.useEffect(() => {
    console.log(props.selectedProject, props.selectedFeatures, 'new selected Products');
    if (props.selectedProject && props.selectedProject.length > 0) {
      setSelectedProjectListDisplay(props.selectedProject[0]);
      setSelectedProjectName(props.selectedProject[0].product_name);
    }
  }, [props.selectedProject]);

  const handleCheckBoxClick = (feature: any) => {
    const currIndex: any = props.selectedFeatures.indexOf(feature);
    const tempSelectedFeaturesList = [...props.selectedFeatures];
    if (currIndex === -1) {
      tempSelectedFeaturesList.push(feature);
    } else {
      tempSelectedFeaturesList.splice(currIndex, 1);
      // return;
    }
    props.setSelectedFeatures(tempSelectedFeaturesList);

    // console.log(currIndex, 'curidx');
  };

  const handleStatusMenu = () => {
    setRequirementStatus('red');
  };

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    // console.log(props);
  };

  const handleSelectedProductChange = (ele: any) => {
    // console.log('add new req ', ele);
    setSelectedProjectListDisplay(ele);
    console.log('here I ran');
    const currIndex: any = props.selectedProject.indexOf(ele);
    const tempSelectedProductList = [...props.selectedProject];
    tempSelectedProductList.map((ele1: any) => {
      if (ele1.product_uid === ele.product_uid)
        tempSelectedProductList.splice(tempSelectedProductList.indexOf(ele1), 1);
    });
    if (currIndex === -1) {
      tempSelectedProductList.push(ele);
    } else {
      // tempSelectedProductList.splice(currIndex, 1);
      return;
    }
    console.log(currIndex, tempSelectedProductList, 'maybe final');
    props.setSelectedProject(tempSelectedProductList);
  };

  const handleRemoveProject = () => {
    const currIndex: any = props.selectedProject.indexOf(selectedProjectListDisplay);
    const tempSelectedProductList = [...props.selectedProject];
    if (currIndex === -1) {
      // tempSelectedProductList.push(ele);
      return;
    } else {
      tempSelectedProductList.splice(currIndex, 1);
    }
    props.setSelectedProject(tempSelectedProductList);
    props.setSelectedFeatures([]);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedProjectName(event.target.value as string);
  };

  const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  React.useEffect(() => {
    dispatch(fetchProductAndRequirements());
  }, []);

  React.useEffect(() => {
    console.log(productsAndRequirementData, 'prd');
  }, [productsAndRequirementData]);

  const addNewRequirementToDb = () => {
    console.log(selectedProjectListDisplay);
    // alert('aman jain');
    dispatch(
      addNewRequirement({
        feature_content: '',
        feature_description: newRequirementNamefield,
        feature_estimateddeliverydate: '',
        feature_readinessstate_status: 'red',
        product_uid: selectedProjectListDisplay.product_uid,
      })
    );

    setTimeout(() => {
      dispatch(fetchProductAndRequirements());
      setNewRequirementNamefield('');
      setSelectedProjectName(selectedProjectListDisplay.product_name);
    }, 2000);

    setTimeout(() => {
      setSelectedProjectName(selectedProjectListDisplay.product_name);
    }, 4000);

    // do this at last
    setOpenAddNewRequirementModal(false);
  };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: 460 }}
      role="presentation"
      //   onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box sx={{ padding: '20px' }}>
        <Typography sx={{ fontSize: '18px', fontWeight: '500', marginTop: '64px' }}>
          Product requirement mapping
        </Typography>
        <Typography sx={{ fontSize: '12px', fontWeight: '400', marginTop: '24px' }}>Product Name</Typography>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedProjectName}
          placeholder="Select Product"
          onChange={handleChange}
          size="small"
          sx={{ width: '310px', marginTop: '4px' }}
        >
          {productsAndRequirementData &&
            productsAndRequirementData.data.map((ele, i) => {
              return (
                <MenuItem key={i} value={ele.product_name} onClick={() => handleSelectedProductChange(ele)}>
                  {ele.product_name}
                </MenuItem>
              );
            })}
        </Select>
        <Stack direction="row" sx={{ marginTop: '12px' }}>
          <Typography sx={{ fontSize: '12px', fontWeight: '400' }}>Product not listed ? </Typography>
          <Typography
            sx={{ fontSize: '12px', fontWeight: '400', color: '#2D60F6', marginLeft: '3px', cursor: 'pointer' }}
            onClick={() => navigate('/products')}
          >
            Click here
          </Typography>
          <Typography sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '3px' }}> to add</Typography>
        </Stack>
        {selectedProjectListDisplay && (
          <Box sx={{ marginTop: '24px' }}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography>{selectedProjectListDisplay?.product_name}</Typography>
              </Grid>
              <Grid item>
                <Button variant="text" sx={{ color: '#E75B5C' }} onClick={() => handleRemoveProject()}>
                  Remove
                </Button>
              </Grid>
            </Grid>
            {features &&
              features?.map((ele: any, i: number) => {
                // console.log(ele, selectedProjectListDisplay, 'new check drawer');
                return selectedProjectListDisplay?.feature?.map((ele1: any) => {
                  // console.log(ele1, ele, 'march');
                  return ele1.feature_id == ele.feature_id ? (
                    <Paper
                      key={i}
                      sx={{ height: '100px', marginTop: '12px' }}
                      // onClick={handleToggle(i, selectedProjectListDisplay.product_uid)}
                    >
                      <Box sx={{ padding: '12px' }}>
                        <Stack direction="row">
                          <Checkbox
                            checked={props?.selectedFeatures?.indexOf(ele) == -1 ? false : true}
                            onClick={() => handleCheckBoxClick(ele)}
                            disableRipple
                          />
                          <Typography sx={{ fontSize: '14px', fontWeight: '400', marginLeft: '20px' }}>
                            {ele.feature_description}
                          </Typography>
                          <span
                            style={{ marginRight: '4px', marginLeft: '20px' }}
                            className={
                              ele.feature_readinessstate_status == 'red'
                                ? 'red_status_box_task'
                                : ele.feature_readinessstate_status == 'amber'
                                ? 'amber_status_box_task'
                                : ele.feature_readinessstate_status == 'green'
                                ? 'green_status_box_task'
                                : ele.feature_readinessstate_status == 'blue'
                                ? 'blue_status_box_task'
                                : ''
                            }
                          ></span>
                        </Stack>
                        <Grid container sx={{ marginTop: '12px' }}>
                          <Grid item xs={6}>
                            <Stack>
                              <Typography sx={{ color: '#7A7A7A', fontSize: '12px', fontWeight: '400' }}>
                                ETA for release
                              </Typography>
                              <Typography sx={{ fontSize: '14px', fontWeight: '400' }}>
                                {format(new Date(ele.feature_creaded_on.replace(/-/g, '/')), 'dd MMM yyyy')}
                                {/* {format(new Date(ele.feature_estimateddeliverydate), 'dd MMM yyyy')} */}
                              </Typography>
                            </Stack>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography sx={{ fontSize: '14px', fontWeight: '400' }}>Requirement Type</Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </Paper>
                  ) : null;
                });
              })}
            <Typography
              onClick={() => {
                setOpenAddNewRequirementModal(true);
              }}
              sx={{ marginTop: '12px' }}
              variant="subtitle1"
              className="link-text cognisaas-pointer"
            >
              New requirement name
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      {(['right'] as const).map((anchor) => (
        <React.Fragment key={anchor}>
          {/* <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button> */}
          <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
      {props.selectedProject && props.selectedProject?.length > 0 ? (
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <Paper sx={{ height: '732px' }}>
              <Grid
                container
                direction="column"
                sx={{ height: '100%', padding: '20px' }}
                justifyContent="space-between"
              >
                <Grid item>
                  <Typography sx={{ fontSize: '18px', fontWeight: '500', marginBottom: '24px' }}>Products</Typography>
                  {props.selectedProject &&
                    props.selectedProject?.map((ele: any, i: any) => {
                      return ele === selectedProjectListDisplay ? (
                        <Paper
                          key={i}
                          style={{ height: '68px', marginTop: '12px', cursor: 'pointer', border: '1px solid #2d60f6' }}
                          onClick={() => setSelectedProjectListDisplay(ele)}
                        >
                          <Box sx={{ padding: '12px' }}>
                            <Typography sx={{ fontSize: '14px', fontWeight: '400' }}>{ele.product_name}</Typography>
                            <Typography
                              sx={{ fontSize: '14px', fontWeight: '400', color: '#7A7A7A', marginTop: '4px' }}
                            >
                              {ele.feature.length} Requirements
                            </Typography>
                          </Box>
                        </Paper>
                      ) : (
                        <Paper
                          key={i}
                          sx={{ height: '68px', marginTop: '12px', cursor: 'pointer' }}
                          onClick={() => setSelectedProjectListDisplay(ele)}
                        >
                          <Box sx={{ padding: '12px' }}>
                            <Typography sx={{ fontSize: '14px', fontWeight: '400' }}>{ele.product_name}</Typography>
                            <Typography
                              sx={{ fontSize: '14px', fontWeight: '400', color: '#7A7A7A', marginTop: '4px' }}
                            >
                              {ele.feature.length} Requirements
                            </Typography>
                          </Box>
                        </Paper>
                      );
                    })}
                </Grid>
                <Grid item>
                  {/* add new requirement modal */}
                  <BasicModal
                    height={235}
                    width={300}
                    open={openAddNewRequirementModal}
                    onClose={() => {
                      setOpenAddNewRequirementModal(false);
                      setNewRequirementNamefield('');
                    }}
                  >
                    <Stack sx={{ margin: '4px' }}>
                      <Typography variant="h2" sx={{ marginBottom: '16px' }}>
                        New Requirement Name
                      </Typography>
                      <Typography variant="subtitle2">Requirement Name</Typography>
                      <TextField
                        autoFocus
                        value={newRequirementNamefield}
                        onChange={(e: any) => {
                          setNewRequirementNamefield(e.target.value);
                        }}
                        variant="outlined"
                        placeholder="Enter requirement name"
                        size="small"
                      />
                      <Typography variant="subtitle2" className="label-text" sx={{ margin: '16px 0px 24px 0px' }}>
                        *Submitting a new requirement here will show up as a new feature in ‘Red’ status on the Manage
                        Product Requirements page.
                      </Typography>

                      <Stack direction={'row'} justifyContent={'flex-end'}>
                        <CognisaasButton
                          label="Cancel"
                          isOutlined={true}
                          sx={{ marginRight: '20px' }}
                          clickHandler={() => {
                            setOpenAddNewRequirementModal(false);
                            setNewRequirementNamefield('');
                          }}
                        />
                        <CognisaasButton label="Save" isOutlined={false} clickHandler={() => addNewRequirementToDb()} />
                      </Stack>
                    </Stack>
                  </BasicModal>
                  <Button variant="outlined" fullWidth onClick={toggleDrawer('right', true)}>
                    Add Product
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={9}>
            <Paper sx={{ height: '732px' }}>
              <Box sx={{ padding: '20px' }}>
                {props.selectedProject && (
                  <>
                    <Typography variant="h6">{selectedProjectListDisplay.product_name}</Typography>
                    <Stack direction="row">
                      {selectedProjectListDisplay &&
                        selectedProjectListDisplay?.feature?.map((f: any, index: number) => {
                          return props?.selectedFeatures?.map((ele: any) => {
                            return ele.feature_id == f.feature_id ? (
                              <Box key={index} sx={{ margin: '10px' }}>
                                <CognisaasCard
                                  key={index}
                                  clientStatusColor={f.feature_readinessstate_status}
                                  height="280px"
                                  width="360px"
                                >
                                  <CardHeader
                                    sx={{ paddingBottom: '10px', paddingTop: '10px' }}
                                    action={
                                      <IconButton onClick={handleClick} aria-label="settings">
                                        <MoreVertIcon />
                                      </IconButton>
                                    }
                                    title={
                                      <Typography
                                        // onClick={() => props.cardClickHandler(props.data)}
                                        style={{ color: '#000000' }}
                                        variant="subtitle1"
                                      >
                                        {f.feature_description}
                                      </Typography>
                                    }
                                  />
                                  <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    MenuListProps={{
                                      'aria-labelledby': 'basic-button',
                                    }}
                                  >
                                    <MenuItem>
                                      <ListItemIcon>
                                        <DeleteIcon fontSize="small" />
                                      </ListItemIcon>
                                      <Typography variant="inherit">Delete Requirement</Typography>
                                    </MenuItem>
                                  </Menu>
                                  <CardContent sx={{ pt: 0, paddingBottom: '12px' }}>
                                    <Grid container>
                                      <Grid item xs>
                                        <Typography style={{ color: '#7A7A7A' }} variant="subtitle1">
                                          ETA for release
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={3}>
                                        <Typography style={{ color: '#000000' }} variant="subtitle1">
                                          {parseProductRequirementDate(f.feature_estimateddeliverydate)}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </CardContent>
                                  <CardContent sx={{ color: 'black', pt: 0, minHeight: '74px' }}>
                                    <CognisaasTooltip title={f.feature_content}>
                                      <Typography
                                        style={{ color: '#000000' }}
                                        variant="subtitle1"
                                        color="text.secondary"
                                      >
                                        {f.feature_content.length > 80
                                          ? f.feature_content.slice(0, 80) + '...'
                                          : f.feature_content}
                                      </Typography>
                                    </CognisaasTooltip>
                                  </CardContent>
                                  <CardContent>
                                    <CognisaasRequirementStatusDropdown
                                      value={requirementStatus}
                                      changeHandler={handleStatusMenu}
                                      readOnly={true}
                                    />
                                  </CardContent>
                                </CognisaasCard>
                              </Box>
                            ) : null;
                          });
                        })}
                    </Stack>
                  </>
                )}
              </Box>
            </Paper>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          alignItems="center"
          direction="column"
          justifyContent="center"
          sx={{ backgroundColor: '#FFFFFF', minHeight: '664px' }}
        >
          <Typography sx={{ fontSize: '20px', fontWeight: '600' }}>No product requirement mapped</Typography>
          <Button sx={{ mt: '32px' }} variant="contained" onClick={toggleDrawer('right', true)}>
            Add Product
          </Button>
          <NoRecord style={{ marginTop: '58px', height: '256px', width: '340px' }} />
        </Grid>
      )}
    </Box>
  );
};

export default ProductTemplate;
