import { apis } from '../axiosInstance';
import { usecaseRoutes } from '../route';

import Cookies from 'universal-cookie';

const cookies = new Cookies();

const getOpenUsecases = () =>
  apis.phpAxiosInstance.get(usecaseRoutes.openUsecases(cookies.get('cogni_customer_uid') as string), {
    headers: { JWT: 'true' },
  });
const getClosedUsecases = () =>
  apis.phpAxiosInstance.get(usecaseRoutes.closedUsecases(cookies.get('cogni_customer_uid') as string), {
    headers: { JWT: 'true' },
  });
const updateUsecase = (usecase: any) =>
  apis.phpAxiosInstance.post(usecaseRoutes.updateUsecase(), usecase, { headers: { JWT: 'true' } });

const getUsecaseCustomFields = () =>
  apis.phpAxiosInstance.get(usecaseRoutes.getUsecaseCustomFields(cookies.get('cogni_customer_uid') as string), {
    headers: { JWT: 'true' },
  });

const getUsecaseCustomFieldsWithValues = (case_uid: string) =>
  apis.phpAxiosInstance.get(usecaseRoutes.getUsecaseCustomFieldsWithValue(case_uid), { headers: { JWT: 'true' } });

const updateUsecaseByUid = (detail: any) =>
  apis.phpAxiosInstance.post(usecaseRoutes.updateUsecaseByUid(), detail, { headers: { JWT: 'true' } });

const addUsecaseInformation = (detail: any) =>
  apis.phpAxiosInstance.post(usecaseRoutes.addUsecaseInformation(), detail, { headers: { JWT: 'true' } });

const addUsecaseAuditTrial = (detail: any) =>
  apis.phpAxiosInstance.post(usecaseRoutes.addUsecaseAuditTrial(), detail, { headers: { JWT: 'true' } });

const getUsecaseAuditTrail = (case_uid: string) =>
  apis.phpAxiosInstance.get(usecaseRoutes.getUsecaseAuditTrail(case_uid), { headers: { JWT: 'true' } });

const updateUsecaseCustomfield = (detail: any) =>
  apis.phpAxiosInstance.post(usecaseRoutes.updateUsecaseCustomField(), detail, { headers: { JWT: 'true' } });

const getUsecaseByUid = (case_uid: any) =>
  apis.phpAxiosInstance.get(usecaseRoutes.getUsecaseByUid(case_uid), { headers: { JWT: 'true' } });

const deleteUsecase = (detail: any) =>
  apis.phpAxiosInstance.post(usecaseRoutes.deleteUsecase(), detail, { headers: { JWT: 'true' } });

const getAllUsecasesCustomField = () =>
  apis.phpAxiosInstance.get(
    usecaseRoutes.getCustomFieldValuesOfAllUsecases(cookies.get('cogni_customer_uid') as string),
    { headers: { JWT: 'true' } }
  );

const getUsecaseComments = (case_uid: string) =>
  apis.phpAxiosInstance.get(usecaseRoutes.getUsecaseCommentsByCaseUid(case_uid), {
    headers: { JWT: 'true' },
  });

const addUsecaseCommentByCaseUid = (detail: any) =>
  apis.phpAxiosInstance.post(usecaseRoutes.addUsecaseComment(), detail, { headers: { JWT: 'true' } });

const updateUsecaseLevelCommentDetails = (detail: any) =>
  apis.phpAxiosInstance.post(usecaseRoutes.updateUsecaseLevelComment(), detail, { headers: { JWT: 'true' } });

const deleteUsecaseLevelComment = (detail: any) =>
  apis.phpAxiosInstance.post(usecaseRoutes.deleteUsecaseLevelComment(), detail, { headers: { JWT: 'true' } });

export const usecaseAPI = {
  getOpenUsecases,
  getClosedUsecases,
  updateUsecase,
  getUsecaseCustomFields,
  getUsecaseCustomFieldsWithValues,
  updateUsecaseByUid,
  addUsecaseInformation,
  addUsecaseAuditTrial,
  getUsecaseAuditTrail,
  updateUsecaseCustomfield,
  getUsecaseByUid,
  deleteUsecase,
  getAllUsecasesCustomField,
  getUsecaseComments,
  addUsecaseCommentByCaseUid,
  updateUsecaseLevelCommentDetails,
  deleteUsecaseLevelComment,
};
