import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import {
  Card,
  Grid,
  TextField,
  ButtonGroup,
  Button,
  Autocomplete,
  Checkbox,
  ListItemText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Snackbar,
  Alert,
  Stack,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ReactComponent as SearchIcon } from '../../../assets/svgs/search.svg';
import { ReactComponent as EditPen } from '../../../assets/svgs/edit_pen.svg';
import CognisaasDropdown from '../../shared/CognisaasDropdown/CognisaasDropdown';
import {
  getPolicyOnResources_slice,
  addConditionsToPolicy_slice,
  getConditions_slice,
  getResourcesFieldValue_slice,
  getAllConditions_slice,
  getResourcesField_slice,
  getAllResources_slice,
  getGeneralFieldValue_slice,
  removeCondition_slice,
  getAllPermissions_slice,
} from '../../../store/modules/RBAC/slice';
import {
  getResourcesField_selector,
  getAllConditions_selector,
  getConditions_selector,
  getPolicyOnResources_selector,
  getAllPermissions_selector,
  getAllResources_selector,
  getGeneralFieldValues_selector,
  getResourcesFieldValues_selector,
} from '../../../store/modules/RBAC/selector';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../store/hooks';
// import CognisaasButton from '../../shared/CognisaasButton/CognisaasButton';
// import { useExpanded } from 'react-table';
import { useNavigate } from 'react-router-dom';
import CognisaaasTooltip from '../../shared/CognisaasTooltip/CognisaasTooltip';
import ClearIcon from '@mui/icons-material/Clear';
import CognisaasSelect from '../../shared/CognisaasSelect/CognisaasSelect';
import { requestState } from 'store/modules/types';
import CognisaasSpinner from 'components/shared/CognisaasSpinner/CognisaasSpinner';
import { label_project } from 'components/Settings/Preferences/CustomLabelCookieHelper';

const imgLink = 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png';

interface Data {
  permissionType: string;
}

function createData(permissionType: string): Data {
  return {
    permissionType,
  };
}

const rows = [
  createData(`View Only My ${label_project}s`),
  createData(`Edit Only My ' + ${label_project}s`),
  createData(`View ' + ${label_project}s of People reporting to me`),
  createData('CEO'),
];

type Order = 'asc' | 'desc';

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array: any, order: 'asc' | 'desc') {
  console.log('STABLE SORT');
  console.log({ array });
  const data = array.slice().sort((a: any, b: any) => {
    if (order === 'asc') {
      return a.name.localeCompare(b.name);
    } else {
      return b.name.localeCompare(a.name);
    }
  });
  return data;
}
interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'permissionType',
    numeric: false,
    disablePadding: true,
    label: 'Custom Permissions',
  },
];

interface UserCardComponentProps {
  permissionType: string;
  backColor: string;
  boxValue: string;
  isPulic: boolean;
  tabIndex: number;
  displayProfile?: boolean;
}

const UserCardComponent = (props: UserCardComponentProps) => {
  return (
    <Card
      sx={{
        margin: '10px',
        backgroundColor: props?.backColor,
        padding: '18px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Grid sx={{ display: 'flex', backgroundColor: 'grey', alignItems: 'center' }}>
        {props.displayProfile && (
          <img src={imgLink} style={{ height: '45px', width: '45px', borderRadius: '50%', marginRight: '10px' }} />
        )}
        <Typography variant="h3">{props?.permissionType}</Typography>
        {props.tabIndex == 1 ? (
          <Grid
            sx={{
              padding: '3px 7px',
              marginLeft: '10px',
              backgroundColor: 'white',
              border: '1px black solid',
              borderRadius: '5px',
            }}
          >
            <Typography variant="h3">{props?.boxValue}</Typography>
          </Grid>
        ) : null}
      </Grid>
      <Grid>
        <Typography variant="subtitle1" className="label-text">
          {props?.isPulic ? '(Global)' : null}
        </Typography>
      </Grid>
    </Card>
  );
};

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  searchValue: any;
  setSearchValue: any;
  selectedIndex: any;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const [showSearchIcon, setShowSearchIcon] = useState(true);
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            // padding={headCell.disablePadding ? 'none' : 'normal'}
            sx={{ padding: '20px !important', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <Typography variant={'h2'}>{props.selectedIndex == 1 ? 'Policies' : 'Conditions'}</Typography>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
            {showSearchIcon ? (
              <SearchIcon
                onClick={() => {
                  setShowSearchIcon(false);
                }}
              />
            ) : (
              <TextField
                placeholder="Start typing name..."
                value={props.searchValue}
                onBlur={() => {
                  setShowSearchIcon(true);
                }}
                onChange={(e: any) => {
                  props.setSearchValue(e.target.value);
                }}
                id="standard-basic"
                variant="standard"
              />
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

// interface rightSideComponent_payload {
//   resourcesAllValues: any;
//   resourcesCurrentValue: string;
//   setResourcesCurrentValue: any;
// }

interface UserInfo_RightSideComponent_payload {
  allData: any;
  selectedPermission: any;
  getAllPermissions_fromSelectorStatus?: requestState;
  getConditions_fromSelectorStatus?: requestState;
}

const UserInfo_RightSideComponent_policy = (props: UserInfo_RightSideComponent_payload) => {
  const dispatch = useDispatch();

  // const [rolesDropdown, setRolesDropdown] = useState<any>('View');
  const [activeButton, setActiveButton] = useState(1);
  const [resourcesField, setResourcesField] = useState<any[]>([]);
  const [allResources, setAllResources] = useState<string[]>([]);
  const [resourcesDropdown, setResourcesDropdown] = useState<any>('');
  const [allConditions, setAllConditions] = useState<any[]>([]);
  const [selectedConditions, setSelectedConditions] = useState<any[]>([]);
  const [autoCompleteValue, setAutoCompleteValue] = useState('');
  const [selectedAccessDropdown, setSelectedAccessDropdown] = useState<any>([]);
  const [conditionResourceDropdown, setConditionResourceDropdown] = useState('');
  const [conditionResourceFieldValue, setConditionResourceFieldValue] = useState<any>([]);
  const [conditionResourceGeneralFieldValue, setConditionResourceGeneralFieldValue] = useState<any>([]);
  const [fieldValueDropDownValue, setFieldValueDropDownValue] = useState<any>('');
  const [selectedOperator, setSelectedOperator] = useState<string>('');
  const [selectedFieldType, setSelectedFieldType] = useState<string>('');
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarText, setSnackBarText] = useState('');

  // const switchInsightTabs = (event: any, tabIndex: number) => {
  //   setTabIndex(tabIndex);
  //   };
  const getResourcesField_fromSelector = useAppSelector(getResourcesField_selector).data;
  const getAllResources_fromSelector = useAppSelector(getAllResources_selector).data;
  const getAllConditions_fromSelector = useAppSelector(getAllConditions_selector).data;
  const getGeneralFieldValues_fromselector = useAppSelector(getGeneralFieldValues_selector).data;
  const getResourcesFieldValues_fromSelector = useAppSelector(getResourcesFieldValues_selector).data;
  const getPolicyOnResources_fromSelector = useAppSelector(getPolicyOnResources_selector).data;
  // const getConditions_fromSelector = useAppSelector(getConditions_selector).data;

  const operatorMapping: any = {
    EQ: 'is',
    IN: 'is in',
    NEQ: 'is not',
    NIN: 'is not in',
    HAS: 'has',
    HASN: 'has not',
  };

  useEffect(() => {
    setResourcesDropdown(props?.selectedPermission?.resource?.display_name);
    setSelectedConditions(props?.selectedPermission?.conditions);
    if (props?.selectedPermission?.effect == 'A') {
      setActiveButton(1);
    } else {
      setActiveButton(2);
    }
  }, [props.selectedPermission]);

  useEffect(() => {
    dispatch(getAllResources_slice());
    dispatch(getGeneralFieldValue_slice());
    dispatch(getConditions_slice());
  }, []);

  useEffect(() => {
    if (getPolicyOnResources_fromSelector) {
      setSelectedAccessDropdown(getPolicyOnResources_fromSelector[0]?.actions);
    }
  }, [getPolicyOnResources_fromSelector]);

  useEffect(() => {
    setConditionResourceGeneralFieldValue(getGeneralFieldValues_fromselector);
  }, [getGeneralFieldValues_fromselector]);

  useEffect(() => {
    setConditionResourceFieldValue(getResourcesFieldValues_fromSelector);
  }, [getResourcesFieldValues_fromSelector]);

  useEffect(() => {
    setAllConditions(getAllConditions_fromSelector);
  }, [getAllConditions_fromSelector]);

  useEffect(() => {
    if (getAllResources_fromSelector) {
      const resourceFieldId = getAllResources_fromSelector?.filter((obj: any) => {
        return obj.display_name == resourcesDropdown;
      });

      dispatch(getResourcesField_slice({ resource: resourceFieldId[0]?.id || '' }));
      // setSelectedConditions([]);
    }
  }, [resourcesDropdown, getAllResources_fromSelector]);

  useEffect(() => {
    const resourceFieldId = getAllResources_fromSelector?.filter((obj: any) => {
      return obj.display_name == resourcesDropdown;
    });

    if (resourceFieldId.length > 0) {
      dispatch(
        getPolicyOnResources_slice({
          resource: resourceFieldId[0]?.id,
        })
      );

      // uncomment it

      dispatch(
        getAllConditions_slice({
          resource: resourceFieldId[0]?.id,
        })
      );
    }
  }, [resourcesDropdown, getAllResources_fromSelector]);

  useEffect(() => {
    setResourcesField(getResourcesField_fromSelector);
  }, [getResourcesField_fromSelector]);

  useEffect(() => {
    const temp = getAllResources_fromSelector?.map((obj: any) => {
      return obj.display_name;
    });
    setAllResources(temp);
    setResourcesDropdown(temp[0]);
  }, [getAllResources_fromSelector]);

  useEffect(() => {
    if (getAllResources_fromSelector) {
      const tempFilter = resourcesField?.filter((obj: any) => {
        return obj.display_name == conditionResourceDropdown;
      });

      const resourceFieldId = getAllResources_fromSelector?.filter((obj: any) => {
        return obj.display_name == resourcesDropdown;
      });

      if (tempFilter) {
        dispatch(
          getResourcesFieldValue_slice({
            resource: resourceFieldId[0]?.id || '',
            field: tempFilter[0]?.id || '',
          })
        );
      }
    }
  }, [conditionResourceDropdown, getAllResources_fromSelector]);

  useEffect(() => {
    const dropDownForSelectedField = resourcesField.filter((r: any) => r.display_name == conditionResourceDropdown);
    if (dropDownForSelectedField.length > 0) {
      setSelectedFieldType(dropDownForSelectedField[0].type);
    }
  }, [conditionResourceDropdown]);

  useEffect(() => {
    if (selectedConditions && selectedConditions.length > 0) {
      setSelectedOperator(operatorMapping[selectedConditions[0]?.operator]);
    }
  }, [selectedConditions]);

  const onChickActiveButton = (buttonNumber: number) => {
    setActiveButton(buttonNumber);
  };

  const handleChangeAccessDropdown = (event: any) => {
    const option = event.target.value;
    setSelectedAccessDropdown(option);
  };

  const isCheckBoxChecked = (option: any) => {
    const temp = selectedAccessDropdown?.filter((obj: any) => {
      return obj?.id == option.id;
    });
    return temp?.length > 0;
  };

  const isString = (item: any): item is string => {
    return typeof item === 'string';
  };

  const onClickAutoCompleteDropdown = (obj: any) => {
    const filteredCondition = allConditions?.filter((ins: any) => {
      return ins?.display_name == obj?.target.value;
    });

    const tempFilter = selectedConditions?.filter((ins: any) => {
      return filteredCondition[0].id == ins.id;
    });

    if (filteredCondition.length > 0 && tempFilter.length == 0) {
      const temp = [...selectedConditions, filteredCondition[0]];
      setSelectedConditions(temp);
    }
    setAutoCompleteValue('');

    const payload = {
      policyId: props?.selectedPermission?.id,
      conditionIds: [filteredCondition[0]?.id],
    };
    dispatch(addConditionsToPolicy_slice(payload));
  };

  const onClickClearCondition = (obj: any) => {
    const temp = selectedConditions?.filter((ins: any) => {
      return obj.id != ins.id;
    });
    setSelectedConditions(temp);
    const payload = {
      policyId: props?.selectedPermission?.id,
      conditionIds: [obj?.id],
    };
    dispatch(removeCondition_slice(payload));
    setSnackBarText('Condition removed successfully');
    setOpenSnackBar(true);
  };

  const handleCloseSnackBar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackBar(false);
  };

  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        padding: '20px',
        height: '70vh',
        width: '50%',
        overflow: 'scroll',
      }}
    >
      <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Grid>
          <Typography variant="h2">View {label_project}s of People reporting to me</Typography>
          {props?.selectedPermission?.is_public && (
            <>
              <Stack direction="row" style={{ marginTop: '10px' }}>
                <div
                  style={{
                    padding: '3px 10px',
                    textAlign: 'center',
                    borderRadius: '4px',
                    border: '1px solid #3D38BA',
                    width: 'fit-content',
                  }}
                >
                  <Typography variant="subtitle1" color="#3D38BA">
                    Cognisaas Managed
                  </Typography>
                </div>
                <div
                  style={{
                    width: 'fit-content',
                    padding: '3px 10px',
                    borderRadius: '4px',
                    border: '1px solid #0061bb',
                    backgroundColor: '#d8ecff',
                    marginLeft: '12px',
                  }}
                >
                  <Typography variant="subtitle1" color="#0061bb">
                    The policy is managed by Cognisaas, cannot be edited
                  </Typography>
                </div>
              </Stack>
            </>
          )}
          <Typography variant="h3">
            {props?.selectedPermission?.isPulic ? (
              <Grid
                sx={{
                  padding: '3px 7px',
                  marginTop: '5px',
                  maxWidth: '150px',
                  border: '1px solid #5F7CFF',
                  borderRadius: '5px',
                }}
              >
                Custom Permission
              </Grid>
            ) : null}
          </Typography>
        </Grid>
        {props?.selectedPermission?.isPulic ? (
          <CognisaaasTooltip title="Public Policy cannot be edited!">
            <EditPen />
          </CognisaaasTooltip>
        ) : (
          <EditPen style={{ cursor: 'pointer' }} />
        )}
      </Grid>
      {props.selectedPermission &&
      Object.keys(props.selectedPermission).length > 0 &&
      props.getAllPermissions_fromSelectorStatus !== requestState.loading ? (
        <>
          <Grid sx={{ marginTop: '35px' }}>
            <Typography variant={'h2'}>Effect </Typography>
            <ButtonGroup sx={{ marginTop: '15px' }} variant="outlined" aria-label="outlined button group">
              <Button
                className={activeButton == 1 ? 'active-btn' : undefined}
                onClick={() => {
                  onChickActiveButton(1);
                }}
              >
                <Typography variant={'subtitle1'}>Allow</Typography>
              </Button>
              <Button
                className={activeButton == 2 ? 'active-btn' : undefined}
                onClick={() => {
                  onChickActiveButton(2);
                }}
              >
                <Typography variant={'subtitle1'}>Deny</Typography>
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid sx={{ marginTop: '35px' }}>
            <Typography variant={'h2'}>Resources</Typography>
            <CognisaasDropdown
              sx={{ marginTop: '10px' }}
              name="resources-dropdown"
              type="dropdown"
              validvalues={allResources}
              onChange={(e: any) => setResourcesDropdown(e.target.value)}
              value={resourcesDropdown}
              readOnly={props?.selectedPermission?.is_public || false}
            />
          </Grid>
          <Grid sx={{ marginTop: '35px' }}>
            <Typography variant={'h2'}>Access</Typography>
            <FormControl>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={selectedAccessDropdown || []}
                onChange={handleChangeAccessDropdown}
                // input={<OutlinedInput label="Tag" />}
                renderValue={(selected: any) => {
                  const temp = selected?.map((obj: any) => {
                    return obj?.name + ', ';
                  });
                  return temp;
                }}
                readOnly={props?.selectedPermission?.is_public || false}
              >
                {getPolicyOnResources_fromSelector[0]?.resource?.actions?.map((obj: any) => (
                  <MenuItem key={obj} value={obj}>
                    <Checkbox checked={isCheckBoxChecked(obj)} />
                    <ListItemText primary={obj.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid sx={{ marginTop: '35px' }}>
            <Typography variant={'h2'} marginTop="25px" marginBottom="10px">
              Conditions{' '}
            </Typography>
            <Autocomplete
              size="small"
              options={allConditions}
              fullWidth
              value={autoCompleteValue}
              getOptionLabel={(option: any) => (isString(option['description']) ? option['description'] : '')}
              renderInput={(params: any) => {
                return <TextField {...params} placeholder="search condition..." />;
              }}
              onSelect={onClickAutoCompleteDropdown}
              readOnly={props?.selectedPermission?.is_public || false}
            />
            {selectedConditions?.map((obj: any) => {
              return (
                <Card sx={{ marginTop: '25px', display: 'flex', justifyContent: 'space-between' }} key={obj.id}>
                  <Accordion
                    elevation={0}
                    onChange={(ins: any, expanded: any) => {
                      if (expanded) {
                        const temp1 = resourcesField?.filter((ins: any) => {
                          return ins.name == JSON.parse(obj.conditional_key).keyName;
                        });
                        setConditionResourceDropdown(temp1[0].display_name);

                        const temp2 = conditionResourceGeneralFieldValue?.filter((ins: any) => {
                          return ins.name == JSON.parse(obj?.conditional_value)?.value;
                        });
                        if (temp2.length > 0) {
                          setFieldValueDropDownValue(temp2[0].display_name);
                        } else {
                          const temp3 = conditionResourceFieldValue?.filter((ins: any) => {
                            return ins.name == JSON.parse(obj?.conditional_value)?.value;
                          });
                          setFieldValueDropDownValue(temp3[0].display_name);
                        }
                      }
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      sx={{ width: '400px' }}
                    >
                      <Typography variant="h2">{obj.name}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid>
                        <Grid sx={{ display: 'flex', alignItems: 'center' }}>
                          <Typography variant="h2">Where {resourcesDropdown}</Typography>
                          <Grid sx={{ marginLeft: '10px' }}>
                            <FormControl fullWidth>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={conditionResourceDropdown}
                                // label="Age"
                                onChange={(e: any) => setConditionResourceDropdown(e.target.value)}
                                readOnly={props?.selectedPermission?.is_public || false}
                              >
                                {resourcesField?.map((obj: any) => {
                                  return (
                                    <MenuItem key={obj} value={obj?.display_name}>
                                      <Typography variant="h2">{obj?.display_name}</Typography>
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Grid sx={{ marginTop: '15px' }}>
                          <Typography variant={'h2'} style={{ marginBottom: '5px' }}>
                            Operator
                          </Typography>
                          <CognisaasSelect
                            width="310px"
                            value={selectedOperator}
                            validValues={['is', 'is_not', 'is_in', 'is_not_in', 'has', 'has_not']}
                            disabledValues={
                              selectedFieldType == 'ARR' ? ['is', 'is_not', 'is_in', 'is_not_in'] : ['has', 'has_not']
                            }
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                              setSelectedOperator((e.target as HTMLInputElement).value)
                            }
                            readOnly={props?.selectedPermission?.is_public || false}
                          />
                          <Grid>
                            <Typography marginBottom="10px" variant={'h2'}>
                              Value
                            </Typography>
                            <Grid sx={{ width: '150px', height: '70px' }}>
                              <FormControl>
                                <Select
                                  size="small"
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={fieldValueDropDownValue}
                                  onChange={(e: any) => setFieldValueDropDownValue(e.target.value)}
                                  readOnly={props?.selectedPermission?.is_public || false}
                                >
                                  <Typography sx={{ color: '#3978FF', margin: '5px 8px' }} variant="h3">
                                    General
                                  </Typography>
                                  {conditionResourceGeneralFieldValue?.map((obj: any) => {
                                    return (
                                      <MenuItem key={obj} value={obj?.display_name}>
                                        <Typography variant="h2">{obj?.display_name}</Typography>
                                      </MenuItem>
                                    );
                                  })}
                                  <Typography sx={{ color: '#3978FF', margin: '5px 8px' }} variant="h3">
                                    Auto Values
                                  </Typography>
                                  {conditionResourceFieldValue?.map((obj: any) => {
                                    return (
                                      <MenuItem key={obj} value={obj?.display_name}>
                                        <Typography variant="h2">{obj?.display_name}</Typography>
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                  <Grid sx={{ marginTop: '4px', marginRight: '5px' }}>
                    <IconButton aria-label="delete" size="small">
                      {props?.selectedPermission?.is_public && (
                        <ClearIcon
                          fontSize="inherit"
                          onClick={() => {
                            onClickClearCondition(obj);
                          }}
                        />
                      )}
                    </IconButton>
                  </Grid>
                </Card>
              );
            })}

            {/* <Button onClick={addConditionhandler} variant='outlined' sx={{marginTop: '25px'}} >Add Condition</Button> */}

            <Snackbar open={openSnackBar} autoHideDuration={4000} onClose={handleCloseSnackBar}>
              <Alert onClose={handleCloseSnackBar} severity="success" sx={{ width: '100%' }}>
                {snackBarText}
              </Alert>
            </Snackbar>
          </Grid>
        </>
      ) : (
        <Typography>Please select conditions!</Typography>
      )}
    </Paper>
  );
};

const UserInfo_RightSideComponent_condition = (props: UserInfo_RightSideComponent_payload) => {
  const dispatch = useDispatch();

  const [rolesDropdown, setRolesDropdown] = useState<any>('');
  const [activeButton, setActiveButton] = useState(1);
  const [resourcesField, setResourcesField] = useState<any[]>([]);
  const [allResources, setAllResources] = useState<any[]>([]);
  const [userDropdown, setUserDropdown] = useState<any>('');
  const [resourcesDropdown, setResourcesDropdown] = useState<any>(allResources[0] ? allResources[0] : '');
  const getResourcesField_fromSelector = useAppSelector(getResourcesField_selector).data;
  const getAllResources_fromSelector = useAppSelector(getAllResources_selector).data;
  const [conditionResourceGeneralFieldValue, setConditionResourceGeneralFieldValue] = useState<any>([]);
  const [conditionResourceFieldValue, setConditionResourceFieldValue] = useState<any>([]);
  const getResourcesFieldValues_fromSelector = useAppSelector(getResourcesFieldValues_selector).data;
  const getGeneralFieldValues_fromselector = useAppSelector(getGeneralFieldValues_selector).data;
  const [selectedFieldType, setSelectedFieldType] = useState<string>('');
  const [selectedOperator, setSelectedOperator] = useState<string>('');

  const operatorMapping = {
    EQ: 'is',
    IN: 'is in',
    NEQ: 'is not',
    NIN: 'is not in',
    HAS: 'has',
    HASN: 'has not',
  };
  console.log(activeButton);
  useEffect(() => {
    const resourceId = props?.selectedPermission?.resource;
    const filteredResource = getAllResources_fromSelector?.filter((obj: any) => {
      return obj?.id == resourceId;
    });
    setResourcesDropdown(filteredResource[0]?.display_name);

    setActiveButton(props?.selectedPermission?.operator == 'EQ' ? 1 : 2);
    setSelectedOperator(operatorMapping[props?.selectedPermission?.operator as keyof typeof operatorMapping]);
    const parsedResourceFieldValue = JSON.parse(
      props?.selectedPermission?.conditional_value || JSON.stringify({})
    ).value;
    setRolesDropdown(parsedResourceFieldValue);
  }, [props.selectedPermission]);

  useEffect(() => {
    if (getResourcesField_fromSelector) {
      const resourceFieldTemp = getResourcesField_fromSelector?.filter((obj: any) => {
        const temp = JSON.parse(props?.selectedPermission?.conditional_key || JSON.stringify({}))?.keyName;
        return obj?.display_name == temp;
      });
      if (resourceFieldTemp.length > 0) {
        setUserDropdown(resourceFieldTemp[0]?.display_name || '');
      }
      setUserDropdown(resourceFieldTemp[0]?.display_name || '');

      const resourceFieldTemp2 = getResourcesField_fromSelector?.filter((obj: any) => {
        const temp = JSON.parse(props?.selectedPermission?.conditional_key || JSON.stringify({}))?.keyName;
        return obj?.name == temp;
      });
      if (resourceFieldTemp2.length > 0) {
        setUserDropdown(resourceFieldTemp2[0]?.display_name || '');
      }
    }
  }, [resourcesDropdown, getResourcesField_fromSelector]);

  useEffect(() => {
    if (getResourcesFieldValues_fromSelector && getGeneralFieldValues_fromselector) {
      const parsedResourceFieldValue = JSON.parse(
        props?.selectedPermission?.conditional_value || JSON.stringify({})
      ).value;
      setRolesDropdown(parsedResourceFieldValue);
    }
  }, [getResourcesFieldValues_fromSelector, getGeneralFieldValues_fromselector]);

  useEffect(() => {
    dispatch(getAllResources_slice());
    dispatch(getGeneralFieldValue_slice());
  }, []);

  useEffect(() => {
    if (getGeneralFieldValues_fromselector) {
      setConditionResourceGeneralFieldValue(getGeneralFieldValues_fromselector);
    }
  }, [getGeneralFieldValues_fromselector]);

  useEffect(() => {
    if (getResourcesFieldValues_fromSelector) {
      setConditionResourceFieldValue(getResourcesFieldValues_fromSelector);
    }
  }, [getResourcesFieldValues_fromSelector]);

  useEffect(() => {
    const tempFilter = resourcesField?.filter((obj: any) => {
      return obj.display_name === userDropdown;
    });

    const resourceFieldId = getAllResources_fromSelector?.filter((obj: any) => {
      return obj.display_name === resourcesDropdown;
    });

    dispatch(
      getResourcesFieldValue_slice({
        resource: resourceFieldId[0]?.id || '',
        field: tempFilter[0]?.id || '',
      })
    );
  }, [resourcesDropdown, userDropdown, getAllResources_fromSelector]);

  useEffect(() => {
    const resourceFieldId = getAllResources_fromSelector?.filter((obj: any) => {
      return obj.display_name == resourcesDropdown;
    });

    dispatch(getResourcesField_slice({ resource: resourceFieldId[0]?.id || '' }));
  }, [resourcesDropdown, getAllResources_fromSelector]);

  useEffect(() => {
    const resourceFieldId = getAllResources_fromSelector?.filter((obj: any) => {
      return obj.display_name == resourcesDropdown;
    });

    dispatch(
      getPolicyOnResources_slice({
        resource: resourceFieldId[0]?.id,
      })
    );
  }, [resourcesDropdown]);

  useEffect(() => {
    if (getResourcesField_fromSelector) {
      setResourcesField(getResourcesField_fromSelector);
      // console.log('getResourcesField_fromSelector: ', getResourcesField_fromSelector);
    }
  }, [getResourcesField_fromSelector]);

  useEffect(() => {
    const temp = getAllResources_fromSelector?.map((obj: any) => {
      return obj.display_name;
    });
    setAllResources(temp);
  }, [getAllResources_fromSelector]);

  // const onChickActiveButton = (buttonNumber: number) => {
  //   setActiveButton(buttonNumber);
  // };

  useEffect(() => {
    const dropDownForSelectedField = resourcesField.filter((r: any) => r.display_name == userDropdown);
    if (dropDownForSelectedField.length > 0) {
      setSelectedFieldType(dropDownForSelectedField[0].type);
    }
  }, [userDropdown]);

  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        padding: '20px',
        height: '70vh',
        width: '50%',
        overflow: 'scroll',
      }}
    >
      <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Grid>
          <Typography variant="h2">View {label_project}s of People reporting to me</Typography>
          {props?.selectedPermission?.is_public && (
            <>
              <Stack direction="row" style={{ marginTop: '10px' }}>
                <div
                  style={{
                    padding: '3px 10px',
                    textAlign: 'center',
                    borderRadius: '4px',
                    border: '1px solid #3D38BA',
                    width: 'fit-content',
                  }}
                >
                  <Typography variant="subtitle1" color="#3D38BA">
                    Cognisaas Managed
                  </Typography>
                </div>
                <div
                  style={{
                    width: 'fit-content',
                    padding: '3px 10px',
                    borderRadius: '4px',
                    border: '1px solid #0061bb',
                    backgroundColor: '#d8ecff',
                    marginLeft: '12px',
                  }}
                >
                  <Typography variant="subtitle1" color="#0061bb">
                    The policy is managed by Cognisaas, cannot be edited
                  </Typography>
                </div>
              </Stack>
            </>
          )}
          <Typography variant="h3">
            {props?.selectedPermission?.isPulic ? (
              <Grid
                sx={{
                  padding: '3px 7px',
                  marginTop: '5px',
                  maxWidth: '150px',
                  border: '1px solid #5F7CFF',
                  borderRadius: '5px',
                }}
              >
                Custom Conditions
              </Grid>
            ) : null}
          </Typography>
          <Typography marginTop="20px" variant="h2">
            {props?.selectedPermission?.display_name}
          </Typography>
        </Grid>
        {props?.selectedPermission?.isPulic ? (
          <CognisaaasTooltip title="Public Policy cannot be edited!">
            <EditPen />
          </CognisaaasTooltip>
        ) : (
          <EditPen style={{ cursor: 'pointer' }} />
        )}
      </Grid>
      {props.selectedPermission &&
      Object.keys(props.selectedPermission).length > 0 &&
      props.getConditions_fromSelectorStatus !== requestState.loading ? (
        <>
          <Grid sx={{ marginTop: '35px' }}>
            <Typography marginBottom="10px" variant="h2">
              Resources
            </Typography>
            <FormControl>
              <CognisaasDropdown
                sx={{ marginTop: '10px' }}
                name="resources-dropdown"
                type="dropdown"
                validvalues={allResources}
                onChange={(e: any) => setResourcesDropdown(e.target.value)}
                value={resourcesDropdown}
                readOnly={props?.selectedPermission?.is_public || false}
              />
            </FormControl>
          </Grid>

          <Grid sx={{ display: 'flex', marginTop: '40px', alignItems: 'center' }}>
            <Typography variant="h2" marginRight="15px">
              Where {resourcesDropdown}
            </Typography>
            <FormControl>
              <Select
                size="small"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={userDropdown}
                // label="Age"
                onChange={(e) => setUserDropdown(e.target.value)}
                readOnly={props?.selectedPermission?.is_public || false}
              >
                {resourcesField?.map((obj: any) => {
                  // console.log(obj);
                  return (
                    <MenuItem key={obj} value={obj?.display_name}>
                      <Typography variant="h2">{obj?.display_name}</Typography>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>

          <Grid sx={{ marginTop: '35px' }}>
            <Typography>Operator</Typography>
            <CognisaasSelect
              width="310px"
              value={selectedOperator}
              validValues={['is', 'is not', 'is in', 'is not in', 'has', 'has not']}
              disabledValues={selectedFieldType == 'ARR' ? ['is', 'is not', 'is in', 'is not in'] : ['has', 'has not']}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSelectedOperator((e.target as HTMLInputElement).value)
              }
              readOnly={props?.selectedPermission?.is_public || false}
            />
          </Grid>

          <Grid sx={{ marginTop: '35px' }}>
            <Typography marginBottom="10px" variant={'h2'}>
              Value
            </Typography>
            <Grid sx={{ width: '150px', height: '70px' }}>
              <FormControl>
                <Select
                  size="small"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={rolesDropdown}
                  // label="Age"
                  onChange={(e: any) => setRolesDropdown(e.target.value)}
                  readOnly={props?.selectedPermission?.is_public || false}
                >
                  <Typography sx={{ color: '#3978FF', margin: '5px 8px' }} variant="h3">
                    General
                  </Typography>
                  {conditionResourceGeneralFieldValue &&
                    conditionResourceGeneralFieldValue?.map((obj: any) => {
                      return (
                        <MenuItem key={obj} value={obj?.name}>
                          <Typography variant="h2">{obj?.display_name}</Typography>
                        </MenuItem>
                      );
                    })}
                  <Typography sx={{ color: '#3978FF', margin: '5px 8px' }} variant="h3">
                    Auto Values
                  </Typography>
                  {conditionResourceFieldValue &&
                    conditionResourceFieldValue?.map((obj: any) => {
                      return (
                        <MenuItem key={obj} value={obj?.name}>
                          <Typography variant="h2">{obj?.display_name}</Typography>
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </>
      ) : (
        <Typography>Please select conditions!</Typography>
      )}
    </Paper>
  );
};

interface CustomPermissionsPayload {
  usersData: any;
  selectedUser: any;
  setSelectedUser: any;
  allPermissions: any;
}

const CustomPermissions = (props: CustomPermissionsPayload) => {
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof Data>('permissionType');
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [totalData, setTotalData] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [totalData_condition, setTotalData_condition] = useState<any[]>([]);
  const [filteredConditionData, setFilteredConditionData] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [activeButton, setActiveButton] = useState(1);
  const getAllPermissions_fromSelector = useAppSelector(getAllPermissions_selector).data;
  const getAllPermissions_fromSelectorStatus = useAppSelector(getAllPermissions_selector).status;
  const navigate = useNavigate();
  const getConditions_fromSelector = useAppSelector(getConditions_selector).data;
  const getConditions_fromSelectorStatus = useAppSelector(getConditions_selector).status;
  const dispatch = useDispatch();

  useEffect(() => {
    setTotalData(getAllPermissions_fromSelector);
    setFilteredData(getAllPermissions_fromSelector);
    setSelectedRow(getAllPermissions_fromSelector[0]);
  }, [getAllPermissions_fromSelector]);

  useEffect(() => {
    dispatch(getConditions_slice());
    dispatch(
      getAllPermissions_slice({
        role: props?.selectedUser?.role?.id || '',
      })
    );
  }, []);

  useEffect(() => {
    if (getConditions_fromSelector) {
      if (activeButton != 1) {
        setSelectedRow(getConditions_fromSelector[0]);
      }
    }
  }, [activeButton]);

  useEffect(() => {
    if (getConditions_fromSelector) {
      setTotalData_condition(getConditions_fromSelector);
      setFilteredConditionData(getConditions_fromSelector);
    }
  }, [getConditions_fromSelector]);

  useEffect(() => {
    if (activeButton == 1) {
      const filterNames = totalData?.filter((rowItem: any) => {
        return searchValue.length > 0 ? rowItem?.name?.includes(searchValue) : true;
      });
      setFilteredData(filterNames);
      setSelected([]);
    } else {
      const filterNames = totalData_condition?.filter((rowItem: any) => {
        return searchValue.length > 0 ? rowItem?.name?.includes(searchValue) : true;
      });
      setFilteredConditionData(filterNames);
      setSelected([]);
    }
  }, [searchValue]);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = () => {
    console.log('handleSelectAllClick clicked');
  };

  return (
    <Grid sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <ButtonGroup
          sx={{ marginBottom: '20px', height: '45px' }}
          variant="outlined"
          aria-label="outlined button group"
        >
          <Button
            sx={{ width: '110px' }}
            className={activeButton == 1 ? 'active-btn' : undefined}
            onClick={() => {
              setActiveButton(1);
            }}
          >
            <Typography variant={'h2'}>Policies</Typography>
          </Button>
          <Button
            sx={{ width: '120px' }}
            className={activeButton == 2 ? 'active-btn' : undefined}
            onClick={() => {
              setActiveButton(2);
            }}
          >
            <Typography variant={'h2'}>Conditions</Typography>
          </Button>
        </ButtonGroup>
        <Grid>
          {activeButton == 1 ? (
            <Button
              variant="contained"
              sx={{ width: '130px', height: '45px' }}
              onClick={() => {
                navigate('/access-management/add-policy', {
                  state: {
                    data: selectedRow,
                  },
                });
              }}
            >
              <Typography variant={'h2'}>Add Policy</Typography>
            </Button>
          ) : (
            <Button
              variant="contained"
              sx={{ width: '190px', height: '45px' }}
              onClick={() => {
                navigate('/access-management/add-condition');
              }}
            >
              <Typography variant={'h2'}>Add Conditions</Typography>
            </Button>
          )}
        </Grid>
      </Grid>
      {activeButton == 1 ? (
        <Grid sx={{ display: 'flex' }}>
          <Paper
            sx={{ height: '75vh', width: '50% !important', margin: '0 15px 0 0', flexGrow: 1, overflowY: 'hidden' }}
          >
            {getAllPermissions_fromSelectorStatus === requestState.loading ? (
              <Stack justifyContent="center" alignItems="center" height="100%">
                <CognisaasSpinner size={50} thickness={3} />
              </Stack>
            ) : (
              <TableContainer sx={{ maxHeight: '100%' }}>
                <Table stickyHeader={true} aria-labelledby="tableTitle">
                  <EnhancedTableHead
                    selectedIndex={1}
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                  />
                  <TableBody>
                    {stableSort(filteredData, order).map((row: any, index: number) => {
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          hover
                          tabIndex={-1}
                          key={index}
                          sx={{ cursor: 'pointer' }}
                          onClick={() => {
                            setSelectedRow(row);
                          }}
                        >
                          <TableCell component="th" id={labelId} scope="row" padding="none">
                            <UserCardComponent
                              tabIndex={1}
                              backColor={row.id == selectedRow?.id ? '#2D5FF627' : 'white'}
                              permissionType={row.name}
                              boxValue={row?.resource.name}
                              isPulic={row?.is_public}
                              displayProfile={false}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Paper>
          <UserInfo_RightSideComponent_policy
            allData={totalData}
            selectedPermission={selectedRow}
            getAllPermissions_fromSelectorStatus={getAllPermissions_fromSelectorStatus}
          />
        </Grid>
      ) : (
        <Grid sx={{ display: 'flex' }}>
          <Paper
            sx={{ height: '75vh', width: '50% !important', margin: '0 15px 0 0', flexGrow: 1, overflowY: 'hidden' }}
          >
            {getConditions_fromSelectorStatus === requestState.loading ? (
              <Stack justifyContent="center" alignItems="center" height="100%">
                <CognisaasSpinner size={50} thickness={3} />
              </Stack>
            ) : (
              <TableContainer sx={{ maxHeight: '100%' }}>
                <Table stickyHeader={true} aria-labelledby="tableTitle">
                  <EnhancedTableHead
                    selectedIndex={2}
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                  />
                  <TableBody>
                    {stableSort(filteredConditionData, order).map((row: any, index: number) => {
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          hover
                          tabIndex={-1}
                          key={index}
                          sx={{ cursor: 'pointer' }}
                          onClick={() => {
                            setSelectedRow(row);
                          }}
                        >
                          <TableCell component="th" id={labelId} scope="row" padding="none">
                            <UserCardComponent
                              tabIndex={2}
                              backColor={row.id == selectedRow?.id ? '#2D5FF627' : 'white'}
                              permissionType={row.name}
                              boxValue={row?.resource.name}
                              isPulic={row?.is_public}
                              displayProfile={false}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Paper>
          <UserInfo_RightSideComponent_condition
            allData={totalData}
            selectedPermission={selectedRow}
            getConditions_fromSelectorStatus={getConditions_fromSelectorStatus}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default CustomPermissions;
