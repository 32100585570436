import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getAttachmentsFromServer, resetAttachmentDownloadData } from '../../../../store/modules/Attachment/slice';
import { selectAttachmentDataDownloadQueue } from '../../../../store/modules/Attachment/selector';
import { useAppSelector } from '../../../../store/hooks';
import AttachmentViewer from '../../../shared/Attachment/AttachmentFileView/AttachmentViewer';
import { Divider, Typography, Button } from '@mui/material';
import { ReactComponent as NoRecordsIcon } from '../../../../assets/svgs/no_records_mini.svg';
import Styles from '../../../ProductRequirement/RequirementTabs/RequirementTabs.module.css';
import BasicModal from '../../../shared/CognisaasModal/CognisaasModal';
import Attachment from '../../../shared/Attachment/Attachment';
import Cookies from 'universal-cookie';
import VersionTable from './Version';
import { userPermissionSchema } from 'components/Reports/SideDrawer/types';
import CognisaasToast from 'components/shared/CognisaasToast/CognisaasToast';
import { selectCustomerDetail } from 'store/modules/Common/selector';
import { format } from 'date-fns';
import { getLoggedInUserUid } from 'utils/getUid';
import { addTaskAuditTrail } from 'store/modules/Task/slice';

const cookies = new Cookies();

interface Props {
  data: any;
  clientDetail: any;
  userPermissions: any;
}

const TaskAttachments = (props: Props) => {
  const { userPermissions } = props;

  const dispatch = useDispatch();
  const attachmentDataQueueState = useAppSelector(selectAttachmentDataDownloadQueue);
  const [attachmentsData, setAttachmentsData] = useState<Record<string, any>[]>([]);
  const [isBulUploadAttachmentOpened, setIsBulUploadAttachmentOpened] = useState(false);
  const [modalIndex, setModalIndex] = useState<number>(0);
  const [attachmentDetail, setAttachmentDetail] = useState<any>({});
  const customerDetail = useAppSelector(selectCustomerDetail);

  const [alertDetails, setAlertDetails] = useState<{
    isVisible: boolean;
    type: 'success' | 'failure';
    message: string;
  }>({ isVisible: false, type: 'success', message: '' });

  useEffect(() => {
    dispatch(getAttachmentsFromServer(`getAttachmentsByTaskUid?task_uid=${props.data.task_uid}`));
  }, []);

  useEffect(() => {
    if (attachmentDataQueueState.status === 'success') {
      setAttachmentsData(attachmentDataQueueState.data);
      setTimeout(() => {
        dispatch(resetAttachmentDownloadData());
      }, 1000);
    }
  }, [attachmentDataQueueState]);

  const reloadAttachments = () => {
    const detail = {
      field: 'Attachment',
      task_uid: props.data.task_uid,
      user_uid: getLoggedInUserUid(),
      old_value: '',
      new_value: '',
      modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
    };
    dispatch(addTaskAuditTrail(detail));
    dispatch(getAttachmentsFromServer(`getAttachmentsByTaskUid?task_uid=${props.data.task_uid}`));
  };

  const attachmentCallback = (actionPerformed: string) => {
    if (actionPerformed === 'uploaded-bulk-attachments') {
      // get attachments uploaded on server and save in state and later show them
      dispatch(getAttachmentsFromServer(`getAttachmentsByTaskUid?task_uid=${props.data.task_uid}`));

      setTimeout(() => {
        // dispatch(resetAttachmentDownloadData());
        getAttachmentsFromServer(`getAttachmentsByTaskUid?task_uid=${props.data.task_uid}`);
      }, 3000);
    }

    setIsBulUploadAttachmentOpened(false);
  };

  return (
    <>
      <CognisaasToast
        fromPanel={true}
        open={alertDetails.isVisible}
        onClose={() => setAlertDetails({ isVisible: false, type: alertDetails.type, message: '' })}
        message={alertDetails.message}
        type={alertDetails.type}
      />
      <div style={{ marginTop: '20px', padding: '20px' }}>
        {attachmentsData.map((oneAttachment, index) => {
          return (
            <>
              <AttachmentViewer
                key={index}
                fileName={oneAttachment.name}
                attachmentUid={oneAttachment.attachment_uid}
                serverGetApiUrlWithQueryString={`downloadAttachment?attachment_uid=${oneAttachment.attachment_uid}`}
                deleteApiUrl={'deleteAttachment'}
                deleteAttachmentPayload={{
                  attachment_uid: oneAttachment.attachment_uid,
                  category: props.data.case_uid !== null ? 'usecase' : 'project',
                  taskUid: props.data.task_uid,
                  level_name: 'task',
                  customer_uid: cookies.get('cogni_customer_uid'),
                  key: btoa(btoa(`${customerDetail.customer_jira_user}:${customerDetail.customer_jira_token}`)),
                }}
                deleteCallbackFunc={reloadAttachments}
                setModalIndex={setModalIndex}
                setAttachmentInfo={setAttachmentDetail}
                attachmentData={oneAttachment}
                userPermissions={userPermissions}
              />
              <Divider />
            </>
          );
        })}

        {attachmentsData.length < 1 ? (
          <div className={Styles.RequirementAttachmentTab__noRecordsContainer}>
            <div className={Styles.RequirementAttachmentTab__noRecordsWrapper}>
              <Typography sx={{ marginBottom: '20px' }} variant="subtitle1" className="label-text">
                No Attachments Found
              </Typography>

              <NoRecordsIcon />
              {userPermissions?.includes(userPermissionSchema.EDIT) && (
                <Button onClick={() => setIsBulUploadAttachmentOpened(true)}>
                  <Typography variant="subtitle2">Upload Attachment</Typography>
                </Button>
              )}
            </div>
          </div>
        ) : (
          userPermissions?.includes(userPermissionSchema.EDIT) && (
            <Button onClick={() => setIsBulUploadAttachmentOpened(true)} style={{ marginTop: '10px' }}>
              <Typography variant="subtitle2">Upload More Attachment</Typography>
            </Button>
          )
        )}

        <BasicModal
          height={280}
          width={350}
          open={isBulUploadAttachmentOpened}
          onClose={() => setIsBulUploadAttachmentOpened(false)}
        >
          {props.data.feature_uid !== '' ? (
            <Attachment
              variant="bulk-upload"
              type="normal"
              metaDataForHeaders={{
                category: props.data.case_uid !== null ? 'usecase' : 'project',
                taskUid: props.data.task_uid,
                level_uid: props.data.task_uid,
                level_name: 'task',
                project_uid: props.data.project_uid,
                client_uid: props.clientDetail.client_uid,
                case_uid: props.data.case_uid,
                customer_uid: cookies.get('cogni_customer_uid'),
                current_version_uploaded_by_user_uid: cookies.get('cogni_user_uid'),
                current_version_uploaded_by_user_name: cookies.get('user_name'),
                key: btoa(btoa(`${customerDetail.customer_jira_user}:${customerDetail.customer_jira_token}`)),
              }}
              apiUrlForAttachmentUpload={'addAttachments'}
              callbackOnWhatIsDoneWithAttachments={(actionPerformed: any) => attachmentCallback(actionPerformed)}
              setAlertDetails={setAlertDetails}
            />
          ) : (
            ''
          )}
        </BasicModal>
        <BasicModal onClose={() => setModalIndex(0)} open={modalIndex === 1} height={280} width={350}>
          {props.data.feature_uid !== '' ? (
            <Attachment
              variant="bulk-upload"
              type="normal"
              metaDataForHeaders={{
                category: props.data.case_uid !== null ? 'usecase' : 'project',
                taskUid: props.data.task_uid,
                level_uid: props.data.task_uid,
                level_name: 'task',
                project_uid: props.data.project_uid,
                client_uid: props.clientDetail.client_uid,
                case_uid: props.data.case_uid,
                customer_uid: cookies.get('cogni_customer_uid'),
                attachment_uid: props.data.attachment_uid,
                current_version_uploaded_by_user_uid: cookies.get('cogni_user_uid'),
                current_version_uploaded_by_user_name: cookies.get('user_name'),
                key: btoa(btoa(`${customerDetail.customer_jira_user}:${customerDetail.customer_jira_token}`)),
              }}
              apiUrlForAttachmentUpload={'updateAttachment'}
              callbackOnWhatIsDoneWithAttachments={(actionPerformed: any) => attachmentCallback(actionPerformed)}
              setAlertDetails={setAlertDetails}
            />
          ) : (
            ''
          )}
        </BasicModal>
        <BasicModal onClose={() => setModalIndex(0)} open={modalIndex === 2} width={1000} height={400}>
          <VersionTable attachmentDetail={attachmentDetail} />
        </BasicModal>
      </div>
    </>
  );
};

export default TaskAttachments;
