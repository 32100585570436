import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
// import { getCustomerDetails } from 'store/modules/Settings/slice';

import { selectCustomerDetails } from 'store/modules/Settings/selector';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import {
  getContactNameByContactUid,
  getCustomerSubDomain,
  getCustomerUid,
  getLoggedInUserUid,
  getUserLogoLinkByUid,
} from '../../../../utils/getUid';
// import { isNull } from 'utils/dataHelpers';

import CognisaasCard from '../CognisaasCard';
import { Typography, Stack, IconButton, Avatar, Box } from '@mui/material';
import format from 'date-fns/format';
import { ReactComponent as Info } from '../../../../assets/svgs/info.svg';
import CognisaaasTooltip from '../../CognisaasTooltip/CognisaasTooltip';
import { getUserNameByUserUid } from '../../../../utils/getUid';
import { default as AvatarImage } from '../../../shared/Avatar/Avatar';
import CognisaasSwitchButton from 'components/shared/CognisaasSwitch/CognisaasSwitch';
import {
  addTaskAuditTrail,
  addClientTaskAuditTrail,
  addProjectTaskAuditTrial,
  addProjectClientTaskAuditTrial,
  updateTask,
} from 'store/modules/Task/slice';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';

import { Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { isValidDate } from 'utils/dataHelpers';
// import { isNull } from 'utils/dataHelpers';
import CognisaasStageSelect from 'components/shared/CognisaasStageSelect/CognisaasStageSelect';
import CognisaasTaskStageIndicator, {
  taskStage,
} from 'components/shared/CognisaasTaskStageIndicator/CognisaasTaskStageIndicator';
import { getProjectInformation } from 'store/modules/Project/slice';
import { userPermissionSchema } from 'components/Reports/SideDrawer/types';
import { label_client, label_task } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { getAttachmentsFromServer } from 'store/modules/Attachment/slice';
import CognisaasToast from 'components/shared/CognisaasToast/CognisaasToast';
import { selectTaskCustomfieldsWithValue } from 'store/modules/Task/selector';

const Container = styled.div`
  margin-bottom: 10px;
  margin-left: 10px;
  // border: 1px solid #e5e5e5;
  border-radius: 4px;
  height: 149px;
  box-sizing: border-box;
  width: '92%';
`;

function stringToHslColor(str: string, s: number, l: number) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const h = hash % 360;
  return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToHslColor(name, 30, 80),
    },
    children: (
      <Typography className="is_defined" sx={{ fontSize: '12px', fontWeight: '400', color: '#000000' }}>
        {name.split(' ')[0][0]}
        {name.indexOf(' ') >= 0 ? name.split(' ')[1][0] : null}
      </Typography>
    ),
  };
}

const CognisaasTaskCard = (props: any) => {
  const { userPermissions } = props;
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const customerDetails = useAppSelector(selectCustomerDetails);
  const taskCustomfieldsWithValues = useAppSelector(selectTaskCustomfieldsWithValue);

  const [shareTask, setShareTask] = useState<boolean>(props.task.client_check === '0' ? false : true);
  const [taskStage, setTaskStage] = useState<taskStage>(props.task.task_stage);

  const companyLogoLink = customerDetails?.data?.customer_logo_link || '';
  const ownerLogoLink = props.task.task_owner !== '15243' ? getUserLogoLinkByUid(props.task.task_owner) : '';
  const [alertDetails, setAlertDetails] = useState<{
    isVisible: boolean;
    type: 'success' | 'failure';
    message: string;
  }>({ isVisible: false, type: 'success', message: '' });

  const dispatchTrailByTaskType = async (detail: any) => {
    if (props?.task?.project_uid && props?.task?.case_uid) {
      if (props?.task?.is_team_task === 1 || props?.task?.is_team_task === '1') {
        await dispatch(addTaskAuditTrail(detail));
      } else {
        await dispatch(addClientTaskAuditTrail(detail));
      }
    } else if (props?.task?.project_uid.length > 0 && props?.task?.case_uid == null) {
      if (props?.task?.is_team_task === 1 || props?.task?.is_team_task === '1') {
        await dispatch(addProjectTaskAuditTrial(detail));
      } else {
        await dispatch(addProjectClientTaskAuditTrial(detail));
      }
    }
  };
  const handleSwitchChange = async () => {
    if (props.task.is_team_task == 1 && isMandatoryFieldsEmpty()) {
      setAlertDetails({
        isVisible: true,
        type: 'failure',
        message: `Please fill out mandatory Custom fields before editing this ${label_task}`,
      });
      return;
    }
    const detail = {
      field: 'Share with client',
      task_uid: props?.task?.task_uid,
      user_uid: getLoggedInUserUid(),
      old_value: props.task.client_check,
      new_value: shareTask === true ? '0' : '1',
      modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
    };
    await dispatch(
      updateTask({
        ...props.task,
        client_check: shareTask === true ? '0' : '1',
        phase_uid: props.task.section_uid,
        customer_uid: getCustomerUid(),
      })
    );
    setShareTask((prevState) => !prevState);
    dispatchTrailByTaskType(detail);
    await dispatch(
      getProjectInformation({
        project_uid: props.projectInformation.project_uid,
        association_uid: props.projectInformation.association_uid,
      })
    );
  };

  const handleDropdownChange = async (task_stage: any) => {
    if (props.task.is_team_task == 1 && isMandatoryFieldsEmpty()) {
      setAlertDetails({
        isVisible: true,
        type: 'failure',
        message: `Please fill out mandatory Custom fields before editing this ${label_task}`,
      });
      return;
    }
    const details = {
      field: 'Stage',
      task_uid: props?.task?.task_uid,
      user_uid: getLoggedInUserUid(),
      old_value: props.task.task_stage,
      new_value: task_stage,
      modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
    };
    if (
      task_stage === 'Done' &&
      props.task.milestone_task === '1' &&
      customerDetails?.data?.attachment_mandatory_when_marking_milestone_task_as_done == '1'
    ) {
      await appDispatch(getAttachmentsFromServer(`getAttachmentsByTaskUid?task_uid=${props.task.task_uid}`))
        .unwrap()
        .then(async (data: any) => {
          if (data?.length > 0) {
            setTaskStage(task_stage);
            await dispatch(
              updateTask({
                ...props.task,
                task_stage: task_stage,
                phase_uid: props.task.section_uid,
                base_com_url: window.location.host + '/customer-portal/' + getCustomerSubDomain() + '/',
                client_uid: props?.clientInformation?.client_uid,
                customer_company: customerDetails?.data?.customer_company,
                customer_uid: customerDetails?.data?.customer_uid,
              })
            );
            dispatchTrailByTaskType(details);
            await dispatch(
              getProjectInformation({
                project_uid: props.projectInformation.project_uid,
                association_uid: props.projectInformation.association_uid,
              })
            );
            setAlertDetails({
              isVisible: true,
              type: 'success',
              message: 'Task stage updated',
            });
          } else {
            setAlertDetails({
              isVisible: true,
              type: 'failure',
              message: 'At least one attachment is mandatory for marking milestone Task as Done.',
            });
          }
        })
        .catch((err: any) => {
          console.log('err', err);
        });
    } else {
      setTaskStage(task_stage);
      await dispatch(
        updateTask({
          ...props.task,
          task_stage: task_stage,
          phase_uid: props.task.section_uid,
          base_com_url: window.location.host + '/customer-portal/' + getCustomerSubDomain() + '/',
          client_uid: props?.clientInformation?.client_uid,
          customer_company: customerDetails?.data?.customer_company,
          customer_uid: customerDetails?.data?.customer_uid,
        })
      );
      dispatchTrailByTaskType(details);
      await dispatch(
        getProjectInformation({
          project_uid: props.projectInformation.project_uid,
          association_uid: props.projectInformation.association_uid,
        })
      );
      setAlertDetails({
        isVisible: true,
        type: 'success',
        message: 'Task stage updated',
      });
    }
  };

  useEffect(() => {
    setTaskStage(props.task.task_stage);
    setShareTask(props.task.client_check === '0' ? false : true);
  }, [props.task.task_stage, props.task.client_check]);

  const isMandatoryFieldsEmpty = () => {
    for (const value of taskCustomfieldsWithValues) {
      if (value.mandatory == '1' && value.field_value.length === 0) return true;
    }
    return false;
  };

  return (
    <Draggable draggableId={props.task.task_uid || ''} index={parseInt(props.task.task_sequence)}>
      {(provided: any) => (
        <Container {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
          <CognisaasCard
            height="147px"
            // width="92%"
            clientStatusColor={props.task.task_status}
            // boxShadow="0px 4px 26px rgba(0, 0, 0, 0.05)"
          >
            <Stack className="cognisaas-pointer" id="cognisaas-card" onClick={props.clickHandler} spacing={1}>
              <Stack className="is_defined" direction="row" justifyContent="space-between" mt={1} ml={1}>
                <Typography
                  className="is_defined"
                  variant="subtitle1"
                  style={{ fontSize: '14px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
                >
                  {props.task.task_name}
                </Typography>

                <Stack sx={{ paddingRight: '4px' }}>
                  <Stack direction="row">
                    {customerDetails?.data?.task_acknowledgement === '1' ? (
                      props.task.task_stage === 'Done' && props.task.task_acknowledged == '1' ? (
                        <CognisaaasTooltip
                          title={
                            <Box sx={{ height: '30px', width: '300px', padding: '12px' }}>
                              <Typography variant="subtitle1">
                                {label_task} has been acknowledged by {label_client.toLowerCase()}
                              </Typography>
                            </Box>
                          }
                        >
                          <TaskAltOutlinedIcon color="success" sx={{ mr: '0px', mt: '2px' }} />
                        </CognisaaasTooltip>
                      ) : null
                    ) : null}

                    {props.task.milestone_task == '1' && (
                      <CognisaaasTooltip
                        title={
                          <Box sx={{ height: '30px', padding: '12px' }}>
                            <Typography variant="subtitle1">Milestone {label_task}</Typography>
                          </Box>
                        }
                      >
                        <Stack sx={{ marginTop: '5px', marginLeft: '5px' }}>
                          <CognisaasTaskStageIndicator
                            isMileStoneTask={props.task.milestone_task == 1}
                            stage={props.task.task_stage}
                          />
                        </Stack>
                      </CognisaaasTooltip>
                    )}
                  </Stack>
                </Stack>
              </Stack>
              <Stack spacing={2}>
                <Stack
                  className="is_defined"
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  style={{ margin: '7px 10px' }}
                >
                  <Stack className="is_defined" direction="row" alignItems="center">
                    <div className="not_defined">
                      <CognisaasStageSelect
                        // disabled={props?.task?.jira_issue_id?.length > 0}
                        readOnly={!userPermissions?.includes(userPermissionSchema.EDIT)}
                        changeHandler={(e: any) => {
                          handleDropdownChange(e.target.value);
                          e.stopPropagation();
                        }}
                        width="156px"
                        value={taskStage}
                        isMileStoneTask={props.task.milestone_task !== '0'}
                      />
                    </div>
                    <div className="is_defined">
                      {props.task.task_implementation_fee_percentage && (
                        <CognisaaasTooltip
                          title={
                            <Box sx={{ height: '30px', padding: '12px' }}>
                              <Typography variant="subtitle1">Implementation Fee</Typography>
                            </Box>
                          }
                        >
                          <Stack>
                            {parseInt(props.task.task_implementation_fee_percentage) > 0 && (
                              <Stack sx={{ marginLeft: '5px' }} ml={1}>
                                <Typography variant="h6">$</Typography>
                              </Stack>
                            )}
                          </Stack>
                        </CognisaaasTooltip>
                      )}
                    </div>
                  </Stack>
                  <div className="is_defined">
                    {props.task.is_team_task == 1 && (
                      <Stack direction="row" alignItems="center">
                        <CognisaasSwitchButton
                          disabled={!userPermissions?.includes(userPermissionSchema.EDIT)}
                          size="small"
                          checked={shareTask}
                          handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            handleSwitchChange();
                            return e.stopPropagation();
                          }}
                        ></CognisaasSwitchButton>
                        <CognisaaasTooltip
                          title={
                            <Typography variant="subtitle1" style={{ padding: '10px' }}>
                              Share with {label_client}
                            </Typography>
                          }
                        >
                          <IconButton className="is_defined">
                            <Info />
                          </IconButton>
                        </CognisaaasTooltip>
                      </Stack>
                    )}
                  </div>
                </Stack>

                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  style={{ margin: '5px 10px 10px 10px' }}
                  className="is_defined"
                >
                  <Typography className="label-text is_defined" style={{ fontSize: '13px', marginTop: '5px' }}>
                    Due by{' '}
                    <span style={{ color: 'black' }}>
                      {' '}
                      {isValidDate(new Date(props.task?.task_due_date?.replace(/-/g, '/')))
                        ? format(new Date(props.task?.task_due_date?.replace(/-/g, '/')), 'dd MMM yyyy')
                        : ''}
                    </span>
                  </Typography>
                  <Stack className="is_defined">
                    <Stack spacing={1} direction="row" className="is_defined">
                      {props?.task?.is_team_task === '1' &&
                        (companyLogoLink?.length > 0 ? (
                          <AvatarImage src={companyLogoLink} alt="company-logo" height="34px" width="34px" />
                        ) : (
                          <Avatar
                            className="is_defined"
                            style={{ height: '34px', width: '34px' }}
                            {...stringAvatar(getUserNameByUserUid(props?.task?.task_owner))}
                          />
                        ))}
                      {props.task.is_team_task === '0' &&
                        (props?.clientInformation?.client_logo_link &&
                        props?.clientInformation?.client_logo_link.length > 0 ? (
                          <AvatarImage
                            src={props.clientInformation.client_logo_link}
                            alt="Client logo"
                            height="34px"
                            width="34px"
                          />
                        ) : (
                          <Avatar
                            className="is_defined"
                            style={{ height: '34px', width: '34px' }}
                            {...stringAvatar(props.clientInformation.client_name)}
                          />
                        ))}
                      {ownerLogoLink?.length > 0 ? (
                        <AvatarImage src={ownerLogoLink} alt="owner-logo" height="34px" width="34px" />
                      ) : (
                        <Avatar
                          className="is_defined"
                          style={{ height: '34px', width: '34px' }}
                          {...stringAvatar(
                            props.task.is_team_task == 1
                              ? getUserNameByUserUid(props.task.task_owner)
                              : getContactNameByContactUid(props.task.task_owner)
                          )}
                        />
                      )}
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <CognisaasToast
              open={alertDetails.isVisible}
              onClose={() => setAlertDetails({ isVisible: false, type: alertDetails.type, message: '' })}
              message={alertDetails.message}
              type={alertDetails.type}
            />
          </CognisaasCard>
        </Container>
      )}
    </Draggable>
  );
};

export default CognisaasTaskCard;
