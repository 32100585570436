import { Stack, Typography, useMediaQuery } from '@mui/material';
import BasicModal from './CognisaasModal';
import theme from '../../../plugins/materialUI';
import React from 'react';
import CognisaasButton from '../CognisaasButton/CognisaasButton';

interface Props {
  message: string;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onDelete: () => void;
}

const DeleteModal = (props: Props) => {
  const biggerScreens = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <BasicModal
      height={biggerScreens ? 222 : 170}
      width={biggerScreens ? 480 : 220}
      open={props.isOpen}
      onClose={() => props.setIsOpen(false)}
    >
      <Stack alignItems={'center'} justifyContent={'center'} sx={{ width: '100%', height: '100%' }}>
        <Typography variant="h2" sx={{ maxWidth: '498px', textAlign: 'center', marginBottom: '20px' }}>
          {props.message}
        </Typography>
        <Stack direction={'row'}>
          <CognisaasButton
            isOutlined={true}
            label="Cancel"
            clickHandler={() => {
              props.setIsOpen(false);
            }}
            sx={{ marginRight: '8px' }}
          />
          <CognisaasButton
            autoFocus
            color="error"
            isOutlined={false}
            label="Delete"
            clickHandler={() => {
              props.onDelete();
              props.setIsOpen(false);
            }}
          />
        </Stack>
      </Stack>
    </BasicModal>
  );
};

export default DeleteModal;
