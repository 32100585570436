export const intialStateForClient: any = {
  columns: {
    columnVisibilityModel: {
      client_uid: false,
    },
  },
  pinnedColumns: {
    left: ['client_name'],
  },
  pagination: {
    page: 0,
    pageSize: 20,
  },
  filter: {
    filterModel: {
      items: [],
    },
  },
};

export const intialStateForProjectHomePage: any = {
  columns: {
    columnVisibilityModel: {
      project_uid: false,
    },
  },
  pinnedColumns: {
    left: ['project_name'],
  },
  pagination: {
    page: 0,
    pageSize: 10,
  },
  filter: {
    filterModel: {
      items: [],
    },
  },
};

export const intialStateForUsecaseReport: any = {
  columns: {
    columnVisibilityModel: {
      case_uid: false,
    },
  },
  pinnedColumns: {
    left: ['case_name'],
  },
  pagination: {
    page: 0,
    pageSize: 10,
  },
  filter: {
    filterModel: {
      items: [],
    },
  },
};

export const intialStateForTaskReport: any = {
  columns: {
    columnVisibilityModel: {
      case_uid: false,
    },
  },
  pinnedColumns: {
    left: ['task_name'],
  },
  pagination: {
    page: 0,
    pageSize: 10,
  },
  filter: {
    filterModel: {
      items: [],
    },
  },
};
