import { apis } from '../axiosInstance';

import { graphRoutes } from '../route';

const addGraphConfiguration = (graphConfig: any) =>
  apis.phpAxiosInstance.post(graphRoutes.addGraphConfiguration(), graphConfig, { headers: { JWT: 'true' } });

const getGraphConfiguration = (type: string, user_uid: string, customer_uid: string) =>
  apis.phpAxiosInstance.get(graphRoutes.getGraphConfiguration(type, user_uid, customer_uid), {
    headers: { JWT: 'true' },
  });

const updateGraphConfiguration = (graphConfig: any) =>
  apis.phpAxiosInstance.post(graphRoutes.updateGraphConfiguration(), graphConfig, { headers: { JWT: 'true' } });

const deleteGraphConfiguration = (graphConfig: any) =>
  apis.phpAxiosInstance.post(graphRoutes.deleteGraphConfiguration(), graphConfig, { headers: { JWT: 'true' } });

export const graphAPI = {
  addGraphConfiguration,
  getGraphConfiguration,
  deleteGraphConfiguration,
  updateGraphConfiguration,
};
