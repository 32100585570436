import './LabeledBadge.css';
import Typography from '@mui/material/Typography';
// import CircleIcon from '@mui/icons-material/Circle';
// import CognisaasCountBadge from 'components/shared/CognisaasCountBadge/CognisaasCountBadge';
// import CognisaasCountBadge from '../CognisaasCountBadge/CognisaasCountBadge';
// import CountBadge from './CountBadge/CognisaasCountBadge';
import { isUndefined } from 'utils/dataHelpers';
import { Box } from '@mui/system';

type LabeledBadgeProps = {
  text: string;
  badgeCount?: number;
  statusColor?: string;
  textColor?: string;
  pl?: string;
  badgeBgColor?: string;
  badgeColor?: string;
  showBorder?: boolean;
  isActive: boolean;
  onClick?: () => void;
  fontSize?: any;
  fontWeight?: any;
  hideWidth?: boolean;
};

const LabeledBadge = (props: LabeledBadgeProps) => {
  const fSize = props.fontSize ? props.fontSize + ' !important' : undefined;
  const fWeight = props.fontWeight ? props.fontWeight + ' !important' : undefined;

  return (
    <span
      onClick={props.onClick}
      className={
        (props.isActive && props.showBorder ? 'LabeledBadge__containerActiveBorder ' : '') +
        (props.showBorder ? 'LabeledBadge__containerWithDefaultBorder' : 'LabeledBadge__container')
      }
      style={{ paddingLeft: props.pl }}
    >
      {!isUndefined(props.statusColor) ? (
        <Box
          sx={{ height: '18px', width: '3px', borderRadius: '4px', background: props.statusColor, marginRight: '8px ' }}
        ></Box>
      ) : (
        <></>
      )}
      <Typography
        className={(props.isActive ? 'Badge_label_font_active ' : '') + 'Badge_label_font'}
        sx={
          props.textColor
            ? { color: props.textColor, fontSize: fSize, fontWeight: fWeight }
            : { fontSize: fSize, fontWeight: fWeight }
        }
      >
        {props.text}
      </Typography>
      {props.badgeCount || props.badgeCount == 0 ? (
        <Box
          sx={{
            height: '21px',
            width: props.badgeCount > 99 ? '35px' : '21px',
            // padding: '2px',
            borderRadius: '12px',
            background: props.isActive ? '#2D60F6' : '#E5E5E5',
            color: props.isActive ? 'white' : '#7A7A7A',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: '8px',
          }}
        >
          {props.badgeCount}
        </Box>
      ) : (
        ''
      )}
    </span>
  );
};
export default LabeledBadge;
