import Cookies from 'universal-cookie';
import staticCustomLabelData from '../../../configs/staticCustomLabel.json';

const env: string = process.env.REACT_APP_ENV ?? 'local';
const maxAge = 41400;
const cookies = new Cookies();

// get domain name
const getDomainName = (env: string): string => {
  if (env == 'dev') {
    return '.devng.cognisaas.com';
  } else if (env == 'prod') {
    return '.ng.cognisaas.com';
  } else if (env == 'new-prod') {
    return '.cognisaas.net';
  } else {
    return 'localhost';
  }
};
const domain = getDomainName(env);

// Client -> new client label you give
export const setClientLabelCookie = (newClientLabel: string) => {
  cookies.set('label_client', newClientLabel, {
    path: '/',
    domain: domain,
    maxAge: maxAge,
  });
};

// project
export const setProjectLabelCookie = (newProjectLabel: string) => {
  cookies.set('label_project', newProjectLabel, {
    path: '/',
    domain: domain,
    maxAge: maxAge,
  });
};

// use case
export const setUseCaseLabelCookie = (newUseCaseLabel: string) => {
  cookies.set('label_use_case', newUseCaseLabel, {
    path: '/',
    domain: domain,
    maxAge: maxAge,
  });
};

// use case
export const setTaskLabelCookie = (newTaskLabel: string) => {
  cookies.set('label_task', newTaskLabel, {
    path: '/',
    domain: domain,
    maxAge: maxAge,
  });
};

export const setLabelCookies = (standard_name: string, custom_name: string) => {
  const cookie_name = 'label_' + standard_name.split(' ').join('_');
  cookies.set(cookie_name, custom_name, {
    path: '/',
    domain: domain,
    maxAge: maxAge,
  });
};

export const makeLabelCookies = (labelDataFromDb: any[]) => {
  staticCustomLabelData.forEach((item: any) => {
    const labelData = labelDataFromDb.filter(
      (ele) => ele.standard_value === item.standard_value && ele.entity === item.entity
    );
    if (labelData.length > 0) {
      // since delivery manager name is common for project and use case so entity have to consider
      if (item.standard_value === 'Delivery Manager') {
        if (item.entity === 'project') {
          setLabelCookies('Project ' + item.standard_value, labelData[0].custom_value);
        } else {
          setLabelCookies('Use Case ' + item.standard_value, labelData[0].custom_value);
        }
      } else {
        setLabelCookies(item.standard_value, labelData[0].custom_value);
      }
    } else {
      if (item.standard_value === 'Delivery Manager') {
        if (item.entity === 'project') {
          setLabelCookies('Project ' + item.standard_value, item.custom_value);
        } else {
          setLabelCookies('Use Case ' + item.standard_value, item.custom_value);
        }
      } else {
        setLabelCookies(item.standard_value, item.custom_value);
      }
    }
  });
};

// making table data for settings preferences

export const makeTableData = (dataFromDb: any[]): any[] => {
  const newTableData: any[] = [];

  staticCustomLabelData.forEach((item: any) => {
    const labelData = dataFromDb.filter(
      (ele: any) => ele.standard_value === item.standard_value && ele.entity === item.entity
    );
    if (labelData.length > 0) {
      newTableData.push(labelData[0]);
    } else {
      newTableData.push(item);
    }
  });

  return newTableData;
};

// resetting cookies
export const resetThisLabelCookie = (standard_name: string) => {
  const cookie_name = 'label_' + standard_name.split(' ').join('_');
  cookies.remove(cookie_name, { path: '/', domain: domain });
};

// getting globally
export let label_client = cookies.get('label_Client');
export let label_project = cookies.get('label_Project');
export let label_use_case = cookies.get('label_Use_Case');
export let label_task = cookies.get('label_Task');
export let label_due_date = cookies.get('label_Due_Date');
export let label_delivery_date = cookies.get('label_Delivery_Date');
export let label_arr = cookies.get('label_ARR');
export let label_cs_owner = cookies.get('label_CS_Owner');
export let label_sales_owner = cookies.get('label_Sales_Owner');
export let label_project_delivery_manager = cookies.get('label_Project_Delivery_Manager');
export let label_use_case_delivery_manager = cookies.get('label_Use_Case_Delivery_Manager');

export const refreshLabelCookies = () => {
  label_client = cookies.get('label_Client');
  label_project = cookies.get('label_Project');
  label_use_case = cookies.get('label_Use_Case');
  label_task = cookies.get('label_Task');
  label_due_date = cookies.get('label_Due_Date');
  label_delivery_date = cookies.get('label_Delivery_Date');
  label_arr = cookies.get('label_ARR');
  label_cs_owner = cookies.get('label_CS_Owner');
  label_sales_owner = cookies.get('label_Sales_Owner');
  label_project_delivery_manager = cookies.get('label_Project_Delivery_Manager');
  label_use_case_delivery_manager = cookies.get('label_Use_Case_Delivery_Manager');
};
