import { Card, Grid, Typography } from '@mui/material';
import { RolesCardProps } from 'components/RBACv2/interfaces';

export default function RolesCard(props: RolesCardProps) {
  return (
    <>
      {props.title !== 'Not Assigned' ? (
        <Card
          sx={{
            margin: '10px',
            backgroundColor: props.backgroundColor,
            padding: '18px',
            display: 'flex',
            justifyContent: 'space-between',
            boxShadow: 'none',
            border: '1px solid #E5E5E5;',
          }}
        >
          <Grid sx={{ display: 'flex', backgroundColor: 'grey', alignItems: 'center' }}>
            <Typography variant={'h2'}>{props.title}</Typography>
          </Grid>
          <Typography variant={'subtitle1'} className="label-text">
            {props.global ? '(Global)' : null}
          </Typography>
        </Card>
      ) : null}
    </>
  );
}
