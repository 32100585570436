import { FC } from 'react';
import styled from 'styled-components';
import { Drawer, Button } from '@mui/material';

import { Box, FlexBox, GridBox, Text } from 'components/ResourceManagement/design-system/primitives';
import { useUpdateTask } from './useUpdateTask';
import { FormSelect } from 'components/ResourceManagement/design-system/form/select';
import { FormDatePicker } from 'components/ResourceManagement/design-system/form/date-picker';
import { FormInput } from 'components/ResourceManagement/design-system/form/input';
import {
  label_delivery_date,
  label_due_date,
  label_task,
} from 'components/Settings/Preferences/CustomLabelCookieHelper';

const StyledFormGrid = styled(GridBox)`
  grid-template-columns: 1fr;
  grid-gap: ${({ theme }) => theme.space[4]};
  margin: ${({ theme }) => theme.space[20]};
  padding-top: ${({ theme }) => theme.space[23]};
  width: 650px;
  justify-content: center;
  align-items: center;
`;

const StyledDateWrap = styled(GridBox)`
  grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
  grid-gap: ${({ theme }) => theme.space[8]};
  justify-content: space-between;
  align-items: center;
`;

const StyledSelectWrap = styled(GridBox)`
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: ${({ theme }) => theme.space[8]};
  justify-content: space-between;
  align-items: center;
`;

const StyledButtonWrap = styled(GridBox)`
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: ${({ theme }) => theme.space[8]};
  justify-content: flex-end;
  align-items: center;
`;

const StyledBoxScrollWrap = styled(Box)`
  height: 60vh;
`;

export const UpdateTask: FC<any> = () => {
  const {
    plannedStartDate,
    actualStartDate,
    taskDeliveryDate,
    taskDueDate,
    taskStage,
    taskPriority,
    taskName,
    hookFormProps,
    taskStageOptions,
    taskPriorityOptions,
    originalEstimate,
    hoursPerDay,
    isUpdateDrawerOpen,
    onUpdateDrawerClose,
  } = useUpdateTask();

  const { onFormSubmit, handleSubmit } = hookFormProps;

  return (
    <Drawer anchor="right" open={isUpdateDrawerOpen} onClose={onUpdateDrawerClose}>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <StyledFormGrid>
          <Box my={6}>
            <Text variant="heading2">Update {label_task}</Text>
          </Box>
          <Box my={6}>
            <Text variant="heading3">{taskName}</Text>
          </Box>
          <StyledBoxScrollWrap>
            <StyledSelectWrap>
              <FormSelect
                id="taskStage"
                name="taskStage"
                size="medium"
                selectedItem={taskStage}
                defaultOptions={taskStageOptions}
                targetIdField="name"
                targetNameField="name"
                customLabel={label_task + ' Stage'}
                {...hookFormProps}
              />
              <FormSelect
                id="taskPriority"
                name="taskPriority"
                size="medium"
                selectedItem={taskPriority}
                defaultOptions={taskPriorityOptions}
                targetIdField="name"
                targetNameField="name"
                customLabel={label_task + ' Priority'}
                {...hookFormProps}
              />
              {/* <FormSelect
                                id="taskOwner"
                                name="taskOwner"
                                size="medium"
                                selectedItem={taskOwner}
                                defaultOptions={taskOwnerOptions}
                                targetIdField='user_uid'
                                targetNameField='user_username'
                                customLabel='Task Owner'
                                {...hookFormProps}
                            /> */}
              {/* <FormSelect
                                id="taskReporter"
                                name="taskReporter"
                                size="medium"
                                selectedItem={taskReporter}
                                defaultOptions={taskReporterOptions}
                                targetIdField='id'
                                targetNameField='name'
                                customLabel='Task Reporter'
                                {...hookFormProps}
                            /> */}
            </StyledSelectWrap>
            <StyledDateWrap>
              <FormDatePicker
                id="updateTaskPlannedStartDate"
                name="updateTaskPlannedStartDate"
                selectedDateItem={plannedStartDate}
                customLabel="Planned Start Date"
                {...hookFormProps}
              />
              <FormDatePicker
                id="updateTaskActualStartDate"
                name="updateTaskActualStartDate"
                selectedDateItem={actualStartDate}
                customLabel="Actual Start Date"
                {...hookFormProps}
              />
            </StyledDateWrap>
            <StyledDateWrap>
              <FormDatePicker
                id="updateTaskDueDate"
                name="updateTaskDueDate"
                selectedDateItem={taskDueDate}
                customLabel={label_due_date}
                {...hookFormProps}
              />
              <FormDatePicker
                id="updateTaskDeliveryDate"
                name="updateTaskDeliveryDate"
                selectedDateItem={taskDeliveryDate}
                customLabel={label_delivery_date}
                {...hookFormProps}
              />
            </StyledDateWrap>
            <Box>
              <FlexBox variant="flexRow" justifyContent="space-between">
                <Box>
                  <FormInput
                    id="updateTaskEstimate"
                    name="updateTaskEstimate"
                    selectedItem={originalEstimate}
                    placeholder="2w 3d 5h 2m"
                    customLabel="Original estimate"
                    // setFun={setOriginalEstimate}
                    {...hookFormProps}
                  />
                </Box>
                <Box>
                  <FormInput
                    id="updateTaskHoursPerDay"
                    name="updateTaskHoursPerDay"
                    selectedItem={hoursPerDay}
                    placeholder="0"
                    customLabel="Hours per day"
                    type="number"
                    // setFun={setHoursPerday}
                    // onChange={(e: any) => setHoursPerday(e.target.value)}
                    {...hookFormProps}
                  />
                </Box>
              </FlexBox>
            </Box>
          </StyledBoxScrollWrap>
          <StyledButtonWrap>
            <span></span>
            <Button type="reset" size="large" variant="outlined" onClick={onUpdateDrawerClose}>
              CANCEL
            </Button>
            <Button type="submit" size="large" variant="contained">
              SAVE
            </Button>
          </StyledButtonWrap>
        </StyledFormGrid>
      </form>
    </Drawer>
  );
};
