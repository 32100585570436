import { chartMetaDataStructure } from './types';

const CHART_DEFAULT_WIDTH = '690px';

// export const getMetaDataForChart = (data: Record<any, any>, type: visualChartTypes): chartMetaDataStructure => {
export const getMetaDataForChart = (): chartMetaDataStructure => {
  return {
    height: '',
    width: CHART_DEFAULT_WIDTH,
  };
};

export const getWidthForBarChart = (data: any[]) => {
  const numberOfBars = data.length;
  const neededWidth: number = 150 + numberOfBars * 30;
  const minWidth = 690;
  return neededWidth > minWidth ? neededWidth : minWidth;
};
