import Radio, { RadioProps } from '@mui/material/Radio';
import { ReactComponent as CheckedIcon } from '../../../assets/svgs/radioChecked.svg';
import { ReactComponent as UncheckedIcon } from '../../../assets/svgs/radioUnchecked.svg';

const CognisaasRadioButton = (props: RadioProps) => {
  return (
    <Radio
      sx={{
        '&:hover': {
          bgcolor: 'transparent',
        },
      }}
      disableRipple
      color="default"
      checkedIcon={<CheckedIcon />}
      icon={<UncheckedIcon />}
      {...props}
    />
  );
};

export default CognisaasRadioButton;
