import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import CognisaasDropdown from 'components/shared/CognisaasDropdown/CognisaasDropdown';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  createPolicy_slice,
  getAllConditions_slice,
  getAllResources_slice,
  getGeneralFieldValue_slice,
  getResourcesFieldValue_slice,
  getResourcesField_slice,
} from '../../../store/modules/RBAC/slice';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  getAllConditions_selector,
  getAllResources_selector,
  getGeneralFieldValues_selector,
  getResourcesFieldValues_selector,
  getResourcesField_selector,
} from '../../../store/modules/RBAC/selector';
import { useAppSelector } from '../../../store/hooks';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ClearIcon from '@mui/icons-material/Clear';

import CognisaasSelect from '../../shared/CognisaasSelect/CognisaasSelect';

// interface helperText_payload {
//     text: string,
//     textToCheck: string
// }

interface CustomizedState {
  data: any;
}
const AddPermission = () => {
  const [permissionName, setPermissionName] = useState('');
  const [permissionDescription, setPermissionDescription] = useState('');
  const [activeButton, setActiveButton] = useState(1);
  const [resourcesDropdown, setResourcesDropdown] = useState('project');
  const [selectedRolesDropdown, setSelectedAccessDropdown] = useState<any[]>([]);
  const [allActions, setAllActions] = useState<any[]>([]);
  const [allConditions, setAllConditions] = useState<any[]>([]);
  const [autoCompleteValue, setAutoCompleteValue] = useState('');
  const [selectedConditions, setSelectedConditions] = useState<any[]>([]);
  const [resourcesField, setResourcesField] = useState<any[]>([]);
  const [conditionResourceDropdown, setConditionResourceDropdown] = useState('');
  const [conditionResourceGeneralFieldValue, setConditionResourceGeneralFieldValue] = useState<any>([]);
  const [fieldValueDropDownValue, setFieldValueDropDownValue] = useState<any>('');
  const [conditionResourceFieldValue, setConditionResourceFieldValue] = useState<any>([]);
  const getGeneralFieldValues_fromselector = useAppSelector(getGeneralFieldValues_selector).data;
  const getResourcesFieldValues_fromSelector = useAppSelector(getResourcesFieldValues_selector).data;
  const getResourcesField_fromSelector = useAppSelector(getResourcesField_selector).data;

  const [selectedFieldType, setSelectedFieldType] = useState<string>('');
  const [selectedOperator, setSelectedOperator] = useState<string>('');

  const location = useLocation();
  const { data } = location.state as CustomizedState;
  const [allResources, setAllResources] = useState<string[]>([]);
  const getAllResources_fromSelector = useAppSelector(getAllResources_selector).data;
  const getAllConditions_fromSelector = useAppSelector(getAllConditions_selector).data;

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();

  const onClickCreate = () => {
    console.log(getAllResources_fromSelector);
    const actionIds = selectedRolesDropdown?.map((obj: any) => {
      console.log(obj);
      const temp = getAllResources_fromSelector[0]?.actions?.filter((ins: any) => {
        return ins?.name == obj;
      });
      return temp[0]?.id;
    });
    const selectedResource = getAllResources_fromSelector?.filter((obj: any) => {
      return obj.display_name == resourcesDropdown;
    });
    const selectedConditionsIds = selectedConditions?.map((obj: any) => {
      return obj?.id;
    });
    const payload = {
      name: permissionName,
      description: permissionDescription,
      resourceId: selectedResource[0].id || '',
      effect: activeButton == 1 ? 'A' : 'D',
      access: actionIds,
      conditionIds: selectedConditionsIds,
    };
    console.log(payload);
    dispatch(createPolicy_slice(payload));
    setTimeout(() => {
      navigate('/access-management');
    }, 2500);
  };

  useEffect(() => {
    dispatch(getAllResources_slice());
    dispatch(getGeneralFieldValue_slice());
  }, []);

  useEffect(() => {
    setConditionResourceGeneralFieldValue(getGeneralFieldValues_fromselector);
  }, [getGeneralFieldValues_fromselector]);

  useEffect(() => {
    setResourcesField(getResourcesField_fromSelector);
  }, [getResourcesField_fromSelector]);

  useEffect(() => {
    setConditionResourceFieldValue(getResourcesFieldValues_fromSelector);
  }, [getResourcesFieldValues_fromSelector]);

  useEffect(() => {
    // const {data} = state;
    // getAllResources_fromSelector
    setAllActions(getAllResources_fromSelector[0]?.actions);
    // setAllActions(data?.resource?.actions);
  }, [data]);

  // useEffect(()=>{
  //     const temp = getAllResources_fromSelector?.map((obj: any) => {
  //         return obj.description;
  //     });
  //     setAllResources(temp);
  // }, [resourcesDropdown, getAllResources_fromSelector]);

  useEffect(() => {
    setAllConditions(getAllConditions_fromSelector);
    // if(getAllConditions_fromSelector){
    // }
  }, [getAllConditions_fromSelector]);

  useEffect(() => {
    const dropDownForSelectedField = resourcesField.filter((r: any) => r.display_name == conditionResourceDropdown);
    if (dropDownForSelectedField.length > 0) {
      setSelectedFieldType(dropDownForSelectedField[0].type);
    }
  }, [conditionResourceDropdown]);

  useEffect(() => {
    setSelectedConditions([]);
    if (getAllResources_fromSelector) {
      setAllActions(getAllResources_fromSelector[0]?.actions);
      const temp = getAllResources_fromSelector?.map((obj: any) => {
        return obj.display_name;
      });
      setAllResources(temp);
      const resourceFieldId = getAllResources_fromSelector?.filter((obj: any) => {
        return obj.display_name == resourcesDropdown;
      });
      dispatch(
        getAllConditions_slice({
          resource: resourceFieldId[0]?.id || '',
        })
      );

      dispatch(getResourcesField_slice({ resource: resourceFieldId[0]?.id || '' }));
    }
  }, [resourcesDropdown, getAllResources_fromSelector]);

  useEffect(() => {
    if (getAllResources_fromSelector) {
      const tempFilter = resourcesField?.filter((obj: any) => {
        return obj.display_name == conditionResourceDropdown;
      });

      const resourceFieldId = getAllResources_fromSelector?.filter((obj: any) => {
        return obj.display_name == resourcesDropdown;
      });

      if (tempFilter) {
        dispatch(
          getResourcesFieldValue_slice({
            resource: resourceFieldId[0]?.id || '',
            field: tempFilter[0]?.id || '',
          })
        );
      }
    }
  }, [conditionResourceDropdown, getAllResources_fromSelector]);

  const handleChangeAccessDropdown = (event: any) => {
    const value = event.target.value;
    setSelectedAccessDropdown(value);
  };

  const isCheckBoxChecked = (option: any) => {
    // selectedRolesDropdown.indexOf(option) > -1
    const temp = selectedRolesDropdown.filter((obj: any) => {
      return obj == option?.name;
    });
    return temp.length > 0;
  };

  // useEffect(()=>{
  //     console.log('selectedRolesDropdown: ', selectedRolesDropdown);
  // }, [selectedRolesDropdown]);

  const handleClickOpenAlertDialog = () => {
    setOpen(true);
  };

  const handleAlertDialogClose = () => {
    setOpen(false);
  };

  const isString = (item: any): item is string => {
    return typeof item === 'string';
  };

  const onClickAutoCompleteDropdown = (obj: any) => {
    console.log(obj);
    const filteredCondition = allConditions?.filter((ins: any) => {
      return ins?.display_name == obj?.target.value;
    });

    const tempFilter = selectedConditions?.filter((ins: any) => {
      return filteredCondition[0].id == ins.id;
    });

    if (filteredCondition.length > 0 && tempFilter.length == 0) {
      const temp = [...selectedConditions, filteredCondition[0]];
      setSelectedConditions(temp);
    }
    setAutoCompleteValue('');
  };

  const onClickClearCondition = (obj: any) => {
    const temp = selectedConditions?.filter((ins: any) => {
      return obj.id != ins.id;
    });
    setSelectedConditions(temp);
    // dispatch(removeCondition_slice(payload));
  };

  return (
    <Grid sx={{ padding: '18px 23px', marginLeft: '90px' }}>
      <Typography marginLeft="10px" variant={'h1'}>
        Create New Policy
      </Typography>
      <Grid
        sx={{
          marginTop: '18px',
          position: 'relative',
          height: '84vh',
          overflowY: 'scroll',
          padding: '30px',
          minHeight: '80vh',
          width: '70vw !important',
          backgroundColor: 'white',
          borderRadius: '5px',
        }}
      >
        <Grid sx={{ minHeight: '95px' }}>
          <Typography variant="h3" fontSize="15px" marginBottom="4px">
            Policy Name *
          </Typography>
          <TextField
            size="small"
            value={permissionName}
            onChange={(e: any) => {
              setPermissionName(e.target.value);
            }}
            placeholder="Enter Policy Name"
          />
          {/* <MyFormHelperText textToCheck={permissionName} text='Fill the Permission Name' /> */}
        </Grid>
        <Grid sx={{ minHeight: '95px' }}>
          <Typography variant="h3" fontSize="15px" marginBottom="4px">
            Policy Description
          </Typography>
          <TextField
            size="small"
            value={permissionDescription}
            onChange={(e: any) => {
              setPermissionDescription(e.target.value);
            }}
            placeholder="Enter Policy Name"
          />
          {/* <MyFormHelperText textToCheck={permissionName} text='Fill the Permission Name' /> */}
        </Grid>
        <Grid>
          <Typography variant="h2" fontSize="19px" marginTop="0px">
            Effect
          </Typography>
          <ButtonGroup sx={{ marginTop: '15px' }} variant="outlined" aria-label="outlined button group">
            <Button
              className={activeButton == 1 ? 'active-btn' : undefined}
              onClick={() => {
                setActiveButton(1);
              }}
            >
              <Typography variant={'subtitle1'}>Allow</Typography>
            </Button>
            <Button
              className={activeButton == 2 ? 'active-btn' : undefined}
              onClick={() => {
                setActiveButton(2);
              }}
            >
              <Typography variant={'subtitle1'}>Deny</Typography>
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid>
          <Typography variant="h2" fontSize="19px" marginTop="25px" marginBottom="10px">
            Resources
          </Typography>
          <CognisaasDropdown
            sx={{ marginTop: '10px' }}
            name="resources-dropdown"
            type="dropdown"
            validvalues={allResources}
            onChange={(e: any) => setResourcesDropdown(e.target.value)}
            value={resourcesDropdown}
          />
        </Grid>
        {/*  */}
        <Grid>
          <Typography variant="h2" fontSize="19px" marginTop="25px" marginBottom="10px">
            Access
          </Typography>
          <FormControl>
            <Select
              size="small"
              labelId="mutiple-select-label"
              multiple
              value={selectedRolesDropdown}
              onChange={handleChangeAccessDropdown}
              renderValue={(selected: any) => {
                const temp = selected?.map((obj: any) => {
                  return obj + ', ';
                });
                return temp;
              }}
              // MenuProps={MenuProps}
            >
              {allActions?.map((option: any) => (
                <MenuItem key={option.id} value={option.name}>
                  <ListItemIcon>
                    <Checkbox checked={isCheckBoxChecked(option)} />
                  </ListItemIcon>
                  <ListItemText primary={option.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* <CognisaasDropdown
                        sx={{marginTop: '10px'}}
                        name='resources-dropdown'
                        type='dropdown'
                        validvalues={['View', 'Edit', 'Delete']}
                        onChange={(e: any) => setAccessDropdown(e.target.value)}
                        value={accessDropdown}
                        // defaultValue={renewalDuration}
                    /> */}
        </Grid>

        <Autocomplete
          sx={{ width: '500px', marginTop: '25px' }}
          size="small"
          options={allConditions}
          fullWidth
          value={autoCompleteValue}
          getOptionLabel={(option: any) => (isString(option['description']) ? option['description'] : '')}
          renderInput={(params: any) => {
            return <TextField {...params} placeholder="search condition..." />;
          }}
          onSelect={onClickAutoCompleteDropdown}
        />
        {selectedConditions?.map((obj: any) => {
          return (
            <Card
              sx={{ marginTop: '25px', width: '500px', display: 'flex', justifyContent: 'space-between' }}
              key={obj.id}
            >
              <Accordion
                elevation={0}
                onChange={(ins: any, expanded: any) => {
                  if (expanded) {
                    console.log(obj);
                    const temp1 = resourcesField?.filter((ins: any) => {
                      return ins.name == JSON.parse(obj.conditional_key).keyName;
                    });
                    setConditionResourceDropdown(temp1[0].display_name);

                    const temp2 = conditionResourceGeneralFieldValue?.filter((ins: any) => {
                      return ins.name == JSON.parse(obj?.conditional_value)?.value;
                    });
                    console.log('temp2', temp2);
                    if (temp2.length > 0) {
                      setFieldValueDropDownValue(temp2[0].display_name);
                    } else {
                      const temp3 = conditionResourceFieldValue?.filter((ins: any) => {
                        return ins.name == JSON.parse(obj?.conditional_value)?.value;
                      });
                      setFieldValueDropDownValue(temp3[0].display_name);
                    }
                  }
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{ width: '400px' }}
                >
                  <Typography variant="h2">{obj.name}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid>
                    <Grid sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography variant="h2">Where {resourcesDropdown}</Typography>
                      <Grid sx={{ marginLeft: '10px' }}>
                        <FormControl fullWidth>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={conditionResourceDropdown}
                            // label="Age"
                            onChange={(e: any) => setConditionResourceDropdown(e.target.value)}
                          >
                            {resourcesField?.map((obj: any) => {
                              return (
                                <MenuItem key={obj} value={obj?.display_name}>
                                  <Typography variant="h2">{obj?.display_name}</Typography>
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid sx={{ marginTop: '15px' }}>
                      <Grid sx={{ margin: '15px 0' }}>
                        <Typography variant={'h2'} style={{ marginBottom: '5px' }}>
                          Operator
                        </Typography>
                        <CognisaasSelect
                          width="310px"
                          value={selectedOperator}
                          validValues={['is', 'is_not', 'is_in', 'is_not_in', 'has', 'has_not']}
                          disabledValues={
                            selectedFieldType == 'ARR' ? ['is', 'is_not', 'is_in', 'is_not_in'] : ['has', 'has_not']
                          }
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setSelectedOperator((e.target as HTMLInputElement).value)
                          }
                        />
                      </Grid>
                      <Grid>
                        <Typography variant={'h2'} style={{ marginBottom: '5px' }}>
                          Value
                        </Typography>
                        <FormControl fullWidth>
                          <Select
                            size="small"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={fieldValueDropDownValue}
                            // label="Age"
                            onChange={(e: any) => setFieldValueDropDownValue(e.target.value)}
                          >
                            <Typography sx={{ color: '#3978FF', margin: '5px 8px' }} variant="h3">
                              General
                            </Typography>
                            {conditionResourceGeneralFieldValue?.map((obj: any) => {
                              return (
                                <MenuItem key={obj} value={obj?.display_name}>
                                  <Typography variant="h2">{obj?.display_name}</Typography>
                                </MenuItem>
                              );
                            })}
                            <Typography sx={{ color: '#3978FF', margin: '5px 8px' }} variant="h3">
                              Auto Values
                            </Typography>
                            {conditionResourceFieldValue?.map((obj: any) => {
                              return (
                                <MenuItem key={obj} value={obj?.display_name}>
                                  <Typography variant="h2">{obj?.display_name}</Typography>
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Grid sx={{ marginTop: '4px', marginRight: '5px' }}>
                <IconButton aria-label="delete" size="small">
                  <ClearIcon
                    fontSize="inherit"
                    onClick={() => {
                      onClickClearCondition(obj);
                    }}
                  />
                </IconButton>
              </Grid>
            </Card>
          );
        })}

        <Grid sx={{ marginTop: '15px', display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={handleClickOpenAlertDialog} variant="outlined">
            Cancel
          </Button>
          <Button onClick={onClickCreate} sx={{ marginLeft: '25px' }} variant="contained">
            Create
          </Button>
          <Dialog
            open={open}
            onClose={handleAlertDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              <Typography variant="h1">Alert!</Typography>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Typography variant="h2">
                  You will be redirected to User Management and all your data filled in the form will be lost !
                </Typography>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleAlertDialogClose}>Cancel</Button>
              <Button
                onClick={() => {
                  navigate('/access-management');
                }}
                autoFocus
              >
                Agree
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddPermission;
