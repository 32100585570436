import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import {
  // getAllDeliveryManagerDetailsWithProjectAndTaskUsingCustomerUid,
  selectComponentState,
  selectMainViewData,
  selectProjectId,
  selectResourceManagementState,
  setDataForMainView,
  setSoftAllocationData,
  getDataForResourceManagement,
  // selectResourceManagementTransformedData,
} from '../../store/modules/ResourceManagement';
import { transformDataForMainView } from './utils/transformers';

import { Component } from 'components/ResourceManagement/types/public-types';

export const useResourceManagement = () => {
  const mode = 'main-view';
  const dispatch = useAppDispatch();
  const resourceManagementState = useAppSelector(selectResourceManagementState);

  // const resouceManagementRawData = resouceManagementRawData
  const {
    members: { isLoading, data, isError, message },
  } = resourceManagementState;
  const selectedMainViewData = useAppSelector(selectMainViewData);
  const selectedProjectId = useAppSelector(selectProjectId);
  const selectedComponentState = useAppSelector(selectComponentState);
  const { viewMode } = selectedComponentState;
  const { events: mainViewEventsForDateRange } = selectedMainViewData;

  useEffect(() => {
    // dispatch(getAllDeliveryManagerDetailsWithProjectAndTaskUsingCustomerUid());
    dispatch(getDataForResourceManagement());
  }, []);

  const [primeComponents, setPrimeComponents] = useState<any[]>([]);

  useEffect(() => {
    if (data !== undefined && data.length) {
      const selectedMainViewData = transformDataForMainView(data);
      const resources = selectedMainViewData.resources;
      const events = selectedMainViewData.events;
      if (resources && events && resources.length && events.length) {
        setPrimeComponents([...resources, ...events]);
        dispatch(
          setDataForMainView({
            resources,
            events,
          })
        );
      }
      const softAllocations = selectedMainViewData.softAllocations;
      if (softAllocations) {
        dispatch(
          setSoftAllocationData({
            softAllocations,
          })
        );
      }
    }
  }, [data, isError, message]);

  const handleSelect = () => {
    // console.log(component.name + ' has ' + (isSelected ? 'selected' : 'unselected'));
  };

  const handleExpanderClick = (component: Component) => {
    const expandedComponents = primeComponents.map((t) => (t.id === component.id ? component : t));
    // console.log('On expander click Id:' + component.id);
    setPrimeComponents(expandedComponents);
  };

  // console.log({ primeComponents });

  return {
    viewMode,
    primeComponents,
    selectedMainViewData,
    selectedProjectId,
    isLoading,
    isError,
    mode,
    mainViewEventsForDateRange,
    handleSelect,
    handleExpanderClick,
  };
};
