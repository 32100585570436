import TemplatesList from './UI/TemplatesList/TemplatesList';

const TemplatesIndex = () => {
  return (
    <>
      <TemplatesList />
    </>
  );
};

export default TemplatesIndex;
