import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useDispatch } from 'react-redux';
import {
  getCustomFieldUsecaseRecords_slice,
  updateCustomUsecaseField,
  deleteCustomUsecaseField,
  resetManipulatedNewCustomFields,
  updateCustomFieldAttachmentStatus,
  updateCustomFieldMandatoryStatus,
} from '../../../../store/modules/Settings/slice';
import { customerFieldUsecaseRecords, manipulatingCustomFields } from '../../../../store/modules/Settings/selector';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  Box,
  Pagination,
  PaginationItem,
  Typography,
  TextField,
  IconButton,
  Menu,
  MenuItem,
  Stack,
} from '@mui/material';
import AddCustomField from './AddCustomField';
import {
  DataGridPremium,
  gridClasses,
  useGridApiContext,
  useGridSelector,
  gridPageSelector,
  gridPageCountSelector,
  GridRenderCellParams,
  GridColDef,
  GridRenderEditCellParams,
} from '@mui/x-data-grid-premium';
import { alpha, styled } from '@mui/material/styles';
import Cookies from 'universal-cookie';
import { ReactComponent as EditSVG } from '../../../../assets/svgs/TableEditButton.svg';
import { label_use_case } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { requestState } from 'store/modules/types';
import CognisaasToast from 'components/shared/CognisaasToast/CognisaasToast';
import { resetAllFiltersAcrossApp } from '../utils';
import { resetFilterAcrossAppAtStore } from 'store/modules/AllFilters/slice';
import DeleteModal from 'components/shared/CognisaasModal/DeleteModal';
import CognisaaasTooltip from 'components/shared/CognisaasTooltip/CognisaasTooltip';
import CognisaasCheckBox from 'components/shared/CognisaasCheckbox/CognisaasCheckbox';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CognisaasSwitchButton from 'components/shared/CognisaasSwitch/CognisaasSwitch';
import { updateCaseStatusByCustomer } from 'store/modules/Common/slice';

const cookies = new Cookies();
const customer_uid = cookies.get('cogni_customer_uid');
// import React from 'react';

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGridPremium)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
        },
      },
    },
  },
}));

const Usecase = () => {
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const customerFieldData = useAppSelector(customerFieldUsecaseRecords).data;
  const [tableData, setTableData] = useState([]);
  const [showAddCustomFields, setShowCustomFields] = useState(false);
  const manipulatingFieldsStatus = useAppSelector(manipulatingCustomFields);
  const [alertDetails, setAlertDetails] = useState<{
    isVisible: boolean;
    type: 'success' | 'failure';
    message: string;
  }>({ isVisible: false, type: 'success', message: '' });
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [selectedUsecaseCustomFieldForDeletion, setSelectedUsecaseCustomFieldForDeletion] = useState<any>({});

  useEffect(() => {
    dispatch(getCustomFieldUsecaseRecords_slice(customer_uid));
    setAlertDetails({ isVisible: false, type: alertDetails.type, message: '' });
  }, []);

  useEffect(() => {
    setTableData(customerFieldData.data?.result != undefined ? customerFieldData?.data?.result : []);
  }, [customerFieldData]);

  useEffect(() => {
    if (manipulatingFieldsStatus.status === requestState.success) {
      setAlertDetails({
        isVisible: true,
        type: 'success',
        message: manipulatingFieldsStatus.message as string,
      });
      resetAllFiltersAcrossApp();
      dispatch(resetFilterAcrossAppAtStore());
      dispatch(getCustomFieldUsecaseRecords_slice(customer_uid ? customer_uid : ''));
      setShowCustomFields(false);
      dispatch(resetManipulatedNewCustomFields());
    } else if (manipulatingFieldsStatus.status === requestState.failed) {
      setAlertDetails({
        isVisible: true,
        type: 'failure',
        message: manipulatingFieldsStatus.message as string,
      });
      dispatch(resetManipulatedNewCustomFields());
    }
  }, [manipulatingFieldsStatus]);

  const handleSubmitUpdateCustomValues = async (params: any) => {
    console.log(params, 'params');
    if (
      params?.row?.field_type == 'String' ||
      params?.row?.field_type == 'Hyperlink' ||
      params?.row?.field_type == 'Date' ||
      params?.row?.field_type == 'Number'
    ) {
      const reqObj: any = {
        customer_uid: params?.row?.customer_uid,
        field_seq_num: params?.row?.field_seq_num,
        field_uid: params?.row?.field_uid,
        field_type: params?.row?.field_type,
        field_name: params?.row?.field_name,
        valid_values: params?.row?.valid_values,
      };
      await dispatch(updateCustomUsecaseField(reqObj));
    }
    if (params?.row?.field_type == 'Dropdown' || params?.row?.field_type == 'RAG status') {
      const reqObj: any = {
        customer_uid: params?.row?.customer_uid,
        field_seq_num: params?.row?.field_seq_num,
        field_uid: params?.row?.field_uid,
        field_type: params?.row?.field_type,
        field_name: params?.row?.field_name,
        valid_values: params?.row?.valid_values,
      };
      await dispatch(updateCustomUsecaseField(reqObj));
    }
  };

  const handleDeleteClick = async () => {
    await dispatch(
      deleteCustomUsecaseField({
        field_uid: selectedUsecaseCustomFieldForDeletion.field_uid,
        customer_uid: selectedUsecaseCustomFieldForDeletion.customer_uid,
      })
    );
  };

  const updateIncludeRagForStatusCalculation = async (rowData: any, newStatusValue: boolean) => {
    const reqObj: any = {
      customer_uid: rowData?.customer_uid,
      field_seq_num: rowData?.field_seq_num,
      field_uid: rowData?.field_uid,
      field_type: rowData?.field_type,
      field_name: rowData?.field_name,
      valid_values: rowData?.valid_values,
      rag_status_calculation: newStatusValue,
      system_overide: rowData?.system_overide,
    };
    await appDispatch(updateCustomUsecaseField(reqObj)).then(async () => {
      await dispatch(getCustomFieldUsecaseRecords_slice(customer_uid));
      await dispatch(updateCaseStatusByCustomer());
    });
  };

  const overideRagForStatusCalculation = async (rowData: any, newStatusValue: boolean) => {
    const reqObj: any = {
      customer_uid: rowData?.customer_uid,
      field_seq_num: rowData?.field_seq_num,
      field_uid: rowData?.field_uid,
      field_type: rowData?.field_type,
      field_name: rowData?.field_name,
      valid_values: rowData?.valid_values,
      rag_status_calculation: rowData?.rag_status_calculation,
      system_overide: newStatusValue,
      systemOveride: true,
    };
    await appDispatch(updateCustomUsecaseField(reqObj)).then(async () => {
      await dispatch(getCustomFieldUsecaseRecords_slice(customer_uid));
      await dispatch(updateCaseStatusByCustomer());
    });
  };

  const CustomEditInputCell = (props: { params: GridRenderEditCellParams }) => {
    const { params } = props;

    const apiRef = useGridApiContext();
    const { id, value, field } = params;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      apiRef.current.setEditCellValue({ id, field, value: event.target.value as string });
    };

    const handleClick = () => {
      apiRef.current.stopCellEditMode({ id, field });
      handleSubmitUpdateCustomValues(params);
    };

    return (
      <>
        <div style={{ padding: '0 4px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <TextField
            autoComplete=""
            autoFocus={true}
            value={value}
            onChange={handleChange}
            style={{ width: '150px' }}
            size="small"
          />

          <button
            style={{
              border: '1px solid #4F9F52',
              background: '#BDF2BF',
              width: '40px',
              borderRadius: '0px 5px 5px 0px',
              height: '40px',
              marginLeft: '-1px',
              cursor: 'pointer',
            }}
            onClick={() => handleClick()}
          >
            <EditSVG />
          </button>
        </div>
      </>
    );
  };

  const ExceptionalValidValuesEditCell = (props: { params: GridRenderEditCellParams }) => {
    const { params } = props;

    const apiRef = useGridApiContext();
    const { id, value, field } = params;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      apiRef.current.setEditCellValue({ id, field, value: event.target.value as string });
    };

    const handleClick = () => {
      apiRef.current.stopCellEditMode({ id, field });
      handleSubmitUpdateCustomValues(params);
    };

    return (
      <>
        {params.row.field_type == 'Dropdown' ? (
          <div style={{ padding: '0 4px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <TextField
              autoComplete=""
              autoFocus={true}
              value={value}
              onChange={handleChange}
              style={{ width: '150px' }}
              size="small"
            />

            <button
              style={{
                border: '1px solid #4F9F52',
                background: '#BDF2BF',
                width: '40px',
                borderRadius: '0px 5px 5px 0px',
                height: '40px',
                marginLeft: '-1px',
                cursor: 'pointer',
              }}
              onClick={() => handleClick()}
            >
              <EditSVG />
            </button>
          </div>
        ) : (
          <Typography>{value}</Typography>
        )}
      </>
    );
  };

  const CustomSwitchEditCell = (props: { params: GridRenderEditCellParams; type: string }) => {
    const { id, value, field } = props.params;
    const type = props.type;
    const apiRef = useGridApiContext();

    const handleAttachmentSwitch = (e: React.ChangeEvent<HTMLInputElement>) => {
      e.stopPropagation();
      const newValue = e.target.checked ? '1' : '0';
      apiRef.current.setEditCellValue({ id, field, value: newValue });
      apiRef.current.stopCellEditMode({ id, field });
      dispatch(
        updateCustomFieldAttachmentStatus({
          type: 'usecase',
          customer_uid: props.params?.row.customer_uid,
          field_uid: props.params?.row.field_uid,
          attachment: newValue,
        })
      );
    };

    const handleMandatorySwitch = (e: React.ChangeEvent<HTMLInputElement>) => {
      e.stopPropagation();
      const newValue = e.target.checked ? '1' : '0';
      apiRef.current.setEditCellValue({ id, field, value: newValue });
      apiRef.current.stopCellEditMode({ id, field });
      dispatch(
        updateCustomFieldMandatoryStatus({
          type: 'usecase',
          customer_uid: props.params?.row.customer_uid,
          field_uid: props.params?.row.field_uid,
          mandatory: newValue,
        })
      );
    };

    if (type === 'Attachment') {
      return (
        <Stack direction="row" justifyContent="space-between" alignItems="center" style={{ marginLeft: '10px' }}>
          <CognisaasSwitchButton handleChange={handleAttachmentSwitch} checked={value === '1' ? true : false} />
          <Typography variant="subtitle1" style={{ marginLeft: '5px' }}>
            {value === '1' ? 'Yes' : 'No'}
          </Typography>
        </Stack>
      );
    }

    return (
      <Stack direction="row" justifyContent="space-between" alignItems="center" style={{ marginLeft: '10px' }}>
        <CognisaasSwitchButton handleChange={handleMandatorySwitch} checked={value === '1' ? true : false} />
        <Typography variant="subtitle1" style={{ marginLeft: '5px' }}>
          {value === '1' ? 'Enabled' : 'Disabled'}
        </Typography>
      </Stack>
    );
  };

  const StyledBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    boxShadow: '0px 4px 26px rgba(0, 0, 0, 0.05)',
    flexDirection: 'column',
    marginTop: '12px',
    width: '100%',
    '& .MuiFormGroup-options': {
      alignItems: 'center',
      paddingBottom: theme.spacing(1),
      '& > div': {
        minWidth: 100,
        margin: theme.spacing(2),
        marginLeft: 0,
      },
      border: 'none',
    },
  }));

  interface GridPaginationSettings {
    pagination: boolean;
    autoPageSize: boolean;
    pageSize: number | undefined;
  }

  const initialState: any = {
    columns: {
      columnVisibilityModel: {},
    },
  };

  const columns: GridColDef[] = [
    {
      field: 'field_id',
      hide: true,
    },
    {
      field: 'field_name',
      hide: false,
      headerName: 'Field Name',
      flex: 1,
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams) => <CustomEditInputCell params={params} />,
    },
    {
      field: 'field_type',
      hide: false,
      headerName: 'Field Type',
      flex: 1,
      editable: false,
    },
    {
      field: 'valid_values',
      hide: false,
      headerName: 'Values',
      flex: 1,
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams) => <ExceptionalValidValuesEditCell params={params} />,
    },
    {
      field: '_',
      headerName: 'RAG status calculation',
      width: 100,
      renderCell: (params: GridRenderCellParams) => {
        const [value, setValue] = useState<boolean>(params.row.rag_status_calculation === '1');

        return (
          <>
            {params.row.field_type === 'RAG status' ? (
              <CognisaaasTooltip
                title={
                  <Typography style={{ padding: '10px' }} variant={'subtitle1'}>
                    Include this field in status calculation
                  </Typography>
                }
              >
                <CognisaasCheckBox
                  checked={value}
                  onClick={async () => {
                    await updateIncludeRagForStatusCalculation(params.row, !value);
                    setValue(!value);
                  }}
                />
              </CognisaaasTooltip>
            ) : (
              <></>
            )}
          </>
        );
      },
    },
    {
      field: 'system_overide',
      headerName: 'System Overide',
      width: 100,
      renderCell: (params: GridRenderCellParams) => {
        const [value, setValue] = useState<boolean>(params.row.system_overide === '1');
        return (
          <>
            {params.row.field_type === 'RAG status' ? (
              <CognisaaasTooltip
                title={
                  <Typography style={{ padding: '10px' }} variant={'subtitle1'}>
                    Overide the system status calculation with this value.
                  </Typography>
                }
              >
                <CognisaasCheckBox
                  checked={value}
                  onClick={async () => {
                    await overideRagForStatusCalculation(params.row, !value);
                    setValue(!value);
                  }}
                />
              </CognisaaasTooltip>
            ) : (
              <></>
            )}
          </>
        );
      },
    },
    {
      field: 'attachment',
      hide: false,
      headerName: 'Attachment',
      flex: 1,
      editable: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <CognisaasSwitchButton checked={params.value === '1' ? true : false} />
            <Typography variant="subtitle1" style={{ marginLeft: '5px' }}>
              {params.value === '1' ? 'Yes' : 'No'}
            </Typography>
          </Stack>
        );
      },
      renderEditCell: (params: GridRenderEditCellParams) => <CustomSwitchEditCell params={params} type="Attachment" />,
    },
    {
      field: 'mandatory',
      headerName: 'Mandatory',
      hide: false,
      flex: 1,
      editable: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <CognisaasSwitchButton checked={params.value === '1' ? true : false} />
            <Typography variant="subtitle1" style={{ marginLeft: '5px' }}>
              {params.value === '1' ? 'Enabled' : 'Disabled'}
            </Typography>
          </Stack>
        );
      },
      renderEditCell: (params: GridRenderEditCellParams) => <CustomSwitchEditCell params={params} type="Mandatory" />,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      renderCell: (params: GridRenderCellParams) => {
        const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
        const open = Boolean(anchorEl);
        const handleClick = (event: React.MouseEvent<HTMLElement>) => {
          setAnchorEl(event.currentTarget);
        };
        const handleClose = () => {
          setAnchorEl(null);
        };

        const apiRef = useGridApiContext();
        const { id } = params;
        const handleEnableEdit = (action: string) => {
          handleClose();
          if (action === 'Delete') {
            setOpenDeleteModal(true);
            setSelectedUsecaseCustomFieldForDeletion(params.row);
          } else if (action === 'Edit') {
            setTimeout(() => {
              apiRef.current.startCellEditMode({ id, field: 'field_name' });
            }, 300);
          }
        };

        return (
          <div>
            <IconButton onClick={handleClick}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: '160px',
                  width: '10ch',
                },
              }}
            >
              {['Edit', 'Delete'].map((item) => (
                <MenuItem
                  key={item}
                  dense={true}
                  onClick={() => {
                    handleEnableEdit(item);
                  }}
                >
                  {item}
                </MenuItem>
              ))}
            </Menu>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    console.log(tableData, 'table data for usecase');
  }, [tableData]);

  const rows = [...tableData];

  const data = {
    columns,
    rows,
    initialState,
  };

  function getPaginationItemStyle(item: any) {
    switch (item.type) {
      case 'page':
        if (item.selected) {
          return { background: '#c0c0c0', color: '#2D60F6' };
        }
        return { background: '#ffffff', color: '#000000' };
      case 'previous':
        return {};
      case 'next':
        return {};
    }
  }

  const [pagination] = useState<GridPaginationSettings>({
    pagination: true,
    autoPageSize: false,
    pageSize: 5,
  });

  const CustomPagination = () => {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <Box
        component={'span'}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          margin: '20px 0px',
        }}
      >
        <Typography variant="subtitle1">
          {page == 0 ? page + '-' + 5 : page * 5 + '-' + (page * 5 + 5)} of {tableData.length}
        </Typography>
        <Pagination
          count={pageCount}
          onChange={(event, value) => {
            // console.log(value, 'val check');
            apiRef.current.setPage(value - 1);
          }}
          shape="rounded"
          page={page === 0 ? 1 : page + 1}
          renderItem={(item) => {
            // console.log(item, 'item check');
            return <PaginationItem {...item} sx={getPaginationItemStyle(item)} />;
          }}
        />
      </Box>
    );
  };

  columns.forEach((item: any) => {
    if (item.hide) {
      initialState.columns.columnVisibilityModel[item.field] = false;
    }
  });

  return (
    <>
      <DeleteModal
        isOpen={openDeleteModal}
        setIsOpen={setOpenDeleteModal}
        message={'Are you sure you want to delete this custom field'}
        onDelete={handleDeleteClick}
      />
      <Grid sx={{ padding: '20px' }}>
        <Box sx={{ width: '100%' }}>
          <Grid sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Grid>Configure custom fields in {label_use_case.toLowerCase()} record</Grid>
            <Grid>
              <Button variant="contained" size="small" onClick={() => setShowCustomFields(true)}>
                Add Field
              </Button>
            </Grid>
          </Grid>
          {showAddCustomFields ? (
            <AddCustomField
              customerFieldData={customerFieldData?.data?.result}
              customFieldFor={'usecase'}
              setShowCustomFields={setShowCustomFields}
            />
          ) : null}
        </Box>
        {tableData.length > 0 ? (
          <>
            <StyledBox>
              <StripedDataGrid
                hideFooter={false}
                autoHeight
                sx={{
                  boxShadow: 0,
                  border: 0,
                  borderColor: 'primary.light',
                  '& .MuiDataGrid-cell:hover': {
                    color: 'primary.main',
                  },
                }}
                {...data}
                components={{
                  Toolbar: (() => {
                    return null;
                  })(),
                  Footer: CustomPagination,
                }}
                componentsProps={{
                  toolbar: { showQuickFilter: true },
                }}
                loading={false}
                getRowId={(row) => row['field_id']}
                disableSelectionOnClick
                rowThreshold={0}
                initialState={{
                  ...data.initialState,
                  pinnedColumns: { left: ['field_name'] },
                }}
                {...pagination}
                getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'odd' : 'odd')}
                experimentalFeatures={{ newEditingApi: true }}
              />
            </StyledBox>
          </>
        ) : null}
        {/* alerts used */}
        <CognisaasToast
          open={alertDetails.isVisible}
          onClose={() => setAlertDetails({ isVisible: false, type: alertDetails.type, message: '' })}
          message={alertDetails.message}
          type={alertDetails.type}
        />
      </Grid>
    </>
  );
};

export default Usecase;
