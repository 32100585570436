import { useEffect, useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import { getAllUsecaseCustomFields } from '../../../../store/modules/Usecase/slice';
import { transformCustomFieldDataForEntityStructure } from '../UtilityFunction';
import { usecaseEntityStandardFields } from './usecaseEntityStandardFields';
import useCustomNames from 'components/shared/CustomNames/useCustomNames';
const usecaseEntity = () => {
  const dispatch = useAppDispatch();
  const [usecaseEntityWithCustomFields, setUsecaseEntityWithCustomFields] = useState<any[]>([]);
  const { usecaseCategoryDropdownValues, usecaseStagesDropdownValues } = useCustomNames();

  useEffect(() => {
    getCustomFieldsOfUsecaseEntity();
  }, []);

  const transformStandardFields = (fields: any) => {
    const usecaseFieldsTemp: any = {};
    for (const property in fields) {
      if (property == 'case_stage') {
        usecaseFieldsTemp[property] = {
          ...fields[property],
          validValues: [...usecaseStagesDropdownValues.map((field: any) => field.custom_stage_name)],
        };
      } else if (property == 'case_category') {
        usecaseFieldsTemp[property] = {
          ...fields[property],
          validValues: [...usecaseCategoryDropdownValues.map((field: any) => field.custom_category_name)],
        };
      } else if (property == 'case_priority') {
        usecaseFieldsTemp[property] = {
          ...fields[property],
          validValues: ['Critical', 'High', 'Medium', 'Low'],
        };
      } else {
        usecaseFieldsTemp[property] = fields[property];
      }
    }
    console.log(usecaseFieldsTemp);
    return usecaseFieldsTemp;
  };

  const getCustomFieldsOfUsecaseEntity = async () => {
    const usecaseCustomFields = await dispatch(getAllUsecaseCustomFields()).unwrap();
    setUsecaseEntityWithCustomFields({
      ...transformStandardFields(usecaseEntityStandardFields),
      ...transformCustomFieldDataForEntityStructure(usecaseCustomFields),
    });
  };
  console.log('usecaseEntityStandardFields  = ', usecaseEntityStandardFields);
  console.log('usecaseEntityWithCustomFields  = ', usecaseEntityWithCustomFields);
  return { usecaseEntityWithCustomFields };
};

export default usecaseEntity;
