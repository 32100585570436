export interface AuthState {
  loading: boolean;
  isAuthenticated: boolean;
  error?: string;
  isRegistered?: boolean;
}

export enum requestState {
  idle = 'idle',
  loading = 'loading',
  success = 'success',
  failed = 'failed',
}

export enum status {
  BLUE = 'BLUE',
  GREEN = 'GREEN',
  AMBER = 'AMBER',
  RED = 'RED',
}
