import { apis } from '../axiosInstance';
import { commonRoutes, customerRoutes } from '../route';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const getJourneyStage = () => apis.phpAxiosInstance.get(commonRoutes.getJourneyStage(), { headers: { JWT: 'true' } });
const getCustomJourneyState_Data = () => {
  return apis.phpAxiosInstance.get(
    commonRoutes.getCustomJourneyStageData(cookies.get('cogni_customer_uid') as string),
    {
      headers: { JWT: 'true' },
    }
  );
};
const getIndustryValue = () => apis.phpAxiosInstance.get(commonRoutes.getIndustryValue(), { headers: { JWT: 'true' } });
const getSegmentValue = () => apis.phpAxiosInstance.get(commonRoutes.getSegmentValue(), { headers: { JWT: 'true' } });
const getCompanyUsers = () =>
  apis.phpAxiosInstance.get(commonRoutes.getAllUsersOfCompany(cookies.get('cogni_customer_uid') as string), {
    headers: { JWT: 'true' },
  });

const getAllClientContactsByCustomerUid = () =>
  apis.phpAxiosInstance.get(
    commonRoutes.getAllClientContactsByCustomerUid(cookies.get('cogni_customer_uid') as string),
    {
      headers: { JWT: 'true' },
    }
  );
const getAllClientsByCustomerUid = () =>
  apis.phpAxiosInstance.get(commonRoutes.getAllClientsByCustomerUid(cookies.get('cogni_customer_uid') as string), {
    headers: { JWT: 'true' },
  });
const getCustomFields = () =>
  apis.phpAxiosInstance.get(commonRoutes.getCustomFields(cookies.get('cogni_customer_uid') as string), {
    headers: { JWT: 'true' },
  });
const getCustomerDetail = () =>
  apis.phpAxiosInstance.get(commonRoutes.getCustomerDetail(cookies.get('cogni_customer_uid') as string), {
    headers: { JWT: 'true' },
  });
const updateCaseStatusByCustomer = () =>
  apis.phpAxiosInstance.get(commonRoutes.updateCaseStatusByCustomer(cookies.get('cogni_customer_uid') as string), {
    headers: { JWT: 'true' },
  });
const getUserInfoByUid = (user_uid: string) =>
  apis.phpAxiosInstance.get(commonRoutes.getUserInfoByUid(user_uid), { headers: { JWT: 'false' } });
const checkEmailValid = (userDetails: any) =>
  apis.phpAxiosInstance.post(
    commonRoutes.checkEmailPresent(),
    { user_email: userDetails.user_email, base_url: userDetails.base_url },
    { headers: { JWT: 'false' } }
  );

const verifyEmailAddress = (user_email: string) =>
  apis.phpAxiosInstance.post(commonRoutes.sendVerifyEmail(), { user_email: user_email }, { headers: { JWT: 'false' } });

const sendForgotPasswordEmail = (userDetails: any) =>
  apis.phpAxiosInstance.post(
    commonRoutes.sendForgotPasswordEmail(),
    { user_email: userDetails.user_email, base_url: userDetails.base_url },
    { headers: { JWT: 'false' } }
  );

const getUserDetail = () =>
  apis.phpAxiosInstance.get(commonRoutes.getUserDetail(cookies.get('cogni_user_uid') as string), {
    headers: { JWT: 'true' },
  });
const checkAndValidateStatus = (dataObj: any) =>
  apis.phpAxiosInstance.post(
    commonRoutes.checkAndValidateStatus(),
    { token_id: dataObj.token_id, token: dataObj.token },
    { headers: { JWT: 'false' } }
  );

const updatePasswordByUId = (updateObj: any) =>
  apis.phpAxiosInstance.post(
    commonRoutes.updatePasswordByUid(),
    {
      user_uid: updateObj.user_uid,
      user_password: updateObj.user_password,
    },
    { headers: { JWT: 'false' } }
  );

const createUserPassword = (dataObj: any) =>
  apis.phpAxiosInstance.post(
    commonRoutes.createUserPassword(),
    {
      user_uid: dataObj.user_uid,
      account_id: dataObj.account_id,
      token: dataObj.token,
      user_password: dataObj.user_password,
    },
    { headers: { JWT: 'false' } }
  );

const sendUserSignupEmail = (userSignupDetails: any) =>
  apis.phpAxiosInstance.post(commonRoutes.sendUserSignupEmail(), userSignupDetails, { headers: { JWT: 'false' } });
const sendUserSignupEmailGmail = (userSignupDetails: any) =>
  apis.phpAxiosInstance.post(commonRoutes.sendUserSignupEmailGmail(), userSignupDetails, { headers: { JWT: 'false' } });

const sendUserSignupNotification = (userSignupDetails: any) =>
  apis.phpAxiosInstance.post(commonRoutes.sendUserSignupNotification(), userSignupDetails, {
    headers: { JWT: 'false' },
  });

const completeUserAccount = (userSignupDetailsGoogle: any) =>
  apis.phpAxiosInstance.post(
    commonRoutes.completeUserAccount(),
    {
      customer_company: userSignupDetailsGoogle.customer_company,
      user_job_title: userSignupDetailsGoogle.user_job_title,
      user_password: userSignupDetailsGoogle.user_password,
      user_signup_reason: userSignupDetailsGoogle.user_signup_reason,
      user_uid: userSignupDetailsGoogle.user_uid,
    },
    { headers: { JWT: 'false' } }
  );

const sendUserSignupGoogle = (googleUserObject: any) =>
  apis.phpAxiosInstance.post(
    commonRoutes.sendUserSignupEmail(),
    {
      access_token: googleUserObject.access_token,
      base_url: googleUserObject.base_url,
      customer_company: googleUserObject.customer_company,
      signup_method: googleUserObject.signup_method,
      user_email: googleUserObject.user_email,
      user_job_title: googleUserObject.user_job_title,
      user_signup_reason: googleUserObject.user_signup_reason,
      user_uid: googleUserObject.user_uid,
      user_username: googleUserObject.user_username,
    },
    { headers: { JWT: 'false' } }
  );

const mailToSupport = (reqObj: any) =>
  apis.phpAxiosInstance.post(commonRoutes.mailToSupport(), reqObj, { headers: { JWT: 'false' } });

const feedbackMailTosupport = (reqObj: any) =>
  apis.phpAxiosInstance.post(commonRoutes.feedbackMailTosupport(), reqObj, { headers: { JWT: 'false' } });

const updateCaseWithFeatureStatus = (client_uid: string) =>
  apis.phpAxiosInstance.get(commonRoutes.updateCaseWithFeatureStatus(client_uid), { headers: { JWT: 'true' } });

const updateTimeTrackingInSettings = (detail: any) =>
  apis.phpAxiosInstance.post(commonRoutes.updateTimeTrackingInSettings(), detail, { headers: { JWT: 'true' } });

const getRenamedLabels = () =>
  apis.phpAxiosInstance.get(commonRoutes.getRenamedLabels(cookies.get('cogni_customer_uid') as string), {
    headers: { JWT: 'true' },
  });

const getAllEntitiesOfCustomer = () =>
  apis.phpAxiosInstance.get(commonRoutes.getAllEntitiesOfCustomer(cookies.get('cogni_customer_uid') as string), {
    headers: { JWT: 'true' },
  });

const createCustomReport = (detail: any) =>
  apis.phpAxiosInstance.post(commonRoutes.createCustomReport(), detail, {
    headers: { JWT: 'true' },
  });

const getCustomReports = (detail: any) =>
  apis.phpAxiosInstance.get(commonRoutes.getCustomReports(detail), {
    headers: { JWT: 'true' },
  });

const getReport = (reportUid: string) =>
  apis.phpAxiosInstance.get(commonRoutes.getReportByUid(reportUid), {
    headers: { JWT: 'true' },
  });

const getReportDetail = (uid: string) =>
  apis.phpAxiosInstance.get(commonRoutes.getReportConfig(uid), {
    headers: { JWT: 'true' },
  });

const updateCustomReport = (detail: string) =>
  apis.phpAxiosInstance.post(commonRoutes.updateCustomReport(), detail, {
    headers: { JWT: 'true' },
  });

const getAllProjectMembersWhichAreClientContact = () =>
  apis.phpAxiosInstance.get(customerRoutes.allClientProjectMember(cookies.get('cogni_customer_uid') as string), {
    headers: { JWT: 'true' },
  });

const getProjectCategories = () =>
  apis.phpAxiosInstance.get(commonRoutes.getProjectCategory(cookies.get('cogni_customer_uid') as string), {
    headers: { JWT: 'true' },
  });

const getProjectCustomCategories = () =>
  apis.phpAxiosInstance.get(commonRoutes.getProjectCustomCategory(cookies.get('cogni_customer_uid') as string), {
    headers: { JWT: 'true' },
  });

const getProjectStage = () =>
  apis.phpAxiosInstance.get(commonRoutes.getProjectStage(cookies.get('cogni_customer_uid') as string), {
    headers: { JWT: 'true' },
  });

const getProjectCustomStage = () =>
  apis.phpAxiosInstance.get(commonRoutes.getProjectCustomStage(cookies.get('cogni_customer_uid') as string), {
    headers: { JWT: 'true' },
  });

const getUsecaseCategories = () =>
  apis.phpAxiosInstance.get(commonRoutes.getUsecaseCategory(cookies.get('cogni_customer_uid') as string), {
    headers: { JWT: 'true' },
  });

const getUsecaseCustomCategories = () =>
  apis.phpAxiosInstance.get(commonRoutes.getUsecaseCustomCategory(cookies.get('cogni_customer_uid') as string), {
    headers: { JWT: 'true' },
  });

const getUsecaseStage = () =>
  apis.phpAxiosInstance.get(commonRoutes.getUsecaseStage(cookies.get('cogni_customer_uid') as string), {
    headers: { JWT: 'true' },
  });

const getUsecaseCustomStage = () =>
  apis.phpAxiosInstance.get(commonRoutes.getUsecaseCustomStage(cookies.get('cogni_customer_uid') as string), {
    headers: { JWT: 'true' },
  });

const saveAppState = (details: any) =>
  apis.phpAxiosInstance.post(commonRoutes.saveAppStateConfigurations(), details, {
    headers: { JWT: 'true' },
  });

const getAppStateConfigurations = (details: any) =>
  apis.phpAxiosInstance.get(commonRoutes.getAppStateConfigurations(details.customer_uid, details.user_uid), {
    headers: { JWT: 'true' },
  });

const getProjectPriority = () =>
  apis.phpAxiosInstance.get(commonRoutes.getProjectPriority(cookies.get('cogni_customer_uid') as string), {
    headers: { JWT: 'true' },
  });

const getProjectCustomPriority = () =>
  apis.phpAxiosInstance.get(commonRoutes.getProjectCustomPriority(cookies.get('cogni_customer_uid') as string), {
    headers: { JWT: 'true' },
  });

const getUsecasePriority = () =>
  apis.phpAxiosInstance.get(commonRoutes.getUsecasePriority(cookies.get('cogni_customer_uid') as string), {
    headers: { JWT: 'true' },
  });

const getUsecaseCustomPriority = () =>
  apis.phpAxiosInstance.get(commonRoutes.getUsecaseCustomPriority(cookies.get('cogni_customer_uid') as string), {
    headers: { JWT: 'true' },
  });

const getTaskPriority = () =>
  apis.phpAxiosInstance.get(commonRoutes.getTaskPriority(cookies.get('cogni_customer_uid') as string), {
    headers: { JWT: 'true' },
  });

const getTaskCustomPriority = () =>
  apis.phpAxiosInstance.get(commonRoutes.getTaskCustomPriority(cookies.get('cogni_customer_uid') as string), {
    headers: { JWT: 'true' },
  });

const getTaskStage = () =>
  apis.phpAxiosInstance.get(commonRoutes.getTaskStage(cookies.get('cogni_customer_uid') as string), {
    headers: { JWT: 'true' },
  });

const getTaskCustomStage = () =>
  apis.phpAxiosInstance.get(commonRoutes.getTaskCustomStage(cookies.get('cogni_customer_uid') as string), {
    headers: { JWT: 'true' },
  });

const getStandardAndCustomNames = () =>
  apis.phpAxiosInstance.get(commonRoutes.getStandardAndCustomNames(cookies.get('cogni_customer_uid') as string), {
    headers: { JWT: 'true' },
  });

export const clientListRequiredApis = {
  getJourneyStage,
  getCustomJourneyState_Data,
  getIndustryValue,
  getSegmentValue,
  getCompanyUsers,
  getCustomFields,
  getCustomerDetail,
};

export const commonAPI = {
  checkEmailValid,
  sendForgotPasswordEmail,
  getUserDetail,
  sendUserSignupEmail,
  sendUserSignupEmailGmail,
  sendUserSignupNotification,
  sendUserSignupGoogle,
  verifyEmailAddress,
  checkAndValidateStatus,
  getUserInfoByUid,
  createUserPassword,
  completeUserAccount,
  updatePasswordByUId,
  getIndustryValue,
  getSegmentValue,
  getJourneyStage,
  getCustomJourneyState_Data,
  getCompanyUsers,
  getCustomerDetail,
  getCustomFields,
  updateCaseWithFeatureStatus,
  mailToSupport,
  feedbackMailTosupport,
  updateTimeTrackingInSettings,
  getAllClientContactsByCustomerUid,
  getRenamedLabels,
  getAllClientsByCustomerUid,
  getAllEntitiesOfCustomer,
  createCustomReport,
  getCustomReports,
  getReport,
  getReportDetail,
  updateCustomReport,
  updateCaseStatusByCustomer,
  getAllProjectMembersWhichAreClientContact,
  getProjectCategories,
  getProjectCustomCategories,
  getProjectStage,
  getProjectCustomStage,
  getUsecaseCategories,
  getUsecaseCustomCategories,
  getUsecaseStage,
  getUsecaseCustomStage,
  saveAppState,
  getAppStateConfigurations,
  getProjectPriority,
  getProjectCustomPriority,
  getUsecasePriority,
  getUsecaseCustomPriority,
  getTaskPriority,
  getTaskCustomPriority,
  getTaskStage,
  getTaskCustomStage,
  getStandardAndCustomNames,
};
