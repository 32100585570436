import { label_client, makeLabelCookies, refreshLabelCookies } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import {
    GridCellParams,
    GridColumns,
    MuiEvent,
} from '@mui/x-data-grid';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import Cookies from 'universal-cookie';
import { Typography, Box, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CognisaasLoader from 'components/shared/CognisaasLoader/CognisaasLoader';
import { selectUserObj, selectUserObjStatus } from 'store/modules/CustomerPortal/selector';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { alpha, styled } from '@mui/material/styles';
import { DataGridPremium, gridClasses } from '@mui/x-data-grid-premium';
import { useEffect } from 'react';
import { client_userSignIn_withjwttoken } from 'store/modules/CustomerPortal/CustomerPortal';
import { fetchCustomLabel } from 'store/modules/Common/slice';
import useCustomNames from 'components/shared/CustomNames/useCustomNames';
const ODD_OPACITY = 0.2;

const ClientSelectionOnLanding = () => {
    const cookies = new Cookies();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const linking = (params: any) => {
        cookies.set('client_uid', params.row.client_uid);
        navigate('/customer-portal/dashboard', { replace: true });
    };
    const userObject: any = useAppSelector(selectUserObj);
    const userObjectStatus: any = useAppSelector(selectUserObjStatus);
    const { loadCustomNamesModule } = useCustomNames();
    const setCustomLabelCookies = async () => {
        await dispatch(fetchCustomLabel())
            .unwrap()
            .then((data: any) => {
                if (data.status === 200) {
                    if (data.data.result.length > 0) {
                        makeLabelCookies(data.data.result);
                        refreshLabelCookies();
                        loadCustomNamesModule();
                    } else if (data.data.result.length === 0) {
                        makeLabelCookies([]);
                        refreshLabelCookies();
                        loadCustomNamesModule();
                    }
                }
            })
            .catch((err: any) => {
                console.log(err);
            });
    };
    const StyledBox = styled(Box)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        height: 300,
        width: '95%',
        borderRadius: '4px',
        boxShadow: '0px 4px 26px rgba(0, 0, 0, 0.05)',
        '& .MuiFormGroup-options': {
            alignItems: 'center',
            paddingBottom: theme.spacing(1),
            '& > div': {
                minWidth: 100,
                margin: theme.spacing(2),
                marginLeft: 0,
            },
        },
    }));
    const StripedDataGrid = styled(DataGridPremium)(({ theme }) => ({
        [`& .${gridClasses.row}.even`]: {
            backgroundColor: theme.palette.grey[200],
            '&:hover, &.Mui-hovered': {
                backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
                '@media (hover: none)': {
                    backgroundColor: 'transparent',
                },
            },
            '&.Mui-selected': {
                backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
                '&:hover, &.Mui-hovered': {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        ODD_OPACITY + theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity
                    ),
                    // Reset on touch devices, it doesn't add specificity
                    '@media (hover: none)': {
                        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
                    },
                },
            },
        },
    }));
    const columns: GridColumns<any> = [
        {
            headerName: `${label_client} Name`,
            field: 'client_name',
            width: 240,
            renderCell: (params: GridRenderCellParams) => {
                return <Typography className="cognisaas-pointer" variant="subtitle1" style={{
                    color: '#2D60F6'
                }} >{params.value}</Typography>;
            },
        },
    ];
    const data = {
        columns: columns,
        rows: userObject,
    };
    const initialState: any = {
        pagination: {
            page: 0,
            pageSize: 10,
        }
    };
    useEffect(() => {
        cookies.set('oneToManyClient', true);
    }, []);


    useEffect(() => {
        console.log('client_userSignIn_withjwttoken', userObject, 'Length', userObject.length);
        if (!userObject.length) {
            dispatch(client_userSignIn_withjwttoken(cookies.get('jwt-token')));
        } else {
            if (userObjectStatus == 'success' && cookies.get('jwt-token')) {
                if (userObject.length > 1) {
                    setCustomLabelCookies();
                    cookies.set('oneToManyClient', true);
                    navigate('/customer-portal/landing');
                } else {
                    cookies.set('oneToManyClient', false);
                    navigate('/customer-portal/dashboard');
                }
            }
        }
    }, [userObject]);

    return (
        <>
            <Box sx={{
                mt: '25px',
            }}>
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={0.5}
                >
                    {/* <Typography variant="h2"> Please select the {label_client}</Typography> */}
                    <StyledBox>
                        {userObject.length > 0 ? (
                            <StripedDataGrid
                                {...data}
                                initialState={initialState}
                                loading={false}
                                getRowId={(row: any) => row.client_uid}
                                disableSelectionOnClick
                                rowThreshold={10}
                                rowsPerPageOptions={[5, 10, 20, 50, 100]}
                                pagination
                                getRowHeight={() => 'auto'}
                                sx={{
                                    '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
                                    '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '7px' },
                                    '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
                                }}
                                getRowClassName={(params: any) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'odd' : 'odd')}
                                onCellClick={(params: GridCellParams, event: MuiEvent<React.MouseEvent>) => {
                                    event.defaultMuiPrevented = true;
                                    linking(params);
                                }}
                            />
                        ) : (
                            <CognisaasLoader />
                        )}
                    </StyledBox>
                </Stack>

            </Box>
        </>
    );
};

export default ClientSelectionOnLanding;