import { useAppDispatch } from 'store/hooks';
import { getCustomerDetail } from '../../../store/modules/Common/slice';
import { selectCustomerDetail } from 'store/modules/Common/selector';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { companyHolidays } from 'utils/getUid';
import { format, addDays } from 'date-fns';

export const useNextDayIfItIsHoliday = (date: Date) => {
  const dispatch = useAppDispatch();
  const customerDetail = useAppSelector(selectCustomerDetail);
  const [dateToReturn, setDateToReturn] = useState<Date>(date);

  const convertToDate = (dates: any[]) => {
    const dateObjects = dates.map((date: any) => format(new Date(date), 'yyyy-MM-dd'));
    return dateObjects;
  };

  const checkTheDateForHoliday = () => {
    const holidays = companyHolidays();
    const dateObjects = convertToDate(holidays);
    if (dateObjects.includes(format(date, 'yyyy-MM-dd'))) {
      setDateToReturn(addDays(date, 1));
      return;
    } else {
      setDateToReturn(date);
      return;
    }
  };

  useEffect(() => {
    if (customerDetail) {
      checkTheDateForHoliday();
    } else {
      dispatch(getCustomerDetail());
    }
  }, [selectCustomerDetail]);

  return dateToReturn;
};
