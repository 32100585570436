import './CognisaasAlertBlinker.css';

interface Props {
    type:'info' | 'danger' | 'primary' | 'success' | 'warning';
}

const CognisaasAlertBlinker = (props:Props) => {
    return (
        <span className={`${props.type} blinker`}>&#9888;</span>
    );
};

export default CognisaasAlertBlinker;