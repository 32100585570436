import { Formik } from 'formik';
import { Stack, Typography, TextField, Box } from '@mui/material';
// import CognisaasAutocompleteSelect from 'components/shared/CognisaasSelect/CognisaasAutocompleteSelect';
import { getUserUid } from 'utils/getUid';
import { AddProjectTemplateFormInitialValues } from '../Project/AddProjectTemplate/helper';
import { newProjectTemplateSchema } from 'utils/validations';
import useUserDropDownData from 'components/shared/Hooks/useUserDropDownData';
import CognisaasUserSelect from 'components/shared/CognisaasSelect/CognisaasUserSelect';
import { userPermissionSchema } from 'components/Reports/SideDrawer/types';
import { getUserActiveState } from 'utils/userHelpers';

const TemplateOverviewSection = (props: any) => {
  const companyUsersDropDownData = useUserDropDownData('company');
  const userPermissions = props.userPermissions;

  return (
    <>
      <Formik
        initialValues={{ ...AddProjectTemplateFormInitialValues }}
        onSubmit={(values: any) => {
          console.log(values, 'values');
        }}
        validationSchema={newProjectTemplateSchema}
      >
        {(formik: any) => (
          <form onSubmit={formik.handleSubmit}>
            <Stack direction="row" spacing={4}>
              <Box>
                <Typography sx={{ fontSize: '12px', fontWeight: '400' }}>Template Name*</Typography>
                <TextField
                  disabled={!userPermissions?.includes(userPermissionSchema.EDIT)}
                  placeholder="Enter Template Name"
                  variant="outlined"
                  fullWidth
                  size="small"
                  sx={{ backgroundColor: '#FFFFFF', mt: '4px' }}
                  id="templateName"
                  name="templateName"
                  value={props.name}
                  onBlur={formik.handleBlur}
                  onChange={(event: any) => {
                    formik.handleChange(event);
                    props.newTemplateName(event.target.value);
                  }}
                  error={formik.touched.templateName && Boolean(formik.errors.templateName)}
                  helperText={formik.touched.templateName && formik.errors.templateName}
                />
              </Box>
              <Box>
                <Typography sx={{ fontSize: '12px', fontWeight: '400', marginBottom: '4px' }}>
                  Template Owner
                </Typography>

                <CognisaasUserSelect
                  disabled={!userPermissions?.includes(userPermissionSchema.EDIT)}
                  width="310px"
                  sx={{ background: 'white' }}
                  name="projectTemplateOwner"
                  allOptions={companyUsersDropDownData}
                  selectedOption={{
                    id: getUserUid(props.owner),
                    name:
                      getUserActiveState(getUserUid(props.owner), companyUsersDropDownData) === '0'
                        ? `${props.owner} (Inactive)`
                        : props.owner,
                    is_active: getUserActiveState(getUserUid(props.owner), companyUsersDropDownData),
                  }}
                  onChange={(val: any) => {
                    console.log(val);
                    formik.setFieldValue('projectTemplateOwner', val);
                    props.newTemplateOwner(val.target.textContent);
                  }}
                />
              </Box>
              <Box>
                <Typography sx={{ fontSize: '12px', fontWeight: '400' }}>
                  Target number of days for delivery*
                </Typography>
                <TextField
                  disabled={!userPermissions?.includes(userPermissionSchema.EDIT)}
                  placeholder="Enter no. of days"
                  variant="outlined"
                  fullWidth
                  size="small"
                  type="number"
                  id="targetNoOfDays"
                  name="projectTargetNumberOfDays"
                  value={props.days}
                  onBlur={formik.handleBlur}
                  onChange={(params: any) => {
                    console.log(params.target.value, 'params');
                    formik.handleChange(params);
                    props.newTemplateTargetDays(params.target.value);
                  }}
                  error={formik.touched.targetNoOfDays && Boolean(formik.errors.targetNoOfDays)}
                  helperText={formik.touched.targetNoOfDays && formik.errors.targetNoOfDays}
                  sx={{ backgroundColor: '#FFFFFF', mt: '4px' }}
                />
              </Box>
            </Stack>
          </form>
        )}
      </Formik>
    </>
  );
};

export default TemplateOverviewSection;
