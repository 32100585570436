import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../index';

export const selectTaskState = (state: RootState) => state.taskState;

export const selectJiraPriorityList = createSelector(selectTaskState, (taskState) => taskState.getJiraPriority);
export const selectTaskInformation = createSelector(selectTaskState, (taskState) => taskState.getTaskByTaskUid);

export const selectJiraProjects = createSelector(selectTaskState, (taskState) => taskState.getJiraProjects);

export const selectAllTasksUnderProject = createSelector(
  selectTaskState,
  (taskState) => taskState.getAllTasksUnderProject
);
export const selectAllTasksUnderProjectStatus = createSelector(
  selectTaskState,
  (taskState) => taskState.getAllTasksUnderProjectStatus
);

export const selectProjectSubtasks = createSelector(selectTaskState, (taskState) => taskState.getProjectSubtasks);

export const selectUsecaseSubtasks = createSelector(selectTaskState, (taskState) => taskState.getUsecaseSubtasks);

export const selectTaskCustomfields = createSelector(selectTaskState, (taskState) => taskState.getTaskCustomfields);

export const selectTaskCustomfieldsWithValue = createSelector(
  selectTaskState,
  (taskState) => taskState.getTaskCustomfieldsWithValue
);

export const selectTaskDependency = createSelector(selectTaskState, (taskState) => taskState.getTaskDependency);
export const selectTaskDependencyStatus = createSelector(selectTaskState, (taskState) => taskState.getTaskDependencyStatus);

export const selectProjectTaskDependency = createSelector(
  selectTaskState,
  (taskState) => taskState.getProjectTaskDependency
);

export const selectTasksOfUser = createSelector(selectTaskState, (taskState) => taskState.getTasksOfUser);

export const selectProjectTaskComments = createSelector(
  selectTaskState,
  (taskState) => taskState.getProjectTaskComments
);

export const selectProjectTaskCommentsStatus = createSelector(
  selectTaskState,
  (taskState) => taskState.getProjectTaskCommentsStatus
);

export const selectAddProjectTaskCommentsStatus = createSelector(
  selectTaskState,
  (taskState) => taskState.addProjectTaskCommentStatus
);

export const selectUpdateProjectTaskCommentsStatus = createSelector(
  selectTaskState,
  (taskState) => taskState.updateProjectTaskCommentStatus
);

export const selectUpdateUsecaseTaskCommentsStatus = createSelector(
  selectTaskState,
  (taskState) => taskState.updateUsecaseTaskCommentStatus
);

export const selectProjectClientTaskComments = createSelector(
  selectTaskState,
  (taskState) => taskState.getProjectClientTaskComments
);

export const selectProjectClientTaskCommentsStatus = createSelector(
  selectTaskState,
  (taskState) => taskState.getProjectClientTaskCommentsStatus
);

export const selectUsecaseTaskComments = createSelector(
  selectTaskState,
  (taskState) => taskState.getUsecaseTaskComments
);

export const selectUsecaseTaskCommentsStatus = createSelector(
  selectTaskState,
  (taskState) => taskState.getUsecaseTaskCommentsStatus
);

export const selectUsecaseClientTaskComments = createSelector(
  selectTaskState,
  (taskState) => taskState.getUsecaseClientTaskComments
);

export const selectUsecaseClientTaskCommentsStatus = createSelector(
  selectTaskState,
  (taskState) => taskState.getUsecaseClientTaskCommentsStatus
);

export const selectJiraProjectIssueType = createSelector(
  selectTaskState,
  (taskState) => taskState.getJiraProjectIssueTypes
);

export const selectProjectTaskTimeLog = createSelector(selectTaskState, (taskState) => taskState.getProjectTaskTimeLog);

export const selectUsecaseTaskTimeLog = createSelector(selectTaskState, (taskState) => taskState.getUsecaseTaskTimeLog);

export const selectchangeTaskStatus = createSelector(selectTaskState, (taskState) => taskState.changeTaskStatusStatus);

export const selectIncrementTaskSequenceStatus = createSelector(
  selectTaskState,
  (taskState) => taskState.incrementProjectTaskSequenceStatus
);

export const selectIncrementTaskSequenceError = createSelector(
  selectTaskState,
  (taskState) => taskState.incrementProjectTaskSequenceError
);

export const selectIncrementCaseTaskSequenceStatus = createSelector(
  selectTaskState,
  (taskState) => taskState.incrementCaseTaskSequenceStatus
);

export const selectIncrementCaseTaskSequenceError = createSelector(
  selectTaskState,
  (taskState) => taskState.incrementCaseTaskSequenceError
);
export const selectTaskAuditTrail = createSelector(selectTaskState, (taskState) => taskState.getTaskAuditTrail);

export const selectProjectTaskAuditTrail = createSelector(
  selectTaskState,
  (taskState) => taskState.getProjectTaskAuditTrail
);

export const selectClientTaskAuditTrail = createSelector(
  selectTaskState,
  (taskState) => taskState.getClientTaskAuditTrail
);

export const selectSubtaskAuditTrail = createSelector(selectTaskState, (taskState) => taskState.getSubtaskAuditTrail);

export const selectProjectClientTaskAuditTrail = createSelector(
  selectTaskState,
  (taskState) => taskState.getProjectClientTaskAuditTrail
);

export const selectGetAllEligibleTaskOwnerDetailsOfClient = createSelector(
  selectTaskState,
  (taskState) => taskState.getAllEligibleTaskOwnerDetailsOfClient
);

export const selectUpdatingTaskCustomField = createSelector(
  selectTaskState,
  (taskState) => taskState.updatingTaskCustomFields
);

export const selectJiraSyncedTasksStatus = createSelector(selectTaskState, (taskState) => taskState.syncedJiraTasks);

export const selectTaskModalDetail = createSelector(selectTaskState, (taskState) => taskState.editTaskModal.detail);

export const selectTaskModalInitialState = createSelector(
  selectTaskState,
  (taskState) => taskState.editTaskModal.initialFormState
);

export const selectTaskModalTimeTracking = createSelector(
  selectTaskState,
  (taskState) => taskState.editTaskModal.timeTracking
);

export const selectTaskModalCustomfields = createSelector(
  selectTaskState,
  (taskState) => taskState.editTaskModal.customFields
);

export const selectTaskModalCustomfieldsFormRef = createSelector(
  selectTaskState,
  (taskState) => taskState.editTaskModal.customFieldsFormRef
);

export const selectTaskModalDetailsFormRef = createSelector(
  selectTaskState,
  (taskState) => taskState.editTaskModal.detailFormRef
);
