import { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import OnboardAccountProfile from './OnboardAccountProfile';
import OnboardCustomFields from './OnboardCustomFields';
import {
  resetOnboardFomvalues,
  updateOnboardAccountProfile,
  addAccountProfileToDraftInDb,
  resetOnBoardNewAccountToDraftState,
  addClientAuditTrail,
  fetchCustomFields,
  resetWholeOnboardingProcess,
  createProjectFromProjectTemplate,
  updateOnboardCustomFields,
  activeOnboardProjectButtonStatus,
  deActivateOnboardProjectButtonStatus,
} from '../../../store/modules/ManageClient/OnboardFormValues/slice';
import { useNavigate } from 'react-router-dom';
import {
  selectAddAccountToDraftInDb,
  selectAddedClientUidState,
  selectCustomFieldsUpdateToDbState,
  selectAssociationUid,
  selectCustomFieldsFromServer,
  selectCreatingProjectFromProjectTemplate,
} from '../../../store/modules/ManageClient/OnboardFormValues/selector';
import { selectAttachmentQueue } from '../../../store/modules/Attachment/selector';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  getStageUid,
  getIndustryUid,
  getSegmentUid,
  getLoggedInUserUid,
  getLoggedInUserName,
} from '../../../utils/getUid';

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Styles from './OnboardNewAccount.module.scss';
import { StepLabel } from '@mui/material';
import { ReactComponent as Check } from '../../../assets/svgs/check.svg';
import OnboardAddProjects from './OnboardAddProjects';
import { addAttachmentToServer, resetAttachmentQueue } from '../../../store/modules/Attachment/slice';
import { updateClientCustomField_slice } from '../../../store/modules/ManageClient/OnboardFormValues/slice';
import { isNull, isUndefined } from '../../../utils/dataHelpers';

import { apis } from '../../../api/axiosInstance';
import { format } from 'date-fns';
import {
  addClientContact,
  fetchUserDetail,
  sendClientContactAdditionMail,
  updateClientStatus,
} from 'store/modules/ManageClient/ClientList/slice';
import { selectAddedClientContactUid, selectUserFullDetail } from 'store/modules/ManageClient/ClientList/selector';
import Cookies from 'universal-cookie';
import CognisaasToast from 'components/shared/CognisaasToast/CognisaasToast';
import { addMembersForProject } from 'store/modules/Project/slice';
import { label_client, label_due_date, label_project } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import useCustomNames from 'components/shared/CustomNames/useCustomNames';

const cookies = new Cookies();

const steps = [`${label_client} Profile`, 'Custom Fields', `Add ${label_project}`];

interface CognisaasStepIconProps {
  index: number;
  active?: boolean;
  completed?: boolean;
  className?: string;
}

const OnboardNewAccountIndex = () => {
  const navigate = useNavigate();
  const [alertDetails, setAlertDetails] = useState<{
    isVisible: boolean;
    type: 'success' | 'failure';
    message: string;
  }>({ isVisible: false, type: 'success', message: '' });

  // if web app is used in mobiles
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const dispatch = useAppDispatch();
  const attachmentQueue = useAppSelector(selectAttachmentQueue);
  const client_uid = useAppSelector(selectAddedClientUidState);
  const addAccountToDraftInDbState = useAppSelector(selectAddAccountToDraftInDb);
  const customFieldsUpdateOnServerState = useAppSelector(selectCustomFieldsUpdateToDbState);

  const associationUid = useAppSelector(selectAssociationUid);
  const customFieldsFromServer = useAppSelector(selectCustomFieldsFromServer);
  const userDetail = useAppSelector(selectUserFullDetail);
  const addedClientCOntactUid = useAppSelector(selectAddedClientContactUid);
  const [reqObjForEmail, setreqObjForEmail] = useState({});
  const [onbaordAccountProfileContactList, setOnbaordAccountProfileContactList] = useState<any[]>([]);
  const [contactPrimaryIndex, setContactPrimaryIndex] = useState<number>(0);
  const newProjectData = useAppSelector(selectCreatingProjectFromProjectTemplate);
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState<{
    [k: number]: boolean;
  }>({});
  const { getStandardNameMappedValue } = useCustomNames();

  useEffect(() => {
    if (client_uid) {
      // setAddedClientUid(client_uid);
      onbaordAccountProfileContactList.forEach((contactDetails: any) => {
        // const multipleContactObj: any = [];
        if (
          contactDetails.name.length === 0 ||
          !validateEmail(contactDetails.email)
          // contactDetails.role.length === 0
        ) {
          // then don't add this contact details
        } else {
          const addContactDetails = {
            base_url: `https://${window.location.host}/customer-portal/${userDetail.user_company}/magic-link/`,
            client_uid: client_uid,
            contact_email: contactDetails.email,
            contact_name: contactDetails.name,
            contact_role: contactDetails.role,
            customer_company: userDetail.user_company,
            customer_uid: cookies.get('cogni_customer_uid') as string,
            primary_contact:
              contactDetails.email === onbaordAccountProfileContactList[contactPrimaryIndex].email ? 1 : 0,
            user_email: userDetail.user_email,
            user_username: userDetail.user_username,
            added_by_username: userDetail.user_username,
            last_magiclink_sent_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
          };
          setreqObjForEmail(addContactDetails);
          dispatch(addClientContact(addContactDetails));
        }
      });
      setTimeout(() => {
        setOnbaordAccountProfileContactList([]);
        setContactPrimaryIndex(0);
      }, 2000);
    }
  }, [client_uid]);

  useEffect(() => {
    if (newProjectData?.status === 'success' && !isNull(addedClientCOntactUid) && !isUndefined(addedClientCOntactUid)) {
      const newReqObjForEmail = { ...reqObjForEmail, contact_uid: addedClientCOntactUid };
      dispatch(sendClientContactAdditionMail(newReqObjForEmail));
    }
  }, [addedClientCOntactUid, newProjectData]);

  useEffect(() => {
    dispatch(fetchUserDetail());
    dispatch(resetAttachmentQueue());
  }, []);

  useEffect(() => {
    if (newProjectData?.status === 'failed') {
      setAlertDetails({
        isVisible: true,
        type: 'failure',
        message: 'Praject creation failed',
      });
    } else if (newProjectData?.status === 'success') {
      setAlertDetails({
        isVisible: true,
        type: 'success',
        message: 'Project added successfully',
      });
    }
  }, [newProjectData]);

  // form error and submission status handler
  useEffect(() => {
    if (addAccountToDraftInDbState.status === 'success') {
      // alert successful in onboarding
      if (isNull(addAccountToDraftInDbState.error)) {
        setAlertDetails({
          isVisible: true,
          type: 'success',
          message: `${label_client} Onboarded As Draft Successfully!`,
        });
        handleCompleteThisStep(0);
        setActiveStep(1);
        handleUnblockThisStep(1);
        if (customFieldsFromServer.data.filter((cf: any) => cf.mandatory == 1).length == 0) {
          handleUnblockThisStep(2);
        }
      }
    } else if (addAccountToDraftInDbState.status === 'failed') {
      setAlertDetails({
        isVisible: true,
        type: 'failure',
        message: JSON.stringify(addAccountToDraftInDbState.error),
      });
      dispatch(resetOnBoardNewAccountToDraftState());
    }
  }, [addAccountToDraftInDbState]);

  // track custom fields updating to server
  useEffect(() => {
    if (customFieldsUpdateOnServerState.status === 'failed') {
      console.log(customFieldsUpdateOnServerState);
    }
  }, [customFieldsUpdateOnServerState]);

  useEffect(() => {
    dispatch(fetchCustomFields());

    return () => {
      // on unmounting reset onboard state
      dispatch(resetWholeOnboardingProcess());
    };
  }, []);

  const handleStep = (step: number) => () => {
    // check if this step completed or not then only come to here

    if (completed[step] === true || completed[step] === false) {
      setActiveStep(step);
    }
  };

  const validateEmail = (input: string) => {
    const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (input.match(validRegex)) {
      return true;
    } else {
      return false;
    }
  };

  const handleCompleteThisStep = (step: number) => {
    setCompleted((prevCompleted) => {
      const newCompleted = { ...prevCompleted };
      newCompleted[step] = true;
      return { ...prevCompleted, [step]: true };
    });
  };

  const handleUnblockThisStep = (step: number) => {
    setCompleted((prevCompleted) => {
      const newCompleted = { ...prevCompleted };
      newCompleted[step] = false;
      return newCompleted;
    });
  };

  const attachmentQueueHelper = (attachmentQueue: any) => {
    attachmentQueue.forEach((attachment: any) => {
      dispatch(addAttachmentToServer(attachment));
    });
  };

  // Add Members for this project

  // const addMembersForThisProject = (fields: any) => {
  //   console.log(addMembersForThisProject);
  // };

  // ! NEVER DO THIS , Never do api calls inside component

  // ** REFACTOR NEEDED MOVE OUTSIDE OF COMPONENT

  const importTemplate = async (formFieldValues: any) => {
    const templateProjectInfo: any = await apis.phpAxiosInstance.get(
      `getTemplateProjectinformationwithtask?template_project_uid=${formFieldValues.importedTemplateProjectUid}`,
      { headers: { JWT: 'true' } }
    );
    console.log('templateProjectInfo', templateProjectInfo);

    console.log('template to be cloned id = ', formFieldValues.importedTemplateProjectUid);
    // new importing logic here
    const deliveryMangerAsAMemberForProject = {
      is_client_contact: '0',
      user_uid: formFieldValues.deliveryManager === 'Not Assigned' ? '15243' : formFieldValues.deliveryManager.id,
    };
    const members = [...formFieldValues.members, deliveryMangerAsAMemberForProject];
    const projectServerPayload = {
      // project_id: null,
      // project_uid: tempProjectUid,
      template_project_uid: formFieldValues.importedTemplateProjectUid,
      association_uid: associationUid,
      project_name: formFieldValues.projectName,
      project_fees: formFieldValues.implementationFeeInUSD,
      project_ARR: formFieldValues.projectARR == null ? 0 : formFieldValues.projectARR,
      project_description: formFieldValues.projectDescription,
      project_comment: formFieldValues.comment,
      project_reference: '',
      project_value: null,
      project_needed_status: formFieldValues.category == '' ? 'Land' : formFieldValues.category,
      project_stage: formFieldValues.projectStage === '' ? 'Pre-Sales' : formFieldValues.projectStage,
      project_priority: formFieldValues.priority,
      project_redinessstate_status: 'green',
      project_expansionvalue: formFieldValues.expansionValue ? formFieldValues.expansionValue : 0,
      project_status_client: 'red',
      project_status_reason: `The ${label_due_date.toLowerCase()} of this ${label_project.toLowerCase()} has passed`,
      project_status_reason_client: `The ${label_due_date.toLowerCase()} of this ${label_project.toLowerCase()} has passed`,
      project_startdate: format(new Date(formFieldValues.actualStartDate), 'yyyy-MM-dd HH:mm:ss'),
      project_planned_startdate: format(new Date(formFieldValues.plannedStartDate), 'yyyy-MM-dd HH:mm:ss'),
      project_deliverydate: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      project_created_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      project_modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      eta_forprojectdelivery: format(new Date(formFieldValues.etaForDeliveryDate), 'yyyy-MM-dd HH:mm:ss'),
      project_requiredbydate: format(new Date(formFieldValues.dueDate), 'yyyy-MM-dd HH:mm:ss'),
      project_deliverymanager: formFieldValues.deliveryManager ? formFieldValues.deliveryManager.name : 'Not Assigned',
      user_uid: formFieldValues.deliveryManager ? formFieldValues.deliveryManager.id : '15243',
      imported_template: formFieldValues.projectTemplate.length > 0 ? 1 : 0,
      project_rollout: 0,
      opportunity_id: null,
      last_user_modified: getLoggedInUserUid(),
      base_url: `https://${window.location.host}/`,
      members: [...members],
    };

    console.log('formFieldValues', formFieldValues);
    console.log('projectServerPayload', projectServerPayload);

    await dispatch(createProjectFromProjectTemplate(projectServerPayload))
      .unwrap()
      .then(async (res) => {
        const projectUid = res.result;
        const data: any = {
          members,
          association_uid: associationUid,
          project_uid: projectUid,
        };
        const detail = {
          status: 1,
          clientUid: client_uid,
        };
        await dispatch(addMembersForProject({ ...data }));
        await dispatch(updateClientStatus(detail));
      });
    dispatch(deActivateOnboardProjectButtonStatus());
  };

  const handleSave = async (
    formCategory: 'accountProfile' | 'customFields' | 'addProject' | 'reset',
    formFieldValues: Record<string, any>
  ) => {
    try {
      let projectServerPayload: any;
      switch (formCategory) {
        case 'accountProfile': {
          const salesOwnerUserId = formFieldValues.salesOwner ? formFieldValues.salesOwner.id : '15243';
          const stageUid =
            formFieldValues.journeyStage !== '' ? getStageUid(formFieldValues.journeyStage as string) : '';
          const segmentUid =
            formFieldValues.segment !== ''
              ? getSegmentUid(getStandardNameMappedValue(formFieldValues.segment, 'segment_name') as string)
              : '';
          const industryuid = getIndustryUid(formFieldValues.industry as string);

          const serverPayload = {
            client_account_owner: formFieldValues.csOwner ? formFieldValues.csOwner.name : 'Not Assigned',
            client_account_worth: formFieldValues.accountArr === '' ? 0 : formFieldValues.accountArr,
            client_comment: typeof formFieldValues.comment === 'undefined' ? '' : formFieldValues.comment,
            client_name: typeof formFieldValues.accountName === 'undefined' ? '' : formFieldValues.accountName.trim(),
            client_readiness_status: '',
            client_sales_owner: formFieldValues.salesOwner ? formFieldValues.salesOwner.name : 'Not Assigned',
            customer_uid: cookies.get('cogni_customer_uid') as string,
            industry_uid: typeof industryuid === 'undefined' ? '' : industryuid,
            sales_user_uid: typeof salesOwnerUserId === 'undefined' ? '' : salesOwnerUserId,
            segment_uid: typeof segmentUid === 'undefined' ? '' : segmentUid,
            stage_uid: typeof stageUid === 'undefined' ? '' : stageUid,
            user_uid: formFieldValues.csOwner ? formFieldValues.csOwner.id : '15243',
          };

          const addedclientuid = await dispatch(addAccountProfileToDraftInDb(serverPayload)).unwrap();
          if (formFieldValues.contactInfo.length && formFieldValues.contactInfo.length > 0) {
            const addContactDetails = {
              user_name: getLoggedInUserName(),
              primary_contact: 1,
              base_url: `https://${window.location.host}/customer-portal/${userDetail.user_company}/magic-link/`,
              contact_name: formFieldValues.contactInfo[0].name.trim(),
              contact_email: formFieldValues.contactInfo[0].email.trim(),
              contact_role: formFieldValues.contactInfo[0].role.trim(),
              customer_uid: cookies.get('cogni_customer_uid') as string,
              customer_company: userDetail.user_company,
              client_uid: addedclientuid.result.client_uid,
              user_email: userDetail.user_email,
              user_username: userDetail.user_username,
              last_magiclink_sent_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
            };
            console.log({ addContactDetails });
            await dispatch(addClientContact(addContactDetails));
          }
          dispatch(updateOnboardAccountProfile(formFieldValues));
          setOnbaordAccountProfileContactList(formFieldValues.contactInfo);
          setContactPrimaryIndex(formFieldValues.primaryContactIndex);

          const detail = {
            client_uid: addedclientuid?.result?.client_uid,
            field: 'Created',
            modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
            new_value: '',
            old_value: '',
            user_uid: getLoggedInUserUid(),
          };

          dispatch(addClientAuditTrail(detail));
          break;
        }

        case 'customFields': {
          attachmentQueueHelper(attachmentQueue);

          // check if all attachment are attached or not
          let weHaveAllAttachmentsInQueue = true;

          if (customFieldsFromServer.data.length > 0) {
            customFieldsFromServer.data.forEach((customField: any) => {
              if (customField.attachment == '1' && customField.mandatory == '1') {
                const foundAttachmentsArr = attachmentQueue.filter(
                  (attachment: any) => attachment.id == customField.field_uid
                );
                if (foundAttachmentsArr.length == 0) {
                  weHaveAllAttachmentsInQueue = false;
                }
              }
            });
          }

          // check if all mandatory fields have a value
          let weHaveAllMandatoryCustomFieldValues = true;
          if (customFieldsFromServer.data.length > 0) {
            customFieldsFromServer.data.forEach((customField: any) => {
              if (customField.mandatory == '1') {
                if (formFieldValues[customField.field_uid].toString().length == 0) {
                  weHaveAllMandatoryCustomFieldValues = false;
                }
              }
            });
          }

          if (weHaveAllMandatoryCustomFieldValues) {
            if (weHaveAllAttachmentsInQueue) {
              if (customFieldsFromServer.data.length > 0) {
                customFieldsFromServer.data.forEach((customField: any) => {
                  if (formFieldValues[customField.field_uid]) {
                    const updateClientCustomField_payload = {
                      client_uid: client_uid ? client_uid : '',
                      customer_uid: customField.customer_uid ? customField.customer_uid : '',
                      field_name: customField.field_name,
                      field_seq_num: customField.field_seq_num,
                      field_type: customField.field_type,
                      field_uid: customField.field_uid,
                      field_value:
                        customField.field_type === 'Date'
                          ? format(formFieldValues[customField.field_uid], 'yyyy-MM-dd HH:mm:ss')
                          : formFieldValues[customField.field_uid],
                    };
                    // add to server here
                    dispatch(updateClientCustomField_slice(updateClientCustomField_payload));
                  }
                });
              }

              setAlertDetails({ isVisible: true, type: 'success', message: 'Custom fields added successfully!' });
              handleCompleteThisStep(1);
              handleUnblockThisStep(2);
              setActiveStep(2);
            } else {
              setAlertDetails({ isVisible: true, type: 'failure', message: 'Upload All the mandatory Attachments!' });
            }
          } else {
            setAlertDetails({ isVisible: true, type: 'failure', message: 'Fill All the mandatory fields!' });
          }
          dispatch(updateOnboardCustomFields(formFieldValues));
          break;
        }

        case 'addProject':
          dispatch(activeOnboardProjectButtonStatus());

          if (formFieldValues?.importedTemplateProjectUid?.length > 0) {
            await importTemplate(formFieldValues);
            dispatch(deActivateOnboardProjectButtonStatus());
            return;
          } else {
            // new importing logic here

            projectServerPayload = {
              members: formFieldValues.members,
              template_project_uid: '',
              association_uid: associationUid,
              project_name: formFieldValues.projectName,
              project_fees: formFieldValues.implementationFeeInUSD,
              project_ARR: formFieldValues.projectARR == null ? 0 : formFieldValues.projectARR,
              project_description: formFieldValues.projectDescription,
              project_comment: formFieldValues.comment,
              project_reference: '',
              project_value: null,
              project_needed_status: formFieldValues.category,
              project_stage: formFieldValues.projectStage === '' ? 'Pre-Sales' : formFieldValues.projectStage,
              project_priority: formFieldValues.priority,
              project_redinessstate_status: 'green',
              project_expansionvalue: formFieldValues.expansionValue ? formFieldValues.expansionValue : 0,
              project_status_client: 'red',
              project_status_reason: `The ${label_due_date.toLowerCase()} of this ${label_project.toLowerCase()} has passed`,
              project_status_reason_client: `The ${label_due_date.toLowerCase()} of this ${label_project.toLowerCase()} has passed`,
              project_startdate: format(new Date(formFieldValues.actualStartDate), 'yyyy-MM-dd HH:mm:ss'),
              project_planned_startdate: format(new Date(formFieldValues.plannedStartDate), 'yyyy-MM-dd HH:mm:ss'),
              project_deliverydate: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
              project_created_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
              project_modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
              eta_forprojectdelivery: format(new Date(formFieldValues.etaForDeliveryDate), 'yyyy-MM-dd HH:mm:ss'),
              project_requiredbydate: format(new Date(formFieldValues.dueDate), 'yyyy-MM-dd HH:mm:ss'),
              project_deliverymanager: formFieldValues.deliveryManager
                ? formFieldValues.deliveryManager.name
                : 'Not Assigned',
              user_uid: formFieldValues.deliveryManager ? formFieldValues.deliveryManager.id : '15243',
              imported_template: formFieldValues.projectTemplate.length > 0 ? 1 : 0,
              project_rollout: 0,
              opportunity_id: null,
              last_user_modified: getLoggedInUserUid(),
              last_user_modified_name: getLoggedInUserName(),
            };

            console.log('formFieldValues', formFieldValues);
            console.log('projectServerPayload', projectServerPayload);

            const deliveryMangerAsAMemberForProject = {
              is_client_contact: '0',
              user_uid:
                formFieldValues.deliveryManager === 'Not Assigned' ? '15243' : formFieldValues.deliveryManager.id,
            };
            const members = [...formFieldValues.members, deliveryMangerAsAMemberForProject];

            dispatch(createProjectFromProjectTemplate(projectServerPayload))
              .unwrap()
              .then((res) => {
                const projectUid = res.result;
                const data: any = {
                  members,
                  association_uid: associationUid,
                  project_uid: projectUid,
                };
                const detail = {
                  status: 1,
                  clientUid: client_uid,
                };
                dispatch(addMembersForProject({ ...data }));
                dispatch(updateClientStatus(detail));
              });
            // new importing logic here
          }
          dispatch(deActivateOnboardProjectButtonStatus());

          break;
        default:
          dispatch(resetOnboardFomvalues());
      }
    } catch (error) {
      setAlertDetails({
        isVisible: true,
        type: 'failure',
        message: 'An Unhandled Exception Occured!',
      });
    }
  };

  useEffect(() => {
    if (newProjectData?.status == 'success') {
      navigate(`/projects/project/${associationUid}/${newProjectData.data}`, {
        state: { newlyAddedProjectUid: newProjectData.data },
      });
    } else if (newProjectData?.error) {
      setAlertDetails({
        isVisible: true,
        type: 'failure',
        message: 'An Unhandled Exception Occured!',
      });
      // console.error('Error = ', newProjectData?.error);
    }
  }, [newProjectData]);

  const handleCancel = () => {
    dispatch(resetOnboardFomvalues());
    navigate('/client-list');
  };

  const handleSkip = (nextActiveState: number) => {
    setActiveStep(nextActiveState);
  };

  // stepper icon
  const CognisaasStepIcon = (props: CognisaasStepIconProps) => {
    const { active, completed, className, index } = props;

    return (
      <div className={className}>
        {completed ? (
          <span className={Styles.OnBoardNewAccount__Stepper__stepIconCompleted}>
            <Check />
          </span>
        ) : (
          <span
            className={
              active
                ? Styles.OnBoardNewAccount__Stepper__stepIconActive
                : Styles.OnBoardNewAccount__Stepper__stepIconNormal
            }
          >
            {JSON.stringify(index + 1)}
          </span>
        )}
      </div>
    );
  };

  return (
    <Box
      component="div"
      className={
        mobileScreen ? Styles.OnBoardNewAccount__mainContainer__mobileScreen : Styles.OnBoardNewAccount__mainContainer
      }
    >
      {/* onboard heading */}
      <div className="OnBoardNewAccount__Header">
        <Typography variant="h6" gutterBottom component="div">
          Onboard New {label_client}
        </Typography>
      </div>

      {/* stepper */}
      <div className="OnBoardNewAccount__Stepper">
        <Stepper
          connector={
            <div style={{ flex: '1 1 auto' }}>
              <span></span>
            </div>
          }
          nonLinear
          activeStep={activeStep}
        >
          {steps.map((label, index) => (
            <Step className={Styles.OnBoardNewAccount__Stepper__eachStep} key={label} completed={completed[index]}>
              <StepButton color="inherit" onClick={handleStep(index)}>
                <StepLabel StepIconComponent={(props) => <CognisaasStepIcon {...props} index={index} />}>
                  {label} &nbsp;
                  <span className={Styles.OnBoardNewAccount__Stepper__optionalText}>{index > 0 ? 'optional' : ''}</span>
                </StepLabel>
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </div>

      {/* form components or actual form */}
      <div className="OnBoardNewAccount__formContainer">
        {activeStep === 0 ? <OnboardAccountProfile handleSave={handleSave} handleCancel={handleCancel} /> : ''}

        {activeStep === 1 ? (
          <OnboardCustomFields handleSkip={handleSkip} handleSave={handleSave} handleCancel={handleCancel} />
        ) : (
          ''
        )}

        {activeStep === 2 ? (
          <OnboardAddProjects handleSkip={handleSkip} handleSave={handleSave} handleCancel={handleCancel} />
        ) : (
          ''
        )}

        <CognisaasToast
          message={alertDetails.message}
          type={alertDetails.type}
          open={alertDetails.isVisible}
          onClose={() => {
            setAlertDetails({ isVisible: false, type: alertDetails.type, message: '' });
          }}
        />
      </div>
    </Box>
  );
};

export default OnboardNewAccountIndex;
