import { label_client } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { selectAllFilters } from 'store/modules/AllFilters/selector';
import { addFilterGroup } from 'store/modules/AllFilters/slice';
import {
  selectAllClientsByCustomerUid,
  selectClientContactsOfCompany,
  selectClientContactsOfCompanyStatus,
} from 'store/modules/Common/selector';
import { getAllClientContacts, getAllClientsByCustomerUidForClientContactReport } from 'store/modules/Common/slice';
import { requestState } from 'store/modules/types';
import { isUndefined } from 'utils/dataHelpers';
import { FilterableData } from 'utils/filteringFunctions';
export const useClientContact = () => {
  const dispatch = useDispatch();
  const [contactsClient, setcontactsClient] = useState<any>([]);
  const [filteredContactsClient, setfilteredContactsClient] = useState<any>([]);
  const allFilters = useAppSelector(selectAllFilters);
  const clientContacts = useAppSelector(selectClientContactsOfCompany);
  const clientContactsState = useAppSelector(selectClientContactsOfCompanyStatus);
  const allClientsByCustomerUid = useAppSelector(selectAllClientsByCustomerUid);

  useEffect(() => {
    dispatch(getAllClientContacts());
    dispatch(getAllClientsByCustomerUidForClientContactReport());
  }, []);
  useEffect(() => {
    if (!isUndefined(allFilters['clientContactReport'])) {
      const newFilteredData = new FilterableData(contactsClient);
      newFilteredData.bulkFilter(allFilters['clientContactReport'].appliedFilters);
      setfilteredContactsClient(newFilteredData.toArray());
    }
  }, [allFilters]);
  useEffect(() => {
    if (clientContactsState == requestState.success && allClientsByCustomerUid.status == requestState.success) {
      dispatch(
        addFilterGroup({
          idName: 'clientContactReport',
          appliedFilters: [
            {
              name: `${label_client} Name`,
              dataKey: 'client_name',
              isSelected: false,
              equalToValue: [],
              canBeCleared: true,
              availableOptions: allClientsByCustomerUid.data.map((item: any) => item.client_name),
            },
            {
              name: 'Contact Type',
              dataKey: 'primary_contact',
              isSelected: false,
              equalToValue: [],
              canBeCleared: true,
              availableOptions: ['Primary', 'Secondary'],
            },
          ],
        })
      );
      if (clientContacts.length !== 0 && allClientsByCustomerUid.data.length !== 0 && contactsClient.length === 0) {
        const temp: any = [];
        allClientsByCustomerUid.data.map((element: any) => {
          const filteredArray = clientContacts.filter((item: any) => item.client_uid === element.client_uid);
          console.log('fillll', filteredArray);
          filteredArray.map((filteredItem: any) => {
            temp.push({
              ...filteredItem,
              client_name: element.client_name,
              primary_contact:
                filteredArray[0].primary_contact === '1' || filteredArray[0].primary_contact === 1
                  ? 'Primary'
                  : 'Secondary',
            });
          });
        });
        if (temp.length > 0) {
          setcontactsClient(temp);
        }
      }
    }
  }, [clientContacts, clientContactsState, allClientsByCustomerUid]);

  return {
    contactsClient: filteredContactsClient,
    allFilters,
  };
};
