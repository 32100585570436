import styled from 'styled-components';


export const Container = styled.div`
    display: flex;
    flex-direction: column;
    font-family: 'Noto sans';
`;


export const ImageInput = styled.input`
display:none;
`;

export const Image = styled.img`
    width:100%;
    height:100%;
    object-fit: cover;
`;

export const ButtonLink = styled.span`
    color:#2D60F6;
    font-size: 14px;
    cursor: pointer;

    &:hover {
        text-decoration: underline; 
    }
`;

export const Box = styled.div`
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: #F3F3F3;;
    border:1px solid #E5E5E5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    overflow: hidden;
    cursor: pointer;
    font-style: 'Noto sans';
`;