import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isNull, isUndefined } from 'utils/dataHelpers';

interface StateProps {
  allFilters: {
    [idName: string]: {
      appliedFilters: {
        name: string;
        dataKey: string;
        isSelected: boolean;
        equalToValue: string[];
        availableOptions: string[];
        canBeCleared: boolean;
        groupName?: string;
      }[];
    };
  };
}

const initialState: StateProps = {
  allFilters: {
    defaultForStructureShowing: {
      appliedFilters: [
        {
          name: 'Owner',
          dataKey: 'client_sales_owner',
          isSelected: false,
          equalToValue: [],
          canBeCleared: true,
          availableOptions: ['Aman', 'Abhishek'],
        },
      ],
    },
    default2: {
      appliedFilters: [
        {
          name: 'Owner',
          dataKey: 'client_sales_owner',
          isSelected: false,
          equalToValue: [],
          canBeCleared: true,
          availableOptions: ['Aman', 'Abhishek'],
        },
      ],
    },
  },
};

const allFiltersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    addFilterGroup: (state: StateProps, action: PayloadAction<any>) => {
      let tempAllFilters: any = {};
      if (
        !isNull(localStorage.getItem('cognisaas_all_filters')) ||
        !isUndefined(localStorage.getItem('cognisaas_all_filters'))
      ) {
        // tempAllFilters = JSON.parse(localStorage.getItem('cognisaas_all_filters'));
        // localStorage.getItem('cognisaas_all_filters');
        tempAllFilters = JSON.parse(localStorage.getItem('cognisaas_all_filters') as string);
      }

      let newAllFilters = { ...tempAllFilters };
      if (isUndefined(newAllFilters[action.payload.idName])) {
        newAllFilters = {
          ...tempAllFilters,
          [action.payload.idName]: {
            appliedFilters: [...action.payload.appliedFilters],
          },
        };
      }

      // tempAllFilters = JSON.parse(localStorage.getItem('cognisaas_all_filters') as string);

      localStorage.setItem('cognisaas_all_filters', JSON.stringify(newAllFilters));
      state.allFilters = newAllFilters;

      // const newAllFilters = {
      //   ...state.allFilters,
      //   [action.payload.idName]: {
      //     appliedFilters: [...action.payload.appliedFilters],
      //   },
      // };
      // state.allFilters = newAllFilters;
    },
    updateFilter: (state: StateProps, action: PayloadAction<any>) => {
      /*
        payload = {
          idName : 'actionableInsightProjectsOnTrack',
          updatedFilter: {
            name: 'Owner',
            dataKey: 'client_sales_owner',
            isSelected: false,
            equalToValue: 'Abhishek Goyal',
          }
        }
      */

      // console.log(action.payload.updatedFilter, 'updated Fitter here');
      const tempAllFilters = JSON.parse(localStorage.getItem('cognisaas_all_filters') as string);

      // state.allFilters[action.payload.idName as keyof StateProps].appliedFilters;
      const newAppliedFilters = state.allFilters[action.payload.idName as keyof StateProps].appliedFilters.map(
        (eachFilter: any) => {
          if (
            eachFilter.name === action.payload.updatedFilter.name &&
            eachFilter.dataKey === action.payload.updatedFilter.dataKey
          ) {
            console.log('updated filter');
            return action.payload.updatedFilter;
          } else {
            return eachFilter;
          }
        }
      );

      tempAllFilters[action.payload.idName as keyof StateProps].appliedFilters = newAppliedFilters;
      localStorage.setItem('cognisaas_all_filters', JSON.stringify(tempAllFilters));

      state.allFilters[action.payload.idName as keyof StateProps].appliedFilters = newAppliedFilters;
      // console.log(newAppliedFilters);
    },
    updateFilterSelectedValue: (state: StateProps, action: PayloadAction<any>) => {
      /*
        payload = {
          idName: props.idName,
          selectedValue: value,
          filterName: filterName,
        }
      */

      console.log(action.payload, 'updated Fitter here');

      console.log('update triggered', action.payload);
      const tempAllFilters = JSON.parse(localStorage.getItem('cognisaas_all_filters') as string);

      // state.allFilters[action.payload.idName as keyof StateProps].appliedFilters;
      const newAppliedFilters = state.allFilters[action.payload.idName as keyof StateProps].appliedFilters.map(
        (eachFilter: any) => {
          if (eachFilter.name === action.payload.filterName && eachFilter.dataKey === action.payload.filterDataKey) {
            // console.log('updated filter');
            let tempEqualToValue: any[] = eachFilter.equalToValue;
            const indexOfSelectedValueInEqualToArray = tempEqualToValue.indexOf(action.payload.selectedValue);
            if (indexOfSelectedValueInEqualToArray !== -1) {
              // means that it is already selected, so we need to remove it
              // tempEqualToValue.splice(action.payload.selectedValue, 1);
              tempEqualToValue = [
                ...tempEqualToValue.slice(0, indexOfSelectedValueInEqualToArray),
                ...tempEqualToValue.slice(indexOfSelectedValueInEqualToArray + 1),
              ];
            } else {
              tempEqualToValue.push(action.payload.selectedValue);
            }

            // date range values are handled here
            if (
              !isUndefined(action.payload.filterName.split('$$')[1]) &&
              action.payload.filterName.split('$$')[1] === 'date-range-picker'
            ) {
              tempEqualToValue = [action.payload.selectedValue];
            }

            // number range handled here
            if (
              !isUndefined(action.payload.filterName.split('$$')[1]) &&
              action.payload.filterName.split('$$')[1] === 'number-range'
            ) {
              tempEqualToValue = [action.payload.selectedValue];
            }

            // text contains field
            if (
              !isUndefined(action.payload.filterName.split('$$')[1]) &&
              action.payload.filterName.split('$$')[1] === 'text-contains'
            ) {
              tempEqualToValue = [action.payload.selectedValue];
            }

            return { ...eachFilter, equalToValue: tempEqualToValue };
            // return action.payload.updatedFilter;
          } else {
            return eachFilter;
          }
        }
      );

      // console.log('update filter equalto value', action.payload, newAppliedFilters);

      tempAllFilters[action.payload.idName as keyof StateProps].appliedFilters = newAppliedFilters;
      localStorage.setItem('cognisaas_all_filters', JSON.stringify(tempAllFilters));

      state.allFilters[action.payload.idName as keyof StateProps].appliedFilters = [...newAppliedFilters];
      // console.log(newAppliedFilters);
    },
    resetFilterOfThisContext: (state: StateProps, action: PayloadAction<{ idName: string }>) => {
      const tempAllFilters = JSON.parse(localStorage.getItem('cognisaas_all_filters') as string);

      const newAppliedFilters = state.allFilters[action.payload.idName as keyof StateProps].appliedFilters.map(
        (eachFilter: any) => {
          return { ...eachFilter, equalToValue: [], isSelected: false };
        }
      );
      tempAllFilters[action.payload.idName as keyof StateProps].appliedFilters = newAppliedFilters;
      localStorage.setItem('cognisaas_all_filters', JSON.stringify(tempAllFilters));
      state.allFilters[action.payload.idName as keyof StateProps].appliedFilters = newAppliedFilters;
    },
    resetFilterAcrossAppAtStore: (state: StateProps) => {
      state.allFilters = initialState.allFilters;
    },
    deleteFilterGroup: (state: StateProps, action: PayloadAction<{ idName: string }>) => {
      let tempAllFilters: any = {};
      if (
        !isNull(localStorage.getItem('cognisaas_all_filters')) ||
        !isUndefined(localStorage.getItem('cognisaas_all_filters'))
      ) {
        // tempAllFilters = JSON.parse(localStorage.getItem('cognisaas_all_filters'));
        // localStorage.getItem('cognisaas_all_filters');
        tempAllFilters = JSON.parse(localStorage.getItem('cognisaas_all_filters') as string);
      }

      delete tempAllFilters[action.payload.idName];

      const newAllFilters = { ...tempAllFilters };

      // if (isUndefined(newAllFilters[action.payload.idName])) {
      //   newAllFilters =
      //    {
      //     ...tempAllFilters,
      //     [action.payload.idName]: {
      //       appliedFilters: [...action.payload.appliedFilters],
      //     },
      //   };
      // }

      // tempAllFilters = JSON.parse(localStorage.getItem('cognisaas_all_filters') as string);

      localStorage.setItem('cognisaas_all_filters', JSON.stringify(newAllFilters));
      state.allFilters = newAllFilters;

      // const newAllFilters = {
      //   ...state.allFilters,
      //   [action.payload.idName]: {
      //     appliedFilters: [...action.payload.appliedFilters],
      //   },
      // };
      // state.allFilters = newAllFilters;
    },
  },
});

const allFiltersReducer = allFiltersSlice.reducer;
export default allFiltersReducer;
export const {
  addFilterGroup,
  deleteFilterGroup,
  updateFilter,
  updateFilterSelectedValue,
  resetFilterOfThisContext,
  resetFilterAcrossAppAtStore,
} = allFiltersSlice.actions;
