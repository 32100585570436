import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Box, Grid, Paper, Typography, Menu, MenuItem, IconButton, Stack } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { deleteTemplateDocument, getTemplateDocumentByCustomerUid } from 'store/modules/Templates/Templates';
import { getCustomerUid, getLoggedInUserName, getLoggedInUserUid } from 'utils/getUid';
import FileIcon from './FileIcon';
import DeleteModal from 'components/shared/CognisaasModal/DeleteModal';
import BasicModal from 'components/shared/CognisaasModal/CognisaasModal';
import Attachment from 'components/shared/Attachment/Attachment';
import { attachmentKeyGenerator } from 'components/shared/Attachment/attachmentHelpers';
import { downloadAttachmentFromServer } from 'store/modules/Attachment/slice';
import { GET_DOCUMENT_TEMPLATE_ATTACHMENT_URL } from './constants';
import VersionTable from './Version';
import { useAppDispatch } from 'store/hooks';
import CognisaasToast from 'components/shared/CognisaasToast/CognisaasToast';

interface documentTemplatePropType {
  templateDetail: any;
}

export default function DocumentTemplate(props: documentTemplatePropType) {
  const { templateDetail } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const customerUid = getCustomerUid();
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openUploadNewVersionDrawer, setOpenUploadNewVersionDrawer] = useState<boolean>(false);
  const [openVersionHistoryTable, setOpenVersionHistoryTable] = useState<boolean>(false);
  const selectedAttachmentDetails = props.templateDetail;
  const appDispatch = useAppDispatch();
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [alertDetails, setAlertDetails] = useState<{
    isVisible: boolean;
    type: 'success' | 'failure';
    message: string;
  }>({
    isVisible: false,
    type: 'success',
    message: '',
  });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleDeleteClick = async () => {
    await dispatch(
      deleteTemplateDocument({
        template_document_uid: templateDetail.template_document_uid,
      })
    );
    await dispatch(getTemplateDocumentByCustomerUid(customerUid));
  };

  const attachmentCallback = async (actionPerformed: string) => {
    if (actionPerformed === 'canceled-bulk-attachments') {
      setOpenUploadNewVersionDrawer(false);
    } else if (actionPerformed === 'uploaded-bulk-attachments') {
      setOpenUploadNewVersionDrawer(false);
      await dispatch(getTemplateDocumentByCustomerUid(customerUid));
    }
  };

  const getDocumentUploadNewVersionModal = () => {
    return (
      <BasicModal
        height={280}
        width={350}
        open={openUploadNewVersionDrawer}
        onClose={() => setOpenUploadNewVersionDrawer(false)}
      >
        {templateDetail.template_document_uid !== '' && (
          <Attachment
            variant="bulk-upload"
            type="normal"
            metaDataForHeaders={{
              template_document_uid: templateDetail.template_document_uid,
              customer_uid: getCustomerUid(),
              current_version_uploaded_by_user_uid: getLoggedInUserUid(),
              current_version_uploaded_by_user_name: getLoggedInUserName(),
              key: attachmentKeyGenerator(getCustomerUid(), templateDetail.template_document_uid),
            }}
            apiUrlForAttachmentUpload={'updateTemplateDocumentAttachment'}
            // setModalIndex={setModalIndex}
            callbackOnWhatIsDoneWithAttachments={(actionPerformed: any) => attachmentCallback(actionPerformed)}
            setAlertDetails={setAlertDetails}
            acceptFile={'single'}
          />
        )}
      </BasicModal>
    );
  };

  const getVersionModal = () => {
    return (
      <BasicModal
        onClose={() => setOpenVersionHistoryTable(false)}
        open={openVersionHistoryTable}
        width={1000}
        height={400}
      >
        <VersionTable attachmentDetail={selectedAttachmentDetails} />
      </BasicModal>
    );
  };

  const getAlertsModal = () => {
    return (
      <CognisaasToast
        open={alertDetails.isVisible}
        onClose={() => setAlertDetails({ isVisible: false, type: alertDetails.type, message: '' })}
        message={alertDetails.message}
        type={alertDetails.type}
      />
    );
  };

  const implementDownload = async () => {
    let downloadableLink = '';
    await appDispatch(
      downloadAttachmentFromServer({
        serverGetApiUrlWithQueryString: `${GET_DOCUMENT_TEMPLATE_ATTACHMENT_URL}?template_attachment_uid=${templateDetail.template_attachment_uid}`,
        attachmentUid: templateDetail.template_attachment_uid,
      })
    ).then((res: any) => {
      downloadableLink = res.payload.data.url;
    });

    const link: any = document.createElement('a');
    link.download = name;
    link.href = downloadableLink;
    link.click();
  };

  return (
    <>
      <Box sx={{ marginTop: '10px', marginRight: '10px' }}>
        <Paper sx={{ width: '310px', height: '68px' }}>
          <Grid container className="cognisaas-pointer" sx={{ padding: '10px 12px' }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                {templateDetail.name !== null && (
                  <Box component="span" sx={{ marginRight: '16px' }}>
                    {/* show file icon here */}
                    <FileIcon fileType={templateDetail.name.split('.')[1]} />
                  </Box>
                )}
                <Typography>{templateDetail.template_name}</Typography>
              </Stack>
              <IconButton disableRipple onClick={handleClick}>
                <MoreVertIcon />
              </IconButton>
            </Stack>
            <Grid item>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                style={{ padding: '0px' }}
              >
                <MenuItem
                  onClick={() => {
                    implementDownload();
                  }}
                >
                  <Typography variant="inherit">Download Attachment</Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setOpenUploadNewVersionDrawer(true);
                  }}
                >
                  <Typography variant="inherit">Upload New Version</Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setOpenVersionHistoryTable(true);
                  }}
                >
                  <Typography variant="inherit">Version history</Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setOpenDeleteModal(true);
                  }}
                >
                  <Typography variant="inherit">Delete</Typography>
                </MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </Paper>
      </Box>
      {getDocumentUploadNewVersionModal()}
      {getVersionModal()}
      {getAlertsModal()}
      <DeleteModal
        isOpen={openDeleteModal}
        setIsOpen={setOpenDeleteModal}
        message={'Are you sure you want to delete this document template'}
        onDelete={handleDeleteClick}
      />
    </>
  );
}
