import { Container, Title, Group } from './CustomiseDrawer.styles';
import { graphSchema } from './types';

import {
  LeftBar,
  RightBar,
  TopBar,
  BottomBar,
  BottomTrendlineBar,
  TopTrendlineBar,
  LeftTrendlineBar,
  RightTrendlineBar,
  TopHorizontalBar,
  LeftHorizontalBar,
  BottomHorizontalBar,
  RightHorizontalBar,
  TopHTrendlineBar,
  LeftHTrendlineBar,
  RightHTrendlineBar,
  BottomHTrendlineBar,
} from '../../../../assets/svgs/bar';
import { LeftDonut, RightDonut, TopDonut, BottomDonut } from '../../../../assets/svgs/donut';
import { selectAllChartsConfig } from '../../../../store/modules/Charts/selector';
import { useAppSelector } from '../../../../store/hooks';
import { LeftLineChart, RightLineChart, TopLineChart, BottomLineChart } from '../../../../assets/svgs/line';

interface previewProps {
  graphInfo?: graphSchema[];
  idName: string;
}

const PreviewPage = (props: previewProps) => {
  const graphConfigs = useAppSelector(selectAllChartsConfig);

  const graphs = graphConfigs.filter((graph) => graph.idName == props.idName);
  console.log('INSIDE PREVIEW');
  console.log(props.idName);
  console.log('aman', graphs);
  const Graph = (props: any) => {
    const { graph } = props;
    switch (graph.labelPosition) {
      case 'left':
        if (graph.chartType === 'Bar Chart') {
          if (graph.layout === 'horizontal') {
            if (graph.trendLine === true) {
              return <LeftHTrendlineBar />;
            }
            return <LeftHorizontalBar />;
          }
          if (graph.trendLine === true) {
            return <LeftTrendlineBar />;
          }
          return <LeftBar />;
        } else if (graph.chartType === 'Donut Chart') {
          return <LeftDonut />;
        } else if (graph.chartType === 'Line Chart') {
          return <LeftLineChart />;
        } else {
          return <></>;
        }
      case 'right':
        if (graph.chartType === 'Bar Chart') {
          if (graph.layout === 'horizontal') {
            if (graph.trendLine === true) {
              return <RightHTrendlineBar />;
            }
            return <RightHorizontalBar />;
          }
          if (graph.trendLine === true) {
            return <RightTrendlineBar />;
          }
          return <RightBar />;
        } else if (graph.chartType === 'Donut Chart') {
          return <RightDonut />;
        } else if (graph.chartType === 'Line Chart') {
          return <RightLineChart />;
        } else {
          return <></>;
        }
      case 'top':
        if (graph.chartType === 'Bar Chart') {
          if (graph.layout === 'horizontal') {
            if (graph.trendLine === true) {
              return <TopHTrendlineBar />;
            }
            return <TopHorizontalBar />;
          }
          if (graph.trendLine === true) {
            return <TopTrendlineBar />;
          }
          return <TopBar />;
        } else if (graph.chartType === 'Donut Chart') {
          return <TopDonut />;
        } else if (graph.chartType === 'Line Chart') {
          return <TopLineChart />;
        } else {
          return <></>;
        }
      case 'bottom':
        if (graph.chartType === 'Bar Chart') {
          if (graph.layout === 'horizontal') {
            if (graph.trendLine === true) {
              return <BottomHTrendlineBar />;
            }
            return <BottomHorizontalBar />;
          }
          if (graph.trendLine === true) {
            return <BottomTrendlineBar />;
          }
          return <BottomBar />;
        } else if (graph.chartType === 'Donut Chart') {
          return <BottomDonut />;
        } else if (graph.chartType === 'Line Chart') {
          return <BottomLineChart />;
        } else {
          return <></>;
        }
      default:
        return <></>;
    }
  };

  return (
    <Container style={{ position: 'fixed', top: '0', bottom: '0', right: '450px' }}>
      <Title>Preview Page</Title>
      {graphs?.map((graph: any, index: number) => (
        <Group style={{ marginTop: '15px' }} key={index}>
          <Graph graph={graph} />
        </Group>
      ))}
      {props.graphInfo?.map((graph, index) => (
        <Group style={{ marginTop: '15px' }} key={index}>
          <Graph graph={graph} />
        </Group>
      ))}
    </Container>
  );
};

export default PreviewPage;
