import { useState, useEffect } from 'react';
import { fetchClientList } from '../../../store/modules/ManageClient/ClientList/slice';
import { selectClientList } from '../../../store/modules/ManageClient/ClientList/selector';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../store/hooks';

const useClientList = () => {
  const dispatch = useDispatch();
  const clientListState = useAppSelector(selectClientList);
  const [clientList, setClientList] = useState<any>(null);

  useEffect(() => {
    dispatch(fetchClientList());
  }, []);

  useEffect(() => {
    setClientList([...clientListState]);
  }, [clientListState]);

  return clientList;
};

export default useClientList;
