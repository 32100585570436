import React, { useEffect, useState } from 'react';

import PictureUpload from 'components/shared/PictureUpload/PictureUpload';
import { Container } from './Preferences.styles';
import {
  Stack,
  Typography,
  IconButton,
  Box,
  Divider,
  FormControlLabel,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';

import CognisaasSwitchButton from 'components/shared/CognisaasSwitch/CognisaasSwitch';
import CognisaaasTooltip from 'components/shared/CognisaasTooltip/CognisaasTooltip';
import CognisaasToast from 'components/shared/CognisaasToast/CognisaasToast';

import { isRbacEnabled, isSuperUser } from '../../../utils/getUid';
import Cookies from 'universal-cookie';

import { ReactComponent as Info } from '../../../assets/svgs/info.svg';

const cookies = new Cookies();
const customer_uid: string = cookies.get('cogni_customer_uid') ? cookies.get('cogni_customer_uid') : '';

import RBACPreferences from './RBACPreferences';
import CogniTable, { EditableTextCell } from 'components/shared/Tables/CogniTable';
import { useDispatch } from 'react-redux';
import { fetchCustomLabel } from 'store/modules/Common/slice';
import { useAppSelector } from 'store/hooks';
import { selectAllCustomLabels } from 'store/modules/Common/selector';
import {
  getCustomerDetails,
  resetCustomLabelvalueUpdation,
  resetRevenueReportBasis,
  updateCustomLabelValue,
  updateRevenueReportBasis,
  enableMandatorySSO,
  disableMandatorySSO,
} from 'store/modules/Settings/slice';
import {
  selectCustomerDetails,
  updatingCustomLabelValue,
  selectRevenueReportBasis,
} from 'store/modules/Settings/selector';
import {
  label_arr,
  label_client,
  makeLabelCookies,
  makeTableData,
  refreshLabelCookies,
  label_project,
} from './CustomLabelCookieHelper';
import { requestState } from 'store/modules/types';
import CognisaasCheckBox from 'components/shared/CognisaasCheckbox/CognisaasCheckbox';
import { updateArrVisibleOnClientList } from 'store/modules/ManageClient/ClientList/slice';
import { UpdateCustomLabelValue } from 'store/modules/Settings/types';

const Preferences = () => {
  const dispatch = useDispatch();
  const [rbacStatus, setRbacStatus] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [switchState, setSwitchState] = useState<boolean>(isRbacEnabled() as boolean); // RBACPreferences uses this state to render  relevant modal content
  const [alertDetails, setAlertDetails] = useState<{
    isVisible: boolean;
    type: 'success' | 'failure';
    message: string;
  }>({ isVisible: false, type: 'success', message: '' });
  const allCustomLabelsData = useAppSelector(selectAllCustomLabels);
  const [customLabelsDataForTable, setCustomLabelsDataForTable] = useState<any[]>([]);
  const updatingLabelValue = useAppSelector(updatingCustomLabelValue);
  const updatingRevenueReportBasis = useAppSelector(selectRevenueReportBasis);
  const [arrVisibleOnClientList, setArrVisibleOnClientList] = useState<boolean>(false);
  const [SSOMandatoryEnabled, setSSOMandatoryEnabled] = useState<boolean>(false);
  const customerDetails = useAppSelector(selectCustomerDetails);

  const [revenueReportBasis, setrevenueReportBasis] = useState<string>('Implementation Fee');

  const handleChangeRevenueReportBasis = async (event: SelectChangeEvent) => {
    setrevenueReportBasis(event.target.value as string);
    if (event.target.value == 'Implementation Fee') {
      await dispatch(updateRevenueReportBasis({ customer_uid: customer_uid, revenue_report_basis: 0 }));
      dispatch(getCustomerDetails());
    } else if (event.target.value == 'ARR') {
      await dispatch(updateRevenueReportBasis({ customer_uid: customer_uid, revenue_report_basis: 1 }));
      dispatch(getCustomerDetails());
    }
  };

  useEffect(() => {
    dispatch(fetchCustomLabel());
  }, []);

  useEffect(() => {
    if (allCustomLabelsData) {
      if (allCustomLabelsData?.data?.data?.status == '0') {
        setAlertDetails({ isVisible: true, type: 'failure', message: 'Unable To Get Custom labels!' });
      } else if (allCustomLabelsData?.data?.data?.status == '1') {
        setCustomLabelsDataForTable(makeTableData(allCustomLabelsData.data.data.result));
        makeLabelCookies(allCustomLabelsData.data.data.result);
        refreshLabelCookies();
      }
    }
  }, [allCustomLabelsData]);

  // for handling if we update some field is it success or not
  useEffect(() => {
    if (updatingLabelValue.status === requestState.success) {
      setAlertDetails({ isVisible: true, type: 'success', message: 'Updated Successfully!' });
      dispatch(fetchCustomLabel());
      setTimeout(() => {
        dispatch(resetCustomLabelvalueUpdation());
      }, 1000);
    } else if (updatingLabelValue.status === requestState.failed) {
      setAlertDetails({ isVisible: true, type: 'success', message: 'Updating Custom Label Failed!' });
    }
  }, [updatingLabelValue]);

  useEffect(() => {
    if (updatingRevenueReportBasis.status === requestState.success) {
      setAlertDetails({ isVisible: true, type: 'success', message: 'Updated Successfully!' });
      setTimeout(() => {
        dispatch(resetRevenueReportBasis());
      }, 1000);
    } else if (updatingRevenueReportBasis.status === requestState.failed) {
      setAlertDetails({ isVisible: true, type: 'success', message: 'Updating Revenue Report Basis Failed!' });
    }
  }, [updatingRevenueReportBasis]);

  useEffect(() => {
    if (customerDetails.status === requestState.idle || customerDetails.status === requestState.failed) {
      dispatch(getCustomerDetails());
    } else if (customerDetails.status === requestState.success) {
      setArrVisibleOnClientList(customerDetails.data.arr_on_client_list_visible === '1' ? true : false);
      setSSOMandatoryEnabled(customerDetails.data.enforce_mandatory_sso);
      setrevenueReportBasis(customerDetails.data.revenue_report_basis == '0' ? 'Implementation Fee' : 'ARR');
    }
  }, [customerDetails]);

  const handleModalOpen = () => setOpenModal(true);
  const handleModalClose = (disableClosingModal?: boolean) => {
    if (!disableClosingModal) {
      setSwitchState((prevState) => !prevState);
      setOpenModal(false);
    }
  };

  const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleModalOpen();
    setSwitchState(e.target.checked);
  };

  const closeToast = () => {
    setRbacStatus(false);
  };

  // for custom label value update
  const handleFieldLabelUpdate = async (rowData: Record<string, string>) => {
    dispatch(
      updateCustomLabelValue({
        customer_uid: customer_uid,
        standard_value: rowData.standard_value,
        custom_value: rowData.custom_value.trim(),
        entity: rowData.entity,
      } as UpdateCustomLabelValue)
    );
  };

  const handleAggregationChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const nextState = e.target.checked;
    setArrVisibleOnClientList(nextState);
    await dispatch(
      updateArrVisibleOnClientList({ customer_uid: customer_uid, arr_on_client_list_visible: nextState ? '1' : '0' })
    );
    await dispatch(getCustomerDetails());
    setAlertDetails({ isVisible: true, type: 'success', message: 'Updated Successfully!' });
  };

  const handleSSOmandatoryChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const nextState = e.target.checked;
    setSSOMandatoryEnabled(nextState);
    await dispatch(nextState ? enableMandatorySSO() : disableMandatorySSO());
    await dispatch(getCustomerDetails());
    setAlertDetails({ isVisible: true, type: 'success', message: 'Updated Successfully!' });
  };

  return (
    <>
      {rbacStatus ? (
        <CognisaasToast open={rbacStatus} onClose={closeToast} message={'Updated successfully! '} type="success" />
      ) : null}
      <Container>
        <Typography variant="h6">Company Preferences</Typography>
        <PictureUpload
          customer_uid={cookies.get('cogni_customer_uid') as string}
          type="customer"
          setAlertDetails={setAlertDetails}
        />
        {isSuperUser() && (
          <Stack mt={2}>
            <Stack direction="row">
              <Typography variant="h6">Role based access control</Typography>
              <CognisaaasTooltip
                title={
                  <Box sx={{ padding: '12px' }}>
                    <Typography>
                      If enabled, <b>role based access control</b> to various pages and entities can be done in the
                      &apos;User Management&apos; page.
                    </Typography>
                  </Box>
                }
              >
                <IconButton disableRipple size="small">
                  <Info />
                </IconButton>
              </CognisaaasTooltip>
            </Stack>
            <Stack mt={1}>
              <div style={{ width: '15px' }}>
                <CognisaasSwitchButton disabled={false} checked={switchState} handleChange={handleSwitchChange} />
              </div>
              <RBACPreferences
                openModal={openModal}
                handleModalClose={handleModalClose}
                switchState={switchState}
                setSwitchState={setSwitchState}
              />
            </Stack>
          </Stack>
        )}

        <Divider sx={{ margin: '24px 0px' }} />

        <Stack>
          <Typography variant="h6">Login Configurations </Typography>
        </Stack>

        <FormControlLabel
          sx={{ margin: '0px' }}
          control={
            <CognisaasCheckBox checked={SSOMandatoryEnabled} onCheckBoxClick={handleSSOmandatoryChange} size="small" />
          }
          label={
            <Typography variant={'subtitle1'} sx={{ marginLeft: '5px' }}>
              Set SSO mandatory to login
            </Typography>
          }
        />

        <Divider sx={{ margin: '24px 0px' }} />

        <Stack>
          <Typography variant="h6">Configure aggregations </Typography>
        </Stack>

        <FormControlLabel
          sx={{ margin: '0px' }}
          control={
            <CognisaasCheckBox
              checked={arrVisibleOnClientList}
              onCheckBoxClick={handleAggregationChange}
              size="small"
            />
          }
          label={
            <Typography variant={'subtitle1'} sx={{ marginLeft: '5px' }}>
              Display total affected {label_arr} on {label_client.toLowerCase()}s page
            </Typography>
          }
        />
        <Stack sx={{ mt: '24px' }}>
          <Typography variant="h6">Reports configurations </Typography>
        </Stack>
        <Stack direction={'row'}>
          <Typography variant="subtitle1">Revenue reports should be based on</Typography>
          <Box>
            <FormControl fullWidth>
              <Select
                sx={{ height: '20px', ml: '10px', mt: '3px' }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={revenueReportBasis}
                onChange={handleChangeRevenueReportBasis}
              >
                <MenuItem value={'Implementation Fee'}>
                  <Typography variant="subtitle1">Implementation Fee</Typography>
                </MenuItem>
                <MenuItem value={'ARR'}>
                  <Typography variant="subtitle1">
                    {label_project} {label_arr}
                  </Typography>
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Stack>
        <Divider sx={{ margin: '24px 0px' }} />

        {/* Customize labels ui  */}
        <Stack>
          <Typography variant="h6">Customise labels for fields in client, project, use case and task record</Typography>
        </Stack>

        <Stack>
          <CogniTable
            hideFooter={true}
            hideHeader={true}
            columns={[
              {
                field: 'field_uid',
                hide: true,
              },
              {
                field: 'standard_value',
                headerName: 'Standard Labels',
                minWidth: 200,
                flex: 1,
                renderCell: (params: any) => `${params.value} (${params.row.entity})`,
              },
              {
                field: 'custom_value',
                headerName: 'Custom Labels',
                minWidth: 140,
                flex: 1,
                editable: true,
                renderEditCell: (params) => EditableTextCell(params, handleFieldLabelUpdate),
              },
            ]}
            pageSize={11}
            data={customLabelsDataForTable}
          />
        </Stack>
      </Container>
      {/* alerts */}
      <CognisaasToast
        message={alertDetails.message}
        type={alertDetails.type}
        open={alertDetails.isVisible}
        onClose={() => setAlertDetails({ isVisible: false, type: alertDetails.type, message: '' })}
      />
    </>
  );
};

export default Preferences;
