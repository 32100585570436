import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { Box, Grid, Stack, Typography } from '@mui/material';
import Cookies from 'universal-cookie';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { selectUsecaseTemplates, selectUsecaseTemplatesStatus } from 'store/modules/Templates/selector';
import { getUseCaseTemplatesbycustomer } from 'store/modules/Templates/Templates';
import CognisaasMultiSelectWithSearchUid from 'components/shared/CognisaasMultiSelectWithSearch/CognisaasMultiSelectWithSearchUid';
import { selectCreatingUsecaseFromUsecaseTemplateInBulk } from 'store/modules/ManageClient/OnboardFormValues/selector';
import { createUsecaseFromUsecaseTemplateInBulk } from 'store/modules/ManageClient/OnboardFormValues/slice';
import { getProjectInformation } from 'store/modules/Project/slice';
import { label_use_case } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import CognisaasButtonWithLoading from 'components/shared/CognisaasButton/CognisaasButtonWithLoading';
const cookies = new Cookies();
const customer_uid = cookies.get('cogni_customer_uid') as string;
const cogni_user_uid = cookies.get('cogni_user_uid') as string;

function PaperComponent(props: PaperProps) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

export default function AddMultipleUsecase(props: any) {

  const dispatch = useDispatch();
  const usecaseTemplateDetails = useAppSelector(selectUsecaseTemplates);
  const usecaseTemplateDetailsStatus = useAppSelector(selectUsecaseTemplatesStatus);
  // api to create usecase in bulk
  const createUsecaseInBulk = useAppSelector(selectCreatingUsecaseFromUsecaseTemplateInBulk);
  const [selectedUsecase, setSelectedUsecase] = useState<any[]>([]);
  const [usecaseTemplateListForDropDown, setUsecaseTemplateListForDropDown] = useState<any[]>([]);
  // loder for save button
  const [isLoading, setIsLoading] = useState<boolean>(false);

  //   initial
  useEffect(() => {
    // getting all the use case templates uid and their names
    dispatch(getUseCaseTemplatesbycustomer());
  }, []);

  useEffect(() => {
    if (usecaseTemplateDetailsStatus == 'success') {
      const dropDownData: any = [];
      for (let j = 0; j < usecaseTemplateDetails.length; j++) {
        const data: any = {};
        data.id = usecaseTemplateDetails[j].template_case_uid;
        data.name = usecaseTemplateDetails[j].template_case_name;
        dropDownData.push(data);
      }
      setUsecaseTemplateListForDropDown([...dropDownData]);
    }
  }, [usecaseTemplateDetailsStatus]);

  const handleClickOpen = () => {
    props.setOpen(true);
  };

  const handleClose = () => {
    // setting the selected usecase array as empty
    setSelectedUsecase([]);
    props.setOpen(false);
  };

  const handleAddUsecase = () => {
    // adding usecase one by one
    const payload: any = {
      project_uid: props.project_uid,
      association_uid: props.association_uid,
      client_uid: props.client_uid,
      customer_uid: customer_uid,
      selectedUsecaseTemplateUid: selectedUsecase,
      last_user_modified: cogni_user_uid,
      delivery_manager:props.project_deliverymanager
    };
    dispatch(createUsecaseFromUsecaseTemplateInBulk(payload));
    setIsLoading(true);
  };

  useEffect(() => {
    if (createUsecaseInBulk.status == 'success') {
      const detail = {
        project_uid: props.project_uid,
        association_uid: props.association_uid,
      };
      dispatch(getProjectInformation(detail));
      setIsLoading(false);
      handleClose();
    }
  }, [createUsecaseInBulk.status]);

  return (
    <div>
      <Button variant="contained" onClick={handleClickOpen} sx={{ marginLeft: '5px' }}>
        Add Multiple {label_use_case}s
      </Button>
      {usecaseTemplateDetails.length > 0 && (
        <Dialog
          sx={{ zIndex: 1202 }}
          open={props.open}
          onClose={handleClose}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
            Add Multiple {label_use_case}s
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Box>
                <Grid container>
                  <Stack spacing={3} direction="column" sx={{ width: '350px' }}>
                    <Stack>
                      <Typography variant="subtitle2">{label_use_case} Template name</Typography>
                      <CognisaasMultiSelectWithSearchUid
                        shouldBeOpenByDefault={false}
                        allOptions={usecaseTemplateListForDropDown}
                        selectedOption={selectedUsecase}
                        setSelectedOptions={setSelectedUsecase}
                      />
                    </Stack>
                  </Stack>
                </Grid>
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" autoFocus onClick={handleClose}>
              Cancel
            </Button>
            <CognisaasButtonWithLoading
              isLoading={isLoading}
              disabled={selectedUsecase.length > 0 ? false : true}
              label={`Add ${label_use_case}s`}
              isOutlined={false}
              clickHandler={() => {
                handleAddUsecase();
              }}
            />
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
