import {
  label_arr,
  label_delivery_date,
  label_due_date,
  label_project,
  label_task,
  label_use_case,
} from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { ChartYAxisConfig, OptionsMappingObj } from './types';

export const CF_TYPE_DATE = 'Date';
export const CF_TYPE_NUMBER = 'Number';
export const CF_TYPE_DROPDOWN = 'Dropdown';
export const CF_TYPE_RAG = 'RAG status';

export const timeOptionsMapping: OptionsMappingObj[] = [
  {
    name: `${label_project} Planned Start Date`,
    key: 'project_planned_startdate',
    type: 'project',
  },
  {
    name: `${label_project} Actual Start Date`,
    key: 'project_startdate',
    type: 'project',
  },
  {
    name: `${label_project} ${label_due_date}`,
    key: 'project_requiredbydate',
    type: 'project',
  },
  {
    name: `${label_project} ETA for Delivery`,
    key: 'eta_forprojectdelivery',
    type: 'project',
  },
  {
    name: `${label_project} ${label_delivery_date}`,
    key: 'project_deliverydate',
    type: 'project',
  },
  // uc
  {
    name: `${label_use_case} Planned Start Date`,
    key: 'case_planned_startdate',
    type: 'usecase',
  },
  {
    name: `${label_use_case} Actual Start Date`,
    key: 'case_startdate',
    type: 'usecase',
  },
  {
    name: `${label_use_case} ${label_due_date}`,
    key: 'case_requiredbydate',
    type: 'usecase',
  },
  {
    name: `${label_use_case} ETA for Delivery`,
    key: 'eta_forcasedelivery',
    type: 'usecase',
  },
  {
    name: `${label_use_case} ${label_delivery_date}`,
    key: 'case_deliverydate',
    type: 'usecase',
  },
  // task
  {
    name: `${label_task} Planned Start Date`,
    key: 'task_planned_start_date',
    type: 'task',
  },
  {
    name: `${label_task} Actual Start Date`,
    key: 'task_start_date',
    type: 'task',
  },
  {
    name: `${label_task} ${label_due_date}`,
    key: 'task_due_date',
    type: 'task',
  },
  {
    name: `${label_task} ${label_delivery_date}`,
    key: 'task_closed_date',
    type: 'task',
  },
];

export const statusOptionMapping: OptionsMappingObj[] = [
  {
    name: 'Overdue',
    key: 'red',
    type: 'any',
  },
  {
    name: 'At Risk',
    key: 'amber',
    type: 'any',
  },
  {
    name: 'On Track',
    key: 'green',
    type: 'any',
  },
  {
    name: 'Completed',
    key: 'blue',
    type: 'any',
  },
];

export const yaxisValues: ChartYAxisConfig[] = [
  {
    name: `${label_project} Count`,
    key: 'Data Count',
    type: 'project',
    basis: 'number',
  },
  {
    name: `${label_project} ${label_arr}`,
    key: 'project_ARR',
    type: 'project',
    basis: 'number',
  },
  {
    name: `${label_project} Expansion Value`,
    key: 'project_expansionvalue',
    type: 'project',
    basis: 'number',
  },
  {
    name: `${label_project} Ageing`,
    key: 'project_age',
    type: 'project',
    basis: 'number',
  },
  {
    name: `${label_project} implementation fee billed`,
    key: 'implementation_fee_billed',
    type: 'project',
    basis: 'number',
  },
  {
    name: `${label_project} implementation fee pending`,
    key: 'implementation_fee_pending',
    type: 'project',
    basis: 'number',
  },
  {
    name: 'Revenue Impact',
    key: 'client_account_worth',
    type: 'project',
    basis: 'number',
  },
  {
    name: `${label_use_case} Count`,
    key: 'Data Count',
    type: 'usecase',
    basis: 'number',
  },
  {
    name: `${label_use_case} Expansion Value`,
    key: 'case_expansionvalue',
    type: 'usecase',
    basis: 'number',
  },
  {
    name: 'Revenue Impact',
    key: 'client_account_worth',
    type: 'usecase',
    basis: 'number',
  },
  {
    name: `${label_task} Count`,
    key: 'Data Count',
    type: 'task',
    basis: 'number',
  },
];

export const TIME_OPTION_VALUES_FOR_CHART = ['Year', 'Quarter', 'Month', 'Week'];

export const TASK_STAGES = ['Not Started', 'Blocked', 'In Progress', 'Deferred', 'Done'];

export const KEYS_REPRESENTING_REVENUE = ['project_ARR', 'client_account_worth'];
