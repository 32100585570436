import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../index';

const selectRBACDataState = (state: RootState) => state.RBACState;

export const getUserAndRoles_selector = createSelector(selectRBACDataState, (rbacSlice) => {
  return rbacSlice.usersAndRoles;
});

export const getResourcesField_selector = createSelector(selectRBACDataState, (rbacSlice) => {
  return rbacSlice.resourcesFields;
});

export const getResourcesFieldValues_selector = createSelector(selectRBACDataState, (rbacSlice) => {
  return rbacSlice.resourcesFieldsValues;
});

export const getAllPermissions_selector = createSelector(selectRBACDataState, (rbacSlice) => {
  return rbacSlice.allPermissions;
});

export const getValidReportees_selector = createSelector(selectRBACDataState, (rbacSlice) => {
  return rbacSlice.validReporteeAdditions;
});

export const getPolicyOnResources_selector = createSelector(selectRBACDataState, (rbacSlice) => {
  return rbacSlice.policyOnResources;
});

export const getAllResources_selector = createSelector(selectRBACDataState, (rbacSlice) => {
  return rbacSlice.allResources;
});

export const getGeneralFieldValues_selector = createSelector(selectRBACDataState, (rbacSlice) => {
  return rbacSlice.generalFieldsValues;
});

export const getAllPages_selector = createSelector(selectRBACDataState, (rbacSlice) => {
  return rbacSlice.allPages;
});

export const getAllRoles_selector = createSelector(selectRBACDataState, (rbacSlice) => {
  return rbacSlice.allRoles;
});

export const getRole_selector = createSelector(selectRBACDataState, (rbacSlice) => {
  return rbacSlice.roleDetails;
});

export const getAllConditions_selector = createSelector(selectRBACDataState, (rbacSlice) => {
  return rbacSlice.allConditions;
});

export const getConditions_selector = createSelector(selectRBACDataState, (rbacSlice) => {
  return rbacSlice.conditions;
});

export const getRBACAffectingUsers_selector = createSelector(selectRBACDataState, (rbacSlice) => {
  return rbacSlice.rbacAffectingUsers;
});

//bootstrapRBACWithDefaultRole_slice
export const selectRbacEnablingCondition = createSelector(selectRBACDataState, (rbacSlice) => {
  return rbacSlice.bootStrapRBACWithDefaultRole;
});

export const selectUpdatingUser = createSelector(selectRBACDataState, (rbacSlice) => {
  return rbacSlice.updatingUser;
});

export const getValidatedReportees_selector = createSelector(selectRBACDataState, (rbacSlice) => {
  return rbacSlice.validReporteeAdditions;
});
