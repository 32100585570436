import { Typography, Stack, Avatar, Paper, Grid } from '@mui/material';
import CognisaasCard from '../../../../../shared/CognisaasCard/CognisaasCard';
import CognisaasTaskStageIndicator from '../../../../../shared/CognisaasTaskStageIndicator/CognisaasTaskStageIndicator';
import { taskStage } from '../../../../../shared/CognisaasTaskStageIndicator/CognisaasTaskStageIndicator';
import UpdateTaskDrawer from '../../../../../TaskModule/TaskView/UpdateTaskDrawer';
import { useState } from 'react';
import { getContactNameByContactUid, getUserNameByUserUid } from '../../../../../../utils/getUid';
import { ReactComponent as NoRecords } from '../../../../../../assets/svgs/no_records.svg';
import { selectProjectInformation } from '../../../../../../store/modules/Project/selector';
import { useAppSelector } from '../../../../../../store/hooks';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { getProjectInformation } from '../../../../../../store/modules/Project/slice';
import { useDispatch } from 'react-redux';
import { differenceInDays } from 'date-fns';
import { label_due_date, label_task, label_use_case } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import ViewDate from 'components/shared/ViewDate/ViewDate';

const ImportantTasks = (props: any) => {
  console.log(props);

  const [updateTaskDrawer, setUpdateTaskDrawer] = useState<boolean>(false);
  const [taskForDrawer, setTaskForDrawer] = useState<any>(null);
  const projectInfo = useAppSelector(selectProjectInformation);
  const [tasks, setTasks] = useState<any>([]);
  const dispatch = useDispatch();
  const params = useParams();
  console.log(props);

  useEffect(() => {
    if (projectInfo) {
      makeRequirementsData(projectInfo);
    } else {
      const payload = {
        association_uid: params.associationId,
        project_uid: params.projectId,
      };
      dispatch(getProjectInformation(payload));
    }
  }, [projectInfo]);

  const makeRequirementsData = (project: any) => {
    console.log(project, params.caseId);

    const uc = project.usecase.filter((uc: any) => uc.case_uid == params.caseId);
    if (uc.length > 0) {
      let ucTasks: any = [];
      for (let i = 0; i < uc[0].section.length; i++) {
        ucTasks = [...ucTasks, ...uc[0].section[i].task];
        // console.log(uc);
        setTasks([...ucTasks]);
      }
    }
  };

  function stringToHslColor(str: string, s: number, l: number) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    const h = hash % 360;
    return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
  }

  function stringAvatar(name: string) {
    return {
      sx: {
        bgcolor: stringToHslColor(name, 30, 80),
      },
      children: (
        <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#000000' }}>
          {name.split(' ')[0][0]}
          {name.indexOf(' ') >= 0 ? name.split(' ')[1][0] : null}
        </Typography>
      ),
    };
  }

  console.log(props);

  const importantTasksCard = (task: any, index: number) => {
    // console.log(props);

    return (
      <>
        <CognisaasCard
          key={index}
          clientStatusColor={task.task_status}
          width="98%"
          height="85"
          customStyle={{ boxShadow: '0px 4px 26px rgba(0, 0, 0, 0.05)', border: '1px solid #E5E5E5' }}
        >
          <Stack p={1.6} justifyContent={'space-between'} direction="row">
            <Stack style={{ width: '140px' }}>
              <Stack spacing={1} direction="row">
                <CognisaasTaskStageIndicator
                  isMileStoneTask={task.milestone_task}
                  stage={
                    task.task_stage == 'Blocked'
                      ? taskStage.BLOCKED
                      : task.task_stage == 'Done'
                      ? taskStage.DONE
                      : task.task_stage == 'In Progress'
                      ? taskStage.IN_PROGRESS
                      : task.task_stage == 'Not Started'
                      ? taskStage.NOT_STARTED
                      : task.task_stage == 'Deferred'
                      ? taskStage.DEFERRED
                      : ''
                  }
                />
                <Grid container wrap="nowrap">
                  <Grid item>
                    <Typography
                      className="cognisaas-pointer"
                      onClick={() => openTaskDrawer(task)}
                      sx={{ fontSize: '14px', color: '#2D60F6', mt: '0px' }}
                      variant="subtitle1"
                    >
                      {task.task_name.length > 13 ? `${task.task_name.slice(0, 13)}...` : task.task_name}
                    </Typography>
                  </Grid>
                </Grid>
              </Stack>
              <Stack ml={3}>
                <Typography variant="subtitle2">
                  {differenceInDays(new Date(task.task_due_date.replace(/-/g, '/')), new Date()) >= 0
                    ? `${differenceInDays(new Date(task.task_due_date.replace(/-/g, '/')), new Date())} days to go`
                    : `${
                        differenceInDays(new Date(task.task_due_date.replace(/-/g, '/')), new Date()) * -1
                      } days overdue`}
                </Typography>
              </Stack>
            </Stack>
            <Stack style={{ width: '75px' }}>
              <Typography variant="subtitle2">{label_due_date}</Typography>
              <Typography variant="subtitle2">
                <ViewDate date={task.task_due_date} />
              </Typography>
            </Stack>
            <Stack direction="row" style={{ width: '130px' }}>
              <Avatar
                style={{ height: '34px', width: '34px', marginTop: '3px' }}
                {...stringAvatar(
                  task.is_team_task == '1'
                    ? getUserNameByUserUid(task.task_owner)
                    : getContactNameByContactUid(task.task_owner)
                )}
              />
              <Stack direction="column">
                <Typography sx={{ paddingLeft: '4px' }} display={'inline'} variant="subtitle2">
                  {label_task} Owner
                </Typography>
                <Typography
                  sx={{ paddingLeft: '6px' }}
                  variant="subtitle1"
                  style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: '110px' }}
                >
                  {task.is_team_task == '1'
                    ? getUserNameByUserUid(task.task_owner)
                    : getContactNameByContactUid(task.task_owner)}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </CognisaasCard>
      </>
    );
  };

  const openTaskDrawer = (task: any) => {
    setTaskForDrawer({ ...task });
    setUpdateTaskDrawer(true);
    // taskDetail={task}
    // drawerState={updateTaskDrawer}
    // onClose={() => setUpdateTaskDrawer(!updateTaskDrawer)}
  };

  return (
    <>
      {/* <Typography variant="h2">Important Tasks</Typography>
      {importantTasksCard()} */}

      <Paper sx={{ width: '100%', padding: '8px 0 16px', margin: '8px 0', boxShadow: 'none' }}>
        <Stack my={1} ml={2}>
          <Typography variant={'h2'}>{label_task}s which need attention</Typography>
        </Stack>
        <Stack>
          <Stack spacing={1} ml={2}>
            {tasks.filter((task: any) => task.task_status !== 'blue').length == 0 ? (
              <Stack justifyContent="center" alignItems="center">
                <NoRecords
                  style={{ marginLeft: '6px', marginTop: '10px', width: '150px', height: '150px' }}
                ></NoRecords>
                <Typography variant="h2" className="label-text" sx={{ textAlign: 'center' }}>
                  {label_use_case} Complete
                </Typography>
              </Stack>
            ) : tasks.filter((task: any) => task.task_status == 'red' || task.task_status == 'amber').length == 0 ? (
              <Stack justifyContent="center" alignItems="center">
                <NoRecords
                  style={{ marginLeft: '6px', marginTop: '10px', width: '150px', height: '150px' }}
                ></NoRecords>
                <Typography variant="h2" className="label-text" sx={{ textAlign: 'center' }}>
                  No {label_task}s
                </Typography>
              </Stack>
            ) : (
              <>
                {tasks
                  .filter((task: any) => task.task_status == 'red' || task.task_status == 'amber')
                  .map((task: any, index: number) => {
                    return <>{importantTasksCard(task, index)}</>;
                  })}
              </>
            )}

            {updateTaskDrawer && (
              <UpdateTaskDrawer
                client={props.clientDetail}
                taskDetail={taskForDrawer}
                drawerState={updateTaskDrawer}
                onClose={() => setUpdateTaskDrawer(!updateTaskDrawer)}
              />
            )}
            {/* {importantTasksCard()}
            {importantTasksCard()}
            {importantTasksCard()} */}
          </Stack>
        </Stack>
      </Paper>
    </>
  );
};

export default ImportantTasks;
