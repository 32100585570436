import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { taskAPI } from '../../../../api/modules/tasks';
import { requestState } from '../../types';

interface externalTasksType {
  getOpenTasks: Array<any>;
  getOpenTasksStatus: requestState;
  getOpenTasksError: null | undefined | string;
}

export const getOpenTasks = createAsyncThunk('tasks/getOpenTasks', async () => {
  const openTeamTasksData = await taskAPI.getTeamOpenTasks();
  const closedTeamTasksData = await taskAPI.getTeamClosedTasks();
  const data = [...openTeamTasksData.data.result, ...closedTeamTasksData.data.result];
  return data ?? [];
});

const initialState: externalTasksType = {
  getOpenTasks: [],
  getOpenTasksStatus: requestState.idle,
  getOpenTasksError: null,
};

const openTasksSlice = createSlice({
  name: 'getOpenTasks',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOpenTasks.pending, (state) => {
        state.getOpenTasksStatus = requestState.loading;
      })
      .addCase(getOpenTasks.fulfilled, (state, action) => {
        state.getOpenTasksStatus = requestState.success;
        state.getOpenTasks = action.payload;
      })
      .addCase(getOpenTasks.rejected, (state, action) => {
        state.getOpenTasksError = action.error.message;
        state.getOpenTasksStatus = requestState.failed;
      });
  },
});

export default openTasksSlice.reducer;
