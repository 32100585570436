import { Button, ButtonGroup } from '@mui/material';

interface Props {
  options: string[];
  setSelectedOption: React.Dispatch<React.SetStateAction<number>>;
  selectdOption: number;
}

const CognisaasButtonGroup = (props: Props) => {
  return (
    <ButtonGroup
      variant={'outlined'}
      sx={{
        display: 'inline-flex',
        flexWrap: 'wrap',
        backgroundColor: 'white',
        border: '1px solid #E5E5E5',
        borderRadius: '4px',
      }}
    >
      {props.options.map((item, index) => (
        <Button
          key={index}
          sx={
            props.options[props.selectdOption] === item
              ? { border: '1px solid transparent', background: 'rgba(45, 96, 246,0.15)', color: '#2D60F6' }
              : { border: '1px solid transparent', color: 'black' }
          }
          onClick={() => {
            props.setSelectedOption(index);
          }}
        >
          {item.charAt(0).toUpperCase() + item.slice(1)}
        </Button>
      ))}
    </ButtonGroup>
  );
};

export default CognisaasButtonGroup;
