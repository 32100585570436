import { useEffect, useState } from 'react';
import useCompanyUsers from './useCompanyUsers';
import useClientContacts from './useClientContacts';

const useUserDropDownData = (userType: string, clientUid?: string) => {
  const userList = userType == 'company' ? useCompanyUsers() : useClientContacts(clientUid as string);

  const [userData, setUserData] = useState<any>([]);

  useEffect(() => {
    if (userList.length > 0) {
      const array: any = [];
      for (let i = 0; i < userList.length; i++) {
        const data: any = {};
        data.id = userList[i].user_uid;
        data.name = userList[i].user_username;
        data.is_active = userList[i].is_active;
        data.user_uid = userList[i].user_uid;
        array.push(data);
      }

      setUserData([...array]);
    }
  }, [userList]);

  return userData;
};

export default useUserDropDownData;
