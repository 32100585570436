import { createTheme, Theme } from '@mui/material';

const theme: Theme = createTheme({
  palette: {
    primary: {
      main: '#2D60F6',
    },
    secondary: {
      main: '#7A7A7A',
    },
  },
  components: {
    // Name of the component ⚛️
    MuiButtonBase: {
      defaultProps: {
        // The props to apply
        disableRipple: true, // No more ripple, on the whole application 💣!
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          // minWidth: '310px',
        },
      },
    },

    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: '0px',
        },
      },
      defaultProps: {
        disableRipple: true,
      },
    },

    MuiRadio: {
      styleOverrides: {
        root: {
          padding: '0px',
        },
      },
      defaultProps: {
        disableRipple: true,
      },
    },
  },

  typography: {
    fontFamily: ['Noto Sans', 'sans-serif'].join(','),
    button: {
      textTransform: 'none',
    },
    h1: {
      fontWeight: 600,
      fontSize: 20,
    },
    h2: {
      fontWeight: 500,
      fontSize: 18,
    },
    h3: {
      fontWeight: 500,
      fontSize: 14,
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: 14,
    },
    subtitle2: {
      fontWeight: 400,
      fontSize: 12,
    },
  },
});

export default theme;