import { useEffect, useState } from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { GridRenderCellParams, GridToolbar, GridColumnOrderChangeParams } from '@mui/x-data-grid-premium';
import FilterTypeSelector from 'components/shared/CognisaasFilters/FilterTypeSelector/FilterTypeSelector';
import SelectedFilterList from 'components/shared/CognisaasFilters/SelectedFilterList/SelectedFilterList';
import CognisaasTabs from 'components/shared/CognisaasTabs';
import ReportsHeader, { ReportsPages } from '../ReportsHeader/ReportsHeader';
import { tabs, uniquePhaseColumnId } from './constants';
// import { initialState } from './tableConfig';
import { StyledBox, StripedDataGrid } from './style';
import { convertMinutes, usePhases } from './usePhases';
import { Link } from 'react-router-dom';
import CognisaasLoader from 'components/shared/CognisaasLoader/CognisaasLoader';
import { generateOrderedColumn } from 'utils/dataHelpers';
import { label_client, label_project, label_use_case } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { fetchClientList } from 'store/modules/ManageClient/ClientList/slice';
import { selectClientList } from 'store/modules/ManageClient/ClientList/selector';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import checkAccessForRouting from 'components/shared/Hooks/checkAccessForRouting';
import CognisaaasTooltip from 'components/shared/CognisaasTooltip/CognisaasTooltip';
import useCommonAppConfig from 'components/Common/useCommonAppConfig';

export function getAccountNameCell(params: GridRenderCellParams) {
  return (
    <Link to={`/client-list/client/${params.row.client_uid}`}>
      <Typography variant="subtitle1" className="cognisaas-pointer" sx={{ color: '#2D60F6' }}>
        {params.value}
      </Typography>
    </Link>
  );
}

export function getProjectNameCell(params: GridRenderCellParams) {
  return (
    <Grid>
      <Link to={`/projects/project/${params.row.association_uid}/${params.row.project_uid}`}>
        <Typography variant="subtitle1" className="cognisaas-pointer" sx={{ color: '#2D60F6' }}>
          {params.row.project_name}
        </Typography>
      </Link>
    </Grid>
  );
}

export function getCaseNameCell(params: GridRenderCellParams) {
  return (
    <Grid>
      {params.row.case_name ? (
        <Link to={`/projects/project/${params.row.association_uid}/${params.row.project_uid}/${params.row.case_uid}`}>
          <Typography variant="subtitle1" className="cognisaas-pointer" sx={{ color: '#2D60F6' }}>
            {params.row.case_name}
          </Typography>
        </Link>
      ) : (
        ' - '
      )}
    </Grid>
  );
}

export function getPlannedStartDateOfPhase(params: GridRenderCellParams) {
  return (
    <Typography variant="subtitle1">
      {params.row.planned_start_date_of_phase
        ? new Date(params.row.planned_start_date_of_phase).toLocaleDateString('en-gb', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          })
        : ' - '}
    </Typography>
  );
}

export function getActualStartDateOfPhase(params: GridRenderCellParams) {
  return (
    <Typography variant="subtitle1">
      {params.row.actual_start_date_of_phase
        ? new Date(params.row.actual_start_date_of_phase).toLocaleDateString('en-gb', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          })
        : ' - '}
    </Typography>
  );
}

export function getPlannedEndDateOfPhase(params: GridRenderCellParams) {
  return (
    <Typography variant="subtitle1">
      {params.row.planned_end_date_of_phase
        ? new Date(params.row.planned_end_date_of_phase).toLocaleDateString('en-gb', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          })
        : ' - '}
    </Typography>
  );
}

export function getActualEndDateOfPhase(params: GridRenderCellParams) {
  return (
    <Typography variant="subtitle1">
      {params.row.actual_end_date_of_phase
        ? new Date(params.row.actual_end_date_of_phase).toLocaleDateString('en-gb', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          })
        : ' - '}
    </Typography>
  );
}

export function showDuration(params: GridRenderCellParams) {
  return <Typography variant="subtitle1">{convertMinutes(params.value)}</Typography>;
}

export function getActualEndDateCell(params: GridRenderCellParams) {
  return (
    <Typography variant="subtitle1">
      {params.row.phase_completed
        ? params.value
          ? new Date(params.row.planned_end_date_of_phase).toLocaleDateString('en-gb', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })
          : '-'
        : ' - '}
    </Typography>
  );
}

export function getPhaseCompletedCell(params: GridRenderCellParams) {
  return (
    <Stack>
      <Stack spacing={1} direction="row">
        <span
          style={{ marginRight: '4px' }}
          className={
            params.value == 'No' ? 'red_status_box_task' : params.value == 'Yes' ? 'green_status_box_task' : ''
          }
        ></span>
        {params.value}
      </Stack>
    </Stack>
  );
}

const PhaseTable = () => {
  const [tableColumns, setTableColumns] = useState<any[]>([]);
  const { selectedTab, allFilters, phasesDataToDisplay, showTable, handleTabSelection } = usePhases();
  const dispatch = useAppDispatch();
  const clientList = useAppSelector(selectClientList);

  let myTableState: any = {};
  const { saveAppTableState, getTableState } = useCommonAppConfig();
  const initialState: any = {
    columns: {
      columnVisibilityModel: {
        section_uid: false,
      },
    },
    pinnedColumns: {
      left: ['section_name'],
    },
    pagination: {
      page: 0,
      pageSize: 10,
    },
    ...getTableState('phaseReportTable'),
  };

  const handleTableSave = (idName: string, thisTableState: any) => {
    saveAppTableState(idName, thisTableState);
  };

  const getClientPageURL = (row: any) => {
    return `/client-list/client/${row.client_uid}`;
  };

  const handleClientAccess = (params: GridRenderCellParams) => {
    const arrayToCheck = clientList.map((ele) => ele.client_uid);
    const hasAccessToClient = checkAccessForRouting({ arrayToCheck: arrayToCheck, uid: params.row.client_uid });
    console.log(params.value);
    if (hasAccessToClient == true) {
      return <Link to={getClientPageURL(params.row)}>{params.value}</Link>;
    }
    if (hasAccessToClient == false) {
      return (
        <CognisaaasTooltip
          title={<Typography sx={{ padding: '5px' }}>You do not have access to this {label_client}</Typography>}
        >
          <Typography variant="subtitle1">{params.value}</Typography>
        </CognisaaasTooltip>
      );
    }
  };

  useEffect(() => {
    dispatch(fetchClientList());
  }, []);

  const phasesColumns = [
    // {
    //   field: 'section_uid',
    //   headerName: 'Section Uid',
    //   // flex: 1,
    //   width: 200,
    // },
    {
      field: 'section_name',
      headerName: 'Phase Name',
      width: 200,
      // flex: 1,
      // editable: false,
    },
    {
      headerName: `${label_client} Name`,
      field: 'client_name',
      width: 200,
      renderCell: (params: GridRenderCellParams) => handleClientAccess(params),
    },
    {
      field: 'project_name',
      headerName: `${label_project} Name`,
      // flex: 1,
      width: 200,
      renderCell: (params: GridRenderCellParams) => {
        return getProjectNameCell(params);
      },
    },
    {
      field: 'case_name',
      headerName: `${label_use_case} Name`,
      // flex: 1,
      width: 200,
      renderCell: (params: GridRenderCellParams) => {
        return getCaseNameCell(params);
      },
    },
    {
      field: 'planned_start_date_of_phase',
      headerName: 'Planned start date of phase',
      // flex: 1,
      width: 240,
      type: 'date',
      // valueGetter: ({ value }: any) => value && new Date(value),
      renderCell: (params: GridRenderCellParams) => getPlannedStartDateOfPhase(params),
    },
    {
      field: 'actual_start_date_of_phase',
      headerName: 'Actual start date of phase',
      // flex: 1,
      width: 240,
      type: 'date',
      // valueGetter: ({ value }: any) => value && new Date(value),
      renderCell: (params: GridRenderCellParams) => getActualStartDateOfPhase(params),
    },
    {
      field: 'planned_end_date_of_phase',
      headerName: 'Planned end date of phase',
      // flex: 1,
      width: 240,
      type: 'date',
      // valueGetter: ({ value }: any) => value && new Date(value),
      renderCell: (params: GridRenderCellParams) => getPlannedEndDateOfPhase(params),
    },
    {
      field: 'actual_end_date_of_phase',
      headerName: 'Actual end date of phase',
      // flex: 1,
      width: 240,
      type: 'date',
      // valueGetter: ({ value }: any) => value && new Date(value),
      renderCell: (params: GridRenderCellParams) => getActualEndDateOfPhase(params),
    },
    {
      field: 'phase_completed',
      headerName: 'Phase completed',
      // flex: 1,
      width: 150,
      renderCell: (params: GridRenderCellParams) => getPhaseCompletedCell(params),
    },
    {
      field: 'planned_duration_of_phase',
      headerName: 'Planned duration of phase',
      // flex: 1,
      width: 240,
      renderCell: (params: GridRenderCellParams) => showDuration(params),
    },
    {
      field: 'actual_duration_of_phase',
      headerName: 'Actual duration of phase',
      // flex: 1,
      width: 240,
      renderCell: (params: GridRenderCellParams) => showDuration(params),
    },
  ];

  useEffect(() => {
    setTableColumns(generateOrderedColumn(phasesColumns, uniquePhaseColumnId));
  }, []);

  function getTabSwitcher(selectedTab: number) {
    return (
      <Stack direction={'row'} justifyContent={'space-between'}>
        <CognisaasTabs
          size="squeezed"
          selectedTabIndex={selectedTab}
          handleTabSwitch={handleTabSelection}
          tabs={tabs}
        />
        <Box>
          <FilterTypeSelector idName="reportPhases" />
        </Box>
      </Stack>
    );
  }

  const handleColumnOrderChange = (params: GridColumnOrderChangeParams) => {
    const oldArrayIndex = params.oldIndex;
    const targetArrayIndex = params.targetIndex;
    setTableColumns(generateOrderedColumn(tableColumns, uniquePhaseColumnId, oldArrayIndex, targetArrayIndex));
  };

  return (
    <>
      <Box sx={{ display: 'grid', gridTemplateRows: '70px auto', height: '100%' }}>
        <ReportsHeader pageName={ReportsPages.Phases} />
        <Box sx={{ display: 'grid', gridTemplateRows: '50px auto', m: '0px 20px', height: '100%' }}>
          {getTabSwitcher(selectedTab)}
          {/* filters */}
          <Box sx={{ margin: '8px 0px' }}>
            {allFilters['reportPhases']?.appliedFilters.filter((item: any) => item.isSelected).length > 0 && (
              <SelectedFilterList idName="reportPhases" isResetEnabled={true} />
            )}
          </Box>
          <Box
            sx={{
              display: 'grid',
              justifyContent: 'center',
              alignItems: 'center',
              gridTemplateColumns: '1fr',
              height: '100%',
            }}
          >
            {showTable ? (
              <StyledBox>
                <StripedDataGrid
                  initialState={initialState}
                  onStateChange={(tblState: any) => {
                    myTableState = tblState;
                  }}
                  onColumnVisibilityModelChange={() => {
                    handleTableSave('phaseReportTable', myTableState);
                  }}
                  onFilterModelChange={() => {
                    handleTableSave('phaseReportTable', myTableState);
                  }}
                  onPageSizeChange={() => {
                    handleTableSave('phaseReportTable', myTableState);
                  }}
                  columns={tableColumns}
                  rows={phasesDataToDisplay}
                  // initialState={initialState}
                  components={{
                    Toolbar: GridToolbar,
                  }}
                  componentsProps={{
                    toolbar: { showQuickFilter: true },
                  }}
                  // loading={true}
                  getRowId={(phasesDataToDisplay) => phasesDataToDisplay.section_uid}
                  disableSelectionOnClick
                  rowThreshold={0}
                  // pageSize={rowsCount}
                  // onPageSizeChange={(newPageSize) => setRowsCount(newPageSize)}
                  autoHeight={true}
                  rowsPerPageOptions={[5, 10, 20, 50, 100]}
                  pagination
                  getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'odd' : 'odd')}
                  onColumnOrderChange={handleColumnOrderChange}
                />
              </StyledBox>
            ) : (
              <div>
                <CognisaasLoader />
              </div>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default PhaseTable;
