import React, { useState, useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import Cookies from 'universal-cookie';
import { useLocation } from 'react-router-dom';
import { Account, JWTToken } from '../../src/store/types/index';

const env: string = process.env.REACT_APP_ENV ?? 'local';
const cookies = new Cookies();

const PublicRoute: React.FC = ({ children }): JSX.Element => {
  const [isLoggedInUser, setIsLoggedInUser] = useState(false);
  const [requiresSubdomainRedirection, setSubdomainRedirection] = useState(false);
  const location = useLocation();
  useEffect(() => {
    const jwtToken = cookies.get('jwt-token');
    if (jwtToken) {
      setIsLoggedInUser(true);
    } else {
      const full = window.location.host;
      //window.location.host is cognisaas.devng.cognisaas.com
      const parts = full.split('.');
      //TODO handle for subdomains like xyz.abc.devng.cognisaas.com
      const subdomain = parts[0]; //Example [app].devng.cognisaas.com
      if (subdomain !== 'app') {
        setSubdomainRedirection(true);
      }
    }
  }, []);
  useEffect(() => {
    //if user is logged in send to dashboard
    if (isLoggedInUser) {
      console.log('---------------------------------Redirections');
      const jwtToken = cookies.get('jwt-token');
      const decoded_token = jwtDecode<JWTToken>(jwtToken);
      const account = decoded_token.account as Account;
      if (env == 'dev') {
        document.location.href = `https://${account.subdomain}.devng.cognisaas.com`;
      } else if (env == 'prod') {
        document.location.href = `https://${account.subdomain}.ng.cognisaas.com`;
      } else if (env == 'new-prod') {
        document.location.href = `https://${account.subdomain}.cognisaas.net`;
      }
    }
    //if user is visiting a subdomain public route i.e company.devng.cognisaas.com/public_route
    //redirect to app.devng.cognisaas.com/public_route
    if (requiresSubdomainRedirection) {
      console.log('---------------------------------Redirections11111111111111111');
      if (env == 'dev') {
        document.location.href = `https://app.devng.cognisaas.com${location.pathname}`;
      } else if (env == 'prod') {
        document.location.href = `https://app.ng.cognisaas.com${location.pathname}`;
      } else if (env == 'new-prod') {
        document.location.href = `https://app.cognisaas.net${location.pathname}`;
      }
    }
  }, [isLoggedInUser, requiresSubdomainRedirection]);
  return children as JSX.Element;
};
export default PublicRoute;
