import { Drawer, Stack, IconButton, TextField, Typography, Menu, MenuItem } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CognisaasTabs from '../../shared/CognisaasTabs';
import { useState, useEffect, useRef } from 'react';
import EditTask from './EditTask';
import Dependency from './Dependency/Dependency';
import Subtask from './Subtask/Subtask';
import Customfields from './Customfields/Customfields';
import TimeTracking from './TimeTracking/TimeTracking';
import CommentHome from './Comment/CommentHome';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ClearIcon from '@mui/icons-material/Clear';
import { getProjectInformation } from '../../../store/modules/Project/slice';
import { useAppSelector, useAppDispatch } from '../../../store/hooks';
import TaskAttachment from './Attachments/Attachments';
import CircleIcon from '@mui/icons-material/Circle';
import {
  deleteProjectTask,
  deleteProjectClientTask,
  deleteUsecaseClientTask,
  deleteUsecaseTask,
  syncTaskFromJira,
  syncProjectTaskfromJira,
  getJiraProjects,
  getJiraProjectIssueTypesList,
  clearTaskStore,
  addTaskAuditTrail,
  getTaskCustomfieldsWithValue,
} from '../../../store/modules/Task/slice';
import { getCustomerUid, getLoggedInUserName, getLoggedInUserUid } from 'utils/getUid';
import {
  selectTaskAuditTrail,
  selectProjectTaskAuditTrail,
  selectClientTaskAuditTrail,
  selectProjectClientTaskAuditTrail,
  selectTaskCustomfieldsWithValue,
} from 'store/modules/Task/selector';
import {
  getProjectTaskAuditTrail,
  getTaskAuditTrail,
  getClientTaskAuditTrail,
  getProjectClientTaskAuditTrail,
  // updateJiraIssueForTasks,
} from '../../../store/modules/Task/slice';
import CognisaasTimeline from 'components/shared/CognisaasTimeline/CognisaasTimeline';
import { label_task } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { userPermissionSchema } from 'components/Reports/SideDrawer/types';
import { getTaskByTaskUid } from '../../../store/modules/Task/slice';
import DeleteModal from 'components/shared/CognisaasModal/DeleteModal';
import { getCustomerDetail, updateCaseWithfeatureStatus } from 'store/modules/Common/slice';
import { selectCustomerDetail } from 'store/modules/Common/selector';
import { isMandatoryFieldsEmpty, isNull } from 'utils/dataHelpers';
import CognisaasLoader from 'components/shared/CognisaasLoader/CognisaasLoader';
import Cookies from 'universal-cookie';
import { getJiraCustomFieldNames } from '../jiraHelpers/jiraUtil';
import { FormikProps } from 'formik';
import { format } from 'date-fns';
import CognisaasAlertBlinker from 'components/shared/CognisaasAlertBlinker/CognisaasAlertBlinker';

const cookies = new Cookies();
const UpdateTaskDrawer = (props: any) => {
  // const { userPermissions } =  props;

  const dispatch = useAppDispatch();
  const taskDetailFormRef = useRef<FormikProps<any>>(null);
  const taskCustomFieldsFormRef = useRef<FormikProps<any>>(null);

  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [dataForEditTasks, setDataForEditTasks] = useState<any>({});
  const [drawerHeaderTextField, setDrawerHeaderTextField] = useState('');
  const [drawerHeaderTaskStatus, setDrawerHeaderTaskStatus] = useState('');
  const [isHeaderUpdate, setIsHeaderUpdate] = useState(false);
  const [editTaskHeader, setEditTaskHeader] = useState(false);
  const [userPermissions, setUserPermissions] = useState<any[]>(['view', 'edit', 'delete']);
  let tabs: string[];
  const client = props.client;
  const task = props.taskDetail;
  if (task?.is_team_task == 1)
    tabs = ['Details', 'Comments', `Sub ${label_task}`, 'Dependency', 'Attachments', 'Time Tracking', 'Custom Fields'];
  else tabs = ['Details', 'Comments', `Sub ${label_task}`, 'Dependency', 'Attachments', 'Time Tracking'];
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const projectTaskTrail = useAppSelector(selectProjectTaskAuditTrail);
  const taskTrail = useAppSelector(selectTaskAuditTrail);
  const clientTaskTrail = useAppSelector(selectClientTaskAuditTrail);
  const projectClientTaskTrail = useAppSelector(selectProjectClientTaskAuditTrail);
  const taskCustomfieldsWithValues = useAppSelector(selectTaskCustomfieldsWithValue);
  const [timelineDetails, setTimelineDetails] = useState<any>([]);
  const [tabComponentIndex, setTabComponentIndex] = useState<number | undefined>();
  const [tabComponent, setTabComponent] = useState<any>();

  const [openTimelineModal, setOpenTimelineModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const customerDetail = useAppSelector(selectCustomerDetail);
  const open = Boolean(anchorEl);
  const appDispatch = useAppDispatch();
  const [waitingForTasksync, setWaitingForTasksync] = useState<boolean>(true);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const syncTaskFromJiraAndUpdate = async () => {
    setWaitingForTasksync(true);
    await dispatch(getCustomerDetail());
    const key = btoa(btoa(`${customerDetail.customer_jira_user}:${customerDetail.customer_jira_token}`));

    if (task.jira_issue_id && task.jira_issue_id?.length > 0 && task.is_team_task === '1' && task.jira_ind === '1') {
      await appDispatch(getJiraProjects()).then(async (res: any) => {
        const taskJiraProject = res.payload.filter((item: any) => item.jira_project_id == task.jira_project_id)[0];

        const data = {
          customer_uid: cookies.get('cogni_customer_uid'),
          key: key,
          project_key: taskJiraProject.project_key,
        };
        await appDispatch(getJiraProjectIssueTypesList(data)).then(async (isuueTypeRes: any) => {
          const issueParsedRes = JSON.parse(isuueTypeRes.payload);
          const taskIssueType = issueParsedRes.issueTypes.filter((item: any) => item.id == task.jira_issue_type)[0];

          const customfield = await getJiraCustomFieldNames(
            customerDetail.customer_jira_token,
            customerDetail.customer_jira_user,
            customerDetail.customer_uid,
            taskJiraProject.project_key,
            taskIssueType
          );

          const payload = {
            customer_uid: cookies.get('cogni_customer_uid'),
            key: key,
            task_uid: task.task_uid,
            customDeliveryDate: customfield.customDeliveryDate,
            task_stage: task.task_stage,
          };
          if (isNull(task.case_uid)) {
            await dispatch(syncProjectTaskfromJira(payload));
          } else {
            await dispatch(syncTaskFromJira(payload));
          }
        });
      });
    }

    appDispatch(getTaskByTaskUid(task.task_uid)).then((res: any) => {
      setDataForEditTasks(res.payload);
      setWaitingForTasksync(false);
    });
  };

  useEffect(() => {
    syncTaskFromJiraAndUpdate();
    setDrawerHeaderTextField(props.taskDetail.task_name);
    setDrawerHeaderTaskStatus(props.taskDetail.task_status ? props.taskDetail.task_status : '');
    setDataForEditTasks(props.taskDetail);
    updateUserPermissions(props.taskDetail.task_uid);
    dispatch(getTaskCustomfieldsWithValue(props.taskDetail.task_uid));
  }, []);

  const updateUserPermissions = async (taskUid: any) => {
    const res = await dispatch(getTaskByTaskUid(taskUid)).unwrap();
    setUserPermissions([...res.__user_permissions]);
  };

  useEffect(() => {
    if (taskCustomfieldsWithValues && isMandatoryFieldsEmpty(taskCustomfieldsWithValues)) {
      for (let i = 0; i < tabs.length; i++) {
        if (tabs[i] === 'Custom Fields') {
          setTabComponentIndex(i);
          // eslint-disable-next-line react/jsx-no-undef
          setTabComponent(<CognisaasAlertBlinker type="danger" />);
        }
      }
    } else {
      setTabComponentIndex(undefined);
      setTabComponent(undefined);
    }
  }, [taskCustomfieldsWithValues]);

  useEffect(() => {
    if (task.is_team_task === '1') {
      if (task.case_uid === null) {
        setTimelineDetails(projectTaskTrail.task_audits || []);
      } else {
        setTimelineDetails(taskTrail.task_audits || []);
      }
    } else {
      if (task.case_uid === null) {
        setTimelineDetails(projectClientTaskTrail.task_audits || []);
      } else {
        setTimelineDetails(clientTaskTrail.task_audits || []);
      }
    }
  }, [projectTaskTrail, taskTrail, clientTaskTrail, projectClientTaskTrail]);

  useEffect(() => {
    const fetchAuditTrails = async () => {
      if (task.is_team_task === '1') {
        if (task.case_uid === null) {
          await dispatch(getProjectTaskAuditTrail(task.task_uid));
        } else {
          await dispatch(getTaskAuditTrail(task.task_uid));
        }
      } else {
        if (task.case_uid === null) {
          await dispatch(getProjectClientTaskAuditTrail(task.task_uid));
        } else {
          await dispatch(getClientTaskAuditTrail(task.task_uid) || []);
        }
      }
    };
    fetchAuditTrails();
  }, []);

  useEffect(() => {
    return () => {
      dispatch(clearTaskStore());
    };
  }, []);

  const returnComponentForSelectedtab = () => {
    if (selectedTab === 0) {
      return (
        <EditTask
          taskDetailFormRef={taskDetailFormRef}
          phaseUid={props.phaseUid}
          onClose={props.onClose}
          client={client}
          drawerHeaderTextField={drawerHeaderTextField}
          taskDetail={dataForEditTasks}
          projectInformation={props.projectInformation}
          userPermissions={userPermissions}
        />
      );
    } else if (selectedTab === 1) {
      return <CommentHome clientDetail={client} taskDetail={props.taskDetail} />;
    } else if (selectedTab === 2) {
      return <Subtask taskDetail={props.taskDetail} userPermissions={userPermissions} />;
    } else if (selectedTab === 3) {
      return <Dependency taskDetail={props.taskDetail} userPermissions={userPermissions} />;
    } else if (selectedTab === 4) {
      return <TaskAttachment data={props.taskDetail} clientDetail={client} userPermissions={userPermissions} />;
    } else if (selectedTab === 5) {
      return <TimeTracking onClose={props.onClose} taskDetail={props.taskDetail} userPermissions={userPermissions} />;
    } else if (selectedTab === 6) {
      return (
        <Customfields
          taskCustomFieldsFormRef={taskCustomFieldsFormRef}
          onClose={props.onClose}
          taskDetail={props.taskDetail}
          userPermissions={userPermissions}
        />
      );
    }
  };

  const handleDelete = async () => {
    const base_com_url = window.location.host;
    const detail = {
      dependency_details: [],
      task_uid: props.taskDetail.task_uid,
      base_com_url: base_com_url,
      customer_uid: getCustomerUid(),
      deleted_by_user_name: getLoggedInUserName(),
    };
    const auditDetail = {
      task_uid: props.taskDetail.task_uid,
      user_uid: getLoggedInUserUid(),
      field: 'task_deleted',
      modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      old_value: '',
      new_value: '',
    };
    dispatch(addTaskAuditTrail(auditDetail));
    if (props.taskDetail.project_uid && props.taskDetail.case_uid) {
      if (props.taskDetail.is_team_task == 1) {
        await dispatch(deleteUsecaseTask(detail));
      } else {
        await dispatch(deleteUsecaseClientTask(detail));
      }
    } else if (props.taskDetail.project_uid && props.taskDetail.case_uid == null) {
      if (props.taskDetail.is_team_task == 1) {
        await dispatch(deleteProjectTask(detail));
      } else {
        await dispatch(deleteProjectClientTask(detail));
      }
    }

    const getProjectData = {
      association_uid: props.client.association_uid,
      project_uid: props.taskDetail.project_uid,
    };
    await dispatch(getProjectInformation(getProjectData));
    dispatch(updateCaseWithfeatureStatus(props.client.client_uid));
    props.onClose();
  };

  const handleOnCloseTimelineModal = () => setOpenTimelineModal(false);
  const handleOnOpenTimelineModal = () => setOpenTimelineModal(true);

  const statusColorForEditTaskModal = (status: string) => {
    if (status == 'amber')
      return (
        <>
          <span style={{ display: 'inline-flex', alignItems: 'center' }}>
            <CircleIcon fontSize="small" sx={{ color: '#EFA958' }} />
            <Typography variant="subtitle1">&nbsp;At Risk</Typography>
          </span>
        </>
      );

    if (status == 'green')
      return (
        <>
          <span style={{ display: 'inline-flex', alignItems: 'center' }}>
            <CircleIcon fontSize="small" sx={{ color: '#4F9F52' }} />
            <Typography variant="subtitle1">&nbsp;On Track</Typography>
          </span>
        </>
      );

    if (status == 'red')
      return (
        <>
          <span style={{ display: 'inline-flex', alignItems: 'center' }}>
            <CircleIcon fontSize="small" sx={{ color: '#E75B5C' }} />
            <Typography variant="subtitle1">&nbsp;Overdue</Typography>
          </span>
        </>
      );

    if (status == 'blue')
      return (
        <>
          <span style={{ display: 'inline-flex', alignItems: 'center' }}>
            <CircleIcon fontSize="small" sx={{ color: '#4390e1' }} />
            <Typography variant="subtitle1">&nbsp;Completed</Typography>
          </span>
        </>
      );
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={props.drawerState}
        onClose={() => props.onClose()}
        sx={{
          '.MuiPaper-root': {
            width: '900px',
            overflowX: 'hidden',
          },
        }}
      >
        {waitingForTasksync ? (
          <CognisaasLoader />
        ) : (
          <Stack my={2}>
            <Stack mb={1} m={2} justifyContent="space-between" sx={{ marginTop: '77px' }} direction="row">
              <Stack direction="row" sx={{ display: 'flex', alignItems: 'center' }}>
                {editTaskHeader ? (
                  <TextField
                    size={'small'}
                    value={drawerHeaderTextField}
                    onChange={(obj: any) => {
                      const temp = { ...props.taskDetail };
                      temp.task_name = obj.target.value;
                      setDataForEditTasks(temp);
                      setDrawerHeaderTextField(obj.target.value);
                      setIsHeaderUpdate(!isHeaderUpdate);
                    }}
                  ></TextField>
                ) : (
                  <Typography>{drawerHeaderTextField}</Typography>
                )}
                {userPermissions?.includes(userPermissionSchema.EDIT) && (
                  <IconButton
                    onClick={() => {
                      setEditTaskHeader(!editTaskHeader);
                    }}
                    disableRipple
                    sx={{ paddingTop: '0px', fontSize: '10px' }}
                  >
                    {editTaskHeader ? <ClearIcon fontSize="small" /> : <EditIcon fontSize="small" />}
                  </IconButton>
                )}
                <Stack style={{ marginLeft: '16px' }}>{statusColorForEditTaskModal(drawerHeaderTaskStatus)}</Stack>
              </Stack>
              <Stack>
                <IconButton disableRipple onClick={handleClick}>
                  <MoreVertIcon />
                </IconButton>
              </Stack>
            </Stack>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              style={{ padding: '0px' }}
            >
              <MenuItem
                onClick={() => {
                  handleOnOpenTimelineModal();
                }}
              >
                <Typography variant="inherit">History</Typography>
              </MenuItem>
              {userPermissions?.includes(userPermissionSchema.EDIT) && (
                <MenuItem
                  onClick={() => {
                    setOpenDeleteModal(true);
                    // handleDelete();
                  }}
                >
                  <Typography variant="inherit">Delete {label_task}</Typography>
                </MenuItem>
              )}
            </Menu>
            <Stack>
              <CognisaasTabs
                tabs={tabs}
                tabComponent={tabComponent}
                tabComponentIndex={tabComponentIndex}
                handleTabSwitch={(e: any, tab: any) => {
                  setSelectedTab(tab);
                }}
              />
            </Stack>
            <CognisaasTimeline
              open={openTimelineModal}
              handleOnClose={handleOnCloseTimelineModal}
              timelineDetails={timelineDetails}
              type={label_task}
            />
            {returnComponentForSelectedtab()}
          </Stack>
        )}
      </Drawer>
      <DeleteModal
        isOpen={openDeleteModal}
        setIsOpen={setOpenDeleteModal}
        message={`Are you sure you want to permanently delete this ${label_task.toLowerCase()}?`}
        onDelete={handleDelete}
      />
    </>
  );
};

export default UpdateTaskDrawer;
