import CognisaasCard from '../CognisaasCard';
import { Avatar, Typography, Stack } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import { CustomizedProgressBars } from '../../CognisaasProgressBar/ProgressBar';
import format from 'date-fns/format';
import { useNavigate } from 'react-router-dom';
import { label_project_delivery_manager } from 'components/Settings/Preferences/CustomLabelCookieHelper';

// interface props {
//   data: any;
// }

const CognisaasProjectCard = (props: any) => {
  console.log(props);
  const projectDetail = props.projectDetail;
  const navigate = useNavigate();

  function stringToHslColor(str: string, s: number, l: number) {
    let hash = 0;

    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    const h = hash % 360;
    return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
  }

  function stringAvatar(name: string) {
    return {
      sx: {
        bgcolor: stringToHslColor(name, 30, 80),
      },
      children: (
        <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#000000' }}>
          {name.split(' ')[0][0]}
          {name.indexOf(' ') >= 0 ? name.split(' ')[1][0] : null}
        </Typography>
      ),
    };
  }

  return (
    <CognisaasCard
      width="310px"
      height="150px"
      boxShadow="0px 4px 26px rgba(0, 0, 0, 0.05)"
      clientStatusColor={projectDetail.project_readinessstate_status}
    >
      <CardContent
        className="cognisaas-pointer"
        onClick={() => {
          navigate(`project/${projectDetail.association_uid}/${projectDetail.project_uid}`);
        }}
      >
        <Stack>
          <Typography
            style={{ fontSize: '18px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
            variant="subtitle1"
          >
            {projectDetail.project_name}
          </Typography>
          <Typography className="label-text" variant="subtitle2">
            {projectDetail.client_name}
          </Typography>
          <CustomizedProgressBars
            hidePercentage={false}
            value={Math.floor(projectDetail.project_progress)}
            statusColor={'grees'}
          ></CustomizedProgressBars>
          <Stack mt={2} direction="row" justifyContent="space-between">
            <Stack>
              <Typography className="label-text" variant="subtitle2">
                {' '}
                Due by
              </Typography>
              <Typography variant="subtitle2">
                {' '}
                {format(new Date(projectDetail.project_requiredbydate.replace(/-/g, '/')), 'yyyy-MMM-dd')}{' '}
              </Typography>
            </Stack>
            <Stack direction="row">
              {/* <Avatar style={{ marginRight: '6px' }}>N</Avatar> */}
              <Avatar
                style={{ height: '34px', width: '34px', marginTop: '3px', marginRight: '4px' }}
                {...stringAvatar(projectDetail.project_deliverymanager)}
              />

              <Stack>
                <Typography className="label-text" variant="subtitle2">
                  {label_project_delivery_manager}
                </Typography>
                <Typography variant="subtitle2"> {projectDetail.project_deliverymanager} </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
    </CognisaasCard>
  );
};

export default CognisaasProjectCard;
