import { Mention, MentionsInput } from 'react-mentions';
import { useState, useEffect } from 'react';
import defaultStyle from './defaultStyle';
import defaultMentionStyle from './defaultMentionStyle';
import { Stack, Button } from '@mui/material';
import { getAllUsers } from '../../../../store/modules/Common/slice';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { selectUsersOfCompany } from '../../../../store/modules/Common/selector';
import { getUserDetails_slice } from '../../../../store/modules/Settings/slice';
import { getLoggedInUserUid, getUserNameByUserUid } from '../../../../utils/getUid';
import { format } from 'date-fns';
import { isUndefined } from 'utils/dataHelpers';
import {
  addProjectAuditTrial,
  addProjectComment,
  getAllProjectComments,
  updateProjectComment,
} from 'store/modules/Project/slice';
interface commentProps {
  commentDetail?: any;
  type?: 'Save' | 'Edit';
  setCommentId?: (value: string) => void;
  projectUid: string;
}

const Comment = (props: commentProps) => {
  const [comment, setComment] = useState<string>(props.commentDetail?.comment || '');
  const type: 'Save' | 'Edit' = props.type === 'Edit' ? 'Edit' : 'Save';

  const [commentText, setCommentText] = useState<string>('');
  const [users, setUsers] = useState<any[]>([]);
  const [mentions, setMentions] = useState<any[]>([]);
  const dispatch = useAppDispatch();
  const usersOfCompany = useAppSelector(selectUsersOfCompany);

  useEffect(() => {
    dispatch(getUserDetails_slice(getLoggedInUserUid()));
  }, []);

  useEffect(() => {
    if (usersOfCompany.length == 0) {
      dispatch(getAllUsers());
    }
  }, []);

  useEffect(() => {
    if (usersOfCompany.length > 0) {
      getUserListArray();
    }
  }, [usersOfCompany]);

  const getUserListArray = () => {
    const list = usersOfCompany.map((user: any) => ({ display: user.user_username, id: `company:${user.user_uid}` }));
    setUsers(list);
  };

  const detailsForSavingComent = () => {
    const detail = {
      comment_uid: props.commentDetail?.comment_uid || '',
      comment: comment,
      project_uid: props.projectUid,
      user_username: getUserNameByUserUid(getLoggedInUserUid()),
      user_uid: getLoggedInUserUid(),
      value: commentText,
      report_url: `${location.hostname}/`,
      company_url: '',
    };
    const mentioned = [];
    for (let i = 0; i < mentions.length; i++) {
      const mention = {
        display: mentions[i].display,
        id: mentions[i].id.split(':')[1],
        type: mentions[i].id.split(':')[0],
      };
      mentioned.push(mention);
    }

    const commentDetail = {
      opost: detail,
      userlistmentions: [...mentioned],
    };
    return commentDetail;
  };

  const saveComment = async () => {
    const commentDetail = detailsForSavingComent();
    await dispatch(addProjectComment(commentDetail));
    setCommentText('');
    await dispatch(getAllProjectComments(props.projectUid));
    dispatchProjectAuditTrail();
  };
  const dispatchProjectAuditTrail = () => {
    const detail = {
      project_uid: props.projectUid,
      user_uid: getLoggedInUserUid(),
      field: 'Comment',
      modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      old_value: isUndefined(props.commentDetail) ? '' : props.commentDetail.comment,
      new_value: commentText,
    };
    dispatch(addProjectAuditTrial(detail));
  };

  const updateComment = async () => {
    const commentDetail = detailsForSavingComent();
    await dispatch(updateProjectComment(commentDetail));
    setCommentText('');
    await dispatch(getAllProjectComments(props.projectUid));
    dispatchProjectAuditTrail();
  };

  const handleSaveButton = () => {
    if (type === 'Edit') {
      updateComment();
    } else if (type === 'Save') {
      saveComment();
    }
    if (props.setCommentId) {
      props.setCommentId('');
    }
    setComment('');
  };

  const handleCancelButton = () => {
    if (props.setCommentId) {
      props.setCommentId('');
    }
    setComment('');
  };

  return (
    <Stack sx={{ width: '100%', fontFamily: 'Noto sans' }}>
      <MentionsInput
        style={defaultStyle}
        value={comment}
        placeholder="Type here"
        className="mention-input"
        onChange={(e: any, newValue: any, newPlainTextValue: any, mentions: any) => {
          setCommentText(newPlainTextValue);
          setComment(e.target.value);
          setMentions([...mentions]);
        }}
      >
        <Mention style={defaultMentionStyle} trigger="@" data={[...users]} />
      </MentionsInput>
      <Stack justifyContent={'flex-end'} direction="row">
        <Stack direction="row">
          <Button variant="text" disabled={commentText.trim().length === 0} onClick={() => handleSaveButton()}>
            Save
          </Button>
          <Button variant="text" disabled={commentText.trim().length === 0} onClick={() => handleCancelButton()}>
            Cancel
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Comment;
