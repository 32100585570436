// const months = [
//   'January',
//   'February',
//   'March',
//   'April',
//   'May',
//   'June',
//   'July',
//   'August',
//   'September',
//   'October',
//   'November',
//   'December',
// ];

const miniMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
const fullMonths = ['January','Febuary','March','April','May','June','July','August','September','October','November','December'];

export const parseProductRequirementDate = (dateString: string) => {
  if (
    Object.prototype.toString.call(dateString) === '[object Date]' ||
    Object.prototype.toString.call(dateString) === '[object Null]' ||
    dateString === ''
  ) {
    return '--';
  }

  const dateArr = dateString.split(' ')[0].split('-');
  if (typeof miniMonths[parseInt(dateArr[1]) - 1] === 'undefined') {
    return '--';
  }
  return `${dateArr[2]} ${miniMonths[parseInt(dateArr[1]) - 1]} ${dateArr[0].slice(2)}`;
};

export const parseServerDateToFullDateWithFullMonths = (dateString: string) => {
  // 2022-05-13 00:00:00 - this type of string is parsed
  // console.log(dateString);

  if (
    Object.prototype.toString.call(dateString) === '[object Date]' ||
    Object.prototype.toString.call(dateString) === '[object Null]' ||
    dateString === ''
  ) {
    return '-';
  }

  const dateArr = dateString.split(' ')[0].split('-');
  if (typeof fullMonths[parseInt(dateArr[1]) - 1] === 'undefined') {
    return '-';
  }
  return `${dateArr[2]} ${fullMonths[parseInt(dateArr[1]) - 1]} ${dateArr[0]}`;
};

export const daysToFullTimeLength = (numberOfDays:number) => {

   let months = 0, years = 0, days = 0, weeks = 0;
   while(numberOfDays){
      if(numberOfDays >= 365){
         years++;
         numberOfDays -= 365;
      }else if(numberOfDays >= 30){
         months++;
         numberOfDays -= 30;
      }else if(numberOfDays >= 7){
         weeks++;
         numberOfDays -= 7;
      }else{
         days++;
         numberOfDays--;
      }
   }

   let finalFormattedDate = '';
   if(years>0) {
    finalFormattedDate = finalFormattedDate + years + ' years ';
   }else if(months > 0 ) {
    finalFormattedDate = finalFormattedDate + months + ' months ';
   }else if(weeks > 0) {
    finalFormattedDate = finalFormattedDate + weeks + ' weeks ';
   }else if(days > 0) {
    finalFormattedDate = finalFormattedDate + days+ ' days';
   }

   console.log(finalFormattedDate);

   return finalFormattedDate;
};

export const parseDateObjTo_YYYYMMDD_Format = (dateObj: Date | string) => {
  if (Object.prototype.toString.call(dateObj) === '[object Date]') {
    return '';
  }
  if (Object.prototype.toString.call(dateObj) === '[object Null]') {
    return '';
  }

  if (dateObj === '') {
    return '';
  }

  const tempDateObj: Date = dateObj as Date;

  return `${tempDateObj.getFullYear()}-${tempDateObj.getMonth() + 1 < 10 ? '0' : ''}${
    tempDateObj.getMonth() + 1
  }-${tempDateObj.getDate()}`;
};

export const makeThisDatePickerCompatible = (date: string) => {
  if (date === '0000-00-00 00:00:00') {
    return new Date();
  }
  return new Date(date);
};


export const convertDateToTime = (date: string) => {
  return Intl.DateTimeFormat('en', { hour: 'numeric', minute: 'numeric' }).format(new Date(date));
};

export const convertDateToString = (date: string) => {
  return new Date(date).toLocaleDateString('en-gb', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: 'utc',
  });
};