import { IconButton, InputLabel, Paper, Stack, TextareaAutosize, TextField, Tooltip, Typography } from '@mui/material';
import { label_client, label_project, label_use_case } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import CognisaasSelect from 'components/shared/CognisaasSelect/CognisaasSelect';
import { useState } from 'react';
import InfoIcon from 'components/shared/Icons/InfoIcon';
// import CreateReport from '../CustomReports/CreateReport';
import CreateAggregatedCustomReports from '../AggregatedCustomReports/Create/CreateAggregatedCustomReports';

export const entities = ['None', label_client, label_project, label_use_case];
export type SelectedEntityStorageMethodObj = { client: string[]; project: string[]; usecase: string[]; task: string[] };

const CreateCustomReportWrapper = () => {
  const [reportName, setReportName] = useState<string>('');
  const [reportDescription, setReportDescription] = useState<string>('');
  const [topEntity, setTopEntity] = useState<string>(entities[0]);
  const [selectedEntitiesAndItsFields, setSelectedEntitiesAndItsFields] = useState<SelectedEntityStorageMethodObj>({
    client: [],
    project: [],
    usecase: [],
    task: [],
  });

  return (
    <Stack sx={{ margin: '8px 80px' }}>
      <Stack mx={1} mt={1}>
        <Typography variant="h6" sx={{ fontWeight: 500 }}>
          Create Custom Report
        </Typography>
      </Stack>

      <Stack mx={1} my={1}>
        <Paper sx={{ padding: '16px 24px 16px 0px' }} elevation={0}>
          <Stack ml={3}>
            <Stack direction={'row'} flexWrap={'wrap'}>
              <Stack my={1} mt={1} mr={3}>
                <InputLabel htmlFor="component-simple">
                  Report Name*{' '}
                  <Tooltip title="Provide a unique report name." placement="right-start">
                    <IconButton color="primary" aria-label="SMTP">
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </InputLabel>
                <TextField
                  style={{ width: '310px' }}
                  size="small"
                  placeholder={'Enter Report Name'}
                  required={true}
                  value={reportName}
                  onChange={(e: any) => setReportName(e.target.value)}
                ></TextField>
              </Stack>

              <Stack my={1} mt={1}>
                <InputLabel htmlFor="component-simple">
                  Aggregate On{' '}
                  <Tooltip title="Choose on which level this report has to be aggregated." placement="right-start">
                    <IconButton color="primary" aria-label="SMTP">
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </InputLabel>

                <CognisaasSelect
                  width="310px"
                  onChange={(e: any) => setTopEntity(e.target.value)}
                  value={topEntity}
                  validValues={[...entities]}
                />
              </Stack>
            </Stack>
            <Stack my={1} mt={1} mr={3}>
              <InputLabel htmlFor="component-simple">
                Report Description{' '}
                <Tooltip
                  title="Provide a description here which enables others to understand what information this report gives out, in case its shared."
                  placement="right-start"
                >
                  <IconButton color="primary" aria-label="SMTP">
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </InputLabel>
              <TextareaAutosize
                onChange={(e) => setReportDescription(e.target.value)}
                minRows={4}
                value={reportDescription}
                aria-label="maximum height"
                placeholder="Enter description here"
                defaultValue=""
                style={{ width: 310, borderRadius: '4px', borderColor: '#c0c0c0' }}
              />
            </Stack>
            <CreateAggregatedCustomReports
              reportName={reportName}
              reportDescription={reportDescription}
              topEntity={topEntity}
              setSelectedEntitiesAndItsFields={setSelectedEntitiesAndItsFields}
              selectedEntitiesAndItsFields={selectedEntitiesAndItsFields}
            />
          </Stack>
        </Paper>
      </Stack>
    </Stack>
  );
};

export default CreateCustomReportWrapper;
