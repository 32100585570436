import React from 'react';
import { Drawer, Typography, Stack, TextField, TextareaAutosize, Switch, Box, Tab, Tabs, Grid } from '@mui/material';
import { useState, useEffect } from 'react';
import { ReactComponent as EmptyMileStone } from '../../../../../assets/svgs/EmptyMileStone.svg';
import '../../../../../components/TaskModule/AddTask/datePickerStyle.css';
import CognisaasSelect from '../../../../../components/shared/CognisaasSelect/CognisaasSelect';
import { useDispatch } from 'react-redux';
import CognisaasButton from '../../../../../components/shared/CognisaasButton/CognisaasButton';
import { CognisaaasTooltip } from '../../../../../components/shared/CognisaasTooltip/CognisaasTooltip';
import CognisaasCalenderNew from '../../../../../components/shared/CognisaasCalenderNew/CognisaasCalenderNew';
import {
  client_updateProjecttaskbyuid,
  client_updateUsecasetaskbyuid,
  client_addProjectTaskAuditTrail,
} from '../../../../../store/modules/CustomerPortal/CustomerPortal';
import { format } from 'date-fns';
import Cookies from 'universal-cookie';
import { Formik } from 'formik';
import { customerPortalTaskSchemas } from 'utils/validations';
import { usePreselectedProjectMembers } from 'components/shared/Hooks/usePreselectedProjectMembers';
import { label_due_date, label_task } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { useAppSelector } from 'store/hooks';
import { selectclientUserInformation } from 'store/modules/CustomerPortal/selector';
import CommentHome from 'components/TaskModule/TaskView/Comment/CommentHome';
import TaskAttachment from 'components/TaskModule/TaskView/Attachments/Attachments';
import useCustomNames from 'components/shared/CustomNames/useCustomNames';
import NAMED_CONSTATNTS from 'components/shared/CustomNames/Constants';
import { getUserActiveState, INACTIVE_USER_COLOR } from 'utils/userHelpers';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1, width: '800px' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const __user_permissions = ['view', 'edit', 'delete'];

const UpdateTask = (props: any) => {
  const cookies = new Cookies();
  const dispatch = useDispatch();

  const customerUidLocal: any = cookies.get('cogni_user_uid');
  const clientUidLocal: any = cookies.get('client_uid');
  const contactUidLocal: any = cookies.get('client_contact_uid');
  const companyNameLocal: any = cookies.get('customerCompanyName');
  const [companyName, setCompanyName] = useState<any>('');
  const [clientUid, setClientUid] = useState<any>('');
  const [, setContactUid] = useState<any>('');
  const [customerUid, setCustomerUid] = useState<any>('');
  const [plannedStartDate, setPlannedStartDate] = useState(new Date());
  const [selectedSection, setSelectedSection] = useState('');
  const [selectedSectionUid, setSelectionSectionUid] = useState('');
  const [sectionListNames, setSectionListNames] = useState([]);
  const [actualStartDate, setActualStartDate] = useState(new Date());
  const [dueDate, setDueDate] = useState(new Date());
  const [etaForDelivery, setEtaForDelivery] = useState(new Date());
  const [taskName, setTaskName] = useState<string>('');
  const [stage, setStage] = useState<string>('');
  const [priority, setPriority] = useState<string>('');
  const [taskOwner, setTaskOwner] = useState<string>('Not Assigned');
  const [taskOwnerUid, settaskOwnerUid] = useState('15243');
  const [taskReporter, setTaskReporter] = useState<string>('Not Assigned');
  const [taskReporterUid, settaskReporterUid] = useState('15243');
  const [taskDescription, setTaskDesription] = useState<string>('');
  const [mileStone, setMileStone] = useState<boolean>(false);
  const [initialSectionDetail, setInitialSectionDetial] = useState('');
  const [showActualStartDate, setshowActualStartDate] = useState(false);

  const clientInformation: any = useAppSelector(selectclientUserInformation);
  const {
    taskPrioritiesDropdownValues,
    getCustomNameMappedValue,
    getStandardNameMappedValue,
    taskStagesDropdownValues,
  } = useCustomNames();
  const taskAllPriorities = taskPrioritiesDropdownValues.map((item: any) => item.custom_priority_name);
  const taskAllStages = taskStagesDropdownValues.map((item: any) => item.custom_stage_name);

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const projectUid = props?.projectUid;
  const { preSelectedCombinedMembers = [] } = usePreselectedProjectMembers(projectUid, clientUidLocal);

  useEffect(() => {
    if (taskOwner == 'Not Assigned') settaskOwnerUid('15243');
    if (preSelectedCombinedMembers?.length > 0) {
      preSelectedCombinedMembers?.map((ele: any) => {
        if (ele.user_username == taskOwner) settaskOwnerUid(ele.user_uid);
      });
    }
  }, [taskOwner]);

  const getUserUid = (username: string) => {
    let userUid = '';
    if (preSelectedCombinedMembers?.length > 0) {
      preSelectedCombinedMembers?.map((ele: any) => {
        if (ele.user_username == username) {
          userUid = ele.user_uid;
        }
      });
    } else {
      userUid = '15243';
    }
    return userUid;
  };

  useEffect(() => {
    if (taskReporter == 'Not Assigned') settaskReporterUid('15243');
    if (props?.allClientUsersApi?.length > 0) {
      props?.allClientUsersApi?.map((ele: any) => {
        if (ele.contact_name == taskReporter) settaskReporterUid(ele.contact_uid);
      });
    }
  }, [taskReporter]);

  useEffect(() => {
    console.log(stage, 'stage');
    if (stage == 'Not Started') setshowActualStartDate(false);
    else setshowActualStartDate(true);
  }, [stage]);

  useEffect(() => {
    console.log(selectedSection, 'selected Section Test');
    if (selectedSection?.length > 0) {
      console.log('reached here');
      props.sectionsList?.map((ele: any) => {
        if (ele.section_detail == selectedSection) setSelectionSectionUid(ele.section_uid);
        console.log(ele.section_detail, selectedSection, 'test for update task section uid');
      });
    }
  }, [selectedSection]);

  useEffect(() => {
    console.log(props.sectionsList, 'sec list');
    if (props.sectionsList?.length > 0) {
      const tempArr: any = [];
      props.sectionsList.map((ele: any) => {
        tempArr.push(ele.section_detail);
      });
      setSectionListNames(tempArr);
    }
  }, [props.sectionsList]);

  useEffect(() => {
    if (companyNameLocal?.length > 0) setCompanyName(companyNameLocal);
  }, [companyNameLocal]);

  useEffect(() => {
    if (customerUidLocal?.length > 0) setCustomerUid(customerUidLocal);
  }, [customerUidLocal]);

  useEffect(() => {
    if (clientUidLocal?.length > 0) setClientUid(clientUidLocal);
  }, [clientUidLocal]);

  useEffect(() => {
    if (contactUidLocal?.length > 0) setContactUid(contactUidLocal);
  }, [contactUidLocal]);

  useEffect(() => {
    console.log(props.selectedTask, 'task passed props');
    setTaskName(props?.selectedTask?.task_name);
    setStage(props?.selectedTask?.task_stage);
    console.log(props?.allClientUsersApi, ' client contt');
    if (props?.allClientUsersApi?.length > 0)
      props?.allClientUsersApi?.map((ele: any) => {
        if (ele.contact_uid == props?.selectedTask?.task_reporter) setTaskReporter(ele.contact_name);
      });
    if (preSelectedCombinedMembers.length > 0)
      preSelectedCombinedMembers.map((ele: any) => {
        if (ele.user_uid == props?.selectedTask?.task_owner) setTaskOwner(ele.user_username);
      });
    setPriority(props?.selectedTask?.task_priority);
    setPlannedStartDate(new Date(props?.selectedTask?.task_planned_start_date));
    props?.sectionsList?.map((ele: any) => {
      if (ele.section_uid == props?.selectedTask?.section_uid) {
        setSelectedSection(ele.section_detail);
        setInitialSectionDetial(ele.section_detail);
      }
      console.log(ele.section_uid, props?.selectedTask?.section_uid, 'sec test');
    });
    setDueDate(new Date(props?.selectedTask?.task_due_date));
    setEtaForDelivery(new Date(props?.selectedTask?.task_closed_date));
    setTaskDesription(props?.selectedTask?.task_description);
    setMileStone(props?.selectedTask?.milestone_task == 1 ? true : false);
    setActualStartDate(props?.selectedTask?.task_start_date);
  }, [props]);

  const onSubmitTask = () => {
    console.log('came here', props?.selectedTask);
    if (props.taskType == 'project') {
      const taskObj = {
        blocked_by_dependency: '0',
        customer_company: companyName,
        client_uid: clientUid,
        case_uid: '',
        client_check: props?.selectedTask?.client_check,
        client_initiate: props?.selectedTask?.client_initiate,
        contact_uid: props?.selectedTask?.is_team_task === '1' ? null : taskOwnerUid,
        csat_indicator: '0',
        customer_uid: customerUid,
        dependency_details: [],
        effort_estimate: '',
        jira_ind: 0,
        jira_issue_type: '',
        jira_priority: '',
        jira_project_id: '',
        jira_issue_id: '',
        jira_issue_key: '',
        jira_status_name: '',
        milestone_task: mileStone == true ? 1 : 0,
        project_uid: props?.selectedTask?.project_uid,
        section_uid: selectedSectionUid,
        task_implementation_fee_percentage: 0,
        starting_time: '',
        task_closed_date: format(etaForDelivery, 'yyyy-MM-dd HH:mm:ss'),
        task_description: taskDescription,
        task_name: taskName,
        task_due_date: format(dueDate, 'yyyy-MM-dd HH:mm:ss'),
        task_start_date: actualStartDate,
        task_owner: taskOwnerUid,
        task_percentage: 0,
        task_planned_start_date: format(plannedStartDate, 'yyyy-MM-dd HH:mm:ss'),
        task_created_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
        task_modified_on: '',
        task_priority: priority,
        task_reporter: taskReporterUid,
        task_reporter_uid: clientUid,
        task_sequence: 0,
        task_stage: stage,
        task_uid: props?.selectedTask?.task_uid,
        time_remaining: '',
        total_time_spent: '',
        is_team_task: props?.selectedTask?.is_team_task,
        last_user_modified: '',
        old_status: props?.selectedTask?.task_status,
        old_stage: props?.selectedTask?.task_stage,
        template_task_uid: '',
      };
      dispatch(client_updateProjecttaskbyuid(taskObj));
      const auditTrailReqObj = {
        task_uid: props?.selectedTask?.task_uid,
        user_uid: cookies.get('cogni_user_uid'),
        modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
        field: 'Created',
        old_value: props?.selectedTask?.task_stage,
        new_value: stage,
      };
      dispatch(client_addProjectTaskAuditTrail(auditTrailReqObj));
      setTimeout(
        () => dispatch(props.client_getsingleProjectInformationWithTaskClientUser(props?.selectedTask?.project_uid)),
        500
      );
      props.setOpenUpdateTaskDrawer(false);
    }
    if (props.taskType == 'useCase') {
      console.log(actualStartDate, dueDate);
      const taskObj = {
        blocked_by_dependency: '0',
        customer_company: companyName,
        client_uid: clientUid,
        case_uid: props?.selectedTask?.case_uid,
        client_check: props?.selectedTask?.client_check,
        client_initiate: props?.selectedTask?.client_initiate,
        contact_uid: props?.selectedTask?.is_team_task === '1' ? null : taskOwnerUid,
        csat_indicator: '0',
        customer_uid: customerUid,
        dependency_details: [],
        effort_estimate: '',
        jira_ind: 0,
        jira_issue_type: '',
        jira_priority: '',
        jira_project_id: '',
        jira_issue_id: '',
        jira_issue_key: '',
        jira_status_name: '',
        milestone_task: mileStone == true ? 1 : 0,
        project_uid: '',
        section_uid: selectedSectionUid,
        task_implementation_fee_percentage: 0,
        starting_time: '',
        task_closed_date: format(etaForDelivery, 'yyyy-MM-dd HH:mm:ss'),
        task_description: taskDescription,
        task_name: taskName,
        task_due_date: format(dueDate, 'yyyy-MM-dd HH:mm:ss'),
        task_start_date: actualStartDate,
        task_owner: taskOwnerUid,
        task_percentage: 0,
        task_planned_start_date: format(plannedStartDate, 'yyyy-MM-dd HH:mm:ss'),
        task_created_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
        task_modified_on: '',
        task_priority: priority,
        task_reporter: taskReporterUid,
        task_reporter_uid: clientUid,
        task_sequence: 0,
        task_stage: stage,
        template_task_uid: '',
        time_remaining: '',
        total_time_spent: '',
        is_team_task: props?.selectedTask?.is_team_task,
        last_user_modified: '',
        old_status: props?.selectedTask?.task_status,
        old_stage: props?.selectedTask?.task_stage,
        task_uid: props?.selectedTask?.task_uid,
      };

      dispatch(client_updateUsecasetaskbyuid(taskObj));
      setTimeout(
        () => dispatch(props.client_getsingleUsecaseInformationWithTaskClientUser(props.selectedTask.case_uid)),
        500
      );
      props.setOpenUpdateTaskDrawer(false);
    }
  };

  const notAssignedStr = 'Not Assigned';
  const preselectedCombinedMembersNameListWithNotAssigned = preSelectedCombinedMembers?.length
    ? [
      ...preSelectedCombinedMembers.filter((user: any) => user.is_active === '1').map((item: any) => item.name),
      notAssignedStr,
    ]
    : [notAssignedStr];

  return (
    <Drawer
      open={props.openUpdateTaskDrawer}
      onClose={() => {
        props.setOpenUpdateTaskDrawer(false);
      }}
      sx={{
        '.MuiPaper-root': {
          width: '850px',
          overflowX: 'hidden',
        },
      }}
      anchor="right"
    >
      <Formik
        initialValues={{
          taskName: props?.selectedTask?.task_name,
          sectionName: initialSectionDetail,
        }}
        onSubmit={(values: any) => {
          console.log(values, 'values');
          // handleSubmit(values);
        }}
        validationSchema={customerPortalTaskSchemas}
      >
        {(formik: any) => (
          <Grid container>
            <Typography mb={1} m={2} sx={{ marginTop: '77px' }} variant="h2">
              Update {label_task}
            </Typography>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '105%' }}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Details" {...a11yProps(0)} />
                <Tab label="Comments" {...a11yProps(1)} />
                <Tab label="Attachments" {...a11yProps(2)} />
              </Tabs>
            </Box>

            <TabPanel value={value} index={0}>
              <Stack m={2} spacing={3} direction="column">
                <Stack>
                  <Typography variant="subtitle2">{label_task} Name</Typography>
                  <TextField
                    autoComplete="off"
                    id="taskName"
                    name="taskName"
                    value={formik.values.taskName}
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      formik.setFieldValue('taskName', e.target.value);
                      setTaskName(e.target.value);
                    }}
                    autoFocus={true}
                    style={{ width: '220px' }}
                    size="small"
                    placeholder={`Enter ${label_task} name`}
                    error={formik.touched.taskName && Boolean(formik.errors.taskName)}
                    helperText={formik.touched.taskName && formik.errors.taskName}
                  ></TextField>
                </Stack>
                <Stack spacing={2} direction="row">
                  <Stack>
                    <Typography variant="subtitle2">{label_task} Stage</Typography>
                    <CognisaasSelect
                      onChange={(e: any) =>
                        setStage(getStandardNameMappedValue(e.target.value, NAMED_CONSTATNTS.task_stage_key))
                      }
                      value={getCustomNameMappedValue(stage, NAMED_CONSTATNTS.task_stage_key)}
                      validValues={taskAllStages}
                      width={'220px'}
                    ></CognisaasSelect>
                  </Stack>
                  <Stack>
                    <Typography variant="subtitle2">Priority</Typography>
                    <CognisaasSelect
                      onChange={(e: any) =>
                        setPriority(getStandardNameMappedValue(e.target.value, NAMED_CONSTATNTS.task_priority_key))
                      }
                      value={getCustomNameMappedValue(priority, NAMED_CONSTATNTS.task_priority_key)}
                      validValues={taskAllPriorities}
                      width={'220px'}
                    ></CognisaasSelect>
                  </Stack>
                  <Stack>
                    <Typography variant="subtitle2">{label_task} Owner</Typography>
                    <CognisaasSelect
                      value={
                        getUserActiveState(getUserUid(taskOwner), preSelectedCombinedMembers) === '0'
                          ? `${taskOwner} (Inactive)`
                          : taskOwner
                      }
                      sx={
                        getUserActiveState(getUserUid(taskOwner), preSelectedCombinedMembers) === '0'
                          ? { color: INACTIVE_USER_COLOR }
                          : {}
                      }
                      onChange={(e: any) => setTaskOwner(e.target.value)}
                      validValues={preselectedCombinedMembersNameListWithNotAssigned}
                      width={'220px'}
                    ></CognisaasSelect>
                  </Stack>
                </Stack>
                <Stack spacing={2} direction="row">
                  <Stack>
                    <Typography variant="subtitle2">{label_task} Reporter</Typography>
                    <CognisaasSelect
                      value={taskReporter}
                      onChange={(e: any) => setTaskReporter(e.target.value)}
                      validValues={props?.clientList}
                      width={'220px'}
                    ></CognisaasSelect>
                  </Stack>
                  <Stack>
                    <Typography variant="subtitle2">Planned Start Date*</Typography>
                    <Stack className="customDatePickerWidth">
                      <CognisaasCalenderNew
                        value={plannedStartDate}
                        handleChange={(date: Date) => setPlannedStartDate(date)}
                        width={'220px'}
                      />
                    </Stack>
                  </Stack>
                  <Stack>
                    <Typography variant="subtitle2">{label_due_date}*</Typography>
                    <CognisaasCalenderNew
                      value={dueDate}
                      handleChange={(date: Date) => setDueDate(date)}
                      width={'220px'}
                    />
                  </Stack>
                </Stack>
                <Stack spacing={2} direction="row">
                  {props?.selectedTask?.client_initiate == 1 ? (
                    <Stack>
                      <Typography variant="subtitle2">Select Phase</Typography>
                      <CognisaasSelect
                        value={formik.values.sectionName}
                        id="sectionName"
                        name="sectionName"
                        validValues={sectionListNames}
                        onBlur={formik.handleBlur}
                        onChange={(e: any) => {
                          formik.setFieldValue('sectionName', e.target.value);
                          setSelectedSection(e.target.value);
                        }}
                        width={'220px'}
                        error={formik.touched.sectionName && Boolean(formik.errors.sectionName)}
                        helperText={formik.touched.sectionName && formik.errors.sectionName}
                      ></CognisaasSelect>
                    </Stack>
                  ) : null}

                  {showActualStartDate == true ? (
                    <Stack>
                      <React.Fragment>
                        <Typography variant="subtitle2">Actual Start Date</Typography>
                        <CognisaasCalenderNew
                          value={actualStartDate}
                          handleChange={(date: Date) => {
                            console.log(date, ' test for actual start date');
                            setActualStartDate(date);
                          }}
                          width={'220px'}
                        />
                      </React.Fragment>
                    </Stack>
                  ) : (
                    <CognisaaasTooltip
                      title={
                        <Typography variant="subtitle1">
                          {label_task} Start date will be system generated when this {label_task.toLowerCase()} moves to
                          In progress
                        </Typography>
                      }
                    >
                      <Stack>
                        <React.Fragment>
                          <Typography variant="subtitle2">Actual Start Date</Typography>
                          {props?.selectedTask?.client_initiate == 1 ? (
                            <CognisaasCalenderNew
                              value={actualStartDate}
                              handleChange={(date: Date) => setActualStartDate(date)}
                              disabled={true}
                              width={'220px'}
                            />
                          ) : (
                            <CognisaasCalenderNew
                              width={'220px'}
                              value={actualStartDate}
                              handleChange={(date: Date) => setActualStartDate(date)}
                              disabled={true}
                            />
                          )}
                        </React.Fragment>
                      </Stack>
                    </CognisaaasTooltip>
                  )}
                  <Stack>
                    <Typography variant="subtitle2">ETA for delivery</Typography>
                    <CognisaasCalenderNew
                      value={etaForDelivery}
                      handleChange={(date: Date) => setEtaForDelivery(date)}
                      width={'220px'}
                    />
                  </Stack>
                </Stack>
                <Stack direction="row">
                  <Stack direction="column">
                    <Typography variant="subtitle2">{label_task} Description</Typography>
                    <TextareaAutosize
                      onChange={(e) => setTaskDesription(e.target.value)}
                      maxRows={15}
                      value={taskDescription}
                      aria-label="maximum height"
                      placeholder="Enter description here"
                      defaultValue=""
                      style={{ width: 420, height: 88 }}
                    />
                  </Stack>
                </Stack>
                <Stack direction="row" spacing={6}>
                  <Stack>
                    <Stack spacing={1} direction="row">
                      <EmptyMileStone />
                      <Typography variant="subtitle2">Its a milestone</Typography>
                    </Stack>
                    <Stack>
                      <Switch
                        checked={mileStone}
                        onChange={() => {
                          setMileStone(!mileStone);
                          // if it is a milestone task, then set csat indicator onn
                        }}
                      ></Switch>
                    </Stack>
                  </Stack>
                </Stack>
                <Stack spacing={2} justifyContent={'end'} direction="row">
                  <Stack mb={2} spacing={1} direction="row">
                    <CognisaasButton
                      clickHandler={() => props.setOpenUpdateTaskDrawer(false)}
                      isOutlined={true}
                      label="Cancel"
                    />
                    <CognisaasButton clickHandler={onSubmitTask} isOutlined={false} label="Save" />
                  </Stack>
                </Stack>
              </Stack>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <CommentHome
                clientDetail={clientInformation}
                taskDetail={props.selectedTask}
                isCustomerPortal={true}
                customerPortalClientUserName={cookies.get('client_user_username')}
              />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <TaskAttachment
                data={props.selectedTask}
                clientDetail={clientInformation}
                userPermissions={__user_permissions}
              />
            </TabPanel>
          </Grid>
        )}
      </Formik>
    </Drawer>
  );
};

export default UpdateTask;
