import { Drawer, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box } from '@mui/system';
import './style.module.css';
import { styles } from './styles.module';
import { Link, useLocation } from 'react-router-dom';

// logos
import { ReactComponent as Home } from '../../assets/svgs/home.svg';
import { ReactComponent as Client } from '../../assets/svgs/Client.svg';
import { ReactComponent as Project } from '../../assets/svgs/Projects.svg';
import { ReactComponent as ResourceManagement } from '../../assets/svgs/ResourceMapping.svg';
import { ReactComponent as Products } from '../../assets/svgs/products.svg';
import { ReactComponent as ActionableInsights } from '../../assets/svgs/actionableInsights.svg';
import { ReactComponent as Reports } from '../../assets/svgs/reports.svg';
import { ReactComponent as Templates } from '../../assets/svgs/Templates.svg';
import { ReactComponent as Setting } from '../../assets/svgs/Settings.svg';
import { ReactComponent as Dashboard } from '../../assets/svgs/dashboard.svg';
import { useState, useEffect } from 'react';
import usePageAccess from 'components/shared/Hooks/usePageAccess';
import { isRbacEnabled } from '../../utils/getUid';
import Cookies from 'universal-cookie';
import { label_client, label_project, label_task } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { useAppSelector } from 'store/hooks';
import { selectAllCustomLabels } from 'store/modules/Common/selector';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';

const SideBar = (props: any) => {
  const cookies = new Cookies();
  const [isCustomer, setIsCustomer] = useState(false);
  const isCustomerPortal = cookies.get('isCustomerPortal');
  const pagesUserHasAccessTo = usePageAccess();

  console.log(pagesUserHasAccessTo);

  const theme = useTheme();
  const notMobileScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const largeHeightScreens = useMediaQuery('(min-height: 795px)');
  const { pathname } = useLocation();
  const allCustomLabelsUpdate = useAppSelector(selectAllCustomLabels);
  const [u, updateSidebar] = useState<number>(0);

  // const styledTabBarSx = largeHeightScreens ? styles.tabs : styles.Sidebar__smallerTabs;
  const styledTabBarSx = largeHeightScreens ? styles.Sidebar__smallerTabs : styles.Sidebar__smallerTabs;

  console.log(isCustomer, 'customer check');

  useEffect(() => {
    console.log(isCustomerPortal, ' === isCustomerPortal');
    if (isCustomerPortal == 'true') setIsCustomer(true);
    else setIsCustomer(false);
  }, [cookies]);

  useEffect(() => {
    console.log('sidebarrrrrr', label_client, label_project);
    updateSidebar((prev) => ++prev);
    console.log('update', u);
    // refreshLabelCookies();
  }, [allCustomLabelsUpdate]);

  return (
    <Drawer
      open={notMobileScreen ? true : props.isDrawerOpen}
      sx={styles.drawer}
      PaperProps={{
        sx: styles.drawerPaper,
      }}
      variant="persistent"
    >
      <List sx={styles.list}>
        {/* home */}
        {!isCustomer && (
          <>
            {isRbacEnabled() ? (
              <>
                {pagesUserHasAccessTo.includes('/home') ? (
                  <Link to="/" style={styles.Sidebar__tabLinks}>
                    <ListItemButton
                      sx={pathname === '/' ? [styledTabBarSx, styles.Sidebar__activeTab] : styledTabBarSx}
                      onClick={() => props.setIsDrawerOpen(!props.isDrawerOpen)}
                    >
                      <Box component="span" sx={styles.Sidebar__insideTab}>
                        <Home />
                        <ListItemIcon sx={styles.eachTab}>
                          <ListItemText sx={styles.listText}>
                            <span style={{ fontSize: '12px' }}>Home</span>
                          </ListItemText>
                        </ListItemIcon>
                      </Box>
                    </ListItemButton>
                  </Link>
                ) : null}
              </>
            ) : (
              <Link to="/" style={styles.Sidebar__tabLinks}>
                <ListItemButton
                  sx={pathname === '/' ? [styledTabBarSx, styles.Sidebar__activeTab] : styledTabBarSx}
                  onClick={() => props.setIsDrawerOpen(!props.isDrawerOpen)}
                >
                  <Box component="span" sx={styles.Sidebar__insideTab}>
                    <Home />
                    <ListItemIcon sx={styles.eachTab}>
                      <ListItemText sx={styles.listText}>
                        <span style={{ fontSize: '12px' }}>Home</span>
                      </ListItemText>
                    </ListItemIcon>
                  </Box>
                </ListItemButton>
              </Link>
            )}
          </>
        )}

        {/* dashboard */}
        {!isCustomer ? (
          <>
            {isRbacEnabled() ? (
              <>
                {pagesUserHasAccessTo.includes('/dashboard') ? (
                  <Link to="/dashboard" style={styles.Sidebar__tabLinks}>
                    <ListItemButton
                      sx={pathname === '/dashboard' ? [styledTabBarSx, styles.Sidebar__activeTab] : styledTabBarSx}
                      onClick={() => props.setIsDrawerOpen(!props.isDrawerOpen)}
                    >
                      <Box component="span" sx={styles.Sidebar__insideTab}>
                        <Dashboard />
                        <ListItemIcon sx={styles.eachTab}>
                          <ListItemText sx={styles.listText}>
                            <span style={{ fontSize: '12px' }}>Dashboard</span>
                          </ListItemText>
                        </ListItemIcon>
                      </Box>
                    </ListItemButton>
                  </Link>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <Link to="/dashboard" style={styles.Sidebar__tabLinks}>
                <ListItemButton
                  sx={pathname === '/dashboard' ? [styledTabBarSx, styles.Sidebar__activeTab] : styledTabBarSx}
                  onClick={() => props.setIsDrawerOpen(!props.isDrawerOpen)}
                >
                  <Box component="span" sx={styles.Sidebar__insideTab}>
                    <Dashboard />
                    <ListItemIcon sx={styles.eachTab}>
                      <ListItemText sx={styles.listText}>
                        <span style={{ fontSize: '12px' }}>Dashboard</span>
                      </ListItemText>
                    </ListItemIcon>
                  </Box>
                </ListItemButton>
              </Link>
            )}
          </>
        ) : (
          <Link to="/customer-portal/dashboard" style={styles.Sidebar__tabLinks}>
            <ListItemButton
              sx={pathname === '/dashboard' ? [styledTabBarSx, styles.Sidebar__activeTab] : styledTabBarSx}
              onClick={() => props.setIsDrawerOpen(!props.isDrawerOpen)}
            >
              <Box component="span" sx={styles.Sidebar__insideTab}>
                <Dashboard />
                <ListItemIcon sx={styles.eachTab}>
                  <ListItemText sx={styles.listText}>
                    <span style={{ fontSize: '12px' }}>Dashboard</span>
                  </ListItemText>
                </ListItemIcon>
              </Box>
            </ListItemButton>
          </Link>
        )}

        {/* actionable insight */}

        {!isCustomer && (
          <>
            {isRbacEnabled() ? (
              <>
                {pagesUserHasAccessTo.includes('/actionable-insights') ? (
                  <Link to="/actionable-insights" style={styles.Sidebar__tabLinks}>
                    <ListItemButton
                      sx={
                        pathname === '/actionable-insights'
                          ? [styledTabBarSx, styles.Sidebar__activeTab]
                          : styledTabBarSx
                      }
                      onClick={() => props.setIsDrawerOpen(!props.isDrawerOpen)}
                    >
                      <Box component="span" sx={styles.Sidebar__insideTab}>
                        <ActionableInsights />
                        <ListItemIcon sx={styles.eachTab}>
                          <ListItemText className="Sidebar__largeText" sx={styles.listText}>
                            <Box component="span" sx={styles.longListText}>
                              <span style={{ fontSize: '12px', position: 'relative', top: '6px' }}>Actionable</span>
                              <span style={{ fontSize: '12px' }}>Insights</span>
                            </Box>
                          </ListItemText>
                        </ListItemIcon>
                      </Box>
                    </ListItemButton>
                  </Link>
                ) : null}
              </>
            ) : (
              <Link to="/actionable-insights" style={styles.Sidebar__tabLinks}>
                <ListItemButton
                  sx={
                    pathname === '/actionable-insights' ? [styledTabBarSx, styles.Sidebar__activeTab] : styledTabBarSx
                  }
                  onClick={() => props.setIsDrawerOpen(!props.isDrawerOpen)}
                >
                  <Box component="span" sx={styles.Sidebar__insideTab}>
                    <ActionableInsights />
                    <ListItemIcon sx={styles.eachTab}>
                      <ListItemText className="Sidebar__largeText" sx={styles.listText}>
                        <Box component="span" sx={styles.longListText}>
                          <span style={{ fontSize: '12px', position: 'relative', top: '6px' }}>Actionable</span>
                          <span style={{ fontSize: '12px' }}>Insights</span>
                        </Box>
                      </ListItemText>
                    </ListItemIcon>
                  </Box>
                </ListItemButton>
              </Link>
            )}
          </>
        )}

        {/* clients */}
        {!isCustomer && (
          <>
            {isRbacEnabled() ? (
              <>
                {pagesUserHasAccessTo.includes('/clients') ? (
                  <Link to="/client-list" style={styles.Sidebar__tabLinks}>
                    <ListItemButton
                      sx={pathname === '/client-list' ? [styledTabBarSx, styles.Sidebar__activeTab] : styledTabBarSx}
                      onClick={() => props.setIsDrawerOpen(!props.isDrawerOpen)}
                    >
                      <Box component="span" sx={styles.Sidebar__insideTab}>
                        <Client />
                        <ListItemIcon sx={styles.eachTab}>
                          {label_client?.length < 11 ? (
                            <ListItemText sx={styles.listText}>
                              <span style={{ fontSize: '12px' }}>{label_client}s</span>
                            </ListItemText>
                          ) : (
                            <ListItemText className="Sidebar__largeText" sx={styles.listText}>
                              <Box component="span" sx={styles.longListText}>
                                <span style={{ fontSize: '12px', position: 'relative', top: '6px' }}>
                                  {label_client?.split(' ')[0]}
                                </span>
                                <span style={{ fontSize: '12px' }}>{label_client?.split(' ')[1]}s</span>
                              </Box>
                            </ListItemText>
                          )}
                        </ListItemIcon>
                      </Box>
                    </ListItemButton>
                  </Link>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <Link to="/client-list" style={styles.Sidebar__tabLinks}>
                <ListItemButton
                  sx={pathname === '/client-list' ? [styledTabBarSx, styles.Sidebar__activeTab] : styledTabBarSx}
                  onClick={() => props.setIsDrawerOpen(!props.isDrawerOpen)}
                >
                  <Box component="span" sx={styles.Sidebar__insideTab}>
                    <Client />
                    <ListItemIcon sx={styles.eachTab}>
                      {label_client?.length < 11 ? (
                        <ListItemText sx={styles.listText}>
                          <span style={{ fontSize: '12px' }}>{label_client}s</span>
                        </ListItemText>
                      ) : (
                        <ListItemText className="Sidebar__largeText" sx={styles.listText}>
                          <Box component="span" sx={styles.longListText}>
                            <span style={{ fontSize: '12px', position: 'relative', top: '6px' }}>
                              {label_client?.split(' ')[0]}
                            </span>
                            <span style={{ fontSize: '12px' }}>{label_client?.split(' ')[1]}s</span>
                          </Box>
                        </ListItemText>
                      )}
                    </ListItemIcon>
                  </Box>
                </ListItemButton>
              </Link>
            )}
          </>
        )}

        {/* project */}
        {!isCustomer ? (
          <>
            {isRbacEnabled() ? (
              <>
                {pagesUserHasAccessTo.includes('/project') ? (
                  <Link to="/projects" style={styles.Sidebar__tabLinks}>
                    <ListItemButton
                      sx={pathname === '/projects' ? [styledTabBarSx, styles.Sidebar__activeTab] : styledTabBarSx}
                      onClick={() => props.setIsDrawerOpen(!props.isDrawerOpen)}
                    >
                      <Box component="span" sx={styles.Sidebar__insideTab}>
                        <Project />
                        <ListItemIcon sx={styles.eachTab}>
                          {label_project?.length < 11 ? (
                            <ListItemText sx={styles.listText}>
                              <span style={{ fontSize: '12px' }}>{label_project}s</span>
                            </ListItemText>
                          ) : (
                            <ListItemText className="Sidebar__largeText" sx={styles.listText}>
                              <Box component="span" sx={styles.longListText}>
                                <span style={{ fontSize: '12px', position: 'relative', top: '6px' }}>
                                  {label_project?.split(' ')[0]}
                                </span>
                                <span style={{ fontSize: '12px' }}>{label_project?.split(' ')[1]}s</span>
                              </Box>
                            </ListItemText>
                          )}
                        </ListItemIcon>
                      </Box>
                    </ListItemButton>
                  </Link>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <Link to="/projects" style={styles.Sidebar__tabLinks}>
                <ListItemButton
                  sx={pathname === '/projects' ? [styledTabBarSx, styles.Sidebar__activeTab] : styledTabBarSx}
                  onClick={() => props.setIsDrawerOpen(!props.isDrawerOpen)}
                >
                  <Box component="span" sx={styles.Sidebar__insideTab}>
                    <Project />
                    <ListItemIcon sx={styles.eachTab}>
                      {label_project?.length < 11 ? (
                        <ListItemText sx={styles.listText}>
                          <span style={{ fontSize: '12px' }}>{label_project}s</span>
                        </ListItemText>
                      ) : (
                        <ListItemText className="Sidebar__largeText" sx={styles.listText}>
                          <Box component="span" sx={styles.longListText}>
                            <span style={{ fontSize: '12px', position: 'relative', top: '6px' }}>
                              {label_project?.split(' ')[0]}
                            </span>
                            <span style={{ fontSize: '12px' }}>{label_project?.split(' ')[1]}s</span>
                          </Box>
                        </ListItemText>
                      )}
                    </ListItemIcon>
                  </Box>
                </ListItemButton>
              </Link>
            )}
          </>
        ) : (
          <Link to="/customer-portal/project" style={styles.Sidebar__tabLinks}>
            <ListItemButton
              sx={pathname === '/project' ? [styledTabBarSx, styles.Sidebar__activeTab] : styledTabBarSx}
              onClick={() => props.setIsDrawerOpen(!props.isDrawerOpen)}
            >
              <Box component="span" sx={styles.Sidebar__insideTab}>
                <Project />
                <ListItemIcon sx={styles.eachTab}>
                  {label_project?.length < 11 ? (
                    <ListItemText sx={styles.listText}>
                      <span style={{ fontSize: '12px' }}>{label_project}s</span>
                    </ListItemText>
                  ) : (
                    <ListItemText className="Sidebar__largeText" sx={styles.listText}>
                      <Box component="span" sx={styles.longListText}>
                        <span style={{ fontSize: '12px', position: 'relative', top: '6px' }}>
                          {label_project?.split(' ')[0]}
                        </span>
                        <span style={{ fontSize: '12px' }}>{label_project?.split(' ')[1]}s</span>
                      </Box>
                    </ListItemText>
                  )}
                </ListItemIcon>
              </Box>
            </ListItemButton>
          </Link>
        )}

        {/* task dashboard */}
        {!isCustomer ? (
          <>
            {isRbacEnabled() ? (
              <>
                {pagesUserHasAccessTo.includes('/tasks') ? (
                  <Link to="/tasks" style={styles.Sidebar__tabLinks}>
                    <ListItemButton
                      sx={pathname === '/tasks' ? [styledTabBarSx, styles.Sidebar__activeTab] : styledTabBarSx}
                      onClick={() => props.setIsDrawerOpen(!props.isDrawerOpen)}
                    >
                      <Box component="span" sx={styles.Sidebar__insideTab}>
                        <AssignmentOutlinedIcon style={{ transform: 'Scale(1.15)' }} />
                        <ListItemIcon sx={styles.eachTab}>
                          {label_task?.length < 11 ? (
                            <ListItemText sx={styles.listText}>
                              <span style={{ fontSize: '12px' }}>{label_task}s</span>
                            </ListItemText>
                          ) : (
                            <ListItemText className="Sidebar__largeText" sx={styles.listText}>
                              <Box component="span" sx={styles.longListText}>
                                <span style={{ fontSize: '12px', position: 'relative', top: '6px' }}>
                                  {label_task?.split(' ')[0]}
                                </span>
                                <span style={{ fontSize: '12px' }}>{label_task?.split(' ')[1]}s</span>
                              </Box>
                            </ListItemText>
                          )}
                        </ListItemIcon>
                      </Box>
                    </ListItemButton>
                  </Link>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <Link to="/tasks" style={styles.Sidebar__tabLinks}>
                <ListItemButton
                  sx={pathname === '/tasks' ? [styledTabBarSx, styles.Sidebar__activeTab] : styledTabBarSx}
                  onClick={() => props.setIsDrawerOpen(!props.isDrawerOpen)}
                >
                  <Box component="span" sx={styles.Sidebar__insideTab}>
                    <AssignmentOutlinedIcon style={{ transform: 'Scale(1.15)' }} />
                    <ListItemIcon sx={styles.eachTab}>
                      {label_task?.length < 11 ? (
                        <ListItemText sx={styles.listText}>
                          <span style={{ fontSize: '12px' }}>{label_task}s</span>
                        </ListItemText>
                      ) : (
                        <ListItemText className="Sidebar__largeText" sx={styles.listText}>
                          <Box component="span" sx={styles.longListText}>
                            <span style={{ fontSize: '12px', position: 'relative', top: '6px' }}>
                              {label_task?.split(' ')[0]}
                            </span>
                            <span style={{ fontSize: '12px' }}>{label_task?.split(' ')[1]}s</span>
                          </Box>
                        </ListItemText>
                      )}
                    </ListItemIcon>
                  </Box>
                </ListItemButton>
              </Link>
            )}
          </>
        ) : (
          <></>
        )}

        {/* resource management */}
        {!isCustomer && (
          <>
            {isRbacEnabled() ? (
              <>
                {pagesUserHasAccessTo.includes('/resource-management') ? (
                  <Link to="/resource-management" style={styles.Sidebar__tabLinks}>
                    <ListItemButton
                      sx={
                        pathname === '/resource-management'
                          ? [styledTabBarSx, styles.Sidebar__activeTab]
                          : styledTabBarSx
                      }
                      onClick={() => props.setIsDrawerOpen(!props.isDrawerOpen)}
                    >
                      <Box component="span" sx={styles.Sidebar__insideTab}>
                        <ResourceManagement />
                        <ListItemIcon sx={styles.eachTab}>
                          <ListItemText id="Sidebar__largeText" sx={styles.listText}>
                            <Box component="span" sx={styles.longListText}>
                              <span style={{ fontSize: '12px', position: 'relative', top: '6px' }}>Resource</span>
                              <span style={{ fontSize: '12px' }}>Management</span>
                            </Box>
                          </ListItemText>
                        </ListItemIcon>
                      </Box>
                    </ListItemButton>
                  </Link>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <Link to="/resource-management" style={styles.Sidebar__tabLinks}>
                <ListItemButton
                  sx={
                    pathname === '/resource-management' ? [styledTabBarSx, styles.Sidebar__activeTab] : styledTabBarSx
                  }
                  onClick={() => props.setIsDrawerOpen(!props.isDrawerOpen)}
                >
                  <Box component="span" sx={styles.Sidebar__insideTab}>
                    <ResourceManagement />
                    <ListItemIcon sx={styles.eachTab}>
                      <ListItemText id="Sidebar__largeText" sx={styles.listText}>
                        <Box component="span" sx={styles.longListText}>
                          <span style={{ fontSize: '12px', position: 'relative', top: '6px' }}>Resource</span>
                          <span style={{ fontSize: '12px' }}>Management</span>
                        </Box>
                      </ListItemText>
                    </ListItemIcon>
                  </Box>
                </ListItemButton>
              </Link>
            )}
          </>
        )}

        {/* products */}
        {!isCustomer && (
          <>
            {isRbacEnabled() ? (
              <>
                {pagesUserHasAccessTo.includes('/products') ? (
                  <Link to="/products" style={styles.Sidebar__tabLinks}>
                    <ListItemButton
                      sx={pathname === '/products' ? [styledTabBarSx, styles.Sidebar__activeTab] : styledTabBarSx}
                      onClick={() => props.setIsDrawerOpen(!props.isDrawerOpen)}
                    >
                      <Box component="span" sx={styles.Sidebar__insideTab}>
                        <Products />
                        <ListItemIcon sx={styles.eachTab}>
                          <ListItemText sx={styles.listText}>
                            <span style={{ fontSize: '12px' }}>Products</span>
                          </ListItemText>
                        </ListItemIcon>
                      </Box>
                    </ListItemButton>
                  </Link>
                ) : null}
              </>
            ) : (
              <Link to="/products" style={styles.Sidebar__tabLinks}>
                <ListItemButton
                  sx={pathname === '/products' ? [styledTabBarSx, styles.Sidebar__activeTab] : styledTabBarSx}
                  onClick={() => props.setIsDrawerOpen(!props.isDrawerOpen)}
                >
                  <Box component="span" sx={styles.Sidebar__insideTab}>
                    <Products />
                    <ListItemIcon sx={styles.eachTab}>
                      <ListItemText sx={styles.listText}>
                        <span style={{ fontSize: '12px' }}>Products</span>
                      </ListItemText>
                    </ListItemIcon>
                  </Box>
                </ListItemButton>
              </Link>
            )}
          </>
        )}

        {/* reports */}
        {!isCustomer && (
          <>
            {isRbacEnabled() ? (
              <>
                {pagesUserHasAccessTo.includes('/reports') ? (
                  <Link to="/reports" style={styles.Sidebar__tabLinks}>
                    <ListItemButton
                      sx={pathname === '/reports' ? [styledTabBarSx, styles.Sidebar__activeTab] : styledTabBarSx}
                      onClick={() => props.setIsDrawerOpen(!props.isDrawerOpen)}
                    >
                      <Box component="span" sx={styles.Sidebar__insideTab}>
                        <Reports />
                        <ListItemIcon sx={styles.eachTab}>
                          <ListItemText sx={styles.listText}>
                            <span style={{ fontSize: '12px' }}>Reports</span>
                          </ListItemText>
                        </ListItemIcon>
                      </Box>
                    </ListItemButton>
                  </Link>
                ) : null}
              </>
            ) : (
              <Link to="/reports" style={styles.Sidebar__tabLinks}>
                <ListItemButton
                  sx={pathname === '/reports' ? [styledTabBarSx, styles.Sidebar__activeTab] : styledTabBarSx}
                  onClick={() => props.setIsDrawerOpen(!props.isDrawerOpen)}
                >
                  <Box component="span" sx={styles.Sidebar__insideTab}>
                    <Reports />
                    <ListItemIcon sx={styles.eachTab}>
                      <ListItemText sx={styles.listText}>
                        <span style={{ fontSize: '12px' }}>Reports</span>
                      </ListItemText>
                    </ListItemIcon>
                  </Box>
                </ListItemButton>
              </Link>
            )}
          </>
        )}

        {/* templates */}
        {!isCustomer && (
          <>
            {isRbacEnabled() ? (
              <>
                {pagesUserHasAccessTo.includes('/templates') ? (
                  <Link to="/templates" style={styles.Sidebar__tabLinks}>
                    <ListItemButton
                      sx={pathname === '/templates' ? [styledTabBarSx, styles.Sidebar__activeTab] : styledTabBarSx}
                      onClick={() => props.setIsDrawerOpen(!props.isDrawerOpen)}
                    >
                      <Box component="span" sx={styles.Sidebar__insideTab}>
                        <Templates />
                        <ListItemIcon sx={styles.eachTab}>
                          <ListItemText sx={styles.listText}>
                            <span style={{ fontSize: '12px' }}>Templates</span>
                          </ListItemText>
                        </ListItemIcon>
                      </Box>
                    </ListItemButton>
                  </Link>
                ) : null}
              </>
            ) : (
              <Link to="/templates" style={styles.Sidebar__tabLinks}>
                <ListItemButton
                  sx={pathname === '/templates' ? [styledTabBarSx, styles.Sidebar__activeTab] : styledTabBarSx}
                  onClick={() => props.setIsDrawerOpen(!props.isDrawerOpen)}
                >
                  <Box component="span" sx={styles.Sidebar__insideTab}>
                    <Templates />
                    <ListItemIcon sx={styles.eachTab}>
                      <ListItemText sx={styles.listText}>
                        <span style={{ fontSize: '12px' }}>Templates</span>
                      </ListItemText>
                    </ListItemIcon>
                  </Box>
                </ListItemButton>
              </Link>
            )}
          </>
        )}

        {/* settings */}
        {!isCustomer && (
          <>
            {isRbacEnabled() ? (
              <>
                {pagesUserHasAccessTo.includes('/settings') ? (
                  <Link to="/settings/profile" style={styles.Sidebar__tabLinks}>
                    <ListItemButton
                      sx={
                        ['/settings', '/settings/profile'].includes(pathname)
                          ? [styledTabBarSx, styles.Sidebar__activeTab]
                          : styledTabBarSx
                      }
                      onClick={() => props.setIsDrawerOpen(!props.isDrawerOpen)}
                    >
                      <Box component="span" sx={styles.Sidebar__insideTab}>
                        <Setting />
                        <ListItemIcon sx={styles.eachTab}>
                          <ListItemText sx={styles.listText}>
                            <span style={{ fontSize: '12px' }}>Settings</span>
                          </ListItemText>
                        </ListItemIcon>
                      </Box>
                    </ListItemButton>
                  </Link>
                ) : null}
              </>
            ) : (
              <Link to="/settings/profile" style={styles.Sidebar__tabLinks}>
                <ListItemButton
                  sx={
                    ['/settings', '/settings/profile'].includes(pathname)
                      ? [styledTabBarSx, styles.Sidebar__activeTab]
                      : styledTabBarSx
                  }
                  onClick={() => props.setIsDrawerOpen(!props.isDrawerOpen)}
                >
                  <Box component="span" sx={styles.Sidebar__insideTab}>
                    <Setting />
                    <ListItemIcon sx={styles.eachTab}>
                      <ListItemText sx={styles.listText}>
                        <span style={{ fontSize: '12px' }}>Settings</span>
                      </ListItemText>
                    </ListItemIcon>
                  </Box>
                </ListItemButton>
              </Link>
            )}
          </>
        )}
      </List>
    </Drawer>
  );
};

export default SideBar;
