import Comment from './Comment';
import CommentView from './CommentView';
import { Stack, Paper } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../store/hooks';
import { requestState } from 'store/modules/types';
import CognisaasSpinner from 'components/shared/CognisaasSpinner/CognisaasSpinner';
import { getAllProjectComments, resetGetProjectComments } from 'store/modules/Project/slice';
import { selectProjectLevelComments } from 'store/modules/Project/selector';
import NoDataFound from 'components/shared/NoRecords/NoDataFound';

interface Props {
  project_uid: string;
}

const CommentHome = (props: Props) => {
  const dispatch = useDispatch();
  const projectComments = useAppSelector(selectProjectLevelComments);
  const [comments, setComments] = useState<any[]>([]);

  useEffect(() => {
    dispatch(resetGetProjectComments());
    setComments([]);
    dispatch(getAllProjectComments(props.project_uid));

    return () => {
      dispatch(resetGetProjectComments());
    };
  }, []);

  useEffect(() => {
    setComments(projectComments.data);
  }, [projectComments]);

  const loading = projectComments.status === requestState.loading;

  return (
    <Stack style={{ marginTop: '8px', borderRadius: '4px', background: 'white', padding: '8px' }}>
      <Stack style={{ padding: '20px 20px 5px 20px' }}>
        <Comment projectUid={props.project_uid} />
      </Stack>
      {loading ? (
        <Stack style={{ width: '100%', height: '100%' }} direction="row" justifyContent="center" alignItems="center">
          <CognisaasSpinner size={50} />
        </Stack>
      ) : (
        <Paper elevation={0} style={{ overflow: 'hidden', marginBottom: '10px', padding: '0px 20px' }}>
          <CommentView projectUid={props.project_uid} commentsData={comments} />
        </Paper>
      )}
      {!loading && projectComments.data.length === 0 && projectComments.status === requestState.success && (
        <NoDataFound title={'No comments yet!'} />
      )}
    </Stack>
  );
};

export default CommentHome;
