import { apis } from '../axiosInstance';
import { aggregationCardRoutes } from '../route';
// import Cookies from 'universal-cookie';

// const cookies = new Cookies();

const createAggregationCard = (detail: any) =>
  apis.phpAxiosInstance.post(aggregationCardRoutes.createAggregatedCard(), detail, {
    headers: { JWT: 'true' },
  });

const updateAggregationCard = (detail: any) =>
  apis.phpAxiosInstance.post(aggregationCardRoutes.updateAggregatedCard(), detail, {
    headers: { JWT: 'true' },
  });

const getAggregationCards = (details: any) =>
  apis.phpAxiosInstance.get(aggregationCardRoutes.getAllAggregatedCards(details.customerUid, details.userUid), {
    headers: { JWT: 'true' },
  });
const cloneAggregationCard = (detail: any) =>
  apis.phpAxiosInstance.post(aggregationCardRoutes.cloneAggregatedCard(), detail, {
    headers: { JWT: 'true' },
  });
const deleteAggregationCard = (detail: any) =>
  apis.phpAxiosInstance.post(aggregationCardRoutes.deleteAggregatedCard(), detail, {
    headers: { JWT: 'true' },
  });
export const aggregatedCardAPI = {
  createAggregationCard,
  getAggregationCards,
  updateAggregationCard,
  cloneAggregationCard,
  deleteAggregationCard,
};
