import { useState } from 'react';
import { Box, Grid, Paper, Stack, Typography, Avatar } from '@mui/material';
// import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../../../../store/hooks';
import { selectProjectsOfClient } from '../../../../store/modules/Project/selector';
import { findCriticalPath, getProjectInformation } from '../../../../store/modules/Project/slice';
import TaskCompletionDaysCard from '../../../shared/CognisaasCard/TaskCompletionCard/TaskCompletionDaysCard/TaskCompletionDaysCard';
import { getAllTasksOfProject, fetchAllCompanyAndClientTasksFromTaskList } from '../../utils';
import UsecasesOverview from '../UsecasesOverview/UsecasesOverview';
import { selectedProjectForprojectDashboard, getTasksPathOfProject } from '../../../../store/modules/Project/slice';
import { selectProjectInformation } from '../../../../store/modules/Project/selector';
// import { ReactComponent as OverviewDetail } from '../../../../assets/svgs/OverviewDetail.svg';
import { getRedTasks, getAmberTasks, getGreenTasks, getBlueTasks, getGreyTasks } from '../../../TaskModule/taskUtils';
// import { CognisaaasTooltip } from '../../../shared/CognisaasTooltip/CognisaasTooltip';
import TaskCompletionCard from '../../../shared/CognisaasCard/TaskCompletionCard/TaskCompletionCard';
import { selectCustomerDetails } from 'store/modules/Settings/selector';
import { getCustomerDetails } from 'store/modules/Settings/slice';
import { useParams } from 'react-router-dom';
import { default as AvatarImage } from '../../../shared/Avatar/Avatar';
// import { format } from 'date-fns';

import Cookies from 'universal-cookie';
// import { differenceInDays } from 'date-fns';

const cookies = new Cookies();

//TODO: THIS SELECTED PROJECT FOR USING IN CLIENT MODULE SHOULD COME INSIDE DASHBOARD INITI CHANGE IT LATER

const TasksOverview = (props: any) => {
  const params = useParams();

  const [selectedProject, setSelectedProject] = useState<any>(null);
  const [project, setProject] = useState<any>(null);
  const [teamTasks, setTeamTasks] = useState<any[]>([]);
  const [clientTasks, setClientTasks] = useState<any[]>([]);
  const [delayByTeam, setDelayByTeam] = useState<number>(0);
  const [delayByClient, setDelayByClient] = useState<number>(0);
  const projectUid = location.pathname.split('/')[location.pathname.split('/').length - 1];
  let companyAndClientTasks: any;
  const clientProjectsInfo = useAppSelector(selectProjectsOfClient);
  const projectInfo = useAppSelector(selectProjectInformation);
  const customerDetails = useAppSelector(selectCustomerDetails);

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getCustomerDetails());
    const detail = {
      association_uid: params.associationId,
      project_uid: params.projectId,
    };
    dispatch(getProjectInformation(detail));
  }, []);

  useEffect(() => {
    if (clientProjectsInfo.length) {
      const project = clientProjectsInfo.filter((p: any) => {
        console.log(p, projectUid);

        return p.project_uid == projectUid;
      });
      console.log(selectedProject);

      setSelectedProject(project[0]);
      const p = project[0];
      dispatch(selectedProjectForprojectDashboard(p));
      console.log('seleceeee, ', project[0]);
      const tasks = getAllTasksOfProject(project[0]);
      console.log(tasks);
      companyAndClientTasks = fetchAllCompanyAndClientTasksFromTaskList(tasks);
      console.log(companyAndClientTasks);
    }
  }, [clientProjectsInfo]);

  useEffect(() => {
    if (projectInfo) {
      if (Object.entries(projectInfo).length > 0) {
        console.log('infoooo', projectInfo);

        makeMetaDataForComponent();
      }
    }
  }, [projectInfo]);

  const makeMetaDataForComponent = async () => {
    setProject({ ...projectInfo });
    projectInfo.task.length > 0 ? setTeamTasks([...projectInfo.task]) : null;
    projectInfo.clientTask.length > 0 ? setClientTasks([...projectInfo.clientTask]) : null;
    const pay = {
      project_uid: projectInfo.project_uid,
      type: 'PROJECT',
    };
    let criticalPath: any = await dispatch(findCriticalPath(pay)).unwrap();
    criticalPath = [...criticalPath.result[0]];
    console.log(criticalPath);
    const path: any[] = await dispatch(getTasksPathOfProject(pay)).unwrap();
    let teamDelay = 0;
    let clientDelay = 0;
    for (let i = 0; i < criticalPath.length; i++) {
      for (let j = 0; j < path.length; j++) {
        if (path[j].task_uid == criticalPath[i].task_uid) {
          if (path[j].delayed_by != 0) {
            if (path[j].is_team_task == 1) {
              teamDelay = parseInt(path[j].delayed_by) + teamDelay;
            } else {
              clientDelay = parseInt(path[j].delayed_by) + clientDelay;
            }
          }

          // if (new Date() > new Date(path[j].lf.split(' ')[0]) && path[j].task_stage != 'Done') {
          //   if (path[j].is_team_task == 1) {
          //     teamDelay = teamDelay + differenceInDays(new Date(), new Date(path[j].lf.split(' ')[0]));
          //   } else {
          //     clientDelay = clientDelay + differenceInDays(new Date(), new Date(path[j].lf.split(' ')[0]));
          //   }
          // } else if (
          //   new Date(path[j].task_closed_date.split(' ')[0]) > new Date(path[j].lf.split(' ')[0]) &&
          //   path[j].task_stage == 'Done'
          // ) {
          //   if (path[j].is_team_task == 1) {
          //     teamDelay =
          //       teamDelay +
          //       differenceInDays(new Date(path[j].task_closed_date.split(' ')[0]), new Date(path[j].lf.split(' ')[0]));
          //   } else {
          //     clientDelay =
          //       clientDelay +
          //       differenceInDays(new Date(path[j].task_closed_date.split(' ')[0]), new Date(path[j].lf.split(' ')[0]));
          //   }
          // }
        }
      }
    }
    // path.forEach((p: any) => {
    //   if (new Date() > new Date(p.lf.split(' ')[0]) && p.task_stage != 'Done') {
    //     if (p.is_team_task == 1) {
    //       teamDelay = teamDelay + differenceInDays(new Date(), new Date(p.lf.split(' ')[0])) + 1;
    //     } else {
    //       clientDelay = clientDelay + differenceInDays(new Date(), new Date(p.lf.split(' ')[0])) + 1;
    //     }
    //   } else if (new Date(p.task_closed_date.split(' ')[0]) > new Date(p.lf.split(' ')[0]) && p.task_stage == 'Done') {
    //     if (p.is_team_task == 1) {
    //       teamDelay =
    //         teamDelay + differenceInDays(new Date(p.task_closed_date.split(' ')[0]), new Date(p.lf.split(' ')[0])) + 1;
    //     } else {
    //       clientDelay =
    //         clientDelay +
    //         differenceInDays(new Date(p.task_closed_date.split(' ')[0]), new Date(p.lf.split(' ')[0])) +
    //         1;
    //     }
    //   }
    // });
    console.log('TEAM AND CLIENT', teamDelay, clientDelay);
    setDelayByTeam(teamDelay);
    setDelayByClient(clientDelay);
  };

  function stringToHslColor(str: string, s: number, l: number) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    const h = hash % 360;
    return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
  }

  function stringAvatar(name: string) {
    return {
      sx: {
        bgcolor: stringToHslColor(name, 30, 80),
      },
      children: (
        <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#000000' }}>
          {name.split(' ')[0][0]}
          {name.indexOf(' ') >= 0 ? name.split(' ')[1][0] : null}
        </Typography>
      ),
    };
  }

  return (
    <>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={6}>
          {props.project && <TaskCompletionCard totalTasks={[...teamTasks, ...clientTasks]} />}
        </Grid>
        <Grid item xs={6}>
          {project && (
            <TaskCompletionDaysCard
              dueDate={project.project_requiredbydate}
              status={project.project_readinessstate_status}
              overdueDays={project.project_days_overdue}
              remainingDays={project.project_days_remaining}
              eta={props?.project?.eta_forprojectdelivery}
            ></TaskCompletionDaysCard>
          )}
        </Grid>
        <Grid item sm={6} md={12}>
          <Stack>
            <Box>
              <Paper sx={{ boxShadow: 'none' }}>
                <Stack mx={2} pt={2} direction="row">
                  {customerDetails.data.customer_logo_link && customerDetails.data.customer_logo_link.length > 0 ? (
                    <AvatarImage
                      src={customerDetails.data.customer_logo_link}
                      height="38px"
                      width="38px"
                      alt="Customer logo"
                    />
                  ) : (
                    <Avatar
                      style={{ height: '38px', width: '38px', marginTop: '3px' }}
                      {...stringAvatar(`${cookies.get('customer_subdomain')}`)}
                    />
                  )}
                  <Typography ml={1} mt={1} variant="h2">
                    {cookies.get('customer_subdomain')}
                  </Typography>
                  <Typography ml={1} mt={1} variant="subtitle1">
                    (Internal team delay: {delayByTeam} {delayByTeam == 1 ? 'day' : 'days'})
                  </Typography>
                </Stack>
                <Stack my={1} direction="row" pl={7} spacing={8}>
                  <Box>
                    <Stack direction="column">
                      <Stack direction="row">
                        <Box
                          component="span"
                          sx={{
                            backgroundColor: '#E75B5C',
                            display: 'inline-block',
                            width: '12px',
                            height: '12px',
                            borderRadius: '60%',
                            marginRight: '8px',
                            marginTop: '3px',
                          }}
                        ></Box>
                        <Typography variant="subtitle2" className="label-text">
                          Overdue
                        </Typography>
                      </Stack>
                      <Stack ml={2.2}>
                        <Typography variant="h2">{getRedTasks(teamTasks).length}</Typography>
                      </Stack>
                    </Stack>
                  </Box>
                  <Box style={{ marginLeft: '50px' }}>
                    <Stack direction="column">
                      <Stack direction="row">
                        <Box
                          component="span"
                          sx={{
                            backgroundColor: '#EFA958',
                            display: 'inline-block',
                            width: '12px',
                            height: '12px',
                            borderRadius: '60%',
                            marginRight: '8px',
                            marginTop: '3px',
                          }}
                        ></Box>
                        <Typography variant="subtitle2" className="label-text">
                          At Risk
                        </Typography>
                      </Stack>
                      <Stack ml={2.2}>
                        <Typography variant="h2">{getAmberTasks(teamTasks).length}</Typography>
                      </Stack>
                    </Stack>
                  </Box>
                  <Box style={{ marginLeft: '50px' }}>
                    <Stack direction="column">
                      <Stack direction="row">
                        <Box
                          component="span"
                          sx={{
                            backgroundColor: '#4F9F52',
                            display: 'inline-block',
                            width: '12px',
                            height: '12px',
                            borderRadius: '60%',
                            marginRight: '8px',
                            marginTop: '3px',
                          }}
                        ></Box>
                        <Typography variant="subtitle2" className="label-text">
                          On Track
                        </Typography>
                      </Stack>
                      <Stack ml={2.2}>
                        <Typography variant="h2">{getGreenTasks(teamTasks).length}</Typography>
                      </Stack>
                    </Stack>
                  </Box>
                  <Box style={{ marginLeft: '50px' }}>
                    <Stack direction="column">
                      <Stack direction="row">
                        <Box
                          component="span"
                          sx={{
                            backgroundColor: '#4390E1',
                            display: 'inline-block',
                            width: '12px',
                            height: '12px',
                            borderRadius: '60%',
                            marginRight: '8px',
                            marginTop: '3px',
                          }}
                        ></Box>
                        <Typography variant="subtitle2" className="label-text">
                          Completed
                        </Typography>
                      </Stack>
                      <Stack ml={2.2}>
                        <Typography variant="h2"> {getBlueTasks(teamTasks).length} </Typography>
                      </Stack>
                    </Stack>
                  </Box>
                  <Box style={{ marginLeft: '50px' }}>
                    <Stack direction="column">
                      <Stack direction="row">
                        <Box
                          component="span"
                          sx={{
                            backgroundColor: '#7a7a7a',
                            display: 'inline-block',
                            width: '12px',
                            height: '12px',
                            borderRadius: '60%',
                            marginRight: '8px',
                            marginTop: '3px',
                          }}
                        ></Box>
                        <Typography variant="subtitle2" className="label-text">
                          Deferred
                        </Typography>
                      </Stack>
                      <Stack ml={2.2}>
                        <Typography variant="h2"> {getGreyTasks(teamTasks).length} </Typography>
                      </Stack>
                    </Stack>
                  </Box>
                </Stack>
                <Stack mx={2} pt={1} direction="row">
                  {props.client.client_logo_link && props.client.client_logo_link.length > 0 ? (
                    <AvatarImage src={props.client.client_logo_link} alt="Client logo" height="38px" width="38px" />
                  ) : (
                    <Avatar
                      style={{ height: '38px', width: '38px', marginTop: '3px' }}
                      {...stringAvatar(props.client.client_name)}
                    />
                  )}
                  <Typography ml={1} mt={1} variant="h2">
                    {props.client.client_name}
                  </Typography>
                  <Typography ml={1} mt={1} variant="subtitle1">
                    (Client delay: {delayByClient} {delayByClient == 1 ? 'day' : 'days'} )
                  </Typography>
                </Stack>
                <Stack my={1} direction="row" pl={7} spacing={8} pb={3}>
                  <Box>
                    <Stack direction="column">
                      <Stack direction="row">
                        <Box
                          component="span"
                          sx={{
                            backgroundColor: '#E75B5C',
                            display: 'inline-block',
                            width: '12px',
                            height: '12px',
                            borderRadius: '60%',
                            marginRight: '8px',
                            marginTop: '3px',
                          }}
                        ></Box>
                        <Typography variant="subtitle2" className="label-text">
                          Overdue
                        </Typography>
                      </Stack>
                      <Stack ml={2.2}>
                        <Typography variant="h2">{getRedTasks(clientTasks).length}</Typography>
                      </Stack>
                    </Stack>
                  </Box>
                  <Box style={{ marginLeft: '50px' }}>
                    <Stack direction="column">
                      <Stack direction="row">
                        <Box
                          component="span"
                          sx={{
                            backgroundColor: '#EFA958',
                            display: 'inline-block',
                            width: '12px',
                            height: '12px',
                            borderRadius: '60%',
                            marginRight: '8px',
                            marginTop: '3px',
                          }}
                        ></Box>
                        <Typography variant="subtitle2" className="label-text">
                          At Risk
                        </Typography>
                      </Stack>
                      <Stack ml={2.2}>
                        <Typography variant="h2"> {getAmberTasks(clientTasks).length} </Typography>
                      </Stack>
                    </Stack>
                  </Box>
                  <Box style={{ marginLeft: '50px' }}>
                    <Stack direction="column">
                      <Stack direction="row">
                        <Box
                          component="span"
                          sx={{
                            backgroundColor: '#4F9F52',
                            display: 'inline-block',
                            width: '12px',
                            height: '12px',
                            borderRadius: '60%',
                            marginRight: '8px',
                            marginTop: '3px',
                          }}
                        ></Box>
                        <Typography variant="subtitle2" className="label-text">
                          On Track
                        </Typography>
                      </Stack>
                      <Stack ml={2.2}>
                        <Typography variant="h2"> {getGreenTasks(clientTasks).length} </Typography>
                      </Stack>
                    </Stack>
                  </Box>
                  <Box style={{ marginLeft: '50px' }}>
                    <Stack direction="column">
                      <Stack direction="row">
                        <Box
                          component="span"
                          sx={{
                            backgroundColor: '#4390E1',
                            display: 'inline-block',
                            width: '12px',
                            height: '12px',
                            borderRadius: '60%',
                            marginRight: '8px',
                            marginTop: '3px',
                          }}
                        ></Box>
                        <Typography variant="subtitle2" className="label-text">
                          Completed
                        </Typography>
                      </Stack>
                      <Stack ml={2.2}>
                        <Typography variant="h2">{getBlueTasks(clientTasks).length}</Typography>
                      </Stack>
                    </Stack>
                  </Box>
                  <Box style={{ marginLeft: '50px' }}>
                    <Stack direction="column">
                      <Stack direction="row">
                        <Box
                          component="span"
                          sx={{
                            backgroundColor: '#7a7a7a',
                            display: 'inline-block',
                            width: '12px',
                            height: '12px',
                            borderRadius: '60%',
                            marginRight: '8px',
                            marginTop: '3px',
                          }}
                        ></Box>
                        <Typography variant="subtitle2" className="label-text">
                          Deferred
                        </Typography>
                      </Stack>
                      <Stack ml={2.2}>
                        <Typography variant="h2"> {getGreyTasks(clientTasks).length} </Typography>
                      </Stack>
                    </Stack>
                  </Box>
                </Stack>
              </Paper>
            </Box>
          </Stack>
        </Grid>
        <Grid item sm={6} md={12}>
          <Stack>{project && <UsecasesOverview client={props.client} project={project} />}</Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default TasksOverview;
