const data = [
  {
    project_status_reason: 'The due date of this project has passed',
    project_expansionvalue: '0',
    project_priority: 'Medium',
    association_uid: '628e41575f992',
    project_uid: '628f88a8c0544',
    project_description: '',
    project_comment: '',
    project_name: 'Mid-market Customer Onboarding',
    project_readinessstate_status: 'red',
    project_needed_status: 'Land',
    project_requiredbydate: '2022-07-05 00:00:00',
    project_stage: 'Pre-Sales',
    project_deliverymanager: 'Not Assigned',
    client_status_reason: 'Delivery of one or more projects of this client is not planned or is overdue',
    client_uid: '628e41575f989',
    client_account_owner: 'Aman',
    client_name: '32',
    client_account_worth: '3923',
    client_readiness_status: 'red',
    imported_template: '1',
    user_uid: '15243',
    project_startdate: '2022-05-26 00:00:00',
    project_deliverydate: '0000-00-00 00:00:00',
    eta_forprojectdelivery: '2022-07-05 00:00:00',
    custom_values: [],
  },
  {
    project_status_reason: 'The due date of this project has passed',
    project_expansionvalue: '0',
    project_priority: 'Medium',
    association_uid: '628e41575f992',
    project_uid: '628e415eb690f',
    project_description: '',
    project_comment: '',
    project_name: 'Mid-market Customer Onboarding',
    project_readinessstate_status: 'red',
    project_needed_status: 'Land',
    project_requiredbydate: '2022-07-04 00:00:00',
    project_stage: 'Adoption',
    project_deliverymanager: 'Not Assigned',
    client_status_reason: 'Delivery of one or more projects of this client is not planned or is overdue',
    client_uid: '628e41575f989',
    client_account_owner: 'Aman',
    client_name: '12',
    client_account_worth: '677',
    client_readiness_status: 'red',
    imported_template: '1',
    user_uid: '15243',
    project_startdate: '2022-05-25 00:00:00',
    project_deliverydate: '0000-00-00 00:00:00',
    eta_forprojectdelivery: '0000-00-00 00:00:00',
    custom_values: [],
  },
  {
    project_status_reason: 'The due date of this project has passed',
    project_expansionvalue: '0',
    project_priority: 'Medium',
    association_uid: '62a873a2d3645',
    project_uid: '62a873b47c61c',
    project_description: '',
    project_comment: '',
    project_name: 'Coffee',
    project_readinessstate_status: 'red',
    project_needed_status: 'Land',
    project_requiredbydate: '2022-06-14 00:00:00',
    project_stage: 'Adoption',
    project_deliverymanager: 'Not Assigned',
    client_status_reason: 'Delivery of one or more projects of this client is not planned or is overdue',
    client_uid: '62a873a2d363d',
    client_account_owner: 'Aman',
    client_name: 'Client Wipro',
    client_account_worth: '403',
    client_readiness_status: 'red',
    imported_template: '1',
    user_uid: '15243',
    project_startdate: '2022-06-14 00:00:00',
    project_deliverydate: '0000-00-00 00:00:00',
    eta_forprojectdelivery: '0000-00-00 00:00:00',
    custom_values: [],
  },
  {
    project_status_reason: 'The due date of this project has passed',
    project_expansionvalue: '0',
    project_priority: 'Medium',
    association_uid: '62828ecbd279f',
    project_uid: '62828f030f782',
    project_description: '',
    project_comment: '',
    project_name: 'Enterprise Customer Onboarding Template',
    project_readinessstate_status: 'red',
    project_needed_status: 'Land',
    project_requiredbydate: '2022-05-16 00:00:00',
    project_stage: 'Go-Live',
    project_deliverymanager: 'Not Assigned',
    client_status_reason: 'Delivery of one or more projects of this client is not planned or is overdue',
    client_uid: '62828ecbd2796',
    client_account_owner: 'Aman',
    client_name: 'sbi',
    client_account_worth: '5666',
    client_readiness_status: 'red',
    imported_template: '1',
    user_uid: '15243',
    project_startdate: '2022-05-16 00:00:00',
    project_deliverydate: '0000-00-00 00:00:00',
    eta_forprojectdelivery: '0000-00-00 00:00:00',
    custom_values: [],
  },
];

export default data;