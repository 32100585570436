import { useState, useEffect } from 'react';
import { Typography, Box, TextField, Stack } from '@mui/material';
import CognisaasButton from '../../shared/CognisaasButton/CognisaasButton';
import {
  selectUserFullDetail,
  selectAddedClientContactUid,
} from '../../../store/modules/ManageClient/ClientList/selector';
import { useAppSelector } from '../../../store/hooks';
import { userDetailModel } from '../../../api/responseInterface';
import { useLocation } from 'react-router-dom';
import {
  fetchClientContacts,
  sendClientContactAdditionMail,
  fetchUserDetail,
  addClientContact,
  resetAddedClientContact,
} from '../../../store/modules/ManageClient/ClientList/slice';
import { useDispatch } from 'react-redux';
import { getLoggedInUserUid, getLoggedInUserName } from '../../../utils/getUid';
import Cookies from 'universal-cookie';
import { format } from 'date-fns';
import { Formik } from 'formik';
import { clientContactModal } from 'utils/validations';
import { label_client } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { useParams } from 'react-router-dom';
import { addClientContacts } from 'store/modules/features/client/client';

const cookies = new Cookies();
const InviteContactModal = (componentProps: any) => {
  const location = useLocation();
  const params = useParams();
  const dispatch = useDispatch();
  const [email, setEmail] = useState<string>('');
  const initialValues = {
    name: '',
    email: '',
    role: '',
    phone: '',
  };
  let addContactDetails = {};
  const addedClientCOntactUid: any = useAppSelector(selectAddedClientContactUid);

  const userDetail: userDetailModel = useAppSelector(selectUserFullDetail);
  // useEffect(() => {
  //   dispatch(resetAddedClientContact());
  // }, []);

  // useEffect(() => {
  //   if (addedClientCOntactUid && userDetail?.client_contact_added_mail == '1' && addedClientCOntactUid.length > 0) {
  //     const data1 = {
  //       // ...addContactDetails,
  //       contact_uid: addedClientCOntactUid,
  //       customer_uid: cookies.get('cogni_customer_uid') as string,
  //       user_uid: getLoggedInUserUid(),
  //       user_email: userDetail.user_email,
  //       user_username: userDetail.user_username,
  //       contact_email: email,
  //       client_uid: location.pathname.split('/')[location.pathname.split('/').length - 1],
  //       base_url: `https://${window.location.host}/customer-portal/${userDetail.user_company}/magic-link/`
  //     };
  //     dispatch(sendClientContactAdditionMail(data1));
  //     componentProps.setAlertDetails({
  //       isVisible: true,
  //       type: 'success',
  //       message: `${label_client} contact added successfully.`,
  //     });
  //     dispatch(resetAddedClientContact());
  //     componentProps.setInviteContactsModal(false);
  //   }
  // }, [addedClientCOntactUid]);

  // useEffect(() => {
  //   dispatch(fetchUserDetail());
  // }, []);

  return (
    <>
      <Box>
        <Typography sx={{ margin: '16px 0px' }} variant="h2">
          Invite a Member
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={clientContactModal}
          onSubmit={async (values) => {
            if (values.name.trim()) {
              setEmail(values.email.trim());
              addContactDetails = {
                // user_name: getLoggedInUserName(),
                // primary_contact: componentProps.clientContacts?.length > 0 ? 0 : 1,
                // base_url: `https://${window.location.host}/customer-portal/${userDetail.user_company}/magic-link/`,
                name: values.name.trim(),
                email: values.email.trim(),
                phone_number: values.phone.trim(),
                role: values.role.trim(),
                clientId: params.clientId as string,
                // customer_uid: cookies.get('cogni_customer_uid') as string,
                // customer_company: userDetail.user_company,
                // client_uid: location.pathname.split('/')[location.pathname.split('/').length - 1],
                // user_email: userDetail.user_email,
                // user_username: userDetail.user_username,
                // last_magiclink_sent_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
              };
              await dispatch(addClientContacts(addContactDetails));
              componentProps.setInviteContactsModal(false);
              // await dispatch(
              //   fetchClientContacts(location.pathname.split('/')[location.pathname.split('/').length - 1])
              // );
            } else {
              componentProps?.setAlertDetails({
                isVisible: true,
                type: 'failure',
                message: 'Name cannot be empty.',
              });
            }
          }}
        >
          {(props: any) => (
            <form>
              <Stack direction="column" spacing={3}>
                <TextField
                  sx={{ width: '350px' }}
                  label="Name*"
                  size="small"
                  variant="outlined"
                  id="name"
                  name="name"
                  placeholder="Name"
                  onChange={props.handleChange}
                  value={props.values.name}
                  onBlur={props.handleBlur}
                  error={props.touched.name && Boolean(props.errors.name)}
                  helperText={props.touched.name && props.errors.name}
                ></TextField>

                <TextField
                  sx={{ width: '350px' }}
                  size="small"
                  label="Email Address*"
                  variant="outlined"
                  id="email"
                  name="email"
                  placeholder="Email Address"
                  onChange={props.handleChange}
                  value={props.values.email}
                  onBlur={props.handleBlur}
                  error={props.touched.email && Boolean(props.errors.email)}
                  helperText={props.touched.email && props.errors.email}
                ></TextField>

                <TextField
                  sx={{ width: '350px' }}
                  size="small"
                  label="Contact number"
                  variant="outlined"
                  id="phone"
                  name="phone"
                  placeholder="Contact number"
                  onChange={props.handleChange}
                  value={props.values.phone}
                  onBlur={props.handleBlur}
                  error={props.touched.phone && Boolean(props.errors.phone)}
                  helperText={props.touched.phone && props.errors.phone}
                ></TextField>

                <TextField
                  sx={{ width: '350px' }}
                  size="small"
                  label="Role"
                  variant="outlined"
                  id="role"
                  name="role"
                  placeholder="Role"
                  onChange={props.handleChange}
                  value={props.values.role}
                ></TextField>
              </Stack>
              <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ marginTop: '32px' }}>
                <CognisaasButton
                  label={'Cancel'}
                  isOutlined={true}
                  clickHandler={() => componentProps.setInviteContactsModal(false)}
                />
                <CognisaasButton
                  disabled={!props.isValid || !props.dirty}
                  label={'Save'}
                  isOutlined={false}
                  clickHandler={props.handleSubmit}
                />
              </Stack>
            </form>
          )}
        </Formik>
      </Box>
    </>
  );
};

export default InviteContactModal;
