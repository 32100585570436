import { apis } from '../axiosInstance';
import { resourceManagementRoutes } from '../route';

import Cookies from 'universal-cookie';

const cookies = new Cookies();

const getAllDeliveryManagerDetailsWithProjectAndTaskUsingCustomerUid = () => {
  const customer_uid = cookies.get('cogni_customer_uid') as string;
  return apis.phpAxiosInstance.get(
    resourceManagementRoutes.getAllDeliveryManagerDetailsWithProjectAndTaskUsingCustomerUid(customer_uid),
    {
      headers: { JWT: 'true' },
    }
  );
};

const getDataForResourceManagement = () => {
  const customer_uid = cookies.get('cogni_customer_uid') as string;
  return apis.phpAxiosInstance.get(resourceManagementRoutes.getDataForResourceManagement(customer_uid), {
    headers: { JWT: 'true' },
  });
};

const getDataForResourceManagementUser = () => {
  const customer_uid = cookies.get('cogni_customer_uid') as string;
  return apis.phpAxiosInstance.get(resourceManagementRoutes.getResourceManagementRawUserData(customer_uid), {
    headers: { JWT: 'true' },
  });
};

const getDataForResourceManagementProject = () => {
  const customer_uid = cookies.get('cogni_customer_uid') as string;
  return apis.phpAxiosInstance.get(resourceManagementRoutes.getResourceManagementRawProjectData(customer_uid), {
    headers: { JWT: 'true' },
  });
};

const getDataForResourceManagementTask = () => {
  const customer_uid = cookies.get('cogni_customer_uid') as string;
  return apis.phpAxiosInstance.get(resourceManagementRoutes.getResourceManagementRawTaskData(customer_uid), {
    headers: { JWT: 'true' },
  });
};

export const resourceManagementAPI = {
  getAllDeliveryManagerDetailsWithProjectAndTaskUsingCustomerUid,
  getDataForResourceManagement,
  getDataForResourceManagementProject,
  getDataForResourceManagementTask,
  getDataForResourceManagementUser,
};
