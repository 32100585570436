import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../store/hooks';
import {
  getAllClientCounts_slice,
  getAllCustomerDetails_slice,
  getProjectCount_slice,
  getUsecaseCount_slice,
  getUserCount_slice,
  getAllUserActivity_slice,
  deleteCustomer_slice,
  getUsecaseCountByUser_slice,
  getProjectCountByUser_slice,
  // checkAdminToken_slice
} from '../../store/modules/AdminPanel/slice';
import {
  allCustomerDetails_selector,
  getAllClientCounts_selector,
  getUsecase_selector,
  getProjectCount_selector,
  getUserCount_selector,
  getAllUserActivity_selector,
  getProjectCountByUser_selector,
  getUsecaseByUser_selector,
  // checkAdminToken_selector
} from '../../store/modules/AdminPanel/selector';
import { getFilteredData, getAllUserByCustomerUid } from './helper';
import {
  DataGridPremium,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
  GridRenderCellParams,
} from '@mui/x-data-grid-premium';
import { Typography, Box, Grid, IconButton, Button, Modal, Link } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';
// import { makeStyles } from '@material-ui/core/styles';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import { ReactComponent as CognisaasLogo } from '../../assets/svgs/BrandLogo.svg';
import { useNavigate } from 'react-router-dom';

function CustomNoRowsOverlay() {
  const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .ant-empty-img-1': {
      fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
      fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
      fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
      fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
      fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
      fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
  }));
  return (
    <StyledGridOverlay>
      <svg width="120" height="100" viewBox="0 0 184 152" aria-hidden focusable="false">
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse className="ant-empty-img-5" cx="67.797" cy="106.89" rx="67.797" ry="12.668" />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Rows</Box>
    </StyledGridOverlay>
  );
}

const AdminPanel = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const allCustomerDetails_fromselector = useAppSelector(allCustomerDetails_selector);
  const allClientCounts_fromselector = useAppSelector(getAllClientCounts_selector);
  const userCount_fromselector = useAppSelector(getUserCount_selector);
  const allUserActivity_fromselector = useAppSelector(getAllUserActivity_selector);
  const projectCount_fromselector = useAppSelector(getProjectCount_selector);
  const usecaseCount_fromselector = useAppSelector(getUsecase_selector);
  const projectCountByUser_fromselector = useAppSelector(getProjectCountByUser_selector);
  const usecaseCountByUser_fromselector = useAppSelector(getUsecaseByUser_selector);
  // const checkAdminToken_fromSelector = useAppSelector(checkAdminToken_selector);

  const [filteredTableData, setFilteredTableData] = useState<any[]>([]);
  const [initialCalls, setInitialCalls] = useState(false);

  const apiRef = useGridApiRef();

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      rowGrouping: {
        model: ['timeline'],
      },
      columns: {
        columnVisibilityModel: {
          task_uid: false,
          task_name: false,
        },
      },
    },
  });

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1100,
    bgcolor: '#ffffff',
    borderRadius: '4px',
    boxShadow: 24,
    p: 4,
  };

  const modalTableColumns = [
    {
      field: 'user_name',
      headerName: 'User Name',
      width: 200,
    },
    {
      field: 'user_email',
      headerName: 'User Email',
      width: 290,
    },
    {
      field: 'project_count',
      headerName: 'Project Count',
      width: 160,
    },
    {
      field: 'usecase_count',
      headerName: 'Usecase Count',
      width: 160,
    },
    {
      field: 'user_last_login',
      headerName: 'Last Login',
      width: 200,
    },
  ];

  const columns = [
    {
      field: 'customer_company',
      headerName: 'Customer Name',
      width: 300,
      renderCell: (params: GridRenderCellParams) => (
        <Grid>
          <Typography variant="subtitle1">{params.row.customer_company}</Typography>
        </Grid>
      ),
    },
    {
      field: 'client_count',
      headerName: 'No of Clients',
      width: 200,
      renderCell: (params: GridRenderCellParams) => (
        <Grid>
          <Typography variant="subtitle1">{params.row.client_count}</Typography>
        </Grid>
      ),
    },
    {
      field: 'user_count',
      headerName: 'No of Users',
      width: 200,
      renderCell: (params: GridRenderCellParams) => {
        const [open, setOpen] = useState(false);
        const [modalData, setModalData] = useState<any[]>([]);
        const handleOpen = () => {
          console.log(params.row);
          const temp = getAllUserByCustomerUid({
            c_uid: params.row.customer_uid,
            allUserData: allUserActivity_fromselector.data,
            projectCountData: projectCountByUser_fromselector.data,
            usecaseData: usecaseCountByUser_fromselector.data,
          });
          // console.log(temp);
          // const dataWithAllCounts = getProjectAndUsecaseCount_byUserUid({
          //   allUserData: temp,
          //   projectCountData: projectCountByUser_fromselector.data,
          //   usecaseData: usecaseCountByUser_fromselector.data
          // });
          console.log(temp);
          setModalData(temp);
          setOpen(true);
        };

        const ModalUserData = {
          rows: modalData,
          columns: modalTableColumns,
        };

        const handleClose = () => setOpen(false);

        return (
          <Grid>
            <Link sx={{ cursor: 'pointer', textDecoration: 'none' }} onClick={handleOpen}>
              <Typography variant="subtitle1">{params.row.user_count}</Typography>
            </Link>
            <Modal open={open} onClose={handleClose}>
              <Box sx={modalStyle}>
                <Grid
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginTop: '-20px',
                    marginBottom: '15px',
                  }}
                >
                  <Typography variant="h6" component="h2">
                    User of {params.row.customer_company || ''}
                  </Typography>
                  <IconButton onClick={handleClose}>
                    <ClearIcon />
                  </IconButton>
                </Grid>
                <Grid sx={{ height: '290px', maxHeight: '400px' }}>
                  {modalData && (
                    <DataGridPremium
                      {...ModalUserData}
                      getRowId={(row) => row.user_uid}
                      disableSelectionOnClick
                      disableMultipleSelection={true}
                    />
                  )}
                </Grid>
              </Box>
            </Modal>
          </Grid>
        );
      },
    },
    {
      field: 'project_count',
      headerName: 'No of Projects',
      width: 200,
      renderCell: (params: GridRenderCellParams) => (
        <Grid>
          <Typography variant="subtitle1">{params.row.project_count}</Typography>
        </Grid>
      ),
    },
    {
      field: 'usecase_count',
      headerName: 'No of Usecases',
      width: 200,
      renderCell: (params: GridRenderCellParams) => (
        <Grid>
          <Typography variant="subtitle1">{params.row.usecase_count}</Typography>
        </Grid>
      ),
    },
    {
      field: 'customer_created_on',
      headerName: 'Creation Date',
      width: 200,
      renderCell: (params: GridRenderCellParams) => (
        <Grid>
          <Typography variant="subtitle1">{params.row.customer_created_on}</Typography>
        </Grid>
      ),
    },
    {
      field: 'last_login',
      headerName: 'Last login by user',
      width: 200,
      renderCell: (params: GridRenderCellParams) => (
        // console.log(params.row);
        <Grid>
          <Typography variant="subtitle1">{params.row.last_login}</Typography>
        </Grid>
      ),
    },
    {
      field: '_',
      headerName: 'Action',
      width: 80,
      renderCell: (params: GridRenderCellParams) => {
        const deleteCustomer = () => {
          dispatch(deleteCustomer_slice(params.row.customer_uid || ''));
        };
        return (
          <Grid>
            <IconButton
              aria-label="delete"
              onClick={() => {
                deleteCustomer();
              }}
            >
              <DeleteIcon sx={{ color: '#EB5858' }} />
            </IconButton>
          </Grid>
        );
      },
    },
  ];

  // useEffect(()=>{
  //   if(checkAdminToken_fromSelector){
  //     console.log('checkAdminToken_fromSelector:', checkAdminToken_fromSelector);
  //     if(checkAdminToken_fromSelector.status=='success' && checkAdminToken_fromSelector?.data[0]?.status == 0){
  //       console.log('not login in');
  //       alert('User not Logged In! Please Login.');
  //       // navigate('/9kad7Bx73');
  //     }else if(checkAdminToken_fromSelector.status=='success'){
  //       setInitialCalls(true);
  //     }
  //   }
  // }, [checkAdminToken_fromSelector]);

  useEffect(() => {
    if (initialCalls) {
      dispatch(getAllCustomerDetails_slice());
      dispatch(getAllClientCounts_slice());
      dispatch(getUserCount_slice());
      dispatch(getAllUserActivity_slice());
      dispatch(getProjectCount_slice());
      dispatch(getUsecaseCount_slice());
      dispatch(getProjectCountByUser_slice());
      dispatch(getUsecaseCountByUser_slice());
    }
  }, [initialCalls]);

  useEffect(() => {
    // setInitialCalls(true);
    const local_jwt = sessionStorage.getItem('admin_jwt');
    if (local_jwt) {
      setInitialCalls(true);
    }
  }, []);

  // useEffect(()=>{
  //   const token = sessionStorage.getItem('admin_jwt') || '';
  //   const user_name = sessionStorage.getItem('admin_name') || 'temp';
  //   const payload = {
  //     'jwt_token': token,
  //     'admin_name': user_name
  //   };
  //   dispatch(checkAdminToken_slice(payload));
  // }, []);

  useEffect(() => {
    if (
      allCustomerDetails_fromselector.status == 'success' &&
      allClientCounts_fromselector.status == 'success' &&
      userCount_fromselector.status == 'success' &&
      allUserActivity_fromselector &&
      projectCount_fromselector.status == 'success' &&
      usecaseCount_fromselector.status == 'success'
    ) {
      console.log('customerDetailsData: ', allCustomerDetails_fromselector.data);
      console.log('userCountsData: ', userCount_fromselector.data);
      console.log('clientCountsData: ', allClientCounts_fromselector.data);
      console.log('allActivitiesData: ', allUserActivity_fromselector.data);
      console.log('projectCountData: ', projectCount_fromselector.data);
      console.log('usecaseData: ', usecaseCount_fromselector.data);
      const filteredData = getFilteredData({
        customerDetailsData: allCustomerDetails_fromselector.data,
        userCountsData: userCount_fromselector.data,
        clientCountsData: allClientCounts_fromselector.data,
        allActivitiesData: allUserActivity_fromselector.data,
        projectCountData: projectCount_fromselector.data,
        usecaseData: usecaseCount_fromselector.data,
      });
      setFilteredTableData(filteredData);
    }
  }, [
    allCustomerDetails_fromselector,
    allClientCounts_fromselector,
    userCount_fromselector,
    allUserActivity_fromselector,
    projectCount_fromselector,
    usecaseCount_fromselector,
  ]);

  const data = {
    rows: filteredTableData,
    // rows: [],

    columns: columns,
  };

  const useStyles = () =>
    makeStyles(() => {
      return {
        root: {
          '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
        },
      };
    });

  const handleOnLogout = () => {
    console.log('i was in index.tsx');
    localStorage.removeItem('jid');
    localStorage.removeItem('adminLoggedIn');
    localStorage.clear();
    navigate('/login/');
  };

  return (
    <Grid>
      <Grid
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: '#ffffff',
          padding: '0 15px',
        }}
      >
        <CognisaasLogo style={{ width: '180px' }} />
        <Typography variant="h2" fontSize="30px" color="#616161">
          Admin Panel
        </Typography>
        <Button
          variant="contained"
          onClick={() => {
            handleOnLogout();
          }}
        >
          Log out
        </Button>
      </Grid>
      <Grid sx={{ padding: '22px' }}>
        <Grid>
          <Typography variant="h2" sx={{ fontSize: '21px' }}>
            Customers List
          </Typography>
        </Grid>
        <Grid sx={{ height: '83.5vh', marginTop: '13px' }}>
          {filteredTableData && (
            <DataGridPremium
              components={{
                NoRowsOverlay: CustomNoRowsOverlay,
              }}
              {...data}
              getRowId={(row) => row.customer_uid}
              apiRef={apiRef}
              disableSelectionOnClick
              initialState={initialState}
              pagination={true}
              pageSize={12}
              disableMultipleSelection={true}
              className={useStyles()().root}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AdminPanel;
