import { label_client } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  customerFieldClientRecords,
  selectCrmPipedriveCustomFieldsFromCognisaasDb,
  selectCrmPipedriveCustomFieldsFromIntegration,
} from 'store/modules/Settings/selector';
import {
  getCustomFieldClientRecords_slice,
  getPipedriveCrmCustomFieldsFromDb,
  getPipedriveCrmCustomFieldsFromIntegrationsService,
  updateCrmCustomFieldMappingInDb,
} from 'store/modules/Settings/slice';
import { requestState } from 'store/modules/types';
import { isUndefined } from 'utils/dataHelpers';
import { getCustomerUid } from 'utils/getUid';
import {
  CF_TYPE_DATE,
  CF_TYPE_DROPDOWN,
  CF_TYPE_NUMBER,
  CF_TYPE_STRING,
  PIEPDRIVE_DATE_TYPES,
  PIPEDRIVE_DROPDOWN_TYPES,
  PIPEDRIVE_NUMBER_TYPES,
  PIPEDRIVE_STRING_TYPES,
} from './constants';
import { MappedCustomFieldPipedriveObj } from './types';

const usePipedriveIntegration = () => {
  const dispatch = useAppDispatch();
  const crmPipedriveCustomFieldsFromIntegration = useAppSelector(selectCrmPipedriveCustomFieldsFromIntegration);
  const crmPipedriveCustomFieldsFromCognisaasDb = useAppSelector(selectCrmPipedriveCustomFieldsFromCognisaasDb);
  const clientCustomFields: any = useAppSelector(customerFieldClientRecords);
  const [mappedCustomfieldsDataForPipedrive, setMappedCustomfieldsDataForPipedrive] = useState<
    MappedCustomFieldPipedriveObj[]
  >([]);
  const [customFieldsOptionsForPipedrive, setCustomFieldsOptionsForPipedrive] = useState<any>([]);
  const [cannotMapStatusMessage, setCannotMapStatusMessage] = useState<string>('');
  const [isPipedriveLoading, setIsPipedriveLoading] = useState(true);

  const getAndSetPipedriveIntegrationCustomfields = () => {
    dispatch(getPipedriveCrmCustomFieldsFromIntegrationsService());
    dispatch(getPipedriveCrmCustomFieldsFromDb());
    dispatch(getCustomFieldClientRecords_slice(getCustomerUid()));
  };

  const handlecCustomFieldChange = (
    rowData: MappedCustomFieldPipedriveObj,
    newSelectedCustomField: { id: string; name: string, options?: Record<string, never> }
  ) => {
    let newCfOption: MappedCustomFieldPipedriveObj = { ...rowData };
    let customFieldData: any[] = [];
    if (newSelectedCustomField.id === '000') {
      newCfOption = {
        ...newCfOption,
        cognisaas_custom_field_name: '',
        cognisaas_custom_field_type: '',
        cognisaas_custom_field_uid: '',
      };

      // update in local
      setMappedCustomfieldsDataForPipedrive(
        mappedCustomfieldsDataForPipedrive.map((item: MappedCustomFieldPipedriveObj) => {
          if (item.crm_custom_field_uid === rowData.crm_custom_field_uid) {
            return newCfOption;
          }
          return item;
        })
      );
    } else {
      customFieldData = customFieldsOptionsForPipedrive.filter((cf: any) => cf.field_uid === newSelectedCustomField.id);
      newCfOption = {
        ...rowData,
        cognisaas_custom_field_name: customFieldData[0]['field_name'],
        cognisaas_custom_field_type: customFieldData[0]['field_type'],
        cognisaas_custom_field_uid: customFieldData[0]['field_uid'],

      };
      //   update in local
      setMappedCustomfieldsDataForPipedrive(
        mappedCustomfieldsDataForPipedrive.map((item: MappedCustomFieldPipedriveObj) => {
          if (item.crm_custom_field_uid === rowData.crm_custom_field_uid) {
            return newCfOption;
          }
          return item;
        })
      );
    }
    console.log(newCfOption);
    dispatch(updateCrmCustomFieldMappingInDb(newCfOption));
  };

  const makeMappingForCustomFieldsAcrossPlatforms = () => {
    let message = '';

    if (clientCustomFields.data.length == 0) {
      message += `No ${label_client} custom fields in cognisaas.`;
    } else {
      const newCustomFieldAsOptions = clientCustomFields.data.map((cf: any) => {
        if (cf.field_type === CF_TYPE_NUMBER) {
          return {
            ...cf,
            allowed_types: PIPEDRIVE_NUMBER_TYPES,
          };
        } else if (cf.field_type === CF_TYPE_STRING) {
          return {
            ...cf,
            allowed_types: PIPEDRIVE_STRING_TYPES,
          };
        } else if (cf.field_type === CF_TYPE_DROPDOWN) {
          return {
            ...cf,
            allowed_types: PIPEDRIVE_DROPDOWN_TYPES,
          };
        } else if (cf.field_type === CF_TYPE_DATE) {
          return {
            ...cf,
            allowed_types: PIEPDRIVE_DATE_TYPES,
          };
        }
        else {
          return {
            ...cf,
            allowed_types: [],
          };
        }
      });
      setCustomFieldsOptionsForPipedrive(newCustomFieldAsOptions);
    }

    if (crmPipedriveCustomFieldsFromIntegration.data.length == 0) {
      message += 'No custom fields in your pipedrive account.';
    }

    setCannotMapStatusMessage(message);
    setIsPipedriveLoading(false);

    if (message.length === 0) {
      const customer_uid = getCustomerUid();
      const mappedData = crmPipedriveCustomFieldsFromIntegration.data.map((item: any) => {
        const foundValueInAlreadyMappedData = crmPipedriveCustomFieldsFromCognisaasDb.data.filter(
          (itemInDb: any) => item.key === itemInDb.crm_custom_field_uid
        );
        if (foundValueInAlreadyMappedData.length > 0) {
          return {
            crm_custom_field_uid: item.key,
            crm_custom_field_name: item.name,
            crm_custom_field_type: item.field_type,
            cognisaas_custom_field_uid: foundValueInAlreadyMappedData[0]['cognisaas_custom_field_uid'],
            cognisaas_custom_field_name: foundValueInAlreadyMappedData[0]['cognisaas_custom_field_name'],
            cognisaas_custom_field_type: foundValueInAlreadyMappedData[0]['cognisaas_custom_field_type'],
            customer_uid: customer_uid,
            options: !isUndefined(item.options) ? item.options : null,
          };
        } else {
          return {
            crm_custom_field_uid: item.key,
            crm_custom_field_name: item.name,
            crm_custom_field_type: item.field_type,
            cognisaas_custom_field_uid: '',
            cognisaas_custom_field_name: '',
            cognisaas_custom_field_type: '',
            customer_uid: customer_uid,
            options: !isUndefined(item.options) ? item.options : null,
          };
        }
      });

      setMappedCustomfieldsDataForPipedrive(mappedData);
    }
  };

  useEffect(() => {
    if (
      clientCustomFields.status === 'success' &&
      crmPipedriveCustomFieldsFromIntegration.status === requestState.success &&
      crmPipedriveCustomFieldsFromCognisaasDb.status === requestState.success
    ) {
      makeMappingForCustomFieldsAcrossPlatforms();
    }
  }, [crmPipedriveCustomFieldsFromIntegration, crmPipedriveCustomFieldsFromCognisaasDb, clientCustomFields]);

  useEffect(() => {
    getAndSetPipedriveIntegrationCustomfields();
  }, []);

  return {
    customFieldsOptionsForPipedrive,
    mappedCustomfieldsDataForPipedrive,
    handlecCustomFieldChange,
    cannotMapStatusMessage,
    isPipedriveLoading,
  };
};

export default usePipedriveIntegration;
