import { Box, Grid, Typography, Paper } from '@mui/material';
import  { useState } from 'react';
import Cookies from 'universal-cookie';

import { client_sendmagiclink } from 'store/modules/CustomerPortal/CustomerPortal';
import { useDispatch } from 'react-redux';
import { format } from 'date-fns';
import { ReactComponent as Error } from '../../assets/svgs/error.svg';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import CognisaasButton from 'components/shared/CognisaasButton/CognisaasButton';
import { ReactComponent as Tick } from '../../assets/svgs/tick.svg';

const GenerateNewMagicLink = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [isEmailSent, setIsEmailSent] = useState<boolean>(false);

  const cookies = new Cookies();
  const dispatch = useDispatch();
  const params = cookies.get('url');
  const splitParams = params.split('/');
  splitParams.pop();
  const baseurl = splitParams.join('/');


  const onSubmit = async() => {
    setLoading(true);
    const reqObj = {
      user_email: cookies.get('cogni_user_email'),
      customer_uid: cookies.get('cogni_customer_uid'),
      user_uid: cookies.get('cogni_user_uid'),
      base_url: baseurl + '/',
      last_magiclink_sent_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
    };
    await dispatch(client_sendmagiclink(reqObj));
    setLoading(false);
    setIsEmailSent(true);
  };

  return (
    <Box sx={{ padding: '50px 100px',}}>
      <Paper 
        elevation={2} 
        sx={{ 
            padding: '20px', 
            width:'400px', 
            minHeight:'100px', 
            display:'flex', 
            alignItems:'center', 
            justifyContent:'center',
            textAlign:'center',   
            borderRadius: '4px',
            boxShadow: '0px 4px 26px rgba(0, 0, 0, 0.05)',
            margin:'0 auto'
          }}>
          <Grid item xs={12}>
            {isEmailSent ? 
              <Tick width={100} height={100}/>
              :
            <Error width={200} height={200}/>
            }
            {isEmailSent ?
               <Typography style={{fontSize:'16px'}} className='label-text'>Email has been sent successfully with the magic link</Typography>
                :
                <>
                  <Typography style={{fontSize:'16px'}} className='label-text'>Oops! This magic link has expired</Typography>
                  <Typography style={{fontSize:'16px', marginBottom:'15px'}} className='label-text'>Get a new magic link to sign in instantly</Typography>
                </>
            }
            <div style={{marginBottom:'30px',}}>
            {!isEmailSent && (

              loading ? (
                <LoadingButton loading loadingPosition="start" startIcon={<SaveIcon />} variant="outlined" >
                  Send me a new magic link
                </LoadingButton>
              ) : (
                <CognisaasButton
                  clickHandler={onSubmit}
                  variant="contained"
                  label='Send me a new magic link'
                  isOutlined={false}
                />
                  
              )
            )
            }
            </div>

            
        </Grid>
      </Paper>
    </Box>
  );
};

export default GenerateNewMagicLink;
