import { useDispatch } from 'react-redux';
import {
  addProjectAuditTrial,
  getProjectCustomFields,
  getProjectCustomFieldsDetail,
  updateProjectCustomField,
} from '../../../store/modules/Project/slice';
import { useEffect, useState } from 'react';
import CustomFieldsForm from '../../shared/CustomfieldsForm/CustomfieldsForm';
import { selectProjectCustomFields, selectProjectCustomFieldsDetail } from '../../../store/modules/Project/selector';
import { useAppSelector } from '../../../store/hooks';
import { useParams } from 'react-router-dom';
import CognisaasToast from 'components/shared/CognisaasToast/CognisaasToast';
import { selectAttachmentQueue } from 'store/modules/Attachment/selector';
import { addAttachmentToServer, resetAttachmentQueue } from 'store/modules/Attachment/slice';
import { getLoggedInUserUid } from 'utils/getUid';
import { format } from 'date-fns';

const ProjectCustomFields = (props: any) => {
  const { projectInfo } = props;
  const userPermissions = projectInfo.__user_permissions;

  const projectCustomFields = useAppSelector(selectProjectCustomFields);
  const projectCustomFieldsWithDetail = useAppSelector(selectProjectCustomFieldsDetail);
  const attachmentQueue = useAppSelector(selectAttachmentQueue);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [alertDetails, setAlertDetails] = useState<{
    isVisible: boolean;
    type: 'success' | 'failure';
    message: string;
  }>({ isVisible: false, type: 'success', message: '' });

  const params = useParams();
  const { projectId } = params;

  let cf: any[] = [];
  let cfv: any[] = [];

  useEffect(() => {
    if (projectCustomFields.length == projectCustomFieldsWithDetail.length) {
      mergeBothSelectors();
    }
    // setCustomFields((customFields: any) => [...customFields, ...projectCustomFields]);
  }, [projectCustomFields, projectCustomFieldsWithDetail]);

  useEffect(() => {
    dispatch(getProjectCustomFields(projectId as string));
    dispatch(getProjectCustomFieldsDetail());
  }, []);

  //TODO: Move this function as reusable

  const mergeBothSelectors = () => {
    cf = [...projectCustomFields];
    cfv = [...projectCustomFieldsWithDetail];

    for (let i = 0; i < cf.length; i++) {
      for (let j = 0; j < cfv.length; j++) {
        if (cf[i].field_uid == cfv[j].field_uid) {
          cf[j] = { ...cfv[j], ...cf[i] };
        }
      }
    }
  };

  const attachmentQueueHelper = (attachmentQueue: any) => {
    attachmentQueue.forEach((attachment: any) => {
      dispatch(addAttachmentToServer(attachment));
    });
  };

  const updateProjectCustomFields = async (fields: any) => {
    setIsLoading(true);
    attachmentQueueHelper(attachmentQueue);
    for (const i in fields) {
      for (let j = 0; j < projectCustomFields.length; j++) {
        if (projectCustomFields[j].field_name == i) {
          let detail = projectCustomFields[j];
          if (fields[i].length === 0 && projectCustomFields[j].field_type === 'String') {
            const attachment = attachmentQueue.find(
              (attachment: any) => attachment.id === projectCustomFields[j].field_uid
            );
            detail = { ...projectCustomFields[j], field_value: attachment?.file?.name || '' };
          } else {
            detail = { ...projectCustomFields[j], field_value: fields[i] };
          }
          const detail2 = {
            project_uid: projectId,
            user_uid: getLoggedInUserUid(),
            field: 'Custom Field ' + detail.field_name,
            modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
            old_value: projectCustomFields[j].field_value,
            new_value: detail.field_value,
          };
          if (detail2.old_value != detail2.new_value) {
            if (detail.field_type === 'Date') {
              detail.field_value = format(new Date(detail.field_value), 'yyyy-MM-dd');
            }
            await dispatch(updateProjectCustomField(detail));
            await dispatch(addProjectAuditTrial(detail2));
          }
        }
      }
    }

    dispatch(resetAttachmentQueue());
    await dispatch(getProjectCustomFields(projectId as string));
    setIsLoading(false);
    setAlertDetails({
      isVisible: true,
      type: 'success',
      message: 'Custom fields updated successfully',
    });
  };

  const reloadCustomfieldsData = async () => {
    await dispatch(getProjectCustomFields(projectId as string));
    await dispatch(getProjectCustomFieldsDetail());
  };

  return (
    <>
      {/* <>CustomFields</> */}
      <CustomFieldsForm
        onSaveClickHandler={updateProjectCustomFields}
        customFields={projectCustomFields}
        projectUid={projectId}
        clientUid={projectInfo.client_uid}
        userPermissions={userPermissions}
        isLoading={isLoading}
        callbackToReloadData={reloadCustomfieldsData}
        attachmentQueue={attachmentQueue}
      />
      <CognisaasToast
        message={alertDetails.message}
        type={alertDetails.type}
        open={alertDetails.isVisible}
        onClose={() => {
          setAlertDetails({ isVisible: false, type: alertDetails.type, message: '' });
        }}
      />
    </>
  );
};

export default ProjectCustomFields;
