import styled from 'styled-components';

export const Radio = styled.input`
${({ theme }) => {
    return `
    border-radius: 50%;
    cursor: pointer;
    width: ${theme.space[10]};
    height: ${theme.space[10]};
    margin: ${theme.space[2]};
    &:hover {
        background-color: ${theme.colors.grey_3};
    }
    `;
  }}

  ${({ theme, checked }) =>
    checked &&
    `
        &:checked {
            background-color: ${theme.colors.brand_1};
            border: 1px solid ${theme.colors.brand_1};
    }
  `}
`;