import { useEffect, useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import { getCustomFields } from '../../../../store/modules/Common/slice';
import { transformCustomFieldDataForEntityStructure } from '../UtilityFunction';
import { clientEntityStandardFields } from './clientEntityStandardFields';

const clientEntity = () => {
  const dispatch = useAppDispatch();
  const [clientEntityWithCustomFields, setClientEntityWithCustomFields] = useState<any[]>([]);

  useEffect(() => {
    getCustomFieldsOfClientEntity();
  }, []);

  const getCustomFieldsOfClientEntity = async () => {
    const clientCustomFields = await dispatch(getCustomFields()).unwrap();
    // const clientFields: any = [];
    // clientCustomFields.map((customField: any) => {
    //   clientFields.push({
    //     displayName: customField.field_name,
    //     key: customField.name,
    //     type: customField.field_type,
    //     aggregatable: isAggregatable(customField.field_type),
    //     isCustomField: true,
    //     allowedOperationsList: getAllowedOperations(customField.field_type),
    //   });
    // });
    // console.log('clientFields 2 = ', clientFields);
    console.log(clientCustomFields);

    setClientEntityWithCustomFields({
      ...clientEntityStandardFields,
      ...transformCustomFieldDataForEntityStructure(clientCustomFields),
    });
  };
  console.log('clientEntityStandardFields  = ', clientEntityStandardFields);
  console.log('clientEntityWithCustomFields  = ', clientEntityWithCustomFields);
  return { clientEntityWithCustomFields };
};

export default clientEntity;
