import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Styles from './notification.module.css';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useDispatch } from 'react-redux';
import CognisaasButton from '../../shared/CognisaasButton/CognisaasButton';
import { useAppSelector } from '../../../store/hooks';
import {
  getCustomerDetails,
  resetConfigurationUpdateState,
  updateUseCase_Configuration,
} from '../../../store/modules/Settings/slice';
import { selectConfigurationUpdateState, selectCustomerDetails } from '../../../store/modules/Settings/selector';
import CognisaasCheckBox from 'components/shared/CognisaasCheckbox/CognisaasCheckbox';
import CognisaasToast from 'components/shared/CognisaasToast/CognisaasToast';
import { requestState } from 'store/modules/types';
import {
  label_client,
  label_cs_owner,
  label_project,
  label_project_delivery_manager,
  label_task,
  label_use_case,
  label_use_case_delivery_manager,
} from '../Preferences/CustomLabelCookieHelper';

const Notification = () => {
  const dispatch = useDispatch();
  const customerDetails = useAppSelector(selectCustomerDetails).data;
  const [taskOwner_taskAsigned, setTaskOwner_taskAsigned] = useState(false);
  const [clientContact, setClientContact] = useState(true);
  const [taskOwner_statusChanges, setTaskOwner_statusChanges] = useState(false);
  const [clientContactStatusChange, setClientContactStatusChange] = useState(false);
  const [taskOwner_taskDeleted, setTaskOwner_taskDeleted] = useState(true);
  // const [deliveryManager_taskDeleted, setDeliveryManager_taskDeleted] = useState(false);
  const [taskAssigner, setTaskAssigner] = useState(true);
  // const [deliveryManager_taskAssigned, setDeliveryManager_taskAssigned] = useState(false);
  const [deliveryManager_statusChanged, setDeliveryManager_statusChanged] = useState(false);
  const [taskAssignMailToDeliveryManagerCopy, setTaskAssignMailToDeliveryManagerCopy] = useState<boolean>(false);
  const [taskDeletionDeliveryManagerMail, setTaskDeletionDeliveryManagerMail] = useState<boolean>(false);
  const [sendEmailWhenClientContactIsAdded, setSendEmailWhenClientContactIsAdded] = useState<boolean>(false);
  const [CS_Owner, setCS_Owner] = useState(true);
  const [payloadObj, setPayloadObj] = useState({
    client_mail: '',
    client_task_ind: '',
    currency_code: '',
    customer_amber_threshold: 0,
    customer_uid: '',
    renewal_notification: '',
    subtask_enable: '',
    task_deletion_mail: '',
    task_dependency_date_updation: '',
    task_inprogress_indicator: '',
    task_mail: '',
    task_mail_copy: '',
    task_status_account_owner_mail: '',
    task_status_mail: '',
    task_status_client_contact_mail: '',
    task_status_manager_mail: '',
    time_track: '',
    usecase_calc_ind: '',
    task_assign_mail_dm_copy: '',
    task_deletion_delivery_manager_mail: '',
    project_progress_indicator: '',
    status_report_client: '',
    primary_contact_as_default_owner: '',
    delivery_manager_as_default_owner: '',
    client_contact_added_mail: 0,
    project_plan_view: '',
    usecase_plan_view: '',
    freeze_planned_date_project: true,
    freeze_planned_date_usecase: true,
  });
  const configurationUpdateStatus = useAppSelector(selectConfigurationUpdateState);
  const [alertDetails, setAlertDetails] = useState<{
    isVisible: boolean;
    type: 'success' | 'failure';
    message: string;
  }>({ isVisible: false, type: 'success', message: '' });

  // for alerts
  useEffect(() => {
    if (configurationUpdateStatus.status === requestState.success) {
      setAlertDetails({ isVisible: true, type: 'success', message: 'Updated Successfully!' });
      dispatch(resetConfigurationUpdateState());
    }
  }, [configurationUpdateStatus]);

  useEffect(() => {
    dispatch(getCustomerDetails());
  }, []);

  useEffect(() => {
    setPayloadHelperFunc(customerDetails);
  }, [customerDetails]);

  const setPayloadHelperFunc = (customerDetailsObj: any) => {
    console.log('vineet', payloadObj);
    setPayloadObj({
      client_mail: customerDetailsObj.client_mail,
      client_task_ind: customerDetailsObj.client_task_ind,
      currency_code: customerDetailsObj.currency_code,
      customer_amber_threshold: 100 - customerDetailsObj.customer_amber_threshold,
      customer_uid: customerDetailsObj.customer_uid,
      renewal_notification: customerDetailsObj.renewal_notification,
      subtask_enable: customerDetailsObj.subtask_enable,
      task_deletion_mail: customerDetailsObj.task_deletion_mail,
      task_dependency_date_updation: customerDetailsObj.task_dependency_date_updation,
      task_inprogress_indicator: customerDetailsObj.task_inprogress_indicator,
      task_mail: customerDetailsObj.task_mail,
      task_mail_copy: customerDetailsObj.task_mail_copy,
      task_status_account_owner_mail: customerDetailsObj.task_status_accountowner_mail,
      task_status_mail: customerDetailsObj.task_status_mail,
      task_status_client_contact_mail: customerDetailsObj.task_status_client_contact_mail,
      task_status_manager_mail: customerDetailsObj.task_status_manager_mail,
      time_track: customerDetailsObj.time_track,
      usecase_calc_ind: customerDetailsObj.usecase_calc_ind,
      task_assign_mail_dm_copy: '',
      task_deletion_delivery_manager_mail: '',
      project_progress_indicator: '',
      status_report_client: customerDetailsObj.status_report_client,
      primary_contact_as_default_owner: customerDetailsObj.primary_contact_as_default_owner,
      delivery_manager_as_default_owner: customerDetailsObj.delivery_manager_as_default_owner,
      client_contact_added_mail: customerDetailsObj.client_contact_added_mail,
      project_plan_view: customerDetails.project_plan_view,
      usecase_plan_view: customerDetails.usecase_plan_view,
      freeze_planned_date_project: customerDetails.freeze_planned_date_project,
      freeze_planned_date_usecase: customerDetails.freeze_planned_date_usecase,
    });
    setTaskOwner_taskAsigned(customerDetailsObj.task_mail == '1' ? true : false);
    setTaskAssigner(customerDetailsObj.task_mail_copy == '1' ? true : false);
    setTaskOwner_statusChanges(customerDetailsObj.task_status_mail == '1' ? true : false);
    setClientContactStatusChange(customerDetailsObj.task_status_client_contact_mail == '1' ? true : false);
    setDeliveryManager_statusChanged(customerDetailsObj.task_status_manager_mail == '1' ? true : false);
    setCS_Owner(customerDetailsObj.task_status_accountowner_mail == '1' ? true : false);
    setClientContact(customerDetailsObj.client_mail == '1' ? true : false);
    setTaskAssignMailToDeliveryManagerCopy(customerDetailsObj.task_assign_mail_dm_copy == '1' ? true : false);
    setTaskDeletionDeliveryManagerMail(customerDetailsObj.task_deletion_delivery_manager_mail == '1' ? true : false);
    setTaskOwner_taskDeleted(customerDetailsObj.task_deletion_mail == '1' ? true : false);
    setSendEmailWhenClientContactIsAdded(
      customerDetailsObj.client_contact_added_mail == '1' || customerDetailsObj.client_contact_added_mail == 1
        ? true
        : false
    );
    console.log('vineet1', payloadObj);
  };

  const onClickUpdateButton = () => {
    dispatch(
      updateUseCase_Configuration({
        client_mail: clientContact, // *
        client_task_ind: customerDetails.client_task_ind,
        currency_code: customerDetails.currency_code,
        customer_amber_threshold: 100 - customerDetails.customer_amber_threshold,
        customer_uid: customerDetails.customer_uid,
        renewal_notification: customerDetails.renewal_notification,
        subtask_enable: customerDetails.subtask_enable,
        task_deletion_mail: taskOwner_taskDeleted,
        task_dependency_date_updation: customerDetails.task_dependency_date_updation,
        task_inprogress_indicator: customerDetails.task_inprogress_indicator,
        task_mail: taskOwner_taskAsigned, // *
        task_mail_copy: taskAssigner, // *
        task_status_account_owner_mail: CS_Owner, // *
        task_status_mail: taskOwner_statusChanges, // *
        task_status_client_contact_mail: clientContactStatusChange,
        task_status_manager_mail: deliveryManager_statusChanged, // *
        time_track: customerDetails.time_track,
        usecase_calc_ind: customerDetails.usecase_calc_ind,
        task_assign_mail_dm_copy: taskAssignMailToDeliveryManagerCopy,
        task_deletion_delivery_manager_mail: taskDeletionDeliveryManagerMail,
        project_progress_indicator: customerDetails.project_progress_indicator,
        status_report_client: customerDetails.status_report_client,
        primary_contact_as_default_owner: customerDetails.primary_contact_as_default_owner,
        delivery_manager_as_default_owner: customerDetails.delivery_manager_as_default_owner,
        project_plan_view: customerDetails.project_plan_view,
        usecase_plan_view: customerDetails.usecase_plan_view,
        client_contact_added_mail: sendEmailWhenClientContactIsAdded == true ? '1' : '0',
        freeze_planned_date_project: customerDetails.freeze_planned_date_project,
        freeze_planned_date_usecase: customerDetails.freeze_planned_date_usecase,
      })
    );
  };

  const onClickCancelButton = () => {
    setPayloadHelperFunc(customerDetails);
  };

  return (
    <div className={Styles.noti_main}>
      <Typography variant={'h2'}>Email Notifications</Typography>
      <Box sx={{ flexGrow: 1 }} className={Styles.noti_table_main}>
        <Grid container spacing={1}>
          <Grid container item spacing={3} sx={{ marginBottom: '20px' }}>
            <Grid item xs={4}>
              <Typography variant={'subtitle1'} sx={{ color: '#7A7A7A' }}>
                Notify when
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant={'subtitle1'} sx={{ color: '#7A7A7A' }}>
                Send mail
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant={'subtitle1'} sx={{ color: '#7A7A7A' }}>
                Send a copy
              </Typography>
            </Grid>
          </Grid>

          <Grid container item spacing={3}>
            <Grid item xs={4}>
              <Typography variant={'h3'}>{label_task} is assigned</Typography>
            </Grid>
            <Grid item xs={4}>
              <FormGroup>
                <FormControlLabel
                  sx={{ marginBottom: '10px' }}
                  onClick={() => {
                    setTaskOwner_taskAsigned(!taskOwner_taskAsigned);
                  }}
                  control={<CognisaasCheckBox checked={taskOwner_taskAsigned} />}
                  label={
                    <Typography variant={'subtitle1'} sx={{ marginLeft: '8px' }}>
                      {label_task} Owner
                    </Typography>
                  }
                />
                <FormControlLabel
                  sx={{ marginBottom: '10px' }}
                  onClick={() => {
                    setClientContact(!clientContact);
                  }}
                  control={<CognisaasCheckBox checked={clientContact} />}
                  label={
                    <Typography variant={'subtitle1'} sx={{ marginLeft: '8px' }}>
                      {label_client} Contact
                    </Typography>
                  }
                />
              </FormGroup>
            </Grid>
            <Grid item xs={4}>
              <FormGroup>
                <FormControlLabel
                  sx={{ marginBottom: '10px' }}
                  onClick={() => {
                    setTaskAssigner(!taskAssigner);
                  }}
                  control={<CognisaasCheckBox checked={taskAssigner} />}
                  label={
                    <Typography variant={'subtitle1'} sx={{ marginLeft: '8px' }}>
                      {label_task} Assigner
                    </Typography>
                  }
                />
                <FormControlLabel
                  sx={{ marginBottom: '10px' }}
                  onClick={() => {
                    console.log(taskAssignMailToDeliveryManagerCopy);
                    setTaskAssignMailToDeliveryManagerCopy(!taskAssignMailToDeliveryManagerCopy);
                  }}
                  control={<CognisaasCheckBox checked={taskAssignMailToDeliveryManagerCopy} />}
                  label={
                    <Typography variant={'subtitle1'} sx={{ marginLeft: '8px' }}>
                      {label_use_case_delivery_manager === label_project_delivery_manager
                        ? label_project_delivery_manager
                        : `${label_project_delivery_manager} / ${label_use_case_delivery_manager}`}
                    </Typography>
                  }
                />
              </FormGroup>
            </Grid>
          </Grid>

          <Grid container item spacing={3} sx={{ marginTop: '10px' }}>
            <Grid item xs={4}>
              <Typography variant={'h3'}>{label_task} status changes</Typography>
            </Grid>
            <Grid item xs={4}>
              <FormGroup>
                <FormControlLabel
                  sx={{ marginBottom: '10px' }}
                  onClick={() => {
                    setTaskOwner_statusChanges(!taskOwner_statusChanges);
                  }}
                  control={<CognisaasCheckBox checked={taskOwner_statusChanges} />}
                  label={
                    <Typography variant={'subtitle1'} sx={{ marginLeft: '8px' }}>
                      {label_task} Owner
                    </Typography>
                  }
                />
                <FormControlLabel
                  sx={{ marginBottom: '10px' }}
                  onClick={() => {
                    setClientContactStatusChange(!clientContactStatusChange);
                  }}
                  control={<CognisaasCheckBox checked={clientContactStatusChange} />}
                  label={
                    <Typography variant={'subtitle1'} sx={{ marginLeft: '8px' }}>
                      {label_client} Contact
                    </Typography>
                  }
                />
              </FormGroup>
            </Grid>
            <Grid item xs={4}>
              <FormGroup>
                <FormControlLabel
                  sx={{ marginBottom: '10px' }}
                  onClick={() => {
                    setDeliveryManager_statusChanged(!deliveryManager_statusChanged);
                  }}
                  control={<CognisaasCheckBox checked={deliveryManager_statusChanged} />}
                  label={
                    <Typography variant={'subtitle1'} sx={{ marginLeft: '8px' }}>
                      {label_use_case_delivery_manager === label_project_delivery_manager
                        ? label_project_delivery_manager
                        : `${label_project_delivery_manager} / ${label_use_case_delivery_manager}`}
                    </Typography>
                  }
                />
                <FormControlLabel
                  sx={{ marginBottom: '10px' }}
                  onClick={() => {
                    setCS_Owner(!CS_Owner);
                  }}
                  control={<CognisaasCheckBox checked={CS_Owner} />}
                  label={
                    <Typography variant={'subtitle1'} sx={{ marginLeft: '8px' }}>
                      {label_cs_owner}
                      <Typography variant={'subtitle2'}>(Relevent {label_client}s)</Typography>
                    </Typography>
                  }
                />
              </FormGroup>
            </Grid>
          </Grid>

          <Grid container item spacing={3} sx={{ marginTop: '10px' }}>
            <Grid item xs={4}>
              <Typography variant={'h3'}>{label_task} is deleted</Typography>
            </Grid>
            <Grid item xs={4}>
              <Grid>
                <FormGroup>
                  <FormControlLabel
                    sx={{ marginBottom: '10px' }}
                    onClick={() => {
                      setTaskOwner_taskDeleted(!taskOwner_taskDeleted);
                    }}
                    control={<CognisaasCheckBox checked={taskOwner_taskDeleted} />}
                    label={
                      <Typography variant={'subtitle1'} sx={{ marginLeft: '8px' }}>
                        {label_task} Owner
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    sx={{ marginBottom: '10px' }}
                    onClick={() => {
                      setTaskDeletionDeliveryManagerMail(!taskDeletionDeliveryManagerMail);
                    }}
                    control={<CognisaasCheckBox checked={taskDeletionDeliveryManagerMail} />}
                    label={
                      <Typography variant={'subtitle1'} sx={{ marginLeft: '8px' }}>
                        {label_use_case_delivery_manager === label_project_delivery_manager
                          ? label_project_delivery_manager
                          : `${label_project_delivery_manager} / ${label_use_case_delivery_manager}`}
                        <Typography variant={'subtitle2'}>
                          (Relevent {label_project}/{label_use_case}&apos;s)
                        </Typography>
                      </Typography>
                    }
                  />
                </FormGroup>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <FormGroup></FormGroup>
            </Grid>
          </Grid>

          <Grid container item spacing={3} sx={{ marginTop: '10px' }}>
            <Grid item xs={4}>
              <Typography variant={'h3'}>Client Contact is added</Typography>
            </Grid>
            <Grid item xs={4}>
              <Grid>
                <FormGroup>
                  <FormControlLabel
                    sx={{ marginBottom: '10px' }}
                    onClick={() => {
                      setSendEmailWhenClientContactIsAdded(!sendEmailWhenClientContactIsAdded);
                    }}
                    control={<CognisaasCheckBox checked={sendEmailWhenClientContactIsAdded} />}
                    label={
                      <Typography variant={'subtitle1'} sx={{ marginLeft: '8px' }}>
                        Client Contact
                      </Typography>
                    }
                  />
                </FormGroup>
              </Grid>
            </Grid>
            <Grid item xs={4}></Grid>
          </Grid>
        </Grid>
      </Box>
      <Grid sx={{ margin: '24px 0' }}>
        <CognisaasButton
          label={'Update'}
          isOutlined={false}
          clickHandler={onClickUpdateButton}
          sx={{ marginRight: '20px' }}
        />
        <CognisaasButton label={'Cancel'} isOutlined={true} clickHandler={onClickCancelButton} />
      </Grid>
      {/* this is new alerts */}
      <CognisaasToast
        open={alertDetails.isVisible}
        onClose={() => setAlertDetails({ isVisible: false, type: alertDetails.type, message: '' })}
        message={alertDetails.message}
        type={alertDetails.type}
      />
    </div>
  );
};
export default Notification;
