import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { projectAPI } from '../../../../api/modules/project';
import { requestState } from '../../types';

interface projectsType {
  projects: Array<any>;
  status: requestState.idle | requestState.loading | requestState.success | requestState.failed;
  error: null | undefined | string;
}

const initialState: projectsType = {
  projects: [],
  status: requestState.idle,
  error: null,
} as projectsType;

async function getOpenProjects() {
  const { data } = await projectAPI.getOpenProjects();
  return data.result;
}

async function getClosedProjects() {
  const { data } = await projectAPI.getClosedProjects();
  return data.result;
}

export const fetchAllProjects = createAsyncThunk('/allProjects', async () => {
  let openProjects = await getOpenProjects();
  let closedProjects = await getClosedProjects();

  if (openProjects == null) {
    openProjects = [];
  }
  if (closedProjects == null) {
    closedProjects = [];
  }

  const allProjects: any[] = [...openProjects, ...closedProjects];
  console.log('slice all projects reports', allProjects);
  return allProjects;
});

const allProjectsSlice = createSlice({
  name: 'allProjects',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchAllProjects.pending, (state) => {
        state.status = requestState.loading;
      })
      .addCase(fetchAllProjects.fulfilled, (state, action) => {
        state.status = requestState.success;
        state.projects = action.payload;
      })
      .addCase(fetchAllProjects.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      });
  },
});

export const getAllProjects = (state: projectsType) => state.projects;
const reportsAllProjectsReducer = allProjectsSlice.reducer;
export default reportsAllProjectsReducer;
