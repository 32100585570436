import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { clientListRequiredApis } from '../../../../api/modules/common';
import { requestState } from '../../types';
import { statusFilters } from '../../../../utils/filters';
import { segmentModel, userDetailModel, industryModel, journeyStageModel } from '../../../../api/responseInterface';

export interface selectedFiltersInManageClientspageInterface {
  Stage: string[];
  Industry: string[];
  Segment: string[];
  'CS Owner': string[];
  'Sales Owner': string[];
  Status: string[];
}
interface availableFilters {
  stageFilters: journeyStageModel[];
  statusFilters: any[];
  industryFilters: industryModel[];
  segmentFilters: segmentModel[];
  companyUsers: userDetailModel[];
  csOwnersFilters: userDetailModel[];
  salesOwnersFilters: userDetailModel[];
  customFields: any[];
  status: requestState;
  error: null | undefined | string;
  selectedMainFilters: string[];
  selectedFiltersInManageClientspage: selectedFiltersInManageClientspageInterface;
  columnsToShowInClientListPage: any; //From backend it is  a string
}
const initialState: availableFilters = {
  stageFilters: [],
  statusFilters: [...statusFilters],
  industryFilters: [],
  segmentFilters: [],
  csOwnersFilters: [],
  salesOwnersFilters: [],
  companyUsers: [],
  customFields: [],
  selectedMainFilters: [],
  selectedFiltersInManageClientspage: {
    Stage: [],
    Industry: [],
    Segment: [],
    'CS Owner': [],
    'Sales Owner': [],
    Status: [],
  },
  columnsToShowInClientListPage: {},
  status: requestState.idle,
  error: null,
};

export const setUpFiltersForClientListPage = createAsyncThunk('setUpFiltersForClientListPage', async () => {
  const ClientListFiltersData: any = {};
  await Promise.all(
    Object.keys(clientListRequiredApis).map(async (api, i) => {
      const res = await Object.values(clientListRequiredApis)[i]();
      if (api == 'getCustomerDetail') {
        ClientListFiltersData[api] = res.data.result.client_list_columns;
      } else {
        ClientListFiltersData[api] = res.data.result ? [...res.data.result] : [];
      }
    })
  );
  const clientListPageFilters = makeDataForClientListFilters(ClientListFiltersData);
  return clientListPageFilters;
});

const makeDataForClientListFilters = (data: Record<string,[]>) => {
  const clientListFilters: any = {};
  for (const filter in data) {
    if (filter == 'getIndustryValue') {
      clientListFilters.industryFilter = [...data[filter]];
    } else if (filter == 'getSegmentValue') {
      clientListFilters.segmentFilter = [...data[filter]];
    } else if (filter == 'getCompanyUsers') {
      clientListFilters.usersFilter = [...data[filter]];
    } else if (filter == 'getCustomFields') {
      clientListFilters.customFieldsFilter = [...data[filter]];
    } else if (filter == 'getCustomerDetail') {
      clientListFilters.columnsForClientsListTable = data[filter];
    } else {
      clientListFilters.stageFilter = data.getCustomJourneyState_Data.length !=0 ?data.getCustomJourneyState_Data:data.getJourneyStage;
    }
  }
  console.log('Data for the client',clientListFilters);
  return clientListFilters;
};
const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    addMainFilter: (state, action: PayloadAction<keyof selectedFiltersInManageClientspageInterface>) => {
      // console.log(action.payload);

      if (action.payload == 'Status') {
        if (state.selectedMainFilters.length && state.selectedMainFilters.every((val) => val == 'Status')) {
          return;
        } else {
          state.selectedMainFilters = [...state.selectedMainFilters, action.payload];
        }
      } else {
        state.selectedMainFilters = [...state.selectedMainFilters, action.payload];
      }
    },
    removeMainFilter: (state, action: PayloadAction<keyof selectedFiltersInManageClientspageInterface>) => {
      state.selectedMainFilters = state.selectedMainFilters.filter((filter) => filter !== action.payload);
      state.selectedFiltersInManageClientspage[action.payload] = [];
    },
    resetMainFilters: (state) => {
      state.selectedMainFilters = [];
    },
    addFiltersToRespectivePage: (state, action: PayloadAction<any>) => {
      const payloadData = action.payload;
      const mainFilter: any = payloadData.mainFilter;
      const path: string = payloadData.path;
      const val: string = payloadData.val;
      console.log(path, val, mainFilter);
      if (path == '/client-list') {
        if (mainFilter == 'Status') {
          state.selectedFiltersInManageClientspage['Status'] = [];
          state.selectedFiltersInManageClientspage['Status'] = [
            ...state.selectedFiltersInManageClientspage['Status'],
            val,
          ];
        } else {
          // console.log('yes it is client list and it is getting added');

          state.selectedFiltersInManageClientspage[mainFilter as keyof selectedFiltersInManageClientspageInterface] = [
            ...state.selectedFiltersInManageClientspage[
              mainFilter as keyof selectedFiltersInManageClientspageInterface
            ],
            val,
          ];
        }
      }
    },
    removeFiltersForRespectivePage: (state, action: PayloadAction<any>) => {
      const payloadData = action.payload;
      const mainFilter: any = payloadData.mainFilter;
      const path: string = payloadData.path;
      const val: string = payloadData.val;
      // console.log(path, val, mainFilter);
      if (path == '/client-list') {
        // console.log('yes it is client list and it is getting added');
        state.selectedFiltersInManageClientspage[mainFilter as keyof selectedFiltersInManageClientspageInterface] =
          state.selectedFiltersInManageClientspage[
            mainFilter as keyof selectedFiltersInManageClientspageInterface
          ].filter((item: any) => item != val);

        // console.log(test);

        // state.selectedFiltersInManageClientspage[mainFilter as keyof selectedFiltersInManageClientspage] = [
        //   ...state.selectedFiltersInManageClientspage[mainFilter as keyof selectedFiltersInManageClientspage],
        //   val,
        // ];
      }
    },
    addColumnInClientListTable: (state, action: PayloadAction<string>) => {
      const col = action.payload;
      state.columnsToShowInClientListPage = { ...state.columnsToShowInClientListPage, [col]: true };
    },
    removeColumnFromClientListTable: (state, action: PayloadAction<string>) => {
      console.log(action.payload);
      const k = action.payload;
      state.columnsToShowInClientListPage = { ...state.columnsToShowInClientListPage, [k]: false };
      // const updatedColumnList = state.columnsToShowInClientListPage.split(',').filter((col: any) => {
      //   return col != action.payload;
      // });
      // console.log(updatedColumnList);

      // state.columnsToShowInClientListPage = updatedColumnList.join(',');
    },
  },
  extraReducers(builder) {
    builder
      .addCase(setUpFiltersForClientListPage.pending, (state) => {
        state.status = requestState.loading;
      })
      .addCase(setUpFiltersForClientListPage.fulfilled, (state, action) => {
        state.status = requestState.success;
        state.stageFilters = [...action.payload.stageFilter];
        state.companyUsers = [...action.payload.usersFilter];
        state.csOwnersFilters = [...action.payload.usersFilter];
        state.salesOwnersFilters = [...action.payload.usersFilter];
        state.industryFilters = [...action.payload.industryFilter];
        state.segmentFilters = [...action.payload.segmentFilter];
        state.customFields = [...action.payload.customFieldsFilter];
        const cols = action.payload.columnsForClientsListTable.split(',');
        console.log(cols);

        // const columnFilter = cols.map((col: string) => {
        //   state.columnsToShowInClientListPage[col] = true;
        // });
        for (let i = 0; i < cols.length; i++) {
          state.columnsToShowInClientListPage[cols[i]] = true;
        }
        console.log(state.columnsToShowInClientListPage);

        // state.columnsToShowInClientListPage = columnFilter;
        // state.columnsToShowInClientListPage = action.payload.columnsForClientsListTable;
      })
      .addCase(setUpFiltersForClientListPage.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      });
  },
});

export const filtersReducer = filtersSlice.reducer;
export const {
  addMainFilter,
  removeMainFilter,
  resetMainFilters,
  addFiltersToRespectivePage,
  removeFiltersForRespectivePage,
  removeColumnFromClientListTable,
  addColumnInClientListTable,
} = filtersSlice.actions;

export default filtersReducer;
