import { apis } from '../axiosInstance';
import { projectRoutes } from '../route';

import Cookies from 'universal-cookie';

const cookies = new Cookies();

const getOpenProjects = () =>
  apis.phpAxiosInstance.get(projectRoutes.openProjects(cookies.get('cogni_customer_uid') as string), {
    headers: { JWT: 'true' },
  });

const getClosedProjects = () =>
  apis.phpAxiosInstance.get(projectRoutes.closedProjects(cookies.get('cogni_customer_uid') as string), {
    headers: { JWT: 'true' },
  });

const getAllProjects = () =>
  apis.phpAxiosInstance.get(projectRoutes.allProjects(cookies.get('cogni_customer_uid') as string), {
    headers: { JWT: 'true' },
  });

const getCustomFieldsOfProject = (project_uid: string) =>
  apis.phpAxiosInstance.get(projectRoutes.customFields(project_uid), { headers: { JWT: 'true' } });

const getProjectCustomFieldsDetail = () =>
  apis.phpAxiosInstance.get(projectRoutes.customFieldsDetail(cookies.get('cogni_customer_uid') as string), {
    headers: { JWT: 'true' },
  });

const updateProjectCustomField = (fieldDetail: any) =>
  apis.phpAxiosInstance.post(projectRoutes.updateProjectCustomField(), fieldDetail, { headers: { JWT: 'true' } });

const getProjectInformation = (association_uid: string, project_uid: string) =>
  apis.phpAxiosInstance.get(projectRoutes.getProjectInformation(association_uid, project_uid), {
    headers: { JWT: 'true' },
  });

const addBlankProject = (association_uid: string) =>
  apis.phpAxiosInstance.post(projectRoutes.addBlankProject(), { association_uid }, { headers: { JWT: 'true' } });

const addProjectInformation = (serverPayload: Record<string, any>) =>
  apis.phpAxiosInstance.post(projectRoutes.addProjectInformation(), serverPayload, { headers: { JWT: 'true' } });

const createProjectFromProjectTemplate = (serverPayload: Record<string, any>) =>
  apis.phpAxiosInstance.post(projectRoutes.createProjectFromProjectTemplate(), serverPayload, {
    headers: { JWT: 'true' },
  });

const createUsecaseFromUsecaseTemplate = (serverPayload: Record<string, any>) =>
  apis.phpAxiosInstance.post(projectRoutes.createUsecaseFromUsecaseTemplate(), serverPayload, {
    headers: { JWT: 'true' },
  });

const createUsecaseFromUsecaseTemplateInBulk = (serverPayload: Record<string, any>) =>
  apis.phpAxiosInstance.post(projectRoutes.createUsecaseFromUsecaseTemplateInBulk(), serverPayload, {
    headers: { JWT: 'true' },
  });

const addProjectAuditTrial = (detail: any) =>
  apis.phpAxiosInstance.post(projectRoutes.addProjectAuditTrial(), detail, { headers: { JWT: 'true' } });

const getProjectInformationWithCaseTaskByUid = (project_uid: string) =>
  apis.phpAxiosInstance.get(projectRoutes.getProjectInformationWithCaseTaskByUid(project_uid), {
    headers: { JWT: 'true' },
  });

const deleteProject = (detail: any) =>
  apis.phpAxiosInstance.post(projectRoutes.deleteProject(), detail, { headers: { JWT: 'true' } });

const updateProject = (detail: any) =>
  apis.phpAxiosInstance.post(projectRoutes.updateProject(), detail, { headers: { JWT: 'true' } });

const getProjectAudit = (project_uid: string) =>
  apis.phpAxiosInstance.get(projectRoutes.getProjectAuditTrail(project_uid), { headers: { JWT: 'true' } });

const deleteBlankProject = () =>
  apis.phpAxiosInstance.get(projectRoutes.deleteBlankProject(), { headers: { JWT: 'true' } });

const getProjectMembers = (project_uid: string, client_uid: string) =>
  apis.phpAxiosInstance.get(projectRoutes.getProjectMembers(project_uid, client_uid), { headers: { JWT: 'true' } });

const addMembersForProject = (detail: any) =>
  apis.phpAxiosInstance.post(projectRoutes.addMembersForProject(), detail, { headers: { JWT: 'true' } });

const removeUserFromProject = (detail: any) =>
  apis.phpAxiosInstance.post(projectRoutes.removeUserFromProject(), detail, { headers: { JWT: 'true' } });

const getProjectByUid = (project_uid: any) =>
  apis.phpAxiosInstance.get(projectRoutes.getProjectByUid(project_uid), { headers: { JWT: 'true' } });

const getCfForAllProjectsByCustomerUid = () =>
  apis.phpAxiosInstance.get(
    projectRoutes.getCustomFieldValuesOfAllProjects(cookies.get('cogni_customer_uid') as string),
    {
      headers: { JWT: 'true' },
    }
  );

const getProjectComments = (project_uid: string) =>
  apis.phpAxiosInstance.get(projectRoutes.getProjectCommentsByProjectUid(project_uid), {
    headers: { JWT: 'true' },
  });

const addProjectCommentByProjectUid = (detail: any) =>
  apis.phpAxiosInstance.post(projectRoutes.addProjectComment(), detail, { headers: { JWT: 'true' } });

const updateProjectLevelCommentDetails = (detail: any) =>
  apis.phpAxiosInstance.post(projectRoutes.updateProjectLevelComment(), detail, { headers: { JWT: 'true' } });

const deleteProjectLevelComment = (detail: any) =>
  apis.phpAxiosInstance.post(projectRoutes.deleteProjectLevelComment(), detail, { headers: { JWT: 'true' } });

const cloneLiveProjectOfClient = (detail: any) =>
  apis.phpAxiosInstance.post(projectRoutes.cloentLiveProjectOfClient(), detail, { headers: { JWT: 'true' } });
const getOpportunitiesOfCLient = (client_uid: any) =>
  apis.phpAxiosInstance.get(projectRoutes.getOpportunitiesOfClient(client_uid), { headers: { JWT: 'true' } });

const getTasksPath = (project_uid: any, type: string) =>
  apis.phpAxiosInstance.get(projectRoutes.getTasksPath(project_uid, type), { headers: { JWT: 'true' } });

const updateProjectEndDates = (detail: any) =>
  apis.phpAxiosInstance.post(projectRoutes.updateProjectEndDates(), detail, { headers: { JWT: 'true' } });

const findCriticalPath = (project_uid: any, type: string) =>
  apis.phpAxiosInstance.get(projectRoutes.findCriticalPath(project_uid, type), { headers: { JWT: 'true' } });

const getPathOfThisTask = (task_uid: any, project_uid: string, type: string) =>
  apis.phpAxiosInstance.get(projectRoutes.getPathOfThisTask(task_uid, project_uid, type), { headers: { JWT: 'true' } });

const getTaskParents = (detail: any) =>
  apis.phpAxiosInstance.post(projectRoutes.getTaskParents(), detail, { headers: { JWT: 'true' } });

const getTaskChildren = (detail: any) =>
  apis.phpAxiosInstance.post(projectRoutes.getTaskChildren(), detail, { headers: { JWT: 'true' } });

const updateTaskDelay = (task_uid: string, delayed_by: number) =>
  apis.phpAxiosInstance.get(projectRoutes.updateTaskDelay(task_uid, delayed_by), { headers: { JWT: 'true' } });
const handleProjectPlannedDateUpdate = (detail: any) =>
  apis.phpAxiosInstance.post(projectRoutes.handleProjectPlannedDateUpdate(), detail, { headers: { JWT: 'true' } });

export const projectAPI = {
  getOpenProjects,
  getClosedProjects,
  getAllProjects,
  getCustomFieldsOfProject,
  getProjectCustomFieldsDetail,
  updateProjectCustomField,
  getProjectInformation,
  addBlankProject,
  addProjectInformation,
  createProjectFromProjectTemplate,
  createUsecaseFromUsecaseTemplate,
  createUsecaseFromUsecaseTemplateInBulk,
  addProjectAuditTrial,
  getProjectInformationWithCaseTaskByUid,
  deleteProject,
  updateProject,
  getProjectAudit,
  deleteBlankProject,
  getProjectMembers,
  addMembersForProject,
  removeUserFromProject,
  getProjectByUid,
  getCfForAllProjectsByCustomerUid,
  getProjectComments,
  addProjectCommentByProjectUid,
  updateProjectLevelCommentDetails,
  deleteProjectLevelComment,
  cloneLiveProjectOfClient,
  getOpportunitiesOfCLient,
  getTasksPath,
  updateProjectEndDates,
  findCriticalPath,
  getPathOfThisTask,
  getTaskParents,
  getTaskChildren,
  updateTaskDelay,
  handleProjectPlannedDateUpdate,
};
