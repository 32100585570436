import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { requestState } from '../../types';
import { commonAPI } from '../../../../api/modules/common';
import { customReportAPI } from '../../../../api/modules/customReports';
import { isNull } from 'utils/dataHelpers';
import { DeleteCustomReportType, CloneCustomReportType } from './types';

interface customReportType {
  getClients: any[];
  getClientsStatus: requestState;
  getClientsError: null | undefined | string;

  getProjects: any[];
  getProjectsStatus: requestState;
  getProjectsError: null | undefined | string;

  getUsecases: any[];
  getUsecaseStatus: requestState;
  getUsecasesError: null | undefined | string;

  getTasks: any[];
  getTaskssStatus: requestState;
  getTaskssError: null | undefined | string;

  getCustomReport: any;
  getCustomReportStatus: requestState;
  getCustomReportError: null | undefined | string;

  getDetailsOfAllEntity: any[];
  getDetailsOfAllEntityStatus: requestState;
  getDetailsOfAllEntityError: null | undefined | string;

  customReportDetailsByReportUid: {
    data: any;
    status: requestState;
    error: null | undefined | string;
  };

  getReportConfigByReportUid: {
    data: any;
    status: requestState;
    error: null | undefined | string;
  };
}

const initialState: customReportType = {
  getClients: [],
  getClientsStatus: requestState.idle,
  getClientsError: null,

  getProjects: [],
  getProjectsStatus: requestState.idle,
  getProjectsError: null,

  getUsecases: [],
  getUsecaseStatus: requestState.idle,
  getUsecasesError: null,

  getTasks: [],
  getTaskssStatus: requestState.idle,
  getTaskssError: null,

  getDetailsOfAllEntity: [],
  getDetailsOfAllEntityStatus: requestState.idle,
  getDetailsOfAllEntityError: null,

  getCustomReport: null,
  getCustomReportStatus: requestState.idle,
  getCustomReportError: null,

  customReportDetailsByReportUid: {
    data: [],
    status: requestState.idle,
    error: null,
  },

  getReportConfigByReportUid: {
    data: [],
    status: requestState.idle,
    error: null,
  },
};

export const getCustomReportDetailsByReportUid = createAsyncThunk(
  'customReports/customReportDetailsByReportUid_createAsyncThunk',
  async (report_uid: string) => {
    const { data } = await customReportAPI.getGeneratedCustomReportUsingUid(report_uid);
    console.log(data);
    return data.result ?? [];
  }
);

export const getReportConfigByReportUid = createAsyncThunk(
  'customReports/getReportConfigByReportUid',
  async (report_uid: string) => {
    const { data } = await commonAPI.getReportDetail(report_uid);
    return data.result ?? [];
  }
);

export const updateCustomReport = createAsyncThunk(
  'customReports/updateReportConfigByReportUid',
  async (detail: any) => {
    const { data } = await commonAPI.updateCustomReport(detail);
    return data.result ?? [];
  }
);

export const getClientssOfCustomer = createAsyncThunk('report/getClients', async () => {
  // const {data} ;
  return [];
});

export const getProjectsOfCustomer = createAsyncThunk('report/getProjects', async () => {
  // const {data} ;
  return [];
});

export const getUsecasesOfCustomer = createAsyncThunk('report/getUsecases', async () => {
  // const {data} ;
  return [];
});

export const getTaskssOfCustomer = createAsyncThunk('report/getTasks', async () => {
  // const {data} ;
  return [];
});

export const getCustomReport = createAsyncThunk('report/getTasks', async () => {
  // const {data} ;
  return [];
});

export const getAllEntityDetails = createAsyncThunk('report/getAllEntityDetails', async () => {
  // const {data} ;
  const { data } = await commonAPI.getAllEntitiesOfCustomer();
  return data ?? [];
});

export const createCustomReport = createAsyncThunk('report/createCustomReport', async (detail: any) => {
  // const {data} ;
  const { data } = await commonAPI.createCustomReport(detail);
  return data ?? [];
});

export const getCustomReports = createAsyncThunk('report/getCustomReports', async (details: any) => {
  const { data } = await commonAPI.getCustomReports(details);
  return data ?? [];
});

export const getReportByUid = createAsyncThunk('report/getReport', async (uid: any) => {
  const { data } = await commonAPI.getReport(uid);
  return data ?? [];
});

export const deleteCustomReport = createAsyncThunk(
  'report/deleteCustomReport',
  async (details: DeleteCustomReportType) => {
    const { data } = await customReportAPI.deleteCustomReportByUid(details);
    return data ?? [];
  }
);

export const cloneCustomReport = createAsyncThunk(
  'report/cloneCustomReport',
  async (details: CloneCustomReportType) => {
    const { data } = await customReportAPI.cloneAnyCustomReport(details);
    return data ?? [];
  }
);

const customReportSlice = createSlice({
  name: 'customReportSlice',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder

      // customReportDetailsByReportUid
      .addCase(getCustomReportDetailsByReportUid.pending, (state) => {
        state.customReportDetailsByReportUid.status = requestState.loading;
      })
      .addCase(getCustomReportDetailsByReportUid.fulfilled, (state, action) => {
        state.customReportDetailsByReportUid.status = requestState.success;
        console.log('custom report details  = ', action.payload);
        if (isNull(action.payload)) state.customReportDetailsByReportUid.data = [];
        else state.customReportDetailsByReportUid.data = action.payload;
      })
      .addCase(getCustomReportDetailsByReportUid.rejected, (state, action) => {
        state.customReportDetailsByReportUid.status = requestState.failed;
        state.customReportDetailsByReportUid.error = action.error.message;
      });
  },
});

export const customReportReducer = customReportSlice.reducer;
export default customReportReducer;
