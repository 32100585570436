import { Stack } from '@mui/material';
import CognisaasButton from '../../shared/CognisaasButton/CognisaasButton';
import { useState } from 'react';
import BasicModal from '../../shared/CognisaasModal/CognisaasModal';
import CreatePhase from './CreatePhase';
import { EmptyPhaseContainer } from './Phase.styles';

const EmptyPhase = (props: any) => {
  const [createPhaseModal, setCreatePhaseModal] = useState<boolean>(false);

  const changeCreatePhaseModalVisibilityStatus = () => {
    setCreatePhaseModal(!createPhaseModal);
  };

  return (
    <>
      <EmptyPhaseContainer  >
        <Stack px={3} mt={2} direction="row" justifyContent="center" alignItems="center">
          <CognisaasButton
            clickHandler={changeCreatePhaseModalVisibilityStatus}
            sx={{ padding: '8px 32px', width: '250px' }}
            isOutlined={true}
            label="Add Phase"
          />
        </Stack>
      </EmptyPhaseContainer >
      {createPhaseModal && (
        <BasicModal height={224} width={570} open={true} onClose={() => setCreatePhaseModal(!createPhaseModal)}>
          <CreatePhase
            caseUid={props.caseUid}
            associationUid={props.associationUid}
            projectUid={props.projectUid}
            closeModal={changeCreatePhaseModalVisibilityStatus}
            sections={props.sections || []}
          />
        </BasicModal>
      )}
    </>
  );
};

export default EmptyPhase;
