import React, { useState, SyntheticEvent, useRef, useEffect, useMemo } from 'react';
import { GanttProps, Component } from 'components/ResourceManagement/types/public-types';
import { GridProps } from 'components/ResourceManagement/components/grid';
import { seedDatesX, ganttDateRangeX } from 'components/ResourceManagement/helpers/date-helper';
import { CalendarProps } from 'components/ResourceManagement/components/calendar';
import { ComponentGanttContentProps } from 'components/ResourceManagement/components/resource-gantt/component-gantt-content';
import { VerticalScroll } from 'components/ResourceManagement/components/other/vertical-scroll';
import { ComponentListProps, ComponentList } from 'components/ResourceManagement/components/list';
import { ComponentGantt } from 'components/ResourceManagement/components/resource-gantt/component-gantt';
import { BarComponent } from 'components/ResourceManagement/types/bar-component';
import { convertToBarComponents } from 'components/ResourceManagement/helpers/bar-helper';
import { GanttEvent } from 'components/ResourceManagement/types/gantt-component-actions';
import { DateSetup } from 'components/ResourceManagement/types/date-setup';
import styles from './gantt.module.css';
import { removeHiddenComponents, sortComponents } from 'components/ResourceManagement/helpers/component-helper';
import { Box } from 'components/ResourceManagement/design-system/primitives';
import styled from 'styled-components';
import { useWindowSize } from '../shared/useWindowSize';
import { columnWidthMap } from 'components/ResourceManagement/config';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectExpandedItems, setExpandedItems } from 'store/modules/ResourceManagement';
// import { getDateInYYYYMMDD } from 'components/ResourceManagement/utils/date';
import { getDurationInX } from 'components/ResourceManagement/utils/transformers';

const headerHeight = 56;
const rowHeight = 70;
const offsetHeight = 950;
const locale = 'en-GB';
const barFill = 30;
const stepCount = 1;

const StyledComponentWrapper = styled(Box)`
  background-color: ${({ theme }) => theme.colors.grey_5};
`;

function loadScrollToTargetDate(targetDate: Date, viewMode: string, columnWidth: number): number {
  const now = new Date().toLocaleDateString('en-US');
  const _startDate = targetDate.toLocaleDateString('en-US');
  const dayDistanceBetweenStartCurrentDates = getDurationInX(_startDate, now, 'days');
  const weekDistanceBetweenStartCurrentDates = getDurationInX(_startDate, now, 'weeks');
  const monthDistanceBetweenStartCurrentDates = getDurationInX(_startDate, now, 'months');

  const distanceMap: Record<string, number> = {
    Day: dayDistanceBetweenStartCurrentDates,
    Week: weekDistanceBetweenStartCurrentDates,
    Month: monthDistanceBetweenStartCurrentDates,
  };
  const scrollValue = columnWidth * distanceMap[viewMode];
  return scrollValue;
}

export const ResourceGantt: React.FC<GanttProps> = ({
  primeComponents,
  onDateChange,
  onSelect,
  onExpanderClick,
  viewMode,
  mode,
  events,
}) => {
  const [docuHeight] = useWindowSize();
  const [ganttHeight, setGanntHeightByDocumentHeight] = useState(docuHeight);
  const columnWidth = columnWidthMap[viewMode];
  const dispatch = useAppDispatch();
  const selectedExpandedItems = useAppSelector(selectExpandedItems);

  const wrapperRef = useRef<HTMLDivElement>(null);
  const componentListRef = useRef<HTMLDivElement>(null);
  // console.log({ events, mode });
  const [dateSetup, setDateSetup] = useState<DateSetup>(() => {
    const [startDate, endDate] = ganttDateRangeX(events, viewMode, stepCount);
    const dates = seedDatesX(new Date(startDate), new Date(endDate), viewMode);
    // console.log('seed', { dates, viewMode, startDate, endDate, });
    return { viewMode, dates };
  });
  const [componentListWidth, setComponentListWidth] = useState(0);
  const [barComponents, setBarComponents] = useState<BarComponent[]>([]);
  const [ganttEvent, setGanttEvent] = useState<GanttEvent>({
    action: '',
  });
  const componentHeight = useMemo(() => (rowHeight * barFill) / 100, []);

  const [selectedComponent, setSelectedComponent] = useState<BarComponent>();

  const svgWidth = dateSetup.dates.length * columnWidth;
  const ganttFullHeight = barComponents.length * rowHeight;

  // console.log({ dateSetup });

  const [scrollY, setScrollY] = useState(0);
  const [scrollX, setScrollX] = useState(0);
  const [ignoreScrollEvent, setIgnoreScrollEvent] = useState(false);
  const [startDateForScroll, setStartDateForScroll] = useState(new Date());
  useEffect(() => {
    setGanntHeightByDocumentHeight(docuHeight - offsetHeight);
  }, [docuHeight]);
  // component change events
  useEffect(() => {
    let filteredComponents: Component[];

    const manipulateHiddenChildren = (item: Component) => {
      if (selectedExpandedItems[item.id] !== undefined) {
        const hideChildren: boolean = selectedExpandedItems[item.id];
        return {
          ...item,
          hideChildren,
        };
      }
      return item;
    };

    const updatedPrimeComponents = primeComponents.map(manipulateHiddenChildren);

    if (onExpanderClick) {
      filteredComponents = removeHiddenComponents(updatedPrimeComponents);
    } else {
      filteredComponents = updatedPrimeComponents;
    }
    filteredComponents = filteredComponents.sort(sortComponents);
    const [startDate, endDate] = ganttDateRangeX(events, viewMode, stepCount);
    const newDates = seedDatesX(new Date(startDate), new Date(endDate), viewMode);
    // console.log('seed ue', { newDates, startDate, endDate, });
    setStartDateForScroll(startDate);
    setDateSetup({ dates: newDates, viewMode });
    setBarComponents(convertToBarComponents(filteredComponents, newDates, columnWidth, rowHeight, componentHeight));
  }, [
    primeComponents,
    viewMode,
    events,
    rowHeight,
    columnWidth,
    componentHeight,
    onExpanderClick,
    selectedExpandedItems,
  ]);

  useEffect(() => {
    if (componentListRef.current) {
      setComponentListWidth(componentListRef.current.offsetWidth);
    }
  }, [componentListRef]);

  useEffect(() => {
    const scrollValue = loadScrollToTargetDate(startDateForScroll, viewMode, columnWidth);
    setScrollX(scrollValue);
  }, [startDateForScroll, viewMode]);

  // scroll events
  useEffect(() => {
    const handleWheel = (event: WheelEvent) => {
      if (event.shiftKey || event.deltaX) {
        const scrollMove = event.deltaX ? event.deltaX : event.deltaY;
        let newScrollX = scrollX + scrollMove;
        if (newScrollX < 0) {
          newScrollX = 0;
        } else if (newScrollX > svgWidth) {
          newScrollX = svgWidth;
        }
        setScrollX(newScrollX);
        event.preventDefault();
      } else if (ganttHeight) {
        let newScrollY = scrollY + event.deltaY;
        if (newScrollY < 0) {
          newScrollY = 0;
        } else if (newScrollY > ganttFullHeight - ganttHeight) {
          newScrollY = ganttFullHeight - ganttHeight;
        }
        if (newScrollY !== scrollY) {
          setScrollY(newScrollY);
          event.preventDefault();
        }
      }

      setIgnoreScrollEvent(true);
    };

    const wrapperRefCurrent = wrapperRef.current;

    // subscribe if scroll is necessary
    if (wrapperRefCurrent) {
      wrapperRefCurrent.addEventListener('wheel', handleWheel, {
        passive: false,
      });
    }
    return () => {
      if (wrapperRefCurrent) {
        wrapperRefCurrent.removeEventListener('wheel', handleWheel);
      }
    };
  }, [scrollY, scrollX, ganttHeight, svgWidth, ganttFullHeight]);

  const handleScrollY = (event: SyntheticEvent<HTMLDivElement>) => {
    if (scrollY !== event.currentTarget.scrollTop && !ignoreScrollEvent) {
      setScrollY(event.currentTarget.scrollTop);
    }
    setIgnoreScrollEvent(false);
  };

  /**
   * Handles arrow keys events and transform it to new scroll
   */
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    event.preventDefault();
    let newScrollY = scrollY;
    let newScrollX = scrollX;
    let isX = true;
    switch (event.key) {
      case 'Down': // IE/Edge specific value
      case 'ArrowDown':
        newScrollY += rowHeight;
        isX = false;
        break;
      case 'Up': // IE/Edge specific value
      case 'ArrowUp':
        newScrollY -= rowHeight;
        isX = false;
        break;
      case 'Left':
      case 'ArrowLeft':
        newScrollX -= columnWidth;
        break;
      case 'Right': // IE/Edge specific value
      case 'ArrowRight':
        newScrollX += columnWidth;
        break;
    }
    if (isX) {
      if (newScrollX < 0) {
        newScrollX = 0;
      } else if (newScrollX > svgWidth) {
        newScrollX = svgWidth;
      }
      setScrollX(newScrollX);
    } else {
      if (newScrollY < 0) {
        newScrollY = 0;
      } else if (newScrollY > ganttFullHeight - ganttHeight) {
        newScrollY = ganttFullHeight - ganttHeight;
      }
      setScrollY(newScrollY);
    }
    setIgnoreScrollEvent(true);
  };

  /**
   * Component select event
   */
  const handleSelectedComponent = (componentId: string) => {
    const newSelectedComponent = barComponents.find((t) => t.id === componentId);
    const oldSelectedComponent = barComponents.find((t) => !!selectedComponent && t.id === selectedComponent.id);
    if (onSelect) {
      if (oldSelectedComponent) {
        onSelect(oldSelectedComponent, false);
      }
      if (newSelectedComponent) {
        onSelect(newSelectedComponent, true);
      }
    }
    setSelectedComponent(newSelectedComponent);
  };
  const handleExpanderClick = (component: Component) => {
    if (onExpanderClick && component.hideChildren !== undefined) {
      const hideChildren = !component.hideChildren;
      const _expandedItems = { ...selectedExpandedItems, [component.id]: hideChildren };
      dispatch(setExpandedItems(_expandedItems));
      onExpanderClick({ ...component, hideChildren });
    }
  };
  const gridProps: GridProps = {
    columnWidth,
    svgWidth,
    primeComponents,
    rowHeight,
    dates: dateSetup.dates,
  };
  const calendarProps: CalendarProps = {
    dateSetup,
    locale,
    viewMode,
    headerHeight,
    columnWidth,
    svgWidth,
  };
  const barProps: ComponentGanttContentProps = {
    primeComponents: barComponents,
    dates: dateSetup.dates,
    ganttEvent,
    selectedComponent,
    rowHeight,
    componentHeight,
    columnWidth,
    svgWidth,
    mode,
    setGanttEvent,
    setSelectedComponent: handleSelectedComponent,
    onDateChange,
  };

  const tableProps: ComponentListProps = {
    rowHeight,
    primeComponents: barComponents,
    locale,
    headerHeight,
    scrollY,
    ganttHeight,
    horizontalContainerClass: styles.horizontalContainer,
    selectedComponent,
    componentListRef,
    mode,
    setSelectedComponent: handleSelectedComponent,
    onExpanderClick: handleExpanderClick,
  };
  return (
    <div>
      <StyledComponentWrapper className={styles.wrapper} onKeyDown={handleKeyDown} tabIndex={0} ref={wrapperRef}>
        <ComponentList {...tableProps} />
        <ComponentGantt
          gridProps={gridProps}
          calendarProps={calendarProps}
          barProps={barProps}
          ganttHeight={ganttHeight}
          scrollY={scrollY}
          scrollX={scrollX}
          svgWidth={svgWidth}
          componentListWidth={componentListWidth}
        />
        {/* {ganttEvent.changedComponent && (
          <Tooltip
            rowHeight={rowHeight}
            svgContainerHeight={svgContainerHeight}
            svgContainerWidth={svgContainerWidth}
            scrollX={scrollX}
            scrollY={scrollY}
            component={ganttEvent.changedComponent}
            headerHeight={headerHeight}
            componentListWidth={componentListWidth}
            svgWidth={svgWidth}
          />
        )} */}
        <VerticalScroll
          ganttFullHeight={ganttFullHeight}
          ganttHeight={ganttHeight}
          headerHeight={headerHeight}
          scroll={scrollY}
          onScroll={handleScrollY}
        />
      </StyledComponentWrapper>
    </div>
  );
};
