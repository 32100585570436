import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectTasksOfUser } from '../../../store/modules/Task/selector';
import { getTasksOfUser } from '../../../store/modules/Task/slice';
import { getCustomerUid, getLoggedInUserUid } from '../../../utils/getUid';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import './overview.css';
import { getTaskByTaskUid } from '../../../store/modules/Task/slice';

import UpdateTaskDrawer from '../../TaskModule/TaskView/UpdateTaskDrawer';
import { getAllUsers, getCustomerDetail } from '../../../store/modules/Common/slice';

import { DataGridPremium, useGridApiRef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { format } from 'date-fns';
import { Typography, Stack, Box, Avatar, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { Link } from 'react-router-dom';
import './overview.css';
import { selectAllFilters } from 'store/modules/AllFilters/selector';
import SelectedFilterList from 'components/shared/CognisaasFilters/SelectedFilterList/SelectedFilterList';
import { availableExtendedTimePeriodOptions, FilterableData } from 'utils/filteringFunctions';
import { isNull, isUndefined } from 'utils/dataHelpers';
import { addFilterGroup, updateFilter } from 'store/modules/AllFilters/slice';
import { selectAllGeneralStatus } from 'store/modules/Common/selector';
import {
  label_client,
  label_due_date,
  label_project,
  label_task,
  label_use_case,
} from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { getUserDetails_slice } from 'store/modules/Settings/slice';
import Cookies from 'universal-cookie';
import CognisaasButton from 'components/shared/CognisaasButton/CognisaasButton';
import NewTaskDrawer from 'components/TaskModule/AddTask/NewTaskDrawer';
import CognisaaasTooltip from 'components/shared/CognisaasTooltip/CognisaasTooltip';
import CognisaasTaskStageIndicator from 'components/shared/CognisaasTaskStageIndicator/CognisaasTaskStageIndicator';

function stringToHslColor(str: string, s: number, l: number) {
  let hash = 0;
  // console.log(str);

  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const h = hash % 360;
  return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
}

function stringAvatar(name: string) {
  // console.log(name);

  return {
    sx: {
      bgcolor: stringToHslColor(name, 30, 80),
    },
    children: (
      <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#000000' }}>
        {name.split(' ')[0][0]}
        {name.indexOf(' ') >= 0 ? name.split(' ')[1][0] : null}
      </Typography>
    ),
  };
}

const Overview = () => {
  const dispatch = useAppDispatch();
  const cookies = new Cookies();
  const [updateTaskDrawer, setUpdateTaskDrawer] = useState<boolean>(false);
  const [openTaskWithDetail, setOpenTaskWithDetail] = useState<any>(null);
  const [overDueTask, setOverDueTask] = useState<any[]>([]);
  const [todayTask, setTodayTask] = useState<any[]>([]);
  const [weekTask, setWeekTask] = useState<any[]>([]);
  const [monthTask, setMonthTask] = useState<any[]>([]);
  const [laterThanMonthTask, setLaterThanMonthTask] = useState<any[]>([]);
  const [filteredAllTasks, setFilteredAllTasks] = useState<any>([]);
  const [overdueTasksPageSize, setOverdueTasksPageSize] = useState<number>(5);
  const [todayTasksPageSize, setTodayTasksPageSize] = useState<number>(5);
  const [weekTasksPageSize, setWeekTasksPageSize] = useState<number>(5);
  const [monthTasksPageSize, setMonthTasksPageSize] = useState<number>(5);
  const [laterMonthPageSize, setLaterMonthPageSize] = useState<number>(5);
  const [tablesToShow, setTablesToShow] = useState<any>([]);
  const [addTaskDrawer, setAddTaskDrawer] = useState<boolean>(false);

  const allTasks = useAppSelector(selectTasksOfUser);
  const allFilters = useAppSelector(selectAllFilters);
  const allGeneralStatus = useAppSelector(selectAllGeneralStatus);

  useEffect(() => {
    if (!updateTaskDrawer || !addTaskDrawer) {
      dispatch(getAllUsers());
      dispatch(getCustomerDetail());
      const details = {
        user_uid: getLoggedInUserUid(),
        customer_uid: getCustomerUid(),
      };
      dispatch(getTasksOfUser(details));

      dispatch(getUserDetails_slice((cookies.get('cogni_user_uid') as string) || ''));
    }
  }, [dispatch, updateTaskDrawer, addTaskDrawer]);

  useEffect(() => {
    if (isUndefined(allFilters['landingOverviewPage']) || isNull(allFilters['landingOverviewPage'])) {
      dispatch(
        addFilterGroup({
          idName: 'landingOverviewPage',
          appliedFilters: [
            {
              name: 'Due Date',
              dataKey: 'task_due_date',
              isSelected: false,
              equalToValue: [],
              canBeCleared: true,
              availableOptions: availableExtendedTimePeriodOptions,
            },
            {
              name: 'Status',
              dataKey: 'task_status',
              isSelected: true,
              equalToValue: [],
              canBeCleared: true,
              availableOptions: allGeneralStatus.data,
            },
          ],
        })
      );
    }
  }, [allGeneralStatus.data, dispatch]);

  useEffect(() => {
    if (!isUndefined(allFilters['landingOverviewPage']) || !isNull(allFilters['landingOverviewPage'])) {
      dispatch(
        updateFilter({
          idName: 'landingOverviewPage',
          updatedFilter: {
            name: 'Due Date',
            dataKey: 'task_due_date',
            isSelected: true,
            equalToValue: ['Overdue', 'Today', 'Week', 'This Month', 'Later Than A Month'],
            canBeCleared: false,
            availableOptions: availableExtendedTimePeriodOptions,
          },
        })
      );
      dispatch(
        updateFilter({
          idName: 'landingOverviewPage',
          updatedFilter: {
            name: 'Status',
            dataKey: 'task_status',
            isSelected: true,
            equalToValue: [],
            canBeCleared: false,
            availableOptions: allGeneralStatus.data.slice(0, 3),
          },
        })
      );
    }
  }, [allGeneralStatus.data, dispatch]);

  useEffect(() => {
    // console.log(allFilters['landingOverviewPage']?.appliedFilters[0]);
    if (!isUndefined(allFilters['landingOverviewPage']) && !isNull(allFilters['landingOverviewPage'])) {
      setTablesToShow([...allFilters['landingOverviewPage'].appliedFilters[0].equalToValue]);
    }
  }, [allFilters['landingOverviewPage']]);

  useEffect(() => {
    if (allFilters['landingOverviewPage'] && allTasks) {
      const newFilteredData = new FilterableData([...allTasks]);
      newFilteredData.bulkFilter(allFilters['landingOverviewPage'].appliedFilters);
      setFilteredAllTasks([...newFilteredData.toArray()]);
    }
  }, [allFilters, allTasks]);

  useEffect(() => {
    if (filteredAllTasks) {
      groupTasksForTable(filteredAllTasks);
    }
  }, [filteredAllTasks]);

  const groupTasksForTable = (allTasks: any[]) => {
    const overDueTask: any = [];
    const todayTask: any = [];
    const weekTask: any = [];
    const monthTask: any = [];
    const laterThanMonth: any = [];
    const lessThanToday = new Date();
    lessThanToday.setDate(lessThanToday.getDate() - 1);
    const nextWeek = new Date();
    nextWeek.setDate(nextWeek.getDate() + 7);
    const thismonth = new Date();
    thismonth.setDate(thismonth.getDate() + 37);
    allTasks?.map((task: any) => {
      if (
        format(new Date(task.task_due_date.replace(/-/g, '/')), 'yyyy/MM/dd') <= format(lessThanToday, 'yyyy/MM/dd')
      ) {
        overDueTask.push({ ...task, timeline: 'Overdue tasks' });
      }
    });

    allTasks?.map((task: any) => {
      if (format(new Date(task.task_due_date.replace(/-/g, '/')), 'yyyy/MM/dd') == format(new Date(), 'yyyy/MM/dd')) {
        todayTask.push({ ...task, timeline: 'Tasks for today' });
      }
    });

    // for (let i = 1; i < 7; i++) {
    // console.log(i);
    allTasks?.map((task: any) => {
      if (
        format(new Date(task.task_due_date.replace(/-/g, '/')), 'yyyy/MM/dd') > format(new Date(), 'yyyy/MM/dd') &&
        format(new Date(task.task_due_date.replace(/-/g, '/')), 'yyyy/MM/dd') <= format(nextWeek, 'yyyy/MM/dd')
      ) {
        weekTask.push({ ...task, timeline: 'Upcoming tasks for the week' });
      }
    });
    // }

    // for (let i = 7; i < 30; i++) {
    // console.log(i);
    allTasks?.map((task: any) => {
      if (
        format(new Date(task.task_due_date.replace(/-/g, '/')), 'yyyy/MM/dd') > format(nextWeek, 'yyyy/MM/dd') &&
        format(new Date(task.task_due_date.replace(/-/g, '/')), 'yyyy/MM/dd') <= format(thismonth, 'yyyy/MM/dd')
      ) {
        monthTask.push({ ...task, timeline: 'Upcoming tasks for the month' });
      }
    });
    // }

    // for (let i = 30; i < 60; i++) {
    // console.log(i);
    allTasks?.map((task: any) => {
      if (format(new Date(task.task_due_date.replace(/-/g, '/')), 'yyyy/MM/dd') > format(thismonth, 'yyyy/MM/dd')) {
        laterThanMonth.push({ ...task, timeline: 'Upcoming tasks for later than a month' });
      }
    });
    // }

    // setGroupedTasks([...overDueTask, ...todayTask, ...weekTask, ...monthTask, ...laterThanMonth]);
    setOverDueTask(overDueTask);
    setTodayTask(todayTask);
    setWeekTask(weekTask);
    setMonthTask(monthTask);
    setLaterThanMonthTask(laterThanMonth);
    // console.log('Overdue',overDueTask,'\\/n today',todayTask,'\\/n week',weekTask,'\\/n month',monthTask,'\\/n later month',laterThanMonth);
  };
  // console.log({todayTask, overDueTask, weekTask, monthTask, laterThanMonthTask});

  const getTaskDetail = async (task: any) => {
    const taskResult = await dispatch(getTaskByTaskUid(task.task_uid)).unwrap();
    console.log(taskResult);
    setOpenTaskWithDetail({ ...taskResult, client_uid: task.client_uid });
    setUpdateTaskDrawer(!updateTaskDrawer);
  };

  const columns = [
    {
      field: 'task_uid',
      hide: true,
    },
    {
      field: 'task_implementation_fee_percentage',
      headerName: '',
      width: 5,
      renderCell: (params: GridRenderCellParams) => (
        <Stack direction="row">
          {params.row.task_implementation_fee_percentage &&
          parseInt(params.row.task_implementation_fee_percentage) > 0 ? (
            <CognisaaasTooltip
              title={
                <Box sx={{ padding: '6px' }}>
                  <Typography variant="subtitle1">Implementation fee</Typography>
                </Box>
              }
            >
              <Stack>
                {params.row.task_implementation_fee_percentage &&
                  parseInt(params.row.task_implementation_fee_percentage) > 0 && (
                    <Stack sx={{ marginLeft: '5px' }} ml={1}>
                      <Typography variant="h6" className="label-text">
                        $
                      </Typography>
                    </Stack>
                  )}
              </Stack>
            </CognisaaasTooltip>
          ) : (
            <Stack>
              {params.row.task_implementation_fee_percentage &&
              parseInt(params.row.task_implementation_fee_percentage) > 0 ? (
                <Stack sx={{ marginLeft: '5px' }} ml={1}>
                  <Typography variant="h6">$</Typography>
                </Stack>
              ) : (
                <Stack sx={{ marginLeft: '5px' }} ml={1}>
                  <Typography variant="h6" sx={{ color: 'white' }}>
                    _
                  </Typography>
                </Stack>
              )}
            </Stack>
          )}

          <Stack sx={{ padding: '6px 4px' }}>
            {params.row.milestone_task == '1' && (
              <Stack>
                <CognisaaasTooltip
                  title={
                    <Typography variant="subtitle1" style={{ padding: '10px' }}>
                      Milestone {label_task}: &nbsp;
                      {params.row.task_stage}
                    </Typography>
                  }
                >
                  {/* <Typography variant="subtitle1">ss</Typography> */}
                  <Stack>
                    <CognisaasTaskStageIndicator
                      isMileStoneTask={params.row.milestone_task}
                      stage={params.row.task_stage}
                    />
                  </Stack>
                </CognisaaasTooltip>
              </Stack>
            )}
          </Stack>
        </Stack>
      ),
    },
    {
      field: 'task_name',
      headerName: `${label_task} Name`,
      width: 200,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Grid>
            <Stack direction="row">
              <Link
                onClick={() => {
                  getTaskDetail(params.row);
                  // setOpenTaskWithDetail(params.row);
                }}
                to="/"
              >
                <Typography variant="h3" sx={{ padding: '5px 0' }}>
                  {params.row.task_name}
                </Typography>
              </Link>
            </Stack>
          </Grid>
        );
      },
    },
    {
      field: 'project/usecase',
      headerName: `${label_project}/${label_use_case}`,
      width: 300,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <Typography variant="subtitle1">
          {params.row.project_name ? params.row.project_name : params.row.case_name}
        </Typography>
      ),
    },
    {
      field: 'client_name',
      headerName: label_client,
      width: 200,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Stack direction="row">
            {params?.row.client_name ? (
              <Avatar
                style={{ height: '34px', width: '34px', marginTop: '3px' }}
                {...stringAvatar(params.row.client_name)}
              />
            ) : null}
            <Typography style={{ margin: '6px 0  0 6px' }} variant="subtitle1">
              {params.row.client_name}
            </Typography>
          </Stack>
        );
      },
    },

    {
      headerName: 'Status',
      field: 'task_status',
      width: 185,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <Stack>
          <Stack spacing={1} direction="row">
            <span
              style={{ marginRight: '4px' }}
              className={
                params.value == 'red'
                  ? 'red_status_box_task'
                  : params.value == 'amber'
                  ? 'amber_status_box_task'
                  : params.value == 'green'
                  ? 'green_status_box_task'
                  : params.value == 'blue'
                  ? 'blue_status_box_task'
                  : params.value == 'grey'
                  ? 'grey_status_box_task'
                  : ''
              }
            ></span>
            {params.value == 'red'
              ? 'Overdue'
              : params.value == 'amber'
              ? 'At Risk'
              : params.value == 'green'
              ? 'On Track'
              : params.value == 'blue'
              ? 'Completed'
              : params.value == 'grey'
              ? 'Deferred'
              : ''}
          </Stack>
        </Stack>
      ),
    },
    {
      field: 'task_due_date',
      headerName: label_due_date,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        // console.log(params.value.slice(0, 10)),
        // <Typography variant='subtitle1'> {params.value} </Typography>
        <Typography variant="subtitle1">
          {' '}
          {params.value ? format(new Date(params?.value.slice(0, 10).replace(/-/g, '/')), 'dd MMM yyyy') : null}{' '}
        </Typography>
      ),
    },

    {
      field: 'timeline',
      hide: true,
      headerName: 'Timeline',
      width: 350,
      flex: 1,
    },
  ];

  const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .ant-empty-img-1': {
      fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
      fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
      fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
      fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
      fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
      fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <svg width="120" height="100" viewBox="0 0 184 152" aria-hidden focusable="false">
          <g fill="none" fillRule="evenodd">
            <g transform="translate(24 31.67)">
              <ellipse className="ant-empty-img-5" cx="67.797" cy="106.89" rx="67.797" ry="12.668" />
              <path
                className="ant-empty-img-1"
                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
              />
              <path
                className="ant-empty-img-2"
                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
              />
              <path
                className="ant-empty-img-3"
                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
              />
            </g>
            <path
              className="ant-empty-img-3"
              d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
            />
            <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
              <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
              <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
            </g>
          </g>
        </svg>
        <Box sx={{ mt: 1 }}>No Rows</Box>
      </StyledGridOverlay>
    );
  }

  // const dueDateAllNames = ['Overdue', 'Today', 'This Week', 'This Month', 'After This Month'];
  // const statusAllName = ['Red', 'Amber', 'Green'];

  // useEffect(() => {
  //   const final: any[] = [];
  //   if (dueDateName.includes('Today')) {
  //     todayTask.map((obj: any) => {
  //       final.push(obj);
  //     });
  //   }
  //   if (dueDateName.includes('Week')) {
  //     weekTask.map((obj: any) => {
  //       final.push(obj);
  //     });
  //   }
  //   if (dueDateName.includes('Month')) {
  //     monthTask.map((obj: any) => {
  //       final.push(obj);
  //     });
  //   }
  //   if (dueDateName.includes('Later than a month')) {
  //     laterThanMonthTask.map((obj: any) => {
  //       final.push(obj);
  //     });
  //   }
  //   if (dueDateName.includes('Over due')) {
  //     overDueTask.map((obj: any) => {
  //       final.push(obj);
  //     });
  //   }
  //   if (final.length == 0) {
  //     // groupTasksForTable(allTasks);
  //     if (dueDateName.length > 1) {
  //       groupTasksForTable([]);
  //     } else {
  //       groupTasksForTable(allTasks);
  //     }
  //   } else {
  //     // console.log('came here');
  //     const uniqueTempArr: any = final.filter((x: any, i: any, a: any) => a.indexOf(x) === i);
  //     groupTasksForTable(uniqueTempArr);
  //   }
  // }, [dueDateName]);

  // useEffect(() => {
  //   const final: any[] = [];
  //   if (statusName.includes('Red') || statusName.includes('red')) {
  //     allTasks.map((obj: any) => {
  //       obj.task_status.toLowerCase() == 'red' ? final.push(obj) : null;
  //     });
  //   }
  //   if (statusName.includes('Green') || statusName.includes('green')) {
  //     allTasks.map((obj: any) => {
  //       obj.task_status.toLowerCase() == 'green' ? final.push(obj) : null;
  //     });
  //   }
  //   if (statusName.includes('Amber') || statusName.includes('amber')) {
  //     allTasks.map((obj: any) => {
  //       obj.task_status.toLowerCase() == 'amber' ? final.push(obj) : null;
  //     });
  //   }
  //   // console.log(final);
  //   if (final.length > 0) {
  //     groupTasksForTable(final);
  //   } else {
  //     // console.log(statusName);
  //     if (statusName.length > 1) {
  //       // console.log('herere');
  //       groupTasksForTable([]);
  //     } else {
  //       groupTasksForTable(allTasks);
  //     }
  //   }
  // }, [statusName]);

  // const handleChangeDueDate = (event: SelectChangeEvent<typeof dueDateName>) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setDueDateName(typeof value === 'string' ? value.split(',') : value);
  // };

  // const handleChangeStatus = (event: SelectChangeEvent<typeof dueDateName>) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setStatusName(typeof value === 'string' ? value.split(',') : value);
  // };

  // const SelectAllFuncDueDate = () => {
  //   if (selectAllDueDate) {
  //     setSelectAllDueDate(false);
  //     setDueDateName(['Select Due Date']);
  //   } else {
  //     setSelectAllDueDate(true);
  //     setDueDateName(dueDateAllNames);
  //   }
  // };

  // const SelectAllFuncStatus = () => {
  //   if (selectAllStatus) {
  //     setSelectAllStatus(false);
  //     setStatusName(['Select Status']);
  //   } else {
  //     setSelectAllStatus(true);
  //     setStatusName(statusAllName);
  //   }
  // };

  const apiRef = useGridApiRef();

  return (
    <Box sx={{ display: 'grid', alignItems: 'center', justifyContent: 'stretch', mt: '20px', gridRowGap: '20px' }}>
      {/* {tasksOfUser.length > 0 && ( */}
      {/* <Grid sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
        <Typography id="demo-multiple-checkbox-label">Filter</Typography>
        <Stack
          direction="row"
          style={{
            backgroundColor: 'rgba(45, 96, 246,0.1)',
            marginLeft: '12px',
            borderRadius: '12px',
            marginTop: '0',
          }}
        >
          <FormControl variant="standard">
            <Select
              id="demo-simple-select"
              disableUnderline
              multiple
              value={dueDateName}
              onChange={handleChangeDueDate}
              IconComponent={() => <KeyboardArrowDownIcon fontSize="small" sx={{ ml: '-20px' }} />}
              // input={<OutlinedInput  />}
              renderValue={(selected) => selected.join(', ')}
              // MenuProps={MenuProps}
              // autoWidth
              size="small"
              sx={{ ml: '10px', maxWidth: '250px', color: '#2D60F6' }}
            >
              <MenuItem key={'all'} onClick={SelectAllFuncDueDate}>
                <Checkbox checked={selectAllDueDate} />
                <ListItemText primary={'Select All'} />
              </MenuItem>
              {dueDateAllNames.map((name: any) => (
                <MenuItem key={name} value={name}>
                  <Checkbox checked={dueDateName.indexOf(name) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
        <Stack
          direction="row"
          style={{ backgroundColor: 'rgba(45, 96, 246,0.1)', marginLeft: '7px', borderRadius: '12px', marginTop: '0' }}
        >
          <FormControl variant="standard">
            <Select
              id="demo-simple-select"
              disableUnderline
              multiple
              value={statusName}
              onChange={handleChangeStatus}
              IconComponent={() => <KeyboardArrowDownIcon fontSize="small" sx={{ ml: '-20px' }} />}
              // input={<OutlinedInput  />}
              renderValue={(selected) => selected.join(', ')}
              // MenuProps={MenuProps}
              // autoWidth
              size="small"
              sx={{ ml: '10px', maxWidth: '250px', color: '#2D60F6' }}
            >
              <MenuItem key={'all'} onClick={SelectAllFuncStatus}>
                <Checkbox checked={selectAllStatus} />
                <ListItemText primary={'Select All'} />
              </MenuItem>
              {statusAllName.map((name: any) => (
                <MenuItem key={name} value={name}>
                  <Checkbox checked={statusName.indexOf(name) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </Grid> */}
      {/* {allFilters['landingOverviewPage']?.appliedFilters.filter((item: any) => item.isSelected).length > 0 && ( */}
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <SelectedFilterList idName="landingOverviewPage" isResetEnabled={false} />
        <CognisaasButton isOutlined={false} clickHandler={() => setAddTaskDrawer(true)} label="Add New Task" />
      </Box>
      {/* )} */}
      <Box
        sx={{ padding: '10px', marginBottom: '15px' }}
        style={{
          background: '#fff',
          boxShadow: '0px 4px 26px rgba(0, 0, 0, 0.05)',
          borderRadius: '4px',
          minHeight: '75vh',
        }}
      >
        {tablesToShow.includes('Overdue') && (
          <Accordion
            style={{ marginTop: '5px', boxShadow: 'none', border: '1px solid #E5E5E5' }}
            defaultExpanded={true}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography variant={'h2'}>Overdue {label_task.toLowerCase()}s</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ padding: '0' }}>
              {overDueTask.length === 0 ? (
                <div style={{ padding: '5px 16px' }}>
                  <Typography variant={'subtitle1'} className="label-text">
                    No {label_task.toLowerCase()} due for today
                  </Typography>
                </div>
              ) : (
                <DataGridPremium
                  sx={{ borderRadius: 0 }}
                  components={{
                    NoRowsOverlay: CustomNoRowsOverlay,
                  }}
                  autoHeight
                  columns={columns}
                  rows={overDueTask}
                  getRowId={(row) => row.task_uid}
                  apiRef={apiRef}
                  disableSelectionOnClick
                  pageSize={overdueTasksPageSize}
                  onPageSizeChange={(newPageSize) => setOverdueTasksPageSize(newPageSize)}
                  rowsPerPageOptions={[5, 10, 20, 50, 100]}
                  pagination
                  // hideFooter
                />
              )}
            </AccordionDetails>
          </Accordion>
        )}

        {tablesToShow.includes('Today') && (
          <Accordion
            style={{ marginTop: '5px', boxShadow: 'none', border: '1px solid #E5E5E5' }}
            defaultExpanded={true}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography variant={'h2'}>{label_task}s for today</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ padding: '0' }}>
              {todayTask.length === 0 ? (
                <div style={{ padding: '5px 16px' }}>
                  <Typography variant={'subtitle1'} className="label-text">
                    No {label_task} due for today
                  </Typography>
                </div>
              ) : (
                <DataGridPremium
                  sx={{ borderRadius: 0 }}
                  components={{
                    NoRowsOverlay: CustomNoRowsOverlay,
                  }}
                  columns={columns}
                  rows={todayTask}
                  getRowId={(row) => row.task_uid}
                  apiRef={apiRef}
                  disableSelectionOnClick
                  autoHeight
                  pageSize={todayTasksPageSize}
                  onPageSizeChange={(newPageSize) => setTodayTasksPageSize(newPageSize)}
                  rowsPerPageOptions={[5, 10, 20, 50, 100]}
                  pagination
                />
              )}
            </AccordionDetails>
          </Accordion>
        )}
        {tablesToShow.includes('Week') && (
          <Accordion
            style={{ marginTop: '5px', boxShadow: 'none', border: '1px solid #E5E5E5' }}
            defaultExpanded={true}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography variant={'h2'}>Upcoming {label_task.toLowerCase()}s for the week</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ padding: '0' }}>
              {weekTask.length === 0 ? (
                <div style={{ padding: '5px 16px' }}>
                  <Typography variant={'subtitle1'} className="label-text">
                    No {label_task} due for this week
                  </Typography>
                </div>
              ) : (
                <DataGridPremium
                  sx={{ borderRadius: 0 }}
                  components={{
                    NoRowsOverlay: CustomNoRowsOverlay,
                  }}
                  columns={columns}
                  rows={weekTask}
                  getRowId={(row) => row.task_uid}
                  apiRef={apiRef}
                  disableSelectionOnClick
                  autoHeight
                  pageSize={weekTasksPageSize}
                  onPageSizeChange={(newPageSize) => setWeekTasksPageSize(newPageSize)}
                  rowsPerPageOptions={[5, 10, 20, 50, 100]}
                  pagination
                />
              )}
            </AccordionDetails>
          </Accordion>
        )}

        {tablesToShow.includes('This Month') && (
          <Accordion
            style={{ marginTop: '5px', boxShadow: 'none', border: '1px solid #E5E5E5' }}
            defaultExpanded={true}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography variant={'h2'}>Upcoming {label_task.toLowerCase()}s for the month</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ padding: '0' }}>
              {monthTask.length === 0 ? (
                <div style={{ padding: '5px 16px' }}>
                  <Typography variant={'subtitle1'} className="label-text">
                    No {label_task} due for this month
                  </Typography>
                </div>
              ) : (
                <DataGridPremium
                  sx={{ borderRadius: 0 }}
                  components={{
                    NoRowsOverlay: CustomNoRowsOverlay,
                  }}
                  columns={columns}
                  rows={monthTask}
                  getRowId={(row) => row.task_uid}
                  apiRef={apiRef}
                  disableSelectionOnClick
                  autoHeight
                  pageSize={monthTasksPageSize}
                  onPageSizeChange={(newPageSize) => setMonthTasksPageSize(newPageSize)}
                  rowsPerPageOptions={[5, 10, 20, 50, 100]}
                  pagination
                />
              )}
            </AccordionDetails>
          </Accordion>
        )}
        {tablesToShow.includes('Later Than A Month') && (
          <Accordion
            style={{ marginTop: '5px', boxShadow: 'none', border: '1px solid #E5E5E5' }}
            defaultExpanded={true}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography variant={'h2'}>Upcoming {label_task.toLowerCase()}s for later than a month</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ padding: '0' }}>
              {laterThanMonthTask.length === 0 ? (
                <div style={{ padding: '5px 16px' }}>
                  <Typography variant={'subtitle1'} className="label-text">
                    No {label_task.toLowerCase()} due for later than month
                  </Typography>
                </div>
              ) : (
                <DataGridPremium
                  sx={{ borderRadius: 0 }}
                  components={{
                    NoRowsOverlay: CustomNoRowsOverlay,
                  }}
                  columns={columns}
                  autoHeight
                  rows={laterThanMonthTask}
                  getRowId={(row) => row.task_uid}
                  apiRef={apiRef}
                  disableSelectionOnClick
                  pageSize={laterMonthPageSize}
                  onPageSizeChange={(newPageSize) => setLaterMonthPageSize(newPageSize)}
                  rowsPerPageOptions={[5, 10, 20, 50, 100]}
                  pagination
                />
              )}
            </AccordionDetails>
          </Accordion>
        )}
      </Box>
      {updateTaskDrawer && (
        <UpdateTaskDrawer
          client={{ client_uid: openTaskWithDetail.client_uid }}
          taskDetail={openTaskWithDetail}
          drawerState={updateTaskDrawer}
          onClose={() => setUpdateTaskDrawer(!updateTaskDrawer)}
        />
      )}

      {/* new task drawer */}
      <NewTaskDrawer
        type="standalone"
        isProjectTask={true}
        drawerState={addTaskDrawer}
        onClose={() => setAddTaskDrawer(!addTaskDrawer)}
      />
    </Box>
  );
};

export default Overview;
