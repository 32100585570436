const CLIENT_ROOT_URL = 'clients';
const CUSTOM_FIELD_ROOT_URL = 'fields';
const GET_USERS = 'users';
const GET_SEGMENTS = '/clients/segments/';
const GET_INDUSTRIES = '/clients/industries/';
const GET_STAGES = '/clients/stages/';
const GET_CONTACTS = '/contacts/';

export const clientRoutes2 = {
  clients: (page: number, pageSize: number) => {
    page = page * pageSize;
    return `${CLIENT_ROOT_URL}/?limit=${pageSize}&?offset=${page}`;
  },
  clientDetail: (client_id: string) => `${CLIENT_ROOT_URL}/${client_id}/`,
  clientCustomFields: (client_id: string) => `${CLIENT_ROOT_URL}/${client_id}/${CUSTOM_FIELD_ROOT_URL}/`,
  updateClientCustomfields: (clientId: string, fieldId: string) => `${CLIENT_ROOT_URL}/${clientId}/${fieldId}/`,
  industries: () => `${CLIENT_ROOT_URL}/industries/`,
  segments: () => `${CLIENT_ROOT_URL}/segments/`,
  stages: () => `${CLIENT_ROOT_URL}/stages/`,
  getRenewals: (client_id: string) => `${CLIENT_ROOT_URL}/${client_id}/renewals/`,
  updateRenewals: (client_id: string) => `${CLIENT_ROOT_URL}/${client_id}/renewals/`,
  getClientContacts: (client_id: string) => `${CLIENT_ROOT_URL}/${client_id}${GET_CONTACTS}`,
  addClientContacts: (client_id: string) => `${CLIENT_ROOT_URL}/${client_id}${GET_CONTACTS}`,
  deleteClientContact: (clientId: string, contactId: string) => `${CLIENT_ROOT_URL}/${clientId}/contacts/${contactId}/`,
};

export const commonRoutes = {
  companyUsers: () => `${GET_USERS}/`,
  getSegments: () => GET_SEGMENTS,
  getIndustries: () => GET_INDUSTRIES,
  getStages: () => GET_STAGES,
};
