import { Box, Grid, Typography } from '@mui/material';
import { Chart, registerables } from 'chart.js';
import { Bar } from 'react-chartjs-2';
Chart.register(...registerables);

type CognisaasMultiBarGraphProps = {
  title: string;
  labels: string[];
  data: any[];
  bars: Bar[];
  options?: Options;
  legend?: Legend[];
};

type Legend = {
  name: string;
  calculateValue?: (data: any[]) => number;
  color?: string;
};

type Options = {
  height?: number;
  width?: number;
};

type Bar = {
  label: string;
  color?: string;
  colors?: string[];
  filter: (data: any[]) => any[];
  barThickness?: number;
};

type StructuredBarGraphData = {
  labels: string[];
  datasets: any[];
};

const CognisaasMultiBarGraph = (props: CognisaasMultiBarGraphProps) => {
  let maxValue = 0;
  function generateDataset(bar: Bar) {
    const dataset = bar.filter(props.data);
    return dataset;
  }

  function prepareData() {
    const internalPreparedData: StructuredBarGraphData = {
      labels: [],
      datasets: [],
    };
    internalPreparedData.labels = props.labels;
    props.bars.map((bar) => {
      const data = generateDataset(bar);
      data.map((value) => {
        if (value > maxValue) {
          maxValue = value;
        }
      });
      internalPreparedData.datasets.push({
        label: bar.label,
        backgroundColor: bar.colors ? bar.colors : bar.color ? bar.color : 'black',
        data: data,
        barThickness: bar.barThickness ? bar.barThickness : 12,
        borderRadius: 4,
      });
    });
    return internalPreparedData;
  }

  const plugin = {
    id: 'custom_canvas_background_color',
    beforeDraw: (chart: { canvas: { getContext: (arg0: string) => any }; width: any; height: any }) => {
      const ctx = chart.canvas.getContext('2d');
      ctx.save();
      const gradient = ctx.createLinearGradient(0, 0, 0, 800);
      gradient.addColorStop(0, 'white');
      gradient.addColorStop(1, '#E5E5E5');
      ctx.globalCompositeOperation = 'destination-over';
      ctx.fillStyle = gradient;
      ctx.fillRect(0, 0, chart.width, chart.height);
      ctx.restore();
    },
  };

  return props.options ? (
    <>
      <Box sx={{ padding: '20px', background: 'white', borderRadius: '4px', marginTop: '20px' }}>
        <Typography sx={{}}>{props.title}</Typography>
        <Grid container spacing={2} padding="20px">
          <Grid item xs={8}>
            <Bar
              height={props.options.height}
              width={props.options.width}
              data={prepareData()}
              plugins={[plugin]}
              options={{
                responsive: true,
                plugins: {
                  title: {
                    display: true,
                    text: `#${props.title}`,
                    position: 'left',
                  },
                  legend: {
                    display: false,
                  },
                },
                scales: {
                  y: {
                    min: 0,
                    max: Math.ceil(maxValue * 1.2),
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={4}>
            {props.legend ? (
              <Grid container spacing={2} sx={{ margin: 'auto' }}>
                {props.legend.map((legend, index) => {
                  return (
                    <Grid key={index} item xs={6}>
                      <Box
                        border={1}
                        padding="10%"
                        borderRadius="4px"
                        borderColor="#E5E5E5"
                        display="flex"
                        flexDirection="row"
                        alignItems="start"
                      >
                        <Box
                          sx={{
                            borderLeft: `4px solid ${legend.color} `,
                          }}
                        >
                          <Typography marginLeft="5px" fontFamily="Noto Sans" fontWeight="400" fontSize="14px">
                            {`${legend.calculateValue ? legend.calculateValue(props.data) : ''} ${legend.name}`}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Box>
    </>
  ) : (
    <Bar
      data={prepareData()}
      options={{
        plugins: {
          legend: {
            display: false,
          },
        },
      }}
    />
  );
};
export default CognisaasMultiBarGraph;
