import { Card, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface IntegrationBoxPayload {
  image: React.ReactNode;
  primaryText: string;
  secondaryText: string;
  navigateTo: string;
}

const IntegrationBox = (props: IntegrationBoxPayload) => {
  const navigate = useNavigate();
  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'row',
        width: '300px',
        background: 'white',
        marginTop: '15px',
        cursor: 'pointer',
        boxShadow: '0px 4px 26px rgba(0, 0, 0, 0.05)',
      }}
      onClick={() => {
        navigate(props.navigateTo);
      }}
    >
      <Grid sx={{ padding: '8px 12px 0 12px', background: '#F3F3F3' }}>{props.image}</Grid>
      <Grid>
        <Grid>
          <Typography sx={{ paddingTop: '16px', paddingLeft: '20px' }} variant={'h3'}>
            {props.primaryText}
          </Typography>
        </Grid>
        <Grid>
          <Typography sx={{ paddingTop: '4px', paddingLeft: '20px', color: '#7A7A7A' }} variant={'subtitle1'}>
            {props.secondaryText}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};

export default IntegrationBox;
