import { useEffect, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import CognisaasMultiSelectWithSearchUid from 'components/shared/CognisaasMultiSelectWithSearch/CognisaasMultiSelectWithSearchUid';
import CognisaasCalenderNew from 'components/shared/CognisaasCalenderNew/CognisaasCalenderNew';
import { subDays, addDays } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { label_project } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { useAppSelector } from 'store/hooks';
import { selectCustomerDetails } from 'store/modules/Settings/selector';
import CognisaasMultiSelectWithSearch from 'components/shared/CognisaasMultiSelectWithSearch/CognisaasMultiSelectWithSearch';
import CognisaasButton from 'components/shared/CognisaasButton/CognisaasButton';

interface Props {
  getprojectsByclientuid: any;
  clientUid: string;
  type: 'project-report' | 'client-report';
  selectedProjectUid?: string;
}

const GenerateStatusReportFiltering = (props: Props) => {
  const navigate = useNavigate();
  const today = new Date();
  const completed = subDays(today, 7);
  const ended = addDays(today, 7);
  const customerDetails = useAppSelector(selectCustomerDetails);
  const [startDate, setStartDate] = useState<any>(completed);
  const [endDate, setEndDate] = useState<any>(ended);
  const [selectedProjectOption, setSelectedProjectOption] = useState<any>([]);
  const [selectedStatusOption, setselectedStatusOption] = useState<any[]>(['Red', 'Green', 'Amber', 'Blue']);
  const [submitButton, setSubmitButton] = useState<boolean>(false);
  const [projectArray, setprojectArray] = useState<any>([]);
  const status: any = ['Red', 'Green', 'Amber', 'Blue'];
  const [filterInformations, setFilterInformation] = useState({
    report_selected_projects: [...selectedProjectOption],
    report_task_status: ['Red', 'Amber', 'Green', 'Blue'],
    report_mindate_projects: startDate,
    report_maxdate_projects: endDate,
  });
  const [filterStatus, setFilterStatus] = useState({
    report_task_status: ['Red', 'Amber', 'Green', 'Blue'],
    report_selected_projects: [...selectedProjectOption],
  });
  const clientUid = props.clientUid;

  useEffect(() => {
    const project_array: any = [];
    props.getprojectsByclientuid.forEach((element: any) => {
      const obj: any = {};
      obj.name = element.project_name;
      obj.id = element.project_uid;
      project_array.push(obj);
    });
    setprojectArray([...project_array]);

    // if project-report
    if (props.type === 'project-report' && props.selectedProjectUid) {
      setSelectedProjectOption([props.selectedProjectUid]);
    }
  }, []);
  useEffect(() => {
    setFilterInformation({
      report_selected_projects: selectedProjectOption,
      report_task_status: selectedStatusOption,
      report_mindate_projects: startDate,
      report_maxdate_projects: endDate,
    });
  }, [selectedProjectOption]);

  useEffect(() => {
    if (
      (customerDetails?.data?.status_report_client === 1 || customerDetails?.data?.status_report_client === '1') &&
      startDate != null &&
      endDate != null &&
      selectedStatusOption.length != 0 &&
      selectedProjectOption.length != 0
    ) {
      setSubmitButton(true);
      setFilterInformation({
        report_selected_projects: [...selectedProjectOption],
        report_task_status: [...selectedStatusOption],
        report_mindate_projects: startDate,
        report_maxdate_projects: endDate,
      });
    } else if (
      (customerDetails?.data?.status_report_client === 0 || customerDetails?.data?.status_report_client === '0') &&
      selectedStatusOption.length != 0
    ) {
      setSubmitButton(true);
      setFilterStatus({
        report_task_status: [...selectedStatusOption],
        report_selected_projects: [...selectedProjectOption],
      });
    } else {
      setSubmitButton(false);
    }
  }, [startDate, endDate, selectedProjectOption, selectedStatusOption]);

  return (
    <>
      <Stack direction="column" justifyContent="space-evenly">
        {customerDetails?.data?.status_report_client === 1 ||
          ((customerDetails?.data?.status_report_client === '1' || props.type === 'project-report') && (
            <>
              <Stack direction="column">
                <Typography variant="h4" sx={{ fontSize: '1rem' }}>
                  {label_project}s
                </Typography>
                <CognisaasMultiSelectWithSearchUid
                  allOptions={projectArray}
                  selectedOption={selectedProjectOption}
                  setSelectedOptions={setSelectedProjectOption}
                  disabled={props.type === 'project-report'}
                />
              </Stack>
              <hr style={{ border: 'none' }} />
            </>
          ))}
        <Stack direction="column">
          <Typography variant="h4" sx={{ fontSize: '1rem' }}>
            Status
          </Typography>
          <CognisaasMultiSelectWithSearch
            allOptions={status}
            selectedOption={selectedStatusOption}
            setSelectedOptions={setselectedStatusOption}
          />
        </Stack>
        {customerDetails?.data?.status_report_client === 1 ||
          (customerDetails?.data?.status_report_client === '1' && (
            <>
              <hr style={{ border: 'none' }} />
              <Stack>
                <Typography variant="h4" sx={{ fontSize: '1rem' }}>
                  Start Date
                </Typography>
                <Stack direction="row">
                  <CognisaasCalenderNew
                    value={startDate}
                    handleChange={(date: Date) => setStartDate(date)}
                    name={'startDate'}
                  />
                </Stack>
              </Stack>
              <hr style={{ border: 'none' }} />
              <Stack>
                <Typography variant="h4" sx={{ fontSize: '1rem' }}>
                  End Date
                </Typography>
                <Stack direction="row">
                  <CognisaasCalenderNew
                    value={endDate}
                    handleChange={(date: Date) => setEndDate(date)}
                    // value={props.values.renewalDate}
                    name={'endDate'}
                  />
                </Stack>
              </Stack>
            </>
          ))}
      </Stack>
      <hr style={{ border: 'none' }} />
      {customerDetails?.data?.status_report_client === 1 ||
        (customerDetails?.data?.status_report_client === '1' && (
          <CognisaasButton
            disabled={submitButton == true ? false : true}
            isOutlined={false}
            label={'Generate Status Report'}
            clickHandler={() =>
              navigate(`/client-status-report/${clientUid}`, {
                state: { filterInformations: filterInformations, reportType: props.type },
              })
            }
          />
        ))}
      {customerDetails?.data?.status_report_client === 0 ||
        (customerDetails?.data?.status_report_client === '0' && (
          <CognisaasButton
            disabled={submitButton == true ? false : true}
            isOutlined={false}
            label={'Generate Status Report'}
            clickHandler={() =>
              navigate(`/client-status-report/${clientUid}`, {
                state: { filterStatus: filterStatus, reportType: props.type },
              })
            }
          />
        ))}
    </>
  );
};

export default GenerateStatusReportFiltering;
