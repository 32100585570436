import React, { useEffect, useState } from 'react';
import { Grid, Container, Box, CircularProgress, Typography, Stack } from '@mui/material';
import CognisaasTabs from 'components/shared/CognisaasTabs';
import { ReactComponent as RevenueImage } from '../../assets/svgs/reports-revenue.svg';
import { ReactComponent as ResourcesImage } from '../../assets/svgs/reports-resources.svg';
import { ReactComponent as ProjectsImage } from '../../assets/svgs/reports-projects.svg';
import { ReactComponent as UsecasesImage } from '../../assets/svgs/reports-usecases.svg';
import { ReactComponent as TasksImage } from '../../assets/svgs/reports-tasks.svg';
import { useAppSelector } from '../../store/hooks';
import { fetchClosedProjects } from '../../store/modules/Reports/ClosedProjects/slice';
import { useDispatch } from 'react-redux';
import { selectClosedProjects, selectClosedProjectsStatus } from '../../store/modules/Reports/ClosedProjects/selector';
import { selectOpenProjects, selectOpenProjectsStatus } from '../../store/modules/Reports/OpenProjects/selector';
import { selectClosedUsecases, selectClosedUsecaseStatus } from '../../store/modules/Reports/ClosedUsecases/selector';
import { selectOpenUsecases, selectOpenUsecasesStatus } from '../../store/modules/Reports/OpenUsecases/selector';
import { selectUsers, selectUsersStatus } from '../../store/modules/Reports/Users/selector';
import { selectRenewalTasks, selectRenewalTasksStatus } from '../../store/modules/Reports/RenewalTasks/selector';
import { selectRevenueTasks, selectRevenueTasksStatus } from '../../store/modules/Reports/RevenueProjectTasks/selector';
import { selectOpenTasks, selectOpenTasksStatus } from 'store/modules/Reports/OpenTasks/selector';
import { selectClosedTasks, selectClosedTasksStatus } from 'store/modules/Reports/ClosedTasks/selector';
import { fetchOpenProjects } from '../../store/modules/Reports/OpenProjects/slice';
import { fetchClosedUsecases } from '../../store/modules/Reports/ClosedUsecases/slice';
import { fetchAllUsers } from '../../store/modules/Reports/Users/slice';
import { fetchOpenUsecases } from '../../store/modules/Reports/OpenUsecases/slice';
import { fetchRenewalTasks } from '../../store/modules/Reports/RenewalTasks/slice';
import { fetchRevenueTasks } from '../../store/modules/Reports/RevenueProjectTasks/slice';
import { getOpenTasks } from 'store/modules/Reports/OpenTasks/slice';
import { getClosedTasks } from 'store/modules/Reports/ClosedTasks/slice';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  fetchAllIndustries,
  fetchAllJourneyStages,
  getAllClientContacts,
  getAllClientsByCustomerUidForClientContactReport,
  getAllSegmentValues,
  getAllUsers,
} from 'store/modules/Common/slice';
import { getCustomerDetails } from 'store/modules/Settings/slice';
import { selectCustomerDetails } from 'store/modules/Settings/selector';
import {
  label_arr,
  label_client,
  label_project,
  label_task,
  label_use_case,
} from 'components/Settings/Preferences/CustomLabelCookieHelper';
import Cookies from 'universal-cookie';
import { JWTToken } from 'store/types';
import jwtDecode from 'jwt-decode';
import {
  selectAllClientsByCustomerUid,
  selectClientContactsOfCompany,
  selectClientContactsOfCompanyStatus,
} from 'store/modules/Common/selector';
import { formatCurrency } from 'utils/dataHelpers';
import CustomReports from './CustomReports/CustomReports';
import CognisaasButton from 'components/shared/CognisaasButton/CognisaasButton';
// import AggregatedCustomReports from './AggregatedCustomReports/View/AggregatedCustomReports';

const cookies = new Cookies();
const enableCustomReports = 'true'; //this is temporary
// const enableAggregratedReports = cookies.get('enable_custom_aggregations');

// export const reportTabs =
//   enableAggregratedReports == 'true'
//     ? ['Standard Reports', 'Custom Reports', 'Aggregated Custom Reports']
//     : ['Standard Reports', 'Custom Reports'];

export const reportTabs = ['Standard Reports', 'Custom Reports'];

const reportsSkeleton: any = [
  {
    id: 1,
    reportName: 'Revenue',
    slug: 'Revenue',
    img: <RevenueImage />,
    reportFields: [
      { id: 1, fieldName: 'Accounts for renewal', fieldValue: null },
      { id: 2, fieldName: 'Implementation Fee', fieldValue: null },
    ],
  },
  {
    id: 2,
    reportName: 'Resources',
    slug: 'Resources',
    img: <ResourcesImage />,
    reportFields: [
      { id: 1, fieldName: 'Number of Resources', fieldValue: null },
      { id: 2, fieldName: `Total ${label_project}s`, fieldValue: null },
    ],
  },
  {
    id: 3,
    reportName: `${label_project}s`,
    slug: 'Projects',
    img: <ProjectsImage />,
    reportFields: [
      { id: 1, fieldName: `Open ${label_project}s`, fieldValue: null },
      { id: 2, fieldName: `Closed ${label_project}s`, fieldValue: null },
    ],
  },
  {
    id: 4,
    reportName: `${label_use_case}s`,
    slug: 'Usecases',
    img: <UsecasesImage />,
    reportFields: [
      { id: 1, fieldName: `Open ${label_use_case}s`, fieldValue: null },
      { id: 2, fieldName: `Closed ${label_use_case}s`, fieldValue: null },
    ],
  },
  {
    id: 5,
    reportName: `${label_task}s`,
    slug: 'Tasks',
    img: <TasksImage />,
    reportFields: [
      { id: 1, fieldName: `Internal ${label_task}s`, fieldValue: null },
      {
        id: 2,
        fieldName: `External ${label_task}s`,
        fieldValue: null,
      },
    ],
  },
  {
    id: 6,
    reportName: 'Performance',
    slug: 'Performance',
    img: <TasksImage />,
    reportFields: [
      { id: 1, fieldName: 'CSAT Score', fieldValue: null },
      { id: 2, fieldName: `${label_project} Performance`, fieldValue: null },
      { id: 3, fieldName: 'Team Performance', fieldValue: null },
    ],
  },
  {
    id: 7,
    reportName: 'Phases',
    slug: 'Phases',
    img: <TasksImage />,
    reportFields: [
      { id: 1, fieldName: `${label_project} Phases`, fieldValue: null },
      { id: 2, fieldName: `${label_use_case} Phases`, fieldValue: null },
    ],
  },
  {
    id: 9,
    reportName: `${label_client} Contacts`,
    slug: 'Client Contacts',
    img: <ResourcesImage />,
    reportFields: [
      { id: 1, fieldName: `Number of ${label_client} Contacts`, fieldValue: null },
      { id: 2, fieldName: `Total ${label_client}s`, fieldValue: null },
    ],
  },
];

const reportsSkeletonProjectARR: any = [
  {
    id: 1,
    reportName: 'Revenue',
    slug: 'Revenue',
    img: <RevenueImage />,
    reportFields: [
      { id: 1, fieldName: 'Accounts for renewal', fieldValue: null },
      { id: 2, fieldName: `${label_project} ${label_arr}`, fieldValue: null },
    ],
  },
  {
    id: 2,
    reportName: 'Resources',
    slug: 'Resources',
    img: <ResourcesImage />,
    reportFields: [
      { id: 1, fieldName: 'Number of Resources', fieldValue: null },
      { id: 2, fieldName: `Total ${label_project}s`, fieldValue: null },
    ],
  },
  {
    id: 3,
    reportName: `${label_project}s`,
    slug: 'Projects',
    img: <ProjectsImage />,
    reportFields: [
      { id: 1, fieldName: `Open ${label_project}s`, fieldValue: null },
      { id: 2, fieldName: `Closed ${label_project}s`, fieldValue: null },
    ],
  },
  {
    id: 4,
    reportName: `${label_use_case}s`,
    slug: 'Usecases',
    img: <UsecasesImage />,
    reportFields: [
      { id: 1, fieldName: `Open ${label_use_case}s`, fieldValue: null },
      { id: 2, fieldName: `Closed ${label_use_case}s`, fieldValue: null },
    ],
  },
  {
    id: 5,
    reportName: `${label_task}s`,
    slug: 'Tasks',
    img: <TasksImage />,
    reportFields: [
      { id: 1, fieldName: `Internal ${label_task}s`, fieldValue: null },
      {
        id: 2,
        fieldName: `External ${label_task}s`,
        fieldValue: null,
      },
    ],
  },
  {
    id: 6,
    reportName: 'Performance',
    slug: 'Performance',
    img: <TasksImage />,
    reportFields: [
      { id: 1, fieldName: 'CSAT Score', fieldValue: null },
      { id: 2, fieldName: `${label_project} Performance`, fieldValue: null },
      { id: 3, fieldName: 'Team Performance', fieldValue: null },
    ],
  },
  {
    id: 7,
    reportName: 'Phases',
    slug: 'Phases',
    img: <TasksImage />,
    reportFields: [
      { id: 1, fieldName: `${label_project} Phases`, fieldValue: null },
      { id: 2, fieldName: `${label_use_case} Phases`, fieldValue: null },
    ],
  },
  {
    id: 9,
    reportName: `${label_client} Contacts`,
    slug: 'Client Contacts',
    img: <ResourcesImage />,
    reportFields: [
      { id: 1, fieldName: `Number of ${label_client} Contacts`, fieldValue: null },
      { id: 2, fieldName: `Total ${label_client}s`, fieldValue: null },
    ],
  },
];

function displayReportFieldValue(reportFieldValue: any, valueStatus: any) {
  if (valueStatus == 'loading') {
    return (
      <Box sx={{ display: 'flex' }}>
        <CircularProgress size={14} />
      </Box>
    );
  } else {
    return reportFieldValue;
  }
}

function getAccountsForRenewal(renewalTasks: any[] = []) {
  return renewalTasks
    ? renewalTasks.reduce((accountsForRenewal, renewalTask) => {
        const taskStage = renewalTask.renewal_task_stage == 'Not Done' ? 1 : 0;
        accountsForRenewal = accountsForRenewal + taskStage;
        return accountsForRenewal;
      }, 0)
    : 'No Accounts found';
}

const Reports: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pageFilter, setpageFilter] = React.useState<any>([]);
  const [pageList, setPageList] = React.useState<any>([]);
  const [selectedReport, setSelectedReport] = React.useState<number>(0);
  const { state }: { state: any } = useLocation();

  const customerDetail = useAppSelector(selectCustomerDetails).data;

  useEffect(() => {
    console.log(' state ====== ', state);
    if (state?.showCustomReports == 1) {
      setSelectedReport(1);
    } else if (state?.showCustomReports == 2) {
      setSelectedReport(2);
    } else if (state?.showAggregatedCustomReports == 1) {
      setSelectedReport(2);
    }
  }, []);

  // useEffect(() => {
  //   console.log(' selectedReport = ', selectedReport);
  // }, [selectedReport]);

  const jwt = cookies.get('jwt-token');

  useEffect(() => {
    const pages: any = [];
    if (jwt) {
      const decodedToken = jwtDecode<JWTToken>(jwt as string);
      const nestedAllPagesData: any[] = decodedToken.pages.filter((ele: any) => {
        return ele.url.split('/').length - 1 == 2;
      });
      nestedAllPagesData.map((page: any) => {
        if (page.hasAccess == true) {
          pages.push(page);
        }
      });
      setPageList(pages);
    }
  }, []);

  useEffect(() => {
    let newArr: any = [];
    let rbacEnabled = false;
    if (jwt) {
      const decodedToken = jwtDecode<JWTToken>(jwt as string);
      rbacEnabled = decodedToken.account.config.enable_rbac;
    }

    if (rbacEnabled) {
      if (customerDetail.revenue_report_basis == 0) {
        reportsSkeleton.map((reportsSubParts: any) => {
          return pageList.map((page: any) => {
            if (page.name == reportsSubParts.slug) newArr.push(reportsSubParts);
          });
        });
      } else {
        reportsSkeletonProjectARR.map((reportsSubParts: any) => {
          return pageList.map((page: any) => {
            if (page.name == reportsSubParts.slug) newArr.push(reportsSubParts);
          });
        });
      }
    } else {
      if (customerDetail.revenue_report_basis == 0) {
        newArr = reportsSkeleton;
      } else {
        newArr = reportsSkeletonProjectARR;
      }
    }
    console.log(newArr, 'newArr');

    setpageFilter(newArr);
  }, [pageList, customerDetail]);

  useEffect(() => {
    console.log(pageFilter, 'page filter');
  }, [pageFilter, jwt]);

  const openProjects = useAppSelector(selectOpenProjects);
  const openProjectsState = useAppSelector(selectOpenProjectsStatus);

  const closedProjects = useAppSelector(selectClosedProjects);
  const closedProjectsState = useAppSelector(selectClosedProjectsStatus);

  const closedUsecases = useAppSelector(selectClosedUsecases);
  const closedUsecasesState = useAppSelector(selectClosedUsecaseStatus);

  const allOpenUseCases = useAppSelector(selectOpenUsecases);

  const [openUsecases, setOpenUseCases] = useState<any>([]);
  useEffect(() => {
    if (allOpenUseCases.length != 0) {
      const filterUsecases = allOpenUseCases.filter((element: any) => element.case_stage != 'On Hold');
      setOpenUseCases(filterUsecases);
    }
  }, [allOpenUseCases]);
  console.log('openusercases', openUsecases);
  const openUsecasesState = useAppSelector(selectOpenUsecasesStatus);

  const users = useAppSelector(selectUsers);
  const usersState = useAppSelector(selectUsersStatus);

  const renewalTasks = useAppSelector(selectRenewalTasks);
  const renewalTasksState = useAppSelector(selectRenewalTasksStatus);

  const revenueTasks = useAppSelector(selectRevenueTasks);
  const revenueTaskState = useAppSelector(selectRevenueTasksStatus);

  const openTasks = useAppSelector(selectOpenTasks);
  const openTasksState = useAppSelector(selectOpenTasksStatus);

  const closedTasks = useAppSelector(selectClosedTasks);
  const closedTasksState = useAppSelector(selectClosedTasksStatus);

  const clientContacts = useAppSelector(selectClientContactsOfCompany);
  const clientContactsState = useAppSelector(selectClientContactsOfCompanyStatus);
  const allClientsByCustomerUid = useAppSelector(selectAllClientsByCustomerUid);

  function getImplementationFeeFromRevenueTasks(revenueTasks: any[] = []) {
    const value = revenueTasks.reduce(
      (implementationFee: number, revenueProjectTask: any) =>
        (implementationFee =
          implementationFee +
          parseInt(revenueProjectTask.implementation_fee == null ? '0' : revenueProjectTask.implementation_fee)),
      0
    );
    return revenueTasks ? formatCurrency(value, customerDetail?.currency_code) : 'No Tasks Found';
  }

  function uniq(arr: any) {
    const result: any = {};
    arr.map((el: any) => {
      result[el.project_uid] = el;
    });
    return Object.values(result);
  }

  function getProjectArrFromRevenueTasks(revenueTasks: any[] = []) {
    const uniqueProjects = uniq(revenueTasks);

    console.log(uniqueProjects);
    const value = uniqueProjects.reduce(
      (projectArr: number, revenueProjectTask: any) =>
        (projectArr =
          projectArr + parseInt(revenueProjectTask.project_ARR == null ? '0' : revenueProjectTask.project_ARR)),
      0
    );
    return revenueTasks ? formatCurrency(value, customerDetail?.currency_code) : 'No Tasks Found';
  }

  useEffect(() => {
    let pages: any = [];
    let rbacEnabled = false;
    if (jwt) {
      const decodedToken = jwtDecode<JWTToken>(jwt as string);
      pages = decodedToken.pages;
      rbacEnabled = decodedToken.account.config.enable_rbac;
    }

    //has Report->Revenue Sub Page Access
    const revenueSubPage: any = pages.find((page: any) => page.url === '/reports/revenue');
    if (!rbacEnabled || typeof revenueSubPage !== 'undefined') {
      dispatch(fetchRenewalTasks());
      dispatch(fetchRevenueTasks());
    }

    //has Report->Resources Sub Page Access
    const resourcesSubPage: any = pages.find((page: any) => page.url === '/reports/resources');
    if (!rbacEnabled || typeof resourcesSubPage !== 'undefined') {
      dispatch(fetchAllUsers());
    }

    //has Report->Projects Sub Page Access
    const projectsSubPage: any = pages.find((page: any) => page.url === '/reports/projects');
    console.log(projectsSubPage, 'projectsSubPage');
    if (!rbacEnabled || typeof resourcesSubPage !== 'undefined') {
      dispatch(fetchClosedProjects());
      dispatch(fetchOpenProjects());
    }

    //has Report->Usecase Sub Page Access
    const usecaseSubPage: any = pages.find((page: any) => page.url === '/reports/usecases');
    console.log(usecaseSubPage, 'usecaseSubPage');
    if (!rbacEnabled || typeof resourcesSubPage !== 'undefined') {
      dispatch(fetchOpenUsecases());
      dispatch(fetchClosedUsecases());
    }

    //has Report->Tasks Sub Page Access
    const taskSubPage: any = pages.find((page: any) => page.url === '/reports/tasks');
    console.log(taskSubPage, 'taskSubPage');
    if (!rbacEnabled || typeof resourcesSubPage !== 'undefined') {
      dispatch(getOpenTasks());
      dispatch(getClosedTasks());
    }

    //has Report->Tasks Sub Page Access
    const clientContactsSubPage: any = pages.find((page: any) => page.url === '/reports/client-contacts');
    if (!rbacEnabled || typeof clientContactsSubPage !== 'undefined') {
      dispatch(getAllClientContacts());
      dispatch(getAllClientsByCustomerUidForClientContactReport());
      // dispatch(getClosedTasks());
    }

    // options for filters
    dispatch(getAllUsers());
    dispatch(getAllSegmentValues());
    dispatch(fetchAllJourneyStages());
    dispatch(fetchAllIndustries());
    dispatch(getCustomerDetails());
  }, []);

  function handleOnReportCardClick(reportName: string) {
    navigate(reportName);
  }

  const headerSx = {
    fontFamily: 'Noto Sans',
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '32px',
    color: '#000000',
    marginTop: '20px',
  };

  const fieldNameSx = {
    fontFamily: 'Noto Sans',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '20px',
    color: '#7a7a7a',
    marginTop: '20px',
  };

  const fieldValueSx = {
    fontFamily: 'Noto Sans',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#000000',
  };

  return (
    <React.Fragment>
      <Box sx={{ padding: '20px' }}>
        {
          <Box>
            <Stack direction={'row'} justifyContent={'space-between'}>
              <CognisaasTabs
                selectedTabIndex={selectedReport}
                handleTabSwitch={(e: any, index: number) => setSelectedReport(index)}
                tabs={reportTabs}
                size="squeezed"
              />

              {enableCustomReports == 'true' && selectedReport == 1 && (
                <CognisaasButton
                  isOutlined={false}
                  clickHandler={() => {
                    navigate('/reports/create-report');
                  }}
                  label={'Create Report'}
                />
              )}

              {/* {enableCustomReports == 'true' && selectedReport == 2 && (
                <CognisaasButton
                  isOutlined={false}
                  clickHandler={() => {
                    navigate('/reports/create-aggregated-custom-report');
                  }}
                  label={'Create Aggregated Report'}
                />
              )} */}
            </Stack>
            {selectedReport === 0 ? (
              <Grid marginTop="10px" container spacing={2}>
                {pageFilter.length == 0 && (
                  <Typography sx={{ ml: '15px' }}>
                    You do not have access to this page sections, please contact your Administrator
                  </Typography>
                )}
                {pageFilter.map((report: any) => (
                  <Grid
                    container
                    key={report.id}
                    item
                    xs={6}
                    md={4}
                    className={'cognisaas-pointer'}
                    // !Make it proper later
                    onClick={() =>
                      handleOnReportCardClick(
                        report.reportName === `${label_use_case}s`
                          ? 'UseCases'
                          : report.reportName === `${label_project}s`
                          ? 'Projects'
                          : report.reportName === `${label_task}s`
                          ? 'Tasks'
                          : report.reportName === `${label_client} Contacts`
                          ? 'Client-Contacts'
                          : report.reportName
                      )
                    }
                  >
                    <Container
                      sx={{
                        background: 'white',
                        padding: '20px',
                        boxShadow: '0px 4px 26px rgba(0, 0, 0, 0.05)',
                        borderRadius: '4px',
                      }}
                    >
                      <Grid container>
                        <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                          {report.img}
                          <Typography sx={headerSx} component={'span'} variant={'body2'}>
                            {report.reportName}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          {report.reportFields.map((field: { id: any; fieldName: string }) => (
                            <Container key={field.id}>
                              <Stack>
                                <Typography sx={fieldNameSx} component={'span'} variant={'body2'}>
                                  {field.fieldName.includes(`Open ${label_use_case}s`)
                                    ? `Open ${label_use_case}s`
                                    : field.fieldName.includes('Closed Usecases')
                                    ? `Closed ${label_use_case}s`
                                    : field.fieldName}
                                </Typography>
                                <Typography sx={fieldValueSx} component={'span'}>
                                  {
                                    //the code below works as a switch case
                                    {
                                      ['Accounts for renewal']: displayReportFieldValue(
                                        getAccountsForRenewal(renewalTasks),
                                        renewalTasksState
                                      ),
                                      ['Implementation Fee']: displayReportFieldValue(
                                        getImplementationFeeFromRevenueTasks(revenueTasks),
                                        revenueTaskState
                                      ),
                                      [`${label_project} ${label_arr}`]: displayReportFieldValue(
                                        getProjectArrFromRevenueTasks(revenueTasks),
                                        revenueTaskState
                                      ),
                                      ['Number of Resources']: displayReportFieldValue(
                                        users ? users.length : 0,
                                        usersState
                                      ),
                                      [`Number of ${label_client} Contacts`]: displayReportFieldValue(
                                        clientContacts ? clientContacts.length : 0,
                                        clientContactsState
                                      ),
                                      [`Total ${label_client}s`]: displayReportFieldValue(
                                        allClientsByCustomerUid ? allClientsByCustomerUid?.data?.length : 0,
                                        allClientsByCustomerUid?.status
                                      ),
                                      [`Total ${label_project}s`]: displayReportFieldValue(
                                        (openProjects ? openProjects.length : 0) +
                                          (closedProjects ? closedProjects.length : 0),
                                        openProjectsState != 'loading' && closedProjectsState != 'loading'
                                          ? 'success'
                                          : 'loading'
                                      ),
                                      [`Open ${label_project}s`]: displayReportFieldValue(
                                        openProjects ? openProjects.length : 0,
                                        openProjectsState
                                      ),
                                      [`Closed ${label_project}s`]: displayReportFieldValue(
                                        closedProjects ? closedProjects.length : 0,
                                        closedProjectsState
                                      ),
                                      [`Open ${label_use_case}s`]: displayReportFieldValue(
                                        openUsecases ? openUsecases.length : 0,
                                        openUsecasesState
                                      ),
                                      [`Closed ${label_use_case}s`]: displayReportFieldValue(
                                        closedUsecases ? closedUsecases.length : 0,
                                        closedUsecasesState
                                      ),
                                      [`Internal ${label_task}s`]: displayReportFieldValue(
                                        openTasks.length,
                                        openTasksState
                                      ),
                                      [`External ${label_task}s`]: displayReportFieldValue(
                                        closedTasks.length,
                                        closedTasksState
                                      ),
                                    }[field.fieldName]
                                  }
                                </Typography>
                              </Stack>
                            </Container>
                          ))}
                        </Grid>
                      </Grid>
                    </Container>
                  </Grid>
                ))}
              </Grid>
            ) : selectedReport === 1 ? (
              <CustomReports />
            ) : (
              <></>
            )}
          </Box>
        }
      </Box>
    </React.Fragment>
  );
};

export default Reports;
