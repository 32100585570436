import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../';

export const TemplateSlice = (state: RootState) => state.templateSlice;

export const selectProjectTemplates = createSelector(TemplateSlice, (TemplateSlice) => TemplateSlice.projectTemplates);
export const selectUsecaseTemplates = createSelector(TemplateSlice, (TemplateSlice) => TemplateSlice.usecaseTemplates);
export const selectUsecaseTemplatesStatus = createSelector(
  TemplateSlice,
  (TemplateSlice) => TemplateSlice.usecaseTemplatesStatus
);
export const selectUserDetails = createSelector(TemplateSlice, (TemplateSlice) => TemplateSlice.userDetails);
export const selectTemplateProjectUid = createSelector(
  TemplateSlice,
  (TemplateSlice) => TemplateSlice.tempProjectTemplateUid
);
export const selectTemplateCaseUid = createSelector(
  TemplateSlice,
  (TemplateSlice) => TemplateSlice.tempCaseTemplateUid
);
export const selectTemplateSectionSequence = createSelector(
  TemplateSlice,
  (TemplateSlice) => TemplateSlice.template_section_sequence
);
export const selectTemplateProjectUidNew = createSelector(
  TemplateSlice,
  (TemplateSlice) => TemplateSlice.template_project_uid
);
export const selectAllSectionTemplates = createSelector(TemplateSlice, (TemplateSlice) => TemplateSlice.AllSections);
export const selectuseCaseTemplateUid = createSelector(
  TemplateSlice,
  (TemplateSlice) => TemplateSlice.useCaseTemplateUid
);
export const selectusecaseTemplateSections = createSelector(
  TemplateSlice,
  (TemplateSlice) => TemplateSlice.usecaseTemplateSections
);
export const selectuseCaseTemplateSectionSequenceNo = createSelector(
  TemplateSlice,
  (TemplateSlice) => TemplateSlice.useCaseTemplateSectionSequenceNo
);
export const selectredirectToTemplates = createSelector(
  TemplateSlice,
  (TemplateSlice) => TemplateSlice.redirectToTemplates
);
export const selectprojectTemplateInformation = createSelector(
  TemplateSlice,
  (TemplateSlice) => TemplateSlice.projectTemplateInformation
);

export const selectUsecaseTemplateInformation = createSelector(
  TemplateSlice,
  (TemplateSlice) => TemplateSlice.usecaseTemplateInformation
);

export const selectTemplateprojectInformation = createSelector(
  TemplateSlice,
  (TemplateSlice) => TemplateSlice.getTemplateProjectInformation
);

export const selectTemplateProjectTaskDependency = createSelector(
  TemplateSlice,
  (TemplateSlice) => TemplateSlice.getTemplateProjectTaskDependency
);

export const selectTemplateProjectTeamTasks = createSelector(
  TemplateSlice,
  (TemplateSlice) => TemplateSlice.getTemplateProjectTeamTaskList
);

export const selectTemplateProjectClientTasks = createSelector(
  TemplateSlice,
  (TemplateSlice) => TemplateSlice.getTemplateProjectClientTaskList
);

export const selectTemplateUsecaseTaskDependency = createSelector(
  TemplateSlice,
  (TemplateSlice) => TemplateSlice.getTemplateUsecaseTaskDependency
);

export const selectTemplateUsecaseTeamTasks = createSelector(
  TemplateSlice,
  (TemplateSlice) => TemplateSlice.getTemplateUsecaseTeamTaskList
);

export const selectTemplateUsecaseClientTasks = createSelector(
  TemplateSlice,
  (TemplateSlice) => TemplateSlice.getTemplateUsecaseClientTaskList
);

export const selectTemplateDocuments = createSelector(
  TemplateSlice,
  (TemplateSlice) => TemplateSlice.getTemplateDocuments
);

export const selectAddTemplateDocument = createSelector(
  TemplateSlice,
  (TemplateSlice) => TemplateSlice.addTemplateDocument
);

export const selectNewDocumentModalStatus = createSelector(
  TemplateSlice,
  (TemplateSlice) => TemplateSlice.newDocumentTemplateModal
);

export const selectToggleCreateProjectTemplateExcelViewSelectedState = createSelector(
  TemplateSlice,
  (TemplateSlice) => TemplateSlice.createProjectTemplateExcelViewSelected
);

export const selectCreateProjectTemplateExcelData = createSelector(TemplateSlice, (TemplateSlice) => [
  ...TemplateSlice.createProjectTemplateExcelView,
]);

export const selectToggleUpdateProjectTemplateExcelViewSelectedState = createSelector(
  TemplateSlice,
  (TemplateSlice) => TemplateSlice.updateProjectTemplateExcelViewSelected
);

export const selectUpdateProjectTemplateExcelData = createSelector(TemplateSlice, (TemplateSlice) => [
  ...TemplateSlice.updateProjectTemplateExcelView,
]);

export const selectToggleCreateUsecaseTemplateExcelViewSelectedState = createSelector(
  TemplateSlice,
  (TemplateSlice) => TemplateSlice.createUsecaseTemplateExcelViewSelected
);

export const selectCreateUsecaseTemplateExcelData = createSelector(TemplateSlice, (TemplateSlice) => [
  ...TemplateSlice.createUsecaseTemplateExcelView,
]);

export const selectToggleUpdateUsecaseTemplateExcelViewSelectedState = createSelector(
  TemplateSlice,
  (TemplateSlice) => TemplateSlice.updateUsecaseTemplateExcelViewSelected
);

export const selectUpdateUsecaseTemplateExcelData = createSelector(TemplateSlice, (TemplateSlice) => [
  ...TemplateSlice.updateUsecaseTemplateExcelView,
]);
