import { Typography, Stack, Grid, IconButton, Divider } from '@mui/material';
import { useEffect, useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CognisaasMenu from '../../../shared/CognisaasMenu/CognisaasMenu';
import { addTaskAuditTrail, deleteUsecaseTaskDependency } from 'store/modules/Task/slice';
import { useDispatch } from 'react-redux';
import { getTaskDependency } from 'store/modules/Task/slice';
import { ReactComponent as NoRecordsIcon } from '../../../../assets/svgs/no_records_mini.svg';
import {
  label_client,
  label_project,
  label_task,
  label_use_case,
} from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { userPermissionSchema } from 'components/Reports/SideDrawer/types';
import DeleteModal from 'components/shared/CognisaasModal/DeleteModal';
import { getLoggedInUserUid, getUserNameByUserUid } from 'utils/getUid';
import { format } from 'date-fns';

const AddedDependencies = (props: any) => {
  const { userPermissions } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [teamTasks, setTeamTasks] = useState<any[]>([]);
  const [clientTasks, setClientTasks] = useState<any[]>([]);
  const [taskToDelete, setTaskToDelete] = useState<any>(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [toBedeletedDependencyPayload, setToBedeletedDependencyPayload] = useState<any>({});
  const [toBeDeletedGetDependencyPayload, setToBeDeletedGetDependencyPayload] = useState<any>({});

  const dispatch = useDispatch();
  const isProjectLevel = props.projectLevel;
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (props.addedDependencies.length) {
      const dependencyDetails: any[] = [];
      for (let i = 0; i < props.addedDependencies.length; i++) {
        for (let j = 0; j < props.allTasks.length; j++) {
          if (props.addedDependencies[i].task_parent_uid == props.allTasks[j].task_uid) {
            dependencyDetails.push(props.allTasks[j]);
          }
        }
      }
      const teamTasks = dependencyDetails.filter((task: any) => task.is_team_task == '1');
      const clientTasks = dependencyDetails.filter((task: any) => task.is_team_task == '0');
      setTeamTasks([...teamTasks]);
      setClientTasks([...clientTasks]);
    }
  }, [props.allTasks, props.addedDependencies]);

  const handleExcelClick = (event: React.MouseEvent<HTMLElement>, handleTask: any) => {
    setTaskToDelete({ ...handleTask });
    setAnchorEl(event.currentTarget);
  };

  const handleExcelClose = () => {
    setAnchorEl(null);
  };

  const handleSelectFromMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
    if (event.currentTarget.innerText == 'Dependencies') {
      // setAddDependenciesModal(true);
    } else if (event.currentTarget.innerText == 'Delete Dependency') {
      // deleteSubtask();
      const deleteThis: any = [];
      const deleteDetail: any = [];
      for (let i = 0; i < props.addedDependencies.length; i++) {
        if (props.addedDependencies[i].task_parent_uid == taskToDelete.task_uid) {
          deleteThis.push(props.addedDependencies[i].dependency_id);
          deleteDetail.push(props.addedDependencies[i]);
        }
      }
      const task = props.allTasks.filter((item: any) => item.task_uid === taskToDelete.task_uid)[0];
      let detail;
      if (task.case_uid) {
        detail = {
          dependency_id: deleteThis[0],
        };
      } else {
        detail = {
          dependency_id: deleteThis[0],
          project_uid: task.project_uid,
        };
      }

      const getDependency = {
        task_uid: props.addedDependencies[0]?.task_uid,
        type:
          props.allTasks.filter((item: any) => item.task_uid === taskToDelete.task_uid)[0].case_uid != null
            ? 'usecase'
            : 'project',
      };
      setToBedeletedDependencyPayload(detail);
      setToBeDeletedGetDependencyPayload(getDependency);
      setOpenDeleteModal(true);
    }
  };

  const deleteSelectedDependency = async (detail: any, getDependency: any) => {
    await dispatch(deleteUsecaseTaskDependency(detail));
    const auditTrail = {
      field: 'Dependency',
      task_uid: taskToDelete.task_uid,
      user_uid: getLoggedInUserUid(),
      new_value: '',
      old_value: '',
      modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
    };

    dispatch(addTaskAuditTrail(auditTrail));

    await dispatch(getTaskDependency(getDependency));
  };

  const renderContent = () => {
    return (
      <>
        {isProjectLevel && (
          <>
            <Typography variant="h3">
              Precedent {label_project} {label_task} of team
            </Typography>
            {teamTasks.length > 0 && (
              <Grid>
                <Grid mt={3} container direction="row" justifyContent={'space-around'} alignItems="center">
                  <Grid item md={2}>
                    <Typography variant="subtitle2" className="label-text">
                      {label_task} Details
                    </Typography>
                  </Grid>
                  <Grid item md={2}>
                    <Typography variant="subtitle2" className="label-text">
                      {label_task} Owner
                    </Typography>
                  </Grid>
                  <Grid item md={2}>
                    <Typography variant="subtitle2" className="label-text">
                      {label_task} Stage
                    </Typography>
                  </Grid>
                  <Grid item md={2}>
                    <Typography variant="subtitle2" className="label-text">
                      {label_task} Status
                    </Typography>
                  </Grid>
                  <Grid item md={2}></Grid>
                </Grid>
                <Divider />
              </Grid>
            )}

            {teamTasks.length > 0 ? (
              teamTasks.map((task: any, index: number) => (
                <Grid mb={1} container key={index} direction="row" justifyContent={'space-around'} alignItems="center">
                  <Grid item sm={2} md={2}>
                    <Typography
                      //  onClick={() => callUpdateSubtaskModal(task)}
                      style={{ color: '#2D60F6' }}
                      className="cognisaas-pointer"
                      variant="subtitle2"
                    >
                      {task.subtask_detail == '' ? 'test1' : task.task_name.slice(0, 6)}
                    </Typography>
                  </Grid>
                  <Grid item sm={2} md={2}>
                    <Typography variant="subtitle2">
                      {task.subtask_owner == '' ? 'test1' : getUserNameByUserUid(task.task_owner)}
                    </Typography>
                  </Grid>
                  <Grid item sm={2} md={2}>
                    <Typography variant="subtitle2">{task.task_stage}</Typography>
                  </Grid>
                  <Grid item sm={2} md={2}>
                    <Typography variant="subtitle2">{task.task_status}</Typography>
                  </Grid>
                  <Grid item sm={2} md={2}>
                    <Stack direction="row">
                      <Typography variant="subtitle2">{task.subtask_due_date}</Typography>
                      <IconButton onClick={(e: any) => handleExcelClick(e, task)} sx={{ margin: '0px' }}>
                        <MoreVertIcon />
                      </IconButton>
                      <CognisaasMenu
                        anchorEl={anchorEl}
                        open={open}
                        handleSelectFromMenu={handleSelectFromMenu}
                        handleMenuClose={handleExcelClose}
                        list={['Delete Dependency']}
                      ></CognisaasMenu>
                    </Stack>
                  </Grid>
                  <Divider />
                </Grid>
              ))
            ) : (
              <Stack alignItems={'center'} justifyContent={'center'} sx={{ margin: '20px 0px' }}>
                <Typography variant="subtitle1" className="label-text" sx={{ marginBottom: '20px' }}>
                  No Dependencies on {label_project} team {label_task}s
                </Typography>

                <NoRecordsIcon style={{ width: '150px', height: '150px' }} />
              </Stack>
            )}
            <>
              <Typography variant="h3">
                Precedent {label_project} {label_task} of {label_client}
              </Typography>
              {clientTasks.length > 0 && (
                <Grid>
                  <Grid mt={3} container direction="row" justifyContent={'space-around'} alignItems="center">
                    <Grid item md={2}>
                      <Typography variant="subtitle2" className="label-text">
                        {label_task} Details
                      </Typography>
                    </Grid>
                    <Grid item md={2}>
                      <Typography variant="subtitle2" className="label-text">
                        {label_task} Owner
                      </Typography>
                    </Grid>
                    <Grid item md={2}>
                      <Typography variant="subtitle2" className="label-text">
                        {label_task} Stage
                      </Typography>
                    </Grid>
                    <Grid item md={2}>
                      <Typography variant="subtitle2" className="label-text">
                        {label_task} Status
                      </Typography>
                    </Grid>
                    <Grid item md={2}></Grid>
                  </Grid>
                  <Divider />
                </Grid>
              )}

              {clientTasks.length > 0 ? (
                clientTasks.map((task: any, index: number) => (
                  <Grid
                    mb={1}
                    container
                    key={index}
                    direction="row"
                    justifyContent={'space-around'}
                    alignItems="center"
                  >
                    <Grid item sm={2} md={2}>
                      <Typography
                        //  onClick={() => callUpdateSubtaskModal(task)}
                        style={{ color: '#2D60F6' }}
                        className="cognisaas-pointer"
                        variant="subtitle2"
                      >
                        {task.subtask_detail == '' ? 'test1' : task.task_name.slice(0, 6)}
                      </Typography>
                    </Grid>
                    <Grid item sm={2} md={2}>
                      <Typography variant="subtitle2">
                        {task.subtask_owner == '' ? 'test1' : getUserNameByUserUid(task.task_owner)}
                      </Typography>
                    </Grid>
                    <Grid item sm={2} md={2}>
                      <Typography variant="subtitle2">{task.task_stage}</Typography>
                    </Grid>
                    <Grid item sm={2} md={2}>
                      <Typography variant="subtitle2">{task.task_status}</Typography>
                    </Grid>
                    <Grid item sm={2} md={2}>
                      <Stack direction="row">
                        <Typography variant="subtitle2">{task.subtask_due_date}</Typography>
                        <IconButton onClick={(e: any) => handleExcelClick(e, task)} sx={{ margin: '0px' }}>
                          <MoreVertIcon />
                        </IconButton>
                        <CognisaasMenu
                          anchorEl={anchorEl}
                          open={open}
                          handleSelectFromMenu={handleSelectFromMenu}
                          handleMenuClose={handleExcelClose}
                          list={['Delete Dependency']}
                        ></CognisaasMenu>
                      </Stack>
                    </Grid>
                    <Divider />
                  </Grid>
                ))
              ) : (
                <Stack alignItems={'center'} justifyContent={'center'} sx={{ margin: '20px 0px' }}>
                  <Typography variant="subtitle1" className="label-text" sx={{ marginBottom: '20px' }}>
                    No Dependencies on {label_project} {label_client} {label_task}s
                  </Typography>

                  <NoRecordsIcon style={{ width: '150px', height: '150px' }} />
                </Stack>
              )}
            </>
          </>
        )}
        {!isProjectLevel && (
          <>
            <Typography variant="h3">
              Precedent {label_use_case} {label_task} of team
            </Typography>
            {teamTasks.length > 0 && (
              <Grid>
                <Grid mt={3} container direction="row" justifyContent={'space-around'} alignItems="center">
                  <Grid item md={2}>
                    <Typography variant="subtitle2" className="label-text">
                      {label_task} Details
                    </Typography>
                  </Grid>
                  <Grid item md={2}>
                    <Typography variant="subtitle2" className="label-text">
                      {label_task} Owner
                    </Typography>
                  </Grid>
                  <Grid item md={2}>
                    <Typography variant="subtitle2" className="label-text">
                      {label_task} Stage
                    </Typography>
                  </Grid>
                  <Grid item md={2}>
                    <Typography variant="subtitle2" className="label-text">
                      {label_task} Status
                    </Typography>
                  </Grid>
                  <Grid item md={2}></Grid>
                </Grid>
                <Divider />
              </Grid>
            )}

            {teamTasks.length > 0 ? (
              teamTasks.map((task: any, index: number) => (
                <Grid mb={1} container key={index} direction="row" justifyContent={'space-around'} alignItems="center">
                  <Grid item sm={2} md={2}>
                    <Typography
                      //  onClick={() => callUpdateSubtaskModal(task)}
                      style={{ color: '#2D60F6' }}
                      className="cognisaas-pointer"
                      variant="subtitle2"
                    >
                      {task.subtask_detail == '' ? 'test1' : task.task_name.slice(0, 6)}
                    </Typography>
                  </Grid>
                  <Grid item sm={2} md={2}>
                    <Typography variant="subtitle2">
                      {task.subtask_owner == '' ? 'test1' : getUserNameByUserUid(task.task_owner)}
                    </Typography>
                  </Grid>
                  <Grid item sm={2} md={2}>
                    <Typography variant="subtitle2">{task.task_stage}</Typography>
                  </Grid>
                  <Grid item sm={2} md={2}>
                    <Typography variant="subtitle2">{task.task_status}</Typography>
                  </Grid>
                  <Grid item sm={2} md={2}>
                    <Stack direction="row">
                      <Typography variant="subtitle2">{task.subtask_due_date}</Typography>
                      <IconButton onClick={(e: any) => handleExcelClick(e, task)} sx={{ margin: '0px' }}>
                        <MoreVertIcon />
                      </IconButton>
                      <CognisaasMenu
                        anchorEl={anchorEl}
                        open={open}
                        handleSelectFromMenu={handleSelectFromMenu}
                        handleMenuClose={handleExcelClose}
                        list={['Delete Dependency']}
                      ></CognisaasMenu>
                    </Stack>
                  </Grid>
                  <Divider />
                </Grid>
              ))
            ) : (
              <Stack alignItems={'center'} justifyContent={'center'} sx={{ margin: '20px 0px' }}>
                <Typography variant="subtitle1" className="label-text" sx={{ marginBottom: '20px' }}>
                  No Dependencies on {label_use_case} team {label_task}s
                </Typography>

                <NoRecordsIcon style={{ width: '150px', height: '150px' }} />
              </Stack>
            )}
            <>
              <Typography variant="h3">
                Precedent {label_use_case} {label_task} of {label_client}
              </Typography>
              {clientTasks.length > 0 && (
                <Grid>
                  <Grid mt={3} container direction="row" justifyContent={'space-around'} alignItems="center">
                    <Grid item md={2}>
                      <Typography variant="subtitle2" className="label-text">
                        {label_task} Details
                      </Typography>
                    </Grid>
                    <Grid item md={2}>
                      <Typography variant="subtitle2" className="label-text">
                        {label_task} Owner
                      </Typography>
                    </Grid>
                    <Grid item md={2}>
                      <Typography variant="subtitle2" className="label-text">
                        {label_task} Stage
                      </Typography>
                    </Grid>
                    <Grid item md={2}>
                      <Typography variant="subtitle2" className="label-text">
                        {label_task} Status
                      </Typography>
                    </Grid>
                    <Grid item md={2}></Grid>
                  </Grid>
                  <Divider />
                </Grid>
              )}

              {clientTasks.length > 0 ? (
                clientTasks.map((task: any, index: number) => (
                  <Grid
                    mb={1}
                    container
                    key={index}
                    direction="row"
                    justifyContent={'space-around'}
                    alignItems="center"
                  >
                    <Grid item sm={2} md={2}>
                      <Typography
                        //  onClick={() => callUpdateSubtaskModal(task)}
                        style={{ color: '#2D60F6' }}
                        className="cognisaas-pointer"
                        variant="subtitle2"
                      >
                        {task.subtask_detail == '' ? 'test1' : task.task_name.slice(0, 6)}
                      </Typography>
                    </Grid>
                    <Grid item sm={2} md={2}>
                      <Typography variant="subtitle2">
                        {task.subtask_owner == '' ? 'test1' : getUserNameByUserUid(task.task_owner)}
                      </Typography>
                    </Grid>
                    <Grid item sm={2} md={2}>
                      <Typography variant="subtitle2">{task.task_stage}</Typography>
                    </Grid>
                    <Grid item sm={2} md={2}>
                      <Typography variant="subtitle2">{task.task_status}</Typography>
                    </Grid>
                    <Grid item sm={2} md={2}>
                      <Stack direction="row">
                        <Typography variant="subtitle2">{task.subtask_due_date}</Typography>
                        <IconButton onClick={(e: any) => handleExcelClick(e, task)} sx={{ margin: '0px' }}>
                          <MoreVertIcon />
                        </IconButton>
                        {userPermissions?.includes(userPermissionSchema.EDIT) ? (
                          <CognisaasMenu
                            anchorEl={anchorEl}
                            open={open}
                            handleSelectFromMenu={handleSelectFromMenu}
                            handleMenuClose={handleExcelClose}
                            list={['Delete Dependency']}
                          />
                        ) : (
                          <CognisaasMenu
                            anchorEl={anchorEl}
                            open={open}
                            handleSelectFromMenu={handleSelectFromMenu}
                            handleMenuClose={handleExcelClose}
                            list={['Delete Dependency']}
                          />
                        )}
                      </Stack>
                    </Grid>
                    <Divider />
                  </Grid>
                ))
              ) : (
                <Stack alignItems={'center'} justifyContent={'center'} sx={{ margin: '20px 0px' }}>
                  <Typography variant="subtitle1" className="label-text" sx={{ marginBottom: '20px' }}>
                    No Dependencies on {label_use_case} {label_client} {label_task}s
                  </Typography>

                  <NoRecordsIcon style={{ width: '150px', height: '150px' }} />
                </Stack>
              )}
            </>
          </>
        )}
        <DeleteModal
          isOpen={openDeleteModal}
          setIsOpen={setOpenDeleteModal}
          message={'Are you sure you want to delete this dependency '}
          onDelete={() => {
            deleteSelectedDependency(toBedeletedDependencyPayload, toBeDeletedGetDependencyPayload);
          }}
        />
      </>
    );
  };
  return <>{renderContent()}</>;
};

export default AddedDependencies;
