// this file handles which field is aggregatable or not
// true == aggregatable
// false = not aggregatable

export const clientEntityStandardFieldsAggregatable = {
  name: false,
  segment: false,
  industry: false,
  status: false,
  salesOwner: false,
  accountOwner: false,
  accountWorth: true,
  stage: false,
  createdOn: false,
  renewalDate: false,
  renewalDuration: false,
  lastStatusReportSentTime: false,
  comment: false,
};

export const projectEntityStandardFieldsAggregatable = {
  name: false,
  progress: false,
  description: false,
  fee: true,
  arr: true,
  reference: false,
  value: true,
  neededStatus: false,
  status: false,
  expansionValue: true,
  priority: false,
  plannedStartDate: false,
  startDate: false,
  etaForDelivery: false,
  requiredByDate: false,
  deliveryDate: false,
  createdOn: false,
  stage: false,
  deliveryManager: false,
  comment: false,
  implementationFeeBilled: true,
  implementationFeePending: true,
};

export const usecaseEntityStandardFieldsAggregatable = {
  name: false,
  progress: false,
  description: false,
  fee: true,
  reference: false,
  neededStatus: false,
  status: false,
  expansionValue: true,
  requiredByDate: false,
  priority: false,
  startDate: false,
  plannedStartDate: false,
  deliveryDate: false,
  createdOn: false,
  stage: false,
  deliveryManager: false,
  comment: false,
  etaForDelivery: false,
};

export const taskEntityStandardFieldsAggregatable = {
  name: false,
  owner: false,
  type: true, // 0 = client task; 1= team task
  priority: false,
  description: false,
  stage: false,
  status: false,
  duedate: false,
  closedDate: false,
  startDate: false,
  plannedStartDate: false,
  createdOn: false,
  effortEstimate: true,
  totalTimeSpent: true,
  totalTimeRemaining: true,
  clientCheck: true,
  clientInitiated: true,
  milestoneTask: true,
  csatIndicator: true,
  lastModifiedBy: false,
  jiraIndicator: true,
  implementationFeePercentage: true,
};
