import { useAppDispatch, useAppSelector } from 'store/hooks';
import { GanttStatic } from 'dhtmlx-gantt';

import {
  addClientTaskAuditTrail,
  addProjectClientTaskAuditTrial,
  addProjectTaskAuditTrial,
  addTaskAuditTrail,
  addTaskDependency,
  deleteUsecaseTaskDependency,
  getTaskDependency,
  updateTask,
} from 'store/modules/Task/slice';
import { getProjectInformationWithCaseTaskByUid } from 'store/modules/Project/slice';
import { selectedProjectInformationForGantt } from 'store/modules/Project/selector';
import {
  transformToAPITaskFormat,
  transformToGanttMileStoneTaskFormat,
  transformToGanttTaskFormat,
} from './transformers';
import { selectCustomerDetail } from 'store/modules/Common/selector';
import { dayNameList, dayNameMap } from './config';
import { useParams } from 'react-router-dom';
import { getLoggedInUserUid } from 'utils/getUid';
import { format } from 'date-fns';

export const useAPIForGantt = () => {
  const projectForGantt = useAppSelector(selectedProjectInformationForGantt);
  const customerDetail = useAppSelector(selectCustomerDetail);
  const allTasksFromAPI = projectForGantt?.all_task ?? [];
  const dispatch = useAppDispatch();
  const params = useParams();

  // const getCriticalPath = async (project: any) => {
  //   console.log(project);
  //   project = JSON.parse(JSON.stringify(project));

  //   const pay = {
  //     project_uid: project.project_uid,
  //     type: 'PROJECT',
  //   };
  //   const criticalPath: any = await dispatch(findCriticalPath(pay)).unwrap();
  //   console.log(criticalPath);
  //   for (let task = 0; task < project.all_task.length; task++) {
  //     for (let i = 0; i < criticalPath.result.length; i++) {
  //       for (let j = 0; j < criticalPath.result[i].length; j++) {
  //         if (criticalPath.result[i][j].task_uid == project.all_task[task].task_uid) {
  //           // isThisTaskInCriticalPath = true;
  //           project.all_task[task]['is_critical'] = true;
  //         }
  //       }
  //     }
  //   }
  //   console.log(project);
  //   projectForGantt = project;
  // };

  // getCriticalPath(projectForGantt);

  function updateTaskInGantt(gantt: GanttStatic, id: string) {
    const updatedTaskFromGantt = gantt.getTask(id);
    const existingTaskFromAPI = allTasksFromAPI.filter((item: any) => item.task_uid === id)?.[0];
    const finalUpdatedTask = {
      ...existingTaskFromAPI,
      ...transformToAPITaskFormat(updatedTaskFromGantt),
    };

    if (finalUpdatedTask.entity === 'task') {
      dispatch(updateTask(finalUpdatedTask))
        .unwrap()
        .then((res) => {
          const updatedTaskFromAPI = { ...res[0] };
          const updatedTaskData =
            finalUpdatedTask.type === 'milestone'
              ? transformToGanttMileStoneTaskFormat(updatedTaskFromAPI)
              : transformToGanttTaskFormat(updatedTaskFromAPI);
          gantt.updateTask(id, updatedTaskData);

          const detail = {
            project_uid: updatedTaskData.project_uid,
          };
          dispatch(getProjectInformationWithCaseTaskByUid(detail));
        })
        .catch((err) => {
          console.log({ err });
        });
    }
  }
  // function checkBeforeTheLinkPresentOrCyclic(id: string, link: any) {
  //   const linkPresent = projectForGantt?.dependency?.filter((item: any) => item.task_parent_uid == link.target);
  //   if (linkPresent?.length !== 0) {
  //     return true;
  //   }
  //   else {
  //     console.log('updateLinkInGantt', id, link);
  //     return true;
  //   }
  // }
  async function deleteLinkInGantt(link: any) {
    const parent_task = allTasksFromAPI.filter((item: any) => item.task_uid == link.source);
    let detail;
    if (parent_task[0].case_uid) {
      detail = {
        dependency_id: link.id,
      };
    } else {
      detail = {
        dependency_id: link.id,
        project_uid: parent_task[0].project_uid,
      };
    }
    await dispatch(deleteUsecaseTaskDependency(detail));
    await dispatch(getProjectInformationWithCaseTaskByUid(detail));
  }
  async function updateLinkInGantt(gantt: GanttStatic, id: string, link: any) {
    const parent_task = allTasksFromAPI.filter((item: any) => item.task_uid === link.source);
    const target_task = allTasksFromAPI.filter((item: any) => item.task_uid === link.target);
    if (parent_task[0].case_uid) {
      const details = {
        case_uid: parent_task[0].case_uid,
        project_uid: parent_task[0].project_uid,
        task_parent_uid: parent_task[0].task_uid,
        task_parent_type: parent_task[0].is_team_task == '1' ? 'company' : 'client',
        task_uid: target_task[0].task_uid,
        task_type: target_task[0].is_team_task == '1' ? 'company' : 'client',
        type: params.caseId ? 'usecase' : 'project',
      };
      const auditTrail = {
        field: 'Dependency',
        task_uid: target_task[0].task_uid,
        user_uid: getLoggedInUserUid(),
        new_value: parent_task[0].task_name,
        old_value: '',
        modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      };
      await dispatch(addTaskDependency(details));
      await dispatchTrailByTaskType(auditTrail, target_task);
      const detail1 = {
        task_uid: target_task[0].task_uid,
        type: params.caseId ? 'usecase' : 'project',
      };
      await dispatch(getTaskDependency(detail1));
      const detail = {
        project_uid: parent_task[0].project_uid,
      };
      await dispatch(getProjectInformationWithCaseTaskByUid(detail));
    } else {
      const details = {
        project_uid: parent_task[0].project_uid,
        task_parent_uid: parent_task[0].task_uid,
        task_parent_type: parent_task[0].is_team_task == '1' || parent_task[0].is_team_task == 1 ? 'company' : 'client',
        task_uid: target_task[0].task_uid,
        task_type: target_task[0].is_team_task == '1' || target_task[0].is_team_task == 1 ? 'company' : 'client',
        type: params.caseId ? 'usecase' : 'project',
      };

      const auditTrail = {
        field: 'Dependency',
        task_uid: target_task[0].task_uid,
        user_uid: getLoggedInUserUid(),
        new_value: parent_task[0].task_name,
        old_value: '',
        modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      };

      await dispatch(addTaskDependency(details));
      await dispatchTrailByTaskType(auditTrail, target_task);
      const detail1 = {
        task_uid: target_task[0].task_uid,
        type: params.caseId ? 'usecase' : 'project',
      };
      await dispatch(getTaskDependency(detail1));
      const detail = {
        project_uid: parent_task[0].project_uid,
      };
      await dispatch(getProjectInformationWithCaseTaskByUid(detail));
    }
  }
  const dispatchTrailByTaskType = async (detail: any, target_task: any) => {
    if (target_task[0].project_uid && target_task[0].case_uid) {
      if (target_task[0].is_team_task == 1) {
        await dispatch(addTaskAuditTrail(detail));
      } else {
        await dispatch(addClientTaskAuditTrail(detail));
      }
    } else {
      if (target_task[0].is_team_task == 1) {
        await dispatch(addProjectTaskAuditTrial(detail));
      } else {
        await dispatch(addProjectClientTaskAuditTrial(detail));
      }
    }
  };
  let nonWorkingDaysInNumber: number[] = [];
  const splittedWorkingDays = customerDetail?.customer_working_days.split(',');
  const targetWorkingDaysInNumber: string[] = dayNameList.filter(
    (dayName: string) => !splittedWorkingDays.includes(dayName)
  );

  targetWorkingDaysInNumber.forEach((dayName: string) => {
    nonWorkingDaysInNumber = [...nonWorkingDaysInNumber, dayNameMap[dayName]];
  });

  const parsedHolidayData = customerDetail?.customer_holidays ? JSON.parse(customerDetail?.customer_holidays) : [];
  const holidays: string[] = parsedHolidayData.map((item: any) => item.date);
  const workingHours = customerDetail?.customer_hours_per_day ? parseInt(customerDetail.customer_hours_per_day, 10) : 0;
  const customerAmberThreshold = customerDetail?.customer_amber_threshold
    ? parseInt(customerDetail.customer_amber_threshold, 10)
    : 0;

  return {
    projectForGantt,
    allTasksFromAPI,
    nonWorkingDaysInNumber,
    workingHours,
    holidays,
    customerAmberThreshold,
    updateTaskInGantt,
    updateLinkInGantt,
    deleteLinkInGantt,
    // checkBeforeTheLinkPresentOrCyclic
  };
};
