import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { customFieldsAPI } from '../../../../api/modules/customFields';
import { clientAPI } from '../../../../api/modules/client';
import { requestState } from '../../types';
// import { string } from 'yup/lib/locale';
import { isNull } from '../../../../utils/dataHelpers';
import { projectAPI } from '../../../../api';
import { current } from '@reduxjs/toolkit';

// fetching custom fields from api
export const fetchCustomFields = createAsyncThunk('getPulldownCustomFieldsByCustomerUid', async () => {
  const { data } = await customFieldsAPI.getCustomFields();
  return data.result ?? [];
});

export const addAccountProfileToDb = createAsyncThunk('addClientInformation', async (clientData: any) => {
  const { data } = await clientAPI.addClientInformation(clientData);
  return data.result;
});

export const addBlankProject = createAsyncThunk('manageClient/addBlankProject', async (associationUid: any) => {
  const { data } = await projectAPI.addBlankProject(associationUid);
  return data.result;
});

export const addProjectInformation = createAsyncThunk(
  'manageClient/addProjectInformation',
  async (serverPayload: Record<string, any>) => {
    const { data } = await projectAPI.addProjectInformation(serverPayload);
    return data;
  }
);

export const createProjectFromProjectTemplate = createAsyncThunk(
  'manageClient/createProjectFromProjectTemplate',
  async (serverPayload: Record<string, any>) => {
    const { data } = await projectAPI.createProjectFromProjectTemplate(serverPayload);
    return data;
  }
);

export const createUsecaseFromUsecaseTemplate = createAsyncThunk(
  'manageClient/createUsecaseFromUsecaseTemplate',
  async (serverPayload: Record<string, any>) => {
    const { data } = await projectAPI.createUsecaseFromUsecaseTemplate(serverPayload);
    return data;
  }
);

export const createUsecaseFromUsecaseTemplateInBulk = createAsyncThunk(
  'manageClient/createUsecaseFromUsecaseTemplateInBulk',
  async (serverPayload: Record<string, any>) => {
    const { data } = await projectAPI.createUsecaseFromUsecaseTemplateInBulk(serverPayload);
    return data;
  }
);

export const updateClientStatusById = async (clientStatus: 0 | 1, clientUid: string) => {
  const { data } = await clientAPI.updateClientStatusById(clientStatus, clientUid);
  return data.message;
  // if message => 'Update successfully' then successfull
};

export const addAccountProfileToDraftInDb = createAsyncThunk(
  'addClientInfoAndSetStatusToDraft',
  async (clientData: addClientInformation_interface) => {
    // adding a new client
    const { data: addClientResponseData } = await clientAPI.addClientInformation(clientData);

    // making the new client status to draft
    if (isNull(addClientResponseData.result)) {
      return { ...addClientResponseData, message: 'Account Name already Exists!' };
    }

    const clientStatusMsg = await updateClientStatusById(0, addClientResponseData.result.client_uid);

    if (clientStatusMsg === 'Update successfully') {
      return { ...addClientResponseData, message: 'Account Onboarded To Draft Successfully!' };
    } else {
      return { ...addClientResponseData, message: 'Unable To Update Onboard Status to Draft!' };
    }
  }
);

// export const addClientAuditTrial = createAsyncThunk('addClientAuditTrial', async (detail: any) => {
//   await clientAPI.addClientAuditTrail(detail);
// });

// interface ClientTrailProps {
//   client_uid: string;
//   field: string;
//   modified_on: string;
//   new_value: string;
//   old_value: string;
//   user_uid: string;
// }

export const addClientAuditTrail = createAsyncThunk('manageclient/addClientAuditTrail', async (detail: any) => {
  const { data } = await clientAPI.addClientAuditTrail(detail);
  console.log('audit trail', data);
  return data.result;
});

interface addClientInformation_interface {
  client_account_owner: string;
  client_account_worth: number | string;
  client_comment: string;
  client_name: string;
  client_readiness_status: string;
  client_sales_owner: string;
  customer_uid: string;
  industry_uid: string;
  sales_user_uid: string;
  segment_uid: string;
  stage_uid: string;
  user_uid: string;
}

export const addClientInformation_slice = createAsyncThunk(
  'onBoardClientToDraft',
  async (addClientInformation_payload: addClientInformation_interface) => {
    const { data } = await clientAPI.addClientInformation(addClientInformation_payload);
    return data;
  }
);

interface updateClientCustomField_interface {
  client_uid: string;
  customer_uid: string;
  field_name: string;
  field_seq_num: string;
  field_type: string;
  field_uid: string;
  field_value: string;
}

export const updateClientCustomField_slice = createAsyncThunk(
  'updateClientCustomField',
  async (updateClientCustomField_payload: updateClientCustomField_interface) => {
    const { data } = await clientAPI.updateClientCustomField(updateClientCustomField_payload);
    return data;
  }
);

// onboarding account form state
interface intialFormValuesStateType {
  onboardFormValues: {
    accountProfile: Record<string, unknown>;
    customFields: Record<string, unknown>;
    addProject: any;
  };
  customFieldsForOnboarding: {
    data: Array<Record<string, unknown>>;
    status: requestState;
    error: null | undefined | string;
  };
  onBoardNewAccountToDraft: {
    data: Record<string, unknown>;
    status: requestState;
    error: null | undefined | string;
  };

  onboardedFormCustomFieldsUpdate: {
    data: Record<string, unknown>;
    status: requestState;
    error: null | undefined | string;
  };

  status: requestState;
  error: null | undefined | string;
  clientUid: null | undefined | string;
  associationUid: null | string | undefined;

  // clientUid: null | undefined | string;
  projectUid: {
    value: string;
    status: requestState;
    error: null | undefined | string;
  };
  addingProjectInformation: {
    data: any;
    status: requestState;
    error: null | undefined | string;
  };
  creatingProjectFromProjectTemplate: {
    data: any;
    status: requestState;
    error: null | undefined | string;
  };
  creatingUsecaseFromUsecaseTemplate: {
    data: any;
    status: requestState;
    error: null | undefined | string;
  };
  creatingUsecaseFromUsecaseTemplateInBulk: {
    data: any;
    status: requestState;
    error: null | undefined | string;
  };
}

interface FieldProps {
  attachment: string;
  customer_uid: string;
  field_id: string;
  field_name: string;
  field_seq_num: string;
  field_type: string;
  field_uid: string;
  mandatory: string;
  valid_values: string;
}

const intialFormValuesState: intialFormValuesStateType = {
  onboardFormValues: {
    accountProfile: {
      accountName: '',
      segment: 'Enterprise',
      industry: '',
      journeyStage: '',
      accountArr: 0,
      salesOwner: {
        id: '15243',
        name: 'Not Assigned',
      },
      csOwner: {
        id: '15243',
        name: 'Not Assigned',
      },
      contactInfo: [
        {
          name: '',
          email: '',
          role: '',
        },
      ],
      whitelistThisDomains: [''],
    },
    customFields: {},
    addProject: {
      importedTemplateProjectUid: '',
      projectTemplate: '',
      projectName: '',
      priority: 'Medium',
      category: 'Land',
      deliveryManager: {
        id: '15243',
        name: 'Not Assigned',
      },
      implementationFeeInUSD: 0,
      projectArrInUSD: 0,
      projectDescription: '',
      comment: '',
      plannedStartDate: new Date(),
      actualStartDate: new Date(),
      dueDate: new Date(),
      etaForDeliveryDate: new Date(),
      projectStage: 'Pre-Sales',
      deliveryDate: new Date(),
      link: '',
      ourTeamProjectMembers: [],
      clientTeamProjectMembers: [],
      teamMembers: [],
      saveProjectStatus: false,
    },
  },
  customFieldsForOnboarding: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  onBoardNewAccountToDraft: {
    data: {},
    status: requestState.idle,
    error: null,
  },
  onboardedFormCustomFieldsUpdate: {
    data: {},
    status: requestState.idle,
    error: null,
  },
  status: requestState.idle,
  error: null,
  clientUid: null,

  // newClientUid: null,
  associationUid: null,

  projectUid: {
    value: '',
    status: requestState.idle,
    error: null,
  },
  addingProjectInformation: {
    data: '',
    status: requestState.idle,
    error: null,
  },
  creatingProjectFromProjectTemplate: {
    data: '',
    status: requestState.idle,
    error: null,
  },
  creatingUsecaseFromUsecaseTemplate: {
    data: '',
    status: requestState.idle,
    error: null,
  },
  creatingUsecaseFromUsecaseTemplateInBulk: {
    data: '',
    status: requestState.idle,
    error: null,
  },
};

// onboard form values and its manipulation
export const onboardFormValuesSlice = createSlice({
  name: 'onboardFormValues',
  initialState: intialFormValuesState,
  reducers: {
    resetOnboardFomvalues: (state: any) => {
      state = intialFormValuesState;
      console.log(state);
    },
    resetNewProjectData: (state: any) => {
      state.creatingProjectFromProjectTemplate = {
        data: '',
        status: requestState.idle,
        error: null,
      };
    },
    activeOnboardProjectButtonStatus: (state: any) => {
      console.log(current(state));
      state.onboardFormValues.addProject.saveProjectStatus = true;
    },
    deActivateOnboardProjectButtonStatus: (state: any) => {
      state.onboardFormValues.addProject.saveProjectStatus = false;
    },

    updateOnboardAccountProfile: (state: any, action: PayloadAction<any>) => {
      state.onboardFormValues.accountProfile = action.payload;
    },
    updateOnboardCustomFields: (state: any, action: PayloadAction<any>) => {
      console.log(action.payload);
      state.onboardFormValues.customFields = { ...action.payload };
    },
    updateOnboardAddProject: (state: any, action: PayloadAction<any>) => {
      state.onboardFormValues.addProject = action.payload;
    },
    resetOnBoardNewAccountToDraftState: (state: any) => {
      state.onBoardNewAccountToDraft = intialFormValuesState.onBoardNewAccountToDraft;
    },
    resetCustomFieldsForOnboarding: (state: intialFormValuesStateType) => {
      state.customFieldsForOnboarding = intialFormValuesState.customFieldsForOnboarding;
      console.log(state);
    },
    resetWholeOnboardingProcess: () => {
      return {
        ...intialFormValuesState,
        onboardFormValues: {
          accountProfile: { ...intialFormValuesState.onboardFormValues.accountProfile },
          customFields: { ...intialFormValuesState.onboardFormValues.customFields },
          addProject: { ...intialFormValuesState.onboardFormValues.addProject },
        },
      };
    },

    addTeamMembersForProject: (state, action: PayloadAction<any>) => {
      state.onboardFormValues.addProject.ourTeamProjectMembers = [...action.payload];
      console.log(action.payload);
    },

    addClientContactsAsMembersForProject: (state, action: PayloadAction<any>) => {
      state.onboardFormValues.addProject.clientTeamProjectMembers = [...action.payload];
      console.log(action.payload);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCustomFields.pending, (state) => {
        state.customFieldsForOnboarding.status = requestState.loading;
      })
      .addCase(fetchCustomFields.fulfilled, (state, action) => {
        if (action.payload) {
          state.customFieldsForOnboarding.status = requestState.success;
          state.customFieldsForOnboarding.data = action.payload;
          // adding intial custom fields
          const newField: Record<string, string> = {};
          action.payload.map((field: FieldProps) => {
            newField[field.field_uid] = '';
          });
          state.onboardFormValues.customFields = newField;
        } else {
          state.onboardFormValues.customFields = {};
        }
      })
      .addCase(fetchCustomFields.rejected, (state, action) => {
        state.customFieldsForOnboarding.status = requestState.failed;
        state.customFieldsForOnboarding.error = action.error.message;
      })
      .addCase(addAccountProfileToDraftInDb.pending, (state) => {
        state.onBoardNewAccountToDraft.status = requestState.loading;
      })
      .addCase(addAccountProfileToDraftInDb.fulfilled, (state, action) => {
        state.onBoardNewAccountToDraft.status = requestState.success;
        console.log(action.payload);
        if (isNull(action.payload.result)) {
          state.onBoardNewAccountToDraft.error = action.payload.message;
          state.onBoardNewAccountToDraft.status = requestState.failed;
        } else {
          state.onBoardNewAccountToDraft.error = null;
          state.clientUid = action.payload.result.client_uid;
          state.associationUid = action.payload.result.association_uid;
        }
      })
      .addCase(addAccountProfileToDraftInDb.rejected, (state, action) => {
        state.onBoardNewAccountToDraft.status = requestState.failed;
        state.onBoardNewAccountToDraft.error = action.error.message;
        console.log(action.error.message);
      })
      .addCase(addClientAuditTrail.pending, (state) => {
        // state.onBoardNewAccountToDraft.status = requestState.loading;
        console.log(state);
      })
      .addCase(addClientAuditTrail.fulfilled, (state, action) => {
        // state.onBoardNewAccountToDraft.status = requestState.success;
        // console.log(action.payload);
        // if (isNull(action.payload.result)) {
        //   state.onBoardNewAccountToDraft.error = action.payload.message;
        //   state.onBoardNewAccountToDraft.status = requestState.failed;
        // } else {
        //   state.onBoardNewAccountToDraft.error = null;
        //   state.clientUid = action.payload.result.client_uid;
        // }
        console.log(state, action);
      })
      .addCase(addClientAuditTrail.rejected, (state, action) => {
        // state.onBoardNewAccountToDraft.status = requestState.failed;
        // state.onBoardNewAccountToDraft.error = action.error.message;
        // console.log(action.error.message);
        console.log(state, action);
      })
      .addCase(updateClientCustomField_slice.pending, (state) => {
        state.onboardedFormCustomFieldsUpdate.status = requestState.loading;
      })
      .addCase(updateClientCustomField_slice.fulfilled, (state, action) => {
        // console.log(action.payload);
        if (isNull(action.payload.result)) {
          state.onboardedFormCustomFieldsUpdate.error = action.payload.message;
          state.onboardedFormCustomFieldsUpdate.status = requestState.failed;
        } else {
          state.onboardedFormCustomFieldsUpdate.error = null;
          state.onboardedFormCustomFieldsUpdate.status = requestState.success;
          state.onboardedFormCustomFieldsUpdate.data = action.payload.result;
        }
      })
      .addCase(updateClientCustomField_slice.rejected, (state, action) => {
        state.onboardedFormCustomFieldsUpdate.status = requestState.failed;
        state.onboardedFormCustomFieldsUpdate.error = action.error.message;
        console.log(action.error.message);
      })
      .addCase(addBlankProject.fulfilled, (state, action) => {
        state.projectUid.status = requestState.success;
        state.projectUid = action.payload;
        console.log(action);
      })
      .addCase(addBlankProject.rejected, (state, action) => {
        state.projectUid.status = requestState.failed;
        state.projectUid.error = action.error.message;
      })
      .addCase(addBlankProject.pending, (state) => {
        state.projectUid.status = requestState.loading;
      })
      .addCase(addProjectInformation.fulfilled, (state, action) => {
        state.addingProjectInformation.status = requestState.success;
        state.addingProjectInformation.data = action.payload.result;
        console.log(action.payload.result);
      })
      .addCase(addProjectInformation.rejected, (state, action) => {
        state.addingProjectInformation.status = requestState.failed;
        state.addingProjectInformation.error = action.error.message;
      })
      .addCase(addProjectInformation.pending, (state) => {
        state.addingProjectInformation.status = requestState.loading;
      })
      .addCase(createProjectFromProjectTemplate.fulfilled, (state, action) => {
        state.creatingProjectFromProjectTemplate.status = requestState.success;
        state.creatingProjectFromProjectTemplate.data = action.payload.result;
        console.log(action.payload.result, 'createProjectFromProjectTemplate');
      })
      .addCase(createProjectFromProjectTemplate.rejected, (state, action) => {
        state.creatingProjectFromProjectTemplate.status = requestState.failed;
        state.creatingProjectFromProjectTemplate.error = action.error.message;
      })
      .addCase(createProjectFromProjectTemplate.pending, (state) => {
        state.creatingProjectFromProjectTemplate.status = requestState.loading;
      })
      .addCase(createUsecaseFromUsecaseTemplate.fulfilled, (state, action) => {
        state.creatingUsecaseFromUsecaseTemplate.status = requestState.success;
        state.creatingUsecaseFromUsecaseTemplate.data = action.payload.result;
        console.log(action.payload.result, 'createUsecaseFromUsecaseTemplate');
      })
      .addCase(createUsecaseFromUsecaseTemplate.rejected, (state, action) => {
        state.creatingUsecaseFromUsecaseTemplate.status = requestState.failed;
        state.creatingUsecaseFromUsecaseTemplate.error = action.error.message;
      })
      .addCase(createUsecaseFromUsecaseTemplate.pending, (state) => {
        state.creatingUsecaseFromUsecaseTemplate.status = requestState.loading;
      })
      .addCase(createUsecaseFromUsecaseTemplateInBulk.fulfilled, (state, action) => {
        state.creatingUsecaseFromUsecaseTemplateInBulk.status = requestState.success;
        state.creatingUsecaseFromUsecaseTemplateInBulk.data = action.payload.result;
        console.log(action.payload.result, 'createUsecaseFromUsecaseTemplateInBulk');
      })
      .addCase(createUsecaseFromUsecaseTemplateInBulk.rejected, (state, action) => {
        state.creatingUsecaseFromUsecaseTemplateInBulk.status = requestState.failed;
        state.creatingUsecaseFromUsecaseTemplateInBulk.error = action.error.message;
      })
      .addCase(createUsecaseFromUsecaseTemplateInBulk.pending, (state) => {
        state.creatingUsecaseFromUsecaseTemplateInBulk.status = requestState.loading;
      });
  },
});

// onboard form reducer export
export const onboardFormValuesReducer = onboardFormValuesSlice.reducer;
export const {
  resetOnboardFomvalues,
  resetOnBoardNewAccountToDraftState,
  updateOnboardAccountProfile,
  updateOnboardCustomFields,
  updateOnboardAddProject,
  resetCustomFieldsForOnboarding,
  resetWholeOnboardingProcess,
  addTeamMembersForProject,
  addClientContactsAsMembersForProject,
  resetNewProjectData,
  deActivateOnboardProjectButtonStatus,
  activeOnboardProjectButtonStatus,
} = onboardFormValuesSlice.actions;
export default onboardFormValuesReducer;
