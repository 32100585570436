import { label_task } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import React from 'react';
import { Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from '../../../store/hooks';
import { selectchangeTaskStatus } from '../../../store/modules/Task/selector';
import { changeTaskStatus } from '../../../store/modules/Task/slice';
import FullScreenMessages from './FullScreenMessages';

const TaskProgressStatusChange = () => {
  // const params = useParams();
  const location: any = useLocation();
  const dispatch = useDispatch();
  const changedStatus = useAppSelector(selectchangeTaskStatus);

  const details = {
    task_uid: location.state.task_uid,
    task_stage:
      location.state.stage == 'Progress'
        ? 'In Progress'
        : location.state.stage == 'Complete'
        ? 'Done'
        : location.state.stage == 'Blocked'
        ? 'Blocked'
        : null,

    last_user_modified: location.state.contact_uid,
  };
  // console.log('params = ', params);
  console.log('details 974 = ', details);

  useEffect(() => {
    console.log('changing the task status');
    dispatch(changeTaskStatus(details));
  }, []);

  const showData = () => {
    if (changedStatus == 'loading') {
      return (
        <Fragment>
          {/* <Typography variant="h5" component="h2">
            Changing the task status
          </Typography> */}
          <FullScreenMessages status={'loading'} message={`Changing the ${label_task} status`} />
        </Fragment>
      );
    } else if (changedStatus == 'success') {
      return (
        <Fragment>
          {/* <Typography variant="h5" component="h2">
            Task Status Changed Sucessfully
          </Typography> */}
          <FullScreenMessages status={'success'} message={`${label_task} Status Changed Sucessfully`} />
        </Fragment>
      );
    } else {
      return (
        <React.Fragment>
          {/* <Typography variant="h5" component="h2">
            Some error occured. Please try again later.
          </Typography> */}
          <FullScreenMessages status={'failed'} message={'Some error occured. Please try again later.'} />
        </React.Fragment>
      );
    }
  };

  return <Fragment>{showData()}</Fragment>;
};

export default TaskProgressStatusChange;
