import { useState } from 'react';
import CognisaasTabs from 'components/shared/CognisaasTabs';
import { Drawer, Stack } from '@mui/material';
import DetailsSection from './DetailsSection';
import CommentSection from './CommentSection';


const UpdateRaidDrawer = (props: any) => {
    const [selectedTab, setSelectedTab] = useState<number>(0);
    const tabs = ['Details','Comments'];
    const raidDetails = props.raidDetails;

    return (<>
    {console.log('Selected Tab',selectedTab)}
        <Drawer
            anchor="right"
            open={props.open}
            onClose={props.onClose}
            sx={{
                '.MuiPaper-root': {
                  width: '650px',
                  overflowX: 'hidden',
                },
            }}
        >
            <h1>Update Raid</h1>
            <Stack>
                <CognisaasTabs
                    tabs={tabs}
                    handleTabSwitch={(e: any, tab: any) => {
                    setSelectedTab(tab);
                    }}  
                />
            </Stack>
            <Stack>
                {selectedTab === 0 && <DetailsSection
                    onClose={props.onClose}
                    raidDetails={raidDetails}
                />}
            {selectedTab === 1 && 
            <Stack style={{ marginTop: '0px', width: '100%', marginRight: '0px' }}>
                <Stack style={{ padding: '20px 20px 5px 20px' }}>
                    <CommentSection
                        raidDetails={raidDetails}
                    />
                </Stack>
            </Stack>
            
            } 
            </Stack>
        </Drawer>
        </>
    );
};

export default UpdateRaidDrawer;
