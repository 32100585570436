import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useDispatch } from 'react-redux';

import CognisaasCheckBox from '../../shared/CognisaasCheckbox/CognisaasCheckbox';
import { selectColumnsForClientListPage } from '../../../store/modules/ui/Filters/selector';
import { useAppSelector } from '../../../store/hooks';
import { removeColumnFromClientListTable, addColumnInClientListTable } from '../../../store/modules/ui/Filters/slice';

const CustomizeColumns = () => {
  const dispatch = useDispatch();
  const clientListColumns = useAppSelector(selectColumnsForClientListPage);
  const updateColumnList = (e: any) => {
    console.log('column list updation', e.target.checked, e.target.value);
    const columnToHide = e.target.value;
    if (e.target.checked) {
      console.log('Add column');
      dispatch(addColumnInClientListTable(columnToHide));
    } else {
      dispatch(removeColumnFromClientListTable(columnToHide));
      console.log('remove column');
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
        <FormLabel component="legend">Show/Hide Columns</FormLabel>
        <FormGroup>
          {Object.keys(clientListColumns).map((c: any, i: number) => {
            return (
              <FormControlLabel
                key={i}
                control={
                  <CognisaasCheckBox
                    checked={clientListColumns[c]}
                    value={c}
                    onCheckBoxClick={(e) => updateColumnList(e)}
                  ></CognisaasCheckBox>
                }
                label={c}
              />
            );
          })}

          {/* <FormControlLabel
            control={<CognisaasCheckBox checked={false} onCheckBoxClick={updateColumnList}></CognisaasCheckBox>}
            label="Check"
          />
          <FormControlLabel
            control={<CognisaasCheckBox checked={false} onCheckBoxClick={updateColumnList}></CognisaasCheckBox>}
            label="Check"
          /> */}
        </FormGroup>
      </FormControl>
    </Box>
  );
};

export default CustomizeColumns;
