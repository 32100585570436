import { Stack, Typography, ButtonGroup, Button } from '@mui/material';
import { useState, useEffect } from 'react';
import CognisaasSelect from '../../../shared/CognisaasSelect/CognisaasSelect';
import {
  addProjectClientTaskAuditTrial,
  addProjectTaskAuditTrial,
  addClientTaskAuditTrail,
  addTaskAuditTrail,
  getAllTasksUnderProject,
  getTaskDependency,
  updateTask,
} from '../../../../store/modules/Task/slice';
import { selectProjectInformation } from '../../../../store/modules/Project/selector';
import { useAppSelector, useAppDispatch } from '../../../../store/hooks';
import {
  selectAllTasksUnderProject,
  selectAllTasksUnderProjectStatus,
  selectTaskDependency,
  selectTaskDependencyStatus,
} from '../../../../store/modules/Task/selector';
import { getProjectInformation, getProjectInformationWithCaseTaskByUid } from '../../../../store/modules/Project/slice';
import AddedDependencies from './AddedDependencies';
import { useParams } from 'react-router-dom';
import { addTaskDependency } from '../../../../store/modules/Task/slice';
import { getLoggedInUserUid } from 'utils/getUid';
import { addDays, differenceInDays, format } from 'date-fns';
import { label_project, label_task, label_use_case } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { requestState } from 'store/modules/types';
import { Box } from '@mui/system';
import CognisaasLoader from 'components/shared/CognisaasLoader/CognisaasLoader';

const Dependency = (props: any) => {
  const [projectLevel, setProjectLevel] = useState<boolean>(true);
  const [projectTeamTasks, setProjectTeamTasks] = useState<any[]>([]);
  const [projectClientTasks, setProjectClientTasks] = useState<any[]>([]);
  const [usecaseTeamTasks, setUsecaseTeamTasks] = useState<any[]>([]);
  const [usecaseClientTasks, setUsecaseClientTasks] = useState<any[]>([]);
  const [showUsecaseSelect, setShowUsecaseSelect] = useState<boolean>(false);
  const [selectedUsecase, setSelectedUsecase] = useState<string>('');
  const [usecaseList, setUsecaseList] = useState<any[]>([]);
  const [projectAllTasks, setProjectAllTasks] = useState<any[]>([]);
  const [usecaseAllTasks, setUsecaseAllTasks] = useState<any[]>([]);
  const [selectedCaseUid, setSelectedCaseUid] = useState<any>('');
  const projectInfo = useAppSelector(selectProjectInformation);
  const [filteredTaskDependencies, setFilteredTaskDependencies] = useState<any[]>([]);
  const taskDependencies = useAppSelector(selectTaskDependency);
  const taskDependenciesStatus = useAppSelector(selectTaskDependencyStatus);

  const allTasksUnderProject = useAppSelector(selectAllTasksUnderProject);
  const allTasksUnderProjectStatus = useAppSelector(selectAllTasksUnderProjectStatus);

  const dispatch = useAppDispatch();
  const params = useParams();

  useEffect(() => {
    const detail = {
      project_uid: params.projectId ? params.projectId : props.taskDetail.project_uid,
      association_uid: params.associationId,
    };
    dispatch(getProjectInformation(detail));

    const detail1 = {
      task_uid: props.taskDetail.task_uid,
      type: params.caseId ? 'usecase' : 'project',
    };

    dispatch(getTaskDependency(detail1));
    const projectUid = params.projectId ? params.projectId : props.taskDetail.project_uid;
    dispatch(getAllTasksUnderProject(projectUid));
    if (props.taskDetail.case_uid) {
      setProjectLevel(false);
      usecaseDependencyData();
    }
  }, []);

  useEffect(() => {
    if (allTasksUnderProject.length > 0) {
      makeDataForDropDown();
    }
  }, [allTasksUnderProject, filteredTaskDependencies]);

  useEffect(() => {
    console.log(
      'task dependencies',
      taskDependencies.filter((task: any) => task.case_uid.length > 0 && task.project_uid)
    );
    if (!projectLevel) {
      setFilteredTaskDependencies(taskDependencies.filter((task: any) => task.case_uid.length > 0 && task.project_uid));
      createUsecaseList();
      setShowUsecaseSelect(true);
      makeDataForDropDown();
    } else {
      setFilteredTaskDependencies(
        taskDependencies.filter((task: any) => task.case_uid.length == 0 && task.project_uid)
      );
    }
  }, [projectLevel, taskDependencies, taskDependenciesStatus]);

  useEffect(() => {
    makeDataForDropDown();
  }, [selectedUsecase, allTasksUnderProjectStatus, taskDependenciesStatus]);

  const createUsecaseList = () => {
    if (projectInfo.usecase.length > 0) {
      const usecaseNames = projectInfo.usecase.map((uc: any) => uc.case_name);
      setUsecaseList([...usecaseNames]);
      makeDataForDropDown();
    }
  };

  const makeDataForDropDown = () => {
    if (projectLevel) {
      let projectTasks: any;
      let dependencyProjectTasks: any;
      if (props.taskDetail.project_uid && !props.taskDetail.case_uid) {
        projectTasks = allTasksUnderProject.filter((task: any) => !task.case_uid && task.project_uid);
        projectTasks = projectTasks.filter((task: any) => task.task_uid != props.taskDetail.task_uid);

        const dependencyTasksUid = filteredTaskDependencies.map((task: any) => task.task_parent_uid);

        dependencyProjectTasks = projectTasks.filter((task: any) => dependencyTasksUid.includes(task.task_uid));
        projectTasks = projectTasks.filter((task: any) => !dependencyTasksUid.includes(task.task_uid));
      } else {
        projectTasks = allTasksUnderProject.filter((task: any) => !task.case_uid && task.project_uid);
        projectTasks = projectTasks.filter((task: any) => task.task_uid != props.taskDetail.task_uid);

        const dependencyTasksUid = filteredTaskDependencies.map((task: any) => task.task_parent_uid);

        dependencyProjectTasks = projectTasks.filter((task: any) => dependencyTasksUid.includes(task.task_uid));
        projectTasks = projectTasks.filter((task: any) => !dependencyTasksUid.includes(task.task_uid));
      }
      const teamTasks = projectTasks.filter((task: any) => task.is_team_task == 1);
      const clientTasks = projectTasks.filter((task: any) => task.is_team_task == 0);

      const teamTaskNames = teamTasks.map((task: any) => task.task_name);
      const clientTaskNames = clientTasks.map((task: any) => task.task_name);

      setProjectAllTasks([...dependencyProjectTasks]);
      setProjectTeamTasks([...teamTaskNames]);
      setProjectClientTasks([...clientTaskNames]);
    } else {
      if (selectedUsecase) {
        let usecaseTasks: any;
        let dependencyUsecaseTasks: any;
        if (props.taskDetail.project_uid && props.taskDetail.case_uid) {
          usecaseTasks = allTasksUnderProject.filter(
            (task: any) => task.case_uid && task.project_uid && task.case_uid == selectedCaseUid
          );
          usecaseTasks = usecaseTasks.filter((task: any) => task.task_uid != props.taskDetail.task_uid);

          const dependencyTasksUid = filteredTaskDependencies.map((task: any) => task.task_parent_uid);

          dependencyUsecaseTasks = usecaseTasks.filter((task: any) => dependencyTasksUid.includes(task.task_uid));
          usecaseTasks = usecaseTasks.filter((task: any) => !dependencyTasksUid.includes(task.task_uid));
        } else {
          usecaseTasks = allTasksUnderProject.filter(
            (task: any) => task.case_uid && task.project_uid && task.case_uid == selectedCaseUid
          );
          usecaseTasks = usecaseTasks.filter((task: any) => task.task_uid != props.taskDetail.task_uid);

          const dependencyTasksUid = filteredTaskDependencies.map((task: any) => task.task_parent_uid);

          dependencyUsecaseTasks = usecaseTasks.filter((task: any) => dependencyTasksUid.includes(task.task_uid));
          usecaseTasks = usecaseTasks.filter((task: any) => !dependencyTasksUid.includes(task.task_uid));
        }
        const teamTasks = usecaseTasks.filter((task: any) => task.is_team_task == 1);
        const clientTasks = usecaseTasks.filter((task: any) => task.is_team_task == 0);

        const teamTaskNames = teamTasks.map((task: any) => task.task_name);
        const clientTaskNames = clientTasks.map((task: any) => task.task_name);

        setUsecaseAllTasks([...dependencyUsecaseTasks]);
        setUsecaseTeamTasks([...teamTaskNames]);
        setUsecaseClientTasks([...clientTaskNames]);
      }
    }
  };

  const dispatchTrailByTaskType = async (detail: any) => {
    if (props.taskDetail.project_uid && props.taskDetail.case_uid) {
      if (props.taskDetail.is_team_task == 1) {
        await dispatch(addTaskAuditTrail(detail));
      } else {
        await dispatch(addClientTaskAuditTrail(detail));
      }
    } else {
      if (props.taskDetail.is_team_task == 1) {
        await dispatch(addProjectTaskAuditTrial(detail));
      } else {
        await dispatch(addProjectClientTaskAuditTrial(detail));
      }
    }
  };

  const addDependency = async (dependencyName: any) => {
    const selectedTask = allTasksUnderProject.filter((task: any) => task.task_name == dependencyName);

    if (selectedTask[0].case_uid) {
      const details = {
        case_uid: selectedTask[0].case_uid,
        project_uid: selectedTask[0].project_uid,
        task_parent_uid: selectedTask[0].task_uid,
        task_parent_type: selectedTask[0].is_team_task == '1' ? 'company' : 'client',
        task_uid: props.taskDetail.task_uid,
        task_type: props.taskDetail.is_team_task == '1' ? 'company' : 'client',
        type: params.caseId ? 'usecase' : 'project',
      };
      const auditTrail = {
        field: 'Dependency',
        task_uid: props.taskDetail.task_uid,
        user_uid: getLoggedInUserUid(),
        new_value: selectedTask[0].task_name,
        old_value: '',
        modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      };
      dispatch(addTaskDependency(details));
      dispatchTrailByTaskType(auditTrail);
      const detail1 = {
        task_uid: props.taskDetail.task_uid,
        type: params.caseId ? 'usecase' : 'project',
      };
      dispatch(getTaskDependency(detail1));
    } else {
      const details = {
        project_uid: selectedTask[0].project_uid,
        task_parent_uid: selectedTask[0].task_uid,
        task_parent_type: selectedTask[0].is_team_task == '1' ? 'company' : 'client',
        task_uid: props.taskDetail.task_uid,
        task_type: props.taskDetail.is_team_task == '1' ? 'company' : 'client',
        type: params.caseId ? 'usecase' : 'project',
      };

      const auditTrail = {
        field: 'Dependency',
        task_uid: props.taskDetail.task_uid,
        user_uid: getLoggedInUserUid(),
        new_value: selectedTask[0].task_name,
        old_value: '',
        modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      };

      dispatch(addTaskDependency(details));
      dispatchTrailByTaskType(auditTrail);
      const detail1 = {
        task_uid: props.taskDetail.task_uid,
        type: params.caseId ? 'usecase' : 'project',
      };
      dispatch(getTaskDependency(detail1));
    }
    const detail1 = {
      task_uid: props.taskDetail.task_uid,
      type: params.caseId ? 'usecase' : 'project',
    };
    const dependencies = await dispatch(getTaskDependency(detail1)).unwrap();
    console.log(dependencies);
    const parents: any[] = [];
    for (let i = 0; i < dependencies.length; i++) {
      for (let j = 0; j < allTasksUnderProject.length; j++) {
        if (dependencies[i].task_parent_uid == allTasksUnderProject[j].task_uid) {
          parents.push(allTasksUnderProject[j]);
        }
      }
    }
    // find which task is finishing last
    let latest: any;
    let latestTask: any;
    for (let i = 0; i < parents.length; i++) {
      if (i == 0) {
        latest = parents[i].task_closed_date;
        latestTask = parents[i];
      } else {
        if (new Date(parents[i].task_closed_date) > new Date(latest)) {
          latestTask = parents[i];
          latest = parents[i].task_closed_date;
        }
      }
    }
    console.log(latest, latestTask);
    // Update current task as per the dependency
    const newStartDate = addDays(new Date(latestTask.task_closed_date.split(' ')[0]), 1);
    const newEndDate = addDays(
      newStartDate,
      differenceInDays(
        new Date(latestTask.task_due_date.split(' ')[0]),
        new Date(latestTask.task_planned_start_date.split(' ')[0])
      )
    );
    console.log(newStartDate, newEndDate);
    await dispatch(
      updateTask({
        ...props.taskDetail,
        phase_uid: latestTask.section_uid,
        section_uid: latestTask.section_uid,
        task_planned_start_date: format(newStartDate, 'yyyy-MM-dd'),
        task_due_date: format(newEndDate, 'yyyy-MM-dd'),
        task_start_date: format(newStartDate, 'yyyy-MM-dd'),
        task_closed_date: format(newEndDate, 'yyyy-MM-dd'),
      })
    );
    const test = {
      project_uid: props.taskDetail.project_uid,
    };

    // await dispatch(fetchClientInformation(client_uid));

    await dispatch(getProjectInformationWithCaseTaskByUid(test));

    const detail = {
      association_uid: params.associationId,
      project_uid: props.taskDetail.project_uid,
    };
    await dispatch(getProjectInformation(detail));
  };

  const selectedUsecaseUid = (name: any) => {
    const uc = projectInfo.usecase.filter((uc: any) => uc.case_name == name);
    setSelectedCaseUid(uc[0].case_uid);
  };

  const usecaseDependencyData = () => {
    // const dependencies = taskDependencies.filter((task: any) => task.case_uid && task.project_uid);
    const usecaseDependencies = allTasksUnderProject.filter((task: any) => task.case_uid && task.project_uid);
    setUsecaseAllTasks([...usecaseDependencies]);
  };

  return (
    <Stack m={2}>
      {allTasksUnderProjectStatus == requestState.loading || taskDependenciesStatus == requestState.loading ? (
        <Box component={'span'} sx={{ height: '50%' }}>
          <CognisaasLoader />
        </Box>
      ) : (
        <>
          <Stack direction="row">
            <ButtonGroup size="large" variant="outlined" aria-label="outlined button group">
              <Button
                onClick={() => {
                  setProjectLevel(true);
                  setShowUsecaseSelect(false);
                }}
                color={projectLevel ? 'primary' : 'secondary'}
                className={`${projectLevel ? 'active-btn' : null}`}
              >
                {label_project} Level
              </Button>
              <Button
                // disabled={pushToJira}
                onClick={() => {
                  setProjectLevel(false);
                  usecaseDependencyData();
                  // makeDataForDropDown();
                  // setUsecaseLists();
                }}
                color={!projectLevel ? 'primary' : 'secondary'}
                className={`${!projectLevel ? 'active-btn' : null}`}
              >
                {label_use_case} Level
              </Button>
            </ButtonGroup>
          </Stack>
          <Stack mt={2} spacing={4} direction="row">
            {showUsecaseSelect && (
              <Stack>
                <Typography variant="subtitle2">{label_use_case}</Typography>
                <CognisaasSelect
                  onChange={(e: any) => {
                    setSelectedUsecase(e.target.value);
                    selectedUsecaseUid(e.target.value);
                    // showTasksOfSelectedUsecase(e.target.value);
                  }}
                  value={selectedUsecase}
                  width="200px"
                  validValues={[...usecaseList]}
                  placeholder="Select Use case"
                />
              </Stack>
            )}

            <Stack>
              <Typography variant="subtitle2">Team {label_task}</Typography>
              <CognisaasSelect
                onChange={(e: any) => addDependency(e.target.value)}
                value=""
                width="200px"
                validValues={projectLevel ? [...projectTeamTasks] : !projectLevel ? [...usecaseTeamTasks] : null}
                placeholder="Select team tasks"
              />
            </Stack>
            <Stack>
              <Typography variant="subtitle2">Account team {label_task}</Typography>
              <CognisaasSelect
                onChange={(e: any) => addDependency(e.target.value)}
                value=""
                width="200px"
                validValues={projectLevel ? [...projectClientTasks] : !projectLevel ? [...usecaseClientTasks] : null}
                placeholder="Select team tasks"
              />
            </Stack>
          </Stack>
          <Stack my={3}>
            <AddedDependencies
              projectLevel={projectLevel}
              addedDependencies={filteredTaskDependencies}
              allTasks={projectLevel ? projectAllTasks : usecaseAllTasks}
              userPermissions={props.userPermissions}
            ></AddedDependencies>
          </Stack>
        </>
      )}
    </Stack>
  );
};

export default Dependency;
