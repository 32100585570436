// require('dotenv').config();
import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import PrivateRoute from './router/PrivateRoute';
import PublicRoute from './router/PublcRoute';
import Login from './components/Login/Login';
import Dashboard from './components/Dashboard/Dashboard';
import ManageClient from './components/ManageClient/ManageClient';
import Reports from './components/Reports/Reports';
import Tasks from './components/Reports/Tasks/Tasks';
import RevenueReport from './components/Reports/RevenueReports/RevenueReports';
import ClientContactsReport from 'components/Reports/ClientContacts/ClientContactsReport';
import Usecases from './components/Reports/Usecases/Usecases';
import Resources from './components/Reports/Resources/Resources';
import MainLayout from './layout/MainLayout';
// import Login_v1 from './components/Login/Login_v1';
import ClientModule from './components/ClientModule/ClientDashboard';
import ClientStatusReport from './components/ClientModule/ClientStatusReport/ClientStatusReport';
import ProjectModule from './components/ProjectModule/ProjectModule';
import CustomizedTreeView from './components/shared/DocumentExplorer/DocumentExplorer';
import SampleExcel from 'components/Templates/TemplatesIndex/UI/SampleExcel';

// project routes
import Projects from './components/Reports/Projects/Projects';
import ProjectDashboard from './components/ProjectModule/ProjectDashboard/ProjectDashboard';
import AddNewProject from './components/ProjectModule/AddNewProject/AddNewProject';
import AddNewUsecase from './components/ProjectModule/ProjectDashboard/UsecaseModule/AddNewUsecase/AddNewUsecase';
import UsecaseDashboard from './components/ProjectModule/ProjectDashboard/UsecaseModule/UsecaseDashBoard/UsecaseDashboard';

import './App.css';
import Signup from './components/Signup/Signup';
import OnboarNewAccount from './components/ManageClient/OnboardNewAccount/OnboardNewAccountIndex';
import ProductRequirementIndex from './components/ProductRequirement/Index';
import './App.css';
import CognisaasDatePicker from './components/shared/CognisaasDatePicker/CognisaasDatePicker';
import CreatePassword from './components/Signup/CreatePassword';
import CompleteAccount from './components/Signup/CompleteAccount';
import ForgotPassword from './components/Login/ForgotPassword';
import Settings from './components/Settings/Settings';
import Templates from './components/Templates/TemplatesIndex';

//customer portal
import CustomerDashboard from './CustomerPortal/components/dashboard/CustomerDashboard';
import ClientSelectionOnLanding from './CustomerPortal/components/Landing/ClientSelectionOnLanding';
import CustomerUseCaseDashboard from './CustomerPortal/components/project/dashboard/CustomerUseCaseDashboard';
import CustomerProject from './CustomerPortal/components/project/CustomerProject';
import CustomerPortal from './CustomerPortal/CustomerPortal';
import TaskProgressStatusChange from './CustomerPortal/components/EmailLinkEndPoint/TaskProgressStatusChange';
import GenerateNewMagicLink from './CustomerPortal/components/GenerateNewMagicLink';
import ActionableInsights from './components/ActionableInsights/ActionableInsights';
// import { CUSTOMER_PORTAL } from './utils/constants';
import CustomerProjectDashboard from './CustomerPortal/components/project/dashboard/CustomerProjectDashboard';
import Home from './components/Home/Home';
import AddProjectTemplateIndex from 'components/Templates/TemplatesIndex/UI/Project/AddProjectTemplate/AddProjectTemplateIndex';
// import NewProjectTemplate from './components/Templates/NewProjectTemplate';
import UserManagement from './components/RBAC/index';
import AddUser from './components/RBAC/User/AddUser';
import AddRoles from './components/RBAC/Roles/AddRoles';
import AddPermission from './components/RBAC/CustomPermissions/AddPermission';
import AddPolicy from './components/RBAC/CustomPermissions/AddPolicy';
// import CreateReport from 'components/Reports/CustomReports/CreateReport';
// import AggregatedCustomReports from 'components/Reports/AggregatedCustomReports/View/AggregatedCustomReports';
import Report from 'components/Reports/AggregatedCustomReports/View/Report';

import AddUsecaseTemplateIndex from 'components/Templates/TemplatesIndex/UI/Usecase/AddUsecaseTemplate/AddUsecaseTemplateIndex';
// import NewUsecaseTemplate from './components/Templates/NewUsecaseTemplate';
// import UpdateProjectTemplate from './components/Templates/UpdateProjectTemplate';
import UpdateProjectTemplateIndex from 'components/Templates/TemplatesIndex/UI/Project/UpdateProjectTemplate/UpdateProjectTemplateIndex';
import UpdateUsecaseTemplateIndex from 'components/Templates/TemplatesIndex/UI/Usecase/UpdateusecaseTemplate/UpdateUsecaseTemplateIndex';
// import UpdateUsecaseTemplate from './components/Templates/UpdateUsecaseTemplate';
import { LicenseInfo } from '@mui/x-license-pro';
import { ResourceManagement } from './components/ResourceManagement';
import Performance from './components/Reports/Performance/Performance';
import Phases from './components/Reports/Phases';
import DemoIndex from './components/shared/Charts/DemoIndex';

// admin panel
import AdminPanel from './components/AdminPanel/index';
import AdminLogin from './components/AdminPanel/AdminLogin';
import OTPScreen from './components/AdminPanel/OTPScreen';
import usePageAccess from 'components/shared/Hooks/usePageAccess';
import ErrorPage from './components/shared/Error/Error';
import CsatRating from './CustomerPortal/components/Csat/CsatRating';
import { isRbacEnabled, isSuperUser } from './utils/getUid';
import TaskProgressChange from './components/EmailLinkEndPoint/TaskProgressChange';

import TagManager from 'react-gtm-module';
import SettingsLayout from './components/Settings/SettingsLayout/SettingsLayout';
// import Profile from 'components/Settings/Profile/Profile';
import ProfileV2 from 'components/Settings/Profile/ProfileV2';
import Preferences from 'components/Settings/Preferences/Preferences';
import Configuration from 'components/Settings/Configuration/Configuration';
import Fields from 'components/Settings/Fields/Fields';
import Notification from 'components/Settings/Notifications/Notification';
import TimeTracking from 'components/Settings/Time Tracking/TimeTracking';
import Integration from 'components/Settings/Integration/Integration';
import Jira from 'components/Settings/Integration/Jira';
import Cookies from 'universal-cookie';
import EmailConfiguration from 'components/Settings/EmailConfigurations/EmailConfiguration';
import Users from 'components/Settings/Users/Users';
import { Navigate } from 'react-router-dom';
import AccessManagement from 'components/Settings/AccessManagement/AccessManagement';
// import CreateAggregatedCustomReports from 'components/Reports/AggregatedCustomReports/Create/CreateAggregatedCustomReports';
import TaskDashboard from 'components/TaskDashboard/Index';
import CreateCustomReportWrapper from 'components/Reports/Shared/CreateCustomReportWrapper';
import Pipedrive from 'components/Settings/Integration/CRM/Pipedrive';

LicenseInfo.setLicenseKey(
  '4bfb14fc4c3e7a47d629798ae5bf26bcTz00NjM0OSxFPTE2ODc5MjM3MzY3MjAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y'
);
// import CognisaasCalenderNew from './components/shared/CognisaasCalenderNew/CognisaasCalenderNew';

// import { CUSTOMER_PORTAL } from './utils/constants';

const App: React.FC = () => {
  const userHasAccessTo = usePageAccess();
  const cookies = new Cookies();
  useEffect(() => {
    const temp = cookies.getAll();
    const cogni_user_uid = temp['cogni_user_uid'];
    const customerCompanyName = temp['customerCompanyName'];
    const user_name = temp['user_name'];
    localStorage.setItem('cogni_user_uid', cogni_user_uid);
    localStorage.setItem('customerCompanyName', customerCompanyName);
    localStorage.setItem('user_name', user_name);
    const tagManagerArgs = {
      gtmId: 'GTM-THF5N89',
    };
    TagManager.initialize(tagManagerArgs);
  }, []);

  return (
    <Routes>
      <Route
        path="/login"
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      />
      <Route
        path="/reset-password/*"
        element={
          <PublicRoute>
            <ForgotPassword />
          </PublicRoute>
        }
      />
      <Route
        path="/signup"
        element={
          <PublicRoute>
            <Signup />
          </PublicRoute>
        }
      />
      <Route
        path="/signup/user"
        element={
          <PublicRoute>
            <Signup />
          </PublicRoute>
        }
      />
      <Route
        path="/create-password/*"
        element={
          <PublicRoute>
            <CreatePassword />
          </PublicRoute>
        }
      />
      <Route
        path="/complete-account/*"
        element={
          <PublicRoute>
            <CompleteAccount />
          </PublicRoute>
        }
      />
      <Route
        path="/signup/email/:mailId"
        element={
          <PublicRoute>
            <Signup />
          </PublicRoute>
        }
      />
      <Route
        path="/signup"
        element={
          <PublicRoute>
            <Signup />
          </PublicRoute>
        }
      />
      <Route
        path="/plan/sample-excel-view"
        element={
          <PrivateRoute>
            <SampleExcel />
          </PrivateRoute>
        }
      />
      <Route
        path="/projects"
        element={
          <PrivateRoute>
            {isRbacEnabled() ? (
              <>
                {userHasAccessTo.includes('/project') || isSuperUser() ? (
                  <MainLayout>
                    <ProjectModule />
                  </MainLayout>
                ) : (
                  <ErrorPage></ErrorPage>
                )}
              </>
            ) : (
              <MainLayout>
                <ProjectModule />
              </MainLayout>
            )}
          </PrivateRoute>
        }
      />
      <Route
        path="/projects/project/:associationId/:projectId"
        element={
          <PrivateRoute>
            {isRbacEnabled() ? (
              <>
                {userHasAccessTo.includes('/project') || isSuperUser() ? (
                  <MainLayout>
                    <ProjectDashboard />
                  </MainLayout>
                ) : (
                  <ErrorPage></ErrorPage>
                )}
              </>
            ) : (
              <MainLayout>
                <ProjectDashboard />
              </MainLayout>
            )}
          </PrivateRoute>
        }
      />
      <Route
        path="/projects/project/add-usecase/:associationId/:projectId"
        element={
          <PrivateRoute>
            {isRbacEnabled() ? (
              <>
                {userHasAccessTo.includes('/project') || isSuperUser() ? (
                  <MainLayout>
                    <AddNewUsecase />
                  </MainLayout>
                ) : (
                  <ErrorPage></ErrorPage>
                )}
              </>
            ) : (
              <MainLayout>
                <AddNewUsecase />
              </MainLayout>
            )}
          </PrivateRoute>
        }
      />
      <Route
        path="/projects/project/:associationId/:projectId/:caseId"
        element={
          <PrivateRoute>
            {isRbacEnabled() ? (
              <>
                {userHasAccessTo.includes('/project') || isSuperUser() ? (
                  <MainLayout>
                    <UsecaseDashboard />
                  </MainLayout>
                ) : (
                  <ErrorPage></ErrorPage>
                )}
              </>
            ) : (
              <MainLayout>
                <UsecaseDashboard />
              </MainLayout>
            )}
          </PrivateRoute>
        }
      />
      <Route
        path="/projects/add-project"
        element={
          <PrivateRoute>
            {isRbacEnabled() ? (
              <>
                {userHasAccessTo.includes('/project') || isSuperUser() ? (
                  <MainLayout>
                    <AddNewProject />
                  </MainLayout>
                ) : (
                  <ErrorPage></ErrorPage>
                )}
              </>
            ) : (
              <MainLayout>
                <AddNewProject />
              </MainLayout>
            )}
          </PrivateRoute>
        }
      />
      {/* add new usecase */}
      <Route
        path="/add-usecase"
        element={
          <PrivateRoute>
            {isRbacEnabled() ? (
              <>
                {userHasAccessTo.includes('/project') || isSuperUser() ? (
                  <MainLayout>
                    <AddNewUsecase />
                  </MainLayout>
                ) : (
                  <ErrorPage></ErrorPage>
                )}
              </>
            ) : (
              <MainLayout>
                <AddNewUsecase />
              </MainLayout>
            )}
          </PrivateRoute>
        }
      />

      <Route
        path="/graphs"
        element={
          <PrivateRoute>
            {isRbacEnabled() ? (
              <>
                {userHasAccessTo.includes('reports') || isSuperUser() ? (
                  <MainLayout>
                    <DemoIndex />
                  </MainLayout>
                ) : (
                  <ErrorPage></ErrorPage>
                )}
              </>
            ) : (
              <MainLayout>
                <DemoIndex />
              </MainLayout>
            )}
          </PrivateRoute>
        }
      />
      <Route
        path="/projects/project/:associationId/:projectId/:caseId"
        element={
          <PrivateRoute>
            {isRbacEnabled() ? (
              <>
                {userHasAccessTo.includes('/project') || isSuperUser() ? (
                  <MainLayout>
                    <UsecaseDashboard />
                  </MainLayout>
                ) : (
                  <ErrorPage></ErrorPage>
                )}
              </>
            ) : (
              <MainLayout>
                <UsecaseDashboard />
              </MainLayout>
            )}
          </PrivateRoute>
        }
      />
      <Route
        path="/projects/add-project"
        element={
          <PrivateRoute>
            {isRbacEnabled() ? (
              <>
                {userHasAccessTo.includes('/project') || isSuperUser() ? (
                  <MainLayout>
                    <AddNewProject />
                  </MainLayout>
                ) : (
                  <ErrorPage></ErrorPage>
                )}
              </>
            ) : (
              <MainLayout>
                <AddNewProject />
              </MainLayout>
            )}
          </PrivateRoute>
        }
      />
      <Route
        path="/resource-management"
        element={
          <PrivateRoute>
            {isRbacEnabled() ? (
              <>
                {userHasAccessTo.includes('/resource-management') || isSuperUser() ? (
                  <MainLayout>
                    <ResourceManagement />
                  </MainLayout>
                ) : (
                  <ErrorPage></ErrorPage>
                )}
              </>
            ) : (
              <MainLayout>
                <ResourceManagement />
              </MainLayout>
            )}
          </PrivateRoute>
        }
      />
      <Route
        path="/tasks"
        element={
          <PrivateRoute>
            {isRbacEnabled() ? (
              <>
                {userHasAccessTo.includes('/tasks') || isSuperUser() ? (
                  <MainLayout>
                    <TaskDashboard />
                  </MainLayout>
                ) : (
                  <ErrorPage></ErrorPage>
                )}
              </>
            ) : (
              <MainLayout>
                <TaskDashboard />
              </MainLayout>
            )}
          </PrivateRoute>
        }
      />
      <Route
        path="/dashboard"
        element={
          <PrivateRoute>
            {isRbacEnabled() ? (
              <>
                {userHasAccessTo.includes('/dashboard') || isSuperUser() ? (
                  <MainLayout>
                    <Dashboard />
                  </MainLayout>
                ) : (
                  <ErrorPage></ErrorPage>
                )}
              </>
            ) : (
              <MainLayout>
                <Dashboard />
              </MainLayout>
            )}
          </PrivateRoute>
        }
      />
      <Route
        path="/products"
        element={
          <PrivateRoute>
            {isRbacEnabled() ? (
              <>
                {userHasAccessTo.includes('/products') || isSuperUser() ? (
                  <MainLayout>
                    <ProductRequirementIndex />
                  </MainLayout>
                ) : (
                  <ErrorPage></ErrorPage>
                )}
              </>
            ) : (
              <MainLayout>
                <ProductRequirementIndex />
              </MainLayout>
            )}
          </PrivateRoute>
        }
      />
      <Route
        path="/actionable-insights"
        element={
          <PrivateRoute>
            {isRbacEnabled() ? (
              <>
                {userHasAccessTo.includes('/actionable-insights') || isSuperUser() ? (
                  <MainLayout>
                    <ActionableInsights />
                  </MainLayout>
                ) : (
                  <ErrorPage></ErrorPage>
                )}
              </>
            ) : (
              <MainLayout>
                <ActionableInsights />
              </MainLayout>
            )}
          </PrivateRoute>
        }
      />
      {/* <Route
        path="/templates"
        element={
          <PublicRoute>
            <MainLayout>
              <Dashboard />
            </MainLayout>
          </PublicRoute>
        }
      /> */}
      <Route
        path="/settings"
        element={
          <PrivateRoute>
            {isRbacEnabled() ? (
              <>
                {userHasAccessTo.includes('/settings') || isSuperUser() ? (
                  <MainLayout>
                    <Settings />
                  </MainLayout>
                ) : (
                  <ErrorPage></ErrorPage>
                )}
              </>
            ) : (
              <MainLayout>
                <Settings />
              </MainLayout>
            )}
          </PrivateRoute>
        }
      />

      <Route
        path="/settings/profile"
        element={
          <PrivateRoute>
            {isRbacEnabled() ? (
              <>
                {userHasAccessTo.includes('/settings') || isSuperUser() ? (
                  <MainLayout>
                    <SettingsLayout>
                      <ProfileV2 />
                    </SettingsLayout>
                  </MainLayout>
                ) : (
                  <ErrorPage></ErrorPage>
                )}
              </>
            ) : (
              <MainLayout>
                <SettingsLayout>
                  <ProfileV2 />
                </SettingsLayout>
              </MainLayout>
            )}
          </PrivateRoute>
        }
      />

      <Route
        path="/settings/preferences"
        element={
          <PrivateRoute>
            {isSuperUser() ? (
              <MainLayout>
                <SettingsLayout>
                  <Preferences />
                </SettingsLayout>
              </MainLayout>
            ) : (
              <ErrorPage></ErrorPage>
            )}
          </PrivateRoute>
        }
      />

      <Route
        path="/settings/configuration"
        element={
          <PrivateRoute>
            {isSuperUser() ? (
              <MainLayout>
                <SettingsLayout>
                  <Configuration />
                </SettingsLayout>
              </MainLayout>
            ) : (
              <ErrorPage></ErrorPage>
            )}
          </PrivateRoute>
        }
      />

      <Route
        path="/settings/fields"
        element={
          <PrivateRoute>
            {isSuperUser() ? (
              <MainLayout>
                <SettingsLayout>
                  <Fields />
                </SettingsLayout>
              </MainLayout>
            ) : (
              <ErrorPage></ErrorPage>
            )}
          </PrivateRoute>
        }
      />
      <Route
        path="/settings/notifications"
        element={
          <PrivateRoute>
            {isSuperUser() ? (
              <MainLayout>
                <SettingsLayout>
                  <Notification />
                </SettingsLayout>
              </MainLayout>
            ) : (
              <ErrorPage></ErrorPage>
            )}
          </PrivateRoute>
        }
      />

      <Route
        path="/settings/email-configurations"
        element={
          <PrivateRoute>
            {isSuperUser() ? (
              <MainLayout>
                <SettingsLayout>
                  <EmailConfiguration />
                </SettingsLayout>
              </MainLayout>
            ) : (
              <ErrorPage></ErrorPage>
            )}
          </PrivateRoute>
        }
      />

      <Route
        path="/settings/time-tracking"
        element={
          <PrivateRoute>
            {isSuperUser() ? (
              <MainLayout>
                <SettingsLayout>
                  <TimeTracking />
                </SettingsLayout>
              </MainLayout>
            ) : (
              <ErrorPage></ErrorPage>
            )}
          </PrivateRoute>
        }
      />

      <Route
        path="/settings/integrations"
        element={
          <PrivateRoute>
            {isSuperUser() ? (
              <MainLayout>
                <SettingsLayout>
                  <Integration />
                </SettingsLayout>
              </MainLayout>
            ) : (
              <ErrorPage></ErrorPage>
            )}
          </PrivateRoute>
        }
      />

      <Route
        path="/settings/access-management"
        element={
          <PrivateRoute>
            {isSuperUser() ? (
              <MainLayout>
                <SettingsLayout>
                  <AccessManagement />
                </SettingsLayout>
              </MainLayout>
            ) : (
              <ErrorPage></ErrorPage>
            )}
          </PrivateRoute>
        }
      />

      <Route
        path="/settings/integrations/jira"
        element={
          <PrivateRoute>
            {isSuperUser() ? <Navigate to="/settings/integrations/jira/product" /> : <ErrorPage></ErrorPage>}
          </PrivateRoute>
        }
      />

      <Route
        path="/settings/integrations/jira/:type"
        element={
          <PrivateRoute>
            {isSuperUser() ? (
              <MainLayout>
                <SettingsLayout>
                  <Jira />
                </SettingsLayout>
              </MainLayout>
            ) : (
              <ErrorPage></ErrorPage>
            )}
          </PrivateRoute>
        }
      />

      <Route
        path="/settings/CRM/Pipedrive/"
        element={
          <PrivateRoute>
            {isSuperUser() ? (
              <MainLayout>
                <SettingsLayout>
                  <Pipedrive />
                </SettingsLayout>
              </MainLayout>
            ) : (
              <ErrorPage></ErrorPage>
            )}
          </PrivateRoute>
        }
      />

      <Route
        path="/settings/users"
        element={
          <PrivateRoute>
            {isSuperUser() ? (
              <MainLayout>
                <SettingsLayout>
                  <Users />
                </SettingsLayout>
              </MainLayout>
            ) : (
              <ErrorPage></ErrorPage>
            )}
          </PrivateRoute>
        }
      />

      <Route
        path=""
        element={
          <PrivateRoute>
            {isRbacEnabled() ? (
              <>
                {userHasAccessTo.includes('/home') || isSuperUser() ? (
                  <MainLayout>
                    {/* <Dashboard /> */}
                    {/* <CustomizedTreeView /> */}
                    <Home />
                  </MainLayout>
                ) : (
                  <ErrorPage></ErrorPage>
                )}
              </>
            ) : (
              <MainLayout>
                {/* <Dashboard /> */}
                {/* <CustomizedTreeView /> */}
                <Home />
              </MainLayout>
            )}
          </PrivateRoute>
        }
      />
      <Route
        path="/"
        element={
          <MainLayout>
            <CustomizedTreeView />
            {/* <Dashboard /> */}
          </MainLayout>
        }
      />
      <Route
        path="/client-list"
        element={
          <PrivateRoute>
            {isRbacEnabled() ? (
              <>
                {userHasAccessTo.includes('/clients') || isSuperUser() ? (
                  <MainLayout>
                    <ManageClient />
                  </MainLayout>
                ) : (
                  <ErrorPage></ErrorPage>
                )}
              </>
            ) : (
              <MainLayout>
                <ManageClient />
              </MainLayout>
            )}
          </PrivateRoute>
        }
      />
      <Route // for client
        path="/client-list/client/:clientId"
        element={
          <PrivateRoute>
            {isRbacEnabled() ? (
              <>
                {userHasAccessTo.includes('/clients') || isSuperUser() ? (
                  <MainLayout>
                    <ClientModule />
                  </MainLayout>
                ) : (
                  <ErrorPage></ErrorPage>
                )}
              </>
            ) : (
              <MainLayout>
                <ClientModule />
              </MainLayout>
            )}
          </PrivateRoute>
        }
      />
      <Route
        path="/onboard-new-account"
        element={
          <PrivateRoute>
            {isRbacEnabled() ? (
              <>
                {userHasAccessTo.includes('/clients') || isSuperUser() ? (
                  <MainLayout>
                    <OnboarNewAccount />
                  </MainLayout>
                ) : (
                  <ErrorPage></ErrorPage>
                )}
              </>
            ) : (
              <MainLayout>
                <OnboarNewAccount />
              </MainLayout>
            )}
          </PrivateRoute>
        }
      />
      <Route
        path="/date"
        element={
          <PrivateRoute>
            <MainLayout>
              <CognisaasDatePicker minDate={new Date(2022, 4, 3)} maxDate={new Date(2022, 5, 3)} />
            </MainLayout>
          </PrivateRoute>
        }
      />
      <Route
        path="/reports"
        element={
          <PrivateRoute>
            {isRbacEnabled() ? (
              <>
                {userHasAccessTo.includes('/reports') || isSuperUser() ? (
                  <MainLayout>
                    <Reports></Reports>
                  </MainLayout>
                ) : (
                  <ErrorPage></ErrorPage>
                )}
              </>
            ) : (
              <MainLayout>
                <Reports></Reports>
              </MainLayout>
            )}
          </PrivateRoute>
        }
      />
      <Route
        path="/reports/create-report"
        element={
          <PrivateRoute>
            {isRbacEnabled() ? (
              <>
                {userHasAccessTo.includes('/reports') || isSuperUser() ? (
                  <MainLayout>
                    <CreateCustomReportWrapper />
                  </MainLayout>
                ) : (
                  <ErrorPage></ErrorPage>
                )}
              </>
            ) : (
              <MainLayout>
                <CreateCustomReportWrapper />
              </MainLayout>
            )}
          </PrivateRoute>
        }
      />
      {/* <Route
        path="/reports/create-aggregated-custom-report"
        element={
          <PrivateRoute>
            {isRbacEnabled() ? (
              <>
                {userHasAccessTo.includes('/reports') || isSuperUser() ? (
                  <MainLayout>
                    <CreateAggregatedCustomReports></CreateAggregatedCustomReports>
                  </MainLayout>
                ) : (
                  <ErrorPage></ErrorPage>
                )}
              </>
            ) : (
              <MainLayout>
                <CreateAggregatedCustomReports></CreateAggregatedCustomReports>
              </MainLayout>
            )}
          </PrivateRoute>
        }
      /> */}
      <Route
        path="/reports/aggregated-custom-report/:report_uid"
        element={
          <PrivateRoute>
            {isRbacEnabled() ? (
              <>
                {userHasAccessTo.includes('/reports') || isSuperUser() ? (
                  <MainLayout>
                    <Report></Report>
                  </MainLayout>
                ) : (
                  <ErrorPage></ErrorPage>
                )}
              </>
            ) : (
              <MainLayout>
                <Report></Report>
              </MainLayout>
            )}
          </PrivateRoute>
        }
      />

      <Route
        path="/reports/Performance"
        element={
          <PrivateRoute>
            {isRbacEnabled() ? (
              <>
                {userHasAccessTo.includes('/reports') || isSuperUser() ? (
                  <MainLayout>
                    <Performance></Performance>
                  </MainLayout>
                ) : (
                  <ErrorPage></ErrorPage>
                )}
              </>
            ) : (
              <MainLayout>
                <Performance></Performance>
              </MainLayout>
            )}
          </PrivateRoute>
        }
      />
      <Route
        path="/reports/Phases"
        element={
          <PrivateRoute>
            {isRbacEnabled() ? (
              <>
                {userHasAccessTo.includes('/reports') || isSuperUser() ? (
                  <MainLayout>
                    <Phases></Phases>
                  </MainLayout>
                ) : (
                  <ErrorPage></ErrorPage>
                )}
              </>
            ) : (
              <MainLayout>
                <Phases></Phases>
              </MainLayout>
            )}
          </PrivateRoute>
        }
      />
      <Route
        path="/reports/Revenue"
        element={
          <PrivateRoute>
            {isRbacEnabled() ? (
              <>
                {userHasAccessTo.includes('/reports') || isSuperUser() ? (
                  <MainLayout>
                    <RevenueReport />
                  </MainLayout>
                ) : (
                  <ErrorPage></ErrorPage>
                )}
              </>
            ) : (
              <MainLayout>
                <RevenueReport />
              </MainLayout>
            )}
          </PrivateRoute>
        }
      />
      <Route
        path="/reports/Client-Contacts"
        element={
          <PrivateRoute>
            {isRbacEnabled() ? (
              <>
                {userHasAccessTo.includes('/reports') || isSuperUser() ? (
                  <MainLayout>
                    <ClientContactsReport />
                  </MainLayout>
                ) : (
                  <ErrorPage></ErrorPage>
                )}
              </>
            ) : (
              <MainLayout>
                <ClientContactsReport />
              </MainLayout>
            )}
          </PrivateRoute>
        }
      />
      <Route
        path="/reports/Resources"
        element={
          <PrivateRoute>
            {isRbacEnabled() ? (
              <>
                {userHasAccessTo.includes('/reports') || isSuperUser() ? (
                  <MainLayout>
                    <Resources />
                  </MainLayout>
                ) : (
                  <ErrorPage></ErrorPage>
                )}
              </>
            ) : (
              <MainLayout>
                <Resources />
              </MainLayout>
            )}
          </PrivateRoute>
        }
      />
      <Route
        path="/reports/Projects"
        element={
          <PrivateRoute>
            {isRbacEnabled() ? (
              <>
                {userHasAccessTo.includes('/reports') || isSuperUser() ? (
                  <MainLayout>
                    <Projects />
                  </MainLayout>
                ) : (
                  <ErrorPage></ErrorPage>
                )}
              </>
            ) : (
              <MainLayout>
                <Projects />
              </MainLayout>
            )}
          </PrivateRoute>
        }
      />
      <Route
        path="/reports/Usecases"
        element={
          <PrivateRoute>
            {isRbacEnabled() ? (
              <>
                {userHasAccessTo.includes('/reports') || isSuperUser() ? (
                  <MainLayout>
                    <Usecases />
                  </MainLayout>
                ) : (
                  <ErrorPage></ErrorPage>
                )}
              </>
            ) : (
              <MainLayout>
                <Usecases />
              </MainLayout>
            )}
          </PrivateRoute>
        }
      />
      <Route
        path="/reports/Tasks"
        element={
          <PrivateRoute>
            {isRbacEnabled() ? (
              <>
                {userHasAccessTo.includes('/reports') || isSuperUser() ? (
                  <MainLayout>
                    <Tasks />
                  </MainLayout>
                ) : (
                  <ErrorPage></ErrorPage>
                )}
              </>
            ) : (
              <MainLayout>
                <Tasks />
              </MainLayout>
            )}
          </PrivateRoute>
        }
      />
      <Route
        path="/client-status-report/:clientId"
        element={
          <PrivateRoute>
            {isRbacEnabled() ? (
              <>
                {userHasAccessTo.includes('/clients') || isSuperUser() ? (
                  <MainLayout>
                    <ClientStatusReport />
                  </MainLayout>
                ) : (
                  <ErrorPage></ErrorPage>
                )}
              </>
            ) : (
              <MainLayout>
                <ClientStatusReport />
              </MainLayout>
            )}
          </PrivateRoute>
        }
      />
      <Route
        path={'/templates'}
        element={
          <PrivateRoute>
            {isRbacEnabled() ? (
              <>
                {userHasAccessTo.includes('/templates') || isSuperUser() ? (
                  <MainLayout>
                    <Templates />
                  </MainLayout>
                ) : (
                  <ErrorPage></ErrorPage>
                )}
              </>
            ) : (
              <MainLayout>
                <Templates />
              </MainLayout>
            )}
          </PrivateRoute>
        }
      />
      <Route
        path={'/templates/createnewtemplate'}
        element={
          <PrivateRoute>
            {isRbacEnabled() ? (
              <>
                {userHasAccessTo.includes('/templates') || isSuperUser() ? (
                  <MainLayout>
                    <AddProjectTemplateIndex />
                  </MainLayout>
                ) : (
                  <ErrorPage></ErrorPage>
                )}
              </>
            ) : (
              <MainLayout>
                <AddProjectTemplateIndex />
              </MainLayout>
            )}
          </PrivateRoute>
        }
      />
      <Route
        path={'/templates/createnewusecasetemplate'}
        element={
          <PrivateRoute>
            {isRbacEnabled() ? (
              <>
                {userHasAccessTo.includes('/templates') || isSuperUser() ? (
                  <MainLayout>
                    <AddUsecaseTemplateIndex />
                  </MainLayout>
                ) : (
                  <ErrorPage></ErrorPage>
                )}
              </>
            ) : (
              <MainLayout>
                <AddUsecaseTemplateIndex />
              </MainLayout>
            )}
          </PrivateRoute>
        }
      />
      <Route
        path={'/templates/updateprojecttemplate'}
        element={
          <PrivateRoute>
            {isRbacEnabled() ? (
              <>
                {userHasAccessTo.includes('/templates') || isSuperUser() ? (
                  <MainLayout>
                    <UpdateProjectTemplateIndex />
                  </MainLayout>
                ) : (
                  <ErrorPage></ErrorPage>
                )}
              </>
            ) : (
              <MainLayout>
                <UpdateProjectTemplateIndex />
              </MainLayout>
            )}
          </PrivateRoute>
        }
      />
      <Route
        path={'/templates/updateusecasetemplate'}
        element={
          <PrivateRoute>
            {isRbacEnabled() ? (
              <>
                {userHasAccessTo.includes('/templates') || isSuperUser() ? (
                  <MainLayout>
                    <UpdateUsecaseTemplateIndex />
                  </MainLayout>
                ) : (
                  <ErrorPage></ErrorPage>
                )}
              </>
            ) : (
              <MainLayout>
                <UpdateUsecaseTemplateIndex />
              </MainLayout>
            )}
          </PrivateRoute>
        }
      />
      <Route
        path={'/customer-portal/*'}
        element={
          // <PublicRoute>
          <MainLayout>
            <CustomerPortal />
          </MainLayout>
          // </PublicRoute>
        }
      />
      <Route
        path={'/customer-portal/sendResetPasswordLink'}
        element={
          <PublicRoute>
            {/* <MainLayout> */}
            <GenerateNewMagicLink />
            {/* </MainLayout> */}
          </PublicRoute>
        }
      />
      <Route
        path={'/customer-portal/dashboard'}
        element={
          <PrivateRoute>
            <MainLayout>
              <CustomerDashboard />
            </MainLayout>
          </PrivateRoute>
        }
      />
      <Route
        path={'/customer-portal/landing'}
        element={
          <PrivateRoute>
            <MainLayout>
              <ClientSelectionOnLanding />
            </MainLayout>
          </PrivateRoute>
        }
      />
      <Route
        path={'/customer-portal/project'}
        element={
          <PrivateRoute>
            <MainLayout>
              <CustomerProject />
            </MainLayout>
          </PrivateRoute>
        }
      />
      <Route
        path={'/customer-portal/project/dashboard'}
        element={
          <PrivateRoute>
            <MainLayout>
              <CustomerProjectDashboard />
            </MainLayout>
          </PrivateRoute>
        }
      />
      <Route path={'/9kad7Bx73'} element={<AdminLogin />} />
      <Route path={'/9e43cSe40K'} element={<OTPScreen />} />
      <Route path={'/d4rUvIkI8Hd'} element={<AdminPanel />} />
      <Route
        path={'/customer-portal/csat-rating'}
        element={
          <PrivateRoute>
            <MainLayout>
              <CsatRating />
            </MainLayout>
          </PrivateRoute>
        }
      />
      <Route
        path={'/customer-portal/project/usecase/dashboard'}
        element={
          <PrivateRoute>
            <MainLayout>
              <CustomerUseCaseDashboard />
            </MainLayout>
          </PrivateRoute>
        }
      />
      {/* <Route
        path={'/customer-portal/task-progress-change/:task_uid/:contact_uid/:stage'}
        element={
          <PrivateRoute>
            <MainLayout>
              <TaskProgressStatusChange />
            </MainLayout>
          </PrivateRoute>
        }
      /> */}
      <Route
        path={'/customer-portal/task-progress-change'}
        element={
          <PrivateRoute>
            <MainLayout>
              <TaskProgressStatusChange />
            </MainLayout>
          </PrivateRoute>
        }
      />
      <Route
        path="/access-management"
        element={
          <PrivateRoute>
            <MainLayout>
              <UserManagement />
            </MainLayout>
          </PrivateRoute>
        }
      />
      <Route
        path="/access-management/add-user"
        element={
          <PrivateRoute>
            <MainLayout>
              <AddUser />
            </MainLayout>
          </PrivateRoute>
        }
      />
      <Route
        path="/access-management/add-role"
        element={
          <PrivateRoute>
            <MainLayout>
              <AddRoles />
            </MainLayout>
          </PrivateRoute>
        }
      />
      <Route
        path="/access-management/add-condition"
        element={
          <PrivateRoute>
            <MainLayout>
              <AddPermission />
            </MainLayout>
          </PrivateRoute>
        }
      />
      <Route
        path="/access-management/add-policy"
        element={
          <PrivateRoute>
            <MainLayout>
              <AddPolicy />
            </MainLayout>
          </PrivateRoute>
        }
      />
      <Route
        path="/task-progress-change/:taskId/:taskStatus"
        element={
          <PrivateRoute>
            {/* <MainLayout> */}
            <TaskProgressChange />
            {/* </MainLayout> */}
          </PrivateRoute>
        }
      />
      <Route
        path="/access-management"
        element={
          <PrivateRoute>
            <MainLayout>
              <UserManagement />
            </MainLayout>
          </PrivateRoute>
        }
      />
      <Route
        path="/access-management/add-user"
        element={
          <PrivateRoute>
            <MainLayout>
              <AddUser />
            </MainLayout>
          </PrivateRoute>
        }
      />
      <Route
        path="/access-management/add-role"
        element={
          <PrivateRoute>
            <MainLayout>
              <AddRoles />
            </MainLayout>
          </PrivateRoute>
        }
      />
      <Route
        path="/access-management/add-condition"
        element={
          <PrivateRoute>
            <MainLayout>
              <AddPermission />
            </MainLayout>
          </PrivateRoute>
        }
      />
      <Route
        path="/access-management/add-policy"
        element={
          <PrivateRoute>
            <MainLayout>
              <AddPolicy />
            </MainLayout>
          </PrivateRoute>
        }
      />
    </Routes>
  );
};

export default App;
