import { colors, size, font } from './tokens';

/* Below values are scale values and not css properties */
export const resourceManagementTheme = {
  space: Object.values(size),
  colors: {
    ...colors,
  },
  fonts: {
    primary: 'Noto Sans',
  },
  fontSizes: Object.values(size),
  fontWeights: Object.values(font.weight),
  lineHeights: Object.values(size),
  letterSpacings: Object.values(size),
  zIndices: [0, 1, 2, 3],
  shadows: {
    a: `4px 0px 5px -5px ${colors.grey_1}`,
    b: '1px 3px 3px 1px rgba(0, 0, 0, 0.1]',
    c: '0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)',
  },
  borders: [
    0,
    `1px solid ${colors.grey_1}`,
    `1px solid ${colors.grey_2}`,
    `1px solid ${colors.grey_3}`,
    `1px solid ${colors.grey_4}`,
    `1px solid ${colors.grey_5}`,
    `1px solid ${colors.additional_3}`,
    `1px solid ${colors.brag_1}`,
    `1px solid ${colors.brag_2}`,
    `1px solid ${colors.brag_3}`,
    `1px solid ${colors.brag_4}`,
  ],
  radii: Object.values(size),
};
