// TODO: This file name needs to be changes since, both project and uc both uses this (THIS IS A SHARED COMPONENT) -- arif

import { useState, useEffect } from 'react';
import {
  Grid,
  Paper,
  Box,
  Menu,
  MenuItem,
  IconButton,
  Typography,
  Stack,
  Avatar,
  styled,
  Button,
  TextField,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ReactComponent as Info } from '../../../../../../../assets/svgs/info.svg';
import CognisaaasTooltip from 'components/shared/CognisaasTooltip/CognisaasTooltip';
import Switch, { SwitchProps } from '@mui/material/Switch';
import AddPhase from '../../../Shared/AddPhase';
import UpdateTemplateTaskDrawer from '../../../Shared/UpdateTemplateTaskDrawer';
import { label_client, label_task } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import {
  saveTemplateProjectSection,
  getAllSectionByTemplateProjectuid,
  deleteProjectTemplatesectionByUid,
  deleteUsecaseTemplatesectionByUid,
  addTemplateProjectTask,
  addTemplateProjectClientTask,
  addTemplateUsecaseTask,
  addTemplateUsecaseClientTask,
  saveTemplateUsecaseSection,
  getAllSectionByTemplateCaseuid,
  deleteTemplateProjectTaskbyUid,
  deleteTemplateProjectClientTaskbyUid,
  updateTemplateUsecaseClienttaskByuid,
  updateTemplateUsecaseTaskbyuid,
  updateTemplateProjectTaskbyuid,
  updateTemplateProjectClienttaskByuid,
  deleteTemplateUsecasebyuid,
  updateTemplatePhase,
} from 'store/modules/Templates/Templates';
import BasicModal from 'components/shared/CognisaasModal/CognisaasModal';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import {
  selectTemplateSectionSequence,
  selectAllSectionTemplates,
  selectusecaseTemplateSections,
  selectuseCaseTemplateSectionSequenceNo,
} from '../../../../../../../store/modules/Templates/selector';
import { addProhectTemplateTask, addUsecaseTemplateTask } from './AddProjectTemplatePlanHelper';
import { Wrapper } from 'components/Templates/Templates.styles';
import AddProjectTemplateTaskDrawer from '../../../Shared/AddProjectTemplateTaskDrawer';
import { updateProjectTemplateTask, updateUsecaseTemplateTask } from '../helper';
import CognisaasButton from 'components/shared/CognisaasButton/CognisaasButton';
import DeleteModal from 'components/shared/CognisaasModal/DeleteModal';

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 32,
  height: 16,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#2D60F6',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 11,
    height: 11,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const AddProjectTemplatePlanSection = (props: any) => {
  const [addPhaseModal, setAddPhaseModal] = useState<boolean>(false);
  const [tempSectionSequenceNo, setTempSectionSequenceNo] = useState(1);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const AllProjectSectionsApi = useAppSelector(selectAllSectionTemplates);
  const tempSectionSequenceNoApi = useAppSelector(selectTemplateSectionSequence);
  const usecaseTemplateSectionsApi = useAppSelector(selectusecaseTemplateSections);
  const useCaseTemplateSectionSequenceNoApi = useAppSelector(selectuseCaseTemplateSectionSequenceNo);
  const [selectedMenuItemPhaseUid, setSelectedMenuItemPhaseUid] = useState('');
  const [selectedMenuOption, setSelectedMenuOption] = useState('');
  const [currentSectionUid, setCurrentSectionUid] = useState<string | null>(null);
  const [openTaskDrawer, setOpenTaskDrawer] = useState<boolean>(false);
  const [openUpdateTaskDrawer, setOpenUpdateTaskDrawer] = useState<boolean>(false);
  const [updateTaskDetail, setUpdateTaskDetail] = useState<any>(null);
  const [taskSequence, setTaskSequence] = useState<number>(1);
  const [openRenameModal, setOpenRenameModal] = useState<boolean>(false);
  const [phaseName, setPhaseName] = useState<string>('');
  const options = ['Rename Phase', 'Delete Phase'];
  const openOptions = Boolean(anchorEl);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

  const handleCloseOptions = () => {
    setAnchorEl(null);
  };

  const dispatch = useAppDispatch();

  // useEffect(() => {
  //   if (tempSectionSequenceNoApi === tempSectionSequenceNo) {
  //     if (selectedMenuOption === 'Delete Phase') setTempSectionSequenceNo(tempSectionSequenceNo - 1);
  //     else setTempSectionSequenceNo(tempSectionSequenceNo + 1);
  //     if (props.templateProjectUid == '') return;
  //     // dispatch(getAllSectionByTemplateProjectuid(templateProjectUid));
  //   }
  // }, [tempSectionSequenceNoApi, tempSectionSequenceNo]);

  useEffect(() => {
    if (props.type == 'project') {
      if (tempSectionSequenceNoApi === tempSectionSequenceNo) {
        if (selectedMenuOption === 'Delete Phase') setTempSectionSequenceNo(tempSectionSequenceNo - 1);
        else setTempSectionSequenceNo(tempSectionSequenceNo + 1);
        if (props.templateProjectUid == '') return;
        // dispatch(getAllSectionByTemplateProjectuid(templateProjectUid));
      }
    } else {
      if (props.sectionsData && props.sectionsData.length <= 0) setTempSectionSequenceNo(1);
      if (props.sectionsData && props.sectionsData.length > 0) setTempSectionSequenceNo(props.sectionsData.length + 1);
    }
  }, [tempSectionSequenceNoApi, tempSectionSequenceNo, useCaseTemplateSectionSequenceNoApi, props.sectionsData]);

  useEffect(() => {
    if (props.type == 'project') {
      if (AllProjectSectionsApi) {
        AllProjectSectionsApi.map((ele: any) => {
          setTaskSequence(ele.task.length + 1);
          console.log(AllProjectSectionsApi, 'all p s api'), setSelectedMenuOption;
          // setSectionsData(AllProjectSectionsApi);
        });
      }
      // if (AllProjectSectionsApi && AllProjectSectionsApi.length <= 0) setSectionsData([]);
      // console.log(AllProjectSectionsApi, 'test');
    } else {
      if (usecaseTemplateSectionsApi) {
        usecaseTemplateSectionsApi.map((ele: any) => {
          setTaskSequence(ele.task.length + 1);
          // setSectionsData(usecaseTemplateSectionsApi);
          // console.log(usecaseTemplateSectionsApi, 'secd');
        });
      }
      // if (usecaseTemplateSectionsApi && usecaseTemplateSectionsApi.length <= 0) setSectionsData([]);
    }
  }, [AllProjectSectionsApi, usecaseTemplateSectionsApi]);

  // useEffect(() => {
  //   if (selectedMenuOption === 'Delete Phase') {
  //     // const timer = setTimeout(callGetAllSections, 500);
  //     // return () => clearTimeout(timer);
  //   }
  // }, [value]);

  const handleClick = (event: React.MouseEvent<HTMLElement>, template_section_uid: string) => {
    setAnchorEl(event.currentTarget);
    setSelectedMenuItemPhaseUid(template_section_uid);
  };

  const addPhase = async (phaseName: string) => {
    if (props.type == 'usecase') {
      const tempPhaseObj = {
        template_section_detail: phaseName,
        template_case_uid: props.projectTemplateUid,
        template_section_sequence: tempSectionSequenceNo,
      };
      console.log(tempPhaseObj);
      await dispatch(saveTemplateUsecaseSection(tempPhaseObj));
      await dispatch(getAllSectionByTemplateCaseuid(props.projectTemplateUid));
    } else {
      const tempPhaseObj = {
        template_section_detail: phaseName,
        template_project_uid: props.projectTemplateUid,
        template_section_sequence: tempSectionSequenceNo,
      };
      console.log(tempPhaseObj);
      await dispatch(saveTemplateProjectSection(tempPhaseObj));
      await dispatch(getAllSectionByTemplateProjectuid(props.projectTemplateUid));
    }
    // await addPhaseForProjectTemplate(props.templateProjectUid, tempPhaseObj);
  };

  const addTask = async (formValues: any) => {
    if (props.type == 'project') {
      if (formValues.isTeamTask) {
        console.log(formValues);
        const payload = addProhectTemplateTask({
          ...formValues,
          template_project_uid: props.projectTemplateUid,
          task_sequence: taskSequence,
          sectionUid: currentSectionUid,
        });
        console.log(payload);
        await dispatch(addTemplateProjectTask(payload));
      } else {
        console.log(formValues);
        const payload = addProhectTemplateTask({
          ...formValues,
          template_project_uid: props.projectTemplateUid,
          task_sequence: taskSequence,
          sectionUid: currentSectionUid,
        });
        console.log(payload);
        await dispatch(addTemplateProjectClientTask(payload));
      }

      await dispatch(getAllSectionByTemplateProjectuid(props.projectTemplateUid));
    } else {
      if (formValues.isTeamTask) {
        console.log(formValues);
        const payload = addUsecaseTemplateTask({
          ...formValues,
          template_case_uid: props.projectTemplateUid,
          task_sequence: taskSequence,
          sectionUid: currentSectionUid,
        });
        console.log(payload);
        await dispatch(addTemplateUsecaseTask(payload));
      } else {
        console.log(formValues);
        const payload = addUsecaseTemplateTask({
          ...formValues,
          template_case_uid: props.projectTemplateUid,
          task_sequence: taskSequence,
          sectionUid: currentSectionUid,
        });
        console.log(payload);
        await dispatch(addTemplateUsecaseClientTask(payload));
      }

      await dispatch(getAllSectionByTemplateCaseuid(props.projectTemplateUid));
    }
  };

  const deletePhase = async () => {
    if (props.type == 'project') {
      await dispatch(deleteProjectTemplatesectionByUid(selectedMenuItemPhaseUid));
      await dispatch(getAllSectionByTemplateProjectuid(props.projectTemplateUid));
    } else {
      await dispatch(deleteUsecaseTemplatesectionByUid(selectedMenuItemPhaseUid));
      await dispatch(getAllSectionByTemplateCaseuid(props.projectTemplateUid));
    }
    handleCloseOptions();
  };

  const handlePhaseRename = async () => {
    if (props.type === 'project') {
      await dispatch(
        updateTemplatePhase({
          section_uid: selectedMenuItemPhaseUid,
          section_name: phaseName,
          type: props.type,
        })
      );
      await dispatch(getAllSectionByTemplateProjectuid(props.projectTemplateUid));
    } else {
      await dispatch(
        updateTemplatePhase({
          section_uid: selectedMenuItemPhaseUid,
          section_name: phaseName,
          type: props.type,
        })
      );
      await dispatch(getAllSectionByTemplateCaseuid(props.projectTemplateUid));
    }
    setOpenRenameModal(false);
    handleCloseOptions();
  };
  function stringToHslColor(str: string, s: number, l: number) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    const h = hash % 360;
    return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
  }

  function stringAvatar(name: string) {
    return {
      sx: {
        bgcolor: stringToHslColor(name, 30, 65),
      },
      children: (
        <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#000000' }}>
          {name.split(' ')[0][0]}
          {name.indexOf(' ') >= 0 ? name.split(' ')[1][0] : null}
        </Typography>
      ),
    };
  }

  const handleDeleteTask = async (taskUid: string, taskType: string, isProject: boolean, uid: string) => {
    console.log(taskUid, taskType, isProject, uid);
    if (isProject) {
      if (taskType == 'Company task') {
        await dispatch(deleteTemplateProjectTaskbyUid(taskUid));
      }
      if (taskType == 'Client task') {
        await dispatch(deleteTemplateProjectClientTaskbyUid(taskUid));
      }
      await dispatch(getAllSectionByTemplateProjectuid(uid));
      setOpenUpdateTaskDrawer(false);
    } else {
      if (taskType == 'Company task') {
        await dispatch(deleteTemplateUsecasebyuid(taskUid));
      }
      if (taskType == 'Client task') {
        await dispatch(updateTemplateUsecaseClienttaskByuid(taskUid));
      }
      await dispatch(getAllSectionByTemplateCaseuid(uid));
      setOpenUpdateTaskDrawer(false);
    }
  };

  const updateTask = async (formValues: any) => {
    if (props.type == 'project') {
      if (formValues.isTeamTask) {
        console.log(formValues);
        const payload = updateProjectTemplateTask({
          ...formValues,
          template_project_uid: props.projectTemplateUid,
          task_sequence: taskSequence,
          sectionUid: formValues.sectionUid,
        });
        console.log(payload);
        await dispatch(updateTemplateProjectTaskbyuid(payload));
      } else {
        console.log(formValues);
        const payload = updateProjectTemplateTask({
          ...formValues,
          template_project_uid: props.projectTemplateUid,
          task_sequence: taskSequence,
          sectionUid: currentSectionUid,
        });
        console.log(payload);
        await dispatch(updateTemplateProjectClienttaskByuid(payload));
      }

      await dispatch(getAllSectionByTemplateProjectuid(props.projectTemplateUid));
    } else {
      if (formValues.isTeamTask) {
        console.log(formValues);
        const payload = updateUsecaseTemplateTask({
          ...formValues,
          template_case_uid: props.projectTemplateUid,
          task_sequence: taskSequence,
          sectionUid: currentSectionUid,
        });
        console.log(payload);
        await dispatch(updateTemplateUsecaseTaskbyuid(payload));
      } else {
        console.log(formValues);
        const payload = updateUsecaseTemplateTask({
          ...formValues,
          template_case_uid: props.projectTemplateUid,
          task_sequence: taskSequence,
          sectionUid: currentSectionUid,
        });
        console.log(payload);
        await dispatch(updateTemplateUsecaseClienttaskByuid(payload));
      }

      await dispatch(getAllSectionByTemplateCaseuid(props.projectTemplateUid));
    }
  };

  // const handleDeleteTask = () => {
  // console.log('DELETE');
  // if (taskType == 'Team') {
  //   dispatch(deleteTemplateProjectTaskbyUid(tempTaskUid));
  //   setTimeout(callGetAllSections, 300);
  //   setStateDrawerOpen(false);
  // }
  // if (taskType == 'Client') {
  //   dispatch(deleteTemplateProjectClientTaskbyUid(tempTaskUid));
  //   setTimeout(callGetAllSections, 300);
  //   setStateDrawerOpen(false);
  // }
  // };

  console.log(props);
  return (
    <>
      <DeleteModal
        isOpen={openDeleteModal}
        setIsOpen={setOpenDeleteModal}
        message={'Are you sure you want to delete this phase'}
        onDelete={deletePhase}
      />
      <BasicModal
        height={274}
        width={570}
        open={openRenameModal}
        onClose={() => {
          setOpenRenameModal(false);
          handleCloseOptions();
        }}
      >
        <Stack>
          <Stack my={2}>
            <Typography variant="h2">Rename Phase</Typography>
          </Stack>
          <TextField
            autoComplete=""
            autoFocus={true}
            value={phaseName}
            onChange={(e) => setPhaseName(e.target.value)}
            style={{ width: '410px', marginBottom: '15px' }}
            size="small"
            placeholder="Enter Template name"
          />
          <Stack mt={3} spacing={2} justifyContent="flex-end" direction="row">
            <CognisaasButton
              isOutlined={true}
              clickHandler={() => {
                setOpenRenameModal(false);
                handleCloseOptions();
              }}
              label="Cancel"
            />
            <CognisaasButton
              clickHandler={() => handlePhaseRename()}
              isOutlined={false}
              label="Save"
              disabled={phaseName.length === 0}
            />
          </Stack>
        </Stack>
      </BasicModal>
      <Menu anchorEl={anchorEl} open={openOptions} onClose={handleCloseOptions}>
        {options.map((option) => (
          <MenuItem
            key={option}
            onClick={() => {
              if (option === 'Rename Phase') {
                setOpenRenameModal(true);
              } else {
                setOpenDeleteModal(true);
              }
              // handleCloseOptions();
              // setSelectedMenuOption(option);
            }}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
      <Wrapper>
        {props.sectionsData &&
          props.sectionsData.map((ele: any, i: any) => {
            return (
              <Grid item xs={3} key={i}>
                <Paper
                  elevation={0}
                  style={{
                    height: '710px',
                    minWidth: '300px',
                    maxWidth: '300px',
                    overflowY: 'scroll',
                    overflowX: 'hidden',
                    marginRight: '10px',
                    padding: '10px',
                    backgroundColor: '#FFFFFF',
                  }}
                >
                  <Box>
                    <Stack direction="row" justifyContent="space-between">
                      <Typography variant="h2">{ele.template_section_detail}</Typography>
                      <IconButton
                        disableRipple
                        onClick={(event) => {
                          handleClick(event, ele.template_section_uid);
                          setPhaseName(ele.template_section_detail);
                        }}
                        // aria-haspopup="true"
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </Stack>
                  </Box>
                  {ele.task.map((ele1: any, i1: any) => {
                    return (
                      <Paper
                        key={i1}
                        elevation={3}
                        sx={{
                          minHeight: '98px',
                          width: '100%',
                          marginTop: '10px',
                          // boxShadow: '0px 4px 23px rgba(0, 0, 0, 0.20)',
                        }}
                        onClick={() => {
                          setUpdateTaskDetail(ele1);
                          setOpenUpdateTaskDrawer(true);
                        }}
                        // onClick={toggleDrawer('right', true, ele, ele1, true)}
                      >
                        <Box sx={{ padding: '12px' }}>
                          <Typography sx={{ fontSize: '18px', fontWeight: '500' }}>
                            {ele1.template_task_detail}
                          </Typography>
                          <Grid container justifyContent="space-between">
                            <Grid item>
                              {ele1?.template_task_client_check == 0 ? (
                                <Stack direction="row" sx={{ mt: '16px' }}>
                                  <IOSSwitch
                                    sx={{ mt: '4px' }}
                                    // onChange={() => handleChangeShareWithClient()}
                                    onClick={(event) => {
                                      event.preventDefault();
                                    }}
                                    onChange={(e) => {
                                      e.preventDefault();
                                    }}
                                  />
                                  <Typography sx={{ ml: '8px', fontSize: '12px', fontWeight: '400', mt: '3px' }}>
                                    Share
                                  </Typography>
                                  <CognisaaasTooltip
                                    title={<Typography variant="subtitle1">Share with {label_client}</Typography>}
                                  >
                                    <IconButton sx={{ paddingTop: '3px' }}>
                                      <Info />
                                    </IconButton>
                                  </CognisaaasTooltip>
                                </Stack>
                              ) : null}
                              {ele1?.task_type == 'Client task' ? (
                                <Typography sx={{ fontSize: '12px', fontWeight: '400', mt: '19px' }}>
                                  {label_client} {label_task}
                                </Typography>
                              ) : null}
                              {ele1?.template_task_client_check == 1 ? (
                                <Stack direction="row" sx={{ mt: '16px' }}>
                                  <IOSSwitch
                                    defaultChecked
                                    sx={{ mt: '4px' }}
                                    // onChange={() => handleChangeShareWithClient()}
                                    onClick={(event: any) => {
                                      event.preventDefault();
                                    }}
                                    onChange={(e) => {
                                      e.preventDefault();
                                    }}
                                  />
                                  <Typography sx={{ ml: '8px', fontSize: '12px', fontWeight: '400', mt: '3px' }}>
                                    Share
                                  </Typography>
                                  <CognisaaasTooltip
                                    title={<Typography variant="subtitle1">Share with {label_client}</Typography>}
                                  >
                                    <IconButton sx={{ paddingTop: '3px' }}>
                                      <Info />
                                    </IconButton>
                                  </CognisaaasTooltip>
                                </Stack>
                              ) : null}
                            </Grid>
                            <Grid item>
                              {ele1?.task_type !== 'Client task' && (
                                <Avatar
                                  style={{ height: '30px', width: '30px', marginTop: '15px' }}
                                  {...stringAvatar(ele1.template_task_owner)}
                                />
                              )}
                            </Grid>
                          </Grid>
                        </Box>
                      </Paper>
                    );
                  })}
                  <Button
                    variant="outlined"
                    fullWidth
                    sx={{ color: '#2D60F6', borderColor: '#2D60F6', mt: '10px' }}
                    onClick={() => {
                      setOpenTaskDrawer(!openTaskDrawer);
                      setCurrentSectionUid(ele.template_section_uid);
                    }}
                  >
                    Add {label_task}
                  </Button>
                </Paper>
              </Grid>
            );
          })}
        {/* Add Phase */}
        <Grid item xs={3}>
          <Paper
            elevation={0}
            style={{
              height: '710px',
              width: '300px',
              border: '1px dashed #bababa',
              backgroundColor: '#F1F1F1',
              // marginTop: '24px',
            }}
          >
            <Box sx={{ padding: '20px 10px', width: '280px' }}>
              <Button
                variant="outlined"
                fullWidth
                sx={{ color: '#2D60F6', borderColor: '#2D60F6' }}
                onClick={() => setAddPhaseModal(true)}
              >
                Add Phase
              </Button>
            </Box>
          </Paper>
        </Grid>
        {addPhaseModal && (
          <BasicModal height={224} width={570} open={true} onClose={() => setAddPhaseModal(!addPhaseModal)}>
            <AddPhase
              templateUid={props.projectTemplateUid}
              addPhaseHandler={(phaseName: string) => addPhase(phaseName)}
              closeModal={() => setAddPhaseModal(false)}
            />
          </BasicModal>
        )}
      </Wrapper>
      {openTaskDrawer && (
        <AddProjectTemplateTaskDrawer
          sectionUid={currentSectionUid}
          addProjectTask={addTask}
          drawerState={true}
          onClose={() => {
            setOpenTaskDrawer(false);
            setCurrentSectionUid(null);
          }}
        />
      )}
      {openUpdateTaskDrawer && (
        <UpdateTemplateTaskDrawer
          userPermissions={['view', 'edit', 'delete']}
          taskDetail={updateTaskDetail}
          handleDeleteTask={handleDeleteTask}
          // sectionUid={currentSectionUid}
          addProjectTask={updateTask}
          drawerState={true}
          onClose={() => {
            setOpenUpdateTaskDrawer(false);
            setCurrentSectionUid(null);
          }}
        />
      )}
    </>
  );
};

export default AddProjectTemplatePlanSection;
