import { Grid, Stack, Typography } from '@mui/material';
import CognisaasCard from '../../shared/CognisaasCard/CognisaasCard';
import ProjectContent from './ProjectContent';
import { label_project } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { ReactComponent as NoRecords } from '../../../assets/svgs/no_records.svg';
// import { useEffect } from 'react';
// import { useAppDispatch } from 'store/hooks';
// import { fetchClientInformation } from 'store/modules/ManageClient/ClientList/slice';
const ClientProjects = (props: any) => {
  // const dispatch = useAppDispatch();
  // useEffect(() => {
  //   if (props.clientDetailWithProject.length == 0 && props.client.client_status == 1)
  //     dispatch(fetchClientInformation(props.clientUid));
  // }, [props.clientDetailWithProject]);
  const tasksUnderProject = (project: any) => {
    let projectSections: any[] = [];
    let usecaseSections: any = [];
    projectSections = [...project.section];
    console.log(projectSections);

    for (let i = 0; i < project.usecase.length; i++) {
      usecaseSections = [...usecaseSections, ...project['usecase'][i].section];
    }

    const sections = [...projectSections, ...usecaseSections];

    // From sections pull tasks of project and usecases
    let projectTasks: any[] = [];
    for (let i = 0; i < sections.length; i++) {
      projectTasks = [...projectTasks, ...sections[i]['task']];
    }
    return [...projectTasks];
  };

  const renderItem = () => {
    if (props.clientDetailWithProject.length > 0) {
      return (
        <>
          {props.clientDetailWithProject.map((project: any, index: number) => {
            return (
              <Grid key={index} item sm={12} md={12} sx={{ mt: '12px' }}>
                <CognisaasCard
                  key={index}
                  width="100%"
                  height={'268px'}
                  clientStatusColor={project.project_readinessstate_status}
                  customStyle={{
                    background: '#FFFFFF',
                    boxShadow: '0px 4px 26px rgba(0, 0, 0, 0.05)',
                    borderRadius: '4px',
                    border: '1px solid #E5E5E5',
                  }}
                >
                  <ProjectContent
                    client={{ client_uid: props.clientUid, clientName: props.client.client_name }}
                    allTasksOfClient={tasksUnderProject(project)}
                    projectInfo={project}
                    setshowLoaderForCloning={props.setShowClientModule}
                    crmEnabled={project.opportunity_id ? true : false}
                  ></ProjectContent>
                </CognisaasCard>
              </Grid>
            );
          })}
        </>
      );
    }
    return (
      <Stack alignItems="center" justifyContent="center" direction="column" mt={6}>
        <NoRecords style={{ width: '170px', height: '170px' }} />
        <Typography variant="subtitle1">No Records to show</Typography>
      </Stack>
    );
  };
  return <>{renderItem()}</>;
};

export default ClientProjects;
