import { Mention, MentionsInput } from 'react-mentions';
import { useState, useEffect } from 'react';
import defaultStyle from './defaultStyle';
import defaultMentionStyle from './defaultMentionStyle';
import { Stack, Button } from '@mui/material';
import { getAllUsers } from '../../../../store/modules/Common/slice';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { selectCustomerDetail, selectUsersOfCompany } from '../../../../store/modules/Common/selector';
import { getUserDetails_slice } from '../../../../store/modules/Settings/slice';
import { getCustomerUid, getLoggedInUserUid, getUserNameByUserUid } from '../../../../utils/getUid';
import {
  addProjectTaskComment,
  addProjectClientTaskComment,
  addUsecaseTaskComment,
  addUsecaseClientTaskComment,
  getProjectTaskComments,
  getProjectClientTaskComments,
  getUsecaseTaskComments,
  getUsecaseClientTaskComments,
  updateProjectTaskComment,
  updateUsecaseTaskComment,
  addTaskAuditTrail,
  addClientTaskAuditTrail,
  addProjectTaskAuditTrial,
  addProjectClientTaskAuditTrial,
  sendProjectTaskCommentEmail,
  sendUsecaseTaskCommentEmail,
  createTaskCommentToJira,
  updateProjectClientTaskComment,
  updateUsecaseClientTaskComment,
} from '../../../../store/modules/Task/slice';
import { format } from 'date-fns';
import { isUndefined } from 'utils/dataHelpers';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
interface commentProps {
  commentDetail?: any;
  clientDetail: any;
  taskDetail: any;
  type?: 'Save' | 'Edit';
  isCustomerPortal?: boolean;
  customerPortalClientUserName?: string;
  setCommentId?: (value: string) => void;
}

const Comment = (props: commentProps) => {
  const [comment, setComment] = useState<string>(props.commentDetail?.comment || '');
  const type: 'Save' | 'Edit' = props.type === 'Edit' ? 'Edit' : 'Save';
  const [internalComment, setInternalComment] = useState(props.isCustomerPortal==true?false:true);
  const [commentText, setCommentText] = useState<string>('');
  const [users, setUsers] = useState<any[]>([]);
  const [mentions, setMentions] = useState<any[]>([]);
  const dispatch = useAppDispatch();
  const usersOfCompany = useAppSelector(selectUsersOfCompany);
  const customerDetail = useAppSelector(selectCustomerDetail);

  useEffect(() => {
    dispatch(getUserDetails_slice(getLoggedInUserUid()));
  }, []);

  useEffect(() => {
    if (usersOfCompany.length == 0) {
      dispatch(getAllUsers());
    }
  }, []);

  useEffect(() => {
    if (usersOfCompany.length > 0) {
      getUserListArray();
    }
  }, [usersOfCompany]);

  const getUserListArray = () => {
    const list = usersOfCompany.map((user: any) => ({ display: user.user_username, id: `company:${user.user_uid}` }));
    setUsers(list);
  };

  const detailsForSavingComent = () => {
    const detail = {
      comment_uid: props.commentDetail?.comment_uid || '',
      clientId: props.clientDetail.client_uid,
      client_name: props.clientDetail.client_name,
      comment: comment,
      task_name: props.taskDetail.task_name,
      project_uid: props.taskDetail.project_uid,
      case_uid: props.taskDetail.case_uid,
      task_uid: props.taskDetail.task_uid,
      user_username:
        props.isCustomerPortal === true
          ? props.customerPortalClientUserName
          : getUserNameByUserUid(getLoggedInUserUid()),
      user_uid: getLoggedInUserUid(),
      value: commentText,
      report_url: `${location.hostname}/`,
      company_url: '',
      client_commented: props.isCustomerPortal == true ? 1 : 0,
      share_with_client: internalComment == false ? 1 : 0
    };
    const mentioned = [];
    for (let i = 0; i < mentions.length; i++) {
      const mention = {
        display: mentions[i].display,
        id: mentions[i].id.split(':')[1],
        type: mentions[i].id.split(':')[0],
      };
      mentioned.push(mention);
    }

    const commentDetail = {
      opost: detail,
      userlistmentions: [...mentioned],
    };
    return commentDetail;
  };

  const saveComment = async () => {
    const commentDetail = detailsForSavingComent();

    if (props.taskDetail.project_uid && props.taskDetail.case_uid) {
      if (props.taskDetail.is_team_task == 1) {
        await dispatch(addUsecaseTaskComment(commentDetail)).unwrap();
        await dispatch(getUsecaseTaskComments(props.taskDetail.task_uid));
        await dispatch(sendUsecaseTaskCommentEmail(commentDetail));
      } else {
        await dispatch(addUsecaseClientTaskComment(commentDetail));
        await dispatch(getUsecaseClientTaskComments(props.taskDetail.task_uid));
        await dispatch(sendUsecaseTaskCommentEmail(commentDetail));
      }
    } else {
      if (props.taskDetail.is_team_task == 1) {
        await dispatch(addProjectTaskComment(commentDetail));
        await dispatch(getProjectTaskComments(props.taskDetail.task_uid));
        dispatch(sendProjectTaskCommentEmail(commentDetail));
      } else {
        await dispatch(addProjectClientTaskComment(commentDetail));
        await dispatch(getProjectClientTaskComments(props.taskDetail.task_uid));
        dispatch(sendProjectTaskCommentEmail(commentDetail));
      }
    }
    if (isUndefined(props.commentDetail?.comment_uid)) {
      const detail = {
        field: 'Comment',
        task_uid: props.taskDetail.task_uid,
        user_uid: getLoggedInUserUid(),
        new_value: comment,
        old_value: '',
        modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      };
      dispatchTrailByTaskType(detail);
    } else {
      const detail = {
        field: 'Comment',
        task_uid: props.taskDetail.task_uid,
        user_uid: getLoggedInUserUid(),
        new_value: comment,
        old_value: props.commentDetail?.comment,
        modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      };
      await dispatchTrailByTaskType(detail);
    }
    const isJiraEnabled = customerDetail.customer_jira_user.length > 0;
    if (isJiraEnabled) {
      if (props.taskDetail.case_uid) {
        dispatch(
          createTaskCommentToJira({
            customer_uid: getCustomerUid(),
            jira_issue_id: props.taskDetail.jira_issue_id,
            key: btoa(btoa(`${customerDetail.customer_jira_user}:${customerDetail.customer_jira_token}`)),
            taskUid: props.taskDetail.task_uid,
            case_uid: props.taskDetail.case_uid,
          })
        );
      } else {
        dispatch(
          createTaskCommentToJira({
            customer_uid: getCustomerUid(),
            jira_issue_id: props.taskDetail.jira_issue_id,
            key: btoa(btoa(`${customerDetail.customer_jira_user}:${customerDetail.customer_jira_token}`)),
            taskUid: props.taskDetail.task_uid,
          })
        );
      }
    }

    // getting comments
    if (props.taskDetail.project_uid && props.taskDetail.case_uid) {
      if (props.taskDetail.is_team_task == 1) {
        await dispatch(getUsecaseTaskComments(props.taskDetail.task_uid));
        setComment('');
      } else {
        await dispatch(getUsecaseClientTaskComments(props.taskDetail.task_uid));
        setComment('');
      }
    } else {
      if (props.taskDetail.is_team_task == 1) {
        await dispatch(getProjectTaskComments(props.taskDetail.task_uid));
        setComment('');
      } else {
        await dispatch(getProjectClientTaskComments(props.taskDetail.task_uid));
        setComment('');
      }
    }
  };
  const dispatchTrailByTaskType = async (detail: any) => {
    if (props.taskDetail.project_uid && props.taskDetail.case_uid) {
      if (props.taskDetail.is_team_task == 1) {
        await dispatch(addTaskAuditTrail(detail));
      } else {
        await dispatch(addClientTaskAuditTrail(detail));
      }
    } else {
      if (props.taskDetail.is_team_task == 1) {
        await dispatch(addProjectTaskAuditTrial(detail));
      } else {
        await dispatch(addProjectClientTaskAuditTrial(detail));
      }
    }
  };
  const updateComment = async () => {
    const commentDetail = detailsForSavingComent();
    if (props.taskDetail.project_uid && props.taskDetail.case_uid) {
      if (props.taskDetail.is_team_task == 1) {
        await dispatch(updateUsecaseTaskComment(commentDetail));
        await dispatch(getUsecaseTaskComments(props.taskDetail.task_uid));
        setComment('');
      } else {
        await dispatch(updateUsecaseClientTaskComment(commentDetail));
        await dispatch(getUsecaseClientTaskComments(props.taskDetail.task_uid));
        setComment('');
      }
    } else {
      if (props.taskDetail.is_team_task == 1) {
        await dispatch(updateProjectTaskComment(commentDetail));
        await dispatch(getProjectTaskComments(props.taskDetail.task_uid));
        setComment('');
      } else {
        await dispatch(updateProjectClientTaskComment(commentDetail));
        await dispatch(getProjectClientTaskComments(props.taskDetail.task_uid));
        setComment('');
      }
    }
  };

  const handleSaveButton = () => {
    if (type === 'Edit') {
      updateComment();
    } else if (type === 'Save') {
      saveComment();
    }
    if (props.setCommentId) {
      props.setCommentId('');
    }
    setComment('');
  };

  const handleCancelButton = () => {
    if (props.setCommentId) {
      props.setCommentId('');
    }
    setComment('');
  };

  return (
    <Stack sx={{ width: '100%', fontFamily: 'Noto sans' }}>
      <MentionsInput
        style={defaultStyle}
        value={comment}
        placeholder="Type here"
        className="mention-input"
        onChange={(e: any, newValue: any, newPlainTextValue: any, mentions: any) => {
          setCommentText(newPlainTextValue);
          setComment(e.target.value);
          setMentions([...mentions]);
        }}
      >
        <Mention style={defaultMentionStyle} trigger="@" data={[...users]} />
      </MentionsInput>
      <Stack justifyContent={'flex-end'} direction="row">
        <Stack direction="row">
          {/* <CognisaaasTooltip title={
            <Box mb={1} sx={{ height: '12px', width: '185px', padding: '12px' }}>
              <Typography variant="subtitle1">
                {internalComment==true?'This is marked as internal':'This is marked as public'}
              </Typography>
            </Box>
              }
              >
            </CognisaaasTooltip> */}
            {props.isCustomerPortal==true?<></>:<FormControlLabel control={<Checkbox style={{ marginRight:'9px'}} checked={internalComment} onChange={(e) => {setInternalComment(e.target.checked);}} />} label={<Typography style={{fontSize:'14px',marginLeft:'4px'}} variant="h3" color="primary">{'Internal'}</Typography>} />}
          <Button variant="text" onClick={() => handleSaveButton()}>
            Save
          </Button>
          <Button variant="text" onClick={() => handleCancelButton()}>
            Cancel
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Comment;
