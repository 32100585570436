import { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Avatar, Typography, Stack } from '@mui/material';
import formatDistance from 'date-fns/formatDistance';

import CognissasCustomMenu from '../../shared/CognisaasCustomMenu/CognisaasCustomMenu';

import RequirementsCommentTab from './RequirementsCommentTab';
import { getUserLogoLinkByUid } from 'utils/getUid';
import { default as AvatarImage } from '../../shared/Avatar/Avatar';

import {
  deleteRequirementCommentByUid,
  deleteRequirementCommentFromJira,
  getAllRequriementCommentsIncludingJira,
} from '../../../store/modules/ProductRequirements/ProductDetails/slice';
import Cookies from 'universal-cookie';
import { useAppSelector } from 'store/hooks';
import { selectCustomerDetail } from 'store/modules/Common/selector';

const cookies = new Cookies();
interface CommentViewProps {
  commentsData: any;
  taskDetail?: any;
  clientDetail?: any;
  getRequirementMetaData: any;
  requirementData: any;
}

const Wrapper = styled.div`
  width: 100%;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: 40px;
    right: 37px;
    bottom: -6px;
    height: 1px;
    width: 100%;
    background-color: #e5e5e5;
  }
`;

const Frame = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 10px 14px;
  margin-top: 17px;

  &:hover {
    background: #f5f5f7;
  }
`;

const ViewProductComments = (props: CommentViewProps) => {
  const [commentId, setCommentId] = useState<string>('');
  const [editComment, setEditComment] = useState(false);
  const [clickedComment, setClickedComment] = useState<any>({});
  const customerDetail = useAppSelector(selectCustomerDetail);

  const dispatch = useDispatch();

  function stringToHslColor(str: string, s: number, l: number) {
    let hash = 0;
    console.log(str);

    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    const h = hash % 360;
    return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
  }

  function stringAvatar(name: string) {
    console.log(name, commentId, editComment);

    return {
      sx: {
        bgcolor: stringToHslColor(name, 30, 80),
      },
      children: (
        <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#000000' }}>
          {name.split(' ')[0][0]}
          {name.indexOf(' ') >= 0 ? name.split(' ')[1][0] : null}
        </Typography>
      ),
    };
  }

  const generateCommentUi = (comment: string) => {
    const splitForUsers = comment.split('@[');
    return splitForUsers.map((item, index) => {
      if (index === 0) {
        return <span key={index}>{item}</span>;
      } else {
        // index more than 0 something like this expected => vivek](customer:324234) great
        const strContainingUser = item;
        const arrContainingUser = strContainingUser.split('](');
        const username = arrContainingUser[0];
        const restStrArr = arrContainingUser[1].split(')');
        const customerUid = restStrArr[0];
        const restString = [...restStrArr.filter((item, index) => index > 0)].join('');
        return (
          <span key={index}>
            &nbsp;
            <span
              style={{
                backgroundColor: 'rgb(45, 96, 246, 0.1)',
                color: '#2D60F6',
                padding: '2px 10px',
                borderRadius: '33px',
                fontSize: '13px',
                cursor: 'pointer',
              }}
              id={customerUid}
            >
              {username}
            </span>
            {restString}
          </span>
        );
      }
    });
  };

  const handleEdit = (comment: any) => {
    console.log(comment, 'edit button clicked comment');
    setEditComment(true);
    setClickedComment(comment);
    setCommentId(comment.feature_comment_id);
  };

  const handleDelete = async (comment: Record<string, string>) => {
    console.log(comment, props.requirementData, 'com id for del');

    if (props.requirementData.feature_issue_id && props.requirementData.feature_issue_id?.length > 0) {
      const key = btoa(btoa(`${customerDetail.customer_jira_user}:${customerDetail.customer_jira_token}`));
      const payload = {
        comment_uid: comment.feature_comment_uid,
        customer_uid: cookies.get('cogni_customer_uid'),
        jira_comment_id: comment.jira_comment_id,
        jira_issue_id: props.requirementData.feature_issue_id,
        key: key,
      };
      await dispatch(deleteRequirementCommentFromJira(payload));
    }
    await dispatch(deleteRequirementCommentByUid({ comment_uid: comment.feature_comment_uid }));
    if (props.requirementData.feature_issue_id && props.requirementData.feature_issue_id?.length > 0) {
      await dispatch(
        getAllRequriementCommentsIncludingJira({
          feature_uid: props.requirementData.feature_uid,
          jira_issue_id: props.requirementData.feature_issue_id,
        })
      );
    }
    props.getRequirementMetaData(props.requirementData.feature_uid);
  };
  return (
    <>
      {props.commentsData?.map((comment: any, index: number) => {
        console.log(comment, 'map comment');
        return (
          <Wrapper key={index}>
            <Frame>
              <Stack spacing={2} direction={'row'} sx={{ minHeight: '10px', wordBreak: 'break-all', width: '80%' }}>
                <Stack ml={2} direction="row">
                  {getUserLogoLinkByUid(comment.user_uid).length > 0 ? (
                    <AvatarImage src={getUserLogoLinkByUid(comment.user_uid)} alt="user profile" />
                  ) : (
                    <Avatar
                      style={{ height: '40px', width: '40px', marginTop: '3px' }}
                      {...stringAvatar(comment.user_username)}
                    />
                  )}
                </Stack>

                <Stack direction={'column'} sx={{ marginTop: '5px' }} style={{ width: '100%' }}>
                  <Typography variant="subtitle1">{comment.user_username}</Typography>
                  {clickedComment?.feature_comment_uid == comment.feature_comment_uid ? (
                    <RequirementsCommentTab
                      clientDetail={props.clientDetail}
                      setCommentId={setCommentId}
                      commentDetail={clickedComment}
                      type="Edit"
                      data={clickedComment}
                    />
                  ) : (
                    <Stack className="label-text" direction={'row'}>
                      {generateCommentUi(comment.comment)}
                    </Stack>
                  )}
                </Stack>
              </Stack>
              <Stack m={2}>
                <Typography className="label-text" variant="subtitle1" style={{ fontSize: '12px' }}>
                  {formatDistance(new Date(comment.created_on), new Date(), { includeSeconds: true })} ago
                </Typography>
              </Stack>
              <Stack>
                <CognissasCustomMenu
                  list={[
                    {
                      value: 'Edit',
                      handleClick: handleEdit,
                      args: [comment],
                    },
                    {
                      value: 'Delete',
                      handleClick: handleDelete,
                      args: [comment],
                    },
                  ]}
                />

                {/* <IconButton onClick={handleClick}>
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem onClick={(e) => handleEdit(comment)}>
                    <Typography variant="inherit">Edit Comment</Typography>
                  </MenuItem>
                  <MenuItem onClick={(e) => handleDelete(comment.feature_comment_uid)}>
                    <Typography variant="inherit">Delete Comment</Typography>
                  </MenuItem>
                </Menu> */}
              </Stack>
            </Frame>
          </Wrapper>
        );
      })}
    </>
  );
};

export default ViewProductComments;
