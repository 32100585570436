import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { clientAPI } from '../../../../api/modules/client';
import { taskAPI } from '../../../../api/modules/tasks';
import { requestState } from '../../types';
import { reportsTaskTransformer } from './util';

interface TaskStateType {
  clients: Array<any>;
  status: requestState.idle | requestState.loading | requestState.success | requestState.failed;
  error: null | undefined | string;

  teamOpenTasks: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };

  teamClosedTasks: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };

  clientOpenTasks: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };

  clientClosedTasks: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };
  subtasks: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  }
}

const initialState: TaskStateType = {
  clients: [],
  status: requestState.idle,
  error: null,
  teamOpenTasks: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  teamClosedTasks: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  clientOpenTasks: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  clientClosedTasks: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  subtasks: {
    data: [],
    status: requestState.idle,
    error: null,
  }
};

export const fetchAllClients = createAsyncThunk('projects/fetchAllClients', async () => {
  const { data } = await clientAPI.getClientList();
  return data.result;
});

export const fetchTeamOpenTasks = createAsyncThunk('reports/fetchTeamOpenTasks', async () => {
  const OpentasksData = await taskAPI.getTeamOpenTasks();
  const TaskCustomValuesData = await taskAPI.getTaskCustomValuesByCustomer();
  const data = [OpentasksData.data.result ?? [], TaskCustomValuesData.data.result ?? {}];
  return data ?? [];
});
export const fetchTeamClosedTasks = createAsyncThunk('reports/fetchTeamClosedTasks', async () => {
  const closedTasksdata = await taskAPI.getTeamClosedTasks();
  const taskCustomValuesData = await taskAPI.getTaskCustomValuesByCustomer();
  const data = [closedTasksdata.data.result ?? [], taskCustomValuesData.data.result ?? {}];
  return data ?? [];
});
export const fetchClientOpenTasks = createAsyncThunk('reports/fetchClientOpenTasks', async () => {
  const openClientTasksData = await taskAPI.getClientOpenTasks();
  const taskCustomValuesData = await taskAPI.getTaskCustomValuesByCustomer();
  const data = [openClientTasksData.data.result ?? [], taskCustomValuesData.data.result ?? {}];
  return data ?? [];
});
export const fetchClientClosedTasks = createAsyncThunk('reports/fetchClientClosedTasks', async () => {
  const clientTasksClosedData = await taskAPI.getClientClosedTasks();
  const taskCustomValuesData = await taskAPI.getTaskCustomValuesByCustomer();
  const data = [clientTasksClosedData.data.result ?? [], taskCustomValuesData.data.result ?? {}];
  return data ?? [];
});

const clientsSlice = createSlice({
  name: 'openProjects',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchAllClients.pending, (state) => {
        state.status = requestState.loading;
      })
      .addCase(fetchAllClients.fulfilled, (state, action) => {
        state.status = requestState.success;
        state.clients = action.payload;
      })
      .addCase(fetchAllClients.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      })
      // team open tasks
      .addCase(fetchTeamOpenTasks.pending, (state) => {
        state.teamOpenTasks.status = requestState.loading;
      })
      .addCase(fetchTeamOpenTasks.fulfilled, (state, action) => {
        state.teamOpenTasks.status = requestState.success;
        if (action.payload) {
          const detail = {
            tasks: [...action.payload[0]],
            customValues: [...action.payload[1].custom_values],
          };
          const transformTaskresponse = reportsTaskTransformer(detail);
          state.teamOpenTasks.data = [...transformTaskresponse];
          state.subtasks.data = [...action.payload[1].projectSubtasks, ...action.payload[1].usecaseSubtasks];
        } else {
          state.teamOpenTasks.data = [];
          state.subtasks.data = [];
        }
      })
      .addCase(fetchTeamOpenTasks.rejected, (state, action) => {
        state.teamOpenTasks.status = requestState.failed;
        state.teamOpenTasks.error = action.error.message;
      })
      // team closed tasks
      .addCase(fetchTeamClosedTasks.pending, (state) => {
        state.teamClosedTasks.status = requestState.loading;
      })
      .addCase(fetchTeamClosedTasks.fulfilled, (state, action) => {
        state.teamClosedTasks.status = requestState.success;
        // state.teamClosedTasks.data = action.payload;
        if (action.payload) {
          const detail = {
            tasks: [...action.payload[0]],
            customValues: [...action.payload[1].custom_values],
          };
          const transformTaskresponse = reportsTaskTransformer(detail);
          state.teamClosedTasks.data = [...transformTaskresponse];
        } else {
          state.teamClosedTasks.data = [];
        }
      })
      .addCase(fetchTeamClosedTasks.rejected, (state, action) => {
        state.teamClosedTasks.status = requestState.failed;
        state.teamClosedTasks.error = action.error.message;
      })
      // client open tasks
      .addCase(fetchClientOpenTasks.pending, (state) => {
        state.clientOpenTasks.status = requestState.loading;
      })
      .addCase(fetchClientOpenTasks.fulfilled, (state, action) => {
        state.clientOpenTasks.status = requestState.success;
        // state.clientOpenTasks.data = action.payload;
        if (action.payload) {
          const detail = {
            tasks: [...action.payload[0]],
            customValues: [...action.payload[1].custom_values],
          };
          const transformTaskresponse = reportsTaskTransformer(detail);
          state.clientOpenTasks.data = [...transformTaskresponse];
        } else {
          state.clientOpenTasks.data = [];
        }
      })
      .addCase(fetchClientOpenTasks.rejected, (state, action) => {
        state.clientOpenTasks.status = requestState.failed;
        state.clientOpenTasks.error = action.error.message;
      })
      // client closed tasks
      .addCase(fetchClientClosedTasks.pending, (state) => {
        state.clientClosedTasks.status = requestState.loading;
      })
      .addCase(fetchClientClosedTasks.fulfilled, (state, action) => {
        state.clientClosedTasks.status = requestState.success;
        // state.clientClosedTasks.data = action.payload;
        if (action.payload) {
          const detail = {
            tasks: [...action.payload[0]],
            customValues: [...action.payload[1].custom_values],
          };
          const transformTaskresponse = reportsTaskTransformer(detail);
          state.clientClosedTasks.data = [...transformTaskresponse];
        } else {
          state.clientClosedTasks.data = [];
        }
      })
      .addCase(fetchClientClosedTasks.rejected, (state, action) => {
        state.clientClosedTasks.status = requestState.failed;
        state.clientClosedTasks.error = action.error.message;
      });
  },
});

// export const getAllClients = (state: TaskStateType) => state.clients;
const reportsTasksReducer = clientsSlice.reducer;
export default reportsTasksReducer;
