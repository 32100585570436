// TODO: Can be made as reusable with project detail

import { Typography, Stack, IconButton, Paper, Avatar, Divider } from '@mui/material';
import { useState, useEffect } from 'react';
import EditUsecaseDrawer from './EditUsecaseDrawer';
import format from 'date-fns/format';
import { selectProjectInformation } from '../../../../../../store/modules/Project/selector';
import { useAppSelector } from '../../../../../../store/hooks';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getProjectInformation } from '../../../../../../store/modules/Project/slice';
import { formatCurrency, isValidDate } from 'utils/dataHelpers';
import { selectCustomerDetail } from 'store/modules/Common/selector';
import { selectUsecaseInfo } from 'store/modules/Usecase/selector';
import { userPermissionSchema } from 'components/Reports/SideDrawer/types';
import {
  label_delivery_date,
  label_due_date,
  label_use_case,
  label_use_case_delivery_manager,
} from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { ReactComponent as EditPen } from '../../../../../../assets/svgs/edit_pen.svg';
import useCustomNames from 'components/shared/CustomNames/useCustomNames';
import NAMED_CONSTATNTS from 'components/shared/CustomNames/Constants';
import { getUserActiveState, INACTIVE_USER_COLOR } from 'utils/userHelpers';
import { getUserUid } from 'utils/getUid';
import useUserDropDownData from 'components/shared/Hooks/useUserDropDownData';

const UsecaseDetailCard = (props: any) => {
  const [editUsecaseDrawer, setEditUsecaseDrawer] = useState<boolean>(false);
  const project = useAppSelector(selectProjectInformation);
  const customerDetail = useAppSelector(selectCustomerDetail);
  const useCaseInfo = useAppSelector(selectUsecaseInfo);
  const dispatch = useDispatch();
  const [selectedCase, setSelectedCase] = useState<any>({});
  const params = useParams();
  const { getCustomNameMappedValue } = useCustomNames();
  const companyUsersDropDownData = useUserDropDownData('company');

  useEffect(() => {
    const detail = {
      project_uid: params.projectId,
      association_uid: params.associationId,
    };
    dispatch(getProjectInformation(detail));
  }, []);

  useEffect(() => {
    const uc = project.usecase.filter((uc: any) => uc.case_uid == params.caseId);
    setSelectedCase({ ...uc[0] });
  }, [project]);

  function stringToHslColor(str: string, s: number, l: number) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    const h = hash % 360;
    return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
  }

  function stringAvatar(name: string) {
    return {
      sx: {
        bgcolor: stringToHslColor(name, 30, 80),
      },
      children: (
        <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#000000' }}>
          {name.split(' ')[0][0]}
          {name.indexOf(' ') >= 0 ? name.split(' ')[1][0] : null}
        </Typography>
      ),
    };
  }

  return (
    <>
      {Object.entries(selectedCase).length > 0 && (
        <Paper sx={{ padding: '12px', minHeight: '480', minWidth: '310', boxShadow: 'none' }}>
          <Stack spacing={2} direction="row" justifyContent="space-between">
            <Typography variant="h2">{label_use_case} Details</Typography>
            {useCaseInfo.__user_permissions?.includes(userPermissionSchema.EDIT) && (
              <IconButton
                onClick={() => setEditUsecaseDrawer(!editUsecaseDrawer)}
                disableRipple
                style={{ padding: '0px' }}
              >
                <EditPen />
              </IconButton>
            )}
          </Stack>
          <Stack spacing={1} my={2} direction="row" alignItems="center" justifyContent="space-between">
            <Stack>
              <Typography className="label-text" variant="subtitle2">
                Actual Start Date
              </Typography>
              <Typography variant="subtitle1">
                {isValidDate(new Date(selectedCase.case_startdate.replace(/-/g, '/')))
                  ? format(new Date(selectedCase.case_startdate.replace(/-/g, '/')), 'dd MMM yyyy')
                  : 'Nan-Nan-Nan'}
              </Typography>
            </Stack>
            <Stack sx={{ minWidth: '110px' }}>
              <Typography className="label-text" variant="subtitle2">
                Planned Start Date
              </Typography>
              <Typography variant="subtitle1">
                {selectedCase.case_planned_startdate != null &&
                selectedCase.case_planned_startdate != '0000-00-00 00:00:00'
                  ? format(new Date(selectedCase.case_planned_startdate.replace(/-/g, '/')), 'dd MMM yyyy')
                  : '--'}
              </Typography>
            </Stack>
          </Stack>
          <Stack my={1} direction="row" alignItems="center" justifyContent="space-between">
            <Stack>
              <Typography className="label-text" variant="subtitle2">
                {label_delivery_date}
              </Typography>
              {selectedCase.case_deliverydate == '0000-00-00 00:00:00' ? (
                <>
                  <Typography variant="subtitle1">-</Typography>
                </>
              ) : (
                <>
                  {selectedCase.case_stage !== 'Go-Live' && selectedCase.case_stage !== 'Adption' ? (
                    <Typography variant="subtitle1">Not yet delivered</Typography>
                  ) : (
                    <Typography variant="subtitle1">
                      {format(new Date(selectedCase.case_deliverydate.replace(/-/g, '/')), 'dd MMM yyyy')}
                    </Typography>
                  )}
                </>
              )}
            </Stack>
            <Stack sx={{ minWidth: '110px' }}>
              <Typography className="label-text" variant="subtitle2">
                {label_due_date}
              </Typography>
              <Typography variant="subtitle1">
                {format(new Date(selectedCase.case_requiredbydate.replace(/-/g, '/')), 'dd MMM yyyy')}{' '}
              </Typography>
            </Stack>
          </Stack>
          <Stack mb={1} sx={{ minWidth: '110px' }}>
            <Typography className="label-text" variant="subtitle2">
              Estimated Delivery Date
            </Typography>
            <Typography sx={{ color: '#E75E5C' }} variant="subtitle1">
              {format(new Date(selectedCase.eta_forcasedelivery.replace(/-/g, '/')), 'dd MMM yyyy')}{' '}
            </Typography>
          </Stack>
          <Divider />
          <Stack>
            {selectedCase?.imported_template == '1' && (
              <>
                <Typography className="label-text" variant="subtitle2">
                  Imported from
                </Typography>
                <Typography variant="subtitle1">{useCaseInfo?.template_case_name}</Typography>
              </>
            )}
            {selectedCase?.imported_template == '2' && (
              <>
                <Typography className="label-text" variant="subtitle2">
                  Imported from
                </Typography>
                <Typography variant="subtitle1">{useCaseInfo?.template_case_name}</Typography>
              </>
            )}
          </Stack>
          <Stack my={1} direction="row" alignItems="center" justifyContent="space-between">
            <Stack>
              <Typography className="label-text" variant="subtitle2">
                Stage
              </Typography>
              <Typography variant="subtitle1">
                {getCustomNameMappedValue(selectedCase.case_stage, NAMED_CONSTATNTS.usecase_stage_key)}
              </Typography>
            </Stack>
            <Stack sx={{ minWidth: '110px' }}>
              <Typography className="label-text" variant="subtitle2">
                Category
              </Typography>
              <Typography variant="subtitle1">
                {getCustomNameMappedValue(selectedCase.case_needed_status, NAMED_CONSTATNTS.usecase_category_key)}
              </Typography>
            </Stack>
          </Stack>
          <Stack my={1} direction="row" alignItems="center" justifyContent="space-between">
            <Stack>
              <Typography className="label-text" variant="subtitle2">
                Implementation Fee
              </Typography>
              <Typography variant="subtitle1">
                {formatCurrency(selectedCase.case_fees ? selectedCase.case_fees : 0, customerDetail?.currency_code)}
              </Typography>
            </Stack>
          </Stack>
          {/* <Stack my={1} direction="row" alignItems="center" justifyContent="space-between"> */}
          {/* <Stack>
            
            </Stack> */}
          <Divider />
          <Stack direction="row" spacing={2} mt={2}>
            <Stack direction="column">
              <Avatar
                style={{ height: '38px', width: '40px', marginTop: '3px' }}
                {...stringAvatar(selectedCase.case_deliverymanager)}
              />
            </Stack>
            <Stack ml={3} pl={0} direction="column">
              <Typography className="label-text" variant={'subtitle2'}>
                {label_use_case_delivery_manager}
              </Typography>

              {!selectedCase.case_deliverymanager ? (
                <Typography m={1} variant="subtitle1">
                  -
                </Typography>
              ) : (
                <>
                  {getUserActiveState(getUserUid(selectedCase.case_deliverymanager), companyUsersDropDownData) ===
                  '0' ? (
                    <Typography style={{ color: INACTIVE_USER_COLOR }} variant="subtitle1">
                      {selectedCase.case_deliverymanager}&nbsp;(Inactive)
                    </Typography>
                  ) : (
                    <Typography variant="subtitle1">{selectedCase.case_deliverymanager}</Typography>
                  )}
                </>
              )}
            </Stack>
          </Stack>
          {/* </Stack> */}
          {editUsecaseDrawer && (
            <EditUsecaseDrawer
              client={props.clientDetail}
              usecaseDetails={selectedCase}
              onClose={() => setEditUsecaseDrawer(!editUsecaseDrawer)}
              drawerState={editUsecaseDrawer}
              tasks={props.tasks}
            />
          )}
        </Paper>
      )}
    </>
  );
};

export default UsecaseDetailCard;
