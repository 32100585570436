import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../..';

export const selectOpenTasksState = (state: RootState) => state.openTasksState;

export const selectOpenTasks = createSelector(
  selectOpenTasksState,
  (openTasksState) => openTasksState.getOpenTasks
);
export const selectOpenTasksStatus = createSelector(
  selectOpenTasksState,
  (openTasksState) => openTasksState.getOpenTasksStatus
);
export const selectOpenTasksError = createSelector(
  selectOpenTasksState,
  (openTasksState) => openTasksState.getOpenTasksError
);



export const selectOpenInternalTasks = createSelector(
  selectOpenTasksState,
  (openTasksState) => openTasksState.getOpenTasks.filter(task => task.is_task_team == 1)
);

export const selectOpenExternalTasks = createSelector(
  selectOpenTasksState,
  (openTasksState) => openTasksState.getOpenTasks.filter(task => task.is_task_team == 0)
);