import CircularProgress, {CircularProgressProps} from '@mui/material/CircularProgress';
import { Box } from '@mui/system';
import { ReactComponent as Logo } from '../../../assets/svgs/Logo.svg';

const CognisaasLoader = (props:CircularProgressProps) => {
    return (
        <Box sx={{height:'80vh'}} display='flex' justifyContent='center' alignItems='center'>
      
            <CircularProgress
                variant="indeterminate"
                size={60}
                thickness={3}
                {...props}
            />
            <span style={{position:'absolute'}}>
                <Logo />
            </span>
        </Box>
    );
};

export default CognisaasLoader;