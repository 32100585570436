import * as React from 'react';

import './CognisaasCountBadge.css';

interface props {
  count: number;
  isActive: boolean;
  badgeBgColor?: string;
  badgeColor?: string;
}

const CognisaasCountBadge = (props: props) => {
  const badgeStyleDeterminer = props.isActive
    ? { backgroundColor: '#2D60F6', color: '#FFFFFF' }
    : props?.badgeBgColor && props?.badgeColor
    ? { backgroundColor: props?.badgeBgColor, color: props?.badgeColor }
    : {};

  return (
    <React.Fragment>
      {props.count > 99 ? (
        <span
          className={(props.isActive ? 'LabeledBadge__badgeActive ' : '') + 'LabeledBadge__badge__large'}
          style={{ ...badgeStyleDeterminer, borderRadius: '12px', width: '35px' }}
        >
          {props.count}
        </span>
      ) : (
        <span
          className={(props.isActive ? 'LabeledBadge__badgeActive ' : '') + 'LabeledBadge__badge__large'}
          style={badgeStyleDeterminer}
        >
          {props.count}
        </span>
      )}
    </React.Fragment>
  );
};

export default CognisaasCountBadge;
