import { Stack, Grid, Typography, Avatar, Divider } from '@mui/material';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  getContactNameByContactUid,
  getUserNameByUserUid,
  getIndustryName,
  getSegmentName,
  getStageName,
} from '../../../utils/getUid';
import format from 'date-fns/format';
import { ReactComponent as NoRecords } from '../../../assets/svgs/no_records.svg';
import { useState, useEffect } from 'react';
import { isUndefined, isValidDate } from 'utils/dataHelpers';
import CognisaasMenu from '../CognisaasCustomMenu/CognisaasCustomMenu';
import CognisaasTimeline from '../CognisaasTimeline/CognisaasTimeline';
import { label_arr, label_client, label_project } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import useCustomNames from '../CustomNames/useCustomNames';

const RecentActivity = (props: any) => {
  const [audit, setAudit] = useState<any>([]);
  const [openTimelineModal, setOpenTimelineModal] = useState<boolean>(false);
  const { getCustomNameMappedValue } = useCustomNames();

  function stringToHslColor(str: string, s: number, l: number) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    const h = hash % 360;
    return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
  }

  function stringAvatar(name: string) {
    return {
      sx: {
        bgcolor: stringToHslColor(name, 30, 80),
      },
      children: (
        <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#000000' }}>
          {name.split(' ')[0][0]}
          {name.indexOf(' ') >= 0 ? name.split(' ')[1][0] : null}
        </Typography>
      ),
    };
  }

  useEffect(() => {
    if (props.audit) {
      const tempArray: any = [];
      props?.audit.map((trail: any) => {
        tempArray.push({
          ...trail,
          modified_on: format(new Date(trail?.modified_on.replace(/-/g, '/')), 'yyyy/MM/dd hh:mm a'),
        });
      });
      tempArray.sort(sortByProperty('modified_on'));
      setAudit([...tempArray]);
    }
  }, [props.audit]);

  const handleOnCloseTimelineModal = () => setOpenTimelineModal(false);
  const handleOnOpenTimelineModal = () => setOpenTimelineModal(true);

  const parseLines = (value: string) => value?.replace(/(\\n)/g, '\n').replace(/(\\)/g, '');

  return (
    <>
      <Stack direction="row" justifyContent={'space-between'}>
        <Typography variant="h6">Recent Updates</Typography>
        <CognisaasTimeline
          open={openTimelineModal}
          handleOnClose={handleOnCloseTimelineModal}
          timelineDetails={audit}
          type={props.type}
          totalTrail={props.type === label_client ? props?.totalTrail : null}
          clientName={props.type === label_client ? props?.clientName : null}
        />
        <CognisaasMenu
          list={[
            {
              value: 'History',
              handleClick: handleOnOpenTimelineModal,
              args: [],
            },
          ]}
        />
      </Stack>
      {audit.length == 0 ? (
        <Stack justifyContent="center" alignItems="center">
          <NoRecords style={{ marginLeft: '6px', marginTop: '10px', width: '150px', height: '150px' }}></NoRecords>
        </Stack>
      ) : (
        <>
          <Grid sx={{ marginTop: '0px', height: '300px', marginRight: '0px' }}>
            {audit.map((item: any, index: number) => (
              <>
                {item.field != 'Created' && (
                  <>
                    <Grid key={index} container>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        style={{ width: '100%' }}
                      >
                        <Stack direction="row" alignItems="center">
                          <Avatar
                            style={{ height: '34px', width: '34px', marginTop: '3px' }}
                            {...stringAvatar(
                              item.user_uid != 'CogniSaaS Platform'
                                ? getUserNameByUserUid(item.user_uid)
                                : item.user_uid
                            )}
                          />
                          <Typography
                            variant="subtitle1"
                            style={{
                              marginLeft: '10px',
                              width: '100px',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {/* {console.log('tempUser', getUserNameByUserUid(item.user_uid))} */}
                            {item.user_uid != 'CogniSaaS Platform'
                              ? getUserNameByUserUid(item.user_uid)
                              : item.user_uid}
                          </Typography>
                        </Stack>
                        <Typography variant="subtitle2" className="label-text">
                          {isValidDate(new Date(item.modified_on.replace(/-/g, '/')))
                            ? `${format(new Date(item.modified_on.replace(/-/g, '/')), 'MMM dd hh:mm aaa')}`
                            : ''}
                        </Typography>
                      </Stack>
                      <Grid style={{ marginLeft: '45px' }}>
                        <Typography style={{ margin: '3px 0' }} variant="subtitle2">
                          <span className="label-text">
                            {item.field.includes('Comment') || item.field.includes('Description') ? (
                              <span>
                                {item.old_value != '' ? (
                                  <span>
                                    Updated {item.field.includes('Comment') ? 'comment' : 'description'} from{' '}
                                  </span>
                                ) : (
                                  <span style={{ color: '#7A7A7A' }}>
                                    Added {item.field.includes('Comment') ? 'comment ' : 'description '}
                                  </span>
                                )}
                              </span>
                            ) : (
                              <>
                                <span>Updated </span>{' '}
                                {item.field.includes('status') ? (
                                  <span> status</span>
                                ) : item.field.includes('Account ARR') ? (
                                  <span>
                                    {' '}
                                    {label_client} {label_arr.toUpperCase()}
                                  </span>
                                ) : item.field.includes('Project ARR') ? (
                                  <span>
                                    {' '}
                                    {label_project} {label_arr.toUpperCase()}
                                  </span>
                                ) : (
                                  <span> {item.field}</span>
                                )}{' '}
                                from &nbsp;
                              </>
                            )}
                          </span>
                          {(item.field.includes('Date') || item.field.includes('date')) &&
                            item.field.includes('Custom Field') ? (
                            <span>
                              {isValidDate(new Date(item.old_value))
                                ? ` ${format(new Date(item.old_value), 'dd MMM yyyy')} to `
                                : 'None to '}
                              {isValidDate(new Date(item.new_value))
                                ? `${format(new Date(item.new_value), 'dd MMM yyyy')}`
                                : 'None'}
                            </span>
                          ) : item.field.includes('Date') ||
                            item.field.includes('date') ||
                            item.field.includes('ETA') ||
                            item.field.includes('Due Date') ||
                            item.field.includes('Delivery Date') ? (
                            <span>
                              {isValidDate(new Date(item.old_date))
                                ? ` ${format(new Date(item.old_date), 'dd MMM yyyy')} to `
                                : 'None to '}
                              {isValidDate(new Date(item.new_date))
                                ? `${format(new Date(item.new_date), 'dd MMM yyyy')}`
                                : 'None'}
                            </span>
                          ) : item.field.includes('Owner') ||
                            item.field.includes('owner') ||
                            item.field.includes('Manager') ||
                            item.field.includes('manager') ? (
                            <span>
                              {getUserNameByUserUid(item.old_value)
                                ? getUserNameByUserUid(item.old_value)
                                : getContactNameByContactUid(item.old_value)
                                  ? getContactNameByContactUid(item.old_value)
                                  : item.old_value}
                              &nbsp;
                              <span className="label-text">to</span>
                              &nbsp;
                              {getUserNameByUserUid(item.new_value)
                                ? getUserNameByUserUid(item.new_value)
                                : getContactNameByContactUid(item.new_value)
                                  ? getContactNameByContactUid(item.new_value)
                                  : item.new_value}
                            </span>
                          ) : item.field.includes('status') ||
                            ['red', 'amber', 'green', 'blue'].includes(item.old_value.toLowerCase()) ||
                            ['red', 'amber', 'green', 'blue'].includes(item.new_value.toLowerCase()) ? (
                            <>
                              <span>
                                {item.old_value === '' ? (
                                  ' None'
                                ) : (
                                  <span>
                                    {' '}
                                    {item.old_value.toLowerCase() == 'red' ? (
                                      <>
                                        <span
                                          style={{
                                            backgroundColor: '#E75B5C',
                                            display: 'inline-block',
                                            width: '8px',
                                            height: '8px',
                                            borderRadius: '60%',
                                            marginRight: '8px',
                                            marginLeft: '4px',
                                          }}
                                        ></span>
                                        <span>Overdue</span>
                                      </>
                                    ) : item.old_value.toLowerCase() == 'green' ? (
                                      <>
                                        <span
                                          style={{
                                            backgroundColor: '#4F9F52',
                                            display: 'inline-block',
                                            width: '8px',
                                            height: '8px',
                                            borderRadius: '60%',
                                            marginRight: '8px',
                                            marginLeft: '4px',
                                          }}
                                        ></span>
                                        <span>On Track</span>
                                      </>
                                    ) : item.old_value.toLowerCase() == 'amber' ? (
                                      <>
                                        <span
                                          style={{
                                            backgroundColor: '#EFA958',
                                            display: 'inline-block',
                                            width: '8px',
                                            height: '8px',
                                            borderRadius: '60%',
                                            marginRight: '8px',
                                            marginLeft: '4px',
                                          }}
                                        ></span>
                                        <span>At Risk</span>
                                      </>
                                    ) : item.old_value.toLowerCase() == 'blue' ? (
                                      <>
                                        <span
                                          style={{
                                            backgroundColor: '#4390E1',
                                            display: 'inline-block',
                                            width: '8px',
                                            height: '8px',
                                            borderRadius: '60%',
                                            marginRight: '8px',
                                            marginLeft: '4px',
                                          }}
                                        ></span>
                                        <span>Completed</span>
                                      </>
                                    ) : item.old_value.toLowerCase() == 'grey' ? (
                                      <>
                                        <span
                                          style={{
                                            backgroundColor: '#7a7a7a',
                                            display: 'inline-block',
                                            width: '8px',
                                            height: '8px',
                                            borderRadius: '60%',
                                            marginRight: '8px',
                                            marginLeft: '4px',
                                          }}
                                        ></span>
                                        <span>Deferred</span>
                                      </>
                                    ) : (
                                      ''
                                    )}{' '}
                                  </span>
                                )}
                                <span style={{ fontWeight: '400' }} className="label-text">
                                  {' '}
                                  to{' '}
                                </span>
                                {item.new_value === '' ? (
                                  <span> None</span>
                                ) : (
                                  <span>
                                    {' '}
                                    {item.new_value.toLowerCase() == 'red' ? (
                                      <>
                                        <span
                                          style={{
                                            backgroundColor: '#E75B5C',
                                            display: 'inline-block',
                                            width: '8px',
                                            height: '8px',
                                            borderRadius: '60%',
                                            marginRight: '8px',
                                            marginLeft: '4px',
                                          }}
                                        ></span>
                                        <span>Overdue</span>
                                      </>
                                    ) : item.new_value.toLowerCase() == 'green' ? (
                                      <>
                                        <span
                                          style={{
                                            backgroundColor: '#4F9F52',
                                            display: 'inline-block',
                                            width: '8px',
                                            height: '8px',
                                            borderRadius: '60%',
                                            marginRight: '8px',
                                            marginLeft: '4px',
                                          }}
                                        ></span>
                                        <span>On Track</span>
                                      </>
                                    ) : item.new_value.toLowerCase() == 'amber' ? (
                                      <>
                                        <span
                                          style={{
                                            backgroundColor: '#EFA958',
                                            display: 'inline-block',
                                            width: '8px',
                                            height: '8px',
                                            borderRadius: '60%',
                                            marginRight: '8px',
                                            marginLeft: '4px',
                                          }}
                                        ></span>
                                        <span>At Risk</span>
                                      </>
                                    ) : item.new_value.toLowerCase() == 'blue' ? (
                                      <>
                                        <span
                                          style={{
                                            backgroundColor: '#4390E1',
                                            display: 'inline-block',
                                            width: '8px',
                                            height: '8px',
                                            borderRadius: '60%',
                                            marginRight: '8px',
                                            marginLeft: '4px',
                                          }}
                                        ></span>
                                        <span>Completed</span>
                                      </>
                                    ) : item.new_value.toLowerCase() == 'grey' ? (
                                      <>
                                        <span
                                          style={{
                                            backgroundColor: '#7a7a7a',
                                            display: 'inline-block',
                                            width: '8px',
                                            height: '8px',
                                            borderRadius: '60%',
                                            marginRight: '8px',
                                            marginLeft: '4px',
                                          }}
                                        ></span>
                                        <span>Deferred</span>
                                      </>
                                    ) : (
                                      ''
                                    )}{' '}
                                  </span>
                                )}
                              </span>
                            </>
                          ) : item.field.includes('Segment') ? (
                            <span>
                              {' '}
                              {getSegmentName(item.old_value) ? getSegmentName(item.old_value) : item.old_value}&nbsp;
                              <span className="label-text">to</span>
                              &nbsp;
                              {getSegmentName(item.new_value) ? getSegmentName(item.new_value) : item.new_value}
                            </span>
                          ) : item.field.includes('Industry') ? (
                            <span>
                              {' '}
                              {getIndustryName(item.old_value) ? getIndustryName(item.old_value) : item.old_value}&nbsp;
                              <span className="label-text">to</span>
                              &nbsp;
                              {getIndustryName(item.new_value) ? getIndustryName(item.new_value) : item.new_value}
                            </span>
                          ) : item.field.includes('Stage') && !isUndefined(item.project_uid) ? (
                            <span>
                              {' '}
                              {getStageName(item.old_value)
                                ? getCustomNameMappedValue(getStageName(item.old_value), 'project_stage')
                                : getCustomNameMappedValue(item.old_value, 'project_stage')}
                              &nbsp;
                              <span className="label-text">to</span>
                              &nbsp;
                              {getStageName(item.new_value)
                                ? getCustomNameMappedValue(getStageName(item.new_value), 'project_stage')
                                : getCustomNameMappedValue(item.new_value, 'project_stage')}
                            </span>
                          ) : item.field.includes('Stage') && !isUndefined(item.case_uid) ? (
                            <span>
                              {' '}
                              {getStageName(item.old_value)
                                ? getCustomNameMappedValue(getStageName(item.old_value), 'case_stage')
                                : getCustomNameMappedValue(item.old_value, 'case_stage')}
                              &nbsp;
                              <span className="label-text">to</span>
                              &nbsp;
                              {getStageName(item.new_value)
                                ? getCustomNameMappedValue(getStageName(item.new_value), 'case_stage')
                                : getCustomNameMappedValue(item.new_value, 'case_stage')}
                            </span>
                          ) : item.field.includes('Category') && !isUndefined(item.project_uid) ? (
                            <span>
                              {' '}
                              {getCustomNameMappedValue(item.old_value, 'project_needed_status')}
                              &nbsp;
                              <span className="label-text">to</span>
                              &nbsp;
                              {getCustomNameMappedValue(item.new_value, 'project_needed_status')}
                            </span>
                          ) : item.field.includes('Category') && !isUndefined(item.case_uid) ? (
                            <span>
                              {' '}
                              {getCustomNameMappedValue(item.old_value, 'case_needed_status')}
                              &nbsp;
                              <span className="label-text">to</span>
                              &nbsp;
                              {getCustomNameMappedValue(item.new_value, 'case_needed_status')}
                            </span>
                          ) : item.field.includes('Comment') || item.field.includes('Description') ? (
                            <>
                              <span>
                                {item.old_value != '' ? (
                                  <>
                                    {parseLines(item.old_value)} <small style={{ color: '#7A7A7A' }}>to</small>{' '}
                                    {parseLines(item.new_value)}
                                  </>
                                ) : (
                                  <>{parseLines(item.new_value)}</>
                                )}
                              </span>
                            </>
                          ) : (
                            <>
                              <span>
                                {item.old_value === '' ? (
                                  <span> None </span>
                                ) : item.old_value === '0' &&
                                  (!item.field.includes('ARR') || !item.field.includes('Implementation')) ? (
                                  <span> {item.old_value} </span>
                                ) : item.old_value === '1' &&
                                  (!item.field.includes('ARR') || !item.field.includes('Implementation')) ? (
                                  <span> {item.old_value} </span>
                                ) : (
                                  <span> {item.old_value} </span>
                                )}
                                <span style={{ fontWeight: '400' }}>to</span>
                                {item.new_value === '' ? (
                                  <span> None</span>
                                ) : item.new_value === '0' &&
                                  (!item.field.includes('ARR') || !item.field.includes('Implementation')) ? (
                                  <span> {item.new_value}</span>
                                ) : item.new_value === '1' &&
                                  (!item.field.includes('ARR') || !item.field.includes('Implementation')) ? (
                                  <span> {item.new_value}</span>
                                ) : (
                                  <span> {item.new_value} </span>
                                )}
                              </span>
                            </>
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider style={{ margin: '2px 0' }} />
                  </>
                )}
                {/* FOR CREATED */}
                {item.field == 'Created' && (
                  <>
                    <Grid key={index} container>
                      <Grid item direction="row" md={2}>
                        <Avatar
                          style={{ height: '34px', width: '34px', marginTop: '3px' }}
                          {...stringAvatar(
                            item.user_uid != 'CogniSaaS Platform' ? getUserNameByUserUid(item.user_uid) : item.user_uid
                          )}
                        />
                      </Grid>
                      <Grid spacing={2} item direction="row" md={8}>
                        <Typography variant="subtitle1">{getUserNameByUserUid(item.user_uid)}</Typography>
                        <Typography style={{ margin: '3px 0' }} variant="subtitle2" className={'label-text'}>
                          Created this {props.type}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider style={{ margin: '2px 0' }} />
                  </>
                )}
              </>
            ))}
          </Grid>
        </>
      )}
    </>
  );
};
function sortByProperty(property: any) {
  return function (a: any, b: any) {
    if (new Date(a[property]) < new Date(b[property])) return 1;
    else if (new Date(a[property]) > new Date(b[property])) return -1;
    return 0;
  };
}
export default RecentActivity;
