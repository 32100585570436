import { useState, useEffect } from 'react';
import { Stack, Typography } from '@mui/material';
import {
  getTemplateProjectTeamTaskList,
  getTemplateProjectTaskDependency,
  getTemplateProjectClientTaskList,
  getTemplateUsecaseClientTaskList,
  getTemplateUsecaseTaskDependency,
  getTemplateUsecaseTeamTaskList,
} from '../../../store/modules/Templates/Templates';
import {
  selectTemplateProjectTaskDependency,
  selectTemplateProjectTeamTasks,
  selectTemplateProjectClientTasks,
  selectTemplateUsecaseTaskDependency,
  selectTemplateUsecaseClientTasks,
  selectTemplateUsecaseTeamTasks,
} from '../../../store/modules/Templates/selector';
import { addProjectTaskDependency, addTaskDependency } from 'store/modules/Task/slice';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import CognisaasSelect from 'components/shared/CognisaasSelect/CognisaasSelect';
import AddedDependencyList from './AddedDependencyList';
import { label_client, label_task } from 'components/Settings/Preferences/CustomLabelCookieHelper';
// import { useParams } from 'react-router-dom';

const Dependency = (props: any) => {
  // const params = useParams();
  console.log(props);
  const detail = props.detail;
  const type = props.taskType;
  const taskUid = props.taskUid;
  const taskComesUnderUid = props.taskComesUnderUid;

  const dispatch = useDispatch();
  const dependencies =
    type == 'project'
      ? useAppSelector(selectTemplateProjectTaskDependency)
      : useAppSelector(selectTemplateUsecaseTaskDependency);
  const teamTasks =
    type == 'project' ? useAppSelector(selectTemplateProjectTeamTasks) : useAppSelector(selectTemplateUsecaseTeamTasks);
  const clientTasks =
    type == 'project'
      ? useAppSelector(selectTemplateProjectClientTasks)
      : useAppSelector(selectTemplateUsecaseClientTasks);

  const [selectedDependency, setSelectedDependency] = useState<string>('');
  const [teamTaskDependencyList, setTeamTaskDependencyList] = useState<string[]>([]);
  const [clientTaskDependencyList, setClientTaskDependencyList] = useState<string[]>([]);

  useEffect(() => {
    if (type == 'project') {
      const detail = {
        task_uid: taskUid,
      };
      dispatch(getTemplateProjectTaskDependency(detail));
      dispatch(getTemplateProjectTeamTaskList(taskComesUnderUid));
      dispatch(getTemplateProjectClientTaskList(taskComesUnderUid));
    } else {
      const detail = {
        task_uid: taskUid,
        type: 'usecase',
      };
      dispatch(getTemplateUsecaseTaskDependency(detail));
      dispatch(getTemplateUsecaseTeamTaskList(taskComesUnderUid));
      dispatch(getTemplateUsecaseClientTaskList(taskComesUnderUid));
    }
  }, []);

  useEffect(() => {
    if (teamTasks.length > 0) {
      // remove alreadt added dependencies from dropdown
      const dependenciesUid = dependencies.map((task: any) => task.task_parent_uid);
      dependenciesUid.push(taskUid);
      const i = teamTasks.filter((task: any) => !dependenciesUid.includes(task.template_task_uid));
      console.log(dependenciesUid, i, setSelectedDependency);

      const temp = i.map((task: any) => task.template_task_detail);
      console.log(temp);

      setTeamTaskDependencyList([...temp]);
    }
    if (clientTasks.length > 0) {
      const dependenciesUid = dependencies.map((task: any) => task.task_parent_uid);
      dependenciesUid.push(taskUid);
      const i = clientTasks.filter((task: any) => !dependenciesUid.includes(task.template_task_uid));
      console.log(dependenciesUid, i);

      const temp = i.map((task: any) => task.template_task_detail);
      setClientTaskDependencyList([...temp]);
    }
  }, [teamTasks, clientTasks]);

  const addDependency = async (taskType: string, name: string) => {
    console.log(taskType, name);
    if (type == 'project') {
      if (taskType == 'team') {
        const temp = teamTasks.filter((task: any) => task.template_task_detail == name);
        const post = {
          project_uid: taskComesUnderUid,
          case_uid: '',
          task_parent_type: detail == 'Team' ? 'company' : 'client',
          task_parent_uid: temp[0].template_task_uid,
          task_type: 'company',
          task_uid: taskUid,
        };
        console.log(post);
        const data = {
          task_uid: taskUid,
        };
        await dispatch(addProjectTaskDependency(post));

        await dispatch(getTemplateProjectTaskDependency(data));
        updateDropDown();
      } else if (taskType == 'client') {
        const temp = clientTasks.filter((task: any) => task.template_task_detail == name);
        const post = {
          project_uid: taskComesUnderUid,
          case_uid: '',
          task_parent_type: detail == 'Team' ? 'company' : 'client',
          task_parent_uid: temp[0].template_task_uid,
          task_type: 'company',
          task_uid: taskUid,
        };
        console.log(post);
        const data = {
          task_uid: taskUid,
        };
        await dispatch(addProjectTaskDependency(post));

        await dispatch(getTemplateProjectTaskDependency(data));
        updateDropDown();
      }
    } else {
      if (taskType == 'team') {
        const temp = teamTasks.filter((task: any) => task.template_task_detail == name);
        const post = {
          project_uid: '',
          case_uid: taskComesUnderUid,
          task_parent_type: detail == 'Team' ? 'company' : 'client',
          task_parent_uid: temp[0].template_task_uid,
          task_type: 'company',
          task_uid: taskUid,
          type: 'usecase',
        };
        console.log(post);
        const data = {
          task_uid: taskUid,
          type: 'usecase',
        };
        await dispatch(addTaskDependency(post));

        await dispatch(getTemplateUsecaseTaskDependency(data));
        updateDropDown();
      } else if (taskType == 'client') {
        const temp = clientTasks.filter((task: any) => task.template_task_detail == name);
        const post = {
          project_uid: '',
          case_uid: taskComesUnderUid,
          task_parent_type: detail == 'Team' ? 'company' : 'client',
          task_parent_uid: temp[0].template_task_uid,
          task_type: 'company',
          task_uid: taskUid,
          type: 'usecase',
        };
        console.log('post = ', post);
        const data = {
          task_uid: taskUid,
          type: 'usecase',
        };
        await dispatch(addTaskDependency(post));

        await dispatch(getTemplateUsecaseTaskDependency(data));
        updateDropDown();
      }
    }
  };

  useEffect(() => {
    updateDropDown();
  }, [dependencies]);

  const updateDropDown = () => {
    console.log(dependencies, teamTasks);

    const dependencyTasksUid = dependencies.map((task: any) => task.task_parent_uid);
    dependencyTasksUid.push(taskUid);
    console.log(dependencyTasksUid);
    const team: any = [];
    for (let i = 0; i < teamTasks.length; i++) {
      console.log(teamTasks[i].template_task_uid);

      if (!dependencyTasksUid.includes(teamTasks[i].template_task_uid)) {
        console.log(teamTasks[i]);
        team.push(teamTasks[i]);
      }
    }
    console.log(team);

    console.log(teamTasks, team);
    const newFilter = team.map((task: any) => task.template_task_detail);
    setTeamTaskDependencyList([...newFilter]);

    // Do same for client

    const client: any = [];
    for (let i = 0; i < clientTasks.length; i++) {
      console.log(clientTasks[i].template_task_uid);

      if (!dependencyTasksUid.includes(clientTasks[i].template_task_uid)) {
        console.log(clientTasks[i]);
        client.push(clientTasks[i]);
      }
    }
    console.log(client);

    console.log(clientTasks, client);
    const newFilterForClient = client.map((task: any) => task.template_task_detail);
    setClientTaskDependencyList([...newFilterForClient]);
  };

  return (
    <>
      <Stack sx={{ margin: '16px 0' }} direction="row" spacing={2}>
        <Stack>
          <Typography variant="subtitle2">Team {label_task}s</Typography>
          <CognisaasSelect
            width="210px"
            value={selectedDependency}
            validValues={teamTaskDependencyList}
            onChange={(e: any) => {
              console.log(e.target.value);
              addDependency('team', e.target.value);
            }}
          />
        </Stack>

        {/* ClientTask list */}

        <Stack>
          <Typography variant="subtitle2">
            {label_client} {label_task}s
          </Typography>
          <CognisaasSelect
            width="210px"
            value={selectedDependency}
            validValues={clientTaskDependencyList}
            onChange={(e: any) => {
              console.log(e.target.value);
              addDependency('client', e.target.value);
            }}
          />
        </Stack>
      </Stack>
      <AddedDependencyList type={type} dependencies={dependencies} teamTasks={teamTasks} clientTasks={clientTasks} />
    </>
  );
};

export default Dependency;
