import { Button, ButtonProps } from '@mui/material';

interface Props extends ButtonProps {
  label: string;
  isOutlined: boolean;
  isText?: boolean;
  clickHandler?: () => void;
  sx?: any;
  disabled?: boolean;
  type?: 'submit' | 'button' | 'reset';
}

const CognisaasButton: React.FC<Props> = (props: Props) => {
  return (
    <Button
      disabled={props.disabled == true}
      onClick={props?.clickHandler}
      disableElevation
      variant={props.isOutlined && !props.isText ? 'outlined' : props.isOutlined && props.isText ? 'text' : 'contained'}
      color="primary"
      sx={props.sx}
    >
      {props.label}
    </Button>
  );
};

export default CognisaasButton;
