import { Box, Grid, Typography, Paper, Stack } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import { useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { ReactComponent as NoRecords } from '../../../assets/svgs/no_records.svg';
// import { Doughnut } from 'react-chartjs-2';

// import { Doughnut } from 'react-chartjs-2';

import ClientTimeline from './ClientTimeline';
import CognisaasCard from '../../../components/shared/CognisaasCard/CognisaasCard';
import React from 'react';
import {
  clientCheckClient,
  client_userSignIn_withjwttoken,
  client_getProjectsWithTasks,
  client_getProjectUpgradationAlert,
  client_getUsecaseUpgradationAlert,
  client_getProjectDegradationAlert,
  client_getUsecaseDegradationAlert,
  client_getProjectStatusCountByClientUid,
  client_getTaskStatusCountByClientUid,
  client_getUseCaseStatusCount,
  client_getClientinformationapi,
} from '../../../store/modules/CustomerPortal/CustomerPortal';
import {
  selectUserObj,
  selectProjectWithTasks,
  selectprojectUpgradation,
  selectuseCaseUpgradation,
  selectprojectDegradation,
  selectuseCaseDegradation,
  selectprojectStatusCount,
  selecttaskStatusCount,
  selectuseCaseStatusCount,
  selectisMagicLinkExpired,
  selectclientUserInformation,
  selectcustomerInformation,
} from '../../../store/modules/CustomerPortal/selector';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../store/hooks';
import { format } from 'date-fns';
import Cookies from 'universal-cookie';
import DonutChart from 'components/shared/Charts/DonutCharts/DonutChart';
import {
  label_project,
  label_task,
  label_use_case,
  refreshLabelCookies,
} from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { getAllUsers } from 'store/modules/Common/slice';
const env: string = process.env.REACT_APP_ENV ?? 'local';
const graphColors: string[] = ['#4390E1', '#E75B5C', '#EFA958', '#4F9F52'];
const emptyGraphColors: string[] = ['#E5E5E5', '#a2a2a2', '#808080', '#4d4d4d'];

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other }: any = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Dashboard = () => {
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const navigate = useNavigate();

  const customerCompanyName: any = cookies.get('customerCompanyName');

  const customerInfo: any = useAppSelector(selectcustomerInformation);

  const useObjApi: any = useAppSelector(selectUserObj);
  const projectWithTaskApi: any = useAppSelector(selectProjectWithTasks);
  const projectProgressApi: any = useAppSelector(selectprojectUpgradation);
  const useCaseProgressApi: any = useAppSelector(selectuseCaseUpgradation);
  const projectDegradationApi: any = useAppSelector(selectprojectDegradation);
  const useCaseDegradationApi: any = useAppSelector(selectuseCaseDegradation);
  const projectStatusCountApi: any = useAppSelector(selectprojectStatusCount);
  const taskStatusCountApi: any = useAppSelector(selecttaskStatusCount);
  const useCaseStatusCountApi: any = useAppSelector(selectuseCaseStatusCount);
  const isExpired: any = useAppSelector(selectisMagicLinkExpired);
  const clientUserInformation: any = useAppSelector(selectclientUserInformation);

  React.useEffect(() => {
    console.log(customerInfo, 'customerInfo');
    cookies.set('task_acknowledgement', customerInfo?.task_acknowledgement);
  }, [customerInfo]);

  React.useEffect(() => {
    dispatch(clientCheckClient(customerCompanyName));
  }, [customerCompanyName]);

  React.useEffect(() => {
    console.log(clientUserInformation, 'clientUserInformation');
    cookies.set('cogni_customer_client', clientUserInformation?.client_name);
  }, [clientUserInformation]);

  React.useEffect(() => {
    console.log(isExpired, 'isExpired');
    if (isExpired == 'Magic Link Expired') {
      cookies.remove('jwt-token');
      if (env == 'local') {
        cookies.remove('jwt-token', { path: '/', domain: 'localhost' });
      } else if (env == 'dev') {
        cookies.remove('jwt-token', { path: '/', domain: '.devng.cognisaas.com' });
      } else if (env == 'prod') {
        cookies.remove('jwt-token', { path: '/', domain: '.ng.cognisaas.com' });
      } else if (env == 'new-prod') {
        cookies.remove('jwt-token', { path: '/', domain: '.cognisaas.net' });
      }
      navigate('/customer-portal/sendResetPasswordLink');
    }
  }, [isExpired]);

  const [totalTask, setTotalTask] = React.useState(0);
  const [totalUseCase, setTotalUseCase] = React.useState(0);
  const [totalProjects, setTotalProjects] = React.useState(0);

  const [value, setValue] = useState(0);
  const [companyName, setCompanyName] = useState<any>();
  const [jwtToken, setJwtToken] = useState('');
  const [projectsWithList, setProjectsWithList] = useState([]);

  const setTotalTaskCount = () => {
    let tempValue = 0;
    if (taskStatusCountApi?.blue > 0) tempValue = tempValue + Number(taskStatusCountApi.blue);
    if (taskStatusCountApi?.red > 0) tempValue = tempValue + Number(taskStatusCountApi.red);
    if (taskStatusCountApi?.amber > 0) tempValue = tempValue + Number(taskStatusCountApi.amber);
    if (taskStatusCountApi?.green > 0) tempValue = tempValue + Number(taskStatusCountApi.green);
    setTotalTask(tempValue);
  };

  const setTotalUseCaseCount = () => {
    let tempValue = 0;
    if (useCaseStatusCountApi?.blue > 0) tempValue = tempValue + Number(useCaseStatusCountApi.blue);
    if (useCaseStatusCountApi?.red > 0) tempValue = tempValue + Number(useCaseStatusCountApi.red);
    if (useCaseStatusCountApi?.amber > 0) tempValue = tempValue + Number(useCaseStatusCountApi.amber);
    if (useCaseStatusCountApi?.green > 0) tempValue = tempValue + Number(useCaseStatusCountApi.green);
    setTotalUseCase(tempValue);
  };

  const setTotalProjectsCount = () => {
    let tempValue = 0;
    if (projectStatusCountApi?.blue > 0) tempValue = tempValue + Number(projectStatusCountApi.blue);
    if (projectStatusCountApi?.red > 0) tempValue = tempValue + Number(projectStatusCountApi.red);
    if (projectStatusCountApi?.amber > 0) tempValue = tempValue + Number(projectStatusCountApi.amber);
    if (projectStatusCountApi?.green > 0) tempValue = tempValue + Number(projectStatusCountApi.green);
    setTotalProjects(tempValue);
  };

  useEffect(() => {
    setTotalTaskCount();
    setTotalUseCaseCount();
    setTotalProjectsCount();
    refreshLabelCookies();
  }, [projectStatusCountApi, useCaseStatusCountApi, taskStatusCountApi]);

  const [dataTaskCountApi, setDataTaskCountApi] = React.useState([
    {
      id: 'Completed',
      label: 'blue',
      value: 99999999999,
      type: 'task',
    },
    {
      id: 'Overdue',
      label: 'red',
      value: 99999999999,
      type: 'task',
    },
    {
      id: 'At risk',
      label: 'amber',
      value: 99999999999,
      type: 'task',
    },
    {
      id: 'On track',
      label: 'green',
      value: 99999999999,
      type: 'task',
    },
  ]);

  const [dataUseCaseApi, setDataUseCaseApi] = React.useState([
    {
      id: 'Completed',
      label: 'blue',
      value: 99999999999,
      type: 'usecase',
    },
    {
      id: 'Overdue',
      label: 'red',
      value: 99999999999,
      type: 'usecase',
    },
    {
      id: 'At risk',
      label: 'amber',
      value: 99999999999,
      type: 'usecase',
    },
    {
      id: 'On track',
      label: 'green',
      value: 99999999999,
      type: 'usecase',
    },
  ]);

  const [dataProjectApi, setDataProjectApi] = React.useState([
    {
      id: 'Completed',
      label: 'blue',
      value: 99999999999,
      type: 'project',
    },
    {
      id: 'Overdue',
      label: 'red',
      value: 99999999999,
      type: 'project',
    },
    {
      id: 'At risk',
      label: 'amber',
      value: 99999999999,
      type: 'project',
    },
    {
      id: 'On track',
      label: 'green',
      value: 99999999999,
      type: 'project',
    },
  ]);

  useEffect(() => {
    if (taskStatusCountApi)
      setDataTaskCountApi([
        {
          id: 'Completed',
          label: 'blue',
          value: taskStatusCountApi.blue ? taskStatusCountApi.blue : 0,
          type: 'task',
        },
        {
          id: 'Overdue',
          label: 'red',
          value: taskStatusCountApi.red ? taskStatusCountApi.red : 0,
          type: 'task',
        },
        {
          id: 'At risk',
          label: 'amber',
          value: taskStatusCountApi.amber ? taskStatusCountApi.amber : 0,
          type: 'task',
        },
        {
          id: 'On track',
          label: 'green',
          value: taskStatusCountApi.green ? taskStatusCountApi.green : 0,
          type: 'task',
        },
      ]);

    if (taskStatusCountApi == null || Object.keys(taskStatusCountApi).length === 0)
      setDataTaskCountApi([
        {
          id: 'Completed',
          label: 'blue',
          value: 99999999999,
          type: 'task',
        },
        {
          id: 'Overdue',
          label: 'red',
          value: 99999999999,
          type: 'task',
        },
        {
          id: 'At risk',
          label: 'amber',
          value: 99999999999,
          type: 'task',
        },
        {
          id: 'On track',
          label: 'green',
          value: 99999999999,
          type: 'task',
        },
      ]);
    console.log(useCaseStatusCountApi, projectStatusCountApi, 'clictapi');

    if (useCaseStatusCountApi)
      setDataUseCaseApi([
        {
          id: 'Completed',
          label: 'blue',
          value: useCaseStatusCountApi.blue ? useCaseStatusCountApi.blue : 0,
          type: 'usecase',
        },
        {
          id: 'Overdue',
          label: 'red',
          value: useCaseStatusCountApi.red ? useCaseStatusCountApi.red : 0,
          type: 'usecase',
        },
        {
          id: 'At risk',
          label: 'amber',
          value: useCaseStatusCountApi.amber ? useCaseStatusCountApi.amber : 0,
          type: 'usecase',
        },
        {
          id: 'On track',
          label: 'green',
          value: useCaseStatusCountApi.green ? useCaseStatusCountApi.green : 0,
          type: 'usecase',
        },
      ]);

    if (useCaseStatusCountApi == null || Object.keys(useCaseStatusCountApi).length === 0)
      setDataUseCaseApi([
        {
          id: 'Completed',
          label: 'blue',
          value: 99999999999,
          type: 'usecase',
        },
        {
          id: 'Overdue',
          label: 'red',
          value: 99999999999,
          type: 'usecase',
        },
        {
          id: 'At risk',
          label: 'amber',
          value: 99999999999,
          type: 'usecase',
        },
        {
          id: 'On track',
          label: 'green',
          value: 99999999999,
          type: 'usecase',
        },
      ]);

    if (projectStatusCountApi) {
      setDataProjectApi([
        {
          id: 'Completed',
          label: 'blue',
          value: projectStatusCountApi.blue ? projectStatusCountApi.blue : 0,
          type: 'project',
        },
        {
          id: 'Overdue',
          label: 'red',
          value: projectStatusCountApi.red ? projectStatusCountApi.red : 0,
          type: 'project',
        },
        {
          id: 'At risk',
          label: 'amber',
          value: projectStatusCountApi.amber ? projectStatusCountApi.amber : 0,
          type: 'project',
        },
        {
          id: 'On track',
          label: 'green',
          value: projectStatusCountApi.green ? projectStatusCountApi.green : 0,
          type: 'project',
        },
      ]);

      if (projectStatusCountApi == null || Object.keys(projectStatusCountApi).length === 0) {
        setDataProjectApi([
          {
            id: 'Completed',
            label: 'blue',
            value: 99999999999,
            type: 'project',
          },
          {
            id: 'Overdue',
            label: 'red',
            value: 99999999999,
            type: 'project',
          },
          {
            id: 'At risk',
            label: 'amber',
            value: 99999999999,
            type: 'project',
          },
          {
            id: 'On track',
            label: 'green',
            value: 99999999999,
            type: 'project',
          },
        ]);
      }
    }
  }, [taskStatusCountApi, useCaseStatusCountApi, projectStatusCountApi]);

  const CenteredTaskMetric = ({ centerX, centerY }: any) => {
    return (
      <>
        <text
          x={centerX}
          y={centerY - 6}
          textAnchor="middle"
          fill="#7A7A7A"
          dominantBaseline="central"
          style={{
            fontSize: '14px',
            fontWeight: 400,
            fontFamily: 'Noto Sans',
          }}
        >
          {label_task}s
        </text>
        <text
          x={centerX}
          y={centerY + 20}
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            fontSize: '16px',
            fontWeight: 400,
            fontFamily: 'Noto Sans',
          }}
        >
          {totalTask}
        </text>
      </>
    );
  };

  const CenteredUsecaseMetric = ({ centerX, centerY }: any) => {
    return (
      <>
        <text
          x={centerX}
          y={centerY - 6}
          textAnchor="middle"
          fill="#7A7A7A"
          dominantBaseline="central"
          style={{
            fontSize: '14px',
            fontWeight: 400,
            fontFamily: 'Noto Sans',
          }}
        >
          {label_use_case}s
        </text>
        <text
          x={centerX}
          y={centerY + 20}
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            fontSize: '16px',
            fontWeight: 400,
            fontFamily: 'Noto Sans',
          }}
        >
          {totalUseCase}
        </text>
      </>
    );
  };

  const CenteredProjectMetric = ({ centerX, centerY }: any) => {
    return (
      <>
        <text
          x={centerX}
          y={centerY - 6}
          textAnchor="middle"
          fill="#7A7A7A"
          dominantBaseline="central"
          style={{
            fontSize: '14px',
            fontWeight: 300,
            fontFamily: 'Noto Sans',
          }}
        >
          {label_project}s
        </text>
        <text
          x={centerX}
          y={centerY + 20}
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            fontSize: '16px',
            fontWeight: 400,
            fontFamily: 'Noto Sans',
          }}
        >
          {totalProjects}
        </text>
      </>
    );
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [value1, setValue1] = useState(0);

  const handleChange1 = (event: React.SyntheticEvent, newValue: number) => {
    setValue1(newValue);
  };

  React.useEffect(() => {
    const tempVal: any = cookies.get('customerCompanyName');
    const jwtTokenLocal: any = cookies.get('jwt-token');
    setCompanyName(tempVal);
    setJwtToken(jwtTokenLocal);
    console.log(companyName, jwtTokenLocal);
    dispatch(getAllUsers());
    if (cookies.get('client_uid') as string == '' && useObjApi.length > 1) {
      navigate('/customer-portal/landing');
    }
  }, []);

  React.useEffect(() => {
    if (projectWithTaskApi && projectWithTaskApi.length > 0) setProjectsWithList(projectWithTaskApi);
  }, [projectWithTaskApi]);

  React.useEffect(() => {
    console.log(companyName, 'cpny nam');
    if (companyName?.length > 0) {
      dispatch(clientCheckClient(companyName));
    }
  }, [companyName]);

  React.useEffect(() => {
    console.log(jwtToken, 'cpny j nam');
    if (jwtToken?.length > 0) {
      dispatch(client_userSignIn_withjwttoken(jwtToken));
    }
  }, [jwtToken]);

  React.useEffect(() => {
    console.log(projectWithTaskApi, 'pro ject');
  }, [projectWithTaskApi]);

  React.useEffect(() => {
    if (cookies.get('client_uid') as string != '') {
      console.log('getalldata', cookies.get('client_uid') as string);
      const reqObj = {
        client_uid: cookies.get('client_uid'),
        customer_uid: cookies.get('cogni_customer_uid') as string,
      };
      dispatch(client_getProjectsWithTasks(reqObj.client_uid));
      dispatch(client_getProjectUpgradationAlert(reqObj.client_uid));
      dispatch(client_getUsecaseUpgradationAlert(reqObj.client_uid));
      dispatch(client_getProjectDegradationAlert(reqObj.client_uid));
      dispatch(client_getUsecaseDegradationAlert(reqObj.client_uid));
      dispatch(client_getProjectStatusCountByClientUid(reqObj.client_uid));
      dispatch(client_getTaskStatusCountByClientUid(reqObj.client_uid));
      dispatch(client_getUseCaseStatusCount(reqObj));
      dispatch(client_getClientinformationapi(reqObj.client_uid));
    } else {
      console.log('getalldata');
      getAllData();
    }
  }, [useObjApi]);

  const getAllData = async () => {
    const reqObj = {
      client_uid: useObjApi?.client_uid,
      customer_uid: cookies.get('cogni_customer_uid') as string,
    };
    if (useObjApi?.client_uid) {
      cookies.set('client_uid', useObjApi?.client_uid);
      await dispatch(client_getProjectsWithTasks(useObjApi && useObjApi?.client_uid));
      await dispatch(client_getProjectUpgradationAlert(useObjApi && useObjApi?.client_uid));
      await dispatch(client_getUsecaseUpgradationAlert(useObjApi && useObjApi?.client_uid));
      await dispatch(client_getProjectDegradationAlert(useObjApi && useObjApi?.client_uid));
      await dispatch(client_getUsecaseDegradationAlert(useObjApi && useObjApi?.client_uid));
      await dispatch(client_getProjectStatusCountByClientUid(useObjApi && useObjApi?.client_uid));
      await dispatch(client_getTaskStatusCountByClientUid(useObjApi && useObjApi?.client_uid));
      await dispatch(client_getUseCaseStatusCount(reqObj));
      await dispatch(client_getClientinformationapi(useObjApi && useObjApi?.client_uid));
    }
  };

  const handleChartClick = (data: any, chartType: 'project' | 'usecase' | 'task') => {
    const payload = Object.assign(data, { field: 'status' });
    if (chartType === 'project') {
      navigate('/customer-portal/project', { state: payload });
    }
  };

  return (
    <Box sx={{ mt: '10px', padding: '0px 20px' }}>
      <Box my={2} sx={{ paddingTop: '24px' }}>
        <Typography sx={{ fontSize: '20px', fontWeight: '500' }}>
          Welcome {cookies.get('client_user_username') as string}!
        </Typography>
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={9}>
          <Box sx={{ height: '236px' }}>
            <Paper
              // elevation={0}
              style={{
                padding: '20px',
                maxHeight: '236px',
                boxShadow: 'box-shadow: 0px 4px 26px 0px rgba(0, 0, 0, 0.05)',
                backgroundColor: '#FFFFFF',
              }}
            >
              <Grid container justifyContent="space-between" sx={{ mb: '16px' }}>
                <Grid item>
                  <Typography variant="h6">Status Overview</Typography>
                </Grid>
                <Grid item>
                  <Stack direction="row">
                    <CircleIcon fontSize="small" sx={{ mt: '7px', height: '12px', width: '12px', color: '#4390E1' }} />
                    <Typography sx={{ ml: '4px' }}>Completed</Typography>
                    <CircleIcon
                      fontSize="small"
                      sx={{ mt: '7px', height: '12px', width: '12px', color: '#E75B5C', marginLeft: '24px' }}
                    />
                    <Typography sx={{ ml: '4px' }}>Overdue</Typography>
                    <CircleIcon
                      fontSize="small"
                      sx={{ mt: '7px', height: '12px', width: '12px', color: '#EFA958', marginLeft: '24px' }}
                    />
                    <Typography sx={{ ml: '4px' }}>At Risk</Typography>
                    <CircleIcon
                      fontSize="small"
                      sx={{ mt: '7px', height: '12px', width: '12px', color: '#4F9F52', marginLeft: '24px' }}
                    />
                    <Typography sx={{ ml: '4px' }}>On track</Typography>
                  </Stack>
                </Grid>
              </Grid>
              <Grid container spacing={6}>
                <Grid item xs={4}>
                  <Grid container>
                    <Grid item xs={6}>
                      <DonutChart
                        height={200}
                        width={200}
                        data={dataTaskCountApi}
                        dataConfig={{
                          idKey: 'id',
                          dataKey: 'value',
                        }}
                        colors={
                          taskStatusCountApi == null || Object.keys(taskStatusCountApi).length === 0
                            ? emptyGraphColors
                            : graphColors
                        }
                        legend="none"
                        // onClick={(data: any) => handleChartClick(data, 'task')}
                        layers={['arcs', 'arcLabels', 'arcLinkLabels', 'legends', CenteredTaskMetric]}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Grid
                        container
                        spacing={0}
                        alignItems="center"
                        justifyContent="center"
                        sx={{ paddingTop: '30px', paddingLeft: '50px' }}
                      >
                        <Grid item xs={6}>
                          <Stack direction="row">
                            <Box
                              sx={{
                                backgroundColor: '#4390E1',
                                height: '17px',
                                width: '4px',
                                mt: '5px',
                                borderRadius: '4px',
                              }}
                            />
                            <Typography sx={{ mt: '1px', ml: '5px' }}>
                              {taskStatusCountApi?.blue > 0 ? taskStatusCountApi?.blue : 0}
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={6}>
                          <Stack direction="row">
                            <Box
                              sx={{
                                backgroundColor: '#E75B5C',
                                height: '17px',
                                width: '4px',
                                mt: '5px',
                                borderRadius: '4px',
                              }}
                            />
                            <Typography sx={{ mt: '1px', ml: '5px' }}>
                              {taskStatusCountApi?.red > 0 ? taskStatusCountApi?.red : 0}
                            </Typography>
                          </Stack>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={0}
                        alignItems="center"
                        justifyContent="center"
                        sx={{ paddingTop: '20px', paddingLeft: '50px' }}
                      >
                        <Grid item xs={6}>
                          <Stack direction="row">
                            <Box
                              sx={{
                                backgroundColor: '#EFA958',
                                height: '17px',
                                width: '4px',
                                mt: '5px',
                                borderRadius: '4px',
                              }}
                            />
                            <Typography sx={{ mt: '1px', ml: '5px' }}>
                              {taskStatusCountApi?.amber > 0 ? taskStatusCountApi?.amber : 0}
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={6}>
                          <Stack direction="row">
                            <Box
                              sx={{
                                backgroundColor: '#4F9F52',
                                height: '17px',
                                width: '4px',
                                mt: '5px',
                                borderRadius: '4px',
                              }}
                            />
                            <Typography sx={{ mt: '1px', ml: '5px' }}>
                              {taskStatusCountApi?.green > 0 ? taskStatusCountApi?.green : 0}
                            </Typography>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Grid container>
                    <Grid item xs={6}>
                      <DonutChart
                        height={200}
                        width={200}
                        data={dataUseCaseApi}
                        dataConfig={{
                          idKey: 'id',
                          dataKey: 'value',
                        }}
                        colors={
                          useCaseStatusCountApi === null || Object.keys(useCaseStatusCountApi).length === 0
                            ? emptyGraphColors
                            : graphColors
                        }
                        legend="none"
                        // onClick={(data: any) => handleChartClick(data, 'usecase')}
                        layers={['arcs', 'arcLabels', 'arcLinkLabels', 'legends', CenteredUsecaseMetric]}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Grid
                        container
                        spacing={0}
                        alignItems="center"
                        justifyContent="center"
                        sx={{ paddingTop: '30px', paddingLeft: '50px' }}
                      >
                        <Grid item xs={6}>
                          <Stack direction="row">
                            <Box
                              sx={{
                                backgroundColor: '#4390E1',
                                height: '17px',
                                width: '4px',
                                mt: '5px',
                                borderRadius: '4px',
                              }}
                            />
                            <Typography sx={{ mt: '1px', ml: '5px' }}>
                              {useCaseStatusCountApi?.blue > 0 ? useCaseStatusCountApi.blue : 0}
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={6}>
                          <Stack direction="row">
                            <Box
                              sx={{
                                backgroundColor: '#E75B5C',
                                height: '17px',
                                width: '4px',
                                mt: '5px',
                                borderRadius: '4px',
                              }}
                            />
                            <Typography sx={{ mt: '1px', ml: '5px' }}>
                              {useCaseStatusCountApi?.red > 0 ? useCaseStatusCountApi.red : 0}
                            </Typography>
                          </Stack>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={0}
                        alignItems="center"
                        justifyContent="center"
                        sx={{ paddingTop: '20px', paddingLeft: '50px' }}
                      >
                        <Grid item xs={6}>
                          <Stack direction="row">
                            <Box
                              sx={{
                                backgroundColor: '#EFA958',
                                height: '17px',
                                width: '4px',
                                mt: '5px',
                                borderRadius: '4px',
                              }}
                            />
                            <Typography sx={{ mt: '1px', ml: '5px' }}>
                              {useCaseStatusCountApi?.amber > 0 ? useCaseStatusCountApi.amber : 0}
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={6}>
                          <Stack direction="row">
                            <Box
                              sx={{
                                backgroundColor: '#4F9F52',
                                height: '17px',
                                width: '4px',
                                mt: '5px',
                                borderRadius: '4px',
                              }}
                            />
                            <Typography sx={{ mt: '1px', ml: '5px' }}>
                              {useCaseStatusCountApi?.green > 0 ? useCaseStatusCountApi.green : 0}
                            </Typography>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Grid container>
                    <Grid item xs={6}>
                      <DonutChart
                        height={200}
                        width={200}
                        data={dataProjectApi}
                        dataConfig={{
                          idKey: 'id',
                          dataKey: 'value',
                        }}
                        colors={
                          projectStatusCountApi === null || Object.keys(projectStatusCountApi).length === 0
                            ? emptyGraphColors
                            : graphColors
                        }
                        legend="none"
                        onClick={(data: any) => handleChartClick(data, 'project')}
                        // onClick={(data: any) => handleChartClick(data, 'project')}
                        layers={['arcs', 'arcLabels', 'arcLinkLabels', 'legends', CenteredProjectMetric]}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Grid
                        container
                        spacing={0}
                        alignItems="center"
                        justifyContent="center"
                        sx={{ paddingTop: '30px', paddingLeft: '50px' }}
                      >
                        <Grid item xs={6}>
                          <Stack direction="row">
                            <Box
                              sx={{
                                backgroundColor: '#4390E1',
                                height: '17px',
                                width: '4px',
                                mt: '5px',
                                borderRadius: '4px',
                              }}
                            />
                            <Typography sx={{ mt: '1px', ml: '5px' }}>
                              {projectStatusCountApi?.blue > 0 ? projectStatusCountApi?.blue : 0}
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={6}>
                          <Stack direction="row">
                            <Box
                              sx={{
                                backgroundColor: '#E75B5C',
                                height: '17px',
                                width: '4px',
                                mt: '5px',
                                borderRadius: '4px',
                              }}
                            />
                            <Typography sx={{ mt: '1px', ml: '5px' }}>
                              {projectStatusCountApi?.red > 0 ? projectStatusCountApi?.red : 0}
                            </Typography>
                          </Stack>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={0}
                        alignItems="center"
                        justifyContent="center"
                        sx={{ paddingTop: '20px', paddingLeft: '50px' }}
                      >
                        <Grid item xs={6}>
                          <Stack direction="row">
                            <Box
                              sx={{
                                backgroundColor: '#EFA958',
                                height: '17px',
                                width: '4px',
                                mt: '5px',
                                borderRadius: '4px',
                              }}
                            />
                            <Typography sx={{ mt: '1px', ml: '5px' }}>
                              {projectStatusCountApi?.amber > 0 ? projectStatusCountApi?.amber : 0}
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={6}>
                          <Stack direction="row">
                            <Box
                              sx={{
                                backgroundColor: '#4F9F52',
                                height: '17px',
                                width: '4px',
                                mt: '5px',
                                borderRadius: '4px',
                              }}
                            />
                            <Typography sx={{ mt: '1px', ml: '5px' }}>
                              {projectStatusCountApi?.green > 0 ? projectStatusCountApi?.green : 0}
                            </Typography>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Box>
          <Box my={2}>
            <Typography variant="h6">{label_project}</Typography>
          </Box>
          <ClientTimeline projectsWithList={projectsWithList} />
        </Grid>
        <Grid item xs={3}>
          <Paper elevation={0} sx={{ height: '528px', width: '100%' }}>
            <Box sx={{ padding: '0px 20px 20px 20px' }}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography sx={{ marginTop: '10px' }}>Alerts</Typography>
                </Grid>
                <Grid item>
                  <Tabs
                    style={{ paddingBottom: '10px' }}
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab label={`${label_project}s`} {...a11yProps(0)} />
                    <Tab label={`${label_use_case}`} {...a11yProps(1)} />
                  </Tabs>
                </Grid>
              </Grid>
              <TabPanel value={value} index={0}>
                {projectDegradationApi?.length > 0 ? (
                  projectDegradationApi.map((ele: any, i: any) => {
                    // console.log(ele, 'energy');
                    return (
                      <CognisaasCard
                        key={i}
                        clientStatusColor={ele.project_readinessstate_status}
                        height="140px"
                        width="270px"
                      >
                        <Box sx={{ padding: '20px' }}>
                          <Typography
                            className="cognisaas-pointer"
                            onClick={() =>
                              navigate('/customer-portal/project/dashboard', { state: { selectedProject: ele } })
                            }
                            variant="subtitle1"
                          >
                            {ele.project_name}
                          </Typography>
                          <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#7A7A7A', marginTop: '8px' }}>
                            {format(new Date(ele?.trail_date.replace(/-/g, '/')), 'dd MMM yyyy')}
                          </Typography>

                          {ele.new_value === 'amber' ? (
                            <>
                              <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#7A7A7A' }}>
                                Status changed from
                              </Typography>
                              <Stack direction="row">
                                <CircleIcon
                                  sx={{
                                    color: '#4F9F52',
                                    height: '8px',
                                    width: '8px',
                                    marginTop: '5px',
                                  }}
                                />
                                <Typography sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}>
                                  On Track
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: '12px',
                                    fontWeight: '400',
                                    marginLeft: '13px',
                                    color: '#7A7A7A',
                                  }}
                                >
                                  to
                                </Typography>
                                <CircleIcon
                                  sx={{
                                    color: '#EFA958',
                                    height: '8px',
                                    width: '8px',
                                    marginTop: '5px',
                                    marginLeft: '12px',
                                  }}
                                />
                                <Typography sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}>
                                  At Risk
                                </Typography>
                              </Stack>
                            </>
                          ) : null}
                          {ele.new_value === 'red' ? (
                            <>
                              <Stack direction="row" sx={{ mt: '20px' }}>
                                <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#2D60F6' }}>
                                  {ele.project_name}
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: '12px',
                                    fontWeight: '400',
                                    color: '#7A7A7A',
                                    ml: '8px',
                                  }}
                                >
                                  is
                                </Typography>
                                <CircleIcon
                                  sx={{
                                    color: '#E75B5C',
                                    height: '8px',
                                    width: '8px',
                                    marginTop: '5px',
                                    ml: '8px',
                                  }}
                                />
                                <Typography sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}>
                                  Overdue
                                </Typography>
                              </Stack>
                            </>
                          ) : null}
                        </Box>
                      </CognisaasCard>
                    );
                  })
                ) : (
                  <Stack justifyContent="center" alignItems="center">
                    <NoRecords
                      style={{ marginLeft: '6px', marginTop: '10px', width: '150px', height: '150px' }}
                    ></NoRecords>
                  </Stack>
                )}
              </TabPanel>
              <TabPanel value={value} index={1}>
                {useCaseDegradationApi?.length > 0 ? (
                  useCaseDegradationApi.map((ele: any, i: any) => {
                    return (
                      <CognisaasCard
                        key={i}
                        clientStatusColor={ele.case_readinessstate_status}
                        height="140px"
                        width="270px"
                      >
                        <Box sx={{ padding: '20px' }}>
                          <Typography>{ele.case_name}</Typography>
                          <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#7A7A7A', marginTop: '8px' }}>
                            {format(new Date(ele?.trail_date.replace(/-/g, '/')), 'dd MMM yyyy')}
                          </Typography>
                          {ele.new_value === 'amber' ? (
                            <>
                              <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#7A7A7A' }}>
                                Status changed from
                              </Typography>
                              <Stack direction="row">
                                <CircleIcon
                                  sx={{
                                    color: '#4F9F52',
                                    height: '8px',
                                    width: '8px',
                                    marginTop: '5px',
                                  }}
                                />
                                <Typography sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}>
                                  On Track
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: '12px',
                                    fontWeight: '400',
                                    marginLeft: '13px',
                                    color: '#7A7A7A',
                                  }}
                                >
                                  to
                                </Typography>
                                <CircleIcon
                                  sx={{
                                    color: '#EFA958',
                                    height: '8px',
                                    width: '8px',
                                    marginTop: '5px',
                                    marginLeft: '12px',
                                  }}
                                />
                                <Typography sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}>
                                  At Risk
                                </Typography>
                              </Stack>
                            </>
                          ) : null}
                          {ele.new_value === 'red' ? (
                            <>
                              <Stack direction="row" sx={{ mt: '20px' }}>
                                <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#2D60F6' }}>
                                  {ele.case_name}
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: '12px',
                                    fontWeight: '400',
                                    color: '#7A7A7A',
                                    ml: '8px',
                                  }}
                                >
                                  is
                                </Typography>
                                <CircleIcon
                                  sx={{
                                    color: '#E75B5C',
                                    height: '8px',
                                    width: '8px',
                                    marginTop: '5px',
                                    ml: '8px',
                                  }}
                                />
                                <Typography sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}>
                                  Overdue
                                </Typography>
                              </Stack>
                            </>
                          ) : null}
                        </Box>
                      </CognisaasCard>
                    );
                  })
                ) : (
                  <Stack justifyContent="center" alignItems="center">
                    <NoRecords
                      style={{ marginLeft: '6px', marginTop: '10px', width: '150px', height: '150px' }}
                    ></NoRecords>
                  </Stack>
                )}
              </TabPanel>
            </Box>
          </Paper>
          <Paper elevation={0} sx={{ height: '528px', marginTop: '20px', width: '100%' }}>
            <Box sx={{ padding: '0px 20px 20px 20px' }}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography sx={{ marginTop: '10px' }}>Progress</Typography>
                </Grid>
                <Grid item>
                  <Tabs
                    style={{ paddingBottom: '10px' }}
                    value={value1}
                    onChange={handleChange1}
                    aria-label="basic tabs example"
                  >
                    <Tab label="Projects" {...a11yProps(0)} />
                    <Tab label="Usecase" {...a11yProps(1)} />
                  </Tabs>
                </Grid>
              </Grid>
              <TabPanel value={value1} index={0}>
                {projectProgressApi?.length > 0 ? (
                  projectProgressApi.map((ele: any, i: any) => {
                    return (
                      <CognisaasCard
                        key={i}
                        clientStatusColor={ele.project_readinessstate_status}
                        height="140px"
                        width="270px"
                      >
                        <Box sx={{ padding: '20px' }}>
                          <Typography
                            className="cognisaas-pointer"
                            onClick={() =>
                              navigate('/customer-portal/project/dashboard', { state: { selectedProject: ele } })
                            }
                          >
                            {ele.project_name}
                          </Typography>
                          <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#7A7A7A', marginTop: '8px' }}>
                            {format(new Date(ele?.trail_date.replace(/-/g, '/')), 'dd MMM yyyy')}
                          </Typography>
                          {ele.new_value === 'amber' && (
                            <>
                              <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#7A7A7A' }}>
                                Status changed from
                              </Typography>
                              <Stack direction="row">
                                <CircleIcon
                                  sx={{
                                    color: '#E75B5C',
                                    height: '8px',
                                    width: '8px',
                                    marginTop: '5px',
                                  }}
                                />
                                <Typography sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}>
                                  Overdue
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: '12px',
                                    fontWeight: '400',
                                    marginLeft: '13px',
                                    color: '#7A7A7A',
                                  }}
                                >
                                  to
                                </Typography>
                                <CircleIcon
                                  sx={{
                                    color: '#EFA958',
                                    height: '8px',
                                    width: '8px',
                                    marginTop: '5px',
                                    marginLeft: '12px',
                                  }}
                                />
                                <Typography sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}>
                                  At Risk
                                </Typography>
                              </Stack>
                            </>
                          )}
                          {ele.new_value === 'green' && (
                            <>
                              <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#7A7A7A' }}>
                                Status changed from
                              </Typography>
                              <Stack direction="row">
                                <CircleIcon
                                  sx={{
                                    color: '#EFA958',
                                    height: '8px',
                                    width: '8px',
                                    marginTop: '5px',
                                  }}
                                />
                                <Typography sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}>
                                  At Risk
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: '12px',
                                    fontWeight: '400',
                                    marginLeft: '13px',
                                    color: '#7A7A7A',
                                  }}
                                >
                                  to
                                </Typography>
                                <CircleIcon
                                  sx={{
                                    color: '#4F9F52',
                                    height: '8px',
                                    width: '8px',
                                    marginTop: '5px',
                                    marginLeft: '12px',
                                  }}
                                />
                                <Typography sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}>
                                  On Track
                                </Typography>
                              </Stack>
                            </>
                          )}
                          {ele.new_value === 'blue' && (
                            <>
                              <Stack direction="row">
                                <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#2D60F6' }}>
                                  {ele.project_name}
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: '12px',
                                    fontWeight: '400',
                                    color: '#7A7A7A',
                                    ml: '8px',
                                  }}
                                >
                                  is
                                </Typography>
                                <CircleIcon
                                  sx={{
                                    color: '#4390E1',
                                    height: '8px',
                                    width: '8px',
                                    marginTop: '5px',
                                    ml: '8px',
                                  }}
                                />
                                <Typography sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}>
                                  Completed!
                                </Typography>
                              </Stack>
                            </>
                          )}
                        </Box>
                      </CognisaasCard>
                    );
                  })
                ) : (
                  <Stack justifyContent="center" alignItems="center">
                    <NoRecords
                      style={{ marginLeft: '6px', marginTop: '10px', width: '150px', height: '150px' }}
                    ></NoRecords>
                  </Stack>
                )}
              </TabPanel>
              <TabPanel value={value1} index={1}>
                {useCaseProgressApi?.length > 0 ? (
                  useCaseProgressApi.map((ele: any, i: any) => {
                    return (
                      <CognisaasCard
                        key={i}
                        clientStatusColor={ele.case_readinessstate_status}
                        height="140px"
                        width="270px"
                      >
                        <Box sx={{ padding: '20px' }}>
                          <Typography>{ele.case_name}</Typography>
                          <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#7A7A7A', marginTop: '8px' }}>
                            {format(new Date(ele?.trail_date.replace(/-/g, '/')), 'dd MMM yyyy')}
                          </Typography>
                          {ele.new_value === 'amber' && (
                            <>
                              <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#7A7A7A' }}>
                                Status changed from
                              </Typography>
                              <Stack direction="row">
                                <CircleIcon
                                  sx={{
                                    color: '#E75B5C',
                                    height: '8px',
                                    width: '8px',
                                    marginTop: '5px',
                                  }}
                                />
                                <Typography sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}>
                                  Overdue
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: '12px',
                                    fontWeight: '400',
                                    marginLeft: '13px',
                                    color: '#7A7A7A',
                                  }}
                                >
                                  to
                                </Typography>
                                <CircleIcon
                                  sx={{
                                    color: '#EFA958',
                                    height: '8px',
                                    width: '8px',
                                    marginTop: '5px',
                                    marginLeft: '12px',
                                  }}
                                />
                                <Typography sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}>
                                  At Risk
                                </Typography>
                              </Stack>
                            </>
                          )}
                          {ele.new_value === 'green' && (
                            <>
                              <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#7A7A7A' }}>
                                Status changed from
                              </Typography>
                              <Stack direction="row">
                                <CircleIcon
                                  sx={{
                                    color: '#EFA958',
                                    height: '8px',
                                    width: '8px',
                                    marginTop: '5px',
                                  }}
                                />
                                <Typography sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}>
                                  At Risk
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: '12px',
                                    fontWeight: '400',
                                    marginLeft: '13px',
                                    color: '#7A7A7A',
                                  }}
                                >
                                  to
                                </Typography>
                                <CircleIcon
                                  sx={{
                                    color: '#4F9F52',
                                    height: '8px',
                                    width: '8px',
                                    marginTop: '5px',
                                    marginLeft: '12px',
                                  }}
                                />
                                <Typography sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}>
                                  On Track
                                </Typography>
                              </Stack>
                            </>
                          )}
                          {ele.new_value === 'blue' && (
                            <>
                              <Stack direction="row">
                                <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#2D60F6' }}>
                                  {ele.case_name}
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: '12px',
                                    fontWeight: '400',
                                    color: '#7A7A7A',
                                    ml: '8px',
                                  }}
                                >
                                  is
                                </Typography>
                                <CircleIcon
                                  sx={{
                                    color: '#4390E1',
                                    height: '8px',
                                    width: '8px',
                                    marginTop: '5px',
                                    ml: '8px',
                                  }}
                                />
                                <Typography sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}>
                                  Completed!
                                </Typography>
                              </Stack>
                            </>
                          )}
                        </Box>
                      </CognisaasCard>
                    );
                  })
                ) : (
                  <Stack justifyContent="center" alignItems="center">
                    <NoRecords
                      style={{ marginLeft: '6px', marginTop: '10px', width: '150px', height: '150px' }}
                    ></NoRecords>
                  </Stack>
                )}
              </TabPanel>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
