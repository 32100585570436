import { LoadingButton } from '@mui/lab';
import { Button, ButtonProps } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

interface Props extends ButtonProps {
  label: string;
  isOutlined: boolean;
  clickHandler?: () => void;
  sx?: any;
  disabled?: boolean;
  type?: 'submit' | 'button' | 'reset';
  isLoading?: boolean;
}

const CognisaasButtonWithLoading: React.FC<Props> = (props: Props) => {
  return props.isLoading ? (
    <LoadingButton
      // sx={{ width: '77px' }}
      loading
      loadingPosition="start"
      startIcon={<SaveIcon />}
      variant="outlined"
    >
      {props.label}
    </LoadingButton>
  ) : (
    <Button
      disabled={props.disabled == true}
      onClick={props?.clickHandler}
      disableElevation
      variant={props.isOutlined ? 'outlined' : 'contained'}
      color="primary"
      sx={props.sx}
    >
      {props.label}
    </Button>
  );
};

export default CognisaasButtonWithLoading;
