import {
  TextField,
  Grid,
  Typography,
  Switch,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  Divider,
  useMediaQuery,
  Stack,
  // Snackbar,
  // Alert,
  // AlertColor,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { ReactComponent as GoBack } from '../../../assets/svgs/go_back_arrow.svg';
import { useDispatch } from 'react-redux';
import {
  deleteExistingProduct_slice,
  saveJiraCredentials_slice,
  updateJiraFetchMode_slice,
  updateJiraTaskMode_slice,
  getUserDetails_slice,
  updateJiraDetailsToCustomer,
  updateJiraAccountIdForUsers,
  addJiraProjectKeyToCustomer,
  getJiraProjectsFromApi,
  addJiraCustomProductStageCustomStage,
  fetchProjectAddedToCustomerAndJira,
  deleteCustomerJiraProject,
  removeJiraToken,
  removeJiraLinkFromTask,
  deleteWholeCustomerProduct,
  getJiraStatusByJiraProjectKey,
  getJiraServiceDesksByJiraProjectKey,
} from '../../../store/modules/Settings/slice';
import { useAppSelector } from '../../../store/hooks';
import CognisaasLoader from '../../shared/CognisaasLoader/CognisaasLoader';
import {
  getUserData_selector,
  selectAddedJiraProjectKeyData,
  selectAllJiraProjectsData,
  selectAlreadyAddedJiraProjectsData,
  selectDeletedJiraProjectKeyData,
  selectJiraServiceDeskAccounts,
  selectJiraStatuses,
} from '../../../store/modules/Settings/selector';
import BasicModal from '../../shared/CognisaasModal/CognisaasModal';
import theme from '../../../plugins/materialUI';
import CognisaasButton from '../../shared/CognisaasButton/CognisaasButton';
import CognisaasCheckBox from '../../shared/CognisaasCheckbox/CognisaasCheckbox';
import CogniTable, { CustomTextlinkCell, MenuCell } from '../SettingsTable/Table';
import { Box } from '@mui/system';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  // selectJiraCustomFieldsState,
  selectJiraFullCustomFieldsState,
  selectPrAndReqDataState,
} from '../../../store/modules/ProductRequirements/ProductDetails/selector';
import {
  deleteProduct,
  fetchProductAndRequirements,
  getJiraCustomFields,
} from '../../../store/modules/ProductRequirements/ProductDetails/slice';
// import MapJiraStatusModal from './MapJiraStatus/MapJiraStatusModal';
import MapProductJiraStatusModal from './MapJiraStatus/MapProductJiraStatusModal';
import MapJiraTaskStatusModal from './MapJiraStatus/MapTaskJiraStatusModal';
import Cookies from 'universal-cookie';
// import CognisaasSelect from 'components/shared/CognisaasSelect/CognisaasSelect';
import CognisaasMultiSelectWithSearchUid from 'components/shared/CognisaasMultiSelectWithSearch/CognisaasMultiSelectWithSearchUid';
import { getCustomerUid } from 'utils/getUid';
// import { requestState } from 'store/modules/types';
import { jiraAPI } from 'api/modules/jira';
import CognisaasToast from 'components/shared/CognisaasToast/CognisaasToast';
import { useNavigate, useParams } from 'react-router-dom';
import { label_project, label_task } from '../Preferences/CustomLabelCookieHelper';
import DeleteModal from 'components/shared/CognisaasModal/DeleteModal';

const cookies = new Cookies();

// interface modalPayload {
//   openAddKeyModal: boolean;
//   onOpenAddKeyModal: (payload: boolean) => void;
//   onClickOkModal: (key: string) => void;
// }

const Integration = () => {
  const dispatch = useDispatch();
  const [integrationSwitch, setIntegrationSwitch] = useState(false);
  // const [openAddKeyModal, setOpenAddKeyModal] = useState(false);
  const [jiraUsername, setJiraUsername] = useState('');
  const [jiraAPI_Token, setJiraAPI_Token] = useState('');
  const [jiraCompany_URL, setJiraCompany_URL] = useState('');
  const [jiraFetchModeRadio, setJiraFetchModeRadio] = useState('push');
  const [jiraTaskModeRadio, setJiraTAskModeRadio] = useState('pushToJira');
  const userDetailsState = useAppSelector(getUserData_selector);
  const biggerScreens = useMediaQuery(theme.breakpoints.up('md'));
  const [shouldToggleJiraIntegration, setShouldToggleJiraIntegration] = useState<boolean>(false);
  const allJiraProjectsState = useAppSelector(selectAllJiraProjectsData);
  const [alertDetails, setAlertDetails] = useState<{
    isVisible: boolean;
    type: 'success' | 'failure';
    message: string;
  }>({ isVisible: false, type: 'success', message: '' });
  const projectsAlreadyAddedToJira = useAppSelector(selectAlreadyAddedJiraProjectsData);
  const allProductsAndTheirRequirements = useAppSelector(selectPrAndReqDataState);
  //   modals
  const [confirmTurnOnJiraModal, setConfirmTurnOnJiraModal] = useState(false);
  const [confirmTurnOffJiraModal, setConfirmTurnOffJiraModal] = useState(false);
  const [addProjectKeyForProReqModal, setaddProjectKeyForProReqModal] = useState<boolean>(false);
  const [addProjectKeyForTaskModal, setAddProjectKeyForTaskModal] = useState<boolean>(false);
  const [projectKeyForproductReqMapping, setProjectKeyForproductReqMapping] = useState<string>('');
  const [isProjectKeyOneToMany, setIsProjectKeyOneToMany] = useState<boolean>(false);

  const [openJiraStatusMappingForProductReq, setOpenJiraStatusMappingForProductReq] = useState<boolean>(false);
  const [openJiraStatusMappingForTasks, setOpenJiraStatusMappingForTasks] = useState<boolean>(false);
  const [selectedProjectkey, setSelectedProjectkey] = useState('');
  const newlyAddedJiraProjectKeyData = useAppSelector(selectAddedJiraProjectKeyData);
  const deletedJiraProjectKeyData = useAppSelector(selectDeletedJiraProjectKeyData);
  // const [getRequiredFieldsForPullModal, setGetRequiredFieldsForPullModal] = useState<boolean>(false);
  const [isNextSectionInProjectAddVisible, setIsNextSectionInProjectAddVisible] = useState<boolean>(false);
  const [availableJiraIssuetypesOptions, setAvailableJiraIssuetypesOptions] = useState<any[]>([]);
  const [selectedJiraIssueTypes, setSelectedJiraIssueTypes] = useState<any[]>([]);
  const [availableJiraStatusOptions, setAvailableJiraStatusOptions] = useState<any[]>([]);
  const [selectedJiraIssueStatuses, setSelectedJiraIssueStatuses] = useState<any[]>([]);
  const jiraCustomFields = useAppSelector(selectJiraFullCustomFieldsState);
  const jiraStatuses = useAppSelector(selectJiraStatuses);
  const jiraServiceDeskProjects = useAppSelector(selectJiraServiceDeskAccounts);
  const navigate = useNavigate();
  const params = useParams();
  const accessType = params.type as string;
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [selectedRowForProjectKeyDeletion, setSelectedRowForProjectKeyDeletion] = useState<any>({});

  useEffect(() => {
    const user_uid = cookies.get('cogni_user_uid');
    dispatch(getUserDetails_slice(user_uid as string));
    dispatch(fetchProductAndRequirements());
  }, []);

  useEffect(() => {
    // console.log(jiraCustomFields);
    if (jiraCustomFields.data.projects) {
      const tempIssuesPair = jiraCustomFields.data.projects[0].issuetypes.map((item: any) => ({
        id: item.id,
        name: item.name,
      }));
      setAvailableJiraIssuetypesOptions(tempIssuesPair);
    }
  }, [jiraCustomFields.data]);

  useEffect(() => {
    console.log(jiraStatuses);
    if (jiraStatuses.data.length > 0) {
      const tempStatuses = jiraStatuses.data[0].statuses.map((item: any) => ({ id: item.id, name: item.name }));
      setAvailableJiraStatusOptions(tempStatuses);
      console.log(tempStatuses);
    }
  }, [jiraStatuses.data]);

  // dispatch(
  //   getJiraServiceDesksByJiraProjectKey({
  //     customer_uid: getCustomerUid(),
  //     key: btoa(btoa(`${userDetailsState.data.customer_jira_user}:${userDetailsState.data.customer_jira_token}`)),
  //   })
  // );

  useEffect(() => {
    if (jiraTaskModeRadio == 'pushToJira_ServiceDesk') {
      console.log('getting jira tasks');
      dispatch(
        getJiraServiceDesksByJiraProjectKey({
          customer_uid: getCustomerUid(),
          key: btoa(btoa(`${userDetailsState.data.customer_jira_user}:${userDetailsState.data.customer_jira_token}`)),
        })
      );
    }
  }, [jiraTaskModeRadio]);

  // console.log('jiraaaaaaaa', userDetailsState);

  useEffect(() => {
    if (userDetailsState.status === 'success') {
      console.log(userDetailsState.data);
      if (userDetailsState.data.customer_jira_api_url.length === 0) {
        // jira is disabled
        setIntegrationSwitch(false);
      } else {
        // jira is enabled
        setIntegrationSwitch(true);
        setJiraUsername(userDetailsState.data.customer_jira_user);
        setJiraAPI_Token(userDetailsState.data.customer_jira_token);
        setJiraCompany_URL(userDetailsState.data.customer_jira_api_url);
        setJiraFetchModeRadio(userDetailsState.data.customer_jira_fetch_mode);
        setJiraTAskModeRadio(userDetailsState.data.customer_JSD === '1' ? 'pushToJira_ServiceDesk' : 'pushToJira');

        // get project keys from jira maybe we need for adding projects
        if (allJiraProjectsState.status !== 'success' && allJiraProjectsState.status !== 'loading') {
          const customer_uid = cookies.get('cogni_customer_uid');
          dispatch(
            getJiraProjectsFromApi({
              customer_uid: customer_uid ? customer_uid : '',
              key: btoa(
                btoa(`${userDetailsState.data.customer_jira_user}:${userDetailsState.data.customer_jira_token}`)
              ),
            })
          );
          dispatch(fetchProjectAddedToCustomerAndJira());
        }
      }
    }
  }, [userDetailsState]);

  useEffect(() => {
    // console.log(integrationSwitch);
    const customer_uid = cookies.get('cogni_customer_uid');
    if (shouldToggleJiraIntegration) {
      dispatch(deleteExistingProduct_slice(customer_uid ? customer_uid : ''));
      setShouldToggleJiraIntegration(false);
      deleteCustomerProjectHandler();
    }
  }, [shouldToggleJiraIntegration]);

  const onChangeIntegrationSwitch = () => {
    if (integrationSwitch) {
      setConfirmTurnOffJiraModal(true);
    } else {
      setConfirmTurnOnJiraModal(true);
    }
  };

  const handleMapJiraStatusToProductRequirementButton = (rowData: Record<string, any>) => {
    // console.log(rowData, 'rowdata');
    setSelectedProjectkey(rowData.project_key);
    setOpenJiraStatusMappingForProductReq(true);
  };

  const handleMapJiraStatusToTasksButton = (rowData: Record<string, any>) => {
    // console.log(rowData, 'rowdata');
    setSelectedProjectkey(rowData.project_key);
    setOpenJiraStatusMappingForTasks(true);
  };

  const onSaveJiraCredentials = () => {
    dispatch(
      saveJiraCredentials_slice({
        key: btoa(btoa(`${jiraUsername}:${jiraAPI_Token}`)),
        url: jiraCompany_URL,
      })
    );
    const customer_uid = cookies.get('cogni_customer_uid');
    dispatch(
      updateJiraDetailsToCustomer({
        customer_jira_api_url: jiraCompany_URL,
        customer_jira_token: jiraAPI_Token,
        customer_jira_user: jiraUsername,
        customer_uid: customer_uid ? customer_uid : '',
      })
    );
    dispatch(
      updateJiraAccountIdForUsers({
        customer_uid: customer_uid ? customer_uid : '',
        key: btoa(btoa(`${jiraUsername}:${jiraAPI_Token}`)),
      })
    );
    const user_uid = cookies.get('cogni_user_uid');
    setTimeout(() => {
      dispatch(getUserDetails_slice(user_uid as string));
    }, 1500);
  };

  const onJiraCredential_cancel = () => {
    setJiraUsername('');
    setJiraAPI_Token('');
    setJiraCompany_URL('');
  };

  const fetchModeRadioChange = (e: any) => {
    const customer_uid = cookies.get('cogni_customer_uid');

    const jiraProjectKeysMappedAsProduct = projectsAlreadyAddedToJira.data.filter((item) => item.task_ind === '0');

    if (jiraProjectKeysMappedAsProduct.length > 0) {
      setAlertDetails({
        isVisible: true,
        type: 'failure',
        message: 'Delete All Product requirement Mapped Keys First!',
      });
    } else {
      deleteCustomerProjectHandler();
      dispatch(
        updateJiraFetchMode_slice({
          customer_jira_fetch_mode: e.target.value,
          customer_uid: customer_uid ? customer_uid : '',
        })
      );
      setJiraFetchModeRadio(e.target.value);
      setAlertDetails({ isVisible: true, type: 'success', message: 'Fetch Mode Changed Successfully!.' });
    }
  };

  const jiraTaskMode_OnChange = (e: any) => {
    if (
      projectsAlreadyAddedToJira.data.filter((item) => item.task_ind === '1').length > 0 ||
      projectsAlreadyAddedToJira.data.filter((item) => item.jira_service_desk_id === '1').length > 0
    ) {
      setAlertDetails({
        isVisible: true,
        type: 'failure',
        message: `Delete All ${label_task} Mapped Keys First!`,
      });
    } else {
      setJiraTAskModeRadio(e.target.value);
      const customer_uid = cookies.get('cogni_customer_uid');
      dispatch(
        updateJiraTaskMode_slice({
          customer_JSD: e.target.value == 'pushToJira' ? false : true,
          customer_uid: customer_uid ? customer_uid : '',
        })
      );
      dispatch(
        updateJiraTaskMode_slice({
          customer_JSD: e.target.value == 'pushToJira' ? false : true,
          customer_uid: customer_uid ? customer_uid : '',
        })
      );

      setAlertDetails({ isVisible: true, type: 'success', message: `Jira ${label_task} Mode Changed Successfully!` });
    }
  };

  const doesProjectKeyPresetOnJira = (intendedProjectKey: string) => {
    const jiraProjectKeyData = JSON.parse(allJiraProjectsState.data.result);
    const filteredJiraProjectKeyData = jiraProjectKeyData.values.filter(
      (project: any) => project.key === intendedProjectKey
    );
    // console.log(filteredJiraProjectKeyData);
    console.log(projectsAlreadyAddedToJira.data);

    const filteredAlreadyAddedToJiraProjectKeys = projectsAlreadyAddedToJira.data.filter(
      (item) => item.project_key === intendedProjectKey
    );

    if (filteredAlreadyAddedToJiraProjectKeys.length > 0) {
      setAlertDetails({ isVisible: true, type: 'failure', message: 'Project key Already Attached!' });
      return null;
    } else {
      // do nothing go forward
      if (filteredJiraProjectKeyData.length > 0) {
        // check if this is normal or service desk
        if (filteredJiraProjectKeyData[0].projectTypeKey === 'service_desk') {
          setAlertDetails({
            isVisible: true,
            type: 'failure',
            message: 'Project key is of Service Desk, Not Allowed here!',
          });
          return null;
        }
        return filteredJiraProjectKeyData[0];
      } else {
        setAlertDetails({ isVisible: true, type: 'failure', message: 'Project key Not Found on Jira!' });
        return null;
      }
    }

    // check for if the project key already added
  };

  const handleAddProductRequirementJiraProjectKey = (type: 'product' | 'task') => {
    const customer_uid = cookies.get('cogni_customer_uid');

    if (jiraTaskModeRadio === 'pushToJira_ServiceDesk' && type === 'task') {
      if (jiraTaskModeRadio === 'pushToJira_ServiceDesk') {
        console.log(
          'jiraServiceDeskProjects.data.values',
          jiraServiceDeskProjects.data.values,
          projectKeyForproductReqMapping
        );

        if (jiraServiceDeskProjects.data.length === 0) {
          setAlertDetails({ isVisible: true, type: 'failure', message: 'Not Loaded Properly, Please Reload!' });
        } else {
          const jiraCurrentServiceDeskProjects = [...jiraServiceDeskProjects.data.values];
          const tempProjectInServiceDesk = jiraCurrentServiceDeskProjects.filter(
            (item: any) => item.projectKey === projectKeyForproductReqMapping
          );
          if (tempProjectInServiceDesk.length > 0) {
            dispatch(
              addJiraProjectKeyToCustomer({
                custom_field: '',
                custom_field_name: '',
                customer_uid: customer_uid ? customer_uid : '',
                jira_project_id: tempProjectInServiceDesk[0].projectId,
                jira_project_name: tempProjectInServiceDesk[0].projectName,
                jira_service_desk_id: tempProjectInServiceDesk[0].id,
                oneTomany: isProjectKeyOneToMany,
                project_key: tempProjectInServiceDesk[0].projectKey,
                pull_issuetype: '',
                pull_status: '',
                task_ind: 1,
              })
            );

            dispatch(
              addJiraCustomProductStageCustomStage({
                customer_uid: customer_uid ? customer_uid : '',
                key: btoa(btoa(`${jiraUsername}:${jiraAPI_Token}`)),
                project_key: tempProjectInServiceDesk[0].projectKey,
              })
            );

            setaddProjectKeyForProReqModal(false);
            setAddProjectKeyForTaskModal(false);
            setSelectedJiraIssueTypes([]);
            setSelectedJiraIssueStatuses([]);
            setIsNextSectionInProjectAddVisible(false);
          } else {
            setAlertDetails({ isVisible: true, type: 'failure', message: 'This is Not a Service Desk Key.' });
          }
        }
      }
    } else {
      const foundProjectKeyDetails = doesProjectKeyPresetOnJira(projectKeyForproductReqMapping);
      if (foundProjectKeyDetails) {
        // custom fields will come when its one to many
        dispatch(
          addJiraProjectKeyToCustomer({
            custom_field: '',
            custom_field_name: '',
            customer_uid: customer_uid ? customer_uid : '',
            jira_project_id: foundProjectKeyDetails.id,
            jira_project_name: foundProjectKeyDetails.name,
            jira_service_desk_id: null,
            oneTomany: isProjectKeyOneToMany,
            project_key: foundProjectKeyDetails.key,
            pull_issuetype: selectedJiraIssueTypes.join(','),
            pull_status: selectedJiraIssueStatuses.join(','),
            task_ind: type === 'product' ? 0 : 1,
          })
        );

        dispatch(
          addJiraCustomProductStageCustomStage({
            customer_uid: customer_uid ? customer_uid : '',
            key: btoa(btoa(`${jiraUsername}:${jiraAPI_Token}`)),
            project_key: foundProjectKeyDetails.key,
          })
        );

        // setTimeout(() => {
        //   dispatch(fetchProjectAddedToCustomerAndJira());
        // }, 1500);
        // setAlertDetails({ isVisible: true, severity: 'success', message: 'Jira Project Mapped Successfully!' });

        setaddProjectKeyForProReqModal(false);
        setAddProjectKeyForTaskModal(false);
        setSelectedJiraIssueTypes([]);
        setSelectedJiraIssueStatuses([]);
        setIsNextSectionInProjectAddVisible(false);
      }
    }
  };

  // after adding a new key this use effect get fired and we need to reload the project keys data
  useEffect(() => {
    // console.log('this much time');
    dispatch(fetchProjectAddedToCustomerAndJira());
    if (Object.keys(newlyAddedJiraProjectKeyData).length > 0) {
      setAlertDetails({ isVisible: true, type: 'success', message: 'Jira Project Mapped Successfully!' });
    }
  }, [newlyAddedJiraProjectKeyData]);

  const handleProjectKeyDelete = async () => {
    // delete project key here
    const { rowData, action } = selectedRowForProjectKeyDeletion;

    console.log('ROWWWWW', rowData, action);

    const customer_uid = cookies.get('cogni_customer_uid');
    // dispatch(
    //   deleteCustomerJiraProject({ customer_uid: customer_uid ? customer_uid : '', project_key: rowData.project_key })
    // );
    // dispatch(fetchProjectAddedToCustomerAndJira());
    if (rowData.task_ind === '0') {
      dispatch(
        deleteCustomerJiraProject({
          customer_uid: customer_uid ? customer_uid : '',
          project_key: rowData.project_key,
        })
      );
      const productInfo = await jiraAPI.getJiraProductInfoByJiraIssueId(rowData.jira_project_id);
      console.log(productInfo);
      const project_uid = productInfo.data.result[0].product_uid;
      dispatch(deleteProduct({ customer_uid: customer_uid ? customer_uid : '', product_uid: project_uid }));
    } else {
      dispatch(
        deleteCustomerJiraProject({
          customer_uid: customer_uid ? customer_uid : '',
          project_key: rowData.project_key,
        })
      );
    }
    setTimeout(() => {
      dispatch(fetchProjectAddedToCustomerAndJira());
      setAlertDetails({ isVisible: true, type: 'success', message: 'Jira Project Key Deleted!' });
    }, 1500);
  };

  const deletingJiraProjectKey = (rowData: Record<string, any>, action: string) => {
    setSelectedRowForProjectKeyDeletion({ rowData, action });
    setOpenDeleteModal(true);
  };

  // after deleting project key we need to update project keys table
  useEffect(() => {
    // console.log('this much time');
    dispatch(fetchProjectAddedToCustomerAndJira());
  }, [deletedJiraProjectKeyData]);

  const deleteCustomerProjectHandler = () => {
    const customer_uid = cookies.get('cogni_customer_uid');
    projectsAlreadyAddedToJira.data.forEach((project) => {
      if (project.task_ind === '0') {
        dispatch(
          deleteCustomerJiraProject({
            customer_uid: customer_uid ? customer_uid : '',
            project_key: project.project_key,
          })
        );
      } else {
        dispatch(
          deleteWholeCustomerProduct({
            customer_uid: customer_uid ? customer_uid : '',
            project_key: project.project_key,
          })
        );
      }
    });

    allProductsAndTheirRequirements.data.forEach((product) => {
      dispatch(deleteProduct({ customer_uid: customer_uid ? customer_uid : '', product_uid: product.product_uid }));
    });
  };

  return (
    <Grid sx={{ padding: '5px 20px 40px 20px' }}>
      {userDetailsState.status !== 'success' ? (
        <CognisaasLoader />
      ) : (
        <>
          <Grid>
            <Typography variant={'subtitle2'} className={'label-text'} sx={{ marginLeft: '15px' }}>
              {label_project} Management
            </Typography>
            <Grid>
              <Stack direction={'row'} spacing={1} alignItems={'center'}>
                <GoBack
                  onClick={() => {
                    navigate('/settings/integrations');
                  }}
                  style={{ cursor: 'pointer' }}
                />
                <Typography variant={'h2'}>Jira Integration</Typography>
              </Stack>
            </Grid>
          </Grid>
          <Grid sx={{ marginTop: '24px' }}>
            <Typography variant={'subtitle1'}>Jira Integration</Typography>
            <Switch
              sx={{ marginLeft: '-10px' }}
              value={integrationSwitch}
              checked={integrationSwitch}
              onChange={onChangeIntegrationSwitch}
            />
          </Grid>
          <Grid sx={{ marginTop: '30px' }}>
            <Typography variant={'h2'}>Details</Typography>
            <Typography variant={'subtitle2'} marginTop={'10px'}>
              Jira username
            </Typography>
            <TextField
              required
              disabled={!integrationSwitch}
              value={jiraUsername}
              onChange={(e: any) => {
                setJiraUsername(e.target.value);
              }}
              variant="outlined"
              placeholder="Enter Username"
              size="small"
              autoFocus
            />
            <Grid sx={{ display: 'flex', marginTop: '10px' }}>
              <Grid>
                <Typography variant={'subtitle2'} marginTop={'10px'}>
                  API Token
                </Typography>
                <TextField
                  required
                  disabled={!integrationSwitch}
                  value={jiraAPI_Token}
                  onChange={(e: any) => {
                    setJiraAPI_Token(e.target.value);
                  }}
                  type={'password'}
                  variant="outlined"
                  placeholder="Enter Token"
                  size="small"
                />
              </Grid>
              <Grid sx={{ marginLeft: '20px' }}>
                <Typography variant={'subtitle2'} marginTop={'10px'}>
                  Company&apos;s URL for Jira
                </Typography>
                <TextField
                  required
                  disabled={!integrationSwitch}
                  value={jiraCompany_URL}
                  onChange={(e: any) => {
                    setJiraCompany_URL(e.target.value);
                  }}
                  variant="outlined"
                  placeholder="Enter URL"
                  size="small"
                />
              </Grid>
            </Grid>
            <Grid sx={{ marginTop: '40px' }}>
              <Button
                disabled={
                  !integrationSwitch ||
                  jiraAPI_Token.length === 0 ||
                  jiraUsername.length === 0 ||
                  jiraCompany_URL.length === 0
                }
                onClick={onSaveJiraCredentials}
                variant="contained"
              >
                Save
              </Button>
              <Button
                disabled={!integrationSwitch}
                onClick={onJiraCredential_cancel}
                sx={{ marginLeft: '20px' }}
                variant="outlined"
              >
                Cancel
              </Button>
            </Grid>
          </Grid>

          {integrationSwitch ? (
            <>
              {accessType === 'product' && (
                <>
                  <Divider sx={{ marginTop: '24px' }} />
                  <Grid sx={{ marginTop: '14px' }}>
                    <Typography variant={'h2'}>Jira Project Mapping Mode for Product Requirement</Typography>
                    <RadioGroup
                      aria-labelledby="jira-mapping-mode"
                      // defaultValue="push"
                      name="jira-fetch-mode"
                      value={jiraFetchModeRadio}
                      onChange={fetchModeRadioChange}
                      sx={{ display: 'flex', flexDirection: 'row', marginLeft: '8px' }}
                    >
                      <FormControlLabel
                        value="push"
                        control={<Radio size="small" sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }} />}
                        label={<Typography variant={'subtitle1'}>Push</Typography>}
                      />
                      <FormControlLabel
                        value="pull"
                        control={<Radio size="small" sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }} />}
                        label={<Typography variant={'subtitle1'}>Pull</Typography>}
                      />
                    </RadioGroup>
                  </Grid>
                  <Grid sx={{ marginTop: '24px' }}>
                    <Typography variant={'h2'}>Jira Project Mapping for Product Requirement</Typography>
                    <Typography variant={'subtitle2'} sx={{ marginTop: '8px' }}>
                      Please specify Jira project where product requirement raise in cognisaas will be tracked.
                    </Typography>
                    <Grid sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Grid sx={{ backgroundColor: '#F3F3F3', padding: '10px', marginTop: '12px', width: '70%' }}>
                        <Typography variant={'subtitle2'} className={'label-text'}>
                          Note: Select One-to-many Indicator if product requirements for multiple products are tracked
                          in a project. You need to specify the Jira field that tracks the product name.
                        </Typography>
                      </Grid>
                      <Button
                        disabled={!integrationSwitch && userDetailsState.data.customer_jira_api_url.length === 0}
                        size="small"
                        variant="outlined"
                        onClick={() => {
                          setaddProjectKeyForProReqModal(true);
                        }}
                      >
                        Add Project Key
                      </Button>
                    </Grid>
                  </Grid>

                  {/* project key mapped table  */}
                  {projectsAlreadyAddedToJira.data.filter((item) => item.task_ind === '0').length > 0 ? (
                    <Box sx={{ margin: '12px 0px' }}>
                      <CogniTable
                        hideFooter={true}
                        hideHeader={true}
                        pageSize={5}
                        columns={[
                          {
                            field: 'project_uid',
                            hide: true,
                          },
                          {
                            field: 'project_key',
                            headerName: 'Project Key',
                            minWidth: 240,
                            flex: 1,
                          },
                          {
                            field: 'jira_project_name',
                            headerName: 'Project Name',
                            minWidth: 200,
                            flex: 1,
                          },
                          {
                            field: 'one_to_many_structure',
                            headerName: 'One To Many',
                            minWidth: 140,
                            flex: 1,
                          },
                          {
                            field: '_',
                            headerName: 'Status Mapping',
                            minWidth: 140,
                            flex: 1,
                            renderCell: (params: any) =>
                              CustomTextlinkCell(
                                params,
                                handleMapJiraStatusToProductRequirementButton,
                                'Map jira status'
                              ),
                          },
                          {
                            field: '',
                            headerName: 'Edit',
                            minWidth: 50,
                            flex: 1,
                            renderCell: (params: any) => MenuCell(params, ['Delete'], deletingJiraProjectKey),
                          },
                        ]}
                        uniquRowId={'project_uid'}
                        data={projectsAlreadyAddedToJira.data.filter((item) => item.task_ind === '0')}
                      />
                    </Box>
                  ) : (
                    <></>
                  )}
                </>
              )}

              {accessType == 'task' && (
                <>
                  <Divider sx={{ margin: '25px 0' }} />
                  <Grid>
                    <Typography variant={'h2'}>Jira Project Mapping for Implementation Task</Typography>
                    <Grid sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <RadioGroup
                        aria-labelledby="jira-implementation-task"
                        value={jiraTaskModeRadio}
                        onChange={jiraTaskMode_OnChange}
                        name="jira-implementation-task"
                        sx={{ display: 'flex', flexDirection: 'row', marginLeft: '8px' }}
                      >
                        <FormControlLabel
                          value="pushToJira"
                          control={<Radio size="small" sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }} />}
                          label={<Typography variant={'subtitle1'}>Push to Jira</Typography>}
                        />
                        <FormControlLabel
                          value="pushToJira_ServiceDesk"
                          control={<Radio size="small" sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }} />}
                          label={<Typography variant={'subtitle1'}>Push to Jira Service Desk</Typography>}
                        />
                      </RadioGroup>
                      <Button
                        disabled={!integrationSwitch}
                        size="small"
                        variant="outlined"
                        onClick={() => {
                          // setOpenAddKeyModal(true);
                          setAddProjectKeyForTaskModal(true);
                        }}
                      >
                        Add Project Key
                      </Button>
                    </Grid>
                    <Typography variant={'subtitle2'} sx={{ marginTop: '10px' }}>
                      Configure relevent Jira projects for integration based on project keys.
                    </Typography>
                    {/* task key mapped table  */}
                    {projectsAlreadyAddedToJira.data.filter((item) => item.task_ind === '1').length > 0 ||
                    projectsAlreadyAddedToJira.data.filter((item) => item.jira_service_desk_id === '1').length > 0 ? (
                      <Box sx={{ margin: '12px 0px' }}>
                        <CogniTable
                          hideFooter={true}
                          hideHeader={true}
                          pageSize={5}
                          columns={[
                            {
                              field: 'project_uid',
                              hide: true,
                            },
                            {
                              field: 'project_key',
                              headerName: 'Project Key',
                              minWidth: 240,
                              flex: 1,
                            },
                            {
                              field: 'jira_project_name',
                              headerName: 'Project Name',
                              minWidth: 200,
                              flex: 1,
                            },
                            {
                              field: '_',
                              headerName: 'Status Mapping',
                              minWidth: 140,
                              flex: 1,
                              renderCell: (params: any) =>
                                CustomTextlinkCell(params, handleMapJiraStatusToTasksButton, 'Map jira status'),
                            },
                            {
                              field: '',
                              headerName: 'Edit',
                              minWidth: 50,
                              flex: 1,
                              renderCell: (params: any) => MenuCell(params, ['Delete'], deletingJiraProjectKey),
                            },
                          ]}
                          uniquRowId={'project_uid'}
                          data={
                            userDetailsState.data.customer_JSD === '1'
                              ? [...projectsAlreadyAddedToJira.data.filter((item) => item.jira_service_desk_id === '1')]
                              : [...projectsAlreadyAddedToJira.data.filter((item) => item.task_ind === '1')]
                          }
                        />
                      </Box>
                    ) : (
                      <></>
                    )}
                  </Grid>
                </>
              )}
            </>
          ) : (
            <></>
          )}
        </>
      )}
      {/* modal for confirming to assign delivery manager */}
      {/* turn off jira integration modal */}
      <BasicModal
        height={biggerScreens ? 222 : 170}
        width={biggerScreens ? 657 : 450}
        open={confirmTurnOffJiraModal}
        onClose={() => {
          setConfirmTurnOffJiraModal(false);
        }}
      >
        <Stack alignItems={'center'} justifyContent={'center'} sx={{ width: '100%', height: '100%' }}>
          <Typography variant="h2" sx={{ maxWidth: '498px', textAlign: 'center', marginBottom: '20px' }}>
            All product feature pulled from JIRA will be deleted and dependent use cases will change status. Are you
            sure you want to continue?
          </Typography>
          <Stack direction={'row'}>
            <CognisaasButton
              isOutlined={true}
              label="Cancel"
              clickHandler={() => {
                setConfirmTurnOffJiraModal(false);
              }}
              sx={{ marginRight: '8px' }}
            />
            <CognisaasButton
              isOutlined={false}
              label="Proceed"
              clickHandler={() => {
                // console.log('Proceed');
                dispatch(removeJiraToken());
                dispatch(removeJiraLinkFromTask());
                deleteCustomerProjectHandler();
                onJiraCredential_cancel();
                setConfirmTurnOffJiraModal(false);
                setIntegrationSwitch(false);
                setShouldToggleJiraIntegration(true);
              }}
            />
          </Stack>
        </Stack>
      </BasicModal>

      {/* turn on jira modal */}
      <BasicModal
        height={biggerScreens ? 222 : 170}
        width={biggerScreens ? 657 : 450}
        open={confirmTurnOnJiraModal}
        onClose={() => {
          setConfirmTurnOnJiraModal(false);
        }}
      >
        <Stack alignItems={'center'} justifyContent={'center'} sx={{ width: '100%', height: '100%' }}>
          <Typography variant="h2" sx={{ maxWidth: '498px', textAlign: 'center', marginBottom: '20px' }}>
            All product feature added manually will be deleted and dependent use cases will change status.
          </Typography>
          <Stack direction={'row'}>
            <CognisaasButton
              isOutlined={true}
              label="Cancel"
              clickHandler={() => {
                setConfirmTurnOnJiraModal(false);
              }}
              sx={{ marginRight: '8px' }}
            />
            <CognisaasButton
              isOutlined={false}
              label="Proceed"
              clickHandler={() => {
                // console.log('Proceed');
                setConfirmTurnOnJiraModal(false);
                setIntegrationSwitch(true);
                setShouldToggleJiraIntegration(true);
              }}
            />
          </Stack>
        </Stack>
      </BasicModal>

      {/* project key  */}
      <BasicModal
        height={isNextSectionInProjectAddVisible ? 410 : 250}
        width={290}
        open={addProjectKeyForProReqModal}
        onClose={() => {
          setProjectKeyForproductReqMapping('');
          setIsProjectKeyOneToMany(false);
          setaddProjectKeyForProReqModal(false);
          setIsNextSectionInProjectAddVisible(false);
        }}
      >
        <Stack sx={{ margin: '4px' }}>
          <Typography variant="h2">Add Project Key</Typography>
          <Typography sx={{ marginBottom: '24px' }} variant="subtitle2" className="label-text">
            For product reqirement mapping
          </Typography>
          <Typography variant="subtitle2">Project key form Jira</Typography>
          <TextField
            autoFocus
            disabled={isNextSectionInProjectAddVisible}
            value={projectKeyForproductReqMapping}
            onChange={(e: any) => {
              setProjectKeyForproductReqMapping(e.target.value);
            }}
            variant="outlined"
            placeholder="Enter Key"
            size="small"
          />

          {jiraFetchModeRadio === 'pull' && isNextSectionInProjectAddVisible ? (
            <>
              <Typography sx={{ marginTop: '8px' }} variant="subtitle2">
                Jira issue types for product pull
              </Typography>
              <CognisaasMultiSelectWithSearchUid
                allOptions={availableJiraIssuetypesOptions}
                selectedOption={selectedJiraIssueTypes}
                setSelectedOptions={setSelectedJiraIssueTypes}
              />

              <Typography sx={{ marginTop: '8px' }} variant="subtitle2">
                Jira issue status for product pull
              </Typography>
              <CognisaasMultiSelectWithSearchUid
                allOptions={availableJiraStatusOptions}
                selectedOption={selectedJiraIssueStatuses}
                setSelectedOptions={setSelectedJiraIssueStatuses}
              />
            </>
          ) : (
            <></>
          )}

          <Stack
            spacing={1}
            onClick={() => {
              setIsProjectKeyOneToMany((prev) => !prev);
            }}
            direction={'row'}
            alignItems={'center'}
            sx={{ margin: '16px 0px 24px 0px' }}
            className={'cognisaas-pointer'}
          >
            <CognisaasCheckBox checked={isProjectKeyOneToMany} />
            <Typography variant="subtitle2">One to many</Typography>
          </Stack>
          <Stack direction={'row'} justifyContent={'flex-end'} spacing={1}>
            <CognisaasButton
              label="Cancel"
              isOutlined={true}
              clickHandler={() => {
                console.log('cancel and reset modal');
                setProjectKeyForproductReqMapping('');
                setIsProjectKeyOneToMany(false);
                setaddProjectKeyForProReqModal(false);
                setIsNextSectionInProjectAddVisible(false);
              }}
            />
            {jiraFetchModeRadio === 'pull' && !isNextSectionInProjectAddVisible ? (
              <CognisaasButton
                label="Next"
                isOutlined={false}
                clickHandler={() => {
                  setIsNextSectionInProjectAddVisible(true);

                  const key = btoa(
                    btoa(userDetailsState.data.customer_jira_user + ':' + userDetailsState.data.customer_jira_token)
                  );

                  const payload = {
                    customer_uid: getCustomerUid(),
                    project_key: projectKeyForproductReqMapping,
                    key: key,
                  };

                  dispatch(getJiraCustomFields(payload));
                  dispatch(getJiraStatusByJiraProjectKey(payload));
                }}
              />
            ) : (
              <CognisaasButton
                disabled={
                  jiraFetchModeRadio === 'pull'
                    ? projectKeyForproductReqMapping.length == 0 ||
                      selectedJiraIssueTypes.length == 0 ||
                      selectedJiraIssueStatuses.length == 0
                    : projectKeyForproductReqMapping.length == 0
                }
                label="Add"
                isOutlined={false}
                clickHandler={() => {
                  handleAddProductRequirementJiraProjectKey('product');
                  setIsNextSectionInProjectAddVisible(false);
                }}
              />
            )}
          </Stack>
        </Stack>
      </BasicModal>

      {/* task key modal replace in future repetative */}
      <BasicModal
        height={220}
        width={290}
        open={addProjectKeyForTaskModal}
        onClose={() => {
          setAddProjectKeyForTaskModal(false);
        }}
      >
        <Stack sx={{ margin: '4px' }}>
          <Typography variant="h2">Add Project Key</Typography>
          <Typography sx={{ marginBottom: '24px' }} variant="subtitle2" className="label-text">
            For Tasks
          </Typography>
          <Typography variant="subtitle2">Project key form Jira</Typography>
          <TextField
            autoFocus
            value={projectKeyForproductReqMapping}
            onChange={(e: any) => {
              setProjectKeyForproductReqMapping(e.target.value);
            }}
            variant="outlined"
            placeholder="Enter Key"
            size="small"
          />
          {/* <Stack
            spacing={1}
            onClick={() => {
              setIsProjectKeyOneToMany((prev) => !prev);
            }}
            direction={'row'}
            alignItems={'center'}
            sx={{ margin: '16px 0px 24px 0px' }}
            className={'cognisaas-pointer'}
          >
            <CognisaasCheckBox checked={isProjectKeyOneToMany} />
            <Typography variant="subtitle2">One to many</Typography>
          </Stack> */}
          <Stack sx={{ marginTop: '24px' }} direction={'row'} justifyContent={'flex-end'} spacing={1}>
            <CognisaasButton
              label="Cancel"
              isOutlined={true}
              clickHandler={() => {
                setProjectKeyForproductReqMapping('');
                setIsProjectKeyOneToMany(false);
                setAddProjectKeyForTaskModal(false);
              }}
            />
            <CognisaasButton
              label="Add"
              isOutlined={false}
              clickHandler={() => handleAddProductRequirementJiraProjectKey('task')}
            />
          </Stack>
        </Stack>
      </BasicModal>

      {/* this is new alerts */}
      <CognisaasToast
        open={alertDetails.isVisible}
        onClose={() => setAlertDetails({ isVisible: false, type: alertDetails.type, message: '' })}
        message={alertDetails.message}
        type={alertDetails.type}
      />

      {/* map jira status to product requirement status modal */}
      <MapProductJiraStatusModal
        open={openJiraStatusMappingForProductReq}
        setIsOpen={setOpenJiraStatusMappingForProductReq}
        projectKey={selectedProjectkey}
      />

      {/* map jira status to task status modal */}
      <MapJiraTaskStatusModal
        open={openJiraStatusMappingForTasks}
        setIsOpen={setOpenJiraStatusMappingForTasks}
        projectKey={selectedProjectkey}
      />

      <DeleteModal
        isOpen={openDeleteModal}
        setIsOpen={setOpenDeleteModal}
        message={'Are you sure you want to delete this Jira Project Key'}
        onDelete={handleProjectKeyDelete}
      />
    </Grid>
  );
};

export default Integration;
