import { Typography, Stack, TextField } from '@mui/material';
import { useState, useEffect } from 'react';
import CognisaasButton from 'components/shared/CognisaasButton/CognisaasButton';
import {
  getProjectTaskTimeLog,
  upadateProjectTaskTimeLog,
  getUsecaseTaskTimeLog,
  upadateUsecaseTaskTimeLog,
  updateTaskTimeTracking,
  addTaskAuditTrail,
} from 'store/modules/Task/slice';
import {
  selectProjectTaskTimeLog,
  selectUsecaseTaskTimeLog,
  selectTaskModalTimeTracking,
} from 'store/modules/Task/selector';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { userPermissionSchema } from 'components/Reports/SideDrawer/types';
import CognisaasToast from 'components/shared/CognisaasToast/CognisaasToast';
import { getLoggedInUserUid } from 'utils/getUid';
import { format } from 'date-fns';

// import { useParams } from 'react-router-dom';

const TimeTracking = (props: any) => {
  const taskDetail = props.taskDetail;
  const { userPermissions } = props;

  console.log('TIME TRACKIng', userPermissions);
  // const params = useParams();
  const regex = new RegExp(
    '([0-9]{1,3}[w]{1}\\s{1}[0-9]{1,3}[d]{1}\\s{1}[0-9]{1,3}[h]{1}\\s{1}[0-9]{1,3}[m]{1}|[0-9]{1,3}[mhdw]{1}\\s{1}[0-9]{1,3}[mhd]{1}\\s{1}[0-9]{1,3}[mh]{1}|[0-9]{1,3}[wdhm]{1}\\s{1}[0-9]{1,3}[dhm]{1}|[0-9]{1,3}[wdhm]{1})'
  );

  const valueToMinutes = (val: any) => {
    const inputLogValue = val.split(' ');
    let valueInMinutes = 0;
    let minute = 0;
    let hour = 0;
    let day = 0;
    let week = 0;
    inputLogValue.forEach((val: any) => {
      const char = val.slice(-1);

      if (char == 'm') {
        minute = parseInt(val.slice(0, -1));
        valueInMinutes += minute;
      }
      if (char == 'h') {
        hour = parseInt(val.slice(0, -1));
        valueInMinutes += hour * 60; //convert hours => 60minutes
      }
      if (char == 'd') {
        day = parseInt(val.slice(0, -1));
        valueInMinutes += day * 8 * 60; //convert days => 8 hours => 60minutes
      }
      if (char == 'w') {
        week = parseInt(val.slice(0, -1));
        valueInMinutes += week * 5 * 8 * 60;
        //convert week to 5days => 8 hours => 60minutes
      }
    });
    return valueInMinutes;
  };

  // Method used to Convert Database Value to be used in the UI
  const convertMinutes = (num: any) => {
    if (num <= 0) {
      return '0m';
    } else {
      let hour = Math.floor(num / 60); // 60 minutes in hour
      let day = Math.floor(hour / 8); // 8 hours a day office time
      const week = Math.floor(day / 5); // 5 days a week office time
      const minute = num - 60 * hour; // remaining minutes

      hour = hour - day * 8; // remaining hours
      day = day - week * 5; // remaining days

      let timeLogValue = ''; // Var to store Time Log Value

      if (week > 0) {
        timeLogValue = timeLogValue.concat(`${week}w `);
      }
      if (day > 0) {
        timeLogValue = timeLogValue.concat(`${day}d `);
      }
      if (hour > 0) {
        timeLogValue = timeLogValue = timeLogValue.concat(`${hour}h `);
      }
      if (minute > 0) {
        timeLogValue = timeLogValue.concat(`${minute}m `);
      }

      timeLogValue = timeLogValue.slice(0, -1); // To remove the last blank space
      console.log(timeLogValue);

      return timeLogValue;
    }
  };

  const dispatch = useAppDispatch();
  const projectTaskTimeLog = useAppSelector(selectProjectTaskTimeLog);
  const usecaseTaskTimeLog = useAppSelector(selectUsecaseTaskTimeLog);
  const taskModalValuesFromStore = useAppSelector(selectTaskModalTimeTracking);

  console.log(props);

  const [estimateError, setEstimateError] = useState<boolean>(false);
  const [originalEstimate, setOriginalEstimate] = useState<any>('');
  const [alertDetails, setAlertDetails] = useState<{
    isVisible: boolean;
    type: 'success' | 'failure';
    message: string;
  }>({ isVisible: false, type: 'success', message: '' });
  const [timeSpent, setTimeSpent] = useState<any>('');
  const [timeRemaining, setTimeRemaining] = useState<any>('');
  const [originalTimeRemaining, setoriginalTimeRemaining] = useState<any>('');
  const [hoursPerDay, setHoursPerday] = useState<any>('');
  const [totalTimeLogged, setTotalTimeLogged] = useState<any>('');

  console.log(estimateError, setEstimateError);

  useEffect(() => {
    if (!taskModalValuesFromStore) {
      if (taskDetail.project_uid && taskDetail.case_uid) {
        dispatch(getUsecaseTaskTimeLog(taskDetail.task_uid));
      } else {
        dispatch(getProjectTaskTimeLog(taskDetail.task_uid));
      }
      console.log(timeRemaining);
    }
  }, []);

  useEffect(() => {
    if (taskModalValuesFromStore) {
      if (taskDetail.case_uid && taskDetail.project_uid) {
        if (usecaseTaskTimeLog.length > 0) {
          setOriginalEstimate(convertMinutes(usecaseTaskTimeLog[0].effort_estimate));
          setTimeRemaining(
            convertMinutes(
              valueToMinutes(convertMinutes(usecaseTaskTimeLog[0].effort_estimate)) -
                valueToMinutes(convertMinutes(usecaseTaskTimeLog[0].total_time_spent))
            )
          );

          setoriginalTimeRemaining(
            convertMinutes(
              valueToMinutes(convertMinutes(usecaseTaskTimeLog[0].effort_estimate)) -
                valueToMinutes(convertMinutes(usecaseTaskTimeLog[0].total_time_spent))
            )
          );

          setHoursPerday(usecaseTaskTimeLog[0].hours_per_day);
          setTotalTimeLogged(usecaseTaskTimeLog[0].total_time_spent);
        }
      } else {
        if (projectTaskTimeLog.length > 0) {
          setOriginalEstimate(convertMinutes(projectTaskTimeLog[0].effort_estimate));
          setTimeRemaining(
            convertMinutes(
              valueToMinutes(convertMinutes(projectTaskTimeLog[0].effort_estimate)) -
                valueToMinutes(convertMinutes(projectTaskTimeLog[0].total_time_spent))
            )
          );

          setoriginalTimeRemaining(
            convertMinutes(
              valueToMinutes(convertMinutes(projectTaskTimeLog[0].effort_estimate)) -
                valueToMinutes(convertMinutes(projectTaskTimeLog[0].total_time_spent))
            )
          );

          setHoursPerday(projectTaskTimeLog[0].hours_per_day);
          setTotalTimeLogged(projectTaskTimeLog[0].total_time_spent);
        }
      }
    }
  }, [projectTaskTimeLog]);

  useEffect(() => {
    if (timeSpent.length > 0) {
      setTimeRemaining(convertMinutes(valueToMinutes(timeRemaining) - valueToMinutes(timeSpent)));
    }
  }, [timeSpent]);

  const giveDetailObject = () => {
    if (taskDetail.case_uid && taskDetail.project_uid) {
      return { ...usecaseTaskTimeLog[0], taskUid: taskDetail.task_uid };
    } else {
      return { ...projectTaskTimeLog[0], taskUid: taskDetail.task_uid };
    }
  };

  useEffect(() => {
    dispatch(
      updateTaskTimeTracking({
        hoursPerDay,
        timeSpent,
        originalEstimate,
        detail: giveDetailObject(),
        taskDetail: taskDetail,
        timeRemaining,
      })
    );
  }, [hoursPerDay, timeSpent, originalEstimate]);

  useEffect(() => {
    if (taskModalValuesFromStore) {
      console.log(taskModalValuesFromStore);
      setOriginalEstimate(taskModalValuesFromStore.originalEstimate);
      setTimeSpent(taskModalValuesFromStore.timeSpent);
      setHoursPerday(taskModalValuesFromStore.hoursPerDay);
    }
  }, []);

  const saveTimeLog = async () => {
    if (taskDetail.case_uid && taskDetail.project_uid) {
      const detail = {
        effort_estimate: valueToMinutes(originalEstimate),
        task_uid: taskDetail.task_uid,
        time_remaining: valueToMinutes(timeRemaining),
        total_time_spent: valueToMinutes(timeSpent) + parseInt(usecaseTaskTimeLog[0].total_time_spent),
        hours_per_day: hoursPerDay,
      };

      const updateLog = await dispatch(upadateUsecaseTaskTimeLog(detail));
      if (updateLog) {
        setAlertDetails({
          isVisible: true,
          type: 'success',
          message: 'Updated successfully!',
        });
      }
      const auditDetail = {
        task_uid: taskDetail.task_uid,
        user_uid: getLoggedInUserUid(),
        field: 'Time Tracking',
        modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
        old_value: '',
        new_value: '',
      };
      dispatch(addTaskAuditTrail(auditDetail));
      dispatch(getUsecaseTaskTimeLog(taskDetail.task_uid));
      setTimeSpent('');
    } else {
      const detail = {
        effort_estimate: valueToMinutes(originalEstimate),
        task_uid: taskDetail.task_uid,
        time_remaining: valueToMinutes(timeRemaining),
        total_time_spent: valueToMinutes(timeSpent) + parseInt(projectTaskTimeLog[0].total_time_spent),
        hours_per_day: hoursPerDay,
      };

      const updateLog = await dispatch(upadateProjectTaskTimeLog(detail)).unwrap();
      console.log(updateLog);
      if (updateLog) {
        setAlertDetails({
          isVisible: true,
          type: 'success',
          message: 'Updated successfully!',
        });
      }
      const auditDetail = {
        task_uid: taskDetail.task_uid,
        user_uid: getLoggedInUserUid(),
        field: 'Time Tracking',
        modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
        old_value: '',
        new_value: '',
      };
      dispatch(addTaskAuditTrail(auditDetail));
      await dispatch(getProjectTaskTimeLog(taskDetail.task_uid));
      setTimeSpent('');
    }
    // props.onClose();
  };

  const handleChange = (e: any) => {
    const testReg = regex.test(e.target.value);
    if (testReg) {
      setTimeSpent(e.target.value);
    } else {
      setTimeSpent(' ');
      setTimeRemaining(originalTimeRemaining);
    }
  };
  return (
    <Stack>
      {props.taskDetail.project_uid && !props.taskDetail.case_uid && projectTaskTimeLog.length > 0 && (
        <>
          <Stack>
            <Stack m={2}>
              <Typography variant="subtitle1" className="label-text">
                Total time logged
              </Typography>
              <Typography style={{ marginTop: '6px' }} variant={'h1'}>
                {(parseInt(totalTimeLogged) / 60).toFixed(2)} Hours
              </Typography>
            </Stack>
            <Stack direction="row" spacing={2} m={2} sx={{ marginTop: '8px' }}>
              <Stack>
                <Typography variant="subtitle1">Original estimate</Typography>
                <TextField
                  disabled={
                    (projectTaskTimeLog[0].effort_estimate[0] > 0 ? true : false) ||
                    !userPermissions?.includes(userPermissionSchema.EDIT)
                  }
                  // error={estimateError ? true : false}
                  // helperText={estimateError ? 'Incorrect entry.' : null}
                  value={originalEstimate}
                  size="small"
                  style={{ width: '200px', marginTop: '8px' }}
                  placeholder="2w 3d 5h 2m"
                  onChange={(e: any) => setOriginalEstimate(e.target.value)}
                ></TextField>
              </Stack>
              <Stack>
                <Typography variant="subtitle1">Hours per day</Typography>
                <TextField
                  disabled={!userPermissions?.includes(userPermissionSchema.EDIT)}
                  type="number"
                  onChange={(e: any) => setHoursPerday(e.target.value)}
                  // error
                  // helperText="Incorrect entry."
                  value={hoursPerDay}
                  size="small"
                  style={{ width: '200px', marginTop: '8px' }}
                  placeholder="0"
                ></TextField>
              </Stack>
              <Stack spacing={2}>
                <Typography variant="subtitle1">Total Hours </Typography>
                <Typography variant="h2">
                  {originalEstimate == '0m' ? 0 : valueToMinutes(originalEstimate) / 60}
                </Typography>
              </Stack>
            </Stack>
            <Stack direction="row" mx={2} spacing={2}>
              <Stack>
                <Typography variant="subtitle1">Total Time Spent</Typography>
                <TextField
                  // value={timeSpent}
                  disabled={
                    (originalEstimate == '0m' ? true : false) || !userPermissions?.includes(userPermissionSchema.EDIT)
                  }
                  // error={estimateError ? true : false}
                  // helperText={estimateError ? 'Incorrect entry.' : null}
                  // disabled={convertMinutes(taskDetail.effort_estimate) == '0m' ? true : false}
                  size="small"
                  style={{ width: '200px', marginTop: '8px' }}
                  placeholder="2w 3d 5h 2m"
                  onChange={(e: any) => {
                    setTimeSpent(' ');
                    handleChange(e);
                  }}
                ></TextField>
              </Stack>
              <Stack>
                <Typography variant="subtitle1">Time Remaining </Typography>
                <TextField
                  aria-readonly
                  value={!timeRemaining ? '0m' : timeRemaining}
                  size="small"
                  style={{ width: '200px', marginTop: '8px' }}
                  disabled={!userPermissions?.includes(userPermissionSchema.EDIT)}
                ></TextField>
              </Stack>
            </Stack>
          </Stack>
          {userPermissions?.includes(userPermissionSchema.EDIT) && (
            <>
              <Stack direction="row" justifyContent="end" alignItems="flex-end" spacing={2}>
                <CognisaasButton
                  clickHandler={() => {
                    console.log('save');
                    saveTimeLog();

                    // handleSubmit();
                    // closeDrawer();
                  }}
                  isOutlined={false}
                  disabled={props.dirty == false}
                  label="Save"
                />
                <CognisaasButton clickHandler={() => props.onClose()} isOutlined={true} label="Cancel" />
              </Stack>
            </>
          )}
        </>
      )}
      {props.taskDetail.project_uid && props.taskDetail.case_uid && usecaseTaskTimeLog.length > 0 && (
        <>
          <Stack>
            <Stack m={2}>
              <Typography variant="subtitle1" className="label-text">
                Total time logged
              </Typography>
              <Typography style={{ marginTop: '6px' }} variant={'h1'}>
                {(parseInt(totalTimeLogged) / 60).toFixed(2)} Hours
              </Typography>
            </Stack>
            <Stack direction="row" spacing={2} m={2} sx={{ marginTop: '8px' }}>
              <Stack>
                <Typography variant="subtitle1">Original estimate</Typography>
                <TextField
                  disabled={
                    (usecaseTaskTimeLog[0].effort_estimate[0] > 0 ? true : false) ||
                    userPermissions?.includes(userPermissionSchema.EDIT)
                  }
                  // error={estimateError ? true : false}
                  // helperText={estimateError ? 'Incorrect entry.' : null}
                  value={originalEstimate}
                  size="small"
                  style={{ width: '200px', marginTop: '8px' }}
                  placeholder="2w 3d 5h 2m"
                  onChange={(e: any) => setOriginalEstimate(e.target.value)}
                ></TextField>
              </Stack>
              <Stack>
                <Typography variant="subtitle1">Hours per day</Typography>
                <TextField
                  type="number"
                  onChange={(e: any) => setHoursPerday(e.target.value)}
                  // error
                  // helperText="Incorrect entry."
                  value={hoursPerDay}
                  size="small"
                  style={{ width: '200px', marginTop: '8px' }}
                  placeholder="0"
                  disabled={userPermissions?.includes(userPermissionSchema.EDIT)}
                ></TextField>
              </Stack>
              <Stack spacing={2}>
                <Typography variant="subtitle1">Total Hours </Typography>
                <Typography variant="h2">
                  {originalEstimate == '0m' ? 0 : valueToMinutes(originalEstimate) / 60}
                </Typography>
              </Stack>
            </Stack>
            <Stack direction="row" mx={2} spacing={2}>
              <Stack>
                <Typography variant="subtitle1">Total Time Spent</Typography>
                <TextField
                  // value={timeSpent}
                  disabled={
                    (originalEstimate == '0m' ? true : false) || !userPermissions?.includes(userPermissionSchema.EDIT)
                  }
                  // error={estimateError ? true : false}
                  // helperText={estimateError ? 'Incorrect entry.' : null}
                  // disabled={convertMinutes(taskDetail.effort_estimate) == '0m' ? true : false}
                  size="small"
                  style={{ width: '200px', marginTop: '8px' }}
                  placeholder="2w 3d 5h 2m"
                  onChange={(e: any) => {
                    setTimeSpent(' ');
                    handleChange(e);
                  }}
                ></TextField>
              </Stack>
              <Stack>
                <Typography variant="subtitle1">Time Remaining </Typography>
                <TextField
                  aria-readonly
                  // disabled
                  // error
                  // helperText="Incorrect entry."
                  value={!timeRemaining ? '0m' : timeRemaining}
                  size="small"
                  style={{ width: '200px', marginTop: '8px' }}
                  disabled={!userPermissions?.includes(userPermissionSchema.EDIT)}
                  // placeholder="Enter Hours"
                ></TextField>
              </Stack>
            </Stack>
          </Stack>
          {userPermissions?.includes(userPermissionSchema.EDIT) && (
            <Stack direction="row" justifyContent="end" alignItems="flex-end" spacing={2}>
              <CognisaasButton
                clickHandler={() => {
                  console.log('save');
                  saveTimeLog();

                  // props.handleSubmit();
                  // closeDrawer();
                }}
                isOutlined={false}
                disabled={props.dirty == false}
                label="Save"
              />
              <CognisaasButton
                sx={{ paddingRight: '16px' }}
                clickHandler={() => props.onClose()}
                isOutlined={true}
                label="Cancel"
              />
            </Stack>
          )}
        </>
      )}
      <CognisaasToast
        fromPanel={true}
        message={alertDetails.message}
        type={alertDetails.type}
        open={alertDetails.isVisible}
        onClose={() => {
          setAlertDetails({ isVisible: false, type: alertDetails.type, message: '' });
        }}
      />
    </Stack>
  );
};

export default TimeTracking;
