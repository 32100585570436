import { useEffect, useState } from 'react';
import { Typography, Stack, Accordion, AccordionSummary, AccordionDetails, Badge, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddFields from '../shared/AddFields';
import { label_project } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { SelectedEntityStorageMethodObj } from 'components/Reports/Shared/CreateCustomReportWrapper';
// import CognisaasButton from 'components/shared/CognisaasButton/CognisaasButton';

const ProjectAggregration = (props: any) => {
  console.log(props);
  // const addAggregrationField = {
  //   selected: false,
  //   columnName: '',
  //   fieldName: '',
  //   aggregrationType: '',
  //   conditions: [],
  // };
  console.log(props.entityFields);
  const [fields, setFields] = useState<any[]>([]);
  const [projectFields, setProjectFields] = useState<any[]>([]);

  useEffect(() => {
    makeEntityFields();
  }, [props.entityFields]);

  useEffect(() => {
    if (props.page != 'UPDATE') {
      makeAccordianData();
    } else {
      makeDataForUpdatePage();
    }
  }, [props.entityFields, props.projectData]);

  const changeToNeededDataType = (arr: any) => {
    const temp: any = {};
    for (let i = 0; i < arr.length; i++) {
      temp[arr[i].columnName] = { ...arr[i] };
    }
    return temp;
  };

  const makeDataForUpdatePage = () => {
    const store: any = {};
    console.log(props.projectData);
    for (const i of props.projectData) {
      console.log(i);
      store[i.displayName] = {
        ...i,
        columnName: i.displayName,
      };
    }
    console.log(store);
    setFields({ ...store });
  };

  const makeAccordianData = () => {
    const store: any = {};
    console.log(props.entityFields);
    for (const i in props.entityFields) {
      store[props.entityFields[i].displayName] = {
        columnName: props.entityFields[i].displayName,
        fieldName: props.entityFields[i].key,
        aggregations: [],
        selected: false,
      };

      if (props.page != 'UPDATE') {
        props.preSelectedEntities['project'].forEach((selectedEntity: any) => {
          if (selectedEntity === props.entityFields[i].key) {
            store[props.entityFields[i].displayName] = { ...store[props.entityFields[i].displayName], selected: true };
          }
        });
      }
    }
    const newFields = Object.keys(store).map((field: any) => {
      return store[field];
    });
    props.selectedItemsInProject(newFields);
    setFields({ ...store });
    // console.log('Inside function', props.entityFields);
    // for (const i in props.entityFields) {
    //   store.push({
    //     columnName: props.entityFields[i].displayName,
    //     fieldName: props.entityFields[i].key,
    //     aggregationType: '',
    //     conditions: [],
    //   });
    // }
    // console.log(store);
    // setFields([...store]);
  };

  const addAggregation = (index: number) => {
    const updateEntity = Object.keys(fields).map((field: any, i: number) => {
      if (i == index) {
        fields[field].aggregations.push({
          columnName: fields[field].columnName,
          fieldName: fields[field].fieldName,
          aggregationType: '',
          conditions: [],
        });
        fields[field].selected = true;
        return fields[field];
      } else {
        return fields[field];
      }
    });
    // console.log(updateEntity);
    setFields([...updateEntity]);
  };

  const makeEntityFields = () => {
    const allProjectFields = Object.keys(props.entityFields).map((k: any) => {
      return props.entityFields[k].displayName;
    });
    // console.log(allProjectFields);
    setProjectFields([...allProjectFields]);
  };

  const markFieldSelected = (value: boolean, index: number) => {
    // console.log(value, index, fields);
    const updateField = Object.keys(fields).map((field: any, i: number) => {
      if (i == index) {
        fields[field].selected = value;
        if (value === false) {
          fields[field].aggregations = [];
        }
        return fields[field];
      } else {
        return fields[field];
      }
    });
    setFields({ ...changeToNeededDataType(updateField) });
    if (props.page != 'UPDATE') {
      props.setSelectedEntitiesAndItsFields(
        (prevState: any) =>
          ({
            client: prevState.client,
            project: updateField.filter((item: any) => item.selected).map((item: any) => item.fieldName),
            usecase: prevState.usecase,
            task: prevState.task,
            // ...updateField.filter((item: any) => item.selected).map((item: any) => ({ key: item.fieldName, type: 'client' })),
          } as SelectedEntityStorageMethodObj)
      );
    }
    props.selectedItemsInProject(updateField);
    // console.log(updateField);
  };

  const columnNameChange = (value: string, key: string, mainIndex: number, index: number) => {
    // console.log(value, index, fields);
    // console.log(value, fields, key, index, mainIndex);
    const updateField = Object.keys(fields).map((field: any, i: number) => {
      if (i == mainIndex) {
        // console.log(field);
        for (let j = 0; j < fields[field].aggregations.length; j++) {
          if (j == index) {
            fields[field].aggregations[j].columnName = value;
          }
        }
        // field.columnName = value;
        return fields[field];
      } else {
        return fields[field];
      }
    });

    // const updateField = Object.keys(fields).map((field: any, i: number) => {
    //   if (i == mainIndex) {
    //     console.log(field);
    //     for (let j = 0; j < field.aggregations.length; j++) {
    //       if (j == index) {
    //         field.aggregations[j].columnName = value;
    //       }
    //     }
    //     // field.columnName = value;
    //     return field;
    //   } else {
    //     return field;
    //   }
    // });
    // const updateField = fields.map((field: any, i: number) => {
    //   if (i == index) {
    //     console.log(field);
    //     field.columnName = value;
    //     return field;
    //   } else {
    //     return field;
    //   }
    // });
    setFields({ ...changeToNeededDataType(updateField) });
    props.selectedItemsInProject(updateField);
    // console.log(updateField);
  };

  const fieldNameChange = (value: string, index: number) => {
    // console.log(value, index);
    const updateField = fields.map((field: any, i: number) => {
      if (i == index) {
        // console.log(field);
        field.fieldName = value;
        return field;
      } else {
        return field;
      }
    });
    setFields([...updateField]);
    props.selectedItemsInProject(updateField);

    // console.log(updateField);
  };

  const operationTypeChange = (value: string, key: string, mainIndex: number, index: number) => {
    // console.log(value, index);
    const updateField = Object.keys(fields).map((field: any, i: number) => {
      if (i == mainIndex) {
        // console.log(field);
        for (let j = 0; j < fields[field].aggregations.length; j++) {
          if (j == index) {
            fields[field].aggregations[j].aggregationType = value;
          }
        }
        // field.aggregrationType = value;
        return fields[field];
      } else {
        return fields[field];
      }
    });
    // const updateField = fields.map((field: any, i: number) => {
    //   if (i == index) {
    //     console.log(field);
    //     field.aggregrationType = value;
    //     return field;
    //   } else {
    //     return field;
    //   }
    // });
    setFields({ ...changeToNeededDataType(updateField) });
    props.selectedItemsInProject(updateField);
    console.log(updateField);
  };

  const conditionsChangeHandler = (conditions: any, mainIndex: number, index: number) => {
    console.log(conditions, index);

    const updateField = Object.keys(fields).map((field: any, i: number) => {
      if (i == mainIndex) {
        console.log(field);
        for (let j = 0; j < fields[field].aggregations.length; j++) {
          if (j == index) {
            fields[field].aggregations[j].conditions = conditions ? [...conditions] : [];

            // field.aggregations[j].aggregationType = value;
          }
        }
        return fields[field];
      } else {
        return fields[field];
      }
    });
    setFields({ ...changeToNeededDataType(updateField) });
    props.selectedItemsInProject(updateField);
    console.log(updateField);

    // const updateField = fields.map((field: any, i: number) => {
    //   if (i == mainIndex) {
    //     console.log(field);
    //     for (let j = 0; j < field.aggregations.length; j++) {
    //       if (j == index) {
    //         field.aggregations[j].conditions = conditions ? [...conditions] : [];

    //         // field.aggregations[j].aggregationType = value;
    //       }
    //     }
    //     return field;
    //   } else {
    //     return field;
    //   }
    // });
    // const updateField = fields.map((field: any, i: number) => {
    //   if (i == index) {
    //     console.log(field);
    //     field.conditions = conditions ? [...conditions] : [];
    //     return field;
    //   } else {
    //     return field;
    //   }
    // });
    // setFields([...updateField]);
    // props.selectedItemsInProject(updateField);
    // console.log(updateField);
  };

  const removeField = (mainIndex: number, index: number) => {
    console.log(fields, mainIndex, index);
    const updateField = Object.keys(fields).map((field: any, i: number) => {
      if (i == mainIndex) {
        console.log(field);
        fields[field].aggregations = fields[field].aggregations.filter((item: any, ind: number) => ind != index);
        return fields[field];
      } else {
        return fields[field];
      }
    });
    console.log(updateField);
    setFields({ ...changeToNeededDataType(updateField) });
    props.selectedItemsInProject(updateField);
    console.log(updateField);
  };

  const getSelectedFieldsCount = (data: any) => {
    return Object.keys(data)
      .map((field: any) => {
        if (data[field].selected) {
          return 1 as number;
        } else {
          return 0 as number;
        }
      })
      .reduce((partialSum, a) => partialSum + a, 0);
  };

  return (
    <Stack mb={1} ml={1} sx={{ minWidth: '420px' }}>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Stack direction="row" alignItems={'center'}>
            <Typography variant="subtitle1" style={{ fontWeight: 500, marginRight: '16px' }}>
              {label_project} Fields
            </Typography>
            <Badge
              color={getSelectedFieldsCount(fields) > 0 ? 'primary' : 'secondary'}
              badgeContent={getSelectedFieldsCount(fields).toString()}
            ></Badge>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Stack>
            <Grid container>
              <Grid item xs={6}>
                <Stack>
                  {Object.keys(fields)
                    .slice(0, Math.ceil(Object.keys(fields).length / 2))
                    .map((field: any, index: number) => {
                      console.log(fields[field]);
                      return (
                        <AddFields
                          currentEntityIndex={2}
                          topEntityIndex={props.topEntityIndex}
                          fieldValue={fields[field]}
                          markFieldSelected={markFieldSelected}
                          columnNameChangeHandler={columnNameChange}
                          fieldNameChangeHandler={(val: string) => fieldNameChange(val, index)}
                          operationTypeChange={operationTypeChange}
                          key={index}
                          index={index}
                          fields={[...projectFields]}
                          dataForChoosingAllowedOperations={props.entityFields}
                          conditionsChangeHandler={conditionsChangeHandler}
                          removeField={removeField}
                          addAggregation={addAggregation}
                        />
                      );
                    })}
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack>
                  {Object.keys(fields)
                    .slice(Math.ceil(Object.keys(fields).length / 2), Object.keys(fields).length)
                    .map((field: any, index: number) => {
                      console.log(fields[field]);
                      return (
                        <AddFields
                          currentEntityIndex={2}
                          topEntityIndex={props.topEntityIndex}
                          fieldValue={fields[field]}
                          markFieldSelected={markFieldSelected}
                          columnNameChangeHandler={columnNameChange}
                          fieldNameChangeHandler={(val: string) => fieldNameChange(val, index)}
                          operationTypeChange={operationTypeChange}
                          key={index}
                          index={index + Math.ceil(Object.keys(fields).length / 2)}
                          fields={[...projectFields]}
                          dataForChoosingAllowedOperations={props.entityFields}
                          conditionsChangeHandler={conditionsChangeHandler}
                          removeField={removeField}
                          addAggregation={addAggregation}
                        />
                      );
                    })}
                </Stack>
              </Grid>
            </Grid>
          </Stack>

          {/* <CognisaasButton
            isOutlined={false}
            label={'Add Field'}
            clickHandler={() => setFields([...fields, addAggregrationField])}
          /> */}
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
};

export default ProjectAggregration;
