import { ReactComponent as Error } from '../../../assets/svgs/error.svg';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import CognisaasButton from '../CognisaasButton/CognisaasButton';
import { Link } from 'react-router-dom';

import './Error.css';
import { useDispatch } from 'react-redux';
import { logoutUser } from 'store/modules/auth/auth';
import Cookies from 'universal-cookie';
import { useState, useEffect } from 'react';
import jwtDecode from 'jwt-decode';

const ErrorPage = () => {
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const decoded_token = jwtDecode<any>(cookies.get('jwt-token'));
  const [noRoleAssigned, setnoRoleAssigned] = useState(false);

  useEffect(() => {
    console.log(decoded_token);
    if (decoded_token) {
      if (decoded_token?.account?.role === null) setnoRoleAssigned(true);
      else setnoRoleAssigned(false);
    }
  }, [decoded_token]);

  const tryAgainClickHandler = () => {
    console.log('user clicked try again');
  };
  const handleOnLogout = async () => {
    await dispatch(logoutUser());
  };

  return (
    <div className="content" style={{ textAlign: 'center', marginTop: '2%' }}>
      <Grid container>
        <Grid item xs={12}>
          {noRoleAssigned ? (
            <Typography variant="h5">No role is assigned please contact your Manager</Typography>
          ) : (
            <Typography variant="h5">We`re sorry. We`ve run into an issue.</Typography>
          )}
        </Grid>
        <Grid sx={{ margin: '16px 0' }} item xs={12}>
          <CognisaasButton isOutlined={false} label="Try Again" clickHandler={tryAgainClickHandler}></CognisaasButton>
        </Grid>
        <Grid sx={{ marginBottom: '16px' }} item xs={12}>
          {/* <CognisaasButton isOutlined="false" label="Try Again" clickhandler={tryAgainClickHandler}></CognisaasButton> */}
          <Typography variant="subtitle1">
            If that doesnt work try
            <Link style={{ textDecoration: 'none' }} to={'/login/'} onClick={() => handleOnLogout()}>
              {' '}
              signing out
            </Link>{' '}
            and back in
          </Typography>
        </Grid>
      </Grid>
      <Error></Error>
    </div>
  );
};

export default ErrorPage;
