import { format } from 'date-fns';
// import { getuid } from 'process';
import { getLoggedInUserUid, getUserUid } from 'utils/getUid';

export const AddProjectTemplateFormInitialValues = {
  projectTemplateName: '',
  projectTemplateOwner: 'Not Assigned',
  projectTargetNumberOfDays: 0,
};

export const addTemplateTaskInitialValues = {
  taskName: '',
  durationTime: '',
  taskOwner: { name: 'Not Assigned', id: '15243' },
  priority: 'Medium',
  implementationFee: 0,
  description: '',
  shareWithClient: false,
  isMilestone: false,
  isTeamTask: true,
};

export const updateProjectTemplate = (payload: any) => {
  console.log(payload);
  const data = {
    check_duration: '',
    project_rollout: 0,
    section: [...payload.sections],
    template_fees: '',
    template_owner_name: payload.templateOwner,
    template_owner_uid: getUserUid(payload.templateOwner),
    template_project_created_on: format(new Date(), 'yyyy-MM-dd hh:MM:ss'),
    template_project_name: payload.templateName,
    template_project_rollout: '',
    template_project_target_days: payload.templateTargetDays,
    template_project_uid: payload.newTemplateUid,
    user_uid: getLoggedInUserUid(),
  };
  console.log(data);
  return data;
};

export const updateUsecaseTemplate = (payload: any) => {
  const data = {
    dependency_case_feature_config: '',
    check_duration: 0,
    feature: [...payload.features],
    product_id: '',
    product_uid: null,
    project_rollout: 0,
    section: [...payload.sections],
    template_case_created_on: format(new Date(), 'yyyy-MM-dd hh:MM:ss'),
    template_case_name: payload.templateName,
    template_case_target_days: payload.templateTargetDays,
    template_case_uid: payload.newTemplateUid,
    template_fees: '',
    template_owner_name: payload.templateOwner,
    template_owner_uid: getUserUid(payload.templateOwner),
    user_uid: getLoggedInUserUid(),
  };
  return data;
};

// Method to Convert Time Input Value to minutes to store in Database
export const valueToMinutes = (val: any) => {
  console.log(val);
  const inputLogValue: any[] = [];
  let valueInMinutes = 0;
  let minute = 0;
  let hour = 0;
  let day = 0;
  let week = 0;
  let chunk = '';
  console.log('HEEELO', val);
  for (const s of val) {
    if (s === ' ') {
      continue;
    }
    if (!isNaN(s)) {
      chunk = chunk.concat(s);
    } else {
      chunk = chunk.concat(s);
      inputLogValue.push(chunk);
      chunk = '';
    }
  }
  console.log(inputLogValue);
  inputLogValue.forEach((val: any) => {
    const char = val.slice(-1);
    console.log({ val });
    console.log({ char });
    if (char == 'm') {
      minute = parseInt(val.slice(0, -1));
      valueInMinutes += minute;
    }
    if (char == 'h') {
      hour = parseInt(val.slice(0, -1));
      valueInMinutes += hour * 60; //convert hours => 60minutes
    }
    if (char == 'd') {
      day = parseInt(val.slice(0, -1));
      valueInMinutes += day * 8 * 60;
      // valueInMinutes += 8;
      // valueInMinutes += 60;
      // valueInMinutes += (day  8  60)            //convert days => 8 hours => 60minutes
    }
    if (char == 'w') {
      week = parseInt(val.slice(0, -1));
      valueInMinutes += week * 5 * 8 * 60; //convert week to 5days => 8 hours => 60minutes
      // valueInMinutes += 5;
      // valueInMinutes += 8 * 60;
    }
  });
  return valueInMinutes;
};

// Method used to Convert Database Value to be used in the UI
export const convertMinutes = (num: number) => {
  if (num === 0) {
    return '0m';
  } else {
    let hour = Math.floor(num / 60); // 60 minutes in hour
    let day = Math.floor(hour / 8); // 8 hours a day office time
    const week = Math.floor(day / 5); // 5 days a week office time
    const minute = num - 60 * hour; // remaining minutes

    hour = hour - day * 8; // remaining hours
    day = day - week * 5; // remaining days

    let timeLogValue = ''; // Var to store Time Log Value

    if (week > 0) {
      timeLogValue = timeLogValue.concat(`${week}w `);
    }
    if (day > 0) {
      timeLogValue = timeLogValue.concat(`${day}d `);
    }
    if (hour > 0) {
      timeLogValue = timeLogValue = timeLogValue.concat(`${hour}h `);
    }
    if (minute > 0) {
      timeLogValue = timeLogValue.concat(`${minute}m `);
    }

    timeLogValue = timeLogValue.slice(0, -1); // To remove the last blank space

    return timeLogValue;
  }
};

export const updateTaskInitialValues = (details: any) => {
  if (details.template_project_uid) {
    const data = {
      taskName: details.template_task_detail,
      durationTime: convertMinutes(details.days_to_start),
      taskOwner: { name: details.template_task_owner, id: getUserUid(details.template_task_owner) },
      priority: details.template_task_priority,
      implementationFee: details.template_task_percentage,
      description: details.template_task_description,
      shareWithClient: details.template_task_client_check == 0 ? false : true,
      isMilestone: details.template_milestone_task == 0 ? false : true,
      isTeamTask: details.task_type == 'Company task' ? true : false,
    };
    return data;
  } else {
    const data = {
      taskName: details.template_task_detail,
      durationTime: convertMinutes(details.days_to_start),
      taskOwner: { name: details.template_task_owner, id: getUserUid(details.template_task_owner) },
      priority: details.template_task_priority,
      implementationFee: details.template_task_percentage,
      description: details.template_task_description,
      shareWithClient: details.template_task_client_check == 0 ? false : true,
      isMilestone: details.template_milestone_task == 0 ? false : true,
      isTeamTask: details.task_type == 'Company task' ? true : false,
    };
    return data;
  }
};

export const updateProjectTemplateTask = (detail: any) => {
  console.log(detail);
  const data = {
    days_to_start: detail.durationTime,
    template_milestone_task: detail.isMilestone ? '1' : '0',
    template_project_uid: detail.template_project_uid,
    template_task_client_check: detail.shareWithClient ? '1' : '0',
    template_task_description: detail.description,
    template_task_detail: detail.taskName,
    template_task_owner: detail.taskOwner.name,
    template_task_percentage: detail.implementationFee,
    template_task_priority: detail.priority,
    template_task_uid: detail.template_task_uid,
    user_uid: detail.taskOwner.id,
  };

  console.log(data);
  return data;
};

export const updateUsecaseTemplateTask = (detail: any) => {
  const data = {
    days_to_start: detail.durationTime,
    template_milestone_task: detail.isMilestone ? '1' : '0',
    template_case_uid: detail.template_case_uid,
    template_task_client_check: detail.shareWithClient ? '1' : '0',
    template_task_description: detail.description,
    template_task_detail: detail.taskName,
    template_task_owner: detail.taskOwner.name,
    template_task_percentage: detail.implementationFee,
    template_task_priority: detail.priority,
    template_task_uid: detail.template_task_uid,
    user_uid: detail.taskOwner.id,
  };

  console.log(data);
  return data;
};
