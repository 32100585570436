import { Typography, Stack, Avatar } from '@mui/material';
import CognisaasLoader from '../../shared/CognisaasLoader/CognisaasLoader';
import CognisaasTabs from '../../shared/CognisaasTabs';
import { alpha, styled } from '@mui/material/styles';
import CognisaasTaskStageIndicator from 'components/shared/CognisaasTaskStageIndicator/CognisaasTaskStageIndicator';
import {
  DataGridPremium,
  gridClasses,
  GridRenderCellParams,
  useGridApiContext,
  DataGridPremiumProps,
  useGridSelector,
  GridToolbar,
  gridFilteredDescendantCountLookupSelector,
  GridRenderEditCellParams,
  GridColumnOrderChangeParams,
} from '@mui/x-data-grid-premium';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { Link, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import {
  selectAllGeneralStatus,
  selectAllTaskPriorities,
  selectAllTaskStages,
  selectTaskCustomFields,
  selectUsersOfCompany,
  selectUsersOfCompanyStatus,
  selectTaskCustomFieldsStatus,
  selectAllProjectMembersForClientContact,
} from '../../../store/modules/Common/selector';
import {
  fetchCustomFieldsOfTask,
  getAllProjectMembersThatAreClientContact,
  getAllUsers,
  getCustomerDetail,
} from '../../../store/modules/Common/slice';
// import { ReactComponent as NoRecords } from '../../../assets/svgs/no_records.svg';
// import { Container } from './Tasks.styles';
import Box from '@mui/material/Box';
// import { selectClientList } from 'store/modules/ManageClient/ClientList/selector';

import ReportsHeader, { ReportsPages } from '../ReportsHeader/ReportsHeader';
import ChartView from '../../shared/Charts/ChartView/ChartView';
import CustomiseDrawer from '../../shared/Charts/CustomiseDrawer/CustomiseDrawer';

import { selectAllChartsConfig } from '../../../store/modules/Charts/selector';
import { getGraphConfigurations } from 'store/modules/Charts/slice';
import { useAppSelector, useAppDispatch } from '../../../store/hooks';
import { getTaskByTaskUid, updateTask, updateTaskCustomField } from '../../../store/modules/Task/slice';

import { graphSchema } from '../../shared/Charts/CustomiseDrawer/types';

import { requestState } from 'store/modules/types';
import {
  generateDataWhileMappingCustomFieldsAsNormalFields,
  generateMappedCustomFieldKey,
  generateOrderedColumn,
  isNull,
  isUndefined,
} from '../../../utils/dataHelpers';
import {
  fetchClientClosedTasks,
  fetchClientOpenTasks,
  fetchTeamClosedTasks,
  fetchTeamOpenTasks,
} from 'store/modules/Reports/AllTasks/slice';

import {
  selectTeamOpenTasks,
  selectTeamClosedTasks,
  selectClientOpenTasks,
  selectClientClosedTasks,
  selectAllSubtasks,
} from 'store/modules/Reports/AllTasks/selector';
import { availableDateRangeOptions, FilterableData, generateOptionsForThisDataKey } from 'utils/filteringFunctions';
import { availablePriorities } from '../constants';
import { addFilterGroup, updateFilter } from 'store/modules/AllFilters/slice';
import { selectAllFilters } from 'store/modules/AllFilters/selector';
import { generateTableColumnsWithCustomFiltersMapped } from 'components/shared/Tables/CogniTable';
import FilterTypeSelector from 'components/shared/CognisaasFilters/FilterTypeSelector/FilterTypeSelector';
import SelectedFilterList from 'components/shared/CognisaasFilters/SelectedFilterList/SelectedFilterList';
import NoRecordsFound from 'components/shared/NoRecords/NoRecordsFound';
import UpdateTaskDrawer from '../../TaskModule/TaskView/UpdateTaskDrawer';
import Cookies from 'universal-cookie';
import { getTaskDeltaXWithDueDate, getTaskTimeTrackingFields } from './utils';
import useClientList from 'components/shared/Hooks/UseClientList';
import ViewDate from 'components/shared/ViewDate/ViewDate';
import {
  label_client,
  label_delivery_date,
  label_due_date,
  label_project,
  label_task,
  label_use_case,
} from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { SelectEditDropdownCell, SelectEditDateInputCell } from 'components/shared/Tables/InlineEditTable';
import CognisaasToast from 'components/shared/CognisaasToast/CognisaasToast';
import { getContactUidByContactName, getUserUid } from 'utils/getUid';
import CognisaaasTooltip from 'components/shared/CognisaasTooltip/CognisaasTooltip';
import CognisaasCheckBox from 'components/shared/CognisaasCheckbox/CognisaasCheckbox';
import checkAccessForRouting from 'components/shared/Hooks/checkAccessForRouting';
import useCommonAppConfig from 'components/Common/useCommonAppConfig';
import useCustomNames from 'components/shared/CustomNames/useCustomNames';
import { getUserActiveState, INACTIVE_USER_COLOR } from 'utils/userHelpers';
import { getMaxColumnWidth } from 'utils/getMaxColumnWidth';
import { format } from 'date-fns';

const statusColorMap: Record<string, string> = {
  red: '#E75B5C',
  amber: '#EFA958',
  green: '#4f9f52',
  blue: '#4390e1',
  grey: '#7a7a7a',
};

const cookies = new Cookies();

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGridPremium)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
        },
      },
    },
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: 600,
  width: '100%',
  '& .MuiFormGroup-options': {
    alignItems: 'center',
    paddingBottom: theme.spacing(1),
    '& > div': {
      minWidth: 100,
      margin: theme.spacing(2),
      marginLeft: 0,
    },
  },
}));

function stringToHslColor(str: string, s: number, l: number) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const h = hash % 360;
  return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToHslColor(name, 30, 80),
    },
    children: (
      <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#000000' }}>
        {name.split(' ')[0][0]}
        {name.indexOf(' ') >= 0 ? name.split(' ')[1][0] : null}
      </Typography>
    ),
  };
}

interface LocationParams {
  pathname: string;
  state: any;
  search: string;
  hash: string;
  key: string;
}

const Tasks = () => {
  const dispatch = useAppDispatch();
  const users = useAppSelector(selectUsersOfCompany);
  const [idName, setIdName] = useState<string>('');
  const graphConfigs: graphSchema[] = useAppSelector(selectAllChartsConfig);
  const taskCustomFieldsStatus = useAppSelector(selectTaskCustomFieldsStatus);
  const allSubtasks = useAppSelector(selectAllSubtasks);

  const graphs: graphSchema[] = graphConfigs.filter((graph: graphSchema) => graph.idName === idName);
  const [drawerState, setDrawerState] = useState<boolean>(false);
  const [usersOfCompany, setUsersOfCompany] = useState<any[]>([]);
  const [clientUid, setClientUid] = useState<any>(null);
  const [taskToOpen, setTaskToOpen] = useState<string>('');
  const [updateTaskDrawer, setUpdateTaskDrawer] = useState<boolean>(false);
  const [customFieldStatus, setCustomFieldStatus] = useState<boolean>(false); // used to prevent loading page while inline editing custom fields(e.g if we edit custom value it will trigger page reload this will prevent it.)
  const [tableColumns, setTableColumns] = useState<any[]>([]);
  const tabs = ['All', 'Our Team', 'Clients Team'];
  const [selectedMainTab, setSelectedMainTab] = useState(0);
  const location: LocationParams = useLocation();
  const [showOnlyMilestoneTasks, setShowOnlyMilestoneTasks] = useState<boolean>(false);

  const [selectedInnerTab, setInnerTab] = useState<'all' | 'open' | 'closed'>('all');

  const taskCustomfields = useAppSelector(selectTaskCustomFields);

  // actual new data - aman
  const teamOpenTasks = useAppSelector(selectTeamOpenTasks);
  const teamClosedTasks = useAppSelector(selectTeamClosedTasks);
  const clientOpenTasks = useAppSelector(selectClientOpenTasks);
  const clientClosedTasks = useAppSelector(selectClientClosedTasks);

  // only team tasks are having custom fields
  const [cfMappedTeamOpenTasks, setCfMappedTeamOpenTasks] = useState<any[]>([]);
  const [cfMappedTeamClosedTasks, setCfMappedTeamClosedTasks] = useState<any[]>([]);
  const [cfMappedTeamTasksCloumns, setCfMappedTeamTasksCloumns] = useState<any[]>([]);
  const [filteredTeamTasks, setFilteredTeamTasks] = useState<any[]>([]);
  const allFilters = useAppSelector(selectAllFilters);

  const [filteredClientTasks, setFiteredClientTasks] = useState<any[]>([]);
  const [filteredAllTasks, setFilteredAllTasks] = useState<any[]>([]);
  const [cfMappedAllOpenTasks, setCfMappedAllOpenTasks] = useState<any[]>([]);
  const [cfMappedAllClosedTasks, setCfMappedAllClosedTasks] = useState<any[]>([]);
  const clientList = useClientList();
  const allUsers = useAppSelector(selectUsersOfCompany);
  const allUsersStatus = useAppSelector(selectUsersOfCompanyStatus);
  const allGeneralStatus = useAppSelector(selectAllGeneralStatus);
  const allTaskStages = useAppSelector(selectAllTaskStages);
  const allTaskPriorities = useAppSelector(selectAllTaskPriorities);
  const uniqueReportTaskCFColumnId = 'reportCFTaskListTable';
  const uniqueReportTaskColumnId = 'reportTaskListTable';
  const [countCameFromChartClick, setCountCameFromChartClick] = useState<number>(-1);
  const [isDataLoadedAndFilteredApplied, setIsDataLoadedAndFilteredApplied] = useState<boolean>(false);

  const [alertDetails, setAlertDetails] = useState<{
    isVisible: boolean;
    type: 'success' | 'failure';
    message: string;
  }>({ isVisible: false, type: 'success', message: '' });
  const [graphData, setGraphData] = useState<graphSchema>({} as graphSchema);
  const [formActionType, setFormActionType] = useState<'save' | 'update'>('save');
  const allProjectMembersWhichAreClientContactForCustomer = useAppSelector(selectAllProjectMembersForClientContact);
  const {
    taskStagesDropdownValues,
    displayDataWithCustomNames,
    taskPrioritiesDropdownValues,
    getStandardRowDataWithoutCustomNames,
  } = useCustomNames();
  const taskAllStages = taskStagesDropdownValues.map((item: any) => item.custom_stage_name);
  const taskAllPriorities = taskPrioritiesDropdownValues.map((item: any) => item.custom_priority_name);

  // pagination
  // const [allTasksPageSize, setAllTasksPageSize] = useState<number>(10);
  // const [openTasksPageSize, setOpenTasksPageSize] = useState<number>(10);
  // const [closedTasksPageSize, setClosedTasksPageSize] = useState<number>(10);

  let myTableStateForAllTasksTab: any = {};
  let myTableStateForOpenTasksTab: any = {};
  let myTableStateForClosedtasksTab: any = {};
  const { saveAppTableState, getTableState } = useCommonAppConfig();
  const initialStateAllTasks: any = {
    columns: {
      columnVisibilityModel: {},
    },
    pinnedColumns: {
      left: ['task_name'],
    },
    pagination: {
      page: 0,
      pageSize: 10,
    },
    filter: {},
    ...getTableState('projectReportAllTasksPage'),
  };

  const initialStateOpenTasks = {
    columns: {
      columnVisibilityModel: {},
    },
    pinnedColumns: {
      left: ['task_name'],
    },
    pagination: {
      page: 0,
      pageSize: 10,
    },
    ...getTableState('projectReportOpenTasksPage'),
  };

  const initialStateClosedTasks = {
    columns: {
      columnVisibilityModel: {},
    },
    pinnedColumns: {
      left: ['task_name'],
    },
    pagination: {
      page: 0,
      pageSize: 10,
    },
    ...getTableState('projectReportClosedTasksPage'),
  };

  const handleTableSave = (idName: string, thisTableState: any) => {
    saveAppTableState(idName, thisTableState);
  };

  const handleClientAccess = (params: GridRenderCellParams) => {
    const arrayToCheck = clientList?.map((ele: any) => ele.client_uid);
    const hasAccessToClient = checkAccessForRouting({ arrayToCheck: arrayToCheck, uid: params.row.client_uid });

    if (hasAccessToClient) {
      return (
        <Link to={getClient(params.row)} style={{ padding: '5px 0' }}>
          {params.value}
        </Link>
      );
    }
    if (!hasAccessToClient) {
      return (
        <CognisaaasTooltip
          title={<Typography sx={{ padding: '5px' }}>You do not have access to this {label_client}</Typography>}
        >
          <Typography variant="subtitle1">{params.value}</Typography>
        </CognisaaasTooltip>
      );
    }
  };

  const openTask = async (client_uid: string, task: any) => {
    const client = {
      client_uid: client_uid,
    };
    setClientUid(client);
    const taskResult = await dispatch(getTaskByTaskUid(task.task_uid)).unwrap();
    setTaskToOpen(taskResult);
    setUpdateTaskDrawer(true);
  };
  useEffect(() => {
    dispatch(getCustomerDetail());
    const customer_uid = cookies.get('cogni_customer_uid');
    const user_uid = cookies.get('cogni_user_uid');
    dispatch(
      getGraphConfigurations({
        type: 'task',
        customer_uid,
        user_uid,
      })
    );
    if (users.length == 0) {
      dispatch(getAllUsers());
    }

    dispatch(getAllProjectMembersThatAreClientContact());
    // *real dispatches
    dispatch(fetchTeamOpenTasks());
    dispatch(fetchTeamClosedTasks());
    dispatch(fetchClientOpenTasks());
    dispatch(fetchClientClosedTasks());
    dispatch(fetchCustomFieldsOfTask());
  }, []);

  useEffect(() => {
    if (clientList.length > 0) {
      setTableColumns(generateOrderedColumn(columns, uniqueReportTaskColumnId));
    } else if (clientList.length == 0) {
      setTableColumns(generateOrderedColumn(columns, uniqueReportTaskColumnId));
    }
  }, [clientList]);

  useEffect(() => {
    if (allUsersStatus !== 'success') {
      dispatch(getAllUsers());
    }
  }, []);

  const addSubtasks = (data: any[]) => {
    const dataTreeRows = [];
    for (let i = 0; i < data.length; i++) {
      const subTasks = allSubtasks.filter((subtask) => subtask.task_uid === data[i].task_uid);
      dataTreeRows.push({ ...data[i], path: [data[i].task_uid] });
      for (let j = 0; j < subTasks.length; j++) {
        const subTaskData: any = {
          ...subTasks[j],
          task_name: subTasks[j].subtask_detail,
          task_owner: subTasks[j].subtask_owner,
          task_status: subTasks[j].subtask_status,
          task_start_date: subTasks[j].subtask_start_date,
          task_due_date: subTasks[j].subtask_due_date,
          task_closed_date: subTasks[j].subtask_closed_date,
          task_stage: subTasks[j].subtask_stage,
          task_priority: subTasks[j].subtask_priority,
          client_name: data[i].client_name,
          task_planned_start_date: subTasks[j].subtask_planned_start_date,
          path: [data[i].task_uid, subTasks[j].subtask_uid],
        };
        dataTreeRows.push(subTaskData);
      }
    }
    return dataTreeRows;
    // return dataTreeRows;
  };

  useEffect(() => {
    if (teamOpenTasks.status === 'success') {
      setCfMappedTeamOpenTasks(
        addSubtasks(
          generateDataWhileMappingCustomFieldsAsNormalFields(
            [...teamOpenTasks.data],
            'custom_values',
            'field_name',
            'field_value',
            'field_uid',
            'field_type'
          )
        )
      );
    }
  }, [teamOpenTasks]);

  useEffect(() => {
    if (teamClosedTasks.status === 'success') {
      setCfMappedTeamClosedTasks(
        addSubtasks(
          generateDataWhileMappingCustomFieldsAsNormalFields(
            [...teamClosedTasks.data],
            'custom_values',
            'field_name',
            'field_value',
            'field_uid',
            'field_type'
          )
        )
      );
    }
  }, [teamClosedTasks]);

  // *setting all tasks
  useEffect(() => {
    if (
      teamOpenTasks.status === 'success' &&
      teamClosedTasks.status === 'success' &&
      clientOpenTasks.status === 'success' &&
      clientClosedTasks.status === 'success'
    ) {
      setCfMappedAllOpenTasks(
        addSubtasks(
          generateDataWhileMappingCustomFieldsAsNormalFields(
            [...teamOpenTasks.data, ...clientOpenTasks.data],
            'custom_values',
            'field_name',
            'field_value',
            'field_uid',
            'field_type'
          )
        )
      );

      setCfMappedAllClosedTasks(
        addSubtasks(
          generateDataWhileMappingCustomFieldsAsNormalFields(
            [...teamClosedTasks.data, ...clientClosedTasks.data],
            'custom_values',
            'field_name',
            'field_value',
            'field_uid',
            'field_type'
          )
        )
      );
      setIsDataLoadedAndFilteredApplied(true);
    }
  }, [teamOpenTasks, teamClosedTasks, clientOpenTasks, clientClosedTasks]);

  const columns = [
    {
      field: '.',
      headerName: '',
      minWidth: 40,
      maxWidth: 60,
      cellClassName: 'remove-padding',
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Stack direction="row">
            <CognisaaasTooltip
              title={
                <Box sx={{ height: '30px', padding: '12px' }}>
                  <Typography variant="subtitle1">Implementation fee</Typography>
                </Box>
              }
            >
              <Stack marginRight={'6px'}>
                {params.row.task_implementation_fee_percentage &&
                  parseInt(params.row.task_implementation_fee_percentage) > 0 && (
                    <Stack sx={{ marginLeft: '0px' }} ml={1}>
                      <Typography variant="h6" className="label-text">
                        $
                      </Typography>
                    </Stack>
                  )}
              </Stack>
            </CognisaaasTooltip>
            <Stack sx={{ padding: '5px 0px' }}>
              {params.row.milestone_task == '1' ? (
                <Stack>
                  <CognisaaasTooltip
                    title={
                      <Typography variant="subtitle1" style={{ padding: '10px 5px' }}>
                        Milestone {label_task}: &nbsp;
                        {params.row.task_stage}
                      </Typography>
                    }
                  >
                    <Stack
                      style={{
                        marginTop: '3px',
                        marginLeft: !(
                          params.row.task_implementation_fee_percentage &&
                          parseInt(params.row.task_implementation_fee_percentage) > 0
                        )
                          ? '14px'
                          : '0px',
                      }}
                    >
                      <CognisaasTaskStageIndicator
                        isMileStoneTask={params.row.milestone_task}
                        stage={params.row.task_stage}
                      />
                    </Stack>
                  </CognisaaasTooltip>
                </Stack>
              ) : (
                <div style={{ paddingRight: '12px' }}>&nbsp;</div>
              )}
            </Stack>
          </Stack>
        );
      },
    },

    {
      renderHeader: () => {
        return (
          <Typography variant="subtitle1" style={{ marginLeft: '22px' }}>
            {label_task} Name
          </Typography>
        );
      },
      field: 'task_name',
      width: 240,
      renderCell: (params: GridRenderCellParams) => {
        if (params.row.subtask_detail) {
          if (params.value.length > 30) {
            return (
              <CognisaaasTooltip
                title={
                  <Typography variant="subtitle1" style={{ padding: '10px 5px' }}>
                    {params.value}
                  </Typography>
                }
              >
                <Typography
                  variant="subtitle1"
                  className="label-text"
                  onClick={() => openTask(params.row.client_uid, params.row)}
                  style={{ padding: '5px 0' }}
                >
                  {params.value}
                </Typography>
              </CognisaaasTooltip>
            );
          } else {
            return (
              <Typography
                variant="subtitle1"
                className="label-text"
                onClick={() => openTask(params.row.client_uid, params.row)}
                style={{ padding: '5px 0' }}
              >
                {params.value}
              </Typography>
            );
          }
        } else {
          if (params.value.length > 30) {
            return (
              <CognisaaasTooltip
                title={
                  <Typography variant="subtitle1" style={{ padding: '10px 5px' }}>
                    {params.value}
                  </Typography>
                }
              >
                <Stack direction="row">
                  <Typography
                    variant="subtitle1"
                    style={{ color: '#2D60F6', padding: '5px 0' }}
                    className="cognisaas-pointer"
                    onClick={() => openTask(params.row.client_uid, params.row)}
                  >
                    {params.value}
                  </Typography>
                </Stack>
              </CognisaaasTooltip>
            );
          } else {
            return (
              <Stack direction="row">
                <Typography
                  variant="subtitle1"
                  style={{ color: '#2D60F6', padding: '5px 0' }}
                  className="cognisaas-pointer"
                  onClick={() => openTask(params.row.client_uid, params.row)}
                >
                  {params.value}
                </Typography>
              </Stack>
            );
          }
        }
      },
    },
    {
      headerName: `${label_client} Name`,
      field: 'client_name',
      width: 240,
      renderCell: (params: GridRenderCellParams) => handleClientAccess(params),
    },
    {
      headerName: `${label_project} Name`,
      field: 'project_name',
      width: 240,
      renderCell: (params: GridRenderCellParams) => {
        if (params.value) {
          return (
            <Link to={getProject(params.row)} style={{ padding: '5px 0' }}>
              {params.value}
            </Link>
          );
        } else {
          return <Typography>-</Typography>;
        }
      },
    },
    {
      headerName: `${label_use_case} name`,
      field: 'case_name',
      width: 240,
      renderCell: (params: GridRenderCellParams) => {
        if (params.value) {
          return (
            <Link to={getUsecase(params.row)} style={{ padding: '5px 0' }}>
              {params.value}
            </Link>
          );
        } else {
          return <Typography>-</Typography>;
        }
      },
    },
    {
      headerName: 'Phase name',
      field: 'section_detail',
      width: 240,
      renderCell: (params: GridRenderCellParams) => <Typography>{params.value}</Typography>,
    },
    {
      headerName: `${label_task} Owner`,
      field: 'task_owner',
      width: getMaxColumnWidth(usersOfCompany),
      editable: true,
      renderEditCell: (params: any) => {
        let dropdownValues: string[] = [];
        if (params.row.is_team_task === '0') {
          dropdownValues = [
            ...allProjectMembersWhichAreClientContactForCustomer.data
              .filter((item: any) => item.project_uid === params.row.project_uid)
              .map((item: any) => item.client_user_name),
          ];
          dropdownValues.push('Not Assigned');
        } else {
          dropdownValues = usersOfCompany;
        }
        return SelectEditDropdownCell(params, dropdownValues, handleInlineEditValue);
      },
      renderCell: function (params: GridRenderCellParams) {
        return (
          <Stack direction="row" style={{ padding: '5px 0' }}>
            <Stack spacing={1} direction={'row'} alignItems={'center'}>
              <Avatar style={{ height: '34px', width: '34px', marginTop: '3px' }} {...stringAvatar(params.value)} />
              {getUserActiveState(getUserUid(params.value), users) === '0' ? (
                <Typography style={{ color: INACTIVE_USER_COLOR }} mt={1} variant="subtitle1">
                  {params.value}&nbsp;(Inactive)
                </Typography>
              ) : (
                <Typography mt={1} variant="subtitle1">
                  {params.value}
                </Typography>
              )}
            </Stack>
          </Stack>
        );
      },
    },
    {
      headerName: `${label_task} Status`,
      field: 'task_status',
      width: 150,

      renderCell: (params: GridRenderCellParams) => (
        <Stack style={{ padding: '5px 0' }}>
          <Stack spacing={1} direction="row">
            <span
              style={{ marginRight: '4px' }}
              className={
                params.value == 'red'
                  ? 'red_status_box_task'
                  : params.value == 'amber'
                  ? 'amber_status_box_task'
                  : params.value == 'green'
                  ? 'green_status_box_task'
                  : params.value == 'blue'
                  ? 'blue_status_box_task'
                  : params.value == 'grey'
                  ? 'grey_status_box_task'
                  : ''
              }
            ></span>
            {params.value == 'red'
              ? 'Overdue'
              : params.value == 'amber'
              ? 'At Risk'
              : params.value == 'green'
              ? 'On Track'
              : params.value == 'blue'
              ? 'Completed'
              : params.value == 'grey'
              ? 'Deferred'
              : ''}
          </Stack>
        </Stack>
      ),
    },
    {
      headerName: `${label_task} Stage`,
      field: 'task_stage',
      width: getMaxColumnWidth(taskAllStages),
      columnType: 'status',
      enableSorting: true,
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams) =>
        SelectEditDropdownCell(params, taskAllStages, handleInlineEditValue),
    },
    {
      headerName: `${label_task} Priority`,
      field: 'task_priority',
      width: getMaxColumnWidth(taskAllPriorities),
      enableSorting: true,
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams) =>
        SelectEditDropdownCell(params, taskAllPriorities, handleInlineEditValue),
    },
    {
      field: 'task_planned_start_date',
      headerName: 'Planned Start Date',
      minWidth: 200,
      flex: 1,
      editable: true,
      type: 'date',
      // valueGetter: ({ value }: any) => value && new Date(value),
      renderCell: (params: GridRenderCellParams) => {
        return <ViewDate date={params.row.task_planned_start_date} />;
      },
      renderEditCell: (params: GridRenderEditCellParams) => SelectEditDateInputCell(params, handleInlineEditValue),
    },
    {
      headerName: 'Start Date',
      field: 'task_start_date',
      width: 160,
      editable: true,
      type: 'date',
      // valueGetter: ({ value }: any) => value && new Date(value),
      renderCell: (params: GridRenderCellParams) => {
        return <ViewDate date={params.row.task_start_date} />;
      },
      renderEditCell: (params: GridRenderEditCellParams) => SelectEditDateInputCell(params, handleInlineEditValue),
    },
    {
      headerName: label_due_date,
      field: 'task_due_date',
      width: 160,
      editable: true,
      type: 'date',
      // valueGetter: ({ value }: any) => value && new Date(value),
      renderCell: (params: GridRenderCellParams) => {
        return <ViewDate date={params.row.task_due_date} />;
      },
      renderEditCell: (params: GridRenderEditCellParams) => SelectEditDateInputCell(params, handleInlineEditValue),
    },
    {
      headerName: label_delivery_date,
      field: 'task_closed_date',
      width: 160,
      type: 'date',
      // valueGetter: ({ value }: any) => value && new Date(value),
      renderCell: (params: GridRenderCellParams) => {
        return <ViewDate date={params.row.task_closed_date} />;
      },
    },
    {
      headerName: 'Delta with ' + label_due_date,
      field: 'task_uid',
      width: 240,
      editable: true,

      renderCell: (params: GridRenderCellParams) => {
        const { openTaskDeltaWithDueDateFullText, closedTaskDeltaWithDueDateFullText } = getTaskDeltaXWithDueDate(
          params.row
        );

        return (
          <Typography variant="subtitle1" color={statusColorMap[params.row.task_status]}>
            {selectedInnerTab === 'open' ? openTaskDeltaWithDueDateFullText : closedTaskDeltaWithDueDateFullText}
          </Typography>
        );
      },
    },
    {
      headerName: 'Implementation fee (in %)',
      width: 200,
      field: 'task_implementation_fee_percentage',
      renderCell: (params: any) => {
        if (!isNull(params?.row[params.field]) && params?.row[params.field].length > 0) {
          return `${params.row[params.field]}%`;
        } else {
          return <>-</>;
        }
      },
    },
    {
      headerName: 'Description',
      field: 'task_description',
      width: 240,
    },
    {
      headerName: 'Original Effort Estimate',
      field: 'effort_estimate',
      width: 160,
      renderCell: (params: GridRenderCellParams) => {
        const { effortEstimate = '-' } = getTaskTimeTrackingFields(params.row);
        return (
          <Typography variant="subtitle1" style={{ padding: '5px 0' }}>
            {effortEstimate}
          </Typography>
        );
      },
    },
    {
      headerName: 'Total Time Logged',
      field: 'total_time_spent',
      width: 160,
      renderCell: (params: GridRenderCellParams) => {
        const { totalTimeLogged = '-' } = getTaskTimeTrackingFields(params.row);
        return (
          <Typography variant="subtitle1" style={{ padding: '5px 0' }}>
            {totalTimeLogged}
          </Typography>
        );
      },
    },
  ];

  // all + team tabs
  const teamColumns = [
    {
      field: '.',
      headerName: '',
      minWidth: 40,
      maxWidth: 60,
      cellClassName: 'remove-padding',
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Stack direction="row">
            <CognisaaasTooltip
              title={
                <Box sx={{ height: '30px', padding: '12px' }}>
                  <Typography variant="subtitle1">Implementation fee</Typography>
                </Box>
              }
            >
              <Stack marginRight={'6px'}>
                {params.row.task_implementation_fee_percentage &&
                  parseInt(params.row.task_implementation_fee_percentage) > 0 && (
                    <Stack sx={{ marginLeft: '0px' }} ml={1}>
                      <Typography variant="h6" className="label-text">
                        $
                      </Typography>
                    </Stack>
                  )}
              </Stack>
            </CognisaaasTooltip>
            <Stack sx={{ padding: '5px 0px' }}>
              {params.row.milestone_task == '1' ? (
                <Stack>
                  <CognisaaasTooltip
                    title={
                      <Typography variant="subtitle1" style={{ padding: '10px 5px' }}>
                        Milestone {label_task}: &nbsp;
                        {params.row.task_stage}
                      </Typography>
                    }
                  >
                    <Stack
                      style={{
                        marginTop: '3px',
                        marginLeft: !(
                          params.row.task_implementation_fee_percentage &&
                          parseInt(params.row.task_implementation_fee_percentage) > 0
                        )
                          ? '14px'
                          : '0px',
                      }}
                    >
                      <CognisaasTaskStageIndicator
                        isMileStoneTask={params.row.milestone_task}
                        stage={params.row.task_stage}
                      />
                    </Stack>
                  </CognisaaasTooltip>
                </Stack>
              ) : (
                <div style={{ paddingRight: '12px' }}>&nbsp;</div>
              )}
            </Stack>
          </Stack>
        );
      },
    },
    {
      field: 'task_name',
      renderHeader: () => {
        return <Typography variant="subtitle1">{label_task} Name</Typography>;
      },
      width: 240,
      renderCell: (params: GridRenderCellParams) => {
        if (params.row.subtask_detail) {
          return (
            <Typography
              variant="subtitle1"
              className="label-text"
              onClick={() => openTask(params.row.client_uid, params.row)}
              style={{ padding: '5px 0' }}
            >
              {params.value}
            </Typography>
          );
        } else {
          return (
            <Stack direction="row">
              <Typography
                variant="subtitle1"
                style={{ color: '#2D60F6', padding: '5px 0' }}
                className="cognisaas-pointer"
                onClick={() => openTask(params.row.client_uid, params.row)}
              >
                {params.value}
              </Typography>
            </Stack>
          );
        }
      },
    },
    {
      headerName: `${label_client} Name`,
      field: 'client_name',
      width: 240,
      renderCell: (params: GridRenderCellParams) => handleClientAccess(params),
    },
    {
      headerName: `${label_project} Name`,
      field: 'project_name',
      width: 240,
      renderCell: (params: GridRenderCellParams) => {
        if (params.value) {
          return (
            <Link to={getProject(params.row)} style={{ padding: '5px 0' }}>
              {params.value}
            </Link>
          );
        } else {
          return <Typography>-</Typography>;
        }
      },
    },
    {
      headerName: `${label_use_case} Name`,
      field: 'case_name',
      width: 240,
      renderCell: (params: GridRenderCellParams) => {
        if (params.value) {
          return (
            <Link to={getUsecase(params.row)} style={{ padding: '5px 0' }}>
              {params.value}
            </Link>
          );
        } else {
          return <Typography style={{ padding: '5px 0' }}>-</Typography>;
        }
      },
    },
    {
      headerName: 'Phase Name',
      field: 'section_detail',
      width: 240,
      renderCell: (params: GridRenderCellParams) => <Typography variant="subtitle1">{params.value}</Typography>,
    },
    {
      headerName: `${label_task} Owner`,
      field: 'task_owner',
      width: getMaxColumnWidth(usersOfCompany),
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams) =>
        SelectEditDropdownCell(params, usersOfCompany, handleInlineEditValue),
      renderCell: (params: GridRenderCellParams) => (
        <Stack direction="row" style={{ padding: '5px 0' }}>
          <Stack spacing={1} direction={'row'} alignItems={'center'}>
            <Avatar style={{ height: '34px', width: '34px', marginTop: '3px' }} {...stringAvatar(params.value)} />
            {getUserActiveState(getUserUid(params.value), users) === '0' ? (
              <Typography style={{ color: INACTIVE_USER_COLOR }} mt={1} variant="subtitle1">
                {params.value}&nbsp;(Inactive)
              </Typography>
            ) : (
              <Typography mt={1} variant="subtitle1">
                {params.value}
              </Typography>
            )}
          </Stack>
        </Stack>
      ),
    },
    {
      headerName: `${label_task} Status`,
      field: 'task_status',
      width: 150,
      renderCell: (params: GridRenderCellParams) => (
        <Stack style={{ padding: '5px 0' }}>
          <Stack spacing={1} direction="row">
            <span
              style={{ marginRight: '4px' }}
              className={
                params.value == 'red'
                  ? 'red_status_box_task'
                  : params.value == 'amber'
                  ? 'amber_status_box_task'
                  : params.value == 'green'
                  ? 'green_status_box_task'
                  : params.value == 'blue'
                  ? 'blue_status_box_task'
                  : params.value == 'grey'
                  ? 'grey_status_box_task'
                  : ''
              }
            ></span>
            {params.value == 'red'
              ? 'Overdue'
              : params.value == 'amber'
              ? 'At Risk'
              : params.value == 'green'
              ? 'On Track'
              : params.value == 'blue'
              ? 'Completed'
              : params.value == 'grey'
              ? 'Deferred'
              : ''}
          </Stack>
        </Stack>
      ),
    },
    {
      headerName: `${label_task} Stage`,
      field: 'task_stage',
      width: getMaxColumnWidth(taskAllStages),
      columnType: 'status',
      enableSorting: true,
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams) =>
        SelectEditDropdownCell(params, taskAllStages, handleInlineEditValue),
    },
    {
      headerName: `${label_task} Priority`,
      field: 'task_priority',
      width: getMaxColumnWidth(taskAllPriorities),
      enableSorting: true,
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams) =>
        SelectEditDropdownCell(params, taskAllPriorities, handleInlineEditValue),
    },
    {
      field: 'task_planned_start_date',
      headerName: 'Planned Start Date',
      minWidth: 200,
      flex: 1,
      editable: true,
      type: 'date',
      // valueGetter: ({ value }: any) => value && new Date(value),
      renderCell: (params: GridRenderCellParams) => {
        return <ViewDate date={params.row.task_planned_start_date} />;
      },
      renderEditCell: (params: GridRenderEditCellParams) => SelectEditDateInputCell(params, handleInlineEditValue),
    },
    {
      headerName: 'Start Date',
      field: 'task_start_date',
      width: 200,
      editable: true,
      type: 'date',
      // valueGetter: ({ value }: any) => value && new Date(value),
      renderCell: (params: GridRenderCellParams) => {
        return <ViewDate date={params.row.task_start_date} />;
      },
      renderEditCell: (params: GridRenderEditCellParams) => SelectEditDateInputCell(params, handleInlineEditValue),
    },
    {
      headerName: label_due_date,
      field: 'task_due_date',
      width: 200,
      editable: true,
      type: 'date',
      // valueGetter: ({ value }: any) => value && new Date(value),
      renderCell: (params: GridRenderCellParams) => {
        return <ViewDate date={params.row.task_due_date} />;
      },
      renderEditCell: (params: GridRenderEditCellParams) => SelectEditDateInputCell(params, handleInlineEditValue),
    },
    {
      headerName: label_delivery_date,
      field: 'task_closed_date',
      width: 160,
      type: 'date',
      // valueGetter: ({ value }: any) => value && new Date(value),
      renderCell: (params: GridRenderCellParams) => {
        return <ViewDate date={params.row.task_closed_date} />;
      },
    },
    {
      headerName: 'Delta with ' + label_due_date,
      field: 'task_uid',
      width: 240,
      renderCell: (params: GridRenderCellParams) => {
        const { openTaskDeltaWithDueDateFullText, closedTaskDeltaWithDueDateFullText } = getTaskDeltaXWithDueDate(
          params.row
        );
        return (
          <Typography variant="subtitle1" color={statusColorMap[params.row.task_status]} style={{ padding: '5px 0' }}>
            {selectedInnerTab === 'open' ? openTaskDeltaWithDueDateFullText : closedTaskDeltaWithDueDateFullText}
          </Typography>
        );
      },
    },
    {
      headerName: 'Implementation fee (in %)',
      width: 200,
      field: 'task_implementation_fee_percentage',
      renderCell: (params: any) => {
        if (!isNull(params?.row[params.field]) && params?.row[params.field].length > 0) {
          return `${params.row[params.field]}%`;
        } else {
          return <>-</>;
        }
      },
    },
    {
      headerName: 'Description',
      field: 'task_description',
      width: 240,
    },
    {
      headerName: 'Original Effort Estimate',
      field: 'effort_estimate',
      width: 240,
      renderCell: (params: GridRenderCellParams) => {
        const { effortEstimate = '-' } = getTaskTimeTrackingFields(params.row);
        return (
          <Typography variant="subtitle1" style={{ padding: '5px 0' }}>
            {effortEstimate}
          </Typography>
        );
      },
    },
    {
      headerName: 'Total Time Logged',
      field: 'total_time_spent',
      width: 240,
      renderCell: (params: GridRenderCellParams) => {
        const { totalTimeLogged = '-' } = getTaskTimeTrackingFields(params.row);
        return (
          <Typography variant="subtitle1" style={{ padding: '5px 0' }}>
            {totalTimeLogged}
          </Typography>
        );
      },
    },
  ];

  // filters for all and team tasks
  useEffect(() => {
    if ((cfMappedTeamClosedTasks.length > 0 || cfMappedTeamOpenTasks.length > 0) && allUsersStatus === 'success') {
      const allTeamTasks = [...cfMappedTeamClosedTasks, ...cfMappedTeamOpenTasks];
      if (taskCustomFieldsStatus == 'success') {
        const defaultTaskFilters = [
          {
            name: `${label_task} Owner`,
            dataKey: 'task_owner',
            isSelected: false,
            equalToValue: [],
            canBeCleared: true,
            availableOptions: allUsers.map((item: any) => item.user_username),
          },
          {
            name: `${label_client} Name`,
            dataKey: 'client_name',
            isSelected: false,
            equalToValue: [],
            canBeCleared: true,
            availableOptions: generateOptionsForThisDataKey(allTeamTasks, 'client_name'),
          },
          {
            name: 'Stage',
            dataKey: 'task_stage',
            isSelected: false,
            equalToValue: [],
            canBeCleared: true,
            availableOptions: allTaskStages.data,
          },
          {
            name: 'Status',
            dataKey: 'task_status',
            isSelected: false,
            equalToValue: [],
            canBeCleared: true,
            availableOptions: allGeneralStatus.data,
          },
          {
            name: 'Priority',
            dataKey: 'task_priority',
            isSelected: false,
            equalToValue: [],
            canBeCleared: true,
            availableOptions: availablePriorities,
          },
          {
            name: label_due_date + '$$date-range-picker',
            dataKey: 'task_due_date',
            isSelected: false,
            equalToValue: [],
            canBeCleared: true,
            availableOptions: availableDateRangeOptions,
          },
        ];

        const cfTasks: any[] = [];

        taskCustomfields.data.forEach((cf: any) => {
          if (['RAG status', 'Dropdown'].includes(cf.field_type)) {
            cfTasks.push({
              name: cf.field_name,
              dataKey: generateMappedCustomFieldKey(cf.field_name, cf.field_uid, cf.field_type),
              isSelected: false,
              equalToValue: [],
              canBeCleared: true,
              availableOptions: cf.valid_values.split(','),
            });
          } else if (cf.field_type === 'Date') {
            cfTasks.push({
              name: `${cf.field_name}$$date-range-picker`,
              dataKey: generateMappedCustomFieldKey(cf.field_name, cf.field_uid, cf.field_type),
              isSelected: false,
              equalToValue: [],
              canBeCleared: true,
              availableOptions: availableDateRangeOptions,
            });
          }
        });

        if (isUndefined(allFilters['reportsPageAllTasks']) || isNull(allFilters['reportsPageAllTasks'])) {
          dispatch(
            addFilterGroup({
              idName: 'reportsPageAllTasks',
              appliedFilters: [...defaultTaskFilters, ...cfTasks],
            })
          );
        }
        if (isUndefined(allFilters['reportsPageTeamTasks']) || isNull(allFilters['reportsPageTeamTasks'])) {
          dispatch(
            addFilterGroup({
              idName: 'reportsPageTeamTasks',
              appliedFilters: [...defaultTaskFilters, ...cfTasks],
            })
          );
        }
      }

      if (taskCustomfields.status === 'success' && taskCustomfields.data.length > 0) {
        setCfMappedTeamTasksCloumns(
          generateOrderedColumn(
            addSubtasks([
              ...generateTableColumnsWithCustomFiltersMapped(
                allTeamTasks[0],
                teamColumns,
                'Task',
                taskCustomfields.data,
                handleInlineEditCustomValue,
                users
              ),
            ]),
            uniqueReportTaskCFColumnId
          )
        );
      } else {
        setCfMappedTeamTasksCloumns(generateOrderedColumn(addSubtasks(teamColumns), uniqueReportTaskCFColumnId));
      }
    }
  }, [cfMappedTeamOpenTasks, cfMappedTeamClosedTasks, taskCustomfields, allUsersStatus, users]);

  // client filters
  useEffect(() => {
    if (
      (clientOpenTasks.status == 'success' || clientClosedTasks.status == 'success') &&
      allUsersStatus === 'success'
    ) {
      const allTeamTasks = [...clientOpenTasks.data, ...clientClosedTasks.data];

      if (isUndefined(allFilters['reportsPageClientTasks']) || isNull(allFilters['reportsPageClientTasks'])) {
        dispatch(
          addFilterGroup({
            idName: 'reportsPageClientTasks',
            appliedFilters: [
              {
                name: `${label_task} Owner`,
                dataKey: 'task_detail',
                isSelected: false,
                equalToValue: [],
                canBeCleared: true,
                availableOptions: allUsers.map((item: any) => item.user_username),
              },
              {
                name: `${label_client} Name`,
                dataKey: 'client_name',
                isSelected: false,
                equalToValue: [],
                canBeCleared: true,
                availableOptions: generateOptionsForThisDataKey(allTeamTasks, 'client_name'),
              },
              {
                name: 'Stage',
                dataKey: 'task_stage',
                isSelected: false,
                equalToValue: [],
                canBeCleared: true,
                availableOptions: allTaskStages.data,
              },
              {
                name: 'Status',
                dataKey: 'task_status',
                isSelected: false,
                equalToValue: [],
                canBeCleared: true,
                availableOptions: allGeneralStatus.data,
              },
              {
                name: 'Priority',
                dataKey: 'task_priority',
                isSelected: false,
                equalToValue: [],
                canBeCleared: true,
                availableOptions: allTaskPriorities.data,
              },
              {
                name: label_due_date + '$$date-range-picker',
                dataKey: 'task_due_date',
                isSelected: false,
                equalToValue: [],
                canBeCleared: true,
                availableOptions: availableDateRangeOptions,
              },
            ],
          })
        );
      }
    }
  }, [clientOpenTasks, clientClosedTasks]);

  useEffect(() => {
    if (users.length > 0) {
      setUsersData();
    }
  }, [users]);

  useEffect(() => {
    if (selectedMainTab === 0) {
      setIdName('reportsPageAllTasks');
      if (selectedInnerTab === 'all') {
        if (allFilters['reportsPageAllTasks']) {
          const newFilteredTeamOpenData = new FilterableData([...cfMappedTeamOpenTasks]);
          const newFilteredTeamClosedData = new FilterableData([...cfMappedTeamClosedTasks]);
          const newFilteredClientOpenData = new FilterableData(addSubtasks([...clientOpenTasks.data]));
          const newFilteredClientClosedData = new FilterableData(addSubtasks([...clientClosedTasks.data]));
          newFilteredTeamOpenData.bulkFilter(allFilters['reportsPageAllTasks'].appliedFilters);
          newFilteredTeamClosedData.bulkFilter(allFilters['reportsPageAllTasks'].appliedFilters);
          newFilteredClientOpenData.bulkFilter(allFilters['reportsPageAllTasks'].appliedFilters);
          newFilteredClientClosedData.bulkFilter(allFilters['reportsPageAllTasks'].appliedFilters);
          setFilteredAllTasks([
            ...newFilteredTeamOpenData.toArray(),
            ...newFilteredTeamClosedData.toArray(),
            ...newFilteredClientOpenData.toArray(),
            ...newFilteredClientClosedData.toArray(),
          ]);
          if (
            countCameFromChartClick >= 0 &&
            [...cfMappedTeamOpenTasks, ...cfMappedTeamClosedTasks, ...clientOpenTasks.data, ...clientClosedTasks.data]
              .length > 0 &&
            isDataLoadedAndFilteredApplied
          ) {
            if (
              countCameFromChartClick !==
              [
                ...newFilteredTeamOpenData.toArray(),
                ...newFilteredTeamClosedData.toArray(),
                ...newFilteredClientOpenData.toArray(),
                ...newFilteredClientClosedData.toArray(),
              ].length
            ) {
              setAlertDetails({
                isVisible: true,
                message: `You can't see all ${label_task}s of your reportees as role not properly configured in RBAC, please contact admin.`,
                type: 'failure',
              });
            }
            setCountCameFromChartClick(-1);
          }
        }
      } else if (selectedInnerTab === 'open') {
        if (allFilters['reportsPageAllTasks']) {
          const newFilteredTeamOpenData = new FilterableData([...cfMappedTeamOpenTasks]);
          const newFilteredClientOpenData = new FilterableData(addSubtasks([...clientOpenTasks.data]));
          newFilteredTeamOpenData.bulkFilter(allFilters['reportsPageAllTasks'].appliedFilters);
          newFilteredClientOpenData.bulkFilter(allFilters['reportsPageAllTasks'].appliedFilters);
          setFilteredAllTasks([...newFilteredTeamOpenData.toArray(), ...newFilteredClientOpenData.toArray()]);
        }
      } else if (selectedInnerTab === 'closed') {
        if (allFilters['reportsPageAllTasks']) {
          const newFilteredTeamClosedData = new FilterableData([...cfMappedTeamClosedTasks]);
          const newFilteredClientClosedData = new FilterableData(addSubtasks([...clientClosedTasks.data]));
          newFilteredTeamClosedData.bulkFilter(allFilters['reportsPageAllTasks'].appliedFilters);
          newFilteredClientClosedData.bulkFilter(allFilters['reportsPageAllTasks'].appliedFilters);
          setFilteredAllTasks([...newFilteredTeamClosedData.toArray(), ...newFilteredClientClosedData.toArray()]);
        }
      }
    } else if (selectedMainTab === 1) {
      setIdName('reportsPageTeamTasks');
      if (selectedInnerTab === 'all') {
        if (allFilters['reportsPageTeamTasks']) {
          const newFilteredTeamOpenData = new FilterableData([...cfMappedTeamOpenTasks]);
          const newFilteredTeamClosedData = new FilterableData([...cfMappedTeamClosedTasks]);
          newFilteredTeamOpenData.bulkFilter(allFilters['reportsPageTeamTasks'].appliedFilters);
          newFilteredTeamClosedData.bulkFilter(allFilters['reportsPageTeamTasks'].appliedFilters);
          setFilteredTeamTasks([...newFilteredTeamOpenData.toArray(), ...newFilteredTeamClosedData.toArray()]);
        }
      } else if (selectedInnerTab === 'open') {
        if (allFilters['reportsPageTeamTasks']) {
          const newFilteredData = new FilterableData([...cfMappedTeamOpenTasks]);
          newFilteredData.bulkFilter(allFilters['reportsPageTeamTasks'].appliedFilters);
          setFilteredTeamTasks([...newFilteredData.toArray()]);
        }
      } else {
        if (allFilters['reportsPageTeamTasks']) {
          const newFilteredData = new FilterableData([...cfMappedTeamClosedTasks]);
          newFilteredData.bulkFilter(allFilters['reportsPageTeamTasks'].appliedFilters);
          setFilteredTeamTasks([...newFilteredData.toArray()]);
        }
      }
    } else if (selectedMainTab === 2) {
      setIdName('reportsPageClientTasks');
      if (selectedInnerTab === 'all') {
        if (allFilters['reportsPageClientTasks']) {
          const newFilteredClientOpenData = new FilterableData(addSubtasks([...clientOpenTasks.data]));
          const newFilteredClientClosedData = new FilterableData(addSubtasks([...clientClosedTasks.data]));
          newFilteredClientOpenData.bulkFilter(allFilters['reportsPageClientTasks'].appliedFilters);
          newFilteredClientClosedData.bulkFilter(allFilters['reportsPageClientTasks'].appliedFilters);
          setFiteredClientTasks([...newFilteredClientOpenData.toArray(), ...newFilteredClientClosedData.toArray()]);
        }
      } else if (selectedInnerTab === 'open') {
        if (allFilters['reportsPageClientTasks']) {
          const newFilteredData = new FilterableData(addSubtasks(clientOpenTasks.data));
          newFilteredData.bulkFilter(allFilters['reportsPageClientTasks'].appliedFilters);
          setFiteredClientTasks([...newFilteredData.toArray()]);
        }
      } else {
        if (allFilters['reportsPageClientTasks']) {
          const newFilteredData = new FilterableData(addSubtasks(clientClosedTasks.data));
          newFilteredData.bulkFilter(allFilters['reportsPageClientTasks'].appliedFilters);
          setFiteredClientTasks([...newFilteredData.toArray()]);
        }
      }
    }
  }, [
    selectedMainTab,
    selectedInnerTab,
    allFilters,
    cfMappedAllOpenTasks,
    cfMappedAllClosedTasks,
    isDataLoadedAndFilteredApplied,
  ]);

  useEffect(() => {
    if (!isNull(location.state)) {
      if (
        !isUndefined(allFilters['reportsPageAllTasks']) &&
        !isNull(allFilters['reportsPageAllTasks']) &&
        !isUndefined(allFilters['reportsPageTeamTasks']) &&
        !isNull(allFilters['reportsPageTeamTasks']) &&
        !isUndefined(allFilters['reportsPageClientTasks']) &&
        !isNull(allFilters['reportsPageClientTasks'])
      ) {
        const targetModules = ['reportsPageAllTasks', 'reportsPageTeamTasks', 'reportsPageClientTasks'];
        targetModules.forEach((module) => {
          const isTaskStatusAvailable = !!location?.state?.task_status;
          dispatch(
            updateFilter({
              idName: module,
              updatedFilter: {
                name: 'Status',
                dataKey: 'task_status',
                isSelected: isTaskStatusAvailable,
                equalToValue: isTaskStatusAvailable ? [location.state.task_status] : [],
                canBeCleared: true,
                availableOptions: allGeneralStatus.data,
              },
            })
          );
          if (location?.state?.reportees) {
            dispatch(
              updateFilter({
                idName: module,
                updatedFilter: {
                  name: 'Task Owner',
                  dataKey: 'task_owner',
                  isSelected: true,
                  equalToValue: [...location.state.reportees.map((ele: any) => ele.user_name)],
                  canBeCleared: true,
                  availableOptions: allUsers.map((item: any) => item.user_username),
                },
              })
            );
            setCountCameFromChartClick(parseInt(location.state.value));
          } else {
            const isTaskOwnerAvailable = !!location?.state?.task_owner;
            dispatch(
              updateFilter({
                idName: module,
                updatedFilter: {
                  name: 'Task Owner',
                  dataKey: 'task_owner',
                  isSelected: isTaskOwnerAvailable,
                  equalToValue: isTaskOwnerAvailable ? [location.state.task_owner] : [],
                  canBeCleared: true,
                  availableOptions: allUsers.map((item: any) => item.user_username),
                },
              })
            );
          }
          const isClientNameAvailable = !!location?.state?.client_name;
          dispatch(
            updateFilter({
              idName: module,
              updatedFilter: {
                name: 'Account Name',
                dataKey: 'client_name',
                isSelected: isClientNameAvailable,
                equalToValue: isClientNameAvailable ? [location.state.client_name] : [],
                canBeCleared: true,
                availableOptions: clientList.map((item: any) => item.client_name),
              },
            })
          );
        });
        location.state = null;
      }
    }
  }, [location, allFilters, clientList]);

  function getTabSwitcher(selectedMainTab: number) {
    let filterTypeSelectorComp = <></>;
    if (selectedMainTab === 0) {
      filterTypeSelectorComp = (
        <Box>
          <FilterTypeSelector idName="reportsPageAllTasks" />
        </Box>
      );
    } else if (selectedMainTab === 1) {
      filterTypeSelectorComp = (
        <Box>
          <FilterTypeSelector idName="reportsPageTeamTasks" />
        </Box>
      );
    } else if (selectedMainTab === 2) {
      filterTypeSelectorComp = (
        <Box>
          <FilterTypeSelector idName="reportsPageClientTasks" />
        </Box>
      );
    }

    return (
      <Stack direction={'row'} justifyContent={'space-between'}>
        <CognisaasTabs
          size="squeezed"
          selectedTabIndex={selectedMainTab}
          handleTabSwitch={handleTabSelection}
          tabs={tabs}
        />
        {filterTypeSelectorComp}
      </Stack>
    );
  }

  function handleTabSelection(_event: any, tabIndex: number) {
    setSelectedMainTab(tabIndex);
  }

  const setUsersData = () => {
    const data = users.filter((user: any) => user.is_active === '1').map((user: any) => user.user_username);
    data.unshift('Not Assigned');
    setUsersOfCompany([...data]);
  };

  const getClient = (row: any) => {
    return `/client-list/client/${row.client_uid}`;
  };

  const getProject = (row: any) => {
    return `/projects/project/${row.association_uid}/${row.project_uid}`;
  };

  const getUsecase = (row: any) => {
    return `/projects/project/${row.association_uid}/${row.project_uid}/${row.case_uid}`;
  };

  const generateUniqueId = (): string => {
    return Date.now().toString(36) + Math.floor(Math.pow(10, 12) + Math.random() * 9 * Math.pow(10, 12)).toString(36);
  };

  const showTasks = (tasks: any[]) => {
    if (showOnlyMilestoneTasks) {
      const filteredAllTasks = tasks.filter((task: any) => {
        return task.milestone_task != 0 && task.milestone_task;
      });
      const data = filteredAllTasks.map((item: any) => {
        return {
          ...item,
          cognisaas_unique_id: generateUniqueId(),
        };
      });
      return data;
    } else {
      const data = tasks.map((item: any) => {
        return {
          ...item,
          cognisaas_unique_id: generateUniqueId(),
        };
      });
      return data;
    }
  };

  // const initialState = {
  //   columns: {
  //     columnVisibilityModel: {
  //       client_uid: false,
  //     },
  //   },
  // };
  const handleInlineEditValue = async (
    params: GridRenderEditCellParams,
    editableField: string,
    editableValue: string
  ) => {
    const newRow: any = {
      ...getStandardRowDataWithoutCustomNames({ ...params.row }),
      task_closed_date: format(new Date(params.row.task_closed_date), 'yyyy-MM-dd HH:mm:ss'),
      task_due_date: format(new Date(params.row.task_due_date), 'yyyy-MM-dd HH:mm:ss'),
      task_start_date: format(new Date(params.row.task_start_date), 'yyyy-MM-dd HH:mm:ss'),
      task_planned_start_date: format(new Date(params.row.task_planned_start_date), 'yyyy-MM-dd HH:mm:ss'),
    };
    if (editableField === 'task_owner') {
      await dispatch(
        updateTask({
          ...newRow,
          [editableField]:
            newRow.is_team_task === '1' ? getUserUid(newRow.task_owner) : getContactUidByContactName(newRow.task_owner),
          phase_uid: newRow.section_uid,
        })
      );
    } else {
      await dispatch(
        updateTask({
          ...newRow,
          [editableField]: editableValue,
          phase_uid: newRow.section_uid,
          task_owner:
            newRow.is_team_task === '1' ? getUserUid(newRow.task_owner) : getContactUidByContactName(newRow.task_owner),
        })
      );
    }
  };

  const handleInlineEditCustomValue = async (
    params: GridRenderEditCellParams,
    editableField: string,
    editableValue: string
  ) => {
    const index = params.indexofCustomField;
    const customFieldData = params.row.custom_values[index];
    setCustomFieldStatus(true);
    await dispatch(updateTaskCustomField({ ...customFieldData, field_value: editableValue }));
    if (selectedMainTab === 0) {
      await dispatch(fetchTeamOpenTasks());
      await dispatch(fetchTeamClosedTasks());
      await dispatch(fetchClientOpenTasks());
      await dispatch(fetchClientClosedTasks());
      setCustomFieldStatus(false);
    } else if (selectedMainTab === 1) {
      await dispatch(fetchTeamOpenTasks());
      await dispatch(fetchTeamClosedTasks());
      setCustomFieldStatus(false);
    } else {
      await dispatch(fetchClientOpenTasks());
      await dispatch(fetchClientClosedTasks());
      setCustomFieldStatus(false);
    }
  };

  const CustomGridTreeDataGroupingCell = (props: GridRenderCellParams) => {
    const { id, field, rowNode } = props;
    const apiRef = useGridApiContext();
    const filteredDescendantCountLookup = useGridSelector(apiRef, gridFilteredDescendantCountLookupSelector);
    const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0;

    const handleClick = (event: any) => {
      apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
      apiRef.current.setCellFocus(id, field);
      event.stopPropagation();
    };

    return (
      <Box sx={{ ml: rowNode.depth * 4 }}>
        <div>
          {filteredDescendantCount > 0 ? (
            rowNode.childrenExpanded ? (
              <KeyboardArrowDownIcon onClick={handleClick} style={{ cursor: 'pointer' }} />
            ) : (
              <KeyboardArrowRightIcon onClick={handleClick} style={{ cursor: 'pointer' }} />
            )
          ) : (
            <span />
          )}
        </div>
      </Box>
    );
  };

  const handleColumnOrderChange = (params: GridColumnOrderChangeParams) => {
    const oldArrayIndex = params.oldIndex;
    const targetArrayIndex = params.targetIndex;
    setCfMappedTeamTasksCloumns(
      generateOrderedColumn(
        cfMappedTeamTasksCloumns,
        uniqueReportTaskCFColumnId,
        oldArrayIndex - 1,
        targetArrayIndex - 1
      )
    );
  };

  const handleTeamColumnOrderChange = (params: GridColumnOrderChangeParams) => {
    const oldArrayIndex = params.oldIndex;
    const targetArrayIndex = params.targetIndex;
    setTableColumns(
      generateOrderedColumn(tableColumns, uniqueReportTaskColumnId, oldArrayIndex - 1, targetArrayIndex - 1)
    );
  };

  const groupingColDef: DataGridPremiumProps['groupingColDef'] = {
    headerName: '',
    width: 10,
    renderCell: (params: GridRenderCellParams) => <CustomGridTreeDataGroupingCell {...params} />,
  };

  return (
    <>
      {!isNull(teamOpenTasks.data) && teamOpenTasks.data.length > 0 && idName === 'reportsPageAllTasks' && (
        <CustomiseDrawer
          idName={idName}
          configType="task"
          setDrawerState={setDrawerState}
          drawerState={drawerState}
          sampleData={teamOpenTasks.data[0]}
          formActionType={formActionType}
          setFormActionType={setFormActionType}
          graphData={graphData}
        />
      )}
      {!isNull(teamOpenTasks.data) && teamOpenTasks.data.length > 0 && idName === 'reportsPageTeamTasks' && (
        <CustomiseDrawer
          idName={idName}
          configType="task"
          setDrawerState={setDrawerState}
          drawerState={drawerState}
          sampleData={teamOpenTasks.data[0]}
          formActionType={formActionType}
          setFormActionType={setFormActionType}
          graphData={graphData}
        />
      )}
      {!isNull(clientOpenTasks.data) && clientOpenTasks.data.length > 0 && idName === 'reportsPageClientTasks' && (
        <CustomiseDrawer
          idName={idName}
          configType="task"
          setDrawerState={setDrawerState}
          drawerState={drawerState}
          sampleData={clientOpenTasks.data[0]}
          formActionType={formActionType}
          setFormActionType={setFormActionType}
          graphData={graphData}
        />
      )}
      <Box sx={{ display: 'grid', gridTemplateRows: '70px auto' }}>
        <ReportsHeader pageName={ReportsPages.Tasks} setDrawerState={setDrawerState} />

        <Box sx={{ display: 'grid', m: '0px 20px', gridRowGap: '20px' }}>
          {getTabSwitcher(selectedMainTab)}

          <Box>
            <ButtonGroup
              variant="outlined"
              aria-label="outlined button group"
              sx={{
                display: 'inline-flex',
                flexWrap: 'wrap',
                backgroundColor: 'white',
                border: '1px solid #E5E5E5',
                borderRadius: '4px',
              }}
            >
              <Button
                variant="text"
                sx={selectedInnerTab === 'all' ? { background: 'rgba(45, 96, 246,0.15)' } : {}}
                onClick={() => setInnerTab('all')}
              >
                All {label_task}s
              </Button>
              <Button
                variant="text"
                sx={selectedInnerTab === 'open' ? { background: 'rgba(45, 96, 246,0.15)' } : {}}
                onClick={() => setInnerTab('open')}
              >
                Open {label_task}s
              </Button>
              <Button
                variant="text"
                sx={selectedInnerTab === 'closed' ? { background: 'rgba(45, 96, 246,0.15)' } : {}}
                onClick={() => setInnerTab('closed')}
              >
                Closed {label_task}s
              </Button>
            </ButtonGroup>
          </Box>

          {selectedMainTab === 0 &&
            allFilters['reportsPageAllTasks']?.appliedFilters.filter((item: any) => item.isSelected).length > 0 && (
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                <SelectedFilterList idName="reportsPageAllTasks" isResetEnabled={true} />
              </Box>
            )}
          {selectedMainTab === 1 &&
            allFilters['reportsPageTeamTasks']?.appliedFilters.filter((item: any) => item.isSelected).length > 0 && (
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                <SelectedFilterList idName="reportsPageTeamTasks" isResetEnabled={true} />
              </Box>
            )}
          {selectedMainTab === 2 &&
            allFilters['reportsPageClientTasks']?.appliedFilters.filter((item: any) => item.isSelected).length > 0 && (
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                <SelectedFilterList idName="reportsPageClientTasks" isResetEnabled={true} />
              </Box>
            )}

          {/* all tasks tab */}
          {selectedMainTab === 0 &&
            (teamOpenTasks.status === requestState.success || teamClosedTasks.status === requestState.success ? (
              <>
                {graphs !== undefined && graphs.length > 0 && (
                  <Box>
                    <ChartView
                      idName={'reportsPageAllTasks'}
                      data={filteredAllTasks}
                      numberOfcharts={2}
                      setGraphData={setGraphData}
                      setDrawerState={setDrawerState}
                      setFormActionType={setFormActionType}
                      configType="task"
                    />
                  </Box>
                )}

                <Stack direction="row" justifyContent="flex-end" alignItems="baseline">
                  <Stack>
                    <CognisaasCheckBox
                      sx={{ top: '3px' }}
                      onClick={() => setShowOnlyMilestoneTasks(!showOnlyMilestoneTasks)}
                      checked={showOnlyMilestoneTasks}
                    />
                  </Stack>

                  <Typography sx={{ marginLeft: '4px' }} variant="subtitle1">
                    Show milestone {label_task.toLowerCase()}s
                  </Typography>
                </Stack>

                {customFieldStatus ||
                (cfMappedTeamTasksCloumns.length > 0 &&
                  teamOpenTasks.status === requestState.success &&
                  teamClosedTasks.status === requestState.success) ? (
                  <>
                    <StyledBox>
                      <StripedDataGrid
                        // initialState={initialState}
                        initialState={initialStateAllTasks}
                        onStateChange={(tblState: any) => {
                          myTableStateForAllTasksTab = tblState;
                        }}
                        onColumnVisibilityModelChange={() => {
                          handleTableSave('projectReportAllTasksPage', myTableStateForAllTasksTab);
                        }}
                        onFilterModelChange={() => {
                          handleTableSave('projectReportAllTasksPage', myTableStateForAllTasksTab);
                        }}
                        onPageSizeChange={() => {
                          handleTableSave('projectReportAllTasksPage', myTableStateForAllTasksTab);
                        }}
                        rows={displayDataWithCustomNames(showTasks(filteredAllTasks))}
                        components={{
                          Toolbar: GridToolbar,
                        }}
                        componentsProps={{
                          toolbar: { showQuickFilter: true },
                        }}
                        columns={cfMappedTeamTasksCloumns}
                        loading={false}
                        getRowId={(row) => row.cognisaas_unique_id}
                        disableSelectionOnClick
                        rowThreshold={0}
                        getRowHeight={() => 'auto'}
                        sx={{
                          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
                          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '7px' },
                          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
                        }}
                        // pageSize={allTasksPageSize}
                        // onPageSizeChange={(newPageSize) => setAllTasksPageSize(newPageSize)}
                        rowsPerPageOptions={[5, 10, 20, 50, 100]}
                        pagination
                        editMode="cell"
                        experimentalFeatures={{ newEditingApi: true }}
                        getRowClassName={(params: any) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'odd' : 'odd')}
                        treeData
                        getTreeDataPath={(row) => {
                          return row.path;
                        }}
                        groupingColDef={groupingColDef}
                        onColumnOrderChange={handleColumnOrderChange}
                      />
                    </StyledBox>
                  </>
                ) : (
                  <div>
                    {teamOpenTasks.status === requestState.failed || teamClosedTasks.status === requestState.failed ? (
                      <NoRecordsFound />
                    ) : (
                      <CognisaasLoader />
                    )}
                  </div>
                )}
              </>
            ) : (
              <div>
                {teamOpenTasks.status === requestState.failed || teamClosedTasks.status === requestState.failed ? (
                  <NoRecordsFound />
                ) : (
                  <CognisaasLoader />
                )}
              </div>
            ))}
          {/* our team tab */}
          {selectedMainTab === 1 &&
            (teamOpenTasks.status === requestState.success || teamClosedTasks.status === requestState.success ? (
              <>
                {graphs !== undefined && graphs.length > 0 && (
                  <Box>
                    <ChartView
                      idName={'reportsPageTeamTasks'}
                      data={filteredTeamTasks}
                      numberOfcharts={2}
                      setGraphData={setGraphData}
                      setDrawerState={setDrawerState}
                      setFormActionType={setFormActionType}
                      configType="task"
                    />
                  </Box>
                )}
                <Stack direction="row" justifyContent="flex-end" alignItems="baseline">
                  <Stack>
                    {' '}
                    <CognisaasCheckBox
                      sx={{ top: '3px' }}
                      onClick={() => setShowOnlyMilestoneTasks(!showOnlyMilestoneTasks)}
                      checked={showOnlyMilestoneTasks}
                    />
                  </Stack>

                  <Typography sx={{ marginLeft: '4px' }} variant="subtitle1">
                    Show milestone {label_task.toLowerCase()}s
                  </Typography>
                </Stack>

                {customFieldStatus ||
                (cfMappedTeamTasksCloumns.length > 0 &&
                  teamOpenTasks.status === requestState.success &&
                  teamClosedTasks.status === requestState.success) ? (
                  <>
                    <StyledBox>
                      <StripedDataGrid
                        // initialState={initialState}
                        initialState={initialStateOpenTasks}
                        onStateChange={(tblState: any) => {
                          myTableStateForOpenTasksTab = tblState;
                        }}
                        onColumnVisibilityModelChange={() => {
                          handleTableSave('projectReportOpenTasksPage', myTableStateForOpenTasksTab);
                        }}
                        onFilterModelChange={() => {
                          handleTableSave('projectReportOpenTasksPage', myTableStateForOpenTasksTab);
                        }}
                        onPageSizeChange={() => {
                          handleTableSave('projectReportOpenTasksPage', myTableStateForOpenTasksTab);
                        }}
                        rows={displayDataWithCustomNames(showTasks(filteredTeamTasks))}
                        columns={cfMappedTeamTasksCloumns}
                        loading={false}
                        getRowHeight={() => 'auto'}
                        sx={{
                          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
                          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '7px' },
                          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
                        }}
                        getRowId={(row) => row.cognisaas_unique_id}
                        disableSelectionOnClick
                        components={{
                          Toolbar: GridToolbar,
                        }}
                        componentsProps={{
                          toolbar: { showQuickFilter: true },
                        }}
                        rowThreshold={0}
                        // pageSize={openTasksPageSize}
                        rowsPerPageOptions={[5, 10, 20, 50, 100]}
                        // onPageSizeChange={(newPageSize) => setOpenTasksPageSize(newPageSize)}
                        pagination
                        editMode="cell"
                        experimentalFeatures={{ newEditingApi: true }}
                        getRowClassName={(params: any) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'odd' : 'odd')}
                        treeData
                        getTreeDataPath={(row) => {
                          return row.path;
                        }}
                        groupingColDef={groupingColDef}
                        onColumnOrderChange={handleColumnOrderChange}
                      />
                    </StyledBox>
                  </>
                ) : (
                  <div>
                    {teamOpenTasks.status === requestState.failed || teamClosedTasks.status === requestState.failed ? (
                      <NoRecordsFound />
                    ) : (
                      <CognisaasLoader />
                    )}
                  </div>
                )}
              </>
            ) : (
              <div>
                {teamOpenTasks.status === requestState.failed || teamClosedTasks.status === requestState.failed ? (
                  <NoRecordsFound />
                ) : (
                  <CognisaasLoader />
                )}
              </div>
            ))}
          {selectedMainTab === 2 &&
            (customFieldStatus ||
            clientOpenTasks.status === requestState.success ||
            clientClosedTasks.status === requestState.success ? (
              <>
                {graphs !== undefined && graphs.length > 0 && (
                  <Box>
                    <ChartView
                      idName={'reportsPageClientTasks'}
                      data={filteredClientTasks}
                      numberOfcharts={2}
                      setGraphData={setGraphData}
                      setDrawerState={setDrawerState}
                      setFormActionType={setFormActionType}
                      configType="task"
                    />
                  </Box>
                )}

                <Stack direction="row" justifyContent="flex-end" alignItems="baseline">
                  <Stack>
                    <CognisaasCheckBox
                      sx={{ top: '3px' }}
                      onClick={() => setShowOnlyMilestoneTasks(!showOnlyMilestoneTasks)}
                      checked={showOnlyMilestoneTasks}
                    />
                  </Stack>

                  <Typography sx={{ marginLeft: '4px' }} variant="subtitle1">
                    Show milestone {label_task.toLowerCase()}s
                  </Typography>
                </Stack>

                <StyledBox>
                  <StripedDataGrid
                    // initialState={initialState}
                    initialState={initialStateClosedTasks}
                    onStateChange={(tblState: any) => {
                      myTableStateForClosedtasksTab = tblState;
                    }}
                    onColumnVisibilityModelChange={() => {
                      handleTableSave('projectReportClosedTasksPage', myTableStateForClosedtasksTab);
                    }}
                    onFilterModelChange={() => {
                      handleTableSave('projectReportClosedTasksPage', myTableStateForClosedtasksTab);
                    }}
                    onPageSizeChange={() => {
                      handleTableSave('projectReportClosedTasksPage', myTableStateForClosedtasksTab);
                    }}
                    rows={displayDataWithCustomNames(showTasks(filteredClientTasks))}
                    columns={tableColumns}
                    loading={false}
                    components={{
                      Toolbar: GridToolbar,
                    }}
                    componentsProps={{
                      toolbar: { showQuickFilter: true },
                    }}
                    getRowId={(row) => row.cognisaas_unique_id}
                    disableSelectionOnClick
                    rowThreshold={0}
                    // pageSize={closedTasksPageSize}
                    rowsPerPageOptions={[5, 10, 20, 50, 100]}
                    // onPageSizeChange={(newPageSize) => setClosedTasksPageSize(newPageSize)}
                    pagination
                    editMode="cell"
                    getRowHeight={() => 'auto'}
                    sx={{
                      '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
                      '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '7px' },
                      '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
                    }}
                    experimentalFeatures={{ newEditingApi: true }}
                    getRowClassName={(params: any) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'odd' : 'odd')}
                    treeData
                    getTreeDataPath={(row) => {
                      return row.path;
                    }}
                    groupingColDef={groupingColDef}
                    onColumnOrderChange={handleTeamColumnOrderChange}
                  />
                </StyledBox>
              </>
            ) : (
              <div>
                <CognisaasLoader />
              </div>
            ))}
        </Box>
      </Box>
      {updateTaskDrawer && (
        <UpdateTaskDrawer
          client={clientUid}
          taskDetail={taskToOpen}
          drawerState={updateTaskDrawer}
          onClose={() => setUpdateTaskDrawer(!updateTaskDrawer)}
        />
      )}
      <CognisaasToast
        message={alertDetails.message}
        type={alertDetails.type}
        open={alertDetails.isVisible}
        onClose={() => {
          setAlertDetails({ isVisible: false, type: alertDetails.type, message: '' });
        }}
      />
    </>
  );
};

export default Tasks;
