import { apis } from '../axiosInstance';
import Cookies from 'universal-cookie';
import { customerPortalRoutes } from '../route';
const cookies = new Cookies();

const client_getsingleProjectInformationWithTaskClientUser = (project_uid: string) =>
  apis.phpAxiosInstance.get(customerPortalRoutes.client_getsingleProjectInformationWithTaskClientUser(project_uid), {
    headers: { JWT: 'true' },
  });

const client_getsingleUsecaseInformationWithTaskClientUser = (usecase_uid: string) =>
  apis.phpAxiosInstance.get(customerPortalRoutes.client_getsingleUsecaseInformationWithTaskClientUser(usecase_uid), {
    headers: { JWT: 'true' },
  });

const client_getProjectUpgradationAlert = (client_uid: string) =>
  apis.phpAxiosInstance.get(customerPortalRoutes.client_getProjectUpgradationAlert(client_uid), {
    headers: { JWT: 'true' },
  });

const client_getUsecaseUpgradationAlert = (client_uid: string) =>
  apis.phpAxiosInstance.get(customerPortalRoutes.client_getUsecaseUpgradationAlert(client_uid), {
    headers: { JWT: 'true' },
  });

const client_getProjectDegradationAlert = (client_uid: string) =>
  apis.phpAxiosInstance.get(customerPortalRoutes.client_getProjectDegradationAlert(client_uid), {
    headers: { JWT: 'true' },
  });

const client_getUsecaseDegradationAlert = (client_uid: string) =>
  apis.phpAxiosInstance.get(customerPortalRoutes.client_getUsecaseDegradationAlert(client_uid), {
    headers: { JWT: 'true' },
  });

const client_getProjectStatusCountByClientUid = (client_uid: string) =>
  apis.phpAxiosInstance.get(customerPortalRoutes.client_getProjectStatusCountByClientUid(client_uid), {
    headers: { JWT: 'true' },
  });

const client_getTaskStatusCountByClientUid = (client_uid: string) =>
  apis.phpAxiosInstance.get(customerPortalRoutes.client_getTaskStatusCountByClientUid(client_uid), {
    headers: { JWT: 'true' },
  });

const client_getUseCaseStatusCount = (reqObj: any) =>
  apis.phpAxiosInstance.get(customerPortalRoutes.client_getUseCaseStatusCount(reqObj.client_uid, reqObj.customer_uid), {
    headers: { JWT: 'true' },
  });

const clientCheckClient = (client_name: any) =>
  apis.phpAxiosInstance.post(
    customerPortalRoutes.clientCheckClient(),
    {
      client_name: client_name,
    },
    { headers: { JWT: 'false' } }
  );

// const client_getClientinformation = (client_uid: any) =>
//   axiosInstance.post(
//     customerPortalRoutes.client_getClientinformation(),
//     {
//       client_uid: client_uid,
//     },
//     { headers: { JWT: 'fasle' } }
//   );

const client_updatedcasewithfeaturestatusClient = (client_uid: any) =>
  apis.phpAxiosInstance.post(
    customerPortalRoutes.client_updatedcasewithfeaturestatusClient(),
    {
      client_uid: client_uid,
    },
    { headers: { JWT: 'true' } }
  );

const client_userSignIn_withjwttoken = (magic_jwt_token: string) =>
  apis.phpAxiosInstance.post(
    customerPortalRoutes.client_userSignIn_withjwttoken(),
    {
      magic_jwt_token: magic_jwt_token,
    },
    { headers: { JWT: 'false' } }
  );

const client_getProjectsWithTasks = (client_uid: any) =>
  apis.phpAxiosInstance.post(
    customerPortalRoutes.client_getProjectsWithTasks(client_uid),
    {
      client_uid: client_uid,
    },
    { headers: { JWT: 'true' } }
  );

const client_addProjectTask = (reqObj: any) =>
  apis.phpAxiosInstance.post(customerPortalRoutes.client_addProjectTask(), reqObj, { headers: { JWT: 'true' } });

const client_addUsecaseTask = (reqObj: any) =>
  apis.phpAxiosInstance.post(customerPortalRoutes.client_addUsecaseTask(), reqObj, { headers: { JWT: 'true' } });

const client_updateProjecttaskbyuid = (reqObj: any) =>
  apis.phpAxiosInstance.post(customerPortalRoutes.client_updateProjecttaskbyuid(), reqObj, {
    headers: { JWT: 'true' },
  });

const client_updateUsecasetaskbyuid = (reqObj: any) =>
  apis.phpAxiosInstance.post(customerPortalRoutes.client_updateUsecasetaskbyuid(), reqObj, {
    headers: { JWT: 'true' },
  });

const getCompanyUsers = (customer_uid: any) =>
  apis.phpAxiosInstance.get(customerPortalRoutes.getAllUsersOfCompany(customer_uid), { headers: { JWT: 'true' } });

const getClientContactsByContactUid = (contact_uid: any) =>
  apis.phpAxiosInstance.get(customerPortalRoutes.getClientContactsByContactUid(contact_uid), {
    headers: { JWT: 'true' },
  });

const client_getClientContactsByClientUid = (contact_uid: any) =>
  apis.phpAxiosInstance.get(customerPortalRoutes.client_getClientContactsByClientUid(contact_uid), {
    headers: { JWT: 'true' },
  });

const client_addProjectTaskAuditTrail = (resObj: any) =>
  apis.phpAxiosInstance.post(customerPortalRoutes.client_addProjectTaskAuditTrail(), resObj, {
    headers: { JWT: 'true' },
  });

const client_sendmagiclink = (resObj: any) =>
  apis.phpAxiosInstance.post(customerPortalRoutes.client_sendmagiclink(), resObj, {
    headers: { JWT: 'false' },
  });

const client_getClientinformationapi = (client_uid: string) =>
  apis.phpAxiosInstance.get(customerPortalRoutes.client_getClientinformationapi(client_uid), {
    headers: { JWT: 'true' },
  });

const client_getTaskByTaskUid = (task_uid: any) =>
  apis.phpAxiosInstance.get(customerPortalRoutes.client_getTaskByTaskUid(task_uid), { headers: { JWT: 'true' } });

const client_getCustomerDetail = () =>
  apis.phpAxiosInstance.get(
    customerPortalRoutes.client_getCustomerDetail(cookies.get('cogni_customer_uid') as string),
    {
      headers: { JWT: 'true' },
    }
  );

const client_updateTaskAcknowledgedStatus = (resObj: any) =>
  apis.phpAxiosInstance.post(customerPortalRoutes.client_updateTaskAcknowledgedStatus(), resObj, {
    headers: { JWT: 'true' },
  });

export const customerPortalApi = {
  clientCheckClient,
  // getclient_getProjectsWithTasks,
  client_getProjectsWithTasks,
  client_userSignIn_withjwttoken,
  client_updatedcasewithfeaturestatusClient,
  client_getProjectUpgradationAlert,
  client_getUsecaseUpgradationAlert,
  client_getProjectDegradationAlert,
  client_getUsecaseDegradationAlert,
  client_getProjectStatusCountByClientUid,
  client_getTaskStatusCountByClientUid,
  client_getUseCaseStatusCount,
  client_getsingleProjectInformationWithTaskClientUser,
  client_getsingleUsecaseInformationWithTaskClientUser,
  client_getClientContactsByClientUid,
  getCompanyUsers,
  getClientContactsByContactUid,
  client_addProjectTask,
  client_addUsecaseTask,
  client_updateProjecttaskbyuid,
  client_updateUsecasetaskbyuid,
  client_addProjectTaskAuditTrail,
  client_sendmagiclink,
  client_getClientinformationapi,
  client_getCustomerDetail,
  client_getTaskByTaskUid,
  client_updateTaskAcknowledgedStatus,
};
