import { Typography, Stack, Grid, IconButton, Divider } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CognisaasMenu from '../../shared/CognisaasMenu/CognisaasMenu';
import { useState, useEffect } from 'react';
import { deleteProjectTaskDependency, deleteUsecaseTaskDependency } from 'store/modules/Task/slice';
import { useDispatch } from 'react-redux';
import { getTemplateProjectTaskDependency, getTemplateUsecaseTaskDependency } from 'store/modules/Templates/Templates';
import { label_client, label_project, label_task } from 'components/Settings/Preferences/CustomLabelCookieHelper';

const AddedDependencyList = (props: any) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  //   const [addedDependencies, setAddedDependencies] = useState<any>([]);
  const [addedTeamDependencies, setAddedTeamDependencies] = useState<any>([]);
  const [addedClientDependencies, setAddedClientDependencies] = useState<any>([]);
  const [itemToDelete, setItemToDelete] = useState<any>({});
  const open = Boolean(anchorEl);

  const dispatch = useDispatch();

  useEffect(() => {
    makeDataForDependency();
  }, [props.dependencies]);

  const makeDataForDependency = () => {
    const dependencyTasksUid = props.dependencies.map((task: any) => task.task_parent_uid);
    const team: any = [];
    for (let i = 0; i < props.teamTasks.length; i++) {
      console.log(props.teamTasks[i].template_task_uid);

      if (dependencyTasksUid.includes(props.teamTasks[i].template_task_uid)) {
        console.log(props.teamTasks[i]);
        team.push(props.teamTasks[i]);
      }
    }
    setAddedTeamDependencies([...team]);

    const client: any = [];
    for (let i = 0; i < props.clientTasks.length; i++) {
      console.log(props.clientTasks[i].template_task_uid);

      if (dependencyTasksUid.includes(props.clientTasks[i].template_task_uid)) {
        console.log(props.clientTasks[i]);
        client.push(props.clientTasks[i]);
      }
    }
    setAddedClientDependencies([...client]);
  };

  console.log(props);

  const handleExcelClick = (event: React.MouseEvent<HTMLElement>, handleTask: any) => {
    console.log(event.currentTarget, handleTask);
    setItemToDelete({ ...handleTask });
    // console.log(clientTasks);

    // setHandleThisTask(handleTask);

    setAnchorEl(event.currentTarget);
  };

  const handleExcelClose = () => {
    setAnchorEl(null);
  };

  const handleSelectFromMenu = (event: React.MouseEvent<HTMLElement>, task: any) => {
    setAnchorEl(null);
    console.log(event.currentTarget.innerText, task);
    const deleteThis: any = [];
    const deleteDetail: any = [];
    for (let i = 0; i < props.dependencies.length; i++) {
      if (props.dependencies[i].task_parent_uid == itemToDelete.template_task_uid) {
        deleteThis.push(props.dependencies[i].dependency_id);
        deleteDetail.push(props.dependencies[i]);
      }
    }

    const deleteId = {
      dependency_id: deleteThis[0],
    };
    if (props.type == 'project') {
      dispatch(deleteProjectTaskDependency(deleteId));
      const getTask = {
        task_uid: deleteDetail[0].task_uid,
      };
      dispatch(getTemplateProjectTaskDependency(getTask));
    } else {
      dispatch(deleteUsecaseTaskDependency(deleteId));
      const getTask = {
        task_uid: deleteDetail[0].task_uid,
        type: 'usecase',
      };
      dispatch(getTemplateUsecaseTaskDependency(getTask));
    }

    // if (event.currentTarget.innerText == 'Dependencies') {
    //   // setAddDependenciesModal(true);
    // } else if (event.currentTarget.innerText == 'Delete Task') {
    //   // deleteSubtask();
    // }

    // if (event.currentTarget.innerText == 'Download') {
    //   console.log('Dowmnload now');

    //   excelExport(clientList, 'Client_List');
    // }
  };

  return (
    <>
      <>
        <Typography sx={{ marginTop: '16px' }} variant="h3">
          Precedent {label_project} {label_task} of team
        </Typography>
        <Grid>
          <Grid mt={3} container direction="row" justifyContent={'space-around'} alignItems="center">
            <Grid item md={2}>
              <Typography variant="subtitle2" className="label-text">
                {label_task} Details
              </Typography>
            </Grid>
            <Grid item md={2}>
              <Typography variant="subtitle2" className="label-text">
                {label_task} Owner
              </Typography>
            </Grid>
            <Grid item md={2}>
              <Typography variant="subtitle2" className="label-text">
                {label_task} Stage
              </Typography>
            </Grid>
            <Grid item md={2}>
              <Typography variant="subtitle2" className="label-text">
                {label_task} Status
              </Typography>
            </Grid>
            <Grid item md={2}>
              {/* <Typography variant="subtitle2" className="label-text">
                    Task Status
                  </Typography> */}
            </Grid>
            {/* <Grid item md={2}>
              <Typography variant="subtitle2" className="label-text">
                Due Date
              </Typography>
            </Grid> */}
            {/* <Grid item md={3}></Grid> */}
          </Grid>
          <Divider />
        </Grid>
        {addedTeamDependencies.map((task: any, index: number) => (
          <Grid mb={1} container key={index} direction="row" justifyContent={'space-around'} alignItems="center">
            <Grid item sm={2} md={2}>
              <Typography
                //  onClick={() => callUpdateSubtaskModal(task)}
                style={{ color: '#2D60F6' }}
                className="cognisaas-pointer"
                variant="subtitle2"
              >
                {task.template_task_detail == '' ? 'test1' : `${task.template_task_detail.slice(0, 7)}...`}
              </Typography>
            </Grid>
            <Grid item sm={2} md={2}>
              <Typography variant="subtitle2">
                {task.template_task_owner == 'z`' ? 'test1' : task.template_task_owner.slice(0, 6)}
              </Typography>
            </Grid>
            <Grid item md={2} sm={2}>
              <Typography variant="subtitle2">
                {task.template_task_stage == '' ? '-' : task.template_task_stage}
              </Typography>
            </Grid>
            <Grid item md={2} sm={2}>
              <Stack justifyContent={'space-between'} direction="row">
                <Typography variant="subtitle2">
                  {task.template_task_stage == '' ? '-' : task.template_task_stage}
                </Typography>
                <IconButton onClick={(e: any) => handleExcelClick(e, task)} sx={{ margin: '0px' }}>
                  <MoreVertIcon />
                </IconButton>
                <CognisaasMenu
                  anchorEl={anchorEl}
                  open={open}
                  handleSelectFromMenu={(e: any) => handleSelectFromMenu(e, task)}
                  handleMenuClose={handleExcelClose}
                  list={['Remove dependency']}
                ></CognisaasMenu>
              </Stack>
            </Grid>

            <Divider />
          </Grid>
        ))}
      </>

      <>
        <Typography sx={{ marginTop: '16px' }} variant="h3">
          Precedent {label_project} {label_task} of {label_client}
        </Typography>
        <Grid>
          <Grid mt={3} container direction="row" justifyContent={'space-around'} alignItems="center">
            <Grid item md={2}>
              <Typography variant="subtitle2" className="label-text">
                {label_task} Details
              </Typography>
            </Grid>
            <Grid item md={2}>
              <Typography variant="subtitle2" className="label-text">
                {label_task} Owner
              </Typography>
            </Grid>
            <Grid item md={2}>
              <Typography variant="subtitle2" className="label-text">
                {label_task} Stage
              </Typography>
            </Grid>
            <Grid item md={2}>
              <Typography variant="subtitle2" className="label-text">
                {label_task} Status
              </Typography>
            </Grid>
            <Grid item md={2}>
              {/* <Typography variant="subtitle2" className="label-text">
                    Task Status
                  </Typography> */}
            </Grid>
            {/* <Grid item md={2}>
              <Typography variant="subtitle2" className="label-text">
                Due Date
              </Typography>
            </Grid> */}
            {/* <Grid item md={3}></Grid> */}
          </Grid>
          <Divider />
        </Grid>
        {addedClientDependencies.map((task: any, index: number) => (
          <Grid mb={1} container key={index} direction="row" justifyContent={'space-around'} alignItems="center">
            <Grid item sm={2} md={2}>
              <Typography
                //  onClick={() => callUpdateSubtaskModal(task)}
                style={{ color: '#2D60F6' }}
                className="cognisaas-pointer"
                variant="subtitle2"
              >
                {task.template_task_detail == '' ? 'test1' : task.template_task_detail.slice(0, 6)}
              </Typography>
            </Grid>
            <Grid item sm={2} md={2}>
              <Typography variant="subtitle2">
                {task.template_task_owner == 'z`' ? 'test1' : task.template_task_owner.slice(0, 6)}
              </Typography>
            </Grid>
            <Grid item md={2} sm={2}>
              <Typography variant="subtitle2">
                {task.template_task_stage == '' ? '-' : task.template_task_stage}
              </Typography>
            </Grid>
            <Grid item md={2} sm={2}>
              <Stack justifyContent={'space-between'} direction="row">
                <Typography variant="subtitle2">
                  {task.template_task_stage == '' ? '-' : task.template_task_stage}
                </Typography>
                <IconButton onClick={(e: any) => handleExcelClick(e, task)} sx={{ margin: '0px' }}>
                  <MoreVertIcon />
                </IconButton>
                <CognisaasMenu
                  anchorEl={anchorEl}
                  open={open}
                  handleSelectFromMenu={(e: any) => handleSelectFromMenu(e, task)}
                  handleMenuClose={handleExcelClose}
                  list={['Remove dependency']}
                ></CognisaasMenu>
              </Stack>
            </Grid>

            <Divider />
          </Grid>
        ))}
      </>
    </>
  );
};

export default AddedDependencyList;
