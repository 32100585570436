import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { Box, Typography, Stack } from '@mui/material';

import {
  disableRbac,
  enableRbac,
} from '../../../store/modules/RBAC/slice';
import {
  selectRbacEnablingCondition,
} from 'store/modules/RBAC/selector';
import CognisaasButton from '../../shared/CognisaasButton/CognisaasButton';
import BasicModal from '../../shared/CognisaasModal/CognisaasModal';

import { logoutUser } from 'store/modules/auth/auth';
import { requestState } from 'store/modules/types';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import CognisaasToast from 'components/shared/CognisaasToast/CognisaasToast';

interface PropType {
  openModal: boolean;
  handleModalClose: (disableClosingModal?:boolean) => void;
  switchState: boolean;
  setSwitchState?: React.Dispatch<React.SetStateAction<boolean>>;
}

const RBACPreferences = (props: PropType) => {
  const rbacEnablingCondition = useAppSelector(selectRbacEnablingCondition);

  const [alertDetails, setAlertDetails] = useState<{
    isVisible: boolean;
    type: 'success' | 'failure';
    message: string;
  }>({ isVisible: false, type: 'success', message: '' });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const openModal: boolean = props.openModal;
  const handleModalClose = props.handleModalClose;

  useEffect(() => {
    if (rbacEnablingCondition.status === requestState.loading) {
      setAlertDetails({ isVisible: true, type: 'success', message: 'Enabling role based Accesss in Progress!' });
    } else if (rbacEnablingCondition.status === requestState.failed) {
      setAlertDetails({ isVisible: true, type: 'failure', message: 'Role based access Failure!' });
    }
  }, [rbacEnablingCondition]);


  const updateRBAC = async () => {
    setIsLoading(true);
    if (props.switchState) {
      await dispatch(enableRbac());
    } else {
      await dispatch(disableRbac());
    }
    dispatch(logoutUser());
    navigate('/login/');
  };

  const handleConfirmButton = () => updateRBAC();

  const handleCancelButton = () => handleModalClose();


  return (
    <BasicModal 
      open={openModal} 
      onClose={() => handleModalClose(isLoading)} 
      width={700} 
      height="fit-content"
    >
       <CognisaasToast
        message={alertDetails.message}
        type={alertDetails.type}
        open={alertDetails.isVisible}
        onClose={() => {
          setAlertDetails({ isVisible: false, type: alertDetails.type, message: '' });
        }}
      />
      <Stack style={{ padding: '10px', borderRadius: '4px' }}>
        <Stack style={{ marginBottom: '20px' }}>
          <Typography variant={'h1'}>Role Based Access Control</Typography>
        </Stack>
        {props.switchState ? (
          <Box>
            <Typography variant="h2">
              Are you sure you want to turn on RBAC ? This action will log you out of the application.
            </Typography>
            <Typography variant="subtitle1" style={{ color: 'DodgerBlue', margin: '5px 0 10px 0' }}>
            Turning on RBAC will assign all users with a default all access role, you can later change role of users as per requirement.
            </Typography>
            <Typography variant="subtitle1" style={{ color: 'DodgerBlue', margin: '5px 0 10px 0' }}>
              This might take a while, please wait until operation completes.
            </Typography>
          </Box>
        ) : (
          <Box>
            <Typography>
              Are you sure you want to turn off RBAC ? This action will log you out of the application.
            </Typography>
            <Typography variant="subtitle1" style={{ color: 'DodgerBlue', margin: '5px 0 10px 0' }}>
            Turning off RBAC will remove roles of all users , if you turn RBAC on later all users will be assigned a default all access role, you can later change role of users as per requirement.
            </Typography>
            <Typography variant="subtitle1" style={{ color: 'DodgerBlue', margin: '5px 0 10px 0' }}>
              This might take a while, please wait until operation completes.
            </Typography>
          </Box>
        )}

        <Stack direction={'row-reverse'} style={{marginTop:'20px'}}>
          {isLoading ? (
            <>
             <LoadingButton
                sx={{ width: '110px' }}
                loading
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="outlined"
              >Updating
              </LoadingButton>
            </>
          ) : (
            <CognisaasButton clickHandler={() => handleConfirmButton()} isOutlined={false} label={'Confirm'} />
          )}

          <div style={{ marginRight: '10px' }}>
            <CognisaasButton clickHandler={() => handleCancelButton()} isOutlined={true} label={'Cancel'} />
          </div>
        </Stack>
      </Stack>
    </BasicModal>
  );
};

export default RBACPreferences;
