import React, { useState, useMemo } from 'react';
import {
  FormControl,
  Select,
  MenuItem,
  ListSubheader,
  TextField,
  InputAdornment,
  SelectChangeEvent,
  // Checkbox,
  ListItemText,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CognisaasCheckBox from '../CognisaasCheckbox/CognisaasCheckbox';

const containsText = (text: string, searchText: string) => text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

interface Props {
  placeholder?: string;
  selectedOption: string[];
  setSelectedOptions: React.Dispatch<React.SetStateAction<string[]>>;
  allOptions: string[];
}

export default function CognisaasMultiSelectWithSearch(props: Props) {
  //   const [selectedOption, setSelectedOption] = useState<string[]>([]);

  const [searchText, setSearchText] = useState('');
  const displayedOptions = useMemo(
    () => props.allOptions.filter((option: string) => containsText(option, searchText)),
    [searchText]
  );

  const handleChange = (event: SelectChangeEvent<typeof props.selectedOption>) => {
    const {
      target: { value },
    } = event;
    props.setSelectedOptions(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );

  };

  return (
    <FormControl fullWidth size="small">
      {/* <InputLabel id="search-select-label">Options</InputLabel> */}
      <Select
        // Disables auto focus on MenuItems and allows TextField to be in focus
        displayEmpty
        MenuProps={{ autoFocus: false, PaperProps: { sx: { maxHeight: 250 } } }}
        inputProps={{ 'aria-label': 'Without label' }}
        labelId="search-select-label"
        id="search-select"
        multiple
        value={props.selectedOption}
        // label="Options"
        onChange={handleChange}
        onClose={() => setSearchText('')}
        // This prevents rendering empty string in Select's value
        // if search text would exclude currently selected option.
        renderValue={(selected) => {
          if (selected.length === 0) {
            return props?.placeholder ? <em>Placeholder</em> : <em>Select</em>;
          }
          return selected.join(', ');
        }}
      >
        {/* TextField is put into ListSubheader so that it doesn't
              act as a selectable item in the menu
              i.e. we can click the TextField without triggering any selection.*/}
        <ListSubheader>
          <TextField
            size="small"
            // Autofocus on textfield
            autoFocus
            placeholder="Type to search..."
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key !== 'Escape') {
                // Prevents autoselecting item while typing (default Select behaviour)
                e.stopPropagation();
              }
            }}
          />
        </ListSubheader>
        {displayedOptions.map((option, i) => (
          <MenuItem key={i} value={option}>
            {/* <Checkbox checked={selectedOption.indexOf(option) > -1} /> */}
            <CognisaasCheckBox checked={props.selectedOption.indexOf(option) > -1} />
            <ListItemText sx={{ marginLeft: '8px' }} primary={option} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
