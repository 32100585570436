import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../index';

const selectActionbleInsightState = (state: RootState) => state.actionableInsights;

// select all data and status and error form open projects
export const selectOpenProjectsState = createSelector(
  selectActionbleInsightState,
  (actionableInsightState) => actionableInsightState.openProjects
);

export const selectOpenUsecasesState = createSelector(
  selectActionbleInsightState,
  (actionableInsightState) => actionableInsightState.openUsecases
);

export const selectRevenueImpactDataState = createSelector(
  selectActionbleInsightState,
  (actionableInsightState) => actionableInsightState.revenueImpactData
);

export const selectRevenueDataState = createSelector(
  selectActionbleInsightState,
  (actionableInsightState) => actionableInsightState.projectRevenue
);

// get projects at risk
export const selectProjectsAtRisk = createSelector(
  selectActionbleInsightState,
  (actionableInsightState) => actionableInsightState.projectsAtRisk
);

// get project at loss or overdue
export const selectProjectsAtLossOrOverdue = createSelector(
  selectActionbleInsightState,
  (actionableInsightState) => actionableInsightState.projectsOverdueOrAtLoss
);

// get projects on track
export const selectProjectsOnTrack = createSelector(
  selectActionbleInsightState,
  (actionableInsightState) => actionableInsightState.projectsOnTrack
);

// get usecase at risk
export const selectUsecaseAtRisk = createSelector(
  selectActionbleInsightState,
  (actionableInsightState) => actionableInsightState.usecasesAtRisk
);

// get usecase at loss or overdue
export const selectUsecaseAtLossOrOverdue = createSelector(
  selectActionbleInsightState,
  (actionableInsightState) => actionableInsightState.usecasesOverdueOrAtLoss
);

// get usecase on track
export const selectUsecaseOnTrack = createSelector(
  selectActionbleInsightState,
  (actionableInsightState) => actionableInsightState.usecasesOnTrack
);

// good fit accounts
export const selectGoodFitAccounts = createSelector(
  selectActionbleInsightState,
  (actionableInsightState) => actionableInsightState.goodFitAccounts
);

// bad fit accounts
export const selectBadFitAccounts = createSelector(
  selectActionbleInsightState,
  (actionableInsightState) => actionableInsightState.badFitAccounts
);

// top3 land usecase client mapping
export const selectTopLandUsecaseMapping = createSelector(
  selectActionbleInsightState,
  (actionableInsightState) => actionableInsightState.topLandUsecaseMapping
);

// top3 expand usecase client mapping
export const selectTopExpandUsecaseMapping = createSelector(
  selectActionbleInsightState,
  (actionableInsightState) => actionableInsightState.topExpandUsecaseMapping
);

// top land product client mapping
export const selectTopLandProductMapping = createSelector(
  selectActionbleInsightState,
  (actionableInsightState) => actionableInsightState.topLandProductMapping
);

// top expand product client mapping
export const selectTopExpandProductMapping = createSelector(
  selectActionbleInsightState,
  (actionableInsightState) => actionableInsightState.topExpandProductMapping
);

// revenue impact recommendations state
export const selectRevenueImpactRecommendations = createSelector(
  selectActionbleInsightState,
  (actionableInsightState) => actionableInsightState.revenueImpactRecommendations
);

// project recommendations state
export const selectProjectRecommendations = createSelector(
  selectActionbleInsightState,
  (actionableInsightState) => actionableInsightState.projectRecommendations
);

// usecase recommendations state
export const selectUsecaseRecommendations = createSelector(
  selectActionbleInsightState,
  (actionableInsightState) => actionableInsightState.usecaseRecommendations
);

// task recommendations state
export const selectTaskRecommendations = createSelector(
  selectActionbleInsightState,
  (actionableInsightState) => actionableInsightState.taskRecommendations
);

// mapped clients data in client mapping
export const selectMappedClientsListState = createSelector(
  selectActionbleInsightState,
  (actionableInsightState) => actionableInsightState.clientMappedTemplateInfo
);

// mapped clients to features
export const selectMappedClientsListToReqState = createSelector(
  selectActionbleInsightState,
  (actionableInsightState) => actionableInsightState.clientMappedToFeatures
);

// get All usecases where delivery managet is Not assigned
export const selectUsecasesWhereDeliveryManagerIsNotAssigned = createSelector(
  selectActionbleInsightState,
  (actionableInsightState) => actionableInsightState.fetchAllUsecasesWhereDeliveryManagerisNotAssigned
);

// get All project where delivery managet is Not assigned
export const selectProjectWhereDeliveryManagerIsNotAssigned = createSelector(
  selectActionbleInsightState,
  (actionableInsightState) => actionableInsightState.fetchAllProjectWhereDeliveryManagerisNotAssigned
);
