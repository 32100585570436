import { isSuperUser } from './getUid';

export const checkFreezePlannedDate = (isPlannedDateDisabled: boolean, isStarted: any) => {
  if (isSuperUser() || !isPlannedDateDisabled) {
    return false;
  }
  if (isStarted) {
    return true;
  }
};
