import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { selectAggregatedCardsState } from 'store/modules/Common/selector';
import { cloneAggregationCard, deleteAggregationCard, getAllAggregatedCards } from 'store/modules/Common/slice';
import { requestState } from 'store/modules/types';
import { getCustomerUid, getLoggedInUserUid } from 'utils/getUid';
import DeleteModal from '../CognisaasModal/DeleteModal';
import AggregatedCard from './AggregatedCard';
import AlterAggregationCardDrawer from './AlterAggregationCardDrawer';
import { allEntityOptions } from './EntityConfig';

interface Props {
  idName: string;
  data: any[];
}

const DisplayAggregatedCards = (props: Props) => {
  const allAggregatedCardsState = useAppSelector(selectAggregatedCardsState);
  const [openAlterAggregationCardDrawer, setOpenAlterAggregationCardDrawer] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [selectedCarduid, setSelectedCarduid] = useState<string>('');
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

  useEffect(() => {
    if (allAggregatedCardsState.data.length === 0 && allAggregatedCardsState.status === requestState.idle) {
      dispatch(getAllAggregatedCards({ customerUid: getCustomerUid(), userUid: getLoggedInUserUid() }));
    }
  }, [allAggregatedCardsState]);

  console.log('data', allAggregatedCardsState.data);

  const implementOperation = (data: any[], operationId: string, dataKey: string): number => {
    const entitySelected = allEntityOptions.filter((item) => item.dataKey === dataKey);
    if (entitySelected.length > 0) {
      const operationToDo = entitySelected[0].aggregableOptions.filter((item: any) => item.operationId === operationId);
      if (operationToDo.length > 0) {
        return operationToDo[0].apply(data, dataKey);
      }
    }
    return 0;
  };

  const checkIfUserHasCardManipulationAccess = (cardDetails: Record<string, string>): boolean => {
    if (getLoggedInUserUid() === cardDetails.created_by || cardDetails.global_write_access === '1') {
      return true;
    }
    return false;
  };

  const handleCardEdit = (cardDetails: Record<string, string>) => {
    setSelectedCarduid(cardDetails.card_uid);
    setOpenAlterAggregationCardDrawer(true);
  };

  const handleCardClone = async (cardDetails: Record<string, string>) => {
    await dispatch(
      cloneAggregationCard({
        cardUid: cardDetails.card_uid,
        customerUid: getCustomerUid(),
        userUid: getLoggedInUserUid(),
      })
    );
    dispatch(getAllAggregatedCards({ customerUid: getCustomerUid(), userUid: getLoggedInUserUid() }));
  };

  const handleCardDelete = async () => {
    await dispatch(
      deleteAggregationCard({
        cardUid: selectedCarduid,
        customerUid: getCustomerUid(),
      })
    );
    dispatch(getAllAggregatedCards({ customerUid: getCustomerUid(), userUid: getLoggedInUserUid() }));
  };

  return (
    <>
      <Stack direction={'row'} spacing={1}>
        {allAggregatedCardsState.data.map((item: any, index: number) => {
          if (item.id_name_for_ui === props.idName) {
            return (
              <AggregatedCard
                key={index}
                header={item.card_name}
                value={`${implementOperation(props.data, item.operation_id_to_perform, item.field_to_aggregate_key)}`}
                color={'#2d60f6'}
                isUnitPrefixed={item.is_unit_prefixed === '1' ? true : false}
                unitString={item.unit_string}
                useAnnotations={item.use_annotations === '1' ? true : false}
                handleEdit={() => {
                  if (checkIfUserHasCardManipulationAccess(item)) {
                    handleCardEdit(item);
                  }
                }}
                handleClone={() => {
                  if (checkIfUserHasCardManipulationAccess(item)) {
                    handleCardClone(item);
                  }
                }}
                handleDelete={() => {
                  if (checkIfUserHasCardManipulationAccess(item)) {
                    setSelectedCarduid(item.card_uid);
                    setOpenDeleteModal(true);
                  }
                }}
              />
            );
          } else {
            <></>;
          }
        })}
      </Stack>
      <AlterAggregationCardDrawer
        cardUid={selectedCarduid}
        idName={props.idName}
        open={openAlterAggregationCardDrawer}
        setOpen={setOpenAlterAggregationCardDrawer}
        sampleData={props.data.length > 0 ? props.data[0] : {}}
      />
      <DeleteModal
        isOpen={openDeleteModal}
        setIsOpen={setOpenDeleteModal}
        message={'Are you sure you want to delete this card'}
        onDelete={handleCardDelete}
      />
    </>
  );
};

export default DisplayAggregatedCards;
