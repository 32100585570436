import 'dhtmlx-gantt';
import 'dhtmlx-gantt/codebase/skins/dhtmlxgantt_terrace.css';
import './Gantt.css';
import { GanttToolbar } from './GanttToolbar';
import { useProjectGantt } from './useProjectGantt';
import UpdateTaskDrawer from 'components/TaskModule/TaskView/UpdateTaskDrawer';

function GanttProject(props: any) {
  const criticalPath = props.criticalPath;
  console.log(criticalPath);
  // const addCriticalTaskIndicator = (tasks: any) => {
  //   console.log(tasks);
  //   for (let task = 0; task < tasks.length; task++) {
  //     for (let i = 0; i < criticalPath.length; i++) {
  //       for (let j = 0; j < criticalPath[i].length; j++) {
  //         if (criticalPath[i][j].task_uid == tasks[task].task_uid) {
  //           // isThisTaskInCriticalPath = true;
  //           tasks[task]['is_critical'] = true;
  //         }
  //       }
  //     }
  //   }
  //   console.log(tasks);
  // };

  const {
    taskData,
    isUpdateTaskDrawerOpen,
    isWorkTimeOn,
    handleClose,
    handleOnZoomIn,
    handleOnZoomOut,
    handleOnExportToExcel,
    handleOnTodayClick,
    handleOnToogleWorkTime,
    handleCriticalPathHighlight,
  } = useProjectGantt();

  // addCriticalTaskIndicator(taskData);

  const ganttToolbarProps = {
    isWorkTimeOn,
    handleOnZoomIn,
    handleOnZoomOut,
    handleOnExportToExcel,
    handleOnTodayClick,
    handleOnToogleWorkTime,
    handleCriticalPathHighlight,
  };

  return (
    <>
      <GanttToolbar {...ganttToolbarProps} />
      <div className="gantt_project" id="gantt_project"></div>
      {isUpdateTaskDrawerOpen && taskData?.client_uid ? (
        <UpdateTaskDrawer
          client={{ client_uid: taskData.client_uid }}
          taskDetail={taskData}
          drawerState={isUpdateTaskDrawerOpen}
          onClose={handleClose}
        />
      ) : null}
    </>
  );
}

export default GanttProject;
