import { useAppDispatch } from 'store/hooks';
import { getUserUid } from 'utils/getUid';
import { saveTemplateProjectSection } from '../../../../../../../store/modules/Templates/Templates';
import { getAllSectionByTemplateProjectuid } from '../../../../../../../store/modules/Templates/Templates';
import { valueToMinutes } from '../helper';

export const addPhaseForProjectTemplate = async (projectUid: string, payload: any) => {
  const dispatch = useAppDispatch();
  await dispatch(saveTemplateProjectSection(payload));
  await dispatch(getAllSectionByTemplateProjectuid(projectUid));
};

export const addProhectTemplateTask = (payload: any) => {
  const data = {
    clone_template_task_uid: '',
    days_to_start: valueToMinutes(payload.durationTime),
    task_sequence: payload.task_sequence,
    template_milestone_task: payload.isMilestone ? '1' : '0',
    template_project_uid: payload.template_project_uid,
    template_section_uid: payload.sectionUid,
    template_task_client_check: payload.shareWithClient ? '1' : '0',
    template_task_description: payload.description,
    template_task_detail: payload.taskName,
    template_task_owner: payload.taskOwner.name,
    template_task_percentage: payload.implementationFee,
    template_task_priority: payload.priority,
    user_uid: getUserUid(payload.taskOwner.name),
  };

  return data;
};

export const addUsecaseTemplateTask = (payload: any) => {
  const data = {
    clone_template_task_uid: '',
    days_to_start: valueToMinutes(payload.durationTime),
    task_sequence: payload.task_sequence,
    template_milestone_task: payload.isMilestone ? '1' : '0',
    template_case_uid: payload.template_case_uid,
    template_section_uid: payload.sectionUid,
    template_task_client_check: payload.shareWithClient ? '1' : '0',
    template_task_description: payload.description,
    template_task_detail: payload.taskName,
    template_task_owner: payload.taskOwner.name,
    template_task_percentage: payload.implementationFee,
    template_task_priority: payload.priority,
    user_uid: getUserUid(payload.taskOwner.name),
  };

  return data;
};
