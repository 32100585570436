import { apis } from '../axiosInstance';
import { actionableInsightRoutes } from '../route';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const getRevenueOfProjects = () =>
  apis.phpAxiosInstance.get(
    actionableInsightRoutes.getProjectsRevenueByCustomerUid(cookies.get('cogni_customer_uid')),
    {
      headers: { JWT: 'true' },
    }
  );

const getAllProjectsList = () =>
  apis.phpAxiosInstance.get(actionableInsightRoutes.getAllProjectsByCustomerUid(cookies.get('cogni_customer_uid')), {
    headers: { JWT: 'true' },
  });

const getRecommendations = () =>
  apis.phpAxiosInstance.get(
    actionableInsightRoutes.getRecommendationsByCustomerUid(cookies.get('cogni_customer_uid')),
    {
      headers: { JWT: 'true' },
    }
  );

const getReportListWithAllCaseGreen = () =>
  apis.phpAxiosInstance.get(actionableInsightRoutes.getReportListWithAllCaseGreen(cookies.get('cogni_customer_uid')), {
    headers: { JWT: 'true' },
  });

const getReportListWithAllCaseNoneGreen = () =>
  apis.phpAxiosInstance.get(
    actionableInsightRoutes.getReportListWithAllCaseNoneGreen(cookies.get('cogni_customer_uid')),
    {
      headers: { JWT: 'true' },
    }
  );

const getTopLandUsecaseMapping = () =>
  apis.phpAxiosInstance.get(
    actionableInsightRoutes.getTop3LandUsecaseMappingByCustomerUid(cookies.get('cogni_customer_uid')),
    {
      headers: { JWT: 'true' },
    }
  );
const getTopExpandUsecaseMapping = () =>
  apis.phpAxiosInstance.get(
    actionableInsightRoutes.getTop3ExpandUsecaseMappingByCustomerUid(cookies.get('cogni_customer_uid')),
    {
      headers: { JWT: 'true' },
    }
  );
const getTopLandProductMapping = () =>
  apis.phpAxiosInstance.get(
    actionableInsightRoutes.getTopLandProductMappingByCustomerUid(cookies.get('cogni_customer_uid')),
    {
      headers: { JWT: 'true' },
    }
  );
const getTopExpandProductMapping = () =>
  apis.phpAxiosInstance.get(
    actionableInsightRoutes.getTopExpandProductMappingByCustomerUid(cookies.get('cogni_customer_uid')),
    {
      headers: { JWT: 'true' },
    }
  );

const getTopProjectRecommendations = () =>
  apis.phpAxiosInstance.get(
    actionableInsightRoutes.getProjectRecommendationsByCustomerUid(cookies.get('cogni_customer_uid')),
    {
      headers: { JWT: 'true' },
    }
  );

const getTopUsecaseRecommendations = () =>
  apis.phpAxiosInstance.get(
    actionableInsightRoutes.getUsecaseRecommendationsByCustomerUid(cookies.get('cogni_customer_uid')),
    {
      headers: { JWT: 'true' },
    }
  );

const getTopTaskRecommendations = () =>
  apis.phpAxiosInstance.get(
    actionableInsightRoutes.getTaskRecommendationsByCustomerUid(cookies.get('cogni_customer_uid')),
    {
      headers: { JWT: 'true' },
    }
  );

const getTemplateInfoByTemplateuid = (template_uid: string) =>
  apis.phpAxiosInstance.get(
    actionableInsightRoutes.getTemplateInfoByTemplateUid(template_uid, cookies.get('cogni_customer_uid')),
    {
      headers: { JWT: 'true' },
    }
  );

const getClientInformationByMultipleFeatureUids = (payload: any) =>
  apis.phpAxiosInstance.post(actionableInsightRoutes.getClientsByMultipleFeatureUids(), payload, {
    headers: { JWT: 'true' },
  });

const getUsecasesWhereDeliveryManagerIsNotAssigned = (user_uid: string) =>
  apis.phpAxiosInstance.get(
    actionableInsightRoutes.getUsecasesWhereDeliveryManagerNotAssigned(cookies.get('cogni_customer_uid'), user_uid),
    { headers: { JWT: 'true' } }
  );

const getProjectWhereDeliveryManagerIsNotAssigned = (user_uid: string) =>
  apis.phpAxiosInstance.get(
    actionableInsightRoutes.getUsecasesWhereDeliveryManagerNotAssigned(cookies.get('cogni_customer_uid'), user_uid),
    { headers: { JWT: 'true' } }
  );

export const actionableInsightAPI = {
  getRevenueOfProjects,
  getAllProjectsList,
  getRecommendations,
  getReportListWithAllCaseGreen,
  getReportListWithAllCaseNoneGreen,
  getTopLandUsecaseMapping,
  getTopExpandUsecaseMapping,
  getTopLandProductMapping,
  getTopExpandProductMapping,
  getTopProjectRecommendations,
  getTopUsecaseRecommendations,
  getTopTaskRecommendations,
  getTemplateInfoByTemplateuid,
  getClientInformationByMultipleFeatureUids,
  getUsecasesWhereDeliveryManagerIsNotAssigned,
  getProjectWhereDeliveryManagerIsNotAssigned,
};
