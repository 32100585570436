import { selectProjectInformation } from '../../../../store/modules/Project/selector';
import { useAppSelector } from '../../../../store/hooks';
import { useState, useEffect } from 'react';
import { alpha, styled } from '@mui/material/styles';
import { Typography, Stack, Avatar, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  DataGridPremium,
  GridToolbar,
  gridClasses,
  GridRenderCellParams,
  GridColumns,
  GridRenderEditCellParams,
} from '@mui/x-data-grid-premium';
import { CustomizedProgressBars } from '../../../shared/CognisaasProgressBar/ProgressBar';
import format from 'date-fns/format';
import CognisaasButton from '../../../shared/CognisaasButton/CognisaasButton';
import { useParams } from 'react-router-dom';
import { getUserLogoLinkByUid, getUserUid } from 'utils/getUid';
import { default as AvatarImage } from '../../../../components/shared/Avatar/Avatar';
import ViewDate, { ViewDateTooltip } from 'components/shared/ViewDate/ViewDate';
import { userPermissionSchema } from 'components/Reports/SideDrawer/types';
import {
  label_delivery_date,
  label_due_date,
  label_use_case,
  label_use_case_delivery_manager,
} from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { selectAllCustomFieldsOfAllUsecases, selectUsecaseCustomFields } from 'store/modules/Usecase/selector';
import { useDispatch } from 'react-redux';
import {
  getAllCustomFieldsOfAllUsecases,
  getAllUsecaseCustomFields,
  updateUsecaseByUid,
  updateUsecaseCustomField,
} from 'store/modules/Usecase/slice';
import { makeDate, transformDataToHaveCustomFields } from 'components/ProjectModule/utils';
import { requestState } from 'store/modules/types';
import {
  formatCurrency,
  generateDataWhileMappingCustomFieldsAsNormalFields,
  isNull,
  isUndefined,
} from 'utils/dataHelpers';
import { generateTableColumnsWithCustomFiltersMapped } from 'components/shared/Tables/CogniTable';
import AddMultipleUsecase from './AddNewUsecase/AddMultipleUsecase';
import { getAllUsers } from 'store/modules/Common/slice';
import { selectUsersOfCompany, selectCustomerDetail } from 'store/modules/Common/selector';
import { SelectEditDropdownCell, SelectEditDateInputCell } from 'components/shared/Tables/InlineEditTable';
import { differenceInDays, formatDistance } from 'date-fns';
import CognisaaasTooltip from 'components/shared/CognisaasTooltip/CognisaasTooltip';
import {
  getDaysRemainingHeader,
  getUsecaseAgeingHeader,
  getUsecaseDaysRemainingWithColorCell,
  formatUsecaseDaysRemainingField,
} from 'components/shared/Tables/TableCells';
import useCustomNames from 'components/shared/CustomNames/useCustomNames';
import NAMED_CONSTATNTS from 'components/shared/CustomNames/Constants';
import useCommonAppConfig from 'components/Common/useCommonAppConfig';
import { getUserActiveState, INACTIVE_USER_COLOR } from 'utils/userHelpers';
import { checkFreezePlannedDate } from 'utils/checkFreezePlannedDate';
import { getMaxColumnWidth } from 'utils/getMaxColumnWidth';
import { handleProjectPlannedDateUpdate } from 'store/modules/Project/slice';

function stringToHslColor(str: string, s: number, l: number) {
  let hash = 0;

  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const h = hash % 360;
  return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToHslColor(name, 30, 80),
    },
    children: (
      <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#000000' }}>
        {name.split(' ')[0][0]}
        {name.indexOf(' ') >= 0 ? name.split(' ')[1][0] : null}
      </Typography>
    ),
  };
}

const UsecaseHome = (props: any) => {
  const navigate = useNavigate();
  const urlParams = useParams();
  const projectInfo = useAppSelector(selectProjectInformation);
  const clientInfo = props.client;
  const users = useAppSelector(selectUsersOfCompany);
  const customerDetail = useAppSelector(selectCustomerDetail);
  const [useCaseInfo, setUsecaseInfo] = useState<any[]>([]);
  const usecaseCustomFields = useAppSelector(selectUsecaseCustomFields);
  const [cfMappedUsecases, setCfMappedUsecases] = useState<any[]>([]);
  const allUsecaseAllCustomFields = useAppSelector(selectAllCustomFieldsOfAllUsecases);
  const [tableColumns, setTableColumns] = useState<GridColumns<any>>([]);
  const [usersOfCompany, setUsersOfCompany] = useState<any[]>([]);
  // const [pageSize, setPageSize] = useState<number>(10);
  const {
    displayDataWithCustomNames,
    getStandardNameMappedValue,
    usecaseStagesDropdownValues,
    usecaseCategoryDropdownValues,
    getStandardRowDataWithoutCustomNames,
  } = useCustomNames();
  const customUseCaseStages = usecaseStagesDropdownValues.map((item: any) => item.custom_stage_name);
  const customUsecaseCategories = usecaseCategoryDropdownValues.map((item: any) => item.custom_category_name);

  // for Bulk usecase creation
  const [openMultipleUsecaseCreationDialog, setOpenMultipleUsecaseCreationDialog] = useState<boolean>(false);
  // for table state
  let myTableState: any = {};
  const { saveAppTableState, getTableState } = useCommonAppConfig();
  const initialState = {
    columns: {
      columnVisibilityModel: {
        case_uid: false,
      },
    },
    pinnedColumns: {
      left: ['case_name'],
    },
    pagination: {
      page: 0,
      pageSize: 20,
    },
    ...getTableState('allUsecaseTableDashboard'),
  };

  const dispatch = useDispatch();

  const ODD_OPACITY = 0.2;

  const StripedDataGrid = styled(DataGridPremium)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: theme.palette.grey[200],
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
      '&.Mui-selected': {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
        '&:hover, &.Mui-hovered': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity
          ),
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
          },
        },
      },
    },
  }));

  const StyledBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: 600,
    width: '100%',
    '& .MuiFormGroup-options': {
      alignItems: 'center',
      paddingBottom: theme.spacing(1),
      '& > div': {
        minWidth: 100,
        margin: theme.spacing(2),
        marginLeft: 0,
      },
    },
  }));

  const generateCommentUi = (comment: string) => {
    const splitForUsers = comment.split('@[');
    return splitForUsers.map((item, index) => {
      if (index === 0) {
        return <span key={index}>{item}</span>;
      } else {
        // index more than 0 something like this expected => vivek](customer:324234) great
        const strContainingUser = item;
        const arrContainingUser = strContainingUser.split('](');
        const username = arrContainingUser[0];
        const restStrArr = arrContainingUser[1].split(')');
        const customerUid = restStrArr[0];
        const restString = [...restStrArr.filter((item, index) => index > 0)].join('');
        return (
          <span key={index}>
            &nbsp;
            <span
              style={{
                backgroundColor: 'rgb(45, 96, 246, 0.1)',
                color: '#2D60F6',
                padding: '2px 10px',
                borderRadius: '33px',
                fontSize: '13px',
                cursor: 'pointer',
              }}
              id={customerUid}
            >
              {username}
            </span>
            {restString}
          </span>
        );
      }
    });
  };

  const generateCommentText = (comment: string): string => {
    const splitForUsers = comment.split('@[');
    const finalCommentStrArr = splitForUsers.map((item, index) => {
      if (index === 0) {
        return item;
      } else {
        const strContainingUser = item;
        const arrContainingUser = strContainingUser.split('](');
        const username = arrContainingUser[0];
        const restStrArr = arrContainingUser[1].split(')');
        const restString = [...restStrArr.filter((item, index) => index > 0)].join('');
        return `${username} ${restString}`;
      }
    });
    return finalCommentStrArr.join(' ');
  };

  useEffect(() => {
    dispatch(getAllCustomFieldsOfAllUsecases());
    dispatch(getAllUsecaseCustomFields());
    dispatch(getAllUsers());
    if (projectInfo) {
      setUsecaseInfo([...projectInfo.usecase]);
    }
  }, []);

  useEffect(() => {
    if (users.length > 0) {
      const data = users.filter((item: any) => item.is_active === '1').map((user: any) => user.user_username);
      data.unshift('Not Assigned');
      setUsersOfCompany([...data]);
    }
  }, [users]);

  useEffect(() => {
    if (projectInfo) {
      setUsecaseInfo([...projectInfo.usecase]);
    }
  }, [projectInfo]);

  useEffect(() => {
    if (
      useCaseInfo.length > 0 &&
      allUsecaseAllCustomFields.status === requestState.success &&
      usecaseCustomFields.length > 0
    ) {
      const transformed_data = transformDataToHaveCustomFields(
        useCaseInfo,
        allUsecaseAllCustomFields.data,
        'case_uid',
        usecaseCustomFields
      );
      setCfMappedUsecases(
        generateDataWhileMappingCustomFieldsAsNormalFields(
          formatUsecaseDaysRemainingField([...transformed_data]),
          'custom_values',
          'field_name',
          'field_value',
          'field_uid',
          'field_type'
        )
      );
    } else {
      setCfMappedUsecases(formatUsecaseDaysRemainingField(useCaseInfo));
    }
  }, [useCaseInfo, allUsecaseAllCustomFields, usecaseCustomFields]);

  const columns = [
    {
      field: 'case_uid',
      hide: true,
    },
    {
      field: 'case_name',
      headerName: `${label_use_case} Name`,
      width: 280,
      renderCell: (params: GridRenderCellParams) => (
        <Typography
          className="cognisaas-pointer"
          variant="subtitle1"
          sx={{ color: '#2D60F6', padding: '7px 0' }}
          onClick={() =>
            navigate(`/projects/project/${urlParams.associationId}/${urlParams.projectId}/${params.row.case_uid}`, {
              state: { clientUid: clientInfo.client_uid, clientName: clientInfo.client_name },
            })
          }
        >
          {params.value}
        </Typography>
      ),
    },

    {
      field: 'progress',
      headerName: 'Progress (in %)',
      width: 260,
      renderCell: (params: GridRenderCellParams) => (
        <CustomizedProgressBars
          value={isNaN(params.row.project_progress) ? 0 : params.row.project_progress}
          statusColor={'grees'}
          hidePercentage={false}
        />
      ),
    },
    {
      field: 'case_age',
      renderHeader: () => getUsecaseAgeingHeader(),
      width: 240,
      renderCell: (params: GridRenderCellParams) => (
        <Stack style={{ padding: '7px 0' }}>
          <Stack spacing={1} direction="row">
            {params.value ? `${params.value} Days` : 'Not Started'}
          </Stack>
        </Stack>
      ),
    },
    {
      field: 'case_days_remaining',
      renderHeader: () => getDaysRemainingHeader(),
      width: 240,
      renderCell: (params: GridRenderCellParams) => getUsecaseDaysRemainingWithColorCell(params),
    },
    {
      field: 'case_planned_startdate',
      hide: false,
      headerName: 'Planned Start Date',
      width: 180,
      editable: true,
      type: 'date',
      renderCell: (params: GridRenderCellParams) => {
        return checkFreezePlannedDate(
          customerDetail?.freeze_planned_date_usecase == '1',
          params.row?.is_case_started
        ) ? (
          <ViewDateTooltip
            date={params.row?.case_planned_startdate}
            text={`Planned start date cannot be changed once the ${label_use_case.toLowerCase()} has been started. Contact an admin if you want to change the planned start date.`}
          />
        ) : (
          <ViewDate date={params.row?.case_planned_startdate} />
        );
      },
      renderEditCell: (params: GridRenderEditCellParams) =>
        !checkFreezePlannedDate(customerDetail?.freeze_planned_date_usecase == '1', params.row?.is_case_started) ? (
          SelectEditDateInputCell(params, handleInlineEditValue)
        ) : (
          <ViewDate date={params.row?.case_planned_startdate} />
        ),
    },
    {
      field: 'case_startdate',
      hide: false,
      headerName: 'Start Date',
      width: 180,
      editable: true,
      type: 'date',
      renderEditCell: (params: GridRenderEditCellParams) => SelectEditDateInputCell(params, handleInlineEditValue),

      renderCell: (params: GridRenderCellParams) => {
        return <ViewDate date={params.row.case_startdate} />;
      },
    },
    {
      field: 'case_deliverydate',
      hide: false,
      headerName: label_delivery_date,
      width: 180,
      editable: false,
      type: 'date',
      renderEditCell: (params: GridRenderEditCellParams) => SelectEditDateInputCell(params, handleInlineEditValue),

      renderCell: (params: GridRenderCellParams) => {
        return <ViewDate date={params.row.case_deliverydate} />;
      },
    },
    {
      field: 'eta_forcasedelivery',
      hide: false,
      headerName: 'Estimated Delivery Date',
      width: 180,
      editable: true,
      type: 'date',
      renderEditCell: (params: GridRenderEditCellParams) => SelectEditDateInputCell(params, handleInlineEditValue),

      renderCell: (params: GridRenderCellParams) => {
        return params.row.eta_forcasedelivery == '0000-00-00 00:00:00' ? (
          <Typography variant="subtitle1">-</Typography>
        ) : (
          <Typography variant="subtitle1">
            {format(new Date(params.row.eta_forcasedelivery.replace(/-/g, '/')), 'dd MMM yyyy')}
          </Typography>
        );
      },
    },
    {
      field: 'case_requiredbydate',
      headerName: label_due_date,
      width: 180,
      editable: true,
      type: 'date',
      renderCell: (params: GridRenderCellParams) => {
        return checkFreezePlannedDate(
          customerDetail?.freeze_planned_date_usecase == '1',
          params.row?.is_case_started
        ) ? (
          <ViewDateTooltip
            date={params.row?.case_requiredbydate}
            text={`${label_due_date} cannot be changed once the ${label_use_case.toLowerCase()} has been started. Contact an admin if you want to change the ${label_due_date.toLowerCase()}.`}
          />
        ) : (
          <ViewDate date={params.row?.case_requiredbydate} />
        );
      },
      renderEditCell: (params: GridRenderEditCellParams) =>
        !checkFreezePlannedDate(customerDetail?.freeze_planned_date_usecase == '1', params.row?.is_case_started) ? (
          SelectEditDateInputCell(params, handleInlineEditValue)
        ) : (
          <ViewDate date={params.row?.case_requiredbydate} />
        ),
    },
    {
      field: 'case_readinessstate_status',
      headerName: 'Status',
      width: 140,
      renderCell: (params: GridRenderCellParams) => (
        <Stack style={{ padding: '7px 0' }}>
          <Stack spacing={1} direction="row">
            <span
              style={{ marginRight: '4px' }}
              className={
                params.value == 'grey'
                  ? 'grey_status_box_task'
                  : params.value == 'red'
                  ? 'red_status_box_task'
                  : params.value == 'amber'
                  ? 'amber_status_box_task'
                  : params.value == 'green'
                  ? 'green_status_box_task'
                  : params.value == 'blue'
                  ? 'blue_status_box_task'
                  : ''
              }
            ></span>
            {params.value == 'grey'
              ? 'On Hold'
              : params.value == 'red'
              ? 'Overdue'
              : params.value == 'amber'
              ? 'At Risk'
              : params.value == 'green'
              ? 'On Track'
              : params.value == 'blue'
              ? 'Completed'
              : ''}
          </Stack>
        </Stack>
      ),
    },
    {
      headerName: 'Category',
      field: 'case_needed_status',
      width: getMaxColumnWidth(customUseCaseStages),
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams) =>
        SelectEditDropdownCell(params, customUsecaseCategories || [], handleInlineEditValue),
    },
    {
      headerName: 'Stage',
      field: 'case_stage',
      width: getMaxColumnWidth(customUseCaseStages),
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams) =>
        SelectEditDropdownCell(params, customUseCaseStages || [], handleInlineEditValue),
    },
    {
      field: 'case_deliverymanager',
      headerName: label_use_case_delivery_manager,
      width: getMaxColumnWidth(usersOfCompany),
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams) =>
        SelectEditDropdownCell(params, usersOfCompany || [], handleInlineEditValue),
      renderCell: (params: GridRenderCellParams) => {
        const userUid = getUserUid(params.value);
        const deliveryManagerProfileLink = getUserLogoLinkByUid(userUid);
        return (
          <Stack direction="row" style={{ padding: '7px 0' }}>
            {deliveryManagerProfileLink.length > 0 ? (
              <AvatarImage src={deliveryManagerProfileLink} height="34px" width="34px" alt="Delivery manager profile" />
            ) : (
              <Avatar style={{ height: '34px', width: '34px', marginTop: '3px' }} {...stringAvatar(params.value)} />
            )}

            {getUserActiveState(getUserUid(params.value), users) === '0' ? (
              <Typography style={{ color: INACTIVE_USER_COLOR }} m={1} variant="subtitle1">
                {params.value}&nbsp;(Inactive)
              </Typography>
            ) : (
              <Typography m={1} variant="subtitle1">
                {params.value}
              </Typography>
            )}
          </Stack>
        );
      },
    },
    {
      headerName: 'Implementation fee',
      width: 200,
      field: 'case_fees',
      renderCell: (params: any) => {
        if (isNull(params?.row[params.field])) {
          return formatCurrency(0, customerDetail?.currency_code);
        } else if (!isNull(params?.row[params.field]) && params?.row[params.field].length > 0) {
          return formatCurrency(params.row[params.field], customerDetail?.currency_code);
        } else {
          return <>-</>;
        }
      },
    },
    {
      headerName: 'Description',
      field: 'case_description',
      width: 240,
    },
    {
      field: 'recent_comment',
      headerName: 'Comment',
      width: 240,
      renderCell: (params: GridRenderCellParams) => (
        <>
          {isUndefined(params.row.recent_comment.comment) ? (
            ''
          ) : (
            <CognisaaasTooltip
              title={
                <Stack sx={{ padding: '16px', width: '240px', maxHeight: 200, overflow: 'scroll' }}>
                  <Stack>
                    <Stack direction={'row'} justifyContent={'space-between'}>
                      {/* user avatar and name */}
                      <Stack direction={'row'} alignItems={'center'}>
                        {getUserLogoLinkByUid(params.row.recent_comment.user_uid).length > 0 ? (
                          <AvatarImage
                            src={getUserLogoLinkByUid(params.row.recent_comment.user_uid)}
                            alt="user profile"
                          />
                        ) : (
                          <Avatar
                            style={{ height: '40px', width: '40px', marginTop: '3px' }}
                            {...stringAvatar(params.row.recent_comment.user_username)}
                          />
                        )}
                        <Typography ml={1} variant={'h3'}>
                          {params.row.recent_comment.user_username}
                        </Typography>
                      </Stack>

                      <Typography className="label-text" variant="subtitle1" style={{ fontSize: '12px' }}>
                        {formatDistance(new Date(params.row.recent_comment.created_on.replace(/-/g, '/')), new Date(), {
                          includeSeconds: true,
                        })}
                        <br />
                        ago
                      </Typography>
                    </Stack>
                    <Typography mt={1} variant={'subtitle1'} sx={{ whiteSpace: 'pre-line' }}>
                      {generateCommentUi(params.row.recent_comment.comment)}
                    </Typography>
                  </Stack>
                </Stack>
              }
            >
              <Typography variant={'subtitle1'}>
                {generateCommentText(params.row.recent_comment.comment).length > 30
                  ? generateCommentText(params.row.recent_comment.comment).slice(0, 30).trim() + '...'
                  : generateCommentText(params.row.recent_comment.comment)}
              </Typography>
            </CognisaaasTooltip>
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    if (cfMappedUsecases.length > 0 && users.length > 0) {
      setTableColumns([
        ...generateTableColumnsWithCustomFiltersMapped(
          cfMappedUsecases[0],
          columns,
          'Usecase',
          usecaseCustomFields,
          handleInlineEditCustomValue,
          users
        ),
      ]);
    }
  }, [cfMappedUsecases, users]);

  const handleInlineEditValue = async (
    params: GridRenderEditCellParams,
    editableField: string,
    editableValue: string
  ) => {
    let newEditableValue = editableValue;
    const newRow: any = {
      ...getStandardRowDataWithoutCustomNames({ ...params.row }),
      case_startdate: format(new Date(params.row.case_startdate), 'yyyy-MM-dd HH:mm:ss'),
      case_planned_startdate: format(new Date(params.row.case_planned_startdate), 'yyyy-MM-dd HH:mm:ss'),
      case_deliverydate: format(new Date(params.row.case_deliverydate), 'yyyy-MM-dd HH:mm:ss'),
      eta_forcasedelivery: format(new Date(params.row.eta_forcasedelivery), 'yyyy-MM-dd HH:mm:ss'),
      case_requiredbydate: format(new Date(params.row.case_requiredbydate), 'yyyy-MM-dd HH:mm:ss'),
    };
    if (editableField === NAMED_CONSTATNTS.usecase_category_key) {
      newEditableValue = getStandardNameMappedValue(editableValue, NAMED_CONSTATNTS.usecase_category_key);
    } else if (editableField === NAMED_CONSTATNTS.usecase_stage_key) {
      newEditableValue = getStandardNameMappedValue(editableValue, NAMED_CONSTATNTS.usecase_stage_key);
    }
    await dispatch(
      updateUsecaseByUid({
        ...newRow,
        [editableField]: newEditableValue,
        case_redinessstate_status: newRow.case_readinessstate_status,
        user_uid: getUserUid(newRow.case_deliverymanager),
      })
    );
    if (editableField == 'case_planned_startdate') {
      const proj = cfMappedUsecases.filter((data: any) => data.case_uid == params.row.case_uid);
      if (
        makeDate(proj[0].case_planned_startdate).getTime() !=
        new Date(convert(params.row.case_planned_startdate)).getTime()
      ) {
        const diff = differenceInDays(
          new Date(convert(params.row.case_planned_startdate)),
          makeDate(proj[0].case_planned_startdate)
        );
        const detail = {
          project_uid: params.row.case_uid,
          shifted_by: diff,
          updated_date: makeDate(params.row.case_planned_startdate),
          entity: 'USECASE',
        };
        await dispatch(handleProjectPlannedDateUpdate(detail));
      }
    }
  };

  const convert = (str: any) => {
    const date = new Date(str),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join('-');
  };

  const handleInlineEditCustomValue = async (
    params: GridRenderEditCellParams,
    editableField: string,
    editableValue: string
  ) => {
    const index = params.indexofCustomField;
    const customFieldData = params.row.custom_values[index];
    await dispatch(updateUsecaseCustomField({ ...customFieldData, field_value: editableValue }));
    await dispatch(getAllCustomFieldsOfAllUsecases());
  };

  const handleTableSave = (idName: string) => {
    saveAppTableState(idName, myTableState);
  };

  return (
    <>
      <Stack my={2} mx={2} direction={'row'} justifyContent={'flex-end'}>
        {props.project.__user_permissions?.includes(userPermissionSchema.EDIT) && (
          <Stack direction={'row'}>
            <CognisaasButton
              isOutlined={false}
              label={'Add ' + label_use_case}
              clickHandler={() => {
                // console.log(' qwerty = ', urlParams.associationId, urlParams.projectId, projectInfo, clientInfo);
                navigate(`/projects/project/add-usecase/${urlParams.associationId}/${urlParams.projectId}`, {
                  state: { projectName: projectInfo.project_name, clientName: clientInfo.client_name },
                });
              }}
            />
            <AddMultipleUsecase
              open={openMultipleUsecaseCreationDialog}
              setOpen={setOpenMultipleUsecaseCreationDialog}
              project_uid={projectInfo.project_uid}
              association_uid={projectInfo.association_uid}
              client_uid={projectInfo.client_uid}
              project_deliverymanager={projectInfo.project_deliverymanager}
            />
          </Stack>
        )}
      </Stack>
      {tableColumns.length > 0 && (
        <StyledBox>
          <StripedDataGrid
            initialState={initialState}
            onStateChange={(tblState: any) => {
              myTableState = tblState;
            }}
            onColumnVisibilityModelChange={() => {
              handleTableSave('allUsecaseTableDashboard');
            }}
            onFilterModelChange={() => {
              handleTableSave('allUsecaseTableDashboard');
            }}
            onPageSizeChange={() => {
              handleTableSave('allUsecaseTableDashboard');
            }}
            rows={displayDataWithCustomNames(cfMappedUsecases)}
            columns={tableColumns}
            components={{
              Toolbar: GridToolbar,
            }}
            componentsProps={{
              toolbar: { showQuickFilter: true },
            }}
            loading={false}
            getRowId={(row) => row.case_uid}
            disableSelectionOnClick
            rowThreshold={0}
            // pageSize={pageSize}
            // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            pagination
            getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'odd' : 'odd')}
            experimentalFeatures={{ newEditingApi: true }}
          />
        </StyledBox>
      )}
    </>
  );
};

export default UsecaseHome;
