import styled from 'styled-components';
import { variant, color, LayoutProps } from 'styled-system';

const common = {
  fontFamily: 'primary',
  fontStyle: 'normal',
  textAlign: 'left',
  boxSizing: 'border-box',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  pointerEvents: 'none',
};

const variants = {
  heading1: {
    fontSize: 10,
    fontWeight: 3,
    ...common,
  },
  heading2: {
    fontSize: 9,
    fontWeight: 2,
    ...common,
  },
  heading3: {
    fontSize: 7,
    fontWeight: 2,
    ...common,
  },
  display: {
    fontSize: 12,
    fontWeight: 1,
    ...common,
  },
  paragraph1: {
    fontSize: 7,
    fontWeight: 1,
    ...common,
  },
  paragraph2: {
    fontSize: 6,
    fontWeight: 1,
    ...common,
  },
  link: {
    fontSize: 7,
    fontWeight: 2,
    color: 'brand_1',
    cursor: 'pointer',
    ...common,
    pointerEvents: 'all',
  },
};

const defaults = {
  margin: 0,
  padding: 0,
  display: 'inline',
};

type TextProps = {
  variant: string;
} & LayoutProps;

const handleProps = ({ variant }: TextProps) => ({
  variant: variant ?? 'paragraph2',
});

export const Text = styled('p').attrs<TextProps>(handleProps)<TextProps>(defaults, color, variant({ variants }));
export const Label = styled('label').attrs<TextProps>(handleProps)<TextProps>(defaults, color, variant({ variants }));
export const SvgText = styled('text').attrs<TextProps>(handleProps)<TextProps>(defaults, color, variant({ variants }));
