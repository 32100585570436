import { Typography, Stack, Avatar } from '@mui/material';
import { selectOpenProjects, selectOpenProjectsStatus } from '../../../store/modules/Reports/OpenProjects/selector';
import {
  selectClosedProjects,
  selectClosedProjectsStatus,
} from '../../../store/modules/Reports/ClosedProjects/selector';
import { fetchOpenProjects } from '../../../store/modules/Reports/OpenProjects/slice';
import { fetchClosedProjects } from '../../../store/modules/Reports/ClosedProjects/slice';
import {
  getAllProjectsOfCustomer,
  getProjectCustomFieldsDetail,
  handleProjectPlannedDateUpdate,
  updateProject,
  updateProjectCustomField,
} from '../../../store/modules/Project/slice';
// import { selectProjectList, selectProjectListStatus } from '../../../store/modules/Project/selector';
import { getGraphConfigurations } from '../../../store/modules/Charts/slice';
import { selectCustomerDetails } from 'store/modules/Settings/selector';
import { getCustomerDetails } from 'store/modules/Settings/slice';
import { requestState } from '../../../store/modules/types';
import CognisaasLoader from '../../shared/CognisaasLoader/CognisaasLoader';
import CognisaasTabs from '../../shared/CognisaasTabs';

import { alpha, styled } from '@mui/material/styles';
import {
  DataGridPremium,
  gridClasses,
  GridRenderCellParams,
  GridToolbar,
  GridRenderEditCellParams,
  GridColumnOrderChangeParams,
} from '@mui/x-data-grid-premium';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import {
  // selectAllCategories,
  selectAllGeneralStatus,
  selectAllIndustries,
  selectJourneyStage,
  selectSegmentValues,
  selectUsersOfCompany,
  selectUsersOfCompanyStatus,
} from '../../../store/modules/Common/selector';
import {
  fetchAllIndustries,
  fetchAllJourneyStages,
  getAllSegmentValues,
  getAllUsers,
} from '../../../store/modules/Common/slice';
// import { ReactComponent as NoRecords } from '../../../assets/svgs/no_records.svg';
// import { Container } from './Projects.styles';
import Box from '@mui/material/Box';

import ReportsHeader, { ReportsPages } from '../ReportsHeader/ReportsHeader';
import ChartView from '../../shared/Charts/ChartView/ChartView';
import CustomiseDrawer from '../../shared/Charts/CustomiseDrawer/CustomiseDrawer';

import { selectAllChartsConfig } from '../../../store/modules/Charts/selector';
import { useAppSelector } from '../../../store/hooks';

import { graphSchema } from '../../shared/Charts/CustomiseDrawer/types';

import {
  formatCurrency,
  generateDataWhileMappingCustomFieldsAsNormalFields,
  generateMappedCustomFieldKey,
  generateOrderedColumn,
  isNull,
  isUndefined,
} from '../../../utils/dataHelpers';
import FilterTypeSelector from 'components/shared/CognisaasFilters/FilterTypeSelector/FilterTypeSelector';
import SelectedFilterList from 'components/shared/CognisaasFilters/SelectedFilterList/SelectedFilterList';
import { selectAllFilters } from 'store/modules/AllFilters/selector';
import { addFilterGroup } from 'store/modules/AllFilters/slice';
import { availableDateRangeOptions, FilterableData, generateOptionsForThisDataKey } from 'utils/filteringFunctions';
// import { availableCategories, availablestages } from '../constants';
import { generateTableColumnsWithCustomFiltersMapped } from 'components/shared/Tables/CogniTable';
import {
  selectProjectCustomFieldsDetail,
  selectProjectCustomFieldsDetailStatus,
} from '../../../store/modules/Project/selector';
import Cookies from 'universal-cookie';
import ViewDate, { ViewDateTooltip } from 'components/shared/ViewDate/ViewDate';
import {
  label_arr,
  label_client,
  label_delivery_date,
  label_due_date,
  label_project,
  label_project_delivery_manager,
  label_use_case,
} from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { SelectEditDateInputCell, SelectEditDropdownCell } from 'components/shared/Tables/InlineEditTable';
import { inlineEditPropType } from 'components/shared/Tables/types';
import CognisaaasTooltip from 'components/shared/CognisaasTooltip/CognisaasTooltip';
import { getUserLogoLinkByUid, getUserUid } from 'utils/getUid';
import { differenceInDays, format, formatDistance } from 'date-fns';
import { default as AvatarImage } from 'components/shared/Avatar/Avatar';
import { CustomizedProgressBars } from 'components/shared/CognisaasProgressBar/ProgressBar';
import {
  getDaysRemainingHeader,
  getProjectAgeingHeader,
  getProjectDaysRemainingWithColorCell,
  formatProjectDaysRemainingField,
} from 'components/shared/Tables/TableCells';
import useCustomNames from 'components/shared/CustomNames/useCustomNames';
import NAMED_CONSTATNTS from 'components/shared/CustomNames/Constants';
import { fetchClientList, updatedcasewithfeaturestatusClient } from 'store/modules/ManageClient/ClientList/slice';
import { selectClientList } from 'store/modules/ManageClient/ClientList/selector';
import checkAccessForRouting from 'components/shared/Hooks/checkAccessForRouting';
import useCommonAppConfig from 'components/Common/useCommonAppConfig';
import { getUserActiveState, INACTIVE_USER_COLOR } from 'utils/userHelpers';
import { checkFreezePlannedDate } from 'utils/checkFreezePlannedDate';
import { getMaxColumnWidth } from 'utils/getMaxColumnWidth';
import { makeDate } from 'components/ProjectModule/utils';

const cookies = new Cookies();

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGridPremium)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
        },
      },
    },
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: 600,
  width: '100%',
  background: 'white',
  borderRadius: '4px',
  boxShadow: '0px 4px 26px rgba(0, 0, 0, 0.05)',
  '& .MuiFormGroup-options': {
    alignItems: 'center',
    paddingBottom: theme.spacing(1),
    '& > div': {
      minWidth: 100,
      margin: theme.spacing(2),
      marginLeft: 0,
    },
  },
}));

function stringToHslColor(str: string, s: number, l: number) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const h = hash % 360;
  return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToHslColor(name, 30, 80),
    },
    children: (
      <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#000000' }}>
        {name.split(' ')[0][0]}
        {name.indexOf(' ') >= 0 ? name.split(' ')[1][0] : null}
      </Typography>
    ),
  };
}

// interface LocationParams {
//   pathname: string;
//   state: any;
//   search: string;
//   hash: string;
//   key: string;
// }

const Projects = () => {
  const dispatch = useDispatch();
  const users = useAppSelector(selectUsersOfCompany);
  const navigate = useNavigate();
  const { projectPrioritiesDropdownValues } = useCustomNames();
  const projectPriorities = projectPrioritiesDropdownValues.map((item: any) => item.priority_name);

  const [idName, setIdName] = useState<string>('');
  const graphConfigs: graphSchema[] = useAppSelector(selectAllChartsConfig);
  const graphs: graphSchema[] = graphConfigs.filter((graph: graphSchema) => graph.idName === idName);
  const [drawerState, setDrawerState] = useState<boolean>(false);
  const [usersOfCompany, setUsersOfCompany] = useState<any[]>([]);
  const [customFieldStatus, setCustomFieldStatus] = useState<boolean>(false); // used to prevent loading page while inline editing custom fields(e.g if we edit custom value it will trigger page reload this will prevent it.)
  const customerDetail = useAppSelector(selectCustomerDetails).data;

  const [selectedTab, setSelectedTab] = useState(0);

  const tabs = ['All', `Open ${label_project}s`, `Closed ${label_project}s`];

  const openProjects = useAppSelector(selectOpenProjects);
  const openProjectsState = useAppSelector(selectOpenProjectsStatus);

  // const [allProjects, setAllProjects] = useState<any[]>([]);

  // const allProjects = useAppSelector(selectProjectList);
  // const allProjectsStatus = useAppSelector(selectProjectListStatus);
  const projectCustomFields = useAppSelector(selectProjectCustomFieldsDetail);
  const projectCustomFieldsStatus = useAppSelector(selectProjectCustomFieldsDetailStatus);

  const [cfMappedAllProjects, setCfMappedAllProjects] = useState<any[]>([]);
  const [cfMappedOpenProjects, setCfMappedOpenProjects] = useState<any[]>([]);
  const [cfMappedClosedProject, setCfMappedClosedProject] = useState<any[]>([]);
  const [filteredProjects, setFilteredProjects] = useState<any[]>([]);
  const [projectColumns, setProjectColumns] = useState<any[]>([]);

  const closedProjects = useAppSelector(selectClosedProjects);
  const closedProjectsState = useAppSelector(selectClosedProjectsStatus);

  const allFilters = useAppSelector(selectAllFilters);
  const allUsers = useAppSelector(selectUsersOfCompany);
  const allUsersStatus = useAppSelector(selectUsersOfCompanyStatus);
  const allSegments = useAppSelector(selectSegmentValues);
  const journeyStages = useAppSelector(selectJourneyStage);
  const allIndustries = useAppSelector(selectAllIndustries);
  const allGeneralStatus = useAppSelector(selectAllGeneralStatus);
  const clientList = useAppSelector(selectClientList);
  // const allCategories = useAppSelector(selectAllCategories);

  let myTableStateForAllProjectsTab: any = {};
  let myTableStateForOpenProjectsTab: any = {};
  let myTableStateForClosedProjectsTab: any = {};
  const { saveAppTableState, getTableState } = useCommonAppConfig();
  // let initialStateAllProjects: any = {};

  const initialStateAllProjects = {
    columns: {
      columnVisibilityModel: {
        implementation_fee_billed: false,
        implementation_fee_pending: false,
      },
    },
    pinnedColumns: {
      left: ['project_name'],
    },
    pagination: {
      page: 0,
      pageSize: 10,
    },
    ...getTableState('projectReportAllProjPage'),
  };

  const initialStateOpenProjects = {
    columns: {
      columnVisibilityModel: {
        implementation_fee_billed: false,
        implementation_fee_pending: false,
      },
    },
    pinnedColumns: {
      left: ['project_name'],
    },
    pagination: {
      page: 0,
      pageSize: 10,
    },
    ...getTableState('projectReportOpenProjPage'),
  };

  const initialStateClosedProjects = {
    columns: {
      columnVisibilityModel: {
        implementation_fee_billed: false,
        implementation_fee_pending: false,
      },
    },
    pinnedColumns: {
      left: ['project_name'],
    },
    pagination: {
      page: 0,
      pageSize: 10,
    },
    ...getTableState('projectReportClosedProjPage'),
  };

  const handleTableSave = (idName: string, thisTableState: any) => {
    saveAppTableState(idName, thisTableState);
  };

  // useEffect(() => {
  //   if (location?.state !== null) {
  //     console.log('o yeah', location?.state);
  //     initialStateAllProjects = {
  //       columns: {
  //         columnVisibilityModel: {},
  //       },
  //       pagination: {
  //         page: 0,
  //         pageSize: 10,
  //       },
  //       filter: {
  //         filterModel: {
  //           items: [
  //             { columnField: 'project_readinessstate_status', operatorValue: 'equals', value: location?.state?.label },
  //           ],
  //         },
  //       },
  //     };
  //     // handleTableSave('projectReportAllProjPage', initialStateAllProjects);
  //   } else {
  //     // initialStateAllProjects = {
  //     //   columns: {
  //     //     columnVisibilityModel: {},
  //     //   },
  //     //   pinnedColumns: {
  //     //     left: ['project_name'],
  //     //   },
  //     //   pagination: {
  //     //     page: 0,
  //     //     pageSize: 10,
  //     //   },
  //     //   filter: {},
  //     //   ...getTableState('projectReportAllProjPage'),
  //     // };
  //   }
  // }, []);

  const handleClientAccess = (params: GridRenderCellParams) => {
    const arrayToCheck = clientList.map((ele) => ele.client_uid);
    const hasAccessToClient = checkAccessForRouting({ arrayToCheck: arrayToCheck, uid: params.row.client_uid });
    if (hasAccessToClient == true) {
      return <Link to={getClient(params.row)}>{params.value}</Link>;
    }
    if (hasAccessToClient == false) {
      return (
        <CognisaaasTooltip
          title={<Typography sx={{ padding: '5px' }}>You do not have access to this {label_client}</Typography>}
        >
          <Typography variant="subtitle1">{params.value}1</Typography>
        </CognisaaasTooltip>
      );
    }
  };

  const [graphData, setGraphData] = useState<graphSchema>({} as graphSchema);
  const [formActionType, setFormActionType] = useState<'save' | 'update'>('save');
  const uniqueColumnId = 'projectReportListTable';
  // const [filterAllModel, setFilterAllModel] = useLocalStorage<GridFilterModel>('reportAllProjectFilters', {
  //   items: [],
  // } as GridFilterModel);
  const {
    displayDataWithCustomNames,
    projectCategoryDropdownValues,
    getStandardNameMappedValue,
    projectStagesDropdownValues,
    getStandardRowDataWithoutCustomNames,
  } = useCustomNames();
  const projectCategories = projectCategoryDropdownValues.map((item: any) => item.custom_category_name);
  const standardProjectStages = projectStagesDropdownValues.map((item: any) => item.stage_name);
  const customProjectStages = projectStagesDropdownValues.map((item: any) => item.custom_stage_name);
  const customProjectCategories = projectCategoryDropdownValues.map((item: any) => item.category_name);
  useEffect(() => {
    const customer_uid = cookies.get('cogni_customer_uid');
    const user_uid = cookies.get('cogni_user_uid');
    const fetchProjectInfo = async () => {
      setCustomFieldStatus(false);
      dispatch(
        getGraphConfigurations({
          type: 'project',
          customer_uid,
          user_uid,
        })
      );
      dispatch(getCustomerDetails());
      dispatch(getProjectCustomFieldsDetail());
      await dispatch(fetchOpenProjects());
      await dispatch(fetchClosedProjects());
      await dispatch(getAllProjectsOfCustomer());
      // addFilters();
    };
    dispatch(fetchClientList());
    fetchProjectInfo();
  }, []);

  // filter options fetch
  useEffect(() => {
    if (allUsersStatus === 'idle' || allUsersStatus === 'failed') {
      dispatch(getAllUsers());
    }
    if (allSegments.status === 'idle' || allSegments.status === 'failed') {
      dispatch(getAllSegmentValues());
    }

    if (journeyStages.status === 'idle' || journeyStages.status === 'failed') {
      dispatch(fetchAllJourneyStages());
    }

    if (allIndustries.status === 'idle' || allIndustries.status === 'failed') {
      dispatch(fetchAllIndustries());
    }
  }, [allUsersStatus, allSegments, journeyStages, allIndustries]);

  useEffect(() => {
    if (openProjectsState === requestState.success && closedProjectsState === requestState.success) {
      let generatedDataOpenAndClosedProjects = generateDataWhileMappingCustomFieldsAsNormalFields(
        [...openProjects, ...closedProjects],
        'custom_values',
        'field_name',
        'field_value',
        'field_uid',
        'field_type'
      );
      generatedDataOpenAndClosedProjects = formatProjectDaysRemainingField(generatedDataOpenAndClosedProjects);

      setCfMappedAllProjects(generatedDataOpenAndClosedProjects);

      let generatedDataOpenProjects = generateDataWhileMappingCustomFieldsAsNormalFields(
        [...openProjects],
        'custom_values',
        'field_name',
        'field_value',
        'field_uid',
        'field_type'
      );
      generatedDataOpenProjects = formatProjectDaysRemainingField(generatedDataOpenProjects);

      setCfMappedOpenProjects(generatedDataOpenProjects);

      let generatedDataClosedProjects = generateDataWhileMappingCustomFieldsAsNormalFields(
        [...closedProjects],
        'custom_values',
        'field_name',
        'field_value',
        'field_uid',
        'field_type'
      );
      generatedDataClosedProjects = formatProjectDaysRemainingField(generatedDataClosedProjects);

      setCfMappedClosedProject(generatedDataClosedProjects);
    }
  }, [openProjects, openProjectsState, closedProjects, closedProjectsState]);

  const generateCommentUi = (comment: string) => {
    const splitForUsers = comment.split('@[');
    return splitForUsers.map((item, index) => {
      if (index === 0) {
        return <span key={index}>{item}</span>;
      } else {
        // index more than 0 something like this expected => vivek](customer:324234) great
        const strContainingUser = item;
        const arrContainingUser = strContainingUser.split('](');
        const username = arrContainingUser[0];
        const restStrArr = arrContainingUser[1].split(')');
        const customerUid = restStrArr[0];
        const restString = [...restStrArr.filter((item, index) => index > 0)].join('');
        return (
          <span key={index}>
            &nbsp;
            <span
              style={{
                backgroundColor: 'rgb(45, 96, 246, 0.1)',
                color: '#2D60F6',
                padding: '2px 10px',
                borderRadius: '33px',
                fontSize: '13px',
                cursor: 'pointer',
              }}
              id={customerUid}
            >
              {username}
            </span>
            {restString}
          </span>
        );
      }
    });
  };

  const generateCommentText = (comment: string): string => {
    const splitForUsers = comment.split('@[');
    const finalCommentStrArr = splitForUsers.map((item, index) => {
      if (index === 0) {
        return item;
      } else {
        const strContainingUser = item;
        const arrContainingUser = strContainingUser.split('](');
        const username = arrContainingUser[0];
        const restStrArr = arrContainingUser[1].split(')');
        const restString = [...restStrArr.filter((item, index) => index > 0)].join('');
        return `${username} ${restString}`;
      }
    });
    return finalCommentStrArr.join(' ');
  };
  const columns = [
    {
      headerName: `${label_project} Name`,
      field: 'project_name',
      width: 240,
      renderCell: (params: any) => (
        <Typography
          variant="subtitle1"
          className="cognisaas-pointer"
          sx={{ color: '#2D60F6' }}
          onClick={() => navigate(`/projects/project/${params.row.association_uid}/${params.row.project_uid}`)}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      headerName: `${label_client} Name`,
      field: 'client_name',
      width: 240,
      renderCell: (params: GridRenderCellParams) => handleClientAccess(params),
    },
    {
      headerName: label_project_delivery_manager,
      field: 'project_deliverymanager',
      width: getMaxColumnWidth(usersOfCompany),
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams) =>
        SelectEditDropdownCell(params, usersOfCompany || [], handleInlineEditValue),
      renderCell: (params: GridRenderCellParams) => (
        <Stack direction="row" style={{ padding: '5px 0' }}>
          <Stack spacing={1} direction={'row'} alignItems={'center'}>
            <Avatar style={{ height: '34px', width: '34px', marginTop: '3px' }} {...stringAvatar(params.value)} />
            {getUserActiveState(getUserUid(params.value), users) === '0' ? (
              <Typography style={{ color: INACTIVE_USER_COLOR }} mt={1} variant="subtitle1">
                {params.value}&nbsp;(Inactive)
              </Typography>
            ) : (
              <Typography mt={1} variant="subtitle1">
                {params.value}
              </Typography>
            )}
          </Stack>
        </Stack>
      ),
    },
    {
      field: 'project_progress',
      headerName: 'Progress (in %)',
      width: 220,
      renderCell: (params: GridRenderCellParams) => (
        <CustomizedProgressBars value={params.value} statusColor={'grees'} hidePercentage={false} />
      ),
    },
    {
      field: 'project_age',
      renderHeader: () => getProjectAgeingHeader(),
      width: 240,
      renderCell: (params: GridRenderCellParams) => (
        <Stack style={{ padding: '7px 0' }}>
          <Stack spacing={1} direction="row">
            {params.value ? `${params.value} Days` : 'Not Started'}
          </Stack>
        </Stack>
      ),
    },
    {
      field: 'project_days_remaining',
      renderHeader: () => getDaysRemainingHeader(),
      width: 240,
      renderCell: (params: GridRenderCellParams) => getProjectDaysRemainingWithColorCell(params),
    },
    {
      headerName: 'Category',
      field: 'project_needed_status',
      width: getMaxColumnWidth(projectCategories),
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams) =>
        SelectEditDropdownCell(params, projectCategories || [], handleInlineEditValue),
    },
    {
      headerName: 'Priority',
      field: 'project_priority',
      width: 240,
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams) =>
        SelectEditDropdownCell(params, projectPriorities || [], handleInlineEditValue),
    },
    {
      headerName: 'Status',
      field: 'project_readinessstate_status',
      width: 150,

      renderCell: (params: GridRenderCellParams) => (
        <Stack>
          <Stack spacing={1} direction="row">
            <span
              style={{ marginRight: '4px' }}
              className={
                params.value == 'red'
                  ? 'red_status_box_task'
                  : params.value == 'amber'
                  ? 'amber_status_box_task'
                  : params.value == 'green'
                  ? 'green_status_box_task'
                  : params.value == 'blue'
                  ? 'blue_status_box_task'
                  : ''
              }
            ></span>
            {params.value == 'red'
              ? 'Overdue'
              : params.value == 'amber'
              ? 'At Risk'
              : params.value == 'green'
              ? 'On Track'
              : params.value == 'blue'
              ? 'Completed'
              : ''}
          </Stack>
        </Stack>
      ),
    },
    {
      field: 'total_usecase',
      headerName: `Total ${label_use_case}s `,
      width: 150,
      renderCell: (params: GridRenderCellParams) => <Typography variant="subtitle1">{params.value}</Typography>,
    },
    {
      field: 'red_usecase',
      renderHeader: () => (
        <>
          <span style={{ marginRight: '4px' }} className={'red_status_box_task'}></span>
          {`Overdue ${label_use_case}s `}
        </>
      ),
      width: 200,
      renderCell: (params: GridRenderCellParams) => (
        <Stack style={{ padding: '7px 0' }}>
          <Stack spacing={1} direction="row">
            {params.value}
          </Stack>
        </Stack>
      ),
    },
    {
      field: 'amber_usecase',
      renderHeader: () => (
        <>
          <span style={{ marginRight: '4px' }} className={'amber_status_box_task'}></span>
          {`At Risk ${label_use_case}s `}
        </>
      ),
      width: 200,
      renderCell: (params: GridRenderCellParams) => (
        <Stack style={{ padding: '7px 0' }}>
          <Stack spacing={1} direction="row">
            {params.value}
          </Stack>
        </Stack>
      ),
    },
    {
      field: 'green_usecase',
      renderHeader: () => (
        <>
          <span style={{ marginRight: '4px' }} className={'green_status_box_task'}></span>
          {`On Track ${label_use_case}s `}
        </>
      ),
      width: 200,
      renderCell: (params: GridRenderCellParams) => (
        <Stack style={{ padding: '7px 0' }}>
          <Stack spacing={1} direction="row">
            {params.value}
          </Stack>
        </Stack>
      ),
    },
    {
      field: 'blue_usecase',
      renderHeader: () => (
        <>
          <span style={{ marginRight: '4px' }} className={'blue_status_box_task'}></span>
          {`Completed ${label_use_case}s `}
        </>
      ),
      width: 210,
      renderCell: (params: GridRenderCellParams) => (
        <Stack style={{ padding: '7px 0' }}>
          <Stack spacing={1} direction="row">
            {params.value}
          </Stack>
        </Stack>
      ),
    },
    {
      headerName: 'Stage',
      field: 'project_stage',
      width: getMaxColumnWidth(customProjectStages),
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams) =>
        SelectEditDropdownCell(params, customProjectStages || [], handleInlineEditValue),
    },
    {
      field: 'project_planned_startdate',
      headerName: 'Planned Start Date',
      minWidth: 200,
      flex: 1,
      editable: true,
      type: 'date',
      // valueGetter: ({ value }: any) => value && new Date(value),
      renderCell: (params: GridRenderCellParams) => {
        return checkFreezePlannedDate(
          customerDetail?.freeze_planned_date_project == '1',
          params.row?.is_project_started
        ) ? (
          <ViewDateTooltip
            date={params.row?.project_planned_startdate}
            text={`Planned start date cannot be changed once the ${label_project.toLowerCase()} has been started. Contact an admin if you want to change the planned start date.`}
          />
        ) : (
          <ViewDate date={params.row?.project_planned_startdate} />
        );
      },
      renderEditCell: (params: GridRenderEditCellParams) =>
        !checkFreezePlannedDate(customerDetail?.freeze_planned_date_project == '1', params.row?.is_project_started) ? (
          SelectEditDateInputCell(params, handleInlineEditValue)
        ) : (
          <ViewDate date={params.row?.project_planned_startdate} />
        ),
    },
    {
      headerName: 'Start Date',
      field: 'project_startdate',
      width: 200,
      editable: true,
      type: 'date',
      // valueGetter: ({ value }: any) => value && new Date(value),
      renderCell: (params: GridRenderCellParams) => {
        return <ViewDate date={params.row.project_startdate} />;
      },
      renderEditCell: (params: GridRenderEditCellParams) => SelectEditDateInputCell(params, handleInlineEditValue),
    },
    {
      headerName: label_due_date,
      field: 'project_requiredbydate',
      editable: true,
      width: 200,
      type: 'date',
      // valueGetter: ({ value }: any) => value && new Date(value),
      renderCell: (params: GridRenderCellParams) => {
        return checkFreezePlannedDate(
          customerDetail.freeze_planned_date_project == '1',
          params.row.is_project_started
        ) ? (
          <ViewDateTooltip
            date={params.row.project_requiredbydate}
            text={`${label_due_date} cannot be changed once the ${label_project.toLowerCase()} has been started. Contact an admin if
            you want to change the ${label_due_date.toLowerCase()}.`}
          />
        ) : (
          <ViewDate date={params.row.project_requiredbydate} />
        );
      },
      renderEditCell: (params: GridRenderEditCellParams) =>
        !checkFreezePlannedDate(customerDetail.freeze_planned_date_project == '1', params.row.is_project_started) ? (
          SelectEditDateInputCell(params, handleInlineEditValue)
        ) : (
          <ViewDate date={params.row.project_requiredbydate} />
        ),
    },
    {
      headerName: 'Last updated',
      field: 'project_modified_on',
      editable: false,
      width: 200,
      type: 'date',
      // valueGetter: ({ value }: any) => value && new Date(value),
      renderCell: (params: GridRenderCellParams) => {
        return <ViewDate date={params.row.project_modified_on} />;
      },
      // renderEditCell: (params: GridRenderEditCellParams) => SelectEditDateInputCell(params, handleInlineEditValue),
    },
    {
      headerName: 'Last status email sent',
      field: 'last_status_report_sent_time',
      editable: false,
      width: 200,
      type: 'date',
      renderCell: (params: GridRenderCellParams) => {
        return <ViewDate date={params.row.last_status_report_sent_time} />;
      },
    },
    {
      headerName: label_delivery_date,
      field: 'project_deliverydate',
      editable: false,
      width: 200,
      type: 'date',
      // valueGetter: ({ value }: any) => value && new Date(value),
      renderCell: (params: GridRenderCellParams) => {
        return <ViewDate date={params.row.project_deliverydate} />;
      },
      renderEditCell: (params: GridRenderEditCellParams) => SelectEditDateInputCell(params, handleInlineEditValue),
    },
    {
      headerName: 'Estimated Delivery Date',
      width: 200,
      field: 'eta_forprojectdelivery',
      editable: true,
      type: 'date',
      // valueGetter: ({ value }: any) => value && new Date(value),
      renderCell: (params: GridRenderCellParams) => {
        return <ViewDate date={params.row.eta_forprojectdelivery} />;
      },
      renderEditCell: (params: GridRenderEditCellParams) => SelectEditDateInputCell(params, handleInlineEditValue),
    },
    {
      headerName: `${label_project} ${label_arr}`,
      width: 200,
      field: 'project_ARR',
      renderCell: (params: any) => {
        if (params?.row[params.field].length > 0) {
          return formatCurrency(params.row[params.field], customerDetail?.currency_code);
        } else {
          return <>-</>;
        }
      },
    },
    {
      headerName: 'Implementation fee',
      width: 200,
      field: 'project_fees',
      renderCell: (params: any) => {
        if (isNull(params?.row[params.field])) {
          return formatCurrency(0, customerDetail?.currency_code);
        } else if (!isNull(params?.row[params.field]) && params?.row[params.field].length > 0) {
          return formatCurrency(params.row[params.field], customerDetail?.currency_code);
        } else {
          return <>-</>;
        }
      },
    },
    {
      headerName: 'Implementation fee billed',
      width: 200,
      field: 'implementation_fee_billed',
      renderCell: (params: any) => {
        if (isNull(params.row[params.field])) {
          return formatCurrency(0, customerDetail?.currency_code);
        } else if (
          !isNull(params?.row[params.field]) &&
          params?.row[params.field].length > 0 &&
          !isNaN(params?.row[params.field])
        ) {
          return formatCurrency(parseInt(params?.row[params.field]), customerDetail?.currency_code);
        } else {
          return <>-</>;
        }
      },
    },
    {
      headerName: 'Implementation fee pending',
      width: 200,
      field: 'implementation_fee_pending',
      renderCell: (params: any) => {
        if (isNull(params?.row[params.field])) {
          const totalFee = isNull(params.row['project_fees']) ? 0 : params.row['project_fees'];
          return formatCurrency(totalFee, customerDetail?.currency_code);
        } else if (
          !isNull(params?.row[params.field]) &&
          params?.row[params.field].length > 0 &&
          !isNaN(params?.row[params.field])
        ) {
          const implemetationFeePending = params?.row[params.field];
          return formatCurrency(implemetationFeePending, customerDetail?.currency_code);
        } else {
          return <>-</>;
        }
      },
    },
    {
      headerName: 'Description',
      field: 'project_description',
      width: 240,
    },
    {
      field: 'recent_comment',
      headerName: 'Comment',
      width: 240,
      renderCell: (params: GridRenderCellParams) => (
        <>
          {isUndefined(params.row.recent_comment.comment) ? (
            ''
          ) : (
            <CognisaaasTooltip
              title={
                <Stack sx={{ padding: '16px', width: '240px', maxHeight: 200, overflow: 'scroll' }}>
                  <Stack>
                    <Stack direction={'row'} justifyContent={'space-between'}>
                      {/* user avatar and name */}
                      <Stack direction={'row'} alignItems={'center'}>
                        {getUserLogoLinkByUid(params.row.recent_comment.user_uid).length > 0 ? (
                          <AvatarImage
                            src={getUserLogoLinkByUid(params.row.recent_comment.user_uid)}
                            alt="user profile"
                          />
                        ) : (
                          <Avatar
                            style={{ height: '40px', width: '40px', marginTop: '3px' }}
                            {...stringAvatar(params.row.recent_comment.user_username)}
                          />
                        )}
                        <Typography ml={1} variant={'h3'}>
                          {params.row.recent_comment.user_username}
                        </Typography>
                      </Stack>

                      <Typography className="label-text" variant="subtitle1" style={{ fontSize: '12px' }}>
                        {formatDistance(new Date(params.row.recent_comment.created_on.replace(/-/g, '/')), new Date(), {
                          includeSeconds: true,
                        })}
                        <br />
                        ago
                      </Typography>
                    </Stack>
                    <Typography mt={1} variant={'subtitle1'} sx={{ whiteSpace: 'pre-line' }}>
                      {generateCommentUi(params.row.recent_comment.comment)}
                    </Typography>
                  </Stack>
                </Stack>
              }
            >
              <Typography variant={'subtitle1'}>
                {generateCommentText(params.row.recent_comment.comment).length > 30
                  ? generateCommentText(params.row.recent_comment.comment).slice(0, 30).trim() + '...'
                  : generateCommentText(params.row.recent_comment.comment)}
              </Typography>
            </CognisaaasTooltip>
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    if (cfMappedAllProjects.length > 0) {
      if (
        allUsersStatus === 'success' &&
        allSegments.status == 'success' &&
        journeyStages.status == 'success' &&
        allIndustries.status == 'success' &&
        standardProjectStages.length > 0
      ) {
        const defaultProjectFilters = [
          {
            name: label_project_delivery_manager,
            dataKey: 'project_deliverymanager',
            isSelected: false,
            equalToValue: [],
            canBeCleared: true,
            availableOptions: ['Not Assigned', ...allUsers.map((item: any) => item.user_username)],
          },
          {
            name: `${label_client} Name`,
            dataKey: 'client_name',
            isSelected: false,
            equalToValue: [],
            canBeCleared: true,
            availableOptions: generateOptionsForThisDataKey(cfMappedAllProjects, 'client_name'),
          },
          {
            name: 'Priority',
            dataKey: 'project_priority',
            isSelected: false,
            equalToValue: [],
            canBeCleared: true,
            availableOptions: projectPriorities,
          },
          {
            name: 'Category',
            dataKey: 'project_needed_status',
            isSelected: false,
            equalToValue: [],
            canBeCleared: true,
            availableOptions: customProjectCategories,
          },
          {
            name: 'Status',
            dataKey: 'project_readinessstate_status',
            isSelected: false,
            equalToValue: [],
            canBeCleared: true,
            availableOptions: allGeneralStatus.data,
          },
          {
            name: `${label_project} Stage`,
            dataKey: 'project_stage',
            isSelected: false,
            equalToValue: [],
            canBeCleared: true,
            availableOptions: standardProjectStages,
          },
          {
            name: `${label_project} age$$number-range`,
            dataKey: 'project_age',
            isSelected: false,
            equalToValue: [],
            canBeCleared: true,
            availableOptions: availableDateRangeOptions,
          },
          {
            name: 'Last status email sent$$date-range-picker',
            dataKey: 'last_status_report_sent_time',
            isSelected: false,
            equalToValue: [],
            canBeCleared: true,
            availableOptions: availableDateRangeOptions,
          },
          {
            name: 'Start date$$date-range-picker',
            dataKey: 'project_startdate',
            isSelected: false,
            equalToValue: [],
            canBeCleared: true,
            availableOptions: availableDateRangeOptions,
          },
          {
            name: label_due_date + '$$date-range-picker',
            dataKey: 'project_requiredbydate',
            isSelected: false,
            equalToValue: [],
            canBeCleared: true,
            availableOptions: availableDateRangeOptions,
          },
        ];

        const cfProjects: any[] = [];

        projectCustomFields.forEach((cf) => {
          if (['RAG status', 'Dropdown'].includes(cf.field_type)) {
            cfProjects.push({
              name: cf.field_name,
              dataKey: generateMappedCustomFieldKey(cf.field_name, cf.field_uid, cf.field_type),
              isSelected: false,
              equalToValue: [],
              canBeCleared: true,
              availableOptions: cf.valid_values.split(','),
            });
          } else if (cf.field_type === 'Date') {
            cfProjects.push({
              name: `${cf.field_name}$$date-range-picker`,
              dataKey: generateMappedCustomFieldKey(cf.field_name, cf.field_uid, cf.field_type),
              isSelected: false,
              equalToValue: [],
              canBeCleared: true,
              availableOptions: availableDateRangeOptions,
            });
          }
        });

        if (isUndefined(allFilters['reportsProjectModulePage']) || isNull(allFilters['reportsProjectModulePage'])) {
          dispatch(
            addFilterGroup({
              idName: 'reportsProjectModulePage',
              appliedFilters: [...defaultProjectFilters, ...cfProjects],
            })
          );
        }
      }

      if (projectCustomFieldsStatus === 'success' && projectCustomFields.length > 0 && users.length > 0) {
        setProjectColumns(
          generateOrderedColumn(
            [
              ...generateTableColumnsWithCustomFiltersMapped(
                cfMappedAllProjects[0],
                columns,
                'Project',
                projectCustomFields,
                handleInlineEditCustomValue,
                users
              ),
            ],
            uniqueColumnId
          )
        );
      } else {
        setProjectColumns(columns);
      }
    }
  }, [
    cfMappedAllProjects,
    projectCustomFieldsStatus,
    projectCustomFields,
    allUsersStatus,
    allSegments,
    journeyStages,
    allIndustries,
    users,
  ]);

  // actual filtering logic happening here
  useEffect(() => {
    if (selectedTab === 0) {
      setIdName('reportsPageAllProjects');
      setFilteredProjects(cfMappedAllProjects);
      if (allFilters['reportsProjectModulePage']) {
        const newFilteredData = new FilterableData(cfMappedAllProjects);
        newFilteredData.bulkFilter(allFilters['reportsProjectModulePage'].appliedFilters);
        setFilteredProjects(newFilteredData.toArray());
      }
    } else if (selectedTab === 1) {
      setIdName('reportsPageOpenProjects');
      setFilteredProjects(cfMappedOpenProjects);
      if (allFilters['reportsProjectModulePage']) {
        const newFilteredData = new FilterableData(cfMappedOpenProjects);
        newFilteredData.bulkFilter(allFilters['reportsProjectModulePage'].appliedFilters);
        setFilteredProjects(newFilteredData.toArray());
      }
    } else if (selectedTab === 2) {
      setIdName('reportsPageClosedProjects');
      setFilteredProjects(cfMappedClosedProject);
      if (allFilters['reportsProjectModulePage']) {
        const newFilteredData = new FilterableData(cfMappedClosedProject);
        newFilteredData.bulkFilter(allFilters['reportsProjectModulePage'].appliedFilters);
        setFilteredProjects(newFilteredData.toArray());
      }
    }
  }, [allFilters, selectedTab, cfMappedAllProjects]);

  // const addFilters = () => {
  //   const user_name = cookies.get('user_name');
  //   if (location.state !== null) {
  //     // const { data } = location.state;
  //     // console.log('jjjjjjjjjjjjjjjjjjjjj', location);
  //     const filter = {
  //       items: [
  //         {
  //           columnField: 'client_readiness_status',
  //           operatorValue: 'equals',
  //           value: location.state.label,
  //         },
  //         {
  //           columnField: 'client_account_owner',
  //           operatorValue: 'equals',
  //           value: user_name,
  //         },
  //         {
  //           columnField: 'client_sales_owner',
  //           operatorValue: 'equals',
  //           value: user_name,
  //         },
  //       ],
  //     };
  //     setFilterAllModel(filter);
  //   }
  // };

  // function getDifferenceInDaysBetweenDates(date1: Date, date2: Date) {
  //   const timeDelta = date1.valueOf() - date2.valueOf();
  //   const timeDeltaInDays = parseInt(timeDelta.toString()) / (1000 * 3600 * 24);

  //   return parseInt(timeDeltaInDays.toString());
  // }

  function getTabSwitcher(selectedTab: number) {
    return (
      <Stack direction={'row'} justifyContent={'space-between'}>
        <CognisaasTabs
          size="squeezed"
          selectedTabIndex={selectedTab}
          handleTabSwitch={handleTabSelection}
          tabs={tabs}
        />
        <Box>
          <FilterTypeSelector idName="reportsProjectModulePage" />
        </Box>
      </Stack>
    );
  }

  function handleTabSelection(_event: any, tabIndex: number) {
    setSelectedTab(tabIndex);
  }

  useEffect(() => {
    if (users.length == 0) {
      dispatch(getAllUsers());
    }
  }, []);

  useEffect(() => {
    if (users.length > 0) {
      setUsersData();
    }
  }, [users]);

  const setUsersData = () => {
    const data = users.filter((user: any) => user.is_active === '1').map((user: any) => user.user_username);
    data.unshift('Not Assigned');
    setUsersOfCompany([...data]);
  };

  const getClient = (row: any) => {
    return `/client-list/client/${row.client_uid}`;
  };

  const handleInlineEditValue = async (
    params: GridRenderEditCellParams,
    editableField: string,
    editableValue: string
  ) => {
    const newRow = {
      ...getStandardRowDataWithoutCustomNames({ ...params.row }),
      project_startdate: format(new Date(params.row.project_startdate), 'yyyy-MM-dd HH:mm:ss'),
      project_planned_startdate: format(new Date(params.row.project_planned_startdate), 'yyyy-MM-dd HH:mm:ss'),
      project_deliverydate: format(new Date(params.row.project_deliverydate), 'yyyy-MM-dd HH:mm:ss'),
      eta_forprojectdelivery: format(new Date(params.row.eta_forprojectdelivery), 'yyyy-MM-dd HH:mm:ss'),
      project_requiredbydate: format(new Date(params.row.project_requiredbydate), 'yyyy-MM-dd HH:mm:ss'),
    };
    let newEditableValue = editableValue;
    if (editableField === NAMED_CONSTATNTS.project_category_key) {
      newEditableValue = getStandardNameMappedValue(editableValue, NAMED_CONSTATNTS.project_category_key);
    } else if (editableField === NAMED_CONSTATNTS.project_stage_key) {
      newEditableValue = getStandardNameMappedValue(editableValue, NAMED_CONSTATNTS.project_stage_key);
    }
    await dispatch(
      updateProject({
        ...newRow,
        [editableField]: newEditableValue,
        user_uid: editableField === 'project_deliverymanager' ? getUserUid(editableValue) : params.row.user_uid,
      })
    );
    if (editableField === 'project_stage') {
      await dispatch(updatedcasewithfeaturestatusClient(params.row.client_uid));
    }
    if (editableField == 'project_planned_startdate') {
      console.log(params.row, filteredProjects);
      const proj = filteredProjects.filter((data: any) => data.project_uid == params.row.project_uid);
      // const planned = format(params.row.project_planned_startdate, 'yyyy-MM-dd');
      // console.log(planned);
      if (
        makeDate(proj[0].project_planned_startdate).getTime() !=
        new Date(convert(params.row.project_planned_startdate)).getTime()
      ) {
        console.log(
          makeDate(proj[0].project_planned_startdate).getTime() ==
            new Date(convert(params.row.project_planned_startdate)).getTime()
        );
        const diff = differenceInDays(
          new Date(convert(params.row.project_planned_startdate)),
          makeDate(proj[0].project_planned_startdate)
        );
        const detail = {
          project_uid: params.row.project_uid,
          shifted_by: diff,
          updated_date: makeDate(params.row.project_planned_startdate),
          entity: 'PROJECT',
        };
        await dispatch(handleProjectPlannedDateUpdate(detail));
        console.log(diff);
      }
      console.log(proj);
    }
  };

  const convert = (str: any) => {
    const date = new Date(str),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join('-');
  };

  const handleInlineEditCustomValue = async (
    params: GridRenderEditCellParams,
    editableField: string,
    editableValue: string,
    type?: inlineEditPropType
  ) => {
    const index = params.indexofCustomField;
    const customFieldData = params.row.custom_values[index];
    if (type === 'Project') {
      setCustomFieldStatus(true);
      await dispatch(updateProjectCustomField({ ...customFieldData, field_value: editableValue }));
      await dispatch(fetchOpenProjects());
      await dispatch(fetchClosedProjects());
      await dispatch(getAllProjectsOfCustomer());
      setCustomFieldStatus(false);
    }
  };

  const handleColumnOrderChange = (params: GridColumnOrderChangeParams) => {
    const oldArrayIndex = params.oldIndex;
    const targetArrayIndex = params.targetIndex;
    setProjectColumns(generateOrderedColumn(projectColumns, uniqueColumnId, oldArrayIndex, targetArrayIndex));
  };

  return (
    <>
      {!isNull(cfMappedClosedProject) && cfMappedClosedProject.length > 0 && idName === 'reportsPageClosedProjects' && (
        <CustomiseDrawer
          key={1}
          idName={idName}
          configType="project"
          setDrawerState={setDrawerState}
          drawerState={drawerState}
          sampleData={cfMappedClosedProject[0]}
          formActionType={formActionType}
          setFormActionType={setFormActionType}
          graphData={graphData}
          data={cfMappedClosedProject}
        />
      )}
      {!isNull(cfMappedAllProjects) && cfMappedAllProjects.length > 0 && idName === 'reportsPageAllProjects' && (
        <CustomiseDrawer
          key={2}
          idName={idName}
          configType="project"
          setDrawerState={setDrawerState}
          drawerState={drawerState}
          sampleData={cfMappedAllProjects[0]}
          formActionType={formActionType}
          setFormActionType={setFormActionType}
          graphData={graphData}
          data={cfMappedAllProjects}
        />
      )}
      {!isNull(cfMappedOpenProjects) && cfMappedOpenProjects.length > 0 && idName === 'reportsPageOpenProjects' && (
        <CustomiseDrawer
          key={3}
          idName={idName}
          configType="project"
          setDrawerState={setDrawerState}
          drawerState={drawerState}
          sampleData={cfMappedOpenProjects[0]}
          formActionType={formActionType}
          setFormActionType={setFormActionType}
          graphData={graphData}
          data={cfMappedOpenProjects}
        />
      )}
      <Box sx={{ display: 'grid', gridTemplateRows: '70px auto' }}>
        <ReportsHeader pageName={ReportsPages.Projects} setDrawerState={setDrawerState} />
        <Box sx={{ display: 'grid', m: '0px 20px', gridRowGap: '20px' }}>
          {getTabSwitcher(selectedTab)}

          {/* <Box sx={{ display: 'flex', alignItems: 'center', justifySelf: 'flex-end' }}>
          </Box> */}

          {allFilters['reportsProjectModulePage']?.appliedFilters.filter((item: any) => item.isSelected).length > 0 && (
            <Box>
              <SelectedFilterList idName="reportsProjectModulePage" isResetEnabled={true} />
            </Box>
          )}
          {selectedTab === 0 &&
            (customFieldStatus ||
            (openProjectsState === requestState.success &&
              closedProjectsState === requestState.success &&
              cfMappedAllProjects.length > 0) ? (
              <>
                {/* {(graphs === undefined || graphs.length === 0) && (
                  <Container>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <NoRecords />
                      <p>
                        <span style={{ color: '#2D60F6', cursor: 'pointer' }} onClick={() => setDrawerState(true)}>
                          Choose
                        </span>{' '}
                        a data set to visualize
                      </p>
                    </div>
                  </Container>
                )} */}
                {graphs !== undefined && graphs.length > 0 && (
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <ChartView
                      key={4}
                      idName={'reportsPageAllProjects'}
                      data={filteredProjects}
                      numberOfcharts={2}
                      setGraphData={setGraphData}
                      setDrawerState={setDrawerState}
                      setFormActionType={setFormActionType}
                      configType="project"
                    />
                  </Box>
                )}

                {projectColumns.length > 0 ? (
                  <StyledBox>
                    <StripedDataGrid
                      initialState={initialStateAllProjects}
                      onStateChange={(tblState: any) => {
                        myTableStateForAllProjectsTab = tblState;
                      }}
                      onColumnVisibilityModelChange={() => {
                        handleTableSave('projectReportAllProjPage', myTableStateForAllProjectsTab);
                      }}
                      onFilterModelChange={() => {
                        // setFilterAllModel(newFilterModel);
                        handleTableSave('projectReportAllProjPage', myTableStateForAllProjectsTab);
                      }}
                      onPageSizeChange={() => {
                        handleTableSave('projectReportAllProjPage', myTableStateForAllProjectsTab);
                      }}
                      rows={displayDataWithCustomNames(filteredProjects)}
                      columns={projectColumns}
                      loading={false}
                      // checkboxSelection
                      getRowId={(row) => row['project_uid']}
                      components={{
                        Toolbar: GridToolbar,
                      }}
                      componentsProps={{
                        toolbar: { showQuickFilter: true },
                      }}
                      disableSelectionOnClick
                      rowThreshold={0}
                      // pageSize={allProjectsPageSize}
                      // onPageSizeChange={(newPageSize) => setAllProjectsPageSize(newPageSize)}
                      rowsPerPageOptions={[5, 10, 20, 50, 100]}
                      pagination
                      editMode="cell"
                      getRowHeight={() => 'auto'}
                      sx={{
                        '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
                        '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '7px' },
                        '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
                      }}
                      experimentalFeatures={{ newEditingApi: true }}
                      getRowClassName={(params: any) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'odd' : 'odd')}
                      onColumnOrderChange={handleColumnOrderChange}
                      // filterModel={filterAllModel}
                      // onFilterModelChange={(newFilterModel) => setFilterAllModel(newFilterModel)}
                    />
                  </StyledBox>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <div>
                <CognisaasLoader />
              </div>
            ))}
          {selectedTab === 1 &&
            (customFieldStatus || openProjectsState === requestState.success ? (
              <>
                {/* {(graphs === undefined || graphs.length === 0) && (
                  <Container>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <NoRecords />
                      <p>
                        <span style={{ color: '#2D60F6', cursor: 'pointer' }} onClick={() => setDrawerState(true)}>
                          Choose
                        </span>{' '}
                        a data set to visualize
                      </p>
                    </div>
                  </Container>
                )} */}
                {graphs !== undefined && graphs.length > 0 && (
                  <Box>
                    <ChartView
                      key={5}
                      idName={'reportsPageOpenProjects'}
                      data={filteredProjects}
                      numberOfcharts={2}
                      setGraphData={setGraphData}
                      setDrawerState={setDrawerState}
                      setFormActionType={setFormActionType}
                      configType="project"
                    />
                  </Box>
                )}

                {projectColumns.length > 0 ? (
                  <StyledBox>
                    <StripedDataGrid
                      initialState={initialStateOpenProjects}
                      onStateChange={(tblState: any) => {
                        myTableStateForOpenProjectsTab = tblState;
                      }}
                      onColumnVisibilityModelChange={() => {
                        handleTableSave('projectReportOpenProjPage', myTableStateForOpenProjectsTab);
                      }}
                      onFilterModelChange={() => {
                        handleTableSave('projectReportOpenProjPage', myTableStateForOpenProjectsTab);
                        // setFilterAllModel(newFilterModel);
                      }}
                      onPageSizeChange={() => {
                        handleTableSave('projectReportOpenProjPage', myTableStateForOpenProjectsTab);
                      }}
                      rows={displayDataWithCustomNames(filteredProjects)}
                      columns={projectColumns}
                      loading={false}
                      components={{
                        Toolbar: GridToolbar,
                      }}
                      componentsProps={{
                        toolbar: { showQuickFilter: true },
                      }}
                      // checkboxSelection
                      getRowId={(row) => row['project_uid']}
                      disableSelectionOnClick
                      rowThreshold={0}
                      // pageSize={openProjectsPageSize}
                      // onPageSizeChange={(newPageSize) => setOpenProjectsPageSize(newPageSize)}
                      rowsPerPageOptions={[5, 10, 20, 50, 100]}
                      pagination
                      editMode="cell"
                      getRowHeight={() => 'auto'}
                      sx={{
                        '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
                        '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '7px' },
                        '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
                      }}
                      experimentalFeatures={{ newEditingApi: true }}
                      getRowClassName={(params: any) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'odd' : 'odd')}
                      // filterModel={filterAllModel}
                      onColumnOrderChange={handleColumnOrderChange}
                    />
                  </StyledBox>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <div>
                <CognisaasLoader />
              </div>
            ))}
          {selectedTab === 2 &&
            (customFieldStatus || closedProjectsState === requestState.success ? (
              <>
                {/* {(graphs === undefined || graphs.length === 0) && (
                  <Container>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <NoRecords />
                      <p>
                        <span style={{ color: '#2D60F6', cursor: 'pointer' }} onClick={() => setDrawerState(true)}>
                          Choose
                        </span>{' '}
                        a data set to visualize
                      </p>
                    </div>
                  </Container>
                )} */}
                {graphs !== undefined && graphs.length > 0 && (
                  <Box>
                    <ChartView
                      key={6}
                      idName={'reportsPageClosedProjects'}
                      data={filteredProjects}
                      numberOfcharts={2}
                      setGraphData={setGraphData}
                      setDrawerState={setDrawerState}
                      setFormActionType={setFormActionType}
                      configType="project"
                    />
                  </Box>
                )}

                {projectColumns.length > 0 ? (
                  <StyledBox>
                    <StripedDataGrid
                      initialState={initialStateClosedProjects}
                      onStateChange={(tblState: any) => {
                        myTableStateForClosedProjectsTab = tblState;
                      }}
                      onColumnVisibilityModelChange={() => {
                        handleTableSave('projectReportClosedProjPage', myTableStateForClosedProjectsTab);
                      }}
                      onFilterModelChange={() => {
                        // setFilterAllModel(newFilterModel);
                        handleTableSave('projectReportClosedProjPage', myTableStateForClosedProjectsTab);
                      }}
                      onPageSizeChange={() => {
                        handleTableSave('projectReportClosedProjPage', myTableStateForClosedProjectsTab);
                      }}
                      rows={displayDataWithCustomNames(filteredProjects)}
                      columns={projectColumns}
                      loading={false}
                      // checkboxSelection
                      getRowId={(row) => row['project_uid']}
                      disableSelectionOnClick
                      rowThreshold={0}
                      // pageSize={closedProjectsPageSize}
                      components={{
                        Toolbar: GridToolbar,
                      }}
                      componentsProps={{
                        toolbar: { showQuickFilter: true },
                      }}
                      // onPageSizeChange={(newPageSize) => setClosedProjectsPageSize(newPageSize)}
                      rowsPerPageOptions={[5, 10, 20, 50, 100]}
                      pagination
                      editMode="cell"
                      getRowHeight={() => 'auto'}
                      sx={{
                        '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
                        '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '7px' },
                        '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
                      }}
                      experimentalFeatures={{ newEditingApi: true }}
                      getRowClassName={(params: any) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'odd' : 'odd')}
                      // filterModel={filterAllModel}
                      // onFilterModelChange={(newFilterModel) => setFilterAllModel(newFilterModel)}
                      onColumnOrderChange={handleColumnOrderChange}
                    />
                  </StyledBox>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <div>
                <CognisaasLoader />
              </div>
            ))}
        </Box>
      </Box>
    </>
  );
};

export default Projects;
