import React, { useState, useEffect, useMemo } from 'react';
import { getAllRoles_slice, getAllUsersAndRoles_slice, resetForUpdatingUser } from '../../../store/modules/RBAC/slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getUserAndRoles_selector, selectUpdatingUser } from 'store/modules/RBAC/selector';
import CognisaasLoader from '../../shared/CognisaasLoader/CognisaasLoader';
import {
  DataGridPremium,
  GridRenderCellParams,
  GridRowParams,
  GridSelectionModel,
  GridToolbar,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import { styled } from '@mui/material/styles';
import { addUser_slice } from '../../../store/modules/RBAC/slice';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Stack,
  TextField,
  Typography,
  useFormControl,
} from '@mui/material';
import Cookies from 'universal-cookie';
import CognisaasButton from '../../shared/CognisaasButton/CognisaasButton';
import { requestState } from 'store/modules/types';
import UserDetail from './UsersDetail';
interface helperText_payload {
  text: string;
  textToCheck: string;
}

import { UserInfo } from 'components/RBACv2/shared/styles';
import { isRbacEnabled } from 'utils/getUid';
import ViewDate from 'components/shared/ViewDate/ViewDate';
import PersistentDrawerLeft from './PersistentDrawer';
import { resetSendEmailToUsersWithCustomMessage } from 'store/modules/Settings/slice';
import useCommonAppConfig from 'components/Common/useCommonAppConfig';
import CognisaasDropdown from '../../shared/CognisaasDropdown/CognisaasDropdown';

const cookies = new Cookies();

const Users = () => {
  const dispatch = useAppDispatch();
  const userAndRoleData_fromSelector = useAppSelector(getUserAndRoles_selector).data;
  const userAndRoleData_fromSelectorStatus = useAppSelector(getUserAndRoles_selector).status;
  const [users, setUsers] = useState<any[]>([]);
  const [userDataRenderReady, setUserDataRenderReady] = useState(false);
  // const [pageSize, setPageSize] = React.useState<number>(10);
  const [email, setEmail] = useState('');
  const [userName, setUserName] = useState('');
  const [notifyUser, setNotifyUser] = useState(true);
  const [createUserSelected, setCreateUserSelected] = useState(false);
  const [drawerState, setDrawerState] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<any>({});
  const [roles, setRoles] = useState<any>([]);
  const [selectedRole, setSelectedRole] = useState('Select Role');

  const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>([]);

  const updatingUser = useAppSelector(selectUpdatingUser);

  let myTableState: any = {};
  const { saveAppTableState, getTableState } = useCommonAppConfig();
  const initialState: any = {
    columns: {},

    pagination: {
      page: 0,
      pageSize: 10,
    },
    ...getTableState('settingsUserPage'),
  };

  const handleTableSave = (idName: string, thisTableState: any) => {
    saveAppTableState(idName, thisTableState);
  };

  useEffect(() => {
    if (cookies.get('rbac_enabled')) {
      const fetchData = async () => {
        const data = await dispatch(getAllRoles_slice()).unwrap();
        if (data?.length > 0) {
          setRoles([...data]);
          setSelectedRole(data[0]['name']);
        }
      };
      fetchData();
    }
    if (updatingUser.status === requestState.success) {
      dispatch(resetForUpdatingUser());
    }
  }, [updatingUser]);

  function formatUsers(unFormattedUsers: any[]) {
    const formattedUsers: any[] = [];
    unFormattedUsers.forEach((unFormattedUser) => {
      //We are filtering out client users
      if (unFormattedUser['account']['user_type'] !== 'C') {
        formattedUsers.push({
          id: unFormattedUser['id'],
          name: unFormattedUser['user_name'],
          email: unFormattedUser['email'],
          isActive: unFormattedUser['active'],
          userType: unFormattedUser['account']['user_type'],
          role: unFormattedUser['role'],
          roleName: unFormattedUser['role']?.name || '',
          account: unFormattedUser['account'],
          last_access_time: unFormattedUser['last_access_time'],
        });
      }
    });
    return formattedUsers;
  }

  const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .ant-empty-img-1': {
      fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
      fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
      fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
      fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
      fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
      fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <svg width="120" height="100" viewBox="0 0 184 152" aria-hidden focusable="false">
          <g fill="none" fillRule="evenodd">
            <g transform="translate(24 31.67)">
              <ellipse className="ant-empty-img-5" cx="67.797" cy="106.89" rx="67.797" ry="12.668" />
              <path
                className="ant-empty-img-1"
                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
              />
              <path
                className="ant-empty-img-2"
                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
              />
              <path
                className="ant-empty-img-3"
                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
              />
            </g>
            <path
              className="ant-empty-img-3"
              d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
            />
            <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
              <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
              <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
            </g>
          </g>
        </svg>
        <Box sx={{ mt: 1 }}>No Rows</Box>
      </StyledGridOverlay>
    );
  }

  const StyledBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '500px',
    boxShadow: '0px 4px 26px rgba(0, 0, 0, 0.05)',
    '& .MuiFormGroup-options': {
      alignItems: 'center',
      paddingBottom: theme.spacing(1),
      '& > div': {
        minWidth: 100,
        margin: theme.spacing(2),
        marginLeft: 0,
      },
    },
  }));

  useEffect(() => {
    dispatch(getAllUsersAndRoles_slice());
    dispatch(resetSendEmailToUsersWithCustomMessage());
  }, []);

  useEffect(() => {
    if (userAndRoleData_fromSelectorStatus == 'success') {
      const formattedUsers = formatUsers(userAndRoleData_fromSelector);
      setUsers(formattedUsers);
      setUserDataRenderReady(true);
    } else {
      setUserDataRenderReady(false);
    }
  }, [userAndRoleData_fromSelector, userAndRoleData_fromSelectorStatus]);

  const handleUserCreateButtonClick = () => {
    setCreateUserSelected(true);
  };

  const handleUserCreateCancelButtonClick = () => {
    setUserName('');
    setEmail('');
    setCreateUserSelected(false);
  };

  const apiRef = useGridApiRef();

  const onClickCreate = async () => {
    const roleObject = roles.find((role: { name: string }) => role.name === selectedRole);
    await dispatch(
      addUser_slice({
        firstname: '',
        lastname: '',
        email: email || '',
        role: roleObject?.id || '',
        team: '',
        invited: notifyUser || true,
        username: userName || '',
      })
    );
    await dispatch(getAllUsersAndRoles_slice());
    setCreateUserSelected(false);
    setUserName('');
    setEmail('');
  };

  const columns = [
    {
      field: 'id',
      hide: true,
      flex: 1,
    },
    {
      field: 'name',
      headerName: 'User Name',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Stack direction="row">
            <Typography
              variant="subtitle1"
              color={'#093bcd'}
              style={{ cursor: 'pointer', marginRight: '5px' }}
              onClick={() => {
                setSelectedUser(params.row);
                setDrawerState(true);
              }}
            >
              {params.value}
            </Typography>
            {params.row?.account?.user_type === 'S' && <UserInfo>Super User</UserInfo>}
          </Stack>
        );
      },
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'roleName',
      headerName: 'Role',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <Typography variant="subtitle1">{params.value}</Typography>,
    },
    {
      field: 'last_access_time',
      headerName: 'Last Used',
      flex: 1,
      minWidth: 100,
      renderCell: (params: GridRenderCellParams) => (
        <Typography variant="subtitle1" sx={{ fontWeight: 500, fontSize: '12px' }}>
          {params?.row?.last_access_time && <ViewDate date={params.row.last_access_time} />}
        </Typography>
      ),
    },
    {
      field: 'isActive',
      headerName: 'Status',
      flex: 1,
      minWidth: 100,
      valueFormatter: ({ value }: any) => (value ? 'Active' : 'Inactive'),
      renderCell: (params: GridRenderCellParams) =>
        params.row.isActive ? (
          <Typography color={'#4F9F52'} variant="subtitle1">
            Active
          </Typography>
        ) : (
          <Typography color={'#E75B5C'} variant="subtitle1">
            Inactive
          </Typography>
        ),
    },
  ];
  const filteredColumns = columns.filter((column: any) =>
    !isRbacEnabled() && column.field === 'roleName' ? false : true
  );
  const data = {
    rows: [...users],
    columns: [...filteredColumns],
  };

  const MyFormHelperText = (props: helperText_payload) => {
    const { focused } = useFormControl() || {};

    const helperText = useMemo(() => {
      if (focused) {
        return null;
      } else {
        return props.textToCheck == '' ? props.text : null;
      }
    }, [focused]);

    return <FormHelperText>{helperText}</FormHelperText>;
  };

  return userDataRenderReady ? (
    <>
      <Box margin={'20px 20px 0 20px'}>
        <Box component="span" display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant={'h2'}>Users</Typography>
          {!createUserSelected ? (
            <CognisaasButton
              label="Add User"
              isOutlined={false}
              clickHandler={handleUserCreateButtonClick}
            ></CognisaasButton>
          ) : (
            ''
          )}
        </Box>
        <Typography marginBottom={'20px'} variant="subtitle1" sx={{ color: '#7A7A7A' }}>
          Manage your account users here
        </Typography>
        {createUserSelected ? (
          <Stack sx={{ margin: '10px 0px' }}>
            <Typography variant="h1">Create New User</Typography>
            <Stack
              sx={{
                marginTop: '16px',
                position: 'relative',
                minHeight: '300px',
                width: '100% !important',
                backgroundColor: 'white',
                borderRadius: '5px',
              }}
            >
              <Stack>
                <Typography variant="h2" fontSize="20px">
                  Basic Information
                </Typography>
                <Grid sx={{ minHeight: '95px', height: 'auto', marginTop: '15px' }}>
                  <FormControl sx={{ marginRight: '40px' }}>
                    <Typography variant="h3" marginBottom="4px">
                      Username
                    </Typography>
                    <TextField
                      size="small"
                      value={userName}
                      onChange={(e: any) => {
                        setUserName(e.target.value);
                      }}
                      placeholder="Enter Username"
                    />
                    <MyFormHelperText textToCheck={email} text="Fill the Username" />
                  </FormControl>
                  <FormControl sx={{ marginRight: '40px' }}>
                    <Typography variant="h3" marginBottom="4px">
                      Email
                    </Typography>
                    <TextField
                      size="small"
                      value={email}
                      onChange={(e: any) => {
                        setEmail(e.target.value);
                      }}
                      placeholder="Enter Email"
                    />
                    <MyFormHelperText textToCheck={email} text="Fill the Email" />
                  </FormControl>
                  {cookies.get('rbac_enabled') ? (
                    cookies.get('rbac_enabled') === 'true' ? (
                      <FormControl>
                        <Typography variant="h3" marginBottom="4px">
                          Role
                        </Typography>
                        <CognisaasDropdown
                          name="role-dropdown"
                          type="dropdown"
                          validvalues={roles.map(function (role: { [x: string]: any }) {
                            return role['name'];
                          })}
                          onChange={(e: any) => {
                            setSelectedRole(e.target.value);
                          }}
                          value={selectedRole}
                        />
                        <MyFormHelperText text="Select Role" textToCheck={email} />
                      </FormControl>
                    ) : (
                      ''
                    )
                  ) : (
                    ''
                  )}
                </Grid>
              </Stack>

              <Stack sx={{ marginTop: '16px' }}>
                <Typography variant="h2" fontSize="20px">
                  Invite User
                </Typography>
                <FormControlLabel
                  sx={{ marginTop: '20px', marginLeft: '0px' }}
                  control={
                    <Checkbox
                      size="small"
                      checked={notifyUser}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setNotifyUser(event.target.checked);
                      }}
                    />
                  }
                  label={
                    <Typography marginLeft="13px" variant="h3">
                      Send invite email to user{' '}
                    </Typography>
                  }
                />
              </Stack>
              <Stack direction={'row'} sx={{ marginTop: '24px' }}>
                <CognisaasButton
                  isOutlined={true}
                  label="Cancel"
                  clickHandler={() => handleUserCreateCancelButtonClick()}
                />
                <Button onClick={() => onClickCreate()} sx={{ marginLeft: '25px' }} variant="contained">
                  Create
                </Button>
              </Stack>
            </Stack>
          </Stack>
        ) : (
          <>
            <StyledBox>
              <DataGridPremium
                initialState={initialState}
                onStateChange={(tblState: any) => {
                  myTableState = tblState;
                }}
                onColumnVisibilityModelChange={() => {
                  handleTableSave('settingsUserPage', myTableState);
                }}
                onFilterModelChange={() => {
                  handleTableSave('settingsUserPage', myTableState);
                }}
                onPageSizeChange={() => {
                  handleTableSave('settingsUserPage', myTableState);
                }}
                components={{
                  NoRowsOverlay: CustomNoRowsOverlay,
                  Toolbar: GridToolbar,
                }}
                componentsProps={{
                  toolbar: { showQuickFilter: true },
                }}
                {...data}
                getRowId={(row) => row.id}
                apiRef={apiRef}
                // pageSize={pageSize}
                rowsPerPageOptions={[10]}
                // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                disableSelectionOnClick
                checkboxSelection={true}
                isRowSelectable={(params: GridRowParams) => {
                  if (params.row.userType == 'S') return false;
                  return params.row.isActive == true;
                }}
                onSelectionModelChange={(newSelectionModel) => {
                  setSelectionModel(newSelectionModel);
                }}
                selectionModel={selectionModel}
              />
            </StyledBox>
            <PersistentDrawerLeft selectionModel={selectionModel} setSelectionModel={setSelectionModel} />
          </>
        )}
        {/* alerts used */}
      </Box>
      {drawerState && (
        <UserDetail
          drawerState={drawerState}
          onDrawerClose={() => {
            setDrawerState(false);
            dispatch(getAllUsersAndRoles_slice());
          }}
          selectedUser={selectedUser}
          setUsers={setUsers}
        />
      )}
    </>
  ) : (
    <CognisaasLoader />
  );
};
export default Users;
