import { Box, IconButton, Typography,  } from '@mui/material';
import Styles from '../../shared/Attachment/AttachmentFileView/FullWidthAttachmentViewer.module.css';
import CognisaaasTooltip from '../../shared/CognisaasTooltip/CognisaasTooltip';
import { ReactComponent as DeleteIcon } from '../../../assets/svgs/delete.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/svgs/downloadAttach.svg';
import FileIcon from '../../shared/Attachment/FileIcon';

interface Props {
  fileName: string;
  attachmentURL: string;
  handleDeleteAttachment: () => void;
}
const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

const DocumentAttachmentViewer = (props: Props) => {

  // const deleteAttachmentState = useAppSelector(selectDeleteAttachment);


  const implementDownload = (name: string) => {
    const link = document.createElement('a');
    link.download = name;
    link.href = props.attachmentURL;
    link.click();
  };


  return (
        <Box className={Styles.NormalAttachment__fileViewer} style={{marginTop:'10px'}}>
        <Box className={Styles.NormalAttachment__uploadedFileDetails}>
            {/* show the uploaded file */}
            <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Box component="span" sx={{ marginRight: '16px' }}>
                {/* show file icon here */}
                <FileIcon fileType={props.fileName.split('.')[1]} />
            </Box>

            <Box component="span" className={Styles.NormalAttachment__uploadedFileDetails_text}>
                {/* show file data like attachment name and date */}
                {/* file name */}
                <CognisaaasTooltip  
                title={
                    <span style={{padding:'10px'}}>{props.fileName}</span>
                } >
                <Typography variant="subtitle1" className={Styles.NormalAttachment__fileName} >
                    {props.fileName.length > 13 ? <>{props.fileName.slice(0, 13) + '...'}</> : props.fileName}
                </Typography>
                </CognisaaasTooltip>
                <Typography variant="subtitle2" className={Styles.NormalAttachment__fileDate}>
                {(() => {
                    const date = new Date();
                    return `${date.getDate()} ${monthNames[date.getMonth()]} ${date.getFullYear()}`;
                })()}
                </Typography>
            </Box>
            </span>

            <span>
            <span style={{ marginRight: '40px' }}>
                {/* download attachment button  */}
                <IconButton onClick={() => implementDownload(props.fileName)}>
                <DownloadIcon />
                </IconButton>
            </span>

            <Box component="span" style={{marginRight:'20px'}}>
                {/* delete attachment button */}
                <IconButton onClick={() => props.handleDeleteAttachment()}>
                <DeleteIcon />
                </IconButton>
            </Box>
            </span>
        </Box>
        </Box>
  );
};

export default DocumentAttachmentViewer;
