import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { usecaseAPI } from '../../../../api/modules/usecase';
import { requestState } from '../../types';

interface openUsecasesType {
  openUsecases: Array<any>;
  status: requestState.idle | requestState.loading | requestState.success | requestState.failed;
  error: null | undefined | string;
}

const initialState: openUsecasesType = {
  openUsecases: [],
  status: requestState.idle,
  error: null,
} as openUsecasesType;

export const fetchOpenUsecases = createAsyncThunk('usecase/fetchOpenUsecases', async () => {
  const { data } = await usecaseAPI.getOpenUsecases();

  return data.result ?? [];
});

export const updateUsecase = createAsyncThunk('usecase/updateUsecase', async (usecase: any) => {
  const { data } = await usecaseAPI.updateUsecase(usecase);
  return data.result ?? [];
});

const openUsecaseSlice = createSlice({
  name: 'openUsecase',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchOpenUsecases.pending, (state) => {
        state.status = requestState.loading;
      })
      .addCase(fetchOpenUsecases.fulfilled, (state, action) => {
        state.status = requestState.success;
        state.openUsecases = action.payload;
      })
      .addCase(fetchOpenUsecases.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      });
  },
});

export const getAllOpenUsecases = (state: openUsecasesType) => state.openUsecases;

const openUsecasesReducer = openUsecaseSlice.reducer;
export default openUsecasesReducer;
