import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { Component } from 'components/ResourceManagement/types/public-types';
import {
  selectComponentState,
  selectMainViewData,
  selectResourceId,
  selectResourceManagementState,
  setDataForResourceView,
} from 'store/modules/ResourceManagement';
import { getUserLetters, transformDataForResourceView } from 'components/ResourceManagement/utils/transformers';

export const useResourceView = () => {
  const mode = 'resource-view';

  const dispatch = useAppDispatch();
  const selectedComponentState = useAppSelector(selectComponentState);
  const { viewMode } = selectedComponentState;
  const selectedResourceId = useAppSelector(selectResourceId);
  const selectedMainViewData = useAppSelector(selectMainViewData);
  const resourceManagementState = useAppSelector(selectResourceManagementState);
  const {
    members: { data, isError, message },
  } = resourceManagementState;

  const [resourcePrimeComponents, setResourcePrimeComponents] = useState<any[]>([]);

  useEffect(() => {
    if (data !== undefined && data.length) {
      const resourceViewData = transformDataForResourceView(data, selectedResourceId);
      const resources = resourceViewData.resources;
      const events = resourceViewData.events;
      setResourcePrimeComponents([...resources, ...events]);
      dispatch(
        setDataForResourceView({
          resources,
          events,
        })
      );
    }
  }, [data, isError, message, selectedResourceId]);

  const handleSelect = () => {
    // console.log(component.name + ' has ' + (isSelected ? 'selected' : 'unselected'));
  };

  const handleExpanderClick = (component: Component) => {
    const expandedComponents = resourcePrimeComponents.map((t) => (t.id === component.id ? component : t));
    // console.log('On expander click Id:' + component.id);
    setResourcePrimeComponents(expandedComponents);
  };

  // console.log({primeComponents});

  let resourceNameToDisplay = '';
  let resourceNameTile = '';

  const { resources: members } = selectedMainViewData;

  if (members !== undefined && members.length && selectedResourceId !== undefined) {
    const selectedResource = members.find((item: any) => item.id === selectedResourceId);
    resourceNameToDisplay = selectedResource?.name ?? '';
    resourceNameTile = selectedResource?.name !== undefined ? getUserLetters(selectedResource.name) : 'N/A';
    // console.log({ selectedResource, resourcePrimeComponents });
  }

  const { events: mainViewEventsForDateRange } = selectedMainViewData;

  return {
    viewMode,
    primeComponents: resourcePrimeComponents,
    resourceNameToDisplay,
    resourceNameTile,
    mode,
    mainViewEventsForDateRange,
    handleSelect,
    handleExpanderClick,
  };
};
