import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getAttachmentsFromServer, resetAttachmentDownloadData } from '../../../store/modules/Attachment/slice';
import { selectAttachmentDataDownloadQueue } from '../../../store/modules/Attachment/selector';
import { useAppSelector } from '../../../store/hooks';
import AttachmentViewer from '../../shared/Attachment/AttachmentFileView/AttachmentViewer';
import { Divider, Typography, Button } from '@mui/material';
import { ReactComponent as NoRecordsIcon } from '../../../assets/svgs/no_records_mini.svg';
import Styles from './RequirementTabs.module.css';
import BasicModal from '../../shared/CognisaasModal/CognisaasModal';
import Attachment from '../../shared/Attachment/Attachment';
import CognisaasToast from 'components/shared/CognisaasToast/CognisaasToast';
import { selectCustomerDetail } from 'store/modules/Common/selector';

interface Props {
  data: any;
}

const __user_permissions = ['view', 'edit', 'delete'];

const RequirementsAttachmentTab = (props: Props) => {
  const dispatch = useDispatch();
  const attachmentDataQueueState = useAppSelector(selectAttachmentDataDownloadQueue);
  const [attachmentsData, setAttachmentsData] = useState<Record<string, any>[]>([]);
  // const [modalIndex, setModalIndex] = useState<number>(0);
  const [isBulUploadAttachmentOpened, setIsBulUploadAttachmentOpened] = useState(false);
  const [alertDetails, setAlertDetails] = useState<{
    isVisible: boolean;
    type: 'success' | 'failure';
    message: string;
  }>({ isVisible: false, type: 'success', message: '' });
  const customerDetail = useAppSelector(selectCustomerDetail);

  useEffect(() => {
    dispatch(getAttachmentsFromServer(`getAttachmentsByFeatureUid?feature_uid=${props.data.feature_uid}`));
  }, []);

  useEffect(() => {
    if (attachmentDataQueueState.status === 'success') {
      setAttachmentsData(attachmentDataQueueState.data);
      setTimeout(() => {
        dispatch(resetAttachmentDownloadData());
      }, 1000);
    }
  }, [attachmentDataQueueState]);

  const reloadAttachments = () => {
    dispatch(getAttachmentsFromServer(`getAttachmentsByFeatureUid?feature_uid=${props.data.feature_uid}`));
  };

  console.log(attachmentsData.length < 1);
  const attachmentCallback = (actionPerformed: string) => {
    console.log(actionPerformed);

    if (actionPerformed === 'uploaded-bulk-attachments') {
      // get attachments uploaded on server and save in state and later show them
      dispatch(getAttachmentsFromServer(`getAttachmentsByFeatureUid?feature_uid=${props.data.feature_uid}`));

      setTimeout(() => {
        // dispatch(resetAttachmentDownloadData());
        getAttachmentsFromServer(`getAttachmentsByFeatureUid?feature_uid=${props.data.feature_uid}`);
      }, 3000);
    }

    // if (actionPerformed === 'canceled-bulk-attachments') {
    // }
    setIsBulUploadAttachmentOpened(false);
  };

  return (
    <>
      <CognisaasToast
        open={alertDetails.isVisible}
        onClose={() => setAlertDetails({ isVisible: false, type: alertDetails.type, message: '' })}
        message={alertDetails.message}
        type={alertDetails.type}
      />
      <div>
        {attachmentsData.map((oneAttachment, index) => {
          return (
            <>
              <AttachmentViewer
                key={index}
                fileName={oneAttachment.name}
                attachmentUid={oneAttachment.attachment_uid}
                serverGetApiUrlWithQueryString={`downloadFeatureAttachment?attachment_uid=${oneAttachment.attachment_uid}`}
                deleteApiUrl={'deleteFeatureAttachment'}
                deleteAttachmentPayload={{
                  attachment_uid: oneAttachment.attachment_uid,
                  customer_uid: props.data.customer_uid,
                  featureUid: props.data.feature_uid,
                  feature_issue_id: props.data.feature_issue_id,
                  key: btoa(btoa(`${customerDetail.customer_jira_user}:${customerDetail.customer_jira_token}`)),
                }}
                deleteCallbackFunc={reloadAttachments}
                attachmentData={oneAttachment}
                userPermissions={__user_permissions}
              />
              {/* <BasicModal onClose={() => setModalIndex(0)} open={modalIndex === 1} height={280} width={350}>
                {props.data.feature_uid !== '' ? (
                  <Attachment
                    variant="bulk-upload"
                    type="normal"
                    metaDataForHeaders={{
                      category: props.data.case_uid !== null ? 'usecase' : 'project',
                      taskUid: props.data.task_uid,
                      level_uid: props.data.task_uid,
                      level_name: 'task',
                      project_uid: props.data.project_uid,
                      // client_uid: props.clientDetail.client_uid,
                      case_uid: props.data.case_uid,
                      customer_uid: cookies.get('cogni_customer_uid'),
                      current_version_uploaded_by_user_uid: cookies.get('cogni_user_uid'),
                      current_version_uploaded_by_user_name: cookies.get('user_name'),
                      key: attachmentKeyGenerator(
                        cookies.get('cogni_customer_uid') || 'task-document',
                        props.data.task_uid
                      ),
                    }}
                    apiUrlForAttachmentUpload={'updateAttachment'}
                    callbackOnWhatIsDoneWithAttachments={(actionPerformed: any) => attachmentCallback(actionPerformed)}
                  />
                ) : (
                  ''
                )}
              </BasicModal>
              <BasicModal onClose={() => setModalIndex(0)} open={modalIndex === 2} width={1000} height={400}>
                <VersionTable attachmentDetail={attachmentDetail} />
              </BasicModal> */}
              <Divider />
            </>
          );
        })}

        {attachmentsData.length < 1 ? (
          <div className={Styles.RequirementAttachmentTab__noRecordsContainer}>
            <div className={Styles.RequirementAttachmentTab__noRecordsWrapper}>
              <Typography sx={{ marginBottom: '20px' }} variant="subtitle1" className="label-text">
                No Attachments Found
              </Typography>

              <NoRecordsIcon />
              <Button onClick={() => setIsBulUploadAttachmentOpened(true)}>
                <Typography variant="subtitle2">Upload Attachment</Typography>
              </Button>
            </div>
          </div>
        ) : (
          <Button onClick={() => setIsBulUploadAttachmentOpened(true)}>
            <Typography variant="subtitle2">Upload More Attachment</Typography>
          </Button>
        )}

        <BasicModal
          height={280}
          width={350}
          open={isBulUploadAttachmentOpened}
          onClose={() => setIsBulUploadAttachmentOpened(false)}
        >
          {props.data.feature_uid !== '' ? (
            <Attachment
              variant="bulk-upload"
              type="normal"
              metaDataForHeaders={{
                featureUid: props.data.feature_uid,
                product_uid: props.data.product_uid,
                feature_issue_id: props.data.feature_issue_id,
                customer_uid: props.data.customer_uid,
                key: btoa(btoa(`${customerDetail.customer_jira_user}:${customerDetail.customer_jira_token}`)),
              }}
              apiUrlForAttachmentUpload={'addFeatureAttachments'}
              callbackOnWhatIsDoneWithAttachments={(actionPerformed: any) => attachmentCallback(actionPerformed)}
              setAlertDetails={setAlertDetails}
            />
          ) : (
            ''
          )}
        </BasicModal>
      </div>
    </>
  );
};

export default RequirementsAttachmentTab;
