import { Gantt, GanttStatic } from 'dhtmlx-gantt';
import {
  filterByMilestoneTask,
  getResourceData,
  transformToGanttDependencyFormat,
  transformToGanttMileStoneTaskFormat,
  transformToGanttSectionFormat,
  transformToGanttTaskFormat,
  transformToGanttUsecaseFormat,
  includeAvailableClientUid,
} from './transformers';
import { TGanttTask } from './types';

import { useGantt } from './useGantt';
import { groupByCustomKey } from './utils';

export const useUsecaseGantt = ({ usecaseDetail }: any) => {
  const gantt: GanttStatic = Gantt.getGanttInstance();

  const gUsecase = transformToGanttUsecaseFormat(usecaseDetail);
  const listBySectionUid = groupByCustomKey(usecaseDetail.task, 'section_uid');
  const gUsecaseSections = usecaseDetail.section.map((section: any) =>
    transformToGanttSectionFormat(section, listBySectionUid)
  );
  const gUsecaseTasks = usecaseDetail.task.map(transformToGanttTaskFormat);
  const gUsecaseClientTasks = usecaseDetail.clientTask
    .map((item: TGanttTask) => includeAvailableClientUid(item, usecaseDetail.section))
    .map(transformToGanttTaskFormat);
  const allUsecaseTasks = [...usecaseDetail.task, ...usecaseDetail.clientTask];
  const gUsecaseMilestoneTasks = allUsecaseTasks.filter(filterByMilestoneTask).map(transformToGanttMileStoneTaskFormat);
  const gDependencies = usecaseDetail.dependency.map(transformToGanttDependencyFormat);
  const allData = [gUsecase, ...gUsecaseSections, ...gUsecaseTasks, ...gUsecaseClientTasks, ...gUsecaseMilestoneTasks];
  const resourceData = gUsecaseTasks.map(getResourceData);
  const customExportExcelFilename = usecaseDetail?.case_name ?? 'usecase';

  // console.log({
  //   usecaseDetail,
  //   gUsecase,
  //   gUsecaseSections,
  //   gUsecaseTasks,
  //   gUsecaseClientTasks,
  //   gUsecaseMilestoneTasks,
  //   gDependencies,
  //   allData,
  //   resourceData,
  //   customExportExcelFilename,
  // });

  const ganttDataObj = {
    gDependencies,
    allData,
    resourceData,
    customExportExcelFilename,
    ganttRootElemId: 'gantt_usecase',
    ganttStartDateBoundary: gUsecase?.start_date,
    ganttEndDateBoundary: gUsecase?.end_date,
  };

  const {
    taskData,
    isUpdateTaskDrawerOpen,
    isWorkTimeOn,
    handleClose,
    handleOnZoomIn,
    handleOnZoomOut,
    handleOnExportToExcel,
    handleOnTodayClick,
    handleOnToogleWorkTime,
    handleCriticalPathHighlight,
  } = useGantt(gantt, ganttDataObj);

  return {
    taskData,
    isUpdateTaskDrawerOpen,
    isWorkTimeOn,
    handleClose,
    handleOnZoomIn,
    handleOnZoomOut,
    handleOnExportToExcel,
    handleOnTodayClick,
    handleOnToogleWorkTime,
    handleCriticalPathHighlight,
  };
};
