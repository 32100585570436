import { Chip, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import { useState } from 'react';
import { isUndefined } from 'utils/dataHelpers';

const brandBlue = '#2D60F6';
const useStyles: any = makeStyles({
  input: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  chipRoot: {
    '&.Mui-focusVisible': {
      backgroundColor: '#cedafd',
    },
  },
});

interface Props {
  name: string;
  value: any;
  handleChange: (newVal: number[]) => void;
  handleDelete: () => void;
}

const NumberRangeChip = (props: Props) => {
  const [values, setValues] = useState<any[]>(() => {
    if (props.value.length > 0) {
      const first =
        props.value[0].split('$N$')[0] === 'undefined' ? undefined : parseInt(props.value[0].split('$N$')[0]);
      const last =
        props.value[0].split('$N$')[1] === 'undefined' ? undefined : parseInt(props.value[0].split('$N$')[1]);
      return [first, last];
    } else {
      return [undefined, undefined];
    }
  });

  const classes = useStyles();
  return (
    <Chip
      classes={{ root: classes.chipRoot }}
      sx={{ background: '#cedafd' }}
      label={
        <Box
          sx={{
            background: '#cedafd',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          <Typography variant="h3" sx={{ color: brandBlue }}>
            {props.name}
          </Typography>
          <TextField
            value={values[0]}
            inputProps={{
              style: {
                padding: '0px',
              },
            }}
            className={classes.input}
            variant="standard"
            type={'number'}
            onKeyPress={(event) => {
              if (event?.key === '-') {
                event.preventDefault();
              }
            }}
            placeholder="min"
            size="small"
            sx={{ margin: '0px 10px', width: '10ch' }}
            onChange={(e: any) => {
              if (e.target.value < 0) return;
              if (values[0] <= values[1] || isUndefined(values[1])) {
                props.handleChange([e.target.value, values[1]]);
                console.log('true false check hehe');
              }
              console.log('true false check haha');
              setValues([e.target.value, values[1]]);
            }}
            error={values[0] > values[1]}
          />
          To
          <TextField
            value={values[1]}
            inputProps={{
              style: {
                padding: '0px',
              },
            }}
            className={classes.input}
            variant="standard"
            type={'number'}
            placeholder="max"
            size="small"
            sx={{ margin: '0px 10px', width: '10ch' }}
            onKeyPress={(event) => {
              if (event?.key === '-') {
                event.preventDefault();
              }
            }}
            onChange={(e: any) => {
              if (e.target.value < 0 || e.target.value == undefined) return;
              if (values[0] <= Number(e.target.value)) {
                props.handleChange([values[0], e.target.value]);
              }
              setValues([values[0], e.target.value]);
            }}
            error={values[0] > values[1]}
          />
        </Box>
      }
      onDelete={props.handleDelete}
    />
  );
};

export default NumberRangeChip;
