import { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CognisaasTabs from '../../shared/CognisaasTabs';
import Task from './MicroComponents/Task';
import Usecase from './MicroComponents/Usecase';
import Project from './MicroComponents/Project';
import Client from './MicroComponents/Client';
import { useDispatch } from 'react-redux';
import { fetchJourneyStagesForClientConfigurations } from '../../../store/modules/Settings/slice';
import { label_client, label_project, label_task, label_use_case } from '../Preferences/CustomLabelCookieHelper';

// interface TabPanelProps {
//     children?: React.ReactNode;
//     index: number;
//     value: number;
// }

// const Project = () => {
//     return (
//         <Grid>
//             <Grid>
//                 projects
//             </Grid>
//             <Grid>

//             </Grid>
//         </Grid>
//     );
// };

// const Client = () => {
//     return (
//         <Grid>Client</Grid>
//     );
// };

// const Usecase = () => {
//     return (
//         <Grid>Usecase</Grid>
//     );
// };

// const Task = () => {
//     return (
//         <Grid>Task</Grid>
//     );
// };

const Configuration = () => {
  const [tabValue, setTabValue] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchJourneyStagesForClientConfigurations());
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };
  const tabs = [label_client, label_project, label_use_case, label_task];

  return (
    <Box sx={{ width: '100%', paddingTop: '15px' }}>
      <Box sx={{ marginLeft: '20px' }}>
        <CognisaasTabs selectedTabIndex={tabValue} handleTabSwitch={handleChange} tabs={tabs} />
      </Box>
      {tabValue == 0 ? (
        <Typography>
          <Client />
        </Typography>
      ) : tabValue == 1 ? (
        <Typography>
          <Project />
        </Typography>
      ) : tabValue == 2 ? (
        <Typography>
          <Usecase />
        </Typography>
      ) : tabValue == 3 ? (
        <Typography>
          <Task />
        </Typography>
      ) : null}
    </Box>
  );
};
export default Configuration;
