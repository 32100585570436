import { useEffect, useState } from 'react';
import { getIndustries, getSegments } from 'store/modules/features/common/common';
import { useAppDispatch } from 'store/hooks';
import { useAppSelector } from 'store/hooks';
import { selectIndustries, selectSegments } from 'store/modules/features/common/selector';

const useIndustries = () => {
  const dispatch = useAppDispatch();
  const allIndustries = useAppSelector(selectIndustries);
  const [industries, setIndustries] = useState<any[]>([]);

  useEffect(() => {
    if (allIndustries.data.length == 0) {
      dispatch(getIndustries());
    }
    if (allIndustries.data.length > 0) {
      setIndustries([...allIndustries.data]);
    }
  }, [allIndustries]);

  const getIndustryNameById = (id: string) => {
    if (allIndustries.data.length) {
      for (let i = 0; i < allIndustries.data.length; i++) {
        if (allIndustries.data[i].id == id) {
          return allIndustries.data[i].name;
        }
      }
    }
  };

  const getIndustryIdByName = (name: string) => {
    if (allIndustries.data.length) {
      for (let i = 0; i < allIndustries.data.length; i++) {
        if (allIndustries.data[i].name == name) {
          return allIndustries.data[i].id;
        }
      }
    }
  };

  return {
    industries,
    getIndustryNameById,
    getIndustryIdByName,
  };
};

export default useIndustries;
