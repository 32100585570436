import styled from 'styled-components';
import { ModalProvider, BaseModalBackground } from 'styled-react-modal';

import { GridBox, Box, Text } from '../../design-system/primitives';
import { ResourceGantt } from '../../components/resource-gantt';
import { useProjectView } from './useProjectView';
import { stringToHslColor } from 'components/ResourceManagement/utils/transformers';
// import { ProjectDateSwitcher } from './ProjectDateSwitcher';
import { ProjectViewSwitcher } from './ProjectViewSwitcher';
import { GenericModal } from 'components/ResourceManagement/components/shared/Modal';

const StyledProjectText = styled(Text)``;
const StyledProjectNameTile = styled(Box) <{
    itemNameLetters: string
}>`
    ${({ theme, itemNameLetters }) => {
        return `
        background-color: ${stringToHslColor(itemNameLetters, 30, 80)};
        color: ${theme.colors.grey_1};
        font-size: ${theme.fontSizes[6]};
        font-weight: ${theme.fontWeights[2]};
        border-radius: 50%;
        width: ${theme.space[16]};
        height: ${theme.space[16]};
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        `;
    }}
    `;
const StyledProjectBox = styled(Box)(({ theme }) => {
    return {
        display: 'grid',
        gridTemplateColumns: 'auto auto auto',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gridGap: `${theme.space[5]}`,
    };
});

const StyledSwitcherGrid = styled(GridBox)(({ theme }) => {
    return {
        justifyContent: 'space-between',
        alignItems: 'center',
        gridTemplateColumns: '300px auto auto 240px',
        gridTemplateRows: `${theme.space[22]}`,
        backgroundColor: `${theme.colors.grey_5}`,
        padding: `0px ${theme.space[7]}`,
        border: `${theme.borders[3]}`,
        borderTopLeftRadius: `${theme.space[2]}`,
        borderTopRightRadius: `${theme.space[2]}`,
        borderBottom: `${theme.borders[5]}`,
    };
});

const StyledRootGrid = styled(GridBox)(({ theme }) => {
    return {
        justifyContent: 'center',
        alignItems: 'center',
        gridTemplateColumns: '100%',
        borderWidth: `${theme.space[1]}`,
        border: `${theme.borders[3]}`,
    };
});

export const ProjectView = () => {
    const {
        viewMode,
        resourceNameTile,
        resourceNameToDisplay,
        resourceNameOfSelectedProjectToDisplay,
        resourceNameOfSelectedProject,
        primeComponents,
        mode,
        isProjectViewModalOpen,
        mainViewEventsForDateRange,
        toggleModal,
        handleSelect,
        handleExpanderClick,
    } = useProjectView();

    return isProjectViewModalOpen ? <GenericModal key='resource-management-project-view' isModalOpen={isProjectViewModalOpen} toggleFn={toggleModal}>
        <ModalProvider backgroundComponent={BaseModalBackground}>
            <Box mr={5} m={12} id="resource-management-project-view">
                <Box>
                    <StyledSwitcherGrid>
                        {/* <ProjectDateSwitcher /> */}
                        <span></span>
                        <StyledProjectBox>
                            <StyledProjectNameTile itemNameLetters={resourceNameOfSelectedProject}>
                                <Text variant="paragraph1">{resourceNameOfSelectedProject}</Text>
                            </StyledProjectNameTile>
                            <StyledProjectText variant="paragraph1">{resourceNameOfSelectedProjectToDisplay}</StyledProjectText>
                        </StyledProjectBox>
                        <StyledProjectBox>
                            <StyledProjectNameTile itemNameLetters={resourceNameTile}>
                                <StyledProjectText variant="paragraph1">{resourceNameTile}</StyledProjectText>
                            </StyledProjectNameTile>
                            <StyledProjectText variant="paragraph1">{resourceNameToDisplay}</StyledProjectText>
                        </StyledProjectBox>
                        <ProjectViewSwitcher />
                    </StyledSwitcherGrid>
                    <StyledRootGrid id="resource-management-project-view">
                        <ResourceGantt
                            primeComponents={primeComponents}
                            onSelect={handleSelect}
                            onExpanderClick={handleExpanderClick}
                            viewMode={viewMode}
                            mode={mode}
                            events={mainViewEventsForDateRange}
                        />
                    </StyledRootGrid>
                </Box>
            </Box>
        </ModalProvider>
    </GenericModal> : null;
};