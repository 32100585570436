import { AutocompleteRenderInputParams } from '@mui/material';
import { Field } from 'formik';
import { Autocomplete } from 'formik-mui';
// import { useState } from 'react';
import MuiTextField from '@mui/material/TextField';
import { getUserActiveState, INACTIVE_USER_COLOR } from 'utils/userHelpers';
// import CognisaasCheckBox from '../CognisaasCheckbox/CognisaasCheckbox';

interface CognisaasUserSelectType {
  name: string;
  allOptions: { id: string; name: string; isActive: string }[];
  width?: string;
  onBlur?: () => void;
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
}

// interface props {
//   usersObject: any[];
//   isMultiSelect: boolean;
//   onChange: any;
//   value: string;
//   name: string;
// }

// const containsText = (text: string, searchText: string) => text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

// interface Props {
//   placeholder?: string;
//   selectedOption: string[];
//   setSelectedOptions: React.Dispatch<React.SetStateAction<string[]>>;
//   allOptions: any[];
// }

//   const [selectedOption, setSelectedOption] = useState<string[]>([]);

const CognisaasUserSelect = (props: CognisaasUserSelectType & any) => {
  // const [searchText, setSearchText] = useState('');

  // const handleChange = (event: SelectChangeEvent<typeof props.selectedOption>) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   console.log(value);
  //   props.onChange(value);
  //   // props.setSelectedOptions(
  //   //   // On autofill we get a stringified value.
  //   //   typeof value === 'string' ? value.split(',') : value
  //   // );
  // };
  // const [mappingObject, setmappingObject] = useState<Record<string, string>>({});
  // useEffect(() => {
  //   const tempObj: any = {};
  //   props.allOptions.forEach((element: any) => {
  //     tempObj[`${element.id}`] = element.name;
  //   });

  //   setmappingObject(tempObj);
  //   // if (props.allOptions.length > 0) {
  //   //   console.log('-----');

  //   //   // displayedOptions();
  //   setSearchText('arif');
  //   //   setSearchText('');
  //   // }
  // }, []);
  console.log('ALL', props.allOptions, props.selectedOption);

  return (
    <Field
      name={props.name}
      // multiple
      component={Autocomplete}
      onChange={props.onChange}
      options={props.allOptions
        .filter((item: any) => item.is_active == '1' || item.name?.toLowerCase() === 'not assigned')
        .sort(function (a: any, b: any) {
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        })}
      ListboxProps={{
        sx: {
          maxHeight: 48 * 4.5,

          // height: '36px',
          // '& li:nth-child(even)': { backgroundColor: '#CCC' },
          // '& li:nth-child(odd)': { backgroundColor: '#FFF' },
        },
      }}
      value={props.selectedOption}
      getOptionLabel={(option: any) => option.name}
      style={{ width: props.width ? props.width : 300 }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <MuiTextField
          // sx={props?.sx}
          {...params}
          sx={
            getUserActiveState(props.selectedOption.id, props.allOptions) === '1' ||
            props.selectedOption?.name?.toLowerCase() == 'not assigned'
              ? {}
              : { input: { color: INACTIVE_USER_COLOR } }
          }
          size="small"
          name={props.name}
          // error={props.touched[props.name] && !!props.errors[props.name]}
          // helperText={props.touched[props.name] && props.errors[props.name]}
          // label="Autocomplete"
          variant="outlined"
          onBlur={props?.onBlur}
          error={props?.error}
          helperText={props?.helperText}
          disabled={props.disabled}
        />
      )}
    />
    // <Autocomplete
    //   open={props.keepOpen}
    //   // multiple
    //   id="checkboxes-tags-demo"
    //   name='Vivek Devaraj'
    //   options={props.allOptions}
    //   disableCloseOnSelect
    //   value={props.selectedOption}
    //   defaultValue={props.selectedOption}
    //   // limitTags={2}
    //   size="small"
    //   onChange={(e: any, value: any) => props.onChange(value)}
    //   //   getOptionLabel ={'Label'}
    //   getOptionLabel={(option: any) => option}
    //   renderOption={(props: any, option, { selected }) => (
    //     <li {...props}>
    //       {/* <CognisaasCheckBox
    //       sx={{ marginRight: '8px' }}
    //       //   icon={icon}
    //       //   checkedIcon={checkedIcon}
    //       //   style={{ marginRight: 8 }}
    //       checked={selected}
    //     /> */}
    //       {option}
    //     </li>
    //   )}
    //   // style={{ width: 400 }}
    //   renderInput={(params) => <TextField {...params}  placeholder="Members" />}
    // />
    // <Autocomplete
    //   id="foreignCountry"
    //   className="country-select"
    //   disableClearable={true}
    //   autoSelect={true}
    //   // name={props.name}
    //   options={props.options}
    //   getOptionLabel={(option: any) => (option.name ? option.name : '')}
    //   value={props.value}
    //   onChange={(e: any, value: any) => {
    //     // console.log(value);
    //     props.onChange(value);
    //     // props.setFieldValue('client_account_owner', value);
    //   }}
    //   renderInput={(params: any) => (
    //     <TextField
    //       {...params}
    //       name="country"
    //       placeholder="Select"
    //       // label="select member"
    //       size="small"
    //       variant="outlined"
    //       fullWidth
    //     />
    //   )}
    // />
  );
};

export default CognisaasUserSelect;
