import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

// const options = ['AND', 'OR'];

export default function Chain(props: any) {
  // const [open, setOpen] = React.useState(false);
  const [isAnd, setAnd] = React.useState<boolean>(true);
  // const anchorRef = React.useRef<HTMLDivElement>(null);
  // const [selectedIndex, setSelectedIndex] = React.useState(1);

  return (
    <React.Fragment>
      <ButtonGroup variant="outlined" aria-label="outlined button group">
        <Button
          onClick={() => {
            setAnd(true);
            props.chainWith('AND');
          }}
          className={`${isAnd ? 'active-btn' : null}`}
        >
          AND
        </Button>
        <Button
          onClick={() => {
            setAnd(false);
            props.chainWith('OR');
          }}
          className={`${!isAnd ? 'active-btn' : null}`}
        >
          OR
        </Button>
      </ButtonGroup>
    </React.Fragment>
  );
}
