import { useState, useEffect } from 'react';
import { LoadingButton } from '@mui/lab';
import { Grid, Stack, Typography, TextField, Switch } from '@mui/material';
import CognisaasButton from 'components/shared/CognisaasButton/CognisaasButton';
import CognisaasCalenderNew from 'components/shared/CognisaasCalenderNew/CognisaasCalenderNew';
import CognisaasSelect from 'components/shared/CognisaasSelect/CognisaasSelect';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import SaveIcon from '@mui/icons-material/Save';
import { selectProjectInformation, selectProjectMembers } from '../../../../store/modules/Project/selector';

import CognisaasToast from 'components/shared/CognisaasToast/CognisaasToast';
import { raidApi } from '../../../../api/modules/raid';
import { getRaidByProjectID } from 'store/modules/RAID/slice';
import { doesValueExists } from 'components/TaskModule/AddTask/utils';
import { updateCaseWithfeatureStatus } from 'store/modules/Common/slice';
import { getProjectInformation } from 'store/modules/Project/slice';
import { format } from 'date-fns';
import CognisaasAutocompleteSelect from 'components/shared/CognisaasSelect/CognisaasAutocompleteSelect';

const DetailsSection = (props: any) => {
    const { onClose, raidDetails } = props;
    const projectInfo = useAppSelector(selectProjectInformation);
    const clientUid = projectInfo.client_uid;
    const projectUid = projectInfo.project_uid;
    const [raidName, setRaidName] = useState('');
    const [raidType, setRaidType] = useState('');
    const [raidStage, setRaidStage] = useState('');
    const phaseOptions = projectInfo.section.map((phase: any) => ({ id: phase.section_uid, name: phase.section_detail }));
    const [selectedPhase, setSelectedPhase] = useState<{ id: string; name: string }>({ id: '', name: '' });
    const [dueDate, setDueDate] = useState(new Date());
    const [shareWithClient, setShareWithClient] = useState(false);
    const [taskOwner, setTaskOwner] = useState('Not Assigned');
    const [disableSave, setDisableSave] = useState<boolean>(true);
    const preSelectedCombinedMembers = useAppSelector(selectProjectMembers);
    const [isUpdatingInProgress, setIsUpdatingInProgress] = useState<boolean>(false);
    const [alertDetails, setAlertDetails] = useState<{
        isVisible: boolean;
        type: 'success' | 'failure';
        message: string;
    }>({ isVisible: false, type: 'success', message: '' });
    const taskOwnerDropdownOptions = preSelectedCombinedMembers.filter((user: any) => user.is_active === '1').map((user: any) => user.user_username);
    const taskOwnerDropdownOptionsWithNotAssigned = ['Not Assigned', ...taskOwnerDropdownOptions];
    const dispatch = useAppDispatch();

    //set initial values from raidDetails
    useEffect(() => {
        console.log('raidDetails', raidDetails);
        setRaidName(raidDetails?.name);
        setRaidType(raidDetails?.type);
        setDueDate(raidDetails?.due_date);
        setShareWithClient(raidDetails?.share_with_client);
        setTaskOwner(raidDetails?.owner_name);
        setRaidStage(raidDetails?.stage);
        if (raidDetails?.section_uid) {
            const phase = projectInfo.section.find((phase: any) => phase.section_uid === raidDetails?.section_uid);
            setSelectedPhase({ id: phase?.section_uid, name: phase?.section_detail });
        }
    }, []);

    const validateAllFields = () => {
        if (doesValueExists(raidName) && doesValueExists(raidType) && doesValueExists(selectedPhase.id)) {
            setDisableSave(false);
        }
        else {
            setDisableSave(true);
        }
    };

    useEffect(() => {
        validateAllFields();
    }, [raidName, raidType, selectedPhase.id]);

    const updateTask = async (values: any) => {
        setIsUpdatingInProgress(true);
        const uid = preSelectedCombinedMembers.find((user: any) => user.user_username === values.taskOwner)?.user_uid || '15243';
        const data = {
            name: values.raidName,
            type: values.raidType,
            due_date: format(new Date(values.dueDate), 'yyyy-MM-dd'),
            share_with_client: values.shareWithClient,
            owner_uid: uid,
            project_uid: projectUid,
            client_uid: clientUid,
            raid_uid: raidDetails?.raid_uid,
            section_uid: values.selectedPhase?.id,
            stage: values.raidType!=='Assumption'? raidStage:'',
        };
        const response = await raidApi.updateRaid(data);
        console.log('response', response);
        if (response?.data?.status === 0) {
            setAlertDetails({ isVisible: true, type: 'failure', message: response?.data?.message });
            setIsUpdatingInProgress(false);
            return;
        }
        setAlertDetails({ isVisible: true, type: 'success', message: 'RAID updated successfully' });
        await dispatch(updateCaseWithfeatureStatus(clientUid));
        const detail = {
            project_uid: projectInfo.project_uid,
            association_uid: projectInfo.association_uid,
        };
        await dispatch(getProjectInformation(detail));
        await dispatch(getRaidByProjectID(projectInfo?.project_uid));
        setIsUpdatingInProgress(false);
    };


    return (
        <>
            <form>
                <Grid spacing={2} mx={2}    >
                    <Stack spacing={2} direction="column" sx={{ marginTop: '10px' }}>
                        <Stack spacing={2} direction="row">
                            <Stack>
                                <Typography variant="subtitle2"> Name <sup style={{ color: 'red' }}>*</sup></Typography>
                                <TextField
                                    autoComplete="off"
                                    onChange={(e) => {
                                        console.log('e.target.value', e.target.value);
                                        setRaidName(e.target.value);
                                    }}
                                    autoFocus={true}
                                    style={{ width: '260px' }}
                                    size="small"
                                    placeholder={'Enter RAID name'}
                                    value={raidName}
                                ></TextField>
                            </Stack>

                            <Stack>
                                <Typography variant="subtitle2"> Type <sup style={{ color: 'red' }}>*</sup></Typography>
                                <CognisaasSelect
                                    validValues={[
                                        'Risk',
                                        'Assumption',
                                        'Issue',
                                        'Dependency',
                                    ]}
                                    value={raidType}
                                    onChange={(e: any) => {
                                        setRaidType(e.target.value);
                                    }}
                                    style={{ width: '260px' }}
                                    disabled={true}
                                />

                            </Stack>
                        </Stack>
                        <Stack spacing={2} direction="row">
                            <Stack>
                                <Typography variant="subtitle2">
                                    Phase Name<sup style={{ color: 'red' }}>*</sup>
                                </Typography>
                                <CognisaasAutocompleteSelect
                                    width="260px"
                                    value={{ id: selectedPhase.id, name: selectedPhase.name }}
                                    allOptions={phaseOptions}
                                    onChange={(data: any) => {
                                        setSelectedPhase({ id: data.id, name: data.name });
                                    }}
                                />
                            </Stack>
                            <Stack>
                                <Typography variant="subtitle2"> Due Date</Typography>
                                <Stack className='customDatePickerWidth'>
                                    <CognisaasCalenderNew
                                        handleChange={(date: any) => {
                                            setDueDate(date);
                                        }}
                                        value={dueDate}
                                        width="260px"
                                    />
                                </Stack>
                            </Stack>

                        </Stack>
                        <Stack spacing={2} direction="row">
                            <Stack>
                                <Typography variant="subtitle2"> Owner </Typography>
                                <CognisaasSelect
                                    value={taskOwner}
                                    onChange={(e: any) => setTaskOwner(e.target.value)}
                                    validValues={taskOwnerDropdownOptionsWithNotAssigned}
                                    width="260px"
                                ></CognisaasSelect>
                            </Stack>
                            <Stack>
                                <Typography variant="subtitle2"> Stage</Typography>
                                <CognisaasSelect
                                    disabled={raidType === 'Assumption'}
                                    onChange={(e: any) =>
                                        setRaidStage(e.target.value)
                                    }
                                    value={raidStage}
                                    validValues={['Mitigated', 'Not Mitigated']}
                                    width="260px"
                                />
                            </Stack>

                        </Stack>

                        <Stack>
                            <Stack spacing={1} direction="row">
                                <Typography variant="subtitle2">Share with client</Typography>
                            </Stack>
                            <Stack>
                                <Switch
                                    checked={shareWithClient}
                                    onChange={() => setShareWithClient(!shareWithClient)}
                                ></Switch>
                            </Stack>
                        </Stack>

                        <Stack justifyContent="flex-end" direction="row">
                            <Stack mb={2} spacing={1} direction="row">
                                <CognisaasButton
                                    clickHandler={() => {
                                        onClose();
                                    }}
                                    isOutlined={true}
                                    label="Cancel"
                                />

                                {isUpdatingInProgress ? (
                                    <LoadingButton loading loadingPosition="start" startIcon={<SaveIcon />} variant="outlined">
                                        Save
                                    </LoadingButton>
                                ) : (
                                    <CognisaasButton
                                        clickHandler={() => {
                                            updateTask({
                                                raidName,
                                                raidType,
                                                dueDate,
                                                shareWithClient,
                                                taskOwner,
                                                selectedPhase,
                                                raidStage,
                                            });

                                        }}
                                        disabled={disableSave}
                                        // clickHandler={saveTask}
                                        isOutlined={false}
                                        label="Save"
                                    />
                                )}
                            </Stack>
                        </Stack>
                    </Stack>
                </Grid>
            </form>
            <CognisaasToast
                fromPanel={true}
                open={alertDetails.isVisible}
                onClose={() => setAlertDetails({ isVisible: false, type: alertDetails.type, message: '' })}
                message={alertDetails.message}
                type={alertDetails.type}
            />
        </>
    );
};

export default DetailsSection;