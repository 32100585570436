import {
  OPERATION_AVERAGE_OPERATION_ID,
  OPERATION_COUNT_OPERATION_ID,
  OPERATION_TOTAL_OPERATION_ID,
} from './constants';

export const doCount = {
  operationId: OPERATION_COUNT_OPERATION_ID,
  label: 'Count',
  apply: (data: Record<string, any>[], dataKey: string): number => {
    console.log(dataKey);
    return data.length;
  },
};

export const doTotal = {
  operationId: OPERATION_TOTAL_OPERATION_ID,
  label: 'Total',
  apply: (data: any[], dataKey: string): number => {
    const total = data.reduce((accu, item) => accu + parseInt(item[dataKey]), 0);
    return total;
  },
};

export const doAverage = {
  operationId: OPERATION_AVERAGE_OPERATION_ID,
  label: 'Average',
  apply: (data: Record<string, any>[], dataKey: string): number => {
    const total = data.reduce((accu, item) => accu + parseInt(item[dataKey]), 0);
    const roundedAverage = Math.round((total / data.length) * 100) / 100;
    return roundedAverage;
  },
};
