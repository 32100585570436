import { Form, Field, FieldProps } from 'formik';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

type TFormikAutocomplete = {
  textFieldProps: Record<string, string | boolean>;
  form: Record<string, any>;
  field: Record<string, any>;
  width: string;
  options: Record<string, any>[];
  selectedOptions: Record<string, any>[];
} & FieldProps;

const FormikAutocomplete = ({ textFieldProps, width, ...props }: TFormikAutocomplete) => {
  const {
    form: { setFieldValue, errors },
    field,
    options,
  } = props;
  const { name } = field;

  const isError = !!errors?.[name];
  const errorText = errors[name];

  const handleOnChange = (e: any, value: any) => {
    console.log({ e, value });
    setFieldValue(name, value);
  };

  console.log({ props, name, isError, options, field });

  return (
    <Autocomplete
      {...props}
      {...field}
      limitTags={1}
      size="small"
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      defaultValue={props.selectedOptions}
      onChange={handleOnChange}
      disableCloseOnSelect
      style={{ width: width ? width : 400 }}
      // getOptionLabel={(option) => option.label}
      renderInput={(props) => {
        console.log({ renderInput: props });
        return <TextField {...props} {...textFieldProps} style={{ margin: 0 }} helperText={errorText} error={isError} />;
      }}
    />
  );
};

type Props = {
  name: string;
  allOptions: Record<string, any>[];
  selectedOptions: Record<string, any>[];
  width: string;
  errText: string;
  placeholder: string;
};

export const CognisaasMultiSelectWithSearchAndValidation = ({
  name,
  allOptions,
  selectedOptions,
  width,
  errText,
  placeholder,
}: Props) => {
  const customProps = {
    errText,
    width,
    placeholder,
  };

  return (
    <Form>
      <Field
        name={name}
        component={FormikAutocomplete}
        options={allOptions}
        selectedOptions={selectedOptions}
        textFieldProps={{
          fullWidth: true,
          margin: 'normal',
          variant: 'outlined',
          placeholder: 'Choose a team member',
        }}
        {...customProps}
        multiple
      />
    </Form>
  );
};
