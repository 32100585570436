import { Grid, Stack } from '@mui/material';
import { Box } from '@mui/system';

interface Props {
  leftBarHeaderComponent: React.ReactNode;
  leftBarMainComponent: React.ReactNode;
  leftBarFooterComponent: React.ReactNode;
  rightBarHeaderComponent: React.ReactNode;
  rightBarMainComponent: React.ReactNode;
  maxHeight?: string;
  margin?: string;
}

const ProdReqLayout = (props: Props) => {
  return (
    <>
      <Grid
        container
        spacing={1}
        sx={{ height: props.maxHeight ? props.maxHeight : '100vh', margin: props.margin ? props.margin : '0px' }}
      >
        <Grid item lg={3} md={4} sx={{ height: '100%' }}>
          {/* left bar  */}
          <Stack justifyContent={'space-between'} sx={{ background: 'white', padding: '20px', borderRadius: '4px' }}>
            <Stack>
              {/* one header */}
              {props.leftBarHeaderComponent}

              {/* main content  */}
              <Box sx={{ height: '52vh', marginRight: '8px', overflowY: 'scroll' }}>{props.leftBarMainComponent}</Box>
            </Stack>

            {/* one footer */}
            {props.leftBarFooterComponent}
          </Stack>
        </Grid>

        <Grid item lg={9} md={8} sx={{ paddingRight: '8px' }}>
          {/* right bar  */}

          <Stack direction={'column'} sx={{ background: 'white' }} width={'95%'}>
            {/* one header */}
            {props.rightBarHeaderComponent}

            {/* main content  */}
            <Box sx={{ height: '57vh', marginRight: '8px', overflowY: 'scroll' }}>{props.rightBarMainComponent}</Box>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default ProdReqLayout;
