import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { isNull, isUndefined } from 'utils/dataHelpers';
import { clientAPI } from '../../../../api/modules/client';
import { requestState } from '../../types';

interface revenueTasks {
  revenueTasks: Array<any>;
  status: requestState.idle | requestState.loading | requestState.success | requestState.failed;
  error: null | undefined | string;
  revenueUsecaseTask: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };
  revenueProjectTask: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };
}

const initialState: revenueTasks = {
  revenueTasks: [],
  status: requestState.idle,
  error: null,
  revenueUsecaseTask: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  revenueProjectTask: {
    data: [],
    status: requestState.idle,
    error: null,
  },
};

async function getRevenueUsecaseTasks() {
  const { data } = await clientAPI.getAllRevenueUsecaseTasks();
  return data.result ?? [];
}

async function getRevenueProjectTasks() {
  const { data } = await clientAPI.getRevenueProjectTasks();
  return data.result ?? [];
}

export const fetchRevenueUsecaseTasks = createAsyncThunk('projects/fetchRevenueUsecaseTasks', async () => {
  const { data } = await clientAPI.getAllRevenueUsecaseTasks();
  return data.result ?? [];
});

export const fetchRevenueProjectTasks = createAsyncThunk('projects/fetchRevenueProjectTasks', async () => {
  const { data } = await clientAPI.getRevenueProjectTasks();
  return data.result ?? [];
});

export const fetchRevenueTasks = createAsyncThunk('projects/revenueProjectTasks', async () => {
  const revenueUsecaseTasks = await getRevenueUsecaseTasks();
  const revenueProjectTasks = await getRevenueProjectTasks();
  const modifiedRevenueUsecaseTasks = revenueUsecaseTasks.map((task: { taskType: string }) => {
    task.taskType = 'usecase';
    return task;
  });
  const modifiedRevenueProjectTasks = revenueProjectTasks.map((task: { taskType: string }) => {
    task.taskType = 'project';
    return task;
  });
  const revenueTasks = modifiedRevenueUsecaseTasks.concat(modifiedRevenueProjectTasks);

  return revenueTasks;
});

const revenueTasksSlice = createSlice({
  name: 'revenueTasks',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchRevenueTasks.pending, (state) => {
        state.status = requestState.loading;
      })
      .addCase(fetchRevenueTasks.fulfilled, (state, action) => {
        state.status = requestState.success;
        state.revenueTasks = action.payload;
      })
      .addCase(fetchRevenueTasks.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      })
      .addCase(fetchRevenueUsecaseTasks.pending, (state) => {
        state.revenueUsecaseTask.status = requestState.loading;
      })
      .addCase(fetchRevenueUsecaseTasks.fulfilled, (state, action) => {
        state.revenueUsecaseTask.status = requestState.success;
        if (isUndefined(action.payload) || isNull(action.payload)) {
          state.revenueUsecaseTask.data = [];
        } else {
          state.revenueUsecaseTask.data = action.payload;
        }
      })
      .addCase(fetchRevenueUsecaseTasks.rejected, (state, action) => {
        state.revenueUsecaseTask.status = requestState.failed;
        state.revenueUsecaseTask.error = action.error.message;
      })
      .addCase(fetchRevenueProjectTasks.pending, (state) => {
        state.revenueProjectTask.status = requestState.loading;
      })
      .addCase(fetchRevenueProjectTasks.fulfilled, (state, action) => {
        state.revenueProjectTask.status = requestState.success;
        if (isUndefined(action.payload) || isNull(action.payload)) {
          state.revenueProjectTask.data = [];
        } else {
          state.revenueProjectTask.data = action.payload;
        }
      })
      .addCase(fetchRevenueProjectTasks.rejected, (state, action) => {
        state.revenueProjectTask.status = requestState.failed;
        state.revenueProjectTask.error = action.error.message;
      });
  },
});

export const getAllRevenueTasks = (state: revenueTasks) => state.revenueTasks;

const revenueTasksReducer = revenueTasksSlice.reducer;
export default revenueTasksReducer;
