import { useEffect, useState } from 'react';
import { ButtonGroup, Button, Typography, MenuItem, FormControl, Select, Box } from '@mui/material';
import Projects from './Project';
import PhasesAndTasks from './PhasesAndTasks';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  getProjectDetailsByCustomerUid_slice,
  phasesAndTasks_slice,
} from '../../../../store/modules/ProjectReport/slice';
import { projectData_selector, phasesAndTasks_selector } from '../../../../store/modules/ProjectReport/selector';
import Cookies from 'universal-cookie';
import { selectAllFilters } from 'store/modules/AllFilters/selector';
import FilterTypeSelector from 'components/shared/CognisaasFilters/FilterTypeSelector/FilterTypeSelector';
import SelectedFilterList from 'components/shared/CognisaasFilters/SelectedFilterList/SelectedFilterList';
import { label_project, label_task } from 'components/Settings/Preferences/CustomLabelCookieHelper';

const cookies = new Cookies();

const Project1 = () => {
  const dispatch = useAppDispatch();
  const allProjectsDetails_fromSelector = useAppSelector(projectData_selector).data as Record<string, any>;
  const phasesAndTasksDetails_fromSelector = useAppSelector(phasesAndTasks_selector).data as Record<string, any>;
  const [activeButton, setActiveButton] = useState(1);
  const [durationFilter, setDurationFilter] = useState<string>('All');
  const [projectsData, setProjectsData] = useState<any>([]);
  const [phasesAndTaskData, setPhasesAndTaskData] = useState<any>([]);

  const allFilters = useAppSelector(selectAllFilters);
  useEffect(() => {
    const customer_uid = cookies.get('cogni_customer_uid') as string;
    dispatch(getProjectDetailsByCustomerUid_slice(customer_uid));
    dispatch(phasesAndTasks_slice(customer_uid));
  }, [dispatch]);

  useEffect(() => {
    setProjectsData(allProjectsDetails_fromSelector.result);
  }, [allProjectsDetails_fromSelector]);

  useEffect(() => {
    setPhasesAndTaskData(phasesAndTasksDetails_fromSelector.result);
  }, [phasesAndTasksDetails_fromSelector]);

  useEffect(() => {
    const today = new Date().getTime();
    if (activeButton == 1) {
      if (durationFilter == 'All') {
        setProjectsData(allProjectsDetails_fromSelector.result);
      } else if (durationFilter == 'Last Week') {
        let tempProjectData = allProjectsDetails_fromSelector.result?.map((obj: any) => {
          const objDate = new Date(obj.project_startdate.replace(/-/g, '/').slice(0, 10));
          const diff = (today - objDate.getTime()) / (24 * 60 * 60 * 1000);
          if (diff <= 7) {
            return obj;
          }
        });
        tempProjectData = tempProjectData?.filter((obj: any) => obj != undefined);
        setProjectsData(tempProjectData);
      } else if (durationFilter == 'Last month') {
        let tempProjectData = allProjectsDetails_fromSelector.result?.map((obj: any) => {
          const objDate = new Date(obj.project_startdate.replace(/-/g, '/').slice(0, 10));
          const diff = (today - objDate.getTime()) / (24 * 60 * 60 * 1000);
          if (diff <= 31) {
            return obj;
          }
        });
        tempProjectData = tempProjectData?.filter((obj: any) => obj != undefined);
        setProjectsData(tempProjectData);
      } else if (durationFilter == 'Last 90 Days') {
        let tempProjectData = allProjectsDetails_fromSelector.result?.map((obj: any) => {
          const objDate = new Date(obj.project_startdate.replace(/-/g, '/').slice(0, 10));
          const diff = (today - objDate.getTime()) / (24 * 60 * 60 * 1000);
          if (diff <= 90) {
            return obj;
          }
        });
        tempProjectData = tempProjectData?.filter((obj: any) => obj != undefined);
        setProjectsData(tempProjectData);
      }
    } else if (activeButton == 2) {
      if (durationFilter == 'All') {
        setPhasesAndTaskData(phasesAndTasksDetails_fromSelector.result);
      } else if (durationFilter == 'Last Week') {
        let tempProjectData = phasesAndTasksDetails_fromSelector.result?.map((obj: any) => {
          const objDate = new Date(obj.project_startdate.replace(/-/g, '/').slice(0, 10));
          const diff = (today - objDate.getTime()) / (24 * 60 * 60 * 1000);
          if (diff <= 7) {
            return obj;
          }
        });
        tempProjectData = tempProjectData?.filter((obj: any) => obj != undefined);
        setPhasesAndTaskData(tempProjectData);
      } else if (durationFilter == 'Last month') {
        let tempProjectData = phasesAndTasksDetails_fromSelector.result?.map((obj: any) => {
          const objDate = new Date(obj.project_startdate.replace(/-/g, '/').slice(0, 10));
          const diff = (today - objDate.getTime()) / (24 * 60 * 60 * 1000);
          if (diff <= 31) {
            return obj;
          }
        });
        tempProjectData = tempProjectData?.filter((obj: any) => obj != undefined);
        setPhasesAndTaskData(tempProjectData);
      } else if (durationFilter == 'Last 90 Days') {
        let tempProjectData = phasesAndTasksDetails_fromSelector.result?.map((obj: any) => {
          const objDate = new Date(obj.project_startdate.replace(/-/g, '/').slice(0, 10));
          const diff = (today - objDate.getTime()) / (24 * 60 * 60 * 1000);
          if (diff <= 90) {
            return obj;
          }
        });
        tempProjectData = tempProjectData?.filter((obj: any) => obj != undefined);
        setPhasesAndTaskData(tempProjectData);
      }
    }
  }, [durationFilter]);

  const onChickActiveButton = (buttonNumber: number) => {
    setActiveButton(buttonNumber);
  };
  return (
    <Box sx={{ display: 'grid', alignItems: 'center', justifyContent: 'stretch', gridRowGap: '20px' }}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr auto auto',
          alignItems: 'center',
          gridColumnGap: activeButton === 1 ? '10px' : '0px',
        }}
      >
        <ButtonGroup variant="outlined" aria-label="outlined button group" size="large">
          <Button
            className={activeButton == 1 ? 'active-btn' : undefined}
            onClick={() => {
              onChickActiveButton(1);
            }}
          >
            <Typography variant={'subtitle1'}>{label_project}s</Typography>
          </Button>
          <Button
            className={activeButton == 2 ? 'active-btn' : undefined}
            onClick={() => {
              onChickActiveButton(2);
            }}
          >
            <Typography variant={'subtitle1'}>Phases & {label_task}s</Typography>
          </Button>
        </ButtonGroup>
        <Box sx={{ display: 'flex', alignItems: 'center', justifySelf: 'flex-end' }}>
          <Typography variant="subtitle1">Duration</Typography>
          <FormControl sx={{ marginLeft: '10px' }}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={durationFilter}
              onChange={(e: any) => setDurationFilter(e.target.value)}
              size="small"
              inputProps={{ sx: { display: 'flex', alignItems: 'center' } }}
              sx={{
                width: '180px',
                padding: '0px !important',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <MenuItem value={'All'}>
                <Typography variant="subtitle1">All</Typography>
              </MenuItem>
              <MenuItem value={'Last Week'}>
                <Typography variant="subtitle1">Last Week</Typography>
              </MenuItem>
              <MenuItem value={'Last month'}>
                <Typography variant="subtitle1">Last month</Typography>
              </MenuItem>
              <MenuItem value={'Last 90 Days'}>
                <Typography variant="subtitle1">Last 90 Days</Typography>
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
        {activeButton === 1 && (
          <Box>
            <FilterTypeSelector idName="performanceProjectsOfProjectPage" />
          </Box>
        )}
        {activeButton === 2 && (
          <Box>
            <FilterTypeSelector idName="performanceProjectphaseAndTasksReport" />
          </Box>
        )}
      </Box>
      {activeButton === 1 &&
        allFilters['performanceProjectsOfProjectPage']?.appliedFilters.filter((item: any) => item.isSelected).length >
          0 && (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
            <SelectedFilterList idName="performanceProjectsOfProjectPage" isResetEnabled={true} />
          </Box>
        )}
      {activeButton === 2 &&
        allFilters['performanceProjectphaseAndTasksReport']?.appliedFilters.filter((item: any) => item.isSelected)
          .length > 0 && <SelectedFilterList idName="performanceProjectphaseAndTasksReport" isResetEnabled={true} />}
      <Box>{activeButton === 1 ? <Projects data={projectsData} /> : <PhasesAndTasks data={phasesAndTaskData} />}</Box>
    </Box>
  );
};

export default Project1;
