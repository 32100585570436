import { Avatar, Box, Grid, Stack, Typography } from '@mui/material';
import { getStatus, getStatusColor } from '../../../utils/dataHelpers';
import { ReactNode } from 'react';
import {
  label_project,
  label_task,
  label_use_case_delivery_manager,
} from 'components/Settings/Preferences/CustomLabelCookieHelper';

interface Props {
  type?: string;
  deliveryManagerName: string;
  usecaseOrTaskName: string | ReactNode;
  delayedBy: string;
  NumOfProjectsAssignedToManager: number;
  status: 'red' | 'amber' | 'green' | 'blue';
  customHeader?: boolean;
}

const UsecaseTaskRecommendationCard = (props: Props) => {
  function stringToHslColor(str: string, s: number, l: number) {
    let hash = 0;

    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    const h = hash % 360;
    return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
  }

  function stringAvatar(name: string) {
    return {
      sx: {
        bgcolor: stringToHslColor(name, 30, 80),
      },
      children: (
        <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#000000' }}>
          {name.split(' ')[0][0]}
          {name.indexOf(' ') >= 0 ? name.split(' ')[1][0] : null}
        </Typography>
      ),
    };
  }

  return (
    <Box
      sx={{
        maxWidth: '28vw',
        boxShadow: '0px 4px 26px rgba(0, 0, 0, 0.05)',
        border: '1px solid #E5E5E5',
        borderRadius: '4px',
        marginBottom: '20px',
      }}
    >
      <Grid container sx={{ padding: '7px' }}>
        <Grid item xs={12}>
          <Grid container justifyContent={'space-between'}>
            <Grid item xs={10}>
              <Typography variant="subtitle1">
                <Typography variant="subtitle1">{props.customHeader ? '' : label_task}</Typography>
                <Typography variant="subtitle1" style={{ color: props.customHeader ? '' : '#2D60F6' }}>
                  {props.usecaseOrTaskName}
                </Typography>
                {props.customHeader
                  ? ''
                  : ` delayed by ${props.delayedBy} and ${label_use_case_delivery_manager} is assigned on
                ${props.NumOfProjectsAssignedToManager} ${label_project}s`}
              </Typography>
            </Grid>
            <Grid item xs={1}></Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ margin: '10px 0px' }}>
          <Grid container>
            <Grid item xs={8}>
              <Stack direction={'row'} alignItems={'center'} spacing={2}>
                <Avatar
                  style={{ height: '34px', width: '34px', marginTop: '3px' }}
                  {...stringAvatar(props.deliveryManagerName)}
                ></Avatar>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" className="label-text">
                      {label_use_case_delivery_manager}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1">{props.deliveryManagerName}</Typography>
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="subtitle2" className="label-text">
                    Status
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    component={'span'}
                    sx={{ height: '8px', width: '8px', borderRadius: '50%', background: getStatusColor(props.status) }}
                  ></Box>
                  <Typography variant="subtitle1">{getStatus(props.status)}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container sx={{ marginLeft: '46px' }}>
            <Grid item xs={12}>
              <Typography variant="subtitle2" className="label-text">
                {props.type ? <># {props.type} assigned</> : <>#tasks assigned</>}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">{props.NumOfProjectsAssignedToManager}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UsecaseTaskRecommendationCard;
