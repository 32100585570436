import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from '@mui/material';
import { FlexBox, GridBox, Label, Text } from '../primitives';
import { Controller } from 'react-hook-form';
import { useEffect } from 'react';



export const FormDatePicker = ({
    id,
    name,
    selectedDateItem,
    customLabel,
    control,
    formState: { errors },
    resetField,
    setValue
}: any) => {

    useEffect(() => {
        setValue(name, selectedDateItem);
        return () => resetField(name);
    }, []);

    const errName = errors?.[name];
    const errType = errName?.type;
    const errMessage = errName?.message;
    const isError = !!(errType && errMessage);

    return (
        <GridBox
            gridTemplateRows='30px auto 20px'
            gridTemplateColumns='1fr'
            justifyContent='flex-start'
            alignItems='center'
        >
            {customLabel && (
                <Label
                    id={id}
                    variant='heading3'
                    htmlFor={name}
                >
                    {customLabel}
                </Label>
            )}
            <Controller
                name={name}
                control={control}
                render={({ field }) =>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            key={name}
                            InputProps={{ readOnly: true }}
                            {...field}
                            renderInput={(params) => (
                                <TextField {...params} onKeyDown={(e: any) => e.preventDefault()} size="medium" />
                            )}
                        />
                    </LocalizationProvider>
                }
            />
            {isError ? (
                <FlexBox pt={3}>
                    <Text variant='paragraph2' color='red'>{errors[name].message}</Text>
                </FlexBox>
            ) : null}
        </GridBox >
    );
};