import { useEffect, useMemo, useState } from 'react';
import {
  Grid,
  Typography,
  TextField,
  // Checkbox,
  // Card,
  useFormControl,
  // FormControlLabel,
  FormHelperText,
  Button,
} from '@mui/material';
// import Accordion from '@mui/material/Accordion';
// import AccordionDetails from '@mui/material/AccordionDetails';
// import AccordionSummary from '@mui/material/AccordionSummary';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import FormGroup from '@mui/material/FormGroup';
import CognisaasButton from '../../shared/CognisaasButton/CognisaasButton';
import { useDispatch } from 'react-redux';
// import { useAppSelector } from '../../../store/hooks';
import { addRole_slice, getAllPages_slice } from '../../../store/modules/RBAC/slice';
import { useNavigate } from 'react-router-dom';
import { getAllPages_selector } from 'store/modules/RBAC/selector';
import { useAppSelector } from 'store/hooks';
// import { label_client, label_project, label_task } from 'components/Settings/Preferences/CustomLabelCookieHelper';

interface helperText_payload {
  text: string;
  textToCheck: string;
}

// interface checkBox_payload{
//     textToDisplay: string,
//     isChecked: boolean
// }

// const CheckBoxShow = (props: checkBox_payload) => {
//     return (

//     );
// };

const AddUser = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [roleName, setRoleName] = useState('');
  const [description, setDescription] = useState('');
  // const [expanded, setExpanded] = useState<string | false>('0');
  // const [projectsCheckboxes, setProjectsCheckboxes] = useState([false, false, false, false, false, false]);
  // const [pagesCheckboxes, setPagesCheckboxes] = useState<any>([]);
  // const [allPages, setAllPages] = useState<any>([]);
  // const getAllResources_fromSelector = useAppSelector(getAllResources_selector).data;
  const getAllPages_fromSelector = useAppSelector(getAllPages_selector).data;

  // const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
  //   setExpanded(isExpanded ? panel : false);
  // };

  // const handleAddCustomPermission = () => {
  //   console.log('handleAddCustomPermission clicked');
  // };

  useEffect(() => {
    // dispatch(getAllResources_slice());
    dispatch(getAllPages_slice());
  }, []);

  useEffect(() => {
    if (getAllPages_fromSelector) {
      // const temp = getAllPages_fromSelector?.map(() => false);
      // setPagesCheckboxes(temp);
      // setAllPages(getAllPages_fromSelector);
      console.log(getAllPages_fromSelector);
    }
  }, [getAllPages_fromSelector]);

  const onClickCreate = () => {
    // const name = localStorage.getItem('currentName');
    // console.log('create with - ', {
    //     'role': roleName,
    //     'name': name,
    //     'description': description
    // });
    dispatch(
      addRole_slice({
        name: roleName ? roleName : '',
        description: description,
      })
    );
    navigate('/access-management');
  };

  const MyFormHelperText = (props: helperText_payload) => {
    const { focused } = useFormControl() || {};

    const helperText = useMemo(() => {
      if (focused) {
        return null;
      } else {
        return props.textToCheck == '' ? props.text : null;
      }
    }, [focused]);

    return <FormHelperText>{helperText}</FormHelperText>;
  };

  return (
    <Grid sx={{ margin: '20px 100px' }}>
      <Typography variant="h1">Create New Role</Typography>
      <Grid
        sx={{
          marginTop: '18px',
          position: 'relative',
          height: '82vh',
          overflowY: 'scroll',
          padding: '30px',
          minHeight: '80vh',
          width: '70vw !important',
          backgroundColor: 'white',
          borderRadius: '5px',
        }}
      >
        <Grid sx={{ minHeight: '95px' }}>
          <Typography variant="h3" fontSize="15px" marginBottom="4px">
            Role Name *
          </Typography>
          <TextField
            size="small"
            value={roleName}
            onChange={(e: any) => {
              setRoleName(e.target.value);
            }}
            placeholder="Enter Role Name"
          />
          <MyFormHelperText textToCheck={roleName} text="Fill the Role Name" />
        </Grid>
        <Grid sx={{ minHeight: '95px' }}>
          <Typography variant="h3" fontSize="15px" marginTop="20px" marginBottom="4px">
            Description *
          </Typography>
          <TextField
            size="small"
            value={description}
            onChange={(e: any) => {
              setDescription(e.target.value);
            }}
            placeholder="Enter Description"
          />
          <MyFormHelperText textToCheck={description} text="Please Enter Description" />
        </Grid>
        {/* <Grid sx={{ marginTop: '28px' }}>
          <Typography variant="h2" fontSize="20px">
            Add Permissions
          </Typography>
          <Typography variant="h2" fontSize="17px" marginTop="15px">
            Resources
          </Typography>
        </Grid>
        <Grid sx={{ width: '30vw !important', marginTop: '15px' }}>
          <Card>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                <Typography variant={'h2'} sx={{ width: '33%', flexShrink: 0 }}>
                  {label_project}s
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormGroup sx={{ marginLeft: '10px' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        onChange={() => {
                          const temp = { ...projectsCheckboxes };
                          temp[0] = !temp[0];
                          setProjectsCheckboxes(temp);
                        }}
                        checked={projectsCheckboxes[0]}
                      />
                    }
                    label={
                      <Typography variant={'h3'} sx={{ marginLeft: '5px' }}>
                        View {label_project}s assigned to me
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        onChange={() => {
                          const temp = { ...projectsCheckboxes };
                          temp[1] = !temp[1];
                          setProjectsCheckboxes(temp);
                        }}
                        checked={projectsCheckboxes[1]}
                      />
                    }
                    label={
                      <Typography variant={'h3'} sx={{ marginLeft: '5px' }}>
                        Edit {label_project}s assigned to me{' '}
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        onChange={() => {
                          const temp = { ...projectsCheckboxes };
                          temp[2] = !temp[2];
                          setProjectsCheckboxes(temp);
                        }}
                        checked={projectsCheckboxes[2]}
                      />
                    }
                    label={
                      <Typography variant={'h3'} sx={{ marginLeft: '5px' }}>
                        View {label_project}s assigned to users reporting me{' '}
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        onChange={() => {
                          const temp = { ...projectsCheckboxes };
                          temp[3] = !temp[3];
                          setProjectsCheckboxes(temp);
                        }}
                        checked={projectsCheckboxes[3]}
                      />
                    }
                    label={
                      <Typography variant={'h3'} sx={{ marginLeft: '5px' }}>
                        Edit {label_project}s assigned to users reporting me{' '}
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        onChange={() => {
                          const temp = { ...projectsCheckboxes };
                          temp[4] = !temp[4];
                          setProjectsCheckboxes(temp);
                        }}
                        checked={projectsCheckboxes[4]}
                      />
                    }
                    label={
                      <Typography variant={'h3'} sx={{ marginLeft: '5px' }}>
                        View All{' '}
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        onChange={() => {
                          const temp = { ...projectsCheckboxes };
                          temp[5] = !temp[5];
                          setProjectsCheckboxes(temp);
                        }}
                        checked={projectsCheckboxes[5]}
                      />
                    }
                    label={
                      <Typography variant={'h3'} sx={{ marginLeft: '5px' }}>
                        Edit All{' '}
                      </Typography>
                    }
                  />
                </FormGroup>
                <CognisaasButton
                  style={{ marginLeft: '12px', marginTop: '10px' }}
                  isOutlined={true}
                  size="small"
                  clickHandler={handleAddCustomPermission}
                  label="Add Custom Permission"
                />
              </AccordionDetails>
            </Accordion>
          </Card>
          <Card sx={{ marginTop: '20px' }}>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                <Typography variant={'h2'} sx={{ width: '33%', flexShrink: 0 }}>
                  {label_client}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{label_client} Accordian</Typography>
              </AccordionDetails>
            </Accordion>
          </Card>
          <Card sx={{ marginTop: '20px' }}>
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                <Typography variant={'h2'} sx={{ width: '33%', flexShrink: 0 }}>
                  {label_task}s
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{label_task} Accordian</Typography>
              </AccordionDetails>
            </Accordion>
          </Card>
        </Grid>
        <Grid>
          <Typography variant="h2" fontSize="17px" marginTop="40px">
            Pages
          </Typography>
          <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
            {allPages?.map((obj: any, index: number) => {
              return (
                <FormControlLabel
                  key={obj.id}
                  sx={{ marginTop: '10px', marginLeft: '0px' }}
                  control={
                    <Checkbox
                      onChange={() => {
                        const temp = { ...pagesCheckboxes };
                        temp[index] = !temp[index];
                        setPagesCheckboxes(temp);
                      }}
                      checked={pagesCheckboxes[index]}
                      size="small"
                    />
                  }
                  label={
                    <Typography marginLeft="13px" variant="h3">
                      {obj.name}
                    </Typography>
                  }
                />
              );
            })}
          </Grid>

        </Grid> */}
        <Grid sx={{ marginTop: '15px', display: 'flex', justifyContent: 'flex-end' }}>
          <CognisaasButton isOutlined={true} label="Cancel" clickHandler={() => navigate(-1)} />
          {/* <CognisaasButton isOutlined={false} sx={{marginLeft: '25px'}} label="Create" /> */}
          <Button onClick={onClickCreate} sx={{ marginLeft: '25px' }} variant="contained">
            Create
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddUser;
