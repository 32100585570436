import { useState, useEffect } from 'react';

import { Box, Grid, Stack, Typography, Paper, Button, OutlinedInput, InputAdornment } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';

import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import SHA3 from 'crypto-js/sha3';

import { useAppSelector } from '../../store/hooks';

import { ReactComponent as BrandLogo } from '../../assets/svgs/BrandLogo.svg';
import { updatePasswordByUId } from '../../store/modules/auth/auth';
import { selectpasswordUpdated } from '../../store/modules/auth/selector';

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const url = window.location.pathname;
  const tokenId = url.slice(16, 37);
  const token = url.slice(38, 55);

  const [isFocused, setIsFocues] = useState(false);

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isPasswordSame, setIsPasswordSame] = useState(false);

  const [contains8letters, setContains8Letters] = useState(false);
  const [containsUpperLowerLetter, setContainsUpperLowerletter] = useState(false);
  const [containsSymbolNumber, setContainsSymbolNumber] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const isPasswordUpdated = useAppSelector(selectpasswordUpdated);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const handleKeyDown = (e: any) => {
    if (e.key === ' ') {
      e.preventDefault();
    }
  };

  const handlePassword = (value: string) => {
    const regExpUpper = /[A-Z]/;
    const regExpLower = /[a-z]/;
    const regExpNumber = /[0-9]/;
    const regExpSymbol = /[!@#$%^&*]/;
    console.log(value, 'passnew');
    if (value.length > 7) {
      setContains8Letters(true);
    } else {
      {
        setContains8Letters(false);
      }
    }
    if (regExpUpper.test(value) && regExpLower.test(value)) setContainsUpperLowerletter(true);
    else {
      {
        setContainsUpperLowerletter(false);
      }
    }
    if (regExpNumber.test(value) || regExpSymbol.test(value)) setContainsSymbolNumber(true);
    else {
      {
        setContainsSymbolNumber(false);
      }
    }
    setPassword(value);
    console.log('passed all validations');
  };

  const handleSubmitSetPassword = () => {
    console.log(confirmPassword, 'i was clicked');
    const hash = SHA3(password, { outputLength: 512 }).toString();
    console.log(hash, 'hashed pass');
    // setPassword(hash);
    dispatch(updatePasswordByUId({ user_uid: tokenId, user_password: hash }));
  };

  useEffect(() => {
    console.log(url, tokenId, 'test');
    if (isPasswordUpdated) navigate('/login/');
  }, [url, tokenId, token, isPasswordUpdated]);

  return (
    <Box>
      <Grid container>
        <Grid item xs={9} sx={{ backgroundColor: '#F5F5F7', height: '100vh', margin: '0px', paddingLeft: '110px' }}>
          {/* <img style={{ width: '326px', height: '60px', marginTop: '372px' }} src={BrandLogo} alt="COGNISAAS" /> */}
          <BrandLogo style={{ width: '326px', height: '60px', marginTop: '30vh' }} />
          <Typography sx={{ fontSize: '44px', fontWeight: '600', marginTop: '45px', lineHeight: '70.8px' }}>
            Deliver faster time-to-value
          </Typography>
          <Typography sx={{ fontSize: '44px', fontWeight: '600', lineHeight: '70.8px' }}>for customer</Typography>
          <Typography sx={{ fontSize: '28px', fontWeight: '400', color: '#7A7A7A', lineHeight: '45px' }}>
            by collaborating with cross-functional teams on a
          </Typography>
          <Typography sx={{ fontSize: '28px', fontWeight: '400', color: '#7A7A7A', lineHeight: '45px' }}>
            common platform.
          </Typography>
        </Grid>
        <Grid item xs={3} sx={{ backgroundColor: '#3D38BA', height: '100vh', margin: '0px', padding: '0px' }}>
          <Paper
            // elevation={1}
            sx={{
              position: 'absolute',
              backgroundColor: '#FFFFFF',
              width: '400px',
              height: '70vh',
              left: '60vw',
              top: '10vh',
              padding: '32px 20px',
              borderRadius: '4px',
              boxShadow: '0px 4px 26px rgba(0, 0, 0, 0.05);',
              overflowY: 'scroll',
            }}
          >
            <Typography sx={{ fontSize: '20px', fontWeight: '600' }}>Enter new password</Typography>

            <Typography sx={{ marginTop: '24px', fontSize: '12px', fontWeight: '400' }}>Password</Typography>
            <OutlinedInput
              sx={{ marginTop: '4px' }}
              fullWidth
              size="small"
              id="outlined-basic"
              placeholder="New Password"
              //   variant="outlined"
              onChange={(e) => {
                setIsFocues(true);
                handlePassword(e.target.value);
              }}
              type={showPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              onKeyDown={handleKeyDown}
            />
            {isFocused ? (
              <>
                {contains8letters ? (
                  <Grid container direction="row" sx={{ mt: '8px' }}>
                    <DoneIcon sx={{ color: '#4F9F52' }} fontSize="small" />
                    <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#4F9F52' }}>
                      contains at least 8 characters
                    </Typography>
                  </Grid>
                ) : (
                  <Grid container direction="row" sx={{ mt: '8px' }}>
                    <CloseIcon sx={{ color: '#E75B5C' }} fontSize="small" />
                    <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#E75B5C' }}>
                      contains at least 8 characters
                    </Typography>
                  </Grid>
                )}
                {containsUpperLowerLetter ? (
                  <Grid container direction="row" sx={{ mt: '8px' }}>
                    <DoneIcon sx={{ color: '#4F9F52' }} fontSize="small" />
                    <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#4F9F52' }}>
                      contains both lower (a-z) and upper case letters (A-Z)
                    </Typography>
                  </Grid>
                ) : (
                  <Grid container direction="row" sx={{ mt: '8px' }}>
                    <CloseIcon sx={{ color: '#E75B5C' }} fontSize="small" />
                    <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#E75B5C' }}>
                      contains both lower (a-z) and upper case letters (A-Z)
                    </Typography>
                  </Grid>
                )}
                {containsSymbolNumber ? (
                  <Grid container direction="row" sx={{ mt: '8px' }}>
                    <DoneIcon sx={{ color: '#4F9F52' }} fontSize="small" />
                    <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#4F9F52' }}>
                      contains at least one number (0-9) or a symbol
                    </Typography>
                  </Grid>
                ) : (
                  <Grid container direction="row" sx={{ mt: '8px' }}>
                    <CloseIcon sx={{ color: '#E75B5C' }} fontSize="small" />
                    <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#E75B5C' }}>
                      contains at least one number (0-9) or a symbol
                    </Typography>
                  </Grid>
                )}
              </>
            ) : null}

            <Typography sx={{ marginTop: '24px', fontSize: '12px', fontWeight: '400' }}>Confirm Password</Typography>
            <OutlinedInput
              sx={{ marginTop: '4px' }}
              fullWidth
              size="small"
              id="outlined-basic"
              placeholder="Confirm Password"
              //   variant="outlined"
              onChange={(e) => {
                if (password === e.target.value && contains8letters && containsUpperLowerLetter && containsSymbolNumber)
                  setIsPasswordSame(true);
                else setIsPasswordSame(false);
                setConfirmPassword(e.target.value);
              }}
              type={showConfirmPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirmPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              onKeyDown={handleKeyDown}
            />
            {isPasswordSame ? (
              <Button
                sx={{
                  backgroundColor: '#2D60F6',
                  borderRadius: '4px',
                  width: '400px',
                  height: '44px',
                  marginTop: '32px',
                }}
                variant="contained"
                onClick={() => handleSubmitSetPassword()}
              >
                Submit
              </Button>
            ) : (
              <Button
                sx={{
                  backgroundColor: '#2D60F6',
                  borderRadius: '4px',
                  width: '400px',
                  height: '44px',
                  marginTop: '32px',
                }}
                variant="contained"
                disabled
                //   onClick={handelSubmit}
              >
                Submit
              </Button>
            )}
            <Box display="flex" justifyContent="center" alignItems="center" sx={{ marginTop: '36px' }}>
              <Stack direction="row">
                <Typography sx={{ fontSize: '14px', fontWeight: '400' }}>Already have an account?</Typography>
                <Typography
                  sx={{ fontSize: '14px', fontWeight: '400', color: '#2D60F6', cursor: 'pointer', marginLeft: '5px' }}
                  onClick={() => navigate('/login')}
                >
                  Sign in
                </Typography>
              </Stack>
            </Box>
            <Grid
              container
              spacing={0}
              direction="row"
              alignItems="center"
              //   justifyContent="center"
              textAlign="center"
              sx={{ marginTop: '32px' }}
            >
              <Grid item>
                <Typography sx={{ fontSize: '12px', color: '#7A7A7A', fontWeight: '400' }}>
                  By clicking on Sign up you agree to our
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  sx={{
                    fontSize: '12px',
                    color: '#2D60F6',
                    cursor: 'pointer',
                    fontWeight: '400',
                    marginLeft: '4px',
                    marginRight: '4px',
                  }}
                >
                  terms
                </Typography>
              </Grid>
              <Grid item>
                <Typography sx={{ fontSize: '12px', color: '#7A7A7A', fontWeight: '400' }}>
                  and you acknowledge
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={0} direction="row" alignItems="center" justifyContent="center" textAlign="center">
              <Grid item>
                <Typography sx={{ fontSize: '12px', color: '#7A7A7A', fontWeight: '400' }}>having read our</Typography>
              </Grid>
              <Grid item>
                <Typography
                  sx={{ fontSize: '12px', color: '#2D60F6', cursor: 'pointer', fontWeight: '400', marginLeft: '4px' }}
                >
                  privacy notice
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ForgotPassword;
