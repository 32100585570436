import * as React from 'react';
import { Formik } from 'formik';
import { Stack, Typography, TextField, TextareaAutosize, FormControl } from '@mui/material';
import CognisaasSelect from '../../../shared/CognisaasSelect/CognisaasSelect';
import { useState, useEffect } from 'react';
import { CognisaaasTooltip } from '../../../shared/CognisaasTooltip/CognisaasTooltip';
// import CognisaasDatePicker from '../../../shared/CognisaasDatePicker/CognisaasDatePicker';
import CognisaasButton from '../../../shared/CognisaasButton/CognisaasButton';
import { selectUsersOfCompany } from '../../../../store/modules/Common/selector';
import { useAppSelector, useAppDispatch } from '../../../../store/hooks';
import { selectJiraPriorityList, selectJiraProjects } from '../../../../store/modules/Task/selector';
import format from 'date-fns/format';
// import { ReactComponent as EmptyMileStone } from '../../../../assets/svgs/EmptyMileStone.svg';
import { taskPriorityValues } from '../../taskUtils';
import { getAllUsers } from '../../../../store/modules/Common/slice';
// import CognisaasCheckBox from '../../../shared/CognisaasCheckbox/CognisaasCheckbox';
import { getLoggedInUserUid } from '../../../../utils/getUid';
// import { useParams } from 'react-router-dom';
import {
  addProjectSubtask,
  getProjectSubtasks,
  addUsecaseSubtask,
  getUsecaseSubtasks,
  addProjectSubtaskAuditTrail,
} from '../../../../store/modules/Task/slice';
import CognisaasCalenderNew from 'components/shared/CognisaasCalenderNew/CognisaasCalenderNew';
import { usePreselectedProjectMembers } from 'components/shared/Hooks/usePreselectedProjectMembers';
import { label_due_date, label_task } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { selectProjectInformation } from 'store/modules/Project/selector';

const NewSubtaskForm = (props: any) => {
  // const [users, setUsers] = useState<any[]>([]);
  //   const [isTeamTask, setIsteamTask] = useState<boolean>(true);
  // const [plannedStartDate, setPlannedStartDate] = useState(new Date());
  // const [actualStartDate, setActualStartDate] = useState(new Date());
  // const [dueDate, setDueDate] = useState(new Date());
  // const [etaForDelivery, setEtaForDelivery] = useState(new Date());
  // const [taskName, setTaskName] = useState<string>('');
  //   const [stage, setStage] = useState<string>('Not Started');
  // const [priority, setPriority] = useState<string>('');
  // const [taskOwner, setTaskOwner] = useState<string>('Not Assigned');
  // const [implementationFee, setImplementationFee] = useState<any | null>(null);
  //   const [taskDescription, setTaskDesription] = useState<string>('');
  const [mileStone, setMileStone] = useState<boolean>(false);
  //   const [shareWithClient, setShareWithClient] = useState<boolean>(false);
  // const [pushToJira, setPushToJira] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  // const params: any = useParams();
  const usersOfCompany = useAppSelector(selectUsersOfCompany);
  const jiraPriorityList = useAppSelector(selectJiraPriorityList);
  const jiraProjectList = useAppSelector(selectJiraProjects);
  const project = useAppSelector(selectProjectInformation);

  const jiraPriorityValues = jiraPriorityList.map((item: any) => item.name);
  const jiraProjectValues = jiraProjectList.map((item: any) => item.jira_project_name);

  const taskDetail = props.taskDetail;
  const close = props.onClose;
  // const mainTaskName = props.header;

  useEffect(() => {
    if (usersOfCompany.length == 0) {
      dispatch(getAllUsers());
      console.log(usersOfCompany);
    }
    return () => {
      console.log(mileStone, 'swswdwdwfefef');

      setMileStone(false);
    };
  }, []);

  // useEffect(() => {
  //   if (usersOfCompany.length > 0) {
  //     getUserListArray();
  //   }
  // }, [usersOfCompany]);

  // const getUserListArray = () => {
  //   const list = usersOfCompany.map((user: any) => {
  //     return user.user_username;
  //   });
  //   console.log(list);
  //   list.push('Not Assigned');
  //   setUsers(list);
  // };

  const { preselectedProjectMembers, preselectedClientContacts } = usePreselectedProjectMembers(taskDetail.project_uid,project?.client_uid);
  const taskOwnerDropdownOptions =
    taskDetail.is_team_task === '1'
      ? preselectedProjectMembers.filter((user: any) => user.is_active === '1').map((user: any) => user.user_username)
      : preselectedClientContacts.map((user: any) => user.user_username);
  // console.log('@SubTask', { preselectedProjectMembers, preselectedClientContacts, taskDetail });
  const notAssignedStr = 'Not Assigned';
  const taskOwnerDropdownOptionsWithNotAssigned = taskOwnerDropdownOptions?.length
    ? [...taskOwnerDropdownOptions, notAssignedStr]
    : [notAssignedStr];

  const pushToJiraContent = () => {
    return (
      <Stack direction="row" spacing={2}>
        <Stack>
          <Typography variant="subtitle2">Jira Project*</Typography>
          <CognisaasSelect placeholder="Select" validValues={[...jiraProjectValues]} />
        </Stack>
        <Stack>
          <Typography variant="subtitle2">Jira Issue Type*</Typography>
          <CognisaasSelect placeholder="Select" validValues={['test1', 'test2']} />
        </Stack>
        <Stack>
          <Typography variant="subtitle2">Jira Priority*</Typography>
          <CognisaasSelect placeholder="Select" validValues={[...jiraPriorityValues]} />
        </Stack>
      </Stack>
    );
  };

  const addSubtaskCreationAudit = (Uid: string) => {
    const data = {
      field: 'Created',
      subtask_uid: Uid,
      user_uid: getLoggedInUserUid(),
      old_value: '',
      new_value: '',
      // old_date: '',
      // new_date: '',
      project_uid: taskDetail.project_uid,
      case_uid: taskDetail.case_uid ? taskDetail.case_uid : null,
      modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
    };

    dispatch(addProjectSubtaskAuditTrail(data));
  };

  const initialValues = {
    taskName: '',
    stage: 'Not Started',
    plannedStartDate: new Date(),
    actualStartDate: new Date(),
    priority: 'Medium',
    taskOwner: 'Not Assigned',
    implementationFee: 0,
    etaForDelivery: new Date(),
    dueDate: new Date(),
    pushToJira: false,
    mileStone: false,
    shareWithClient: false,
    taskDescription: '',
  };
  console.log(taskDetail);
  const saveSubtask = async (values: any) => {
    if (taskDetail.project_uid && !taskDetail.case_uid) {
      const detail = {
        blocked_by_dependency: '0',
        client_check: '',
        contact_uid: '',
        dependency_details: [],
        effort_estimate: 0,
        jira_ind: '',
        jira_issue_type: '',
        jira_priority: '',
        jira_project_id: '',
        // milestone_subtask: 0,
        project_uid: taskDetail.project_uid,
        starting_time: 0,
        subtask_closed_date: format(new Date(values.etaForDelivery), 'yyyy-MM-dd HH:mm:ss'),
        subtask_description: values.taskDescription,
        subtask_detail: values.taskName,
        subtask_due_date: format(new Date(values.dueDate), 'yyyy-MM-dd HH:mm:ss'),
        subtask_planned_start_date: format(new Date(values.plannedStartDate), 'yyyy-MM-dd HH:mm:ss'),
        subtask_owner: values.taskOwner,
        subtask_percentage: '',
        subtask_priority: values.priority,
        subtask_sequence: 1,
        subtask_stage: values.stage,
        subtask_start_date: format(new Date(values.actualStartDate), 'yyyy-MM-dd HH:mm:ss'),
        task_uid: props.taskDetail.task_uid,
        template_subtask_uid: '',
        time_remaining: 0,
        total_time_spent: 0,
        user_uid: getLoggedInUserUid(),
      };
      const res = await dispatch(addProjectSubtask(detail)).unwrap();
      addSubtaskCreationAudit(res);
      await dispatch(getProjectSubtasks(taskDetail.task_uid));
      props.onClose();
    } else {
      const detail = {
        blocked_by_dependency: '0',
        client_check: '',
        contact_uid: '',
        dependency_details: [],
        effort_estimate: 0,
        jira_ind: '',
        jira_issue_type: '',
        jira_priority: '',
        jira_project_id: '',
        // milestone_subtask: 0,
        project_uid: taskDetail.project_uid,
        case_uid: taskDetail.case_uid,
        starting_time: 0,
        subtask_closed_date: format(new Date(values.etaForDelivery), 'yyyy-MM-dd HH:mm:ss'),
        subtask_description: values.taskDescription,
        subtask_detail: values.taskName,
        subtask_due_date: format(new Date(values.dueDate), 'yyyy-MM-dd HH:mm:ss'),
        subtask_planned_start_date: format(new Date(values.plannedStartDate), 'yyyy-MM-dd HH:mm:ss'),
        subtask_owner: values.taskOwner,
        subtask_percentage: '',
        subtask_priority: values.priority,
        subtask_sequence: 1,
        subtask_stage: values.stage,
        subtask_start_date: format(new Date(values.actualStartDate), 'yyyy-MM-dd HH:mm:ss'),
        task_uid: props.taskDetail.task_uid,
        template_subtask_uid: '',
        time_remaining: 0,
        total_time_spent: 0,
        user_uid: getLoggedInUserUid(),
      };
      const res = await dispatch(addUsecaseSubtask(detail)).unwrap();
      addSubtaskCreationAudit(res);
      await dispatch(getUsecaseSubtasks(taskDetail.task_uid));
      props.onClose();
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          saveSubtask({
            ...values,
            plannedStartDate: format(new Date(values.plannedStartDate), 'yyyy-MM-dd HH:mm:ss'),
            actualStartDate: format(new Date(values.actualStartDate), 'yyyy-MM-dd HH:mm:ss'),
            dueDate: format(new Date(values.dueDate), 'yyyy-MM-dd HH:mm:ss'),
            etaForDelivery: format(new Date(values.etaForDelivery), 'yyyy-MM-dd HH:mm:ss'),
          });
        }}
      >
        {(props: any) => (
          <form>
            <Stack ml={2} my={2}>
              <Stack mb={2}>
                <Typography>
                  Add Sub {label_task} to <span className="label-text"> {taskDetail.task_name} </span> {label_task}{' '}
                </Typography>
              </Stack>
              <Typography variant="subtitle2">{label_task} Name</Typography>
              <Stack direction="row">
                <FormControl>
                  <TextField
                    name="taskName"
                    autoComplete="off"
                    onChange={props.handleChange}
                    autoFocus={true}
                    style={{ width: '310px' }}
                    size="small"
                    placeholder={`Enter ${label_task} name`}
                  ></TextField>
                </FormControl>
              </Stack>

              <Stack my={2} spacing={2} direction="row">
                <Stack>
                  <Typography variant="subtitle2">{label_task} Stage</Typography>
                  <FormControl>
                    <CognisaasSelect
                      width="200px"
                      name="stage"
                      onChange={props.handleChange}
                      // handleChange={props.handleChange}
                      value={props.values.stage}
                      validValues={['Not Started', 'In Progress', 'Blocked', 'Done']}
                    ></CognisaasSelect>
                  </FormControl>

                  {/* <CognisaasDropdown width="200px" placeholder={'Not Started'} validvalues={['Not Started', 'In Progress', 'Blocked', 'Done']} /> */}
                </Stack>
                <Stack>
                  <Typography variant="subtitle2">{label_task} Owner</Typography>
                  <CognisaasSelect
                    name="taskOwner"
                    width="200px"
                    value={props.values.taskOwner}
                    onChange={props.handleChange}
                    validValues={taskOwnerDropdownOptionsWithNotAssigned}
                  ></CognisaasSelect>
                  {/* <CognisaasDropdown width="200px" placeholder={'Not Assigned'} validvalues={['Arif', 'Sahil', 'Aman']} /> */}
                </Stack>
                <Stack>
                  <Typography variant="subtitle2">Priority</Typography>
                  <CognisaasSelect
                    name="priority"
                    width="200px"
                    onChange={props.handleChange}
                    // handleChange={(e: any) => setPriority(e.target.value)}
                    value={props.values.priority}
                    validValues={taskPriorityValues()}
                  ></CognisaasSelect>
                  {/* <CognisaasDropdown width="200px" placeholder={'Not Asigned'} validvalues={()} /> */}
                </Stack>
              </Stack>
              <Stack my={2} spacing={2} direction="row">
                <Stack>
                  <Typography variant="subtitle2">Planned Start Date*</Typography>
                  <Stack className="customDatePickerWidth">
                    <CognisaasCalenderNew
                      width="200px"
                      name="plannedStartDate"
                      value={props.values.plannedStartDate}
                      handleChange={(e: any) => props.setFieldValue('plannedStartDate', new Date(e))}
                    />
                  </Stack>
                </Stack>

                <CognisaaasTooltip
                  title={
                    <Typography variant="subtitle1">
                      {label_task} Start date will be system generated when this {label_task} moves to In progress
                    </Typography>
                  }
                >
                  <Stack>
                    <React.Fragment>
                      <Typography variant="subtitle2">Actual Start Date</Typography>
                      <CognisaasCalenderNew
                        disabled={true}
                        width={'200px'}
                        name="actualStartDate"
                        value={props.values.actualStartDate ? new Date(props.values.actualStartDate) : null}
                        handleChange={(e: any) => props.setFieldValue('actualStartDate', new Date(e))}
                      />
                    </React.Fragment>
                  </Stack>
                </CognisaaasTooltip>

                <Stack>
                  <Typography variant="subtitle2">{label_due_date}*</Typography>
                  <CognisaasCalenderNew
                    name="dueDate"
                    width="200px"
                    value={props.values.dueDate}
                    handleChange={(e: any) => props.setFieldValue('dueDate', new Date(e))}
                    // handleChange={(date: Date) => setDueDate(date)}
                  />
                </Stack>
              </Stack>
              <Stack my={2} spacing={2} direction="row">
                <Stack>
                  <Typography variant="subtitle2">ETA for delivery</Typography>
                  <CognisaasCalenderNew
                    width="200px"
                    name="etaForDelivery"
                    value={props.values.etaForDelivery}
                    handleChange={(e: any) => props.setFieldValue('etaForDelivery', new Date(e))}
                  />
                </Stack>
                <Stack>
                  <Typography variant="subtitle2">Implementation Fee</Typography>
                  <TextField
                    name="implementationFee"
                    onChange={props.handleChange}
                    // onChange={(e) => setImplementationFee(e.target.value)}
                    type="number"
                    style={{ width: '200px' }}
                    size="small"
                    placeholder="0%"
                  ></TextField>
                </Stack>
              </Stack>
              {props.values.pushToJira && pushToJiraContent()}
              <Stack my={2} direction="row">
                <Stack direction="column">
                  <Typography variant="subtitle2">{label_task} Description</Typography>
                  <TextareaAutosize
                    name="taskDescription"
                    value={props.values.taskDescription}
                    onChange={props.handleChange}
                    // onChange={(e) => setTaskDesription(e.target.value)}
                    maxRows={15}
                    aria-label="maximum height"
                    placeholder="Enter description here"
                    defaultValue=""
                    style={{ width: 420, height: 88 }}
                  />
                </Stack>
              </Stack>
              {/* <Stack my={2} direction="row" spacing={6}>
                <Stack>
                  <Stack spacing={1} direction="row">
                    <EmptyMileStone />
                    <Typography variant="subtitle2">Its a milestone</Typography>
                  </Stack>
                  <Stack>
                    <Switch name="mileStone" checked={props.values.mileStone} onChange={props.handleChange}></Switch>
                  </Stack>
                </Stack>

                <Stack>
                  <Stack spacing={1} direction="row">
                    <Typography variant="subtitle2">Share with client</Typography>
                  </Stack>
                  <Stack>
                    <Switch
                      name="shareWithClient"
                      checked={props.values.shareWithClient}
                      onChange={props.handleChange}
                    ></Switch>
                  </Stack>
                </Stack>
              </Stack> */}
              <Stack my={2} justifyContent="flex-end" direction="row">
                <Stack mb={2} spacing={1} direction="row">
                  <CognisaasButton isOutlined={true} clickHandler={() => close()} label="Cancel" />
                  <CognisaasButton
                    type="submit"
                    clickHandler={() => {
                      props.handleSubmit();
                    }}
                    isOutlined={false}
                    label="Save"
                  />
                </Stack>
              </Stack>
            </Stack>
          </form>
        )}
      </Formik>
    </>
  );
};

export default NewSubtaskForm;
