import { convertMinutes } from 'components/Templates/TemplatesIndex/UI/Project/AddProjectTemplate/helper';
import { useEffect, useState } from 'react';
import { avatarColors } from 'utils/designSystemColors';

export const useCreatePlanExcelForUpdate = (data: any[], dependencies: any[]) => {
  console.log(data, dependencies);
  const [rows, setRows] = useState<any[]>([]);
  const min = 0;
  const max = 6;

  useEffect(() => {
    if (data.length) {
      makeRows(data);
    }
  }, []);

  const rowT = {
    id: '',
    phase_name: '',
    task_name: '',
    task_type: '',
    duration: '',
    predecessors: '',
    task_owner: '',
    share_with_client: '',
    milestone: '',
    implementation_fee: '',
    task_priority: '',
  };

  // const randomnumber = Math.floor(Math.random() * (maximum - minimum + 1)) + minimum;
  function randomInteger() {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  const sortPhases = (arr: any[]) => {
    console.log(arr);
    const d = JSON.parse(JSON.stringify(arr));
    d.sort((a: any, b: any) => a.template_section_sequence - b.template_section_sequence); // b - a for reverse sort
    console.log(d);
    const tasks: any[] = [];
    d.forEach((phase: any) => {
      phase.task.forEach((t: any) => {
        tasks.push({
          ...t,
          task_priority: t.template_task_priority,
          template_project_uid: phase.template_project_uid,
          template_section_detail: phase.template_section_detail,
          template_section_uid: phase.template_section_uid,
          phase_name: phase.template_section_detail,
          task_name: t.template_task_detail,
          share_with_client: t.template_task_client_check == 1 ? 'Yes' : 'No',
          predecessors: [],
          duration: convertMinutes(t.days_to_start),
          task_type: t.task_type == 'Company task' ? 'Internal' : 'External',
          milestone: t.template_milestone_task == 1 ? 'Yes' : 'No',
          implementation_fee: t.template_task_percentage,
          task_uid: t.template_task_uid,
          section_uid: t.template_section_uid,
          task_owner: t.template_task_owner,
          // id: i,
        });
      });
    });
    // tasks.sort((a: any, b: any) => a.task_sequence - b.task_sequence); // b - a for reverse sort
    console.log(tasks);
    const temp: any[] = [];
    tasks.forEach((task: any, index: number) => {
      temp.push({ ...task, id: index + 1 });
    });

    // Add predecessors
    for (let t = 0; t < temp.length; t++) {
      const dependencyForTask: any = [];
      for (let dep = 0; dep < dependencies.length; dep++) {
        if (temp[t].task_uid == dependencies[dep].task_uid) {
          dependencyForTask.push(dependencies[dep].task_parent_uid);
        }
      }
      const dependencyIndex: any = [];
      dependencyForTask.forEach((d: any) => {
        temp.forEach((t: any) => {
          if (d == t.task_uid) {
            dependencyIndex.push(t.id);
          }
        });
      });
      console.log(dependencyIndex);
      temp[t].predecessors = [...dependencyIndex];
    }

    console.log(temp);

    const tempLength = temp.length + 100;
    for (let i = temp.length + 1; i < tempLength; i++) {
      temp.push({ ...rowT, id: i });
    }

    console.log(temp);

    return temp;
  };

  const makeRows = (data: any[]) => {
    const sortedPhases = sortPhases(data);
    console.log(sortedPhases);
    // for (let i = 0; i < 100; i++) {
    //   sortedPhases.push(rowT);
    // }
    setRows([...sortedPhases]);

    return sortedPhases;
    const temp: any[] = [];
    console.log('MAKE FUNCTIOn');
    data.forEach((item: any, mainIndex: number) => {
      console.log(item.task.length, mainIndex);
      temp.push({
        ...rowT,
        phase_name: item.template_section_detail,
        seq_id: +item.template_section_sequence as number,
        phase_uid: item.template_section_uid,
        color: avatarColors[randomInteger()],
        id: `${item.template_section_uid}^${item.template_section_detail}`,
      });
      [...item.task].forEach((task: any) => {
        temp.push({
          ...rowT,
          id: `${task.template_task_uid}^${task.template_task_detail}`,
          seq_id: +task.task_sequence,
          phase_name: item.template_section_detail,
          task_name: task.template_task_detail,
          task_type: task.task_type == 'Company task' ? 'Internal' : 'External',
          duration: task.days_to_start,
          predecessors: [],
          task_owner: '',
          share_with_client: task.template_task_client_check == 1 ? 'True' : 'False',
          milestone: task.template_milestone_task == 1 ? 'True' : 'False',
          implementation_fee: task.template_task_percentage,
          task_uid: task.template_task_uid,
          section_uid: task.template_section_uid,
          color: temp[temp.length - 1].color,
        });
      });
    });

    console.log(temp);
    setRows([...temp]);
  };

  return rows;
};
