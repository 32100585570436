import { useState, useEffect, useMemo } from 'react';

import { Box, Grid, Stack, Typography, Paper, Button, OutlinedInput, InputAdornment } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';

import { checkAndValidateStatus, getUserInfoByUid, createUserPassword } from '../../store/modules/auth/auth';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../store/hooks';
import { commonAPI } from '../../api/modules/common';

import { ReactComponent as BrandLogo } from '../../assets/svgs/BrandLogo.svg';
// import { ReactComponent as GoogleLogo } from '../../assets/svgs/GoogleLogo.svg';
import { useNavigate } from 'react-router-dom';

import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import {
  selectAllowAccess,
  selectSignupUserData,
  selectSetUserObj,
  selectRedirectToLogin,
  selectpasswordUpdatedState,
} from '../../store/modules/auth/selector';

import { CustomLink } from '../GlobalStyles';
import { requestState } from 'store/modules/types';
import CognisaasToast from 'components/shared/CognisaasToast/CognisaasToast';

const CreatePassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const url = window.location.pathname;
  const url_split = url.split('/');

  const accountId = url_split[2];
  const userId = url_split[3];
  const token = url_split[4];
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isPasswordSame, setIsPasswordSame] = useState(false);
  const [isFocused, setIsFocues] = useState(false);
  const [dataObj, setDataObj] = useState({});
  // const [shouldAllowAccess, setShouldAllowAccess] = useState(false);
  const shouldAllowAccess = useAppSelector(selectAllowAccess);
  const shouldSetUserDataObj = useAppSelector(selectSetUserObj);
  const getUserDataObj: any = useAppSelector(selectSignupUserData);
  const redirectToLoginPage = useAppSelector(selectRedirectToLogin);
  const passwordUpdateState = useAppSelector(selectpasswordUpdatedState);

  const memoizedValue = useMemo(() => dispatch(checkAndValidateStatus(dataObj)), [dataObj]);

  const [contains8letters, setContains8Letters] = useState(false);
  const [containsUpperLowerLetter, setContainsUpperLowerletter] = useState(false);
  const [containsSymbolNumber, setContainsSymbolNumber] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const userData = commonAPI.getUserInfoByUid(userId);
  const [alertDetails, setAlertDetails] = useState<{
    isVisible: boolean;
    type: 'success' | 'failure';
    message: string;
  }>({ isVisible: false, type: 'success', message: '' });

  useEffect(() => {
    console.log('updatteeeee', passwordUpdateState);
    if (passwordUpdateState.status === requestState.failed) {
      const errors = passwordUpdateState.message;
      setAlertDetails({ isVisible: true, type: 'failure', message: errors });
    }
  }, [passwordUpdateState]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const handleKeyDown = (e: any) => {
    if (e.key === ' ') {
      e.preventDefault();
    }
  };

  const handlePassword = (value: string) => {
    const regExpUpper = /[A-Z]/;
    const regExpLower = /[a-z]/;
    const regExpNumber = /[0-9]/;
    const regExpSymbol = /[!@#$%^&*]/;
    console.log(value, 'passnew');
    if (value.length > 7) {
      setContains8Letters(true);
    } else {
      {
        setContains8Letters(false);
      }
    }
    if (regExpUpper.test(value) && regExpLower.test(value)) setContainsUpperLowerletter(true);
    else {
      {
        setContainsUpperLowerletter(false);
      }
    }
    if (regExpNumber.test(value) || regExpSymbol.test(value)) setContainsSymbolNumber(true);
    else {
      {
        setContainsSymbolNumber(false);
      }
    }
    setPassword(value);
    console.log('passed all validations');
  };

  const handleSubmitSetPassword = () => {
    dispatch(createUserPassword({ user_uid: userId, account_id: accountId, token: token, user_password: password }));
    console.log(confirmPassword);
  };
  // setUserDetailsObj(userData);
  console.log(userData, memoizedValue, 'udata');
  useEffect(() => {
    setDataObj({ token_id: userId, token: token });
    console.log(shouldSetUserDataObj, getUserDataObj, 'data of user');
    if (shouldAllowAccess) {
      dispatch(getUserInfoByUid(userId));
    }
    if (redirectToLoginPage) navigate('/login');
  }, [shouldAllowAccess, shouldSetUserDataObj, redirectToLoginPage]);

  return (
    <Box>
      <Grid container>
        <Grid item xs={9} sx={{ backgroundColor: '#F5F5F7', height: '100vh', margin: '0px', paddingLeft: '110px' }}>
          {/* <img style={{ width: '326px', height: '60px', marginTop: '372px' }} src={BrandLogo} alt="COGNISAAS" /> */}
          <BrandLogo style={{ width: '326px', height: '60px', marginTop: '30vh' }} />
          <Typography sx={{ fontSize: '44px', fontWeight: '600', marginTop: '45px', lineHeight: '70.8px' }}>
            Deliver faster time-to-value
          </Typography>
          <Typography sx={{ fontSize: '44px', fontWeight: '600', lineHeight: '70.8px' }}>for your customers</Typography>
          <Typography sx={{ fontSize: '28px', fontWeight: '400', color: '#7A7A7A', lineHeight: '45px' }}>
            by collaborating with cross-functional teams on a
          </Typography>
          <Typography sx={{ fontSize: '28px', fontWeight: '400', color: '#7A7A7A', lineHeight: '45px' }}>
            common platform.
          </Typography>
        </Grid>
        <Grid item xs={3} sx={{ backgroundColor: '#F5F5F7', height: '100vh', margin: '0px', padding: '0px' }}>
          <Paper
            // elevation={1}
            sx={{
              position: 'absolute',
              backgroundColor: '#FFFFFF',
              width: '420px',
              maxHeight: '80vh',
              left: '63vw',
              top: '10vh',
              padding: '30px 15px',
              borderRadius: '4px',
              boxShadow: '0px 4px 26px rgba(0, 0, 0, 0.05);',
              overflowY: 'scroll',
              overflowX: 'hidden',
            }}
          >
            <Typography sx={{ fontSize: '20px', fontWeight: '600' }}>Create Password</Typography>
            <Typography sx={{ marginTop: '24px', fontSize: '12px', fontWeight: '400' }}>Password</Typography>
            <OutlinedInput
              sx={{ marginTop: '4px' }}
              fullWidth
              size="small"
              id="outlined-basic"
              placeholder="Enter Password"
              //   variant="outlined"
              onChange={(e) => {
                setIsFocues(true);
                handlePassword(e.target.value);
              }}
              type={showPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              onKeyDown={handleKeyDown}
            />
            {isFocused ? (
              <>
                {contains8letters ? (
                  <Grid container direction="row" sx={{ mt: '8px' }}>
                    <DoneIcon sx={{ color: '#4F9F52' }} fontSize="small" />
                    <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#4F9F52' }}>
                      contains at least 8 characters
                    </Typography>
                  </Grid>
                ) : (
                  <Grid container direction="row" sx={{ mt: '8px' }}>
                    <CloseIcon sx={{ color: '#E75B5C' }} fontSize="small" />
                    <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#E75B5C' }}>
                      contains at least 8 characters
                    </Typography>
                  </Grid>
                )}
                {containsUpperLowerLetter ? (
                  <Grid container direction="row" sx={{ mt: '8px' }}>
                    <DoneIcon sx={{ color: '#4F9F52' }} fontSize="small" />
                    <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#4F9F52' }}>
                      contains both lower (a-z) and upper case letters (A-Z)
                    </Typography>
                  </Grid>
                ) : (
                  <Grid container direction="row" sx={{ mt: '8px' }}>
                    <CloseIcon sx={{ color: '#E75B5C' }} fontSize="small" />
                    <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#E75B5C' }}>
                      contains both lower (a-z) and upper case letters (A-Z)
                    </Typography>
                  </Grid>
                )}
                {containsSymbolNumber ? (
                  <Grid container direction="row" sx={{ mt: '8px' }}>
                    <DoneIcon sx={{ color: '#4F9F52' }} fontSize="small" />
                    <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#4F9F52' }}>
                      contains at least one number (0-9) or a symbol
                    </Typography>
                  </Grid>
                ) : (
                  <Grid container direction="row" sx={{ mt: '8px' }}>
                    <CloseIcon sx={{ color: '#E75B5C' }} fontSize="small" />
                    <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#E75B5C' }}>
                      contains at least one number (0-9) or a symbol
                    </Typography>
                  </Grid>
                )}
              </>
            ) : null}

            <Typography sx={{ marginTop: '24px', fontSize: '12px', fontWeight: '400' }}>Confirm Password</Typography>
            <OutlinedInput
              sx={{ marginTop: '4px' }}
              fullWidth
              size="small"
              id="outlined-basic"
              placeholder="Confirm Password"
              //   variant="outlined"
              onChange={(e) => {
                if (password === e.target.value && contains8letters && containsUpperLowerLetter && containsSymbolNumber)
                  setIsPasswordSame(true);
                else setIsPasswordSame(false);
                setConfirmPassword(e.target.value);
              }}
              type={showConfirmPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirmPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              onKeyDown={handleKeyDown}
            />
            {isPasswordSame ? (
              <Button
                sx={{
                  backgroundColor: '#2D60F6',
                  borderRadius: '4px',
                  width: '100%',
                  height: '44px',
                  marginTop: '32px',
                }}
                variant="contained"
                onClick={() => handleSubmitSetPassword()}
              >
                Submit
              </Button>
            ) : (
              <Button
                sx={{
                  backgroundColor: '#2D60F6',
                  borderRadius: '4px',
                  width: '100%',
                  height: '44px',
                  marginTop: '32px',
                }}
                variant="contained"
                disabled
                //   onClick={handelSubmit}
              >
                Submit
              </Button>
            )}

            <Box display="flex" justifyContent="center" alignItems="center" sx={{ marginTop: '36px' }}>
              <Stack direction="row">
                <Typography sx={{ fontSize: '14px', fontWeight: '400' }}>Already have an account?</Typography>
                <Typography
                  sx={{ fontSize: '14px', fontWeight: '400', color: '#2D60F6', cursor: 'pointer', marginLeft: '5px' }}
                  onClick={() => navigate('/login')}
                >
                  Sign in
                </Typography>
              </Stack>
            </Box>
            <Grid container sx={{ marginTop: '30px', width: '100%' }}>
              <Typography sx={{ fontSize: '12px', color: '#7A7A7A', fontWeight: '400' }}>
                By clicking on Sign up you agree to our{' '}
                <CustomLink href="https://www.cognisaas.com/terms" target="_blank">
                  terms
                </CustomLink>{' '}
                and you acknowledge
              </Typography>
            </Grid>
            <Grid container spacing={0} direction="row" alignItems="center" justifyContent="center" textAlign="center">
              <Typography sx={{ fontSize: '12px', color: '#7A7A7A', fontWeight: '400' }}>
                having read our{' '}
                <CustomLink href="https://www.cognisaas.com/privacy" target="_blank">
                  privacy notice
                </CustomLink>
              </Typography>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <CognisaasToast
        open={alertDetails.isVisible}
        onClose={() => setAlertDetails({ isVisible: false, type: alertDetails.type, message: '' })}
        message={alertDetails.message}
        type={alertDetails.type}
      />
    </Box>
  );
};

export default CreatePassword;
