import { Stack, Box, Typography } from '@mui/material';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';

interface Props {
  status: 'loading' | 'success' | 'failed';
  message: string;
}

const FullScreenMessages = (props: Props) => {
  return (
    <Stack
      direction={'row'}
      sx={{
        width: '100%',
        height: '80vh',
        color: props.status == 'success' ? 'green' : props.status == 'loading' ? 'orange' : 'red',
      }}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <Box>
        {props.status == 'loading' && <RotateRightIcon />}
        {props.status == 'success' && <DoneAllIcon />}
        {props.status == 'failed' && <ReportGmailerrorredIcon />}
      </Box>
      <Typography variant="h2">&nbsp;&nbsp;{props.message}</Typography>
    </Stack>
  );
};

export default FullScreenMessages;
