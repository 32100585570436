import React, { useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode';
import Cookies from 'universal-cookie';
import { useLocation } from 'react-router-dom';
import CognisaasLoader from 'components/shared/CognisaasLoader/CognisaasLoader';
import { Account, JWTToken } from '../../src/store/types/index';

const env: string = process.env.REACT_APP_ENV ?? 'local';
const cookies = new Cookies();

const PrivateRoute: React.FC = ({ children }): JSX.Element => {
  const location = useLocation();
  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const [requiresSubdomainRedirection, setSubdomainRedirection] = useState(false);
  // let jwtToken;

  useEffect(() => {
    // jwtToken = cookies.get('jwt-token');
    console.log('PrivateRoute jwt token', cookies.get('jwt-token'));
    // console.log('jwt-token', jwtDecode);
    if (cookies.get('jwt-token')) {
      const full = window.location.host;
      const parts = full.split('.');
      //TODO handle for subdomains like xyz.abc.devng.cognisaas.com
      const subdomain = parts[0]; //Example [app].devng.cognisaas.com
      const decoded_token = jwtDecode<JWTToken>(cookies.get('jwt-token'));
      const account = decoded_token.account as Account;
      if (env != 'local' && subdomain !== account.subdomain) {
        console.log('subdomain direction required');
        setSubdomainRedirection(true);
      }
    } else {
      console.log('redirection to login required');
      setRedirectToLogin(true);
    }
  }, []);

  useEffect(() => {
    if (redirectToLogin) {
      console.log('i was in  private route');
      if (env == 'local') {
        document.location.href = 'http://localhost:3000/login';
      } else if (env == 'dev') {
        document.location.href = 'https://app.devng.cognisaas.com/login';
      } else if (env == 'prod') {
        document.location.href = 'https://app.ng.cognisaas.com/login';
      } else if (env == 'new-prod') {
        document.location.href = 'https://app.cognisaas.net/login';
      }
      setRedirectToLogin(false);
    }
  }, [redirectToLogin]);

  useEffect(() => {
    if (requiresSubdomainRedirection) {
      console.log('subdomain redirection');
      const jwtToken = cookies.get('jwt-token');
      const decoded_token = jwtDecode<JWTToken>(jwtToken);
      const account = decoded_token.account as Account;
      if (env == 'local') {
        document.location.href = `http://localhost:3000${location.pathname}`;
      } else if (env == 'dev') {
        document.location.href = `https://${account.subdomain}.devng.cognisaas.com${location.pathname}`;
      } else if (env == 'prod') {
        document.location.href = `https://${account.subdomain}.ng.cognisaas.com${location.pathname}`;
      } else if (env == 'new-prod') {
        document.location.href = `https://${account.subdomain}.cognisaas.net${location.pathname}`;
      }
      setSubdomainRedirection(false);
    }
  }, [requiresSubdomainRedirection]);

  if (cookies.get('jwt-token')) {
    return children as JSX.Element;
  }
  return <CognisaasLoader />;
};

export default PrivateRoute;
