export const INACTIVE_USER_COLOR = '#7a7a7a';
export const INACTIVE_STRING = 'Inactive';

export const isUserInactive = (selcted_user_name: string, users: any[]) => {
  console.log('sad', users, selcted_user_name);
  const foundUser = users.filter((user: any) => user.user_username === selcted_user_name);
  if (foundUser.length > 0) {
    console.log('isactive1', foundUser[0]['is_active'] === '0');
    return foundUser[0]['is_active'] === '0';
  } else {
    console.log('isactive', false);
    return false;
  }
};

export const getUserActiveState = (selcted_user_id: string, users: any[]) => {
  const foundUser = users.filter((user: any) => user.user_uid === selcted_user_id);
  if (foundUser.length > 0) {
    return foundUser[0]['is_active'];
  } else {
    return '1';
  }
};
