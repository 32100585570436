import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../store/hooks';
import { getAllUsers } from 'store/modules/Common/slice';
import { selectUsersOfCompany } from 'store/modules/Common/selector';

const useCompanyUsers = () => {
  const dispatch = useDispatch();
  const userListState = useAppSelector(selectUsersOfCompany);
  const [userList, setUserList] = useState<any>([]);

  useEffect(() => {
    if (userListState.length == 0) {
      dispatch(getAllUsers());
    }
  }, []);

  useEffect(() => {
    setUserList([...userListState, { user_username: 'Not Assigned', user_uid: '15243' }]);
    console.log(userList);
  }, [userListState]);

  return userList;
};

export default useCompanyUsers;
