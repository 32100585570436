import { Mention, MentionsInput } from 'react-mentions';
import { useState, useEffect } from 'react';
import defaultStyle from '../../TaskModule/TaskView/Comment/defaultStyle';

import defaultMentionStyle from '../../TaskModule/TaskView/Comment/defaultMentionStyle';
import { Stack, Button } from '@mui/material';
import { getAllUsers } from '../../../store/modules/Common/slice';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../store/hooks';
import { selectUsersOfCompany } from '../../../store/modules/Common/selector';
import { getUserDetails_slice } from '../../../store/modules/Settings/slice';
import { getLoggedInUserUid, getUserNameByUserUid } from '../../../utils/getUid';
import SaveIcon from '@mui/icons-material/Save';
import {
  addRequirementComment,
  createJiraRequirementComment,
  updateRequirementCommentByUid,
} from '../../../store/modules/ProductRequirements/ProductDetails/slice';
import {
  selectAddedNewRequirementFeatureUid,
  selectCustomerDetailsState,
} from 'store/modules/ProductRequirements/ProductDetails/selector';
import { LoadingButton } from '@mui/lab';

interface commentProps {
  commentDetail?: any;
  clientDetail?: any;
  taskDetail?: any;
  type?: 'Save' | 'Edit';
  data: any;
  setCommentId?: (value: string) => void;
  getRequirementMetaData?: any;
}

const RequirementsCommentTab = (props: commentProps) => {
  const [comment, setComment] = useState<string>(props.commentDetail?.comment || '');
  const type: 'Save' | 'Edit' = props.type === 'Edit' ? 'Edit' : 'Save';
  const customerDetailState = useAppSelector(selectCustomerDetailsState);

  const [commentText, setCommentText] = useState<string>('');
  const [users, setUsers] = useState<any[]>([]);
  const [mentions, setMentions] = useState<any[]>([]);

  const dispatch = useDispatch();
  const usersOfCompany = useAppSelector(selectUsersOfCompany);
  const addedCommentFeatureUid = useAppSelector(selectAddedNewRequirementFeatureUid);
  const [isCommentAddingInProgress, setIsCommentAddingInProgress] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getUserDetails_slice(getLoggedInUserUid()));
  }, []);

  const addrequirementCommentToJira = async () => {
    const key = btoa(
      btoa(customerDetailState.data.customer_jira_user + ':' + customerDetailState.data.customer_jira_token)
    );
    await dispatch(
      createJiraRequirementComment({
        customer_uid: props.data.customer_uid,
        feature_comment_uid: addedCommentFeatureUid.data,
        feature_uid: props.data.feature_uid,
        jira_issue_id: props.data.feature_issue_id,
        key: key,
      })
    );
    props.getRequirementMetaData(props.data.feature_uid);
  };

  useEffect(() => {
    if (addedCommentFeatureUid.data.length > 0) {
      addrequirementCommentToJira();
    }
  }, [addedCommentFeatureUid]);

  useEffect(() => {
    if (usersOfCompany.length == 0) {
      dispatch(getAllUsers());
    }
  }, []);

  useEffect(() => {
    if (usersOfCompany.length > 0) {
      getUserListArray();
    }
  }, [usersOfCompany]);

  const getUserListArray = () => {
    const list = usersOfCompany.map((user: any) => ({ display: user.user_username, id: `company:${user.user_uid}` }));
    setUsers(list);
  };

  const detailsForSavingComent = () => {
    const detail = {
      comment_uid:
        props.type == 'Save'
          ? props.commentDetail?.comment_uid || ''
          : props.type == 'Edit'
          ? props.commentDetail.feature_comment_uid
          : '',
      requirement_uid: props.data?.feature_uid,
      comment: comment,
      user_username: getUserNameByUserUid(getLoggedInUserUid()),
      user_uid: getLoggedInUserUid(),
      value: commentText,
    };
    const mentioned = [];
    for (let i = 0; i < mentions.length; i++) {
      const mention = {
        display: mentions[i].display,
        id: mentions[i].id.split(':')[0],
        type: mentions[i].id.split(':')[1],
      };
      mentioned.push(mention);
    }

    const commentDetail = {
      opost: detail,
      userlistmentions: [...mentioned],
    };
    return commentDetail;
  };

  const saveComment = async () => {
    const commentDetail = detailsForSavingComent();
    await dispatch(addRequirementComment(commentDetail.opost));
  };

  const updateComment = async () => {
    const commentDetail = detailsForSavingComent();
    await dispatch(updateRequirementCommentByUid(commentDetail.opost));
    await dispatch(props.getRequirementMetaData(props.data.product_uid));
  };

  const handleSaveButton = async () => {
    setIsCommentAddingInProgress(true);
    if (type === 'Edit') {
      await updateComment();
    } else if (type === 'Save') {
      await saveComment();
    }
    if (props.setCommentId) {
      props.setCommentId('');
    }
    setComment('');
    setIsCommentAddingInProgress(false);
  };

  const handleCancelButton = () => {
    if (props.setCommentId) {
      props.setCommentId('');
    }
    setComment('');
  };

  const LoadingSendButton = () => {
    return (
      <LoadingButton size="small" loading={true} loadingPosition="start" startIcon={<SaveIcon />} variant={'text'}>
        Saving
      </LoadingButton>
    );
  };

  return (
    <>
      <Stack sx={{ width: '100%' }}>
        <MentionsInput
          style={defaultStyle}
          value={comment}
          placeholder="Type here"
          className="mention-input"
          onChange={(e: any, newValue: any, newPlainTextValue: any, mentions: any) => {
            setCommentText(newPlainTextValue);
            setComment(e.target.value);
            setMentions([...mentions]);
          }}
        >
          <Mention style={defaultMentionStyle} trigger="@" data={[...users]} />
        </MentionsInput>
        <Stack justifyContent={'flex-end'} direction="row">
          <Stack direction="row">
            {isCommentAddingInProgress ? (
              <LoadingSendButton />
            ) : (
              <>
                <Button variant="text" onClick={() => handleSaveButton()}>
                  Save
                </Button>
                <Button variant="text" onClick={() => handleCancelButton()}>
                  Cancel
                </Button>
              </>
            )}
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default RequirementsCommentTab;
