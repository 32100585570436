import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { projectAPI } from '../../../../api/modules/project';

interface closedProjectsType {
  closedProjects: Array<any>;
  status: 'idle' | 'loading' | 'success' | 'failed';
  error: null | undefined | string;
}

export const fetchClosedProjects = createAsyncThunk('/closedProjects', async () => {
  const { data } = await projectAPI.getClosedProjects();
  return data.result ?? [];
});

const initialState: closedProjectsType = {
  closedProjects: [],
  status: 'idle',
  error: null,
};

const closedProjectsSlice = createSlice({
  name: 'closedProjects',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchClosedProjects.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchClosedProjects.fulfilled, (state, action) => {
        state.status = 'success';
        state.closedProjects = action.payload;
      })
      .addCase(fetchClosedProjects.rejected, (state, { error }) => {
        state.error = error.message;
        state.status = 'failed';
      });
  },
});
export default closedProjectsSlice.reducer;
