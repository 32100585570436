import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { projectAPI } from '../../../api/modules/project';
import { clientAPI } from '../../../api';
import { requestState } from '../types';
import { AddProjectCommentType } from './types';
import { isNull } from 'utils/dataHelpers';

interface projectsType {
  getAllProjectsList: Array<any>;
  getAllProjectsStatus: requestState;
  getAllProjectsError: null | undefined | string;

  getProjectsOfClient: Array<any>;
  getprojectsOfClientStatus: requestState;
  getProjectsOfClientError: null | undefined | string;

  projectCustomFields: Array<any>;
  getProjectCustomFieldsStatus: requestState;
  getProjectCustomFieldsError: null | undefined | string;

  projectCustomFieldsDetail: Array<any>;
  getProjectCustomFieldsDetailStatus: requestState;
  getProjectCustomFieldsDetailError: null | undefined | string;

  updateProjectCustomFieldStatus: requestState;
  updateProjectCustomFieldError: null | undefined | string;

  selectedProjectInformation: any;
  selectedProjectInformationStatus: requestState;
  selectedProjectInformationError: null | undefined | string;

  selectedProjectInformationForGantt: any;
  selectedProjectInformationStatusForGantt: requestState;
  selectedProjectInformationErrorForGantt: null | undefined | string;

  getProjectAudit: any;
  getProjectAuditStatus: requestState;
  getProjectAuditError: null | undefined | string;

  getProjectMembers: any[];
  getProjectMembersStatus: requestState;
  getProjectMembersError: null | undefined | string;

  selectedProject: null | any;

  highlightCriticalPathInProjectGantt: boolean;
  highlightCriticalPathInUsecaseGantt: boolean;

  allProjectCustomFields: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };

  allProjectComments: {
    data: any[];
    status: requestState;
    message: null | undefined | string;
  };
  opportunitiesOfClient: {
    data: any[];
    status: requestState;
    message: null | undefined | string;
  };
}

const initialState: projectsType = {
  getAllProjectsList: [],
  getAllProjectsStatus: requestState.idle,
  getAllProjectsError: null,

  getProjectsOfClient: [],
  getprojectsOfClientStatus: requestState.idle,
  getProjectsOfClientError: null,

  projectCustomFields: [],
  getProjectCustomFieldsStatus: requestState.idle,
  getProjectCustomFieldsError: null,

  projectCustomFieldsDetail: [],
  getProjectCustomFieldsDetailStatus: requestState.idle,
  getProjectCustomFieldsDetailError: null,

  updateProjectCustomFieldStatus: requestState.idle,
  updateProjectCustomFieldError: null,

  selectedProjectInformation: {},
  selectedProjectInformationStatus: requestState.idle,
  selectedProjectInformationError: null,

  selectedProjectInformationForGantt: {},
  selectedProjectInformationStatusForGantt: requestState.idle,
  selectedProjectInformationErrorForGantt: null,

  getProjectAudit: [],
  getProjectAuditStatus: requestState.idle,
  getProjectAuditError: null,

  getProjectMembers: [],
  getProjectMembersStatus: requestState.idle,
  getProjectMembersError: null,

  selectedProject: null,
  highlightCriticalPathInProjectGantt: false,
  highlightCriticalPathInUsecaseGantt: false,

  allProjectCustomFields: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  allProjectComments: {
    data: [],
    status: requestState.idle,
    message: null,
  },

  opportunitiesOfClient: {
    data: [],
    status: requestState.idle,
    message: null,
  },
};

export const getProjectByProjectUid = createAsyncThunk(
  'projects.getProjectByprojectUid',
  async (project_uid: string) => {
    const { data } = await projectAPI.getProjectByUid(project_uid);
    return data.result ?? [];
  }
);

export const updateTaskDelay = createAsyncThunk('updateTaskDelay', async (detail: any) => {
  const { data } = await projectAPI.updateTaskDelay(detail.task_uid, detail.delayed_by);
  return data.result ?? [];
});

export const getTasksPathOfProject = createAsyncThunk('getTasksPathOfProject', async (detail: any) => {
  const { data } = await projectAPI.getTasksPath(detail.project_uid, detail.type);
  return data.result ?? [];
});

export const handleProjectPlannedDateUpdate = createAsyncThunk(
  'handleProjectPlannedDateUpdate',
  async (detail: any) => {
    const { data } = await projectAPI.handleProjectPlannedDateUpdate(detail);
    return data.result ?? [];
  }
);

export const getTaskParents = createAsyncThunk('getTaskParents', async (detail: any) => {
  const { data } = await projectAPI.getTaskParents(detail);
  return data.result ?? [];
});

export const getTaskChildren = createAsyncThunk('getTaskChildren', async (detail: any) => {
  const { data } = await projectAPI.getTaskChildren(detail);
  return data.result ?? [];
});

export const getPathOfThisTask = createAsyncThunk('getTasksPathOfProject', async (detail: any) => {
  const { data } = await projectAPI.getPathOfThisTask(detail.task_uid, detail.project_uid, detail.type);
  return data.result ?? [];
});

export const updateEndDatesOfProject = createAsyncThunk('updateProjectEndDates', async (detail: any) => {
  const { data } = await projectAPI.updateProjectEndDates(detail);
  return data.result ?? [];
});

export const getAllProjectsOfCustomer = createAsyncThunk('projects/getAllProjects', async () => {
  const { data } = await projectAPI.getAllProjects();
  console.log(data);
  return data.result ?? [];
});

// This api gives all projects of client need an new api
export const getProjectsOfClient = createAsyncThunk('projects/getclientProjects', async (client_uid: string) => {
  const { data } = await clientAPI.getClientInformation(client_uid);
  console.log(data);
  return data.result ?? [];
});

export const getProjectCustomFields = createAsyncThunk('project/customfields', async (project_uid: string) => {
  const { data } = await projectAPI.getCustomFieldsOfProject(project_uid);
  console.log(data);
  return data.result ?? [];
});

export const getProjectCustomFieldsDetail = createAsyncThunk('project/customfiedlsDetails', async () => {
  const { data } = await projectAPI.getProjectCustomFieldsDetail();
  return data.result ?? [];
});

export const updateProjectCustomField = createAsyncThunk('project/updateCustomField', async (detail: any) => {
  await projectAPI.updateProjectCustomField(detail);
});

export const getProjectInformation = createAsyncThunk('project/getProjectInformnation', async (detail: any) => {
  const { data } = await projectAPI.getProjectInformation(detail.association_uid, detail.project_uid);
  console.log(data);
  return data.result ?? [];
});

export const getProjectInformationWithCaseTaskByUid = createAsyncThunk(
  'project/getProjectInformationWithCaseTaskByuid',
  async (detail: any) => {
    const { data } = await projectAPI.getProjectInformationWithCaseTaskByUid(detail.project_uid);
    console.log(data);
    return data.result;
  }
);

export const addProjectAuditTrial = createAsyncThunk('project/addProjectAuditTrial', async (detail: any) => {
  const { data } = await projectAPI.addProjectAuditTrial(detail);
  console.log(data);
});

export const deleteProject = createAsyncThunk('project/deleteProject', async (detail: any) => {
  await projectAPI.deleteProject(detail);
});

export const updateProject = createAsyncThunk('project/updateProject', async (detail: any) => {
  await projectAPI.updateProject(detail);
});

export const getProjectAudit = createAsyncThunk('project.getProjectAudit', async (project_uid: string) => {
  const { data } = await projectAPI.getProjectAudit(project_uid);
  return data.result;
});

export const deleteBlankProject = createAsyncThunk('project/deleteBlankProject', async () => {
  await projectAPI.deleteBlankProject();
});

export const getProjectMembers = createAsyncThunk('project/getProjectMembers', async (details: any) => {
  const { data } = await projectAPI.getProjectMembers(details.project_uid, details.client_uid);
  return data.result ?? [];
});

export const addMembersForProject = createAsyncThunk('project/addMembersForProject', async (detail: string) => {
  const { data } = await projectAPI.addMembersForProject(detail);
  return data.result;
});

export const removeUserFromProject = createAsyncThunk('project/removeMembersForProject', async (detail: string) => {
  const { data } = await projectAPI.removeUserFromProject(detail);
  return data.result;
});

export const getAllProjectCustomFields = createAsyncThunk('project/getAllProjectCustomFields', async () => {
  const { data } = await projectAPI.getCfForAllProjectsByCustomerUid();
  return data;
});

export const getAllProjectComments = createAsyncThunk('project/getAllProjectComments', async (project_uid: string) => {
  const { data } = await projectAPI.getProjectComments(project_uid);
  return data;
});

export const addProjectComment = createAsyncThunk(
  'project/addProjectComment',
  async (projectCommentPayload: AddProjectCommentType) => {
    const { data } = await projectAPI.addProjectCommentByProjectUid(projectCommentPayload);
    return data;
  }
);

export const updateProjectComment = createAsyncThunk(
  'project/updateProjectComment',
  async (projectCommentPayload: AddProjectCommentType) => {
    const { data } = await projectAPI.updateProjectLevelCommentDetails(projectCommentPayload);
    return data;
  }
);

export const deleteProjectComment = createAsyncThunk('project/deleteProjectComment', async (comment_uid: string) => {
  const { data } = await projectAPI.deleteProjectLevelComment({ comment_uid });
  return data;
});

export const getOpportunitiesOfClient = createAsyncThunk(
  'project/getOpportunitiesOfClient',
  async (client_uid: string) => {
    const { data } = await projectAPI.getOpportunitiesOfCLient(client_uid);
    return data.result ?? [];
  }
);

export const cloneLiveProject = createAsyncThunk('project/cloneLiveProjectOfClient', async (detail: any) => {
  const { data } = await projectAPI.cloneLiveProjectOfClient(detail);
  return data;
});

export const findCriticalPath = createAsyncThunk('project/findCriticalPath', async (detail: any) => {
  const { data } = await projectAPI.findCriticalPath(detail.project_uid, detail.type);
  return data;
});

const projectSlice = createSlice({
  name: 'projectSlice',
  initialState,
  reducers: {
    selectedProjectForprojectDashboard: (state, action: PayloadAction<any>) => {
      state.selectedProject = { ...action.payload };
    },
    resetGetProjectComments: (state) => {
      state.allProjectComments = initialState.allProjectComments;
    },
    resetSelectedProject: (state) => {
      state.selectedProject = null;
    },
    resetGetAllProjects: (state) => {
      state.getAllProjectsList = [];
    },
    toggleCriticalPathHighlightInProjectGantt: (state) => {
      state.highlightCriticalPathInProjectGantt = !state.highlightCriticalPathInProjectGantt;
    },
    toggleCriticalPathHighlightInUsecaseGantt: (state) => {
      state.highlightCriticalPathInUsecaseGantt = !state.highlightCriticalPathInUsecaseGantt;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAllProjectsOfCustomer.pending, (state) => {
        state.getAllProjectsStatus = requestState.loading;
      })
      .addCase(getAllProjectsOfCustomer.fulfilled, (state, action) => {
        state.getAllProjectsStatus = requestState.success;
        console.log(action);

        state.getAllProjectsList = [...action.payload];
      })
      .addCase(getAllProjectsOfCustomer.rejected, (state, action) => {
        state.getAllProjectsStatus = requestState.failed;
        state.getAllProjectsError = action.error.message;
      })

      // get projects of client
      .addCase(getProjectsOfClient.pending, (state) => {
        state.getprojectsOfClientStatus = requestState.loading;
      })
      .addCase(getProjectsOfClient.fulfilled, (state, action) => {
        state.getprojectsOfClientStatus = requestState.success;
        console.log(action);

        state.getProjectsOfClient = [...action.payload];
      })
      .addCase(getProjectsOfClient.rejected, (state, action) => {
        state.getprojectsOfClientStatus = requestState.failed;
        state.getProjectsOfClientError = action.error.message;
      })

      // get project custom fields

      .addCase(getProjectCustomFields.pending, (state) => {
        state.getprojectsOfClientStatus = requestState.loading;
      })
      .addCase(getProjectCustomFields.fulfilled, (state, action) => {
        state.getProjectCustomFieldsStatus = requestState.success;
        console.log(action);

        state.projectCustomFields = [...action.payload];
      })
      .addCase(getProjectCustomFields.rejected, (state, action) => {
        state.getProjectCustomFieldsStatus = requestState.failed;
        state.getProjectCustomFieldsError = action.error.message;
      })

      // custom foedls detail
      .addCase(getProjectCustomFieldsDetail.pending, (state) => {
        state.getProjectCustomFieldsDetailStatus = requestState.loading;
      })
      .addCase(getProjectCustomFieldsDetail.fulfilled, (state, action) => {
        state.getProjectCustomFieldsDetailStatus = requestState.success;
        console.log(action);

        state.projectCustomFieldsDetail = [...action.payload];
      })
      .addCase(getProjectCustomFieldsDetail.rejected, (state, action) => {
        state.getProjectCustomFieldsDetailStatus = requestState.failed;
        state.getProjectCustomFieldsDetailError = action.error.message;
      })

      // update custom fields
      .addCase(updateProjectCustomField.pending, (state) => {
        state.updateProjectCustomFieldStatus = requestState.loading;
      })
      .addCase(updateProjectCustomField.fulfilled, (state) => {
        state.updateProjectCustomFieldStatus = requestState.success;
      })
      .addCase(updateProjectCustomField.rejected, (state, action) => {
        state.updateProjectCustomFieldError = action.error.message;
      })

      // get Single project Detail
      .addCase(getProjectInformation.pending, (state) => {
        state.selectedProjectInformationStatus = requestState.loading;
      })
      .addCase(getProjectInformation.fulfilled, (state, action: any) => {
        state.selectedProjectInformationStatus = requestState.success;
        const data = action.payload;
        data?.usecase?.forEach((usecase: any) => {
          usecase?.section?.forEach((section: any) => {
            section?.task?.sort((a: any, b: any) => a?.task_sequence - b?.task_sequence);
          });
        });
        state.selectedProject = JSON.parse(JSON.stringify(data));
      })
      .addCase(getProjectInformation.rejected, (state, action) => {
        state.selectedProjectInformationError = action.error.message;
      })

      // get Single project Detail for gantt with section dates
      .addCase(getProjectInformationWithCaseTaskByUid.pending, (state) => {
        state.selectedProjectInformationStatusForGantt = requestState.loading;
      })
      .addCase(getProjectInformationWithCaseTaskByUid.fulfilled, (state, action: any) => {
        state.selectedProjectInformationStatusForGantt = requestState.success;
        state.selectedProjectInformationForGantt = { ...action.payload };
      })
      .addCase(getProjectInformationWithCaseTaskByUid.rejected, (state, action) => {
        state.selectedProjectInformationErrorForGantt = action.error.message;
      })

      // Get Opportunities OF client
      .addCase(getOpportunitiesOfClient.pending, (state) => {
        state.opportunitiesOfClient.status = requestState.loading;
      })
      .addCase(getOpportunitiesOfClient.fulfilled, (state, action: any) => {
        state.opportunitiesOfClient.status = requestState.success;
        state.opportunitiesOfClient.data = [...action.payload];
      })
      .addCase(getOpportunitiesOfClient.rejected, (state, action) => {
        state.opportunitiesOfClient.message = action.error.message;
      })

      // get project audit
      .addCase(getProjectAudit.pending, (state) => {
        state.getProjectAuditStatus = requestState.loading;
      })
      .addCase(getProjectAudit.fulfilled, (state, action: any) => {
        state.getProjectAuditStatus = requestState.success;
        if (action.payload) {
          state.getProjectAudit = [...action.payload];
        } else {
          state.getProjectAudit = [];
        }
      })
      .addCase(getProjectAudit.rejected, (state, action) => {
        state.getProjectAuditError = action.error.message;
      })

      // get project members
      .addCase(getProjectMembers.pending, (state) => {
        state.getProjectMembersStatus = requestState.loading;
      })
      .addCase(getProjectMembers.fulfilled, (state, action: any) => {
        state.getProjectMembersStatus = requestState.success;
        if (action.payload) {
          state.getProjectMembers = [...action.payload];
        } else {
          state.getProjectMembers = [];
        }
      })
      .addCase(getProjectMembers.rejected, (state, action) => {
        state.getProjectMembersError = action.error.message;
      })
      .addCase(getAllProjectCustomFields.pending, (state) => {
        state.allProjectCustomFields.status = requestState.loading;
      })
      .addCase(getAllProjectCustomFields.fulfilled, (state, action: any) => {
        state.allProjectCustomFields.status = requestState.success;
        if (action.payload.result) {
          state.allProjectCustomFields.data = action.payload.result;
        } else {
          state.allProjectCustomFields.data = [];
        }
      })
      .addCase(getAllProjectCustomFields.rejected, (state, action) => {
        state.allProjectCustomFields.error = action.error.message;
      })
      .addCase(getAllProjectComments.pending, (state) => {
        state.allProjectComments.status = requestState.loading;
      })
      .addCase(getAllProjectComments.fulfilled, (state, action: any) => {
        state.allProjectComments.status = requestState.success;
        if (!isNull(action.payload.result)) {
          state.allProjectComments.data = action.payload.result;
        } else {
          state.allProjectComments.data = [];
        }
      })
      .addCase(getAllProjectComments.rejected, (state, action) => {
        state.allProjectComments.message = action.error.message;
      });
  },
});

export const projectsReducer = projectSlice.reducer;
export const {
  selectedProjectForprojectDashboard,
  resetGetProjectComments,
  resetSelectedProject,
  resetGetAllProjects,
  toggleCriticalPathHighlightInProjectGantt,
  toggleCriticalPathHighlightInUsecaseGantt,
} = projectSlice.actions;
export default projectsReducer;
