import React, { SyntheticEvent, useRef, useEffect } from 'react';
import styles from './vertical-scroll.module.css';

export const VerticalScroll: React.FC<{
  scroll: number;
  ganttHeight: number;
  ganttFullHeight: number;
  headerHeight: number;
  onScroll: (event: SyntheticEvent<HTMLDivElement>) => void;
}> = ({
  scroll,
  ganttHeight,
  ganttFullHeight,
  headerHeight,
  onScroll,
}) => {
    const scrollRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (scrollRef.current) {
        scrollRef.current.scrollTop = scroll;
      }
    }, [scroll]);

    return (
      <div
        style={{
          height: ganttHeight,
          marginTop: headerHeight,
          marginLeft: '-17px',
        }}
        className={styles.scroll}
        onScroll={onScroll}
        ref={scrollRef}
      >
        <div style={{ height: ganttFullHeight, width: 1 }} />
      </div>
    );
  };
