import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../index';

export const selectCommonState = (state: RootState) => state.commonState;

export const selectUsersOfCompany = createSelector(selectCommonState, (commonState) => commonState.getAllUsers);
export const selectClientContactsOfCompany = createSelector(
  selectCommonState,
  (commonState) => commonState.getAllClientContacts
);
export const selectClientContactsOfCompanyStatus = createSelector(
  selectCommonState,
  (commonState) => commonState.getAllClientContactsStatus
);
export const selectAllClientsByCustomerUid = createSelector(
  selectCommonState,
  (commonState) => commonState.clientsByCustomerUid
);
export const selectUsersOfCompanyStatus = createSelector(
  selectCommonState,
  (commonState) => commonState.getAllUsersStatus
);
export const selectCustomerDetail = createSelector(selectCommonState, (commonState) => commonState.getCustomerDetail);

export const selectCustomFields = createSelector(selectCommonState, (commonState) => commonState.getCustomFields);

export const selectCustomerCfs = createSelector(selectCommonState, (commonState) => commonState.customerCustomfields);

export const selectUsecaseCfsWithSubFields = createSelector(
  selectCommonState,
  (commonState) => commonState.usecaseCustomFieldsWithSubFields
);

// segments
export const selectSegmentValues = createSelector(selectCommonState, (commonState) => commonState.segmentValues);

// journey stage
export const selectJourneyStage = createSelector(selectCommonState, (commonState) => commonState.journeyStageValues);

// all idustries
export const selectAllIndustries = createSelector(selectCommonState, (commonState) => commonState.industryValues);

// milestone statues
export const selectAllMilestoneStatus = createSelector(
  selectCommonState,
  (commonState) => commonState.milestoneStatusValues
);

//renewal stages
export const selectAllRenewalStatus = createSelector(selectCommonState, (commonState) => commonState.AllRenewalStages);

// all general status - red, amber green, blue like
export const selectAllGeneralStatus = createSelector(selectCommonState, (commonState) => commonState.allGeneralStatus);

// all categories
export const selectAllCategories = createSelector(selectCommonState, (commonState) => commonState.allCategories);

export const selectTaskCustomFields = createSelector(selectCommonState, (commonState) => commonState.taskCustomFields);

export const selectTaskCustomFieldsStatus = createSelector(
  selectCommonState,
  (commonState) => commonState.taskCustomFields.status
);

export const selectAllTaskStages = createSelector(selectCommonState, (commonState) => commonState.allTaskStages);
export const selectAllTaskPriorities = createSelector(
  selectCommonState,
  (commonState) => commonState.allTaskPriorities
);

export const selectCurrentUserDetails = createSelector(
  selectCommonState,
  (commonState) => commonState.currentUserDetails
);

// global labels of client, project etc
export const selectAllCustomLabels = createSelector(selectCommonState, (commonState) => commonState.GlobalFieldLabels);

// allProjectMembersWhichAreClientContact
export const selectAllProjectMembersForClientContact = createSelector(
  selectCommonState,
  (commonState) => commonState.allProjectMembersWhichAreClientContact
);

export const selectCustomSegmentValues = createSelector(
  selectCommonState,
  (commonState) => commonState.customSegmentValues
);

export const selectProjectCategories = createSelector(
  selectCommonState,
  (commonState) => commonState.projectCategoryValues
);

export const selectCustomProjectCategories = createSelector(
  selectCommonState,
  (commonState) => commonState.customProjectCategoryValues
);

export const selectProjectStages = createSelector(selectCommonState, (commonState) => commonState.projectStageValues);

export const selectCustomProjectStage = createSelector(
  selectCommonState,
  (commonState) => commonState.customProjectStageValues
);

// usecase
export const selectUsecaseCategories = createSelector(
  selectCommonState,
  (commonState) => commonState.usecaseCategoryValues
);

export const selectCustomUsecaseCategories = createSelector(
  selectCommonState,
  (commonState) => commonState.customUsecaseCategoryValues
);

export const selectUsecaseStages = createSelector(selectCommonState, (commonState) => commonState.usecaseStageValues);

export const selectCustomUsecaseStage = createSelector(
  selectCommonState,
  (commonState) => commonState.customUsecaseStageValues
);

export const selectCustomClientJourneyStage = createSelector(
  selectCommonState,
  (commonState) => commonState.customJourneyStageValues
);

export const selectAppConfigurationsState = createSelector(selectCommonState, (commonState) => commonState.appStates);

export const selectProjectPriorities = createSelector(
  selectCommonState,
  (commonState) => commonState.projectPriorityValues
);

export const selectCustomProjectPriorities = createSelector(
  selectCommonState,
  (commonState) => commonState.customProjectPriorityValues
);

export const selectUsecasePriorities = createSelector(
  selectCommonState,
  (commonState) => commonState.usecasePriorityValues
);

export const selectCustomUsecasePriorities = createSelector(
  selectCommonState,
  (commonState) => commonState.customUsecasePriorityValues
);

export const selectTaskPriorities = createSelector(selectCommonState, (commonState) => commonState.taskPriorityValues);

export const selectCustomTaskPriorities = createSelector(
  selectCommonState,
  (commonState) => commonState.customTaskPriorityValues
);

export const selectTaskStages = createSelector(selectCommonState, (commonState) => commonState.taskStageValues);

export const selectCustomtaskStage = createSelector(
  selectCommonState,
  (commonState) => commonState.customTaskStageValues
);

export const selectAggregatedCardsState = createSelector(
  selectCommonState,
  (commonState) => commonState.allAggregatedCards
);
