import jwtDecode from 'jwt-decode';
import { JWTToken } from '../../../store/types/index';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const usePageAccess = () => {
  let pages: any = [];
  const jwt = cookies.get('jwt-token');
  if (jwt) {
    const decodedToken = jwtDecode<JWTToken>(jwt as string);
    pages = decodedToken.pages.map((page: any) => {
      if (page.hasAccess) {
        return page.url;
      }
    });
  }

  return pages;
};

export default usePageAccess;
