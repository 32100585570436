import styled from 'styled-components';

interface ColourBoxType {
    colour: string;
}

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, 20px);
    gap:12px;
`;

export const ColourBox = styled.div`
    background-color: ${(props: ColourBoxType) => props.colour ? props.colour: '#000'};
    width:20px;
    height: 20px;
    cursor: pointer;
`;

export const Title = styled.p`
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 10px;
`;