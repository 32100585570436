import { IconButton, Stack, SxProps, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { ReactComponent as EditPen } from '../../../assets/svgs/edit_pen.svg';
import { ReactComponent as Tick } from '../../../assets/svgs/tick.svg';
import { ReactComponent as Cross } from '../../../assets/svgs/cross.svg';

interface Props {
  value: string;
  viewType: 'text-only' | 'disabled-input';
  handleSave: (newValue: string) => void;
  containerSx?: SxProps;
  disableEditPenButton: boolean;
  customEditingModeOperation: {
    enableEditingMode: boolean;
    setEnabledEditingMode: React.Dispatch<React.SetStateAction<boolean>>;
  } | null;
}

const EditableInlineTextField = (props: Props) => {
  const [initialValue, setInitialValue] = useState<string>(props.value);
  const [textValue, setTextValue] = useState<string>(props.value);
  const [isEditingModeEnabled, setIsEditingModeEnabled] = useState(false);

  const textFieldColorForDisabled = 'black';
  console.log(isEditingModeEnabled);

  useEffect(() => {
    setInitialValue(props.value);
    setTextValue(props.value);
  }, [props.value]);

  useEffect(() => {
    if (props.customEditingModeOperation && props.customEditingModeOperation.enableEditingMode) {
      setIsEditingModeEnabled(props.customEditingModeOperation && props.customEditingModeOperation.enableEditingMode);
    }
  }, [props.customEditingModeOperation]);

  const handleStopEditing = () => {
    setIsEditingModeEnabled(false);
    setTextValue(initialValue);
    if (props.customEditingModeOperation) props.customEditingModeOperation.setEnabledEditingMode(false);
  };

  return (
    <Stack direction={'row'} spacing={1} alignItems={'center'} sx={props.containerSx}>
      {props.viewType == 'text-only' && !isEditingModeEnabled && <Typography variant="h2">{initialValue}</Typography>}

      {props.viewType == 'disabled-input' && !isEditingModeEnabled && (
        <TextField
          size="small"
          sx={{
            input: {
              '-webkit-text-fill-color': `${textFieldColorForDisabled} !important`,
              color: `${textFieldColorForDisabled} !important`,
              border: '1px solid #E5E5E5',
            },
            width: '310px',
          }}
          value={initialValue}
          disabled={true}
          InputProps={{
            sx: {
              background: '#F3F3F3',
              borderRadius: '4px',
              color: 'white',
            },
          }}
          inputProps={{
            style: {
              color: 'black',
            },
          }}
        />
      )}

      {isEditingModeEnabled && (
        <TextField
          autoFocus
          size="small"
          sx={{
            input: {
              '-webkit-text-fill-color': `${textFieldColorForDisabled} !important`,
              color: `${textFieldColorForDisabled} !important`,
            },
            width: '310px',
          }}
          value={textValue}
          onChange={(e: any) => setTextValue(e.target.value)}
          InputProps={{
            sx: {
              borderRadius: '4px',
              color: 'white',
            },
          }}
        />
      )}

      {isEditingModeEnabled && (
        <Stack direction={'row'} alignItems={'center'}>
          <IconButton
            disableRipple={true}
            onClick={() => {
              // save only if value is changed from initial value
              if (initialValue !== textValue) {
                props.handleSave(textValue);
              }
              setIsEditingModeEnabled(false);
              if (props.customEditingModeOperation) props.customEditingModeOperation.setEnabledEditingMode(false);
            }}
          >
            <Tick />
          </IconButton>
          <IconButton disableRipple={true} onClick={handleStopEditing}>
            <Cross />
          </IconButton>
        </Stack>
      )}
      {!isEditingModeEnabled && !props.disableEditPenButton && (
        <IconButton onClick={() => setIsEditingModeEnabled(true)} disableRipple>
          <EditPen />
        </IconButton>
      )}
    </Stack>
  );
};

export default EditableInlineTextField;
