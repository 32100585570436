import { Stack, Typography } from '@mui/material';
import { ReactComponent as NoRecordIcon } from '../../../assets/svgs/no_records_mini.svg';
interface Props {
  title?: string;
}

const NoDataFound = (props: Props) => {
  return (
    <Stack sx={{ width: '100%', height: '100%' }} justifyContent={'center'} alignItems={'center'} p={3}>
      {props.title && (
        <Typography variant="h6" className="label-text" mb={2}>
          {props.title}
        </Typography>
      )}
      <NoRecordIcon />
    </Stack>
  );
};

export default NoDataFound;
