import { Select, FormControl, MenuItem, SelectProps, OutlinedInput } from '@mui/material';

const CognisaasSelect = (props: SelectProps & any) => {
  const disabledValues = props?.disabledValues?.length > 0 ? [...props.disabledValues] : [];
  return (
    <FormControl sx={{ width: `${props.width ? props.width : '200px'}` }} size="small">
      <Select
        displayEmpty
        renderValue={() => (props.value != '' ? props.value : props.overRideDefaultValue ? 'None' : 'Select')}
        name={props.name ? props.name : ''}
        disabled={props.disabled ? props.disabled : false}
        defaultOpen={props.shouldBeOpenByDefault ? true : false}
        labelId="demo-multiple-name-label"
        id="demo-multiple-name"
        onChange={props.onChange}
        input={<OutlinedInput />}
        onOpen={props?.onOpen}
        MenuProps={{
          elevation: 2,
          sx: {
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
          style: {
            maxHeight: 48 * 4.5,
            width: '25ch',
          },
        }}
        {...props}
      >
        {props.validValues.length == 0 && (
          <MenuItem value="">
            <em>No data available</em>
          </MenuItem>
        )}
        {props.validValues.map((obj: any, index: number) => (
          <MenuItem value={obj.id} key={index}>
            {obj.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CognisaasSelect;
