import { useState } from 'react';
import { ReactComponent as DocmentPlus } from '../../../assets/svgs/DocumentPlus.svg';
import { ReactComponent as DocumentMinus } from '../../../assets/svgs/Documentminus.svg';
import { ReactComponent as Attach } from '../../../assets/svgs/attach.svg';

import { Container, Title, Document, Small, Frame, Group, Wrapper, Intersection } from './DocumentExplorer.styles';
import { label_project, label_task, label_use_case } from 'components/Settings/Preferences/CustomLabelCookieHelper';

const CustomizedTreeView = (props: any) => {
  const { id, title, type, children, indent, selectedDocument, setSelectedDocument } = props;
  const [collapse, setCollapse] = useState<boolean>(false);
  console.log({ props });
  const Icon = () => (collapse ? <DocumentMinus /> : <DocmentPlus />);

  const handleClick = (type: string): void => {
    setCollapse((prevState) => !prevState);
    setSelectedDocument({ type, id, title, project_uid: props.project_uid || '', case_uid: props.case_uid || '' });
  };

  const typeLabel =
    type === 'Task' ? label_task : type === 'Project' ? label_project : type === 'Usecase' ? label_use_case : type;

  return (
    <Container>
      <Wrapper>
        <Document indent={indent} active={id === selectedDocument.id ? true : false} onClick={() => handleClick(type)}>
          <Group className="group">
            <Small>{typeLabel}</Small>
            <Frame>
              <div style={{ display: 'flex', alignItems: 'center', width: '80%' }}>
                {children && (
                  <span style={{ marginRight: '4px' }}>
                    <Icon />
                  </span>
                )}
                <Title>{title}</Title>
              </div>
              <Attach />
            </Frame>
          </Group>
        </Document>
        <Intersection />
      </Wrapper>
      {collapse &&
        (children ?? []).map((node: any) => (
          <CustomizedTreeView
            {...node}
            key={node.id}
            indent={indent + 0.5}
            selectedDocument={selectedDocument}
            setSelectedDocument={setSelectedDocument}
          />
        ))}
    </Container>
  );
};

export default CustomizedTreeView;
