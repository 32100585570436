import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { clientAPI } from '../../../../api/modules/client';
import { requestState } from '../../types';

interface renewalTasksType {
  renewalTasks: any;
  status: requestState.idle | requestState.loading | requestState.success | requestState.failed;
  error: null | undefined | string;
}

const initialState: renewalTasksType = {
  renewalTasks: null,
  status: requestState.idle,
  error: null,
} as renewalTasksType;

export const fetchRenewalTasks = createAsyncThunk('projects/fetchRenewalTasks', async () => {
  const { data } = await clientAPI.getAllRenewalTasks();
  return data.result;
});

const renewalTasksSlice = createSlice({
  name: 'renewalTasks',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchRenewalTasks.pending, (state) => {
        state.status = requestState.loading;
      })
      .addCase(fetchRenewalTasks.fulfilled, (state: renewalTasksType, action) => {
        state.status = requestState.success;
        state.renewalTasks = action.payload;
      })
      .addCase(fetchRenewalTasks.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      });
  },
});

export const getAllRenewalTasks = (state: renewalTasksType) => state.renewalTasks;

const renewalTasksReducer = renewalTasksSlice.reducer;
export default renewalTasksReducer;
