import { apis } from '../axiosInstance';
import { phaseRoutes } from '../route';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const getPhasesDetailsByCustomerUid = () =>
  apis.phpAxiosInstance.get(phaseRoutes.getPhasesDetailsByCustomerUid(cookies.get('cogni_customer_uid') as string), {
    headers: { JWT: 'true' },
  });

export const phaseAPI = {
  getPhasesDetailsByCustomerUid,
};
