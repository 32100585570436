import { Chip, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';
import { makeStyles } from '@mui/styles';

const brandBlue = '#2D60F6';

const useStyles: any = makeStyles({
  chipRoot: {
    '&.Mui-focusVisible': {
      backgroundColor: '#cedafd',
    },
  },
});

interface Props {
  name: string;
  value: any;
  handleChange: (newVal: number[]) => void;
  handleDelete: () => void;
}

const TextSearchChip = (props: Props) => {
  const [value, setValue] = useState<string>(() => {
    if (props.value.length > 0) {
      return props.value[0].split('$C$')[0] as string;
    } else {
      return '';
    }
  });

  const classes = useStyles();
  return (
    <Chip
      classes={{ root: classes.chipRoot }}
      sx={{ background: '#cedafd' }}
      label={
        <Box
          sx={{
            background: '#cedafd',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          <Typography variant="h3" sx={{ color: brandBlue, marginRight: '8px' }}>
            {props.name}
          </Typography>
          <Typography variant="subtitle2" className="label-text">
            Contains
          </Typography>

          <TextField
            value={value}
            inputProps={{
              style: {
                padding: '0px',
              },
            }}
            variant="standard"
            size="small"
            sx={{ margin: '0px 10px', width: '15ch' }}
            onChange={(e: any) => {
              setValue(e.target.value);
              props.handleChange(e.target.value);
            }}
          />
        </Box>
      }
      onDelete={props.handleDelete}
    />
  );
};

export default TextSearchChip;
