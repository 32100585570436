import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { requestState } from '../types';
import { projectReportsAPI } from '../../../api/modules/projectReports';
import { isNull } from 'utils/dataHelpers';

interface SettingsProps {
  projectDetailsForProjectPerformance: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };
  phasesAndTasks: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };
  teamMemberData: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };
}

const initialState: SettingsProps = {
  projectDetailsForProjectPerformance: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  phasesAndTasks: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  teamMemberData: {
    data: [],
    status: requestState.idle,
    error: null,
  },
};

export const getProjectDetailsByCustomerUid_slice = createAsyncThunk(
  'getProjectDetailsByCustomerUid',
  async (customer_uid: string) => {
    const { data } = await projectReportsAPI.getProjectsByCustomerUid(customer_uid);
    return data;
  }
);

export const phasesAndTasks_slice = createAsyncThunk('getPhasesAndTasks', async (customer_uid: string) => {
  const { data } = await projectReportsAPI.getPhasesAndTasks(customer_uid);
  return data;
});

export const teamMembersData_slice = createAsyncThunk('getTeamMembersData', async (customer_uid: string) => {
  const { data } = await projectReportsAPI.getTeamMembersData(customer_uid);
  return data;
});

export const projectReportsSlice = createSlice({
  name: 'project report reducer data',
  initialState,
  reducers: {
    //without api calls
  },
  extraReducers(builder) {
    builder
      // journey stages
      .addCase(getProjectDetailsByCustomerUid_slice.pending, (state) => {
        state.projectDetailsForProjectPerformance.status = requestState.loading;
      })
      .addCase(getProjectDetailsByCustomerUid_slice.fulfilled, (state, action: PayloadAction<any>) => {
        state.projectDetailsForProjectPerformance.status = requestState.success;
        if (isNull(action.payload)) {
          state.projectDetailsForProjectPerformance.data = [];
        } else {
          state.projectDetailsForProjectPerformance.data = action.payload;
        }
      })
      .addCase(getProjectDetailsByCustomerUid_slice.rejected, (state, action: any) => {
        state.projectDetailsForProjectPerformance.status = requestState.failed;
        state.projectDetailsForProjectPerformance.error = action.error.message;
      })
      .addCase(phasesAndTasks_slice.pending, (state) => {
        state.phasesAndTasks.status = requestState.loading;
      })
      .addCase(phasesAndTasks_slice.fulfilled, (state, action: PayloadAction<any>) => {
        state.phasesAndTasks.status = requestState.success;
        if (isNull(action.payload)) {
          state.phasesAndTasks.data = [];
        } else {
          state.phasesAndTasks.data = action.payload;
        }
      })
      .addCase(phasesAndTasks_slice.rejected, (state, action: any) => {
        state.phasesAndTasks.status = requestState.failed;
        state.phasesAndTasks.error = action.error.message;
      })
      .addCase(teamMembersData_slice.pending, (state) => {
        state.teamMemberData.status = requestState.loading;
      })
      .addCase(teamMembersData_slice.fulfilled, (state, action: PayloadAction<any>) => {
        state.teamMemberData.status = requestState.success;
        if (isNull(action.payload)) {
          state.teamMemberData.data = [];
        } else {
          state.teamMemberData.data = action.payload;
        }
      })
      .addCase(teamMembersData_slice.rejected, (state, action: any) => {
        state.teamMemberData.status = requestState.failed;
        state.teamMemberData.error = action.error.message;
      });
  },
});

const projectReportsReducer = projectReportsSlice.reducer;
export default projectReportsReducer;
