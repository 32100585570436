import { Grid, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { getStatus, getStatusColor } from 'utils/dataHelpers';
import CognisaasCheckBox from '../CognisaasCheckbox/CognisaasCheckbox';

interface Props {
  name: string;
  statusColor: 'red' | 'amber' | 'green' | 'blue';
  eta: string;
  feature_issue_type_name: string;
  isChecked: boolean;
  clickHandler: () => void;
}
const RequirementCardWithCheckbox = (props: Props) => {
  return (
    <Stack
      sx={{
        padding: '16px',
        borderRadius: '4px',
        border: '1px solid #E5E5E5',
        boxShadow: '0px 4px 26px rgba(0, 0, 0, 0.05)',
      }}
    >
      <Grid container alignItems={'center'} sx={{ marginBottom: '12px' }}>
        <Grid item xs={6}>
          <Stack className="cognisaas-pointer" onClick={props.clickHandler} direction={'row'} spacing={1}>
            <CognisaasCheckBox checked={props.isChecked} />
            <Typography variant={'subtitle1'}>{props.name}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack direction={'row'} alignItems={'center'}>
            <Box
              sx={{
                height: '12px',
                width: '12px',
                borderRadius: '50%',
                background: getStatusColor(props.statusColor),
                marginRight: '4px',
              }}
            ></Box>
            <Typography variant={'subtitle2'}>{getStatus(props.statusColor)}</Typography>
          </Stack>
        </Grid>
      </Grid>
      <Grid container alignItems={'center'} sx={{ marginBottom: '12px' }}>
        <Grid item xs={6}>
          <Stack direction={'row'} justifyContent="space-between" alignItems="baseline" spacing={0}>
            <Typography variant={'subtitle2'} className="label-text" sx={{ marginBottom: '4px' }}>
              ETA for Release
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack direction={'row'} justifyContent="space-between" alignItems="baseline" spacing={0}>
            <Typography variant={'subtitle2'} className="label-text" sx={{ marginBottom: '4px' }}>Requirement Type</Typography>
          </Stack>
        </Grid>
      </Grid>




      <Grid container>
        <Grid item xs={6}>
          {props.eta}
        </Grid>
        <Grid item xs={6}>
          <Typography>{props.feature_issue_type_name}</Typography>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default RequirementCardWithCheckbox;
