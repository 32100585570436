import { Grid } from '@mui/material';
import { useAppSelector } from '../../../../store/hooks';
// import data from '../data';
import ChartBox from './ChartBox';
import { selectAllChartsConfig } from '../../../../store/modules/Charts/selector';
import { useEffect, useState } from 'react';
import { graphSchema } from '../CustomiseDrawer/types';
import { isUndefined } from '../../../../utils/dataHelpers';

interface ChartViewProps {
  data: any[];
  numberOfcharts: number;
  idName: string;
  configType: 'project' | 'usecase' | 'task' | 'any';
  setDrawerState: React.Dispatch<React.SetStateAction<boolean>>;
  setGraphData: React.Dispatch<React.SetStateAction<any>>;
  setFormActionType: React.Dispatch<React.SetStateAction<'save' | 'update'>>;
}

const ChartView = (props: ChartViewProps) => {
  console.log('chart view', props.data);
  const allChartConfigs = useAppSelector(selectAllChartsConfig);
  const [myConfigs, setMyConfigs] = useState<graphSchema[]>([]);
  const [data, setData] = useState(props.data);
  const charts = allChartConfigs.filter((chart: any) => chart.idName === props.idName);
  useEffect(() => {
    if (isUndefined(charts)) {
      setMyConfigs([]);
    } else {
      setMyConfigs(charts);
    }
    console.log('chartconfigs', allChartConfigs);
  }, [allChartConfigs]);

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  return (
    <Grid container sx={{ background: 'white', borderRadius: '4px' }}>
      {myConfigs.map((chartConfig: graphSchema, index: number) => {
        return (
          <Grid item xs={myConfigs.length > 1 ? 6 : 12} key={index}>
            <ChartBox
              key={index}
              idName={chartConfig.idName || ''}
              chartHeading={chartConfig.chartName ? chartConfig.chartName : 'Chart'}
              chartType={chartConfig.chartType}
              dataForChart={data}
              layout={chartConfig.layout}
              colors={chartConfig.colors}
              legendPosition={chartConfig.labelPosition}
              xAxisKeys={chartConfig.xAxisKeys}
              yAxisKeys={chartConfig.yAxisKeys}
              barChartTrendline={chartConfig.trendLine == '1' ? true : false}
              flipAxis={chartConfig.flipAxis == '1' ? true : false}
              project_chart_uid={chartConfig.project_chart_uid || ''}
              case_chart_uid={chartConfig.case_chart_uid || ''}
              task_chart_uid={chartConfig.task_chart_uid || ''}
              setDrawerState={props.setDrawerState}
              setGraphData={props.setGraphData}
              setFormActionType={props.setFormActionType}
              configType={props.configType}
              entity_chart_uid={chartConfig.entity_chart_uid || ''}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default ChartView;
