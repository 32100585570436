const getClientCount = (customerId: string, clientCountsData: any) => {
    for(let i=0;i<clientCountsData.length;i++){
        if(clientCountsData[i].customer_uid === customerId) return clientCountsData[i].client_count;
    }
    return 0;
};

const getUserCount = (customerId: string, userCountsData: any) => {
    for(let i=0;i<userCountsData.length;i++){
        if(userCountsData[i].customer_uid === customerId) return userCountsData[i].user_count;
    }
    return 0;
};

const getProjectCount = (customerId: string, projectCountData: any) => {
    for(let i=0;i<projectCountData.length;i++){
        if(projectCountData[i]?.customer_uid === customerId) return projectCountData[i].project_count;
    }
    return 0;
};

const getUsecaseCount = (customerId: string, usecaseCountData: any) => {
    for(let i=0;i<usecaseCountData.length;i++){
        if(usecaseCountData[i]?.customer_uid === customerId) return usecaseCountData[i].usecase_count;
    }
    return 0;
};

// const getLastLogin = (customerId: string, allActivitiesData: any) => {
//     for(let i=allActivitiesData.length-1;i>=0;i--){
//         if(allActivitiesData[i].customer_uid === customerId) return allActivitiesData[i].user_last_login;
//     }
//     return 0;
// };

const getLatestLogin = (customerId: string, allActivitiesData: any) => {
    let date = '0000-00-00';
    for(let i=0;i<allActivitiesData.length;i++){
        if(customerId === allActivitiesData[i].customer_uid){
            if(date < allActivitiesData[i].user_last_login){
                date = allActivitiesData[i].user_last_login;
            }
        }
    }
    return date;
};


interface getFilteredData_payload{
    customerDetailsData: any, 
    allActivitiesData: any, 
    userCountsData: any, 
    clientCountsData: any,
    projectCountData: any,
    usecaseData: any
}

export const getFilteredData = (payload: getFilteredData_payload) => {
    const finalAns = [];
    for(let i=0;i<payload.customerDetailsData.length;i++){
        const lastLogin = getLatestLogin(payload.customerDetailsData[i].customer_uid, payload.allActivitiesData);
        const clientCount = getClientCount(payload.customerDetailsData[i].customer_uid, payload.clientCountsData);
        const userCount = getUserCount(payload.customerDetailsData[i].customer_uid, payload.userCountsData);
        const projectCount = getProjectCount(payload.customerDetailsData[i].customer_uid, payload.projectCountData);
        const usecaseCount = getUsecaseCount(payload.customerDetailsData[i].customer_uid, payload.usecaseData);
        const tempObj = {
            ...payload.customerDetailsData[i],
            'last_login': lastLogin,
            'client_count': clientCount,
            'user_count': userCount,
            'project_count': projectCount,
            'usecase_count': usecaseCount
        };
        finalAns.push(tempObj);
    }
    return finalAns;
};

const getProjectCountByUsers = (userId: string, projectCountData: any) => {
    for(let i=0;i<projectCountData.length;i++){
        if(projectCountData[i]?.user_uid === userId) return projectCountData[i].project_count;
    }
    return 0;
};


const getUsecaseCountByUsers = (userId: string, usecaseCountData: any) => {
    for(let i=0;i<usecaseCountData.length;i++){
        if(usecaseCountData[i]?.user_uid === userId) return usecaseCountData[i].usecase_count;
    }
    return 0;
};


interface allUSerData_payload {
    c_uid: string,
    allUserData: any,
    projectCountData: any,
    usecaseData: any
}

export const getAllUserByCustomerUid = (payload: allUSerData_payload) => {
    const finalAns = [];   

    for(let i=0;i<payload.allUserData.length;i++){
        if(payload.allUserData[i].customer_uid === payload.c_uid){
            const projectCountTemp = getProjectCountByUsers(payload.allUserData[i].user_uid, payload.projectCountData);
            const usecaseCountTemp = getUsecaseCountByUsers(payload.allUserData[i].user_uid, payload.usecaseData);
            const tempObj = {
                ...payload.allUserData[i],
                'project_count': projectCountTemp,
                'usecase_count': usecaseCountTemp
            };
            finalAns.push(tempObj);
        }
    }
    return finalAns;
};

// interface projectAndUsecaseCount_byUserUid_payload{
//     allUserData: any,
//     projectCountData: any,
//     usecaseData: any
// }

// export const getProjectAndUsecaseCount_byUserUid = (payload: projectAndUsecaseCount_byUserUid_payload) => {
//     const finAns = [];
//     console.log(payload);
//     for(let i=0;i<payload.allUserData.length;i++){
//         const projectCountTemp = getProjectCountByUsers(payload.allUserData[i].user_uid, payload.projectCountData);
//         const usecaseCountTemp = getUsecaseCountByUsers(payload.allUserData[i].user_uid, payload.usecaseData);
//         const tempObj = {
//             ...payload.allUserData[i],
//             'project_count': projectCountTemp,
//             'usecase_count': usecaseCountTemp
//         };
//         finAns.push(tempObj);
//     }
//     return finAns;
// };