import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { usecaseAPI } from '../../../../api/modules/usecase';
import { requestState } from '../../types';

interface usecasesType {
  usecases: Array<any>;
  status: requestState.idle | requestState.loading | requestState.success | requestState.failed;
  error: null | undefined | string;
}

const initialState: usecasesType = {
  usecases: [],
  status: requestState.idle,
  error: null,
} as usecasesType;

async function getOpenUsecases() {
  const { data } = await usecaseAPI.getOpenUsecases();
  return data.result ?? [];
}

async function getClosedUsecases() {
  const { data } = await usecaseAPI.getClosedUsecases();
  return data.result ?? [];
}

export const fetchAllUsecases = createAsyncThunk('/allUsecases', async () => {
  let openUsecases = await getOpenUsecases();
  let closedUsecases = await getClosedUsecases();
  if (openUsecases == null) {
    openUsecases = [];
  }
  if (closedUsecases == null) {
    closedUsecases = [];
  }
  const allUsecases: any[] = [...openUsecases, ...closedUsecases];
  console.log('allUsecases', allUsecases);
  return allUsecases;
});

const allUsecasesSlice = createSlice({
  name: 'allUsecases',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchAllUsecases.pending, (state) => {
        state.status = requestState.loading;
      })
      .addCase(fetchAllUsecases.fulfilled, (state, action) => {
        state.status = requestState.success;
        state.usecases = action.payload;
      })
      .addCase(fetchAllUsecases.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      });
  },
});

export const getAllUsecases = (state: usecasesType) => state.usecases;
const reportsAllUsecasesReducer = allUsecasesSlice.reducer;
export default reportsAllUsecasesReducer;
