import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { FormControlLabel, Box, Typography, Grid } from '@mui/material';

import { useAppSelector } from 'store/hooks';
import { getAllPages_selector, getRole_selector } from 'store/modules/RBAC/selector';
import {
  addPagesToRole_slice,
  getAllPages_slice,
  removePagesFromRole_slice,
  getRole_slice,
} from 'store/modules/RBAC/slice';
import CognisaasCheckBox from 'components/shared/CognisaasCheckbox/CognisaasCheckbox';
import { requestState } from 'store/modules/types';
import CognisaasSpinner from 'components/shared/CognisaasSpinner/CognisaasSpinner';

const NestedCheckbox = ({ obj, props, pages }: any) => {
  const [checked, setChecked] = useState<any>(Array(obj.subPages.length).fill(false));
  const [parentPageChecked, setParentPageChecked] = useState<boolean>(false);
  const dispatch = useDispatch();
  useEffect(() => {
    const selectedPages = pages;
    const temp = obj.subPages?.map((obj: any) => {
      const check = selectedPages?.find((page: any) => page?.id === obj?.id);
      return check != undefined;
    });
    setChecked(temp);
  }, []);

  useEffect(() => {
    if (checked.includes(true)) setParentPageChecked(true);
    else setParentPageChecked(false);
  }, [checked]);

  const roleId = props?.selectedRole?.id;

  const handleChange = async () => {
    if (parentPageChecked) {
      setChecked(Array(obj.subPages.length).fill(false));
      setParentPageChecked(false);
      obj.subPages.map((ele: any) => {
        dispatch(
          removePagesFromRole_slice({
            roleId: roleId,
            pageIds: [ele.id],
          })
        );
      });
      await dispatch(
        removePagesFromRole_slice({
          roleId: roleId,
          pageIds: [obj.id],
        })
      );
    }
    if (!parentPageChecked) {
      setParentPageChecked(true);
      await dispatch(
        addPagesToRole_slice({
          roleId: roleId,
          pageIds: [obj.id],
        })
      );
    }
    dispatch(getRole_slice(roleId));
  };

  const handleSubPagesChange = async (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const tempArr = [...checked];
    const newChecked: boolean[] = [];
    tempArr.map((ele: any, i: number) => {
      if (i == index) newChecked.push(!ele);
      else newChecked.push(ele);
    });
    const pagesTobeProccessed = obj.subPages[index];
    if (!tempArr[index]) {
      if (!parentPageChecked) {
        setParentPageChecked(true);
        dispatch(
          addPagesToRole_slice({
            roleId: roleId,
            pageIds: [obj.id],
          })
        );
      }
      await dispatch(
        addPagesToRole_slice({
          roleId: roleId,
          pageIds: [pagesTobeProccessed.id],
        })
      );
    } else {
      await dispatch(
        removePagesFromRole_slice({
          roleId: roleId,
          pageIds: [pagesTobeProccessed.id],
        })
      );
    }
    setChecked(newChecked);
    dispatch(getRole_slice(roleId));
  };
  const children = (
    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
      {obj.subPages?.map((ele: any, index: number) => {
        return (
          <FormControlLabel
            key={index}
            label={
              <Typography variant={'h3'} sx={{ marginLeft: '5px' }}>
                {ele?.name}
              </Typography>
            }
            sx={{ ml: '2px', mt: '7px' }}
            control={
              <CognisaasCheckBox
                checked={checked[index]}
                disabled={props.selectedRole.is_public == true ? true : false}
                onCheckBoxClick={(event) => !props.selectedRole.is_public && handleSubPagesChange(event, index)}
              />
            }
          />
        );
      })}
    </Box>
  );
  return (
    <div>
      <FormControlLabel
        sx={{ margin: '5px 0 0 0' }}
        label={
          <Typography variant={'h3'} sx={{ marginLeft: '5px' }}>
            {obj?.name}
          </Typography>
        }
        control={
          <CognisaasCheckBox
            checked={parentPageChecked}
            disabled={props.selectedRole.is_public}
            onCheckBoxClick={() => !props.selectedRole.is_public && handleChange()}
          />
        }
      />
      {children}
    </div>
  );
};

export default function Pages(props: any) {
  const [allPages, setAllPages] = useState<any[]>([]);
  const [nestedPages, setNestedPages] = useState<any[]>([]);
  const [checkBoxes, setCheckBoxes] = useState<any[]>([]);

  const getAllPages = useAppSelector(getAllPages_selector).data;
  const { pages } = useAppSelector(getRole_selector).data;
  const status = useAppSelector(getRole_selector).status;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllPages_slice());
    dispatch(getRole_slice(props.selectedRole.id));
  }, []);

  useEffect(() => {
    if (getAllPages.length > 0) setAllPages(getAllPages);
  }, [getAllPages]);

  const buildNestedPagesData = () => {
    let nestedAllPagesData: any[] = [];
    const flatPagesArr: any = [];
    const subPagesArr: any = [];
    const parentPagesArr: any = [];

    allPages.map((page: any) => {
      const pageUrlSplit = page.url.split('/');
      if (pageUrlSplit.length === 2) {
        flatPagesArr.push(page);
      } else {
        subPagesArr.push(page);
      }
    });

    subPagesArr.map((subPage: any) => {
      let parentUrl: string = subPage.url.split('/')[1];
      parentUrl = '/' + parentUrl;
      const parentObj: any = flatPagesArr.find((flatPage: any) => flatPage.url === parentUrl);
      const parentObjIndex = flatPagesArr.indexOf(parentObj);

      if (parentObj === undefined) {
        const parentObj: any = parentPagesArr.find((parentPage: any) => parentPage.url === parentUrl);
        parentObj?.subPages.push(subPage);
      } else {
        const formattedParent: any = { ...parentObj };
        formattedParent.subPages = [subPage];
        parentPagesArr.push(formattedParent);
        flatPagesArr.splice(parentObjIndex, 1);
      }
    });
    nestedAllPagesData = [...flatPagesArr, ...parentPagesArr];
    setNestedPages(nestedAllPagesData);
  };

  useEffect(() => {
    const temp = nestedPages?.map((obj: any) => {
      const check = pages?.find((o: any) => o?.id === obj?.id);
      return check !== undefined;
    });
    setCheckBoxes(temp);
  }, [pages, nestedPages]);

  useEffect(() => {
    buildNestedPagesData();
  }, [allPages]);

  const onClickCheckBoxState = async (index: number) => {
    const tempCheckBoxex = { ...checkBoxes };
    tempCheckBoxex[index] = !checkBoxes[index];
    setCheckBoxes(tempCheckBoxex);
    const pagesTobeProccessed = nestedPages[index];
    const roleId = props?.selectedRole?.id;
    if (tempCheckBoxex[index]) {
      props.addPage(pagesTobeProccessed);
      await dispatch(
        addPagesToRole_slice({
          roleId: roleId,
          pageIds: [pagesTobeProccessed.id],
        })
      );
    } else {
      props.removePage(pagesTobeProccessed);
      await dispatch(
        removePagesFromRole_slice({
          roleId: roleId,
          pageIds: [pagesTobeProccessed.id],
        })
      );
    }
    dispatch(getRole_slice(props.selectedRole.id));
  };

  return (
    <Grid sx={{ display: 'flex', flexDirection: 'column', marginTop: '15px' }}>
      {status === requestState.loading ? (
        <CognisaasSpinner />
      ) : (
        nestedPages?.map((obj: any, index: number) => {
          if (typeof obj.subPages === 'undefined') {
            return (
              <FormControlLabel
                onClick={() => {
                  if (!props.selectedRole.is_public) {
                    onClickCheckBoxState(index);
                  }
                }}
                key={index}
                sx={{ margin: '5px 0 0 0' }}
                control={
                  <CognisaasCheckBox checked={checkBoxes[index] || false} disabled={props.selectedRole.is_public} />
                }
                label={
                  <Typography variant={'h3'} sx={{ marginLeft: '5px' }}>
                    {obj?.name}
                  </Typography>
                }
              />
            );
          } else {
            return (
              <NestedCheckbox
                obj={obj}
                pages={pages}
                props={{ ...props, parentPageChecked: checkBoxes[index] || false }}
                allPages={allPages}
                key={index}
              />
            );
          }
        })
      )}
    </Grid>
  );
}
