import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as Logo } from '../../assets/svgs/cognisaas_dashboard_logo.svg';
import { ReactComponent as HelpLogo } from '../../assets/svgs/help.svg';
import { LoadingButton } from '@mui/lab';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import Rating, { IconContainerProps } from '@mui/material/Rating';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';

// responsive design
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ReactComponent as SmLogo } from '../../assets/svgs/Logo.svg';
import { ReactComponent as SuccessSvg } from '../../assets/svgs/emailSuccesSvg.svg';

// importing and Integrating Sidebar
import SideBar from '../SideBar/SideBar';
import { useEffect, useState } from 'react';
import GlobalSearch from './GlobalSearch/GlobalSearch';
import MenuItems from './MenuItems/MenuItems';
import { Button, Grid, Modal, TextField, Typography } from '@mui/material';

import { selectCurrentUserDetails } from 'store/modules/Common/selector';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { mailToSupport, feedbackMailTosupport } from '../../store/modules/auth/auth';
import { selectShowFeedbackScreen } from 'store/modules/auth/selector';
import Cookies from 'universal-cookie';
import { getLoggedInUserName } from 'utils/getUid';
import { getCurrentUserDetails } from 'store/modules/Common/slice';
import CognisaasButton from 'components/shared/CognisaasButton/CognisaasButton';
import { label_client } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { useNavigate } from 'react-router-dom';

const cookies = new Cookies();

const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
    color: theme.palette.action.disabled,
  },
}));

const customIcons: {
  [index: string]: {
    icon: React.ReactElement;
    label: string;
  };
} = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon sx={{ height: '48px', width: '48px' }} color="error" />,
    label: 'Very Dissatisfied',
  },
  2: {
    icon: <SentimentDissatisfiedIcon sx={{ height: '48px', width: '48px' }} color="error" />,
    label: 'Dissatisfied',
  },
  3: {
    icon: <SentimentSatisfiedIcon sx={{ height: '48px', width: '48px' }} color="warning" />,
    label: 'Neutral',
  },
  4: {
    icon: <SentimentSatisfiedAltIcon sx={{ height: '48px', width: '48px' }} color="success" />,
    label: 'Satisfied',
  },
  5: {
    icon: <SentimentVerySatisfiedIcon sx={{ height: '48px', width: '48px' }} color="success" />,
    label: 'Very Satisfied',
  },
};

function IconContainer(props: IconContainerProps) {
  const { value, ...other } = props;
  return (
    <span {...other} style={{ marginRight: '16px' }}>
      {customIcons[value].icon}
    </span>
  );
}

const styles = {
  appbar: {
    backgroundColor: '#ffffff',
    color: 'black',
    minHeight: '60px',
    justifyContent: 'center',
    borderBottom: '1px solid lightgray',
  },
  topBarAvatar: {
    bgcolor: '#F9D1A2',
    color: 'black',
    width: '30px',
    height: '30px',
    fontSize: '0.8rem',
  },
  toolbarSpacing: {
    padding: '0px 22px',
    height: '60px',
  },
  icons: {
    marginLeft: '16px',
  },
};

const TheToolBar = () => {
  const drawerWidth = 240;

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }: any) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      height: '150px',
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  const style = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 640,
    bgcolor: '#FFFFFF',
    boxShadow: 24,
    p: '20px',
  };

  const theme = useTheme();
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const userDetails = useAppSelector(selectCurrentUserDetails);
  const showFeedbackSuccessScreenApi = useAppSelector(selectShowFeedbackScreen);
  const [sendingHelpMail, setSendingHelpMail] = useState<boolean>(false);
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const [inputProblem, setInputProblem] = useState('');
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [openHelpModal, setOpenHelpModal] = useState(false);
  const [openHelpModalSuccess, setOpenHelpModalSuccess] = useState(false);
  const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [userName, setUserName] = useState('');
  const [userPhone, setUserPhone] = useState('');
  const [customerCompany, setCustomerCompany] = useState('');
  const [customerUid, setCustomerUid] = useState('');
  const [userJobTitle, setUserJobTitle] = useState('');
  const [rating, setRating] = useState('Very Satisfied');
  const [feedbackInput, setFeedbackInput] = useState('');

  const handleOpen = () => setOpenHelpModal(true);
  const handleClose = () => setOpenHelpModal(false);

  const handleOpenSuccess = () => setOpenHelpModalSuccess(true);
  const handleCloseSuccess = () => setOpenHelpModalSuccess(false);

  const handleOpenFeedback = () => setOpenFeedbackModal(true);
  const handleCloseFeedback = () => setOpenFeedbackModal(false);

  useEffect(() => {
    if (cookies.get('isCustomerPortal') == 'false') {
      dispatch(getCurrentUserDetails());
    }
  }, []);

  useEffect(() => {
    console.log(userDetails, 'u dat');
    setUserEmail(userDetails.data?.user_email);
    setUserName(getLoggedInUserName());
    setUserPhone(userDetails.data?.user_phone);
    setCustomerCompany(userDetails.data?.customer_company);
    setCustomerUid(userDetails.data?.customer_uid);
    setUserJobTitle(userDetails.data?.user_job_title);
  }, [userDetails]);

  useEffect(() => {
    console.log(inputProblem, 'u dat');
  }, [inputProblem]);

  useEffect(() => {
    console.log(showFeedbackSuccessScreenApi, 'sho suces');
    if (showFeedbackSuccessScreenApi == true) {
      handleOpenSuccess();
      handleClose();
    }
  }, [showFeedbackSuccessScreenApi]);

  const onSubmit = async () => {
    setSendingHelpMail(true);
    const reqObj = {
      help_question: inputProblem,
      product_detail: '',
      product_value: '',
      centricity_goal: '',
      user_email: userEmail,
      user_username: userName,
      user_phone: userPhone,
      customer_company: customerCompany,
      user_job_title: userJobTitle,
    };
    const res = await dispatch(mailToSupport(reqObj)).unwrap();
    if (res.status == 1) {
      setOpenHelpModal(false);
      setOpenHelpModalSuccess(true);
    }
    setSendingHelpMail(false);
    console.log(reqObj);
  };

  const onSubmitExperience = () => {
    const reqObj = {
      user_uid: cookies.get('cogni_user_uid'),
      user_email: userEmail,
      user_username: userName,
      user_phone: userPhone,
      customer_company: customerCompany,
      customer_uid: customerUid,
      user_job_title: userJobTitle,
      rating: rating,
      feedback: feedbackInput,
    };
    dispatch(feedbackMailTosupport(reqObj));
    setOpenFeedbackModal(false);
    console.log(reqObj, 'req obj');
  };
  const backToLandingPage = () => {
    cookies.set('client_uid', '');
    navigate('/customer-portal/landing', { replace: true });
  };

  return (
    <div>
      <Modal
        open={openHelpModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography sx={{ fontSize: '18px', fontWeight: '500' }}>How can we help you?</Typography>
          <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#7A7A7A' }}>
            Tell us about the problem you’re facing
          </Typography>
          <TextField
            multiline
            rows={3}
            size="small"
            fullWidth
            sx={{ mt: '18px' }}
            placeholder="Start typing here..."
            onChange={(e) => setInputProblem(e.target.value)}
          />
          <Grid container justifyContent="flex-end" sx={{ mt: '24px' }}>
            <Button variant="outlined" onClick={() => handleClose()}>
              Cancel
            </Button>
            {sendingHelpMail ? (
              <LoadingButton loading loadingPosition="start" startIcon={<PendingOutlinedIcon />} variant="outlined">
                Sending
              </LoadingButton>
            ) : (
              <Button variant="contained" sx={{ ml: '20px' }} onClick={() => onSubmit()}>
                Save
              </Button>
            )}
          </Grid>
        </Box>
      </Modal>
      <Modal
        open={openHelpModalSuccess}
        onClose={handleCloseSuccess}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container direction="column" alignItems="center" justifyContent="center" textAlign="center">
            <SuccessSvg />
            <Box>
              <Typography sx={{ mt: '24px', fontSize: '18px', fontWeight: '500' }}>Received Successfully!</Typography>
              <Typography sx={{ mt: '1px', fontSize: '12px', fontWeight: '400', color: ' #7A7A7A' }}>
                We’ll get back to you within 24 Hours via your registered email.
              </Typography>
              <Typography sx={{ mt: '8px', fontSize: '14px', fontWeight: '400' }}>
                We strive to build a better experience for you. It will
              </Typography>
              <Typography sx={{ mt: '1px', fontSize: '14px', fontWeight: '400' }}>
                be great if you can provide your feedback.
              </Typography>
            </Box>
            <Button
              variant="contained"
              sx={{ mt: '17px' }}
              onClick={() => {
                handleOpenFeedback();
                handleCloseSuccess();
              }}
            >
              Continue to feedback
            </Button>
          </Grid>
        </Box>
      </Modal>
      <Modal
        open={openFeedbackModal}
        onClose={handleCloseFeedback}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography sx={{ fontSize: '18px', fontWeight: '500' }}>Feedback</Typography>
          <Typography sx={{ mt: '1px', fontSize: '12px', fontWeight: '400', color: ' #7A7A7A', mb: '10px' }}>
            How was your experience with CogniSaas?
          </Typography>
          <StyledRating
            name="highlight-selected-only"
            defaultValue={5}
            IconContainerComponent={IconContainer}
            getLabelText={(value: number) => customIcons[value].label}
            highlightSelectedOnly
            onChange={(e, newValue) => {
              console.log(newValue);
              if (newValue == 1) setRating('Very Dissatisfied');
              if (newValue == 2) setRating('Dissatisfied');
              if (newValue == 3) setRating('neutral');
              if (newValue == 4) setRating('Satisfied');
              if (newValue == 5) setRating('Very Satisfied');
            }}
          />
          <Typography sx={{ mt: '8px', fontSize: '14px', fontWeight: '400' }}>
            Tell us more about your experience
          </Typography>
          <TextField
            multiline
            rows={3}
            size="small"
            fullWidth
            sx={{ mt: '18px' }}
            placeholder="Start typing here..."
            onChange={(e) => setFeedbackInput(e.target.value)}
          />
          <Grid container justifyContent="flex-end" sx={{ mt: '24px' }}>
            <Button variant="outlined" onClick={() => handleCloseFeedback()}>
              Cancel
            </Button>
            <Button variant="contained" sx={{ ml: '20px' }} onClick={() => onSubmitExperience()}>
              Save
            </Button>
          </Grid>
        </Box>
      </Modal>
      <AppBar elevation={0} style={styles.appbar} position="fixed">
        <Toolbar sx={matches ? { padding: '0px 10px' } : styles.toolbarSpacing}>
          {matches ? (
            <>
              <IconButton aria-label="sidebar menu icon" onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                <MenuIcon />
              </IconButton>
              <SmLogo />
            </>
          ) : (
            <Logo />
          )}
          {cookies.get('isCustomerPortal') == 'false' ? (
            <Box sx={matches ? { marginLeft: 'auto' } : { marginLeft: 'auto', marginRight: '22px' }}>
              <IconButton disableRipple aria-label="search">
                {/* {isGlobalNavActive ? (
                  <GlobalSearch />
                ) : (
                  <SearchLogo
                    onClick={(e) => {
                      setIsGlobalNavActive(true);
                    }}
                  />
                )} */}
                <GlobalSearch />
              </IconButton>
              <IconButton sx={matches ? {} : styles.icons} aria-label="help" onClick={handleOpen}>
                <HelpLogo />
              </IconButton>
              <IconButton sx={matches ? {} : styles.icons} aria-label="avtar">
                {/* <Avatar sx={styles.topBarAvatar}>CK</Avatar> */}
                <MenuItems />
              </IconButton>
            </Box>
          ) : cookies.get('oneToManyClient') == 'true' && cookies.get('isCustomerPortal') == 'true' && window.location.pathname.trim().split('/')[window.location.pathname.trim().split('/').length - 1] != 'landing' ? (
            <Box sx={matches ? { marginLeft: 'auto' } : { marginLeft: 'auto', marginRight: '22px' }}>
              <CognisaasButton label={`Switch ${label_client}`} isOutlined={false} clickHandler={() => backToLandingPage()} />
            </Box>
          ) : null}
        </Toolbar>
      </AppBar>
      <Divider />
      <SideBar isDrawerOpen={isSidebarOpen} setIsDrawerOpen={setIsSidebarOpen} />
    </div>
  );
};

export default TheToolBar;
