import { Avatar, Box, Button, Grid, Paper, Rating, Stack, TextareaAutosize, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import StarIcon from '@mui/icons-material/Star';
import { clientUpdateCsatRating_slice, getCsatByCsatUid_slice } from '../../../store/modules/Reports/Performance/slice';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { selectTaskRating, selectTaskRatingByCsatUid } from 'store/modules/Reports/Performance/selector';
import CognisaasSpinner from 'components/shared/CognisaasSpinner/CognisaasSpinner';
import { label_project, label_task } from 'components/Settings/Preferences/CustomLabelCookieHelper';

// START for Avatar
function stringToHslColor(str: string, s: number, l: number) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const h = hash % 360;
  return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
}
function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToHslColor(name, 30, 80),
    },
    children: (
      <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#000000' }}>
        {name.split(' ')[0][0]}
        {name.indexOf(' ') >= 0 ? name.split(' ')[1][0] : null}
      </Typography>
    ),
  };
}
// End of Avatar

// START for rating
const labels: { [index: string]: string } = {
  0.5: 'Very Dissatisfied',
  1: 'Very Dissatisfied',
  1.5: 'Dissatisfied',
  2: 'Dissatisfied',
  2.5: 'Neutral',
  3: 'Neutral',
  3.5: 'Satisfied',
  4: 'Satisfied',
  4.5: 'Very Satisfied',
  5: 'Very Satisfied',
};

function getLabelText(value: number) {
  return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
}
// END for rating

const CsatRating = () => {
  // for geting the url parameters
  const location: any = useLocation();

  // fro api calls
  const dispatch = useDispatch();
  const csatTaskRatingState = useAppSelector(selectTaskRating);
  const csatTaskRatingByCsatUidState = useAppSelector(selectTaskRatingByCsatUid);

  console.log(csatTaskRatingByCsatUidState, '-----------------------------');

  // for displaying the form
  const [displayForm, setDisplayForm] = React.useState<any>({
    case_uid: '',
    client_uid: '',
    contact_email: '',
    contact_name: '',
    created_at: '',
    csat_comment: '',
    csat_id: '',
    csat_link: '',
    csat_link_visited: 0,
    csat_rating: '',
    csat_reviewer_name: '',
    csat_reviewer_uid: '',
    csat_uid: '',
    customer_uid: '',
    last_updated: '',
    project_description: '',
    project_name: '',
    project_uid: '',
    task_description: '',
    task_name: '',
    task_owner: '',
    task_uid: '',
  });

  useEffect(() => {
    if (csatTaskRatingByCsatUidState.status === 'success') {
      setDisplayForm(csatTaskRatingByCsatUidState.data[0]);
    }
    // console.log('displayForm = ', displayForm);
  }, [csatTaskRatingByCsatUidState]);

  // for displaying the data
  const [displayData, setDisplayData] = React.useState<any>([]);

  useEffect(() => {
    if (csatTaskRatingState.data) {
      setDisplayData(csatTaskRatingState.data[0]);
    }
    console.log('displayData = ', displayData);
  }, [csatTaskRatingState]);

  // params from url
  const data = {
    csat_rating_type: location.state.csat_rating_type,
    csat_uid: location.state.csat_uid,
    jwt_token: location.state.jwt_token,
    stars: location.state.stars,
    task_type: location.state.task_type,
    task_type_uid: location.state.task_type_uid,
    task_uid: location.state.task_uid,
  };

  useEffect(() => {
    // console.log('location.state = ', location.state);
    // dispatch(getCsatByTaskUid_slice(data.task_uid));
    dispatch(getCsatByCsatUid_slice(data.csat_uid));
    // console.log('csatTaskRatingState = ', csatTaskRatingState);
    // console.log('csatTaskRatingByCsatUidState = ', csatTaskRatingByCsatUidState);
  }, []);

  // for displaying the form
  // if(csatTaskRatingByCsatUidState.status === 'success') {
  // setDisplayForm(csatTaskRatingByCsatUidState.data[0].csat_link_visited);

  // for rating start
  const [value, setValue] = React.useState<number | null>(data?.stars ? data?.stars : 0);
  const [hover, setHover] = React.useState(-1);
  // for rating end

  // for comment start
  const [comment, setComment] = React.useState<string>('');
  // for comment end

  // START saving Csat rating
  const handleSave = async () => {
    console.log('save');
    console.log('value = ', value);
    console.log('comment = ', comment);

    const updateData = {
      csat_comment: comment,
      csat_rating: value,
      csat_uid: data.csat_uid,
    };
    await dispatch(clientUpdateCsatRating_slice(updateData));
    // dispatch(getCsatByTaskUid_slice(data.task_uid));
    await dispatch(getCsatByCsatUid_slice(data.csat_uid));
  };
  // END saving Csat rating

  let displayFormLastUpdatedDate = displayForm?.last_updated;
  if (displayFormLastUpdatedDate !== undefined) {
    const year = new Date(displayForm?.last_updated).getFullYear();
    const month = new Date(displayForm?.last_updated).getMonth();
    const day = new Date(displayForm?.last_updated).getDate();
    const hours = new Date(displayForm?.last_updated).getHours();
    const minutes = new Date(displayForm?.last_updated).getMinutes();
    if (minutes <= 9) displayFormLastUpdatedDate = `${day}-${month}-${year} ${hours}:0${minutes}`;
    else displayFormLastUpdatedDate = `${day}-${month}-${year} ${hours}:${minutes}`;
  }

  return csatTaskRatingByCsatUidState.status == 'success' && displayForm ? (
    <React.Fragment>
      <Box sx={{ padding: '20px', paddingTop: '40px', backgroundColor: '' }}>
        <Paper variant="outlined" sx={{ width: '60%', marginX: 'auto', minWidth: '600px' }}>
          <Grid container justifyContent="space-between" sx={{ backgroundColor: '' }}>
            <Grid sx={{ marginX: 'auto', marginBottom: '40px', marginTop: '20px' }}>
              <Typography sx={{ fontSize: 20, fontWeight: 'bold', lineHeight: '200%' }}>{label_task} Rating</Typography>
              <Typography sx={{ fontSize: 14, fontWeight: 'normal' }}>{displayForm?.task_name}</Typography>
            </Grid>
            <Grid container justifyContent="space-between" sx={{ backgroundColor: '' }}>
              <Grid container sx={{ width: '50%', backgroundColor: '#F5F5F7', padding: '40px' }}>
                <Grid
                  sx={{
                    width: '100%',
                    marginBottom: '20px',
                    fontSize: 14,
                    fontWeight: 'bold',
                  }}
                >
                  <Stack direction="row">
                    <Avatar {...stringAvatar(!displayForm?.contact_name ? 'NULL NULL' : displayForm?.contact_name)} />
                    <Typography m={1}>{displayForm?.contact_name}</Typography>
                  </Stack>
                </Grid>
                <Grid sx={{ width: '100%', marginBottom: '20px' }}>
                  <Typography sx={{ fontSize: 12, fontWeight: 'normal', color: 'text.secondary', lineHeight: '250%' }}>
                    {label_task} Owner
                  </Typography>
                  <Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>{displayForm?.contact_name}</Typography>
                </Grid>
                <Grid sx={{ width: '100%', marginBottom: '20px' }}>
                  <Typography sx={{ fontSize: 12, fontWeight: 'normal', color: 'text.secondary', lineHeight: '250%' }}>
                    {label_project} name
                  </Typography>
                  <Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>{displayForm?.project_name}</Typography>
                </Grid>
                <Grid sx={{ width: '100%', marginBottom: '20px' }}>
                  <Typography sx={{ fontSize: 12, fontWeight: 'normal', color: 'text.secondary', lineHeight: '250%' }}>
                    {label_project} Description
                  </Typography>
                  <Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>
                    {displayForm?.project_description ? displayForm.project_description : 'Not Available'}
                  </Typography>
                </Grid>
              </Grid>
              {/* if the task is not rated then show this */}
              <Grid
                display={displayForm?.csat_link_visited == '0' ? '' : 'none'}
                container
                sx={{ width: '50%', backgroundColor: '', padding: '40px' }}
              >
                <Grid sx={{ marginBottom: '18px' }}>
                  <Typography sx={{ fontSize: 14, fontWeight: 'normal' }}>
                    Rate the `{displayForm?.task_name}` {label_task.toLowerCase()}
                  </Typography>
                </Grid>
                <Grid sx={{ width: '100%', marginBottom: '42px' }}>
                  <Box
                    sx={{
                      // width: 200,
                      display: 'flex',
                      alignItems: 'center',
                      backgroundColor: '',
                    }}
                  >
                    <Rating
                      name="star-rating"
                      value={value}
                      precision={0.5}
                      getLabelText={getLabelText}
                      onChange={(event, newValue) => {
                        setValue(newValue);
                      }}
                      onChangeActive={(event, newHover) => {
                        setHover(newHover);
                      }}
                      emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                    />
                    {value !== null && (
                      <Box sx={{ ml: 2 }}>
                        <Typography>{labels[hover !== -1 ? hover : value]}</Typography>
                      </Box>
                    )}
                  </Box>
                </Grid>
                <Grid sx={{ width: '100%' }}>
                  <TextareaAutosize
                    aria-label="Comment"
                    name="comment"
                    minRows={3}
                    onChange={(event) => {
                      setComment(event.target.value);
                    }}
                    placeholder="Additional Comments..."
                    style={{ width: '100%' }}
                  />
                </Grid>
                <Grid>
                  {/* <Button autoFocus variant="outlined">
                    Cancel
                  </Button> */}
                  <Button
                    onClick={handleSave}
                    autoFocus
                    variant="contained"
                    disabled={displayForm?.csat_link_visited == '1'}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
              {/* If the task is rated show this */}
              <Grid
                display={displayForm?.csat_link_visited == '1' ? '' : 'none'}
                container
                sx={{ width: '50%', backgroundColor: '', padding: '40px' }}
              >
                {/* need to show this in loop for all the csat rating of that task. But current showing only the csat rating given by the current user */}
                <Grid sx={{ marginBottom: '18px' }}>
                  <Typography sx={{ fontSize: 14, fontWeight: 'normal' }}>
                    {/* You rated the <b>`{displayData?.task_name}`</b> task on <b>{displayData?.last_updated}</b> */}
                    You rated the <strong>{displayForm?.task_name}</strong> {label_task.toLowerCase()} on{' '}
                    {displayFormLastUpdatedDate}
                  </Typography>
                </Grid>
                <Grid sx={{ width: '100%', marginBottom: '25px' }}>
                  <Box
                    sx={{
                      // width: 200,
                      display: 'flex',
                      alignItems: 'center',
                      backgroundColor: '',
                    }}
                  >
                    <Rating
                      name="star-rating"
                      // value={displayData?.csat_rating}
                      value={Number(displayForm?.csat_rating)}
                      precision={0.5}
                      readOnly
                      getLabelText={getLabelText}
                      onChange={(event, newValue) => {
                        setValue(newValue);
                      }}
                      onChangeActive={(event, newHover) => {
                        setHover(newHover);
                      }}
                      emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                    />
                    {value !== null && (
                      <Box sx={{ ml: 2 }}>
                        <Typography>{labels[hover !== -1 ? hover : value]}</Typography>
                      </Box>
                    )}
                  </Box>
                </Grid>
                <Grid sx={{ width: '100%' }}>
                  <Paper variant="outlined" sx={{ minHeight: '100px' }}>
                    {/* {displayData?.csat_comment} */}
                    <Typography> {displayForm?.csat_comment} </Typography>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </React.Fragment>
  ) : (
    <Stack style={{ marginTop: '45%' }} justifyContent="center" alignItems="center" height="100%">
      <CognisaasSpinner size={50} thickness={3} />
    </Stack>
  );
};

export default CsatRating;
