import { REPORT_TYPE_CUSTOM_REPORT } from 'components/Reports/Shared/constants';
import {
  label_client,
  label_cs_owner,
  label_project,
  label_project_delivery_manager,
  label_sales_owner,
  label_task,
  label_use_case,
  label_use_case_delivery_manager,
} from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import {
  selectAllIndustries,
  selectJourneyStage,
  selectSegmentValues,
  selectUsersOfCompany,
} from 'store/modules/Common/selector';
import { fetchAllIndustries, getAllSegmentValues, getAllUsers } from 'store/modules/Common/slice';
import {
  customerFieldClientRecords,
  customerFieldProjectRecords,
  customerFieldTaskRecords,
  customerFieldUsecaseRecords,
} from 'store/modules/Settings/selector';
import {
  getCustomFieldClientRecords_slice,
  getCustomFieldProjectRecords_slice,
  getCustomFieldTaskRecords_slice,
  getCustomFieldUsecaseRecords_slice,
} from 'store/modules/Settings/slice';
// import { selectIndustryFilterData } from 'store/modules/ui/Filters/selector';
// import { setUpFiltersForClientListPage } from 'store/modules/ui/Filters/slice';
import { capitalizeEachWordOf, generateMappedCustomFieldKey, isUndefined } from 'utils/dataHelpers';
import { getCustomerUid } from 'utils/getUid';
import useCustomNames from '../CustomNames/useCustomNames';
import {
  CF_TYPE_DATE,
  CF_TYPE_DROPDOWN,
  CF_TYPE_NUMBER,
  CF_TYPE_RAG,
  statusOptionMapping,
  TASK_STAGES,
  timeOptionsMapping,
  yaxisValues,
} from './constants';
import { chartConfigObj, chartType, ChartYAxisConfig, OptionsMappingObj } from './types';

const useChartCustomizeDrawer = () => {
  const [chartXaxisConfigs, setChartXaxisConfigs] = useState<chartConfigObj[]>([]);
  const [chartYaxisConfigs, setChartYaxisConfigs] = useState<ChartYAxisConfig[]>([]);
  const [projectXaxisChartConfig, setProjectXaxisChartConfig] = useState<chartConfigObj[]>([]);
  const [usecaseXaxisChartConfig, setUsecaseXaxisChartConfig] = useState<chartConfigObj[]>([]);
  const [taskXaxisChartConfig, setTaskXaxisChartConfig] = useState<chartConfigObj[]>([]);
  const [clientXaxisChartConfig, setClientXaxisChartConfig] = useState<chartConfigObj[]>([]);
  const [timeConfigMapping, setTimeConfigMapping] = useState<OptionsMappingObj[]>([]);

  // imports
  const {
    projectCategoryDropdownValues,
    projectStagesDropdownValues,
    usecaseCategoryDropdownValues,
    usecaseStagesDropdownValues,
    clientSegmentDropdownValues,
  } = useCustomNames();
  const dispatch = useDispatch();
  const projectCustomFields: any = useAppSelector(customerFieldProjectRecords);
  const usecaseCustomFields = useAppSelector(customerFieldUsecaseRecords);
  const taskCustomFields = useAppSelector(customerFieldTaskRecords);
  const clientCustomFields = useAppSelector(customerFieldClientRecords);
  const allUsers = useAppSelector(selectUsersOfCompany);
  const allSegments = useAppSelector(selectSegmentValues);
  const journeyStages = useAppSelector(selectJourneyStage);
  const allIndustries = useAppSelector(selectAllIndustries);

  const getAndSetData = () => {
    setTimeConfigMapping(timeOptionsMapping);
    dispatch(getCustomFieldProjectRecords_slice(getCustomerUid()));
    dispatch(getCustomFieldUsecaseRecords_slice(getCustomerUid()));
    dispatch(getCustomFieldTaskRecords_slice(getCustomerUid()));
    dispatch(getCustomFieldClientRecords_slice(getCustomerUid()));
    dispatch(getAllUsers());
    dispatch(getAllSegmentValues());
    dispatch(fetchAllIndustries());
  };

  const makeAndSetProjectConfigs = () => {
    const defaultProjectXAxisConfig: chartConfigObj[] = [
      {
        name: `${label_project} Category`,
        key: 'project_needed_status',
        options: projectCategoryDropdownValues.map((item: any) => item.category_name),
        type: 'project',
      },
      {
        name: `${label_project} Status`,
        key: 'project_readinessstate_status',
        options: statusOptionMapping.map((item: any) => item.key),
        type: 'project',
      },
      {
        name: `${label_project} Stage`,
        key: 'project_stage',
        options: projectStagesDropdownValues.map((item: any) => item.stage_name),
        type: 'project',
      },
      {
        name: `${label_project} ${label_project_delivery_manager}`,
        key: 'project_deliverymanager',
        options: [...allUsers.map((item: any) => item.user_username), 'Not Assigned'],
        type: 'project',
      },
    ];

    const timeProjectXAxisConfig: chartConfigObj = {
      name: 'Time',
      key: 'Time',
      options: timeOptionsMapping.map((item: any) => item.key),
      type: 'project',
    };

    const cfProjectXAxisConfig: chartConfigObj[] = [];
    const cfTimeOptions: OptionsMappingObj[] = [];

    // handling custom fields
    const projectCustomFieldData =
      projectCustomFields?.data.data?.result != undefined ? projectCustomFields?.data.data?.result : [];

    if (projectCustomFieldData.length > 0) {
      projectCustomFieldData.forEach((cf: any) => {
        const cfKey = generateMappedCustomFieldKey(cf.field_name, cf.field_uid, cf.field_type);
        if (cf.field_type === CF_TYPE_DATE) {
          //  timeProjectXAxisConfig = {
          //   ...timeProjectXAxisConfig,
          //   options: [...timeProjectXAxisConfig.options, cfKey],
          // };
          cfTimeOptions.push({
            name: cf.field_name,
            key: cfKey,
            type: 'project',
          });
        } else if (cf.field_type === CF_TYPE_DROPDOWN || cf.field_type === CF_TYPE_RAG) {
          cfProjectXAxisConfig.push({
            name: cf.field_name,
            key: cfKey,
            options: cf.valid_values.split(','),
            type: 'project',
          });
        }
      });
    }

    setProjectXaxisChartConfig([...defaultProjectXAxisConfig, timeProjectXAxisConfig, ...cfProjectXAxisConfig]);
    setTimeConfigMapping((prev: any) => [...prev, ...cfTimeOptions]);
  };

  const makeAndSetUsecaseConfigs = () => {
    const defaultUsecaseXAxisConfig: chartConfigObj[] = [
      {
        name: `${label_use_case} Category`,
        key: 'case_needed_status',
        options: usecaseCategoryDropdownValues.map((item: any) => item.category_name),
        type: 'usecase',
      },
      {
        name: `${label_use_case} Status`,
        key: 'case_readinessstate_status',
        options: statusOptionMapping.map((item: any) => item.key),
        type: 'usecase',
      },
      {
        name: `${label_use_case} Stage`,
        key: 'case_stage',
        options: usecaseStagesDropdownValues.map((item: any) => item.stage_name),
        type: 'usecase',
      },
      {
        name: `${label_use_case} ${label_use_case_delivery_manager}`,
        key: 'case_deliverymanager',
        options: [...allUsers.map((item: any) => item.user_username), 'Not Assigned'],
        type: 'usecase',
      },
      {
        name: `${label_client} Status`,
        key: 'client_readiness_status',
        options: statusOptionMapping.map((item: any) => item.key),
        type: 'usecase',
      },
    ];

    const timeUsecaseXAxisConfig: chartConfigObj = {
      name: 'Time',
      key: 'Time',
      options: timeOptionsMapping.map((item: any) => item.key),
      type: 'usecase',
    };

    const cfUsecaseXAxisConfig: chartConfigObj[] = [];
    const cfTimeOptions: OptionsMappingObj[] = [];

    // handling custom fields
    const usecaseCustomFieldData =
      usecaseCustomFields?.data.data?.result != undefined ? usecaseCustomFields?.data.data?.result : [];

    if (usecaseCustomFieldData.length > 0) {
      usecaseCustomFieldData.forEach((cf: any) => {
        const cfKey = generateMappedCustomFieldKey(cf.field_name, cf.field_uid, cf.field_type);
        if (cf.field_type === CF_TYPE_DATE) {
          // timeUsecaseXAxisConfig = {
          //   ...timeUsecaseXAxisConfig,
          //   options: [...timeUsecaseXAxisConfig.options, cfKey],
          // };
          cfTimeOptions.push({
            name: cf.field_name,
            key: cfKey,
            type: 'usecase',
          });
        } else if (cf.field_type === CF_TYPE_DROPDOWN || cf.field_type === CF_TYPE_RAG) {
          cfUsecaseXAxisConfig.push({
            name: cf.field_name,
            key: cfKey,
            options: cf.valid_values.split(','),
            type: 'usecase',
          });
        }
      });
    }

    setUsecaseXaxisChartConfig([...defaultUsecaseXAxisConfig, timeUsecaseXAxisConfig, ...cfUsecaseXAxisConfig]);
    setTimeConfigMapping((prev: any) => [...prev, ...cfTimeOptions]);
  };

  const makeAndSetTaskConfigs = () => {
    const defaultTaskXAxisConfig: chartConfigObj[] = [
      {
        name: `${label_task} Status`,
        key: 'task_status',
        options: statusOptionMapping.map((item: any) => item.key),
        type: 'task',
      },
      {
        name: `${label_task} Stage`,
        key: 'task_stage',
        options: TASK_STAGES,
        type: 'task',
      },
      {
        name: `${label_task} Owner`,
        key: 'task_owner',
        options: [...allUsers.map((item: any) => item.user_username), 'Not Assigned'],
        type: 'task',
      },
    ];

    const timeTaskXAxisConfig: chartConfigObj = {
      name: 'Time',
      key: 'Time',
      options: timeOptionsMapping.map((item: any) => item.key),
      type: 'task',
    };

    const cfTaskXAxisConfig: chartConfigObj[] = [];
    const cfTimeOptions: OptionsMappingObj[] = [];

    // handling custom fields
    const taskCustomFieldData =
      taskCustomFields?.data.data?.result != undefined ? taskCustomFields?.data.data?.result : [];

    if (taskCustomFieldData.length > 0) {
      taskCustomFieldData.forEach((cf: any) => {
        const cfKey = generateMappedCustomFieldKey(cf.field_name, cf.field_uid, cf.field_type);
        if (cf.field_type === CF_TYPE_DATE) {
          // timeTaskXAxisConfig = {
          //   ...timeTaskXAxisConfig,
          //   options: [...timeTaskXAxisConfig.options, cfKey],
          // };
          cfTimeOptions.push({
            name: cf.field_name,
            key: cfKey,
            type: 'task',
          });
        } else if (cf.field_type === CF_TYPE_DROPDOWN || cf.field_type === CF_TYPE_RAG) {
          cfTaskXAxisConfig.push({
            name: cf.field_name,
            key: cfKey,
            options: cf.valid_values.split(','),
            type: 'task',
          });
        }
      });
    }

    setTaskXaxisChartConfig([...defaultTaskXAxisConfig, timeTaskXAxisConfig, ...cfTaskXAxisConfig]);
    setTimeConfigMapping((prev: any) => [...prev, ...cfTimeOptions]);
  };

  const makeAndSetClientConfigs = () => {
    const defaultClientXAxisConfig: chartConfigObj[] = [
      {
        name: `${label_client} ${label_sales_owner}`,
        key: 'client_sales_owner',
        options: [...allUsers.map((item: any) => item.user_username), 'Not Assigned'],
        type: 'client',
      },
      {
        name: `${label_client} ${label_cs_owner}`,
        key: 'client_account_owner',
        options: [...allUsers.map((item: any) => item.user_username), 'Not Assigned'],
        type: 'client',
      },
      {
        name: `${label_client} Status`,
        key: 'client_readiness_status',
        options: statusOptionMapping.map((item: any) => item.key),
        type: 'client',
      },
      {
        name: `${label_client} Segment`,
        key: 'segment_name',
        options: clientSegmentDropdownValues.map((item: any) => item.custom_segment_name),
        type: 'client',
      },
      {
        name: `${label_client} Industry`,
        key: 'industry_name',
        options: allIndustries.data.map((item: any) => item.industry_name),
        type: 'client',
      },
      {
        name: `${label_client} Stage`,
        key: 'stage_journerystage',
        options: journeyStages.data.map((item: any) => item.stage_name),
        type: 'client',
      },
    ];

    const timeClientXAxisConfig: chartConfigObj = {
      name: 'Time',
      key: 'Time',
      options: timeOptionsMapping.map((item: any) => item.key),
      type: 'client',
    };

    const cfClientXAxisConfig: chartConfigObj[] = [];
    const cfTimeOptions: OptionsMappingObj[] = [];

    // handling custom fields
    const clientCustomFieldData = clientCustomFields?.data != undefined ? clientCustomFields?.data : [];

    if (clientCustomFieldData.length > 0) {
      clientCustomFieldData.forEach((cf: any) => {
        const cfKey = generateMappedCustomFieldKey(cf.field_name, cf.field_uid, cf.field_type);
        if (cf.field_type === CF_TYPE_DATE) {
          // timeUsecaseXAxisConfig = {
          //   ...timeUsecaseXAxisConfig,
          //   options: [...timeUsecaseXAxisConfig.options, cfKey],
          // };
          cfTimeOptions.push({
            name: cf.field_name,
            key: cfKey,
            type: 'client',
          });
        } else if (cf.field_type === CF_TYPE_DROPDOWN || cf.field_type === CF_TYPE_RAG) {
          cfClientXAxisConfig.push({
            name: cf.field_name,
            key: cfKey,
            options: cf.valid_values.split(','),
            type: 'client',
          });
        }
      });
    }

    setClientXaxisChartConfig([...defaultClientXAxisConfig, timeClientXAxisConfig, ...cfClientXAxisConfig]);
    setTimeConfigMapping((prev: any) => [...prev, ...cfTimeOptions]);
  };

  const makeYAxisConfigs = () => {
    const cfYAxisConfig: ChartYAxisConfig[] = [];

    // projects custom fields
    const projectCustomFieldData =
      projectCustomFields?.data?.data?.result != undefined ? projectCustomFields?.data.data?.result : [];

    if (projectCustomFieldData.length > 0) {
      projectCustomFieldData.forEach((cf: any) => {
        const cfKey = generateMappedCustomFieldKey(cf.field_name, cf.field_uid, cf.field_type);
        if (cf.field_type === CF_TYPE_NUMBER) {
          cfYAxisConfig.push({
            name: capitalizeEachWordOf(cf.field_name),
            key: cfKey,
            type: 'project',
            basis: 'number',
          });
        } else {
          // for aggregated report
          cfYAxisConfig.push({
            name: capitalizeEachWordOf(cf.field_name),
            key: cfKey,
            type: 'any',
            basis: 'number',
          });
        }
      });
    }

    // usecase custom fields
    const usecaseCustomFieldData =
      usecaseCustomFields?.data?.data?.result != undefined ? usecaseCustomFields?.data.data?.result : [];

    if (usecaseCustomFieldData.length > 0) {
      usecaseCustomFieldData.forEach((cf: any) => {
        const cfKey = generateMappedCustomFieldKey(cf.field_name, cf.field_uid, cf.field_type);
        if (cf.field_type === CF_TYPE_NUMBER) {
          cfYAxisConfig.push({
            name: capitalizeEachWordOf(cf.field_name),
            key: cfKey,
            type: 'usecase',
            basis: 'number',
          });
        } else {
          // for aggregated report
          cfYAxisConfig.push({
            name: capitalizeEachWordOf(cf.field_name),
            key: cfKey,
            type: 'any',
            basis: 'number',
          });
        }
      });
    }

    // task custom fields
    const taskCustomFieldData =
      taskCustomFields?.data?.data?.result != undefined ? taskCustomFields?.data.data?.result : [];

    if (taskCustomFieldData.length > 0) {
      taskCustomFieldData.forEach((cf: any) => {
        const cfKey = generateMappedCustomFieldKey(cf.field_name, cf.field_uid, cf.field_type);
        if (cf.field_type === CF_TYPE_NUMBER) {
          cfYAxisConfig.push({
            name: capitalizeEachWordOf(cf.field_name),
            key: cfKey,
            type: 'task',
            basis: 'number',
          });
        } else {
          // for aggregated report
          cfYAxisConfig.push({
            name: capitalizeEachWordOf(cf.field_name),
            key: cfKey,
            type: 'any',
            basis: 'number',
          });
        }
      });
    }

    setChartYaxisConfigs([...yaxisValues, ...cfYAxisConfig]);
  };

  const getTimeDataKeysFor = (chartType: chartType, customReportConfig: any): any[] => {
    if (chartType === 'any') {
      if (customReportConfig) {
        const customReportKeys: string[] = [];
        customReportConfig.report_config?.client?.forEach((item: any) => customReportKeys.push(item.key));
        customReportConfig.report_config?.project?.forEach((item: any) => customReportKeys.push(item.key));
        customReportConfig.report_config?.usecase?.forEach((item: any) => customReportKeys.push(item.key));
        customReportConfig.report_config?.task?.forEach((item: any) => customReportKeys.push(item.key));

        return timeConfigMapping.filter((item: any) => customReportKeys.includes(item.key));
      } else {
        return [];
      }
    } else {
      return timeConfigMapping.filter((item: any) => item.type === chartType);
    }
  };

  const getGroupedDataKeysFor = (chartType: chartType): any[] => {
    const allGroupConfigs = [
      {
        name: `${label_client} Status`,
        key: 'client_readiness_status',
        options: statusOptionMapping.map((item: any) => item.key),
        type: 'client',
      },
      {
        name: `${label_project} Status`,
        key: 'project_readinessstate_status',
        options: statusOptionMapping.map((item: any) => item.key),
        type: 'project',
      },
      {
        name: `${label_use_case} Status`,
        key: 'case_readinessstate_status',
        options: statusOptionMapping.map((item: any) => item.key),
        type: 'usecase',
      },
      {
        name: `${label_task} Status`,
        key: 'task_status',
        options: statusOptionMapping.map((item: any) => item.key),
        type: 'task',
      },
    ];

    return allGroupConfigs.filter((item: any) => item.type === chartType);
  };

  const getTimeActualDataKeyFromName = (name: string): string => {
    const filteredArr = timeConfigMapping.filter((item: any) => item.name === name);
    if (filteredArr.length > 0) {
      return filteredArr[0].key;
    } else {
      return name;
    }
  };

  const getAllChartXConfigsforTypeAny = (customReportConfig: any): chartConfigObj[] => {
    const allConfigs = chartXaxisConfigs;
    const newXConfigs: chartConfigObj[] = [];
    if (customReportConfig) {
      if (customReportConfig.report_type === REPORT_TYPE_CUSTOM_REPORT) {
        const customReportKeys: string[] = [];
        customReportConfig.report_config?.client?.forEach((item: any) => customReportKeys.push(item.key));
        customReportConfig.report_config?.project?.forEach((item: any) => customReportKeys.push(item.key));
        customReportConfig.report_config?.usecase?.forEach((item: any) => customReportKeys.push(item.key));
        customReportConfig.report_config?.task?.forEach((item: any) => customReportKeys.push(item.key));
        allConfigs.forEach((item: any) => {
          const keysAlreadyPresent = newXConfigs.map((chart: any) => chart.key);
          if (!keysAlreadyPresent.includes(item.key)) {
            if (item.key === 'Time') {
              newXConfigs.push({
                ...item,
                options: item.options.filter((timeKey: string) => customReportKeys.includes(timeKey)),
              });
            } else if (customReportKeys.includes(item.key)) newXConfigs.push(item);
          }
        });
      } else {
        // aggregated report
        const customReportKeys: string[] = [];
        if (['client', 'project', 'usecase'].includes(customReportConfig.top_entity)) {
          customReportConfig.report_config?.client?.forEach((item: any) => customReportKeys.push(item.key));
        }
        if (['project', 'usecase'].includes(customReportConfig.top_entity)) {
          customReportConfig.report_config?.project?.forEach((item: any) => customReportKeys.push(item.key));
        }
        if (['usecase'].includes(customReportConfig.top_entity)) {
          customReportConfig.report_config?.usecase?.forEach((item: any) => customReportKeys.push(item.key));
        }
        allConfigs.forEach((item: any) => {
          const keysAlreadyPresent = newXConfigs.map((chart: any) => chart.key);

          if (item.key.split('#$#').length > 0) {
            // means this is a custom field
            const field_name = item.key.split('#$#')[0];
            if (!keysAlreadyPresent.includes(field_name) && customReportKeys.includes(field_name)) {
              newXConfigs.push(item);
            }
          } else if (!keysAlreadyPresent.includes(item.key) && customReportKeys.includes(item.key)) {
            newXConfigs.push(item);
          }
        });
      }
    }
    return newXConfigs.map((item: any) => ({ ...item, type: 'any' }));
  };

  const getAllChartYConfigsForTypeAny = (customReportConfig: any): ChartYAxisConfig[] => {
    const allConfigs = chartYaxisConfigs;
    const newYConfigs: ChartYAxisConfig[] = [];
    newYConfigs.push({
      name: 'Data Count',
      key: 'Data Count',
      type: 'any',
      basis: 'number',
    });
    if (customReportConfig) {
      if (customReportConfig.report_type === REPORT_TYPE_CUSTOM_REPORT) {
        const customReportKeys: string[] = [];
        customReportConfig.report_config?.client?.forEach((item: any) => customReportKeys.push(item.key));
        customReportConfig.report_config?.project?.forEach((item: any) => customReportKeys.push(item.key));
        customReportConfig.report_config?.usecase?.forEach((item: any) => customReportKeys.push(item.key));
        customReportConfig.report_config?.task?.forEach((item: any) => customReportKeys.push(item.key));
        allConfigs.forEach((item: any) => {
          const keysAlreadyPresent = [...newYConfigs.map((chart: any) => chart.key), 'Data Count'];
          if (!keysAlreadyPresent.includes(item.key) && customReportKeys.includes(item.key)) {
            newYConfigs.push(item);
          }
        });
      } else {
        // aggregated report
        const customReportKeys: string[] = [];

        if (['client'].includes(customReportConfig.top_entity)) {
          // customReportConfig.report_config?.project?.forEach((item: any) => customReportKeys.push(item.key));
          customReportConfig.report_config?.project?.forEach((item: any) => {
            if (item.isCustomField) {
              allConfigs.forEach((ele: any) => {
                if (ele.key.split('#$#').length > 0 && ele.key.split('#$#')[0] === item.displayName) {
                  newYConfigs.push(ele);
                }
              });
            } else {
              // in aggregated report other than top entity everything is a count
              newYConfigs.push({
                name: item.displayName,
                key: item.key,
                type: 'any',
                basis: 'number',
              });
            }
            customReportKeys.push(item.key);
          });
        }
        if (['client', 'project'].includes(customReportConfig.top_entity)) {
          // customReportConfig.report_config?.usecase?.forEach((item: any) => customReportKeys.push(item.key));
          customReportConfig.report_config?.usecase?.forEach((item: any) => {
            if (item.isCustomField) {
              allConfigs.forEach((ele: any) => {
                if (ele.key.split('#$#').length > 0 && ele.key.split('#$#')[0] === item.displayName) {
                  newYConfigs.push(ele);
                }
              });
            } else {
              // in aggregated report other than top entity everything is a count
              newYConfigs.push({
                name: item.displayName,
                key: item.key,
                type: 'any',
                basis: 'number',
              });
            }
            customReportKeys.push(item.key);
          });
        }

        // customReportConfig.report_config?.task?.forEach((item: any) => customReportKeys.push(item.key));
        customReportConfig.report_config?.task?.forEach((item: any) => {
          if (item.isCustomField) {
            allConfigs.forEach((ele: any) => {
              if (ele.key.split('#$#').length > 0 && ele.key.split('#$#')[0] === item.displayName) {
                newYConfigs.push(ele);
              }
            });
          } else {
            // in aggregated report other than top entity everything is a count
            newYConfigs.push({
              name: item.displayName,
              key: item.key,
              type: 'any',
              basis: 'number',
            });
          }
          customReportKeys.push(item.key);
        });

        // allConfigs.forEach((item: any) => {
        //   const keysAlreadyPresent = [...newYConfigs.map((chart: any) => chart.key), 'Data Count'];
        //   if (item.key.split('#$#').length > 0) {
        //     // means this is a custom field
        //     const field_name = item.key.split('#$#')[0];
        //     if (!keysAlreadyPresent.includes(field_name) && customReportKeys.includes(field_name)) {
        //       newYConfigs.push(item);
        //     }
        //   } else if (!keysAlreadyPresent.includes(item.key) && customReportKeys.includes(item.key)) {
        //     newYConfigs.push(item);
        //   }
        // });
      }
    }

    return newYConfigs.map((item: any) => ({ ...item, type: 'any' }));
  };

  function transformDataForCustomReport(data: any[], customReportConfig: any): any[] {
    if (
      customReportConfig &&
      clientCustomFields.status === 'success' &&
      projectCustomFields.status === 'success' &&
      usecaseCustomFields.status === 'success' &&
      taskCustomFields.status === 'success'
    ) {
      const displayNameToKeymapping: Record<string, string> = {};
      const clientCustomFieldData = clientCustomFields?.data != undefined ? clientCustomFields?.data : [];
      const projectCustomFieldData =
        projectCustomFields?.data.data?.result != undefined ? projectCustomFields?.data.data?.result : [];
      const usecaseCustomFieldData =
        usecaseCustomFields?.data.data?.result != undefined ? usecaseCustomFields?.data.data?.result : [];
      const taskCustomFieldData =
        taskCustomFields?.data.data?.result != undefined ? taskCustomFields?.data.data?.result : [];

      // client keys mapping
      customReportConfig?.report_config?.client.forEach((item: any) => {
        if (item.isCustomField) {
          clientCustomFieldData.forEach((cf: any) => {
            if (cf.field_name === item.key) {
              displayNameToKeymapping[item.displayName] = generateMappedCustomFieldKey(
                cf.field_name,
                cf.field_uid,
                cf.field_type
              );
            }
          });
        } else {
          displayNameToKeymapping[item.displayName] = item.key;
        }
      });

      // projects
      customReportConfig?.report_config?.project.forEach((item: any) => {
        if (item.isCustomField) {
          projectCustomFieldData.forEach((cf: any) => {
            if (cf.field_name === item.key) {
              displayNameToKeymapping[item.displayName] = generateMappedCustomFieldKey(
                cf.field_name,
                cf.field_uid,
                cf.field_type
              );
            }
          });
        } else {
          displayNameToKeymapping[item.displayName] = item.key;
        }
      });

      // usecase
      customReportConfig?.report_config?.usecase.forEach((item: any) => {
        if (item.isCustomField) {
          usecaseCustomFieldData.forEach((cf: any) => {
            if (cf.field_name === item.key) {
              displayNameToKeymapping[item.displayName] = generateMappedCustomFieldKey(
                cf.field_name,
                cf.field_uid,
                cf.field_type
              );
            }
          });
        } else {
          displayNameToKeymapping[item.displayName] = item.key;
        }
      });

      // task
      customReportConfig?.report_config?.task.forEach((item: any) => {
        if (item.isCustomField) {
          taskCustomFieldData.forEach((cf: any) => {
            if (cf.field_name === item.key) {
              displayNameToKeymapping[item.displayName] = generateMappedCustomFieldKey(
                cf.field_name,
                cf.field_uid,
                cf.field_type
              );
            }
          });
        } else {
          displayNameToKeymapping[item.displayName] = item.key;
        }
      });

      return data.map((item: any) => {
        const keys = Object.keys(item);
        const newItem: Record<string, any> = {};
        keys.forEach((key: any) => {
          if (isUndefined(displayNameToKeymapping[key])) {
            newItem[key] = item[key];
          } else {
            newItem[displayNameToKeymapping[key]] = item[key];
          }
          newItem[key] = item[key]; // for custom fields we need norml mapping also
        });
        return newItem;
      });
    } else {
      return [];
    }
  }

  const addRealtimeChartXaxisConfigAsPerData = (data: any[]) => {
    const newconfigs: chartConfigObj[] = [];
    // const oldConfigs: chartConfigObj[] = [...projectXaxisChartConfig];
    // project names
    if (data?.length > 0) {
      newconfigs.push({
        name: `${label_project} Name`,
        key: 'project_name',
        options: data.map((item: any) => item.project_name),
        type: 'project',
      });
    }

    return newconfigs;

    // if (oldConfigs.length > 1) {
    //   setProjectXaxisChartConfig((prevState: any) => {
    //     return [...newconfigs, ...prevState];
    //   });
    // }
  };

  useEffect(() => {
    getAndSetData();
  }, []);

  useEffect(() => {
    if (
      projectCategoryDropdownValues.length > 0 &&
      projectStagesDropdownValues.length > 0 &&
      projectCustomFields.status === 'success' &&
      projectXaxisChartConfig.length === 0 &&
      allUsers.length > 0
    ) {
      makeAndSetProjectConfigs();
    }
  }, [projectCategoryDropdownValues, projectStagesDropdownValues, projectCustomFields]);

  useEffect(() => {
    if (
      usecaseCategoryDropdownValues.length > 0 &&
      usecaseStagesDropdownValues.length > 0 &&
      usecaseCustomFields.status === 'success' &&
      usecaseXaxisChartConfig.length === 0 &&
      allUsers.length > 0
    ) {
      makeAndSetUsecaseConfigs();
    }
  }, [usecaseCategoryDropdownValues, usecaseStagesDropdownValues, usecaseCustomFields]);

  useEffect(() => {
    if (taskCustomFields.status === 'success' && taskXaxisChartConfig.length === 0) {
      makeAndSetTaskConfigs();
    }
  }, [taskCustomFields]);

  useEffect(() => {
    if (
      clientCustomFields.status === 'success' &&
      allUsers.length > 0 &&
      allSegments.data.length > 0 &&
      allIndustries.data.length > 0
    ) {
      makeAndSetClientConfigs();
    }
  }, [clientCustomFields, allUsers, allSegments, allIndustries]);

  useEffect(() => {
    if (
      projectCustomFields.status === 'success' &&
      usecaseCustomFields.status === 'success' &&
      taskCustomFields.status === 'success' &&
      allUsers.length > 0
    ) {
      makeYAxisConfigs();
    }
  }, [projectCustomFields, usecaseCustomFields, taskCustomFields]);

  useEffect(() => {
    if (
      projectXaxisChartConfig.length > 0 ||
      usecaseXaxisChartConfig.length > 0 ||
      taskXaxisChartConfig.length > 0 ||
      clientXaxisChartConfig.length > 0
    ) {
      setChartXaxisConfigs([
        ...projectXaxisChartConfig,
        ...usecaseXaxisChartConfig,
        ...taskXaxisChartConfig,
        ...clientXaxisChartConfig,
      ]);
    }
  }, [projectXaxisChartConfig, usecaseXaxisChartConfig, taskXaxisChartConfig, clientXaxisChartConfig]);

  return {
    chartXaxisConfigs,
    chartYaxisConfigs,
    getTimeDataKeysFor,
    getTimeActualDataKeyFromName,
    getAllChartXConfigsforTypeAny,
    getAllChartYConfigsForTypeAny,
    transformDataForCustomReport,
    getGroupedDataKeysFor,
    addRealtimeChartXaxisConfigAsPerData,
  };
};

export default useChartCustomizeDrawer;
