import { format } from 'date-fns';
import { getLoggedInUserUid, getUserUid } from 'utils/getUid';

export const updateUsecaseTemplate = (payload: any) => {
  const data = {
    check_duration: 0,
    feature: [...payload.features],
    product_id: '',
    product_uid: null,
    project_rollout: 0,
    section: [...payload.sections],
    template_case_created_on: format(new Date(), 'yyyy-MM-dd hh:MM:ss'),
    template_case_name: payload.templateName,
    template_case_target_days: payload.templateTargetDays,
    template_case_uid: payload.newTemplateUid,
    template_fees: '',
    template_owner_name: payload.templateOwner,
    template_owner_uid: getUserUid(payload.templateOwner),
    user_uid: getLoggedInUserUid(),
  };
  return data;
};
