import { differenceInDays } from 'date-fns';

export const getMinAndMaxDatesWithinTasks = (tasks: any) => {
  const startDates: any = [];
  for (let i = 0; i < tasks.length; i++) {
    startDates.push(new Date(tasks[i].task_start_date.replace(/-/g, '/')));
  }

  const dueDates: any = [];
  for (let i = 0; i < tasks.length; i++) {
    dueDates.push(new Date(tasks[i].task_due_date.replace(/-/g, '/')));
  }
  console.log(startDates, dueDates);

  const minDate = new Date(Math.min.apply(null, startDates));
  const maxDate = new Date(Math.max.apply(null, dueDates));
  const minAndMaxDate = {
    minDate,
    maxDate,
  };
  console.log(minAndMaxDate);
  return minAndMaxDate;
};

export const taskPriorityValues = () => ['Critical', 'High', 'Medium', 'Low'];

export const getRedTasks = (tasks: any) => {
  const redTasks = tasks.filter((task: any) => {
    return task.task_status == 'red';
  });
  return redTasks;
};

export const getAmberTasks = (tasks: any) => {
  const amberTasks = tasks.filter((task: any) => {
    return task.task_status == 'amber';
  });
  return amberTasks;
};

export const getGreenTasks = (tasks: any) => {
  const greenTasks = tasks.filter((task: any) => {
    return task.task_status == 'green';
  });
  return greenTasks;
};

export const getBlueTasks = (tasks: any) => {
  const blueTasks = tasks.filter((task: any) => {
    return task.task_status == 'blue';
  });
  return blueTasks;
};
export const getGreyTasks = (tasks: any) => {
  const greyTasks = tasks.filter((task: any) => {
    return task.task_status == 'grey';
  });
  return greyTasks;
};

export const getTasksWithTheirUsecase = (usecase: any) => {
  const result: any = {};
  for (let i = 0; i < usecase.length; i++) {
    const caseName = usecase[i].case_uid;
    console.log(i, usecase[i]);

    const tasks = [];
    for (let j = 0; j < usecase[i].section.length; j++) {
      for (let k = 0; k < usecase[i].section[j].task.length; k++) {
        tasks.push(usecase[i].section[j].task[k]);
      }
    }
    result[caseName] = tasks;
  }
  return result;
};

export const sendOnlyMileStoneTasks = (tasks: any) => {
  const mileStoneTasks = tasks.filter((task: any) => task.milestone_task == 1);
  return mileStoneTasks;
};

export const shouldUpdateDateOfDependents = (paths: any[], task: any, detail: any, shifted_by: any) => {
  // const paths: any[] = await dispatch(getTasksPathOfProject(task.project_uid)).unwrap();
  console.log(paths, task, detail);
  const immediatesArray = [];
  // let updateDetail = 0;
  if (paths.length > 0 && paths[0].length > 1) {
    for (let i = 0; i < paths.length; i++) {
      immediatesArray.push(
        differenceInDays(
          new Date(paths[i][1].task_start_date.split(' ')[0]),

          new Date(detail.task_closed_date.split(' ')[0])
        ) - 1
      );
    }
    const min = Math.min.apply(null, immediatesArray);
    console.log(immediatesArray, min);
    return shifted_by - min;
  } else {
    return shifted_by;
  }
};

// 1. Find closest child of the task - NOTE: Consider our class is T
// 2. For closest child find it's parents
// 3. if any of the parent is greater than T dont update dependency
// 4. If all parents are same dont move.
// 5. If all are lesser, find latest finish among them. our shift should not go lesser than that

export const shouldUpdateDateOfDependentsWhenMovingBackward = (task: any, parents: any[], allTasks: any[]) => {
  const resultObj: any = { shift: true };
  console.log(task, parents);
  const parentWithDetail = [];
  for (let parent = 0; parent < parents.length; parent++) {
    for (let t = 0; t < allTasks.length; t++) {
      if (parents[parent].task_parent_uid == allTasks[t].task_uid) {
        parentWithDetail.push(allTasks[t]);
      }
    }
  }

  // if any parent has grater end than T dont shift
  for (let i = 0; i < parentWithDetail.length; i++) {
    if (new Date(parentWithDetail[i].task_closed_date.split(' ')[0]) > new Date(task.task_closed_date.split(' ')[0])) {
      resultObj['shift'] = false;
      return resultObj;
    }
  }

  if (resultObj.shift) {
    // let isAllParentsEndAtSameDate = true;
    for (let i = 0; i < parentWithDetail.length; i++) {
      if (
        new Date(task.task_closed_date.split(' ')[0]).getTime() !=
        new Date(parentWithDetail[i].task_closed_date.split(' ')[0]).getTime()
      ) {
        console.log(
          new Date(task.task_closed_date.split(' ')[0]).getTime(),
          new Date(parentWithDetail[i].task_closed_date.split(' ')[0]).getTime()
        );
        // isAllParentsEndAtSameDate = false;
        resultObj['shift'] = false;
        return resultObj;
      }
    }
  }

  if (resultObj.shift) {
    // for late finishing parents our change should not be lesser that that.

    // Finding late finishing parent
    let lateFinishingParent = parentWithDetail[0].task_closed_date;
    for (let i = 1; i < parentWithDetail.length; i++) {
      if (new Date(parentWithDetail[0].task_closed_date.split(' ')[0]) > new Date(lateFinishingParent.split(' ')[0])) {
        lateFinishingParent = parentWithDetail[0].task_closed_date;
      }
    }
    console.log(lateFinishingParent);
    // check if our change is going lesser than late finishing parent
    if (new Date(task.task_closed_date.split(' ')[0]) < new Date(lateFinishingParent.split(' ')[0])) {
      resultObj['shift'] = false;
    }
  }

  return resultObj;

  //
};

export const nearestDate = (children: any, allTasks: any[]) => {
  console.log(children);
  // const dates = children.map((child: any) => child.task_start_date);
  // console.log(dates);
  // IF NO CHILDREN WE CAN SHICT BACKWARD NO PROBLEM

  let closest: any;
  const childrenWithDetail: any = [];
  for (let i = 0; i < children.length; i++) {
    for (let j = 0; j < allTasks.length; j++) {
      if (allTasks[j].task_uid == children[i].task_uid) {
        childrenWithDetail.push(allTasks[j]);
      }
    }
  }

  closest = childrenWithDetail[0];
  for (let i = 1; i < childrenWithDetail.length; i++) {
    if (
      new Date(childrenWithDetail[i].task_start_date.split(' ')[0]) < new Date(closest.task_start_date.split(' ')[0])
    ) {
      closest = childrenWithDetail[i];
    }
  }

  console.log('closesttt', closest);
  return closest;
};
