export function generateQuarterDetails(date: Date) {
  const m = date.getMonth();
  const y = date.getFullYear();
  let quarterDetails = '';
  if ([0, 1, 2].includes(m)) {
    quarterDetails += 'Q1 ';
  } else if ([3, 4, 5].includes(m)) {
    quarterDetails += 'Q2 ';
  } else if ([6, 7, 8].includes(m)) {
    quarterDetails += 'Q3 ';
  } else {
    quarterDetails += 'Q4 ';
  }

  quarterDetails += y.toString().substring(2);
  return quarterDetails;
}

export function quarterRangeGenerator(minDate: Date, maxDate: Date) {
  const qStart = parseInt(generateQuarterDetails(minDate)[1]);
  const qEnd = parseInt(generateQuarterDetails(maxDate)[1]);
  const yStart = minDate.getFullYear();
  const yEnd = maxDate.getFullYear();

  const values = [];
  for (let i = yStart; i <= yEnd; i++) {
    for (let j = 1; j <= 4; j++) {
      if (i === yStart) {
        if (j < qStart) {
          continue;
        }
      }

      if (i === yEnd) {
        if (j > qEnd) {
          break;
        }
      }

      values.push(`Q${j} ${i.toString().substring(2)}`);
    }
  }
  return values;
}

export function yearRangeGenerator(minDate: Date, maxDate: Date) {
  const yStart = minDate.getFullYear();
  const yEnd = maxDate.getFullYear();

  const values = [];
  for (let i = yStart; i <= yEnd; i++) {
    values.push(`${i}`);
  }
  return values;
}

// handling months
export function generateMonthDetails(date: Date) {
  const m = date.toLocaleString('default', { month: 'short' });
  const y = date.getFullYear();
  let monthDetails = m;

  monthDetails = monthDetails + ' ' + y.toString().substring(2);
  return monthDetails;
}

export function monthRangeGenerator(minDate: Date, maxDate: Date) {
  const mStart = minDate.getMonth();
  const mEnd = maxDate.getMonth();
  const yStart = minDate.getFullYear();
  const yEnd = maxDate.getFullYear();

  const values = [];
  for (let i = yStart; i <= yEnd; i++) {
    for (let j = 0; j <= 11; j++) {
      if (i === yStart) {
        if (j < mStart) {
          continue;
        }
      }

      if (i === yEnd) {
        if (j > mEnd) {
          break;
        }
      }

      values.push(
        `${new Date(`2022-${j + 1}-24 00:00:00`).toLocaleString('default', { month: 'short' })} ${i
          .toString()
          .substring(2)}`
      );
    }
  }
  return values;
}

export function getWeekNumber(date: Date) {
  const startDate: any = new Date(date.getFullYear(), 0, 1);
  const days = Math.floor(((date as any) - startDate) / (24 * 60 * 60 * 1000));
  return Math.ceil(days / 7);
}

export function generateWeekDetails(date: Date) {
  const y = date.getFullYear();
  return 'W' + getWeekNumber(date) + ' ' + y.toString().substring(2);
}

export function weekRangeGenerator(minDate: Date, maxDate: Date) {
  const wStart = getWeekNumber(minDate);
  const wEnd = getWeekNumber(maxDate);
  const yStart = minDate.getFullYear();
  const yEnd = maxDate.getFullYear();

  const values = [];
  for (let i = yStart; i <= yEnd; i++) {
    for (let j = 0; j <= 52; j++) {
      if (i === yStart) {
        if (j < wStart) {
          continue;
        }
      }

      if (i === yEnd) {
        if (j > wEnd) {
          break;
        }
      }

      values.push(`W${j} ${i.toString().substring(2)}`);
    }
  }
  return values;
}
