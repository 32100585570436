import CognisaasDropdown from '../CognisaasDropdown/CognisaasDropdown';
import MenuItem from '@mui/material/MenuItem';
import CognisaasTaskStageIndicator, { taskStage } from '../CognisaasTaskStageIndicator/CognisaasTaskStageIndicator';
import useCustomNames from '../CustomNames/useCustomNames';
import NAMED_CONSTATNTS from '../CustomNames/Constants';

interface Props {
  value:
    | taskStage.NOT_DONE
    | taskStage.NOT_STARTED
    | taskStage.IN_PROGRESS
    | taskStage.BLOCKED
    | taskStage.DONE
    | taskStage.DEFERRED;
  changeHandler: (event: any) => void;
  sx?: any;
  placeholder?: string;
  name?: any;
  readOnly?: boolean;
  isMileStoneTask: boolean;
  width?: string;
  renewalTask?: boolean;
}
const CognisaasStageSelect = (receivedProps: Props) => {
  const dropdownRestProps: any = { ...receivedProps };
  delete dropdownRestProps.value;
  delete dropdownRestProps.changeHandler;
  const { taskStagesDropdownValues, getCustomNameMappedValue } = useCustomNames();
  const taskAllStages = taskStagesDropdownValues.map((item: any) => item.custom_stage_name);

  return (
    <>
      {receivedProps.renewalTask === true ? (
        <CognisaasDropdown
          {...dropdownRestProps}
          isSmall={true}
          value={receivedProps.value}
          onChange={receivedProps.changeHandler}
          size="small"
          readOnly={receivedProps.readOnly}
          width={receivedProps.width}
          validValues={['Done', 'Not Done']}
        >
          <MenuItem
            value={taskStage.NOT_DONE}
            disabled={receivedProps.value === 'Done' || receivedProps.value === 'Not Done'}
          >
            <span style={{ display: 'inline-flex', alignItems: 'center' }}>
              <CognisaasTaskStageIndicator isMileStoneTask={false} stage={taskStage.NOT_STARTED} />
              <span style={{ marginLeft: '3px', fontSize: '14px' }}>{taskStage.NOT_DONE}</span>
            </span>
          </MenuItem>
          <MenuItem value={taskStage.DONE} disabled={receivedProps.value === 'Done'}>
            <span style={{ display: 'inline-flex', alignItems: 'center' }}>
              <CognisaasTaskStageIndicator isMileStoneTask={false} stage={taskStage.DONE} />
              <span style={{ marginLeft: '3px', fontSize: '14px' }}>{taskStage.DONE}</span>
            </span>
          </MenuItem>
        </CognisaasDropdown>
      ) : (
        <CognisaasDropdown
          {...dropdownRestProps}
          isSmall={true}
          value={receivedProps.value}
          onChange={receivedProps.changeHandler}
          size="small"
          readOnly={receivedProps.readOnly}
          width={receivedProps.width}
          validValues={taskAllStages}
        >
          <MenuItem value={taskStage.NOT_STARTED}>
            <span style={{ display: 'inline-flex', alignItems: 'center' }}>
              <CognisaasTaskStageIndicator
                isMileStoneTask={receivedProps.isMileStoneTask}
                stage={taskStage.NOT_STARTED}
              />
              <span style={{ marginLeft: '3px', fontSize: '14px' }}>
                {getCustomNameMappedValue(taskStage.NOT_STARTED, NAMED_CONSTATNTS.task_stage_key)}
              </span>
            </span>
          </MenuItem>
          <MenuItem value={taskStage.IN_PROGRESS}>
            <span style={{ display: 'inline-flex', alignItems: 'center' }}>
              <CognisaasTaskStageIndicator
                isMileStoneTask={receivedProps.isMileStoneTask}
                stage={taskStage.IN_PROGRESS}
              />
              <span style={{ marginLeft: '3px', fontSize: '14px' }}>
                {getCustomNameMappedValue(taskStage.IN_PROGRESS, NAMED_CONSTATNTS.task_stage_key)}
              </span>
            </span>
          </MenuItem>
          <MenuItem value={taskStage.BLOCKED}>
            <span style={{ display: 'inline-flex', alignItems: 'center' }}>
              <CognisaasTaskStageIndicator isMileStoneTask={receivedProps.isMileStoneTask} stage={taskStage.BLOCKED} />
              <span style={{ marginLeft: '3px', fontSize: '14px' }}>
                {getCustomNameMappedValue(taskStage.BLOCKED, NAMED_CONSTATNTS.task_stage_key)}
              </span>
            </span>
          </MenuItem>
          <MenuItem value={taskStage.DONE}>
            <span style={{ display: 'inline-flex', alignItems: 'center' }}>
              <CognisaasTaskStageIndicator isMileStoneTask={receivedProps.isMileStoneTask} stage={taskStage.DONE} />
              <span style={{ marginLeft: '3px', fontSize: '14px' }}>
                {getCustomNameMappedValue(taskStage.DONE, NAMED_CONSTATNTS.task_stage_key)}
              </span>
            </span>
          </MenuItem>
          <MenuItem value={taskStage.DEFERRED}>
            <span style={{ display: 'inline-flex', alignItems: 'center' }}>
              <CognisaasTaskStageIndicator isMileStoneTask={receivedProps.isMileStoneTask} stage={taskStage.DEFERRED} />
              <span style={{ marginLeft: '3px', fontSize: '14px' }}>
                {getCustomNameMappedValue(taskStage.DEFERRED, NAMED_CONSTATNTS.task_stage_key)}
              </span>
            </span>
          </MenuItem>
        </CognisaasDropdown>
      )}
    </>
  );
};

export default CognisaasStageSelect;
