import { useState } from 'react';
import Csat from './Csat/Csat';
import Project from './Project/Index';
import Team from './Team/index';
// import Project from './Project/Project';
import CognisaasTabs from '../../shared/CognisaasTabs';
import ReportsHeader, { ReportsPages } from '../ReportsHeader/ReportsHeader';
import { Box } from '@mui/material';
import { label_project } from 'components/Settings/Preferences/CustomLabelCookieHelper';

const Performance = () => {
  const tabs = ['CSAT', label_project, 'Team'];
  const [tabIndex, setTabIndex] = useState<number>(0);

  const returnComponentForSelectedTab = () => {
    if (tabIndex == 0) {
      return <Csat />;
    } else if (tabIndex == 1) {
      return <Project />;
    } else if (tabIndex == 2) {
      return <Team />;
    }
  };

  return (
    <Box sx={{ display: 'grid', gridTemplateRows: '70px auto' }}>
      <ReportsHeader pageName={ReportsPages.Performance} />

      <Box sx={{ display: 'grid', gridTemplateRows: '50px auto', m: '0px 20px' }}>
        <CognisaasTabs
          tabs={tabs}
          selectedTabIndex={tabIndex}
          handleTabSwitch={(e: any, index: number) => {
            console.log(e, index);
            setTabIndex(index);
          }}
        />
        {returnComponentForSelectedTab()}
      </Box>
    </Box>
  );
};

export default Performance;
