export const clientEntityStandardFieldsKey = {
  //clientEntityStandardFieldsDisplayName
  //   name: 'client_name',
  //   salesOwner: 'client_sales_owner',
  //   csOwner: 'client_account_owner',
  //   status: 'client_readiness_status',
  //   segment: 'segment_name',
  //   industry: 'industry_name',
  //   stage: 'stage_journerystage',
  //   accountWorth: 'client_account_worth',
  //   comment: 'client_comment',

  name: 'client_name',
  segment: 'segment_name',
  industry: 'industry_name',
  status: 'client_readiness_status',
  salesOwner: 'client_sales_owner',
  accountOwner: 'client_account_owner',
  accountWorth: 'client_account_worth',
  stage: 'stage_journerystage',
  createdOn: 'client_creaded_on',
  renewalDate: 'renewal_date',
  renewalDuration: 'renewal_duration',
  lastStatusReportSentTime: 'last_status_report_sent_time',
  comment: 'client_comment',
};

export const projectEntityStandardFieldsKey = {
  //   name: 'project_name',
  //   progress: 'project_progress',
  //   plannedStartDate: 'project_planned_startdate',
  //   startDate: 'project_startdate',
  //   etaForDelivery: 'eta_forprojectdelivery',
  //   dueDate: 'project_requiredbydate',
  //   deliveryDate: 'project_deliverydate',
  //   status: 'project_readinessstate_status',
  //   deliveryManager: 'project_deliverymanager',

  name: 'project_name',
  progress: 'project_progress',
  description: 'project_description',
  fee: 'project_fees',
  arr: 'project_ARR',
  reference: 'project_reference',
  value: 'project_value',
  neededStatus: 'project_needed_status',
  status: 'project_readinessstate_status',
  expansionValue: 'project_expansionvalue',
  priority: 'project_priority',
  plannedStartDate: 'project_planned_startdate',
  startDate: 'project_startdate',
  etaForDelivery: 'eta_forprojectdelivery',
  requiredByDate: 'project_requiredbydate',
  deliveryDate: 'project_deliverydate',
  createdOn: 'project_created_on',
  stage: 'project_stage',
  deliveryManager: 'project_deliverymanager',
  comment: 'project_comment',
  implementationFeeBilled: 'implementation_fee_billed',
  implementationFeePending: 'implementation_fee_pending',
};

export const usecaseEntityStandardFieldsKey = {
  //   name: 'case_name',
  //   progress: 'case_progress',
  //   plannedStartDate: 'case_planned_startdate',
  //   startDate: 'case_startdate',
  //   etaForDelivery: 'eta_forcasedelivery',
  //   dueDate: 'case_requiredbydate',
  //   deliveryDate: 'case_deliverydate',
  //   status: 'case_readinessstate_status',
  //   deliveryManager: 'case_deliverymanager',

  name: 'case_name',
  progress: 'case_progress',
  description: 'case_description',
  fee: 'case_fees',
  reference: 'case_reference',
  neededStatus: 'case_needed_status',
  status: 'case_readinessstate_status',
  expansionValue: 'case_expansionvalue',
  requiredByDate: 'case_requiredbydate',
  priority: 'case_priority',
  startDate: 'case_startdate',
  plannedStartDate: 'case_planned_startdate',
  deliveryDate: 'case_deliverydate',
  createdOn: 'case_creaded_on',
  stage: 'case_stage',
  deliveryManager: 'case_deliverymanager',
  comment: 'case_comment',
  etaForDelivery: 'eta_forcasedelivery',
};

export const taskEntityStandardFieldsKey = {
  //   name: 'task_name',
  //   owner: 'task_owner',
  //   status: 'task_status',
  //   stage: 'task_stage',
  //   priority: 'task_priority',
  //   plannedStartDate: 'task_planned_start_date',
  //   startDate: 'task_start_date',
  //   dueDate: 'task_due_date',
  //   deliveryDate: 'task_closed_date',
  //   effortEstimate: 'effort_estimate',
  //   timeSpent: 'total_time_spent',

  name: 'task_name',
  owner: 'task_owner',
  type: 'is_team_task',
  priority: 'task_priority',
  description: 'task_description',
  stage: 'task_stage',
  status: 'task_status',
  duedate: 'task_due_date',
  closedDate: 'task_closed_date',
  startDate: 'task_start_date',
  plannedStartDate: 'task_planned_start_date',
  createdOn: 'task_created_on',
  effortEstimate: 'effort_estimate',
  totalTimeSpent: 'total_time_spent',
  totalTimeRemaining: 'time_remaining',
  clientCheck: 'client_check',
  clientInitiated: 'client_initiate',
  milestoneTask: 'milestone_task',
  csatIndicator: 'csat_indicator',
  lastModifiedBy: 'last_user_modified',
  jiraIndicator: 'jira_ind',
  implementationFeePercentage: 'task_implementation_fee_percentage',
};
