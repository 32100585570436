import {
  Autocomplete,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { selectAggregatedCardsState } from 'store/modules/Common/selector';
import { createAggregationCard, getAllAggregatedCards, updateAggregationCard } from 'store/modules/Common/slice';
import { getCustomerUid, getLoggedInUserUid } from 'utils/getUid';
import CognisaasButton from '../CognisaasButton/CognisaasButton';
import CognisaasSwitchButton from '../CognisaasSwitch/CognisaasSwitch';
import AggregatedCard from './AggregatedCard';
import { allEntityOptions } from './EntityConfig';
import { EntityOption } from './types';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  idName: string;
  cardUid?: string;
  sampleData: Record<string, any>;
}

const AlterAggregationCardDrawer = (props: Props) => {
  const { open, setOpen } = props;
  const [aggregatedCardName, setAggregatedCardName] = useState<string>('');
  const [selectedAggregation, setSelectedAggregation] = useState<EntityOption>(allEntityOptions[0]);
  const [selectedOperation, setSelectedOperation] = useState<any>(allEntityOptions[0].aggregableOptions[0]);
  const [isUnitNeedToAdd, setIsUnitNeedToAdd] = useState<boolean>(false);
  const [isUnitPrefixed, setIsUnitPrefixed] = useState<boolean>(false);
  const [unitString, setUnitString] = useState<string>('');
  const [shouldUseNumberAnnotations, setShouldUseNumberAnnotations] = useState<boolean>(false);
  const [sharedWithCompany, setSharedWithCompany] = useState<boolean>(false);
  const [globalWriteAccess, setGlobalWriteAccess] = useState<boolean>(false);
  const dispatch = useDispatch();

  const allAggregatedCardsState = useAppSelector(selectAggregatedCardsState);
  let selectedCard = {};
  if (props.cardUid) {
    selectedCard = allAggregatedCardsState.data.filter((item: any) => item.card_uid === props.cardUid)[0];
  }
  const keysInSampleData = Object.keys(props.sampleData);
  const entityFieldsToAggregateList = allEntityOptions.filter((item) => keysInSampleData.includes(item.dataKey));

  console.log('carduid', props.cardUid, selectedCard);

  const onClose = () => {
    setOpen(false);
  };

  const resetValues = () => {
    setAggregatedCardName('');
    setSelectedAggregation(allEntityOptions[0]);
    setSelectedOperation(allEntityOptions[0].aggregableOptions[0]);
    setIsUnitNeedToAdd(false);
    setIsUnitPrefixed(false);
    setUnitString('');
    setShouldUseNumberAnnotations(false);
    setSharedWithCompany(false);
    setGlobalWriteAccess(false);
  };

  const disableSaveButton = (): boolean => {
    if (aggregatedCardName.length === 0) {
      return true;
    }
    return false;
  };

  const handleSave = async () => {
    const payload: any = {
      customerUid: getCustomerUid(),
      cardName: aggregatedCardName,
      fieldToAggregateKey: selectedAggregation.dataKey,
      operationIdToPerform: selectedOperation.operationId,
      unitString: unitString,
      isUnitPrefixed: isUnitPrefixed,
      useAnnotations: shouldUseNumberAnnotations,
      shareWithCompany: sharedWithCompany,
      globalWriteAccess: globalWriteAccess,
      lastUpdateBy: getLoggedInUserUid(),
      userUid: getLoggedInUserUid(),
      idNameForUi: props.idName,
    };
    if (props.cardUid) {
      dispatch(updateAggregationCard({ ...payload, cardUid: props.cardUid }));
    } else {
      await dispatch(createAggregationCard(payload));
    }
    onClose();
    dispatch(getAllAggregatedCards({ customerUid: getCustomerUid(), userUid: getLoggedInUserUid() }));
  };

  const setCardData = (cardDetails: any) => {
    setAggregatedCardName(cardDetails.card_name);
    const entityToSelect = allEntityOptions.filter((item) => item.dataKey === cardDetails.field_to_aggregate_key);
    setSelectedAggregation(entityToSelect[0]);
    const operationToSelect = entityToSelect[0].aggregableOptions.filter(
      (item: any) => item.operationId === cardDetails.operation_id_to_perform
    );
    setSelectedOperation(operationToSelect[0]);
    setIsUnitNeedToAdd(cardDetails.unit_string.length > 0 ? true : false);
    setIsUnitPrefixed(cardDetails.is_unit_prefixed === '1' ? true : false);
    setUnitString(cardDetails.unit_string);
    setShouldUseNumberAnnotations(cardDetails.use_annotations === '1' ? true : false);
    setSharedWithCompany(cardDetails.share_with_company === '1' ? true : false);
    setGlobalWriteAccess(cardDetails.global_write_access === '1' ? true : false);
  };

  useEffect(() => {
    resetValues();
    return () => {
      resetValues();
    };
  }, []);

  useEffect(() => {
    if (props.cardUid && selectedCard) {
      setCardData(selectedCard);
    }
  }, [props.cardUid]);

  return (
    <Drawer anchor={'right'} open={open} onClose={onClose}>
      <Stack sx={{ margin: '70px 24px 0px 16px' }} direction="row">
        {/* preview side */}
        <Stack sx={{ width: '250px', height: '80vh' }} mt={2}>
          <Typography variant="h2" className="label-text">
            Preview
          </Typography>
          <Stack alignItems={'center'} justifyContent={'center'} sx={{ width: '100%', height: '100%' }}>
            <AggregatedCard
              header={aggregatedCardName.length === 0 ? 'aggregation card name' : aggregatedCardName}
              value={'20000'}
              color={'#2d60f6'}
              isUnitPrefixed={isUnitPrefixed}
              unitString={unitString}
              useAnnotations={shouldUseNumberAnnotations}
            />
          </Stack>
        </Stack>
        <Divider orientation="vertical" />

        {/* main form */}
        <Stack sx={{ width: '300px' }} ml={2} mt={2}>
          <Typography variant="h2">
            {props.cardUid ? 'Update aggregated card' : 'Create new aggregated card'}
          </Typography>

          <Typography mt={3} mb={0.5} variant="subtitle2" className="label-text">
            Card name here
          </Typography>
          <TextField
            type="text"
            sx={{ width: '280px' }}
            size="small"
            placeholder="Untitled"
            value={aggregatedCardName}
            onChange={(e: any) => setAggregatedCardName(e.target.value)}
            // disabled={!userPermissions?.includes(userPermissionSchema.EDIT)}
          ></TextField>

          <Typography mt={3} mb={0.5} variant="subtitle2" className="label-text">
            Choose a field to aggregate
          </Typography>
          <Autocomplete
            // disablePortal
            // id="combo-box-demo"
            // value={}
            onChange={(e: any, option: any) => {
              setSelectedAggregation(option);
              setSelectedOperation(option.aggregableOptions[0]);
            }}
            value={selectedAggregation}
            getOptionLabel={(option: any) => option.label}
            size="small"
            options={entityFieldsToAggregateList}
            fullWidth={false}
            sx={{ width: 280 }}
            renderInput={(params) => <TextField {...params} placeholder="select" />}
          />
          <Typography mt={3} mb={0.5} variant="subtitle2" className="label-text">
            Operations
          </Typography>
          <FormControl sx={{ marginLeft: '12px' }}>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="grouped"
              name="radio-buttons-group"
              row={true}
              onChange={(e: any) => {
                setSelectedOperation(
                  selectedAggregation?.aggregableOptions.filter((item: any) => item.label === e.target.value)[0]
                );
              }}
              value={selectedOperation.label}
            >
              {selectedAggregation?.aggregableOptions?.map((item: any, index: number) => {
                return (
                  <FormControlLabel
                    key={index}
                    sx={{ marginRight: '16px' }}
                    value={item.label}
                    control={<Radio />}
                    label={item.label}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>

          <Stack mt={3} direction={'row'} spacing={1} alignItems={'center'}>
            <Typography variant="subtitle1">Add Unit</Typography>
            <CognisaasSwitchButton
              handleChange={(e: any) => {
                setIsUnitNeedToAdd(e.target.checked);
                setIsUnitPrefixed(false);
                setUnitString('');
              }}
              checked={isUnitNeedToAdd}
            />
          </Stack>
          {isUnitNeedToAdd && (
            <>
              <Stack>
                <Typography mt={1} mb={0.5} variant="subtitle2" className="label-text">
                  Make Prefix
                </Typography>
                <CognisaasSwitchButton
                  handleChange={(e: any) => {
                    setIsUnitPrefixed(e.target.checked);
                    // if (e.target.checked) {
                    //   setWriteAccess(false);
                    // }
                  }}
                  checked={isUnitPrefixed}
                />
              </Stack>

              <Typography mt={3} mb={0.5} variant="subtitle2" className="label-text">
                Unit
              </Typography>
              <TextField
                type="text"
                error={unitString.length > 3}
                helperText={
                  unitString.length > 3 ? (
                    <Typography sx={{ margin: '-4px -14px' }} variant="subtitle2" className="cogni-red">
                      Unit can only be 3 characters long
                    </Typography>
                  ) : (
                    <></>
                  )
                }
                sx={{ width: '280px' }}
                size="small"
                placeholder="INR, $, etc.."
                value={unitString}
                onChange={(e: any) => setUnitString(e.target.value)}
              ></TextField>
            </>
          )}

          <Typography mt={3} mb={0.5} variant="subtitle2" className="label-text">
            Use Annotations
          </Typography>
          <CognisaasSwitchButton
            handleChange={(e: any) => {
              setShouldUseNumberAnnotations(e.target.checked);
            }}
            checked={shouldUseNumberAnnotations}
          />

          <Grid container>
            <Grid item xs={6}>
              <Typography mt={3} mb={0.5} variant="subtitle2" className="label-text">
                Share with company
              </Typography>
              <CognisaasSwitchButton
                handleChange={(e: any) => {
                  setSharedWithCompany(e.target.checked);
                  setGlobalWriteAccess(false);
                }}
                checked={sharedWithCompany}
              />
            </Grid>
            <Grid item xs={6}>
              {sharedWithCompany && (
                <>
                  <Typography mt={3} mb={0.5} variant="subtitle2" className="label-text">
                    Allow others to edit
                  </Typography>
                  <CognisaasSwitchButton
                    handleChange={(e: any) => {
                      setGlobalWriteAccess(e.target.checked);
                    }}
                    checked={globalWriteAccess}
                  />
                </>
              )}
            </Grid>
          </Grid>

          {/* Action buttons   */}
          <Stack mt={2} direction={'row'} spacing={1} justifyContent={'flex-end'}>
            <CognisaasButton isOutlined={true} label={'Cancel'} clickHandler={onClose} />
            <CognisaasButton
              disabled={disableSaveButton()}
              isOutlined={false}
              label={'Save'}
              clickHandler={handleSave}
            />
          </Stack>
        </Stack>
      </Stack>
    </Drawer>
  );
};

export default AlterAggregationCardDrawer;
