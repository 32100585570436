import { IconButton, MenuItem, Skeleton } from '@mui/material';
import { useState } from 'react';
import { ReactComponent as FilterIcon } from '../../../../assets/svgs/filter.svg';
import CognisaasMenuList from '../../CognisaasMenu/CognisaasMenuList';
import CognisaasCheckBox from '../../CognisaasCheckbox/CognisaasCheckbox';
import { updateFilter } from '../../../../store/modules/AllFilters/slice';
import { useDispatch } from 'react-redux';
import { selectAllFilters } from '../../../../store/modules/AllFilters/selector';
import { useAppSelector } from '../../../../store/hooks';
import { isNull, isUndefined } from 'utils/dataHelpers';

interface FilterTypeSelectorProps {
  idName: string;
}

const FilterTypeSelector = (props: FilterTypeSelectorProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const allFilters = useAppSelector(selectAllFilters);
  const dispatch = useDispatch();

  const handleFilterIconClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClicked = (filter: any) => {
    // console.log('option clicked ', filter);
    const newUpdatedFilter = {
      name: filter.name,
      dataKey: filter.dataKey,
      isSelected: !filter.isSelected,
      equalToValue: filter.equalToValue,
      canBeCleared: filter.canBeCleared,
      availableOptions: filter.availableOptions,
    };

    if (filter.groupName) {
      dispatch(
        updateFilter({
          idName: props.idName,
          updatedFilter: { ...newUpdatedFilter, groupName: filter.groupName },
        })
      );
    } else {
      dispatch(
        updateFilter({
          idName: props.idName,
          updatedFilter: newUpdatedFilter,
        })
      );
    }
  };

  return (
    <>
      <IconButton onClick={handleFilterIconClick} size="small">
        <FilterIcon />
      </IconButton>
      <CognisaasMenuList open={open} anchorEl={anchorEl} handleDropDownClose={handleClose}>
        {isUndefined(allFilters[props.idName]) ||
          isNull(allFilters[props.idName]) ||
          (allFilters[props.idName]?.appliedFilters?.length == 0 && (
            <>
              <Skeleton variant="rectangular" width={210} height={27} sx={{ margin: '4px' }} />
              <Skeleton variant="rectangular" width={210} height={27} sx={{ margin: '4px' }} />
            </>
          ))}
        {allFilters[props.idName]?.appliedFilters.map((filter, index) => {
          return (
            <MenuItem dense={true} key={index} onClick={() => handleOptionClicked(filter)}>
              <CognisaasCheckBox
                checked={filter.isSelected}
                value={filter.name}
                sx={{
                  marginRight: '8px',
                }}
              />
              {isUndefined(filter.name.split('$$')[1]) ? filter.name : filter.name.split('$$')[0]}
            </MenuItem>
          );
        })}
      </CognisaasMenuList>
    </>
  );
};

export default FilterTypeSelector;
