import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GlobalSearchAPI } from '../../../api/modules/globalSearch';
import { requestState } from '../types';

interface authType {
  results: any;
  status: requestState;
  error: null | undefined | string;
  client: any;
  isLoading: boolean;
  showData: boolean;
  isClient: boolean;
  isProjects: boolean;
  useCase: boolean;
  teamTask: boolean;
  clientTask: boolean;
}

const initialState: authType = {
  results: {},
  status: requestState.idle,
  error: null,
  client: [],
  isLoading: false,
  showData: false,
  isClient: false,
  isProjects: false,
  useCase: false,
  teamTask: false,
  clientTask: false,
};

export const getGlobalSearchContentByCustomerUid = createAsyncThunk(
  'user/complete-account',
  async (requestObject: any) => {
    const { data } = await GlobalSearchAPI.getGlobalSearchContentByCustomerUid(requestObject);
    return data.result;
  }
);

const globalSlice = createSlice({
  name: 'globalSearch',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getGlobalSearchContentByCustomerUid.pending, (state) => {
        state.status = requestState.loading;
        state.isLoading = true;
      })
      .addCase(getGlobalSearchContentByCustomerUid.fulfilled, (state: authType, { payload }) => {
        console.log(payload);
        state.status = requestState.success;
        state.showData = true;
        state.isLoading = false;
        state.results = payload;
        if (payload.client.length > 0) {
          console.log('is client');
          state.isClient = true;
        } else state.isClient = false;
        if (payload.usecase.length > 0) {
          console.log('is usecase');
          state.useCase = true;
        } else state.useCase = false;
        if (payload.project.length > 0) {
          console.log('is isProjects');
          state.isProjects = true;
        } else state.isProjects = false;
        if (payload.task.length > 0) {
          console.log('is isProjects');
          state.teamTask = true;
        } else state.teamTask = false;
        if (payload.client_task.length > 0) {
          console.log('is teamTask');
          state.clientTask = true;
        } else state.clientTask = false;
        // console.log(payload, 'new slice state');
      })
      .addCase(getGlobalSearchContentByCustomerUid.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      });
  },
});

const globalSearchSlice = globalSlice.reducer;
export default globalSearchSlice;
