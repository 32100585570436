import { apis } from 'api/axiosInstance';
// import { getUserUid } from 'utils/getUid';
import {
  addProhectTemplateTask,
  addUsecaseTemplateTask,
} from '../Project/AddProjectTemplate/ProjectTemplatePlan/AddProjectTemplatePlanHelper';

export const addPlanFromExcel = async (uid: any, name: any, owner: any, sections: any[], days: any, rows: any[]) => {
  console.log(rows);
  const phases: string[] = [];
  for (let i = 0; i < rows.length; i++) {
    // if (rows[i].phase_name != '' && rows[i].task_name != '') {
    if (rows[i].phase_name != '') {
      phases.push(rows[i].phase_name);
    }

    // }
    // else if (rows[i].phase_name == '' && rows[i].task_name == '') {
    //   console.log('LAST ROW', i);
    //   break;
    // }
  }
  const uniquePhases = Array.from(new Set(phases));

  console.log(uniquePhases);
  //   Add these phases in DB
  const newPhases: any[] = [];
  for (let i = 0; i < uniquePhases.length; i++) {
    let detail: any = {
      template_project_uid: uid,
      template_section_detail: uniquePhases[i],
      template_section_sequence: i,
    };
    const section = await apis.phpAxiosInstance.post('saveTemplateProjectSection', detail, {
      headers: { JWT: 'true' },
    });
    console.log(section);
    detail = {
      uid: section.data.result.template_section_uid,
      name: section.data.result.template_section_detail,
    };
    newPhases.push(detail);
  }

  console.log(newPhases);

  //   Add all tasks
  const newTasks: any[] = [];
  for (let i = 0; i < rows.length; i++) {
    if (rows[i].phase_name != '' && rows[i].task_name != '') {
      console.log(owner);
      const taskDetail = {
        taskOwner: { name: rows[i].task_owner ? rows[i].task_owner : 'Not Assigned' },
        clone_template_task_uid: '',
        durationTime: rows[i].duration,
        isMilestone: rows[i].milestone == 'Yes' ? true : false,
        task_sequence: i,
        template_project_uid: uid,
        sectionUid: getSectionUid(newPhases, rows[i].phase_name),
        shareWithClient: rows[i].share_with_client == 'Yes' ? true : false,
        description: '',
        taskName: rows[i].task_name,
        // taskOwner: owner,
        implementationFee: rows[i].implementation_fee ? rows[i].implementation_fee.toLocaleString() : '0',
        priority: rows[i].task_priority.length > 0 ? rows[i].task_priority : 'Medium',

        // user_uid: {name: owner},
        // template_project_uid: uid,
        // template_task_detail: rows[i].task_name,
        // template_task_priority: 'Medium',
        // template_task_owner: 'Not Assigned',
        // template_milestone_task: '0',
        // template_task_description: '',
        // template_task_percentage: 0,
        // clone_template_task_uid: '',
        // template_section_uid: getSectionUid(newPhases, rows[i].phase_name),
        // days_to_start: 0,
        // task_sequence: i,
        // user_uid: '15243',
        // template_task_client_check: '0',
      };
      console.log(taskDetail);
      const data = addProhectTemplateTask({ ...taskDetail, template_task_percentage: 0 });
      console.log(data);
      let task: any;
      if (rows[i].task_type == 'External') {
        task = await apis.phpAxiosInstance.post('addTemplateProjectClientTask', data, {
          headers: { JWT: 'true' },
        });
      } else {
        task = await apis.phpAxiosInstance.post('addTemplateProjectTask', data, {
          headers: { JWT: 'true' },
        });
      }

      const taskD = {
        task_uid: task.data.result.template_task_uid,
        section_uid: task.data.result.template_section_uid,
        task_name: task.data.result.template_task_detail,
        id: rows[i].id,
      };
      newTasks.push(taskD);

      //   Check if there are predecessors in excel
      if (rows[i].predecessors.length > 0) {
        const dependencies = rows[i].predecessors.split(',');
        console.log('deps', dependencies);
        for (let d = 0; d < dependencies.length; d++) {
          const dependencyDetail: any = {
            project_uid: uid,
            case_uid: '',
            task_parent_type: 'client',
            task_parent_uid: getTaskUidByRowId(dependencies[d], newTasks),
            task_type: 'company',
            task_uid: task.data.result.template_task_uid,
          };
          await apis.phpAxiosInstance.post('addProjectTaskDependency', dependencyDetail, {
            headers: { JWT: 'true' },
          });
        }
      }
    }
  }
};

const getSectionUid = (phases: any, name: any) => {
  for (let i = 0; i < phases.length; i++) {
    if (phases[i].name == name) {
      return phases[i].uid;
    }
  }
};

const getTaskUidByRowId = (row_id: any, rows: any[]) => {
  for (let i = 0; i < rows.length; i++) {
    if (rows[i].id == row_id) {
      return rows[i].task_uid;
    }
  }
};

// USECASE
export const addPlanFromExcelUsecase = async (
  uid: any,
  name: any,
  owner: any,
  sections: any[],
  days: any,
  rows: any[]
) => {
  console.log(rows);
  const phases: string[] = [];
  for (let i = 0; i < rows.length; i++) {
    // if (rows[i].phase_name != '' && rows[i].task_name != '') {
    if (rows[i].phase_name != '') {
      phases.push(rows[i].phase_name);
    }

    // }
    // else if (rows[i].phase_name == '' && rows[i].task_name == '') {
    //   console.log('LAST ROW', i);
    //   break;
    // }
  }
  const uniquePhases = Array.from(new Set(phases));

  console.log(uniquePhases);
  //   Add these phases in DB
  const newPhases: any[] = [];
  for (let i = 0; i < uniquePhases.length; i++) {
    let detail: any = {
      template_case_uid: uid,
      template_section_detail: uniquePhases[i],
      template_section_sequence: i,
    };
    const section = await apis.phpAxiosInstance.post('saveTemplateUsecaseSection', detail, {
      headers: { JWT: 'true' },
    });
    console.log(section);
    detail = {
      uid: section.data.result.template_section_uid,
      name: section.data.result.template_section_detail,
    };
    newPhases.push(detail);
  }

  console.log(newPhases);

  //   Add all tasks
  const newTasks: any[] = [];
  for (let i = 0; i < rows.length; i++) {
    if (rows[i].phase_name != '' && rows[i].task_name != '') {
      console.log(owner);
      const taskDetail = {
        taskOwner: { name: rows[i].task_owner ? rows[i].task_owner : 'Not Assigned' },
        clone_template_task_uid: '',
        durationTime: rows[i].duration,
        isMilestone: rows[i].milestone == 'Yes' ? true : false,
        task_sequence: i,
        template_case_uid: uid,
        sectionUid: getSectionUidUsecase(newPhases, rows[i].phase_name),
        shareWithClient: rows[i].share_with_client == 'Yes' ? true : false,
        description: '',
        taskName: rows[i].task_name,
        // taskOwner: owner,
        implementationFee: rows[i].implementation_fee ? rows[i].implementation_fee.toLocaleString() : '0',
        priority: rows[i].task_priority.length > 0 ? rows[i].task_priority : 'Medium',

        // user_uid: {name: owner},
        // template_project_uid: uid,
        // template_task_detail: rows[i].task_name,
        // template_task_priority: 'Medium',
        // template_task_owner: 'Not Assigned',
        // template_milestone_task: '0',
        // template_task_description: '',
        // template_task_percentage: 0,
        // clone_template_task_uid: '',
        // template_section_uid: getSectionUid(newPhases, rows[i].phase_name),
        // days_to_start: 0,
        // task_sequence: i,
        // user_uid: '15243',
        // template_task_client_check: '0',
      };
      console.log(taskDetail);
      const data = addUsecaseTemplateTask({ ...taskDetail, template_task_percentage: 0 });
      console.log(data);
      let task: any;
      if (rows[i].task_type == 'External') {
        task = await apis.phpAxiosInstance.post('addTemplateUsecaseClientTask', data, {
          headers: { JWT: 'true' },
        });
      } else {
        task = await apis.phpAxiosInstance.post('addTemplateUsecaseTask', data, {
          headers: { JWT: 'true' },
        });
      }

      const taskD = {
        task_uid: task.data.result.template_task_uid,
        section_uid: task.data.result.template_section_uid,
        task_name: task.data.result.template_task_detail,
        id: rows[i].id,
      };
      newTasks.push(taskD);

      //   Check if there are predecessors in excel
      if (rows[i].predecessors.length > 0) {
        const dependencies = rows[i].predecessors.split(',');
        console.log('deps', dependencies);
        for (let d = 0; d < dependencies.length; d++) {
          const dependencyDetail: any = {
            case_uid: uid,
            project_uid: '',
            task_parent_type: 'client',
            task_parent_uid: getTaskUidByRowIdUsecase(dependencies[d], newTasks),
            task_type: 'company',
            task_uid: task.data.result.template_task_uid,
          };
          await apis.phpAxiosInstance.post('addTaskDependency', dependencyDetail, {
            headers: { JWT: 'true' },
          });
        }
      }
    }
  }
};

const getSectionUidUsecase = (phases: any, name: any) => {
  for (let i = 0; i < phases.length; i++) {
    if (phases[i].name == name) {
      return phases[i].uid;
    }
  }
};

const getTaskUidByRowIdUsecase = (row_id: any, rows: any[]) => {
  for (let i = 0; i < rows.length; i++) {
    if (rows[i].id == row_id) {
      return rows[i].task_uid;
    }
  }
};
