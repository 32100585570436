export const GRAPH_COLORS = [
  '#8D74F8',
  '#9DE2CD',
  '#F2CC79',
  '#EF996B',
  '#F6F1F1',
  '#79B1F9',
  '#C16FE4',
  '#DD736D',
  '#72A0DF',
  '#F3A9FF',
  '#8DCCB5',
  '#BAA9FF',
];
