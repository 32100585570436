import { Button, ButtonGroup, Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import { GridColumns } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
// import { ReactComponent as RightArrowIcon } from '../../../assets/svgs/rightArrow.svg';
import { useAppSelector } from '../../../store/hooks';
import { selectAllFilters } from '../../../store/modules/AllFilters/selector';
import { addFilterGroup } from '../../../store/modules/AllFilters/slice';
import { useNavigate } from 'react-router-dom';
import {
  fetchAllUsecasesWhereDeliveryManagerisNotAssigned,
  fetchAllProjectWhereDeliveryManagerisNotAssigned,
} from 'store/modules/ActionableInsights/slice';
// import { generateMenuOptions } from '../../../store/modules/ui/CommonData/slice';
// import { FilterableData } from '../../../utils/filteringFunctions';
// import FilterTypeSelector from '../../shared/CognisaasFilters/FilterTypeSelector/FilterTypeSelector';
// import SelectedFilterList from '../../shared/CognisaasFilters/SelectedFilterList/SelectedFilterList';
// import DataBox from './DataBox/DataBox';
import CogniTable, { AvatarCell } from '../ActionableInsightTable/Table';

import { tempData } from './data';
import BasicModal from '../../shared/CognisaasModal/CognisaasModal';
import CognisaasButton from '../../shared/CognisaasButton/CognisaasButton';
import theme from '../../../plugins/materialUI';
// import { generateOptionsForThisDataKey } from 'utils/filteringFunctions';
import { formatCurrency, isNull, isUndefined } from 'utils/dataHelpers';
// import { getLoggedInUserName } from 'utils/getUid';
import {
  selectUsecasesWhereDeliveryManagerIsNotAssigned,
  selectProjectWhereDeliveryManagerIsNotAssigned,
} from 'store/modules/ActionableInsights/selector';
import { getSegmentName } from 'utils/getUid';
import { setUpFiltersForClientListPage } from 'store/modules/ui/Filters/slice';
import { selectCustomerDetails } from 'store/modules/Settings/selector';
import { getCustomerDetails } from 'store/modules/Settings/slice';
import {
  label_client,
  label_cs_owner,
  label_project,
  label_project_delivery_manager,
  label_use_case,
  label_use_case_delivery_manager,
} from 'components/Settings/Preferences/CustomLabelCookieHelper';
import CognisaasToast from 'components/shared/CognisaasToast/CognisaasToast';
import useCustomNames from 'components/shared/CustomNames/useCustomNames';

const ResoursePlanning = () => {
  const navigate = useNavigate();
  const usecaseColumnMappings = [
    {
      field: 'project_uid',
      hide: true,
    },
    {
      field: 'case_name',
      headerName: label_use_case,
      minWidth: 240,
      flex: 1,
      renderCell: (params: any) => (
        <Typography
          className="cognisaas-pointer"
          variant="subtitle1"
          sx={{ color: '#2D60F6' }}
          onClick={() =>
            navigate(
              `/projects/project/${params.row.association_uid}/${params.row.project_uid}/${params.row.case_uid}`,
              { state: { clientUid: params.row.client_uid, clientName: params.row.client_name } }
            )
          }
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: 'case_deliverymanager',
      headerName: label_use_case_delivery_manager,
      minWidth: 200,
      flex: 1,
      // renderCell: (params: any) =>
      //   AssignCell(
      //     params,
      //     [
      //       { name: 'Manager 1', availabilityPercent: '60' },
      //       { name: 'Manager 2', availabilityPercent: '60' },
      //     ],
      //     handleDeliveryManagerMenuOptionClicked
      //   ),
    },
    {
      field: 'case_needed_status',
      headerName: 'Category',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'segment_uid',
      headerName: `${label_client} Segment`,
      minWidth: 200,
      flex: 1,
      renderCell: (params: any) => <Typography variant="subtitle1">{getSegmentName(params.value)}</Typography>,
    },
    {
      field: 'project_name',
      headerName: label_project,
      minWidth: 200,
      flex: 1,
      renderCell: (params: any) => (
        <Typography
          variant="subtitle1"
          className="cognisaas-pointer"
          sx={{ color: '#2D60F6' }}
          onClick={() => navigate(`/projects/project/${params.row.association_uid}/${params.row.project_uid}`)}
        >
          {params.value}
        </Typography>
      ),
    },

    {
      field: 'client_account_owner',
      headerName: label_cs_owner,
      minWidth: 200,
      flex: 1,
      renderCell: AvatarCell,
    },
    {
      field: 'client_account_worth',
      headerName: 'Revenue Impact',
      minWidth: 200,
      flex: 1,
      renderCell: (params: any) => {
        return formatCurrency(params.row[params.field], customerDetail?.currency_code);
      },
    },
  ];

  const biggerScreens = useMediaQuery(theme.breakpoints.up('md'));
  const usecasesNotAssigned = useAppSelector(selectUsecasesWhereDeliveryManagerIsNotAssigned);
  const projectsNotAssigned = useAppSelector(selectProjectWhereDeliveryManagerIsNotAssigned);

  const [selectedTab, setSelectedTab] = useState<'Usecase' | 'Project' | 'Task'>('Usecase');
  const dispatch = useDispatch();
  const allFilters = useAppSelector(selectAllFilters);
  const [alertDetails, setAlertDetails] = useState<{
    isVisible: boolean;
    type: 'success' | 'failure';
    message: string;
  }>({ isVisible: false, type: 'success', message: '' });
  // table states
  const [filteredData, setFilteredData] = useState<any[]>(tempData);
  const [tablecolumnsStructure, setTablecolumnsStructure] = useState<GridColumns<any>>(usecaseColumnMappings);
  // const [tableUniqueRowId, setTableuniqueRowId] = useState<string>('');
  // delivery manager states
  const [isDeliveryManagerModalOpened, setIsDeliveryManagerModalOpened] = useState(false);
  const [toggledRowData, setToggledRowData] = useState<Record<string, string>>({});
  // const [newDeliveryManagerName, setNewDeliveryManagerName] = useState<string>('');
  const customerDetail = useAppSelector(selectCustomerDetails).data;
  const { displayDataWithCustomNames } = useCustomNames();

  const projectColumnMappings = [
    {
      field: 'project_uid',
      hide: true,
    },
    {
      field: 'project_name',
      headerName: label_project,
      minWidth: 240,
      flex: 1,
      renderCell: (params: any) => (
        <Typography
          variant="subtitle1"
          className="cognisaas-pointer"
          sx={{ color: '#2D60F6' }}
          onClick={() => navigate(`/projects/project/${params.row.association_uid}/${params.row.project_uid}`)}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: 'project_deliverymanager',
      headerName: label_project_delivery_manager,
      minWidth: 200,
      flex: 1,
      // renderCell: (params: any) =>
      //   AssignCell(
      //     params,
      //     [
      //       { name: 'Manager 1', availabilityPercent: '60' },
      //       { name: 'Manager 2', availabilityPercent: '60' },
      //     ],
      //     handleDeliveryManagerMenuOptionClicked
      //   ),
    },
    {
      field: 'project_needed_status',
      headerName: 'Category',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'segment_uid',
      headerName: 'Account Segment',
      minWidth: 200,
      flex: 1,
      renderCell: (params: any) => <Typography variant="subtitle1">{getSegmentName(params.value)}</Typography>,
    },
    // {
    //   field: 'project_name',
    //   headerName: 'Project',
    //   minWidth: 140,
    //   flex: 1,
    //   renderCell: (params: any) => FieldWithStatus(params, 'project_name', 'case_readinessstate_status'),
    // },

    {
      field: 'client_account_owner',
      headerName: label_cs_owner,
      minWidth: 200,
      flex: 1,
      renderCell: AvatarCell,
    },
    {
      field: 'client_account_worth',
      headerName: 'Revenue Impact',
      minWidth: 200,
      flex: 1,
      renderCell: (params: any) => {
        return formatCurrency(params.row[params.field], customerDetail?.currency_code);
      },
    },
  ];

  useEffect(() => {
    dispatch(setUpFiltersForClientListPage());

    dispatch(getCustomerDetails());
  }, []);

  useEffect(() => {
    if (selectedTab === 'Usecase') {
      setTablecolumnsStructure(usecaseColumnMappings);
      dispatch(fetchAllUsecasesWhereDeliveryManagerisNotAssigned('Not Assigned'));
    } else if (selectedTab == 'Project') {
      setTablecolumnsStructure(projectColumnMappings);
      dispatch(fetchAllProjectWhereDeliveryManagerisNotAssigned('Not Assigned'));
    }
  }, [selectedTab]);

  useEffect(() => {
    // setting filters functionality
    // dispatch(generateMenuOptions([]));
    // update option ones we got data
    if (
      isUndefined(allFilters['actionableInsightsProjectsAtRisk']) ||
      isNull(allFilters['actionableInsightsProjectsAtRisk'])
    ) {
      dispatch(
        addFilterGroup({
          // idName: 'actionableInsightsResoursePlanning',
          appliedFilters: [
            // {
            //   name: 'Sales Owner',
            //   dataKey: 'client_sales_owner',
            //   isSelected: false,
            //   equalToValue: [],
            //   availableOptions: generateOptionsForThisDataKey(tempData, 'client_sales_owner'),
            // },
            // {
            //   name: 'CS Owner',
            //   dataKey: 'client_account_owner',
            //   isSelected: false,
            //   equalToValue: [],
            //   availableOptions: generateOptionsForThisDataKey(tempData, 'client_account_owner'),
            // },
            // {
            //   name: 'Segment',
            //   dataKey: 'segment_name',
            //   isSelected: false,
            //   equalToValue: [],
            //   availableOptions: ['Enterprise', 'Mid-Market', 'SMB'],
            // },
            // {
            //   name: 'Category',
            //   dataKey: 'project_needed_status',
            //   isSelected: false,
            //   equalToValue: [],
            //   availableOptions: ['Land', 'Expand'],
            // },
          ],
        })
      );
    }
  }, []);

  // actual data filtering is happening here
  useEffect(() => {
    if (allFilters['actionableInsightsResoursePlanning']) {
      // const newFilteredData = new FilterableData([]);
      // newFilteredData.bulkFilter(allFilters['actionableInsightsResoursePlanning'].appliedFilters);
      // console.log('filtering data');
      // setFilteredData(newFilteredData.toArray());
      console.log(setFilteredData, filteredData);
    }
  }, [allFilters]);

  return (
    <Box>
      <Stack>
        <Grid
          my={2}
          container
          spacing={1}
          alignItems={'center'}
          justifyContent={'space-between'}
          sx={{ margin: '8px 0px' }}
        >
          <Grid sx={{ margin: '0 0 8px 0' }} item lg={12} md={12} sm={12}>
            <Typography variant="h2">
              {label_use_case}s and {label_project}s where{' '}
              {label_project_delivery_manager === label_use_case_delivery_manager
                ? 'Delivery Manager'
                : `${label_project_delivery_manager.toLowerCase()} or
              ${label_use_case_delivery_manager.toLowerCase()}`}{' '}
              is &apos;Not Assigned&apos;
            </Typography>
          </Grid>

          {/* boxes container */}
          {/* <Grid item lg={6} md={6} sm={12}>
            {/* box 0n track */}
          {/* <Stack direction={'row'} justifyContent={'flex-end'}>
              <DataBox key={1} dataLabel="On Track" dataCount={3} dataPercent={50} color="#4F9F52" />
              <DataBox key={2} dataLabel="At Risk" dataCount={3} dataPercent={50} color="#EFA958" />
              <DataBox key={3} dataLabel="Overdue" dataCount={3} dataPercent={50} color="#E75B5C" />
            </Stack> */}
          {/* </Grid> */}
        </Grid>
        <Stack direction={'row'} justifyContent={'space-between'} sx={{ margin: '8px 0px 12px' }}>
          {/* button tabs */}
          <ButtonGroup
            variant="outlined"
            aria-label="outlined button group"
            sx={{
              display: 'inline-flex',
              flexWrap: 'wrap',
              backgroundColor: 'white',
              border: '1px solid #E5E5E5',
              borderRadius: '4px',
            }}
          >
            <Button
              onClick={() => setSelectedTab('Usecase')}
              variant="text"
              sx={selectedTab === 'Usecase' ? { background: 'rgba(45, 96, 246,0.15)' } : {}}
            >
              {label_use_case}
            </Button>
            <Button
              onClick={() => setSelectedTab('Project')}
              variant="text"
              sx={selectedTab === 'Project' ? { background: 'rgba(45, 96, 246,0.15)' } : {}}
            >
              {label_project}
            </Button>
            {/* <Button
              onClick={() => setSelectedTab('Task')}
              variant="text"
              sx={selectedTab === 'Task' ? { background: 'rgba(45, 96, 246,0.15)' } : {}}
            >
              Task
            </Button> */}
          </ButtonGroup>

          {/* filter toogler */}
          {/* <FilterTypeSelector idName="actionableInsightsResoursePlanning" /> */}
        </Stack>
        <Stack sx={{ margin: '8px 0px' }}>
          {/* dynamic filters */}
          {/* <SelectedFilterList idName="actionableInsightsResoursePlanning" /> */}
        </Stack>
        <Stack>
          <CogniTable
            hideFooter={false}
            hideHeader={true}
            pageSize={6}
            columns={tablecolumnsStructure}
            uniquRowId={'case_uid'}
            data={
              selectedTab == 'Usecase'
                ? displayDataWithCustomNames(usecasesNotAssigned.data)
                : displayDataWithCustomNames(projectsNotAssigned.data)
            }
          />
        </Stack>
      </Stack>

      {/* modal for confirming to assign delivery manager */}
      <BasicModal
        height={biggerScreens ? 222 : 170}
        width={biggerScreens ? 657 : 450}
        open={isDeliveryManagerModalOpened}
        onClose={() => setIsDeliveryManagerModalOpened(false)}
      >
        <Stack alignItems={'center'} justifyContent={'center'} sx={{ width: '100%', height: '100%' }}>
          <Typography variant="h2" sx={{ maxWidth: '498px', textAlign: 'center', marginBottom: '20px' }}>
            Once you assign a {label_use_case_delivery_manager}, this {label_use_case} will be removed from the list
          </Typography>
          <Stack direction={'row'}>
            <CognisaasButton
              isOutlined={true}
              label="Cancel"
              clickHandler={() => {
                setToggledRowData({});
                // setNewDeliveryManagerName('');
                setIsDeliveryManagerModalOpened(false);
              }}
              sx={{ marginRight: '8px' }}
            />
            <CognisaasButton
              isOutlined={false}
              label="Proceed"
              clickHandler={() => {
                // do server request for updating data and reloading table here
                setAlertDetails({
                  isVisible: true,
                  type: 'success',
                  message: `John has been successfully assigned as ${label_use_case_delivery_manager} for ${label_use_case} '${toggledRowData.case_name}'`,
                });
                setIsDeliveryManagerModalOpened(false);
              }}
            />
          </Stack>
        </Stack>
      </BasicModal>

      {/* new alerts or toasts */}
      <CognisaasToast
        open={alertDetails.isVisible}
        onClose={() => setAlertDetails({ isVisible: false, type: alertDetails.type, message: '' })}
        message={
          alertDetails.message == 'Request failed with status code 400' ? 'Invalid credentials' : alertDetails.message
        }
        type={alertDetails.type}
      ></CognisaasToast>
    </Box>
  );
};

export default ResoursePlanning;
