import { apis } from '../axiosInstance';
import { tasksRoutes } from '../route';

import Cookies from 'universal-cookie';

const cookies = new Cookies();

const getOpenTasks = () =>
  apis.phpAxiosInstance.get(tasksRoutes.openTasks(cookies.get('cogni_customer_uid') as string), {
    headers: { JWT: 'true' },
  });

const getClosedTasks = () =>
  apis.phpAxiosInstance.get(tasksRoutes.closedTasks(cookies.get('cogni_customer_uid') as string), {
    headers: { JWT: 'true' },
  });

const addProjectPhase = (detail: any) =>
  apis.phpAxiosInstance.post(tasksRoutes.addProjectPhase(), detail, { headers: { JWT: 'true' } });

const cloneProjectPhase = (detail: any) =>
  apis.phpAxiosInstance.post(tasksRoutes.cloneProjectPhase(), detail, { headers: { JWT: 'true' } });

const cloneUsecasePhase = (detail: any) =>
  apis.phpAxiosInstance.post(tasksRoutes.cloneUsecasePhase(), detail, { headers: { JWT: 'true' } });

const updatePhase = (detail: any) =>
  apis.phpAxiosInstance.post(tasksRoutes.updatePhase(), detail, { headers: { JWT: 'true' } });

const addUsecasePhase = (detail: any) =>
  apis.phpAxiosInstance.post(tasksRoutes.addUsecasePhase(), detail, { headers: { JWT: 'true' } });

const getJiraPriorityList = (detail: any) =>
  apis.phpAxiosInstance.post(tasksRoutes.getJiraPriority(), detail, { headers: { JWT: 'true' } });

const getJiraProjects = () =>
  apis.phpAxiosInstance.get(tasksRoutes.getJiraProjects(cookies.get('cogni_customer_uid') as string), {
    headers: { JWT: 'true' },
  });

const addProjectTask = (detail: any) =>
  apis.phpAxiosInstance.post(tasksRoutes.addProjectTask(), detail, { headers: { JWT: 'true' } });

const addUsecaseTask = (detail: any) =>
  apis.phpAxiosInstance.post(tasksRoutes.addUsecaseTask(), detail, { headers: { JWT: 'true' } });

const getTasksUnderProject = (project_uid: string) =>
  apis.phpAxiosInstance.get(tasksRoutes.getTasksUnderProject(project_uid), { headers: { JWT: 'true' } });

const getProjectSubtasks = (task_uid: string) =>
  apis.phpAxiosInstance.get(tasksRoutes.getProjectSubtask(task_uid), { headers: { JWT: 'true' } });

const addProjectSubtask = (detail: any) =>
  apis.phpAxiosInstance.post(tasksRoutes.addProjectSubtask(), detail, { headers: { JWT: 'true' } });

const addProjectSubtaskAuditTrail = (detail: any) =>
  apis.phpAxiosInstance.post(tasksRoutes.addSubtaskAuditTrail(), detail, { headers: { JWT: 'true' } });

const getUsecaseSubtasks = (task_uid: string) =>
  apis.phpAxiosInstance.get(tasksRoutes.getUsecaseSubtasks(task_uid), { headers: { JWT: 'true' } });

const addUsecaseSubtask = (detail: any) =>
  apis.phpAxiosInstance.post(tasksRoutes.addUsecaseSubtask(), detail, { headers: { JWT: 'true' } });

const getTaskCustomfieldsWithValue = (task_uid: string) =>
  apis.phpAxiosInstance.get(tasksRoutes.getTaskCustomfieldsWithValue(task_uid), { headers: { JWT: 'true' } });

const getTaskCustomfields = () =>
  apis.phpAxiosInstance.get(tasksRoutes.getTaskCustomfields(cookies.get('cogni_customer_uid') as string), {
    headers: { JWT: 'true' },
  });

const updateProjectSubtask = (detail: any) =>
  apis.phpAxiosInstance.post(tasksRoutes.updateProjectSubtask(), detail, { headers: { JWT: 'true' } });

const deleteProjectTaskFromJiraByIssueId = (detail: any) =>
  apis.phpAxiosInstance.post(tasksRoutes.deleteProjectFromJiraByIssueId(), detail, { headers: { JWT: 'true' } });

const deleteProjectSubtask = (detail: any) =>
  apis.phpAxiosInstance.post(tasksRoutes.deleteProjectSubtask(), detail, { headers: { JWT: 'true' } });

const deleteUsecaseSubtask = (detail: any) =>
  apis.phpAxiosInstance.post(tasksRoutes.deleteUsecaseSubtask(), detail, { headers: { JWT: 'true' } });

const updateUsecaseSubtask = (detail: any) =>
  apis.phpAxiosInstance.post(tasksRoutes.updateUsecaseSubtask(), detail, { headers: { JWT: 'true' } });

const addTaskDependency = (detail: any) =>
  apis.phpAxiosInstance.post(tasksRoutes.addTaskDependency(), detail, { headers: { JWT: 'true' } });

const addProjectTaskDependency = (detail: any) =>
  apis.phpAxiosInstance.post(tasksRoutes.addProjectTaskDependency(), detail, { headers: { JWT: 'true' } });

const getTaskDependency = (detail: any) =>
  apis.phpAxiosInstance.post(tasksRoutes.getTaskDependency(), detail, { headers: { JWT: 'true' } });

const getProjectTaskDependency = (detail: any) =>
  apis.phpAxiosInstance.post(tasksRoutes.getProjectTaskDependency(), detail, { headers: { JWT: 'true' } });

const deleteProjectTaskDependency = (detail: any) =>
  apis.phpAxiosInstance.post(tasksRoutes.deleteProjectTaskDependency(), detail, { headers: { JWT: 'true' } });

const deleteUsecaseTaskDependency = (detail: any) =>
  apis.phpAxiosInstance.post(tasksRoutes.deleteUsecaseTaskDependency(), detail, { headers: { JWT: 'true' } });

const getTaskByTaskUid = (task_uid: any) =>
  apis.phpAxiosInstance.get(tasksRoutes.getTaskByTaskUid(task_uid), { headers: { JWT: 'true' } });

const getTasksOfUser = (detail: any) =>
  apis.phpAxiosInstance.post(tasksRoutes.getTasksOfUser(), detail, { headers: { JWT: 'true' } });

const getJiraProjectIssueTypes = (detail: any) =>
  apis.phpAxiosInstance.post(tasksRoutes.getJiraProjectIssueTypes(), detail, { headers: { JWT: 'true' } });

const getProjectTaskTimeLog = (task_uid: any) =>
  apis.phpAxiosInstance.get(tasksRoutes.getProjectTaskTimeLog(task_uid), { headers: { JWT: 'true' } });

const updateProjectTaskTimeLog = (detail: any) =>
  apis.phpAxiosInstance.post(tasksRoutes.updateProjectTaskTimeLog(), detail, { headers: { JWT: 'true' } });

const getUsecaseTaskTimeLog = (task_uid: any) =>
  apis.phpAxiosInstance.get(tasksRoutes.getUsecaseTaskTimeLog(task_uid), { headers: { JWT: 'true' } });

const updateUsecaseTaskTimeLog = (detail: any) =>
  apis.phpAxiosInstance.post(tasksRoutes.updateUsecaseTaskTimeLog(), detail, { headers: { JWT: 'true' } });

// const deleteProjectTaskDependency = (detail: any) =>
//   apis.phpAxiosInstance.post(tasksRoutes.deleTaskDependency(), detail, { headers: { JWT: 'true' } });

const addProjectTaskAuditTrail = (detail: any) =>
  apis.phpAxiosInstance.post(tasksRoutes.addProjectTaskAuditTrail(), detail, { headers: { JWT: 'true' } });

const addProjectClientTaskAuditTrail = (detail: any) =>
  apis.phpAxiosInstance.post(tasksRoutes.addProjectClientTaskAuditTrail(), detail, { headers: { JWT: 'true' } });

const addTaskAuditTrail = (detail: any) =>
  apis.phpAxiosInstance.post(tasksRoutes.addTaskAuditTrail(), detail, { headers: { JWT: 'true' } });

const addClientTaskAuditTrail = (detail: any) =>
  apis.phpAxiosInstance.post(tasksRoutes.addClientTaskAuditTrail(), detail, { headers: { JWT: 'true' } });

const addProjectTaskComment = (detail: any) =>
  apis.phpAxiosInstance.post(tasksRoutes.addProjectTaskComment(), detail, { headers: { JWT: 'true' } });

const sendProjectTaskCommentEmail = (detail: any) =>
  apis.phpAxiosInstance.post(tasksRoutes.sendProjectTaskCommentEmail(), detail, { headers: { JWT: 'true' } });

const sendUsecaseTaskCommentEmail = (detail: any) =>
  apis.phpAxiosInstance.post(tasksRoutes.sendUsecaseTaskCommentEmail(), detail, { headers: { JWT: 'true' } });

const updateProjectTaskComment = (detail: any) =>
  apis.phpAxiosInstance.post(tasksRoutes.updateProjectTaskComment(), detail, { headers: { JWT: 'true' } });

const updateProjectClientTaskComment = (detail: any) =>
  apis.phpAxiosInstance.post(tasksRoutes.updateProjectClientTaskComment(), detail, { headers: { JWT: 'true' } });

const addProjectClientTaskComment = (detail: any) =>
  apis.phpAxiosInstance.post(tasksRoutes.addProjectClientTaskComment(), detail, { headers: { JWT: 'true' } });

const addUsecaseTaskComment = (detail: any) =>
  apis.phpAxiosInstance.post(tasksRoutes.addUsecaseTaskComment(), detail, { headers: { JWT: 'true' } });

const updateUsecaseTaskComment = (detail: any) =>
  apis.phpAxiosInstance.post(tasksRoutes.updateUsecaseTaskComment(), detail, { headers: { JWT: 'true' } });

const updateUsecaseClientTaskComment = (detail: any) =>
  apis.phpAxiosInstance.post(tasksRoutes.updateUsecaseClientTaskComment(), detail, { headers: { JWT: 'true' } });

const addUsecaseClientTaskComment = (detail: any) =>
  apis.phpAxiosInstance.post(tasksRoutes.addUsecaseClientTaskComment(), detail, { headers: { JWT: 'true' } });

const getProjectTaskComments = (task_uid: string) =>
  apis.phpAxiosInstance.get(tasksRoutes.getProjectTaskComments(task_uid), { headers: { JWT: 'true' } });

const getProjectClientTaskComments = (task_uid: string) =>
  apis.phpAxiosInstance.get(tasksRoutes.getProjectClientTasksComments(task_uid), { headers: { JWT: 'true' } });

const getUsecaseTaskComments = (task_uid: string) =>
  apis.phpAxiosInstance.get(tasksRoutes.getUsecaseTaskComments(task_uid), { headers: { JWT: 'true' } });

const getUsecaseClientTaskComments = (task_uid: string) =>
  apis.phpAxiosInstance.get(tasksRoutes.getUsecaseClientTaskComments(task_uid), { headers: { JWT: 'true' } });

const updateTask = (detail: any) =>
  apis.phpAxiosInstance.post(tasksRoutes.updateTask(), detail, { headers: { JWT: 'true' } });

const deleteTaskComment = (detail: any) =>
  apis.phpAxiosInstance.post(tasksRoutes.deleteTaskComment(), detail, { headers: { JWT: 'true' } });

const markingPublicOrInternalTaskComment = (detail: any) =>
  apis.phpAxiosInstance.post(tasksRoutes.markingPublicOrInternalTaskComment(), detail, { headers: { JWT: 'true' } });

const deleteClientTaskComment = (detail: any) =>
  apis.phpAxiosInstance.post(tasksRoutes.deleteClientTaskComment(), detail, { headers: { JWT: 'true' } });

const deleteProjectTask = (detail: any) =>
  apis.phpAxiosInstance.post(tasksRoutes.deleteProjectTask(), detail, { headers: { JWT: 'true' } });

const deleteProjectClientTask = (detail: any) =>
  apis.phpAxiosInstance.post(tasksRoutes.deleteProjectClientTask(), detail, { headers: { JWT: 'true' } });

const deleteUsecaseTask = (detail: any) =>
  apis.phpAxiosInstance.post(tasksRoutes.deleteUsecaseTask(), detail, { headers: { JWT: 'true' } });

const deleteUsecaseClientTask = (detail: any) =>
  apis.phpAxiosInstance.post(tasksRoutes.deleteUsecaseClientTask(), detail, { headers: { JWT: 'true' } });

const deleteProjectSection = (detail: any) =>
  apis.phpAxiosInstance.post(tasksRoutes.deleteProjectSection(), detail, { headers: { JWT: 'true' } });

const changeTaskStatus = (detail: any) =>
  apis.phpAxiosInstance.post(tasksRoutes.changeTaskStatus(), detail, { headers: { JWT: 'true' } });

const deleteUsecaseSection = (detail: any) =>
  apis.phpAxiosInstance.post(tasksRoutes.deleteUsecaseSection(), detail, { headers: { JWT: 'true' } });

const taskBulkUpdate = (detail: any) =>
  apis.phpAxiosInstance.post(tasksRoutes.taskBulkUpdate(), detail, { headers: { JWT: 'true' } });

const bulkDeleteTask = (detail: any) =>
  apis.phpAxiosInstance.post(tasksRoutes.bulkDeleteTask(), detail, { headers: { JWT: 'true' } });

const taskBulkCsatMail = (detail: any) =>
  apis.phpAxiosInstance.post(tasksRoutes.taskBulkCsatMail(), detail, { headers: { JWT: 'true' } });

const updateTaskCustomField = (detail: any) =>
  apis.phpAxiosInstance.post(tasksRoutes.updateTaskCustomField(), detail, { headers: { JWT: 'true' } });

const deleteTaskFromTemporaryTableAndSendMail = (detail: any) =>
  apis.phpAxiosInstance.post(tasksRoutes.deleteTaskFromTemporaryTableAndSendMail(), detail, {
    headers: { JWT: 'true' },
  });

const getTaskCustomValuesByCustomer = () =>
  apis.phpAxiosInstance.get(tasksRoutes.getTaskCustomValuesByCustomer(cookies.get('cogni_customer_uid') as string), {
    headers: { JWT: 'true' },
  });

const getAllEligibleTaskOwnerDetailsOfClient = (client_uid: string) =>
  apis.phpAxiosInstance.get(
    tasksRoutes.getAllEligibleTaskOwnerDetailsOfClient(cookies.get('cogni_customer_uid') as string, client_uid),
    {
      headers: { JWT: 'true' },
    }
  );

// const deleteProjectTaskDependency = (detail: any) =>
//   apis.phpAxiosInstance.post(tasksRoutes.deleTaskDependency(), detail, { headers: { JWT: 'true' } });

// routes for reports page
const getTeamOpenTasks = () =>
  apis.phpAxiosInstance.get(tasksRoutes.teamOpenTasks(cookies.get('cogni_customer_uid') as string), {
    headers: { JWT: 'true' },
  });
const getTeamClosedTasks = () =>
  apis.phpAxiosInstance.get(tasksRoutes.teamClosedTasks(cookies.get('cogni_customer_uid') as string), {
    headers: { JWT: 'true' },
  });
const getClientOpenTasks = () =>
  apis.phpAxiosInstance.get(tasksRoutes.clientOpenTasks(cookies.get('cogni_customer_uid') as string), {
    headers: { JWT: 'true' },
  });
const getClientClosedTasks = () =>
  apis.phpAxiosInstance.get(tasksRoutes.clientClosedTasks(cookies.get('cogni_customer_uid') as string), {
    headers: { JWT: 'true' },
  });
const syncProjectTaskToJira = (payload: any) =>
  apis.phpAxiosInstance.post(tasksRoutes.syncProjectTaskToJira(), payload, { headers: { JWT: 'true' } });

const updateJiraIssueforTasks = (payload: any) =>
  apis.phpAxiosInstance.post(tasksRoutes.updateJiraTaskIssue(), payload, { headers: { JWT: 'true' } });

const incrementProjectTaskSequence = (
  project_uid: string,
  task_uid: string,
  task_sequence: string,
  section_uid: string
) =>
  apis.phpAxiosInstance.get(
    tasksRoutes.incrementProjectTaskSequence(project_uid, task_uid, task_sequence, section_uid),
    {
      headers: { JWT: 'true' },
    }
  );
const incrementProjectSectionSequence = (project_uid: string, section_sequence: string, section_uid: string) =>
  apis.phpAxiosInstance.get(tasksRoutes.incrementProjectSectionSequence(project_uid, section_uid, section_sequence), {
    headers: { JWT: 'true' },
  });
const incrementCaseSectionSequence = (case_uid: string, section_sequence: string, section_uid: string) =>
  apis.phpAxiosInstance.get(tasksRoutes.incrementCaseSectionSequence(case_uid, section_uid, section_sequence), {
    headers: { JWT: 'true' },
  });
const incrementCaseTaskSequence = (
  project_uid: string,
  case_uid: string,
  task_uid: string,
  task_sequence: string,
  section_uid: string
) =>
  apis.phpAxiosInstance.get(
    tasksRoutes.incrementCaseTaskSequence(project_uid, case_uid, task_uid, task_sequence, section_uid),
    { headers: { JWT: 'true' } }
  );
const incrementTemplateProjectTaskSequence = (
  template_case_uid: string,
  task_sequence: string,
  template_section_uid: string,
  template_task_uid: string
) =>
  apis.phpAxiosInstance.get(
    tasksRoutes.incrementTemplateProjectTaskSequence(
      template_case_uid,
      task_sequence,
      template_section_uid,
      template_task_uid
    ),
    { headers: { JWT: 'true' } }
  );
const incrementTemplateCaseTaskSequence = (
  template_case_uid: string,
  task_sequence: string,
  template_section_uid: string,
  template_task_uid: string
) =>
  apis.phpAxiosInstance.get(
    tasksRoutes.incrementTemplateCaseTaskSequence(
      template_case_uid,
      task_sequence,
      template_section_uid,
      template_task_uid
    ),
    { headers: { JWT: 'true' } }
  );

const getProjectTaskByTaskUid = (task_uid: string) =>
  apis.phpAxiosInstance.get(tasksRoutes.getProjectTaskByTaskUid(task_uid), { headers: { JWT: 'true' } });

const syncJiraTask = (payload: any) =>
  apis.phpAxiosInstance.post(tasksRoutes.syncTasksFromJira(), payload, { headers: { JWT: 'true' } });

const getTaskAuditTrail = (task_uid: string) =>
  apis.phpAxiosInstance.get(tasksRoutes.getTaskAuditTrail(task_uid), { headers: { JWT: 'true' } });

const getProjectTaskAuditTrail = (task_uid: string) =>
  apis.phpAxiosInstance.get(tasksRoutes.getProjectTaskAuditTrail(task_uid), { headers: { JWT: 'true' } });

const getProjectClientTaskAuditTrail = (task_uid: string) =>
  apis.phpAxiosInstance.get(tasksRoutes.getProjectClientTaskAuditTrail(task_uid), { headers: { JWT: 'true' } });

const getClientTaskAuditTrail = (task_uid: string) =>
  apis.phpAxiosInstance.get(tasksRoutes.getClientTaskAuditTrail(task_uid), { headers: { JWT: 'true' } });

const getSubTaskAuditTrail = (subtask_uid: string) =>
  apis.phpAxiosInstance.get(tasksRoutes.getSubTaskAuditTrail(subtask_uid), { headers: { JWT: 'true' } });

const updateUseCaseDependencyDueDate = (detail: any) =>
  apis.phpAxiosInstance.post(tasksRoutes.updateUsecaseDependencyDueDate(), detail, { headers: { JWT: 'true' } });

const updateUseCaseDependencyDeliveryDate = (detail: any) =>
  apis.phpAxiosInstance.post(tasksRoutes.updateUsecaseDependencyDeliveryDate(), detail, { headers: { JWT: 'true' } });

const updateProjectDependencyDueDate = (detail: any) =>
  apis.phpAxiosInstance.post(tasksRoutes.updateProjectDependencyDueDate(), detail, { headers: { JWT: 'true' } });

const updateProjectDependencyDeliveryDate = (detail: any) =>
  apis.phpAxiosInstance.post(tasksRoutes.updateProjectDependencyDeliveryDate(), detail, { headers: { JWT: 'true' } });

const addTaskCommentToJira = (payload: any) => {
  return apis.phpAxiosInstance.post(tasksRoutes.addTaskCommentToJira(), payload, {
    headers: { JWT: 'true' },
  });
};

const syncTaskFromJira = (payload: any) => {
  return apis.phpAxiosInstance.post(tasksRoutes.syncTaskToJira(), payload, {
    headers: { JWT: 'true' },
  });
};

const syncProjectTaskfromJira = (payload: any) => {
  return apis.phpAxiosInstance.post(tasksRoutes.syncProjectTaskToJira(), payload, {
    headers: { JWT: 'true' },
  });
};

const deleteCommentFromJira = (payload: any) => {
  return apis.phpAxiosInstance.post(tasksRoutes.deleteJiraTaskComment(), payload, {
    headers: { JWT: 'true' },
  });
};

export const taskAPI = {
  getClosedTasks,
  getOpenTasks,
  addProjectPhase: addProjectPhase,
  cloneProjectPhase: cloneProjectPhase,
  cloneUsecasePhase: cloneUsecasePhase,
  getJiraPriorityList,
  getJiraProjects,
  addProjectTask,
  getTasksUnderProject,
  getProjectSubtasks,
  addProjectSubtask,
  getTaskCustomfields,
  getTaskCustomfieldsWithValue,
  getTaskCustomValuesByCustomer,
  updateProjectSubtask,
  deleteProjectTaskFromJiraByIssueId,
  deleteProjectSubtask,
  addTaskDependency,
  getTaskDependency,
  getTaskByTaskUid,
  getTasksOfUser,
  addUsecaseTask,
  addUsecasePhase,
  addProjectTaskAuditTrail,
  addProjectClientTaskAuditTrail,
  addTaskAuditTrail,
  addClientTaskAuditTrail,
  addProjectTaskComment,
  sendProjectTaskCommentEmail,
  sendUsecaseTaskCommentEmail,
  addProjectClientTaskComment,
  addUsecaseTaskComment,
  addUsecaseClientTaskComment,
  getProjectTaskComments,
  getProjectClientTaskComments,
  getUsecaseTaskComments,
  getUsecaseClientTaskComments,
  updateProjectTaskComment,
  updateProjectClientTaskComment,
  updateUsecaseTaskComment,
  updateUsecaseClientTaskComment,
  deleteTaskComment,
  deleteClientTaskComment,
  updateTask,
  deleteProjectTask,
  deleteProjectClientTask,
  deleteUsecaseTask,
  deleteUsecaseClientTask,
  deleteProjectSection,
  changeTaskStatus,
  deleteUsecaseSection,
  getJiraProjectIssueTypes,
  getProjectTaskDependency,
  addProjectTaskDependency,
  deleteProjectTaskDependency,
  deleteUsecaseTaskDependency,
  getProjectTaskTimeLog,
  updateProjectTaskTimeLog,
  addUsecaseSubtask,
  addProjectSubtaskAuditTrail,
  getUsecaseSubtasks,
  deleteUsecaseSubtask,
  updateUsecaseSubtask,
  getUsecaseTaskTimeLog,
  updateUsecaseTaskTimeLog,
  taskBulkUpdate,
  bulkDeleteTask,
  deleteTaskFromTemporaryTableAndSendMail,
  taskBulkCsatMail,
  getAllEligibleTaskOwnerDetailsOfClient,
  getTeamOpenTasks,
  getTeamClosedTasks,
  getClientOpenTasks,
  getClientClosedTasks,
  incrementProjectTaskSequence,
  incrementTemplateProjectTaskSequence,
  incrementTemplateCaseTaskSequence,
  incrementCaseTaskSequence,
  incrementProjectSectionSequence,
  incrementCaseSectionSequence,

  // deleteProjectTaskDependency,
  syncProjectTaskToJira,
  updateJiraIssueforTasks,
  getProjectTaskByTaskUid,
  syncJiraTask,
  updateTaskCustomField,
  // task audit trail
  getTaskAuditTrail,
  getProjectTaskAuditTrail,
  getProjectClientTaskAuditTrail,
  getClientTaskAuditTrail,
  getSubTaskAuditTrail,
  updateUseCaseDependencyDueDate,
  updateUseCaseDependencyDeliveryDate,
  updateProjectDependencyDueDate,
  updateProjectDependencyDeliveryDate,
  updatePhase,
  addTaskCommentToJira,
  syncTaskFromJira,
  syncProjectTaskfromJira,
  deleteCommentFromJira,
  markingPublicOrInternalTaskComment
};
