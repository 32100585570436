import {
  Drawer,
  Box,
  Grid,
  Stack,
  Typography,
  Button,
  Snackbar,
  Alert,
  Select,
  MenuItem,
  IconButton,
} from '@mui/material';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import CommonAddUser from 'components/RBAC/shared/CommonAddUser';
import CognisaasButton from 'components/shared/CognisaasButton/CognisaasButton';
import BasicModal from 'components/shared/CognisaasModal/CognisaasModal';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getAllRoles_selector, getValidatedReportees_selector } from 'store/modules/RBAC/selector';
import {
  activateUser_slice,
  changeUserRole_slice,
  deactivateUser_slice,
  getAllRoles_slice,
  getAllUsersAndRoles_slice,
  getValidReporteeAdditions_slice,
  removeReportsTo,
} from 'store/modules/RBAC/slice';
import EditableInlineTextField from 'components/shared/EditableInlineTextField/EditableInlineTextField';
import CognisaasToast from 'components/shared/CognisaasToast/CognisaasToast';
import { updateUsername } from 'store/modules/Settings/slice';
import { resetFilterAcrossAppAtStore } from 'store/modules/AllFilters/slice';
import { resetAllFiltersAcrossApp } from '../Fields/utils';

const imgLink = 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png';

interface UserDetailProps {
  drawerState: boolean;
  onDrawerClose: () => void;
  selectedUser: any;
  setUsers: React.Dispatch<React.SetStateAction<any[]>>;
}

interface UserStatusComponent_payload {
  text: string;
}

const UserStatusComponent = (props: UserStatusComponent_payload) => {
  const [bgColor, setBgColor] = useState<string>('#fff');
  const [borderColor, setBorderColor] = useState<string>('#000');
  const [fontColor, setFontColor] = useState<string>('#000');

  useEffect(() => {
    const bgColor: string =
      props.text === 'Active' ? 'rgb(200, 247, 202, 0.7)' : props.text == 'Inactive' ? '#F1AFAF' : '#F9D1A2';

    const brColor: string = props.text === 'Active' ? '#4f9f52' : props.text == 'Inactive' ? '#E75B5C' : '#EFA958';

    const ftColor: string = props.text === 'Active' ? '#4f9f52' : props.text == 'Inactive' ? '#E75B5C' : '#EFA958';

    setBgColor(bgColor);
    setBorderColor(brColor);
    setFontColor(ftColor);
  }, [props]);

  return (
    <Stack
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '0 7px',
        maxHeight: '25px',
        boxSizing: 'border-box',
        border: `1px solid ${borderColor}`,
        borderRadius: '12px',
        backgroundColor: bgColor,
        marginLeft: '10px',
      }}
    >
      <Typography variant="subtitle1" color={fontColor} fontSize="12px" style={{ fontWeight: 'bold' }}>
        {props.text}
      </Typography>
    </Stack>
  );
};

export default function UserDetail(props: UserDetailProps) {
  const [data, setData] = useState(props.selectedUser ? props.selectedUser : { name: '', role: '' });
  const [reportees, setReportees] = useState<any[]>([]);
  const [reporteesAddition, setReporteesAddition] = useState<any[]>([]);
  const [uniqueRoles, setUniqueRoles] = useState<any[]>([]);
  const [roleDropdown, setRoleDropdown] = useState(props.selectedUser?.role?.name);
  const [status, setStatus] = useState<'Active' | 'Inactive' | 'User not Verified'>('Active');
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [openSnackBarRole, setOpenSnackBarRole] = useState(false);
  const allRolesData_fromSelector = useAppSelector(getAllRoles_selector).data;
  const validReportees = useAppSelector(getValidatedReportees_selector).data;
  const [addUserModalStatus, setAddUserModalStatus] = useState<boolean>(false);
  const [userName, setUserName] = useState<string>('');
  const [alertDetails, setAlertDetails] = useState<{
    isVisible: boolean;
    type: 'success' | 'failure';
    message: string;
  }>({ isVisible: false, type: 'success', message: '' });

  console.log(props);

  const dispatch = useAppDispatch();

  useEffect(() => {
    setData(props.selectedUser ? props.selectedUser : { name: '', role: '' });
    setReportees(props.selectedUser?.account?.account_reportees || []);
    setRoleDropdown(props.selectedUser?.role?.name);
    setUserName(props.selectedUser?.name || '');
    if (props?.selectedUser?.is_verified === false) setStatus('User not Verified');
    else if (props?.selectedUser?.isActive === true) setStatus('Active');
    else if (props?.selectedUser?.isActive === false) setStatus('Inactive');
  }, []);

  useEffect(() => {
    dispatch(getAllRoles_slice());
    dispatch(getValidReporteeAdditions_slice(props.selectedUser.id));
  }, []);

  useEffect(() => {
    if (validReportees.length > 0) {
      const array: any = [];
      for (let i = 0; i < validReportees.length; i++) {
        const data: any = {};
        data.id = validReportees[i].id;
        data.name = validReportees[i].user_name;
        data.email = validReportees[i].email;
        array.push(data);
      }
      setReporteesAddition(array);
    }
  }, [validReportees]);

  useEffect(() => {
    if (allRolesData_fromSelector) {
      const tempArr = [...allRolesData_fromSelector];
      tempArr.push({
        id: null,
        name: 'Not Assigned',
        description: 'Not Assigned',
        is_public: true,
        account: null,
        pages: [],
        policies: [],
      });
      setUniqueRoles(tempArr);
    }
  }, [allRolesData_fromSelector]);

  const onChangeRole = async (e: any) => {
    // changeUserRole_slice
    setRoleDropdown(e.target.value);
    const temp = uniqueRoles?.filter((obj: any) => {
      return obj?.name == e.target.value;
    });
    const newRoleId = temp[0]?.id || '';
    await dispatch(
      changeUserRole_slice({
        userId: props?.selectedUser?.id || '',
        roleId: newRoleId || '',
      })
    );
    setOpenSnackBarRole(true);
    dispatch(getAllUsersAndRoles_slice());
    props.onDrawerClose();
  };

  const deactivateUserHandler = async () => {
    const updatedUser = { ...data, isActive: false };
    setData({ ...updatedUser });
    setStatus('Inactive');
    props.setUsers((users: any[]) => {
      const filteredUsers = users.map((user: any) => {
        if (user.id === updatedUser.id) return updatedUser;
        return user;
      });
      return [...filteredUsers];
    });
    await dispatch(
      deactivateUser_slice({
        userId: data.id,
      })
    );
    setOpenSnackBar(true);
  };

  const activateUserHandler = async () => {
    const updatedUser = { ...data, isActive: true };
    setData({ ...updatedUser });
    setStatus('Active');
    props.setUsers((users: any[]) => {
      const filteredUsers = users.map((user: any) => {
        if (user.id === updatedUser.id) return updatedUser;
        return user;
      });
      return [...filteredUsers];
    });
    await dispatch(
      activateUser_slice({
        userId: data.id,
      })
    );
    setOpenSnackBar(true);
  };

  const handleCloseSnackBar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackBar(false);
  };

  const handleCloseSnackBarRole = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackBarRole(false);
  };

  const handleRemoveReportees = async (reporteesId: string, userId: string) => {
    const filteredReportees = reportees.filter((reportee: any) => reportee.id !== reporteesId);
    setReportees(filteredReportees);
    await dispatch(
      removeReportsTo({
        reportees: [reporteesId],
        userId: userId,
      })
    );
  };

  const handleUsernameUpdate = (user: any, newValue: string) => {
    dispatch(updateUsername({ user_uid: user.id, user_username: newValue }))
      .unwrap()
      .then(() => {
        const updatedUser = { ...user, name: newValue };
        props.setUsers((users: any[]) => {
          const filteredUsers = users.map((user: any) => {
            if (user.id === updatedUser.id) return updatedUser;
            return user;
          });
          return [...filteredUsers];
        });
        resetAllFiltersAcrossApp();
        dispatch(resetFilterAcrossAppAtStore());
        setUserName(newValue);
        setAlertDetails({
          isVisible: true,
          type: 'success',
          message: 'Username updated',
        });
      })
      .catch(() => {
        setAlertDetails({
          isVisible: true,
          type: 'failure',
          message: 'Username updation failed!',
        });
      });
  };

  return (
    <>
      <Drawer
        anchor={'right'}
        open={props.drawerState}
        onClose={() => props.onDrawerClose()}
        sx={{
          width: '700px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '20px',
            marginTop: '70px',
            width: '500px',
          }}
        >
          <Stack direction="row" justifyContent="space-between">
            <Grid sx={{ display: 'flex', alignItems: 'center' }}>
              <Grid>
                <img
                  src={imgLink}
                  style={{ height: '51px', width: '51px', borderRadius: '50%', marginRight: '10px' }}
                  alt={'Image'}
                />
              </Grid>
              <Grid>
                <Stack direction="row" flexWrap="wrap" style={{ maxWidth: '300px' }} alignItems="center">
                  <EditableInlineTextField
                    containerSx={{ maxWidth: '280px' }}
                    viewType="text-only"
                    value={userName}
                    disableEditPenButton={false}
                    handleSave={(newValue: string) => handleUsernameUpdate(data, newValue)}
                    customEditingModeOperation={null}
                  />
                  <UserStatusComponent text={status} />
                </Stack>
                <Typography variant={'subtitle1'} className="label-text" style={{ textDecoration: 'bold' }}>
                  {data?.role?.name ? data?.role?.name : 'No Role Assigned'}
                </Typography>
                <Typography variant={'subtitle1'} className="label-text" style={{ textDecoration: 'bold' }}>
                  {data?.email ? data?.email : 'abc@def.com'}
                </Typography>
              </Grid>
            </Grid>
            {data?.account?.user_type !== 'S' && (
              <Box>
                {data?.isActive === false ? (
                  <Button variant="outlined" onClick={activateUserHandler} color="warning" sx={{ marginTop: '20px' }}>
                    Activate User
                  </Button>
                ) : (
                  <Button variant="outlined" onClick={deactivateUserHandler} color="error" sx={{ marginTop: '20px' }}>
                    Deactivate User
                  </Button>
                )}
              </Box>
            )}
          </Stack>
          {data?.account?.user_type !== 'S' && (
            <Grid sx={{ marginTop: '40px' }}>
              <Grid sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Stack direction="row">
                  <Typography variant={'h2'} sx={{ mt: '5px' }}>
                    Role :{' '}
                  </Typography>
                  <Select
                    value={roleDropdown}
                    variant="standard"
                    onChange={onChangeRole}
                    label="Choose Role"
                    sx={{ color: 'blue', ml: '5px' }}
                  >
                    {uniqueRoles?.map((obj: any, index: number) => {
                      return (
                        <MenuItem key={index} value={obj?.name}>
                          {obj?.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Stack>
              </Grid>

              <Snackbar open={openSnackBar} autoHideDuration={4500} onClose={handleCloseSnackBar}>
                <Alert onClose={handleCloseSnackBar} severity="success" sx={{ width: '100%' }}>
                  User {data?.isActive === false ? 'Deactivated!' : 'activated!'}
                </Alert>
              </Snackbar>
              <Snackbar open={openSnackBarRole} autoHideDuration={4500} onClose={handleCloseSnackBarRole}>
                <Alert onClose={handleCloseSnackBarRole} severity="success" sx={{ width: '100%' }}>
                  Role Changed!
                </Alert>
              </Snackbar>
            </Grid>
          )}
          <Grid sx={{ marginTop: '50px' }}>
            <Stack direction="row" alignItems="center">
              <Typography variant={'h2'} className="label-text">
                Reportees
              </Typography>
              <CognisaasButton
                sx={{ marginLeft: '15px' }}
                isOutlined={true}
                clickHandler={() => {
                  setAddUserModalStatus(true);
                }}
                label="Add Reportees"
              />
            </Stack>
            {reportees.length > 0 ? (
              <Box
                style={{
                  marginTop: '15px',
                  width: '100%',
                }}
              >
                {data.account &&
                  reportees.map((reportees: any) => (
                    <>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        key={reportees.id}
                        style={{ marginTop: '16px' }}
                      >
                        <Stack direction="row" alignItems="center">
                          <div
                            style={{
                              padding: '3px 5px',
                              border: '1px solid #cdcdcd',
                              borderRadius: '50%',
                            }}
                          >
                            <PersonOutlineOutlinedIcon />
                          </div>
                          <div>
                            <Typography variant="subtitle1" style={{ marginLeft: '10px' }}>
                              {reportees.user_name}
                            </Typography>
                            <Typography variant="subtitle1" className="label-text" style={{ marginLeft: '10px' }}>
                              {reportees.email ?? ''}
                            </Typography>
                          </div>
                        </Stack>
                        <IconButton
                          onClick={() => {
                            handleRemoveReportees(reportees.id, data.id);
                          }}
                        >
                          <DeleteOutlineOutlinedIcon />
                        </IconButton>
                      </Stack>
                    </>
                  ))}
              </Box>
            ) : (
              <Typography variant="subtitle1">No Reportees</Typography>
            )}
          </Grid>
          {addUserModalStatus && (
            <BasicModal width={440} open={true} onClose={() => setAddUserModalStatus(!addUserModalStatus)}>
              <CommonAddUser
                id={'whoIReportTo'}
                onClose={() => setAddUserModalStatus(!addUserModalStatus)}
                userId={data.id}
                existingReportees={reporteesAddition}
                setReportees={setReportees}
              />
            </BasicModal>
          )}
        </Box>
      </Drawer>
      <CognisaasToast
        open={alertDetails.isVisible}
        onClose={() => setAlertDetails({ isVisible: false, type: alertDetails.type, message: '' })}
        message={alertDetails.message}
        type={alertDetails.type}
      />
    </>
  );
}
