import { Backdrop, CircularProgress, FormControl, Stack, TextField, Typography } from '@mui/material';
import { Formik } from 'formik';
import CognisaasButton from '../../shared/CognisaasButton/CognisaasButton';
import Styles from './OnboardNewAccount.module.scss';
// import Button from '@mui/material/Button';
// import CognisaasDropdown from '../../shared/CognisaasDropdown/CognisaasDropdown';
// import CognisaasDatePicker from '../../shared/CognisaasDatePicker/CognisaasDatePicker';

import { useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from '../../../store/hooks';
import {
  selectOnboardFormValues,
  selectAssociationUid,
  selectNewClientUid,
  selectOnboardProjectStatusButton,
} from '../../../store/modules/ManageClient/OnboardFormValues/selector';
import { onboardAddProjectSchema } from '../../../utils/validations';
import { selectProjectTemplates, selectTemplateprojectInformation } from '../../../store/modules/Templates/selector';
import { getProjectTemplates, getTemplateProjectInformation } from '../../../store/modules/Templates/Templates';
import SaveIcon from '@mui/icons-material/Save';

// import { useDispatch } from 'react-redux';
import CognisaasCalenderNew from '../../shared/CognisaasCalenderNew/CognisaasCalenderNew';
import {
  addBlankProject,
  // addTeamMembersForProject,
  addClientContactsAsMembersForProject,
} from '../../../store/modules/ManageClient/OnboardFormValues/slice';
import { selectUsersOfCompany } from '../../../store/modules/Common/selector';
import { getAllUsers } from '../../../store/modules/Common/slice';
import CognisaasSelect from '../../shared/CognisaasSelect/CognisaasSelect';
import { add } from 'date-fns';
import { deleteBlankProject } from '../../../store/modules/Project/slice';
import CognisaasMultiSelectWithSearchUid from '../../shared/CognisaasMultiSelectWithSearch/CognisaasMultiSelectWithSearchUid';
import useCompanyUsers from '../../shared/Hooks/useCompanyUsers';
import useClientContacts from '../../shared/Hooks/useClientContacts';
import { selectCustomerDetail } from 'store/modules/Common/selector';
import getSymbolFromCurrency from 'currency-symbol-map';
// import { getUserUid } from 'utils/getUid';
import useUserDropDownData from 'components/shared/Hooks/useUserDropDownData';
import CognisaasUserSelect from 'components/shared/CognisaasSelect/CognisaasUserSelect';
import { CognisaasMultiSelectWithSearchAndValidation } from 'components/shared/CognisaasMultiSelectWithSearch/CognisaasMultiSelectWithSearchAndValidation';
import {
  label_arr,
  label_client,
  label_due_date,
  label_project,
  label_project_delivery_manager,
} from 'components/Settings/Preferences/CustomLabelCookieHelper';
import CognisaasToast from 'components/shared/CognisaasToast/CognisaasToast';
import { LoadingButton } from '@mui/lab';
import { getUserUid } from 'utils/getUid';
import useCustomNames from 'components/shared/CustomNames/useCustomNames';
import NAMED_CONSTATNTS from 'components/shared/CustomNames/Constants';

// import { useNavigate } from 'react-router-dom';

interface Props {
  handleSkip: (nextActiveState: number) => void;
  handleSave: (
    formCategory: 'accountProfile' | 'customFields' | 'addProject' | 'reset',
    formFieldValues: Record<string, any>,
    nextActiveState: number
  ) => void;
  handleCancel: () => void;
}

const OnboardAddProjects = (componentProps: Props) => {
  const onboardFormValues = useAppSelector(selectOnboardFormValues);
  const onboardProjectStatus = useAppSelector(selectOnboardProjectStatusButton);
  // const [selectedOption, setSelectedOptions] = useState<any>([]);
  const [selectedContactOption, setSelectedContactOptions] = useState<any>([]);
  const companyUsers = useCompanyUsers();
  // const [companyUsersDropDownData, setCompanyUsersDropDownData] = useState<any[]>([]);
  const [clientContactsDropDownData, setClientContactsDropDownData] = useState<any[]>([]);
  const [importInProgress, setImportInProgress] = useState<boolean>(false);
  const [initialFormValues, setInitialFormValues] = useState<any>({ ...onboardFormValues.addProject });
  // console.log(initailFormValues);

  const projectTemplates = useAppSelector(selectProjectTemplates);
  const templateProjectInfo = useAppSelector(selectTemplateprojectInformation);
  const customerDetail = useAppSelector(selectCustomerDetail);
  const [allProjectTemplates, setAllProjectTemplates] = useState<any[]>([]);
  const [templateList, setTemplateList] = useState<any[]>([]);
  const [selectedProjectTemplate, setSelectedProjectTemplate] = useState<string>('');
  const associationUid = useAppSelector(selectAssociationUid);
  const clientUid = useAppSelector(selectNewClientUid);
  const deliveryManagers = useAppSelector(selectUsersOfCompany);
  // const [deliveryManagersList, setDeliveryManagersList] = useState<any[]>([]);
  const dispatch = useAppDispatch();
  // const navigate = useNavigate();
  const [alertDetails, setAlertDetails] = useState<{
    isVisible: boolean;
    type: 'success' | 'failure';
    message: string;
  }>({ isVisible: false, type: 'success', message: '' });
  const clientContacts = useClientContacts(clientUid as string);
  const allCompanyUsersDropDownData = useUserDropDownData('company');
  const {
    projectCategoryDropdownValues,
    getStandardNameMappedValue,
    projectStagesDropdownValues,
    getCustomNameMappedValue,
    projectPrioritiesDropdownValues,
  } = useCustomNames();
  const projectCategories = projectCategoryDropdownValues.map((item: any) => item.custom_category_name);
  const projectStages = projectStagesDropdownValues.map((item: any) => item.custom_stage_name);
  const projectPriorities = projectPrioritiesDropdownValues.map((item: any) => item.custom_priority_name);

  useEffect(() => {
    if (companyUsers.length > 0) {
      const array: any = [];
      for (let i = 0; i < companyUsers.length; i++) {
        const data: any = {};
        data.id = companyUsers[i].user_uid;
        data.name = companyUsers[i].user_username;
        data.value = companyUsers[i].user_uid;
        data.label = companyUsers[i].user_username;
        array.push(data);
      }
      console.log({ array });
      // setCompanyUsersDropDownData([...array]);
    }

    if (clientContacts.length > 0) {
      const array: any = [];
      for (let i = 0; i < clientContacts.length; i++) {
        const data: any = {};
        data.id = clientContacts[i].contact_uid || clientContacts[i].user_uid;
        data.name = clientContacts[i].contact_name || clientContacts[i].user_username;
        array.push(data);
      }
      console.log(array);
      setClientContactsDropDownData([...array]);
    }
  }, [companyUsers, clientContacts]);

  useEffect(() => {
    // if (selectedOption) {
    //   console.log(selectedOption);

    //   dispatch(addTeamMembersForProject(selectedOption));
    // }
    if (selectedContactOption) {
      console.log(selectedContactOption);

      dispatch(addClientContactsAsMembersForProject(selectedContactOption));
    }
  }, [selectedContactOption]);
  // }, [selectedOption, selectedContactOption]);

  useEffect(() => {
    // intialize empty project
    // const associationUid = associationUid;
    dispatch(addBlankProject(associationUid));
    if (deliveryManagers.length == 0) {
      dispatch(getAllUsers()); // getting delivery managers
    }
  }, []);

  useEffect(() => {
    if (projectTemplates.length > 0) {
      setAllProjectTemplates([...projectTemplates]);
      setDataForTemplatesDropDown(projectTemplates);
      console.log(projectTemplates);
    } else {
      dispatch(getProjectTemplates());
    }
  }, [projectTemplates]);

  useEffect(() => {
    if (selectedProjectTemplate.length > 0) {
      const template = allProjectTemplates.filter((t: any) => t.template_project_name == selectedProjectTemplate);
      dispatch(getTemplateProjectInformation(template[0].template_project_uid));
    }
  }, [selectedProjectTemplate]);

  useEffect(() => {
    if (templateProjectInfo) {
      console.log(templateProjectInfo);
    }
  }, [templateProjectInfo]);

  useEffect(() => {
    return () => {
      dispatch(deleteBlankProject());
    };
  }, []);

  // console.log(onboardFormValues);

  const setDataForTemplatesDropDown = (templates: any) => {
    const template = templates.map((t: any) => t.template_project_name);
    console.log(template);
    setTemplateList([...template]);
  };

  function loadTeamMembers(templateProjectInfo: any) {
    let templateTaskOwnerList: any[] = [];
    let allTasks: any[] = [];
    templateProjectInfo?.section.forEach((section: any) => {
      allTasks = [...allTasks, ...section.task];
    });
    templateTaskOwnerList = allTasks
      .filter((templateItem) => templateItem.user_uid && templateItem.template_task_owner)
      .map((templateItem: any) => ({
        name: templateItem.template_task_owner,
        id: templateItem.user_uid,
        label: templateItem.template_task_owner,
        value: templateItem.user_uid,
      }));

    const selectedTeamMemberOptions = [...templateTaskOwnerList];
    // console.log({
    //   initialFormValues,
    //   templateProjectInfo,
    //   allTasks,
    //   templateTaskOwnerList,
    //   selectedTeamMemberOptions,
    //   selectedOption,
    // });
    return selectedTeamMemberOptions;
  }

  const importTemplate = async (name: any, setFieldValue: any) => {
    setImportInProgress(true);

    console.log(name, setFieldValue);
    setSelectedProjectTemplate(name);
    const temp: any = allProjectTemplates.filter((t: any) => t.template_project_name == name);
    console.log('temp ----->', temp);

    setInitialFormValues((prevState: any) => {
      return { ...prevState, projectName: temp[0].template_project_name };
    });
    setFieldValue('importedTemplateProjectUid', temp[0].template_project_uid);
    setFieldValue('projectTemplate', name);
    // setFieldValue('projectName', temp[0].template_project_name);
    setFieldValue('deliveryManager', {
      id: '15243',
      name: 'Not Assigned',
    });
    setFieldValue('implementationFee', temp[0].template_fees);
    setFieldValue('dueDate', add(new Date(), { days: temp[0].template_project_target_days - 1 }));
    setFieldValue('etaForDeliveryDate', add(new Date(), { days: temp[0].template_project_target_days - 1 }));
    const res = await dispatch(getTemplateProjectInformation(temp[0].template_project_uid)).unwrap();
    console.log(res);
    let selectedTeamMemberOptions = loadTeamMembers(res[0]);
    selectedTeamMemberOptions = selectedTeamMemberOptions.filter(
      (a, i) => selectedTeamMemberOptions.findIndex((s) => a.id === s.id) === i
    );

    setFieldValue('teamMembers', selectedTeamMemberOptions);
    setImportInProgress(false);
  };

  const availableTeamMemberOptions = allCompanyUsersDropDownData.map((item: any) => ({
    ...item,
    label: item.name,
    value: item.id,
  }));

  return (
    <>
      <CognisaasToast
        message={alertDetails.message}
        type={alertDetails.type}
        open={alertDetails.isVisible}
        onClose={() => {
          setAlertDetails({ isVisible: false, type: alertDetails.type, message: '' });
        }}
      />
      <Formik
        initialValues={initialFormValues}
        validationSchema={onboardAddProjectSchema}
        onSubmit={(values) => {
          if (values.projectName.trim) {
            const members: any = [];
            for (let i = 0; i < values.teamMembers.length; i++) {
              const obj: any = {};
              obj.user_uid = values.teamMembers[i].id;
              obj.is_client_contact = '0';
              members.push(obj);
            }

            for (let i = 0; i < selectedContactOption.length; i++) {
              const obj: any = {};
              obj.user_uid = selectedContactOption[i];
              obj.is_client_contact = '1';
              members.push(obj);
            }

            const projectValues = {
              ...values,
              members: [...members],
              category: getStandardNameMappedValue(values.category, NAMED_CONSTATNTS.project_category_key),
              projectStage: getStandardNameMappedValue(values.projectStage, NAMED_CONSTATNTS.project_stage_key),
              priority: getStandardNameMappedValue(values.priority, NAMED_CONSTATNTS.project_priority_key),
            };
            componentProps.handleSave('addProject', { ...projectValues }, 0);
          } else {
            setAlertDetails({
              isVisible: true,
              type: 'failure',
              message: `${label_project} Name cannot be empty.`,
            });
          }
        }}
      >
        {(props: any) => (
          <form className={Styles.OnboardAccountProfile__container}>
            <div className={Styles.OnboardAccountProfile__formAlternateTopSection}>
              <Typography sx={{ magrin: '12px 0px' }} variant="h6" component="span" gutterBottom>
                Choose a {label_project} Template
              </Typography>
            </div>

            <div className={Styles.OnboardAccount__section}>
              <div className={Styles.OnboardAccountProfile__formField}>
                <FormControl sx={{ minWidth: '260px' }} size="small">
                  <CognisaasSelect
                    width="310px"
                    onChange={(e: any) => {
                      props.handleChange;
                      importTemplate(e.target.value, props.setFieldValue);
                    }}
                    name="SelectTemplate"
                    onOpen={() => {
                      dispatch(getProjectTemplates());
                    }}
                    value={props.values.projectTemplate}
                    validValues={[...templateList]}
                    onBlur={props.handleBlur}
                    error={props.touched.SelectTemplate && Boolean(props.errors.SelectTemplate)}
                    helperText={props.touched.SelectTemplate && props.errors.SelectTemplate}
                  />
                </FormControl>
              </div>
              <div className={Styles.OnboardAccountProfile__formField}>
                <a
                  style={{ margin: 'auto' }}
                  className={'link-text'}
                  href="/templates/createnewtemplate"
                  target={'_blank'}
                >
                  <Typography>Create Template</Typography>
                </a>
              </div>
            </div>

            <div className={Styles.OnboardAccount__section}>
              <div className={Styles.OnboardAccountProfile__formField}>
                <Typography variant="subtitle2">{label_project} Name*</Typography>
                <TextField
                  sx={{ width: '310px' }}
                  size="small"
                  id="projectName"
                  name="projectName"
                  placeholder={`Enter ${label_project.toLowerCase()} name`}
                  onChange={props.handleChange}
                  value={props.values.projectName}
                  onBlur={props.handleBlur}
                  error={props.touched.projectName && Boolean(props.errors.projectName)}
                  helperText={props.touched.projectName && props.errors.projectName}
                ></TextField>
              </div>
              <div className={Styles.OnboardAccountProfile__formField}>
                <Typography variant="subtitle2">Priority</Typography>
                <FormControl sx={{ minWidth: '260px' }} size="small">
                  <CognisaasSelect
                    width="310px"
                    onChange={props.handleChange}
                    name="priority"
                    value={
                      props.values.priority === 'Medium'
                        ? getCustomNameMappedValue('Medium', NAMED_CONSTATNTS.project_priority_key)
                        : props.values.priority
                    }
                    validValues={projectPriorities}
                    onBlur={props.handleBlur}
                    error={props.touched.priority && Boolean(props.errors.priority)}
                    helperText={props.touched.priority && props.errors.priority}
                  />
                </FormControl>
              </div>
              <div className={Styles.OnboardAccountProfile__formField}>
                <Typography variant="subtitle2">Category</Typography>
                <FormControl sx={{ minWidth: '260px' }} size="small">
                  <CognisaasSelect
                    width="310px"
                    onChange={props.handleChange}
                    name="category"
                    value={
                      props.values.category === 'Land'
                        ? getCustomNameMappedValue('Land', 'project_needed_status')
                        : props.values.category
                    }
                    validValues={projectCategories}
                    onBlur={props.handleBlur}
                    error={props.touched.category && Boolean(props.errors.category)}
                    helperText={props.touched.category && props.errors.category}
                  />
                </FormControl>
              </div>
              {getStandardNameMappedValue(props.values.category, NAMED_CONSTATNTS.project_category_key) == 'Expand' && (
                <div className={Styles.OnboardAccountProfile__formField}>
                  <Typography variant="subtitle2">
                    {getCustomNameMappedValue('Expand', NAMED_CONSTATNTS.project_category_key)} {label_arr}
                  </Typography>
                  <FormControl sx={{ minWidth: '260px' }} size="small">
                    <TextField
                      sx={{ width: '310px' }}
                      size="small"
                      onChange={props.handleChange}
                      name="expansionValue"
                      value={props.values.expansionValue}
                      onBlur={props.handleBlur}
                      error={props.touched.expansionValue && Boolean(props.errors.expansionValue)}
                      helperText={props.touched.expansionValue && props.errors.expansionValue}
                    />
                  </FormControl>
                </div>
              )}
            </div>

            <div className={Styles.OnboardAccount__section}>
              <div className={Styles.OnboardAccountProfile__formField}>
                <Typography variant="subtitle2">{label_project_delivery_manager}</Typography>
                <FormControl sx={{ minWidth: '260px' }} size="small">
                  <CognisaasUserSelect
                    width="310px"
                    name="deliveryManager"
                    allOptions={allCompanyUsersDropDownData}
                    selectedOption={props.values.deliveryManager}
                    onChange={(val: any) => {
                      props.setFieldValue('deliveryManager', {
                        name: val.target.textContent,
                        id: getUserUid(val.target.textContent),
                      });
                    }}
                    onBlur={props.handleBlur}
                    error={props.touched.deliveryManager && Boolean(props.errors.deliveryManager)}
                    helperText={props.touched.deliveryManager && props.errors.deliveryManager}
                  />
                </FormControl>
              </div>

              <div className={Styles.OnboardAccountProfile__formField}>
                <Typography variant="subtitle2">
                  {`Implementation Fee (${getSymbolFromCurrency(customerDetail?.currency_code)})`}
                </Typography>
                <TextField
                  type="number"
                  sx={{ width: '310px' }}
                  size="small"
                  id="implementationFee"
                  name="implementationFee"
                  placeholder="0"
                  onChange={props.handleChange}
                  value={props.values.implementationFee}
                  onBlur={props.handleBlur}
                  error={props.touched.implementationFee && Boolean(props.errors.implementationFee)}
                  helperText={props.touched.implementationFee && props.errors.implementationFee}
                ></TextField>
              </div>
              <div className={Styles.OnboardAccountProfile__formField}>
                <Typography variant="subtitle2">Stage</Typography>
                <FormControl sx={{ minWidth: '260px' }} size="small">
                  <CognisaasSelect
                    width="310px"
                    onChange={props.handleChange}
                    name="projectStage"
                    value={
                      props.values.projectStage == 'Pre-Sales'
                        ? getCustomNameMappedValue('Pre-Sales', 'project_stage')
                        : props.values.projectStage
                    }
                    validValues={projectStages}
                    onBlur={props.handleBlur}
                    error={props.touched.projectStage && Boolean(props.errors.projectStage)}
                    helperText={props.touched.projectStage && props.errors.projectStage}
                  />
                </FormControl>
              </div>
            </div>

            <div className={Styles.OnboardAccount__section}>
              <div className={Styles.OnboardAccountProfile__formField}>
                <Typography variant="subtitle2">
                  {/* label_arr {customerDetail && `(${customerDetail.currency_code})`} */}
                  {`${label_project} ${label_arr} (${getSymbolFromCurrency(customerDetail?.currency_code)})`}
                </Typography>
                <TextField
                  type="number"
                  size="small"
                  id="projectARR"
                  name="projectARR"
                  placeholder="0"
                  onChange={props.handleChange}
                  value={props.values.projectARR}
                  // onBlur={props.handleBlur}
                  error={props.touched.projectARR && Boolean(props.errors.projectARR)}
                  helperText={props.touched.projectARR && props.errors.projectARR}
                  style={{ width: '257px' }}
                ></TextField>
              </div>
            </div>

            {/* date component */}

            <div className={Styles.OnboardAccount__section}>
              <div className={Styles.OnboardAccountProfile__formField}>
                <Typography variant="subtitle2">Planned Start Date*</Typography>

                <CognisaasCalenderNew
                  width="310px"
                  value={new Date(props.values.plannedStartDate)}
                  handleChange={(date: any) => props.setFieldValue('plannedStartDate', new Date(date))}
                />
              </div>

              <div className={Styles.OnboardAccountProfile__formField}>
                <Typography variant="subtitle2">Actual Start Date</Typography>
                <CognisaasCalenderNew
                  width="310px"
                  value={new Date(props.values.actualStartDate)}
                  handleChange={(date: any) => props.setFieldValue('actualStartDate', new Date(date))}
                />
              </div>

              <div className={Styles.OnboardAccountProfile__formField}>
                <Typography variant="subtitle2">{label_due_date}*</Typography>
                <CognisaasCalenderNew
                  width="310px"
                  value={new Date(props.values.dueDate)}
                  handleChange={(date: any) => props.setFieldValue('dueDate', new Date(date))}
                />
              </div>

              <div className={Styles.OnboardAccountProfile__formField}>
                <Typography variant="subtitle2">Estimated Delivery Date</Typography>
                <CognisaasCalenderNew
                  width="310px"
                  value={new Date(props.values.etaForDeliveryDate)}
                  handleChange={(date: any) => props.setFieldValue('etaForDeliveryDate', new Date(date))}
                />
              </div>
            </div>

            {/* Add Project Members */}
            {
              <>
                <Stack sx={{ margin: '16px 0' }}>
                  <Typography variant="subtitle1">Add members for this project</Typography>
                </Stack>

                <Stack spacing={2} direction="row">
                  <Stack sx={{ marginRight: '0px' }}>
                    <Typography variant="subtitle1">Select team members for this {label_project}</Typography>

                    <Stack sx={{ width: '310px' }}>
                      <CognisaasMultiSelectWithSearchAndValidation
                        name="teamMembers"
                        selectedOptions={props.values.teamMembers}
                        allOptions={availableTeamMemberOptions}
                        errText="Choose at least one team member"
                        placeholder="Choose a team member"
                        width="310px"
                      />
                    </Stack>
                  </Stack>

                  <Stack>
                    <Typography variant="subtitle1">
                      Add {label_client} contact as members for this {label_project}
                    </Typography>

                    <Stack sx={{ width: '310px' }}>
                      <CognisaasMultiSelectWithSearchUid
                        allOptions={clientContactsDropDownData}
                        selectedOption={selectedContactOption}
                        setSelectedOptions={setSelectedContactOptions}
                      />
                    </Stack>
                  </Stack>
                </Stack>
              </>
            }

            {/* project description */}
            <div className={Styles.OnboardAccount__section}>
              <div className={Styles.OnboardAccountProfile__formField}>
                <Typography variant="subtitle2">{label_project} Description</Typography>
                <FormControl>
                  <TextField
                    sx={{ width: '47vw', minWidth: '260px' }}
                    id="projectDescription"
                    name="projectDescription"
                    placeholder={`Enter ${label_project} description`}
                    onChange={props.handleChange}
                    value={props.values.projectDescription}
                    multiline
                    rows={4}
                    onBlur={props.handleBlur}
                    error={props.touched.projectDescription && Boolean(props.errors.projectDescription)}
                    helperText={props.touched.projectDescription && props.errors.projectDescription}
                  ></TextField>
                </FormControl>
              </div>
            </div>

            {/* comments section */}
            <div className={Styles.OnboardAccount__section}>
              <div className={Styles.OnboardAccountProfile__formField}>
                {/* comment component here */}
                <Typography variant="subtitle2">Comments</Typography>
                <FormControl>
                  <TextField
                    sx={{ width: '47vw', minWidth: '260px' }}
                    id="comment"
                    name="comment"
                    placeholder="Enter Comment"
                    onChange={props.handleChange}
                    value={props.values.comment}
                    multiline
                    rows={4}
                    onBlur={props.handleBlur}
                    error={props.touched.comment && Boolean(props.errors.comment)}
                    helperText={props.touched.comment && props.errors.comment}
                  ></TextField>
                </FormControl>
              </div>
            </div>

            {/* buttons for furthur form actions */}
            <div className={Styles.OnboardAccount__formBottomBarWrapper}>
              <CognisaasButton label={'Back'} isOutlined={true} clickHandler={componentProps.handleCancel} />

              <div className={Styles.OnboardAccountProfile__formActionButtonsContainer}>
                {/* save and cancel this step buttons  */}
                <CognisaasButton label={'Cancel'} isOutlined={true} clickHandler={componentProps.handleCancel} />
                <span style={{ width: '20px' }}></span>
                {onboardProjectStatus ? (
                  <LoadingButton loading loadingPosition="start" startIcon={<SaveIcon />} variant="outlined">
                    Save
                  </LoadingButton>
                ) : (
                  <CognisaasButton
                    disabled={!props.isValid || !props.dirty}
                    label={'Save'}
                    isOutlined={false}
                    clickHandler={props.handleSubmit}
                  />
                )}
                {/* <CognisaasButton
                  disabled={!props.isValid || !props.dirty}
                  label={'Save'}
                  isOutlined={false}
                  clickHandler={props.handleSubmit}
                /> */}
              </div>
            </div>
          </form>
        )}
      </Formik>
      <Backdrop sx={{ color: '#fff', zIndex: 1201 }} open={importInProgress}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default OnboardAddProjects;
