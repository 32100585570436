import * as React from 'react';
import { ReactComponent as MilestoneTaskBlockedStage } from '../../../assets/svgs/MilestoneTaskBlockedStage.svg';
import { ReactComponent as MilestoneTaskDoneStage } from '../../../assets/svgs/MilestoneTaskDoneStage.svg';
import { ReactComponent as MilestoneTaskInProgressStage } from '../../../assets/svgs/MilestoneTaskInProgressStage.svg';
import { ReactComponent as MilestoneTaskNotStartedStage } from '../../../assets/svgs/MilestoneTaskNotStartedStage.svg';
import { ReactComponent as NormalTaskBlockedStage } from '../../../assets/svgs/NormalTaskBlockedStage.svg';
import { ReactComponent as NormalTaskDoneStage } from '../../../assets/svgs/NormalTaskDoneStage.svg';
import { ReactComponent as NormalTaskInProgressStage } from '../../../assets/svgs/NormalTaskInprogressStage.svg';
import { ReactComponent as NormalTaskNotStartedStage } from '../../../assets/svgs/NormalTaskNotStartedStage.svg';
import { ReactComponent as NormalTaskDeferredStage } from '../../../assets/svgs/NormalTaskDeferredStage.svg';
import { ReactComponent as MilestoneTaskDeferredStage } from '../../../assets/svgs/MilestoneTaskDeferredStage.svg';

export enum taskStage {
  BLOCKED = 'Blocked',
  IN_PROGRESS = 'In Progress',
  NOT_STARTED = 'Not Started',
  DONE = 'Done',
  DEFERRED = 'Deferred',
  NOT_DONE = 'Not Done',
}

export enum raidStage {
  MITIGATED = 'Mitigated',
  NOT_MITIGATED = 'Not Mitigated',
}

interface Props {
  isMileStoneTask: true | false;
  stage: taskStage.BLOCKED | taskStage.IN_PROGRESS | taskStage.NOT_STARTED | taskStage.DONE | taskStage.DEFERRED | '';
}

const CognisaasTaskStageIndicator: React.FC<Props> = (props: Props) => {
  const IndicatorForTask = (props: Props) => {
    if (props.isMileStoneTask && props.stage == taskStage.BLOCKED) {
      return <MilestoneTaskBlockedStage />;
    } else if (props.isMileStoneTask && props.stage == taskStage.DONE) {
      return <MilestoneTaskDoneStage />;
    } else if (props.isMileStoneTask && props.stage == taskStage.IN_PROGRESS) {
      return <MilestoneTaskInProgressStage />;
    } else if (props.isMileStoneTask && props.stage == taskStage.NOT_STARTED) {
      return <MilestoneTaskNotStartedStage />;
    } else if (props.isMileStoneTask && props.stage == taskStage.DEFERRED) {
      return <MilestoneTaskDeferredStage />;
    } else if (!props.isMileStoneTask && props.stage == taskStage.BLOCKED) {
      return <NormalTaskBlockedStage />;
    } else if (!props.isMileStoneTask && props.stage == taskStage.DONE) {
      return <NormalTaskDoneStage />;
    } else if (!props.isMileStoneTask && props.stage == taskStage.IN_PROGRESS) {
      return <NormalTaskInProgressStage />;
    } else if (!props.isMileStoneTask && props.stage == taskStage.NOT_STARTED) {
      return <NormalTaskNotStartedStage />;
    } else if (!props.isMileStoneTask && props.stage == taskStage.DEFERRED) {
      return <NormalTaskDeferredStage />;
    }
  };
  return <React.Fragment>{IndicatorForTask(props)}</React.Fragment>;
};

export default CognisaasTaskStageIndicator;
