import {
  Drawer,
  Typography,
  Stack,
  Grid,
  TextField,
  Button,
  ButtonGroup,
  TextareaAutosize,
  Switch,
} from '@mui/material';
import { useState, useEffect } from 'react';
import * as React from 'react';
import { ReactComponent as EmptyMileStone } from '../../../assets/svgs/EmptyMileStone.svg';
import './datePickerStyle.css';
import CognisaasSelect from '../../shared/CognisaasSelect/CognisaasSelect';
import { selectUsersOfCompany } from '../../../store/modules/Common/selector';
// import { useDispatch } from 'react-redux';
import { useAppSelector, useAppDispatch } from '../../../store/hooks';
import { getAllUsers } from '../../../store/modules/Common/slice';
import CognisaasButton from '../../shared/CognisaasButton/CognisaasButton';
import { CognisaaasTooltip } from '../../shared/CognisaasTooltip/CognisaasTooltip';
import { selectJiraPriorityList, selectJiraProjects } from '../../../store/modules/Task/selector';
// import { addProjectTask, addUsecaseTask } from '../../../store/modules/Task/slice';
import format from 'date-fns/format';
import SaveIcon from '@mui/icons-material/Save';
import { Formik } from 'formik';
// import CognisaasUserSelect from 'components/shared/CognisaasSelect/CognisaasUserSelect';
// import useUserDropDownData from 'components/shared/Hooks/useUserDropDownData';
import { startOfDay, endOfDay, differenceInDays } from 'date-fns';
import * as yup from 'yup';
import {
  getCustomerUid,
  getLoggedInUserName,
  getLoggedInUserUid,
  getUserUid,
  // getCustomerUrlName,
  getCustomerSubDomain,
  getContactUidByContactName,
} from '../../../utils/getUid';
import { getProjectInformation, updateTaskDelay } from '../../../store/modules/Project/slice';
import CognisaasCalenderNew from '../../shared/CognisaasCalenderNew/CognisaasCalenderNew';
import { selectProjectInformation } from '../../../store/modules/Project/selector';
import { updateCaseWithfeatureStatus } from '../../../store/modules/Common/slice';
import { fetchClientContacts, fetchClientInformation } from 'store/modules/ManageClient/ClientList/slice';
import { getProjectInformationWithCaseTaskByUid } from '../../../store/modules/Project/slice';
import { getJiraJsdServicesRequestLists } from '../../../store/modules/Settings/slice';
// import { selectAllJiraProjectsData } from 'store/modules/Settings/selector';
// import { getCustomerDetail } from '../../../store/modules/Common/slice';
import { selectCustomerDetail } from '../../../store/modules/Common/selector';
import {
  getJiraPriorityList,
  getJiraProjectIssueTypesList,
  getJiraProjects,
  syncAllJiraTasks,
} from '../../../store/modules/Task/slice';
import { selectJiraProjectIssueType } from '../../../store/modules/Task/selector';
import {
  getJiraCustomFields,
  getMandatoryCustomFieldsForIssue,
  addTaskToJira,
  // getJiraIssueId,
  getMandatoryCustomFieldsForJsdServiceRequests,
  addTaskToJiraServiceDesk,
  getJiraCustomFieldNames,
} from '../jiraHelpers/jiraUtil';
import BasicModal from 'components/shared/CognisaasModal/CognisaasModal';
import JiraCustomFields from '../jiraHelpers/JiraCustomFields';
import { taskAPI } from 'api/modules/tasks';
// import { log } from 'console';
// import { isNull } from 'utils/dataHelpers';
import { selectJiraServiceDeskRequestLists } from 'store/modules/Settings/selector';
import { jiraAPI } from 'api/modules/jira';
import JiraServiceDeskCustomFields from '../jiraHelpers/JiraServiceDeskCustomFields';
import CognisaasToast from 'components/shared/CognisaasToast/CognisaasToast';
import { DateRange, LoadingButton } from '@mui/lab';
import getSymbolFromCurrency from 'currency-symbol-map';
import CognisaasAutocompleteSelect from 'components/shared/CognisaasSelect/CognisaasAutocompleteSelect';
import { usePreselectedProjectMembers } from 'components/shared/Hooks/usePreselectedProjectMembers';
import useUserDropDownData from 'components/shared/Hooks/useUserDropDownData';
import {
  label_client,
  label_delivery_date,
  label_due_date,
  label_task,
} from 'components/Settings/Preferences/CustomLabelCookieHelper';
import GetOtherInfoBeforeStandaloneTask from './GetOtherInfoBeforeStandaloneTask';
import { getProjectByProjectUid } from '../../../store/modules/Project/slice';
import { isNull } from 'utils/dataHelpers';
import BasicDateRangePicker from 'components/shared/BasicDateRangePicker/BasicDateRangePicker';
import { doesValueExists } from './utils';
import useCustomNames from 'components/shared/CustomNames/useCustomNames';
import NAMED_CONSTATNTS from 'components/shared/CustomNames/Constants';
import { getUsecaseByUid } from 'store/modules/Usecase/slice';
// import { jiraEnabledInTaskLevel } from '../../../utils/validations';

const taskValidationSchema = yup.object().shape({
  actualStartDate: yup.date().required('Required'),
  plannedStartDate: yup.date().required('Required'),
  dueDate: yup
    .date()
    .required('Required')
    .test(
      'is-taskDueDate',
      () => `Must be after ${label_task} start dates`,
      function validateDates(value) {
        const { actualStartDate, plannedStartDate } = this.parent;
        return value !== undefined && actualStartDate <= value && plannedStartDate <= value;
      }
    ),
  etaForDelivery: yup
    .date()
    .required('Required')
    .test(
      'is-taskDeliveryDate',
      () => `Must be after ${label_task} start dates`,
      function validateDates(value) {
        const { actualStartDate, plannedStartDate } = this.parent;
        return value !== undefined && actualStartDate <= value && plannedStartDate <= value;
      }
    ),
});

const NewTaskDrawer = (props: any) => {
  const { onClose } = props;
  const projectInfo = useAppSelector(selectProjectInformation);
  const [projectInfoForStandalone, setProjectInfoForStandalone] = useState({});
  const getProjectInfo = () => {
    if (props?.type === 'standalone') {
      return projectInfoForStandalone;
    } else {
      return projectInfo;
    }
  };
  const [usecaseName, setUsecaseName] = useState(props.caseOrProjectName);
  console.log('projectInfo = ', getProjectInfo());
  const customerDetail = useAppSelector(selectCustomerDetail);
  const issueTypes = useAppSelector(selectJiraProjectIssueType);
  let clientUid = getProjectInfo()?.client_uid;
  const companyUsersDropDownData = useUserDropDownData('company');
  const [rangeValue, setRangeValue] = React.useState<DateRange<any>>([new Date(), new Date()]);
  const [plannedStartDate, setPlannedStartDate] = useState<Date>(new Date());
  const [dueDate, setDueDate] = useState<Date>(new Date());

  // const clientUsersDropDownData = useUserDropDownData('client', clientUid);

  // const [users, setUsers] = useState<any[]>([]);
  const [disableSave, setDisableSave] = useState<boolean>(true);
  const [jiraCustomfields, setJiraCustomfields] = useState<any>(null);
  const [jiraCustomFieldsModal, setJiraCustomFieldsModal] = useState<boolean>(false);
  const [issueTypesForProject, setIssueTypesForProject] = useState<any>([]);
  const [isTeamTask, setIsteamTask] = useState<boolean>(true);
  const [taskName, setTaskName] = useState<string>('');
  const [stage, setStage] = useState<string>('Not Started');
  const [priority, setPriority] = useState<string>('Medium');
  const [taskOwner, setTaskOwner] = useState<any>();
  const [taskReporter, setTaskReporter] = useState<string>(getLoggedInUserName());
  const [implementationFee, setImplementationFee] = useState<any | null>(0);
  const [taskDescription, setTaskDesription] = useState<string>('');
  const [mileStone, setMileStone] = useState<boolean>(false);
  const [shareWithClient, setShareWithClient] = useState<boolean>(false);
  const [csatIndicator, setCsatIndicator] = useState<boolean>(false);
  const [isCsatDisabled, setIsCsatDisabled] = useState<boolean>(false);
  const [pushToJira, setPushToJira] = useState<boolean>(false);
  const [selectedJiraProject, setSelectedJiraProject] = useState<string>('');
  const [selectedOldJiraProject, setSelectedOldJiraProject] = useState<string>('');
  const [issueType, setIssueType] = useState<any>('');
  const [priorityType, setPriorityType] = useState<any>('Medium');
  const [enableActualStartDate, setEnableActualStartDate] = useState(false);
  const [taskDetailToBeAdded, setTaskDetailToBeAdded] = useState<any>(null);
  const dispatch = useAppDispatch();
  const [alertDetails, setAlertDetails] = useState<{
    isVisible: boolean;
    type: 'success' | 'failure';
    message: string;
  }>({ isVisible: false, type: 'success', message: '' });
  const [isJSDenabled, setIsJSDenabled] = useState<boolean>(false);
  const jiraServicesList = useAppSelector(selectJiraServiceDeskRequestLists);
  // const jsdCustomFields = useAppSelector(selectJsdCustomFields);

  // standalone
  const [isPrerequisiteForStandaloneTaskIsValidated, setIsPrerequisiteForStandaloneTaskIsValidated] = useState<boolean>(
    () => {
      if (props?.type === 'standalone') {
        return false;
      }
      return true;
    }
  );
  const drawerType: string = props.type;
  const [associationUid, setAssociationUid] = useState<string>(props.associationUid);
  const [phaseUid, setPhaseUid] = useState<string>(props.phaseUid);
  const [isProjectTask, setIsProjectTask] = useState<boolean>(props.isProjectTask);
  const [projectUid, setProjectUid] = useState(props.projectUid);
  const [caseUid, setCaseUid] = useState(props.caseUid);
  const {
    taskStagesDropdownValues,
    taskPrioritiesDropdownValues,
    getCustomNameMappedValue,
    getStandardNameMappedValue,
  } = useCustomNames();
  const taskAllStages = taskStagesDropdownValues.map((item: any) => item.custom_stage_name);
  const taskAllPriorities = taskPrioritiesDropdownValues.map((item: any) => item.custom_priority_name);

  useEffect(() => {
    if (props.type !== 'standalone') {
      setPhaseUid(props.phaseUid);
    }
  }, [props.phaseUid]);

  const initialValues = {
    plannedStartDate: new Date(),
    actualStartDate: new Date(),
    dueDate: new Date(),
    etaForDelivery: new Date(),
  };

  useEffect(() => {
    if (stage == 'Not Started') setEnableActualStartDate(false);
    else setEnableActualStartDate(true);
  }, [stage]);

  const usersOfCompany = useAppSelector(selectUsersOfCompany);
  const jiraPriorityList = useAppSelector(selectJiraPriorityList);
  const jiraProjectList = useAppSelector(selectJiraProjects);

  const jiraPriorityValues = jiraPriorityList.map((item: any) => item.name);
  const jiraProjectValues = jiraProjectList.map((item: any) => item.jira_project_name);
  const [jsdCustomFieldsModal, setJsdCustomFieldsModal] = useState<boolean>(false);

  // final upgradation
  const [isSavingInProgress, setIsSavingInProgress] = useState<boolean>(false);

  useEffect(() => {
    if (customerDetail) {
      setIsJSDenabled(customerDetail.customer_JSD === '1');
      if (customerDetail.customer_jira_user && customerDetail.customer_jira_user.length > 0) {
        const data = {
          customer_uid: getCustomerUid(),
          key: btoa(btoa(`${customerDetail.customer_jira_user}:${customerDetail.customer_jira_token}`)),
        };
        // dispatch(getJiraProjectsFromApi(data));
        dispatch(getJiraProjects());
        dispatch(getJiraPriorityList(data));
      }

      // const data1 = {
      //   customer_uid: getCustomerUid(),
      //   key: btoa(btoa(`${customerDetail.customer_jira_user}:${customerDetail.customer_jira_token}`)),
      //   project_key: 'SUP',
      // };
      // // console.log('DDDDDDDDDDDDDD', data1);

      // dispatch(getJiraProjectIssueTypesList(data1));
    }

    // }
    // const data1 = {
    //   customer_uid: getCustomerUid(),
    //   key: btoa(btoa(`${customerDetail.customer_jira_user}:${customerDetail.customer_jira_token}`)),
    //   project_key: 'SUP',
    // };
    // console.log('DDDDDDDDDDDDDD', data1);

    // dispatch(getJiraProjectIssueTypesList(data1));
  }, [selectCustomerDetail, customerDetail]);

  useEffect(() => {
    setIsSavingInProgress(false);
    dispatch(fetchClientContacts(clientUid));
    setMileStone(false);
    setShareWithClient(false);
    setCsatIndicator(false);
    setIsCsatDisabled(false);
    setPushToJira(false);
    setTaskOwner('Not Assigned');
    setTaskReporter(getLoggedInUserName());
    setIssueType('');
    setPriorityType('');
    setStage('Not Started');
    setJiraCustomFieldsModal(false);
    setJiraCustomfields([]);
    setJsdCustomFieldsModal(false);
    setTaskDesription('');
    // setPlannedStartDate(new Date());
    // setActualStartDate(new Date());
    // setDueDate(new Date());
    // setEtaForDelivery(new Date());
    setIsteamTask(true);
    // setAddTaskResponseDetails({});
    setTaskDetailToBeAdded(null);
    if (usersOfCompany.length == 0) {
      dispatch(getAllUsers());
      setMileStone(false);
      setMileStone(false);
      setShareWithClient(false);
      setCsatIndicator(false);
      setIsCsatDisabled(false);
      setPushToJira(false);
      setTaskOwner('Not Assigned');
      setTaskReporter(getLoggedInUserName());
      setIssueType('');
      setPriorityType('');
      setStage('Not Started');
      setJiraCustomFieldsModal(false);
      setJiraCustomfields([]);
      setJsdCustomFieldsModal(false);
      // setAddTaskResponseDetails({});
      setTaskDetailToBeAdded(null);
    }
    return () => {
      // console.log(mileStone, 'swswdwdwfefef');
      setMileStone(false);
      // setMileStone(false);
      setShareWithClient(false);

      setCsatIndicator(false);
      setIsCsatDisabled(false);
      setPushToJira(false);
      setTaskOwner('Not Assigned');
      setTaskReporter(getLoggedInUserName());
      setIssueType('');
      setPriorityType('Medium');
      setStage('Not Started');
      setJiraCustomFieldsModal(false);
      // setAddTaskResponseDetails({});
      setTaskDetailToBeAdded(null);
      setJsdCustomFieldsModal(false);
      setIsSavingInProgress(false);
      setJiraCustomFieldsModal(false);
      setJiraCustomfields([]);
    };
  }, []);

  useEffect(() => {
    const p = jiraProjectList.filter((project: any) => project.jira_project_name == selectedJiraProject);

    if (p.length > 0 && p[0].jira_project_name != selectedOldJiraProject) {
      const key = btoa(btoa(`${customerDetail.customer_jira_user}:${customerDetail.customer_jira_token}`));

      if (isJSDenabled) {
        dispatch(
          getJiraJsdServicesRequestLists({
            customer_uid: customerDetail.customer_uid,
            key: key,
            jira_service_desk_id: p[0].jira_service_desk_id,
          })
        );
      } else {
        const data1 = {
          customer_uid: getCustomerUid(),
          key: key,
          project_key: p[0].project_key,
        };

        dispatch(getJiraProjectIssueTypesList(data1));
      }
    }
  }, [selectedJiraProject]);

  useEffect(() => {
    if (!isJSDenabled) {
      if (issueTypes.length > 0) {
        const issues = issueTypes.map((issue: any) => {
          return issue.name;
        });
        setIssueTypesForProject([...issues]);
        setIssueType(issues[0]);
        setPriorityType(jiraPriorityValues[0]);
      }
    }
  }, [issueTypes, isJSDenabled]);

  useEffect(() => {
    if (isJSDenabled) {
      if (jiraServicesList.data.values && jiraServicesList.data.values.length > 0) {
        const issues = jiraServicesList.data.values.map((item: any) => item.name);
        setIssueTypesForProject([...issues]);
        setIssueType(issues[0]);
        setPriorityType(jiraPriorityValues[0]);
      }
    }
  }, [jiraServicesList.data, isJSDenabled]);

  const pushToJiraContent = () => {
    return (
      <Stack direction="row" spacing={2}>
        <Stack>
          <Typography variant="subtitle2">Jira Project*</Typography>
          <CognisaasSelect
            placeholder="Select"
            onChange={(e: any) => {
              setSelectedOldJiraProject(selectedJiraProject);
              setSelectedJiraProject(e.target.value);
              updateProject(e);
            }}
            value={selectedJiraProject}
            validValues={[...jiraProjectValues]}
            width={'220px'}
          />
        </Stack>
        <Stack>
          <Typography variant="subtitle2">Jira Issue Type*</Typography>
          <CognisaasSelect
            placeholder="Select"
            onChange={(e: any) => setIssueType(e.target.value)}
            value={issueType}
            validValues={[...issueTypesForProject].filter((item: any) => item !== 'Sub-task')}
            width={'220px'}
          />
        </Stack>
        <Stack>
          <Typography variant="subtitle2">Jira Priority*</Typography>
          <CognisaasSelect
            onChange={(e: any) => setPriorityType(e.target.value)}
            value={priorityType}
            placeholder="Select"
            validValues={[...jiraPriorityValues]}
            width={'220px'}
          />
        </Stack>
      </Stack>
    );
  };

  const getIssueId = () => {
    const issue = issueTypes.filter((issue: any) => issue.name == issueType)[0];
    return issue.id;
  };

  const getProjectId = () => {
    const project = jiraProjectList.filter((project: any) => project.jira_project_name == selectedJiraProject);
    return project[0].jira_project_id;
  };

  const getCurrentProjectKey = () => {
    const project = jiraProjectList.filter((project: any) => project.jira_project_name == selectedJiraProject);
    return project[0].project_key;
  };

  const getProjectJiraServiceDeskId = (jiraProjectList: any[]) => {
    const project = jiraProjectList.filter((project: any) => project.jira_project_name == selectedJiraProject);
    return project[0].jira_service_desk_id;
  };

  const resetValues = () => {
    setMileStone(false);
    setShareWithClient(false);
    setCsatIndicator(false);
    setIsCsatDisabled(false);
    setPushToJira(false);
    setTaskOwner('Not Assigned');
    setTaskReporter(getLoggedInUserName());
    setStage('Not Started');
    setMileStone(false);
    setPriority('Medium');
    setTaskDesription('');
    setIsteamTask(true);
    setImplementationFee(0);
    setEnableActualStartDate(false);
    setDisableSave(true);
    if (props?.type === 'standalone') {
      setIsPrerequisiteForStandaloneTaskIsValidated(false);
    }
  };

  const updateProject = (e: any) => {
    console.log(e);
  };

  const getJsdIssueId = (): string => {
    const required_issue = jiraServicesList.data.values.filter((item: any) => item.name === issueType);
    if (required_issue.length > 0) {
      return required_issue[0].id;
    }
    return '';
  };

  const pushTaskToJiraFlow = async (taskDetails: any) => {
    // setAlertDetails({ isVisible: true, type: 'info', message: 'Adding Task to Jira!' });

    const project = jiraProjectList.filter((proj: any) => proj.jira_project_name == selectedJiraProject)[0];
    // case_name when talking inside project it is going to be project name

    const detail = {
      customer_uid: getCustomerUid(),
      key: btoa(btoa(`${customerDetail.customer_jira_user}:${customerDetail.customer_jira_token}`)),
      project_key: project.project_key,
    };

    const jiraCustomFields = await getJiraCustomFields(detail);

    const issue = issueTypes.filter((issue: any) => issue.name == issueType);
    // console.log('jira custom field for selected jira project ', jiraCustomFields, issue[0]);
    const mandatoryFields = getMandatoryCustomFieldsForIssue(jiraCustomFields, issue[0]);

    if (mandatoryFields.length > 0) {
      setJiraCustomfields([...mandatoryFields]);
      setJiraCustomFieldsModal(true);
    } else {
      const status = await addTaskToJira(
        customerDetail,
        project.jira_project_id,
        taskName,
        null,
        customerDetail.customer_jira_user,
        taskDetails,
        project.project_key,
        issue,
        getProjectInfo(),
        [],
        null
      );
      syncTasks();
      setAlertDetails({
        isVisible: true,
        type: status?.status == 1 ? 'success' : 'failure',
        message: status?.message as string,
      });
      setIsSavingInProgress(false);
      afterSave();
    }
  };

  const pushToJiraServiceDeskFlow = async (taskDetails: any) => {
    // setAlertDetails({ isVisible: true, type: 'info', message: 'Adding Task to Jira Service Desk!' });

    const project = jiraProjectList.filter((proj: any) => proj.jira_project_name == selectedJiraProject)[0];
    // case_name when talking inside project it is going to be project name

    const detail = {
      jira_service_desk_id: getProjectJiraServiceDeskId(jiraProjectList),
      requestTypeId: getJsdIssueId(),
      customer_uid: getCustomerUid(),
      key: btoa(btoa(`${customerDetail.customer_jira_user}:${customerDetail.customer_jira_token}`)),
      project_key: project.project_key,
    };
    const issueId = getJsdIssueId();
    const jiraCustomFieldsJsd = await jiraAPI.getJiraJsdCustomFieldsById(detail);
    const jsdCustomFields = JSON.parse(jiraCustomFieldsJsd.data.result);
    // const issue = issueTypes.filter((issue: any) => issue.name == issueType);
    // console.log('jira custom field for selected jira project ', jiraCustomFields, issue[0]);
    if (!jsdCustomFields) {
      setAlertDetails({
        isVisible: true,
        type: 'failure',
        message: 'Problem With jira Service Desk, Please reload and Try Again',
      });
      return;
    }
    const mandatoryFields = getMandatoryCustomFieldsForJsdServiceRequests(jsdCustomFields.requestTypeFields);

    setTaskDetailToBeAdded(taskDetails);
    if (mandatoryFields.length > 0) {
      setJiraCustomfields([...mandatoryFields]);
      setJsdCustomFieldsModal(true);
    } else {
      const progressStatus = await addTaskToJiraServiceDesk(
        customerDetail,
        project.jira_service_desk_id,
        taskName,
        null,
        customerDetail.customer_jira_user,
        taskDetails,
        project.project_key,
        issueId,
        getProjectInfo(),
        [],
        null
      );
      setAlertDetails({
        isVisible: true,
        type: progressStatus.status == 1 ? 'success' : 'failure',
        message: progressStatus.message,
      });
      // syncTasks();
      afterSave();
    }
  };

  const saveTask = async (dateValues: any) => {
    // setAlertDetails({ isVisible: true, type: 'info', message: 'Adding Task to Portal!' });
    setIsSavingInProgress(true);
    const base_com_url = window.location.host + '/customer-portal/' + getCustomerSubDomain() + '/';
    const base_url_for_email = window.location.host;
    const report_url_for_email = window.location.host + '/client-list/client/' + getProjectInfo().client_uid;

    let issueId;
    let projectId;

    if (pushToJira) {
      if (isJSDenabled) {
        issueId = getJsdIssueId();
        projectId = getProjectId();
      } else {
        issueId = getIssueId();
        projectId = getProjectId();
      }
    }

    let taskDetails: Record<string, any>;
    // let newTaskDetails: Record<string, any>;
    // let taskResposeDetails: any = null;

    if (isProjectTask) {
      taskDetails = {
        blocked_by_dependency: '0',
        base_com_url: base_com_url,
        base_url_for_email: base_url_for_email,
        report_url_for_email: report_url_for_email,
        // customer_company: customerDetail.customer_company,
        customer_company: getCustomerSubDomain(),
        client_uid: clientUid,
        case_uid: null,
        client_check: shareWithClient ? '1' : '0',
        client_initiate: '',
        contact_uid: !isTeamTask ? getContactUidByContactName(taskOwner) : null,
        csat_indicator: csatIndicator ? '1' : '0',
        customer_uid: getCustomerUid(),
        dependency_details: [],
        effort_estimate: '',
        jira_ind: pushToJira == false ? 0 : 1,
        jira_issue_type: pushToJira ? issueId : '',
        jira_priority: pushToJira ? priorityType : '',
        jira_project_id: pushToJira ? projectId : '',
        jira_issue_id: pushToJira ? issueId : '',
        jira_issue_key: '',
        jira_status_name: '',
        milestone_task: mileStone ? '1' : '0',
        project_uid: projectUid,
        section_uid: phaseUid,
        task_implementation_fee_percentage: implementationFee,
        starting_time: '',
        task_description: taskDescription,
        task_name: taskName,
        task_closed_date:
          new Date(dateValues.etaForDelivery.split(' ')[0]) > dueDate
            ? format(new Date(dateValues.etaForDelivery), 'yyyy-MM-dd')
            : format(new Date(dueDate), 'yyyy-MM-dd'),
        // task_closed_date: format(endOfDay(new Date(dateValues.etaForDelivery)), 'yyyy-MM-dd HH:mm:ss'),
        task_due_date: format(endOfDay(new Date(dueDate)), 'yyyy-MM-dd HH:mm:ss'),
        task_start_date:
          stage == 'Not Started'
            ? format(startOfDay(new Date(dateValues.plannedStartDate)), 'yyyy-MM-dd HH:mm:ss')
            : format(startOfDay(new Date(dateValues.actualStartDate)), 'yyyy-MM-dd HH:mm:ss'),
        // if its client task then storing the contact uid in both contact_uid and task_owner feild
        task_owner: isTeamTask ? getUserUid(taskOwner) : getContactUidByContactName(taskOwner),
        task_percentage: 0,
        task_planned_start_date: format(startOfDay(new Date(plannedStartDate)), 'yyyy-MM-dd HH:mm:ss'),
        task_created_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
        task_modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
        task_priority: pushToJira == false ? priority : '',
        task_reporter: taskReporter,
        task_reporter_uid: getUserUid(taskReporter),
        created_by_user_name: getLoggedInUserName(),
        created_by_user_uid: getLoggedInUserUid(),
        task_sequence: 3,
        task_stage: stage,
        template_task_uid: '',
        time_remaining: '',
        total_time_spent: '',
        // user_uid: '15243',
        is_team_task: isTeamTask ? '1' : '0',
        last_user_modified: getLoggedInUserUid(),
        usecaseOrProjectName: getProjectInfo().project_name,
      };

      // to add task to project
      // setAddTaskResponseDetails(taskResponse.data.result[0]);
      // taskResposeDetails = taskResponse.data.result[0];
    } else {
      taskDetails = {
        blocked_by_dependency: '',
        base_com_url: base_com_url,
        base_url_for_email: base_url_for_email,
        report_url_for_email: report_url_for_email,
        // customer_company: customerDetail.customer_company,
        customer_company: getCustomerSubDomain(),
        client_uid: clientUid,
        case_uid: caseUid,
        client_check: shareWithClient ? '1' : '0',
        client_initiate: '0',
        contact_uid: !isTeamTask ? getContactUidByContactName(taskOwner) : null,
        csat_indicator: csatIndicator ? '1' : '0',
        customer_uid: getCustomerUid(),
        dependency_details: [],
        effort_estimate: 0,
        jira_ind: pushToJira == false ? 0 : 1,
        jira_issue_type: pushToJira ? issueId : '',
        jira_priority: pushToJira ? priorityType : '',
        jira_project_id: pushToJira ? projectId : '',
        jira_issue_id: pushToJira ? issueId : '',
        jira_issue_key: '',
        jira_status_name: '',
        milestone_task: mileStone ? '1' : '0',
        project_uid: projectUid,
        section_uid: phaseUid,
        task_implementation_fee_percentage: implementationFee,
        starting_time: 0,
        task_description: taskDescription,
        task_name: taskName,
        task_closed_date: format(endOfDay(new Date(dateValues.etaForDelivery)), 'yyyy-MM-dd HH:mm:ss'),
        task_due_date: format(endOfDay(new Date(dueDate)), 'yyyy-MM-dd HH:mm:ss'),
        task_start_date:
          stage == 'Not Started'
            ? format(startOfDay(new Date(dateValues.plannedStartDate)), 'yyyy-MM-dd HH:mm:ss')
            : format(startOfDay(new Date(dateValues.actualStartDate)), 'yyyy-MM-dd HH:mm:ss'),
        task_owner: isTeamTask ? getUserUid(taskOwner) : getContactUidByContactName(taskOwner),
        task_percentage: 0,
        task_planned_start_date: format(startOfDay(new Date(plannedStartDate)), 'yyyy-MM-dd HH:mm:ss'),
        task_created_on: format(new Date(), 'yyyy-MM-dd'),
        task_modified_on: format(new Date(), 'yyyy-MM-dd'),
        task_priority: priority,
        task_reporter: taskReporter,
        task_reporter_uid: getUserUid(taskReporter),
        created_by_user_name: getLoggedInUserName(),
        created_by_user_uid: getLoggedInUserUid(),
        task_sequence: 3,
        task_stage: stage,
        template_task_uid: '',
        time_remaining: 0,
        total_time_spent: 0,
        // user_uid: '15243',
        is_team_task: isTeamTask ? '1' : '0',
        last_user_modified: getLoggedInUserUid(),
        usecaseOrProjectName: usecaseName,
      };

      // add task to usecase not project
      // setAddTaskResponseDetails(taskResponse.data.result[0]);
      // taskResposeDetails = taskResponse.data.result[0];
    }

    // in case if mandatory fields handled they will need this data
    setTaskDetailToBeAdded(taskDetails);

    if (pushToJira) {
      if (isJSDenabled) {
        pushToJiraServiceDeskFlow(taskDetails);
      } else {
        pushTaskToJiraFlow(taskDetails);
      }
    } else {
      await addNormalTask(taskDetails);
      // afterSave();
    }
  };

  const addNormalTask = async (taskPayload: Record<string, any>) => {
    if (taskPayload.case_uid) {
      // this is usecase task
      const responseNewTask = await taskAPI.addUsecaseTask(taskPayload);
      const usecase = await dispatch(getUsecaseByUid(caseUid)).unwrap();
      if (
        new Date(usecase[0].eta_forcasedelivery.split(' ')[0]) < new Date(taskPayload.task_closed_date.split(' ')[0])
      ) {
        // We should add delay
        const delay = differenceInDays(
          new Date(taskPayload.task_closed_date.split(' ')[0]),
          new Date(usecase[0].eta_forcasedelivery.split(' ')[0])
        );
        const detail = {
          task_uid: responseNewTask.data.result[0].task_uid,
          delayed_by: delay,
        };
        await dispatch(updateTaskDelay(detail));
      }
      if (responseNewTask?.data?.status === 0) {
        setAlertDetails({ isVisible: true, type: 'failure', message: responseNewTask?.data?.message });
        setIsSavingInProgress(false);
        return;
      }
    } else {
      // this is project task
      const responseNewTask: any = await taskAPI.addProjectTask(taskPayload);
      const project = await dispatch(getProjectByProjectUid(projectUid)).unwrap();
      console.log(project);
      if (
        new Date(project[0].eta_forprojectdelivery.split(' ')[0]) < new Date(taskPayload.task_closed_date.split(' ')[0])
      ) {
        // We should add delay
        const delay = differenceInDays(
          new Date(taskPayload.task_closed_date.split(' ')[0]),
          new Date(project[0].eta_forprojectdelivery.split(' ')[0])
        );
        const detail = {
          task_uid: responseNewTask.data.result[0].task_uid,
          delayed_by: delay,
        };
        await dispatch(updateTaskDelay(detail));
      }
      if (responseNewTask?.data?.status === 0) {
        setAlertDetails({ isVisible: true, type: 'failure', message: responseNewTask?.data?.message });
        setIsSavingInProgress(false);
        return;
      }
    }
    setAlertDetails({ isVisible: true, type: 'success', message: `${label_task} added successfully!` });

    afterSave();
  };

  const afterSave = async () => {
    const detail = {
      association_uid: associationUid,
      project_uid: projectUid,
    };
    const test = {
      project_uid: projectUid,
    };

    await dispatch(updateCaseWithfeatureStatus(clientUid));

    await dispatch(fetchClientInformation(clientUid));

    await dispatch(getProjectInformationWithCaseTaskByUid(test));

    await dispatch(getProjectInformation(detail));

    if (pushToJira) {
      setTimeout(() => {
        syncTasks();
      }, 1000);
    }

    setIsSavingInProgress(false);
    setTimeout(() => {
      props.onClose();
      resetValues();
    }, 1000);
  };

  const makeUiReadyForJira = () => {
    const data1 = {
      customer_uid: getCustomerUid(),
      key: btoa(btoa(`${customerDetail.customer_jira_user}:${customerDetail.customer_jira_token}`)),
      project_key: jiraProjectList[0].project_key,
    };

    dispatch(getJiraProjectIssueTypesList(data1));

    setSelectedOldJiraProject(jiraProjectList[0].jira_project_name);
    setSelectedJiraProject(jiraProjectList[0].jira_project_name);
  };

  const handleCustomMandatoryFieldsSave = async (newMandatoryFieldsWithValue: any[]) => {
    setJiraCustomFieldsModal(false);
    const project = jiraProjectList.filter((proj: any) => proj.jira_project_name == selectedJiraProject)[0];
    const issue = issueTypes.filter((issue: any) => issue.name == issueType);

    const saveStatus = await addTaskToJira(
      customerDetail,
      project.jira_project_id,
      taskName,
      null,
      customerDetail.customer_jira_user,
      taskDetailToBeAdded,
      project.project_key,
      issue,
      getProjectInfo(),
      newMandatoryFieldsWithValue,
      null
    );
    // syncTasks();
    afterSave();
    // props.onClose();
    setAlertDetails({
      isVisible: true,
      type: saveStatus?.status == 1 ? 'success' : 'failure',
      message: saveStatus?.message as string,
    });
  };

  const handleCustomMandatoryFieldsOfJsd = async (newMandatoryFieldsWithValue: any[]) => {
    setJsdCustomFieldsModal(false);

    const project = jiraProjectList.filter((proj: any) => proj.jira_project_name == selectedJiraProject)[0];
    // const issue = issueTypes.filter((issue: any) => issue.name == issueType);
    // const base_com_url = window.location.host + '/customer-portal/' + customerDetail.customer_url_name + '/';
    const issueId = getJsdIssueId();

    const progressStatus = await addTaskToJiraServiceDesk(
      customerDetail,
      project.jira_service_desk_id,
      taskName,
      null,
      customerDetail.customer_jira_user,
      taskDetailToBeAdded,
      project.project_key,
      issueId,
      getProjectInfo(),
      newMandatoryFieldsWithValue,
      null
    );

    setAlertDetails({
      isVisible: true,
      type: progressStatus.status == 1 ? 'success' : 'failure',
      message: progressStatus.message,
    });

    afterSave();
    // props.onClose();
  };

  // const validateName = (name: string) => {
  //   if (name.trim() === '') {
  //     setDisableSave(true);
  //   } else {
  //     setDisableSave(false);
  //   }
  // };

  const validateAllFilds = () => {
    console.log(
      'validation',
      taskName,
      doesValueExists(taskName),
      plannedStartDate,
      doesValueExists(plannedStartDate.toString()),
      dueDate,
      doesValueExists(dueDate.toString())
    );
    if (
      doesValueExists(taskName) &&
      doesValueExists(plannedStartDate.toString()) &&
      doesValueExists(dueDate.toString())
    ) {
      setDisableSave(false);
    } else {
      setDisableSave(true);
    }
  };

  useEffect(() => {
    validateAllFilds();
  }, [taskName, plannedStartDate, dueDate]);

  const syncTasks = async () => {
    const key = btoa(btoa(customerDetail.customer_jira_user + ':' + customerDetail.customer_jira_token));

    const customfield = await getJiraCustomFieldNames(
      customerDetail.customer_jira_token,
      customerDetail.customer_jira_user,
      customerDetail.customer_uid,
      getCurrentProjectKey(),
      issueTypes.filter((item) => item.id === '10002')[0]
    );

    dispatch(
      syncAllJiraTasks({
        client_uid: clientUid,
        customDeliveryDate: customfield.customDeliveryDate,
        customer_uid: customerDetail.customer_uid,
        key: key,
      })
    );
  };

  const { preselectedProjectMembers, preselectedClientContacts } = usePreselectedProjectMembers(projectUid,clientUid);

  const taskOwnerDropdownOptions = isTeamTask
    ? preselectedProjectMembers.filter((user: any) => user.is_active === '1').map((user: any) => user.user_username)
    : preselectedClientContacts.map((user: any) => user.user_username);

  console.log('users', preselectedProjectMembers, preselectedClientContacts, taskOwnerDropdownOptions);
  const notAssignedStr = 'Not Assigned';
  const taskOwnerDropdownOptionsWithNotAssigned = taskOwnerDropdownOptions?.length
    ? [...taskOwnerDropdownOptions, notAssignedStr]
    : [notAssignedStr];

  const handleNextOfDetailsForStandalone = (result: {
    clientDetails: Record<string, any>;
    projectDetails: Record<string, any>;
    usecaseDetails: Record<string, any>;
    phaseDetails: Record<string, any>;
  }) => {
    console.log('got here', result);
    setProjectInfoForStandalone(result.projectDetails);
    clientUid = result.clientDetails.client_uid;
    setAssociationUid(result.clientDetails.association_uid);
    setPhaseUid(result.phaseDetails.section_uid);
    setIsProjectTask(isNull(result.usecaseDetails) ? true : false);
    setProjectUid(result.projectDetails.project_uid);
    setCaseUid(isNull(result.usecaseDetails) ? null : result.usecaseDetails.case_uid);
    setUsecaseName(isNull(result.usecaseDetails) ? '' : result.usecaseDetails.case_name);
  };

  return (
    <>
      <Drawer
        open={props.drawerState}
        onClose={() => {
          props.onClose();
          resetValues();
        }}
        anchor="right"
        sx={{ width: '300px' }}
      >
        (
        <Formik
          validationSchema={taskValidationSchema}
          initialValues={initialValues}
          onSubmit={(values) => {
            // addAuditTrailForTaskUpdate(initialValues, values);
            saveTask({
              ...values,
              etaForDelivery: format(new Date(values.etaForDelivery), 'yyyy-MM-dd HH:mm:ss'),
              actualStartDate: format(new Date(values.actualStartDate), 'yyyy-MM-dd HH:mm:ss'),
            });
          }}
        >
          {(props: any) => {
            const { errors } = props;

            const errMsgMap = {
              dueDate: errors?.dueDate ?? '',
              etaForDelivery: errors?.etaForDelivery ?? '',
            };

            return (
              <form>
                <Grid spacing={2} mx={2}>
                  <Typography my={1} sx={{ marginTop: '70px' }} variant="h2">
                    Add {label_task}
                  </Typography>
                  {drawerType === 'standalone' && (
                    <GetOtherInfoBeforeStandaloneTask
                      onSubmit={handleNextOfDetailsForStandalone}
                      updateIsValidated={setIsPrerequisiteForStandaloneTaskIsValidated}
                    />
                  )}
                  <Stack spacing={3} direction="column">
                    <Stack>
                      <Stack direction="row" justifyContent="space-between" alignItems={'center'}>
                        <ButtonGroup size="large" variant="outlined" aria-label="outlined button group">
                          <Button
                            onClick={() => {
                              setIsteamTask(true);
                              setTaskOwner('Not Assigned');
                            }}
                            color={isTeamTask ? 'primary' : 'secondary'}
                            className={`${isTeamTask ? 'active-btn' : null}`}
                          >
                            Team {label_task}
                          </Button>
                          <Button
                            disabled={pushToJira}
                            onClick={() => {
                              setIsteamTask(false);
                              setTaskOwner('Not Assigned');
                            }}
                            color={!isTeamTask ? 'primary' : 'secondary'}
                            className={`${!isTeamTask ? 'active-btn' : null}`}
                          >
                            Client {label_task}
                          </Button>
                        </ButtonGroup>
                      </Stack>
                    </Stack>
                    <Stack>
                      <Typography variant="subtitle2">{label_task} Name</Typography>
                      <TextField
                        autoComplete="off"
                        onChange={(e) => {
                          setTaskName(e.target.value);
                          // validateName(e.target.value);
                        }}
                        autoFocus={true}
                        style={{ width: '310px' }}
                        size="small"
                        placeholder={`Enter ${label_task} name`}
                      ></TextField>
                    </Stack>
                    <Stack spacing={2} direction="row">
                      <Stack>
                        {pushToJira ? (
                          <CognisaaasTooltip
                            title={
                              <Typography variant="subtitle1">
                                The {label_task} Stage of Jira {label_task.toLowerCase()} is automatically synced with
                                the stage in jira
                              </Typography>
                            }
                          >
                            <Stack>
                              <Typography variant="subtitle2">{label_task} Stage</Typography>
                              <CognisaasSelect
                                disabled={pushToJira}
                                onChange={(e: any) =>
                                  setStage(getStandardNameMappedValue(e.target.value, NAMED_CONSTATNTS.task_stage_key))
                                }
                                value={
                                  pushToJira
                                    ? getCustomNameMappedValue('Not Started', NAMED_CONSTATNTS.task_stage_key)
                                    : getCustomNameMappedValue(stage, NAMED_CONSTATNTS.task_stage_key)
                                }
                                validValues={taskAllStages}
                                width="220px"
                              />
                            </Stack>
                          </CognisaaasTooltip>
                        ) : (
                          <>
                            <Typography variant="subtitle2">{label_task} Stage</Typography>
                            <CognisaasSelect
                              disabled={pushToJira}
                              onChange={(e: any) =>
                                setStage(getStandardNameMappedValue(e.target.value, NAMED_CONSTATNTS.task_stage_key))
                              }
                              value={
                                pushToJira
                                  ? getCustomNameMappedValue('Not Started', NAMED_CONSTATNTS.task_stage_key)
                                  : getCustomNameMappedValue(stage, NAMED_CONSTATNTS.task_stage_key)
                              }
                              validValues={taskAllStages}
                              width="220px"
                            />
                          </>
                        )}

                        {/* <CognisaasDropdown width="200px" placeholder={'Not Started'} validvalues={['Not Started', 'In Progress', 'Blocked', 'Done']} /> */}
                      </Stack>
                      <Stack>
                        <Typography variant="subtitle2">{label_task} Owner</Typography>
                        {/* <CognisaasUserSelect
                  width="220px"
                  name="taskOwner"
                  allOptions={isTeamTask ? companyUsersDropDownData : clientUsersDropDownData}
                  selectedOption={taskOwner}
                  onChange={(e: any) => setTaskOwner(e.target.value)}
                /> */}
                        {/* <CognisaasSelect
                          value={taskOwner}
                          onChange={(e: any) => setTaskOwner(e.target.value)}
                          validValues={taskOwnerDropdownOptions}
                          width="220px"
                        ></CognisaasSelect> */}
                        {/* <CognisaasAutocompleteSelect
                          width="220px"
                          value={{
                            id: isTeamTask ? getUserUid(taskOwner) : getContactUidByContactName(taskOwner),
                            name: taskOwner,
                          }}
                          allOptions={isTeamTask ? companyUsersDropDownData : clientUsersDropDownData}
                          onChange={(data: any) => {
                            setTaskOwner(data.name);
                          }}
                        /> */}

                        <CognisaasSelect
                          value={taskOwner}
                          onChange={(e: any) => setTaskOwner(e.target.value)}
                          validValues={taskOwnerDropdownOptionsWithNotAssigned}
                          width="220px"
                        ></CognisaasSelect>
                      </Stack>
                      <Stack>
                        <Typography variant="subtitle2">{label_task} Reporter</Typography>

                        <CognisaasAutocompleteSelect
                          width="220px"
                          value={{ id: getUserUid(taskReporter), name: taskReporter }}
                          allOptions={companyUsersDropDownData.filter((item: any) => item.is_active === '1')}
                          onChange={(data: any) => {
                            setTaskReporter(data.name);
                          }}
                        />
                        {/* <CognisaasDropdown width="200px" placeholder={'Not Assigned'} validvalues={['Arif', 'Sahil', 'Aman']} /> */}
                      </Stack>
                    </Stack>
                    <Stack spacing={2} direction="row">
                      <Stack>
                        <Typography variant="subtitle2">Priority</Typography>
                        <CognisaasSelect
                          onChange={(e: any) =>
                            setPriority(getStandardNameMappedValue(e.target.value, NAMED_CONSTATNTS.task_priority_key))
                          }
                          value={getCustomNameMappedValue(priority, NAMED_CONSTATNTS.task_priority_key)}
                          validValues={taskAllPriorities}
                          width="220px"
                        ></CognisaasSelect>
                        {/* <CognisaasDropdown width="200px" placeholder={'Not Asigned'} validvalues={()} /> */}
                      </Stack>
                      <Stack>
                        {/* <Typography variant="subtitle2">Planned Start Date*</Typography> */}
                        <Stack className="customDatePickerWidth">
                          <BasicDateRangePicker
                            value={rangeValue}
                            setValue={(value: any) => {
                              setRangeValue(value);
                              setPlannedStartDate(new Date(value[0]?.toString()));
                              setDueDate(new Date(value[1]?.toString()));
                            }}
                            startLabel="Planned Start Date"
                            endLabel={label_due_date}
                            width={'220px'}
                          />
                        </Stack>
                      </Stack>
                    </Stack>
                    <Stack spacing={2} direction="row">
                      {enableActualStartDate ? (
                        <Stack>
                          <Typography variant="subtitle2">Actual Start Date*</Typography>
                          <Stack className="customDatePickerWidth">
                            <CognisaasCalenderNew
                              minDate={new Date(props.values.plannedStartDate)}
                              name="actualStartDate"
                              value={props.values.actualStartDate ? new Date(props.values.actualStartDate) : null}
                              handleChange={(e: any) => props.setFieldValue('actualStartDate', new Date(e))}
                              width={'220px'}
                            />
                          </Stack>
                        </Stack>
                      ) : (
                        <CognisaaasTooltip
                          title={
                            <Typography variant="subtitle1" style={{ padding: '10px' }}>
                              {label_task} Start date will be system generated when this {label_task.toLowerCase()}{' '}
                              moves to In progress
                            </Typography>
                          }
                        >
                          <Stack>
                            <React.Fragment>
                              <Typography variant="subtitle2">Actual Start Date</Typography>
                              <CognisaasCalenderNew disabled={true} width={'220px'} />
                            </React.Fragment>
                          </Stack>
                        </CognisaaasTooltip>
                      )}
                      <Stack sx={{ display: 'grid', gridTemplateRows: 'auto auto 20px' }}>
                        <Typography variant="subtitle2">{label_delivery_date}*</Typography>
                        <CognisaasCalenderNew
                          minDate={new Date(props.values.plannedStartDate)}
                          name="etaForDelivery"
                          value={props.values.etaForDelivery}
                          handleChange={(e: any) => props.setFieldValue('etaForDelivery', new Date(e))}
                          // handleChange={(date: Date) => setEtaForDelivery(date)}
                          width={'220px'}
                        />
                        {errMsgMap['etaForDelivery'] && (
                          <Typography variant="subtitle2" color="error">
                            {errMsgMap['etaForDelivery']}
                          </Typography>
                        )}
                      </Stack>
                      <Stack>
                        <Typography variant="subtitle2">
                          {`Implementation Fee (${getSymbolFromCurrency(customerDetail?.currency_code)})`}
                        </Typography>
                        <TextField
                          onChange={(e) => setImplementationFee(e.target.value)}
                          type="number"
                          style={{ width: '220px' }}
                          size="small"
                          placeholder="0%"
                          sx={{ width: '220px' }}
                        ></TextField>
                      </Stack>
                    </Stack>
                    {pushToJira && pushToJiraContent()}
                    <Stack direction="row">
                      <Stack direction="column">
                        <Typography variant="subtitle2">{label_task} Description</Typography>
                        <TextareaAutosize
                          onChange={(e) => setTaskDesription(e.target.value)}
                          maxRows={15}
                          value={taskDescription}
                          aria-label="maximum height"
                          placeholder="Enter description here"
                          defaultValue=""
                          style={{ width: 420, height: 88 }}
                        />
                      </Stack>
                    </Stack>
                    <Stack direction="row" spacing={6}>
                      <Stack>
                        <Stack spacing={1} direction="row">
                          <EmptyMileStone />
                          <Typography variant="subtitle2">Its a milestone</Typography>
                        </Stack>
                        <Stack>
                          <Switch
                            checked={mileStone}
                            onChange={() => {
                              setMileStone(!mileStone);
                              // if it is a milestone task, then set csat indicator onn
                              if (!mileStone) {
                                setCsatIndicator(true);
                              }
                            }}
                          ></Switch>
                        </Stack>
                      </Stack>
                      {isTeamTask && (
                        <>
                          <Stack>
                            <Stack spacing={1} direction="row">
                              <Typography variant="subtitle2">Share with {label_client.toLowerCase()}</Typography>
                            </Stack>
                            <Stack>
                              <Switch
                                checked={shareWithClient}
                                onChange={() => setShareWithClient(!shareWithClient)}
                              ></Switch>
                            </Stack>
                          </Stack>
                          <Stack>
                            <Stack spacing={1} direction="row">
                              <Typography variant="subtitle2">CSAT</Typography>
                            </Stack>
                            <Stack>
                              <Switch
                                disabled={isCsatDisabled}
                                checked={csatIndicator}
                                onChange={() => setCsatIndicator(!csatIndicator)}
                              ></Switch>
                            </Stack>
                          </Stack>
                        </>
                      )}
                      {customerDetail?.customer_jira_api_url && isTeamTask && (
                        <Stack>
                          <Stack direction="row" className={'cognisaas-pointer'}>
                            <Typography style={{ marginLeft: '8px' }} variant="subtitle2">
                              Push to Jira
                            </Typography>
                          </Stack>
                          <Stack>
                            <Switch
                              checked={pushToJira}
                              onChange={() => {
                                setPushToJira(!pushToJira);
                                makeUiReadyForJira();
                              }}
                            />
                          </Stack>
                        </Stack>
                      )}
                    </Stack>
                    <Stack justifyContent="flex-end" direction="row">
                      <Stack mb={2} spacing={1} direction="row">
                        <CognisaasButton
                          clickHandler={() => {
                            onClose();
                            resetValues();
                          }}
                          isOutlined={true}
                          label="Cancel"
                        />

                        {isSavingInProgress ? (
                          <LoadingButton loading loadingPosition="start" startIcon={<SaveIcon />} variant="outlined">
                            Save
                          </LoadingButton>
                        ) : (
                          <CognisaasButton
                            disabled={
                              drawerType && drawerType === 'standalone'
                                ? !isPrerequisiteForStandaloneTaskIsValidated || disableSave
                                : disableSave
                            }
                            clickHandler={() => {
                              props.handleSubmit();
                              // closeDrawer();
                            }}
                            // clickHandler={saveTask}
                            isOutlined={false}
                            label="Save"
                          />
                        )}
                      </Stack>
                    </Stack>
                  </Stack>
                </Grid>
                {jiraCustomFieldsModal && !isJSDenabled && (
                  <BasicModal
                    height={jiraCustomfields.length > 2 ? 400 : 250}
                    open={true}
                    onClose={() => {
                      setJiraCustomFieldsModal(false);
                      setIsSavingInProgress(false);
                    }}
                  >
                    <Stack>
                      <JiraCustomFields
                        onClose={() => {
                          setJiraCustomFieldsModal(false);
                          setIsSavingInProgress(false);
                        }}
                        taskDetail={taskDetailToBeAdded}
                        fields={jiraCustomfields}
                        handleCustomFieldsSave={handleCustomMandatoryFieldsSave}
                      ></JiraCustomFields>
                    </Stack>
                  </BasicModal>
                )}
                {jsdCustomFieldsModal && (
                  <BasicModal
                    height={jiraCustomfields.length > 2 ? 400 : 250}
                    open={true}
                    onClose={() => {
                      setJsdCustomFieldsModal(false);
                      setIsSavingInProgress(false);
                    }}
                  >
                    <Stack>
                      <JiraServiceDeskCustomFields
                        onClose={() => {
                          setJsdCustomFieldsModal(false);
                          setIsSavingInProgress(false);
                        }}
                        taskDetail={taskDetailToBeAdded}
                        fields={jiraCustomfields}
                        handleCustomFieldsSave={handleCustomMandatoryFieldsOfJsd}
                      ></JiraServiceDeskCustomFields>
                    </Stack>
                  </BasicModal>
                )}
              </form>
            );
          }}
        </Formik>
        )
      </Drawer>
      <CognisaasToast
        open={alertDetails.isVisible}
        onClose={() => setAlertDetails({ isVisible: false, type: alertDetails.type, message: '' })}
        message={alertDetails.message}
        type={alertDetails.type}
      />
    </>
  );
};

export default NewTaskDrawer;
