import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    height: 70vh;
    margin-top: 20px;
`;

export const Main = styled.div`
    display:flex;
    flex-direction: column;
    width: 80%;
    border-radius: 5px;
    background-color: #fff;
    margin: 0 20px;
    overflow-y:scroll;
    overflow-x:hidden;
`;

export const Frame = styled.div`
    display:flex;
    padding: 10px;
    justify-content: space-between;
    align-items: center;
`;

export const Grid = styled.div`
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-left:10px;
    gap: 20px;
    margin-bottom:10px;
`;

export const Group = styled.div`
    display:flex;
    flex-direction: column;
`;

export const Sidebar = styled.div`
    width: 300px;
    border-radius: 5px;
    padding: 15px;
    overflow: scroll;
    overflow-x: hidden;
    background-color: #fff;
`;
