import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  Card,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  Grid,
  Checkbox,
  Typography,
  FormGroup,
  TextField,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useAppSelector } from 'store/hooks';
import { getAllResources_selector, getPolicyOnResources_selector, getRole_selector } from 'store/modules/RBAC/selector';
import {
  addPolicyToRole_slice,
  getAllResources_slice,
  getPolicyOnResources_slice,
  getRole_slice,
  removePolicyFromRole_slice,
} from 'store/modules/RBAC/slice';

import CognisaasButton from 'components/shared/CognisaasButton/CognisaasButton';
import CognisaasSpinner from 'components/shared/CognisaasSpinner/CognisaasSpinner';

import { ResourcesProps } from 'components/RBACv2/interfaces';
import { requestState } from 'store/modules/types';

export default function Resources(props: ResourcesProps) {
  const [expanded, setExpanded] = useState<string>('');
  const [projectCheckboxes, setProjectCheckboxes] = useState<any[]>([]);
  const [checkBoxes, setCheckBoxes] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');

  const dispatch = useDispatch();

  const getPolicyOnResources_fromSelector = useAppSelector(getPolicyOnResources_selector).data;
  const getPolicyResourcesStatus = useAppSelector(getPolicyOnResources_selector).status;
  const resources = useAppSelector(getAllResources_selector).data;
  const { policies } = useAppSelector(getRole_selector).data;

  useEffect(() => {
    dispatch(getAllResources_slice());
    dispatch(getRole_slice(props.selectedRole.id));
  }, []);

  useEffect(() => {
    setProjectCheckboxes([]);
    dispatch(
      getPolicyOnResources_slice({
        resource: expanded,
      })
    );
  }, [expanded]);

  useEffect(() => {
    setProjectCheckboxes(getPolicyOnResources_fromSelector);
    const selectedRolesPolicies = policies;
    const checkBoxTicksObj = getPolicyOnResources_fromSelector?.map((obj: any) => {
      const findedValue = selectedRolesPolicies?.find((o: any) => o.id === obj?.id);
      return findedValue !== undefined;
    });
    setCheckBoxes(checkBoxTicksObj);
  }, [getPolicyOnResources_fromSelector, policies]);

  useEffect(() => {
    const filterNames = getPolicyOnResources_fromSelector?.filter((rowItem: any) => {
      return rowItem?.description?.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1;
    });
    setProjectCheckboxes(filterNames);
  }, [searchValue]);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : '');
  };

  const onClickCheckBoxState = async (index: number) => {
    const tempCheckBoxex = { ...checkBoxes };
    tempCheckBoxex[index] = !checkBoxes[index];
    setCheckBoxes(tempCheckBoxex);
    const policyTobeProccessed = projectCheckboxes[index];
    const roleId = props?.selectedRole?.id;
    if (tempCheckBoxex[index]) {
      props.addPolicy(policyTobeProccessed);
      await dispatch(
        addPolicyToRole_slice({
          roleId: roleId,
          policyIds: [policyTobeProccessed.id],
        })
      );
    } else {
      props.removePolicy(policyTobeProccessed);
      await dispatch(
        removePolicyFromRole_slice({
          roleId: roleId,
          policyIds: [policyTobeProccessed.id],
        })
      );
    }
  };

  return (
    <>
      {resources.map((obj: any) => {
        return (
          <Card
            sx={{ marginTop: '10px', boxShadow: 'none', border: '1px solid #E5E5E5;', width: '600px' }}
            key={obj.id}
          >
            <Accordion expanded={expanded === obj.id} onChange={handleChange(obj.id)}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                <Typography variant={'h2'} sx={{ width: '33%', flexShrink: 0 }}>
                  {obj.display_name}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormGroup sx={{ marginLeft: '10px', marginBottom: '20px' }}>
                  <TextField
                    id="outlined-basic"
                    value={searchValue}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setSearchValue(e.target.value);
                    }}
                    placeholder="search..."
                    sx={{ height: '30px', marginBottom: '25px' }}
                    size="small"
                    variant="outlined"
                  />
                  <Grid
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      maxHeight: '200px',
                      overflowY: 'scroll',
                      padding: '0 10px',
                    }}
                  >
                    {getPolicyResourcesStatus === requestState.loading ? (
                      <CognisaasSpinner />
                    ) : (
                      projectCheckboxes?.map((obj: any, index: number) => (
                        <FormControlLabel
                          key={index}
                          control={
                            <Checkbox
                              onClick={() => onClickCheckBoxState(index)}
                              size="small"
                              checked={checkBoxes[index] || false}
                              disabled={props.selectedRole?.is_public}
                            />
                          }
                          label={
                            <Typography variant={'h3'} sx={{ marginLeft: '5px' }}>
                              {obj?.description}
                            </Typography>
                          }
                        />
                      ))
                    )}
                  </Grid>
                </FormGroup>
                {!props.selectedRole?.is_public && (
                  <CognisaasButton
                    style={{ marginLeft: '12px', marginTop: '10px' }}
                    isOutlined={true}
                    size="small"
                    // clickHandler={handleAddCustomPermission}
                    label="Add Custom Permission"
                  />
                )}
              </AccordionDetails>
            </Accordion>
          </Card>
        );
      })}
    </>
  );
}
