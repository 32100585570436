import './LabeledBadge.css';
import Typography from '@mui/material/Typography';
import CircleIcon from '@mui/icons-material/Circle';
import CognisaasCountBadge from '../CognisaasCountBadge/CognisaasCountBadge';

type LabeledBadgeProps = {
  text: string;
  badgeCount?: number;
  statusColor?: string;
  textColor?: string;
  pl?: string;
  badgeBgColor?: string;
  badgeColor?: string;
  showBorder?: boolean;
  isActive: boolean;
  onClick?: () => void;
  fontSize?: any;
  fontWeight?: any;
  hideWidth?: boolean;
};

const LabeledBadge = (props: LabeledBadgeProps) => {
  const fSize = props.fontSize ? props.fontSize + ' !important' : undefined;
  const fWeight = props.fontWeight ? props.fontWeight + ' !important' : undefined;

  return (
    <span
      onClick={props.onClick}
      className={
        (props.isActive && props.showBorder ? 'LabeledBadge__containerActiveBorder ' : '') +
        (props.showBorder ? 'LabeledBadge__containerWithDefaultBorder' : 'LabeledBadge__container')
      }
      style={{ paddingLeft: props.pl }}
    >
      {props?.statusColor ? (
        <CircleIcon
          sx={{
            color: props.statusColor,
            height: '8px',
            width: props.hideWidth == true ? null : '8px',
            marginRight: '4px',
          }}
        />
      ) : (
        ''
      )}
      <Typography
        className={(props.isActive ? 'Badge_label_font_active ' : '') + 'Badge_label_font'}
        sx={
          props.textColor
            ? { color: props.textColor, fontSize: fSize, fontWeight: fWeight }
            : { fontSize: fSize, fontWeight: fWeight }
        }
      >
        {props.text}
      </Typography>
      {props.badgeCount != null ? <CognisaasCountBadge count={props.badgeCount} isActive={props.isActive} /> : ''}
    </span>
  );
};
export default LabeledBadge;
