import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { usersAPI } from '../../../../api/modules/resources';
import { requestState } from '../../types';

interface usersType {
  users: Array<any>;
  status: requestState.idle | requestState.loading | requestState.success | requestState.failed;
  error: null | undefined | string;
}

const initialState: usersType = {
  users: [],
  status: requestState.idle,
  error: null,
} as usersType;

export const fetchAllUsers = createAsyncThunk('projects/fetchAllResources', async () => {
  const { data } = await usersAPI.getAllUsers();
  const users = data.result;
  console.log(users);
  return users;
});

const usersSlice = createSlice({
  name: 'openProjects',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchAllUsers.pending, (state) => {
        state.status = requestState.loading;
      })
      .addCase(fetchAllUsers.fulfilled, (state, action) => {
        state.status = requestState.success;
        state.users = action.payload;
      })
      .addCase(fetchAllUsers.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      });
  },
});

export const getAllUsers = (state: usersType) => state.users;
const usersReducer = usersSlice.reducer;
export default usersReducer;
