export const styles = {
    drawerPaper: {
        background: '#051240',
        color: 'white'
    },
    drawer: {
        fontFamily: 'Noto Sans'
    },
    eachTab: { 
        color: 'white',
        height: '20px',
    },
    list: {
        marginTop: '70px',
        padding: '0px'
    },
    listText: {
        display:'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0px',
        textAlign: 'center'
    },
    spanText: {
        textAlign: 'center'
    },
    tabs: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '60px',
        width: '100px',
        padding: '0px',
        marginTop: '12px'
    },
    Sidebar__activeTab: {
        background: 'linear-gradient(90deg, rgba(45, 96, 246, 0.1875) 5.37%, rgba(45, 97, 246, 0.0875) 80.5%)',
        boxShadow: 'inset 4px 0px 0px #2A55E9',
    },
    Sidebar__insideTab: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        transform: 'scale(0.7)'
    },
    Sidebar__smallerTabs: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '44px',
        width: '72px',
        padding: '0px',
        marginTop: '10px'
    },
    Sidebar__tabLinks: {
        color: '#dbe3ff',
        textDecoration: 'none'
    },
    longListText: {
        display: 'flex',
        flexDirection: 'column'
    }
};