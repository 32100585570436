import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../..';

export const selectOpenUsecasesState = (state: RootState) => state.openUsecasesState;
export const selectOpenUsecases = createSelector(
  selectOpenUsecasesState,
  (openUsecasesState) => openUsecasesState.openUsecases
);
export const selectOpenUsecasesStatus = createSelector(
  selectOpenUsecasesState,
  (openUsecasesState) => openUsecasesState.status
);
export const selectOpenUsecasesError = createSelector(
  selectOpenUsecasesState,
  (openUsecasesState) => openUsecasesState.error
);
