import { Button, Typography, Stack, TextField } from '@mui/material';
import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { updateTimeTrackingInSettings } from 'store/modules/Common/slice';
import { getCustomerDetail } from 'store/modules/ManageClient/ClientList/slice';
import { selectCustomerDetail } from '../../../store/modules/ManageClient/ClientList/selector';
import CognisaasCheckBox from 'components/shared/CognisaasCheckbox/CognisaasCheckbox';
import CognisaasToast from 'components/shared/CognisaasToast/CognisaasToast';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import Holidays from './Holidays/Holidays';

const TimeTracking = () => {
  const customer = useAppSelector(selectCustomerDetail);
  const [hoursPerDay, setHoursPerDay] = useState<any>(0);
  const [monday, setMonday] = useState<boolean>(false);
  const [tuesday, setTuesday] = useState<boolean>(false);
  const [wednesday, setWednesday] = useState<boolean>(false);
  const [thursday, setThursday] = useState<boolean>(false);
  const [friday, setFriday] = useState<boolean>(false);
  const [saturday, setSaturday] = useState<boolean>(false);
  const [sunday, setSunday] = useState<boolean>(false);
  const [updateStatus, setUpdateStatus] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getCustomerDetail());
  }, []);

  useEffect(() => {
    if (customer) {
      setHoursPerDay(customer.customer_hours_per_day == null ? 0 : customer.customer_hours_per_day);
      if (customer.customer_working_days?.length > 0) {
        const days = customer.customer_working_days.split(',');
        for (let i = 0; i < days.length; i++) {
          if (days[i] == 'monday') {
            setMonday(true);
          }
          if (days[i] == 'tuesday') {
            setTuesday(true);
          }
          if (days[i] == 'wednesday') {
            setWednesday(true);
          }
          if (days[i] == 'thursday') {
            setThursday(true);
          }
          if (days[i] == 'friday') {
            setFriday(true);
          }
          if (days[i] == 'saturday') {
            setSaturday(true);
          }
          if (days[i] == 'sunday') {
            setSunday(true);
          }
        }
      }
    }
  }, [customer]);

  const dispatch = useAppDispatch();

  const updateTimeTracking = async () => {
    const days: any = [];
    if (monday) {
      days.push('monday');
    }
    if (tuesday) {
      days.push('tuesday');
    }
    if (wednesday) {
      days.push('wednesday');
    }
    if (thursday) {
      days.push('thursday');
    }
    if (friday) {
      days.push('friday');
    }
    if (saturday) {
      days.push('saturday');
    }
    if (sunday) {
      days.push('sunday');
    }

    console.log(days, hoursPerDay);

    const daysString = days.join(',');

    console.log(days);

    const detail = {
      hours_per_day: hoursPerDay,
      working_days: days.length == 0 ? '' : daysString,
      customer_uid: customer.customer_uid,
    };

    const res = await dispatch(updateTimeTrackingInSettings(detail)).unwrap();
    console.log(res);
    if (res.status == 1) {
      setUpdateStatus(!updateStatus);
    }
  };

  return (
    <>
      {updateStatus ? (
        <CognisaasToast
          open={updateStatus}
          onClose={() => setUpdateStatus(false)}
          message={'Updated successfully! '}
          type="success"
        />
      ) : null}

      <Grid sx={{ padding: '20px' }}>
        <Typography variant={'h2'}>Time Tracking</Typography>
        <Stack direction="row" alignItems="center" sx={{ marginTop: '24px' }}>
          <Stack direction="column">
            <Typography variant={'h3'}>Working Capacity</Typography>
            <Typography variant={'subtitle2'} sx={{ color: '#7A7A7A' }} style={{ marginTop: '10px' }}>
              Define members working capacity <br />
              in number of hours per week{' '}
            </Typography>
          </Stack>
          <Stack ml={2}>
            <Typography variant="subtitle1" sx={{ color: '#7A7A7A' }}>
              Hours per day
            </Typography>
            <TextField
              type="number"
              onChange={(e: any) => setHoursPerDay(e.target.value)}
              value={hoursPerDay}
              size="small"
              style={{ width: '200px', marginTop: '8px' }}
              placeholder="0"
            ></TextField>
          </Stack>
        </Stack>
        <Grid sx={{ display: 'flex', alignItems: 'flex-end' }}>
          <Grid
            sx={{
              marginTop: '50px',
              height: '20px',
              width: '134px',
              left: '340px',
              top: '280px',
            }}
          >
            <Typography variant={'h3'}>Working Days</Typography>
          </Grid>
          <Grid xs={4}>
            <FormGroup
              sx={{ display: 'flex', padding: '0', flexDirection: 'row', width: '600px', marginLeft: '113px' }}
            >
              <FormControlLabel
                control={
                  <CognisaasCheckBox
                    onCheckBoxClick={() => setMonday(!monday)}
                    checked={monday}
                    sx={{ padding: '0' }}
                    size="small"
                  />
                }
                label={
                  <Typography sx={{ padding: '0px 5px' }} variant={'subtitle1'}>
                    Mon
                  </Typography>
                }
              />
              <FormControlLabel
                control={
                  <CognisaasCheckBox
                    onCheckBoxClick={() => setTuesday(!tuesday)}
                    checked={tuesday}
                    sx={{ padding: '0' }}
                    size="small"
                  />
                }
                label={
                  <Typography sx={{ padding: '0px 5px' }} variant={'subtitle1'}>
                    Tue
                  </Typography>
                }
              />
              <FormControlLabel
                control={
                  <CognisaasCheckBox
                    onCheckBoxClick={() => setWednesday(!wednesday)}
                    checked={wednesday}
                    sx={{ padding: '0' }}
                    size="small"
                  />
                }
                label={
                  <Typography sx={{ padding: '0px 5px' }} variant={'subtitle1'}>
                    Wed
                  </Typography>
                }
              />
              <FormControlLabel
                control={
                  <CognisaasCheckBox
                    onCheckBoxClick={() => setThursday(!thursday)}
                    checked={thursday}
                    sx={{ padding: '0' }}
                    size="small"
                  />
                }
                label={
                  <Typography sx={{ padding: '0px 5px' }} variant={'subtitle1'}>
                    Thu
                  </Typography>
                }
              />
              <FormControlLabel
                control={
                  <CognisaasCheckBox
                    onCheckBoxClick={() => setFriday(!friday)}
                    checked={friday}
                    sx={{ padding: '0' }}
                    size="small"
                  />
                }
                label={
                  <Typography sx={{ padding: '0px 5px' }} variant={'subtitle1'}>
                    Fri
                  </Typography>
                }
              />
              <FormControlLabel
                control={
                  <CognisaasCheckBox
                    onCheckBoxClick={() => setSaturday(!saturday)}
                    checked={saturday}
                    sx={{ padding: '0' }}
                    size="small"
                  />
                }
                label={
                  <Typography sx={{ padding: '0px 5px' }} variant={'subtitle1'}>
                    Sat
                  </Typography>
                }
              />
              <FormControlLabel
                control={
                  <CognisaasCheckBox
                    onCheckBoxClick={() => setSunday(!sunday)}
                    checked={sunday}
                    sx={{ padding: '0' }}
                    size="small"
                  />
                }
                label={
                  <Typography sx={{ padding: '0px 5px' }} variant={'subtitle1'}>
                    Sun
                  </Typography>
                }
              />
            </FormGroup>
          </Grid>
        </Grid>
        <Grid>
          <Holidays customerDetail={customer} />
        </Grid>
        <Grid sx={{ marginTop: '50px' }}>
          <Button onClick={updateTimeTracking} size="small" variant="contained">
            Submit
          </Button>
          <Button size="small" variant="outlined" sx={{ marginLeft: '20px' }}>
            Cancel
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default TimeTracking;
