import { useState, useEffect } from 'react';

import { Grid, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getAllRoles_selector } from 'store/modules/RBAC/selector';
import { getAllRoles_slice } from 'store/modules/RBAC/slice';
import ListTableHead from 'components/RBACv2/shared/ListTableHead';
import { stableSort } from 'components/RBACv2/utils/filters';
import RolesCard from './RolesCard';
import { SortingOrder } from '../../types';
import { requestState } from 'store/modules/types';
import RolesDetail from './RolesDetail/RolesDetail';
import CognisaasSpinner from 'components/shared/CognisaasSpinner/CognisaasSpinner';

const Roles = () => {
  const [order, setOrder] = useState<SortingOrder>('asc');
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [selectedRole, setSelectedRole] = useState<any>({});
  const [searchValue, setSearchValue] = useState('');
  const [filteredDataBy_UniqueRows, setFilteredDataBy_UniqueRows] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [drawerState, setDrawerState] = useState<boolean>(false);

  const allRolesData_fromSelector = useAppSelector(getAllRoles_selector).data;
  const fetchAllRolesDataStatus = useAppSelector(getAllRoles_selector).status;

  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const data = await dispatch(getAllRoles_slice()).unwrap();
      if (data?.length > 0) {
        setFilteredData([...data]);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    // Passing newer(fresh) data to children if any changes are made in children component in this selectedRole
    if (allRolesData_fromSelector && allRolesData_fromSelector.length > 0) {
      if (selectedRole) {
        const newRole = allRolesData_fromSelector.find((role) => selectedRole.id === role.id);
        setSelectedRole(newRole);
      }
      setFilteredData([...allRolesData_fromSelector]);
    }
  }, [allRolesData_fromSelector]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await dispatch(getAllRoles_slice()).unwrap();
      if (data?.length > 0) {
        setSelectedRole(data[0]);
        setFilteredData([...data]);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const filterNames = allRolesData_fromSelector?.filter((rowItem: any) => {
      return rowItem?.name?.toLowerCase().indexOf(searchValue?.toLowerCase()) !== -1;
    });
    const SortedFilterNames = filterNames.sort((a, b) => (a.name > b.name ? 1 : -1));
    setFilteredDataBy_UniqueRows(SortedFilterNames);
    setFilteredData(SortedFilterNames);
    setSelected([]);
  }, [searchValue]);
  return (
    <Grid sx={{ display: 'flex', width: '100%' }}>
      <Paper
        sx={{
          height: '500px',
          margin: '15px 15px 0px 30px',
          flexGrow: 1,
          overflowY: 'hidden',
          boxShadow: '0px 4px 26px rgba(0, 0, 0, 0.05)',
        }}
      >
        {fetchAllRolesDataStatus === requestState.loading ? (
          <Stack justifyContent="center" alignItems="center" height="100%">
            <CognisaasSpinner size={50} thickness={3} />
          </Stack>
        ) : (
          <TableContainer sx={{ maxHeight: '100%' }}>
            <Table stickyHeader={true} aria-labelledby="tableTitle" size={'small'}>
              <ListTableHead
                numSelected={selected.length}
                order={order}
                setOrder={setOrder}
                rowCount={filteredDataBy_UniqueRows.length}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                label={'Roles'}
              />
              <TableBody>
                {stableSort(filteredData, order).map((row: any, index: number) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      sx={{ cursor: 'pointer' }}
                      onClick={() => {
                        setSelectedRole(row);
                        setDrawerState(true);
                      }}
                      tabIndex={-1}
                      key={index}
                    >
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                        <RolesCard
                          backgroundColor={row?.id === selectedRole?.id ? '#2D5FF627' : '#ffffff'}
                          title={`${row.name}`}
                          global={row.is_public ? true : false}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>
      <RolesDetail drawerState={drawerState} onDrawerClose={() => setDrawerState(false)} selectedRole={selectedRole} />
    </Grid>
  );
};

export default Roles;
