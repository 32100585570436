import React from 'react';
import { EventOption } from 'components/ResourceManagement/types/public-types';
import { BarComponent } from 'components/ResourceManagement/types/bar-component';
import { Bar } from 'components/ResourceManagement/components/bar';
import {
  GanttContentMoveAction,
  GanttEvent,
} from 'components/ResourceManagement/types/gantt-component-actions';


import { ComponentSoftAllocation } from './component-soft-allocation';

export type ComponentGanttContentProps = {
  primeComponents: BarComponent[];
  dates: Date[];
  ganttEvent: GanttEvent;
  selectedComponent: BarComponent | undefined;
  rowHeight: number;
  columnWidth: number;
  svg?: React.RefObject<SVGSVGElement>;
  svgWidth: number;
  componentHeight: number;
  mode: string;
  setGanttEvent: (value: GanttEvent) => void;
  setSelectedComponent: (componentId: string) => void;
} & EventOption;

export const ComponentGanttContent: React.FC<ComponentGanttContentProps> = ({
  primeComponents,
  ganttEvent,
  selectedComponent,
  componentHeight,
  columnWidth,
  rowHeight,
  mode,
  setGanttEvent,
  setSelectedComponent,
}) => {
  /**
   * Method is Start point of component change
   */
  const handleBarEventStart = async (
    action: GanttContentMoveAction,
    component: BarComponent,
    event?: React.MouseEvent | React.KeyboardEvent
  ) => {
    if (!event) {
      if (action === 'select') {
        setSelectedComponent(component.id);
      }
    }
    // Mouse Events
    else if (action === 'mouseenter') {
      if (!ganttEvent.action) {
        setGanttEvent({
          action,
          changedComponent: component,
          originalSelectedComponent: component,
        });
      }
    } else if (action === 'mouseleave') {
      if (ganttEvent.action === 'mouseenter') {
        setGanttEvent({ action: '' });
      }
    }
    else {
      setGanttEvent({
        action,
        changedComponent: component,
        originalSelectedComponent: component,
      });
    }
  };

  const barCornerRadius = 2.5;
  const softAllocationProps = {
    primeComponents,
    barCornerRadius,
    columnWidth,
    rowHeight,
    componentHeight,
    mode,
    selectedComponent,
    onEventStart: handleBarEventStart,
  };

  return (
    <g className='content' fill='#fff'>
      <g className='bar'>
        {primeComponents.map(component => {
          return (
            <Bar
              key={component.id}
              component={component}
              barCornerRadius={barCornerRadius}
              componentHeight={componentHeight}
              onEventStart={handleBarEventStart}
              isSelected={!!selectedComponent && component.id === selectedComponent.id}
              mode={mode}
            />
          );
        })}
        <ComponentSoftAllocation {...softAllocationProps} />
      </g>
    </g>
  );
};
