import { getAllTasksOfAUseCase, arrangeTasksBasedOnPriority } from '../../utils';
import { Stack, Grid, Typography, Avatar } from '@mui/material';
import CognisaasTaskStageIndicator from '../../../shared/CognisaasTaskStageIndicator/CognisaasTaskStageIndicator';
// import { taskStage } from '../../../shared/CognisaasTaskStageIndicator/CognisaasTaskStageIndicator';
import { getUserNameByUserUid } from '../../../../utils/getUid';
import { label_due_date, label_task } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { useAppDispatch } from 'store/hooks';
import { getTaskByTaskUid } from 'store/modules/Task/slice';
import { useState } from 'react';
import UpdateTaskDrawer from 'components/TaskModule/TaskView/UpdateTaskDrawer';
import ViewDate from 'components/shared/ViewDate/ViewDate';
import { getUserActiveState, INACTIVE_STRING, INACTIVE_USER_COLOR } from 'utils/userHelpers';
import TypographyWithTooltip from 'components/shared/UI/TypographyWithTooltip';
import useUserDropDownData from 'components/shared/Hooks/useUserDropDownData';

const UsecasesCardContent = (props: any) => {
  const dispatch = useAppDispatch();
  const [updateTaskDrawer, setUpdateTaskDrawer] = useState<boolean>(false);
  const [taskForDrawer, setTaskForDrawer] = useState<any>(null);
  const [client, setClient] = useState<any>(null);
  const companyUsersDropDownData = useUserDropDownData('company');

  const allTasksUnderUsecase = getAllTasksOfAUseCase(props.usecase);

  function stringToHslColor(str: string, s: number, l: number) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    const h = hash % 360;
    return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
  }

  function stringAvatar(name: string) {
    return {
      sx: {
        bgcolor: stringToHslColor(name, 30, 80),
      },
      children: (
        <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#000000' }}>
          {name.split(' ')[0][0]}
          {name.indexOf(' ') >= 0 ? name.split(' ')[1][0] : null}
        </Typography>
      ),
    };
  }

  const openTaskDrawer = async (task: any) => {
    const taskResult = await dispatch(getTaskByTaskUid(task.task_uid)).unwrap();
    setClient({ client_uid: taskResult.client_uid });
    setTaskForDrawer({ ...taskResult });
    setUpdateTaskDrawer(!updateTaskDrawer);
  };

  let tasksArrangedByPriority = arrangeTasksBasedOnPriority(allTasksUnderUsecase);
  tasksArrangedByPriority = tasksArrangedByPriority.slice(0, 3);
  return (
    <>
      {tasksArrangedByPriority.map((task: any, index: number) => {
        return (
          <Stack
            sx={{ paddingTop: '16px', marginTop: '8px' }}
            direction="row"
            key={index}
            // justifyContent="flex-start"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            // spacing={3}
          >
            <Grid container spacing={1}>
              <Grid item md={5}>
                <Stack ml={1} direction="row">
                  <CognisaasTaskStageIndicator isMileStoneTask={task.milestone_task != '0'} stage={task.task_stage} />
                  <Stack mx={1} direction="column">
                    <Typography
                      style={{
                        color: '#2D60F6',
                        marginTop: '-3px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        width: '190px',
                      }}
                      className="cognisaas-pointer"
                      onClick={() => openTaskDrawer(task)}
                      variant="subtitle1"
                    >
                      {task.task_name}
                    </Typography>
                    <Typography variant="subtitle2">
                      {/* 10 Days{' '} */}
                      {/* {task?.project_uid ? (
            <span className="label-text">Task Stage (Project Task)</span>
          ) : (
            <span className="label-text">Task Stage (Use Case Task)</span>
          )} */}
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item md={2}>
                <Stack direction="column">
                  <Typography variant="subtitle1">{label_due_date}</Typography>
                  <Typography variant="subtitle2">
                    {/* 10 Days{' '} */}
                    {/* <span className="label-text">12 Mar 2022</span> */}
                    <span className="label-text">
                      <ViewDate date={task?.task_due_date} />
                    </span>
                  </Typography>
                </Stack>
              </Grid>
              <Grid ml={6} item md={4}>
                <Stack direction="row">
                  <Avatar
                    style={{ height: '34px', width: '34px', marginTop: '3px' }}
                    {...stringAvatar(getUserNameByUserUid(task.task_owner))}
                  />

                  {/* <Avatar sx={{ color: 'black', bgcolor: '#9EF3F380', display: 'inline-flex' }}>t</Avatar> */}
                  <Stack direction="column">
                    <Typography sx={{ paddingLeft: '4px' }} display={'inline'} variant="subtitle2">
                      {label_task} Owner
                    </Typography>
                    {getUserActiveState(task.task_owner, companyUsersDropDownData) === '0' ? (
                      <TypographyWithTooltip
                        style={{ color: INACTIVE_USER_COLOR, paddingLeft: '6px' }}
                        maxLength={13}
                        variant={'subtitle1'}
                      >
                        {`${getUserNameByUserUid(task.task_owner)} (${INACTIVE_STRING})`}
                      </TypographyWithTooltip>
                    ) : (
                      <TypographyWithTooltip maxLength={13} sx={{ paddingLeft: '6px' }} variant={'subtitle1'}>
                        {getUserNameByUserUid(task.task_owner)}
                      </TypographyWithTooltip>
                    )}
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        );
      })}
      {updateTaskDrawer && (
        <UpdateTaskDrawer
          client={client}
          taskDetail={taskForDrawer}
          drawerState={updateTaskDrawer}
          onClose={async () => {
            setUpdateTaskDrawer(!updateTaskDrawer);
          }}
        />
      )}
    </>
  );
};

export default UsecasesCardContent;
