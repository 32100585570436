import { Card, Grid, Typography } from '@mui/material';
import { PermissionsCardProps } from 'components/RBACv2/interfaces';

export default function PermissionsCard(props: PermissionsCardProps) {
  return (
    <Card
      sx={{
        margin: '10px',
        backgroundColor: props?.backgroundColor,
        padding: '18px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        boxShadow: 'none',
        border: '1px solid #E5E5E5;',
      }}
    >
      <Grid sx={{ display: 'flex', backgroundColor: 'grey', alignItems: 'center' }}>
        <Typography variant="h2">{props?.permissionType}</Typography>
        {props.cardType === 'Policy' ? (
          <Grid
            sx={{
              padding: '3px 7px',
              marginLeft: '10px',
              backgroundColor: 'white',
              border: '1px #00c462 solid',
              borderRadius: '5px',
            }}
          >
            <Typography variant="h3" color="#00c462">
              {props?.boxValue}
            </Typography>
          </Grid>
        ) : null}
      </Grid>
      <Grid>
        <Typography variant="subtitle1" className="label-text">
          {props?.isPublic ? '(Global)' : null}
        </Typography>
      </Grid>
    </Card>
  );
}
