import { Box, FormControl, TextField, Typography } from '@mui/material';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectTasksOfUser } from '../../../store/modules/Task/selector';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useEffect } from 'react';
import { IconButton, Stack, Grid, Avatar } from '@mui/material';
import { getCustomerUid } from '../../../utils/getUid';
import { add, differenceInDays, format, parseISO } from 'date-fns';
import { ReactComponent as NoRecords } from '../../../assets/svgs/no_records.svg';
import CognisaasTaskStageIndicator from '../../shared/CognisaasTaskStageIndicator/CognisaasTaskStageIndicator';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CognisaaasTooltip from '../../shared/CognisaasTooltip/CognisaasTooltip';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { getTaskByTaskUid, getTasksOfUser } from '../../../store/modules/Task/slice';
import { getUserNameByUserUid, getLoggedInUserUid } from '../../../utils/getUid';

import { setUpFiltersForClientListPage } from '../../../store/modules/ui/Filters/slice';
import { label_project, label_task } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { selectAllTeamTasks } from 'store/modules/Dashboard/selector';
import { getAllUsers } from '../../../store/modules/Common/slice';
import UpdateTaskDrawer from 'components/TaskModule/TaskView/UpdateTaskDrawer';

function stringToHslColor(str: string, s: number, l: number) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const h = hash % 360;
  return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToHslColor(name, 30, 80),
    },
    children: (
      <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#000000' }}>
        {name.split(' ')[0][0]}
        {name.indexOf(' ') >= 0 ? name.split(' ')[1][0] : null}
      </Typography>
    ),
  };
}

const DeliverablesTimeline = ({ valueForTeamDashboard }: any) => {
  const dispatch = useAppDispatch();
  const [selectedFilter, setSelectedFilter] = React.useState('This Week');
  const [filteredTasks, setFilteredTasks] = React.useState([]);
  const [value, setValue] = React.useState<any>([new Date(), new Date()]);
  const [noOfDaysBetween, setNoOfDaysBetween] = React.useState(0);
  const [showDatePicker, setShowDatePicker] = React.useState(false);
  const [updateTaskDrawer, setUpdateTaskDrawer] = React.useState<boolean>(false);
  const [taskForDrawer, setTaskForDrawer] = React.useState<any>(null);

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedFilter(event.target.value as string);
  };

  const selectedDate = new Date();

  const allTasks = useAppSelector(selectTasksOfUser);
  const allTeamTasks = useAppSelector(selectAllTeamTasks);

  useEffect(() => {
    dispatch(getAllUsers());
    dispatch(setUpFiltersForClientListPage());
  }, []);

  useEffect(() => {
    filterTasks();
  }, [allTasks, allTeamTasks, valueForTeamDashboard]);

  useEffect(() => {
    filterTasks();
  }, [value, noOfDaysBetween]);

  useEffect(() => {
    let durationBetweenDates = 0;

    durationBetweenDates = differenceInDays(value[1], value[0]);
    setNoOfDaysBetween(durationBetweenDates);
  }, [value]);

  useEffect(() => {
    filterTasks();
    if (selectedFilter == 'Select Period') setShowDatePicker(true);
    else setShowDatePicker(false);
  }, [selectedFilter]);

  useEffect(() => {
    const details = {
      user_uid: getLoggedInUserUid(),
      customer_uid: getCustomerUid(),
    };
    dispatch(getTasksOfUser(details));
  }, []);

  const countMileStoneTask = (tasks: any) => {
    let count = 0;
    tasks.forEach((task: any) => {
      if (task.milestone_task == 1) {
        count++;
      }
    });
    return count;
  };

  const openTaskDrawer = async (task: any) => {
    const taskResult = await dispatch(getTaskByTaskUid(task.task_uid)).unwrap();
    setTaskForDrawer({ ...task, client_uid: taskResult.client_uid });
    setUpdateTaskDrawer(!updateTaskDrawer);
  };

  const filterTasks = () => {
    if (valueForTeamDashboard == 0) {
      if (allTasks && allTasks.length > 0) {
        if (selectedFilter == 'This Week') {
          const tempArr: any = [];
          for (let i = 0; i < 7; i++) {
            // console.log(i);
            allTasks.map((task: any) => {
              if (
                format(new Date(task.task_due_date.replace(/-/g, '/')), 'dd MMM yyyy') ==
                format(add(new Date(), { days: i }), 'dd MMM yyyy')
              ) {
                tempArr.push(task);
              }
              // return (
              //   format(new Date(task.task_due_date.split(' ')), 'dd MMM yyyy') ==
              //   format(add(new Date(), { days: i }), 'dd MMM yyyy')
              // );
            });
          }
          setFilteredTasks(tempArr);
        }
        if (selectedFilter == 'This Month') {
          const tempArr: any = [];
          for (let i = 0; i < 30; i++) {
            allTasks.map((task: any) => {
              if (
                format(parseISO(task.task_due_date), 'dd MMM yyyy') ==
                format(add(new Date(), { days: i }), 'dd MMM yyyy')
              ) {
                tempArr.push(task);
              }
              // return (
              //   format(parseISO(task.task_due_date), 'dd MMM yyyy') == format(add(new Date(), { days: i }), 'dd MMM yyyy')
              // );
            });
          }
          setFilteredTasks(tempArr);
        }
        if (selectedFilter == 'Next Month') {
          const tempArr: any = [];
          for (let i = 29; i < 60; i++) {
            // console.log(i);
            allTasks.map((task: any) => {
              if (
                format(parseISO(task.task_due_date), 'dd MMM yyyy') ==
                format(add(new Date(), { days: i }), 'dd MMM yyyy')
              ) {
                tempArr.push(task);
              }
            });
          }
          setFilteredTasks(tempArr);
        }
        if (selectedFilter == 'Select Period') {
          const tempArr: any = [];

          for (let i = 0; i < noOfDaysBetween; i++) {
            allTasks.map((task: any) => {
              if (
                format(parseISO(task.task_due_date), 'dd MMM yyyy') == format(add(value[0], { days: i }), 'dd MMM yyyy')
              ) {
                tempArr.push(task);
              }
              // return (
              //   format(parseISO(task.task_due_date), 'dd MMM yyyy') == format(add(new Date(), { days: i }), 'dd MMM yyyy')
              // );
            });
          }
          setFilteredTasks(tempArr);
        }
      }
    }

    if (valueForTeamDashboard == 1) {
      if (allTeamTasks && allTeamTasks.length > 0) {
        if (selectedFilter == 'This Week') {
          const tempArr: any = [];
          for (let i = 0; i < 7; i++) {
            // console.log(i);
            allTeamTasks.map((task: any) => {
              if (
                format(new Date(task.task_due_date.replace(/-/g, '/')), 'dd MMM yyyy') ==
                format(add(new Date(), { days: i }), 'dd MMM yyyy')
              ) {
                tempArr.push(task);
              }
              // return (
              //   format(new Date(task.task_due_date.split(' ')), 'dd MMM yyyy') ==
              //   format(add(new Date(), { days: i }), 'dd MMM yyyy')
              // );
            });
          }
          setFilteredTasks(tempArr);
        }
        if (selectedFilter == 'This Month') {
          const tempArr: any = [];
          for (let i = 0; i < 30; i++) {
            allTeamTasks.map((task: any) => {
              if (
                format(parseISO(task.task_due_date), 'dd MMM yyyy') ==
                format(add(new Date(), { days: i }), 'dd MMM yyyy')
              ) {
                tempArr.push(task);
              }
              // return (
              //   format(parseISO(task.task_due_date), 'dd MMM yyyy') == format(add(new Date(), { days: i }), 'dd MMM yyyy')
              // );
            });
          }
          setFilteredTasks(tempArr);
        }
        if (selectedFilter == 'Next Month') {
          const tempArr: any = [];
          for (let i = 29; i < 60; i++) {
            // console.log(i);
            allTeamTasks.map((task: any) => {
              if (
                format(parseISO(task.task_due_date), 'dd MMM yyyy') ==
                format(add(new Date(), { days: i }), 'dd MMM yyyy')
              ) {
                tempArr.push(task);
              }
              // return (
              //   format(parseISO(task.task_due_date), 'dd MMM yyyy') == format(add(new Date(), { days: i }), 'dd MMM yyyy')
              // );
            });
          }
          setFilteredTasks(tempArr);
        }
        if (selectedFilter == 'Select Period') {
          const tempArr: any = [];

          for (let i = 0; i < noOfDaysBetween; i++) {
            allTeamTasks.map((task: any) => {
              if (
                format(parseISO(task.task_due_date), 'dd MMM yyyy') == format(add(value[0], { days: i }), 'dd MMM yyyy')
              ) {
                tempArr.push(task);
              }
              // return (
              //   format(parseISO(task.task_due_date), 'dd MMM yyyy') == format(add(new Date(), { days: i }), 'dd MMM yyyy')
              // );
            });
          }
          setFilteredTasks(tempArr);
        }
      }
    }
  };

  const showTodayAndTomorrowsDeliverables = () => {
    if (filteredTasks && countMileStoneTask(filteredTasks) == 0) {
      return (
        <>
          <Typography variant="subtitle1" alignSelf="center">
            No Deliverables in this time period
            {/* {selectedFilter == 'This Week'
              ? ' next 7 days'
              : selectedFilter == 'This Month'
              ? ' next 30 days'
              : selectedFilter == 'Next Month'
              ? ' later than 30 days'
              : selectedFilter == 'Select Period'
              ? ' this selected period'
              : ' selected filter'} */}
          </Typography>
          <Stack justifyContent="center" alignItems="center">
            <NoRecords style={{ marginLeft: '6px', marginTop: '10px', width: '150px', height: '150px' }}></NoRecords>
          </Stack>
        </>
      );
    }
    return (
      <>
        <Stack direction="row" spacing={2}>
          <Typography variant="h3" sx={{ marginTop: '3px' }}>
            Today
          </Typography>
          <Typography className="label-text" variant="subtitle1">
            {format(new Date(selectedDate), 'dd MMM yyyy')}
          </Typography>
        </Stack>
        {filteredTasks &&
          filteredTasks.map((task: any, index: number) => {
            if (task.milestone_task == 1)
              return (
                <div key={index}>
                  <Stack justifyContent="flex-start" spacing={2} direction="row" sx={{ marginTop: '8px' }}>
                    <Grid container justifyContent="space-between">
                      <Grid item xs={2}>
                        <Box sx={{ marginTop: '5px' }}>
                          <CognisaasTaskStageIndicator
                            isMileStoneTask={task.milestone_task != '0'}
                            stage={task.task_stage}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={8}>
                        <Stack direction="column">
                          <Typography variant="subtitle1">{task.task_name}</Typography>
                          <Typography className="label-text" variant="subtitle1">
                            {task.task_stage}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={2}>
                        {
                          !updateTaskDrawer && (
                            <CognisaaasTooltip
                          title={
                            <Box sx={{  padding: '12px' }}>
                              {task.case_uid === null ? (
                                <Typography className="label-text" variant="subtitle1">
                                  {label_project}
                                </Typography>
                              ) : (
                                <Typography className="label-text" variant="subtitle1">
                                  Use Case
                                </Typography>
                              )}
                              <Typography
                                className="cognisaas-pointer"
                                onClick={() => openTaskDrawer(task)}
                                sx={{ fontSize: '14px', color: '#2D60F6', mt: '0px' }}
                              >
                                {task.task_name}
                              </Typography>
                              <Stack direction="row" sx={{ marginTop: '12px' }} spacing={2}>
                                <Typography variant="h3" sx={{ marginTop: '3px' }}>
                                  {label_task} Due On
                                </Typography>
                                <Typography className="label-text" variant="subtitle1">
                                  {format(new Date(task.task_due_date.replace(/-/g, '/')), 'dd MMM yyyy')}
                                </Typography>
                              </Stack>
                              <Stack direction="row">
                                <Avatar
                                  style={{ height: '30px', width: '30px', marginTop: '12px' }}
                                  {...stringAvatar(getUserNameByUserUid(task.task_owner))}
                                />
                                <Box sx={{ mt: '12px', ml: '7px' }}>
                                  <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#7A7A7A' }}>
                                    {label_task} Owner
                                  </Typography>
                                  <Typography sx={{ fontSize: '12px', fontWeight: '400' }}>
                                    {getUserNameByUserUid(task.task_owner)}
                                  </Typography>
                                </Box>
                              </Stack>
                            </Box>
                          }
                        >
                          <IconButton disableRipple size="small">
                            <InfoOutlinedIcon />
                          </IconButton>
                        </CognisaaasTooltip>
                          )
                        }
                        
                      </Grid>
                    </Grid>

                    {/* <Typography className="label-text" variant="subtitle1">
                  {format(new Date(selectedDate), 'dd MMM yyyy')}
                </Typography> */}

                    {/* <CognisaasTaskStageIndicator isMileStoneTask={true} stage={taskStage.IN_PROGRESS} /> */}
                  </Stack>
                </div>
              );
          })}

        {/* <div>
          <Typography sx={{ paddingLeft: '14px', marginLeft: '16px' }} className="label-text" variant="subtitle1">
            17 mar 2022
          </Typography>
        </div> */}
      </>
    );
  };

  return (
    <Box sx={{ minHeight: '592px', maxHeight: '592px' }}>
      <Typography sx={{ fontSize: '18px', fontWeight: '500' }}>Deliverables Timeline</Typography>
      <Typography sx={{ fontSize: '12px', fontWeight: '500', color: '#7A7A7A' }}>Milestones</Typography>
      {/* <Chip label={'Timeline '} />   */}
      <Stack
        direction="row"
        justifyContent="center"
        style={{ backgroundColor: 'rgba(45, 96, 246,0.1)', borderRadius: '15px', marginTop: '10px' }}
      >
        <Typography sx={{ ml: '8px', fontSize: '12px', fontWeight: '400', color: '#7A7A7A', mt: '5px' }}>
          Timeline:
        </Typography>
        <FormControl variant="standard">
          <Select
            // id="demo-simple-select"
            value={selectedFilter}
            onChange={handleChange}
            size="small"
            disableUnderline
            sx={{ ml: '10px' }}
          >
            <MenuItem value={'This Week'}>Next 7 days</MenuItem>
            <MenuItem value={'This Month'}>Next 30 days</MenuItem>
            <MenuItem value={'Next Month'}>Later than 30 days</MenuItem>
            <MenuItem value={'Select Period'}>
              <Stack direction="row">
                <Typography>Select Period</Typography>
              </Stack>
            </MenuItem>
          </Select>
        </FormControl>
      </Stack>
      {showDatePicker ? (
        <Box style={{ marginTop: '15px' }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateRangePicker
              calendars={1}
              value={value}
              onChange={(newValue) => {
                const tempArr = [newValue[0], newValue[1]];
                setValue(tempArr);
              }}
              renderInput={(startProps, endProps) => (
                <React.Fragment>
                  <TextField size="small" sx={{ width: '138px', padding: '5px' }} {...startProps} />
                  <Typography sx={{ fontSize: '12px', fontWeight: '500', color: '#7A7A7A' }}> to </Typography>
                  <TextField size="small" sx={{ width: '138px', padding: '5px' }} {...endProps} />
                </React.Fragment>
              )}
            />
          </LocalizationProvider>
        </Box>
      ) : null}

      <Stack sx={{ marginTop: '10px', height: '450px', overflowY: 'scroll' }}>
        {showTodayAndTomorrowsDeliverables()}
      </Stack>
      {updateTaskDrawer && (
        <UpdateTaskDrawer
          client={{ client_uid: taskForDrawer.client_uid }}
          taskDetail={taskForDrawer}
          drawerState={updateTaskDrawer}
          onClose={() => setUpdateTaskDrawer(!updateTaskDrawer)}
        />
      )}
    </Box>
  );
};

export default DeliverablesTimeline;
