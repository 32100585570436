export const colors = {
  additional_1: '#051240',
  additional_2: '#093BCD',
  additional_3: '#2D60F6',
  additional_4: '#F5F5F7',
  bragLite_1: '#9BC5F2',
  bragLite_2: '#F1AFAF',
  bragLite_3: '#F9D1A2',
  bragLite_4: '#BDF2BF',
  brag_1: '#4390E1',
  brag_2: '#E75B5C',
  brag_3: '#EFA958',
  brag_4: '#4F9F52',
  brand_1: '#3D38BA',
  brand_gradient_1: '#3D38BA1A',
  brand_2: '#FFA100',
  grey_1: '#000000',
  grey_2: '#7A7A7A',
  grey_3: '#E5E5E5',
  grey_4: '#F3F3F3',
  grey_5: '#FFFFFF',
  pastel_1: '#F9CFCF',
  pastel_2: '#F9E8CF',
  pastel_3: '#E4F9CF',
  pastel_4: '#CFF6F9',
  pastel_5: '#CFE5F9',
  pastel_6: '#D0CFF9',
  pastel_7: '#F6CFF9',
  gradient_1: 'linear-gradient(90deg, rgba(45, 96, 246, 0.1875) 5.37%, rgba(45, 97, 246, 0.0875) 80.5%);',
};

export const size = {
  _0: 0,
  _2: '0.125rem',
  _4: '0.25rem',
  _6: '0.375rem',
  _8: '0.5rem',
  _10: '0.625rem',
  _12: '0.75rem',
  _14: '0.875rem',
  _16: '1rem',
  _18: '1.125rem',
  _20: '1.25rem',
  _22: '1.375rem',
  _24: '1.5rem',
  _26: '1.625rem',
  _28: '1.75rem',
  _30: '1.875rem',
  _32: '2rem',
  _34: '2.125rem',
  _36: '2.25rem',
  _38: '2.375rem',
  _40: '2.5rem',
  _48: '3rem',
  _56: '3.5rem',
  _64: '4rem',
};

export const font = {
  weight: {
    _0: 0,
    _1: 400,
    _2: 500,
    _3: 600,
  },
};
