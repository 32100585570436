import { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Avatar, Typography, Stack } from '@mui/material';
import formatDistance from 'date-fns/formatDistance';

import CognissasCustomMenu from '../../../shared/CognisaasCustomMenu/CognisaasCustomMenu';

import Comment from './Comment';
import { getUserLogoLinkByUid } from 'utils/getUid';
import { default as AvatarImage } from '../../../shared/Avatar/Avatar';
import DeleteModal from 'components/shared/CognisaasModal/DeleteModal';
import { deleteProjectComment, getAllProjectComments } from 'store/modules/Project/slice';

interface CommentViewProps {
  commentsData: any;
  projectUid: string;
}

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  padding-right: 16px;

  &::after {
    content: '';
    position: absolute;
    left: 19px;
    right: 37px;
    bottom: -6px;
    height: 1px;
    width: 96%;
    background-color: #e5e5e5;
  }
`;

const Frame = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
  margin-top: 17px;

  &:hover {
    background: #f5f5f7;
  }
`;

const CommentView = (props: CommentViewProps) => {
  const [commentId, setCommentId] = useState<string>('');
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedCommentForDeletion, setSelectedCommentForDeletion] = useState<Record<string, any>>({});
  const dispatch = useDispatch();

  function stringToHslColor(str: string, s: number, l: number) {
    let hash = 0;
    console.log(str);

    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    const h = hash % 360;
    return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
  }

  function stringAvatar(name: string) {
    return {
      sx: {
        bgcolor: stringToHslColor(name, 30, 80),
      },
      children: (
        <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#000000' }}>
          {name.split(' ')[0][0]}
          {name.indexOf(' ') >= 0 ? name.split(' ')[1][0] : null}
        </Typography>
      ),
    };
  }

  const generateCommentUi = (comment: string) => {
    const splitForUsers = comment.split('@[');
    return splitForUsers.map((item, index) => {
      if (index === 0) {
        return (
          <span key={index} style={{ whiteSpace: 'pre-line' }}>
            {item}
          </span>
        );
      } else {
        // index more than 0 something like this expected => vivek](customer:324234) great
        const strContainingUser = item;
        const arrContainingUser = strContainingUser.split('](');
        const username = arrContainingUser[0];
        const restStrArr = arrContainingUser[1].split(')');
        const customerUid = restStrArr[0];
        const restString = [...restStrArr.filter((item, index) => index > 0)].join('');
        return (
          <span key={index} style={{ whiteSpace: 'pre-line' }}>
            &nbsp;
            <span
              style={{
                backgroundColor: 'rgb(45, 96, 246, 0.1)',
                color: '#2D60F6',
                padding: '2px 10px',
                borderRadius: '33px',
                fontSize: '13px',
                cursor: 'pointer',
              }}
              id={customerUid}
            >
              {username}
            </span>
            {restString}
          </span>
        );
      }
    });
  };

  const handleDelete = async (comment: Record<string, any>) => {
    await dispatch(deleteProjectComment(comment.comment_uid));
    await dispatch(getAllProjectComments(props.projectUid));
  };

  const handleDeleteModal = (comment: Record<string, any>) => {
    setOpenDeleteModal(true);
    setSelectedCommentForDeletion(comment);
  };

  return (
    <>
      {[...props.commentsData].reverse().map((comment: any, index: number) => {
        return (
          <Wrapper key={comment.comment_uid || index}>
            <Frame>
              <Stack spacing={2} direction={'row'} sx={{ minHeight: '10px', wordBreak: 'break-all', width: '80%' }}>
                <Stack ml={2} direction="row">
                  {getUserLogoLinkByUid(comment.user_uid).length > 0 ? (
                    <AvatarImage src={getUserLogoLinkByUid(comment.user_uid)} alt="user profile" />
                  ) : (
                    <Avatar
                      style={{ height: '40px', width: '40px', marginTop: '3px' }}
                      {...stringAvatar(comment.user_username)}
                    />
                  )}
                </Stack>

                <Stack
                  direction={'column'}
                  sx={{ marginTop: '5px' }}
                  style={{ width: '100%', fontFamily: 'Noto sans' }}
                >
                  <Typography variant="subtitle1">{comment.user_username}</Typography>
                  {commentId === comment.comment_uid ? (
                    <Comment
                      projectUid={props.projectUid}
                      setCommentId={setCommentId}
                      commentDetail={comment}
                      type="Edit"
                    />
                  ) : (
                    <Stack className="label-text" direction={'row'}>
                      {generateCommentUi(comment.comment)}
                    </Stack>
                  )}
                </Stack>
              </Stack>
              <Stack m={2}>
                <Typography className="label-text" variant="subtitle1" style={{ fontSize: '12px' }}>
                  {formatDistance(new Date(comment.created_on.replace(/-/g, '/')), new Date(), {
                    includeSeconds: true,
                  })}
                  &nbsp;ago
                </Typography>
              </Stack>
              <Stack>
                <CognissasCustomMenu
                  list={[
                    {
                      value: 'Edit',
                      handleClick: setCommentId,
                      args: [comment.comment_uid],
                    },
                    {
                      value: 'Delete',
                      handleClick: handleDeleteModal,
                      args: [comment],
                    },
                  ]}
                />
              </Stack>
            </Frame>
            <DeleteModal
              isOpen={openDeleteModal}
              setIsOpen={setOpenDeleteModal}
              message={'Are you sure you want to delete this comment'}
              onDelete={() => {
                handleDelete(selectedCommentForDeletion);
              }}
            />
          </Wrapper>
        );
      })}
    </>
  );
};

export default CommentView;
