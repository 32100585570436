import Styles from './OnboardNewAccount.module.scss';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import SaveIcon from '@mui/icons-material/Save';
import { Formik, FieldArray } from 'formik';
import { Autocomplete, FormControl, Radio } from '@mui/material';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import CognisaasButton from '../../shared/CognisaasButton/CognisaasButton';
import { useAppSelector } from '../../../store/hooks';
import {
  selectAddAccountToDraftInDb,
  selectOnboardFormValues,
} from '../../../store/modules/ManageClient/OnboardFormValues/selector';
import {
  selectIndustryFilterData,
  // selectSegmentFilterData,
  selectStageFilterData,
} from 'store/modules/ui/Filters/selector';
import { setUpFiltersForClientListPage } from '../../../store/modules/ui/Filters/slice';
import { getAllClientContacts } from '../../../store/modules/Common/slice';
import { selectClientContactsOfCompany } from '../../../store/modules/Common/selector';

import { onboardAccountProfileSchema } from '../../../utils/validations';
import { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import CognisaasSelect from '../../shared/CognisaasSelect/CognisaasSelect';
import { selectUsersOfCompany } from '../../../store/modules/Common/selector';
import { getAllUsers } from '../../../store/modules/Common/slice';
import { useDispatch } from 'react-redux';
import { getCustomerDetail } from 'store/modules/Common/slice';
// import { selectCustomerDetail } from 'store/modules/Common/selector';
// import getSymbolFromCurrency from 'currency-symbol-map';
import useUserDropDownData from 'components/shared/Hooks/useUserDropDownData';
import CognisaasUserSelect from 'components/shared/CognisaasSelect/CognisaasUserSelect';
import CognisaasToast from 'components/shared/CognisaasToast/CognisaasToast';
import {
  label_client,
  label_cs_owner,
  label_sales_owner,
} from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { getUserUid } from 'utils/getUid';
import DeleteModal from 'components/shared/CognisaasModal/DeleteModal';
import useCustomNames from 'components/shared/CustomNames/useCustomNames';
interface Props {
  handleSave: (
    formCategory: 'accountProfile' | 'customFields' | 'addProject' | 'reset',
    formFieldValues: Record<string, any>,
    nextActiveState: number
  ) => void;
  handleCancel: () => void;
}

const OnboardAccountProfile = (componentProps: Props) => {
  const onboardFormValues = useAppSelector(selectOnboardFormValues);
  const industry = useAppSelector(selectIndustryFilterData);
  // const segment = useAppSelector(selectSegmentFilterData);
  const addAccountToDraftInDbState = useAppSelector(selectAddAccountToDraftInDb);
  // const customerDetail = useAppSelector(selectCustomerDetail);
  const allUsers = useAppSelector(selectUsersOfCompany);
  const allClientContacts = useAppSelector(selectClientContactsOfCompany);
  const stage = useAppSelector(selectStageFilterData);
  const [primaryContactIndex, setPrimaryContactIndex] = useState<number>(0);
  const [clienContactEmailList, setclienContactEmailList] = useState([]);
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const companyUsersDropDownData = useUserDropDownData('company');
  const [alertDetails, setAlertDetails] = useState<{
    isVisible: boolean;
    type: 'success' | 'failure';
    message: string;
  }>({ isVisible: false, type: 'success', message: '' });
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const { clientSegmentDropdownValues } = useCustomNames();
  const segment = clientSegmentDropdownValues.map((item: any) => item.custom_segment_name);

  const dispatch = useDispatch();

  useEffect(() => {
    if (allClientContacts?.length > 0) {
      const tempArr: any = [];
      allClientContacts?.map((ele) => {
        tempArr.push(ele.contact_email);
      });
      setclienContactEmailList(tempArr);
    }
  }, [allClientContacts]);

  useEffect(() => {
    if (allUsers.length == 0) {
      dispatch(getAllUsers()); // getting all users
    }
    dispatch(getCustomerDetail());
    dispatch(getAllClientContacts());
  }, []);

  useEffect(() => {
    dispatch(setUpFiltersForClientListPage());
  }, []);

  function validateContactEmail(email: any) {
    const invalidEmail = email;
    const error = 'Email Is already Added';
    if (clienContactEmailList?.length > 0) {
      clienContactEmailList.map((ele: any) => {
        if (ele == invalidEmail) {
          setIsEmailInvalid(true);
          return error;
        } else setIsEmailInvalid(false);
      });
    }
    return error;
  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          ...onboardFormValues.accountProfile,
          journeyStage: stage[0],
          segment: segment[0],
        }}
        validationSchema={onboardAccountProfileSchema}
        onSubmit={(values: any) => {
          if (values.accountName.trim())
            componentProps.handleSave('accountProfile', { ...values, primaryContactIndex }, 1);
          else
            setAlertDetails({
              isVisible: true,
              type: 'failure',
              message: 'Account Name cannot be empty.',
            });
        }}
      >
        {(props: any) => (
          <form onSubmit={props.handleSubmit} className={Styles.OnboardAccountProfile__container}>
            <Typography sx={{ magrin: '12px 0px' }} variant="h6" component="div" gutterBottom>
              Profile
            </Typography>
            <div className={Styles.OnboardAccount__section}>
              <div className={Styles.OnboardAccountProfile__formField}>
                <Typography variant="subtitle2">{label_client} Name*</Typography>
                <TextField
                  sx={{ width: '310px' }}
                  size="small"
                  id="accountName"
                  name="accountName"
                  placeholder="Account Name"
                  onChange={props.handleChange}
                  value={props.values.accountName}
                  onBlur={props.handleBlur}
                  error={
                    (props.touched.accountName && Boolean(props.errors.accountName)) ||
                    Boolean(addAccountToDraftInDbState.error)
                  }
                  helperText={
                    (props.touched.accountName && props.errors.accountName) || addAccountToDraftInDbState.error
                  }
                ></TextField>
              </div>
              <div className={Styles.OnboardAccountProfile__formField}>
                <Typography variant="subtitle2">Segment</Typography>
                <FormControl sx={{ minWidth: '260px' }} size="small">
                  <CognisaasSelect
                    width="310px"
                    onChange={props.handleChange}
                    name="segment"
                    value={props.values.segment}
                    validValues={segment}
                    onBlur={props.handleBlur}
                    error={props.touched.segment && Boolean(props.errors.segment)}
                    helperText={props.touched.segment && props.errors.segment}
                  />
                </FormControl>
              </div>
              <div className={Styles.OnboardAccountProfile__formField}>
                <Typography variant="subtitle2">Industry*</Typography>
                <FormControl sx={{ minWidth: '260px' }} size="small">
                  <Autocomplete
                    sx={{ width: 310 }}
                    options={[...industry]}
                    getOptionLabel={(option) => {
                      return option;
                    }}
                    value={props.values.industry}
                    onBlur={props.handleBlur}
                    disableClearable
                    onChange={(e, value) => {
                      props.setFieldValue('industry', value);
                    }}
                    size="small"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="industry"
                        variant="outlined"
                        placeholder="Select Industry"
                        error={props.touched.industry && Boolean(props.errors.industry)}
                        helperText={props.touched.industry && props.errors.industry}
                      />
                    )}
                  />
                </FormControl>
              </div>
            </div>
            <div className={Styles.OnboardAccount__section}>
              <div className={Styles.OnboardAccountProfile__formField}>
                <Typography variant="subtitle2">Journey Stage</Typography>
                <FormControl sx={{ minWidth: '260px' }} size="small">
                  <CognisaasSelect
                    width="310px"
                    onChange={props.handleChange}
                    name="journeyStage"
                    value={props.values.journeyStage}
                    validValues={[...stage]}
                    onBlur={props.handleBlur}
                    error={props.touched.journey_stage && Boolean(props.errors.journey_stage)}
                    helperText={props.touched.journey_stage && props.errors.journey_stage}
                  />
                </FormControl>
              </div>

              {/* <div className={Styles.OnboardAccountProfile__formField}>
                <Typography variant="subtitle2">
                  {`Account ${label_arr} (${getSymbolFromCurrency(customerDetail?.currency_code)})`}
                </Typography>
                <TextField
                  sx={{ width: '310px' }}
                  type="number"
                  size="small"
                  id="accountArr"
                  name="accountArr"
                  placeholder={`Enter ${label_arr}`}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  error={props.touched.accountArr && Boolean(props.errors.accountArr)}
                  helperText={props.touched.accountArr && props.errors.accountArr}
                ></TextField>
              </div> */}

              <div className={Styles.OnboardAccountProfile__formField}>
                <Typography variant="subtitle2">{label_sales_owner}</Typography>
                <FormControl sx={{ minWidth: '260px' }} size="small">
                  <CognisaasUserSelect
                    width="310px"
                    name="salesOwner"
                    allOptions={companyUsersDropDownData}
                    selectedOption={props.values.salesOwner}
                    onChange={(val: any) => {
                      props.setFieldValue('salesOwner', {
                        name: val.target.textContent,
                        id: getUserUid(val.target.textContent),
                      });
                    }}
                    onBlur={props.handleBlur}
                    error={props.touched.salesOwner && Boolean(props.errors.salesOwner)}
                    helperText={props.touched.salesOwner && props.errors.salesOwner}
                  />
                </FormControl>
              </div>

              <div className={Styles.OnboardAccountProfile__formField}>
                <Typography variant="subtitle2">{label_cs_owner}</Typography>
                <FormControl sx={{ minWidth: '260px' }} size="small">
                  <CognisaasUserSelect
                    width="310px"
                    name="csOwner"
                    allOptions={companyUsersDropDownData}
                    selectedOption={props.values.csOwner}
                    onChange={(val: any) => {
                      props.setFieldValue('csOwner', {
                        name: val.target.textContent,
                        id: getUserUid(val.target.textContent),
                      });
                    }}
                    onBlur={props.handleBlur}
                    error={props.touched.csOwner && Boolean(props.errors.csOwner)}
                    helperText={props.touched.csOwner && props.errors.csOwner}
                  />
                </FormControl>
              </div>
            </div>

            {/* contact information      */}
            <Typography sx={{ magrin: '12px 0px' }} variant="h6" component="div" gutterBottom>
              {label_client} Contacts
            </Typography>
            <FieldArray name="contactInfo">
              {({ remove }) => (
                <div>
                  {props.values.contactInfo.map((_: any, index: number) => (
                    <div className={Styles.OnboardAccount__section} key={index}>
                      {/* contact person name */}
                      <div className={Styles.OnboardAccountProfile__formField}>
                        <Typography variant="subtitle2">Enter Name</Typography>
                        <TextField
                          sx={{ width: '310px' }}
                          size="small"
                          name={`contactInfo[${index}].name`}
                          placeholder="Enter Name"
                          onChange={props.handleChange}
                          value={props.values.contactInfo[index].name}
                          onBlur={props.handleBlur}
                          error={
                            props.errors.contactInfo &&
                            props.errors.contactInfo[index] &&
                            props.errors.contactInfo[index].name &&
                            props.touched &&
                            props.touched.contactInfo &&
                            props.touched.contactInfo[index] &&
                            props.touched.contactInfo[index].name
                          }
                          helperText={
                            props.errors.contactInfo &&
                            props.errors.contactInfo[index] &&
                            props.errors.contactInfo[index].name &&
                            props.touched &&
                            props.touched.contactInfo &&
                            props.touched.contactInfo[index] &&
                            props.touched.contactInfo[index].name &&
                            props.errors.contactInfo[index].name
                          }
                        ></TextField>
                      </div>

                      {/* contact person email */}
                      <div className={Styles.OnboardAccountProfile__formField}>
                        <Typography variant="subtitle2">Email Address</Typography>

                        <TextField
                          style={{ width: '310px', height: '40px' }}
                          size="small"
                          type={'email'}
                          name={`contactInfo[${index}].email`}
                          placeholder="Enter Email address"
                          onChange={(e: any) => {
                            validateContactEmail(e.target.value);
                            props.handleChange(e);
                          }}
                          value={props.values.contactInfo[index].email}
                          onBlur={props.handleBlur}
                          error={
                            props.errors.contactInfo &&
                            props.errors.contactInfo[index] &&
                            props.errors.contactInfo[index].email &&
                            props.touched &&
                            props.touched.contactInfo &&
                            props.touched.contactInfo[index] &&
                            props.touched.contactInfo[index].email
                          }
                          helperText={
                            props.errors.contactInfo &&
                            props.errors.contactInfo[index] &&
                            props.errors.contactInfo[index].email &&
                            props.touched &&
                            props.touched.contactInfo &&
                            props.touched.contactInfo[index] &&
                            props.touched.contactInfo[index].email &&
                            props.errors.contactInfo[index].email
                          }
                        ></TextField>
                        {isEmailInvalid ? (
                          <Typography color="red" variant="subtitle2">
                            {props.errors.email} already present please use different email
                          </Typography>
                        ) : null}
                      </div>

                      <div className={Styles.OnboardAccountProfile__formField}>
                        <Typography variant="subtitle2">Role</Typography>
                        <FormControl sx={{ minWidth: '260px' }} size="small">
                          <TextField
                            fullWidth
                            onChange={props.handleChange}
                            name={`contactInfo[${index}].role`}
                            value={props.values.contactInfo[index].role}
                            size="small"
                            sx={{ width: '300px' }}
                            onBlur={props.handleBlur}
                            error={
                              props.errors.contactInfo &&
                              props.errors.contactInfo[index] &&
                              props.errors.contactInfo[index].role &&
                              props.touched &&
                              props.touched.contactInfo &&
                              props.touched.contactInfo[index] &&
                              props.touched.contactInfo[index].role
                            }
                            helperText={
                              props.errors.contactInfo &&
                              props.errors.contactInfo[index] &&
                              props.errors.contactInfo[index].role &&
                              props.touched &&
                              props.touched.contactInfo &&
                              props.touched.contactInfo[index] &&
                              props.touched.contactInfo[index].role &&
                              props.errors.contactInfo[index].role
                            }
                          />
                        </FormControl>
                      </div>

                      <div className={Styles.OnboardAccountProfile__formField}>
                        <Typography variant="subtitle2">Primary</Typography>
                        <Radio
                          sx={{ margin: 'auto' }}
                          checked={primaryContactIndex === index}
                          onChange={() => {
                            setPrimaryContactIndex(index);
                          }}
                          name={'isPrimary'}
                          value={index}
                        />
                      </div>

                      {index > 0 ? (
                        <div className={Styles.OnboardAccountProfile__formField}>
                          <label style={{ visibility: 'hidden' }} htmlFor="delete button">
                            .
                          </label>
                          <Button
                            onClick={() => {
                              setOpenDeleteModal(true);
                              remove(index);
                            }}
                          >
                            Delete
                          </Button>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  ))}
                  <div className={Styles.OnboardAccount__section}></div>
                </div>
              )}
            </FieldArray>
            <Divider sx={{ margin: '12px 0px' }} />
            <div className={Styles.OnboardAccount__section}>
              <div className={Styles.OnboardAccountProfile__formField}>
                <Typography variant="subtitle2">Comments</Typography>
                <FormControl>
                  <TextField
                    sx={{ width: '47vw', minWidth: '260px' }}
                    id="comment"
                    name="comment"
                    placeholder="Enter Comment"
                    onChange={props.handleChange}
                    value={props.values.comment}
                    multiline
                    rows={4}
                    onBlur={props.handleBlur}
                    error={props.touched.comment && Boolean(props.errors.comment)}
                    helperText={props.touched.comment && props.errors.comment}
                  ></TextField>
                </FormControl>
              </div>
            </div>
            <div className={Styles.OnboardAccountProfile__formActionButtonsContainer}>
              <CognisaasButton label={'Cancel'} isOutlined={true} clickHandler={componentProps.handleCancel} />
              <span style={{ width: '20px' }}></span>

              {addAccountToDraftInDbState.status === 'loading' ? (
                <LoadingButton
                  sx={{ width: '77px' }}
                  loading
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="outlined"
                >
                  Save
                </LoadingButton>
              ) : (
                <CognisaasButton
                  disabled={!props.isValid || !props.dirty}
                  label={'Save'}
                  isOutlined={false}
                  clickHandler={props.handleSubmit}
                />
              )}
            </div>
          </form>
        )}
      </Formik>

      <CognisaasToast
        message={alertDetails.message}
        type={alertDetails.type}
        open={alertDetails.isVisible}
        onClose={() => {
          setAlertDetails({ isVisible: false, type: alertDetails.type, message: '' });
        }}
      />
      <DeleteModal
        isOpen={openDeleteModal}
        setIsOpen={setOpenDeleteModal}
        message={'This will delete'}
        onDelete={() => {
          console.log('this deletion not happening in debate.');
        }}
      />
    </>
  );
};

export default OnboardAccountProfile;
