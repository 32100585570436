import { Box, FormControl, Grid, Stack, Typography } from '@mui/material';
import CognisaasDropdown from 'components/shared/CognisaasDropdown/CognisaasDropdown';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { selectCustomerDetailsIntegration, selectCRMIntegrationStages } from 'store/modules/Settings/selector';
import { getCRMStagesSlice, updateStageAndFetchModeSlice } from 'store/modules/Settings/slice';
import { requestState } from 'store/modules/types';
import CognisaasButton from 'components/shared/CognisaasButton/CognisaasButton';
import Cookies from 'universal-cookie';
import { getCustomerIntegrationDetails } from 'store/modules/Settings/slice';
import CognisaasAutoComplete from 'components/shared/CognisaasMultiSelectWithSearch/CognisaasAutoComplete';
import CognisaasToast from 'components/shared/CognisaasToast/CognisaasToast';
import CognisaasLoader from 'components/shared/CognisaasLoader/CognisaasLoader';
const cookies = new Cookies();
const PipeDriveStatusOrStageSelection = () => {
    const dispatch = useAppDispatch();
    const customerIntegrationDetails = useAppSelector(selectCustomerDetailsIntegration);
    const [enableForm, setenableForm] = useState<boolean>(true);
    const [stages, setstages] = useState<any[]>([]);
    const [statusOrStage, setstatusOrStage] = useState<string>('Status');
    const crmStages = useAppSelector(selectCRMIntegrationStages);
    const stage_array: any = [];
    const [originalStageData, setoriginalStageData] = useState<any[]>([]);
    const [initialFormValues, setInitialFormValues] = useState<any>({
        status: '',
        fetch_mode: '',
    });
    const [selectedStageMultiOption, setSelectedStageMultiOption] = useState<any>([]);
    const [alertDetails, setAlertDetails] = useState<{
        isVisible: boolean;
        type: 'success' | 'failure';
        message: string;
    }>({ isVisible: false, type: 'success', message: '' });
    useEffect(() => {
        if (stages.length == 0) {
            dispatch(getCRMStagesSlice());
        }
    }, [stages]);
    useEffect(() => {
        if (crmStages.status == requestState.success) {
            if (crmStages.data != null) {
                setoriginalStageData(crmStages.data.data);
                crmStages.data.data.forEach((element: any) => {
                    const obj: any = {};
                    obj.name = element.name;
                    obj.id = element.id;
                    stage_array.push(obj);
                });
                setstages([...stage_array]);
            }
        }
    }, [crmStages]);

    useEffect(() => {
        if (customerIntegrationDetails.status == requestState.success) {
            if (customerIntegrationDetails.data.result != null) {
                if ((customerIntegrationDetails.data.result.fetch_mode == null && (customerIntegrationDetails.data.result.status == null || customerIntegrationDetails.data.result.stages.length == 0))) {
                    setInitialFormValues({
                        status: '',
                        fetch_mode: '',
                    });
                    setSelectedStageMultiOption([]);
                }
                else {
                    setenableForm(false);
                    setInitialFormValues({
                        status: customerIntegrationDetails.data.result.status == null ? '' : customerIntegrationDetails.data.result.status,
                        fetch_mode: customerIntegrationDetails.data.result.fetch_mode,
                    });
                    if (customerIntegrationDetails.data.result.status != null)
                        setstatusOrStage('Status');
                    else
                        setstatusOrStage('Stage');
                    if (customerIntegrationDetails.data.result.stages) {
                        setSelectedStageMultiOption([...customerIntegrationDetails.data.result.stages]);
                    }
                }
            }
        }
    }, [customerIntegrationDetails, originalStageData, stages]);

    const submitFormValues = async (value: any) => {
        if (selectedStageMultiOption.length == 0 && value.status == '') {
            setAlertDetails({
                isVisible: true,
                type: 'failure',
                message: 'Select stage or status on which the opportunites will be synced!',
            });
        } else {
            if (selectedStageMultiOption.length != 0 && value.status != '') {
                setAlertDetails({
                    isVisible: true,
                    type: 'failure',
                    message: 'Select either stage or status on which the opportunites will be synced!',
                });
            }
            else if (selectedStageMultiOption.length >= 0 && value.status == '') {
                const details = {
                    status: null,
                    fetch_mode: 'OPPORTUNITIES',
                    stages: [...selectedStageMultiOption],
                    customer_uid: cookies.get('cogni_customer_uid') as string
                };
                await dispatch(updateStageAndFetchModeSlice(details));
                dispatch(getCustomerIntegrationDetails());
            } else if (value.status != '' && selectedStageMultiOption.length == 0) {
                const details = {
                    status: value.status,
                    fetch_mode: 'OPPORTUNITIES',
                    stages: [...selectedStageMultiOption],
                    customer_uid: cookies.get('cogni_customer_uid') as string
                };
                await dispatch(updateStageAndFetchModeSlice(details));
                dispatch(getCustomerIntegrationDetails());
            }
        }
    };
    return (
        <>
            <CognisaasToast
                message={alertDetails.message}
                type={alertDetails.type}
                open={alertDetails.isVisible}
                onClose={() => {
                    setAlertDetails({ isVisible: false, type: alertDetails.type, message: '' });
                }}
            />
            <Grid sx={{ padding: '5px 20px 40px 20px' }}>
                {
                    stages.length >= 0 && customerIntegrationDetails.status == requestState.success &&
                    <>
                        <hr style={{ border: 'none' }} />
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                        >
                            <Grid direction="column" item >
                                <Typography>
                                    Pull the opportunities based on
                                </Typography>
                                <CognisaasDropdown
                                    sx={{ width: '250px', }}
                                    name="status"
                                    id='status'
                                    type="dropdown"
                                    validvalues={['Status', 'Stage']}
                                    onChange={(e: any) => {
                                        setstatusOrStage(e.target.value);
                                        setInitialFormValues({
                                            status: '',
                                            fetch_mode: '',
                                        });
                                        setSelectedStageMultiOption([]);
                                    }}
                                    value={statusOrStage}
                                    readOnly={!enableForm}
                                ></CognisaasDropdown>
                            </Grid>
                        </Grid>
                        <hr style={{ border: 'none' }} />
                        <Formik
                            initialValues={initialFormValues}
                            onSubmit={(value) => {
                                submitFormValues(value);
                            }}
                            enableReinitialize={true}
                        >
                            {(props: any) => (
                                <form >
                                    <div>
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                            spacing={2}
                                        >
                                            {statusOrStage == 'Status' ? (
                                                <Grid direction="column" item  >
                                                    <Typography>
                                                        Select the Status
                                                    </Typography>
                                                    <FormControl variant="outlined">
                                                        <CognisaasDropdown
                                                            sx={{ width: '250px', }}
                                                            name="status"
                                                            id='status'
                                                            type="dropdown"
                                                            validvalues={['WON', 'CLOSED', 'OPEN']}
                                                            onChange={props.handleChange}
                                                            value={props.values.status}
                                                            onBlur={props.handleBlur}
                                                            error={props.touched.status && Boolean(props.errors.status)}
                                                            helperText={props.touched.status && props.errors.status}
                                                            readOnly={!enableForm}
                                                        ></CognisaasDropdown>
                                                    </FormControl>
                                                </Grid>
                                            ) : (
                                                <Grid direction="column" item >
                                                    <Typography>
                                                        Select the Stage
                                                    </Typography>
                                                    <FormControl variant="outlined" >
                                                        <CognisaasAutoComplete
                                                            keepOpen={false}
                                                            allOptions={stages}
                                                            width='202px'
                                                            placeholder='Select stages'
                                                            disabled={!enableForm}
                                                            value={selectedStageMultiOption}
                                                            selectedOption={(e: any, value: any) => {
                                                                setSelectedStageMultiOption(value);
                                                            }}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            )
                                            }
                                            <Grid direction="column" item >
                                                <Typography>
                                                    Select the Fetch type
                                                </Typography>
                                                <FormControl variant="outlined">
                                                    <CognisaasDropdown
                                                        sx={{ width: '250px', }}
                                                        name="fetch_mode"
                                                        id='fetch_mode'
                                                        type="dropdown"
                                                        validvalues={['ACCOUNTS', 'OPPORTUNITIES']}
                                                        onChange={props.handleChange}
                                                        value='OPPORTUNITIES'
                                                        onBlur={props.handleBlur}
                                                        error={props.touched.fetch_mode && Boolean(props.errors.fetch_mode)}
                                                        helperText={props.touched.fetch_mode && props.errors.fetch_mode}
                                                        readOnly={true}
                                                    ></CognisaasDropdown>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                        >
                                            <Grid sx={{ marginTop: '28px', alignItems: 'end' }} item>
                                                <Stack direction="row" spacing={2}>
                                                    <CognisaasButton style={{ marginRight: '16px' }} isOutlined={true} label="Cancel" disabled={!enableForm} clickHandler={() => {
                                                        props.handleReset();
                                                        setSelectedStageMultiOption([]);
                                                    }} />
                                                    <CognisaasButton
                                                        isOutlined={false}
                                                        disabled={!enableForm}
                                                        clickHandler={() => {
                                                            props.handleSubmit();
                                                        }}
                                                        label="Save"
                                                    />
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </>
                }
                {
                    customerIntegrationDetails.status == requestState.loading &&
                    <Box component={'span'} sx={{ height: '50%' }}>
                        <CognisaasLoader />
                    </Box>
                }
            </Grid>
        </>
    );
};

export default PipeDriveStatusOrStageSelection;