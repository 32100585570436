import { TextField, Typography, Grid, Stack } from '@mui/material';
import Styles from '../../ManageClient/OnboardNewAccount/OnboardNewAccount.module.scss';
import CognisaasButton from '../CognisaasButton/CognisaasButton';
import { Formik } from 'formik';
import CognisaasSelect from '../CognisaasSelect/CognisaasSelect';
import { ReactComponent as NoRecords } from '../../../assets/svgs/no_records.svg';
import CognisaasCalenderNew from '../CognisaasCalenderNew/CognisaasCalenderNew';
import { isNull, isUndefined } from 'utils/dataHelpers';
import AttachmentViewer from '../Attachment/AttachmentFileView/AttachmentViewer';
import { attachmentKeyGenerator } from '../Attachment/attachmentHelpers';
import Attachment from '../Attachment/Attachment';
import { label_client } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { userPermissionSchema } from 'components/Reports/SideDrawer/types';
import { useEffect, useState } from 'react';
import CognisaasButtonWithLoading from '../CognisaasButton/CognisaasButtonWithLoading';
import CognisaasToast from '../CognisaasToast/CognisaasToast';
import { updateTaskCustomfields } from 'store/modules/Task/slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectTaskModalCustomfields } from 'store/modules/Task/selector';
import CognisaasUserSelect from 'components/shared/CognisaasSelect/CognisaasUserSelect';
import { getUserNameByUserUid, getUserUid } from 'utils/getUid';
import { getUserActiveState, INACTIVE_STRING } from 'utils/userHelpers';
import useCompanyUsers from '../../shared/Hooks/useCompanyUsers';

const CustomFieldsForm = (componentProps: any) => {
  console.log({ componentProps });
  const dispatch = useAppDispatch();
  const taskModalCustomFields = useAppSelector(selectTaskModalCustomfields);
  const customFields = [...componentProps.customFields];
  // let formValuesOnUpdate: any = null;
  const closeDrawer = componentProps.onClose;
  // let isTaskForm = componentProps?.taskUid ? true : false;
  const [initialValues, setInitialValues] = useState<any>({});
  const [mandatoryFields, setMandatoryFields] = useState<any[]>([]);
  const clientUid = componentProps.clientUid ? componentProps.clientUid : null;
  const [alertDetails, setAlertDetails] = useState<{
    isVisible: boolean;
    type: 'success' | 'failure';
    message: string;
  }>({ isVisible: false, type: 'success', message: '' });
  const companyUsers = useCompanyUsers();

  useEffect(() => {
    const values: any = {};
    const tempMandatoryFields = [];
    for (let i = 0; i < customFields.length; i++) {
      values[customFields[i]['field_name']] = customFields[i]['field_value'];
      if (customFields[i].mandatory === '1') {
        tempMandatoryFields.push(customFields[i]);
      }
    }
    setInitialValues(
      taskModalCustomFields && Object.keys(taskModalCustomFields).length > 0
        ? { ...taskModalCustomFields }
        : { ...values }
    );
    setMandatoryFields([...tempMandatoryFields]);
    if (componentProps.taskUid) {
      dispatch(updateTaskCustomfields({ ...taskModalCustomFields }));
    }
  }, [componentProps.customFields]);

  if (customFields.length > 0) {
    return (
      <>
        <CognisaasToast
          fromPanel={true}
          open={alertDetails.isVisible}
          onClose={() => setAlertDetails({ isVisible: false, type: alertDetails.type, message: '' })}
          message={alertDetails.message}
          type={alertDetails.type}
        />
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={(values) => {
            for (const field of mandatoryFields) {
              console.log(field);
              const attachmentAdded = componentProps.attachmentQueue?.find(
                (attachment: any) => attachment.id === field['field_uid']
              );
              if (values[field['field_name']]?.length === 0 && attachmentAdded === undefined) {
                setAlertDetails({
                  isVisible: true,
                  type: 'failure',
                  message: 'Please fill out mandatory fields',
                });
                return;
              }
            }
            componentProps.onSaveClickHandler(values);
          }}
          innerRef={componentProps.taskUid ? componentProps.taskCustomFieldsFormRef : {}}
        >
          {(props: any) => {
            return (
              <form className={Styles.OnboardAccountProfile__container}>
                {/* {updateStoreForTaskModal(props?.values)} */}
                <div className={Styles.OnboardAccountProfile__formAlternateTopSection}>
                  <Typography sx={{ magrin: '12px 0px' }} variant="body2" component="span" gutterBottom>
                    You can update the values of custom fields for this {label_client}.
                  </Typography>
                  {/* <Button >Skip</Button> */}
                </div>

                {/* type of fields String, Number, Date, Dropdown, Hyperlink, RAG status */}

                {customFields.map((eachField: any, index: number) => {
                  const { field_type, field_name, valid_values, field_uid, mandatory } = eachField as any;
                  field_name.trim();
                  const attachment = eachField.attachment ? eachField.attachment : null;
                  const attachment_detail = eachField.attachment_detail ? eachField.attachment_detail : null;
                  const customer_uid = eachField.customer_uid;
                  const project_uid = componentProps.projectUid;
                  const case_uid = componentProps.caseUid;
                  const task_uid = componentProps.taskUid;
                  // const commentText = `${props.values[field_name]}`;
                  const parseLines = (value: string) => value?.replace(/(\\n)/g, '\n');
                  switch (field_type) {
                    case 'String':
                      return (
                        <div className={Styles.OnboardAccount__section} key={index}>
                          <div className={Styles.OnboardAccount__customFieldWrapper}>
                            <Typography
                              variant="body1"
                              component="div"
                              className={Styles.OnboardAccount__customFieldName}
                            >
                              {field_name} {mandatory == '1' && <sup style={{ color: 'red' }}>*</sup>}
                            </Typography>
                            <div className={Styles.OnboardAccount__customFieldInput}>
                              <TextField
                                sx={{ width: '310px', marginRight: '8px' }}
                                size="small"
                                id={field_name}
                                name={field_name}
                                // placeholder={field_name}
                                onChange={(e: any) => {
                                  props.setFieldValue(field_name, e.target.value);
                                }}
                                // onChange={props.handleChange}
                                value={parseLines(props.values[field_name])}
                                multiline
                                maxRows={5}
                                style={{ whiteSpace: 'pre-wrap' }}
                                error={
                                  props.touched[`${field_name}&&${mandatory}`] &&
                                  mandatory == '1' &&
                                  props.values[`${field_name}&&${mandatory}`].length == 0
                                }
                              ></TextField>

                              {/* custom fields */}
                              {attachment && attachment == '1' && attachment_detail && attachment_detail.length > 0 && (
                                <AttachmentViewer
                                  key={index}
                                  fileName={attachment_detail[0].name}
                                  attachmentUid={attachment_detail[0].attachment_uid}
                                  serverGetApiUrlWithQueryString={`downloadAttachment?attachment_uid=${attachment_detail[0].attachment_uid}`}
                                  deleteApiUrl={'deleteAttachment'}
                                  deleteAttachmentPayload={{
                                    attachment_uid: attachment_detail[0].attachment_uid,
                                    category: 'custom_field',
                                    client_uid: attachment_detail[0].client_uid,
                                    customer_uid: customer_uid,
                                    field_uid: field_uid,
                                    key: attachmentKeyGenerator(customer_uid, field_uid),
                                    taskUid: null,
                                  }}
                                  deleteCallbackFunc={() => {
                                    componentProps.callbackToReloadData();
                                  }}
                                  size={'minimal'}
                                />
                              )}
                              {attachment === '1' && isNull(attachment_detail) && (
                                <Attachment
                                  type="small"
                                  metaDataForHeaders={{
                                    category: 'custom_field',
                                    client_uid: clientUid,
                                    project_uid: project_uid,
                                    taskUid: task_uid || '',
                                    case_uid: case_uid,
                                    customer_uid: customer_uid,
                                    field_uid: field_uid,
                                    key: attachmentKeyGenerator(customer_uid, field_uid),
                                  }}
                                  apiUrlForAttachmentUpload={'addAttachments'}
                                  setAlertDetails={setAlertDetails}
                                  // error={props.touched[`${field_uid}&&${mandatory}`] && mandatory == '1' && attachment == '1'}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      );
                      break;

                    case 'Number':
                      return (
                        <div className={Styles.OnboardAccount__section} key={index}>
                          <div className={Styles.OnboardAccount__customFieldWrapper}>
                            <Typography
                              variant="body1"
                              component="div"
                              className={Styles.OnboardAccount__customFieldName}
                            >
                              {field_name} {mandatory == '1' && <sup style={{ color: 'red' }}>*</sup>}
                            </Typography>

                            <div className={Styles.OnboardAccount__customFieldInput}>
                              <TextField
                                sx={{ width: '310px', marginRight: '8px' }}
                                size="small"
                                type="number"
                                id={field_name}
                                name={field_name}
                                // placeholder={field_name}
                                onChange={(e: any) => {
                                  return props.setFieldValue(field_name, e.target.value);
                                }}
                                value={props.values[field_name]}
                              ></TextField>
                              {/* custom fields */}
                              {attachment && attachment == '1' && attachment_detail && attachment_detail.length > 0 && (
                                <AttachmentViewer
                                  key={index}
                                  fileName={attachment_detail[0].name}
                                  attachmentUid={attachment_detail[0].attachment_uid}
                                  serverGetApiUrlWithQueryString={`downloadAttachment?attachment_uid=${attachment_detail[0].attachment_uid}`}
                                  deleteApiUrl={'deleteAttachment'}
                                  deleteAttachmentPayload={{
                                    attachment_uid: attachment_detail[0].attachment_uid,
                                    category: 'custom_field',
                                    client_uid: attachment_detail[0].client_uid,
                                    customer_uid: customer_uid,
                                    field_uid: field_uid,
                                    key: attachmentKeyGenerator(customer_uid, field_uid),
                                    taskUid: null,
                                  }}
                                  deleteCallbackFunc={() => {
                                    componentProps.callbackToReloadData();
                                  }}
                                  size={'minimal'}
                                />
                              )}
                              {attachment === '1' && isNull(attachment_detail) && (
                                <Attachment
                                  type="small"
                                  metaDataForHeaders={{
                                    category: 'custom_field',

                                    project_uid: project_uid,
                                    taskUid: task_uid || '',
                                    case_uid: case_uid,
                                    client_uid: clientUid,
                                    customer_uid: customer_uid,
                                    field_uid: field_uid,

                                    key: attachmentKeyGenerator(customer_uid, field_uid),
                                  }}
                                  apiUrlForAttachmentUpload={'addAttachments'}
                                  setAlertDetails={setAlertDetails}

                                  // error={props.touched[`${field_uid}&&${mandatory}`] && mandatory == '1' && attachment == '1'}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      );
                      break;

                    case 'Date':
                      return (
                        <div className={Styles.OnboardAccount__section} key={index}>
                          <div className={Styles.OnboardAccount__customFieldWrapper}>
                            <Typography
                              variant="body1"
                              component="div"
                              className={Styles.OnboardAccount__customFieldName}
                            >
                              {field_name} {mandatory == '1' && <sup style={{ color: 'red' }}>*</sup>}
                            </Typography>
                            {/* <h1>{new Date(props.values[field_name][0])}</h1> */}

                            <div className={Styles.OnboardAccount__customFieldInput}>
                              <CognisaasCalenderNew
                                name={field_name}
                                width="310px"
                                handleChange={(e: any) => {
                                  props.setFieldValue(field_name, new Date(e));
                                }}
                                value={
                                  isUndefined(props.values[field_name]) || props.values[field_name] == ''
                                    ? null
                                    : new Date(props.values[field_name])
                                }
                              />
                              <span style={{ marginRight: '8px' }}></span>
                              {/* custom fields */}
                              {attachment && attachment == '1' && attachment_detail && attachment_detail.length > 0 && (
                                <AttachmentViewer
                                  key={index}
                                  fileName={attachment_detail[0].name}
                                  attachmentUid={attachment_detail[0].attachment_uid}
                                  serverGetApiUrlWithQueryString={`downloadAttachment?attachment_uid=${attachment_detail[0].attachment_uid}`}
                                  deleteApiUrl={'deleteAttachment'}
                                  deleteAttachmentPayload={{
                                    attachment_uid: attachment_detail[0].attachment_uid,
                                    category: 'custom_field',
                                    client_uid: attachment_detail[0].client_uid,
                                    customer_uid: customer_uid,
                                    field_uid: field_uid,
                                    key: attachmentKeyGenerator(customer_uid, field_uid),
                                    taskUid: null,
                                  }}
                                  deleteCallbackFunc={() => {
                                    componentProps.callbackToReloadData();
                                  }}
                                  size={'minimal'}
                                />
                              )}
                              {attachment === '1' && isNull(attachment_detail) && (
                                <Attachment
                                  type="small"
                                  metaDataForHeaders={{
                                    category: 'custom_field',

                                    project_uid: project_uid,
                                    taskUid: task_uid || '',
                                    case_uid: case_uid,
                                    client_uid: clientUid,
                                    customer_uid: customer_uid,
                                    field_uid: field_uid,

                                    key: attachmentKeyGenerator(customer_uid, field_uid),
                                  }}
                                  apiUrlForAttachmentUpload={'addAttachments'}
                                  setAlertDetails={setAlertDetails}
                                  // error={props.touched[`${field_uid}&&${mandatory}`] && mandatory == '1' && attachment == '1'}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      );
                      break;

                    case 'Dropdown':
                      return (
                        <div className={Styles.OnboardAccount__section} key={index}>
                          <div className={Styles.OnboardAccount__customFieldWrapper}>
                            <Typography
                              variant="body1"
                              component="div"
                              className={Styles.OnboardAccount__customFieldName}
                            >
                              {field_name} {mandatory == '1' && <sup style={{ color: 'red' }}>*</sup>}
                            </Typography>

                            <div className={Styles.OnboardAccount__customFieldInput}>
                              <CognisaasSelect
                                width="317px"
                                sx={{ marginRight: '8px' }}
                                name={field_name}
                                onChange={(e: any) => {
                                  return props.setFieldValue(field_name, e.target.value);
                                }}
                                validValues={[...valid_values.split(',')]}
                                value={props.values[field_name]}
                              />
                              {/* custom fields */}
                              {attachment && attachment == '1' && attachment_detail && attachment_detail.length > 0 && (
                                <AttachmentViewer
                                  key={index}
                                  fileName={attachment_detail[0].name}
                                  attachmentUid={attachment_detail[0].attachment_uid}
                                  serverGetApiUrlWithQueryString={`downloadAttachment?attachment_uid=${attachment_detail[0].attachment_uid}`}
                                  deleteApiUrl={'deleteAttachment'}
                                  deleteAttachmentPayload={{
                                    attachment_uid: attachment_detail[0].attachment_uid,
                                    category: 'custom_field',
                                    client_uid: attachment_detail[0].client_uid,
                                    customer_uid: customer_uid,
                                    field_uid: field_uid,
                                    key: attachmentKeyGenerator(customer_uid, field_uid),
                                    taskUid: null,
                                  }}
                                  deleteCallbackFunc={() => {
                                    componentProps.callbackToReloadData();
                                  }}
                                  size={'minimal'}
                                />
                              )}
                              {attachment === '1' && isNull(attachment_detail) && (
                                <Attachment
                                  type="small"
                                  metaDataForHeaders={{
                                    category: 'custom_field',

                                    project_uid: project_uid,
                                    taskUid: task_uid || '',
                                    case_uid: case_uid,
                                    client_uid: clientUid,
                                    customer_uid: customer_uid,
                                    field_uid: field_uid,

                                    key: attachmentKeyGenerator(customer_uid, field_uid),
                                  }}
                                  apiUrlForAttachmentUpload={'addAttachments'}
                                  setAlertDetails={setAlertDetails}
                                  // error={props.touched[`${field_uid}&&${mandatory}`] && mandatory == '1' && attachment == '1'}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      );
                      break;

                    case 'Hyperlink':
                      return (
                        <div className={Styles.OnboardAccount__section} key={index}>
                          <div className={Styles.OnboardAccount__customFieldWrapper}>
                            <Typography
                              variant="body1"
                              component="div"
                              className={Styles.OnboardAccount__customFieldName}
                            >
                              {field_name} {mandatory == '1' && <sup style={{ color: 'red' }}>*</sup>}
                            </Typography>

                            <div className={Styles.OnboardAccount__customFieldInput}>
                              <TextField
                                sx={{ width: '310px', marginRight: '8px' }}
                                size="small"
                                id={field_name}
                                name={field_name}
                                // placeholder={''}
                                onChange={(e: any) => {
                                  return props.setFieldValue(field_name, e.target.value);
                                }}
                                value={props.values[field_name]}
                              ></TextField>
                              {/* custom fields */}
                              {attachment && attachment == '1' && attachment_detail && attachment_detail.length > 0 && (
                                <AttachmentViewer
                                  key={index}
                                  fileName={attachment_detail[0].name}
                                  attachmentUid={attachment_detail[0].attachment_uid}
                                  serverGetApiUrlWithQueryString={`downloadAttachment?attachment_uid=${attachment_detail[0].attachment_uid}`}
                                  deleteApiUrl={'deleteAttachment'}
                                  deleteAttachmentPayload={{
                                    attachment_uid: attachment_detail[0].attachment_uid,
                                    category: 'custom_field',
                                    client_uid: attachment_detail[0].client_uid,
                                    customer_uid: customer_uid,
                                    field_uid: field_uid,
                                    key: attachmentKeyGenerator(customer_uid, field_uid),
                                    taskUid: null,
                                  }}
                                  deleteCallbackFunc={() => {
                                    componentProps.callbackToReloadData();
                                  }}
                                  size={'minimal'}
                                />
                              )}
                              {attachment === '1' && isNull(attachment_detail) && (
                                <Attachment
                                  type="small"
                                  metaDataForHeaders={{
                                    category: 'custom_field',

                                    project_uid: project_uid,
                                    taskUid: task_uid || '',
                                    case_uid: case_uid,
                                    client_uid: clientUid,
                                    customer_uid: customer_uid,
                                    field_uid: field_uid,

                                    key: attachmentKeyGenerator(customer_uid, field_uid),
                                  }}
                                  apiUrlForAttachmentUpload={'addAttachments'}
                                  setAlertDetails={setAlertDetails}
                                  // error={props.touched[`${field_uid}&&${mandatory}`] && mandatory == '1' && attachment == '1'}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      );
                      break;

                    case 'RAG status':
                      return (
                        <div className={Styles.OnboardAccount__section} key={index}>
                          <div className={Styles.OnboardAccount__customFieldWrapper}>
                            <Typography
                              variant="body1"
                              component="div"
                              className={Styles.OnboardAccount__customFieldName}
                            >
                              {field_name} {mandatory == '1' && <sup style={{ color: 'red' }}>*</sup>}
                              {/* {props.values[field_name]} */}
                            </Typography>

                            <div className={Styles.OnboardAccount__customFieldInput}>
                              <CognisaasSelect
                                width="317px"
                                overRideDefaultValue={true}
                                sx={{ marginRight: '8px' }}
                                name={field_name}
                                type="rag"
                                value={props.values[`${field_name}`] == null ? 'None' : props.values[`${field_name}`]}
                                onChange={(e: any) => {
                                  return props.setFieldValue(field_name, e.target.value);
                                }}
                                validValues={[...valid_values.split(',')]}
                                error={
                                  props.touched[`${field_name}&&${mandatory}`] &&
                                  mandatory == '1' &&
                                  props.values[`${field_name}&&${mandatory}`].length == 0
                                }
                              />
                              {/* custom fields */}
                              {attachment && attachment == '1' && attachment_detail && attachment_detail.length > 0 && (
                                <AttachmentViewer
                                  key={index}
                                  fileName={attachment_detail[0].name}
                                  attachmentUid={attachment_detail[0].attachment_uid}
                                  serverGetApiUrlWithQueryString={`downloadAttachment?attachment_uid=${attachment_detail[0].attachment_uid}`}
                                  deleteApiUrl={'deleteAttachment'}
                                  deleteAttachmentPayload={{
                                    attachment_uid: attachment_detail[0].attachment_uid,
                                    category: 'custom_field',
                                    client_uid: attachment_detail[0].client_uid,
                                    customer_uid: customer_uid,
                                    field_uid: field_uid,
                                    key: attachmentKeyGenerator(customer_uid, field_uid),
                                    taskUid: null,
                                  }}
                                  deleteCallbackFunc={() => {
                                    componentProps.callbackToReloadData();
                                  }}
                                  size={'minimal'}
                                />
                              )}
                              {attachment === '1' && isNull(attachment_detail) && (
                                <Attachment
                                  type="small"
                                  metaDataForHeaders={{
                                    category: 'custom_field',

                                    project_uid: project_uid,
                                    taskUid: task_uid || '',
                                    case_uid: case_uid,
                                    client_uid: clientUid,
                                    customer_uid: customer_uid,
                                    field_uid: field_uid,

                                    key: attachmentKeyGenerator(customer_uid, field_uid),
                                  }}
                                  apiUrlForAttachmentUpload={'addAttachments'}
                                  setAlertDetails={setAlertDetails}
                                  // error={props.touched[`${field_uid}&&${mandatory}`] && mandatory == '1' && attachment == '1'}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      );
                      break;
                    case 'User List':
                      return (
                        <div className={Styles.OnboardAccount__section} key={index}>
                          <div className={Styles.OnboardAccount__customFieldWrapper}>
                            <Typography
                              variant="body1"
                              component="div"
                              className={Styles.OnboardAccount__customFieldName}
                            >
                              {field_name} {mandatory == '1' && <sup style={{ color: 'red' }}>*</sup>}
                            </Typography>

                            <div className={Styles.OnboardAccount__customFieldInput}>
                              <CognisaasUserSelect
                                width="310px"
                                name={field_name}
                                allOptions={companyUsers.map((ele: any) => ({
                                  ...ele,
                                  id: ele.user_uid,
                                  name: ele.user_username,
                                  is_active: getUserActiveState(props.values[field_name], companyUsers),
                                }))}
                                selectedOption={{
                                  id: props.values[field_name],
                                  name:
                                    getUserActiveState(props.values[field_name], companyUsers) === '0'
                                      ? `${getUserNameByUserUid(props.values[field_name])} (${INACTIVE_STRING})`
                                      : getUserNameByUserUid(props.values[field_name]),
                                  is_active: getUserActiveState(props.values[field_name], companyUsers),
                                }}
                                onChange={(val: any) => {
                                  props.setFieldValue(field_name, getUserUid(val.target.textContent));
                                }}
                              />
                              {/* custom fields */}
                              {attachment && attachment == '1' && attachment_detail && attachment_detail.length > 0 && (
                                <AttachmentViewer
                                  key={index}
                                  fileName={attachment_detail[0].name}
                                  attachmentUid={attachment_detail[0].attachment_uid}
                                  serverGetApiUrlWithQueryString={`downloadAttachment?attachment_uid=${attachment_detail[0].attachment_uid}`}
                                  deleteApiUrl={'deleteAttachment'}
                                  deleteAttachmentPayload={{
                                    attachment_uid: attachment_detail[0].attachment_uid,
                                    category: 'custom_field',
                                    client_uid: attachment_detail[0].client_uid,
                                    customer_uid: customer_uid,
                                    field_uid: field_uid,
                                    key: attachmentKeyGenerator(customer_uid, field_uid),
                                    taskUid: null,
                                  }}
                                  deleteCallbackFunc={() => {
                                    componentProps.callbackToReloadData();
                                  }}
                                  size={'minimal'}
                                />
                              )}
                              {attachment === '1' && isNull(attachment_detail) && (
                                <Attachment
                                  type="small"
                                  metaDataForHeaders={{
                                    category: 'custom_field',

                                    project_uid: project_uid,
                                    taskUid: task_uid || '',
                                    case_uid: case_uid,
                                    client_uid: clientUid,
                                    customer_uid: customer_uid,
                                    field_uid: field_uid,

                                    key: attachmentKeyGenerator(customer_uid, field_uid),
                                  }}
                                  apiUrlForAttachmentUpload={'addAttachments'}
                                  setAlertDetails={setAlertDetails}
                                  // error={props.touched[`${field_uid}&&${mandatory}`] && mandatory == '1' && attachment == '1'}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      );
                      break;

                    default:
                  }
                })}

                {/* buttons for furthur form actions */}
                {componentProps?.userPermissions ? (
                  componentProps?.userPermissions?.includes(userPermissionSchema.EDIT) && (
                    <Grid spacing={2} container justifyContent="flex-end">
                      <Grid item xs={6} md={6} lg={6}>
                        <span style={{ width: '20px' }}></span>
                        <Stack spacing={2} direction="row">
                          <CognisaasButton
                            clickHandler={() => {
                              closeDrawer();
                            }}
                            label={'Cancel'}
                            isOutlined={true}
                          />
                          <CognisaasButtonWithLoading
                            isLoading={componentProps.isLoading}
                            label={'Save'}
                            isOutlined={false}
                            disabled={!props.dirty}
                            clickHandler={() => {
                              props.handleSubmit();
                              // componentProps.handleSave('customFields', props.values, 2);
                            }}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={6} md={6} lg={6}></Grid>
                    </Grid>
                  )
                ) : (
                  <Grid spacing={2} container justifyContent="flex-end">
                    <Grid item xs={6} md={6} lg={6}>
                      <span style={{ width: '20px' }}></span>
                      <Stack spacing={2} direction="row">
                        <CognisaasButton
                          clickHandler={() => {
                            closeDrawer();
                          }}
                          label={'Cancel'}
                          isOutlined={true}
                          disabled={!props.dirty}
                        />
                        <CognisaasButtonWithLoading
                          isLoading={componentProps.isLoading}
                          label={'Save'}
                          isOutlined={false}
                          clickHandler={() => {
                            props.handleSubmit();
                            // componentProps.handleSave('customFields', props.values, 2);
                          }}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={6} md={6} lg={6}></Grid>
                  </Grid>
                )}
                <div className={Styles.OnboardAccountProfile__formActionButtonsContainer}>
                  {/* save and cancel this step buttons  */}
                </div>
              </form>
            );
          }}
        </Formik>
      </>
    );
  } else {
    return (
      <Stack style={{ position: 'absolute', left: '50%', top: '50%' }} height="100%">
        <NoRecords style={{ marginLeft: '6px', marginTop: '10px', width: '170px', height: '170px' }} />
      </Stack>
    );
  }
};

export default CustomFieldsForm;
// lickHandler={componentProps.handleCancel}
