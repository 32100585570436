import { Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { GridColumns } from '@mui/x-data-grid';
import CognisaasButton from 'components/shared/CognisaasButton/CognisaasButton';
import CognisaasLoader from 'components/shared/CognisaasLoader/CognisaasLoader';
// import CognisaasButton from 'components/shared/CognisaasButton/CognisaasButton';
import BasicModal from 'components/shared/CognisaasModal/CognisaasModal';
import CogniTable from 'components/shared/Tables/CogniTable';
import { useEffect } from 'react';

interface MapJiraStatusModalProps {
  header: string;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
  data: any[];
  columns: GridColumns<any>;
  handleSave: () => void;
}

const MapJiraStatusModal = (props: MapJiraStatusModalProps) => {
  useEffect(() => {
    props.setIsOpen(props.open);
  }, [props.open]);

  return (
    <>
      <BasicModal
        height={420}
        width={450}
        open={props.open}
        onClose={() => {
          props.setIsOpen(false);
        }}
      >
        {props.data.length == 0 ? (
          <Box component={'span'} sx={{ height: '50%' }}>
            <CognisaasLoader />
          </Box>
        ) : (
          <Stack spacing={2} sx={{ overflowY: 'scroll' }}>
            <Typography variant={'h2'}>{props.header}</Typography>

            <CogniTable data={props.data} columns={props.columns} hideFooter={true} hideHeader={true} />
            <Stack justifyContent={'flex-end'}>
              <CognisaasButton isOutlined={false} label={'Save'} clickHandler={props.handleSave} />
            </Stack>
          </Stack>
        )}
      </BasicModal>
    </>
  );
};

export default MapJiraStatusModal;
