import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../..';

export const selectclientListState = (state: RootState) => state.clientListState;
export const selectClientList = createSelector(selectclientListState, (clientListState) => clientListState.clientList);
export const selectClientListStatus = createSelector(
  selectclientListState,
  (clientListState) => clientListState.status
);
export const selectClientListError = createSelector(selectclientListState, (clientListState) => clientListState.error);

export const selectSingleClientInfo = createSelector(
  selectclientListState,
  (clientListState) => clientListState.clientFullInformation
);

export const selectSingleClientStatus = createSelector(
  selectclientListState,
  (clientListState) => clientListState.getClientInfoStatus
);

export const selectTotalTrailUnderClient = createSelector(
  selectclientListState,
  (clientListState) => clientListState.totaltrailUnderClient
);

export const selectClientContacts = createSelector(
  selectclientListState,
  (clientListState) => clientListState.clientContacts
);

export const selectAddedClientContactUid = createSelector(
  selectclientListState,
  (clientListState) => clientListState.clientContactUid
);

export const selectUserFullDetail = createSelector(
  selectclientListState,
  (clientListState) => clientListState.userDetail
);

export const selectClientCustomFields = createSelector(
  selectclientListState,
  (clientListState) => clientListState.clientCustomFields
);

export const selectClientAccessListMembers = createSelector(
  selectclientListState,
  (clientListState) => clientListState.getClientAccessListMembers
);

export const selectClientDetail = createSelector(
  selectclientListState,
  (clientListState) => clientListState.clientDetail
);

export const selectClientDetailStatus = createSelector(
  selectclientListState,
  (clientListState) => clientListState.getClientDetailStatus
);

export const selectAddCustomerClientLogoStatus = createSelector(
  selectclientListState,
  (clientListState) => clientListState.addCustomerClientLogoStatus
);

export const selectAddCustomerClientLogoError = createSelector(
  selectclientListState,
  (clientListState) => clientListState.addCustomerClientLogoError
);

export const selectClientCustomFieldValues = createSelector(
  selectclientListState,
  (clientListState) => clientListState.clientCustomFieldsWithValues
);

export const selectClientWhitelistedDomains = createSelector(
  selectclientListState,
  (clientListState) => clientListState.clientWhitelistDomains
);

export const selectCustomerDetail = createSelector(
  selectclientListState,
  (clientListState) => clientListState.getCustomerDetail
);

export const selectClientUidOfSelectedProject = createSelector(
  selectclientListState,
  (clientListState) => clientListState.clientUidOfSelectedProject
);

export const selectClientByAssociationUid = createSelector(
  selectclientListState,
  (clientListState) => clientListState.clientUidOfSelectedProject
);

export const selectClientDetailByClientUid = createSelector(
  selectclientListState,
  (clientListState) => clientListState.getClientDetailByUid
);

export const selectClientAudit = createSelector(
  selectclientListState,
  (clientListState) => clientListState.getClientAudit
);
export const selectVerifiedClientContacts = createSelector(
  selectclientListState,
  (clientListState) => clientListState.getVerifiedClientContacts
);

export const selectProjectInformationWithTaskClientReport = createSelector(
  selectclientListState,
  (clientListState) => clientListState.getProjectInformationWithTaskClientReport
);

export const selectProjecWithClientUid = createSelector(
  selectclientListState,
  (clientListState) => clientListState.getProjecWithClientUid
);

export const selectsendReportToAllClientContacts = createSelector(
  selectclientListState,
  (clientListState) => clientListState.sendReportToAllClientContacts
);

export const selectsendDynamicReportToAllClientContacts = createSelector(
  selectclientListState,
  (clientListState) => clientListState.sendDynamicReportToClientContact
);

export const selectclientListTableView = createSelector(
  selectclientListState,
  (clientListState) => clientListState.clientListTableView
);

export const selectclientContactErrorMessage = createSelector(
  selectclientListState,
  (clientListState) => clientListState.clientContactErrorMessage
);

export const selectClientSettingsInfo = createSelector(
  selectclientListState,
  (clientListState) => clientListState.clientSettingsInfo
);
export const selectRenewalTaskOfClient = createSelector(
  selectclientListState,
  (clientListState) => clientListState.renewalTaskOfClient
);
export const selectDoneRenewalTaskOfClient = createSelector(
  selectclientListState,
  (clientListState) => clientListState.donerenewalTaskOfClient
);
export const selectTaskRenewalOfClientOwner = createSelector(
  selectclientListState,
  (clientListState) => clientListState.renewalTaskOfClientOwner
);

export const selectFetchClientListDispatchStatus = createSelector(
  selectclientListState,
  (clientListState) => clientListState.status
);
