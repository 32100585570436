import Box from '@mui/material/Box';
import { Typography, Stack, Avatar } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { DataGridPremium, gridClasses, GridRenderCellParams,} from '@mui/x-data-grid-premium';
import { useDispatch } from 'react-redux';
import {  useState, useEffect } from 'react';
import { getOldAttachmentVersions, downloadAttachmentFromServer } from 'store/modules/Attachment/slice';
import { useAppSelector } from 'store/hooks';
import { selectAttachmentOldVersion, selectAttachmentDownloading } from 'store/modules/Attachment/selector';
import { default as styledComp } from 'styled-components';

const ButtonLink = styledComp.button`
  background: none;
  border: none;
  color:#2D60F6;
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  cursor:pointer;
`;


const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGridPremium)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
        },
      },
    },
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height:'300px',
  marginTop:'15px',
  boxShadow:'0px 4px 26px rgba(0, 0, 0, 0.05)',
  '& .MuiFormGroup-options': {
    alignItems: 'center',
    paddingBottom: theme.spacing(1),
    '& > div': {
      minWidth: 100,
      margin: theme.spacing(2),
      marginLeft: 0,
    },
  },
}));

function stringToHslColor(str: string, s: number, l: number) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const h = hash % 360;
  return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToHslColor(name, 30, 80),
    },
    children: (
      <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#000000' }}>
        {name.split(' ')[0][0]}
        {name.indexOf(' ') >= 0 ? name.split(' ')[1][0] : null}
      </Typography>
    ),
  };
}

interface VersionTableProps {
  attachmentDetail:any
}

const VersionTable = (props:VersionTableProps) => {
  const dispatch = useDispatch();
  const attachmentOldVersions = useAppSelector(selectAttachmentOldVersion).data;
  const [fileDowloadableUrl, setFileDownloadableUrl] = useState<string>('');

  const attachmentDownloading = useAppSelector(selectAttachmentDownloading);

  const [rows, setRows] = useState<any>(attachmentOldVersions);


  useEffect(() => {
    dispatch(getOldAttachmentVersions(`getOldAttachmentVersions?attachment_uid=${props.attachmentDetail.attachment_uid}`));
  }, []);
  

  useEffect(() => {
    const newRows:any[] = [];
    console.log(attachmentOldVersions);
    for (let i = 0; i < attachmentOldVersions.length; i++) {
      newRows.push({...(attachmentOldVersions[i] as object), document_version:i+1});
    }
    setRows([{...props.attachmentDetail, document_version:'Current', version_uid:props.attachmentDetail.attachment_uid}, ...newRows]);
  }, [attachmentOldVersions]);

  console.log('ROWWSSSS');
  console.log(rows);

  const implementDownload = async (name:string, version_uid:string) => {
    await dispatch(
      downloadAttachmentFromServer({
        serverGetApiUrlWithQueryString: `downloadOldAttachmentVersion?version_uid=${version_uid}`,
        attachmentUid: props.attachmentDetail.attachment_uid,
      })
    );

    const link = document.createElement('a');
    link.download = name;
    link.href = fileDowloadableUrl;
    link.click();
  };

  useEffect(() => {
    if (
      attachmentDownloading.status === 'success' &&
      attachmentDownloading.data.attachmentUid === props.attachmentDetail.attachment_uid
    ) {
      setFileDownloadableUrl(attachmentDownloading.data.url);
    }
  }, [attachmentDownloading]);

  console.log('DETAIL');
  console.log(props.attachmentDetail);
  const columns = [
    {
      headerName: 'Version', 
      field: 'document_version',
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        console.log('PARAMSS');
        console.log({params});
        return (<p>{params.value}</p>);
      },
    },
    {
        headerName: 'Document Name',
        field: 'name',
        width: 200,
      },
    {
      headerName: 'Uploaded By',
      field: 'current_version_uploaded_by_user_name',
      width: 230,

      renderCell: (params: GridRenderCellParams) => (
        <Stack direction="row">
          <Stack spacing={1} direction={'row'}>
            <Avatar style={{ height: '34px', width: '34px', marginTop: '3px' }} {...stringAvatar(params.value)} />
            <Typography style={{ marginTop: '8px' }} variant="subtitle1">
              {params.value}
            </Typography>
          </Stack>
        </Stack>
      ),
    },
    {
      headerName: 'Uploaded On',
      field: 'last_updated',
      width: 170,
    },
    {
      headerName: 'Action',
      field: 'version_uid',
      width:170,
      renderCell: (params: GridRenderCellParams) => {
        return (<ButtonLink onClick={() => implementDownload(params.row.name, params.row.version_uid)}>Download</ButtonLink>);
      }
    },
  ];


  return (
    <>
      <Typography >Version History</Typography>
      <StyledBox>
        <StripedDataGrid
          columns= {columns}
          rows={rows}
          loading={false}
          getRowId={(row) => row.version_uid}
          disableSelectionOnClick
          getRowClassName={(params:any) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'odd' : 'odd')}
        />
      </StyledBox>
    </>
  );
};

export default VersionTable;

