export const getAllTasksOfProject = (project: any) => {
  let projectSections: any[] = [];
  let usecaseSections: any = [];
  projectSections = [...project.section];

  for (let i = 0; i < project.usecase.length; i++) {
    usecaseSections = [...usecaseSections, ...project['usecase'][i].section];
  }

  const sections = [...projectSections, ...usecaseSections];

  // From sections pull tasks of project and usecases
  let projectTasks: any[] = [];
  for (let i = 0; i < sections.length; i++) {
    projectTasks = [...projectTasks, ...sections[i]['task']];
  }

  return [...projectTasks];
};

export const fetchAllCompanyAndClientTasksFromTaskList = (tasks: any[]) => {
  const companyTasks = tasks.filter((t: any) => t.task_type == 'Company task');
  const clientTasks = tasks.filter((t: any) => t.task_type == 'Client task');
  console.log(companyTasks, clientTasks);
  const taskDetails = {
    companyTasks: [...companyTasks],
    clientTasks: [...clientTasks],
  };
  console.log(taskDetails);
  return taskDetails;
};

export const getAllTasksOfAUseCase = (usecase: any) => {
  let allUsecaseTasks: any = [];
  let usecaseSections: any = [];
  usecaseSections = [...usecase.section];

  let usecaseTasks: any[] = [];
  for (let i = 0; i < usecaseSections.length; i++) {
    usecaseTasks = [...usecaseTasks, ...usecaseSections[i]['task']];
  }

  allUsecaseTasks = [...usecaseTasks];
  return allUsecaseTasks;
};

export const arrangeTasksBasedOnPriority = (tasks: any) => {
  const redTasks = tasks.filter((task: any) => {
    return task.task_stage == 'Blocked' || task.task_stage == 'Not Started';
  });

  const greenTasks = tasks.filter((task: any) => {
    return task.task_stage == 'In Progress';
  });

  const blueTasks = tasks.filter((task: any) => {
    return task.task_stage == 'Done';
  });

  const orderedTasks = [...redTasks, ...greenTasks, ...blueTasks];
  return orderedTasks;
};

export const selectProjectFromGetProjectInformationWithCaseTaskAPI = (projects: any, selectedProjectUid: string) => {
  const project = projects.filter((p: any) => p.project_uid == selectedProjectUid);
  console.log(project);
};

export const getTeamTasks = (tasks: any[]) => {
  const teamTasks = tasks.filter((task: any) => task.is_team_task != '1');
  return teamTasks;
};

export const getClientTasks = (tasks: any[]) => {
  const clientTasks = tasks.filter((task: any) => task.is_team_task != '0');
  return clientTasks;
};

export const projectStageValues = [
  'Not Started',
  'On Hold',
  'Pre-Sales',
  'Discovery',
  'Design',
  'Development',
  'UAT',
  'Go-Live',
  'Adoption',
];

export const projectPriorityValues = ['Critical', 'High', 'Medium', 'Low'];

export const makeDate = (date: string) => {
  return new Date(date.split(' ')[0]);
};

export const transformDataToHaveCustomFields = (
  data: any[],
  customFieldsData: any[],
  uidKeyToMatch: string,
  customFieldConfigFields: any[]
) => {
  console.log(data, customFieldsData);
  const allData: any = [];
  for (let i = 0; i < data.length; i++) {
    let obj: any = {};
    const newCustomFields: any = [];
    for (let j = 0; j < customFieldsData.length; j++) {
      if (customFieldsData[j][uidKeyToMatch] == data[i][uidKeyToMatch]) {
        newCustomFields.push(customFieldsData[j]);
      }
    }
    // adding custom fields which are missing
    for (let k = 0; k < customFieldConfigFields.length; k++) {
      const foundField = newCustomFields.filter(
        (item: any) => item.field_name === customFieldConfigFields[k].field_name
      );
      if (foundField.length === 0) {
        newCustomFields.push({ ...customFieldConfigFields[k], field_value: '' });
      }
    }
    console.log(newCustomFields.length);
    if (newCustomFields.length === 16) {
      console.log('why less', newCustomFields, customFieldConfigFields);
    }
    obj = { ...data[i], custom_values: [...newCustomFields] };
    allData.push(obj);
  }
  console.log(allData);
  return allData;
};
// function stringToHslColor(str: string, s: number, l: number) {
//   let hash = 0;
//   for (let i = 0; i < str.length; i++) {
//     hash = str.charCodeAt(i) + ((hash << 5) - hash);
//   }

//   const h = hash % 360;
//   return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
// }

// function stringAvatar(name: string) {
//   return {
//     sx: {
//       bgcolor: stringToHslColor(name, 30, 80),
//     },
//     children: (
//       <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#000000' }}>
//         {name.split(' ')[0][0]}
//         {name.indexOf(' ') >= 0 ? name.split(' ')[1][0] : null}
//       </Typography>
//     ),
//   };
// }
