import { useState, useEffect } from 'react';

import { Grid, Typography, FormControl, Select, SelectChangeEvent, MenuItem } from '@mui/material';

import { ConditionDetailProps } from 'components/RBACv2/interfaces';
import { operatorType } from 'components/RBACv2/types';
import { operatorMapping } from 'components/RBACv2/utils/filters';
import CognisaasSelect from 'components/shared/CognisaasSelect/CognisaasSelect';

export default function ConditionDetail(props: ConditionDetailProps) {
  const [resourceField, setResourceField] = useState<string>('');
  const [selectedOperator, setSelectedOperator] = useState<operatorType>('' as operatorType);
  const [valueField, setValueField] = useState<string>('');

  const getResourceFieldName = () => {
    const conditional_key = JSON.parse(props.conditionData?.conditional_key);
    const conditional_value = JSON.parse(props.conditionData?.conditional_value);
    const resourceField = props.resourcesFieldObject?.find(
      (resource: any) => resource?.name === conditional_key?.keyName
    );
    const resourceFieldValue = props.conditionResourceGeneralFieldValue?.find(
      (value: any) => value?.name === conditional_value?.value
    );
    setValueField(resourceFieldValue?.display_name || '');
    setResourceField(resourceField?.display_name || '');
    setSelectedOperator(props.conditionData?.operator || '');
  };

  useEffect(() => {
    getResourceFieldName();
  }, []);

  return (
    <>
      <Grid sx={{ display: 'flex', marginTop: '5px', alignItems: 'center' }}>
        <Typography variant="h2" marginRight="15px">
          Where <span className="label-text">{props.resourceName}</span>
        </Typography>
        <FormControl>
          <Select
            size="small"
            value={resourceField}
            onChange={(e: SelectChangeEvent<string>) => setResourceField(e.target.value)}
            readOnly={props?.selectedPermission?.is_public || false}
          >
            {props.resourcesFieldObject?.map((obj: any) => {
              return (
                <MenuItem key={obj.id} value={obj?.display_name}>
                  <Typography variant="subtitle1">{obj?.display_name}</Typography>
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>

      <Grid sx={{ marginTop: '20px' }}>
        <Typography variant="h2" style={{ marginBottom: '10px' }}>
          Operator
        </Typography>
        <CognisaasSelect
          value={operatorMapping[selectedOperator]}
          validValues={['is', 'is not', 'is in', 'is not in', 'has', 'has not']}
          // disabledValues={selectedFieldType == 'ARR' ? ['is', 'is not', 'is in', 'is not in'] : ['has', 'has not']}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setSelectedOperator((e.target as HTMLInputElement).value as operatorType)
          }
          readOnly={props?.selectedPermission?.is_public || false}
        />
      </Grid>
      <Grid>
        <Typography margin="14px 0" variant={'h2'}>
          Value
        </Typography>
        <Grid sx={{ width: '150px', height: '70px' }}>
          <FormControl>
            <Select
              size="small"
              value={valueField}
              onChange={(e: SelectChangeEvent<string>) => setValueField(e.target.value)}
              readOnly={props?.selectedPermission?.is_public || false}
            >
              <Typography sx={{ color: '#3978FF', margin: '5px 8px' }} variant="subtitle1">
                General
              </Typography>
              {props.conditionResourceGeneralFieldValue?.map((fieldValue: any) => {
                return (
                  <MenuItem key={fieldValue.id} value={fieldValue?.display_name}>
                    <Typography variant="subtitle1">{fieldValue?.display_name}</Typography>
                  </MenuItem>
                );
              })}
              <Typography sx={{ color: '#3978FF', margin: '5px 8px' }} variant="h3">
                Auto Values
              </Typography>
              {props.conditionResourceFieldValue?.map((fieldValue: any) => {
                return (
                  <MenuItem key={fieldValue.id} value={fieldValue?.display_name}>
                    <Typography variant="subtitle1">{fieldValue?.display_name}</Typography>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
}
