import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { isNull } from '../../../../../src/utils/dataHelpers';
import { requestState } from '../../types';
import { AggregatedCustomReportAPI } from '../../../../api/modules/aggregatedCustomReports';

interface customReportType {
  getGeneratedAggregatedCustomReportUsingUid: {
    data: any;
    status: requestState;
    error: null | undefined | string;
  };

  getAllAggregatedCustomReports: {
    data: any;
    status: requestState;
    error: null | undefined | string;
  };

  getAggregatedCustomReportConfigByUid: {
    data: any;
    status: requestState;
    error: null | undefined | string;
  };

  createAggregatedCustomReport: {
    data: any;
    status: requestState;
    error: null | undefined | string;
  };

  updateAggregatedCustomReport: {
    data: any;
    status: requestState;
    error: null | undefined | string;
  };

  deleteAggregatedCustomReport: {
    data: any;
    status: requestState;
    error: null | undefined | string;
  };
}

const initialState: customReportType = {
  getGeneratedAggregatedCustomReportUsingUid: {
    data: [],
    status: requestState.idle,
    error: null,
  },

  getAllAggregatedCustomReports: {
    data: [],
    status: requestState.idle,
    error: null,
  },

  getAggregatedCustomReportConfigByUid: {
    data: [],
    status: requestState.idle,
    error: null,
  },

  createAggregatedCustomReport: {
    data: [],
    status: requestState.idle,
    error: null,
  },

  updateAggregatedCustomReport: {
    data: [],
    status: requestState.idle,
    error: null,
  },

  deleteAggregatedCustomReport: {
    data: [],
    status: requestState.idle,
    error: null,
  },
};

export const getGeneratedAggregatedCustomReportUsingUid = createAsyncThunk(
  'customReports/getGeneratedAggregatedCustomReportUsingUid',
  async (report_uid: string) => {
    const { data } = await AggregatedCustomReportAPI.getGeneratedAggregatedCustomReportUsingUid(report_uid);
    console.log(data);
    return data.result ?? [];
  }
);

export const getAllAggregatedCustomReports = createAsyncThunk(
  'customReports/getAllAggregatedCustomReports',
  async (details: any) => {
    const { data } = await AggregatedCustomReportAPI.getAllAggregatedCustomReports(details);
    return data.result ?? [];
  }
);

export const getAggregatedCustomReportConfigByUid = createAsyncThunk(
  'customReports/getAggregatedCustomReportConfigByUid',
  async (report_uid: string) => {
    const { data } = await AggregatedCustomReportAPI.getAggregatedCustomReportConfigByUid(report_uid);
    return data.result ?? [];
  }
);

export const createAggregatedCustomReport = createAsyncThunk(
  'report/createAggregatedCustomReport',
  async (detail: any) => {
    const { data } = await AggregatedCustomReportAPI.createAggregatedCustomReport(detail);
    return data ?? [];
  }
);

export const updateAggregatedCustomReport = createAsyncThunk(
  'report/updateAggregatedCustomReport',
  async (details: any) => {
    const { data } = await AggregatedCustomReportAPI.updateAggregatedCustomReport(details);
    return data ?? [];
  }
);

export const deleteAggregatedCustomReport = createAsyncThunk(
  'report/deleteAggregatedCustomReport',
  async (details: any) => {
    const { data } = await AggregatedCustomReportAPI.deleteAggregatedCustomReport(details);
    return data ?? [];
  }
);

const aggregatedCustomReportSlice = createSlice({
  name: 'aggregatedCustomReportSlice',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder

      // getGeneratedAggregatedCustomReportUsingUid
      .addCase(getGeneratedAggregatedCustomReportUsingUid.pending, (state) => {
        state.getGeneratedAggregatedCustomReportUsingUid.status = requestState.loading;
      })
      .addCase(getGeneratedAggregatedCustomReportUsingUid.fulfilled, (state, action) => {
        state.getGeneratedAggregatedCustomReportUsingUid.status = requestState.success;
        // console.log('action = ', action);
        if (isNull(action.payload)) state.getGeneratedAggregatedCustomReportUsingUid.data = '';
        else state.getGeneratedAggregatedCustomReportUsingUid.data = action.payload;
      })
      .addCase(getGeneratedAggregatedCustomReportUsingUid.rejected, (state, action) => {
        state.getGeneratedAggregatedCustomReportUsingUid.status = requestState.failed;
        state.getGeneratedAggregatedCustomReportUsingUid.error = action.error.message;
      })

      // getAllAggregatedCustomReports
      .addCase(getAllAggregatedCustomReports.pending, (state) => {
        state.getAllAggregatedCustomReports.status = requestState.loading;
      })
      .addCase(getAllAggregatedCustomReports.fulfilled, (state, action) => {
        state.getAllAggregatedCustomReports.status = requestState.success;
        state.getAllAggregatedCustomReports.data = action.payload;
      })
      .addCase(getAllAggregatedCustomReports.rejected, (state, action) => {
        state.getAllAggregatedCustomReports.status = requestState.failed;
        state.getAllAggregatedCustomReports.error = action.error.message;
      })

      // getAggregatedCustomReportConfigByUid
      .addCase(getAggregatedCustomReportConfigByUid.pending, (state) => {
        state.getAggregatedCustomReportConfigByUid.status = requestState.loading;
      })
      .addCase(getAggregatedCustomReportConfigByUid.fulfilled, (state, action) => {
        state.getAggregatedCustomReportConfigByUid.status = requestState.success;
        state.getAggregatedCustomReportConfigByUid.data = action.payload;
      })
      .addCase(getAggregatedCustomReportConfigByUid.rejected, (state, action) => {
        state.getAggregatedCustomReportConfigByUid.status = requestState.failed;
        state.getAggregatedCustomReportConfigByUid.error = action.error.message;
      })

      // createAggregatedCustomReport
      .addCase(createAggregatedCustomReport.pending, (state) => {
        state.createAggregatedCustomReport.status = requestState.loading;
      })
      .addCase(createAggregatedCustomReport.fulfilled, (state, action) => {
        state.createAggregatedCustomReport.status = requestState.success;
        state.createAggregatedCustomReport.data = action.payload;
      })
      .addCase(createAggregatedCustomReport.rejected, (state, action) => {
        state.createAggregatedCustomReport.status = requestState.failed;
        state.createAggregatedCustomReport.error = action.error.message;
      })

      // updateAggregatedCustomReport
      .addCase(updateAggregatedCustomReport.pending, (state) => {
        state.updateAggregatedCustomReport.status = requestState.loading;
      })
      .addCase(updateAggregatedCustomReport.fulfilled, (state, action) => {
        state.updateAggregatedCustomReport.status = requestState.success;
        state.updateAggregatedCustomReport.data = action.payload;
      })
      .addCase(updateAggregatedCustomReport.rejected, (state, action) => {
        state.updateAggregatedCustomReport.status = requestState.failed;
        state.updateAggregatedCustomReport.error = action.error.message;
      })

      // deleteAggregatedCustomReport
      .addCase(deleteAggregatedCustomReport.pending, (state) => {
        state.deleteAggregatedCustomReport.status = requestState.loading;
      })
      .addCase(deleteAggregatedCustomReport.fulfilled, (state, action) => {
        state.deleteAggregatedCustomReport.status = requestState.success;
        state.deleteAggregatedCustomReport.data = action.payload;
      })
      .addCase(deleteAggregatedCustomReport.rejected, (state, action) => {
        state.deleteAggregatedCustomReport.status = requestState.failed;
        state.deleteAggregatedCustomReport.error = action.error.message;
      });
  },
});

export const aggregatedCustomReportReducer = aggregatedCustomReportSlice.reducer;
export default aggregatedCustomReportReducer;
