import { useEffect, useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import { getTaskCustomfields } from 'store/modules/Task/slice';
import { transformCustomFieldDataForEntityStructure } from '../UtilityFunction';
import { taskEntityStandardFields } from './taskEntityStandardFields';

const taskEntity = () => {
  const dispatch = useAppDispatch();
  const [taskEntityWithCustomFields, setTaskEntityWithCustomFields] = useState<any[]>([]);

  useEffect(() => {
    getCustomFieldsOfTaskEntity();
  }, []);

  const getCustomFieldsOfTaskEntity = async () => {
    const taskCustomFields = await dispatch(getTaskCustomfields()).unwrap();
    setTaskEntityWithCustomFields({
      ...taskEntityStandardFields,
      ...transformCustomFieldDataForEntityStructure(taskCustomFields),
    });
  };
  console.log('taskEntityStandardFields  = ', taskEntityStandardFields);
  console.log('taskEntityWithCustomFields  = ', taskEntityWithCustomFields);
  return { taskEntityWithCustomFields };
};

export default taskEntity;
