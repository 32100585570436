import styled from 'styled-components';
import { ReactSVG } from 'react-svg';

export const StyledIcon = styled(ReactSVG)`
  div {
    margin: 0;
    padding: 0;
    & svg {
      fill: ${({ color }) => `${color}`};
      stroke: ${({ strokecolor }) => `${strokecolor}`};
      transform: rotate(${({ deg = 0 }) => `${deg}deg`});
    }
    &:hover {
      cursor: pointer;
    }
  }
`;
