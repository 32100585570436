import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { requestState } from '../types';
import { adminPanelAPI } from '../../../api/modules/adminPanel';


interface AdminPanelProps {
  adminLogin: {
    data: Record<string, any>;
    status: requestState;
    error: null | undefined | string;
  };
  allCustomerDetails: {
    data: Record<string, any>;
    status: requestState;
    error: null | undefined | string;
  };
  allClientCounts: {
    data: Record<string, any>;
    status: requestState;
    error: null | undefined | string;
  };
  allUserCount: {
    data: Record<string, any>;
    status: requestState;
    error: null | undefined | string;
  };
  allUserActivity: {
    data: Record<string, any>;
    status: requestState;
    error: null | undefined | string;
  };
  otpVerification: {
    data: Record<string, any>;
    status: requestState;
    error: null | undefined | string;
  };
  projectCount: {
    data: Record<string, any>;
    status: requestState;
    error: null | undefined | string;
  };
  usecaseCount: {
    data: Record<string, any>;
    status: requestState;
    error: null | undefined | string;
  };
  saveAdminToken: {
    data: Record<string, any>;
    status: requestState;
    error: null | undefined | string;
  };
  checkAdminToken: {
    data: Record<string, any>;
    status: requestState;
    error: null | undefined | string;
  };
  projectCountByUser: {
    data: Record<string, any>;
    status: requestState;
    error: null | undefined | string;
  };
  usecaseCountByUser: {
    data: Record<string, any>;
    status: requestState;
    error: null | undefined | string;
  };
}

const initialState: AdminPanelProps = {
  adminLogin: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  allCustomerDetails: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  allClientCounts: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  allUserCount: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  allUserActivity: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  otpVerification: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  projectCount: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  usecaseCount: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  projectCountByUser: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  usecaseCountByUser: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  saveAdminToken :{
    data: [],
    status: requestState.idle,
    error: null,
  },
  checkAdminToken :{
    data: [],
    status: requestState.idle,
    error: null,
  },
};

export const getAllCustomerDetails_slice = createAsyncThunk('getAllCustomerDetails', async () => {
  const {data} = await adminPanelAPI.getAllCustomerDetails();
  return data.result;
});

export const getAllClientCounts_slice = createAsyncThunk('getAllClientCounts', async () => {
  const {data} = await adminPanelAPI.getAllClientCounts();
  return data.result;
});

export const getUserCount_slice = createAsyncThunk('getUserCount', async () => {
  const {data} = await adminPanelAPI.getUserCount();
  return data.result;
});

export const getAllUserActivity_slice = createAsyncThunk('getAllUserActivity', async () => {
  const {data} = await adminPanelAPI.getAllUserActivity();
  return data.result;
});

export const deleteCustomer_slice = createAsyncThunk('deleteCustomer', async (customer_uid: string) => {
  const {data} = await adminPanelAPI.deleteCustomer(customer_uid);
  return data.result;
});

interface loginPayload{
    'user_email': string,
    'user_password': string
}

export const adminLogin_slice = createAsyncThunk('adminLogin', async (payload: loginPayload) => {
  const {data} = await adminPanelAPI.adminLogin(payload);
  return data;
});

export const otpVerification_slice = createAsyncThunk('otpVerification', async (payload: any) => {
  const {data} = await adminPanelAPI.otpVerification(payload);    
  return data;
});

export const getProjectCount_slice = createAsyncThunk('getProjectCount', async () => {
  const {data} = await adminPanelAPI.getProjectCount();
  return data.result;
});

export const getUsecaseCount_slice = createAsyncThunk('getUsecaseCount', async () => {
  const {data} = await adminPanelAPI.getUsecaseCount();
  return data.result;
});

export const getProjectCountByUser_slice = createAsyncThunk('getProjectCountByUser', async () => {
  const {data} = await adminPanelAPI.getProjectCountByUsers();
  return data.result;
});

export const getUsecaseCountByUser_slice = createAsyncThunk('getUsecaseCountByUser', async () => {
  const {data} = await adminPanelAPI.getUsecaseCountByUsers();
  return data.result;
});

interface saveToken_payload{
  'jwt_token': string,
  'admin_name': string
}
// export const saveAdminToken_slice = createAsyncThunk('saveAdminToken', async (payload: saveToken_payload) => {
//   const {data} = await adminPanelAPI.saveAdminToken(payload);
//   return data;
// });

export const saveAdminToken_slice = createAsyncThunk('saveAdminToken', async (payload: saveToken_payload) => {
  const {data} = await adminPanelAPI.saveAdminToken(payload);
  return data;
});

export const checkAdminToken_slice = createAsyncThunk('checkAdminToken', async (payload: saveToken_payload) => {
  const {data} = await adminPanelAPI.checkAdminToken(payload);
  return data;
});


export const adminPanelSlice = createSlice({
  name: 'admin panel data',
  initialState,
  reducers: {
    //without api calls
  },
  extraReducers(builder) {
    builder
    // admin login
      .addCase(adminLogin_slice.pending, (state) => {
        state.adminLogin.status = requestState.loading;
      })
      .addCase(adminLogin_slice.fulfilled, (state: any, action: PayloadAction<any>) => {
        state.adminLogin.status = requestState.success;
        state.adminLogin.data = [action.payload];
      })
      .addCase(adminLogin_slice.rejected, (state, action: any) => {
        state.adminLogin.status = requestState.failed;
        state.adminLogin.error = action.error.message;
      })
    // otpVerification_slice
     .addCase(otpVerification_slice.pending, (state) => {
        state.otpVerification.status = requestState.loading;
      })
      .addCase(otpVerification_slice.fulfilled, (state: any, action: PayloadAction<any>) => {
        state.otpVerification.status = requestState.success;
        state.otpVerification.data = [action.payload];
      })
      .addCase(otpVerification_slice.rejected, (state, action: any) => {
        state.otpVerification.status = requestState.failed;
        state.otpVerification.error = action.error.message;
      })
      // get all customer details
      .addCase(getAllCustomerDetails_slice.pending, (state) => {
        state.allCustomerDetails.status = requestState.loading;
      })
      .addCase(getAllCustomerDetails_slice.fulfilled, (state, action: PayloadAction<any>) => {
        state.allCustomerDetails.status = requestState.success;
        state.allCustomerDetails.data = [...action.payload];
      })
      .addCase(getAllCustomerDetails_slice.rejected, (state, action: any) => {
        state.allCustomerDetails.status = requestState.failed;
        state.allCustomerDetails.error = action.error.message;
      })
      //  get all client counts
      .addCase(getAllClientCounts_slice.pending, (state) => {
        state.allClientCounts.status = requestState.loading;
      })
      .addCase(getAllClientCounts_slice.fulfilled, (state, action: PayloadAction<any>) => {
        state.allClientCounts.status = requestState.success;
        state.allClientCounts.data = [...action.payload];
      })
      .addCase(getAllClientCounts_slice.rejected, (state, action: any) => {
        state.allClientCounts.status = requestState.failed;
        state.allClientCounts.error = action.error.message;
      })
      //  get all user counts
      .addCase(getUserCount_slice.pending, (state) => {
        state.allUserCount.status = requestState.loading;
      })
      .addCase(getUserCount_slice.fulfilled, (state, action: PayloadAction<any>) => {
        state.allUserCount.status = requestState.success;
        state.allUserCount.data = [...action.payload];
      })  
      .addCase(getUserCount_slice.rejected, (state, action: any) => {
        state.allUserCount.status = requestState.failed;
        state.allUserCount.error = action.error.message;
      })
      //  get all user activities
      .addCase(getAllUserActivity_slice.pending, (state) => {
        state.allUserActivity.status = requestState.loading;
      })
      .addCase(getAllUserActivity_slice.fulfilled, (state, action: PayloadAction<any>) => {
        state.allUserActivity.status = requestState.success;
        state.allUserActivity.data = [...action.payload];
      })  
      .addCase(getAllUserActivity_slice.rejected, (state, action: any) => {
        state.allUserActivity.status = requestState.failed;
        state.allUserActivity.error = action.error.message;
      })
      //  get project counts
      .addCase(getProjectCount_slice.pending, (state) => {
        state.projectCount.status = requestState.loading;
      })
      .addCase(getProjectCount_slice.fulfilled, (state, action: PayloadAction<any>) => {
        state.projectCount.status = requestState.success;
        state.projectCount.data = [...action.payload];
      })  
      .addCase(getProjectCount_slice.rejected, (state, action: any) => {
        state.projectCount.status = requestState.failed;
        state.projectCount.error = action.error.message;
      })
      //  get usecase counts
      .addCase(getUsecaseCount_slice.pending, (state) => {
        state.usecaseCount.status = requestState.loading;
      })
      .addCase(getUsecaseCount_slice.fulfilled, (state, action: PayloadAction<any>) => {
        state.usecaseCount.status = requestState.success;
        state.usecaseCount.data = [...action.payload];
      })  
      .addCase(getUsecaseCount_slice.rejected, (state, action: any) => {
        state.usecaseCount.status = requestState.failed;
        state.usecaseCount.error = action.error.message;
      })
      //  check admin token
      .addCase(checkAdminToken_slice.pending, (state) => {
        state.checkAdminToken.status = requestState.loading;
      })
      .addCase(checkAdminToken_slice.fulfilled, (state, action: PayloadAction<any>) => {
        state.checkAdminToken.status = requestState.success;
        state.checkAdminToken.data = [action.payload];
      })  
      .addCase(checkAdminToken_slice.rejected, (state, action: any) => {
        state.checkAdminToken.status = requestState.failed;
        state.checkAdminToken.error = action.error.message;
      })
      //  save admin token
      .addCase(saveAdminToken_slice.pending, (state) => {
        state.saveAdminToken.status = requestState.loading;
      })
      .addCase(saveAdminToken_slice.fulfilled, (state, action: PayloadAction<any>) => {
        state.saveAdminToken.status = requestState.success;
        state.saveAdminToken.data = [action.payload];
      })  
      .addCase(saveAdminToken_slice.rejected, (state, action: any) => {
        state.saveAdminToken.status = requestState.failed;
        state.saveAdminToken.error = action.error.message;
      })
      //  get project count by user
      .addCase(getProjectCountByUser_slice.pending, (state) => {
        state.projectCountByUser.status = requestState.loading;
      })
      .addCase(getProjectCountByUser_slice.fulfilled, (state, action: PayloadAction<any>) => {
        state.projectCountByUser.status = requestState.success;
        state.projectCountByUser.data = [action.payload];
      })  
      .addCase(getProjectCountByUser_slice.rejected, (state, action: any) => {
        state.projectCountByUser.status = requestState.failed;
        state.projectCountByUser.error = action.error.message;
      })
      //  get usecase count by user
      .addCase(getUsecaseCountByUser_slice.pending, (state) => {
        state.usecaseCountByUser.status = requestState.loading;
      })
      .addCase(getUsecaseCountByUser_slice.fulfilled, (state, action: PayloadAction<any>) => {
        state.usecaseCountByUser.status = requestState.success;
        state.usecaseCountByUser.data = [action.payload];
      })  
      .addCase(getUsecaseCountByUser_slice.rejected, (state, action: any) => {
        state.usecaseCountByUser.status = requestState.failed;
        state.usecaseCountByUser.error = action.error.message;
      });
  },
});

const adminPanelReducer = adminPanelSlice.reducer;
export default adminPanelReducer;
