import { Button, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

interface TabTouchablePropTypes {
  label: string;
  active: boolean;
  icon: ReactNode;
  redirectTo: string;
}

const TabTouchable = (props: TabTouchablePropTypes) => {
  const navigate = useNavigate();

  return (
    <Button
      sx={{
        background: props.active
          ? 'linear-gradient(90deg, rgba(45, 96, 246, 0.1875) 5.37%, rgba(45, 97, 246, 0.0875) 80.5%)'
          : 'transparent',
        color: props.active ? '#2D60F6' : '#000000',
        width: '100%',
        justifyContent: 'left',
        maxWidth: '250px',
        borderRadius: '4px',
        minWidth: '160px',
      }}
      onClick={() => {
        navigate(props.redirectTo);
      }}
      startIcon={props.icon}
    >
      <Typography sx={{ marginLeft: '8px' }} variant={'h3'}>
        {props.label}
      </Typography>
    </Button>
  );
};

export default TabTouchable;
