import { Container, Image } from './Avatar.styles';

interface AvatarProps {
    alt:string;
    src:string;
    height?: string;
    width?: string;
}

const Avatar = (props: AvatarProps) => {
    return (
        <Container height={props.height || '40px' } width={props.width || '40px'}>
            <Image className='is_defined' src={props.src} alt={props.alt}/>
        </Container>
    );
};


export default Avatar;