import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import { IconButton } from '@mui/material';
import { useState } from 'react';
import AlterAggregationCardDrawer from './AlterAggregationCardDrawer';

interface Props {
  idName: string;
  sampleData: Record<string, any>;
}

const AddAggregationCardButton = (props: Props) => {
  const [openAlterAggregationCardDrawer, setOpenAlterAggregationCardDrawer] = useState<boolean>(false);
  return (
    <>
      <IconButton size="small" onClick={() => setOpenAlterAggregationCardDrawer(true)}>
        <DashboardCustomizeOutlinedIcon />
      </IconButton>

      <AlterAggregationCardDrawer
        idName={props.idName}
        open={openAlterAggregationCardDrawer}
        setOpen={setOpenAlterAggregationCardDrawer}
        sampleData={props.sampleData}
      />
    </>
  );
};

export default AddAggregationCardButton;
