import { useState, useEffect } from 'react';
import { Typography, IconButton, Stack, Grid, Box, Avatar, FormControl, TextField } from '@mui/material';
import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { format, parseISO, add, differenceInDays, addDays } from 'date-fns';
import { ReactComponent as NoRecords } from '../../../assets/svgs/no_records.svg';
import CognisaasTaskStageIndicator from '../../shared/CognisaasTaskStageIndicator/CognisaasTaskStageIndicator';
import { ReactComponent as Info } from '../../../assets/svgs/info.svg';
import CognisaaasTooltip from '../../shared/CognisaasTooltip/CognisaasTooltip';
import { getUserNameByUserUid, getLoggedInUserUid } from '../../../utils/getUid';
import UpdateTaskDrawer from '../../TaskModule/TaskView/UpdateTaskDrawer';
import { label_project, label_task } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { setUpFiltersForClientListPage } from '../../../store/modules/ui/Filters/slice';
// import { useDispatch } from 'react-redux';
import { useAppDispatch } from 'store/hooks';
import { getCustomerUid } from '../../../utils/getUid';
import { getTaskByTaskUid, getTasksOfUser } from '../../../store/modules/Task/slice';

function stringToHslColor(str: string, s: number, l: number) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const h = hash % 360;
  return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToHslColor(name, 30, 80),
    },
    children: (
      <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#000000' }}>
        {name.split(' ')[0][0]}
        {name.indexOf(' ') >= 0 ? name.split(' ')[1][0] : null}
      </Typography>
    ),
  };
}

const DeliverablesTimeline = (props: any) => {
  const { tasks } = props;
  const dispatch = useAppDispatch();
  const [updateTaskDrawer, setUpdateTaskDrawer] = useState<boolean>(false);
  const [taskForDrawer, setTaskForDrawer] = useState<any>(null);
  const [value, setValue] = React.useState<any>([new Date(), new Date()]);
  const [showDatePicker, setShowDatePicker] = React.useState(false);
  const [selectedFilter, setSelectedFilter] = React.useState('This Week');
  const [filteredTasks, setFilteredTasks] = React.useState([]);
  const [noOfDaysBetween, setNoOfDaysBetween] = React.useState(0);

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedFilter(event.target.value as string);
  };

  useEffect(() => {
    dispatch(setUpFiltersForClientListPage());
  }, []);

  useEffect(() => {
    filterTasks();
  }, [tasks]);

  useEffect(() => {
    filterTasks();
  }, [value, noOfDaysBetween]);

  useEffect(() => {
    let durationBetweenDates = 0;

    durationBetweenDates = differenceInDays(value[1], value[0]);
    setNoOfDaysBetween(durationBetweenDates);
  }, [value]);

  useEffect(() => {
    filterTasks();
    if (selectedFilter == 'Select Period') setShowDatePicker(true);
    else setShowDatePicker(false);
  }, [selectedFilter]);

  useEffect(() => {
    const details = {
      user_uid: getLoggedInUserUid(),
      customer_uid: getCustomerUid(),
    };
    dispatch(getTasksOfUser(details));
  }, []);

  const filterTasks = () => {
    if (tasks && tasks.length > 0) {
      if (selectedFilter == 'This Week') {
        const tempArr: any = [];
        for (let i = 0; i < 7; i++) {
          // console.log(i);
          tasks.map((task: any) => {
            if (
              format(new Date(task.task_due_date.replace(/-/g, '/')), 'dd MMM yyyy') ==
              format(add(new Date(), { days: i }), 'dd MMM yyyy')
            ) {
              tempArr.push(task);
            }
          });
        }
        setFilteredTasks(tempArr);
      }
      if (selectedFilter == 'This Month') {
        const tempArr: any = [];
        for (let i = 0; i < 30; i++) {
          tasks.map((task: any) => {
            if (
              format(parseISO(task.task_due_date), 'dd MMM yyyy') == format(add(new Date(), { days: i }), 'dd MMM yyyy')
            ) {
              tempArr.push(task);
            }
          });
        }
        setFilteredTasks(tempArr);
      }
      if (selectedFilter == 'Next Month') {
        const tempArr: any = [];
        for (let i = 29; i < 60; i++) {
          tasks.map((task: any) => {
            if (
              format(parseISO(task.task_due_date), 'dd MMM yyyy') == format(add(new Date(), { days: i }), 'dd MMM yyyy')
            ) {
              tempArr.push(task);
            }
          });
        }
        setFilteredTasks(tempArr);
      }
      if (selectedFilter == 'Select Period') {
        const tempArr: any = [];

        for (let i = 0; i < noOfDaysBetween; i++) {
          tasks.map((task: any) => {
            if (
              format(parseISO(task.task_due_date), 'dd MMM yyyy') == format(add(value[0], { days: i }), 'dd MMM yyyy')
            ) {
              tempArr.push(task);
            }
          });
        }
        setFilteredTasks(tempArr);
      }
    }
  };

  const openTaskDrawer = async (task: any) => {
    const taskResult = await dispatch(getTaskByTaskUid(task.task_uid)).unwrap();
    setTaskForDrawer({ ...task, client_uid: taskResult.client_uid });
    setUpdateTaskDrawer(!updateTaskDrawer);
  };

  const showTodayAndTomorrowsDeliverables = () => {
    if (filteredTasks && filteredTasks.length == 0) {
      return (
        <>
          <Typography mt={1} variant="subtitle1" alignSelf="center">
            No deliverables in this time period
            {/* {selectedFilter == 'This Week'
              ? ' next 7 days'
              : selectedFilter == 'This Month'
              ? ' next 30 days'
              : selectedFilter == 'Next Month'
              ? ' later than 30 days'
              : selectedFilter == 'Select Period'
              ? ' this selected period'
              : ' selected filter'} */}
          </Typography>
          <Stack justifyContent="center" alignItems="center">
            <NoRecords style={{ marginLeft: '6px', marginTop: '10px', width: '150px', height: '150px' }}></NoRecords>
          </Stack>
        </>
      );
    }
    return (
      <>
        <Stack direction="row" spacing={2} sx={{ marginTop: '12px', alignItems: 'center' }}>
          {
            selectedFilter == 'Select Period' ? (
              <Typography variant="subtitle1">
                {label_task}s due between {format(value[0], 'dd MMM yyyy')} - {format(value[1], 'dd MMM yyyy')}{' '}
              </Typography>
            ) : selectedFilter == 'This Week' ? (
              <Typography variant="subtitle1">
                {label_task}s due between {format(new Date(), 'dd MMM yyyy')} -{' '}
                {format(addDays(new Date(), 6), 'dd MMM yyyy')}{' '}
              </Typography>
            ) : selectedFilter == 'This Month' ? (
              <Typography variant="subtitle1">
                {label_task}s due between {format(new Date(), 'dd MMM yyyy')} -{' '}
                {format(addDays(new Date(), 30), 'dd MMM yyyy')}{' '}
              </Typography>
            ) : selectedFilter == 'Next Month' ? (
              <Typography variant="subtitle1">
                {label_task}s due after {format(addDays(new Date(), 30), 'dd MMM yyyy')}
              </Typography>
            ) : (
              <></>
            )
            //  :
            // (
            //   <>
            //     <Typography variant="h3">Today</Typography>
            //     <Typography className="label-text" variant="subtitle1">
            //       {format(new Date(selectedDate), 'dd MMM yyyy')}
            //     </Typography>
            //   </>
            // )
          }
        </Stack>
        {filteredTasks &&
          filteredTasks.map((task: any, index: number) => {
            if (task.milestone_task == 1)
              return (
                <div key={index}>
                  <Stack justifyContent="flex-start" spacing={2} direction="row" sx={{ marginTop: '8px' }}>
                    <Grid container justifyContent="space-between">
                      <Grid item xs={2}>
                        <Box sx={{ marginTop: '5px' }}>
                          <CognisaasTaskStageIndicator
                            isMileStoneTask={task.milestone_task != '0'}
                            stage={task.task_stage}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={8}>
                        <Stack direction="column">
                          <Typography variant="subtitle1">{task.task_name}</Typography>
                          <Typography className="label-text" variant="subtitle1">
                            {task.task_stage}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={2}>
                        {
                          !updateTaskDrawer && (
                            <CognisaaasTooltip
                            title={
                              <Box sx={{ padding: '8px 12px' }}>
                                {task.case_uid === null ? (
                                  <Typography className="label-text" variant="subtitle1">
                                    {label_project} {label_task.toLowerCase()}
                                  </Typography>
                                ) : (
                                  <Typography className="label-text" variant="subtitle1">
                                    Use Case
                                  </Typography>
                                )}
                                <Typography
                                  className="cognisaas-pointer"
                                  onClick={() => openTaskDrawer(task)}
                                  sx={{ fontSize: '14px', color: '#2D60F6', mt: '0px' }}
                                >
                                  {task.task_name}
                                </Typography>
                                <Stack direction="row" sx={{ marginTop: '12px' }} spacing={2}>
                                  <Typography variant="h3" sx={{ marginTop: '3px' }}>
                                    {label_task} Due On
                                  </Typography>
                                  <Typography className="label-text" variant="subtitle1">
                                    {format(new Date(task.task_due_date.replace(/-/g, '/')), 'dd MMM yyyy')}
                                  </Typography>
                                </Stack>
                                <Stack direction="row">
                                  <Avatar
                                    style={{ height: '30px', width: '30px', marginTop: '12px' }}
                                    {...stringAvatar(getUserNameByUserUid(task.task_owner))}
                                  />
                                  <Box sx={{ mt: '12px', ml: '7px' }}>
                                    <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#7A7A7A' }}>
                                      {label_task} Owner
                                    </Typography>
                                    <Typography sx={{ fontSize: '12px', fontWeight: '400' }}>
                                      {getUserNameByUserUid(task.task_owner)}
                                    </Typography>
                                  </Box>
                                </Stack>
                              </Box>
                            }
                          >
                            <IconButton disableRipple size="small">
                              <Info />
                            </IconButton>
                          </CognisaaasTooltip>
                          )
                        }
                       
                      </Grid>
                    </Grid>
                  </Stack>
                </div>
              );
          })}
      </>
    );
  };

  return (
    <>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography sx={{ paddingTop: '3px', paddingBottom: '0px' }} variant="h2">
            Deliverables Timeline
          </Typography>
        </Grid>

        <Grid
          item
          container
          style={{ backgroundColor: 'rgba(45, 96, 246,0.1)', borderRadius: '15px', marginTop: '10px' }}
          justifyContent="center"
        >
          <Typography sx={{ ml: '8px', fontSize: '12px', fontWeight: '400', color: '#7A7A7A', mt: '5px' }}>
            Timeline:
          </Typography>
          <FormControl variant="standard">
            <Select
              // id="demo-simple-select"
              value={selectedFilter}
              onChange={handleChange}
              size="small"
              disableUnderline
              sx={{ ml: '10px' }}
            >
              <MenuItem value={'This Week'}>Next 7 days</MenuItem>
              <MenuItem value={'This Month'}>Next 30 days</MenuItem>
              <MenuItem value={'Next Month'}>Later than 30 days</MenuItem>
              <MenuItem value={'Select Period'}>
                <Stack direction="row">
                  <Typography>Select Period</Typography>
                </Stack>
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {/* </Stack> */}

        {showDatePicker ? (
          <Box style={{ marginTop: '15px' }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateRangePicker
                calendars={1}
                value={value}
                onChange={(newValue) => {
                  const tempArr = [newValue[0], newValue[1]];
                  setValue(tempArr);
                }}
                renderInput={(startProps, endProps) => (
                  <React.Fragment>
                    <TextField size="small" sx={{ maxWidth: '138px', padding: '5px' }} {...startProps} />
                    <Typography sx={{ fontSize: '12px', fontWeight: '500', color: '#7A7A7A' }}> to </Typography>
                    <TextField size="small" sx={{ maxWidth: '138px', padding: '5px' }} {...endProps} />
                  </React.Fragment>
                )}
              />
            </LocalizationProvider>
          </Box>
        ) : null}
      </Grid>

      {/* <Stack direction="row" spacing={2}> */}
      <Stack sx={{ marginTop: '0px', height: '300px', overflowY: 'scroll' }}>
        {showTodayAndTomorrowsDeliverables()}
      </Stack>
      {/* </Stack> */}
      {console.log('taskForDrawer: ', taskForDrawer)}
      {updateTaskDrawer && (
        <UpdateTaskDrawer
          client={{ client_uid: taskForDrawer.client_uid }}
          taskDetail={taskForDrawer}
          drawerState={updateTaskDrawer}
          onClose={() => setUpdateTaskDrawer(!updateTaskDrawer)}
        />
      )}
    </>
  );
};

export default DeliverablesTimeline;

{
  /* <DatePicker
customInput={
  <IconButton>
    <CalendarTodayIcon sx={{ height: '15px', width: '15px', color: '#2D60F6' }} />
  </IconButton>
}
selected={selectedDate}
dateFormat="yyyy MM dd"
onChange={(date: any) => setSelectedDate(new Date(date))}
highlightDates={[...highlightDates]}
/> */
}
