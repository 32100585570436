const MILLISECONDS_PER_DAY = 1000 * 60 * 60 * 24;
// a and b are javascript Date objects
export function dateDiffInDays(a: Date, b: Date) {
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / MILLISECONDS_PER_DAY);
}

export const convertMinutes = (num: number) => {
  if (num === 0) {
    return '0m';
  } else {
    let hour = Math.floor(num / 60); // 60 minutes in hour
    let day = Math.floor(hour / 8); // 8 hours a day office time
    const week = Math.floor(day / 5); // 5 days a week office time
    const minute = num - 60 * hour; // remaining minutes

    hour = hour - day * 8; // remaining hours
    day = day - week * 5; // remaining days

    let timeLogValue = ''; // Var to store Time Log Value

    if (week > 0) {
      timeLogValue = timeLogValue.concat(`${week}w `);
    }
    if (day > 0) {
      timeLogValue = timeLogValue.concat(`${day}d `);
    }
    if (hour > 0) {
      timeLogValue = timeLogValue = timeLogValue.concat(`${hour}h `);
    }
    if (minute > 0) {
      timeLogValue = timeLogValue.concat(`${minute}m `);
    }

    timeLogValue = timeLogValue.slice(0, -1); // To remove the last blank space

    return timeLogValue;
  }
};

export const groupByCustomKey = (targetList: any[], targetKey: string) => {
  const list = targetList;

  const result = list.reduce(function (sum: any, a: any) {
    sum[a[targetKey]] = sum[a[targetKey]] || [];
    sum[a[targetKey]].push(a);
    return sum;
  }, Object.create(null));

  return result;
};
