import { useEffect, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useAppSelector } from '../../../../store/hooks';
import { useDispatch } from 'react-redux';
import { selectAllFilters } from '../../../../store/modules/AllFilters/selector';
import FilterChip from '../../FilterChipv2/FilterChipv2';
import {
  updateFilterSelectedValue,
  resetFilterOfThisContext,
  updateFilter,
} from '../../../../store/modules/AllFilters/slice';
// import { selectMenuOptions } from '../../../../store/modules/ui/CommonData/selector';

interface SelectedGroupFilterListProps {
  idName: string;
  isResetEnabled?: boolean;
}
const SelectedGroupFilterList = (props: SelectedGroupFilterListProps) => {
  const allFilters = useAppSelector(selectAllFilters);
  // const menuOptions = useAppSelector(selectMenuOptions);
  const dispatch = useDispatch();
  const [groups, setGroups] = useState<string[]>([]);

  const handleResetFilters = () => {
    // reset filters
    dispatch(resetFilterOfThisContext({ idName: props.idName }));
  };

  const handleOptionClickedFromMenu = (value: string, filterName: string, filterDataKey: string) => {
    dispatch(
      updateFilterSelectedValue({
        idName: props.idName,
        selectedValue: value,
        filterName: filterName,
        filterDataKey: filterDataKey,
      })
    );
  };

  useEffect(() => {
    if (allFilters[props.idName]) {
      const groupSet = new Set();
      allFilters[props.idName]?.appliedFilters?.forEach((filter: any) => {
        if (filter.isSelected) {
          groupSet.add(filter.groupName);
        }
      });
      setGroups(Array.from(groupSet) as string[]);
    }
  }, [allFilters]);

  // console.log(menuOptions);
  // console.log('all filters', allFilters);

  const deleteThisFilter = (filter: { name: string; availableOptions: string[]; dataKey: string }) => {
    // console.log('deleting ..');

    dispatch(
      updateFilter({
        idName: props.idName,
        updatedFilter: {
          name: filter.name,
          dataKey: filter.dataKey,
          isSelected: false,
          equalToValue: [],
          canBeCleared: true,
          availableOptions: filter.availableOptions,
        },
      })
    );
  };

  return (
    <Stack>
      <Typography variant="subtitle1">Filters</Typography>
      {/* {console.log('after click on filter', allFilters)} */}
      <Stack
      // sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gridGap: '10px', flexWrap: 'wrap' }}
      >
        {groups.map((groupName: string, groupIndex: number) => {
          return (
            <Stack sx={{ marginBottom: '8px' }} key={groupIndex}>
              <Typography variant="subtitle2">{groupName}</Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  gridGap: '10px',
                  flexWrap: 'wrap',
                }}
              >
                {allFilters[props.idName]
                  ? allFilters[props.idName].appliedFilters.map((filter, index) => {
                      if (filter.isSelected && filter.groupName === groupName) {
                        return (
                          <FilterChip
                            key={index}
                            dataKey={filter.dataKey}
                            name={filter.name}
                            checkedItemsForList={filter.equalToValue}
                            handleOptionClicked={handleOptionClickedFromMenu}
                            // options={menuOptions[filter.dataKey] ? menuOptions[filter.dataKey] : ['default']}
                            options={filter.availableOptions}
                            handleDeleteFilter={deleteThisFilter}
                            canBeCleared={filter.canBeCleared}
                          />
                        );
                      }
                    })
                  : ''}
              </Box>
            </Stack>
          );
        })}
        {/* {allFilters[props.idName]
          ? allFilters[props.idName].appliedFilters.map((filter, index) => {
              if (filter.isSelected) {
                return (
                  <FilterChip
                    key={index}
                    dataKey={filter.dataKey}
                    name={filter.name}
                    checkedItemsForList={filter.equalToValue}
                    handleOptionClicked={handleOptionClickedFromMenu}
                    // options={menuOptions[filter.dataKey] ? menuOptions[filter.dataKey] : ['default']}
                    options={filter.availableOptions}
                    handleDeleteFilter={deleteThisFilter}
                    canBeCleared={filter.canBeCleared}
                  />
                );
              }
            })
          : ''} */}
      </Stack>
      {!!props?.isResetEnabled && (
        <Typography variant="subtitle2" className="link-text cognisaas-pointer" onClick={handleResetFilters}>
          Reset Filters
        </Typography>
      )}
    </Stack>
  );
};

export default SelectedGroupFilterList;
