import * as React from 'react';
import { useState } from 'react';
import { FormControl, TextField, Typography, Button, Stack, CircularProgress, Backdrop } from '@mui/material';
import { Formik } from 'formik';
import Styles from '../../../../ManageClient/OnboardNewAccount/OnboardNewAccount.module.scss';
import CognisaasButton from '../../../../shared/CognisaasButton/CognisaasButton';
import { addUsecaseSchema } from '../../../../../utils/validations';
import CognisaasSelect from '../../../../shared/CognisaasSelect/CognisaasSelect';
import { selectCustomerDetail } from '../../../../../store/modules/Common/selector';
import { getAllUsers } from '../../../../../store/modules/Common/slice';
import { useAppSelector } from '../../../../../store/hooks';
import { useEffect } from 'react';
// import { useDispatch } from 'react-redux';
import { useAppDispatch } from '../../../../../store/hooks';
import CognisaasCalenderNew from '../../../../shared/CognisaasCalenderNew/CognisaasCalenderNew';
import { fetchClientList } from '../../../../../store/modules/ManageClient/ClientList/slice';
import { format, add } from 'date-fns';
import { getLoggedInUserName, getLoggedInUserUid, getUserUid } from '../../../../../utils/getUid';
// import { projectStageValues } from '../../../utils';
import { CognisaaasTooltip } from '../../../../shared/CognisaasTooltip/CognisaasTooltip';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
// import { sendTaskAssignmentMailImportingTemplate } from '../../../../../store/modules/Templates/Templates';
import { getUseCaseTemplatesbycustomer } from '../../../../../store/modules/Templates/Templates';
import {
  selectTemplateprojectInformation,
  selectUsecaseTemplates,
} from '../../../../../store/modules/Templates/selector';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
// import { selectCreatingUsecaseFromUsecaseTemplate } from 'store/modules/ManageClient/OnboardFormValues/selector';
import { createUsecaseFromUsecaseTemplate } from '../../../../../store/modules/ManageClient/OnboardFormValues/slice';
import getSymbolFromCurrency from 'currency-symbol-map';
import CognisaasUserSelect from 'components/shared/CognisaasSelect/CognisaasUserSelect';
import useUserDropDownData from 'components/shared/Hooks/useUserDropDownData';
import {
  label_arr,
  label_delivery_date,
  label_due_date,
  label_use_case,
  label_use_case_delivery_manager,
} from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { useNextDayIfItIsHoliday } from 'components/shared/Hooks/useNextDayIfItIsHoliday';
import useCustomNames from 'components/shared/CustomNames/useCustomNames';
import NAMED_CONSTATNTS from 'components/shared/CustomNames/Constants';

const AddNewUsecase = () => {
  const dispatch = useAppDispatch();
  const params: any = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const initialValues = {
    clientName: '',
    usecaseTemplate: '',
    usecaseName: '',
    priority: 'Medium',
    category: 'Land',
    deliveryManager: {
      id: '15243',
      name: 'Not Assigned',
    },
    implementationFee: 0,
    projectDescription: '',
    comment: '',
    plannedStartDate: useNextDayIfItIsHoliday(new Date()),
    actualStartDate: useNextDayIfItIsHoliday(new Date()),
    dueDate: useNextDayIfItIsHoliday(new Date()),
    etaForDeliveryDate: useNextDayIfItIsHoliday(new Date()),
    usecaseStage: 'Pre-Sales',
    deliveryDate: useNextDayIfItIsHoliday(new Date()),
    link: '',
    case_ARR: 0,
    expandARR: 0,
  };
  // const projectInfo = useAppSelector(selectProjectInformation);
  // const [usersOfCompany, setUsersOfCompany] = useState<any[]>([]);
  // const allUsers = useAppSelector(selectUsersOfCompany);
  const projectTemplates = useAppSelector(selectUsecaseTemplates);
  const templateProjectInfo = useAppSelector(selectTemplateprojectInformation);
  // const newUsecaseData = useAppSelector(selectCreatingUsecaseFromUsecaseTemplate);
  const customerDetail = useAppSelector(selectCustomerDetail);
  const [allProjectTemplates, setAllProjectTemplates] = useState<any[]>([]);
  const [templateList, setTemplateList] = useState<any[]>([]);
  const [isSaveInProgress, setIsSaveInProgress] = useState<boolean>(false);
  // const [selectedProjectTemplate, setSelectedProjectTemplate] = useState<string>('');
  const [importInProgress, setImportInProgress] = useState<boolean>(false);
  const { projectName, clientName } = location.state as any;
  const [arrFieldValue] = useState(0);
  const companyUsersDropDownData = useUserDropDownData('company');
  const {
    usecaseCategoryDropdownValues,
    usecaseStagesDropdownValues,
    getStandardNameMappedValue,
    getCustomNameMappedValue,
    usecasePrioritiesDropdownValues,
  } = useCustomNames();
  const usecaseCategories = usecaseCategoryDropdownValues.map((item: any) => item.custom_category_name);
  const usecaseStages = usecaseStagesDropdownValues.map((item: any) => item.custom_stage_name);
  const usecasePriorities = usecasePrioritiesDropdownValues.map((item: any) => item.custom_priority_name);

  useEffect(() => {
    dispatch(fetchClientList());
    dispatch(getAllUsers());
    // addBlankUC();
  }, []);

  useEffect(() => {
    if (projectTemplates.length > 0) {
      setAllProjectTemplates([...projectTemplates]);
      setDataForTemplatesDropDown(projectTemplates);
      console.log(projectTemplates);
    } else {
      dispatch(getUseCaseTemplatesbycustomer());
    }
  }, [projectTemplates]);

  useEffect(() => {
    if (templateProjectInfo) {
      console.log(templateProjectInfo);
    }
  }, [templateProjectInfo]);

  const setDataForTemplatesDropDown = (templates: any) => {
    const template = templates.map((t: any) => t.template_case_name);
    console.log(template);
    setTemplateList([...template]);
  };

  const importThisTemplate = async (name: any, setFieldValue: any) => {
    setImportInProgress(true);

    console.log(name, setFieldValue);
    // setSelectedProjectTemplate(name);
    const temp: any = allProjectTemplates.filter((t: any) => t.template_case_name == name);
    console.log(temp);

    setFieldValue('importedTemplateProjectUid', temp[0].template_case_uid);
    setFieldValue('usecaseTemplate', name);
    // setFieldValue('usecaseName', temp[0].template_case_name);
    setFieldValue('deliveryManager', {
      id: '15243',
      name: 'Not Assigned',
    });
    setFieldValue('implementationFee', temp[0].template_fees ? temp[0].template_fees : 0);
    setFieldValue('dueDate', add(new Date(), { days: temp[0].template_case_target_days - 1 }));
    setFieldValue('etaForDeliveryDate', add(new Date(), { days: temp[0].template_case_target_days - 1 }));
    setImportInProgress(false);
  };

  const saveProject = async (newForm: any) => {
    setIsSaveInProgress(true);
    console.log('newForm = ', newForm);
    const projectInformation = {
      project_uid: params.projectId,
      association_uid: params.associationId,
      template_case_uid: newForm.importedTemplateProjectUid,
      case_name: newForm.usecaseName,
      case_fees: newForm.implementationFee,
      case_ARR: arrFieldValue,
      case_description: newForm.projectDescription,
      case_comment: newForm.comment,
      case_reference: newForm.link,
      case_value: null,
      case_needed_status: newForm.category,
      case_stage: newForm.usecaseStage,
      case_priority: newForm.priority,
      case_readinessstate_status: 'green',
      case_expansionvalue: newForm.expandARR,
      case_status_client: 'red',
      case_status_reason: `The ${label_due_date.toLowerCase()} of this ${label_use_case.toLowerCase()} has passed`,
      case_status_reason_client: `The ${label_due_date.toLowerCase()} of this ${label_use_case.toLowerCase()} has passed`,
      case_startdate: format(newForm.actualStartDate, 'yyyy-MM-dd HH:mm:ss'),
      case_planned_startdate: format(newForm.plannedStartDate, 'yyyy-MM-dd HH:mm:ss'),
      case_deliverydate: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      case_creaded_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      case_modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      eta_forcasedelivery: format(newForm.etaForDeliveryDate, 'yyyy-MM-dd HH:mm:ss'),
      case_requiredbydate: format(newForm.dueDate, 'yyyy-MM-dd HH:mm:ss'),
      case_deliverymanager: newForm.deliveryManager,
      user_uid: newForm.deliveryManager == 'Not Assigned' ? '15243' : getUserUid(newForm.deliveryManager),
      userAddingUsecase: getLoggedInUserUid(),
      imported_template: newForm.usecaseTemplate.length > 0 ? 1 : 0,
      project_rollout: 0,
      last_user_modified: getLoggedInUserUid(),
      last_user_modified_name: getLoggedInUserName(),
      base_url: `https://${window.location.host}/`,
    };

    const addedTemplate = await dispatch(createUsecaseFromUsecaseTemplate(projectInformation)).unwrap();
    console.log(addedTemplate);
    if (addedTemplate.result) {
      setIsSaveInProgress(false);
      navigate(`/projects/project/${params.associationId}/${params.projectId}/${addedTemplate.result}`, {
        state: {
          projectName: projectName,
          clientName: clientName,
          clientUid: '',
          new_added_usecase_uid: addedTemplate.result,
        },
      });
    }

    console.log(addedTemplate);
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={addUsecaseSchema}
        onSubmit={(values) => {
          console.log(values);
          const newValues = {
            ...values,
            deliveryManager: values.deliveryManager ? values.deliveryManager.name : 'Not Assigned',
            category: getStandardNameMappedValue(values.category, NAMED_CONSTATNTS.usecase_category_key),
            usecaseStage: getStandardNameMappedValue(values.usecaseStage, NAMED_CONSTATNTS.usecase_stage_key),
            priority: getStandardNameMappedValue(values.priority, NAMED_CONSTATNTS.usecase_priority_key),
          };
          saveProject(newValues);
          // saveProject(values);
        }}
      >
        {(props: any) => (
          <form className={Styles.OnboardAccountProfile__container}>
            <div className={Styles.OnboardAccountProfile__formAlternateTopSection}>
              <Typography sx={{ magrin: '12px 0px' }} variant="h6" component="span" gutterBottom>
                Choose a {label_use_case} Template
              </Typography>
              {/* <Button onClick={() => componentProps.handleSkip(0) }>Skip</Button> */}
            </div>

            <div className={Styles.OnboardAccount__section}>
              <div className={Styles.OnboardAccountProfile__formField}>
                <FormControl sx={{ width: '257px' }} size="small">
                  <CognisaasSelect
                    width="257px"
                    onChange={(e: any) => {
                      props.handleChange;
                      importThisTemplate(e.target.value, props.setFieldValue);
                    }}
                    name="SelectTemplate"
                    value={props.values.usecaseTemplate}
                    validValues={[...templateList]}
                    onBlur={props.handleBlur}
                    error={props.touched.SelectTemplate && Boolean(props.errors.SelectTemplate)}
                    helperText={props.touched.SelectTemplate && props.errors.SelectTemplate}
                  />
                </FormControl>
              </div>
              <div className={Styles.OnboardAccountProfile__formField}>
                <Button disabled>Create Template</Button>
              </div>
            </div>

            <div className={Styles.OnboardAccount__section}>
              <div className={Styles.OnboardAccountProfile__formField}>
                <Typography variant="subtitle2">{label_use_case} Name*</Typography>
                <TextField
                  size="small"
                  id="usecaseName"
                  name="usecaseName"
                  placeholder={`Enter ${label_use_case.toLowerCase()} name`}
                  onChange={props.handleChange}
                  value={props.values.usecaseName}
                  onBlur={props.handleBlur}
                  error={props.touched.usecaseName && Boolean(props.errors.usecaseName)}
                  helperText={props.touched.usecaseName && props.errors.usecaseName}
                  sx={{ width: '257px' }}
                ></TextField>
              </div>
              <div className={Styles.OnboardAccountProfile__formField}>
                <Typography variant="subtitle2">Priority</Typography>
                <FormControl size="small" sx={{ width: '257px' }}>
                  <CognisaasSelect
                    name="priority"
                    value={
                      props.values.priority == 'Medium'
                        ? getCustomNameMappedValue('Medium', NAMED_CONSTATNTS.usecase_priority_key)
                        : props.values.priority
                    }
                    onChange={props.handleChange}
                    validValues={usecasePriorities}
                    onBlur={props.handleBlur}
                    error={props.touched.priority && Boolean(props.errors.priority)}
                    helperText={props.touched.priority && props.errors.priority}
                    sx={{ width: '257px' }}
                  />
                </FormControl>
              </div>
              <div className={Styles.OnboardAccountProfile__formField}>
                <Typography variant="subtitle2">Category</Typography>
                <FormControl size="small">
                  <CognisaasSelect
                    name="category"
                    value={
                      props.values.category == 'Land'
                        ? getCustomNameMappedValue('Land', NAMED_CONSTATNTS.usecase_category_key)
                        : props.values.category
                    }
                    onChange={props.handleChange}
                    validValues={usecaseCategories}
                    error={props.touched.category && Boolean(props.errors.category)}
                    helperText={props.touched.category && props.errors.category}
                    sx={{ width: '257px' }}
                  />
                </FormControl>
              </div>
            </div>

            {getStandardNameMappedValue(props.values.category, NAMED_CONSTATNTS.usecase_category_key) == 'Expand' ? (
              <Stack>
                <Stack>
                  <Typography variant="subtitle2">
                    {`${getCustomNameMappedValue(
                      'Expand',
                      NAMED_CONSTATNTS.usecase_category_key
                    )} ${label_arr} (${getSymbolFromCurrency(customerDetail?.currency_code)})`}
                  </Typography>
                  <TextField
                    type="number"
                    sx={{ width: '210px' }}
                    size="small"
                    placeholder="0"
                    name="expandARR"
                    id="expandARR"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.expandARR}
                    error={props.touched.expandARR && Boolean(props.errors.expandARR)}
                    helperText={props.touched.expandARR && props.errors.expandARR}
                  ></TextField>
                </Stack>
              </Stack>
            ) : null}

            <div className={Styles.OnboardAccount__section}>
              <div className={Styles.OnboardAccountProfile__formField}>
                <Typography variant="subtitle2">Stage</Typography>
                <FormControl size="small">
                  <CognisaasSelect
                    width="257px"
                    name="usecaseStage"
                    value={
                      props.values.usecaseStage == 'Pre-Sales'
                        ? getCustomNameMappedValue('Pre-Sales', NAMED_CONSTATNTS.usecase_stage_key)
                        : props.values.usecaseStage
                    }
                    onChange={props.handleChange}
                    validValues={[...usecaseStages]}
                    onBlur={props.handleBlur}
                    error={props.touched.usecaseStage && Boolean(props.errors.usecaseStage)}
                    helperText={props.touched.usecaseStage && props.errors.usecaseStage}
                  />
                </FormControl>
              </div>

              <div className={Styles.OnboardAccountProfile__formField}>
                <Typography variant="subtitle2">{label_use_case_delivery_manager}</Typography>
                <FormControl size="small">
                  <CognisaasUserSelect
                    width="257px"
                    name="deliveryManager"
                    allOptions={companyUsersDropDownData}
                    selectedOption={props.values.deliveryManager}
                    onChange={(val: any) => {
                      props.setFieldValue('deliveryManager', {
                        name: val.target.textContent,
                        id: getUserUid(val.target.textContent),
                      });
                    }}
                    onBlur={props.handleBlur}
                    error={props.touched.deliveryManager && Boolean(props.errors.deliveryManager)}
                    helperText={props.touched.deliveryManager && props.errors.deliveryManager}
                  />
                </FormControl>
              </div>

              <div className={Styles.OnboardAccountProfile__formField}>
                <Typography variant="subtitle2">
                  {`Implementation Fee (${getSymbolFromCurrency(customerDetail?.currency_code)})`}
                </Typography>
                <TextField
                  type="number"
                  sx={{ width: '257px' }}
                  size="small"
                  id="implementationFee"
                  name="implementationFee"
                  placeholder="0"
                  onChange={props.handleChange}
                  value={props.values.implementationFee}
                  onBlur={props.handleBlur}
                  error={props.touched.implementationFee && Boolean(props.errors.implementationFee)}
                  helperText={props.touched.implementationFee && props.errors.implementationFee}
                ></TextField>
              </div>
            </div>

            <div className={Styles.OnboardAccount__section}>
              <div className={Styles.OnboardAccountProfile__formField}>
                <Typography variant="subtitle2">Link to {label_use_case} Reference Document</Typography>
                <TextField
                  type="url"
                  size="small"
                  id="link"
                  name="link"
                  style={{ width: '257px' }}
                  placeholder="https://example.com"
                  onChange={props.handleChange}
                  value={props.values.link}
                  error={props.touched.link && Boolean(props.errors.link)}
                  helperText={props.touched.link && props.errors.link}
                ></TextField>
              </div>

              <div className={Styles.OnboardAccountProfile__formField}>
                <Typography variant="subtitle2">Planned Start Date*</Typography>
                <CognisaasCalenderNew
                  value={new Date(props.values.plannedStartDate)}
                  handleChange={(date: any) => props.setFieldValue('plannedStartDate', new Date(date))}
                  width="257px"
                />
              </div>

              <div className={Styles.OnboardAccountProfile__formField}>
                <Typography variant="subtitle2">Actual Start Date</Typography>
                <CognisaasCalenderNew
                  value={new Date(props.values.actualStartDate)}
                  handleChange={(date: any) => props.setFieldValue('actualStartDate', new Date(date))}
                  width="257px"
                />
              </div>
            </div>
            <div className={Styles.OnboardAccount__section}>
              <div className={Styles.OnboardAccountProfile__formField}>
                <Typography variant="subtitle2">{label_due_date}*</Typography>
                <CognisaasCalenderNew
                  value={new Date(props.values.dueDate)}
                  handleChange={(date: any) => props.setFieldValue('dueDate', new Date(date))}
                  width="257px"
                />
              </div>

              <div className={Styles.OnboardAccountProfile__formField}>
                <Typography variant="subtitle2">Estimated Delivery Date</Typography>
                <CognisaasCalenderNew
                  value={new Date(props.values.etaForDeliveryDate)}
                  handleChange={(date: any) => props.setFieldValue('etaForDeliveryDate', new Date(date))}
                  width="257px"
                />
              </div>

              <CognisaaasTooltip
                title={
                  <Typography variant="subtitle1" style={{ padding: '10px' }}>
                    {label_use_case} Start date will be system generated when this {label_use_case.toLowerCase()} moves
                    to In progress
                  </Typography>
                }
              >
                <div className={Styles.OnboardAccountProfile__formField}>
                  <React.Fragment>
                    <Typography variant="subtitle2">{label_delivery_date}</Typography>

                    <CognisaasCalenderNew
                      disabled={true}
                      value={useNextDayIfItIsHoliday(props.values.deliveryDate)}
                      handleChange={(date: any) => props.setFieldValue('deliveryDate', new Date(date))}
                      width="257px"
                    />
                  </React.Fragment>
                </div>
              </CognisaaasTooltip>
            </div>

            {/* project description */}
            <div className={Styles.OnboardAccount__section}>
              <div className={Styles.OnboardAccountProfile__formField}>
                <Typography variant="subtitle2">{label_use_case} Description</Typography>
                <FormControl sx={{ width: '47vw', minWidth: '250px' }}>
                  <TextField
                    rows={4}
                    name="projectDescription"
                    id="projectDescription"
                    aria-label="maximum height"
                    placeholder="Enter description here"
                    defaultValue=""
                    value={props.values.projectDescription}
                    onBlur={props.handleBlur}
                    onChange={props.handleChange}
                    multiline
                  />
                </FormControl>
              </div>
            </div>

            {/* comments section */}
            <div className={Styles.OnboardAccount__section}>
              <div className={Styles.OnboardAccountProfile__formField}>
                {/* comment component here */}
                <Typography variant="subtitle2">Comments</Typography>
                <FormControl sx={{ width: '47vw', minWidth: '250px' }}>
                  <TextField
                    rows={4}
                    name="comment"
                    id="comment"
                    aria-label="maximum height"
                    placeholder="Enter comment here"
                    defaultValue=""
                    value={props.values.comment}
                    onBlur={props.handleBlur}
                    onChange={props.handleChange}
                    multiline
                  />
                </FormControl>
              </div>
            </div>

            {/* buttons for furthur form actions */}
            <div className={Styles.OnboardAccount__formBottomBarWrapper}>
              <div className={Styles.OnboardAccountProfile__formActionButtonsContainer}>
                {/* save and cancel this step buttons  */}
                <CognisaasButton label={'Cancel'} isOutlined={true} clickHandler={() => console.log('Calcel', props)} />
                <span style={{ width: '20px' }}></span>
                {isSaveInProgress ? (
                  <LoadingButton loading loadingPosition="start" startIcon={<SaveIcon />} variant="outlined">
                    Save
                  </LoadingButton>
                ) : (
                  <CognisaasButton
                    disabled={!props.isValid || !props.dirty}
                    label={'Save'}
                    isOutlined={false}
                    clickHandler={props.handleSubmit}
                  />
                )}
                {/* <CognisaasButton
                  disabled={!props.isValid || !props.dirty}
                  label={'Save'}
                  isOutlined={false}
                  clickHandler={props.handleSubmit}
                /> */}
              </div>
            </div>
          </form>
        )}
      </Formik>
      <Backdrop sx={{ color: '#fff', zIndex: 12011 }} open={importInProgress}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default AddNewUsecase;
