import { useState, useEffect } from 'react';
import { fetchClientList } from '../../../store/modules/ManageClient/ClientList/slice';
import { useDispatch } from 'react-redux';

const useClientList = () => {
  const dispatch = useDispatch();
  const [clientList, setClientList] = useState<any>([]);

  useEffect(() => {
    fetchClients();
  }, []);

  const fetchClients = async () => {
    const res: any = await dispatch(fetchClientList());
    setClientList([...res.payload]);
  };

  return clientList;
};

export default useClientList;
