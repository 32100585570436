import React, { useEffect, useState } from 'react';
import { Box, Grid, Stack, Typography, Paper, TextField, Button } from '@mui/material';
import { CustomButton, CustomLink } from '../GlobalStyles';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../store/hooks';
import { GoogleLogin } from 'react-google-login';
import { useSearchParams } from 'react-router-dom';

import MicrosoftLogin from 'react-microsoft-login';

import {
  sendUserSignupEmail,
  sendUserSignupNotification,
  sendUserSignupEmailGmail,
  resetErrorMessage,
} from '../../store/modules/auth/auth';

const clientId: string = process.env.REACT_APP_GOOGLE_CLIENT_ID ? process.env.REACT_APP_GOOGLE_CLIENT_ID : '';
const microsoftId: string = process.env.REACT_APP_MICROSOFT_CLIENT_ID ? process.env.REACT_APP_MICROSOFT_CLIENT_ID : '';

import { ReactComponent as BrandLogo } from '../../assets/svgs/BrandLogo.svg';
import { ReactComponent as GoogleLogo } from '../../assets/svgs/GoogleLogo.svg';
import { ReactComponent as MicrosoftLogo } from '../../assets/svgs/microsoftIcon.svg';
import CognisaasToast from 'components/shared/CognisaasToast/CognisaasToast';
const Signup: React.FC = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const emailFromUrl = searchParams.get('Email') ?? '';

  const navigate = useNavigate();

  const path = window.location.pathname.trim().split('/signup')[1];

  const [alertDetails, setAlertDetails] = useState<{
    isVisible: boolean;
    type: 'success' | 'failure';
    message: string;
  }>({ isVisible: false, type: 'success', message: '' });
  const [signupName, setSignupName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [workEmail, setWorkEmail] = useState(emailFromUrl);

  const sendUserSignupNotificationEmail = async () => {
    const userDetails = {
      email: workEmail,
    };
    const res = await dispatch(sendUserSignupNotification(userDetails)).unwrap();
    if (res.status == 1) {
      location.replace('https://www.cognisaas.com/thank-you');
    }
  };

  const responseGoogle = (response: any) => {
    if (response.tokenId) {
      const googleSignupReq = {
        access_token: response.tokenId,
        base_url: window.location.host + '/complete-account/',
        customer_company: '',
        signup_method: 'google',
        user_email: response.profileObj.email,
        user_job_title: '',
        user_signup_reason: '',
        user_uid: response.googleId,
        user_username: response.profileObj.name,
      };

      dispatch(sendUserSignupEmail(googleSignupReq))
        .unwrap()
        .then((data) => {
          console.log('message', data);
          if (data['status'] == 0) {
            setAlertDetails({
              isVisible: true,
              message: data['message'],
              type: 'failure',
            });
          } else {
            setAlertDetails({
              isVisible: false,
              message: data['message'],
              type: 'success',
            });
            sendUserSignupNotificationEmail();
          }
        })
        .catch((err) => {
          console.log('err', err);
          setAlertDetails({ isVisible: true, type: 'failure', message: 'Please try again later' });
        });
    }
  };

  const microsoftAuthHandler = (err: any, data: any) => {
    console.log(data, err, 'I am called');
    if (data) {
      console.log(data.accessToken, 'access token');
      const microsoftObj = {
        access_token: data.accessToken,
        base_url: window.location.host + '/complete-account/',
        customer_company: '',
        signup_method: 'microsoft',
        user_email: data.account.userName,
        user_job_title: '',
        user_signup_reason: '',
        user_uid: data.googleId,
        user_username: data.account.name,
      };
      dispatch(sendUserSignupEmail(microsoftObj))
        .unwrap()
        .then((data) => {
          console.log('message', data);
          if (data['status'] == 0) {
            setAlertDetails({
              isVisible: true,
              message: data['message'],
              type: 'failure',
            });
          } else {
            setAlertDetails({
              isVisible: true,
              message: data['message'],
              type: 'success',
            });
            sendUserSignupNotificationEmail();
          }
        })
        .catch((err) => {
          console.log('err', err);
          setAlertDetails({ isVisible: true, type: 'failure', message: 'Please try again later' });
        });
    } else {
      console.log(err);
    }
  };

  const handelSubmit = () => {
    if (path.includes('/user')) {
      const signupRequestDetails = {
        base_url: window.location.host + '/create-password/',
        customer_company: companyName,
        signup_method: 'email',
        user_email: workEmail,
        user_job_title: '',
        user_signup_reason: '',
        user_username: signupName,
      };
      dispatch(sendUserSignupEmailGmail(signupRequestDetails))
        .unwrap()
        .then((data) => {
          if (data['status'] == 0) {
            setAlertDetails({
              isVisible: true,
              type: 'failure',
              message: data['message'],
            });
          } else {
            setAlertDetails({
              isVisible: true,
              type: 'success',
              message: data['message'],
            });
            sendUserSignupNotificationEmail();
          }
        })
        .catch((err) => {
          console.log('err', err);
          setAlertDetails({ isVisible: true, type: 'failure', message: 'Please try again later' });
        });
    } else {
      const signupRequestDetails = {
        base_url: window.location.host + '/create-password/',
        customer_company: companyName,
        signup_method: 'email',
        user_email: workEmail,
        user_job_title: '',
        user_signup_reason: '',
        user_username: signupName,
      };
      dispatch(sendUserSignupEmail(signupRequestDetails))
        .unwrap()
        .then((data) => {
          if (data['status'] == 0) {
            setAlertDetails({
              isVisible: true,
              type: 'failure',
              message: data['message'],
            });
          } else {
            setAlertDetails({
              isVisible: false,
              type: 'success',
              message: data['message'],
            });
            sendUserSignupNotificationEmail();
          }
        })
        .catch((err) => {
          console.log('err', err);
          setAlertDetails({ isVisible: true, type: 'failure', message: 'Please try again later' });
        });
    }
  };

  useEffect(() => {
    dispatch(resetErrorMessage());
  }, []);

  return (
    <Box>
      <CognisaasToast
        message={alertDetails.message}
        type={alertDetails.type}
        open={alertDetails.isVisible}
        onClose={() => {
          setAlertDetails({ isVisible: false, type: alertDetails.type, message: '' });
        }}
      />
      <Grid container>
        <Grid item xs={9} sx={{ backgroundColor: '#F5F5F7', height: '100vh', margin: '0px', paddingLeft: '110px' }}>
          {/* <img style={{ width: '326px', height: '60px', marginTop: '372px' }} src={BrandLogo} alt="COGNISAAS" /> */}
          <BrandLogo style={{ width: '326px', height: '60px', marginTop: '30vh' }} />
          <Typography sx={{ fontSize: '44px', fontWeight: '600', marginTop: '45px', lineHeight: '70.8px' }}>
            Deliver faster time-to-value
          </Typography>
          <Typography sx={{ fontSize: '44px', fontWeight: '600', lineHeight: '70.8px' }}>for your customers</Typography>
          <Typography sx={{ fontSize: '28px', fontWeight: '400', color: '#7A7A7A', lineHeight: '45px' }}>
            by collaborating with cross-functional teams on a
          </Typography>
          <Typography sx={{ fontSize: '28px', fontWeight: '400', color: '#7A7A7A', lineHeight: '45px' }}>
            common platform.
          </Typography>
        </Grid>
        <Grid item xs={3} sx={{ backgroundColor: '#F5F5F7', height: '100vh', margin: '0px', padding: '0px' }}>
          <Paper
            // elevation={1}
            sx={{
              position: 'absolute',
              backgroundColor: '#FFFFFF',
              width: '440px',
              minHeight: '70vh',
              left: '61vw',
              top: '3vh',
              padding: '30px 20px',
              borderRadius: '4px',
              boxShadow: '0px 4px 26px rgba(0, 0, 0, 0.05);',
              overflowY: 'scroll',

              ['@media (min-width:1800px)']: {
                left: '63vw',
                top: '7vh',
              },
            }}
          >
            <Typography sx={{ fontSize: '20px', fontWeight: '600' }}>Sign Up</Typography>
            <Typography sx={{ marginTop: '24px', fontSize: '12px', fontWeight: '400' }}>Name*</Typography>
            <TextField
              sx={{ marginTop: '4px' }}
              fullWidth
              size="small"
              id="outlined-basic"
              placeholder="Enter Name"
              variant="outlined"
              onChange={(e) => setSignupName(e.target.value)}
            />
            <Typography sx={{ marginTop: '24px', fontSize: '12px', fontWeight: '400' }}>Company Name*</Typography>
            <TextField
              sx={{ marginTop: '4px' }}
              fullWidth
              size="small"
              id="outlined-basic"
              placeholder="Enter Name"
              variant="outlined"
              onChange={(e) => setCompanyName(e.target.value)}
            />
            <Typography sx={{ marginTop: '24px', fontSize: '12px', fontWeight: '400' }}>Work Email*</Typography>
            <TextField
              sx={{ marginTop: '4px' }}
              fullWidth
              size="small"
              value={workEmail}
              id="outlined-basic"
              placeholder="Enter Email"
              variant="outlined"
              onChange={(e) => setWorkEmail(e.target.value)}
            />
            <CustomButton onClick={handelSubmit}>Sign Up</CustomButton>
            <Grid container spacing={0} sx={{ marginTop: '8px', width: '100%' }}>
              <Grid item sx={{ width: '100%' }}>
                <Typography sx={{ fontSize: '12px', color: '#7A7A7A', fontWeight: '400', textAlign: 'center' }}>
                  By continuing, you agree to Cognisaas&apos;s{' '}
                  <CustomLink href="https://www.cognisaas.com/terms" target="_blank">
                    Terms
                  </CustomLink>{' '}
                  and{' '}
                  <CustomLink href="https://www.cognisaas.com/privacy" target="_blank">
                    Privacy Policy
                  </CustomLink>
                </Typography>
              </Grid>
            </Grid>
            <Box display="flex" justifyContent="center" alignItems="center">
              {path.includes('/user') ? null : (
                <Typography sx={{ marginTop: '12px', color: '#7A7A7A', fontWeight: '400' }}>or</Typography>
              )}
            </Box>
            {path.includes('/user') ? null : (
              <>
                <GoogleLogin
                  clientId={clientId}
                  render={(renderProps) => (
                    <Button
                      sx={{
                        width: '100%',
                        height: '44px',
                        color: '#000000',
                        marginTop: '12px',
                        border: '1px solid #E5E5E5',
                        fontSize: '14px',
                        fontWeight: '400',
                      }}
                      variant="outlined"
                      startIcon={<GoogleLogo />}
                      onClick={renderProps.onClick}
                      disabled={renderProps.disabled}
                    >
                      Sign up with Google
                    </Button>
                  )}
                  buttonText="Login"
                  onSuccess={responseGoogle}
                  onFailure={responseGoogle}
                  cookiePolicy={'single_host_origin'}
                />
                <MicrosoftLogin clientId={microsoftId} authCallback={microsoftAuthHandler}>
                  <Button
                    sx={{
                      width: '100%',
                      height: '44px',
                      color: '#000000',
                      marginTop: '12px',
                      border: '1px solid #E5E5E5',
                      fontSize: '14px',
                      fontWeight: '400',
                    }}
                    variant="outlined"
                    startIcon={<MicrosoftLogo />}
                  >
                    Sign up with Microsoft
                  </Button>
                </MicrosoftLogin>
              </>
            )}

            <Box display="flex" justifyContent="center" alignItems="center" sx={{ marginTop: '36px' }}>
              <Stack direction="row">
                <Typography sx={{ fontSize: '14px', fontWeight: '400' }}>Already have an account?</Typography>
                <Typography
                  sx={{ fontSize: '14px', fontWeight: '400', color: '#2D60F6', cursor: 'pointer', marginLeft: '5px' }}
                  onClick={() => navigate('/login')}
                >
                  Sign in
                </Typography>
              </Stack>
            </Box>

            {/* <Grid container spacing={0} direction="row" alignItems="center" justifyContent="center" textAlign="center">
              <Grid item>
                <Typography sx={{ fontSize: '12px', color: '#7A7A7A', fontWeight: '400' }}></Typography>
              </Grid>
            </Grid> */}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Signup;
