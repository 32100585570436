import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import {
  selectCustomClientJourneyStage,
  selectCustomProjectCategories,
  selectCustomProjectPriorities,
  selectCustomProjectStage,
  selectCustomSegmentValues,
  selectCustomTaskPriorities,
  selectCustomtaskStage,
  selectCustomUsecaseCategories,
  selectCustomUsecasePriorities,
  selectCustomUsecaseStage,
  // selectJourneyStage,
  selectProjectCategories,
  selectProjectPriorities,
  selectProjectStages,
  selectSegmentValues,
  selectTaskPriorities,
  selectTaskStages,
  selectUsecaseCategories,
  selectUsecasePriorities,
  selectUsecaseStages,
} from 'store/modules/Common/selector';
import { fetchAllCustomJourneyStages, getStandardAndCustomNamesOfAllEntites } from 'store/modules/Common/slice';
import { isUndefined } from 'utils/dataHelpers';
import NAMED_CONSTATNTS from './Constants';

const useCustomNames = () => {
  const dispatch = useDispatch();
  // Clients
  const allSegments = useAppSelector(selectSegmentValues);
  const allCustomSegments = useAppSelector(selectCustomSegmentValues);
  const [clientSegmentDropdownValues, setClientSegmentDropdownValues] = useState<any[]>([]);
  // const [clientStageDropdownValues, setClientStageDropdownValues] = useState<any[]>([]);
  const customAndStandardJourneyStages = useAppSelector(selectCustomClientJourneyStage);
  // const standardJourneyStages = useAppSelector(selectJourneyStage);
  const [nameMapping, setNameMapping] = useState<Record<string, any>>({});
  // Projects
  const allProjectCategories = useAppSelector(selectProjectCategories);
  const allCustomProjectCustomCategory = useAppSelector(selectCustomProjectCategories);
  const [projectCategoryDropdownValues, setProjectCategoryDropdownValues] = useState<any[]>([]);
  const allProjectStages = useAppSelector(selectProjectStages);
  const allCustomProjectStages = useAppSelector(selectCustomProjectStage);
  const [projectStagesDropdownValues, setProjectStagesDropdownValues] = useState<any[]>([]);
  const allProjectPriorities = useAppSelector(selectProjectPriorities);
  const allCustomProjectPriorities = useAppSelector(selectCustomProjectPriorities);
  const [projectPrioritiesDropdownValues, setProjectPrioritiesDropdownValues] = useState<any[]>([]);

  // usecase
  const allUsecaseCategories = useAppSelector(selectUsecaseCategories);
  const allCustomUsecaseCustomCategory = useAppSelector(selectCustomUsecaseCategories);
  const [usecaseCategoryDropdownValues, setUsecaseCategoryDropdownValues] = useState<any[]>([]);
  const allUsecaseStages = useAppSelector(selectUsecaseStages);
  const allCustomUsecaseStages = useAppSelector(selectCustomUsecaseStage);
  const [usecaseStagesDropdownValues, setUsecaseStagesDropdownValues] = useState<any[]>([]);
  const allUsecasePriorities = useAppSelector(selectUsecasePriorities);
  const allCustomUsecasePriorities = useAppSelector(selectCustomUsecasePriorities);
  const [usecasePrioritiesDropdownValues, setUsecasePrioritiesDropdownValues] = useState<any[]>([]);

  // task
  const allTaskStages = useAppSelector(selectTaskStages);
  const allCustomTaskStages = useAppSelector(selectCustomtaskStage);
  const [taskStagesDropdownValues, setTaskStagesDropdownValues] = useState<any[]>([]);
  const allTaskPriorities = useAppSelector(selectTaskPriorities);
  const allCustomTaskPriorities = useAppSelector(selectCustomTaskPriorities);
  const [taskPrioritiesDropdownValues, setTaskPrioritiesDropdownValues] = useState<any[]>([]);

  const mergeStandardAndCustomValues = (
    standardData: any[],
    customData: any[],
    uidKey: string,
    standardNameKey: string,
    customNameKey: string
  ): any[] => {
    return standardData.map((item: any) => {
      const correspondingCustomvalue = customData.filter((custom: any) => custom[uidKey] === item[uidKey]);
      if (correspondingCustomvalue.length > 0) {
        return { ...item, [customNameKey]: correspondingCustomvalue[0][customNameKey] };
      } else {
        return { ...item, [customNameKey]: item[standardNameKey] };
      }
    });
  };

  const getAndSetClientStageData = async () => {
    // if (standardJourneyStages.data.length == 0 && standardJourneyStages.status == 'success') {
    //   await dispatch(fetchAllJourneyStages());
    // }
    if (customAndStandardJourneyStages.data.length === 0 && customAndStandardJourneyStages.status === 'success') {
      await dispatch(fetchAllCustomJourneyStages());
    }
  };

  const getAndSetStandardAndCustomNamesOfAllEntities = async () => {
    const fetchingDataIsNeeded =
      allSegments.data.length === 0 ||
      allCustomSegments.status !== 'success' ||
      allProjectCategories.data.length === 0 ||
      allCustomProjectCustomCategory.status !== 'success' ||
      allProjectStages.data.length === 0 ||
      allCustomProjectStages.status !== 'success' ||
      allProjectPriorities.data.length === 0 ||
      allCustomProjectPriorities.status !== 'success' ||
      allUsecaseCategories.data.length === 0 ||
      allCustomUsecaseCustomCategory.status !== 'success' ||
      allUsecaseStages.data.length === 0 ||
      allCustomUsecaseStages.status !== 'success' ||
      allUsecasePriorities.data.length === 0 ||
      allCustomUsecasePriorities.status !== 'success' ||
      allTaskPriorities.data.length === 0 ||
      allCustomTaskPriorities.status !== 'success' ||
      allTaskStages.data.length === 0 ||
      allCustomTaskStages.status !== 'success';
    if (fetchingDataIsNeeded) {
      await dispatch(getStandardAndCustomNamesOfAllEntites());
    }
  };

  const getCustomNameMappedValue = (value: string, key: string) => {
    if (!isUndefined(nameMapping[key])) {
      const mapping = nameMapping[key];
      return mapping[value];
    } else {
      return value;
    }
  };

  const getStandardNameMappedValue = (value: string, key: string) => {
    if (!isUndefined(nameMapping[key])) {
      const mapping = nameMapping[key];

      const all_keys = Object.keys(mapping);
      let my_answer: string = value;
      all_keys.forEach((key) => {
        if (mapping[key] === value) {
          my_answer = key;
        }
      });
      return my_answer;
    } else {
      return value;
    }
  };

  const displayDataWithCustomNames = (data: any[]): any[] => {
    console.log('displayDataWithCustomNames', data);
    if (data.length > 0 && Object.keys(nameMapping).length > 0) {
      const dataKeys = Object.keys(data[0]);
      const mappedKeys = Object.keys(nameMapping);

      const newdata = data.map((item: any) => {
        const newItem = { ...item };

        dataKeys.forEach((key: string) => {
          if (key !== NAMED_CONSTATNTS.client_stage_key && mappedKeys.includes(key)) {
            newItem[key] = getCustomNameMappedValue(newItem[key], key);
          }
        });
        return newItem;
      });
      return newdata;
    } else {
      return data;
    }
  };

  const getStandardDataWithoutCustomNames = (data: any[]): any[] => {
    if (data.length > 0 && Object.keys(nameMapping).length > 0) {
      const dataKeys = Object.keys(data[0]);
      const mappedKeys = Object.keys(nameMapping);

      const newdata = data.map((item: any) => {
        const newItem = { ...item };

        dataKeys.forEach((key: string) => {
          if (mappedKeys.includes(key)) {
            newItem[key] = getStandardNameMappedValue(newItem[key], key);
          }
        });
        return newItem;
      });
      return newdata;
    } else {
      return data;
    }
  };

  const getStandardRowDataWithoutCustomNames = (data: Record<string, any>): Record<string, any> => {
    const newDataAsArr = getStandardDataWithoutCustomNames([data]);
    return newDataAsArr[0];
  };

  const addInMapping = (key: string, mapping: Record<string, string>) => {
    const newMapping = nameMapping;
    newMapping[key] = { ...mapping };
    setNameMapping(newMapping);
  };

  const makeMapping = (
    standardData: any[],
    customData: any[],
    uidKey: string,
    standardNameKey: string,
    customNameKey: string,
    dataKey: string
  ) => {
    const newMapping: Record<string, string> = {};
    standardData.forEach((item: any) => {
      const correspondingCustomvalue = customData.filter((custom: any) => custom[uidKey] === item[uidKey]);
      if (correspondingCustomvalue.length > 0) {
        newMapping[item[standardNameKey]] = correspondingCustomvalue[0][customNameKey];
      } else {
        newMapping[item[standardNameKey]] = item[standardNameKey];
      }
    });
    addInMapping(dataKey, newMapping);
  };

  const loadCustomNamesModule = async () => {
    getAndSetClientStageData();
    getAndSetStandardAndCustomNamesOfAllEntities();
  };

  useEffect(() => {
    loadCustomNamesModule();
  }, []);

  useEffect(() => {
    if (allSegments.data.length >= 0) {
      const newData = mergeStandardAndCustomValues(
        allSegments.data,
        allCustomSegments.data,
        'segment_uid',
        'segment_name',
        'custom_segment_name'
      );
      setClientSegmentDropdownValues(newData);
      // makeSegmentMapping(allSegments.data, allCustomSegments.data);
      makeMapping(
        allSegments.data,
        allCustomSegments.data,
        'segment_uid',
        'segment_name',
        'custom_segment_name',
        'segment_name'
      );
    }
  }, [allSegments.data, allCustomSegments.data]);

  useEffect(() => {
    if (allProjectCategories.data.length >= 0) {
      const newData = mergeStandardAndCustomValues(
        allProjectCategories.data,
        allCustomProjectCustomCategory.data,
        'category_uid',
        'category_name',
        'custom_category_name'
      );
      setProjectCategoryDropdownValues(newData);
      makeMapping(
        allProjectCategories.data,
        allCustomProjectCustomCategory.data,
        'category_uid',
        'category_name',
        'custom_category_name',
        'project_needed_status'
      );
    }
  }, [allProjectCategories.data, allCustomProjectCustomCategory.data]);

  useEffect(() => {
    if (allProjectStages.data.length >= 0) {
      const newData = mergeStandardAndCustomValues(
        allProjectStages.data,
        allCustomProjectStages.data,
        'stage_uid',
        'stage_name',
        'custom_stage_name'
      );
      setProjectStagesDropdownValues(newData);
      makeMapping(
        allProjectStages.data,
        allCustomProjectStages.data,
        'stage_uid',
        'stage_name',
        'custom_stage_name',
        'project_stage'
      );
    }
  }, [allProjectStages.data, allCustomProjectStages.data]);

  useEffect(() => {
    if (allUsecaseCategories.data.length >= 0) {
      const newData = mergeStandardAndCustomValues(
        allUsecaseCategories.data,
        allCustomUsecaseCustomCategory.data,
        'category_uid',
        'category_name',
        'custom_category_name'
      );
      setUsecaseCategoryDropdownValues(newData);
      makeMapping(
        allUsecaseCategories.data,
        allCustomUsecaseCustomCategory.data,
        'category_uid',
        'category_name',
        'custom_category_name',
        NAMED_CONSTATNTS.usecase_category_key
      );
    }
  }, [allUsecaseCategories.data, allCustomUsecaseCustomCategory.data]);

  useEffect(() => {
    if (allUsecaseStages.data.length >= 0) {
      const newData = mergeStandardAndCustomValues(
        allUsecaseStages.data,
        allCustomUsecaseStages.data,
        'stage_uid',
        'stage_name',
        'custom_stage_name'
      );
      setUsecaseStagesDropdownValues(newData);
      makeMapping(
        allUsecaseStages.data,
        allCustomUsecaseStages.data,
        'stage_uid',
        'stage_name',
        'custom_stage_name',
        NAMED_CONSTATNTS.usecase_stage_key
      );
    }
  }, [allUsecaseStages.data, allCustomUsecaseStages.data]);

  useEffect(() => {
    if (allProjectPriorities.data.length >= 0) {
      const newData = mergeStandardAndCustomValues(
        allProjectPriorities.data,
        allCustomProjectPriorities.data,
        'priority_uid',
        'priority_name',
        'custom_priority_name'
      );
      setProjectPrioritiesDropdownValues(newData);
      makeMapping(
        allProjectPriorities.data,
        allCustomProjectPriorities.data,
        'priority_uid',
        'priority_name',
        'custom_priority_name',
        'project_priority'
      );
    }
  }, [allProjectPriorities.data, allCustomProjectPriorities.data]);

  useEffect(() => {
    if (allUsecasePriorities.data.length >= 0) {
      const newData = mergeStandardAndCustomValues(
        allUsecasePriorities.data,
        allCustomUsecasePriorities.data,
        'priority_uid',
        'priority_name',
        'custom_priority_name'
      );
      setUsecasePrioritiesDropdownValues(newData);
      makeMapping(
        allUsecasePriorities.data,
        allCustomUsecasePriorities.data,
        'priority_uid',
        'priority_name',
        'custom_priority_name',
        'case_priority'
      );
    }
  }, [allUsecasePriorities.data, allCustomUsecasePriorities.data]);

  useEffect(() => {
    if (allTaskPriorities.data.length >= 0) {
      const newData = mergeStandardAndCustomValues(
        allTaskPriorities.data,
        allCustomTaskPriorities.data,
        'priority_uid',
        'priority_name',
        'custom_priority_name'
      );
      setTaskPrioritiesDropdownValues(newData);
      makeMapping(
        allTaskPriorities.data,
        allCustomTaskPriorities.data,
        'priority_uid',
        'priority_name',
        'custom_priority_name',
        'task_priority'
      );
    }
  }, [allTaskPriorities.data, allCustomTaskPriorities.data]);

  useEffect(() => {
    if (allTaskStages.data.length >= 0) {
      const newData = mergeStandardAndCustomValues(
        allTaskStages.data,
        allCustomTaskStages.data,
        'stage_uid',
        'stage_name',
        'custom_stage_name'
      );
      setTaskStagesDropdownValues(newData);
      makeMapping(
        allTaskStages.data,
        allCustomTaskStages.data,
        'stage_uid',
        'stage_name',
        'custom_stage_name',
        'task_stage'
      );
    }
  }, [allTaskStages.data, allCustomTaskStages.data]);

  // useEffect(() => {
  //   if (customAndStandardJourneyStages.data.length > 0) {
  //     makeMapping(
  //       customAndStandardJourneyStages.data,
  //       customAndStandardJourneyStages.data,
  //       'stage_uid',
  //       'original_journeystage',
  //       'stage_journerystage',
  //       NAMED_CONSTATNTS.client_stage_key
  //     );
  //   } else {
  //     makeMapping(
  //       standardJourneyStages.data,
  //       customAndStandardJourneyStages.data,
  //       'stage_uid',
  //       'stage_journerystage',
  //       'original_journeystage',
  //       NAMED_CONSTATNTS.client_stage_key
  //     );
  //   }
  // }, [customAndStandardJourneyStages.data, standardJourneyStages.data]);

  return {
    clientSegmentDropdownValues,
    displayDataWithCustomNames,
    getStandardNameMappedValue,
    getCustomNameMappedValue,
    projectCategoryDropdownValues,
    projectStagesDropdownValues,
    usecaseCategoryDropdownValues,
    usecaseStagesDropdownValues,
    getStandardDataWithoutCustomNames,
    getStandardRowDataWithoutCustomNames,
    projectPrioritiesDropdownValues,
    usecasePrioritiesDropdownValues,
    taskPrioritiesDropdownValues,
    taskStagesDropdownValues,
    loadCustomNamesModule,
  };
};

export default useCustomNames;
