export const isNumber = (obj: any) => {
  if (Object.prototype.toString.call(obj) === '[object Number]') {
    return true;
  }
  return false;
};

export const isUndefined = (obj: any) => {
  if (Object.prototype.toString.call(obj) === '[object Undefined]') {
    return true;
  }
  return false;
};

export const isNull = (obj: any) => {
  if (Object.prototype.toString.call(obj) === '[object Null]') {
    return true;
  }
  return false;
};

export const isString = (obj: any) => {
  if (Object.prototype.toString.call(obj) === '[object String]') {
    return true;
  }
  return false;
};

export function isValidDate(date: any) {
  return date && Object.prototype.toString.call(date) === '[object Date]' && !isNaN(date);
}

export const findSumOfThisFieldInData = (
  data: any[],
  dataKey: string,
  filter: null | { dataKey: string; value: string } = null
): number => {
  // loop through data and add all values of data.dataKey
  let accumulatedSum = 0;
  let filteredData = data;

  if (filter) {
    filteredData = data.filter((item) => item[filter.dataKey] === filter.value);
  }

  filteredData.forEach((item) => {
    accumulatedSum += parseInt(item[dataKey]);
  });

  return accumulatedSum;
};

export const formatToUSD = (val: number): string => {
  const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 });
  return formatter.format(val);
};

export const formatToCurrencySymbol = (val: number, currency?: string): string => {
  if (isUndefined(currency)) {
    return val.toString();
  }
  const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: currency, maximumFractionDigits: 0 });
  return formatter.format(val);
};

export const getStatus = (color: string) => {
  if (isUndefined(color) || isNull(color)) {
    return 'Undefined';
  }
  switch (color.toLowerCase()) {
    case 'red':
      return 'Overdue';
    case 'green':
      return 'On Track';
    case 'amber':
      return 'At Risk';
    case 'blue':
      return 'Completed';
    default:
      return 'Unknown';
  }
};

export const getOriginalStatusFromStatusName = (statusName: string) => {
  if (isUndefined(statusName) || isNull(statusName)) {
    return 'Undefined';
  }
  switch (statusName.toLowerCase().trim()) {
    case 'overdue':
      return 'red';
    case 'on track':
      return 'green';
    case 'at risk':
      return 'amber';
    case 'completed':
      return 'blue';
    default:
      return 'Unknown';
  }
};

export const getStatusColor = (color: string) => {
  if (isUndefined(color) || isNull(color)) {
    return 'Undefined';
  }
  switch (color.toLowerCase()) {
    case 'red':
      return '#E75B5C';
    case 'green':
      return '#4F9F52';
    case 'amber':
      return '#EFA958';
    case 'blue':
      return '#4390E1';
    case 'grey':
      return '#7a7a7a';
    default:
      return 'Unknown';
  }
};

export const generateOptions = (data: Record<string, any>[], dataKey: string): string[] => {
  const options: string[] = [];
  data.forEach((item) => {
    if (!options.includes(item[dataKey])) {
      options.push(item[dataKey]);
    }
  });

  return options;
};

export const validateEmail = (input: string) => {
  const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  if (input.match(validRegex)) {
    return true;
  } else {
    return false;
  }
};

export const validateRequiredField = (input: string) => {
  if (input.trim() === '') {
    return false;
  }
  return true;
};

export const generateDataWhileMappingCustomFieldsAsNormalFields = (
  data: any[],
  customFieldSubListKey: string,
  customFieldKeyToBeFutureDataKey: string,
  customFieldKeyToBeFutureValueForGivenDataKey: string,
  customFieldKeyHavingFieldUid: string,
  customFieldKeyHavingCustomFieldType: string
) => {
  if (data.length > 0) {
    const tempData = data;
    return tempData.map((item) => {
      const row = { ...item };
      if (!isUndefined(item[customFieldSubListKey]) && !isNull(item[customFieldSubListKey])) {
        item[customFieldSubListKey].forEach((cf: any) => {
          // key -> customAmanDropdown#$#2123023ds$#$RAG_status
          const key =
            cf[customFieldKeyToBeFutureDataKey] +
            '#$#' +
            cf[customFieldKeyHavingFieldUid] +
            '$#$' +
            cf[customFieldKeyHavingCustomFieldType].split(' ').join('_');
          const value = cf[customFieldKeyToBeFutureValueForGivenDataKey];
          row[key] = value;
        });
      }

      return { ...row };
    });
  } else {
    return [];
  }
};

export const generateMappedCustomFieldKey = (fieldName: string, fieldUid: string, fieldType: string) => {
  return `${fieldName}#$#${fieldUid}$#$${fieldType.split(' ').join('_')}`;
};

const currencytoCountryCodeMapping: Record<string, string> = {
  USD: 'US',
  INR: 'IN',
  EUR: 'DE',
  GBP: 'GB',
  JPY: 'JP',
};

export const formatCurrency = (value: number, currencyCode: string) => {
  let formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currencyCode || 'USD',
    minimumFractionDigits: 0,
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  const countryCode = currencytoCountryCodeMapping[currencyCode];
  if (countryCode) {
    formatter = new Intl.NumberFormat(`en-${countryCode}`, {
      style: 'currency',
      currency: currencyCode || 'USD',
      minimumFractionDigits: 0,
    });
  }

  return formatter.format(value);
};

const mapColumnFieldToColumnObject = (columnFields: string[], columns: any[]) => {
  const orderedColumns: any[] = [];
  columnFields.forEach((field: string) => {
    const column = columns.find((column: any) => column.field === field);
    orderedColumns.push(column);
  });
  return orderedColumns;
};

export const generateOrderedColumn = (
  columns: any[],
  columnId: string,
  oldArrayIndex?: number,
  targetArrayIndex?: number
) => {
  const orderedColumnsFields = localStorage.getItem(columnId);
  if (oldArrayIndex !== undefined && targetArrayIndex !== undefined) {
    const columnFields: string[] = columns.map((column: any) => column.field);
    const field = columnFields[oldArrayIndex];
    columnFields.splice(oldArrayIndex, 1);
    columnFields.splice(targetArrayIndex, 0, field);
    localStorage.setItem(columnId, JSON.stringify(columnFields));
    return mapColumnFieldToColumnObject(columnFields, columns);
  } else {
    if (orderedColumnsFields) {
      return mapColumnFieldToColumnObject(JSON.parse(orderedColumnsFields) as string[], columns);
    }
    return columns;
  }
};

// utility function to check if madatory fields is empty for client, task, usecase, project.
export const isMandatoryFieldsEmpty = (customFields: any[]) => {
  for (const field of customFields) {
    if (field.mandatory == '1' && field.field_value.length === 0) return true;
  }
  return false;
};

export const capitalizeEachWordOf = (str: string): string => {
  const arr = str.split(' ');
  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }

  return arr.join(' ');
};

export const capitalizeString = (str: string): string => {
  const arr = str.split(' ');
  arr[0] = arr[0].charAt(0).toUpperCase() + arr[0].slice(1);
  for (let i = 1; i < arr.length; i++) {
    arr[i] = arr[i].toLowerCase();
  }

  return arr.join(' ');
};

export function numberFormatter(num: number) {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item ? (num / item.value).toFixed(1).replace(rx, '$1') + item.symbol : '0';
}

export const formatNumberWithReadableCommas = (value: number, currencyCode: string) => {
  // let formatter = new Intl.NumberFormat('en-US', {
  //   style: 'currency',
  //   currency: currencyCode || 'USD',
  //   minimumFractionDigits: 0,
  //   // These options are needed to round to whole numbers if that's what you want.
  //   //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //   //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  // });
  // const countryCode = currencytoCountryCodeMapping[currencyCode];
  // if (countryCode) {
  //   formatter = new Intl.NumberFormat(`en-${countryCode}`, {
  //     style: 'currency',
  //     currency: currencyCode || 'USD',
  //     minimumFractionDigits: 0,
  //   });
  // }

  // return formatter.format(value);
  const countryCode = currencytoCountryCodeMapping[currencyCode];
  if (countryCode) {
    return new Intl.NumberFormat(`en-${countryCode}`).format(value);
  } else {
    return new Intl.NumberFormat('en-IN').format(value);
  }
};
