import { SelectChangeEvent } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid-premium';
import CogniTable from 'components/shared/Tables/CogniTable';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { selectCustomSegmentValues, selectSegmentValues } from 'store/modules/Common/selector';
import { getAllSegmentValues, getCustomSegmentValues } from 'store/modules/Common/slice';
import { updateCustomClientSegmentValue } from 'store/modules/Settings/slice';
import { getCustomerUid } from 'utils/getUid';
import { ReactComponent as EditSVG } from '../../../../../assets/svgs/TableEditButton.svg';

const CustomClientSegments = () => {
  const dispatch = useDispatch();
  const allSegments = useAppSelector(selectSegmentValues);
  const [mergedData, setMergedData] = useState<any[]>([]);
  const allCustomSegments = useAppSelector(selectCustomSegmentValues);

  const getAndSetInitialData = async () => {
    dispatch(getAllSegmentValues());
    dispatch(getCustomSegmentValues());
  };

  const mergeStandardAndCustomSegments = (standardData: any[], customData: any[]): any[] => {
    return standardData.map((item: any) => {
      const correspondingCustomvalue = customData.filter((custom: any) => custom.segment_uid === item.segment_uid);
      if (correspondingCustomvalue.length > 0) {
        return { ...item, custom_segment_name: correspondingCustomvalue[0].custom_segment_name };
      } else {
        return { ...item, custom_segment_name: item.segment_name };
      }
    });
  };

  useEffect(() => {
    getAndSetInitialData();
  }, []);

  useEffect(() => {
    if (allSegments.data.length >= 0) {
      const newData = mergeStandardAndCustomSegments(allSegments.data, allCustomSegments.data);
      setMergedData(newData);
    }
  }, [allSegments.data, allCustomSegments.data]);

  const handleEditCustomSegment = (params: any) => {
    const apiRef = useGridApiContext();
    const { id, value, field } = params;
    const handleChange = (event: SelectChangeEvent<unknown>) => {
      apiRef.current.setEditCellValue({ id, field, value: event.target.value as string });
    };
    const handleClick = async () => {
      await dispatch(
        updateCustomClientSegmentValue({
          customer_uid: getCustomerUid(),
          segment_name: params.row.segment_name,
          custom_segment_name: params.row.custom_segment_name,
          segment_uid: params.row.segment_uid,
        })
      );
      getAndSetInitialData();
      apiRef.current.stopCellEditMode({ id, field });
    };

    return (
      <>
        <div style={{ padding: '0 4px', display: 'flex', alignItems: 'center', width: 'inherit' }}>
          <input
            value={value}
            id="string"
            onChange={handleChange}
            style={{ width: '70%', height: '35px', borderRadius: '5px 0 0 5px', border: '1px solid #E5E5E5' }}
          />
          <button
            style={{
              border: '1px solid #4F9F52',
              background: '#BDF2BF',
              width: '40px',
              borderRadius: '0px 5px 5px 0px',
              height: '40px',
              marginLeft: '-1px',
              cursor: 'pointer',
            }}
            onClick={() => handleClick()}
          >
            <EditSVG />
          </button>
        </div>
      </>
    );
  };

  const columns = [
    {
      field: 'segment_uid',
      hide: true,
    },
    {
      headerName: 'Standard segment name',
      field: 'segment_name',
      flex: 1,
      width: 240,
    },
    {
      headerName: 'Custom segment name',
      field: 'custom_segment_name',
      flex: 1,
      width: 240,
      editable: true,
      renderEditCell: (params: any) => handleEditCustomSegment(params),
    },
  ];

  return <CogniTable columns={columns} data={mergedData} hideFooter={false} hideHeader={true} pageSize={10} />;
};

export default CustomClientSegments;
