import { apis } from '../axiosInstance';
import { productRequirementRoutes, jiraRoutes } from '../route';

import Cookies from 'universal-cookie';

const cookies = new Cookies();

const customer_uid = cookies.get('cogni_customer_uid') as string;

const getProductAndTheirRequirementsData = () =>
  apis.phpAxiosInstance.get(
    productRequirementRoutes.getProductAndTheirRequirements(cookies.get('cogni_customer_uid') as string),
    {
      headers: { JWT: 'true' },
    }
  );

const addNewProductAndRequirements = (requirementWithProductDetail: any) =>
  apis.phpAxiosInstance.post(productRequirementRoutes.addNewProductAndItsRequirements(), requirementWithProductDetail, {
    headers: { JWT: 'true' },
  });

const adddNewRequirement = (newRequirement: any) => {
  return apis.phpAxiosInstance.post(productRequirementRoutes.addNewRequirement(), newRequirement, {
    headers: { JWT: 'true' },
  });
};

const updateNewRequirement = (updatedPayload: any) =>
  apis.phpAxiosInstance.post(productRequirementRoutes.updateNewRequirementRoute(), updatedPayload, {
    headers: { JWT: 'true' },
  });

const deleteRequirement = (payload: any) =>
  apis.phpAxiosInstance.post(productRequirementRoutes.deleteRequirementRoute(), payload, {
    headers: { JWT: 'true' },
  });

const deleteProduct = (payload: any) =>
  apis.phpAxiosInstance.post(productRequirementRoutes.deleteProductRoute(), payload, {
    headers: { JWT: 'true' },
  });

const getRequirementComments = (feature_uid: string) =>
  apis.phpAxiosInstance.get(productRequirementRoutes.getRequirementComments(feature_uid), {
    headers: { JWT: 'true' },
  });

const getJiraProjectKeyMappedToProduct = (payload: any) =>
  apis.phpAxiosInstance.post(productRequirementRoutes.getJiraProductIssueId(), payload, {
    headers: { JWT: 'true' },
  });
const getJiraCustomFields = (payload: any) =>
  apis.phpAxiosInstance.post(productRequirementRoutes.getJiraCustomFields(), payload, {
    headers: { JWT: 'true' },
  });

const createJiraIssue = (payload: any) =>
  apis.phpAxiosInstance.post(productRequirementRoutes.createJiraIssue(), payload, {
    headers: { JWT: 'true' },
  });

const updateRequirementIssueId = (payload: any) =>
  apis.phpAxiosInstance.post(productRequirementRoutes.updateRequirementIssueId(), payload, {
    headers: { JWT: 'true' },
  });

const addRequiremetAttachmentsToJira = (payload: any) =>
  apis.phpAxiosInstance.post(productRequirementRoutes.addFeatureAttachmentsToJira(), payload, {
    headers: { JWT: 'true' },
  });

const addRequirementComment = (payload: any) =>
  apis.phpAxiosInstance.post(productRequirementRoutes.addRequirementComment(), payload, {
    headers: { JWT: 'true' },
  });

const deleteRequirementByDescription = (payload: any) =>
  apis.phpAxiosInstance.post(productRequirementRoutes.deleteRequirementByDescription(), payload, {
    headers: { JWT: 'true' },
  });

const updateProductByProductUid = (payload: any) =>
  apis.phpAxiosInstance.post(productRequirementRoutes.updateProductByProductUid(), payload, {
    headers: { JWT: 'true' },
  });

const deleteRequirementCommentByUid = (payload: any) =>
  apis.phpAxiosInstance.post(productRequirementRoutes.deleteRequirementCommentByUid(), payload, {
    headers: { JWT: 'true' },
  });

const updateRequirementCommentByUid = (payload: any) =>
  apis.phpAxiosInstance.post(productRequirementRoutes.updateRequirementCommentByUid(), payload, {
    headers: { JWT: 'true' },
  });

const syncProductRequirementByCustomerUid = () =>
  apis.phpAxiosInstance.get(jiraRoutes.syncProductsandFeaturesFromJIRAbyOneCustomer(customer_uid), {
    headers: { JWT: 'true' },
  });

const createJiraCommentOfFeature = (payload: any) =>
  apis.phpAxiosInstance.post(productRequirementRoutes.createJiraRequirementComment(), payload, {
    headers: { JWT: 'true' },
  });

const getRequirementCommentsFromJira = (feature_uid: string, jira_issue_id: string) =>
  apis.phpAxiosInstance.get(
    productRequirementRoutes.getJiraRequirementComments(feature_uid, jira_issue_id, customer_uid),
    {
      headers: { JWT: 'true' },
    }
  );

const deleteRequirmentCommentFromJira = (payload: any) =>
  apis.phpAxiosInstance.post(productRequirementRoutes.deleteJiraRequirmentComment(), payload, {
    headers: { JWT: 'true' },
  });

export const productRequirementAPI = {
  getProductAndTheirRequirementsData,
  addNewProductAndRequirements,
  adddNewRequirement,
  updateNewRequirement,
  deleteRequirement,
  deleteProduct,
  getRequirementComments,
  getJiraProjectKeyMappedToProduct,
  getJiraCustomFields,
  createJiraIssue,
  updateRequirementIssueId,
  addRequiremetAttachmentsToJira,
  deleteRequirementByDescription,
  addRequirementComment,
  updateProductByProductUid,
  deleteRequirementCommentByUid,
  updateRequirementCommentByUid,
  syncProductRequirementByCustomerUid,
  createJiraCommentOfFeature,
  getRequirementCommentsFromJira,
  deleteRequirmentCommentFromJira,
};
