export const VIEW_CONSTATNTS = {
  project_task_dashboard_view: 'project_task_dashboard_view',
  usecase_task_dashboard_view: 'usecase_task_dashboard_view',
};

export function setProjectTaskdashboardView(view: 'table' | 'plan' | 'resourse' | 'na') {
  localStorage.setItem(VIEW_CONSTATNTS.project_task_dashboard_view, view);
}

export function getProjectTaskDashboardView() {
  return localStorage.getItem(VIEW_CONSTATNTS.project_task_dashboard_view);
}

export function setUsecaseTaskdashboardView(view: 'table' | 'plan' | 'resourse' | 'na') {
  return localStorage.setItem(VIEW_CONSTATNTS.usecase_task_dashboard_view, view);
}

export function getUsecaseTaskDashboardView() {
  return localStorage.getItem(VIEW_CONSTATNTS.usecase_task_dashboard_view);
}
