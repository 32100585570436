import { Box, Stack, Typography } from '@mui/material';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';

import CircleIcon from '@mui/icons-material/Circle';
import { label_task } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import ViewDate from 'components/shared/ViewDate/ViewDate';
const ClientTimelineComponent = (props: any) => {
  const tasksToFilter = props.tasks;
  const filteredTasks = tasksToFilter.slice().sort((a: any, b: any) => a.task_sequence - b.task_sequence);
  return (
    <Box sx={{ marginTop: '15px', overflowX: 'scroll', paddingBottom: '15px' }}>
      <Stack direction="row">
        {filteredTasks.map((ele: any, i: any, arr: any) => {
          return (
            <Box key={i}>
              <Stack direction="row" sx={{ width: '195px' }}>
                {ele.task_stage === 'Done' ? (
                  <CircleIcon style={{ height: '10px', width: '10px', color: '#3D38BA' }} />
                ) : (
                  <CircleOutlinedIcon style={{ height: '10px', width: '10px', color: '#3D38BA' }} />
                )}
                {i != arr?.length - 1 ? (
                  <Box sx={{ height: '1px', width: '195px', backgroundColor: '#3D38BA', marginTop: '5px' }} />
                ) : null}
              </Stack>
              <Typography sx={{ fontSize: '14px', fontWeight: '400' }}>
                {ele?.task_name?.length > 24 ? ele.task_name?.slice(0, 24) + '...' : ele.task_name}
              </Typography>
              <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#7A7A7A' }}>
                <ViewDate date={ele?.project_requiredbydate} />
              </Typography>
            </Box>
          );
        })}
        {props?.tasks?.length == 0 ? <Typography variant="subtitle1">No Milestone {label_task}s</Typography> : null}
      </Stack>
    </Box>
  );
};

export default ClientTimelineComponent;
