import ReactDOM from 'react-dom';
import CognisaasSpinner from '../CognisaasSpinner/CognisaasSpinner';

import { Container } from './CognisaasOverlaySpinner.styles';

const OverlaySpinner = () => (
    ReactDOM.createPortal(<Container><CognisaasSpinner thickness={3} size={50}/></Container>,
    document.body
    )
);


export default OverlaySpinner;