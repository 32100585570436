import { label_task } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { EditableDropdown } from 'components/shared/Tables/CogniTable';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { selectJiraTaskCustomStage } from 'store/modules/Settings/selector';
import { fetchJiraCustomTaskStages, updateJiraTaskCustomStage } from 'store/modules/Settings/slice';
import MapJiraStatusModal from './MapJiraStatusModal';

interface Props {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
  projectKey: string;
}

const MapJiraTaskStatusModal = (props: Props) => {
  const jiraTaskStagesState = useAppSelector(selectJiraTaskCustomStage);
  const dispatch = useDispatch();
  const [mydata, setMydata] = useState<any[]>([]);

  useEffect(() => {
    if (props.open) {
      dispatch(fetchJiraCustomTaskStages(props.projectKey));
    }
  }, [props.open]);

  useEffect(() => {
    if (jiraTaskStagesState.status === 'success' && jiraTaskStagesState.data.length > 0) {
      setMydata(jiraTaskStagesState.data);
      // console.log(jiraTaskStagesState);
    }
  }, [jiraTaskStagesState]);

  const handleDropDownUpdate = (
    rowData: Record<string, string>,
    updated_data_key: string,
    updated_value: string
  ): void => {
    // console.log(rowData, updated_data_key, updated_value);
    setMydata((prevData) =>
      prevData.map((item: any) => {
        if (item.task_map_uid == rowData.task_map_uid) {
          return { ...item, [updated_data_key]: updated_value };
        } else {
          return item;
        }
      })
    );
  };

  const saveUpdatedProductStages = () => {
    // update here
    console.log(mydata);
    dispatch(updateJiraTaskCustomStage(mydata));
    props.setIsOpen(false);
  };

  return (
    <>
      <MapJiraStatusModal
        header="Please update mapping between Jira status and CogniSaaS Requirement status."
        columns={[
          {
            field: 'jira_status_name',
            headerName: 'Jira status',
            minWidth: 70,
            flex: 1,
          },
          {
            field: 'mapped_task_stage',
            headerName: `Cognisaas ${label_task} Status`,
            minWidth: 230,
            flex: 1,
            renderCell: (params) =>
              EditableDropdown(
                params,
                [
                  { id: '1', value: 'Not Started' },
                  { id: '2', value: 'In Progress' },
                  { id: '3', value: 'Blocked' },
                  { id: '3', value: 'Deferred' },
                  { id: '4', value: 'Done' },
                ],
                handleDropDownUpdate,
                'asItIs'
              ),
          },
        ]}
        data={mydata}
        handleSave={saveUpdatedProductStages}
        open={props.open}
        setIsOpen={props.setIsOpen}
      />
    </>
  );
};

export default MapJiraTaskStatusModal;
