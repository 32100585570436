import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { projectAPI } from '../../../../api/modules/project';
import { requestState } from '../../types';

interface openProjectsType {
  openProjects: Array<any>;
  status: requestState.idle | requestState.loading | requestState.success | requestState.failed;
  error: null | undefined | string;
}

const initialState: openProjectsType = {
  openProjects: [],
  status: requestState.idle,
  error: null,
} as openProjectsType;

export const fetchOpenProjects = createAsyncThunk('projects/fetchopenProjects', async () => {
  const { data } = await projectAPI.getOpenProjects();
  return data.result ?? [];
});

const openProjectSlice = createSlice({
  name: 'openProjects',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchOpenProjects.pending, (state) => {
        state.status = requestState.loading;
      })
      .addCase(fetchOpenProjects.fulfilled, (state: any, action) => {
        state.status = requestState.success;
        state.openProjects = action.payload;
      })
      .addCase(fetchOpenProjects.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      });
  },
});

export const getAllOpenProjects = (state: openProjectsType) => state.openProjects;

const openProjectsReducer = openProjectSlice.reducer;
export default openProjectsReducer;
