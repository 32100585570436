import { Typography, FormControl, TextField, Stack, Grid, Paper } from '@mui/material';
import { Formik } from 'formik';
import CognisaasDropdown from '../../shared/CognisaasDropdown/CognisaasDropdown';
import CognisaasButton from '../../shared/CognisaasButton/CognisaasButton';
import {
  updateClientRenewal,
  getClientDetail,
  updatedcasewithfeaturestatusClient,
  getAllRenewalTasksOfClient,
} from '../../../store/modules/ManageClient/ClientList/slice';
import { useEffect, useState } from 'react';
import { selectClientDetail } from '../../../store/modules/features/client/selector';
import { useAppSelector, useAppDispatch } from '../../../store/hooks';
import format from 'date-fns/format';
import CognisaasCalenderNew from 'components/shared/CognisaasCalenderNew/CognisaasCalenderNew';
import { selectCurrentUserDetails } from '../../../store/modules/Common/selector';
import { ReactComponent as NoRecords } from '../../../assets/svgs/no_records.svg';
import Cookies from 'universal-cookie';
import { getCurrentUserDetails } from 'store/modules/Common/slice';
import { requestState } from 'store/modules/types';
import { label_client } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { userPermissionSchema } from 'components/Reports/SideDrawer/types';
import { renewalClientTask } from 'utils/validations';
import CognisaasLoader from 'components/shared/CognisaasLoader/CognisaasLoader';
import RenewalTaskTable from './RenewalTaskTable';
import CognisaasToast from 'components/shared/CognisaasToast/CognisaasToast';
import { getClientRenewals, updateClientRenewals } from 'store/modules/features/client/client';
import { useParams } from 'react-router-dom';
import { selectClientRenewal } from 'store/modules/features/client/selector';
import CognisaasSelect from 'components/shared/CognisaasSelect/CognisaasSelect';

const cookies = new Cookies();

const RenewalConfiguration = (props: any) => {
  const durationTypes = [
    { id: '1 Year', name: '1 Year' },
    { id: '6 Months', name: '6 Months' },
    { id: '3 Months', name: '3 Months' },
    { id: '1 Month', name: '1 Month' },
  ];
  const lessThanToday = new Date();
  const params = useParams();
  const renewal = useAppSelector(selectClientRenewal);
  lessThanToday.setFullYear(lessThanToday.getFullYear() - 2);
  const userPermissions = props.clientInfo?.__user_permissions;
  const [initialFormValues, setInitialFormValues] = useState<any>({
    duration: '',
    renewalDate: '',
    frequency: 0,
    remaining: 0,
  });
  const dispatch = useAppDispatch();
  const clientDetail = useAppSelector(selectClientDetail);
  const userDetail = useAppSelector(selectCurrentUserDetails);
  const clientUid = props.clientUid;
  const [alertDetails, setAlertDetails] = useState<{
    isVisible: boolean;
    type: 'success' | 'failure';
    message: string;
  }>({ isVisible: false, type: 'success', message: '' });
  useEffect(() => {
    if (!renewal.data) {
      dispatch(getClientRenewals(params.clientId as string));
    }
    if (renewal.data) {
      setValues();
    }
    // dispatch(getClientDetail(clientUid));
    // dispatch(updatedcasewithfeaturestatusClient(clientUid));
    // dispatch(getCurrentUserDetails());
  }, [renewal]);
  const updateRenewalTaskDetails = async (values: any) => {
    const details = {
      renewal_date: new Date(values.renewalDate).toISOString(),
      renewal_duration:
        values.duration == '1 Year'
          ? 'Annually'
          : values.duration == '6 Months'
          ? 'Half-yearly'
          : values.duration == '3 Months'
          ? 'Quarterly'
          : values.duration == '1 Month'
          ? 'Monthly'
          : '',
      renewal_reminder_days_before: values.remaining,
      renewal_reminder_days_before_frequency: values.frequency,
      customer_uid: cookies.get('cogni_customer_uid') as string,
      client_uid: params.clientId,
    };
    await dispatch(updateClientRenewals(details));
    // .unwrap()
    // .then(async (data) => {
    //   if (data.status == 0) {
    //     setAlertDetails({
    //       isVisible: true,
    //       type: 'failure',
    //       message: data.message,
    //     });
    //   } else {
    //     setAlertDetails({
    //       isVisible: true,
    //       type: 'success',
    //       message: 'Details saved successfully!',
    //     });
    //     await dispatch(updatedcasewithfeaturestatusClient(clientUid));
    //     await dispatch(getClientDetail(clientUid));
    //     await dispatch(getAllRenewalTasksOfClient(clientUid));
    //   }
    // });
  };
  useEffect(() => {
    // if (Object.entries(renewal).length > 0) {
    // }
    console.log(initialFormValues);
  }, [initialFormValues]);
  const setValues = () => {
    setInitialFormValues({
      duration: '',
      renewalDate: '',
      frequency: 0,
      remaining: 0,
    });
    setInitialFormValues({
      duration:
        renewal.data.duration == 'ANNUALLY'
          ? '1 Year'
          : renewal.data.duration == 'HALF_YEARLY'
          ? '6 Months'
          : renewal.data.duration == 'QUARTERLY'
          ? '3 Months'
          : renewal.data.duration == 'MONTHLY'
          ? '1 Month'
          : '',
      renewalDate: renewal.data.renewal_date !== null ? new Date(renewal.data?.renewal_date) : null,
      // frequency: parseInt(renewal.data.renewal_reminder_days_before_frequency),
      remaining: parseInt(renewal.data.renewal_reminder_days_before),
    });
  };
  const validationForTheFormValues = (value: any) => {
    console.log(value, format(new Date(value.renewalDate), 'yyyy-MM-dd') <= format(lessThanToday, 'yyyy-MM-dd'));
    if (format(new Date(value.renewalDate), 'yyyy-MM-dd') <= format(lessThanToday, 'yyyy-MM-dd')) {
      console.log('UPDATE RENEW FAIl');

      setAlertDetails({
        isVisible: true,
        type: 'failure',
        message: 'Enter a valid date',
      });
    } else {
      if (value.duration == '1 Year') {
        console.log('UPDATE RENEW FAIl2');
        if (value.remaining <= 365 && value.frequency <= 365) {
          console.log('153');
          updateRenewalTaskDetails(value);
        } else {
          if (value.remaining > 365) {
            setAlertDetails({
              isVisible: true,
              type: 'failure',
              message: 'Days remaining for renewal to start sending reminders cannot be greater than Renewal duration',
            });
          } else {
            setAlertDetails({
              isVisible: true,
              type: 'failure',
              message: 'Frequency of reminders cannot be greater Days remaining for renewal to start sending reminders',
            });
          }
        }
      } else if (value.duration == '6 Months') {
        if (value.remaining <= 182 && value.frequency <= 182) {
          console.log('UPDATE RENEW');
          updateRenewalTaskDetails(value);
        } else {
          if (value.remaining > 182) {
            setAlertDetails({
              isVisible: true,
              type: 'failure',
              message: 'Days remaining for renewal to start sending reminders cannot be greater than Renewal duration',
            });
          } else {
            setAlertDetails({
              isVisible: true,
              type: 'failure',
              message: 'Frequency of reminders cannot be greater Days remaining for renewal to start sending reminders',
            });
          }
        }
      } else if (value.duration == '3 Months') {
        if (value.remaining <= 90 && value.frequency <= 90) {
          console.log('153');

          updateRenewalTaskDetails(value);
        } else {
          if (value.remaining > 90) {
            setAlertDetails({
              isVisible: true,
              type: 'failure',
              message: 'Days remaining for renewal to start sending reminders cannot be greater than Renewal duration',
            });
          } else {
            setAlertDetails({
              isVisible: true,
              type: 'failure',
              message: 'Frequency of reminders cannot be greater Days remaining for renewal to start sending reminders',
            });
          }
        }
      } else if (value.duration == '1 Month') {
        if (value.remaining <= 30 && value.frequency <= 30) {
          console.log('153');
          updateRenewalTaskDetails(value);
        } else {
          if (value.remaining > 30) {
            setAlertDetails({
              isVisible: true,
              type: 'failure',
              message: 'Days remaining for renewal to start sending reminders cannot be greater than Renewal duration',
            });
          } else {
            setAlertDetails({
              isVisible: true,
              type: 'failure',
              message: 'Frequency of reminders cannot be greater Days remaining for renewal to start sending reminders',
            });
          }
        }
      }
    }
  };

  return (
    <>
      {/* {userDetail.status == requestState.loading ? (
        <Grid container
          direction="column"
          justifyContent="center"
          alignItems="center">
          <Grid item>
            <CognisaasLoader />
          </Grid>
        </Grid>
      ) : userDetail.data.renewal_notification == 0 ? (
        <Stack justifyContent="center" alignItems="center">
          <NoRecords style={{ marginLeft: '6px', marginTop: '10px', width: '250px', height: '250px' }}></NoRecords>
          <Typography variant="h6">Renewal configuration is not enabled</Typography>
        </Stack> */}
      {/* ) : ( */}
      <>
        <CognisaasToast
          message={alertDetails.message}
          type={alertDetails.type}
          open={alertDetails.isVisible}
          onClose={() => {
            setAlertDetails({ isVisible: false, type: alertDetails.type, message: '' });
          }}
        />
        {renewal.data && (
          <Paper
            style={{
              margin: '16px',
              padding: '20px',
            }}
          >
            <Formik
              initialValues={initialFormValues}
              validationSchema={renewalClientTask}
              onSubmit={(value) => {
                console.log(value);
                validationForTheFormValues(value);
              }}
              enableReinitialize={true}
            >
              {(props: any) => (
                <form>
                  <div>
                    <Typography variant="h2" style={{ marginBottom: '10px' }}>
                      Renewal Configuration
                    </Typography>
                    <Typography variant="subtitle2" className="label-text" style={{ marginBottom: '25px' }}>
                      Please configure the renewal tracking settings for this {label_client}
                    </Typography>

                    <Grid rowSpacing={2} container>
                      <Grid item md={4} spacing={2}>
                        <Typography variant="subtitle1" className="label-text">
                          Renewal Date
                        </Typography>
                      </Grid>
                      <Grid item md={8}>
                        <FormControl variant="outlined" sx={{ width: '250px' }}>
                          <CognisaasCalenderNew
                            id="renewalDate"
                            name="renewalDate"
                            value={props.values.renewalDate}
                            handleChange={(date: any) => {
                              props.setFieldValue('renewalDate', new Date(date));
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={4}>
                        <Typography variant="subtitle1" className="label-text">
                          Renewal Duration
                        </Typography>
                      </Grid>
                      <Grid item md={8}>
                        <CognisaasSelect
                          width="250px"
                          name="duration"
                          id="duration"
                          type="dropdown"
                          value={props.values.duration}
                          validValues={[...durationTypes]}
                          onChange={(e: any, v: any) => {
                            console.log(v.props.value);
                            props.setFieldValue('duration', v.props.value);
                          }}
                          // onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          error={props.touched.duration && Boolean(props.errors.duration)}
                          helperText={props.touched.duration && props.errors.duration}
                        />
                      </Grid>
                      <Grid item md={4}>
                        <Typography variant="subtitle1" className="label-text">
                          Days remaining for renewal to start sending reminders
                        </Typography>
                      </Grid>
                      <Grid item md={8} sx={{ width: '250px' }}>
                        <FormControl variant="outlined">
                          <TextField
                            name="remaining"
                            id="remaining"
                            size="small"
                            onChange={props.handleChange}
                            value={props.values.remaining}
                            type="number"
                            placeholder="Days"
                            onBlur={props.handleBlur}
                            error={props.touched.remaining && Boolean(props.errors.remaining)}
                            helperText={props.touched.remaining && props.errors.remaining}
                          ></TextField>
                        </FormControl>
                      </Grid>
                      <Grid item md={4}>
                        <Typography variant="subtitle1" className="label-text">
                          Frequency of reminders
                        </Typography>
                      </Grid>
                      <Grid item md={8} sx={{ width: '250px' }}>
                        <FormControl variant="outlined">
                          <TextField
                            name="frequency"
                            id="frequency"
                            onChange={props.handleChange}
                            size="small"
                            type="number"
                            value={props.values.frequency}
                            placeholder="Days"
                            onBlur={props.handleBlur}
                            error={props.touched.frequency && Boolean(props.errors.frequency)}
                            helperText={props.touched.frequency && props.errors.frequency}
                          ></TextField>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </div>
                  {clientDetail.data.user_permissions?.includes(userPermissionSchema.EDIT) && (
                    <Grid
                      style={{ paddingBottom: '16px' }}
                      container
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <Grid sx={{ marginRight: '10%', paddingBotton: '16px', alignItems: 'end' }} item>
                        <Stack direction="row" spacing={2}>
                          <CognisaasButton
                            style={{ marginRight: '16px' }}
                            isOutlined={true}
                            label="Cancel"
                            clickHandler={() => {
                              props.handleReset();
                            }}
                          />
                          <CognisaasButton
                            isOutlined={false}
                            disabled={!props.dirty}
                            clickHandler={() => {
                              validationForTheFormValues(props.values);
                            }}
                            label="Save"
                          />
                        </Stack>
                        <Stack direction="row"></Stack>
                      </Grid>
                    </Grid>
                  )}
                </form>
              )}
            </Formik>
          </Paper>
        )}

        {/* <Paper style={{ padding: '20px', marginTop: '10px' }}>
            <RenewalTaskTable clientUid={clientUid} />
          </Paper> */}
      </>
      {/* )} */}
    </>
  );
};

export default RenewalConfiguration;
