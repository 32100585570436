import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import { Card, Grid, TextField, Link, Button, Snackbar, Alert, Stack } from '@mui/material';
import { ReactComponent as SearchIcon } from '../../../assets/svgs/search.svg';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useDispatch } from 'react-redux';
import CognisaasButton from '../../shared/CognisaasButton/CognisaasButton';
import BasicModal from '../../shared/CognisaasModal/CognisaasModal';
import CommonAddUser from '../shared/CommonAddUser';
import {
  deactivateUser_slice,
  activateUser_slice,
  changeUserRole_slice,
  getAllUsersAndRoles_slice,
  getAllRoles_slice,
  removeReportsTo,
} from '../../../store/modules/RBAC/slice';
import { getAllRoles_selector, getUserAndRoles_selector } from 'store/modules/RBAC/selector';
import { useAppSelector } from 'store/hooks';
import { addUserModalFor } from '../types';
const imgLink = 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png';

// Styled-components
import { Info } from '../styles';
import { requestState } from 'store/modules/types';
import CognisaasSpinner from 'components/shared/CognisaasSpinner/CognisaasSpinner';

interface Data {
  name: string;
  designition: string;
}

function createData(name: string, designition: string): Data {
  return {
    name,
    designition,
  };
}

const rows = [
  createData('A1', 'B1'),
  createData('A2', 'B2'),
  createData('A3', 'B3'),
  createData('A4', 'B4'),
  createData('A5', 'B5'),
  createData('A6', 'B6'),
  createData('A7', 'B7'),
  createData('A8', 'B8'),
  createData('A9', 'B9'),
  createData('A10', 'B10'),
  createData('A11', 'B11'),
  createData('A12', 'B12'),
  createData('A13', 'B13'),
  createData('A14', 'B14'),
  createData('A15', 'B15'),
  createData('A16', 'B16'),
  createData('A17', 'B17'),
  createData('A18', 'B18'),
  createData('A19', 'B19'),
];

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array: any, order: 'asc' | 'desc') {
  const data = array.sort((a: any, b: any) => {
    if (order === 'asc') {
      return a.user_name.localeCompare(b.user_name);
    } else {
      return b.user_name.localeCompare(a.user_name);
    }
  });
  return data;
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Users',
  },
];

interface UserCardComponentProps {
  name: string;
  designition: string;
  backColor: string;
  type: string;
}

const UserCardComponent = (props: UserCardComponentProps) => {
  return (
    <Card
      sx={{
        margin: '10px',
        backgroundColor: props.backColor,
        padding: '18px',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Grid sx={{ display: 'flex', backgroundColor: 'grey', alignItems: 'center' }}>
        <img src={imgLink} style={{ height: '45px', width: '45px', borderRadius: '50%', marginRight: '10px' }} />
        {/* <Grid></Grid> */}
        {props.type === 'S' ? (
          <>
            <Typography variant="h3">{props.name}</Typography>
            <Info>Super user</Info>
          </>
        ) : (
          <Typography variant="h3">{props.name}</Typography>
        )}
      </Grid>
      {props.type !== 'S' && (
        <Typography className="label-text" sx={{ marginTop: '16px' }} variant="subtitle1">
          ({props.designition})
        </Typography>
      )}
      {/* <Grid>{props.designition}</Grid> */}
    </Card>
  );
};

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: 'desc' | 'asc';
  orderBy: string;
  rowCount: number;
  searchValue: any;
  setSearchValue: any;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const [showSearchIcon, setShowSearchIcon] = useState(true);
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            // padding={headCell.disablePadding ? 'none' : 'normal'}
            sx={{ padding: '20px !important', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <Typography variant={'h2'}>{headCell.label}</Typography>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
            {showSearchIcon ? (
              <SearchIcon
                onClick={() => {
                  setShowSearchIcon(false);
                }}
              />
            ) : (
              <TextField
                placeholder="Start typing name..."
                value={props.searchValue}
                onBlur={() => {
                  setShowSearchIcon(true);
                }}
                onChange={(e: any) => {
                  props.setSearchValue(e.target.value);
                }}
                id="standard-basic"
                variant="standard"
              />
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface UserStatusComponent_payload {
  text: string;
}

const UserStatusComponent = (props: UserStatusComponent_payload) => {
  const [bgColor, setBgColor] = useState('white');
  const [borderColor, setBorderColor] = useState('black');
  useEffect(() => {
    const tempBgColor: string = props.text == 'Active' ? '#BDF2BF' : props.text == 'Inactive' ? '#F1AFAF' : '#F9D1A2';

    const tempBrColor: string = props.text == 'Active' ? '#4F9F52' : props.text == 'Inactive' ? '#E75B5C' : '#EFA958';

    setBgColor(tempBgColor);
    setBorderColor(tempBrColor);
  }, [props]);

  return (
    <Typography
      variant="h3"
      sx={{
        backgroundColor: bgColor,
        border: `1px solid ${borderColor}`,
        padding: '3px 6px',
        marginLeft: '20px',
        borderRadius: '5px',
      }}
    >
      {props.text}
    </Typography>
  );
};

interface rightSideComponent_User_Payload {
  data: any;
  selectedUser: any;
  setTabIndex: any;
  userAndRoleData_fromSelectorStatus: requestState;
}

const UserInfo_RightSideComponent = (props: rightSideComponent_User_Payload) => {
  const [data, setData] = useState(props.selectedUser ? props.selectedUser : { name: '', role: '' });
  const [uniqueRoles, setUniqueRoles] = useState<any[]>([]);
  const [roleDropdown, setRoleDropdown] = useState(props.selectedUser?.role?.name);
  const [status, setStatus] = useState('Active');
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [openSnackBarRole, setOpenSnackBarRole] = useState(false);
  const allRolesData_fromSelector = useAppSelector(getAllRoles_selector).data;
  const [addUserModalStatus, setAddUserModalStatus] = useState<boolean>(false);

  const dispatch = useDispatch();

  useEffect(() => {
    // const tempUnique = props?.data?.filter((value: any, index: any, self: any) =>
    //   index === self.findIndex((t: any) => (
    //     t?.role?.id === value?.role?.id && t.role.name != 'Not Assigned'
    //   ))
    // );
    // setUniqueRoles(tempUnique);
    setData(props.selectedUser ? props.selectedUser : { name: '', role: '' });
    setRoleDropdown(props.selectedUser?.role?.name);
    if (props?.selectedUser?.is_verified == false) setStatus('User not Verified');
    else if (props?.selectedUser?.active == true) setStatus('Active');
    else if (props?.selectedUser?.active == false) setStatus('Inactive');
  }, [props]);

  useEffect(() => {
    dispatch(getAllRoles_slice());
  }, []);

  useEffect(() => {
    if (allRolesData_fromSelector) {
      const tempArr = [...allRolesData_fromSelector];
      tempArr.push({
        id: null,
        name: 'Not Assigned',
        description: 'Not Assigned',
        is_public: true,
        account: null,
        pages: [],
        policies: [],
      });
      setUniqueRoles(tempArr);
    }
  }, [allRolesData_fromSelector]);

  const deactivateUserHandler = async () => {
    dispatch(
      deactivateUser_slice({
        userId: data.id,
      })
    );
    setOpenSnackBar(true);
    await dispatch(getAllUsersAndRoles_slice());
  };

  const activateUserHandler = async () => {
    dispatch(
      activateUser_slice({
        userId: data.id,
      })
    );
    setOpenSnackBar(true);
    await dispatch(getAllUsersAndRoles_slice());
  };

  const onChangeRole = (e: any) => {
    // changeUserRole_slice
    setRoleDropdown(e.target.value);
    const temp = uniqueRoles?.filter((obj: any) => {
      return obj?.name == e.target.value;
    });
    const newRoleId = temp[0]?.id || '';
    dispatch(
      changeUserRole_slice({
        userId: props?.selectedUser?.id || '',
        roleId: newRoleId || '',
      })
    );
    setOpenSnackBarRole(true);
  };

  const handleCloseSnackBar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackBar(false);
  };

  const handleCloseSnackBarRole = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackBarRole(false);
  };

  const handleRemoveReportees = async (reporteesId: string, userId: string) => {
    await dispatch(
      removeReportsTo({
        reportees: [reporteesId],
        userId: userId,
      })
    );
    await dispatch(getAllUsersAndRoles_slice());
  };

  return (
    <Paper sx={{ padding: '20px', height: '72vh', width: '55%', overflow: 'scroll' }}>
      {props.userAndRoleData_fromSelectorStatus === requestState.loading ? (
        <Stack justifyContent="center" alignItems="center" height="100%">
          <CognisaasSpinner size={50} thickness={3} />
        </Stack>
      ) : (
        <>
          <Stack direction="row" justifyContent="space-between">
            <Grid sx={{ display: 'flex', alignItems: 'center' }}>
              <Grid>
                <img
                  src={imgLink}
                  style={{ height: '51px', width: '51px', borderRadius: '50%', marginRight: '10px' }}
                  alt={'Image'}
                />
              </Grid>
              <Grid>
                <Grid sx={{ display: 'flex' }}>
                  <Typography variant={'h2'}>{data.user_name}</Typography>
                  <UserStatusComponent text={status} />
                </Grid>
                <Typography variant={'subtitle1'}>
                  {data?.role?.name ? data?.role?.name : 'No Role Assigned'}
                </Typography>
                <Typography variant={'subtitle1'}>{data?.email ? data?.email : 'abc@def.com'}</Typography>
              </Grid>
            </Grid>
            {data?.account?.user_type !== 'S' && (
              <Box>
                {props?.selectedUser?.active == false ? (
                  <Button variant="outlined" onClick={activateUserHandler} color="warning" sx={{ marginTop: '20px' }}>
                    Activate User
                  </Button>
                ) : (
                  <Button variant="outlined" onClick={deactivateUserHandler} color="error" sx={{ marginTop: '20px' }}>
                    Deactivate User
                  </Button>
                )}
              </Box>
            )}
          </Stack>
          {data?.account?.user_type !== 'S' && (
            <Grid sx={{ marginTop: '40px' }}>
              <Grid sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Stack direction="row">
                  <Typography variant={'h2'} sx={{ mt: '5px' }}>
                    Role :{' '}
                  </Typography>
                  <Select
                    value={roleDropdown}
                    variant="standard"
                    onChange={onChangeRole}
                    label="Choose Role"
                    sx={{ color: 'blue', ml: '5px' }}
                  >
                    {uniqueRoles?.map((obj: any, index: number) => {
                      return (
                        <MenuItem key={index} value={obj?.name}>
                          {obj?.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Stack>
                <Link
                  underline="none"
                  onClick={() => {
                    props.setTabIndex(1);
                  }}
                >
                  <Typography sx={{ marginTop: '10px !important', cursor: 'pointer' }} variant={'h3'}>
                    Go to role &gt;
                  </Typography>
                </Link>
              </Grid>

              <Snackbar open={openSnackBar} autoHideDuration={4500} onClose={handleCloseSnackBar}>
                <Alert onClose={handleCloseSnackBar} severity="success" sx={{ width: '100%' }}>
                  User {data?.active == true ? 'deactivated!' : 'activated!'}
                </Alert>
              </Snackbar>
              <Snackbar open={openSnackBarRole} autoHideDuration={4500} onClose={handleCloseSnackBarRole}>
                <Alert onClose={handleCloseSnackBarRole} severity="success" sx={{ width: '100%' }}>
                  Role Changed!
                </Alert>
              </Snackbar>
            </Grid>
          )}
          <Grid sx={{ marginTop: '50px' }}>
            <Stack direction="row" alignItems="center">
              <Typography variant={'h2'}>Reportees</Typography>
              <CognisaasButton
                sx={{ marginLeft: '15px' }}
                isOutlined={true}
                clickHandler={() => {
                  setAddUserModalStatus(true);
                }}
                label="Add Reportees"
              />
            </Stack>
            {data.account && data.account.account_reportees.length > 0 ? (
              <Box
                style={{
                  maxHeight: '200px',
                  overflowY: 'scroll',
                  overflowX: 'hidden',
                  border: '1px solid #E5E5E5',
                  borderRadius: '4px',
                  padding: '5px',
                  marginTop: '15px',
                }}
              >
                {data.account &&
                  data.account.account_reportees.map((reportees: any) => (
                    <>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        key={reportees.id}
                        style={{
                          borderRadius: '4px',
                          border: '1px solid #E5E5E5',
                          boxShadow: '0px 4px 26px rgba(0, 0, 0, 0.05)',
                          background: '#fff',
                          padding: '5px 10px',
                          marginBottom: '7px',
                        }}
                      >
                        <Typography variant="h2">{reportees.user_name}</Typography>
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={() => {
                            handleRemoveReportees(reportees.id, data.id);
                          }}
                        >
                          Remove
                        </Button>
                      </Stack>
                    </>
                  ))}
              </Box>
            ) : (
              <Typography variant="subtitle1">No Reportees</Typography>
            )}
          </Grid>
          {addUserModalStatus && (
            <BasicModal width={440} open={true} onClose={() => setAddUserModalStatus(!addUserModalStatus)}>
              <CommonAddUser
                id={addUserModalFor.whoIReportTo}
                onClose={() => setAddUserModalStatus(!addUserModalStatus)}
                userId={data.id}
                existingReportees={data.account.account_reportees}
              ></CommonAddUser>
            </BasicModal>
          )}
        </>
      )}
    </Paper>
  );
};

interface UserPayload {
  usersData: any;
  selectedUser: any;
  setSelectedUser: any;
  setTabIndex: any;
}

const User = (props: UserPayload) => {
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<keyof Data>('name');
  const [selected, setSelected] = useState<readonly string[]>([]);
  // const [rightSideIndex, setRightSIdeIndex] = useState(0);
  const [totalData, setTotalData] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const userAndRoleData_fromSelector = useAppSelector(getUserAndRoles_selector).data;
  const userAndRoleData_fromSelectorStatus = useAppSelector(getUserAndRoles_selector).status;
  // const [userAndRolesCombinedData, setUserAndRolesCombinedData] = useState<any[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllUsersAndRoles_slice());
  }, []);

  useEffect(() => {
    if (userAndRoleData_fromSelector) {
      const temp = userAndRoleData_fromSelector?.map((obj: any) => {
        if (obj.role === null || obj.role === undefined) {
          return {
            ...obj,
            role: {
              id: obj.created_at,
              name: 'Not Assigned',
            },
          };
        } else {
          return obj;
        }
      });
      const data = temp.filter((obj: any) => obj.account?.user_type === 'S' || obj.account?.user_type === 'A');
      setTotalData(data);
      setFilteredData(data);
    }
  }, [userAndRoleData_fromSelector]);

  // useEffect(()=>{
  // }, [totalData]);

  useEffect(() => {
    const filterNames = totalData?.filter((rowItem: any) => {
      return searchValue.length > 0 ? rowItem.user_name.includes(searchValue) : true;
    });
    setFilteredData(filterNames);
  }, [searchValue]);
  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    console.log({ property });
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Grid sx={{ display: 'flex' }}>
      <Paper sx={{ height: '77vh', margin: '0 15px 0 0', width: '45%', overflowY: 'hidden' }}>
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
        {userAndRoleData_fromSelectorStatus === requestState.loading ? (
          <Stack justifyContent="center" alignItems="center" height="100%">
            <CognisaasSpinner size={50} thickness={3} />
          </Stack>
        ) : (
          <TableContainer sx={{ maxHeight: '100%' }}>
            <Table
              stickyHeader={true}
              aria-labelledby="tableTitle"
              // size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
              />
              <TableBody>
                {stableSort(filteredData, order).map((row: any, index: number) => {
                  const isItemSelected = isSelected(row.first_name);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      sx={{ cursor: 'pointer' }}
                      onClick={() => {
                        props.setSelectedUser(row);
                      }}
                      // role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={index}
                      selected={isItemSelected}
                    >
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                        <UserCardComponent
                          backColor={row?.id == props?.selectedUser?.id ? '#2D5FF627' : 'white'}
                          name={row.user_name}
                          designition={row?.role?.name}
                          type={row.account.user_type}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>
      <UserInfo_RightSideComponent
        data={props.usersData}
        selectedUser={props.selectedUser}
        setTabIndex={props.setTabIndex}
        userAndRoleData_fromSelectorStatus={userAndRoleData_fromSelectorStatus}
      />
    </Grid>
  );
};

export default User;
