import { apis } from '../axiosInstance';

import { authRoutes } from '../route';

const userLogin = (loginDetails: any) => apis.iamAxiosInstance.post(authRoutes.userLogin(), loginDetails);
const userRequestChangePassword = (requestDetails: any) =>
  apis.iamAxiosInstance.post(authRoutes.userRequestChangePassword(), requestDetails);

const userSSOLogin = (loginDetails: any) => apis.phpAxiosInstance.post(authRoutes.userSSOLogin(), loginDetails);

const invalidateRefreshToken = (detail: any) => apis.iamAxiosInstance.post(authRoutes.invalidateRefreshToken(), detail);
export const authAPI = {
  userLogin,
  userRequestChangePassword,
  userSSOLogin,
  invalidateRefreshToken,
};
