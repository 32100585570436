import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ReactChild } from 'react';
import TabTouchable from './TabTouchable';
import { ReactComponent as ConfigurationIcon } from '../../../assets/svgs/confi_setting_icon.svg';
import { ReactComponent as ActiveConfigurationIcon } from '../../../assets/svgs/active_config_setting_icon.svg';
import { ReactComponent as NotificationIcon } from '../../../assets/svgs/noti_bell.svg';
import { ReactComponent as ActiveNotificationIcon } from '../../../assets/svgs/active_noti_bell.svg';
import { ReactComponent as TimeTrackingIcon } from '../../../assets/svgs/time_tracking.svg';
import { ReactComponent as ActiveTimeTrackingIcon } from '../../../assets/svgs/active_time_tracking.svg';
import { ReactComponent as IntegrationIcon } from '../../../assets/svgs/integration.svg';
import { ReactComponent as ActiveIntegrationIcon } from '../../../assets/svgs/active_integration.svg';
import { ReactComponent as AvatarIcon } from '../../../assets/svgs/avatar_setting.svg';
import { ReactComponent as ActiveAvatarIcon } from '../../../assets/svgs/selected_avatar_setting.svg';
import { ReactComponent as FieldIcon } from '../../../assets/svgs/settingField.svg';
import { ReactComponent as ActiveFieldIcon } from '../../../assets/svgs/active_settingField.svg';
import { ReactComponent as EmailSettingsIcon } from '../../../assets/svgs/email_settings.svg';
import { ReactComponent as UsersIcon } from '../../../assets/svgs/users.svg';
import { ReactComponent as ActiveUsersIcon } from '../../../assets/svgs/active_users.svg';
import { isSuperUser, isRbacEnabled } from 'utils/getUid';

interface Props {
  children: ReactChild;
}
const SettingsLayout = (props: Props) => {
  return (
    <Box sx={{ margin: '24px' }}>
      <Grid container>
        {/* left panel of settings with tabs */}
        <Grid item xs={3} sx={{ paddingRight: '16px' }}>
          <Typography sx={{ marginBottom: '16px' }} variant={'h1'}>
            Settings
          </Typography>

          {/* panels */}

          {/* profiles */}
          <TabTouchable
            icon={
              window.location.pathname == '/settings/profile' ? (
                <ActiveAvatarIcon style={{ margin: '8px', transform: 'scale(1.15)' }} />
              ) : (
                <AvatarIcon style={{ margin: '8px', transform: 'scale(1.15)' }} />
              )
            }
            label={'Profile'}
            redirectTo={'/settings/profile'}
            active={window.location.pathname == '/settings/profile' ? true : false}
          />
          {/* preferences */}
          {isSuperUser() && (
            <TabTouchable
              icon={
                window.location.pathname == '/settings/preferences' ? (
                  <ActiveAvatarIcon style={{ margin: '8px', transform: 'scale(1.15)' }} />
                ) : (
                  <AvatarIcon style={{ margin: '8px', transform: 'scale(1.15)' }} />
                )
              }
              label={'Preferences'}
              redirectTo={'/settings/preferences'}
              active={window.location.pathname == '/settings/preferences' ? true : false}
            />
          )}

          {/* configuration */}
          {isSuperUser() && (
            <TabTouchable
              icon={
                window.location.pathname == '/settings/configuration' ? (
                  <ActiveConfigurationIcon style={{ margin: '8px', transform: 'scale(1.15)' }} />
                ) : (
                  <ConfigurationIcon style={{ margin: '8px', transform: 'scale(1.15)' }} />
                )
              }
              label={'Configurations'}
              redirectTo={'/settings/configuration'}
              active={window.location.pathname == '/settings/configuration' ? true : false}
            />
          )}

          {/* Fields */}
          {isSuperUser() && (
            <TabTouchable
              icon={
                window.location.pathname == '/settings/fields' ? (
                  <ActiveFieldIcon style={{ margin: '8px', transform: 'scale(1.15)' }} />
                ) : (
                  <FieldIcon style={{ margin: '8px', transform: 'scale(1.15)' }} />
                )
              }
              label={'Fields'}
              redirectTo={'/settings/fields'}
              active={window.location.pathname == '/settings/fields' ? true : false}
            />
          )}

          {/* notifictions */}
          {isSuperUser() && (
            <TabTouchable
              icon={
                window.location.pathname == '/settings/notifications' ? (
                  <ActiveNotificationIcon style={{ margin: '8px', transform: 'scale(1.15)' }} />
                ) : (
                  <NotificationIcon style={{ margin: '8px', transform: 'scale(1.15)' }} />
                )
              }
              label={'Notifications'}
              redirectTo={'/settings/notifications'}
              active={window.location.pathname == '/settings/notifications' ? true : false}
            />
          )}

          {/* email configurations */}
          {isSuperUser() && (
            <TabTouchable
              icon={
                window.location.pathname == '/settings/email-configurations' ? (
                  <EmailSettingsIcon style={{ margin: '8px', width: '18px', height: '18px' }} />
                ) : (
                  <EmailSettingsIcon style={{ margin: '8px', width: '18px', height: '18px' }} />
                )
              }
              label={'Email Configurations'}
              redirectTo={'/settings/email-configurations'}
              active={window.location.pathname == '/settings/email-configurations' ? true : false}
            />
          )}

          {/* time tracking */}
          {isSuperUser() && (
            <TabTouchable
              icon={
                window.location.pathname == '/settings/time-tracking' ? (
                  <ActiveTimeTrackingIcon style={{ margin: '8px', transform: 'scale(1.15)' }} />
                ) : (
                  <TimeTrackingIcon style={{ margin: '8px', transform: 'scale(1.15)' }} />
                )
              }
              label={'Time Tracking'}
              redirectTo={'/settings/time-tracking'}
              active={window.location.pathname == '/settings/time-tracking' ? true : false}
            />
          )}

          {/* Integrations */}
          {isSuperUser() && (
            <TabTouchable
              icon={
                window.location.pathname == '/settings/integrations' ? (
                  <ActiveIntegrationIcon style={{ margin: '8px', transform: 'scale(1.15)' }} />
                ) : (
                  <IntegrationIcon style={{ margin: '8px', transform: 'scale(1.15)' }} />
                )
              }
              label={'Integrations'}
              redirectTo={'/settings/integrations'}
              active={window.location.pathname == '/settings/integrations' ? true : false}
            />
          )}
          {/* Users */}
          {isSuperUser() && (
            <TabTouchable
              icon={
                window.location.pathname == '/settings/users' ? (
                  <ActiveUsersIcon style={{ margin: '8px', transform: 'scale(1.15)' }} />
                ) : (
                  <UsersIcon style={{ margin: '8px', transform: 'scale(1.15)' }} />
                )
              }
              label={'User Management'}
              redirectTo={'/settings/users'}
              active={window.location.pathname == '/settings/users' ? true : false}
            />
          )}
          {/* Access Management */}
          {isSuperUser() && isRbacEnabled() && (
            <TabTouchable
              icon={
                window.location.pathname == '/settings/access-management' ? (
                  <ActiveIntegrationIcon style={{ margin: '8px', transform: 'scale(1.15)' }} />
                ) : (
                  <IntegrationIcon style={{ margin: '8px', transform: 'scale(1.15)' }} />
                )
              }
              label={'Access Management'}
              redirectTo={'/settings/access-management'}
              active={window.location.pathname == '/settings/access-management' ? true : false}
            />
          )}
        </Grid>
        {/* right panel */}
        <Grid item xs={9}>
          <Box
            sx={{ background: 'white', borderRadius: '4px', height: '80vh', overflowY: 'scroll', overflowX: 'clip' }}
          >
            {/* children go here */}
            {props.children}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SettingsLayout;
