import { Typography, Stack, TextField, Switch } from '@mui/material';
import CognisaasSelect from '../../shared/CognisaasSelect/CognisaasSelect';
import {
  nearestDate,
  shouldUpdateDateOfDependents,
  shouldUpdateDateOfDependentsWhenMovingBackward,
} from '../taskUtils';
import { useEffect, useState } from 'react';
import { selectCurrentUserDetails, selectCustomerDetail } from '../../../store/modules/Common/selector';
import { useAppSelector, useAppDispatch } from '../../../store/hooks';
import { TextareaAutosize } from '@mui/material';
import * as yup from 'yup';
import { Formik } from 'formik';
import { fetchClientContacts } from '../../../store/modules/ManageClient/ClientList/slice';
import { fetchClientInformation } from '../../../store/modules/ManageClient/ClientList/slice';
import {
  addTaskAuditTrail,
  addClientTaskAuditTrail,
  addProjectTaskAuditTrial,
  addProjectClientTaskAuditTrial,
  getJiraProjectIssueTypesList,
  syncAllJiraTasks,
  getUsecaseSubtasks,
  updateUseCaseDependencyDueDate,
  updateUseCaseDependencyDeliveryDate,
  updateProjectDependencyDueDate,
  updateProjectDependencyDeliveryDate,
  getJiraProjects,
  getJiraPriorityList,
  getTaskCustomfieldsWithValue,
  updateTaskDetailModal,
  updateTaskDetailFormRef,
  updateTaskDetailModalInitialState,
} from '../../../store/modules/Task/slice';
import BasicModal from '../../shared/CognisaasModal/CognisaasModal';
import AssignTaskToClientModal from './AssignTaskToClientModal';
import { ReactComponent as EmptyMileStone } from '../../../assets/svgs/EmptyMileStone.svg';
import CognisaasCalenderNew from '../../shared/CognisaasCalenderNew/CognisaasCalenderNew';
import {
  getCustomerUid,
  getLoggedInUserUid,
  getUserNameByUserUid,
  getContactNameByContactUid,
  getContactUidByContactName,
  getUserUid,
  getCustomerSubDomain,
} from '../../../utils/getUid';

import CognisaasButton from '../../shared/CognisaasButton/CognisaasButton';
import CognisaasUserSelect from 'components/shared/CognisaasSelect/CognisaasUserSelect';
import AssignTaskToTeamModal from './AssignTaskToTeamModal';
import format from 'date-fns/format';
import { getCurrentUserDetails, updateCaseWithfeatureStatus, getAllUsers } from '../../../store/modules/Common/slice';
import {
  findCriticalPath,
  getPathOfThisTask,
  getProjectByProjectUid,
  getProjectInformation,
  getTaskChildren,
  getTaskParents,
  getTasksPathOfProject,
  updateTaskDelay,
} from '../../../store/modules/Project/slice';
import { getProjectSubtasks } from '../../../store/modules/Task/slice';
import { getProjectInformationWithCaseTaskByUid } from '../../../store/modules/Project/slice';
import {
  selectJiraPriorityList,
  selectJiraProjectIssueType,
  selectJiraProjects,
  selectTaskCustomfieldsWithValue,
  selectTaskModalDetailsFormRef,
} from 'store/modules/Task/selector';
import { getJiraJsdServicesRequestLists, getJiraProjectsFromApi } from 'store/modules/Settings/slice';
import {
  addTaskToJira,
  addTaskToJiraServiceDesk,
  getJiraCustomFieldNames,
  getJiraCustomFields,
  getJiraIssueId,
  getJiraIssueName,
  getJiraProjectIdFromProjectKey,
  getJiraProjectKeyFromProjectId,
  getJiraProjectKeyFromProjectName,
  getMandatoryCustomFieldsForIssue,
  getMandatoryCustomFieldsForJsdServiceRequests,
  updateTaskToJira,
} from '../jiraHelpers/jiraUtil';
import JiraCustomFields from '../jiraHelpers/JiraCustomFields';
import { selectProjectInformation } from 'store/modules/Project/selector';
import { isNull } from 'utils/dataHelpers';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import { selectJiraServiceDeskRequestLists } from 'store/modules/Settings/selector';
import { jiraAPI } from 'api/modules/jira';
import JiraServiceDeskCustomFields from '../jiraHelpers/JiraServiceDeskCustomFields';
import CognisaaasTooltip from 'components/shared/CognisaasTooltip/CognisaasTooltip';
import CognisaasToast from 'components/shared/CognisaasToast/CognisaasToast';
import { taskAPI } from 'api/modules/tasks';
import {
  selectProjectSubtasks,
  selectUsecaseSubtasks,
  selectTaskModalDetail,
  selectTaskModalInitialState,
} from '../../../store/modules/Task/selector';
import { useParams } from 'react-router-dom';
import { usePreselectedProjectMembers } from 'components/shared/Hooks/usePreselectedProjectMembers';
import { getUsecaseByUid } from 'store/modules/Usecase/slice';
import {
  label_client,
  label_delivery_date,
  label_due_date,
  label_task,
} from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { userPermissionSchema } from 'components/Reports/SideDrawer/types';
import { getAttachmentsFromServer } from 'store/modules/Attachment/slice';
import { selectAttachmentDataDownloadQueue } from 'store/modules/Attachment/selector';
import { addDays, differenceInDays, startOfDay } from 'date-fns';
import BasicDateRangePicker from 'components/shared/BasicDateRangePicker/BasicDateRangePicker';
import DateShiftConfirmationModal from './DateShiftConfirmationModal';
import useCustomNames from 'components/shared/CustomNames/useCustomNames';
import NAMED_CONSTATNTS from 'components/shared/CustomNames/Constants';
import { getUserActiveState } from 'utils/userHelpers';
interface Props {
  client: Record<string, any>;
  taskDetail: Record<string, any>;
  onClose: any;
  drawerHeaderTextField: any;
  phaseUid: string;
  caseName?: string;
  projectInformation: any;
  userPermissions?: any;
  taskDetailFormRef?: any;
}

const EditTask = (props: Props) => {
  const task = props.taskDetail;
  const client = props.client;
  const closeDrawer = props.onClose;
  const params = useParams();
  const { userPermissions } = props;
  const PROJECT = 'PROJECT';
  const USECASE = 'USECASE';

  const customerDetail = useAppSelector(selectCustomerDetail);
  const dispatch = useAppDispatch();
  const taskDetailForModal = useAppSelector(selectTaskModalDetail);
  const taskModalDetailsFormRef = useAppSelector(selectTaskModalDetailsFormRef);
  const projectInfo = useAppSelector(selectProjectInformation);
  const projectSubtasks = useAppSelector(selectProjectSubtasks);
  const usecaseSubtasks = useAppSelector(selectUsecaseSubtasks);
  const initialStateForm = useAppSelector(selectTaskModalInitialState);
  const taskCustomfieldsWithValues = useAppSelector(selectTaskCustomfieldsWithValue);

  const [assignTaskOwnerToClientModal, setAssignTaskOwnerToClientModal] = useState<boolean>(false);
  const [assignTaskOwnerToTeamModal, setAssignTaskOwnerToTeamModal] = useState<boolean>(false);
  const [ucDetail, setUcDetail] = useState<any>(null);
  const [dateShiftConfirmation, setDateShiftConfirmation] = useState<boolean>(false);
  const [shiftedDays, setShiftedDays] = useState<number>(0);
  const [isContinuing, setIsContinuing] = useState<boolean>(false);
  const [updatedFormData, setUpdatedFormData] = useState<any>(null);
  // const [taskDueDate, setTaskDueDate] = useState<any>('');
  const attachmentDataQueueState = useAppSelector(selectAttachmentDataDownloadQueue);
  const [isJiraEnabled, setIsJiraEnabled] = useState(() => {
    if (isNull(task.jira_issue_id)) {
      return false;
    } else {
      return task.jira_issue_id.trim() != '';
    }
  });
  // for jira updation
  const jiraProjectList = useAppSelector(selectJiraProjects);
  const issueTypes = useAppSelector(selectJiraProjectIssueType);
  const jiraPriorityList = useAppSelector(selectJiraPriorityList);
  const jiraPriorityValues = jiraPriorityList.map((item: any) => item.name);
  const isJiraAlreadyEnabled = (() => {
    if (isNull(task.jira_issue_id)) {
      return false;
    } else {
      return task.jira_issue_id.trim() != '';
    }
  })();
  const [selectedJiraProject, setSelectedJiraProject] = useState<string>('');
  const [selectedJiraIssueType, setSelectedJiraIssueType] = useState<string>('');
  const [selectedJiraPriority, setSelectedJiraPriority] = useState<string>('');
  const [jiraCustomfields, setJiraCustomfields] = useState<any>(null);
  const [jiraCustomFieldsModal, setJiraCustomFieldsModal] = useState<boolean>(false);
  const [taskDetailsToBeAdded, setTaskDetailsToBeAdded] = useState<Record<any, any>>({});
  const [jiraCustomFieldsModalForUpdate, setJiraCustomFieldsModalForUpdate] = useState<boolean>(false);
  const currentUserDetails = useAppSelector(selectCurrentUserDetails);
  const [isSaveInProgress, setIsSaveInProgress] = useState<boolean>(false);
  const [alertDetails, setAlertDetails] = useState<{
    isVisible: boolean;
    type: 'success' | 'failure' | 'info';
    message: string;
  }>({ isVisible: false, type: 'success', message: '' });
  const [isJSDenabled, setIsJSDenabled] = useState<boolean>(false);
  const jiraServicesList = useAppSelector(selectJiraServiceDeskRequestLists);
  const [jsdCustomFieldsModal, setJsdCustomFieldsModal] = useState<boolean>(false);
  const [jiraIssueTypesNamesList, setJiraIssueTypesNamesList] = useState<string[]>([]);
  const [jiraTaskPayload, setJiraTaskPayload] = useState<Record<string, any>>({});
  const [somethingChangedInJira, setSomethingChangedInJira] = useState<boolean>(false);
  const [client_uid, setclient_uid] = useState(props.client.client_uid);
  const [association_uid, setassociation_uid] = useState(
    props?.projectInformation?.association_uid ? props.projectInformation.association_uid : ''
  );
  const {
    taskStagesDropdownValues,
    taskPrioritiesDropdownValues,
    getCustomNameMappedValue,
    getStandardNameMappedValue,
  } = useCustomNames();
  const taskAllStages = taskStagesDropdownValues.map((item: any) => item.custom_stage_name);
  const taskAllPriorities = taskPrioritiesDropdownValues.map((item: any) => item.custom_priority_name);

  const { preselectedProjectMembers, preselectedClientContacts } = usePreselectedProjectMembers(task.project_uid,props.client.client_uid);
  const notAssignedStr = 'Not Assigned';
  const notAssignedId = '15243';
  const preselectedProejctMembersWithNotAssigned = preselectedProjectMembers?.length
    ? [...preselectedProjectMembers, { name: notAssignedStr, id: notAssignedId }]
    : [{ name: notAssignedStr, id: notAssignedId }];
  const preselectedClientContactsWithNotAssigned = preselectedClientContacts?.length
    ? [...preselectedClientContacts, { name: notAssignedStr, id: notAssignedId }]
    : [{ name: notAssignedStr, id: notAssignedId }];

  const getNamebyTaskOwnerUid = (uid: any, isTeamTask: any) => {
    if (isTeamTask == 1) {
      const name = getUserNameByUserUid(uid);
      return name;
    } else {
      const name = getContactNameByContactUid(uid);
      return name;
    }
  };

  const initialForm = {
    taskName: task.task_name,
    taskStage: task.task_stage,
    plannedStartDate: task.task_planned_start_date,
    actualStartDate: task.task_start_date,
    taskPriority: task.task_priority,
    taskOwner: {
      id: task.is_team_task == '1' ? task.task_owner : task.contact_uid,
      name: getNamebyTaskOwnerUid(task.is_team_task == '1' ? task.task_owner : task.contact_uid, task.is_team_task),
    },
    implementationFee: task.task_implementation_fee_percentage,
    shareWithClient: task.client_check == 0 ? false : true,
    clientInitated: task.client_initiate,
    csatIndicator: task.csat_indicator == 0 ? false : true,
    taskDescription: task.task_description,
    taskDeliveryDate: task.task_closed_date,
    taskDueDate: task.task_due_date,
    pushToJira: task.jira_ind == '0' || task.jira_ind == null ? false : true,
    jiraIssueId: task.jira_issue_id,
    jiraPriority: task.jira_priority,
    jiraIssueTypeId: task.jira_issue_type,
    isMileStone: task.milestone_task == 0 ? false : true,
    isTeamTask: task.is_team_task,
    rangeValue: [task.task_planned_start_date, task.task_due_date],
    taskReporter:
      task.task_reporter === ''
        ? {
            id: '15243',
            name: 'Not Assigned',
          }
        : {
            id: getUserUid(task.task_reporter)
              ? getUserUid(task.task_reporter)
              : getContactUidByContactName(task.task_reporter)
              ? getContactUidByContactName(task.task_reporter)
              : task.task_reporter,
            name: getContactNameByContactUid(task.task_reporter)
              ? getContactNameByContactUid(task.task_reporter)
              : getUserNameByUserUid(task.task_reporter)
              ? getUserNameByUserUid(task.task_reporter)
              : task.task_reporter,
          },
  };
  if (!initialStateForm) {
    dispatch(updateTaskDetailModalInitialState(initialForm));
  }

  let initialValues: any = null;
  if (taskDetailForModal) {
    // if (!initialValues) {
    initialValues = { ...taskDetailForModal };
    // setIsJiraEnabled(taskDetailForModal.pushToJira);
    if (taskDetailForModal.pushToJira && taskDetailForModal.jiraIssueId?.length > 0) {
      if (selectedJiraPriority !== taskDetailForModal.jiraPriority) {
        setSelectedJiraPriority(taskDetailForModal.jiraPriority);
      }
      if (
        selectedJiraIssueType !== getJiraIssueName(issueTypes, taskDetailForModal.jiraIssueTypeId, isJiraAlreadyEnabled)
      ) {
        setSelectedJiraIssueType(
          getJiraIssueName(issueTypes, taskDetailForModal.jiraIssueTypeId, isJiraAlreadyEnabled)
        );
      }
    }
  } else {
    initialValues = {
      taskName: task.task_name,
      taskStage: task.task_stage,
      plannedStartDate: task.task_planned_start_date,
      actualStartDate: task.task_start_date,
      taskPriority: task.task_priority,
      taskOwner: {
        id: task.is_team_task == '1' ? task.task_owner : task.contact_uid,
        name: getNamebyTaskOwnerUid(task.is_team_task == '1' ? task.task_owner : task.contact_uid, task.is_team_task),
        is_active: getUserActiveState(task.task_owner, preselectedProjectMembers),
      },
      implementationFee: task.task_implementation_fee_percentage,
      shareWithClient: task.client_check == 0 ? false : true,
      clientInitated: task.client_initiate,
      csatIndicator: task.csat_indicator == 0 ? false : true,
      taskDescription: task.task_description,
      taskDeliveryDate: task.task_closed_date,
      taskDueDate: task.task_due_date,
      pushToJira: task.jira_ind == '0' || task.jira_ind == null ? false : true,
      jiraIssueId: task.jira_issue_id,
      jiraPriority: task.jira_priority,
      jiraIssueTypeId: task.jira_issue_type,
      isMileStone: task.milestone_task == 0 ? false : true,
      isTeamTask: task.is_team_task,
      rangeValue: [task.task_planned_start_date, task.task_due_date],
      taskReporter:
        task.task_reporter === ''
          ? {
              id: '15243',
              name: 'Not Assigned',
            }
          : {
              id: getUserUid(task.task_reporter)
                ? getUserUid(task.task_reporter)
                : getContactUidByContactName(task.task_reporter)
                ? getContactUidByContactName(task.task_reporter)
                : task.task_reporter,
              name: getContactNameByContactUid(task.task_reporter)
                ? getContactNameByContactUid(task.task_reporter)
                : getUserNameByUserUid(task.task_reporter)
                ? getUserNameByUserUid(task.task_reporter)
                : task.task_reporter,
            },
    };
  }

  const getUsecaseOrProject = async (isProject: boolean, uid: string, fieldWanted: string) => {
    if (isProject) {
      const data = await dispatch(getProjectByProjectUid(uid)).unwrap();
      if (fieldWanted == 'name') {
        return data[0].project_name;
      } else if (fieldWanted == 'associationUid') {
        if (data[0].association_uid && association_uid == '') {
          setassociation_uid(data[0].association_uid);
        }
        return data[0].association_uid;
      } else if (fieldWanted == 'clientUid') {
        if (data[0].client_uid && client_uid == '') {
          setclient_uid(data[0].client_uid);
        }
        return data[0].client_uid;
      }
      return data[0];
    } else {
      const data = await dispatch(getUsecaseByUid(uid)).unwrap();
      if (fieldWanted == 'name') {
        return data[0].case_name;
      } else if (fieldWanted == 'associationUid') {
        if (data[0].association_uid && association_uid == '') {
          setassociation_uid(data[0].association_uid);
        }
        return data[0].association_uid;
      } else if (fieldWanted == 'client') {
        if (data[0].client_uid && client_uid == '') {
          setclient_uid(data[0].client_uid);
        }
        return data[0].client_uid;
      }
      return data[0];
    }
  };

  const getProjectInfo = async () => {
    await getUsecaseOrProject(true, task.project_uid, 'associationUid');
    const detail = {
      association_uid: association_uid,
      project_uid: task.project_uid,
    };
    const test = {
      project_uid: task.project_uid,
    };

    await dispatch(fetchClientInformation(client_uid));
    await dispatch(getProjectInformationWithCaseTaskByUid(test));
    await dispatch(getProjectInformation(detail));
  };

  // * getting project information if task drawer is opened directly from client or somewhere else
  useEffect(() => {
    if (isNull(projectInfo)) {
      getProjectInfo();
    }
    if (task?.case_uid) {
      getUc();
    }
  }, [projectInfo]);

  const getUc = async () => {
    const uc = await dispatch(getUsecaseByUid(task.case_uid)).unwrap();
    setUcDetail(uc[0]);
  };

  useEffect(() => {
    dispatch(getAllUsers());
    dispatch(getTaskCustomfieldsWithValue(task.task_uid));
    dispatch(getAttachmentsFromServer(`getAttachmentsByTaskUid?task_uid=${task.task_uid}`));
    if (currentUserDetails.status !== 'success') {
      dispatch(getCurrentUserDetails());
    }
  }, []);

  useEffect(() => {
    if (jiraProjectList.length > 0) {
      if (isJiraAlreadyEnabled) {
        setSelectedJiraProject(getJiraProjectKeyFromProjectId(jiraProjectList, task.jira_project_id));
      } else {
        setSelectedJiraProject(getJiraProjectKeyFromProjectName(jiraProjectList, getJiraProjectValues()[0]));
      }
    }
  }, [jiraProjectList, isJiraAlreadyEnabled]);

  useEffect(() => {
    if (customerDetail && customerDetail.customer_jira_user.trim().length > 0 && customerDetail.customer_JSD != '1') {
      if (!isJSDenabled && issueTypes.length > 0) {
        if (isJiraAlreadyEnabled) {
          setSelectedJiraIssueType(getJiraIssueName(issueTypes, task.jira_issue_type, isJiraAlreadyEnabled));
        } else {
          setSelectedJiraIssueType(getJiraIssueNamesArray()[0]);
        }
      }
      setJiraIssueTypesNamesList(issueTypes.map((item) => item.name));
    }
  }, [issueTypes, isJiraAlreadyEnabled, isJSDenabled]);

  useEffect(() => {
    if (isJSDenabled) {
      if (jiraServicesList.data.values && jiraServicesList.data.values.length > 0) {
        const choosenService = jiraServicesList.data.values.filter((item: any) => item.id == task.jira_issue_type);
        const allServicesList = jiraServicesList.data.values.map((item: any) => item.name);
        if (task.jira_issue_type && task.jira_issue_type.length > 0) {
          setSelectedJiraIssueType(choosenService[0]?.name);
        } else {
          setSelectedJiraIssueType(allServicesList[0]?.name);
        }
        setJiraIssueTypesNamesList(allServicesList);
      }
    }
  }, [jiraServicesList.data, isJSDenabled]);

  useEffect(() => {
    if (jiraPriorityList.length > 0) {
      if (isJiraAlreadyEnabled) {
        setSelectedJiraPriority(task.jira_priority);
      } else {
        setSelectedJiraPriority(jiraPriorityValues[0]);
      }
    }
  }, [jiraPriorityList, isJiraAlreadyEnabled]);

  // const prevAmount = usePrevious(props.drawerHeaderTextField);

  useEffect(() => {
    if (customerDetail && customerDetail.customer_jira_user.trim().length > 0) {
      if (customerDetail.customer_jira_user && customerDetail.customer_jira_user.length > 0) {
        const data = {
          customer_uid: getCustomerUid(),
          key: btoa(btoa(`${customerDetail.customer_jira_user}:${customerDetail.customer_jira_token}`)),
        };
        dispatch(getJiraProjectsFromApi(data));
        dispatch(getJiraProjects());
        const detail = {
          key: btoa(btoa(`${customerDetail.customer_jira_user}:${customerDetail.customer_jira_token}`)),
          customer_uid: customerDetail.customer_uid,
        };
        dispatch(getJiraPriorityList(detail));
      }
      setIsJSDenabled(customerDetail.customer_JSD === '1');
    }
  }, [customerDetail]);

  const getJiraProjectValues = () => {
    if (jiraProjectList.length > 0) {
      return jiraProjectList.map((item: any) => item.jira_project_name);
    }
    return [];
  };

  const updateJiraProjectkey = async (project_key: string) => {
    setSelectedJiraProject(project_key);

    const data = {
      customer_uid: getCustomerUid(),
      key: btoa(btoa(`${customerDetail.customer_jira_user}:${customerDetail.customer_jira_token}`)),
      project_key: project_key,
    };
    await dispatch(getJiraProjectIssueTypesList(data));
  };

  const getJiraIssueNamesArray = () => {
    return issueTypes.map((item) => item.name);
  };

  useEffect(() => {
    dispatch(fetchClientInformation(client.client_uid));
    dispatch(fetchClientContacts(client.client_uid));
    if (task.project_uid && task.case_uid) {
      dispatch(getUsecaseSubtasks(task.task_uid));
    } else {
      if (task.project_uid && !task.case_uid) {
        dispatch(getProjectSubtasks(task.task_uid));
      }
    }
  }, []);

  useEffect(() => {
    if (isJiraEnabled && jiraProjectList.length > 0 && selectedJiraProject.length > 0) {
      setSomethingChangedInJira(true);
      const selectedProjectFullData = jiraProjectList.filter((item: any) => item.project_key == selectedJiraProject)[0];
      const key = btoa(btoa(`${customerDetail.customer_jira_user}:${customerDetail.customer_jira_token}`));
      if (selectedProjectFullData.jira_service_desk_id && selectedProjectFullData.jira_service_desk_id == '1') {
        // get jsd service requests or may be no needed
        dispatch(
          getJiraJsdServicesRequestLists({
            customer_uid: customerDetail.customer_uid,
            key: key,
            jira_service_desk_id: jiraProjectList[0].jira_service_desk_id,
          })
        );
      } else {
        // normal jira workflow
        const data = {
          customer_uid: customerDetail.customer_uid,
          key: key,
          project_key: jiraProjectList[0].project_key,
        };
        dispatch(getJiraProjectIssueTypesList(data));
      }
    }
  }, [selectedJiraProject, customerDetail, isJiraEnabled]);

  const getJsdIssueId = (): string => {
    const required_issue = jiraServicesList.data.values.filter((item: any) => item.name === selectedJiraIssueType);
    if (required_issue.length > 0) {
      return required_issue[0].id;
    }
    return '';
  };

  const taskValidationSchema = yup.object().shape({
    taskDueDate: yup.string().required('Due date is required'),
  });

  const base_com_url = window.location.host + '/customer-portal/' + getCustomerSubDomain() + '/';

  const dispatchTrailByTaskType = async (detail: any) => {
    if (task.project_uid && task.case_uid) {
      if (task.is_team_task === 1 || task.is_team_task === '1') {
        await dispatch(addTaskAuditTrail(detail));
      } else {
        await dispatch(addClientTaskAuditTrail(detail));
      }
    } else if (task.project_uid.length > 0 && task.case_uid == null) {
      if (task.is_team_task === 1 || task.is_team_task === '1') {
        await dispatch(addProjectTaskAuditTrial(detail));
      } else {
        await dispatch(addProjectClientTaskAuditTrial(detail));
      }
    }
  };

  console.log('intial vals', initialValues);

  const addAuditTrailForTaskUpdate = async (oldForm: any, newForm: any) => {
    // setIsSaveInProgress(true);
    for (const i in newForm) {
      if (newForm[i] != oldForm[i]) {
        let detail: any = {};
        if (i == 'taskOwner' && newForm[i].name !== oldForm[i].name) {
          detail = {
            field: 'Task Owner',
            task_uid: task.task_uid,
            user_uid: getLoggedInUserUid(),
            old_value: oldForm[i].id,
            new_value: newForm[i].id,
            modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
          };
          dispatchTrailByTaskType(detail);
        } else if (i == 'taskPriority') {
          detail = {
            field: 'Priority',
            task_uid: task.task_uid,
            user_uid: getLoggedInUserUid(),
            old_value: oldForm[i],
            new_value: newForm[i],
            modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
          };
          dispatchTrailByTaskType(detail);
        } else if (i == 'taskReporter') {
          detail = {
            field: 'Task Reporter',
            task_uid: task.task_uid,
            user_uid: getLoggedInUserUid(),
            old_value: oldForm[i].id,
            new_value: newForm[i].id,
            modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
          };
          dispatchTrailByTaskType(detail);
        } else if (i == 'taskDescription') {
          detail = {
            field: 'Description',
            task_uid: task.task_uid,
            user_uid: getLoggedInUserUid(),
            old_value: oldForm[i],
            new_value: newForm[i],
            modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
          };
          dispatchTrailByTaskType(detail);
        } else if (i == 'taskDeliveryDate') {
          detail = {
            field: 'Delivery Date',
            task_uid: task.task_uid,
            user_uid: getLoggedInUserUid(),
            old_date: oldForm[i],
            new_date: newForm[i],
            modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
          };
          dispatchTrailByTaskType(detail);
        } else if (i == 'taskDueDate') {
          detail = {
            field: 'Due Date',
            task_uid: task.task_uid,
            user_uid: getLoggedInUserUid(),
            old_date: oldForm[i],
            new_date: newForm[i],
            modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
          };
          dispatchTrailByTaskType(detail);
        } else if (i == 'actualStartDate') {
          detail = {
            field: 'Start Date',
            task_uid: task.task_uid,
            user_uid: getLoggedInUserUid(),
            old_date: oldForm[i],
            new_date: newForm[i],
            modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
          };
          dispatchTrailByTaskType(detail);
        } else if (i == 'plannedStartDate') {
          detail = {
            field: 'Planned Start Date',
            task_uid: task.task_uid,
            user_uid: getLoggedInUserUid(),
            old_date: oldForm[i],
            new_date: newForm[i],
            modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
          };
          dispatchTrailByTaskType(detail);
        } else if (i == 'taskStage') {
          detail = {
            field: 'Stage',
            task_uid: task.task_uid,
            user_uid: getLoggedInUserUid(),
            old_value: oldForm[i],
            new_value: newForm[i],
            modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
          };
          dispatchTrailByTaskType(detail);
        } else if (i == 'implementationFee') {
          detail = {
            field: 'Implementation Fee (%)',
            task_uid: task.task_uid,
            user_uid: getLoggedInUserUid(),
            old_value: oldForm[i],
            new_value: newForm[i],
            modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
          };
          dispatchTrailByTaskType(detail);
        } else if (i == 'shareWithClient') {
          detail = {
            field: 'Share with client',
            task_uid: task.task_uid,
            user_uid: getLoggedInUserUid(),
            old_value: oldForm[i] === false ? 'Off' : 'On',
            new_value: newForm[i] === false ? 'Off' : 'On',
            modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
          };
          dispatchTrailByTaskType(detail);
        } else if (i == 'csatIndicator') {
          detail = {
            field: 'CSAT',
            task_uid: task.task_uid,
            user_uid: getLoggedInUserUid(),
            old_value: oldForm[i] === false ? 'Off' : 'On',
            new_value: newForm[i] === false ? 'Off' : 'On',
            modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
          };
          dispatchTrailByTaskType(detail);
        } else if (i == 'isMileStone') {
          detail = {
            field: 'Milestone Task',
            task_uid: task.task_uid,
            user_uid: getLoggedInUserUid(),
            old_value: oldForm[i] === false ? 'Off' : 'On',
            new_value: newForm[i] === false ? 'Off' : 'On',
            modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
          };
          dispatchTrailByTaskType(detail);
        }
      }
    }
  };

  const datesPushActivity = async (newForm: any) => {
    setUpdatedFormData(newForm);
    if (newForm.taskDeliveryDate.split(' ')[0] != task.task_closed_date.split(' ')[0]) {
      console.log(
        'Dates are nit same show pop up ',
        isContinuing,
        newForm.taskDeliveryDate.split(' ')[0],
        task.task_closed_date.split(' ')[0]
      );
      let paths: any[];
      let criticalPath: any;

      if (task.case_uid == '' || !task.case_uid) {
        const pay = {
          project_uid: task.project_uid,
          type: PROJECT,
        };
        paths = await dispatch(getTasksPathOfProject(pay)).unwrap();
        criticalPath = await dispatch(findCriticalPath(pay)).unwrap();
      } else {
        const pay = {
          project_uid: task.case_uid,
          type: USECASE,
        };
        paths = await dispatch(getTasksPathOfProject(pay)).unwrap();
        criticalPath = await dispatch(findCriticalPath(pay)).unwrap();
      }

      console.log(paths, criticalPath.result[0]);
      let isThisTaskInCriticalPath = false;
      let notInCriticalPathButAffectsProjectETA = false;
      let actualImpactOnChildren = 0;
      for (let i = 0; i < criticalPath.result.length; i++) {
        for (let j = 0; j < criticalPath.result[i].length; j++) {
          if (criticalPath.result[i][j].task_uid == task.task_uid) {
            isThisTaskInCriticalPath = true;
          }
        }
      }
      // Check if other critical task if any (If other critical task is greater than our shift continue updating)

      // if (isThisTaskInCriticalPath) {
      //   for (let i = 0; i < criticalPath.result.length; i++) {
      //     if (
      //       new Date(criticalPath.result[i][0].task_closed_date.split(' ')[0]) >
      //       new Date(newForm.taskDeliveryDate.split(' ')[0])
      //     ) {
      //       if (task.task_uid != criticalPath.result[i][0].task_uid) {
      //         isThisTaskInCriticalPath = false; // Shift it will not affect projectETA
      //       }
      //     }
      //   }
      // }

      // If not in critical path , check if this task date delay might cause project delay
      let pathOfThisTask: any;
      if (!isThisTaskInCriticalPath) {
        if (task.case_uid == '' || !task.case_uid) {
          const pay = {
            project_uid: task.project_uid,
            task_uid: task.task_uid,
            type: PROJECT,
          };
          pathOfThisTask = await dispatch(getPathOfThisTask(pay)).unwrap();
          console.log(pathOfThisTask);
        } else {
          const pay = {
            project_uid: task.case_uid,
            task_uid: task.task_uid,
            type: USECASE,
          };
          pathOfThisTask = await dispatch(getPathOfThisTask(pay)).unwrap();
          console.log(pathOfThisTask);
        }

        // ( new Date(newForm.taskDeliveryDate.split(' ')[0]) >
        // new Date(pathOfThisTask[i][pathOfThisTask[i].length - 1].task_closed_date.split(' ')[0]))  && (  )
        for (let i = 0; i < pathOfThisTask.length; i++) {
          const numberShifted = differenceInDays(
            new Date(newForm.taskDeliveryDate.split(' ')[0]),
            new Date(task.task_closed_date.split(' ')[0])
          );
          // console.log(
          //   numberShifted,
          //   addDays(
          //     new Date(pathOfThisTask[i][pathOfThisTask[i].length - 1].task_closed_date.split(' ')[0]),
          //     numberShifted
          //   ),
          //   new Date(task.eta_forprojectdelivery.split(' ')[0])
          // );

          // If this current task will affect  immediate task?
          //  i.e.., If that task and immediate task difference is > numberShifted then there is an impact
          if (pathOfThisTask[i].length > 1) {
            actualImpactOnChildren =
              differenceInDays(
                new Date(task.task_closed_date.split(' ')[0]),
                new Date(pathOfThisTask[i][1].task_start_date.split(' ')[0])
              ) -
                1 <
              numberShifted
                ? numberShifted -
                  (differenceInDays(
                    new Date(pathOfThisTask[i][1].task_start_date.split(' ')[0]),

                    new Date(task.task_closed_date.split(' ')[0])
                  ) -
                    1)
                : 0;
          }

          if (actualImpactOnChildren > 0) {
            // isThisTaskInCriticalPath = true;
            // Also check if any parallel task is >= our task due
            notInCriticalPathButAffectsProjectETA = true;
            break;
          }
        }
        // No children , so check if that change affects eta
        if (pathOfThisTask.length == 1 && pathOfThisTask[0].length == 1) {
          if (task.case_uid == '' || !task.case_uid) {
            if (new Date(newForm.taskDeliveryDate) > new Date(task.eta_forprojectdelivery)) {
              notInCriticalPathButAffectsProjectETA = true;
              actualImpactOnChildren = differenceInDays(
                new Date(newForm.taskDeliveryDate.split(' ')[0]),
                new Date(task.eta_forprojectdelivery.split(' ')[0])
              );
            }
          } else {
            if (new Date(newForm.taskDeliveryDate) > new Date(task.eta_forcasedelivery)) {
              notInCriticalPathButAffectsProjectETA = true;
              actualImpactOnChildren = differenceInDays(
                new Date(newForm.taskDeliveryDate.split(' ')[0]),
                new Date(task.eta_forcasedelivery.split(' ')[0])
              );
            }
          }
        }
      }

      // if (isThisTaskInCriticalPath) {
      //   console.log(
      //     'This task will move ETA',
      //     differenceInDays(new Date(newForm.taskDeliveryDate.split(' ')[0]), criticalPath.result[0][0].task_closed_date)
      //   );
      // }

      // const updatedTaskDifferenceFromCritical = differenceInDays(
      //   new Date(criticalPath.result[0][0].task_closed_date),
      //   new Date(task.task_closed_date)
      // );
      // task is affecting project ETA
      // const taskWithFloatDetail: any[] = paths.filter((path: any) => path.task_uid == task.task_uid);
      // console.log(isThisTaskInCriticalPath, updatedTaskDifferenceFromCritical);
      if (isThisTaskInCriticalPath) {
        // THIS IS A CRITICAL TASK
        console.log('Show popup to contiPnue');
        setDateShiftConfirmation(true);
        setShiftedDays(differenceInDays(new Date(newForm.taskDeliveryDate), new Date(task.task_closed_date)));
      } else if (notInCriticalPathButAffectsProjectETA && !isThisTaskInCriticalPath) {
        setDateShiftConfirmation(true);
        setShiftedDays(actualImpactOnChildren);
      } else {
        // No impact on project ETA go ahead with usual flow
        updateTaskDetail(newForm);
      }
    } else {
      updateTaskDetail(newForm);
    }
  };

  // useEffect(() => {
  //   if (isContinuing) {
  //     doContinue(isContinuing);
  //   }
  // }, [isContinuing]);

  const updateTaskDetail = async (newForm: any) => {
    console.log(newForm, task);

    // return;

    // const lastTask = path.reduce((a: any, b: any) => (a.lf > b.lf ? a : b));

    setIsSaveInProgress(true);

    let issueId = '';
    let projectId = '';

    if (isJiraEnabled) {
      if (isJSDenabled) {
        issueId = getJsdIssueId();
      } else {
        issueId = getJiraIssueId(issueTypes, selectedJiraIssueType as string);
      }
      projectId = getJiraProjectIdFromProjectKey(jiraProjectList, selectedJiraProject);
    }

    let detail: any;

    if (!task.case_uid) {
      detail = {
        dependency_details: [],
        base_com_url: base_com_url,
        customer_company: getCustomerSubDomain(),
        customer_uid: customerDetail.customer_uid,
        client_uid: client.client_uid,
        case_uid: task.case_uid,
        project_uid: task.project_uid,
        phase_uid: task.section_uid,
        task_uid: task.task_uid,
        task_name: task.task_name,
        hours_per_day: task.hours_per_day,
        task_owner:
          newForm.taskOwner.id == '15243' || newForm.taskOwner.name == 'Not Assigned' ? '15243' : newForm.taskOwner.id,
        task_reporter: newForm.taskReporter,
        task_reporter_uid: getUserUid(newForm.taskReporter),
        task_start_date: newForm.actualStartDate,
        task_due_date: newForm.taskDueDate,
        task_planned_start_date: newForm.plannedStartDate,
        task_closed_date: newForm.taskDeliveryDate,
        task_description: newForm.taskDescription,
        task_percentage: '0',
        task_stage: newForm.taskStage,
        task_priority: newForm.taskPriority,
        milestone_task: newForm.isMileStone ? '1' : '0',
        time_remaining: '',
        total_time_spent: '0',
        starting_time: '',
        user_uid: newForm.taskOwner.id,
        client_check: newForm.shareWithClient ? '1' : '0',
        csat_indicator: newForm.csatIndicator ? '1' : '0',
        jira_ind: 0,
        jira_project_id: task.jira_project_id,
        jira_issue_type: task.jira_issue_type,
        blocked_by_dependency: '0',
        last_user_modified: getLoggedInUserUid(),
        contact_uid: newForm.isTeamTask == '0' ? newForm.taskOwner.id : null,
        is_team_task: newForm.isTeamTask,
        task_implementation_fee_percentage: newForm.implementationFee,
        effort_estimate: task.effort_estimate,
        client_initiate: task.client_initiate,
        old_status: task.old_status,
        old_stage: task.old_stage,
        task_sequence: task.task_sequence,
        template_task_uid: task.template_task_uid,
        jira_priority: selectedJiraPriority,
        jira_issue_id: task.jira_issue_id,
        jira_issue_key: task.jira_issue_key,
        jira_status_name: task.jira_status_name,
        section_uid: task.section_uid,
        task_created_on: format(new Date(), 'yyyy-MM-dd'),
        task_modified_on: format(new Date(), 'yyyy-MM-dd'),
        usecaseOrProjectName: await getUsecaseOrProject(true, task.project_uid, 'name'),
      };
    } else {
      // for usecase
      detail = {
        dependency_details: [],
        base_com_url: base_com_url,
        customer_company: customerDetail.customer_company,
        client_uid: client.client_uid,
        customer_uid: customerDetail.customer_uid,
        case_uid: null,
        project_uid: task.project_uid,
        phase_uid: task.section_uid,
        task_uid: task.task_uid,
        task_name: task.task_name,
        hours_per_day: task.hours_per_day,
        task_owner:
          newForm.taskOwner.id == '15243' || newForm.taskOwner.name == 'Not Assigned' ? '15243' : newForm.taskOwner.id,
        task_reporter: newForm.taskReporter,
        task_reporter_uid: getUserUid(newForm.taskReporter),
        task_start_date: newForm.actualStartDate,
        task_due_date: newForm.taskDueDate,
        task_planned_start_date: newForm.plannedStartDate,
        task_closed_date: newForm.taskDeliveryDate,
        task_description: newForm.taskDescription,
        task_percentage: task.task_percentage,
        task_stage: newForm.taskStage,
        task_priority: newForm.taskPriority,
        milestone_task: newForm.isMileStone ? '1' : '0',
        time_remaining: task.time_remaining,
        total_time_spent: '0',
        starting_time: '',
        user_uid: newForm.taskOwner.id,
        client_check: newForm.shareWithClient ? '1' : '0',
        csat_indicator: newForm.csatIndicator ? '1' : '0',
        jira_ind: task.jira_ind,
        jira_project_id: task.jira_project_id,
        jira_issue_type: task.jira_issue_type,
        jira_priority: selectedJiraPriority,
        blocked_by_dependency: task.blocked_by_dependency,
        last_user_modified: getLoggedInUserUid(),
        contact_uid: newForm.isTeamTask == '0' ? newForm.taskOwner.id : null,
        is_team_task: newForm.isTeamTask,
        task_implementation_fee_percentage: newForm.implementationFee,
        effort_estimate: task.effort_estimate,
        client_initiate: task.client_initiate,
        old_status: task.old_status,
        old_stage: task.old_stage,
        task_sequence: task.task_sequence,
        template_task_uid: task.template_task_uid,
        jira_issue_id: task.jira_issue_id,
        jira_issue_key: task.jira_issue_key,
        jira_status_name: task.jira_status_name,
        section_uid: task.section_uid,
        task_created_on: format(new Date(), 'yyyy-MM-dd'),
        task_modified_on: format(new Date(), 'yyyy-MM-dd'),
        usecaseOrProjectName: await getUsecaseOrProject(false, task.case_uid, 'name'),
      };
    }

    // jira payload if exists
    let jiraUpdatePayload: Record<string, any> = {};

    if (isJiraAlreadyEnabled) {
      jiraUpdatePayload = {
        ...jiraUpdatePayload,
        jira_ind: task.jira_ind,
        jira_project_id: task.jira_project_id,
        jira_issue_type: issueId,
        jira_priority: selectedJiraPriority,
        jira_issue_id: task.jira_issue_id,
        jira_issue_key: task.jira_issue_key,
        jira_status_name: task.jira_status_name,
      };
    } else if (isJiraEnabled) {
      jiraUpdatePayload = {
        ...jiraUpdatePayload,
        jira_ind: 1,
        jira_project_id: projectId,
        jira_issue_type: issueId,
        jira_priority: selectedJiraPriority,
        jira_issue_id: '',
        jira_issue_key: selectedJiraProject,
        jira_status_name: '',
      };
    }

    setJiraTaskPayload({ ...jiraUpdatePayload });

    setTaskDetailsToBeAdded(detail);

    // jira flow integration
    if (isJiraAlreadyEnabled) {
      if (!isJSDenabled) {
        updateTaskJiraFlow(detail, jiraTaskPayload);
      } else {
        updateTaskToNormalFlow(detail);
      }
    } else {
      if (isJiraEnabled) {
        if (isJSDenabled) {
          pushToJiraServiceDeskFlow(detail, jiraUpdatePayload);
        } else {
          pushTaskToJiraFlow(detail, jiraUpdatePayload); // DONE
        }
      } else {
        // no need to involve jira flow
        updateTaskToNormalFlow(detail);
      }
    }
  };

  const updateTaskToNormalFlow = async (taskPayload: Record<string, any>) => {
    const responseUpdatetask = await taskAPI.updateTask({ ...taskPayload, customer_uid: getCustomerUid() });
    // setTaskDueDate(taskPayload.task_due_date);
    console.log('--------8500', taskPayload.task_due_date);
    if (responseUpdatetask?.data?.status === 0) {
      setAlertDetails({ isVisible: true, type: 'failure', message: responseUpdatetask?.data?.message });
      setIsSaveInProgress(false);
      return;
    }
    await dispatch(
      getProjectInformation({
        project_uid: task.project_uid,
        association_uid: params.associationId,
      })
    );
    setIsSaveInProgress(false);
    setAlertDetails({ isVisible: true, type: 'success', message: `${label_task} Details Updated Successfully!` });
    afterSave(taskPayload);
    // closeDrawer();
  };

  const pushTaskToJiraFlow = async (taskDetails: any, jiraTaskPayload: Record<string, any>) => {
    if (!isJSDenabled) {
      const project = jiraProjectList.filter((proj: any) => proj.project_key == selectedJiraProject)[0];
      const detail = {
        customer_uid: getCustomerUid(),
        key: btoa(btoa(`${customerDetail.customer_jira_user}:${customerDetail.customer_jira_token}`)),
        project_key: project.project_key,
      };
      const jiraCustomFields = await getJiraCustomFields(detail);
      const issue = issueTypes.filter((issue: any) => issue.name == selectedJiraIssueType)[0];
      const mandatoryFields = getMandatoryCustomFieldsForIssue(jiraCustomFields, issue);
      if (mandatoryFields.length > 0) {
        setJiraCustomfields([...mandatoryFields]);
        setJiraCustomFieldsModal(true);
      } else {
        const project = jiraProjectList.filter((proj: any) => proj.project_key == selectedJiraProject)[0];
        const issue = issueTypes.filter((issue: any) => issue.name == selectedJiraIssueType);

        const serverResponse = await addTaskToJira(
          customerDetail,
          project.jira_project_id,
          task.task_name,
          task.task_uid,
          customerDetail.customer_jira_user,
          taskDetails,
          project.project_key,
          issue,
          projectInfo,
          [],
          { ...jiraTaskPayload }
        );
        setAlertDetails({
          isVisible: true,
          type: serverResponse.status == 1 ? 'success' : serverResponse.status == 0 ? 'failure' : 'info',
          message: serverResponse.message,
        });
        afterSave(taskDetails);
      }
    }
  };

  const pushToJiraServiceDeskFlow = async (taskDetails: any, jiraTaskPayload: Record<string, any>) => {
    const project = jiraProjectList.filter((proj: any) => proj.project_key == selectedJiraProject)[0];
    const detail = {
      jira_service_desk_id: project.jira_service_desk_id,
      requestTypeId: getJsdIssueId(),
      customer_uid: getCustomerUid(),
      key: btoa(btoa(`${customerDetail.customer_jira_user}:${customerDetail.customer_jira_token}`)),
      project_key: project.project_key,
    };

    const jiraCustomFieldsJsd = await jiraAPI.getJiraJsdCustomFieldsById(detail);
    const jsdCustomFields = JSON.parse(jiraCustomFieldsJsd.data.result);
    if (!jsdCustomFields) {
      setAlertDetails({
        isVisible: true,
        type: 'failure',
        message: 'Problem With jira Service Desk, Please reload and Try Again',
      });
      return;
    }
    const mandatoryFields = getMandatoryCustomFieldsForJsdServiceRequests(jsdCustomFields.requestTypeFields);

    if (mandatoryFields.length > 0) {
      setJiraCustomfields([...mandatoryFields]);
      setJsdCustomFieldsModal(true);
    } else {
      const serverResponse = await addTaskToJiraServiceDesk(
        customerDetail,
        project.jira_service_desk_id,
        taskDetailsToBeAdded.task_name,
        taskDetailsToBeAdded.task_uid,
        customerDetail.customer_jira_user,
        taskDetails,
        project.project_key,
        getJsdIssueId(),
        projectInfo,
        [],
        jiraTaskPayload
      );
      setAlertDetails({
        isVisible: true,
        type: serverResponse.status == 1 ? 'success' : 'failure',
        message: serverResponse.message,
      });
      afterSave(taskDetails);
    }
  };

  const handleCustomMandatoryFieldsOfJsd = async (newMandatoryFieldsWithValue: any[]) => {
    const project = jiraProjectList.filter((proj: any) => proj.project_key == selectedJiraProject)[0];
    const issueId = getJsdIssueId();

    const serverResponse = await addTaskToJiraServiceDesk(
      customerDetail,
      project.jira_service_desk_id,
      taskDetailsToBeAdded.task_name,
      taskDetailsToBeAdded.task_uid,
      customerDetail.customer_jira_user,
      taskDetailsToBeAdded,
      project.project_key,
      issueId,
      projectInfo,
      newMandatoryFieldsWithValue,
      jiraTaskPayload
    );

    setAlertDetails({
      isVisible: true,
      type: serverResponse.status == 1 ? 'success' : 'failure',
      message: serverResponse.message,
    });

    afterSave(taskDetailsToBeAdded);
  };

  const updateTaskJiraFlow = async (taskDetails: any, jiraTaskPayload: Record<string, any>) => {
    const project = jiraProjectList.filter((proj: any) => proj.project_key == selectedJiraProject)[0];

    const detail = {
      customer_uid: getCustomerUid(),
      key: btoa(btoa(`${customerDetail.customer_jira_user}:${customerDetail.customer_jira_token}`)),
      project_key: project.project_key,
    };

    const jiraCustomFields = await getJiraCustomFields(detail);

    const issue = issueTypes.filter((issue: any) => issue.name == selectedJiraIssueType)[0];
    const mandatoryFields = getMandatoryCustomFieldsForIssue(jiraCustomFields, issue);

    if (mandatoryFields.length > 0) {
      setJiraCustomfields([...mandatoryFields]);
      setJiraCustomFieldsModalForUpdate(true);
    } else {
      const project = jiraProjectList.filter((proj: any) => proj.project_key == selectedJiraProject)[0];
      const issue = issueTypes.filter((issue: any) => issue.name == selectedJiraIssueType);
      const base_com_url = window.location.host + '/customer-portal/' + customerDetail.customer_url_name + '/';

      let newTaskDetails = {
        blocked_by_dependency: '',
        base_com_url: base_com_url,
        customer_company: taskDetails.customer_company,
        client_uid: taskDetails.client_uid,
        case_uid: taskDetails.case_uid,
        client_check: taskDetails.client_check,
        client_initiate: '0',
        contact_uid: null,
        csat_indicator: taskDetails.csat_indicator,
        customer_uid: getCustomerUid(),
        dependency_details: [],
        effort_estimate: 0,
        jira_ind: taskDetails.jira_ind,
        jira_issue_type: issue[0]?.id,
        jira_priority: taskDetails.jira_priority,
        jira_project_id: taskDetails.jira_project_id,
        jira_issue_id: taskDetails.jira_issue_id,
        jira_issue_key: issue,
        jira_status_name: taskDetails.jira_status_name,
        milestone_task: taskDetails.milestone_task,
        project_uid: taskDetails.project_uid,
        section_uid: taskDetails.phase_uid,
        task_implementation_fee_percentage: taskDetails.task_implementation_fee_percentage,
        starting_time: taskDetails.starting_time,
        task_closed_date: taskDetails.task_closed_date,
        task_description: taskDetails.task_description,
        task_name: taskDetails.task_name,
        task_due_date: taskDetails.task_due_date,
        task_start_date: taskDetails.task_start_date,
        task_owner: taskDetails.task_owner?.id,
        task_percentage: taskDetails.task_percentage,
        task_planned_start_date: taskDetails.task_planned_start_date,
        task_created_on: format(new Date(), 'yyyy-MM-dd'),
        task_modified_on: format(new Date(), 'yyyy-MM-dd'),
        task_priority: taskDetails.task_priority,
        task_reporter: taskDetails.task_reporter,
        task_reporter_uid: taskDetails.task_reporter_uid,
        task_sequence: 3,
        task_stage: taskDetails.task_stage,
        template_task_uid: taskDetails.template_task_uid,
        time_remaining: taskDetails.time_remaining,
        total_time_spent: taskDetails.total_time_spent,
        is_team_task: taskDetails.is_team_task,
        last_user_modified: taskDetails.last_user_modified,
        phase_uid: task.section_uid,
      };

      newTaskDetails = { ...newTaskDetails, ...jiraTaskPayload };

      const response = await updateTaskToJira(
        customerDetail,
        project.jira_project_id,
        task.task_name,
        task.task_uid,
        customerDetail.customer_jira_user,
        newTaskDetails,
        project.project_key,
        issue,
        projectInfo,
        currentUserDetails.data.user_email,
        []
      );
      setAlertDetails({
        isVisible: true,
        type: response.status == 1 ? 'success' : 'failure',
        message: response.message,
      });
      afterSave(newTaskDetails);
    }
  };

  const handleCustomMandatoryFieldsSave = async (newMandatoryFieldsWithValue: any[]) => {
    const project = jiraProjectList.filter((proj: any) => proj.project_key == selectedJiraProject)[0];
    const issue = issueTypes.filter((issue: any) => issue.name == selectedJiraIssueType);
    const serverResponse = await addTaskToJira(
      customerDetail,
      project.jira_project_id,
      task.task_name,
      task.task_uid,
      customerDetail.customer_jira_user,
      taskDetailsToBeAdded,
      project.project_key,
      issue,
      projectInfo,
      newMandatoryFieldsWithValue,
      jiraTaskPayload
    );
    setAlertDetails({
      isVisible: true,
      type: serverResponse.status == 1 ? 'success' : 'failure',
      message: serverResponse.message,
    });
    setJiraCustomFieldsModal(false);
    afterSave(taskDetailsToBeAdded);
  };

  const handleCustomMandatoryFieldsForUpdate = async (newMandatoryFieldsWithValue: any[]) => {
    setJsdCustomFieldsModal(false);

    const project = jiraProjectList.filter((proj: any) => proj.project_key == selectedJiraProject)[0];
    const issue = issueTypes.filter((issue: any) => issue.name == selectedJiraIssueType);
    const base_com_url: string = window.location.host + '/customer-portal/' + customerDetail.customer_url_name + '/';
    let taskDetails = {
      blocked_by_dependency: '0',
      base_com_url: base_com_url,
      customer_company: customerDetail.customer_company,
      client_uid: client.client_uid,
      case_uid: null,
      client_check: taskDetailsToBeAdded.client_check,
      client_initiate: taskDetailsToBeAdded.client_initiate,
      contact_uid: !taskDetailsToBeAdded.is_team_task ? '15243' : '',
      csat_indicator: taskDetailsToBeAdded.csat_indicator,
      customer_uid: getCustomerUid(),
      dependency_details: [],
      effort_estimate: taskDetailsToBeAdded.effort_estimate,
      jira_ind: taskDetailsToBeAdded.jira_ind,
      jira_project_id: taskDetailsToBeAdded.jira_project_id,
      jira_issue_type: issue[0]?.id,
      jira_priority: selectedJiraPriority,
      jira_issue_id: taskDetailsToBeAdded.jira_issue_id,
      jira_issue_key: taskDetailsToBeAdded.jira_issue_key,
      jira_status_name: taskDetailsToBeAdded.jira_status_name,
      milestone_task: taskDetailsToBeAdded.milestone_task,
      project_uid: task.projectUid,
      section_uid: props.phaseUid,
      task_implementation_fee_percentage: taskDetailsToBeAdded.task_implementation_fee_percentage,
      starting_time: '',
      task_closed_date: taskDetailsToBeAdded.task_closed_date,
      task_description: taskDetailsToBeAdded.task_description,
      task_name: taskDetailsToBeAdded.task_name,
      task_due_date: taskDetailsToBeAdded.task_due_date,
      task_start_date: taskDetailsToBeAdded.task_start_date,
      task_owner: taskDetailsToBeAdded.task_owner.id,
      task_percentage: taskDetailsToBeAdded.task_percentage,
      task_planned_start_date: taskDetailsToBeAdded.task_planned_start_date,
      task_created_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      task_modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      task_priority: taskDetailsToBeAdded.jira_priority,
      task_reporter: taskDetailsToBeAdded.task_reporter,
      task_reporter_uid: taskDetailsToBeAdded.task_reporter_uid,
      task_sequence: 3,
      task_stage: taskDetailsToBeAdded.task_stage,
      template_task_uid: '',
      time_remaining: '',
      total_time_spent: '',
      is_team_task: taskDetailsToBeAdded.is_team_task,
      last_user_modified: getLoggedInUserUid(),
      phase_uid: task.section_uid,
    };

    taskDetails = { ...taskDetails, ...jiraTaskPayload };
    const response = await updateTaskToJira(
      customerDetail,
      project.jira_project_id,
      task.task_name,
      task.task_uid,
      customerDetail.customer_jira_user,
      taskDetails,
      project.project_key,
      issue,
      projectInfo,
      currentUserDetails.data.user_email,
      newMandatoryFieldsWithValue
    );
    setAlertDetails({ isVisible: true, type: response.status == 1 ? 'success' : 'failure', message: response.message });
    afterSave(taskDetails);
  };

  const doContinue = (proceed: boolean) => {
    console.log('IS HE CONTINUINF', proceed);
    setIsContinuing(proceed);

    setDateShiftConfirmation(false);
    if (proceed) {
      console.log(updatedFormData);
      updateTaskDelayDays(updatedFormData);
      updateTaskDetail(updatedFormData);
    } else {
      console.log('DONT PROCEEDD');
    }
  };

  const updateTaskDelayDays = async (detail: any) => {
    if (task.case_uid == '' || !task.case_uid) {
      // only delay if shift moves project ETA > project Due
      const pay = {
        project_uid: task.project_uid,
        type: PROJECT,
      };
      let paths = await dispatch(getTasksPathOfProject(pay)).unwrap();
      paths = paths.filter((path: any) => path.task_uid == task.task_uid);

      const newETA = addDays(
        new Date(task.eta_forprojectdelivery.split(' ')[0]),
        differenceInDays(new Date(detail.taskDeliveryDate.split(' ')[0]), new Date(task.task_closed_date.split(' ')[0]))
      );
      if (newETA >= new Date(task.project_requiredbydate.split(' ')[0])) {
        let detail1 = {
          task_uid: task.task_uid,
          delayed_by: differenceInDays(
            new Date(detail.taskDeliveryDate.split(' ')[0]),
            new Date(task.task_closed_date.split(' ')[0])
          ),
        };
        detail1 = {
          ...detail1,
          delayed_by: detail1.delayed_by - paths[0].float,
        };
        console.log(detail1);
        await dispatch(updateTaskDelay(detail1));
      }
    } else {
      // only delay if shift moves project ETA > project Due
      const pay = {
        project_uid: task.case_uid,
        type: USECASE,
      };
      let paths = await dispatch(getTasksPathOfProject(pay)).unwrap();
      paths = paths.filter((path: any) => path.task_uid != task.task_uid);

      const newETA = addDays(
        new Date(task.eta_forcasedelivery.split(' ')[0]),
        differenceInDays(new Date(detail.taskDeliveryDate.split(' ')[0]), new Date(task.task_closed_date.split(' ')[0]))
      );
      if (newETA >= new Date(task.case_requiredbydate.split(' ')[0])) {
        let detail1 = {
          task_uid: task.task_uid,
          delayed_by: differenceInDays(
            new Date(detail.taskDeliveryDate.split(' ')[0]),
            new Date(task.task_closed_date.split(' ')[0])
          ),
        };
        detail1 = {
          ...detail1,
          delayed_by: detail1.delayed_by - paths[0].float,
        };
        console.log(detail1);
        await dispatch(updateTaskDelay(detail1));
      }
    }
  };

  const afterSave = async (taskPayload?: any) => {
    // Due and delivery update based on precedents
    if (task.case_uid) {
      let usecase = await dispatch(getUsecaseByUid(task.case_uid)).unwrap();
      usecase = usecase[0];
      setUcDetail(usecase);
      console.log(usecase);
      const dueDetail = {
        customer_uid: customerDetail.customer_uid,
        task_uid: task.task_uid,
        old_due_date: task.task_due_date,
        new_due_date: taskPayload.task_due_date,
        shifted_by: differenceInDays(
          new Date(taskPayload.task_due_date.split(' ')[0]),
          new Date(task.task_due_date.split(' ')[0])
        ),
        client_task: task.isTeamTask == 1 ? 0 : 1,
      };

      const deliveryDetail = {
        customer_uid: customerDetail.customer_uid,
        task_uid: task.task_uid,
        old_closed_date: task.task_closed_date,
        new_closed_date: taskDetailsToBeAdded.taskDeliveryDate,
        shifted_by: differenceInDays(
          new Date(taskPayload.task_closed_date.split(' ')[0]),
          new Date(task.task_closed_date.split(' ')[0])
        ),
        client_task: taskDetailsToBeAdded.isTeamTask == 1 ? 0 : 1,
      };

      if (deliveryDetail.shifted_by > 0) {
        // const taskDetailWithCriticalPath = await dispatch(getTasksPathOfProject(task.project_uid)).unwrap();
        // const paths: any[] = await dispatch(getTasksPathOfProject(task.project_uid)).unwrap();
        const pay = {
          project_uid: task.case_uid,
          task_uid: task.task_uid,
          type: USECASE,
        };
        const paths = await dispatch(getPathOfThisTask(pay)).unwrap();

        const shouldUpdate = shouldUpdateDateOfDependents(paths, task, taskPayload, deliveryDetail.shifted_by);
        console.log(shouldUpdate);
        if (shouldUpdate) {
          console.log('SHOULD UPDATE', shouldUpdate);
          if (shouldUpdate > 0) {
            await dispatch(updateUseCaseDependencyDueDate(dueDetail));
            await dispatch(updateUseCaseDependencyDeliveryDate(deliveryDetail));
          }
        }
      } else if (deliveryDetail.shifted_by < 0) {
        const pay = {
          project_uid: task.case_uid,
          type: USECASE,
        };
        const taskDetailWithCriticalPath = await dispatch(getTasksPathOfProject(pay)).unwrap();
        const d = {
          task_uid: task.task_uid,
          task_type: USECASE,
        };
        const taskChildren = await dispatch(getTaskChildren(d)).unwrap();
        console.log(taskChildren);
        // Find closest child
        const res = nearestDate(taskChildren, taskDetailWithCriticalPath);
        console.log(res);
        const getParents = {
          task_uid: res.task_uid,
          task_type: USECASE,
        };
        const taskParents = await dispatch(getTaskParents(getParents)).unwrap();
        console.log(taskParents);

        const shouldPushDatesBackwards = shouldUpdateDateOfDependentsWhenMovingBackward(
          taskPayload,
          taskParents,
          taskDetailWithCriticalPath
        );
        console.log(shouldPushDatesBackwards);
        if (shouldPushDatesBackwards.shift) {
          await dispatch(updateUseCaseDependencyDueDate(dueDetail));
          await dispatch(updateUseCaseDependencyDeliveryDate(deliveryDetail));
        }

        // const pay = {
        //   project_uid: task.project_uid,
        //   task_uid: task.task_uid,
        // };
        // const paths = await dispatch(getPathOfThisTask(pay)).unwrap();
      }

      // let newDate;
      // if (shiftedDays != 0) {
      // if (shiftedDays < 0) {
      //   console.log('SGIFTTT', shiftedDays, usecase.eta_forcasedelivery);

      //   newDate = format(
      //     subDays(new Date(usecase.eta_forcasetdelivery.split(' ')[0]), shiftedDays * -1),
      //     'yyyy-MM-dd'
      //   );
      // } else {
      // newDate = format(addDays(new Date(usecase.eta_forcasedelivery.split(' ')[0]), shiftedDays), 'yyyy-MM-dd');
      // }
      // const newData = {
      //   project_uid: task.case_uid,
      //   newDate: newDate,
      //   type: USECASE,
      // };
      // await dispatch(updateEndDatesOfProject(newData));
      // }

      // await dispatch(updateUseCaseDependencyDueDate(dueDetail));
      // await dispatch(updateUseCaseDependencyDeliveryDate(deliveryDetail));
    } else {
      console.log('1198', taskPayload);
      const dueDetail = {
        customer_uid: customerDetail.customer_uid,
        task_uid: task.task_uid,
        old_due_date: task.task_due_date,
        new_due_date: taskPayload.task_due_date,
        shifted_by: differenceInDays(
          new Date(taskPayload.task_due_date.split(' ')[0]),
          new Date(task.task_due_date.split(' ')[0])
        ),

        client_task: taskDetailsToBeAdded.isTeamTask == 1 ? 0 : 1,
      };

      const deliveryDetail = {
        customer_uid: customerDetail.customer_uid,
        task_uid: task.task_uid,
        old_closed_date: task.task_closed_date,
        new_closed_date: taskDetailsToBeAdded.taskDeliveryDate,
        shifted_by: differenceInDays(
          new Date(taskPayload.task_closed_date.split(' ')[0]),
          new Date(task.task_closed_date.split(' ')[0])
        ),
        client_task: taskDetailsToBeAdded.isTeamTask == 1 ? 0 : 1,
      };
      if (deliveryDetail.shifted_by > 0) {
        // const taskDetailWithCriticalPath = await dispatch(getTasksPathOfProject(task.project_uid)).unwrap();
        // const paths: any[] = await dispatch(getTasksPathOfProject(task.project_uid)).unwrap();
        const pay = {
          project_uid: task.project_uid,
          task_uid: task.task_uid,
          type: PROJECT,
        };
        const paths = await dispatch(getPathOfThisTask(pay)).unwrap();

        const shouldUpdate = shouldUpdateDateOfDependents(paths, task, taskPayload, deliveryDetail.shifted_by);
        console.log(shouldUpdate);
        if (shouldUpdate) {
          console.log('SHOULD UPDATE', shouldUpdate);
          if (shouldUpdate - deliveryDetail.shifted_by > 0) {
            if (dueDetail.shifted_by != 0) {
              await dispatch(updateProjectDependencyDueDate(dueDetail));
            }
            if (deliveryDetail.shifted_by != 0) {
              await dispatch(
                updateProjectDependencyDeliveryDate({
                  ...deliveryDetail,
                  shifted_by: shouldUpdate - deliveryDetail.shifted_by,
                })
              );
              // const updateDetail = {
              //   task_uid: task.task_uid,
              //   delayed_by: deliveryDetail.shifted_by,
              // };
              // await dispatch(updateTaskDelay(updateDetail));
            }
          }
        }
      } else if (deliveryDetail.shifted_by < 0) {
        const pay = {
          project_uid: task.project_uid,
          type: PROJECT,
          task_uid: task.task_uid,
        };
        const taskDetailWithCriticalPath = await dispatch(getTasksPathOfProject(pay)).unwrap();
        const d = {
          task_uid: task.task_uid,
          task_type: PROJECT,
        };
        const taskChildren = await dispatch(getTaskChildren(d)).unwrap();
        if (taskChildren.length > 0) {
          console.log(taskChildren);
          // Find closest child
          const res = nearestDate(taskChildren, taskDetailWithCriticalPath);
          console.log(res);
          const getParents = {
            task_uid: res.task_uid,
            task_type: PROJECT,
          };
          const taskParents = await dispatch(getTaskParents(getParents)).unwrap();
          console.log(taskParents);

          const shouldPushDatesBackwards = shouldUpdateDateOfDependentsWhenMovingBackward(
            taskPayload,
            taskParents,
            taskDetailWithCriticalPath
          );
          console.log(shouldPushDatesBackwards);
          if (shouldPushDatesBackwards.shift) {
            if (dueDetail.shifted_by != 0) {
              await dispatch(updateProjectDependencyDueDate(dueDetail));
              // const updateDetail = {
              //   task_uid: task.task_uid,
              //   delayed_by: deliveryDetail.shifted_by,
              // };
              // await dispatch(updateTaskDelay(updateDetail));
            }
            if (deliveryDetail.shifted_by != 0) {
              // const updateDetail = {
              //   task_uid: task.task_uid,
              //   delayed_by: deliveryDetail.shifted_by,
              // };
              // await dispatch(updateTaskDelay(updateDetail));
              await dispatch(updateProjectDependencyDeliveryDate(deliveryDetail));
            }
          }
        } else {
          await dispatch(updateProjectDependencyDueDate(dueDetail));
          await dispatch(updateProjectDependencyDeliveryDate(deliveryDetail));
          // const updateDetail = {
          //   task_uid: task.task_uid,
          //   delayed_by: deliveryDetail.shifted_by,
          // };
          // await dispatch(updateTaskDelay(updateDetail));
        }

        // const pay = {
        //   project_uid: task.project_uid,
        //   task_uid: task.task_uid,
        // };
        // const paths = await dispatch(getPathOfThisTask(pay)).unwrap();
      }

      // let newDate;
      // if (shiftedDays != 0) {
      //   if (shiftedDays < 0) {
      //     console.log('SGIFTTT', shiftedDays, task.eta_forprojectdelivery);

      //     newDate = format(
      //       subDays(new Date(task.eta_forprojectdelivery.split(' ')[0]), shiftedDays * -1),
      //       'yyyy-MM-dd'
      //     );
      //   } else {
      //     // newDate = format(addDays(new Date(task.eta_forprojectdelivery.split(' ')[0]), shiftedDays), 'yyyy-MM-dd');
      //   }
      //   // const newData = {
      //   //   project_uid: task.project_uid,
      //   //   newDate: newDate,
      //   //   type: PROJECT,
      //   // };
      //   // await dispatch(updateEndDatesOfProject(newData));
      // }
    }

    if (isJiraEnabled || isJiraAlreadyEnabled) {
      syncTasks();
    } else {
      await getUsecaseOrProject(true, task.project_uid, 'associationUid');
      const test = {
        project_uid: task.project_uid,
      };

      await dispatch(fetchClientInformation(client_uid));

      await dispatch(getProjectInformationWithCaseTaskByUid(test));

      setIsSaveInProgress(false);
    }

    props.onClose();
    await dispatch(updateCaseWithfeatureStatus(client_uid));

    const detail = {
      association_uid: association_uid,
      project_uid: task.project_uid,
    };
    await dispatch(getProjectInformation(detail));

    const detail2 = {
      project_uid: task.project_uid,
    };
    await dispatch(getProjectInformationWithCaseTaskByUid(detail2));
  };

  const setDataForTaskOwnerDropDown = (teamTask: any, owner: string, setFieldValue: any) => {
    if (teamTask) {
      const uid = getUserUid(owner);
      setFieldValue('taskOwner', { name: owner, id: uid });
    } else {
      const uid = getContactUidByContactName(owner);
      setFieldValue('taskOwner', { name: owner, id: uid });
    }
  };

  const onClickingContinue = (owner: any, setFieldValue: any) => {
    setFieldValue('isTeamTask', 0);
    setDataForTaskOwnerDropDown(0, owner, setFieldValue);
  };

  const onClickingContinueForTeamModal = (owner: any, setFieldValue: any) => {
    setFieldValue('isTeamTask', 1);
    setDataForTaskOwnerDropDown(1, owner, setFieldValue);
  };

  const assignTaskModal = (isTeamTask: any) => {
    if (isTeamTask == 1) {
      setAssignTaskOwnerToClientModal(!assignTaskOwnerToClientModal);
    } else {
      setAssignTaskOwnerToTeamModal(!assignTaskOwnerToTeamModal);
    }
  };

  const getJiraProjectKeyByProjectName = (project_name: string) => {
    return jiraProjectList.filter((project: any) => project.jira_project_name == project_name)[0].project_key;
  };

  const syncTasks = async () => {
    if (
      !(customerDetail.customer_JSD == 1) &&
      customerDetail.customer_jira_user &&
      customerDetail.customer_jira_user.length > 0
    ) {
      const key = btoa(btoa(customerDetail.customer_jira_user + ':' + customerDetail.customer_jira_token));
      const customfield = await getJiraCustomFieldNames(
        customerDetail.customer_jira_token,
        customerDetail.customer_jira_user,
        customerDetail.customer_uid,
        selectedJiraProject,
        issueTypes.filter((item) => item.id === '10002')[0]
      );

      dispatch(
        syncAllJiraTasks({
          client_uid: props.client.client_uid,
          customDeliveryDate: customfield.customDeliveryDate,
          customer_uid: customerDetail.customer_uid,
          key: key,
        })
      );
    }
  };

  const isMandatoryFieldsEmpty = () => {
    for (const value of taskCustomfieldsWithValues) {
      if (value.mandatory == '1' && value.field_value.length === 0) return true;
    }
    return false;
  };

  const updateStoreForTaskModal = (values: any) => {
    if (JSON.stringify(values) !== JSON.stringify(initialValues)) {
      dispatch(updateTaskDetailModal(values));
    }
  };
  if (!taskModalDetailsFormRef?.current) {
    dispatch(updateTaskDetailFormRef(props.taskDetailFormRef));
  }

  return (
    <>
      <Formik
        validationSchema={taskValidationSchema}
        initialValues={initialValues}
        onSubmit={(values) => {
          if (isMandatoryFieldsEmpty() && (values.isTeamTask === 1 || values.isTeamTask === '1')) {
            setAlertDetails({
              isVisible: true,
              type: 'failure',
              message: `Please fill out mandatory Custom fields before editing this ${label_task}`,
            });
            return;
          }
          const newValues = {
            ...values,
            taskReporter: values.taskReporter ? values.taskReporter.name : 'Not Assigned',
            taskReporterUid: values.taskReporter ? values.taskReporter.id : '15243',
            taskOwner: values.taskOwner ? values.taskOwner : { id: '15243', name: 'Not Assigned' },
            taskDeliveryDate: format(new Date(values.taskDeliveryDate), 'yyyy-MM-dd HH:mm:ss'),
            actualStartDate: format(new Date(values.actualStartDate), 'yyyy-MM-dd HH:mm:ss'),
          };

          if (
            customerDetail?.attachment_mandatory_when_marking_milestone_task_as_done == '1' &&
            values.isMileStone &&
            attachmentDataQueueState?.data?.length === 0 &&
            values.taskStage === 'Done'
          ) {
            setAlertDetails({
              isVisible: true,
              type: 'failure',
              message: `At Least One ${label_task} attachment is Mandatory for a milestone ${label_task} to be Done`,
            });
          } else {
            datesPushActivity(newValues);
            addAuditTrailForTaskUpdate(initialStateForm, newValues);
            // updateTaskDetail(newValues);
          }
        }}
        innerRef={props.taskDetailFormRef}
      >
        {(props: any) => (
          <form>
            {updateStoreForTaskModal(props.values)}
            <Stack m={2}>
              <Stack justifyContent="space-between" alignItems={'center'} direction="row">
                <Stack m={2} direction="row">
                  <Typography mb={2} my={1} variant="h2">
                    {props.values.isTeamTask == 1 ? 'Team ' + label_task : 'Client ' + label_task}
                  </Typography>
                  {!isNull(task.project_uid) &&
                  !isNull(task.case_uid) &&
                  userPermissions?.includes(userPermissionSchema.EDIT) ? (
                    usecaseSubtasks.length == 0 ? (
                      props.values.pushToJira == false ? (
                        <Typography
                          m={1}
                          ml={1}
                          style={{ color: '#2D60F6' }}
                          className="cognisaas-pointer"
                          onClick={() => assignTaskModal(props.values.isTeamTask)}
                          variant="subtitle1"
                        >
                          Change to {props.values.isTeamTask == 1 ? 'Client' : 'Team'} {label_task}
                        </Typography>
                      ) : null
                    ) : null
                  ) : !isNull(task.project_uid) &&
                    isNull(task.case_uid) &&
                    userPermissions?.includes(userPermissionSchema.EDIT) ? (
                    projectSubtasks.length == 0 ? (
                      props.values.pushToJira == false ? (
                        <Typography
                          m={1}
                          ml={1}
                          style={{ color: '#2D60F6' }}
                          className="cognisaas-pointer"
                          onClick={() => assignTaskModal(props.values.isTeamTask)}
                          variant="subtitle1"
                        >
                          Change to {props.values.isTeamTask == 1 ? 'Client' : 'Team'} {label_task}
                        </Typography>
                      ) : null
                    ) : null
                  ) : null}
                </Stack>

                {props.values.isTeamTask == 1 && (
                  <Stack direction={'row'}>
                    {isNull(task.jira_issue_key) ? (
                      false
                    ) : task.jira_issue_key.length > 0 ? (
                      <Typography
                        className={'cognisaas-pointer link-text'}
                        variant={'subtitle2'}
                        onClick={() => {
                          window.open(`${customerDetail.customer_jira_api_url}browse/${task.jira_issue_key}`, '_blank');
                        }}
                        sx={{ marginRight: '24px' }}
                      >
                        Link To Jira
                      </Typography>
                    ) : (
                      <></>
                    )}
                  </Stack>
                )}
              </Stack>

              <Stack mb={2} ml={2} spacing={4} direction="row">
                <Stack>
                  {isJiraEnabled ? (
                    <CognisaaasTooltip
                      title={
                        <Typography variant="subtitle1">
                          The {label_task} Stage of Jira task is automatically synced with the stage in jira
                        </Typography>
                      }
                    >
                      <Stack>
                        <Typography variant="subtitle2">{label_task} Stage</Typography>
                        <CognisaasSelect
                          disabled={isJiraEnabled || !userPermissions?.includes(userPermissionSchema.EDIT)}
                          name="taskStage"
                          onChange={(e: any) => {
                            props.setFieldValue(
                              'taskStage',
                              getStandardNameMappedValue(e.target.value, NAMED_CONSTATNTS.task_stage_key)
                            );
                          }}
                          value={getCustomNameMappedValue(props.values.taskStage, NAMED_CONSTATNTS.task_stage_key)}
                          validValues={taskAllStages}
                          width={'220px'}
                        ></CognisaasSelect>
                      </Stack>
                    </CognisaaasTooltip>
                  ) : (
                    <>
                      <Typography variant="subtitle2">{label_task} Stage</Typography>
                      <CognisaasSelect
                        disabled={props.values.pushToJira || !userPermissions?.includes(userPermissionSchema.EDIT)}
                        name="taskStage"
                        onChange={(e: any) => {
                          props.setFieldValue(
                            'taskStage',
                            getStandardNameMappedValue(e.target.value, NAMED_CONSTATNTS.task_stage_key)
                          );
                        }}
                        value={getCustomNameMappedValue(props.values.taskStage, NAMED_CONSTATNTS.task_stage_key)}
                        validValues={taskAllStages}
                        width={'220px'}
                      ></CognisaasSelect>
                    </>
                  )}
                </Stack>
                <Stack>
                  <Typography variant="subtitle2">Priority</Typography>
                  <CognisaasSelect
                    disabled={!userPermissions?.includes(userPermissionSchema.EDIT)}
                    name="taskPriority"
                    onChange={(e: any) => {
                      props.handleChange;
                      props.setFieldValue(
                        'taskPriority',
                        getStandardNameMappedValue(e.target.value, NAMED_CONSTATNTS.task_priority_key)
                      );
                    }}
                    value={getCustomNameMappedValue(props.values.taskPriority, NAMED_CONSTATNTS.task_priority_key)}
                    validValues={taskAllPriorities}
                    width={'220px'}
                  ></CognisaasSelect>
                </Stack>
                <Stack sx={{ width: '220px' }}>
                  <Typography variant="subtitle2">{label_task} Owner</Typography>
                  <CognisaasUserSelect
                    width="220px"
                    name="taskOwner"
                    allOptions={
                      props.values.isTeamTask == 1
                        ? preselectedProejctMembersWithNotAssigned
                        : preselectedClientContactsWithNotAssigned
                    }
                    onChange={(val: any) => {
                      props.setFieldValue('taskOwner', {
                        name: val.target.textContent,
                        id:
                          props.values.isTeamTask == 1
                            ? getUserUid(val.target.textContent)
                            : getContactUidByContactName(val.target.textContent),
                      });
                    }}
                    selectedOption={{
                      id: props.values.taskOwner.id,
                      name:
                        getUserActiveState(
                          props.values.taskOwner.id,
                          props.values.isTeamTask == 1 ? preselectedProjectMembers : preselectedClientContacts
                        ) === '0'
                          ? `${props.values.taskOwner.name} (Inactive)`
                          : props.values.taskOwner.name,
                      is_active: getUserActiveState(
                        props.values.taskOwner.id,
                        props.values.isTeamTask == 1 ? preselectedProjectMembers : preselectedClientContacts
                      ),
                    }}
                  />
                </Stack>
              </Stack>
              <Stack mb={2} ml={2} spacing={4} direction="row">
                <Stack>
                  <Typography variant="subtitle2">{label_task} Reporter</Typography>
                  <CognisaasUserSelect
                    width="220px"
                    name="taskReporter"
                    allOptions={
                      props.values.clientInitated == 0
                        ? preselectedProejctMembersWithNotAssigned
                        : preselectedClientContactsWithNotAssigned
                    }
                    selectedOption={{
                      id:
                        props.values.isTeamTask == 1
                          ? getUserUid(props.values.taskReporter.name)
                          : getContactUidByContactName(props.values.taskReporter.name),
                      name:
                        getUserActiveState(
                          props.values.taskReporter.id,
                          props.values.isTeamTask == 1 ? preselectedProjectMembers : preselectedClientContacts
                        ) === '0'
                          ? `${props.values.taskReporter.name} (Inactive)`
                          : props.values.taskReporter.name,
                      is_active: getUserActiveState(
                        props.values.taskReporter.id,
                        props.values.isTeamTask == 1 ? preselectedProjectMembers : preselectedClientContacts
                      ),
                    }}
                    onChange={(val: any) => {
                      props.setFieldValue('taskReporter', {
                        // name: val.target.textContent,
                        id:
                          props.values.isTeamTask == 1
                            ? getUserUid(val.target.textContent)
                            : getContactUidByContactName(val.target.textContent),
                        name: val.target.textContent,
                        is_active: getUserActiveState(
                          props.values.isTeamTask == 1
                            ? getUserUid(val.target.textContent)
                            : getContactUidByContactName(val.target.textContent),
                          props.values.isTeamTask == 1 ? preselectedProjectMembers : preselectedClientContacts
                        ),
                      });
                    }}
                  />
                </Stack>

                <Stack>
                  <BasicDateRangePicker
                    value={props.values.rangeValue}
                    setValue={(value: any) => {
                      const startDate = new Date(value[0]?.toString());
                      const endDate = new Date(value[1]?.toString());
                      props.setFieldValue('plannedStartDate', format(startOfDay(new Date(startDate)), 'yyyy-MM-dd'));
                      props.setFieldValue('taskDueDate', format(startOfDay(new Date(endDate)), 'yyyy-MM-dd'));
                      props.setFieldValue('rangeValue', [
                        format(startOfDay(new Date(startDate)), 'yyyy-MM-dd'),
                        format(startOfDay(new Date(endDate)), 'yyyy-MM-dd'),
                      ]);
                    }}
                    startLabel="Planned Start Date"
                    endLabel={label_due_date}
                    width={'220px'}
                    marginBetweenDates={2}
                  />
                </Stack>
              </Stack>
              <Stack mb={2} ml={2} spacing={4} direction="row">
                <Stack>
                  <Typography variant="subtitle2">Actual Start Date*</Typography>
                  {props.values.taskStage === 'Not Started' ? (
                    <CognisaaasTooltip
                      title={
                        <Typography variant="subtitle1" style={{ padding: '10px' }}>
                          {label_task} Start date will be system generated when this {label_task.toLowerCase()} moves to
                          In progress
                        </Typography>
                      }
                    >
                      <Stack>
                        <CognisaasCalenderNew disabled={true} width={'220px'} />
                      </Stack>
                    </CognisaaasTooltip>
                  ) : (
                    <CognisaasCalenderNew
                      disabled={!userPermissions?.includes(userPermissionSchema.EDIT)}
                      name="actualStartDate"
                      value={props.values.actualStartDate ? new Date(props.values.actualStartDate) : null}
                      handleChange={(e: any) => props.setFieldValue('actualStartDate', new Date(e))}
                      width={'220px'}
                    />
                  )}
                </Stack>

                <Stack>
                  <Typography variant="subtitle2">{label_delivery_date}</Typography>
                  <CognisaasCalenderNew
                    disabled={!userPermissions?.includes(userPermissionSchema.EDIT)}
                    name="taskDeliveryDate"
                    value={new Date(props.values.taskDeliveryDate)}
                    handleChange={(e: any) => props.setFieldValue('taskDeliveryDate', new Date(e))}
                    width={'220px'}
                  />
                </Stack>
                <Stack>
                  <Typography variant="subtitle2">Implementation Fee(%)</Typography>
                  <TextField
                    type="number"
                    style={{ width: '220px' }}
                    size="small"
                    placeholder="0%"
                    name="implementationFee"
                    value={props.values.implementationFee}
                    onChange={props.handleChange}
                    disabled={!userPermissions?.includes(userPermissionSchema.EDIT)}
                  ></TextField>
                </Stack>
              </Stack>

              {isJiraAlreadyEnabled ? (
                <Stack my={2} ml={2}>
                  <Typography variant="subtitle2">Jira Status</Typography>
                  <Typography variant="subtitle1">{task.jira_status_name}</Typography>
                </Stack>
              ) : (
                <></>
              )}

              {isJiraEnabled || isJiraAlreadyEnabled || taskDetailForModal?.pushToJira ? (
                <Stack mb={2} ml={2} spacing={4} direction="row">
                  <Stack>
                    <Typography variant="subtitle2">Jira Project*</Typography>
                    <CognisaasSelect
                      name=""
                      disabled={isJiraAlreadyEnabled || !userPermissions?.includes(userPermissionSchema.EDIT)}
                      onChange={(e: any) => {
                        updateJiraProjectkey(getJiraProjectKeyByProjectName(e.target.value as string));
                      }}
                      value={selectedJiraProject}
                      validValues={getJiraProjectValues()}
                      width={'220px'}
                    />
                  </Stack>
                  <Stack>
                    <Typography variant="subtitle2">Jira Issue Type*</Typography>
                    <CognisaasSelect
                      disabled={
                        (isJSDenabled && isJiraAlreadyEnabled) ||
                        !userPermissions?.includes(userPermissionSchema.EDIT) ||
                        selectedJiraIssueType === 'Epic'
                      }
                      name=""
                      onChange={(e: any) => {
                        props.setFieldValue('jiraIssueTypeId', getJiraIssueId(issueTypes, e.target.value as string));
                        setSelectedJiraIssueType(e.target.value);
                        setSomethingChangedInJira(true);
                      }}
                      value={
                        // isJiraAlreadyEnabled ?
                        selectedJiraIssueType
                        // :
                        // jiraIssueTypesNamesList.filter((item: any) => {
                        //   if (item !== 'Epic' || item !== 'Sub-task') {
                        //     return item;
                        //   }
                        // })
                      }
                      validValues={jiraIssueTypesNamesList.filter((item: any) => {
                        if (item === 'Sub-task' || item === 'Epic') {
                          return false;
                        }
                        return true;
                      })}
                      width={'220px'}
                    />
                  </Stack>
                  <Stack>
                    <Typography variant="subtitle2">Jira Priority*</Typography>
                    <CognisaasSelect
                      disabled={
                        (isJSDenabled && isJiraAlreadyEnabled) || !userPermissions?.includes(userPermissionSchema.EDIT)
                      }
                      name="jiraPriority"
                      onChange={(e: any) => {
                        props.setFieldValue('jiraPriority', e.target.value);
                        setSelectedJiraPriority(e.target.value);
                        setSomethingChangedInJira(true);
                      }}
                      value={selectedJiraPriority}
                      validValues={[...jiraPriorityValues]}
                      width={'220px'}
                    />
                  </Stack>
                </Stack>
              ) : (
                <></>
              )}
              <Stack mb={2} ml={2} direction="column">
                <Typography variant="subtitle2">{label_task} Description</Typography>
                <TextareaAutosize
                  name="taskDescription"
                  value={props.values.taskDescription}
                  maxRows={15}
                  aria-label="maximum height"
                  placeholder="Enter description here"
                  defaultValue=""
                  style={{ width: 420, height: 88 }}
                  onChange={props.handleChange}
                  disabled={!userPermissions?.includes(userPermissionSchema.EDIT)}
                />
              </Stack>

              <Stack spacing={3} m={3} direction="row">
                <Stack>
                  <Stack spacing={1} direction="row">
                    <EmptyMileStone />
                    <Typography variant="subtitle2">Its a milestone {props.values.isMileStone}</Typography>
                  </Stack>
                  <Stack>
                    <Switch
                      name="mileStone"
                      disabled={!userPermissions?.includes(userPermissionSchema.EDIT)}
                      checked={props.values.isMileStone}
                      onChange={(e: any) => {
                        props.setFieldValue('isMileStone', e.target.checked);
                        if (e.target.checked) {
                          props.setFieldValue('csatIndicator', true);
                        }
                      }}
                    ></Switch>
                  </Stack>
                </Stack>
                {props.values.isTeamTask == 1 && (
                  <Stack>
                    <Stack spacing={1} direction="row">
                      <Typography variant="subtitle2">Share with {label_client.toLowerCase()}</Typography>
                    </Stack>
                    <Stack>
                      <Switch
                        disabled={!userPermissions?.includes(userPermissionSchema.EDIT)}
                        name="shareWithClient"
                        checked={props.values.shareWithClient}
                        onChange={(e: any) => {
                          props.setFieldValue('shareWithClient', e.target.checked);
                        }}
                      ></Switch>
                    </Stack>
                  </Stack>
                )}
                {props.values.isTeamTask == 1 && (
                  <Stack>
                    <Stack spacing={1} direction="row">
                      <Typography variant="subtitle2">CSAT</Typography>
                    </Stack>
                    <Stack>
                      <Switch
                        name="csatIndicator"
                        disabled={!userPermissions?.includes(userPermissionSchema.EDIT)}
                        checked={props.values.csatIndicator}
                        onChange={(e: any) => {
                          props.setFieldValue('csatIndicator', e.target.checked);
                        }}
                      ></Switch>
                    </Stack>
                  </Stack>
                )}

                {customerDetail?.customer_jira_api_url && props.values.isTeamTask == 1 ? (
                  <Stack
                    direction="row"
                    className={'cognisaas-pointer'}
                    justifyContent={'center'}
                    onClick={() => {
                      if (!isJiraAlreadyEnabled) {
                        setIsJiraEnabled(!isJiraEnabled);
                        props.setFieldValue('pushToJira', !isJiraEnabled);
                      }
                    }}
                  >
                    {isJiraAlreadyEnabled ? (
                      <CognisaaasTooltip
                        title={
                          <Typography variant="subtitle1">You can&apos;t turn off Jira, once turned On.</Typography>
                        }
                      >
                        <Stack>
                          <Stack spacing={1} direction="row">
                            <Typography variant="subtitle2">Push to Jira</Typography>
                          </Stack>
                          <Stack>
                            <Switch
                              disabled={isJiraAlreadyEnabled || !userPermissions?.includes(userPermissionSchema.EDIT)}
                              name="pushToJira"
                              checked={isJiraEnabled || isJiraAlreadyEnabled}
                            ></Switch>
                          </Stack>
                        </Stack>
                      </CognisaaasTooltip>
                    ) : (
                      <>
                        <Stack>
                          <Stack spacing={1} direction="row">
                            <Typography variant="subtitle2">Push to Jira</Typography>
                          </Stack>
                          <Stack>
                            <Switch
                              disabled={isJiraAlreadyEnabled || !userPermissions?.includes(userPermissionSchema.EDIT)}
                              name="pushToJira"
                              checked={isJiraEnabled || taskDetailForModal?.pushToJira}
                            ></Switch>
                          </Stack>
                        </Stack>
                      </>
                    )}
                  </Stack>
                ) : null}
              </Stack>
              {userPermissions?.includes(userPermissionSchema.EDIT) && (
                <Stack spacing={2} justifyContent={'end'} direction="row">
                  {isSaveInProgress ? (
                    <LoadingButton loading loadingPosition="start" startIcon={<SaveIcon />} variant="outlined">
                      Save
                    </LoadingButton>
                  ) : (
                    <CognisaasButton
                      clickHandler={() => {
                        props.handleSubmit();
                      }}
                      isOutlined={false}
                      disabled={
                        (!props.isValid || !props.dirty) && !somethingChangedInJira && taskDetailForModal === null
                      }
                      label="Save"
                    />
                  )}
                  <CognisaasButton clickHandler={() => closeDrawer()} isOutlined={true} label="Cancel" />
                </Stack>
              )}
            </Stack>
            {assignTaskOwnerToClientModal && (
              <BasicModal open={true} onClose={() => setAssignTaskOwnerToClientModal(!assignTaskOwnerToClientModal)}>
                <AssignTaskToClientModal
                  onClickingContinue={(name: string) => {
                    onClickingContinue(name, props.setFieldValue);
                  }}
                  onClose={() => setAssignTaskOwnerToClientModal(!assignTaskOwnerToClientModal)}
                  client={client}
                  preselectedClientContacts={preselectedClientContacts}
                />
              </BasicModal>
            )}

            {assignTaskOwnerToTeamModal && (
              <BasicModal open={true} onClose={() => setAssignTaskOwnerToTeamModal(!assignTaskOwnerToTeamModal)}>
                <AssignTaskToTeamModal
                  onClickingContinue={(name: string) => {
                    onClickingContinueForTeamModal(name, props.setFieldValue);
                  }}
                  onClose={() => setAssignTaskOwnerToTeamModal(!assignTaskOwnerToTeamModal)}
                  client={client}
                  preselectedProjectMembers={preselectedProjectMembers}
                />
              </BasicModal>
            )}

            <BasicModal open={jiraCustomFieldsModal} onClose={() => setJiraCustomFieldsModal(false)}>
              <Stack>
                <JiraCustomFields
                  onClose={() => {
                    setJiraCustomFieldsModal(!jiraCustomFieldsModal);
                    setIsSaveInProgress(false);
                  }}
                  taskDetail={task}
                  fields={jiraCustomfields}
                  handleCustomFieldsSave={handleCustomMandatoryFieldsSave}
                ></JiraCustomFields>
              </Stack>
            </BasicModal>

            <BasicModal open={jiraCustomFieldsModalForUpdate} onClose={() => setJiraCustomFieldsModalForUpdate(false)}>
              <Stack>
                <JiraCustomFields
                  onClose={() => {
                    setJiraCustomFieldsModalForUpdate(false);
                    setIsSaveInProgress(false);
                  }}
                  taskDetail={task}
                  fields={jiraCustomfields}
                  handleCustomFieldsSave={handleCustomMandatoryFieldsForUpdate}
                ></JiraCustomFields>
              </Stack>
            </BasicModal>
            {jsdCustomFieldsModal && (
              <BasicModal
                height={jiraCustomfields.length > 2 ? 400 : 250}
                open={jsdCustomFieldsModal}
                onClose={() => setJsdCustomFieldsModal(false)}
              >
                <Stack>
                  <JiraServiceDeskCustomFields
                    onClose={() => setJsdCustomFieldsModal(false)}
                    taskDetail={taskDetailsToBeAdded}
                    fields={jiraCustomfields}
                    handleCustomFieldsSave={handleCustomMandatoryFieldsOfJsd}
                  ></JiraServiceDeskCustomFields>
                </Stack>
              </BasicModal>
            )}
          </form>
        )}
      </Formik>
      <CognisaasToast
        fromPanel={true}
        open={alertDetails.isVisible}
        onClose={() => setAlertDetails({ isVisible: false, type: alertDetails.type, message: '' })}
        message={alertDetails.message}
        type={alertDetails.type}
      />
      <BasicModal
        height={350}
        width={600}
        open={dateShiftConfirmation}
        onClose={() => {
          setDateShiftConfirmation(true);
        }}
      >
        <DateShiftConfirmationModal
          type={task.case_uid ? USECASE : PROJECT}
          doContinue={doContinue}
          fromDate={task.case_uid ? ucDetail?.eta_forcasedelivery : task.eta_forprojectdelivery}
          shiftedBy={shiftedDays}
        />
      </BasicModal>
    </>
  );
};

export default EditTask;

// tiboj71506@aosod.com
