import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { templatesApi } from '../../../api/modules/templates';
import { taskAPI } from 'api/modules/tasks';
import { requestState } from '../types';

interface authType {
  results: any;
  status: requestState;
  error: null | undefined | string;
  isLoading: boolean;
  projectTemplates: any;
  usecaseTemplates: any;
  usecaseTemplatesStatus: any;
  tempProjectTemplateUid: any;
  tempCaseTemplateUid: any;
  userDetails: any;
  template_section_sequence: any;
  template_project_uid: any;
  AllSections: any;
  useCaseTemplateUid: any;
  usecaseTemplateSections: any;
  useCaseTemplateSectionSequenceNo: any;
  redirectToTemplates: any;
  getTemplateProjectInformation: any;
  getTemplateProjectInformationStatus: requestState;
  getTemplateProjectInformationError: null | string | undefined;

  getTemplateUsecaseInformation: any;
  getTemplateUsecaseInformationStatus: requestState;
  getTemplateUsecaseInformationError: null | string | undefined;
  projectTemplateInformation: any;
  usecaseTemplateInformation: any;

  getTemplateProjectTaskDependency: any;
  getTemplateProjectTaskDependencyStatus: requestState;
  getTemplateProjectTaskDependencyError: null | string | undefined;

  getTemplateProjectTeamTaskList: any;
  getTemplateProjectTeamTaskListStatus: requestState;
  getTemplateProjectTeamTaskListError: null | string | undefined;

  getTemplateProjectClientTaskList: any;
  getTemplateProjectClientTaskListStatus: requestState;
  getTemplateProjectClientTaskListError: null | string | undefined;

  getTemplateUsecaseTaskDependency: any;
  getTemplateUsecaseTaskDependencyStatus: requestState;
  getTemplateUsecaseTaskDependencyError: null | string | undefined;

  getTemplateUsecaseTeamTaskList: any;
  getTemplateUsecaseTeamTaskListStatus: requestState;
  getTemplateUsecaseTeamTaskListError: null | string | undefined;

  getTemplateUsecaseClientTaskList: any;
  getTemplateUsecaseClientTaskListStatus: requestState;
  getTemplateUsecaseClientTaskListError: null | string | undefined;

  cloneProjectTemplateStatus: requestState;
  cloneProjectTemplateError: null | undefined | string;

  cloneUsecaseTemplateStatus: requestState;
  cloneUsecaseTemplateError: null | undefined | string;

  updateTemplatePhaseStatus: requestState;
  updateTemplatePhaseError: null | undefined | string;

  addTemplateDocument: any;
  addTemplateDocumentStatus: requestState;
  addTemplateDocumentError: null | undefined | string;

  deleteTemplateDocumentStatus: requestState;
  deleteTemplateDocumentError: null | undefined | string;

  getTemplateDocuments: any;
  getTemplateDocumentsStatus: requestState;
  getTemplateDocumentsError: null | undefined | string;

  incrementTemplateProjectSectionSequencesStatus: requestState;
  incrementTemplateProjectSectionSequencesError: null | undefined | string;

  incrementTemplateCaseSectionSequencesStatus: requestState;
  incrementTemplateCaseSectionSequencesError: null | undefined | string;

  newDocumentTemplateModal: boolean;

  createProjectTemplateExcelView: any[];
  createProjectTemplateExcelViewSelected: boolean;

  updateProjectTemplateExcelView: any[];
  updateProjectTemplateExcelViewSelected: boolean;

  createUsecaseTemplateExcelView: any[];
  createUsecaseTemplateExcelViewSelected: boolean;

  updateUsecaseTemplateExcelView: any[];
  updateUsecaseTemplateExcelViewSelected: boolean;
}

const initialState: authType = {
  results: {},
  status: requestState.idle,
  error: null,
  isLoading: false,
  projectTemplates: [],
  usecaseTemplates: [],
  usecaseTemplatesStatus: requestState.idle,
  tempProjectTemplateUid: '',
  tempCaseTemplateUid: '',
  userDetails: {},
  template_section_sequence: 0,
  template_project_uid: '',
  AllSections: [],
  useCaseTemplateUid: '',
  usecaseTemplateSections: [],
  useCaseTemplateSectionSequenceNo: 0,
  redirectToTemplates: false,
  projectTemplateInformation: {},
  usecaseTemplateInformation: {},
  getTemplateProjectInformation: null,
  getTemplateProjectInformationStatus: requestState.idle,
  getTemplateProjectInformationError: null,

  getTemplateUsecaseInformation: null,
  getTemplateUsecaseInformationStatus: requestState.idle,
  getTemplateUsecaseInformationError: null,

  getTemplateProjectTaskDependency: [],
  getTemplateProjectTaskDependencyStatus: requestState.idle,
  getTemplateProjectTaskDependencyError: null,

  getTemplateProjectTeamTaskList: [],
  getTemplateProjectTeamTaskListStatus: requestState.idle,
  getTemplateProjectTeamTaskListError: null,

  getTemplateProjectClientTaskList: [],
  getTemplateProjectClientTaskListStatus: requestState.idle,
  getTemplateProjectClientTaskListError: null,

  getTemplateUsecaseTaskDependency: [],
  getTemplateUsecaseTaskDependencyStatus: requestState.idle,
  getTemplateUsecaseTaskDependencyError: null,

  getTemplateUsecaseTeamTaskList: [],
  getTemplateUsecaseTeamTaskListStatus: requestState.idle,
  getTemplateUsecaseTeamTaskListError: null,

  getTemplateUsecaseClientTaskList: [],
  getTemplateUsecaseClientTaskListStatus: requestState.idle,
  getTemplateUsecaseClientTaskListError: null,

  cloneProjectTemplateStatus: requestState.idle,
  cloneProjectTemplateError: null,

  cloneUsecaseTemplateStatus: requestState.idle,
  cloneUsecaseTemplateError: null,

  updateTemplatePhaseStatus: requestState.idle,
  updateTemplatePhaseError: null,

  getTemplateDocuments: [],
  getTemplateDocumentsStatus: requestState.idle,
  getTemplateDocumentsError: null,

  addTemplateDocument: [],
  addTemplateDocumentStatus: requestState.idle,
  addTemplateDocumentError: null,

  deleteTemplateDocumentStatus: requestState.idle,
  deleteTemplateDocumentError: null,

  incrementTemplateProjectSectionSequencesStatus: requestState.idle,
  incrementTemplateProjectSectionSequencesError: null,

  incrementTemplateCaseSectionSequencesStatus: requestState.idle,
  incrementTemplateCaseSectionSequencesError: null,

  newDocumentTemplateModal: false,

  createProjectTemplateExcelView: [],
  createProjectTemplateExcelViewSelected: false,

  updateProjectTemplateExcelView: [],
  updateProjectTemplateExcelViewSelected: false,

  createUsecaseTemplateExcelView: [],
  createUsecaseTemplateExcelViewSelected: false,

  updateUsecaseTemplateExcelView: [],
  updateUsecaseTemplateExcelViewSelected: false,
};

export const getProjectTemplates = createAsyncThunk('getProjectTemplatesByCustomerId', async () => {
  const { data } = await templatesApi.getProjectTemplates();
  return data;
});

export const getUseCaseTemplatesbycustomer = createAsyncThunk('getUsecaseTemplatesByCustomerId', async () => {
  const { data } = await templatesApi.getUseCaseTemplatesbycustomer();
  return data;
});

export const addTemplateProject = createAsyncThunk('createTemporaryTemplate', async (template_owner_name: any) => {
  const { data } = await templatesApi.addTemplateProject(template_owner_name);
  return data;
});

export const getCustDetails = createAsyncThunk('getCustomerDetialsWithCustomerUid', async () => {
  const { data } = await templatesApi.getCustDetails();
  return data;
});

export const saveTemplateProjectSection = createAsyncThunk('saveTemplateProjectSection', async (phaseObject: any) => {
  const { data } = await templatesApi.saveTemplateProjectSection(phaseObject);
  return data;
});

export const getAllSectionByTemplateProjectuid = createAsyncThunk(
  'getAllSectionByTemplateProjectuid',
  async (template_project_uid: any) => {
    const { data } = await templatesApi.getAllSectionByTemplateProjectuid(template_project_uid);
    return data.result ?? [];
  }
);

export const addTemplateProjectTask = createAsyncThunk('addTemplateProjectTask', async (taskObj: any) => {
  const { data } = await templatesApi.addTemplateProjectTask(taskObj);
  return data;
});

export const addTemplateProjectClientTask = createAsyncThunk(
  'addTemplateProjectClientTask',
  async (clientTaskObj: any) => {
    const { data } = await templatesApi.addTemplateProjectClientTask(clientTaskObj);
    return data;
  }
);

export const updateTemplateProjectByUid = createAsyncThunk('updateTemplateProjectByUid', async (clientTaskObj: any) => {
  const { data } = await templatesApi.updateTemplateProjectByUid(clientTaskObj);
  return data;
});

export const addTemplateUseCase = createAsyncThunk('addTemplateUseCase', async (phaseObj: any) => {
  const { data } = await templatesApi.addTemplateUseCase(phaseObj);
  return data;
});

export const updateTemplateProjectTaskbyuid = createAsyncThunk(
  'updateTemplateProjectTaskbyuid',
  async (taskObj: any) => {
    const { data } = await templatesApi.updateTemplateProjectTaskbyuid(taskObj);
    return data;
  }
);

export const updateTemplateProjectClienttaskByuid = createAsyncThunk(
  'updateTemplateProjectClienttaskByuid',
  async (taskObj: any) => {
    const { data } = await templatesApi.updateTemplateProjectClienttaskByuid(taskObj);
    return data;
  }
);

export const updateTemplateUsecaseTaskbyuid = createAsyncThunk(
  'updateTemplateUsecaseTaskbyuid',
  async (taskObj: any) => {
    const { data } = await templatesApi.updateTemplateUsecaseTaskbyuid(taskObj);
    return data;
  }
);

export const updateTemplateUsecaseClienttaskByuid = createAsyncThunk(
  'updateTemplateUsecaseClienttaskByuid',
  async (taskObj: any) => {
    const { data } = await templatesApi.updateTemplateUsecaseClienttaskByuid(taskObj);
    return data;
  }
);

export const saveTemplateUsecaseSection = createAsyncThunk('saveTemplateUsecaseSection', async (phaseObj: any) => {
  const { data } = await templatesApi.saveTemplateUsecaseSection(phaseObj);
  return data;
});

export const getAllSectionByTemplateCaseuid = createAsyncThunk(
  'getAllSectionByTemplateCaseuid',
  async (template_case_uid: any) => {
    const { data } = await templatesApi.getAllSectionByTemplateCaseuid(template_case_uid);
    return data.result ?? [];
  }
);

export const addTemplateUsecaseTask = createAsyncThunk(
  'addTemplateUsecaseTask',
  async (useCaseTaskTemplateObj: any) => {
    const { data } = await templatesApi.addTemplateUsecaseTask(useCaseTaskTemplateObj);
    return data;
  }
);

export const addTemplateUsecaseClientTask = createAsyncThunk(
  'addTemplateUsecaseClientTask',
  async (useCaseTaskClientTemplateObj: any) => {
    const { data } = await templatesApi.addTemplateUsecaseClientTask(useCaseTaskClientTemplateObj);
    return data;
  }
);

export const updateTemplateUsecaseByUid = createAsyncThunk(
  'updateTemplateUsecaseByUid',
  async (updateTemplateObj: any) => {
    const { data } = await templatesApi.updateTemplateUsecaseByUid(updateTemplateObj);
    return data;
  }
);

export const syncProductsandFeaturesFromJIRAbyOneCustomer = createAsyncThunk(
  'syncProductsandFeaturesFromJIRAbyOneCustomer',
  async () => {
    const { data } = await templatesApi.syncProductsandFeaturesFromJIRAbyOneCustomer();
    return data;
  }
);

export const getTemplateProjectinformation = createAsyncThunk(
  'getTemplateProjectinformation',
  async (template_project_uid: string) => {
    const { data } = await templatesApi.getTemplateProjectinformation(template_project_uid);
    return data;
  }
);
export const getTemplateProjectInformation = createAsyncThunk(
  'getTemplateProjectInformation',
  async (project_uid: string) => {
    const { data } = await templatesApi.getTemplateProjectInformation(project_uid);
    console.log(data);
    return data.result;
  }
);

export const getTemplateUsecaseInformation = createAsyncThunk(
  'getTemplateUsecaseInformation',
  async (case_uid: string) => {
    const { data } = await templatesApi.getTemplateUsecaseinformation(case_uid);
    // console.log(data);
    return data.result;
  }
);

export const deleteTemplateProjectbyuid = createAsyncThunk(
  'deleteTemplateProjectbyuid',
  async (template_project_uid: string) => {
    const { data } = await templatesApi.deleteTemplateProjectbyuid(template_project_uid);
    return data;
  }
);

export const deleteTemplateUsecasebyuid = createAsyncThunk(
  'deleteTemplateUsecasebyuid',
  async (template_case_uid: string) => {
    const { data } = await templatesApi.deleteTemplateUsecasebyuid(template_case_uid);
    return data;
  }
);

export const deleteUsecaseTemplatesectionByUid = createAsyncThunk(
  'deleteUsecaseTemplatesectionByUid',
  async (template_section_uid: string) => {
    const { data } = await templatesApi.deleteUsecaseTemplatesectionByUid(template_section_uid);
    return data;
  }
);

export const deleteProjectTemplatesectionByUid = createAsyncThunk(
  'deleteProjectTemplatesectionByUid',
  async (template_section_uid: string) => {
    const { data } = await templatesApi.deleteProjectTemplatesectionByUid(template_section_uid);
    return data;
  }
);

export const deleteTemplateProjectTaskbyUid = createAsyncThunk(
  'deleteTemplateProjectTaskbyUid',
  async (template_task_uid: string) => {
    const { data } = await templatesApi.deleteTemplateProjectTaskbyUid(template_task_uid);
    return data;
  }
);
export const deleteTemplateProjectClientTaskbyUid = createAsyncThunk(
  'deleteTemplateProjectClientTaskbyUid',
  async (template_task_uid: string) => {
    const { data } = await templatesApi.deleteTemplateProjectClientTaskbyUid(template_task_uid);
    return data;
  }
);
export const deleteTemplateUsecaseTaskbyUid = createAsyncThunk(
  'deleteTemplateUsecaseTaskbyUid',
  async (template_task_uid: string) => {
    const { data } = await templatesApi.deleteTemplateUsecaseTaskbyUid(template_task_uid);
    return data;
  }
);
export const deleteTemplateusecaseClientTaskbyUid = createAsyncThunk(
  'deleteTemplateusecaseClientTaskbyUid',
  async (template_task_uid: string) => {
    const { data } = await templatesApi.deleteTemplateusecaseClientTaskbyUid(template_task_uid);
    return data;
  }
);

export const getTemplateProjectTaskDependency = createAsyncThunk('task/getTaskDependency', async (detail: any) => {
  const { data } = await taskAPI.getProjectTaskDependency(detail);
  return data.result;
});

export const getTemplateUsecaseTaskDependency = createAsyncThunk(
  'task/getUsecaseTaskDependency',
  async (detail: any) => {
    const { data } = await taskAPI.getTaskDependency(detail);
    return data.result;
  }
);

export const getTemplateProjectTeamTaskList = createAsyncThunk(
  'task/getTemplateTeamtasks',
  async (project_uid: any) => {
    const { data } = await templatesApi.getTemplateProjectTeamTasks(project_uid);
    return data.result;
  }
);

export const getTemplateProjectClientTaskList = createAsyncThunk(
  'task/getTemplateClienttasks',
  async (project_uid: any) => {
    const { data } = await templatesApi.getTemplateProjectClientTasks(project_uid);
    return data.result;
  }
);

export const getTemplateUsecaseTeamTaskList = createAsyncThunk(
  'task/getTemplateUsecaseTeamtasks',
  async (project_uid: any) => {
    const { data } = await templatesApi.getTemplateUsecaseTeamTasks(project_uid);
    return data.result;
  }
);

export const getTemplateUsecaseClientTaskList = createAsyncThunk(
  'task/getTemplateUsecaseClienttasks',
  async (project_uid: any) => {
    const { data } = await templatesApi.getTemplateUsecaseClientTasks(project_uid);
    return data.result;
  }
);

export const cloneProjectTemplate = createAsyncThunk('task/cloneProjectTemplate', async (detail: any) => {
  const { data } = await templatesApi.cloneProjectTemplate(detail);
  console.log(data);
  return data.result;
});

export const cloneUsecaseTemplate = createAsyncThunk('task/cloneUsecaseTemplate', async (detail: any) => {
  const { data } = await templatesApi.cloneUsecaseTemplate(detail);
  console.log(data);
  return data.result;
});

export const updateTemplatePhase = createAsyncThunk('task/updateTemplatePhase', async (detail: any) => {
  const { data } = await templatesApi.updateTemplatePhase(detail);
  return data.result;
});

export const addTemplateDocument = createAsyncThunk('task/addTemplateDocument', async (detail: any) => {
  const { data } = await templatesApi.addTemplateDocument(detail);
  return data.result ?? {};
});

export const deleteTemplateDocument = createAsyncThunk('task/deleteTemplateDocument', async (detail: any) => {
  const { data } = await templatesApi.deleteTemplateDocument(detail);
  return data.result ?? {};
});

export const getTemplateDocumentByCustomerUid = createAsyncThunk(
  'task/getTemplateDocumentByCustomerUid',
  async (customer_uid: string) => {
    const { data } = await templatesApi.getTemplateDocumentByCustomerUid(customer_uid);
    return data.result ?? [];
  }
);
export const sendTaskAssignmentMailImportingTemplate = createAsyncThunk(
  'template/sendTaskAssignmentMailImportingTemplate',
  async (detail: any) => {
    await templatesApi.sendTaskAssignmentMailImportingTemplate(detail);
  }
);

export const incrementTemplateProjectSectionSequences = createAsyncThunk(
  'template/ incrementTemplateProjectSectionSequences',
  async (detail: any) => {
    const { project_uid, section_uid, section_sequence } = detail;
    await templatesApi.incrementTemplateProjectSection(project_uid, section_sequence, section_uid);
  }
);

export const incrementTemplateCaseSectionSequences = createAsyncThunk(
  'template/ incrementTemplateCaseSectionSequences',
  async (detail: any) => {
    const { case_uid, section_uid, section_sequence } = detail;
    await templatesApi.incrementTemplateCaseSection(case_uid, section_sequence, section_uid);
  }
);

export const getTemplateProjectDependency = createAsyncThunk(
  'template/getTemplateProjectDependency',
  async (project_uid: any) => {
    const { data } = await templatesApi.getTemplateProjectDependency(project_uid);
    return data.result;
  }
);

export const getTemplateUsecaseDependency = createAsyncThunk(
  'template/getTemplateUsecaseDependency',
  async (project_uid: any) => {
    const { data } = await templatesApi.getTemplateUsecaseDependency(project_uid);
    return data.result;
  }
);


const TemplateSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setRedirectFalse: (state) => {
      state.redirectToTemplates = false;
    },
    setSectionsDataStore: (state) => {
      state.AllSections = [];
    },
    setUsecaseSectionsDataStore: (state) => {
      state.usecaseTemplateSections = [];
    },
    openNewDocumentTemplateModal: (state) => {
      state.newDocumentTemplateModal = true;
    },
    closeNewDocumentTemplateModal: (state) => {
      state.newDocumentTemplateModal = false;
    },
    updateProjectTemplateExcelData: (state, action) => {
      console.log(action.payload);
      state.createProjectTemplateExcelView = [...action.payload];
    },
    toggleCreateProjectTemplateExcelViewSelectedState: (state) => {
      console.log(state.createProjectTemplateExcelViewSelected);
      state.createProjectTemplateExcelViewSelected = !state.createProjectTemplateExcelViewSelected;
    },
    updateProjectTemplateExcelDataUpdateView: (state, action) => {
      console.log(action.payload);
      state.updateProjectTemplateExcelView = [...action.payload];
    },

    toggleUpdateProjectTemplateExcelViewSelectedState: (state) => {
      console.log(state.createProjectTemplateExcelViewSelected);
      state.updateProjectTemplateExcelViewSelected = !state.updateProjectTemplateExcelViewSelected;
    },

    updateUsecaseTemplateExcelData: (state, action) => {
      console.log(action.payload);
      state.createUsecaseTemplateExcelView = [...action.payload];
    },
    toggleCreateUsecaseTemplateExcelViewSelectedState: (state) => {
      console.log(state.createUsecaseTemplateExcelViewSelected);
      state.createUsecaseTemplateExcelViewSelected = !state.createUsecaseTemplateExcelViewSelected;
    },
    updateUsecaseTemplateExcelDataUpdateView: (state, action) => {
      console.log(action.payload);
      state.updateUsecaseTemplateExcelView = [...action.payload];
    },

    toggleUpdateUsecaseTemplateExcelViewSelectedState: (state) => {
      console.log(state.createUsecaseTemplateExcelViewSelected);
      state.updateUsecaseTemplateExcelViewSelected = !state.updateUsecaseTemplateExcelViewSelected;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getProjectTemplates.pending, (state) => {
        state.status = requestState.loading;
        state.isLoading = true;
      })
      .addCase(getProjectTemplates.fulfilled, (state: authType, { payload }) => {
        state.status = requestState.success;
        state.projectTemplates = payload.result;
      })
      .addCase(getProjectTemplates.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      })
      .addCase(getUseCaseTemplatesbycustomer.pending, (state) => {
        state.status = requestState.loading;
        state.usecaseTemplatesStatus = requestState.loading;
        state.isLoading = true;
      })
      .addCase(getUseCaseTemplatesbycustomer.fulfilled, (state: authType, { payload }) => {
        state.status = requestState.success;
        state.usecaseTemplatesStatus = requestState.success;
        state.usecaseTemplates = payload.result;
      })
      .addCase(getUseCaseTemplatesbycustomer.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      })
      .addCase(addTemplateProject.pending, (state) => {
        state.status = requestState.loading;
        state.usecaseTemplatesStatus = requestState.loading;
        state.isLoading = true;
      })
      .addCase(addTemplateProject.fulfilled, (state: authType, { payload }) => {
        state.status = requestState.success;
        state.tempProjectTemplateUid = payload.result;
      })
      .addCase(addTemplateProject.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      })
      .addCase(getTemplateDocumentByCustomerUid.pending, (state) => {
        state.status = requestState.loading;
        state.isLoading = true;
      })
      .addCase(getTemplateDocumentByCustomerUid.fulfilled, (state: authType, action) => {
        state.status = requestState.success;
        state.getTemplateDocuments = action.payload;
      })
      .addCase(getTemplateDocumentByCustomerUid.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      })
      .addCase(addTemplateDocument.pending, (state) => {
        state.status = requestState.loading;
        state.isLoading = true;
      })
      .addCase(addTemplateDocument.fulfilled, (state, action) => {
        state.addTemplateDocument = { ...action.payload };
        state.status = requestState.success;
      })
      .addCase(addTemplateDocument.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      })
      .addCase(deleteTemplateDocument.pending, (state) => {
        state.status = requestState.loading;
        state.isLoading = true;
      })
      .addCase(deleteTemplateDocument.fulfilled, (state) => {
        state.status = requestState.success;
      })
      .addCase(deleteTemplateDocument.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      })
      .addCase(getCustDetails.pending, (state) => {
        state.status = requestState.loading;
        state.isLoading = true;
      })
      .addCase(getCustDetails.fulfilled, (state: authType, { payload }) => {
        state.status = requestState.success;
        state.userDetails = payload.result;
      })
      .addCase(getCustDetails.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      })
      .addCase(saveTemplateProjectSection.pending, (state) => {
        state.status = requestState.loading;
        state.isLoading = true;
      })
      .addCase(saveTemplateProjectSection.fulfilled, (state: authType, { payload }) => {
        state.status = requestState.success;
        state.template_section_sequence = payload.result.template_section_sequence;
        state.template_project_uid = payload.result.template_project_uid;
      })
      .addCase(saveTemplateProjectSection.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      })
      .addCase(getAllSectionByTemplateProjectuid.pending, (state) => {
        state.status = requestState.loading;
        state.isLoading = true;
      })
      .addCase(getAllSectionByTemplateProjectuid.fulfilled, (state: authType, { payload }) => {
        state.status = requestState.success;
        payload.forEach((section: any) =>
          section.task.sort((a: any, b: any) => {
            return parseInt(a.task_sequence) - parseInt(b.task_sequence);
          })
        );
        state.AllSections = payload;
      })
      .addCase(getAllSectionByTemplateProjectuid.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      })
      .addCase(addTemplateProjectTask.pending, (state) => {
        state.status = requestState.loading;
        state.isLoading = true;
      })
      .addCase(addTemplateProjectTask.fulfilled, (state: authType) => {
        state.status = requestState.success;
        // state.AllSections = payload.result;
      })
      .addCase(addTemplateProjectTask.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      })
      .addCase(addTemplateProjectClientTask.pending, (state) => {
        state.status = requestState.loading;
        state.isLoading = true;
      })
      .addCase(addTemplateProjectClientTask.fulfilled, (state: authType) => {
        state.status = requestState.success;
        // state.AllSections = payload.result;
      })
      .addCase(addTemplateProjectClientTask.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      })
      .addCase(addTemplateUseCase.pending, (state) => {
        state.status = requestState.loading;
        // state.tempCaseTemplateUid = payload.result;
        state.isLoading = true;
      })
      .addCase(addTemplateUseCase.fulfilled, (state: authType, { payload }) => {
        state.status = requestState.success;
        state.useCaseTemplateUid = payload.result;
        state.useCaseTemplateSectionSequenceNo = payload.result.template_section_sequence;
      })
      .addCase(addTemplateUseCase.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      })
      .addCase(getAllSectionByTemplateCaseuid.pending, (state) => {
        state.status = requestState.loading;
        state.isLoading = true;
      })
      .addCase(getAllSectionByTemplateCaseuid.fulfilled, (state: authType, { payload }) => {
        state.status = requestState.success;
        payload.forEach((section: any) =>
          section.task.sort((a: any, b: any) => {
            return parseInt(a.task_sequence) - parseInt(b.task_sequence);
          })
        );
        state.usecaseTemplateSections = payload;
      })
      .addCase(getAllSectionByTemplateCaseuid.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      })
      .addCase(updateTemplateProjectByUid.pending, (state) => {
        state.status = requestState.loading;
        state.isLoading = true;
      })
      .addCase(updateTemplateProjectByUid.fulfilled, (state: authType) => {
        state.status = requestState.success;
        state.redirectToTemplates = true;
      })
      .addCase(updateTemplateProjectByUid.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      })
      .addCase(updateTemplateUsecaseByUid.pending, (state) => {
        state.status = requestState.loading;
        state.isLoading = true;
      })
      .addCase(updateTemplateUsecaseByUid.fulfilled, (state: authType) => {
        state.status = requestState.success;
        state.redirectToTemplates = true;
      })
      .addCase(updateTemplateUsecaseByUid.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      })
      .addCase(getTemplateProjectinformation.pending, (state) => {
        state.status = requestState.loading;
        state.isLoading = true;
      })
      .addCase(getTemplateProjectinformation.fulfilled, (state: authType, { payload }) => {
        state.status = requestState.success;
        // console.log(payload, 'template information');
        state.projectTemplateInformation = payload.result;
      })
      .addCase(getTemplateProjectinformation.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      })

      .addCase(getTemplateUsecaseInformation.pending, (state) => {
        state.status = requestState.loading;
        state.isLoading = true;
      })
      .addCase(getTemplateUsecaseInformation.fulfilled, (state: authType, { payload }) => {
        state.status = requestState.success;
        console.log(payload, 'template information');
        state.usecaseTemplateInformation = payload;
        // {
        //   template_case_id: '66',
        //   template_case_uid: '601e8837562db',
        //   customer_uid: '5ef9a05fa797e',
        //   template_case_name: 'new tetstingg14',
        //   template_case_target_days: '1234',
        //   template_fees: '10000',
        //   user_uid: '15243',
        //   template_case_deliverymanager: 'Not Assigned',
        //   template_owner_uid: '5f3fed15b8d68',
        //   template_owner_name: 'Abhay Agarwal',
        //   template_case_created_on: '2021-02-06 00:00:00',
        //   save_status: '1',
        //   project_rollout: '1',
        //   check_duration: '0',
        //   product: null,
        // };
      })
      .addCase(getTemplateUsecaseInformation.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      })

      // get template project Ibformation

      .addCase(getTemplateProjectInformation.pending, (state) => {
        state.getTemplateProjectInformationStatus = requestState.loading;
        state.isLoading = true;
      })
      .addCase(getTemplateProjectInformation.fulfilled, (state: authType, { payload }) => {
        console.log(payload);
        state.getTemplateProjectInformationStatus = requestState.success;
        state.getTemplateProjectInformation = { ...payload[0] };
        state.redirectToTemplates = true;
      })
      .addCase(getTemplateProjectInformation.rejected, (state, action) => {
        state.getTemplateProjectInformationStatus = requestState.failed;
        state.getTemplateProjectInformationError = action.error.message;
      })

      // get Template Project task dependency
      .addCase(getTemplateProjectTaskDependency.pending, (state) => {
        state.getTemplateProjectTaskDependencyStatus = requestState.loading;
        state.isLoading = true;
      })
      .addCase(getTemplateProjectTaskDependency.fulfilled, (state: authType, { payload }) => {
        console.log(payload);
        state.getTemplateProjectTaskDependencyStatus = requestState.success;
        if (payload) {
          state.getTemplateProjectTaskDependency = [...payload];
        } else {
          state.getTemplateProjectTaskDependency = [];
        }
        // state.redirectToTemplates = true;
      })
      .addCase(getTemplateProjectTaskDependency.rejected, (state, action) => {
        state.getTemplateProjectTaskDependencyStatus = requestState.failed;
        state.getTemplateProjectTaskDependencyError = action.error.message;
      })

      // get template project team task lst
      .addCase(getTemplateProjectTeamTaskList.pending, (state) => {
        state.getTemplateProjectTeamTaskListStatus = requestState.loading;
        // state.isLoading = true;
      })
      .addCase(getTemplateProjectTeamTaskList.fulfilled, (state: authType, { payload }) => {
        console.log(payload);
        state.getTemplateProjectTeamTaskListStatus = requestState.success;
        state.getTemplateProjectTeamTaskList = [...payload];
        // state.redirectToTemplates = true;
      })
      .addCase(getTemplateProjectTeamTaskList.rejected, (state, action) => {
        state.getTemplateProjectTeamTaskListStatus = requestState.failed;
        state.getTemplateProjectTeamTaskListError = action.error.message;
      })

      // GET project template client tasks

      // get template project team task lst
      .addCase(getTemplateProjectClientTaskList.pending, (state) => {
        state.getTemplateProjectClientTaskListStatus = requestState.loading;
        // state.isLoading = true;
      })
      .addCase(getTemplateProjectClientTaskList.fulfilled, (state: authType, { payload }) => {
        console.log(payload);
        state.getTemplateProjectClientTaskListStatus = requestState.success;
        state.getTemplateProjectClientTaskList = [...payload];
        // state.redirectToTemplates = true;
      })
      .addCase(getTemplateProjectClientTaskList.rejected, (state, action) => {
        state.getTemplateProjectClientTaskListStatus = requestState.failed;
        state.getTemplateProjectClientTaskListError = action.error.message;
      })

      // For use case dependency
      // get Template usecase task dependency
      .addCase(getTemplateUsecaseTaskDependency.pending, (state) => {
        state.getTemplateUsecaseTaskDependencyStatus = requestState.loading;
        state.isLoading = true;
      })
      .addCase(getTemplateUsecaseTaskDependency.fulfilled, (state: authType, { payload }) => {
        console.log(payload);
        state.getTemplateUsecaseTaskDependencyStatus = requestState.success;
        if (payload) {
          state.getTemplateUsecaseTaskDependency = [...payload];
        } else {
          state.getTemplateUsecaseTaskDependency = [];
        }
        // state.redirectToTemplates = true;
      })
      .addCase(getTemplateUsecaseTaskDependency.rejected, (state, action) => {
        state.getTemplateUsecaseTaskDependencyStatus = requestState.failed;
        state.getTemplateUsecaseTaskDependencyError = action.error.message;
      })

      // get template project team task lst
      .addCase(getTemplateUsecaseTeamTaskList.pending, (state) => {
        state.getTemplateUsecaseTeamTaskListStatus = requestState.loading;
        // state.isLoading = true;
      })
      .addCase(getTemplateUsecaseTeamTaskList.fulfilled, (state: authType, { payload }) => {
        console.log(payload);
        state.getTemplateUsecaseTeamTaskListStatus = requestState.success;
        state.getTemplateUsecaseTeamTaskList = [...payload];
        // state.redirectToTemplates = true;
      })
      .addCase(getTemplateUsecaseTeamTaskList.rejected, (state, action) => {
        state.getTemplateUsecaseTeamTaskListStatus = requestState.failed;
        state.getTemplateUsecaseTeamTaskListError = action.error.message;
      })

      // GET project template client tasks

      // get template project team task lst
      .addCase(getTemplateUsecaseClientTaskList.pending, (state) => {
        state.getTemplateUsecaseClientTaskListStatus = requestState.loading;
        // state.isLoading = true;
      })
      .addCase(getTemplateUsecaseClientTaskList.fulfilled, (state: authType, { payload }) => {
        console.log(payload);
        state.getTemplateUsecaseClientTaskListStatus = requestState.success;
        state.getTemplateUsecaseClientTaskList = [...payload];
        // state.redirectToTemplates = true;
      })
      .addCase(getTemplateUsecaseClientTaskList.rejected, (state, action) => {
        state.getTemplateUsecaseClientTaskListStatus = requestState.failed;
        state.getTemplateUsecaseClientTaskListError = action.error.message;
      })

      // task bulk update

      .addCase(cloneProjectTemplate.pending, (state) => {
        state.cloneProjectTemplateStatus = requestState.loading;
      })
      .addCase(cloneProjectTemplate.fulfilled, (state) => {
        state.cloneProjectTemplateStatus = requestState.success;
      })
      .addCase(cloneProjectTemplate.rejected, (state, action) => {
        state.cloneProjectTemplateError = action.error.message;
      })

      // task bulk update

      .addCase(cloneUsecaseTemplate.pending, (state) => {
        state.cloneUsecaseTemplateStatus = requestState.loading;
      })
      .addCase(cloneUsecaseTemplate.fulfilled, (state) => {
        state.cloneUsecaseTemplateStatus = requestState.success;
      })
      .addCase(cloneUsecaseTemplate.rejected, (state, action) => {
        state.cloneUsecaseTemplateError = action.error.message;
      })

      .addCase(updateTemplatePhase.pending, (state) => {
        state.updateTemplatePhaseStatus = requestState.loading;
      })
      .addCase(updateTemplatePhase.fulfilled, (state) => {
        state.updateTemplatePhaseStatus = requestState.success;
      })
      .addCase(updateTemplatePhase.rejected, (state, action) => {
        state.updateTemplatePhaseError = action.error.message;
      })

      .addCase(incrementTemplateProjectSectionSequences.pending, (state) => {
        state.incrementTemplateProjectSectionSequencesStatus = requestState.loading;
      })
      .addCase(incrementTemplateProjectSectionSequences.fulfilled, (state) => {
        state.incrementTemplateProjectSectionSequencesStatus = requestState.success;
      })
      .addCase(incrementTemplateProjectSectionSequences.rejected, (state, action) => {
        state.incrementTemplateProjectSectionSequencesError = action.error.message;
      })

      .addCase(incrementTemplateCaseSectionSequences.pending, (state) => {
        state.incrementTemplateCaseSectionSequencesStatus = requestState.loading;
      })
      .addCase(incrementTemplateCaseSectionSequences.fulfilled, (state) => {
        state.incrementTemplateCaseSectionSequencesStatus = requestState.success;
      })
      .addCase(incrementTemplateCaseSectionSequences.rejected, (state, action) => {
        state.incrementTemplateCaseSectionSequencesError = action.error.message;
      });
  },
});

export const {
  setRedirectFalse,
  setSectionsDataStore,
  setUsecaseSectionsDataStore,
  openNewDocumentTemplateModal,
  closeNewDocumentTemplateModal,
  updateProjectTemplateExcelData,
  updateProjectTemplateExcelDataUpdateView,
  toggleCreateProjectTemplateExcelViewSelectedState,
  toggleUpdateProjectTemplateExcelViewSelectedState,

  updateUsecaseTemplateExcelData,
  updateUsecaseTemplateExcelDataUpdateView,
  toggleCreateUsecaseTemplateExcelViewSelectedState,
  toggleUpdateUsecaseTemplateExcelViewSelectedState,
} = TemplateSlice.actions;
const templateSlice = TemplateSlice.reducer;
export default templateSlice;
