import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { requestState } from '../types';
import { usecaseAPI } from '../../../api/modules/usecase';
import { clientAPI } from 'api';
import { AddUsecaseCommentType } from './types';
import { isNull } from 'utils/dataHelpers';

interface usecaseType {
  usecaseCustomFields: Array<any>;
  getUsecaseCustomFieldsStatus: requestState;
  getUsecaseCustomFieldsError: null | undefined | string;

  usecaseCustomFieldsWithValues: Array<any>;
  getUsecaseCustomFieldsWithValuesStatus: requestState;
  getUsecaseCustomFieldsWithValuesError: null | undefined | string;

  updateUsecaseByUid: Array<any> | null;
  updateUsecaseByUidStatus: requestState;
  updateUsecaseByUidError: null | undefined | string;

  // updateUsecaseByUid: Array<any> | null;
  addUsecaseInformationStatus: requestState;
  addUsecaseInformationError: null | undefined | string;

  getUsecaseAudit: any;
  getUsecaseAuditStatus: requestState;
  getUsecaseAuditError: null | undefined | string;

  getUsecaseInfo: any;
  getUsecaseInfoStatus: requestState;
  getUsecaseInfoError: null | undefined | string;

  usecaseCustomFieldUpdateStatus: requestState;

  clientInformation: {
    data: any;
    status: requestState;
    error: null | undefined | string;
  };

  allUsecaseCustomFields: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };
  allUsecaseComments: {
    data: any[];
    status: requestState;
    message: null | undefined | string;
  };
}

const initialState: usecaseType = {
  usecaseCustomFields: [],
  getUsecaseCustomFieldsStatus: requestState.idle,
  getUsecaseCustomFieldsError: null,

  usecaseCustomFieldsWithValues: [],
  getUsecaseCustomFieldsWithValuesStatus: requestState.idle,
  getUsecaseCustomFieldsWithValuesError: null,

  updateUsecaseByUid: null,
  updateUsecaseByUidStatus: requestState.idle,
  updateUsecaseByUidError: null,

  addUsecaseInformationStatus: requestState.idle,
  addUsecaseInformationError: null,

  getUsecaseAudit: [],
  getUsecaseAuditStatus: requestState.idle,
  getUsecaseAuditError: null,

  getUsecaseInfo: {},
  getUsecaseInfoStatus: requestState.idle,
  getUsecaseInfoError: null,

  usecaseCustomFieldUpdateStatus: requestState.idle,

  clientInformation: {
    data: {},
    status: requestState.idle,
    error: null,
  },

  allUsecaseCustomFields: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  allUsecaseComments: {
    data: [],
    status: requestState.idle,
    message: null,
  },
};

export const getUsecaseByUid = createAsyncThunk('usecase/getUsecaseByUid', async (caseUid: string) => {
  const { data } = await usecaseAPI.getUsecaseByUid(caseUid);
  return data.result;
});

export const getClientInformation = createAsyncThunk('usecase/getClientInformation', async (client_uid: string) => {
  const { data } = await clientAPI.getClientInformation(client_uid);
  // console.log(data);
  return data.result;
});

export const getAllUsecaseCustomFields = createAsyncThunk('usecase/getUsecaseCustomFields', async () => {
  const { data } = await usecaseAPI.getUsecaseCustomFields();
  console.log(data);
  return data.result ?? [];
});

export const getAllUsecaseCustomFieldsWithValue = createAsyncThunk(
  'usecase/getUsecaseCustomFieldsWithValue',
  async (case_uid: string) => {
    const { data } = await usecaseAPI.getUsecaseCustomFieldsWithValues(case_uid);

    console.log(data);
    return data.result ?? [];
  }
);

export const updateUsecaseByUid = createAsyncThunk('usecase/updateusecasebyud', async (details: any) => {
  const { data } = await usecaseAPI.updateUsecaseByUid(details);
  // console.log(data);
  return data.result;
});

export const addUsecaseInformation = createAsyncThunk('usecase/addUsecaseInformation', async (detail: any) => {
  await usecaseAPI.addUsecaseInformation(detail);
});

export const addUsecaseAuditTrial = createAsyncThunk('project/addUsecaseAuditTrial', async (detail: any) => {
  const { data } = await usecaseAPI.addUsecaseAuditTrial(detail);
  console.log(data);
});

export const getUsecaseAudit = createAsyncThunk('usecase/getUsecaseAudit', async (case_uid: string) => {
  const { data } = await usecaseAPI.getUsecaseAuditTrail(case_uid);
  return data.result;
});

export const updateUsecaseCustomField = createAsyncThunk('usecase/updateUsecaseCustomField', async (payload: any) => {
  const { data } = await usecaseAPI.updateUsecaseCustomfield(payload);
  return data.result;
});

export const deleteUsecase = createAsyncThunk('usecase/deleteUsecase', async (detail: any) => {
  await usecaseAPI.deleteUsecase(detail);
});

export const getAllCustomFieldsOfAllUsecases = createAsyncThunk('project/getAllCustomFieldsOfAllUsecases', async () => {
  const { data } = await usecaseAPI.getAllUsecasesCustomField();
  return data;
});

export const getAllUsecaseComments = createAsyncThunk('usecase/getAllusecaseComments', async (usecase_uid: string) => {
  const { data } = await usecaseAPI.getUsecaseComments(usecase_uid);
  return data;
});

export const addusecaseComment = createAsyncThunk(
  'usecase/addusecaseComment',
  async (usecaseCommentPayload: AddUsecaseCommentType) => {
    const { data } = await usecaseAPI.addUsecaseCommentByCaseUid(usecaseCommentPayload);
    return data;
  }
);

export const updateusecaseComment = createAsyncThunk(
  'usecase/updateusecaseComment',
  async (usecaseCommentPayload: AddUsecaseCommentType) => {
    const { data } = await usecaseAPI.updateUsecaseLevelCommentDetails(usecaseCommentPayload);
    return data;
  }
);

export const deleteusecaseComment = createAsyncThunk('usecase/deleteusecaseComment', async (comment_uid: string) => {
  const { data } = await usecaseAPI.deleteUsecaseLevelComment({ comment_uid });
  return data;
});

const usecaseSlice = createSlice({
  name: 'usecaseSlice',
  initialState,
  reducers: {
    resetGetUsecaseComments: (state) => {
      state.allUsecaseComments = initialState.allUsecaseComments;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(addUsecaseInformation.pending, (state) => {
        state.addUsecaseInformationStatus = requestState.loading;
      })
      .addCase(addUsecaseInformation.fulfilled, (state) => {
        state.addUsecaseInformationStatus = requestState.success;
      })
      .addCase(addUsecaseInformation.rejected, (state, action) => {
        state.addUsecaseInformationError = action.error.message;
      })

      .addCase(getAllUsecaseCustomFields.pending, (state) => {
        state.getUsecaseCustomFieldsStatus = requestState.loading;
      })
      .addCase(getAllUsecaseCustomFields.fulfilled, (state, action) => {
        state.getUsecaseCustomFieldsStatus = requestState.success;
        console.log(action);

        state.usecaseCustomFields = [...action.payload];
      })
      .addCase(getAllUsecaseCustomFields.rejected, (state, action) => {
        state.getUsecaseCustomFieldsStatus = requestState.failed;
        state.getUsecaseCustomFieldsError = action.error.message;
      })

      // custom foedls detail
      .addCase(getAllUsecaseCustomFieldsWithValue.pending, (state) => {
        state.getUsecaseCustomFieldsWithValuesStatus = requestState.loading;
      })
      .addCase(getAllUsecaseCustomFieldsWithValue.fulfilled, (state, action) => {
        state.getUsecaseCustomFieldsWithValuesStatus = requestState.success;
        console.log(action);

        state.usecaseCustomFieldsWithValues = [...action.payload];
      })
      .addCase(getAllUsecaseCustomFieldsWithValue.rejected, (state, action) => {
        state.getUsecaseCustomFieldsWithValuesStatus = requestState.failed;
        state.getUsecaseCustomFieldsWithValuesError = action.error.message;
      })

      // get uc ausits
      .addCase(getUsecaseAudit.pending, (state) => {
        state.getUsecaseAuditStatus = requestState.loading;
      })
      .addCase(getUsecaseAudit.fulfilled, (state, action: any) => {
        state.getUsecaseAuditStatus = requestState.success;
        if (action.payload) {
          state.getUsecaseAudit = [...action.payload];
        } else {
          state.getUsecaseAudit = [];
        }
      })
      .addCase(getUsecaseAudit.rejected, (state, action) => {
        state.getUsecaseAuditError = action.error.message;
      })
      // updateUsecaseCustomField
      .addCase(updateUsecaseCustomField.pending, (state) => {
        state.usecaseCustomFieldUpdateStatus = requestState.loading;
      })
      .addCase(updateUsecaseCustomField.fulfilled, (state) => {
        state.usecaseCustomFieldUpdateStatus = requestState.success;
      })
      .addCase(updateUsecaseCustomField.rejected, (state) => {
        state.usecaseCustomFieldUpdateStatus = requestState.failed;
      })
      // getClientInformation
      .addCase(getClientInformation.pending, (state) => {
        state.clientInformation.status = requestState.loading;
      })
      .addCase(getClientInformation.fulfilled, (state, action: PayloadAction<any>) => {
        state.clientInformation.status = requestState.success;
        state.clientInformation.data = action.payload;
      })
      .addCase(getClientInformation.rejected, (state) => {
        state.clientInformation.status = requestState.failed;
      })
      // getUsecaseByUid
      .addCase(getUsecaseByUid.pending, (state) => {
        state.getUsecaseInfoStatus = requestState.loading;
      })
      .addCase(getUsecaseByUid.fulfilled, (state, action: PayloadAction<any>) => {
        state.getUsecaseInfoStatus = requestState.success;
        state.getUsecaseInfo = action.payload[0];
      })
      .addCase(getUsecaseByUid.rejected, (state) => {
        state.getUsecaseInfoStatus = requestState.failed;
      })
      .addCase(getAllCustomFieldsOfAllUsecases.pending, (state) => {
        state.allUsecaseCustomFields.status = requestState.loading;
      })
      .addCase(getAllCustomFieldsOfAllUsecases.fulfilled, (state, action: any) => {
        state.allUsecaseCustomFields.status = requestState.success;
        if (action.payload) {
          state.allUsecaseCustomFields.data = action.payload.result;
        } else {
          state.allUsecaseCustomFields.data = [];
        }
      })
      .addCase(getAllCustomFieldsOfAllUsecases.rejected, (state, action) => {
        state.allUsecaseCustomFields.error = action.error.message;
      })
      .addCase(getAllUsecaseComments.pending, (state) => {
        state.allUsecaseComments.status = requestState.loading;
      })
      .addCase(getAllUsecaseComments.fulfilled, (state, action: any) => {
        state.allUsecaseComments.status = requestState.success;
        if (!isNull(action.payload.result)) {
          state.allUsecaseComments.data = action.payload.result;
        } else {
          state.allUsecaseComments.data = [];
        }
      })
      .addCase(getAllUsecaseComments.rejected, (state, action) => {
        state.allUsecaseComments.message = action.error.message;
      });
  },
});

export const usecaseReducer = usecaseSlice.reducer;
export const { resetGetUsecaseComments } = usecaseSlice.actions;
export default usecaseReducer;
