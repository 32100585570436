import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { label_task } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { getCustomerUid } from 'utils/getUid';
import { taskAPI } from '../../../api/modules/tasks';
import { requestState } from '../types';
import { AddTaskCommentToJira, SyncSingleTask, DeleteCommentFromJiraType } from './types';

interface tasksType {
  addProjectPhaseStatus: requestState;
  addProjectPhaseStatusError: null | undefined | string;

  cloneProjectPhaseStatus: requestState;
  cloneProjectPhaseStatusError: null | undefined | string;

  cloneUsecasePhaseStatus: requestState;
  cloneUsecasePhaseStatusError: null | undefined | string;

  updatePhaseStatus: requestState;
  updatePhaseStatusError: null | undefined | string;

  getJiraPriority: any;
  getJiraPriorityStatus: requestState;
  getJiraPriorityError: null | undefined | string;

  getJiraProjects: Array<any>;
  getJiraProjectsStatus: requestState;
  getJiraProjecstError: null | undefined | string;

  addProjectTaskStatus: requestState;
  addProjectTaskError: null | undefined | string;

  addUsecaseTaskStatus: requestState;
  addUsecaseTaskError: null | undefined | string;

  getAllTasksUnderProject: Array<any>;
  getAllTasksUnderProjectStatus: requestState;
  getAllTasksUnderProjectError: null | undefined | string;

  getProjectSubtasks: Array<any>;
  getProjectSubtasksStatus: requestState;
  getProjectSubtasksError: null | undefined | string;

  addProjectSubtaskStatus: requestState;
  addProjectSubtaskError: null | undefined | string;

  getUsecaseSubtasks: Array<any>;
  getUsecaseSubtasksStatus: requestState;
  getUsecaseSubtasksError: null | undefined | string;

  addUsecaseSubtaskStatus: requestState;
  addUsecaseSubtaskError: null | undefined | string;

  getTaskCustomfieldsWithValue: Array<any>;
  getTaskCustomfieldsWithValueStatus: requestState;
  getTaskCustomfieldsWithValueError: null | undefined | string;

  getTaskCustomfields: Array<any>;
  getTaskCustomfieldsStatus: requestState;
  getTaskCustomfieldsError: null | undefined | string;

  updateProjectSubtaskStatus: requestState;
  updateProjectSubtaskError: null | undefined | string;

  deleteProjectTaskFromJiraByIssueIdStatus: requestState;
  deleteProjectTaskFromJiraByIssueIdError: null | undefined | string;

  deleteProjectSubtaskStatus: requestState;
  deleteProjectSubtaskError: null | undefined | string;

  addTaskDependencyStatus: requestState;
  addTaskDependencyError: null | undefined | string;

  getTaskDependency: Array<any>;
  getTaskDependencyStatus: requestState;
  getTaskDependencyError: null | undefined | string;

  getProjectTaskDependency: Array<any>;
  getProjectTaskDependencyStatus: requestState;
  getProjectTaskDependencyError: null | undefined | string;

  getTaskByTaskUid: any | null;
  getTaskByTaskUidStatus: requestState;
  getTaskByTaskUidError: null | undefined | string;

  getTasksOfUser: any | null;
  getTasksOfUserStatus: requestState;
  getTasksOfUserError: null | undefined | string;

  getTasksUnderUsecase: any | null;
  getTasksUnderUsecaseStatus: requestState;
  getTasksUnderUsecaseError: null | undefined | string;

  addProjectTaskCommentStatus: requestState;
  addProjectTaskCommentError: null | undefined | string;

  sendProjectTaskCommentEmailStatus: requestState;
  sendProjectTaskCommentEmailError: null | undefined | string;

  sendUsecaseTaskCommentEmailStatus: requestState;
  sendUsecaseTaskCommentEmailError: null | undefined | string;

  updateProjectTaskCommentStatus: requestState;
  updateProjectTaskCommentError: null | undefined | string;

  addProjectClientTaskCommentStatus: requestState;
  addProjectClientTaskCommentError: null | undefined | string;

  addUsecaseTaskCommentStatus: requestState;
  addUsecaseTaskCommentError: null | undefined | string;

  updateUsecaseTaskCommentStatus: requestState;
  updateUsecaseTaskCommentError: null | undefined | string;

  addUsecaseClientTaskCommentStatus: requestState;
  addUsecaseClientTaskCommentError: null | undefined | string;

  getProjectTaskComments: Array<any>;
  getProjectTaskCommentsStatus: requestState;
  getProjectTaskCommentsError: null | undefined | string;

  getProjectClientTaskComments: Array<any>;
  getProjectClientTaskCommentsStatus: requestState;
  getProjectClientTaskCommentsError: null | undefined | string;

  getUsecaseTaskComments: Array<any>;
  getUsecaseTaskCommentsStatus: requestState;
  getUsecaseTaskCommentsError: null | undefined | string;

  getUsecaseClientTaskComments: Array<any>;
  getUsecaseClientTaskCommentsStatus: requestState;
  getUsecaseClientTaskCommentsError: null | undefined | string;

  deleteTaskCommentStatus: requestState;
  deleteTaskCommentError: null | undefined | string;

  deleteClientTaskCommentStatus: requestState;
  deleteClientTaskCommentError: null | undefined | string;

  updateTaskStatus: requestState;
  updateTaskError: null | undefined | string;

  getJiraProjectIssueTypes: Array<any>;
  getJiraProjectIssueTypesStatus: requestState;
  getJiraProjectIssueTypesError: null | undefined | string;

  getProjectTaskTimeLog: Array<any>;
  getProjectTaskTimeLogStatus: requestState;
  getProjectTaskTimeLogError: null | undefined | string;

  getUsecaseTaskTimeLog: Array<any>;
  getUsecaseTaskTimeLogStatus: requestState;
  getUsecaseTaskTimeLogError: null | undefined | string;

  incrementProjectTaskSequenceStatus: requestState;
  incrementProjectTaskSequenceError: null | undefined | string;

  incrementCaseTaskSequenceStatus: requestState;
  incrementCaseTaskSequenceError: null | undefined | string;

  incrementTemplateProjectTaskSequenceStatus: requestState;
  incrementTemplateProjectTaskSequenceError: null | undefined | string;

  incrementTemplateCaseTaskSequenceStatus: requestState;
  incrementTemplateCaseTaskSequenceError: null | undefined | string;

  incrementProjectSectionSequenceStatus: requestState;
  incrementProjectSectionSequenceError: null | undefined | string;

  incrementCaseSectionSequenceStatus: requestState;
  incrementCaseSectionSequenceError: null | undefined | string;

  getAllEligibleTaskOwnerDetailsOfClient: Array<any>;
  getAllEligibleTaskOwnerDetailsOfClientStatus: requestState;
  getAllEligibleTaskOwnerDetailsOfClientError: null | undefined | string;

  taskBulkUpdateStatus: requestState;
  taskBulkUpdateError: null | undefined | string;

  bulkDeleteTaskStatus: requestState;
  bulkDeleteTaskError: null | undefined | string;

  deleteTaskFromTemporaryTableAndSendMailStatus: requestState;
  deleteTaskFromTemporaryTableAndSendMailError: null | undefined | string;

  taskBulkCsatMailStatus: requestState;
  taskBulkCsatMailError: null | undefined | string;

  updatingJiraTaskIssue: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };

  changeTaskStatusStatus: requestState;
  changeTaskStatusError: null | undefined | string;

  getTaskAuditTrail: any;
  getTaskAuditTrailStatus: requestState;
  getTaskAuditTrailError: null | undefined | string;

  getSubtaskAuditTrail: any;
  getSubtaskAuditTrailStatus: requestState;
  getSubtaskAuditTrailError: null | undefined | string;

  getProjectTaskAuditTrail: any;
  getProjectTaskAuditTrailStatus: requestState;
  getProjectTaskAuditTrailError: null | undefined | string;

  getProjectClientTaskAuditTrail: any;
  getProjectClientTaskAuditTrailStatus: requestState;
  getProjectClientTaskAuditTrailError: null | undefined | string;

  getClientTaskAuditTrail: any;
  getClientTaskAuditTrailStatus: requestState;
  getClientTaskAuditTrailError: null | undefined | string;

  // dataForEditTaskDrawer: {} | null;
  syncedJiraTasks: {
    data: any;
    status: requestState;
    error: null | undefined | string;
  };

  updatingTaskCustomFields: {
    status: requestState;
    message: string;
  };

  editTaskModal: {
    detail: any;
    comment: string;
    timeTracking: any;
    customFields: any;
    detailFormRef: any;
    customFieldsFormRef: any;
    initialFormState: any;
    rangeValue: any; // Range value for date range
  };
}

const initialState: tasksType = {
  editTaskModal: {
    detail: null,
    comment: '',
    timeTracking: null,
    customFields: null,
    detailFormRef: null,
    customFieldsFormRef: null,
    initialFormState: null,
    rangeValue: null,
  },

  addProjectPhaseStatus: requestState.idle,
  addProjectPhaseStatusError: null,

  cloneProjectPhaseStatus: requestState.idle,
  cloneProjectPhaseStatusError: null,

  cloneUsecasePhaseStatus: requestState.idle,
  cloneUsecasePhaseStatusError: null,

  updatePhaseStatus: requestState.idle,
  updatePhaseStatusError: null,

  getJiraPriority: [],
  getJiraPriorityStatus: requestState.idle,
  getJiraPriorityError: null,

  getJiraProjects: [],
  getJiraProjectsStatus: requestState.idle,
  getJiraProjecstError: null,

  addProjectTaskStatus: requestState.idle,
  addProjectTaskError: null,

  addUsecaseTaskStatus: requestState.idle,
  addUsecaseTaskError: null,

  getAllTasksUnderProject: [],
  getAllTasksUnderProjectStatus: requestState.idle,
  getAllTasksUnderProjectError: null,

  getProjectSubtasks: [],
  getProjectSubtasksStatus: requestState.idle,
  getProjectSubtasksError: null,

  addProjectSubtaskStatus: requestState.idle,
  addProjectSubtaskError: null,

  getUsecaseSubtasks: [],
  getUsecaseSubtasksStatus: requestState.idle,
  getUsecaseSubtasksError: null,

  addUsecaseSubtaskStatus: requestState.idle,
  addUsecaseSubtaskError: null,

  getTaskCustomfieldsWithValue: [],
  getTaskCustomfieldsWithValueStatus: requestState.idle,
  getTaskCustomfieldsWithValueError: null,

  getTaskCustomfields: [],
  getTaskCustomfieldsStatus: requestState.idle,
  getTaskCustomfieldsError: null,

  updateProjectSubtaskStatus: requestState.idle,
  updateProjectSubtaskError: null,

  deleteProjectTaskFromJiraByIssueIdStatus: requestState.idle,
  deleteProjectTaskFromJiraByIssueIdError: null,

  deleteProjectSubtaskStatus: requestState.idle,
  deleteProjectSubtaskError: null,

  addTaskDependencyStatus: requestState.idle,
  addTaskDependencyError: null,

  getTaskDependency: [],
  getTaskDependencyStatus: requestState.idle,
  getTaskDependencyError: null,

  getProjectTaskDependency: [],
  getProjectTaskDependencyStatus: requestState.idle,
  getProjectTaskDependencyError: null,

  getTaskByTaskUid: null,
  getTaskByTaskUidStatus: requestState.idle,
  getTaskByTaskUidError: null,

  getTasksOfUser: null,
  getTasksOfUserStatus: requestState.idle,
  getTasksOfUserError: null,

  getTasksUnderUsecase: null,
  getTasksUnderUsecaseStatus: requestState.idle,
  getTasksUnderUsecaseError: null,

  addProjectTaskCommentStatus: requestState.idle,
  addProjectTaskCommentError: null,

  sendProjectTaskCommentEmailStatus: requestState.idle,
  sendProjectTaskCommentEmailError: null,

  sendUsecaseTaskCommentEmailStatus: requestState.idle,
  sendUsecaseTaskCommentEmailError: null,

  updateProjectTaskCommentStatus: requestState.idle,
  updateProjectTaskCommentError: null,

  addProjectClientTaskCommentStatus: requestState.idle,
  addProjectClientTaskCommentError: null,

  addUsecaseTaskCommentStatus: requestState.idle,
  addUsecaseTaskCommentError: null,

  updateUsecaseTaskCommentStatus: requestState.idle,
  updateUsecaseTaskCommentError: null,

  addUsecaseClientTaskCommentStatus: requestState.idle,
  addUsecaseClientTaskCommentError: null,

  getProjectTaskComments: [],
  getProjectTaskCommentsStatus: requestState.idle,
  getProjectTaskCommentsError: null,

  getProjectClientTaskComments: [],
  getProjectClientTaskCommentsStatus: requestState.idle,
  getProjectClientTaskCommentsError: null,

  getUsecaseTaskComments: [],
  getUsecaseTaskCommentsStatus: requestState.idle,
  getUsecaseTaskCommentsError: null,

  getUsecaseClientTaskComments: [],
  getUsecaseClientTaskCommentsStatus: requestState.idle,
  getUsecaseClientTaskCommentsError: null,

  deleteTaskCommentStatus: requestState.idle,
  deleteTaskCommentError: null,

  deleteClientTaskCommentStatus: requestState.idle,
  deleteClientTaskCommentError: null,

  updateTaskStatus: requestState.idle,
  updateTaskError: null,

  getJiraProjectIssueTypes: [],
  getJiraProjectIssueTypesStatus: requestState.idle,
  getJiraProjectIssueTypesError: null,

  getProjectTaskTimeLog: [],
  getProjectTaskTimeLogStatus: requestState.idle,
  getProjectTaskTimeLogError: null,

  getUsecaseTaskTimeLog: [],
  getUsecaseTaskTimeLogStatus: requestState.idle,
  getUsecaseTaskTimeLogError: null,

  incrementProjectTaskSequenceStatus: requestState.idle,
  incrementProjectTaskSequenceError: null,

  incrementCaseTaskSequenceStatus: requestState.idle,
  incrementCaseTaskSequenceError: null,

  incrementTemplateProjectTaskSequenceStatus: requestState.idle,
  incrementTemplateProjectTaskSequenceError: null,

  incrementProjectSectionSequenceStatus: requestState.idle,
  incrementProjectSectionSequenceError: null,

  incrementCaseSectionSequenceStatus: requestState.idle,
  incrementCaseSectionSequenceError: null,

  incrementTemplateCaseTaskSequenceStatus: requestState.idle,
  incrementTemplateCaseTaskSequenceError: null,
  getAllEligibleTaskOwnerDetailsOfClient: [],
  getAllEligibleTaskOwnerDetailsOfClientStatus: requestState.idle,
  getAllEligibleTaskOwnerDetailsOfClientError: null,

  taskBulkUpdateStatus: requestState.idle,
  taskBulkUpdateError: null,

  bulkDeleteTaskStatus: requestState.idle,
  bulkDeleteTaskError: null,

  deleteTaskFromTemporaryTableAndSendMailStatus: requestState.idle,
  deleteTaskFromTemporaryTableAndSendMailError: null,

  taskBulkCsatMailStatus: requestState.idle,
  taskBulkCsatMailError: null,

  updatingJiraTaskIssue: {
    data: [],
    status: requestState.idle,
    error: null,
  },

  changeTaskStatusStatus: requestState.idle,
  changeTaskStatusError: null,

  getTaskAuditTrail: {},
  getTaskAuditTrailStatus: requestState.idle,
  getTaskAuditTrailError: null,

  getSubtaskAuditTrail: {},
  getSubtaskAuditTrailStatus: requestState.idle,
  getSubtaskAuditTrailError: null,

  getProjectTaskAuditTrail: {},
  getProjectTaskAuditTrailStatus: requestState.idle,
  getProjectTaskAuditTrailError: null,

  getProjectClientTaskAuditTrail: {},
  getProjectClientTaskAuditTrailStatus: requestState.idle,
  getProjectClientTaskAuditTrailError: null,

  getClientTaskAuditTrail: {},
  getClientTaskAuditTrailStatus: requestState.idle,
  getClientTaskAuditTrailError: null,

  // dataForEditTaskDrawer: null,
  syncedJiraTasks: {
    data: [],
    status: requestState.idle,
    error: null,
  },

  updatingTaskCustomFields: {
    status: requestState.idle,
    message: '',
  },
};

export const addProjectPhase = createAsyncThunk('tasks/addProjectPhase', async (phaseDetail: any) => {
  const { data } = await taskAPI.addProjectPhase(phaseDetail);
  return data.result;
});

export const cloneProjectPhase = createAsyncThunk('tasks/cloneProjectPhase', async (phaseDetail: any) => {
  const { data } = await taskAPI.cloneProjectPhase(phaseDetail);
  return data.result;
});

export const cloneUsecasePhase = createAsyncThunk('tasks/cloneUsecasePhase', async (phaseDetail: any) => {
  const { data } = await taskAPI.cloneUsecasePhase(phaseDetail);
  return data.result;
});

export const updatePhase = createAsyncThunk('tasks/updatePhase', async (phaseDetail: any) => {
  const { data } = await taskAPI.updatePhase(phaseDetail);
  return data.result;
});

export const addUsecasePhase = createAsyncThunk('tasks/addUsecasePhase', async (phaseDetail: any) => {
  const { data } = await taskAPI.addUsecasePhase(phaseDetail);
  return data.result;
});

export const getJiraPriorityList = createAsyncThunk('tasks/getJiraPriorityList', async (detail: any) => {
  const { data } = await taskAPI.getJiraPriorityList(detail);
  console.log(data);
  return data.result;
});

export const getJiraProjects = createAsyncThunk('tasks/getJiraProjects', async () => {
  const { data } = await taskAPI.getJiraProjects();
  console.log(data);
  return data.result ?? [];
});

export const addProjectTask = createAsyncThunk('task/addProjectTask', async (detail: any) => {
  const { data } = await taskAPI.addProjectTask(detail);
  console.log(data);
});

export const addUsecaseTask = createAsyncThunk('task/addUsecaseTask', async (detail: any) => {
  const { data } = await taskAPI.addUsecaseTask(detail);
  console.log(data);
});

export const getAllTasksUnderProject = createAsyncThunk('task/getProjectTask', async (project_uid: string) => {
  const { data } = await taskAPI.getTasksUnderProject(project_uid);
  console.log(data);
  return data.result;
});

export const getProjectSubtasks = createAsyncThunk('task/getProjectSubtask', async (task_uid: string) => {
  const { data } = await taskAPI.getProjectSubtasks(task_uid);
  return data.result ?? [];
});

export const addProjectSubtask = createAsyncThunk('task/addProjectSubtask', async (detail: any) => {
  const { data } = await taskAPI.addProjectSubtask(detail);
  return data.result;
});

export const getUsecaseSubtasks = createAsyncThunk('task/getUsecaseSubtask', async (task_uid: string) => {
  const { data } = await taskAPI.getUsecaseSubtasks(task_uid);
  return data.result ?? [];
});

export const addProjectSubtaskAuditTrail = createAsyncThunk('task/addProjectSubtaskAuditTrail', async (detail: any) => {
  await taskAPI.addProjectSubtaskAuditTrail(detail);
});

export const addUsecaseSubtask = createAsyncThunk('task/addUsecaseSubtask', async (detail: any) => {
  const { data } = await taskAPI.addUsecaseSubtask(detail);
  return data.result;
});

export const getTaskCustomfieldsWithValue = createAsyncThunk('task/getTaskCustomfields', async (task_uid: string) => {
  const { data } = await taskAPI.getTaskCustomfieldsWithValue(task_uid);
  return data.result ?? [];
});

export const getTaskCustomfields = createAsyncThunk('task/taskCustomfields', async () => {
  const { data } = await taskAPI.getTaskCustomfields();
  console.log(data);
  return data.result ?? [];
});

export const updateProjectSubtask = createAsyncThunk('task/updateProjectSubtask', async (detail: any) => {
  const { data } = await taskAPI.updateProjectSubtask(detail);
  return data.result;
});

export const updateUsecaseSubtask = createAsyncThunk('task/updateUsecaseSubtask', async (detail: any) => {
  const { data } = await taskAPI.updateUsecaseSubtask(detail);
  return data.result;
});

export const deleteProjectTaskFromJiraByIssueId = createAsyncThunk(
  'task/deleteProjectTaskFromJiraByIssueId',
  async (detail: any) => {
    const { data } = await taskAPI.deleteProjectTaskFromJiraByIssueId(detail);
    return data.result;
  }
);

export const deleteProjectSubtask = createAsyncThunk('task/deleteProjectSubtask', async (detail: any) => {
  const { data } = await taskAPI.deleteProjectSubtask(detail);
  return data.result;
});

export const deleteUsecaseSubtask = createAsyncThunk('task/deleteUsecaseSubtask', async (detail: any) => {
  const { data } = await taskAPI.deleteUsecaseSubtask(detail);
  return data.result;
});

export const addTaskDependency = createAsyncThunk('task/addTaskDependency', async (detail: any) => {
  const { data } = await taskAPI.addTaskDependency(detail);
  return data.result;
});

export const addProjectTaskDependency = createAsyncThunk('task/addProjectTaskDependency', async (detail: any) => {
  const { data } = await taskAPI.addProjectTaskDependency(detail);
  return data.result;
});

export const getTaskDependency = createAsyncThunk('task/getTaskDependency', async (detail: any) => {
  const { data } = await taskAPI.getTaskDependency(detail);
  return data.result;
});

export const getProjectTaskDependency = createAsyncThunk('task/getProjectTaskDependency', async (detail: any) => {
  const { data } = await taskAPI.getProjectTaskDependency(detail);
  return data.result;
});

export const deleteProjectTaskDependency = createAsyncThunk('task/deleteProjectTaskDependency', async (detail: any) => {
  const { data } = await taskAPI.deleteProjectTaskDependency(detail);
  return data.result;
});

export const deleteUsecaseTaskDependency = createAsyncThunk('task/deleteUsecaseTaskDependency', async (detail: any) => {
  const { data } = await taskAPI.deleteUsecaseTaskDependency(detail);
  return data.result;
});

export const getTaskByTaskUid = createAsyncThunk('task/getTaskByTaskUid', async (task_uid: string) => {
  const { data } = await taskAPI.getTaskByTaskUid(task_uid);
  return data.result;
});

export const getTasksOfUser = createAsyncThunk('task/getTasksOfUser', async (detail: any) => {
  const { data } = await taskAPI.getTasksOfUser(detail);
  return data.result;
});

export const addTaskAuditTrail = createAsyncThunk('task/addTaskAuditTrail', async (detail: any) => {
  await taskAPI.addTaskAuditTrail(detail);
});

export const addClientTaskAuditTrail = createAsyncThunk('task/addClientTaskAuditTrail', async (detail: any) => {
  await taskAPI.addClientTaskAuditTrail(detail);
});

export const addProjectTaskAuditTrial = createAsyncThunk('task/addProjectTaskAuditTrial', async (detail: any) => {
  await taskAPI.addProjectTaskAuditTrail(detail);
});

export const addProjectClientTaskAuditTrial = createAsyncThunk(
  'task/addProjectClientTaskAuditTrial',
  async (detail: any) => {
    await taskAPI.addProjectClientTaskAuditTrail(detail);
  }
);

export const addProjectTaskComment = createAsyncThunk('task/addProjectTaskComment', async (detail: any) => {
  const { data } = await taskAPI.addProjectTaskComment(detail);
  return data.result;
});

export const sendProjectTaskCommentEmail = createAsyncThunk('task/sendProjectTaskCommentEmail', async (detail: any) => {
  const { data } = await taskAPI.sendProjectTaskCommentEmail(detail);
  return data.result;
});

export const sendUsecaseTaskCommentEmail = createAsyncThunk('task/sendUsecaseTaskCommentEmail', async (detail: any) => {
  const { data } = await taskAPI.sendUsecaseTaskCommentEmail(detail);
  return data.result;
});

export const updateProjectTaskComment = createAsyncThunk('task/updateProjectTaskComment', async (detail: any) => {
  const { data } = await taskAPI.updateProjectTaskComment(detail);
  return data.result;
});

export const updateProjectClientTaskComment = createAsyncThunk('task/updateProjectTaskComment', async (detail: any) => {
  const { data } = await taskAPI.updateProjectClientTaskComment(detail);
  return data.result;
});

export const addProjectClientTaskComment = createAsyncThunk('task/addProjectClientTaskComment', async (detail: any) => {
  const { data } = await taskAPI.addProjectClientTaskComment(detail);
  return data.result;
});

export const addUsecaseTaskComment = createAsyncThunk('task/addUsecaseTaskComment', async (detail: any) => {
  const { data } = await taskAPI.addUsecaseTaskComment(detail);
  return data.result;
});

export const updateUsecaseTaskComment = createAsyncThunk('task/updateUsecaseTaskComment', async (detail: any) => {
  const { data } = await taskAPI.updateUsecaseTaskComment(detail);
  return data.result;
});

export const updateUsecaseClientTaskComment = createAsyncThunk(
  'task/updateUsecaseClientTaskComment',
  async (detail: any) => {
    const { data } = await taskAPI.updateUsecaseClientTaskComment(detail);
    return data.result;
  }
);

export const changeTaskStatus = createAsyncThunk('task/changeTaskStatus', async (detail: any) => {
  const { data } = await taskAPI.changeTaskStatus(detail);
  return data.result;
});

export const addUsecaseClientTaskComment = createAsyncThunk('task/addUsecaseClientTaskComment', async (detail: any) => {
  const { data } = await taskAPI.addUsecaseClientTaskComment(detail);
  return data.result;
});

export const getProjectTaskComments = createAsyncThunk('task/getProjectTaskComments', async (task_uid: string) => {
  const { data } = await taskAPI.getProjectTaskComments(task_uid);
  return data.result;
});

export const getProjectClientTaskComments = createAsyncThunk(
  'task/getProjectClientTaskComments',
  async (task_uid: string) => {
    const { data } = await taskAPI.getProjectClientTaskComments(task_uid);
    return data.result ?? [];
  }
);

export const getUsecaseTaskComments = createAsyncThunk('task/getUsecaseTaskComments', async (task_uid: string) => {
  const { data } = await taskAPI.getUsecaseTaskComments(task_uid);
  return data.result ?? [];
});

export const getUsecaseClientTaskComments = createAsyncThunk(
  'task/getUsecaseClientTaskComments',
  async (task_uid: string) => {
    const { data } = await taskAPI.getUsecaseClientTaskComments(task_uid);
    return data.result ?? [];
  }
);

export const deleteTaskComment = createAsyncThunk('task/deleteTaskComment', async (detail: any) => {
  const { data } = await taskAPI.deleteTaskComment(detail);
  return data.result;
});
export const markingPublicOrInternalTaskCommentSlice = createAsyncThunk('task/markingPublicOrInternalTaskComment', async (detail: any) => {
  const { data } = await taskAPI.markingPublicOrInternalTaskComment(detail);
  return data;
});

export const deleteClientTaskComment = createAsyncThunk('task/deleteClientTaskComment', async (detail: any) => {
  const { data } = await taskAPI.deleteClientTaskComment(detail);
  return data.result;
});

export const updateTask = createAsyncThunk('task/updateTask', async (detail: any) => {
  const { data } = await taskAPI.updateTask({ ...detail, customer_uid: getCustomerUid() });
  return data.result;
});

export const deleteProjectTask = createAsyncThunk('task/deleteProjectTask', async (detail: any) => {
  await taskAPI.deleteProjectTask(detail);
});

export const deleteProjectClientTask = createAsyncThunk('task/deleteProjectClientTask', async (detail: any) => {
  await taskAPI.deleteProjectClientTask(detail);
});

export const deleteUsecaseTask = createAsyncThunk('task/deleteUsecaseTask', async (detail: any) => {
  await taskAPI.deleteUsecaseTask(detail);
});

export const deleteUsecaseClientTask = createAsyncThunk('task/deleteUsecaseClientTask', async (detail: any) => {
  await taskAPI.deleteUsecaseClientTask(detail);
});

export const deleteProjectSection = createAsyncThunk('task/deleteProjectSection', async (detail: any) => {
  await taskAPI.deleteProjectSection(detail);
});

export const deleteUsecaseSection = createAsyncThunk('task/deleteUsecaseSection', async (detail: any) => {
  await taskAPI.deleteUsecaseSection(detail);
});

export const getJiraProjectIssueTypesList = createAsyncThunk('task/getJiraProjectIssueTypes', async (detail: any) => {
  const { data } = await taskAPI.getJiraProjectIssueTypes(detail);
  return data.result;
});

export const getProjectTaskTimeLog = createAsyncThunk('task/getProjectTaskTimelog', async (task_uid: any) => {
  const { data } = await taskAPI.getProjectTaskTimeLog(task_uid);
  return data.result;
});

export const upadateProjectTaskTimeLog = createAsyncThunk('task/updateProjectTaskTimelog', async (detail: any) => {
  const { data } = await taskAPI.updateProjectTaskTimeLog(detail);
  return data.status;
});

export const getUsecaseTaskTimeLog = createAsyncThunk('task/getUsecaseTaskTimelog', async (task_uid: any) => {
  const { data } = await taskAPI.getUsecaseTaskTimeLog(task_uid);
  return data.status;
});

export const upadateUsecaseTaskTimeLog = createAsyncThunk('task/updateUsecaseTaskTimelog', async (detail: any) => {
  const { data } = await taskAPI.updateUsecaseTaskTimeLog(detail);
  return data.result;
});

export const incrementProjectTaskSequence = createAsyncThunk(
  'task/incrementProjectTaskSequence',
  async (detail: any) => {
    const { project_uid, task_uid, task_sequence, section_uid } = detail;
    const { data } = await taskAPI.incrementProjectTaskSequence(project_uid, task_uid, task_sequence, section_uid);
    return data.result ?? null;
  }
);

export const incrementProjectSectionSequence = createAsyncThunk(
  'task/incrementProjectSectionSequence',
  async (detail: any) => {
    const { project_uid, section_sequence, section_uid } = detail;
    const { data } = await taskAPI.incrementProjectSectionSequence(project_uid, section_sequence, section_uid);
    return data.result ?? null;
  }
);

export const incrementCaseSectionSequence = createAsyncThunk(
  'task/incrementCaseSectionSequence',
  async (detail: any) => {
    const { case_uid, section_sequence, section_uid } = detail;
    const { data } = await taskAPI.incrementCaseSectionSequence(case_uid, section_sequence, section_uid);
    return data.result ?? null;
  }
);

export const incrementCaseTaskSequence = createAsyncThunk('task/incrementCaseTaskSequence', async (detail: any) => {
  const { project_uid, case_uid, task_uid, task_sequence, section_uid } = detail;
  const { data } = await taskAPI.incrementCaseTaskSequence(project_uid, case_uid, task_uid, task_sequence, section_uid);
  return data.result ?? null;
});

export const incrementTemplateProjectTaskSequence = createAsyncThunk(
  'task/incrementTemplateProjectTaskSequence',
  async (detail: any) => {
    const { template_project_uid, task_sequence, template_section_uid, template_task_uid } = detail;
    const { data } = await taskAPI.incrementTemplateProjectTaskSequence(
      template_project_uid,
      task_sequence,
      template_section_uid,
      template_task_uid
    );
    return data.result ?? null;
  }
);

export const incrementTemplateCaseTaskSequence = createAsyncThunk(
  'task/incrementTemplateCaseTaskSequence',
  async (detail: any) => {
    const { template_case_uid, task_sequence, template_section_uid, template_task_uid } = detail;
    const { data } = await taskAPI.incrementTemplateCaseTaskSequence(
      template_case_uid,
      task_sequence,
      template_section_uid,
      template_task_uid
    );
    return data.result ?? null;
  }
);
export const getAllEligibleTaskOwnerDetailsOfClient = createAsyncThunk(
  'task/getAllEligibleTaskOwnerDetailsOfClient',
  async (client_uid: string) => {
    const { data } = await taskAPI.getAllEligibleTaskOwnerDetailsOfClient(client_uid);
    console.log(data);
    return data.result;
  }
);

export const taskBulkUpdate = createAsyncThunk('task/taskBulkUpdate', async (detail: any) => {
  const { data } = await taskAPI.taskBulkUpdate(detail);
  console.log(data);
  return data.result;
});

export const bulkDeleteTask = createAsyncThunk('task/bulkDeleteTask', async (detail: any) => {
  const { data } = await taskAPI.bulkDeleteTask(detail);
  console.log(data);
  return data.result;
});

export const deleteTaskFromTemporaryTableAndSendMail = createAsyncThunk(
  'task/deleteTaskFromTemporaryTableAndSendMail',
  async (detail: any) => {
    const { data } = await taskAPI.deleteTaskFromTemporaryTableAndSendMail(detail);
    console.log(data);
    return data.result;
  }
);

export const taskBulkCsatMail = createAsyncThunk('task/taskBulkCsatMail', async (detail: any) => {
  const { data } = await taskAPI.taskBulkCsatMail(detail);
  console.log(data);
  return data.result;
});

export const getTaskAuditTrail = createAsyncThunk('task/getTaskAuditTrail', async (task_uid: string) => {
  const { data } = await taskAPI.getTaskAuditTrail(task_uid);
  return data.result ?? {};
});

export const getSubtaskAuditTrail = createAsyncThunk('task/getSubTaskAuditTrail', async (task_uid: string) => {
  const { data } = await taskAPI.getSubTaskAuditTrail(task_uid);
  return data.result ?? {};
});

export const getClientTaskAuditTrail = createAsyncThunk('task/getClientTaskAuditTrail', async (task_uid: string) => {
  const { data } = await taskAPI.getClientTaskAuditTrail(task_uid);
  return data.result ?? {};
});

export const getProjectTaskAuditTrail = createAsyncThunk('task/getProjectTaskAuditTrail', async (task_uid: string) => {
  const { data } = await taskAPI.getProjectTaskAuditTrail(task_uid);
  return data.result ?? {};
});

export const getProjectClientTaskAuditTrail = createAsyncThunk(
  'task/getProjectClientTaskAuditTrail',
  async (task_uid: string) => {
    const { data } = await taskAPI.getProjectClientTaskAuditTrail(task_uid);
    return data.result ?? {};
  }
);

export const updateUseCaseDependencyDueDate = createAsyncThunk(
  'task/updateUseCaseDependencyDueDate',
  async (detail: any) => {
    const { data } = await taskAPI.updateUseCaseDependencyDueDate(detail);
    return data.result ?? {};
  }
);

export const updateUseCaseDependencyDeliveryDate = createAsyncThunk(
  'task/updateUseCaseDependencyDeliveryDate',
  async (detail: any) => {
    const { data } = await taskAPI.updateUseCaseDependencyDeliveryDate(detail);
    return data.result ?? {};
  }
);

export const updateProjectDependencyDueDate = createAsyncThunk(
  'task/updateProjectDependencyDueDate',
  async (detail: any) => {
    const { data } = await taskAPI.updateProjectDependencyDueDate(detail);
    return data.result ?? {};
  }
);

export const updateProjectDependencyDeliveryDate = createAsyncThunk(
  'task/updateProjectDependencyDeliveryDate',
  async (detail: any) => {
    const { data } = await taskAPI.updateProjectDependencyDeliveryDate(detail);
    return data.result ?? {};
  }
);

interface syncProjectProps {
  key: string;
  customer_uid: string;
  task_uid: string;
  customDeliveryDate: string;
  task_stage: string;
}

export const syncThisProjectTaskToJira = createAsyncThunk(
  'task/syncThisProjectTaskToJira',
  async (detail: syncProjectProps) => {
    const { data } = await taskAPI.getTaskDependency(detail);
    return data.result;
  }
);

interface SyncJiraTasksPayload {
  key: string;
  customer_uid: string;
  client_uid: string;
  customDeliveryDate: string;
}

export const syncAllJiraTasks = createAsyncThunk('task/syncAllJiraTasks', async (detail: SyncJiraTasksPayload) => {
  const { data } = await taskAPI.syncJiraTask(detail);
  return data.result;
});

interface UpdatingJiraIssueForTaskPayload {
  key: string;
  customer_uid: string;
  id: string;
  summary: string;
  description: string;
  reporter: string;
  name: string;
  assignee: string;
  clientId: string;
  issueId: string;
  duedate: string;
  customClientName: string;
  client_name: string;
  customUseCaseName: string;
  case_name: string;
  customDeliveryDate: string;
  delivery_date: string;
  priority: string;
  task_stage: string;
  remainingEstimate: string;
  required_fields: string;
  formValues: string;
}

export const updateJiraIssueForTasks = createAsyncThunk(
  'task/updateJiraIssueForTasks',
  async (detail: UpdatingJiraIssueForTaskPayload) => {
    const { data } = await taskAPI.updateJiraIssueforTasks(detail);
    return data.result;
  }
);
export const updateTaskCustomField = createAsyncThunk('task/updateTaskCustomField', async (detail: any) => {
  await taskAPI.updateTaskCustomField(detail);
});

export const createTaskCommentToJira = createAsyncThunk(
  'task/createTaskCommentToJira',
  async (detail: AddTaskCommentToJira) => {
    const { data } = await taskAPI.addTaskCommentToJira(detail);
    return data.result;
  }
);

export const syncTaskFromJira = createAsyncThunk('task/syncTaskFromJira', async (detail: SyncSingleTask) => {
  const { data } = await taskAPI.syncTaskFromJira(detail);
  return data.result;
});

export const syncProjectTaskfromJira = createAsyncThunk(
  'task/syncProjectTaskfromJira',
  async (detail: SyncSingleTask) => {
    const { data } = await taskAPI.syncProjectTaskfromJira(detail);
    return data.result;
  }
);

//deleteCommentFromJira
export const deleteCommentFromJira = createAsyncThunk(
  'task/deleteCommentFromJira',
  async (detail: DeleteCommentFromJiraType) => {
    const { data } = await taskAPI.deleteCommentFromJira(detail);
    return data.result;
  }
);

const taskSlice = createSlice({
  name: 'taskSlice',
  initialState,
  reducers: {
    resetUpdatingTaskCustomField: (state) => {
      state.updatingTaskCustomFields = initialState.updatingTaskCustomFields;
    },
    updateTaskDetailModal: (state, action) => {
      state.editTaskModal.detail = { ...action.payload };
    },
    updateTaskDetailModalInitialState: (state, action) => {
      state.editTaskModal.initialFormState = { ...action.payload };
    },
    updateTaskCustomfields: (state, action) => {
      state.editTaskModal.customFields = { ...action.payload };
    },
    updateTaskTimeTracking: (state, action) => {
      state.editTaskModal.timeTracking = { ...action.payload };
    },
    updateTaskDetailFormRef: (state, action) => {
      state.editTaskModal.detailFormRef = { ...action.payload };
    },
    updateTaskCustomFieldFormRef: (state, action) => {
      state.editTaskModal.customFieldsFormRef = { ...action.payload };
    },
    clearTaskStore: (state) => {
      state.editTaskModal = initialState.editTaskModal;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(addProjectPhase.pending, (state) => {
        state.addProjectPhaseStatus = requestState.loading;
      })
      .addCase(addProjectPhase.fulfilled, (state) => {
        state.addProjectPhaseStatus = requestState.success;
      })
      .addCase(addProjectPhase.rejected, (state, action) => {
        state.addProjectPhaseStatusError = requestState.failed;
        state.addProjectPhaseStatusError = action.error.message;
      })

      .addCase(cloneProjectPhase.pending, (state) => {
        state.cloneProjectPhaseStatus = requestState.loading;
      })
      .addCase(cloneProjectPhase.fulfilled, (state) => {
        state.cloneProjectPhaseStatus = requestState.success;
      })
      .addCase(cloneProjectPhase.rejected, (state, action) => {
        state.cloneProjectPhaseStatusError = requestState.failed;
        state.cloneProjectPhaseStatusError = action.error.message;
      })

      .addCase(cloneUsecasePhase.pending, (state) => {
        state.cloneUsecasePhaseStatus = requestState.loading;
      })
      .addCase(cloneUsecasePhase.fulfilled, (state) => {
        state.cloneUsecasePhaseStatus = requestState.success;
      })
      .addCase(cloneUsecasePhase.rejected, (state, action) => {
        state.cloneUsecasePhaseStatusError = requestState.failed;
        state.cloneUsecasePhaseStatusError = action.error.message;
      })

      .addCase(updatePhase.pending, (state) => {
        state.updatePhaseStatus = requestState.loading;
      })
      .addCase(updatePhase.fulfilled, (state) => {
        state.updatePhaseStatus = requestState.success;
      })
      .addCase(updatePhase.rejected, (state, action) => {
        state.updatePhaseStatusError = requestState.failed;
        state.updatePhaseStatusError = action.error.message;
      })

      // GET JIRA PRIORITY LIST
      .addCase(getJiraPriorityList.pending, (state) => {
        state.addProjectPhaseStatus = requestState.loading;
      })
      .addCase(getJiraPriorityList.fulfilled, (state, action) => {
        state.addProjectPhaseStatus = requestState.success;

        const jiraPriorityFields = JSON.parse(action.payload);
        const tempArr: any = [];
        jiraPriorityFields.forEach((priority: any) => {
          const object: any = {};
          object['id'] = priority.id;
          object['name'] = priority.name;
          tempArr.push(object);
        });
        state.getJiraPriority = [...tempArr];
      })
      .addCase(getJiraPriorityList.rejected, (state, action) => {
        state.addProjectPhaseStatusError = requestState.failed;
        state.addProjectPhaseStatusError = action.error.message;
      })

      // Get jira projects
      .addCase(getJiraProjects.pending, (state) => {
        state.getJiraProjectsStatus = requestState.loading;
      })
      .addCase(getJiraProjects.fulfilled, (state, action) => {
        state.getJiraProjectsStatus = requestState.success;
        console.log('what is problem', action.payload);
        state.getJiraProjects = [...action.payload];
      })
      .addCase(getJiraProjects.rejected, (state, action) => {
        state.getJiraProjecstError = requestState.failed;
        state.getJiraProjecstError = action.error.message;
      })

      // GET ALL TASKS UNDER PROJECT
      .addCase(getAllTasksUnderProject.pending, (state) => {
        state.getAllTasksUnderProjectStatus = requestState.loading;
      })
      .addCase(getAllTasksUnderProject.fulfilled, (state, action) => {
        state.getAllTasksUnderProjectStatus = requestState.success;
        state.getAllTasksUnderProject = [...action.payload];
      })
      .addCase(getAllTasksUnderProject.rejected, (state, action) => {
        state.getAllTasksUnderProjectStatus = requestState.failed;
        state.getJiraProjecstError = action.error.message;
      })

      // Add Project Subtask
      .addCase(addProjectSubtask.pending, (state) => {
        state.addProjectSubtaskStatus = requestState.loading;
      })
      .addCase(addProjectSubtask.fulfilled, (state) => {
        state.addProjectSubtaskStatus = requestState.success;
        // state.addProjectSubtask = [...action.payload];
      })
      .addCase(addProjectSubtask.rejected, (state, action) => {
        state.addProjectSubtaskStatus = requestState.failed;
        state.addProjectSubtaskError = action.error.message;
      })

      // GET ALL PROJECT SUBTASKS
      .addCase(getUsecaseSubtasks.pending, (state) => {
        state.getUsecaseSubtasksStatus = requestState.loading;
      })
      .addCase(getUsecaseSubtasks.fulfilled, (state, action) => {
        state.getUsecaseSubtasksStatus = requestState.success;
        state.getUsecaseSubtasks = [...action.payload];
      })
      .addCase(getUsecaseSubtasks.rejected, (state, action) => {
        state.getUsecaseSubtasksStatus = requestState.failed;
        state.getUsecaseSubtasksError = action.error.message;
      })

      // Add Project Subtask
      .addCase(addUsecaseSubtask.pending, (state) => {
        state.addUsecaseSubtaskStatus = requestState.loading;
      })
      .addCase(addUsecaseSubtask.fulfilled, (state) => {
        state.addUsecaseSubtaskStatus = requestState.success;
        // state.addProjectSubtask = [...action.payload];
      })
      .addCase(addUsecaseSubtask.rejected, (state, action) => {
        state.addUsecaseSubtaskStatus = requestState.failed;
        state.addUsecaseSubtaskError = action.error.message;
      })

      // GET ALL PROJECT SUBTASKS
      .addCase(getProjectSubtasks.pending, (state) => {
        state.getProjectSubtasksStatus = requestState.loading;
      })
      .addCase(getProjectSubtasks.fulfilled, (state, action) => {
        state.getProjectSubtasksStatus = requestState.success;
        state.getProjectSubtasks = [...action.payload];
      })
      .addCase(getProjectSubtasks.rejected, (state, action) => {
        state.getProjectSubtasksStatus = requestState.failed;
        state.getProjectSubtasksError = action.error.message;
      })

      // GET TASK CUSTOM FIELDS WITH VALUE
      .addCase(getTaskCustomfieldsWithValue.pending, (state) => {
        state.getTaskCustomfieldsWithValueStatus = requestState.loading;
      })
      .addCase(getTaskCustomfieldsWithValue.fulfilled, (state, action) => {
        state.getTaskCustomfieldsWithValueStatus = requestState.success;
        state.getTaskCustomfieldsWithValue = [...action.payload];
      })
      .addCase(getTaskCustomfieldsWithValue.rejected, (state, action) => {
        state.getTaskCustomfieldsWithValueStatus = requestState.failed;
        state.getTaskCustomfieldsWithValueError = action.error.message;
      })

      // GET TASK CUSTOM FIELDS
      .addCase(getTaskCustomfields.pending, (state) => {
        state.getProjectSubtasksStatus = requestState.loading;
      })
      .addCase(getTaskCustomfields.fulfilled, (state, action) => {
        state.getTaskCustomfieldsStatus = requestState.success;
        state.getTaskCustomfields = [...action.payload];
      })
      .addCase(getTaskCustomfields.rejected, (state, action) => {
        state.getTaskCustomfieldsStatus = requestState.failed;
        state.getTaskCustomfieldsError = action.error.message;
      })

      // UPDATE PROJECT SUBTASK
      .addCase(updateProjectSubtask.pending, (state) => {
        state.updateProjectSubtaskStatus = requestState.loading;
      })
      .addCase(updateProjectSubtask.fulfilled, (state) => {
        state.updateProjectSubtaskStatus = requestState.success;
      })
      .addCase(updateProjectSubtask.rejected, (state, action) => {
        state.updateProjectSubtaskError = action.error.message;
      })

      // DELETE PROJECT TASK FROM JIRA BY ISSUE ID
      .addCase(deleteProjectTaskFromJiraByIssueId.pending, (state) => {
        state.deleteProjectTaskFromJiraByIssueIdStatus = requestState.loading;
      })
      .addCase(deleteProjectTaskFromJiraByIssueId.fulfilled, (state) => {
        state.deleteProjectTaskFromJiraByIssueIdStatus = requestState.success;
      })
      .addCase(deleteProjectTaskFromJiraByIssueId.rejected, (state, action) => {
        state.deleteProjectTaskFromJiraByIssueIdError = action.error.message;
      })

      // DELETE PROJECT SUBTASK
      .addCase(deleteProjectSubtask.pending, (state) => {
        state.deleteProjectSubtaskStatus = requestState.loading;
      })
      .addCase(deleteProjectSubtask.fulfilled, (state) => {
        state.deleteProjectSubtaskStatus = requestState.success;
      })
      .addCase(deleteProjectSubtask.rejected, (state, action) => {
        state.deleteProjectSubtaskError = action.error.message;
      })

      // add Task Dependency
      .addCase(addTaskDependency.pending, (state) => {
        state.addTaskDependencyStatus = requestState.loading;
      })
      .addCase(addTaskDependency.fulfilled, (state) => {
        state.addTaskDependencyStatus = requestState.success;
      })
      .addCase(addTaskDependency.rejected, (state, action) => {
        state.addTaskDependencyError = action.error.message;
      })

      // GET TASK DEPENDENCY
      .addCase(getTaskDependency.pending, (state) => {
        state.getTaskDependencyStatus = requestState.loading;
      })
      .addCase(getTaskDependency.fulfilled, (state, action) => {
        if (action.payload) {
          state.getTaskDependency = [...action.payload];
        } else {
          state.getTaskDependency = [];
        }
        state.getTaskDependencyStatus = requestState.success;
      })
      .addCase(getTaskDependency.rejected, (state, action) => {
        state.getTaskDependencyError = action.error.message;
      })

      // project taskdependency

      .addCase(getProjectTaskDependency.pending, (state) => {
        state.getProjectTaskDependencyStatus = requestState.loading;
      })
      .addCase(getProjectTaskDependency.fulfilled, (state, action) => {
        if (action.payload) {
          state.getProjectTaskDependency = [...action.payload];
        } else {
          state.getProjectTaskDependency = [];
        }
        state.getProjectTaskDependencyStatus = requestState.success;
      })
      .addCase(getProjectTaskDependency.rejected, (state, action) => {
        state.getProjectTaskDependencyError = action.error.message;
      })

      // GET TASK BY TASK UID
      .addCase(getTaskByTaskUid.pending, (state) => {
        state.getTaskByTaskUidStatus = requestState.loading;
      })
      .addCase(getTaskByTaskUid.fulfilled, (state, action) => {
        state.getTaskByTaskUid = action.payload;
        state.getTaskByTaskUidStatus = requestState.success;
      })
      .addCase(getTaskByTaskUid.rejected, (state, action) => {
        state.getTaskByTaskUidError = action.error.message;
      })

      // GET TASK BY USER
      .addCase(getTasksOfUser.pending, (state) => {
        state.getTasksOfUserStatus = requestState.loading;
      })
      .addCase(getTasksOfUser.fulfilled, (state, action) => {
        state.getTasksOfUser = [...action.payload];
        state.getTasksOfUserStatus = requestState.success;
      })
      .addCase(getTasksOfUser.rejected, (state, action) => {
        state.getTasksOfUserError = action.error.message;
      })

      // add project task comment
      .addCase(addProjectTaskComment.pending, (state) => {
        state.addProjectTaskCommentStatus = requestState.loading;
      })
      .addCase(addProjectTaskComment.fulfilled, (state) => {
        state.addProjectTaskCommentStatus = requestState.success;
      })
      .addCase(addProjectTaskComment.rejected, (state, action) => {
        state.addProjectTaskCommentError = action.error.message;
      })

      .addCase(sendProjectTaskCommentEmail.pending, (state) => {
        state.sendProjectTaskCommentEmailStatus = requestState.loading;
      })
      .addCase(sendProjectTaskCommentEmail.fulfilled, (state) => {
        state.sendProjectTaskCommentEmailStatus = requestState.success;
      })
      .addCase(sendProjectTaskCommentEmail.rejected, (state, action) => {
        state.sendProjectTaskCommentEmailError = action.error.message;
      })

      .addCase(sendUsecaseTaskCommentEmail.pending, (state) => {
        state.sendUsecaseTaskCommentEmailStatus = requestState.loading;
      })
      .addCase(sendUsecaseTaskCommentEmail.fulfilled, (state) => {
        state.sendUsecaseTaskCommentEmailStatus = requestState.success;
      })
      .addCase(sendUsecaseTaskCommentEmail.rejected, (state, action) => {
        state.sendUsecaseTaskCommentEmailError = action.error.message;
      })

      // update project task comment
      .addCase(updateProjectTaskComment.pending, (state) => {
        state.updateProjectTaskCommentStatus = requestState.loading;
      })
      .addCase(updateProjectTaskComment.fulfilled, (state) => {
        state.updateProjectTaskCommentStatus = requestState.success;
      })
      .addCase(updateProjectTaskComment.rejected, (state, action) => {
        state.updateProjectTaskCommentError = action.error.message;
      })

      // add Project client task comment
      .addCase(addProjectClientTaskComment.fulfilled, (state) => {
        state.addProjectClientTaskCommentStatus = requestState.success;
      })
      .addCase(addProjectClientTaskComment.rejected, (state, action) => {
        state.addProjectClientTaskCommentError = action.error.message;
      })

      // add usecase task comment
      .addCase(addUsecaseTaskComment.fulfilled, (state) => {
        state.addUsecaseTaskCommentStatus = requestState.success;
      })
      .addCase(addUsecaseTaskComment.rejected, (state, action) => {
        state.addUsecaseTaskCommentError = action.error.message;
      })

      // update usecase task comment
      .addCase(updateUsecaseTaskComment.pending, (state) => {
        state.updateUsecaseTaskCommentStatus = requestState.loading;
      })
      .addCase(updateUsecaseTaskComment.fulfilled, (state) => {
        state.updateUsecaseTaskCommentStatus = requestState.success;
      })
      .addCase(updateUsecaseTaskComment.rejected, (state, action) => {
        state.updateUsecaseTaskCommentError = action.error.message;
      })

      // add Usecase client taslk coment
      .addCase(addUsecaseClientTaskComment.fulfilled, (state) => {
        state.addUsecaseClientTaskCommentStatus = requestState.success;
      })
      .addCase(addUsecaseClientTaskComment.rejected, (state, action) => {
        state.addUsecaseClientTaskCommentError = action.error.message;
      })

      // getProject task comments

      .addCase(getProjectTaskComments.pending, (state) => {
        state.getProjectTaskCommentsStatus = requestState.loading;
      })
      .addCase(getProjectTaskComments.fulfilled, (state, action) => {
        state.getProjectTaskCommentsStatus = requestState.success;
        if (action.payload) {
          state.getProjectTaskComments = [...action.payload];
        } else {
          state.getProjectTaskComments = [];
        }
      })
      .addCase(getProjectTaskComments.rejected, (state, action) => {
        state.getProjectTaskCommentsStatus = requestState.failed;
        state.getProjectTaskCommentsError = action.error.message;
      })

      // get Project CLient task comments
      .addCase(getProjectClientTaskComments.pending, (state) => {
        state.getProjectClientTaskCommentsStatus = requestState.loading;
      })
      .addCase(getProjectClientTaskComments.fulfilled, (state, action) => {
        state.getProjectClientTaskCommentsStatus = requestState.success;
        state.getProjectClientTaskComments = [...action.payload];
      })
      .addCase(getProjectClientTaskComments.rejected, (state, action) => {
        state.getProjectClientTaskCommentsStatus = requestState.failed;
        state.getProjectClientTaskCommentsError = action.error.message;
      })

      // get uc task comments
      .addCase(getUsecaseTaskComments.pending, (state) => {
        state.getUsecaseTaskCommentsStatus = requestState.loading;
      })
      .addCase(getUsecaseTaskComments.fulfilled, (state, action) => {
        state.getUsecaseTaskCommentsStatus = requestState.success;
        state.getUsecaseTaskComments = [...action.payload];
      })
      .addCase(getUsecaseTaskComments.rejected, (state, action) => {
        state.getUsecaseTaskCommentsStatus = requestState.failed;
        state.getUsecaseTaskCommentsError = action.error.message;
      })

      // get uc client task
      .addCase(getUsecaseClientTaskComments.pending, (state) => {
        state.getUsecaseClientTaskCommentsStatus = requestState.loading;
      })
      .addCase(getUsecaseClientTaskComments.fulfilled, (state, action) => {
        state.getUsecaseClientTaskCommentsStatus = requestState.success;
        state.getUsecaseClientTaskComments = [...action.payload];
      })
      .addCase(getUsecaseClientTaskComments.rejected, (state, action) => {
        state.getUsecaseClientTaskCommentsStatus = requestState.failed;
        state.getUsecaseClientTaskCommentsError = action.error.message;
      })

      // increment project task sequence
      .addCase(incrementProjectTaskSequence.pending, (state) => {
        state.incrementProjectTaskSequenceStatus = requestState.loading;
      })
      .addCase(incrementProjectTaskSequence.fulfilled, (state) => {
        state.incrementProjectTaskSequenceStatus = requestState.success;
      })
      .addCase(incrementProjectTaskSequence.rejected, (state, action) => {
        state.incrementProjectTaskSequenceError = action.error.message;
      })
      .addCase(incrementProjectSectionSequence.pending, (state) => {
        state.incrementProjectSectionSequenceStatus = requestState.loading;
      })
      .addCase(incrementProjectSectionSequence.fulfilled, (state) => {
        state.incrementProjectSectionSequenceStatus = requestState.success;
      })
      .addCase(incrementProjectSectionSequence.rejected, (state, action) => {
        state.incrementProjectSectionSequenceError = action.error.message;
      })

      .addCase(incrementCaseSectionSequence.pending, (state) => {
        state.incrementCaseSectionSequenceStatus = requestState.loading;
      })
      .addCase(incrementCaseSectionSequence.fulfilled, (state) => {
        state.incrementCaseSectionSequenceStatus = requestState.success;
      })
      .addCase(incrementCaseSectionSequence.rejected, (state, action) => {
        state.incrementCaseSectionSequenceError = action.error.message;
      })

      .addCase(incrementCaseTaskSequence.pending, (state) => {
        state.incrementCaseTaskSequenceStatus = requestState.loading;
      })
      .addCase(incrementCaseTaskSequence.fulfilled, (state) => {
        state.incrementCaseTaskSequenceStatus = requestState.success;
      })
      .addCase(incrementCaseTaskSequence.rejected, (state, action) => {
        state.incrementCaseTaskSequenceError = action.error.message;
      })

      .addCase(incrementTemplateProjectTaskSequence.pending, (state) => {
        state.incrementTemplateProjectTaskSequenceStatus = requestState.loading;
      })
      .addCase(incrementTemplateProjectTaskSequence.fulfilled, (state) => {
        state.incrementTemplateProjectTaskSequenceStatus = requestState.success;
      })
      .addCase(incrementTemplateProjectTaskSequence.rejected, (state, action) => {
        state.incrementTemplateProjectTaskSequenceError = action.error.message;
      })

      .addCase(incrementTemplateCaseTaskSequence.pending, (state) => {
        state.incrementTemplateCaseTaskSequenceStatus = requestState.loading;
      })
      .addCase(incrementTemplateCaseTaskSequence.fulfilled, (state) => {
        state.incrementTemplateCaseTaskSequenceStatus = requestState.success;
      })
      .addCase(incrementTemplateCaseTaskSequence.rejected, (state, action) => {
        state.incrementTemplateCaseTaskSequenceError = action.error.message;
      })

      // delete task comment
      .addCase(deleteTaskComment.fulfilled, (state) => {
        state.deleteTaskCommentStatus = requestState.success;
      })
      .addCase(deleteTaskComment.rejected, (state, action) => {
        state.deleteTaskCommentError = action.error.message;
      })

      // delete client task comment
      .addCase(deleteClientTaskComment.fulfilled, (state) => {
        state.deleteClientTaskCommentStatus = requestState.success;
      })
      .addCase(deleteClientTaskComment.rejected, (state, action) => {
        state.deleteClientTaskCommentError = action.error.message;
      })

      // get project task time log
      .addCase(getUsecaseTaskTimeLog.pending, (state) => {
        state.getUsecaseClientTaskCommentsStatus = requestState.loading;
      })
      .addCase(getUsecaseTaskTimeLog.fulfilled, (state, action) => {
        state.getUsecaseTaskTimeLogStatus = requestState.success;
        if (action.payload) {
          state.getUsecaseTaskTimeLog = [...action.payload];
        } else {
          state.getUsecaseTaskTimeLog = [];
        }
      })
      .addCase(getUsecaseTaskTimeLog.rejected, (state, action) => {
        state.getUsecaseTaskTimeLogError = action.error.message;
      })

      // usecase task time log

      .addCase(getProjectTaskTimeLog.pending, (state) => {
        state.getProjectClientTaskCommentsStatus = requestState.loading;
      })
      .addCase(getProjectTaskTimeLog.fulfilled, (state, action) => {
        state.getProjectTaskTimeLogStatus = requestState.success;
        if (action.payload) {
          state.getProjectTaskTimeLog = [...action.payload];
        } else {
          state.getProjectTaskTimeLog = [];
        }
      })
      .addCase(getProjectTaskTimeLog.rejected, (state, action) => {
        state.getProjectTaskTimeLogError = action.error.message;
      })

      // get task Project Issue tytpes
      .addCase(getJiraProjectIssueTypesList.pending, (state) => {
        state.getJiraProjectIssueTypesStatus = requestState.loading;
      })
      .addCase(getJiraProjectIssueTypesList.fulfilled, (state, action) => {
        state.getJiraProjectIssueTypesStatus = requestState.success;
        if (action.payload) {
          console.log(JSON.parse(action.payload));
          const temp = JSON.parse(action.payload);
          console.log(temp.issueTypes);

          const issues = [...temp.issueTypes];
          state.getJiraProjectIssueTypes = [...issues];
        }
      })
      .addCase(getJiraProjectIssueTypesList.rejected, (state, action) => {
        state.getJiraProjectIssueTypesError = action.error.message;
      })

      // getAllEligibleTaskOwnerDetailsOfClient

      .addCase(getAllEligibleTaskOwnerDetailsOfClient.pending, (state) => {
        state.getAllEligibleTaskOwnerDetailsOfClientStatus = requestState.loading;
      })
      .addCase(getAllEligibleTaskOwnerDetailsOfClient.fulfilled, (state, action) => {
        state.getAllEligibleTaskOwnerDetailsOfClientStatus = requestState.success;
        if (action.payload) {
          state.getAllEligibleTaskOwnerDetailsOfClient = [...action.payload];
        } else {
          state.getAllEligibleTaskOwnerDetailsOfClient = [];
        }
      })
      .addCase(getAllEligibleTaskOwnerDetailsOfClient.rejected, (state, action) => {
        state.getAllEligibleTaskOwnerDetailsOfClientError = action.error.message;
      })

      // task bulk update

      .addCase(taskBulkUpdate.pending, (state) => {
        state.taskBulkUpdateStatus = requestState.loading;
      })
      .addCase(taskBulkUpdate.fulfilled, (state) => {
        state.taskBulkUpdateStatus = requestState.success;
      })
      .addCase(taskBulkUpdate.rejected, (state, action) => {
        state.taskBulkUpdateError = action.error.message;
      })

      // task bulk delete

      .addCase(bulkDeleteTask.pending, (state) => {
        state.bulkDeleteTaskStatus = requestState.loading;
      })
      .addCase(bulkDeleteTask.fulfilled, (state) => {
        state.bulkDeleteTaskStatus = requestState.success;
      })
      .addCase(bulkDeleteTask.rejected, (state, action) => {
        state.bulkDeleteTaskError = action.error.message;
      })

      // task bulk delete task from temporary table and send mail

      .addCase(deleteTaskFromTemporaryTableAndSendMail.pending, (state) => {
        state.deleteTaskFromTemporaryTableAndSendMailStatus = requestState.loading;
      })
      .addCase(deleteTaskFromTemporaryTableAndSendMail.fulfilled, (state) => {
        state.deleteTaskFromTemporaryTableAndSendMailStatus = requestState.success;
      })
      .addCase(deleteTaskFromTemporaryTableAndSendMail.rejected, (state, action) => {
        state.deleteTaskFromTemporaryTableAndSendMailError = action.error.message;
      })

      // task bulk csat mail

      .addCase(taskBulkCsatMail.pending, (state) => {
        state.taskBulkCsatMailStatus = requestState.loading;
      })
      .addCase(taskBulkCsatMail.fulfilled, (state) => {
        state.taskBulkCsatMailStatus = requestState.success;
      })
      .addCase(taskBulkCsatMail.rejected, (state, action) => {
        state.taskBulkCsatMailError = action.error.message;
      })

      // updateJiraIssueForTasks
      .addCase(updateJiraIssueForTasks.pending, (state) => {
        state.updatingJiraTaskIssue.status = requestState.loading;
      })
      .addCase(updateJiraIssueForTasks.fulfilled, (state, action) => {
        state.updatingJiraTaskIssue.status = requestState.success;
        state.updatingJiraTaskIssue.data = action.payload ?? [];
      })
      .addCase(updateJiraIssueForTasks.rejected, (state, action) => {
        state.updatingJiraTaskIssue.error = action.error.message;
      })

      // task bulk csat mail

      .addCase(changeTaskStatus.pending, (state) => {
        state.changeTaskStatusStatus = requestState.loading;
      })
      .addCase(changeTaskStatus.fulfilled, (state) => {
        state.changeTaskStatusStatus = requestState.success;
      })
      .addCase(changeTaskStatus.rejected, (state, action) => {
        state.changeTaskStatusError = action.error.message;
      })

      // task audit trail

      .addCase(getTaskAuditTrail.pending, (state) => {
        state.getTaskAuditTrailStatus = requestState.loading;
      })
      .addCase(getTaskAuditTrail.fulfilled, (state, action) => {
        state.getTaskAuditTrailStatus = requestState.success;
        state.getTaskAuditTrail = action.payload;
      })
      .addCase(getTaskAuditTrail.rejected, (state, action) => {
        state.getTaskAuditTrailError = action.error.message;
      })
      .addCase(getSubtaskAuditTrail.pending, (state) => {
        state.getSubtaskAuditTrailStatus = requestState.loading;
      })
      .addCase(getSubtaskAuditTrail.fulfilled, (state, action) => {
        state.getSubtaskAuditTrailStatus = requestState.success;
        state.getSubtaskAuditTrail = action.payload;
      })
      .addCase(getSubtaskAuditTrail.rejected, (state, action) => {
        state.getSubtaskAuditTrailError = action.error.message;
      })
      .addCase(getClientTaskAuditTrail.pending, (state) => {
        state.getClientTaskAuditTrailStatus = requestState.loading;
      })
      .addCase(getClientTaskAuditTrail.fulfilled, (state, action) => {
        state.getClientTaskAuditTrailStatus = requestState.success;
        state.getClientTaskAuditTrail = action.payload;
      })
      .addCase(getClientTaskAuditTrail.rejected, (state, action) => {
        state.getClientTaskAuditTrailError = action.error.message;
      })
      .addCase(getProjectTaskAuditTrail.pending, (state) => {
        state.getProjectTaskAuditTrailStatus = requestState.loading;
      })
      .addCase(getProjectTaskAuditTrail.fulfilled, (state, action) => {
        console.log('FUFFLEEDDING');
        console.log(action.payload);
        state.getProjectTaskAuditTrailStatus = requestState.success;
        state.getProjectTaskAuditTrail = { ...action.payload };
      })
      .addCase(getProjectTaskAuditTrail.rejected, (state, action) => {
        state.getTaskAuditTrailError = action.error.message;
      })
      .addCase(getProjectClientTaskAuditTrail.pending, (state) => {
        state.getProjectClientTaskAuditTrailStatus = requestState.loading;
      })
      .addCase(getProjectClientTaskAuditTrail.fulfilled, (state, action) => {
        state.getProjectClientTaskAuditTrailStatus = requestState.success;
        state.getProjectClientTaskAuditTrail = action.payload;
      })
      .addCase(getProjectClientTaskAuditTrail.rejected, (state, action) => {
        state.getProjectClientTaskAuditTrailError = action.error.message;
      })
      //syncAllJiraTasks
      .addCase(syncAllJiraTasks.pending, (state) => {
        state.syncedJiraTasks.status = requestState.loading;
      })
      .addCase(syncAllJiraTasks.fulfilled, (state, action) => {
        state.syncedJiraTasks.status = requestState.success;
        state.syncedJiraTasks.data = action.payload;
      })
      .addCase(syncAllJiraTasks.rejected, (state, action) => {
        state.syncedJiraTasks.status = requestState.failed;
        state.syncedJiraTasks.error = action.error.message;
      })
      // updateTaskCustomField
      .addCase(updateTaskCustomField.pending, (state) => {
        state.updatingTaskCustomFields.status = requestState.loading;
      })
      .addCase(updateTaskCustomField.fulfilled, (state) => {
        state.updatingTaskCustomFields.status = requestState.success;
        state.updatingTaskCustomFields.message = `Custom field for ${label_task.toLowerCase()} updated successfully!`;
      })
      .addCase(updateTaskCustomField.rejected, (state) => {
        state.updatingTaskCustomFields.status = requestState.failed;
        state.updatingTaskCustomFields.message = 'Could not update custom field';
      });
  },
});

export const tasksReducer = taskSlice.reducer;
export const {
  resetUpdatingTaskCustomField,
  updateTaskDetailModal,
  updateTaskDetailModalInitialState,
  updateTaskCustomfields,
  updateTaskTimeTracking,
  updateTaskDetailFormRef,
  updateTaskCustomFieldFormRef,
  clearTaskStore,
} = taskSlice.actions;

export default tasksReducer;
