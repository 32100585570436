import {
  Box,
  Grid,
  Typography,
  Stack,
  Button,
  Paper,
  Divider,
  Avatar,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import React, { useEffect } from 'react';
import DeliverablesTimeline from '../../../../components/ClientModule/DeliverablesTimeline/DeliverablesTimieline';
import { taskStage } from '../../../../components/shared/CognisaasTaskStageIndicator/CognisaasTaskStageIndicator';

import { ReactComponent as NoRecord } from '../../../../assets/svgs/no_records.svg';

import { CustomizedProgressBars } from '../../../../components/shared/CognisaasProgressBar/ProgressBar';
import { useLocation, useNavigate } from 'react-router-dom';
import { differenceInDays } from 'date-fns';
import UseCaseTable from './UseCaseTable';
import UpdateTask from './updateTask/updateTask';
import Cookies from 'universal-cookie';

import { useDispatch } from 'react-redux';
import {
  client_getsingleProjectInformationWithTaskClientUser,
  getAllUsers,
  client_getClientContactsByClientUid,
  client_getClientinformationapi,
  client_userSignIn_withjwttoken,
  client_updateTaskAcknowledgedStatus,
} from 'store/modules/CustomerPortal/CustomerPortal';
import {
  selectsignleProjectInformation,
  selectallCompanyUsers,
  selectallClientUsersUsers,
  selectUserObj,
} from 'store/modules/CustomerPortal/selector';
import { DataGridPremium, GridRenderCellParams, useGridApiRef } from '@mui/x-data-grid-premium';
import { styled } from '@mui/material/styles';
import CognisaasTaskStageIndicator from 'components/shared/CognisaasTaskStageIndicator/CognisaasTaskStageIndicator';
import { useAppSelector } from 'store/hooks';
import NewTaskDrawer from './addTask/addProjectTask';
import ViewDate from 'components/shared/ViewDate/ViewDate';
import { isNull, isUndefined } from 'utils/dataHelpers';
import {
  label_delivery_date,
  label_due_date,
  label_project,
  label_project_delivery_manager,
  label_task,
  label_use_case,
} from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { customerPortalGetUserNameByUserUid, getUserUid } from 'utils/getUid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckIcon from '@mui/icons-material/Check';
import useCustomNames from 'components/shared/CustomNames/useCustomNames';
import NAMED_CONSTATNTS from 'components/shared/CustomNames/Constants';
import { getUserActiveState, INACTIVE_STRING, INACTIVE_USER_COLOR } from 'utils/userHelpers';
import CustomerRaidDashboard from './CustomerRaidDashBoard';

const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  '& .ant-empty-img-1': {
    fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
  },
  '& .ant-empty-img-2': {
    fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
  },
  '& .ant-empty-img-3': {
    fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
  },
  '& .ant-empty-img-4': {
    fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
  },
  '& .ant-empty-img-5': {
    fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
    fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
  },
}));
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function stringToHslColor(str: string, s: number, l: number) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const h = hash % 360;
  return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToHslColor(name, 30, 80),
    },
    children: (
      <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#000000' }}>
        {name.split(' ')[0][0]}
        {name.indexOf(' ') >= 0 ? name.split(' ')[1][0] : null}
      </Typography>
    ),
  };
}

const CustomerProjectDashboard = () => {
  const cookies = new Cookies();
  const apiRef = useGridApiRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location: any = useLocation();
  const customerUid: any = cookies.get('cogni_customer_uid');
  const [client_uid, setclient_uid] = React.useState('');
  const customerCompanyName: any = cookies.get('customerCompanyName');
  const cognisassCustomerClientname: any = cookies.get('cogni_customer_client');
  const showAcknowledgeButton: any = cookies.get('task_acknowledgement');
  const [value, setValue] = React.useState(0);
  const [totalTasks, setTotalTasks] = React.useState([]);
  const [openTaskDrawer, setOpenTaskDrawer] = React.useState(false);
  const [openUpdateTaskDrawer, setOpenUpdateTaskDrawer] = React.useState(false);
  const [usersList, setUsersList] = React.useState([]);
  const [clientList, setClientList] = React.useState([]);
  const [singleProjectInformation, setSingleProjectInformation] = React.useState<any>({});
  const [selectedTask, setSelectedTask] = React.useState({});
  const [sectionsList, setSectionsList] = React.useState<any>([]);
  const [jwtToken, setJwtToken] = React.useState('');
  const [taskTypeOne, settaskTypeOne] = React.useState<any>([]);
  const [taskTypeTwo, settaskTypeTwo] = React.useState<any>([]);
  const [taskTypeThree, settaskTypeThree] = React.useState<any>([]);

  const singleProjectInformationApi: any = useAppSelector(selectsignleProjectInformation);
  const allUsersApi = useAppSelector(selectallCompanyUsers);
  const allClientUsersApi = useAppSelector(selectallClientUsersUsers);
  const useObjApi: any = useAppSelector(selectUserObj);

  const { getCustomNameMappedValue, loadCustomNamesModule } = useCustomNames();

  const updateAcknowledgedTaskStatus = async (task_uid: string) => {
    await dispatch(client_updateTaskAcknowledgedStatus({ task_uid: task_uid, task_acknowledged: '1' }));
    dispatch(client_getsingleProjectInformationWithTaskClientUser(location?.state?.selectedProject?.project_uid));
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  React.useEffect(() => {
    if (useObjApi?.client_uid) {
      setclient_uid(useObjApi?.client_uid);
      dispatch(client_getClientinformationapi(useObjApi && useObjApi?.client_uid));
    }
  }, [useObjApi]);

  React.useEffect(() => {
    const jwtTokenLocal: any = cookies.get('jwt-token');
    setJwtToken(jwtTokenLocal);
    dispatch(getAllUsers(customerUid));
  }, []);

  React.useEffect(() => {
    if (jwtToken?.length > 0) {
      dispatch(client_userSignIn_withjwttoken(jwtToken));
    }
  }, [jwtToken]);

  useEffect(() => {
    if (client_uid?.length > 0) dispatch(client_getClientContactsByClientUid(client_uid));
  }, [client_uid]);

  useEffect(() => {
    dispatch(client_getsingleProjectInformationWithTaskClientUser(location?.state?.selectedProject?.project_uid));
  }, [location]);

  useEffect(() => {
    if (customerUid?.length > 0) {
      dispatch(getAllUsers(customerUid));
      loadCustomNamesModule();
    }
  }, [customerUid]);

  useEffect(() => {
    if (client_uid?.length > 0) {
      dispatch(client_getClientContactsByClientUid(client_uid));
      dispatch(client_getClientinformationapi(client_uid));
    }
  }, [client_uid]);

  useEffect(() => {
    if (allUsersApi?.length > 0) {
      const tempArr: any = [];
      allUsersApi
        .filter((user: any) => user.is_active === '1')
        .map((ele: any) => {
          tempArr.push(ele.user_username);
        });
      tempArr.push('Not Assigned');
      setUsersList(tempArr);
    }
  }, [allUsersApi]);

  useEffect(() => {
    if (allClientUsersApi?.length > 0) {
      const tempArr: any = [];
      allClientUsersApi.map((ele: any) => {
        tempArr.push(ele.contact_name);
      });
      tempArr.push('Not Assigned');
      setClientList(tempArr);
    }
  }, [allClientUsersApi]);

  useEffect(() => {
    setSingleProjectInformation(singleProjectInformationApi);
    const tempArr: any = [];
    const tempArr1: any = [];
    const tempArr2: any = [];
    if (singleProjectInformationApi?.task?.length > 0) {
      singleProjectInformationApi?.task?.map((ele: any) => {
        if (Number(ele.client_initiate) == 1) {
          const tempEle: any = {
            ...ele,
            filter_by: `Dependencies from ${customerCompanyName} initiated by ${cognisassCustomerClientname}`,
          };
          tempArr.push(tempEle);
          return;
        }
      });
      settaskTypeOne(tempArr);
      singleProjectInformationApi?.task?.map((ele: any) => {
        if (Number(ele.client_initiate) == 0) {
          const tempEleNew: any = {
            ...ele,
            filter_by: `Dependencies from ${customerCompanyName}`,
          };
          tempArr1.push(tempEleNew);
          return;
        }
      });
      settaskTypeTwo(tempArr1);
    }
    if (singleProjectInformationApi?.clientTask?.length > 0) {
      singleProjectInformationApi?.clientTask?.map((ele: any) => {
        const tempEleNewest: any = {
          ...ele,
          filter_by: `Dependencies from ${cognisassCustomerClientname}`,
        };
        tempArr2.push(tempEleNewest);
        return;
      });
      settaskTypeThree(tempArr2);
    }
    setTotalTasks(tempArr);
  }, [singleProjectInformationApi]);

  useEffect(() => {
    setSingleProjectInformation(singleProjectInformationApi);
  }, [singleProjectInformationApi]);

  useEffect(() => {
    setSectionsList(singleProjectInformation.section);
  }, [singleProjectInformation]);

  useEffect(() => {
    if (location.state.openDrawer && Object.keys(location.state.openDrawer).length > 0) {
      setSelectedTask(location.state.openDrawer);
      setOpenUpdateTaskDrawer(true);
    }
  }, [location.state.openDrawer]);

  const customerPortalGetContactNameByContactUid = (uid: string) => {
    const found: any = allClientUsersApi?.find((contact: any) => contact['contact_uid'] == uid);
    if (uid == '15243') {
      return 'Not Assigned';
    } else if (typeof found === 'undefined') {
      return '';
    } else {
      return found['contact_name'];
    }
  };

  const columns = [
    {
      field: 'task_uid',
      hide: true,
      flex: 1,
    },
    {
      field: 'filter_by',
      hide: true,
      flex: 1,
    },
    {
      field: 'task_name',
      headerName: `${label_task} Name`,
      width: 250,
      renderCell: (params: GridRenderCellParams) => (
        <Typography
          className="cognisaas-pointer"
          variant="subtitle1"
          sx={{ color: '#093BCD', fontWeight: 400, fontSize: '14px' }}
        >
          {params.row.task_name}
        </Typography>
      ),
    },
    {
      field: 'section_uid',
      headerName: 'Phase Name',
      width: 200,
      renderCell: (params: GridRenderCellParams) => (
        <Typography variant="subtitle1" sx={{ fontWeight: 400, fontSize: '14px' }}>
          {sectionsList.map((ele: any) => {
            if (params.row.section_uid == ele?.section_uid) {
              return ele.section_detail;
            }
          })}
        </Typography>
      ),
    },
    {
      field: 'task_owner',
      headerName: 'Owner',
      width: 200,
      renderCell: (params: GridRenderCellParams) => (
        <Stack direction="row">
          <Stack spacing={1} direction={'row'}>
            <Stack direction="row">
              {!isUndefined(params.row.task_owner) || !isUndefined(params.row.contact_uid) ? (
                params.row.is_team_task === '1' ? (
                  params.row.task_owner !== '15243' ? (
                    <>
                      <Avatar
                        style={{ height: '34px', width: '34px', marginTop: '3px' }}
                        {...stringAvatar(customerPortalGetUserNameByUserUid(params.row.task_owner))}
                      />
                      {getUserActiveState(params.row.task_owner, allUsersApi) === '0' ? (
                        <Typography style={{ color: INACTIVE_USER_COLOR }} mt={1} variant="subtitle1">
                          {customerPortalGetUserNameByUserUid(params.row.task_owner)}&nbsp;({INACTIVE_STRING})
                        </Typography>
                      ) : (
                        <Typography mt={1} variant="subtitle1">
                          {customerPortalGetUserNameByUserUid(params.row.task_owner)}
                        </Typography>
                      )}
                    </>
                  ) : (
                    <>
                      <Avatar
                        style={{ height: '34px', width: '34px', marginTop: '3px' }}
                        {...stringAvatar('Not Assigned')}
                      />
                      <Typography style={{ marginTop: '8px', marginLeft: '5px' }} variant="subtitle1">
                        Not Assigned
                      </Typography>
                    </>
                  )
                ) : params.row.task_owner !== '15243' ? (
                  <>
                    <Avatar
                      style={{ height: '34px', width: '34px', marginTop: '3px' }}
                      {...stringAvatar(customerPortalGetContactNameByContactUid(params.row.contact_uid))}
                    />
                    <Typography style={{ marginTop: '8px', marginLeft: '5px' }} variant="subtitle1">
                      {customerPortalGetContactNameByContactUid(params.row.contact_uid)}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Avatar
                      style={{ height: '34px', width: '34px', marginTop: '3px' }}
                      {...stringAvatar('Not Assigned')}
                    />
                    <Typography style={{ marginTop: '8px', marginLeft: '5px' }} variant="subtitle1">
                      Not Assigned
                    </Typography>
                  </>
                )
              ) : null}
            </Stack>
          </Stack>
        </Stack>
      ),
    },
    {
      headerName: 'Status',
      field: 'task_status',
      width: 150,
      renderCell: (params: GridRenderCellParams) => (
        <Stack>
          <Stack
            direction="row"
            sx={{ fontWeight: 500, fontSize: '12px', display: 'flex', alignItems: 'center', flexDirection: 'row' }}
          >
            <span
              style={{ marginRight: '4px' }}
              className={
                params.value == 'red'
                  ? 'red_status_box_task'
                  : params.value == 'amber'
                  ? 'amber_status_box_task'
                  : params.value == 'green'
                  ? 'green_status_box_task'
                  : params.value == 'blue'
                  ? 'blue_status_box_task'
                  : params.value == 'grey'
                  ? 'grey_status_box_task'
                  : ''
              }
            ></span>
            {params.value == 'red' ? (
              <Typography sx={{ mt: '3px' }} variant="subtitle1">
                Overdue
              </Typography>
            ) : params.value == 'amber' ? (
              <Typography sx={{ mt: '3px' }} variant="subtitle1">
                At Risk
              </Typography>
            ) : params.value == 'green' ? (
              <Typography sx={{ mt: '3px' }} variant="subtitle1">
                On Track
              </Typography>
            ) : params.value == 'blue' ? (
              <Typography sx={{ mt: '3px' }} variant="subtitle1">
                Completed
              </Typography>
            ) : params.value == 'grey' ? (
              <Typography sx={{ mt: '3px' }} variant="subtitle1">
                Deferred
              </Typography>
            ) : (
              ''
            )}
          </Stack>
        </Stack>
      ),
    },
    {
      field: 'task_stage',
      headerName: 'Stage',
      width: 150,

      renderCell: (params: GridRenderCellParams) => (
        <Stack>
          <Stack
            direction="row"
            sx={{ fontWeight: 500, fontSize: '12px', display: 'flex', alignItems: 'center', flexDirection: 'row' }}
          >
            <span style={{ marginRight: '4px' }}>
              {params.row.task_stage === taskStage.IN_PROGRESS && (
                <CognisaasTaskStageIndicator
                  isMileStoneTask={params.row.milestone_task == 0 ? false : true}
                  stage={taskStage.IN_PROGRESS}
                />
              )}
              {params.row.task_stage === taskStage.NOT_STARTED && (
                <CognisaasTaskStageIndicator
                  isMileStoneTask={params.row.milestone_task == 0 ? false : true}
                  stage={taskStage.NOT_STARTED}
                />
              )}
              {params.row.task_stage === taskStage.BLOCKED && (
                <CognisaasTaskStageIndicator
                  isMileStoneTask={params.row.milestone_task == 0 ? false : true}
                  stage={taskStage.BLOCKED}
                />
              )}
              {params.row.task_stage === taskStage.DONE && (
                <CognisaasTaskStageIndicator
                  isMileStoneTask={params.row.milestone_task == 0 ? false : true}
                  stage={taskStage.DONE}
                />
              )}
              {params.row.task_stage === taskStage.DEFERRED && (
                <CognisaasTaskStageIndicator
                  isMileStoneTask={params.row.milestone_task == 0 ? false : true}
                  stage={taskStage.DEFERRED}
                />
              )}
            </span>
            <Typography variant="subtitle1">
              {getCustomNameMappedValue(params.row.task_stage, NAMED_CONSTATNTS.task_stage_key)}
            </Typography>
          </Stack>
        </Stack>
      ),
    },
    {
      field: 'task_start_date',
      headerName: 'Start Date',
      width: 150,
      renderCell: (params: GridRenderCellParams) => (
        <Typography variant="subtitle1" sx={{ fontWeight: 500, fontSize: '12px' }}>
          {params?.row?.task_start_date && <ViewDate date={params.row.task_start_date} />}
        </Typography>
      ),
    },

    {
      field: 'task_due_date',
      headerName: label_due_date,
      width: 150,
      renderCell: (params: GridRenderCellParams) => (
        <Typography variant="subtitle1" sx={{ fontWeight: 500, fontSize: '12px' }}>
          {params?.row?.task_due_date && <ViewDate date={params.row.task_due_date} />}
        </Typography>
      ),
    },
    {
      field: 'task_closed_date',
      headerName: label_delivery_date,
      width: 150,
      renderCell: (params: GridRenderCellParams) => (
        <Typography variant="subtitle1" sx={{ fontWeight: 500, fontSize: '12px' }}>
          {params?.row?.task_closed_date && <ViewDate date={params.row.task_closed_date} />}
        </Typography>
      ),
    },
  ];

  const columnsAcknowledged = [
    {
      field: 'task_uid',
      hide: true,
      flex: 1,
    },
    {
      field: 'filter_by',
      hide: true,
      flex: 1,
    },
    {
      field: 'task_name',
      headerName: `${label_task} Name`,
      width: 250,
      renderCell: (params: GridRenderCellParams) => (
        <Typography
          className="cognisaas-pointer"
          variant="subtitle1"
          sx={{ color: '#093BCD', fontWeight: 400, fontSize: '14px' }}
        >
          {params.row.task_name}
        </Typography>
      ),
    },
    {
      field: 'section_uid',
      headerName: 'Phase Name',
      width: 200,
      renderCell: (params: GridRenderCellParams) => (
        <Typography variant="subtitle1" sx={{ fontWeight: 400, fontSize: '14px' }}>
          {sectionsList.map((ele: any) => {
            if (params.row.section_uid == ele?.section_uid) {
              return ele.section_detail;
            }
          })}
        </Typography>
      ),
    },
    {
      field: 'task_owner',
      headerName: 'Owner',
      width: 200,
      renderCell: (params: GridRenderCellParams) => (
        <Stack direction="row">
          <Stack spacing={1} direction={'row'}>
            <Stack direction="row">
              {!isUndefined(params.row.task_owner) || !isUndefined(params.row.contact_uid) ? (
                params.row.is_team_task === '1' ? (
                  params.row.task_owner !== '15243' ? (
                    <>
                      <Avatar
                        style={{ height: '34px', width: '34px', marginTop: '3px' }}
                        {...stringAvatar(customerPortalGetUserNameByUserUid(params.row.task_owner))}
                      />
                      {getUserActiveState(params.row.task_owner, allUsersApi) === '0' ? (
                        <Typography style={{ color: INACTIVE_USER_COLOR }} mt={1} variant="subtitle1">
                          {customerPortalGetUserNameByUserUid(params.row.task_owner)}&nbsp;({INACTIVE_STRING})
                        </Typography>
                      ) : (
                        <Typography mt={1} variant="subtitle1">
                          {params.value}
                        </Typography>
                      )}
                    </>
                  ) : (
                    <>
                      <Avatar
                        style={{ height: '34px', width: '34px', marginTop: '3px' }}
                        {...stringAvatar('Not Assigned')}
                      />
                      <Typography style={{ marginTop: '8px', marginLeft: '5px' }} variant="subtitle1">
                        Not Assigned
                      </Typography>
                    </>
                  )
                ) : params.row.task_owner !== '15243' ? (
                  <>
                    <Avatar
                      style={{ height: '34px', width: '34px', marginTop: '3px' }}
                      {...stringAvatar(customerPortalGetContactNameByContactUid(params.row.contact_uid))}
                    />
                    <Typography style={{ marginTop: '8px', marginLeft: '5px' }} variant="subtitle1">
                      {customerPortalGetContactNameByContactUid(params.row.contact_uid)}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Avatar
                      style={{ height: '34px', width: '34px', marginTop: '3px' }}
                      {...stringAvatar('Not Assigned')}
                    />
                    <Typography style={{ marginTop: '8px', marginLeft: '5px' }} variant="subtitle1">
                      Not Assigned
                    </Typography>
                  </>
                )
              ) : null}
            </Stack>
          </Stack>
        </Stack>
      ),
    },
    {
      headerName: 'Status',
      field: 'task_status',
      width: 150,
      renderCell: (params: GridRenderCellParams) => (
        <Stack>
          <Stack
            direction="row"
            sx={{ fontWeight: 500, fontSize: '12px', display: 'flex', alignItems: 'center', flexDirection: 'row' }}
          >
            <span
              style={{ marginRight: '4px' }}
              className={
                params.value == 'red'
                  ? 'red_status_box_task'
                  : params.value == 'amber'
                  ? 'amber_status_box_task'
                  : params.value == 'green'
                  ? 'green_status_box_task'
                  : params.value == 'blue'
                  ? 'blue_status_box_task'
                  : params.value == 'grey'
                  ? 'grey_status_box_task'
                  : ''
              }
            ></span>
            {params.value == 'red' ? (
              <Typography sx={{ mt: '3px' }} variant="subtitle1">
                Overdue
              </Typography>
            ) : params.value == 'amber' ? (
              <Typography sx={{ mt: '3px' }} variant="subtitle1">
                At Risk
              </Typography>
            ) : params.value == 'green' ? (
              <Typography sx={{ mt: '3px' }} variant="subtitle1">
                On Track
              </Typography>
            ) : params.value == 'blue' ? (
              <Typography sx={{ mt: '3px' }} variant="subtitle1">
                Completed
              </Typography>
            ) : params.value == 'grey' ? (
              <Typography sx={{ mt: '3px' }} variant="subtitle1">
                Deferred
              </Typography>
            ) : (
              ''
            )}
          </Stack>
        </Stack>
      ),
    },
    {
      field: 'task_stage',
      headerName: 'Stage',
      width: 150,

      renderCell: (params: GridRenderCellParams) => (
        <Stack>
          <Stack
            direction="row"
            sx={{ fontWeight: 500, fontSize: '12px', display: 'flex', alignItems: 'center', flexDirection: 'row' }}
          >
            <span style={{ marginRight: '4px' }}>
              {params.row.task_stage === taskStage.IN_PROGRESS && (
                <CognisaasTaskStageIndicator
                  isMileStoneTask={params.row.milestone_task == 0 ? false : true}
                  stage={taskStage.IN_PROGRESS}
                />
              )}
              {params.row.task_stage === taskStage.NOT_STARTED && (
                <CognisaasTaskStageIndicator
                  isMileStoneTask={params.row.milestone_task == 0 ? false : true}
                  stage={taskStage.NOT_STARTED}
                />
              )}
              {params.row.task_stage === taskStage.BLOCKED && (
                <CognisaasTaskStageIndicator
                  isMileStoneTask={params.row.milestone_task == 0 ? false : true}
                  stage={taskStage.BLOCKED}
                />
              )}
              {params.row.task_stage === taskStage.DONE && (
                <CognisaasTaskStageIndicator
                  isMileStoneTask={params.row.milestone_task == 0 ? false : true}
                  stage={taskStage.DONE}
                />
              )}
              {params.row.task_stage === taskStage.DEFERRED && (
                <CognisaasTaskStageIndicator
                  isMileStoneTask={params.row.milestone_task == 0 ? false : true}
                  stage={taskStage.DEFERRED}
                />
              )}
            </span>
            <Typography variant="subtitle1">{params.row.task_stage}</Typography>
          </Stack>
        </Stack>
      ),
    },
    {
      field: 'task_start_date',
      headerName: 'Start Date',
      width: 150,
      renderCell: (params: GridRenderCellParams) => (
        <Typography variant="subtitle1" sx={{ fontWeight: 500, fontSize: '12px' }}>
          {params?.row?.task_start_date && <ViewDate date={params.row.task_start_date} />}
        </Typography>
      ),
    },

    {
      field: 'task_due_date',
      headerName: label_due_date,
      width: 150,
      renderCell: (params: GridRenderCellParams) => (
        <Typography variant="subtitle1" sx={{ fontWeight: 500, fontSize: '12px' }}>
          {params?.row?.task_due_date && <ViewDate date={params.row.task_due_date} />}
        </Typography>
      ),
    },
    {
      field: 'task_closed_date',
      headerName: label_delivery_date,
      width: 150,
      renderCell: (params: GridRenderCellParams) => (
        <Typography variant="subtitle1" sx={{ fontWeight: 500, fontSize: '12px' }}>
          {params?.row?.task_closed_date && <ViewDate date={params.row.task_closed_date} />}
        </Typography>
      ),
    },
    {
      field: 'task_acknowledged',
      headerName: 'Acknowledgement Status',
      alignItems: 'center',
      width: 250,
      renderCell: (params: GridRenderCellParams) => {
        if (params.row?.task_stage == 'Done') {
          return params?.row?.task_acknowledged === '0' ? (
            <Button
              variant="outlined"
              color="warning"
              sx={{ height: '25px', mt: '5px', width: '230px' }}
              onClick={() => updateAcknowledgedTaskStatus(params.row.task_uid)}
            >
              Click here to Acknowledge
            </Button>
          ) : (
            <Button variant="outlined" sx={{ height: '25px', mt: '5px', width: '230px' }} endIcon={<CheckIcon />}>
              Acknowledged
            </Button>
          );
        } else return <Typography variant="subtitle1">N/A</Typography>;
      },
    },
  ];

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <NoRecord />
        <Box sx={{ mt: 1 }}>No Rows</Box>
      </StyledGridOverlay>
    );
  }

  const isNotOverdue = (): boolean => {
    if (
      !isUndefined(location?.state?.selectedProject?.project_days_overdue) &&
      !isNull(location?.state?.selectedProject?.project_days_overdue)
    ) {
      if (location?.state?.selectedProject?.project_days_overdue <= 0) {
        return true;
      }
      return false;
    } else {
      if (
        differenceInDays(
          new Date(location?.state?.selectedProject?.project_requiredbydate.replace(/-/g, '/')),
          new Date()
        ) >= 0
      ) {
        return true;
      }
      return false;
    }
  };

  const projectUid = location?.state?.selectedProject?.project_uid;
  return (
    <Box sx={{ padding: '20px 0px 20px 20px' }}>
      <UpdateTask
        selectedTask={selectedTask}
        openUpdateTaskDrawer={openUpdateTaskDrawer}
        setOpenUpdateTaskDrawer={setOpenUpdateTaskDrawer}
        usersList={usersList}
        allUsersApi={allUsersApi}
        clientList={clientList}
        allClientUsersApi={allClientUsersApi}
        taskType={'project'}
        sectionsList={sectionsList}
        client_getsingleProjectInformationWithTaskClientUser={client_getsingleProjectInformationWithTaskClientUser}
        projectUid={projectUid}
      />
      <NewTaskDrawer
        usersList={usersList}
        allUsersApi={allUsersApi}
        clientList={clientList}
        allClientUsersApi={allClientUsersApi}
        openTaskDrawer={openTaskDrawer}
        singleProjectInformation={singleProjectInformation}
        setOpenTaskDrawer={setOpenTaskDrawer}
        taskType={'project'}
        sectionsList={sectionsList}
        client_getsingleProjectInformationWithTaskClientUser={client_getsingleProjectInformationWithTaskClientUser}
        projectUid={projectUid}
      />
      <Grid container justifyContent="space-between" sx={{ paddingRight: '20px' }}>
        <Grid item>
          <Stack direction="row">
            <IconButton disableRipple onClick={() => navigate(-1)}>
              <ChevronLeftIcon sx={{ cursor: 'pointer', ml: '-15px' }} />
            </IconButton>
            <Typography variant="h2" sx={{ mt: '7px' }}>
              {location?.state?.selectedProject?.project_name}
            </Typography>
            <Stack direction="row" sx={{ mt: '5px', ml: '10px' }}>
              <Box sx={{ marginTop: '4px' }}>
                <span
                  style={{ marginRight: '4px' }}
                  className={
                    location?.state?.selectedProject?.project_readinessstate_status == 'red'
                      ? 'red_status_box_task'
                      : location?.state?.selectedProject?.project_readinessstate_status == 'amber'
                      ? 'amber_status_box_task'
                      : location?.state?.selectedProject?.project_readinessstate_status == 'green'
                      ? 'green_status_box_task'
                      : location?.state?.selectedProject?.project_readinessstate_status == 'blue'
                      ? 'blue_status_box_task'
                      : ''
                  }
                ></span>
              </Box>
              <Typography variant="subtitle1" sx={{ mt: '1px' }}>
                {location?.state?.selectedProject?.project_readinessstate_status == 'red'
                  ? 'Overdue'
                  : location?.state?.selectedProject?.project_readinessstate_status == 'amber'
                  ? 'At Risk'
                  : location?.state?.selectedProject?.project_readinessstate_status == 'green'
                  ? 'On Track'
                  : location?.state?.selectedProject?.project_readinessstate_status == 'blue'
                  ? 'Completed'
                  : ''}
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item>
          {value == 0 ? (
            <Button
              sx={{ backgroundColor: '#FFFFFF', color: '#2D60F6' }}
              variant="outlined"
              onClick={() => setOpenTaskDrawer(true)}
            >
              Create {label_task}
            </Button>
          ) : null}
        </Grid>
      </Grid>
      <Box sx={{ width: '300px' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Overview" {...a11yProps(0)} />
            <Tab label={label_use_case} {...a11yProps(1)} />
            <Tab label="RAID" {...a11yProps(2)}/>
          </Tabs>
        </Box>
      </Box>
      <TabPanel value={value} index={0}>
        <Grid container xs={12} spacing={3}>
          <Grid item xs={3}>
            <Paper sx={{ height: '307px', width: '100%', mt: '20px' }}>
              <Box sx={{ padding: '12px' }}>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Typography sx={{ fontSize: '18px', fontWeight: '500' }}>{label_project} Detail</Typography>
                  </Grid>
                </Grid>
                <Grid container sx={{ mt: '12px', mb: '12px' }}>
                  <Grid xs={6}>
                    <Typography className="label-text" variant="subtitle2">
                      Actual Start Date
                    </Typography>
                    <ViewDate date={location?.state?.selectedProject?.project_startdate} />
                  </Grid>
                  <Grid xs={6}>
                    <Typography className="label-text" variant="subtitle2">
                      Planned Start Date
                    </Typography>
                    <ViewDate date={location?.state?.selectedProject?.project_planned_startdate} />
                  </Grid>
                </Grid>

                <Divider />
                <Grid container sx={{ mt: '12px', mb: '12px' }}>
                  <Grid xs={6}>
                    <Typography className="label-text" variant="subtitle2">
                      Due by
                    </Typography>
                    <ViewDate date={location?.state?.selectedProject?.project_requiredbydate} />
                  </Grid>
                  <Grid xs={6}>
                    <Typography className="label-text" variant="subtitle2">
                      ETA Delivery
                    </Typography>
                    <ViewDate date={location?.state?.selectedProject?.eta_forprojectdelivery} />
                  </Grid>
                </Grid>
                <Divider />
                <Typography className="label-text" variant="subtitle2" sx={{ mt: '12px' }}>
                  {label_delivery_date}
                </Typography>
                <ViewDate date={location?.state?.selectedProject?.project_deliverydate} />
                <Divider />
                <Stack direction="row">
                  <Avatar
                    style={{ height: '30px', width: '30px', marginTop: '25px' }}
                    {...stringAvatar(location?.state?.selectedProject?.project_deliverymanager)}
                  />
                  <Box sx={{ mt: '20px', ml: '7px' }}>
                    <Typography className="label-text" variant="subtitle2">
                      {label_project_delivery_manager}
                    </Typography>

                    {getUserActiveState(
                      getUserUid(location?.state?.selectedProject?.project_deliverymanager),
                      allUsersApi
                    ) === '0' ? (
                      <Typography
                        style={{ fontSize: '12px', fontWeight: '400', color: INACTIVE_USER_COLOR }}
                        variant="subtitle1"
                      >
                        {location?.state?.selectedProject?.project_deliverymanager}&nbsp;(Inactive)
                      </Typography>
                    ) : (
                      <Typography sx={{ fontSize: '12px', fontWeight: '400' }} variant="subtitle1">
                        {location?.state?.selectedProject?.project_deliverymanager}
                      </Typography>
                    )}
                  </Box>
                </Stack>
              </Box>
            </Paper>
            <Paper sx={{ mt: '12px' }}>
              <Box sx={{ padding: '20px' }}>
                {isNotOverdue() ? (
                  <>
                    <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>{label_due_date} Remaining</Typography>
                    <Stack direction="row">
                      <Typography sx={{ fontSize: '20px', fontWeight: '600', mt: '10px' }}>
                        {Math.abs(location?.state?.selectedProject?.project_days_remaining)} Days
                      </Typography>
                      <Typography
                        sx={{ fontSize: '12px', fontWeight: '400', color: '#7A7A7A', mt: '15px', ml: '15px' }}
                      >
                        <ViewDate date={location?.state?.selectedProject?.project_requiredbydate} />
                      </Typography>
                    </Stack>
                  </>
                ) : null}
                {!isNotOverdue() ? (
                  <>
                    <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>{label_due_date} Passed by</Typography>
                    <Stack direction="row">
                      <Typography sx={{ fontSize: '20px', fontWeight: '600', mt: '10px' }}>
                        {Math.abs(location?.state?.selectedProject?.project_days_overdue)} Days
                      </Typography>
                      <Typography
                        sx={{ fontSize: '12px', fontWeight: '400', color: '#7A7A7A', mt: '15px', ml: '15px' }}
                      >
                        <ViewDate date={location?.state?.selectedProject?.project_requiredbydate} />
                      </Typography>
                    </Stack>
                  </>
                ) : null}
              </Box>
            </Paper>
            <Paper sx={{ mt: '12px' }}>
              <Box sx={{ padding: '20px' }}>
                <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>Completion</Typography>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#7A7A7A', mt: '10px' }}>
                      Total {label_task}s Progress as per {label_due_date}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography sx={{ fontSize: '12px', fontWeight: '400', mt: '10px' }}>
                      {Math.trunc(singleProjectInformation?.project_progress)}%
                    </Typography>
                  </Grid>
                </Grid>
                <CustomizedProgressBars
                  value={singleProjectInformation?.project_progress}
                  statusColor="green"
                  hidePercentage={true}
                />
              </Box>
            </Paper>
            <Paper sx={{ mt: '12px' }}>
              <Box sx={{ padding: '20px' }}>
                <DeliverablesTimeline tasks={totalTasks} />
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={9} sx={{ mt: '20px', padding: '20px 0px 20px 0px' }}>
            {sectionsList && (
              <Box
                sx={{ padding: '10px', marginBottom: '15px' }}
                style={{
                  background: '#fff',
                  boxShadow: '0px 4px 26px rgba(0, 0, 0, 0.05)',
                  borderRadius: '4px',
                  minHeight: '100%',
                }}
              >
                {taskTypeOne?.length > 0 && (
                  <>
                    <Accordion
                      style={{ marginTop: '5px', boxShadow: 'none', border: '1px solid #E5E5E5' }}
                      defaultExpanded={true}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography variant={'h2'}>
                          {`Dependencies from ${customerCompanyName} initiated by ${cognisassCustomerClientname}`}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails style={{ padding: '0' }}>
                        <DataGridPremium
                          sx={{ borderRadius: 0 }}
                          components={{
                            NoRowsOverlay: CustomNoRowsOverlay,
                          }}
                          autoHeight
                          columns={columns}
                          rows={taskTypeOne}
                          getRowId={(row) => row.task_uid}
                          apiRef={apiRef}
                          disableSelectionOnClick
                          onRowClick={(params) => {
                            if (Object.entries(params.row).length == 0) return;
                            else {
                              setSelectedTask(params.row);
                              setOpenUpdateTaskDrawer(true);
                            }
                          }}
                          hideFooter
                        />
                      </AccordionDetails>
                    </Accordion>
                  </>
                )}
                {taskTypeTwo?.length > 0 && (
                  <>
                    <Accordion
                      style={{ marginTop: '5px', boxShadow: 'none', border: '1px solid #E5E5E5' }}
                      defaultExpanded={true}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography variant={'h2'}>{`Dependencies from ${customerCompanyName}`}</Typography>
                      </AccordionSummary>
                      <AccordionDetails style={{ padding: '0' }}>
                        <DataGridPremium
                          sx={{ borderRadius: 0 }}
                          components={{
                            NoRowsOverlay: CustomNoRowsOverlay,
                          }}
                          autoHeight
                          columns={showAcknowledgeButton === '1' ? columnsAcknowledged : columns}
                          rows={taskTypeTwo}
                          getRowId={(row) => row.task_uid}
                          apiRef={apiRef}
                          disableSelectionOnClick
                          hideFooter
                        />
                      </AccordionDetails>
                    </Accordion>
                  </>
                )}
                {taskTypeThree?.length > 0 && (
                  <>
                    <Accordion
                      style={{ marginTop: '5px', boxShadow: 'none', border: '1px solid #E5E5E5' }}
                      defaultExpanded={true}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography variant={'h2'}>{`Dependencies from ${cognisassCustomerClientname}`}</Typography>
                      </AccordionSummary>
                      <AccordionDetails style={{ padding: '0' }}>
                        <DataGridPremium
                          sx={{ borderRadius: 0 }}
                          components={{
                            NoRowsOverlay: CustomNoRowsOverlay,
                          }}
                          autoHeight
                          columns={columns}
                          rows={taskTypeThree}
                          getRowId={(row) => row.task_uid}
                          apiRef={apiRef}
                          disableSelectionOnClick
                          onRowClick={(params) => {
                            if (Object.entries(params.row).length == 0) return;
                            else {
                              setSelectedTask(params.row);
                              setOpenUpdateTaskDrawer(true);
                            }
                          }}
                          hideFooter
                        />
                      </AccordionDetails>
                    </Accordion>
                  </>
                )}
              </Box>
            )}
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
        {singleProjectInformation?.usecases?.length > 0 ? (
          <UseCaseTable
            useCases={singleProjectInformation?.usecases?.length > 0 ? singleProjectInformation?.usecases : []}
          />
        ) : (
          <Stack justifyContent="center" alignItems="center">
            <NoRecord style={{ marginLeft: '6px', marginTop: '10px', width: '150px', height: '150px' }}></NoRecord>
          </Stack>
        )}
      </TabPanel>
      <TabPanel value={value} index={2}>
        <CustomerRaidDashboard/>
      </TabPanel>
    </Box>
  );
};

export default CustomerProjectDashboard;
