import { Grid, Stack } from '@mui/material';
import { Box } from '@mui/system';
import { BarDatum, ResponsiveBar } from '@nivo/bar';
import { curveCatmullRom, line } from 'd3-shape';
// import theme from 'plugins/materialUI';
import { ReactNode } from 'react';
import { formatCurrency, formatNumberWithReadableCommas } from 'utils/dataHelpers';
// import LargeLegend from '../ChartComponents/LargeLegend';
import SmallLegend from '../ChartComponents/SmallLegend';
// import { AxisTick } from './MyAxisTick';
// // import RoundedCornersBar from './RoundedCornersBar';
import { animated } from '@react-spring/web';
// import { useTheme } from '@nivo/core';
import './styles.css';
import { useAppSelector } from 'store/hooks';
import { selectCustomerDetail } from 'store/modules/Common/selector';
import { KEYS_REPRESENTING_REVENUE } from '../constants';
import getSymbolFromCurrency from 'currency-symbol-map';
import { getWidthForBarChart } from '../chartUtils';

interface BarChartProps {
  data: any[];
  layout?: 'horizontal' | 'vertical';
  indexAxisKey: string;
  otherAxisKeys: string[];
  colors: string[];
  axisTopLegend?: string;
  axisRightLegend?: string;
  axisBottomLegend?: string;
  axisLeftLegend?: string;
  legend: 'left' | 'right' | 'top' | 'bottom' | 'none';
  customLegendComponent?: ReactNode;
  showTrendLine?: boolean;
  otherAxisDataKey: string;
  // legend: {
  //   anchor: 'top-right' | 'right' | 'bottom-right' | 'bottom' | 'bottom-left' | 'left' | 'top-left' | 'top' | 'center';
  //   translateX: number;
  //   translateY: number;
  //   direction: 'column' | 'row';
  // };
}

const ValueOnBarTop = ({ bars, xShift, yShift, chartProps }: any) => {
  const customerDetail = useAppSelector(selectCustomerDetail);

  const checkTypeNumAndFormatAccordingly = (str: any) => {
    console.log('chartttt', chartProps);
    if (isNaN(parseInt(str)) || chartProps.indexAxisKey === 'Time') {
      return str;
    } else {
      if (KEYS_REPRESENTING_REVENUE.includes(chartProps.otherAxisDataKey)) {
        return formatCurrency(parseInt(str), customerDetail?.currency_code);
      }
      return formatNumberWithReadableCommas(parseInt(str), customerDetail?.currency_code);
    }
  };

  return bars.map((bar: any) => {
    const {
      key,
      width,
      x,
      y,
      data: { value },
    } = bar;
    // console.log('sdasdsa', );
    return (
      <g key={key} transform={`translate(${x}, ${y})`}>
        <text
          transform={`translate(${
            chartProps.layout === 'horizontal'
              ? width + xShift + `${checkTypeNumAndFormatAccordingly(bar.data.value)}`.length
              : width + xShift
          }, ${yShift})`}
          textAnchor="middle"
          fontSize="12px"
          fontFamily="Noto Sans"
          color="#7A7A7A"
        >
          {/* YOUR LABEL HERE */}
          {checkTypeNumAndFormatAccordingly(value)}
        </text>
      </g>
    );
  });
};

const BarChart = (props: BarChartProps) => {
  // const mediumScreensAndSmaller = useMediaQuery(theme.breakpoints.down('md'));
  const chartProps = props;
  const customerDetail = useAppSelector(selectCustomerDetail);

  const ScatterCircle = (scatterCircleProps: any) => {
    // console.log(scatterCircleProps);

    const { bars } = scatterCircleProps;

    return (
      <>
        {bars.map((bar: any) => (
          // Render the circle SVG in chart using Bars co-ordinates.
          <>
            {/* {console.log(bar.data.index)} */}
            <circle
              key={`point-${bar.data.data[props.indexAxisKey]}`}
              // Scale x-cordinate of the circle to the center of bar
              cx={bar.x + bar.width / 2}
              // Scale y-cordinate of the circle to top of the bar
              cy={bar.y}
              r={3}
              fill="black"
              stroke="black"
              style={{ pointerEvents: 'none' }}
            />
          </>
        ))}
      </>
    );
  };

  const Line = (lineProps: any) => {
    const { bars } = lineProps;
    const lineGenerator = line()
      .x((bar: any) => bar.x + bar.width / 2)
      .y((bar: any) => bar.y)
      .curve(curveCatmullRom.alpha(0.5));

    return (
      <>
        {console.log(bars[0])}
        <path
          d={lineGenerator(bars) as string}
          fill="none"
          stroke={'#3d38ba'}
          style={{ pointerEvents: 'none', strokeWidth: '1' }}
        />
      </>
    );
  };
  // const theme = useTheme();

  const shouldTruncateLabelOnBottomAxis = (val: string): boolean =>
    props.data.length > 4 && val.length > 5 ? true : false;
  const shouldTruncateLabelOnLeftAxis = (val: string): boolean => (val.length > 5 ? true : false);

  // const checkTypeNumAndFormat = (str: any) => {
  //   console.log('chartttt', chartProps);

  //   if (isNaN(parseInt(str)) || chartProps.indexAxisKey === 'Time') {
  //     return str;
  //   } else {
  //     return numberFormatter(str);
  //   }
  // };

  const checkTypeNumAndFormatAccordingly = (str: any) => {
    console.log('chartttt', chartProps);
    if (isNaN(parseInt(str)) || chartProps.indexAxisKey === 'Time') {
      return str;
    } else {
      if (KEYS_REPRESENTING_REVENUE.includes(chartProps.otherAxisDataKey)) {
        return formatCurrency(parseInt(str), customerDetail?.currency_code);
      }
      return formatNumberWithReadableCommas(parseInt(str), customerDetail?.currency_code);
    }
  };

  return (
    <Box>
      <Stack
        alignItems={'center'}
        justifyContent={'center'}
        direction={
          props.legend === 'bottom'
            ? 'column'
            : props.legend === 'right'
            ? 'row'
            : props.legend === 'top'
            ? 'column-reverse'
            : props.legend === 'left'
            ? 'row-reverse'
            : 'column'
        }
      >
        <Box
          sx={{
            height: '350px',
            width: getWidthForBarChart(props.data),
            marginLeft: '100px',
            marginBottom: '20px',
          }}
        >
          <ResponsiveBar
            data={props.data as unknown as BarDatum[]}
            colors={props.colors}
            keys={props.otherAxisKeys}
            indexBy={props.indexAxisKey}
            margin={{ top: 50, right: 60, bottom: 70, left: 100 }}
            padding={0.5}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            // colors={{ scheme: 'nivo' }}
            layout={props.layout ? props.layout : 'vertical'}
            defs={[
              {
                id: 'dots',
                type: 'patternDots',
                background: 'inherit',
                color: '#38bcb2',
                size: 4,
                padding: 1,
                stagger: true,
              },
              {
                id: 'lines',
                type: 'patternLines',
                background: 'inherit',
                color: '#eed312',
                rotation: -45,
                lineWidth: 6,
                spacing: 10,
              },
            ]}
            fill={[
              {
                match: {
                  id: 'fries',
                },
                id: 'dots',
              },
              {
                match: {
                  id: 'sandwich',
                },
                id: 'lines',
              },
            ]}
            theme={{
              axis: {
                ticks: {
                  line: {
                    stroke: 'green',
                    strokeWidth: 0,
                  },
                  text: {
                    fill: '#7A7A7A',
                    wordWrap: 'break-word',
                    maxWidth: '2',
                  },
                },
                legend: {
                  text: {
                    fontFamily: 'Noto Sans',
                    fontSize: 18,
                    fill: '#000000',
                  },
                },
                domain: {
                  line: {
                    strokeWidth: 1,
                    stroke: '#E5E5E5',
                  },
                },
              },
              fontFamily: 'Noto Sans',
              // background: 'rgba(243, 243, 243,0.3)',

              grid: {
                line: {
                  stroke: '#E5E5E5',
                  strokeWidth: 1,
                  strokeDasharray: '4 4',
                },
              },
            }}
            borderColor={{
              from: 'color',
              modifiers: [['darker', 1.6]],
            }}
            axisTop={
              props.axisTopLegend
                ? {
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: props.axisTopLegend,
                    legendPosition: 'middle',
                    legendOffset: 32,
                  }
                : null
            }
            axisRight={
              props.axisRightLegend
                ? {
                    tickSize: 5,
                    tickPadding: 0,
                    tickRotation: 0,
                    legend: props.axisRightLegend,
                    legendPosition: 'middle',
                    legendOffset: 40,
                  }
                : null
            }
            axisBottom={
              props.axisBottomLegend
                ? {
                    tickSize: 5,
                    tickPadding: 0,
                    tickRotation: 0,
                    // legend: props.axisBottomLegend,
                    legend:
                      KEYS_REPRESENTING_REVENUE.includes(props.otherAxisDataKey) && chartProps.layout === 'horizontal'
                        ? `${props.axisBottomLegend} (${getSymbolFromCurrency(customerDetail?.currency_code)})`
                        : props.axisBottomLegend,

                    legendPosition: 'middle',
                    legendOffset: 40,
                    renderTick: (oProps: any) => {
                      const value = checkTypeNumAndFormatAccordingly(oProps.value);
                      return (
                        <animated.g transform={oProps.animatedProps.transform} {...oProps}>
                          <line x1={0} x2={oProps.lineX} y1={0} y2={oProps.lineY} />
                          <animated.text
                            dominantBaseline={oProps.textBaseline}
                            textAnchor={oProps.textAnchor}
                            transform={oProps.animatedProps.textTransform}
                            style={{ fontSize: '0.8rem' }}
                          >
                            <title>{value}</title>
                            {isNaN(oProps.value)
                              ? `${shouldTruncateLabelOnBottomAxis(`${value}`) ? `${value}`.slice(0, 6) : value}${
                                  shouldTruncateLabelOnBottomAxis(`${value}`) ? '...' : ''
                                }`
                              : value}
                          </animated.text>
                        </animated.g>
                      );
                    },
                  }
                : null
            }
            axisLeft={
              props.axisLeftLegend
                ? {
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: KEYS_REPRESENTING_REVENUE.includes(props.otherAxisDataKey)
                      ? `${props.axisLeftLegend} (${getSymbolFromCurrency(customerDetail?.currency_code)})`
                      : props.axisLeftLegend,
                    legendPosition: 'middle',
                    legendOffset: -80,
                    format: (e) => Math.floor(e) === e && e,
                    // tickValues: [500, 1000, 5000, 100000, 125000],
                    // format: (e) => {
                    //   if (isNaN(parseInt(e))) {
                    //     return e;
                    //   } else {
                    //     return numberFormatter(e);
                    //   }
                    // },
                    renderTick: (oProps: any) => {
                      const value = checkTypeNumAndFormatAccordingly(oProps.value);
                      return (
                        <animated.g transform={oProps.animatedProps.transform} {...oProps}>
                          <line x1={0} x2={oProps.lineX} y1={0} y2={oProps.lineY} />
                          <animated.text
                            dominantBaseline={oProps.textBaseline}
                            textAnchor={oProps.textAnchor}
                            transform={oProps.animatedProps.textTransform}
                            style={{ fontSize: '0.7rem' }}
                          >
                            <title>{value}</title>
                            {isNaN(oProps.value)
                              ? `${shouldTruncateLabelOnLeftAxis(`${value}`) ? `${value}`.slice(0, 6) : value}
                                ${shouldTruncateLabelOnLeftAxis(`${value}`) ? '...' : ''}`
                              : value}
                          </animated.text>
                        </animated.g>
                      );
                    },
                  }
                : null
            }
            layers={
              props.showTrendLine
                ? props.showTrendLine === true
                  ? [
                      'grid',
                      'axes',
                      'bars',
                      ScatterCircle,
                      Line,
                      (props) => (
                        <ValueOnBarTop
                          {...props}
                          xShift={
                            chartProps.layout && chartProps.layout === 'horizontal'
                              ? 9
                              : chartProps.data.length > 4
                              ? -12
                              : chartProps.data.length > 1 && chartProps.data.length < 4
                              ? -19
                              : -12
                          }
                          yShift={
                            chartProps.layout && chartProps.layout === 'horizontal'
                              ? chartProps.data.length > 4
                                ? 8
                                : 11
                              : -5
                          }
                          chartProps={chartProps}
                        />
                      ),
                    ]
                  : [
                      'grid',
                      'axes',
                      'bars',
                      (props) => (
                        <ValueOnBarTop
                          {...props}
                          xShift={
                            chartProps.layout && chartProps.layout === 'horizontal'
                              ? 9
                              : chartProps.data.length > 4
                              ? -12
                              : chartProps.data.length > 1 && chartProps.data.length < 4
                              ? -19
                              : -12
                          }
                          yShift={
                            chartProps.layout && chartProps.layout === 'horizontal'
                              ? chartProps.data.length > 4
                                ? 8
                                : 11
                              : -5
                          }
                          chartProps={chartProps}
                        />
                      ),
                    ]
                : [
                    'grid',
                    'axes',
                    'bars',
                    (props) => (
                      <ValueOnBarTop
                        {...props}
                        xShift={
                          chartProps.layout && chartProps.layout === 'horizontal'
                            ? 9
                            : chartProps.data.length > 4
                            ? -12
                            : chartProps.data.length > 1 && chartProps.data.length < 4
                            ? -19
                            : -12
                        }
                        yShift={
                          chartProps.layout && chartProps.layout === 'horizontal'
                            ? chartProps.data.length > 4
                              ? 8
                              : 11
                            : -5
                        }
                        chartProps={chartProps}
                      />
                    ),
                  ]
            }
            enableLabel={false}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{
              from: 'color',
              modifiers: [['darker', 1.6]],
            }}
            labelFormat={''}
            legends={[]}
            role="application"
            // barComponent={RoundedCornersBar}
            ariaLabel="Nivo bar chart"
            borderRadius={4}
            barAriaLabel={function (e) {
              return e.id + ': ' + e.formattedValue + ' in country: ' + e.indexValue;
            }}
          />
        </Box>
        {props.legend !== 'none' ? (
          <Grid container spacing={1} sx={{ padding: '8px', width: 'fit-content' }} justifyContent={'center'}>
            {props.customLegendComponent ? (
              props.customLegendComponent
            ) : (
              <>
                {props.otherAxisKeys.map((item, index) => {
                  return (
                    <Grid item xs={['left', 'right'].includes(props.legend) ? 12 : 6} key={index}>
                      <SmallLegend color={props.colors[index % props.colors.length]} name={item.split('_').join(' ')} />
                    </Grid>
                  );
                })}
              </>
            )}
          </Grid>
        ) : (
          ''
        )}
      </Stack>
    </Box>
  );
};

export default BarChart;
