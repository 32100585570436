import { IconButton, Stack, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { label_due_date, label_project, label_use_case } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import CognisaaasTooltip from '../CognisaasTooltip/CognisaasTooltip';
import { ReactComponent as Info } from '../../../assets/svgs/info.svg';
import { Box } from '@mui/system';
import { isNull } from '../../../utils/dataHelpers';

export const getProjectDaysRemainingWithColorCell = (params: GridRenderCellParams) => {
  const color = !isNull(params.value) ? (params.value >= 0 ? '#4F9F52' : '#E75B5C') : '#4390E1';
  const text = !isNull(params.value)
    ? params.value >= 0
      ? `${Math.abs(params.value)} Days Remaining`
      : `${Math.abs(params.value)} Days Overdue`
    : `${label_project} Completed`;
  return (
    <Stack style={{ padding: '7px 0', color: color }}>
      <Stack spacing={1} direction="row">
        {text}
      </Stack>
    </Stack>
  );
};

export const getUsecaseDaysRemainingWithColorCell = (params: GridRenderCellParams) => {
  const color = !isNull(params.value) ? (params.value >= 0 ? '#4F9F52' : '#E75B5C') : '#4390E1';
  const text = !isNull(params.value)
    ? params.value >= 0
      ? `${Math.abs(params.value)} Days Remaining`
      : `${Math.abs(params.value)} Days Overdue`
    : `${label_use_case} Completed`;
  return (
    <Stack style={{ padding: '7px 0', color: color }}>
      <Stack spacing={1} direction="row">
        {text}
      </Stack>
    </Stack>
  );
};

export const getProjectAgeingHeader = () => (
  <Stack direction={'row'}>
    <Typography variant="subtitle1" style={{ fontWeight: 500 }}>
      {label_project} Age
    </Typography>
    <CognisaaasTooltip
      title={
        <Box sx={{ padding: '12px' }}>
          <Typography variant="subtitle2">Difference of Actual Start Date to Today</Typography>
        </Box>
      }
    >
      <IconButton disableRipple size="small">
        <Info />
      </IconButton>
    </CognisaaasTooltip>
  </Stack>
);

export const getDaysRemainingHeader = () => (
  <Stack direction={'row'}>
    <Typography variant="subtitle1" style={{ fontWeight: 500 }}>
      Days Remaining
    </Typography>
    <CognisaaasTooltip
      title={
        <Box sx={{ padding: '12px' }}>
          <Typography variant="subtitle2">Difference between {label_due_date} to Today</Typography>
        </Box>
      }
    >
      <IconButton disableRipple size="small">
        <Info />
      </IconButton>
    </CognisaaasTooltip>
  </Stack>
);

export const getUsecaseAgeingHeader = () => (
  <Stack direction={'row'}>
    <Typography variant="subtitle1" style={{ fontWeight: 500 }}>
      {label_use_case} Age
    </Typography>
    <CognisaaasTooltip
      title={
        <Box sx={{ padding: '12px' }}>
          <Typography variant="subtitle2">Difference of Actual Start Date to Today</Typography>
        </Box>
      }
    >
      <IconButton disableRipple size="small">
        <Info />
      </IconButton>
    </CognisaaasTooltip>
  </Stack>
);

export function formatUsecaseDaysRemainingField(data: any[]) {
  if (data.length > 0) {
    const tempData = data.map((item) => {
      const row = { ...item };
      if (row?.case_days_remaining) {
        if (row.case_days_remaining < 0) {
          row.case_days_remaining = row.case_days_overdue * -1;
        }
      }
      return { ...row };
    });
    return tempData;
  } else {
    return [];
  }
}

export function formatProjectDaysRemainingField(data: any[]) {
  if (data.length > 0) {
    const tempData = data.map((item) => {
      const row = { ...item };
      if (row?.project_days_remaining) {
        if (row.project_days_remaining < 0) {
          row.project_days_remaining = row.project_days_overdue * -1;
        }
      }
      return { ...row };
    });
    return tempData;
  } else {
    return [];
  }
}
