import ProjectDetail from './ProjectDetail';
// import { Grid } from '@mui/material';

const ProjectOverview = (props: any) => {
  return (
    // <Grid container spacing={2}>
    <ProjectDetail client={props.client} project={props.project} />
    // </Grid>
  );
};

export default ProjectOverview;
