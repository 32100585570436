// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/bar
import { ResponsiveBar } from '@nivo/bar';
import { chartGroupType, LayoutType } from 'components/shared/Charts/types';
import { animated } from '@react-spring/web';
import { KEYS_REPRESENTING_REVENUE } from '../constants';
// import { formatCurrency, formatNumberWithReadableCommas } from 'utils/dataHelpers';
import { useAppSelector } from 'store/hooks';
import { selectCustomerDetail } from 'store/modules/Common/selector';
import getSymbolFromCurrency from 'currency-symbol-map';
// import { data } from './temp';
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
// const keys = ['hot dog', 'burger', 'sandwich', 'kebab', 'fries', 'donut'];
const getWidthForGroupChart = (data: any[], groupedKeys: string[], layout?: LayoutType) => {
  if (layout && layout === 'horizontal') {
    return 490;
  }
  const numberOfGroups = data.length;
  const numberOfGroupedKeys = groupedKeys.length;
  const neededWidth: number = numberOfGroups * 110 + numberOfGroupedKeys * 20;
  const minWidth = 600;
  return neededWidth > minWidth ? neededWidth : minWidth;
};

const getHeightForGroupChart = (data: any[], groupedKeys: string[], layout: LayoutType) => {
  if (layout === 'horizontal') {
    return getWidthForGroupChart(data, groupedKeys);
  }
  return 370;
};

const getAxisLabelTiltAngle = (groupedKeys: string[], layout: LayoutType): number => {
  const numberOfGroupedKeys = groupedKeys.length;
  if (layout === 'horizontal') {
    return 90;
  } else if (numberOfGroupedKeys > 4) {
    return 0;
  } else {
    return -45;
  }
};
const shouldTruncateLabelOnBottomAxis = (data: any[], val: string): boolean => {
  console.log('chhhhhhhhhhhhhhh', val);
  return val.length > 8 ? true : false;
};

// const ValueOnBarTop = ({ bars }: any) => {
//   // const customerDetail = useAppSelector(selectCustomerDetail);

//   // const checkTypeNumAndFormatAccordingly = (str: any) => {
//   //   console.log('chartttt', chartProps);
//   //   if (isNaN(parseInt(str)) || chartProps.indexAxisKey === 'Time') {
//   //     return str;
//   //   } else {
//   //     if (KEYS_REPRESENTING_REVENUE.includes(chartProps.otherAxisDataKey)) {
//   //       return formatCurrency(parseInt(str), customerDetail?.currency_code);
//   //     }
//   //     return formatNumberWithReadableCommas(parseInt(str), customerDetail?.currency_code);
//   //   }
//   // };

//   return bars.map((bar: any) => {
//     const {
//       key,
//       width,
//       x,
//       y,
//       data: { value },
//     } = bar;
//     // console.log('sdasdsa', );
//     return (
//       <g key={key} transform={`translate(${x}, ${y})`}>
//         <text
//           // transform={`translate(${width + xShift}, ${yShift})`}
//           textAnchor="top"
//           fontSize="12px"
//           fontFamily="Noto Sans"
//           color="#7A7A7A"
//         >
//           {/* YOUR LABEL HERE */}
//           {value}
//         </text>
//       </g>
//     );
//   });
// };

interface Props {
  groupNameValueKey: string;
  keys: string[];
  data: any[];
  colors: string[];
  xAxisName: string;
  yAxisName: string;
  yAxisKey: string;
  groupMode: chartGroupType;
  layout: LayoutType;
}

const GorupBarChart = (props: Props) => {
  const { data, groupNameValueKey, keys, colors, xAxisName, yAxisName, groupMode, yAxisKey, layout } = props;
  const customerDetail = useAppSelector(selectCustomerDetail);

  // const checkTypeNumAndFormatAccordingly = (yAxisKey: string, str: any) => {
  //   // console.log('chartttt', chartProps);
  //   // if (isNaN(parseInt(str)) || chartProps.indexAxisKey === 'Time') {
  //   //   return str;
  //   // } else {
  //   if (KEYS_REPRESENTING_REVENUE.includes(yAxisKey)) {
  //     return formatCurrency(parseInt(str), customerDetail?.currency_code);
  //   }
  //   return formatNumberWithReadableCommas(parseInt(str), customerDetail?.currency_code);
  //   // }
  // };

  const renderTickForTextLabels = (oProps: any) => {
    // const value = checkTypeNumAndFormatAccordingly(yAxisKey, oProps.value);
    const value = oProps.value;
    return (
      <animated.g transform={oProps.animatedProps.transform} {...oProps}>
        <line x1={0} x2={oProps.lineX} y1={0} y2={oProps.lineY} />
        <animated.text
          dominantBaseline={oProps.textBaseline}
          textAnchor={oProps.textAnchor}
          transform={oProps.animatedProps.textTransform}
          style={{ fontSize: '0.8rem' }}
        >
          <title>{value}</title>
          {isNaN(oProps.value)
            ? `${shouldTruncateLabelOnBottomAxis(data, `${value}`) ? `${value}`.slice(0, 6) : value}${
                shouldTruncateLabelOnBottomAxis(data, `${value}`) ? '...' : ''
              }`
            : value}
        </animated.text>
      </animated.g>
    );
  };

  return (
    <div
      style={{ width: getWidthForGroupChart(data, keys, layout), height: getHeightForGroupChart(data, keys, layout) }}
    >
      <ResponsiveBar
        data={data}
        keys={keys}
        indexBy={groupNameValueKey}
        margin={{ top: 50, right: 130, bottom: 80, left: 100 }}
        padding={0.3}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={colors}
        groupMode={groupMode}
        layout={props.layout ? props.layout : 'vertical'}
        // defs={[
        //   {
        //     id: 'dots',
        //     type: 'patternDots',
        //     background: 'inherit',
        //     color: '#38bcb2',
        //     size: 4,
        //     padding: 1,
        //     stagger: true,
        //   },
        //   {
        //     id: 'lines',
        //     type: 'patternLines',
        //     background: 'inherit',
        //     color: '#eed312',
        //     rotation: -45,
        //     lineWidth: 6,
        //     spacing: 10,
        //   },
        // ]}
        //   for label on top of bars
        //   layers={[
        //     'grid',
        //     'axes',
        //     'bars',
        //     (props) => (
        //       <ValueOnBarTop
        //         {...props}
        //         // xShift={
        //         //   chartProps.layout && chartProps.layout === 'horizontal'
        //         //     ? 9
        //         //     : chartProps.data.length > 4
        //         //     ? -12
        //         //     : chartProps.data.length > 1 && chartProps.data.length < 4
        //         //     ? -19
        //         //     : -12
        //         // }
        //         // yShift={
        //         //   chartProps.layout && chartProps.layout === 'horizontal'
        //         //     ? chartProps.data.length > 4
        //         //       ? 8
        //         //       : 11
        //         //     : -5
        //         // }
        //         // chartProps={chartProps}
        //       />
        //     ),
        //   ]}

        borderColor={{
          from: 'color',
          modifiers: [['darker', 1.6]],
        }}
        theme={{
          axis: {
            ticks: {
              line: {
                stroke: 'green',
                strokeWidth: 0,
              },
              text: {
                fill: '#7A7A7A',
                wordWrap: 'break-word',
                maxWidth: '2',
              },
            },
            legend: {
              text: {
                fontFamily: 'Noto Sans',
                fontSize: 18,
                fill: '#000000',
              },
            },
            domain: {
              line: {
                strokeWidth: 1,
                stroke: '#E5E5E5',
              },
            },
          },
          fontFamily: 'Noto Sans',
          // background: 'rgba(243, 243, 243,0.3)',

          grid: {
            line: {
              stroke: '#E5E5E5',
              strokeWidth: 1,
              strokeDasharray: '4 4',
            },
          },
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: getAxisLabelTiltAngle(keys, layout),
          legend: xAxisName,
          legendPosition: 'middle',
          legendOffset: 60,
          renderTick: (oProps: any) => renderTickForTextLabels(oProps),
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend:
            KEYS_REPRESENTING_REVENUE.includes(yAxisKey) && layout === 'horizontal'
              ? `${yAxisName} (${getSymbolFromCurrency(customerDetail?.currency_code)})`
              : yAxisName,
          legendPosition: 'middle',
          legendOffset: -65,
          renderTick: layout === 'horizontal' ? (oProps: any) => renderTickForTextLabels(oProps) : undefined,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
          from: 'color',
          modifiers: [['darker', 2.6]],
        }}
        legends={[
          {
            dataFrom: 'keys',
            anchor: 'top-right',
            direction: 'column',
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 5,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: 'left-to-right',
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: 'hover',
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        role="application"
        // ariaLabel="Nivo bar chart demo"
        barAriaLabel={function (e) {
          return e.id + ': ' + e.formattedValue + ' in country: ' + e.indexValue;
        }}
      />
    </div>
  );
};

export default GorupBarChart;
