import { Button, IconButton, Stack, Typography } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import BarChart from '../BarCharts/BarChart';
import { chart, labelPositionType } from '../CustomiseDrawer/types';
import DonutChart from '../DonutCharts/DonutChart';
import LineChart from '../LineChart/LineChart';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  generateMonthDetails,
  generateQuarterDetails,
  generateWeekDetails,
  monthRangeGenerator,
  quarterRangeGenerator,
  weekRangeGenerator,
  yearRangeGenerator,
} from '../helpers';
import { deleteGraphConfiguration } from 'store/modules/Charts/slice';
// import CognisaasButton from 'components/shared/CognisaasButton/CognisaasButton';
import { isNull, isValidDate } from 'utils/dataHelpers';
import useCustomNames from 'components/shared/CustomNames/useCustomNames';
// import { KEYS_REPRESENTING_REVENUE } from '../constants';
// import getSymbolFromCurrency from 'currency-symbol-map';
import { useAppSelector } from 'store/hooks';
import { selectCustomerDetail } from 'store/modules/Common/selector';
import { getCustomerDetail } from 'store/modules/Common/slice';
import DeleteModal from 'components/shared/CognisaasModal/DeleteModal';
import GorupBarChart from '../GroupBarCharts/GroupBarChart';

export interface ChartBoxProps {
  idName: string;
  chartType: chart;
  layout: 'horizontal' | 'vertical';
  chartHeading: string;
  dataForChart: any[];
  colors: string[];
  legendPosition: labelPositionType;
  xAxisKeys: Record<string, any>;
  yAxisKeys: Record<string, any>;
  barChartTrendline?: boolean;
  project_chart_uid?: string;
  case_chart_uid?: string;
  task_chart_uid?: string;
  configType: 'project' | 'usecase' | 'task' | 'any' | '';
  flipAxis: boolean;
  setDrawerState: React.Dispatch<React.SetStateAction<boolean>>;
  setGraphData: React.Dispatch<React.SetStateAction<any>>;
  setFormActionType: React.Dispatch<React.SetStateAction<'save' | 'update'>>;
  entity_chart_uid?: string;
}

const getNumericValue = (value: string): number => {
  return isNaN(parseInt(value)) ? 0 : parseInt(value);
};

const ChartBox = (props: ChartBoxProps) => {
  const [generatedData, setGeneratedData] = useState<Record<string, any>[]>([]);
  const { setDrawerState, setGraphData, setFormActionType } = props;

  const dispatch = useDispatch();
  const { displayDataWithCustomNames } = useCustomNames();
  const customerDetail = useAppSelector(selectCustomerDetail);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

  console.log(props.dataForChart);
  console.log('cus', customerDetail);

  useEffect(() => {
    if (isNull(customerDetail)) {
      dispatch(getCustomerDetail());
    }
  }, []);

  useEffect(() => {
    const dataKey = props.xAxisKeys.possibleOptions[0][1];

    const tempGeneratedData: Record<string, any>[] = [];
    // this is currently for bar chart and donut chart
    console.log('first', props.xAxisKeys);
    if (props.xAxisKeys.key === 'Time') {
      let maxDate: Date = new Date('1000-01-01 00:00:00'.replace(/-/g, '/'));
      let minDate: Date = new Date();

      const validss = props.dataForChart.map((item) => isValidDate(new Date(item[dataKey])));
      console.log('valids', validss);

      props.dataForChart.forEach((item) => {
        if (isValidDate(new Date(item[dataKey]))) {
          const tempDate = new Date(item[dataKey].replace(/-/g, '/'));
          if (tempDate < minDate) {
            minDate = tempDate;
          }

          if (tempDate > maxDate) {
            maxDate = tempDate;
          }
        }
      });

      console.log('min', minDate, maxDate);

      if (props.xAxisKeys.possibleOptions[1][0] === 'Year') {
        const allPossibleYears = yearRangeGenerator(minDate, maxDate);
        const valueCounts = allPossibleYears.map(() => 0);

        props.dataForChart.forEach((item) => {
          if (isValidDate(new Date(item[dataKey]))) {
            const tempDate = new Date(item[dataKey]);
            const yearDetails = tempDate.getFullYear();
            const foundindex = allPossibleYears.indexOf(`${yearDetails}`);
            if (props.yAxisKeys.key === 'Data Count') {
              valueCounts[foundindex] += 1;
            } else {
              valueCounts[foundindex] += getNumericValue(item[props.yAxisKeys.key]);
            }
          }
        });

        allPossibleYears.forEach((item, index) => {
          tempGeneratedData.push({
            [props.xAxisKeys.key]: item,
            [props.yAxisKeys.name]: valueCounts[index],
          });
        });
      } else if (props.xAxisKeys.possibleOptions[1][0] === 'Quarter') {
        const allPossibleQuarters = quarterRangeGenerator(minDate, maxDate);

        const valueCounts = allPossibleQuarters.map(() => 0);

        props.dataForChart.forEach((item) => {
          if (isValidDate(new Date(item[dataKey]))) {
            const tempDate = new Date(item[dataKey].replace(/-/g, '/'));
            const quarterDetails = generateQuarterDetails(tempDate);
            const foundindex = allPossibleQuarters.indexOf(quarterDetails);
            if (props.yAxisKeys.key === 'Data Count') {
              valueCounts[foundindex] += 1;
            } else {
              valueCounts[foundindex] += getNumericValue(item[props.yAxisKeys.key]);
            }
          }
        });

        allPossibleQuarters.forEach((item, index) => {
          tempGeneratedData.push({
            [props.xAxisKeys.key]: item,
            [props.yAxisKeys.name]: valueCounts[index],
          });
        });
      } else if (props.xAxisKeys.possibleOptions[1][0] === 'Month') {
        const allPossibleMonths = monthRangeGenerator(minDate, maxDate);

        const valueCounts = allPossibleMonths.map(() => 0);

        props.dataForChart.forEach((item) => {
          if (isValidDate(new Date(item[dataKey]))) {
            const tempDate = new Date(item[dataKey].replace(/-/g, '/'));
            const monthDetails = generateMonthDetails(tempDate);
            const foundindex = allPossibleMonths.indexOf(monthDetails);

            if (props.yAxisKeys.key === 'Data Count') {
              valueCounts[foundindex] += 1;
            } else {
              valueCounts[foundindex] += getNumericValue(item[props.yAxisKeys.key]);
            }
          }
        });

        allPossibleMonths.forEach((item, index) => {
          tempGeneratedData.push({
            [props.xAxisKeys.key]: item,
            [props.yAxisKeys.name]: valueCounts[index],
          });
        });
      } else if (props.xAxisKeys.possibleOptions[1][0] === 'Week') {
        const allPossibleWeeks = weekRangeGenerator(minDate, maxDate);

        const valueCounts = allPossibleWeeks.map(() => 0);

        props.dataForChart.forEach((item) => {
          if (isValidDate(new Date(item[dataKey]))) {
            const tempDate = new Date(item[dataKey].replace(/-/g, '/'));
            const weekDetails = generateWeekDetails(tempDate);
            const foundindex = allPossibleWeeks.indexOf(weekDetails);
            if (props.yAxisKeys.key === 'Data Count') {
              valueCounts[foundindex] += 1;
            } else {
              valueCounts[foundindex] += getNumericValue(item[props.yAxisKeys.key]);
            }
          }
        });

        allPossibleWeeks.forEach((item, index) => {
          tempGeneratedData.push({
            [props.xAxisKeys.key]: item,
            [props.yAxisKeys.name]: valueCounts[index],
          });
        });
      }
    } else if (props.chartType === 'Grouped Chart') {
      props.xAxisKeys.possibleOptions.forEach((item: string) => {
        let chartDataObj: any = {
          [props.xAxisKeys.name]: item,
        };
        props.xAxisKeys.groupOn.options.forEach((groupEntity: string) => {
          chartDataObj = { ...chartDataObj, [groupEntity]: 0 };
        });

        // console.log('props.xAxisKeys.groupOn', chartDataObj, props.xAxisKeys.groupOn);
        // let yCount = 0;
        if (props.yAxisKeys.key === 'Data Count') {
          props.dataForChart.forEach((ele: any) => {
            if (ele[props.xAxisKeys.key] && ele[props.xAxisKeys.key].toLowerCase() === item.toLowerCase()) {
              props.xAxisKeys.groupOn.options.forEach((groupEntity: string) => {
                if (ele[props.xAxisKeys.groupOn.key] === groupEntity)
                  chartDataObj = { ...chartDataObj, [groupEntity]: chartDataObj[groupEntity] + 1 };
              });
            }
          });
          const isDataAlreadyPresent = tempGeneratedData.filter(
            (dataEle: any) => dataEle[props.xAxisKeys.name] === chartDataObj[props.xAxisKeys.name]
          );
          if (isDataAlreadyPresent.length == 0) {
            tempGeneratedData.push(chartDataObj);
          }
        } else {
          props.dataForChart.forEach((ele: any) => {
            console.log('dddddddddddddd', props.yAxisKeys.key, ele, ele[props.yAxisKeys.key]);

            if (ele[props.xAxisKeys.key] && ele[props.xAxisKeys.key].toLowerCase() === item.toLowerCase()) {
              props.xAxisKeys.groupOn.options.forEach((groupEntity: string) => {
                if (ele[props.xAxisKeys.groupOn.key] === groupEntity) {
                  chartDataObj = {
                    ...chartDataObj,
                    [groupEntity]: chartDataObj[groupEntity] + getNumericValue(ele[props.yAxisKeys.key]),
                  };
                }
              });
            }
          });
          const isDataAlreadyPresent = tempGeneratedData.filter(
            (dataEle: any) => dataEle[props.xAxisKeys.name] === chartDataObj[props.xAxisKeys.name]
          );
          if (isDataAlreadyPresent.length == 0) {
            tempGeneratedData.push(chartDataObj);
          }
        }
      });
    } else {
      props.xAxisKeys.possibleOptions.forEach((item: string) => {
        let yCount = 0;
        if (props.yAxisKeys.key === 'Data Count') {
          yCount = props.dataForChart.filter(
            (ele) => ele[props.xAxisKeys.key].toLowerCase() === item.toLowerCase()
          ).length;
        } else {
          props.dataForChart.forEach((ele: any) => {
            if (ele[props.xAxisKeys.key] && ele[props.xAxisKeys.key].toLowerCase() === item.toLowerCase()) {
              // const valToAdd: number = ele[props.yAxisKeys.key] != '' ? parseInt(ele[props.yAxisKeys.key]) : 0;
              // console.log('ddddddd', valToAdd, ele);
              yCount += getNumericValue(ele[props.yAxisKeys.key]);
            }
          });
        }
        tempGeneratedData.push({
          [props.xAxisKeys.key]: item,
          [props.yAxisKeys.name]: yCount,
        });
      });
    }

    setGeneratedData(displayDataWithCustomNames(tempGeneratedData));

    console.log('jiiiii', props.xAxisKeys, displayDataWithCustomNames(tempGeneratedData), tempGeneratedData);
  }, [props.dataForChart, props.xAxisKeys, props.yAxisKeys]);

  const handleUpdateGraph = () => {
    const data = {
      idName: props.idName,
      chartType: props.chartType,
      layout: props.layout,
      labelPosition: props.legendPosition,
      flipAxis: props.flipAxis,
      chartName: props.chartHeading,
      trendLine: props.barChartTrendline || false,
      colors: props.colors,
      xAxisKeys: props.xAxisKeys,
      yAxisKeys: props.yAxisKeys,
      project_chart_uid: props.project_chart_uid || '',
      case_chart_uid: props.case_chart_uid || '',
      task_chart_uid: props.task_chart_uid || '',
      entity_chart_uid: props.entity_chart_uid || '',
    };
    setDrawerState(true);
    setGraphData(data);
    setFormActionType('update');
  };
  const handleDeleteGraph = () => {
    dispatch(
      deleteGraphConfiguration({
        type: props.configType,
        project_chart_uid: props.project_chart_uid,
        case_chart_uid: props.case_chart_uid,
        task_chart_uid: props.task_chart_uid,
        entity_chart_uid: props.entity_chart_uid,
      })
    );
  };

  console.log('flipp', props.layout);

  return (
    <Stack
      sx={{
        border: '1px solid #E5E5E5',
        borderRadius: '4px',
        margin: '8px',
        maxWidth: '86vw',
      }}
    >
      {/* top bar */}
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        sx={{ borderBottom: '1px solid #E5E5E5' }}
      >
        <Typography variant={'h2'} sx={{ padding: '8px 16px' }}>
          {props.chartHeading}
        </Typography>
        <Stack direction={'row'} alignItems={'center'}>
          {/* all right buttons here like cancel, zoom etc */}
          <Button onClick={handleUpdateGraph}>Update</Button>
          <IconButton onClick={() => setOpenDeleteModal(true)}>
            <ClearIcon />
          </IconButton>
        </Stack>
      </Stack>
      <Stack
        sx={{
          background: 'white',
          margin: '40px 8px 8px 8px',
          height: '420px',
          maxHeight: '420px',
          overflowY: 'scroll',
          overflowX: 'scroll',
        }}
      >
        {/* chart wrapper */}
        <>
          {props.chartType === 'Bar Chart' ? (
            <BarChart
              data={generatedData}
              indexAxisKey={props.xAxisKeys.key}
              otherAxisKeys={[props.yAxisKeys.name]}
              // legend={props.legendPosition}
              legend={'none'}
              axisLeftLegend={props.yAxisKeys.name}
              axisBottomLegend={props.xAxisKeys.name}
              colors={props.colors}
              showTrendLine={props.barChartTrendline ? props.barChartTrendline : false}
              layout={props.layout}
              otherAxisDataKey={props.yAxisKeys.key}
            />
          ) : props.chartType === 'Donut Chart' ? (
            <DonutChart
              height={300}
              width={300}
              data={generatedData.filter((item) => item.manipulated_y !== 0)}
              dataConfig={{ idKey: props.xAxisKeys.key, dataKey: props.yAxisKeys.name }}
              colors={props.colors}
              legend={props.legendPosition}
              sx={{
                height: '175px',
                width: '175px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '50px',
              }}
              innerRadius={0.65}
            />
          ) : props.chartType === 'Grouped Chart' ? (
            <GorupBarChart
              // height={300}
              // width={300}
              data={generatedData}
              keys={Array.from(new Set(props.xAxisKeys.groupOn.options))}
              groupNameValueKey={props.xAxisKeys.name}
              xAxisName={props.xAxisKeys.name}
              yAxisName={props.yAxisKeys.name}
              yAxisKey={props.yAxisKeys.key}
              colors={props.colors}
              groupMode={props.xAxisKeys.groupOn.groupType}
              layout={props.layout}
              // dataConfig={{ idKey: props.xAxisKeys.key, dataKey: props.yAxisKeys.name }}
              // colors={props.colors}
              // legend={props.legendPosition}
              // sx={{
              //   height: '175px',
              //   width: '175px',
              //   display: 'flex',
              //   alignItems: 'center',
              //   justifyContent: 'center',
              //   marginTop: '50px',
              // }}
              // innerRadius={0.65}
            />
          ) : (
            <LineChart
              data={[
                {
                  id: props.yAxisKeys.name,
                  color: props.colors[0],
                  data: generatedData.map((item: any) => ({
                    x: item[props.xAxisKeys.key],
                    y: item[props.yAxisKeys.name],
                  })),
                },
              ]}
              legend={props.legendPosition}
              axisLeftLegend={props.yAxisKeys.name}
              axisBottomLegend={props.xAxisKeys.name}
              colors={props.colors}
              indexAxisKey={props.xAxisKeys.key}
              otherAxisDataKey={props.yAxisKeys.key}
            />
          )}
        </>
        <DeleteModal
          isOpen={openDeleteModal}
          setIsOpen={setOpenDeleteModal}
          message={'Are you sure you want to delete this chart?'}
          onDelete={handleDeleteGraph}
        />
      </Stack>
    </Stack>
  );
};

export default ChartBox;
