import { Grid, Box, Typography, Stack } from '@mui/material';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { selectCustomerDetail } from '../../../../store/modules/Common/selector';
import { getCustomerDetail } from 'store/modules/Common/slice';
import { useAppSelector } from '../../../../store/hooks';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import CognisaasButton from '../../../shared/CognisaasButton/CognisaasButton';
import { resetConfigurationUpdateState, updateUseCase_Configuration } from '../../../../store/modules/Settings/slice';
import CognisaasToast from 'components/shared/CognisaasToast/CognisaasToast';
import { selectConfigurationUpdateState } from 'store/modules/Settings/selector';
import { requestState } from 'store/modules/types';
import { label_project, label_task } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { setProjectTaskdashboardView } from 'utils/viewHelper';
import CustomProjectCategory from './Project/CustomProjectCategory';
import CustomProjectStage from './Project/CustomProjectStage';
import CustomProjectPriority from './Project/CustomProjectPriority';
import SwitchBadge from '../SwitchBadge';
import { isSuperUser } from '../../../../utils/getUid';

const Project = () => {
  const dispatch = useDispatch();
  const [projectProgress, setprojectProgress] = useState<boolean>(false);
  const [radioValue, setRadioValue] = useState<string>('0');
  const [planView, setPlanView] = useState<string | '0' | '1' | '2'>('0');
  const [radioPreviousValue, setRadioPreviousValue] = useState('0');
  const [customerDetails, setcustomerDetails] = useState<any>();
  const [freezePlannedDate, setFreezePlannedDate] = useState(false);

  const customerDetail = useAppSelector(selectCustomerDetail); // contains ticked values
  const [payloadObj, setPayloadObj] = useState({
    client_mail: '',
    client_task_ind: '',
    currency_code: '',
    customer_amber_threshold: 0,
    customer_uid: '',
    renewal_notification: '',
    project_progress_indicator: '',
    subtask_enable: '',
    task_deletion_mail: '',
    task_dependency_date_updation: '',
    task_inprogress_indicator: '',
    task_mail: '',
    task_mail_copy: '',
    task_status_account_owner_mail: '',
    task_status_mail: '',
    task_status_client_contact_mail: '',
    task_status_manager_mail: '',
    time_track: '',
    usecase_calc_ind: '',
    task_assign_mail_dm_copy: '',
    task_deletion_delivery_manager_mail: '',
    status_report_client: '',
    primary_contact_as_default_owner: '',
    delivery_manager_as_default_owner: '',
    client_contact_added_mail: '0',
    project_plan_view: '',
    usecase_plan_view: '',
    freeze_planned_date_project: true,
    freeze_planned_date_usecase: true,
  });
  const configurationUpdateStatus = useAppSelector(selectConfigurationUpdateState);
  const [alertDetails, setAlertDetails] = useState<{
    isVisible: boolean;
    type: 'success' | 'failure';
    message: string;
  }>({ isVisible: false, type: 'success', message: '' });

  // for alerts
  useEffect(() => {
    if (configurationUpdateStatus.status === requestState.success) {
      setAlertDetails({ isVisible: true, type: 'success', message: 'Updated Successfully!' });
      dispatch(resetConfigurationUpdateState());
    }
  }, [configurationUpdateStatus]);

  useEffect(() => {
    dispatch(getCustomerDetail());
  }, []);
  useEffect(() => {
    if (customerDetail != null) setcustomerDetails(customerDetail);
  }, [customerDetail]);

  useEffect(() => {
    if (customerDetails != null) {
      setprojectProgress(customerDetails.project_progress_indicator == '0' ? false : true);
      setRadioPreviousValue(customerDetails.project_progress_indicator);
      setRadioValue(customerDetails.project_progress_indicator);
      setPlanView(customerDetails.project_plan_view);
      setFreezePlannedDate(customerDetails.freeze_planned_date_project == '1');
      setPayloadObj({
        client_mail: customerDetails.client_mail,
        client_task_ind: customerDetails.client_task_ind,
        currency_code: customerDetails.currency_code,
        customer_amber_threshold: 100 - customerDetails.customer_amber_threshold,
        customer_uid: customerDetails.customer_uid,
        project_progress_indicator: customerDetails.project_progress_indicator,
        renewal_notification: customerDetails.renewal_notification,
        subtask_enable: customerDetails.subtask_enable,
        task_deletion_mail: customerDetails.task_deletion_mail,
        task_dependency_date_updation: customerDetails.task_dependency_date_updation,
        task_inprogress_indicator: customerDetails.task_inprogress_indicator,
        task_mail: customerDetails.task_mail,
        task_mail_copy: customerDetails.task_mail_copy,
        task_status_account_owner_mail: customerDetails.task_status_accountowner_mail,
        task_status_mail: customerDetails.task_status_mail,
        task_status_client_contact_mail: customerDetails.task_status_client_contact_mail,
        task_status_manager_mail: customerDetails.task_status_manager_mail,
        time_track: customerDetails.time_track,
        usecase_calc_ind: customerDetails.usecase_calc_ind,
        task_assign_mail_dm_copy: customerDetails.task_assign_mail_dm_copy,
        task_deletion_delivery_manager_mail: customerDetails.task_deletion_delivery_manager_mail,
        status_report_client: customerDetails.status_report_client,
        primary_contact_as_default_owner: customerDetails.primary_contact_as_default_owner,
        delivery_manager_as_default_owner: customerDetails.delivery_manager_as_default_owner,
        client_contact_added_mail: customerDetails.client_contact_added_mail,
        project_plan_view: customerDetails.project_plan_view,
        usecase_plan_view: customerDetails.usecase_plan_view,
        freeze_planned_date_project: customerDetails.freeze_planned_date_project,
        freeze_planned_date_usecase: customerDetails.freeze_planned_date_usecase,
      });
    }
  }, [customerDetails]);

  const onClickUpdateButton = () => {
    let finalObj = { ...payloadObj };
    finalObj = {
      ...payloadObj,
      ['project_progress_indicator']: radioValue,
      project_plan_view: planView,
      usecase_plan_view: planView,
    };
    setprojectProgress(!projectProgress);
    dispatch(updateUseCase_Configuration(finalObj));
    if (customerDetails?.project_plan_view != planView) {
      const taskView = planView == '0' ? 'table' : planView == '1' ? 'plan' : 'resourse';
      setProjectTaskdashboardView(taskView);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioPreviousValue(radioValue);
    setRadioValue((event.target as HTMLInputElement).value);
  };

  const handlePlanViewChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // setRadioPreviousValue(radioValue);
    setPlanView((event.target as HTMLInputElement).value);
  };

  const onClickCancelButton = () => {
    setRadioValue(radioPreviousValue);
    console.log('onclick Cancel');
  };

  const onChickSwitch = async () => {
    let finalObj = { ...payloadObj };
    finalObj = {
      ...payloadObj,
      freeze_planned_date_project: !freezePlannedDate,
    };
    setprojectProgress(!projectProgress);
    setFreezePlannedDate(!freezePlannedDate);
    await dispatch(updateUseCase_Configuration(finalObj));
    await dispatch(getCustomerDetail());
  };

  return (
    <Box sx={{ margin: '28px 20px 20px 20px', width: '65%' }}>
      <Grid sx={{ marginTop: '5px' }}>
        <Box>
          <Typography style={{ marginBottom: '20px' }} variant={'h2'}>
            Calculation of &quot;{label_project} Progress&quot; should be based on
          </Typography>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            name="radio-buttons-group"
            value={radioValue}
            onChange={handleChange}
            sx={{ marginLeft: '10px' }}
          >
            <FormControlLabel
              sx={{ marginTop: '10px' }}
              value="0"
              control={<Radio size="small" />}
              label={
                <Typography
                  variant={'subtitle1'}
                  color={radioValue == '0' ? '#2D60F6' : '#7A7A7A'}
                  sx={{ marginLeft: '6px' }}
                >
                  % of {label_task}s Completed
                </Typography>
              }
            />
            <FormControlLabel
              sx={{ marginTop: '10px' }}
              value="1"
              control={<Radio size="small" />}
              label={
                <Typography
                  variant={'subtitle1'}
                  color={radioValue == '1' ? '#2D60F6' : '#7A7A7A'}
                  sx={{ marginLeft: '6px' }}
                >
                  % of Total Time Consumed
                </Typography>
              }
            />
          </RadioGroup>
        </Box>

        <Box style={{ marginTop: '20px' }}>
          <Typography style={{ marginBottom: '10px' }} variant={'h2'}>
            Default &quot;{label_project} Plan&quot; view should be
          </Typography>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            name="radio-buttons-group"
            value={planView}
            onChange={handlePlanViewChange}
            sx={{ marginLeft: '10px' }}
          >
            <FormControlLabel
              sx={{ marginTop: '10px' }}
              value="0"
              control={<Radio size="small" />}
              label={
                <Typography
                  variant={'subtitle1'}
                  color={planView == '0' ? '#2D60F6' : '#7A7A7A'}
                  sx={{ marginLeft: '6px' }}
                >
                  List View
                </Typography>
              }
            />
            <FormControlLabel
              sx={{ marginTop: '10px' }}
              value="1"
              control={<Radio size="small" />}
              label={
                <Typography
                  variant={'subtitle1'}
                  color={planView == '1' ? '#2D60F6' : '#7A7A7A'}
                  sx={{ marginLeft: '6px' }}
                >
                  Kanban View
                </Typography>
              }
            />
            <FormControlLabel
              sx={{ marginTop: '10px' }}
              value="2"
              control={<Radio size="small" />}
              label={
                <Typography
                  variant={'subtitle1'}
                  color={planView == '2' ? '#2D60F6' : '#7A7A7A'}
                  sx={{ marginLeft: '6px' }}
                >
                  Gantt View
                </Typography>
              }
            />
          </RadioGroup>
        </Box>

        <Grid sx={{ margin: '24px 0' }}>
          <CognisaasButton
            label={'Update'}
            isOutlined={false}
            clickHandler={onClickUpdateButton}
            sx={{ marginRight: '20px' }}
          />
          <CognisaasButton label={'Cancel'} isOutlined={true} clickHandler={onClickCancelButton} />
        </Grid>
        {isSuperUser() && (
          <SwitchBadge
            text={'Freeze Planned Date'}
            checked={freezePlannedDate}
            handleChange={onChickSwitch}
            margin={'0px 16px 16px 0px'}
          />
        )}

        <Stack style={{ marginTop: '24px', width: '480px' }}>
          <Typography variant="h6">Custom {label_project.toLowerCase()} category names</Typography>
          <CustomProjectCategory />
        </Stack>

        <Stack style={{ marginTop: '24px', width: '480px' }}>
          <Typography variant="h6">Custom {label_project.toLowerCase()} stage names</Typography>
          <CustomProjectStage />
        </Stack>

        <Stack style={{ marginTop: '24px', width: '480px' }}>
          <Typography variant="h6">Custom {label_project.toLowerCase()} priority names</Typography>
          <CustomProjectPriority />
        </Stack>
      </Grid>

      {/* this is new alerts */}
      <CognisaasToast
        open={alertDetails.isVisible}
        onClose={() => setAlertDetails({ isVisible: false, type: alertDetails.type, message: '' })}
        message={alertDetails.message}
        type={alertDetails.type}
      />
    </Box>
  );
};
export default Project;
