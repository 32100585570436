import React, { ReactNode } from 'react';
import { ViewMode } from 'components/ResourceManagement/types/public-types';
import { TopPartOfCalendar } from './top-part-of-calendar';
import {
  getLocalDayOfWeek,
  getLocaleMonth,
  getWeekNumberISO8601,
} from 'components/ResourceManagement/helpers/date-helper';
import { DateSetup } from 'components/ResourceManagement/types/date-setup';
import styles from './calendar.module.css';
import styled from 'styled-components';
import { SvgRect, SvgText } from 'components/ResourceManagement/design-system/primitives';
import { getIsCurrentDateLastOne } from 'components/ResourceManagement/utils/date';

export type CalendarProps = {
  dateSetup: DateSetup;
  locale: string;
  viewMode: ViewMode;
  headerHeight: number;
  columnWidth: number;
  svgWidth: number,
};

const StyledSvgLine = styled('line')`
    stroke: ${({ theme }) => theme.colors.grey_3};
    stroke-width: 1;
`;

const StyledBottomValue = styled(SvgText)`
  text-anchor: middle;
  fill: ${({ theme }) => theme.colors.grey_1};
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
`;

const StyledTodayRect = styled(SvgRect)(({ theme }) => {
  return {
    fill: `${theme.colors.brand_gradient_1}`,
  };
});

export const Calendar: React.FC<CalendarProps> = ({
  dateSetup,
  locale,
  headerHeight,
  columnWidth,
  svgWidth
}) => {

  const rowLines: ReactNode[] = [
    <StyledSvgLine
      key='RowLineFirst'
      x1={0}
      y1={0}
      x2={svgWidth}
      y2={0}
      className={styles.gridRowLine}
    />,
  ];
  let today: ReactNode = <rect />;
  const now = new Date();

  const getCalendarValuesForMonth = () => {
    const topValues: ReactNode[] = [];
    const bottomValues: ReactNode[] = [];
    const topDefaultHeight = headerHeight * 0.5;

    for (let i = 0; i < dateSetup.dates.length; i++) {
      const date = dateSetup.dates[i];
      const bottomValue = getLocaleMonth(date, locale);
      bottomValues.push(
        <StyledBottomValue
          key={bottomValue + date.getFullYear()}
          y={headerHeight * 0.8}
          x={columnWidth * i + columnWidth * 0.5}
          variant="paragraph1"
        >
          {bottomValue}
        </StyledBottomValue>
      );

      const isCurrentDateLastOne = getIsCurrentDateLastOne(i, date, dateSetup.dates);
      if (
        (i + 1 !== dateSetup.dates.length &&
          date.getTime() < now.getTime() &&
          dateSetup.dates[i + 1].getTime() >= now.getTime()) ||
        isCurrentDateLastOne
      ) {
        today = (
          <StyledTodayRect
            x={columnWidth * (i)}
            y={0}
            width={columnWidth}
            height={70}
          />
        );
      }

      if (
        i === 0 ||
        date.getFullYear() !== dateSetup.dates[i - 1].getFullYear()
      ) {
        const topValue = date.getFullYear().toString();
        const xText: number = (6 + i - date.getMonth()) * columnWidth;
        topValues.push(
          <TopPartOfCalendar
            key={topValue}
            value={topValue}
            x1Line={columnWidth * i}
            y1Line={0}
            y2Line={topDefaultHeight}
            xText={xText}
            yText={topDefaultHeight * 0.9}
          />
        );
      }
    }
    return [topValues, bottomValues];
  };

  const getCalendarValuesForWeek = () => {
    const topValues: ReactNode[] = [];
    const bottomValues: ReactNode[] = [];
    let weeksCount = 1;
    const topDefaultHeight = headerHeight * 0.5;
    const dates = dateSetup.dates;

    for (let i = dates.length - 1; i >= 0; i--) {
      const date = dates[i];
      let topValue = '';
      if (i === 0 || date.getMonth() !== dates[i - 1].getMonth()) {
        // top
        topValue = `${getLocaleMonth(date, locale)}, ${date.getFullYear()}`;
      }
      // bottom
      const bottomValue = `W${getWeekNumberISO8601(date)}`;

      const isCurrentDateLastOne = getIsCurrentDateLastOne(i, date, dates);
      if (
        (i + 1 !== dates.length &&
          date.getTime() < now.getTime() &&
          dates[i + 1].getTime() >= now.getTime()) ||
        isCurrentDateLastOne
      ) {
        today = (
          <StyledTodayRect
            x={columnWidth * (i)}
            y={0}
            width={columnWidth}
            height={70}
          />
        );
      }

      const x = columnWidth * i + columnWidth / 2;

      bottomValues.push(
        <StyledBottomValue
          key={date.getTime()}
          y={headerHeight * 0.8}
          x={x}
          variant="paragraph1"
        >
          {bottomValue}
        </StyledBottomValue>
      );

      if (topValue) {
        // if last day is new month
        if (i !== dates.length - 1) {
          topValues.push(
            <TopPartOfCalendar
              key={topValue}
              value={topValue}
              x1Line={columnWidth * i + weeksCount * columnWidth}
              y1Line={0}
              y2Line={topDefaultHeight}
              xText={columnWidth * i + columnWidth * weeksCount * 0.5}
              yText={topDefaultHeight * 0.9}
            />
          );
        }
        weeksCount = 0;
      }
      weeksCount++;
    }
    return [topValues, bottomValues];
  };

  let tickX = 0;
  const ticks: ReactNode[] = [];

  const getCalendarValuesForDay = () => {
    const topValues: ReactNode[] = [];
    const bottomValues: ReactNode[] = [];
    const dates = dateSetup.dates;

    for (let i = 0; i < dates.length; i++) {
      const date = dates[i];
      const monthValue = `${getLocaleMonth(date, locale)}, ${date.getFullYear()}`;
      const bottomValue = `${getLocalDayOfWeek(date, locale, 'short')}, ${date
        .getDate()
        .toString()}`;
      const splittedBottomVal = bottomValue.split(' ');
      const dayName = splittedBottomVal[0].replace(/[,]/, '');
      const dayNum = splittedBottomVal[1];
      const isCurrentDateLastOne = getIsCurrentDateLastOne(i, date, dates);
      if (
        (i + 1 !== dates.length &&
          date.getTime() < now.getTime() &&
          dates[i + 1].getTime() >= now.getTime()) ||
        isCurrentDateLastOne
      ) {
        today = (
          <StyledTodayRect
            x={tickX}
            y={0}
            width={columnWidth}
            height={70}
          />
        );
      }

      ticks.push(
        <StyledSvgLine
          key={`tick-${date.getTime()}`}
          x1={tickX}
          y1={0}
          x2={tickX}
          y2={headerHeight}
          className={styles.gridTick}
        />
      );

      bottomValues.push(
        <g key={date.getTime()}>
          <StyledBottomValue
            key={`${date.getTime()}-${i}-name`}
            y={headerHeight * 0.4}
            x={columnWidth * i + columnWidth * 0.5}
            variant="paragraph1"
          >
            {monthValue}
          </StyledBottomValue>
          <StyledBottomValue
            key={`${date.getTime()}-${i}-num`}
            y={headerHeight * 0.8}
            x={columnWidth * i + columnWidth * 0.5}
            variant="paragraph1"
          >
            {`${dayName} / ${dayNum}`}
          </StyledBottomValue>
        </g>
      );
      tickX += columnWidth;
    }
    return [topValues, bottomValues];
  };

  let topValues: ReactNode[] = [];
  let bottomValues: ReactNode[] = [];
  switch (dateSetup.viewMode) {
    case ViewMode.Month:
      [topValues, bottomValues] = getCalendarValuesForMonth();
      break;
    case ViewMode.Week:
      [topValues, bottomValues] = getCalendarValuesForWeek();
      break;
    case ViewMode.Day:
      [topValues, bottomValues] = getCalendarValuesForDay();
      break;
  }
  return (
    <g>
      <g className='calendar'>
        <rect
          x={0}
          y={0}
          width={columnWidth * dateSetup.dates.length}
          height={headerHeight}
          className={styles.calendarHeader}
        />
        {topValues}
        {bottomValues}
      </g>
      <g className='today'>{today}</g>
      <g className="gridRowLine">{rowLines}</g>
      <g className="gridTick">{ticks}</g>
    </g>
  );
};
