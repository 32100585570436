import TemplateOverviewSection from '../../Shared/TemplateOverviewSection';
import { useEffect, useState } from 'react';
import {
  // addTemplateProject,
  // getAllSectionByTemplateProjectuid,
  getAllSectionByTemplateCaseuid,
  // getProjectTemplates,
  // updateTemplateProjectByUid,
  // updateTemplateUsecaseTaskbyuid,
  addTemplateUseCase,
  getUseCaseTemplatesbycustomer,
  updateTemplateUsecaseByUid,
  toggleCreateUsecaseTemplateExcelViewSelectedState,
} from 'store/modules/Templates/Templates';
// import { getCustomerDetail } from 'store/modules/Common/slice';
// import { selectCustomerDetail } from 'store/modules/Common/selector';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { getCustomerCompanyName } from 'utils/getUid';
import { Typography, Grid, Stack, ButtonGroup } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useNavigate } from 'react-router-dom';
import CognisaasTabs from 'components/shared/CognisaasTabs';
import { label_use_case } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { usecaseTemplateTabs } from 'components/Templates/TemplatesIndex/types';
import { ReactComponent as TableViewUnselected } from '../../../../../../assets/svgs/table_view_unselected.svg';
import { ReactComponent as TableViewSelected } from '../../../../../../assets/svgs/table_view_unselected.svg';
import { ReactComponent as KanbanViewUnselected } from '../../../../../../assets/svgs/kanban_view_unselected.svg';
import { ReactComponent as KanbanViewSelected } from '../../../../../../assets/svgs/kanban_view_selected.svg';
// import AddProjectTemplatePlanSection from './ProjectTemplatePlan/AddProjectTemplatePlanSection';
import {
  selectCreateUsecaseTemplateExcelData,
  selectToggleCreateUsecaseTemplateExcelViewSelectedState,
  // selectAllSectionTemplates,
  selectusecaseTemplateSections,
  // selectuseCaseTemplateSectionSequenceNo,
} from 'store/modules/Templates/selector';
import { Button } from '@mui/material';
import ProductTemplate from 'components/Templates/ProductRequirementsMapping/ProductTemplate';
import { updateUsecaseTemplate } from './helper';
// import UpdateUsecaseTemplate from 'components/Templates/UpdateUsecaseTemplate';
import AddProjectTemplatePlanSection from '../../Project/AddProjectTemplate/ProjectTemplatePlan/AddProjectTemplatePlanSection';
import AddUsecaseTemplatePlanExcel from '../../Project/AddProjectTemplate/ProjectTemplatePlanExcel/AddUsecaseTemplatePlanExcel';
import { addPlanFromExcelUsecase } from '../../Shared/addPlanFromExcel';
// import { updateProjectTemplate } from './helper';

const AddUsecaseTemplateIndex = () => {
  // const customerDetail = useAppSelector(selectCustomerDetail);
  const projectTemplateSections = useAppSelector(selectusecaseTemplateSections);
  const projectTemplateExcelData = useAppSelector(selectCreateUsecaseTemplateExcelData);
  const isExcelViewSelected = useAppSelector(selectToggleCreateUsecaseTemplateExcelViewSelectedState);

  // const useCaseTemplateSectionSequenceNoApi = useAppSelector(selectuseCaseTemplateSectionSequenceNo);
  const [selectedFeatures, setSelectedFeatures] = useState<any>([]);
  const [selectedProject, setSelectedProject] = useState<any>([]);
  const [newFeatureObjList, setNewFeatureobjList] = useState([]);
  const [newTemplateUid, setNewTemplateUid] = useState<string | null>(null);
  const [selectedTab, setSelectedTab] = useState<string>(usecaseTemplateTabs[0]);
  // Overview form state
  const [templateName, setTemplateName] = useState<string>('');
  const [templateOwner, setTemplateOwner] = useState<any>('Not Assigned');
  const [templateTargetDays, setTemplateTargetDays] = useState<number>(0);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const getTemplateUid = async () => {
    const template = await dispatch(
      addTemplateUseCase({
        template_owner_name: getCustomerCompanyName(),
        check_duration: 0,
        template_case_deliverymanager: 'Not Assigned',
      })
    ).unwrap();
    console.log(template.result);
    if (template.result) {
      setNewTemplateUid(template.result);
    }
    dispatch(getAllSectionByTemplateCaseuid(template.result));
  };

  useEffect(() => {
    getTemplateUid();
  }, []);

  useEffect(() => {
    const tempNewFeatureList: any = [];
    if (selectedFeatures && selectedFeatures.length > 0) {
      selectedFeatures.map((feEle: any) => {
        tempNewFeatureList.push({
          dependency_case_feature_config: '',
          feature_checked_status: true,
          feature_creaded_on: feEle.feature_creaded_on,
          feature_description: feEle.feature_description,
          feature_estimateddeliverydate: feEle.feature_estimateddeliverydate,
          feature_id: feEle.feature_id,
          feature_issue_type_name: feEle.feature_issue_type_name,
          feature_modified_on: feEle.feature_modified_on,
          feature_readinessstate_status: feEle.feature_readinessstate_status,
          feature_uid: feEle.feature_uid,
          product_issue_id: feEle.product_issue_id,
          product_uid: feEle.product_uid,
        });
      });
    }
    setNewFeatureobjList(tempNewFeatureList);
  }, [selectedFeatures]);

  const handleSubmitUsecase = async () => {
    if (isExcelViewSelected) {
      console.log('Create plan');
      const payload = {
        templateName,
        templateOwner,
        sections: [],
        features: [...newFeatureObjList],
        templateTargetDays,
        newTemplateUid,
      };
      const postData = updateUsecaseTemplate(payload);
      console.log(postData);
      await dispatch(updateTemplateUsecaseByUid(postData));
      await addPlanFromExcelUsecase(
        newTemplateUid as string,
        templateName,
        templateOwner,
        [],
        0,
        projectTemplateExcelData
      );
      dispatch(toggleCreateUsecaseTemplateExcelViewSelectedState());
      navigate('/templates');
    } else {
      console.log(templateName, templateOwner, templateTargetDays);
      const sections = await dispatch(getAllSectionByTemplateCaseuid(newTemplateUid)).unwrap();
      console.log(templateName, templateOwner, [...sections], templateTargetDays, newTemplateUid);
      const payload = {
        templateName,
        templateOwner,
        sections: [...sections],
        features: [...newFeatureObjList],
        templateTargetDays,
        newTemplateUid,
      };
      const postData = updateUsecaseTemplate(payload);
      console.log(postData);
      await dispatch(updateTemplateUsecaseByUid(postData));
      await dispatch(getUseCaseTemplatesbycustomer());
      navigate('/templates');
    }
  };

  return (
    <>
      <Stack ml={2} my={2}>
        <Typography sx={{ fontSize: '12px', fontWeight: '400', ml: '25px', color: '#2D60F6' }}>Templates</Typography>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Stack direction="row">
              <ArrowBackIosNewIcon
                onClick={() => navigate(-1)}
                fontSize="small"
                sx={{ mt: '5px', cursor: 'pointer' }}
              />
              <Typography sx={{ fontSize: '20px', fontWeight: '500', ml: '5px' }}>{label_use_case} Template</Typography>
            </Stack>
          </Grid>
          <Grid item>
            <Button
              disableRipple
              disabled={templateTargetDays < 0 || templateName?.trim() == '' ? true : false}
              variant="contained"
              onClick={() => {
                handleSubmitUsecase();
                // setStateDrawerOpen(false);
              }}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Stack>
      <Stack ml={2} direction="row" justifyContent={'space-between'}>
        <CognisaasTabs
          size="squeezed"
          tabs={[...usecaseTemplateTabs]}
          handleTabSwitch={(e: any, index: number) => setSelectedTab(usecaseTemplateTabs[index])}
        />
        <Stack direction={'row'}>
          {/* {tabIndex == 1 && ( */}
          <Stack>
            <ButtonGroup sx={{ backgroundColor: 'white' }} variant="outlined" aria-label="outlined button group">
              <Button
                className={`${isExcelViewSelected ? 'active-btn' : null} `}
                onClick={() => {
                  dispatch(toggleCreateUsecaseTemplateExcelViewSelectedState());
                }}
              >
                {console.log(isExcelViewSelected)}
                {isExcelViewSelected ? <TableViewSelected /> : <TableViewUnselected />}
              </Button>
              <Button
                className={`${!isExcelViewSelected ? 'active-btn' : null} `}
                onClick={() => {
                  dispatch(toggleCreateUsecaseTemplateExcelViewSelectedState());
                }}
              >
                {isExcelViewSelected ? <KanbanViewSelected /> : <KanbanViewUnselected />}
              </Button>
            </ButtonGroup>
          </Stack>
        </Stack>
      </Stack>
      <Stack ml={2} my={2}>
        {selectedTab == usecaseTemplateTabs[0] ? (
          <TemplateOverviewSection
            userPermissions={['view', 'edit', 'delete']}
            name={templateName}
            owner={templateOwner}
            days={templateTargetDays}
            newTemplateName={(name: string) => setTemplateName(name)}
            newTemplateOwner={(name: any) => {
              setTemplateOwner(name);
              console.log(name);
            }}
            newTemplateTargetDays={(days: any) => setTemplateTargetDays(days)}
          />
        ) : selectedTab == usecaseTemplateTabs[1] ? (
          <>
            {!isExcelViewSelected ? (
              <AddProjectTemplatePlanSection
                type="usecase"
                projectTemplateUid={newTemplateUid}
                sectionsData={projectTemplateSections}
              />
            ) : (
              <AddUsecaseTemplatePlanExcel />
            )}
          </>
        ) : selectedTab == usecaseTemplateTabs[2] ? (
          <ProductTemplate
            selectedFeatures={selectedFeatures}
            setSelectedFeatures={setSelectedFeatures}
            selectedProject={selectedProject}
            setSelectedProject={setSelectedProject}
          />
        ) : null}
      </Stack>
    </>
  );
};

export default AddUsecaseTemplateIndex;
