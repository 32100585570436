import CognisaasDropdown from '../CognisaasDropdown';
import MenuItem from '@mui/material/MenuItem';
// import { ReactComponent as InProgress } from '../../../../assets/svgs/progressor.svg';
// import { ReactComponent as NormalTaskNotStartedStage } from '../../../../assets/svgs/NormalTaskNotStartedStage.svg';
// import { ReactComponent as NormalTaskDoneStage } from '../../../../assets/svgs/NormalTaskDoneStage.svg';
import CircleIcon from '@mui/icons-material/Circle';

/*
Note that in changeHandler manage you will get an event on which you can get value as
e.target.value => gives three values  'red' or 'green' or 'amber'

red => new requirement
amber => In progress
green => released

how to use :-

function handlingChange(e) {
  console.log(e.target.value); // can be 'red' or 'amber' or 'green'
  // set state or update in db whatever you want to do with value
}

IN JSX:
  <CognisaasRequirementStatusDropdown value={'green'} changeHandler={handlingChange} />

*/

interface Props {
  value: any;
  changeHandler: (event: any) => void;
  sx?: any;
  placeholder?: string;
  name?: any;
  readOnly?: boolean;
}

const CognisaasRequirementStatusDropdown = (receivedProps: Props) => {
  const dropdownRestProps: any = { ...receivedProps };
  delete dropdownRestProps.value;
  delete dropdownRestProps.changeHandler;

  // console.log(dropdownRestProps);

  return (
    <CognisaasDropdown
      {...dropdownRestProps}
      value={receivedProps.value}
      onChange={receivedProps.changeHandler}
      size="small"
      readOnly={receivedProps.readOnly}
    >
      <MenuItem value={'amber'}>
        <span style={{ display: 'inline-flex', alignItems: 'center' }}>
          <CircleIcon fontSize="small" sx={{ color: '#EFA958' }} />
          <span>&nbsp;&nbsp;In Progress</span>
        </span>
      </MenuItem>
      <MenuItem value={'green'}>
        <span style={{ display: 'inline-flex', alignItems: 'center' }}>
          <CircleIcon fontSize="small" sx={{ color: '#4F9F52' }} />
          <span>&nbsp;&nbsp;Released</span>
        </span>
      </MenuItem>
      <MenuItem value={'red'}>
        <span style={{ display: 'inline-flex', alignItems: 'center' }}>
          <CircleIcon fontSize="small" sx={{ color: '#E75B5C' }} />
          <span>&nbsp;&nbsp;New Requirement</span>
        </span>
      </MenuItem>
    </CognisaasDropdown>
  );
};

export default CognisaasRequirementStatusDropdown;
