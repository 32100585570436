import { usecaseEntityStandardFieldsDisplayName } from '../Config/EntityDisplayNames';
import { usecaseEntityStandardFieldsKey } from '../Config/EntityKeys';
import { usecaseEntityStandardFieldsAggregatable } from '../Config/EntityAggregatable';
import { usecaseEntityStandardFieldsType } from '../Config/EntityTypes';
import { getAllowedOperations } from '../UtilityFunction';

export const usecaseEntityStandardFields = {
  case_name: {
    displayName: usecaseEntityStandardFieldsDisplayName.name,
    key: usecaseEntityStandardFieldsKey.name,
    type: usecaseEntityStandardFieldsType.name,
    isAggregatable: usecaseEntityStandardFieldsAggregatable.name,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(usecaseEntityStandardFieldsType.name),
    toAggregate: false,
    aggregationToPerform: null,
  },
  case_progress: {
    displayName: usecaseEntityStandardFieldsDisplayName.progress,
    key: usecaseEntityStandardFieldsKey.progress,
    type: usecaseEntityStandardFieldsType.progress,
    isAggregatable: usecaseEntityStandardFieldsAggregatable.progress,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(usecaseEntityStandardFieldsType.progress),
    toAggregate: false,
    aggregationToPerform: null,
  },
  case_description: {
    displayName: usecaseEntityStandardFieldsDisplayName.description,
    key: usecaseEntityStandardFieldsKey.description,
    type: usecaseEntityStandardFieldsType.description,
    isAggregatable: usecaseEntityStandardFieldsAggregatable.description,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(usecaseEntityStandardFieldsType.description),
    toAggregate: false,
    aggregationToPerform: null,
  },
  case_fees: {
    displayName: usecaseEntityStandardFieldsDisplayName.fee,
    key: usecaseEntityStandardFieldsKey.fee,
    type: usecaseEntityStandardFieldsType.fee,
    isAggregatable: usecaseEntityStandardFieldsAggregatable.fee,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(usecaseEntityStandardFieldsType.fee),
    toAggregate: false,
    aggregationToPerform: null,
  },
  case_reference: {
    displayName: usecaseEntityStandardFieldsDisplayName.reference,
    key: usecaseEntityStandardFieldsKey.reference,
    type: usecaseEntityStandardFieldsType.reference,
    isAggregatable: usecaseEntityStandardFieldsAggregatable.reference,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(usecaseEntityStandardFieldsType.reference),
    toAggregate: false,
    aggregationToPerform: null,
  },
  case_needed_status: {
    displayName: usecaseEntityStandardFieldsDisplayName.neededStatus,
    key: usecaseEntityStandardFieldsKey.neededStatus,
    type: usecaseEntityStandardFieldsType.neededStatus,
    isAggregatable: usecaseEntityStandardFieldsAggregatable.neededStatus,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(usecaseEntityStandardFieldsType.neededStatus),
    toAggregate: false,
    aggregationToPerform: null,
  },
  case_readinessstate_status: {
    displayName: usecaseEntityStandardFieldsDisplayName.status,
    key: usecaseEntityStandardFieldsKey.status,
    type: usecaseEntityStandardFieldsType.status,
    isAggregatable: usecaseEntityStandardFieldsAggregatable.status,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(usecaseEntityStandardFieldsType.status),
    toAggregate: false,
    aggregationToPerform: null,
  },
  case_expansionvalue: {
    displayName: usecaseEntityStandardFieldsDisplayName.expansionValue,
    key: usecaseEntityStandardFieldsKey.expansionValue,
    type: usecaseEntityStandardFieldsType.expansionValue,
    isAggregatable: usecaseEntityStandardFieldsAggregatable.expansionValue,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(usecaseEntityStandardFieldsType.expansionValue),
    toAggregate: false,
    aggregationToPerform: null,
  },
  case_requiredbydate: {
    displayName: usecaseEntityStandardFieldsDisplayName.requiredByDate,
    key: usecaseEntityStandardFieldsKey.requiredByDate,
    type: usecaseEntityStandardFieldsType.requiredByDate,
    isAggregatable: usecaseEntityStandardFieldsAggregatable.requiredByDate,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(usecaseEntityStandardFieldsType.requiredByDate),
    toAggregate: false,
    aggregationToPerform: null,
  },
  case_priority: {
    displayName: usecaseEntityStandardFieldsDisplayName.priority,
    key: usecaseEntityStandardFieldsKey.priority,
    type: usecaseEntityStandardFieldsType.priority,
    isAggregatable: usecaseEntityStandardFieldsAggregatable.priority,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(usecaseEntityStandardFieldsType.priority),
    toAggregate: false,
    aggregationToPerform: null,
  },
  case_planned_startdate: {
    displayName: usecaseEntityStandardFieldsDisplayName.plannedStartDate,
    key: usecaseEntityStandardFieldsKey.plannedStartDate,
    type: usecaseEntityStandardFieldsType.plannedStartDate,
    isAggregatable: usecaseEntityStandardFieldsAggregatable.plannedStartDate,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(usecaseEntityStandardFieldsType.plannedStartDate),
    toAggregate: false,
    aggregationToPerform: null,
  },
  case_startdate: {
    displayName: usecaseEntityStandardFieldsDisplayName.startDate,
    key: usecaseEntityStandardFieldsKey.startDate,
    type: usecaseEntityStandardFieldsType.startDate,
    isAggregatable: usecaseEntityStandardFieldsAggregatable.startDate,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(usecaseEntityStandardFieldsType.startDate),
    toAggregate: false,
    aggregationToPerform: null,
  },
  eta_forcasedelivery: {
    displayName: usecaseEntityStandardFieldsDisplayName.etaForDelivery,
    key: usecaseEntityStandardFieldsKey.etaForDelivery,
    type: usecaseEntityStandardFieldsType.etaForDelivery,
    isAggregatable: usecaseEntityStandardFieldsAggregatable.etaForDelivery,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(usecaseEntityStandardFieldsType.etaForDelivery),
    toAggregate: false,
    aggregationToPerform: null,
  },
  case_deliverydate: {
    displayName: usecaseEntityStandardFieldsDisplayName.deliveryDate,
    key: usecaseEntityStandardFieldsKey.deliveryDate,
    type: usecaseEntityStandardFieldsType.deliveryDate,
    isAggregatable: usecaseEntityStandardFieldsAggregatable.deliveryDate,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(usecaseEntityStandardFieldsType.deliveryDate),
    toAggregate: false,
    aggregationToPerform: null,
  },
  case_creaded_on: {
    displayName: usecaseEntityStandardFieldsDisplayName.createdOn,
    key: usecaseEntityStandardFieldsKey.createdOn,
    type: usecaseEntityStandardFieldsType.createdOn,
    isAggregatable: usecaseEntityStandardFieldsAggregatable.createdOn,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(usecaseEntityStandardFieldsType.createdOn),
    toAggregate: false,
    aggregationToPerform: null,
  },
  case_stage: {
    displayName: usecaseEntityStandardFieldsDisplayName.stage,
    key: usecaseEntityStandardFieldsKey.stage,
    type: usecaseEntityStandardFieldsType.stage,
    isAggregatable: usecaseEntityStandardFieldsAggregatable.stage,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(usecaseEntityStandardFieldsType.stage),
    toAggregate: false,
    aggregationToPerform: null,
  },
  case_deliverymanager: {
    displayName: usecaseEntityStandardFieldsDisplayName.deliveryManager,
    key: usecaseEntityStandardFieldsKey.deliveryManager,
    type: usecaseEntityStandardFieldsType.deliveryManager,
    isAggregatable: usecaseEntityStandardFieldsAggregatable.deliveryManager,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(usecaseEntityStandardFieldsType.deliveryManager),
    toAggregate: false,
    aggregationToPerform: null,
  },
  // case_comment: {
  //   displayName: usecaseEntityStandardFieldsDisplayName.comment,
  //   key: usecaseEntityStandardFieldsKey.comment,
  //   type: usecaseEntityStandardFieldsType.comment,
  //   isAggregatable: usecaseEntityStandardFieldsAggregatable.comment,
  //   isCustomField: false,
  //   allowedOperationsList: getAllowedOperations(usecaseEntityStandardFieldsType.comment),
  //   toAggregate: false,
  //   aggregationToPerform: null,
  // },
};
