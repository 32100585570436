import TemplateOverviewSection from '../../Shared/TemplateOverviewSection';
import { useEffect, useState } from 'react';
// import DataGrid from 'react-data-grid';
// import 'react-data-grid/dist/react-data-grid.css';

import {
  addTemplateProject,
  getAllSectionByTemplateProjectuid,
  getProjectTemplates,
  updateTemplateProjectByUid,
  toggleCreateProjectTemplateExcelViewSelectedState,
} from 'store/modules/Templates/Templates';
import { selectToggleCreateProjectTemplateExcelViewSelectedState } from 'store/modules/Templates/selector';
// import { getCustomerDetail } from 'store/modules/Common/slice';
// import { selectCustomerDetail } from 'store/modules/Common/selector';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { getCustomerCompanyName } from 'utils/getUid';
import { Typography, Grid, Stack, ButtonGroup } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useNavigate } from 'react-router-dom';
import CognisaasTabs from 'components/shared/CognisaasTabs';
import { projectTemplateTabs } from 'components/Templates/TemplatesIndex/types';
import AddProjectTemplatePlanSection from './ProjectTemplatePlan/AddProjectTemplatePlanSection';
import { selectAllSectionTemplates, selectCreateProjectTemplateExcelData } from 'store/modules/Templates/selector';
import { Button } from '@mui/material';
import { updateProjectTemplate } from './helper';
import { label_project } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import AddProjectTemplatePlanExcel from './ProjectTemplatePlanExcel/AddProjectTemplatePlanExcel';
import { ReactComponent as TableViewUnselected } from '../../../../../../assets/svgs/table_view_unselected.svg';
import { ReactComponent as TableViewSelected } from '../../../../../../assets/svgs/table_view_unselected.svg';
import { ReactComponent as KanbanViewUnselected } from '../../../../../../assets/svgs/kanban_view_unselected.svg';
import { ReactComponent as KanbanViewSelected } from '../../../../../../assets/svgs/kanban_view_selected.svg';
// import { ReactComponent as GanttViewUnselected } from '../../../assets/svgs/gantt_chart_unselected.svg';
// import { ReactComponent as GanttViewSelected } from '../../../assets/svgs/gantt_chart_selected.svg';
import { addPlanFromExcel } from '../../Shared/addPlanFromExcel';
// import SampleExcel from '../../SampleExcel';

const AddProjectTemplateIndex = () => {
  // const columns = [
  //   { key: 'id', name: 'ID' },
  //   { key: 'title', name: 'Title' },
  // ];

  // const rows = [
  //   { id: 0, title: 'Example' },
  //   { id: 1, title: 'Demo' },
  // ];
  // const customerDetail = useAppSelector(selectCustomerDetail);
  const projectTemplateSections = useAppSelector(selectAllSectionTemplates);
  const projectTemplateExcelData = useAppSelector(selectCreateProjectTemplateExcelData);
  const isExcelViewSelected = useAppSelector(selectToggleCreateProjectTemplateExcelViewSelectedState);
  const [newTemplateUid, setNewTemplateUid] = useState<string | null>(null);
  const [selectedTab, setSelectedTab] = useState<string>(projectTemplateTabs[0]);
  // Overview form state
  const [templateName, setTemplateName] = useState<string>('');
  const [templateOwner, setTemplateOwner] = useState<any>('Not Assigned');
  const [templateTargetDays, setTemplateTargetDays] = useState<number>(0);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const getTemplateUid = async () => {
    const template = await dispatch(addTemplateProject(getCustomerCompanyName())).unwrap();
    console.log(template.result);
    if (template.result) {
      setNewTemplateUid(template.result);
    }
    dispatch(getAllSectionByTemplateProjectuid(template.result));
  };

  useEffect(() => {
    getTemplateUid();
  }, []);

  const handleSubmitProject = async () => {
    if (isExcelViewSelected) {
      console.log('Create plan');
      const payload = {
        templateName,
        templateOwner,
        sections: [],
        templateTargetDays,
        newTemplateUid,
      };
      const postData = updateProjectTemplate(payload);
      console.log(postData);
      await dispatch(updateTemplateProjectByUid(postData));
      await addPlanFromExcel(newTemplateUid as string, templateName, templateOwner, [], 0, projectTemplateExcelData);
      dispatch(toggleCreateProjectTemplateExcelViewSelectedState());
      navigate('/templates');
    } else {
      console.log(templateName, templateOwner, templateTargetDays);
      const sections = await dispatch(getAllSectionByTemplateProjectuid(newTemplateUid)).unwrap();
      console.log(
        templateName,
        templateOwner,
        [...sections],
        templateTargetDays,
        newTemplateUid,
        projectTemplateExcelData
      );
      const payload = {
        templateName,
        templateOwner,
        sections: [...sections],
        templateTargetDays,
        newTemplateUid,
      };
      const postData = updateProjectTemplate(payload);
      console.log(postData);
      await dispatch(updateTemplateProjectByUid(postData));
      await dispatch(getProjectTemplates());

      navigate('/templates');
    }
  };

  return (
    <>
      <Stack ml={2} my={2}>
        <Typography sx={{ fontSize: '12px', fontWeight: '400', ml: '25px', color: '#2D60F6' }}>Templates</Typography>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Stack direction="row">
              <ArrowBackIosNewIcon
                onClick={() => navigate(-1)}
                fontSize="small"
                sx={{ mt: '5px', cursor: 'pointer' }}
              />
              <Typography sx={{ fontSize: '20px', fontWeight: '500', ml: '5px' }}>{label_project} Template</Typography>
            </Stack>
          </Grid>
          <Grid item>
            <Button
              disableRipple
              disabled={templateTargetDays < 0 || templateName?.trim() == '' ? true : false}
              variant="contained"
              onClick={() => {
                handleSubmitProject();
                // setStateDrawerOpen(false);
              }}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Stack>
      <Stack ml={2} direction="row" justifyContent={'space-between'}>
        <CognisaasTabs
          size="squeezed"
          tabs={[...projectTemplateTabs]}
          handleTabSwitch={(e: any, index: number) => setSelectedTab(projectTemplateTabs[index])}
        />
        <Stack direction={'row'}>
          {/* {tabIndex == 1 && ( */}
          <Stack>
            <ButtonGroup sx={{ backgroundColor: 'white' }} variant="outlined" aria-label="outlined button group">
              <Button
                className={`${isExcelViewSelected ? 'active-btn' : null} `}
                onClick={() => {
                  dispatch(toggleCreateProjectTemplateExcelViewSelectedState());
                }}
              >
                {console.log(isExcelViewSelected)}
                {isExcelViewSelected ? <TableViewSelected /> : <TableViewUnselected />}
              </Button>
              <Button
                className={`${!isExcelViewSelected ? 'active-btn' : null} `}
                onClick={() => {
                  dispatch(toggleCreateProjectTemplateExcelViewSelectedState());
                }}
              >
                {isExcelViewSelected ? <KanbanViewSelected /> : <KanbanViewUnselected />}
              </Button>
            </ButtonGroup>
          </Stack>
        </Stack>
      </Stack>
      <Stack ml={2} my={2}>
        {selectedTab == projectTemplateTabs[0] ? (
          <TemplateOverviewSection
            userPermissions={['view', 'edit', 'delete']}
            name={templateName}
            owner={templateOwner}
            days={templateTargetDays}
            newTemplateName={(name: string) => setTemplateName(name)}
            newTemplateOwner={(name: any) => {
              setTemplateOwner(name);
              console.log(name);
            }}
            newTemplateTargetDays={(days: any) => setTemplateTargetDays(days)}
          />
        ) : (
          <>
            {/* <AddProjectTemplatePlanSection
              type="project"
              projectTemplateUid={newTemplateUid}
              sectionsData={projectTemplateSections}
            /> */}
            {!isExcelViewSelected ? (
              <AddProjectTemplatePlanSection
                type="project"
                projectTemplateUid={newTemplateUid}
                sectionsData={projectTemplateSections}
              />
            ) : (
              <AddProjectTemplatePlanExcel />
            )}
          </>

          // <DataGrid columns={columns} rows={rows} />
        )}
      </Stack>
    </>
  );
};

export default AddProjectTemplateIndex;
