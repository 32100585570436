import { clientEntityStandardFieldsDisplayName } from '../Config/EntityDisplayNames';
import { clientEntityStandardFieldsKey } from '../Config/EntityKeys';
import { clientEntityStandardFieldsAggregatable } from '../Config/EntityAggregatable';
import { clientEntityStandardFieldsType } from '../Config/EntityTypes';
import { getAllowedOperations } from '../UtilityFunction';

export const clientEntityStandardFields = {
  client_name: {
    displayName: clientEntityStandardFieldsDisplayName.name,
    key: clientEntityStandardFieldsKey.name,
    type: clientEntityStandardFieldsType.name,
    isAggregatable: clientEntityStandardFieldsAggregatable.name,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(clientEntityStandardFieldsType.name),
    toAggregate: false,
    aggregationToPerform: null,
  },
  segment_name: {
    displayName: clientEntityStandardFieldsDisplayName.segment,
    key: clientEntityStandardFieldsKey.segment,
    type: clientEntityStandardFieldsType.segment,
    isAggregatable: clientEntityStandardFieldsAggregatable.segment,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(clientEntityStandardFieldsType.segment),
    toAggregate: false,
    aggregationToPerform: null,
  },
  industry_name: {
    displayName: clientEntityStandardFieldsDisplayName.industry,
    key: clientEntityStandardFieldsKey.industry,
    type: clientEntityStandardFieldsType.industry,
    isAggregatable: clientEntityStandardFieldsAggregatable.industry,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(clientEntityStandardFieldsType.industry),
    toAggregate: false,
    aggregationToPerform: null,
  },
  client_readiness_status: {
    displayName: clientEntityStandardFieldsDisplayName.status,
    key: clientEntityStandardFieldsKey.status,
    type: clientEntityStandardFieldsType.status,
    isAggregatable: clientEntityStandardFieldsAggregatable.status,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(clientEntityStandardFieldsType.status),
    toAggregate: false,
    aggregationToPerform: null,
  },
  client_sales_owner: {
    displayName: clientEntityStandardFieldsDisplayName.salesOwner,
    key: clientEntityStandardFieldsKey.salesOwner,
    type: clientEntityStandardFieldsType.salesOwner,
    isAggregatable: clientEntityStandardFieldsAggregatable.salesOwner,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(clientEntityStandardFieldsType.salesOwner),
    toAggregate: false,
    aggregationToPerform: null,
  },
  client_account_owner: {
    displayName: clientEntityStandardFieldsDisplayName.accountOwner,
    key: clientEntityStandardFieldsKey.accountOwner,
    type: clientEntityStandardFieldsType.accountOwner,
    isAggregatable: clientEntityStandardFieldsAggregatable.accountOwner,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(clientEntityStandardFieldsType.accountOwner),
    toAggregate: false,
    aggregationToPerform: null,
  },
  client_account_worth: {
    displayName: clientEntityStandardFieldsDisplayName.accountWorth,
    key: clientEntityStandardFieldsKey.accountWorth,
    type: clientEntityStandardFieldsType.accountWorth,
    isAggregatable: clientEntityStandardFieldsAggregatable.accountWorth,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(clientEntityStandardFieldsType.accountWorth),
    toAggregate: false,
    aggregationToPerform: null,
  },
  stage_journerystage: {
    displayName: clientEntityStandardFieldsDisplayName.stage,
    key: clientEntityStandardFieldsKey.stage,
    type: clientEntityStandardFieldsType.stage,
    isAggregatable: clientEntityStandardFieldsAggregatable.stage,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(clientEntityStandardFieldsType.stage),
    toAggregate: false,
    aggregationToPerform: null,
  },
  client_creaded_on: {
    displayName: clientEntityStandardFieldsDisplayName.createdOn,
    key: clientEntityStandardFieldsKey.createdOn,
    type: clientEntityStandardFieldsType.createdOn,
    isAggregatable: clientEntityStandardFieldsAggregatable.createdOn,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(clientEntityStandardFieldsType.createdOn),
    toAggregate: false,
    aggregationToPerform: null,
  },
  renewal_date: {
    displayName: clientEntityStandardFieldsDisplayName.renewalDate,
    key: clientEntityStandardFieldsKey.renewalDate,
    type: clientEntityStandardFieldsType.renewalDate,
    isAggregatable: clientEntityStandardFieldsAggregatable.renewalDate,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(clientEntityStandardFieldsType.renewalDate),
    toAggregate: false,
    aggregationToPerform: null,
  },
  renewal_duration: {
    displayName: clientEntityStandardFieldsDisplayName.renewalDuration,
    key: clientEntityStandardFieldsKey.renewalDuration,
    type: clientEntityStandardFieldsType.renewalDuration,
    isAggregatable: clientEntityStandardFieldsAggregatable.renewalDuration,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(clientEntityStandardFieldsType.renewalDuration),
    toAggregate: false,
    aggregationToPerform: null,
  },
  last_status_report_sent_time: {
    displayName: clientEntityStandardFieldsDisplayName.lastStatusReportSentTime,
    key: clientEntityStandardFieldsKey.lastStatusReportSentTime,
    type: clientEntityStandardFieldsType.lastStatusReportSentTime,
    isAggregatable: clientEntityStandardFieldsAggregatable.lastStatusReportSentTime,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(clientEntityStandardFieldsType.lastStatusReportSentTime),
    toAggregate: false,
    aggregationToPerform: null,
  },
  // client_comment: {
  //   displayName: clientEntityStandardFieldsDisplayName.comment,
  //   key: clientEntityStandardFieldsKey.comment,
  //   type: clientEntityStandardFieldsType.comment,
  //   isAggregatable: clientEntityStandardFieldsAggregatable.comment,
  //   isCustomField: false,
  //   allowedOperationsList: getAllowedOperations(clientEntityStandardFieldsType.comment),
  //   toAggregate: false,
  //   aggregationToPerform: null,
  // },
};
