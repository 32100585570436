import { Box, Grid, Paper, Stack, Typography, IconButton, Tabs } from '@mui/material';
import { useEffect, useState } from 'react';
import ProjectOverview from './ProjectOverview/ProjectOverview';
import TasksOverview from './TasksOverview/TasksOverview';
import TaskTableView from '../../TaskModule/TaskView/TableView/TableView';
// import { clearProjectDashboardUidsFromStore } from '../../../store/modules/ManageClient/ClientList/slice';
import ProjectCustomFields from '../ProjectCustomFields/ProjectCustomFields';
import UsecaseHome from './UsecaseModule/UsecaseHome';
import PlanHome from '../../TaskModule/PlanHome';
import TeamMatesHome from '../TeamMates/TeamMatesHome';
import DeliverablesTimeline from '../../ClientModule/DeliverablesTimeline/DeliverablesTimieline';
import { getAllUsers } from '../../../store/modules/Common/slice';
import {
  selectProjectInformation,
  selectProjectAudit,
  selectProjectCustomFields,
  selectProjectList,
} from '../../../store/modules/Project/selector';
import {
  fetchClientContacts,
  fetchClientList,
  getClientUidByAssociationUid,
  getProjecWithClientUid,
} from '../../../store/modules/ManageClient/ClientList/slice';
import { useSelector } from 'react-redux';
import {
  findCriticalPath,
  getAllProjectsOfCustomer,
  getProjectAudit,
  getProjectCustomFields,
  getProjectInformation,
  getProjectInformationWithCaseTaskByUid,
} from '../../../store/modules/Project/slice';
import { useLocation, useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
// icons
import { ReactComponent as TableViewUnselected } from '../../../assets/svgs/table_view_unselected.svg';
import { ReactComponent as TableViewSelected } from '../../../assets/svgs/table_view_selected.svg';
import { ReactComponent as KanbanViewUnselected } from '../../../assets/svgs/kanban_view_unselected.svg';
import { ReactComponent as KanbanViewSelected } from '../../../assets/svgs/kanban_view_selected.svg';
import { ReactComponent as GanttViewUnselected } from '../../../assets/svgs/gantt_chart_unselected.svg';
import { ReactComponent as GanttViewSelected } from '../../../assets/svgs/gantt_chart_selected.svg';
import { getCustomerDetail, updateCaseWithfeatureStatus } from '../../../store/modules/Common/slice';
import CognisaasLoader from 'components/shared/CognisaasLoader/CognisaasLoader';
import {
  selectClientByAssociationUid,
  selectClientContacts,
  selectClientList,
  selectProjecWithClientUid,
} from '../../../store/modules/ManageClient/ClientList/selector';
import { selectCustomerDetail } from '../../../store/modules/Common/selector';
import { useAppSelector, useAppDispatch } from '../../../store/hooks';
import { Link } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom';
import ProjectAttachment from './ProjectAttachment/ProjectAttachment';
import { sendOnlyMileStoneTasks } from '../../TaskModule/taskUtils';
import RecentActivity from '../../shared/RecentActivity/RecentActivity';
import { label_client, label_project, label_use_case } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import GanttProject from 'components/shared/GanttCharts/GanttComponent/GanttProject';
import { getProjectTaskDashboardView, setProjectTaskdashboardView } from 'utils/viewHelper';
import CommentHome from './ProjectComment/CommentHome';
import { isMandatoryFieldsEmpty } from 'utils/dataHelpers';
import CognisaasAlertBlinker from 'components/shared/CognisaasAlertBlinker/CognisaasAlertBlinker';
import BasicModal from 'components/shared/CognisaasModal/CognisaasModal';
import CognisaasButton from 'components/shared/CognisaasButton/CognisaasButton';
import GenerateStatusReportFiltering from 'components/ClientModule/ClientStatusReport/GenerateStatusReportFiltering';
import checkAccessForRouting from 'components/shared/Hooks/checkAccessForRouting';
import CognisaasToast from 'components/shared/CognisaasToast/CognisaasToast';
import RAIDTableView from './Raid/Raid';
// import { fetchClientInformation } from '../../../store/modules/ManageClient/ClientList/slice';
import { withStyles } from '@mui/styles';
import Tab from '@mui/material/Tab';
import InfoIcon from 'components/shared/Icons/InfoIcon';
import CognisaaasTooltip from 'components/shared/CognisaasTooltip/CognisaasTooltip';

const RaidInfoToolTip = ()=>{
  return (
    <CognisaaasTooltip title={
      <Typography style={{ padding: '4px' }} variant={'subtitle1'}>
        Risks, Assumptions, Issues, and Dependencies
      </Typography>
    }>
        <IconButton>
      <InfoIcon />
      </IconButton>
    </CognisaaasTooltip>
  );
};

const ProjectDashboard = () => {
  const { projectId } = useParams();
  const selectClientInformation = useAppSelector(selectClientByAssociationUid);
  const projectCustomFields = useAppSelector(selectProjectCustomFields);
  const customerDetail = useAppSelector(selectCustomerDetail);
  const projectAudit = useAppSelector(selectProjectAudit);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [selectedProject, setSelectedProject] = useState<any>(null);
  const [projectTasks, setProjectTasks] = useState<any[]>([]);
  const [clientDetail, setClientDetail] = useState<any>(null);
  const [alertModal, setAlertModal] = useState<boolean>(false);
  const [alertDetails, setAlertDetails] = useState({ isVisible: false, severity: 'error', message: '' });
  const [alertModalOpened, setAlertModalOpened] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const associationUid: string = params.associationId as string;
  const tabs = ['Overview', 'Plan', label_use_case, 'Teammates', 'Attachments', 'Custom Fields', 'Comments', 'RAID'];
  // const clientUid = params.clientId as string;
  const project = useSelector(selectProjectInformation);
  const [tableView, setTableView] = useState<number>(1);
  const [criticalPath, setCriticalPath] = useState<any>([]);
  const [fullCriticalPath, setFullCriticalPath] = useState<any>([]);
  const [tabComponentIndex, setTabComponentIndex] = useState<number | undefined>();
  const [tabComponent, setTabComponent] = useState<any>();
  const [openFilterModal, setopenFilterModal] = useState<boolean>(false);
  const [showProjectModule, setShowProjectModule] = useState<boolean>(false);
  //const [isClientLinkAccessable, setIsClientLickAccessable] = useState<boolean>(false);
  const getClientContacts = useAppSelector(selectClientContacts);
  const getprojectsByclientuid = useAppSelector(selectProjecWithClientUid);
  const projectsList = useAppSelector(selectProjectList);
  const location: any = useLocation();
  const clientList = useAppSelector(selectClientList);
  const arrayToCheckClient = clientList.map((ele) => ele.client_uid);

  const isClientLinkAccessable = arrayToCheckClient.includes(project?.client_uid);
  //checkAccessForRouting({ arrayToCheck: arrayToCheckClient, uid: project?.client_uid });

  console.log({ isClientLinkAccessable });

  const CustomTab = withStyles({
    root: {
      // textTransform: 'none',
      minHeight: '60px',
      padding: '0px',
    },
  })(Tab);

  useEffect(() => {
    console.log('projectlist', { projectsList });
    if (projectsList?.length > 0 && selectClientInformation != null && selectClientInformation?.client_status == 1) {
      const arrayToCheck = projectsList.map((ele: any) => ele.project_uid);
      if (projectId != undefined) {
        if (location.state?.newlyAddedProjectUid && location.state?.newlyAddedProjectUid.length > 0) {
          arrayToCheck.push(location.state?.newlyAddedProjectUid);
        }
        const hasAccessToProject = checkAccessForRouting({ arrayToCheck: arrayToCheck, uid: projectId });
        if (hasAccessToProject == true) {
          setShowProjectModule(true);
        } else if (hasAccessToProject == false) {
          setShowProjectModule(false);
          navigate(-1);
          return;
        }
      }
    } else if (selectClientInformation?.client_status != 1) {
      setShowProjectModule(true);
    }
  }, [projectsList, selectClientInformation]);

  const getSelectedTab = (e: any, index: any) => {
    setTabIndex(index);
  };

  useEffect(() => {
    if (selectClientInformation != null && associationUid == selectClientInformation.association_uid) {
      dispatch(updateCaseWithfeatureStatus(selectClientInformation.client_uid));

      setClientDetail(selectClientInformation);
      dispatch(fetchClientContacts(selectClientInformation.client_uid));
    } else {
      dispatch(getClientUidByAssociationUid(params.associationId as string));
    }
  }, [selectClientInformation]);

  useEffect(() => {
    const detail = {
      project_uid: params.projectId,
    };
    getCriticalPath();
    dispatch(getAllUsers());
    dispatch(getProjectInformationWithCaseTaskByUid(detail));
    dispatch(getProjectAudit(params.projectId as string));
    dispatch(getCustomerDetail());
    dispatch(getAllProjectsOfCustomer());
    dispatch(fetchClientList());
    dispatch(getProjectCustomFields(params.projectId as string));

    const tableView =
      getProjectTaskDashboardView() === 'table' ? 0 : getProjectTaskDashboardView() === 'resourse' ? 2 : 1;
    setTableView(tableView);
  }, []);

  console.log('data', params, project, selectedProject, selectClientInformation);

  useEffect(() => {
    if (projectCustomFields && isMandatoryFieldsEmpty(projectCustomFields)) {
      for (let i = 0; i < tabs.length; i++) {
        if (tabs[i] === 'Custom Fields') {
          setTabComponentIndex(i);
          setTabComponent(<CognisaasAlertBlinker type="danger" />);
          if (!alertModalOpened) {
            setAlertModal(true);
          }
        }
      }
    } else {
      setAlertModal(false);
      setTabComponentIndex(undefined);
      setTabComponent(undefined);
    }
  }, [projectCustomFields]);
  useEffect(() => {
    if (customerDetail) {
      if (getProjectTaskDashboardView() === 'na') {
        setTableView(customerDetail.project_plan_view || 1);
        if (customerDetail.project_plan_view) {
          setProjectTaskdashboardView(
            customerDetail.project_plan_view === '0'
              ? 'table'
              : customerDetail.project_plan_view === '1'
              ? 'plan'
              : 'resourse'
          );
        } else {
          setProjectTaskdashboardView('plan');
        }
      }
    }
  }, [customerDetail]);

  useEffect(() => {
    if (project) {
      if (Object.entries(project).length > 0) {
        setSelectedProject({ ...project });
        setProjectTasks([...project.task, ...project.clientTask]);
        // for status report
        const clientUid = project.client_uid;
        dispatch(fetchClientContacts(clientUid));
        dispatch(getProjecWithClientUid(clientUid));
      }
    } else {
      const detail = {
        project_uid: params.projectId,
        association_uid: params.associationId,
      };
      dispatch(getProjectInformation(detail));
    }
  }, [project]);

  const getCriticalPath = async () => {
    const pay = {
      project_uid: params.projectId,
      type: 'PROJECT',
    };
    const criticalPath: any = await dispatch(findCriticalPath(pay)).unwrap();
    setCriticalPath([...criticalPath.result[0]]);
    setFullCriticalPath([...criticalPath.result]);
  };

  const returnComponentForSelectedIndex = () => {
    if (tabIndex == 0) {
      return (
        <Grid container spacing={1.2}>
          {selectedProject && (
            <Grid item xs={3}>
              <ProjectOverview client={clientDetail} project={project} />
            </Grid>
          )}

          {clientDetail && (
            <Grid item xs={6}>
              <TasksOverview project={selectedProject} client={clientDetail} />
            </Grid>
          )}

          <Grid item xs={3}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Paper
                  sx={{
                    height: '395px',
                    marginTop: '16px',
                    padding: '6px 20px',
                    overflowY: 'scroll',
                    boxShadow: 'none',
                  }}
                >
                  <DeliverablesTimeline tasks={sendOnlyMileStoneTasks([...projectTasks])} />
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper
                  sx={{
                    padding: '10px 0 10px 15px',
                    overflowY: 'scroll',
                    boxShadow: 'none',
                  }}
                >
                  <RecentActivity audit={projectAudit} type={label_project} />
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    } else if (tabIndex == 1) {
      return (
        <>
          {(tableView == 0 && <TaskTableView projectInformation={project} criticalPath={criticalPath} />) ||
            (tableView == 1 && <PlanHome client={clientDetail} project={project} />)}
          {tableView == 2 && <GanttProject criticalPath={fullCriticalPath} />}
        </>
      );
    } else if (tabIndex == 2) {
      return (
        <>
          <UsecaseHome client={clientDetail} project={selectedProject} />
        </>
      );
    } else if (tabIndex == 3) {
      return <TeamMatesHome projectInfo={project} clientInfo={clientDetail}></TeamMatesHome>;
    } else if (tabIndex == 4) {
      return <ProjectAttachment clientInfo={clientDetail} projectInfo={project} />;
    } else if (tabIndex == 5) {
      return (
        <>
          <ProjectCustomFields projectInfo={project} />
        </>
      );
    } else if (tabIndex == 6) {
      return (
        <>
          <CommentHome project_uid={selectedProject.project_uid} />
        </>
      );
    } else if (tabIndex == 7) {
      return (
        <>
          <RAIDTableView></RAIDTableView>
        </>
      );
    }
  };

  // useEffect(() => {
  //   return () => {
  //     dispatch(clearProjectDashboardUidsFromStore());
  //   };
  // });

  // const showClient = () => {
  //   return;
  // };

  const checkIfClientContactsPresent = () => {
    if (getClientContacts.length != 0) {
      setopenFilterModal(true);
    } else {
      // setAlertDetails({ isVisible: true, severity: 'error', message: 'Please add a client contact first.' });
    }
  };

  const clearLocalStorageWhenModalCloses = () => {
    setopenFilterModal(false);
    localStorage.setItem('report_selected_projects', '');
    localStorage.setItem('report_mindate_projects', '');
    localStorage.setItem('report_maxdate_projects', '');
  };
  if (showProjectModule == false) {
    return <CognisaasLoader />;
  } else
    return (
      <>
        <BasicModal
          height={250}
          width={400}
          open={alertModal}
          onClose={() => {
            setAlertModalOpened(true);
            setAlertModal((prevState) => !prevState);
          }}
        >
          <Stack style={{ padding: '15px' }}>
            <Typography variant="subtitle1" style={{ fontSize: '16px', lineHeight: '1.6rem' }}>
              Mandatory custom field data is missing. Any changes made will not be saved.
            </Typography>
            <Stack direction="row-reverse" style={{ marginTop: '30px' }}>
              <CognisaasButton
                clickHandler={() =>
                  setTabIndex(() => {
                    const index = tabs.findIndex((tab: string) => tab === 'Custom Fields');
                    setAlertModalOpened(true);
                    setAlertModal(false);
                    return index;
                  })
                }
                sx={{ padding: '8px 32px', marginLeft: '10px', height: '35px' }}
                isOutlined={false}
                label="Go to custom fields"
              />
              <CognisaasButton
                clickHandler={() =>
                  setAlertModal(() => {
                    setAlertModalOpened(true);
                    return false;
                  })
                }
                sx={{ padding: '8px 32px', height: '35px' }}
                isOutlined={true}
                label="Cancel"
              />
            </Stack>
          </Stack>
        </BasicModal>
        {project ? (
          <Box sx={{ paddingLeft: '20px', paddingRight: '20px' }}>
            <CognisaasToast
              open={alertDetails.isVisible}
              onClose={() => setAlertDetails({ isVisible: false, severity: 'error', message: '' })}
              message={alertDetails.message}
              type={alertDetails.severity == 'success' ? 'success' : 'failure'}
            ></CognisaasToast>
            {clientDetail && selectedProject && (
              <Stack>
                <Stack mt={1} mb={0} spacing={2} direction="row">
                  <Stack style={{ marginLeft: '32px' }} spacing={2} direction="row">
                    <Typography variant="subtitle1" className="label-text">
                      {label_client}
                    </Typography>
                    <Typography variant="subtitle1">
                      {isClientLinkAccessable == true ? (
                        <Link to={`/client-list/client/${clientDetail.client_uid}`}>{clientDetail.client_name}</Link>
                      ) : (
                        clientDetail.client_name
                      )}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack mt={0.5} mb={0} spacing={2} direction="row" justifyContent="space-between">
                  <Stack direction="row" alignItems="center" justifyContent={'space-between'} sx={{ width: '100%' }}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Stack>
                        <IconButton onClick={() => navigate(-1)} sx={{ margin: '0', padding: '0px' }}>
                          <ArrowBackIosIcon />
                        </IconButton>
                      </Stack>
                      <Stack>
                        <Typography style={{ marginLeft: '0px', marginRight: '10px' }} variant="h2">
                          {selectedProject.project_name}
                        </Typography>
                      </Stack>
                      <Stack direction="row" alignItems="center">
                        <span
                          style={{ marginRight: '4px', height: '16px', width: '16px' }}
                          className={
                            selectedProject.project_readinessstate_status == 'grey'
                              ? 'grey_status_box_task'
                              : selectedProject.project_readinessstate_status == 'red'
                              ? 'red_status_box_task'
                              : selectedProject.project_readinessstate_status == 'amber'
                              ? 'amber_status_box_task'
                              : selectedProject.project_readinessstate_status == 'green'
                              ? 'green_status_box_task'
                              : selectedProject.project_readinessstate_status == 'blue'
                              ? 'blue_status_box_task'
                              : ''
                          }
                        ></span>
                        <Typography variant="subtitle1" style={{ marginTop: '2px' }}>
                          {selectedProject.project_readinessstate_status == 'grey'
                            ? 'On Hold'
                            : selectedProject.project_readinessstate_status == 'red'
                            ? 'Overdue'
                            : selectedProject.project_readinessstate_status == 'amber'
                            ? 'At Risk'
                            : selectedProject.project_readinessstate_status == 'green'
                            ? 'On Track'
                            : selectedProject.project_readinessstate_status == 'blue'
                            ? 'Completed'
                            : ''}
                        </Typography>
                      </Stack>
                    </Stack>
                    {getClientContacts?.length > 0 ? (
                      <CognisaasButton
                        isOutlined={true}
                        label="Generate Status Report"
                        clickHandler={() => {
                          checkIfClientContactsPresent();
                        }}
                      />
                    ) : null}
                  </Stack>
                  <Stack direction={'row'}>
                    {tabIndex == 1 && (
                      <Stack>
                        <ButtonGroup
                          sx={{ backgroundColor: 'white' }}
                          variant="outlined"
                          aria-label="outlined button group"
                        >
                          <Button
                            className={`${tableView == 0 ? 'active-btn' : null} `}
                            onClick={() => {
                              setProjectTaskdashboardView('table');
                              setTableView(0);
                            }}
                          >
                            {tableView === 0 ? <TableViewSelected /> : <TableViewUnselected />}
                          </Button>

                          <Button
                            className={`${tableView == 1 ? 'active-btn' : null} `}
                            onClick={() => {
                              setProjectTaskdashboardView('plan');
                              setTableView(1);
                            }}
                          >
                            {tableView === 1 ? <KanbanViewSelected /> : <KanbanViewUnselected />}
                          </Button>
                          <Button
                            className={`${tableView == 2 ? 'active-btn' : null} `}
                            sx={{ backgroundColor: 'grey' }}
                            onClick={() => {
                              setProjectTaskdashboardView('resourse');
                              setTableView(2);
                            }}
                          >
                            {tableView === 2 ? <GanttViewSelected /> : <GanttViewUnselected />}
                          </Button>
                        </ButtonGroup>
                      </Stack>
                    )}
                  </Stack>
                </Stack>
              </Stack>
            )}

            <Box my={3} mb={2}>
            <Box sx={{ position: 'relative', top: '-20px', marginBottom: '-20px' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabIndex} onChange={getSelectedTab} aria-label="basic tabs example">
                  {tabs.map((tab: string, index: number) => (
                    // <div              key={index}>
                    <CustomTab
                      key={index}
                      value={index}
                      icon={index === tabComponentIndex ? tabComponent : tab==='RAID'? (<RaidInfoToolTip/>) : '' }
                      iconPosition="end"
                      label={tab}
                      sx={index === tabs.length - 1 ? { marginRight: '2px' } : { marginRight: '32px' }}
                    />
                    // </div>
                  ))}
                </Tabs>
                </Box>
                {returnComponentForSelectedIndex()}
            </Box>
            </Box>
          </Box>
        ) : (
          <CognisaasLoader />
        )}

        {openFilterModal == true && (
          <BasicModal open={openFilterModal} onClose={() => clearLocalStorageWhenModalCloses()}>
            <GenerateStatusReportFiltering
              getprojectsByclientuid={getprojectsByclientuid}
              clientUid={selectedProject.client_uid}
              type={'project-report'}
              selectedProjectUid={selectedProject.project_uid}
            />
          </BasicModal>
        )}
      </>
    );
};

export default ProjectDashboard;

//                 // client-list/client/${clientDetail.client_uid}`
