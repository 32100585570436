import { Chip, Grid, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Typography } from '@mui/material';
import CognisaaasTooltip from 'components/shared/CognisaasTooltip/CognisaasTooltip';

import { ReactComponent as ReportImage } from '../../../../assets/svgs/reports-tasks.svg';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import EditIcon from '@mui/icons-material/Edit';
import { Box } from '@mui/system';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useState } from 'react';
import DeleteModal from 'components/shared/CognisaasModal/DeleteModal';
import { ReactComponent as DeleteIcon } from '../../../../assets/svgs/delete.svg';
import CopyAllOutlinedIcon from '@mui/icons-material/CopyAllOutlined';

interface Props {
  onClick: () => void;
  title: string;
  description: string;
  private: boolean;
  editableByEveryone: boolean;
  entities?: string[];
  onDelete: () => void;
  onClone: () => void;
}

const ReportInfoCard = (props: Props) => {
  const TooltipTextStyled = (text: string) => {
    return (
      <Typography sx={{ padding: '4px' }} variant={'subtitle2'}>
        {text}
      </Typography>
    );
  };
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

  const toggleMenuOpenOrClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack
      sx={{
        minWidth: '400px',
        maxWidth: '400px',
        minHeight: '158px',
        background: '#FFFFFF',
        boxShadow: '0px 4px 26px rgba(0, 0, 0, 0.05)',
        borderRadius: '4px',
        cursor: 'pointer',
        margin: '0px 10px 10px 0px',
        position: 'relative',
      }}
    >
      <Grid onClick={props.onClick} sx={{ height: '100%', transform: 'Scale(0.9)' }} container>
        <Grid
          item
          xs={5}
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}
        >
          <ReportImage width={'64px'} height={'64px'} />
          {props.title.length > 11 ? (
            <>
              <CognisaaasTooltip title={TooltipTextStyled(props.title)} placement={'top'}>
                <Typography sx={{ fontWeight: 'bold' }} variant={'h6'}>
                  {props.title.substring(0, 11) + '...'}
                </Typography>
              </CognisaaasTooltip>
            </>
          ) : (
            <Typography sx={{ fontWeight: 'bold' }} variant={'h6'}>
              {props.title}
            </Typography>
          )}
          {/* icons */}
          <Stack direction={'row'}>
            {props.private ? (
              <CognisaaasTooltip title={TooltipTextStyled('This report is only visible to you')} placement={'top'}>
                <IconButton disableRipple color="secondary">
                  <LockOutlinedIcon fontSize="small" />
                </IconButton>
              </CognisaaasTooltip>
            ) : (
              <CognisaaasTooltip title={TooltipTextStyled('This report is visible to everyone')} placement={'top'}>
                <IconButton disableRipple color="success">
                  <LockOpenIcon fontSize="small" />
                </IconButton>
              </CognisaaasTooltip>
            )}

            {props.editableByEveryone ? (
              <CognisaaasTooltip title={TooltipTextStyled('This report can be edited by anyone')} placement={'top'}>
                <IconButton disableRipple color="success">
                  <EditIcon fontSize="small" />
                </IconButton>
              </CognisaaasTooltip>
            ) : (
              <CognisaaasTooltip title={TooltipTextStyled('This report can be edited only by you')} placement={'top'}>
                <IconButton disableRipple color="secondary">
                  <EditIcon fontSize="small" />
                </IconButton>
              </CognisaaasTooltip>
            )}
          </Stack>
        </Grid>
        <Grid item xs={7}>
          <Stack direction={'column'}>
            {/* present entity badge */}
            {props.entities && (
              <Stack sx={{ marginTop: '16px' }}>
                <Typography className="label-text" variant={'subtitle2'} sx={{ marginBottom: '-4px' }}>
                  Entities
                </Typography>
                <Stack direction={'row'} alignItems={'center'} flexWrap={'wrap'}>
                  {props.entities.map((entity: string, index: number) => (
                    <Chip
                      key={index}
                      sx={{ marginRight: '4px', marginTop: '4px' }}
                      label={entity}
                      size="small"
                      color="primary"
                      variant="outlined"
                    />
                  ))}
                </Stack>
              </Stack>
            )}

            {/* description */}
            <Stack sx={{ marginTop: '16px' }}>
              <Typography className="label-text" variant={'subtitle2'}>
                Description
              </Typography>

              <Typography className="subtitle1">
                {props.description?.length > 30 ? props.description.slice(0, 40) + '...' : props.description}
              </Typography>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
      <Box sx={{ position: 'absolute', right: '0', marginRight: '-6px' }}>
        <IconButton disableRipple onClick={toggleMenuOpenOrClose}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleMenuClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
            // dense: true,
          }}
          style={{ padding: '0px' }}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem
            onClick={() => {
              handleMenuClose();
              props.onClone();
            }}
          >
            <ListItemIcon>
              <CopyAllOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Clone report</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleMenuClose();
              setOpenDeleteModal(true);
            }}
          >
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            <ListItemText>Delete report</ListItemText>
          </MenuItem>
        </Menu>
      </Box>
      <DeleteModal
        isOpen={openDeleteModal}
        setIsOpen={setOpenDeleteModal}
        message={'Are you sure you want to permanently delete this report?'}
        onDelete={props.onDelete}
      />
    </Stack>
  );
};

export default ReportInfoCard;
