import { Stack, Typography, IconButton, Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CognisaaasTooltip from '../CognisaasTooltip/CognisaasTooltip';
const EditButtonWithDisabledWithTooltip = () => {
  return (
    <Stack direction="row" justifyContent={'space-between'} spacing={1}>
      <Typography variant={'h2'}>Profile Details</Typography>

      <CognisaaasTooltip
        title={
          <Box sx={{ height: '32px' }}>
            <Typography variant="subtitle1">You dont have access to edit</Typography>
          </Box>
        }
      >
        <Stack direction="row" spacing={1} justifyContent={'space-between'}>
          <IconButton disableRipple style={{ paddingTop: '0px' }}>
            <EditIcon />
          </IconButton>
        </Stack>
      </CognisaaasTooltip>
    </Stack>
  );
};

export default EditButtonWithDisabledWithTooltip;
