import { Paper, Stack, Typography } from '@mui/material';
import { label_task } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { CustomizedProgressBars } from '../../CognisaasProgressBar/ProgressBar';

const UsecaseTaskCompletionCard = (props: any) => {
  const tasks = props.totalTasks;

  const doneTasks = tasks.filter((task: any) => task.task_status == 'blue');
  console.log(tasks, Math.floor((parseInt(doneTasks.length) / parseInt(tasks.length)) * 100));

  return (
    <Paper sx={{ height: '127px', marginTop: '16px', boxShadow: 'none' }}>
      <Stack my={2}>
        <Stack my={2} mt={1} ml={1}>
          <Stack my={2}>
            <Typography sx={{ paddingBottom: '3px' }} variant="h2">
              Completion{' '}
            </Typography>
            <Stack mr={3}>
              <CustomizedProgressBars
                statusColor="green"
                hidePercentage={false}
                value={Math.floor(props?.usecase_progress)}
              />
            </Stack>
          </Stack>

          <Stack>
            <Typography variant="subtitle2">
              {doneTasks.length}/{tasks.length} {label_task}s
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default UsecaseTaskCompletionCard;
