// import * as React from 'react';
// import Box from '@mui/material/Box';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import { SelectChangeEvent } from '@mui/material/Select';
// import CognisaasDropdown from 'components/shared/CognisaasDropdown/CognisaasDropdown';
// import { handleInlineEditClickType, inlineEditPropType } from
// import { handleInlineEditClickType, inlineEditPropType } from 'components/shared/Tables/types';
import { useGridApiContext } from '@mui/x-data-grid';
// import CognisaasUserSelect from 'components/shared/CognisaasSelect/CognisaasUserSelect';
import { getUserUid } from 'utils/getUid';
// import { Field } from 'formik';
// import { Autocomplete } from 'formik-mui';
import { Autocomplete, TextField } from '@mui/material';

export const ExcelUserSelect = (
  params: any,
  dropdownValues: any[],
  handleUpdate: any
  // handleOnClick?: handleInlineEditClickType,
  // type?: inlineEditPropType
) => {
  console.log('DD', dropdownValues);
  const apiRef = useGridApiContext();
  const { id, field } = params;
  const handleChange = (value: string) => {
    handleUpdate(value, id, field);
    apiRef.current.setEditCellValue({ id, field, value: value });
  };

  const selectedUser = {
    name: params.value,
    id: getUserUid(params.value),
  };

  console.log(selectedUser, params, 'parsms:', params);

  // const handleClick = async () => {
  //   await handleOnClick(params, field, value, type);
  //   apiRef.current.stopCellEditMode({ id, field });
  // };

  // function determineCustomValue(params: any, value: any): unknown {
  //   return value;
  // }

  const users = dropdownValues.map((user: any) => user.name);
  console.log(users, 'USER:', params.value);

  return (
    <div style={{ width: '100%' }}>
      <Autocomplete
        // open={true}
        onChange={(e: any, v: any) => {
          console.log(v);
          handleChange(v);
        }}
        openOnFocus={true}
        value={params.value}
        style={{ width: '100%' }}
        id="free-solo-demo"
        freeSolo
        options={users}
        renderInput={(params) => <TextField {...params} />}
      />
    </div>
  );
};
