import { Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';

interface Props {
  header: string;
  value: string;
  color: string;
}

const InfoChipWithColor = (props: Props) => {
  return (
    <Box
      sx={{
        background: 'white',
        borderRadius: '4px',
        minWidth: '120px',
        padding: '4px 16px 4px 4px',
        boxShadow: '0px 4px 26px rgb(0 0 0 / 5%)',
      }}
    >
      <Stack direction={'row'}>
        <Box
          sx={{
            width: '4px',
            background: props.color,
            borderRadius: '4px',
            margin: '4px',
          }}
        ></Box>
        <Box
          sx={{
            margin: '4px',
          }}
        >
          <Typography variant={'subtitle2'} className="label-text">
            {props.header}
          </Typography>
          <Typography variant={'subtitle1'}>{props.value}</Typography>
        </Box>
      </Stack>
    </Box>
  );
};

export default InfoChipWithColor;
