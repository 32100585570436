import TextField from '@mui/material/TextField';
import CognisaaasTooltip from '../CognisaasTooltip/CognisaasTooltip';
import { Typography, Box, Stack } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { Badge } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import format from 'date-fns/format';
import { isValidDate } from 'utils/dataHelpers';
import { companyHolidays, companyHolidaysWithDetail } from 'utils/getUid';
import { selectCustomerDetail } from 'store/modules/Common/selector';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { getCustomerDetail } from 'store/modules/Common/slice';
import { useEffect, useState } from 'react';
import { client_getCustomerDetail } from 'store/modules/CustomerPortal/CustomerPortal';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const nextDay = new Date(new Date().setDate(new Date().getDate() + 2));
const dates: any = [];
dates.push(format(nextDay, 'dd MMM yyyy'));

interface props {
  id?: string;
  name?: string;
  disabled?: true | false;
  value?: Date | null;
  handleChange?: any;
  highlightDates?: any[];
  label?: string;
  width?: string;
  error?: boolean;
  minDate?: Date;
  maxDate?: Date;
  open?: boolean;
}

const CognisaasCalenderNew = (props: props) => {
  const customerDetail = useAppSelector(selectCustomerDetail);
  const dispatch = useAppDispatch();
  const [dateError, setDateError] = useState(false);

  useEffect(() => {
    if (!customerDetail) {
      fillCustomerData();
    }
  }, []);

  const fillCustomerData = async () => {
    if (cookies.get('isCustomerPortal') === 'true') {
      await dispatch(client_getCustomerDetail());
    } else {
      await dispatch(getCustomerDetail());
    }
  };

  // console.log(props.highlightDates);
  const highlightDates: any = props.highlightDates;
  let datesToBeHighlighted: any = [];
  if (highlightDates) {
    datesToBeHighlighted = highlightDates?.map((date: Date) => {
      const dateRef = date;
      if (isValidDate(dateRef)) format(date, 'dd MMM yyyy');
    });
    console.log(datesToBeHighlighted);
  }

  const disableThisDate = (date: Date) => {
    if (customerDetail) {
      const holidays = companyHolidays();
      return holidays.includes(date.getTime());
    }
    return false;
  };

  const getHolidayReason = (day: Date) => {
    const holidays = companyHolidaysWithDetail();
    const leaveDay = holidays.filter((holiday: any) => new Date(holiday.date).getTime() == day.getTime());
    return leaveDay;
  };

  const ShowHoliday = (day: Date) => {
    const leaveDetail = getHolidayReason(day);
    return (
      <Stack>
        <CognisaaasTooltip
          title={
            <Box sx={{ padding: '12px' }}>
              <Typography variant="subtitle1">Company holiday due to {leaveDetail[0].detail}</Typography>
            </Box>
          }
        >
          <div>⚪️</div>
        </CognisaaasTooltip>
      </Stack>
    );
  };

  // disableThisDate(new Date());

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        {...(props.minDate ? { minDate: props.minDate } : {})}
        {...(props.maxDate ? { maxDate: props.maxDate } : {})}
        // maxDate={props.maxDate ? props.maxDate : null}
        disabled={props.disabled == true}
        value={props.value}
        label={props.label}
        onChange={(newValue) => {
          if (!isValidDate(newValue)) {
            setDateError(true);
          } else {
            setDateError(false);
            props.handleChange(newValue);
          }
        }}
        shouldDisableDate={disableThisDate}
        renderInput={(params) => (
          <TextField style={{ width: props.width }} size="small" {...params} error={dateError} />
        )}
        renderDay={(day: any, _value, props) => {
          return (
            <Badge
              key={day?.toString()}
              overlap="circular"
              badgeContent={disableThisDate(day) ? ShowHoliday(day) : null}

              // badgeContent={datesToBeHighlighted?.includes(format(day, 'dd MMM yyyy').toString()) ? '🟠' : undefined}
            >
              <PickersDay {...props} />
            </Badge>
          );
        }}
      />
    </LocalizationProvider>
  );
};

export default CognisaasCalenderNew;
