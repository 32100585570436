import { useEffect, useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import { getProjectCustomFieldsDetail } from '../../../../store/modules/Project/slice';
import { transformCustomFieldDataForEntityStructure } from '../UtilityFunction';
import { projectEntityStandardFields } from './projectEntityStandardFields';
import useCustomNames from 'components/shared/CustomNames/useCustomNames';

const projectEntity = () => {
  const dispatch = useAppDispatch();
  const { projectStagesDropdownValues, projectCategoryDropdownValues } = useCustomNames();
  const [projectEntityWithCustomFields, setProjectEntityWithCustomFields] = useState<any[]>([]);

  console.log(projectStagesDropdownValues, projectCategoryDropdownValues, projectEntityStandardFields);

  useEffect(() => {
    getCustomFieldsOfProjectEntity();
  }, []);

  useEffect(() => {
    // transformStabdardFields();
  }, [projectStagesDropdownValues, projectCategoryDropdownValues]);

  const transformStandardFields = (fields: any) => {
    const projectFieldsTemp: any = {};
    for (const property in fields) {
      if (property == 'project_stage') {
        projectFieldsTemp[property] = {
          ...fields[property],
          validValues: [...projectStagesDropdownValues.map((field: any) => field.custom_stage_name)],
        };
        console.log(projectFieldsTemp);
      } else if (property == 'project_category') {
        projectFieldsTemp[property] = {
          ...fields[property],
          validValues: [...projectCategoryDropdownValues.map((field: any) => field.custom_category_name)],
        };
      } else if (property == 'project_priority') {
        projectFieldsTemp[property] = {
          ...fields[property],
          validValues: ['Critical', 'High', 'Medium', 'Low'],
        };
      } else {
        projectFieldsTemp[property] = fields[property];
      }
    }
    console.log(projectFieldsTemp);
    return projectFieldsTemp;
  };

  const getCustomFieldsOfProjectEntity = async () => {
    const projectCustomFields = await dispatch(getProjectCustomFieldsDetail()).unwrap();
    setProjectEntityWithCustomFields({
      ...transformStandardFields(projectEntityStandardFields),
      ...transformCustomFieldDataForEntityStructure(projectCustomFields),
    });
  };
  return { projectEntityWithCustomFields };
};

export default projectEntity;
