export enum brandColor {
  BR_PURPLE = '#3D38BA',
  BR_ORANGE = '#FFA100',
}

export enum grays {
  BLACK = '#000000',
  DARK_GRAY = '#7A7A7A',
  LIGHT_GRAY = '#E5E5E5',
  LIGHTER_GRAY = '#F3F3F3',
  WHITE = '#FFFFFF',
}

export enum brags {
  BLUE = '#4390E1',
  CORAL = '#E75B5C',
  ORANGE = '#EFA958',
  GREEN = '#4F9F52',
  LITE_BLUE = '#9BC5F2',
  LITE_CORAL = '#F1AFAF',
  LITE_ORANGE = '#F9D1A2',
  LITE_GREEN = '#BDF2BF',
}

export enum profilePastels {
  PALE_RED = '#F9CFCF',
  PEACH_UMBRELLA = '#F9E8CF',
  SNOW_FLURRY = '#E4F9CF',
  BLANCHED_ALMOND = '#CFF6F9',
  PALE_BLUE = '#CFE5F9',
  PALE_VIOLET = '#D0CFF9',
  PALE_PINK = '#F6CFF9',
}

export const ragStatusColor: Record<string, string> = {
  Red: '#E75B5C',
  Amber: '#ffa726',
  Green: '#4F9F52',
  Blue: '#2196F3',
};

export const avatarColors = ['#F9CFCF', '#F9E8CF', '#E4F9CF', '#CFF6F9', '#CFE5F9', '#D0CFF9', '#F6CFF9'];

export const giveColor = (letter: string) => {
  const num = letter.toLowerCase().charCodeAt(0) - 97 + 1;

  //   const num = randomIntFromInterval();
  //   console.log(avatarColors[num % 7]);

  return avatarColors[num % 7];
};
