import React, { useState } from 'react';

import { Stack, Typography, TextField } from '@mui/material';
import CognisaasButton from '../../shared/CognisaasButton/CognisaasButton';

import { getCustomerUid } from 'utils/getUid';
import { getTemplateDocumentByCustomerUid } from 'store/modules/Templates/Templates';

import DocumentAttachmentViewer from './DocumentAttachmentViewer';
import { apis } from 'api/axiosInstance';
import { templateRoutes } from 'api/route';
import { attachmentKeyGenerator } from 'components/shared/Attachment/attachmentHelpers';

import Cookies from 'universal-cookie';
import { addAttachmentsWithCustomUrlAndPayload } from 'store/modules/Attachment/slice';
import { useAppDispatch } from 'store/hooks';

interface propType {
  openModal: React.Dispatch<React.SetStateAction<boolean>>;
  setAlertDetails: React.Dispatch<
    React.SetStateAction<{
      isVisible: boolean;
      type: 'success' | 'failure';
      message: string;
    }>
  >;
}

const cookies = new Cookies();

export default function CreateDocumentTemplate(props: propType) {
  const [templateName, setTemplateName] = useState<string>('');
  const [attachment, setAttachment] = useState<any>(null);
  const [attachmentURL, setAttachmentURL] = useState<string>('');

  const dispatch = useAppDispatch();
  const customerUid = getCustomerUid();

  const handleSaveButton = async () => {
    const payload = {
      template_name: templateName,
      customer_uid: customerUid,
    };
    const response = await apis.phpAxiosInstance.post(templateRoutes.addTemplateDocument(), payload, {
      headers: { JWT: 'true' },
    });
    const template_document_uid = response.data.result.template_document_uid;
    const metaData = {
      template_document_uid: template_document_uid,
      customer_uid: customerUid,
      current_version_uploaded_by_user_uid: cookies.get('cogni_user_uid'),
      current_version_uploaded_by_user_name: cookies.get('user_name'),
      key: attachmentKeyGenerator(cookies.get('cogni_customer_uid'), customerUid),
    };
    const fileData = {
      metaDataForHeaders: metaData,
      file: attachment,
    };
    await dispatch(
      addAttachmentsWithCustomUrlAndPayload({ apiUrl: 'addTemplateDocumentAttachment', oneAttachment: fileData })
    )
      .unwrap()
      .then((res) => {
        if (res.status === 0) {
          props.setAlertDetails({
            isVisible: true,
            message: res.message,
            type: 'failure',
          });
        } else {
          props.setAlertDetails({
            isVisible: true,
            message: 'File uploaded successfully',
            type: 'success',
          });
        }
      })
      .catch(() => {
        props.setAlertDetails({
          isVisible: true,
          message: 'File size larger than 20 MB',
          type: 'failure',
        });
      });
    await dispatch(getTemplateDocumentByCustomerUid(customerUid));
    props.openModal(false);
  };

  const addAttachment = (e: any) => {
    const file = e.target.files[0];
    const blob = new Blob([file]);
    const fileDownloadUrl = URL.createObjectURL(blob);
    setAttachmentURL(fileDownloadUrl);
    setAttachment(file);
  };

  const handleDeleteAttachment = () => {
    setAttachment(null);
    setAttachmentURL('');
  };

  return (
    <Stack>
      <Stack my={2}>
        <Typography variant="h2">Create Document Template</Typography>
      </Stack>
      <Typography variant="subtitle2">
        Template Name<span className="cogni-red">*</span>
      </Typography>
      <TextField
        autoComplete=""
        autoFocus={true}
        onChange={(e) => setTemplateName(e.target.value)}
        style={{ width: '410px', marginBottom: '15px' }}
        size="small"
        placeholder="Enter Template name"
      />
      {attachmentURL.length > 0 ? (
        <DocumentAttachmentViewer
          fileName={attachment.name}
          attachmentURL={attachmentURL}
          handleDeleteAttachment={handleDeleteAttachment}
        />
      ) : (
        <label htmlFor="template-document-attachment">
          <input
            onChange={addAttachment}
            style={{ display: 'none' }}
            accept="*"
            id="template-document-attachment"
            type="file"
          />
          <Typography style={{ display: 'inline-block', cursor: 'pointer' }} variant="subtitle2" className="link-text">
            Upload Attachment<span className="cogni-red">*</span>
          </Typography>
        </label>
      )}
      <Stack mt={3} spacing={2} justifyContent="flex-end" direction="row">
        <CognisaasButton isOutlined={true} clickHandler={() => props.openModal(false)} label="Cancel" />
        <CognisaasButton
          clickHandler={() => handleSaveButton()}
          isOutlined={false}
          label="Save"
          disabled={attachmentURL.length === 0 || templateName.length === 0}
        />
      </Stack>
    </Stack>
  );
}
