import { useEffect, useState } from 'react';
import {
  getTaskCustomfieldsWithValue,
  getTaskCustomfields,
  updateTaskCustomField,
  resetUpdatingTaskCustomField,
  addTaskAuditTrail,
} from '../../../../store/modules/Task/slice';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../store/hooks';
import {
  selectTaskCustomfields,
  selectTaskCustomfieldsWithValue,
  selectUpdatingTaskCustomField,
} from '../../../../store/modules/Task/selector';
import CustomFieldsForm from '../../../shared/CustomfieldsForm/CustomfieldsForm';
import { requestState } from 'store/modules/types';
import CognisaasToast from 'components/shared/CognisaasToast/CognisaasToast';
import { addAttachmentToServer, resetAttachmentQueue } from 'store/modules/Attachment/slice';
import { selectAttachmentQueue } from 'store/modules/Attachment/selector';
import { selectTaskModalCustomfields } from '../../../../store/modules/Task/selector';
import { Stack } from '@mui/material';
import { getLoggedInUserUid } from 'utils/getUid';
import { format } from 'date-fns';

const Customfields = (props: any) => {
  const dispatch = useDispatch();
  const taskModalCustomfields = useAppSelector(selectTaskModalCustomfields);
  const taskCustomfields = useAppSelector(selectTaskCustomfields);
  const taskCustomfieldsWithValues = useAppSelector(selectTaskCustomfieldsWithValue);
  const attachmentQueue = useAppSelector(selectAttachmentQueue);
  const [isSavingCf, setIsSavingCf] = useState(false);
  const updatingCustomFieldState = useAppSelector(selectUpdatingTaskCustomField);
  const [alertDetails, setAlertDetails] = useState<{
    isVisible: boolean;
    type: 'success' | 'failure';
    message: string;
  }>({ isVisible: false, type: 'success', message: '' });

  useEffect(() => {
    if (!taskModalCustomfields) {
      dispatch(getTaskCustomfields());
      dispatch(getTaskCustomfieldsWithValue(props.taskDetail.task_uid));
    }
  }, []);

  useEffect(() => {
    if (updatingCustomFieldState.status === requestState.success) {
      setAlertDetails({
        isVisible: true,
        type: 'success',
        message: updatingCustomFieldState.message,
      });
      dispatch(resetUpdatingTaskCustomField());
    }
  }, [updatingCustomFieldState]);

  useEffect(() => {
    if (taskCustomfields.length > 0) {
      mergeBothSelectors();
    }
  }, [taskCustomfields, taskCustomfieldsWithValues]);

  const mergeBothSelectors = () => {
    const cf = [...taskCustomfieldsWithValues];
    const cfv = [...taskCustomfields];
    const newTcf = [];

    for (let i = 0; i < cfv.length; i++) {
      let new_obj = { ...cfv[i], field_value: '' };
      for (let j = 0; j < cf.length; j++) {
        if (cfv[i].field_uid === cf[j].field_uid) {
          new_obj = { ...new_obj, field_value: cf[j].field_value };
        }
      }
      newTcf.push(new_obj);
    }
  };
  const attachmentQueueHelper = (attachmentQueue: any) => {
    attachmentQueue.forEach((attachment: any) => {
      dispatch(addAttachmentToServer(attachment));
    });
  };

  const updateTaskCustomFields = async (fields: any) => {
    console.log(fields);
    setIsSavingCf(true);
    attachmentQueueHelper(attachmentQueue);
    for (const i in fields) {
      for (let j = 0; j < taskCustomfieldsWithValues.length; j++) {
        if (taskCustomfieldsWithValues[j].field_name == i) {
          let detail = taskCustomfieldsWithValues[j];
          if (fields[i].length === 0 && taskCustomfieldsWithValues[j].field_type === 'String') {
            const attachment = attachmentQueue.find(
              (attachment: any) => attachment.id === taskCustomfieldsWithValues[j].field_uid
            );
            detail = {
              ...taskCustomfieldsWithValues[j],
              field_value: attachment?.file?.name || '',
              task_uid: props.taskDetail.task_uid,
            };
          } else {
            detail = { ...taskCustomfieldsWithValues[j], field_value: fields[i], task_uid: props.taskDetail.task_uid };
          }

          if (taskCustomfieldsWithValues[j].field_value !== detail.field_value) {
            if (detail.field_type === 'Date') {
              detail.field_value = format(new Date(detail.field_value), 'yyyy-MM-dd');
            }
            await dispatch(updateTaskCustomField(detail));
          }
        }
      }
    }
    const auditDetail = {
      task_uid: props.taskDetail.task_uid,
      user_uid: getLoggedInUserUid(),
      field: 'Customfields_updated',
      modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      old_value: '',
      new_value: '',
    };
    dispatch(addTaskAuditTrail(auditDetail));
    dispatch(resetAttachmentQueue());
    await reloadCustomfieldsData();
    setIsSavingCf(false);
  };

  const reloadCustomfieldsData = async () => {
    await dispatch(getTaskCustomfieldsWithValue(props.taskDetail.task_uid));
    await dispatch(getTaskCustomfields());
  };

  return (
    <Stack style={{ overflow: 'scroll' }}>
      <CustomFieldsForm
        taskCustomFieldsFormRef={props.taskCustomFieldsFormRef}
        isLoading={isSavingCf}
        onSaveClickHandler={updateTaskCustomFields}
        customFields={taskCustomfieldsWithValues}
        projectUid={props.taskDetail.project_uid}
        caseUid={props.taskDetail.case_uid}
        taskUid={props.taskDetail.task_uid}
        clientUid={props.taskDetail.client_uid}
        userPermissions={props.taskDetail.__user_permissions}
        callbackToReloadData={reloadCustomfieldsData}
        attachmentQueue={attachmentQueue}
      ></CustomFieldsForm>
      <CognisaasToast
        fromPanel={true}
        open={alertDetails.isVisible}
        onClose={() => setAlertDetails({ isVisible: false, type: alertDetails.type, message: '' })}
        message={alertDetails.message}
        type={alertDetails.type}
      />
    </Stack>
  );
};

export default Customfields;
