import { useEffect, useState } from 'react';
import { Container, ButtonLink, ImageInput, Box as CustomBox, Image } from './PictureUpload.styles';
import { ReactComponent as UploadArrow } from '../../../assets/svgs/uploadArrow.svg';
import { addCustomerLogo, addUserLogo } from 'store/modules/Settings/slice';
import { addCustomerClientLogo, getClientDetail } from 'store/modules/ManageClient/ClientList/slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import {
  selectCustomerDetails,
  getUserData_selector,
  selectAddCustomerLogoStatus,
  selectAddUserLogoStatus,
} from 'store/modules/Settings/selector';
import { selectClientDetail, selectAddCustomerClientLogoStatus } from 'store/modules/ManageClient/ClientList/selector';

import { getCustomerDetails } from 'store/modules/Settings/slice';

import { requestState } from '../../../store/modules/types';
import CognisaasSpinner from '../../shared/CognisaasSpinner/CognisaasSpinner';
import { Typography } from '@mui/material';

interface pictureUploadProps {
  type: 'customer' | 'client' | 'user';
  customer_uid?: string | null;
  client_uid?: string | null;
  user_uid?: string | null;
  setAlertDetails: React.Dispatch<
    React.SetStateAction<{
      isVisible: boolean;
      type: 'success' | 'failure';
      message: string;
    }>
  >;
}

const PictureUpload = (props: pictureUploadProps) => {
  const [imageUrl, setImageUrl] = useState<string>('');

  const addCustomerLogoStatus = useAppSelector(selectAddCustomerLogoStatus);
  const addCustomerClientLogoStatus = useAppSelector(selectAddCustomerClientLogoStatus);
  const addUserLogoStatus = useAppSelector(selectAddUserLogoStatus);

  const customerDetails = useAppSelector(selectCustomerDetails);
  const clientDetail = useAppSelector(selectClientDetail);
  const userFullDetail = useAppSelector(getUserData_selector);

  const dispatch = useAppDispatch();

  useEffect(() => {
    switch (props.type) {
      case 'customer':
        dispatch(getCustomerDetails());
        break;
      case 'client':
        dispatch(getClientDetail(props.client_uid || ''));
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    switch (props.type) {
      case 'customer':
        setImageUrl(customerDetails.data.customer_logo_link || '');
        break;
      case 'client':
        setImageUrl(clientDetail.client_logo_link || '');
        break;
      case 'user':
        setImageUrl(userFullDetail.data.user_profile_pic_link || '');
        break;
      default:
        break;
    }
  }, [customerDetails, clientDetail, userFullDetail]);

  const updateStatus = (status: 0 | 1, message: string) => {
    if (status === 0) {
      props.setAlertDetails({
        isVisible: true,
        message: message,
        type: 'failure',
      });
    } else {
      props.setAlertDetails({
        isVisible: true,
        message: 'File uploaded successfully',
        type: 'success',
      });
    }
  };

  const onImageChange = (e: any) => {
    const file = e.target.files[0];
    const attachment = {
      file: file,
      metaData: {
        customer_uid: props.customer_uid || '',
        client_uid: props.client_uid || '',
        user_uid: props.user_uid || '',
      },
    };
    if (props.type === 'customer') {
      dispatch(addCustomerLogo(attachment))
        .unwrap()
        .then((res: any) => {
          console.log('IT IS HEREEEE');
          updateStatus(res.status, res.message);
        })
        .catch(() => {
          console.log('IT IS NONNTT HEREEEE');
          updateStatus(0, 'File size larger than 20 MB');
        });
    } else if (props.type === 'client') {
      dispatch(addCustomerClientLogo(attachment))
        .unwrap()
        .then((res: any) => {
          updateStatus(res.status, res.message);
        })
        .catch(() => {
          updateStatus(0, 'File size larger than 20 MB');
        });
    } else if (props.type === 'user') {
      dispatch(addUserLogo(attachment))
        .unwrap()
        .then((res: any) => {
          updateStatus(res.status, res.message);
        })
        .catch(() => {
          updateStatus(0, 'File size larger than 20 MB');
        });
    }
  };

  return (
    <Container>
      <label htmlFor="profile-upload">
        {props.type === 'customer' && (
          <>
            <CustomBox>
              {addCustomerLogoStatus === requestState.loading ? (
                <CognisaasSpinner />
              ) : imageUrl.length > 0 ? (
                <Image src={imageUrl} />
              ) : (
                <>
                  <UploadArrow />
                  <Typography style={{ color: '#7A7A7A', fontSize: '12px', margin: '0', fontWeight: '400' }}>
                    Upload
                  </Typography>
                </>
              )}
            </CustomBox>
            <ButtonLink>Upload Company Logo</ButtonLink>
          </>
        )}

        {props.type === 'user' && (
          <>
            <CustomBox>
              {addUserLogoStatus === requestState.loading ? (
                <CognisaasSpinner />
              ) : imageUrl.length > 0 ? (
                <Image src={imageUrl} />
              ) : (
                <>
                  <UploadArrow />
                  <Typography style={{ color: '#7A7A7A', fontSize: '12px', margin: '0', fontWeight: '400' }}>
                    Upload
                  </Typography>
                </>
              )}
            </CustomBox>
            <ButtonLink>Upload Photo</ButtonLink>
          </>
        )}

        {props.type === 'client' && (
          <>
            <CustomBox>
              {addCustomerClientLogoStatus === requestState.loading ? (
                <CognisaasSpinner />
              ) : imageUrl.length > 0 ? (
                <Image src={imageUrl} />
              ) : (
                <>
                  <UploadArrow />
                  <Typography style={{ color: '#7A7A7A', fontSize: '12px', margin: '0', fontWeight: '400' }}>
                    Upload
                  </Typography>
                </>
              )}
            </CustomBox>
            <ButtonLink>Upload Photo</ButtonLink>
          </>
        )}

        <ImageInput
          accept="image/gif,image/png,image/jpeg,image/bmp, .csv"
          type="file"
          id="profile-upload"
          onChange={onImageChange}
        />
      </label>
    </Container>
  );
};

export default PictureUpload;
