import { Typography } from '@mui/material';
import { format } from 'date-fns';
import { isNull, isValidDate } from 'utils/dataHelpers';
import CognisaaasTooltip from 'components/shared/CognisaasTooltip/CognisaasTooltip';
interface PropType {
  date: string;
}

interface PropTypeTooltip {
  date: string;
  text: string;
}

const ViewDate = (props: PropType) => {
  if (props.date) {
    return isValidDate(props.date) || !isNull(props.date) ? (
      props.date == '0000-00-00 00:00:00' || !Date.parse(props.date) ? (
        <Typography variant="subtitle1">-</Typography>
      ) : (
        <Typography variant="subtitle1">{format(new Date(props.date.replace(/-/g, '/')), 'dd MMM yyyy')}</Typography>
      )
    ) : (
      <Typography variant="subtitle1">-</Typography>
    );
  } else {
    return <>-</>;
  }
};

export const ViewDateTooltip = (props: PropTypeTooltip) => {
  if (props.date) {
    return isValidDate(props.date) || !isNull(props.date) ? (
      props.date == '0000-00-00 00:00:00' || !Date.parse(props.date) ? (
        <Typography variant="subtitle1">-</Typography>
      ) : (
        <CognisaaasTooltip
          title={
            <Typography variant="subtitle1" style={{ padding: '10px' }}>
              {props.text}
            </Typography>
          }
        >
          <Typography variant="subtitle1">{format(new Date(props.date.replace(/-/g, '/')), 'dd MMM yyyy')}</Typography>
        </CognisaaasTooltip>
      )
    ) : (
      <Typography variant="subtitle1">-</Typography>
    );
  } else {
    return <>-</>;
  }
};

export default ViewDate;
