import Styles from './Attachment.module.css';
import Box from '@mui/material/Box';
import { Stack, Typography } from '@mui/material';
import { ReactComponent as UploadIcon } from '../../../assets/svgs/upload.svg';

interface Props {
  attachments: any[];
  setAttachments: React.Dispatch<React.SetStateAction<any[]>>;
}

const BulkAttachmentLocalUploader = (props: Props) => {
  const handleBulkFiles = (multipleFileList: any) => {
    props.setAttachments(multipleFileList);
  };

  // dragged files are handled here
  const attchmentDropHandler = (event: any) => {
    event.preventDefault();
    handleBulkFiles(event.dataTransfer.files);
  };

  const attachmentDragoverHandler = (event: any) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  };

  const removeAttachments = () => {
    props.setAttachments([]);
  };

  return (
    <Box key={Date.now()} className={Styles.Attachment__container}>
      <div className={Styles.Attachment__bulkUploadContainer}>
        <Typography variant="h2">Upload Attachment</Typography>

        {/* dropable div */}
        <div
          className={Styles.Attachment__dropzone}
          onDrop={(event) => attchmentDropHandler(event)}
          onDragOver={(event) => attachmentDragoverHandler(event)}
        >
          <UploadIcon />
          <Typography style={{ width: '170px', marginTop: '12px' }} variant="subtitle1">
            Drag and drop to upload or &nbsp;
            <label htmlFor="contained-button-file">
              <input
                onChange={(e) => handleBulkFiles(e.target.files)}
                style={{ display: 'none' }}
                accept="image/*, .doc, .docx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, audio/*, video/*, application/vnd.ms-excel, application/vnd.ms-powerpoint, application/pdf, text/plain, .xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf, .pages, .ppsx"
                id="contained-button-file"
                multiple
                type="file"
              />
              <Typography
                style={{ display: 'inline-block' }}
                variant="subtitle2"
                className="link-text cognisaas-pointer"
              >
                browse
              </Typography>
            </label>
          </Typography>
        </div>
        <Stack direction={'row'} spacing={1}>
          <Typography variant="subtitle2" className="label-text">
            {props.attachments.length} files selected
          </Typography>
          <Typography variant="subtitle2" className="cogni-red cognisaas-pointer" onClick={removeAttachments}>
            Remove
          </Typography>
        </Stack>
      </div>
    </Box>
  );
};

export default BulkAttachmentLocalUploader;
