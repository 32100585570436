import { apis } from '../axiosInstance';
import { customReportRoutes } from '../route';
// import Cookies from 'universal-cookie';

// const cookies = new Cookies();

const getGeneratedCustomReportUsingUid = (report_uid: string) =>
  apis.phpAxiosInstance.get(customReportRoutes.getGeneratedCustomReportUsingUid(report_uid), {
    headers: { JWT: 'true' },
  });

const deleteCustomReportByUid = (details: any) =>
  apis.phpAxiosInstance.post(customReportRoutes.deleteCustomReport(), details, {
    headers: { JWT: 'true' },
  });

const cloneAnyCustomReport = (details: any) =>
  apis.phpAxiosInstance.post(customReportRoutes.cloneCustomReport(), details, {
    headers: { JWT: 'true' },
  });

export const customReportAPI = {
  getGeneratedCustomReportUsingUid,
  deleteCustomReportByUid,
  cloneAnyCustomReport,
};
