import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../..';

export const selectRenewalTasksState = (state: RootState) => state.renewalTasksState;
export const selectRenewalTasks = createSelector(
  selectRenewalTasksState,
  (renewalTasksState) => renewalTasksState.renewalTasks
);
export const selectRenewalTasksStatus = createSelector(
  selectRenewalTasksState,
  (renewalTasksState) => renewalTasksState.status
);
export const selectRenewalTasksError = createSelector(
  selectRenewalTasksState,
  (renewalTasksState) => renewalTasksState.error
);
