import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../';

export const customerPortalSlice = (state: RootState) => state.customerPortal;

export const selectUserObj = createSelector(customerPortalSlice, (customerPortalSlice) => customerPortalSlice.userObj);
export const selectUserObjStatus = createSelector(customerPortalSlice, (customerPortalSlice) => customerPortalSlice.userObjIsLoading);
export const selectProjectWithTasks = createSelector(
  customerPortalSlice,
  (customerPortalSlice) => customerPortalSlice.projectWithTasks
);
export const selectprojectUpgradation = createSelector(
  customerPortalSlice,
  (customerPortalSlice) => customerPortalSlice.projectUpgradation
);
export const selectuseCaseUpgradation = createSelector(
  customerPortalSlice,
  (customerPortalSlice) => customerPortalSlice.useCaseUpgradation
);
export const selectprojectDegradation = createSelector(
  customerPortalSlice,
  (customerPortalSlice) => customerPortalSlice.projectDegradation
);
export const selectuseCaseDegradation = createSelector(
  customerPortalSlice,
  (customerPortalSlice) => customerPortalSlice.useCaseDegradation
);
export const selectprojectStatusCount = createSelector(
  customerPortalSlice,
  (customerPortalSlice) => customerPortalSlice.projectStatusCount
);
export const selecttaskStatusCount = createSelector(
  customerPortalSlice,
  (customerPortalSlice) => customerPortalSlice.taskStatusCount
);
export const selectuseCaseStatusCount = createSelector(
  customerPortalSlice,
  (customerPortalSlice) => customerPortalSlice.useCaseStatusCount
);
export const selectsignleProjectInformation = createSelector(
  customerPortalSlice,
  (customerPortalSlice) => customerPortalSlice.signleProjectInformation
);
export const selectsignleUsecaseInformation = createSelector(
  customerPortalSlice,
  (customerPortalSlice) => customerPortalSlice.signleUsecaseInformation
);
export const selectallCompanyUsers = createSelector(
  customerPortalSlice,
  (customerPortalSlice) => customerPortalSlice.allCompanyUsers
);
export const selectallClientUsersUsers = createSelector(
  customerPortalSlice,
  (customerPortalSlice) => customerPortalSlice.allClientUsersUsers
);
export const selectcreatedTaskUid = createSelector(
  customerPortalSlice,
  (customerPortalSlice) => customerPortalSlice.createdTaskUid
);
export const selectisMagicLinkExpired = createSelector(
  customerPortalSlice,
  (customerPortalSlice) => customerPortalSlice.isMagicLinkExpired
);
export const selectclientUserInformation = createSelector(
  customerPortalSlice,
  (customerPortalSlice) => customerPortalSlice.clientUserInformation
);
export const selectcustomerInformation = createSelector(
  customerPortalSlice,
  (customerPortalSlice) => customerPortalSlice.customerInformation
);
