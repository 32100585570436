import Switch, { SwitchProps } from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#2D60F6' : '#2D60F6',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 10,
    height: 10,
    borderRadius: 6,
    border: '1px solid #2D60F6',
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    border: '1px solid #2D60F6',
    backgroundColor: '#fff',
    boxSizing: 'border-box',
  },
}));

interface props extends SwitchProps {
  defaultChecked?: boolean;
  handleChange?: any;
  checked?: boolean;
  disabled?: boolean;
}

const CognisaasSwitchButton = (props: props) => {
  return (
    <AntSwitch
      {...(props.disabled ? { disabled: props.disabled } : {})}
      defaultChecked={props.defaultChecked}
      checked={props.checked}
      onChange={props.handleChange}
      inputProps={{ 'aria-label': 'ant design' }}
    />
  );
};

export default CognisaasSwitchButton;
