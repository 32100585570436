import React from 'react';
import TheToolBar from './TheToolBar/TheToolBar';
import './MainLayout.css';
type MainLayoutProps = {
  children: React.ReactNode;
};

const MainLayout = (props: MainLayoutProps) => {
  return (
    <React.Fragment>
      <TheToolBar />
      <div className={'MainLayout__wrapperSmallHeightSceens'}>{props.children}</div>
    </React.Fragment>
  );
};

export default MainLayout;
