import { taskAPI } from 'api/modules/tasks';
import { label_task } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { format } from 'date-fns';
import { getLoggedInUserEmail } from 'utils/getUid';
// import { getUserDetail } from 'utils/getUid';
import { apis } from '../../../api/axiosInstance';

export const getJiraCustomFields = async (detail: any) => {
  const data = await apis.phpAxiosInstance.post('getJiraCustomField', detail, { headers: { JWT: 'true' } });
  console.log(data);
  return JSON.parse(data.data.result);
};

export const getMandatoryCustomFieldsForIssue = (customFieldsData: any, issue: any) => {
  const rand_array = customFieldsData.projects[0].issuetypes.filter(function (item1: any) {
    return item1.id === issue.id;
  });

  const filterByRequiredCustomField = (obj: any) =>
    Object.values(obj).filter(function (o: any) {
      if (o.required) {
        // * check  for the mandatory fields other than the default fields for jira
        if (
          o.key != 'project' &&
          o.key != 'summary' &&
          o.key != 'description' &&
          o.key != 'issuetype' &&
          o.key != 'duedate' &&
          o.key != 'reporter' &&
          o.key != 'assignee' &&
          o.key != 'priority'
        )
          return o;
      }
    });

  const field_obj = filterByRequiredCustomField(rand_array[0].fields);

  return field_obj;
};

export const getMandatoryCustomFieldsForJsdServiceRequests = (customFieldsData: any[]) => {
  console.log(customFieldsData);

  const rand_array = customFieldsData.filter((item: any) => {
    if (item.required == true || item.required == 'true') {
      if (
        item.name.toLowerCase() != 'project' &&
        item.name.toLowerCase() != 'summary' &&
        item.name.toLowerCase() != 'description' &&
        item.name.toLowerCase() != 'issuetype' &&
        item.name.toLowerCase() != 'duedate' &&
        item.name.toLowerCase() != 'reporter' &&
        item.name.toLowerCase() != 'assignee' &&
        item.name.toLowerCase() != 'priority'
      ) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  });

  console.log('jsd cf', rand_array);

  // const rand_array = customFieldsData.projects[0].issuetypes.filter(function (item1: any) {
  //   return item1.id === issue.id;
  // });

  // const filterByRequiredCustomField = (obj: any) =>
  //   Object.values(obj).filter(function (o: any) {
  //     if (o.required) {
  //       // check  for the mandatory fields other than the default fields for jira
  //       if (
  //         o.key != 'project' &&
  //         o.key != 'summary' &&
  //         o.key != 'description' &&
  //         o.key != 'issuetype' &&
  //         o.key != 'duedate' &&
  //         o.key != 'reporter' &&
  //         o.key != 'assignee' &&
  //         o.key != 'priority'
  //       )
  //         return o;
  //     }
  //   });
  // console.log(rand_array);

  // const field_obj = filterByRequiredCustomField(rand_array[0].fields);

  return rand_array;
};
export const filterByName = (obj: any, name: any) =>
  Object.values(obj).filter(function (o: any) {
    return o.name === name;
  });
export const addTaskToJira = async (
  user_info: any,
  jira_project_id: string,
  jira_task_name: string,
  task_uid: string | null,
  owner_mail: string,
  taskDetail: any,
  project_key: string,
  issue: any,
  projectInfo: any,
  mandatoryFields: any[],
  jiraTaskPayload: Record<string, any> | null
) => {
  let clientDetails: any;
  let status: { status: 0 | 1 | 2; message: string } = {
    status: 2,
    message: `Please wait the ${label_task} is being pushed to JIRA.`,
  };
  try {
    clientDetails = await apis.phpAxiosInstance.get(`getClientByUid?client_uid=${projectInfo.client_uid}`, {
      headers: { JWT: 'true' },
    });
  } catch (e: any) {
    status = { status: 0, message: 'Unable to handle Client Details!' };
    return status;
  }

  const key = btoa(btoa(user_info.customer_jira_user + ':' + user_info.customer_jira_token));

  const opost = { key: key, customer_uid: user_info.customer_uid, email: user_info.user_email };

  const customjiraUrl = { key: key, customer_uid: user_info.customer_uid, project_key: project_key };

  const data = await apis.phpAxiosInstance.post('getJiraCustomField', customjiraUrl, { headers: { JWT: 'true' } });

  const jiraUrlResult = JSON.parse(data.data.result);
  const issue_id = issue[0].id;
  const rand_array = jiraUrlResult.projects[0].issuetypes.filter(function (item1: any) {
    return item1.id === issue_id;
  });
  const filterByName = (obj: any, name: any) =>
    Object.values(obj).filter(function (o: any) {
      return o.name === name;
    });
  let field_obj: any = filterByName(rand_array[0].fields, 'Client Name');
  const customClientName: string = field_obj[0].key;
  field_obj = filterByName(rand_array[0].fields, 'Delivery Date');
  const customDeliveryDate: string = field_obj[0].key;
  field_obj = filterByName(rand_array[0].fields, 'Due date');
  const customDueDate: string = field_obj[0].key;

  const mailInfo = {
    customer_uid: user_info.customer_uid,
    key: key,
    email: user_info.user_email,
  };
  let ownerJiraId = '';
  let ownerMailInfo: any = await apis.phpAxiosInstance.post('getJiraUserByEmail', mailInfo, {
    headers: { JWT: 'true' },
  });
  if (ownerMailInfo.data.status) {
    ownerMailInfo = JSON.parse(ownerMailInfo.data.result);
    ownerJiraId = ownerMailInfo[0].accountId;
  }

  // * Fetch jira task reporter
  let reporterMail: any = await apis.phpAxiosInstance.post('getJiraUserByEmail', opost, { headers: { JWT: 'true' } });
  let jiraTask: any;
  if (reporterMail.data.status) {
    reporterMail = JSON.parse(reporterMail);
    jiraTask = {
      key: key,
      customer_uid: user_info.customer_uid,
      id: jira_project_id,
      summary: jira_task_name,
      description: taskDetail.task_description,
      reporter: reporterMail[0].accountId,
      name: issue[0].name,
      assignee: ownerJiraId,
      duedate: taskDetail.task_due_date,
      customClientName: customClientName,
      client_name: clientDetails.data.result.client_name ? clientDetails.data.result.client_name : '',
      clientId: projectInfo.client_uid,
      case_name: taskDetail.usecaseOrProjectName,
      customDeliveryDate: customDeliveryDate,
      customDueDate: customDueDate,
      delivery_date: taskDetail.task_closed_date,
      priority: taskDetail.jira_priority,
      task_stage: taskDetail.task_stage,
      remainingEstimate: taskDetail.time_remaining,
      originalEstimate: taskDetail.effort_estimate.trim().length == 0 ? '' : taskDetail.effort_estimate,
      required_fields: '',
      formValues: '',
      userEmail: getLoggedInUserEmail(),
    };
  } else {
    jiraTask = {
      key: key,
      customer_uid: user_info.customer_uid,
      id: jira_project_id,
      summary: jira_task_name,
      description: taskDetail.task_description,
      reporter: '',
      name: issue[0].name,
      assignee: ownerJiraId,
      duedate: taskDetail.task_due_date,
      customClientName: customClientName,
      clientId: projectInfo.client_uid,
      client_name: clientDetails.data.result.client_name ? clientDetails.data.result.client_name : '',
      case_name: taskDetail.usecaseOrProjectName,
      customDeliveryDate: customDeliveryDate,
      customDueDate: customDueDate,
      delivery_date: taskDetail.task_closed_date,
      priority: taskDetail.jira_priority,
      task_stage: taskDetail.task_stage,
      remainingEstimate: taskDetail.time_remaining,
      originalEstimate: taskDetail.effort_estimate,
      required_fields: '',
      formValues: '',
      userEmail: getLoggedInUserEmail(),
    };
  }

  if (mandatoryFields.length > 0) {
    jiraTask = {
      ...jiraTask,
      required_fields: mandatoryFields.map((item: any) => {
        const tempItem = { ...item };
        delete tempItem['newValue'];
        return tempItem;
      }),
      formValues: mandatoryFields.map((item: any) => ({ field_value: item.newValue, field_value_name: item.newValueName })),
    };
  }
  const createJiraIssue: any = await apis.phpAxiosInstance.post('createJiraTaskIssue', jiraTask, {
    headers: { JWT: 'true' },
  });

  // * after adding to jira something should happen
  if (createJiraIssue.data.status) {
    const jiraResponse: any = JSON.parse(createJiraIssue.data.result);
    console.log('jira response', jiraResponse);
    if (jiraResponse.id) {
      let taskResponse: any;

      if (!task_uid) {
        // * create new task only if task_uid is null
        if (taskDetail.case_uid) {
          // * usecase task
          const api_response = await taskAPI.addUsecaseTask(taskDetail);
          taskResponse = api_response.data.result[0];
        } else {
          // * project task
          const api_response = await taskAPI.addProjectTask(taskDetail);
          taskResponse = api_response.data.result[0];
        }
        status = { status: 1, message: 'Task Added Succesfully!' };
      } else {
        if (jiraTaskPayload) {
          console.log(jiraTaskPayload, 'jirataskpayload');
          await taskAPI.updateTask({ ...taskDetail, ...jiraTaskPayload });
        }
      }

      const metadata = {
        customer_uid: user_info.customer_uid,
        key: jiraResponse.key,
        jira_issue_id: jiraResponse.id,
        task_uid: task_uid ? task_uid : taskResponse.task_uid,
      };

      apis.phpAxiosInstance.post('addAttachmentsToJira', metadata, { headers: { JWT: 'true' } });
      if (!taskDetail.case_uid) {
        apis.phpAxiosInstance.post('updateProjectTaskIssueId', metadata, {
          headers: { JWT: 'true' },
        });
      } else {
        apis.phpAxiosInstance.post('updateTaskIssueId', metadata, { headers: { JWT: 'true' } });
      }
      status = { status: 1, message: 'Task Added to Jira Succesfully!' };
    }
  } else {
    status = { status: 0, message: 'Some Unexpected Error Occured!' };
  }
  return status;
};

export const updateTaskToJira = async (
  user_info: any,
  jira_project_id: string,
  jira_task_name: string,
  task_uid: string,
  owner_mail: string,
  taskDetail: any,
  project_key: string,
  issue: any,
  projectInfo: any,
  user_email: string,
  mandatoryFields: any[]
) => {
  try {
    const clientDetails = await apis.phpAxiosInstance.get(`getClientByUid?client_uid=${projectInfo.client_uid}`, {
      headers: { JWT: 'true' },
    });

    const key = btoa(btoa(user_info.customer_jira_user + ':' + user_info.customer_jira_token));

    const opost = { key: key, customer_uid: user_info.customer_uid, email: user_email };

    const customjiraUrl = { key: key, customer_uid: user_info.customer_uid, project_key: project_key };

    const data = await apis.phpAxiosInstance.post('getJiraCustomField', customjiraUrl, { headers: { JWT: 'true' } });

    const jiraUrlResult = JSON.parse(data.data.result);
    const issue_id = issue[0].id;
    const rand_array = jiraUrlResult.projects[0].issuetypes.filter(function (item1: any) {
      return item1.id === issue_id;
    });

    const filterByName = (obj: any, name: any) =>
      Object.values(obj).filter(function (o: any) {
        return o.name === name;
      });
    let field_obj: any = filterByName(rand_array[0].fields, 'Client Name');
    const customClientName: string = field_obj[0].key;
    field_obj = filterByName(rand_array[0].fields, 'Delivery Date');
    const customDeliveryDate: string = field_obj[0].key;

    const mailInfo = {
      customer_uid: user_info.customer_uid,
      key: key,
      email: user_email,
    };
    let ownerJiraId = '';
    let ownerMailInfo: any = await apis.phpAxiosInstance.post('getJiraUserByEmail', mailInfo, {
      headers: { JWT: 'true' },
    });
    if (ownerMailInfo.data.status) {
      ownerMailInfo = JSON.parse(ownerMailInfo.data.result);
      ownerJiraId = ownerMailInfo[0].accountId;
    }

    let jiraUpdateDetails: Record<string, any>;

    jiraUpdateDetails = {
      key: key,
      customer_uid: user_info.customer_uid,
      id: jira_project_id,
      summary: jira_task_name,
      description: taskDetail.task_description,
      reporter: '',
      name: issue[0].name,
      assignee: ownerJiraId,
      clientId: projectInfo.client_uid,
      issueId: taskDetail.jira_issue_id,
      // * format -  2022-11-09 23:59:59
      duedate: format(new Date(taskDetail.task_due_date.replace(/-/g, '/')), 'yyyy-MM-dd HH:mm:ss '),
      customClientName: customClientName,
      client_name: clientDetails.data.result.client_name,
      case_name: taskDetail.case_uid ? taskDetail.case_uid : projectInfo.project_name,
      customDeliveryDate: customDeliveryDate,
      delivery_date: format(new Date(taskDetail.task_closed_date.replace(/-/g, '/')), 'yyyy-MM-dd HH:mm:ss '),
      priority: taskDetail.jira_priority,
      task_stage: taskDetail.task_stage,
      remainingEstimate: taskDetail.time_remaining,
      required_fields: '',
      formValues: '',
      userEmail: getLoggedInUserEmail(),
    };

    let reporterMail: any = await apis.phpAxiosInstance.post('getJiraUserByEmail', opost, { headers: { JWT: 'true' } });

    if (reporterMail.data.status) {
      reporterMail = JSON.parse(reporterMail.data.result);

      jiraUpdateDetails = { ...jiraUpdateDetails, reporter: reporterMail[0].accountId };
    } else {
      jiraUpdateDetails = { ...jiraUpdateDetails, reporter: '' };
    }

    if (mandatoryFields.length > 0) {
      jiraUpdateDetails = {
        ...jiraUpdateDetails,
        required_fields: mandatoryFields.map((item: any) => {
          const tempItem = { ...item };
          delete tempItem['newValue'];
          return tempItem;
        }),
        formValues: mandatoryFields.map((item: any) => ({ field_value: item.newValue })),
      };
    }

    console.log('updating jira payload', jiraUpdateDetails);

    let status: { status: 0 | 1; message: string } = { status: 0, message: 'Unable To Complete Process' };

    const updateJiraIssue: any = await apis.phpAxiosInstance.post('update_jira_task_issue', jiraUpdateDetails, {
      headers: { JWT: 'true' },
    });

    if (updateJiraIssue.data.status == 1) {
      if (updateJiraIssue.data.result.length > 5) {
        // * this means there is some error while updating jira task issue
        status = { status: 0, message: `Unable To Update ${label_task} In Jira, Try Again` };
      } else {
        const response = await taskAPI.updateTask({ ...taskDetail, task_uid });
        if (response && response.data.status == 1) {
          status = { status: 1, message: `${label_task} Updated Successfully!` };
        } else {
          status = { status: 0, message: response.data.message };
        }
      }
    } else {
      status = { status: 0, message: `Unable To Update ${label_task} In Jira, Try Again` };
    }

    return status;
  } catch (e: any) {
    const status = { status: 1, message: 'Updated!' };
    return status;
  }
};

export const addTaskToJiraServiceDesk = async (
  user_info: any,
  jira_project_id: string,
  jira_task_name: string,
  task_uid: string | null,
  owner_mail: string,
  taskDetail: any,
  project_key: string,
  jsd_service_request_issue_id: string,
  projectInfo: any,
  mandatoryFields: any[],
  jiraTaskPayload: Record<string, any> | null
) => {
  // const taskOldDetail = await axiosInstance.get(`getProjectTaskByTaskUid?task_uid=${taskDetail.task_uid}`, {
  //   headers: { JWT: 'true' },
  // });

  const clientDetails = await apis.phpAxiosInstance.get(`getClientByUid?client_uid=${projectInfo.client_uid}`, {
    headers: { JWT: 'true' },
  });

  console.log(user_info, jira_project_id, jira_task_name, task_uid, owner_mail, taskDetail, project_key);
  const key = btoa(btoa(user_info.customer_jira_user + ':' + user_info.customer_jira_token));

  const opost = { key: key, customer_uid: user_info.customer_uid, email: user_info.user_email };

  const clientDetail: any = apis.phpAxiosInstance.get(`getClientInformation?client_uid=${projectInfo.client_uid}`, {
    headers: { JWT: 'true' },
  });
  console.log(clientDetail);

  const mailInfo = {
    customer_uid: user_info.customer_uid,
    key: key,
    email: user_info.user_email,
  };
  let ownerJiraId = '';
  let ownerMailInfo: any = await apis.phpAxiosInstance.post('getJiraUserByEmail', mailInfo, {
    headers: { JWT: 'true' },
  });
  if (ownerMailInfo.data.status) {
    ownerMailInfo = JSON.parse(ownerMailInfo.data.result);
    ownerJiraId = ownerMailInfo[0].accountId;
  }

  // * Fetch jira task reporter
  let reporterMail: any;
  try {
    reporterMail = await apis.phpAxiosInstance.post('getJiraUserByEmail', opost, { headers: { JWT: 'true' } });
  } catch {
    reporterMail = '';
  }
  let jiraTask: any;
  console.log('backtrace-1', user_info, taskDetail, projectInfo);
  // * calculate jira issue id properly for jira service desk task
  if (reporterMail.data.status) {
    reporterMail = JSON.parse(reporterMail);

    jiraTask = {
      key: key,
      customer_uid: user_info.customer_uid,
      id: jira_project_id,
      summary: jira_task_name,
      description: taskDetail.task_description,
      reporter: reporterMail[0].accountId,
      issue_id: jsd_service_request_issue_id,
      assignee: ownerJiraId,
      duedate: taskDetail.task_due_date,
      client_name: clientDetails.data.result.client_name ? clientDetails.data.result.client_name : '',
      clientId: projectInfo.client_uid,
      case_name: taskDetail.case_uid ? taskDetail.case_uid : projectInfo.project_name,
      delivery_date: taskDetail.task_closed_date,
      priority: taskDetail.jira_priority,
      task_stage: taskDetail.task_stage,
      remainingEstimate: taskDetail.time_remaining,
      originalEstimate: taskDetail.effort_estimate.trim().length == 0 ? '' : taskDetail.effort_estimate,
      required_fields: '',
      formValues: '',
      userEmail: getLoggedInUserEmail(),
    };
  } else {
    jiraTask = {
      key: key,
      customer_uid: user_info.customer_uid,
      id: jira_project_id,
      summary: jira_task_name,
      description: taskDetail.task_description,
      reporter: '',
      issue_id: jsd_service_request_issue_id,
      assignee: ownerJiraId,
      duedate: taskDetail.task_due_date,
      client_name: clientDetails.data.result.client_name ? clientDetails.data.result.client_name : '',
      clientId: projectInfo.client_uid,
      case_name: taskDetail.case_uid ? taskDetail.case_uid : projectInfo.project_name,
      delivery_date: taskDetail.task_closed_date,
      priority: taskDetail.jira_priority,
      task_stage: taskDetail.task_stage,
      remainingEstimate: taskDetail.time_remaining,
      originalEstimate: taskDetail.effort_estimate ? taskDetail.effort_estimate : '',
      required_fields: '',
      formValues: '',
      userEmail: getLoggedInUserEmail(),
    };
  }

  if (mandatoryFields.length > 0) {
    jiraTask = {
      ...jiraTask,
      required_fields: mandatoryFields.map((item: any) => {
        const tempItem = { ...item };
        delete tempItem['newValue'];
        return tempItem;
      }),
      formValues: mandatoryFields.map((item: any) => ({ field_value: item.newValue })),
    };
  }

  console.log('task details that are forwarded', taskDetail);
  console.log('jira payload', jiraTask);

  const createJiraServiceDeskIssue: any = await apis.phpAxiosInstance.post('createJiraTaskIssueServiceDesk', jiraTask, {
    headers: { JWT: 'true' },
  });

  let status: { status: 0 | 1; message: string } = { status: 0, message: 'Unable To Complete Process' };

  console.log('task jira jsd', createJiraServiceDeskIssue);
  // * after adding to jira something should happen
  if (createJiraServiceDeskIssue.data.status) {
    let taskResponse: any;

    const jiraResponse: any = JSON.parse(createJiraServiceDeskIssue.data.result);
    const jiraResponseStatus: any = createJiraServiceDeskIssue.data.status;
    console.log('jira response', jiraResponse);
    if (jiraResponseStatus == 1) {
      if (!task_uid) {
        // * if task uid is null then make a new task
        if (taskDetail.case_uid) {
          //usecase task
          const api_response = await taskAPI.addUsecaseTask(taskDetail);
          taskResponse = api_response.data.result[0];
        } else {
          //* project task
          const api_response = await taskAPI.addProjectTask(taskDetail);
          taskResponse = api_response.data.result[0];
        }
        status = { status: 1, message: 'Task Added Succesfully!' };
      } else {
        if (jiraTaskPayload) {
          console.log(jiraTaskPayload, 'jirataskpayload');
          await taskAPI.updateTask({ ...taskDetail, ...jiraTaskPayload });
        }
      }

      const metadata = {
        customer_uid: user_info.customer_uid,
        key: jiraResponse.issueKey,
        jira_issue_id: jiraResponse.issueId,
        task_uid: task_uid ? task_uid : taskResponse.task_uid,
      };
      apis.phpAxiosInstance.post('addAttachmentsToJira', metadata, { headers: { JWT: 'true' } });
      if (!taskDetail.case_uid) {
        apis.phpAxiosInstance.post('updateProjectTaskIssueId', metadata, {
          headers: { JWT: 'true' },
        });
      } else {
        apis.phpAxiosInstance.post('updateTaskIssueId', metadata, { headers: { JWT: 'true' } });
      }
      status = { status: 1, message: 'Task Added to Jira Service Desk Succesfully!' };
    }
  }
  return status;
};

export const getJiraProjectKeyFromProjectId = (jiraProjectsResponse: any[], projectId: string) => {
  if (jiraProjectsResponse && jiraProjectsResponse.length > 0) {
    return jiraProjectsResponse.filter((item: any) => item.jira_project_id == projectId)[0]['project_key'];
  }
  return null;
};

export const getJiraProjectIdFromProjectKey = (jiraProjectsResponse: any[], projectKey: string) => {
  if (jiraProjectsResponse && jiraProjectsResponse.length > 0) {
    return jiraProjectsResponse.filter((item: any) => item.project_key == projectKey)[0]['jira_project_id'];
  }
  return null;
};

export const getJiraProjectKeyFromProjectName = (jiraProjectsResponse: any[], projectName: string) => {
  if (jiraProjectsResponse && jiraProjectsResponse.length > 0) {
    return jiraProjectsResponse.filter((item: any) => item.jira_project_name == projectName)[0]['project_key'];
  }
  return null;
};

export const getJiraIssueName = (issueTypes: any, jira_issue_type: string, isJiraAlreadyEnabled: boolean) => {
  console.log('jira issues', issueTypes, jira_issue_type, isJiraAlreadyEnabled);
  if (issueTypes && issueTypes.length > 0) {
    if (isJiraAlreadyEnabled) {
      const filteredIssueTypes = issueTypes.filter((item: any) => {
        if (item !== 'Epic' || item !== 'Sub-task') {
          return item;
        }
      });
      return filteredIssueTypes.filter((item: any) => item.id == jira_issue_type)[0]['name'];
    }
    else {
      return issueTypes.filter((item: any) => item.id == jira_issue_type)[0]['name'];
    }
  }
  return null;
};

export const getJiraIssueId = (issueTypes: any, issueName: string) => {
  if (issueTypes && issueTypes.length > 0) {
    return issueTypes.filter((issue: any) => issue.name == issueName)[0]['id'];
  }
  return null;
};

export const getJiraCustomFieldNames = async (
  customer_jira_token: string,
  customer_jira_user: string,
  customer_uid: string,
  project_key: string,
  jiraIssue: Record<string, any>
) => {
  console.log('getcustom values', project_key, jiraIssue);
  const key = btoa(btoa(customer_jira_user + ':' + customer_jira_token));

  const customjiraUrl = { key: key, customer_uid: customer_uid, project_key: project_key };

  const data = await apis.phpAxiosInstance.post('getJiraCustomField', customjiraUrl, { headers: { JWT: 'true' } });

  const jiraUrlResult = JSON.parse(data.data.result);

  const issue_id = jiraIssue.id;
  const rand_array = jiraUrlResult.projects[0].issuetypes.filter(function (item1: any) {
    return item1.id === issue_id;
  });

  const filterByName = (obj: any, name: any) =>
    Object.values(obj).filter(function (o: any) {
      return o.name === name;
    });
  let field_obj: any = filterByName(rand_array[0].fields, 'Client Name');
  const customClientName: string = field_obj[0].key;
  // field_obj = filterByName(rand_array[0].fields, 'Use Case Name');
  // const customUseCaseName: string = field_obj[0].key;
  field_obj = filterByName(rand_array[0].fields, 'Delivery Date');
  const customDeliveryDate: string = field_obj[0].key;

  return { customClientName, customDeliveryDate };
};
