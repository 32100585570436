import { useState, useEffect } from 'react';
import { Mention, MentionsInput } from 'react-mentions';
import defaultStyle from 'components/TaskModule/TaskView/Comment/defaultStyle';
import defaultMentionStyle from 'components/TaskModule/TaskView/Comment/defaultMentionStyle';
import { Stack, Typography, Button, Box, Avatar } from '@mui/material';

import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { selectAddRAIDCommentStatus, selectDeleteRAIDCommentStatus, selectUpdateRAIDCommentStatus, selectRAIDCommentsStatus } from '../../../../store/modules/RAID/selector';
import { selectRAIDComments } from '../../../../store/modules/RAID/selector';
import CognisaasToast from 'components/shared/CognisaasToast/CognisaasToast';
import { getLoggedInUserName, getLoggedInUserUid, getUserLogoLinkByUid } from 'utils/getUid';
import { selectUsersOfCompany } from 'store/modules/Common/selector';
import { default as AvatarImage } from '../../../shared/Avatar/Avatar';
import CognissasCustomMenu from '../../../shared/CognisaasCustomMenu/CognisaasCustomMenu';

import DeleteModal from 'components/shared/CognisaasModal/DeleteModal';
import CognisaaasTooltip from 'components/shared/CognisaasTooltip/CognisaasTooltip';
import LockIcon from '@mui/icons-material/Lock';
import styled from 'styled-components';
import formatDistance from 'date-fns/formatDistance';

import {
  getRaidComments,
  addRaidComment,
  updateRaidComment,
  deleteRaidComment,
} from '../../../../store/modules/RAID/slice';
import { requestState } from 'store/modules/types';
import CognisaasSpinner from 'components/shared/CognisaasSpinner/CognisaasSpinner';
const Wrapper = styled.div`
  width: 100%;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: 40px;
    right: 37px;
    bottom: -6px;
    height: 1px;
    width: 100%;
    background-color: #e5e5e5;
  }
`;

const Frame = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 10px 14px;
  margin-top: 17px;

  &:hover {
    background: #f5f5f7;
  }
`;

const Comment = (props: any) => {
  const [commentText, setCommentText] = useState<string>('');
  const [comment, setComment] = useState<string>(props?.commentDetail?.comment || '');
  const [, setMentions] = useState<any[]>([]);
  const [users, setUsers] = useState<any[]>([]);
  const usersOfCompany = useAppSelector(selectUsersOfCompany);
  const [alertDetails, setAlertDetails] = useState<{
    isVisible: boolean;
    type: 'success' | 'failure';
    message: string;
  }>({ isVisible: false, type: 'success', message: '' });
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (usersOfCompany.length > 0) {
      getUserListArray();
    }
  }, [usersOfCompany]);

  const handleSaveButton = () => {
    if (props.type === 'Edit') {
      updateComment();
    } else if (props.type === 'Save') {
      saveComment();
    }
    if (props.setCommentId) {
      props.setCommentId('');
    }
    setComment('');
  };

  const handleCancelButton = () => {
    if (props.setCommentId) {
      props.setCommentId('');
    }
    setComment('');
  };

  const saveComment = async () => {
    console.log(props.raidDetails);
    const data = {
      comment: commentText,
      project_uid: props.raidDetails?.project_uid,
      raid_uid: props.raidDetails?.raid_uid,
      user_uid: getLoggedInUserUid(),
      user_username: getLoggedInUserName(),
    };

    await dispatch(addRaidComment(data));
    // const response = await raidApi.addRaidComment(data);
    // if (response?.data?.status === 0) {
    //     setAlertDetails({ isVisible: true, type: 'failure', message: response?.data?.message });
    //     return;
    // }
    // setAlertDetails({ isVisible: true, type: 'success', message: 'Comment saved successfully' });
    await dispatch(getRaidComments(data.raid_uid));
  };

  const updateComment = async () => {
    const data = {
      comment: commentText,
      project_uid: props.commentDetail?.project_uid,
      raid_uid: props.commentDetail?.raid_uid,
      user_uid: getLoggedInUserUid(),
      user_username: getLoggedInUserName(),
      comment_uid: props.commentDetail?.comment_uid,
    };
    await dispatch(updateRaidComment(data));
    // const response = await raidApi.updateRaidComment(data);
    // if (response?.data?.status === 0) {
    //     setAlertDetails({ isVisible: true, type: 'failure', message: response?.data?.message });
    //     return;
    // }
    // setAlertDetails({ isVisible: true, type: 'success', message: 'Comment updated successfully' });
    await dispatch(getRaidComments(data.raid_uid));
  };

  const getUserListArray = () => {
    const list = usersOfCompany.map((user: any) => ({ display: user.user_username, id: `company:${user.user_uid}` }));
    setUsers(list);
  };
  return (
    <>
      <Stack sx={{ width: '100%', fontFamily: 'Noto sans' }}>
        <MentionsInput
          style={defaultStyle}
          value={comment}
          placeholder="Type here"
          className="mention-input"
          onChange={(e: any, newValue: any, newPlainTextValue: any, mentions: any) => {
            setCommentText(newPlainTextValue);
            setComment(e.target.value);
            setMentions([...mentions]);
          }}
        >
          <Mention style={defaultMentionStyle} trigger="@" data={[...users]} />
        </MentionsInput>
        <Stack justifyContent={'flex-end'} direction="row">
          <Stack direction="row">
            <Button variant="text" onClick={() => handleSaveButton()}>
              Save
            </Button>
            <Button variant="text" onClick={() => handleCancelButton()}>
              Cancel
            </Button>
          </Stack>
        </Stack>
      </Stack>
      <CognisaasToast
        fromPanel={true}
        open={alertDetails.isVisible}
        onClose={() => setAlertDetails({ isVisible: false, type: alertDetails.type, message: '' })}
        message={alertDetails.message}
        type={alertDetails.type}
      />
    </>
  );
};

const CommentView = (props: any) => {
  const [commentId, setCommentId] = useState<string>('');
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const dispatch = useAppDispatch();
  const [selectedCommentForDeletion, setSelectedCommentForDeletion] = useState<Record<string, any>>({});
  const [alertDetails, setAlertDetails] = useState<{
    isVisible: boolean;
    type: 'success' | 'failure';
    message: string;
  }>({ isVisible: false, type: 'success', message: '' });
  function stringToHslColor(str: string, s: number, l: number) {
    let hash = 0;
    console.log(str);

    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    const h = hash % 360;
    return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
  }

  function stringAvatar(name: string) {
    console.log(name);

    return {
      sx: {
        bgcolor: stringToHslColor(name, 30, 80),
      },
      children: (
        <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#000000' }}>
          {name.split(' ')[0][0]}
          {name.indexOf(' ') >= 0 ? name.split(' ')[1][0] : null}
        </Typography>
      ),
    };
  }

  const generateCommentUi = (comment: string) => {
    const splitForUsers = comment.split('@[');
    return splitForUsers.map((item, index) => {
      if (index === 0) {
        return (
          <span key={index} style={{ whiteSpace: 'pre-line' }}>
            {item}
          </span>
        );
      } else {
        // index more than 0 something like this expected => vivek](customer:324234) great
        const strContainingUser = item;
        const arrContainingUser = strContainingUser.split('](');
        const username = arrContainingUser[0];
        const restStrArr = arrContainingUser[1].split(')');
        const customerUid = restStrArr[0];
        const restString = [...restStrArr.filter((item, index) => index > 0)].join('');
        return (
          <span key={index} style={{ whiteSpace: 'pre-line' }}>
            &nbsp;
            <span
              style={{
                backgroundColor: 'rgb(45, 96, 246, 0.1)',
                color: '#2D60F6',
                padding: '2px 10px',
                borderRadius: '33px',
                fontSize: '13px',
                cursor: 'pointer',
              }}
              id={customerUid}
            >
              {username}
            </span>
            {restString}
          </span>
        );
      }
    });
  };

  const handleDelete = async (comment: Record<string, any>) => {
    // const response = await raidApi.deleteRaidComment({
    //     comment_uid: comment.comment_uid,
    // });
    // if (response?.data?.status === 0) {
    //     setAlertDetails({ isVisible: true, type: 'failure', message: response?.data?.message });
    //     return;
    // }
    // setAlertDetails({ isVisible: true, type: 'success', message: 'Comment deleted successfully' });
    await dispatch(deleteRaidComment({ comment_uid: comment.comment_uid }));
    await dispatch(getRaidComments(comment.raid_uid));
  };

  const handleDeleteModal = (comment: Record<string, any>) => {
    setOpenDeleteModal(true);
    setSelectedCommentForDeletion(comment);
  };


  return (
    <>
      {console.log(props.commentsData, 'commentsDSara')}
      {[...props.commentsData].reverse().map((comment: any, index: number) => {
        return (
          <Wrapper key={comment.comment_uid || index}>
            <Frame>
              <Stack spacing={2} direction={'row'} sx={{ minHeight: '10px', wordBreak: 'break-all', width: '80%' }}>
                <Stack ml={2} direction="row">
                  {getUserLogoLinkByUid(comment.user_uid).length > 0 ? (
                    <AvatarImage src={getUserLogoLinkByUid(comment.user_uid)} alt="user profile" />
                  ) : (
                    <Avatar
                      style={{ height: '40px', width: '40px', marginTop: '3px' }}
                      {...stringAvatar(comment.user_username)}
                    />
                  )}
                </Stack>

                <Stack
                  direction={'column'}
                  sx={{ marginTop: '5px' }}
                  style={{ width: '100%', fontFamily: 'Noto sans' }}
                >
                  <Typography variant="subtitle1">{comment.user_username}</Typography>
                  {commentId === comment.comment_uid ? (
                    <Comment
                      raidDetails={props.raidDetails}
                      commentDetail={comment}
                      type="Edit"
                    />
                  ) : (
                    <Stack className="label-text" direction={'row'}>
                      {generateCommentUi(comment.comment)}
                    </Stack>
                  )}
                </Stack>
              </Stack>
              <Stack direction={'column'} m={2}>
                <Stack direction={'row'} justifyContent="center"
                  alignItems="center">
                  {comment.share_with_client == 0 &&
                    <CognisaaasTooltip
                      title={
                        <Box mb={1} sx={{ height: '12px', width: '185px', padding: '12px' }}>
                          <Typography variant="subtitle1">
                            This is an internal comment
                          </Typography>
                        </Box>
                      }
                    >
                      <LockIcon color='secondary' fontSize='medium' />
                    </CognisaaasTooltip>
                  }
                </Stack>
                <Stack direction={'row'}>
                  <Typography className="label-text" variant="subtitle1" style={{ fontSize: '12px' }}>
                    {formatDistance(new Date(comment.created_on.replace(/-/g, '/')), new Date(), {
                      includeSeconds: true,
                    })}{' '}
                    ago
                  </Typography>
                </Stack>
              </Stack>
              <Stack>
                <CognissasCustomMenu
                  list={[
                    {
                      value: 'Edit',
                      handleClick: setCommentId,
                      args: [comment.comment_uid],
                    },
                    {
                      value: 'Delete',
                      handleClick: handleDeleteModal,
                      args: [comment],
                    },
                  ]}
                />
              </Stack>
            </Frame>
            <DeleteModal
              isOpen={openDeleteModal}
              setIsOpen={setOpenDeleteModal}
              message={'Are you sure you want to delete this comment'}
              onDelete={() => {
                handleDelete(selectedCommentForDeletion);
              }}
            />
            <CognisaasToast
              fromPanel={true}
              open={alertDetails.isVisible}
              onClose={() => setAlertDetails({ isVisible: false, type: alertDetails.type, message: '' })}
              message={alertDetails.message}
              type={alertDetails.type}
            />
          </Wrapper>

        );
      })}
    </>
  );
};

const CommentSection = (props: any) => {
  const comments = useAppSelector(selectRAIDComments);
  const dispatch = useAppDispatch();
  const addRaidCommentStatus = useAppSelector(selectAddRAIDCommentStatus);
  const updateRaidCommentStatus = useAppSelector(selectUpdateRAIDCommentStatus);
  const deleteRaidCommentStatus = useAppSelector(selectDeleteRAIDCommentStatus);
  const selectRaidCommentsStatus = useAppSelector(selectRAIDCommentsStatus);
  const loading = addRaidCommentStatus === requestState.loading || updateRaidCommentStatus === requestState.loading || deleteRaidCommentStatus === requestState.loading || selectRaidCommentsStatus === requestState.loading;


  useEffect(() => {
    dispatch(getRaidComments(props.raidDetails.raid_uid));
  }, []);

  return (
    <Stack>
      <Comment
        raidDetails={props.raidDetails}
        type="Save"
      />
      {loading ? (<Stack style={{ width: '100%', height: '100%' }} direction="row" justifyContent="center" alignItems="center">
        <CognisaasSpinner size={50} />
      </Stack>) :
        <CommentView
          commentsData={comments}
          raidDetails={props.raidDetails}
        />
      }
    </Stack>
  );
};

export default CommentSection;