import {
  label_arr,
  label_client,
  label_delivery_date,
  label_due_date,
  label_project,
  label_project_delivery_manager,
  label_sales_owner,
  label_task,
  label_use_case,
  label_use_case_delivery_manager,
} from 'components/Settings/Preferences/CustomLabelCookieHelper';

export const clientEntityStandardFieldsDisplayName = {
  //   clientName: `${label_client} Name`,
  //   clientSegment: `${label_client} Segment`,
  //   clientIndustry: `${label_client} Industry`,
  //   clientStatus: `${label_client} Status`,
  //   clientSalesOwner: `${label_client} Sales Owner`,
  //   clientAccountOwner: `${label_client} Account Owner`,
  //   clientAccountWorth: `${label_client} ${label_arr}`,
  //   clientStage: `${label_client} Stage`,
  //   clientCreatedOn: `${label_client} Created On`,
  //   clientRenewalDate: `${label_client} Renewal Date`,
  //   clientRenewalDuration: `${label_client} Renewal Duration`,
  //   clientLastStatusReportSentTime: `${label_client} Last Status Report Sent Time`,
  //   clientComment: `${label_client} Comment`,

  name: `${label_client} Name`,
  segment: `${label_client} Segment`,
  industry: `${label_client} Industry`,
  status: `${label_client} Status`,
  salesOwner: `${label_client} ${label_sales_owner}`,
  accountOwner: `${label_client} Account Owner`,
  accountWorth: `${label_client} ${label_arr}`,
  stage: `${label_client} Stage`,
  createdOn: `${label_client} Created On`,
  renewalDate: `${label_client} Renewal Date`,
  renewalDuration: `${label_client} Renewal Duration`,
  lastStatusReportSentTime: `${label_client} Last Status Report Sent Time`,
  comment: `${label_client} Comment`,
};

export const projectEntityStandardFieldsDisplayName = {
  // projectName: `${label_project} Name`,
  // projectProgress: `${label_project} Progress (in %)`,
  // projectDescription: `${label_project} Description`,
  // projectFee: `${label_project} Fee`,
  // projectArr: `${label_project} Arr`,
  // projectReference: `${label_project} Reference`,
  // projectValue: `${label_project} Value`,
  // projectNeededStatus: `${label_project} Category`,
  // projectStatus: `${label_project} Status`,
  // projectExpansionValue: `${label_project} Expansion Value`,
  // projectPriority: `${label_project} Priority`,
  // projectPlannedStartDate: `${label_project} Planned Start Date`,
  // projectStartDate: `${label_project} Start Date`,
  // projectETAForDelivery: `${label_project} ETA for Delivery`,
  // projectRequiredByDate: `${label_project} ${label_due_date}`,
  // projectDeliveryDate: `${label_project} ${label_delivery_date}`,
  // projectCreatedOn: `${label_project} Created on`,
  // projectStage: `${label_project} Stage`,
  // projectDeliveryManager: `${label_project} Delivery Manager`,
  // projectComment: `${label_project} Comment`,

  name: `${label_project} Name`,
  progress: `${label_project} Progress (in %)`,
  description: `${label_project} Description`,
  fee: `${label_project} Implementation Fee`,
  arr: `${label_project} ${label_arr}`,
  implementationFeeBilled: `${label_project} Implementation Fee Billed`,
  implementationFeePending: `${label_project} Implementation Fee Pending`,
  reference: `${label_project} Reference`,
  value: `${label_project} Value`,
  neededStatus: `${label_project} Category`,
  status: `${label_project} Status`,
  expansionValue: `${label_project} Expansion Value`,
  priority: `${label_project} Priority`,
  plannedStartDate: `${label_project} Planned Start Date`,
  startDate: `${label_project} Start Date`,
  etaForDelivery: `${label_project} Estimated Delivery Date`,
  requiredByDate: `${label_project} ${label_due_date}`,
  deliveryDate: `${label_project} ${label_delivery_date}`,
  createdOn: `${label_project} Created on`,
  stage: `${label_project} Stage`,
  deliveryManager: `${label_project} ${label_project_delivery_manager}`,
  comment: `${label_project} Comment`,
};

export const usecaseEntityStandardFieldsDisplayName = {
  //   caseName: `${label_use_case} Name`,
  //   caseProgress: `${label_use_case} Progress (in %)`,
  //   caseDescription: `${label_use_case} Description`,
  //   caseFee: `${label_use_case} Fee`,
  //   caseReference: `${label_use_case} Reference`,
  //   caseNeededStatus: `${label_use_case} Category`,
  //   caseStatus: `${label_use_case} Status`,
  //   caseExpansionValue: `${label_use_case} Expansion Value`,
  //   caseRequiredByDate: `${label_use_case} ${label_due_date}`,
  //   casePriority: `${label_use_case} Priority`,
  //   caseStartDate: `${label_use_case} Start Date`,
  //   casePlannedStartDate: `${label_use_case} Planned Start Date`,
  //   caseDeliveryDate: `${label_use_case} ${label_delivery_date}`,
  //   caseCreatedOn: `${label_use_case} Created On`,
  //   caseStage: `${label_use_case} Stage`,
  //   caseDeliveryManager: `${label_use_case} Delivery Manager`,
  //   caseComment: `${label_use_case} Comment`,
  //   caseETAForDelivery: `${label_use_case} ETA for Delivery`,

  name: `${label_use_case} Name`,
  progress: `${label_use_case} Progress (in %)`,
  description: `${label_use_case} Description`,
  fee: `${label_use_case} Implementation Fee`,
  reference: `${label_use_case} Reference`,
  neededStatus: `${label_use_case} Category`,
  status: `${label_use_case} Status`,
  expansionValue: `${label_use_case} Expansion Value`,
  requiredByDate: `${label_use_case} ${label_due_date}`,
  priority: `${label_use_case} Priority`,
  startDate: `${label_use_case} Start Date`,
  plannedStartDate: `${label_use_case} Planned Start Date`,
  deliveryDate: `${label_use_case} ${label_delivery_date}`,
  createdOn: `${label_use_case} Created On`,
  stage: `${label_use_case} Stage`,
  deliveryManager: `${label_use_case} ${label_use_case_delivery_manager}`,
  comment: `${label_use_case} Comment`,
  etaForDelivery: `${label_use_case} ETA for Delivery`,
};

export const taskEntityStandardFieldsDisplayName = {
  //   taskName: `${label_task} Name`,
  //   taskOwner: `${label_task} Owner`,
  //   taskType: `${label_task} Type`, // team or client
  //   taskPriority: `${label_task} Priority`,
  //   taskDescription: `${label_task} Description`,
  //   taskStage: `${label_task} Stage`,
  //   taskStatus: `${label_task} Status`,
  //   taskDuedate: `${label_task} ${label_due_date}`,
  //   taskClosedDate: `${label_task} ${label_delivery_date}`,
  //   taskStartDate: `${label_task} Start Date`,
  //   taskPlannedStartDate: `${label_task} Planned Start Date`,
  //   taskCreatedOn: `${label_task} Created On`,
  //   taskEffortEstimate: `${label_task} Planned Duration`,
  //   taskTotalTimeSpent: `${label_task} Actual Duration`,
  //   taskTotalTimeRemaining: `${label_task} Time Remaining`,
  //   taskClientCheck: `Is ${label_task} Shared to Client`,
  //   taskClientInitiated: `Is Client Initiated ${label_task}`,
  //   taskMilestoneTask: `Is Milestone ${label_task}`,
  //   taskCsatIndicator: `${label_task} CSAT Indicator`,
  //   taskLastModifiedBy: `${label_task} Last Modified By`,
  //   taskJiraIndicator: `${label_task} Jira Indicator`,
  //   taskImplementationFeePercentage: `${label_task} Implementation Fee Percentage`,

  name: `${label_task} Name`,
  owner: `${label_task} Owner`,
  type: `${label_task} Type`, // team or client
  priority: `${label_task} Priority`,
  description: `${label_task} Description`,
  stage: `${label_task} Stage`,
  status: `${label_task} Status`,
  duedate: `${label_task} ${label_due_date}`,
  closedDate: `${label_task} ${label_delivery_date}`,
  startDate: `${label_task} Start Date`,
  plannedStartDate: `${label_task} Planned Start Date`,
  createdOn: `${label_task} Created On`,
  effortEstimate: `${label_task} Planned Duration`,
  totalTimeSpent: `${label_task} Actual Duration`,
  totalTimeRemaining: `${label_task} Time Remaining`,
  clientCheck: `Is ${label_task} Shared to Client`,
  clientInitiated: `Is Client Initiated ${label_task}`,
  milestoneTask: `Is Milestone ${label_task}`,
  csatIndicator: `${label_task} CSAT Indicator`,
  lastModifiedBy: `${label_task} Last Modified By`,
  jiraIndicator: `${label_task} Jira Indicator`,
  implementationFeePercentage: `${label_task} Implementation Fee Percentage`,
};
