import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { requestState } from '../types';
import { usecaseAPI } from '../../../api/modules/usecase';
import { actionableInsightAPI } from '../../../api/modules/actionableInsights';

import { filterDataHavingValueThis } from '../../../utils/filteringFunctions';
import { isNull, isUndefined } from '../../../utils/dataHelpers';

// fetching all projects by customer uid
export const fetchRequiredProjects = createAsyncThunk('projects/fetchRequiredProjects', async () => {
  const { data } = await actionableInsightAPI.getAllProjectsList();
  return data.result;
});

export const fetchOpenUsecases = createAsyncThunk('fetchAllOpenUsecases', async () => {
  const { data } = await usecaseAPI.getOpenUsecases();
  return data.result;
});

export const fetchProjectsRevenueByCustomer = createAsyncThunk('fetchRevenueOfProjects', async () => {
  const { data } = await actionableInsightAPI.getRevenueOfProjects();
  return data.result;
});

export const fetchRecommendations = createAsyncThunk('fetchRecommendations', async () => {
  const { data } = await actionableInsightAPI.getRecommendations();
  return data.result;
});

export const fetchProjectRecommendations = createAsyncThunk('fetchProjectRecommendations', async () => {
  const { data } = await actionableInsightAPI.getTopProjectRecommendations();
  return data.result;
});

export const fetchUsecaseRecommendation = createAsyncThunk('fetchUsecaseRecommendation', async () => {
  const { data } = await actionableInsightAPI.getTopUsecaseRecommendations();
  return data.result;
});

export const fetchTaskRecommendations = createAsyncThunk('fetchTaskRecommendations', async () => {
  const { data } = await actionableInsightAPI.getTopTaskRecommendations();
  return data.result;
});

export const fetchGoodFitAccounts = createAsyncThunk('fetchGoodFitAccounts', async () => {
  const { data } = await actionableInsightAPI.getReportListWithAllCaseGreen();
  return data.result;
});

export const fetchBadFitAccounts = createAsyncThunk('fetchBadFitAccounts', async () => {
  const { data } = await actionableInsightAPI.getReportListWithAllCaseNoneGreen();
  return data.result;
});

// client mapping
export const fetchTopLandUsecaseMapping = createAsyncThunk('actionableInsight/fetchTopLandUsecaseMapping', async () => {
  const { data } = await actionableInsightAPI.getTopLandUsecaseMapping();
  return data.result;
});

export const fetchTopExpandUsecaseMapping = createAsyncThunk(
  'actionableInsight/fetchTopExpandUsecaseMapping',
  async () => {
    const { data } = await actionableInsightAPI.getTopExpandUsecaseMapping();
    return data.result;
  }
);

export const fetchTopLandProductMapping = createAsyncThunk('actionableInsight/fetchTopLandProductMapping', async () => {
  const { data } = await actionableInsightAPI.getTopLandProductMapping();
  return data.result;
});

export const fetchTopExpandProductMapping = createAsyncThunk(
  'actionableInsight/fetchTopExpandProductMapping',
  async () => {
    const { data } = await actionableInsightAPI.getTopExpandProductMapping();
    return data.result;
  }
);

export const fetchTemplateInformationbyTemplateUid = createAsyncThunk(
  'actionableInsight/fetchTemplateInformationbyTemplateUid',
  async (template_uid: string) => {
    const { data } = await actionableInsightAPI.getTemplateInfoByTemplateuid(template_uid);
    return data.result;
  }
);

interface FeatureUidsPayload {
  customer_uid: string;
  feature_uid: string;
}

// get client information by using multiple feature uids
export const fetchClientInfoByMultiplefeatureUids = createAsyncThunk(
  'actionableInsight/fetchClientInfoByMultiplefeatureUids',
  async (payload: FeatureUidsPayload[]) => {
    const { data } = await actionableInsightAPI.getClientInformationByMultipleFeatureUids(payload);
    return data.result;
  }
);

export const fetchAllUsecasesWhereDeliveryManagerisNotAssigned = createAsyncThunk(
  'actionableInsights/getUsecasesWhereDeliveryManagerisNotAssigned',
  async (user_uid: string) => {
    const { data } = await actionableInsightAPI.getUsecasesWhereDeliveryManagerIsNotAssigned(user_uid);
    return data.result ?? [];
  }
);

export const fetchAllProjectWhereDeliveryManagerisNotAssigned = createAsyncThunk(
  'actionableInsights/getProjectWhereDeliveryManagerisNotAssigned',
  async (user_uid: string) => {
    const { data } = await actionableInsightAPI.getProjectWhereDeliveryManagerIsNotAssigned(user_uid);
    return data.result ?? [];
  }
);

interface StateProps {
  openProjects: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };
  openUsecases: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };
  projectRevenue: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };
  revenueImpactData: {
    data: {
      totalProjectsOverdueOrLoss: number;
      totalProjectsAtRisk: number;
      totalProjectsOnTrack: number;
      totalUsecasesOverdueOrLoss: number;
      totalUsecasesAtRisk: number;
      totalUsecasesOnTrack: number;
      revenueOfOnTrack: any[];
      revenueOfAtRisk: any[];
      revenueOfOverdue: any[];
    };
    status:
    | 'getting Open Projects'
    | 'got Open Projects'
    | 'getting all usecases'
    | 'got all usecases'
    | 'analyzing data'
    | 'success'
    | 'idle'
    | string;
  };
  projectsOverdueOrAtLoss: any[];
  projectsAtRisk: any[];
  projectsOnTrack: any[];
  usecasesOverdueOrAtLoss: any[];
  usecasesAtRisk: any[];
  usecasesOnTrack: any[];
  goodFitAccounts: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };
  badFitAccounts: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };
  topLandUsecaseMapping: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };
  topExpandUsecaseMapping: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };
  topLandProductMapping: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };
  topExpandProductMapping: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };
  revenueImpactRecommendations: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };
  usecaseRecommendations: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };
  projectRecommendations: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };
  taskRecommendations: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };
  clientMappedTemplateInfo: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };
  clientMappedToFeatures: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };
  fetchAllUsecasesWhereDeliveryManagerisNotAssigned: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };

  fetchAllProjectWhereDeliveryManagerisNotAssigned: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };
}

const initialState: StateProps = {
  openProjects: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  openUsecases: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  projectRevenue: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  revenueImpactData: {
    data: {
      totalProjectsOverdueOrLoss: 0,
      totalProjectsAtRisk: 0,
      totalProjectsOnTrack: 0,
      totalUsecasesOverdueOrLoss: 0,
      totalUsecasesAtRisk: 0,
      totalUsecasesOnTrack: 0,
      revenueOfOnTrack: [],
      revenueOfAtRisk: [],
      revenueOfOverdue: [],
    },
    status: 'idle',
  },
  projectsOverdueOrAtLoss: [],
  projectsAtRisk: [],
  projectsOnTrack: [],
  usecasesOverdueOrAtLoss: [],
  usecasesAtRisk: [],
  usecasesOnTrack: [],
  goodFitAccounts: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  badFitAccounts: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  topLandUsecaseMapping: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  topExpandUsecaseMapping: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  topLandProductMapping: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  topExpandProductMapping: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  revenueImpactRecommendations: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  usecaseRecommendations: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  projectRecommendations: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  taskRecommendations: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  clientMappedTemplateInfo: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  clientMappedToFeatures: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  fetchAllUsecasesWhereDeliveryManagerisNotAssigned: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  fetchAllProjectWhereDeliveryManagerisNotAssigned: {
    data: [],
    status: requestState.idle,
    error: null,
  },
};

export const actionableInsightsSlice = createSlice({
  name: 'actionable insights',
  initialState,
  reducers: {
    resetClientMappedInfo: (state: StateProps) => {
      state.clientMappedTemplateInfo = initialState.clientMappedTemplateInfo;
    },
  },
  extraReducers(builder) {
    builder
      // fetching open projects and handling it
      .addCase(fetchRequiredProjects.pending, (state) => {
        state.openProjects.status = requestState.loading;
        state.revenueImpactData.status = 'getting Open Projects';
      })
      .addCase(fetchRequiredProjects.fulfilled, (state: StateProps, action) => {
        state.openProjects.status = requestState.success;
        state.revenueImpactData.status = 'got Open Projects';
        console.log('success');
        console.log(action.payload.length);
        state.openProjects.data = action.payload;

        state.revenueImpactData.status = 'analyzing Data';

        // overdue projects
        const filteredOverdueProjectsResult = filterDataHavingValueThis(
          action.payload,
          'project_readinessstate_status',
          'red'
        );
        state.revenueImpactData.data.totalProjectsOverdueOrLoss = filteredOverdueProjectsResult.length;
        state.projectsOverdueOrAtLoss = filteredOverdueProjectsResult;

        // At Risk projects
        const filteredAtRiskProjectsResult = filterDataHavingValueThis(
          action.payload,
          'project_readinessstate_status',
          'amber'
        );
        state.revenueImpactData.data.totalProjectsAtRisk = filteredAtRiskProjectsResult.length;
        state.projectsAtRisk = filteredAtRiskProjectsResult;

        // On Track projects
        const filteredOnTrackProjectsResult = filterDataHavingValueThis(
          action.payload,
          'project_readinessstate_status',
          'green'
        );
        state.revenueImpactData.data.totalProjectsOnTrack = filteredOnTrackProjectsResult.length;
        state.projectsOnTrack = filteredOnTrackProjectsResult;

        state.revenueImpactData.status = 'Projects Analyzed';
      })
      // fetching open usecases
      .addCase(fetchRequiredProjects.rejected, (state, action) => {
        state.openProjects.status = requestState.failed;
        state.openProjects.error = action.error.message;
        state.revenueImpactData.status = 'Failed Fetching Usecases!';
      })
      .addCase(fetchOpenUsecases.pending, (state) => {
        state.openUsecases.status = requestState.loading;
      })
      .addCase(fetchOpenUsecases.fulfilled, (state, action) => {
        state.openUsecases.status = requestState.success;
        state.revenueImpactData.status = 'got Open Usecases';
        // console.log('success');
        // console.log(action.payload.length);
        state.openUsecases.data = action.payload;

        state.revenueImpactData.status = 'analyzing Data';

        // overdue usecases
        const filteredOverdueUsecasesResult = filterDataHavingValueThis(
          action.payload,
          'case_readinessstate_status',
          'red'
        );
        state.revenueImpactData.data.totalUsecasesOverdueOrLoss = filteredOverdueUsecasesResult.length;
        state.usecasesOverdueOrAtLoss = filteredOverdueUsecasesResult;

        // At Risk projects
        const filteredAtRiskUsecasesResult = filterDataHavingValueThis(
          action.payload,
          'case_readinessstate_status',
          'amber'
        );
        state.revenueImpactData.data.totalUsecasesAtRisk = filteredAtRiskUsecasesResult.length;
        state.usecasesAtRisk = filteredAtRiskUsecasesResult;

        // On Track projects
        const filteredOnTrackUsecasesResult = filterDataHavingValueThis(
          action.payload,
          'case_readinessstate_status',
          'green'
        );
        state.revenueImpactData.data.totalUsecasesOnTrack = filteredOnTrackUsecasesResult.length;
        state.usecasesOnTrack = filteredOnTrackUsecasesResult;

        state.revenueImpactData.status = 'usecases Analyzed';
      })
      .addCase(fetchOpenUsecases.rejected, (state, action) => {
        state.openUsecases.status = requestState.failed;
        state.openUsecases.error = action.error.message;
      })
      .addCase(fetchProjectsRevenueByCustomer.pending, (state) => {
        state.projectRevenue.status = requestState.loading;
      })
      .addCase(fetchProjectsRevenueByCustomer.fulfilled, (state, action) => {
        state.projectRevenue.status = requestState.success;
        state.revenueImpactData.status = 'got Land Expand Revenue';
        state.projectRevenue.data = action.payload;

        // filtering and adding revenues of different readiness status
        state.revenueImpactData.data.revenueOfOnTrack = filterDataHavingValueThis(
          action.payload,
          'client_readiness_status',
          'green'
        );
        state.revenueImpactData.data.revenueOfAtRisk = filterDataHavingValueThis(
          action.payload,
          'client_readiness_status',
          'amber'
        );
        state.revenueImpactData.data.revenueOfOverdue = filterDataHavingValueThis(
          action.payload,
          'client_readiness_status',
          'red'
        );
        state.revenueImpactData.status = 'land and expand revenue Data Analyzed';
      })
      .addCase(fetchProjectsRevenueByCustomer.rejected, (state, action) => {
        state.projectRevenue.status = requestState.failed;
        state.projectRevenue.error = action.error.message;
      })
      .addCase(fetchRecommendations.pending, (state) => {
        // non need to track
        // console.log('fetch recommendation', state);
        state.revenueImpactRecommendations.status = requestState.loading;
      })
      .addCase(fetchRecommendations.fulfilled, (state: StateProps, action) => {
        // set state for recommendations
        state.revenueImpactRecommendations.data = action.payload;
        state.revenueImpactRecommendations.status = requestState.success;
      })
      .addCase(fetchRecommendations.rejected, (state: StateProps) => {
        state.revenueImpactRecommendations.status === requestState.failed;
        state.revenueImpactRecommendations.error = 'Unable To fetch revenueImpact Recommendations!';
      })
      // project recommendations
      .addCase(fetchProjectRecommendations.pending, (state) => {
        state.projectRecommendations.status = requestState.loading;
      })
      .addCase(fetchProjectRecommendations.fulfilled, (state: StateProps, action) => {
        // set state for recommendations
        state.projectRecommendations.data = action.payload;
        state.projectRecommendations.status = requestState.success;
      })
      .addCase(fetchProjectRecommendations.rejected, (state: StateProps) => {
        state.projectRecommendations.status === requestState.failed;
        state.projectRecommendations.error = 'Unable To fetch project Recommendations!';
      })
      // usecase recommendations
      .addCase(fetchUsecaseRecommendation.pending, (state) => {
        state.usecaseRecommendations.status = requestState.loading;
      })
      .addCase(fetchUsecaseRecommendation.fulfilled, (state: StateProps, action) => {
        state.usecaseRecommendations.data = action.payload;
        state.usecaseRecommendations.status = requestState.success;
      })
      .addCase(fetchUsecaseRecommendation.rejected, (state: StateProps) => {
        state.usecaseRecommendations.status === requestState.failed;
        state.usecaseRecommendations.error = 'Unable To fetch usecase Recommendations!';
      })
      // task recommendations
      .addCase(fetchTaskRecommendations.pending, (state) => {
        state.taskRecommendations.status = requestState.loading;
      })
      .addCase(fetchTaskRecommendations.fulfilled, (state: StateProps, action) => {
        state.taskRecommendations.data = action.payload;
        state.taskRecommendations.status = requestState.success;
      })
      .addCase(fetchTaskRecommendations.rejected, (state: StateProps) => {
        state.taskRecommendations.status === requestState.failed;
        state.taskRecommendations.error = 'Unable To fetch task Recommendations!';
      })
      .addCase(fetchGoodFitAccounts.pending, (state) => {
        state.goodFitAccounts.status = requestState.loading;
      })
      .addCase(fetchGoodFitAccounts.fulfilled, (state: StateProps, action) => {
        state.goodFitAccounts.status = requestState.success;
        state.goodFitAccounts.data = action.payload.filter((item: any) => {
          return item.usecases !== 0;
        });
      })
      .addCase(fetchGoodFitAccounts.rejected, (state, action) => {
        state.goodFitAccounts.status = requestState.failed;
        state.goodFitAccounts.error = action.error.message;
      })
      // bad fit accounts
      .addCase(fetchBadFitAccounts.pending, (state) => {
        state.badFitAccounts.status = requestState.loading;
      })
      .addCase(fetchBadFitAccounts.fulfilled, (state: StateProps, action) => {
        state.badFitAccounts.status = requestState.success;
        state.badFitAccounts.data = action.payload.filter((item: any) => {
          return item.usecases !== 0;
        });
      })
      .addCase(fetchBadFitAccounts.rejected, (state, action) => {
        state.badFitAccounts.status = requestState.failed;
        state.badFitAccounts.error = action.error.message;
      })
      // land usecase client top 3 mapping
      .addCase(fetchTopLandUsecaseMapping.pending, (state) => {
        state.topLandUsecaseMapping.status = requestState.loading;
      })
      .addCase(fetchTopLandUsecaseMapping.fulfilled, (state: StateProps, action) => {
        state.topLandUsecaseMapping.status = requestState.success;
        if (!(isNull(action.payload) || isUndefined(action.payload))) {
          state.topLandUsecaseMapping.data = action.payload;
        }
      })
      .addCase(fetchTopLandUsecaseMapping.rejected, (state, action) => {
        state.topLandUsecaseMapping.status = requestState.failed;
        state.topLandUsecaseMapping.error = action.error.message;
      })
      // expand usecase client top 3 mapping
      .addCase(fetchTopExpandUsecaseMapping.pending, (state) => {
        state.topExpandUsecaseMapping.status = requestState.loading;
      })
      .addCase(fetchTopExpandUsecaseMapping.fulfilled, (state: StateProps, action) => {
        state.topExpandUsecaseMapping.status = requestState.success;
        if (!(isNull(action.payload) || isUndefined(action.payload))) {
          state.topExpandUsecaseMapping.data = action.payload;
        }
      })
      .addCase(fetchTopExpandUsecaseMapping.rejected, (state, action) => {
        state.topExpandUsecaseMapping.status = requestState.failed;
        state.topExpandUsecaseMapping.error = action.error.message;
      })
      // land product client top 3 mapping
      .addCase(fetchTopLandProductMapping.pending, (state) => {
        state.topLandProductMapping.status = requestState.loading;
      })
      .addCase(fetchTopLandProductMapping.fulfilled, (state: StateProps, action) => {
        state.topLandProductMapping.status = requestState.success;
        if (!(isNull(action.payload) || isUndefined(action.payload))) {
          state.topLandProductMapping.data = action.payload;
        }
      })
      .addCase(fetchTopLandProductMapping.rejected, (state, action) => {
        state.topLandProductMapping.status = requestState.failed;
        state.topLandProductMapping.error = action.error.message;
      })

      // Get usecases where delivery manager is not assigned
      .addCase(fetchAllUsecasesWhereDeliveryManagerisNotAssigned.pending, (state) => {
        state.fetchAllUsecasesWhereDeliveryManagerisNotAssigned.status = requestState.loading;
      })
      .addCase(fetchAllUsecasesWhereDeliveryManagerisNotAssigned.fulfilled, (state: StateProps, action) => {
        state.fetchAllUsecasesWhereDeliveryManagerisNotAssigned.status = requestState.success;
        state.fetchAllUsecasesWhereDeliveryManagerisNotAssigned.data = action.payload;
      })
      .addCase(fetchAllUsecasesWhereDeliveryManagerisNotAssigned.rejected, (state, action) => {
        state.fetchAllUsecasesWhereDeliveryManagerisNotAssigned.status = requestState.failed;
        state.fetchAllUsecasesWhereDeliveryManagerisNotAssigned.error = action.error.message;
      })

      // Get project where delivery manager is not assigned
      .addCase(fetchAllProjectWhereDeliveryManagerisNotAssigned.pending, (state) => {
        state.fetchAllProjectWhereDeliveryManagerisNotAssigned.status = requestState.loading;
      })
      .addCase(fetchAllProjectWhereDeliveryManagerisNotAssigned.fulfilled, (state: StateProps, action) => {
        state.fetchAllProjectWhereDeliveryManagerisNotAssigned.status = requestState.success;
        state.fetchAllProjectWhereDeliveryManagerisNotAssigned.data = action.payload;
      })
      .addCase(fetchAllProjectWhereDeliveryManagerisNotAssigned.rejected, (state, action) => {
        state.fetchAllProjectWhereDeliveryManagerisNotAssigned.status = requestState.failed;
        state.fetchAllProjectWhereDeliveryManagerisNotAssigned.error = action.error.message;
      })

      // expand product client top 3 mapping
      .addCase(fetchTopExpandProductMapping.pending, (state) => {
        state.topExpandProductMapping.status = requestState.loading;
      })
      .addCase(fetchTopExpandProductMapping.fulfilled, (state: StateProps, action) => {
        state.topExpandProductMapping.status = requestState.success;
        if (!(isNull(action.payload) || isUndefined(action.payload))) {
          state.topExpandProductMapping.data = action.payload;
        }
      })
      .addCase(fetchTopExpandProductMapping.rejected, (state, action) => {
        state.topExpandProductMapping.status = requestState.failed;
        state.topExpandProductMapping.error = action.error.message;
      })
      // fetchTemplateInformationbyTemplateUid
      .addCase(fetchTemplateInformationbyTemplateUid.pending, (state) => {
        state.clientMappedTemplateInfo.status = requestState.loading;
      })
      .addCase(fetchTemplateInformationbyTemplateUid.fulfilled, (state: StateProps, action) => {
        state.clientMappedTemplateInfo.status = requestState.success;
        if (!(isNull(action.payload) || isUndefined(action.payload))) {
          state.clientMappedTemplateInfo.data = action.payload;
          setTimeout(() => {
            // console.log('reset ststus of mapped data for furthur use');
            state.clientMappedTemplateInfo.status = requestState.idle;
          }, 1000);
        }
      })
      .addCase(fetchTemplateInformationbyTemplateUid.rejected, (state, action) => {
        state.clientMappedTemplateInfo.status = requestState.failed;
        state.clientMappedTemplateInfo.error = action.error.message;
      })
      // fetch mapped clients from features
      .addCase(fetchClientInfoByMultiplefeatureUids.pending, (state) => {
        state.clientMappedToFeatures.status = requestState.loading;
      })
      .addCase(fetchClientInfoByMultiplefeatureUids.fulfilled, (state: StateProps, action) => {
        state.clientMappedToFeatures.status = requestState.success;
        if (!(isNull(action.payload) || isUndefined(action.payload))) {
          state.clientMappedToFeatures.data = action.payload;
          state.clientMappedToFeatures.status = requestState.idle;
        }
      })
      .addCase(fetchClientInfoByMultiplefeatureUids.rejected, (state, action) => {
        state.clientMappedToFeatures.status = requestState.failed;
        state.clientMappedToFeatures.error = action.error.message;
      });
  },
});

const actionableInsightsReducer = actionableInsightsSlice.reducer;
// const { resetClientMappedInfo } = actionableInsightsSlice.actions;
export default actionableInsightsReducer;
