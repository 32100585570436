import { Typography, Stack } from '@mui/material';
import CognisaasTabs from 'components/shared/CognisaasTabs';
import { templateHomeTabs, TemplateHomeTabsType } from '../../types';
import CognisaasButton from 'components/shared/CognisaasButton/CognisaasButton';
import { useNavigate } from 'react-router';
import { openNewDocumentTemplateModal } from 'store/modules/Templates/Templates';
import { useAppDispatch } from '../../../../../store/hooks';
import { label_use_case, label_project } from 'components/Settings/Preferences/CustomLabelCookieHelper';

interface props {
  onTabChangeHandler: (index: number) => void;
  selectedTemplatesType: TemplateHomeTabsType[number];
}

const TopHeader = (props: props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack my={2} ml={3}>
        <Stack>
          <Typography sx={{ fontSize: '20px', fontWeight: '500' }}>Templates</Typography>
        </Stack>
        <Stack mt={2}>
          <CognisaasTabs
            size="squeezed"
            tabs={templateHomeTabs}
            handleTabSwitch={(e: any, index: number) => props.onTabChangeHandler(index)}
          />
        </Stack>
      </Stack>
      <Stack mt={2}>
        <CognisaasButton
          isOutlined={false}
          clickHandler={() => {
            if (props.selectedTemplatesType == templateHomeTabs[0]) {
              return navigate('/templates/createnewtemplate');
            } else if (props.selectedTemplatesType == templateHomeTabs[1]) {
              return navigate('/templates/createnewusecasetemplate');
            } else if (props.selectedTemplatesType == templateHomeTabs[2]) {
              dispatch(openNewDocumentTemplateModal());
            }
          }}
          label={
            props.selectedTemplatesType == templateHomeTabs[0]
              ? `Add ${label_project} Template`
              : props.selectedTemplatesType == templateHomeTabs[1]
              ? `Add ${label_use_case}  Template`
              : props.selectedTemplatesType == templateHomeTabs[2]
              ? 'New Document Template'
              : ''
          }
        />
      </Stack>
    </Stack>
  );
};

export default TopHeader;
