import { SvgText } from 'components/ResourceManagement/design-system/primitives';
import React from 'react';
import styled from 'styled-components';

const StyledSvgLine = styled('line')`
    stroke: ${({ theme }) => theme.colors.grey_3};
    stroke-width: 2;
`;
const StyledCalendarTopText = styled(SvgText)`
    text-anchor: middle;
   fill: ${({ theme }) => theme.colors.grey_1};
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
`;

type TopPartOfCalendarProps = {
  value: string;
  x1Line: number;
  y1Line: number;
  y2Line: number;
  xText: number;
  yText: number;
};

export const TopPartOfCalendar: React.FC<TopPartOfCalendarProps> = ({
  value,
  x1Line,
  y1Line,
  y2Line,
  xText,
  yText,
}) => {
  return (
    <g className='calendarTop'>
      <StyledSvgLine
        x1={x1Line}
        y1={y1Line}
        x2={x1Line}
        y2={y2Line}
        // className={styles.calendarTopTick}
        key={value + 'line'}
      />
      <StyledCalendarTopText
        key={value + 'text'}
        y={yText}
        x={xText}
        // className={styles.calendarTopText}
        variant="paragraph1"
      >
        {value}
      </StyledCalendarTopText>
    </g>
  );
};
