import { Avatar, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import {
  DataGridPremium,
  DataGridPremiumProps,
  gridClasses,
  gridFilteredDescendantCountLookupSelector,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridToolbar,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-premium';
import {
  label_client,
  label_delivery_date,
  label_due_date,
  label_project,
  label_task,
  label_use_case,
} from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { Link } from 'react-router-dom';
import { SelectEditDateInputCell, SelectEditDropdownCell } from 'components/shared/Tables/InlineEditTable';
import { useDispatch } from 'react-redux';
import { updateTask, updateTaskCustomField } from 'store/modules/Task/slice';
import { getContactUidByContactName, getUserUid } from 'utils/getUid';
import { selectAllProjectMembersForClientContact, selectUsersOfCompany } from 'store/modules/Common/selector';
import { useAppSelector } from 'store/hooks';
import ViewDate from 'components/shared/ViewDate/ViewDate';
import { useEffect, useState } from 'react';
import { getTaskDeltaXWithDueDate, getTaskTimeTrackingFields } from 'components/Reports/Tasks/utils';
import { alpha, styled } from '@mui/material/styles';
import { generateTableColumnsWithCustomFiltersMapped } from 'components/shared/Tables/CogniTable';
import { requestState } from 'store/modules/types';
import NoDataFound from 'components/shared/NoRecords/NoDataFound';
import CognisaasLoader from 'components/shared/CognisaasLoader/CognisaasLoader';
import CognisaaasTooltip from 'components/shared/CognisaasTooltip/CognisaasTooltip';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  fetchClientClosedTasks,
  fetchClientOpenTasks,
  fetchTeamClosedTasks,
  fetchTeamOpenTasks,
} from 'store/modules/Reports/AllTasks/slice';
import { customerFieldTaskRecords } from 'store/modules/Settings/selector';
import CognisaasTaskStageIndicator from 'components/shared/CognisaasTaskStageIndicator/CognisaasTaskStageIndicator';
import CognisaasCheckBox from 'components/shared/CognisaasCheckbox/CognisaasCheckbox';
import { fetchClientList } from 'store/modules/ManageClient/ClientList/slice';
import checkAccessForRouting from 'components/shared/Hooks/checkAccessForRouting';
import { selectClientList } from 'store/modules/ManageClient/ClientList/selector';
import { getAllProjectsOfCustomer } from 'store/modules/Project/slice';
import { selectProjectList } from 'store/modules/Project/selector';
import useCommonAppConfig from 'components/Common/useCommonAppConfig';
import useCustomNames from 'components/shared/CustomNames/useCustomNames';
import { getUserActiveState, INACTIVE_USER_COLOR } from 'utils/userHelpers';
import { getMaxColumnWidth } from 'utils/getMaxColumnWidth';
import { format } from 'date-fns';
import { statusColorMap } from 'components/shared/GanttCharts/GanttComponent/config';
import { isNull } from 'utils/dataHelpers';

interface Props {
  data: any[];
  openUpdateTaskDrawer: (taskDetail: Record<string, any>) => void;
  filteredData: any[];
  innerTab: 'all' | 'open' | 'closed';
}

const generateUniqueId = (): string => {
  return Date.now().toString(36) + Math.floor(Math.pow(10, 12) + Math.random() * 9 * Math.pow(10, 12)).toString(36);
};

const TaskTableView = (props: Props) => {
  const ODD_OPACITY = 0.2;
  const dispatch = useDispatch();
  const allProjectMembersWhichAreClientContactForCustomer = useAppSelector(selectAllProjectMembersForClientContact);
  const users = useAppSelector(selectUsersOfCompany);
  const usersOfCompany = [
    'Not Assigned',
    ...users.filter((user: any) => user.is_active === '1').map((user: any) => user.user_username),
  ];
  const [tableColumns, setTableColumns] = useState<any[]>([]);
  // const [pageSize, setPageSize] = useState<number>(20);
  const [showOnlyMilestoneTasks, setShowOnlyMilestoneTasks] = useState<boolean>(false);
  const projectsList = useAppSelector(selectProjectList);
  const clientList = useAppSelector(selectClientList);
  const taskCustomfields = useAppSelector(customerFieldTaskRecords);
  const {
    taskStagesDropdownValues,
    displayDataWithCustomNames,
    taskPrioritiesDropdownValues,
    getStandardRowDataWithoutCustomNames,
  } = useCustomNames();
  const taskAllStages = taskStagesDropdownValues.map((item: any) => item.custom_stage_name);
  const taskAllPriorities = taskPrioritiesDropdownValues.map((item: any) => item.custom_priority_name);

  let myTableState: any = {};
  const { saveAppTableState, getTableState } = useCommonAppConfig();
  const initialState = {
    columns: {
      columnVisibilityModel: {
        task_uid: false,
      },
    },
    pinnedColumns: {
      left: ['client_name'],
    },
    pagination: {
      page: 0,
      pageSize: 20,
    },
    ...getTableState('taskListPage'),
  };

  const getClient = (row: any) => {
    return `/client-list/client/${row.client_uid}`;
  };

  const getProject = (row: any) => {
    return `/projects/project/${row.association_uid}/${row.project_uid}`;
  };

  const getUsecase = (row: any) => {
    return `/projects/project/${row.association_uid}/${row.project_uid}/${row.case_uid}`;
  };

  useEffect(() => {
    dispatch(getAllProjectsOfCustomer());
    dispatch(fetchClientList());
  }, []);

  const handleClientAccess = (params: GridRenderCellParams) => {
    const arrayToCheck = clientList.map((ele: any) => ele.client_uid);
    const hasAccessToClient = checkAccessForRouting({ arrayToCheck: arrayToCheck, uid: params.row.client_uid });
    console.log({ arrayToCheck });
    if (hasAccessToClient == true) {
      return (
        <Link to={getClient(params.row)} style={{ padding: '5px 0' }}>
          {params.value}
        </Link>
      );
    }
    if (hasAccessToClient == false) {
      return (
        <CognisaaasTooltip
          title={<Typography sx={{ padding: '5px' }}>You do not have access to this {label_client}</Typography>}
        >
          <Typography variant="subtitle1">{params.value}</Typography>
        </CognisaaasTooltip>
      );
    }
  };

  const handleProjectAccess = (params: GridRenderCellParams) => {
    const arrayToCheckProject = projectsList.map((ele: any) => ele.project_uid);
    const hasAccessToProject = arrayToCheckProject.includes(params.row.project_uid);
    console.log(arrayToCheckProject.includes(params.row.project_uid));
    if (hasAccessToProject == true) {
      return (
        <Link to={getProject(params.row)} style={{ padding: '5px 0' }}>
          {params.value}
        </Link>
      );
    } else if (hasAccessToProject == false) {
      return <Typography variant="subtitle1">{params.value}</Typography>;
    }
  };

  const handleInlineEditValue = async (
    params: GridRenderEditCellParams,
    editableField: string,
    editableValue: string
  ) => {
    const newRow: any = {
      ...getStandardRowDataWithoutCustomNames({ ...params.row }),
      task_closed_date: format(new Date(params.row.task_closed_date), 'yyyy-MM-dd HH:mm:ss'),
      task_due_date: format(new Date(params.row.task_due_date), 'yyyy-MM-dd HH:mm:ss'),
      task_start_date: format(new Date(params.row.task_start_date), 'yyyy-MM-dd HH:mm:ss'),
      task_planned_start_date: format(new Date(params.row.task_planned_start_date), 'yyyy-MM-dd HH:mm:ss'),
    };
    if (editableField === 'task_owner') {
      await dispatch(
        updateTask({
          ...newRow,
          [editableField]:
            newRow.is_team_task === '1' ? getUserUid(newRow.task_owner) : getContactUidByContactName(newRow.task_owner),
          phase_uid: newRow.section_uid,
        })
      );
    } else {
      await dispatch(
        updateTask({
          ...newRow,
          [editableField]: editableValue,
          phase_uid: newRow.section_uid,
          task_owner:
            newRow.is_team_task === '1' ? getUserUid(newRow.task_owner) : getContactUidByContactName(newRow.task_owner),
        })
      );
    }
  };

  const showTasks = (tasks: any[]) => {
    if (showOnlyMilestoneTasks) {
      const filteredAllTasks = tasks.filter((task: any) => {
        return task.milestone_task != 0 && task.milestone_task;
      });
      const data = filteredAllTasks.map((item: any) => {
        return {
          ...item,
          cognisaas_unique_id: generateUniqueId(),
        };
      });
      return data;
    } else {
      const data = tasks.map((item: any) => {
        return {
          ...item,
          cognisaas_unique_id: generateUniqueId(),
        };
      });
      return data;
    }
  };

  // columns
  const columns = [
    {
      field: '.',
      headerName: '',
      minWidth: 40,
      maxWidth: 60,
      cellClassName: 'remove-padding',
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Stack direction="row">
            <CognisaaasTooltip
              title={
                <Box sx={{ height: '30px', padding: '12px' }}>
                  <Typography variant="subtitle1">Implementation fee</Typography>
                </Box>
              }
            >
              <Stack marginRight={'6px'}>
                {params.row.task_implementation_fee_percentage &&
                  parseInt(params.row.task_implementation_fee_percentage) > 0 && (
                    <Stack sx={{ marginLeft: '0px' }} ml={1}>
                      <Typography variant="h6" className="label-text">
                        $
                      </Typography>
                    </Stack>
                  )}
              </Stack>
            </CognisaaasTooltip>
            <Stack sx={{ padding: '5px 0px' }}>
              {params.row.milestone_task == '1' ? (
                <Stack>
                  <CognisaaasTooltip
                    title={
                      <Typography variant="subtitle1" style={{ padding: '10px 5px' }}>
                        Milestone {label_task}: &nbsp;
                        {params.row.task_stage}
                      </Typography>
                    }
                  >
                    <Stack
                      style={{
                        marginTop: '3px',
                        marginLeft: !(
                          params.row.task_implementation_fee_percentage &&
                          parseInt(params.row.task_implementation_fee_percentage) > 0
                        )
                          ? '14px'
                          : '0px',
                      }}
                    >
                      <CognisaasTaskStageIndicator
                        isMileStoneTask={params.row.milestone_task}
                        stage={params.row.task_stage}
                      />
                    </Stack>
                  </CognisaaasTooltip>
                </Stack>
              ) : (
                <div style={{ paddingRight: '12px' }}>&nbsp;</div>
              )}
            </Stack>
          </Stack>
        );
      },
    },
    // {
    //   field: 'task_implementation_fee_percentage',
    //   headerName: '',
    //   width: 5,
    //   renderCell: (params: GridRenderCellParams) => (
    //     <CognisaaasTooltip
    //       title={
    //         <Box sx={{ height: '30px', padding: '12px' }}>
    //           <Typography variant="subtitle1">Implementation fee</Typography>
    //         </Box>
    //       }
    //     >
    //       <Stack>
    //         {params.row.task_implementation_fee_percentage &&
    //           parseInt(params.row.task_implementation_fee_percentage) > 0 && (
    //             <Stack sx={{ marginLeft: '5px' }} ml={1}>
    //               <Typography variant="h6">$</Typography>
    //             </Stack>
    //           )}
    //       </Stack>
    //     </CognisaaasTooltip>
    //   ),
    // },
    {
      field: 'task_name',
      width: 240,
      renderHeader: () => {
        return (
          <Typography variant="subtitle1" style={{ marginLeft: '0px' }}>
            {label_task} Name
          </Typography>
        );
      },
      renderCell: (params: GridRenderCellParams) => {
        if (params.row.subtask_detail) {
          if (params.value.length > 30) {
            return (
              <CognisaaasTooltip
                title={
                  <Typography variant="subtitle1" style={{ padding: '10px 5px' }}>
                    {params.value}
                  </Typography>
                }
              >
                <Typography
                  variant="subtitle1"
                  className="label-text"
                  onClick={() => props.openUpdateTaskDrawer(params.row)}
                  style={{ padding: '5px 0' }}
                >
                  {params.value}
                </Typography>
              </CognisaaasTooltip>
            );
          } else {
            return (
              <Typography
                variant="subtitle1"
                className="label-text"
                onClick={() => props.openUpdateTaskDrawer(params.row)}
                style={{ padding: '5px 0' }}
              >
                {params.value}
              </Typography>
            );
          }
        } else {
          if (params.value.length > 30) {
            return (
              <CognisaaasTooltip
                title={
                  <Typography variant="subtitle1" style={{ padding: '10px 5px' }}>
                    {params.value}
                  </Typography>
                }
              >
                <Stack direction="row">
                  <Typography
                    variant="subtitle1"
                    style={{ color: '#2D60F6', padding: '5px 0' }}
                    className="cognisaas-pointer"
                    onClick={() => props.openUpdateTaskDrawer(params.row)}
                  >
                    {params.value.slice(0, 25)}...
                  </Typography>
                </Stack>
              </CognisaaasTooltip>
            );
          } else {
            return (
              <Stack direction="row">
                <Typography
                  variant="subtitle1"
                  style={{ color: '#2D60F6', padding: '5px 0' }}
                  className="cognisaas-pointer"
                  onClick={() => props.openUpdateTaskDrawer(params.row)}
                >
                  {params.value}
                </Typography>
              </Stack>
            );
          }
        }
      },
    },
    {
      headerName: `${label_client} Name`,
      field: 'client_name',
      width: 240,
      renderCell: (params: GridRenderCellParams) => handleClientAccess(params),
    },
    {
      headerName: `${label_project} Name`,
      field: 'project_name',
      width: 240,
      renderCell: (params: GridRenderCellParams) => handleProjectAccess(params),
    },
    {
      headerName: `${label_use_case} name`,
      field: 'case_name',
      width: 240,
      renderCell: (params: GridRenderCellParams) => {
        if (params.value) {
          return (
            <Link to={getUsecase(params.row)} style={{ padding: '5px 0' }}>
              {params.value}
            </Link>
          );
        } else {
          return <Typography>-</Typography>;
        }
      },
    },
    {
      headerName: 'Phase name',
      field: 'section_detail',
      width: 240,
      renderCell: (params: GridRenderCellParams) => <Typography variant="subtitle1">{params.value}</Typography>,
    },
    {
      headerName: `${label_task} Owner`,
      field: 'task_owner',
      width: 240,
      editable: true,
      renderEditCell: (params: any) => {
        let dropdownValues: string[] = [];
        if (params.row.is_team_task === '0') {
          dropdownValues = [
            ...allProjectMembersWhichAreClientContactForCustomer.data
              .filter((item: any) => item.project_uid === params.row.project_uid)
              .map((item: any) => item.client_user_name),
          ];
          dropdownValues.push('Not Assigned');
        } else {
          dropdownValues = usersOfCompany;
        }
        return SelectEditDropdownCell(params, dropdownValues, handleInlineEditValue);
      },
      renderCell: function (params: GridRenderCellParams) {
        return (
          <Stack direction="row" style={{ padding: '5px 0' }}>
            <Stack spacing={1} direction={'row'} alignItems={'center'}>
              <Avatar style={{ height: '34px', width: '34px', marginTop: '3px' }} {...stringAvatar(params.value)} />

              {getUserActiveState(getUserUid(params.value), users) === '0' ? (
                <Typography style={{ color: INACTIVE_USER_COLOR }} mt={1} variant="subtitle1">
                  {params.value}&nbsp;(Inactive)
                </Typography>
              ) : (
                <Typography mt={1} variant="subtitle1">
                  {params.value}
                </Typography>
              )}
            </Stack>
          </Stack>
        );
      },
    },
    {
      headerName: `${label_task} Status`,
      field: 'task_status',
      width: 150,

      renderCell: (params: GridRenderCellParams) => (
        <Stack style={{ padding: '5px 0' }}>
          <Stack spacing={1} direction="row">
            <span
              style={{ marginRight: '4px' }}
              className={
                params.value == 'red'
                  ? 'red_status_box_task'
                  : params.value == 'amber'
                  ? 'amber_status_box_task'
                  : params.value == 'green'
                  ? 'green_status_box_task'
                  : params.value == 'blue'
                  ? 'blue_status_box_task'
                  : params.value == 'grey'
                  ? 'grey_status_box_task'
                  : ''
              }
            ></span>
            {params.value == 'red'
              ? 'Overdue'
              : params.value == 'amber'
              ? 'At Risk'
              : params.value == 'green'
              ? 'On Track'
              : params.value == 'blue'
              ? 'Completed'
              : params.value == 'grey'
              ? 'Deferred'
              : ''}
          </Stack>
        </Stack>
      ),
    },
    {
      headerName: `${label_task} Stage`,
      field: 'task_stage',
      width: getMaxColumnWidth(taskAllStages),
      columnType: 'status',
      enableSorting: true,
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams) =>
        SelectEditDropdownCell(params, taskAllStages, handleInlineEditValue),
    },
    {
      headerName: `${label_task} Priority`,
      field: 'task_priority',
      width: getMaxColumnWidth(taskAllPriorities),
      enableSorting: true,
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams) =>
        SelectEditDropdownCell(params, taskAllPriorities, handleInlineEditValue),
    },
    {
      field: 'task_planned_start_date',
      headerName: 'Planned Start Date',
      minWidth: 200,
      flex: 1,
      editable: true,
      type: 'date',
      // valueGetter: ({ value }: any) => value && new Date(value),
      renderCell: (params: GridRenderCellParams) => {
        return <ViewDate date={params.row.task_planned_start_date} />;
      },
      renderEditCell: (params: GridRenderEditCellParams) => SelectEditDateInputCell(params, handleInlineEditValue),
    },
    {
      headerName: 'Start Date',
      field: 'task_start_date',
      width: 160,
      editable: true,
      type: 'date',
      // valueGetter: ({ value }: any) => value && new Date(value),
      renderCell: (params: GridRenderCellParams) => {
        return <ViewDate date={params.row.task_start_date} />;
      },
      renderEditCell: (params: GridRenderEditCellParams) => SelectEditDateInputCell(params, handleInlineEditValue),
    },
    {
      headerName: label_due_date,
      field: 'task_due_date',
      width: 160,
      editable: true,
      type: 'date',
      // valueGetter: ({ value }: any) => value && new Date(value),
      renderCell: (params: GridRenderCellParams) => {
        return <ViewDate date={params.row.task_due_date} />;
      },
      renderEditCell: (params: GridRenderEditCellParams) => SelectEditDateInputCell(params, handleInlineEditValue),
    },
    {
      headerName: label_delivery_date,
      field: 'task_closed_date',
      width: 160,
      type: 'date',
      // valueGetter: ({ value }: any) => value && new Date(value),
      renderCell: (params: GridRenderCellParams) => {
        return <ViewDate date={params.row.task_closed_date} />;
      },
    },
    {
      headerName: 'Delta with ' + label_due_date,
      field: 'task_uid_new',
      width: 240,
      editable: true,

      renderCell: (params: GridRenderCellParams) => {
        const { openTaskDeltaWithDueDateFullText, closedTaskDeltaWithDueDateFullText } = getTaskDeltaXWithDueDate(
          params.row
        );

        return (
          <Typography variant="subtitle1" color={statusColorMap[params.row.task_status]}>
            {props.innerTab === 'open' ? openTaskDeltaWithDueDateFullText : closedTaskDeltaWithDueDateFullText}
          </Typography>
        );
      },
    },
    {
      headerName: 'Implementation fee (in %)',
      width: 200,
      field: 'task_implementation_fee_percentage',
      renderCell: (params: any) => {
        if (!isNull(params?.row[params.field]) && params?.row[params.field].length > 0) {
          return `${params.row[params.field]}%`;
        } else {
          return <>-</>;
        }
      },
    },
    {
      headerName: 'Description',
      field: 'task_description',
      width: 240,
    },
    {
      headerName: 'Original Effort Estimate',
      field: 'effort_estimate',
      width: 160,
      renderCell: (params: GridRenderCellParams) => {
        const { effortEstimate = '-' } = getTaskTimeTrackingFields(params.row);
        return (
          <Typography variant="subtitle1" style={{ padding: '5px 0' }}>
            {effortEstimate}
          </Typography>
        );
      },
    },
    {
      headerName: 'Total Time Logged',
      field: 'total_time_spent',
      width: 160,
      renderCell: (params: GridRenderCellParams) => {
        const { totalTimeLogged = '-' } = getTaskTimeTrackingFields(params.row);
        return (
          <Typography variant="subtitle1" style={{ padding: '5px 0' }}>
            {totalTimeLogged}
          </Typography>
        );
      },
    },
  ];

  const handleInlineEditCustomValue = async (
    params: GridRenderEditCellParams,
    editableField: string,
    editableValue: string
  ) => {
    const index = params.indexofCustomField;
    const customFieldData = params.row.custom_values[index];
    await dispatch(updateTaskCustomField({ ...customFieldData, field_value: editableValue }));
    await dispatch(fetchTeamOpenTasks());
    await dispatch(fetchTeamClosedTasks());
    await dispatch(fetchClientOpenTasks());
    await dispatch(fetchClientClosedTasks());
  };

  useEffect(() => {
    if (taskCustomfields.status === requestState.success && props.data.length > 0) {
      if (taskCustomfields.data?.data?.result?.length > 0 && users.length > 0) {
        setTableColumns([
          ...generateTableColumnsWithCustomFiltersMapped(
            props.data[0],
            columns,
            'Task',
            taskCustomfields.data.data.result,
            handleInlineEditCustomValue,
            users
          ),
        ]);
      } else {
        setTableColumns([...columns]);
      }
    }
  }, [taskCustomfields, props.data, users]);

  // setTableColumns(columns);

  const StripedDataGrid = styled(DataGridPremium)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: theme.palette.grey[200],
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
      '&.Mui-selected': {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
        '&:hover, &.Mui-hovered': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity
          ),
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
          },
        },
      },
    },
  }));

  const StyledBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: 600,
    width: '100%',
    '& .MuiFormGroup-options': {
      alignItems: 'center',
      paddingBottom: theme.spacing(1),
      '& > div': {
        minWidth: 100,
        margin: theme.spacing(2),
        marginLeft: 0,
      },
    },
  }));

  function stringToHslColor(str: string, s: number, l: number) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    const h = hash % 360;
    return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
  }

  function stringAvatar(name: string) {
    return {
      sx: {
        bgcolor: stringToHslColor(name, 30, 80),
      },
      children: (
        <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#000000' }}>
          {name.split(' ')[0][0]}
          {name.indexOf(' ') >= 0 ? name.split(' ')[1][0] : null}
        </Typography>
      ),
    };
  }

  const CustomGridTreeDataGroupingCell = (props: GridRenderCellParams) => {
    const { id, field, rowNode } = props;
    const apiRef = useGridApiContext();
    const filteredDescendantCountLookup = useGridSelector(apiRef, gridFilteredDescendantCountLookupSelector);
    const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0;

    const handleClick = (event: any) => {
      apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
      apiRef.current.setCellFocus(id, field);
      event.stopPropagation();
    };

    return (
      <Box sx={{ ml: rowNode.depth * 4 }}>
        <div>
          {filteredDescendantCount > 0 ? (
            rowNode.childrenExpanded ? (
              <KeyboardArrowDownIcon onClick={handleClick} style={{ cursor: 'pointer' }} />
            ) : (
              <KeyboardArrowRightIcon onClick={handleClick} style={{ cursor: 'pointer' }} />
            )
          ) : (
            <span />
          )}
        </div>
      </Box>
    );
  };

  const groupingColDef: DataGridPremiumProps['groupingColDef'] = {
    headerName: '',
    width: 10,
    renderCell: (params: GridRenderCellParams) => <CustomGridTreeDataGroupingCell {...params} />,
  };

  const handleTableSave = (idName: string) => {
    saveAppTableState(idName, myTableState);
  };

  return (
    <>
      {tableColumns.length > 0 ? (
        props.data.length > 0 ? (
          <>
            <Stack mb={1} direction="row" justifyContent="flex-end" alignItems="baseline">
              <Stack>
                <CognisaasCheckBox
                  sx={{ top: '3px' }}
                  onClick={() => setShowOnlyMilestoneTasks(!showOnlyMilestoneTasks)}
                  checked={showOnlyMilestoneTasks}
                />
              </Stack>

              <Typography sx={{ marginLeft: '4px' }} variant="subtitle1">
                Show milestone {label_task.toLowerCase()}s
              </Typography>
            </Stack>

            <StyledBox>
              <StripedDataGrid
                initialState={initialState}
                onStateChange={(tblState: any) => {
                  myTableState = tblState;
                }}
                onColumnVisibilityModelChange={() => {
                  handleTableSave('taskListPage');
                }}
                onFilterModelChange={() => {
                  handleTableSave('taskListPage');
                }}
                onPageSizeChange={() => {
                  handleTableSave('taskListPage');
                }}
                rows={displayDataWithCustomNames(showTasks(props.filteredData))}
                // rows={props.filteredData.map((item) => ({ ...item, cognisaas_unique_id: generateUniqueId() }))}
                columns={tableColumns}
                loading={false}
                components={{
                  Toolbar: GridToolbar,
                }}
                componentsProps={{
                  toolbar: { showQuickFilter: true },
                }}
                getRowId={(row) => row.cognisaas_unique_id}
                disableSelectionOnClick
                rowThreshold={0}
                // pageSize={pageSize}
                // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[5, 10, 20, 50, 100]}
                pagination
                editMode="cell"
                treeData
                getRowHeight={() => 'auto'}
                sx={{
                  '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
                  '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '7px' },
                  '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
                }}
                getTreeDataPath={(row) => {
                  return row.path;
                }}
                groupingColDef={groupingColDef}
                experimentalFeatures={{ newEditingApi: true }}
                getRowClassName={(params: any) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'odd' : 'odd')}
              />
            </StyledBox>
          </>
        ) : (
          <NoDataFound title="No Tasks Found" />
        )
      ) : (
        <CognisaasLoader />
      )}
    </>
  );
};

export default TaskTableView;
