import { CardContent, CardHeader, Grid, Typography } from '@mui/material';
// import  { useState } from 'react';
import CognisaasCard from '../CognisaasCard/CognisaasCard';
import CognisaasRequirementStatusDropdown from '../CognisaasDropdown/CognisaasRequirementStatusDropdown/CognisaasRequirementStatusDropdown';
import CognisaaasTooltip from '../CognisaasTooltip/CognisaasTooltip';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
// import DeleteIcon from '@mui/icons-material/Delete';
import { parseProductRequirementDate } from 'utils/DateHelper';
// import { ReactComponent as CommentIcon } from '../../../assets/svgs/comment.svg';
// import { ReactComponent as PeoplesIcon } from '../../../assets/svgs/peoples.svg';
// import { ReactComponent as AttachIcon } from '../../../assets/svgs/attachNoColor.svg';
import { Box } from '@mui/system';

interface Props {
  statusColor: 'red' | 'amber' | 'green' | 'blue';
  cardClickHandler: () => void;
  // cardDeleteHandler: (uid: string) => void;
  description: string;
  date: string;
  name: string;
  featureUid?: string;
}

const RequirementCard = (props: Props) => {
  // const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // const open = Boolean(anchorEl);

  // const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  //   console.log(props);
  // };
  return (
    <Box sx={{ marginRight: '8px', cursor: 'pointer' }} onClick={() => props.cardClickHandler()}>
      <CognisaasCard clientStatusColor={props.statusColor} height="280px" >
        <CardHeader
          sx={{ paddingBottom: '10px', paddingTop: '10px' }}
          title={
            <Typography style={{ color: '#000000' }} variant="subtitle1">
              {props.name}
            </Typography>
          }
        />
        <CardContent sx={{ pt: 0, paddingBottom: '12px' }}>
          <Grid container>
            <Grid item xs>
              <Typography style={{ color: '#7A7A7A' }} variant="subtitle1">
                ETA for release
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography style={{ color: '#000000' }} variant="subtitle1">
                {parseProductRequirementDate(props.date)}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>

        <CardContent sx={{ color: 'black', pt: 0, minHeight: '74px' }}>
          <CognisaaasTooltip title={props.description}>
            <Typography style={{ color: '#000000' }} variant="subtitle1" color="text.secondary">
              {props.description.length > 80 ? props.description.slice(0, 80) + '...' : props.description}
            </Typography>
          </CognisaaasTooltip>
        </CardContent>

        <CardContent sx={{ pt: 0 }}>
          <CognisaasRequirementStatusDropdown
            readOnly={true}
            value={props.statusColor}
            changeHandler={() => {
              console.log('changed');
            }}
          />
        </CardContent>
      </CognisaasCard>
    </Box>
  );
};

export default RequirementCard;
