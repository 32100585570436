import { Component, TypeInternal } from 'components/ResourceManagement/types/public-types';
import { BarComponent } from 'components/ResourceManagement/types/bar-component';

export const convertToBarComponents = (
  primeComponents: Component[],
  dates: Date[],
  columnWidth: number,
  rowHeight: number,
  componentHeight: number
) => {
  const dateDelta =
    dates[1].getTime() -
    dates[0].getTime() -
    dates[1].getTimezoneOffset() * 60 * 1000 +
    dates[0].getTimezoneOffset() * 60 * 1000;

  const barComponents = primeComponents.map((component, index) => {
    return convertToBar(component, index, dates, dateDelta, columnWidth, rowHeight, componentHeight);
  });

  return barComponents;
};

export const convertToBar = (
  component: Component,
  index: number,
  dates: Date[],
  dateDelta: number,
  columnWidth: number,
  rowHeight: number,
  componentHeight: number
): BarComponent => {
  const x1: number = componentXCoordinate(component.start, dates, dateDelta, columnWidth);
  const x2: number = componentXCoordinate(component.end, dates, dateDelta, columnWidth);
  const typeInternal: TypeInternal = component.type;
  const [progressWidth, progressX] = progressWithByParams(x1, x2, component.progress);
  const y = componentYCoordinate(index, rowHeight, componentHeight);
  const hideChildren = component.hideChildren;
  // console.log('convertToBar', { cs: component.start, ce: component.end, x1, x2 });

  return {
    ...component,
    typeInternal,
    x1,
    x2,
    y,
    index,
    progressX,
    progressWidth,
    hideChildren,
    height: componentHeight,
    barChildren: [],
  };
};

const componentXCoordinate = (xDate: Date, dates: Date[], dateDelta: number, columnWidth: number) => {
  // console.log('CXC', { xDate, dates, name });
  if (
    !xDate ||
    !dates.length ||
    !dates[0]
    // || xDate.toString() !== 'Invalid Date'
  ) {
    return 0;
  }

  const index = ~~(
    (xDate.getTime() - dates[0].getTime() + xDate.getTimezoneOffset() - dates[0].getTimezoneOffset()) /
    dateDelta
  );

  if (!dates[index]) {
    return 0;
  }

  let actualIndex = index;
  if (actualIndex < 0) actualIndex = ~actualIndex;

  const x = Math.round(
    (actualIndex +
      (xDate.getTime() -
        dates[actualIndex].getTime() -
        xDate.getTimezoneOffset() * 60 * 1000 +
        dates[actualIndex].getTimezoneOffset() * 60 * 1000) /
        dateDelta) *
      columnWidth
  );
  // console.log({ xDate, dates, dateDelta, columnWidth, index, x });

  let actualX = x;
  if (actualX < 0) actualX = ~actualX;

  return actualX;
};

const componentYCoordinate = (index: number, rowHeight: number, componentHeight: number) => {
  const y = index * rowHeight + (rowHeight - componentHeight) / 2;
  return y;
};

export const progressWithByParams = (componentX1: number, componentX2: number, progress: number) => {
  const progressWidth = (componentX2 - componentX1) * progress * 0.01;

  let actualProgressWidth = progressWidth;
  if (actualProgressWidth < 0) actualProgressWidth = ~actualProgressWidth;

  const progressX: number = componentX1;
  return [actualProgressWidth, progressX];
};
