import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from 'store/hooks';
// import { userPermissionSchema } from 'components/Reports/SideDrawer/types';
import {
  //   getCustDetails,
  //   saveTemplateProjectSection,
  getAllSectionByTemplateProjectuid,
  getProjectTemplates,
  //   addTemplateProjectTask,
  //   addTemplateProjectClientTask,
  updateTemplateProjectByUid,
  getTemplateProjectinformation,
  toggleUpdateProjectTemplateExcelViewSelectedState,
  getTemplateProjectDependency,
  //   deleteProjectTemplatesectionByUid,
  //   updateTemplateProjectTaskbyuid,
  //   updateTemplateProjectClienttaskByuid,
  //   deleteTemplateProjectTaskbyUid,
  //   deleteTemplateProjectClientTaskbyUid,
} from '../../../../../../store/modules/Templates/Templates';
import TemplateOverviewSection from '../../Shared/TemplateOverviewSection';
import { projectTemplateTabs } from 'components/Templates/TemplatesIndex/types';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import UpdateTemplatePlanSection from '../../Shared/UpdateTemplatePlanSection';
import {
  // selectUserDetails,
  //   selectTemplateProjectUid,
  //   selectTemplateSectionSequence,
  selectAllSectionTemplates,
  //   selectredirectToTemplates,
  selectprojectTemplateInformation,
  selectToggleUpdateProjectTemplateExcelViewSelectedState,
  selectUpdateProjectTemplateExcelData,
} from '../../../../../../store/modules/Templates/selector';
import { label_project } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { useNavigate } from 'react-router-dom';
import { Typography, Grid, Stack, Button, ButtonGroup } from '@mui/material';
import CognisaasTabs from 'components/shared/CognisaasTabs';
import { updateProjectTemplate } from '../AddProjectTemplate/helper';
import { ReactComponent as TableViewUnselected } from '../../../../../../assets/svgs/table_view_unselected.svg';
import { ReactComponent as TableViewSelected } from '../../../../../../assets/svgs/table_view_unselected.svg';
import { ReactComponent as KanbanViewUnselected } from '../../../../../../assets/svgs/kanban_view_unselected.svg';
import { ReactComponent as KanbanViewSelected } from '../../../../../../assets/svgs/kanban_view_selected.svg';
// import { ReactComponent as GanttViewUnselected } from '../../../assets/svgs/gantt_chart_unselected.svg';
import UpdateTemplateExcelView from '../../Shared/UpdateTemplateExcelView';
// import { useCreatePlanExcelForUpdate } from 'components/shared/Hooks/useCreatePlanExcelForUpdate';
import { updatePlanFromExcel } from '../../Shared/updatePlanFromExcel';

const UpdateProjectTemplateIndex = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { projectUid } = location.state as any;
  const { userPermissions } = location.state as any;
  const isExcelViewSelected = useAppSelector(selectToggleUpdateProjectTemplateExcelViewSelectedState);
  const projectTemplateInformation = useAppSelector(selectprojectTemplateInformation);
  const projectTemplateSections = useAppSelector(selectAllSectionTemplates);
  const [selectedTab, setSelectedTab] = useState<string>(projectTemplateTabs[0]);
  const [templateName, setTemplateName] = useState<string>('');
  const [templateOwner, setTemplateOwner] = useState<any>('');
  const [templateTargetDays, setTemplateTargetDays] = useState<number>(0);
  // useCreatePlanExcelForUpdate(projectTemplateSections);
  const [dependencyData, setDependencyData] = useState<any[]>([]);
  const excelData = useAppSelector(selectUpdateProjectTemplateExcelData);

  console.log(projectUid, userPermissions);

  useEffect(() => {
    makeTemplateDetailsReady();
  }, []);

  useEffect(() => {
    console.log(projectTemplateInformation);
    patchFormValues();
  }, [projectTemplateInformation]);

  const makeTemplateDetailsReady = async () => {
    await dispatch(getTemplateProjectinformation(projectUid));
    await dispatch(getAllSectionByTemplateProjectuid(projectUid));
    const dependency = await dispatch(getTemplateProjectDependency(projectUid));
    console.log(dependency);
    setDependencyData([...dependency.payload]);
  };

  const patchFormValues = () => {
    setTemplateName(projectTemplateInformation.template_project_name);
    setTemplateOwner(projectTemplateInformation.template_owner_name);
    setTemplateTargetDays(projectTemplateInformation.template_project_target_days);
  };

  const handleSubmitProject = async () => {
    if (isExcelViewSelected) {
      const sections = await dispatch(getAllSectionByTemplateProjectuid(projectUid)).unwrap();
      await updatePlanFromExcel(excelData, projectUid, sections);
      // await dispatch(getAllSectionByTemplateProjectuid(projectUid));
      console.log('update');
      makeTemplateDetailsReady();
      // useCreatePlanExcelForUpdate(sections, []);
      return;
    }
    console.log(templateName, templateOwner, templateTargetDays);
    const sections = await dispatch(getAllSectionByTemplateProjectuid(projectUid)).unwrap();
    console.log(templateName, templateOwner, [...sections], templateTargetDays, projectUid);
    const payload = {
      templateName,
      templateOwner,
      sections: [...sections],
      templateTargetDays,
      newTemplateUid: projectUid,
    };
    const postData = updateProjectTemplate(payload);
    console.log(postData);
    await dispatch(updateTemplateProjectByUid(postData));
    await dispatch(getProjectTemplates());
    navigate('/templates');
  };

  return (
    <>
      <Stack my={2}>
        <Typography sx={{ fontSize: '12px', fontWeight: '400', ml: '25px', color: '#2D60F6' }}>Templates</Typography>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Stack direction="row">
              <ArrowBackIosNewIcon
                onClick={() => navigate(-1)}
                fontSize="small"
                sx={{ mt: '5px', cursor: 'pointer' }}
              />
              <Typography sx={{ fontSize: '20px', fontWeight: '500', ml: '5px' }}>{label_project} Template</Typography>
            </Stack>
          </Grid>
          <Grid item>
            <Button
              disableRipple
              disabled={templateTargetDays < 0 || templateName?.trim() == '' ? true : false}
              variant="contained"
              onClick={() => {
                handleSubmitProject();
                // setStateDrawerOpen(false);
              }}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Stack>
      <Stack ml={2} direction="row" justifyContent={'space-between'}>
        <CognisaasTabs
          size="squeezed"
          tabs={[...projectTemplateTabs]}
          handleTabSwitch={(e: any, index: number) => setSelectedTab(projectTemplateTabs[index])}
        />
        <Stack direction={'row'}>
          {/* {tabIndex == 1 && ( */}
          <Stack direction="row" justifyContent={'space-between'}>
            <ButtonGroup sx={{ backgroundColor: 'white' }} variant="outlined" aria-label="outlined button group">
              <Button
                className={`${isExcelViewSelected ? 'active-btn' : null} `}
                onClick={() => {
                  dispatch(toggleUpdateProjectTemplateExcelViewSelectedState());
                }}
              >
                {console.log(isExcelViewSelected)}
                {isExcelViewSelected ? <TableViewSelected /> : <TableViewUnselected />}
              </Button>
              <Button
                className={`${!isExcelViewSelected ? 'active-btn' : null} `}
                onClick={() => {
                  dispatch(toggleUpdateProjectTemplateExcelViewSelectedState());
                }}
              >
                {isExcelViewSelected ? <KanbanViewSelected /> : <KanbanViewUnselected />}
              </Button>
            </ButtonGroup>
          </Stack>
        </Stack>
      </Stack>
      <Stack ml={2} my={2}>
        {selectedTab == projectTemplateTabs[0] ? (
          <TemplateOverviewSection
            userPermissions={userPermissions}
            name={templateName}
            owner={templateOwner}
            days={templateTargetDays}
            newTemplateName={(name: string) => setTemplateName(name)}
            newTemplateOwner={(name: any) => {
              setTemplateOwner(name);
              console.log(name);
            }}
            newTemplateTargetDays={(days: any) => setTemplateTargetDays(days)}
          />
        ) : (
          <>
            {isExcelViewSelected ? (
              <UpdateTemplateExcelView data={projectTemplateSections} dependency={dependencyData} />
            ) : (
              <UpdateTemplatePlanSection
                type="project"
                userPermissions={userPermissions}
                projectTemplateUid={projectUid}
                sectionsData={projectTemplateSections}
              />
            )}
          </>
        )}
      </Stack>
    </>
  );
};

export default UpdateProjectTemplateIndex;
