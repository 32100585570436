export enum ViewMode {
  Day = 'Day',
  Week = 'Week',
  Month = 'Month',
}
export type TypeInternal = 'resource' | 'project';
export interface Component {
  id: string;
  type: TypeInternal;
  name: string;
  start: Date;
  end: Date;
  progress: number;
  isDisabled?: boolean;
  dependencies?: string[];
  hideChildren?: boolean;
  displayOrder?: number;
  componentLinkedTo?: string;
  count?: number;
  taskStage?: string;
  taskEffortEstimate?: number;
  entity: string;
  softY?: number;
  allocationType?: string;
}

export interface SoftAllocationComponent extends Component {
  softY: number;
  allocationType: string;
}

export interface EventOption {
  /**
   * Invokes on bar select on unselect.
   */
  onSelect?: (component: Component, isSelected: boolean) => void;
  /**
   * Invokes on end and start time change. Chart undoes operation if method return false or error.
   */
  onDateChange?: (component: Component, children: Component[]) => void | boolean | Promise<void> | Promise<boolean>;
  onExpanderClick?: (component: Component) => void;
}

export interface DisplayOption {
  viewMode: ViewMode;
  viewDate?: Date;
  preStepsCount?: number;
  locale?: string;
}

export interface StylingOption {
  headerHeight?: number;
  columnWidth?: number;
  listCellWidth?: string;
  rowHeight?: number;
  ganttHeight?: number;
  barFill?: number;
  setSelectedComponent?: (componentId: string) => void;
  onExpanderClick?: (component: Component) => void;
}

export interface GanttProps extends EventOption, DisplayOption, StylingOption {
  primeComponents: Component[];
  // startDate: string;
  // endDate: string;
  mode: string;
  events: any[];
}

export type TTaskItemAPI = {
  user_uid: string; //resourceId
  project_uid: string; //resourceId
  task_uid: string; //id
  task_id: string; //id
  task_name: string; //title
  effort_estimate: string;
  total_days: string;
  hours_per_day: string;
  total_worked_hours: string;
  task_planned_start_date: string;
  task_due_date: string;
  section_uid: string;
  delivery_manager_uid?: string;
  total_utilized_percent_by_project?: number;
  total_utilized_percent_by_tasks?: number;
  task_duration_in_hours?: number;
  resourceId: string;
  start: Date;
  end: Date;
  task_utilized_percentage: number;
  task_stage: string;
};

export type TProjectDetailsItemAPI = {
  user_uid: string; // parentId
  project_uid: string; //id
  project_name: string; //name
  association_uid: string;
};

export type TSectionType = {
  client_initiate: string;
  project_uid: string;
  section_detail: string;
  section_id: string;
  section_sequence: string;
  section_uid: string;
};

export type TProjectItemAPI = {
  project_details: TProjectDetailsItemAPI;
  section_details: TSectionType[];
  tasks: TTaskItemAPI[];
  consolidatedTasksUtilizedPercentage: number;
};

export type TResourceItemAPI = {
  delivery_manager_uid: string; //id
  delivery_manager_name: string; //name
  projects?: TProjectItemAPI[];
  customer_uid: string;
  displayOrder: number;
  soft_allocation: any[];
};
