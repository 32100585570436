import TopHeader from './TopHeader';
import { useEffect } from 'react';
// import { templateHomeTabs } from '../../types';
import TemplateNameCard from '../Shared/TemplateNameCard';
// import { useAppDispatch } from 'store/hooks';
// import {
//   // getUseCaseTemplatesbycustomer,
//   // getProjectTemplates,
//   // getTemplateDocumentByCustomerUid,
// } from 'store/modules/Templates/Templates';
// import { getCustomerUid } from 'utils/getUid';
import useTemplateListData from './useTemplateListData';
import useCustomNames from 'components/shared/CustomNames/useCustomNames';

const TemplatesList = () => {
  const { templateSectionChange, templateList, selectedSectionInTemplates } = useTemplateListData();
  const { loadCustomNamesModule } = useCustomNames();

  useEffect(() => {
    loadCustomNamesModule();
  }, []);

  return (
    <>
      <TopHeader
        onTabChangeHandler={(tabIndex: number) => templateSectionChange(tabIndex)}
        selectedTemplatesType={selectedSectionInTemplates}
      />
      <TemplateNameCard templateType={selectedSectionInTemplates} templates={templateList} />
    </>
  );
};

export default TemplatesList;
