import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { Button, Divider, Grid, Stack, TextField, Typography } from '@mui/material';
import { sendEmailToUsersWithCustomMessage } from 'store/modules/Settings/slice';
import Cookies from 'universal-cookie';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { GridSelectionModel } from '@mui/x-data-grid';
import { selectSendEmailToUsersWithCustomMessage } from 'store/modules/Settings/selector';
import CognisaasToast from 'components/shared/CognisaasToast/CognisaasToast';

const drawerWidth = 280;

interface PropType {
  selectionModel: GridSelectionModel;
  setSelectionModel: React.Dispatch<React.SetStateAction<GridSelectionModel>>;
}

export default function PersistentDrawerLeft(props: PropType) {
  const cookies = new Cookies();
  const dispatch = useAppDispatch();
  const [open, setOpen] = React.useState(false);
  const [emailMessage, setEmailMessage] = React.useState<string | undefined>('');
  const emailStatus = useAppSelector(selectSendEmailToUsersWithCustomMessage);
  const [alertDetails, setAlertDetails] = React.useState({ isVisible: false, severity: 'error', message: '' });

  React.useEffect(() => {
    if (props.selectionModel?.length > 0) {
      setOpen(true);
      setEmailMessage('');
    } else setOpen(false);
  }, [props.selectionModel]);

  React.useEffect(() => {
    if (emailStatus.status == 'loading') {
      setAlertDetails({ isVisible: true, severity: 'success', message: 'Sending email' });
    }
    if (emailStatus.status == 'success') {
      setAlertDetails({ isVisible: true, severity: 'success', message: 'Email sent successfully' });
    }
    if (emailStatus.status == 'failed') {
      setAlertDetails({
        isVisible: true,
        severity: 'error',
        message: 'Email not sent, Please try again or contact us',
      });
    }
  }, [emailStatus]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailMessage(event.target.value);
  };

  const sendEmailToUsersWithMessage = () => {
    dispatch(
      sendEmailToUsersWithCustomMessage({
        users: props.selectionModel,
        messageToSend: emailMessage,
        userName: cookies.get('user_name'),
        companyName: cookies.get('customerCompanyName'),
      })
    );
    props.setSelectionModel([]);
    setEmailMessage('');
  };

  return (
    <Box sx={{ display: 'flex' }}>
      {alertDetails.isVisible ? (
        <CognisaasToast
          open={alertDetails.isVisible}
          onClose={() => setAlertDetails({ isVisible: false, severity: 'error', message: '' })}
          message={alertDetails.message}
          type={alertDetails.severity == 'success' ? 'success' : 'failure'}
        ></CognisaasToast>
      ) : null}
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <Stack mt={10} height={'40px'} justifyContent="center">
          <Typography variant="h2" ml="10px">
            Selected Users : {props?.selectionModel?.length}
          </Typography>
        </Stack>
        <Divider />
        <Box sx={{ padding: 1.5, mt: '10px' }}>
          <Typography variant="subtitle1" sx={{ color: '#7A7A7A' }}>
            Send Message To Selected Users:
          </Typography>
          <TextField
            fullWidth
            id="outlined-multiline-static"
            placeholder="Enter your message here"
            multiline
            rows={15}
            onChange={handleChange}
            value={emailMessage}
          />
          <Grid container justifyContent="flex-end">
            <Button sx={{ mt: '10px' }} variant="outlined" onClick={() => props.setSelectionModel([])}>
              Cancel
            </Button>
            <Button sx={{ mt: '10px', ml: '10px' }} variant="contained" onClick={() => sendEmailToUsersWithMessage()}>
              Send Email
            </Button>
          </Grid>
        </Box>
      </Drawer>
    </Box>
  );
}
