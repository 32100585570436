import { Box, Drawer } from '@mui/material';
import { useAppSelector, useAppDispatch } from '../../../store/hooks';
import { label_use_case, label_arr } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import CogniTable, { linkCell } from '../ActionableInsightTable/Table';
import { selectCustomerDetail } from 'store/modules/Common/selector';
import CognisaasLoader from '../../shared/CognisaasLoader/CognisaasLoader';
import { useEffect, useState } from 'react';
import { fetchTemplateInformationbyTemplateUid } from 'store/modules/ActionableInsights/slice';
import { selectMappedClientsListState } from 'store/modules/ActionableInsights/selector';
import MappedClientView from './MappedClientView';
import { FilterableData } from 'utils/filteringFunctions';
import { formatCurrency } from 'utils/dataHelpers';
import useCustomNames from 'components/shared/CustomNames/useCustomNames';
const UseCaseClientMapping = (props: any) => {
  const dispatch = useAppDispatch();
  const customerDetail = useAppSelector(selectCustomerDetail);
  const mappedClientState = useAppSelector(selectMappedClientsListState);
  const [isClientDataViewDrawerToggled, setIsClientDataViewDrawerToggled] = useState<boolean>(false);
  const [toggledRowData, setToggledRowdata] = useState<Record<string, any>>({});
  const [toggledClientList, setToggledClientList] = useState<any[]>([]);
  const { displayDataWithCustomNames } = useCustomNames();

  // let summ_arr = 0;
  useEffect(() => {
    if (mappedClientState.status === 'success' && mappedClientState.data.length > 0) {
      if (props.selectedCategory === 'expand') {
        //needed expand usecases
        const filteredExpandUsecases = new FilterableData(mappedClientState.data);
        filteredExpandUsecases.customFilter('case_needed_status', 'Expand');
        setToggledClientList(filteredExpandUsecases.myData);
      } else {
        // needed land usecases
        const filteredLandUsecases = new FilterableData(mappedClientState.data);
        filteredLandUsecases.customFilter('case_needed_status', 'Land');
        setToggledClientList(filteredLandUsecases.myData);
      }
    }
  }, [mappedClientState]);
  const handleAccountClick = (rowData: Record<string, any>) => {
    setIsClientDataViewDrawerToggled((prevState: any) => !prevState);
    setToggledRowdata(rowData);
    dispatch(fetchTemplateInformationbyTemplateUid(rowData.template_case_uid));
  };

  return (
    <>
      {props.selectedCategory === 'expand' ? (
        <Box>
          {/* use case client mapping - expand */}
          {props.topExpandUsecaseMappingState.status === 'success' ? (
            <>
              <CogniTable
                hideFooter={true}
                hideHeader={true}
                columns={[
                  {
                    field: 'case_uid',
                    hide: true,
                  },
                  { field: 'template_case_name', headerName: label_use_case, minWidth: 240, flex: 1 },
                  {
                    field: 'case_expansionvalue',
                    headerName: `Total Expected ${label_arr}`,
                    minWidth: 200,
                    flex: 1,
                    renderCell: (params) => {
                      return formatCurrency(params.row[params.field], customerDetail?.currency_code);
                    },
                  },
                  {
                    field: 'client_count',
                    headerName: 'Account List',
                    minWidth: 140,
                    flex: 1,
                    renderCell: (params) => linkCell(params, handleAccountClick),
                  },
                ]}
                uniquRowId={'case_uid'}
                data={displayDataWithCustomNames(props.topExpandUsecaseMappingState.data)}
              />
            </>
          ) : (
            <CognisaasLoader />
          )}
        </Box>
      ) : (
        <Box>
          {/* use case client mapping - land */}
          {props.topLandUsecaseMappingState.status === 'success' ? (
            <>
              <CogniTable
                hideFooter={true}
                hideHeader={true}
                columns={[
                  {
                    field: 'case_uid',
                    hide: true,
                  },
                  { field: 'template_case_name', headerName: label_use_case, minWidth: 240, flex: 1 },
                  {
                    field: 'sum_worth',
                    headerName: `Total Expected ${label_arr}`,
                    minWidth: 200,
                    flex: 1,
                    renderCell: (params) => {
                      return formatCurrency(params.row[params.field], customerDetail?.currency_code);
                    },
                  },
                  {
                    field: 'client_count',
                    headerName: 'Account List',
                    minWidth: 140,
                    flex: 1,
                    renderCell: (params) => linkCell(params, handleAccountClick),
                  },
                ]}
                uniquRowId={'case_uid'}
                data={displayDataWithCustomNames(props.topLandUsecaseMappingState.data)}
              />
            </>
          ) : (
            <CognisaasLoader />
          )}
        </Box>
      )}
      <Drawer
        anchor={'right'}
        open={isClientDataViewDrawerToggled}
        onClose={() => {
          setIsClientDataViewDrawerToggled((prevState) => !prevState);
          setToggledRowdata({});
        }}
        PaperProps={{
          sx: { width: '920px', overflowX: 'clip' },
        }}
      >
        <Box sx={{ padding: '95px 0px 0px 0px' }}>
          <MappedClientView
            name={toggledRowData.template_case_name}
            type={props.selectedClientMapping === 'Usecase-Client' ? 'usecase' : 'project'}
            data={toggledClientList}
            arr={
              toggledRowData.sum_worth
                ? parseInt(toggledRowData.sum_worth)
                : parseInt(toggledRowData.case_expansionvalue)
            }
            dependentClientCount={toggledRowData.client_count}
            category={toggledRowData.case_needed_status}
          />
        </Box>
      </Drawer>
    </>
  );
};

export default UseCaseClientMapping;
