import { useState, useEffect } from 'react';
import { Grid, ButtonGroup, Button, Typography, Stack } from '@mui/material';
import './Notification.scss';
import { getNotificationData_slice } from '../../../store/modules/HomePage/slice';
import { Link, Link as RouterLink } from 'react-router-dom';
import { format } from 'date-fns';
import { getContactNameByContactUid, getUserNameByUserUid } from '../../../utils/getUid';
import { useAppSelector, useAppDispatch } from '../../../store/hooks';
import { getNotificationData } from 'store/modules/Settings/selector';
import UpdateTaskDrawer from '../../TaskModule/TaskView/UpdateTaskDrawer';
import { getTaskByTaskUid } from 'store/modules/Task/slice';
import Cookies from 'universal-cookie';
import { getAllClientContacts } from 'store/modules/Common/slice';
import { isUndefined } from 'utils/dataHelpers';
import { ReactComponent as NoRecords } from '../../../assets/svgs/no_records.svg';
import {
  label_client,
  label_project,
  label_task,
  label_use_case,
} from 'components/Settings/Preferences/CustomLabelCookieHelper';
import CognisaasLoader from 'components/shared/CognisaasLoader/CognisaasLoader';

const cookies = new Cookies();

const Notification = () => {
  const generateCommentUi = (comment: string) => {
    const splitForUsers = comment.split('@[');
    return splitForUsers.map((item, index) => {
      if (index === 0) {
        return <span key={index}>{item}</span>;
      } else {
        // index more than 0 something like this expected => vivek](customer:324234) great
        const strContainingUser = item;
        const arrContainingUser = strContainingUser.split('](');
        const username = arrContainingUser[0];
        const restStrArr = arrContainingUser[1].split(')');
        const customerUid = restStrArr[0];
        const restString = [...restStrArr.filter((item, index) => index > 0)].join('');
        return (
          <span key={index}>
            &nbsp;
            <span
              style={{
                backgroundColor: 'rgb(45, 96, 246, 0.1)',
                color: '#2D60F6',
                padding: '2px 10px',
                borderRadius: '33px',
                fontSize: '13px',
                cursor: 'pointer',
              }}
              id={customerUid}
            >
              {username}
            </span>
            {restString}
          </span>
        );
      }
    });
  };
  const ShowContents = (props: any) => {
    const comps: any = [];
    // eslint-disable-next-line react/prop-types
    props.props.forEach((value: [], key: number) =>
      comps.push(
        <div key={key}>
          <p className="date" style={{ padding: '0 0 0px 60px', margin: '0px !important' }}>
            {format(new Date(key), 'dd-MMM-yyyy')}
          </p>
          <div>
            {value.map((item: any, index: number) => {
              if (item.AMC == 0 || item.AMC == 2) {
                return (
                  <>
                    <li key={index} className="tl-item">
                      <div className="timestamp_date">
                        {format(new Date(item.modified_on.replace(/-/g, '/')), 'HH:mm a')}
                      </div>
                      <div className="item-title">
                        {item.field == 'Comment' ? (
                          <>
                            <span>
                              {item.client_task == '0' ? (
                                <>
                                  <span className="userNameHeading">
                                    {item.user_uid != null
                                      ? getUserNameByUserUid(item.user_uid)
                                      : getContactNameByContactUid(item.contact_uid)}
                                  </span>
                                  <span> mentioned you in a comment of {label_task} </span>
                                  {/* {item.project_name != null ? (
                                  <span style={{ cursor: 'pointer' }}>
                                    {item.task_name}
                                  </span>
                                ) : (
                                  <span style={{ cursor: 'pointer' }}>
                                    {item.task_name}
                                  </span>
                                )} */}
                                  <Link onClick={() => taskInformation(item.task_uid, item.client_uid)} to="/">
                                    {item.task_name}
                                  </Link>
                                </>
                              ) : (
                                <>
                                  <span className="userNameHeading">
                                    {item.user_uid != null
                                      ? getUserNameByUserUid(item.user_uid)
                                      : getContactNameByContactUid(item.contact_uid)}
                                  </span>
                                  <span> mentioned you in a comment of {label_task} </span>
                                  {/* {item.project_name != null ? (
                                  <span style={{ cursor: 'pointer' }}>
                                    {item.task_name}
                                  </span>
                                ) : (
                                  <span style={{ cursor: 'pointer' }}>
                                    {item.task_name}
                                  </span>
                                )} */}
                                  <Link onClick={() => taskInformation(item.task_uid, item.client_uid)} to="/">
                                    {item.task_name}
                                  </Link>
                                </>
                              )}
                              {
                                item.old_value != '' ? (
                                  <>
                                    <span className="label-text">updated the comment </span>
                                  </>
                                ) : null
                                // (
                                //   <>
                                //     <span>added a</span>
                                //   </>
                                // )
                              }
                            </span>
                            {/* <span style={{ textTransform: 'lowercase' }}>{item.field}</span> */}
                            <span>
                              {/* <span> to task </span> */}
                              {/* <>
                                <Link
                                  onClick={() => taskInformation(item.task_uid, item.client_uid)} to='/
                                >
                                  {item.task_name}
                                </Link>
                              </> */}
                            </span>
                            <span style={{ display: 'flex' }}>
                              {item.old_value != '' ? (
                                <>
                                  <span> from </span>
                                  <span>&quot;</span>
                                  <span> {generateCommentUi(item.old_value)}</span>
                                  <span> to </span>
                                  <span>{generateCommentUi(item.new_value)} </span>
                                  <span>&quot;</span>
                                </>
                              ) : (
                                <>
                                  <span>&quot;</span>
                                  <span> {generateCommentUi(item.new_value)} </span>
                                  <span>&quot;</span>
                                </>
                              )}
                            </span>
                          </>
                        ) : item.field != 'Created' && item.field != 'Comment' ? (
                          <>
                            <span>
                              {item.user_uid == 'CogniSaaS Platform' ? (
                                <>
                                  <span className="userNameHeading">CogniSaaS Platform </span>
                                </>
                              ) : item.user_uid == 'Jira Platform' ? (
                                <>
                                  <span className="userNameHeading">Jira Platform </span>
                                </>
                              ) : item.client_task == '0' ? (
                                <>
                                  <span className="userNameHeading">
                                    {item.user_uid != null
                                      ? getUserNameByUserUid(item.user_uid)
                                      : getContactNameByContactUid(item.contact_uid)}
                                  </span>
                                  {/* <span> of task </span>
                                {item.project_name != null ? (
                                  <span style={{ cursor: 'pointer' }}>
                                    {item.task_name}
                                  </span>
                                ) : (
                                  <span style={{ cursor: 'pointer' }}>
                                    {item.task_name}
                                  </span>
                                )} */}
                                </>
                              ) : (
                                <>
                                  <span className="userNameHeading">
                                    {item.user_uid != null
                                      ? getUserNameByUserUid(item.user_uid)
                                      : getContactNameByContactUid(item.contact_uid)}
                                  </span>
                                  {/* <span> of task </span>
                                  {item.project_name != null ? (
                                    <span style={{ cursor: 'pointer' }}>{item.task_name}</span>
                                  ) : (
                                    <span style={{ cursor: 'pointer' }}>{item.task_name}</span>
                                  )} */}
                                </>
                              )}
                              <span className="label-text">
                                {' '}
                                updated{' '}
                                {item.field.includes('status') ? (
                                  <span> status</span>
                                ) : (
                                  <span> {item.field.toLowerCase()}</span>
                                )}{' '}
                                of {label_task}{' '}
                              </span>
                            </span>
                            {/* <>
                            {item.project_name != null && item.client_task == '1' ? (
                              <> */}
                            {/* <span> */}
                            <Link onClick={() => taskInformation(item.task_uid, item.client_uid)} to="/">
                              {item.task_name}
                            </Link>
                            <span className="label-text"> from </span>
                            {/* </span> */}
                            {/* </>
                            ) : !item.project_name && item.client_task == '1' ? (
                              <>
                                <span>
                                  {item.task_name}
                                </span>
                              </>
                            ) : item.project_name != null && item.client_task == '0' ? (
                              <>
                                <span>
                                  {item.task_name}
                                </span>
                              </>
                            ) : !item.project_name && item.client_task == '0' ? (
                              <>
                                <span>
                                  {item.task_name}
                                </span>
                              </>
                            ) : null}
                          </> */}
                            {/* <span> from </span> */}
                            <span style={{ fontWeight: '600' }}>
                              <>
                                {item.field == 'Start Date' ||
                                item.field == 'Due Date' ||
                                item.field == 'Delivery Date' ? (
                                  <>
                                    {item.old_date == '0000/00/00 00:00:00' ||
                                    item.old_date == '0000-00-00 00:00:00' ? (
                                      <span> None </span>
                                    ) : (
                                      <span> {item?.old_date} </span>
                                    )}
                                    <span style={{ fontWeight: '400' }}> to </span>
                                    {item.new_date == '0000/00/00 00:00:00' ||
                                    item.new_date == '0000-00-00 00:00:00' ? (
                                      <span> None </span>
                                    ) : (
                                      <span> {item?.new_date} </span>
                                    )}
                                  </>
                                ) : !(
                                    item.field == 'Start Date' ||
                                    item.field == 'Due Date' ||
                                    item.field == 'Delivery Date'
                                  ) ? (
                                  <>
                                    {item.field.includes('status') ? (
                                      <span>
                                        {item.old_value === '' ? null : (
                                          <span>
                                            {' '}
                                            {item.old_value == 'red' ? (
                                              <>
                                                <span
                                                  style={{
                                                    backgroundColor: '#E75B5C',
                                                    display: 'inline-block',
                                                    width: '8px',
                                                    height: '8px',
                                                    borderRadius: '60%',
                                                    marginRight: '8px',
                                                    marginLeft: '4px',
                                                  }}
                                                ></span>
                                                <span>Overdue</span>
                                              </>
                                            ) : item.old_value == 'green' ? (
                                              <>
                                                <span
                                                  style={{
                                                    backgroundColor: '#4F9F52',
                                                    display: 'inline-block',
                                                    width: '8px',
                                                    height: '8px',
                                                    borderRadius: '60%',
                                                    marginRight: '8px',
                                                    marginLeft: '4px',
                                                  }}
                                                ></span>
                                                <span>On Track</span>
                                              </>
                                            ) : item.old_value == 'amber' ? (
                                              <>
                                                <span
                                                  style={{
                                                    backgroundColor: '#EFA958',
                                                    display: 'inline-block',
                                                    width: '8px',
                                                    height: '8px',
                                                    borderRadius: '60%',
                                                    marginRight: '8px',
                                                    marginLeft: '4px',
                                                  }}
                                                ></span>
                                                <span>At Risk</span>
                                              </>
                                            ) : item.old_value == 'blue' ? (
                                              <>
                                                <span
                                                  style={{
                                                    backgroundColor: '#4390E1',
                                                    display: 'inline-block',
                                                    width: '8px',
                                                    height: '8px',
                                                    borderRadius: '60%',
                                                    marginRight: '8px',
                                                    marginLeft: '4px',
                                                  }}
                                                ></span>
                                                <span>Completed</span>
                                              </>
                                            ) : item.old_value == 'grey' ? (
                                              <>
                                                <span
                                                  style={{
                                                    backgroundColor: '#7a7a7a',
                                                    display: 'inline-block',
                                                    width: '8px',
                                                    height: '8px',
                                                    borderRadius: '60%',
                                                    marginRight: '8px',
                                                    marginLeft: '4px',
                                                  }}
                                                ></span>
                                                <span>Deferred</span>
                                              </>
                                            ) : (
                                              ''
                                            )}{' '}
                                          </span>
                                        )}
                                        <span style={{ fontWeight: '400' }} className="label-text">
                                          {' '}
                                          to{' '}
                                        </span>
                                        {item.new_value === '' ? (
                                          <span> None</span>
                                        ) : (
                                          <span>
                                            {' '}
                                            {item.new_value == 'red' ? (
                                              <>
                                                <span
                                                  style={{
                                                    backgroundColor: '#E75B5C',
                                                    display: 'inline-block',
                                                    width: '8px',
                                                    height: '8px',
                                                    borderRadius: '60%',
                                                    marginRight: '8px',
                                                    marginLeft: '4px',
                                                  }}
                                                ></span>
                                                <span>Overdue</span>
                                              </>
                                            ) : item.new_value == 'green' ? (
                                              <>
                                                <span
                                                  style={{
                                                    backgroundColor: '#4F9F52',
                                                    display: 'inline-block',
                                                    width: '8px',
                                                    height: '8px',
                                                    borderRadius: '60%',
                                                    marginRight: '8px',
                                                    marginLeft: '4px',
                                                  }}
                                                ></span>
                                                <span>On Track</span>
                                              </>
                                            ) : item.new_value == 'amber' ? (
                                              <>
                                                <span
                                                  style={{
                                                    backgroundColor: '#EFA958',
                                                    display: 'inline-block',
                                                    width: '8px',
                                                    height: '8px',
                                                    borderRadius: '60%',
                                                    marginRight: '8px',
                                                    marginLeft: '4px',
                                                  }}
                                                ></span>
                                                <span>At Risk</span>
                                              </>
                                            ) : item.new_value == 'blue' ? (
                                              <>
                                                <span
                                                  style={{
                                                    backgroundColor: '#4390E1',
                                                    display: 'inline-block',
                                                    width: '8px',
                                                    height: '8px',
                                                    borderRadius: '60%',
                                                    marginRight: '8px',
                                                    marginLeft: '4px',
                                                  }}
                                                ></span>
                                                <span>Completed</span>
                                              </>
                                            ) : item.new_value == 'grey' ? (
                                              <>
                                                <span
                                                  style={{
                                                    backgroundColor: '#7a7a7a',
                                                    display: 'inline-block',
                                                    width: '8px',
                                                    height: '8px',
                                                    borderRadius: '60%',
                                                    marginRight: '8px',
                                                    marginLeft: '4px',
                                                  }}
                                                ></span>
                                                <span>Deferred</span>
                                              </>
                                            ) : (
                                              ''
                                            )}{' '}
                                          </span>
                                        )}
                                      </span>
                                    ) : item.field.includes('Task Owner') ? (
                                      <span>
                                        <span>
                                          {' '}
                                          {getUserNameByUserUid(item.old_value) != ''
                                            ? getUserNameByUserUid(item.old_value)
                                            : getContactNameByContactUid(item.old_value)}{' '}
                                        </span>
                                        <span style={{ fontWeight: '400' }}>to</span>
                                        <span>
                                          {' '}
                                          {getUserNameByUserUid(item.new_value) != ''
                                            ? getUserNameByUserUid(item.new_value)
                                            : getContactNameByContactUid(item.new_value)}{' '}
                                        </span>
                                      </span>
                                    ) : (
                                      <span>
                                        {item.old_value === '' ? (
                                          <span> None </span>
                                        ) : item.old_value === '0' ? (
                                          <span> False </span>
                                        ) : item.old_value === '1' ? (
                                          <span> True </span>
                                        ) : (
                                          <span> {item.old_value} </span>
                                        )}
                                        <span style={{ fontWeight: '400' }}>to</span>
                                        {item.new_value === '' ? (
                                          <span> None</span>
                                        ) : item.new_value === '0' ? (
                                          <span> False</span>
                                        ) : item.new_value === '1' ? (
                                          <span> True</span>
                                        ) : (
                                          <span> {item.new_value} </span>
                                        )}
                                      </span>
                                    )}
                                  </>
                                ) : null}
                              </>
                            </span>
                          </>
                        ) : item.field == 'Created' && item.field != 'Comment' ? (
                          <>
                            <span>
                              {item.user_uid == 'CogniSaaS Platform' ? (
                                <>
                                  <span className="userNameHeading">CogniSaaS Platform </span>
                                </>
                              ) : item.user_uid == 'Jira Platform' ? (
                                <>
                                  <span className="userNameHeading">Jira Platform </span>
                                </>
                              ) : item.client_task == '0' ? (
                                <>
                                  <span className="userNameHeading">
                                    {item.user_uid != null
                                      ? getUserNameByUserUid(item.user_uid)
                                      : getContactNameByContactUid(item.contact_uid)}
                                  </span>
                                  {/* <span> of task </span>
                                {item.project_name != null ? (
                                  <span style={{ cursor: 'pointer' }}>
                                    {item.task_name}
                                  </span>
                                ) : (
                                  <span style={{ cursor: 'pointer' }}>
                                    {item.task_name}
                                  </span>
                                )} */}
                                </>
                              ) : (
                                <>
                                  <span className="userNameHeading">
                                    {item.user_uid != null
                                      ? getUserNameByUserUid(item.user_uid)
                                      : getContactNameByContactUid(item.contact_uid)}
                                  </span>
                                  {/* <span> of task </span>
                                {item.project_name != null ? (
                                  <span style={{ cursor: 'pointer' }}>
                                    {item.task_name}
                                  </span>
                                ) : (
                                  <span style={{ cursor: 'pointer' }}>
                                    {item.task_name}
                                  </span>
                                )} */}
                                </>
                              )}
                              <span> created the {label_task.toLowerCase()} </span>
                            </span>
                            {/* {item.project_name != null && item.client_task == '1' ? (
                            <>
                              <span>
                                {item.task_name}
                              </span>
                            </>
                          ) : !item.project_name && item.client_task == '1' ? (
                            <>
                              <span>
                                {item.task_name}
                              </span>
                            </>
                          ) : item.project_name != null && item.client_task == '0' ? (
                            <>
                              <span>
                                {item.task_name}
                              </span>
                            </>
                          ) : !item.project_name && item.client_task == '0' ? (
                            <>
                              <span>
                                {item.task_name}
                              </span>
                            </>
                          ) : null} */}
                            <Link onClick={() => taskInformation(item.task_uid, item.client_uid)} to="/">
                              {item.task_name}
                            </Link>
                          </>
                        ) : null}
                        <div className="item-detail">
                          <p>
                            {item.project_uid != null ? (
                              <>
                                <span>
                                  {label_project}:{' '}
                                  <RouterLink
                                    // style={{ textDecoration: 'none', color: 'blue' }}
                                    to={`/projects/project/${item.association_uid}/${item.project_uid}`}
                                  >
                                    {item.project_name}
                                  </RouterLink>
                                </span>
                              </>
                            ) : (
                              <>
                                <span className="label-text">
                                  {label_use_case}:{' '}
                                  <RouterLink
                                    // style={{ textDecoration: 'none', color: 'blue' }}
                                    to={`/client-list/client/${item.client_uid}`}
                                  >
                                    {item.case_name}
                                  </RouterLink>
                                </span>
                              </>
                            )}
                            &nbsp; &nbsp;
                            <span className="label-text">
                              {label_client}{' '}
                              <RouterLink
                                // style={{ textDecoration: 'none', color: 'blue' }}
                                to={`/client-list/client/${item.client_uid}`}
                              >
                                {item.client_name}
                              </RouterLink>
                            </span>
                          </p>
                        </div>
                      </div>
                    </li>
                  </>
                );
              } else {
                return (
                  <li key={index} className="tl-item">
                    <div className="timestamp_date">
                      {format(new Date(item.modified_on.replace(/-/g, '/')), 'HH:mm a')}
                    </div>
                    <div className="item-title">
                      {item.client_task == '0' ? (
                        <>
                          <span className="userNameHeading">
                            {item.user_uid != null
                              ? getUserNameByUserUid(item.user_uid)
                              : getContactNameByContactUid(item.contact_uid)}
                          </span>
                          <span> mentioned you in a comment {label_task} </span>
                          <Link onClick={() => taskInformation(item.task_uid, item.client_uid)} to="/">
                            {item.task_name}
                          </Link>
                        </>
                      ) : (
                        <>
                          <span className="userNameHeading">
                            {item.user_uid != null
                              ? getUserNameByUserUid(item.user_uid)
                              : getContactNameByContactUid(item.contact_uid)}
                          </span>
                          <span> mentioned you in a comment {label_task} </span>
                          <Link onClick={() => taskInformation(item.task_uid, item.client_uid)} to="/">
                            {item.task_name}
                          </Link>
                        </>
                      )}
                    </div>
                    <div className="item-detail">
                      <p>
                        {item.project_uid != null ? (
                          <>
                            <span>
                              {label_project}:{' '}
                              <RouterLink
                                // style={{ textDecoration: 'none', color: 'blue' }}
                                to={`/projects/project/${item.association_uid}/${item.project_uid}`}
                              >
                                {item.project_name}
                              </RouterLink>
                            </span>
                          </>
                        ) : (
                          <>
                            <span className="label-text">
                              {label_use_case}:{' '}
                              <RouterLink
                                // style={{ textDecoration: 'none', color: 'blue' }}
                                to={`/client-list/client/${item.client_uid}`}
                              >
                                {item.case_name}
                              </RouterLink>
                            </span>
                          </>
                        )}
                        &nbsp; &nbsp;
                        <span className="label-text">
                          {label_client}{' '}
                          <RouterLink
                            // style={{ textDecoration: 'none', color: 'blue' }}
                            to={`/client-list/client/${item.client_uid}`}
                          >
                            {item.client_name}
                          </RouterLink>
                        </span>
                      </p>
                    </div>
                    <div className="item-detail" style={{ display: 'flex' }}>
                      <span>&quot;</span>
                      <span>{generateCommentUi(item.new_value)}</span>
                      <span>&quot;</span>
                    </div>
                  </li>
                );
              }
            })}
          </div>
        </div>
      )
    );
    return comps;
  };
  const dispatch = useAppDispatch();
  let assignedToMeData = useAppSelector(getNotificationData).data[0];
  let mentionsData = useAppSelector(getNotificationData).data[1];
  let createdByMeData = useAppSelector(getNotificationData).data[2];
  const [AllgroupByKey, setAllgroupByKey] = useState(new Map<string, []>());
  const [assignedgroupByKey, setassignedgroupByKey] = useState(new Map<string, []>());
  const [mentiongroupByKey, setmentiongroupByKey] = useState(new Map<string, []>());
  const [createdgroupByKey, setcreatedgroupByKey] = useState(new Map<string, []>());
  const [client_uid, setclient_uid] = useState<any>({});
  const [taskDetails, settaskDetails] = useState<any>({});
  const [updateDrawer, setUpdateDrawer] = useState<boolean>(false);
  const [taskForDrawer, setTaskForDrawer] = useState<any>(null);
  const [loader, setLoader] = useState<boolean>(false);

  const [activeButton, setActiveButton] = useState(1);
  useEffect(() => {
    dataInit();
  }, []);
  let finalArray: any = [];
  useEffect(() => {
    if (assignedToMeData && createdByMeData) {
      finalArray = [...assignedToMeData, ...mentionsData, ...createdByMeData];
      // finalArray = [...assignedToMeData, ...createdByMeData];
    }
  }, [assignedToMeData, mentionsData, createdByMeData]);

  useEffect(() => {
    if (finalArray && finalArray.length > 0) {
      const tempArray: any = [];
      finalArray.map((trail: any) => {
        if (
          (trail.old_date !== '0000-00-00 00:00:00' && !isUndefined(trail.old_date)) ||
          (trail.new_date !== '0000-00-00 00:00:00' && !isUndefined(trail.old_date))
        ) {
          tempArray.push({
            ...trail,
            date_on: format(new Date(trail?.modified_on?.replace(/-/g, '/')), 'yyyy/MM/dd'),
            modified_on: format(new Date(trail?.modified_on.replace(/-/g, '/')), 'yyyy/MM/dd hh:mm a'),
            old_date:
              trail.old_date != '0000-00-00 00:00:00' && !isUndefined(trail.old_date)
                ? format(new Date(trail?.old_date?.replace(/-/g, '/')), 'yyyy/MM/dd')
                : '',
            new_date:
              trail.new_date != '0000-00-00 00:00:00' && !isUndefined(trail.new_date)
                ? format(new Date(trail?.new_date?.replace(/-/g, '/')), 'yyyy/MM/dd')
                : '',
          });
        } else {
          tempArray.push({
            ...trail,
            modified_on: format(new Date(trail?.modified_on.replace(/-/g, '/')), 'yyyy/MM/dd hh:mm a'),
            date_on: format(new Date(trail?.modified_on?.replace(/-/g, '/')), 'yyyy/MM/dd'),
          });
        }
        if (trail.field == 'Total Time Logged' || trail.field == 'Time Remaining' || trail.field == 'Effort Estimate') {
          tempArray.push({
            ...trail,
            old_value: convertMinutes(trail.old_value),
            new_value: convertMinutes(trail.new_value),
          });
        }
      });
      finalArray = tempArray;
      console.log(finalArray);
    }
  }, [finalArray]);
  useEffect(() => {
    finalArray.sort(sortByProperty('modified_on'));
  }, [finalArray]);

  // ALL assigned object
  useEffect(() => {
    // if (finalArray.length != 0) {
    // const newGroupByKey = new Map<string, []>;
    const testObj1: any = y(finalArray, 'date_on');
    const orderedDates1: any = {};
    Object.keys(testObj1)
      .sort(function (a, b) {
        return new Date(b.toString()).getTime() - new Date(a.toString()).getTime();
      })
      .forEach(function (key) {
        orderedDates1[key] = testObj1[key];
      });
    for (const key in orderedDates1) {
      setAllgroupByKey((map) => new Map(map.set(key, orderedDates1[key])));
      // newGroupByKey.set(key, orderedDates1[key]);
    }
    // }
  }, [finalArray]);

  // Mentioned data
  useEffect(() => {
    if (mentionsData && mentionsData.length > 0) {
      const tempArray: any = [];
      mentionsData.map((trail: any) => {
        tempArray.push({
          ...trail,
          modified_on: format(new Date(trail?.modified_on.replace(/-/g, '/')), 'yyyy/MM/dd hh:mm a'),
          date_on: format(new Date(trail?.modified_on?.replace(/-/g, '/')), 'yyyy/MM/dd'),
        });
      });
      mentionsData = tempArray;
    }
  }, [mentionsData]);
  useEffect(() => {
    if (mentionsData && mentionsData.length > 0) {
      const testObj2: any = y(mentionsData, 'date_on');
      const orderedDates2: any = {};
      Object.keys(testObj2)
        .sort(function (a, b) {
          return new Date(b.toString()).getTime() - new Date(a.toString()).getTime();
        })
        .forEach(function (key) {
          orderedDates2[key] = testObj2[key];
        });
      for (const key in orderedDates2) {
        setmentiongroupByKey((map) => new Map(map.set(key, orderedDates2[key])));
      }
    }
  }, [mentionsData]);

  // Assigned to me data
  useEffect(() => {
    if (assignedToMeData && assignedToMeData.length > 0) {
      const tempArray: any = [];
      assignedToMeData.map((trail: any) => {
        if (trail.field == 'Total Time Logged' || trail.field == 'Time Remaining' || trail.field == 'Effort Estimate') {
          if (
            (trail.old_date != '0000-00-00 00:00:00' && !isUndefined(trail.old_date)) ||
            (trail.new_date != '0000-00-00 00:00:00' && !isUndefined(trail.new_date))
          ) {
            tempArray.push({
              ...trail,
              old_value: convertMinutes(trail.old_value),
              new_value: convertMinutes(trail.new_value),
              modified_on: format(new Date(trail?.modified_on.replace(/-/g, '/')), 'yyyy/MM/dd hh:mm a'),
              date_on: format(new Date(trail?.modified_on?.replace(/-/g, '/')), 'yyyy/MM/dd'),
              old_date:
                trail.old_date != '0000-00-00 00:00:00' && !isUndefined(trail.old_date)
                  ? format(new Date(trail?.old_date?.replace(/-/g, '/')), 'yyyy/MM/dd')
                  : '',
              new_date:
                trail.new_date != '0000-00-00 00:00:00' && !isUndefined(trail.new_date)
                  ? format(new Date(trail?.new_date?.replace(/-/g, '/')), 'yyyy/MM/dd')
                  : '',
            });
          } else {
            tempArray.push({
              ...trail,
              old_value: convertMinutes(trail.old_value),
              new_value: convertMinutes(trail.new_value),
              modified_on: format(new Date(trail?.modified_on.replace(/-/g, '/')), 'yyyy/MM/dd hh:mm a'),
              date_on: format(new Date(trail?.modified_on?.replace(/-/g, '/')), 'yyyy/MM/dd'),
              // old_date: format(new Date(trail?.old_date?), 'yyyy/MM/dd'),
              // new_date: format(new Date(trail?.new_date?), 'yyyy/MM/dd'),
            });
          }
        } else {
          if (
            (trail.old_date != '0000-00-00 00:00:00' && !isUndefined(trail.old_date)) ||
            (trail.new_date != '0000-00-00 00:00:00' && !isUndefined(trail.new_date))
          ) {
            tempArray.push({
              ...trail,
              modified_on: format(new Date(trail?.modified_on.replace(/-/g, '/')), 'yyyy/MM/dd hh:mm a'),
              date_on: format(new Date(trail?.modified_on?.replace(/-/g, '/')), 'yyyy/MM/dd'),
              old_date:
                trail.old_date != '0000-00-00 00:00:00' && !isUndefined(trail.old_date)
                  ? format(new Date(trail?.old_date?.replace(/-/g, '/')), 'yyyy/MM/dd')
                  : '',
              new_date:
                trail.new_date != '0000-00-00 00:00:00' && !isUndefined(trail.new_date)
                  ? format(new Date(trail?.new_date?.replace(/-/g, '/')), 'yyyy/MM/dd')
                  : '',
            });
          } else {
            tempArray.push({
              ...trail,
              modified_on: format(new Date(trail?.modified_on.replace(/-/g, '/')), 'yyyy/MM/dd hh:mm a'),
              date_on: format(new Date(trail?.modified_on?.replace(/-/g, '/')), 'yyyy/MM/dd'),
              // old_date: format(new Date(trail?.old_date?), 'yyyy/MM/dd'),
              // new_date: format(new Date(trail?.new_date?), 'yyyy/MM/dd'),
            });
          }
        }
      });
      assignedToMeData = tempArray;
    }
  }, [assignedToMeData]);

  useEffect(() => {
    if (assignedToMeData && assignedToMeData.length > 0) {
      const testObj3: any = y(assignedToMeData, 'date_on');
      const orderedDates3: any = {};
      Object.keys(testObj3)
        .sort(function (a, b) {
          return new Date(b.toString()).getTime() - new Date(a.toString()).getTime();
        })
        .forEach(function (key) {
          orderedDates3[key] = testObj3[key];
        });
      for (const key in orderedDates3) {
        setassignedgroupByKey((map) => new Map(map.set(key, orderedDates3[key])));
        // assignedgroupByKey.set(key, orderedDates3[key]);
      }
    }
  }, [assignedToMeData]);

  // Created by me data
  useEffect(() => {
    if (createdByMeData && createdByMeData.length > 0) {
      const tempArray: any = [];
      createdByMeData.map((trail: any) => {
        if (trail.field == 'Total Time Logged' || trail.field == 'Time Remaining' || trail.field == 'Effort Estimate') {
          tempArray.push({
            ...trail,
            old_value: convertMinutes(trail.old_value),
            new_value: convertMinutes(trail.new_value),
            date_on: format(new Date(trail?.modified_on?.replace(/-/g, '/')), 'yyyy/MM/dd'),
            modified_on: format(new Date(trail?.modified_on.replace(/-/g, '/')), 'yyyy/MM/dd hh:mm a'),
            old_date:
              trail.old_date != '0000-00-00 00:00:00' && !isUndefined(trail.old_date)
                ? format(new Date(trail?.old_date?.replace(/-/g, '/')), 'yyyy/MM/dd')
                : '',
            new_date:
              trail.new_date != '0000-00-00 00:00:00' && !isUndefined(trail.new_date)
                ? format(new Date(trail?.new_date?.replace(/-/g, '/')), 'yyyy/MM/dd')
                : '',
          });
        } else {
          tempArray.push({
            ...trail,
            modified_on: format(new Date(trail?.modified_on), 'yyyy/MM/dd hh:mm a'),
            date_on: format(new Date(trail?.modified_on?.replace(/-/g, '/')), 'yyyy/MM/dd'),
            old_date:
              trail.old_date != '0000-00-00 00:00:00' && !isUndefined(trail.old_date)
                ? format(new Date(trail?.old_date?.replace(/-/g, '/')), 'yyyy/MM/dd')
                : '',
            new_date:
              trail.new_date != '0000-00-00 00:00:00' && !isUndefined(trail.old_date)
                ? format(new Date(trail?.new_date?.replace(/-/g, '/')), 'yyyy/MM/dd')
                : '',
          });
        }
      });
      createdByMeData = tempArray;
    }
  }, [createdByMeData]);
  useEffect(() => {
    if (createdByMeData && createdByMeData.length > 0) {
      const testObj4: any = y(createdByMeData, 'date_on');
      const orderedDates4: any = {};
      Object.keys(testObj4)
        .sort(function (a, b) {
          return new Date(b.toString()).getTime() - new Date(a.toString()).getTime();
        })
        .forEach(function (key) {
          orderedDates4[key] = testObj4[key];
        });
      for (const key in orderedDates4) {
        setcreatedgroupByKey((map) => new Map(map.set(key, orderedDates4[key])));
        // createdgroupByKey.set(key, orderedDates4[key]);
      }
    }
  }, [createdByMeData]);

  const dataInit = async () => {
    setLoader(true);
    await dispatch(getAllClientContacts());
    await dispatch(
      getNotificationData_slice({ user_uid: cookies.get('cogni_user_uid') ? cookies.get('cogni_user_uid') : '' })
    );
    setLoader(false);
  };

  const onChickActiveButton = (buttonNumber: number) => {
    setActiveButton(buttonNumber);
  };
  const taskInformation = async (task: any, client: any) => {
    const clientUID: any = { client_uid: client };
    setclient_uid(clientUID);
    const res = await dispatch(getTaskByTaskUid(task)).unwrap();
    setTaskForDrawer({ ...res });
    setUpdateDrawer(true);
  };

  const closingTaskDrawer = () => {
    setclient_uid({});
    setTaskForDrawer(null);
    setUpdateDrawer(false);
    settaskDetails({});
  };

  return (
    <>
      {loader ? (
        <CognisaasLoader />
      ) : (
        <>
          <Grid sx={{ width: '100%' }}>
            <Grid sx={{ marginTop: '20px' }}>
              <ButtonGroup sx={{ backgroundColor: 'white' }} variant="outlined" aria-label="outlined button group">
                <Button
                  className={activeButton == 1 ? 'active-btn' : undefined}
                  onClick={() => {
                    onChickActiveButton(1);
                  }}
                >
                  <Typography variant={'subtitle1'}>All</Typography>
                </Button>
                <Button
                  className={activeButton == 2 ? 'active-btn' : undefined}
                  onClick={() => {
                    onChickActiveButton(2);
                  }}
                >
                  <Typography variant={'subtitle1'}>Assigned to me</Typography>
                </Button>
                <Button
                  className={activeButton == 3 ? 'active-btn' : undefined}
                  onClick={() => {
                    onChickActiveButton(3);
                  }}
                >
                  <Typography variant={'subtitle1'}>Mentions</Typography>
                </Button>
                <Button
                  className={activeButton == 4 ? 'active-btn' : undefined}
                  onClick={() => {
                    onChickActiveButton(4);
                  }}
                >
                  <Typography variant={'subtitle1'}>Created by me</Typography>
                </Button>
              </ButtonGroup>
            </Grid>
            <Grid
              sx={{
                backgroundColor: 'white',
                width: '100%',
                padding: '13px 23px',
                borderRadius: '6px',
                display: 'flex',
                alignItems: 'flex-start',
                marginTop: '10px',
              }}
            >
              {activeButton == 1 ? (
                <>
                  {AllgroupByKey.size > 0 ? (
                    <div className="NOTIFICATION">
                      <div className="history-tl">
                        <ul className="tl">
                          <ShowContents props={AllgroupByKey} />
                        </ul>
                      </div>
                    </div>
                  ) : (
                    <Stack
                      style={{ marginTop: '32px', marginLeft: '40%' }}
                      justifyContent={'center'}
                      alignItems={'center'}
                    >
                      <Typography variant="h5">Nothing to show!</Typography>
                      <NoRecords style={{ width: '300px', height: '300px' }} />
                    </Stack>
                  )}
                </>
              ) : activeButton == 2 ? (
                <>
                  {assignedgroupByKey.size > 0 ? (
                    <div className="NOTIFICATION">
                      <div className="history-tl">
                        <ul className="tl">
                          <ShowContents props={assignedgroupByKey} />
                        </ul>
                      </div>
                    </div>
                  ) : (
                    <Stack
                      style={{ marginTop: '32px', marginLeft: '40%' }}
                      justifyContent={'center'}
                      alignItems={'center'}
                    >
                      <Typography variant="h5">Nothing to show!</Typography>
                      <NoRecords style={{ width: '300px', height: '300px' }} />
                    </Stack>
                  )}
                </>
              ) : activeButton == 3 ? (
                <>
                  {mentiongroupByKey.size > 0 ? (
                    <div className="NOTIFICATION">
                      <div className="history-tl">
                        <ul className="tl">
                          <ShowContents props={mentiongroupByKey} />
                        </ul>
                      </div>
                    </div>
                  ) : (
                    <Stack
                      style={{ marginTop: '32px', marginLeft: '40%' }}
                      justifyContent={'center'}
                      alignItems={'center'}
                    >
                      <Typography variant="h5">Nothing to show!</Typography>
                      <NoRecords style={{ width: '300px', height: '300px' }} />
                    </Stack>
                  )}
                </>
              ) : activeButton == 4 ? (
                <>
                  {createdgroupByKey.size > 0 ? (
                    <div className="NOTIFICATION">
                      <div className="history-tl">
                        <ul className="tl">
                          <ShowContents props={createdgroupByKey} />
                        </ul>
                      </div>
                    </div>
                  ) : (
                    <Stack
                      style={{ marginTop: '32px', marginLeft: '40%' }}
                      justifyContent={'center'}
                      alignItems={'center'}
                    >
                      <Typography variant="h5">Nothing to show!</Typography>
                      <NoRecords style={{ width: '300px', height: '300px' }} />
                    </Stack>
                  )}
                </>
              ) : null}
            </Grid>
          </Grid>
          {updateDrawer == true && taskDetails ? (
            <UpdateTaskDrawer
              client={client_uid}
              taskDetail={taskForDrawer}
              drawerState={updateDrawer}
              onClose={() => closingTaskDrawer()}
            />
          ) : null}
        </>
      )}
    </>
  );
  function sortByProperty(property: any) {
    return function (a: any, b: any) {
      if (new Date(a[property]) < new Date(b[property])) return 1;
      else if (new Date(a[property]) > new Date(b[property])) return -1;
      return 0;
    };
  }
  function convertMinutes(num: number) {
    if (num == 0) {
      return '0m';
    } else {
      let hour = Math.floor(num / 60); // 60 minutes in hour
      let day = Math.floor(hour / 8); // 8 hours a day office time
      const week = Math.floor(day / 5); // 5 days a week office time
      const minute = num - 60 * hour; // remaining minutes

      hour = hour - day * 8; // remaining hours
      day = day - week * 5; // remaining days

      let timeLogValue = ''; // Var to store Time Log Value

      if (week > 0) {
        timeLogValue = timeLogValue.concat(`${week}w `);
      }
      if (day > 0) {
        timeLogValue = timeLogValue.concat(`${day}d `);
      }
      if (hour > 0) {
        timeLogValue = timeLogValue = timeLogValue.concat(`${hour}h `);
      }
      if (minute > 0) {
        timeLogValue = timeLogValue.concat(`${minute}m `);
      }

      timeLogValue = timeLogValue.slice(0, -1); // To remove the last blank space
      return timeLogValue;
    }
  }
  function y(list: any[], key: string) {
    return list.reduce(
      (hash: { [x: string]: any }, { [key]: value, ...rest }: any) => ({
        ...hash,
        [value]: (hash[value] || []).concat([{ ...rest }]),
      }),
      []
    );
  }
};
export default Notification;
