import {apis} from '../axiosInstance';
import { adminPanel } from '../route';

export const getAllCustomerDetails = () => {
  return apis.phpAxiosInstance.get(adminPanel.getAllCustomerDetails(), {
    headers: { JWT: 'true' },
  });
};


export const getAllClientCounts = () => {
  return apis.phpAxiosInstance.get(adminPanel.getAllClientCounts(), {
    headers: { JWT: 'true' },
  });
};


export const getUserCount = () => {
  return apis.phpAxiosInstance.get(adminPanel.getUserCount(), {
    headers: { JWT: 'true' },
  });
};

export const getAllUserActivity = () => {
  return apis.phpAxiosInstance.get(adminPanel.getAllUserActivity(), {
    headers: { JWT: 'true' },
  });
};

export const deleteCustomer = (payload: any) => {
  return apis.phpAxiosInstance.get(adminPanel.deleteCustomer(payload), {
    headers: { JWT: 'true' },
  });
};

export const adminLogin = (payload: any) => {
  return apis.phpAxiosInstance.post(adminPanel.adminLogin(), payload, {
    headers: { JWT: 'false' },
  });
};

export const otpVerification = (payload: any) => {
  return apis.phpAxiosInstance.get(adminPanel.otpVerification(payload), {
    headers: { JWT: 'true' },
  });
};


export const getProjectCount = () => {
  return apis.phpAxiosInstance.get(adminPanel.getProjectCount(), {
    headers: { JWT: 'true' },
  });
};

export const getUsecaseCount = () => {
  return apis.phpAxiosInstance.get(adminPanel.getUsecaseCount(), {
    headers: { JWT: 'true' },
  });
};

export const saveAdminToken = (payload: any) => {
  return apis.phpAxiosInstance.post(adminPanel.saveAdminToken(), payload, {
    headers: { JWT: 'true' },
  });
};

export const checkAdminToken = (payload: any) => {
  return apis.phpAxiosInstance.post(adminPanel.checkAdminToken(), payload, {
    headers: { JWT: 'true' },
  });
};


export const getProjectCountByUsers = () => {
  return apis.phpAxiosInstance.get(adminPanel.getProjectCountByUsers(), {
    headers: { JWT: 'true' },
  });
};

export const getUsecaseCountByUsers = () => {
  return apis.phpAxiosInstance.get(adminPanel.getUsecaseCountByUsers(), {
    headers: { JWT: 'true' },
  });
};


// getAllCustomerDetails
// getAllClientCounts
// getUserCount
// getAllUserActivity


export const adminPanelAPI = {
    getAllCustomerDetails,
    getAllClientCounts,
    getUserCount,
    getAllUserActivity,
    deleteCustomer,
    adminLogin,
    otpVerification,
    getProjectCount,
    getUsecaseCount,
    saveAdminToken,
    checkAdminToken,
    getProjectCountByUsers,
    getUsecaseCountByUsers
};
