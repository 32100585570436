import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { requestState } from '../../types/index';

// it can be done later if needed right now it not worth of making such big api calls
export const fetchInitialAppRequirements = createAsyncThunk('/getCommonValues', async () => {
  // let openProjects = await getOpenProjects();
  // let closedProjects = await getClosedProjects();
  // if (openProjects == null) {
  //   openProjects = [];
  // }
  // if (closedProjects == null) {
  //   closedProjects = [];
  // }
  // const allProjects: any[] = [...openProjects, ...closedProjects];
  // return allProjects;
  // const menuOptions = {
  //   industry
  // }
});

interface StateProps {
  menuOptions: {
    [key: string]: string[];
  };
  menuOptionsStatus: requestState;
  menuOptionsError: null | undefined | string;
}

const initialState: StateProps = {
  menuOptions: {
    default: ['Aman', 'Arif'],
  },
  menuOptionsStatus: requestState.idle,
  menuOptionsError: null,
};

const commonDataSlice = createSlice({
  name: 'common data',
  initialState,
  reducers: {
    addOptionList: (state: StateProps, action: PayloadAction<{ listKey: string; optionList: string[] }>) => {
      state.menuOptions = { ...state.menuOptions, [action.payload.listKey]: [...action.payload.optionList] };
    },
    generateMenuOptions(state: StateProps, action: PayloadAction<any[]>) {
      // console.log(action.payload);
      const tempMenuOptions: Record<string, any> = {};

      // creating an empty options menu object with proper key
      Object.keys(action.payload[0]).forEach((item) => {
        // Object.defineProperty(tempMenuOptions, item, {
        //   value: [],
        // });
        tempMenuOptions[item] = [];
      });

      action.payload.forEach((dataObj) => {
        Object.keys(dataObj).forEach((item) => {
          // tempMenuOptions[item].add(dataObj[item]);
          if (tempMenuOptions[item].indexOf(dataObj[item]) === -1) {
            tempMenuOptions[item].push(dataObj[item]);
          }
        });
      });

      const newState = { ...state, menuOptions: { ...state.menuOptions, ...tempMenuOptions } };
      return newState;
    },
  },
});

const commonDataReducer = commonDataSlice.reducer;
export default commonDataReducer;
export const { addOptionList, generateMenuOptions } = commonDataSlice.actions;
