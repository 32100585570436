import { useState, useEffect } from 'react';
import { Card, Grid, Typography, Box, Stack } from '@mui/material';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Divider,
  Avatar,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../store/hooks';
import { teamMembersData_slice } from '../../../../store/modules/ProjectReport/slice';
import { teamMemberData_selector } from '../../../../store/modules/ProjectReport/selector';
import { GridColDef, GridToolbar } from '@mui/x-data-grid';
import { DataGridPro, GridColumnOrderChangeParams } from '@mui/x-data-grid-pro';
import CognisaasLoader from '../../../shared/CognisaasLoader/CognisaasLoader';
import { calculateHelperColumns } from './helperFunctions';
import Cookies from 'universal-cookie';
import { stringToHslColor } from 'components/ResourceManagement/utils/transformers';
import { label_project, label_task, label_use_case } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import FilterTypeSelector from 'components/shared/CognisaasFilters/FilterTypeSelector/FilterTypeSelector';
import SelectedFilterList from 'components/shared/CognisaasFilters/SelectedFilterList/SelectedFilterList';
import { selectAllFilters } from 'store/modules/AllFilters/selector';
import { addFilterGroup } from 'store/modules/AllFilters/slice';
import { generateOrderedColumn, isNull, isUndefined } from 'utils/dataHelpers';
import { FilterableData } from 'utils/filteringFunctions';
import { selectUsersOfCompany, selectUsersOfCompanyStatus } from 'store/modules/Common/selector';
import { getAllUsers } from 'store/modules/Common/slice';
import useCommonAppConfig from 'components/Common/useCommonAppConfig';
const cookies = new Cookies();

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToHslColor(name, 30, 80),
    },
    children: (
      <Typography sx={{ fontSize: '14px', fontWeight: '400', color: '#000000' }}>
        {name.split(' ')[0][0]}
        {name.indexOf(' ') >= 0 ? name.split(' ')[1][0] : null}
      </Typography>
    ),
  };
}

function CustomizedTables(data: any) {
  return (
    <>
      {data?.props && (
        <TableContainer sx={{ width: '450px' }} component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow sx={{ backgroundColor: '#DBDBDB', height: '10px' }}>
                <TableCell align="center">
                  <Typography variant="h3">Team Member</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="h3">{label_project}s</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="h3">{label_use_case}s</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="h3">{label_task}s</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.props?.map((element: any) => (
                <TableRow style={{ height: '20px' }} key={element.user_username}>
                  <TableCell sx={{ height: '15px' }} align="center">
                    <Typography variant="subtitle1">{element.user_username}</Typography>
                  </TableCell>
                  <TableCell sx={{ height: '15px' }} align="center">
                    <Typography variant="subtitle1">{element.totalProjects}</Typography>
                  </TableCell>
                  <TableCell sx={{ height: '15px' }} align="center">
                    <Typography variant="subtitle1">{element.totalUsecases}</Typography>
                  </TableCell>
                  <TableCell sx={{ height: '15px' }} align="center">
                    <Typography variant="subtitle1">{element.taskAssigned}</Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}

const TopPerformingUserComponent = (props: any) => {
  return (
    <>
      {props?.props && (
        <>
          <Grid
            sx={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: '10px',
              justifyContent: 'flex-start',
              width: '30%',
            }}
          >
            <Grid sx={{ display: 'flex', justifyContent: 'flex-start' }}>
              <Grid>
                <Avatar style={{ height: '34px', width: '34px' }} {...stringAvatar(props?.props?.user_username)} />
              </Grid>
              <Grid>
                <Typography variant="h2" sx={{ margin: '5px 0 0 15px' }}>
                  {props?.props?.user_username.length <= 10 ? (
                    props?.props?.user_username
                  ) : (
                    <>{props?.props?.user_username.slice(0, 7)}...</>
                  )}
                </Typography>
              </Grid>
            </Grid>
            {/* <Rating
              name="half-rating"
              sx={{ width: '100%', marginTop: '8px', display: 'flex', justifyContent: 'space-between' }}
              defaultValue={2.7}
              precision={0.05}
            /> */}
          </Grid>
          <Grid>
            <Divider orientation="vertical" sx={{ width: '2px', height: '136%' }} />
          </Grid>
          <Grid
            sx={{
              width: '200px',
              marginTop: '10px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Grid sx={{ display: 'flex', position: 'relative', justifyContent: 'space-around' }}>
              <Typography
                sx={{
                  fontFamily: 'Noto Sans',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  fontSize: '18px',
                  lineHeight: '20px',
                  color: 'black',
                  position: 'absolute',
                  left: 0,
                }}
              >
                {label_project}s
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Noto Sans',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  fontSize: '18px',
                  lineHeight: '20px',
                  color: 'black',
                  marginLeft: '55px',
                }}
              >
                :
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Noto Sans',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  fontSize: '18px',
                  lineHeight: '20px',
                  color: 'black',
                  position: 'absolute',
                  right: 0,
                }}
              >
                {!isNaN(props?.props?.projectCompletedOnTime / props?.props?.totalProjects)
                  ? ((props?.props?.projectCompletedOnTime / props?.props?.totalProjects) * 100).toFixed(1)
                  : 0}
                %
              </Typography>
            </Grid>
            <Grid sx={{ display: 'flex', position: 'relative', justifyContent: 'space-around' }}>
              <Typography
                sx={{
                  fontFamily: 'Noto Sans',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  fontSize: '18px',
                  lineHeight: '20px',
                  color: 'black',
                  position: 'absolute',
                  left: 0,
                }}
              >
                {label_use_case}s
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Noto Sans',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  fontSize: '18px',
                  lineHeight: '20px',
                  color: 'black',
                  marginLeft: '55px',
                }}
              >
                :
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Noto Sans',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  fontSize: '18px',
                  lineHeight: '20px',
                  color: 'black',
                  position: 'absolute',
                  right: 0,
                }}
              >
                {!isNaN(props?.props?.usecaseCompletedOnTime / props?.props?.totalUsecases)
                  ? ((props?.props?.usecaseCompletedOnTime / props?.props?.totalUsecases) * 100).toFixed(1)
                  : 0}
                %
              </Typography>
            </Grid>
            <Grid sx={{ display: 'flex', position: 'relative', justifyContent: 'space-around' }}>
              <Typography
                sx={{
                  fontFamily: 'Noto Sans',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  fontSize: '18px',
                  lineHeight: '20px',
                  color: 'black',
                  position: 'absolute',
                  left: 0,
                }}
              >
                {label_task}s
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Noto Sans',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  fontSize: '18px',
                  lineHeight: '20px',
                  color: 'black',
                  marginLeft: '55px',
                }}
              >
                :
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Noto Sans',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  fontSize: '18px',
                  lineHeight: '20px',
                  color: 'black',
                  position: 'absolute',
                  right: 0,
                }}
              >
                {!isNaN(props?.props?.taskCompletedOnTime / props?.props?.taskAssigned)
                  ? ((props?.props?.taskCompletedOnTime / props?.props?.taskAssigned) * 100).toFixed(1)
                  : 0}
                %
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

const MainTableComponent = (props: any) => {
  const [dataToShow, setDataToShow] = useState<Record<string, any>[]>([]);
  const [tableColumns, setTableColumns] = useState<any[]>([]);
  // const [pageSize, setPageSize] = useState(5);

  const uniqueColumnId = 'reportPerformanceTeamListTable';
  let myTableState: any = {};
  const { saveAppTableState, getTableState } = useCommonAppConfig();
  const initialState: any = {
    columns: {},
    pinnedColumns: {},
    pagination: {
      page: 0,
      pageSize: 5,
    },
    ...getTableState('performanceReportTeam'),
  };

  const handleTableSave = (idName: string, thisTableState: any) => {
    saveAppTableState(idName, thisTableState);
  };

  useEffect(() => {
    if (props.data) {
      setDataToShow(props.data);
    } else {
      setDataToShow([]);
    }

    setTableColumns(generateOrderedColumn(columns, uniqueColumnId));
  }, [props]);

  const columns: GridColDef[] = [
    {
      field: 'user_username',
      headerName: 'Team Member',
      width: 190,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (obj: any) => (
        <Typography variant="h3">
          {/* <Link> */}
          {obj.row.user_username}
          {/* </Link> */}
        </Typography>
      ),
    },
    {
      field: 'totalProjects',
      headerName: `Total ${label_project}s`,
      width: 160,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (obj: any) => <Typography variant="h3">{obj.row.totalProjects}</Typography>,
    },
    {
      field: 'taskAssigned',
      headerName: `${label_task} Assigned`,
      width: 160,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (obj: any) => <Typography variant="h3">{obj.row.taskAssigned}</Typography>,
    },
    {
      field: 'totalUsecases',
      headerName: `Total ${label_use_case} s`,
      width: 160,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (obj: any) => <Typography variant="h3">{obj.row.totalUsecases}</Typography>,
    },
    {
      field: 'taskCompletedOnTime',
      headerName: `${label_task} completed on-time`,
      width: 160,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (obj: any) => <Typography variant="h3">{obj.row.taskCompletedOnTime}</Typography>,
    },
    {
      field: 'taskCompletedLate',
      headerName: `${label_task}s completed late`,
      width: 160,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (obj: any) => <Typography variant="h3">{obj.row.taskCompletedLate}</Typography>,
    },
    {
      field: 'totalTaskCompleted',
      headerName: `Total ${label_task}s completed`,
      width: 160,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (obj: any) => <Typography variant="h3">{obj.row.totalTaskCompleted}</Typography>,
    },
    {
      field: 'tasksOverdue',
      headerName: `Total ${label_task}s overdue`,
      width: 160,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (obj: any) => <Typography variant="h3">{obj.row.tasksOverdue}</Typography>,
    },
    {
      field: 'riskedTask',
      headerName: `${label_task}s marked at-risk`,
      width: 160,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (obj: any) => <Typography variant="h3">{obj.row.riskedTask}</Typography>,
    },
    {
      field: 'tasksMarkedBlockList',
      headerName: `${label_task}s marked blocked`,
      width: 160,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (obj: any) => <Typography variant="h3">{obj.row.tasksMarkedBlockList}</Typography>,
    },
  ];

  const handleColumnOrderChange = (params: GridColumnOrderChangeParams) => {
    const oldArrayIndex = params.oldIndex;
    const targetArrayIndex = params.targetIndex;

    setTableColumns(generateOrderedColumn(tableColumns, uniqueColumnId, oldArrayIndex, targetArrayIndex));
  };

  return (
    <Grid sx={{ height: '100%' }}>
      {props.data ? (
        <DataGridPro
          initialState={initialState}
          onStateChange={(tblState: any) => {
            myTableState = tblState;
          }}
          onColumnVisibilityModelChange={() => {
            handleTableSave('performanceReportTeam', myTableState);
          }}
          onFilterModelChange={() => {
            handleTableSave('performanceReportTeam', myTableState);
          }}
          onPageSizeChange={() => {
            handleTableSave('performanceReportTeam', myTableState);
          }}
          components={{
            Toolbar: GridToolbar,
          }}
          componentsProps={{
            toolbar: { showQuickFilter: true },
          }}
          rows={dataToShow}
          columns={tableColumns}
          getRowId={(obj: any) => obj.user_uid}
          loading={!props.data}
          pagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          // pageSize={pageSize}
          // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowHeight={52}
          onColumnOrderChange={handleColumnOrderChange}
        />
      ) : (
        <Grid sx={{ marginTop: '-160px' }}>
          <CognisaasLoader />
        </Grid>
      )}
    </Grid>
  );
};

const TeamPerformance = () => {
  const [data, setData] = useState<Record<string, any>[]>([]);
  const allFilters = useAppSelector(selectAllFilters);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const allUsers = useAppSelector(selectUsersOfCompany);
  const allUsersStatus = useAppSelector(selectUsersOfCompanyStatus);

  const dispatch = useDispatch();

  const teamMemberData_fromSelector = useAppSelector(teamMemberData_selector).data as Record<string, any>;

  useEffect(() => {
    const customer_uid = cookies.get('cogni_customer_uid') as string;
    dispatch(teamMembersData_slice(customer_uid));
  }, []);

  useEffect(() => {
    const newData = calculateHelperColumns(teamMemberData_fromSelector.result);
    setData(newData);
  }, [teamMemberData_fromSelector]);

  useEffect(() => {
    if (allUsersStatus === 'success') {
      if (isUndefined(allFilters['performanceTeamReport']) || isNull(allFilters['performanceTeamReport'])) {
        dispatch(
          addFilterGroup({
            idName: 'performanceTeamReport',
            appliedFilters: [
              {
                name: 'Team Member Name',
                dataKey: 'user_username',
                isSelected: false,
                equalToValue: [],
                canBeCleared: true,
                availableOptions: allUsers.map((item: any) => item.user_username),
              },
              {
                name: `Total ${label_project}s$$number-range`,
                dataKey: 'totalProjects',
                isSelected: false,
                equalToValue: [],
                canBeCleared: true,
                availableOptions: [],
              },
              {
                name: `${label_task}s Assigned$$number-range`,
                dataKey: 'taskAssigned',
                isSelected: false,
                equalToValue: [],
                canBeCleared: true,
                availableOptions: [],
              },
              {
                name: `Total ${label_use_case}s$$number-range`,
                dataKey: 'totalUsecases',
                isSelected: false,
                equalToValue: [],
                canBeCleared: true,
                availableOptions: [],
              },
              {
                name: `${label_task} Completed On-Time$$number-range`,
                dataKey: 'taskCompletedOnTime',
                isSelected: false,
                equalToValue: [],
                canBeCleared: true,
                availableOptions: [],
              },
              {
                name: `${label_task} Completed Late$$number-range`,
                dataKey: 'taskCompletedLate',
                isSelected: false,
                equalToValue: [],
                canBeCleared: true,
                availableOptions: [],
              },
              {
                name: `Total ${label_task}s Completed$$number-range`,
                dataKey: 'totalTaskCompleted',
                isSelected: false,
                equalToValue: [],
                canBeCleared: true,
                availableOptions: [],
              },
              {
                name: `Total ${label_task}s Overdue$$number-range`,
                dataKey: 'tasksOverdue',
                isSelected: false,
                equalToValue: [],
                canBeCleared: true,
                availableOptions: [],
              },
              {
                name: `${label_task}s Marked At Risk$$number-range`,
                dataKey: 'riskedTask',
                isSelected: false,
                equalToValue: [],
                canBeCleared: true,
                availableOptions: [],
              },
              {
                name: `${label_task}s Marked Blocked$$number-range`,
                dataKey: 'tasksMarkedBlockList',
                isSelected: false,
                equalToValue: [],
                canBeCleared: true,
                availableOptions: [],
              },
            ],
          })
        );
      }
    }
  }, [allFilters, allUsersStatus]);

  useEffect(() => {
    if (allFilters['performanceTeamReport'] && data) {
      const newFilteredData = new FilterableData([...data]);
      newFilteredData.bulkFilter(allFilters['performanceTeamReport'].appliedFilters);
      setFilteredData([...newFilteredData.toArray()]);
    }
  }, [allFilters, data]);

  // filter options fetch
  useEffect(() => {
    if (allUsersStatus === 'idle' || allUsersStatus === 'failed') {
      dispatch(getAllUsers());
    }
  }, [allUsersStatus]);

  return (
    <>
      <Stack direction={'row'} justifyContent={'space-between'} sx={{ marginBottom: '24px' }}>
        <Box>
          {allFilters['performanceTeamReport']?.appliedFilters.filter((item: any) => item.isSelected).length > 0 && (
            <SelectedFilterList idName="performanceTeamReport" isResetEnabled={true} />
          )}
        </Box>
        <FilterTypeSelector idName="performanceTeamReport" />
      </Stack>
      <Grid sx={{ backgroundColor: 'white', padding: '30px', borderRadius: '5px' }}>
        <Grid sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <Box
            sx={{
              maxHeight: '300px',
              overflowY: 'scroll',
              border: '1px solid rgba(224, 224, 224, 1)',
              boxShadow:
                '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
              borderRadius: '4px',
            }}
          >
            <Card sx={{ padding: '10px' }}>
              <Typography variant="h2" sx={{ margin: '6px 0' }}>
                {label_project}s, {label_task}s: Workload
              </Typography>
              <CustomizedTables props={filteredData} />
            </Card>
          </Box>
          <Box
            sx={{
              maxHeight: '300px',
              overflowY: 'scroll',
              border: '1px solid rgba(224, 224, 224, 1)',
              boxShadow:
                '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
              borderRadius: '4px',
            }}
          >
            <Card>
              <Grid sx={{ display: 'flex', padding: '15px' }}>
                <Typography
                  sx={{
                    fontFamily: 'Noto Sans',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    fontSize: '18px',
                    lineHeight: '20px',
                    color: 'black',
                  }}
                >
                  Top Performers
                </Typography>{' '}
                &nbsp;
                <Typography
                  variant="h3"
                  sx={{
                    fontFamily: 'Noto Sans',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    fontSize: '17px',
                    lineHeight: '20px',
                    color: '#7A7A7A',
                  }}
                >
                  (Percentage completed on time)
                </Typography>
              </Grid>
              <Divider />
              {filteredData &&
                filteredData?.map((element: any) => {
                  return (
                    <>
                      <Grid sx={{ display: 'flex', justifyContent: 'space-evenly', margin: '12px 0px 12px 0px' }}>
                        <TopPerformingUserComponent props={element} />
                      </Grid>
                      <Divider />
                    </>
                  );
                })}
            </Card>
          </Box>
        </Grid>

        <Grid sx={{ height: '400px', marginTop: '30px' }}>
          <MainTableComponent data={filteredData} /*openDrawer={setRightDrawerOpen}*/ />
        </Grid>
      </Grid>
    </>
  );
};

export default TeamPerformance;
