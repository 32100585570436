import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../index';

const selectSettingDataState = (state: RootState) => state.projectReportsReducer;

export const projectData_selector = createSelector(
    selectSettingDataState,
    (projectDataSlice) => {
        return projectDataSlice.projectDetailsForProjectPerformance;
    }
);

export const phasesAndTasks_selector = createSelector(
    selectSettingDataState,
    (projectDataSlice) => {
        return projectDataSlice.phasesAndTasks;
    }
);

export const teamMemberData_selector = createSelector(
    selectSettingDataState,
    (projectDataSlice) => {
        return projectDataSlice.teamMemberData;
    }
);