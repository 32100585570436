import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { requestState } from '../types';
import { rbacAPI } from '../../../api/modules/rbac';

export const getAllUsersAndRoles_slice = createAsyncThunk('getAllUsersandRoles', async () => {
  const { data } = await rbacAPI.getAllUsersAndRoles();
  // console.log('rbac data:: ', data);
  return data;
});

interface getResourcesField_payload {
  resource: string;
}
export const getResourcesField_slice = createAsyncThunk(
  'getResourcesFields',
  async (payload: getResourcesField_payload) => {
    const { data } = await rbacAPI.getResourcesFields(payload);
    return data;
  }
);

interface getResourcesFieldValue_payload {
  resource: string;
  field: string;
}

export const getResourcesFieldValue_slice = createAsyncThunk(
  'getResourcesFieldValue',
  async (payload: getResourcesFieldValue_payload) => {
    const { data } = await rbacAPI.getResourcesFieldValues(payload);
    return data ?? [];
  }
);

interface getAllPermissions_payload {
  role: string;
}

export const getAllPermissions_slice = createAsyncThunk(
  'getAllPermissions',
  async (payload: getAllPermissions_payload) => {
    const { data } = await rbacAPI.getAllPermissions(payload);
    return data;
  }
);

interface getPolicyOnResources_payload {
  resource: string;
}
export const getPolicyOnResources_slice = createAsyncThunk(
  'getPolicyOnResources',
  async (payload: getPolicyOnResources_payload) => {
    const { data } = await rbacAPI.getPolicyOnResources(payload);
    return data;
  }
);

export const getAllResources_slice = createAsyncThunk('getAllResources', async () => {
  const { data } = await rbacAPI.getAllResources();
  return data;
});

export const getGeneralFieldValue_slice = createAsyncThunk('getGeneralFieldValue', async () => {
  const { data } = await rbacAPI.getGeneralFieldValues();
  return data;
});

export const getAllPages_slice = createAsyncThunk('getAllPages', async () => {
  const { data } = await rbacAPI.getAllPages();
  return data;
});

export const enableRbac = createAsyncThunk('rbac/enableRbac', async () => {
  await rbacAPI.enableRbac();
});

export const disableRbac = createAsyncThunk('rbac/disableRbac', async () => {
  await rbacAPI.disableRbac();
});

export const addReportsTo = createAsyncThunk('rbac/addReportsTo', async (detail: any) => {
  await rbacAPI.addReportsTo(detail);
});

export const removeReportsTo = createAsyncThunk('rbac/addReportsTo', async (detail: any) => {
  await rbacAPI.removeReportsTo(detail);
});

interface createPolicy_payload {
  name: string;
  description: string;
  effect: string;
  access: any;
  conditionIds: any;
  resourceId: any;
}

export const createPolicy_slice = createAsyncThunk('getAllPages', async (payload: createPolicy_payload) => {
  const { data } = await rbacAPI.createPolicy(payload);
  return data;
});

interface createCondition_payload {
  name: string;
  description: string;
  resourceId: string;
  operator: any;
  conditionalKey: any;
  conditionalValue: any;
}

export const createCondition_slice = createAsyncThunk('getAllPages', async (payload: createCondition_payload) => {
  const { data } = await rbacAPI.createCondition(payload);
  return data;
});

interface addUser_payload {
  firstname: string;
  team: string;
  lastname: string;
  email: string;
  role: string;
  invited: boolean;
  username: string;
}

export const addUser_slice = createAsyncThunk('addUser', async (payload: addUser_payload) => {
  const { data } = await rbacAPI.addUser(payload);
  return data;
});

export const getValidReporteeAdditions_slice = createAsyncThunk(
  'getValidReporteeAdditions_slice',
  async (user_id: string) => {
    const { data } = await rbacAPI.getValidReporteeAdditions(user_id);
    return data?.valid_reportee_additions ?? [];
  }
);

interface addRole_payload {
  name: string;
  description: string;
}

export const addRole_slice = createAsyncThunk('addUser', async (payload: addRole_payload) => {
  const { data } = await rbacAPI.addRole(payload);
  return data;
});

interface activate_or_deactivateUser_payload {
  userId: string;
}

export const deactivateUser_slice = createAsyncThunk(
  'deactivateUser',
  async (payload: activate_or_deactivateUser_payload) => {
    const { data } = await rbacAPI.deactivateUser(payload);
    return data;
  }
);

export const activateUser_slice = createAsyncThunk(
  'activateUser',
  async (payload: activate_or_deactivateUser_payload) => {
    const { data } = await rbacAPI.activateUser(payload);
    return data;
  }
);

interface UsernameUpdatePayload {
  userId: string;
  new_username: string;
}

export const updateUserUsername = createAsyncThunk(
  'settings/users/update_username',
  async (payload: UsernameUpdatePayload) => {
    const { data } = await rbacAPI.updateUsernameOfUser(payload);
    return data;
  }
);

interface addAndRemovePolicyOnROles_payload {
  roleId: any;
  policyIds: any;
}

export const addPolicyToRole_slice = createAsyncThunk(
  'addPolicyToRole',
  async (payload: addAndRemovePolicyOnROles_payload) => {
    const { data } = await rbacAPI.addPolicyToRole(payload);
    return data;
  }
);

export const removePolicyFromRole_slice = createAsyncThunk(
  'removePolicyFromRole',
  async (payload: addAndRemovePolicyOnROles_payload) => {
    const { data } = await rbacAPI.removePolicyfromRole(payload);
    return data;
  }
);

interface addAndRemovePagesOnROles_payload {
  roleId: any;
  pageIds: any;
}

export const addPagesToRole_slice = createAsyncThunk(
  'addPagesToRole',
  async (payload: addAndRemovePagesOnROles_payload) => {
    const { data } = await rbacAPI.addPagesToRole(payload);
    return data;
  }
);

export const removePagesFromRole_slice = createAsyncThunk(
  'removePagesFromRole',
  async (payload: addAndRemovePagesOnROles_payload) => {
    const { data } = await rbacAPI.removePagesfromRole(payload);
    return data;
  }
);

interface changeUserRole_payload {
  roleId: string;
  userId: string;
}

export const changeUserRole_slice = createAsyncThunk('changeUserRole', async (payload: changeUserRole_payload) => {
  const { data } = await rbacAPI.changeUserRole(payload);
  return data;
});

export const getAllRoles_slice = createAsyncThunk('getAllroles', async () => {
  const { data } = await rbacAPI.getAllRoles();
  return data ?? [];
});

export const getRole_slice = createAsyncThunk('getRole', async (roleId: string) => {
  const { data } = await rbacAPI.getRole(roleId);
  return data ?? [];
});

interface getAllConditionsPayload {
  resource: string;
}

export const getAllConditions_slice = createAsyncThunk('getAllConditions', async (payload: getAllConditionsPayload) => {
  const { data } = await rbacAPI.getAllConditions(payload);
  return data ?? [];
});

interface remove_or_addCondition_payload {
  policyId: string;
  conditionIds: any;
}

export const removeCondition_slice = createAsyncThunk(
  'removeCondition',
  async (payload: remove_or_addCondition_payload) => {
    const { data } = await rbacAPI.removeConditionsFromPolicy(payload);
    return data ?? [];
  }
);

export const addConditionsToPolicy_slice = createAsyncThunk(
  'addConditionsToPolicy',
  async (payload: remove_or_addCondition_payload) => {
    const { data } = await rbacAPI.addConditionsToPolicy(payload);
    return data ?? [];
  }
);

export const getConditions_slice = createAsyncThunk('getConditions', async () => {
  const { data } = await rbacAPI.getConditions();
  return data ?? [];
});

export const getRbacToggleAffectingUsers_slice = createAsyncThunk('getRbacToggleAffectingUsers', async () => {
  const { data } = await rbacAPI.getRbacToggleAffectingUsers();
  return data ?? [];
});

export const bootstrapRBACWithDefaultRole_slice = createAsyncThunk(
  'bootstrapRBACWithDefaultRole',
  async (roleId: string) => {
    const { data } = await rbacAPI.bootstrapRbacWIthDefaultRole({ roleId });
    return data ?? [];
  }
);

interface RBACProps {
  usersAndRoles: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };
  resourcesFields: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };
  resourcesFieldsValues: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };
  allPermissions: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };
  validReporteeAdditions: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };
  policyOnResources: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };
  allResources: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };
  generalFieldsValues: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };
  allPages: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };
  allRoles: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };
  roleDetails: {
    data: any;
    status: requestState;
    error: null | undefined | string;
  };
  allConditions: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };
  conditions: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };
  rbacAffectingUsers: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };
  bootStrapRBACWithDefaultRole: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };
  updatingRBAC: {
    status: requestState;
    error: null | undefined | string;
  };
  updatingUser: {
    status: requestState;
    message: string;
  };
}

const initialState: RBACProps = {
  usersAndRoles: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  validReporteeAdditions: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  resourcesFields: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  resourcesFieldsValues: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  allPermissions: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  policyOnResources: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  allResources: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  generalFieldsValues: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  allPages: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  allRoles: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  roleDetails: {
    data: {},
    status: requestState.idle,
    error: null,
  },
  allConditions: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  conditions: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  rbacAffectingUsers: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  bootStrapRBACWithDefaultRole: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  updatingRBAC: {
    status: requestState.idle,
    error: null,
  },
  updatingUser: {
    status: requestState.idle,
    message: '',
  },
};

export const rbacSlice = createSlice({
  name: 'setting data',
  initialState,
  reducers: {
    //without api calls
    resetForUpdatingUser: (state) => {
      state.updatingUser = initialState.updatingUser;
    },
  },
  extraReducers(builder) {
    builder
      // journey stages
      // get custom view columns
      .addCase(getAllUsersAndRoles_slice.pending, (state) => {
        state.usersAndRoles.status = requestState.loading;
      })
      .addCase(getAllUsersAndRoles_slice.fulfilled, (state, action: PayloadAction<any>) => {
        state.usersAndRoles.status = requestState.success;
        state.usersAndRoles.data = action.payload;
      })
      .addCase(getAllUsersAndRoles_slice.rejected, (state, action: any) => {
        state.usersAndRoles.status = requestState.failed;
        state.usersAndRoles.error = action.error.message;
      })
      // resource fields
      .addCase(getResourcesField_slice.pending, (state) => {
        state.resourcesFields.status = requestState.loading;
      })
      .addCase(getResourcesField_slice.fulfilled, (state, action: PayloadAction<any>) => {
        state.resourcesFields.status = requestState.success;
        state.resourcesFields.data = action.payload;
      })
      .addCase(getResourcesField_slice.rejected, (state, action: any) => {
        state.resourcesFields.status = requestState.failed;
        state.resourcesFields.error = action.error.message;
      })
      // resource fields values
      .addCase(getResourcesFieldValue_slice.pending, (state) => {
        state.resourcesFieldsValues.status = requestState.loading;
      })
      .addCase(getResourcesFieldValue_slice.fulfilled, (state, action: PayloadAction<any>) => {
        state.resourcesFieldsValues.status = requestState.success;
        state.resourcesFieldsValues.data = action.payload;
      })
      .addCase(getResourcesFieldValue_slice.rejected, (state, action: any) => {
        state.resourcesFieldsValues.status = requestState.failed;
        state.resourcesFieldsValues.error = action.error.message;
      })
      // all Permissions
      .addCase(getAllPermissions_slice.pending, (state) => {
        state.allPermissions.status = requestState.loading;
      })
      .addCase(getAllPermissions_slice.fulfilled, (state, action: PayloadAction<any>) => {
        state.allPermissions.status = requestState.success;
        state.allPermissions.data = action.payload;
      })
      .addCase(getAllPermissions_slice.rejected, (state, action: any) => {
        state.allPermissions.status = requestState.failed;
        state.allPermissions.error = action.error.message;
      })

      // all Permissions
      .addCase(getValidReporteeAdditions_slice.pending, (state) => {
        state.validReporteeAdditions.status = requestState.loading;
      })
      .addCase(getValidReporteeAdditions_slice.fulfilled, (state, action: PayloadAction<any>) => {
        state.validReporteeAdditions.status = requestState.success;
        state.validReporteeAdditions.data = action.payload;
      })
      .addCase(getValidReporteeAdditions_slice.rejected, (state, action: any) => {
        state.validReporteeAdditions.status = requestState.failed;
        state.validReporteeAdditions.error = action.error.message;
      })
      // policy on resources
      .addCase(getPolicyOnResources_slice.pending, (state) => {
        state.policyOnResources.status = requestState.loading;
      })
      .addCase(getPolicyOnResources_slice.fulfilled, (state, action: PayloadAction<any>) => {
        state.policyOnResources.status = requestState.success;
        state.policyOnResources.data = action.payload;
      })
      .addCase(getPolicyOnResources_slice.rejected, (state, action: any) => {
        state.policyOnResources.status = requestState.failed;
        state.policyOnResources.error = action.error.message;
      })
      // get all resources
      .addCase(getAllResources_slice.pending, (state) => {
        state.allResources.status = requestState.loading;
      })
      .addCase(getAllResources_slice.fulfilled, (state, action: PayloadAction<any>) => {
        state.allResources.status = requestState.success;
        state.allResources.data = [...action.payload];
      })
      .addCase(getAllResources_slice.rejected, (state, action: any) => {
        state.allResources.status = requestState.failed;
        state.allResources.error = action.error.message;
      })
      // general field values
      .addCase(getGeneralFieldValue_slice.pending, (state) => {
        state.generalFieldsValues.status = requestState.loading;
      })
      .addCase(getGeneralFieldValue_slice.fulfilled, (state, action: PayloadAction<any>) => {
        state.generalFieldsValues.status = requestState.success;
        state.generalFieldsValues.data = action.payload;
      })
      .addCase(getGeneralFieldValue_slice.rejected, (state, action: any) => {
        state.generalFieldsValues.status = requestState.failed;
        state.generalFieldsValues.error = action.error.message;
      })
      // all pages values
      .addCase(getAllPages_slice.pending, (state) => {
        state.allPages.status = requestState.loading;
      })
      .addCase(getAllPages_slice.fulfilled, (state, action: PayloadAction<any>) => {
        state.allPages.status = requestState.success;
        state.allPages.data = action.payload;
      })
      .addCase(getAllPages_slice.rejected, (state, action: any) => {
        state.allPages.status = requestState.failed;
        state.allPages.error = action.error.message;
      })
      // all roles values
      .addCase(getAllRoles_slice.pending, (state) => {
        state.allRoles.status = requestState.loading;
      })
      .addCase(getAllRoles_slice.fulfilled, (state, action) => {
        state.allRoles.status = requestState.success;
        state.allRoles.data = [...action.payload];
      })
      .addCase(getAllRoles_slice.rejected, (state, action: any) => {
        state.allRoles.status = requestState.failed;
        state.allRoles.error = action.error.message;
      })
      // get role details
      .addCase(getRole_slice.pending, (state) => {
        state.roleDetails.status = requestState.loading;
      })
      .addCase(getRole_slice.fulfilled, (state, action) => {
        state.roleDetails.status = requestState.success;
        state.roleDetails.data = action.payload;
      })
      .addCase(getRole_slice.rejected, (state, action: any) => {
        state.roleDetails.status = requestState.failed;
        state.roleDetails.error = action.error.message;
      })
      // all conditions values
      .addCase(getAllConditions_slice.pending, (state) => {
        state.allConditions.status = requestState.loading;
      })
      .addCase(getAllConditions_slice.fulfilled, (state, action: PayloadAction<any>) => {
        state.allConditions.status = requestState.success;
        state.allConditions.data = action.payload;
      })
      .addCase(getAllConditions_slice.rejected, (state, action: any) => {
        state.allConditions.status = requestState.failed;
        state.allConditions.error = action.error.message;
      })
      // conditions values
      .addCase(getConditions_slice.pending, (state) => {
        state.conditions.status = requestState.loading;
      })
      .addCase(getConditions_slice.fulfilled, (state, action: PayloadAction<any>) => {
        state.conditions.status = requestState.success;
        state.conditions.data = action.payload;
      })
      .addCase(getConditions_slice.rejected, (state, action: any) => {
        state.conditions.status = requestState.failed;
        state.conditions.error = action.error.message;
      })
      // rbac affecting userws
      .addCase(getRbacToggleAffectingUsers_slice.pending, (state) => {
        state.rbacAffectingUsers.status = requestState.loading;
      })
      .addCase(getRbacToggleAffectingUsers_slice.fulfilled, (state, action) => {
        state.rbacAffectingUsers.status = requestState.success;
        state.rbacAffectingUsers.data = action.payload;
      })
      .addCase(getRbacToggleAffectingUsers_slice.rejected, (state, action: any) => {
        state.rbacAffectingUsers.status = requestState.failed;
        state.rbacAffectingUsers.error = action.error.message;
      })

      // Bootstrap rbac with default roles, assigns users default roles
      .addCase(bootstrapRBACWithDefaultRole_slice.pending, (state) => {
        state.bootStrapRBACWithDefaultRole.status = requestState.loading;
      })
      .addCase(bootstrapRBACWithDefaultRole_slice.fulfilled, (state, action) => {
        state.bootStrapRBACWithDefaultRole.status = requestState.success;
        state.bootStrapRBACWithDefaultRole.data = action.payload;
      })
      .addCase(bootstrapRBACWithDefaultRole_slice.rejected, (state, action: any) => {
        state.bootStrapRBACWithDefaultRole.status = requestState.failed;
        state.bootStrapRBACWithDefaultRole.error = action.error.message;
      })
      // updatingUser
      .addCase(addUser_slice.pending, (state) => {
        state.updatingUser.status = requestState.loading;
      })
      .addCase(addUser_slice.fulfilled, (state) => {
        state.updatingUser.status = requestState.success;
        state.updatingUser.message = 'New User Added!';
      })
      .addCase(addUser_slice.rejected, (state) => {
        state.updatingUser.status = requestState.failed;
        state.updatingUser.message = 'Adding User Failed!';
      });
  },
});

// export const {
//     getProfileData,
// } = rbacSlice.actions;

const RBACReducer = rbacSlice.reducer;
export const { resetForUpdatingUser } = rbacSlice.actions;
export default RBACReducer;
