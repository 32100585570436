import { useEffect, useState } from 'react';
import { getIndustries, getSegments, getStages } from 'store/modules/features/common/common';
import { useAppDispatch } from 'store/hooks';
import { useAppSelector } from 'store/hooks';
import { selectIndustries, selectSegments, selectStages } from 'store/modules/features/common/selector';

const useStages = () => {
  const dispatch = useAppDispatch();
  const allStages = useAppSelector(selectStages);
  const [stages, setStages] = useState<any[]>([]);

  useEffect(() => {
    if (allStages.data.length == 0) {
      dispatch(getStages());
    }
    if (allStages.data.length > 0) {
      setStages([...allStages.data]);
    }
  }, [allStages]);

  const getStageNameById = (id: string) => {
    if (allStages.data.length) {
      for (let i = 0; i < allStages.data.length; i++) {
        if (allStages.data[i].id == id) {
          return allStages.data[i].name;
        }
      }
    }
  };

  const getStageIdByName = (name: string) => {
    if (allStages.data.length) {
      for (let i = 0; i < allStages.data.length; i++) {
        if (allStages.data[i].name == name) {
          return allStages.data[i].id;
        }
      }
    }
  };

  return {
    stages,
    getStageNameById,
    getStageIdByName,
  };
};

export default useStages;
