import {
  label_client,
  label_delivery_date,
  label_due_date,
  label_project,
  label_task,
  label_use_case,
} from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { selectAllFilters } from 'store/modules/AllFilters/selector';
import { addFilterGroup } from 'store/modules/AllFilters/slice';
import {
  selectAllGeneralStatus,
  selectAllTaskPriorities,
  selectAllTaskStages,
  selectUsersOfCompany,
  selectUsersOfCompanyStatus,
} from 'store/modules/Common/selector';
import { getAllProjectMembersThatAreClientContact, getAllUsers, getCustomerDetail } from 'store/modules/Common/slice';
import {
  selectAllSubtasks,
  selectClientClosedTasks,
  selectClientOpenTasks,
  selectTeamClosedTasks,
  selectTeamOpenTasks,
} from 'store/modules/Reports/AllTasks/selector';
import {
  fetchClientClosedTasks,
  fetchClientOpenTasks,
  fetchTeamClosedTasks,
  fetchTeamOpenTasks,
} from 'store/modules/Reports/AllTasks/slice';
import { customerFieldTaskRecords } from 'store/modules/Settings/selector';
import { getCustomFieldTaskRecords_slice } from 'store/modules/Settings/slice';
import { requestState } from 'store/modules/types';
import {
  generateDataWhileMappingCustomFieldsAsNormalFields,
  generateMappedCustomFieldKey,
  isNull,
  isUndefined,
} from 'utils/dataHelpers';
import {
  availableDateRangeOptions,
  FilterableData,
  generateOptionsForThisDataKey,
  Operator,
} from 'utils/filteringFunctions';
import { getCustomerUid } from 'utils/getUid';

const useTaskDashboard = () => {
  const dispatch = useDispatch();

  //   actual data
  const teamOpenTasks = useAppSelector(selectTeamOpenTasks);
  const teamClosedTasks = useAppSelector(selectTeamClosedTasks);
  const clientOpenTasks = useAppSelector(selectClientOpenTasks);
  const clientClosedTasks = useAppSelector(selectClientClosedTasks);

  // custom field mapped tasks
  const [mappedAllTasks, setMappedAllTasks] = useState<any[]>([]);
  const [filteredTasks, setFilteredTasks] = useState<any[]>([]);

  // filter
  const taskCustomfields = useAppSelector(customerFieldTaskRecords);
  const allUsers = useAppSelector(selectUsersOfCompany);
  const allUsersStatus = useAppSelector(selectUsersOfCompanyStatus);
  const allGeneralStatus = useAppSelector(selectAllGeneralStatus);
  const allTaskStages = useAppSelector(selectAllTaskStages);
  const allTaskPriorities = useAppSelector(selectAllTaskPriorities);
  const allFilters = useAppSelector(selectAllFilters);

  // others
  const allSubtasks = useAppSelector(selectAllSubtasks);

  const [selectedInnerTab, setInnerTab] = useState<'all' | 'open' | 'closed'>('all');
  const [selectedMainTab, setSelectedMainTab] = useState(0);

  const filterOpenClosedData = (data: FilterableData) => {
    if (selectedInnerTab === 'all') {
      data.toArray();
    } else if (selectedInnerTab === 'open') {
      data.customFilter('task_stage', 'Done', Operator.NotEquals);
    } else if (selectedInnerTab === 'closed') {
      data.customFilter('task_stage', 'Done');
      console.log(data.toArray());
    }
  };

  const filterTeamClientData = (data: FilterableData) => {
    if (selectedMainTab === 1) {
      data.customFilter('is_team_task', '1');
    } else if (selectedMainTab === 2) {
      data.customFilter('is_team_task', '0');
    } else {
      data.toArray();
    }
  };

  const addSubtasks = (data: any[]) => {
    const dataTreeRows = [];
    for (let i = 0; i < data.length; i++) {
      const subTasks = allSubtasks.filter((subtask) => subtask.task_uid === data[i].task_uid);
      dataTreeRows.push({ ...data[i], path: [data[i].task_uid] });
      for (let j = 0; j < subTasks.length; j++) {
        const subTaskData: any = {
          ...subTasks[j],
          task_name: subTasks[j].subtask_detail,
          task_owner: subTasks[j].subtask_owner,
          task_status: subTasks[j].subtask_status,
          task_start_date: subTasks[j].subtask_start_date,
          task_due_date: subTasks[j].subtask_due_date,
          task_closed_date: subTasks[j].subtask_closed_date,
          task_stage: subTasks[j].subtask_stage,
          task_priority: subTasks[j].subtask_priority,
          client_name: data[i].client_name,
          task_planned_start_date: subTasks[j].subtask_planned_start_date,
          path: [data[i].task_uid, subTasks[j].subtask_uid],
        };
        dataTreeRows.push(subTaskData);
      }
    }
    return dataTreeRows;
  };

  // setting all tasks
  useEffect(() => {
    if (
      teamOpenTasks.status === 'success' &&
      teamClosedTasks.status === 'success' &&
      clientOpenTasks.status === 'success' &&
      clientClosedTasks.status === 'success'
    ) {
      const cfMappedTeamOpenTasks = addSubtasks(
        generateDataWhileMappingCustomFieldsAsNormalFields(
          [...teamOpenTasks.data],
          'custom_values',
          'field_name',
          'field_value',
          'field_uid',
          'field_type'
        )
      );

      const cfMappedTeamClosedTasks = addSubtasks(
        generateDataWhileMappingCustomFieldsAsNormalFields(
          [...teamClosedTasks.data],
          'custom_values',
          'field_name',
          'field_value',
          'field_uid',
          'field_type'
        )
      );

      const cfMappedClientOpenTasks = addSubtasks(
        generateDataWhileMappingCustomFieldsAsNormalFields(
          [...clientOpenTasks.data],
          'custom_values',
          'field_name',
          'field_value',
          'field_uid',
          'field_type'
        )
      );

      const cfMappedClientClosedTasks = addSubtasks(
        generateDataWhileMappingCustomFieldsAsNormalFields(
          [...clientClosedTasks.data],
          'custom_values',
          'field_name',
          'field_value',
          'field_uid',
          'field_type'
        )
      );

      setMappedAllTasks([
        ...cfMappedTeamOpenTasks,
        ...cfMappedTeamClosedTasks,
        ...cfMappedClientOpenTasks,
        ...cfMappedClientClosedTasks,
      ]);
    }
  }, [teamOpenTasks, teamClosedTasks, clientOpenTasks, clientClosedTasks]);

  // task filters setup
  useEffect(() => {
    if (
      taskCustomfields.status === requestState.success &&
      mappedAllTasks.length > 0 &&
      allUsersStatus === requestState.success
    ) {
      // default filters
      const defaultTaskFilters = [
        {
          name: `${label_client} Name`,
          dataKey: 'client_name',
          isSelected: false,
          equalToValue: [],
          canBeCleared: true,
          availableOptions: generateOptionsForThisDataKey(mappedAllTasks, 'client_name'),
        },
        {
          name: `${label_project} Name`,
          dataKey: 'project_name',
          isSelected: false,
          equalToValue: [],
          canBeCleared: true,
          availableOptions: generateOptionsForThisDataKey(mappedAllTasks, 'project_name'),
        },
        {
          name: `${label_use_case} Name`,
          dataKey: 'case_name',
          isSelected: false,
          equalToValue: [],
          canBeCleared: true,
          availableOptions: generateOptionsForThisDataKey(mappedAllTasks, 'case_name'),
        },
        {
          name: 'Phase Name',
          dataKey: 'section_detail',
          isSelected: false,
          equalToValue: [],
          canBeCleared: true,
          availableOptions: generateOptionsForThisDataKey(mappedAllTasks, 'section_detail'),
        },
        {
          name: `${label_task} Owner`,
          dataKey: 'task_owner',
          isSelected: false,
          equalToValue: [],
          canBeCleared: true,
          availableOptions: allUsers.map((item: any) => item.user_username),
        },
        {
          name: 'Status',
          dataKey: 'task_status',
          isSelected: false,
          equalToValue: [],
          canBeCleared: true,
          availableOptions: allGeneralStatus.data,
        },
        {
          name: 'Stage',
          dataKey: 'task_stage',
          isSelected: false,
          equalToValue: [],
          canBeCleared: true,
          availableOptions: allTaskStages.data,
        },
        {
          name: 'Priority',
          dataKey: 'task_priority',
          isSelected: false,
          equalToValue: [],
          canBeCleared: true,
          availableOptions: allTaskPriorities.data,
        },
        {
          name: 'Planned Start Date$$date-range-picker',
          dataKey: 'task_planned_start_date',
          isSelected: false,
          equalToValue: [],
          canBeCleared: true,
          availableOptions: availableDateRangeOptions,
        },
        {
          name: 'Start Date$$date-range-picker',
          dataKey: 'task_start_date',
          isSelected: false,
          equalToValue: [],
          canBeCleared: true,
          availableOptions: availableDateRangeOptions,
        },
        {
          name: label_due_date + '$$date-range-picker',
          dataKey: 'task_due_date',
          isSelected: false,
          equalToValue: [],
          canBeCleared: true,
          availableOptions: availableDateRangeOptions,
        },
        {
          name: label_delivery_date + '$$date-range-picker',
          dataKey: 'task_closed_date',
          isSelected: false,
          equalToValue: [],
          canBeCleared: true,
          availableOptions: availableDateRangeOptions,
        },
        {
          name: 'Original Effort Estimate$$number-range',
          dataKey: 'effort_estimate',
          isSelected: false,
          equalToValue: [],
          canBeCleared: true,
          availableOptions: [],
        },
        {
          name: 'Total Time Logged$$number-range',
          dataKey: 'total_time_spent',
          isSelected: false,
          equalToValue: [],
          canBeCleared: true,
          availableOptions: [],
        },
      ];
      const cfTasks: any[] = [];

      taskCustomfields.data?.data?.result?.forEach((cf: any) => {
        if (['RAG status', 'Dropdown'].includes(cf.field_type)) {
          cfTasks.push({
            name: cf.field_name,
            dataKey: generateMappedCustomFieldKey(cf.field_name, cf.field_uid, cf.field_type),
            isSelected: false,
            equalToValue: [],
            canBeCleared: true,
            availableOptions: cf.valid_values.split(','),
          });
        } else if (cf.field_type === 'Date') {
          cfTasks.push({
            name: `${cf.field_name}$$date-range-picker`,
            dataKey: generateMappedCustomFieldKey(cf.field_name, cf.field_uid, cf.field_type),
            isSelected: false,
            equalToValue: [],
            canBeCleared: true,
            availableOptions: availableDateRangeOptions,
          });
        }
      });

      console.log('cfs', taskCustomfields);

      if (isUndefined(allFilters['taskDashboardHome']) || isNull(allFilters['taskDashboardHome'])) {
        dispatch(
          addFilterGroup({
            idName: 'taskDashboardHome',
            appliedFilters: [...defaultTaskFilters, ...cfTasks],
          })
        );
      }
    }
  }, [taskCustomfields, mappedAllTasks, allUsersStatus]);

  // actual filtering of tasks
  useEffect(() => {
    if (!isUndefined(allFilters['taskDashboardHome']) && mappedAllTasks.length > 0) {
      const newFilteredData = new FilterableData(mappedAllTasks);
      newFilteredData.bulkFilter(allFilters['taskDashboardHome'].appliedFilters);
      filterOpenClosedData(newFilteredData);
      filterTeamClientData(newFilteredData);
      setFilteredTasks(newFilteredData.toArray());
    } else {
      setFilteredTasks(mappedAllTasks);
    }
  }, [allFilters, mappedAllTasks, selectedInnerTab, selectedMainTab]);

  const getDataForTasks = async () => {
    dispatch(fetchTeamOpenTasks());
    dispatch(fetchTeamClosedTasks());
    dispatch(fetchClientOpenTasks());
    dispatch(fetchClientClosedTasks());
    dispatch(getCustomFieldTaskRecords_slice(getCustomerUid()));
    dispatch(getAllUsers());
    dispatch(getAllProjectMembersThatAreClientContact());
    dispatch(getCustomerDetail());
  };

  const getUsersData = () => {
    if (allUsers.length == 0) {
      dispatch(getAllUsers());
    }
  };

  useEffect(() => {
    getUsersData();
    getDataForTasks();
  }, []);

  return {
    mappedAllTasks,
    filteredTasks,
    setInnerTab,
    selectedInnerTab,
    selectedMainTab,
    setSelectedMainTab,
  };
};

export default useTaskDashboard;
