import { label_use_case, label_project } from 'components/Settings/Preferences/CustomLabelCookieHelper';

export const templateHomeTabs = [`${label_project} Templates`, `${label_use_case} Templates`, 'Document Templates'];

export type TemplateHomeTabsType = typeof templateHomeTabs;

export const toUpdateProjectTemplate = '/templates/updateprojecttemplate';

export const toUpdateUsecaseTemplate = '/templates/updateusecasetemplate';

export const projectTemplateTabs = ['Overview', 'Plan'];

export const usecaseTemplateTabs = ['Overview', 'Plan', 'Product Mapping'];

export const templateTaskTabs = ['Overview', 'Dependency'];

export const CLIENT_TASK = 'Client';

export const TEAM_TASK = 'TEAM';
