export const filterRecommendationDataInRealTime = (
  userToFilter: any[],
  salesOwnerToFilter: any[],
  segmentToFilter: any[],
  accountRecommendationStatusDetail: any[]
) => {
  segmentToFilter = ['Enterprise', 'Mid-Market', 'SMB', 'Maintenance', 'Proof of Concept'];

  // !Below should be uncommented after filter has been implemented in revenue-impact section

  // this.userToFilter = this.selectedUsers;
  // this.salesOwnerToFilter = this.selectedSalesOwner;
  // this.segmentToFilter = this.selectedSegment;

  // if (!this.selectedUsers.length) //if no user is selected
  // {
  //   this.userToFilter = [];
  //   this.userList.forEach((item: { user_username: any; }) => {
  //     this.userToFilter.push(item.user_username);
  //   });
  // }

  // if (!this.selectedSalesOwner.length) //if no sales owner is selected
  // {
  //   this.salesOwnerToFilter = [];
  //   this.userList.forEach((item: { user_username: any; }) => {
  //     this.salesOwnerToFilter.push(item.user_username);
  //   });
  // }

  // if (!this.selectedSegment.length) //if no segment is selected
  // {
  //   this.segmentToFilter = [];
  //   this.segmentList.forEach((item: any) => {
  //     this.segmentToFilter.push(item.segment_name);
  //   });
  // }

  let finalarray: any = [];
  let filteredArray1 = accountRecommendationStatusDetail;
  //user filter
  userToFilter.forEach((item: any) => {
    const rand_array = filteredArray1.filter(function (item1: { client_account_owner: any }) {
      return item1.client_account_owner === item;
    });
    finalarray = finalarray.concat(rand_array);
  });
  filteredArray1 = finalarray;
  finalarray = [];
  //sales owner filter
  salesOwnerToFilter.forEach((item: any) => {
    const rand_array = filteredArray1.filter(function (item1: { client_sales_owner: any }) {
      return item1.client_sales_owner === item;
    });
    finalarray = finalarray.concat(rand_array);
  });
  filteredArray1 = finalarray;
  finalarray = [];
  //segment filter
  segmentToFilter.forEach((item: any) => {
    const rand_array = filteredArray1.filter(function (item1: any) {
      return item1.segment_name === item;
    });
    finalarray = finalarray.concat(rand_array);
  });
  filteredArray1 = finalarray;
  const clonedArray = filteredArray1.map((_arrayElement: any) => Object.assign({}, _arrayElement));

  clonedArray.forEach(function (a) {
    ['revenue'].forEach(function (k) {
      a[k] = Math.floor(+a[k]);
    });
  });

  const resultarr = clonedArray
    .reduce(
      function (res: any, obj: any) {
        if (!(obj.id in res)) res.__array.push((res[obj.id] = obj));
        else {
          res[obj.id].revenue += obj.revenue;
          const a = new Date(obj.due_date);
          const b = new Date(res[obj.id].due_date);
          if (a < b) res[obj.id].due_date = obj.due_date;
        }
        return res;
      },
      { __array: [] }
    )
    .__array.sort(function (a: any, b: any) {
      if (b.revenue == a.revenue) {
        const c = new Date(a.due_date).getTime();
        const d = new Date(b.due_date).getTime();
        return c - d;
      } else return b.revenue - a.revenue;
    });

  const listRecommendations: any[] = resultarr;
  return listRecommendations;
};
