import styled from 'styled-components';

export const CustomButton = styled.button`
  background: #2d60f6;
  border-radius: 4px;
  width: 100%;
  height: 44px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: #fff;
  margin-top: 28px;
  cursor: pointer;
  transition: 200ms filter linear;
  font-family: Noto Sans;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const CustomLink = styled.a`
  font-size: 12px;
  font-weight: 400;
  color: #2d60f6;
  cursor: pointer;
`;
