import moment from 'moment';

export const convertMinutes = (num: number) => {
  if (num === 0) {
    return '0m';
  } else {
    let hour = Math.floor(num / 60); // 60 minutes in hour
    let day = Math.floor(hour / 8); // 8 hours a day office time
    const week = Math.floor(day / 5); // 5 days a week office time
    const minute = num - 60 * hour; // remaining minutes

    hour = hour - day * 8; // remaining hours
    day = day - week * 5; // remaining days

    let timeLogValue = ''; // Var to store Time Log Value

    if (week > 0) {
      timeLogValue = timeLogValue.concat(`${week}w `);
    }
    if (day > 0) {
      timeLogValue = timeLogValue.concat(`${day}d `);
    }
    if (hour > 0) {
      timeLogValue = timeLogValue = timeLogValue.concat(`${hour}h `);
    }
    if (minute > 0) {
      timeLogValue = timeLogValue.concat(`${minute}m `);
    }

    timeLogValue = timeLogValue.slice(0, -1); // To remove the last blank space

    return timeLogValue;
  }
};

export function getTaskDeltaXWithDueDate(task: any) {
  if (!task?.task_due_date && !task?.task_closed_date) {
    return {
      openTaskDeltaWithDueDateFullText: '-',
      closedTaskDeltaWithDueDateFullText: '-',
    };
  }

  const replacedTaskDueDate = task.task_due_date.replace(/-/g, '/');
  const replacedTaskClosedDate = task.task_closed_date.replace(/-/g, '/');

  const today = moment(new Date());
  const dueDate = moment(new Date(replacedTaskDueDate));
  const closedDate = moment(new Date(replacedTaskClosedDate));

  // today +- due_date
  const diffDaysOfOpenTaskOfDeltaDueDate = dueDate.diff(today, 'days');
  // task_closed_date  +- due_date
  const diffDaysOfClosedTaskOfDeltaDueDate = dueDate.diff(closedDate, 'days');

  const openTaskDeltaWithDueDateFullText =
    diffDaysOfOpenTaskOfDeltaDueDate < 0
      ? `${~diffDaysOfOpenTaskOfDeltaDueDate} days late`
      : `${diffDaysOfOpenTaskOfDeltaDueDate} days remaining`;

  const closedTaskDeltaWithDueDateFullText =
    diffDaysOfClosedTaskOfDeltaDueDate < 0
      ? `${~diffDaysOfClosedTaskOfDeltaDueDate} days late`
      : `${diffDaysOfClosedTaskOfDeltaDueDate} days remaining`;

  return {
    openTaskDeltaWithDueDateFullText,
    closedTaskDeltaWithDueDateFullText,
  };
}

export function getTaskTimeTrackingFields(task: any) {
  if (!task?.total_time_spent && !task?.effort_estimate) {
    return {
      totalTimeLogged: '-',
      effortEstimate: '-',
    };
  }
  const totalTimeLogged = convertMinutes(parseInt(task.total_time_spent, 10));
  const effortEstimate = convertMinutes(parseInt(task.effort_estimate, 10));
  return {
    totalTimeLogged,
    effortEstimate,
  };
}
