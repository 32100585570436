import { useCreatePlanExcelForUpdate } from 'components/shared/Hooks/useCreatePlanExcelForUpdate';
import {
  DataGridPremium,
  GridCellModes,
  GridCellModesModel,
  GridCellParams,
  GridColDef,
  GridRenderEditCellParams,
} from '@mui/x-data-grid-premium';
import { ExcelTextEditor } from '../Project/AddProjectTemplate/ProjectTemplatePlanExcel/editors/ExcelTextEditor';
import { ExcelDropdown } from '../Project/AddProjectTemplate/ProjectTemplatePlanExcel/editors/ExcelDropdown';
import ExcelAutoComplete from '../Project/AddProjectTemplate/ProjectTemplatePlanExcel/editors/ExcelAutoComplete';
import { ExcelUserSelect } from '../Project/AddProjectTemplate/ProjectTemplatePlanExcel/editors/ExcelUserSelect';
import { useCallback, useState, useEffect } from 'react';
import { useAppSelector } from 'store/hooks';
import { selectUsersOfCompany } from 'store/modules/Common/selector';
import { Box, Stack } from '@mui/material';
import { useAppDispatch } from 'store/hooks';
import useUserDropDownData from 'components/shared/Hooks/useUserDropDownData';
import { selectUpdateProjectTemplateExcelData } from 'store/modules/Templates/selector';
import { updateProjectTemplateExcelDataUpdateView } from 'store/modules/Templates/Templates';

const UpdateTemplateExcelView = (props: any) => {
  console.log(props);
  const rows = useCreatePlanExcelForUpdate(props.data, props.dependency);
  const [usersOfCompany, setUsersOfCompany] = useState<any[]>([]);
  const users: any[] = useAppSelector(selectUsersOfCompany);
  const excelData = useAppSelector(selectUpdateProjectTemplateExcelData);
  const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({});
  const companyUsersDropDownData = useUserDropDownData('company');
  const [phaseNames, setPhaseNames] = useState<any[]>([]);
  const dispatch = useAppDispatch();
  console.log(companyUsersDropDownData);

  // const apiRef = useGridApiRef();

  useEffect(() => {
    console.log(rows, usersOfCompany);
    updateStore();
    setUsersData();
    makePhaseNamesArray();
  }, [rows]);

  const updateStore = () => {
    console.log('CALL DISP');
    dispatch(updateProjectTemplateExcelDataUpdateView(rows));
  };

  const makePhaseNamesArray = () => {
    // const phases = rows.map((r: any) => {
    //   console.log(r);
    //   if (r?.phase_name.length > 0) {
    //     return r.phase_name;
    //   }
    //   return;
    // });
    const phases: any = [];
    for (let i = 0; i < rows.length; i++) {
      if (rows[i].phase_name.length > 0) {
        phases.push(rows[i].phase_name);
      }
    }
    console.log(phases);
    // const myArray = [phases];

    const unique = Array.from(new Set(phases));

    console.log(unique);
    setPhaseNames([...unique]);
  };

  const setUsersData = () => {
    const data = users.filter((user: any) => user.is_active === '1').map((user: any) => user.user_username);
    data.unshift('Not Assigned');
    setUsersOfCompany([...data]);
  };

  const handleInlineEditValue = (value: any, id: string, field: string) => {
    console.log('djkejfke', setUsersOfCompany, value, field, id);
    if (+id != 0) {
      console.log(value, id, field);
      // console.log(rows);
      const t = JSON.parse(JSON.stringify(excelData));
      const index: number = +id;
      console.log(t[index - 1]);
      t[index - 1][field] = value;
      // Object.assign(obj1, obj2)
      console.log(t);
      // setRows([...t]);
      // const storeRows = [...t];
      dispatch(updateProjectTemplateExcelDataUpdateView([...t]));
    }
  };
  // const rowT = {
  //   id: '',
  //   phase_name: '',
  //   task_name: '',
  //   task_type: '',
  //   duration: '',
  //   predecessors: '',
  //   task_owner: '',
  //   share_with_client: '',
  //   milestone: '',
  //   implementation_fee: '',
  // };

  console.log(rows);
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      width: 80,
      // cellClassName: (params: GridCellParams<any>) => {
      //   console.log(params.row.color);
      //   if (params.value == null) {
      //     return '';
      //   }
      //   return clsx('cogni-cell', {
      //     F9CFCF: params.row.color.substring(1) == 'F9CFCF' ? true : false,
      //     F9E8CF: params.row.color.substring(1) == 'F9E8CF' ? true : false,
      //     E4F9CF: params.row.color.substring(1) == 'E4F9CF' ? true : false,
      //     CFF6F9: params.row.color.substring(1) == 'CFF6F9' ? true : false,
      //     CFE5F9: params.row.color.substring(1) == 'CFE5F9' ? true : false,
      //     D0CFF9: params.row.color.substring(1) == 'D0CFF9' ? true : false,
      //     F6CFF9: params.row.color.substring(1) == 'F6CFF9' ? true : false,

      //     positive: false,
      //   });
      // },
    },
    {
      field: 'task_name',
      headerName: 'Task Name',
      width: 250,
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams) => ExcelTextEditor(params, handleInlineEditValue),
    },

    {
      field: 'phase_name',
      headerName: 'Phase Name',
      width: 250,
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams) =>
        ExcelAutoComplete(params, phaseNames, handleInlineEditValue),
    },

    {
      field: 'task_type',
      headerName: 'Task Type',
      width: 250,
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams) =>
        ExcelDropdown(params, ['Internal', 'External'] || [], handleInlineEditValue),
    },
    {
      field: 'duration',
      headerName: 'Target Duration',
      width: 150,
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams) => ExcelTextEditor(params, handleInlineEditValue, 'duration'),
    },
    {
      field: 'predecessors',
      headerName: 'Dependency',
      width: 150,
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams) => ExcelTextEditor(params, handleInlineEditValue),
    },
    {
      field: 'implementation_fee',
      headerName: 'Implementation Fee',
      width: 150,
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams) => ExcelTextEditor(params, handleInlineEditValue),
    },
    {
      field: 'task_owner',
      headerName: 'Task Owner',
      width: 250,
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams) =>
        ExcelUserSelect(params, companyUsersDropDownData || [], handleInlineEditValue),
    },
    {
      field: 'task_priority',
      headerName: 'Priority',
      width: 150,
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams) =>
        ExcelDropdown(params, ['Critical', 'High', 'Medium', 'Low'] || [], handleInlineEditValue),
    },
    {
      field: 'share_with_client',
      headerName: 'Share with client',
      width: 150,
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams) =>
        ExcelDropdown(params, ['Yes', 'No'] || [], handleInlineEditValue),
    },
    {
      field: 'milestone',
      headerName: 'Milestone Task',
      width: 150,
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams) =>
        ExcelDropdown(params, ['Yes', 'No'] || [], handleInlineEditValue),
    },
  ];

  const handleCellClick = useCallback((params: GridCellParams) => {
    setCellModesModel((prevModel) => {
      return {
        // Revert the mode of the other cells from other rows
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: Object.keys(prevModel[id]).reduce(
              (acc2, field) => ({
                ...acc2,
                [field]: { mode: GridCellModes.View },
              }),
              {}
            ),
          }),
          {}
        ),
        [params.id]: {
          // Revert the mode of other cells in the same row
          ...Object.keys(prevModel[params.id] || {}).reduce(
            (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
            {}
          ),
          [params.field]: { mode: GridCellModes.Edit },
        },
      };
    });
    // addPhaseNameToCell(params);
  }, []);

  const handleCellModesModelChange = useCallback((newModel) => {
    console.log(newModel);
    // const det = {
    //   id: Object.keys(newModel)[Object.keys(newModel).length - 1],
    // };
    // addPhaseNameToCell(det);

    setCellModesModel(newModel);
  }, []);

  // const apiRef = useGridApiRef();
  // const initialState = useKeepGroupedColumnsHidden({
  //   apiRef,
  //   initialState: {
  //     columns: {
  //       columnVisibilityModel: {
  //         // Hide the column used for leaves
  //         task_name: true,
  //       },
  //     },
  //     rowGrouping: {
  //       model: ['phase_name'],
  //     },
  //     pinnedColumns: {
  //       left: ['id'],
  //     },
  //   },
  // });

  return (
    <>
      <Stack direction={'row'} justifyContent={'space-between'} mb={1}>
        <>Excel Update view </>
        {/* {console.log(rows)} */}
        {/* <CognisaasButton label="Add task" isOutlined={true} /> */}
        {/* <CreateTaskDraggable /> */}
      </Stack>

      {rows.length > 0 && (
        <Box
          sx={{
            height: 800,
            width: '100%',
            '& .cogni-cell.F9CFCF': {
              backgroundColor: '#F9CFCF',
            },
            '& .cogni-cell.F9E8CF': {
              backgroundColor: '#F9E8CF',
            },
            '& .cogni-cell.E4F9CF': {
              backgroundColor: '#E4F9CF',
            },
            '& .cogni-cell.CFF6F9': {
              backgroundColor: '#CFF6F9',
            },
            '& .cogni-cell.CFE5F9': {
              backgroundColor: '#CFE5F9',
            },
            '& .cogni-cell.D0CFF9': {
              backgroundColor: '#D0CFF9',
            },
            '& .cogni-cell.F6CFF9': {
              backgroundColor: '#F6CFF9',
            },
          }}
        >
          <DataGridPremium
            // editMode="row"
            // autoHeight
            // apiRef={apiRef}
            initialState={{ pinnedColumns: { left: ['id'] } }}
            cellModesModel={cellModesModel}
            // groupingColDef={{ leafField: 'task_name' }}
            onCellModesModelChange={handleCellModesModelChange}
            onCellClick={handleCellClick}
            experimentalFeatures={{ newEditingApi: true }}
            rowHeight={43}
            rows={rows}
            columns={columns}
            // rowReordering
            showCellRightBorder={true}
          />
        </Box>
      )}
    </>
  );
};

export default UpdateTemplateExcelView;
