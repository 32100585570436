import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import TypographyWithTooltip from '../UI/TypographyWithTooltip';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { numberFormatter } from 'utils/dataHelpers';
import CognisaaasTooltip from '../CognisaasTooltip/CognisaasTooltip';
import { useState } from 'react';
import { ReactComponent as DeleteIcon } from 'assets/svgs/delete.svg';
import CopyAllOutlinedIcon from '@mui/icons-material/CopyAllOutlined';
import { ReactComponent as EditPen } from 'assets/svgs/edit_pen.svg';

interface Props {
  header: string;
  value: string;
  color: string;
  isUnitPrefixed: boolean;
  unitString: string;
  useAnnotations: boolean;
  handleEdit?: () => void;
  handleClone?: () => void;
  handleDelete?: () => void;
}

const AggregatedCard = (props: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const toggleMenuOpenOrClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        background: 'white',
        borderRadius: '4px',
        width: '150px',
        padding: '4px 0px 4px 4px',
        boxShadow: '0px 4px 26px rgb(0 0 0 / 5%)',
      }}
    >
      <Stack direction={'row'}>
        <Box
          sx={{
            width: '4px',
            background: props.color,
            borderRadius: '4px',
            margin: '4px',
          }}
        ></Box>

        <Stack sx={{ margin: '4px', width: '100%' }} direction={'row'} justifyContent="space-between">
          <Box
          //   sx={{
          //     margin: '4px',
          //   }}
          >
            <TypographyWithTooltip variant={'subtitle2'} className="label-text" maxLength={15}>
              {props.header}
            </TypographyWithTooltip>

            <Stack
              direction={props.isUnitPrefixed ? 'row-reverse' : 'row'}
              justifyContent={props.isUnitPrefixed ? 'flex-end' : 'flex-start'}
              alignItems={'center'}
              spacing={props.unitString.length == 0 ? 0 : 0.5}
            >
              <CognisaaasTooltip
                title={
                  <Box m={1}>
                    <Typography>{props.value}</Typography>
                  </Box>
                }
              >
                <Typography variant={'subtitle1'}>
                  {props.useAnnotations ? numberFormatter(parseInt(props.value)) : props.value}
                </Typography>
              </CognisaaasTooltip>

              <Typography variant={'subtitle2'}>{props.unitString}</Typography>
            </Stack>
          </Box>
          <IconButton disableRipple sx={{ padding: '0px' }} size="small" onClick={toggleMenuOpenOrClose}>
            <MoreVertOutlinedIcon />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleMenuClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
              // dense: true,
            }}
            style={{ padding: '0px' }}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem
              onClick={() => {
                handleMenuClose();
                if (props.handleEdit) {
                  props.handleEdit();
                }
                //   props.onClone();
              }}
            >
              <ListItemIcon>
                <EditPen />
              </ListItemIcon>
              <ListItemText>Edit card</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleMenuClose();
                if (props.handleClone) {
                  props.handleClone();
                }
              }}
            >
              <ListItemIcon>
                <CopyAllOutlinedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Clone card</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleMenuClose();
                if (props.handleDelete) {
                  props.handleDelete();
                }
              }}
            >
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              <ListItemText>Delete card</ListItemText>
            </MenuItem>
          </Menu>
        </Stack>
      </Stack>
    </Box>
  );
};

export default AggregatedCard;
