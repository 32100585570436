import { EditableDropdown } from 'components/shared/Tables/CogniTable';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { selectJiraProductCustomStage } from 'store/modules/Settings/selector';
import { fetchJiraCustomProductStages, updateJiraProductCustomStage } from 'store/modules/Settings/slice';
import MapJiraStatusModal from './MapJiraStatusModal';

interface Props {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
  projectKey: string;
}

const MapProductJiraStatusModal = (props: Props) => {
  const jiraProductStagesState = useAppSelector(selectJiraProductCustomStage);
  const dispatch = useDispatch();
  const [mydata, setMydata] = useState<any[]>([]);

  useEffect(() => {
    if (props.open) {
      dispatch(fetchJiraCustomProductStages(props.projectKey));
    }
  }, [props.open]);

  useEffect(() => {
    if (jiraProductStagesState.status === 'success' && jiraProductStagesState.data.length > 0) {
      setMydata(jiraProductStagesState.data);
    }
  }, [jiraProductStagesState]);

  const handleDropDownUpdate = (
    rowData: Record<string, string>,
    updated_data_key: string,
    updated_value: string
  ): void => {
    // console.log(rowData, updated_data_key, updated_value);
    setMydata((prevData) =>
      prevData.map((item: any) => {
        if (item.jira_product_stage_uid == rowData.jira_product_stage_uid) {
          return { ...item, [updated_data_key]: updated_value };
        } else {
          return item;
        }
      })
    );
  };

  const saveUpdatedProductStages = () => {
    // update here
    console.log(mydata);
    dispatch(updateJiraProductCustomStage(mydata));
    props.setIsOpen(false);
  };

  return (
    <>
      <MapJiraStatusModal
        header="Please update mapping between Jira status and CogniSaaS Requirement status."
        columns={[
          {
            field: 'jira_product_stage_name',
            headerName: 'Jira status',
            minWidth: 70,
            flex: 1,
          },
          {
            field: 'color_in_our_product',
            headerName: 'Cognisaas Requirement Status',
            minWidth: 230,
            flex: 1,
            renderCell: (params) =>
              EditableDropdown(
                params,
                [
                  { id: '1', value: 'red' },
                  { id: '2', value: 'amber' },
                  { id: '3', value: 'green' },
                  { id: '4    ', value: 'blue' },
                ],
                handleDropDownUpdate,
                'requirement'
              ),
          },
        ]}
        data={mydata}
        handleSave={saveUpdatedProductStages}
        open={props.open}
        setIsOpen={props.setIsOpen}
      />
    </>
  );
};

export default MapProductJiraStatusModal;
