import { useEffect, useState } from 'react';
import { getSegments } from 'store/modules/features/common/common';
import { useAppDispatch } from 'store/hooks';
import { useAppSelector } from 'store/hooks';
import { selectSegments } from 'store/modules/features/common/selector';

const useSegments = () => {
  const dispatch = useAppDispatch();
  const allSegments = useAppSelector(selectSegments);
  const [segments, setSegments] = useState<any[]>([]);

  useEffect(() => {
    if (allSegments.data.length == 0) {
      dispatch(getSegments());
    }
    if (allSegments.data.length > 0) {
      setSegments([...allSegments.data]);
    }
  }, [allSegments]);

  const getSegmentNameById = (id: string) => {
    if (allSegments.data.length) {
      for (let i = 0; i < allSegments.data.length; i++) {
        if (allSegments.data[i].id == id) {
          return allSegments.data[i].name;
        }
      }
    }
  };

  const getSegmentIdByName = (name: string) => {
    if (allSegments.data.length) {
      for (let i = 0; i < allSegments.data.length; i++) {
        if (allSegments.data[i].name == name) {
          return allSegments.data[i].id;
        }
      }
    }
  };

  return {
    segments,
    getSegmentNameById,
    getSegmentIdByName,
  };
};

export default useSegments;
