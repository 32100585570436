import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { graphSchema } from '../../../components/shared/Charts/CustomiseDrawer/types';
import { requestState } from '../types';
import { graphAPI } from '../../../api/modules/graph';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

// // this should be passed by the chart config drawer
// export interface GraphConfigType extends graphSchema {
// }

export interface graphConfigPayloadType extends graphSchema {
  type: 'project' | 'usecase' | 'task' | 'any';
  customer_uid?: string;
  user_uid?: string;
}

interface StateProps {
  allChartConfigs: graphSchema[];
  getAllGraphConfigsStatus: requestState;
  getAllGraphConfigsError: null | undefined | string;

  addGraphConfigurationStatus: requestState;
  addGraphConfigurationError: null | undefined | string;

  updateGraphConfigStatus: requestState;
  updateGraphConfigError: null | undefined | string;

  deleteGraphConfigStatus: requestState;
  deleteGraphConfigError: null | undefined | string;
}

const initialState: StateProps = {
  allChartConfigs: [],
  getAllGraphConfigsStatus: requestState.idle,
  getAllGraphConfigsError: null,

  addGraphConfigurationStatus: requestState.idle,
  addGraphConfigurationError: null,

  updateGraphConfigStatus: requestState.idle,
  updateGraphConfigError: null,

  deleteGraphConfigStatus: requestState.idle,
  deleteGraphConfigError: null,
};

export const addGraphConfigurations = createAsyncThunk(
  'charts/addGraphConfigurations',
  async (graphConfigPayload: graphConfigPayloadType) => {
    const customer_uid = cookies.get('cogni_customer_uid');
    const user_uid = cookies.get('cogni_user_uid');
    const { colors, xAxisKeys, yAxisKeys } = graphConfigPayload;
    const payload = {
      ...graphConfigPayload,
      colors: JSON.stringify(colors),
      xAxisKeys: JSON.stringify(xAxisKeys),
      yAxisKeys: JSON.stringify(yAxisKeys),
      customer_uid: customer_uid,
      user_uid: user_uid,
    };
    const { data } = await graphAPI.addGraphConfiguration(payload);
    if (data.result) {
      const { colors, xAxisKeys, yAxisKeys } = data.result;
      const newResult: any = {
        ...data.result,
        colors: JSON.parse(colors),
        xAxisKeys: JSON.parse(xAxisKeys),
        yAxisKeys: JSON.parse(yAxisKeys),
      };
      return newResult;
    }
    return data.result ?? [];
  }
);

export const updateGraphConfigurations = createAsyncThunk(
  'charts/updateGraphConfigurations',
  async (graphConfigPayload: graphConfigPayloadType) => {
    const customer_uid = cookies.get('cogni_customer_uid');
    const user_uid = cookies.get('cogni_user_uid');
    const { colors, xAxisKeys, yAxisKeys } = graphConfigPayload;
    const payload = {
      ...graphConfigPayload,
      colors: JSON.stringify(colors),
      xAxisKeys: JSON.stringify(xAxisKeys),
      yAxisKeys: JSON.stringify(yAxisKeys),
      customer_uid: customer_uid,
      user_uid: user_uid,
    };
    const { data } = await graphAPI.updateGraphConfiguration(payload);
    if (data.result) {
      const { colors, xAxisKeys, yAxisKeys } = data.result;
      const newResult: any = {
        ...data.result,
        colors: JSON.parse(colors),
        xAxisKeys: JSON.parse(xAxisKeys),
        yAxisKeys: JSON.parse(yAxisKeys),
      };
      return newResult;
    }
    return data.result ?? [];
  }
);

export const getGraphConfigurations = createAsyncThunk('charts/getGraphConfigurations', async (graphDetail: any) => {
  const { type, user_uid, customer_uid } = graphDetail;
  const { data } = await graphAPI.getGraphConfiguration(type, user_uid, customer_uid);
  if (data.result) {
    const newResult = data.result.map((result: any) => {
      let { colors, xAxisKeys, yAxisKeys } = result;
      console.log('PARSING');
      colors = JSON.parse(colors);
      xAxisKeys = JSON.parse(xAxisKeys);
      yAxisKeys = JSON.parse(yAxisKeys);
      console.log({ colors, xAxisKeys, yAxisKeys });
      return { ...result, colors, xAxisKeys, yAxisKeys };
    });
    return newResult;
  }
  return data.result ?? [];
});

export const deleteGraphConfiguration: any = createAsyncThunk(
  'charts/deleteGraphconfigurations',
  async (graphDetail: any) => {
    const { data } = await graphAPI.deleteGraphConfiguration(graphDetail);
    return data.result;
  }
);

const chartsSlice = createSlice({
  name: 'charts',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getGraphConfigurations.pending, (state) => {
        state.getAllGraphConfigsStatus = requestState.loading;
      })
      .addCase(getGraphConfigurations.fulfilled, (state, action) => {
        state.getAllGraphConfigsStatus = requestState.success;
        state.allChartConfigs = action.payload ?? [];
      })
      .addCase(getGraphConfigurations.rejected, (state, action) => {
        state.getAllGraphConfigsStatus = requestState.failed;
        state.getAllGraphConfigsError = action.error.message;
      })

      .addCase(addGraphConfigurations.pending, (state) => {
        state.addGraphConfigurationStatus = requestState.loading;
      })
      .addCase(addGraphConfigurations.fulfilled, (state, action) => {
        console.log({ action });
        state.addGraphConfigurationStatus = requestState.success;
        console.log(state.allChartConfigs);
        state.allChartConfigs.push(action.payload);
        console.log(state.allChartConfigs);
      })
      .addCase(addGraphConfigurations.rejected, (state, action) => {
        console.log('REJECTED');
        state.addGraphConfigurationStatus = requestState.failed;
        state.addGraphConfigurationError = action.error.message;
      })

      .addCase(deleteGraphConfiguration.pending, (state) => {
        state.deleteGraphConfigStatus = requestState.loading;
      })
      .addCase(deleteGraphConfiguration.fulfilled, (state, action) => {
        state.deleteGraphConfigStatus = requestState.success;
        state.allChartConfigs = state.allChartConfigs.filter((chart) => {
          if (chart.project_chart_uid !== undefined) {
            return chart.project_chart_uid !== action.payload.project_chart_uid;
          } else if (chart.case_chart_uid !== undefined) {
            return chart.case_chart_uid !== action.payload.case_chart_uid;
          } else if (chart.task_chart_uid !== undefined) {
            return chart.task_chart_uid !== action.payload.task_chart_uid;
          } else if (chart.entity_chart_uid !== undefined) {
            return chart.entity_chart_uid !== action.payload.entity_chart_uid;
          }
        });
      })
      .addCase(deleteGraphConfiguration.rejected, (state, action) => {
        state.deleteGraphConfigStatus = requestState.failed;
        state.deleteGraphConfigError = action.error.message;
      })

      .addCase(updateGraphConfigurations.pending, (state) => {
        state.updateGraphConfigStatus = requestState.loading;
      })
      .addCase(updateGraphConfigurations.fulfilled, (state, action) => {
        state.updateGraphConfigStatus = requestState.success;
        console.log('UPDATING');
        state.allChartConfigs = state.allChartConfigs.map((chart: any) => {
          if (chart.project_chart_uid !== undefined) {
            if (chart.project_chart_uid === action.payload.project_chart_uid) {
              return action.payload;
            }
          } else if (chart.case_chart_uid !== undefined) {
            if (chart.case_chart_uid === action.payload.case_chart_uid) {
              return action.payload;
            }
          } else if (chart.task_chart_uid !== undefined) {
            if (chart.task_chart_uid === action.payload.task_chart_uid) {
              return action.payload;
            }
          } else if (chart.entity_chart_uid !== undefined) {
            if (chart.entity_chart_uid === action.payload.entity_chart_uid) {
              return action.payload;
            }
          }
          return chart;
        });
        // state.allChartConfigs = state.allChartConfigs.filter((chart) => {
        //   if (chart.project_chart_uid !== undefined) {
        //     return chart.project_chart_uid !== action.payload.project_chart_uid;
        //   } else if (chart.case_chart_uid !== undefined) {
        //     return chart.case_chart_uid !== action.payload.case_chart_uid;
        //   } else if (chart.task_chart_uid !== undefined) {
        //     return chart.task_chart_uid !== action.payload.task_chart_uid;
        //   }
        // });
        // state.allChartConfigs.push(action.payload);
      })
      .addCase(updateGraphConfigurations.rejected, (state, action) => {
        state.updateGraphConfigStatus = requestState.failed;
        state.updateGraphConfigError = action.error.message;
      });
  },
});

const chartsReducer = chartsSlice.reducer;
export default chartsReducer;
