import { dataTypes } from './DataType';

const GREATER_THAN = '>';
const GREATER_THAN_OR_EQUALS = '>=';
const LESSER_THAN = '<';
const LESSER_THAN_OR_EQUALS = '<=';
const EQUALS_TO = '=';
const NOT_EQUALS_TO = '!=';

// const STATUS_RED = 'red';
// const STATUS_AMBER = 'amber';
// const STATUS_GREEN = 'green';
// const STATUS_BLUE = 'blue';

const numericalConditions = [GREATER_THAN, GREATER_THAN_OR_EQUALS, LESSER_THAN, LESSER_THAN_OR_EQUALS, EQUALS_TO];
const ragValues = [EQUALS_TO, NOT_EQUALS_TO];

export const availableConditions = {
  [dataTypes.number.toLowerCase()]: [...numericalConditions],
  [dataTypes.rag]: [...ragValues],
  [dataTypes.dropDown.toLowerCase()]: [...ragValues],
  ['RAG status']: [...ragValues],
  [dataTypes.string]: [...ragValues],
  [dataTypes.link]: [...ragValues],
  [dataTypes.userName]: [...ragValues],
  [dataTypes.date.toLocaleLowerCase()]: [...numericalConditions],
};
