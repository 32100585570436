// import * as React from 'react';
// import Box from '@mui/material/Box';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
import { SelectChangeEvent } from '@mui/material/Select';
import CognisaasDropdown from 'components/shared/CognisaasDropdown/CognisaasDropdown';
// import { handleInlineEditClickType, inlineEditPropType } from
// import { handleInlineEditClickType, inlineEditPropType } from 'components/shared/Tables/types';
import { useGridApiContext } from '@mui/x-data-grid';

export const ExcelDropdown = (
  params: any,
  dropdownValues: string[],
  handleUpdate: any
  // handleOnClick?: handleInlineEditClickType,
  // type?: inlineEditPropType
) => {
  const apiRef = useGridApiContext();
  const { id, field, value } = params;
  const handleChange = (value: string) => {
    handleUpdate(value, id, field);
    apiRef.current.setEditCellValue({ id, field, value: value });
  };

  // const handleClick = async () => {
  //   await handleOnClick(params, field, value, type);
  //   apiRef.current.stopCellEditMode({ id, field });
  // };

  function determineCustomValue(params: any, value: any): unknown {
    return value;
  }

  return (
    <>
      <div style={{ padding: '0 4px', display: 'flex', alignItems: 'center' }}>
        <CognisaasDropdown
          validvalues={dropdownValues}
          onChange={(event: SelectChangeEvent<string | unknown>) => handleChange(event.target.value as string)}
          value={value == null ? 'None' : determineCustomValue(params, value)}
          // placeholder="Not Selected"
        />
      </div>
    </>
  );
};
