import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';

export const rootState = (state: RootState) => state;

export const selectResourceManagementState = (state: RootState) => state.resourceManagementState;

export const selectMainViewData = createSelector(
  selectResourceManagementState,
  (resourceManagementState) => resourceManagementState.mainView
);

export const selectResourceViewData = createSelector(
  selectResourceManagementState,
  (resourceManagementState) => resourceManagementState.resourceView
);

export const selectProjectViewData = createSelector(
  selectResourceManagementState,
  (resourceManagementState) => resourceManagementState.projectView
);

export const selectResourceId = createSelector(
  selectResourceManagementState,
  (resourceManagementState) => resourceManagementState.selectedResourceId
);

export const selectProjectId = createSelector(
  selectResourceManagementState,
  (resourceManagementState) => resourceManagementState.selectedProjectId
);

export const selectTaskId = createSelector(
  selectResourceManagementState,
  (resourceManagementState) => resourceManagementState.selectedTaskId
);

export const selectComponentState = createSelector(
  selectResourceManagementState,
  (resourceManagementState) => resourceManagementState.componentState
);

export const selectResourceViewComponentState = createSelector(
  selectResourceManagementState,
  (resourceManagementState) => resourceManagementState.resourceViewComponentState
);

export const selectProjectViewComponentState = createSelector(
  selectResourceManagementState,
  (resourceManagementState) => resourceManagementState.projectViewComponentState
);

export const selectSoftAllocationData = createSelector(
  selectResourceManagementState,
  (resourceManagementState) => resourceManagementState.softAllocations
);

export const selectSoftAllocationId = createSelector(
  selectResourceManagementState,
  (resourceManagementState) => resourceManagementState.selectedSoftAllocationId
);

export const selectSoftAllocationDrawer = createSelector(
  selectResourceManagementState,
  (resourceManagementState) => resourceManagementState.softAllocationDrawer
);

export const selectUpdateTaskDrawer = createSelector(
  selectResourceManagementState,
  (resourceManagementState) => resourceManagementState.updateTaskDrawer
);

export const selectResourceViewModal = createSelector(
  selectResourceManagementState,
  (resourceManagementState) => resourceManagementState.resourceViewModal
);

export const selectProjectViewModal = createSelector(
  selectResourceManagementState,
  (resourceManagementState) => resourceManagementState.projectViewModal
);

export const selectExpandedItems = createSelector(
  selectResourceManagementState,
  (resourceManagementState) => resourceManagementState.expandedItems
);
