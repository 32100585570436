// import { useEffect } from 'react';
import { TextField, Typography } from '@mui/material';
import { Formik } from 'formik';
import CognisaasButton from '../../shared/CognisaasButton/CognisaasButton';
import Styles from './OnboardNewAccount.module.scss';
import Button from '@mui/material/Button';
import {
  selectCustomFieldsFromServer,
  selectAddedClientUidState,
} from '../../../store/modules/ManageClient/OnboardFormValues/selector';
import { useAppSelector } from '../../../store/hooks';
import CognisaasDropdown from '../../shared/CognisaasDropdown/CognisaasDropdown';
import Attachment from '../../shared/Attachment/Attachment';
import { attachmentKeyGenerator } from '../../shared/Attachment/attachmentHelpers';
import CognisaasCalenderNew from '../../shared/CognisaasCalenderNew/CognisaasCalenderNew';
import CognisaasSelect from '../../shared/CognisaasSelect/CognisaasSelect';
import { useEffect, useState } from 'react';
import { label_client } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import CognisaasToast from 'components/shared/CognisaasToast/CognisaasToast';

interface Props {
  handleSkip: (nextActiveState: number) => void;
  handleSave: (
    formCategory: 'accountProfile' | 'customFields' | 'addProject' | 'reset',
    formFieldValues: Record<string, any>,
    nextActiveState: number
  ) => void;
  handleCancel: () => void;
}

interface FieldProps {
  attachment: string;
  customer_uid: string;
  field_id: string;
  field_name: string;
  field_seq_num: string;
  field_type: string;
  field_uid: string;
  mandatory: string;
  valid_values: string;
}

const OnboardCustomFields = (componentProps: Props) => {
  const customFieldsFromServer = useAppSelector(selectCustomFieldsFromServer);
  const clientUid = useAppSelector(selectAddedClientUidState);
  const [disableSkipButton, setDisableSkipButton] = useState<boolean>(false);
  const [alertDetails, setAlertDetails] = useState<{
    isVisible: boolean;
    type: 'success' | 'failure';
    message: string;
  }>({ isVisible: false, type: 'success', message: '' });

  useEffect(() => {
    if (customFieldsFromServer.data.filter((cf: any) => cf.mandatory == 1).length > 0) {
      setDisableSkipButton(true);
    } else {
      setDisableSkipButton(false);
    }
  }, []);

  const getIntialValuesWithMandatoryStatus = (): Record<string, any> => {
    const newInitialValues: Record<string, any> = {};
    customFieldsFromServer.data.forEach((cf: any) => {
      newInitialValues[`${cf.field_uid}&&${cf.mandatory}`] = '';
    });

    return newInitialValues;
  };

  const getFieldsWithoutMandatoryStatus = (values: Record<string, any>): Record<string, any> => {
    const acceptedObj: Record<string, any> = {};

    Object.keys(values).forEach((key: string) => {
      acceptedObj[`${key.split('&&')[0]}`] = values[key];
    });

    return acceptedObj;
  };

  return (
    <>
      <CognisaasToast
        open={alertDetails.isVisible}
        onClose={() => setAlertDetails({ isVisible: false, type: alertDetails.type, message: '' })}
        message={alertDetails.message}
        type={alertDetails.type}
      />
      <Formik
        initialValues={getIntialValuesWithMandatoryStatus()}
        onSubmit={(values) => {
          componentProps.handleSave('customFields', getFieldsWithoutMandatoryStatus(values), 2);
        }}
        validationSchema={(() => {
          return;
        })()}
      >
        {(props: any) => (
          <form onSubmit={props.handleSubmit} className={Styles.OnboardAccountProfile__container}>
            <div className={Styles.OnboardAccountProfile__formAlternateTopSection}>
              <Typography sx={{ magrin: '12px 0px' }} variant="body2" component="span" gutterBottom>
                You can update the values of custom fields for this {label_client}.
              </Typography>
              <Button
                disabled={disableSkipButton}
                onClick={() => {
                  componentProps.handleSkip(2);
                }}
              >
                Skip
              </Button>
            </div>

            {/* type of fields String, Number, Date, Dropdown, Hyperlink, RAG status */}

            {customFieldsFromServer.data.map((eachField, index) => {
              const { field_type, field_name, valid_values, field_uid, mandatory, customer_uid, attachment } =
                eachField as unknown as FieldProps;
              field_name.trim();

              switch (field_type) {
                case 'String':
                  return (
                    <div className={Styles.OnboardAccount__section} key={index}>
                      <div className={Styles.OnboardAccount__customFieldWrapper}>
                        <Typography
                          variant="subtitle1"
                          component="div"
                          className={Styles.OnboardAccount__customFieldName}
                        >
                          {field_name} {mandatory == '1' && <sup style={{ color: 'red' }}>*</sup>}
                        </Typography>

                        <div className={Styles.OnboardAccount__customFieldInput}>
                          <TextField
                            sx={{ width: '310px' }}
                            size="small"
                            id={field_uid}
                            name={`${field_uid}&&${mandatory}`}
                            placeholder={field_name}
                            onChange={props.handleChange}
                            value={props.values[`${field_uid}&&${mandatory}`]}
                            error={
                              props.touched[`${field_uid}&&${mandatory}`] &&
                              mandatory == '1' &&
                              props.values[`${field_uid}&&${mandatory}`].length == 0
                            }
                          ></TextField>
                          {attachment === '1' ? (
                            <Attachment
                              type="small"
                              metaDataForHeaders={{
                                category: 'custom_field',
                                taskUid: null,
                                project_uid: null,
                                client_uid: clientUid,
                                customer_uid: customer_uid,
                                field_uid: field_uid,
                                case_uid: null,
                                key: attachmentKeyGenerator(customer_uid, field_uid),
                              }}
                              apiUrlForAttachmentUpload={'addAttachments'}
                              error={
                                props.touched[`${field_uid}&&${mandatory}`] && mandatory == '1' && attachment == '1'
                              }
                              setAlertDetails={setAlertDetails}
                            />
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                  );
                  break;

                case 'Number':
                  return (
                    <div className={Styles.OnboardAccount__section} key={index}>
                      <div className={Styles.OnboardAccount__customFieldWrapper}>
                        <Typography
                          variant="subtitle1"
                          component="div"
                          className={Styles.OnboardAccount__customFieldName}
                        >
                          {field_name} {mandatory == '1' && <sup style={{ color: 'red' }}>*</sup>}
                        </Typography>

                        <div className={Styles.OnboardAccount__customFieldInput}>
                          <TextField
                            sx={{ width: '310px' }}
                            size="small"
                            type="number"
                            id={`${field_uid}&&${mandatory}`}
                            name={`${field_uid}&&${mandatory}`}
                            placeholder={field_name}
                            onChange={props.handleChange}
                            value={props.values[`${field_uid}&&${mandatory}`]}
                            error={
                              props.touched[`${field_uid}&&${mandatory}`] &&
                              mandatory == '1' &&
                              props.values[`${field_uid}&&${mandatory}`].length == 0
                            }
                          ></TextField>
                          {attachment === '1' ? (
                            <Attachment
                              type="small"
                              metaDataForHeaders={{
                                category: 'custom_field',
                                taskUid: null,
                                project_uid: null,
                                client_uid: clientUid,
                                customer_uid: customer_uid,
                                field_uid: field_uid,
                                case_uid: null,
                                key: attachmentKeyGenerator(customer_uid, field_uid),
                              }}
                              apiUrlForAttachmentUpload={'addAttachments'}
                              error={
                                props.touched[`${field_uid}&&${mandatory}`] && mandatory == '1' && attachment == '1'
                              }
                              setAlertDetails={setAlertDetails}
                            />
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                  );
                  break;

                case 'Date':
                  return (
                    <div className={Styles.OnboardAccount__section} key={index}>
                      <div className={Styles.OnboardAccount__customFieldWrapper}>
                        <Typography
                          variant="subtitle1"
                          component="div"
                          className={Styles.OnboardAccount__customFieldName}
                        >
                          {field_name} {mandatory == '1' && <sup style={{ color: 'red' }}>*</sup>}
                        </Typography>

                        <div className={Styles.OnboardAccount__customFieldInput}>
                          <CognisaasCalenderNew
                            handleChange={(e: any) => {
                              console.log('date', e);
                              if (e.toString() !== '') {
                                props.setFieldValue(`${field_uid}&&${mandatory}`, new Date(e));
                              } else {
                                props.setFieldValue(`${field_uid}&&${mandatory}`, '');
                              }
                            }}
                            value={
                              props.values[`${field_uid}&&${mandatory}`] == ''
                                ? null
                                : props.values[`${field_uid}&&${mandatory}`]
                            }
                            error={
                              props.touched[`${field_uid}&&${mandatory}`] &&
                              mandatory == '1' &&
                              props.values[`${field_uid}&&${mandatory}`].toString().length == 0
                            }
                          />
                          {attachment === '1' ? (
                            <Attachment
                              type="small"
                              metaDataForHeaders={{
                                category: 'custom_field',
                                taskUid: null,
                                project_uid: null,
                                client_uid: clientUid,
                                customer_uid: customer_uid,
                                field_uid: field_uid,
                                case_uid: null,
                                key: attachmentKeyGenerator(customer_uid, field_uid),
                              }}
                              apiUrlForAttachmentUpload={'addAttachments'}
                              error={
                                props.touched[`${field_uid}&&${mandatory}`] && mandatory == '1' && attachment == '1'
                              }
                              setAlertDetails={setAlertDetails}
                            />
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                  );
                  break;

                case 'Dropdown':
                  return (
                    <div className={Styles.OnboardAccount__section} key={index}>
                      <div className={Styles.OnboardAccount__customFieldWrapper}>
                        <Typography
                          variant="subtitle1"
                          component="div"
                          className={Styles.OnboardAccount__customFieldName}
                        >
                          {field_name} {mandatory == '1' && <sup style={{ color: 'red' }}>*</sup>}
                        </Typography>

                        <div
                          style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
                          className={Styles.OnboardAccount__customFieldInput}
                        >
                          <CognisaasSelect
                            width="310px"
                            onChange={props.handleChange}
                            name={`${field_uid}&&${mandatory}`}
                            value={props.values[`${field_uid}&&${mandatory}`]}
                            validValues={[...valid_values.split(',')]}
                            onBlur={props.handleBlur}
                            error={
                              props.touched[`${field_uid}&&${mandatory}`] &&
                              mandatory == '1' &&
                              props.values[`${field_uid}&&${mandatory}`].length == 0
                            }
                          />
                          {attachment === '1' ? (
                            <Attachment
                              type="small"
                              metaDataForHeaders={{
                                category: 'custom_field',
                                taskUid: null,
                                project_uid: null,
                                client_uid: clientUid,
                                customer_uid: customer_uid,
                                field_uid: field_uid,
                                case_uid: null,
                                key: attachmentKeyGenerator(customer_uid, field_uid),
                              }}
                              apiUrlForAttachmentUpload={'addAttachments'}
                              error={
                                props.touched[`${field_uid}&&${mandatory}`] && mandatory == '1' && attachment == '1'
                              }
                              setAlertDetails={setAlertDetails}
                            />
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                  );
                  break;

                case 'Hyperlink':
                  return (
                    <div className={Styles.OnboardAccount__section} key={index}>
                      <div className={Styles.OnboardAccount__customFieldWrapper}>
                        <Typography
                          variant="subtitle1"
                          component="div"
                          className={Styles.OnboardAccount__customFieldName}
                        >
                          {field_name} {mandatory == '1' && <sup style={{ color: 'red' }}>*</sup>}
                        </Typography>

                        <div className={Styles.OnboardAccount__customFieldInput}>
                          <TextField
                            sx={{ width: '310px' }}
                            size="small"
                            id={field_name}
                            name={`${field_uid}&&${mandatory}`}
                            placeholder={field_name}
                            onChange={props.handleChange}
                            value={props.values[`${field_uid}&&${mandatory}`]}
                            error={
                              props.touched[`${field_uid}&&${mandatory}`] &&
                              mandatory == '1' &&
                              props.values[`${field_uid}&&${mandatory}`].length == 0
                            }
                          ></TextField>

                          {attachment === '1' ? (
                            <Attachment
                              type="small"
                              metaDataForHeaders={{
                                category: 'custom_field',
                                taskUid: null,
                                project_uid: null,
                                client_uid: clientUid,
                                customer_uid: customer_uid,
                                field_uid: field_uid,
                                case_uid: null,
                                key: attachmentKeyGenerator(customer_uid, field_uid),
                              }}
                              apiUrlForAttachmentUpload={'addAttachments'}
                              error={
                                props.touched[`${field_uid}&&${mandatory}`] && mandatory == '1' && attachment == '1'
                              }
                              setAlertDetails={setAlertDetails}
                            />
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                  );
                  break;

                case 'RAG status':
                  return (
                    <div className={Styles.OnboardAccount__section} key={index}>
                      <div className={Styles.OnboardAccount__customFieldWrapper}>
                        <Typography
                          variant="subtitle1"
                          component="div"
                          className={Styles.OnboardAccount__customFieldName}
                        >
                          {field_name} {mandatory == '1' && <sup style={{ color: 'red' }}>*</sup>}
                        </Typography>

                        <div
                          style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
                          className={Styles.OnboardAccount__customFieldInput}
                        >
                          <CognisaasDropdown
                            name={`${field_uid}&&${mandatory}`}
                            type="rag"
                            value={props.values[`${field_uid}&&${mandatory}`]}
                            onChange={props.handleChange}
                            validvalues={[...valid_values.split(',')]}
                            error={
                              props.touched[`${field_uid}&&${mandatory}`] &&
                              mandatory == '1' &&
                              props.values[`${field_uid}&&${mandatory}`].length == 0
                            }
                          />

                          {attachment === '1' ? (
                            <Attachment
                              type="small"
                              metaDataForHeaders={{
                                category: 'custom_field',
                                taskUid: null,
                                project_uid: null,
                                client_uid: clientUid,
                                customer_uid: customer_uid,
                                field_uid: field_uid,
                                case_uid: null,
                                key: attachmentKeyGenerator(customer_uid, field_uid),
                              }}
                              apiUrlForAttachmentUpload={'addAttachments'}
                              error={
                                props.touched[`${field_uid}&&${mandatory}`] && mandatory == '1' && attachment == '1'
                              }
                              setAlertDetails={setAlertDetails}
                            />
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                  );
                  break;

                default:
              }
            })}

            {/* buttons for furthur form actions */}
            <div className={Styles.OnboardAccountProfile__formActionButtonsContainer}>
              {/* save and cancel this step buttons  */}
              <CognisaasButton label={'Cancel'} isOutlined={true} clickHandler={componentProps.handleCancel} />
              <span style={{ width: '20px' }}></span>
              <CognisaasButton
                label={'Save'}
                isOutlined={false}
                clickHandler={() => {
                  props.handleSubmit();
                  // componentProps.handleSave('customFields', props.values, 2);
                }}
              />
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default OnboardCustomFields;
