import { apis } from '../axiosInstance';
import { commonRoutes } from '../route-new';

const getUsers = () => apis.phpAxiosInstance.get(commonRoutes.companyUsers(), { headers: { JWT: 'true' } });

const getSegments = () => apis.phpAxiosInstance.get(commonRoutes.getSegments(), { headers: { JWT: 'true' } });

const getIndustries = () => apis.phpAxiosInstance.get(commonRoutes.getIndustries(), { headers: { JWT: 'true' } });

const getStages = () => apis.phpAxiosInstance.get(commonRoutes.getStages(), { headers: { JWT: 'true' } });

export const commonAPI = {
  getUsers,
  getSegments,
  getIndustries,
  getStages,
};
