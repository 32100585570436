import { Typography, Stack } from '@mui/material';
import CognisaasButton from '../../shared/CognisaasButton/CognisaasButton';
import CognisaasMultiSelectWithSearchUid from '../../shared/CognisaasMultiSelectWithSearch/CognisaasMultiSelectWithSearchUid';
import { useState, useEffect } from 'react';
import useCompanyUsers from 'components/shared/Hooks/useCompanyUsers';
import useClientContacts from '../../shared/Hooks/useClientContacts';
import { selectProjectInformation } from '../../../store/modules/Project/selector';
import { useAppDispatch, useAppSelector } from 'store/hooks';
// import { addTeamMembersForProject } from 'store/modules/ManageClient/OnboardFormValues/slice';
import { addMembersForProject, addProjectAuditTrial, getProjectMembers } from '../../../store/modules/Project/slice';
import { useParams } from 'react-router-dom';
import { getLoggedInUserUid } from 'utils/getUid';
import { format } from 'date-fns';

const AddMember = (props: any) => {
  console.log(props);

  const selectedProjectInformation = useAppSelector(selectProjectInformation);

  const projectMembers = props.projectMembers;
  // const companyMembers = props.isUserInTeamView;
  const dispatch = useAppDispatch();
  const params = useParams();

  const companyMembers = props.isUserInTeamView
    ? useCompanyUsers()
    : useClientContacts(selectedProjectInformation.client_uid);

  const [addMembers, setAddMembers] = useState<any[]>([]);
  const [membersForDropDown, setMembersForDropDown] = useState<any[]>([]);

  useEffect(() => {
    if (companyMembers.length > 0) {
      setDataForChoosingMembers();
    }
  }, [projectMembers, companyMembers]);

  const setDataForChoosingMembers = () => {
    if (props.isUserInTeamView) {
      const oldMembersUid = projectMembers.map((member: any) => member.user_uid);
      console.log(oldMembersUid);

      const membersToAdd: any = [];
      for (let i = 0; i < companyMembers.length; i++) {
        if (!oldMembersUid.includes(companyMembers[i].user_uid)) {
          membersToAdd.push(companyMembers[i]);
        }
      }
      const dropDownData: any = [];
      for (let j = 0; j < membersToAdd.length; j++) {
        if (membersToAdd[j].is_active === '1') {
          const data: any = {};
          data.id = membersToAdd[j].user_uid;
          data.name = membersToAdd[j].user_username;
          dropDownData.push(data);
        }
      }

      setMembersForDropDown([...dropDownData]);
    } else {
      const oldMembersUid = projectMembers.map((member: any) => member.user_uid);
      console.log(oldMembersUid, companyMembers);

      const membersToAdd: any = [];
      for (let i = 0; i < companyMembers.length; i++) {
        console.log(companyMembers[i].user_uid, oldMembersUid, '--------------');

        if (!oldMembersUid.includes(companyMembers[i].user_uid)) {
          membersToAdd.push(companyMembers[i]);
        }
      }
      const dropDownData: any = [];
      for (let j = 0; j < membersToAdd.length; j++) {
        const data: any = {};
        data.id = membersToAdd[j].user_uid;
        data.name = membersToAdd[j].user_username;
        dropDownData.push(data);
      }

      setMembersForDropDown([...dropDownData]);
    }
  };

  const addMembersToProject = async () => {
    if (props.isUserInTeamView) {
      console.log('Add Team members ');
      const members: any = [];
      for (let i = 0; i < addMembers.length; i++) {
        const detail: any = {};
        detail.user_uid = addMembers[i];
        detail.is_client_contact = 0;
        members.push(detail);
      }
      const data: any = {
        members: [...members],
        association_uid: params.associationId,
        project_uid: params.projectId,
      };

      await dispatch(addMembersForProject(data)).unwrap().then(async () => {
        const details = {
          project_uid: params.projectId as string,
          client_uid: props.clientInfo.client_uid as string
        };
        await dispatch(getProjectMembers(details));
      });
    } else {
      console.log('Add contacts for this project');
      const members: any = [];
      for (let i = 0; i < addMembers.length; i++) {
        console.log(addMembers[i]);

        const detail: any = {};
        detail.user_uid = addMembers[i];
        detail.is_client_contact = 1;
        members.push(detail);
      }
      const data: any = {
        members: [...members],
        association_uid: params.associationId,
        project_uid: params.projectId,
      };

      await dispatch(addMembersForProject(data)).unwrap().then(async () => {
        const details = {
          project_uid: params.projectId as string,
          client_uid: props.clientInfo.client_uid as string
        };
        await dispatch(getProjectMembers(details));
      });
    }

    const auditDetail = {
      project_uid: params.projectId,
      user_uid: getLoggedInUserUid(),
      field: 'members_updated',
      modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      old_value: '',
      new_value: '',
    };
    dispatch(addProjectAuditTrial(auditDetail));
  };

  return (
    <Stack>
      <Stack my={2}>
        <Typography variant="h2">Add Members</Typography>
      </Stack>
      <Stack>
        <CognisaasMultiSelectWithSearchUid
          shouldBeOpenByDefault={true}
          allOptions={membersForDropDown}
          selectedOption={addMembers}
          setSelectedOptions={setAddMembers}
        />
      </Stack>
      <Stack sx={{ marginTop: '270px' }} spacing={2} justifyContent="flex-end" alignItems="flex-end" direction="row">
        <CognisaasButton clickHandler={props.onClose} isOutlined={true} label="Cancel" />
        <CognisaasButton
          clickHandler={() => {
            console.log(addMembers);
            addMembersToProject();

            // props.onClickingContinue(selectedClientContact);
            props.onClose();
          }}
          isOutlined={false}
          label="Continue"
        />
      </Stack>
    </Stack>
  );
};

export default AddMember;
