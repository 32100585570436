import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

interface MenuProps {
  list: string[];
  handleMenuClose: () => void;
  handleSelectFromMenu: (event: any) => void;
  open: any;
  anchorEl: null | HTMLElement;
}

const CognisaasMenu = (props: MenuProps) => {
  return (
    <React.Fragment>
      <Menu
        anchorEl={props.anchorEl}
        id="account-menu"
        open={props.open}
        onClose={props.handleMenuClose}
        onClick={props.handleMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {props.list.map((item: string, index: number) => (
          <MenuItem onClick={props.handleSelectFromMenu} dense key={index}>
            {item}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
};

export default CognisaasMenu;