import { Box, Stack, Typography, Button, ButtonGroup, Grid } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { CustomizedProgressBars } from '../../../components/shared/CognisaasProgressBar/ProgressBar';
import CircleIcon from '@mui/icons-material/Circle';
import { useLocalStorage } from 'usehooks-ts';

import StatusBadge from '../../../components/shared/LabeledBadge/LabeledBadge';
import React, { useEffect, useState } from 'react';

import { ReactComponent as MCIcon1 } from '../../../assets/svgs/manageclientIcon1.svg';
import { ReactComponent as MCIcon2 } from '../../../assets/svgs/manageclientIcon2.svg';
// import ColumnFilter from '../../../components/ManageClient/ColumnFilter';

import CognisaasCard from '../../../components/shared/CognisaasCard/CognisaasCard';

import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  clientCheckClient,
  client_getProjectsWithTasks,
  // client_getProjectUpgradationAlert,
  // client_getUsecaseUpgradationAlert,
  // client_getProjectDegradationAlert,
  // client_getUsecaseDegradationAlert,
  // client_getProjectStatusCountByClientUid,
  // client_getTaskStatusCountByClientUid,
  // client_getUseCaseStatusCount,
} from '../../../store/modules/CustomerPortal/CustomerPortal';
import { useAppSelector } from 'store/hooks';
import { selectProjectWithTasks } from 'store/modules/CustomerPortal/selector';

import {
  DataGridPremium,
  gridClasses,
  useGridApiContext,
  useGridSelector,
  gridPageSelector,
  gridPageCountSelector,
  GridFilterModel,
  GridColDef,
  GridRenderCellParams,
} from '@mui/x-data-grid-premium';
import { alpha, styled } from '@mui/material/styles';
import { Pagination } from '@mui/material';
import { PaginationItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import Cookies from 'universal-cookie';
import { isNull, isValidDate } from 'utils/dataHelpers';
import {
  label_delivery_date,
  label_project,
  label_project_delivery_manager,
} from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { getUserActiveState, INACTIVE_USER_COLOR } from 'utils/userHelpers';
import { getUserUid } from 'utils/getUid';
import useUserDropDownData from 'components/shared/Hooks/useUserDropDownData';

interface LocationParams {
  pathname: string;
  state: any;
  search: string;
  hash: string;
  key: string;
}

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGridPremium)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
        },
      },
    },
  },
}));

interface GridPaginationSettings {
  pagination: boolean;
  autoPageSize: boolean;
  pageSize: number | undefined;
}

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  border: '1px solid #F3F3F3',
  flexDirection: 'column',
  // height: 600,
  width: '100%',
  '& .MuiFormGroup-options': {
    alignItems: 'center',
    paddingBottom: theme.spacing(1),
    '& > div': {
      minWidth: 100,
      margin: theme.spacing(2),
      marginLeft: 0,
    },
    border: 'none',
  },
}));

// const TableData = [];

function stringToHslColor(str: string, s: number, l: number) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const h = hash % 360;
  return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToHslColor(name, 30, 80),
    },
    children: (
      <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#000000' }}>
        {name.split(' ')[0][0]}
        {name.indexOf(' ') >= 0 ? name.split(' ')[1][0] : null}
      </Typography>
    ),
  };
}

const CustomerProject = () => {
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const navigate = useNavigate();
  const location: LocationParams = useLocation();

  const [tableView, setTableView] = React.useState<boolean>(false);
  const [isTabSelected, setIsTabSelected] = React.useState<string>('All');
  const [FilteredTableData, setFilteredTableData] = React.useState<any>([]);
  const [totalProjects, setTotalProjects] = React.useState<any>([]);
  const [totalOverdue, setTotalOverDue] = React.useState<any>();
  const [totalAtRisk, setTotalAtRisk] = React.useState<any>();
  const [totalOnTrack, setTotalOnTrack] = React.useState<any>();
  const [clientUid, setClientUid] = React.useState('');
  const companyUsersDropDownData = useUserDropDownData('company');

  // const [tableDataNew, setTableDataNew] = React.useState([]);

  const [companyName, setCompanyName] = useState<any>();
  const projectWithTaskApi: any = useAppSelector(selectProjectWithTasks);

  const [filterProjectTable, setFilterProjectTable] = useLocalStorage<GridFilterModel>('usecaseOpenFilters', {
    items: [],
  } as GridFilterModel);

  React.useEffect(() => {
    if (cookies.get('client_uid') as string == '') {
      navigate('/customer-portal/dashboard');
    }
    const tempVal: any = cookies.get('customerCompanyName');
    const clientUidRef: any = cookies.get('client_uid');
    setCompanyName(tempVal);
    setClientUid(clientUidRef);
    const id = setTimeout(addFilters, 1000);
    return () => clearTimeout(id);
  }, []);

  // React.useEffect(() => {
  //   if (projectWithTaskApi?.length > 0) setTableDataNew(projectWithTaskApi);
  // }, [projectWithTaskApi]);

  React.useEffect(() => {
    if (companyName?.length > 0) dispatch(clientCheckClient(companyName));
  }, [projectWithTaskApi]);

  // React.useEffect(() => {
  //   console.log(useObjApi, 'selected user Obj');
  //   localStorage.setItem('customer_uid', useObjApi?.client_user_customer_uid);
  //   localStorage.setItem('client_contact_uid', useObjApi?.client_contact_uid);
  // }, [useObjApi]);

  // React.useEffect(() => {
  //   console.log(projectWithTaskApi, 'cpny nam');
  //   dispatch(client_userSignIn_withjwttoken(jwtToken));
  // }, [jwtToken]);

  // React.useEffect(() => {
  //   console.log(projectWithTaskApi, tableDataNew, totalProjects, totalOverdue, totalAtRisk, totalOnTrack, 'bruh test');
  // }, [tableDataNew, totalProjects, totalOverdue, totalAtRisk, totalOnTrack]);

  const SetFilteredData = () => {
    setTotalProjects(projectWithTaskApi);
    setTotalOverDue(projectWithTaskApi?.filter((ele: any) => ele.project_readinessstate_status == 'red'));
    setTotalAtRisk(projectWithTaskApi?.filter((ele: any) => ele.project_readinessstate_status == 'amber'));
    setTotalOnTrack(projectWithTaskApi?.filter((ele: any) => ele.project_readinessstate_status == 'green'));
  };

  React.useEffect(() => {
    if (clientUid) {
      dispatch(client_getProjectsWithTasks(clientUid));
    }
  }, [clientUid]);

  useEffect(() => {
    console.log(FilteredTableData, projectWithTaskApi);
    console.log(FilteredTableData, projectWithTaskApi, 'test');
    if (projectWithTaskApi?.length > 0) SetFilteredData();
    console.log(projectWithTaskApi?.length, totalOverdue, totalOnTrack, totalAtRisk, 'fil');
  }, [projectWithTaskApi, isTabSelected]);

  const initialState: any = {
    columns: {
      columnVisibilityModel: {},
    },
  };

  const columns: GridColDef[] = [
    {
      field: 'project_id',
      hide: true,
    },
    {
      field: 'project_name',
      hide: false,
      headerName: `${label_project} Name`,
      flex: 1,
      editable: true,
    },
    {
      field: 'project_progress',
      hide: false,
      headerName: 'Progress',
      // flex: 1,
      width: 150,
      maxWidth: 150,
      editable: false,
      renderCell: (params: GridRenderCellParams) => (
        <>
          {console.log(params.row.project_progress, 'here param')}
          <CustomizedProgressBars
            value={params.row.project_progress}
            statusColor={params.row.project_readinessstate_status}
            hidePercentage={false}
          />
        </>
      ),
    },
    // {
    //   field: 'project_deliverydate',
    //   hide: false,
    //   headerName: 'Due Date',
    //   flex: 1,
    //   // editable: true,
    // },
    {
      field: 'project_deliverydate',
      hide: false,
      headerName: label_delivery_date,
      flex: 1,
      // editable: true,
      renderCell: (params: GridRenderCellParams) => (
        <>
          {params.row.project_deliverydate != '0000-00-00 00:00:00' || !isNull(params.row.project_deliverydate)
            ? format(new Date(params.row.project_deliverydate.replace(/-/g, '/')), 'dd MMM yyyy')
            : null}
        </>
      ),
    },
    {
      field: 'eta_forprojectdelivery',
      hide: false,
      headerName: 'Estimated Delivery Date',
      flex: 1,
      // editable: true,
      renderCell: (params: GridRenderCellParams) => (
        <>{format(new Date(params.row.eta_forprojectdelivery.replace(/-/g, '/')), 'dd MMM yyyy')}</>
      ),
    },
    {
      field: 'project_readinessstate_status',
      hide: false,
      headerName: 'Status',
      flex: 1,
      // editable: true,
      renderCell: (params: GridRenderCellParams) => (
        <>
          {params.row.project_readinessstate_status === 'amber' ? (
            <>
              <Stack direction="row">
                <CircleIcon
                  sx={{
                    color: '#EFA958',
                    height: '8px',
                    width: '8px',
                    marginTop: '5px',
                    marginLeft: '12px',
                  }}
                />
                <Typography sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}>At Risk</Typography>
              </Stack>
            </>
          ) : null}
          {params.row.project_readinessstate_status === 'red' ? (
            <>
              <Stack direction="row">
                <CircleIcon
                  sx={{
                    color: '#E75B5C',
                    height: '8px',
                    width: '8px',
                    marginTop: '5px',
                    ml: '8px',
                  }}
                />
                <Typography sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}>Overdue</Typography>
              </Stack>
            </>
          ) : null}
          {params.row.project_readinessstate_status === 'green' ? (
            <>
              <Stack direction="row">
                <CircleIcon
                  sx={{
                    color: '#4F9F52',
                    height: '8px',
                    width: '8px',
                    marginTop: '5px',
                    ml: '8px',
                  }}
                />
                <Typography sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}>On Track</Typography>
              </Stack>
            </>
          ) : null}
          {params.row.project_readinessstate_status === 'blue' ? (
            <>
              <Stack direction="row">
                <CircleIcon
                  sx={{
                    color: '#4390E1',
                    height: '8px',
                    width: '8px',
                    marginTop: '5px',
                    ml: '8px',
                  }}
                />
                <Typography sx={{ fontSize: '12px', fontWeight: '400', marginLeft: '8px' }}>Completed</Typography>
              </Stack>
            </>
          ) : null}
        </>
      ),
    },

    {
      field: 'project_deliverymanager',
      hide: false,
      headerName: label_project_delivery_manager,
      flex: 1,
      // editable: true,
      renderCell: (params: GridRenderCellParams) => (
        <>
          <Stack direction="row">
            <Avatar style={{ height: '30px', width: '30px' }} {...stringAvatar(params?.row?.project_deliverymanager)} />
            <Box sx={{ mt: '5px', ml: '7px' }}>
              <Typography sx={{ fontSize: '12px', fontWeight: '400' }}>{params.row.project_deliverymanager}</Typography>
            </Box>
          </Stack>
        </>
      ),
    },
  ];

  const rows = [
    ...(isTabSelected == 'All'
      ? totalProjects
      : isTabSelected == 'Overdue'
        ? totalOverdue
        : isTabSelected == 'At risk'
          ? totalAtRisk
          : isTabSelected == 'On Track'
            ? totalOnTrack
            : null),
  ];

  const data = {
    columns,
    rows,
    initialState,
  };

  function getPaginationItemStyle(item: any) {
    switch (item.type) {
      case 'page':
        if (item.selected) {
          return { background: '#c0c0c0', color: '#2D60F6' };
        }
        return { background: '#ffffff', color: '#000000' };
      case 'previous':
        return {};
      case 'next':
        return {};
    }
  }

  const [pagination] = useState<GridPaginationSettings>({
    pagination: true,
    autoPageSize: false,
    pageSize: 5,
  });

  const addFilters = () => {
    if (location.state !== null) {
      const { data } = location.state;
      const filter = {
        items: [
          {
            columnField: 'project_readinessstate_status',
            operatorValue: 'equals',
            value: data.label,
          },
        ],
      };
      setFilterProjectTable(filter);
    }
  };

  const CustomPagination = () => {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    console.log(page, pageCount);
    return (
      <Box
        component={'span'}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          margin: '20px 0px',
        }}
      >
        <Typography variant="subtitle1">
          {page == 0 ? page + '-' + 5 : page * 5 + '-' + (page * 5 + 5)} of {projectWithTaskApi?.length}
        </Typography>
        <Pagination
          count={pageCount}
          onChange={(event, value) => {
            // console.log(value, 'val check');
            apiRef.current.setPage(value - 1);
          }}
          shape="rounded"
          page={page === 0 ? 1 : page + 1}
          renderItem={(item) => {
            // console.log(item, 'item check');
            return <PaginationItem {...item} sx={getPaginationItemStyle(item)} />;
          }}
        />
      </Box>
    );
  };

  columns.forEach((item: any) => {
    if (item.hide) {
      initialState.columns.columnVisibilityModel[item.field] = false;
    }
  });

  return (
    <Box sx={{ p: '10px' }}>
      <Stack direction="row" sx={{ paddingTop: '24px', paddingLeft: '10px' }}>
        <Typography sx={{ fontSize: '20px', fontWeight: '500', marginRight: '12px' }}>Project</Typography>
        <Avatar sx={{ bgcolor: '#2D60F6', height: '24px', width: '24px', marginTop: '2px' }}>
          <Typography sx={{ fontSize: '12px', fontWeight: '400' }}>{projectWithTaskApi?.length}</Typography>
        </Avatar>
      </Stack>
      <Grid container xs={12} sx={{ marginTop: '24px' }}>
        <Grid item xs={6}>
          <ButtonGroup
            variant="outlined"
            aria-label="outlined button group"
            sx={{
              display: 'inline-flex',
              flexWrap: 'wrap',
              backgroundColor: 'white',
              border: '1px solid #E5E5E5',
              borderRadius: '4px',
              // width: '485px',
            }}
          >
            <Button disableRipple sx={isTabSelected == 'All' ? { background: 'rgba(45, 96, 246,0.15)' } : {}}>
              <StatusBadge
                text="All"
                statusColor={'blue'}
                hideWidth={true}
                badgeCount={totalProjects?.length}
                isActive={isTabSelected == 'All' ? true : false}
                onClick={() => {
                  setIsTabSelected('All');
                  setFilteredTableData(projectWithTaskApi);
                  // SendFilteredData();
                }}
              />
            </Button>
            <Button disableRipple sx={isTabSelected == 'Overdue' ? { background: 'rgba(45, 96, 246,0.15)' } : {}}>
              <StatusBadge
                text="Overdue"
                statusColor={'#E75B5C'}
                hideWidth={true}
                badgeCount={totalOverdue?.length}
                isActive={isTabSelected == 'Overdue' ? true : false}
                onClick={() => {
                  setIsTabSelected('Overdue');
                  setFilteredTableData(
                    projectWithTaskApi?.filter((ele: any) => {
                      const tempData = ele.project_readinessstate_status == 'red';
                      return tempData;
                    })
                  );
                  // SendFilteredData();
                }}
              />
            </Button>
            <Button disableRipple sx={isTabSelected == 'At risk' ? { background: 'rgba(45, 96, 246,0.15)' } : {}}>
              <StatusBadge
                text="At risk"
                statusColor={'#EFA958'}
                hideWidth={true}
                badgeCount={totalAtRisk?.length}
                isActive={isTabSelected == 'At risk' ? true : false}
                onClick={() => {
                  setIsTabSelected('At risk');
                  setFilteredTableData(
                    projectWithTaskApi?.filter((ele: any) => ele.project_readinessstate_status == 'amber')
                  );
                  // SendFilteredData();
                }}
              />
            </Button>
            <Button disableRipple sx={isTabSelected == 'On Track' ? { background: 'rgba(45, 96, 246,0.15)' } : {}}>
              <StatusBadge
                text="On Track"
                statusColor={'#4F9F52'}
                hideWidth={true}
                badgeCount={totalOnTrack?.length}
                isActive={isTabSelected == 'On Track' ? true : false}
                onClick={() => {
                  setIsTabSelected('On Track');
                  setFilteredTableData(
                    projectWithTaskApi?.filter((ele: any) => ele.project_readinessstate_status == 'green')
                  );
                  // SendFilteredData();
                }}
              />
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid item xs={6}>
          <Grid container justifyContent="flex-end">
            <Stack direction="row">
              {/* <ColumnFilter /> */}
              <ButtonGroup sx={{ backgroundColor: 'white' }} variant="outlined" aria-label="outlined button group">
                <Button className={`${!tableView ? 'active-btn' : null} `} onClick={() => setTableView(false)}>
                  <MCIcon1 />
                </Button>
                <Button className={`${tableView ? 'active-btn' : null} `} onClick={() => setTableView(true)}>
                  <MCIcon2 />
                </Button>
              </ButtonGroup>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      {tableView ? (
        <StyledBox>
          <StripedDataGrid
            hideFooter={false}
            autoHeight
            sx={{
              boxShadow: 0,
              border: 0,
              mt: '10px',
              borderColor: 'primary.light',
              '& .MuiDataGrid-cell:hover': {
                color: 'primary.main',
              },
            }}
            {...data}
            components={{
              Footer: CustomPagination,
            }}
            componentsProps={{
              toolbar: { showQuickFilter: true },
            }}
            loading={false}
            getRowId={(row) => row['project_id']}
            disableSelectionOnClick
            rowThreshold={0}
            initialState={{
              ...data.initialState,
              pinnedColumns: { left: ['field_name'] },
            }}
            {...pagination}
            getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'odd' : 'odd')}
            onRowClick={(params) => {
              navigate('/customer-portal/project/dashboard', { state: { selectedProject: params.row } });
            }}
            experimentalFeatures={{ newEditingApi: true }}
            filterModel={filterProjectTable}
            onFilterModelChange={(newFilterModel) => setFilterProjectTable(newFilterModel)}
          />
        </StyledBox>
      ) : (
        <Box>
          {/* <Box sx={{ flexGrow: 1, mt: '24px' }}>
            <Grid container spacing={{ xs: 2, md: 3 }}>
              {Array.from(Array(8)).map((_, index) => (
                <Grid item xs={6} sm={4} md={3} key={index}>
                  <Item>xs=2</Item>
                </Grid>
              ))}
            </Grid>
          </Box> */}
          <Box sx={{ flexGrow: 1, mt: '24px' }}>
            <Grid container spacing={{ xs: 2, md: 2 }}>
              {isTabSelected == 'All'
                ? totalProjects &&
                totalProjects.map((ele: any, index: number) => (
                  <Grid className="cognisaas-pointer" item xs={6} sm={6} md={4} lg={3} key={index}>
                    <CognisaasCard clientStatusColor={ele.project_readinessstate_status} height="150px" width="310px">
                      <Box
                        sx={{ padding: '12px' }}
                        onClick={() =>
                          navigate('/customer-portal/project/dashboard', { state: { selectedProject: ele } })
                        }
                      >
                        <Typography sx={{ fontSize: '14px', fontWeight: '400' }}>{ele.project_name}</Typography>
                        <Box sx={{ width: '80%', mt: '32px' }}>
                          <CustomizedProgressBars
                            value={ele.project_progress}
                            statusColor={ele.project_readinessstate_status}
                            hidePercentage={false}
                          />
                        </Box>
                        <Grid container justifyContent="space-between">
                          <Grid item>
                            <Box sx={{ mt: '20px' }}>
                              <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#7A7A7A' }}>
                                Due by
                              </Typography>
                              <Typography sx={{ fontSize: '12px', fontWeight: '400' }}>
                                {isValidDate(ele.project_requiredbydate) || !isNull(ele.project_requiredbydate) ? (
                                  ele.project_requiredbydate == '0000-00-00 00:00:00' ? (
                                    <>-</>
                                  ) : (
                                    <>
                                      {format(new Date(ele.project_requiredbydate.replace(/-/g, '/')), 'dd MMM yyyy')}
                                    </>
                                  )
                                ) : (
                                  <>-</>
                                )}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item>
                            <Stack direction="row">
                              <Avatar
                                style={{ height: '30px', width: '30px', marginTop: '25px' }}
                                {...stringAvatar(ele?.project_deliverymanager)}
                              />
                              <Box sx={{ mt: '20px', ml: '7px' }}>
                                <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#7A7A7A' }}>
                                  {label_project_delivery_manager}
                                </Typography>
                                {/* <Typography sx={{ fontSize: '12px', fontWeight: '400' }}>
                                    {ele.project_deliverymanager}
                                  </Typography> */}
                                {getUserActiveState(
                                  getUserUid(ele.project_deliverymanager),
                                  companyUsersDropDownData
                                ) === '0' ? (
                                  <Typography
                                    style={{ fontSize: '12px', fontWeight: '400', color: INACTIVE_USER_COLOR }}
                                    variant="subtitle1"
                                  >
                                    {ele.project_deliverymanager}&nbsp;(Inactive)
                                  </Typography>
                                ) : (
                                  <Typography sx={{ fontSize: '12px', fontWeight: '400' }} variant="subtitle1">
                                    {ele.project_deliverymanager}
                                  </Typography>
                                )}
                              </Box>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Box>
                    </CognisaasCard>
                  </Grid>
                ))
                : null}
              {isTabSelected == 'Overdue'
                ? totalOverdue.map((ele: any, index: number) => (
                  <Grid item xs={6} sm={6} md={4} lg={3} key={index}>
                    <CognisaasCard clientStatusColor={ele.project_readinessstate_status} height="150px" width="310px">
                      <Box
                        sx={{ padding: '12px' }}
                        onClick={() =>
                          navigate('/customer-portal/project/dashboard', { state: { selectedProject: ele } })
                        }
                      >
                        <Typography sx={{ fontSize: '14px', fontWeight: '400' }}>{ele.project_name}</Typography>
                        <Box sx={{ width: '80%', mt: '32px' }}>
                          <CustomizedProgressBars
                            value={ele.project_progress}
                            statusColor={ele.project_readinessstate_status}
                            hidePercentage={false}
                          />
                        </Box>
                        <Grid container justifyContent="space-between">
                          <Grid item>
                            <Box sx={{ mt: '20px' }}>
                              <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#7A7A7A' }}>
                                Due by
                              </Typography>
                              <Typography sx={{ fontSize: '12px', fontWeight: '400' }}>
                                {isValidDate(ele.project_requiredbydate) || !isNull(ele.project_requiredbydate) ? (
                                  ele.project_requiredbydate == '0000-00-00 00:00:00' ? (
                                    <>-</>
                                  ) : (
                                    <>
                                      {format(new Date(ele.project_requiredbydate.replace(/-/g, '/')), 'dd MMM yyyy')}
                                    </>
                                  )
                                ) : (
                                  <>-</>
                                )}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item>
                            <Stack direction="row">
                              <Avatar
                                style={{ height: '30px', width: '30px', marginTop: '25px' }}
                                {...stringAvatar(ele?.project_deliverymanager)}
                              />
                              <Box sx={{ mt: '20px', ml: '7px' }}>
                                <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#7A7A7A' }}>
                                  {label_project_delivery_manager}
                                </Typography>
                                <Typography sx={{ fontSize: '12px', fontWeight: '400' }}>
                                  {ele.project_deliverymanager}
                                </Typography>
                              </Box>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Box>
                    </CognisaasCard>
                  </Grid>
                ))
                : null}
              {isTabSelected == 'At risk'
                ? totalAtRisk.map((ele: any, index: number) => (
                  <Grid item xs={6} sm={6} md={4} lg={3} key={index}>
                    <CognisaasCard clientStatusColor={ele.project_readinessstate_status} height="150px" width="310px">
                      <Box
                        sx={{ padding: '12px' }}
                        onClick={() =>
                          navigate('/customer-portal/project/dashboard', { state: { selectedProject: ele } })
                        }
                      >
                        <Typography sx={{ fontSize: '14px', fontWeight: '400' }}>{ele.project_name}</Typography>
                        <Box sx={{ width: '80%', mt: '32px' }}>
                          <CustomizedProgressBars
                            value={ele.project_progress}
                            statusColor={ele.project_readinessstate_status}
                            hidePercentage={false}
                          />
                        </Box>
                        <Grid container justifyContent="space-between">
                          <Grid item>
                            <Box sx={{ mt: '20px' }}>
                              <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#7A7A7A' }}>
                                Due by
                              </Typography>
                              <Typography sx={{ fontSize: '12px', fontWeight: '400' }}>
                                {isValidDate(ele.project_requiredbydate) || !isNull(ele.project_requiredbydate) ? (
                                  ele.project_requiredbydate == '0000-00-00 00:00:00' ? (
                                    <>-</>
                                  ) : (
                                    <>
                                      {format(new Date(ele.project_requiredbydate.replace(/-/g, '/')), 'dd MMM yyyy')}
                                    </>
                                  )
                                ) : (
                                  <>-</>
                                )}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item>
                            <Stack direction="row">
                              <Avatar
                                style={{ height: '30px', width: '30px', marginTop: '25px' }}
                                {...stringAvatar(ele?.project_deliverymanager)}
                              />
                              <Box sx={{ mt: '20px', ml: '7px' }}>
                                <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#7A7A7A' }}>
                                  {label_project_delivery_manager}
                                </Typography>
                                <Typography sx={{ fontSize: '12px', fontWeight: '400' }}>
                                  {ele.project_deliverymanager}
                                </Typography>
                              </Box>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Box>
                    </CognisaasCard>
                  </Grid>
                ))
                : null}
              {isTabSelected == 'On Track'
                ? totalOnTrack.map((ele: any, index: number) => (
                  <Grid item xs={6} sm={6} md={4} lg={3} key={index}>
                    <CognisaasCard clientStatusColor={ele.project_readinessstate_status} height="150px" width="310px">
                      <Box
                        sx={{ padding: '12px' }}
                        onClick={() =>
                          navigate('/customer-portal/project/dashboard', { state: { selectedProject: ele } })
                        }
                      >
                        <Typography sx={{ fontSize: '14px', fontWeight: '400', cursor: 'pointer' }}>
                          {ele.project_name}
                        </Typography>
                        <Box sx={{ width: '80%', mt: '32px' }}>
                          <CustomizedProgressBars
                            value={ele.project_progress}
                            statusColor={ele.project_readinessstate_status}
                            hidePercentage={false}
                          />
                        </Box>
                        <Grid container justifyContent="space-between">
                          <Grid item>
                            <Box sx={{ mt: '20px' }}>
                              <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#7A7A7A' }}>
                                Due by
                              </Typography>
                              <Typography sx={{ fontSize: '12px', fontWeight: '400' }}>
                                {isValidDate(ele.project_requiredbydate) || !isNull(ele.project_requiredbydate) ? (
                                  ele.project_requiredbydate == '0000-00-00 00:00:00' ? (
                                    <>-</>
                                  ) : (
                                    <>
                                      {format(new Date(ele.project_requiredbydate.replace(/-/g, '/')), 'dd MMM yyyy')}
                                    </>
                                  )
                                ) : (
                                  <>-</>
                                )}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item>
                            <Stack direction="row">
                              <Avatar
                                style={{ height: '30px', width: '30px', marginTop: '25px' }}
                                {...stringAvatar(ele?.project_deliverymanager)}
                              />
                              <Box sx={{ mt: '20px', ml: '7px' }}>
                                <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#7A7A7A' }}>
                                  {label_project_delivery_manager}
                                </Typography>
                                <Typography sx={{ fontSize: '12px', fontWeight: '400' }}>
                                  {ele.project_deliverymanager}
                                </Typography>
                              </Box>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Box>
                    </CognisaasCard>
                  </Grid>
                ))
                : null}
            </Grid>
          </Box>
        </Box>
      )}

      {/*  */}
    </Box>
  );
};
export default CustomerProject;
