import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  Grid,
  Paper,
  Stack,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
  ButtonGroup,
  Typography,
} from '@mui/material';

import ListTableHead from 'components/RBACv2/shared/ListTableHead';
import CognisaasSpinner from 'components/shared/CognisaasSpinner/CognisaasSpinner';
import { requestState } from 'store/modules/types';
import { RBACType, SortingOrder } from 'components/RBACv2/types';
import { useAppSelector } from 'store/hooks';
import { getAllPermissions_selector, getConditions_selector } from 'store/modules/RBAC/selector';
import PermissionsCard from './PermissionsCard';
import { stableSort } from 'components/RBACv2/utils/filters';
import { getAllPermissions_slice, getConditions_slice } from 'store/modules/RBAC/slice';
import PermissionsDetail from './PermissionsDetail/PermissionDetail';

interface PermissionProps {
  currentRBACTab: RBACType;
  setCurrentRBACTab: React.Dispatch<React.SetStateAction<RBACType>>;
}

export default function Permissions(props: PermissionProps) {
  const [order, setOrder] = useState<SortingOrder>('asc');
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [activeButton, setActiveButton] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [drawerState, setDrawerState] = useState<boolean>(false);
  // const [allPermissions, setAllPermissions] = useState<any[]>([]);
  // const [allConditions, setAllConditions] = useState<any[]>([]);

  const getAllPermissionsStatus = useAppSelector(getAllPermissions_selector).status;
  const getAllPermissionsData = useAppSelector(getAllPermissions_selector).data;
  const getAllConditionsData = useAppSelector(getConditions_selector).data;
  const getAllConditionsStatus = useAppSelector(getConditions_selector).status;

  const dispatch = useDispatch();

  useEffect(() => {
    if (activeButton === 0 && getAllPermissionsData.length > 0) {
      setFilteredData([...getAllPermissionsData]);
      setSelectedRow(getAllPermissionsData[0]);
    } else if (activeButton === 1 && getAllConditionsData.length > 0) {
      setFilteredData([...getAllConditionsData]);
      setSelectedRow(getAllConditionsData[0]);
    }
  }, [getAllPermissionsData, getAllConditionsData]);

  useEffect(() => {
    if (activeButton === 0) {
      props.setCurrentRBACTab('Policy');
      dispatch(getAllPermissions_slice({ role: '' }));
    } else {
      props.setCurrentRBACTab('Condition');
      dispatch(getConditions_slice());
    }
  }, [activeButton]);

  useEffect(() => {
    if (activeButton === 0) {
      const data = getAllPermissionsData?.filter((rowItem: any) => {
        return searchValue.length > 0 ? rowItem?.name?.includes(searchValue) : true;
      });
      setFilteredData(data);
      setSelectedRow([]);
    } else {
      const data = getAllConditionsData?.filter((rowItem: any) => {
        return searchValue.length > 0 ? rowItem?.name?.includes(searchValue) : true;
      });
      setFilteredData(data);
      setSelectedRow([]);
    }
  }, [searchValue]);

  return (
    <Grid>
      <ButtonGroup
        sx={{ marginLeft: '30px', marginTop: '10px', height: '35px' }}
        variant="outlined"
        aria-label="outlined button group"
      >
        <Button
          sx={{ width: '110px' }}
          className={activeButton === 0 ? 'active-btn' : undefined}
          onClick={() => {
            setActiveButton(0);
          }}
        >
          <Typography variant={'h3'}>Policies</Typography>
        </Button>
        <Button
          sx={{ width: '120px' }}
          className={activeButton === 1 ? 'active-btn' : undefined}
          onClick={() => {
            setActiveButton(1);
          }}
        >
          <Typography variant={'h3'}>Conditions</Typography>
        </Button>
      </ButtonGroup>
      <Paper
        sx={{
          height: '470px',
          margin: '15px 15px 0px 30px',
          flexGrow: 1,
          overflowY: 'hidden',
          boxShadow: '0px 4px 26px rgba(0, 0, 0, 0.05)',
        }}
      >
        {getAllPermissionsStatus === requestState.loading || getAllConditionsStatus === requestState.loading ? (
          <Stack justifyContent="center" alignItems="center" height="100%">
            <CognisaasSpinner size={50} thickness={3} />
          </Stack>
        ) : (
          <>
            <TableContainer sx={{ maxHeight: '100%' }}>
              <Table stickyHeader={true} aria-labelledby="tableTitle">
                <ListTableHead
                  order={order}
                  setOrder={setOrder}
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                  label={activeButton === 0 ? 'Policies' : 'Conditions'}
                />
                <TableBody>
                  {stableSort(filteredData, order).map((row: any, index: number) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={index}
                        sx={{ cursor: 'pointer' }}
                        onClick={() => {
                          setSelectedRow(row);
                          setDrawerState(true);
                        }}
                      >
                        <TableCell component="th" id={labelId} scope="row" padding="none">
                          <PermissionsCard
                            cardType={activeButton === 0 ? 'Policy' : 'Condition'}
                            backgroundColor={row.id === selectedRow?.id ? '#2D5FF627' : 'white'}
                            permissionType={row.name}
                            boxValue={row?.resource.name}
                            isPublic={row?.is_public}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </Paper>
      <PermissionsDetail
        permissionType={activeButton === 0 ? 'Policy' : 'Condition'}
        selectedPermission={selectedRow}
        drawerState={drawerState}
        onDrawerClose={() => setDrawerState(false)}
      />
    </Grid>
  );
}
