import { useState } from 'react';
import Box from '@mui/material/Box';
import { Avatar, Button, IconButton, Menu, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { DataGridPremium, GridToolbar, gridClasses, useGridApiContext } from '@mui/x-data-grid-premium';
import '../../ClientModule/ClientContacts/ContactsTable.css';
import {
  GridColumnOrderChangeParams,
  GridColumns,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridAlignment,
} from '@mui/x-data-grid';
import { getStatusColor, isNull, isUndefined } from '../../../utils/dataHelpers';
import { Link, useNavigate } from 'react-router-dom';
import StatusBadge from '../../shared/LabeledBadge/LabeledBadge';
import CognisaasSelect from '../CognisaasSelect/CognisaasSelect';
import CognisaasRequirementStatusDropdown from '../CognisaasDropdown/CognisaasRequirementStatusDropdown/CognisaasRequirementStatusDropdown';
import { format } from 'date-fns';
import { ReactComponent as EditSVG } from '../../../assets/svgs/TableEditButton.svg';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  SelectEditDateInputCell,
  SelectEditDropdownCell,
  SelectEditNumericInputCell,
  SelectEditStringInputCell,
  SelectEditDropdownCellUserList,
} from './InlineEditTable';
import { handleInlineEditClickType, inlineEditPropType } from './types';
import CognisaaasTooltip from '../CognisaasTooltip/CognisaasTooltip';
import useCommonAppConfig from 'components/Common/useCommonAppConfig';
import { getMaxColumnWidth } from 'utils/getMaxColumnWidth';
import { getUserNameByUserUid, getUserUid } from 'utils/getUid';
import { getUserActiveState, INACTIVE_USER_COLOR } from 'utils/userHelpers';

const ODD_OPACITY = 0.2;

interface Props {
  data: any[];
  columns: GridColumns<any>;
  hideHeader: boolean;
  hideFooter: boolean;
  pageSize?: number;
  onColumnOrderChange?: (params: GridColumnOrderChangeParams) => void;
  idName?: string;
}

function stringToHslColor(str: string, s: number, l: number) {
  if (str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    const h = hash % 360;
    return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
  }
  return 'red';
}

const parseLines = (value: string) => {
  return value?.replace(/(\\n)/g, '\n');
};

export const linkCell = (
  params: GridRenderEditCellParams<any, any, any>,
  func: (rowData: Record<string, string>) => void
) => {
  return (
    <Typography variant="subtitle1" className="cognisaas-pointer link-text" onClick={() => func(params.row)}>
      {params.row[params.field]}
    </Typography>
  );
};

export const EditableTextCell = (
  params: GridRenderEditCellParams<any, any, any>,
  func: (rowData: Record<string, string>) => void
) => {
  const apiRef = useGridApiContext();
  console.log(params);
  const { id, value, field } = params;
  // const initialValue = value;
  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    apiRef.current.setEditCellValue({ id, field, value: event.target.value as string });
  };
  // console.log('sdsdsd', apiRef.current.unstable_eventManager.events);
  // apiRef.current.unstable_eventManager.on('cellFocusOut', () => {
  //   apiRef.current.setEditCellValue({ id, field, value: initialValue });
  // });

  const handleClick = () => {
    // apiRef.current.stopCellEditMode(params);
    apiRef.current.stopCellEditMode({ id: id, field: field });
    // apiRef.current.stopCellEditMode()
    func(params.row);
  };

  return (
    <>
      {/* check type of editable field */}
      {/* if row is text type */}

      <Stack direction={'row'}>
        <TextField
          sx={{ background: 'white', zIndex: '10' }}
          size={'small'}
          value={value}
          onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleChange(e)}
        />
        <Button
          sx={{
            position: 'relative',
            left: '-10px',
            borderRadius: '0px 5px 5px 0px',
            minWidth: 50,
            padding: '0px 0px 0px 10px',
            border: '1px solid rgb(79, 159, 82)',
            background: 'rgb(189, 242, 191)',
            '&.MuiButtonBase-root:hover': {
              bgcolor: 'rgb(189, 242, 191)',
            },
          }}
          onClick={handleClick}
          variant={'contained'}
          disableElevation
        >
          <EditSVG />
        </Button>
      </Stack>
    </>
  );
};

export const EditableDropdown = (
  params: GridRenderEditCellParams<any, any, any>,
  validValues: { id: string; value: string }[],
  func: (rowData: Record<string, string>, updated_data_key: string, updated_value: string) => void,
  dropdownType: 'requirement' | 'task' | 'asItIs'
) => {
  const [dropDownValue, setDropDownValue] = useState<string>(params.value);

  return (
    <>
      {dropdownType === 'requirement' && (
        <CognisaasRequirementStatusDropdown
          sx={{ width: '210px' }}
          value={dropDownValue}
          changeHandler={(e) => {
            setDropDownValue(e.target.value);
            func(params.row, params.field, e.target.value);
          }}
        />
      )}

      {dropdownType === 'task' && (
        <CognisaasSelect
          value={dropDownValue}
          validValues={validValues.map((item: any) => item.value)}
          onChange={(e: any) => {
            setDropDownValue(e.target.value);
            func(params.row, params.field, e.target.value);
          }}
        />
      )}

      {dropdownType === 'asItIs' && (
        <CognisaasSelect
          value={dropDownValue}
          validValues={validValues.map((item: any) => item.value)}
          onChange={(e: any) => {
            setDropDownValue(e.target.value);
            func(params.row, params.field, e.target.value);
          }}
        />
      )}
    </>
  );
};

export const AnyOneSeparatedBy$Cell = (params: GridRenderEditCellParams<any, any, any>) => {
  // console.log(params.field, params.row);
  const fields = params.field.split('$');
  const navigate = useNavigate();

  let firstCorrectFieldKey = '';
  fields.forEach((item) => {
    if (!isUndefined(params.row[item])) {
      firstCorrectFieldKey = item;
      return;
    }
  });
  if (firstCorrectFieldKey.trim() === '') {
    return '';
  } else {
    // console.log(firstCorrectFieldKey);

    // console.log(params.row[firstCorrectFieldKey]);
    const linkFn =
      params?.row?.association_uid && params?.row?.project_uid
        ? () => navigate(`/projects/project/${params.row.association_uid}/${params.row.project_uid}`)
        : () => null;
    return (
      <Typography variant="subtitle1" className="cognisaas-pointer" sx={{ color: '#2D60F6' }} onClick={linkFn}>
        {params.row[firstCorrectFieldKey]}
      </Typography>
    );
  }
};

export const StatusCell = (params: GridRenderEditCellParams<any, any, any>) => {
  return (
    <Stack>
      <Stack spacing={1} direction="row">
        <span
          style={{ marginRight: '4px' }}
          className={
            params.value == 'red'
              ? 'red_status_box_task'
              : params.value == 'amber'
              ? 'amber_status_box_task'
              : params.value == 'green'
              ? 'green_status_box_task'
              : params.value == 'blue'
              ? 'blue_status_box_task'
              : ''
          }
        ></span>
        {params.value == 'red'
          ? 'Overdue'
          : params.value == 'amber'
          ? 'At Risk'
          : params.value == 'green'
          ? 'On Track'
          : params.value == 'blue'
          ? 'Completed'
          : ''}
      </Stack>
    </Stack>
  );
};

export const CostCell = (params: GridRenderEditCellParams<any, any, any>) => {
  return params.value ? params.value : 0;
};

export const AvatarCell = (params: GridRenderEditCellParams<any, any, any>) => {
  return (
    <Stack direction="row">
      <Avatar style={{ height: '34px', width: '34px', marginTop: '3px' }} {...stringAvatar(params.value)} />

      {/* <Avatar>{params.value ? params.value.slice(0, 1) : 'no name'}</Avatar> */}
      <Typography m={1} variant="subtitle1">
        {params.value}
      </Typography>
    </Stack>
  );
};

export const CustomFieldCell = (params: GridRenderEditCellParams<any, any, any>) => {
  console.log('CUStome values');
  console.log({ params });
  return (
    <Typography variant="subtitle2">
      {!isUndefined(params.value) && !isNull(params.value) ? params.value.split('#$#')[0] : ''}
      {/* {params.value.split('#$#')[0]} */}
    </Typography>
  );
};

export const FieldWithStatus = (
  params: GridRenderEditCellParams<any, any, any>,
  fieldDataKey: string,
  fieldStatusKey: string
) => {
  const dataKeyForName = fieldDataKey;
  const dataKeyForStatus = fieldStatusKey;
  let statusName = '';
  switch (params.row[dataKeyForStatus]) {
    case 'red':
      statusName = 'Overdue';
      break;
    case 'amber':
      statusName = 'At Risk';
      break;
    case 'green':
      statusName = 'On Track';
      break;
    case 'blue':
      statusName = 'Completed';
      break;
    default:
      statusName = 'Unknown';
  }

  return (
    <Typography variant="subtitle2" className="cognisaas-pointer link-text">
      <Link style={{ textDecoration: 'none', color: '#2D60F6' }} to="#">
        {params.row[dataKeyForName]}
      </Link>
      <StatusBadge
        statusColor={getStatusColor(params.row[dataKeyForStatus])}
        textColor="#7A7A7A"
        text={statusName}
        isActive={false}
      />
    </Typography>
  );
};

export const AssignCell = (
  params: GridRenderEditCellParams<any, any, any>,
  menuOptions: { name: string; availabilityPercent: string }[],
  func: (rowData: Record<string, string>, newDeliveryManager: string) => void
) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    // console.log(e.target.value);
  };

  return (
    <div>
      <Typography
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className="link-text cognisaas-pointer"
      >
        Assign
      </Typography>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            maxHeight: '167px',
            padding: '0px',
            borderRadius: '4px',
            boxShadow: '0px 4px 26px rgba(0, 0, 0, 0.05)',
          },
        }}
      >
        {menuOptions.map((item, index) => {
          return (
            <MenuItem
              sx={index !== menuOptions.length - 1 ? { borderBottom: '1px solid #E5E5E5' } : {}}
              dense={true}
              key={index}
              onClick={() => {
                handleClose();
                func(params.row, item.name);
              }}
            >
              <Stack sx={{ minWidth: '216px' }}>
                <Typography variant="subtitle1">{item.name}</Typography>
                <Stack direction={'row'} justifyContent="space-between">
                  <Typography variant="subtitle2" className="label-text">
                    Availability
                  </Typography>
                  <Typography variant="subtitle2" className="label-text">
                    {item.availabilityPercent}%
                  </Typography>
                </Stack>
              </Stack>
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

export const MenuCell = (
  params: any,
  menuOptions: string[],
  func: (params: GridRenderCellParams, action: string) => void
) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: '160px',
            width: '20ch',
          },
        }}
      >
        {menuOptions.map((item) => (
          <MenuItem
            key={item}
            dense={true}
            onClick={() => {
              func(params, item);
              handleClose();
            }}
          >
            {item}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

const generateUniqueId = (): string => {
  return Date.now().toString(36) + Math.floor(Math.pow(10, 12) + Math.random() * 9 * Math.pow(10, 12)).toString(36);
};

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToHslColor(name, 30, 80),
    },
    children: (
      <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#000000' }}>
        {name ? name.split(' ')[0][0] : ''}
        {name ? (name.indexOf(' ') >= 0 ? name.split(' ')[1][0] : null) : null}
      </Typography>
    ),
  };
}

const StripedDataGrid = styled(DataGridPremium)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    border: 'none',
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
        },
      },
    },
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  border: 'none',
  flexDirection: 'column',
  // height: 600,
  width: '100%',
  '& .MuiFormGroup-options': {
    alignItems: 'center',
    paddingBottom: theme.spacing(1),
    '& > div': {
      minWidth: 100,
      margin: theme.spacing(2),
      marginLeft: 0,
    },
    border: 'none',
  },
}));

export const generateTableColumnsWithCustomFiltersMapped = (
  sampleData: Record<string, any>,
  hardCodedColumns: GridColumns<any>,
  type: inlineEditPropType,
  customFields: any[],
  handleInlineEditValue: handleInlineEditClickType,
  companyUsers?: any
): GridColumns<any> => {
  // Note : this function needs that you have already mapped custom fields with function in dataHelpers.ts file
  // like this name#$#6263d1390810b$#$String: "asd" => cfName#$#cfUid$#$cfTypeInUnderscoreFormat : cfValue
  let generatedColumns: GridColumns<any> = [];
  generatedColumns = [...hardCodedColumns];
  // const customColumns: any = [];
  let data = Object.keys(sampleData);
  data = data.filter((item: any) => {
    if (item.split('#$#').length > 1) {
      return item;
    }
  });
  for (let i = 0; i < data.length; i++) {
    if (data[i].split('#$#').length > 1) {
      const column = {
        headerName: data[i].split('#$#')[0],
        field: data[i],
        width:
          data[i].split('#$#')[1].split('$#$')[1].toLowerCase() === 'dropdown'
            ? getMaxColumnWidth(customFields[i].valid_values.split(','))
            : data[i].split('#$#')[1].split('$#$')[1].toLowerCase() === 'rag status'
            ? getMaxColumnWidth(customFields[i].valid_values.split(','))
            : 240,
        editable: true,
        headerAlign: 'left' as GridAlignment,
        align: 'left' as GridAlignment,
        type:
          data[i].split('#$#')[1].split('$#$')[1].toLowerCase() === 'dropdown'
            ? 'string'
            : data[i].split('#$#')[1].split('$#$')[1].toLowerCase() === 'date'
            ? 'date'
            : data[i].split('#$#')[1].split('$#$')[1].toLowerCase(),
        // valueGetter:
        //   data[i].split('#$#')[1].split('$#$')[1].toLowerCase() === 'date'
        //     ? ({ value }: any) => value && new Date(value)
        //     : undefined,
        renderCell: (params: GridRenderCellParams) => {
          if (params.row.custom_values[i]?.field_type === 'RAG status') {
            return (
              <Stack>
                <Stack spacing={1} direction="row">
                  <span
                    style={{ marginRight: '4px' }}
                    className={
                      params.row.custom_values[i].field_value == 'Red'
                        ? 'red_status_box_task'
                        : params.row.custom_values[i].field_value == 'Amber'
                        ? 'amber_status_box_task'
                        : params.row.custom_values[i].field_value == 'Green'
                        ? 'green_status_box_task'
                        : ''
                    }
                  ></span>
                  {params.row[data[i]] ? params.row[data[i]] : 'None'}
                </Stack>
              </Stack>
            );
          }
          if (params.row.custom_values.length > 0 && params.row.custom_values[i]?.field_type === 'Hyperlink') {
            return (
              <CognisaaasTooltip
                title={
                  <Box sx={{ padding: '12px' }}>
                    <Typography variant="subtitle1">
                      <a href={params.row.custom_values[i].field_value} target="_blank" rel="noreferrer">
                        {params.row.custom_values[i]?.field_value}
                      </a>
                    </Typography>
                  </Box>
                }
              >
                <Stack style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                  <Typography variant="subtitle1">
                    <a href={params.row.custom_values[i].field_value} target="_blank" rel="noreferrer">
                      {params.row.custom_values[i]?.field_value}
                    </a>
                  </Typography>
                </Stack>
              </CognisaaasTooltip>
            );
          }
          if (params.row.custom_values && params.row.custom_values[i]?.field_type === 'String') {
            return (
              <CognisaaasTooltip
                title={
                  <Box sx={{ padding: '12px', maxHeight: '140px', overflow: 'scroll' }}>
                    <Typography style={{ whiteSpace: 'pre-line' }} variant="subtitle1">
                      {' '}
                      {parseLines(params.row.custom_values[i]?.field_value)}
                    </Typography>
                  </Box>
                }
              >
                <Stack style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                  <Typography variant="subtitle1"> {parseLines(params.row.custom_values[i]?.field_value)}</Typography>
                </Stack>
              </CognisaaasTooltip>
            );
          }
          if (params.row.custom_values && params.row.custom_values[i]?.field_type.toLowerCase() === 'user list') {
            return (
              <CognisaaasTooltip
                title={
                  <Box sx={{ padding: '12px', maxHeight: '140px', overflow: 'scroll' }}>
                    <Typography style={{ whiteSpace: 'pre-line' }} variant="subtitle1">
                      {getUserNameByUserUid(params.row.custom_values[i]?.field_value)}
                    </Typography>
                  </Box>
                }
              >
                <Stack style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                  {getUserActiveState(getUserUid(params.value), companyUsers) === '0' ? (
                    <Typography style={{ color: INACTIVE_USER_COLOR }} mt={1} variant="subtitle1">
                      {getUserNameByUserUid(params.row.custom_values[i]?.field_value)}&nbsp;(Inactive)
                    </Typography>
                  ) : (
                    <Typography mt={1} variant="subtitle1">
                      {getUserNameByUserUid(params.row.custom_values[i]?.field_value)}
                    </Typography>
                  )}
                </Stack>
              </CognisaaasTooltip>
            );
          }
          if (params.row.custom_values && params.row.custom_values[i]?.field_type === 'Date') {
            return params.row.custom_values[i]?.field_value == '0000-00-00 00:00:00' ||
              !params.row.custom_values[i]?.field_value ? (
              <Typography variant="subtitle1">-</Typography>
            ) : (
              <Typography variant="subtitle1">
                {format(new Date(params.row.custom_values[i]?.field_value.replace(/-/g, '/')), 'dd MMM yyyy')}
              </Typography>
            );

            // <ViewDate date={params.row.params.row.custom_values[i]?.field_value} />;
          }
          return (
            <Stack style={{ width: '100%', overflowWrap: 'break-word' }}>
              <Typography variant="subtitle1">
                {' '}
                {params.row.custom_values.length > 0 &&
                params.row.custom_values[i]?.field_type === 'Date' &&
                params.row.custom_values[i]?.field_value.length > 0
                  ? format(new Date(params.row.custom_values[i]?.field_value.replace(/-/g, '/')), 'dd MMM yyyy')
                  : params.row.custom_values[i]?.field_value}
              </Typography>
            </Stack>
          );
        },
        renderEditCell: (params: GridRenderEditCellParams) => {
          if (params.row.custom_values && params.row.custom_values[i]?.field_type === 'Dropdown') {
            let customField = [];
            if (customFields) {
              customField = customFields?.find((field: any) => field?.id === params.row.custom_values[i]?.id);
            }

            return SelectEditDropdownCell(
              { ...params, indexofCustomField: i, customfield_value: params.row.custom_values[i].field_value },
              customField?.valid_values.length > 0 ? customField?.valid_values.split(',') : [],
              handleInlineEditValue,
              type
            );
          } else if (params.row.custom_values && params.row.custom_values[i]?.field_type === 'User List') {
            return SelectEditDropdownCellUserList(
              { ...params, indexofCustomField: i, customfield_value: params.row.custom_values[i].field_value },
              companyUsers,
              handleInlineEditValue,
              type
            );
          } else if (params.row.custom_values && params.row.custom_values[i]?.field_type === 'RAG status') {
            return SelectEditDropdownCell(
              { ...params, indexofCustomField: i, customfield_value: params.row.custom_values[i].field_value },
              ['Red', 'Amber', 'Green', 'None'],
              handleInlineEditValue,
              type
            );
          } else if (
            (params.row.custom_values && params.row.custom_values[i]?.field_type === 'String') ||
            (params.row.custom_values && params.row.custom_values[i]?.field_type === 'Hyperlink')
          ) {
            return SelectEditStringInputCell({ ...params, indexofCustomField: i }, handleInlineEditValue, type);
          } else if (params.row.custom_values && params.row.custom_values[i]?.field_type === 'Date') {
            return SelectEditDateInputCell({ ...params, indexofCustomField: i }, handleInlineEditValue, type);
          } else if (params.row.custom_values && params.row.custom_values[i]?.field_type === 'Number') {
            return SelectEditNumericInputCell({ ...params, indexofCustomField: i }, handleInlineEditValue, type);
          }
        },
      };
      generatedColumns.push(column);
    }
  }

  return generatedColumns;
};

export const generateClientTableColumnsWithCustomFieldsMapped = (
  data: Record<string, any>,
  hardCodedColumns: GridColumns<any>,
  type: inlineEditPropType,
  customFields: any[],
  handleInlineEditValue: handleInlineEditClickType,
  companyUsers?: any
): GridColumns<any> => {
  // Note : this function needs that you have already mapped custom fields with function in dataHelpers.ts file
  // like this name#$#6263d1390810b$#$String: "asd" => cfName#$#cfUid$#$cfTypeInUnderscoreFormat : cfValue
  let generatedColumns: GridColumns<any> = [];
  generatedColumns = [...hardCodedColumns];
  // const customColumns: any = [];
  for (let i = 0; i < data.length; i++) {
    console.log('XX:', data[i]);
    const column = {
      headerName: data[i].config.label,
      field: data[i].config.label,
      width:
        data[i].config.data_type.toLowerCase() === 'dropdown'
          ? getMaxColumnWidth(data[i].config?.valid_values.split(','))
          : data[i].config.data_type.toLowerCase() === 'rag status'
          ? getMaxColumnWidth(data[i].config?.valid_values.split(','))
          : 240,
      editable: true,
      headerAlign: 'left' as GridAlignment,
      align: 'left' as GridAlignment,
      type:
        data[i].config.data_type.toLowerCase() === 'dropdown'
          ? 'string'
          : data[i].config.data_type.toLowerCase() === 'date'
          ? 'date'
          : data[i].config.data_type.toLowerCase(),
      renderCell: (params: GridRenderCellParams) => {
        if (params.row.custom_fields[i]?.config.data_type === 'RAG status') {
          return (
            <Stack>
              <Stack spacing={1} direction="row">
                <span
                  style={{ marginRight: '4px' }}
                  className={
                    params.row.custom_values[i].value == 'RED'
                      ? 'red_status_box_task'
                      : params.row.custom_values[i].value == 'AMBER'
                      ? 'amber_status_box_task'
                      : params.row.custom_values[i].value == 'GREEN'
                      ? 'green_status_box_task'
                      : ''
                  }
                ></span>
                {params.row[data[i]] ? params.row[data[i]] : 'None'}
              </Stack>
            </Stack>
          );
        }
        if (params.row.custom_fields.length > 0 && params.row.custom_fields[i]?.config.data_type === 'Hyperlink') {
          return (
            <CognisaaasTooltip
              title={
                <Box sx={{ padding: '12px' }}>
                  <Typography variant="subtitle1">
                    <a href={params.row.custom_fields[i].value} target="_blank" rel="noreferrer">
                      {params.row.custom_fields[i]?.value}
                    </a>
                  </Typography>
                </Box>
              }
            >
              <Stack style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                <Typography variant="subtitle1">
                  <a href={params.row.custom_fields[i].value} target="_blank" rel="noreferrer">
                    {params.row.custom_fields[i]?.value}
                  </a>
                </Typography>
              </Stack>
            </CognisaaasTooltip>
          );
        }
        if (params.row.custom_fields && params.row.custom_fields[i]?.config.data_type === 'String') {
          return (
            <CognisaaasTooltip
              title={
                <Box sx={{ padding: '12px', maxHeight: '140px', overflow: 'scroll' }}>
                  <Typography style={{ whiteSpace: 'pre-line' }} variant="subtitle1">
                    {' '}
                    {parseLines(params.row.custom_fields[i]?.value)}
                  </Typography>
                </Box>
              }
            >
              <Stack style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                <Typography variant="subtitle1"> {parseLines(params.row.custom_fields[i]?.value)}</Typography>
              </Stack>
            </CognisaaasTooltip>
          );
        }
        if (params.row.custom_fields && params.row.custom_fields[i]?.config.data_type.toLowerCase() === 'user list') {
          return (
            <CognisaaasTooltip
              title={
                <Box sx={{ padding: '12px', maxHeight: '140px', overflow: 'scroll' }}>
                  <Typography style={{ whiteSpace: 'pre-line' }} variant="subtitle1">
                    {getUserNameByUserUid(params.row.custom_fields[i]?.value)}
                  </Typography>
                </Box>
              }
            >
              <Stack style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                {getUserActiveState(getUserUid(params.value), companyUsers) === '0' ? (
                  <Typography style={{ color: INACTIVE_USER_COLOR }} mt={1} variant="subtitle1">
                    {getUserNameByUserUid(params.row.custom_fields[i]?.value)}&nbsp;(Inactive)
                  </Typography>
                ) : (
                  <Typography mt={1} variant="subtitle1">
                    {getUserNameByUserUid(params.row.custom_fields[i]?.value)}
                  </Typography>
                )}
              </Stack>
            </CognisaaasTooltip>
          );
        }
        if (params.row.custom_fields && params.row.custom_fields[i]?.config.data_type === 'Date') {
          return params.row.custom_fields[i]?.value == '0000-00-00 00:00:00' || !params.row.custom_fields[i]?.value ? (
            <Typography variant="subtitle1">-</Typography>
          ) : (
            <Typography variant="subtitle1">
              {format(new Date(params.row.custom_fields[i]?.value.replace(/-/g, '/')), 'dd MMM yyyy')}
            </Typography>
          );

          // <ViewDate date={params.row.params.row.custom_fields[i]?.value} />;
        }
        return (
          <Stack style={{ width: '100%', overflowWrap: 'break-word' }}>
            <Typography variant="subtitle1">
              {' '}
              {params.row.custom_fields.length > 0 &&
              params.row.custom_fields[i]?.config.data_type === 'Date' &&
              params.row.custom_fields[i]?.value.length > 0
                ? format(new Date(params.row.custom_fields[i]?.value.replace(/-/g, '/')), 'dd MMM yyyy')
                : params.row.custom_fields[i]?.value}
            </Typography>
          </Stack>
        );
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        console.log('NEof:', params.row.custom_fields[i]);
        if (params.row.custom_fields && params.row.custom_fields[i]?.config.data_type === 'Dropdown') {
          console.log('Data type:', params.row.custom_fields[i]?.config.data_type);
          // eslint-disable-next-line quotes
          const valid_values = eval(params.row.custom_fields[i]?.config.valid_values);
          return SelectEditDropdownCell(
            { ...params, indexofCustomField: i, customfield_value: params.row.custom_fields[i].value },
            valid_values,
            handleInlineEditValue,
            type
          );
        } else if (params.row.custom_fields && params.row.custom_fields[i]?.data_type === 'User List') {
          return SelectEditDropdownCellUserList(
            { ...params, indexofCustomField: i, customfield_value: params.row.custom_fields[i].value },
            companyUsers,
            handleInlineEditValue,
            type
          );
        } else if (params.row.custom_fields && params.row.custom_fields[i]?.data_type === 'RAG status') {
          return SelectEditDropdownCell(
            { ...params, indexofCustomField: i, customfield_value: params.row.custom_fields[i].value },
            ['Red', 'Amber', 'Green', 'None'],
            handleInlineEditValue,
            type
          );
        } else if (
          (params.row.custom_fields && params.row.custom_fields[i]?.data_type === 'String') ||
          (params.row.custom_fields && params.row.custom_fields[i]?.data_type === 'Hyperlink')
        ) {
          return SelectEditStringInputCell({ ...params, indexofCustomField: i }, handleInlineEditValue, type);
        } else if (params.row.custom_fields && params.row.custom_fields[i]?.data_type === 'Date') {
          return SelectEditDateInputCell({ ...params, indexofCustomField: i }, handleInlineEditValue, type);
        } else if (params.row.custom_fields && params.row.custom_fields[i]?.data_type === 'Number') {
          return SelectEditNumericInputCell({ ...params, indexofCustomField: i }, handleInlineEditValue, type);
        }
      },
    };
    generatedColumns.push(column);
  }

  return generatedColumns;
};

const CogniTable = (props: Props) => {
  console.log(props, 'props');
  console.log(props.columns);
  // const [pageSize, setPageSize] = useState<number>(props.pageSize ? props.pageSize : 5);

  let myTableState: any = {};
  const { saveAppTableState, getTableState } = useCommonAppConfig();
  const initialState: any = {
    columns: {
      columnVisibilityModel: {},
    },
    pagination: {
      page: 0,
      pageSize: props.pageSize,
    },
    ...getTableState(props.idName ? props.idName : 'default'),
  };

  const handleTableSave = (idName: string) => {
    saveAppTableState(idName, myTableState);
  };

  props.columns.forEach((item: any) => {
    if (item.hide) {
      initialState.columns.columnVisibilityModel[item.field] = false;
    }
  });

  const rows = [...props.data.map((item) => ({ ...item, cognisaas_unique_id: generateUniqueId() }))];

  const columns = [...props.columns];

  const data = {
    columns,
    rows,
  };

  return (
    <StyledBox>
      <StripedDataGrid
        hideFooter={props.hideFooter}
        autoHeight
        sx={{
          boxShadow: 0,
          border: 0,
          borderColor: 'primary.light',
          '& .MuiDataGrid-cell:hover': {
            color: 'primary.main',
          },
        }}
        {...data}
        initialState={initialState}
        onStateChange={(tblState: any) => {
          myTableState = tblState;
        }}
        onColumnVisibilityModelChange={() => {
          handleTableSave(props.idName ? props.idName : 'default');
        }}
        onFilterModelChange={() => {
          handleTableSave(props.idName ? props.idName : 'default');
        }}
        onPageSizeChange={() => {
          handleTableSave(props.idName ? props.idName : 'default');
        }}
        components={{
          Toolbar: (() => {
            if (props.hideHeader) {
              return null;
            } else {
              return GridToolbar;
            }
          })(),
          // Footer: CustomPagination,
        }}
        componentsProps={{
          toolbar: { showQuickFilter: true },
        }}
        loading={false}
        // checkboxSelection
        getRowId={(row) => row['cognisaas_unique_id']}
        disableSelectionOnClick
        rowThreshold={0}
        // initialState={{
        //   ...data.initialState,
        //   pinnedColumns: { left: ['contact_name'] },
        // }}
        experimentalFeatures={{ newEditingApi: true }}
        //{...pagination}
        // pageSize={pageSize}
        // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        pagination
        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'odd' : 'odd')}
        onColumnOrderChange={props.onColumnOrderChange}
      />
    </StyledBox>
  );
};

export default CogniTable;
