import React, { useEffect, useState } from 'react';
import { BarComponent } from 'components/ResourceManagement/types/bar-component';
import { GanttContentMoveAction } from 'components/ResourceManagement/types/gantt-component-actions';
import { ProgressBar } from 'components/ResourceManagement/components/bar/progress';
import { SimpleBar } from 'components/ResourceManagement/components/bar/simple';
import { SoftAllocationBar } from './soft-allocation';

export type BarProps = {
  component: BarComponent;
  componentHeight: number;
  isSelected: boolean;
  mode: string,
  barCornerRadius: number;
  onEventStart: (
    action: GanttContentMoveAction,
    selectedComponent: BarComponent,
    event?: React.MouseEvent | React.KeyboardEvent
  ) => any;
};

export const Bar: React.FC<BarProps> = props => {
  const {
    component,
    isSelected,
    barCornerRadius,
    onEventStart,
  } = {
    ...props,
  };
  // console.log('Bar', { component });
  const [componentItem, setComponentItem] = useState<JSX.Element>(<div />);

  useEffect(() => {
    switch (component.entity) {
      case 'resource':
        setComponentItem(<SimpleBar {...props} />);
        break;
      case 'soft':
        setComponentItem(<SoftAllocationBar {...props} />);
        break;
      case 'project':
        if (component.type === 'resource') {
          setComponentItem(<SimpleBar {...props} barCornerRadius={barCornerRadius} />);
        } else {
          setComponentItem(<ProgressBar {...props} barCornerRadius={barCornerRadius} />);
        }
        break;
      case 'task':
        setComponentItem(<ProgressBar {...props} barCornerRadius={barCornerRadius} />);
        break;
    }
  }, [component, isSelected]);

  return (
    <g
      onKeyDown={e => {
        e.stopPropagation();
      }}
      onMouseEnter={e => {
        onEventStart('mouseenter', component, e);
      }}
      onMouseLeave={e => {
        onEventStart('mouseleave', component, e);
      }}
      onFocus={() => {
        onEventStart('select', component);
      }}
    >
      {componentItem}
    </g>
  );
};
