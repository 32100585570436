import { Stack, Typography, TextField } from '@mui/material';
import CognisaasButton from 'components/shared/CognisaasButton/CognisaasButton';
import { useState } from 'react';

interface Props {
  addPhaseHandler: any;
  templateUid: string;
  closeModal: () => void;
}

const AddPhase = (props: Props) => {
  const [phaseName, setPhaseName] = useState<string>('');
  const [disableSave, setDisableSave] = useState<boolean>(true);

  const validateName = (name: string) => {
    if (name.trim() === '') {
      setDisableSave(true);
    } else {
      setDisableSave(false);
    }
  };

  return (
    <>
      <Stack>
        <Stack my={2}>
          <Typography variant="h2">Create Phase</Typography>
        </Stack>
        <Stack direction="row">
          <TextField
            autoComplete=""
            autoFocus={true}
            onChange={(e) => {
              setPhaseName(e.target.value);
              validateName(e.target.value);
            }}
            style={{ width: '410px' }}
            size="small"
            placeholder="Enter phase name"
          ></TextField>
        </Stack>
        <Stack mt={3} spacing={2} justifyContent="flex-end" direction="row">
          <CognisaasButton isOutlined={true} clickHandler={() => props.closeModal()} label="Cancel" />
          <CognisaasButton
            clickHandler={() => {
              props.addPhaseHandler(phaseName);
              props.closeModal();
            }}
            isOutlined={false}
            disabled={disableSave}
            label="Save"
          />
        </Stack>
      </Stack>
    </>
  );
};
export default AddPhase;
