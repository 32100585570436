import { Select, FormControl, MenuItem, SelectProps, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ragStatusColor } from '../../../utils/designSystemColors';

interface Props extends SelectProps {
  type?: 'rag' | 'dropdown';
  placeholder?: any;
  validvalues?: any[];
  helperText?: string;
  size?: 'small';
  readOnly?: boolean;
  width?: string;
  isSmall?: boolean | undefined;
  value:any;
  identifier?:string;
}

// valid values are actually options


const CognisaasDropdown = (props: Props) => {
  const { placeholder, validvalues, helperText } = props;
  const propsForMuiSelectElement = { ...props };
  delete propsForMuiSelectElement.helperText;
  delete propsForMuiSelectElement.validvalues;
  delete propsForMuiSelectElement.placeholder;
  delete propsForMuiSelectElement.type;
  delete propsForMuiSelectElement.isSmall;
  const identifier = props.identifier || 'name'; 
  console.log({identifier});
  return (
    <FormControl sx={{ width: props?.width }} size="small">
      <Select
        {...propsForMuiSelectElement}
        value={props.value}
        displayEmpty
        size="small"
        disabled={props.readOnly ? props.readOnly : false}
        inputProps={{ 'aria-label': 'Without label' }}
        sx={{ width: '200px' }}
        MenuProps={{
          sx: {
            maxHeight: '230px',
          },
        }}
      >
        <MenuItem sx={{ display: 'none' }} value="">
          {placeholder ? placeholder : 'Select'}
        </MenuItem>

        {validvalues?.map((option, index) => {
          if (typeof option !== 'undefined') {
            console.log({option});
            console.log(option[identifier]);
            return (
              <MenuItem key={index} value={option}>
                {/* show below color if it is an rag type */}
                <Box
                  component="span"
                  sx={{
                    backgroundColor: ragStatusColor[option],
                    display: 'inline-block',
                    width: '8px',
                    height: '8px',
                    borderRadius: '60%',
                    marginRight: '8px',
                  }}
                ></Box>
                {option[identifier]}
              </MenuItem>
            );
          }
        })}

        {props?.children}
      </Select>
      {helperText ? (
        <Typography sx={{ color: '#D32F2F', margin: '4px 14px 0px' }} variant="caption" component="span">
          {helperText}
        </Typography>
      ) : (
        ''
      )}
    </FormControl>
  );
};

export default CognisaasDropdown;
