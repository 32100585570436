import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { clientAPI } from '../../../../api/modules/client';
import { pictureApi } from 'api/modules/picture';
import { requestState } from '../../types';
import { commonAPI, clientListRequiredApis } from '../../../../api/modules/common';
import { customFieldsAPI } from '../../../../api/modules/customFields';
import { UpdateArrVisibleOnClientListPayload } from './types';
interface clientListType {
  clientList: Array<any>;
  clientListTableView: boolean;
  status: requestState;
  error: null | undefined | string;
  updateStatus: requestState;
  updateError: null | undefined | string;
  clientFullInformation: Array<any>;
  getClientInfoStatus: requestState;
  getClientInfoError: null | undefined | string;
  getClientContactStatus: requestState;
  clientContacts: Array<any>;
  getClientContactsError: null | undefined | string;
  addClientContactStatus: requestState;
  addClientContactError: null | undefined | string;
  updateClientContactStatus: requestState;
  updateClientContactError: null | undefined | string;
  clientContactUid: string;
  clientContactErrorMessage: string;

  clientCustomFields: Array<any>;
  getClientCustomFieldsStatus: requestState;
  getClientCustomFieldsError: null | undefined | string;

  updateClientRenewalStatus: requestState;
  updateClientRenewalError: null | undefined | string;

  addCustomerClientLogoStatus: requestState;
  addCustomerClientLogoError: null | undefined | string;

  clientDetail: any;
  getClientDetailStatus: requestState;
  getClientDetailError: null | undefined | string;

  deleteClientStatus: requestState;

  clientCustomFieldsWithValues: any;
  getClientCustomFieldsWithValuesStatus: requestState;
  getClientCustomFieldsWithValuesError: null | undefined | string;

  clientWhitelistDomains: Array<any>;
  getClientWhitelistDomainsStatus: requestState;
  getClientWhitelistDomainsError: null | undefined | string;

  addClientWhitelistDomainStatus: requestState;
  addClientWhitelistDomainError: null | undefined | string;

  updateClientCustomFieldsStatus: requestState;
  updateClientCustomFieldsError: null | undefined | string;

  getCustomerDetail: any;
  getCustomerDetailStatus: requestState;
  getCustomerDetailError: null | undefined | string;

  clientUidOfSelectedProject: any;
  getClientUidByAssociationUidStaus: requestState;
  getClientUidByAssociationUidError: null | undefined | string;

  getClientDetailByUid: any;
  getClientDetailStatusByUid: requestState;
  getClientDetailErrorByUid: null | undefined | string;

  getClientAudit: any;
  getClientAuditStatus: requestState;
  getClientAuditError: null | undefined | string;

  getVerifiedClientContacts: any;
  getVerifiedClientContactsStatus: requestState;
  getVerifiedClientContactsError: null | undefined | string;

  getProjectInformationWithTaskClientReport: any;
  getProjectInformationWithTaskClientReportStatus: requestState;
  getProjectInformationWithTaskClientReportError: null | undefined | string;

  getProjecWithClientUid: any;
  getProjecWithClientUidStatus: requestState;
  getProjecWithClientUidError: null | undefined | string;

  sendReportToAllClientContacts: any;
  sendReportToAllClientContactsStatus: requestState;
  sendReportToAllClientContactsError: null | undefined | string;

  sendDynamicReportToClientContact: any;
  sendDynamicReportToClientContactStatus: requestState;
  sendDynamicReportToClientContactError: null | undefined | string;
  userDetail: any;

  clientContactinformation: {
    data: any;
    status: requestState;
    error: null | undefined | string;
  };

  clientSettingsInfo: {
    data: any;
    status: requestState;
    error: null | undefined | string;
  };

  deleteClientContactStatus: requestState;

  totaltrailUnderClient: {
    data: any;
    status: requestState;
    error: null | undefined | string;
  };
  renewalTaskOfClient: {
    data: any;
    status: requestState;
    error: null | undefined | string;
  };
  donerenewalTaskOfClient: {
    data: any;
    status: requestState;
    error: null | undefined | string;
  };
  renewalTaskOfClientOwner: {
    data: any;
    status: requestState;
    error: null | undefined | string;
  };

  getClientAccessListMembers: any[];
  getClientAccessListMembersStatus: requestState;
  getClientAccessListMembersError: null | undefined | string;
}

const initialState: clientListType = {
  clientList: [],
  clientListTableView: true,
  status: requestState.idle,
  error: null,
  updateStatus: requestState.idle,
  updateError: null,

  clientFullInformation: [],
  getClientInfoStatus: requestState.idle,
  getClientInfoError: null,

  getClientContactStatus: requestState.idle,
  clientContacts: [],
  getClientContactsError: null,

  addClientContactStatus: requestState.idle,
  clientContactUid: '',
  clientContactErrorMessage: '',
  addClientContactError: null,

  updateClientContactStatus: requestState.idle,
  updateClientContactError: null,

  addCustomerClientLogoStatus: requestState.idle,
  addCustomerClientLogoError: null,

  clientCustomFields: [],
  getClientCustomFieldsStatus: requestState.idle,
  getClientCustomFieldsError: null,

  updateClientRenewalStatus: requestState.idle,
  updateClientRenewalError: null,

  clientDetail: {},
  getClientDetailStatus: requestState.idle,
  getClientDetailError: null,

  deleteClientStatus: requestState.idle,

  clientCustomFieldsWithValues: [],
  getClientCustomFieldsWithValuesStatus: requestState.idle,
  getClientCustomFieldsWithValuesError: null,

  clientWhitelistDomains: [],
  getClientWhitelistDomainsStatus: requestState.idle,
  getClientWhitelistDomainsError: null,

  addClientWhitelistDomainStatus: requestState.idle,
  addClientWhitelistDomainError: null,

  updateClientCustomFieldsStatus: requestState.idle,
  updateClientCustomFieldsError: null,

  getCustomerDetail: {},
  getCustomerDetailStatus: requestState.idle,
  getCustomerDetailError: null,

  clientUidOfSelectedProject: null,
  getClientUidByAssociationUidStaus: requestState.idle,
  getClientUidByAssociationUidError: null,

  getClientDetailByUid: null,
  getClientDetailStatusByUid: requestState.idle,
  getClientDetailErrorByUid: null,

  getClientAudit: [],
  getClientAuditStatus: requestState.idle,
  getClientAuditError: null,

  getVerifiedClientContacts: [],
  getVerifiedClientContactsStatus: requestState.idle,
  getVerifiedClientContactsError: null,

  getProjectInformationWithTaskClientReport: [],
  getProjectInformationWithTaskClientReportStatus: requestState.idle,
  getProjectInformationWithTaskClientReportError: null,

  getProjecWithClientUid: [],
  getProjecWithClientUidStatus: requestState.idle,
  getProjecWithClientUidError: null,

  sendReportToAllClientContacts: {},
  sendReportToAllClientContactsStatus: requestState.idle,
  sendReportToAllClientContactsError: null,

  sendDynamicReportToClientContact: {},
  sendDynamicReportToClientContactStatus: requestState.idle,
  sendDynamicReportToClientContactError: null,

  userDetail: {},

  clientContactinformation: {
    data: [],
    status: requestState.idle,
    error: null,
  },

  clientSettingsInfo: {
    data: [],
    status: requestState.idle,
    error: null,
  },

  deleteClientContactStatus: requestState.idle,
  totaltrailUnderClient: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  renewalTaskOfClient: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  donerenewalTaskOfClient: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  renewalTaskOfClientOwner: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  getClientAccessListMembers: [],
  getClientAccessListMembersStatus: requestState.idle,
  getClientAccessListMembersError: null,
};

// TODO: Change user level common calls into common strore

export const fetchClientCustomFields = createAsyncThunk(
  'manageClient/fetchClientCustomfielfd',
  async (client_uid: string) => {
    const { data } = await customFieldsAPI.getCustomFieldsForClient(client_uid);
    return data.result;
  }
);

export const updateClientCustomFields = createAsyncThunk(
  'manageClient/updateClientCustomField',
  async (detail: any) => {
    const { data } = await customFieldsAPI.updateClientCustomFields(detail);
    return data.result;
  }
);

export const fetchCustomFieldsOfClientWithValues = createAsyncThunk(
  'manageclient/fetchCustomFieldsWithValues',
  async (client_uid: string) => {
    const { data } = await customFieldsAPI.getCustomFieldsWithValues(client_uid);
    return data.result;
  }
);

export const fetchUserDetail = createAsyncThunk('manageClient/fetchUserInfo', async () => {
  const { data } = await commonAPI.getUserDetail();
  return data;
});

export const getClientDetail = createAsyncThunk('manageClient/fetchClientDetail', async (client_uid: string) => {
  const { data } = await clientAPI.getClientDetail(client_uid);
  return data;
});

export const fetchClientInformation = createAsyncThunk(
  'manageClient/fetchClientFullInfo',
  async (client_uid: string) => {
    const { data } = await clientAPI.getClientInformation(client_uid);
    return data.result ?? [];
    // return client_uid;
  }
);
export const updateSingleClientInformationFromCRMSlice = createAsyncThunk(
  'manageClient/fetchClientFullInfo',
  async (details: any) => {
    const { data } = await clientAPI.updateSingleClientInformationFromCRM(details);
    return data.result ?? [];
  }
);
export const getAllRenewalTasksOfClient = createAsyncThunk(
  'manageClient/getAllRenewalTasksOfClient',
  async (client_uid: string) => {
    const { data } = await clientAPI.getAllRenewalTasksOfClient(client_uid);
    return data;
  }
);

export const doneRenewalStage = createAsyncThunk('manageClient/doneRenewalStage', async (detail: any) => {
  const { data } = await clientAPI.doneRenewalStage(detail);
  return data.result ?? null;
});
export const taskRenewalOwner = createAsyncThunk('manageClient/taskRenewalOwner', async (detail: any) => {
  const { data } = await clientAPI.taskRenewalOwner(detail);
  return data.result ?? null;
});

export const totalTrailClient = createAsyncThunk('manageClient/totalTrailUnderClient', async (client_uid: string) => {
  const { data } = await clientAPI.getTotalTrailUnderClient(client_uid);
  return data;
});

export const getClientAccessListMembers = createAsyncThunk(
  'manageClient/getClientAccessListMembers',
  async (client_uid: string) => {
    const { data } = await clientAPI.getClientAccessListMembers(client_uid);
    return data.result ?? [];
  }
);

export const addUserToClientAccessList = createAsyncThunk(
  'manageClient/addUserToClientAccessList',
  async (detail: string) => {
    const { data } = await clientAPI.addUserToClientAccessList(detail);
    return data.result;
  }
);

export const removeUserFromClientAccessList = createAsyncThunk(
  'manageClient/removeUserFromClientAccessList',
  async (detail: string) => {
    const { data } = await clientAPI.removeUserFromClientAccessList(detail);
    return data.result;
  }
);

export const fetchClientList = createAsyncThunk('manageClient/fetchClientList', async () => {
  const { data } = await clientAPI.getClientList();
  return data.result;
});
export const fetchCRMClientList = createAsyncThunk('manageClient/fetchCRMClientList', async () => {
  const { data } = await clientAPI.getCRMClientList();
  console.log('fetchCRMClientList', data);
  return data.result;
});

export const fetchClientContacts = createAsyncThunk('manageClient/fetchClientContacts', async (client_uid: string) => {
  const { data } = await clientAPI.getClientContacts(client_uid);
  return data.result;
});

export const fetchClientContactsByAssociationUid = createAsyncThunk(
  'manageClient/fetchClientContactsByAssociationUid',
  async (client_uid: string) => {
    const { data } = await clientAPI.getClientContactsByAssociationUid(client_uid);
    return data.result;
  }
);

export const updateClient = createAsyncThunk('manageClient/updateClient', async (updatedRowDetail: any) => {
  await clientAPI.updateClient(updatedRowDetail);
  return updatedRowDetail;
});

export const addCustomerClientLogo = createAsyncThunk('manageClient/addCustomerClientLogo', async (attachment: any) => {
  const { data } = await pictureApi.addCustomerClientLogo(attachment);
  return data;
});

export const deleteClient = createAsyncThunk('manageClient/deleteClient', async (clientDetail: any) => {
  await clientAPI.deleteClient(clientDetail);
  return clientDetail;
});

export const updateClientCustomField = createAsyncThunk(
  'manageClient/updateClientCustomField',
  async (fieldDetail: any) => {
    await clientAPI.updateClientCustomField(fieldDetail);
  }
);

export const addClientContact = createAsyncThunk('manageClient/addClientContact', async (detail: any) => {
  const { data } = await clientAPI.addClientContact(detail);
  if (data.status == 1) {
    detail = { ...detail, contact_uid: data.result };
    return detail;
  } else if (data.status == 0) {
    return data;
  }
  return null;
});

export const updateClientContact = createAsyncThunk('manageClient/updateClientContact', async (detail: any) => {
  const { data } = await clientAPI.updateClientContact(detail);
  return data.result ?? [];
});

export const sendClientContactAdditionMail = createAsyncThunk(
  'manageClient/sendClientContactMail',
  async (detail: any) => {
    await clientAPI.sendClientContactAdditionMail(detail);
  }
);

export const updateClientRenewal = createAsyncThunk('manageClient/updateClientRenewal', async (detail: any) => {
  const { data } = await clientAPI.updateRenewalDateForClient(detail);
  return data;
});

export const getClientWhitelistDomains = createAsyncThunk(
  'manageCLient/getCLientWhitelist',
  async (client_uid: string) => {
    const { data } = await clientAPI.getClientDomain(client_uid);
    return data;
  }
);

export const addClientWhitelistDomains = createAsyncThunk(
  'manageClient/addClientWhitelistDomain',
  async (detail: any) => {
    await clientAPI.addClientWhitelistDomain(detail);
    return detail;
  }
);

export const getCustomerDetail = createAsyncThunk('manageClient/getCustomerDetail', async () => {
  const { data } = await clientListRequiredApis.getCustomerDetail();
  return data.result ?? {};
});

export const getClientUidByAssociationUid = createAsyncThunk(
  'manageClient/getClientUid',
  async (association_uid: string) => {
    const { data } = await clientAPI.getClientyUidByAssociationUid(association_uid);
    return data.result;
  }
);

export const getClientByUid = createAsyncThunk('manageCLient/getClientNyClientUid', async (client_uid: string) => {
  const { data } = await clientAPI.getClientByUid(client_uid);
  return data.result ?? {};
});

export const getClientAudit = createAsyncThunk('manafeCLient/getClientAudit', async (client_uid: string) => {
  const { data } = await clientAPI.getClientAudit(client_uid);
  return data.result ?? [];
});

export const getVerifiedClientContacts = createAsyncThunk(
  'manafeCLient/getVerifiedClientContacts',
  async (client_uid: string) => {
    const { data } = await clientAPI.getVerifiedClientContacts(client_uid);
    return data.result;
  }
);

export const updatedcasewithfeaturestatusClient = createAsyncThunk(
  'manafeCLient/updatedcasewithfeaturestatusClient',
  async (client_uid: string) => {
    const { data } = await clientAPI.updatedcasewithfeaturestatusClient(client_uid);
    return data.result;
  }
);

export const getProjectInformationWithTaskClientReport = createAsyncThunk(
  'manafeCLient/getProjectInformationWithTaskClientReport',
  async (client_uid: string) => {
    const { data } = await clientAPI.getProjectInformationWithTaskClientReport(client_uid);
    return data.result;
  }
);
export const getProjecWithClientUid = createAsyncThunk(
  'manageCLient/getProjecWithClientUid',
  async (client_uid: string) => {
    const { data } = await clientAPI.getProjecWithClientUid(client_uid);
    return data.result;
  }
);

export const sendReportToAllClientContacts = createAsyncThunk(
  'manageCLient/sendReportToAllClientContacts',
  async (TotalData: any) => {
    const { data } = await clientAPI.sendReportToAllClientContacts(TotalData);
    return data;
  }
);

export const sendDynamicReportToClientContact = createAsyncThunk(
  'manageCLient/sendDynamciReportToClientContact',
  async (TotalData: any) => {
    const { data } = await clientAPI.sendDynamicReportToClientContact(TotalData);
    return data;
  }
);
export const addClientContactInformation = createAsyncThunk(
  'manageCLient/addClientContactInformation',
  async (payload: any) => {
    const { data } = await clientAPI.addClientContactWithSignup(payload);
    return data.result;
  }
);

export const updateClientStatus = createAsyncThunk('manageClient/updateStatus', async (detail: any) => {
  await clientAPI.updateClientStatusById(detail.status, detail.clientUid);
});
// export const updateClientStatusById = async (clientStatus: 0 | 1, clientUid: string) => {
//   const { data } = await clientAPI.updateClientStatusById(clientStatus, clientUid);
//   return data.message;
//   // if message => 'Update successfully' then successfull
// };

export const removeClientContactByContactUid = createAsyncThunk(
  'manageCLient/removeClientContactByContactUid',
  async (deleteContact: any) => {
    const { data } = await clientAPI.deleteClientContactByContactUid(deleteContact);
    return data.result;
  }
);

export const sendAccessLinkToContact = createAsyncThunk('manageClient/sendAccessLinkToContact', async (detail: any) => {
  const { data } = await clientAPI.sendAccessLinkToContact(detail);
  return data;
});

export const updateClientSettings = createAsyncThunk('manageClient/updateClientSettings', async (detail: any) => {
  const { data } = await clientAPI.updateClientSettings(detail);
  return data;
});

export const updateClientContactbyUid = createAsyncThunk(
  'manageCLient/updateClientContactbyUid',
  async (payload: any) => {
    const { data } = await clientAPI.updateClientContactbyUid(payload);
    return data.result;
  }
);

export const updateArrVisibleOnClientList = createAsyncThunk(
  'manageCLient/updateArrVisibleOnClientList',
  async (payload: UpdateArrVisibleOnClientListPayload) => {
    const { data } = await clientAPI.updateDisplayArrOnClientList(payload);
    return data;
  }
);

const clientListSlice = createSlice({
  name: 'clientList',
  initialState,
  reducers: {
    updateOpenClientListCell: (state, action: PayloadAction<any>) => {
      const { newValue, rowIndex, columnKeyToBeUpdated } = action.payload;
      // const {client_uid} = action.payload; // use when needed
      const updatedClientData = { ...state.clientList[rowIndex] };
      updatedClientData[columnKeyToBeUpdated] = newValue;
      state = {
        ...state,
        clientList: [
          ...state.clientList.slice(0, rowIndex),
          updatedClientData,
          ...state.clientList.slice(rowIndex + 1),
        ],
      };
      return state;
    },

    reset(state) {
      Object.assign(state, initialState);
    },

    updateClientListTableview: (state, action: PayloadAction<boolean>) => {
      state.clientListTableView = action.payload;
    },

    setclientContactErrorMessage: (state) => {
      state.clientContactErrorMessage = '';
    },
    resetLogoUpdateStatus: (state) => {
      state.addCustomerClientLogoStatus = initialState.addCustomerClientLogoStatus;
    },
    resetClientListState: (state) => {
      state.status = initialState.status;
    },
    resetAddedClientContact: (state) => {
      state.clientContactUid = '';
    }

    // clearProjectDashboardUidsFromStore: (state) => {
    //   state.clientUidOfSelectedProject = null;
    // },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchClientList.pending, (state) => {
        state.status = requestState.loading;
      })
      .addCase(fetchClientList.fulfilled, (state, action) => {
        state.status = requestState.success;
        if (action.payload) {
          state.clientList = [...action.payload];
        } else {
          state.clientList = [];
        }
      })
      .addCase(fetchClientList.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      })

      // For client updation
      .addCase(updateClient.pending, (state) => {
        state.updateStatus = requestState.loading;
      })
      .addCase(updateClient.fulfilled, (state, action) => {
        state.updateStatus = requestState.success;
        const { newValue, rowIndex, columnKeyToBeUpdated } = action.payload;
        const updatedClientData = { ...state.clientList[rowIndex] };
        updatedClientData[columnKeyToBeUpdated] = newValue;
        state = {
          ...state,
          clientList: [
            ...state.clientList.slice(0, rowIndex),
            updatedClientData,
            ...state.clientList.slice(rowIndex + 1),
          ],
        };
        // return state;
        // state.clientList = action.payload;
      })
      .addCase(updateClient.rejected, (state, action) => {
        state.updateStatus = requestState.failed;
        state.updateError = action.error.message;
      })

      // For Single Client detail Fetch

      .addCase(fetchClientInformation.pending, (state) => {
        state.getClientInfoStatus = requestState.loading;
      })
      .addCase(fetchClientInformation.fulfilled, (state, action) => {
        state.getClientInfoStatus = requestState.success;
        state.clientFullInformation = [...action.payload];
      })
      .addCase(fetchClientInformation.rejected, (state, action) => {
        state.getClientInfoStatus = requestState.failed;
        state.getClientInfoError = action.error.message;
      })

      // For fetching all trails under client

      .addCase(totalTrailClient.pending, (state) => {
        state.totaltrailUnderClient.status = requestState.loading;
      })
      .addCase(totalTrailClient.fulfilled, (state, action) => {
        state.totaltrailUnderClient.status = requestState.success;
        state.totaltrailUnderClient.data = [...action.payload];
      })
      .addCase(totalTrailClient.rejected, (state, action) => {
        state.totaltrailUnderClient.status = requestState.failed;
        state.getClientInfoError = action.error.message;
      })

      // get client access list members
      .addCase(getClientAccessListMembers.pending, (state) => {
        state.getClientAccessListMembersStatus = requestState.loading;
      })
      .addCase(getClientAccessListMembers.fulfilled, (state, action: any) => {
        state.getClientAccessListMembersStatus = requestState.success;
        if (action.payload) {
          state.getClientAccessListMembers = [...action.payload];
        } else {
          state.getClientAccessListMembers = [];
        }
      })
      .addCase(getClientAccessListMembers.rejected, (state, action) => {
        state.getClientAccessListMembersError = action.error.message;
      })

      // For fetching client Contacts
      .addCase(fetchClientContacts.pending, (state) => {
        state.getClientContactStatus = requestState.loading;
      })
      .addCase(fetchClientContacts.fulfilled, (state, action) => {
        state.getClientContactStatus = requestState.success;
        if (action.payload) {
          state.clientContacts = [...action.payload];
        } else {
          state.clientContacts = [];
        }
      })
      .addCase(fetchClientContacts.rejected, (state, action) => {
        state.getClientContactStatus = requestState.failed;
        state.getClientContactsError = action.error.message;
      })

      // For add client Contact
      .addCase(addClientContact.pending, (state) => {
        state.addClientContactStatus = requestState.loading;
      })
      .addCase(addClientContact.fulfilled, (state, action) => {
        state.addClientContactStatus = requestState.success;
        if (action.payload.status == 0) {
          state.clientContactErrorMessage = action.payload.message;
        }
        state.clientContactUid = action.payload.contact_uid;
      })

      // Foe uploading client profile
      .addCase(addCustomerClientLogo.pending, (state) => {
        state.addCustomerClientLogoStatus = requestState.loading;
      })
      .addCase(addCustomerClientLogo.fulfilled, (state, action) => {
        state.addCustomerClientLogoStatus = requestState.success;
        state.clientDetail = action.payload;
      })
      .addCase(addCustomerClientLogo.rejected, (state, action) => {
        state.addCustomerClientLogoStatus = requestState.failed;
        state.addCustomerClientLogoError = action.error.message;
      })

      // Update client contact
      .addCase(updateClientContact.pending, (state) => {
        state.updateClientContactStatus = requestState.loading;
      })
      .addCase(updateClientContact.fulfilled, (state) => {
        state.updateClientContactStatus = requestState.success;
      })
      .addCase(updateClientContact.rejected, (state, action) => {
        state.updateClientContactStatus = requestState.failed;
        state.updateClientContactError = action.error.message;
      })

      // For delete client
      .addCase(deleteClient.pending, (state) => {
        state.deleteClientStatus = requestState.loading;
      })

      // get client custom fields
      .addCase(fetchClientCustomFields.pending, (state) => {
        state.getClientCustomFieldsStatus = requestState.loading;
        state.clientCustomFields = [];
      })
      .addCase(fetchClientCustomFields.fulfilled, (state, action) => {
        state.getClientCustomFieldsStatus = requestState.success;
        state.clientCustomFields = [...action.payload];
      })

      // Update client Renewal
      .addCase(updateClientRenewal.pending, (state) => {
        state.updateClientRenewalStatus = requestState.loading;
      })
      .addCase(updateClientRenewal.fulfilled, (state) => {
        state.updateClientRenewalStatus = requestState.success;
      })
      .addCase(updateClientRenewal.rejected, (state, action) => {
        state.updateClientRenewalError = action.error.message;
      })

      // get client detail action
      .addCase(getClientDetail.pending, (state) => {
        state.getClientDetailStatus = requestState.loading;
      })
      .addCase(getClientDetail.fulfilled, (state, action) => {
        state.getClientDetailStatus = requestState.success;
        state.clientDetail = { ...action.payload.result };
      })
      .addCase(getClientDetail.rejected, (state, action) => {
        state.getClientDetailError = action.error.message;
      })

      // get client custom field with values

      .addCase(fetchCustomFieldsOfClientWithValues.pending, (state) => {
        state.getClientCustomFieldsWithValuesStatus = requestState.loading;
        state.clientCustomFieldsWithValues = [];
      })
      .addCase(fetchCustomFieldsOfClientWithValues.fulfilled, (state, action) => {
        state.getClientCustomFieldsWithValuesStatus = requestState.success;
        state.clientCustomFieldsWithValues = [...action.payload];
      })
      .addCase(fetchCustomFieldsOfClientWithValues.rejected, (state, action) => {
        state.getClientCustomFieldsWithValuesError = action.error.message;
      })

      // Update client custom fields
      .addCase(updateClientCustomFields.pending, (state) => {
        state.updateClientCustomFieldsStatus = requestState.loading;
      })
      .addCase(updateClientCustomFields.fulfilled, (state) => {
        state.updateClientCustomFieldsStatus = requestState.success;
      })
      .addCase(updateClientCustomFields.rejected, (state, action) => {
        state.updateClientCustomFieldsError = action.error.message;
      })

      // get client whitelist Domain

      .addCase(getClientWhitelistDomains.pending, (state) => {
        state.getClientWhitelistDomainsStatus = requestState.loading;
      })
      .addCase(getClientWhitelistDomains.fulfilled, (state, action) => {
        state.getClientWhitelistDomainsStatus = requestState.success;
        if (action.payload.result != null) {
          state.clientWhitelistDomains = [...action.payload.result];
        } else {
          state.clientWhitelistDomains = [];
        }
      })
      .addCase(getClientWhitelistDomains.rejected, (state, action) => {
        state.getClientWhitelistDomainsError = action.error.message;
      })

      // add Client white list domains

      .addCase(addClientWhitelistDomains.pending, (state) => {
        state.addClientWhitelistDomainStatus = requestState.loading;
      })
      .addCase(addClientWhitelistDomains.fulfilled, (state) => {
        state.addClientWhitelistDomainStatus = requestState.success;
        // state.clientWhitelistDomains = [...state.clientWhitelistDomains, action.payload];
        // TODO: Add that domain to the state
        // if (action.payload) {
        //   state.clientWhitelistDomains = [...action.payload];
        // } else {
        //   state.clientWhitelistDomains = [];
        // }
      })
      .addCase(addClientWhitelistDomains.rejected, (state, action) => {
        state.addClientWhitelistDomainError = action.error.message;
      })

      // userDetail
      .addCase(fetchUserDetail.fulfilled, (state, action: any) => {
        state.userDetail = { ...action.payload.result };
      })

      // get Customer detail
      .addCase(getCustomerDetail.pending, (state) => {
        state.getCustomerDetailStatus = requestState.loading;
      })
      .addCase(getCustomerDetail.fulfilled, (state, action) => {
        state.getCustomerDetailStatus = requestState.success;
        state.getCustomerDetail = action.payload;
      })
      .addCase(getCustomerDetail.rejected, (state, action) => {
        state.getCustomerDetailError = action.error.message;
      })

      // get clientuid by assciation uid
      .addCase(getClientUidByAssociationUid.pending, (state) => {
        state.getClientUidByAssociationUidStaus = requestState.loading;
      })
      .addCase(getClientUidByAssociationUid.fulfilled, (state, action) => {
        state.getClientUidByAssociationUidStaus = requestState.success;
        state.clientUidOfSelectedProject = { ...action.payload };
      })
      .addCase(getClientUidByAssociationUid.rejected, (state, action) => {
        state.getClientUidByAssociationUidError = action.error.message;
      })

      // get clientuid by client uid
      .addCase(getClientByUid.pending, (state) => {
        state.getClientDetailStatus = requestState.loading;
      })
      .addCase(getClientByUid.fulfilled, (state, action) => {
        state.getClientDetailStatus = requestState.success;
        state.getClientDetailByUid = action.payload;
      })
      .addCase(getClientByUid.rejected, (state, action) => {
        state.getClientDetailError = action.error.message;
      })

      // get clientuid by client uid
      .addCase(getClientAudit.pending, (state) => {
        state.getClientAuditStatus = requestState.loading;
      })
      .addCase(getClientAudit.fulfilled, (state, action) => {
        state.getClientAuditStatus = requestState.success;
        state.getClientAudit = [...action.payload];
      })
      .addCase(getClientAudit.rejected, (state, action) => {
        state.getClientAuditError = action.error.message;
      })

      // verfied client contacts
      .addCase(getVerifiedClientContacts.pending, (state) => {
        state.getVerifiedClientContactsStatus = requestState.loading;
      })
      .addCase(getVerifiedClientContacts.fulfilled, (state, action) => {
        state.getVerifiedClientContactsStatus = requestState.success;
        state.getVerifiedClientContacts = action.payload == null ? [] : [...action.payload];
      })
      .addCase(getVerifiedClientContacts.rejected, (state, action) => {
        state.getVerifiedClientContactsError = action.error.message;
      })

      // GET PROJECT INFORMATION
      .addCase(getProjectInformationWithTaskClientReport.pending, (state) => {
        state.getProjectInformationWithTaskClientReportStatus = requestState.loading;
      })
      .addCase(getProjectInformationWithTaskClientReport.fulfilled, (state, action) => {
        state.getProjectInformationWithTaskClientReportStatus = requestState.success;
        state.getProjectInformationWithTaskClientReport = action.payload == null ? [] : [...action.payload];
      })
      .addCase(getProjectInformationWithTaskClientReport.rejected, (state, action) => {
        state.getProjectInformationWithTaskClientReportError = action.error.message;
      })

      // GET PROJECT INFORMATION for client
      .addCase(getProjecWithClientUid.pending, (state) => {
        state.getProjecWithClientUidStatus = requestState.loading;
      })
      .addCase(getProjecWithClientUid.fulfilled, (state, action) => {
        state.getProjecWithClientUidStatus = requestState.success;
        state.getProjecWithClientUid = action.payload == null ? [] : [...action.payload];
      })
      .addCase(getProjecWithClientUid.rejected, (state, action) => {
        state.getProjecWithClientUidError = action.error.message;
      })

      // recieve data from sending mail
      .addCase(sendReportToAllClientContacts.pending, (state) => {
        state.sendReportToAllClientContactsStatus = requestState.loading;
      })
      .addCase(sendReportToAllClientContacts.fulfilled, (state, action) => {
        state.sendReportToAllClientContactsStatus = requestState.success;
        state.sendReportToAllClientContacts = action.payload;
      })
      .addCase(sendReportToAllClientContacts.rejected, (state, action) => {
        state.sendReportToAllClientContactsError = action.error.message;
      })
      .addCase(sendDynamicReportToClientContact.pending, (state) => {
        state.sendDynamicReportToClientContactStatus = requestState.loading;
      })
      .addCase(sendDynamicReportToClientContact.fulfilled, (state, action) => {
        state.sendDynamicReportToClientContactStatus = requestState.success;
        state.sendDynamicReportToClientContact = action.payload;
      })
      .addCase(sendDynamicReportToClientContact.rejected, (state, action) => {
        state.sendDynamicReportToClientContactError = action.error.message;
      })
      // addClientContactInformation
      .addCase(addClientContactInformation.pending, (state) => {
        state.clientContactinformation.status = requestState.loading;
      })
      .addCase(addClientContactInformation.fulfilled, (state, action) => {
        state.clientContactinformation.status = requestState.success;
        state.clientContactinformation.data = [action.payload];
      })
      .addCase(addClientContactInformation.rejected, (state, action) => {
        state.clientContactinformation.status = requestState.failed;
        state.clientContactinformation.error = action.error.message;
      })
      //removeClientContactByContactUid
      .addCase(removeClientContactByContactUid.pending, (state) => {
        state.deleteClientContactStatus = requestState.loading;
      })
      .addCase(removeClientContactByContactUid.fulfilled, (state) => {
        state.deleteClientContactStatus = requestState.success;
        // state.clientContactinformation.data = [action.payload];
      })
      .addCase(removeClientContactByContactUid.rejected, (state) => {
        state.deleteClientContactStatus = requestState.failed;
        // state.clientContactinformation.error = action.error.message;
      })

      // updateClientSettings
      .addCase(updateClientSettings.pending, (state) => {
        state.clientSettingsInfo.status = requestState.loading;
      })
      .addCase(updateClientSettings.fulfilled, (state, action) => {
        state.clientSettingsInfo.status = requestState.success;
        state.clientSettingsInfo.data = [action.payload];
      })
      .addCase(updateClientSettings.rejected, (state, action) => {
        state.clientSettingsInfo.status = requestState.failed;
        state.clientSettingsInfo.error = action.error.message;
      })

      // get renewal task of client
      .addCase(getAllRenewalTasksOfClient.pending, (state) => {
        state.renewalTaskOfClient.status = requestState.loading;
      })
      .addCase(getAllRenewalTasksOfClient.fulfilled, (state, action) => {
        state.renewalTaskOfClient.status = requestState.success;
        state.renewalTaskOfClient.data = action.payload;
      })
      .addCase(getAllRenewalTasksOfClient.rejected, (state, action) => {
        state.renewalTaskOfClient.status = requestState.failed;
        state.renewalTaskOfClient.error = action.error.message;
      })
      // get renewal task of client
      .addCase(doneRenewalStage.pending, (state) => {
        state.donerenewalTaskOfClient.status = requestState.loading;
      })
      .addCase(doneRenewalStage.fulfilled, (state, action) => {
        state.donerenewalTaskOfClient.status = requestState.success;
        state.donerenewalTaskOfClient.data = [action.payload];
      })
      .addCase(doneRenewalStage.rejected, (state, action) => {
        state.donerenewalTaskOfClient.status = requestState.failed;
        state.donerenewalTaskOfClient.error = action.error.message;
      })
      .addCase(taskRenewalOwner.pending, (state) => {
        state.renewalTaskOfClientOwner.status = requestState.loading;
      })
      .addCase(taskRenewalOwner.fulfilled, (state, action) => {
        state.renewalTaskOfClientOwner.status = requestState.success;
        state.renewalTaskOfClientOwner.data = [action.payload];
      })
      .addCase(taskRenewalOwner.rejected, (state, action) => {
        state.renewalTaskOfClientOwner.status = requestState.failed;
        state.renewalTaskOfClientOwner.error = action.error.message;
      });
  },
});

export const clientListReducer = clientListSlice.reducer;
export const {
  updateOpenClientListCell,
  updateClientListTableview,
  setclientContactErrorMessage,
  resetLogoUpdateStatus,
  resetClientListState,
  resetAddedClientContact,
} = clientListSlice.actions;
export default clientListReducer;
