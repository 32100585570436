import { apis } from '../axiosInstance';
import { rbacRoutes } from '../route';

export const getAllUsersAndRoles = () =>
  apis.phpAxiosInstance.get(rbacRoutes.getAllUsersAndRoles(), {
    headers: { JWT: 'true' },
  });

export const getResourcesFields = (payload: any) =>
  apis.phpAxiosInstance.post(rbacRoutes.getResourcesField(), payload, {
    headers: { JWT: 'true' },
  });

export const getAllResources = () =>
  apis.phpAxiosInstance.get(rbacRoutes.getAllResources(), {
    headers: { JWT: 'true' },
  });

export const getResourcesFieldValues = (payload: any) =>
  apis.phpAxiosInstance.post(rbacRoutes.getResourceFieldValues(), payload, {
    headers: { JWT: 'true' },
  });

export const getGeneralFieldValues = () =>
  apis.phpAxiosInstance.get(rbacRoutes.getGeneralFieldValue(), {
    headers: { JWT: 'true' },
  });

export const getAllPages = () =>
  apis.phpAxiosInstance.get(rbacRoutes.getAllPage(), {
    headers: { JWT: 'true' },
  });

export const getAllPermissions = (payload: any) =>
  apis.phpAxiosInstance.post(rbacRoutes.getAllPermissions(), payload, {
    headers: { JWT: 'true' },
  });

export const getValidReporteeAdditions = (userId: string) =>
  apis.phpAxiosInstance.post(
    rbacRoutes.getValidReporteeAdditions(),
    { userId: userId },
    {
      headers: { JWT: 'true' },
    }
  );

export const getPolicyOnResources = (payload: any) =>
  apis.phpAxiosInstance.post(rbacRoutes.getPolicyOnResources(), payload, {
    headers: { JWT: 'true' },
  });

export const addUser = (payload: any) =>
  apis.phpAxiosInstance.post(rbacRoutes.addUser(), payload, {
    headers: { JWT: 'true' },
  });

export const addRole = (payload: any) =>
  apis.phpAxiosInstance.post(rbacRoutes.addRole(), payload, {
    headers: { JWT: 'true' },
  });

export const deactivateUser = (payload: any) =>
  apis.phpAxiosInstance.post(rbacRoutes.deactivateUser(), payload, {
    headers: { JWT: 'true' },
  });

export const updateUsernameOfUser = (payload: any) =>
  apis.phpAxiosInstance.post(rbacRoutes.updateUsernameOfUser(), payload, {
    headers: { JWT: 'true' },
  });

export const activateUser = (payload: any) =>
  apis.phpAxiosInstance.post(rbacRoutes.activateUser(), payload, {
    headers: { JWT: 'true' },
  });

export const addPolicyToRole = (payload: any) =>
  apis.phpAxiosInstance.post(rbacRoutes.addPolicyToRole(), payload, {
    headers: { JWT: 'true' },
  });

export const removePolicyfromRole = (payload: any) =>
  apis.phpAxiosInstance.post(rbacRoutes.removePoliciesFromRole(), payload, {
    headers: { JWT: 'true' },
  });

export const addPagesToRole = (payload: any) =>
  apis.phpAxiosInstance.post(rbacRoutes.addPagesToRole(), payload, {
    headers: { JWT: 'true' },
  });

export const removePagesfromRole = (payload: any) =>
  apis.phpAxiosInstance.post(rbacRoutes.removePagesFromRole(), payload, {
    headers: { JWT: 'true' },
  });

export const changeUserRole = (payload: any) =>
  apis.phpAxiosInstance.post(rbacRoutes.changeUserRole(), payload, {
    headers: { JWT: 'true' },
  });

export const getAllRoles = () =>
  apis.phpAxiosInstance.get(rbacRoutes.getAllRoles(), {
    headers: { JWT: 'true' },
  });

export const getRole = (roleId: string) =>
  apis.phpAxiosInstance.post(
    rbacRoutes.getRole(),
    { roleId: roleId },
    {
      headers: { JWT: 'true' },
    }
  );

export const createPolicy = (payload: any) =>
  apis.phpAxiosInstance.post(rbacRoutes.createPolicy(), payload, {
    headers: { JWT: 'true' },
  });

export const getAllConditions = (payload: any) =>
  apis.phpAxiosInstance.post(rbacRoutes.getAllConditions(), payload, {
    headers: { JWT: 'true' },
  });

export const removeConditionsFromPolicy = (payload: any) =>
  apis.phpAxiosInstance.post(rbacRoutes.removeCondition(), payload, {
    headers: { JWT: 'true' },
  });

export const addConditionsToPolicy = (payload: any) =>
  apis.phpAxiosInstance.post(rbacRoutes.addConditionToPolicy(), payload, {
    headers: { JWT: 'true' },
  });

export const createCondition = (payload: any) =>
  apis.phpAxiosInstance.post(rbacRoutes.createCondition(), payload, {
    headers: { JWT: 'true' },
  });

export const getRbacToggleAffectingUsers = () =>
  apis.phpAxiosInstance.post(
    rbacRoutes.getRbacToggleAffectingUsers(),
    {},
    {
      headers: { JWT: 'true' },
    }
  );

export const getConditions = () =>
  apis.phpAxiosInstance.get(rbacRoutes.getConditions(), {
    headers: { JWT: 'true' },
  });

export const enableRbac = () => apis.phpAxiosInstance.post(rbacRoutes.enableRbac(), {}, { headers: { JWT: 'true' } });

export const disableRbac = () => apis.phpAxiosInstance.post(rbacRoutes.disableRbac(), {}, { headers: { JWT: 'true' } });

export const addReportsTo = (detail: any) =>
  apis.phpAxiosInstance.post(rbacRoutes.addReportsTo(), detail, { headers: { JWT: 'true' } });

export const removeReportsTo = (detail: any) =>
  apis.phpAxiosInstance.post(rbacRoutes.removeReportsTo(), detail, { headers: { JWT: 'true' } });

export const bootstrapRbacWIthDefaultRole = (detail: any) =>
  apis.phpAxiosInstance.post(rbacRoutes.bootstrapRBACWithDefaultValue(), detail, { headers: { JWT: 'true' } });

export const rbacAPI = {
  getAllUsersAndRoles,
  getResourcesFields,
  getAllResources,
  getResourcesFieldValues,
  getGeneralFieldValues,
  getAllPages,
  getAllPermissions,
  getPolicyOnResources,
  addUser,
  getValidReporteeAdditions,
  addRole,
  deactivateUser,
  activateUser,
  addPolicyToRole,
  removePolicyfromRole,
  addPagesToRole,
  removePagesfromRole,
  changeUserRole,
  getAllRoles,
  getRole,
  createPolicy,
  getAllConditions,
  removeConditionsFromPolicy,
  addConditionsToPolicy,
  createCondition,
  getConditions,
  enableRbac,
  disableRbac,
  addReportsTo,
  removeReportsTo,
  getRbacToggleAffectingUsers,
  bootstrapRbacWIthDefaultRole,
  updateUsernameOfUser,
};
