import { Button, ButtonGroup } from '@mui/material';
import StatusBadge from './LabeledBadge';

interface Props {
  selectedBadge: string;
  setSelectedBadge: React.Dispatch<React.SetStateAction<string>>;
  config: {
    id: string;
    name: string;
    color: string;
    count: number;
  }[];
}

const StatusFilterGroup = (props: Props) => {
  // const [thisStatusIsActive, setThisStatusIsActive] = useState('All');
  //   const [counts, setCounts] = useState<number[]>();
  // const [countMapping, setCountMapping] = useState<Record<string, number>>({});
  // console.log(countMapping);
  // useEffect(() => {
  //   const newFilteredData = new FilterableData(props.data);
  //   // newFilteredData.customFilter('')
  //   //   newFilteredData.bulkFilter(allFilters['ManageClientListHomePage'].appliedFilters);
  //   //   if (!(thisStatusIsActive === 'All')) {
  //   //     newFilteredData.customFilter('client_readiness_status', getOriginalStatusFromStatusName(thisStatusIsActive));
  //   //   }

  //   // setCounts([]);
  //   props.config.map((item) => {
  //     setCountMapping((prevMapping: any) => ({
  //       ...prevMapping,
  //       [item.id]: newFilteredData.customFilter(item.dataKey, item.value).toArray().length,
  //     }));
  //     //   setCounts([...counts, newFilteredData.customFilter(item.dataKey, item.value).toArray().length]);
  //   });
  // }, [props.data, thisStatusIsActive]);

  // console.log('count', countMapping);

  return (
    <ButtonGroup
      variant="outlined"
      aria-label="outlined button group"
      sx={{
        display: 'inline-flex',
        flexWrap: 'wrap',
        backgroundColor: 'white',
        border: '1px solid #E5E5E5',
        borderRadius: '4px',
      }}
    >
      {props.config.map((item) => (
        <Button
          variant="text"
          sx={item.name === props.selectedBadge ? { background: 'rgba(45, 96, 246,0.15)' } : {}}
          onClick={() => props.setSelectedBadge(item.name)}
          key={item.id}
        >
          <StatusBadge
            statusColor={item.color}
            text={item.name}
            badgeCount={item.count}
            isActive={item.name === props.selectedBadge}
          />
        </Button>
      ))}

      {/* <Button
        variant="text"
        sx={thisStatusIsActive === 'Overdue' ? { background: 'rgba(45, 96, 246,0.15)' } : {}}
        onClick={() => setThisStatusIsActive('Overdue')}
      >
        <StatusBadge
          statusColor="#E75B5C"
          text="Overdue"
          badgeCount={clientStatusCount('red')}
          isActive={thisStatusIsActive === 'Overdue'}
        />
      </Button>
      <Button
        variant="text"
        sx={thisStatusIsActive === 'At Risk' ? { background: 'rgba(45, 96, 246,0.15)' } : {}}
        onClick={() => setThisStatusIsActive('At Risk')}
      >
        <StatusBadge
          statusColor="#EFA958"
          text="At Risk"
          badgeCount={clientStatusCount('amber')}
          isActive={thisStatusIsActive === 'At Risk'}
        />
      </Button>
      <Button
        variant="text"
        sx={thisStatusIsActive === 'On Track' ? { background: 'rgba(45, 96, 246,0.15)' } : {}}
        onClick={() => setThisStatusIsActive('On Track')}
      >
        <StatusBadge
          statusColor="#4F9F52"
          text="On Track"
          badgeCount={clientStatusCount('green')}
          isActive={thisStatusIsActive === 'On Track'}
        />
      </Button> */}
    </ButtonGroup>
  );
};

export default StatusFilterGroup;
