import { Modal } from 'components/ResourceManagement/design-system/primitives/modal';
import { ModalBox } from 'components/ResourceManagement/design-system/primitives';

type TModalProps = { isModalOpen: boolean, toggleFn: () => void, children: JSX.Element };

export const GenericModal = ({ isModalOpen, toggleFn, children }: TModalProps) => {
    return (
        <Modal isOpen={isModalOpen}
            onBackgroundClick={toggleFn}
            onEscapeKeydown={toggleFn}
        >
            <ModalBox>
                {children}
            </ModalBox>
        </Modal>
    );
};