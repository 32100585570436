import { useState } from 'react';
import { Grid } from '@mui/material';
import CognisaasTabs from '../shared/CognisaasTabs/index';
import Overview from './Overview/Overview';
import Notification from './Notification/Notification';

const Home = () => {
  const [currentTab, setCurrentTab] = useState(0);

  const handleTabSwitch = (event: any, tabIndex: number) => {
    setCurrentTab(tabIndex);
  };

  return (
    <Grid sx={{ padding: '24px 22px' }}>
      <>
        <Grid>
          <CognisaasTabs handleTabSwitch={handleTabSwitch} tabs={['Overview', 'Notifications']} />
        </Grid>
        {currentTab == 0 ? <Overview /> : <Notification />}
      </>
    </Grid>
  );
};

export default Home;
