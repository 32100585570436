import { Alert, alpha, Box, Grid, IconButton, Stack, styled, TextField, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import CognisaasButton from 'components/shared/CognisaasButton/CognisaasButton';
import CognisaaasTooltip from 'components/shared/CognisaasTooltip/CognisaasTooltip';
import { ReactComponent as Info } from '../../../assets/svgs/info.svg';
import { DataGridPremium, gridClasses } from '@mui/x-data-grid-premium';
import { useAppSelector } from 'store/hooks';
import { selectAddEmailConfig, selectdeleteEmailConfig, selectgetEmailConfig } from 'store/modules/Settings/selector';
import { addEmailConfig, deleteEmailConfig, getEmailConfig } from 'store/modules/Settings/slice';
import { useEffect } from 'react';
import { MenuCell } from '../SettingsTable/Table';
import { isUndefined } from 'utils/dataHelpers';
import Cookies from 'universal-cookie';
import { smtpValidationSchema } from 'utils/validations';
const env: string = process.env.REACT_APP_ENV ?? 'local';
const cookies = new Cookies();
const customerUid: any = cookies.get('cogni_customer_uid');
const userUid: any = cookies.get('cogni_user_uid');

const ODD_OPACITY = 0.2;

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: 110,
  width: '100%',
  '& .MuiFormGroup-options': {
    alignItems: 'center',
    paddingBottom: theme.spacing(1),
    '& > div': {
      minWidth: 100,
      margin: theme.spacing(2),
      marginLeft: 0,
    },
  },
}));

const StripedDataGrid = styled(DataGridPremium)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
        },
      },
    },
  },
}));

const initialState = {
  columns: {
    columnVisibilityModel: {
      email_config_uid: false,
    },
  },
};

const emailConfigForm = () => {
  const dispatch = useDispatch();

  // for email config data
  const emailConfigState = useAppSelector(selectgetEmailConfig);
  const deleteEmailConfigState = useAppSelector(selectdeleteEmailConfig);
  const addEmailConfigState = useAppSelector(selectAddEmailConfig);

  useEffect(() => {
    dispatch(getEmailConfig());
    console.log('data = ', emailConfigState);
  }, []);

  useEffect(() => {
    if (deleteEmailConfigState.status == 'success') {
      dispatch(getEmailConfig());
    }
  }, [deleteEmailConfigState]);

  useEffect(() => {
    if (addEmailConfigState.status == 'success') {
      dispatch(getEmailConfig());
    }
  }, [addEmailConfigState]);

  const handleMenuClick = (rowData: Record<string, any>, action: string) => {
    if (action == 'Delete') {
      dispatch(deleteEmailConfig());
    }
  };

  const columns = [
    {
      field: 'email_config_uid',
      hide: true,
    },
    {
      field: 'smtp_host',
      headerName: 'SMTP Host',
      flex: 1,
      // width: 150,
    },
    {
      field: 'smtp_user',
      headerName: 'SMTP User',
      flex: 1,
    },
    {
      field: 'smtp_port',
      headerName: 'SMTP Port',
      flex: 1,
    },
    {
      field: 'last_updated_by_username',
      headerName: 'Last updated by',
      flex: 1,
      // width: 200,
    },
    {
      field: 'last_updated_on',
      headerName: 'Last updated on',
      flex: 1,
      // width: 200,
    },
    {
      field: '.',
      headerName: '',
      minWidth: 50,
      renderCell: (params: any) => MenuCell(params, ['Delete'], handleMenuClick),
    },
  ];

  const initialValues = {
    smtpHost: '',
    smtpUser: '',
    smtpPassword: '',
    smtpPort: '',
  };
  return (
    <div style={{ margin: '20px' }}>
      <Typography variant={'h2'}>Email Configurations</Typography>
      <Box sx={{ flexGrow: 1 }}>
        <Grid sx={{ marginTop: '24px' }}>
          <Grid>
            {emailConfigState.status == 'success' && isUndefined(emailConfigState.data.smtp_host) && (
              <Alert severity="info">
                By default, invitation e-mails and notification e-mails will be sent from{' '}
                <strong>
                  {' '}
                  {env == 'local' || env == 'prod' || env == 'dev' ? (
                    <>notification@cognisaas.net</>
                  ) : (
                    <>notification@cognisaas.com</>
                  )}
                </strong>
                . If you wish to use your own e-mail ids to send these emails, you can configure the same below.
              </Alert>
            )}
            {emailConfigState.status == 'success' && !isUndefined(emailConfigState.data.smtp_host) && (
              <Alert severity="success">
                SMTP Configuration enabled Successfully. Now, invitation e-mails and notification e-mails will be sent
                from <strong>{emailConfigState.data.smtp_user}</strong>. If you wish to use default CogniSaas emails to
                send these emails, you can delete the configurations below.
              </Alert>
            )}
          </Grid>
          <Grid>
            <Typography variant={'subtitle1'}>
              Send emails from your company domain by configuring SMTP
              <a
                rel="noopener noreferrer"
                href="https://en.wikipedia.org/wiki/Simple_Mail_Transfer_Protocol"
                target="_blank"
              >
                <IconButton color="primary" aria-label="SMTP">
                  <InfoIcon />
                </IconButton>
              </a>
            </Typography>
          </Grid>
          <Grid>
            {emailConfigState.status == 'success' && isUndefined(emailConfigState.data.email_config_uid) && (
              <Formik
                initialValues={initialValues}
                validationSchema={smtpValidationSchema}
                onSubmit={(values) => {
                  console.log('Form submitted', values);
                  dispatch(
                    addEmailConfig({
                      smtp_host: values.smtpHost,
                      smtp_user: values.smtpUser,
                      smtp_pass: values.smtpPassword,
                      smtp_port: values.smtpPort,
                      customer_uid: customerUid,
                      is_active: '1',
                      last_updated_by: userUid,
                      created_by: userUid,
                    })
                  );
                }}
              >
                {(props: any) => {
                  return (
                    <form>
                      <Stack mt={2} direction="row">
                        <Stack>
                          <Stack direction={'row'}>
                            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                              SMTP Host Name
                            </Typography>
                            <CognisaaasTooltip
                              title={
                                <Box sx={{ padding: '8px' }}>
                                  <Typography variant="subtitle2">
                                    Enter the SMTP host name of your service provide e.g. - smtp.gmail.com,
                                    smtp.office365.com etc.
                                  </Typography>
                                </Box>
                              }
                            >
                              <IconButton disableRipple size="small">
                                <Info />
                              </IconButton>
                            </CognisaaasTooltip>
                          </Stack>
                          <TextField
                            name="smtpHost"
                            size="small"
                            onChange={props.handleChange}
                            value={props.values.smtpHost}
                            placeholder={'smtp.gmail.com'}
                            error={props.touched.smtpHost && Boolean(props.errors.smtpHost)}
                            helperText={props.touched.smtpHost && props.errors.smtpHost}
                          ></TextField>
                        </Stack>
                      </Stack>
                      <Stack mt={2} direction="row">
                        <Stack>
                          <Stack direction="row">
                            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                              SMTP User Name
                            </Typography>
                            <CognisaaasTooltip
                              title={
                                <Box sx={{ padding: '8px' }}>
                                  <Typography variant="subtitle2">
                                    Enter The SMTP user name eg - notifications@yourCompany.com, alert@yourcompany.com
                                    etc.
                                  </Typography>
                                </Box>
                              }
                            >
                              <IconButton disableRipple size="small">
                                <Info />
                              </IconButton>
                            </CognisaaasTooltip>
                          </Stack>
                          <TextField
                            name="smtpUser"
                            size="small"
                            onChange={props.handleChange}
                            value={props.values.smtpUser}
                            placeholder={'notifications@yourCompany.com'}
                            error={props.touched.smtpUser && Boolean(props.errors.smtpUser)}
                            helperText={props.touched.smtpUser && props.errors.smtpUser}
                          ></TextField>
                        </Stack>
                      </Stack>
                      <Stack mt={2} direction="row">
                        <Stack>
                          <Stack direction={'row'}>
                            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                              SMTP Password
                            </Typography>
                            <CognisaaasTooltip
                              title={
                                <Box sx={{ padding: '8px' }}>
                                  <Typography variant="subtitle2">
                                    Enter the SMTP password for the user name.
                                  </Typography>
                                </Box>
                              }
                            >
                              <IconButton disableRipple size="small">
                                <Info />
                              </IconButton>
                            </CognisaaasTooltip>
                          </Stack>
                          <TextField
                            name="smtpPassword"
                            size="small"
                            onChange={props.handleChange}
                            value={props.values.smtpPassword}
                            placeholder={'Enter SMTP access password'}
                            error={props.touched.smtpPassword && Boolean(props.errors.smtpPassword)}
                            helperText={props.touched.smtpPassword && props.errors.smtpPassword}
                          ></TextField>
                        </Stack>
                      </Stack>
                      <Stack mt={2} direction="row">
                        <Stack>
                          <Stack direction={'row'}>
                            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                              SMTP Port
                            </Typography>
                            <CognisaaasTooltip
                              title={
                                <Box sx={{ padding: '12px' }}>
                                  <Typography variant="subtitle2">Enter the SMTP port</Typography>
                                </Box>
                              }
                            >
                              <IconButton disableRipple size="small">
                                <Info />
                              </IconButton>
                            </CognisaaasTooltip>
                          </Stack>
                          <TextField
                            name="smtpPort"
                            size="small"
                            onChange={props.handleChange}
                            value={props.values.smtpPort}
                            placeholder={'eg - 465, 587'}
                            error={props.touched.smtpPort && Boolean(props.errors.smtpPort)}
                            helperText={props.touched.smtpPort && props.errors.smtpPort}
                          ></TextField>
                        </Stack>
                      </Stack>

                      <Grid
                        mt={3}
                        mb={2}
                        spacing={1}
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        width={'270px'}
                      >
                        <Grid item>
                          <CognisaasButton
                            clickHandler={props.handleSubmit}
                            isOutlined={false}
                            disabled={
                              !props.dirty &&
                              !props.isValid &&
                              !props.touched.smtpHost &&
                              !props.touched.smtpUser &&
                              !props.touched.smtpPassword &&
                              !props.touched.smtpPort
                            }
                            label="Save"
                          />
                        </Grid>
                      </Grid>
                    </form>
                  );
                }}
              </Formik>
            )}
          </Grid>
          <Grid container justifyContent="space-between" sx={{ padding: '20px' }}>
            <Box sx={{ width: '100%' }}>
              {emailConfigState.status == 'success' && emailConfigState.data.email_config_uid && (
                <StyledBox>
                  <StripedDataGrid
                    columns={columns}
                    rows={[emailConfigState.data]}
                    initialState={initialState}
                    loading={true}
                    getRowId={(emailConfigState) => emailConfigState.email_config_uid}
                    disableSelectionOnClick
                    hideFooter={true}
                    pageSize={1}
                    getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'odd' : 'odd')}
                  />
                </StyledBox>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

const EmailConfiguration = () => {
  return emailConfigForm();
};

export default EmailConfiguration;
