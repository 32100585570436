import { apis } from '../axiosInstance';
import { usersRoutes } from '../route';

import Cookies from 'universal-cookie';

const cookies = new Cookies();

const getAllUsers = () =>
  apis.phpAxiosInstance.get(usersRoutes.allUsers(cookies.get('cogni_customer_uid') as string), {
    headers: { JWT: 'true' },
  });

export const usersAPI = {
  getAllUsers,
};
