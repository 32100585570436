import React, { useState } from 'react';

import {
  DataGridPremium,
  gridClasses,
  useGridApiContext,
  useGridSelector,
  gridPageSelector,
  gridPageCountSelector,
  GridColDef,
  GridRenderCellParams,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import { alpha, styled } from '@mui/material/styles';
import { Avatar, Box, Pagination, Stack, Typography } from '@mui/material';
import { PaginationItem } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import { useNavigate } from 'react-router-dom';
import { getUserLogoLinkByUid, getUserUid } from 'utils/getUid';
import { default as AvatarImage } from '../../../../components/shared/Avatar/Avatar';
import {
  label_delivery_date,
  label_use_case,
  label_use_case_delivery_manager,
} from 'components/Settings/Preferences/CustomLabelCookieHelper';
import ViewDate from 'components/shared/ViewDate/ViewDate';
import { getUserActiveState, INACTIVE_USER_COLOR } from 'utils/userHelpers';
import useUserDropDownData from 'components/shared/Hooks/useUserDropDownData';

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGridPremium)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
        },
      },
    },
  },
}));

interface GridPaginationSettings {
  pagination: boolean;
  autoPageSize: boolean;
  pageSize: number | undefined;
}

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  border: '1px solid #F3F3F3',
  flexDirection: 'column',
  width: '100%',
  '& .MuiFormGroup-options': {
    alignItems: 'center',
    paddingBottom: theme.spacing(1),
    '& > div': {
      minWidth: 100,
      margin: theme.spacing(2),
      marginLeft: 0,
    },
    border: 'none',
  },
}));

function stringToHslColor(str: string, s: number, l: number) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const h = hash % 360;
  return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToHslColor(name, 30, 80),
    },
    children: (
      <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#000000' }}>
        {name.split(' ')[0][0]}
        {name.indexOf(' ') >= 0 ? name.split(' ')[1][0] : null}
      </Typography>
    ),
  };
}

const UseCaseTable = (props: any) => {
  const apiRef = useGridApiRef();
  const navigate = useNavigate();
  const companyUsersDropDownData = useUserDropDownData('company');

  React.useEffect(() => {
    return apiRef.current.subscribeEvent('rowClick', (params) => {
      console.log(params, 'click check');
      navigate('/customer-portal/project/usecase/dashboard', { state: { selectedUseCase: params.row } });
    });
  }, [apiRef]);
  console.log(props, 'prr');
  const initialState: any = {
    columns: {
      columnVisibilityModel: {},
    },
  };

  const columns: GridColDef[] = [
    {
      field: 'case_uid',
      hide: true,
    },
    {
      field: 'case_name',
      hide: false,
      headerName: label_use_case,
      flex: 1,
      editable: false,
      renderCell: (params: GridRenderCellParams) => (
        <Typography
          className="cognisaas-pointer"
          variant="subtitle1"
          sx={{ color: '#093BCD', fontWeight: 400, fontSize: '14px' }}
        >
          {params.row.case_name}
        </Typography>
      ),
    },
    {
      field: 'case_deliverydate',
      hide: false,
      headerName: label_delivery_date,
      flex: 1,
      // editable: true,
      renderCell: (params: GridRenderCellParams) => (
        <Typography variant="subtitle1" sx={{ fontWeight: 500, fontSize: '12px' }}>
          {params?.row?.case_deliverydate && <ViewDate date={params.row.case_deliverydate} />}
        </Typography>
      ),
    },
    {
      field: 'eta_forcasedelivery',
      hide: false,
      headerName: 'ETA for Delivery',
      flex: 1,
      // editable: true,
      renderCell: (params: GridRenderCellParams) => (
        <Typography variant="subtitle1" sx={{ fontWeight: 500, fontSize: '12px' }}>
          {params?.row?.eta_forcasedelivery && <ViewDate date={params.row.eta_forcasedelivery} />}
        </Typography>
      ),
    },
    {
      field: 'case_readinessstate_status',
      hide: false,
      headerName: 'Status',
      flex: 1,
      // editable: true,
      renderCell: (params: GridRenderCellParams) => (
        <>
          {params.row.case_readinessstate_status === 'amber' ? (
            <>
              <Stack direction="row">
                <CircleIcon
                  sx={{
                    color: '#EFA958',
                    height: '14px',
                    width: '14px',
                    marginTop: '5px',
                    marginLeft: '12px',
                  }}
                />
                <Typography sx={{ fontSize: '14px', fontWeight: '400', marginLeft: '8px' }}>At Risk</Typography>
              </Stack>
            </>
          ) : null}
          {params.row.case_readinessstate_status === 'red' ? (
            <>
              <Stack direction="row">
                <CircleIcon
                  sx={{
                    color: '#E75B5C',
                    height: '14px',
                    width: '14px',
                    marginTop: '5px',
                    ml: '8px',
                  }}
                />
                <Typography sx={{ fontSize: '14px', fontWeight: '400', marginLeft: '5px' }}>Overdue</Typography>
              </Stack>
            </>
          ) : null}
          {params.row.case_readinessstate_status === 'green' ? (
            <>
              <Stack direction="row">
                <CircleIcon
                  sx={{
                    color: '#4F9F52',
                    height: '14px',
                    width: '14px',
                    marginTop: '5px',
                    ml: '8px',
                  }}
                />
                <Typography sx={{ fontSize: '14px', fontWeight: '400', marginLeft: '5px' }}>On Track</Typography>
              </Stack>
            </>
          ) : null}
          {params.row.case_readinessstate_status === 'blue' ? (
            <>
              <Stack direction="row">
                <CircleIcon
                  sx={{
                    color: '#4390E1',
                    height: '14px',
                    width: '14px',
                    marginTop: '5px',
                    ml: '8px',
                  }}
                />
                <Typography sx={{ fontSize: '14px', fontWeight: '400', marginLeft: '5px' }}>Completed</Typography>
              </Stack>
            </>
          ) : null}
        </>
      ),
    },

    {
      field: 'case_deliverymanager',
      hide: false,
      headerName: label_use_case_delivery_manager,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        const userUid = getUserUid(params.row.case_deliverymanager);
        const deliveryManagerProfileLink = getUserLogoLinkByUid(userUid);
        return (
          <Stack direction="row">
            {deliveryManagerProfileLink.length > 0 ? (
              <AvatarImage src={deliveryManagerProfileLink} height="34px" width="34px" alt="Delivery manager profile" />
            ) : (
              <Avatar
                style={{ height: '34px', width: '34px', marginTop: '3px' }}
                {...stringAvatar(params.row.case_deliverymanager)}
              />
            )}

            {getUserActiveState(getUserUid(params.value), companyUsersDropDownData) === '0' ? (
              <Typography style={{ color: INACTIVE_USER_COLOR }} m={1} variant="subtitle1">
                {params.value}&nbsp;(Inactive)
              </Typography>
            ) : (
              <Typography m={1} variant="subtitle1">
                {params.value}
              </Typography>
            )}
          </Stack>
        );
      },
    },
  ];

  // eslint-disable-next-line no-unsafe-optional-chaining
  const rows: any = [...props.useCases];

  const data = {
    columns,
    rows,
    initialState,
  };

  function getPaginationItemStyle(item: any) {
    switch (item.type) {
      case 'page':
        if (item.selected) {
          return { background: '#c0c0c0', color: '#2D60F6' };
        }
        return { background: '#ffffff', color: '#000000' };
      case 'previous':
        return {};
      case 'next':
        return {};
    }
  }

  const [pagination] = useState<GridPaginationSettings>({
    pagination: true,
    autoPageSize: false,
    pageSize: 5,
  });

  const CustomPagination = () => {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    console.log(page, pageCount);
    return (
      <Box
        component={'span'}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          margin: '20px 0px',
        }}
      >
        <Typography variant="subtitle1">
          {page == 0 ? page + '-' + 5 : page * 5 + '-' + (page * 5 + 5)} of{' '}
          {/* {location?.state?.selectedProject?.tasks.length} */}
        </Typography>
        <Pagination
          count={pageCount}
          onChange={(event, value) => {
            // console.log(value, 'val check');
            apiRef.current.setPage(value - 1);
          }}
          shape="rounded"
          page={page === 0 ? 1 : page + 1}
          renderItem={(item) => {
            // console.log(item, 'item check');
            return <PaginationItem {...item} sx={getPaginationItemStyle(item)} />;
          }}
        />
      </Box>
    );
  };

  columns.forEach((item: any) => {
    if (item.hide) {
      initialState.columns.columnVisibilityModel[item.field] = false;
    }
  });
  return (
    <StyledBox>
      <StripedDataGrid
        hideFooter={false}
        autoHeight
        sx={{
          boxShadow: 0,
          border: 0,
          mt: '10px',
          borderColor: 'primary.light',
          '& .MuiDataGrid-cell:hover': {
            color: 'primary.main',
          },
        }}
        {...data}
        components={{
          Toolbar: (() => {
            return null;
          })(),
          Footer: CustomPagination,
        }}
        componentsProps={{
          toolbar: { showQuickFilter: true },
        }}
        loading={false}
        getRowId={(row) => row['case_uid']}
        disableSelectionOnClick
        rowThreshold={0}
        initialState={{
          ...data.initialState,
          pinnedColumns: { left: ['case_name'] },
        }}
        {...pagination}
        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'odd' : 'odd')}
        experimentalFeatures={{ newEditingApi: true }}
        apiRef={apiRef}
      />
    </StyledBox>
  );
};

export default UseCaseTable;
