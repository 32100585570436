import { Avatar, Button, ButtonGroup, Divider, Drawer, Stack, Typography, Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from '../../../store/hooks';
import {
  selectMappedClientsListToReqState,
  selectOpenUsecasesState,
  selectTopExpandProductMapping,
  selectTopExpandUsecaseMapping,
  selectTopLandProductMapping,
  selectTopLandUsecaseMapping,
} from '../../../store/modules/ActionableInsights/selector';
import {
  fetchClientInfoByMultiplefeatureUids,
  fetchOpenUsecases,
  fetchTopExpandProductMapping,
  fetchTopExpandUsecaseMapping,
  fetchTopLandProductMapping,
  fetchTopLandUsecaseMapping,
} from '../../../store/modules/ActionableInsights/slice';
import RadioFilterChip from '../../shared/FilterChipv2/RadioFilterChip';
import LabeledBadge from '../../shared/LabeledBadge/LabeledBadge';
import { generateOptions } from '../../../utils/dataHelpers';
import CognisaasMultiSelectWithSearch from '../../shared/CognisaasMultiSelectWithSearch/CognisaasMultiSelectWithSearch';
import { FilterableData } from '../../../utils/filteringFunctions';
import { selectPrAndReqDataState } from '../../../store/modules/ProductRequirements/ProductDetails/selector';
import { fetchProductAndRequirements } from '../../../store/modules/ProductRequirements/ProductDetails/slice';
import { ReactComponent as NoRecordsIcon } from '../../../assets/svgs/no_records_mini.svg';
import CognisaasMultiSelectWithGroupingAndSearch from '../../shared/CognisaasMultiSelectWithSearch/CognisaasMultiSelectWithGrouping/CognisaasMultiSelectWithGroupingAndSearch';
import {
  label_arr,
  label_client,
  label_use_case,
  label_use_case_delivery_manager,
} from 'components/Settings/Preferences/CustomLabelCookieHelper';
import UseCaseClientMapping from './UseCaseClientMapping';
import ProductClientMapping from './ProductClientMapping';
import { selectCustomerDetail } from 'store/modules/Common/selector';
import { DataGridPremium, GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium';
import { Link } from 'react-router-dom';
import { getUserLogoLinkByUid, getUserUid } from 'utils/getUid';
import { default as AvatarImage } from '../../shared/Avatar/Avatar';
import useCustomNames from 'components/shared/CustomNames/useCustomNames';
function stringToHslColor(str: string, s: number, l: number) {
  let hash = 0;

  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const h = hash % 360;
  return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToHslColor(name, 30, 80),
    },
    children: (
      <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#000000' }}>
        {name.split(' ')[0][0]}
        {name.indexOf(' ') >= 0 ? name.split(' ')[1][0] : null}
      </Typography>
    ),
  };
}
const ClientMapping = () => {
  const [selectedClientMapping, setSelectedClientMapping] = useState<'Usecase-Client' | 'Product-Client'>(
    'Usecase-Client'
  );
  const dispatch = useAppDispatch();
  const topLandUsecaseMappingState = useAppSelector(selectTopLandUsecaseMapping);
  const topExpandUsecaseMappingState = useAppSelector(selectTopExpandUsecaseMapping);
  const topLandProductMappingState = useAppSelector(selectTopLandProductMapping);
  const topExpandProductMappingState = useAppSelector(selectTopExpandProductMapping);

  const [selectedCategory, setSelectedCategory] = useState<'land' | 'expand'>('land');
  const [isMappedUsecaseClientDrawerOpen, setIsMappedUsecaseClientDrawer] = useState<boolean>(false);
  const [isMappedProductClientDrawerOpen, setIsMappedProductClientDrawer] = useState<boolean>(false);
  const [selectedUsecaseMappingOption, setSelectedUsecaseMappingOption] = useState<string[]>([]);
  const [selectedRequirementMappingOption, setSelectedRequirementMappingOption] = useState<Record<string, string>[]>(
    []
  );
  const openUsecasesFromServerState = useAppSelector(selectOpenUsecasesState);
  const [usecaseOptions, setUsecaseOptions] = useState<string[]>([]);
  const [landUsecases, setLandUsecases] = useState<any[]>([]);
  const [expandUsecases, setExpandUsecases] = useState<any[]>([]);
  const [landProductReqClient, setLandProductReqClient] = useState<any[]>([]);
  const [expandProductReqClient, setExpandProductReqClient] = useState<any[]>([]);
  const productAndTheirRequirement = useAppSelector(selectPrAndReqDataState);
  const clientsMappedWithRequirementsState = useAppSelector(selectMappedClientsListToReqState);
  const customerDetail = useAppSelector(selectCustomerDetail);
  const { displayDataWithCustomNames } = useCustomNames();

  useEffect(() => {
    dispatch(fetchTopLandUsecaseMapping());
    dispatch(fetchTopExpandUsecaseMapping());
    dispatch(fetchTopExpandProductMapping());
    dispatch(fetchTopLandProductMapping());
  }, []);
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: `${customerDetail?.currency_code}`,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  const columnsUsecaseClientMapping: GridColDef[] = [
    {
      field: 'client_name',
      headerName: `${label_client} Name`,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <Link state={{ clientName: params.row.client_name }} to={`/client-list/client/${params.row.client_uid}`}>
          {params.value}
        </Link>
      ),
    },
    {
      field: 'case_name',
      headerName: `${label_use_case} Name`,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <Link state={{ clientName: params.row.client_name }} to={`/client-list/client/${params.row.client_uid}`}>
          {params.value}
        </Link>
      ),
    },
    {
      field: 'case_deliverymanager',
      headerName: label_use_case_delivery_manager,
      width: 240,
      renderCell: (params: GridRenderCellParams) => {
        const userUid = getUserUid(params.row.case_deliverymanager);
        const deliveryManagerProfileLink = getUserLogoLinkByUid(userUid);
        return (
          <Stack direction="row">
            {deliveryManagerProfileLink.length > 0 ? (
              <AvatarImage src={deliveryManagerProfileLink} height="34px" width="34px" alt="Delivery manager profile" />
            ) : (
              <Avatar style={{ height: '34px', width: '34px', marginTop: '3px' }} {...stringAvatar(params.value)} />
            )}

            <Typography m={1} variant="subtitle1">
              {params.value}
            </Typography>
          </Stack>
        );
      },
    },
    {
      headerName: `${label_client} Status`,
      field: 'case_readinessstate_status',
      // width: 185,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <Stack>
          <Stack spacing={1} direction="row">
            <span
              style={{ marginRight: '4px' }}
              className={
                params.value == 'red'
                  ? 'red_status_box_task'
                  : params.value == 'amber'
                  ? 'amber_status_box_task'
                  : params.value == 'green'
                  ? 'green_status_box_task'
                  : params.value == 'blue'
                  ? 'blue_status_box_task'
                  : params.value == 'grey'
                  ? 'grey_status_box_task'
                  : ''
              }
            ></span>
            {params.value == 'red'
              ? 'Overdue'
              : params.value == 'amber'
              ? 'At Risk'
              : params.value == 'green'
              ? 'On Track'
              : params.value == 'blue'
              ? 'Completed'
              : params.value == 'grey'
              ? 'Deferred'
              : ''}
          </Stack>
        </Stack>
      ),
    },
    {
      field: 'client_account_worth',
      headerName: `${label_arr}`,
      groupable: false,
      flex: 1,
      valueFormatter: ({ value }) => {
        if (!value) {
          return value;
        }
        return currencyFormatter.format(value);
      },
    },
    {
      field: 'case_stage',
      headerName: 'Stage',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <Typography variant="subtitle1">{params.value}</Typography>,
    },
  ];
  const columnsProductClientMapping: GridColDef[] = [
    {
      field: 'client_name',
      headerName: `${label_client} Name`,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <Link state={{ clientName: params.row.client_name }} to={`/client-list/client/${params.row.client_uid}`}>
          {params.value}
        </Link>
      ),
    },
    {
      field: 'case_name',
      headerName: `${label_use_case} Name`,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <Link
          state={{
            clientName: params.row.client_name,
            clientUid: params.row.client_uid,
            projectUid: params.row.project_uid,
          }}
          to={`/projects/project/${params.row.association_uid}/${params.row.project_uid}/${params.row.case_uid}`}
        >
          {params.value}
        </Link>
      ),
    },
    {
      headerName: `${label_client} Status`,
      field: 'client_readiness_status',
      // width: 185,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <Stack>
          <Stack spacing={1} direction="row">
            <span
              style={{ marginRight: '4px' }}
              className={
                params.value == 'red'
                  ? 'red_status_box_task'
                  : params.value == 'amber'
                  ? 'amber_status_box_task'
                  : params.value == 'green'
                  ? 'green_status_box_task'
                  : params.value == 'blue'
                  ? 'blue_status_box_task'
                  : params.value == 'grey'
                  ? 'grey_status_box_task'
                  : ''
              }
            ></span>
            {params.value == 'red'
              ? 'Overdue'
              : params.value == 'amber'
              ? 'At Risk'
              : params.value == 'green'
              ? 'On Track'
              : params.value == 'blue'
              ? 'Completed'
              : params.value == 'grey'
              ? 'Deferred'
              : ''}
          </Stack>
        </Stack>
      ),
    },
    {
      field: 'client_account_worth',
      headerName: `${label_arr}`,
      groupable: false,
      flex: 1,
      valueFormatter: ({ value }) => {
        if (!value) {
          return value;
        }
        return currencyFormatter.format(value);
      },
    },
    {
      field: 'stage_journerystage',
      headerName: 'Stage',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <Typography variant="subtitle1">{params.value}</Typography>,
    },
    {
      field: 'segment_name',
      headerName: 'Segment',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <Typography variant="subtitle1">{params.value}</Typography>,
    },
  ];
  useEffect(() => {
    if (openUsecasesFromServerState.data.length === 0) {
      dispatch(fetchOpenUsecases());
    } else {
      if (openUsecasesFromServerState.status === 'success') {
        setUsecaseOptions(generateOptions(openUsecasesFromServerState.data, 'case_name'));
      }
    }
  }, [openUsecasesFromServerState]);

  useEffect(() => {
    if (selectedUsecaseMappingOption.length > 0) {
      // needed land usecases
      const filteredLandUsecases = new FilterableData(openUsecasesFromServerState.data);
      filteredLandUsecases
        .customFilter('case_needed_status', 'Land')
        .containsThisList('case_name', selectedUsecaseMappingOption);
      setLandUsecases(filteredLandUsecases.toArray());

      //needed expand usecases
      const filteredExpandUsecases = new FilterableData(openUsecasesFromServerState.data);
      filteredExpandUsecases
        .customFilter('case_needed_status', 'Expand')
        .containsThisList('case_name', selectedUsecaseMappingOption);
      setExpandUsecases(filteredExpandUsecases.toArray());
    } else {
      setLandUsecases([]);
      setExpandUsecases([]);
    }
  }, [selectedUsecaseMappingOption]);

  useEffect(() => {
    if (selectedRequirementMappingOption.length > 0) {
      const temp = selectedRequirementMappingOption.map((item) => ({
        feature_uid: item.feature_uid,
        customer_uid: item.customer_uid,
      }));
      dispatch(fetchClientInfoByMultiplefeatureUids([...temp]))
        .unwrap()
        .then((productClientMapped) => {
          if (productClientMapped) {
            const filteredLandProductReqs = new FilterableData(productClientMapped);
            filteredLandProductReqs.customFilter('case_needed_status', 'Land');
            setLandProductReqClient(filteredLandProductReqs.toArray());
            const filteredExpandProductReqs = new FilterableData(productClientMapped);
            filteredExpandProductReqs.customFilter('case_needed_status', 'Expand');
            setExpandProductReqClient(filteredExpandProductReqs.toArray());
          }
        });
    } else {
      setLandProductReqClient([]);
      setExpandProductReqClient([]);
    }
  }, [selectedRequirementMappingOption]);

  useEffect(() => {
    if (productAndTheirRequirement.data.length === 0 && productAndTheirRequirement.status === 'idle') {
      dispatch(fetchProductAndRequirements());
    }
  }, [productAndTheirRequirement]);

  return (
    <Box sx={{ padding: '20px 0px' }}>
      <Stack direction={'row'} justifyContent="space-between">
        <ButtonGroup
          variant="outlined"
          aria-label="outlined button group"
          sx={{
            display: 'inline-flex',
            flexWrap: 'wrap',
            backgroundColor: 'white',
            border: '1px solid #E5E5E5',
            borderRadius: '4px',
          }}
        >
          <Button
            onClick={() => {
              setSelectedClientMapping('Usecase-Client');
            }}
            variant="text"
            sx={selectedClientMapping === 'Usecase-Client' ? { background: 'rgba(45, 96, 246,0.15)' } : {}}
          >
            <LabeledBadge
              text={label_use_case + '-' + label_client}
              badgeCount={topLandUsecaseMappingState.data.length + topExpandUsecaseMappingState.data.length}
              isActive={selectedClientMapping === 'Usecase-Client'}
            />
          </Button>
          <Button
            onClick={() => {
              setSelectedClientMapping('Product-Client');
            }}
            variant="text"
            sx={selectedClientMapping === 'Product-Client' ? { background: 'rgba(45, 96, 246,0.15)' } : {}}
          >
            <LabeledBadge
              text={'Product-' + label_client}
              badgeCount={topLandProductMappingState.data.length + topExpandProductMappingState.data.length}
              isActive={selectedClientMapping === 'Product-Client'}
            />
          </Button>
        </ButtonGroup>
        {/* mapped client button */}
        <Button
          variant="contained"
          onClick={() => {
            selectedClientMapping === 'Usecase-Client'
              ? setIsMappedUsecaseClientDrawer(true)
              : (setIsMappedProductClientDrawer(true), setLandUsecases([]), setExpandUsecases([]));
          }}
        >
          Mapped {label_client}s
        </Button>
      </Stack>

      {/* filter group */}
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '10px 0px 10px 0px' }}>
        <Typography variant="subtitle1" className="label-text">
          Filter
        </Typography>

        <RadioFilterChip
          name="Category"
          options={['Land', 'Expand']}
          checkedItemsForList={[`${selectedCategory}`]}
          handleOptionClicked={(value) => {
            if (value.toLowerCase() === 'land') setSelectedCategory('land');
            else setSelectedCategory('expand');
          }}
          context={selectedClientMapping === 'Usecase-Client' ? 'usecase' : 'project'}
        />

        <Box component={'span'} sx={{ width: '0px', height: '0px', marginRight: '20px' }}></Box>
      </Box>

      {selectedClientMapping === 'Usecase-Client' ? (
        <UseCaseClientMapping
          selectedClientMapping={selectedClientMapping}
          selectedCategory={selectedCategory}
          topExpandUsecaseMappingState={topExpandUsecaseMappingState}
          topLandUsecaseMappingState={topLandUsecaseMappingState}
        />
      ) : (
        <ProductClientMapping
          selectedClientMapping={selectedClientMapping}
          selectedCategory={selectedCategory}
          topExpandProductMappingState={topExpandProductMappingState}
          topLandProductMappingState={topLandProductMappingState}
          productAndTheirRequirement={productAndTheirRequirement}
          clientsMappedWithRequirementsState={clientsMappedWithRequirementsState}
        />
      )}
      {/* isMappedUsecaseClientDrawerOpen */}
      <Drawer
        anchor={'right'}
        open={isMappedUsecaseClientDrawerOpen}
        onClose={() => {
          setIsMappedUsecaseClientDrawer((prevState) => !prevState);
          setLandUsecases([]);
          setExpandUsecases([]);
          setSelectedUsecaseMappingOption([]);
        }}
        PaperProps={{
          sx: { width: '920px', overflowX: 'clip' },
        }}
      >
        <Box sx={{ padding: '95px 20px 0px 20px', maxHeight: '83%' }}>
          <>
            <CognisaasMultiSelectWithSearch
              allOptions={usecaseOptions}
              selectedOption={selectedUsecaseMappingOption}
              setSelectedOptions={setSelectedUsecaseMappingOption}
            />

            {/* show the data here  */}

            <Divider sx={{ margin: '20px 0px' }} />

            <Box sx={{ overflowY: 'scroll', maxHeight: '87%' }}>
              <Typography variant="subtitle1" sx={{ marginBottom: '8px' }}>
                {`List of ${label_client}s dependent on the selected template(s) for 'Land' ${label_use_case}s`}
              </Typography>
              {landUsecases.length !== 0 && (
                <DataGridPremium
                  sx={{ borderRadius: 0 }}
                  columns={columnsUsecaseClientMapping}
                  rows={displayDataWithCustomNames(landUsecases)}
                  getRowId={(row) => row.case_uid}
                  hideFooterRowCount
                  disableSelectionOnClick
                  autoHeight
                />
              )}
              {/* show when empty list */}
              {landUsecases.length === 0 && selectedUsecaseMappingOption.length === 0 && (
                <Stack alignItems={'center'} justifyContent={'center'} sx={{ margin: '20px 0px' }}>
                  <Typography variant="subtitle1" className="label-text" sx={{ marginBottom: '20px' }}>
                    No Options Selected, Select One Now!
                  </Typography>

                  <NoRecordsIcon />
                </Stack>
              )}
              {landUsecases.length === 0 && selectedUsecaseMappingOption.length !== 0 && (
                <Stack alignItems={'center'} justifyContent={'center'} sx={{ margin: '20px 0px' }}>
                  <Typography variant="subtitle1" className="label-text" sx={{ marginBottom: '20px' }}>
                    No Records Found!
                  </Typography>

                  <NoRecordsIcon />
                </Stack>
              )}
              <Divider sx={{ margin: '20px 0px' }} />
              <Typography variant="subtitle1">
                {`List of ${label_client} dependent on the selected template(s) for 'Expand' ${label_use_case}s`}
              </Typography>

              {expandUsecases.length !== 0 && (
                <DataGridPremium
                  sx={{ borderRadius: 0 }}
                  columns={columnsUsecaseClientMapping}
                  rows={displayDataWithCustomNames(expandUsecases)}
                  getRowId={(row) => row.case_uid}
                  hideFooterRowCount
                  disableSelectionOnClick
                  autoHeight
                />
              )}
              {expandUsecases.length === 0 && selectedUsecaseMappingOption.length === 0 && (
                <Stack alignItems={'center'} justifyContent={'center'} sx={{ margin: '20px 0px' }}>
                  <Typography variant="subtitle1" className="label-text" sx={{ marginBottom: '20px' }}>
                    No Options Selected, Select One Now!
                  </Typography>
                  <NoRecordsIcon />
                </Stack>
              )}
              {expandUsecases.length === 0 && selectedUsecaseMappingOption.length !== 0 && (
                <Stack alignItems={'center'} justifyContent={'center'} sx={{ margin: '20px 0px' }}>
                  <Typography variant="subtitle1" className="label-text" sx={{ marginBottom: '20px' }}>
                    No Records Found!
                  </Typography>
                  <NoRecordsIcon />
                </Stack>
              )}
            </Box>
          </>
        </Box>
      </Drawer>

      {/*isMappedProductClientDrawerOpen */}
      <Drawer
        anchor={'right'}
        open={isMappedProductClientDrawerOpen}
        onClose={() => {
          setIsMappedProductClientDrawer((prevState) => !prevState);
          setLandUsecases([]);
          setExpandUsecases([]);
          setSelectedRequirementMappingOption([]);
        }}
        PaperProps={{
          sx: { width: '920px', overflowX: 'clip' },
        }}
      >
        <Box sx={{ padding: '95px 20px 0px 20px', maxHeight: '83%' }}>
          <>
            <CognisaasMultiSelectWithGroupingAndSearch
              dataToGenerateoptions={productAndTheirRequirement.data}
              groupHeaderKey={'product_name'}
              optionSubLevelKey={'feature'}
              optionKeyForDataDisplay={'feature_description'}
              dataKeyWithUniqueDataAtOptionLevel={'feature_uid'}
              dataKeysWhoseDataRequiredForFutureUse={['feature_uid', 'customer_uid', 'feature_description']}
              selectedOption={selectedRequirementMappingOption}
              setSelectedOptions={setSelectedRequirementMappingOption}
            />

            {/* show the data here  */}
            <Divider sx={{ margin: '20px 0px' }} />
            <Box sx={{ overflowY: 'scroll', maxHeight: '87%' }}>
              <Typography variant="subtitle1" sx={{ marginBottom: '8px' }}>
                {`List of ${label_client}s dependent on the selected product requirment(s) for 'Land' ${label_use_case}s`}
              </Typography>
              {landProductReqClient.length !== 0 && (
                <DataGridPremium
                  sx={{ borderRadius: 0 }}
                  columns={columnsProductClientMapping}
                  rows={displayDataWithCustomNames(landProductReqClient)}
                  getRowId={(row) => row.case_uid}
                  hideFooterRowCount
                  disableSelectionOnClick
                  autoHeight
                />
              )}
              {/* show when empty list */}
              {landProductReqClient.length === 0 && selectedRequirementMappingOption.length === 0 && (
                <Stack alignItems={'center'} justifyContent={'center'} sx={{ margin: '20px 0px' }}>
                  <Typography variant="subtitle1" className="label-text" sx={{ marginBottom: '20px' }}>
                    No Options Selected, Select One Now!
                  </Typography>

                  <NoRecordsIcon />
                </Stack>
              )}
              {landProductReqClient.length === 0 && selectedRequirementMappingOption.length !== 0 && (
                <Stack alignItems={'center'} justifyContent={'center'} sx={{ margin: '20px 0px' }}>
                  <Typography variant="subtitle1" className="label-text" sx={{ marginBottom: '20px' }}>
                    No Records Found!
                  </Typography>

                  <NoRecordsIcon />
                </Stack>
              )}
              {/* </Box> */}
              <Divider sx={{ margin: '20px 0px' }} />
              <Typography variant="subtitle1">
                {`List of ${label_client}s dependent on the selected selected product requirment(s) for 'Expand' ${label_use_case}s`}
              </Typography>
              {expandProductReqClient.length !== 0 && (
                <DataGridPremium
                  sx={{ borderRadius: 0 }}
                  columns={columnsProductClientMapping}
                  rows={displayDataWithCustomNames(expandProductReqClient)}
                  getRowId={(row) => row.case_uid}
                  hideFooterRowCount
                  disableSelectionOnClick
                  autoHeight
                />
              )}
              {/* show when empty list */}
              {expandProductReqClient.length === 0 && selectedRequirementMappingOption.length === 0 && (
                <Stack alignItems={'center'} justifyContent={'center'} sx={{ margin: '20px 0px' }}>
                  <Typography variant="subtitle1" className="label-text" sx={{ marginBottom: '20px' }}>
                    No Options Selected, Select One Now!
                  </Typography>

                  <NoRecordsIcon />
                </Stack>
              )}
              {expandProductReqClient.length === 0 && selectedRequirementMappingOption.length !== 0 && (
                <Stack alignItems={'center'} justifyContent={'center'} sx={{ margin: '20px 0px' }}>
                  <Typography variant="subtitle1" className="label-text" sx={{ marginBottom: '20px' }}>
                    No Records Found!
                  </Typography>

                  <NoRecordsIcon />
                </Stack>
              )}
            </Box>
          </>
        </Box>
      </Drawer>
    </Box>
  );
};

export default ClientMapping;
