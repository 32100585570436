import { Grid, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ReactComponent as RightArrowIcon } from '../../../assets/svgs/rightArrow.svg';
import {
  fetchRequiredProjects,
  fetchOpenUsecases,
  fetchProjectsRevenueByCustomer,
} from '../../../store/modules/ActionableInsights/slice';
import {
  selectProjectsAtLossOrOverdue,
  selectProjectsAtRisk,
  selectProjectsOnTrack,
  selectRevenueDataState,
  selectRevenueImpactDataState,
  selectUsecaseAtLossOrOverdue,
  selectUsecaseAtRisk,
  selectUsecaseOnTrack,
} from '../../../store/modules/ActionableInsights/selector';
import { useAppSelector } from '../../../store/hooks';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { findSumOfThisFieldInData, formatCurrency } from '../../../utils/dataHelpers';
import { addFilterGroup } from '../../../store/modules/AllFilters/slice';
import { getAllSegmentValues, getAllUsers } from '../../../store/modules/Common/slice';
import { ResponsiveBar } from '@nivo/bar';
import { AxisTickProps } from '@nivo/axes';
import { useNavigate } from 'react-router-dom';
import { selectCustomerDetail } from 'store/modules/Common/selector';
import { getCustomerDetail } from 'store/modules/Common/slice';
import { setUpFiltersForClientListPage } from 'store/modules/ui/Filters/slice';
import { selectSegmentValues, selectUsersOfCompany } from 'store/modules/Common/selector';
import SelectedFilterList from 'components/shared/CognisaasFilters/SelectedFilterList/SelectedFilterList';
import { FilterableData } from 'utils/filteringFunctions';
import { selectAllFilters } from 'store/modules/AllFilters/selector';
import {
  label_arr,
  label_cs_owner,
  label_project,
  label_sales_owner,
  label_use_case,
} from 'components/Settings/Preferences/CustomLabelCookieHelper';
import getSymbolFromCurrency from 'currency-symbol-map';
import useCommonAppConfig from 'components/Common/useCommonAppConfig';

const getBarColor = (bar: any) => bar.data.color;
interface Props {
  setRevenueImpactPage: any;
}

const ValueOutsideDeliveryStatus = ({ bars }: any) => {
  return bars.map((bar: any) => {
    const {
      key,
      width,
      x,
      y,
      data: { value },
    } = bar;
    return (
      <g key={key} transform={`translate(${x}, ${y})`}>
        <text
          transform={`translate(${width - 12}, ${-5})`}
          textAnchor="middle"
          fontSize="12px"
          fontFamily="Noto Sans"
          color="#7A7A7A"
        >
          {/* YOUR LABEL HERE */}
          {value}
        </text>
      </g>
    );
  });
};

const theme = {
  fontFamily: 'Noto Sans',
  fontWeight: 400,
  fontSize: 12,
  axis: {
    domain: {
      line: {
        stroke: '#202020',
        strokeWidth: 0.3,
      },
    },
  },
  grid: {
    line: {
      strokeWidth: 1,
      strokeDasharray: '2 2',
    },
  },
  Bar: {},
};

interface Size {
  width: number;
  height: number;
}

// Hook
function useWindowSize(): Size {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<Size>({
    width: 0,
    height: 0,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

const LandingPage = (props: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const revenueImpactDataState = useAppSelector(selectRevenueImpactDataState);
  const projectsOntrack = useAppSelector(selectProjectsOnTrack);
  const projectsAtRisk = useAppSelector(selectProjectsAtRisk);
  const projectsOverdue = useAppSelector(selectProjectsAtLossOrOverdue);
  const usecaseOnTrack = useAppSelector(selectUsecaseOnTrack);
  const usecaseAtRisk = useAppSelector(selectUsecaseAtRisk);
  const usecaseOverdue = useAppSelector(selectUsecaseAtLossOrOverdue);
  const customerDetail = useAppSelector(selectCustomerDetail);
  const allSegments = useAppSelector(selectSegmentValues);

  const [projectsOverdueCount, setProjectsOverdueCount] = useState(0);
  const [projectAtRiskCount, setProjectAtRiskCount] = useState(0);
  const [projectOntrackCount, setProjectOntrackCount] = useState(0);
  const [usecaseOverdueCount, setUsecaseOverdueCount] = useState(0);
  const [usecaseAtRiskCount, setUsecaseAtRiskCount] = useState(0);
  const [usecaseOntrackCount, setUsecaseOntrackCount] = useState(0);
  const [revenueOnTrackCount, setRevenueOnTrackCount] = useState(0);
  const [revenueAtRiskCount, setRevenueAtRiskCount] = useState(0);
  const [revenueOverdueCount, setRevenueOverdueCount] = useState(0);
  const allUsers = useAppSelector(selectUsersOfCompany);
  const allFilters = useAppSelector(selectAllFilters);

  const projectRevenueData = useAppSelector(selectRevenueDataState);

  const size: Size = useWindowSize();

  const { saveAppTableState } = useCommonAppConfig();

  const ValueOutside = ({ bars }: any) => {
    return bars.map((bar: any) => {
      const {
        key,
        width,
        x,
        y,
        data: { value },
      } = bar;
      return (
        <g key={key} transform={`translate(${x}, ${y})`}>
          <text
            transform={`translate(${width - 12}, ${-5})`}
            textAnchor="middle"
            fontSize="12px"
            fontFamily="Noto Sans"
            color="#7A7A7A"
          >
            {/* YOUR LABEL HERE */}
            {formatCurrency(value, customerDetail?.currency_code)}
          </text>
        </g>
      );
    });
  };

  useEffect(() => {
    dispatch(fetchRequiredProjects());
    dispatch(fetchOpenUsecases());
    dispatch(fetchProjectsRevenueByCustomer());
    dispatch(getAllSegmentValues());

    if (allUsers.length == 0) {
      dispatch(getAllUsers()); // getting all users
    }
  }, []);

  useEffect(() => {
    dispatch(setUpFiltersForClientListPage());
  }, []);

  useEffect(() => {
    if (allUsers.length > 0 && allSegments.data.length > 0) {
      dispatch(
        addFilterGroup({
          idName: 'actionableInsightsRevenueImpactLandingPage',
          appliedFilters: [
            {
              name: label_sales_owner,
              dataKey: 'client_sales_owner',
              isSelected: true,
              equalToValue: [],
              availableOptions: [...allUsers.map((item: any) => item.user_username), 'Not Assigned'],
            },
            {
              name: label_cs_owner,
              dataKey: 'client_account_owner',
              isSelected: true,
              equalToValue: [],
              availableOptions: [...allUsers.map((item: any) => item.user_username), 'Not Assigned'],
            },
            {
              name: 'Segment',
              dataKey: 'segment_name',
              isSelected: true,
              equalToValue: [],
              availableOptions: allSegments.data.map((item: any) => item.segment_name),
            },
          ],
        })
      );
    }
  }, [allUsers, allSegments.data]);

  useEffect(() => {
    dispatch(getCustomerDetail());
  }, []);

  // actual data filtering is happening here
  useEffect(() => {
    if (allFilters['actionableInsightsRevenueImpactLandingPage']) {
      const newfilteredProjectOnTrack = new FilterableData(projectsOntrack);
      newfilteredProjectOnTrack.bulkFilter(allFilters['actionableInsightsRevenueImpactLandingPage'].appliedFilters);
      setProjectOntrackCount(newfilteredProjectOnTrack.toArray().length);

      const newfilteredProjectAtRisk = new FilterableData(projectsAtRisk);
      newfilteredProjectAtRisk.bulkFilter(allFilters['actionableInsightsRevenueImpactLandingPage'].appliedFilters);
      setProjectAtRiskCount(newfilteredProjectAtRisk.toArray().length);

      const newfilteredProjectOverdue = new FilterableData(projectsOverdue);
      newfilteredProjectOverdue.bulkFilter(allFilters['actionableInsightsRevenueImpactLandingPage'].appliedFilters);
      setProjectsOverdueCount(newfilteredProjectOverdue.toArray().length);

      // usecase filtering
      const newfilteredUsecaseOnTrack = new FilterableData(usecaseOnTrack);
      newfilteredUsecaseOnTrack.bulkFilter(allFilters['actionableInsightsRevenueImpactLandingPage'].appliedFilters);
      setUsecaseOntrackCount(newfilteredUsecaseOnTrack.toArray().length);

      const newfilteredUsecaseAtRisk = new FilterableData(usecaseAtRisk);
      newfilteredUsecaseAtRisk.bulkFilter(allFilters['actionableInsightsRevenueImpactLandingPage'].appliedFilters);
      setUsecaseAtRiskCount(newfilteredUsecaseAtRisk.toArray().length);

      const newfilteredUsecaseOverdue = new FilterableData(usecaseOverdue);
      newfilteredUsecaseOverdue.bulkFilter(allFilters['actionableInsightsRevenueImpactLandingPage'].appliedFilters);
      setUsecaseOverdueCount(newfilteredUsecaseOverdue.toArray().length);

      // revenue impact filtering
      const newfilteredRevenueOnTrack = new FilterableData(revenueImpactDataState.data.revenueOfOnTrack);
      newfilteredRevenueOnTrack.bulkFilter(allFilters['actionableInsightsRevenueImpactLandingPage'].appliedFilters);
      setRevenueOnTrackCount(
        findSumOfThisFieldInData(newfilteredRevenueOnTrack.toArray(), 'revenue', {
          dataKey: 'client_readiness_status',
          value: 'green',
        })
      );

      const newfilteredRevenueAtRisk = new FilterableData(revenueImpactDataState.data.revenueOfAtRisk);
      newfilteredRevenueAtRisk.bulkFilter(allFilters['actionableInsightsRevenueImpactLandingPage'].appliedFilters);
      setRevenueAtRiskCount(
        findSumOfThisFieldInData(newfilteredRevenueAtRisk.toArray(), 'revenue', {
          dataKey: 'client_readiness_status',
          value: 'amber',
        })
      );

      const newfilteredRevenueOverdue = new FilterableData(revenueImpactDataState.data.revenueOfOverdue);
      newfilteredRevenueOverdue.bulkFilter(allFilters['actionableInsightsRevenueImpactLandingPage'].appliedFilters);
      setRevenueOverdueCount(
        findSumOfThisFieldInData(newfilteredRevenueOverdue.toArray(), 'revenue', {
          dataKey: 'client_readiness_status',
          value: 'red',
        })
      );
    }
  }, [allFilters]);
  useEffect(() => {
    setProjectOntrackCount(projectsOntrack.length);
    setProjectAtRiskCount(projectsAtRisk.length);
    setProjectsOverdueCount(projectsOverdue.length);
    setUsecaseOntrackCount(usecaseOnTrack.length);
    setUsecaseAtRiskCount(usecaseAtRisk.length);
    setUsecaseOverdueCount(usecaseOverdue.length);
  }, [projectsOntrack, projectsAtRisk, projectsOverdue, usecaseOnTrack, usecaseAtRisk, usecaseOverdue]);

  useEffect(() => {
    setRevenueOnTrackCount(
      findSumOfThisFieldInData(projectRevenueData.data, 'revenue', {
        dataKey: 'client_readiness_status',
        value: 'green',
      })
    );

    setRevenueAtRiskCount(
      findSumOfThisFieldInData(projectRevenueData.data, 'revenue', {
        dataKey: 'client_readiness_status',
        value: 'amber',
      })
    );

    setRevenueOverdueCount(
      findSumOfThisFieldInData(projectRevenueData.data, 'revenue', {
        dataKey: 'client_readiness_status',
        value: 'red',
      })
    );
  }, [projectRevenueData.data]);

  const handleArrLossClick = () => {
    props.setRevenueImpactPage('project-with-arr-loss');
  };

  const handleArrOnTrackClick = () => {
    props.setRevenueImpactPage('project-with-arr-on-track');
  };

  const handleArrAtRiskClick = () => {
    props.setRevenueImpactPage('project-with-arr-at-risk');
  };

  const data = [
    {
      id: 'On Track',
      count: revenueOnTrackCount,
      color: '#4F9F52',
    },
    {
      id: 'At Risk',
      count: revenueAtRiskCount,
      color: '#FFA100',
    },
    {
      id: 'Overdue',
      count: revenueOverdueCount,
      color: '#E75B5C',
    },
  ];

  const handleTableSave = (idName: string, thisTableState: any) => {
    saveAppTableState(idName, thisTableState);
  };

  return (
    <Box>
      <Grid container>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', margin: '10px 0px' }}>
            <Box sx={{ marginRight: '20px' }} className="cognisaas-pointer" onClick={handleArrLossClick}>
              <Grid
                sx={{
                  width: '200px',
                  height: '80px',
                  background: 'white',
                  border: '1px solid #E5E5E5',
                  borderRadius: '4px',
                  padding: '12px',
                }}
                container
                alignItems={'center'}
              >
                <Grid item xs={1}>
                  <Box
                    sx={{
                      width: '3px',
                      height: '44px',
                      backgroundColor: '#E75B5C',
                      borderRadius: '4px',
                    }}
                  ></Box>
                </Grid>
                <Grid item xs={11}>
                  <Grid container spacing={1} alignItems={'space-between'}>
                    <Grid item xs={12}>
                      <Grid container alignItems={'center'}>
                        <Grid item xs={11}>
                          <Typography variant="subtitle1">{label_arr} Overdue</Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <RightArrowIcon />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h2">
                        {formatCurrency(revenueOverdueCount, customerDetail?.currency_code)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>

            <Box sx={{ marginRight: '20px' }} className="cognisaas-pointer" onClick={handleArrAtRiskClick}>
              <Grid
                sx={{
                  width: '200px',
                  height: '80px',
                  background: 'white',
                  border: '1px solid #E5E5E5',
                  borderRadius: '4px',
                  padding: '12px',
                }}
                container
                alignItems={'center'}
              >
                <Grid item xs={1}>
                  <Box
                    sx={{
                      width: '3px',
                      height: '44px',
                      backgroundColor: '#EFA958',
                      borderRadius: '4px',
                    }}
                  ></Box>
                </Grid>
                <Grid item xs={11}>
                  <Grid container spacing={1} alignItems={'space-between'}>
                    <Grid item xs={12}>
                      <Grid container alignItems={'center'}>
                        <Grid item xs={11}>
                          <Typography variant="subtitle1">{label_arr} At Risk</Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <RightArrowIcon />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h2">
                        {formatCurrency(revenueAtRiskCount, customerDetail?.currency_code)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/* </Box> */}
            </Box>

            <Box sx={{ marginRight: '20px' }} className="cognisaas-pointer" onClick={handleArrOnTrackClick}>
              <Grid
                sx={{
                  width: '200px',
                  height: '80px',
                  background: 'white',
                  border: '1px solid #E5E5E5',
                  borderRadius: '4px',
                  padding: '12px',
                }}
                container
                alignItems={'center'}
              >
                <Grid item xs={1}>
                  <Box
                    sx={{
                      width: '3px',
                      height: '44px',
                      backgroundColor: '#4F9F52',
                      borderRadius: '4px',
                    }}
                  ></Box>
                </Grid>
                <Grid item xs={11}>
                  <Grid container spacing={1} alignItems={'space-between'}>
                    <Grid item xs={12}>
                      <Grid container alignItems={'center'}>
                        <Grid item xs={11}>
                          <Typography variant="subtitle1">{label_arr} On Track</Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <RightArrowIcon />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h2">
                        {formatCurrency(revenueOnTrackCount, customerDetail?.currency_code)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>

        {/* filters */}
        <Grid item xs={12}>
          <Box
            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', marginBottom: '14px' }}
          >
            <SelectedFilterList idName="actionableInsightsRevenueImpactLandingPage" isResetEnabled={false} />
          </Box>
        </Grid>

        {/* delivery status part */}

        <Grid item xs={12} sx={{ height: '95%' }}>
          <Grid container spacing={2}>
            {/* Delivery Status chart */}
            <Grid item xs={6}>
              <Grid container sx={{ background: 'white', borderRadius: '4px' }}>
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: '15px', fontWeight: '500', padding: '5px' }}>Delivery Status</Typography>
                  <Box sx={{ height: `${size.height >= 720 ? size.height - 408 : 265}px` }} id="projectMouse">
                    <ResponsiveBar
                      data={[
                        {
                          status: 'On Track',
                          [`${label_project}`]: projectOntrackCount,
                          [`${label_project}Color`]: '#306ECC',
                          [`${label_use_case}`]: usecaseOntrackCount,
                          [`${label_use_case}Color`]: '#86B7ED',
                        },
                        {
                          status: 'At Risk',
                          [`${label_project}`]: projectAtRiskCount,
                          [`${label_project}Color`]: '#306ECC',
                          [`${label_use_case}`]: usecaseAtRiskCount,
                          [`${label_use_case}Color`]: '#86B7ED',
                        },
                        {
                          status: 'Overdue',
                          [`${label_project}`]: projectsOverdueCount,
                          [`${label_project}Color`]: '#306ECC',
                          [`${label_use_case}`]: usecaseOverdueCount,
                          [`${label_use_case}Color`]: '#86B7ED',
                        },
                      ]}
                      keys={[`${label_project}`, `${label_use_case}`]}
                      colors={['#306ECC', '#86B7ED']}
                      indexBy="status"
                      theme={theme}
                      margin={{
                        top: 20,
                        right: 0,
                        bottom: 25,
                        left: 60,
                      }}
                      padding={0.5}
                      groupMode="grouped"
                      enableLabel={false}
                      isInteractive={true}
                      tooltip={() => (
                        <div
                          style={{
                            display: 'hidden',
                          }}
                        ></div>
                      )}
                      colorBy="id"
                      axisTop={null}
                      axisRight={null}
                      axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        tickValues: ['On Track', 'At Risk', 'Overdue'],
                        renderTick: (tick: AxisTickProps<any>) => {
                          let fillColors = 'black';
                          switch (tick.tickIndex) {
                            case 0:
                              fillColors = '#4F9F52';
                              break;
                            case 1:
                              fillColors = '#EFA958';
                              break;
                            case 2:
                              fillColors = '#E75B5C';
                              break;
                          }
                          return (
                            <>
                              <circle
                                height="5"
                                width="5"
                                transform={`translate(${tick.x - 42}, ${tick.y + 16})`}
                                cx="0"
                                cy="0"
                                r="5"
                                fill={fillColors}
                              />
                              <text
                                transform={`translate(${tick.x}, ${tick.y + 20})`}
                                textAnchor="middle"
                                fontSize="14px"
                                fontFamily="Noto Sans"
                                color="#7A7A7A"
                              >
                                {tick.value}
                              </text>
                            </>
                          );
                        },
                      }}
                      layers={[
                        'grid',
                        'axes',
                        'bars',
                        'markers',
                        'legends',
                        'annotations',
                        (props) => <ValueOutsideDeliveryStatus {...props} />,
                      ]}
                      axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Count',
                        legendPosition: 'middle',
                        legendOffset: -50,
                        tickValues: 4,
                        format: (e) => Math.floor(e) === e && e,
                      }}
                      onClick={(data) => {
                        if (data.id === label_project) {
                          if (data.indexValue == 'On Track') {
                            const tableStateForAllProjects = {
                              columns: {
                                columnVisibilityModel: {},
                              },
                              pinnedColumns: {
                                left: ['project_name'],
                              },
                              pagination: {
                                page: 0,
                                pageSize: 10,
                              },
                              filter: {
                                filterModel: {
                                  items: [
                                    {
                                      columnField: 'project_readinessstate_status',
                                      operatorValue: 'equals',
                                      value: 'green',
                                    },
                                  ],
                                },
                              },
                            };

                            handleTableSave('projectReportAllProjPage', tableStateForAllProjects);
                            navigate('/reports/Projects', { state: { filterBy: 'project', label: 'green' } });
                          }
                          if (data.indexValue == 'Overdue') {
                            const tableStateForAllProjects = {
                              columns: {
                                columnVisibilityModel: {},
                              },
                              pinnedColumns: {
                                left: ['project_name'],
                              },
                              pagination: {
                                page: 0,
                                pageSize: 10,
                              },
                              filter: {
                                filterModel: {
                                  items: [
                                    {
                                      columnField: 'project_readinessstate_status',
                                      operatorValue: 'equals',
                                      value: 'red',
                                    },
                                  ],
                                },
                              },
                            };

                            handleTableSave('projectReportAllProjPage', tableStateForAllProjects);
                            navigate('/reports/Projects', { state: { filterBy: 'project', label: 'red' } });
                          }
                          if (data.indexValue == 'At Risk') {
                            const tableStateForAllProjects = {
                              columns: {
                                columnVisibilityModel: {},
                              },
                              pinnedColumns: {
                                left: ['project_name'],
                              },
                              pagination: {
                                page: 0,
                                pageSize: 10,
                              },
                              filter: {
                                filterModel: {
                                  items: [
                                    {
                                      columnField: 'project_readinessstate_status',
                                      operatorValue: 'equals',
                                      value: 'amber',
                                    },
                                  ],
                                },
                              },
                            };

                            handleTableSave('projectReportAllProjPage', tableStateForAllProjects);
                            navigate('/reports/Projects', { state: { filterBy: 'project', label: 'amber' } });
                          }
                        }
                        if (data.id === label_use_case) {
                          if (data.indexValue == 'On Track') {
                            const tableStateForAllUsecases = {
                              columns: {
                                columnVisibilityModel: {},
                              },
                              pinnedColumns: {
                                left: ['case_name'],
                              },
                              pagination: {
                                page: 0,
                                pageSize: 10,
                              },
                              filter: {
                                filterModel: {
                                  items: [
                                    {
                                      columnField: 'project_readinessstate_status',
                                      operatorValue: 'equals',
                                      value: 'green',
                                    },
                                  ],
                                },
                              },
                            };
                            handleTableSave('projectReportAllUsecasePage', tableStateForAllUsecases);
                            navigate('/reports/UseCases', { state: { filterBy: 'Use Case', label: 'green' } });
                          }
                          if (data.indexValue == 'Overdue') {
                            const tableStateForAllUsecases = {
                              columns: {
                                columnVisibilityModel: {},
                              },
                              pinnedColumns: {
                                left: ['case_name'],
                              },
                              pagination: {
                                page: 0,
                                pageSize: 10,
                              },
                              filter: {
                                filterModel: {
                                  items: [
                                    {
                                      columnField: 'project_readinessstate_status',
                                      operatorValue: 'equals',
                                      value: 'red',
                                    },
                                  ],
                                },
                              },
                            };
                            handleTableSave('projectReportAllUsecasePage', tableStateForAllUsecases);
                            navigate('/reports/UseCases', { state: { filterBy: 'Use Case', label: 'red' } });
                          }
                          if (data.indexValue == 'At Risk') {
                            const tableStateForAllUsecases = {
                              columns: {
                                columnVisibilityModel: {},
                              },
                              pinnedColumns: {
                                left: ['case_name'],
                              },
                              pagination: {
                                page: 0,
                                pageSize: 10,
                              },
                              filter: {
                                filterModel: {
                                  items: [
                                    {
                                      columnField: 'project_readinessstate_status',
                                      operatorValue: 'equals',
                                      value: 'amber',
                                    },
                                  ],
                                },
                              },
                            };
                            handleTableSave('projectReportAllUsecasePage', tableStateForAllUsecases);
                            navigate('/reports/UseCases', { state: { filterBy: 'Use Case', label: 'amber' } });
                          }
                        }
                      }}
                      // labelSkipWidth={12}
                      // labelSkipHeight={0}
                      borderRadius={4}
                      innerPadding={10}
                      animate={true}
                      onMouseEnter={() => {
                        (document.getElementById('projectMouse') as HTMLInputElement).style.cursor = 'pointer';
                      }}
                      onMouseLeave={() => {
                        (document.getElementById('projectMouse') as HTMLInputElement).style.cursor = 'default';
                      }}
                    />
                  </Box>
                </Grid>
                <Grid container direction="row" justifyContent="space-between" pl={10} pr={10} mt={1}>
                  <Grid item>
                    <Stack direction="row">
                      <Box
                        sx={{
                          background: '#457DD1',
                          width: '14px',
                          height: '14px',
                          borderRadius: '4px',
                          marginRight: '8px',
                          marginTop: '5px',
                        }}
                      ></Box>
                      <Typography variant="subtitle1">{label_project}</Typography>
                    </Stack>
                  </Grid>
                  <Grid item>
                    <Stack direction="row">
                      <Box
                        sx={{
                          background: '#92BEEF',
                          width: '14px',
                          height: '14px',
                          borderRadius: '4px',
                          marginRight: '8px',
                          marginTop: '5px',
                        }}
                      ></Box>
                      <Typography variant="subtitle1">{label_use_case}</Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* Land and Expand Revenue Impact Analysis charts */}
            <Grid item xs={6}>
              <Grid container sx={{ background: 'white', borderRadius: '4px' }}>
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: '15px', fontWeight: '500', padding: '5px' }}>
                    Land and Expand Revenue Impact Analysis
                  </Typography>
                  <Box sx={{ height: `${size.height >= 720 ? size.height - 375 : 296}px` }} id="chartsMouse">
                    <ResponsiveBar
                      data={data}
                      keys={['count']}
                      isInteractive={true}
                      tooltip={() => (
                        <div
                          style={{
                            display: 'hidden',
                          }}
                        ></div>
                      )}
                      colors={getBarColor}
                      onClick={(data) => {
                        if (data.indexValue == 'On Track') {
                          const tableStateForAllProjects = {
                            columns: {
                              columnVisibilityModel: {},
                            },
                            pinnedColumns: {
                              left: ['project_name'],
                            },
                            pagination: {
                              page: 0,
                              pageSize: 10,
                            },
                            filter: {
                              filterModel: {
                                items: [
                                  {
                                    columnField: 'project_readinessstate_status',
                                    operatorValue: 'equals',
                                    value: 'green',
                                  },
                                ],
                              },
                            },
                          };

                          handleTableSave('projectReportAllProjPage', tableStateForAllProjects);
                          navigate('/reports/Projects', { state: { filterBy: 'project', label: 'green' } });
                        }
                        if (data.indexValue == 'Overdue') {
                          const tableStateForAllProjects = {
                            columns: {
                              columnVisibilityModel: {},
                            },
                            pinnedColumns: {
                              left: ['project_name'],
                            },
                            pagination: {
                              page: 0,
                              pageSize: 10,
                            },
                            filter: {
                              filterModel: {
                                items: [
                                  {
                                    columnField: 'project_readinessstate_status',
                                    operatorValue: 'equals',
                                    value: 'red',
                                  },
                                ],
                              },
                            },
                          };

                          handleTableSave('projectReportAllProjPage', tableStateForAllProjects);
                          navigate('/reports/Projects', { state: { filterBy: 'project', label: 'red' } });
                        }
                        if (data.indexValue == 'At Risk') {
                          const tableStateForAllProjects = {
                            columns: {
                              columnVisibilityModel: {},
                            },
                            pinnedColumns: {
                              left: ['project_name'],
                            },
                            pagination: {
                              page: 0,
                              pageSize: 10,
                            },
                            filter: {
                              filterModel: {
                                items: [
                                  {
                                    columnField: 'project_readinessstate_status',
                                    operatorValue: 'equals',
                                    value: 'amber',
                                  },
                                ],
                              },
                            },
                          };

                          handleTableSave('projectReportAllProjPage', tableStateForAllProjects);
                          navigate('/reports/Projects', { state: { filterBy: 'project', label: 'amber' } });
                        }
                        // if (data.indexValue == 'On Track')
                        //   navigate('/reports/Projects', { state: { filterBy: 'project', label: 'green' } });
                        // if (data.indexValue == 'Overdue')
                        //   navigate('/reports/Projects', { state: { filterBy: 'project', label: 'red' } });
                        // if (data.indexValue == 'At Risk')
                        //   navigate('/reports/Projects', { state: { filterBy: 'project', label: 'amber' } });
                      }}
                      axisLeft={{
                        legend: `${label_arr} (${getSymbolFromCurrency(customerDetail?.currency_code)})`,
                        legendPosition: 'middle',
                        legendOffset: -90,
                        tickValues: 4,
                        format: (e) => formatCurrency(e, customerDetail?.currency_code),
                      }}
                      axisBottom={{
                        renderTick: (tick: AxisTickProps<any>) => {
                          let fillColors = 'black';
                          switch (tick.tickIndex) {
                            case 0:
                              fillColors = '#4F9F52';
                              break;
                            case 1:
                              fillColors = '#EFA958';
                              break;
                            case 2:
                              fillColors = '#E75B5C';
                              break;
                          }
                          return (
                            <>
                              <circle
                                height="8"
                                width="8"
                                transform={`translate(${tick.x - 42}, ${tick.y + 16})`}
                                cx="0"
                                cy="0"
                                r="5"
                                fill={fillColors}
                              />
                              <text
                                transform={`translate(${tick.x}, ${tick.y + 20})`}
                                textAnchor="middle"
                                fontSize="14px"
                                fontFamily="Noto Sans"
                                color="#7A7A7A"
                              >
                                {tick.value}
                              </text>
                            </>
                          );
                        },
                      }}
                      layers={[
                        'grid',
                        'axes',
                        'bars',
                        'markers',
                        'legends',
                        'annotations',
                        (props) => <ValueOutside {...props} />,
                      ]}
                      borderRadius={4}
                      animate={true}
                      padding={0.75}
                      theme={theme}
                      margin={{
                        top: 20,
                        right: 0,
                        bottom: 55,
                        left: 100,
                      }}
                      enableLabel={false}
                      enableGridY={true}
                      onMouseEnter={() => {
                        (document.getElementById('chartsMouse') as HTMLInputElement).style.cursor = 'pointer';
                      }}
                      onMouseLeave={() => {
                        (document.getElementById('chartsMouse') as HTMLInputElement).style.cursor = 'default';
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LandingPage;
