import { Box, Drawer } from '@mui/material';
import { useAppSelector, useAppDispatch } from '../../../store/hooks';
import { label_arr } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import CogniTable, { linkCell, StatusCell } from '../ActionableInsightTable/Table';
import { selectCustomerDetail } from 'store/modules/Common/selector';
import CognisaasLoader from '../../shared/CognisaasLoader/CognisaasLoader';
import { useEffect, useState } from 'react';
import { fetchClientInfoByMultiplefeatureUids } from 'store/modules/ActionableInsights/slice';
import MappedProductClientViewDrawer from './MappedProductClientViewDrawer';
import { formatCurrency } from 'utils/dataHelpers';
import useCustomNames from 'components/shared/CustomNames/useCustomNames';
const ProductClientMapping = (props: any) => {
  const dispatch = useAppDispatch();
  const customerDetail = useAppSelector(selectCustomerDetail);
  const [isClientDataViewDrawerToggled, setIsClientDataViewDrawerToggled] = useState<boolean>(false);
  const [toggledRowData, setToggledRowdata] = useState<Record<string, any>>({});
  const [toggledClientList, setToggledClientList] = useState<any[]>([]);
  const { displayDataWithCustomNames } = useCustomNames();

  const handleAccountClick = (rowData: Record<string, any>) => {
    setIsClientDataViewDrawerToggled((prevState) => !prevState);
    setToggledRowdata(rowData);
    // for product
    const temp: any[] = [];
    props.productAndTheirRequirement.data.forEach((product: any) => {
      if (product.product_name === rowData.product_name) {
        product.feature.forEach((feature: any) => {
          if (feature.feature_uid === rowData.feature_uid)
            temp.push({
              customer_uid: feature.customer_uid,
              feature_uid: feature.feature_uid,
            });
        });
      }
    });

    dispatch(fetchClientInfoByMultiplefeatureUids([...temp]));
  };
  useEffect(() => {
    if (props.clientsMappedWithRequirementsState.data.length > 0) {
      setToggledClientList(
        props.clientsMappedWithRequirementsState.data.filter(
          (item: any) => item.case_needed_status.toLowerCase() === props.selectedCategory
        )
      );
    }
  }, [props.clientsMappedWithRequirementsState]);
  return (
    <>
      {props.selectedCategory === 'expand' ? (
        <Box>
          {props.topExpandProductMappingState.status === 'success' ? (
            <>
              <CogniTable
                hideFooter={true}
                hideHeader={true}
                columns={[
                  {
                    field: 'feature_uid',
                    hide: true,
                  },
                  { field: 'product_name', headerName: 'Product Name', minWidth: 240, flex: 1 },
                  { field: 'feature_name', headerName: 'Requirement', minWidth: 200, flex: 1 },
                  { field: 'feature_status', headerName: 'Requirement Status', minWidth: 200, flex: 1 },
                  {
                    field: 'expansion_sum',
                    headerName: `${label_arr}`,
                    minWidth: 200,
                    flex: 1,
                    renderCell: (params) => {
                      return formatCurrency(params.row[params.field], customerDetail?.currency_code);
                    },
                  },
                  {
                    field: 'client_count',
                    headerName: 'Interested Accounts',
                    minWidth: 140,
                    flex: 1,
                    renderCell: (params) => linkCell(params, handleAccountClick),
                  },
                ]}
                uniquRowId={'feature_uid'}
                data={displayDataWithCustomNames(props.topExpandProductMappingState.data)}
              />
            </>
          ) : (
            <CognisaasLoader />
          )}
        </Box>
      ) : (
        <Box>
          {/* project client mapping - land */}
          {props.topLandProductMappingState.status === 'success' ? (
            <>
              <CogniTable
                hideFooter={true}
                hideHeader={true}
                columns={[
                  {
                    field: 'feature_uid',
                    hide: true,
                  },
                  { field: 'product_name', headerName: 'Product Name', minWidth: 240, flex: 1 },
                  { field: 'feature_name', headerName: 'Requirement', minWidth: 200, flex: 1 },
                  {
                    field: 'feature_status',
                    headerName: 'Requirement Status',
                    minWidth: 200,
                    flex: 1,
                    renderCell: StatusCell,
                  },
                  {
                    field: 'sum_worth',
                    headerName: `${label_arr}`,
                    minWidth: 200,
                    flex: 1,
                    renderCell: (params) => {
                      return formatCurrency(params.row[params.field], customerDetail?.currency_code);
                    },
                  },
                  {
                    field: 'client_count',
                    headerName: 'Interested Accounts',
                    minWidth: 140,
                    flex: 1,
                    renderCell: (params) => linkCell(params, handleAccountClick),
                  },
                ]}
                uniquRowId={'feature_uid'}
                data={displayDataWithCustomNames(props.topLandProductMappingState.data)}
              />
            </>
          ) : (
            <CognisaasLoader />
          )}
        </Box>
      )}
      <Drawer
        anchor={'right'}
        open={isClientDataViewDrawerToggled}
        onClose={() => {
          setIsClientDataViewDrawerToggled((prevState) => !prevState);
          setToggledRowdata({});
        }}
        PaperProps={{
          sx: { width: '920px', overflowX: 'clip' },
        }}
      >
        <Box sx={{ padding: '95px 0px 0px 0px' }}>
          <MappedProductClientViewDrawer
            name={toggledRowData.product_name}
            data={toggledClientList}
            arr={toggledRowData.sum_worth ? toggledRowData.sum_worth : toggledRowData.expansion_sum}
            dependentClientCount={toggledRowData.client_count}
            category={props.selectedCategory === 'land' ? 'Land' : 'Expand'}
          />
        </Box>
      </Drawer>
    </>
  );
};

export default ProductClientMapping;
