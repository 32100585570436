import {
  label_arr,
  label_client,
  label_cs_owner,
  label_delivery_date,
  label_due_date,
  label_project,
  label_project_delivery_manager,
  label_sales_owner,
  label_task,
  label_use_case,
  label_use_case_delivery_manager,
} from 'components/Settings/Preferences/CustomLabelCookieHelper';

export const dataTypes = {
  string: 'string',
  date: 'date',
  link: 'link',
  rag: 'rag',
  progressBar: 'progressBar',
  taskPanel: 'taskPanel',
  number: 'number',
  dropDown: 'dropDown',
  userName: 'userName', //new
};

export const customFieldDataTypes = {
  string: 'String',
  dropDown: 'Dropdown',
  link: 'Hyperlink',
  rag: 'Rag status',
  number: 'Number',
  userList: 'User List',
  date: 'Date',
};

export const clientEntityDisplayName = {
  name: `${label_client} Name`,
  salesOwner: label_sales_owner,
  csOwner: label_cs_owner,
  status: 'Status',
  segment: 'Segment',
  industry: 'Industry',
  stage: 'stage',
  accountWorth: label_arr,
  comment: 'Comment',
};

export const clientEntityKey = {
  name: 'client_name',
  salesOwner: 'client_sales_owner',
  csOwner: 'client_account_owner',
  status: 'client_readiness_status',
  segment: 'segment_name',
  industry: 'industry_name',
  stage: 'stage_journerystage',
  accountWorth: 'client_account_worth',
  comment: 'client_comment',
};
export const clientEntityType = {
  name: dataTypes.link,
  salesOwner: dataTypes.userName,
  csOwner: dataTypes.userName,
  status: dataTypes.rag,
  segment: dataTypes.string,
  industry: dataTypes.string,
  stage: dataTypes.string,
  accountWorth: dataTypes.string,
  comment: dataTypes.string,
};

export const clientEntity = {
  client_name: {
    displayName: `${label_client} Name`,
    key: 'client_name',
    type: dataTypes.link,
  },
  client_sales_owner: {
    displayName: label_sales_owner,
    key: 'client_sales_owner',
    type: dataTypes.userName,
  },
  client_account_owner: {
    displayName: label_cs_owner,
    key: 'client_account_owner',
    type: dataTypes.userName,
  },
  client_readiness_status: {
    displayName: 'Status',
    key: 'client_readiness_status',
    type: dataTypes.rag,
  },
  segment_name: {
    displayName: 'Segment',
    key: 'segment_name',
    type: dataTypes.string,
  },
  industry_name: {
    displayName: 'Industry',
    key: 'industry_name',
    type: dataTypes.string,
  },
  stage_journerystage: {
    displayName: 'Stage',
    key: 'stage_journerystage',
    type: dataTypes.string,
  },
  client_account_worth: {
    displayName: label_arr,
    key: 'client_account_worth',
    type: dataTypes.string,
  },
  client_comment: {
    displayName: 'Comment',
    key: 'client_comment',
    type: dataTypes.string,
  },
};

export const projectEntity = {
  project_name: {
    displayName: `${label_project} Name`,
    key: 'project_name',
    type: dataTypes.link,
  },
  project_progress: {
    displayName: `${label_project} Progress (in %)`,
    key: 'project_progress',
    type: dataTypes.progressBar,
  },
  project_planned_startdate: {
    displayName: `${label_project} Planned Start Date`,
    key: 'project_planned_startdate',
    type: dataTypes.date,
  },
  project_startdate: {
    displayName: `${label_project} Start Date`,
    key: 'project_startdate',
    type: dataTypes.date,
  },
  eta_forprojectdelivery: {
    displayName: `${label_project} Estimated Delivery Date`,
    key: 'eta_forprojectdelivery',
    type: dataTypes.date,
  },
  project_requiredbydate: {
    displayName: `${label_project} ${label_due_date}`,
    key: 'project_requiredbydate',
    type: dataTypes.date,
  },
  project_deliverydate: {
    displayName: `${label_project} ${label_delivery_date}`,
    key: 'project_deliverydate',
    type: dataTypes.date,
  },
  project_readinessstate_status: {
    displayName: `${label_project} Status`,
    key: 'project_readinessstate_status',
    type: dataTypes.rag,
  },
  project_deliverymanager: {
    displayName: `${label_project} ${label_project_delivery_manager}`,
    key: 'project_deliverymanager',
    type: dataTypes.userName,
  },
  project_needed_status: {
    displayName: `${label_project} Category`,
    key: 'project_needed_status',
    type: dataTypes.string,
  },
};

export const projectEntityDisplayName = {
  name: `${label_project} Name`,
  progress: 'Progress',
  plannedStartDate: 'Planned Start Date',
  startDate: 'Start Date',
  etaForDelivery: 'Estimated Delivery Date',
  dueDate: label_due_date,
  deliveryDate: label_delivery_date,
  status: 'Status',
  deliveryManager: label_project_delivery_manager,
  category: `${label_project} Category`,
};

export const projectEntityKey = {
  name: 'project_name',
  progress: 'project_progress',
  plannedStartDate: 'project_planned_startdate',
  startDate: 'project_startdate',
  etaForDelivery: 'eta_forprojectdelivery',
  dueDate: 'project_requiredbydate',
  deliveryDate: 'project_deliverydate',
  status: 'project_readinessstate_status',
  deliveryManager: 'project_deliverymanager',
  category: 'project_needed_status',
};

export const projectEntityType = {
  name: dataTypes.link,
  progress: dataTypes.progressBar,
  plannedStartDate: dataTypes.date,
  startDate: dataTypes.date,
  etaForDelivery: dataTypes.date,
  dueDate: dataTypes.date,
  deliveryDate: dataTypes.date,
  status: dataTypes.rag,
  deliveryManager: dataTypes.userName,
  category: dataTypes.string,
};

export const usecaseEntityDisplayName = {
  name: `${label_use_case} Name`,
  progress: 'Progress (in %)',
  plannedStartDate: 'Planned Start Date',
  startDate: 'Start Date',
  etaForDelivery: 'ETA for Delivery',
  dueDate: label_due_date,
  deliveryDate: label_delivery_date,
  status: 'Status',
  deliveryManager: label_use_case_delivery_manager,
  category: `${label_use_case} Category`,
};

export const usecaseEntityKey = {
  name: 'case_name',
  progress: 'case_progress',
  plannedStartDate: 'case_planned_startdate',
  startDate: 'case_startdate',
  etaForDelivery: 'eta_forcasedelivery',
  dueDate: 'case_requiredbydate',
  deliveryDate: 'case_deliverydate',
  status: 'case_readinessstate_status',
  deliveryManager: 'case_deliverymanager',
  category: 'case_needed_status',
};

export const usecaseEntityType = {
  name: dataTypes.link,
  progress: dataTypes.progressBar,
  plannedStartDate: dataTypes.date,
  startDate: dataTypes.date,
  etaForDelivery: dataTypes.date,
  dueDate: dataTypes.date,
  deliveryDate: dataTypes.date,
  status: dataTypes.rag,
  deliveryManager: dataTypes.userName,
  category: dataTypes.string,
};

export const taskEntityDisplayName = {
  name: `${label_task} Name`,
  owner: `${label_task} Owner`,
  status: `${label_task} Status`,
  stage: `${label_task} Stage`,
  priority: `${label_task} Priority`,
  plannedStartDate: 'Planned Start Date',
  startDate: 'Start Date',
  dueDate: label_due_date,
  deliveryDate: label_delivery_date,
  effortEstimate: 'Planned Duration',
  timeSpent: 'Actual Duration',
};

export const taskEntityKey = {
  name: 'task_name',
  owner: 'task_owner',
  status: 'task_status',
  stage: 'task_stage',
  priority: 'task_priority',
  plannedStartDate: 'task_planned_start_date',
  startDate: 'task_start_date',
  dueDate: 'task_due_date',
  deliveryDate: 'task_closed_date',
  effortEstimate: 'effort_estimate',
  timeSpent: 'total_time_spent',
};

export const taskEntityType = {
  name: dataTypes.taskPanel,
  owner: dataTypes.userName,
  status: dataTypes.rag,
  stage: dataTypes.string,
  priority: dataTypes.string,
  plannedStartDate: dataTypes.date,
  startDate: dataTypes.date,
  dueDate: dataTypes.date,
  deliveryDate: dataTypes.date,
  effortEstimate: dataTypes.string,
  timeSpent: dataTypes.string,
};

export const usecaseEntity = {
  case_name: {
    displayName: usecaseEntityDisplayName.name,
    key: usecaseEntityKey.name,
    type: usecaseEntityType.name,
  },
  case_progress: {
    displayName: usecaseEntityDisplayName.progress,
    key: usecaseEntityKey.progress,
    type: usecaseEntityType.progress,
  },
  case_planned_startdate: {
    displayName: usecaseEntityDisplayName.plannedStartDate,
    key: usecaseEntityKey.plannedStartDate,
    type: usecaseEntityType.plannedStartDate,
  },
  case_startdate: {
    displayName: usecaseEntityDisplayName.startDate,
    key: usecaseEntityKey.startDate,
    type: usecaseEntityType.startDate,
  },
  eta_forcasedelivery: {
    displayName: usecaseEntityDisplayName.etaForDelivery,
    key: usecaseEntityKey.etaForDelivery,
    type: usecaseEntityType.etaForDelivery,
  },
  case_requiredbydate: {
    displayName: usecaseEntityDisplayName.dueDate,
    key: usecaseEntityKey.dueDate,
    type: usecaseEntityType.dueDate,
  },
  case_deliverydate: {
    displayName: usecaseEntityDisplayName.deliveryDate,
    key: usecaseEntityKey.deliveryDate,
    type: usecaseEntityType.deliveryDate,
  },
  case_readinessstate_status: {
    displayName: usecaseEntityDisplayName.status,
    key: usecaseEntityKey.status,
    type: usecaseEntityType.status,
  },
  case_deliverymanager: {
    displayName: usecaseEntityDisplayName.deliveryManager,
    key: usecaseEntityKey.deliveryManager,
    type: usecaseEntityType.deliveryManager,
  },
  case_needed_status: {
    displayName: usecaseEntityDisplayName.category,
    key: usecaseEntityKey.category,
    type: usecaseEntityType.category,
  },
};

export const taskEntity = {
  task_name: {
    displayName: taskEntityDisplayName.name,
    key: taskEntityKey.name,
    type: taskEntityType.name,
  },
  task_owner: {
    displayName: taskEntityDisplayName.owner,
    key: taskEntityKey.owner,
    type: taskEntityType.owner,
  },
  task_status: {
    displayName: taskEntityDisplayName.status,
    key: taskEntityKey.status,
    type: taskEntityType.status,
  },
  task_stage: {
    displayName: taskEntityDisplayName.stage,
    key: taskEntityKey.stage,
    type: taskEntityType.stage,
  },
  task_priority: {
    displayName: taskEntityDisplayName.priority,
    key: taskEntityKey.priority,
    type: taskEntityType.priority,
  },
  task_planned_start_date: {
    displayName: taskEntityDisplayName.plannedStartDate,
    key: taskEntityKey.plannedStartDate,
    type: taskEntityType.plannedStartDate,
  },
  task_start_date: {
    displayName: taskEntityDisplayName.startDate,
    key: taskEntityKey.startDate,
    type: taskEntityType.startDate,
  },
  task_due_date: {
    displayName: taskEntityDisplayName.dueDate,
    key: taskEntityKey.dueDate,
    type: taskEntityType.dueDate,
  },
  task_closed_date: {
    displayName: taskEntityDisplayName.deliveryDate,
    key: taskEntityKey.deliveryDate,
    type: taskEntityType.deliveryDate,
  },
  effort_estimate: {
    displayName: taskEntityDisplayName.effortEstimate,
    key: taskEntityKey.effortEstimate,
    type: taskEntityType.effortEstimate,
  },
  total_time_spent: {
    displayName: taskEntityDisplayName.timeSpent,
    key: taskEntityKey.timeSpent,
    type: taskEntityType.timeSpent,
  },
};

export const clientFilterType = {
  client_name: {
    displayName: `${label_client} Name`,
    key: 'client_name',
    type: dataTypes.link,
  },
  client_sales_owner: {
    displayName: label_sales_owner,
    key: 'client_sales_owner',
    type: dataTypes.userName,
  },
  client_account_owner: {
    displayName: label_cs_owner,
    key: 'client_account_owner',
    type: dataTypes.userName,
  },
  client_readiness_status: {
    displayName: 'Status',
    key: 'client_readiness_status',
    type: dataTypes.rag,
  },
  segment_name: {
    displayName: 'Segment',
    key: 'segment_name',
    type: dataTypes.string,
  },
  industry_name: {
    displayName: 'Industry',
    key: 'industry_name',
    type: dataTypes.string,
  },
  stage_journerystage: {
    displayName: 'Stage',
    key: 'stage_journerystage',
    type: dataTypes.string,
  },
  client_account_worth: {
    displayName: label_arr,
    key: 'client_account_worth',
    type: dataTypes.string,
  },
  client_comment: {
    displayName: 'Comment',
    key: 'client_comment',
    type: dataTypes.string,
  },
};
