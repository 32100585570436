import { Drawer, Box, Stack, Typography } from '@mui/material';
import { PermissionsDetailProps } from 'components/RBACv2/interfaces';
import Policy from './Policy';
import Condition from './Condition';

export default function PermissionsDetail(props: PermissionsDetailProps) {
  return (
    <Drawer
      anchor={'right'}
      open={props.drawerState}
      onClose={() => props.onDrawerClose()}
      sx={{
        width: '700px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '20px',
          marginTop: '70px',
          width: '600px',
        }}
      >
        <Stack>
          {console.log(props.selectedPermission)}
          <Typography variant="h2" style={{ marginRight: '15px' }}>
            Name: {props.selectedPermission.name}
          </Typography>
          <Typography variant="subtitle2" style={{ marginRight: '15px', marginTop: '5px' }}>
            Description: {props.selectedPermission.description}
          </Typography>
          {props?.selectedPermission.is_public && (
            <>
              <Stack direction="row" mt={1}>
                <div
                  style={{
                    padding: '2px 10px',
                    textAlign: 'center',
                    borderRadius: '4px',
                    border: '1px solid #3D38BA',
                    width: 'fit-content',
                  }}
                >
                  <Typography variant="subtitle1" color="#3D38BA" style={{ fontSize: '11px' }}>
                    Cognisaas Managed
                  </Typography>
                </div>
                <div
                  style={{
                    width: 'fit-content',
                    padding: '2px 10px',
                    borderRadius: '4px',
                    border: '1px solid #0061bb',
                    backgroundColor: '#d8ecff',
                    marginLeft: '5px',
                  }}
                >
                  <Typography variant="subtitle1" color="#0061bb" style={{ fontSize: '11px' }}>
                    The policy is managed by Cognisaas, cannot be edited
                  </Typography>
                </div>
              </Stack>
            </>
          )}
        </Stack>
        {props.permissionType === 'Policy' ? (
          <Policy selectedPolicy={props.selectedPermission} />
        ) : (
          <Condition selectedCondition={props.selectedPermission} />
        )}
      </Box>
    </Drawer>
  );
}
