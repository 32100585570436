import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';

import { styled } from '@mui/material/styles';
// import { makeStyles } from '@mui/styles';

interface props {
  title: any;
  children: any;
  placement?: 'top' | 'right' | 'left' | 'bottom';
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    border: '1px solid #E5E5E5',
    boxShadow: '0px 4px 26px rgba(0, 0, 0, 0.05)',
    fontSize: 11,
    padding: '0',
  },
  [`& .${tooltipClasses.arrow}`]: {
    // backgroundColor: theme.palette.common.white,
    color: theme.palette.common.white,
    // boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

// const useStyles = makeStyles({
//   customTooltip: {
//     backgroundColor: 'white',
//     color: 'black',
//     // border: '1px solid grey',
//     boxShadow: '0px 4px 26px rgba(0, 0, 0, 0.05)',
//     borderRadius: '4px',
//   },
//   customArrow: {
//     // color: 'rgba(220, 0, 78, 0.8)',
//     // border: '1px solid black',
//     color: 'white',
//     '&::before': {
//       backgroundColor: 'white',
//       //   border: '1px solid grey',
//     },
//   },
// });

export const CognisaaasTooltip = (props: props) => {
  //   const classes = useStyles();

  return (
    <LightTooltip
      //   classes={{
      //     tooltip: classes.customTooltip,
      //     arrow: classes.customArrow,
      //   }}
      arrow
      TransitionComponent={Zoom}
      // TransitionProps={{ timeout: 200 }}
      title={props.title}
      placement={props.placement ? props.placement : 'top'}
    >
      {props.children}
    </LightTooltip>
  );
};

export default CognisaaasTooltip;
