import * as React from 'react';
import { Box, SelectChangeEvent, Stack, Typography, Avatar } from '@mui/material';
import CognisaasTabs from '../../shared/CognisaasTabs';
import { useState } from 'react';
import {
  selectRevenueProjectTaskState,
  selectRevenueUsecaseTaskState,
} from '../../../store/modules/Reports/RevenueProjectTasks/selector';
import UpdateTaskDrawer from 'components/TaskModule/TaskView/UpdateTaskDrawer';
import { useAppSelector, useAppDispatch } from '../../../store/hooks';
import { requestState } from '../../../store/modules/types';
import ReportsHeader, { ReportsPages } from '../ReportsHeader/ReportsHeader';
import { selectRenewalTasks } from '../../../store/modules/Reports/RenewalTasks/selector';
import { fetchRenewalTasks } from '../../../store/modules/Reports/RenewalTasks/slice';
import FilterTypeSelector from 'components/shared/CognisaasFilters/FilterTypeSelector/FilterTypeSelector';
import { useNavigate } from 'react-router-dom';
import { getTaskByTaskUid } from 'store/modules/Task/slice';
import {
  formatCurrency,
  generateDataWhileMappingCustomFieldsAsNormalFields,
  generateMappedCustomFieldKey,
  generateOrderedColumn,
  isNull,
  isUndefined,
} from 'utils/dataHelpers';
import CogniTable, { AvatarCell, linkCell } from 'components/shared/Tables/CogniTable';
import { addFilterGroup } from 'store/modules/AllFilters/slice';
import { availableDateRangeOptions, FilterableData } from 'utils/filteringFunctions';
import { selectAllFilters } from 'store/modules/AllFilters/selector';
import SelectedFilterList from 'components/shared/CognisaasFilters/SelectedFilterList/SelectedFilterList';
import CognisaasButtonGroup from 'components/shared/CognisaasButtonGroup/CognisaasButtonGroup';
import { fetchRevenueProjectTasks, fetchRevenueUsecaseTasks } from 'store/modules/Reports/RevenueProjectTasks/slice';
import {
  GridColumnOrderChangeParams,
  GridColumns,
  GridRenderCellParams,
  GridRenderEditCellParams,
} from '@mui/x-data-grid';
import {
  selectAllGeneralStatus,
  selectAllMilestoneStatus,
  selectAllRenewalStatus,
  selectCustomerCfs,
  selectUsecaseCfsWithSubFields,
  selectUsersOfCompany,
} from 'store/modules/Common/selector';
import {
  fetchCustomerLevelCustomFields,
  fetchCustomFieldsUsecaseAndSubfields,
  getAllUsers,
} from 'store/modules/Common/slice';
import { getCustomerDetails } from 'store/modules/Settings/slice';
import { selectCustomerDetails } from 'store/modules/Settings/selector';
import ViewDate from 'components/shared/ViewDate/ViewDate';
import {
  label_client,
  label_delivery_date,
  label_due_date,
  label_project,
  label_use_case,
  label_arr,
} from 'components/Settings/Preferences/CustomLabelCookieHelper';
import CognisaasTaskStageIndicator, {
  taskStage,
} from 'components/shared/CognisaasTaskStageIndicator/CognisaasTaskStageIndicator';
import CognisaasStageSelect from 'components/shared/CognisaasStageSelect/CognisaasStageSelect';
import {
  doneRenewalStage,
  fetchClientList,
  taskRenewalOwner,
  updatedcasewithfeaturestatusClient,
} from 'store/modules/ManageClient/ClientList/slice';
import { useGridApiContext } from '@mui/x-data-grid-premium';
import { format } from 'date-fns';
import { ReactComponent as NoRecords } from '../../../assets/svgs/no_records.svg';
import { getUserLogoLinkByUid, getUserUid } from 'utils/getUid';
import CognisaasDropdown from 'components/shared/CognisaasDropdown/CognisaasDropdown';
import { default as AvatarImage } from '../../shared/Avatar/Avatar';
import { selectClientList } from 'store/modules/ManageClient/ClientList/selector';
import checkAccessForRouting from 'components/shared/Hooks/checkAccessForRouting';
import CognisaaasTooltip from 'components/shared/CognisaasTooltip/CognisaasTooltip';
function stringToHslColor(str: string, s: number, l: number) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const h = hash % 360;
  return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToHslColor(name, 30, 80),
    },
    children: (
      <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#000000' }}>
        {name.split(' ')[0][0]}
        {name.indexOf(' ') >= 0 ? name.split(' ')[1][0] : null}
      </Typography>
    ),
  };
}
const RevenueReport = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const revenueUsecaseTasksState = useAppSelector(selectRevenueUsecaseTaskState);
  const revenueProjectTasksState = useAppSelector(selectRevenueProjectTaskState);
  const customerCustomFields = useAppSelector(selectCustomerCfs);
  const usecaseCfsWithSubfields = useAppSelector(selectUsecaseCfsWithSubFields);
  const [clientUid, setClientUid] = useState<any>(null);
  const [taskToOpen, setTaskToOpen] = useState<string>('');
  const [updateTaskDrawer, setUpdateTaskDrawer] = useState<boolean>(false);

  const [revTableColumns, setRevTableColumns] = React.useState<GridColumns<any>>([]);
  const [renewalTableColumns, setRenewalTableColumns] = useState<GridColumns<any>>([]);

  const renewalTasks = useAppSelector(selectRenewalTasks);

  const [selectedTab, setSelectedTab] = React.useState(0);
  const tabs = ['Revenue Reports', 'Renewals'];
  const [taskType, setTaskType] = React.useState<number>(0);
  const allUsers = useAppSelector(selectUsersOfCompany);
  const allMilestoneStatus = useAppSelector(selectAllMilestoneStatus);
  const allGeneralStatus = useAppSelector(selectAllGeneralStatus);
  const allRenewalStatus = useAppSelector(selectAllRenewalStatus);
  const clientList = useAppSelector(selectClientList);
  const customerDetail = useAppSelector(selectCustomerDetails).data;

  // fo filtering
  const [filteredRenewalTasks, setFilteredRenewalTasks] = React.useState<any>();
  const [filteredRevenueTasks, setFilteredRevenueTasks] = React.useState<any[]>([]);
  const allFilters = useAppSelector(selectAllFilters);
  const uniqueRevenueFeeColumnId = 'reportRevenueFeeListTable';
  const uniqueRevenueRenewalsColumnId = 'reportRevenueRenewalListTable';
  const parseLines = (value: string) => {
    return value?.replace(/(\\n)/g, ' ');
  };

  const handleClientAccess = (params: GridRenderCellParams) => {
    const arrayToCheck = clientList.map((ele) => ele.client_uid);
    const hasAccessToClient = checkAccessForRouting({ arrayToCheck: arrayToCheck, uid: params.row.client_uid });
    console.log(params.value);
    if (hasAccessToClient == true) {
      return linkCell(params, handleRenewalAccountClick);
    }
    if (hasAccessToClient == false) {
      return (
        <CognisaaasTooltip
          title={<Typography sx={{ padding: '5px' }}>You do not have access to this {label_client}</Typography>}
        >
          <Typography variant="subtitle1">{params.value}</Typography>
        </CognisaaasTooltip>
      );
    }
  };

  React.useEffect(() => {
    dispatch(fetchRevenueUsecaseTasks());
    dispatch(fetchRevenueProjectTasks());
    dispatch(fetchRenewalTasks());
    dispatch(fetchCustomerLevelCustomFields());
    dispatch(fetchCustomFieldsUsecaseAndSubfields());
    dispatch(getCustomerDetails());
    setRenewalTableColumns(generateOrderedColumn(columns, uniqueRevenueRenewalsColumnId));
    dispatch(fetchClientList());
    dispatch(getAllUsers());
  }, []);

  // table columns being generated
  const defaultRevColumns = [
    {
      field: 'task_uid',
      hide: true,
    },
    {
      field: 'task_detail',
      headerName: 'Milestone',
      minWidth: 200,
      flex: 1,
      renderCell: (params: any) => linkCell(params, openTask),
    },
    {
      field: 'client_name',
      headerName: `${label_client} Name`,
      minWidth: 200,
      flex: 1,
      renderCell: (params: any) => handleClientAccess(params),
    },
    {
      field: 'case_name$project_name',
      headerName:
        taskType === 0 ? `${label_project}s & ${label_use_case}s` : taskType === 1 ? label_use_case : label_project,
      minWidth: 200,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        if (taskType === 0) {
          if (params.row['case_name']) {
            return (
              <Typography
                variant="subtitle1"
                className="cognisaas-pointer link-text"
                onClick={() =>
                  navigate(
                    `/projects/project/${params.row.association_uid}/${params.row.project_uid}/${params.row.case_uid}`
                  )
                }
              >
                {params.row['case_name']}
              </Typography>
            );
          } else {
            return (
              <Typography
                variant="subtitle1"
                className="cognisaas-pointer link-text"
                onClick={() => navigate(`/projects/project/${params.row.association_uid}/${params.row.project_uid}`)}
              >
                {params.row['project_name']}
              </Typography>
            );
          }
        } else if (taskType === 1) {
          return (
            <Typography
              variant="subtitle1"
              className="cognisaas-pointer link-text"
              onClick={() =>
                navigate(
                  `/projects/project/${params.row.association_uid}/${params.row.project_uid}/${params.row.case_uid}`
                )
              }
            >
              {params.row['case_name']}
            </Typography>
          );
        } else {
          return (
            <Typography
              variant="subtitle1"
              className="cognisaas-pointer link-text"
              onClick={() => navigate(`/projects/project/${params.row.association_uid}/${params.row.project_uid}`)}
            >
              {params.row['project_name']}
            </Typography>
          );
        }
      },
    },
    {
      field: 'task_owner',
      headerName: 'Owner',
      minWidth: 200,
      flex: 1,
      renderCell: AvatarCell,
    },
    {
      field: 'implementation_fee',
      headerName: 'Implementation Fee',
      minWidth: 200,
      flex: 1,
      renderCell: (params: GridRenderEditCellParams<any, any, any>) => {
        return params.value ? formatCurrency(params.value, customerDetail?.currency_code) : 0;
      },
    },
    {
      field: 'milestone_status',
      headerName: 'Milestone Status',
      minWidth: 200,
      flex: 1,
      renderCell: AvatarCell,
    },
    {
      field: 'task_planned_start_date',
      headerName: 'Planned Start Date',
      minWidth: 200,
      flex: 1,
      type: 'date',
      // valueGetter: ({ value }: any) => value && new Date(value),
      renderCell: (params: GridRenderCellParams) => {
        return <ViewDate date={params.row.task_planned_start_date} />;
      },
    },
    {
      field: 'task_start_date',
      headerName: 'Start Date',
      minWidth: 200,
      flex: 1,
      type: 'date',
      // valueGetter: ({ value }: any) => value && new Date(value),
      renderCell: (params: GridRenderCellParams) => {
        return <ViewDate date={params.row.task_start_date} />;
      },
    },
    {
      field: 'task_due_date',
      headerName: label_due_date,
      minWidth: 200,
      flex: 1,
      type: 'date',
      // valueGetter: ({ value }: any) => value && new Date(value),
      renderCell: (params: GridRenderCellParams) => {
        return <ViewDate date={params.row.task_due_date} />;
      },
    },
    {
      field: 'task_closed_date',
      headerName: label_delivery_date,
      minWidth: 200,
      flex: 1,
      type: 'date',
      // valueGetter: ({ value }: any) => value && new Date(value),
      renderCell: (params: GridRenderCellParams) => {
        return <ViewDate date={params.row.task_closed_date} />;
      },
    },
  ];

  const defaultRevColumnsProjectArr = [
    {
      field: 'task_uid',
      hide: true,
    },
    {
      field: 'task_detail',
      headerName: 'Milestone',
      minWidth: 200,
      flex: 1,
      renderCell: (params: any) => linkCell(params, openTask),
    },
    {
      field: 'client_name',
      headerName: `${label_client} Name`,
      minWidth: 200,
      flex: 1,
      renderCell: (params: any) => handleClientAccess(params),
    },
    {
      field: 'case_name$project_name',
      headerName:
        taskType === 0 ? `${label_project}s & ${label_use_case}s` : taskType === 1 ? label_use_case : label_project,
      minWidth: 200,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        if (taskType === 0) {
          if (params.row['case_name']) {
            return (
              <Typography
                variant="subtitle1"
                className="cognisaas-pointer link-text"
                onClick={() =>
                  navigate(
                    `/projects/project/${params.row.association_uid}/${params.row.project_uid}/${params.row.case_uid}`
                  )
                }
              >
                {params.row['case_name']}
              </Typography>
            );
          } else {
            return (
              <Typography
                variant="subtitle1"
                className="cognisaas-pointer link-text"
                onClick={() => navigate(`/projects/project/${params.row.association_uid}/${params.row.project_uid}`)}
              >
                {params.row['project_name']}
              </Typography>
            );
          }
        } else if (taskType === 1) {
          return (
            <Typography
              variant="subtitle1"
              className="cognisaas-pointer link-text"
              onClick={() =>
                navigate(
                  `/projects/project/${params.row.association_uid}/${params.row.project_uid}/${params.row.case_uid}`
                )
              }
            >
              {params.row['case_name']}
            </Typography>
          );
        } else {
          return (
            <Typography
              variant="subtitle1"
              className="cognisaas-pointer link-text"
              onClick={() => navigate(`/projects/project/${params.row.association_uid}/${params.row.project_uid}`)}
            >
              {params.row['project_name']}
            </Typography>
          );
        }
      },
    },
    {
      field: 'task_owner',
      headerName: 'Owner',
      minWidth: 200,
      flex: 1,
      renderCell: AvatarCell,
    },
    {
      field: 'implementation_fee',
      headerName: 'Implementation Fee',
      minWidth: 200,
      hide: true,
      flex: 1,
      renderCell: (params: GridRenderEditCellParams<any, any, any>) => {
        return params.value ? formatCurrency(params.value, customerDetail?.currency_code) : 0;
      },
    },
    {
      field: 'project_ARR',
      headerName: `${label_project} ${label_arr}`,
      hide: false,
      minWidth: 200,
      flex: 1,
      renderCell: (params: GridRenderEditCellParams<any, any, any>) => {
        return params.value
          ? formatCurrency(params.value, customerDetail?.currency_code)
          : formatCurrency(0, customerDetail?.currency_code);
      },
    },
    {
      field: 'milestone_status',
      headerName: 'Milestone Status',
      minWidth: 200,
      flex: 1,
      renderCell: AvatarCell,
    },
    {
      field: 'task_planned_start_date',
      headerName: 'Planned Start Date',
      minWidth: 200,
      flex: 1,
      type: 'date',
      // valueGetter: ({ value }: any) => value && new Date(value),
      renderCell: (params: GridRenderCellParams) => {
        return <ViewDate date={params.row.task_planned_start_date} />;
      },
    },
    {
      field: 'task_start_date',
      headerName: 'Start Date',
      minWidth: 200,
      flex: 1,
      type: 'date',
      // valueGetter: ({ value }: any) => value && new Date(value),
      renderCell: (params: GridRenderCellParams) => {
        return <ViewDate date={params.row.task_start_date} />;
      },
    },
    {
      field: 'task_due_date',
      headerName: label_due_date,
      minWidth: 200,
      flex: 1,
      type: 'date',
      // valueGetter: ({ value }: any) => value && new Date(value),
      renderCell: (params: GridRenderCellParams) => {
        return <ViewDate date={params.row.task_due_date} />;
      },
    },
    {
      field: 'task_closed_date',
      headerName: label_delivery_date,
      minWidth: 200,
      flex: 1,
      type: 'date',
      // valueGetter: ({ value }: any) => value && new Date(value),
      renderCell: (params: GridRenderCellParams) => {
        return <ViewDate date={params.row.task_closed_date} />;
      },
    },
  ];

  const columns = [
    {
      field: 'client_name',
      headerName: `${label_client} Name`,
      minWidth: 240,
      flex: 1,
      renderCell: (params: any) => handleClientAccess(params),
    },
    {
      field: 'renewal_task_owner',
      headerName: 'Owner',
      minWidth: 200,
      flex: 1,
      editable: true,
      renderEditCell: (params: any) => SelectEditDropDownCell(params),
      renderCell: (params: GridRenderCellParams) => {
        const clientOwnerProfileLink = getUserLogoLinkByUid(params.row.renewal_task_owner);
        return (
          <Stack direction="row">
            {clientOwnerProfileLink.length > 0 ? (
              <AvatarImage src={clientOwnerProfileLink} height="34px" width="34px" alt="Renewal task owner" />
            ) : (
              <Avatar
                style={{ height: '34px', width: '34px', marginTop: '3px' }}
                {...stringAvatar(params.row.renewal_task_owner_name)}
              />
            )}
            <Typography m={1} variant="subtitle1">
              {params.row.renewal_task_owner_name}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: 'renewal_task_name',
      headerName: 'Renewal Name',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'renewal_task_start_date',
      headerName: 'Start Date',
      minWidth: 120,
      flex: 1,
      type: 'date',
      // valueGetter: ({ value }: any) => value && new Date(value),
      renderCell: (params: GridRenderCellParams) => {
        return <ViewDate date={params.row.renewal_task_start_date} />;
      },
    },
    {
      field: 'renewal_task_due_date',
      headerName: label_due_date,
      minWidth: 120,
      flex: 1,
      type: 'date',
      // valueGetter: ({ value }: any) => value && new Date(value),
      renderCell: (params: GridRenderCellParams) => {
        return <ViewDate date={params.row.renewal_task_due_date} />;
      },
    },
    {
      field: 'renewal_task_done_on',
      headerName: `${label_delivery_date}`,
      flex: 1,
      minWidth: 120,
      type: 'date',
      // valueGetter: ({ value }: any) => value && new Date(value),
      renderCell: (params: GridRenderCellParams) => {
        return params.value == '0000-00-00 00:00:00' || !Date.parse(params.value) ? (
          <Typography variant="subtitle1">-</Typography>
        ) : (
          <Typography variant="subtitle1">
            {format(new Date(params.value.replace(/-/g, '/')), 'dd MMM yyyy')}
          </Typography>
        );
      },
    },
    {
      field: 'renewal_task_stage',
      headerName: 'Renewal Stage',
      minWidth: 200,
      flex: 1,
      editable: true,
      renderEditCell: (params: any) => SelectEditInputCell(params),
      renderCell: (params: GridRenderCellParams) => (
        <Stack>
          <Stack
            spacing={1}
            direction="row"
            sx={{ fontWeight: 500, fontSize: '12px', display: 'flex', alignItems: 'center', flexDirection: 'row' }}
          >
            <span style={{ marginRight: '4px' }}>
              {params.row.renewal_task_stage === 'Not Done' && (
                <CognisaasTaskStageIndicator isMileStoneTask={false} stage={taskStage.NOT_STARTED} />
              )}
              {params.row.renewal_task_stage === taskStage.DONE && (
                <CognisaasTaskStageIndicator isMileStoneTask={false} stage={taskStage.DONE} />
              )}
            </span>
            <span>{params.row.renewal_task_stage}</span>
          </Stack>
        </Stack>
      ),
    },
    {
      field: 'renewal_task_status',
      headerName: 'Renewal Status',
      minWidth: 200,
      flex: 1,
      // renderCell: StatusCell,
      renderCell: (params: GridRenderCellParams) => (
        <Stack>
          <Stack
            spacing={1}
            direction="row"
            sx={{ fontWeight: 500, fontSize: '12px', display: 'flex', alignItems: 'center' }}
          >
            <span
              ref={(el) => {
                if (el) {
                  el.style.setProperty('margin-top', '0px', 'important');
                }
              }}
              style={{ marginRight: '4px' }}
              className={
                params.value == 'red'
                  ? 'red_status_box_task'
                  : params.value == 'amber'
                  ? 'amber_status_box_task'
                  : params.value == 'green'
                  ? 'green_status_box_task'
                  : params.value == 'blue'
                  ? 'blue_status_box_task'
                  : params.value == 'grey'
                  ? 'grey_status_box_task'
                  : ''
              }
            ></span>
            <span>
              {params.value == 'red'
                ? 'Overdue'
                : params.value == 'amber'
                ? 'At Risk'
                : params.value == 'green'
                ? 'On Track'
                : params.value == 'blue'
                ? 'Completed'
                : params.value == 'grey'
                ? 'Deferred'
                : ''}
            </span>
          </Stack>
        </Stack>
      ),
    },
    {
      field: 'project_ARR',
      headerName: `${label_project} ${label_arr}`,
      hide: false,
      minWidth: 200,
      flex: 1,
      renderCell: (params: GridRenderEditCellParams<any, any, any>) => {
        return params.value ? formatCurrency(params.value, customerDetail?.currency_code) : 0;
      },
    },
  ];

  // now generate custom fields columns
  React.useEffect(() => {
    const cfRevColumns: any[] = [];

    if (customerCustomFields.status === 'success' && usecaseCfsWithSubfields.status === 'success') {
      // cf columns if one or more customer custom fields available
      customerCustomFields.data.forEach((cf) => {
        console.log(cf, 'custom fielddd');
        cfRevColumns.push({
          field: generateMappedCustomFieldKey(cf.field_name, cf.field_uid, cf.field_type),
          headerName: cf.field_name,
          minWidth: 200,
          flex: 1,
          type: cf.field_type == 'Date' ? 'date' : undefined,
          valueGetter: cf.field_type == 'Date' ? ({ value }: any) => value && new Date(value) : undefined,
          renderCell: (params: GridRenderCellParams) => {
            if (cf.field_type == 'String') {
              return parseLines(params.row[generateMappedCustomFieldKey(cf.field_name, cf.field_uid, cf.field_type)]);
            } else {
              return params.row[generateMappedCustomFieldKey(cf.field_name, cf.field_uid, cf.field_type)];
            }
          },
        });
      });

      // cf columns if one or more Use Cases custom fields configired
      usecaseCfsWithSubfields.data.forEach((cf) => {
        cfRevColumns.push({
          field: generateMappedCustomFieldKey(cf.field_name, cf.field_uid, cf.field_type),
          headerName: cf.field_name,
          minWidth: 200,
          flex: 1,
          type: cf.field_type == 'Date' ? 'date' : undefined,
          valueGetter: cf.field_type == 'Date' ? ({ value }: any) => value && new Date(value) : undefined,
        });
      });

      // combining default revenue columns an dcustom field columns
      if (customerDetail.revenue_report_basis == '0') {
        setRevTableColumns(generateOrderedColumn([...defaultRevColumns, ...cfRevColumns], uniqueRevenueFeeColumnId));
      } else if (customerDetail.revenue_report_basis == '1') {
        setRevTableColumns(
          generateOrderedColumn([...defaultRevColumnsProjectArr, ...cfRevColumns], uniqueRevenueFeeColumnId)
        );
      }
    }
  }, [customerCustomFields, usecaseCfsWithSubfields, taskType, customerDetail]);

  // setting up filters here
  React.useEffect(() => {
    if (
      revenueUsecaseTasksState.status === requestState.success &&
      revenueProjectTasksState.status === requestState.success &&
      usecaseCfsWithSubfields.status === requestState.success &&
      customerCustomFields.status === requestState.success
    ) {
      // filters from here
      const defaultRevFilters = [
        {
          name: 'Owner',
          dataKey: 'task_owner',
          isSelected: false,
          equalToValue: [],
          canBeCleared: true,
          availableOptions: allUsers.map((item: any) => item.user_username),
        },
        {
          name: 'Milestone Status',
          dataKey: 'milestone_status',
          isSelected: false,
          equalToValue: [],
          canBeCleared: true,
          availableOptions: allMilestoneStatus.data,
        },
        {
          name: label_due_date + '$$date-range-picker',
          dataKey: 'task_due_date',
          isSelected: false,
          equalToValue: [],
          canBeCleared: true,
          availableOptions: availableDateRangeOptions,
        },
        {
          name: label_delivery_date + '$$date-range-picker',
          dataKey: 'task_closed_date',
          isSelected: false,
          equalToValue: [],
          canBeCleared: true,
          availableOptions: availableDateRangeOptions,
        },
      ];
      const cfRevFilters: any[] = [];

      // cf filters if one or more customer custom fields available
      customerCustomFields.data.forEach((cf) => {
        if (['RAG status', 'Dropdown'].includes(cf.field_type)) {
          cfRevFilters.push({
            name: cf.field_name,
            dataKey: generateMappedCustomFieldKey(cf.field_name, cf.field_uid, cf.field_type),
            isSelected: false,
            equalToValue: [],
            canBeCleared: true,
            availableOptions: cf.valid_values.split(','),
          });
        }
      });

      // cf filters if one or more Use Cases custom fields configired
      usecaseCfsWithSubfields.data.forEach((cf) => {
        if (['RAG status', 'Dropdown'].includes(cf.field_type)) {
          cfRevFilters.push({
            name: cf.field_name,
            dataKey: generateMappedCustomFieldKey(cf.field_name, cf.field_uid, cf.field_type),
            isSelected: false,
            equalToValue: [],
            canBeCleared: true,
            availableOptions: cf.valid_values.split(','),
          });
        }
      });

      if (
        isUndefined(allFilters['revenueReportsPageForImplementationFee']) ||
        isNull(allFilters['revenueReportsPageForImplementationFee'])
      ) {
        dispatch(
          addFilterGroup({
            idName: 'revenueReportsPageForImplementationFee',
            appliedFilters: [...defaultRevFilters, ...cfRevFilters],
          })
        );
      }
    }
  }, [revenueUsecaseTasksState, revenueProjectTasksState, usecaseCfsWithSubfields, customerCustomFields]);

  // setting up revenue data here
  React.useEffect(() => {
    if (
      revenueProjectTasksState.status === requestState.success &&
      revenueUsecaseTasksState.status === requestState.success
    ) {
      const cfMappedProjectRevenueData = [
        ...generateDataWhileMappingCustomFieldsAsNormalFields(
          revenueProjectTasksState.data,
          'client_custom_values',
          'field_name',
          'field_value',
          'field_uid',
          'field_type'
        ),
      ];

      let cfMappedUsecaseRevenueData = [
        ...generateDataWhileMappingCustomFieldsAsNormalFields(
          revenueUsecaseTasksState.data,
          'usecase_custom_values',
          'field_name',
          'field_value',
          'field_uid',
          'field_type'
        ),
      ];

      cfMappedUsecaseRevenueData = [
        ...generateDataWhileMappingCustomFieldsAsNormalFields(
          cfMappedUsecaseRevenueData,
          'client_custom_values',
          'field_name',
          'field_value',
          'field_uid',
          'field_type'
        ),
      ];
      if (selectedTab === 0) {
        if (taskType === 0) {
          if (allFilters['revenueReportsPageForImplementationFee']) {
            const newFilteredData = new FilterableData([...cfMappedProjectRevenueData, ...cfMappedUsecaseRevenueData]);
            newFilteredData.bulkFilter(allFilters['revenueReportsPageForImplementationFee'].appliedFilters);
            setFilteredRevenueTasks(newFilteredData.toArray());
          }
        } else if (taskType === 2) {
          // setCfMappedRevenueTasks([...cfMappedProjectRevenueData]);
          if (allFilters['revenueReportsPageForImplementationFee']) {
            const newFilteredData = new FilterableData([...cfMappedProjectRevenueData]);
            newFilteredData.bulkFilter(allFilters['revenueReportsPageForImplementationFee'].appliedFilters);
            setFilteredRevenueTasks(newFilteredData.toArray());
          }
        } else if (taskType === 1) {
          // setCfMappedRevenueTasks([...cfMappedUsecaseRevenueData]);
          if (allFilters['revenueReportsPageForImplementationFee']) {
            const newFilteredData = new FilterableData([...cfMappedUsecaseRevenueData]);
            newFilteredData.bulkFilter(allFilters['revenueReportsPageForImplementationFee'].appliedFilters);
            setFilteredRevenueTasks(newFilteredData.toArray());
          }
        }
      }
    }
  }, [revenueProjectTasksState, revenueUsecaseTasksState, taskType, allFilters]);

  React.useEffect(() => {
    if (renewalTasks !== null) {
      const renewals: any = [...renewalTasks];
      setFilteredRenewalTasks(renewals);
    }
    if (
      isUndefined(allFilters['revenueReportsPageForRenewalTasks']) ||
      isNull(allFilters['revenueReportsPageForRenewalTasks'])
    ) {
      dispatch(
        addFilterGroup({
          idName: 'revenueReportsPageForRenewalTasks',
          appliedFilters: [
            {
              name: 'Owner',
              dataKey: 'client_account_owner',
              isSelected: false,
              equalToValue: [],
              canBeCleared: true,
              availableOptions: allUsers.map((item: any) => item.user_username),
            },
            {
              name: 'Status',
              dataKey: 'renewal_task_status',
              isSelected: false,
              equalToValue: [],
              canBeCleared: true,
              availableOptions: allGeneralStatus.data,
            },
            {
              name: 'Stage',
              dataKey: 'renewal_task_stage',
              isSelected: false,
              equalToValue: [],
              canBeCleared: true,
              availableOptions: allRenewalStatus.data,
            },
          ],
        })
      );
    }
  }, [renewalTasks]);

  React.useEffect(() => {
    if (selectedTab === 1) {
      if (allFilters['revenueReportsPageForRenewalTasks'] && renewalTasks !== null) {
        const newFilteredData = new FilterableData(renewalTasks);
        newFilteredData.bulkFilter(allFilters['revenueReportsPageForRenewalTasks'].appliedFilters);
        setFilteredRenewalTasks(newFilteredData.toArray());
      }
    }
  }, [allFilters]);

  const handleRenewalAccountClick = (rowData: any) => {
    navigate(`/client-list/client/${rowData.client_uid}`);
  };

  const openTask = async (row: any) => {
    const client = {
      client_uid: row.client_uid,
    };
    setClientUid(client);
    const taskResult = await dispatch(getTaskByTaskUid(row.task_uid)).unwrap();
    setTaskToOpen({ ...taskResult, client_uid: row.client_uid });
    setUpdateTaskDrawer(!updateTaskDrawer);
  };

  function SelectEditInputCell(params: any) {
    const apiRef = useGridApiContext();
    const { id, field } = params;
    const handleChange = async (event: SelectChangeEvent<unknown>) => {
      apiRef.current.setEditCellValue({ id, field, value: event.target.value as string });
      const detail = { ...params.row, task_stage: event.target.value };
      await dispatch(doneRenewalStage(detail));
      afterUpdatingTheStage();
      apiRef.current.stopCellEditMode({ id, field });
    };
    const afterUpdatingTheStage = async () => {
      await dispatch(updatedcasewithfeaturestatusClient(params.row.client_uid));
      await dispatch(fetchRenewalTasks());
    };
    return (
      <>
        <>
          <div style={{ padding: '0 4px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CognisaasStageSelect
              changeHandler={(e: any) => {
                handleChange(e);
                e.stopPropagation();
              }}
              width="156px"
              value={params.value}
              isMileStoneTask={false}
              renewalTask={true}
            />
          </div>
        </>
      </>
    );
  }
  function SelectEditDropDownCell(params: any) {
    const apiRef = useGridApiContext();
    const { id, field } = params;
    const data = allUsers.map((user: any) => user.user_username);
    data.unshift('Not Assigned');
    const handleChange = async (event: SelectChangeEvent<unknown>) => {
      apiRef.current.setEditCellValue({ id, field, value: event.target.value as string });
      const detail = { ...params.row, task_owner: getUserUid(event.target.value as string) };
      await dispatch(taskRenewalOwner(detail));
      afterUpdatingTheOwner();
      apiRef.current.stopCellEditMode({ id, field });
    };
    const afterUpdatingTheOwner = async () => {
      await dispatch(updatedcasewithfeaturestatusClient(params.row.client_uid));
      await dispatch(fetchRenewalTasks());
    };
    return (
      <>
        <div style={{ padding: '0 4px', display: 'flex', alignItems: 'center', width: 'inherit' }}>
          <CognisaasDropdown
            validvalues={[...data]}
            onChange={(event: any) => {
              handleChange(event);
              event.stopPropagation();
            }}
            value={params.row.renewal_task_owner_name}
            placeholder="Not Selected"
          />
        </div>
      </>
    );
  }
  const handleColumnOrderChange = (params: GridColumnOrderChangeParams) => {
    const oldArrayIndex = params.oldIndex;
    const targetArrayIndex = params.targetIndex;
    setRevTableColumns(
      generateOrderedColumn(revTableColumns, uniqueRevenueFeeColumnId, oldArrayIndex, targetArrayIndex)
    );
  };

  const handleRenewalColumnOrderChange = (params: GridColumnOrderChangeParams) => {
    const oldArrayIndex = params.oldIndex;
    const targetArrayIndex = params.targetIndex;
    setRenewalTableColumns(
      generateOrderedColumn(renewalTableColumns, uniqueRevenueRenewalsColumnId, oldArrayIndex, targetArrayIndex)
    );
  };

  return (
    <Box sx={{ display: 'grid', gridTemplateRows: '70px auto' }}>
      <ReportsHeader pageName={ReportsPages.RevenueReports} />
      <Box sx={{ display: 'grid', gridTemplateRows: 'auto auto', m: '0px 20px' }}>
        {/* tabs and filter icon */}
        <Box>
          <CognisaasTabs
            size="squeezed"
            tabs={tabs}
            handleTabSwitch={(e, tabIndex) => {
              setSelectedTab(tabIndex);
            }}
          />
        </Box>

        <Box sx={{ display: 'grid', gridTemplateRows: 'auto auto', gridRowGap: '20px' }}>
          {selectedTab === 0 && (
            <>
              <Box
                sx={{
                  display: 'grid',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gridTemplateColumns: 'auto auto',
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', mt: '20px' }}>
                  <CognisaasButtonGroup
                    options={['all', `${label_use_case}s`, `${label_project}s`]}
                    selectdOption={taskType}
                    setSelectedOption={setTaskType}
                  />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', justifySelf: 'flex-end', mt: '20px' }}>
                  <FilterTypeSelector idName="revenueReportsPageForImplementationFee" />
                </Box>
              </Box>
              {allFilters['revenueReportsPageForImplementationFee']?.appliedFilters.filter((item) => item.isSelected)
                .length > 0 && (
                <Box sx={{ mb: '20px' }}>
                  <SelectedFilterList idName="revenueReportsPageForImplementationFee" isResetEnabled={true} />
                </Box>
              )}
            </>
          )}
          {selectedTab === 1 && (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: '20px' }}>
              <Box>
                {allFilters['revenueReportsPageForRenewalTasks']?.appliedFilters.filter((item) => item.isSelected)
                  .length > 0 && (
                  <SelectedFilterList idName="revenueReportsPageForRenewalTasks" isResetEnabled={true} />
                )}
              </Box>
              <FilterTypeSelector idName="revenueReportsPageForRenewalTasks" />
            </Box>
          )}
        </Box>

        {/* tables */}
        <Box>
          {selectedTab === 0 ? (
            // revenue table
            <>
              {revTableColumns.length > 0 ? (
                <CogniTable
                  key={1}
                  hideFooter={false}
                  hideHeader={false}
                  pageSize={10}
                  columns={revTableColumns}
                  data={filteredRevenueTasks}
                  onColumnOrderChange={handleColumnOrderChange}
                  idName="revenueReportRevenue"
                />
              ) : (
                <></>
              )}
            </>
          ) : selectedTab === 1 ? (
            <>
              {isUndefined(filteredRenewalTasks) ? (
                <>
                  <Stack justifyContent="center" alignItems="center">
                    <NoRecords
                      style={{ marginLeft: '6px', marginTop: '10px', width: '250px', height: '250px' }}
                    ></NoRecords>
                    <Typography variant="h6">No Records Found!</Typography>
                  </Stack>
                </>
              ) : (
                <>
                  <CogniTable
                    key={2}
                    hideFooter={false}
                    hideHeader={false}
                    pageSize={10}
                    columns={renewalTableColumns}
                    data={filteredRenewalTasks}
                    onColumnOrderChange={handleRenewalColumnOrderChange}
                    idName="revenueReportRenewals"
                  />
                </>
              )}
            </>
          ) : (
            <></>
          )}
        </Box>
      </Box>
      {updateTaskDrawer && (
        <UpdateTaskDrawer
          client={clientUid}
          taskDetail={taskToOpen}
          drawerState={updateTaskDrawer}
          onClose={() => setUpdateTaskDrawer(!updateTaskDrawer)}
        />
      )}
    </Box>
  );
};

export default RevenueReport;
