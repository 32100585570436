export const reportsTaskTransformer = (result: any) => {
  console.log(result);
  const allTasks: any = [];
  for (let i = 0; i < result.tasks.length; i++) {
    let task: any = {};
    const taskCustomFields: any = [];
    for (let j = 0; j < result.customValues.length; j++) {
      if (result.customValues[j].task_uid == result.tasks[i].task_uid) {
        taskCustomFields.push(result.customValues[j]);
      }
    }
    if (result.tasks[i].is_team_task == 1) {
      if (result.tasks[i].user_username == null) {
        task = {
          ...result.tasks[i],
          custom_values: [...taskCustomFields],
          task_owner: 'Not Assigned',
          task_owner_uid: result.tasks[i].task_owner,
        };
      } else {
        task = {
          ...result.tasks[i],
          custom_values: [...taskCustomFields],
          task_owner: result.tasks[i].user_username,
          task_owner_uid: result.tasks[i].task_owner,
        };
      }
    } else {
      if (result.tasks[i].client_user_name == null) {
        task = {
          ...result.tasks[i],
          custom_values: [...taskCustomFields],
          task_owner: 'Not Assigned',
          task_owner_uid: result.tasks[i].task_owner,
        };
      } else {
        task = {
          ...result.tasks[i],
          custom_values: [...taskCustomFields],
          task_owner: result.tasks[i].client_user_name,
          task_owner_uid: result.tasks[i].task_owner,
        };
      }
    }
    allTasks.push(task);
  }
  console.log(allTasks);
  return allTasks;
};
