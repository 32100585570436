import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../';

export const authState = (state: RootState) => state.authState;
export const selectAuthState = createSelector(authState, (authState) => authState.isAuthed);
export const selectAuthStatus = createSelector(authState, (authState) => authState.status);
export const selectErrorMessage = createSelector(authState, (authState) => authState.error);
export const selectEmailStatus = createSelector(authState, (authState) => authState.doesEmailExist);
export const selectSignupEmailSentStatus = createSelector(authState, (authState) => authState.signupEmailSent);
export const selectIsEmailValidStatus = createSelector(authState, (authState) => authState.isEmailValid);
export const selectAllowAccess = createSelector(authState, (authState) => authState.allowAccess);
export const selectSetUserObj = createSelector(authState, (authState) => authState.setUserDataObj);
export const selectSignupUserData = createSelector(authState, (authState) => authState.fullDetails);
export const selectRedirectToLogin = createSelector(authState, (authState) => authState.redirectToLogin);
export const selectRedirectToLoginOnGoogleSignup = createSelector(
  authState,
  (authState) => authState.redirectToLoginOnGoogleSignup
);

export const selectGoogleUID = createSelector(authState, (authState) => authState.signupOnGoogleUID);
export const selectGoogleToken = createSelector(authState, (authState) => authState.signupOnGoogleToken);
export const selectRedirectToCompleteAccount = createSelector(
  authState,
  (authState) => authState.redirectToCompleteAccount
);
export const selectSuccessMessage = createSelector(authState, (authState) => authState.successMessage);
export const selectpasswordUpdated = createSelector(authState, (authState) => authState.isPasswordUpdated);
export const selectpasswordUpdatedState = createSelector(authState, (authState) => authState.passwordUpdated);
export const selectShowFeedbackScreen = createSelector(authState, (authState) => authState.showSuccessScreenFeedback);
