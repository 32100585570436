import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../index';

const selectCommonStore = (state: RootState) => state.commonFeature;

export const selectUsers = createSelector(selectCommonStore, (commonStore) => commonStore.users);

export const selectUsersForDropdownCell = createSelector(selectUsers, (users) =>
  users.data.map((user) => {
    user.name = user.user_name;
    return user;
  })
);
export const selectSegments = createSelector(selectCommonStore, (commonStore) => commonStore.segments);

export const selectIndustries = createSelector(selectCommonStore, (commonStore) => commonStore.industries);

export const selectStages = createSelector(selectCommonStore, (commonStore) => commonStore.stages);
