import CardContent from '@mui/material/CardContent';

import Typography from '@mui/material/Typography';
import { CardHeader, Avatar, Grid, Box } from '@mui/material';

import CognisaasCard from '../CognisaasCard';
import { useNavigate } from 'react-router-dom';
import { label_arr, label_cs_owner } from 'components/Settings/Preferences/CustomLabelCookieHelper';

interface props {
  data: any;
}

const CognisaasClientCard = (props: props) => {
  // console.log(props);
  // console.log('foedjgfv');
  const navigate = useNavigate();

  return (
    <Box
      onClick={() => {
        navigate(`/client-list/client/${props.data.client_uid}`);
      }}
      className={'cognisaas-pointer'}
    >
      <CognisaasCard width="320px" height="268px" clientStatusColor={props.data.client_readiness_status}>
        <CardContent sx={{ paddingRight: '0px' }}>
          <CardHeader
            sx={{ pl: 0, pt: 0 }}
            avatar={
              <Avatar sx={{ color: 'black', bgcolor: '#9EF3F380' }} aria-label="recipe">
                {props.data.client_name.split(' ')[0][0]}
                {props.data.client_name.indexOf(' ') >= 0 ? props.data.client_name.split(' ')[1][0] : null}
              </Avatar>
            }
            title={<Typography variant="h2">{props.data.client_name}</Typography>}
          />
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Typography className="label-text" variant="subtitle2">
                {label_arr}
              </Typography>
              <Typography variant="subtitle1">{props.data.client_account_owner}</Typography>
            </Grid>
            <Grid style={{ paddingLeft: '0px' }} item xs={6}>
              <Typography className="label-text" variant="subtitle2" display={'inline'}>
                Top
              </Typography>
              <Typography className="label-text" variant="subtitle2" display={'inline'}>
                &nbsp;Issues
              </Typography>
              <Typography variant="subtitle1">3</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography className="label-text" variant="subtitle2">
                Industry
              </Typography>
              <Typography variant="subtitle1">Agriculture</Typography>
            </Grid>
            <Grid style={{ paddingLeft: '0px' }} item xs={6}>
              <Typography className="label-text" variant="subtitle2" display={'inline'}>
                Segment
              </Typography>

              <Typography variant="subtitle1">{props.data.segment_name}</Typography>
            </Grid>
            <div style={{ paddingTop: '16px' }}>
              <Avatar
                sx={{
                  bgcolor: '#EC9EF380',
                  color: 'black',
                  display: 'inline-grid',
                  width: 24,
                  height: 24,
                  marginLeft: '7px',
                  fontSize: '0.8rem',
                }}
                aria-label="recipe"
              >
                {props.data.client_account_owner.split(' ')[0][0]}
                {props.data.client_account_owner.indexOf(' ') >= 0
                  ? props.data.client_account_owner.split(' ')[1][0]
                  : null}
              </Avatar>
              <Typography sx={{ ml: '8px' }} className="label-text" variant="subtitle2" display={'inline-block'}>
                {label_cs_owner}
              </Typography>
              <Typography sx={{ ml: 2, pl: 3, fontWeight: '400', fontSize: '14px' }}>
                {props.data.client_account_owner}
              </Typography>
            </div>
          </Grid>
        </CardContent>
      </CognisaasCard>
    </Box>
  );
};

export default CognisaasClientCard;
