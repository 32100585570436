import { getUserUid } from 'utils/getUid';
import { valueToMinutes } from '../Project/AddProjectTemplate/helper';
import { apis } from 'api/axiosInstance';

let allSection: any[] = [];
export const updatePlanFromExcel = async (rows: any[], uid: string, sections: any) => {
  allSection = [...sections];
  for (let r = 0; r < rows.length; r++) {
    if (rows[r].phase_name.length > 0) {
      if (rows[r].template_task_uid) {
        // old task just update
        const data = {
          days_to_start: valueToMinutes(rows[r].duration),
          template_milestone_task: rows[r].milestone == 'Yes' ? '1' : '0',
          template_project_uid: uid,
          template_task_client_check: rows[r].share_with_client == 'Yes' ? '1' : '0',
          template_task_description: '',
          template_task_detail: rows[r].task_name,
          template_task_owner: rows[r].task_owner,
          template_task_percentage: rows[r].implementation_fee,
          template_task_priority: rows[r].task_priority.length > 0 ? rows[r].task_priority : 'Medium',
          template_task_uid: rows[r].template_task_uid,
          user_uid: getUserUid(rows[r].task_owner),
        };
        const res = await apis.phpAxiosInstance.post('updateTemplateProjectTaskbyuid', data, {
          headers: { JWT: 'true' },
        });
        rows[r] = { ...rows[r], ...res.data.result };
        console.log(rows);
      } else {
        // Check if phase new , if new add it and the add task to it
        if (!findSectionUid(allSection, rows[r].phase_name)) {
          const newSection: any = await addSection(rows[r].phase_name, uid);
          // sections.push(newSection);
          console.log(sections);
          //   add the task now
          const taskDetail = {
            clone_template_task_uid: '',
            days_to_start: valueToMinutes(rows[r].duration),
            task_sequence: '1',
            template_milestone_task: rows[r].milestone.length > 0 ? '1' : '0',
            template_project_uid: uid,
            template_section_uid: newSection.template_section_uid,
            template_task_client_check: rows[r].share_with_client.length > 0 ? '1' : '0',
            template_task_description: '',
            template_task_detail: rows[r].task_name,
            template_task_owner: rows[r].task_owner ? rows[r].task_owner : 'Not Assigned',
            template_task_percentage: rows[r].implementation_fee ? rows[r].implementation_fee.toLocaleString() : '0',
            template_task_priority: rows[r].task_priority.length > 0 ? rows[r].task_priority : 'Medium',
            user_uid: rows[r].task_owner ? getUserUid(rows[r].task_owner) : '15243',
          };
          console.log(taskDetail);
          // const data = addProhectTemplateTask({ ...taskDetail, template_task_percentage: 0 });
          // console.log(data);
          let task: any;
          if (rows[r].task_type == 'External') {
            task = await apis.phpAxiosInstance.post('addTemplateProjectClientTask', taskDetail, {
              headers: { JWT: 'true' },
            });
          } else {
            task = await apis.phpAxiosInstance.post('addTemplateProjectTask', taskDetail, {
              headers: { JWT: 'true' },
            });
          }

          rows[r] = { ...rows[r], ...task.data.result };
          console.log(rows);
        } else {
          console.log(rows[r]);
          const data = {
            clone_template_task_uid: '',
            days_to_start: valueToMinutes(rows[r].duration),
            template_milestone_task: rows[r].milestone == 'Yes' ? '1' : '0',
            template_project_uid: uid,
            template_task_client_check: rows[r].share_with_client == 'Yes' ? '1' : '0',
            template_task_description: '',
            template_task_detail: rows[r].task_name,
            template_task_owner: rows[r].task_owner ? rows[r].task_owner : 'Not Assigned',
            template_task_percentage: rows[r].implementation_fee.toLocaleString(),
            template_task_priority: rows[r].task_priority.length > 0 ? rows[r].task_priority : 'Medium',
            // template_task_uid: row.template_task_uid,
            user_uid: rows[r].task_owner ? getUserUid(rows[r].task_owner) : '15243',
            task_sequence: findSectionTaskLength(allSection, rows[r].phase_name),
            template_section_uid: findSectionUid(allSection, rows[r].phase_name),
          };
          let res: any;
          if (rows[r].task_type == 'External') {
            res = await apis.phpAxiosInstance.post('addTemplateProjectClientTask', data, {
              headers: { JWT: 'true' },
            });
          } else {
            res = await apis.phpAxiosInstance.post('addTemplateProjectTask', data, {
              headers: { JWT: 'true' },
            });
          }

          rows[r] = { ...rows[r], ...res.data.result };
          console.log(rows);
        }
      }
    }
  }
};

const findSectionUid = (sections: any[], name: any) => {
  let sec = null;
  sections.forEach((section: any) => {
    if (section.template_section_detail == name) {
      sec = section.template_section_uid;
    }
  });
  return sec;
};

const findSectionTaskLength = (sections: any, name: string) => {
  for (let i = 0; i < sections.length; i++) {
    if (sections[i].template_section_detail == name) {
      console.log(sections[i]);
      return sections[i].task.length;
    }
  }
};

const addSection = async (name: string, uid: string) => {
  const project = {
    template_project_uid: uid,
  };
  const detail = await apis.phpAxiosInstance.post('getAllSectionByTemplateProjectuid', project, {
    headers: { JWT: 'true' },
  });
  const detail1: any = {
    template_project_uid: uid,
    template_section_detail: name,
    template_section_sequence: detail.data.result.length,
  };
  const section: any = await apis.phpAxiosInstance.post('saveTemplateProjectSection', detail1, {
    headers: { JWT: 'true' },
  });
  console.log(section);

  const allSections = await apis.phpAxiosInstance.post('getAllSectionByTemplateProjectuid', project, {
    headers: { JWT: 'true' },
  });

  allSection = [...allSections.data.result];

  return section.data.result;
};

// USECASE
export const updatePlanFromExcelUsecase = async (rows: any[], uid: string, sections: any) => {
  allSection = [...sections];
  for (let r = 0; r < rows.length; r++) {
    if (rows[r].phase_name.length > 0) {
      if (rows[r].template_task_uid) {
        // old task just update
        const data = {
          days_to_start: valueToMinutes(rows[r].duration),
          template_milestone_task: rows[r].milestone == 'Yes' ? '1' : '0',
          template_case_uid: uid,
          template_task_client_check: rows[r].share_with_client == 'Yes' ? '1' : '0',
          template_task_description: '',
          template_task_detail: rows[r].task_name,
          template_task_owner: rows[r].task_owner,
          template_task_percentage: rows[r].implementation_fee,
          template_task_priority: rows[r].task_priority.length > 0 ? rows[r].task_priority : 'Medium',
          template_task_uid: rows[r].template_task_uid,
          user_uid: getUserUid(rows[r].task_owner),
        };
        const res = await apis.phpAxiosInstance.post('updateTemplateUsecaseTaskbyuid', data, {
          headers: { JWT: 'true' },
        });
        rows[r] = { ...rows[r], ...res.data.result };
        console.log(rows);
      } else {
        // Check if phase new , if new add it and the add task to it
        if (!findSectionUid(allSection, rows[r].phase_name)) {
          const newSection: any = await addSectionUsecase(rows[r].phase_name, uid);
          // sections.push(newSection);
          console.log(sections);
          //   add the task now
          const taskDetail = {
            clone_template_task_uid: '',
            days_to_start: valueToMinutes(rows[r].duration),
            task_sequence: '1',
            template_milestone_task: rows[r].milestone.length > 0 ? '1' : '0',
            template_case_uid: uid,
            template_section_uid: newSection.template_section_uid,
            template_task_client_check: rows[r].share_with_client.length > 0 ? '1' : '0',
            template_task_description: '',
            template_task_detail: rows[r].task_name,
            template_task_owner: rows[r].task_owner ? rows[r].task_owner : 'Not Assigned',
            template_task_percentage: rows[r].implementation_fee ? rows[r].implementation_fee.toLocaleString() : '0',
            template_task_priority: rows[r].task_priority.length > 0 ? rows[r].task_priority : 'Medium',
            user_uid: rows[r].task_owner ? getUserUid(rows[r].task_owner) : '15243',
          };
          console.log(taskDetail);
          // const data = addProhectTemplateTask({ ...taskDetail, template_task_percentage: 0 });
          // console.log(data);
          let task: any;
          if (rows[r].task_type == 'External') {
            task = await apis.phpAxiosInstance.post('addTemplateUsecaseClientTask', taskDetail, {
              headers: { JWT: 'true' },
            });
          } else {
            task = await apis.phpAxiosInstance.post('addTemplateUsecaseTask', taskDetail, {
              headers: { JWT: 'true' },
            });
          }

          rows[r] = { ...rows[r], ...task.data.result };
          console.log(rows);
        } else {
          console.log(rows[r]);
          const data = {
            clone_template_task_uid: '',
            days_to_start: valueToMinutes(rows[r].duration),
            template_milestone_task: rows[r].milestone == 'Yes' ? '1' : '0',
            template_project_uid: uid,
            template_task_client_check: rows[r].share_with_client == 'Yes' ? '1' : '0',
            template_task_description: '',
            template_task_detail: rows[r].task_name,
            template_task_owner: rows[r].task_owner ? rows[r].task_owner : 'Not Assigned',
            template_task_percentage: rows[r].implementation_fee.toLocaleString(),
            template_task_priority: rows[r].task_priority.length > 0 ? rows[r].task_priority : 'Medium',
            // template_task_uid: row.template_task_uid,
            user_uid: rows[r].task_owner ? getUserUid(rows[r].task_owner) : '15243',
            task_sequence: findSectionTaskLengthUsecase(allSection, rows[r].phase_name),
            template_section_uid: findSectionUidUsecase(allSection, rows[r].phase_name),
          };
          let res: any;
          if (rows[r].task_type == 'External') {
            res = await apis.phpAxiosInstance.post('addTemplateUsecaseClientTask', data, {
              headers: { JWT: 'true' },
            });
          } else {
            res = await apis.phpAxiosInstance.post('addTemplateProjectTask', data, {
              headers: { JWT: 'true' },
            });
          }

          rows[r] = { ...rows[r], ...res.data.result };
          console.log(rows);
        }
      }
    }
  }
};

const findSectionUidUsecase = (sections: any[], name: any) => {
  let sec = null;
  sections.forEach((section: any) => {
    if (section.template_section_detail == name) {
      sec = section.template_section_uid;
    }
  });
  return sec;
};

const findSectionTaskLengthUsecase = (sections: any, name: string) => {
  for (let i = 0; i < sections.length; i++) {
    if (sections[i].template_section_detail == name) {
      console.log(sections[i]);
      return sections[i].task.length;
    }
  }
};

const addSectionUsecase = async (name: string, uid: string) => {
  const project = {
    template_project_uid: uid,
  };
  const detail = await apis.phpAxiosInstance.post('getAllSectionByTemplateCaseuid', project, {
    headers: { JWT: 'true' },
  });
  const detail1: any = {
    template_project_uid: uid,
    template_section_detail: name,
    template_section_sequence: detail.data.result.length,
  };
  const section: any = await apis.phpAxiosInstance.post('saveTemplateUsecaseSection', detail1, {
    headers: { JWT: 'true' },
  });
  console.log(section);

  const allSections = await apis.phpAxiosInstance.post('getAllSectionByTemplateCaseuid', project, {
    headers: { JWT: 'true' },
  });

  allSection = [...allSections.data.result];

  return section.data.result;
};
