import Box from '@mui/material/Box';
import { Modal, ModalProps } from '@mui/material';
// import Modal from '@mui/material/Modal';

interface props extends ModalProps {
  open: boolean;
  onClose: () => void;
  children: any;
  height?: number | string;
  width?: number | string;
}

export default function BasicModal(props: props) {
  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);
  // console.log(props);
  const newHeight = props.height == undefined ? 442 : props.height;
  const newWidth = props.width == undefined ? 350 : props.width;
  return (
    <div>
      <Modal
        {...props}
        open={props.open}
        onClose={() => {
          // handleClose();
          props.onClose();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            overflow: newHeight < 350 ? 'none' : 'scroll',
            display: 'block',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 2,
            maxHeight: newHeight,
            width: newWidth,
          }}
        >
          {props.children}
        </Box>
      </Modal>
    </div>
  );
}
