import React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';

interface MenuProps {
  list: {
    value: string;
    handleClick: (props: any) => void;
    args: any[]
  }[];
}

const CognisaasMenu = (props: MenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={event => setAnchorEl(event.currentTarget as HTMLInputElement)}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        elevation={0}
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        onClose={() => setAnchorEl(null)}
      >
        {
          props.list.map((item: any, index: number) => {
            if (item.value) {
              return (
                <MenuItem
                  key={index}
                  onClick={() => {
                    setAnchorEl(null);
                    item.handleClick(...item.args);
                  }}
                >
                  {item.value}
                </MenuItem>
              );
            }
          })
        }
      </Menu>
    </>
  );
};

export default CognisaasMenu;
