import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../index';

const selectSettingDataState = (state: RootState) => state.settingState;

export const selectCustomJourneyStageData = createSelector(selectSettingDataState, (settingSlice) => {
  return settingSlice.customJourneyStageClientConfigurations;
});
export const selectJourneyStageData = createSelector(selectSettingDataState, (settingSlice) => {
  return settingSlice.journeyStageClientConfigurations;
});
export const selectUpdateJourneyStatus = createSelector(selectSettingDataState, (settingSlice) => {
  return settingSlice.updateJourneyTable;
});

export const selectUpdateJourneyStatusExsiting = createSelector(selectSettingDataState, (settingSlice) => {
  return settingSlice.updateJourneyTableExsiting;
});

export const selectCustomerDetails = createSelector(selectSettingDataState, (settingSlice) => {
  return settingSlice.customerDetails;
});

export const getUserData_selector = createSelector(selectSettingDataState, (settingSlice) => {
  return settingSlice.userDetails;
});

export const customerFieldClientRecords = createSelector(selectSettingDataState, (settingSlice) => {
  return settingSlice.customerFieldClientRecords;
});

export const customerFieldProjectRecords = createSelector(selectSettingDataState, (settingSlice) => {
  return settingSlice.customerFieldProjectRecords;
});

export const customerFieldUsecaseRecords = createSelector(selectSettingDataState, (settingSlice) => {
  return settingSlice.customerFieldUsecaseRecords;
});

export const customerFieldTaskRecords = createSelector(selectSettingDataState, (settingSlice) => {
  return settingSlice.customerFieldTaskRecords;
});

export const getNotificationData = createSelector(selectSettingDataState, (settingSlice) => {
  return settingSlice.notificationData;
});

export const getAllTasksData_selector = createSelector(selectSettingDataState, (settingSlice) => {
  return settingSlice.taskDetails;
});

export const selectClientListColumns = createSelector(selectSettingDataState, (settingSlice) => {
  return settingSlice.clientListColumns;
});

export const selectAllJiraProjectsData = createSelector(selectSettingDataState, (settingSlice) => {
  return settingSlice.allJiraProjectsDetails;
});

export const selectAlreadyAddedJiraProjectsData = createSelector(selectSettingDataState, (settingSlice) => {
  return settingSlice.jiraProjectsAlreadyAdded;
});

export const selectAddCustomerLogoStatus = createSelector(
  selectSettingDataState,
  (settingSlice) => settingSlice.addCustomerLogo.status
);

export const selectAddCustomerLogoState = createSelector(
  selectSettingDataState,
  (settingSlice) => settingSlice.addCustomerLogo
);

export const selectAddCustomerLogoError = createSelector(
  selectSettingDataState,
  (settingSlice) => settingSlice.addCustomerLogo.error
);

export const selectAddUserLogoStatus = createSelector(
  selectSettingDataState,
  (settingSlice) => settingSlice.addUserLogo.status
);

export const selectAddUserLogoError = createSelector(
  selectSettingDataState,
  (settingSlice) => settingSlice.addUserLogo.error
);

export const selectJiraProductCustomStage = createSelector(
  selectSettingDataState,
  (settingSlice) => settingSlice.jiraCustomProductStages
);

export const selectJiraTaskCustomStage = createSelector(
  selectSettingDataState,
  (settingSlice) => settingSlice.jiraCustomTaskStages
);

export const selectAddedJiraProjectKeyData = createSelector(
  selectSettingDataState,
  (settingSlice) => settingSlice.addNewJiraProjectKeyToCustomer.data
);

export const selectDeletedJiraProjectKeyData = createSelector(
  selectSettingDataState,
  (settingSlice) => settingSlice.deleteAlreadyAddedJiraProjects.data
);

// jiraStatuses
export const selectJiraStatuses = createSelector(selectSettingDataState, (settingSlice) => settingSlice.jiraStatuses);
export const selectProductInfoState = createSelector(
  selectSettingDataState,
  (settingSlice) => settingSlice.productInfo
);

export const selectJiraServiceDeskAccounts = createSelector(
  selectSettingDataState,
  (settingSlice) => settingSlice.serviceDesks
);

export const selectJiraServiceDeskRequestLists = createSelector(
  selectSettingDataState,
  (settingSlice) => settingSlice.jiraServiceRequestList
);

export const selectJsdCustomFields = createSelector(
  selectSettingDataState,
  (settingSlice) => settingSlice.jsdCustomFields
);

export const selectAddEmailConfig = createSelector(
  selectSettingDataState,
  (settingSlice) => settingSlice.addEmailConfig
);

export const selectgetEmailConfig = createSelector(
  selectSettingDataState,
  (settingSlice) => settingSlice.getEmailConfig
);

export const selectdeleteEmailConfig = createSelector(
  selectSettingDataState,
  (settingSlice) => settingSlice.deleteEmailConfig
);

export const selectchangeEmailConfig = createSelector(
  selectSettingDataState,
  (settingSlice) => settingSlice.changeEmailConfig
);
// profile
export const selectProfileUpdateState = createSelector(
  selectSettingDataState,
  (settingSlice) => settingSlice.updatingProfile
);

// configuration
export const selectConfigurationUpdateState = createSelector(
  selectSettingDataState,
  (settingSlice) => settingSlice.updatingConfiguration
);

// preferences - updating custom label values
export const updatingCustomLabelValue = createSelector(
  selectSettingDataState,
  (settingSlice) => settingSlice.updatingCustomLabels
);

// inserting custom labels

export const selectInsertingCustomLabels = createSelector(
  selectSettingDataState,
  (settingSlice) => settingSlice.insertingCustomLabels
);

export const manipulatingCustomFields = createSelector(
  selectSettingDataState,
  (settingSlice) => settingSlice.manipulatingFields
);

export const selectRevenueReportBasis = createSelector(
  selectSettingDataState,
  (settingSlice) => settingSlice.revenueReportBasis
);
export const selectCustomerDetailsIntegration = createSelector(
  selectSettingDataState,
  (settingSlice) => settingSlice.customerIntegrationDetails
);
export const selectCustomerDetailsIntegrationMicroService = createSelector(
  selectSettingDataState,
  (settingSlice) => settingSlice.customerIntegrationMicroDetails
);
export const selectCRMIntegrationStages = createSelector(
  selectSettingDataState,
  (settingSlice) => settingSlice.crmIntegrationStages
);

export const selectSendEmailToUsersWithCustomMessage = createSelector(
  selectSettingDataState,
  (settingSlice) => settingSlice.sendEmailToUsersWithCustomMessage
);

export const selectCrmPipedriveCustomFieldsFromIntegration = createSelector(
  selectSettingDataState,
  (settingSlice) => settingSlice.crmCustomFieldsFromIntegrations
);

export const selectCrmPipedriveCustomFieldsFromCognisaasDb = createSelector(
  selectSettingDataState,
  (settingSlice) => settingSlice.crmCustomFieldsFromDb
);
