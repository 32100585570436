import styled from 'styled-components';

export const UserInfo = styled.div`
  width: fit-content;
  border-radius: 12px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 6px;
  text-align: center;
  background-color: rgb(200, 247, 202, 0.7);
  border: 1px solid #4f9f52;
  font-family: 'Noto Sans';
  font-style: normal;
  font-size: 12px;
  color: #4f9f52;
  margin-left: 5px;
  font-weight: 500;
`;
