import Card from '@mui/material/Card';

import './CognisaasCard.css';

interface props {
  children?: any;
  clientStatusColor: string;
  height?: string;
  width?: string;
  boxShadow?: string;
  customStyle?: any;
}

const CognisaasCard = (props: props) => {
  // console.log(props);

  return (
    <Card
      className={`${props.clientStatusColor == 'red' ? 'cognisaas-red-card' : ''} ${
        props.clientStatusColor == 'amber' ? 'cognisaas-amber-card' : ''
      } ${props.clientStatusColor == 'green' ? 'cognisaas-green-card' : ''} ${
        props.clientStatusColor == 'blue' ? 'cognisaas-blue-card' : ''
      } ${props.clientStatusColor == 'grey' ? 'cognisaas-grey-card' : ''}`}
      style={{
        // margin: '10px 20px 10px 10px',
        display: 'inline-block',
        background: '#FFFFFF',
        borderRadius: '4px',
        border: '1px solid #E5E5E5',
        boxShadow: props.boxShadow ? props.boxShadow : '0px 4px 26px rgba(0, 0, 0, 0.05)',
        width: typeof props.width !== 'undefined' ? props.width : '310px',
        height: typeof props.height !== 'undefined' ? props.height : '268px',
        ...props.customStyle,
      }}
      variant="outlined"
    >
      {props.children}
    </Card>
  );
};

export default CognisaasCard;
