import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Typography, Stack } from '@mui/material';
// import CognisaasDropdown from '../../../shared/CognisaasDropdown/CognisaasDropdown';
// import { ReactComponent as Tooltip_i } from '../../../../assets/svgs/tooltip_i.svg';
// import Divider from '@mui/material/Divider';
import CognisaasTooltip from '../../../shared/CognisaasTooltip/CognisaasTooltip';
// import Autocomplete from '@mui/material/Autocomplete';
// import TextField from '@mui/material/TextField';
// import CognisaasCheckBox from '../../../shared/CognisaasCheckbox/CognisaasCheckbox';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import CognisaasButton from '../../../shared/CognisaasButton/CognisaasButton';
import { useDispatch } from 'react-redux';
import { ReactComponent as EditSVG } from '../../../../assets/svgs/TableEditButton.svg';
// import CognisaasSwitchButton from 'components/shared/CognisaasSwitch/CognisaasSwitch';
import { SelectChangeEvent } from '@mui/material/Select';
import ClientContactMagicLinkConfig from './client/ClientContactMagicLinkConfig';
import {
  fetchCustomJourneyStagesForClientConfigurations,
  updateTableForFirstTime,
  updateUseCase_Configuration,
  getClientRecordColumnView,
  getCustomFieldClientRecords_slice,
  // updateClientRecordViewColumns_slice,
  fetchJourneyStagesForClientConfigurations,
  updateTable,
  getCustomerDetails,
  resetConfigurationUpdateState,
  resetUpdatedJourneyStageStatus,
} from '../../../../store/modules/Settings/slice';
import InfoIcon from '../../../shared/Icons/InfoIcon';
import {
  selectCustomJourneyStageData,
  selectJourneyStageData,
  selectCustomerDetails,
  // getCustomViewColumn_selector,
  // customerFieldClientRecords,
  selectUpdateJourneyStatusExsiting,
  selectConfigurationUpdateState,
  selectUpdateJourneyStatus,
  // selectClientListColumns,
} from '../../../../store/modules/Settings/selector';
import { useAppSelector } from '../../../../store/hooks';
import currencyData from './currency.json';
// import Draggable from './DragableItem';
import Cookies from 'universal-cookie';
import { DataGridPremium, gridClasses, useGridApiContext } from '@mui/x-data-grid-premium';
import { alpha, styled } from '@mui/material/styles';
import { requestState } from 'store/modules/types';
import SwitchBadge from '../SwitchBadge';
// import ClientFieldView from '../ClientFieldView/ClientFieldView';
import CognisaasToast from 'components/shared/CognisaasToast/CognisaasToast';
import { label_client } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import CognisaasSelect from 'components/shared/CognisaasSelect/CognisaasSelect';
import CustomClientSegments from './client/CustomClientSegments';
const ODD_OPACITY = 0.2;
const cookies = new Cookies();
const StripedDataGrid = styled(DataGridPremium)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
        },
      },
    },
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  '& .MuiFormGroup-options': {
    alignItems: 'center',
    paddingBottom: theme.spacing(1),
    '& > div': {
      minWidth: 100,
      margin: theme.spacing(2),
      marginLeft: 0,
    },
  },
}));
// const ifStringExistInObj = (str: string, obj: any) => {
//   try {
//     for (let i = 0; i < obj?.length; i++) {
//       if (obj[i].key === str) return true;
//     }
//     return false;
//   } catch (e) {
//     return false;
//   }
// };

// const ifStringExist = (str: string, obj: any) => {
//   if (str == undefined || str === 'undefined' || str === undefined || obj === undefined) return false;
//   try {
//     return obj.indexOf(str) > -1;
//   } catch (e) {
//     return false;
//   }
// };

const Client = () => {
  const dispatch = useDispatch();
  const [currency_DropdownValue, setCurrency_DropdownValue] = useState('Indian Rupee');
  const [renewalTrackingSwitch, setRenewalTrackingSwitch] = useState(false);
  const [projectFilteringSwitch, setprojectFilteringSwitch] = useState(false);
  const customer_uid = cookies.get('cogni_customer_uid') as string;
  const clientJourneyData = useAppSelector(selectJourneyStageData); // table data
  const clientCustomJourneyData = useAppSelector(selectCustomJourneyStageData); // table data
  const customerDetails = useAppSelector(selectCustomerDetails).data; // contains ticked values
  const updatedJourneyTable = useAppSelector(selectUpdateJourneyStatus);
  // const customViewColumnData = useAppSelector(selectClientListColumns).data;
  // const customFieldData = useAppSelector(customerFieldClientRecords)?.data?.data?.result; //custom data
  // const [onChangeAnyCheckBox, setOnChangeAnyCheckBox] = useState(false);
  // const [enableUpdateButton, setEnableUpdateButton] = useState(true);
  // const [cancelButtonChange, setCancelButtonChange] = useState(true);
  // const [standardData, setStandardData] = useState([{}]);
  // const [customData, setCustomData] = useState([{}]);
  const [jouneryStage, setJouneryStage] = useState([]);
  const [customJouneryStage, setCustomJouneryStage] = useState([]);
  const updateStatusForExsiting = useAppSelector(selectUpdateJourneyStatusExsiting);
  const configurationUpdateStatus = useAppSelector(selectConfigurationUpdateState);
  const [alertDetails, setAlertDetails] = useState<{
    isVisible: boolean;
    type: 'success' | 'failure';
    message: string;
  }>({ isVisible: false, type: 'success', message: '' });

  // for alerts
  useEffect(() => {
    if (configurationUpdateStatus.status === requestState.success) {
      setAlertDetails({ isVisible: true, type: 'success', message: 'Updated Successfully!' });
      dispatch(resetConfigurationUpdateState());
    }
  }, [configurationUpdateStatus]);

  // for alerts
  useEffect(() => {
    if (
      updatedJourneyTable.status === requestState.success ||
      updateStatusForExsiting.status === requestState.success
    ) {
      setAlertDetails({ isVisible: true, type: 'success', message: 'Journey Stage Updated Successfully!' });
      dispatch(resetUpdatedJourneyStageStatus());
    }
  }, [updatedJourneyTable, updateStatusForExsiting]);

  // const initialSetup = () => {
  //   const tempString = 'Sales Owner,CS Owner,Segment,Industry,Stage,Account Status,Top Issues,label_arr,Comments';

  //   // const tickedAllData = customViewColumnData?.client_list_columns?.split(',');
  //   const tickedAllData = customViewColumnData;
  //   const standardFieldStringsTemp = tempString.split(',');

  //   const customFieldStringsTemp = customFieldData?.map((obj: any) => obj.field_name);
  //   let allTickedCustom = tickedAllData?.map((obj: any) => {
  //     if (ifStringExist(obj, customFieldStringsTemp)) {
  //       return { key: obj, value: true };
  //     }
  //   });
  //   allTickedCustom = allTickedCustom?.filter((obj: any) => {
  //     return obj != undefined;
  //   });
  //   customFieldStringsTemp?.map((obj: any) => {
  //     if (!ifStringExistInObj(obj, allTickedCustom) && !ifStringExistInObj(obj, standardFieldStringsTemp)) {
  //       allTickedCustom?.push({ key: obj, value: false });
  //     }
  //   });

  //   let allTickedStandard = tickedAllData?.map((obj: any) => {
  //     if (ifStringExist(obj, standardFieldStringsTemp)) {
  //       return { key: obj, value: true };
  //     }
  //   });
  //   allTickedStandard = allTickedStandard?.filter((obj: any) => {
  //     return obj != undefined;
  //   });

  //   standardFieldStringsTemp?.map((obj: any) => {
  //     if (!ifStringExistInObj(obj, allTickedStandard)) {
  //       allTickedStandard?.push({ key: obj, value: false });
  //     }
  //   });

  //   setStandardData(allTickedStandard);
  //   setCustomData(allTickedCustom);
  // };

  // useEffect(() => {
  //   initialSetup();
  // }, [customViewColumnData, cancelButtonChange]);
  const [payloadObj, setPayloadObj] = useState({
    client_mail: '',
    client_task_ind: '',
    currency_code: '',
    customer_amber_threshold: 0,
    customer_uid: '',
    renewal_notification: '',
    status_report_client: '',
    subtask_enable: '',
    task_deletion_mail: '',
    task_dependency_date_updation: '',
    task_inprogress_indicator: '',
    task_mail: '',
    task_mail_copy: '',
    task_status_mail: '',
    task_status_client_contact_mail: '',
    task_status_account_owner_mail: '',
    task_status_manager_mail: '',
    time_track: '',
    usecase_calc_ind: '',
    task_assign_mail_dm_copy: '',
    task_deletion_delivery_manager_mail: '',
    project_progress_indicator: '',
    primary_contact_as_default_owner: '',
    delivery_manager_as_default_owner: '',
    client_contact_added_mail: '0',
    project_plan_view: '',
    usecase_plan_view: '',
    freeze_planned_date_project: true,
    freeze_planned_date_usecase: true,
  });
  useEffect(() => {
    const customUid = cookies.get('cogni_customer_uid');
    dispatch(getCustomerDetails());
    dispatch(fetchCustomJourneyStagesForClientConfigurations());
    dispatch(getCustomFieldClientRecords_slice(customer_uid ? customer_uid : ''));
    dispatch(
      getClientRecordColumnView({
        customer_uid: customUid ? customUid : '',
      })
    );
  }, []);

  useEffect(() => {
    if (clientJourneyData?.data?.length != 0 && jouneryStage?.length == 0) {
      let tempFinal: any = [];
      tempFinal = clientJourneyData?.data?.map((element: any) => {
        const tempElement: any = { ...element };
        tempElement.original_journeystage = element.stage_journerystage;
        return tempElement;
      });
      setJouneryStage(tempFinal);
    }
  }, [clientJourneyData]);

  useEffect(() => {
    const fetchCustomJourney = async () => {
      if (clientCustomJourneyData?.data?.length != 0) {
        setJouneryStage([]);
        let tempFinal: any = [];
        tempFinal = clientCustomJourneyData?.data?.map((element: any) => {
          const tempElement: any = { ...element };
          return tempElement;
        });
        setCustomJouneryStage(tempFinal);
      } else {
        dispatch(fetchJourneyStagesForClientConfigurations());
      }
    };
    fetchCustomJourney();
  }, [clientCustomJourneyData]);

  useEffect(() => {
    // console.log('det', customerDetails);
    setPayloadObj({
      client_mail: customerDetails.client_mail,
      client_task_ind: customerDetails.client_task_ind,
      currency_code: customerDetails.currency_code,
      customer_amber_threshold: 100 - customerDetails.customer_amber_threshold,
      customer_uid: customerDetails.customer_uid,
      renewal_notification: customerDetails.renewal_notification,
      status_report_client: customerDetails.status_report_client,
      subtask_enable: customerDetails.subtask_enable,
      task_deletion_mail: customerDetails.task_deletion_mail,
      task_dependency_date_updation: customerDetails.task_dependency_date_updation,
      task_inprogress_indicator: customerDetails.task_inprogress_indicator,
      task_mail: customerDetails.task_mail,
      task_mail_copy: customerDetails.task_mail_copy,
      task_status_account_owner_mail: customerDetails.task_status_accountowner_mail,
      task_status_mail: customerDetails.task_status_mail,
      task_status_client_contact_mail: customerDetails.task_status_client_contact_mail,
      task_status_manager_mail: customerDetails.task_status_manager_mail,
      time_track: customerDetails.time_track,
      usecase_calc_ind: customerDetails.usecase_calc_ind,
      task_assign_mail_dm_copy: customerDetails.task_assign_mail_dm_copy,
      task_deletion_delivery_manager_mail: customerDetails.task_deletion_delivery_manager_mail,
      project_progress_indicator: customerDetails.project_progress_indicator,
      primary_contact_as_default_owner: customerDetails.primary_contact_as_default_owner,
      delivery_manager_as_default_owner: customerDetails.delivery_manager_as_default_owner,
      client_contact_added_mail: customerDetails.client_contact_added_mail,
      project_plan_view: customerDetails.project_plan_view,
      usecase_plan_view: customerDetails.usecase_plan_view,
      freeze_planned_date_project: customerDetails.freeze_planned_date_project,
      freeze_planned_date_usecase: customerDetails.freeze_planned_date_usecase,
    });

    setRenewalTrackingSwitch(customerDetails.renewal_notification == '1' ? true : false);
    setprojectFilteringSwitch(customerDetails.status_report_client == '1' ? true : false);
    const curName = currencyData.newCurrency.find((e) => e.code == customerDetails.currency_code)?.name;
    setCurrency_DropdownValue(curName ? curName : 'Indian Rupee');
  }, [customerDetails]);

  const onChickSwitch = () => {
    let finalObj = { ...payloadObj };
    finalObj = { ...payloadObj, ['renewal_notification']: !renewalTrackingSwitch == true ? '1' : '0' };
    setRenewalTrackingSwitch(!renewalTrackingSwitch);
    dispatch(updateUseCase_Configuration(finalObj));
  };

  const onProjectFilteringSwitch = () => {
    let finalObj = { ...payloadObj };
    finalObj = { ...payloadObj, ['status_report_client']: !projectFilteringSwitch == true ? '1' : '0' };
    setprojectFilteringSwitch(!projectFilteringSwitch);
    dispatch(updateUseCase_Configuration(finalObj));
  };

  const currency_dropdownOnChange = (e: any) => {
    setCurrency_DropdownValue(e.target.value);
    const curName = currencyData.newCurrency.find((obj) => obj.name == e.target.value)?.code;
    let finalObj = { ...payloadObj };
    finalObj = { ...payloadObj, ['currency_code']: curName ? curName : 'INR' };
    dispatch(updateUseCase_Configuration(finalObj));
  };

  // const onTemplateConfigChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const currentState = e.target.checked === true ? '1' : '0';
  //   setPayloadObj({ ...payloadObj, [(e.target as HTMLInputElement).value]: currentState });
  //   dispatch(updateUseCase_Configuration({ ...payloadObj, [(e.target as HTMLInputElement).value]: currentState }));
  // };

  const SelectEditStringInputCell = (params: any) => {
    const apiRef = useGridApiContext();
    console.log(params);
    const { id, value, field } = params;
    const handleChange = (event: SelectChangeEvent<unknown>) => {
      apiRef.current.setEditCellValue({ id, field, value: event.target.value as string });
    };
    const handleClick = () => {
      console.log(params);
      updatingJouneryStageValue(params.row);
      apiRef.current.stopCellEditMode({ id, field });
    };

    return (
      <>
        <div style={{ padding: '0 4px', display: 'flex', alignItems: 'center', width: 'inherit' }}>
          <input
            value={value}
            // type="number"
            id="string"
            onChange={handleChange}
            style={{ width: '70%', height: '35px', borderRadius: '5px 0 0 5px', border: '1px solid #E5E5E5' }}
          />
          <button
            style={{
              border: '1px solid #4F9F52',
              background: '#BDF2BF',
              width: '40px',
              borderRadius: '0px 5px 5px 0px',
              height: '40px',
              marginLeft: '-1px',
              cursor: 'pointer',
            }}
            onClick={() => handleClick()}
          >
            <EditSVG />
          </button>
        </div>
      </>
    );
  };
  const columns = [
    {
      headerName: 'Standard Journey Name',
      field: 'original_journeystage',
      flex: 1,
      width: 240,
    },
    {
      headerName: 'Custom Journey Name',
      field: 'stage_journerystage',
      flex: 1,
      width: 240,
      editable: true,
      renderEditCell: (params: any) => SelectEditStringInputCell({ ...params }),
    },
  ];

  const updatingJouneryStageValue = async (params: any) => {
    if (params.stage_journerystage.trim()) {
      if (customJouneryStage) {
        const temp: any = {};
        temp.customer_uid = customer_uid;
        temp.stage_id = params.stage_id;
        temp.stage_uid = params.stage_uid;
        temp.stage_journerystage = params.stage_journerystage;
        temp.original_journeystage = params.original_journeystage;
        dispatch(updateTable(temp));
      } else {
        let sendingJouneryChangedValues = [];
        sendingJouneryChangedValues = jouneryStage?.map((element: any) => {
          const finalData: any = {};
          if (element.stage_uid == params.stage_uid) {
            finalData.customer_uid = customer_uid;
            finalData.stage_id = params.stage_id;
            finalData.stage_uid = params.stage_uid;
            finalData.stage_journerystage = params.stage_journerystage;
            finalData.original_journeystage = params.original_journeystage;
          } else {
            finalData.customer_uid = customer_uid;
            finalData.stage_id = element.stage_id;
            finalData.stage_uid = element.stage_uid;
            finalData.stage_journerystage = element.stage_journerystage;
            finalData.original_journeystage = element.original_journeystage;
          }
          return finalData;
        });
        console.log(sendingJouneryChangedValues);
        await dispatch(updateTableForFirstTime(sendingJouneryChangedValues));
        dispatch(fetchCustomJourneyStagesForClientConfigurations());
      }
    }
  };
  useEffect(() => {
    if (updateStatusForExsiting?.data?.status == requestState.success) {
      dispatch(fetchCustomJourneyStagesForClientConfigurations());
    }
  }, [updateStatusForExsiting]);

  // const [columnType_DropdownValue_left_side, setColumnType_DropdownValue_left_side] = useState('Standard Column');
  // const columnType_dropdownOnChange_left_side = (e: any) => {
  //   setEnableUpdateButton(false);
  //   setColumnType_DropdownValue_left_side(e.target.value);
  // };

  // const FieldViewLeft = () => {
  //   const [searchValues_custom, setSearchValues_custom] = useState<string[]>([]);
  //   const [searchValues_standard, setSearchValues_standard] = useState<string[]>([]);
  //   const [value, setValue] = useState<string | null>('');
  //   const [inputValue, setInputValue] = useState('');
  //   const [customDataCount, setCustomDataCount] = useState(0);
  //   const [standardDataCount, setStandardDataCount] = useState(0);

  //   useEffect(() => {
  //     let cnt = 0;
  //     customData?.map((obj: any) => {
  //       obj.value == true ? (cnt += 2) : null;
  //     });
  //     setCustomDataCount(cnt / 2);
  //   }, [customData]);

  //   useEffect(() => {
  //     let cnt = 0;
  //     standardData?.map((obj: any) => {
  //       obj.value == true ? (cnt += 2) : null;
  //     });
  //     setStandardDataCount(cnt / 2);
  //   }, [standardData]);

  //   useEffect(() => {
  //     setValue('');
  //     let searchValues_custom_temp = customData?.map((obj: any) => {
  //       return obj.key;
  //     });
  //     searchValues_custom_temp = searchValues_custom_temp?.filter(function (element) {
  //       return element !== undefined;
  //     });
  //     setSearchValues_custom(searchValues_custom_temp);

  //     let searchValues_standard_temp = standardData?.map((obj: any) => {
  //       return obj.key;
  //     });
  //     searchValues_standard_temp = searchValues_standard_temp?.filter(function (element) {
  //       return element !== undefined;
  //     });
  //     setSearchValues_standard(searchValues_standard_temp);
  //   }, [customData, standardData]);

  //   const onSelectFunc = (e: any, newInput: any) => {
  //     const itemToBeSet = columnType_DropdownValue_left_side === 'Standard Column' ? standardData : customData;
  //     const fin = itemToBeSet?.map((itm: any) => {
  //       return newInput == itm.key ? { key: itm.key, value: !itm.value } : itm;
  //     });
  //     // setColnViewCheckbox(fin);
  //     if (columnType_DropdownValue_left_side === 'Standard Column') setStandardData(fin);
  //     else setCustomData(fin);
  //   };

  //   return (
  //     <Grid>
  //       <Grid sx={{ margin: '0 0 6px 0' }}>
  //         {' '}
  //         <Typography variant={'subtitle1'}>Available Columns</Typography>
  //       </Grid>
  //       <Grid sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
  //         <Grid>
  //           <Typography variant={'h3'}>
  //             {columnType_DropdownValue_left_side == 'Standard Column' ? standardDataCount : customDataCount} Items
  //             Selected
  //           </Typography>
  //         </Grid>
  //         <Grid>
  //           <CognisaasDropdown
  //             sx={{ width: '180px', fontSize: '12px' }}
  //             name="segment"
  //             placeholder="Select"
  //             value={columnType_DropdownValue_left_side}
  //             onChange={columnType_dropdownOnChange_left_side}
  //             validvalues={['Standard Column', 'Custom Column']}
  //           />
  //         </Grid>
  //       </Grid>
  //       <Grid sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', margin: '10px 0' }}>
  //         <Autocomplete
  //           sx={{ width: '100%', height: '35px', padding: '2px' }}
  //           freeSolo
  //           size="small"
  //           value={value}
  //           onChange={onSelectFunc}
  //           inputValue={inputValue}
  //           onInputChange={(event: any, newInputValue: any) => {
  //             setEnableUpdateButton(false);
  //             setInputValue(newInputValue);
  //           }}
  //           id="free-solo-demo"
  //           options={
  //             columnType_DropdownValue_left_side == 'Standard Column'
  //               ? searchValues_standard?.map((option: any) => option)
  //               : searchValues_custom?.map((option: any) => option)
  //           }
  //           renderInput={(params) => <TextField {...params} placeholder="search" />}
  //         />
  //       </Grid>
  //       {columnType_DropdownValue_left_side === 'Standard Column' ? (
  //         <Grid
  //           sx={{ display: 'flex', flexDirection: 'column', height: '55vh', overflowY: 'scroll', marginTop: '23px' }}
  //         >
  //           {standardData?.map((e: any, idx: any) => {
  //             return (
  //               <>
  //                 {e.value === false || e.value === true ? (
  //                   <FormControlLabel
  //                     key={idx}
  //                     sx={{ marginLeft: '5px', marginTop: '7px' }}
  //                     control={
  //                       <CognisaasCheckBox
  //                         checked={e.value}
  //                         value={e.key}
  //                         onCheckBoxClick={(e: any) => {
  //                           const fin = standardData?.map((itm: any) => {
  //                             return e.target.value == itm.key ? { key: itm.key, value: !itm.value } : itm;
  //                           });
  //                           setStandardData(fin);
  //                           setOnChangeAnyCheckBox(!onChangeAnyCheckBox);
  //                           setEnableUpdateButton(false);
  //                         }}
  //                         size="small"
  //                       ></CognisaasCheckBox>
  //                     }
  //                     label={
  //                       <Typography variant={'subtitle1'} sx={{ marginLeft: '5px' }}>
  //                         {e.key}
  //                       </Typography>
  //                     }
  //                   />
  //                 ) : null}
  //               </>
  //             );
  //           })}
  //         </Grid>
  //       ) : (
  //         <Grid sx={{ display: 'flex', flexDirection: 'column', height: '55vh', overflowY: 'scroll' }}>
  //           {customData?.map((e: any, idx: any) => {
  //             return (
  //               <>
  //                 {e.value === false || e.value === true ? (
  //                   <FormControlLabel
  //                     key={idx}
  //                     sx={{ marginLeft: '5px', marginTop: '7px' }}
  //                     control={
  //                       <CognisaasCheckBox
  //                         checked={e.value}
  //                         value={e.key}
  //                         onCheckBoxClick={(obj: any) => {
  //                           const fin = customData?.map((itm: any) => {
  //                             return obj.target.value == itm.key ? { key: itm.key, value: !itm.value } : itm;
  //                           });
  //                           setCustomData(fin);
  //                           setOnChangeAnyCheckBox(!onChangeAnyCheckBox);
  //                           setEnableUpdateButton(false);
  //                         }}
  //                         size="small"
  //                       ></CognisaasCheckBox>
  //                     }
  //                     label={
  //                       <Typography variant={'subtitle1'} sx={{ marginLeft: '5px' }}>
  //                         {e.key}
  //                       </Typography>
  //                     }
  //                   />
  //                 ) : null}
  //               </>
  //             );
  //           })}
  //         </Grid>
  //       )}
  //     </Grid>
  //   );
  // };

  // const [columnType_DropdownValue_right_side, setColumnType_DropdownValue_right_side] = useState('Standard Column');

  // const columnType_dropdownOnChange_right_side = (e: any) => {
  //   setEnableUpdateButton(false);
  //   setColumnType_DropdownValue_right_side(e.target.value);
  // };

  // const [finalStandard, setFinalStandard] = useState(standardData);
  // const [finalCustom, setFinalCustom] = useState(customData);

  // const FieldViewRight = () => {
  //   const [value, setValue] = useState<string | null>('');
  //   const [inputValue, setInputValue] = useState('');
  //   const [customDataCount, setCustomDataCount] = useState(0);
  //   const [standardDataCount, setStandardDataCount] = useState(0);

  //   const onSelectFunc = (e: any, newInput: any) => {
  //     const itemToBeSet = columnType_DropdownValue_right_side === 'Standard Column' ? standardData : customData;
  //     const fin = itemToBeSet?.map((itm: any) => {
  //       return newInput == itm.key ? { key: itm.key, value: !itm.value } : itm;
  //     });
  //     if (columnType_DropdownValue_right_side === 'Standard Column') setStandardData(fin);
  //     else setCustomData(fin);
  //   };

  //   useEffect(() => {
  //     let cnt = 0;
  //     customData?.map((obj: any) => {
  //       obj.value == true ? (cnt += 2) : null;
  //     });
  //     setCustomDataCount(cnt / 2);
  //   }, [customData]);
  //   useEffect(() => {
  //     let cnt = 0;
  //     standardData?.map((obj: any) => {
  //       obj.value == true ? (cnt += 2) : null;
  //     });
  //     setStandardDataCount(cnt / 2);
  //   }, [standardData]);
  //   useEffect(() => {
  //     setValue('');
  //   }, []);

  //   const searchValues = customViewColumnData?.client_list_columns?.split(',');

  //   const updateNewColnList = (newList: any) => {
  //     setEnableUpdateButton(false);
  //     console.log('dragging', newList);

  //     if (columnType_DropdownValue_right_side === 'Standard Column') {
  //       setFinalStandard(newList);
  //       const newListt = [{}];
  //       newList?.map((obj: any) => {
  //         if (JSON.stringify(obj) !== JSON.stringify({})) {
  //           newListt.push(obj);
  //         }
  //       });
  //       standardData?.map((obj: any) => {
  //         const ifExist = newListt.find((element: any) => {
  //           if (element === obj) {
  //             return true;
  //           }
  //           return false;
  //         });
  //         if (!ifExist) {
  //           if (JSON.stringify(obj) !== JSON.stringify({})) {
  //             newListt.push(obj);
  //           }
  //         }
  //       });
  //       setStandardData(newListt);
  //     } else {
  //       setFinalCustom(newList);
  //       const newListt = [{}];
  //       newList?.map((obj: any) => {
  //         if (JSON.stringify(obj) !== JSON.stringify({})) {
  //           newListt.push(obj);
  //         }
  //       });
  //       customData?.map((obj: any) => {
  //         const ifExist = newListt.find((element: any) => {
  //           if (element === obj) {
  //             return true;
  //           }
  //           return false;
  //         });
  //         if (!ifExist) {
  //           if (JSON.stringify(obj) !== JSON.stringify({})) {
  //             newListt.push(obj);
  //           }
  //         }
  //       });
  //       setCustomData(newListt);
  //     }
  //   };

  //   return (
  //     <Grid>
  //       <Grid sx={{ margin: '0 0 6px 0' }}>
  //         <Typography variant={'subtitle1'}>Display in this order</Typography>
  //       </Grid>
  //       <Grid sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
  //         <Grid>
  //           <Typography variant={'h3'}>
  //             {columnType_DropdownValue_right_side === 'Standard Column' ? standardDataCount : customDataCount} Items
  //             Selected
  //           </Typography>
  //         </Grid>
  //         <Grid>
  //           <CognisaasDropdown
  //             sx={{ width: '180px', fontSize: '12px' }}
  //             name="segment"
  //             placeholder="Select"
  //             value={columnType_DropdownValue_right_side}
  //             onChange={columnType_dropdownOnChange_right_side}
  //             validvalues={['Standard Column', 'Custom Column']}
  //           />
  //         </Grid>
  //       </Grid>
  //       <Grid sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'start' }}>
  //         <Grid
  //           sx={{
  //             display: 'flex',
  //             flexDirection: 'column',
  //             justifyContent: 'flex-start',
  //             alignItems: 'center',
  //             margin: '10px 0',
  //           }}
  //         >
  //           <Autocomplete
  //             sx={{ width: '100%', height: '35px', padding: '2px' }}
  //             freeSolo
  //             size="small"
  //             value={value}
  //             onChange={onSelectFunc}
  //             inputValue={inputValue}
  //             onInputChange={(event: any, newInputValue: any) => {
  //               setEnableUpdateButton(false);
  //               setInputValue(newInputValue);
  //             }}
  //             id="free-solo-demo"
  //             options={searchValues?.map((option: any) => option)}
  //             renderInput={(params) => <TextField {...params} placeholder="search" />}
  //           />
  //         </Grid>
  //         <Grid sx={{ height: '55vh', overflowY: 'scroll', overflowX: 'hidden' }}>
  //           <Draggable
  //             onChangeCheckbox={onChangeAnyCheckBox}
  //             itemList={columnType_DropdownValue_right_side === 'Standard Column' ? standardData : customData}
  //             setNewItemList={updateNewColnList}
  //             preList={customFieldData}
  //           />
  //         </Grid>
  //       </Grid>
  //     </Grid>
  //   );
  // };

  const JourneyTableHeader = () => {
    return (
      <Grid sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <Typography variant={'h2'} sx={{ display: 'flex' }}>
          Custom {label_client.toLowerCase()} journey stage names
          <CognisaasTooltip
            title={
              <Typography style={{ padding: '10px' }} variant={'subtitle1'}>
                You can set custom journey names for your {label_client.toLowerCase()}
              </Typography>
            }
          >
            <Stack sx={{ marginLeft: '8px' }}>
              <InfoIcon></InfoIcon>
            </Stack>
          </CognisaasTooltip>
        </Typography>
      </Grid>
    );
  };
  // const [standardStrings, setStandardStrings] = useState<string[]>([]);
  // const [customStrings, setCustomStrings] = useState<string[]>([]);

  // useEffect(() => {
  //   const trues_standard = finalStandard?.map((obj: any) => {
  //     return obj.key;
  //   });
  //   setStandardStrings(trues_standard);

  //   const true_custom = finalCustom?.map((obj: any) => {
  //     return obj.key;
  //   });
  //   setCustomStrings(true_custom);
  // }, [finalStandard, finalCustom, standardData, customData]);

  // const onClickUpdateButton = () => {
  //   const customerUid = cookies.get('cogni_customer_uid');

  //   const combined = customStrings;

  //   standardStrings?.map((obj: any) => {
  //     combined.push(obj);
  //   });

  //   let finalString = '';
  //   combined?.map((obj: any) => {
  //     finalString += obj + ',';
  //   });
  //   finalString = finalString.slice(0, -1);

  //   dispatch(
  //     updateClientRecordViewColumns_slice({
  //       customer_uid: customerUid ? customerUid : '',
  //       updatedList: finalString,
  //     })
  //   );
  //   setEnableUpdateButton(true);
  // };

  // const onClickCancelButton = () => {
  //   setCancelButtonChange(!cancelButtonChange);
  // };

  return (
    <Box sx={{ margin: '28px 20px 20px 20px' }}>
      <Stack>
        <Stack sx={{ marginBottom: '16px' }}>
          <Typography variant={'subtitle2'} className={'label-text'}>
            Currency for revenue
          </Typography>
          {/* <CognisaasDropdown
            width="310px"
            placeholder="Select"
            value={currency_DropdownValue}
            onChange={currency_dropdownOnChange}
            validvalues={currencyData.newCurrency.map((e) => {
              return e.name;
            })}
          /> */}
          <CognisaasSelect
            width="310px"
            onChange={currency_dropdownOnChange}
            name="SelectTemplate"
            value={currency_DropdownValue}
            validValues={currencyData.newCurrency.map((e) => {
              return e.name;
            })}
          />
        </Stack>
        <Stack direction={'row'} flexWrap={'wrap'} alignItems={'center'}>
          {/* <Paper
              variant="outlined"
              sx={{
                width: '200px',
                height: '60px',
                margin: '0',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Typography variant={'subtitle1'}>Renewal Tracking</Typography>
              <CognisaasSwitchButton
                value={renewalTrackingSwitch}
                checked={renewalTrackingSwitch}
                handleChange={onChickSwitch}
              />
            </Paper> */}

          <SwitchBadge
            text={'Renewal Tracking'}
            checked={renewalTrackingSwitch}
            handleChange={onChickSwitch}
            margin={'0px 16px 16px 0px'}
          />

          {/* <Paper
  variant="outlined"
  sx={{
    width: '280px',
    height: '60px',
    margin: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: '10px',
  }}
>
  <Typography variant={'subtitle1'}>Project filtering and date range selection in status report</Typography>
  <CognisaasSwitchButton
    value={projectFilteringSwitch}
    checked={projectFilteringSwitch}
    handleChange={onProjectFilteringSwitch}
  />
</Paper> */}

          <SwitchBadge
            text={'Project filtering and date range selection in status report'}
            checked={projectFilteringSwitch}
            handleChange={onProjectFilteringSwitch}
            margin={'0px 16px 16px 0px'}
          />
        </Stack>
      </Stack>
      <Stack sx={{ marginBottom: '24px' }}>
        <ClientContactMagicLinkConfig></ClientContactMagicLinkConfig>
      </Stack>

      {/* <Grid sx={{ marginTop: '35px' }}> */}
      <Grid sx={{ width: '550px' }}>
        <JourneyTableHeader />
        {clientCustomJourneyData?.data?.length >= 0
          ? customJouneryStage?.length >= 0 && (
              <StyledBox>
                <StripedDataGrid
                  columns={columns}
                  rows={clientCustomJourneyData?.data}
                  loading={false}
                  getRowId={(row) => row.stage_uid}
                  disableSelectionOnClick
                  autoHeight
                  rowThreshold={0}
                  editMode="cell"
                  hideFooterRowCount
                  hideFooter
                  experimentalFeatures={{ newEditingApi: true }}
                  getRowClassName={(params: any) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'odd' : 'odd')}
                />
              </StyledBox>
            )
          : jouneryStage?.length > 0 && (
              <StyledBox>
                <StripedDataGrid
                  columns={columns}
                  rows={jouneryStage}
                  loading={false}
                  getRowId={(row) => row.stage_uid}
                  disableSelectionOnClick
                  autoHeight
                  rowThreshold={0}
                  editMode="cell"
                  hideFooterRowCount
                  hideFooter
                  experimentalFeatures={{ newEditingApi: true }}
                  getRowClassName={(params: any) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'odd' : 'odd')}
                />
              </StyledBox>
            )}
      </Grid>
      <Stack spacing={1} sx={{ marginTop: '24px' }}>
        <Typography variant="h6">Custom {label_client.toLowerCase()} segment names</Typography>
        <CustomClientSegments />
      </Stack>
      {/* <Stack sx={{ marginTop: '24px', width: '110%' }}>
        <Typography variant={'h2'}> Import template configuration</Typography>
        <Stack
          flexWrap={'wrap'}
          direction={'row'}
          alignItems={'center'}
          sx={{ paddingLeft: '10px', marginTop: '8px' }}
          spacing={2}
        >
          <FormControlLabel
            value="primary_contact_as_default_owner"
            control={
              <CognisaasCheckBox
                checked={payloadObj.primary_contact_as_default_owner === '1' ? true : false}
                onCheckBoxClick={onTemplateConfigChange}
                size="small"
                value="primary_contact_as_default_owner"
              />
            }
            label={
              <Typography variant={'subtitle1'} sx={{ marginLeft: '5px' }}>
                Set primary contact as default owner of imported {label_task.toLowerCase()}s.
              </Typography>
            }
          />
          <FormControlLabel
            value="delivery_manager_as_default_owner"
            control={
              <CognisaasCheckBox
                checked={payloadObj.delivery_manager_as_default_owner === '1' ? true : false}
                onCheckBoxClick={onTemplateConfigChange}
                size="small"
                value="delivery_manager_as_default_owner"
              />
            }
            label={
              <Typography variant={'subtitle1'} sx={{ marginLeft: '5px' }}>
                Set delivery manager as default owner of imported {label_task.toLowerCase()}s.
              </Typography>
            }
          />
        </Stack>
      </Stack> */}
      {/* below component is for rearranging rows in client list page fully functional but not needed */}
      {/* DO NOT DELETE THIS COMMENT */}
      {/* <Grid sx={{ marginTop: '24px', width: '110%' }}>
        <Grid>
          <Typography variant={'h2'}>Configure {label_client} field view</Typography>
        </Grid>

        <ClientFieldView />
      </Grid> */}
      {/*

      {/* this is new alerts */}
      <CognisaasToast
        open={alertDetails.isVisible}
        onClose={() => setAlertDetails({ isVisible: false, type: alertDetails.type, message: '' })}
        message={alertDetails.message}
        type={alertDetails.type}
      />
    </Box>
  );
};
export default Client;
