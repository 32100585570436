import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { makeStyles } from '@mui/styles';
import InfoIcon from '@mui/icons-material/Info';
interface props {
  type: 'success' | 'failure' | 'info';
  message?: string | undefined;
  detail?: string | undefined;
  open?: boolean;
  onClose?: any;
  fromPanel?: boolean;
}

const CognisaasToast = (props: props) => {
  const vertical = 'top';
  const horizontal = 'right';
  const useStyles = makeStyles({
    successAlert: {
      backgroundColor: '#BDF2BF',
      minWidth: '270px',
      maxWidth: 'max-content',
      border: '1px solid #4F9F52',
    },
    failureAlert: {
      backgroundColor: '#F1AFAF',
      minWidth: '270px',
      maxWidth: 'max-content',
      border: '1px solid #E75B5C',
    },
    infoAlert: {
      backgroundColor: '#FFFFF',
      minWidth: '270px',
      maxWidth: 'max-content',
      border: '1px solid #4390E1',
    },
  });
  const successIcon = {
    success: <CheckCircleOutlineIcon fontSize="inherit" />,
  };
  const errorIcon = {
    error: <CancelIcon fontSize="inherit" />,
  };
  const infoIcon = {
    info: <InfoIcon fontSize="inherit" />,
  };

  const classes = useStyles();

  return (
    <div>
      <Snackbar
        style={props.fromPanel ? { top: '70px' } : {}}
        anchorOrigin={{ vertical, horizontal }}
        open={props.open}
        autoHideDuration={4000}
        onClose={props.onClose}
      >
        <Alert
          className={
            props.type == 'success'
              ? classes.successAlert
              : props.type == 'info'
              ? classes.infoAlert
              : classes.failureAlert
          }
          iconMapping={props.type == 'success' ? successIcon : props.type == 'info' ? infoIcon : errorIcon}
          severity={props.type == 'success' ? 'success' : props.type == 'info' ? 'info' : 'error'}
        >
          {props.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CognisaasToast;
