import { Box, IconButton, Typography, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Styles from './FullWidthAttachmentViewer.module.css';
import FileIcon from '../FileIcon';
import CognisaaasTooltip from '../../CognisaasTooltip/CognisaasTooltip';
import { ReactComponent as DeleteIcon } from '../../../../assets/svgs/delete.svg';
import { ReactComponent as DownloadIcon } from '../../../../assets/svgs/downloadAttach.svg';
import { SetStateAction, useEffect, useState } from 'react';
import {
  downloadAttachmentFromServer,
  deleteAttachmentUsingCustomApiUrlAndPayload,
} from '../../../../store/modules/Attachment/slice';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../store/hooks';
import { selectAttachmentDownloading } from '../../../../store/modules/Attachment/selector';
import { userPermissionSchema } from 'components/Reports/SideDrawer/types';
import DeleteModal from 'components/shared/CognisaasModal/DeleteModal';
// import { selectAttachmentDownloading, selectDeleteAttachment } from '../../../../store/modules/Attachment/selector';

interface Props {
  fileName: string;
  attachmentUid: string;
  serverGetApiUrlWithQueryString: string;
  deleteApiUrl: string;
  deleteAttachmentPayload: Record<string, any>;
  setModalIndex?: any;
  deleteCallbackFunc: () => void;
  setAttachmentInfo: React.Dispatch<SetStateAction<any>>;
  attachmentData?: any;
  userPermissions: string;
}
const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

const FullWidthAttachmentViewer = (props: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [fileDowloadableUrl, setFileDownloadableUrl] = useState('');
  const dispatch = useDispatch();
  const attachmentDownloading = useAppSelector(selectAttachmentDownloading);
  const { setModalIndex } = props;
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  // const deleteAttachmentState = useAppSelector(selectDeleteAttachment);

  useEffect(() => {
    dispatch(
      downloadAttachmentFromServer({
        serverGetApiUrlWithQueryString: props.serverGetApiUrlWithQueryString,
        attachmentUid: props.attachmentUid,
      })
    );
  }, []);

  useEffect(() => {
    if (
      attachmentDownloading.status === 'success' &&
      attachmentDownloading.data.attachmentUid === props.attachmentUid
    ) {
      setFileDownloadableUrl(attachmentDownloading.data.url);
    }
  }, [attachmentDownloading]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const implementDownload = (name: string) => {
    // href="https://devdash-attachment.s3.ap-south-1.amazonaws.com/62828db44c38e/62828e284e41c/62828e2850156/6282a2d496bd4/prabhu_revenue_map.pdf?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Security-Token=IQoJb3JpZ2luX2VjENL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiB7LeRTmbLjEU8Y74MmMWSMPT82fMP2ZFiesBt9Hi59YQIhAIK339QYtZpwiFNBKfoixNcqAxuZJY0gPiImuhp86VgZKt8ECKz%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQAxoMNDQyMjYyNDEzMDg3Igwnu%2BWjgSMIp0QvrnMqswSRhw0wvEUr9wp1%2B6hvmErO%2BMqzih%2B4UTVzH%2BNY7gc3k2%2FgeKGM7p%2BgS78iGrI%2FVrik97PFvIBgvcQbeJ4C9m7rBw6Gw5TYeMeMZi%2BKemhR5BFlPUz2cT52dqohWx3yL0FMV7GsctgcgOnotRzXUr7sbVERVixZ%2FbIqTOtWbT9sD1v0KLJ2X4ozjJBzf7XKyX8Gd9219oXqAsW6oIJOwYinTvgesuA8Wi%2FAlVXLOuZy6vcfVmsZz78%2BZAltKNx8iNg8bmT6WgGoJb1IHFX0Ku2dY1gXKjDW3R%2FpbrYdXbBx27XEnMJVEhTUWim5TZXop4pSlOSnKSnrmlAygEMoMJaIG5VAQpGfe32LBF0iu%2FDGpyEvh%2FDQFrXmAbBv6iQ10NN6rx8Mr%2BKLs3rlv9TN1%2BSGhAeCCzJYJOkKJ%2BUdSdTwCHPCYiOv66dQMmitc7y9HfOzMJ0Cv%2Bvw3z%2BQS7dQjBlulzxlVQzL6kSjv1refUKDKLwIWgzsuL4P7PgvBwhi%2Bzm0P5OdGhWjGj%2Bx36%2FwwJp11L925I80HXjCcTpdZg3f70BeQqt9ZBm4Nn22S%2BguRpX4vLSyAj4fn2sIu0BuL%2F9cJv7F5cIQoiup61RjWxfv6G%2B%2Bsl58NWMwBgapzzagTvo%2FbhrktBsROcIMpVwkWncBg9b6Y%2BKv1%2F9%2FO9jAdyRmL%2FqpTOGuzEsRtWeJmfP59o9gEaOYP4gkhvO%2BRPan1Y7neCZo7kObV9zCBxUqa81KLV0OmDDxrIqUBjqpAVTBSarolT7r8Jt9oipL2iAwCRF4n7kFb5Hve7cQPWkXOF9euS0BK8N97UG4YXz2pivqsnL68I3zh%2BfFMWgTLVVnKNh11%2BtS%2B%2FYAjUJyPJDWXNMzY9XjgDuHW%2BpG7m5g5rrsMcx9RElHVq35cdD31LQ4aEc2MW0sB7gXJXX3J6uzLj%2FycojNLRNFB4kz8IVdzKO3rBq6tvPXhZ6nCPKV7LpuSJTZLBzgtuo%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAWN6HE34P72IKT3WE%2F20220516%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220516T191750Z&X-Amz-SignedHeaders=host&X-Amz-Expires=3000&X-Amz-Signature=5fa8ea8f0fcc86a9ab2421c00dc7e800b5e05dc64904d7d9914ece877cfdb0c3"

    dispatch(
      downloadAttachmentFromServer({
        serverGetApiUrlWithQueryString: props.serverGetApiUrlWithQueryString,
        attachmentUid: props.attachmentUid,
      })
    );

    const link = document.createElement('a');
    link.download = name;
    link.href = fileDowloadableUrl;
    link.click();
  };

  const handleAttachmentDelete = () => {
    console.log(props.deleteAttachmentPayload);

    dispatch(
      deleteAttachmentUsingCustomApiUrlAndPayload({
        apiDeleteAttachmentUrl: props.deleteApiUrl,
        apiDeletePayload: props.deleteAttachmentPayload,
      })
    );

    setTimeout(() => {
      props.deleteCallbackFunc();
    }, 1000);
  };

  const showDeletemodal = () => {
    setOpenDeleteModal(true);
  };

  return (
    <Box className={Styles.NormalAttachment__fileViewer}>
      <Box className={Styles.NormalAttachment__uploadedFileDetails}>
        {/* show the uploaded file */}
        <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Box component="span" sx={{ marginRight: '16px' }}>
            {/* show file icon here */}
            <FileIcon fileType={props.fileName.split('.')[1]} />
          </Box>

          <Box component="span" className={Styles.NormalAttachment__uploadedFileDetails_text}>
            {/* show file data like attachment name and date */}
            {/* file name */}
            <CognisaaasTooltip title={<span style={{ padding: '10px' }}>{props.fileName}</span>}>
              <Typography variant="subtitle1" className={Styles.NormalAttachment__fileName}>
                {props.fileName.length > 13 ? <>{props.fileName.slice(0, 13) + '...'}</> : props.fileName}
              </Typography>
            </CognisaaasTooltip>
            <Typography variant="subtitle2" className={Styles.NormalAttachment__fileDate}>
              {(() => {
                const date = new Date();
                return `${date.getDate()} ${monthNames[date.getMonth()]} ${date.getFullYear()}`;
              })()}
            </Typography>
          </Box>
        </span>

        <span>
          <span style={{ marginRight: '40px' }}>
            {/* download attachment button  */}
            <IconButton onClick={() => implementDownload(props.fileName)}>
              <DownloadIcon />
            </IconButton>
          </span>

          {props.userPermissions?.includes(userPermissionSchema.EDIT) && (
            <Box component="span">
              {/* delete attachment button */}
              <IconButton onClick={showDeletemodal}>
                <DeleteIcon />
              </IconButton>
            </Box>
          )}
          <div style={{ position: 'absolute', top: '1px', right: '-5px' }}>
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? 'long-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              {props.userPermissions?.includes(userPermissionSchema.EDIT) && (
                <>
                  <MenuItem
                    onClick={() => {
                      setModalIndex(1);
                      props.setAttachmentInfo(props.attachmentData);
                    }}
                  >
                    <Typography variant="inherit">Rename</Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setModalIndex(2);
                      props.setAttachmentInfo(props.attachmentData);
                    }}
                  >
                    <Typography variant="inherit">Upload New Version</Typography>
                  </MenuItem>
                </>
              )}
              <MenuItem
                onClick={() => {
                  setModalIndex(3);
                  props.setAttachmentInfo(props.attachmentData);
                }}
              >
                <Typography variant="inherit">Version History</Typography>
              </MenuItem>
            </Menu>
          </div>
        </span>
      </Box>
      <DeleteModal
        isOpen={openDeleteModal}
        setIsOpen={setOpenDeleteModal}
        message={'Are you sure you want to delete this attachment'}
        onDelete={() => {
          handleAttachmentDelete();
        }}
      />
    </Box>
  );
};

export default FullWidthAttachmentViewer;
