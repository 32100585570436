import { useController as useFormController, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  selectProjectViewData,
  selectResourceViewData,
  selectTaskId,
  selectUpdateTaskDrawer,
  setUpdateTaskDrawerState,
  updateTask,
} from 'store/modules/ResourceManagement';
import { getDateInYYYYMMDD } from 'components/ResourceManagement/utils/date';
import { useEffect, useState } from 'react';
import {
  getProjectTaskTimeLog,
  getUsecaseTaskTimeLog,
  upadateProjectTaskTimeLog,
  upadateUsecaseTaskTimeLog,
} from 'store/modules/Task/slice';
import { convertMinutes, valueToMinutes } from 'components/ResourceManagement/utils/transformers';

const validationMessages = {
  required: 'Required',
};

const schema = yup.object().shape({
  taskStage: yup.string().required(validationMessages.required),
  updateTaskActualStartDate: yup.date().typeError('Must be a valid date').required(validationMessages.required),
  updateTaskPlannedStartDate: yup.date().typeError('Must be a valid date').required(validationMessages.required),
  updateTaskDueDate: yup
    .date()
    .typeError('Must be a valid date')
    .required(validationMessages.required)
    .test(
      'is-updateTaskDueDate',
      () => 'Must be after the Start dates',
      function validateDates(value) {
        const { updateTaskActualStartDate, updateTaskPlannedStartDate } = this.parent;
        return value !== undefined && updateTaskActualStartDate < value && updateTaskPlannedStartDate < value;
      }
    ),
  updateTaskDeliveryDate: yup
    .date()
    .typeError('Must be a valid date')
    .required(validationMessages.required)
    .test(
      'is-updateTaskDeliveryDate',
      () => 'Must be after the Start dates',
      function validateDates(value) {
        const { updateTaskActualStartDate, updateTaskPlannedStartDate } = this.parent;
        return value !== undefined && updateTaskActualStartDate < value && updateTaskPlannedStartDate < value;
      }
    ),
  updateTaskHoursPerDay: yup
    .number()
    .typeError('Must be a valid number')
    .required(validationMessages.required)
    .min(1, 'Must be greater than or equal to 1'),
});

export const taskStageOptions = [
  { id: '1', name: 'Not Started' },
  { id: '2', name: 'In Progress' },
  { id: '3', name: 'Blocked' },
  { id: '4', name: 'Done' },
];

export const taskPriorityOptions = [
  { id: '1', name: 'Critical' },
  { id: '2', name: 'Medium' },
  { id: '3', name: 'High' },
  { id: '4', name: 'Low' },
];

export const useUpdateTask = () => {
  const dispatch = useAppDispatch();
  const selectedProjectViewData = useAppSelector(selectProjectViewData);
  const selectedResourceViewData = useAppSelector(selectResourceViewData);
  const selectedTaskId = useAppSelector(selectTaskId);
  const isUpdateDrawerOpen = useAppSelector(selectUpdateTaskDrawer);
  const [taskData, setTaskData] = useState<any>({});

  const taskStageSelectedOption = taskStageOptions.find((item) => item.name === taskData.task_stage) ?? null;
  const onUpdateDrawerClose = () => dispatch(setUpdateTaskDrawerState(false));

  const initialValues = {
    taskName: taskData.task_name,
    taskStage: taskStageSelectedOption?.name,
    plannedStartDate: getDateInYYYYMMDD(taskData.task_planned_start_date),
    actualStartDate: getDateInYYYYMMDD(taskData.task_start_date),
    taskPriority: taskData.task_priority,
    taskDeliveryDate: getDateInYYYYMMDD(taskData.task_closed_date),
    taskDueDate: getDateInYYYYMMDD(taskData.task_due_date),
    effortEstimate: taskData.effort_estimate,
    hoursPerDay: taskData.hours_per_day,
  };

  const useFormObj = {
    defaultValues: {
      taskStage: 'Not Started',
      taskPriority: 'Critical',
      updateTaskActualStartDate: initialValues.actualStartDate,
      updateTaskPlannedStartDate: initialValues.plannedStartDate,
      updateTaskDueDate: initialValues.taskDueDate,
      updateTaskDeliveryDate: initialValues.taskDeliveryDate,
      updateTaskHoursPerDay: initialValues.hoursPerDay,
      updateTaskEstimate: initialValues.effortEstimate,
    },
    resolver: yupResolver(schema),
  };
  const hookFormProps = useForm(useFormObj);

  let taskDataObj: any = null;
  const taskId = selectedTaskId;
  if (selectedProjectViewData?.events?.length) {
    taskDataObj = selectedProjectViewData.events.find((item: any) => item.id === taskId);
  } else if (selectedResourceViewData?.events?.length) {
    taskDataObj = selectedResourceViewData.events.find((item: any) => item.id === taskId);
  }

  useEffect(() => {
    if (taskDataObj) {
      setTaskData(taskDataObj);
    }
  }, [taskDataObj]);

  useEffect(() => {
    if (taskData.project_uid && taskData.case_uid && taskData.task_uid) {
      dispatch(getUsecaseTaskTimeLog(taskData.task_uid));
    } else if (taskData.task_uid) {
      dispatch(getProjectTaskTimeLog(taskData.task_uid));
    }
  }, [taskData]);

  const updateTaskDetail = (newForm: any) => {
    const detail = {
      dependency_details: [],
      customer_company: taskData.customer_company,
      customer_uid: taskData.customer_uid,
      client_uid: taskData.client_uid,
      case_uid: taskData.case_uid,
      project_uid: taskData.project_uid,
      phase_uid: taskData.section_uid,
      task_uid: taskData.task_uid,
      task_name: taskData.task_name,
      task_owner: taskData.task_owner,
      task_description: taskData.task_description,
      task_reporter: taskData.task_reporter,
      task_percentage: '0',
      milestone_task: taskData.milestone_task,
      starting_time: '',
      user_uid: taskData.user_uid,
      client_check: taskData.client_check,
      csat_indicator: taskData.csat_indicator,
      jira_ind: 0,
      jira_project_id: '',
      jira_issue_type: '',
      jira_priority: '',
      blocked_by_dependency: '0',
      contact_uid: '',
      is_team_task: taskData.is_team_task,
      task_implementation_fee_percentage: '0',
      client_initiate: '',
      old_status: '',
      old_stage: '',
      task_sequence: '',
      template_task_uid: '',
      jira_issue_id: '',
      jira_issue_key: '',
      jira_status_name: '',
      time_remaining: '0',
      total_time_spent: '0',
      ...taskData,
      task_stage: newForm.taskStage,
      task_priority: newForm.taskPriority,
      task_start_date: getDateInYYYYMMDD(newForm.updateTaskActualStartDate),
      task_due_date: getDateInYYYYMMDD(newForm.updateTaskDueDate),
      task_planned_start_date: getDateInYYYYMMDD(newForm.updateTaskPlannedStartDate),
      task_closed_date: getDateInYYYYMMDD(newForm.updateTaskDeliveryDate),
      effort_estimate: valueToMinutes(newForm.updateTaskEstimate),
      hours_per_day: newForm.updateTaskHoursPerDay,
    };

    const updateTaskPromise = dispatch(updateTask(detail)).unwrap();
    const updateUsecaseTaskPromise = dispatch(upadateUsecaseTaskTimeLog(detail)).unwrap();
    // dispatch(getUsecaseTaskTimeLog(taskData.task_uid));
    // dispatch(updateCaseWithfeatureStatus(taskData.client_uid));
    // dispatch(fetchClientInformation(clientUid));
    // dispatch(getProjectInformationWithCaseTaskByUid(test));
    // dispatch(getProjectInformation(payload));
    const updateProjectTaskPromise = dispatch(upadateProjectTaskTimeLog(detail)).unwrap();
    // dispatch(getProjectTaskTimeLog(taskData.task_uid));
    const allPromises = [updateTaskPromise, updateUsecaseTaskPromise, updateProjectTaskPromise];
    Promise.all(allPromises)
      .then((promiseResults) => {
        // console.log({ promiseResults });
        const atleastOneResAvailable = promiseResults.some((res) => res.status);
        if (atleastOneResAvailable) {
          onUpdateDrawerClose();
          // setTaskData({});
        }
      })
      .catch(() => {
        // console.log({ promiseErrors });
      });
  };

  const onFormSubmit = async (submittedFormData: any) => {
    // console.log('OnFormsubmit', submittedFormData);
    updateTaskDetail(submittedFormData);
  };

  return {
    taskStageOptions,
    taskPriorityOptions,
    originalEstimate: convertMinutes(initialValues.effortEstimate),
    isUpdateDrawerOpen,
    ...initialValues,
    updateTaskDetail,
    onUpdateDrawerClose,
    hookFormProps: { ...hookFormProps, onFormSubmit, useFormController },
  };
};
