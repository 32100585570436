import { Drawer, Typography, Stack, Button } from '@mui/material';
import { useEffect, useState } from 'react';
// import { fetchProductAndRequirements } from '../../../../../../../store/modules/ProductRequirements/ProductDetails/slice';
import { useDispatch } from 'react-redux';
// import { useAppSelector } from '../../../../../../../store/hooks';
// import { selectPrAndReqDataState } from '../../../../../../../store/modules/ProductRequirements/ProductDetails/selector';
// import CognisaasSelect from '../../../../../../shared/CognisaasSelect/CognisaasSelect';
// import CognisaasCheckBox from '../../../../../../shared/CognisaasCheckbox/CognisaasCheckbox';
// import format from 'date-fns/format';
import { useAppSelector } from 'store/hooks';
import { selectPrAndReqDataState } from 'store/modules/ProductRequirements/ProductDetails/selector';
import { fetchProductAndRequirements } from 'store/modules/ProductRequirements/ProductDetails/slice';
import CognisaasSelect from '../CognisaasSelect/CognisaasSelect';
// import CognisaasCheckBox from '../CognisaasCheckbox/CognisaasCheckbox';
import CognisaasButton from '../CognisaasButton/CognisaasButton';
import RequirementCardWithCheckbox from './RequirementCardWithCheckbox';
import { useNavigate } from 'react-router-dom';
import { parseProductRequirementDate } from 'utils/DateHelper';

import { requestState } from 'store/modules/types';
import { getUserData_selector } from 'store/modules/Settings/selector';
import { getUserDetails_slice } from 'store/modules/Settings/slice';
import { getLoggedInUserUid } from 'utils/getUid';

// import CognisaasButton from '../../../../../../shared/CognisaasButton/CognisaasButton';
// import { updateUsecaseByUid } from '../../../../../../../store/modules/Usecase/slice';

interface Props {
  open: boolean;
  setIsOpen: any;
  mappedfeatureNames: string[];
  handleRequirementSelect: (action: 'add' | 'delete', featureDetails: Record<string, string>) => void;
  handleSave: () => void;
  handleCancel: () => void;
  addNewRequirementHandler: React.Dispatch<React.SetStateAction<boolean>>;
  selectedProduct: string;
  setSelectedProduct: React.Dispatch<React.SetStateAction<string>>;
  disableSaveCancel?: boolean;
}

const ProdReqMappingDrawer = (props: Props) => {
  const dispatch = useDispatch();
  // const [drawerState, setDrawerState] = useState<boolean>(false);
  const productAndRequirementState = useAppSelector(selectPrAndReqDataState);
  // const [selectedProduct, setSelectedProduct] = useState('');
  const [selectedRequirements, setSelectedRequirements] = useState<any[]>([]);
  const navigate = useNavigate();
  const userDetailState = useAppSelector(getUserData_selector);

  // const [products, setProducts] = useState<any>([]);
  // const [addProduct, setAddProduct] = useState<any>(null);
  // const [selectedProduct, setSelectedProduct] = useState<string>('');
  // const [checkedFeatures, setCheckedFeatures] = useState<any[]>([]);

  useEffect(() => {
    dispatch(fetchProductAndRequirements());
  }, []);

  useEffect(() => {
    if (productAndRequirementState.data.length > 0) {
      // console.log(props.selectedProduct);
      if (props.selectedProduct) {
        if (props.selectedProduct.length > 0) {
          updateSelectedProductAndItsRequiremets(props.selectedProduct);
        }
      }
    }
  }, [productAndRequirementState.data]);

  useEffect(() => {
    if (props.selectedProduct) {
      if (props.selectedProduct.length > 0) {
        updateSelectedProductAndItsRequiremets(props.selectedProduct);
      }
    }
  }, []);

  useEffect(() => {
    if (userDetailState.status === requestState.idle) {
      dispatch(getUserDetails_slice(getLoggedInUserUid()));
    }
  }, [userDetailState]);

  const updateSelectedProductAndItsRequiremets = (productName: string) => {
    const selectedProductData = productAndRequirementState.data.filter((item) => item.product_name === productName)[0];
    props.setSelectedProduct(productName);
    setSelectedRequirements(selectedProductData?.feature);
    console.log(selectedProductData);
  };

  return (
    <Drawer
      PaperProps={{
        sx: { width: '460px' },
      }}
      open={props.open}
      anchor="right"
      onClose={() => {
        props.setIsOpen(false);
        props.handleCancel();
      }}
    >
      <Stack direction="row" sx={{ margin: '20px 20px 0px 20px' }}>
        <Stack spacing={1} sx={{ marginTop: '72px' }}>
          <Typography variant="h2">Product requirement mapping</Typography>

          <Stack>
            <Typography variant="subtitle2" className="label-text">
              Select Product
            </Typography>
            <CognisaasSelect
              width={'310px'}
              onChange={(e: any) => {
                updateSelectedProductAndItsRequiremets(e.target.value);
              }}
              validValues={[...productAndRequirementState.data.map((item) => item.product_name)]}
              value={props.selectedProduct}
            />
          </Stack>
          {userDetailState.status === requestState.success &&
            userDetailState.data?.customer_jira_token?.length > 0 &&
            userDetailState.data?.customer_jira_fetch_mode === 'pull' ? (
            <></>
          ) : (
            <Typography
              onClick={() => {
                navigate('/products');
              }}
              variant={'subtitle1'}
              className={'cognisaas-pointer'}
            >
              Product not listed? Click here to add{' '}
            </Typography>
          )}
        </Stack>
      </Stack>

      <Stack sx={{ margin: '8px 20px 0px 20px' }}>
        <Stack direction={'row'} justifyContent={'space-between'}>
          {props?.selectedProduct?.length > 0 && (
            <Button
              onClick={() => {
                props.addNewRequirementHandler(true);
              }}
              color={'primary'}
            >
              Add new requirement
            </Button>
          )}
          {selectedRequirements?.length > 0 && (
            <Button
              onClick={() => {
                props.setSelectedProduct('');
                setSelectedRequirements([]);
              }}
              sx={{ color: '#E75B5C' }}
            >
              Deselect
            </Button>
          )}
        </Stack>

        {/* features  */}
        <Stack spacing={1} sx={{ height: '50vh', overflowY: 'scroll' }}>
          {selectedRequirements?.map((eachFeature: any, index: number) => (
            <RequirementCardWithCheckbox
              key={index}
              eta={parseProductRequirementDate(eachFeature.feature_estimateddeliverydate)}
              name={eachFeature.feature_description}
              statusColor={eachFeature.feature_readinessstate_status}
              feature_issue_type_name={eachFeature.feature_issue_type_name}
              isChecked={props.mappedfeatureNames.includes(eachFeature.feature_description)}
              clickHandler={() =>
                props.handleRequirementSelect(
                  props.mappedfeatureNames.includes(eachFeature.feature_description) ? 'delete' : 'add',
                  eachFeature
                )
              }
            />
          ))}
        </Stack>
      </Stack>
      {props.disableSaveCancel ? (
        ''
      ) : (
        <Stack>
          <Stack sx={{ marginLeft: '20px' }} spacing={2} direction={'row'}>
            <CognisaasButton clickHandler={props.handleSave} label="Save" isOutlined={false} />
            <CognisaasButton label="Cancel" isOutlined={true} onClick={props.handleCancel} />
          </Stack>
        </Stack>
      )}
    </Drawer>
  );
};

export default ProdReqMappingDrawer;
