import { IconButton, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { GridRenderEditCellParams } from '@mui/x-data-grid-premium';
import CognisaasLoader from 'components/shared/CognisaasLoader/CognisaasLoader';
import CognisaasAutocompleteSelect from 'components/shared/CognisaasSelect/CognisaasAutocompleteSelect';
import CogniTable from 'components/shared/Tables/CogniTable';
import { useState } from 'react';
import { DEFAULT_NOT_MAPPED_OPTION } from './constants';
import usePipedriveIntegration from './usePipedriveIntegration';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';

const PipedriveCustomFieldMapping = () => {
  const {
    mappedCustomfieldsDataForPipedrive,
    customFieldsOptionsForPipedrive,
    handlecCustomFieldChange,
    cannotMapStatusMessage,
    isPipedriveLoading,
  } = usePipedriveIntegration();

  const EditableDropdown = (params: GridRenderEditCellParams<any, any, any>, dropdownOptions: any[]) => {
    const [dropDownValue, setDropDownValue] = useState<{ id: string; name: string }>(() => {
      if (params.row.cognisaas_custom_field_name.length > 0) {
        return {
          id: params.row.cognisaas_custom_field_uid,
          name: params.row.cognisaas_custom_field_name,
        };
      } else {
        return {
          id: DEFAULT_NOT_MAPPED_OPTION.id,
          name: DEFAULT_NOT_MAPPED_OPTION.name,
        };
      }
    });
    const filteredOptions = dropdownOptions.filter((item: any) =>
      item.allowed_types.includes(params.row.crm_custom_field_type)
    );
    const validValues: { id: string; name: string }[] = filteredOptions.map((item: any) => ({
      id: item.field_uid,
      name: item.field_name,
    }));

    validValues.unshift({ id: '000', name: 'None' });

    return (
      <>
        <CognisaasAutocompleteSelect
          width="200px"
          value={dropDownValue}
          allOptions={validValues}
          onChange={(data: any) => {
            setDropDownValue({ id: data.id, name: data.name });
            handlecCustomFieldChange(params.row, data);
          }}
        />
      </>
    );
  };

  const columns = [
    {
      headerName: 'Pipedrive custom field name',
      field: 'crm_custom_field_name',
      width: 240,
    },
    {
      headerName: 'Cognisaas custom field name',
      field: 'cognisaas_custom_field_name',
      width: 240,
      editable: false,
      renderCell: (params: any) => EditableDropdown(params, customFieldsOptionsForPipedrive),
    },
  ];

  return (
    <Stack sx={{ marginLeft: '20px', width: '520px' }}>
      {isPipedriveLoading ? (
        <Box component={'span'} sx={{ height: '50%' }}>
          <CognisaasLoader />
        </Box>
      ) : (
        <Stack spacing={2} sx={{ overflowY: 'scroll', marginTop: '24px' }}>
          <Typography variant={'h2'}>Map the relevant custom fields from pipedrive to cognisaas</Typography>

          {cannotMapStatusMessage.length > 0 ? (
            <Stack direction={'row'} alignItems={'center'} className="label-text">
              <IconButton>
                <ReportGmailerrorredIcon className="cogni-red" />
              </IconButton>
              <Typography variant="h2">{cannotMapStatusMessage}</Typography>
            </Stack>
          ) : (
            <CogniTable
              columns={columns}
              data={mappedCustomfieldsDataForPipedrive}
              hideFooter={false}
              hideHeader={true}
              pageSize={10}
            />
          )}
        </Stack>
      )}
    </Stack>
  );
};

export default PipedriveCustomFieldMapping;
