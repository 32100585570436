// import TasksOverview from '../TasksOverview/TasksOverview';
import { Typography, Paper, Stack } from '@mui/material';
import { label_use_case } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import UsecasesCard from './UsecasesCard';

const UsecasesOverview = (props: any) => {
  return (
    <>
      <Paper sx={{ overflowY: 'scroll', height: '446px', padding: '15px', boxShadow: 'none' }}>
        <Stack sx={{ paddingLeft: '5px' }}>
          <Typography variant="h2">Ongoing {label_use_case}s</Typography>
        </Stack>
        {props.project && <UsecasesCard client={props.client} project={props.project} />}
      </Paper>
    </>
  );
};

export default UsecasesOverview;
