import { useAppDispatch } from 'store/hooks';
import { useAppSelector } from 'store/hooks';
import {
  getCustomFields,
  getAllUsers,
  getAllSegmentValues,
  fetchAllJourneyStages,
  fetchAllIndustries,
} from 'store/modules/Common/slice';
import { useState, useEffect } from 'react';
// import { generateMappedCustomFieldKey } from 'utils/dataHelpers';
import {
  availabeTimePeriodOptions,
  availableDateRangeOptions,
  generateOptionsForThisDataKey,
} from 'utils/filteringFunctions';
import {
  selectUsersOfCompany,
  selectSegmentValues,
  selectJourneyStage,
  selectAllGeneralStatus,
  selectAllTaskStages,
  selectAllIndustries,
  //   selectAllTaskPriorities,
} from 'store/modules/Common/selector';
import { getProjectCustomFieldsDetail } from 'store/modules/Project/slice';
import { getAllUsecaseCustomFields } from 'store/modules/Usecase/slice';
import { availablePriorities } from 'components/Reports/constants';
import { getTaskCustomfields } from 'store/modules/Task/slice';
import {
  label_arr,
  label_client,
  label_cs_owner,
  label_delivery_date,
  label_due_date,
  label_project,
  label_project_delivery_manager,
  label_sales_owner,
  label_task,
  label_use_case,
  label_use_case_delivery_manager,
} from 'components/Settings/Preferences/CustomLabelCookieHelper';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const customReportFilter = () => {
  const dispatch = useAppDispatch();
  const allUsers = useAppSelector(selectUsersOfCompany);
  const allSegments = useAppSelector(selectSegmentValues);
  const journeyStages = useAppSelector(selectJourneyStage);
  const allGeneralStatus = useAppSelector(selectAllGeneralStatus);
  const allTaskStages = useAppSelector(selectAllTaskStages);
  const allIndustries = useAppSelector(selectAllIndustries);
  //   const allTaskPriorities = useAppSelector(selectAllTaskPriorities);
  const [clientFilters, setClientFilters] = useState<any>([]);
  const [projectFilters, setProjectFilters] = useState<any[]>([]);
  const [usecaseFilters, setUsecaseFilters] = useState<any>([]);
  const [taskFilters, setTaskFilters] = useState<any[]>([]);

  const getClientFiltersWithCustomFields = async () => {
    const customerCustomFields = await dispatch(getCustomFields()).unwrap();
    const label_client = cookies.get('label_Client');
    console.log('client aman jain', label_client);
    // console.log('loggg-1', label_client, label_project, label_task, label_use_case);
    // refreshLabelCookies();
    // console.log('loggg-2', label_client, label_project, label_task, label_use_case);
    // useEffect(() => {

    // },[label_client, label_project, label_task, label_use_case])

    const clientDefaultFilters = [
      {
        name: label_sales_owner,
        dataKey: 'client_sales_owner',
        isSelected: false,
        equalToValue: [],
        canBeCleared: true,
        availableOptions: allUsers.map((item: any) => item.user_username),
        groupName: label_client,
      },
      {
        name: label_cs_owner,
        dataKey: 'client_account_owner',
        isSelected: false,
        equalToValue: [],
        canBeCleared: true,
        availableOptions: allUsers.map((item: any) => item.user_username),
        groupName: label_client,
      },
      {
        name: 'Segment',
        dataKey: 'segment_name',
        isSelected: false,
        equalToValue: [],
        canBeCleared: true,
        availableOptions: allSegments.data.map((item: any) => item.segment_name),
        groupName: label_client,
      },
      {
        name: 'Stage',
        dataKey: 'stage_journerystage',
        isSelected: false,
        equalToValue: [],
        canBeCleared: true,
        availableOptions: journeyStages.data.map((item: any) => item.stage_journerystage),
        groupName: label_client,
      },
      {
        name: 'Status',
        dataKey: 'client_readiness_status',
        isSelected: false,
        equalToValue: [],
        canBeCleared: true,
        availableOptions: allGeneralStatus.data,
        groupName: label_client,
      },
      {
        name: 'Industry',
        dataKey: 'industry_name',
        isSelected: false,
        equalToValue: [],
        canBeCleared: true,
        availableOptions: allIndustries.data.map((item: any) => item.industry_name),
        groupName: label_client,
      },
      {
        name: `${label_arr}$$number-range`,
        dataKey: 'client_account_worth',
        isSelected: false,
        equalToValue: [],
        canBeCleared: true,
        availableOptions: [],
        groupName: label_client,
      },

      // const clientCustomFields = getClientCustomFields()
    ];

    const cfClientListfilters: any[] = [];

    // cf filters if one or more customer custom fields available
    customerCustomFields.forEach((cf: any) => {
      if (['RAG status', 'Dropdown'].includes(cf.field_type)) {
        cfClientListfilters.push({
          name: cf.field_name,
          dataKey: cf.field_name,
          isSelected: false,
          equalToValue: [],
          canBeCleared: true,
          availableOptions: cf.valid_values.split(','),
          groupName: label_client,
        });
      } else if (cf.field_type === 'Date') {
        cfClientListfilters.push({
          name: `${cf.field_name}$$date-range-picker`,
          dataKey: cf.field_name,
          isSelected: false,
          equalToValue: [],
          canBeCleared: true,
          availableOptions: availableDateRangeOptions,
          groupName: label_client,
        });
      }
    });
    setClientFilters([...clientDefaultFilters, ...cfClientListfilters]);
    // return [...clientDefaultFilters, ...cfClientListfilters];
  };

  const defaultProjectFilters = [
    {
      name: `${label_project} ${label_project_delivery_manager}`,
      dataKey: 'project_deliverymanager',
      isSelected: false,
      equalToValue: [],
      canBeCleared: true,
      availableOptions: allUsers.map((item: any) => item.user_username),
      groupName: label_project,
    },
    {
      name: 'Status',
      dataKey: 'project_readinessstate_status',
      isSelected: false,
      equalToValue: [],
      canBeCleared: true,
      availableOptions: allGeneralStatus.data,
      groupName: label_project,
    },
    {
      name: `${label_project} ${label_delivery_date}$$date-range-picker`,
      dataKey: 'project_deliverydate',
      isSelected: false,
      equalToValue: [],
      canBeCleared: true,
      availableOptions: availableDateRangeOptions,
      groupName: label_project,
    },
    {
      name: `Project ${label_due_date}`,
      dataKey: 'project_requiredbydate',
      isSelected: false,
      equalToValue: [],
      canBeCleared: true,
      availableOptions: availabeTimePeriodOptions,
      groupName: label_project,
    },
    {
      name: `${label_project} Start Date`,
      dataKey: 'project_startdate',
      isSelected: false,
      equalToValue: [],
      canBeCleared: true,
      availableOptions: availabeTimePeriodOptions,
      groupName: label_project,
    },
    {
      name: `${label_project} ETA for Delivery$$date-range-picker`,
      dataKey: 'eta_forprojectdelivery',
      isSelected: false,
      equalToValue: [],
      canBeCleared: true,
      availableOptions: availableDateRangeOptions,
      groupName: label_project,
    },
    {
      name: `${label_project} Progress$$number-range`,
      dataKey: 'project_progress',
      isSelected: false,
      equalToValue: [],
      canBeCleared: true,
      availableOptions: [],
      groupName: label_project,
    },
  ];

  const transformFiltersForAggregatedReport = (filters: any) => {
    return filters.map((item: any) => {
      if (item.name.split('$$').length > 0) {
        if (item.name.split('$$')[1] == 'date-range-picker') {
          return {
            ...item,
            name: item.name.split('$$')[0] + '$$number-range',
          };
        } else if (item.name.split('$$')[1] == 'number-range') {
          return item;
        }
      }
      return {
        ...item,
        name: item.name + '$$number-range',
      };
    });
  };

  const implimentAggregationInReports = (topEntity: any) => {
    const tempClientFilters = [...clientFilters];
    let tempProjectFiters = [...projectFilters];
    let tempUsecaseFilters = [...usecaseFilters];
    let tempTaskFilters = [...taskFilters];

    if (topEntity === 'client') {
      tempProjectFiters = transformFiltersForAggregatedReport(tempProjectFiters);
      tempUsecaseFilters = transformFiltersForAggregatedReport(tempUsecaseFilters);
      tempTaskFilters = transformFiltersForAggregatedReport(tempTaskFilters);
    }

    if (topEntity === 'project') {
      tempUsecaseFilters = transformFiltersForAggregatedReport(tempUsecaseFilters);
      tempTaskFilters = transformFiltersForAggregatedReport(tempTaskFilters);
    }

    if (topEntity === 'usecase') {
      tempTaskFilters = transformFiltersForAggregatedReport(tempTaskFilters);
    }

    return [...tempClientFilters, ...tempProjectFiters, ...tempUsecaseFilters, ...tempTaskFilters];
  };

  const getProjectFiltersWithCustomFields = async () => {
    const projectCustomFields = await dispatch(getProjectCustomFieldsDetail()).unwrap();

    const cfProjects: any[] = [];

    projectCustomFields.forEach((cf: any) => {
      if (['RAG status', 'Dropdown'].includes(cf.field_type)) {
        cfProjects.push({
          name: cf.field_name,
          dataKey: cf.field_name,
          isSelected: false,
          equalToValue: [],
          canBeCleared: true,
          availableOptions: cf.valid_values.split(','),
          groupName: label_project,
        });
      } else if (cf.field_type === 'Date') {
        cfProjects.push({
          name: `${cf.field_name}$$date-range-picker`,
          dataKey: cf.field_name,
          isSelected: false,
          equalToValue: [],
          canBeCleared: true,
          availableOptions: availableDateRangeOptions,
          groupName: label_project,
        });
      }
    });

    setProjectFilters([...defaultProjectFilters, ...cfProjects]);

    // return [...defaultProjectFilters, ...cfProjects];
  };

  const defaultUsecaseFilters = [
    {
      name: `${label_use_case} ${label_use_case_delivery_manager}`,
      dataKey: 'case_deliverymanager',
      isSelected: false,
      equalToValue: [],
      canBeCleared: true,
      availableOptions: allUsers.map((item: any) => item.user_username),
      groupName: label_use_case,
    },
    {
      name: 'Status',
      dataKey: 'case_readinessstate_status',
      isSelected: false,
      equalToValue: [],
      canBeCleared: true,
      availableOptions: allGeneralStatus.data,
      groupName: label_use_case,
    },
    {
      name: `${label_use_case} ${label_delivery_date}$$date-range-picker`,
      dataKey: 'case_deliverydate',
      isSelected: false,
      equalToValue: [],
      canBeCleared: true,
      availableOptions: availableDateRangeOptions,
      groupName: label_use_case,
    },
    {
      name: `${label_use_case} ${label_due_date}$$date-range-picker`,
      dataKey: 'case_requiredbydate',
      isSelected: false,
      equalToValue: [],
      canBeCleared: true,
      availableOptions: availableDateRangeOptions,
      groupName: label_use_case,
    },
    {
      name: `${label_use_case} Planned Start Date$$date-range-picker`,
      dataKey: 'case_planned_startdate',
      isSelected: false,
      equalToValue: [],
      canBeCleared: true,
      availableOptions: availableDateRangeOptions,
      groupName: label_use_case,
    },
    {
      name: `${label_use_case} Start Date$$date-range-picker`,
      dataKey: 'case_startdate',
      isSelected: false,
      equalToValue: [],
      canBeCleared: true,
      availableOptions: availableDateRangeOptions,
      groupName: label_use_case,
    },
    {
      name: 'ETA for Delivery$$date-range-picker',
      dataKey: 'eta_forcasedelivery',
      isSelected: false,
      equalToValue: [],
      canBeCleared: true,
      availableOptions: availableDateRangeOptions,
      groupName: label_use_case,
    },
    {
      name: 'ETA$$date-range-picker',
      dataKey: 'case_requiredbydate',
      isSelected: false,
      equalToValue: [],
      canBeCleared: true,
      availableOptions: availableDateRangeOptions,
      groupName: label_use_case,
    },
    {
      name: `${label_use_case} Progress$$number-range`,
      dataKey: 'case_progress',
      isSelected: false,
      equalToValue: [],
      canBeCleared: true,
      availableOptions: [],
      groupName: label_use_case,
    },
  ];

  const getUsecaseFiltersWithCustomFields = async () => {
    const usecaseCustomFields = await dispatch(getAllUsecaseCustomFields()).unwrap();

    const cfUsecase: any[] = [];

    usecaseCustomFields.forEach((cf: any) => {
      if (['RAG status', 'Dropdown'].includes(cf.field_type)) {
        cfUsecase.push({
          name: cf.field_name,
          dataKey: cf.field_name,
          isSelected: false,
          equalToValue: [],
          canBeCleared: true,
          availableOptions: cf.valid_values.split(','),
          groupName: label_use_case,
        });
      } else if (cf.field_type === 'Date') {
        cfUsecase.push({
          name: `${cf.field_name}$$date-range-picker`,
          dataKey: cf.field_name,
          isSelected: false,
          equalToValue: [],
          canBeCleared: true,
          availableOptions: availableDateRangeOptions,
          groupName: label_use_case,
        });
      }
    });

    setUsecaseFilters([...defaultUsecaseFilters, ...cfUsecase]);
  };

  const defaultTaskFilters = [
    {
      name: `${label_task} Owner`,
      dataKey: 'task_owner',
      isSelected: false,
      equalToValue: [],
      canBeCleared: true,
      availableOptions: allUsers.map((item: any) => item.user_username),
      groupName: label_task,
    },
    {
      name: `${label_task} Stage`,
      dataKey: 'task_stage',
      isSelected: false,
      equalToValue: [],
      canBeCleared: true,
      availableOptions: allTaskStages.data,
      groupName: label_task,
    },
    {
      name: 'Status',
      dataKey: 'task_status',
      isSelected: false,
      equalToValue: [],
      canBeCleared: true,
      availableOptions: allGeneralStatus.data,
      groupName: label_task,
    },
    {
      name: 'Priority',
      dataKey: 'task_priority',
      isSelected: false,
      equalToValue: [],
      canBeCleared: true,
      availableOptions: availablePriorities,
      groupName: label_task,
    },
    {
      name: `${label_task} ${label_due_date}$$date-range-picker`,
      dataKey: 'task_due_date',
      isSelected: false,
      equalToValue: [],
      canBeCleared: true,
      availableOptions: availableDateRangeOptions,
      groupName: label_task,
    },
    {
      name: `${label_task} Planned Start Date$$date-range-picker`,
      dataKey: 'task_planned_start_date',
      isSelected: false,
      equalToValue: [],
      canBeCleared: true,
      availableOptions: availableDateRangeOptions,
      groupName: label_task,
    },
    {
      name: `${label_task} Start Date$$date-range-picker`,
      dataKey: 'task_start_date',
      isSelected: false,
      equalToValue: [],
      canBeCleared: true,
      availableOptions: availableDateRangeOptions,
      groupName: label_task,
    },
    {
      name: `${label_task} ${label_delivery_date}$$date-range-picker`,
      dataKey: 'task_closed_date',
      isSelected: false,
      equalToValue: [],
      canBeCleared: true,
      availableOptions: availableDateRangeOptions,
      groupName: label_task,
    },
    {
      name: 'Planned Duration$$number-range',
      dataKey: 'effort_estimate',
      isSelected: false,
      equalToValue: [],
      canBeCleared: true,
      availableOptions: [],
      groupName: label_task,
    },
    {
      name: 'Actial Duration$$number-range',
      dataKey: 'total_time_spent',
      isSelected: false,
      equalToValue: [],
      canBeCleared: true,
      availableOptions: [],
      groupName: label_task,
    },
  ];

  const getTaskFiltersWithCustomFields = async () => {
    const taskCustomfields = await dispatch(getTaskCustomfields()).unwrap();

    const cfTasks: any[] = [];

    taskCustomfields.forEach((cf: any) => {
      if (['RAG status', 'Dropdown'].includes(cf.field_type)) {
        cfTasks.push({
          name: cf.field_name,
          dataKey: cf.field_name,
          isSelected: false,
          equalToValue: [],
          canBeCleared: true,
          availableOptions: cf.valid_values.split(','),
          groupName: label_task,
        });
      } else if (cf.field_type === 'Date') {
        cfTasks.push({
          name: `${cf.field_name}$$date-range-picker`,
          dataKey: cf.field_name,
          isSelected: false,
          equalToValue: [],
          canBeCleared: true,
          availableOptions: availableDateRangeOptions,
          groupName: label_task,
        });
      }
    });
    console.log(defaultTaskFilters, cfTasks);
    setTaskFilters([...defaultTaskFilters, ...cfTasks]);
  };

  const additionalDataDependentFilters = (data: any[]) => {
    const extraFilters: any[] = [];

    if (data.length > 0 && data[0].client_name) {
      extraFilters.push({
        name: `${label_client} name`,
        dataKey: 'client_name',
        isSelected: false,
        equalToValue: [],
        canBeCleared: true,
        availableOptions: generateOptionsForThisDataKey(data, 'client_name'),
        groupName: label_client,
      });
    }

    if (data.length > 0 && data[0].project_name) {
      extraFilters.push({
        name: `${label_project} name`,
        dataKey: 'project_name',
        isSelected: false,
        equalToValue: [],
        canBeCleared: true,
        availableOptions: generateOptionsForThisDataKey(data, 'project_name'),
        groupName: label_project,
      });
    }

    if (data.length > 0 && data[0].case_name) {
      extraFilters.push({
        name: `${label_use_case} name`,
        dataKey: 'case_name',
        isSelected: false,
        equalToValue: [],
        canBeCleared: true,
        availableOptions: generateOptionsForThisDataKey(data, 'case_name'),
        groupName: label_use_case,
      });
    }

    if (data.length > 0 && data[0].task_name) {
      extraFilters.push({
        name: `${label_task} name`,
        dataKey: 'task_name',
        isSelected: false,
        equalToValue: [],
        canBeCleared: true,
        availableOptions: generateOptionsForThisDataKey(data, 'task_name'),
        groupName: label_task,
      });
    }

    return [...extraFilters];
  };

  const getAllData = async () => {
    // await dispatch(getAllUsers());
    getClientFiltersWithCustomFields();
    getProjectFiltersWithCustomFields();
    getUsecaseFiltersWithCustomFields();
    getTaskFiltersWithCustomFields();
  };

  useEffect(() => {
    if (allUsers.length > 0 && allSegments.data.length > 0 && allIndustries.data.length > 0) {
      getAllData();
    }
  }, [allUsers, allSegments, allIndustries]);

  const getUsers = async () => {
    await dispatch(getAllUsers());
    await dispatch(getAllSegmentValues());
    await dispatch(fetchAllJourneyStages());
    await dispatch(fetchAllIndustries());
  };

  useEffect(() => {
    getUsers();
  }, []);

  // useEffect(() => {
  //   getAllData();
  // }, []);

  return {
    clientFilters,
    projectFilters,
    usecaseFilters,
    taskFilters,
    additionalDataDependentFilters,
    implimentAggregationInReports,
  };
};

export default customReportFilter;
