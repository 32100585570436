import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
`;

export const Container = styled.div`
  width: 430px;
  background-color: #ffffff;
  height: 100%;
  padding: 13px;
  margin-top: 4rem;
`;

export const Title = styled.h4`
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  margin: 0;
`;

export const SubTitle = styled.p`
  font-family: 'Noto Sans';
  font-size: 14px;
  font-weight: 400;
  margin: 0;
`;

export const Small = styled.small`
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #7a7a7a;
  margin: 0;
`;

export const Text = styled.p`
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  display: flex;
  align-items: center;
  color: #000000;
  margin-bottom: 4px;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 95%;
  min-height: 30px;
  padding: 10px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin-top: 1rem;
  cursor: pointer;
`;

export const Frame = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Group = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TextField = styled.input`
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  width: 245px;
  height: 44px;
  padding: 0 10px;
`;
