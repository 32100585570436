import { Box, Grid, Stack, SxProps } from '@mui/material';
import { ResponsivePie } from '@nivo/pie';
import { ReactNode, useEffect, useState } from 'react';
import { isUndefined } from '../../../../utils/dataHelpers';
import SmallLegend from '../ChartComponents/SmallLegend';

interface DonutchartProps {
  data: any[];
  height: number;
  width: number;
  dataConfig: {
    idKey: string;
    dataKey: string;
  };
  colors: string[];
  legend: 'left' | 'right' | 'top' | 'bottom' | 'none';
  customLegendComponent?: ReactNode;
  innerRadius?: number;
  onClick?: any;
  emptyData?: boolean;
  layers?: any[];
  sx?: SxProps;
}

const DonutChart = (props: DonutchartProps) => {
  const [myData, setMyData] = useState<any[]>([]);

  useEffect(() => {
    setMyData(() => {
      let colorArr: string[] = [];
      if (isUndefined(props.colors)) {
        colorArr = ['#72A0DF', '#B0D888', '#EF996B'];
      } else {
        colorArr = props.colors as string[];
      }
      const newData = props.data.map((item: any, index: number) => {
        return {
          id: item[props.dataConfig.idKey],
          value: item[props.dataConfig.dataKey],
          color: colorArr[index % colorArr.length],
          label: item['label'] || 'label',
        };
      });
      return newData;
    });
  }, [props.data, props.dataConfig]);

  return (
    <Stack
      alignItems={'center'}
      justifyContent={'center'}
      direction={
        props.legend === 'bottom'
          ? 'column'
          : props.legend === 'right'
          ? 'row'
          : props.legend === 'top'
          ? 'column-reverse'
          : props.legend === 'left'
          ? 'row-reverse'
          : 'column'
      }
    >
      <Box sx={props?.sx ? props.sx : { height: '145px', width: '145px' }}>
        <ResponsivePie
          onMouseEnter={(data: any, event: any) => {
            event.currentTarget.style.cursor = 'pointer';
          }}
          data={myData}
          colors={{ datum: 'data.color' }}
          innerRadius={props?.innerRadius ? props.innerRadius : 0.79}
          padAngle={0}
          cornerRadius={0}
          activeOuterRadiusOffset={0}
          borderWidth={0}
          borderColor={{
            from: 'color',
            modifiers: [['darker', 0.2]],
          }}
          enableArcLinkLabels={false}
          enableArcLabels={false}
          arcLabelsSkipAngle={10}
          arcLabelsTextColor={{
            from: 'color',
            modifiers: [['darker', 2]],
          }}
          tooltip={({ datum: { id, value, color } }) => {
            return (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: 10,
                  color: '#000',
                  background: '#ffffff',
                  boxShadow: '0px 4px 26px rgba(0, 0, 0, 0.05)',
                  borderRadius: '4px',
                  fontSize: '12px',
                  fontFamily: 'Noto Sans,sans-serif',
                }}
              >
                <div style={{ width: '15px', height: '15px', backgroundColor: color, marginRight: '4px' }}></div>
                <span>
                  {id}: {value === 99999999999 ? 0 : value}
                  {props.emptyData}
                </span>
              </div>
            );
          }}
          onClick={props.onClick}
          defs={[
            {
              id: 'dots',
              type: 'patternDots',
              background: 'inherit',
              color: 'rgba(255, 255, 255, 0.3)',
              size: 4,
              padding: 1,
              stagger: true,
            },
            {
              id: 'lines',
              type: 'patternLines',
              background: 'inherit',
              color: 'rgba(255, 255, 255, 0.3)',
              rotation: -45,
              lineWidth: 6,
              spacing: 10,
            },
          ]}
          legends={[]}
          layers={props.layers ? props.layers : ['arcs', 'arcLabels', 'arcLinkLabels', 'legends']}
        />
      </Box>
      {props.legend !== 'none' ? (
        <Grid
          container
          spacing={1}
          sx={{ padding: '50px 20px 0px 20px', width: 'fit-content' }}
          justifyContent={'center'}
          alignItems={'center'}
        >
          {props.customLegendComponent ? (
            props.customLegendComponent
          ) : (
            <>
              {myData.map((item: any, index: number) => {
                return (
                  <Grid item key={index}>
                    <SmallLegend color={props.colors[index % props.colors.length]} name={item.id} />
                  </Grid>
                );
              })}
            </>
          )}
        </Grid>
      ) : (
        ''
      )}
    </Stack>
  );
};

export default DonutChart;
