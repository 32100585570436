import { Typography, Box, IconButton, Grid } from '@mui/material';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

import BasicModal from '../CognisaasModal/CognisaasModal';
import Content from './Content';
import { ReactComponent as Excel } from './../../../assets/svgs/excel.svg';
import './CognisaasTimeline.css';
import { excelExportData } from './helperFunction';
import { label_client } from 'components/Settings/Preferences/CustomLabelCookieHelper';
interface propsType {
  open: boolean;
  handleOnClose: () => void;
  timelineDetails: any[];
  type: string;
  totalTrail?: any;
  clientName?: any;
}

// This function maps the day and all the events occured on that day.
const createTimelineObject = (timelineDetails: any[]) => {
  const timelineObject = Object.create({});
  timelineDetails.forEach((timelineDetail: any) => {
    const time = timelineDetail.modified_on.split(' ');
    const day = time[0];
    if (day in timelineObject) {
      timelineObject[day].push(timelineDetail);
    } else {
      timelineObject[day] = [timelineDetail];
    }
  });
  return timelineObject;
};

const CognisaasTimeline = (props: propsType) => {
  const { open, handleOnClose, timelineDetails, type } = props;
  const timelineObject = createTimelineObject(timelineDetails);
  console.log('timeline object', timelineObject);

  const handleExcelClick = () => {
    excelExportData(props?.totalTrail, `${props?.clientName} Trail Log`, props?.clientName);
  };

  return (
    <BasicModal open={open} onClose={handleOnClose} width={900} height={600}>
      <Grid container spacing={2}>
        <Grid item xs={11}>
          <Typography variant="h1" style={{ fontWeight: 500 }}>
            History
          </Typography>
        </Grid>
        <Grid item xs={1}>
          {type === label_client ? (
            <IconButton onClick={handleExcelClick} color="primary" component="span">
              <Excel />
            </IconButton>
          ) : null}
        </Grid>
      </Grid>
      <Box style={{ marginTop: '1rem', height: '500px', overflowY: 'scroll', width: '100%' }}>
        <Timeline>
          {Object.keys(timelineObject).map((day: string) => (
            <>
              <TimelineItem key={day} style={{ marginLeft: '-840px' }}>
                <TimelineSeparator color="#3D38BA">
                  <TimelineDot style={{ background: '#3D38BA', margin: '0' }} />
                  <TimelineConnector style={{ background: '#3D38BA' }} />
                </TimelineSeparator>
                <TimelineContent style={{ padding: '0 16px' }}>
                  <Content timelineDetail={timelineObject[day]} day={day} type={type} />
                </TimelineContent>
              </TimelineItem>
            </>
          ))}
        </Timeline>
      </Box>
    </BasicModal>
  );
};

export default CognisaasTimeline;
