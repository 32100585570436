import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import CognisaasDropdown from 'components/shared/CognisaasDropdown/CognisaasDropdown';
import { useState, useEffect } from 'react';
import {
  createCondition_slice,
  getAllResources_slice,
  getGeneralFieldValue_slice,
  getResourcesFieldValue_slice,
  getResourcesField_slice,
} from '../../../store/modules/RBAC/slice';
// import {useLocation, useNavigate} from 'react-router-dom';
import {
  getAllResources_selector,
  getGeneralFieldValues_selector,
  getResourcesFieldValues_selector,
  getResourcesField_selector,
} from '../../../store/modules/RBAC/selector';
import { useAppSelector } from '../../../store/hooks';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import CognisaasSelect from '../../shared/CognisaasSelect/CognisaasSelect';

// interface helperText_payload {
//     text: string,
//     textToCheck: string
// }

const AddPermission = () => {
  const [permissionName, setPermissionName] = useState('');
  const [permissionDescription, setPermissionDescription] = useState('');
  // const [activeButton, setActiveButton] = useState(1);
  const [resourcesDropdown, setResourcesDropdown] = useState('Project');
  // const [accessDropdown, setAccessDropdown] = useState('View');
  const [resourcePriority, setResourcePriority] = useState('Project Priority');
  const [allResources, setAllResources] = useState<string[]>([]);
  const [fieldValueDropDownValue, setFieldValueDropDownValue] = useState<any>('');
  const [conditionResourceGeneralFieldValue, setConditionResourceGeneralFieldValue] = useState<any>([]);
  const [conditionResourceFieldValue, setConditionResourceFieldValue] = useState<any>([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const getAllResources_fromSelector = useAppSelector(getAllResources_selector).data;
  const getGeneralFieldValues_fromselector = useAppSelector(getGeneralFieldValues_selector).data;
  const getResourcesFieldValues_fromSelector = useAppSelector(getResourcesFieldValues_selector).data;
  // const [conditionResourceDropdown, setConditionResourceDropdown] = useState('');
  const [resourcesField, setResourcesField] = useState<any[]>([]);
  const getResourcesField_fromSelector = useAppSelector(getResourcesField_selector).data;
  const [selectedOperator, setSelectedOperator] = useState<string>('');
  const [selectedFieldType, setSelectedFieldType] = useState<string>('');

  useEffect(() => {
    dispatch(getAllResources_slice());
    dispatch(getGeneralFieldValue_slice());
  }, []);

  useEffect(() => {
    const temp = getAllResources_fromSelector?.map((obj: any) => {
      return obj.display_name;
    });
    setAllResources(temp);
    setResourcesDropdown(temp[0]);
  }, [getAllResources_fromSelector]);

  useEffect(() => {
    const resourceFieldId = getAllResources_fromSelector?.filter((obj: any) => {
      return obj.display_name == resourcesDropdown;
    });

    dispatch(getResourcesField_slice({ resource: resourceFieldId[0]?.id || '' }));
  }, [resourcesDropdown, getAllResources_fromSelector]);

  useEffect(() => {
    if (getGeneralFieldValues_fromselector) {
      setConditionResourceGeneralFieldValue(getGeneralFieldValues_fromselector);
      setFieldValueDropDownValue(getGeneralFieldValues_fromselector[0]?.display_name);
    }
  }, [getGeneralFieldValues_fromselector]);

  useEffect(() => {
    setResourcesField(getResourcesField_fromSelector);
  }, [getResourcesField_fromSelector]);

  useEffect(() => {
    if (getResourcesFieldValues_fromSelector) {
      setConditionResourceFieldValue(getResourcesFieldValues_fromSelector);
      if (getResourcesFieldValues_fromSelector[0]?.display_name) {
        setFieldValueDropDownValue(getResourcesFieldValues_fromSelector[0]?.display_name);
      }
    }
  }, [getResourcesFieldValues_fromSelector]);

  useEffect(() => {
    const tempFilter = resourcesField?.filter((obj: any) => {
      return obj.display_name == resourcePriority;
    });

    const resourceFieldId = getAllResources_fromSelector?.filter((obj: any) => {
      return obj.display_name == resourcesDropdown;
    });

    const dropDownForSelectedField = resourcesField.filter((r: any) => r.display_name == resourcePriority);
    if (dropDownForSelectedField.length > 0) {
      setSelectedFieldType(dropDownForSelectedField[0].type);
    }

    dispatch(
      getResourcesFieldValue_slice({
        resource: resourceFieldId[0]?.id || '',
        field: tempFilter[0]?.id || '',
      })
    );
  }, [resourcesDropdown, resourcePriority, getAllResources_fromSelector]);

  useEffect(() => {
    const temp = getAllResources_fromSelector?.map((obj: any) => {
      return obj.display_name;
    });
    setAllResources(temp);
  }, [getAllResources_fromSelector]);

  const onClickCreate = () => {
    const selectedResource = getAllResources_fromSelector?.filter((obj: any) => {
      return obj.display_name == resourcesDropdown;
    });

    const tempFilter = resourcesField?.filter((obj: any) => {
      return obj.display_name == resourcePriority;
    });
    console.log(tempFilter);
    const conditionalKey_temp = {
      type: 'resource',
      keyName: tempFilter[0].name || '',
    };

    const fallInWhichCategory = conditionResourceGeneralFieldValue?.filter((obj: any) => {
      return obj?.display_name == fieldValueDropDownValue;
    });

    let valueCategory;

    if (fallInWhichCategory.length > 0) {
      valueCategory = 'contextual-variable';
    } else {
      valueCategory = 'simple';
    }

    // fieldValueDropDownValue from
    // conditionResourceGeneralFieldValue to
    // conditionResourceFieldValue to
    let fieldValueTemp;
    fieldValueTemp = conditionResourceGeneralFieldValue?.filter((obj: any) => {
      return obj.display_name == fieldValueDropDownValue;
    });

    if (fieldValueTemp.length == 0) {
      fieldValueTemp = conditionResourceFieldValue?.filter((obj: any) => {
        return obj.display_name == fieldValueDropDownValue;
      });
    }

    const conditionalValue_temp = {
      type: valueCategory,
      value: fieldValueTemp[0].name || '',
    };
    const payload = {
      name: permissionName,
      description: permissionDescription,
      resourceId: selectedResource[0]?.id || '',
      operator: 'EQ',
      conditionalKey: JSON.stringify(conditionalKey_temp),
      conditionalValue: JSON.stringify(conditionalValue_temp),
    };
    console.log(payload);
    dispatch(createCondition_slice(payload));
    setTimeout(() => {
      navigate('/access-management');
    }, 2500);
  };

  const handleClickOpenAlertDialog = () => {
    setOpen(true);
  };

  const handleAlertDialogClose = () => {
    setOpen(false);
  };

  return (
    <Grid sx={{ padding: '18px 23px', marginLeft: '90px' }}>
      <Typography marginLeft="10px" variant={'h1'}>
        Create New Condition
      </Typography>
      <Grid
        sx={{
          marginTop: '18px',
          position: 'relative',
          height: '84vh',
          overflowY: 'scroll',
          padding: '30px',
          minHeight: '80vh',
          width: '70vw !important',
          backgroundColor: 'white',
          borderRadius: '5px',
        }}
      >
        <Grid sx={{ minHeight: '95px' }}>
          <Typography variant="h3" fontSize="15px" marginBottom="4px">
            Condition Name *
          </Typography>
          <TextField
            size="small"
            value={permissionName}
            onChange={(e: any) => {
              setPermissionName(e.target.value);
            }}
            placeholder="Enter Condition Name"
          />
          {/* <MyFormHelperText textToCheck={permissionName} text='Fill the Permission Name' /> */}
        </Grid>
        <Grid sx={{ minHeight: '95px' }}>
          <Typography variant="h3" fontSize="15px" marginBottom="4px">
            Condition Description
          </Typography>
          <TextField
            size="small"
            value={permissionDescription}
            onChange={(e: any) => {
              setPermissionDescription(e.target.value);
            }}
            placeholder="Enter Condition Name"
          />
          {/* <MyFormHelperText textToCheck={permissionName} text='Fill the Permission Name' /> */}
        </Grid>
        <Grid>
          <Typography variant="h2" fontSize="21px" marginTop="25px" marginBottom="10px">
            Resources
          </Typography>
          <CognisaasDropdown
            sx={{ marginTop: '10px' }}
            name="resources-dropdown"
            type="dropdown"
            validvalues={allResources}
            onChange={(e: any) => setResourcesDropdown(e.target.value)}
            value={resourcesDropdown}
            // defaultValue={renewalDuration}
          />
        </Grid>
        <Grid>
          <Typography variant="h2" fontSize="21px" marginTop="45px" marginBottom="5px">
            Key
          </Typography>
          <Grid sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography>Where {resourcesDropdown}</Typography>
            <Grid sx={{ marginLeft: '15px' }}>
              <FormControl>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={resourcePriority}
                  // label="Age"
                  onChange={(e: any) => setResourcePriority(e.target.value)}
                >
                  {resourcesField?.map((obj: any) => {
                    return (
                      <MenuItem key={obj} value={obj?.display_name}>
                        <Typography variant="h2">{obj?.display_name}</Typography>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid sx={{ marginTop: '45px' }}>
          <Typography>Operator</Typography>
          <CognisaasSelect
            width="310px"
            value={selectedOperator}
            validValues={['is', 'is_not', 'is_in', 'is_not_in', 'has', 'has_not']}
            disabledValues={selectedFieldType == 'ARR' ? ['is', 'is_not', 'is_in', 'is_not_in'] : ['has', 'has_not']}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setSelectedOperator((e.target as HTMLInputElement).value)
            }
          />
        </Grid>
        <Grid>
          <Typography variant="h2" fontSize="21px" marginTop="45px" marginBottom="10px">
            Value
          </Typography>
          <Grid sx={{ width: '150px', height: '70px' }}>
            <FormControl fullWidth>
              <Select
                size="small"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={fieldValueDropDownValue}
                // label="Age"
                onChange={(e: any) => setFieldValueDropDownValue(e.target.value)}
              >
                <Typography sx={{ color: '#3978FF', margin: '5px 8px' }} variant="h3">
                  General
                </Typography>
                {conditionResourceGeneralFieldValue?.map((obj: any) => {
                  return (
                    <MenuItem key={obj} value={obj?.display_name}>
                      <Typography variant="h2">{obj?.display_name}</Typography>
                    </MenuItem>
                  );
                })}
                <Typography sx={{ color: '#3978FF', margin: '5px 8px' }} variant="h3">
                  Auto Values
                </Typography>
                {conditionResourceFieldValue?.map((obj: any) => {
                  return (
                    <MenuItem key={obj} value={obj?.display_name}>
                      <Typography variant="h2">{obj?.display_name}</Typography>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid sx={{ marginTop: '15px', display: 'flex', justifyContent: 'flex-end' }}>
          {/* <CognisaasButton onClick={onClickCancel} isOutlined={true} label="Cancel" /> */}
          <Button onClick={handleClickOpenAlertDialog} variant="outlined">
            Cancel
          </Button>
          {/* <CognisaasButton isOutlined={false} sx={{marginLeft: '25px'}} label="Create" /> */}
          <Button onClick={onClickCreate} sx={{ marginLeft: '25px' }} variant="contained">
            Create
          </Button>
          <Dialog
            open={open}
            onClose={handleAlertDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              <Typography variant="h1">Alert!</Typography>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Typography variant="h2">
                  You will be redirected to User Management and all your data filled in the form will be lost !
                </Typography>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleAlertDialogClose}>Cancel</Button>
              <Button
                onClick={() => {
                  navigate('/access-management');
                }}
                autoFocus
              >
                Agree
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddPermission;
