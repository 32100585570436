import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { DashboardApi } from '../../../api/modules/dashboard';
import { requestState } from '../types';

interface authType {
  results: any;
  status: requestState;
  error: null | undefined | string;
  isLoading: boolean;
  clientCount: any;
  teamClientCount: any;
  useCaseCount: any;
  teamUseCaseCount: any;
  taskStatusCount: any;
  teamTaskStatusCount: any;
  projectsCount: any;
  teamProjectsCount: any;
  ClientUpgradationAlert: any;
  teamClientUpgradationAlert: any;
  ProjectUpgradationAlert: any;
  teamProjectUpgradationAlert: any;
  UsecaseUpgradationAlert: any;
  teamUsecaseUpgradationAlert: any;
  ClientDegradationAlert: any;
  teamClientDegradationAlert: any;
  ProjectDegradationAlert: any;
  teamProjectDegradationAlert: any;
  UsecaseDegradationAlert: any;
  teamUsecaseDegradationAlert: any;
  userDetials: any;
  allTeamTasks: any;
  allTeamTasksLoading: boolean;
  allReportees: any;
  allTeamClients: any;
}

const initialState: authType = {
  results: {},
  status: requestState.idle,
  error: null,
  isLoading: false,
  clientCount: {},
  teamClientCount: {},
  useCaseCount: {},
  teamUseCaseCount: {},
  taskStatusCount: {},
  teamTaskStatusCount: {},
  projectsCount: {},
  teamProjectsCount: {},
  ClientUpgradationAlert: {},
  teamClientUpgradationAlert: {},
  ProjectUpgradationAlert: {},
  teamProjectUpgradationAlert: {},
  UsecaseUpgradationAlert: {},
  teamUsecaseUpgradationAlert: {},
  ClientDegradationAlert: {},
  teamClientDegradationAlert: {},
  ProjectDegradationAlert: {},
  teamProjectDegradationAlert: {},
  UsecaseDegradationAlert: {},
  teamUsecaseDegradationAlert: {},
  userDetials: {},
  allTeamTasks: {},
  allTeamTasksLoading: false,
  allReportees: [],
  allTeamClients: [],
};

export const getCountByUserUid = createAsyncThunk('getClientCountByUid', async () => {
  const { data } = await DashboardApi.getCountByUserUid();
  return data;
});

export const getTeamCountByUserUid = createAsyncThunk('getTeamCountByUserUid', async () => {
  const { data } = await DashboardApi.getTeamCountByUserUid();
  return data;
});

export const getUseCaseCountUid = createAsyncThunk('getUseCaseCountByUid', async () => {
  const { data } = await DashboardApi.getUseCaseCountUid();
  return data;
});

export const getTeamUseCaseCountUid = createAsyncThunk('getTeamUseCaseCountUid', async () => {
  const { data } = await DashboardApi.getTeamUseCaseCountUid();
  return data;
});

export const getTaskStatusCount = createAsyncThunk('getTaskStatus', async () => {
  const { data } = await DashboardApi.getTaskStatusCount();
  return data;
});

export const getTeamTaskStatusCount = createAsyncThunk('getTeamTaskStatusCount', async () => {
  const { data } = await DashboardApi.getTeamTaskStatusCount();
  return data;
});

export const getClientUpgradationAlert = createAsyncThunk('getClientUpgradationAlert', async () => {
  const { data } = await DashboardApi.getClientUpgradationAlert();
  return data;
});

export const getTeamClientUpgradationAlert = createAsyncThunk('getTeamClientUpgradationAlert', async () => {
  const { data } = await DashboardApi.getTeamClientUpgradationAlert();
  return data;
});

export const getProjectUpgradationAlert = createAsyncThunk('getProjectUpgradationAlert', async () => {
  const { data } = await DashboardApi.getProjectUpgradationAlert();
  return data;
});

export const getTeamProjectUpgradationAlert = createAsyncThunk('getTeamProjectUpgradationAlert', async () => {
  const { data } = await DashboardApi.getTeamProjectUpgradationAlert();
  return data;
});

export const getUsecaseUpgradationAlert = createAsyncThunk('getUsecaseUpgradationAlert', async () => {
  const { data } = await DashboardApi.getUsecaseUpgradationAlert();
  return data;
});

export const getTeamUsecaseUpgradationAlert = createAsyncThunk('getTeamUsecaseUpgradationAlert', async () => {
  const { data } = await DashboardApi.getTeamUsecaseUpgradationAlert();
  return data;
});

export const getClientDegradationAlert = createAsyncThunk('getClientDegradationAlert', async () => {
  const { data } = await DashboardApi.getClientDegradationAlert();
  return data;
});

export const getTeamClientDegradationAlert = createAsyncThunk('getTeamClientDegradationAlert', async () => {
  const { data } = await DashboardApi.getTeamClientDegradationAlert();
  return data;
});

export const getUsecaseDegradationAlert = createAsyncThunk('getUsecaseDegradationAlert', async () => {
  const { data } = await DashboardApi.getUsecaseDegradationAlert();
  return data;
});

export const getTeamUsecaseDegradationAlert = createAsyncThunk('getTeamUsecaseDegradationAlert', async () => {
  const { data } = await DashboardApi.getTeamUsecaseDegradationAlert();
  return data;
});

export const getProjectDegradationAlert = createAsyncThunk('getProjectDegradationAlert', async () => {
  const { data } = await DashboardApi.getProjectDegradationAlert();
  return data;
});

export const getTeamProjectDegradationAlert = createAsyncThunk('getTeamProjectDegradationAlert', async () => {
  const { data } = await DashboardApi.getTeamProjectDegradationAlert();
  return data;
});

export const getAllReporties = createAsyncThunk('getAllReporties', async () => {
  const { data } = await DashboardApi.getAllReporties();
  return data;
});

export const getProjectStatusCountByCustomerUid = createAsyncThunk('getProjectStatusCount', async (detail: any) => {
  const { data } = await DashboardApi.getProjectStatusCountByCustomerUid(detail);
  return data;
});

export const getAllTeamTasksByUseruidForCustomer = createAsyncThunk(
  'getAllTeamTasksByUseruidForCustomer',
  async (detail: any) => {
    const { data } = await DashboardApi.getAllTeamTasksByUseruidForCustomer(detail);
    return data;
  }
);

export const getClientsbyUserTeam = createAsyncThunk('getClientsbyUserTeam', async (detail: any) => {
  const { data } = await DashboardApi.getClientsbyUserTeam(detail);
  return data;
});

export const getTeamProjectStatusCountByCustomerUid = createAsyncThunk(
  'getTeamProjectStatusCountByCustomerUid',
  async (detail: any) => {
    const { data } = await DashboardApi.getTeamProjectStatusCountByCustomerUid(detail);
    return data;
  }
);

export const getUserInfoByUid = createAsyncThunk('getUserInfoByUid', async (user_uid_dashboard: string) => {
  const { data } = await DashboardApi.getUserInfoByUid(user_uid_dashboard);
  return data;
});

const DashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getCountByUserUid.pending, (state) => {
        state.status = requestState.loading;
        state.isLoading = true;
      })
      .addCase(getCountByUserUid.fulfilled, (state: authType, { payload }) => {
        state.status = requestState.success;
        state.clientCount = payload.result;
      })
      .addCase(getCountByUserUid.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      })
      .addCase(getTeamCountByUserUid.pending, (state) => {
        state.status = requestState.loading;
        state.isLoading = true;
      })
      .addCase(getTeamCountByUserUid.fulfilled, (state: authType, { payload }) => {
        state.status = requestState.success;
        state.teamClientCount = payload.result;
      })
      .addCase(getTeamCountByUserUid.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      })
      .addCase(getUseCaseCountUid.pending, (state) => {
        state.status = requestState.loading;
        state.isLoading = true;
      })
      .addCase(getUseCaseCountUid.fulfilled, (state: authType, { payload }) => {
        state.status = requestState.success;
        state.useCaseCount = payload.result;
      })
      .addCase(getUseCaseCountUid.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      })
      .addCase(getTeamUseCaseCountUid.pending, (state) => {
        state.status = requestState.loading;
        state.isLoading = true;
      })
      .addCase(getTeamUseCaseCountUid.fulfilled, (state: authType, { payload }) => {
        state.status = requestState.success;
        state.teamUseCaseCount = payload.result;
      })
      .addCase(getTeamUseCaseCountUid.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      })
      .addCase(getTaskStatusCount.pending, (state) => {
        state.status = requestState.loading;
        state.isLoading = true;
      })
      .addCase(getTaskStatusCount.fulfilled, (state: authType, { payload }) => {
        state.status = requestState.success;
        state.taskStatusCount = payload.result;
      })
      .addCase(getTaskStatusCount.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      })
      .addCase(getTeamTaskStatusCount.pending, (state) => {
        state.status = requestState.loading;
        state.isLoading = true;
      })
      .addCase(getTeamTaskStatusCount.fulfilled, (state: authType, { payload }) => {
        state.status = requestState.success;
        state.teamTaskStatusCount = payload.result;
      })
      .addCase(getTeamTaskStatusCount.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      })
      .addCase(getProjectStatusCountByCustomerUid.pending, (state) => {
        state.status = requestState.loading;
        state.isLoading = true;
      })
      .addCase(getProjectStatusCountByCustomerUid.fulfilled, (state: authType, { payload }) => {
        // console.log(payload.result);
        state.status = requestState.success;
        state.projectsCount = payload.result;
      })
      .addCase(getProjectStatusCountByCustomerUid.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      })
      .addCase(getTeamProjectStatusCountByCustomerUid.pending, (state) => {
        state.status = requestState.loading;
        state.isLoading = true;
      })
      .addCase(getTeamProjectStatusCountByCustomerUid.fulfilled, (state: authType, { payload }) => {
        // console.log(payload.result);
        state.status = requestState.success;
        state.teamProjectsCount = payload.result;
      })
      .addCase(getTeamProjectStatusCountByCustomerUid.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      })
      .addCase(getClientUpgradationAlert.pending, (state) => {
        state.status = requestState.loading;
        state.isLoading = true;
      })
      .addCase(getClientUpgradationAlert.fulfilled, (state: authType, { payload }) => {
        // console.log(payload.result);
        state.status = requestState.success;
        state.ClientUpgradationAlert = payload.result;
      })
      .addCase(getClientUpgradationAlert.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      })
      .addCase(getTeamClientUpgradationAlert.pending, (state) => {
        state.status = requestState.loading;
        state.isLoading = true;
      })
      .addCase(getTeamClientUpgradationAlert.fulfilled, (state: authType, { payload }) => {
        state.status = requestState.success;
        state.teamClientUpgradationAlert = payload.result;
      })
      .addCase(getTeamClientUpgradationAlert.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      })
      .addCase(getProjectUpgradationAlert.pending, (state) => {
        state.status = requestState.loading;
        state.isLoading = true;
      })
      .addCase(getProjectUpgradationAlert.fulfilled, (state: authType, { payload }) => {
        state.status = requestState.success;
        state.ProjectUpgradationAlert = payload.result;
      })
      .addCase(getProjectUpgradationAlert.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      })
      .addCase(getTeamProjectUpgradationAlert.pending, (state) => {
        state.status = requestState.loading;
        state.isLoading = true;
      })
      .addCase(getTeamProjectUpgradationAlert.fulfilled, (state: authType, { payload }) => {
        state.status = requestState.success;
        state.teamProjectUpgradationAlert = payload.result;
      })
      .addCase(getTeamProjectUpgradationAlert.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      })
      .addCase(getUsecaseUpgradationAlert.pending, (state) => {
        state.status = requestState.loading;
        state.isLoading = true;
      })
      .addCase(getUsecaseUpgradationAlert.fulfilled, (state: authType, { payload }) => {
        // console.log(payload.result);
        state.status = requestState.success;
        state.UsecaseUpgradationAlert = payload.result;
      })
      .addCase(getUsecaseUpgradationAlert.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      })
      .addCase(getTeamUsecaseUpgradationAlert.pending, (state) => {
        state.status = requestState.loading;
        state.isLoading = true;
      })
      .addCase(getTeamUsecaseUpgradationAlert.fulfilled, (state: authType, { payload }) => {
        state.status = requestState.success;
        state.teamUsecaseUpgradationAlert = payload.result;
      })
      .addCase(getTeamUsecaseUpgradationAlert.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      })
      .addCase(getClientDegradationAlert.pending, (state) => {
        state.status = requestState.loading;
        state.isLoading = true;
      })
      .addCase(getClientDegradationAlert.fulfilled, (state: authType, { payload }) => {
        state.status = requestState.success;
        state.ClientDegradationAlert = payload.result;
      })
      .addCase(getClientDegradationAlert.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      })
      .addCase(getTeamClientDegradationAlert.pending, (state) => {
        state.status = requestState.loading;
        state.isLoading = true;
      })
      .addCase(getTeamClientDegradationAlert.fulfilled, (state: authType, { payload }) => {
        state.status = requestState.success;
        state.teamClientDegradationAlert = payload.result;
      })
      .addCase(getTeamClientDegradationAlert.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      })
      .addCase(getUsecaseDegradationAlert.pending, (state) => {
        state.status = requestState.loading;
        state.isLoading = true;
      })
      .addCase(getUsecaseDegradationAlert.fulfilled, (state: authType, { payload }) => {
        // console.log(payload.result);
        state.status = requestState.success;
        state.UsecaseDegradationAlert = payload.result;
      })
      .addCase(getUsecaseDegradationAlert.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      })
      .addCase(getTeamUsecaseDegradationAlert.pending, (state) => {
        state.status = requestState.loading;
        state.isLoading = true;
      })
      .addCase(getTeamUsecaseDegradationAlert.fulfilled, (state: authType, { payload }) => {
        // console.log(payload.result);
        state.status = requestState.success;
        state.teamUsecaseDegradationAlert = payload.result;
      })
      .addCase(getTeamUsecaseDegradationAlert.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      })
      .addCase(getProjectDegradationAlert.pending, (state) => {
        state.status = requestState.loading;
        state.isLoading = true;
      })
      .addCase(getProjectDegradationAlert.fulfilled, (state: authType, { payload }) => {
        // console.log(payload.result);
        state.status = requestState.success;
        state.ProjectDegradationAlert = payload.result;
      })
      .addCase(getProjectDegradationAlert.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      })
      .addCase(getTeamProjectDegradationAlert.pending, (state) => {
        state.status = requestState.loading;
        state.isLoading = true;
      })
      .addCase(getTeamProjectDegradationAlert.fulfilled, (state: authType, { payload }) => {
        // console.log(payload.result);
        state.status = requestState.success;
        state.teamProjectDegradationAlert = payload.result;
      })
      .addCase(getTeamProjectDegradationAlert.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      })
      .addCase(getUserInfoByUid.pending, (state) => {
        state.status = requestState.loading;
        state.isLoading = true;
      })
      .addCase(getUserInfoByUid.fulfilled, (state: authType, { payload }) => {
        // console.log(payload.result);
        state.status = requestState.success;
        state.userDetials = payload.result;
      })
      .addCase(getUserInfoByUid.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      })
      .addCase(getAllTeamTasksByUseruidForCustomer.pending, (state) => {
        state.status = requestState.loading;
        state.allTeamTasksLoading = true;
        state.isLoading = true;
      })
      .addCase(getAllTeamTasksByUseruidForCustomer.fulfilled, (state: authType, { payload }) => {
        // console.log(payload.result);
        state.status = requestState.success;
        state.allTeamTasksLoading = false;
        state.allTeamTasks = payload.result;
      })
      .addCase(getAllTeamTasksByUseruidForCustomer.rejected, (state, action) => {
        state.status = requestState.failed;
        state.allTeamTasksLoading = false;
        state.error = action.error.message;
      })
      .addCase(getAllReporties.pending, (state) => {
        state.status = requestState.loading;
        state.isLoading = true;
      })
      .addCase(getAllReporties.fulfilled, (state: authType, { payload }) => {
        // console.log(payload.result);
        state.status = requestState.success;
        state.allReportees = payload.result;
      })
      .addCase(getAllReporties.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      })
      .addCase(getClientsbyUserTeam.pending, (state) => {
        state.status = requestState.loading;
        state.isLoading = true;
      })
      .addCase(getClientsbyUserTeam.fulfilled, (state: authType, { payload }) => {
        // console.log(payload.result);
        state.status = requestState.success;
        state.allTeamClients = payload.result;
      })
      .addCase(getClientsbyUserTeam.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      });
  },
});

const dashboardSlice = DashboardSlice.reducer;
export default dashboardSlice;
