import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { Component } from 'components/ResourceManagement/types/public-types';
import {
  selectProjectId,
  selectResourceManagementState,
  selectMainViewData,
  setDataForProjectView,
  selectProjectViewModal,
  setProjectViewModalState,
  selectComponentState,
} from 'store/modules/ResourceManagement';
import { getUserLetters, transformDataForProjectView } from 'components/ResourceManagement/utils/transformers';

export const useProjectView = () => {
  const mode = 'project-view';

  const dispatch = useAppDispatch();
  const selectedComponentState = useAppSelector(selectComponentState);
  const { viewMode } = selectedComponentState;
  const selectedProjectId = useAppSelector(selectProjectId);
  const selectedMainViewData = useAppSelector(selectMainViewData);
  const resourceManagementState = useAppSelector(selectResourceManagementState);
  const {
    members: { data, isError, message },
  } = resourceManagementState;
  const [projectPrimeComponents, setProjectPrimeComponents] = useState<any[]>([]);
  const isProjectViewModalOpen = useAppSelector(selectProjectViewModal);
  const toggleModal = () => dispatch(setProjectViewModalState(false));

  useEffect(() => {
    if (data !== undefined && data.length) {
      const tasksByProjectId = transformDataForProjectView(data, selectedProjectId);
      setProjectPrimeComponents(tasksByProjectId);
      dispatch(
        setDataForProjectView({
          resources: tasksByProjectId,
          events: tasksByProjectId,
        })
      );
    }
  }, [data, isError, message, selectedProjectId]);

  const handleSelect = () => {
    // console.log(component.name + ' has ' + (isSelected ? 'selected' : 'unselected'));
  };

  const handleExpanderClick = (component: Component) => {
    const expandedComponents = projectPrimeComponents.map((t) => (t.id === component.id ? component : t));
    // console.log('On expander click Id:' + component.id);
    setProjectPrimeComponents(expandedComponents);
  };

  // console.log({primeComponents});

  const { resources: _resources, events } = selectedMainViewData;
  let resourceNameToDisplay = '';
  let resourceNameTile = '';
  let resourceNameOfSelectedProjectToDisplay = '';
  let resourceNameOfSelectedProject = '';

  if (
    events !== undefined &&
    events?.length &&
    _resources !== undefined &&
    _resources?.length &&
    selectedProjectId !== undefined
  ) {
    const selectedProject = events.find((projectItem: any) => projectItem.id === selectedProjectId);
    const resourceOfSelectedProject = _resources.find(
      (resourceItem: any) => selectedProject?.componentLinkedTo && resourceItem.id === selectedProject.componentLinkedTo
    );
    resourceNameToDisplay = selectedProject?.name ?? '';
    resourceNameTile = selectedProject?.name !== undefined ? getUserLetters(selectedProject.name) : 'N/A';
    resourceNameOfSelectedProjectToDisplay = resourceOfSelectedProject?.name ?? '';
    resourceNameOfSelectedProject =
      resourceOfSelectedProject?.name !== undefined ? getUserLetters(resourceOfSelectedProject.name) : 'N/A';
  }

  const { events: mainViewEventsForDateRange } = selectedMainViewData;

  return {
    viewMode,
    primeComponents: projectPrimeComponents,
    resourceNameToDisplay,
    resourceNameTile,
    resourceNameOfSelectedProjectToDisplay,
    resourceNameOfSelectedProject,
    mode,
    isProjectViewModalOpen,
    mainViewEventsForDateRange,
    toggleModal,
    handleSelect,
    handleExpanderClick,
  };
};
