// !TODO: Needs refactor bad code

import { Drawer, Typography, Stack, Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { getCustomerUid } from 'utils/getUid';
import { selectMappedClientsListToReqState } from 'store/modules/ActionableInsights/selector';
import { fetchClientInfoByMultiplefeatureUids } from '../../../store/modules/ActionableInsights/slice';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { ReactComponent as NoRecord } from '../../../assets/svgs/no_records.svg';
import { label_arr, label_client, label_use_case } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { DataGridPremium, GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium';
import { Link } from 'react-router-dom';
import { selectCustomerDetail } from 'store/modules/Common/selector';


const ProductClientMapPanel = (props: any) => {
  const dispatch = useDispatch();
  const mappedClients = useAppSelector(selectMappedClientsListToReqState);
  const [landCount, setLandCount] = useState<number>(0);
  const [expandCount, setExpandCount] = useState<number>(0);
  const customerDetail = useAppSelector(selectCustomerDetail);
  const [totalLand, setTotalLand] = useState<any>([]);
  const [totalExpansion, setTotalExpansion] = useState<any>([]);

  console.log(props);
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: `${customerDetail?.currency_code}`,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  useEffect(() => {
    if (props.featureId.length) {
      const detail = {
        customer_uid: getCustomerUid(),
        feature_uid: props.featureId,
      };
      const payload: any = [];
      payload.push(detail);
      dispatch(fetchClientInfoByMultiplefeatureUids(payload));
    }
  }, [props.featureId]);

  useEffect(() => {
    if (mappedClients.data.length > 0) {
      const land = [...mappedClients.data].filter((data: any) => data.case_needed_status == 'Land');
      setLandCount(land.length);
      setTotalLand(land);
      const expand = [...mappedClients.data].filter((data: any) => data.case_needed_status == 'Expand');
      setExpandCount(expand.length);
      setTotalExpansion(expand);
    }
  }, [mappedClients]);
  const columns: GridColDef[] = [
    {
      field: 'client_name',
      headerName: `${label_client} Name`,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <Link state={{ clientName: params.row.client_name }} to={`/client-list/client/${params.row.client_uid}`}>
          {params.value}
        </Link>
      ),
    },
    {
      field: 'case_name',
      headerName: `${label_use_case} Name`,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <Link
          state={{
            clientName: params.row.client_name,
            clientUid: params.row.client_uid,
            projectUid: params.row.project_uid,
          }}
          to={`/projects/project/${params.row.association_uid}/${params.row.project_uid}/${params.row.case_uid}`}>{params.value}

        </Link>
      ),
    },
    {
      headerName: `${label_client} Status`,
      field: 'client_readiness_status',
      // width: 185,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <Stack>
          <Stack spacing={1} direction="row">
            <span
              style={{ marginRight: '4px' }}
              className={
                params.value == 'red'
                  ? 'red_status_box_task'
                  : params.value == 'amber'
                    ? 'amber_status_box_task'
                    : params.value == 'green'
                      ? 'green_status_box_task'
                      : params.value == 'blue'
                        ? 'blue_status_box_task'
                        : params.value == 'grey'
                          ? 'grey_status_box_task'
                          : ''
              }
            ></span>
            {params.value == 'red'
              ? 'Overdue'
              : params.value == 'amber'
                ? 'At Risk'
                : params.value == 'green'
                  ? 'On Track'
                  : params.value == 'blue'
                    ? 'Completed'
                    : params.value == 'grey'
                      ? 'Deferred'
                      : ''}
          </Stack>
        </Stack>
      ),
    },
    {
      field: 'client_account_worth',
      headerName: `${label_arr}`,
      groupable: false,
      flex: 1,
      valueFormatter: ({ value }) => {
        if (!value) {
          return value;
        }
        return currencyFormatter.format(value);
      },
    },
    {
      field: 'stage_journerystage',
      headerName: 'Stage',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <Typography variant="subtitle1">
          {params.value}
        </Typography>
      ),
    },
    {
      field: 'segment_name',
      headerName: 'Segment',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <Typography variant="subtitle1">
          {params.value}
        </Typography>
      ),
    },
  ];

  return (
    <Drawer
      anchor="right"
      //   open={true}
      open={props.drawerState}
      onClose={() => props.onClose()}
      sx={{
        '.MuiPaper-root': {
          width: '920px',
          overflowX: 'hidden',
        },
      }}
    >
      {/* For land */}
      <Stack mt={10}>
        <Typography sx={{ margin: '16px 16px' }} variant="h6">
          List of {label_client}s dependent on &apos;{props?.featureName}&apos; for &apos;Land&apos;{' '}
          {label_use_case.toLowerCase()}s
        </Typography>
        {mappedClients.data.length > 0 ? (
          <Stack mx={2}>
            {landCount > 0 ? (
              <Box style={{
                background: '#fff',
                borderRadius: '4px',
                maxHeight: '40vh',
              }}>
                <DataGridPremium
                  sx={{ borderRadius: 0 }}
                  // initialState={{
                  //   aggregation: {
                  //     model: {
                  //       client_account_worth: 'sum',
                  //     },
                  //   },
                  // }}
                  columns={columns}
                  rows={totalLand}
                  getRowId={(row) => row.case_uid}
                  hideFooterRowCount
                  // experimentalFeatures={{ aggregation: true }}
                  disableSelectionOnClick
                  autoHeight
                />
              </Box>
            ) : (
              <NoRecord style={{ width: '200px', height: '200px', marginLeft: '30%' }} />
            )}
          </Stack>
        ) : (
          <NoRecord style={{ width: '200px', height: '200px' }} />
        )}
      </Stack>

      {/* For Expand*/}
      <Stack mt={5}>
        <Typography sx={{ margin: '16px 16px' }} variant="h6">
          List of {label_client.toLowerCase()}s dependent on &apos;{props?.featureName}&apos; for &apos;Expand&apos;{' '}
          {label_use_case.toLowerCase()}s
        </Typography>
        {mappedClients.data.length > 0 ? (
          <Stack mx={2}>
            {expandCount > 0 ? (
              <Box style={{
                background: '#fff',
                borderRadius: '4px',
                maxHeight: '40vh',
              }}>
                <DataGridPremium
                  sx={{ borderRadius: 0 }}
                  // initialState={{
                  //   aggregation: {
                  //     model: {
                  //       client_account_worth: 'sum',
                  //     },
                  //   },
                  // }}
                  columns={columns}
                  rows={totalExpansion}
                  getRowId={(row) => row.case_uid}
                  hideFooterRowCount
                  // experimentalFeatures={{ aggregation: true }}
                  disableSelectionOnClick
                  autoHeight
                />
              </Box>
            ) : (
              <NoRecord style={{ width: '200px', height: '200px', marginLeft: '30%' }} />
            )}
          </Stack>
        ) : (
          <NoRecord style={{ width: '200px', height: '200px' }} />
        )}
      </Stack>
    </Drawer>
  );
};

export default ProductClientMapPanel;
