import { useState } from 'react';

// import { useNavigate } from 'react-router-dom';

import { Grid, Typography, TextField, Button } from '@mui/material';

import CognisaasButton from 'components/shared/CognisaasButton/CognisaasButton';
import { useDispatch } from 'react-redux';
import { addRole_slice, getAllRoles_slice } from 'store/modules/RBAC/slice';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import { AddRBACProps } from 'components/RBACv2/interfaces';

const AddUser = (props: AddRBACProps) => {
  const [roleName, setRoleName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [loader, setLoader] = useState<boolean>(false);
  const dispatch = useDispatch();
  // const navigate = useNavigate();

  const onClickCreate = async () => {
    setLoader(true);
    await dispatch(
      addRole_slice({
        name: roleName ? roleName : '',
        description: description,
      })
    );
    await dispatch(getAllRoles_slice());
    setLoader(false);
    props.setAddState(false);
  };

  return (
    <Grid sx={{ marginTop: '20px', marginLeft: '30px' }}>
      <Typography variant="h1">Create New Role</Typography>
      <Grid
        sx={{
          margin: '20px 0',
        }}
      >
        <Grid>
          <Typography variant="h3" fontSize="15px" marginBottom="4px">
            Role Name *
          </Typography>
          <TextField
            size="small"
            value={roleName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setRoleName(e.target.value);
            }}
            placeholder="Enter Role Name"
          />
        </Grid>
        <Grid>
          <Typography variant="h3" fontSize="15px" marginTop="20px" marginBottom="4px">
            Description *
          </Typography>
          <TextField
            size="small"
            value={description}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setDescription(e.target.value);
            }}
            placeholder="Enter Description"
          />
        </Grid>
        <Grid sx={{ marginTop: '30px', display: 'flex' }}>
          <CognisaasButton
            isOutlined={true}
            label="Cancel"
            clickHandler={() => props.setAddState(false)}
            sx={{ marginRight: '20px' }}
          />
          {loader ? (
            <LoadingButton loading loadingPosition="start" startIcon={<SaveIcon />} variant="outlined">
              Save
            </LoadingButton>
          ) : (
            <Button onClick={() => onClickCreate()} disabled={roleName.length === 0} variant="contained">
              Save
            </Button>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddUser;
