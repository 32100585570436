//! The objects in this file has direct dependency for state logic, please be carefull with the name changes and things.

const LOGIN_USER = 'auth/login_account_user';
const REQUEST_CHANGE_PASSWORD = 'auth/request-change-password';
const SSO_LOGIN_USER = 'userSignIn';
const REGISTER_USER = 'auth/register';
const INVALIDATE_REFRESH_TOKEN = 'auth/invalidate-refresh-token';
const CHECK_EMAIL_PRESENT = 'checkEmailPresent';
const SEND_FORGOT_PASSWORD_EMAIL = 'sendForgotPasswordMail';
const USER_DETAIL = 'getUserDetailByUid';
const USER_SIGNUP = 'user-signup';
const USER_SIGNUP_GMAIL = 'userSignupWithGmail';
const USER_SIGNUP_NOTIFICATION = 'sendNewAccountCreationEmail';
const VERIFY_EMAIL = 'verifyEmailAddress';
const VALIDATE_STATUS = 'checkandValidateTheToken';
const GET_USER_INFO_BY_UID = 'getUserInfobyuid';
const CREATE_USER_PASSWORD = 'createUserPassword';
const COMPLETE_USER_ACCOUNT = 'completeUserAccount';
const GLOBAL_SEARCH = 'getGlobalSearchContentByCustomerUid';
const UPDATE_PASSWORD = 'updatePasswordByUId';
const UPDATE_CUSTOMER_TIME_TRACKING_IN_SETTINGS = 'updateCustomerTimeTrackingSettings';

// Client Routes
const CLIENT_LIST = 'getClientByCustomer';
const CRM_CLIENT_LIST = 'getCrmAccountsWithOpportunitites';
const GET_CLIENT_LIST_MEMBERS = 'getAllMembersInClientAccessList';
const ADD_USER_TO_CLIENT_ACCESS_LIST = 'addMembersInClientAccessList';
const REMOVE_USER_FROM_CLIENT_ACCESS_LIST = 'removeMembersFromClientAccessList';
const UPDATE_CLIENT = 'updateClientInformation';
const DELETE_CLIENT = 'deleteClientInformation';
const UPDATE_CLIENT_CUSTOM_FIELD = 'updateclientCustomFieldbyUid';
const SINGLE_CLIENT_INFO = 'getProjectinformationwithCaseTask';
const UPDATE_SINGLE_CLIENT_INFO = 'getaCrmAccountWithOpportunity';
const TOTAL_TRAIL_UNDER_CLIENT = 'getTrailsByClientUid';
const CLIENT_CONTACT = 'getClientContactsByClientUid';
const ADD_CLIENT_CONTACT = 'addClientContact';
const UPDATE_CLIENT_CONTACT = 'updateClientContactbyUid';
const SEND_CLIENT_CONTACT_MAIL = 'contactAddMail';
const CLIENT_RENEWAL_UPDATE = 'updateRenewal';
const CLIENT_INFORMATION = 'getClientInformation';
const CLIENT_DOMAINS = 'getDomainByclientUID';
const GET_CLIENT_UID = 'getClientUidByAssociationUid';
const ADD_CLIENT_DOMAIN = 'addDomainByclientUID';
const ALL_USERS = 'getAllUsersByCompany';
const GET_CLIENT_BY_UID = 'getClientByUid';
const UPDATE_CLIENT_SETTINGS = 'updateClientSettings';
const RENEWAL_TASKS = 'getRenewalTasksByCustomerUid';
const RENEWAL_TASKS_OF_CLIENT = 'renewalTaskDetails';
const DONE_RENEWAL_TASKS_OF_CLIENT = 'doneRenewalStage';
const TASK_RENEWAL_OF_CLIENT_OWNER = 'taskRenewalOwner';
const OPEN_PROJECTS = 'getProjectByRequireddate';
const CLOSED_PROJECTS = 'getDoneProjectsByCustomeruid';
const OPEN_USECASES = 'getUsecaseByRequireddate';
const UPDATE_USECASE = 'updateUseCaseReportFieldByUid';
const CLOSED_USECASES = 'getDoneUsecasesByCustomeruid';
const OPEN_TASKS = 'getPendingTasksByCustomeruid';
const CLOSED_TASKS = 'getDoneTasksByCustomeruid';
const ALL_RESOURCES = 'getAllUsersByCompany';
const REVENUE_PROJECT_TASKS = 'getRevenueProjectTasksByCustomer';
const REVENUE_USECASE_TASKS = 'getRevenueTasksbycustomer';
const JOURNEY_STAGE = 'getJourneyStage';
const INDUSTRY_VALUE = 'getIndustryvalue';
const SEGMENT_VALUE = 'getSegmentValue';
const COMPANY_USERS = 'getAllUsersByCompany';
const GET_CUSTOM_FIELDS = 'getPulldownCustomFieldsByCustomerUid';
const CUSTOM_FIELDS = 'getCustomFieldsByCustomerUid';
const CUSTOMER_DETAIL = 'getCustomerDetailById';
const ADD_CLIENT = 'addClientinformation';
const GET_CUSTOMFIELD_FOR_CLIENT = 'getCustomFieldsByCustomerUid';
const GET_CUSTOMFIELD_OF_CLIENT_WITH_VALUES = 'getCustomFieldsByClientUid';
const UPDATE_CLIENT_STATUS = 'updateClientStatusById';
const ADD_CLIENT_AUDIT_TRAIL = 'addClientAuditTrail';
const GET_CLIENT_AUDIT = 'getClientAuditTrail';
const GET_VERIFIED_CLIENT_CONTACTS = 'getVerifiedClientContactsByClientUid';
const UPDATED_CASE_WITH_FEATURE_STATUS = 'updatedCaseWithFeatureStatus';
const GET_PROJECT_INFORMATION_WITH_TASK_CLIENT_REPORT = 'getProjectInformationWithTaskClientReport';
const GET_PROJECT_WITH_CLIENT_UID = 'getProjecWithClientUid';
const send_Report_To_All_Client_Contacts = 'sendReportToAllClientContacts';
const SEND_DYNAMIC_REPORT_TO_CLIENT_CONTACT = 'sendDynamicReportToClientContact';
const INCREMENT_PROJECT_TASK_SEQUENCE = 'incrementProjectTaskSequences';
const INCREMENT_CASE_TASK_SEQUENCE = 'incrementCaseTaskSequences';
const INCREMENT_PROJECT_SECTION_SEQUENCE = 'incrementProjectSectionSequences';
const INCREMENT_CASE_SECTION_SEQUENCE = 'incrementCaseSectionSequences';
const UPDATE_CUSTOMER_DISPLAY_ARR_ON_CIENT_LIST = 'updateCustomerArrAggregationByCustomerUid';

const ADD_CLIENT_CONTACT_WHEN_SIGNUP = 'welcome/addClientContactwithSignup';
const DELETE_CLIENT_CONTACT = 'welcome/deleteClientContactbyUid';
const UPDATE_CLIENT_CONTACT_BY_UID = 'updateClientContactbyUid';
const SEND_ACCESS_LINK_TO_CONTACT = 'client_sendmagiclink';

// reports page
const TEAM_OPEN_TASKS = 'getPendingTeamTasksByCustomeruid';
const TEAM_CLOSED_TASKS = 'getDoneTasksByCustomeruid';
const CLIENT_OPEN_TASKS = 'getPendingClientTasksByCustomeruid';
const CLIENT_CLOSED_TASKS = 'getDoneClientTasksByCustomeruid';

// crms
const CRM_INTEGRATION_CUSTOM_FIELDS = 'getCrmCustomfields';
const CRM_CUSTOM_FIELDS_FROM_DB = 'getCrmCustomfieldsFromDB';
const UPDATE_CRM_CUSTOM_FIELD_IN_DB = 'updateCrmCustomfieldsInDB';

// Repeated with client-list .. Refactor once client-list page is refav]ctored
// product requiremet
const UPDATE_CLIENT_CUSTOMFIELD = 'updateclientCustomFieldbyUid';
const ADD_ATTACHMENT = 'addAttachments';
const GET_PRODUCT_AND_REQUIREMENT = 'getProductByCustomerWithFeature';
const ADD_NEW_PRODUCT_AND_REQUIREMENT = 'addProductWithFeature';
const ADD_NEW_REQUIREMENT = 'addFeatureWithUid';
const UPDATE_NEW_FEATURE = 'updateFeatureByFeatureUid';
const DELETE_REQIREMENT = 'deleteFeatureById';
const DELETE_PRODUCT = 'deleteProduct';
const GET_REQUIREMENT_COMMENT = 'getRequirementComments';
const GET_PRODUCT_ISSUE_ID = 'getproductissueid';
const GET_JIRA_CUSTOM_FIELDS = 'getJiraCustomField';
const CREATE_JIRA_ISSUE_FOR_REQUIREMENT = 'createJiraissue';
const UPDATE_FEATURE_ISSUE_ID = 'updateFeatureIssueId';
const ADD_FEATURE_ATTACHMENT_TO_JIRA = 'addFeatureAttachmentsToJira';
const DELETE_FEATURE_BY_DESCRIPTION = 'deletefeaturebyDescription';
const ADD_REQUIREMENT_COMMENT = 'addRequirementComment';
const UPDATE_PRODUCT_BY_PRODUCT_UID = 'updateProductByProductUid';
const DELETE_REQIREMENT_COMMENT_BY_UID = 'deleteRequirementCommentByUid';
const UPDATE_REQUIREMENT_COMMENT_BY_UID = 'updateRequirementCommentByUid';
const CREATE_JIRA_FEATURE_COMMENT = 'createJirarequirmentComment';
const GET_JIRA_REQUIREMENT_COMMENTS = 'getJiraRequirementComments';

// ACTIONABLE INSIGHT ROUTES
const GET_PROJECT_REVENUE = 'getRevenueProjectsbyCustomer';
const GET_PROJECT_LIST = 'getProjectListsbyCustomer';
const GET_RECOMMENDATIONS = 'getRecommendationsByCustomer';
const REPORT_LIST_WITH_ALL_CASE_GREEN = 'getReportListWithAllCaseGreenByCustomer';
const REPORT_LIST_WITH_ALL_CASE_NONE_GREEN = 'getReportListWithAllCaseNoneGreenByCustomer';
const GET_TOP3_LAND_USECASE_CLIENT_MAPPING = 'getLandUsecaseMapping';
const GET_TOP3_EXPAND_USECASE_CLIENT_MAPPING = 'getExpandUsecaseMapping';
const GET_TOP_LAND_PRODUCT_CLIENT_MAPPING = 'topFeaturesByCustomerWithRetention';
const GET_TOP_EXPAND_PRODUCT_CLIENT_MAPPING = 'topFeaturesByCustomerWithExpansion';
const GET_PROJECT_RECOMMENDATIONS = 'getTopDelayedProjectsByDeliveryManager';
const GET_USECASE_RECOMMENDATIONS = 'getTopDelayedUseCasesByDeliveryManager';
const GET_TASK_RECOMMENDATIONS = 'getTopDelayedUseCasesByTaskOwner';
const GET_CLIENT_INFO_BY_FEATURE_UIDS = 'getClientInformationByFeatureUid';
const GET_USECASES_WHERE_DELIVERY_MANAGER_IS_NOT_ASSIGNED = 'getUseCaseCountResourcePlanning';
const GET_PROJECT_WHERE_DELIVERY_MANAGER_IS_NOT_ASSIGNED = 'getProjectCountResourcePlanning';

// CSAT
const GET_CSAT_REVIEWS_BY_CUSTOMER_UID = 'getCsatReviewsByCustomerUid';
const GET_CSAT_BY_TASK_UID = 'getcsatbytask_uid';
const GET_CSAT_TARGET_BY_CUSTOMER_UID = 'getCsatTargetbyCustomerUid';
const UPDATE_CSAT_TARGET_BY_CUSTOMER_UID = 'updateCsatTargetByCustomerUid';
const SEND_CSAT_MAIL_TO_CLIENT_CONTACTS = 'sendCSATmailtoclientcontacts';
const SEND_CSAT_USECASE_MAIL_TO_CLIENT_CONTACTS = 'sendCSATUsecasemailtoclientcontacts';
const CLIENT_UPDATE_CSAT_RATING = 'client_updateCsatRating';
const CLIENT_BULK_UPDATE_CSAT_RATING = 'client_bulkUpdateCsatRating';
const GET_CSAT_BY_CSAT_UID = 'getCsatByCsatUid';

// phases report
const GET_PHASES_DETAILS_BY_CUSTOMER_UID = 'newGetPhasesDetailsByCustomerUid';

// Custom Report
const GET_GENERATED_CUSTOM_REPORT_USING_UID = 'getGeneratedCustomReportUsingUid';
const DELETE_CUSTOM_REPORT = 'deleteCustomReport';
const CLONE_CUSTOM_REPORT = 'cloneCustomReport';

// Aggregated Custom Report
const GET_GENERATED_AGGREGATED_CUSTOM_REPORT_USING_UID = 'getNewGeneratedAggregatedCustomReportUsingUid';
const CREATE_AGGREGATED_CUSTOM_REPORT = 'createAggregatedCustomReport';
const UPDATE_AGGREGATED_CUSTOM_REPORT = 'updateAggregatedCustomReport';
const GET_ALL_AGGREGATED_CUSTOM_REPORT = 'getAllAggregatedCustomReports';
const GET_AGGREGATED_CUSTOM_REPORT_CONFIG = 'getAggregatedCustomReportConfigByUid';
const DELETE_AGGREGATED_CUSTOM_REPORT = 'deleteAggregatedCustomReport';

// Project Reports
const getAllProjectsByCustomerUid = 'getAllProjectsByCustomerUid';
const getPhasesAndTasks = 'getAllProjectsPhasesTasksByCustomerUid';
const getTeamMembersRoute = 'getPeoplePerformanceInformation';

// PROJECT

const ALL_PROJECTS = 'getProjectsOfCustomer';
const ADD_MEMBERS_FOR_PROJECT = 'addMembersForProject';
const PROJECT_CUSTOMFIELDS = 'getProjectCustomFieldsByProjectUid';
const PROJECT_CUSTOMFIELDS_DETAIL = 'getCustomFieldProjectByCustomerUid';
const UPDATE_PROJECT_CUSTOMFIELD = 'updateProjectCustomFieldbyUid';
const GET_PROJECT_INFORMATION = 'getProjectinformationwithCaseTaskByProjectUid';
const ADD_BLANK_PROJECT = 'addBlankProject';
const ADD_PROJECT_INFORMATION = 'addProjectInformation';
const CREATE_PROJECT_FROM_PROJECT_TEMPLATE = 'createProjectFromProjectTemplate';
const CREATE_USECASE_FROM_USECASE_TEMPLATE = 'createUsecaseFromUsecaseTemplate';
const CREATE_USECASE_FROM_USECASE_TEMPLATE_IN_BULK = 'createUsecaseFromUsecaseTemplateInBulk';
const INCREMENT_TEMPLATE_PROJECT_SECTION = 'incrementTemplateProjectSectionSequences';
const INCREMENT_TEMPLATE_CASE_SECTION = 'incrementTemplateCaseSectionSequences';
const GET_PROJECT_INFORMATION_FOR_GANTT = 'getProjectinformationwithCaseTaskByuid';
const ADD_PROJECT_AUDIT_TRIAL = 'addProjectAuditTrail';
const DELETE_PROJECT = 'deleteProjectByUid';
const UPDATE_PROJECT = 'updateProjectByUid';
const GET_PROJECT_AUDIT_TRAIL = 'getProjectAuditTrail';
const DELETE_BLANK_PROJECTS = 'deleteBlankProject';
const GET_PROJECT_MEMBERS = 'getMembersOfProject';
const REMOVE_USER_FROM_PROJECT = 'removeUserFromProject';
const GET_PROJECT_BY_PROJECT_UID = 'getProjectByUid';
const GET_CUSTOM_FIELD_VALUE_FOR_ALL_PROJECTS = 'getProjectCustomFieldsByCustomerUid';
const GET_CUSTOM_FIELD_VALUE_FOR_ALL_USECASES = 'getUsecaseCustomFieldsByCustomerUid';
const ADD_PROJECT_COMMENT = 'addProjectComment';
const ALL_PROJECT_COMMENTS = 'getProjectCommentsbyProjectUid';
const UPDATE_PROJECT_LEVEL_COMMENT = 'updateProjectCommentByUid';
const DELETE_PROJECT_LEVEL_COMMENT = 'deleteProjectCommentByUid';
const OPPORUTUNITIES_OF_CLIENT = 'getCrmOpportunityDetailsOfClient';
const CLONE_LIVE_PROJECT = 'cloneLiveProject';
const GET_TASKS_PATH = 'getTaskNetworkWithCriticalPath';
const UPDATE_PROJECT_END_DATES = 'updateEndDatesOfProject';
const FIND_CRITICAL_PATH = 'findCriticalPath';
const GET_TASK_PATH = 'getPathOfThisTask';
const GET_TASK_PARENTS = 'getTaskParents';
const GET_TASK_CHILDREN = 'getTaskChildren';
const UPDATE_TASK_DELAY = 'updateTaskDelay';
const HANDLE_PROJECT_PLANNED_DATE_UPDATE = 'handleProjectPlannedDateUpdate';
// USECASE
const GET_USECASE_BY_UID = 'getUsecaseByUid';
const USE_CASE_CUSTOMFIELDS = 'getCustomFieldUsecaseByCustomerUid';
const USE_CASE_CUSTOMFIELDS_VALUES = 'getUsecaseCustomFieldsByCaseUid';
const GET_SETTING_CUSTOM_JOURNEY_STAGE_ROUTES = 'getCustomJourneystage';
// const GET_SETTING_JOURNEY_STAGE_ROUTES = 'getJourneystage';
const UPDATE_CUSTOM_JOURNEY_STAGE = 'addCustomJourneystage';
const UPDATE_CUSTOM_JOURNEY_STAGE_EXISTING = 'updateCustomJourneyStage';
const GET_CUSTOMER_DETAILS = 'getCustomerDetailById';
const UPDATE_USECASE_IN_CONFIGURATION = 'updateCaseCalcById';
const GET_USER_DETALS_ROUTE = 'getUserDetailByUid';
const UPDATE_USERNAME = 'updateUserNameById';
const UPDATE_USER_EMAIL = 'updateUserEmailbyid';
const UPDATE_USER_PASSWORD = 'updateuserPasswordByUId';
const CUSTOM_FIELD_CLIENT_RECORD = 'getCustomFieldsByCustomerUid';
const CUSTOM_FIELD_PROJECT_RECORD = 'getCustomFieldProjectByCustomerUid';
const CUSTOM_FIELD_USECASE_RECORD = 'getCustomFieldUsecaseByCustomerUid';
const CUSTOM_FIELD_TASK_RECORD = 'getCustomFieldTaskByCustomerUid';
const DELETE_EXISTING_PRUDUCT = 'deleteExisitingProductbyCustomerUid';
const SAVE_JIRA_CREDENTIAL = 'get_jira_api_project_search1';
const UPDATE_JIRA_FETCH_MODE = 'updateJiraFetchModeByCustomeruid';
const UPDATE_JIRA_TASK_MODE = 'updateJiraTaskModeByCustomeruid';
const ADD_IMPLEMENTATION_TASK_KEY = 'get_jira_api_project_search';
const GET_NOTIFICATION_DATA = 'getTrailByUserUid';
const GET_ALL_TASKS = 'getAllTasksByUseruidForCustomer';
const GET_CUSTOM_VIEW_COLUMNS = 'getClientRecordViewColumns';
const UPDATE_CLIENT_RECORD_VIEW_COLUMNS = 'updateClientRecordViewColumns';
const UPDATE_USECASE_BY_UID = 'updateUsecaseByUid';
const ADD_CUSTOM_FIELD_POJECT_BY_CUSTOMER_UID = 'addCustomFieldProjectByCustomerUid';
const UPDATE_CUSTOM_FIELD_RAG_CHECK_BOX_STATUS = 'updateCustomFieldRAGCheckBoxStatus';
const ADD_SUB_FIELD_FOR_CUSTOM_FIELD_PROJECT = 'addSubFieldforCustomFieldProject';
const ADD_CUSTOM_FIELD_BY_CUSTOMER_UID = 'addCustomFieldByCustomerUid';
const ADD_CUSTOM_FIELD_USECASE_BY_CUSTOMER_UID = 'addCustomFieldUsecaseByCustomerUid';
const ADD_CUSTOM_FIELD_TASK_BY_CUSTOMER_UID = 'addCustomFieldTaskByCustomerUid';
// const GET_TASKS_UNDER_USECASE = 'getTasksUnderUsecase'
const ADD_USECASE_INFORMATION = 'addUsecaseInformation';
const ADD_USECASE_AUDIT_TRAIL = 'addUseCaseAuditTrail';
const GET_USECASE_AUDIT_TRAIL = 'getUseCaseAuditTrail';
const UPDATE_USECASE_CUSTOM_FIELDS = 'updateUsecaseCustomFieldbyUid';
const DELETE_USECASE = 'deleteUsecaseByUid';
const ADD_USECASE_COMMENT = 'addUsecaseComment';
const ALL_USECASE_COMMENTS = 'getUsecaseCommentsbyCaseUid';
const UPDATE_USECASE_LEVEL_COMMENT = 'updateUsecaseCommentByUid';
const DELETE_USECASE_LEVEL_COMMENT = 'deleteUsecaseCommentByUid';

const CLIENT_GET_PROJECTS_WITH_TASK = 'welcome/client_getProjectsWithTasks';

// settings routes
const UPDATE_JIRA_USERNAME_AND_CUSTOMER_TOKEN = 'updateJirausernameandtokenByCustomeruid';
const UPDATE_JIRA_ACCOUT_ID_BY_USERS = 'updateJiraAccountIdforUsers';
const ADD_JIRA_PROJECT_TO_CUSTOMER = 'addCustomerProject';
const ADD_JIRA_PRODUCT_STAGE_CUSTOM_STAGE = 'addJiraProductStageCustomStage';
const GET_PROJECT_FROM_CUSTOMER = 'getProjectsByCustomer';
const DELETE_CUSTOMER_PROJECT = 'deleteCustomerProject';
const UPDATE_REMOVE_JIRA_TOKEN = 'updateRemoveJiraTokenByCustomeruid';
const REMOVE_JIRA_LINK_FROM_TASK = 'removeJiraLinkFromTaskByCustomeruid';
const DELETE_WHOLE_CUSTOMER_PROJECT = 'deleteWholeCustomerProject';
const UPDATE_CUSTOM_FIELD_BY_CUSTOMER_UID = 'updateCustomFieldByCustomerUid';
const JIRA_PRODUCT_CUSTOM_STAGE = 'fetchJiraProductCustomStage';
const UPDATE_JIRA_PRODUCT_CUSTOM_STAGE = 'updateJiraProductStageCustomStage';
const JIRA_TASK_CUSTOM_STAGE = 'getCustomTaskStatusbyJiraProject';
const UPDATE_JIRA_TASK_STAGE = 'updateCustomTaskStatusesbyJiraProject';
const UPDATE_CUSTOM_ATTACHMENT_STATUS = 'updateCustomFieldAttachmentStatus';
const UPDATE_CUSTOM_MANDATORY_STATUS = 'updateCustomFieldMandatoryStatus';
const UDPATE_CUSTOM_USECASE_FIELD = 'updateCustomFieldUsecaseByCustomerUid';
const UDPATE_CUSTOM_PROJECT_FIELD = 'updateCustomFieldProjectByCustomerUid';
const UDPATE_CUSTOM_TASK_FIELD = 'updateCustomFieldTaskByCustomerUid';
const DELETE_CUSTOM_USECASE_FIELD = 'deleteCustomFieldUsecaseByUid';
const DELETE_CUSTOM_PROJECT_FIELD = 'deleteCustomFieldProjectByUid';
const DELETE_CUSTOM_TASK_FIELD = 'deleteCustomFieldTaskByUid';
const DELETE_CUSTOM_CLIENT_FIELD = 'deleteCustomFieldbyUid';
const UPDATE_MAGIC_LINK_DURATION = 'updateClienContactMagicLinkDuration';
const GET_EMAIL_CONFIG = 'getEmailConfig';
const ADD_EMAIL_CONFIG = 'addEmailConfig';
const DELETE_EMAIL_CONFIG = 'deleteEmailConfig';
const CHANGE_EMAIL_CONFIG = 'changeEmailConfig';
const UPDATE_CUSTOM_LABEL_VALUE = 'updateCustomLabelValue';
const INSERT_CUSTOM_LABEL = 'insertRenameFields';
const GET_MERGE_LINK_TOKEN = 'merge_link_token';
const SWAP_MERGE_PUBLIC_TOKEN = 'swap_public_token';
const CUSTOMER_INTEGRATION = 'getCustomerIntegrationFromMicroService';
const UPDATE_CUSTOMER_INTEGRATION_PIPEDRIVE = 'update_customer_info';
const CUSTOMER_INTEGRATION_DETAILS = 'getCustomerIntegrationDetails';
const CRM_STAGES = 'getCrmStage';
const UPDATE_STAGE_AND_FETCH_MODE = 'updateStageAndFetchMode';
const UPDATE_REVENUE_REPORT_BASIS = 'updateRevenueReportBasis';
const GET_CUSTOM_SEGMENT_VALUE = 'getCustomClientSegments';
const UPDATE_CUSTOM_SEGMENT_VALUE = 'updateClientCustomSegment';
const SEND_EMAIL_TO_USERS_WITH_CUSTOM_MESSAGE = 'sendEmailToUsersWithCustomMessage';
const GET_PROJECT_CATEGORY = 'getProjectCategories';
const GET_CUSTOM_PROJECT_CATEGORY = 'getCustomProjectCategory';
const UPDATE_CUSTOM_PROJECT_CATEGORY = 'updateProjectCustomCategory';
const GET_PROJECT_STAGES = 'getProjectStages';
const GET_CUSTOM_PROJECT_STAGES = 'getCustomProjectStage';
const UPDATE_CUSTOM_PROJECT_STAGE = 'updateProjectCustomStage';
const GET_USECASE_CATEGORY = 'getUsecaseCategories';
const GET_CUSTOM_USECASE_CATEGORY = 'getCustomUsecaseCategory';
const UPDATE_CUSTOM_USECASE_CATEGORY = 'updateUsecaseCustomCategory';
const GET_USECASE_STAGES = 'getUsecaseStages';
const GET_CUSTOM_USECASE_STAGES = 'getCustomUsecaseStage';
const UPDATE_CUSTOM_USECASE_STAGE = 'updateUsecaseCustomStage';
const SAVE_APP_STATE = 'saveAppStateConfiguration';
const GET_APP_STATE = 'getAppStateConfiguration';
// newer
const GET_PROJECT_PRIORITIES = 'getProjectPriorities';
const GET_CUSTOM_PROJECT_PRIORITIES = 'getCustomProjectPriority';
const UPDATE_CUSTOM_PROJECT_PRIORITY = 'updateProjectCustomPriority';

const GET_USECASE_PRIORITIES = 'getUsecasePriorities';
const GET_CUSTOM_USECASE_PRIORITIES = 'getCustomUsecasePriority';
const UPDATE_CUSTOM_USECASE_PRIORITY = 'updateUsecaseCustomPriority';

const GET_TASK_PRIORITIES = 'getTaskPriorities';
const GET_CUSTOM_TASK_PRIORITIES = 'getCustomTaskPriority';
const UPDATE_CUSTOM_TASK_PRIORITY = 'updateTaskCustomPriority';

const GET_TASK_STAGES = 'getTaskStages';
const GET_CUSTOM_TASK_STAGES = 'getCustomTaskStage';
const UPDATE_CUSTOM_TASK_STAGE = 'updateTaskCustomStage';

const GET_ALL_STNDARD_AND_CUSTOM_NAMES = 'getAllStandardAndCustomNames';

const UPDATE_CUSTOMER_HOLIDAYS = 'updateCustomerHolidays';
// Task

const ADD_PROJECT_PHASE = 'addProjectSection';
const CLONE_PROJECT_PHASE = 'cloneProjectPhase';
const CLONE_USECASE_PHASE = 'cloneUsecasePhase';
const UPDATE_PHASE = 'updatePhase';
// const ADD_USECASE_PHASE = 'addUsecaseSection';
const ADD_USECASE_PHASE = 'addUsecaseSection';
const GET_JIRA_PRIORITY = 'get_jira_priority_list';
const GET_JIRA_PROJECTS = 'getJiraTaskProject';
const ADD_PROJECT_TASK = 'addTaskForProject';
const ADD_USECASE_TASK = 'addTaskForUsecase';
const GET_TASKS_UNDER_PROJECT = 'getAllTasksUnderProject';
const GET_PROJECT_SUBTASK = 'getProjectSubtasksByTaskUid';
const ADD_PROJECT_SUBTASK = 'addProjectSubTask';
const GET_TASK_CUSTOMFIELDS_WITH_VALUE = 'getTaskCustomFieldsByTaskUid';
const GET_TASK_CUSTOMFIELDS = 'getCustomFieldTaskByCustomerUid';
const UPDATE_PROJECT_SUBTASK = 'updateProjectSubtaskByuid';
const UPDATE_USECASE_SUBTASK = 'updateSubtaskByuid';
const DELETE_PROJECT_TASK_FROM_JIRA_BY_ISSUE_ID = 'deleteProjectTaskFromJIRAbyIssueId';
const DELETE_PROJECT_SUBTASK = 'deleteProjectSubTaskbyUid';
const DELETE_USECASE_SUBTASK = 'deleteSubTaskbyUid';
const GET_PROJECT_TASK_DEPENDENCY = 'getProjectTaskDependencyByTaskUid';
const ADD_PROJECT_TASK_DEPENDENCY = 'addProjectTaskDependency';
const DELETE_PROJECT_TASK_DEPENDENCY = 'deleteProjectTaskDependency';
const DELETE_USECASE_TASK_DEPENDENCY = 'deleteTaskDependency';
const ADD_TASK_DEPENDENCY = 'addTaskDependency';
const GET_TASK_DEPENDENCY = 'getTaskDependencyByTaskUid';
const GET_TASK_BY_TASKUID = 'getTaskByTaskUid';
const GET_COUNT_BY_USER_UID = 'getCountByUserUid';
const GET_TEAM_COUNT_BY_USER_UID = 'getTeamCountByUserUid';
const GET_USECASE_COUNT = 'getUseCaseCountUid';
const GET_TEAM_USECASE_COUNT = 'getTeamUseCaseCountUid';
const GET_TASK_STATUS_COUNT = 'getTaskStatusCount';
const GET_TEAM_TASK_STATUS_COUNT = 'getTeamTaskStatusCount';
const GET_PROJECT_STATUS_COUNT = 'getProjectStatusCountByCustomerUid';
const GET_TEAM_PROJECT_STATUS_COUNT = 'getTeamProjectStatusCountByCustomerUid';
const GET_CLIENT_UPGRADATION_ALERT = 'getClientUpgradationAlert';
const GET_TEAM_CLIENT_UPGRADATION_ALERT = 'getTeamClientUpgradationAlert';
const GET_PROJECT_UPGRADATION_ALERT = 'getProjectUpgradationAlert';
const GET_TEAM_PROJECT_UPGRADATION_ALERT = 'getTeamProjectUpgradationAlert';
const GET_USECASE_UPGRADATION_ALERT = 'getUsecaseUpgradationAlert';
const GET_TEAM_USECASE_UPGRADATION_ALERT = 'getTeamUsecaseUpgradationAlert';
const GET_CLIENT_DEGRADATION_ALERT = 'getClientDegradationAlert';
const GET_TEAM_CLIENT_DEGRADATION_ALERT = 'getTeamClientDegradationAlert';
const GET_PROJECT_DEGRADATION_ALERT = 'getProjectDegradationAlert';
const GET_TEAM_PROJECT_DEGRADATION_ALERT = 'getTeamProjectDegradationAlert';
const GET_USECASE_DEGRADATION_ALERT = 'getUsecaseDegradationAlert';
const GET_TEAM_USECASE_DEGRADATION_ALERT = 'getTeamUsecaseDegradationAlert';
const ADD_PROJECT_TASK_AUDIT_TRAIL = 'addProjectTaskAuditTrail';
const ADD_PROJECT_CLIENT_TASK_AUDIT_TRAIL = 'addProjectClientTaskAuditTrail';
const ADD_TASK_AUDIT_TRAIL = 'addTaskAuditTrail';
const ADD_CLIENT_TASK_AUDIT_TRAIL = 'addClientTaskAuditTrail';
const ADD_PROJECT_TASK_COMMENT = 'addProjectTaskComment';
const SEND_PROJECT_TASK_COMMENT_EMAIL = 'sendProjectTaskCommentEmail';
const ADD_USECASE_TASK_COMMENT = 'addTaskComment';
const ADD_PROJECT_CLIENT_TASK_COMMENT = 'addProjectClientTaskComment';
const ADD_COMMENT_TO_JIRA = 'createJiraTaskComment';
const DELETE_JIRA_TASK_COMMENT = 'deleteJiraTaskComment';
const DELETE_JIRA_REQUIREMENT_COMMENT = 'deleteJiraRequirmentComment';
const GET_ALL_TEAM_TASK_FOR_CUSTOMER = 'getAllTeamTasksByUseruidForCustomer';
const GET_ALL_REPORTEES = 'getAllReporties';
const GET_TEAM_CLIENTS = 'getClientsbyUserTeam';

const ADD_USECASE_CLIENT_TASK_COMMENT = 'addClientTaskComment';
const SEND_USECASE_TASK_COMMENT_EMAIL = 'sendUsecaseTaskCommentEmail';
const GET_PROJECT_TASK_COMMENTS = 'getProjectTaskCommentsbyTask';
const GET_PROJECT_CLIENT_TASK_COMMENTS = 'getProjectClientTaskCommentsbyTask';
const GET_USECASE_TASK_COMMENTS = 'getTaskCommentsbyTask';
const GET_USECASE_CLIENT_TASK_COMMENTS = 'getClientTaskCommentsbyTask';
const UPDATE_PROJECT_TASK_COMMENT = 'updateProjectTaskCommentByUid';
const UPDATE_PROJECT_CLIENT_TASK_COMMENT = 'updateProjectClientTaskCommentByUid';
const UPDATE_USECASE_TASK_COMMENT = 'updateTaskCommentByUid';
const UPDATE_USECASE_CLIENT_TASK_COMMENT = 'updateClientTaskCommentByUid';
const DELETE_TASK_COMMENT = 'deleteTaskCommentByUid';
const MARK_PUBLIC_OR_INTERNAL_TASK_COMMENT = 'markPublicorinternaltaskcomment';
const DELETE_CLIENT_TASK_COMMENT = 'deleteClientTaskCommentByUid';
const UPDATE_TASK = 'updateTask';
const DELETE_PROJECT_TASK = 'deleteProjectTaskByUid';
const DELETE_PROJECT_CLIENT_TASK = 'deleteProjectClientTaskByUid';
const DELETE_USECASE_TASK = 'deleteusecaseTaskbyUid';
const DELETE_USECASE_CLIENT_TASK = 'deleteusecaseClientTaskbyUid';
const DELETE_USECASE_SECTION = 'deleteUsecasesectionByUid';
const DELETE_PROJECT_SECTION = 'deleteProjectsectionByUid';
const GET_JIRA_PROJECT_ISSUE = 'get_jira_api_project_by_key';
const GET_PROJECT_TASK_TIME_LOG = 'getProjectTaskTimeLogsByTaskUId';
const UPDATE_PROJECT_TASK_TIME_LOG = 'updateProjectTaskTimeLogsByTaskUId';
const GET_USECASE_TASK_TIME_LOG = 'getTaskTimeLogsByTaskUId';
const UPDATE_USECASE_TASK_TIME_LOG = 'updateTaskTimeLogsByTaskUId';
const GET_USECASE_SUBTASKS = 'getUsecaseSubtasksBytaskuid';
const ADD_USECASE_SUBTASK = 'addUsecaseSubTask';
const TASK_BULK_UPDATE = 'bulkUpdateTasks';
const TASK_BULK_CSAT_MAIL = 'bulkUpdateTaskDetailCsatMail';
const BULK_DELETE_TASK = 'bulkDeleteTask';
const DELETE_TASK_FROM_TEMPORARY_TABLE_AND_SEND_MAIL = 'deleteTaskFromTemporaryTableAndSendMail';
const GET_ALL_ELIGIBLE_TASK_OWNER_DETAILS_OF_CLIENT = 'getAllEligibleTaskOwnerDetailsOfClient';
const SYNC_PROJECT_TASK_FROM_JIRA = 'syncProjectTaskFromJIRAbyIssueId';
const SYNC_TASK_FROM_JIRA = 'syncTaskFromJIRAbyIssueId';
const UPDATE_JIRA_TASK_ISSUE = 'update_jira_task_issue';
const GET_PROJECT_TASK_BY_TASK_UID = 'getProjectTaskByTaskUid';
const SYNC_JIRA_TASK = 'syncTaskFromJIRAbyClient';
const UPDATE_TASK_CUSTOM_FIELD = 'updateTaskCustomFieldbyUid';
const CHANGE_TASK_STATUS = 'changeTaskStatus';
const GET_TASK_AUDIT_TRAIL = 'getTaskAuditTrail';
const GET_SUB_TASK_AUDIT_TRAIL = 'getSubTaskAuditTrail';
const GET_PROJECT_TASK_AUDIT_TRAIL = 'getProjectTaskAuditTrail';
const GET_CLIENT_TASK_AUDIT_TRAIL = 'getClientTaskAuditTrail';
const GET_PROJECT_CLIENT_TASK_AUDIT_TRAIL = 'getClientProjectTaskAuditTrail';
const GET_TASK_CUSTOM_VALUES_BY_CUSTOMER = 'getTaskCustomValuesByCustomer';
const UPDATE_USECASE_DEPENDENCY_DUE_DATE = 'updateTaskDependentsDueDate';
const UPDATE_USECASE_DEPENDENCY_DELIVERY_DATE = 'updateTaskDependentsDeliveryDate';
const UPDATE_PROJECT_DEPENDENCY_DUE_DATE = 'updateProjectTaskDependentsDueDate';
const UPDATE_PROJECT_DEPENDENCY_DELIVERY_DATE = 'updateProjectTaskDependentsDeliveryDate';
const ADD_SUBTASK_AUDIT_TRAIL = 'addSubTaskAuditTrail';
// const GET_TASKS_OF_USER = 'getAllTasksByUseruidForCustomer';

const ADD_CLIENT_INFORMATION = 'addClientinformation';

// RAID
const CREATE_RAID = 'createProjectRaidTask';
const GET_RAID_BY_UID = 'getRaidTaskByRaidUid';
const UPDATE_RAID = 'updateRaidTask';
const DELETE_RAID = 'deleteRaidTaskByUid';
const GET_RAID_BY_PROJECT_UID = 'getRaidTaskByProjectUid';
const BULK_DELETE_RAID = 'bulkDeleteRaid';

// aggregated cards
const CREATE_AGGREGATED_CARD = 'createAggregatedCard';
const UPDATE_AGGREGATED_CARD = 'updateAggregatedCard';
const GET_ALL_AGGREGATED_CARDS = 'getAllAggregatedCards';
const CLONE_AGGREGATED_CARD = 'cloneAggregatedCard';
const DELETE_AGGREGATED_CARD = 'deleteAggregatedCard';

//comment
const GET_RAID_COMMENTS = 'getProjectRaidCommentByRaidUid';
const ADD_RAID_COMMENT = 'addProjectRaidComment';
const UPDATE_RAID_COMMENT = 'updateRaidComment';
const DELETE_RAID_COMMENT = 'deleteRaidCommentByUid';

// Templates

const ADD_PROJECT_TEMPLATE = 'addTemplateProject';
const GET_PROJECT_TEMPLATES = 'getProjectTemplates';
const GET_USECASE_TEMPLATES = 'getUseCaseTemplatesbycustomer';
const SAVE_TEMPLATE_PROJECT_SECTION = 'saveTemplateProjectSection';
const GET_ALL_PROJECT_SECTIONS_BY_PROJECT_UID = 'getAllSectionByTemplateProjectuid';
const ADD_TEMPLATE_PROJECT_TASK = 'addTemplateProjectTask';
const ADD_TEMPLATE_PROJECT_CLIENT_TASK = 'addTemplateProjectClientTask';
const UPDATE_TEMPLATE_PROJECT_BY_UID = 'updateTemplateProjectByUid';
const ADD_TEMPLATE_USECASE = 'addTemplateUseCase';
const SAVE_TEMPLATE_USECASE_SECTION = 'saveTemplateUsecaseSection';
const GET_ALL_SECTIONS_BY_TEMPLATE_UID = 'getAllSectionByTemplateCaseuid';
const ADD_TEMPLATE_USECASE_TASK = 'addTemplateUsecaseTask';
const ADD_TEMPLATE_USECASE_CLIENT_TASK = 'addTemplateUsecaseClientTask';
const UPDATE_TEMPLATE_USECASE_BY_UID = 'updateTemplateUsecaseByUid';
const SYNC_PRODUCT_AND_FEATURES_FROM_JIRA_BY_ONE_CUSTOMER = 'syncProductsandFeaturesFromJIRAbyOneCustomer';
const GET_TEMPLATE_PROJECT_INFORMATION = 'getTemplateProjectinformation';
const GET_TEMPLATE_CASE_INFORMATIONM = 'getTemplateUsecaseinformation';
const GET_TEMPLATE_PROJECT_INFORMATION_WITH_TASK = 'getTemplateProjectinformationwithtask';
const DELETE_TEMPLATE_PROJECT_BY_UID = 'deleteTemplateProjectbyuid';
const DELETE_TEMPLE_USECASE_BY_UID = 'deleteTemplateUsecasebyuid';
const DELETE_USECASE_TEMPLATE_SECTION_BY_UID = 'deleteUsecaseTemplatesectionByUid';
const DELETE_PROJECT_TEMPLATE_SECTION_BY_UID = 'deleteProjectTemplatesectionByUid';
const GET_TEMPLATE_INFO = 'getTemplateInformationByTemplateUid';
const UPDATE_TEMPLATE_PROJECT_TASK_BY_UID = 'updateTemplateProjectTaskbyuid';
const UPDATE_TEMPLATE_USECASE_TASK_BY_UID = 'updateTemplateUsecaseTaskbyuid';
const UPDATE_TEMPLATE_PROJECT_CLIENT_TASK_BY_UID = 'updateTemplateProjectClienttaskByuid';
const UPDATE_TEMPLATE_USECASE_CLIENT_TASK_BY_UID = 'updateTemplateUsecaseClienttaskByuid';
const DELETE_TEMPLATE_POJECT_TASK_BY_UID = 'deleteTemplateProjectTaskbyUid';
const DELETE_TEMPLATE_PROJECT_CLIENT_TASK_BY_UID = 'deleteTemplateProjectClientTaskbyUid';
const DELETE_TEMPLATE_USECASE_CLIENT_TASK_BY_UID = 'deleteTemplateusecaseClientTaskbyUid';
const DELETE_TEMPLATE_USECASE_TASK_BY_UID = 'deleteTemplateUsecaseTaskbyUid';
const GET_TEMPLATE_PROJECT_TASK = 'getTemplateProjecttaskbyprojectuid';
const GET_TEMPLATE_PROJECT_CLIENT_TASK = 'getTemplateProjectClienttaskbyprojectuid';
const GET_TEMPLATE_USECASE_TASK = 'getTemplateUsecasetaskbycaseuid';
const GET_TEMPLATE_USECASE_CLIENT_TASK = 'getTemplateUsecaseClienttaskbycaseuid';
const INCREMENT_TEMPLATE_CASE_TASK_SEQUENCE = 'incrementTemplateCaseTaskSequences';
const INCREMENT_TEMPLATE_PROJECT_TASK_SEQUENCE = 'incrementTemplateProjectSectionTaskSequences';
const CLONE_PROJECT_TEMPLATE = 'cloneProjectTemplate';
const CLONE_USECASE_TEMPLATE = 'cloneUsecaseTemplate';
const CREATE_TEMPLATE_DOCUMENT = 'addTemplateDocument';
const DELETE_TEMPLATE_DOCUMENT = 'deleteTemplateDocument';
const GET_TEMPLATE_DOCUMENTS_BY_CUSTOMER_UID = 'getTemplateDocumentsByCustomerUid';
const SEND_TASK_ASSIGNMENT_MAIL_AFTER_IMPORTING_TEMPLATE = 'sendTaskAssignmentMailImportingTemplate';
const UPDATE_TEMPLATE_PHASE = 'updateTemplatePhase';
const GET_PROJECT_TEMPLATE_DEPENDENCY = 'getAllProjectTaskDependency';
const GET_USECASE_TEMPLATE_DEPENDENCY = 'getAllUsecaseTaskDependency';

// Common

const MAIL_TO_SUPPORT = 'mailToSupport';
const FEEDBACK_MAIL_TO_SUPPORT = 'newFeedbackMailTosupport';
const GET_CF_USECASE_AND_SUB_FIELDS = 'getCustomFieldUsecaseByCustomerUid';
const GET_CUSTOMFIELD_BY_CUSTOMER_UID = 'getCustomFieldsByCustomerUid';
const GET_CF_OF_TASKS = 'getCustomFieldTaskByCustomerUid';
const GET_CLIENT_CONTACTS_BY_CUSTOMER_UID = 'getClientContactsByCustomerUid';
const GET_RENAMED_LABELS = 'getRenamedFeildsByCustomerUid';
const GET_ALL_CLIENTS_BY_CUSTOMER_UID = 'getClientbyCustomerClient';
const GET_ALL_ENTITIES = 'getAllEntitiesOfCustomer';
const CREATE_CUSTOM_REPORT = 'createCustomReport';
const GET_CUSTOM_REPORTS = 'getCustomReports';
const GET_REPORT_BY_UID = 'getGeneratedCustomReportUsingUid';
const GET_REPORT_CONFIG = 'getCustomReportConfig';
const UPDATE_CUSTOM_REPORT = 'updateCustomReport';
const UPDATE_CUSTOMER_STATUS = 'updateCaseStatusByCustomer';
// const DELETE_CUSTOM_REPORT = 'deleteCustomReport';

// Customer Portal

const CLIENT_CHECK = 'welcome/client_checkclient';
// const CLIENT_GET_PROJECTS_WITH_TASK = 'client_getProjectsWithTasks';
const CLIENT_USER_SIGN_IN_WITH_JWT_TOKEN = 'welcome/client_userSignIn_withjwttoken';
const GET_CLIENT_iNFORMATION = 'client_getClientinformation';
const CLIENT_GET_CUSTOMER_DETAIL = 'client_getCustomerDetailById';
// const CLIENT_UPDATED_CASE_WITH_FEATURE_STATUS = 'client_updatedcasewithfeaturestatusClient';
const GET_SINGLE_PROJECT_INFORMATION = 'client_getsingleProjectInformationWithTaskClientUser';
const GET_SINGLE_USECASE_INFORMATION = 'client_getsingleUsecaseInformationWithTaskClientUser';
const GET_CLIENT_PROJECT_UPGRADATION_ALERT = 'client_getProjectUpgradationAlert';
const GET_CLIENT_USECASE_UPGRADATION_ALERT = 'client_getUsecaseUpgradationAlert';
const GET_CLIENT_PROJECT_DEGRADATION_ALERT = 'client_getProjectDegradationAlert';
const GET_CLIENT_USECASE_DEGRADATION_ALERT = 'client_getUsecaseDegradationAlert';
const GET_CLIENT_PROJECT_STATUS_COUNT_BY_CLIENT_UID = 'client_getProjectStatusCountByClientUid';
const GET_CLIENT_TASK_STATUS_COUNT_BY_CLIENT_UID = 'client_getTaskStatusCountByClientUid';
const GET_CLIENT_USECASE_STATUS_COUNT = 'client_getUseCaseStatusCount';
const GET_CLIENT_ALL_USERS_BY_COMPANY = 'welcome/client_getAllusersbycompany';
const GET_CLIENT_CONTACTS_BY_CONTACT_UID = 'welcome/client_getClientContactsByContactUid';
const GET_CLIENT_CONTACTS_BY_CLIENT_UID = 'welcome/client_getClientContactsByClientUid';
const CLIENT_ADD_PROJECT_TASK = 'welcome/client_addProjectTask';
const CLIENT_ADD_USECASE_TASK = 'client_addUsecaseTask';
const CLIENT_UPDATE_PROJECT_TASK_BY_TASK_UID = 'client_updateProjecttaskbyuid';
const CLIENT_UPDATE_USECASE_TASK_BY_TASK_UID = 'client_updateUsecasetaskbyuid';
const CLIENT_ADD_PROJECT_TASK_AUDIT_TRAIL = 'client_addProjectTaskAuditTrail';
const CLIENT_SEND_MAGIC_LINK = 'welcome/client_sendmagiclink';
const CLIENT_GET_TASK_DETAIL = 'client_getTaskByTaskUid';
const CLIENT_UPDATE_TASK_ACKNOWLEDGED_STATUS = 'client_updateTaskAcknowledgedStatus';

// Resource Management
const GET_ALL_DELIVERY_MANAGER_DETAILS_WITH_PROJECT_AND_TASK_USING_CUSTOMER_UID =
  'getAllDeliveryManagerDetailsWithProjectAndTaskUsingCustomerUid';
// Soft Allocation
const GET_SOFT_ALLOCATION_BY_USER_UID = 'getSoftAllocationByUserUid';
const ADD_SOFT_ALLOCATION = 'addSoftAllocation';
const UPDATE_SOFT_ALLOCATION = 'updateSoftAllocation';
const DELETE_SOFT_ALLOCATION = 'deleteSoftAllocation';
const BULK_DATA_FOR_RESOURCE_MANAGEMENT = 'getResourceManagementRawData';
const GET_RESOURCE_MANAGEMENT_USER_DATA = 'getResourceManagementRawUserData';
const GET_RESOURCE_MANAGEMENT_PROJECT_DATA = 'getResourceManagementRawProjectData';
const GET_RESOURCE_MANAGEMENT_TASK_DATA = 'getResourceManagementRawTaskData';

// Graph
const ADD_GRAPH_CONFIGURATION = 'addGraphConfiguration';
const GET_GRAPH_CONFIGURATION = 'getGraphConfiguration';
const DELETE_GRAPH_CONFIGURATION = 'deleteGraphConfiguration';
const UPDATE_GRAPH_CONFIGURATION = 'updateGraphConfiguration';

// RBAC
const GET_ALL_USERS_AND_ROLES = 'welcome/getAllUsersWithRoles';
const GET_ALL_VALID_REPORTEE_ADDITIONS = 'welcome/getAllValidReporteeAdditions';
const GET_RESOURCES_FIELD = 'welcome/getResourcesField';
const GET_ALL_RESOURCES = 'welcome/getAllResources';
const GET_RESOURCE_FIELD_VALUE = 'welcome/getResourcesFieldValue';
const GET_GENERAL_FIELD_VALUE = 'welcome/getGeneralFieldValueonResources';
const GET_ALL_PAGES = 'welcome/getAllPages';
const GET_ALL_PERMISSIONS = 'welcome/getAllPermissions';
const GET_POLICY_ON_RESOURCES = 'welcome/getPolicyOnResources';
const ADD_ROLE = 'welcome/addNewRole';
const ADD_USER = 'welcome/addUser';
const DEACTIVATE_USER_RBAC = 'welcome/deactivateUser';
const ACTIVATE_USER_RBAC = 'welcome/activateUser';
const ADD_POLICY_TO_ROLE = 'welcome/addPoliciesToRole';
const REMOVE_POLICY_FROM_ROLE = 'welcome/removePoliciesFromRole';
const ADD_PAGES_TO_ROLE = 'welcome/addPagesToRole';
const REMOVE_PAGES_FROM_ROLE = 'welcome/removePagesFromRole';
const CHANGE_USER_ROLE = 'welcome/changeUserRole';
const GET_ALL_ROLES = 'welcome/getAllRoles';
const ADD_POLICY = 'welcome/createPolicy';
const GET_ALL_CONDITIONS = 'welcome/getConditionsOnResource';
const REMOVE_CONDITIONS = 'welcome/removeConditionsFromPolicy';
const ADD_CONDITION_TO_POLICY = 'welcome/addConditionsToPolicy';
const CREATE_CONDITION = 'welcome/createCondition';
const GET_CONDITIONS = 'welcome/getAllConditions';
const ENABLE_RBAC_GLOBALLY = 'welcome/enableRbac';
const DISABLE_RBAC_GLOBALLY = 'welcome/disableRbac';
const ENABLE_SSO_MANDATORY = 'welcome/enableMandatorySSO';
const DISABLE_SSO_MANDATORY = 'welcome/disableMandatorySSO';
const ADD_REPORTS_TO = 'welcome/addReportees';
const REMOVE_REPORTS_TO = 'welcome/removeReportees';
const GET_RBAC_AFFECTING_USER = 'welcome/getRbacToggleAffectingUsers';
const BOOTSTRAP_RBAC_WITH_DEFAULT_ROLE = 'welcome/bootstrapRbacWIthDefaultRole';
const UPDATE_USERNAME_OF_USER = 'welcome/updateUsernameOfaUser';
const GET_ROLE = 'welcome/getRole';

// Picture upload e.g Customer, Company, Client logo

const ADD_CUSTOMER_LOGO = 'addCustomerLogo';
const UPDATE_CUSTOMER_LOGO = 'updateCustomerLogo';

const ADD_CUSTOMER_CLIENT_LOGO = 'addCustomerClientLogo';
const UPDATE_CUSTOMER_CLIENT_LOGO = 'updateCustomerClientLogo';

const ADD_USER_LOGO = 'addUserProfilePic';
const UPDATE_USER_LOGO = 'updateUserLogo';

// admin panel
const ALL_CUSTOMER_DETAILS = 'getAllCustomersDetail';
const GET_CLIENT_COUNTS = 'getClientsCountByCustomer';
const GET_USER_COUNT = 'getUsersCountByClients';
const GET_USER_ACTIVITIES = 'getAlluserActivity';
const DELETE_CUSTOMER = 'deleteCustomerDetailsByCustomerUid';
const ADMIN_LOGIN = 'adminSignIn';
const OTP_VERIFICATION = 'verifyOTP';
const GET_PROJECT_COUNT = 'getProjectCountByClients';
const GET_USECASE_COUNT_ADMIN_PANEL = 'getUsecaseCountByClients';
const GET_PROJECT_COUNT_USERS = 'getProjectCountByUsers';
const GET_USECASE_COUNT_USERS = 'getUsecaseCountByUsers';
const SAVE_ADMIN_TOKEN = 'saveAdminToken';
const CHECK_ADMIN_TOKEN = 'checkAdminToken';

// JIRA
const GET_JIRA_PROJECT_STATUSES = 'getJiraStatusByProjectKey';
const GET_PRODUCT_BY_ISSUE_ID = 'getProductByIssue';
const GET_SERVICE_DESK = 'get_service_desks';
const GET_JSD_SERVICE_REQUEST_LIST = 'get_jira_request_type_service_id';
const GET_JIRA_JSD_CUSTOM_FIELDS = 'getJiraServiceDeskCustomField';

// customer level
const GET_ALL_CLIENT_CONTACT_PROJECT_MEMBERS = 'getAllClientProjectMembersByCustomerUid';

// attachments
const DOWNLOAD_TEMPLATE_DOCUMENT_ATTACHMENT = 'downloadTemplateDocumentAttachment';

export const authRoutes = {
  userLogin: () => LOGIN_USER,
  userSSOLogin: () => SSO_LOGIN_USER,
  userRequestChangePassword: () => REQUEST_CHANGE_PASSWORD,
  userRegister: () => REGISTER_USER,
  invalidateRefreshToken: () => INVALIDATE_REFRESH_TOKEN,
};

export const clientRoutes = {
  clientList: (customer_uid: string) => `${CLIENT_LIST}?customer_uid=${customer_uid}`,
  crmClientList: (customer_uid: string) => `${CRM_CLIENT_LIST}?customer_uid=${customer_uid}`,
  updateClient: () => `${UPDATE_CLIENT}`,
  deleteClient: () => `${DELETE_CLIENT}`,
  updateClientContact: () => `${UPDATE_CLIENT_CONTACT}`,
  updateClientCustomField: () => `${UPDATE_CLIENT_CUSTOM_FIELD}`,
  renewalTasks: () => `${RENEWAL_TASKS}`,
  renewalTasksOfClient: () => `${RENEWAL_TASKS_OF_CLIENT}`,
  doneRenewalStage: () => `${DONE_RENEWAL_TASKS_OF_CLIENT}`,
  taskRenewalOwner: () => `${TASK_RENEWAL_OF_CLIENT_OWNER}`,
  revenueUsecaseTasks: (customer_uid: string) => `${REVENUE_USECASE_TASKS}?customer_uid=${customer_uid}`,
  getClientAccessListMembers: (client_uid: string) => `${GET_CLIENT_LIST_MEMBERS}?client_uid=${client_uid}`,
  removeUserFromClientAccessList: () => `${REMOVE_USER_FROM_CLIENT_ACCESS_LIST}`,
  addUserToClientAccessList: () => `${ADD_USER_TO_CLIENT_ACCESS_LIST}`,
  revenueProjectTasks: (customer_uid: string) => `${REVENUE_PROJECT_TASKS}?customer_uid=${customer_uid}`,
  addClientInfo: () => `${ADD_CLIENT}`,
  getClientContacts: (client_uid: string) => `${CLIENT_CONTACT}?client_uid=${client_uid}`,
  getClientContactsByAssociationUid: (client_uid: string) => `${CLIENT_CONTACT}?association_uid_uid=${client_uid}`,
  addClientContact: () => `${ADD_CLIENT_CONTACT}`,
  addClientContactMail: () => `${SEND_CLIENT_CONTACT_MAIL}`,
  getSingleClientInformation: (client_uid: string) => `${SINGLE_CLIENT_INFO}?client_uid=${client_uid}`,
  updateSingleClientInformationfromCRM: () => `${UPDATE_SINGLE_CLIENT_INFO}`,
  totalTrailUnderClient: (client_uid: string) => `${TOTAL_TRAIL_UNDER_CLIENT}?client_uid=${client_uid}`,
  updateClientRenewal: () => `${CLIENT_RENEWAL_UPDATE}`,
  clientInformation: (client_uid: string) => `${CLIENT_INFORMATION}?client_uid=${client_uid}`,
  clientDomains: (client_uid: string) => `${CLIENT_DOMAINS}?client_uid=${client_uid}`,
  addClientDomain: () => `${ADD_CLIENT_DOMAIN}`,
  allUsers: (customer_uid: string) => `${ALL_USERS}?customer_uid=${customer_uid}`,
  getClientUidByAssociationUid: (association_uid: string) => `${GET_CLIENT_UID}?association_uid=${association_uid}`,
  getClientDetailByUid: (client_uid: string) => `${GET_CLIENT_BY_UID}?client_uid=${client_uid}`,
  addClientInformation: () => ADD_CLIENT_INFORMATION,
  sendReportToAllClientContacts: () => send_Report_To_All_Client_Contacts,
  sendDynamicReportToClientContact: () => SEND_DYNAMIC_REPORT_TO_CLIENT_CONTACT,
  updateClientStatus: () => UPDATE_CLIENT_STATUS,
  addClientAuditTrail: () => ADD_CLIENT_AUDIT_TRAIL,
  getCustomFieldByCustomerUid: (customer_uid: string) =>
    `${CUSTOM_FIELD_CLIENT_RECORD}?customer_uid=${customer_uid}&client_uid=`,
  getClientAudit: (client_uid: string) => `${GET_CLIENT_AUDIT}?client_uid=${client_uid}`,
  getVerifiedClientContacts: (client_uid: string) => `${GET_VERIFIED_CLIENT_CONTACTS}?client_uid=${client_uid}`,
  getProjecWithClientUid: (client_uid: string) => `${GET_PROJECT_WITH_CLIENT_UID}?client_uid=${client_uid}`,
  updatedcasewithfeaturestatusClient: (client_uid: string) =>
    `${UPDATED_CASE_WITH_FEATURE_STATUS}?client_uid=${client_uid}`,
  getProjectInformationWithTaskClientReport: (client_uid: string) =>
    `${GET_PROJECT_INFORMATION_WITH_TASK_CLIENT_REPORT}?client_uid=${client_uid}`,
  addClientInfoWhenSignup: () => `${ADD_CLIENT_CONTACT_WHEN_SIGNUP}`,
  deleteClientContact: () => `${DELETE_CLIENT_CONTACT}`,
  updateClientContactbyUid: () => UPDATE_CLIENT_CONTACT_BY_UID,
  sendAccessLinkToContact: () => SEND_ACCESS_LINK_TO_CONTACT,
  updateClientSettings: () => UPDATE_CLIENT_SETTINGS,
  updateDisplayArrOnClientList: () => UPDATE_CUSTOMER_DISPLAY_ARR_ON_CIENT_LIST,
};

export const customFieldRoutes = {
  getCustomFields: (customer_uid: string) => `${GET_CUSTOM_FIELDS}?customer_uid=${customer_uid}`,
  getCustomFieldsForClient: (customer_uid: string, client_uid: string) =>
    `${GET_CUSTOMFIELD_FOR_CLIENT}?customer_uid=${customer_uid}&client_uid=${client_uid}`,
  getCustomFieldsWithValues: (client_uid: string) =>
    `${GET_CUSTOMFIELD_OF_CLIENT_WITH_VALUES}?client_uid=${client_uid}`,
  // REPEATED
  updateClientCustomFields: () => `${UPDATE_CLIENT_CUSTOMFIELD}`,
  getCustomFieldsUsecasewithSubFields: (customer_uid: string) =>
    `${GET_CF_USECASE_AND_SUB_FIELDS}?customer_uid=${customer_uid}`,
  getCustomFieldByCustomer: (customer_uid: string) =>
    `${GET_CUSTOMFIELD_BY_CUSTOMER_UID}?customer_uid=${customer_uid}&client_uid=`,
  getCustomFieldsOfTasks: (customer_uid: string) => `${GET_CF_OF_TASKS}?customer_uid=${customer_uid}`,
};

export const csatRoutes = {
  getCsatReviewsByCustomerUid: (customer_uid: string) =>
    `${GET_CSAT_REVIEWS_BY_CUSTOMER_UID}?customer_uid=${customer_uid}`,
  getCsatByTaskUid: (task_uid: string) => `${GET_CSAT_BY_TASK_UID}?task_uid=${task_uid}`,
  getCsatTargetByCustomerUid: (customer_uid: string) =>
    `${GET_CSAT_TARGET_BY_CUSTOMER_UID}?customer_uid=${customer_uid}`,
  // updateCsatTargetByCustomerUid: (customer_uid: string, csat_target: number) =>
  //   `${UPDATE_CSAT_TARGET_BY_CUSTOMER_UID}?customer_uid=${customer_uid}&csat_target=${csat_target}`,
  updateCsatTargetByCustomerUid: () => `${UPDATE_CSAT_TARGET_BY_CUSTOMER_UID}`,
  sendCsatMailToClientContacts: () => `${SEND_CSAT_MAIL_TO_CLIENT_CONTACTS}`,
  sendUseCaseMailToClientContacts: () => `${SEND_CSAT_USECASE_MAIL_TO_CLIENT_CONTACTS}`,
  clientUpdateCsatRating: () => `${CLIENT_UPDATE_CSAT_RATING}`,
  clientBulkUpdateCsatRating: () => `${CLIENT_BULK_UPDATE_CSAT_RATING}`,
  getCsatByCsatUid: (csat_uid: string) => `${GET_CSAT_BY_CSAT_UID}?csat_uid=${csat_uid}`,
};

export const phaseRoutes = {
  getPhasesDetailsByCustomerUid: (customer_uid: string) =>
    `${GET_PHASES_DETAILS_BY_CUSTOMER_UID}?customer_uid=${customer_uid}`,
};

export const customReportRoutes = {
  getGeneratedCustomReportUsingUid: (report_uid: string) =>
    `${GET_GENERATED_CUSTOM_REPORT_USING_UID}?report_uid=${report_uid}`,
  deleteCustomReport: () => DELETE_CUSTOM_REPORT,
  cloneCustomReport: () => CLONE_CUSTOM_REPORT,
};

export const aggregatedCustomReportRoutes = {
  getGeneratedAggregatedCustomReportUsingUid: (report_uid: string) =>
    `${GET_GENERATED_AGGREGATED_CUSTOM_REPORT_USING_UID}?report_uid=${report_uid}`,
  createAggregatedCustomReport: () => `${CREATE_AGGREGATED_CUSTOM_REPORT}`,
  updateAggregatedCustomReport: () => UPDATE_AGGREGATED_CUSTOM_REPORT,
  getAllAggregatedCustomReports: (detail: any) =>
    `${GET_ALL_AGGREGATED_CUSTOM_REPORT}?customer_uid=${detail.customer_uid}&user_uid=${detail.user_uid}`,
  getAggregatedCustomReportConfigByUid: (report_uid: string) =>
    `${GET_AGGREGATED_CUSTOM_REPORT_CONFIG}?report_uid=${report_uid}`,
  deleteAggregatedCustomReport: () => `${DELETE_AGGREGATED_CUSTOM_REPORT}`,
};

export const ProjectReport = {
  getAllProjectsByCustomerUid: (customer_uid: string) => `${getAllProjectsByCustomerUid}?customer_uid=${customer_uid}`,
  getPhasesAndTasks: (customer_uid: string) => `${getPhasesAndTasks}?customer_uid=${customer_uid}`,
  getTeamMembers: (customer_uid: string) => `${getTeamMembersRoute}?customer_uid=${customer_uid}`,
};

export const projectRoutes = {
  openProjects: (customer_uid: string) => `${OPEN_PROJECTS}?customer_uid=${customer_uid}&end_date=all`,
  closedProjects: (customer_uid: string) => `${CLOSED_PROJECTS}?customer_uid=${customer_uid}&task_stage=done`,
  allProjects: (customer_uid: string) => `${ALL_PROJECTS}?customer_uid=${customer_uid}`,
  customFields: (project_uid: string) => `${PROJECT_CUSTOMFIELDS}?project_uid=${project_uid}`,
  customFieldsDetail: (customer_uid: string) => `${PROJECT_CUSTOMFIELDS_DETAIL}?customer_uid=${customer_uid}`,
  updateProjectCustomField: () => `${UPDATE_PROJECT_CUSTOMFIELD}`,
  getProjectInformation: (association_uid: string, project_uid: string) =>
    `${GET_PROJECT_INFORMATION}?association_uid=${association_uid}&project_uid=${project_uid}`,
  addBlankProject: () => ADD_BLANK_PROJECT,
  addProjectInformation: () => ADD_PROJECT_INFORMATION,
  createProjectFromProjectTemplate: () => CREATE_PROJECT_FROM_PROJECT_TEMPLATE,
  createUsecaseFromUsecaseTemplate: () => CREATE_USECASE_FROM_USECASE_TEMPLATE,
  createUsecaseFromUsecaseTemplateInBulk: () => CREATE_USECASE_FROM_USECASE_TEMPLATE_IN_BULK,
  addProjectAuditTrial: () => ADD_PROJECT_AUDIT_TRIAL,
  getProjectInformationWithCaseTaskByUid: (project_uid: string) =>
    `${GET_PROJECT_INFORMATION_FOR_GANTT}?project_uid=${project_uid}`,
  deleteProject: () => `${DELETE_PROJECT}`,
  updateProject: () => `${UPDATE_PROJECT}`,
  getProjectAuditTrail: (project_uid: string) => `${GET_PROJECT_AUDIT_TRAIL}?project_uid=${project_uid}`,
  deleteBlankProject: () => `${DELETE_BLANK_PROJECTS}`,
  getProjectMembers: (project_uid: string, client_uid: string) =>
    `${GET_PROJECT_MEMBERS}?project_uid=${project_uid}&client_uid=${client_uid}`,
  addMembersForProject: () => `${ADD_MEMBERS_FOR_PROJECT}`,
  removeUserFromProject: () => `${REMOVE_USER_FROM_PROJECT}`,
  getProjectByUid: (project_uid: any) => `${GET_PROJECT_BY_PROJECT_UID}?project_uid=${project_uid}`,
  getCustomFieldValuesOfAllProjects: (customer_uid: string) =>
    `${GET_CUSTOM_FIELD_VALUE_FOR_ALL_PROJECTS}?customer_uid=${customer_uid}`,
  getProjectCommentsByProjectUid: (project_uid: any) => `${ALL_PROJECT_COMMENTS}?project_uid=${project_uid}`,
  addProjectComment: () => ADD_PROJECT_COMMENT,
  updateProjectLevelComment: () => UPDATE_PROJECT_LEVEL_COMMENT,
  deleteProjectLevelComment: () => DELETE_PROJECT_LEVEL_COMMENT,
  cloentLiveProjectOfClient: () => CLONE_LIVE_PROJECT,
  getOpportunitiesOfClient: (client_uid: string) => `${OPPORUTUNITIES_OF_CLIENT}?client_uid=${client_uid}`,
  getTasksPath: (project_uid: string, type: string) => `${GET_TASKS_PATH}?uid=${project_uid}&type=${type}`,
  updateProjectEndDates: () => `${UPDATE_PROJECT_END_DATES}`,
  findCriticalPath: (project_uid: string, type: string) => `${FIND_CRITICAL_PATH}?uid=${project_uid}&type=${type}`,
  getPathOfThisTask: (task_uid: string, project_uid: string, type: string) =>
    `${GET_TASK_PATH}?task_uid=${task_uid}&uid=${project_uid}&type=${type}`,
  getTaskParents: () => `${GET_TASK_PARENTS}`,
  getTaskChildren: () => `${GET_TASK_CHILDREN}`,
  updateTaskDelay: (task_uid: string, delayed_by: number) =>
    `${UPDATE_TASK_DELAY}?task_uid=${task_uid}&delay=${delayed_by}`,
  handleProjectPlannedDateUpdate: () => `${HANDLE_PROJECT_PLANNED_DATE_UPDATE}`,
};

export const usersRoutes = {
  allUsers: (customer_uid: string) => `${ALL_RESOURCES}?customer_uid=${customer_uid}`,
};

export const usecaseRoutes = {
  openUsecases: (customer_uid: string) => `${OPEN_USECASES}?customer_uid=${customer_uid}&end_date=all`,
  closedUsecases: (customer_uid: string) => `${CLOSED_USECASES}?customer_uid=${customer_uid}&task_stage=done`,
  updateUsecase: () => `${UPDATE_USECASE}`,
  getUsecaseCustomFields: (customer_uid: string) => `${USE_CASE_CUSTOMFIELDS}?customer_uid=${customer_uid}`,
  getUsecaseCustomFieldsWithValue: (case_uid: string) => `${USE_CASE_CUSTOMFIELDS_VALUES}?case_uid=${case_uid}`,
  updateUsecaseByUid: () => `${UPDATE_USECASE_BY_UID}`,
  addUsecaseInformation: () => `${ADD_USECASE_INFORMATION}`,
  addUsecaseAuditTrial: () => `${ADD_USECASE_AUDIT_TRAIL}`,
  getUsecaseAuditTrail: (case_uid: string) => `${GET_USECASE_AUDIT_TRAIL}?case_uid=${case_uid}`,
  updateUsecaseCustomField: () => `${UPDATE_USECASE_CUSTOM_FIELDS}`,
  getUsecaseByUid: (case_uid: any) => `${GET_USECASE_BY_UID}?case_uid=${case_uid}`,
  deleteUsecase: () => `${DELETE_USECASE}`,
  getCustomFieldValuesOfAllUsecases: (customer_uid: string) =>
    `${GET_CUSTOM_FIELD_VALUE_FOR_ALL_USECASES}?customer_uid=${customer_uid}`,
  getUsecaseCommentsByCaseUid: (case_uid: any) => `${ALL_USECASE_COMMENTS}?case_uid=${case_uid}`,
  addUsecaseComment: () => ADD_USECASE_COMMENT,
  updateUsecaseLevelComment: () => UPDATE_USECASE_LEVEL_COMMENT,
  deleteUsecaseLevelComment: () => DELETE_USECASE_LEVEL_COMMENT,
};

export const raidRoutes = {
  createRaid: () => `${CREATE_RAID}`,
  getRaidByUid: (raid_uid: string) => `${GET_RAID_BY_UID}?raid_uid=${raid_uid}`,
  updateRaid: () => `${UPDATE_RAID}`,
  deleteRaid: () => `${DELETE_RAID}`,
  bulkDeleteRaid: () => `${BULK_DELETE_RAID}`,
  getRaidByProjectUid: (project_uid: string) => `${GET_RAID_BY_PROJECT_UID}?project_uid=${project_uid}`,
  getRaidComments: (raid_uid: string) => `${GET_RAID_COMMENTS}?raid_uid=${raid_uid}`,
  addRaidComment: () => `${ADD_RAID_COMMENT}`,
  updateRaidComment: () => `${UPDATE_RAID_COMMENT}`,
  deleteRaidComment: () => `${DELETE_RAID_COMMENT}`,
};

export const tasksRoutes = {
  openTasks: (customer_uid: string) => `${OPEN_TASKS}?customer_uid=${customer_uid}`,
  closedTasks: (customer_uid: string) => `${CLOSED_TASKS}?customer_uid=${customer_uid}`,
  getRenewalTasks: () => RENEWAL_TASKS,
  addProjectPhase: () => `${ADD_PROJECT_PHASE}`,
  cloneProjectPhase: () => `${CLONE_PROJECT_PHASE}`,
  cloneUsecasePhase: () => `${CLONE_USECASE_PHASE}`,
  updatePhase: () => `${UPDATE_PHASE}`,
  getJiraPriority: () => `${GET_JIRA_PRIORITY}`,
  getJiraProjects: (customer_uid: string) => `${GET_JIRA_PROJECTS}?customer_uid=${customer_uid}`,
  addProjectTask: () => `${ADD_PROJECT_TASK}`,
  addUsecasePhase: () => `${ADD_USECASE_PHASE}`,
  addUsecaseInformation: () => `${ADD_USECASE_INFORMATION}`,
  addUsecaseTask: () => `${ADD_USECASE_TASK}`,
  getTasksUnderProject: (project_uid: string) => `${GET_TASKS_UNDER_PROJECT}?project_uid=${project_uid}`,
  getProjectSubtask: (task_uid: string) => `${GET_PROJECT_SUBTASK}?task_uid=${task_uid}`,
  addProjectSubtask: () => ADD_PROJECT_SUBTASK,
  addUsecaseSubtask: () => ADD_USECASE_SUBTASK,
  getTaskCustomfieldsWithValue: (task_uid: string) => `${GET_TASK_CUSTOMFIELDS_WITH_VALUE}?task_uid=${task_uid}`,
  getTaskCustomfields: (customer_uid: string) => `${GET_TASK_CUSTOMFIELDS}?customer_uid=${customer_uid}`,
  updateProjectSubtask: () => `${UPDATE_PROJECT_SUBTASK}`,
  updateUsecaseSubtask: () => `${UPDATE_USECASE_SUBTASK}`,
  deleteProjectFromJiraByIssueId: () => `${DELETE_PROJECT_TASK_FROM_JIRA_BY_ISSUE_ID}`,
  deleteProjectSubtask: () => `${DELETE_PROJECT_SUBTASK}`,
  deleteUsecaseSubtask: () => `${DELETE_USECASE_SUBTASK}`,
  getProjectTaskDependency: () => `${GET_PROJECT_TASK_DEPENDENCY}`,
  addProjectTaskDependency: () => `${ADD_PROJECT_TASK_DEPENDENCY}`,
  deleteProjectTaskDependency: () => `${DELETE_PROJECT_TASK_DEPENDENCY}`,
  deleteUsecaseTaskDependency: () => `${DELETE_USECASE_TASK_DEPENDENCY}`,
  addTaskDependency: () => `${ADD_TASK_DEPENDENCY}`,
  getTaskDependency: () => `${GET_TASK_DEPENDENCY}`,
  getTaskByTaskUid: (task_uid: string) => `${GET_TASK_BY_TASKUID}?task_uid=${task_uid}`,
  getTasksOfUser: () => `${GET_ALL_TASKS}`,
  addTaskAuditTrail: () => `${ADD_TASK_AUDIT_TRAIL}`,
  addClientTaskAuditTrail: () => `${ADD_CLIENT_TASK_AUDIT_TRAIL}`,
  addProjectTaskAuditTrail: () => `${ADD_PROJECT_TASK_AUDIT_TRAIL}`,
  addProjectClientTaskAuditTrail: () => `${ADD_PROJECT_CLIENT_TASK_AUDIT_TRAIL}`,
  addProjectTaskComment: () => `${ADD_PROJECT_TASK_COMMENT}`,
  sendProjectTaskCommentEmail: () => SEND_PROJECT_TASK_COMMENT_EMAIL,
  sendUsecaseTaskCommentEmail: () => SEND_USECASE_TASK_COMMENT_EMAIL,
  updateProjectTaskComment: () => `${UPDATE_PROJECT_TASK_COMMENT}`,
  updateProjectClientTaskComment: () => `${UPDATE_PROJECT_CLIENT_TASK_COMMENT}`,
  addProjectClientTaskComment: () => `${ADD_PROJECT_CLIENT_TASK_COMMENT}`,
  addUsecaseClientTaskComment: () => `${ADD_USECASE_CLIENT_TASK_COMMENT}`,
  addUsecaseTaskComment: () => `${ADD_USECASE_TASK_COMMENT}`,
  updateUsecaseTaskComment: () => `${UPDATE_USECASE_TASK_COMMENT}`,
  updateUsecaseClientTaskComment: () => `${UPDATE_USECASE_CLIENT_TASK_COMMENT}`,
  getProjectTaskComments: (task_uid: string) => `${GET_PROJECT_TASK_COMMENTS}?task_uid=${task_uid}`,
  getProjectClientTasksComments: (task_uid: string) => `${GET_PROJECT_CLIENT_TASK_COMMENTS}?task_uid=${task_uid}`,
  getUsecaseTaskComments: (task_uid: string) => `${GET_USECASE_TASK_COMMENTS}?task_uid=${task_uid}`,
  getUsecaseClientTaskComments: (task_uid: string) => `${GET_USECASE_CLIENT_TASK_COMMENTS}?task_uid=${task_uid}`,
  deleteClientTaskComment: () => `${DELETE_CLIENT_TASK_COMMENT}`,
  deleteTaskComment: () => `${DELETE_TASK_COMMENT}`,
  markingPublicOrInternalTaskComment: () => `${MARK_PUBLIC_OR_INTERNAL_TASK_COMMENT}`,
  updateTask: () => `${UPDATE_TASK}`,
  deleteProjectTask: () => `${DELETE_PROJECT_TASK}`,
  deleteProjectClientTask: () => `${DELETE_PROJECT_CLIENT_TASK}`,
  deleteUsecaseTask: () => `${DELETE_USECASE_TASK}`,
  deleteUsecaseClientTask: () => `${DELETE_USECASE_CLIENT_TASK}`,
  deleteProjectSection: () => `${DELETE_PROJECT_SECTION}`,
  deleteUsecaseSection: () => `${DELETE_USECASE_SECTION}`,
  getJiraProjectIssueTypes: () => `${GET_JIRA_PROJECT_ISSUE}`,
  getProjectTaskTimeLog: (task_uid: any) => `${GET_PROJECT_TASK_TIME_LOG}?task_uid=${task_uid}`,
  updateProjectTaskTimeLog: () => `${UPDATE_PROJECT_TASK_TIME_LOG}`,
  getUsecaseTaskTimeLog: (task_uid: any) => `${GET_USECASE_TASK_TIME_LOG}?task_uid=${task_uid}`,
  updateUsecaseTaskTimeLog: () => `${UPDATE_USECASE_TASK_TIME_LOG}`,
  getUsecaseSubtasks: (task_uid: string) => `${GET_USECASE_SUBTASKS}?task_uid=${task_uid}`,
  taskBulkUpdate: () => `${TASK_BULK_UPDATE}`,
  bulkDeleteTask: () => `${BULK_DELETE_TASK}`,
  taskBulkCsatMail: () => `${TASK_BULK_CSAT_MAIL}`,
  deleteTaskFromTemporaryTableAndSendMail: () => `${DELETE_TASK_FROM_TEMPORARY_TABLE_AND_SEND_MAIL}`,
  getAllEligibleTaskOwnerDetailsOfClient: (customer_uid: string, client_uid: string) =>
    `${GET_ALL_ELIGIBLE_TASK_OWNER_DETAILS_OF_CLIENT}?customer_uid=${customer_uid}&client_uid=${client_uid}`,
  teamOpenTasks: (customer_uid: string) => `${TEAM_OPEN_TASKS}?customer_uid=${customer_uid}`,
  teamClosedTasks: (customer_uid: string) => `${TEAM_CLOSED_TASKS}?customer_uid=${customer_uid}`,
  clientOpenTasks: (customer_uid: string) => `${CLIENT_OPEN_TASKS}?customer_uid=${customer_uid}`,
  clientClosedTasks: (customer_uid: string) => `${CLIENT_CLOSED_TASKS}?customer_uid=${customer_uid}`,
  incrementProjectTaskSequence: (project_uid: string, task_uid: string, task_sequence: string, section_uid: string) =>
    `${INCREMENT_PROJECT_TASK_SEQUENCE}?project_uid=${project_uid}&task_uid=${task_uid}&task_sequence=${task_sequence}&section_uid=${section_uid}`,
  incrementProjectSectionSequence: (project_uid: string, section_uid: string, section_sequence: string) =>
    `${INCREMENT_PROJECT_SECTION_SEQUENCE}?project_uid=${project_uid}&section_uid=${section_uid}&section_sequence=${section_sequence}`,
  incrementCaseSectionSequence: (case_uid: string, section_uid: string, section_sequence: string) =>
    `${INCREMENT_CASE_SECTION_SEQUENCE}?case_uid=${case_uid}&section_uid=${section_uid}&section_sequence=${section_sequence}`,
  incrementCaseTaskSequence: (
    project_uid: string,
    case_uid: string,
    task_uid: string,
    task_sequence: string,
    section_uid: string
  ) =>
    `${INCREMENT_CASE_TASK_SEQUENCE}?case_uid=${case_uid}&task_uid=${task_uid}&task_sequence=${task_sequence}&section_uid=${section_uid}&project_uid=${project_uid}`,
  incrementTemplateCaseTaskSequence: (
    template_case_uid: string,
    task_sequence: string,
    template_section_uid: string,
    template_task_uid: string
  ) =>
    `${INCREMENT_TEMPLATE_CASE_TASK_SEQUENCE}?template_case_uid=${template_case_uid}&task_sequence=${task_sequence}&template_section_uid=${template_section_uid}&template_task_uid=${template_task_uid}`,
  incrementTemplateProjectTaskSequence: (
    template_project_uid: string,
    task_sequence: string,
    template_section_uid: string,
    template_task_uid: string
  ) =>
    `${INCREMENT_TEMPLATE_PROJECT_TASK_SEQUENCE}?template_project_uid=${template_project_uid}&task_sequence=${task_sequence}&template_section_uid=${template_section_uid}&template_task_uid=${template_task_uid}`,
  syncProjectTaskToJira: () => `${SYNC_PROJECT_TASK_FROM_JIRA}`,
  syncTaskToJira: () => `${SYNC_TASK_FROM_JIRA}`,
  updateJiraTaskIssue: () => `${UPDATE_JIRA_TASK_ISSUE}`,
  getProjectTaskByTaskUid: (task_uid: string) => `${GET_PROJECT_TASK_BY_TASK_UID}?task_uid=${task_uid}`,
  syncTasksFromJira: () => `${SYNC_JIRA_TASK}`,
  updateTaskCustomField: () => `${UPDATE_TASK_CUSTOM_FIELD}`,
  changeTaskStatus: () => `${CHANGE_TASK_STATUS}`,
  getTaskAuditTrail: (task_uid: string) => `${GET_TASK_AUDIT_TRAIL}?task_uid=${task_uid}`,
  getSubTaskAuditTrail: (subtask_uid: string) => `${GET_SUB_TASK_AUDIT_TRAIL}?subtask_uid=${subtask_uid}`,
  getClientTaskAuditTrail: (task_uid: string) => `${GET_CLIENT_TASK_AUDIT_TRAIL}?task_uid=${task_uid}`,
  getTaskCustomValuesByCustomer: (customer_uid: string) =>
    `${GET_TASK_CUSTOM_VALUES_BY_CUSTOMER}?customer_uid=${customer_uid}`,
  getProjectTaskAuditTrail: (task_uid: string) => `${GET_PROJECT_TASK_AUDIT_TRAIL}?task_uid=${task_uid}`,
  getProjectClientTaskAuditTrail: (task_uid: string) => `${GET_PROJECT_CLIENT_TASK_AUDIT_TRAIL}?task_uid=${task_uid}`,
  updateUsecaseDependencyDueDate: () => `${UPDATE_USECASE_DEPENDENCY_DUE_DATE}`,
  updateUsecaseDependencyDeliveryDate: () => `${UPDATE_USECASE_DEPENDENCY_DELIVERY_DATE}`,
  updateProjectDependencyDueDate: () => `${UPDATE_PROJECT_DEPENDENCY_DUE_DATE}`,
  updateProjectDependencyDeliveryDate: () => `${UPDATE_PROJECT_DEPENDENCY_DELIVERY_DATE}`,
  addSubtaskAuditTrail: () => `${ADD_SUBTASK_AUDIT_TRAIL}`,
  addTaskCommentToJira: () => ADD_COMMENT_TO_JIRA,
  deleteJiraTaskComment: () => DELETE_JIRA_TASK_COMMENT,
};

export const commonRoutes = {
  getCustomJourneyStageData: (customer_uid: string) =>
    `${GET_SETTING_CUSTOM_JOURNEY_STAGE_ROUTES}?customer_uid=${customer_uid}`,
  getJourneyStage: () => JOURNEY_STAGE,
  getIndustryValue: () => INDUSTRY_VALUE,
  getSegmentValue: () => SEGMENT_VALUE,
  getAllUsersOfCompany: (customer_uid: string) => `${COMPANY_USERS}?customer_uid=${customer_uid}`,
  getCustomFields: (customer_uid: string) => `${CUSTOM_FIELDS}?customer_uid=${customer_uid}&&client_uid=`,
  getCustomerDetail: (customer_uid: string) => `${CUSTOMER_DETAIL}?customer_uid=${customer_uid}`,
  updateCaseStatusByCustomer: (customer_uid: string) => `${UPDATE_CUSTOMER_STATUS}?customer_uid=${customer_uid}`,
  getAllClientContactsByCustomerUid: (customer_uid: string) =>
    `${GET_CLIENT_CONTACTS_BY_CUSTOMER_UID}?customer_uid=${customer_uid}`,
  getAllClientsByCustomerUid: (customer_uid: string) =>
    `${GET_ALL_CLIENTS_BY_CUSTOMER_UID}?customer_uid=${customer_uid}`,
  checkEmailPresent: () => CHECK_EMAIL_PRESENT,
  sendForgotPasswordEmail: () => SEND_FORGOT_PASSWORD_EMAIL,
  getUserDetail: (user_uid: string) => `${USER_DETAIL}?user_uid=${user_uid}`,
  sendUserSignupEmail: () => USER_SIGNUP,
  sendUserSignupEmailGmail: () => USER_SIGNUP_GMAIL,
  sendUserSignupNotification: () => USER_SIGNUP_NOTIFICATION,
  sendVerifyEmail: () => VERIFY_EMAIL,
  checkAndValidateStatus: () => VALIDATE_STATUS,
  getUserInfoByUid: (user_uid: string) => `${GET_USER_INFO_BY_UID}?user_uid=${user_uid}`,
  createUserPassword: () => CREATE_USER_PASSWORD,
  completeUserAccount: () => COMPLETE_USER_ACCOUNT,
  getGlobalSearchContentByCustomerUid: () => GLOBAL_SEARCH,
  updatePasswordByUid: () => UPDATE_PASSWORD,
  updateCaseWithFeatureStatus: (client_uid: string) => `${UPDATED_CASE_WITH_FEATURE_STATUS}?client_uid=${client_uid}`,
  mailToSupport: () => MAIL_TO_SUPPORT,
  feedbackMailTosupport: () => FEEDBACK_MAIL_TO_SUPPORT,
  updateTimeTrackingInSettings: () => UPDATE_CUSTOMER_TIME_TRACKING_IN_SETTINGS,
  getRenamedLabels: (customer_uid: string) => `${GET_RENAMED_LABELS}?customer_uid=${customer_uid}`,
  getAllEntitiesOfCustomer: (customer_uid: string) => `${GET_ALL_ENTITIES}}?customer_uid=${customer_uid}`,
  createCustomReport: () => `${CREATE_CUSTOM_REPORT}`,
  getCustomReports: (detail: any) =>
    `${GET_CUSTOM_REPORTS}?customer_uid=${detail.customer_uid}&user_uid=${detail.user_uid}`,
  getReportByUid: (uid: string) => `${GET_REPORT_BY_UID}?report_uid=${uid}`,
  getReportConfig: (uid: string) => `${GET_REPORT_CONFIG}?report_uid=${uid}`,
  updateCustomReport: () => `${UPDATE_CUSTOM_REPORT}`,
  getProjectCategory: (customer_uid: string) => `${GET_PROJECT_CATEGORY}?customer_uid=${customer_uid}`,
  getProjectCustomCategory: (customer_uid: string) => `${GET_CUSTOM_PROJECT_CATEGORY}?customer_uid=${customer_uid}`,
  getProjectStage: (customer_uid: string) => `${GET_PROJECT_STAGES}?customer_uid=${customer_uid}`,
  getProjectCustomStage: (customer_uid: string) => `${GET_CUSTOM_PROJECT_STAGES}?customer_uid=${customer_uid}`,
  getUsecaseCategory: (customer_uid: string) => `${GET_USECASE_CATEGORY}?customer_uid=${customer_uid}`,
  getUsecaseCustomCategory: (customer_uid: string) => `${GET_CUSTOM_USECASE_CATEGORY}?customer_uid=${customer_uid}`,
  getUsecaseStage: (customer_uid: string) => `${GET_USECASE_STAGES}?customer_uid=${customer_uid}`,
  getUsecaseCustomStage: (customer_uid: string) => `${GET_CUSTOM_USECASE_STAGES}?customer_uid=${customer_uid}`,
  saveAppStateConfigurations: () => SAVE_APP_STATE,
  getAppStateConfigurations: (customer_uid: string, user_uid: string) =>
    `${GET_APP_STATE}?customer_uid=${customer_uid}&user_uid=${user_uid}`,
  getProjectPriority: (customer_uid: string) => `${GET_PROJECT_PRIORITIES}?customer_uid=${customer_uid}`,
  getProjectCustomPriority: (customer_uid: string) => `${GET_CUSTOM_PROJECT_PRIORITIES}?customer_uid=${customer_uid}`,

  getUsecasePriority: (customer_uid: string) => `${GET_USECASE_PRIORITIES}?customer_uid=${customer_uid}`,
  getUsecaseCustomPriority: (customer_uid: string) => `${GET_CUSTOM_USECASE_PRIORITIES}?customer_uid=${customer_uid}`,

  getTaskPriority: (customer_uid: string) => `${GET_TASK_PRIORITIES}?customer_uid=${customer_uid}`,
  getTaskCustomPriority: (customer_uid: string) => `${GET_CUSTOM_TASK_PRIORITIES}?customer_uid=${customer_uid}`,

  getTaskStage: (customer_uid: string) => `${GET_TASK_STAGES}?customer_uid=${customer_uid}`,
  getTaskCustomStage: (customer_uid: string) => `${GET_CUSTOM_TASK_STAGES}?customer_uid=${customer_uid}`,
  getStandardAndCustomNames: (customer_uid: string) =>
    `${GET_ALL_STNDARD_AND_CUSTOM_NAMES}?customer_uid=${customer_uid}`,
};

export const attachmentRoutes = {
  addAttachment: () => ADD_ATTACHMENT,
  downloadTemplateDocumentAttachment: () => DOWNLOAD_TEMPLATE_DOCUMENT_ATTACHMENT,
};

export const productRequirementRoutes = {
  getProductAndTheirRequirements: (customer_uid: string) =>
    `${GET_PRODUCT_AND_REQUIREMENT}?customer_uid&customer_uid=${customer_uid}`,
  addNewProductAndItsRequirements: () => ADD_NEW_PRODUCT_AND_REQUIREMENT,
  addNewRequirement: () => ADD_NEW_REQUIREMENT,
  updateNewRequirementRoute: () => UPDATE_NEW_FEATURE,
  deleteRequirementRoute: () => DELETE_REQIREMENT,
  deleteProductRoute: () => DELETE_PRODUCT,
  getRequirementComments: (feature_uid: string) => `${GET_REQUIREMENT_COMMENT}?feature_uid=${feature_uid}`,
  getJiraProductIssueId: () => GET_PRODUCT_ISSUE_ID,
  getJiraCustomFields: () => GET_JIRA_CUSTOM_FIELDS,
  createJiraIssue: () => CREATE_JIRA_ISSUE_FOR_REQUIREMENT,
  updateRequirementIssueId: () => UPDATE_FEATURE_ISSUE_ID,
  addFeatureAttachmentsToJira: () => ADD_FEATURE_ATTACHMENT_TO_JIRA,
  deleteRequirementByDescription: () => DELETE_FEATURE_BY_DESCRIPTION,
  addRequirementComment: () => ADD_REQUIREMENT_COMMENT,
  updateProductByProductUid: () => UPDATE_PRODUCT_BY_PRODUCT_UID,
  deleteRequirementCommentByUid: () => DELETE_REQIREMENT_COMMENT_BY_UID,
  updateRequirementCommentByUid: () => UPDATE_REQUIREMENT_COMMENT_BY_UID,
  createJiraRequirementComment: () => CREATE_JIRA_FEATURE_COMMENT,
  getJiraRequirementComments: (feature_uid: string, issue_id: string, customer_uid: string) =>
    `${GET_JIRA_REQUIREMENT_COMMENTS}?feature_uid=${feature_uid}&jira_issue_id=${issue_id}&customer_uid=${customer_uid}`,
  deleteJiraRequirmentComment: () => DELETE_JIRA_REQUIREMENT_COMMENT,
};

export const actionableInsightRoutes = {
  getProjectsRevenueByCustomerUid: (customer_uid: string) => `${GET_PROJECT_REVENUE}?customer_uid=${customer_uid}`,
  getAllProjectsByCustomerUid: (customer_uid: string) => `${GET_PROJECT_LIST}?customer_uid=${customer_uid}`,
  getRecommendationsByCustomerUid: (customer_uid: string) => `${GET_RECOMMENDATIONS}?customer_uid=${customer_uid}`,
  getReportListWithAllCaseGreen: (customer_uid: string) =>
    `${REPORT_LIST_WITH_ALL_CASE_GREEN}?customer_uid=${customer_uid}&case_readinessstate_status=green`,
  getReportListWithAllCaseNoneGreen: (customer_uid: string) =>
    `${REPORT_LIST_WITH_ALL_CASE_NONE_GREEN}?customer_uid=${customer_uid}&case_readinessstate_status=green`,
  getTop3LandUsecaseMappingByCustomerUid: (customer_uid: string) =>
    `${GET_TOP3_LAND_USECASE_CLIENT_MAPPING}?customer_uid=${customer_uid}`,
  getTop3ExpandUsecaseMappingByCustomerUid: (customer_uid: string) =>
    `${GET_TOP3_EXPAND_USECASE_CLIENT_MAPPING}?customer_uid=${customer_uid}`,
  getTopLandProductMappingByCustomerUid: (customer_uid: string) =>
    `${GET_TOP_LAND_PRODUCT_CLIENT_MAPPING}?customer_uid=${customer_uid}`,
  getTopExpandProductMappingByCustomerUid: (customer_uid: string) =>
    `${GET_TOP_EXPAND_PRODUCT_CLIENT_MAPPING}?customer_uid=${customer_uid}`,
  getProjectRecommendationsByCustomerUid: (customer_uid: string) =>
    `${GET_PROJECT_RECOMMENDATIONS}?customer_uid=${customer_uid}`,
  getUsecaseRecommendationsByCustomerUid: (customer_uid: string) =>
    `${GET_USECASE_RECOMMENDATIONS}?customer_uid=${customer_uid}`,
  getTaskRecommendationsByCustomerUid: (customer_uid: string) =>
    `${GET_TASK_RECOMMENDATIONS}?customer_uid=${customer_uid}`,
  getTemplateInfoByTemplateUid: (template_uid: string, customer_uid: string) =>
    `${GET_TEMPLATE_INFO}?template_case_uid=${template_uid}&customer_uid=${customer_uid}`,
  getClientsByMultipleFeatureUids: () => `${GET_CLIENT_INFO_BY_FEATURE_UIDS}`,
  getUsecasesWhereDeliveryManagerNotAssigned: (customer_uid: string, user_uid: string) =>
    `${GET_USECASES_WHERE_DELIVERY_MANAGER_IS_NOT_ASSIGNED}?customer_uid=${customer_uid}&user_username=${user_uid}`,
  getProjectWhereDeliveryManagerNotAssigned: (customer_uid: string, user_uid: string) =>
    `${GET_PROJECT_WHERE_DELIVERY_MANAGER_IS_NOT_ASSIGNED}?customer_uid=${customer_uid}&user_username=${user_uid}`,
};

// SETTINGS ROUTESS
export const settingRoutes = {
  enableMandatorySSO: () => ENABLE_SSO_MANDATORY,
  disableMandatorySSO: () => DISABLE_SSO_MANDATORY,
  getCustomJourneyStageData: (customer_uid: string) =>
    `${GET_SETTING_CUSTOM_JOURNEY_STAGE_ROUTES}?customer_uid=${customer_uid}`,
  getJourneyStage: () => JOURNEY_STAGE,
  updateTableForFirstTime: () => UPDATE_CUSTOM_JOURNEY_STAGE,
  updateTable: () => UPDATE_CUSTOM_JOURNEY_STAGE_EXISTING,
  getCustomerDetails: (customer_uid: string) => `${GET_CUSTOMER_DETAILS}?customer_uid=${customer_uid}`,
  updateUsecaseInConfigurationRoute: () => UPDATE_USECASE_IN_CONFIGURATION,
  getUserDetaisRoute: (user_uid: string) => `${GET_USER_DETALS_ROUTE}?user_uid=${user_uid}`,
  updateUsernameRoute: () => UPDATE_USERNAME,
  updateUserEmail: () => UPDATE_USER_EMAIL,
  updateUserPassword: () => UPDATE_USER_PASSWORD,
  getCustomField_ClientRecords: (customer_uid: string) =>
    `${CUSTOM_FIELD_CLIENT_RECORD}?customer_uid=${customer_uid}&client_uid=`,
  getCustomField_ProjectRecords: (customer_uid: string) =>
    `${CUSTOM_FIELD_PROJECT_RECORD}?customer_uid=${customer_uid}`,
  getCustomFIeld_UsecaseRecord: (customer_uid: string) => `${CUSTOM_FIELD_USECASE_RECORD}?customer_uid=${customer_uid}`,
  getCustomFIeld_TaskRecord: (customer_uid: string) => `${CUSTOM_FIELD_TASK_RECORD}?customer_uid=${customer_uid}`,
  deleteExistingProduct: () => DELETE_EXISTING_PRUDUCT,
  getmergeLinkToken: () => GET_MERGE_LINK_TOKEN,
  swapPublicToken: () => SWAP_MERGE_PUBLIC_TOKEN,
  updateCustomerPipedriveIntegration: () => UPDATE_CUSTOMER_INTEGRATION_PIPEDRIVE,
  getCustomerIntegrationDetails: (customer_uid: string) =>
    `${CUSTOMER_INTEGRATION_DETAILS}?customer_uid=${customer_uid}`,
  getCustomerIntegrationMicroService: (customer_uid: string) => `${CUSTOMER_INTEGRATION}?customer_uid=${customer_uid}`,
  getCRMStages: (customer_uid: string) => `${CRM_STAGES}?customer_uid=${customer_uid}`,
  updateStageAndFetchMode: () => UPDATE_STAGE_AND_FETCH_MODE,
  saveJiraCredentials: () => SAVE_JIRA_CREDENTIAL,
  updateJiraFetchMode: () => UPDATE_JIRA_FETCH_MODE,
  updateJiraTaskMode: () => UPDATE_JIRA_TASK_MODE,
  addImplementationtaskKey: () => ADD_IMPLEMENTATION_TASK_KEY,
  getNotificationData: () => GET_NOTIFICATION_DATA,
  getAllTasks: () => GET_ALL_TASKS,
  getCustomViewColumn: () => GET_CUSTOM_VIEW_COLUMNS,
  updateClientRecordViewColumns: () => UPDATE_CLIENT_RECORD_VIEW_COLUMNS,
  addCustomFieldProjectByCustomerUid: () => ADD_CUSTOM_FIELD_POJECT_BY_CUSTOMER_UID,
  updateCustomFieldRAGCheckBoxStatus: () => UPDATE_CUSTOM_FIELD_RAG_CHECK_BOX_STATUS,
  addSubFieldforCustomFieldProject: () => ADD_SUB_FIELD_FOR_CUSTOM_FIELD_PROJECT,
  addCustomFieldByCustomerUid: () => ADD_CUSTOM_FIELD_BY_CUSTOMER_UID,
  addCustomFieldUsecaseByCustomerUid: () => ADD_CUSTOM_FIELD_USECASE_BY_CUSTOMER_UID,
  addCustomFieldTaskByCustomerUid: () => ADD_CUSTOM_FIELD_TASK_BY_CUSTOMER_UID,
  updateJiraUsernameAndCustomerToken: () => UPDATE_JIRA_USERNAME_AND_CUSTOMER_TOKEN,
  updateJiraAccountIdForUsers: () => UPDATE_JIRA_ACCOUT_ID_BY_USERS,
  addProjectKeyToCustomer: () => ADD_JIRA_PROJECT_TO_CUSTOMER,
  addJiraProductStageCustomStage: () => ADD_JIRA_PRODUCT_STAGE_CUSTOM_STAGE,
  getJiraProjectFromCustomerUid: (customer_uid: string) => `${GET_PROJECT_FROM_CUSTOMER}?customer_uid=${customer_uid}`,
  deleteCustomerProject: () => DELETE_CUSTOMER_PROJECT,
  removeJiraTokenByCustomerUid: () => UPDATE_REMOVE_JIRA_TOKEN,
  removeJiraLinkFromTask: () => REMOVE_JIRA_LINK_FROM_TASK,
  deleteWholeCustomerProduct: () => DELETE_WHOLE_CUSTOMER_PROJECT,
  updateCustomFieldByCustomerUid: () => UPDATE_CUSTOM_FIELD_BY_CUSTOMER_UID,
  jiraProductCustomStage: () => JIRA_PRODUCT_CUSTOM_STAGE,
  jiraTaskCustomStage: (customer_uid: string, project_key: string) =>
    `${JIRA_TASK_CUSTOM_STAGE}?customer_uid=${customer_uid}&project_key=${project_key}`,
  updateJiraProductCustomStage: () => UPDATE_JIRA_PRODUCT_CUSTOM_STAGE,
  updateJiraTaskStage: () => UPDATE_JIRA_TASK_STAGE,
  updateCustomFieldMandatoryStatus: () => UPDATE_CUSTOM_MANDATORY_STATUS,
  updateCustomFieldAttachmentStatus: () => UPDATE_CUSTOM_ATTACHMENT_STATUS,
  updateCustomProjectField: () => UDPATE_CUSTOM_PROJECT_FIELD,
  updateCustomUsecaseField: () => UDPATE_CUSTOM_USECASE_FIELD,
  updateCustomTaskField: () => UDPATE_CUSTOM_TASK_FIELD,
  deleteCustomProjectField: () => DELETE_CUSTOM_PROJECT_FIELD,
  deleteCustomUsecaseField: () => DELETE_CUSTOM_USECASE_FIELD,
  deleteCustomTaskField: () => DELETE_CUSTOM_TASK_FIELD,
  deleteCustomClientField: () => DELETE_CUSTOM_CLIENT_FIELD,
  updateCustomerHolidays: () => UPDATE_CUSTOMER_HOLIDAYS,
  updateMagicLinkDuration: () => UPDATE_MAGIC_LINK_DURATION,
  getEmailConfig: (customer_uid: string) => `${GET_EMAIL_CONFIG}?customer_uid=${customer_uid}`,
  addEmailConfig: () => ADD_EMAIL_CONFIG,
  deleteEmailConfig: () => DELETE_EMAIL_CONFIG,
  changeEmailConfig: () => CHANGE_EMAIL_CONFIG,
  updateCustomLabelvalues: () => UPDATE_CUSTOM_LABEL_VALUE,
  insertCustomLabelValues: () => INSERT_CUSTOM_LABEL,
  updateRevenueReportBasis: () => UPDATE_REVENUE_REPORT_BASIS,
  updateCustomSegmentValue: () => UPDATE_CUSTOM_SEGMENT_VALUE,
  getCustomSegmentValue: (customer_uid: string) => `${GET_CUSTOM_SEGMENT_VALUE}?customer_uid=${customer_uid}`,
  sendEmailToUsersWithCustomMessage: () => SEND_EMAIL_TO_USERS_WITH_CUSTOM_MESSAGE,
  updateCustomProjectCategoryValue: () => UPDATE_CUSTOM_PROJECT_CATEGORY,
  updateCustomProjectStageValue: () => UPDATE_CUSTOM_PROJECT_STAGE,
  updateCustomUsecaseCategoryValue: () => UPDATE_CUSTOM_USECASE_CATEGORY,
  updateCustomUsecaseStageValue: () => UPDATE_CUSTOM_USECASE_STAGE,
  getCrmCustomFieldsFromIntegrations: (customer_uid: string) =>
    `${CRM_INTEGRATION_CUSTOM_FIELDS}?customer_uid=${customer_uid}`,
  getCrmCustomFieldsFromDb: (customer_uid: string) => `${CRM_CUSTOM_FIELDS_FROM_DB}?customer_uid=${customer_uid}`,
  updateCrmCustomfieldsInDB: () => UPDATE_CRM_CUSTOM_FIELD_IN_DB,
  updateCustomProjectPriorityValue: () => UPDATE_CUSTOM_PROJECT_PRIORITY,
  updateCustomUsecasePriorityValue: () => UPDATE_CUSTOM_USECASE_PRIORITY,
  updateCustomTaskPriorityValue: () => UPDATE_CUSTOM_TASK_PRIORITY,
  updateCustomTaskStageValue: () => UPDATE_CUSTOM_TASK_STAGE,
};

// customer level routes
export const customerRoutes = {
  allClientProjectMember: (customer_uid: string) =>
    `${GET_ALL_CLIENT_CONTACT_PROJECT_MEMBERS}?customer_uid=${customer_uid}`,
};

// aggregated cards reports
export const aggregationCardRoutes = {
  createAggregatedCard: () => CREATE_AGGREGATED_CARD,
  updateAggregatedCard: () => UPDATE_AGGREGATED_CARD,
  getAllAggregatedCards: (customer_uid: string, user_uid: string) =>
    `${GET_ALL_AGGREGATED_CARDS}?customer_uid=${customer_uid}&user_uid=${user_uid}`,
  cloneAggregatedCard: () => CLONE_AGGREGATED_CARD,
  deleteAggregatedCard: () => DELETE_AGGREGATED_CARD,
};

export const customerPortalRoutes = {
  client_getProjectsWithTasks: (client_uid: string) => `${CLIENT_GET_PROJECTS_WITH_TASK}?client_uid=${client_uid}`,
  clientCheckClient: () => CLIENT_CHECK,
  client_userSignIn_withjwttoken: () => CLIENT_USER_SIGN_IN_WITH_JWT_TOKEN,
  client_getClientinformation: () => GET_CLIENT_iNFORMATION,
  client_updatedcasewithfeaturestatusClient: () => UPDATED_CASE_WITH_FEATURE_STATUS,
  client_getsingleProjectInformationWithTaskClientUser: (project_uid: string) =>
    `${GET_SINGLE_PROJECT_INFORMATION}?project_uid=${project_uid}`,
  client_getsingleUsecaseInformationWithTaskClientUser: (usecase_uid: any) =>
    `${GET_SINGLE_USECASE_INFORMATION}?case_uid=${usecase_uid}`,
  getclient_getProjectsWithTasks: (client_uid: string) => `${CLIENT_GET_PROJECTS_WITH_TASK}?client_uid=${client_uid}`,
  client_getProjectUpgradationAlert: (client_uid: string) =>
    `${GET_CLIENT_PROJECT_UPGRADATION_ALERT}?client_uid=${client_uid}`,
  client_getUsecaseUpgradationAlert: (client_uid: string) =>
    `${GET_CLIENT_USECASE_UPGRADATION_ALERT}?client_uid=${client_uid}`,
  client_getProjectDegradationAlert: (client_uid: string) =>
    `${GET_CLIENT_PROJECT_DEGRADATION_ALERT}?client_uid=${client_uid}`,
  client_getUsecaseDegradationAlert: (client_uid: string) =>
    `${GET_CLIENT_USECASE_DEGRADATION_ALERT}?client_uid=${client_uid}`,
  client_getProjectStatusCountByClientUid: (client_uid: string) =>
    `${GET_CLIENT_PROJECT_STATUS_COUNT_BY_CLIENT_UID}?client_uid=${client_uid}`,
  client_getTaskStatusCountByClientUid: (client_uid: string) =>
    `${GET_CLIENT_TASK_STATUS_COUNT_BY_CLIENT_UID}?client_uid=${client_uid}`,
  client_getUseCaseStatusCount: (client_uid: string, customer_uid: string) =>
    `${GET_CLIENT_USECASE_STATUS_COUNT}?client_uid=${client_uid}&customer_uid=${customer_uid}`,
  getAllUsersOfCompany: (customer_uid: string) => `${GET_CLIENT_ALL_USERS_BY_COMPANY}?customer_uid=${customer_uid}`,
  getClientContactsByContactUid: (contact_uid: string) =>
    `${GET_CLIENT_CONTACTS_BY_CONTACT_UID}?contact_uid=${contact_uid}`,
  client_getClientContactsByClientUid: (client_uid: string) =>
    `${GET_CLIENT_CONTACTS_BY_CLIENT_UID}?client_uid=${client_uid}`,
  client_addProjectTask: () => CLIENT_ADD_PROJECT_TASK,
  client_addUsecaseTask: () => CLIENT_ADD_USECASE_TASK,
  client_updateProjecttaskbyuid: () => CLIENT_UPDATE_PROJECT_TASK_BY_TASK_UID,
  client_updateUsecasetaskbyuid: () => CLIENT_UPDATE_USECASE_TASK_BY_TASK_UID,
  client_addProjectTaskAuditTrail: () => CLIENT_ADD_PROJECT_TASK_AUDIT_TRAIL,
  client_sendmagiclink: () => CLIENT_SEND_MAGIC_LINK,
  client_getTaskByTaskUid: (task_uid: string) => `${CLIENT_GET_TASK_DETAIL}?task_uid=${task_uid}`,
  client_getClientinformationapi: (client_uid: string) => `${GET_CLIENT_iNFORMATION}?client_uid=${client_uid}`,
  client_getCustomerDetail: (customer_uid: string) => `${CLIENT_GET_CUSTOMER_DETAIL}?customer_uid=${customer_uid}`,
  client_updateTaskAcknowledgedStatus: () => CLIENT_UPDATE_TASK_ACKNOWLEDGED_STATUS,
};

export const dashboardRoutes = {
  getCountByUserUid: (customer_uid: string, user_uid: string) =>
    `${GET_COUNT_BY_USER_UID}?customer_uid=${customer_uid}&user_uid=${user_uid}`,
  getTeamCountByUserUid: (customer_uid: string, user_uid: string) =>
    `${GET_TEAM_COUNT_BY_USER_UID}?customer_uid=${customer_uid}&user_uid=${user_uid}`,
  getUseCaseCountUid: (customer_uid: string, user_uid: string) =>
    `${GET_USECASE_COUNT}?customer_uid=${customer_uid}&user_uid=${user_uid}`,
  getTeamUseCaseCountUid: (customer_uid: string, user_uid: string) =>
    `${GET_TEAM_USECASE_COUNT}?customer_uid=${customer_uid}&user_uid=${user_uid}`,
  getTaskStatusCount: (customer_uid: string, user_uid: string) =>
    `${GET_TASK_STATUS_COUNT}?user_uid=${user_uid}&customer_uid=${customer_uid}`,
  getTeamTaskStatusCount: (customer_uid: string, user_uid: string) =>
    `${GET_TEAM_TASK_STATUS_COUNT}?user_uid=${user_uid}&customer_uid=${customer_uid}`,
  getProjectStatusCountByCustomerUid: (customer_uid: string, user_uid: string) =>
    `${GET_PROJECT_STATUS_COUNT}?customer_uid=${customer_uid}&user_uid=${user_uid}`,
  getTeamProjectStatusCountByCustomerUid: (customer_uid: string, user_uid: string) =>
    `${GET_TEAM_PROJECT_STATUS_COUNT}?customer_uid=${customer_uid}&user_uid=${user_uid}`,
  getClientUpgradationAlert: (user_uid: string) => `${GET_CLIENT_UPGRADATION_ALERT}?user_uid=${user_uid}`,
  getTeamClientUpgradationAlert: (user_uid: string) => `${GET_TEAM_CLIENT_UPGRADATION_ALERT}?user_uid=${user_uid}`,
  getProjectUpgradationAlert: (user_uid: string) => `${GET_PROJECT_UPGRADATION_ALERT}?user_uid=${user_uid}`,
  getTeamProjectUpgradationAlert: (user_uid: string) => `${GET_TEAM_PROJECT_UPGRADATION_ALERT}?user_uid=${user_uid}`,
  getUsecaseUpgradationAlert: (user_uid: string) => `${GET_USECASE_UPGRADATION_ALERT}?user_uid=${user_uid}`,
  getTeamUsecaseUpgradationAlert: (user_uid: string) => `${GET_TEAM_USECASE_UPGRADATION_ALERT}?user_uid=${user_uid}`,
  getClientDegradationAlert: (user_uid: string) => `${GET_CLIENT_DEGRADATION_ALERT}?user_uid=${user_uid}`,
  getTeamClientDegradationAlert: (user_uid: string) => `${GET_TEAM_CLIENT_DEGRADATION_ALERT}?user_uid=${user_uid}`,
  getProjectDegradationAlert: (user_uid: string) => `${GET_PROJECT_DEGRADATION_ALERT}?user_uid=${user_uid}`,
  getTeamProjectDegradationAlert: (user_uid: string) => `${GET_TEAM_PROJECT_DEGRADATION_ALERT}?user_uid=${user_uid}`,
  getUsecaseDegradationAlert: (user_uid: string) => `${GET_USECASE_DEGRADATION_ALERT}?user_uid=${user_uid}`,
  getTeamUsecaseDegradationAlert: (user_uid: string) => `${GET_TEAM_USECASE_DEGRADATION_ALERT}?user_uid=${user_uid}`,
  getUserDetail: (user_uid: string) => `${USER_DETAIL}?user_uid=${user_uid}`,
  getAllTeamTasksByUseruidForCustomer: (customer_uid: string, user_uid: string) =>
    `${GET_ALL_TEAM_TASK_FOR_CUSTOMER}?customer_uid=${customer_uid}&user_uid=${user_uid}`,
  getAllReporties: (user_uid: string) => `${GET_ALL_REPORTEES}?user_uid=${user_uid}`,
  getClientsbyUserTeam: (customer_uid: string, user_uid: string) =>
    `${GET_TEAM_CLIENTS}?customer_uid=${customer_uid}&user_uid=${user_uid}`,
};

export const templateRoutes = {
  getProjectTemplates: (customer_uid: string) => `${GET_PROJECT_TEMPLATES}?customer_uid=${customer_uid}`,
  getUseCaseTemplatesbycustomer: (customer_uid: string) => `${GET_USECASE_TEMPLATES}?customer_uid=${customer_uid}`,
  addTemplateProject: () => ADD_PROJECT_TEMPLATE,
  getCustDetails: (customer_uid: string) => `${CUSTOMER_DETAIL}?customer_uid=${customer_uid}`,
  saveTemplateProjectSection: () => SAVE_TEMPLATE_PROJECT_SECTION,
  getAllSectionByTemplateProjectuid: () => GET_ALL_PROJECT_SECTIONS_BY_PROJECT_UID,
  addTemplateProjectTask: () => ADD_TEMPLATE_PROJECT_TASK,
  addTemplateProjectClientTask: () => ADD_TEMPLATE_PROJECT_CLIENT_TASK,
  updateTemplateProjectByUid: () => UPDATE_TEMPLATE_PROJECT_BY_UID,
  addTemplateUseCase: () => ADD_TEMPLATE_USECASE,
  saveTemplateUsecaseSection: () => SAVE_TEMPLATE_USECASE_SECTION,
  getAllSectionByTemplateCaseuid: () => GET_ALL_SECTIONS_BY_TEMPLATE_UID,
  addTemplateUsecaseTask: () => ADD_TEMPLATE_USECASE_TASK,
  addTemplateUsecaseClientTask: () => ADD_TEMPLATE_USECASE_CLIENT_TASK,
  updateTemplateUsecaseByUid: () => UPDATE_TEMPLATE_USECASE_BY_UID,
  syncProductsandFeaturesFromJIRAbyOneCustomer: (customer_uid: string) =>
    `${SYNC_PRODUCT_AND_FEATURES_FROM_JIRA_BY_ONE_CUSTOMER}?customer_uid=${customer_uid}`,
  getTemplateProjectInformation: (project_uid: string) =>
    `${GET_TEMPLATE_PROJECT_INFORMATION_WITH_TASK}?template_project_uid=${project_uid}`,
  getTemplateProjectinformation: (template_project_uid: string) =>
    `${GET_TEMPLATE_PROJECT_INFORMATION}?template_project_uid=${template_project_uid}`,
  getTemplateUsecaseInformation: (case_uid: string) =>
    `${GET_TEMPLATE_CASE_INFORMATIONM}?template_case_uid=${case_uid}`,
  deleteTemplateProjectbyuid: () => DELETE_TEMPLATE_PROJECT_BY_UID,
  deleteTemplateUsecasebyuid: () => DELETE_TEMPLE_USECASE_BY_UID,
  deleteUsecaseTemplatesectionByUid: () => DELETE_USECASE_TEMPLATE_SECTION_BY_UID,
  deleteProjectTemplatesectionByUid: () => DELETE_PROJECT_TEMPLATE_SECTION_BY_UID,
  updateTemplateProjectTaskbyuid: () => UPDATE_TEMPLATE_PROJECT_TASK_BY_UID,
  updateTemplateUsecaseTaskbyuid: () => UPDATE_TEMPLATE_USECASE_TASK_BY_UID,
  updateTemplateProjectClienttaskByuid: () => UPDATE_TEMPLATE_PROJECT_CLIENT_TASK_BY_UID,
  updateTemplateUsecaseClienttaskByuid: () => UPDATE_TEMPLATE_USECASE_CLIENT_TASK_BY_UID,
  deleteTemplateProjectTaskbyUid: () => DELETE_TEMPLATE_POJECT_TASK_BY_UID,
  deleteTemplateProjectClientTaskbyUid: () => DELETE_TEMPLATE_PROJECT_CLIENT_TASK_BY_UID,
  deleteTemplateUsecaseTaskbyUid: () => DELETE_TEMPLATE_USECASE_TASK_BY_UID,
  deleteTemplateusecaseClientTaskbyUid: () => DELETE_TEMPLATE_USECASE_CLIENT_TASK_BY_UID,
  getTemplateProjectTasks: (task_uid: string) => `${GET_TEMPLATE_PROJECT_TASK}?template_project_uid=${task_uid}`,
  getTemplateProjectClientTasks: (task_uid: string) =>
    `${GET_TEMPLATE_PROJECT_CLIENT_TASK}?template_project_uid=${task_uid}`,
  getTemplateUsecaseTasks: (task_uid: string) => `${GET_TEMPLATE_USECASE_TASK}?template_case_uid=${task_uid}`,
  getTemplateUsecaseClientTasks: (task_uid: string) =>
    `${GET_TEMPLATE_USECASE_CLIENT_TASK}?template_case_uid=${task_uid}`,

  cloneProjectTemplate: () => CLONE_PROJECT_TEMPLATE,
  cloneUsecaseTemplate: () => CLONE_USECASE_TEMPLATE,
  addTemplateDocument: () => CREATE_TEMPLATE_DOCUMENT,
  deleteTemplateDocument: () => DELETE_TEMPLATE_DOCUMENT,
  getTemplateDocumentByCustomerUid: (customer_uid: string) =>
    `${GET_TEMPLATE_DOCUMENTS_BY_CUSTOMER_UID}?customer_uid=${customer_uid}`,
  sendTaskAssignmentMailAfterImportingTemplate: () => SEND_TASK_ASSIGNMENT_MAIL_AFTER_IMPORTING_TEMPLATE,
  incrementTemplateProjectSection: (project_uid: string, section_sequence: string, section_uid: string) =>
    `${INCREMENT_TEMPLATE_PROJECT_SECTION}?project_uid=${project_uid}&section_sequence=${section_sequence}&section_uid=${section_uid}`,
  incrementTemplateCaseSection: (case_uid: string, section_sequence: string, section_uid: string) =>
    `${INCREMENT_TEMPLATE_CASE_SECTION}?case_uid=${case_uid}&section_sequence=${section_sequence}&section_uid=${section_uid}`,
  updateTemplatePhase: () => UPDATE_TEMPLATE_PHASE,
  getTemplateProjectDependency: (project_uid: string) =>
    `${GET_PROJECT_TEMPLATE_DEPENDENCY}?project_uid=${project_uid}`,

  getTemplateUsecaseDependency: (case_uid: string) => `${GET_USECASE_TEMPLATE_DEPENDENCY}?case_uid=${case_uid}`,
};

export const rbacRoutes = {
  getAllUsersAndRoles: () => GET_ALL_USERS_AND_ROLES,
  getResourcesField: () => GET_RESOURCES_FIELD,
  getAllResources: () => GET_ALL_RESOURCES,
  getResourceFieldValues: () => GET_RESOURCE_FIELD_VALUE,
  getGeneralFieldValue: () => GET_GENERAL_FIELD_VALUE,
  getAllPage: () => GET_ALL_PAGES,
  getAllPermissions: () => GET_ALL_PERMISSIONS,
  getValidReporteeAdditions: () => GET_ALL_VALID_REPORTEE_ADDITIONS,
  getPolicyOnResources: () => GET_POLICY_ON_RESOURCES,
  addUser: () => ADD_USER,
  addRole: () => ADD_ROLE,
  deactivateUser: () => DEACTIVATE_USER_RBAC,
  activateUser: () => ACTIVATE_USER_RBAC,
  addPolicyToRole: () => ADD_POLICY_TO_ROLE,
  removePoliciesFromRole: () => REMOVE_POLICY_FROM_ROLE,
  addPagesToRole: () => ADD_PAGES_TO_ROLE,
  removePagesFromRole: () => REMOVE_PAGES_FROM_ROLE,
  changeUserRole: () => CHANGE_USER_ROLE,
  getAllRoles: () => GET_ALL_ROLES,
  createPolicy: () => ADD_POLICY,
  getAllConditions: () => GET_ALL_CONDITIONS,
  removeCondition: () => REMOVE_CONDITIONS,
  addConditionToPolicy: () => ADD_CONDITION_TO_POLICY,
  createCondition: () => CREATE_CONDITION,
  getConditions: () => GET_CONDITIONS,
  enableRbac: () => ENABLE_RBAC_GLOBALLY,
  disableRbac: () => DISABLE_RBAC_GLOBALLY,
  addReportsTo: () => ADD_REPORTS_TO,
  removeReportsTo: () => REMOVE_REPORTS_TO,
  getRbacToggleAffectingUsers: () => GET_RBAC_AFFECTING_USER,
  bootstrapRBACWithDefaultValue: () => BOOTSTRAP_RBAC_WITH_DEFAULT_ROLE,
  updateUsernameOfUser: () => UPDATE_USERNAME_OF_USER,
  getRole: () => GET_ROLE,
};

export const resourceManagementRoutes = {
  getAllDeliveryManagerDetailsWithProjectAndTaskUsingCustomerUid: (customer_uid: string) =>
    `${GET_ALL_DELIVERY_MANAGER_DETAILS_WITH_PROJECT_AND_TASK_USING_CUSTOMER_UID}?customer_uid=${customer_uid}`,

  getDataForResourceManagement: (customer_uid: string) =>
    `${BULK_DATA_FOR_RESOURCE_MANAGEMENT}?customer_uid=${customer_uid}`,

  getResourceManagementRawUserData: (customer_uid: string) =>
    `${GET_RESOURCE_MANAGEMENT_USER_DATA}?customer_uid=${customer_uid}`,
  getResourceManagementRawProjectData: (customer_uid: string) =>
    `${GET_RESOURCE_MANAGEMENT_PROJECT_DATA}?customer_uid=${customer_uid}`,
  getResourceManagementRawTaskData: (customer_uid: string) =>
    `${GET_RESOURCE_MANAGEMENT_TASK_DATA}?customer_uid=${customer_uid}`,
};

export const softAllocationRoutes = {
  getSoftAllocationByUserUid: (user_uid: string) => `${GET_SOFT_ALLOCATION_BY_USER_UID}?user_uid=${user_uid}`,
  addSoftAllocation: () => `${ADD_SOFT_ALLOCATION}`,
  updateSoftAllocation: () => `${UPDATE_SOFT_ALLOCATION}`,
  deleteSoftAllocation: () => `${DELETE_SOFT_ALLOCATION}`,
};

export const graphRoutes = {
  addGraphConfiguration: () => ADD_GRAPH_CONFIGURATION,
  deleteGraphConfiguration: () => DELETE_GRAPH_CONFIGURATION,
  updateGraphConfiguration: () => UPDATE_GRAPH_CONFIGURATION,
  getGraphConfiguration: (type: string, user_uid: string, customer_uid: string) =>
    `${GET_GRAPH_CONFIGURATION}?type=${type}&user_uid=${user_uid}&customer_uid=${customer_uid}`,
};

export const pictureRoutes = {
  addCustomerLogo: () => ADD_CUSTOMER_LOGO,
  updateCustomerLogo: () => UPDATE_CUSTOMER_LOGO,

  addCustomerClientLogo: () => ADD_CUSTOMER_CLIENT_LOGO,
  updateCustomerClientLogo: () => UPDATE_CUSTOMER_CLIENT_LOGO,

  addUserLogo: () => ADD_USER_LOGO,
  updateUserLogo: () => UPDATE_USER_LOGO,
};

export const adminPanel = {
  getAllCustomerDetails: () => ALL_CUSTOMER_DETAILS,
  getAllClientCounts: () => GET_CLIENT_COUNTS,
  getUserCount: () => GET_USER_COUNT,
  getAllUserActivity: () => GET_USER_ACTIVITIES,
  deleteCustomer: (customer_uid: string) => `${DELETE_CUSTOMER}?customer_uid=${customer_uid}`,
  adminLogin: () => ADMIN_LOGIN,
  otpVerification: (payload: any) => `${OTP_VERIFICATION}?otp=${payload}`,
  getProjectCount: () => GET_PROJECT_COUNT,
  getUsecaseCount: () => GET_USECASE_COUNT_ADMIN_PANEL,
  saveAdminToken: () => SAVE_ADMIN_TOKEN,
  checkAdminToken: () => CHECK_ADMIN_TOKEN,
  getProjectCountByUsers: () => GET_PROJECT_COUNT_USERS,
  getUsecaseCountByUsers: () => GET_USECASE_COUNT_USERS,
};

export const jiraRoutes = {
  syncProductsandFeaturesFromJIRAbyOneCustomer: (customer_uid: string) =>
    `${SYNC_PRODUCT_AND_FEATURES_FROM_JIRA_BY_ONE_CUSTOMER}?customer_uid=${customer_uid}`,
  getJiraStatusByProjectKey: () => GET_JIRA_PROJECT_STATUSES,
  getProductByIssueId: (customer_uid: string, product_issue_id: string) =>
    `${GET_PRODUCT_BY_ISSUE_ID}?customer_uid=${customer_uid}&product_issue_id=${product_issue_id}`,
  getJiraServiceDesk: () => GET_SERVICE_DESK,
  getJiraJSDprojectRequestList: () => GET_JSD_SERVICE_REQUEST_LIST,
  getJiraJsdCustomFields: () => GET_JIRA_JSD_CUSTOM_FIELDS,
};
