import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { selectProjectsAtRisk } from '../../../store/modules/ActionableInsights/selector';
import { useAppSelector } from '../../../store/hooks';
import { ReactComponent as LeftArrowIcon } from '../../../assets/svgs/leftArrow.svg';
// import CognisaasTable, { ColumnMapping } from '../../shared/CognisaasTable/CognisaasTable';
import { FilterableData } from '../../../utils/filteringFunctions';
import { useEffect, useState } from 'react';
import FilterTypeSelector from '../../shared/CognisaasFilters/FilterTypeSelector/FilterTypeSelector';
import { useDispatch } from 'react-redux';
import { addFilterGroup } from '../../../store/modules/AllFilters/slice';
import SelectedFilterList from '../../shared/CognisaasFilters/SelectedFilterList/SelectedFilterList';
import { generateMenuOptions } from '../../../store/modules/ui/CommonData/slice';
import { selectAllFilters } from '../../../store/modules/AllFilters/selector';
import { AvatarCell, CostCell, FieldWithStatus } from '../ActionableInsightTable/Table';
import CogniTable from '../ActionableInsightTable/Table';
import { isNull, isUndefined } from 'utils/dataHelpers';
import {
  selectAllCategories,
  selectSegmentValues,
  selectUsersOfCompany,
  selectUsersOfCompanyStatus,
} from 'store/modules/Common/selector';
import { getAllSegmentValues, getAllUsers } from 'store/modules/Common/slice';
import {
  label_arr,
  label_client,
  label_cs_owner,
  label_project,
  label_sales_owner,
} from 'components/Settings/Preferences/CustomLabelCookieHelper';
import useCustomNames from 'components/shared/CustomNames/useCustomNames';

interface Props {
  setRevenueImpactPage: any;
}

const ProjectWithAtRisk = (props: Props) => {
  const projectsAtRisk = useAppSelector(selectProjectsAtRisk);
  const [filteredData, setFilteredData] = useState<any[]>(projectsAtRisk);
  const dispatch = useDispatch();
  const allFilters = useAppSelector(selectAllFilters);
  const allUsers = useAppSelector(selectUsersOfCompany);
  const allUsersStatus = useAppSelector(selectUsersOfCompanyStatus);
  const allSegments = useAppSelector(selectSegmentValues);
  const allCategories = useAppSelector(selectAllCategories);
  const { displayDataWithCustomNames } = useCustomNames();

  useEffect(() => {
    if (allUsersStatus !== 'success') {
      dispatch(getAllUsers());
    }
    if (allSegments.status !== 'success') {
      dispatch(getAllSegmentValues());
    }
  }, [allUsersStatus, allSegments]);

  useEffect(() => {
    // setting filters functionality
    if (projectsAtRisk.length > 0) {
      dispatch(generateMenuOptions(projectsAtRisk));
    }

    if (
      isUndefined(allFilters['actionableInsightsProjectsAtRisk']) ||
      isNull(allFilters['actionableInsightsProjectsAtRisk'])
    ) {
      if (allUsersStatus === 'success' && allSegments.status === 'success') {
        dispatch(
          addFilterGroup({
            idName: 'actionableInsightsProjectsAtRisk',
            appliedFilters: [
              {
                name: label_sales_owner,
                dataKey: 'client_sales_owner',
                isSelected: false,
                equalToValue: [],
                canBeCleared: true,
                availableOptions: allUsers.map((item: any) => item.user_username),
              },
              {
                name: label_cs_owner,
                dataKey: 'client_account_owner',
                isSelected: false,
                equalToValue: [],
                canBeCleared: true,
                availableOptions: allUsers.map((item: any) => item.user_username),
              },
              {
                name: 'Segment',
                dataKey: 'segment_name',
                isSelected: false,
                equalToValue: [],
                canBeCleared: true,
                availableOptions: allSegments.data.map((item: any) => item.segment_name),
              },
              {
                name: 'Category',
                dataKey: 'project_needed_status',
                isSelected: false,
                equalToValue: [],
                canBeCleared: true,
                availableOptions: allCategories.data,
              },
            ],
          })
        );
      }
    }
  }, [allUsersStatus, allSegments]);

  // actual data filtering is happening here
  useEffect(() => {
    if (allFilters['actionableInsightsProjectsAtRisk']) {
      const newFilteredData = new FilterableData(projectsAtRisk);
      newFilteredData.bulkFilter(allFilters['actionableInsightsProjectsAtRisk'].appliedFilters);

      setFilteredData(newFilteredData.toArray());
    }
  }, [allFilters]);

  // const getProjectsAtRiskMappings = (): ColumnMapping[] => {
  //   const columnMappings: ColumnMapping[] = [
  //     {
  //       columnName: 'Project',
  //       dataKeyName: 'project_name+project_readinessstate_status',
  //       columnType: 'name-and-status',
  //       enableSorting: true,
  //       statusColorMapping: {
  //         red: '#E75B5C',
  //         amber: '#EFA958',
  //         green: '#4F9F52',
  //         blue: '#2D60F6',
  //       },
  //     },
  //     {
  //       columnName: 'Category',
  //       dataKeyName: 'project_needed_status',
  //       columnType: 'simple',
  //       enableSorting: true,
  //     },
  //     {
  //       columnName: 'Account',
  //       dataKeyName: 'client_name+client_readiness_status',
  //       columnType: 'name-and-status',
  //       enableSorting: true,
  //       statusColorMapping: {
  //         red: '#E75B5C',
  //         amber: '#EFA958',
  //         green: '#4F9F52',
  //         blue: '#2D60F6',
  //       },
  //     },
  //     {
  //       columnName: 'Sales Owner',
  //       dataKeyName: 'client_sales_owner',
  //       columnType: 'avatar',
  //       enableSorting: true,
  //     },
  //     {
  //       columnName: 'Cs Owner',
  //       dataKeyName: 'client_account_owner',
  //       columnType: 'avatar',
  //       enableSorting: true,
  //     },
  //     {
  //       columnName: 'Revenue Impact',
  //       dataKeyName: 'revenue',
  //       columnType: 'cost',
  //       enableSorting: true,
  //     },
  //   ];

  //   return columnMappings;
  // };

  return (
    <Box>
      <Grid container>
        <Grid item xs={12}>
          {/* header */}
          <Grid container alignItems={'center'} justifyContent={'space-between'}>
            <Grid item md={6} sm={12}>
              <Box
                className={'cognisaas-pointer'}
                sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '12px 0px' }}
                onClick={() => props.setRevenueImpactPage('revenue-impact-landing-page')}
              >
                <Box
                  component="span"
                  sx={{ marginRight: '10px', transform: 'scale(0.8)', position: 'relative', top: '2px' }}
                >
                  <LeftArrowIcon />
                </Box>
                <Typography variant="h2" sx={{ marginRight: '8px' }}>
                  {label_project}s with {label_arr} At Risk
                </Typography>
                {/* badge */}
                <Box
                  sx={{
                    borderRadius: '50%',
                    background: '#7B7B7C',
                    width: '21px',
                    height: '20px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography variant="subtitle2" sx={{ color: 'white' }}>
                    {projectsAtRisk.length}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item md={6} sm={12}>
              <Box sx={{ marginTop: '12px' }}>
                <Grid container justifyContent="end">
                  <Grid item xs={1}>
                    {/* filter button */}
                    <FilterTypeSelector idName="actionableInsightsProjectsAtRisk" />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sx={{ marginBottom: '8px' }}>
          {/* dynamic filters */}
          {allFilters['actionableInsightsProjectsAtRisk']?.appliedFilters.filter((item: any) => item.isSelected)
            .length > 0 && <SelectedFilterList idName="actionableInsightsProjectsAtRisk" isResetEnabled={true} />}
        </Grid>
        <Grid item xs={12}>
          {/* table  */}
          {filteredData.length > 0 ? (
            <>
              {/* <CognisaasTable
              uniqueTableName={'{label_arr} On Track'}
              columnMappings={getProjectsAtRiskMappings()}
              data={filteredData}
              pageSize={10}
            /> */}
              <CogniTable
                hideFooter={false}
                hideHeader={true}
                pageSize={6}
                columns={[
                  {
                    field: 'project_uid',
                    hide: true,
                  },
                  {
                    field: 'project_name',
                    headerName: label_project,
                    minWidth: 240,
                    flex: 1,
                    renderCell: (params: any) =>
                      FieldWithStatus(params, 'project_name', 'project_readinessstate_status'),
                  },
                  {
                    field: 'project_needed_status',
                    headerName: 'Category',
                    minWidth: 200,
                    flex: 1,
                  },
                  {
                    field: 'client_name',
                    headerName: label_client,
                    minWidth: 140,
                    flex: 1,
                    renderCell: (params) => FieldWithStatus(params, 'client_name', 'client_readiness_status'),
                  },
                  {
                    field: 'client_sales_owner',
                    headerName: label_sales_owner,
                    minWidth: 200,
                    flex: 1,
                    renderCell: AvatarCell,
                  },
                  {
                    field: 'client_account_owner',
                    headerName: label_cs_owner,
                    minWidth: 200,
                    flex: 1,
                    renderCell: AvatarCell,
                  },
                  {
                    field: 'revenue',
                    headerName: 'Revenue Impact',
                    minWidth: 200,
                    flex: 1,
                    renderCell: CostCell,
                  },
                ]}
                uniquRowId={'project_uid'}
                data={displayDataWithCustomNames(filteredData)}
              />
            </>
          ) : (
            ''
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProjectWithAtRisk;
