import { Paper, Stack, Typography } from '@mui/material';
// import { selectProjectInformation } from '../../../../../store/modules/Project/selector';
// import { useAppSelector } from '../../../../../store/hooks';
// import isFuture from 'date-fns/isFuture';
// import isPast from 'date-fns/esm/isPast';
import format from 'date-fns/format';
import differenceInDays from 'date-fns/differenceInDays';
import { label_due_date } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { isNull, isUndefined } from 'utils/dataHelpers';
// import isPast from 'date-fns/isPast';
// import isFuture from 'date-fns/esm/isFuture/index.js';

const TaskCompletionDaysCard = (props: any) => {
  console.log(props);

  // const projectInfo = useAppSelector(selectProjectInformation);

  const ifCompleted = (): boolean => {
    if (isNull(props.overdueDays) || isNull(props.remainingDays)) {
      return true;
    }
    return false;
  };

  const isNotOverdue = (): boolean => {
    if (!isUndefined(props.overdueDays) && !isNull(props.overdueDays)) {
      if (props.overdueDays <= 0) {
        return true;
      }
      return false;
    } else {
      if (differenceInDays(new Date(props.dueDate.replace(/-/g, '/')), new Date()) >= 0) {
        return true;
      }
      return false;
    }
  };

  return (
    <Stack>
      <Paper sx={{ height: '127px', marginTop: '16px', boxShadow: 'none' }}>
        <Stack my={1} justifyContent="center" mx={2} spacing={1}>
          <Typography sx={{ marginTop: '8px' }} variant="h2">
            {/* {props.status != 'blue' ? 'Due Date Remaining' : 'Delivery Date'} */}
            {differenceInDays(new Date(props.dueDate.replace(/-/g, '/')), new Date()) >= 0 ? (
              <Stack direction="row">
                {label_due_date}
                <Typography variant="subtitle1" className="label-text" sx={{ ml: '5px' }}>
                  {format(new Date(props.dueDate.replace(/-/g, '/')), 'dd MMM yyyy')}
                </Typography>
              </Stack>
            ) : (
              <Stack direction="row">
                {label_due_date}
                <Typography variant="subtitle1" className="label-text" sx={{ ml: '5px' }}>
                  {format(new Date(props.dueDate.replace(/-/g, '/')), 'dd MMM yyyy')}
                </Typography>
              </Stack>
            )}
          </Typography>
          <Stack direction="row">
            {/* <Typography sx={{ marginRight: '8px' }} variant="h1">
              {differenceInDays(new Date(props.dueDate.replace(/-/g, '/')), new Date())} Days
              {differenceInDays(new Date(props.dueDate.replace(/-/g, '/')), new Date()) >= 0
                ? `${differenceInDays(new Date(props.dueDate.replace(/-/g, '/')), new Date())} Days Remaining`
                : `Overdue by ${differenceInDays(new Date(props.dueDate.replace(/-/g, '/')), new Date()) * -1} Days`}
            </Typography> */}
            {ifCompleted() ? (
              <Stack direction="row">
                <Typography> Completed </Typography>
              </Stack>
            ) : isNotOverdue() ? (
              <Stack direction="row">
                <Typography sx={{ marginRight: '8px' }} variant="h1">
                  {/* {`${differenceInDays(
                  new Date(props.dueDate.replace(/-/g, '/')),
                  new Date()
                )}`} */}
                  {props.remainingDays}
                </Typography>
                <Typography> Days Remaining</Typography>
              </Stack>
            ) : (
              <Stack direction="row">
                <Typography>Overdue by </Typography>
                <Typography sx={{ marginRight: '8px', ml: '5px' }} variant="h1">
                  {/* {`${
                  differenceInDays(new Date(props.dueDate.replace(/-/g, '/')), new Date()) * -1
                } Days`} */}
                  {`${props.overdueDays} Days`}
                </Typography>
              </Stack>
            )}
            {/* <Typography variant="subtitle1" className="label-text">
              {format(new Date(props.dueDate.replace(/-/g, '/')), 'dd MMM yyyy')}
            </Typography> */}
          </Stack>
        </Stack>
        <Stack ml={2} mt={2}>
          <>
            {differenceInDays(new Date(props.eta.split(' ')[0]), new Date(props.dueDate.split(' ')[0])) > 0 ? (
              <Typography>
                Delay: {differenceInDays(new Date(props.eta.split(' ')[0]), new Date(props.dueDate.split(' ')[0]))}{' '}
                {differenceInDays(new Date(props.eta.split(' ')[0]), new Date(props.dueDate.split(' ')[0])) == 1
                  ? 'day'
                  : 'days'}
              </Typography>
            ) : (
              <Typography>Delay: 0 days</Typography>
            )}
          </>
        </Stack>
      </Paper>
    </Stack>
  );
};

export default TaskCompletionDaysCard;
