import styled from 'styled-components';

interface ContainerProps {
    height: string;
    width: string;
}

export const Container = styled.div<ContainerProps>`
    width:${({ width }) => width};
    height: ${({ height }) => height };
    border-radius: 50%;
    overflow: hidden;
`;

export const Image = styled.img`
    width:100%;
    height: 100%;
    object-fit: cover;
`;

