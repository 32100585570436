import { Alert, IconButton, InputLabel, Stack, Tooltip } from '@mui/material';
import CognisaasButton from 'components/shared/CognisaasButton/CognisaasButton';
import CognisaasButtonWithLoading from 'components/shared/CognisaasButton/CognisaasButtonWithLoading';
import CognisaasSwitchButton from 'components/shared/CognisaasSwitch/CognisaasSwitch';
import ProjectAggregration from '../AggregrationEntity/ProjectAggregration';
import InfoIcon from 'components/shared/Icons/InfoIcon';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCustomerUid, getLoggedInUserUid } from 'utils/getUid';
import clientEntity from '../Entity/ClientEntity';
import projectEntity from '../Entity/projectEntity';
import taskEntity from '../Entity/taskEntity';
import usecaseEntity from '../Entity/usecaseEntity';
import { createAggregatedCustomReport } from 'store/modules/Reports/AggregatedCustomReports/slice';
import ClientAggregration from '../AggregrationEntity/ClientAggregration';
import UsecaseAggregration from '../AggregrationEntity/UsecaseAggregration';
import TaskAggregration from '../AggregrationEntity/TaskAggregration';
import { label_client, label_project, label_use_case } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { getStandardEntityName } from '../UtilityFunction';
import { SelectedEntityStorageMethodObj } from 'components/Reports/Shared/CreateCustomReportWrapper';
import { REPORT_TYPE_AGGREGATED_REPORT, REPORT_TYPE_CUSTOM_REPORT } from 'components/Reports/Shared/constants';
import { useAppDispatch } from 'store/hooks';

export const entities = ['None', label_client, label_project, label_use_case];

interface Props {
  reportName: string;
  reportDescription: string;
  topEntity: string;
  setSelectedEntitiesAndItsFields: React.Dispatch<React.SetStateAction<SelectedEntityStorageMethodObj>>;
  selectedEntitiesAndItsFields: SelectedEntityStorageMethodObj;
}

const CreateAggregatedCustomReports = (props: Props) => {
  const { reportName, reportDescription, topEntity } = props;
  // entities
  const { clientEntityWithCustomFields } = clientEntity();
  const { projectEntityWithCustomFields } = projectEntity();
  // console.log(projectEntityWithCustomFields);
  const { usecaseEntityWithCustomFields } = usecaseEntity();
  const { taskEntityWithCustomFields } = taskEntity();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isPrivate, setIsPrivate] = useState<boolean>(true);
  const [writeAccess, setWriteAccess] = useState<boolean>(false);

  const [selectedProjectEntities, setSelectedProjectEntities] = useState<any[]>([]);
  const [selectedClientEntities, setSelectedClientEntities] = useState<any[]>([]);
  const [selectedUsecaseEntities, setSelectedUsecaseEntities] = useState<any[]>([]);
  const [selectedTaskEntities, setSelectedTaskEntities] = useState<any[]>([]);
  // loader for save button
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // console.log('asdasdasdads', props.selectedEntitiesAndItsFields);

  // useEffect(() => {
  //   const some = [
  //     ...selectedProjectEntities
  //       .filter((item: any) => item.selected)
  //       .map((item: any) => ({ key: item.fieldName, type: 'project' })),
  //     ...selectedClientEntities
  //       .filter((item: any) => item.selected)
  //       .map((item: any) => ({ key: item.fieldName, type: 'client' })),
  //     ...selectedUsecaseEntities
  //       .filter((item: any) => item.selected)
  //       .map((item: any) => ({ key: item.fieldName, type: 'usecase' })),
  //     ...selectedTaskEntities
  //       .filter((item: any) => item.selected)
  //       .map((item: any) => ({ key: item.fieldName, type: 'task' })),
  //   ] as SelectedEntityStorageMethodObj[];
  //   console.warn('some', some);
  // }, [selectedProjectEntities, selectedClientEntities, selectedUsecaseEntities, selectedTaskEntities]);

  // useEffect(() => {
  //   console.log(
  //     'on entity change',
  //     [
  //       ...selectedClientEntities
  //         .filter((item: any) => item.selected)
  //         .map((item: any) => ({ key: item.fieldName, type: 'client' })),
  //     ],
  //     props.selectedEntitiesAndItsFields
  //   );
  // }, [selectedClientEntities]);

  // useEffect(() => {
  //   if (Object.keys(clientEntityWithCustomFields).length > 0) {
  //     preLoadSelectedFields();
  //   }
  // }, [clientEntityWithCustomFields]);

  // useEffect(() => {
  //   if (props.selectedEntitiesAndItsFields.length > 0) {
  //     const clientEntities: string[] = [];
  //     const projectEntities: string[] = [];
  //     const usecaseEntities: string[] = [];
  //     const taskEntities: string[] = [];
  //     setSelectedClientEntities((prevState: any[]) => {
  //       return prevState.map((thisEntity: any) => {
  //         const foundEntity = props.selectedEntitiesAndItsFields.filter(
  //           (entity) => entity.key === thisEntity.fieldName
  //         );
  //         if (foundEntity.length > 0) {
  //           return { ...thisEntity, selected: true };
  //         } else {
  //           return thisEntity;
  //         }
  //       });
  //     });
  //     props.selectedEntitiesAndItsFields.forEach((item: SelectedEntityStorageMethodObj) => {
  //       if (item.type === 'client') {
  //         const foundEntity = selectedClientEntities.filter((entity) => entity.fieldName === item.key);
  //         if (foundEntity.length > 0) {
  //           clientEntities.push({ ...foundEntity[0], selected: true });
  //         }
  //       } else if (item.type === 'project') {
  //         const foundEntity = selectedProjectEntities.filter((entity) => entity.fieldName === item.key);
  //         if (foundEntity.length > 0) {
  //           projectEntities.push({ ...foundEntity[0], selected: true });
  //         }
  //       } else if (item.type === 'usecase') {
  //         const foundEntity = selectedUsecaseEntities.filter((entity) => entity.fieldName === item.key);
  //         if (foundEntity.length > 0) {
  //           usecaseEntities.push({ ...foundEntity[0], selected: true });
  //         }
  //       } else if (item.type === 'task') {
  //         const foundEntity = selectedTaskEntities.filter((entity) => entity.fieldName === item.key);
  //         if (foundEntity.length > 0) {
  //           taskEntities.push({ ...foundEntity[0], selected: true });
  //         }
  //       }
  //     });
  //     setSelectedClientEntities(clientEntities);
  //     setSelectedProjectEntities(projectEntities);
  //     setSelectedUsecaseEntities(usecaseEntities);
  //     setSelectedTaskEntities(taskEntities);
  //   }
  // }, []);

  // useEffect(() => {
  //   console.log('selectedProjectEntities', selectedProjectEntities);
  //   console.log(selectedClientEntities);
  // }, [selectedProjectEntities, selectedClientEntities]);

  // console.log('selectedClientEntities', selectedClientEntities);

  // const changeToNeededDataType = (arr: any[]) => {
  //   // const temp: any = {};
  //   // for (let i = 0; i < arr.length; i++) {
  //   //   temp[arr[i].columnName] = { ...arr[i] };
  //   // }
  //   return arr.map((item: any) => ({
  //     aggregations: [],
  //     columnName: item.displayName,
  //     fieldName: item.key,
  //     selected: true,
  //   }));
  // };

  // const preLoadSelectedFields = () => {
  //   console.log('preeeee', clientEntityWithCustomFields, selectedClientEntities);
  //   const fields = clientEntityWithCustomFields;
  //   const updateField = Object.keys(fields).map((field: any, i: number) => {
  //     fields[field].selected = false;
  //     return fields[field];
  //   });
  //   // setFields({ ...changeToNeededDataType(updateField) });
  //   // props.selectedItemsInClient(updateField);
  //   setSelectedClientEntities(changeToNeededDataType(updateField));
  //   // console.log('preselected data', updateField, changeToNeededDataType(updateField));
  // };

  const makeReportConfig = () => {
    const clientFields: any = [];
    // console.log(selectedClientEntities, clientEntityWithCustomFields);
    for (let client = 0; client < selectedClientEntities.length; client++) {
      if (selectedClientEntities[client].selected) {
        if (selectedClientEntities[client].aggregations.length > 0) {
          for (let j = 0; j < selectedClientEntities[client].aggregations.length; j++) {
            // console.log(selectedClientEntities[client].aggregations[j].columnName);
            const field = {
              ...clientEntityWithCustomFields[selectedClientEntities[client].aggregations[j].fieldName],
              displayName: selectedClientEntities[client].aggregations[j].columnName,
              aggregationToPerform:
                topEntity === 'None' ? 'nothing' : selectedClientEntities[client].aggregations[j].aggregationType,
              isAggregatable: topEntity === 'None' ? false : true,
              toAggregate: topEntity === 'None' ? false : true,
              conditions: topEntity === 'None' ? [] : selectedClientEntities[client].aggregations[j].conditions,
            };
            clientFields.push({ ...field });
          }
        } else {
          const field = {
            ...clientEntityWithCustomFields[selectedClientEntities[client].fieldName],
            displayName: selectedClientEntities[client].columnName,
            aggregationToPerform: 'nothing',
            isAggregatable: topEntity === 'None' ? false : true,
            toAggregate: topEntity === 'None' ? false : true,
            conditions: [],
          };
          clientFields.push({ ...field });
        }
      }
    }
    // console.log('clientFields', clientFields);

    const projectFields: any = [];
    // console.log(selectedProjectEntities, projectEntityWithCustomFields);
    for (let project = 0; project < selectedProjectEntities.length; project++) {
      if (selectedProjectEntities[project].selected) {
        if (selectedProjectEntities[project].aggregations.length > 0) {
          for (let j = 0; j < selectedProjectEntities[project].aggregations.length; j++) {
            // console.log(selectedProjectEntities[project].aggregations[j].columnName);
            const field = {
              ...projectEntityWithCustomFields[selectedProjectEntities[project].aggregations[j].fieldName],
              displayName: selectedProjectEntities[project].aggregations[j].columnName,
              aggregationToPerform:
                topEntity === 'None' ? 'nothing' : selectedProjectEntities[project].aggregations[j].aggregationType,
              isAggregatable: topEntity === 'None' ? false : true,
              toAggregate: topEntity === 'None' ? false : true,
              conditions: topEntity === 'None' ? [] : selectedProjectEntities[project].aggregations[j].conditions,
            };
            projectFields.push({ ...field });
          }
        } else {
          const field = {
            ...projectEntityWithCustomFields[selectedProjectEntities[project].fieldName],
            displayName: selectedProjectEntities[project].columnName,
            aggregationToPerform: 'nothing',
            isAggregatable: topEntity === 'None' ? false : true,
            toAggregate: topEntity === 'None' ? false : true,
            conditions: [],
          };
          projectFields.push({ ...field });
        }
      }
    }
    // TEST
    // projectFields.push({
    //   displayName: 'Scheme Date of Delivery2',
    //   key: 'project_deliverydate',
    //   type: 'date',
    //   isAggregatable: true,
    //   isCustomField: false,
    //   allowedOperationsList: ['count'],
    //   toAggregate: true,
    //   aggregationToPerform: 'nothing',
    //   conditions: [
    //     {
    //       type: '>',
    //       value: 'eta_forprojectdelivery',
    //       chainType: null,
    //       customValue: false,
    //       dropdownFields: [
    //         'Scheme Planned Start Date',
    //         'Scheme Start Date',
    //         'Scheme ETA for Delivery',
    //         'Scheme ETA',
    //         'Scheme Date of Delivery',
    //         'Scheme Created on',
    //         'eta',
    //       ],
    //     },
    //   ],
    // });
    // console.log(projectFields);

    const usecaseFields: any = [];
    // console.log(selectedUsecaseEntities, usecaseEntityWithCustomFields);
    for (let usecase = 0; usecase < selectedUsecaseEntities.length; usecase++) {
      if (selectedUsecaseEntities[usecase].selected) {
        if (selectedUsecaseEntities[usecase].aggregations.length > 0) {
          for (let j = 0; j < selectedUsecaseEntities[usecase].aggregations.length; j++) {
            const field = {
              ...usecaseEntityWithCustomFields[selectedUsecaseEntities[usecase].aggregations[j].fieldName],
              displayName: selectedUsecaseEntities[usecase].aggregations[j].columnName,
              aggregationToPerform:
                topEntity === 'None' ? 'nothing' : selectedUsecaseEntities[usecase].aggregations[j].aggregationType,
              isAggregatable: topEntity === 'None' ? false : true,
              toAggregate: topEntity === 'None' ? false : true,
              conditions: topEntity === 'None' ? [] : selectedUsecaseEntities[usecase].aggregations[j].conditions,
            };
            usecaseFields.push({ ...field });
          }
        } else {
          const field = {
            ...usecaseEntityWithCustomFields[selectedUsecaseEntities[usecase].fieldName],
            displayName: selectedUsecaseEntities[usecase].columnName,
            aggregationToPerform: 'nothing',
            isAggregatable: topEntity === 'None' ? false : true,
            toAggregate: topEntity === 'None' ? false : true,
            conditions: [],
          };
          usecaseFields.push({ ...field });
        }
      }
    }
    // console.log(usecaseFields);

    const taskFields: any = [];
    // console.log(selectedTaskEntities, taskEntityWithCustomFields);
    for (let task = 0; task < selectedTaskEntities.length; task++) {
      if (selectedTaskEntities[task].selected) {
        if (selectedTaskEntities[task].aggregations.length > 0) {
          for (let j = 0; j < selectedTaskEntities[task].aggregations.length; j++) {
            const field = {
              ...taskEntityWithCustomFields[selectedTaskEntities[task].aggregations[j].fieldName],
              displayName: selectedTaskEntities[task].aggregations[j].columnName,
              aggregationToPerform:
                topEntity === 'None' ? 'nothing' : selectedTaskEntities[task].aggregations[j].aggregationType,
              isAggregatable: topEntity === 'None' ? false : true,
              toAggregate: topEntity === 'None' ? false : true,
              conditions: topEntity === 'None' ? [] : selectedTaskEntities[task].aggregations[j].conditions,
            };
            taskFields.push({ ...field });
          }
        } else {
          const field = {
            ...taskEntityWithCustomFields[selectedTaskEntities[task].fieldName],
            displayName: selectedTaskEntities[task].columnName,
            aggregationToPerform: 'nothing',
            isAggregatable: topEntity === 'None' ? false : true,
            toAggregate: topEntity === 'None' ? false : true,
            conditions: [],
          };
          taskFields.push({ ...field });
        }
      }
    }
    const data = {
      project: projectFields,
      client: clientFields,
      usecase: usecaseFields,
      task: taskFields,
    };
    return JSON.stringify(data);
  };

  const saveReport = async () => {
    setIsLoading(true);
    const detail = {
      userUid: getLoggedInUserUid(),
      customerUid: getCustomerUid(),
      lastUpdateBy: getLoggedInUserUid(),
      reportName: reportName,
      topEntity: getStandardEntityName(topEntity),
      reportDescription: reportDescription,
      reportConfig: makeReportConfig(),
      isPrivate: isPrivate,
      writeAccess: writeAccess,
      reportType: props.topEntity === 'None' ? REPORT_TYPE_CUSTOM_REPORT : REPORT_TYPE_AGGREGATED_REPORT,
    };
    // const data = await dispatch(
    //   createAggregatedCustomReport(({
    //     ...detail
    //   }))
    // );
    // const data = await dispatch(createAggregatedCustomReport(detail)).unwrap();
    const data = await dispatch(createAggregatedCustomReport(detail)).unwrap();

    setIsLoading(false);
    if (data.status == 1) {
      navigate(`/reports/aggregated-custom-report/${data.result.report_uid}`);
    }
  };

  // for normal report not aggregated
  // const makeReportConfig = () => {
  //   const data = {
  //     project: [...selectedProjectEntities].map((entity: any) => projectEntityWithCustomFields[entity]),
  //     client: [...selectedClientEntities].map((entity: any) => clientEntityWithCustomFields[entity]),
  //     usecase: [...selectedUsecaseEntities].map((entity: any) => usecaseEntityWithCustomFields[entity]),
  //     task: [...selectedTaskEntities].map((entity: any) => taskEntityWithCustomFields[entity]),
  //   };
  //   return JSON.stringify(data);
  // };

  // const saveReport = async () => {
  //   setIsLoading(true);
  //   const detail = {
  //     userUid: getLoggedInUserUid(),
  //     customerUid: getCustomerUid(),
  //     lastUpdateBy: getLoggedInUserUid(),
  //     reportName: reportName,
  //     reportDescription: reportDescription,
  //     reportConfig: makeReportConfig(),
  //     isPrivate: isPrivate,
  //     writeAccess: writeAccess,
  //   };
  //   const data = await dispatch(createCustomReport(detail)).unwrap();
  // if (data.status == 1)
  //   navigate(`/reports/custom-report/${data.result.report_uid}`, {
  //     state: {
  //       showCustomReports: 1,
  //     },
  //   });
  //   setIsLoading(false);
  // };

  // console.log('entities', selectedClientEntities, selectedProjectEntities);

  const disableSaveButton = (): boolean => {
    if (reportName.length == 0) {
      return true;
    }
    const totalAggregations = [
      ...selectedClientEntities,
      ...selectedProjectEntities,
      ...selectedUsecaseEntities,
      ...selectedTaskEntities,
    ]
      .map((item: any) => ({
        ...item,
        aggregations: item.aggregations.filter((item: any) => item.aggregationType !== 'nothing'),
      }))
      .reduce((accu, currentObj) => (accu += currentObj.aggregations.length), 0);
    // selectedProjectEntities.reduce((accu, currentObj) => (accu += currentObj.aggregations.length), 0) +
    // selectedUsecaseEntities.reduce((accu, currentObj) => (accu += currentObj.aggregations.length), 0) +
    // selectedTaskEntities.reduce((accu, currentObj) => (accu += currentObj.aggregations.length), 0);
    // console.log('agg', totalAggregations);
    if (totalAggregations === 0 && props.topEntity !== 'None') {
      return true;
    }
    return false;
  };

  return (
    <>
      <InputLabel sx={{ marginLeft: '8px' }} htmlFor="component-simple">
        Report Entities{' '}
        <Tooltip title="Select all the fields which you want to include in this report." placement="right-start">
          <IconButton color="primary" aria-label="SMTP">
            <InfoIcon />
          </IconButton>
        </Tooltip>
      </InputLabel>
      <Stack direction={'row'} flexWrap={'wrap'} flexGrow={1}>
        <Stack direction={'column'} flexGrow={1}>
          <ClientAggregration
            page="CREATE"
            topEntityIndex={entities.indexOf(topEntity)}
            selectedItemsInClient={(items: any[]) => setSelectedClientEntities([...items])}
            entityFields={clientEntityWithCustomFields}
            preSelectedEntities={props.selectedEntitiesAndItsFields}
            setSelectedEntitiesAndItsFields={props.setSelectedEntitiesAndItsFields}
          />
          <ProjectAggregration
            page="CREATE"
            topEntityIndex={entities.indexOf(topEntity)}
            selectedItemsInProject={(items: any[]) => setSelectedProjectEntities([...items])}
            entityFields={projectEntityWithCustomFields}
            preSelectedEntities={props.selectedEntitiesAndItsFields}
            setSelectedEntitiesAndItsFields={props.setSelectedEntitiesAndItsFields}
          />
        </Stack>

        <Stack mb={2} direction={'column'} flexGrow={1}>
          <UsecaseAggregration
            topEntityIndex={entities.indexOf(topEntity)}
            selectedItemsInUsecase={(items: any[]) => setSelectedUsecaseEntities([...items])}
            entityFields={usecaseEntityWithCustomFields}
            preSelectedEntities={props.selectedEntitiesAndItsFields}
            setSelectedEntitiesAndItsFields={props.setSelectedEntitiesAndItsFields}
          />

          <TaskAggregration
            topEntityIndex={entities.indexOf(topEntity)}
            selectedItemsInTask={(items: any[]) => setSelectedTaskEntities([...items])}
            entityFields={taskEntityWithCustomFields}
            preSelectedEntities={props.selectedEntitiesAndItsFields}
            setSelectedEntitiesAndItsFields={props.setSelectedEntitiesAndItsFields}
          />
        </Stack>
      </Stack>

      <Stack my={0} mx={1}>
        <InputLabel htmlFor="component-simple">
          Private Report
          <Tooltip
            title="By default custom reports are only visible to you. If you disable this button, custom reports are visible to everyone."
            placement="right-start"
          >
            <IconButton color="primary" aria-label="SMTP">
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </InputLabel>
        <CognisaasSwitchButton
          handleChange={(e: any) => {
            setIsPrivate(e.target.checked);
            if (e.target.checked) {
              setWriteAccess(false);
            }
          }}
          checked={isPrivate}
        />
      </Stack>
      {!isPrivate && (
        <Stack my={0} mx={1}>
          <InputLabel htmlFor="component-simple">
            Allow others to edit report config{' '}
            <Tooltip
              title="If you enable this button, then everyone in your organization can edit this report."
              placement="right-start"
            >
              <IconButton color="primary" aria-label="SMTP">
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </InputLabel>
          <CognisaasSwitchButton
            handleChange={(e: any) => {
              setWriteAccess(e.target.checked);
            }}
            checked={writeAccess}
          />
        </Stack>
      )}

      {topEntity !== 'None' && (
        <Alert sx={{ margin: '12px 0px' }} severity="info">
          Since you selected aggregation, you must add at least one aggregation to save report.
        </Alert>
      )}

      <Stack direction={'row'} flexWrap={'wrap'} sx={{ marginLeft: 'auto' }}>
        <CognisaasButton
          sx={{ marginRight: '16px' }}
          isOutlined={true}
          clickHandler={() => navigate('/reports')}
          label={'Cancel'}
        />
        <CognisaasButtonWithLoading
          isLoading={isLoading}
          disabled={disableSaveButton()}
          label={'Save Custom Report'}
          isOutlined={false}
          clickHandler={() => {
            saveReport();
          }}
        />
      </Stack>
    </>
  );
};

export default CreateAggregatedCustomReports;
