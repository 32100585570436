import { Typography, Box, ButtonGroup, Button, Stack } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useState } from 'react';
import CognisaasTabs from '../shared/CognisaasTabs/index';
import RevenueImpactLandingPage from './RevenueImpact/LandingPage';
import ProjectWithArrLoss from './RevenueImpact/ProjectWithArrLoss';
import ProjectWithAtRisk from './RevenueImpact/ProjectWithAtRisk';
import ProjectWithOnTrack from './RevenueImpact/ProjectWithOnTrack';
import useClientList from 'components/shared/Hooks/UseClientList';
import { selectFetchClientListDispatchStatus } from 'store/modules/ManageClient/ClientList/selector';
import { ReactComponent as NoRecords } from '../../assets/svgs/no_records.svg';
import TimeToValue from './TimeToValue/TimeToValue';
import ClientMapping from './ClientMapping/ClientMapping';
import RecommendationPanel from './Recommendations/RecommendationPanel';
import ResoursePlanning from './ResoursePlanning/ResoursePlanning';
import {
  label_project,
  label_project_delivery_manager,
  label_task,
  label_use_case,
  label_use_case_delivery_manager,
} from 'components/Settings/Preferences/CustomLabelCookieHelper';
import CognisaasLoader from 'components/shared/CognisaasLoader/CognisaasLoader';
import { useAppSelector } from 'store/hooks';
import { requestState } from 'store/modules/types';

const ActionableInsights = () => {
  const clientList = useClientList();
  const fetchClientListDispatchStatus = useAppSelector(selectFetchClientListDispatchStatus);
  const [actionableInsightTabIndex, setActionableInsightTabIndex] = useState(0);
  const [revenueImpactPage, setRevenueImpactPage] = useState('revenue-impact-landing-page');
  const [resoursePlanningProjUsecaseTaskIndex, setResoursePlanningProjUsecaseTaskIndex] = useState<number>(0);
  const switchInsightTabs = (event: any, tabIndex: number) => {
    setActionableInsightTabIndex(tabIndex);
  };
  return (
    <Box sx={{ padding: '20px 20px 0px 20px' }}>
      {fetchClientListDispatchStatus == requestState.loading ? (
        <CognisaasLoader />
      ) : Array.isArray(clientList) && clientList.length == 0 ? (
        // <CognisaasLoader />
        <Stack style={{ marginTop: '32px' }} justifyContent={'center'} alignItems={'center'}>
          <Typography variant="h5">Nothing to show!</Typography>
          <NoRecords style={{ width: '300px', height: '300px' }} />
        </Stack>
      ) : (
        <Grid container spacing={1}>
          <Grid item lg={actionableInsightTabIndex == 2 || actionableInsightTabIndex == 2 ? 12 : 8} sm={12}>
            <Grid container>
              <Grid item xs={12} sx={{ marginBottom: '20px' }}>
                <Typography variant="h1" sx={{ fontWeight: '500' }}>
                  Actionable Insights
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <CognisaasTabs
                  handleTabSwitch={switchInsightTabs}
                  tabs={['Revenue Impact', 'Resource Planning', 'Client Mapping', 'Time-To-Value']}
                  size="squeezed"
                />
              </Grid>

              <Grid item xs={12}>
                {/* revenue impact pages */}
                {revenueImpactPage === 'revenue-impact-landing-page' && actionableInsightTabIndex === 0 ? (
                  <RevenueImpactLandingPage setRevenueImpactPage={setRevenueImpactPage} />
                ) : revenueImpactPage === 'project-with-arr-loss' && actionableInsightTabIndex === 0 ? (
                  <>
                    <ProjectWithArrLoss setRevenueImpactPage={setRevenueImpactPage} />
                  </>
                ) : revenueImpactPage === 'project-with-arr-on-track' && actionableInsightTabIndex === 0 ? (
                  <>
                    <ProjectWithOnTrack setRevenueImpactPage={setRevenueImpactPage} />
                  </>
                ) : revenueImpactPage === 'project-with-arr-at-risk' && actionableInsightTabIndex === 0 ? (
                  <>
                    <ProjectWithAtRisk setRevenueImpactPage={setRevenueImpactPage} />
                  </>
                ) : (
                  ''
                )}

                {/* resourse planning pages */}
                {actionableInsightTabIndex === 1 ? <ResoursePlanning /> : ''}

                {/* client mapping pages */}
                {actionableInsightTabIndex === 2 ? <ClientMapping /> : ''}

                {/* time to value pages */}
                {actionableInsightTabIndex === 3 ? <TimeToValue /> : ''}
              </Grid>
            </Grid>
          </Grid>

          {[2, 3].includes(actionableInsightTabIndex) ? (
            ''
          ) : (
            <Grid item lg={4} md={12}>
              {/* recommendation panel */}
              <Box
                sx={{
                  background: 'white',
                  borderRadius: '4px',
                  height: '100%',
                }}
              >
                <Typography
                  variant="subtitle2"
                  className="label-text"
                  sx={{ marginBottom: '5px', marginLeft: '10px', margintop: '8px', paddingTop: '16px' }}
                >
                  {revenueImpactPage === 'revenue-impact-landing-page' && actionableInsightTabIndex === 0 && (
                    <>Top 4 Recommendations</>
                  )}
                  {actionableInsightTabIndex === 1 && resoursePlanningProjUsecaseTaskIndex === 0 && (
                    <>
                      Top 3 {label_use_case.toLowerCase()}s with delay risk due to{' '}
                      {label_use_case_delivery_manager.toLowerCase()} bandwidth
                    </>
                  )}
                  {actionableInsightTabIndex === 1 && resoursePlanningProjUsecaseTaskIndex === 1 && (
                    <>
                      Top 3 {label_project.toLowerCase()}s with delay risk due to{' '}
                      {label_project_delivery_manager.toLowerCase()} bandwidth
                    </>
                  )}
                  {actionableInsightTabIndex === 1 && resoursePlanningProjUsecaseTaskIndex === 2 && (
                    <>
                      Top 3 {label_task.toLowerCase()}s with delay risk due to {label_task.toLowerCase()} owner
                      bandwidth
                    </>
                  )}
                </Typography>
                <Typography sx={{ marginBottom: '16px', marginLeft: '10px' }} variant="h2">
                  {actionableInsightTabIndex === 0 ? (
                    // revenue Impact
                    <>
                      Maximize Value Delivery and <br /> Minimize Revenue Risk
                    </>
                  ) : (
                    // resourse planning
                    <ButtonGroup
                      variant="outlined"
                      aria-label="outlined button group"
                      sx={{
                        display: 'inline-flex',
                        flexWrap: 'wrap',
                        backgroundColor: 'white',
                        border: '1px solid #E5E5E5',
                        borderRadius: '4px',
                      }}
                    >
                      <Button
                        onClick={() => setResoursePlanningProjUsecaseTaskIndex(0)}
                        variant="text"
                        sx={resoursePlanningProjUsecaseTaskIndex === 0 ? { background: 'rgba(45, 96, 246,0.15)' } : {}}
                      >
                        {label_use_case}
                      </Button>
                      <Button
                        onClick={() => setResoursePlanningProjUsecaseTaskIndex(1)}
                        variant="text"
                        sx={resoursePlanningProjUsecaseTaskIndex === 1 ? { background: 'rgba(45, 96, 246,0.15)' } : {}}
                      >
                        {label_project}
                      </Button>
                      <Button
                        onClick={() => setResoursePlanningProjUsecaseTaskIndex(2)}
                        variant="text"
                        sx={resoursePlanningProjUsecaseTaskIndex === 2 ? { background: 'rgba(45, 96, 246,0.15)' } : {}}
                      >
                        {label_task}
                      </Button>
                    </ButtonGroup>
                  )}
                </Typography>

                {actionableInsightTabIndex === 0 ? (
                  // revenue Impact
                  <RecommendationPanel for="revenueImpact" />
                ) : (
                  // resourse planning

                  <>
                    {/* usecase */}
                    {resoursePlanningProjUsecaseTaskIndex === 0 && <RecommendationPanel for="usecase" />}
                    {/* project */}
                    {resoursePlanningProjUsecaseTaskIndex === 1 && <RecommendationPanel for="project" />}
                    {/* task */}
                    {resoursePlanningProjUsecaseTaskIndex === 2 && <RecommendationPanel for="task" />}
                  </>
                )}
              </Box>
            </Grid>
          )}
        </Grid>
      )}
    </Box>
  );
};

export default ActionableInsights;
