import {
  Box,
  Grid,
  Typography,
  Button,
  ToggleButton,
  Stack,
  ToggleButtonGroup,
  MenuItem,
  Select,
  TextField,
  Drawer,
} from '@mui/material';
import { newTaskTemplateSchema } from 'utils/validations';
import { Formik } from 'formik';
import { addTemplateTaskInitialValues } from '../Project/AddProjectTemplate/helper';
import { label_client, label_task } from 'components/Settings/Preferences/CustomLabelCookieHelper';
// import { CLIENT_TASK, TEAM_TASK } from '../../types';
// import { getUserUid } from 'utils/getUid';
import CognisaasUserSelect from 'components/shared/CognisaasSelect/CognisaasUserSelect';
import useUserDropDownData from 'components/shared/Hooks/useUserDropDownData';
import { getUserUid } from 'utils/getUid';
import IOSSwitch from './IOSSwitch';
import useCustomNames from 'components/shared/CustomNames/useCustomNames';
import NAMED_CONSTATNTS from 'components/shared/CustomNames/Constants';

const AddProjectTemplateTaskDrawer = (props: any) => {
  const companyUsersDropDownData = useUserDropDownData('company');
  const { taskPrioritiesDropdownValues, getCustomNameMappedValue, getStandardNameMappedValue } = useCustomNames();
  const taskAllPriorities = taskPrioritiesDropdownValues.map((item: any) => item.custom_priority_name);

  return (
    <Drawer
      open={props.drawerState}
      onClose={() => {
        props.onClose();
        //   resetValues();
      }}
      anchor="right"
    >
      <Box sx={{ width: '460px', marginTop: '60px', padding: '20px' }}>
        <Grid container justifyContent="space-between">
          <Grid item></Grid>
        </Grid>
        <Typography sx={{ fontSize: '18px', fontWeight: '500', mt: '5px' }}>Add {label_task}</Typography>

        <Formik
          initialValues={{ ...addTemplateTaskInitialValues }}
          onSubmit={(values: any) => {
            console.log(values, 'values');
            props.addProjectTask(values);
          }}
          validationSchema={newTaskTemplateSchema}
        >
          {(formik: any) => (
            <>
              <Typography sx={{ fontSize: '12px', fontWeight: '400', mt: '24px' }}>{label_task} Type</Typography>
              <ToggleButtonGroup
                color="primary"
                value={formik.values.isTeamTask ? 'Team' : 'Client'}
                exclusive
                onChange={() => formik.setFieldValue('isTeamTask', !formik.values.isTeamTask)}
                sx={{ mt: '4px' }}
              >
                <ToggleButton value="Team">Team</ToggleButton>
                <ToggleButton value="Client">{label_client}</ToggleButton>
              </ToggleButtonGroup>
              <Typography sx={{ mt: '24px', fontSize: '12px', fontWeight: '400' }}>{label_task} Name*</Typography>
              <TextField
                sx={{ width: '310px', mt: '4px' }}
                placeholder={`Enter ${label_task} name`}
                size="small"
                id="taskName"
                name="taskName"
                value={formik.values.taskName}
                onBlur={formik.handleBlur}
                onChange={(params: any) => {
                  console.log(params.target.value, 'params');
                  formik.handleChange(params);
                }}
                error={formik.touched.taskName && Boolean(formik.errors.taskName)}
                helperText={formik.touched.taskName && formik.errors.taskName}
              />
              <Stack direction="row" spacing={2} sx={{ mt: '4px' }}>
                {formik.values.isTeamTask == false ? null : (
                  <Box>
                    <Typography sx={{ fontSize: '12px', fontWeight: '400' }}>{label_task} Owner</Typography>

                    <CognisaasUserSelect
                      width="200px"
                      id="taskOwner"
                      name="taskOwner"
                      allOptions={companyUsersDropDownData}
                      selectedOption={formik.values.taskOwner}
                      onChange={(val: any) => {
                        console.log(val.target.textContent);
                        formik.setFieldValue('taskOwner', {
                          name: val.target.textContent,
                          id: getUserUid(val.target.textContent),
                        });
                      }}
                      onBlur={formik.handleBlur}
                      error={formik.touched.taskOwner && Boolean(formik.errors.taskOwner)}
                      helperText={formik.touched.taskOwner && formik.errors.taskOwner}
                    />
                  </Box>
                )}
                <Box>
                  <Typography sx={{ fontSize: '12px', fontWeight: '400' }}>Priority</Typography>
                  <Select
                    name="priority"
                    value={getCustomNameMappedValue(formik.values.priority, NAMED_CONSTATNTS.task_priority_key)}
                    onChange={(e: any) =>
                      formik.setFieldValue(
                        'priority',
                        getStandardNameMappedValue(e.target.value, NAMED_CONSTATNTS.task_priority_key)
                      )
                    }
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    size="small"
                    sx={{ width: '210px' }}
                  >
                    {taskAllPriorities.map((item: string, index: number) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </Stack>
              <Stack direction="row" spacing={2} sx={{ mt: '4px' }}>
                <Box>
                  <Typography sx={{ fontSize: '12px', fontWeight: '400' }}>Target Duration</Typography>
                  <TextField
                    placeholder="2w 3d 5h 10m"
                    sx={{ mt: '4px' }}
                    fullWidth
                    size="small"
                    id="durationTime"
                    name="durationTime"
                    value={formik.values.durationTime}
                    onBlur={formik.handleBlur}
                    onChange={(params: any) => {
                      console.log(params.target.value, 'params');
                      formik.handleChange(params);
                      formik.setFieldValue('durationTime', params.target.value);
                    }}
                    error={formik.touched.durationTime && Boolean(formik.errors.durationTime)}
                    helperText={formik.touched.durationTime && formik.errors.durationTime}
                  />
                </Box>
                {formik.values.isTeamTask == false ? null : (
                  <Box>
                    <Typography sx={{ fontSize: '12px', fontWeight: '400' }}>Implementation Fee %</Typography>
                    <TextField
                      type="number"
                      size="small"
                      sx={{ mt: '4px' }}
                      fullWidth
                      name="implementationFee"
                      value={formik.values.implementationFee}
                      onChange={(e) => {
                        formik.setFieldValue('implementationFee', e.target.value);
                      }}
                    />
                  </Box>
                )}
              </Stack>
              <Typography sx={{ fontSize: '12px', fontWeight: '400', mt: '4px' }}>{label_task} Description</Typography>
              <TextField
                multiline
                rows={3}
                size="small"
                fullWidth
                sx={{ mt: '4px' }}
                placeholder="Enter description here"
                name={'description'}
                value={formik.values.description}
                onChange={(e: any) => {
                  formik.setFieldValue('description', e.target.value);
                }}
              />
              <Stack spacing={2} direction="row">
                {formik.values.isTeamTask == false ? null : (
                  <Stack>
                    <Typography sx={{ fontSize: '12px', fontWeight: '400', mt: '24px' }}>
                      Share with {label_client.toLowerCase()}
                    </Typography>

                    <IOSSwitch
                      sx={{ mt: '4px' }}
                      name="shareWithClient"
                      checked={formik.values.shareWithClient}
                      onChange={() => formik.setFieldValue('shareWithClient', !formik.values.shareWithClient)}
                    />
                  </Stack>
                )}
                <Stack>
                  <Typography sx={{ fontSize: '12px', fontWeight: '400', mt: '24px' }}>Its a milestone</Typography>
                  <IOSSwitch
                    name="isMilestone"
                    sx={{ mt: '4px' }}
                    checked={formik.values.isMilestone}
                    onChange={() => formik.setFieldValue('isMilestone', !formik.values.isMilestone)}
                  />
                </Stack>
              </Stack>
              <Grid container justifyContent="flex-end">
                {console.log(formik)}
                <Button
                  variant="contained"
                  disabled={!formik.isValid || formik.values.taskName?.length == 0}
                  onClick={() => {
                    formik.handleSubmit();
                    props.onClose();
                  }}
                  sx={{ mt: '10px' }}
                >
                  Save
                </Button>
              </Grid>
            </>
          )}
        </Formik>
        {/* ) : (
          <Dependency formik.values.taskType={'project'} taskUid={tempTaskUid}></Dependency>
        )} */}
      </Box>
    </Drawer>
  );
};

export default AddProjectTemplateTaskDrawer;
