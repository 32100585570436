import { Gantt, GanttStatic } from 'dhtmlx-gantt';
import {
  filterByMilestoneTask,
  getResourceData,
  transformToGanttDependencyFormat,
  transformToGanttMileStoneTaskFormat,
  transformToGanttProjectFormat,
  transformToGanttSectionFormat,
  transformToGanttTaskFormat,
} from './transformers';
import { useAPIForGantt } from './useAPIForGantt';
import { useGantt } from './useGantt';
import { groupByCustomKey } from './utils';

const ignoreUsecaseTask = (item: any) => !item.case_uid;

export const useProjectGantt = () => {
  const gantt: GanttStatic = Gantt.getGanttInstance();
  const { projectForGantt } = useAPIForGantt();

  const gProject = transformToGanttProjectFormat(projectForGantt);
  const sectionListBySectionUid = groupByCustomKey(projectForGantt.all_task, 'section_uid');
  const gSections = projectForGantt.section.map((section: any) =>
    transformToGanttSectionFormat(section, sectionListBySectionUid)
  );
  const gTasks = projectForGantt.all_task.filter(ignoreUsecaseTask).map(transformToGanttTaskFormat);
  const gMilestoneTasks = projectForGantt.all_task
    .filter(ignoreUsecaseTask)
    .filter(filterByMilestoneTask)
    .map(transformToGanttMileStoneTaskFormat);
  const gDependencies = projectForGantt.dependency.map(transformToGanttDependencyFormat);

  const allData = [gProject, ...gSections, ...gTasks, ...gMilestoneTasks];
  const resourceData = gTasks.map(getResourceData);
  const customExportExcelFilename = projectForGantt?.project_name
    ? projectForGantt.project_name.replace(/ /g, '_')
    : 'project';

  // console.log({
  //   projectForGantt,
  //   gDependencies,
  //   gProject,
  //   gSections,
  //   gTasks,
  //   gMilestoneTasks,
  //   allData,
  //   resourceData,
  //   customExportExcelFilename,
  //   sectionListBySectionUid,
  // });

  const ganttDataObj = {
    gDependencies,
    allData,
    resourceData,
    customExportExcelFilename,
    ganttRootElemId: 'gantt_project',
    ganttStartDateBoundary: gProject?.start_date,
    ganttEndDateBoundary: gProject?.end_date,
  };

  const {
    taskData,
    isUpdateTaskDrawerOpen,
    isWorkTimeOn,
    handleClose,
    handleOnZoomIn,
    handleOnZoomOut,
    handleOnExportToExcel,
    handleOnTodayClick,
    handleOnToogleWorkTime,
    handleCriticalPathHighlight,
  } = useGantt(gantt, ganttDataObj);

  return {
    taskData,
    isUpdateTaskDrawerOpen,
    isWorkTimeOn,
    handleClose,
    handleOnZoomIn,
    handleOnZoomOut,
    handleOnExportToExcel,
    handleOnTodayClick,
    handleCriticalPathHighlight,
    handleOnToogleWorkTime,
  };
};
