import { Stack, Typography, IconButton, Grid } from '@mui/material';
import * as React from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CognisaasMenu from '../../../shared/CognisaasMenu/CognisaasMenu';
import { useState } from 'react';
import BasicModal from '../../../shared/CognisaasModal/CognisaasModal';
import NewSubtaskForm from './NewSubtaskForm';
import { useEffect } from 'react';
import Dependency from '../Dependency/Dependency';
import UpdateSubtaskModal from './UpdateSubtaskModal';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../store/hooks';
import { selectCustomerDetails } from '../../../../store/modules/Settings/selector';
import { getCustomerDetail } from '../../../../store/modules/Common/slice';
import {
  deleteProjectSubtask,
  getProjectSubtasks,
  deleteUsecaseSubtask,
  getUsecaseSubtasks,
  addTaskAuditTrail,
} from '../../../../store/modules/Task/slice';

import format from 'date-fns/format';
import Cookies from 'universal-cookie';
import { label_due_date, label_task } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { userPermissionSchema } from 'components/Reports/SideDrawer/types';
import DeleteModal from 'components/shared/CognisaasModal/DeleteModal';
import { getLoggedInUserUid } from 'utils/getUid';

const cookies = new Cookies();

const SubtaskList = (props: any) => {
  const [customerDetails, setCustomerDetails] = useState<any>(null);
  const [addDependenciesModal, setAddDependenciesModal] = useState<boolean>(false);
  const [updateSubtaskModal, setUpdateSubtaskModal] = useState<boolean>(false);
  const [addSubtaskModal, setAddSubtaskModal] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [handleThisTask, setHandleThisTask] = useState<any>(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const dispatch = useDispatch();
  const customerDetail = useAppSelector(selectCustomerDetails);

  const [updateSubtaskData, setUpdateSubtaskData] = useState<any>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (customerDetail) {
      setCustomerDetails({ ...customerDetail });
    } else {
      dispatch(getCustomerDetail());
    }
  }, [customerDetail]);

  const handleExcelClick = (event: React.MouseEvent<HTMLElement>, handleTask: any) => {
    console.log(event.currentTarget, handleTask);

    setHandleThisTask(handleTask);

    setAnchorEl(event.currentTarget);
  };

  const handleExcelClose = () => {
    setAnchorEl(null);
  };

  const handleSelectFromMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
    console.log(event.currentTarget.innerText);
    if (event.currentTarget.innerText == 'Dependencies') {
      setAddDependenciesModal(true);
    } else if (event.currentTarget.innerText == 'Delete Task') {
      setOpenDeleteModal(true);
      // deleteSubtask();
    }

    // if (event.currentTarget.innerText == 'Download') {
    //   console.log('Dowmnload now');

    //   excelExport(clientList, 'Client_List');
    // }
  };

  const callUpdateSubtaskModal = (task: any) => {
    setUpdateSubtaskData({
      ...task,
      project_uid: props.taskDetail.project_uid,
      case_uid: props.taskDetail.case_uid,
      is_team_task: props.taskDetail.is_team_task,
    });
    setUpdateSubtaskModal(true);
  };

  const deleteSubtask = async () => {
    if (props.taskDetail.project_uid && props.taskDetail.case_uid) {
      let details: any;
      const key = btoa(btoa(customerDetails.customer_jira_user + ':' + customerDetails.customer_jira_token));
      details = {
        key: key,
        customer_uid: cookies.get('cogni_customer_uid') as string,
        subtask: true,
        task_uid: handleThisTask.subtask_uid,
      };
      console.log(details);
      // dispatch(deleteProjectTaskFromJiraByIssueId(details));
      details = {
        dependency_details: [],
        subtask_uid: handleThisTask.subtask_uid,
      };
      await dispatch(deleteUsecaseSubtask(details));
      await dispatch(getUsecaseSubtasks(props.taskDetail.task_uid));
    } else {
      let details: any;
      const key = btoa(btoa(customerDetails.customer_jira_user + ':' + customerDetails.customer_jira_token));
      details = {
        key: key,
        customer_uid: cookies.get('cogni_customer_uid') as string,
        subtask: true,
        task_uid: handleThisTask.subtask_uid,
      };
      console.log(details);
      // dispatch(deleteProjectTaskFromJiraByIssueId(details));
      details = {
        dependency_details: [],
        subtask_uid: handleThisTask.subtask_uid,
      };
      await dispatch(deleteProjectSubtask(details));
      await dispatch(getProjectSubtasks(props.taskDetail.task_uid));
    }
    const auditTrail = {
      field: 'Subtask',
      task_uid: props.taskDetail.task_uid,
      user_uid: getLoggedInUserUid(),
      new_value: '',
      old_value: '',
      modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
    };

    dispatch(addTaskAuditTrail(auditTrail));
  };

  return (
    <>
      <Grid>
        <Grid mt={3} container direction="row" justifyContent={'space-around'} alignItems="center">
          <Grid item md={2}>
            <Typography variant="subtitle2" className="label-text">
              {label_task} Name
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="subtitle2" className="label-text">
              {label_task} Owner
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="subtitle2" className="label-text">
              {label_task} Stage
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="subtitle2" className="label-text">
              {label_task} Status
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="subtitle2" className="label-text">
              {label_due_date}
            </Typography>
          </Grid>
          {/* <Grid item md={3}></Grid> */}
        </Grid>
      </Grid>
      {props.subtasks.map((task: any, index: number) => (
        <Grid mb={1} container key={index} direction="row" justifyContent={'space-around'} alignItems="center">
          <Grid item sm={2} md={2}>
            <Typography
              onClick={() => callUpdateSubtaskModal(task)}
              style={{ color: '#2D60F6' }}
              className="cognisaas-pointer"
              variant="subtitle2"
            >
              {task.subtask_detail == ''
                ? ''
                : task.subtask_detail.slice(0, 6).length < 6
                ? task.subtask_detail.slice(0, 6)
                : task.subtask_detail.slice(0, 6) + '...'}
            </Typography>
          </Grid>
          <Grid item sm={2} md={2}>
            <Typography variant="subtitle2">
              {task.subtask_owner == '' ? '' : task.subtask_owner.slice(0, 6) + '...'}
            </Typography>
          </Grid>
          <Grid item sm={2} md={2}>
            <Typography variant="subtitle2">{task.subtask_stage}</Typography>
          </Grid>
          <Grid item sm={2} md={2}>
            <Typography variant="subtitle2">{task.subtask_status}</Typography>
          </Grid>
          <Grid item sm={2} md={2}>
            <Stack direction="row">
              <Typography sx={{ marginTop: '6px' }} variant="subtitle2">
                {format(new Date(task.subtask_due_date.replace(/-/g, '/')), 'dd MMM yyyy')}
              </Typography>
              {props?.userPermissions?.includes(userPermissionSchema.EDIT) && (
                <>
                  <IconButton onClick={(e: any) => handleExcelClick(e, task)} sx={{ margin: '0px' }}>
                    <MoreVertIcon />
                  </IconButton>
                  <CognisaasMenu
                    anchorEl={anchorEl}
                    open={open}
                    handleSelectFromMenu={handleSelectFromMenu}
                    handleMenuClose={handleExcelClose}
                    list={['Delete Task']}
                  />
                </>
              )}
            </Stack>
          </Grid>
          {/* <Grid item sm={1} md={1}>
            <IconButton onClick={handleExcelClick} sx={{ margin: '0px' }}>
              <MoreVertIcon />
            </IconButton>
            <CognisaasMenu
              anchorEl={anchorEl}
              open={open}
              handleSelectFromMenu={handleSelectFromMenu}
              handleMenuClose={handleExcelClose}
              list={['History', 'Dependencies', 'Time Tracking', 'Delete Task']}
            ></CognisaasMenu>
          </Grid> */}
        </Grid>
      ))}
      <Grid item md={2}></Grid>

      {/* {props.subtasks.map((task: any, index: number) => (
        <Stack key={index}>
          <Stack mx={3} mt={2} justifyContent="space-around" direction="row">
            <Stack spacing={2}>
              <Typography variant="subtitle2">
                {task.subtask_detail == '' ? 'test1' : task.subtask_detail.slice(0, 6)}
              </Typography>
            </Stack>

            <Typography variant="subtitle2">
              {task.subtask_owner == '' ? 'test1' : task.subtask_owner.slice(0, 6)}
            </Typography>
            <Typography variant="subtitle2">{task.subtask_stage}</Typography>
            <Typography variant="subtitle2">{task.subtask_status}</Typography>
            <Typography variant="subtitle2">Task Status</Typography>
            <Typography variant="subtitle2">{task.subtask_due_date}</Typography>
            <Stack>
              <IconButton onClick={handleExcelClick} sx={{ margin: '0px' }}>
                <MoreVertIcon />
              </IconButton>
              <CognisaasMenu
                anchorEl={anchorEl}
                open={open}
                handleSelectFromMenu={handleSelectFromMenu}
                handleMenuClose={handleExcelClose}
                list={['History', 'Dependencies', 'Time Tracking', 'Delete Task']}
              ></CognisaasMenu>
            </Stack>
          </Stack>
          <Divider />
        </Stack>
      ))} */}
      {props?.userPermissions?.includes(userPermissionSchema.EDIT) && (
        <Stack m={2}>
          <Typography
            style={{ color: '#2D60F6' }}
            className="cognisaas-pointer"
            onClick={() => setAddSubtaskModal(true)}
          >
            Add Sub {label_task}
          </Typography>
        </Stack>
      )}
      <BasicModal height={552} width={690} open={addSubtaskModal} onClose={() => setAddSubtaskModal(!addSubtaskModal)}>
        <NewSubtaskForm onClose={() => setAddSubtaskModal(!addSubtaskModal)} taskDetail={props.taskDetail} />
      </BasicModal>

      <BasicModal
        height={652}
        width={790}
        open={addDependenciesModal}
        onClose={() => setAddDependenciesModal(!addDependenciesModal)}
      >
        <Dependency onClose={() => setAddDependenciesModal(!addDependenciesModal)} />
      </BasicModal>

      <BasicModal
        height={552}
        width={690}
        open={updateSubtaskModal}
        onClose={() => setUpdateSubtaskModal(!addDependenciesModal)}
      >
        <UpdateSubtaskModal onClose={() => setUpdateSubtaskModal(!updateSubtaskModal)} taskDetail={updateSubtaskData} />
      </BasicModal>
      <DeleteModal
        isOpen={openDeleteModal}
        setIsOpen={setOpenDeleteModal}
        message={`Are you sure you want to delete this sub ${label_task.toLowerCase()}`}
        onDelete={() => {
          deleteSubtask();
        }}
      />
    </>
  );
};

export default SubtaskList;
