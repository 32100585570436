import { Stack, Typography, Menu, MenuItem } from '@mui/material';
import React from 'react';
import { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
// import Popover from '@mui/material/Popover';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import Button from '@mui/material/Button';
import { useAppSelector } from 'store/hooks';
import { getUserData_selector } from 'store/modules/Settings/selector';
import { default as AvatarImage } from 'components/shared/Avatar/Avatar';
import { getLoggedInUserName } from 'utils/getUid';
import { useNavigate } from 'react-router-dom';
import { logoutUser, invalidateRefreshToken } from 'store/modules/auth/auth';
import { useDispatch } from 'react-redux';
import '../TheToolBar.css';

const env: string = process.env.REACT_APP_ENV ?? 'local';

function stringToHslColor(str: string, s: number, l: number) {
  let hash = 0;

  for (let i = 0; i < str?.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const h = hash % 360;
  return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
}

function stringAvatar(name: string) {
  return name
    ? {
        sx: {
          bgcolor: stringToHslColor(name, 30, 80),
        },
        children: (
          <Typography
            // className="avatar-text"
            // className={`${getLoggedInUserName().split(' ').length === 1 ? 'avatar-text-long-name' : 'avatar-text'}`}
            sx={{ color: '#000000', fontSize: name.indexOf(' ') >= 0 ? '15px' : '20px' }}
          >
            {name.split(' ')[0][0]}
            {name.indexOf(' ') >= 0 ? name.split(' ')[1][0] : null}
          </Typography>
        ),
      }
    : '';
}

const MenuItems = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const userFullDetail = useAppSelector(getUserData_selector);
  const [redirectToLogin, setRedirectToLogin] = useState(false);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnLogout = async () => {
    await dispatch(invalidateRefreshToken());
    await dispatch(logoutUser());
    setRedirectToLogin(true);
  };

  useEffect(() => {
    console.log('redirect 1234567890 = ', redirectToLogin);
    console.log('i was in menu items');
    if (redirectToLogin) {
      if (env == 'local') {
        console.log('redirecting to localhost');
        document.location.href = 'http://localhost:3000/login';
      } else if (env == 'dev') {
        document.location.href = 'https://app.devng.cognisaas.com/login';
      } else if (env == 'prod') {
        document.location.href = 'https://app.ng.cognisaas.com/login';
      } else if (env == 'new-prod') {
        document.location.href = 'https://app.cognisaas.net/login';
      }
    }
  }, [redirectToLogin]);

  const userName = getLoggedInUserName();

  return (
    <Stack direction="row" aria-describedby={id}>
      {userFullDetail.data &&
      userFullDetail.data.user_profile_pic_link &&
      userFullDetail.data.user_profile_pic_link.length > 0 ? (
        <span onClick={(event: any) => handleClick(event)}>
          <AvatarImage src={userFullDetail?.data?.user_profile_pic_link} alt="user-profil" width="30px" height="30px" />
        </span>
      ) : (
        // <Avatar
        //   sx={{ bgcolor: '#F9D1A2', color: 'black', width: '30px', height: '30px', fontSize: '0.8rem' }}
        //   onClick={(event: any) => handleClick(event)}
        // >
        //   {localStorage.getItem('user_name')?.slice(0, 2)}
        // </Avatar>
        <Avatar
          onClick={(event: any) => handleClick(event)}
          // className="avatar-text"
          // className={`${getLoggedInUserName().split(' ').length == 1 ? 'avatar-text' : ''}`}
          style={{
            height: '34px',
            width: '34px',
            marginTop: '3px',
          }}
          {...stringAvatar(userName)}
        />
      )}
      <Menu
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{ marginTop: '6px' }}
      >
        <MenuItem dense={true} onClick={() => navigate('/settings/profile')}>
          <Typography variant="subtitle1">My Profile</Typography>
        </MenuItem>
        <MenuItem dense={true} onClick={() => handleOnLogout()}>
          <Typography variant="subtitle1">Logout</Typography>
        </MenuItem>
      </Menu>
    </Stack>
  );
};

export default MenuItems;
