import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Stack,
  Typography,
  Paper,
  TextField,
  Button,
  Modal,
  // Snackbar,
  // Alert,
  // AlertColor,
} from '@mui/material';
import { CustomButton, CustomLink } from '../GlobalStyles';
// import { useLocation } from 'react-router-dom';

import { ReactComponent as BrandLogo } from '../../assets/svgs/BrandLogo.svg';
import { ReactComponent as GoogleLogo } from '../../assets/svgs/GoogleLogo.svg';
import { ReactComponent as MicrosoftLogo } from '../../assets/svgs/microsoftIcon.svg';

import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { useFormik } from 'formik';

import { loginCredentialsSchema } from '../../utils/validations';
import {
  loginUser,
  loginUserWithSSO,
  redirectAfterAuth,
  requestForgotPasswordEmail,
} from '../../store/modules/auth/auth';
import { selectEmailStatus, selectErrorMessage, selectAuthState } from '../../store/modules/auth/selector';
import { GoogleLogin } from 'react-google-login';
import MicrosoftLogin from 'react-microsoft-login';
import CognisaasToast from 'components/shared/CognisaasToast/CognisaasToast';
import Cookies from 'universal-cookie';
import { fetchCustomLabel } from 'store/modules/Common/slice';
import { makeLabelCookies } from 'components/Settings/Preferences/CustomLabelCookieHelper';

const clientId: string = process.env.REACT_APP_GOOGLE_CLIENT_ID ? process.env.REACT_APP_GOOGLE_CLIENT_ID : '';
const microsoftId: string = process.env.REACT_APP_MICROSOFT_CLIENT_ID ? process.env.REACT_APP_MICROSOFT_CLIENT_ID : '';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '350px',
  bgcolor: 'background.paper',
  //   border: '2px solid #000',
  boxShadow: 24,
  padding: '20px',
};

const Login_v1: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // const windowHeight = window.screen.height;
  // const { pathname } = useLocation();
  // const base_url = window.location.host + pathname;

  const isAuthed = useAppSelector(selectAuthState);
  const error = useAppSelector<any>(selectErrorMessage);
  const isEmailValidNew = useAppSelector(selectEmailStatus);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openNew, setOpenNew] = React.useState(false);
  const handleCloseNew = () => setOpenNew(false);

  const [errorMessageNew, setErrorMessageNew] = useState<any>('');
  const [userEmail, setUserEmail] = useState<string>('');
  const [forgotPasswordTrigger, setForgotPasswordTrigger] = useState<boolean>(false);

  const [runGoogleDispatch, setRunGoogleDispatch] = useState(false);
  const [runMicrosoftDispatch, setMicrosoftDispatch] = useState(false);
  const [alertDetails, setAlertDetails] = useState({ isVisible: false, severity: 'error', detail: '', message: '' });

  console.log('new login', isAuthed);

  const responseGoogle = (response: any) => {
    if (response.accessToken) {
      const reqObj = {
        access_token: response.tokenId,
        base_url: window.location.host + '/complete-account/',
        signup_method: 'google',
      };
      dispatch(loginUserWithSSO(reqObj))
        .unwrap()
        .then(async (data) => {
          console.log('message', data);
          if (data['status'] == 0) {
            setAlertDetails({
              isVisible: true,
              detail: data['message'],
              severity: 'error',
              message: '',
            });
            console.log('coming here');
          }
        })
        .catch((err) => {
          console.log('err', err);
          setAlertDetails({ isVisible: true, severity: 'error', detail: 'Please try again later', message: '' });
        });
    }
  };

  const microsoftAuthHandler = (err: any, data: any) => {
    console.log(data, err, 'I am called');
    if (data) {
      const reqObj = {
        access_token: data.accessToken,
        base_url: window.location.host + '/complete-account/',
        signup_method: 'microsoft',
      };
      console.log(data, 'here new data');
      dispatch(loginUserWithSSO(reqObj))
        .unwrap()
        .then(async (data) => {
          console.log('message is unique', data);
          if (data.status == 0) {
            // setAlertDetails({
            //   isVisible: true,
            //   message: data['message'],
            //   severity: 'error',
            // });
          } else {
            await dispatch(fetchCustomLabel())
              .unwrap()
              .then((data) => {
                console.log('data after success', data);
                if (data.status === 200) {
                  //do whatever with cookies and setting
                  console.log('1');
                  if (data.data.result.length > 0) {
                    makeLabelCookies(data.data.result);
                    const jwt = new Cookies().get('jwt-token');
                    redirectAfterAuth(jwt);
                    console.log('3');
                  } else if (data.data.result.length === 0) {
                    makeLabelCookies([]);

                    const jwt = new Cookies().get('jwt-token');
                    redirectAfterAuth(jwt);
                  } else {
                    setAlertDetails({
                      isVisible: true,
                      severity: 'error',
                      detail: 'some error in custom labels',
                      message: '',
                    });
                  }
                } else {
                  setAlertDetails({
                    isVisible: true,
                    severity: 'error',
                    detail: 'Please try again later',
                    message: '',
                  });
                }
              })
              .catch((err) => {
                setAlertDetails({ isVisible: true, severity: 'error', detail: err, message: '' });
              });
          }
        })
        .catch((err) => {
          console.log('err', err);
          setAlertDetails({ isVisible: true, severity: 'error', detail: 'Please try again later', message: '' });
        });
    } else {
      console.log(err);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      application: 'cognisaas_app',
    },
    validationSchema: loginCredentialsSchema,
    onSubmit: (e) => {
      // setLoadingButton(true);
      // setErrorMessageNew(false);
      dispatch(loginUser(e))
        .unwrap()
        .then(async (data) => {
          console.log('message', data);

          // navigation is handled in store here only api call needed just after login is handled
          // dispatch()

          // console.log('backtrack-1');
          // const data: any = await dispatch(fetchCustomLabel()).unwrap();
          // await dispatch(fetchCustomLabel())
          //   .unwrap()
          //   .then((data) => {
          //     console.log('data after success', data);
          //     if (data.status === 200) {
          //       //do whatever with cookies and setting
          //       // console.log('dataaaa', data);
          //       console.log('1');
          //       if (data.data.result.length > 0) {
          //         // console.log(data.data.result[0]);
          //         // aman jain
          //         // data.data.result.forEach((item: any) => {
          //         //   setLabelCookies(item.standard_value, item.custom_value);
          //         // });

          //         makeLabelCookies(data.data.result);
          //         const jwt = new Cookies().get('jwt-token');
          //         redirectAfterAuth(jwt);
          //         console.log('3');
          //       } else if (data.data.result.length === 0) {
          //         // staticCustomLabelData.forEach((item: any) => {
          //         //   setLabelCookies(item.standard_value, item.custom_value);
          //         // });

          //         makeLabelCookies([]);

          //         const jwt = new Cookies().get('jwt-token');
          //         redirectAfterAuth(jwt);
          //       } else {
          //         setAlertDetails({
          //           isVisible: true,
          //           severity: 'error',
          //           detail: 'some error in custom labels',
          //           message: '',
          //         });
          //       }
          //     } else {
          //       setAlertDetails({ isVisible: true, severity: 'error', detail: 'Please try again later', message: '' });
          //     }
          //   })
          //   .catch((err) => {
          //     setAlertDetails({ isVisible: true, severity: 'error', detail: err, message: '' });
          //     console.log(err, 'backtrack-2');
          //   });

          // console.log('backtrack-3');
        })
        .catch((err) => {
          console.log('err', err);
          setAlertDetails({ isVisible: true, severity: 'error', detail: err.detail, message: '' });
        });
      console.log(e);
      formik.resetForm();
    },
  });

  const onButtonClick = () => {
    setForgotPasswordTrigger(true);
    setOpen(false);
  };

  const handleClickSubmit = () => {
    setErrorMessageNew(false);
  };

  useEffect(() => {
    if (forgotPasswordTrigger) {
      const requestPasswordDetails = {
        email: userEmail,
        application: 'cognisaas_app',
      };
      dispatch(requestForgotPasswordEmail(requestPasswordDetails))
        .unwrap()
        .then((message) => {
          console.log('this is inside then');
          setAlertDetails({
            isVisible: true,
            severity: 'success',
            detail: message,
            message: '',
          });
        })
        .catch((error) => {
          console.log('this is inside catch');
          console.log(error);
          setAlertDetails({ isVisible: true, severity: 'error', detail: 'Please try again later', message: '' });
        });
      setForgotPasswordTrigger(false);
    }
  }, [forgotPasswordTrigger]);

  useEffect(() => {
    if (error) {
      setAlertDetails({ isVisible: true, severity: 'error', detail: error.payload.detail, message: '' });
      // setLoadingButton(false);
    }
    // setObjectToSend({ email: isEmailValid, base_url: window.location.host + '/reset-password/' });
    // if (isEmailValidNew === true) {
    //   dispatch(sendForgotPasswordEmail(objectToSend));
    //   handleClose();
    //   123();
    // }
    console.log(error, 'error');
    if (runGoogleDispatch) {
      setErrorMessageNew(false);
      setRunGoogleDispatch(false);
    }
  }, [runGoogleDispatch, isAuthed, error, errorMessageNew, isEmailValidNew]);

  useEffect(() => {
    if (runMicrosoftDispatch) {
      setErrorMessageNew(false);
    }
    setMicrosoftDispatch(false);
  }, [runMicrosoftDispatch]);

  return (
    <Box>
      {alertDetails.isVisible ? (
        alertDetails?.detail?.length > 0 ? (
          <CognisaasToast
            open={alertDetails.isVisible}
            onClose={() => setAlertDetails({ isVisible: false, severity: 'error', detail: '', message: '' })}
            message={
              alertDetails.detail == 'Request failed with status code 400'
                ? 'Your plan has expired'
                : alertDetails.detail
            }
            type={alertDetails.severity == 'success' ? 'success' : 'failure'}
          ></CognisaasToast>
        ) : (
          <CognisaasToast
            open={alertDetails.isVisible}
            onClose={() => setAlertDetails({ isVisible: false, severity: 'error', detail: '', message: '' })}
            message={
              alertDetails.message == 'Request failed with status code 400'
                ? 'Your plan has expired'
                : alertDetails.message
            }
            type={alertDetails.severity == 'success' ? 'success' : 'failure'}
          ></CognisaasToast>
        )
      ) : null}

      <Grid container>
        <Grid item xs={9} sx={{ backgroundColor: '#F5F5F7', height: '100vh', paddingLeft: '110px' }}>
          {/* <img style={{ width: '326px', height: '60px', marginTop: '372px' }} src={BrandLogo} alt="COGNISAAS" /> */}
          <BrandLogo style={{ width: '326px', height: '60px', marginTop: '30vh' }} />
          <Typography sx={{ fontSize: '44px', fontWeight: '600', marginTop: '45px', lineHeight: '70.8px' }}>
            Deliver faster time-to-value{' '}
          </Typography>
          <Typography sx={{ fontSize: '44px', fontWeight: '600', lineHeight: '70.8px' }}>for your customers</Typography>
          <Typography sx={{ fontSize: '28px', fontWeight: '400', color: '#7A7A7A', lineHeight: '45px' }}>
            by collaborating with cross-functional teams on a
          </Typography>
          <Typography sx={{ fontSize: '28px', fontWeight: '400', color: '#7A7A7A', lineHeight: '45px' }}>
            common platform.
          </Typography>
        </Grid>
        <Grid item xs={3} sx={{ backgroundColor: '#F5F5F7', margin: '0px', padding: '0px', height: '100vh' }}>
          <Paper
            // elevation={1}
            sx={{
              position: 'absolute',
              backgroundColor: '#FFFFFF',
              width: '410px',
              maxHeight: '80vh',
              left: '60vw',
              top: '10vh',
              padding: '30px 20px',
              borderRadius: '4px',
              boxShadow: '0px 4px 26px rgba(0, 0, 0, 0.05);',
              overflowY: 'scroll',
              overflowX: 'hidden',

              ['@media (min-width:1800px)']: {
                left: '64vw',
                top: '14vh',
              },
            }}
          >
            <Typography sx={{ fontSize: '20px', fontWeight: '600' }}>Login</Typography>
            <form onSubmit={formik.handleSubmit}>
              <Typography sx={{ marginTop: '10px', fontSize: '12px', fontWeight: '400' }}>Email</Typography>
              <TextField
                sx={{
                  marginTop: '4px',
                  '.MuiFormHelperText-root': {
                    marginLeft: 0,
                  },
                }}
                fullWidth
                size="small"
                placeholder="Email"
                variant="outlined"
                id="user_email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <Typography sx={{ marginTop: '24px', fontSize: '12px', fontWeight: '400' }}>Password</Typography>
              <TextField
                sx={{
                  marginTop: '4px',
                  '.MuiFormHelperText-root': {
                    marginLeft: 0,
                  },
                }}
                fullWidth
                size="small"
                // id="outlined-basic"
                placeholder="Password"
                variant="outlined"
                id="password"
                name="password"
                // label="Password"
                type="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
              />
              <Typography
                sx={{ marginTop: '12px', color: '#2D60F6', cursor: 'pointer', fontSize: '14px', fontWeight: '400' }}
                onClick={handleOpen}
              >
                Forgot Password?
              </Typography>

              <CustomButton onClick={() => handleClickSubmit()}>Login</CustomButton>

              <Typography
                sx={{ fontSize: '12px', color: '#7A7A7A', fontWeight: '400', marginTop: '8px', textAlign: 'center' }}
              >
                By continuing, you agree to Cognisaas&apos;s{' '}
                <CustomLink href="https://www.cognisaas.com/terms" target="_blank">
                  Terms
                </CustomLink>{' '}
                and{' '}
                <CustomLink href="https://www.cognisaas.com/privacy" target="_blank">
                  Privacy Policy
                </CustomLink>
              </Typography>
            </form>
            <Box display="flex" justifyContent="center" alignItems="center" sx={{ width: '100%' }}>
              <Typography sx={{ marginTop: '12px', color: '#7A7A7A', fontWeight: '400' }}>or</Typography>
            </Box>
            <GoogleLogin
              clientId={clientId}
              render={(renderProps) => (
                <Button
                  sx={{
                    width: '100%',
                    height: '44px',
                    color: '#000000',
                    marginTop: '12px',
                    border: '1px solid #E5E5E5',
                    fontSize: '14px',
                    fontWeight: '400',
                  }}
                  variant="outlined"
                  startIcon={<GoogleLogo />}
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                >
                  {console.log('i am running')}
                  Sign in with Google
                </Button>
              )}
              buttonText="Login"
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
              cookiePolicy={'single_host_origin'}
            />

            {
              // <MicrosoftLogin clientId={microsoftId} authCallback={microsoftAuthHandler} />
              <MicrosoftLogin clientId={microsoftId} authCallback={microsoftAuthHandler}>
                <Button
                  sx={{
                    width: '100%',
                    height: '44px',
                    color: '#000000',
                    marginTop: '12px',
                    border: '1px solid #E5E5E5',
                    fontSize: '14px',
                    fontWeight: '400',
                  }}
                  variant="outlined"
                  startIcon={<MicrosoftLogo />}
                >
                  Sign in with Microsoft
                </Button>
              </MicrosoftLogin>
            }
            <Box display="flex" justifyContent="center" alignItems="center" sx={{ marginTop: '36px', width: '100%' }}>
              <Stack direction="row">
                <Typography sx={{ fontSize: '14px', fontWeight: '400' }}>Don&apos;t have an account?</Typography>
                <Typography
                  sx={{ fontSize: '14px', fontWeight: '400', color: '#2D60F6', cursor: 'pointer', marginLeft: '5px' }}
                  onClick={() => navigate('/signup')}
                >
                  Sign up
                </Typography>
              </Stack>
            </Box>
          </Paper>
        </Grid>
        <div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography sx={{ fontSize: '18px', fontWeight: '500' }}>Forgot Password?</Typography>
              <Typography sx={{ mt: '24px', fontSize: '12px', fontWeight: '400' }}>Confirm Email Address</Typography>
              <TextField
                variant="outlined"
                size="small"
                sx={{ width: '310px', marginTop: '4px', borderRadius: '4px', border: '1px solid #E5E5E5' }}
                autoFocus
                onChange={(e) => setUserEmail(e.target.value)}
              />
              <Grid container justifyContent="flex-end">
                <Button
                  variant="contained"
                  sx={{
                    mt: '32px',
                    backgroundColor: '#2D60F6',
                    color: '#FFFFFF',
                    borderRadius: '4px',
                    height: '44px',
                    width: '164px',
                  }}
                  onClick={() => onButtonClick()}
                >
                  <Typography sx={{ fontSize: '14px', fontWeight: '400' }}>Request a reset link</Typography>
                </Button>
              </Grid>
            </Box>
          </Modal>
          <Modal
            open={openNew}
            onClose={handleCloseNew}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography sx={{ fontSize: '18px', fontWeight: 500 }}>Email Sent</Typography>
              <Grid container justifyContent="flex-end">
                <Button
                  variant="outlined"
                  sx={{
                    mt: '32px',
                    backgroundColor: '#FFFFFF',
                    color: '#2D60F6',
                    border: '1px solid #2D60F6',
                    borderRadius: '4px',
                    height: '44px',
                    width: '68px',
                  }}
                  onClick={() => handleCloseNew()}
                >
                  <Typography sx={{ fontSize: '14px', fontWeight: '400' }}>Close</Typography>
                </Button>
              </Grid>
            </Box>
          </Modal>
        </div>
      </Grid>
    </Box>
  );
};

export default Login_v1;
