import moment from 'moment';
import { addToDate } from '../helpers/date-helper';

export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const DATETEXT_FORMAT = 'DD MMM YYYY';

export function setDate(num: number, date = moment().format(DATE_FORMAT), viewMode?: string) {
  return _resolveDate(num, date, viewMode);
}

function _resolveDate(num: number, date?: string, viewMode?: string) {
  let selectDate = null;
  let startDate = null;
  let endDate = null;
  if (date !== undefined) selectDate = moment(date).format(DATE_FORMAT);
  if (viewMode === 'Month') {
    startDate = moment(selectDate).add(num, 'year').format(DATE_FORMAT);
    endDate = moment(startDate).endOf('year').format(DATE_FORMAT);
  } else {
    startDate = moment(selectDate).add(num, 'month').format(DATE_FORMAT);
    endDate = moment(startDate).endOf('month').format(DATE_FORMAT);
  }
  // console.log('_resolveDate', { startDate, endDate, selectDate });
  return { startDate, endDate, selectDate: startDate };
}

export function getShortDate(date: Date) {
  return moment(date).format(DATE_FORMAT);
}

export function getDateInYYYYMMDD(date: Date) {
  const dateWithoutTime = moment(date).format(DATE_FORMAT);
  return dateWithoutTime;
}

export function getStartDateInYYYYMMDD(date: Date) {
  const targetDate = moment(date).startOf('day').format(DATETIME_FORMAT);
  return targetDate;
}

export function getEndDateInYYYYMMDD(date: Date) {
  const targetDate = moment(date).endOf('day').format(DATETIME_FORMAT);
  return targetDate;
}

export function getIsCurrentDateLastOne(dateIndex: number, date: Date, dates: Date[]) {
  const now = new Date();
  return (
    dateIndex !== 0 &&
    dateIndex + 1 === dates.length &&
    date.getTime() < now.getTime() &&
    addToDate(date, date.getTime() - dates[dateIndex - 1].getTime(), 'millisecond').getTime() >= now.getTime()
  );
}
