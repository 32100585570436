import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../..';

export const selectClosedTasksState = (state: RootState) => state.closedTasksState;

export const selectClosedTasks = createSelector(
  selectClosedTasksState,
  (closedTasksState) => closedTasksState.getClosedTasks
);
export const selectClosedTasksStatus = createSelector(
  selectClosedTasksState,
  (closedTasksState) => closedTasksState.getClosedTasksStatus
);
export const selectClosedTasksError = createSelector(
  selectClosedTasksState,
  (closedTasksState) => closedTasksState.getClosedTasksError
);
