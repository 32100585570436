import { useState } from 'react';

import { Typography, Box, Stack } from '@mui/material';

import Roles from 'components/RBACv2/container/Roles/Roles';
import CognisaasTabs from 'components/shared/CognisaasTabs';
import Permissions from 'components/RBACv2/container/Permissions/Permissions';
import CognisaasButton from 'components/shared/CognisaasButton/CognisaasButton';
import { RBACType } from 'components/RBACv2/types';
import AddRoles from 'components/RBACv2/container/Roles/AddRoles';
import AddPolicy from 'components/RBACv2/container/Permissions/AddPolicy';
import AddCondition from 'components/RBACv2/container/Permissions/AddCondition';

export default function AccessManagement() {
  const [tabValue, setTabValue] = useState<number>(0);
  const [currentRBACTab, setCurrentRBACTab] = useState<RBACType>('Role'); // Manages state between  Roles, Condition and Policy
  const [addState, setAddState] = useState<boolean>(false);

  const tabs = ['Roles', 'Permissions'];

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (newValue === 0) setCurrentRBACTab('Role');
    setTabValue(newValue);
  };

  if (addState) {
    if (currentRBACTab === 'Role') {
      return <AddRoles setAddState={setAddState} />;
    } else if (currentRBACTab === 'Policy') {
      return <AddPolicy setAddState={setAddState} />;
    } else if (currentRBACTab === 'Condition') {
      return <AddCondition setAddState={setAddState} />;
    }
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h1" style={{ margin: '20px 30px' }}>
          Access Management
        </Typography>
        <CognisaasButton
          label={`Add ${currentRBACTab}`}
          isOutlined={false}
          clickHandler={() => setAddState(true)}
          sx={{ height: '40px', marginRight: '20px', marginTop: '20px' }}
        />
      </Stack>
      <Box style={{ marginLeft: '30px' }}>
        <CognisaasTabs selectedTabIndex={tabValue} handleTabSwitch={handleChange} tabs={tabs} />
      </Box>
      {tabValue === 0 ? (
        <Roles />
      ) : tabValue === 1 ? (
        <Permissions currentRBACTab={currentRBACTab} setCurrentRBACTab={setCurrentRBACTab} />
      ) : null}
    </Box>
  );
}
