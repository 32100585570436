import { apis } from '../axiosInstance';
import { templateRoutes } from '../route';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const getProjectTemplates = () =>
  apis.phpAxiosInstance.get(templateRoutes.getProjectTemplates(cookies.get('cogni_customer_uid') as string), {
    headers: { JWT: 'true' },
  });

const getUseCaseTemplatesbycustomer = () =>
  apis.phpAxiosInstance.get(templateRoutes.getUseCaseTemplatesbycustomer(cookies.get('cogni_customer_uid') as string), {
    headers: { JWT: 'true' },
  });

const getCustDetails = () =>
  apis.phpAxiosInstance.get(templateRoutes.getCustDetails(cookies.get('cogni_customer_uid') as string), {
    headers: { JWT: 'true' },
  });

const syncProductsandFeaturesFromJIRAbyOneCustomer = () =>
  apis.phpAxiosInstance.get(
    templateRoutes.syncProductsandFeaturesFromJIRAbyOneCustomer(cookies.get('cogni_customer_uid') as string),
    {
      headers: { JWT: 'true' },
    }
  );

const getTemplateProjectinformation = (template_project_uid: string) =>
  apis.phpAxiosInstance.get(templateRoutes.getTemplateProjectinformation(template_project_uid), {
    headers: { JWT: 'true' },
  });

const getTemplateUsecaseinformation = (template_project_uid: string) =>
  apis.phpAxiosInstance.get(templateRoutes.getTemplateUsecaseInformation(template_project_uid), {
    headers: { JWT: 'true' },
  });

const addTemplateProject = (template_owner_name: any) =>
  apis.phpAxiosInstance.post(
    templateRoutes.addTemplateProject(),
    {
      check_duration: 0,
      customer_uid: cookies.get('cogni_customer_uid') as string,
      template_owner_name: template_owner_name,
      template_owner_uid: cookies.get('cogni_user_uid') as string,
      template_project_rollout: 0,
    },
    { headers: { JWT: 'true' } }
  );

const addTemplateDocument = (payload: any) =>
  apis.phpAxiosInstance.post(templateRoutes.addTemplateDocument(), payload, { headers: { JWT: 'true' } });

const deleteTemplateDocument = (payload: any) =>
  apis.phpAxiosInstance.post(templateRoutes.deleteTemplateDocument(), payload, { headers: { JWT: 'true' } });

const getTemplateDocumentByCustomerUid = (customer_uid: string) =>
  apis.phpAxiosInstance.get(templateRoutes.getTemplateDocumentByCustomerUid(customer_uid), {
    headers: { JWT: 'true' },
  });

const saveTemplateProjectSection = (phaseObject: any) =>
  apis.phpAxiosInstance.post(
    templateRoutes.saveTemplateProjectSection(),
    {
      template_section_detail: phaseObject.template_section_detail,
      template_project_uid: phaseObject.template_project_uid,
      template_section_sequence: phaseObject.template_section_sequence,
    },
    { headers: { JWT: 'true' } }
  );

const getAllSectionByTemplateProjectuid = (template_project_uid: any) =>
  apis.phpAxiosInstance.post(
    templateRoutes.getAllSectionByTemplateProjectuid(),
    { template_project_uid: template_project_uid },
    {
      headers: { JWT: 'true' },
    }
  );

const addTemplateProjectTask = (taskObj: any) =>
  apis.phpAxiosInstance.post(
    templateRoutes.addTemplateProjectTask(),
    {
      template_project_uid: taskObj.template_project_uid,
      template_task_detail: taskObj.template_task_detail,
      template_task_priority: taskObj.template_task_priority,
      template_task_owner: taskObj.template_task_owner,
      template_milestone_task: taskObj.template_milestone_task,
      template_task_description: taskObj.template_task_description,
      template_task_percentage: taskObj.template_task_percentage,
      clone_template_task_uid: taskObj.clone_template_task_uid,
      template_section_uid: taskObj.template_section_uid,
      days_to_start: taskObj.days_to_start,
      task_sequence: taskObj.task_sequence,
      user_uid: taskObj.user_uid,
      template_task_client_check: taskObj.template_task_client_check,
    },
    { headers: { JWT: 'true' } }
  );

const updateTemplateProjectTaskbyuid = (taskObj: any) =>
  apis.phpAxiosInstance.post(
    templateRoutes.updateTemplateProjectTaskbyuid(),
    {
      template_project_uid: taskObj.template_project_uid,
      template_task_uid: taskObj.template_task_uid,
      template_task_detail: taskObj.template_task_detail,
      template_task_priority: taskObj.template_task_priority,
      template_milestone_task: taskObj.template_milestone_task,
      template_task_description: taskObj.template_task_description,
      template_task_percentage: taskObj.template_task_percentage,
      days_to_start: taskObj.days_to_start,
      template_task_owner: taskObj.template_task_owner,
      user_uid: taskObj.user_uid,
      template_task_client_check: taskObj.template_task_client_check,
    },
    { headers: { JWT: 'true' } }
  );

const updateTemplateProjectClienttaskByuid = (taskObj: any) =>
  apis.phpAxiosInstance.post(
    templateRoutes.updateTemplateProjectClienttaskByuid(),
    {
      template_project_uid: taskObj.template_project_uid,
      template_task_uid: taskObj.template_task_uid,
      template_task_detail: taskObj.template_task_detail,
      template_task_priority: taskObj.template_task_priority,
      template_milestone_task: taskObj.template_milestone_task,
      template_task_description: taskObj.template_task_description,
      days_to_start: taskObj.days_to_start,
    },
    { headers: { JWT: 'true' } }
  );

const updateTemplateUsecaseTaskbyuid = (taskObj: any) =>
  apis.phpAxiosInstance.post(
    templateRoutes.updateTemplateUsecaseTaskbyuid(),
    {
      template_case_uid: taskObj.template_case_uid,
      template_task_uid: taskObj.template_task_uid,
      template_task_detail: taskObj.template_task_detail,
      template_task_priority: taskObj.template_task_priority,
      template_milestone_task: taskObj.template_milestone_task,
      template_task_description: taskObj.template_task_description,
      template_task_percentage: taskObj.template_task_percentage,
      days_to_start: taskObj.days_to_start,
      template_task_owner: taskObj.template_task_owner,
      user_uid: taskObj.user_uid,
      template_task_client_check: taskObj.template_task_client_check,
    },
    { headers: { JWT: 'true' } }
  );

const updateTemplateUsecaseClienttaskByuid = (taskObj: any) =>
  apis.phpAxiosInstance.post(
    templateRoutes.updateTemplateUsecaseClienttaskByuid(),
    {
      template_case_uid: taskObj.template_case_uid,
      template_task_uid: taskObj.template_task_uid,
      template_task_detail: taskObj.template_task_detail,
      template_task_priority: taskObj.template_task_priority,
      template_milestone_task: taskObj.template_milestone_task,
      template_task_description: taskObj.template_task_description,
      days_to_start: taskObj.days_to_start,
    },
    { headers: { JWT: 'true' } }
  );

const addTemplateProjectClientTask = (clientTaskObj: any) =>
  apis.phpAxiosInstance.post(
    templateRoutes.addTemplateProjectClientTask(),
    {
      template_project_uid: clientTaskObj.template_project_uid,
      template_task_detail: clientTaskObj.template_task_detail,
      template_task_priority: clientTaskObj.template_task_priority,
      template_task_owner: clientTaskObj.template_task_owner,
      template_milestone_task: clientTaskObj.template_milestone_task,
      template_task_description: clientTaskObj.template_task_description,
      template_task_percentage: clientTaskObj.template_task_percentage,
      clone_template_task_uid: clientTaskObj.clone_template_task_uid,
      template_section_uid: clientTaskObj.template_section_uid,
      days_to_start: clientTaskObj.days_to_start,
      task_sequence: clientTaskObj.task_sequence,
    },
    { headers: { JWT: 'true' } }
  );

const updateTemplateProjectByUid = (projectTemplateObject: any) =>
  apis.phpAxiosInstance.post(
    templateRoutes.updateTemplateProjectByUid(),
    {
      template_project_name: projectTemplateObject.template_project_name,
      template_project_rollout: projectTemplateObject.template_project_rollout,
      check_duration: projectTemplateObject.check_duration,
      template_fees: projectTemplateObject.template_fees,
      template_project_target_days: projectTemplateObject.template_project_target_days,
      template_project_created_on: projectTemplateObject.template_project_created_on,
      template_project_deliverymanager: projectTemplateObject.template_project_deliverymanager,
      template_owner_name: projectTemplateObject.template_owner_name,
      section: projectTemplateObject.section,
      template_project_uid: projectTemplateObject.template_project_uid,
      user_uid: projectTemplateObject.user_uid,
      template_owner_uid: projectTemplateObject.template_owner_uid,
      project_rollout: projectTemplateObject.project_rollout,
    },
    { headers: { JWT: 'true' } }
  );

const addTemplateUseCase = (phaseObj: any) =>
  apis.phpAxiosInstance.post(
    templateRoutes.addTemplateUseCase(),
    {
      customer_uid: cookies.get('cogni_customer_uid') as string,
      template_owner_uid: cookies.get('cogni_user_uid') as string,
      template_owner_name: phaseObj.template_owner_name,
      check_duration: phaseObj.check_duration,
      template_case_deliverymanager: phaseObj.template_case_deliverymanager,
    },
    { headers: { JWT: 'true' } }
  );

const saveTemplateUsecaseSection = (phaseObj: any) =>
  apis.phpAxiosInstance.post(
    templateRoutes.saveTemplateUsecaseSection(),
    {
      template_section_detail: phaseObj.template_section_detail,
      template_case_uid: phaseObj.template_case_uid,
      template_section_sequence: phaseObj.template_section_sequence,
    },
    { headers: { JWT: 'true' } }
  );

const getAllSectionByTemplateCaseuid = (template_case_uid: any) =>
  apis.phpAxiosInstance.post(
    templateRoutes.getAllSectionByTemplateCaseuid(),
    {
      template_case_uid: template_case_uid,
    },
    { headers: { JWT: 'true' } }
  );

const addTemplateUsecaseTask = (useCaseTaskTemplateObj: any) =>
  apis.phpAxiosInstance.post(
    templateRoutes.addTemplateUsecaseTask(),
    {
      template_case_uid: useCaseTaskTemplateObj.template_case_uid,
      template_task_detail: useCaseTaskTemplateObj.template_task_detail,
      template_task_priority: useCaseTaskTemplateObj.template_task_priority,
      template_task_owner: useCaseTaskTemplateObj.template_task_owner,
      template_milestone_task: useCaseTaskTemplateObj.template_milestone_task,
      template_task_description: useCaseTaskTemplateObj.template_task_description,
      template_task_percentage: useCaseTaskTemplateObj.template_task_percentage,
      clone_template_task_uid: useCaseTaskTemplateObj.clone_template_task_uid,
      template_section_uid: useCaseTaskTemplateObj.template_section_uid,
      user_uid: useCaseTaskTemplateObj.user_uid,
      template_task_client_check: useCaseTaskTemplateObj.template_task_client_check,
      days_to_start: useCaseTaskTemplateObj.days_to_start,
      task_sequence: useCaseTaskTemplateObj.task_sequence,
    },
    { headers: { JWT: 'true' } }
  );

const addTemplateUsecaseClientTask = (useCaseTaskClientTemplateObj: any) =>
  apis.phpAxiosInstance.post(
    templateRoutes.addTemplateUsecaseClientTask(),
    {
      template_case_uid: useCaseTaskClientTemplateObj.template_case_uid,
      template_task_detail: useCaseTaskClientTemplateObj.template_task_detail,
      template_task_priority: useCaseTaskClientTemplateObj.template_task_priority,
      template_task_owner: useCaseTaskClientTemplateObj.template_task_owner,
      template_milestone_task: useCaseTaskClientTemplateObj.template_milestone_task,
      template_task_description: useCaseTaskClientTemplateObj.template_task_description,
      template_task_percentage: useCaseTaskClientTemplateObj.template_task_percentage,
      clone_template_task_uid: useCaseTaskClientTemplateObj.clone_template_task_uid,
      template_section_uid: useCaseTaskClientTemplateObj.template_section_uid,
      days_to_start: useCaseTaskClientTemplateObj.days_to_start,
      task_sequence: useCaseTaskClientTemplateObj.task_sequence,
    },
    { headers: { JWT: 'true' } }
  );

const updateTemplateUsecaseByUid = (updateTemplateObj: any) =>
  apis.phpAxiosInstance.post(
    templateRoutes.updateTemplateUsecaseByUid(),
    {
      template_case_name: updateTemplateObj.template_case_name,
      check_duration: updateTemplateObj.check_duration,
      template_fees: updateTemplateObj.template_fees,
      template_case_target_days: updateTemplateObj.template_case_target_days,
      template_case_created_on: updateTemplateObj.template_case_created_on,
      template_case_deliverymanager: updateTemplateObj.template_case_deliverymanager,
      template_owner_name: updateTemplateObj.template_owner_name,
      product_uid: updateTemplateObj.product_uid,
      product_id: updateTemplateObj.product_id,
      feature: updateTemplateObj.feature,
      section: updateTemplateObj.section,
      template_case_uid: updateTemplateObj.template_case_uid,
      user_uid: updateTemplateObj.user_uid,
      template_owner_uid: updateTemplateObj.template_owner_uid,
      project_rollout: updateTemplateObj.project_rollout,
    },
    { headers: { JWT: 'true' } }
  );

const deleteTemplateProjectbyuid = (template_project_uid: any) =>
  apis.phpAxiosInstance.post(
    templateRoutes.deleteTemplateProjectbyuid(),
    {
      template_project_uid: template_project_uid,
    },
    { headers: { JWT: 'true' } }
  );

const deleteTemplateUsecasebyuid = (template_project_uid: any) =>
  apis.phpAxiosInstance.post(
    templateRoutes.deleteTemplateUsecasebyuid(),
    {
      template_case_uid: template_project_uid,
    },
    { headers: { JWT: 'true' } }
  );

const deleteUsecaseTemplatesectionByUid = (template_section_uid: any) =>
  apis.phpAxiosInstance.post(
    templateRoutes.deleteUsecaseTemplatesectionByUid(),
    {
      template_section_uid: template_section_uid,
    },
    { headers: { JWT: 'true' } }
  );

const deleteTemplateProjectTaskbyUid = (template_task_uid: any) =>
  apis.phpAxiosInstance.post(
    templateRoutes.deleteTemplateProjectTaskbyUid(),
    {
      template_task_uid: template_task_uid,
    },
    { headers: { JWT: 'true' } }
  );

const deleteTemplateProjectClientTaskbyUid = (template_task_uid: any) =>
  apis.phpAxiosInstance.post(
    templateRoutes.deleteTemplateProjectClientTaskbyUid(),
    {
      template_task_uid: template_task_uid,
    },
    { headers: { JWT: 'true' } }
  );

const deleteTemplateUsecaseTaskbyUid = (template_task_uid: any) =>
  apis.phpAxiosInstance.post(
    templateRoutes.deleteTemplateUsecaseTaskbyUid(),
    {
      template_task_uid: template_task_uid,
    },
    { headers: { JWT: 'true' } }
  );

const deleteTemplateusecaseClientTaskbyUid = (template_task_uid: any) =>
  apis.phpAxiosInstance.post(
    templateRoutes.deleteTemplateusecaseClientTaskbyUid(),
    {
      template_task_uid: template_task_uid,
    },
    { headers: { JWT: 'true' } }
  );

const deleteProjectTemplatesectionByUid = (template_section_uid: any) =>
  apis.phpAxiosInstance.post(
    templateRoutes.deleteProjectTemplatesectionByUid(),
    {
      template_section_uid: template_section_uid,
    },
    { headers: { JWT: 'true' } }
  );

const getTemplateProjectInformation = (project_uid: string) =>
  apis.phpAxiosInstance.get(templateRoutes.getTemplateProjectInformation(project_uid), { headers: { JWT: 'true' } });

const getTemplateProjectTeamTasks = (task_uid: string) =>
  apis.phpAxiosInstance.get(templateRoutes.getTemplateProjectTasks(task_uid), { headers: { JWT: 'true' } });

const getTemplateProjectClientTasks = (task_uid: string) =>
  apis.phpAxiosInstance.get(templateRoutes.getTemplateProjectClientTasks(task_uid), { headers: { JWT: 'true' } });

const getTemplateUsecaseTeamTasks = (task_uid: string) =>
  apis.phpAxiosInstance.get(templateRoutes.getTemplateUsecaseTasks(task_uid), { headers: { JWT: 'true' } });

const getTemplateUsecaseClientTasks = (task_uid: string) =>
  apis.phpAxiosInstance.get(templateRoutes.getTemplateUsecaseClientTasks(task_uid), { headers: { JWT: 'true' } });

const cloneProjectTemplate = (detail: any) =>
  apis.phpAxiosInstance.post(templateRoutes.cloneProjectTemplate(), detail, { headers: { JWT: 'true' } });

const cloneUsecaseTemplate = (detail: any) =>
  apis.phpAxiosInstance.post(templateRoutes.cloneUsecaseTemplate(), detail, { headers: { JWT: 'true' } });

const sendTaskAssignmentMailImportingTemplate = (detail: any) =>
  apis.phpAxiosInstance.post(templateRoutes.sendTaskAssignmentMailAfterImportingTemplate(), detail, {
    headers: { JWT: 'true' },
  });

const incrementTemplateProjectSection = (project_uid: string, section_sequence: string, section_uid: string) =>
  apis.phpAxiosInstance.get(
    templateRoutes.incrementTemplateProjectSection(project_uid, section_sequence, section_uid),
    { headers: { JWT: 'true' } }
  );

const incrementTemplateCaseSection = (case_uid: string, section_sequence: string, section_uid: string) =>
  apis.phpAxiosInstance.get(templateRoutes.incrementTemplateCaseSection(case_uid, section_sequence, section_uid), {
    headers: { JWT: 'true' },
  });

const updateTemplatePhase = (detail: any) =>
  apis.phpAxiosInstance.post(templateRoutes.updateTemplatePhase(), detail, { headers: { JWT: 'true' } });

const getTemplateProjectDependency = (project_uid: string) =>
  apis.phpAxiosInstance.get(templateRoutes.getTemplateProjectDependency(project_uid), { headers: { JWT: 'true' } });

  
  const getTemplateUsecaseDependency = (case_uid: string) =>
  apis.phpAxiosInstance.get(templateRoutes.getTemplateUsecaseDependency(case_uid), { headers: { JWT: 'true' } });


export const templatesApi = {
  getProjectTemplates,
  getUseCaseTemplatesbycustomer,
  addTemplateProject,
  getCustDetails,
  addTemplateUseCase,
  getAllSectionByTemplateProjectuid,
  addTemplateProjectTask,
  addTemplateProjectClientTask,
  updateTemplateProjectByUid,
  saveTemplateProjectSection,
  saveTemplateUsecaseSection,
  getAllSectionByTemplateCaseuid,
  addTemplateUsecaseTask,
  addTemplateUsecaseClientTask,
  updateTemplateUsecaseByUid,
  syncProductsandFeaturesFromJIRAbyOneCustomer,
  getTemplateProjectinformation,
  getTemplateProjectInformation,
  getTemplateUsecaseinformation,
  deleteTemplateProjectbyuid,
  deleteTemplateUsecasebyuid,
  deleteUsecaseTemplatesectionByUid,
  deleteProjectTemplatesectionByUid,
  updateTemplateProjectTaskbyuid,
  updateTemplateProjectClienttaskByuid,
  updateTemplateUsecaseTaskbyuid,
  updateTemplateUsecaseClienttaskByuid,
  deleteTemplateProjectTaskbyUid,
  deleteTemplateProjectClientTaskbyUid,
  deleteTemplateUsecaseTaskbyUid,
  deleteTemplateusecaseClientTaskbyUid,
  getTemplateProjectTeamTasks,
  getTemplateProjectClientTasks,
  getTemplateUsecaseTeamTasks,
  getTemplateUsecaseClientTasks,
  cloneProjectTemplate,
  cloneUsecaseTemplate,
  addTemplateDocument,
  deleteTemplateDocument,
  getTemplateDocumentByCustomerUid,
  sendTaskAssignmentMailImportingTemplate,
  incrementTemplateProjectSection,
  incrementTemplateCaseSection,
  updateTemplatePhase,
  getTemplateProjectDependency,
  getTemplateUsecaseDependency
};
