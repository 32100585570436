import {
  Box,
  Typography,
  Stack,
  Avatar,
  Drawer,
  Grid,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { raidStage, taskStage } from '../../../../components/shared/CognisaasTaskStageIndicator/CognisaasTaskStageIndicator';

import {
  selectsignleProjectInformation,
  selectallCompanyUsers,
  selectallClientUsersUsers,
} from 'store/modules/CustomerPortal/selector';
import {
  DataGridPremium,
  GridRenderCellParams,
  useGridApiRef
} from '@mui/x-data-grid-premium';
import CognisaasTaskStageIndicator from 'components/shared/CognisaasTaskStageIndicator/CognisaasTaskStageIndicator';
import { useAppSelector } from 'store/hooks';
import ViewDate from 'components/shared/ViewDate/ViewDate';

import { customerPortalGetUserNameByUserUid} from 'utils/getUid';
import { getUserActiveState, INACTIVE_USER_COLOR } from 'utils/userHelpers';
import CommentSection from 'components/ProjectModule/ProjectDashboard/Raid/CommentSection';
import CognisaasTabs from 'components/shared/CognisaasTabs';
import format from 'date-fns/format';

function stringToHslColor(str: string, s: number, l: number) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const h = hash % 360;
  return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToHslColor(name, 30, 80),
    },
    children: (
      <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#000000' }}>
        {name.split(' ')[0][0]}
        {name.indexOf(' ') >= 0 ? name.split(' ')[1][0] : null}
      </Typography>
    ),
  };
}

const RaidDetails = (props:any) => {
  const { raidDetails } = props;
  console.log('Raid Details', raidDetails);
  return (<>
  <Grid spacing={2} mx={2}    >
  <Stack spacing={2} direction="column" sx={{ marginTop: '10px' }}>
      <Stack spacing={2} direction="row">
          <Stack>
              <Typography variant="subtitle2"> Name </Typography>
              <TextField
                                    autoComplete="off"
                                    autoFocus={true}
                                    style={{ width: '260px' }}
                                    size="small"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    defaultValue={raidDetails.name}
                                ></TextField>
          </Stack>

          <Stack>
              <Typography variant="subtitle2"> Type </Typography>
              <TextField
                                    autoComplete="off"
                                    autoFocus={true}
                                    style={{ width: '260px' }}
                                    size="small"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    defaultValue={raidDetails.type}
                                ></TextField>
          </Stack>
      </Stack>
      <Stack spacing={2} direction="row">
          <Stack>
              <Typography variant="subtitle2">
                  Phase Name
              </Typography>
              <TextField
                                    autoComplete="off"
                                    autoFocus={true}
                                    style={{ width: '260px' }}
                                    size="small"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    defaultValue={raidDetails.phase}
                                ></TextField>
          </Stack>
          <Stack>
              <Typography variant="subtitle2"> Due Date</Typography>
              <TextField
                                    autoComplete="off"
                                    autoFocus={true}
                                    style={{ width: '260px' }}
                                    size="small"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    defaultValue={raidDetails.due_date? format(new Date(raidDetails.due_date),'dd MMM yyyy') : ''}
                                ></TextField>
          </Stack>

      </Stack>
      <Stack spacing={2} direction="row">
          <Stack>
              <Typography variant="subtitle2"> Owner </Typography>
              <TextField
                                    autoComplete="off"
                                    autoFocus={true}
                                    style={{ width: '260px' }}
                                    size="small"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    defaultValue={raidDetails.owner_name}
                                ></TextField>
          </Stack>
          <Stack>
              <Typography variant="subtitle2"> Stage</Typography>
              <TextField
                                    autoComplete="off"
                                    autoFocus={true}
                                    style={{ width: '260px' }}
                                    size="small"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    defaultValue={raidDetails.stage}
                                ></TextField>
          </Stack>

      </Stack>

      <Stack>
          <Stack spacing={1} direction="row">
              <Typography variant="subtitle2">Share with client</Typography>
          </Stack>
          <Stack>
          <TextField
                                    autoComplete="off"
                                    autoFocus={true}
                                    style={{ width: '260px' }}
                                    size="small"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    defaultValue={raidDetails.share_with_client === '1' ? 'Yes' : 'No'}
                                ></TextField>
          </Stack>
      </Stack>
  </Stack>
</Grid>
</>
  );
};

const RaidDrawer = (props: any) => {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const tabs = ['Details','Comments'];
  const raidDetails = props.raidDetails;

  return (<>
  {console.log('Selected Tab',selectedTab)}
      <Drawer
          anchor="right"
          open={props.open}
          onClose={props.onClose}
          sx={{
              '.MuiPaper-root': {
                width: '650px',
                overflowX: 'hidden',
              },
          }}
      >
          <h1>Update Raid</h1>
          <Stack>
              <CognisaasTabs
                  tabs={tabs}
                  handleTabSwitch={(e: any, tab: any) => {
                  setSelectedTab(tab);
                  }}  
              />
          </Stack>
          <Stack>
              {selectedTab === 0 && <RaidDetails raidDetails={raidDetails} />}
          {selectedTab === 1 && 
          <Stack style={{ marginTop: '0px', width: '100%', marginRight: '0px' }}>
              <Stack style={{ padding: '20px 20px 5px 20px' }}>
                  <CommentSection
                      raidDetails={raidDetails}
                  />
              </Stack>
          </Stack>
          
          } 
          </Stack>
      </Drawer>
      </>
  );
};

const CustomerRaidDashboard = () => {
  const [sectionsList, setSectionsList] = React.useState<any>([]);
  const [pageSize, setPageSize] = React.useState(10);
  const singleProjectInformationApi: any = useAppSelector(selectsignleProjectInformation);
  const allUsersApi = useAppSelector(selectallCompanyUsers);
  const [singleProjectInformation, setSingleProjectInformation] = React.useState<any>({});
  const allClientUsersApi = useAppSelector(selectallClientUsersUsers);
  const apiRef = useGridApiRef();
  const [raidTasks, setRaidTasks] = React.useState<any>([]);
  const [openRaidDrawer, setOpenRaidDrawer] = React.useState(false);
  const [raidClicked, setRaidClicked] = React.useState<any>(null);



  useEffect(() => {
    setSingleProjectInformation(singleProjectInformationApi);
  }, [singleProjectInformationApi]);
  useEffect(() => {
    setSectionsList(singleProjectInformation.section);
    console.log(singleProjectInformation);
    if (singleProjectInformation?.raid?.length>0){
      setRaidTasks(singleProjectInformation?.raid);
    }
  }, [singleProjectInformation]);

  const phaseNameByPhaseUid = (section_uid: string) => {
    const ele = sectionsList.find((ele: any) => {
      if (section_uid == ele?.section_uid) {
        return ele.section_detail;
      }
    });
    console.log('ele',ele);
    return ele?.section_detail || '';

  };
  const customerPortalGetContactNameByContactUid = (uid: string) => {
    const found: any = allClientUsersApi?.find((contact: any) => contact['contact_uid'] == uid);
    if (uid == '15243') {
      return 'Not Assigned';
    } else if (typeof found === 'undefined') {
      return '';
    } else {
      return found['contact_name'];
    }
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 250,
      renderCell: (params: GridRenderCellParams) => (
        <Typography
          className="cognisaas-pointer"
          variant="subtitle1"
          sx={{ color: '#093BCD', fontWeight: 400, fontSize: '14px' }}
          onClick={() => {
            setOpenRaidDrawer(true);
            setRaidClicked(params.row);
          }}
        >
          {params.row.name}
        </Typography>
      ),
    },
    {
      field: 'section_uid',
      headerName: 'Phase Name',
      width: 200,
      renderCell: (params: GridRenderCellParams) => (
        <Typography variant="subtitle1" sx={{ fontWeight: 400, fontSize: '14px' }}>
          {sectionsList.map((ele: any) => {
            if (params.row.section_uid == ele?.section_uid) {
              return ele.section_detail;
            }
          })}
        </Typography>
      ),
    },
    {
      field: 'owner_uid',
      headerName: 'Owner',
      width: 200,
      renderCell: (params: GridRenderCellParams) =>{
        const name = customerPortalGetContactNameByContactUid(params.row.owner_uid) || customerPortalGetUserNameByUserUid(params.row.owner_uid) || 'Not Assigned';
        return (
        <Stack direction="row">
          <Stack spacing={1} direction={'row'}>
            <Stack direction="row">
                    <>
                      <Avatar
                        style={{ height: '34px', width: '34px', marginTop: '3px' }}
                        {...stringAvatar(name)}
                      />
                      {getUserActiveState(params.row.owner_uid, allUsersApi) === '0' ? (
                        <Typography style={{ marginTop: '4px', marginLeft: '8px',color: INACTIVE_USER_COLOR }} mt={1} variant="subtitle1">
                          {name}
                        </Typography>
                      ) : (
                        <Typography mt={1} variant="subtitle1" style={{marginTop: '4px', marginLeft: '8px',}}>
                          {name}
                        </Typography>
                      )}
                    </>
                  
            </Stack>
          </Stack>
        </Stack>
      );
      }
    },
    {
      headerName: 'Status',
      field: 'status',
      width: 150,
      renderCell: (params: GridRenderCellParams) => (
        <Stack>
          <Stack
            direction="row"
            sx={{ fontWeight: 500, fontSize: '12px', display: 'flex', alignItems: 'center', flexDirection: 'row' }}
          >
            <span
              style={{ marginRight: '4px' }}
              className={
                params.value == 'red'
                  ? 'red_status_box_task'
                    : params.value == 'green'
                      ? 'green_status_box_task'
                      : params.value == 'blue'
                        ? 'blue_status_box_task'
                          : ''
              }
            ></span>
            {params.value == 'red' ? (
              <Typography sx={{ mt: '3px' }} variant="subtitle1">
                Overdue
              </Typography>
            ) : params.value == 'green' ? (
              <Typography sx={{ mt: '3px' }} variant="subtitle1">
                On Track
              </Typography>
            ) : params.value == 'blue' ? (
              <Typography sx={{ mt: '3px' }} variant="subtitle1">
                Completed
              </Typography>
            )  : (
              '--'
            )}
          </Stack>
        </Stack>
      ),
    },
    {
      field: 'stage',
      headerName: 'Stage',
      width: 150,

      renderCell: (params: GridRenderCellParams) => (
        <Stack>
          <Stack
            direction="row"
            sx={{ fontWeight: 500, fontSize: '12px', display: 'flex', alignItems: 'center', flexDirection: 'row' }}
          >
            <span style={{ marginRight: '4px' }}>
              { params.row.type !=='Assumption'&& (
                <>
                  {params.row.stage === raidStage.NOT_MITIGATED && (
                    <CognisaasTaskStageIndicator
                      isMileStoneTask={false}
                      stage={taskStage.IN_PROGRESS}
                    />
                  )}
                  {params.row.stage === raidStage.MITIGATED && (
                    <CognisaasTaskStageIndicator
                      isMileStoneTask={false}
                      stage={taskStage.DONE}
                    />
                  )}
                </>
              )}
              {params.row.type ==='Assumption' && (<span> -- </span>)}
            </span>
            <Typography variant="subtitle1">
              {params.row.stage}
            </Typography>
          </Stack>
        </Stack>
      ),
    },
    {
      field:'type',
      headerName:'Type',
      width:150,
      renderCell: (params: GridRenderCellParams) => (
        <Typography variant="subtitle1" sx={{ fontWeight: 400, fontSize: '14px' }}>
          {params.row.type}
        </Typography>
      ),
    },
    {
      field: 'due_date',
      headerName: 'Due Date',
      width: 150,
      renderCell: (params: GridRenderCellParams) => (
        <Typography variant="subtitle1" sx={{ fontWeight: 500, fontSize: '12px' }}>
          {params?.row?.due_date && <ViewDate date={params.row.due_date} />}
        </Typography>
      ),
    },
    {
      field: 'share_with_client',
      headerName: 'Share with Client',
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Typography
            variant="subtitle1"
            sx={{ color: '#093BCD', fontWeight: 400, fontSize: '14px' }}
          >
            {params.row.share_with_client === '1' ? 'Yes' : 'No'}
          </Typography>
        );
      },
    },
  ];

  return (
    <>
    {openRaidDrawer && <RaidDrawer
      open={openRaidDrawer}
      raidDetails={{ ...raidClicked, owner_name: customerPortalGetContactNameByContactUid(raidClicked.owner_uid) || customerPortalGetUserNameByUserUid(raidClicked.owner_uid) || 'Not Assigned', phase:phaseNameByPhaseUid(raidClicked.section_uid) }}
      onClose={() => {
        setOpenRaidDrawer(false);
      }}
    />}
    <Box
      sx={{ padding: '10px' }}
      style={{
        background: '#fff',
        boxShadow: '0px 4px 26px rgba(0, 0, 0, 0.05)',
        borderRadius: '4px',
        minHeight: '75vh',
      }}
    >
      <DataGridPremium
        sx={{ borderRadius: 0 }}
        autoHeight
        columns={columns}
        rows={raidTasks}
        apiRef={apiRef}
        disableSelectionOnClick
        hideFooter
        getRowId={(row) => row.raid_uid}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        pagination
        />
    </Box>
    </>
  );
};

export default CustomerRaidDashboard;
