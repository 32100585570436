import CognisaasTabs from '../../../../shared/CognisaasTabs';
import {
  selectClientByAssociationUid,
  selectClientList,
} from '../../../../../store/modules/ManageClient/ClientList/selector';
import { useState, useEffect } from 'react';
import { Grid, Stack, Typography, ButtonGroup, Button, Box } from '@mui/material';
import UsecaseOverview from './UsecaseOverview/UsecaseOverview';
import UsecaseCustomfields from './UsecaseCustomfields/UsecaseCustomfields';
import {
  getProjectInformation,
  getProjectInformationWithCaseTaskByUid,
} from '../../../../../store/modules/Project/slice';
import {
  selectedProjectInformationForGantt,
  selectProjectInformation,
} from '../../../../../store/modules/Project/selector';
import { getAllUsers, getCustomerDetail } from 'store/modules/Common/slice';
import { useAppSelector, useAppDispatch } from '../../../../../store/hooks';
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom';
import PlanHome from '../../../../TaskModule/PlanHome';
import UsecaseAttachment from './UsecaseAttachment/UsecaseAttachment';
import CognisaasLoader from 'components/shared/CognisaasLoader/CognisaasLoader';
import UsecasePlanTable from './UsecasePlanTable';
import { findCriticalPath } from '../../../../../store/modules/Project/slice';
import { ReactComponent as TableViewUnselected } from '../../../../../assets/svgs/table_view_unselected.svg';
import { ReactComponent as TableViewSelected } from '../../../../../assets/svgs/table_view_selected.svg';
import { ReactComponent as KanbanViewUnselected } from '../../../../../assets/svgs/kanban_view_unselected.svg';
import { ReactComponent as KanbanViewSelected } from '../../../../../assets/svgs/kanban_view_selected.svg';
import { ReactComponent as GanttViewUnselected } from '../../../../../assets/svgs/gantt_chart_unselected.svg';
import { ReactComponent as GanttViewSelected } from '../../../../../assets/svgs/gantt_chart_selected.svg';
import ProductRequirementMapping from './ProductRequirementMapping/ProductRequirementMapping';
import { getAllUsecaseCustomFieldsWithValue, getClientInformation } from 'store/modules/Usecase/slice';
import {
  getClientUidByAssociationUid,
  fetchClientContacts,
  fetchClientList,
} from 'store/modules/ManageClient/ClientList/slice';
import { getUsecaseByUid } from 'store/modules/Usecase/slice';
import { selectUsecaseCustomFieldsDetail, selectUsecaseInfo } from 'store/modules/Usecase/selector';
import { updateCaseWithfeatureStatus } from 'store/modules/Common/slice';
import { label_client, label_project } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import GanttUsecase from 'components/shared/GanttCharts/GanttComponent/GanttUseCase';
import { selectCustomerDetail } from 'store/modules/Common/selector';
import { getUsecaseTaskDashboardView, setUsecaseTaskdashboardView } from 'utils/viewHelper';
import CommentHome from './UsecaseComment/CommentHome';
import CognisaasAlertBlinker from 'components/shared/CognisaasAlertBlinker/CognisaasAlertBlinker';
import { isMandatoryFieldsEmpty } from 'utils/dataHelpers';
import BasicModal from 'components/shared/CognisaasModal/CognisaasModal';
import CognisaasButton from 'components/shared/CognisaasButton/CognisaasButton';
import { fetchOpenUsecases } from 'store/modules/Reports/OpenUsecases/slice';
import { fetchClosedUsecases } from 'store/modules/Reports/ClosedUsecases/slice';
import { selectOpenUsecases, selectOpenUsecasesStatus } from 'store/modules/Reports/OpenUsecases/selector';
import { selectClosedUsecases, selectClosedUsecaseStatus } from 'store/modules/Reports/ClosedUsecases/selector';
import CognisaasToast from 'components/shared/CognisaasToast/CognisaasToast';
import checkAccessForRouting from 'components/shared/Hooks/checkAccessForRouting';
const UsecaseDashboard = () => {
  const params = useParams();
  const navigate = useNavigate();
  const location: any = useLocation();
  const [clientUid, setClientUid] = useState<any>('');
  const [clientName, setClientName] = useState<any>('');
  const associationUid: string = params.associationId as string;
  // const [clientDetail, setClientDetail] = useState<any>(null);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [tableView, setTableView] = useState<number>(1);
  const [criticalPath, setCriticalPath] = useState<any>([]);
  const dispatch = useAppDispatch();
  const projectInfoForTaskTable = useAppSelector(selectProjectInformation);
  const selectClientInformation = useAppSelector(selectClientByAssociationUid);
  const usecaseCustomFieldsWithDetail = useAppSelector(selectUsecaseCustomFieldsDetail);
  const openUsecases = useAppSelector(selectOpenUsecases);
  const openUsecasesStatus = useAppSelector(selectOpenUsecasesStatus);
  const closedUsecases = useAppSelector(selectClosedUsecases);
  const closedUsecasesStatus = useAppSelector(selectClosedUsecaseStatus);
  const clientList = useAppSelector(selectClientList);
  const arrayToCheckClient = clientList.map((ele) => ele.client_uid);
  const isClientLinkAccessable = arrayToCheckClient.includes(clientUid);
  console.log({ isClientLinkAccessable });

  const customerDetail = useAppSelector(selectCustomerDetail);
  const useCaseInfo = useAppSelector(selectUsecaseInfo);
  const [usecaseDetail, setUsecaseDetail] = useState<any>(null);
  const [showUsecaseModule, setShowUsecaseModule] = useState<boolean>(false);
  const [projectDetails, setProjectDetails] = useState<any>(null);
  const projectInfo = useAppSelector(selectedProjectInformationForGantt);
  const [usecaseDetailForAttachment, setUsecaseDetailForAttachment] = useState<any>(null);
  const [alertDetails, setAlertDetails] = useState({ isVisible: false, severity: 'error', message: '' });

  const [alertModal, setAlertModal] = useState<boolean>(false);
  const [alertModalOpened, setAlertModalOpened] = useState<boolean>(false);

  const [tabComponentIndex, setTabComponentIndex] = useState<number | undefined>();
  const [tabComponent, setTabComponent] = useState<any>();

  //   TODO: Move below line to constants
  const tabs = ['Overview', 'Plan', 'Product Requirements', 'Attachments', 'Custom Fields', 'Comments'];
  // // console.log({ selectClientInformation });

  const setClientDetailForThePage = async () => {
    if (params.caseId) {
      const usecaseDetail = await dispatch(getUsecaseByUid(params.caseId as string)).unwrap();
      setClientUid(usecaseDetail[0].client_uid);
      setClientName(usecaseDetail[0].client_name);
    }
  };

  useEffect(() => {
    if (openUsecasesStatus == 'success' && closedUsecasesStatus == 'success') {
      const allUseCases = [...openUsecases, ...closedUsecases];
      if (allUseCases?.length > 0) {
        const arrayToCheck = allUseCases.map((ele) => ele.case_uid);
        if (location.state?.new_added_usecase_uid && location.state?.new_added_usecase_uid.length > 0) {
          arrayToCheck.push(location.state?.new_added_usecase_uid);
        }
        if (params.caseId != undefined) {
          const hasAccessToUsecase = checkAccessForRouting({ arrayToCheck: arrayToCheck, uid: params.caseId });
          if (hasAccessToUsecase == true) {
            setShowUsecaseModule(true);
          } else if (hasAccessToUsecase == false) {
            setShowUsecaseModule(false);
            navigate(-1);
            return;
          }
        }
      }
    }
  }, [openUsecases, closedUsecases]);

  useEffect(() => {
    setClientDetailForThePage();
  }, [params, associationUid]);

  useEffect(() => {
    const details = {
      association_uid: params.associationId,
      project_uid: params.projectId,
    };
    getCriticalPath();
    dispatch(getProjectInformation(details));
    dispatch(getProjectInformationWithCaseTaskByUid(details));
    if (clientUid) {
      dispatch(getClientInformation(clientUid));
      dispatch(fetchClientContacts(projectInfo?.client_uid));
      dispatch(updateCaseWithfeatureStatus(projectInfo?.client_uid));
    }
    dispatch(getAllUsers());
    dispatch(fetchOpenUsecases());
    dispatch(fetchClosedUsecases());
    dispatch(getCustomerDetail());
    dispatch(fetchClientList());
    dispatch(getAllUsecaseCustomFieldsWithValue(params.caseId as string));

    const tableView =
      getUsecaseTaskDashboardView() === 'table' ? 0 : getUsecaseTaskDashboardView() === 'resourse' ? 2 : 1;
    setTableView(tableView);
  }, []);

  useEffect(() => {
    if (usecaseCustomFieldsWithDetail && isMandatoryFieldsEmpty(usecaseCustomFieldsWithDetail)) {
      for (let i = 0; i < tabs.length; i++) {
        if (tabs[i] === 'Custom Fields') {
          setTabComponentIndex(i);
          setTabComponent(<CognisaasAlertBlinker type="danger" />);

          if (!alertModalOpened) {
            setAlertModal(true);
          }
        }
      }
    } else {
      setAlertModal(false);
      setTabComponentIndex(undefined);
      setTabComponent(undefined);
    }
  }, [usecaseCustomFieldsWithDetail]);
  useEffect(() => {
    if (selectClientInformation != null && associationUid == selectClientInformation.association_uid) {
      // setClientDetail(selectClientInformation);
    } else {
      dispatch(getClientUidByAssociationUid(params.associationId as string));
    }
  }, [selectClientInformation]);

  useEffect(() => {
    if (customerDetail) {
      if (getUsecaseTaskDashboardView() === 'na') {
        setTableView(customerDetail.usecase_plan_view || 1);
        if (customerDetail.usecase_plan_view) {
          setUsecaseTaskdashboardView(
            customerDetail.usecase_plan_view === '0'
              ? 'table'
              : customerDetail.usecase_plan_view === '1'
              ? 'plan'
              : 'resourse'
          );
        } else {
          setUsecaseTaskdashboardView('plan');
        }
      }
    }
  }, [customerDetail]);

  useEffect(() => {
    if (!projectInfo) {
      const details = {
        association_uid: params.associationId,
        project_uid: params.projectId,
      };
      dispatch(getProjectInformation(details));
      dispatch(getProjectInformationWithCaseTaskByUid(details));
    } else {
      setProjectDetails({ ...projectInfo });
      getUsecaseDetail({ ...projectInfo });
    }
  }, [projectInfo]);

  const getSelectedTab = (e: any, index: any) => {
    setTabIndex(index);
  };

  const getCriticalPath = async () => {
    const pay = {
      project_uid: params.caseId,
      type: 'USECASE',
    };
    const criticalPath: any = await dispatch(findCriticalPath(pay)).unwrap();
    setCriticalPath([...criticalPath.result[0]]);
  };

  const getUsecaseDetail = (project: any) => {
    const usecase = project?.usecase?.filter((uc: any) => uc.case_uid == params.caseId);
    const Temp = projectInfoForTaskTable?.usecase?.filter((uc: any) => uc.case_uid == params.caseId);
    setUsecaseDetailForAttachment({ ...Temp?.[0] });
    setUsecaseDetail({ ...usecase?.[0] });
  };

  const updateComponent = () => {
    const details = {
      association_uid: params.associationId,
      project_uid: params.projectId,
    };
    dispatch(getProjectInformation(details));
    dispatch(getProjectInformationWithCaseTaskByUid(details));
  };

  const returnComponentForSelectedIndex = () => {
    if (tabIndex == 0) {
      return (
        <Grid container spacing={2}>
          {usecaseDetail && <UsecaseOverview clientDetail={selectClientInformation} usecaseInfo={selectUsecaseInfo} />}
        </Grid>
      );
    } else if (tabIndex == 1) {
      return (
        <>
          {(tableView == 0 && (
            <UsecasePlanTable projectInformation={projectInfoForTaskTable} criticalPath={criticalPath} />
          )) ||
            (tableView == 1 && <PlanHome client={selectClientInformation} useCaseInfo={useCaseInfo} />)}
          {tableView == 2 && <GanttUsecase usecaseDetail={usecaseDetail} />}
        </>
      );
    } else if (tabIndex == 2) {
      return usecaseDetail ? (
        <ProductRequirementMapping
          updateWholeComponent={updateComponent}
          usecaseDetails={{ ...usecaseDetail, clientName }}
        />
      ) : (
        <></>
      );
    } else if (tabIndex == 3) {
      return (
        <UsecaseAttachment
          projectInfo={projectInfo}
          clientInfo={selectClientInformation}
          usecaseInfo={usecaseDetailForAttachment}
        />
      );
    } else if (tabIndex == 4) {
      return (
        <>
          <UsecaseCustomfields />
        </>
      );
    } else if (tabIndex == 5) {
      return <CommentHome project_uid={usecaseDetail.project_uid} case_uid={usecaseDetail.case_uid} />;
    }
  };

  if (showUsecaseModule == false) {
    return <CognisaasLoader />;
  } else
    return (
      <>
        <BasicModal
          height={250}
          width={400}
          open={alertModal}
          onClose={() => {
            setAlertModalOpened(true);
            setAlertModal((prevState) => !prevState);
          }}
        >
          <Stack style={{ padding: '15px' }}>
            <Typography variant="subtitle1" style={{ fontSize: '16px', lineHeight: '1.6rem' }}>
              Mandatory custom field data is missing. Any changes made will not be saved.
            </Typography>
            <Stack direction="row-reverse" style={{ marginTop: '30px' }}>
              <CognisaasButton
                clickHandler={() =>
                  setTabIndex(() => {
                    const index = tabs.findIndex((tab: string) => tab === 'Custom Fields');
                    setAlertModalOpened(true);
                    setAlertModal(false);
                    return index;
                  })
                }
                sx={{ padding: '8px 32px', marginLeft: '10px', height: '35px' }}
                isOutlined={false}
                label="Go to custom fields"
              />
              <CognisaasButton
                clickHandler={() =>
                  setAlertModal(() => {
                    setAlertModalOpened(true);
                    return false;
                  })
                }
                sx={{ padding: '8px 32px', height: '35px' }}
                isOutlined={true}
                label="Cancel"
              />
            </Stack>
          </Stack>
        </BasicModal>
        <Box sx={{ padding: '20px' }}>
          <CognisaasToast
            open={alertDetails.isVisible}
            onClose={() => setAlertDetails({ isVisible: false, severity: 'error', message: '' })}
            message={alertDetails.message}
            type={alertDetails.severity == 'success' ? 'success' : 'failure'}
          ></CognisaasToast>
          <Stack>
            <Stack spacing={2} direction="row">
              <Stack spacing={1} direction="row">
                <Typography variant="subtitle2" className={'label-text'}>
                  {label_client}
                </Typography>
                {projectDetails &&
                  (isClientLinkAccessable == true ? (
                    <Typography style={{ color: '#2D60F6' }} className={'cognisaas-pointer'} variant="subtitle2">
                      <Link to={`/client-list/client/${projectDetails.client_uid}`}>{clientName}</Link>
                    </Typography>
                  ) : (
                    <Typography className={'cognisaas-pointer'} variant="subtitle2">
                      {clientName}
                    </Typography>
                  ))}
              </Stack>
              <Stack spacing={1} direction="row">
                <Typography variant="subtitle2" className={'label-text'}>
                  {label_project}
                </Typography>
                {usecaseDetail && (
                  <Typography className={'cognisaas-pointer'} style={{ color: '#2D60F6' }} variant="subtitle2">
                    <Link to={`/projects/project/${usecaseDetail.association_uid}/${usecaseDetail.project_uid}/`}>
                      {' '}
                      {/* {projectName} */}
                      {projectDetails.project_name}
                    </Link>
                  </Typography>
                )}
              </Stack>
            </Stack>
            {usecaseDetail && (
              <Stack justifyContent={'space-between'} spacing={2} direction="row">
                <Stack mt={0.7} spacing={2} direction="row">
                  <Typography variant="h6">{usecaseDetail.case_name}</Typography>
                  {/* <Typography variant="h6">Usecase name</Typography> */}
                  <Stack direction="row" alignItems="center">
                    <span
                      style={{ marginRight: '4px', height: '16px', width: '16px' }}
                      className={
                        usecaseDetail.case_readinessstate_status == 'grey'
                          ? 'grey_status_box_task'
                          : usecaseDetail.case_readinessstate_status == 'red'
                          ? 'red_status_box_task'
                          : usecaseDetail.case_readinessstate_status == 'amber'
                          ? 'amber_status_box_task'
                          : usecaseDetail.case_readinessstate_status == 'green'
                          ? 'green_status_box_task'
                          : usecaseDetail.case_readinessstate_status == 'blue'
                          ? 'blue_status_box_task'
                          : ''
                      }
                    ></span>
                    <Typography variant="subtitle1" style={{ marginTop: '3px' }}>
                      {usecaseDetail.case_readinessstate_status == 'grey'
                        ? 'On Hold'
                        : usecaseDetail.case_readinessstate_status == 'red'
                        ? 'Overdue'
                        : usecaseDetail.case_readinessstate_status == 'amber'
                        ? 'At Risk'
                        : usecaseDetail.case_readinessstate_status == 'green'
                        ? 'On Track'
                        : usecaseDetail.case_readinessstate_status == 'blue'
                        ? 'Completed'
                        : ''}
                    </Typography>
                  </Stack>
                </Stack>

                <Stack direction={'row'}>
                  {tabIndex == 1 && (
                    <Stack>
                      <ButtonGroup
                        sx={{ backgroundColor: 'white' }}
                        variant="outlined"
                        aria-label="outlined button group"
                      >
                        <Button
                          className={`${tableView == 0 ? 'active-btn' : null} `}
                          onClick={() => {
                            setUsecaseTaskdashboardView('table');
                            setTableView(0);
                          }}
                        >
                          {tableView === 0 ? <TableViewSelected /> : <TableViewUnselected />}
                        </Button>

                        <Button
                          className={`${tableView == 1 ? 'active-btn' : null} `}
                          onClick={() => {
                            setUsecaseTaskdashboardView('plan');
                            setTableView(1);
                          }}
                        >
                          {tableView === 1 ? <KanbanViewSelected /> : <KanbanViewUnselected />}
                        </Button>
                        <Button
                          className={`${tableView == 2 ? 'active-btn' : null} `}
                          sx={{ backgroundColor: 'grey' }}
                          onClick={() => {
                            setUsecaseTaskdashboardView('resourse');
                            setTableView(2);
                          }}
                        >
                          {tableView === 2 ? <GanttViewSelected /> : <GanttViewUnselected />}
                        </Button>
                      </ButtonGroup>
                    </Stack>
                  )}
                </Stack>
              </Stack>
            )}
          </Stack>
          <Box sx={{ mt: '22px' }}>
            <CognisaasTabs
              tabs={tabs}
              size="squeezed"
              handleTabSwitch={getSelectedTab}
              selectedTabIndex={tabIndex}
              tabComponent={tabComponent}
              tabComponentIndex={tabComponentIndex}
            />
          </Box>
          {returnComponentForSelectedIndex()}
        </Box>
      </>
    );
};

export default UsecaseDashboard;
