import { useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../store/hooks';
import { getProjectSubtasks, getUsecaseSubtasks } from '../../../../store/modules/Task/slice';
import { selectProjectSubtasks, selectUsecaseSubtasks } from '../../../../store/modules/Task/selector';
import NewSubtaskForm from './NewSubtaskForm';
import SubtaskList from './SubtaskList';
import { userPermissionSchema } from 'components/Reports/SideDrawer/types';

const Subtask = (props: any) => {
  const { userPermissions } = props;

  const dispatch = useDispatch();
  const projectSubtasks = useAppSelector(selectProjectSubtasks);
  const usecaseSubtasks = useAppSelector(selectUsecaseSubtasks);
  const [projectSubtask, setProjectSubtask] = useState<any[]>([]);
  const [usecaseSubtask, setUsecaseSubtask] = useState<any[]>([]);

  // console.log(params);

  useEffect(() => {
    if (props.taskDetail.project_uid && props.taskDetail.case_uid) {
      dispatch(getUsecaseSubtasks(props.taskDetail.task_uid));
    } else {
      if (props.taskDetail.project_uid) {
        dispatch(getProjectSubtasks(props.taskDetail.task_uid));
      }
    }
  }, []);

  useEffect(() => {
    if (usecaseSubtasks.length > 0) {
      setUsecaseSubtask(() => [...usecaseSubtasks]);
    } else {
      if (projectSubtasks.length > 0) {
        setProjectSubtask(() => [...projectSubtasks]);
        console.log(projectSubtasks);
      }
    }
  }, [projectSubtasks, usecaseSubtasks]);

  const renderContent = () => {
    if (props.taskDetail.project_uid && props.taskDetail.case_uid) {
      if (usecaseSubtask.length == 0 && userPermissions?.includes(userPermissionSchema.EDIT)) {
        return <NewSubtaskForm taskDetail={props.taskDetail} />;
      }
      return (
        <SubtaskList
          taskDetail={props.taskDetail}
          subtasks={props.taskDetail.case_uid ? usecaseSubtasks : projectSubtasks}
          userPermissions={userPermissions}
        />
      );
    } else {
      if (projectSubtask.length == 0 && userPermissions?.includes(userPermissionSchema.EDIT)) {
        return <NewSubtaskForm taskDetail={props.taskDetail} />;
      }
      return (
        <SubtaskList
          taskDetail={props.taskDetail}
          subtasks={props.taskDetail.case_uid ? usecaseSubtasks : projectSubtasks}
          userPermissions={userPermissions}
        />
      );
    }
  };

  return <>{renderContent()}</>;
};

export default Subtask;
