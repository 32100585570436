import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
// import { clientAPI } from '../../../../api';
import { requestState } from '../../types';
import { clientAPI } from '../../../../api/modules/client-new';

interface clientType {
  clientDetail: {
    data: any;
    status: requestState;
    error: null | undefined | string;
  };
  updateClientDetail: {
    data: any;
    status: requestState;
    error: null | undefined | string;
  };
  clientCustomFields: {
    data: any;
    status: requestState;
    error: null | undefined | string;
  };
  clientRenewal: {
    data: any;
    status: requestState;
    error: null | undefined | string;
  };
  clientContacts: {
    data: any;
    status: requestState;
    error: null | undefined | string;
  };
}

const initialState: clientType = {
  clientDetail: {
    data: null,
    status: requestState.loading,
    error: null,
  },
  updateClientDetail: {
    data: null,
    status: requestState.loading,
    error: null,
  },
  clientCustomFields: {
    data: null,
    status: requestState.loading,
    error: null,
  },
  clientRenewal: {
    data: null,
    status: requestState.loading,
    error: null,
  },
  clientContacts: {
    data: null,
    status: requestState.loading,
    error: null,
  },
};

export const getClientDetail = createAsyncThunk('client/getClient', async (clientId: string) => {
  const { data } = await clientAPI.getClientDetail(clientId);
  console.log(data.data);
  return data.data;
});

export const updateClient = createAsyncThunk('client/updateClient', async (payload: any) => {
  const { data } = await clientAPI.updateClient(payload.id, payload);
  console.log(data.data);
  return data.data;
});

export const getClientCustomFields = createAsyncThunk('client/getClientCustomFields', async (clientId: string) => {
  const { data } = await clientAPI.getClientCustomFields(clientId);
  console.log(data.results);
  return data.results;
});

export const updateClientCustomFields = createAsyncThunk('client/upateClientCustomFields', async (payload: any) => {
  const { data } = await clientAPI.updateClientCustomfield(payload.cientId, payload.fieldId, payload);
  console.log(data.results);
  return data.results;
});

export const getClientRenewals = createAsyncThunk('client/getClientRenewals', async (clientId: string) => {
  const { data } = await clientAPI.getClientRenewals(clientId);
  console.log(data);
  return data.data;
});

export const updateClientRenewals = createAsyncThunk('client/updateClientRenewals', async (payload: any) => {
  const { data } = await clientAPI.updateClientRenewal(payload, payload.client_uid);
  console.log(data.results);
  return data.results;
});

export const getClientContacts = createAsyncThunk('client/getClientContacts', async (clientId: string) => {
  const { data } = await clientAPI.getClientContacts(clientId);
  console.log(data);
  return data.results;
});

export const addClientContacts = createAsyncThunk('client/addClientContacts', async (payload: any) => {
  const { data } = await clientAPI.addClientContact(payload.clientId, { ...payload, clientId: undefined });
  console.log(data);
  return data.data;
});

export const deleteClientContact = createAsyncThunk('client/deleteClientContact', async (payload: any) => {
  const { data } = await clientAPI.deleteClientContact(payload.clientId, payload.contactId);
  console.log(data);
  return data.data;
});

const clientSlice = createSlice({
  name: 'clientSlice',
  initialState,
  reducers: {
    updateClient: (state, action: PayloadAction<any>) => {
      console.log('!!!!!!!', action.payload);
      state.clientDetail = { ...action.payload };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getClientDetail.pending, (state) => {
        state.clientDetail.status = requestState.loading;
      })
      .addCase(getClientDetail.fulfilled, (state, action) => {
        state.clientDetail.status = requestState.success;
        state.clientDetail.data = { ...action.payload, user_permissions: ['view', 'edit', 'delete'] };
      })
      .addCase(getClientDetail.rejected, (state, action) => {
        state.clientDetail.error = action.error.message;
      })
      // UPDATE CLIENT
      .addCase(updateClient.pending, (state) => {
        state.updateClientDetail.status = requestState.loading;
      })
      .addCase(updateClient.fulfilled, (state, action) => {
        state.updateClientDetail.status = requestState.success;
        console.log(action.payload);
        // state.updateClientDetail.data = { ...action.payload, user_permissions: ['view', 'edit', 'delete'] };
        state.clientDetail.data = { ...action.payload, user_permissions: ['view', 'edit', 'delete'] };
      })
      .addCase(updateClient.rejected, (state, action) => {
        state.updateClientDetail.error = action.error.message;
      })
      // CUSTOM FIELDS
      .addCase(getClientCustomFields.pending, (state) => {
        state.clientCustomFields.status = requestState.loading;
      })
      .addCase(getClientCustomFields.fulfilled, (state, action) => {
        state.clientCustomFields.status = requestState.success;
        state.clientCustomFields.data = action.payload;
      })
      .addCase(getClientCustomFields.rejected, (state, action) => {
        state.clientCustomFields.error = action.error.message;
      })
      //UPDATE CUSTOM FIELDS
      .addCase(updateClientCustomFields.pending, (state) => {
        // state.clientCustomFields.status = requestState.loading;
      })
      .addCase(updateClientCustomFields.fulfilled, (state, action) => {
        // state.clientCustomFields.status = requestState.success;
        // state.clientCustomFields.data = action.payload;
      })
      .addCase(updateClientCustomFields.rejected, (state, action) => {
        // state.clientCustomFields.error = action.error.message;
      })
      // RENEWAL
      .addCase(getClientRenewals.pending, (state) => {
        state.clientRenewal.status = requestState.loading;
      })
      .addCase(getClientRenewals.fulfilled, (state, action) => {
        state.clientRenewal.status = requestState.success;
        state.clientRenewal.data = action.payload;
      })
      .addCase(getClientRenewals.rejected, (state, action) => {
        state.clientRenewal.error = action.error.message;
      })
      // RENEWAL UPDATE
      .addCase(updateClientRenewals.pending, (state) => {
        state.clientRenewal.status = requestState.loading;
      })
      .addCase(updateClientRenewals.fulfilled, (state, action) => {
        state.clientRenewal.status = requestState.success;
        state.clientRenewal.data = action.payload;
      })
      .addCase(updateClientRenewals.rejected, (state, action) => {
        state.clientRenewal.error = action.error.message;
      })
      // GET CONTACTS
      .addCase(getClientContacts.pending, (state) => {
        state.clientContacts.status = requestState.loading;
      })
      .addCase(getClientContacts.fulfilled, (state, action) => {
        state.clientContacts.status = requestState.success;
        state.clientContacts.data = action.payload;
      })
      .addCase(getClientContacts.rejected, (state, action) => {
        state.clientContacts.error = action.error.message;
      })
      // ADD CONTACTS
      .addCase(addClientContacts.pending, (state) => {
        state.clientContacts.status = requestState.loading;
      })
      .addCase(addClientContacts.fulfilled, (state, action) => {
        state.clientContacts.status = requestState.success;
        state.clientContacts.data = [...state.clientContacts.data, action.payload];
      })
      .addCase(addClientContacts.rejected, (state, action) => {
        state.clientContacts.error = action.error.message;
      })
      // DELETE CONTACTS
      .addCase(deleteClientContact.pending, (state) => {
        state.clientContacts.status = requestState.loading;
      })
      .addCase(deleteClientContact.fulfilled, (state, action) => {
        state.clientContacts.status = requestState.success;
        // should remove the contact from store
        // state.clientContacts.data = [...state.clientContacts.data, action.payload];
      })
      .addCase(deleteClientContact.rejected, (state, action) => {
        state.clientContacts.error = action.error.message;
      });
  },
});

export const clientReducer = clientSlice.reducer;

export default clientReducer;
