import { Stack, TextField, Typography, Grid, Drawer, IconButton, MenuItem, Menu } from '@mui/material';
import CognisaasSelect from '../shared/CognisaasSelect/CognisaasSelect';
import { Formik } from 'formik';
import CognisaasButton from '../shared/CognisaasButton/CognisaasButton';
import { useEffect, useState } from 'react';
import { getAllUsers } from '../../store/modules/Common/slice';
import { useAppSelector } from '../../store/hooks';
import { useDispatch } from 'react-redux';
import { selectUsersOfCompany } from '../../store/modules/Common/selector';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { deleteClient, getClientAudit } from '../../store/modules/ManageClient/ClientList/slice';
import { useNavigate } from 'react-router-dom';
import { getClientByUid } from '../../store/modules/ManageClient/ClientList/slice';
import { addClientAuditTrail } from '../../store/modules/ManageClient/OnboardFormValues/slice';
import PictureUpload from '../shared/PictureUpload/PictureUpload';
import { userPermissionSchema } from 'components/Reports/SideDrawer/types';
// import useCompanyUsers from 'components/shared/Hooks/useCompanyUsers';
import CognisaasUserSelect from 'components/shared/CognisaasSelect/CognisaasUserSelect';
import useUserDropDownData from 'components/shared/Hooks/useUserDropDownData';
import { selectCustomerDetails } from 'store/modules/Settings/selector';
import { selectClientList } from '../../store/modules/ManageClient/ClientList/selector';
import {
  selectIndustryFilterData,
  // selectSegmentFilterData,
  selectStageFilterData,
} from '../../store/modules/ui/Filters/selector';
import {
  getIndustryName,
  getSegmentName,
  getSegmentUid,
  getStageName,
  getStageUid,
  getIndustryUid,
  getCustomerUid,
  getLoggedInUserUid,
  getUserUid,
} from '../../utils/getUid';
import format from 'date-fns/format';
import getSymbolFromCurrency from 'currency-symbol-map';
import CognisaasToast from 'components/shared/CognisaasToast/CognisaasToast';
import { editAccountProfileSchema } from 'utils/validations';
import {
  label_arr,
  label_client,
  label_cs_owner,
  label_sales_owner,
} from 'components/Settings/Preferences/CustomLabelCookieHelper';
import DeleteModal from 'components/shared/CognisaasModal/DeleteModal';
import { isMandatoryFieldsEmpty } from 'utils/dataHelpers';
import useCustomNames from 'components/shared/CustomNames/useCustomNames';
import { getUserActiveState } from 'utils/userHelpers';
import useSegments from 'components/shared/Hooks/useSegments';
import CognisaasDropdown from 'components/shared/CognisaasDropdown/CognisaasDropdown';
import useIndustries from 'components/shared/Hooks/useIndustries';
import useStages from 'components/shared/Hooks/useStages';
import useUsers from 'components/shared/Hooks/useUsers';
import { useParams } from 'react-router-dom';
import { updateClient } from 'store/modules/features/client/client';

const EditClientDrawer = (props: any) => {
  // const companyUsers = useCompanyUsers();
  const { segments, getSegmentNameById, getSegmentIdByName } = useSegments();
  const { industries, getIndustryNameById, getIndustryIdByName } = useIndustries();
  const { stages, getStageNameById, getStageIdByName } = useStages();
  const { users, getUsersDropDownData, getUserIdByName, getUserNameById } = useUsers();
  const companyUsersDropDownData = useUserDropDownData('company');
  const params = useParams();

  // console.log(companyUsers);
  const clientList = useAppSelector(selectClientList);
  // const users = useAppSelector(selectUsersOfCompany);
  const industry = useAppSelector(selectIndustryFilterData);
  const stage = useAppSelector(selectStageFilterData);
  // const segment = useAppSelector(selectSegmentFilterData);
  const customerDetail = useAppSelector(selectCustomerDetails).data;
  const closeDrawer = props.onClose;
  const { clientInfo } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [alertDetails, setAlertDetails] = useState<{
    isVisible: boolean;
    type: 'success' | 'failure';
    message: string;
  }>({ isVisible: false, type: 'success', message: '' });
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const { clientSegmentDropdownValues, getCustomNameMappedValue, getStandardNameMappedValue } = useCustomNames();
  // const segment = clientSegmentDropdownValues.map((item: any) => item.custom_segment_name);

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (users.length == 0) {
      dispatch(getAllUsers());
    }
  }, []);

  // useEffect(() => {
  //   console.log(companyUsersDropDownData);
  // }, [companyUsersDropDownData]);

  const handleDelete = async () => {
    const details = {
      client_uid: clientInfo.client_uid,
    };
    dispatch(deleteClient(details));
    closeDrawer();
    navigate('/client-list');
  };

  console.log(clientInfo);
  const initialValues = {
    client_name: clientInfo.name,
    segment_name: clientInfo.segment.name,
    industry_name: getIndustryName(clientInfo.industry.name),
    stage_journerystage: getStageName(clientInfo.stage.name),
    client_account_worth: 0,
    client_account_owner: { id: clientInfo.cs_owner.id, name: clientInfo.cs_owner.user_name },
    client_sales_owner: { id: clientInfo.sales_user_uid, name: clientInfo.sales_owner.user_name },
    client_account_owner_uid: clientInfo.cs_owner.id,
  };

  const sendAuditTrialForClient = async (newForm: any, oldForm: any) => {
    // console.log('Printing objects', { newForm, oldForm });
    for (const i in newForm) {
      if (newForm[i] != oldForm[i]) {
        let detail: any = {};
        if (i == 'client_name') {
          detail = {
            client_uid: clientInfo.client_uid,
            user_uid: getLoggedInUserUid(),
            field: 'Account Name',
            modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
            old_value: oldForm[i],
            new_value: newForm[i],
          };
          await dispatch(addClientAuditTrail(detail));
        } else if (i == 'segment_name') {
          detail = {
            client_uid: clientInfo.client_uid,
            user_uid: getLoggedInUserUid(),
            field: 'Segment',
            modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
            old_value: getSegmentUid(oldForm[i]),
            new_value: getSegmentUid(getStandardNameMappedValue(newForm[i], 'segment_name')),
          };
          await dispatch(addClientAuditTrail(detail));
        } else if (i == 'industry_name') {
          detail = {
            client_uid: clientInfo.client_uid,
            user_uid: getLoggedInUserUid(),
            field: 'Industry',
            modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
            old_value: getIndustryUid(oldForm[i]),
            new_value: getIndustryUid(newForm[i]),
          };
          await dispatch(addClientAuditTrail(detail));
        } else if (i == 'stage_journerystage') {
          detail = {
            client_uid: clientInfo.client_uid,
            user_uid: getLoggedInUserUid(),
            field: 'Stage',
            modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
            old_value: getStageUid(oldForm[i]),
            new_value: getStageUid(newForm[i]),
          };
          await dispatch(addClientAuditTrail(detail));
        } else if (i == 'client_account_worth') {
          detail = {
            client_uid: clientInfo.client_uid,
            user_uid: getLoggedInUserUid(),
            field: 'Account ARR',
            modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
            old_value: oldForm[i],
            new_value: newForm[i],
          };
          await dispatch(addClientAuditTrail(detail));
        } else if (i == 'client_account_owner') {
          if (newForm['client_account_owner'].id != oldForm['client_account_owner'].id) {
            detail = {
              client_uid: clientInfo.client_uid,
              user_uid: getLoggedInUserUid(),
              field: 'CS Owner',
              modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
              old_value: getUserUid(oldForm[i].name),
              new_value: getUserUid(newForm[i].name),
            };
            await dispatch(addClientAuditTrail(detail));
          }
        } else if (i == 'client_sales_owner') {
          if (newForm['client_sales_owner'].id != oldForm['client_sales_owner'].id) {
            detail = {
              client_uid: clientInfo.client_uid,
              user_uid: getLoggedInUserUid(),
              field: 'Sales Owner',
              modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
              old_value: getUserUid(oldForm[i].name),
              new_value: getUserUid(newForm[i].name),
            };
            await dispatch(addClientAuditTrail(detail));
          }
        }
      }
    }
  };
  const updateClientOnFormSubmit = async (values: any) => {
    console.log(values);
    const payload = {
      name: values.client_name,
      sales_owner_id: values.client_sales_owner.id,
      cs_owner_id: values.client_account_owner.id,
      segment_id: getSegmentIdByName(values.segment_name),
      industry_id: getIndustryIdByName(values.industry_name),
      stage_id: getStageIdByName(values.stage_journerystage),
      state: 'ON_BOARDED',
      id: params.clientId,
    };

    console.log(payload);
    await dispatch(updateClient(payload));
    closeDrawer();
    // if (clientList.length != 0) {
    //   let clientFound: any = [];
    //   if (values.client_name.trim() != initialValues.client_name.trim()) {
    //     clientFound = clientList.filter((e: any) => e.client_name === values.client_name);
    //   }

    //   if (clientFound.length == 0 && values.client_name.trim() != '') {
    //     sendAuditTrialForClient(values, initialValues);
    //     await dispatch(
    //       updateClient({
    //         ...clientInfo,
    //         ...values,
    //         segment_name: getStandardNameMappedValue(values.segment_name, 'segment_name'),
    //         client_name: values.client_name.trim(),
    //         client_sales_owner: values.client_sales_owner.name,
    //         client_account_owner: values.client_account_owner.name,
    //         client_account_owner_uid: getUserUid(values.client_account_owner.name),
    //         stage_uid: getStageUid(values.stage_journerystage),
    //         industry_uid: getIndustryUid(values.industry_name),
    //         segment_uid: getSegmentUid(getStandardNameMappedValue(values.segment_name, 'segment_name')),
    //         original_name: initialValues.client_name,
    //         sales_user_uid: values.client_sales_owner.id,
    //         user_uid: values.client_account_owner.id,
    //         customer_uid: getCustomerUid(),
    //       })
    //     );
    //     batchDispatchAfterClientUpdate();
    //     closeDrawer();
    //   } else {
    //     if (values.client_name.trim() == '') {
    //       setAlertDetails({
    //         isVisible: true,
    //         type: 'failure',
    //         message: 'Account Name cannot be empty.',
    //       });
    //     } else {
    //       setAlertDetails({
    //         isVisible: true,
    //         type: 'failure',
    //         message: 'Account Name already exists.',
    //       });
    //     }
    //   }
    // } else {
    //   if (values.client_name.trim() != '') {
    //     sendAuditTrialForClient(values, initialValues);
    //     await dispatch(
    //       updateClient({
    //         ...clientInfo,
    //         ...values,
    //         segment_name: getStandardNameMappedValue(values.segment_name, 'segment_name'),
    //         client_sales_owner: values.client_sales_owner.name,
    //         client_account_owner: values.client_account_owner.name,
    //         stage_uid: getStageUid(values.stage_journerystage),
    //         industry_uid: getIndustryUid(values.industry_name),
    //         segment_uid: getSegmentUid(getStandardNameMappedValue(values.segment_name, 'segment_name')),
    //         original_name: initialValues.client_name.trim(),
    //         sales_user_uid: values.client_sales_owner.id,
    //         user_uid: values.client_account_owner.id,
    //         customer_uid: getCustomerUid(),
    //       })
    //     );
    //     batchDispatchAfterClientUpdate();
    //     closeDrawer();
    //   } else {
    //     setAlertDetails({
    //       isVisible: true,
    //       type: 'failure',
    //       message: 'Account Name cannot be empty.',
    //     });
    //   }
    // }
  };

  const batchDispatchAfterClientUpdate = async () => {
    await dispatch(getClientByUid(clientInfo.client_uid));
    await dispatch(getClientAudit(clientInfo.client_uid));
  };

  // const isMandatoryFieldsEmpty = () => {
  //   for (const value of clientInfo.custom_values) {
  //     if (value.mandatory == '1' && value.field_value.length === 0) return true;
  //   }
  //   return false;
  // };

  const clientDetailForm = () => {
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={editAccountProfileSchema}
        onSubmit={async (values) => {
          // if (isMandatoryFieldsEmpty(clientInfo.custom_values)) {
          //   setAlertDetails({
          //     isVisible: true,
          //     type: 'failure',
          //     message: `Please fill out mandatory Custom fields before editing this ${label_client}`,
          //   });
          //   return;
          // }
          updateClientOnFormSubmit(values);
        }}
      >
        {(props: any) => {
          return (
            <form>
              <Stack mt={2} direction="row">
                <Stack>
                  <Typography variant="subtitle2">{label_client} Name</Typography>
                  <TextField
                    id="client_name"
                    name="client_name"
                    size="small"
                    placeholder="Enter Client Name"
                    value={props.values.client_name}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    error={props.touched.client_name && Boolean(props.errors.client_name)}
                    helperText={props.touched.client_name && props.errors.client_name}
                  ></TextField>
                </Stack>
              </Stack>
              <Stack direction="row" mt={2} spacing={2}>
                <Stack>
                  <Typography variant="subtitle2">Segment</Typography>
                  <CognisaasSelect
                    identifier="name"
                    width="200px"
                    id="segment_name"
                    onChange={(e: any, v: any) => {
                      props.setFieldValue('segment_name', getSegmentNameById(v.props.value));
                    }}
                    name="segment_name"
                    value={props.values.segment_name}
                    validValues={segments}
                    onBlur={props.handleBlur}
                    error={props.touched.segment_name && Boolean(props.errors.segment_name)}
                    helperText={props.touched.segment_name && props.errors.segment_name}
                  />
                </Stack>
                <Stack>
                  <Typography variant="subtitle2">Industry</Typography>
                  <CognisaasSelect
                    width="200px"
                    id="industry_name"
                    name="industry_name"
                    value={props.values.industry_name}
                    onChange={(e: any, v: any) => {
                      props.setFieldValue('industry_name', getIndustryNameById(v.props.value));
                    }}
                    validValues={industries}
                    onBlur={props.handleBlur}
                    error={props.touched.industry_name && Boolean(props.errors.industry_name)}
                    helperText={props.touched.industry_name && props.errors.industry_name}
                  />
                </Stack>
              </Stack>
              <Stack mt={2} direction="row" spacing={2}>
                <Stack>
                  <Typography variant="subtitle2">Journey Stage</Typography>
                  <CognisaasSelect
                    width="200px"
                    id="stage_journerystage"
                    name="stage_journerystage"
                    value={props.values.stage_journerystage}
                    validValues={stages}
                    onChange={(e: any, v: any) => {
                      props.setFieldValue('stage_journerystage', getStageNameById(v.props.value));
                    }}
                    onBlur={props.handleBlur}
                    error={props.touched.stage_journerystage && Boolean(props.errors.stage_journerystage)}
                    helperText={props.touched.stage_journerystage && props.errors.stage_journerystage}
                  />
                </Stack>
                <Stack>
                  <Typography variant="subtitle2">
                    {`Account ${label_arr} (${getSymbolFromCurrency(customerDetail?.currency_code)})`}
                  </Typography>
                  <TextField
                    size="small"
                    type="number"
                    id="client_account_worth"
                    value={props.values.client_account_worth}
                    placeholder={`Enter ${label_arr}`}
                    sx={{ width: '200px' }}
                    name="client_account_worth"
                    onChange={props.handleChange}
                    disabled={props.values.client_account_worth}
                    onBlur={props.handleBlur}
                    error={props.touched.client_account_worth && Boolean(props.errors.client_account_worth)}
                    helperText={props.touched.client_account_worth && props.errors.client_account_worth}
                  ></TextField>
                </Stack>
              </Stack>
              <Stack mt={2} direction="row" spacing={2}>
                <Stack sx={{ width: '200px' }}>
                  <Typography variant="subtitle2">{label_cs_owner} CS Owner</Typography>
                  <CognisaasUserSelect
                    width="200px"
                    id="client_account_owner"
                    name="client_account_owner"
                    allOptions={getUsersDropDownData()}
                    selectedOption={{
                      id: getUserUid(props.values.client_account_owner.name),
                      name:
                        // getUserActiveState(
                        //   getUserUid(props.values.client_account_owner.name),
                        //   companyUsersDropDownData
                        // ) === '0'
                        //   ? `${props.values.client_account_owner.name} (Inactive)`
                        props.values.client_account_owner.name,
                      is_active: 1,
                    }}
                    onChange={(val: any) => {
                      props.setFieldValue('client_account_owner', {
                        name: val.target.textContent,
                        id: getUserIdByName(val.target.textContent),
                        is_active: 1,
                      });
                    }}
                    onBlur={props.handleBlur}
                    error={props.touched.client_account_owner && Boolean(props.errors.client_account_owner)}
                    helperText={props.touched.client_account_owner && props.errors.client_account_owner}
                  />
                </Stack>
                <Stack sx={{ width: '200px' }}>
                  <Typography variant="subtitle2">{label_sales_owner} Sales Owner</Typography>
                  <CognisaasUserSelect
                    width="200px"
                    id="client_sales_owner"
                    name="client_sales_owner"
                    allOptions={getUsersDropDownData()}
                    selectedOption={{
                      id: props.values.client_sales_owner.id,
                      name:
                        // getUserActiveState(
                        //   getUserUid(props.values.client_sales_owner.name),
                        //   companyUsersDropDownData
                        // ) === '0'
                        // `${props.values.client_sales_owner.name} (Inactive)`
                        props.values.client_sales_owner.name,
                      is_active: 1,
                    }}
                    onChange={(val: any) => {
                      props.setFieldValue('client_sales_owner', {
                        name: val.target.textContent,
                        id: getUserIdByName(val.target.textContent),
                        is_active: 1,
                      });
                    }}
                    onBlur={props.handleBlur}
                    error={props.touched.client_sales_owner && Boolean(props.errors.client_sales_owner)}
                    helperText={props.touched.client_sales_owner && props.errors.client_sales_owner}
                  />
                </Stack>
              </Stack>
              <Grid mt={3} mb={2} spacing={1} container direction="row" justifyContent="flex-end" alignItems="flex-end">
                <Grid item>
                  <CognisaasButton isOutlined={true} label="Cancel" clickHandler={closeDrawer} />
                </Grid>
                <Grid item>
                  <CognisaasButton
                    isOutlined={false}
                    label={'Update ' + label_client}
                    disabled={!props.isValid || !props.dirty}
                    clickHandler={() => {
                      props.handleSubmit();
                    }}
                  />
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Formik>
    );
  };

  return (
    <>
      <Drawer open={props.drawerState} onClose={props.onClose} anchor="right">
        <Grid spacing={2} mx={2}>
          <Stack direction="row" justifyContent={'space-between'}>
            <Typography my={3} sx={{ marginTop: '80px' }} variant="h2">
              Edit {label_client} Profile
            </Typography>
            {clientInfo.user_permissions.includes(userPermissionSchema.DELETE) && (
              <IconButton disableRipple sx={{ marginTop: '80px' }} onClick={handleClick}>
                <MoreVertIcon />
              </IconButton>
            )}
          </Stack>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            style={{ padding: '0px' }}
          >
            <MenuItem
              onClick={() => {
                // handleDelete();
                setOpenDeleteModal(true);
              }}
            >
              <Typography variant="inherit">Delete {label_client}</Typography>
            </MenuItem>
          </Menu>

          <PictureUpload client_uid={clientInfo.client_uid || ''} type="client" setAlertDetails={setAlertDetails} />

          {clientDetailForm()}
        </Grid>
      </Drawer>
      <DeleteModal
        isOpen={openDeleteModal}
        setIsOpen={setOpenDeleteModal}
        message={'Are you sure you want to delete this client_label'}
        onDelete={handleDelete}
      />
      <CognisaasToast
        message={alertDetails.message}
        type={alertDetails.type}
        open={alertDetails.isVisible}
        onClose={() => {
          setAlertDetails({ isVisible: false, type: alertDetails.type, message: '' });
        }}
      />
    </>
  );
};

export default EditClientDrawer;
