import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useGridApiContext } from '@mui/x-data-grid-premium';

const ExcelAutoComplete = (params: any, dropdownValues: any[], handleUpdate: any) => {
  const apiRef = useGridApiContext();

  const { id, field } = params;
  const handleChange = (value: string) => {
    handleUpdate(value, id, field);
    apiRef.current.setEditCellValue({ id, field, value: value });
  };

  return (
    <div style={{ width: '100%' }}>
      <Autocomplete
        // open={true}
        onChange={(e: any, v: any) => {
          handleChange(v);
        }}
        onInputChange={(e: any, v: any) => {
          handleChange(v);
        }}
        openOnFocus={true}
        value={params.value}
        style={{ width: '100%' }}
        id="free-solo-demo"
        freeSolo
        options={dropdownValues}
        renderInput={(params) => <TextField {...params} />}
      />
    </div>
  );
};

export default ExcelAutoComplete;
