import {
  label_client,
  label_cs_owner,
  label_due_date,
  label_project,
  label_project_delivery_manager,
  label_sales_owner,
  label_task,
  label_use_case,
} from 'components/Settings/Preferences/CustomLabelCookieHelper';
import * as yup from 'yup';

export const customFieldSchemas = {
  String: yup.string().min(5, 'Atleast 5 characters.'),
  Number: yup.number(),
  Date: yup.date(),
  Dropdown: yup.string().min(5, 'Atleast 5 characters.'),
  Hyperlink: yup.string().url(),
  'RAG status': yup.string().min(5, 'Atleast 5 characters.').required(),
};

// general schemas
export const accountNameSchema = yup.string().required(`${label_client} Name is required`);
export const segmentSchema = yup.string();
export const industrySchema = yup.string().required('Industry Name is required');
export const journeyStageSchema = yup.string();
export const accountArrSchema = yup.number().min(0, 'Atleast 0 is needed');
export const salesOwnerschema = yup
  .object()
  .typeError(`${label_sales_owner} is required`)
  .required(`${label_sales_owner} is required`);
export const csOwnerSchema = yup
  .object()
  .typeError(`${label_cs_owner} is required`)
  .required(`${label_cs_owner} is required`);
export const deliveryManagerSchema = yup
  .object()
  .typeError(`${label_project_delivery_manager} is required`)
  .required(`${label_project_delivery_manager} is required`);
export const domainNameSchema = yup.string().url();

export const loginCredentialsSchema = yup.object({
  email: yup.string().email('Enter a valid email').required('Email is required'),
  password: yup.string().min(8, 'Atlest 8 characters').required('Password is incorrect'),
});

export const RegistrationCredentialsSchema = yup.object({
  username: yup.string().min(5, 'Atleast 5 characters.').required('Username is required'),
  email: yup.string().email('Enter a valid email').required('Email is required'),
  password: yup.string().min(8, 'Atlest 8 characters').required('Password is incorrect'),
});
export const onboardAccountProfileSchema = yup.object({
  accountName: accountNameSchema,
  segment: segmentSchema,
  industry: industrySchema,
  journeyStage: yup.string(),
  accountArr: accountArrSchema,
  salesOwner: salesOwnerschema,
  csOwner: csOwnerSchema,
  contactInfo: yup.array().of(
    yup.object().shape({
      email: yup.string().email('Enter a valid email'),
      name: yup
        .string()
        .max(25, 'Maximum 25 Characters are allowed')
        .when('email', {
          is: (email: any) => email?.length > 0,
          then: yup.string().required('if email is present name is required'),
        }),
      role: yup.string(),
    })
  ),
  whitelistThisDomains: yup.array().of(domainNameSchema),
  comment: yup.string(),
});

export const jiraEnabledInTaskLevel = yup.object({
  selectedJiraProject: yup.string().required('Select a jira project'),
  issueType: yup.string().required('Select an issue type'),
  priorityType: yup.string().required('Priority is required'),
});

export const onboardAddProjectSchema = yup.object({
  projectTemplate: yup.string(),
  projectName: yup.string().required(`${label_project} name is required`),
  clientName: yup.string(),
  priority: yup.string(),
  category: yup.string(),
  expansionValue: yup.number().min(0, 'Value cannot be less than zero.'),
  deliveryManager: yup
    .object()
    .typeError(`${label_project_delivery_manager} is required`)
    .required(`${label_project_delivery_manager} is required`),
  implementationFee: yup.number().min(0, 'Value cannot be less than zero.'),
  projectArr: yup.number().min(0, 'Value cannot be less than zero.'),
  projectDescription: yup.string(),
  comment: yup.string(),
  projectStage: yup.string(),
  link: yup.string().url('Enter a valid url'),
  dueDate: yup.string().required('Due date is required'),
  plannedStartDate: yup.date().required('Planned start date is required'),
  actualStartDate: yup.string().required('Start date is required'),
  teamMembers: yup
    .array()
    .min(1, 'Choose at least one member')
    .of(
      yup.object().shape({
        label: yup.string().required(),
        value: yup.string().required(),
      })
    ),
});

export const addProjectSchema = yup.object({
  projectTemplate: yup.string(),
  projectName: yup.string().required(`${label_project} name is required`),
  clientName: yup.string().required(`${label_client} name is required`),
  priority: yup.string(),
  category: yup.string(),
  expansionValue: yup.number().min(0, 'Value cannot be less than zero.'),
  deliveryManager: yup
    .object()
    .typeError(`${label_project_delivery_manager} is required`)
    .required(`${label_project_delivery_manager} is required`),
  implementationFee: yup.number().min(0, 'Value cannot be less than zero.'),
  projectArr: yup.number().min(0, 'Value cannot be less than zero.'),
  projectDescription: yup.string(),
  comment: yup.string(),
  projectStage: yup.string(),
  link: yup.string().url('Enter a valid url'),
  dueDate: yup.string().required('Due date is required'),
  plannedStartDate: yup.date().required('Planned start date is required'),
  actualStartDate: yup.string().required('Start date is required'),
  teamMembers: yup
    .array()
    .min(1, 'Choose at least one member')
    .of(
      yup.object().shape({
        label: yup.string().required(),
        value: yup.string().required(),
      })
    ),
});

export const addUsecaseSchema = yup.object({
  usecaseTemplate: yup.string(),
  usecaseName: yup.string().required(`${label_use_case} name is required`),
  priority: yup.string(),
  category: yup.string(),
  deliveryManager: deliveryManagerSchema,
  implementationFee: yup.number().min(0, 'Value cannot be less than zero.'),
  expandARR: yup.number().min(0, 'Value cannot be less than zero.'),
  projectDescription: yup.string(),
  comment: yup.string(),
  usecaseStage: yup.string(),
  link: yup.string().url('Enter a valid url'),
  dueDate: yup.string().required(`${label_due_date} is required`),
  plannedStartDate: yup.date().required('Planned start date is required'),
  actualStartDate: yup.string().required('Start date is required'),
});

export const newProductSchema = yup.object({
  productName: yup.string().min(1, 'At least 1 character is needed').required('Product Name Is Required'),
  accountName: yup.string(),
  allRequirements: yup
    .array()
    .of(
      yup.object().shape({
        requirementName: yup.string().required(),
        requirementStatus: yup.string().required(),
        etaForRelease: yup.date(),
      })
    )
    .min(1, 'at least one requirement is needed'),
});

export const estimateSchema = yup.object({
  timeEstimate: yup
    .string()
    .matches(
      /([0-9]{1,3}[w]{1}\\s{1}[0-9]{1,3}[d]{1}\\s{1}[0-9]{1,3}[h]{1}\\s{1}[0-9]{1,3}[m]{1}|[0-9]{1,3}[mhdw]{1}\\s{1}[0-9]{1,3}[mhd]{1}\\s{1}[0-9]{1,3}[mh]{1}|[0-9]{1,3}[wdhm]{1}\\s{1}[0-9]{1,3}[dhm]{1}|[0-9]{1,3}[wdhm]{1})/,
      'Invalid Format'
    ),
});

export const newRequirementSchema = yup.object({
  requirementName: yup.string().min(1, 'at least one character required').required('Requirement Name is requried'),
  requriementStatus: yup.string().required(),
  etaForRelease: yup.date().required(),
  requirementDescription: yup.string(),
});

export const customFieldSettingsSchemas = yup.object({
  fieldName: yup.string().required('Field Name is Required'),
  fieldType: yup.string(),
  commaSeparatedValues: yup.string().when('fieldType', {
    is: (fieldType: any) => fieldType === 'Dropdown',
    then: yup.string().required('Field value is required'),
  }),
});

export const customerPortalTaskSchemas = yup.object({
  taskName: yup.string().required(`${label_task} Name is Required`),
  sectionName: yup.string().required('Phase Name is Required'),
});

export const newProjectTemplateSchema = yup.object({
  templateName: yup.string().required('Template Name is Required'),
  targetNoOfDays: yup.number().required('Target no of days is Required').min(0, 'Target days cannot be less than 0'),
});

export const newSectionTemplateSchema = yup.object({
  sectionName: yup.string().required('Phase Name is Required'),
});

export const newTaskTemplateSchema = yup.object({
  taskName: yup.string().required(`${label_task} Name is Required`),
  durationTime: yup
    .string()
    .matches(
      /([0-9]{1,3}[w]{1}\\s{1}[0-9]{1,3}[d]{1}\\s{1}[0-9]{1,3}[h]{1}\\s{1}[0-9]{1,3}[m]{1}|[0-9]{1,3}[mhdw]{1}\\s{1}[0-9]{1,3}[mhd]{1}\\s{1}[0-9]{1,3}[mh]{1}|[0-9]{1,3}[wdhm]{1}\\s{1}[0-9]{1,3}[dhm]{1}|[0-9]{1,3}[wdhm]{1})/,
      'Invalid Format - 2w 3d 5h 10m'
    ),
});

export const newUseCaseTemplateSchema = yup.object({
  templateName: yup.string().required('Template Name is Required'),
  targetNoOfDays: yup
    .number()
    .required('Target no of days is Required')
    .min(1, 'Target no of days should be greater than zero'),
});

export const smtpValidationSchema = yup.object({
  smtpHost: yup.string().required('SMTP Host Name is Required'),
  smtpUser: yup.string().email('Enter a valid SMTP User').required('SMTP User is required'),
  smtpPassword: yup.string().required('SMTP Host Name is Required'),
  smtpPort: yup.number().required('SMTP Host Name is Required'),
});

export const editProjectSchema = yup.object({
  projectName: yup.string().required('Name is required.'),
  priority: yup.string(),
  projectCategory: yup.string().required(`${label_project} Category cannot be empty.`),
  projectExpansionValue: yup.number().min(0, 'Value cannot be less than zero.'),

  projectDeliveryManager: yup
    .object()
    .typeError(`${label_project_delivery_manager} is required`)
    .required(`${label_project_delivery_manager} is required`),
  projectFees: yup.number().min(0, 'Value cannot be less than zero.'),
  projectARR: yup.number().min(0, 'Value cannot be less than zero.'),

  projectDescription: yup.string(),
  comment: yup.string(),
  projectStage: yup.string(),
});
export const editAccountProfileSchema = yup.object({
  client_name: accountNameSchema,
  stage_journerystage: yup.string(),
  segment_name: segmentSchema,
  industry_name: industrySchema,
  client_sales_owner: yup
    .object()
    .typeError(`${label_sales_owner} is required`)
    .required(`${label_sales_owner} is required`),
  client_account_owner: yup
    .object()
    .typeError(`${label_cs_owner} is required`)
    .required(`${label_cs_owner} is required`),
  client_account_worth: accountArrSchema,
});

export const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
export const clientContactModal = yup.object({
  name: yup.string().required('Name is required.'),
  email: yup
    .string()
    .matches(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/, 'Invalid Email')
    .required('Email is required.'),
  phone: yup.string().matches(phoneRegExp, 'Phone number is not valid'),
});
export const renewalClientTask = yup.object({
  renewalDate: yup.date().required('Renewal Date cannot be empty'),
  duration: yup.string().required('Renewal Duration cannot be empty'),
  remaining: yup
    .number()
    .required('Days remaining for renewal to start sending reminders')
    .transform((value) => (isNaN(value) ? 0 : value))
    .min(0, 'Atleast 0 is needed'),
  frequency: yup
    .number()
    .transform((value) => (isNaN(value) ? 0 : value))
    .required('Frequency of reminders')
    .transform((value) => (isNaN(value) ? 0 : value))
    .min(0, 'Atleast 0 is needed'),
});
export const crmFormSchema = yup.object({
  stage: yup.string().required('Stage is required'),
  fetch_mode: yup.string().required('Fetch Type is required'),
});
export const pipedriveFormSchema = yup.object({
  api_token: yup.string().required('API Token is required'),
  crm_domain: yup.string().required('Subdomain is required'),
});
