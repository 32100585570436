import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { BarDisplay } from './progress-bar-display';
import { BarProps } from 'components/ResourceManagement/components/bar';
import barStyles from './progress-bar.module.css';
import { FlexBox, GridBox, SvgText, Text } from 'components/ResourceManagement/design-system/primitives';
import {
  selectMainViewData,
  setProjectViewModalState,
  setSelectedProjectById,
  setSelectedResourceById,
  setSelectedTaskById,
  setUpdateTaskDrawerState,
} from 'store/modules/ResourceManagement';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Popover } from '@mui/material';
import { getShortDate } from 'components/ResourceManagement/utils/date';
import { usePopover } from '../../shared/usePopover';

const StyledBarText = styled(SvgText)<{
  isTextInside: boolean;
}>`
  ${({ theme, isTextInside }) => {
    return isTextInside
      ? `
  fill: ${theme.colors.grey_1};
  text-anchor: middle;
  font-weight: ${theme.fontWeights[1]};
  dominant-baseline: alphabetic;
  user-select: none;
  pointer-events: none;
  font-size: small;`
      : `
  fill: ${theme.colors.grey_1};
  text-anchor: start;
  user-select: none;
  pointer-events: none;
  font-size: small;
  `;
  }}
`;

const StyledPopoverGrid = styled(GridBox)`
  grid-template-columns: 1fr;
  padding: ${({ theme }) => theme.space[5]};
  grid-column-gap: ${({ theme }) => theme.space[5]};
`;

const StyledG = styled('g')`
  outline: none;
`;

export const ProgressBar: React.FC<BarProps> = ({ component, componentHeight, mode, isSelected }) => {
  const textRef = useRef<SVGTextElement>(null);
  const [isTextInside, setIsTextInside] = useState(true);
  const isMainView = mode === 'main-view';

  const dispatch = useAppDispatch();
  const { anchorEl, handlePopoverClose, handlePopoverOpen, popoverId, popoverOpen } = usePopover();
  // const { isModalOpen, toggleModal } = useModal();
  const selectedMainViewData = useAppSelector(selectMainViewData);

  const handleBarClick = ({ id, entity }: { id: string; entity: string }) => {
    if (entity === 'project') {
      const projectId = id;
      const projectObj = selectedMainViewData?.events?.length
        ? selectedMainViewData.events.find((item: any) => item.id === projectId)
        : null;
      if (projectObj) {
        const resourceId = projectObj?.componentLinkedTo;
        dispatch(setSelectedResourceById({ resourceId }));
        dispatch(setSelectedProjectById({ projectId }));
        dispatch(setProjectViewModalState(true));
      }
    } else if (entity === 'task') {
      const taskId = id;
      dispatch(setSelectedTaskById({ taskId }));
      dispatch(setUpdateTaskDrawerState(true));
    }
  };

  const width = component.x2 - component.x1;
  let actualWidth = width;
  if (actualWidth < 0) actualWidth = ~actualWidth;

  const getBarTextX = () => {
    const width = component.x2 - component.x1;
    let actualWidth = width;
    if (actualWidth < 0) actualWidth = ~actualWidth;
    const hasChild = component.barChildren.length > 0;
    if (isTextInside) {
      return component.x1 + actualWidth * 0.5;
    }
    return component.x1 + actualWidth + +hasChild + 5;
  };

  useEffect(() => {
    if (textRef.current) {
      setIsTextInside(textRef.current.getBBox().width < component.x2 - component.x1);
    }
  }, [textRef, component]);

  const barTextX = getBarTextX();
  let actualBarTextX = barTextX;
  if (actualBarTextX < 0) actualBarTextX = ~actualBarTextX;

  const isSingleGrid = component.count === 1;

  return (
    <>
      <StyledG
        className={barStyles.barWrapper}
        tabIndex={0}
        onClick={() => handleBarClick(component)}
        onMouseEnter={(e: any) => handlePopoverOpen(e)}
        onMouseLeave={() => handlePopoverClose()}
      >
        {actualWidth ? (
          <BarDisplay
            x={component.x1}
            y={component.y}
            width={actualWidth}
            height={component.height}
            progressX={component.progressX}
            progressWidth={component.progressWidth}
            isSelected={isSelected}
          />
        ) : null}
        {actualBarTextX ? (
          <StyledBarText
            x={actualBarTextX}
            y={component.y + componentHeight * 0.67}
            isTextInside={isTextInside}
            ref={textRef}
            variant="paragraph2"
          >
            <>{!isSingleGrid ? `${component.count} items  ` : ''}</>
            {component.progress.toFixed()}%
          </StyledBarText>
        ) : null}
      </StyledG>
      <Popover
        id={popoverId}
        sx={{
          pointerEvents: 'none',
        }}
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        style={{ zIndex: isMainView ? 3 : 31 }}
        disableRestoreFocus
      >
        <StyledPopoverGrid>
          <FlexBox variant="flexCol" justifyContent="center" alignItems="flex-start">
            <Text variant="heading3">{component.name}</Text>
            <FlexBox variant="flexRow" justifyContent="flex-start" alignItems="center">
              <Text variant="paragraph1">Duration:&nbsp;</Text>
              <Text variant="paragraph2">{`${getShortDate(component.start)} - ${getShortDate(component.end)}`}</Text>
            </FlexBox>
            {component.entity === 'task' ? (
              <FlexBox variant="flexRow" justifyContent="flex-start" alignItems="center">
                <Text variant="paragraph1">Time Req:&nbsp;</Text>
                <Text variant="paragraph2">{component.taskEffortEstimate}h</Text>
              </FlexBox>
            ) : null}
            {component.entity === 'task' ? (
              <FlexBox variant="flexRow" justifyContent="flex-start" alignItems="center">
                <Text variant="paragraph1">Stage:&nbsp;</Text>
                <Text variant="paragraph2">{component.taskStage}</Text>
              </FlexBox>
            ) : null}
          </FlexBox>
        </StyledPopoverGrid>
      </Popover>
    </>
  );
};
