import { Stack, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import CognisaasButton from 'components/shared/CognisaasButton/CognisaasButton';
// import CognisaasRequirementCard from 'components/shared/CognisaasCard/CognisaasRequirementCard/Index';
// import CognisaasSelect from 'components/shared/CognisaasSelect/CognisaasSelect';
import MappedProductCard from 'components/shared/MappingComponents/MappedProductCard';
import ProdReqMappingDrawer from 'components/shared/MappingComponents/ProdReqMappingDrawer';
import RequirementCard from 'components/shared/MappingComponents/RequirementCard';
import { useEffect, useState } from 'react';
// import ProdReqLayout from '../../../../../Layouts/ProductRequiremntLayout/ProdReqLayout';
import NoRecordsMapped from 'components/shared/MappingComponents/NoRecordsMapped';
import { useDispatch } from 'react-redux';
// import { updateUsecaseByUid } from 'store/modules/Usecase/slice';
// import { getProjectInformation } from 'store/modules/Project/slice';
// import { format } from 'date-fns';
import BasicModal from 'components/shared/CognisaasModal/CognisaasModal';
import {
  addNewRequirement,
  createJiraIssueEquivalentToRequirement,
  fetchProductAndRequirements,
  getProjectKeyMappedToThisProduct,
  updateRequirementIssueId,
} from 'store/modules/ProductRequirements/ProductDetails/slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectPrAndReqDataState } from 'store/modules/ProductRequirements/ProductDetails/selector';
import ProdReqLayout from 'components/Layouts/ProductRequiremntLayout/ProdReqLayout';
import { userPermissionSchema } from 'components/Reports/SideDrawer/types';
import { selectCustomerDetail } from 'store/modules/Common/selector';
import { selectJiraProjectIssueType } from 'store/modules/Task/selector';
import CognisaasSelect from 'components/shared/CognisaasSelect/CognisaasSelect';
import JiraCustomFields from 'components/shared/JiraHelpers/JiraCustomFields';
import { isNull, isUndefined } from 'utils/dataHelpers';
import { getCustomerDetail } from 'store/modules/Common/slice';
import { getCustomerUid, getLoggedInUserEmail } from 'utils/getUid';
import { getJiraProjectIssueTypesList } from 'store/modules/Task/slice';
import { getJiraCustomFields, getMandatoryCustomFieldsForIssue } from 'components/TaskModule/jiraHelpers/jiraUtil';

interface Props {
  mappedFeatures: any;
  setMappedFeatures: any;
  userPermissions: any[];
}

const ProductRequirementMappingV2 = (props: Props) => {
  const userPermissions = props.userPermissions;

  //   console.log('prod req mapping ', props);
  //   const [usecaseDetails, setUsecaseDetails] = useState<Record<string, any>>(props.usecaseDetails);
  //   const [mappedFeatureArr, setmappedFeatureArr] = useState<any[]>([]);
  const [mappedProductNames, setMappedProductNames] = useState<string[]>([]);
  const [mappedFeaturenames, setMappedFeaturenames] = useState<string[]>([]);
  const [isMapRequirementDrawerOpen, setIsMapRequirementDrawerOpen] = useState(
    userPermissions?.includes(userPermissionSchema.EDIT) ? true : false
  );
  const [selectedProduct, setSelectedProduct] = useState<string>(() => {
    return props.mappedFeatures.length > 0 ? props.mappedFeatures[0].product_name : '';
  });
  const [openAddNewRequirementModal, setOpenAddNewRequirementModal] = useState(false);
  const [newRequirementNamefield, setNewRequirementNamefield] = useState('');
  const productAndRequirementState = useAppSelector(selectPrAndReqDataState);
  // jira
  const [isJiraEnabled, setIsJiraEnabled] = useState<boolean>(false);
  const customerDetail = useAppSelector(selectCustomerDetail);
  const appDispatch = useAppDispatch();
  const [issueTypesForProject, setIssueTypesForProject] = useState<any>('');
  const issueTypes = useAppSelector(selectJiraProjectIssueType);
  const [selectedJiraIssueType, setSelectedJiraIssueType] = useState<string>('');
  const [jiraPayload, setJiraPayload] = useState<Record<any, any>>({});
  const [currnetJiraProjectKey, setCurrnetJiraProjectKey] = useState<string>('');
  const [jiraCustomFields, setJiraCustomFields] = useState<any[]>([]);
  const [jiraCustomFieldsModal, setJiraCustomFieldsModal] = useState<boolean>(false);
  const [newRequirementUid, setNewRequirementUid] = useState<string>('');

  const dispatch = useDispatch();

  useEffect(() => {
    if (isNull(customerDetail)) {
      dispatch(getCustomerDetail());
    } else {
      if (customerDetail.customer_jira_user && customerDetail.customer_jira_user.length > 1) {
        setIsJiraEnabled(true);
      } else {
        setIsJiraEnabled(false);
      }
    }
  }, [customerDetail]);

  // now since we have issue types we will have a list or array of issue types to be rendered on frontend
  useEffect(() => {
    if (issueTypes.length > 0) {
      const issues = issueTypes
        .map((issue: any) => {
          return issue.name;
        })
        .filter((item) => item !== 'Sub-task');
      setIssueTypesForProject([...issues]);
    }
  }, [issueTypes]);

  useEffect(() => {
    if (
      isJiraEnabled &&
      !isUndefined(productAndRequirementState.data) &&
      productAndRequirementState.data.length > 0 &&
      !isUndefined(selectedProduct) &&
      selectedProduct.length > 0
    ) {
      const current_product = productAndRequirementState.data.filter(
        (item: any) => item.product_name === selectedProduct
      );
      (async () => {
        const projct_key_data = await appDispatch(
          getProjectKeyMappedToThisProduct({
            customer_uid: getCustomerUid(),
            product_id: current_product[0].product_issue_id,
          })
        ).unwrap();

        setCurrnetJiraProjectKey(projct_key_data.project_key);

        const data1 = {
          customer_uid: getCustomerUid(),
          key: btoa(btoa(`${customerDetail.customer_jira_user}:${customerDetail.customer_jira_token}`)),
          project_key: projct_key_data.project_key,
        };
        dispatch(getJiraProjectIssueTypesList(data1));
      })();
    }
  }, [productAndRequirementState.data, selectedProduct]);

  useEffect(() => {
    setSelectedProduct(props.mappedFeatures.length > 0 ? props.mappedFeatures[0].product_name : '');
  }, []);

  useEffect(() => {
    props.setMappedFeatures(props.mappedFeatures);
  }, [props.mappedFeatures]);

  //   console.log('usecase details ', usecaseDetails);
  useEffect(() => {
    // update mapped product and feature names on every change in mapped features array
    // console.log('mapped features ', props.mappedFeatures);

    setMappedFeaturenames(props.mappedFeatures.map((item: any) => item.feature_description));
    const tempMappedProductNames: any[] = [];
    props.mappedFeatures.forEach((item: any) => {
      if (!tempMappedProductNames.includes(item.product_name)) {
        tempMappedProductNames.push(item.product_name);
      }
    });
    setMappedProductNames(tempMappedProductNames);
    if (selectedProduct === '') {
      setSelectedProduct(props.mappedFeatures[0]?.product_name);
    }
  }, [props.mappedFeatures]);

  const resetNewRequirementState = () => {
    setNewRequirementUid('');
    setNewRequirementNamefield('');
    setSelectedJiraIssueType('');
  };

  const onRequirementClick = (action: 'add' | 'delete', featureDetails: Record<string, string>) => {
    if (userPermissions?.includes(userPermissionSchema.EDIT)) {
      // console.log('requirement click ', action, featureDetails);
      switch (action) {
        case 'add':
          props.setMappedFeatures((prevFeaturesArr: any) => {
            return [...prevFeaturesArr, featureDetails];
          });
          break;
        case 'delete':
          props.setMappedFeatures((prevFeaturesArr: any) =>
            prevFeaturesArr.filter((item: any) => item.feature_description !== featureDetails.feature_description)
          );
      }
    }
  };

  //   only needed when save and cancel buttons are enabled in prod req mapped drawer
  const handleNewFeatureMappingSave = () => {
    setIsMapRequirementDrawerOpen(false);
  };
  //   only needed when save and cancel buttons are enabled in prod req mapped drawer
  const handleRevertBackToInitialFeaturemapping = () => {
    setIsMapRequirementDrawerOpen(false);
  };

  const addNewRequirementToDb = async (project_uid: string, requirementName: string) => {
    // console.log(selectedProjectListDisplay);
    const serverPayload: any = {
      feature_content: '',
      feature_description: requirementName,
      feature_estimateddeliverydate: '',
      feature_readinessstate_status: 'red',
      product_uid: project_uid,
    };
    const new_requirement_addition_data = await appDispatch(addNewRequirement(serverPayload)).unwrap();
    setNewRequirementUid(new_requirement_addition_data.feature_uid);

    if (isJiraEnabled) {
      const current_product = productAndRequirementState.data.filter(
        (item: any) => item.product_name === selectedProduct
      );
      const tempJiraPayload: any = {
        custom_field_id: '',
        customer_uid: getCustomerUid(),
        description: '',
        formValues: '',
        id: current_product[0].product_issue_id,
        key: btoa(btoa(`${customerDetail.customer_jira_user}:${customerDetail.customer_jira_token}`)),
        name: selectedJiraIssueType,
        product_name: selectedProduct,
        reporter: '',
        required_fields: [],
        summary: requirementName,
        userEmail: getLoggedInUserEmail(),
      };
      setJiraPayload(tempJiraPayload);
      // setPayloadForRequirementUpdateAfterJira(serverPayload);
      await handleJiraIssueCreation(selectedJiraIssueType, tempJiraPayload);
    }

    resetNewRequirementState();
    await dispatch(fetchProductAndRequirements());
    setOpenAddNewRequirementModal(false);
  };

  const handleJiraIssueCreation = async (selectedIssueName: string, jiraPayload: Record<string, any>) => {
    const tempJiraPayload: any = { ...jiraPayload };

    const detail = {
      customer_uid: getCustomerUid(),
      key: btoa(btoa(`${customerDetail.customer_jira_user}:${customerDetail.customer_jira_token}`)),
      project_key: currnetJiraProjectKey,
    };

    console.log('jira cf detail', detail);

    const jiraCustomFields = await getJiraCustomFields(detail);

    const issue = issueTypes.filter((issue: any) => issue.name == selectedIssueName);
    const mandatoryFields = getMandatoryCustomFieldsForIssue(jiraCustomFields, issue[0]);
    // console.log('mandatoryFields', mandatoryFields);

    if (mandatoryFields.length > 0) {
      console.log('Show modal to add custom fields');
      // setTaskDetailToBeAdded(taskDetails);
      setJiraCustomFields([...mandatoryFields]);
      setJiraCustomFieldsModal(true);
    } else {
      // it is a noral issue with no custom fields
      await appDispatch(createJiraIssueEquivalentToRequirement(tempJiraPayload)).then(async (res: any) => {
        console.log('resssss', res.payload);
        const result = JSON.parse(res.payload);
        await dispatch(
          updateRequirementIssueId({
            feature_issue_id: result.id,
            feature_issue_type_name: selectedJiraIssueType,
            feature_uid: newRequirementUid,
            jira_issue_key: result.key,
          })
        );
      });
      resetNewRequirementState();
    }
  };

  const handleCustomMandatoryFieldsSave = async (newMandatoryFieldsWithValue: any[]) => {
    console.log('after clicking save on jira mandatory fields', newMandatoryFieldsWithValue);

    let tempJiraPayload: any = { ...jiraPayload };

    if (newMandatoryFieldsWithValue.length > 0) {
      tempJiraPayload = {
        ...tempJiraPayload,
        required_fields: newMandatoryFieldsWithValue.map((item: any) => {
          const tempItem = { ...item };
          delete tempItem['newValue'];
          return tempItem;
        }),
        formValues: newMandatoryFieldsWithValue.map((item: any) => ({ field_value: item.newValue })),
      };
    }
    // await dispatch(createJiraIssueEquivalentToRequirement(tempJiraPayload));
    await appDispatch(createJiraIssueEquivalentToRequirement(tempJiraPayload)).then(async (res: any) => {
      console.log('resssss', res.payload);
      const result = JSON.parse(res.payload);
      await dispatch(
        updateRequirementIssueId({
          feature_issue_id: result.id,
          feature_issue_type_name: selectedJiraIssueType,
          feature_uid: newRequirementUid,
          jira_issue_key: result.key,
        })
      );
      // setAlertDetails({ isVisible: true, severity: 'success', message: 'Issue Created On Jira' });

      // console.log('3');
      // dispatch(resetAfterJiraIssueCreation());
    });
    resetNewRequirementState();
    setJiraCustomFieldsModal(false);
  };

  const getProductUid = (productName: string) => {
    let productUid = '';
    productAndRequirementState.data.forEach((item) => {
      if (item.product_name === productName) {
        productUid = item.product_uid;
        return;
      }
    });
    return productUid;
  };

  return (
    <Box>
      {props.mappedFeatures.length === 0 ? (
        <NoRecordsMapped
          clickHandler={() => {
            setIsMapRequirementDrawerOpen(true);
          }}
        />
      ) : (
        <ProdReqLayout
          maxHeight="70vh"
          leftBarHeaderComponent={<Typography variant="h2">Products</Typography>}
          leftBarFooterComponent={
            <CognisaasButton
              disabled={!userPermissions?.includes(userPermissionSchema.EDIT)}
              clickHandler={() => setIsMapRequirementDrawerOpen(true)}
              isOutlined={true}
              label={'Add Product'}
            />
          }
          leftBarMainComponent={
            <Stack sx={{ marginTop: '16px' }}>
              {mappedProductNames.map((productName, index) => (
                <MappedProductCard
                  key={index}
                  productName={productName}
                  NumOfReq={props.mappedFeatures.filter((item: any) => item.product_name === productName).length}
                  isActive={selectedProduct === productName}
                  clickHandler={() => {
                    setSelectedProduct(productName);
                  }}
                />
              ))}
            </Stack>
          }
          rightBarHeaderComponent={
            <Stack sx={{ padding: '16px' }} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
              <Typography variant={'h2'}>{selectedProduct}</Typography>
              <CognisaasButton
                clickHandler={() => {
                  setIsMapRequirementDrawerOpen(true);
                }}
                isOutlined={false}
                label={'Requirement Mapping'}
              />
            </Stack>
          }
          rightBarMainComponent={
            <Stack sx={{ marginLeft: '16px' }} direction={'row'} flexWrap={'wrap'}>
              {props.mappedFeatures.map((item: any, index: number) => {
                if (mappedFeaturenames.includes(item.feature_description) && selectedProduct === item.product_name) {
                  return (
                    <RequirementCard
                      key={index}
                      cardClickHandler={() => {
                        setIsMapRequirementDrawerOpen(true);
                      }}
                      // cardDeleteHandler={() => {
                      //   console.log('delete card');
                      // }}
                      date={item.feature_estimateddeliverydate}
                      description={item.feature_content}
                      name={item.feature_description}
                      statusColor={item.feature_readinessstate_status}
                    />
                  );
                } else {
                  return <></>;
                }
              })}
              {/* <RequirementCard
                cardClickHandler={() => {
                  console.log('click card');
                }}
                cardDeleteHandler={() => {
                  console.log('delete card');
                }}
                date={''}
                description={'sdsfd sdfsdf sdfsdfsdf'}
                name={'Aman req'}
                statusColor={'red'}
              /> */}
            </Stack>
          }
        />
      )}

      {/* product requirement mapping drawer */}
      <ProdReqMappingDrawer
        mappedfeatureNames={mappedFeaturenames}
        open={isMapRequirementDrawerOpen}
        setIsOpen={setIsMapRequirementDrawerOpen}
        handleRequirementSelect={onRequirementClick}
        handleSave={handleNewFeatureMappingSave}
        handleCancel={handleRevertBackToInitialFeaturemapping}
        addNewRequirementHandler={setOpenAddNewRequirementModal}
        selectedProduct={selectedProduct}
        setSelectedProduct={setSelectedProduct}
        disableSaveCancel={true}
      />

      {/* add new requirement modal */}
      <BasicModal
        height={isJiraEnabled ? 335 : 255}
        width={300}
        open={openAddNewRequirementModal}
        onClose={() => {
          setOpenAddNewRequirementModal(false);
          resetNewRequirementState();
        }}
      >
        <Stack sx={{ margin: '4px' }}>
          <Typography variant="h2" sx={{ marginBottom: '16px' }}>
            New Requirement Name
          </Typography>
          <Typography variant="subtitle2">Requirement Name</Typography>
          <TextField
            autoFocus
            value={newRequirementNamefield}
            onChange={(e: any) => {
              setNewRequirementNamefield(e.target.value);
            }}
            variant="outlined"
            placeholder="Enter requirement name"
            size="small"
            sx={{ marginBottom: '16px' }}
          />

          {isJiraEnabled ? (
            <>
              <Typography variant="subtitle2">Jira Issue Type*</Typography>

              <CognisaasSelect
                width="292"
                onChange={(e: any) => {
                  setSelectedJiraIssueType(e.target.value);
                }}
                value={selectedJiraIssueType}
                validValues={[...issueTypesForProject]}
              />
            </>
          ) : (
            ''
          )}

          <Typography variant="subtitle2" className="label-text" sx={{ margin: '16px 0px 24px 0px' }}>
            *Submitting a new requirement here will show up as a new feature in ‘Red’ status on the Manage Product
            Requirements page.{' '}
          </Typography>

          <Stack direction={'row'} justifyContent={'flex-end'}>
            <CognisaasButton
              label="Cancel"
              isOutlined={true}
              sx={{ marginRight: '20px' }}
              clickHandler={() => {
                setOpenAddNewRequirementModal(false);
                resetNewRequirementState();
              }}
            />
            <CognisaasButton
              label="Save"
              isOutlined={false}
              clickHandler={() => addNewRequirementToDb(getProductUid(selectedProduct), newRequirementNamefield)}
            />
          </Stack>
        </Stack>
      </BasicModal>

      {/* jira custom fields modal */}
      <BasicModal open={jiraCustomFieldsModal} onClose={() => setJiraCustomFieldsModal(!jiraCustomFieldsModal)}>
        <Stack>
          <JiraCustomFields
            onClose={() => {
              setJiraCustomFieldsModal(!jiraCustomFieldsModal);
              resetNewRequirementState();
            }}
            taskDetail={{}}
            fields={jiraCustomFields}
            handleCustomFieldsSave={handleCustomMandatoryFieldsSave}
          ></JiraCustomFields>
        </Stack>
      </BasicModal>
    </Box>
  );
};

export default ProductRequirementMappingV2;
