import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { selectPhaseDetailsByCustomerUid } from '../../../store/modules/Reports/Phases/selector';
import { getPhasesDetailsByCustomerUid } from '../../../store/modules/Reports/Phases/slice';
import { selectAllFilters } from 'store/modules/AllFilters/selector';
import { addFilterGroup } from 'store/modules/AllFilters/slice';
import { isUndefined, isNull } from 'utils/dataHelpers';
import { availableDateRangeOptions, FilterableData, generateOptionsForThisDataKey } from 'utils/filteringFunctions';
import { label_client, label_project, label_use_case } from 'components/Settings/Preferences/CustomLabelCookieHelper';

// Method used to Convert Database Value to be used in the UI
export const convertMinutes = (num: any) => {
  if (num == 0) {
    return '0m';
  } else {
    let hour = Math.floor(num / 60); // 60 minutes in hour
    let day = Math.floor(hour / 8); // 8 hours a day office time
    const week = Math.floor(day / 5); // 5 days a week office time
    const minute = num - 60 * hour; // remaining minutes

    hour = hour - day * 8; // remaining hours
    day = day - week * 5; // remaining days

    let timeLogValue = ''; // Var to store Time Log Value

    if (week > 0) {
      timeLogValue = timeLogValue.concat(`${week}w `);
    }
    if (day > 0) {
      timeLogValue = timeLogValue.concat(`${day}d `);
    }
    if (hour > 0) {
      timeLogValue = timeLogValue = timeLogValue.concat(`${hour}h `);
    }
    if (minute > 0) {
      timeLogValue = timeLogValue.concat(`${minute}m `);
    }

    timeLogValue = timeLogValue.slice(0, -1); // To remove the last blank space
    return timeLogValue;
  }
};

export const usePhases = () => {
  // for api calls
  const dispatch = useDispatch();

  const phasesDetails = useAppSelector(selectPhaseDetailsByCustomerUid);

  // display data
  const [phasesDataToDisplay, setPhasesDataToDisplay] = useState<any>([]);

  // for filters
  const allFilters = useAppSelector(selectAllFilters);

  // for tabs selected
  const [selectedTab, setSelectedTab] = useState<number>(0);

  // for no. of rows to display
  const [rowsCount, setRowsCount] = useState<number>(10);

  const [showTable, setShowTable] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getPhasesDetailsByCustomerUid());
    console.log('phases details 123 = ', phasesDetails);
  }, []);

  // for filter
  useEffect(() => {
    if (!isUndefined(allFilters['reportPhases']) || !isNull(allFilters['reportPhases'])) {
      if (phasesDetails.status == 'success') {
        setShowTable(true);
        dispatch(
          addFilterGroup({
            idName: 'reportPhases',
            appliedFilters: [
              {
                name: `${label_client} Name`,
                dataKey: 'client_name',
                isSelected: false,
                equalToValue: [],
                canBeCleared: true,
                availableOptions: generateOptionsForThisDataKey(phasesDetails.data, 'client_name'),
              },
              {
                name: `${label_project} Name`,
                dataKey: 'project_name',
                isSelected: false,
                equalToValue: [],
                canBeCleared: true,
                availableOptions: generateOptionsForThisDataKey(phasesDetails.data, 'project_name'),
              },
              {
                name: `${label_use_case} Name`,
                dataKey: 'case_name',
                isSelected: false,
                equalToValue: [],
                canBeCleared: true,
                availableOptions: generateOptionsForThisDataKey(phasesDetails.data, 'case_name'),
              },
              {
                name: 'Planned start date of phase$$date-range-picker',
                dataKey: 'planned_start_date_of_phase',
                isSelected: false,
                equalToValue: [],
                canBeCleared: true,
                availableOptions: availableDateRangeOptions,
              },
              {
                name: 'Actual start date of phase$$date-range-picker',
                dataKey: 'actual_start_date_of_phase',
                isSelected: false,
                equalToValue: [],
                canBeCleared: true,
                availableOptions: availableDateRangeOptions,
              },
              {
                name: 'Planned end date of phase$$date-range-picker',
                dataKey: 'planned_end_date_of_phase',
                isSelected: false,
                equalToValue: [],
                canBeCleared: true,
                availableOptions: availableDateRangeOptions,
              },
              {
                name: 'Actual end date of phase$$date-range-picker',
                dataKey: 'actual_end_date_of_phase',
                isSelected: false,
                equalToValue: [],
                canBeCleared: true,
                availableOptions: availableDateRangeOptions,
              },
              {
                name: 'Phase Completed',
                dataKey: 'phase_completed',
                isSelected: false,
                equalToValue: [],
                canBeCleared: true,
                availableOptions: generateOptionsForThisDataKey(phasesDetails.data, 'phase_completed'),
              },
            ],
          })
        );
      } else {
        setShowTable(false);
      }
    }
  }, [phasesDetails]);

  // actual filtering logic happening here
  useEffect(() => {
    if (phasesDetails.status == 'success') {
      const newFilteredData = new FilterableData(phasesDetails.data);

      if (!isUndefined(allFilters['reportPhases']) && phasesDetails.data.length > 0) {
        newFilteredData.bulkFilter(allFilters['reportPhases'].appliedFilters);
        // setPhasesDataToDisplay(newFilteredData.toArray());
      }
      // console.log('newFilteredData = ', newFilteredData);
      if (selectedTab === 0) {
        // show all phases
        setPhasesDataToDisplay(newFilteredData.toArray());
      } else if (selectedTab === 1) {
        // show only project phases
        const newFilteredProjectData = newFilteredData.myData.filter((obj) => {
          return obj.case_uid === null;
        });
        setPhasesDataToDisplay(newFilteredProjectData);
      } else if (selectedTab === 2) {
        // show only usecase phases
        const newFilteredProjectData = newFilteredData.myData.filter((obj) => {
          return obj.case_uid != null;
        });
        setPhasesDataToDisplay(newFilteredProjectData);
      }
    }
  }, [allFilters, selectedTab, phasesDetails]);

  function handleTabSelection(_event: any, tabIndex: number) {
    // console.log('selected tab = ', tabIndex);
    setSelectedTab(tabIndex);
  }

  return { selectedTab, allFilters, phasesDataToDisplay, rowsCount, showTable, handleTabSelection, setRowsCount };
};
