const initialSelectedColumnOptions = {
  client_name: [],
  client_sales_owner: ['Sahil k', 'Omkar Deshpande', 'Abhishek Goyal'],
  client_account_owner: ['Arif', 'Shubham', 'Aman Jain'],
  segment_name: ['Enterprise', 'Mid-Market', 'SMB'],
  industry_name: [
    'Agriculture',
    'Automobile',
    'Banking & Finance',
    'E-Commerce',
    'Education',
    'Healthcare',
    'Hospitality',
    'Insurance',
    'Manufacturing',
    'Media',
    'Telecom',
    'Retail',
    'Other',
    'Not-Specified',
  ],
  client_readiness_status: ['Completed', 'On Track', 'At Risk', 'Overdue'],
  client_account_worth: [],
  client_comment: [],
  journey_stage: ['Pre-Sales', 'Pilot', 'Onboarding', 'Adoption', 'expansion', 'Churned'],
  roles: ['Manager', 'Senior developer', 'Marketing Head', 'Customer Success'],
  priorities: ['Critical', 'High', 'Medium', 'Low'],
  delivery_managers: ['Aman Jain', 'Arif', 'Aditya', 'Omkar', 'Vivek', 'Rupesh Rao'],
  project_categories: ['Land', 'Expand', 'Maintenance', 'Proof of Concept'],
};

/*
    for reference 
    ON TABLE NAME : COLUMN ID NAME
    account name : client_name 
    sales owner : client_sales_owner
    cs owner : client_account_owner
    segment : segment_name
    Industry : indestry_name
    stage: 
    account status : client_readiness_status
    top isues : 
    ARR : client_account_worth
    comments : client_comment


*/

export default initialSelectedColumnOptions;
