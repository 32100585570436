import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../..';

export const selectClientState = (state: RootState) => state.newClientListState;
export const newSelectClientList = createSelector(selectClientState, (state) => state.clients);
export const selectClientStatusCount = createSelector(newSelectClientList, (clients) => {
  const data = clients.data;
  const all = data.length;
  const overdue = data.filter((client: any) => client.status == 'RED');
  const atrisk = data.filter((client: any) => client.status == 'AMBER');
  const ontrack = data.filter((client: any) => client.status == 'GREEN');
  const completed = data.filter((client: any) => client.status == 'BLUE');

  const res = { all: all, overdue: overdue, atRisk: atrisk, onTrack: ontrack, completed: completed };
  return res;
});
export const selectClientIndustries = createSelector(selectClientState, (state) => state.industries.data);
export const selectClientStages = createSelector(selectClientState, (state) => state.stages.data);
export const selectClientSegments = createSelector(selectClientState, (state) =>
  state.segments.data
);

// export const clientDetail = createSelector(selectClientState, (state) => state.clientListInformation);
export const selectClientCustomFields = createSelector(
  selectClientState,
  (state) => state.clientCutomFieldsInformation
);
