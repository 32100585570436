import { Checkbox, CheckboxProps } from '@mui/material';
// import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
// import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { ReactComponent as Checked } from '../../../assets/svgs/checked.svg';
import { ReactComponent as Unchecked } from '../../../assets/svgs/unchecked.svg';

interface props extends CheckboxProps {
  checked: boolean;
  name?: string;
  value?: string;
  onCheckBoxClick?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  size?: any;
  disabled?: boolean;
}

const CognisaasCheckBox = (props: props) => {
  return (
    <Checkbox
      {...props}
      name={props.name ? props.name : ''}
      value={props.value}
      icon={<Unchecked />}
      checkedIcon={<Checked />}
      checked={props.checked}
      onChange={(e: any) => {
        props.onCheckBoxClick ? props.onCheckBoxClick(e) : '';
      }}
      size={props.size}
      disabled={props.disabled}
    />
  );
};

export default CognisaasCheckBox;
