import { Grid, Typography, Paper, TextField, Button, CircularProgress } from '@mui/material';
import { ReactComponent as BrandLogo } from '../../assets/svgs/BrandLogo.svg';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {otpVerification_slice} from '../../store/modules/AdminPanel/slice';
import { otpVerification_selector } from '../../store/modules/AdminPanel/selector';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';

const OTPSCreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [otp, setOtp] = useState(0);
  const otpVerification_fromSelector = useAppSelector(otpVerification_selector);
//   const [otpTrial, setOtpTrial] = useState(2);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(()=>{
      if(otpVerification_fromSelector){
          if(otpVerification_fromSelector.status=='success' && otpVerification_fromSelector?.data[0]?.status == 0){
              setShowLoader(false);
            //   navigate('/d4rUvIkI8Hd');
            //   sessionStorage.removeItem('admin_jwt');
            //   sessionStorage.removeItem('admin_name');
              alert('Wront Otp!');
            //   navigate('/9kad7Bx73');
            //   setOtpTrial(otpTrial-1);
            //   if(otpTrial<0){
            //       sessionStorage.removeItem('admin_jwt');
            //       sessionStorage.removeItem('user_name');
            //       navigate('/9kad7Bx73', {state: {isFromOtpScreen: true}});
            //   }else{
            //       alert(`Invalid OTP!! You have ${otpTrial} attempts remaining!`);
            //   }
          }else if(otpVerification_fromSelector.status=='success'){
              navigate('/d4rUvIkI8Hd');
          }
      }
  }, [otpVerification_fromSelector]);
  

  const onSubmit = () => {
      setShowLoader(true);
      dispatch(otpVerification_slice(otp));
  };

    return (
        <Grid container>
            <Grid item xs={9} sx={{ backgroundColor: '#F5F5F7', height: '100vh', paddingLeft: '110px' }}>
                <BrandLogo style={{ width: '326px', height: '60px', marginTop: '40vh', marginLeft: '15vw' }} />
            </Grid>
            <Grid item xs={3} sx={{ backgroundColor: '#3D38BA', margin: '0px', padding: '0px', height: '100vh' }}>
            <Paper
                // elevation={1}
                sx={{
                    marginTop: '16vh',
                    position: 'absolute',
                    backgroundColor: '#FFFFFF',
                    width: '410px',
                    height: '35vh',
                    left: '60vw',
                    top: '10vh',
                    padding: '40px 20px 0 20px',
                    borderRadius: '4px',
                    boxShadow: '0px 4px 26px rgba(0, 0, 0, 0.05);',
                    overflowY: 'scroll',
                }}
            >
                <Typography sx={{ fontSize: '20px', fontWeight: '600' }}>OTP Verification</Typography>
                <Typography sx={{ marginTop: '10px', fontSize: '12px', fontWeight: '400' }}>Enter OTP</Typography>
                <TextField
                    sx={{ marginTop: '4px' }}
                    fullWidth
                    size='small'
                    placeholder='Enter OTP'
                    variant='outlined'
                    // type='text'
                    onChange={(e: any) => {setOtp(e.target.value);}}
                    // error={formik.touched.user_email && Boolean(formik.errors.user_email)}
                    // helperText={formik.touched.user_email && formik.errors.user_email}
                />
                <Button
                    sx={{
                    backgroundColor: '#2D60F6',
                    borderRadius: '4px',
                    width: '400px',
                    height: '44px',
                    marginTop: '32px',
                    }}
                    variant='contained'
                    type='submit'
                    onClick={onSubmit}
                >
                    {
                        showLoader ? <Grid sx={{ display: 'flex' }}>
                            <CircularProgress color="inherit" size="1.2rem" />
                        </Grid> : <Typography variant='h2'>Verify</Typography>
                    }
                </Button>
                {/* <Box display='flex' justifyContent='center' alignItems='center' sx={{ width: '400px' }}>
                <Typography sx={{ marginTop: '12px', color: '#7A7A7A', fontWeight: '400' }}>or</Typography>
                </Box> */}
                {/* <Box display='flex' justifyContent='center' alignItems='center' sx={{ marginTop: '36px', width: '400px' }}>
                    <Stack direction='row'>
                        <Typography sx={{ fontSize: '14px', fontWeight: '400' }}>Dont have an account?</Typography>
                        <Typography
                        sx={{ fontSize: '14px', fontWeight: '400', color: '#2D60F6', cursor: 'pointer', marginLeft: '5px' }}
                        onClick={() => navigate('/signup')}
                        >
                        Sign up
                        </Typography>
                    </Stack>
                </Box> */}
            </Paper>
            </Grid>
        </Grid>
    );
};

export default OTPSCreen;