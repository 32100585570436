import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';

import { taskAPI } from 'api/modules/tasks';
import { ViewMode } from 'components/ResourceManagement/types/public-types';
import { resourceManagementAPI } from 'api/modules/resourceManagement';
import { softAllocationAPI } from 'api/modules/softAllocation';
import { requestState } from '../types';
import { resourceManagementResponseTransformer } from './util';

export const getAllDeliveryManagerDetailsWithProjectAndTaskUsingCustomerUid = createAsyncThunk(
  'getAllDeliveryManagerDetailsWithProjectAndTaskUsingCustomerUid',
  async () => {
    const { data } = await resourceManagementAPI.getAllDeliveryManagerDetailsWithProjectAndTaskUsingCustomerUid();
    return data;
  }
);

export const getDataForResourceManagement = createAsyncThunk('rsrcmgmt/getDataForResourceManagement', async () => {
  // const { data } = await resourceManagementAPI.getDataForResourceManagement();
  // data = resourceManagementResponseTransformer([...data.result]);
  const userDataResponse = await resourceManagementAPI.getDataForResourceManagementUser();
  const projectDataResponse = await resourceManagementAPI.getDataForResourceManagementProject();
  const taskDataResponse = await resourceManagementAPI.getDataForResourceManagementTask();
  const resourceManagementData = [
    userDataResponse.data.result ?? [],
    projectDataResponse.data.result ?? [],
    taskDataResponse.data.result ?? [],
  ];
  return resourceManagementData ?? [];
});

export const addProjectTask = createAsyncThunk('resourceManagement/addProjectTask', async (detail: any) => {
  const { data } = await taskAPI.addProjectTask(detail);
  return data;
});

export const addUsecaseTask = createAsyncThunk('resourceManagement/addUsecaseTask', async (detail: any) => {
  const { data } = await taskAPI.addUsecaseTask(detail);
  return data;
});

export const updateTask = createAsyncThunk('resourceManagement/updateTask', async (detail: any) => {
  const { data } = await taskAPI.updateTask(detail);
  return data;
});

export const getSoftAllocationByUserUid = createAsyncThunk(
  'resourceManagement/softAllocation/getSoftAllocationByUserUid',
  async (user_uid: string) => {
    const { data } = await softAllocationAPI.getSoftAllocationByUserUid(user_uid);
    return data;
  }
);

export const addSoftAllocation = createAsyncThunk(
  'resourceManagement/softAllocation/addSoftAllocation',
  async (detail: any) => {
    const { data } = await softAllocationAPI.addSoftAllocation(detail);
    return data;
  }
);

export const updateSoftAllocation = createAsyncThunk(
  'resourceManagement/softAllocation/updateSoftAllocation',
  async (detail: any) => {
    const { data } = await softAllocationAPI.updateSoftAllocation(detail);
    return data;
  }
);

export const deleteSoftAllocation = createAsyncThunk(
  'resourceManagement/softAllocation/deleteSoftAllocation',
  async (detail: any) => {
    const { data } = await softAllocationAPI.deleteSoftAllocation(detail);
    return data;
  }
);

type TResponse = {
  isLoading: boolean;
  isError: boolean;
  status: string;
  data: any[];
  message: any;
};

type TResourceManagementState = {
  members: TResponse;
  addProjectTask: TResponse;
  addUsecaseTask: TResponse;
  updateTask: TResponse;
  getSoftAllocationByUserUid: TResponse;
  addSoftAllocation: TResponse;
  updateSoftAllocation: TResponse;
  deleteSoftAllocation: TResponse;
  mainView: {
    resources: any;
    events: any;
  };
  resourceView: {
    resources: any[];
    events: any[];
  };
  projectView: {
    resources: any[];
    events: any[];
  };
  selectedResourceId: string;
  selectedProjectId: string;
  selectedTaskId: string;
  componentState: {
    startDate: string;
    endDate: string;
    viewMode: ViewMode;
    selectDate: string;
  };
  resourceViewComponentState: {
    startDate: string;
    endDate: string;
    viewMode: ViewMode;
    selectDate: string;
  };
  projectViewComponentState: {
    startDate: string;
    endDate: string;
    viewMode: ViewMode;
    selectDate: string;
  };
  softAllocations: any[];
  selectedSoftAllocationId: string;
  softAllocationDrawer: {
    isOpen: boolean;
    resourceId: string;
    startDate: Date | null;
    endDate: Date | null;
    formMode: string;
    allocationType: any;
    projectId: string;
    id: string;
    isError: boolean;
    errMsg: string;
  };
  updateTaskDrawer: boolean;
  resourceViewModal: boolean;
  projectViewModal: boolean;
  expandedItems: Record<string, boolean>;
};

const commonAPIFields = {
  isLoading: false,
  status: requestState.idle,
  isError: false,
  data: [],
  message: '',
};

const apiStateFields = {
  members: {
    ...commonAPIFields,
  },
  addProjectTask: {
    ...commonAPIFields,
  },
  addUsecaseTask: {
    ...commonAPIFields,
  },
  updateTask: {
    ...commonAPIFields,
  },
  getSoftAllocationByUserUid: {
    ...commonAPIFields,
  },
  addSoftAllocation: {
    ...commonAPIFields,
  },
  updateSoftAllocation: {
    ...commonAPIFields,
  },
  deleteSoftAllocation: {
    ...commonAPIFields,
  },
};

const uiStateFields = {
  mainView: {
    resources: [],
    events: [],
  },
  resourceView: {
    resources: [],
    events: [],
  },
  resourceViewComponentState: {
    startDate: moment(new Date()).startOf('month').format(),
    endDate: moment(new Date()).endOf('month').format(),
    viewMode: ViewMode.Day,
    selectDate: moment(new Date()).startOf('month').format(),
  },
  projectView: {
    resources: [],
    events: [],
  },
  projectViewComponentState: {
    startDate: moment(new Date()).startOf('month').format(),
    endDate: moment(new Date()).endOf('month').format(),
    viewMode: ViewMode.Day,
    selectDate: moment(new Date()).startOf('month').format(),
  },
  selectedResourceId: '',
  selectedProjectId: '',
  selectedTaskId: '',
  componentState: {
    startDate: moment(new Date()).startOf('month').format(),
    endDate: moment(new Date()).endOf('month').format(),
    viewMode: ViewMode.Day,
    selectDate: moment(new Date()).startOf('month').format(),
  },
  softAllocations: [],
  selectedSoftAllocationId: '',
  softAllocationDrawer: {
    isOpen: false,
    resourceId: '',
    startDate: null,
    endDate: null,
    formMode: '',
    allocationType: null,
    projectId: '',
    id: '',
    isError: false,
    errMsg: '',
  },
  updateTaskDrawer: false,
  resourceViewModal: false,
  projectViewModal: false,
  expandedItems: {},
};

const initialState: TResourceManagementState = {
  ...apiStateFields,
  ...uiStateFields,
};

export const resourceManagementSlice = createSlice({
  name: 'resourceManagement',
  initialState,
  reducers: {
    setDataForMainView: (state, action) => {
      state.mainView.resources = action.payload.resources;
      state.mainView.events = action.payload.events;
    },
    setDataForResourceView: (state, action) => {
      state.resourceView.resources = action.payload.resources;
      state.resourceView.events = action.payload.events;
    },
    setDataForProjectView: (state, action) => {
      state.projectView.resources = action.payload.resources;
      state.projectView.events = action.payload.events;
    },
    setSelectedResourceById: (state, action) => {
      state.selectedResourceId = action.payload.resourceId;
    },
    setSelectedProjectById: (state, action) => {
      state.selectedProjectId = action.payload.projectId;
    },
    setSelectedTaskById: (state, action) => {
      state.selectedTaskId = action.payload.taskId;
    },
    setComponentState: (state, action) => {
      const { viewMode, startDate, endDate, selectDate } = action.payload;
      const componentState = Object.assign({}, { viewMode, startDate, endDate, selectDate });
      return {
        ...state,
        componentState,
      };
    },
    setResourceViewComponentState: (state, action) => {
      const { viewMode, startDate, endDate, selectDate } = action.payload;
      const resourceViewComponentState = Object.assign({}, { viewMode, startDate, endDate, selectDate });
      return {
        ...state,
        resourceViewComponentState,
      };
    },
    setProjectViewComponentState: (state, action) => {
      const { viewMode, startDate, endDate, selectDate } = action.payload;
      const projectViewComponentState = Object.assign({}, { viewMode, startDate, endDate, selectDate });
      return {
        ...state,
        projectViewComponentState,
      };
    },
    setSoftAllocationData: (state, action) => {
      state.softAllocations = action.payload.softAllocations;
    },
    setSelectedSoftAllocationById: (state, action) => {
      state.selectedSoftAllocationId = action.payload.softAllocationId;
    },
    setSoftAllocationDrawerState: (state, action) => {
      const { isOpen, resourceId, startDate, endDate, allocationType, projectId, formMode, id, isError, errMsg } =
        action.payload;
      const softAllocationDrawer = Object.assign(
        {},
        { isOpen, resourceId, startDate, endDate, allocationType, projectId, formMode, id, isError, errMsg }
      );
      state.softAllocationDrawer = softAllocationDrawer;
    },
    setUpdateTaskDrawerState: (state, action) => {
      state.updateTaskDrawer = action.payload;
    },
    setResourceViewModalState: (state, action) => {
      state.resourceViewModal = action.payload;
    },
    setProjectViewModalState: (state, action) => {
      state.projectViewModal = action.payload;
    },
    setExpandedItems: (state, action) => {
      state.expandedItems = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllDeliveryManagerDetailsWithProjectAndTaskUsingCustomerUid.pending, (state) => {
        state.members.status = requestState.loading;
        state.members.isLoading = true;
      })
      .addCase(getAllDeliveryManagerDetailsWithProjectAndTaskUsingCustomerUid.fulfilled, (state, action) => {
        if (action?.meta?.requestStatus === 'fulfilled') {
          state.members.status = requestState.success;
          state.members.message = action.payload.message ?? 'success';
          state.members.data = action?.payload?.result ?? [];
          state.members.isLoading = false;
        }
      })
      .addCase(getAllDeliveryManagerDetailsWithProjectAndTaskUsingCustomerUid.rejected, (state, action) => {
        state.members.status = requestState.failed;
        state.members.isError = true;
        state.members.message = action.error.message;
        state.members.isLoading = false;
      })

      // Resource management new api
      .addCase(getDataForResourceManagement.pending, (state) => {
        state.members.status = requestState.loading;
        state.members.isLoading = true;
      })
      .addCase(getDataForResourceManagement.fulfilled, (state, action) => {
        if (action?.meta?.requestStatus === 'fulfilled') {
          state.members.status = requestState.success;
          state.members.message = 'Resources ready';

          if (action.payload) {
            const detail = {
              users: [...action.payload[0]],
              projects: [...action.payload[1]],
              tasks: [...action.payload[2]],
            };
            const transformedResponse = resourceManagementResponseTransformer(detail);
            // console.log({ transformedResponse });
            state.members.data = [...transformedResponse];
          } else {
            state.members.data = [];
          }
          state.members.isLoading = false;
        }
      })
      .addCase(getDataForResourceManagement.rejected, (state, action) => {
        state.members.status = requestState.failed;
        state.members.isError = true;
        state.members.message = action.error.message;
        state.members.isLoading = false;
      })

      .addCase(addProjectTask.pending, (state) => {
        state.addProjectTask.status = requestState.loading;
        state.addProjectTask.isLoading = true;
      })
      .addCase(addProjectTask.fulfilled, (state, action) => {
        if (action?.payload?.status === 1) {
          const responseData = action.payload.result;
          state.addProjectTask.status = requestState.success;
          state.addProjectTask.message = action.payload.message;
          const dataWithAddedProjectTask = [...state.addProjectTask.data, responseData];
          state.addProjectTask.data = [...dataWithAddedProjectTask];
          state.addProjectTask.isLoading = false;
        }
      })
      .addCase(addProjectTask.rejected, (state, action) => {
        state.addProjectTask.status = requestState.failed;
        state.addProjectTask.isError = true;
        state.addProjectTask.message = action.error.message;
        state.addProjectTask.isLoading = false;
      })
      .addCase(addUsecaseTask.pending, (state) => {
        state.addUsecaseTask.status = requestState.loading;
        state.addUsecaseTask.isLoading = true;
      })
      .addCase(addUsecaseTask.fulfilled, (state, action) => {
        if (action?.payload?.status === 1) {
          const responseData = action.payload.result;
          state.addUsecaseTask.status = requestState.success;
          state.addUsecaseTask.message = action.payload.message;
          state.addUsecaseTask.data = action.payload.result;
          const dataWithUpdatedUsecaseTask = [...state.addUsecaseTask.data, responseData];
          state.addProjectTask.data = [...dataWithUpdatedUsecaseTask];
          state.addUsecaseTask.isLoading = false;
        }
      })
      .addCase(addUsecaseTask.rejected, (state, action) => {
        state.addUsecaseTask.status = requestState.failed;
        state.addUsecaseTask.isError = true;
        state.addUsecaseTask.message = action.error.message;
        state.addUsecaseTask.isLoading = false;
      })
      .addCase(updateTask.pending, (state) => {
        state.updateTask.status = requestState.loading;
        state.updateTask.isLoading = true;
      })
      .addCase(updateTask.fulfilled, (state, action) => {
        if (action?.payload?.status === 1) {
          const updateTaskObj = action?.payload?.result?.[0] ?? [];
          state.updateTask.message = action.payload.message;
          const selectedMemberId = state.selectedResourceId;

          const finalUpdatedTaskData = state.members.data.map((member: any) => {
            if (member.delivery_manager_uid === selectedMemberId) {
              const updatedMember: any = {
                ...member,
                projects: member.projects.map((projectItem: any) => {
                  if (projectItem?.project_details?.project_uid === updateTaskObj.project_uid) {
                    const updatedProject: any = {
                      ...projectItem,
                      tasks: projectItem.tasks.map((taskItem: any) => {
                        if (taskItem.task_uid === updateTaskObj.task_uid) {
                          return { ...updateTaskObj };
                        }
                        return taskItem;
                      }),
                    };
                    return { ...updatedProject };
                  }
                  return projectItem;
                }),
              };
              return updatedMember;
            }
            return member;
          });

          state.members.data = [...finalUpdatedTaskData];
          state.updateTask.isLoading = false;
        }
      })
      .addCase(updateTask.rejected, (state, action) => {
        state.updateTask.status = requestState.failed;
        state.updateTask.isError = true;
        state.updateTask.message = action.error.message;
        state.updateTask.isLoading = false;
      })
      .addCase(getSoftAllocationByUserUid.pending, (state) => {
        state.getSoftAllocationByUserUid.status = requestState.loading;
        state.getSoftAllocationByUserUid.isLoading = true;
      })
      .addCase(getSoftAllocationByUserUid.fulfilled, (state, action) => {
        state.getSoftAllocationByUserUid.status = requestState.success;
        state.getSoftAllocationByUserUid.message = action.payload.message;
        state.getSoftAllocationByUserUid.data = action?.payload?.result ?? [];
        state.getSoftAllocationByUserUid.isLoading = false;
      })
      .addCase(getSoftAllocationByUserUid.rejected, (state, action) => {
        state.getSoftAllocationByUserUid.status = requestState.failed;
        state.getSoftAllocationByUserUid.isError = true;
        state.getSoftAllocationByUserUid.message = action.error.message;
        state.getSoftAllocationByUserUid.isLoading = false;
      })
      .addCase(addSoftAllocation.pending, (state) => {
        state.addSoftAllocation.status = requestState.loading;
        state.addSoftAllocation.isLoading = true;
      })
      .addCase(addSoftAllocation.fulfilled, (state, action) => {
        if (action?.payload?.status === 1) {
          const addSoftAllocationResponseData = action?.payload?.result?.[0] ?? [];
          state.addSoftAllocation.status = requestState.success;
          state.addSoftAllocation.message = action.payload.message;
          const finalAddedData = state.members.data.map((member: any) => {
            if (member.delivery_manager_uid === addSoftAllocationResponseData.user_uid) {
              const updatedMember: any = {
                ...member,
                soft_allocation: [...member.soft_allocation, addSoftAllocationResponseData],
              };
              return updatedMember;
            }
            return member;
          });
          state.members.data = [...finalAddedData];
          state.addSoftAllocation.isLoading = false;
        }
      })
      .addCase(addSoftAllocation.rejected, (state, action) => {
        state.addSoftAllocation.status = requestState.failed;
        state.addSoftAllocation.isError = true;
        state.addSoftAllocation.message = action.error.message;
        state.addSoftAllocation.isLoading = false;
      })
      .addCase(updateSoftAllocation.pending, (state) => {
        state.updateSoftAllocation.status = requestState.loading;
        state.updateSoftAllocation.isLoading = true;
      })
      .addCase(updateSoftAllocation.fulfilled, (state, action) => {
        if (action?.payload?.status === 1) {
          const updateSoftAllocationResponseData = action?.payload?.result?.[0] ?? [];
          state.updateSoftAllocation.status = requestState.success;
          state.updateSoftAllocation.message = action.payload.message;
          const finalUpdatedData = state.members.data.map((member: any) => {
            if (member.delivery_manager_uid === updateSoftAllocationResponseData.user_uid) {
              const updatedMember: any = {
                ...member,
                soft_allocation: member.soft_allocation.map((softAllocationItem: any) => {
                  if (softAllocationItem.soft_allocation_uid === updateSoftAllocationResponseData.soft_allocation_uid) {
                    return { ...updateSoftAllocationResponseData };
                  }
                  return softAllocationItem;
                }),
              };
              return updatedMember;
            }
            return member;
          });
          state.members.data = [...finalUpdatedData];
          state.updateSoftAllocation.isLoading = false;
        }
      })
      .addCase(updateSoftAllocation.rejected, (state, action) => {
        state.updateSoftAllocation.status = requestState.failed;
        state.updateSoftAllocation.isError = true;
        state.updateSoftAllocation.message = action.error.message;
        state.updateSoftAllocation.isLoading = false;
      })
      .addCase(deleteSoftAllocation.pending, (state) => {
        state.deleteSoftAllocation.status = requestState.loading;
        state.deleteSoftAllocation.isLoading = true;
      })
      .addCase(deleteSoftAllocation.fulfilled, (state, action) => {
        if (action?.payload?.status === 1) {
          const deleteSoftAllocationResponseData = action?.payload?.result?.[0] ?? [];
          state.deleteSoftAllocation.status = requestState.success;
          state.deleteSoftAllocation.message = action.payload.message;
          const finalDeletedData = state.members.data.map((member: any) => {
            if (member.delivery_manager_uid === deleteSoftAllocationResponseData.user_uid) {
              const updatedMember: any = {
                ...member,
                soft_allocation: member.soft_allocation.filter(
                  (softAllocationItem: any) =>
                    softAllocationItem.soft_allocation_uid !== deleteSoftAllocationResponseData.soft_allocation_uid
                ),
              };
              return updatedMember;
            }
            return member;
          });
          state.members.data = [...finalDeletedData];
          state.deleteSoftAllocation.isLoading = false;
        }
      })
      .addCase(deleteSoftAllocation.rejected, (state, action) => {
        state.deleteSoftAllocation.status = requestState.failed;
        state.deleteSoftAllocation.isError = true;
        state.deleteSoftAllocation.message = action.error.message;
        state.deleteSoftAllocation.isLoading = false;
      });
  },
});

export const resourceManagementReducer = resourceManagementSlice.reducer;
export const {
  setDataForMainView,
  setDataForResourceView,
  setDataForProjectView,
  setSelectedResourceById,
  setSelectedProjectById,
  setSelectedTaskById,
  setComponentState,
  setResourceViewComponentState,
  setProjectViewComponentState,
  setSoftAllocationData,
  setSelectedSoftAllocationById,
  setSoftAllocationDrawerState,
  setUpdateTaskDrawerState,
  setResourceViewModalState,
  setProjectViewModalState,
  setExpandedItems,
} = resourceManagementSlice.actions;
export default resourceManagementReducer;
