import ClientProfile from './ClientProfile/ClientProfile';
import { Grid, Typography, Stack, Box, Paper } from '@mui/material';
import CognisaasTabs from 'components/shared/CognisaasTabs';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getClientDetail } from 'store/modules/features/client/client';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { selectClientDetail } from 'store/modules/features/client/selector';
import { status } from 'store/modules/types';
import CognisaasStatus from 'components/shared/CognisaasSttaus/CognisaasStatus';
import CognisaasStatusValue from 'components/shared/CognisaasSttaus/CognisaasStatusValue';
import ClientProjects from './ClientProjects/ClientProjects';
import DeliverablesTimeline from './DeliverablesTimeline/DeliverablesTimieline';
import RecentActivity from 'components/shared/RecentActivity/RecentActivity';
import ClientCustomFields from './ClientCustomFields/ClientCustomFields';
import RenewalConfiguration from './RenewalConfig/RenewalConfiguration';
import ClientSettings from './ClientSettings/ClientSettings';
import ClientContacts from './ClientContacts/ClientContacts';

const tabs = [
  'Overview',
  'Attachments',
  'Client Contacts',
  'Custom Fields',
  'Renewals',
  'Access List',
  'Client Settings',
];

const ClientModule2 = () => {
  const dispatch = useDispatch();
  const clientDetail = useAppSelector(selectClientDetail);

  const params = useParams();

  const [selectedTab, setSelectedTab] = useState<number>(0);
  useEffect(() => {
    dispatch(getClientDetail(params.clientId as string));
  }, []);

  useEffect(() => {
    console.log(clientDetail);
  }, [clientDetail]);

  const componentForSelectedTab = (index: number) => {
    if (index == 0) {
      return (
        <Grid spacing={0} container m={0}>
          <Grid item md={3} mt={1}>
            <ClientProfile />
          </Grid>
          <Grid item md={6} px={1} mt={1}>
            <Paper
              sx={{
                marginLeft: '16px',
                overflowY: 'scroll',
                height: 'calc(100vh - 200px)',
                background: '#fff',
                boxShadow: 'none',
                borderRadius: '4px',
              }}
            >
              <ClientProjects
                clientUid={''}
                client={''}
                clientDetailWithProject={[]}
                allTasksOfClient={[]}
                setShowClientModule={''}
              />
            </Paper>
          </Grid>
          <Grid spacing={1} item sm={12} md={3} mt={1} px={0}>
            <Paper
              sx={{
                marginLeft: '16px',
                marginRight: '16px',
                padding: '20px',
                overflowY: 'scroll',
                boxShadow: 'none',
                background: '#fff',
                borderRadius: '4px',
              }}
            >
              <DeliverablesTimeline client={clientDetail.data} tasks={[]} />
            </Paper>
            <Paper
              sx={{
                marginLeft: '16px',
                marginRight: '16px',
                marginTop: '8px',
                padding: '10px 0 10px 15px',
                overflowY: 'scroll',
                boxShadow: 'none',
                background: '#fff',
                borderRadius: '4px',
              }}
            >
              <RecentActivity audit={[]} type={'Client'} totalTrail={[]} clientName={clientDetail?.data.name} />
            </Paper>
          </Grid>
        </Grid>
      );
    } else if (index == 1) {
      return <>Attachments</>;
    } else if (index == 2) {
      return <ClientContacts />;
    } else if (index == 3) {
      return (
        <>
          <ClientCustomFields />
        </>
      );
    } else if (index == 4) {
      return <RenewalConfiguration />;
    } else if (index == 5) {
      return <>Access list</>;
    } else if (index == 6) {
      return <ClientSettings />;
    }
  };

  return (
    <Stack mx={2}>
      {clientDetail.data && (
        <>
          <Stack my={2} direction={'row'}>
            <Typography pt={1} ml={2} variant="h2">
              {clientDetail?.data.name}
            </Typography>
            {
              <Stack direction="row" mt={1}>
                <CognisaasStatus status={clientDetail.data.status} />
                <Stack pl={1}>
                  <CognisaasStatusValue status={clientDetail.data.status} />
                </Stack>
              </Stack>
            }
          </Stack>

          <CognisaasTabs tabs={tabs} handleTabSwitch={(e: any, index: any) => setSelectedTab(index)} />
          {componentForSelectedTab(selectedTab)}
        </>
      )}
      {/* <>Client Module 2</> */}
    </Stack>
  );
};

export default ClientModule2;
