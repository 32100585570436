import { Grid, Paper, Stack, Typography } from '@mui/material';
import { label_client } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { userPermissionSchema } from 'components/Reports/SideDrawer/types';
import CognisaasButton from 'components/shared/CognisaasButton/CognisaasButton';
import { Field, Form, Formik } from 'formik';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { selectClientDetailStatus } from 'store/modules/ManageClient/ClientList/selector';
import { getClientDetail, updateClientSettings } from '../../../store/modules/ManageClient/ClientList/slice';

import { selectClientDetail } from 'store/modules/features/client/selector';

const ClientSettings = (props: any) => {
  console.log('client setting props =', props);
  const clientUid = props.clientUid;
  const dispatch = useDispatch();
  const clientDetail = useAppSelector(selectClientDetail);
  const clientDetailStatus = useAppSelector(selectClientDetailStatus);

  useEffect(() => {
    console.log('client details = ', clientDetail);
  }, [clientDetail]);

  useEffect(() => {
    dispatch(getClientDetail(clientUid));
  }, []);

  const handleSubmit = async (formValues: any) => {
    console.log('formValues =', formValues);
    // alert(JSON.stringify(formValues, null, 2));
    await dispatch(
      updateClientSettings({
        client_uid: clientUid,
        task_status_change_mail: formValues.task_status_change_mail,
      })
    );
    await dispatch(getClientDetail(clientUid));
  };

  return (
    <Paper
      style={{
        padding: '20px',
      }}
    >
      <Typography variant="h2" style={{ marginBottom: '10px' }}>
        Email Settings
      </Typography>
      <Typography variant="subtitle2" className="label-text" style={{ marginBottom: '25px' }}>
        Here you can configure the email settings of this {label_client}
      </Typography>
      {clientDetail.status == 'success' && (
        <Formik
          initialValues={{
            // task_status_change_mail: false,
            task_status_change_mail: clientDetail.data?.task_status_change_mail == 1 ? true : false,
          }}
          onSubmit={(formValues) => {
            handleSubmit(formValues);
          }}
        >
          {(formValues: any) => (
            <Form>
              <label>
                <Typography variant="subtitle1">
                  <Field type="checkbox" name="task_status_change_mail"></Field>Send email notifications to{' '}
                  {/* {label_client.toLowerCase()}&nbsp;contacts. */}
                  Client Contacts
                </Typography>
              </label>
              {clientDetail.data.user_permissions.includes(userPermissionSchema.EDIT) && (
                <Grid
                  style={{ paddingBottom: '16px' }}
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Grid sx={{ marginRight: '10%', paddingBotton: '16px', alignItems: 'end' }} item>
                    <Stack direction="row" spacing={2}>
                      <CognisaasButton
                        clickHandler={formValues.handleSubmit}
                        isOutlined={false}
                        disabled={!formValues.dirty}
                        label="Save"
                      />
                    </Stack>
                  </Grid>
                </Grid>
              )}
            </Form>
          )}
        </Formik>
      )}
    </Paper>
  );
};

export default ClientSettings;
