import { ReactComponent as AttachIcon } from '../../../assets/svgs/attach.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/svgs/delete.svg';
import Styles from './Attachment.module.css';
import { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import FileIcon from './FileIcon';
import CognisaaasTooltip from '../CognisaasTooltip/CognisaasTooltip';
import { Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import {
  addAttachmentsWithCustomUrlAndPayload,
  addAttachmentToAttachmentQueue,
  deleteAttachmentFromQueue,
  resetAttachmentQueue,
} from '../../../store/modules/Attachment/slice';
import { selectAttachmentQueue } from '../../../store/modules/Attachment/selector';
import { ReactComponent as UploadIcon } from '../../../assets/svgs/upload.svg';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import DeleteModal from '../CognisaasModal/DeleteModal';
import { addProjectAuditTrial } from 'store/modules/Project/slice';
import { getLoggedInUserUid } from 'utils/getUid';
import { format } from 'date-fns';
import { addUsecaseAuditTrial } from 'store/modules/Usecase/slice';
import { addTaskAuditTrail } from 'store/modules/Task/slice';

interface Props {
  variant?: 'instant' | 'bulk-upload';
  type?: 'small' | 'normal' | 'modal';
  metaDataForHeaders: Record<string, any>;
  apiUrlForAttachmentUpload: string;
  viewOnlyMode?: boolean;
  callbackOnWhatIsDoneWithAttachments?: (actionPerformed: string) => void;
  setModalIndex?: (value: number) => void;
  error?: boolean;
  setAlertDetails: React.Dispatch<
    React.SetStateAction<{
      isVisible: boolean;
      type: 'success' | 'failure';
      message: string;
    }>
  >;
  acceptFile?: 'single' | 'multiple';
}

// note according to the actionPerformed we get a action type in callback function
// if you didn't canceled anything and upload is success then don't forget to resetAttachmentQueue after your use

const initialState = { id: '', metaDataForHeaders: {}, file: { name: '' } };

const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

const Attachment = (props: Props) => {
  const [fileData, setFileData] = useState<Record<string, any>>(initialState);
  const dispatch = useAppDispatch();
  const attachmentQueue = useAppSelector(selectAttachmentQueue);
  const [totalNumberOfAttachments, setTotalNumberOfAttachments] = useState<number>(0);
  const [isSaving, setIsSaving] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleFile = (e: any) => {
    const newFileData = {
      id: props.metaDataForHeaders.field_uid,
      metaDataForHeaders: props.metaDataForHeaders,
      file: e.target.files.item(0),
    };
    setFileData(newFileData);
    // console.log(e.target);
    dispatch(addAttachmentToAttachmentQueue(newFileData));
  };

  // this function can take n number of files and add this files to attachmentQueue in store
  const handleBulkFiles = (multipleFileList: any) => {
    dispatch(resetAttachmentQueue());
    const numberOfFiles = multipleFileList.length;
    // console.log(numberOfFiles);
    setTotalNumberOfAttachments(multipleFileList.length);

    if (numberOfFiles > 0) {
      for (let i = 0; i < numberOfFiles; i++) {
        // console.log(multipleFileList.item(i));
        const newFileData = {
          id: Date.now(),
          metaDataForHeaders: props.metaDataForHeaders,
          file: multipleFileList.item(i),
        };
        dispatch(addAttachmentToAttachmentQueue(newFileData));
      }
    } else {
      alert('add some files first');
    }
  };

  const addAudit = (data: any) => {
    console.log(data);
    if (data.level_name == 'Project') {
      const detail = {
        project_uid: props.metaDataForHeaders.project_uid,
        user_uid: getLoggedInUserUid(),
        field: 'attachment_added',
        modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
        old_value: '',
        new_value: '',
      };
      dispatch(addProjectAuditTrial(detail));
    } else if (data.level_name == 'Usecase') {
      const detail = {
        case_uid: props.metaDataForHeaders.case_uid,
        user_uid: getLoggedInUserUid(),
        field: 'attachment_added',
        modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
        old_value: '',
        new_value: '',
      };
      dispatch(addUsecaseAuditTrial(detail));
    } else if (data.level_name == 'task') {
      const detail = {
        task_uid: props.metaDataForHeaders.level_uid,
        user_uid: getLoggedInUserUid(),
        field: 'attachment_added',
        modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
        old_value: '',
        new_value: '',
      };
      dispatch(addTaskAuditTrail(detail));
    }
  };

  const uploadAttachments = () => {
    setIsSaving(true);
    console.log('audittt', props.metaDataForHeaders);
    attachmentQueue.forEach((attachment: any) => {
      dispatch(
        addAttachmentsWithCustomUrlAndPayload({ apiUrl: props.apiUrlForAttachmentUpload, oneAttachment: attachment })
      )
        .unwrap()
        .then((res) => {
          if (res.status === 0) {
            props.setAlertDetails({
              isVisible: true,
              message: res.message,
              type: 'failure',
            });
          } else {
            props.setAlertDetails({
              isVisible: true,
              message: 'File uploaded successfully',
              type: 'success',
            });
            addAudit(props.metaDataForHeaders);
          }
        })
        .catch(() => {
          props.setAlertDetails({
            isVisible: true,
            message: 'File size larger than 20 MB',
            type: 'failure',
          });
        });
    });

    setTimeout(() => {
      if (props.callbackOnWhatIsDoneWithAttachments != undefined) {
        props.callbackOnWhatIsDoneWithAttachments('uploaded-bulk-attachments');
      }
      setIsSaving(false);
      if (props.setModalIndex) {
        props.setModalIndex(0);
      }
    }, (totalNumberOfAttachments + 1) * 1000);
  };

  const cancelUpload = () => {
    dispatch(resetAttachmentQueue());

    if (props.callbackOnWhatIsDoneWithAttachments != undefined) {
      props.callbackOnWhatIsDoneWithAttachments('canceled-bulk-attachments');
    }
    if (props.setModalIndex) {
      props.setModalIndex(0);
    }
  };

  const handleFileDelete = async () => {
    console.log(fileData);
    await dispatch(deleteAttachmentFromQueue({ ...fileData }));
    setFileData(initialState);
  };

  const askForDeleteConfirmation = () => {
    setOpenDeleteModal(true);
  };

  // console.log('attachment queue ------------', attachmentQueue, props);

  const handleInstantUpload = (e: any) => {
    handleFile(e);
  };

  // dragged files are handled here
  const attchmentDropHandler = (event: any) => {
    event.preventDefault();
    // console.log(event.dataTransfer.files);
    handleBulkFiles(event.dataTransfer.files);
  };

  const attachmentDragoverHandler = (event: any) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
    // console.log('dragover');
  };

  return (
    <Box key={Date.now()} className={Styles.Attachment__container}>
      {props.variant != undefined && props.variant === 'bulk-upload' ? (
        <div className={Styles.Attachment__bulkUploadContainer}>
          <Typography variant="h2">Upload Attachment</Typography>

          {/* dropable div */}
          <div
            className={Styles.Attachment__dropzone}
            onDrop={(event) => attchmentDropHandler(event)}
            onDragOver={(event) => attachmentDragoverHandler(event)}
          >
            <UploadIcon />
            <Typography style={{ width: '170px', marginTop: '12px' }} variant="subtitle1">
              Drag and drop to upload or &nbsp;
              <label htmlFor="contained-button-file">
                <input
                  onChange={(e) => handleBulkFiles(e.target.files)}
                  style={{ display: 'none' }}
                  accept="image/*, .doc, .docx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, audio/*, video/*, application/vnd.ms-excel, application/vnd.ms-powerpoint, application/pdf, text/plain, .xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf, .pages, .ppsx"
                  id="contained-button-file"
                  multiple={props?.acceptFile === 'single' ? false : true}
                  type="file"
                />
                <Typography
                  onClick={() => console.log('s')}
                  style={{ display: 'inline-block' }}
                  variant="subtitle2"
                  className="link-text cognisaas-pointer"
                >
                  browse
                </Typography>
              </label>
            </Typography>
          </div>
          <Typography variant="subtitle2" className="label-text">
            {totalNumberOfAttachments} files selected
          </Typography>

          {/* action buttons */}
          <div className={Styles.Attachment__actionButtonsForDroppedFiles}>
            <Button onClick={cancelUpload} sx={{ marginRight: '20px' }} variant="outlined">
              Cancel
            </Button>

            {isSaving ? (
              <LoadingButton loading loadingPosition="start" startIcon={<SaveIcon />} variant="outlined">
                Save
              </LoadingButton>
            ) : (
              <Button
                onClick={uploadAttachments}
                disabled={totalNumberOfAttachments > 0 ? false : true}
                variant="contained"
              >
                Save
              </Button>
            )}
          </div>
        </div>
      ) : // normal upload system
      attachmentQueue.filter((eachAttachment) => eachAttachment.id === props.metaDataForHeaders.field_uid).length ===
        0 ? (
        // fileData.file.name === '' ? (
        <>
          <Box>
            {/* pormpt attachment using icon */}
            <label htmlFor={`contained-button-file-${props.metaDataForHeaders.field_uid}`}>
              <input
                onChange={(e) => handleInstantUpload(e)}
                style={{ display: 'none' }}
                accept="*"
                id={`contained-button-file-${props.metaDataForHeaders.field_uid}`}
                type="file"
              />
              <IconButton
                sx={props.error ? { border: '1px solid red' } : {}}
                color="primary"
                aria-label="upload picture"
                component="span"
              >
                <AttachIcon />
              </IconButton>
            </label>
          </Box>
        </>
      ) : (
        <Box className={Styles.Attachment__fileViewer}>
          <Box
            sx={props.type === 'small' ? { padding: '0px', transform: 'scale(0.95,0.9)' } : {}}
            className={Styles.Attachment__uploadedFileDetails}
          >
            {/* show the uploaded file */}
            <Box component="span" sx={props.type === 'small' ? { marginRight: '10px' } : { marginRight: '16px' }}>
              {/* show file icon here */}
              <FileIcon
                fileType={
                  attachmentQueue
                    .filter((eachAttachment) => eachAttachment.id === props.metaDataForHeaders.field_uid)[0]
                    .file.name.split('.')[1]
                }
              />
            </Box>

            <Box
              component="span"
              sx={props.type === 'small' ? { marginRight: '10px' } : {}}
              className={Styles.Attachment__uploadedFileDetails_text}
            >
              {/* show file data like attachment name and date */}
              {/* file name */}
              <CognisaaasTooltip title={fileData.file.name}>
                <Typography variant="subtitle1" className={Styles.Attachment__fileName}>
                  {attachmentQueue.filter(
                    (eachAttachment) => eachAttachment.id === props.metaDataForHeaders.field_uid
                  )[0].file.name.length > 13 ? (
                    <>
                      {attachmentQueue
                        .filter((eachAttachment) => eachAttachment.id === props.metaDataForHeaders.field_uid)[0]
                        .file.name.slice(0, 13) + '...'}
                    </>
                  ) : (
                    attachmentQueue.filter(
                      (eachAttachment) => eachAttachment.id === props.metaDataForHeaders.field_uid
                    )[0].file.name
                  )}
                </Typography>
              </CognisaaasTooltip>
              {props.type !== 'small' ? (
                <Typography variant="subtitle2" className={Styles.Attachment__fileDate}>
                  {(() => {
                    const date = new Date();
                    return `${date.getDate()} ${monthNames[date.getMonth()]} ${date.getFullYear()}`;
                  })()}
                </Typography>
              ) : (
                ''
              )}
            </Box>

            <Box component="span">
              {/* delete attachment button */}
              <IconButton onClick={askForDeleteConfirmation}>
                <DeleteIcon />
              </IconButton>
            </Box>
          </Box>
        </Box>
      )}
      <DeleteModal
        isOpen={openDeleteModal}
        setIsOpen={setOpenDeleteModal}
        message={'Are you sure you want to delete this attachment'}
        onDelete={() => {
          handleFileDelete();
        }}
      />
    </Box>
  );
};

export default Attachment;
