import { Button, Grid, Stack, TextField, Typography, Checkbox } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import CircleIcon from '@mui/icons-material/Circle';
import { Formik } from 'formik';
import * as yup from 'yup';

import {
  addCustomFieldProjectByCustomerUid,
  addCustomFieldByCustomerUid,
  addCustomFieldUsecaseByCustomerUid,
  addCustomFieldTaskByCustomerUid,
} from '../../../../store/modules/Settings/slice';
import { useDispatch } from 'react-redux';
import Cookies from 'universal-cookie';
import CognisaasSelect from 'components/shared/CognisaasSelect/CognisaasSelect';
import CognisaaasTooltip from 'components/shared/CognisaasTooltip/CognisaasTooltip';
// import { customFieldSettingsSchemas } from 'utils/validations';

const cookies = new Cookies();

const AddCustomField = (props: any) => {
  const dispatch = useDispatch();
  const [ragStatusCalculationCheck, setRagStatusCalculationCheck] = React.useState(false);
  const [existingCustomFiels, setexistingCustomFiels] = React.useState([]);

  React.useEffect(() => {
    console.log(props);
    if (props?.customerFieldData?.length > 0) {
      const tempArr: any = [];
      props?.customerFieldData?.map((ele: any) => tempArr.push(ele.field_name));
      console.log(tempArr, 'name');
      setexistingCustomFiels(tempArr);
    }
  }, [props]);

  // const userUid = localStorage.getItem('cogni_user_uid');
  const customer_uid = cookies.get('cogni_customer_uid');

  // const getProjectHelper = () => {
  //   dispatch(getCustomFieldProjectRecords_slice(customer_uid ? customer_uid : ''));
  // };

  const handleSubmit = (values: any) => {
    // console.log(values, props, 'values after submit');
    if (props.customFieldFor == 'project') {
      if (values.fieldType == 'RAG status') {
        const projectRAGobj: any = {
          field_name: values.fieldName,
          field_type: values.fieldType,
          customer_uid: customer_uid,
          valid_values: 'Red,Amber,Green,None',
          rag_status_calculation: ragStatusCalculationCheck,
        };
        dispatch(addCustomFieldProjectByCustomerUid(projectRAGobj));
        // setTimeout(getProjectHelper, 1500);
        // props.setShowCustomFields(false);
        return;
      }
      const projectObj: any = {
        field_name: values.fieldName,
        field_type: values.fieldType,
        customer_uid: customer_uid,
        valid_values: values.commaSeparatedValues.trim(),
      };
      dispatch(addCustomFieldProjectByCustomerUid(projectObj));
      // setTimeout(() => dispatch(getCustomFieldProjectRecords_slice(customer_uid ? customer_uid : '')), 1500);
      // props.setShowCustomFields(false);
    }
    if (props.customFieldFor == 'client') {
      if (values.fieldType == 'RAG status') {
        const projectRAGobj: any = {
          field_name: values.fieldName,
          field_type: values.fieldType,
          customer_uid: customer_uid,
          valid_values: 'Red,Amber,Green,None',
          rag_status_calculation: ragStatusCalculationCheck,
        };
        dispatch(addCustomFieldByCustomerUid(projectRAGobj));
        // setTimeout(() => dispatch(getCustomFieldClientRecords_slice(customer_uid ? customer_uid : '')), 1500);
        // props.setShowCustomFields(false);
        return;
      }
      const projectObj: any = {
        field_name: values.fieldName,
        field_type: values.fieldType,
        customer_uid: customer_uid,
        valid_values: values.commaSeparatedValues,
      };
      dispatch(addCustomFieldByCustomerUid(projectObj));
      // setTimeout(() => dispatch(getCustomFieldClientRecords_slice(customer_uid ? customer_uid : '')), 1500);
      // props.setShowCustomFields(false);
    }
    if (props.customFieldFor == 'usecase') {
      if (values.fieldType == 'RAG status') {
        const projectRAGobj: any = {
          field_name: values.fieldName,
          field_type: values.fieldType,
          customer_uid: customer_uid,
          valid_values: 'Red,Amber,Green,None',
          rag_status_calculation: ragStatusCalculationCheck,
        };
        dispatch(addCustomFieldUsecaseByCustomerUid(projectRAGobj));
        // setTimeout(() => dispatch(getCustomFieldUsecaseRecords_slice(customer_uid ? customer_uid : '')), 1500);
        // props.setShowCustomFields(false);
        return;
      }
      const projectObj: any = {
        field_name: values.fieldName,
        field_type: values.fieldType,
        customer_uid: customer_uid,
        valid_values: values.commaSeparatedValues,
      };
      dispatch(addCustomFieldUsecaseByCustomerUid(projectObj));
      // setTimeout(() => dispatch(getCustomFieldUsecaseRecords_slice(customer_uid ? customer_uid : '')), 1500);
      // props.setShowCustomFields(false);
    }
    if (props.customFieldFor == 'tasks') {
      if (values.fieldType == 'RAG status') {
        const projectRAGobj: any = {
          field_name: values.fieldName,
          field_type: values.fieldType,
          customer_uid: customer_uid,
          valid_values: 'Red,Amber,Green,None',
          rag_status_calculation: ragStatusCalculationCheck,
        };
        dispatch(addCustomFieldTaskByCustomerUid(projectRAGobj));
        // setTimeout(() => dispatch(getCustomFieldTaskRecords_slice(customer_uid ? customer_uid : '')), 1500);
        // props.setShowCustomFields(false);
        return;
      }
      const projectObj: any = {
        field_name: values.fieldName,
        field_type: values.fieldType,
        customer_uid: customer_uid,
        valid_values: values.commaSeparatedValues,
      };
      dispatch(addCustomFieldTaskByCustomerUid(projectObj));
      // setTimeout(() => dispatch(getCustomFieldTaskRecords_slice(customer_uid ? customer_uid : '')), 1500);
      // props.setShowCustomFields(false);
    }
  };

  const customFieldSettingsSchemas = yup.object({
    fieldName: yup
      .string()
      .required('Field Name is Required')
      // eslint-disable-next-line quotes
      .notOneOf(existingCustomFiels, `Field Name already exists`),
    fieldType: yup.string(),
    commaSeparatedValues: yup.string().when('fieldType', {
      is: (fieldType: any) => fieldType === 'Dropdown',
      then: yup.string().required('Field value is required'),
    }),
  });

  const validOptions = ['String', 'Number', 'Date', 'Dropdown', 'Hyperlink', 'RAG status', 'User List'];
  return (
    <Box>
      {/* <Stack direction="row"> */}
      <Formik
        initialValues={{
          fieldName: '',
          fieldType: 'String',
          commaSeparatedValues: '',
          rag_status_calculation: false,
        }}
        onSubmit={(values: any) => {
          console.log(values, 'values');
          handleSubmit(values);
        }}
        validationSchema={customFieldSettingsSchemas}
      >
        {(formik: any) => (
          <form onSubmit={formik.handleSubmit}>
            {console.log({ formik })}
            <Stack direction="row" spacing={2}>
              <Box>
                <Typography>Field Name</Typography>
                <TextField
                  size="small"
                  id="fieldName"
                  name="fieldName"
                  value={formik.values.fieldName}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  sx={{ width: '180px', mt: '4px' }}
                  error={formik.touched.fieldName && Boolean(formik.errors.fieldName)}
                  helperText={formik.touched.fieldName && formik.errors.fieldName}
                />
              </Box>
              <Box>
                <Typography>Field Type</Typography>
                <CognisaasSelect
                  id="fieldType"
                  name="fieldType"
                  width="180px"
                  sx={{ mt: '4px' }}
                  onChange={(e: any) => {
                    // console.log(e.target.value);
                    formik.setFieldValue('fieldType', e.target.value);
                  }}
                  value={formik.values.fieldType}
                  validValues={validOptions}
                  onBlur={formik.handleBlur}
                  // error={formik.touched.segment && Boolean(formik.errors.segment)}
                  // helperText={formik.touched.segment && formik.errors.segment}
                />
              </Box>

              <Box>
                {formik.values.fieldType == 'Dropdown' && (
                  <Box>
                    <Typography sx={{ width: '200px' }}>Comma separated values</Typography>
                    <TextField
                      size="small"
                      id="commaSeparatedValues"
                      name="commaSeparatedValues"
                      value={formik.values.commaSeparatedValues}
                      onBlur={formik.handleBlur}
                      onChange={(e) => {
                        const regex = new RegExp('^[a-zA-Z0-9,]');
                        if (regex.test(e.target.value)) {
                          // do whatever you want
                          // setCommaSeparatedValues(e.target.value);
                          formik.setFieldValue('commaSeparatedValues', e.target.value);
                        }
                      }}
                      sx={{ width: '180px', mt: '4px' }}
                      error={formik.touched.commaSeparatedValues && Boolean(formik.errors.commaSeparatedValues)}
                      helperText={formik.touched.commaSeparatedValues && formik.errors.commaSeparatedValues}
                    />
                  </Box>
                )}
              </Box>
              <Box>
                {formik.values.fieldType == 'RAG status' && (
                  <>
                    <Box sx={{ ml: '5px' }}>
                      <Typography sx={{ ml: '10px' }}>RAG status</Typography>
                      <Stack direction="row" sx={{ mt: '10px' }}>
                        <CircleIcon
                          fontSize="small"
                          sx={{ width: '12px', color: '#E75B5C', marginLeft: '4px', mt: '4px' }}
                        />
                        <Typography sx={{ ml: '4px' }}>Red</Typography>
                        <CircleIcon
                          fontSize="small"
                          sx={{ width: '12px', color: '#EFA958', marginLeft: '4px', mt: '4px', ml: '10px' }}
                        />
                        <Typography sx={{ ml: '4px' }}>Amber</Typography>
                        <CircleIcon
                          fontSize="small"
                          sx={{ width: '12px', color: '#4F9F52', marginLeft: '4px', mt: '4px', ml: '10px' }}
                        />
                        <Typography sx={{ ml: '4px' }}>Green</Typography>
                      </Stack>
                    </Box>
                  </>
                )}
              </Box>
              {formik.values.fieldType == 'RAG status' && (
                <Box>
                  <Typography sx={{ width: '180px', ml: '5px' }}>RAG Status Calculation</Typography>
                  <CognisaaasTooltip
                    title={
                      <Typography style={{ padding: '10px' }} variant={'subtitle1'}>
                        Include this field in status calculation
                      </Typography>
                    }
                  >
                    <Checkbox
                      checked={ragStatusCalculationCheck}
                      onClick={() => setRagStatusCalculationCheck(!ragStatusCalculationCheck)}
                      sx={{ ml: '5px', mt: '12px' }}
                    />
                  </CognisaaasTooltip>
                </Box>
              )}
              {/* <button type="submit">Submit</button> */}
              <Grid container justifyContent="flex-end">
                <Button
                  disabled={formik.values.fieldName == '' ? true : false}
                  variant="contained"
                  sx={{ height: '30px', mt: '30px' }}
                  onClick={formik.handleSubmit}
                  type="submit"
                >
                  Save
                </Button>
              </Grid>
            </Stack>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default AddCustomField;
