// do not use this chip in normal flow this is specially designed for filters.
import * as React from 'react';
import { Box, Chip, InputAdornment, ListSubheader, MenuItem, Skeleton, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import './FilterChip.css';
import { IconButton } from '@mui/material';

// icons
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import CognisaasMenuList from '../CognisaasMenu/CognisaasMenuList';
import CognisaasCheckBox from '../CognisaasCheckbox/CognisaasCheckbox';
import { getStatus, isNull } from 'utils/dataHelpers';
import DateRangePicker from '../DateRangePickerMui/DateRangePicker';
import { DateRange } from '@mui/lab';
import DateRangePickerIndependent from '../DateRangePickerMui/DateRangePickerIndepedent';
import NumberRangeChip from './NumberRangeChip';
import TextSearchChip from './TextSearchChip';
import SearchIcon from '@mui/icons-material/Search';
import useCustomNames from '../CustomNames/useCustomNames';

type FilterChipProps = {
  name: string;
  dataKey: string;
  options: string[];
  checkedItemsForList?: string[];
  handleOptionClicked: (value: string, filterName: string, filterDataKey: string) => void;
  handleDeleteFilter: (filter: any) => void;
  canBeCleared: boolean;
};

const filterNameBoxStyle = { overflow: 'hidden', textOverflow: 'ellipsis', width: '200px' };
const containsText = (text: string, searchText: string) => text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

const FilterChip = (props: FilterChipProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [dateRangeValue, setDateRangeValue] = React.useState<DateRange<Date>>([null, null]);
  const [searchText, setSearchText] = React.useState<string>('');

  const displayedOptions = React.useMemo(
    () => props.options.filter((option: string) => containsText(option, searchText)),
    [searchText, props.options]
  );
  const { getCustomNameMappedValue } = useCustomNames();

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const { options } = props;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const statusColors = ['red', 'amber', 'green'];

  const brandBlue = '#2D60F6';
  const useStyles = makeStyles({
    chipRoot: {
      '& .MuiChip-icon': {
        order: 1,
        marginRight: '10px',
        cursor: 'pointer',
      },
      '& .MuiChip-deleteIcon': {
        order: 2,
      },
      '&:hover': {
        backgroundColor: '#cedafd',
      },
    },
  });

  const classes = useStyles();

  React.useEffect(() => {
    if (!isNull(dateRangeValue[0]) && !isNull(dateRangeValue[1])) {
      props.handleOptionClicked(
        dateRangeValue[0]?.toString() + '$$' + dateRangeValue[1]?.toString(),
        props.name,
        props.dataKey
      );
    }
  }, [dateRangeValue]);

  const checkedValues = props.checkedItemsForList || [];

  const deleteFnObj = {
    ...(props.canBeCleared
      ? {
          onDelete: () => {
            props.handleDeleteFilter({ name: props.name, availableOptions: props.options, dataKey: props.dataKey });
          },
        }
      : {}),
  };

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
      {props.name.split('$$')[1] === 'date-range-picker' ? (
        <>
          <DateRangePicker
            name={props.name.split('$$')[0]}
            handleDelete={() => {
              props.handleDeleteFilter({ name: props.name, availableOptions: props.options, dataKey: props.dataKey });
            }}
            value={dateRangeValue}
            setValue={setDateRangeValue}
          />
        </>
      ) : props.name.split('$$')[1] === 'number-range' ? (
        <>
          <NumberRangeChip
            name={props.name.split('$$')[0]}
            value={props.checkedItemsForList}
            handleChange={(newVal: any[]) => {
              props.handleOptionClicked(
                newVal[0]?.toString() + '$N$' + newVal[1]?.toString(),
                props.name,
                props.dataKey
              );
            }}
            handleDelete={() => {
              props.handleDeleteFilter({ name: props.name, availableOptions: props.options, dataKey: props.dataKey });
            }}
          />
        </>
      ) : props.name.split('$$')[1] === 'text-contains' ? (
        <TextSearchChip
          name={props.name.split('$$')[0]}
          value={props.checkedItemsForList}
          handleChange={(newVal: any) => {
            props.handleOptionClicked(newVal + '$C$', props.name, props.dataKey);
          }}
          handleDelete={() => {
            props.handleDeleteFilter({ name: props.name, availableOptions: props.options, dataKey: props.dataKey });
          }}
        />
      ) : (
        <>
          <CognisaasMenuList open={open} anchorEl={anchorEl} handleDropDownClose={handleClose}>
            {options.length === 0 && (
              <>
                <Skeleton variant="rectangular" width={210} height={27} sx={{ margin: '4px' }} />
                <Skeleton variant="rectangular" width={210} height={27} sx={{ margin: '4px' }} />
              </>
            )}

            {options.length > 6 && (
              <ListSubheader>
                <TextField
                  size="small"
                  autoFocus
                  placeholder="Type to search..."
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => setSearchText(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key !== 'Escape') {
                      // Prevents autoselecting item while typing (default Select behaviour)
                      e.stopPropagation();
                    }
                  }}
                />
              </ListSubheader>
            )}
            {displayedOptions.map((option, index) => {
              return (
                <MenuItem
                  key={index}
                  dense={true}
                  onClick={() => {
                    props.handleOptionClicked(option, props.name, props.dataKey);
                  }}
                >
                  <CognisaasCheckBox
                    checked={props.checkedItemsForList?.indexOf(option) === -1 ? false : true}
                    value={option}
                    onCheckBoxClick={() => {
                      console.log('nhjhj');
                    }}
                    sx={{
                      marginRight: '8px',
                    }}
                  />
                  <Box sx={filterNameBoxStyle}>
                    {getCustomNameMappedValue(
                      props.name.toLowerCase().trim() === 'status' ? getStatus(option) : option,
                      props.dataKey
                    )}
                  </Box>
                </MenuItem>
              );
            })}
            {/* menu item for embedded custom date range picker */}
            {props.name.split('$$')[1] === 'embedded-date-range-picker' && (
              <MenuItem key={Date.now()} dense={true}>
                <DateRangePickerIndependent
                  name={props.name.split('$$')[0]}
                  handleDelete={() => {
                    props.handleDeleteFilter({
                      name: props.name,
                      availableOptions: props.options,
                      dataKey: props.dataKey,
                    });
                  }}
                  value={dateRangeValue}
                  setValue={setDateRangeValue}
                />
              </MenuItem>
            )}
          </CognisaasMenuList>

          {
            <Chip
              classes={{ root: classes.chipRoot }}
              label={
                <>
                  <Typography variant="h3" sx={{ color: brandBlue }}>
                    {props.name.split('$$')[0]}
                  </Typography>{' '}
                  <input hidden accept="image/*" multiple type="file" />
                </>
              }
              onClick={(e: any) => handleClickListItem(e)}
              icon={
                <IconButton disableRipple style={{ marginRight: '0px', color: brandBlue }}>
                  <Typography variant="h3" sx={{ color: brandBlue }}>
                    {checkedValues.length > 0 && checkedValues[0].split('$$').length == 2 ? (
                      'Date Range'
                    ) : checkedValues.length > 1 && checkedValues[0].split('$$').length == 2 ? (
                      'Date Range...'
                    ) : (
                      <>
                        {checkedValues.length > 1
                          ? statusColors.includes(checkedValues[0])
                            ? getCustomNameMappedValue(getStatus(checkedValues[0]), props.dataKey)
                            : getCustomNameMappedValue(checkedValues[0], props.dataKey) + '...'
                          : checkedValues.length === 1
                          ? statusColors.includes(checkedValues[0])
                            ? getCustomNameMappedValue(getStatus(checkedValues[0]), props.dataKey)
                            : getCustomNameMappedValue(checkedValues[0], props.dataKey)
                          : ''}
                      </>
                    )}
                  </Typography>
                  {anchorEl ? <KeyboardArrowUp /> : <KeyboardArrowDownIcon />}
                </IconButton>
              }
              {...deleteFnObj}
              sx={{ background: '#cedafd' }}
            ></Chip>
          }
        </>
      )}
    </Box>
  );
};

export default FilterChip;
