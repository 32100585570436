import React, { useState } from 'react';
import './manageclient.scss';

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { requestState } from '../../store/modules/types';
import { useAppSelector } from '../../store/hooks';
import {
  updateClientListTableview,
  updateOpenClientListCell,
  fetchCRMClientList,
} from '../../store/modules/ManageClient/ClientList/slice';
import { selectClientList, selectclientListTableView } from '../../store/modules/ManageClient/ClientList/selector';
import getSymbolFromCurrency from 'currency-symbol-map';
import CognisaasLoader from '../shared/CognisaasLoader/CognisaasLoader';
import { Box } from '@mui/system';
import { Grid, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import StatusBadge from '../shared/LabeledBadge/LabeledBadge';
import ButtonGroup from '@mui/material/ButtonGroup';
import CognisaasTabs from '../../components/shared/CognisaasTabs';
import ExcelFeature from './ExcelFeature/ExcelFeature';
import CognisaasClientCard from '../shared/CognisaasCard/CognisaasClientCard/CognisaasClientCard';
import BasicModal from '../shared/CognisaasModal/CognisaasModal';
import CustomizeColumns from './CustomizeColumns/CustomizeColumns';
import { ReactComponent as NoRecords } from '../../assets/svgs/no_records.svg';
import { ReactComponent as MCIcon1 } from '../../assets/svgs/manageclientIcon1.svg';
import { ReactComponent as MCIcon2 } from '../../assets/svgs/manageclientIcon2.svg';
import { selectedFiltersInManageClientspage, selectRawStageData } from '../../store/modules/ui/Filters/selector';

import { Link, useLocation } from 'react-router-dom';
import ClientListTable from './ClientListTable';
import FilterTypeSelector from 'components/shared/CognisaasFilters/FilterTypeSelector/FilterTypeSelector';
import SelectedFilterList from 'components/shared/CognisaasFilters/SelectedFilterList/SelectedFilterList';
import { selectAllFilters } from 'store/modules/AllFilters/selector';
import { formatToCurrencySymbol } from 'utils/dataHelpers';
import {
  selectAllGeneralStatus,
  selectCustomClientJourneyStage,
  selectCustomerCfs,
  selectJourneyStage,
  selectSegmentValues,
  selectUsersOfCompany,
} from 'store/modules/Common/selector';
import {
  fetchAllCustomJourneyStages,
  fetchAllIndustries,
  fetchAllJourneyStages,
  fetchCustomerLevelCustomFields,
  getAllSegmentValues,
  getAllUsers,
} from 'store/modules/Common/slice';
import Cookies from 'universal-cookie';
import {
  label_arr,
  label_client,
  label_cs_owner,
  label_sales_owner,
} from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { selectCustomerDetails } from 'store/modules/Settings/selector';
import InfoChipWithColor from 'components/shared/DataDisplay/InfoChipWithColor';
import usePageAccess from 'components/shared/Hooks/usePageAccess';
import CognisaasToast from 'components/shared/CognisaasToast/CognisaasToast';
import useClientList from 'components/shared/Hooks/useUserDetail';
import {
  getClientIndustries,
  getClientList,
  getClientSegments,
  getClientStages,
} from 'store/modules/ManageClient/ClientList-new/slice';
import { newSelectClientList, selectClientStatusCount } from 'store/modules/ManageClient/ClientList-new/selector';

interface LocationParams {
  pathname: string;
  state: any;
  search: string;
  hash: string;
  key: string;
}

const ManageClient: React.FC = () => {
  const cookies = new Cookies();
  const allClients = useClientList();
  // const [filters] = useState([]);
  const [columnSelectionModal, setcolumnSelectionModal] = useState<boolean>(false);
  const dispatch = useDispatch();
  const userHasAccessTo = usePageAccess();

  const clientList = useAppSelector(newSelectClientList).data;
  const status = useAppSelector(newSelectClientList).status;
  const totalClients = useAppSelector(newSelectClientList).count;
  const clientStatusCount = useAppSelector(selectClientStatusCount);
  const clientListTableView = useAppSelector(selectclientListTableView);
  const [thisStatusIsActive, setThisStatusIsActive] = useState<string>('All');
  const [tabIndex, setTabIndex] = useState(0);
  const [showOnboardNewAccount, setShowOnboardNewAccount] = React.useState(false);
  const [paginationModel, setPaginationModel] = useState<{ page: number; pageSize: number }>({
    page: 0,
    pageSize: 20,
  });
  const customerDetail = useAppSelector(selectCustomerDetails).data;
  const location: LocationParams = useLocation();

  // new filters data
  const allFilters = useAppSelector(selectAllFilters);
  const [tablecolumns, setTablecolumns] = useState<any[]>([]);
  const [clientStatusCounts, setClientStatusCounts] = useState<{
    all: number;
    overdue: number;
    atRisk: number;
    onTrack: number;
    completed: number;
  }>({ all: 0, overdue: 0, atRisk: 0, onTrack: 0, completed: 0 });
  const [countCameFromChartClick, setCountCameFromChartClick] = useState<number>(-1);
  const [alertDetails, setAlertDetails] = useState<{
    isVisible: boolean;
    type: 'success' | 'failure';
    message: string;
  }>({ isVisible: false, type: 'success', message: '' });

  useEffect(() => {
    if (!allClients) {
      setShowOnboardNewAccount(false);
    } else if (Array.isArray(allClients) && clientList?.length <= 0) setShowOnboardNewAccount(true);
    else setShowOnboardNewAccount(false);
  }, [clientList]);

  useEffect(() => {
    dispatch(getClientIndustries());
    dispatch(getClientStages());
    dispatch(getClientSegments());
  }, []);
  useEffect(() => {
    dispatch(getClientList(paginationModel));
  }, [paginationModel]);

  const updateClientData = (
    isCustomField: boolean,
    newValue: string | number,
    rowIndex: number,
    columnKeyToBeUpdated: string,
    client_uid: string
  ) => {
    dispatch(updateOpenClientListCell({ newValue, rowIndex, columnKeyToBeUpdated, client_uid }));
  };


  const switchBetweenOnboardedAndDraftClients = (event: any, tabIndex: number) => {
    setTabIndex(tabIndex);
  };

  const tabs = [`Onboarded ${label_client}s`, 'Drafts', 'Churned'];

  return showOnboardNewAccount === true ? (
    <Stack style={{ marginTop: '32px' }} justifyContent={'center'} alignItems={'center'}>
      <Typography variant="h5">Nothing to show!</Typography>
      <NoRecords style={{ width: '300px', height: '300px' }} />
      {cookies.get('user_type') === 'S' && (
        <Link to="/onboard-new-account">
          <Button disableElevation variant="contained" color="primary">
            Onboard New Client
          </Button>
        </Link>
      )}
    </Stack>
  ) : (
    <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
      {/* {status === requestState.loading && (
        <div>
          <CognisaasLoader />
        </div>
      )}
      {status === requestState.failed && <div>Failed</div>} */}
      {
        <React.Fragment>
          {/* top section */}
          <div className="ManageClient__topSection">
            <Typography component="span" sx={{ fontWeight: '500' }} variant="h1">
              {label_client} List
            </Typography>
            {userHasAccessTo.includes('/clients/create') && (
              <Link to="/onboard-new-account">
                <Button disableElevation variant="contained" color="primary" sx={{ mt: '10px' }}>
                  New {label_client}
                </Button>
              </Link>
            )}
          </div>

          {/* filters section */}
          <div className="ManageClient__filtersSection">
            {/* draft or onboarded client switchable tabs */}
            <Box component="span" sx={{ display: 'flex' }}>
              <CognisaasTabs handleTabSwitch={switchBetweenOnboardedAndDraftClients} tabs={tabs} />
            </Box>

            {/* search and filters */}
            <div className="ManageClient__searchFiltersContainer">
              {/* filters */}
              <span>
                <ExcelFeature
                  data={clientList}
                  columnNameMapping={[
                    {
                      dataKey: 'client_name',
                      columnName: `${label_client} Name`,
                    },
                    {
                      dataKey: 'client_account_owner',
                      columnName: label_cs_owner,
                    },
                    {
                      dataKey: 'client_sales_owner',
                      columnName: label_sales_owner,
                    },
                    {
                      dataKey: 'segment_name',
                      columnName: 'Segment',
                    },
                    {
                      dataKey: 'industry_name',
                      columnName: 'Industry',
                    },
                    {
                      dataKey: 'stage_journerystage',
                      columnName: 'Stage',
                    },
                    {
                      dataKey: 'client_readiness_status',
                      columnName: `${label_client} Status`,
                    },
                    {
                      dataKey: 'client_account_worth',
                      columnName: `${label_arr} (${getSymbolFromCurrency(customerDetail?.currency_code)})`,
                    },
                    {
                      dataKey: 'last_status_report_sent_time',
                      columnName: 'Last Status Email Sent',
                    },
                    {
                      dataKey: 'client_comment',
                      columnName: 'Comment',
                    },
                  ]}
                  customFieldKey={'custom_values'}
                />
              </span>
              <span>
                {/* <ColumnFilter /> */}
                <FilterTypeSelector idName="ManageClientListHomePage" />
              </span>
              <span>
                {/* className={`${tableView ? 'active-btn' : null}`} */}
                <ButtonGroup sx={{ backgroundColor: 'white' }} variant="outlined" aria-label="outlined button group">
                  <Button
                    className={`${!clientListTableView ? 'active-btn' : null} `}
                    onClick={() => dispatch(updateClientListTableview(false))}
                  >
                    <MCIcon1 />
                  </Button>
                  <Button
                    className={`${clientListTableView ? 'active-btn' : null} `}
                    onClick={() => dispatch(updateClientListTableview(true))}
                  >
                    <MCIcon2 />
                  </Button>
                </ButtonGroup>
              </span>
            </div>
          </div>

          {/* status tab */}
          <Box sx={{ mt: 1 }}>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
              <Stack sx={{ mb: '8px' }}>
                <ButtonGroup
                  variant="outlined"
                  aria-label="outlined button group"
                  sx={{
                    display: 'inline-flex',
                    flexWrap: 'wrap',
                    backgroundColor: 'white',
                    border: '1px solid #E5E5E5',
                    borderRadius: '4px',
                  }}
                >
                  <Button
                    variant="text"
                    sx={thisStatusIsActive === 'All' ? { background: 'rgba(45, 96, 246,0.15)' } : {}}
                    onClick={() => setThisStatusIsActive('All')}
                  >
                    <StatusBadge
                      hideWidth={true}
                      statusColor={'blue'}
                      text="All"
                      badgeCount={clientStatusCount.all}
                      isActive={thisStatusIsActive === 'All'}
                    />
                  </Button>
                  <Button
                    variant="text"
                    sx={thisStatusIsActive === 'Overdue' ? { background: 'rgba(45, 96, 246,0.15)' } : {}}
                    onClick={() => setThisStatusIsActive('Overdue')}
                  >
                    <StatusBadge
                      statusColor="#E75B5C"
                      text="Overdue"
                      hideWidth={true}
                      badgeCount={clientStatusCount.overdue.length}
                      isActive={thisStatusIsActive === 'Overdue'}
                    />
                  </Button>
                  <Button
                    variant="text"
                    sx={thisStatusIsActive === 'At Risk' ? { background: 'rgba(45, 96, 246,0.15)' } : {}}
                    onClick={() => setThisStatusIsActive('At Risk')}
                  >
                    <StatusBadge
                      statusColor="#EFA958"
                      text="At Risk"
                      hideWidth={true}
                      badgeCount={clientStatusCount.atRisk.length}
                      isActive={thisStatusIsActive === 'At Risk'}
                    />
                  </Button>
                  <Button
                    variant="text"
                    sx={thisStatusIsActive === 'On Track' ? { background: 'rgba(45, 96, 246,0.15)' } : {}}
                    onClick={() => setThisStatusIsActive('On Track')}
                  >
                    <StatusBadge
                      statusColor="#4F9F52"
                      text="On Track"
                      hideWidth={true}
                      badgeCount={clientStatusCount.onTrack.length}
                      isActive={thisStatusIsActive === 'On Track'}
                    />
                  </Button>
                  <Button
                    variant="text"
                    sx={thisStatusIsActive === 'Completed' ? { background: 'rgba(45, 96, 246,0.15)' } : {}}
                    onClick={() => setThisStatusIsActive('Completed')}
                  >
                    <StatusBadge
                      statusColor="#4390e1"
                      text="Completed"
                      hideWidth={true}
                      badgeCount={clientStatusCount.completed.length}
                      isActive={thisStatusIsActive === 'Completed'}
                    />
                  </Button>
                </ButtonGroup>
              </Stack>
              <Stack sx={{ marginLeft: '16px' }}>
                {customerDetail?.arr_on_client_list_visible === '1' && (
                  <InfoChipWithColor
                    header={`Total ${label_arr}`}
                    value={formatToCurrencySymbol(
                      clientList
                        .map((item: any) => parseInt(item.client_account_worth))
                        .reduce((partialSum: number, a: number) => partialSum + a, 0),
                      customerDetail?.currency_code
                    )}
                    color={'#2d60f6'}
                  />
                )}
              </Stack>
            </Stack>
            <Box sx={{ margin: '8px 0px' }}>
              {allFilters['ManageClientListHomePage']?.appliedFilters.filter((item) => item.isSelected).length > 0 && (
                <SelectedFilterList idName="ManageClientListHomePage" isResetEnabled={true} />
              )}
            </Box>
          </Box>

          {clientListTableView ? (
            <>
              {clientList.length > 0 ? (
                <ClientListTable
                  updateMyData={updateClientData}
                  data={clientList}
                  paginationModel={paginationModel}
                  setPaginationModel={setPaginationModel}
                  count={totalClients}
                  status={status}
                />
              ) : (
                <></>
              )}
            </>
          ) : (
            <div style={{ padding: '20px 0px' }}>
              <Grid container rowSpacing={2} columnSpacing={1} spacing={2}>
                {clientList.map((item: any, index: any) => {
                  return (
                    <Grid justifyContent={'center'} item key={index}>
                      <CognisaasClientCard key={index} data={item}></CognisaasClientCard>
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          )}
          {columnSelectionModal && (
            <BasicModal open={true} onClose={() => setcolumnSelectionModal(!columnSelectionModal)}>
              <CustomizeColumns />
            </BasicModal>
          )}
        </React.Fragment>
      }
      <CognisaasToast
        message={alertDetails.message}
        type={alertDetails.type}
        open={alertDetails.isVisible}
        onClose={() => {
          setAlertDetails({ isVisible: false, type: alertDetails.type, message: '' });
        }}
      />
    </div>
  );
};

export default ManageClient;
