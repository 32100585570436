import { useEffect, useState } from 'react';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { GridRenderCellParams, GridToolbar } from '@mui/x-data-grid';
import { DataGridPremium, GridColumnOrderChangeParams } from '@mui/x-data-grid-premium';
import { label_client } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import ViewDate from 'components/shared/ViewDate/ViewDate';
import { Link } from 'react-router-dom';
import { ReactComponent as NoRecords } from '../../../assets/svgs/no_records.svg';
import ReportsHeader, { ReportsPages } from '../ReportsHeader/ReportsHeader';
import { excelExportData } from './helperFunction';
import { useClientContact } from './useClientContact';
import { ReactComponent as Excel } from './../../../assets/svgs/excel.svg';
import FilterTypeSelector from 'components/shared/CognisaasFilters/FilterTypeSelector/FilterTypeSelector';
import SelectedFilterList from 'components/shared/CognisaasFilters/SelectedFilterList/SelectedFilterList';
import { generateOrderedColumn } from 'utils/dataHelpers';
import { uniqueReportClientContactColumnId } from './constants';
import CognisaasLoader from 'components/shared/CognisaasLoader/CognisaasLoader';
import { selectClientContactsOfCompanyStatus } from 'store/modules/Common/selector';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { requestState } from 'store/modules/types';
import { fetchClientList } from 'store/modules/ManageClient/ClientList/slice';
import { selectClientList } from 'store/modules/ManageClient/ClientList/selector';
import checkAccessForRouting from 'components/shared/Hooks/checkAccessForRouting';
import CognisaaasTooltip from 'components/shared/CognisaasTooltip/CognisaasTooltip';
import useCommonAppConfig from 'components/Common/useCommonAppConfig';

export function getAccountNameCell(params: GridRenderCellParams) {
  return (
    <Link to={`/client-list/client/${params.row.client_uid}`}>
      <Typography variant="subtitle1" className="cognisaas-pointer" sx={{ color: '#2D60F6' }}>
        {params.value}
      </Typography>
    </Link>
  );
}
export function getClientNameCell(params: GridRenderCellParams) {
  return (
    <Grid>
      <Typography variant="subtitle1">{params.row.contact_name}</Typography>
    </Grid>
  );
}
export function getMagicLinkSentCell(params: GridRenderCellParams) {
  return <ViewDate date={params.value} />;
}
export function getLastAccessLinkCell(params: GridRenderCellParams) {
  return <ViewDate date={params.value} />;
}
const ClientContactsReport = () => {
  const dispatch = useAppDispatch();
  const clientList = useAppSelector(selectClientList);
  let myTableState: any = {};
  const { saveAppTableState, getTableState } = useCommonAppConfig();
  const initialState: any = {
    columns: {
      columnVisibilityModel: {
        section_uid: false,
      },
    },
    pinnedColumns: {
      left: ['client_name'],
    },
    pagination: {
      page: 0,
      pageSize: 10,
    },
    ...getTableState('clientContactReport'),
  };

  const handleTableSave = (idName: string, thisTableState: any) => {
    saveAppTableState(idName, thisTableState);
  };

  const getClientPageURL = (row: any) => {
    return `/client-list/client/${row.client_uid}`;
  };

  const handleClientAccess = (params: GridRenderCellParams) => {
    const arrayToCheck = clientList.map((ele) => ele.client_uid);
    const hasAccessToClient = checkAccessForRouting({ arrayToCheck: arrayToCheck, uid: params.row.client_uid });
    console.log(params.value);
    if (hasAccessToClient == true) {
      return <Link to={getClientPageURL(params.row)}>{params.value}</Link>;
    }
    if (hasAccessToClient == false) {
      return (
        <CognisaaasTooltip
          title={<Typography sx={{ padding: '5px' }}>You do not have access to this {label_client}</Typography>}
        >
          <Typography variant="subtitle1">{params.value}</Typography>
        </CognisaaasTooltip>
      );
    }
  };

  const handleExcelClick = () => {
    excelExportData(contactsClient, `${label_client} Contacts`);
  };
  const { contactsClient, allFilters } = useClientContact();
  const [tableColumns, setTableColumns] = useState<any[]>([]);
  // const [pageSize, setPageSize] = useState<number>(10);
  const clientContactsState = useAppSelector(selectClientContactsOfCompanyStatus);

  const clientContactsColumns = [
    {
      headerName: `${label_client} Name`,
      field: 'client_name',
      minWidth: 200,
      renderCell: (params: GridRenderCellParams) => handleClientAccess(params),
    },
    {
      field: 'contact_name',
      headerName: 'Member',
      minWidth: 110,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        return getClientNameCell(params);
      },
    },
    {
      field: 'contact_email',
      headerName: 'Email',
      flex: 1,
      minWidth: 260,
    },
    {
      field: 'contact_role',
      headerName: 'Role',
      minWidth: 80,
      flex: 1,
    },
    {
      field: 'primary_contact',
      headerName: 'Contact Type',
      minWidth: 110,
      flex: 1,
    },
    {
      field: 'last_magiclink_sent_on',
      headerName: 'Last access link sent on',
      minWidth: 150,
      flex: 1,
      type: 'date',
      renderCell: (params: GridRenderCellParams) => {
        return getMagicLinkSentCell(params);
      },
    },
    {
      field: 'contact_last_accessed_on',
      headerName: 'Last accessed portal on',
      minWidth: 150,
      flex: 1,
      type: 'date',
      renderCell: (params: any) => {
        return getLastAccessLinkCell(params);
      },
    },
  ];

  useEffect(() => {
    dispatch(fetchClientList());
    setTableColumns(generateOrderedColumn(clientContactsColumns, uniqueReportClientContactColumnId));
  }, []);
  const handleColumnOrderChange = (params: GridColumnOrderChangeParams) => {
    const oldArrayIndex = params.oldIndex;
    const targetArrayIndex = params.targetIndex;
    setTableColumns(
      generateOrderedColumn(tableColumns, uniqueReportClientContactColumnId, oldArrayIndex, targetArrayIndex)
    );
  };

  return (
    <>
      <Box sx={{ display: 'grid', gridTemplateRows: '70px auto', height: '100%' }}>
        <ReportsHeader pageName={ReportsPages.Client_Contacts} />
        <Box mx={1}>
          <Grid container direction="row" justifyContent="flex-end" alignItems="center">
            {contactsClient && contactsClient.length !== 0 && (
              <IconButton onClick={handleExcelClick} color="primary" component="span">
                <Excel />
              </IconButton>
            )}
            <FilterTypeSelector idName="clientContactReport" />
          </Grid>
          {allFilters['clientContactReport']?.appliedFilters.filter((item) => item.isSelected).length > 0 && (
            <SelectedFilterList idName="clientContactReport" isResetEnabled={true} />
          )}
          {clientContactsState != requestState.success && contactsClient.length == 0 ? <CognisaasLoader /> : <></>}
          {clientContactsState == requestState.success && contactsClient && contactsClient.length === 0 ? (
            <Grid container direction="column" justifyContent="center" alignItems="center">
              <Grid item>
                <NoRecords style={{ marginLeft: '6px', marginTop: '10px', width: '250px', height: '250px' }} />
              </Grid>
              <Grid item>
                <Typography variant="h6">No Records Found!</Typography>
              </Grid>
            </Grid>
          ) : (
            <>
              <Grid sx={{ marginTop: '24px' }}>
                <Grid item>
                  <DataGridPremium
                    columns={tableColumns}
                    initialState={initialState}
                    onStateChange={(tblState: any) => {
                      myTableState = tblState;
                    }}
                    onColumnVisibilityModelChange={() => {
                      handleTableSave('clientContactReport', myTableState);
                    }}
                    onFilterModelChange={() => {
                      handleTableSave('clientContactReport', myTableState);
                    }}
                    onPageSizeChange={() => {
                      handleTableSave('clientContactReport', myTableState);
                    }}
                    components={{
                      Toolbar: GridToolbar,
                    }}
                    componentsProps={{
                      toolbar: { showQuickFilter: true },
                    }}
                    rows={contactsClient}
                    getRowId={(row) => row.contact_uid}
                    disableSelectionOnClick
                    autoHeight
                    pagination
                    disableColumnSelector
                    // pageSize={pageSize}
                    rowsPerPageOptions={[5, 10, 20, 50, 100]}
                    // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    onColumnOrderChange={handleColumnOrderChange}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default ClientContactsReport;
