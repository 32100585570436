interface propTypes {
  arrayToCheck: string[];
  uid: string;
}

const checkAccessForRouting = (props: propTypes) => {
  if (props.arrayToCheck.includes(props.uid)) return true;
  else return false;
};

export default checkAccessForRouting;
