import { Button, TextField, Typography, FormControl, Snackbar, Alert, AlertColor } from '@mui/material';
import { Formik, FieldArray } from 'formik';
import { ReactComponent as LeftArrowIcon } from '../../../assets/svgs/leftArrow.svg';
import Styles from './AddNewProduct.module.css';
import CognisaasButton from '../../shared/CognisaasButton/CognisaasButton';
import {
  addNewProductAndRequirementToDb,
  fetchProductAndRequirements,
  resetNewProductAndRequirementSubState,
} from '../../../store/modules/ProductRequirements/ProductDetails/slice';
import { selectNewProductAndReqState } from '../../../store/modules/ProductRequirements/ProductDetails/selector';
import { useAppSelector } from '../../../store/hooks';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import CognisaasRequirementStatusDropdown from '../../shared/CognisaasDropdown/CognisaasRequirementStatusDropdown/CognisaasRequirementStatusDropdown';
import { newProductSchema } from '../../../utils/validations';
import { isNull } from '../../../utils/dataHelpers';
import CognisaasCalenderNew from '../../shared/CognisaasCalenderNew/CognisaasCalenderNew';
import { format } from 'date-fns';

import Cookies from 'universal-cookie';

const cookies = new Cookies();
interface NewProductProps {
  setProdReqView: any;
}

interface EachIndividualRequirementProp {
  requirementName: string;
  etaForRelease: Date;
  requirementStatus: string;
}

const AddNewProduct = (componentProps: NewProductProps) => {
  const addingNewProductAndReqState = useAppSelector(selectNewProductAndReqState);

  const dispatch = useDispatch();
  const [alertDetails, setAlertDetails] = useState({ isVisible: false, severity: 'error', message: '' });

  useEffect(() => {
    if (addingNewProductAndReqState.status === 'success') {
      if (!isNull(addingNewProductAndReqState.error)) {
        setAlertDetails({ isVisible: true, severity: 'error', message: addingNewProductAndReqState.error as string });
      } else {
        setAlertDetails({ isVisible: true, severity: 'success', message: 'new product added' });
        dispatch(fetchProductAndRequirements());
        componentProps.setProdReqView('landing-page');
      }
      dispatch(resetNewProductAndRequirementSubState());
    } else if (addingNewProductAndReqState.status === 'failed') {
      alert(addingNewProductAndReqState.error);
    } else if (addingNewProductAndReqState.status === 'loading') {
      setAlertDetails({ isVisible: true, severity: 'info', message: 'Adding To New Product' });
    }
  }, [addingNewProductAndReqState]);

  // const checkAccountNameAlreadyExists = (accountName: string) => {
  //   // check account name with server
  //   console.log(accountName);
  // };

  // const checkProductNameAlreadyExists = (productName: string) => {
  //   // check account name with server
  //   console.log(productName);
  // };

  const handleCancel = (resetForm: any) => {
    resetForm();
    componentProps.setProdReqView('landing-page');
  };

  const handleBack = () => {
    componentProps.setProdReqView('landing-page');
  };

  // console.log(addingNewProductAndReqState);

  return (
    <div className={Styles.AddNewProduct__container}>
      <Button sx={{ display: 'block' }} onClick={handleBack}>
        Product Requirements
      </Button>
      <Button sx={{ marginBottom: '20px' }} startIcon={<LeftArrowIcon />} onClick={handleBack}>
        <Typography style={{ color: 'black' }} variant="h1">
          &nbsp;New Product
        </Typography>
      </Button>
      <Formik
        initialValues={{
          productName: '',
          accountName: '',
          allRequirements: [
            {
              requirementName: '',
              requirementStatus: 'red',
              etaForRelease: '',
            },
          ],
        }}
        validationSchema={newProductSchema}
        onSubmit={(values: any) => {
          let isOnlyWhiteSpacesAreEntered = false;
          // adding product and requirements to server
          const serverPayload: any = [];

          if (values.productName.trim().length < 1) {
            isOnlyWhiteSpacesAreEntered = true;
          }

          values.allRequirements.forEach(
            ({ requirementName, etaForRelease, requirementStatus }: EachIndividualRequirementProp) => {
              // const etaForReleaseInServerFormat =
              //   etaForRelease instanceof Date ? parseDateObjTo_YYYYMMDD_Format(etaForRelease) : 'NaN-NaN-NaN';

              if (requirementName.trim().length < 1) {
                isOnlyWhiteSpacesAreEntered = true;
              }

              serverPayload.push({
                customer_uid: cookies.get('cogni_customer_uid') as string,
                feature_description: requirementName,
                feature_estimateddeliverydate: requirementStatus == 'amber' ? format(new Date(etaForRelease), 'dd-MM-yyyy') : '',
                feature_readinessstate_status: requirementStatus,
                feature_status_reason: 'This feature is from product requirement module',
                product_name: values.productName,
              });
            }
          );

          if (isOnlyWhiteSpacesAreEntered) {
            setAlertDetails({ isVisible: true, severity: 'error', message: 'fields cant be empty' });
          } else {
            console.log(serverPayload);

            // dispatch action that will call api and add new product and its requirements
            dispatch(addNewProductAndRequirementToDb(serverPayload));
            dispatch(fetchProductAndRequirements());

            // after adding product and requirement to server, map client to product also

            console.log(values);
          }
        }}
      >
        {(props: any) => (
          <form className={Styles.AddNewProduct__bodyForm} onSubmit={props.handleSubmit}>
            <div className={Styles.AddNewProject__formFieldContainer}>
              <div className={Styles.AddNewProject__formSingleField}>
                <Typography variant="subtitle1">Product Name*</Typography>
                <TextField
                  size="small"
                  id="productName"
                  autoFocus
                  name="productName"
                  placeholder="Product Name"
                  onChange={props.handleChange}
                  value={props.values.productName}
                  onBlur={props.handleBlur}
                  error={props.touched.productName && Boolean(props.errors.productName)}
                  helperText={props.touched.productName && props.errors.productName}
                ></TextField>
              </div>
            </div>

            {/* requirement info */}
            <FieldArray name="allRequirements">
              {({ remove, push }) => (
                <div>
                  {props.values.allRequirements.map((_: any, index: number) => (
                    <div className={Styles.AddNewProject__formFieldContainer} key={index}>
                      {/* requirement name */}
                      <div className={Styles.AddNewProject__formSingleField}>
                        <Typography variant="subtitle1">Requirement Name*</Typography>
                        <TextField
                          size="small"
                          name={`allRequirements[${index}].requirementName`}
                          placeholder="Enter Requirement Name"
                          onChange={props.handleChange}
                          value={props.values.allRequirements[index].requirementName}
                          error={
                            props.errors.allRequirements &&
                            props.errors.allRequirements[index] &&
                            props.errors.allRequirements[index].requirementName &&
                            props.touched &&
                            props.touched.allRequirements &&
                            props.touched.allRequirements[index] &&
                            props.touched.allRequirements[index].requirementName
                          }
                          helperText={
                            props.errors.allRequirements &&
                            props.errors.allRequirements[index] &&
                            'requirement Name is Required' &&
                            props.touched &&
                            props.touched.allRequirements &&
                            props.touched.allRequirements[index] &&
                            props.touched.allRequirements[index].requirementName &&
                            'requirement Name is Required'
                          }
                        ></TextField>
                      </div>

                      {/* requirement status */}
                      <div className={Styles.AddNewProject__formSingleField}>
                        <Typography variant="subtitle1">Requirement Status*</Typography>
                        <FormControl sx={{ minWidth: '250px' }} size="small">
                          <CognisaasRequirementStatusDropdown
                            sx={{ minWidth: '250px' }}
                            placeholder={'Select'}
                            name={`allRequirements[${index}].requirementStatus`}
                            value={props.values.allRequirements[index].requirementStatus}
                            changeHandler={props.handleChange}
                          />
                        </FormControl>
                      </div>

                      {/* requirement eta */}
                      {props.values.allRequirements[index].requirementStatus == 'amber' ? (
                        <div className={Styles.AddNewProject__formSingleField}>
                          <Typography variant="subtitle1">ETA for Release</Typography>
                          <FormControl sx={{ minWidth: '250px' }} size="small">
                            <CognisaasCalenderNew
                              name={`allRequirements[${index}].etaForRelease`}
                              value={
                                props.values.allRequirements[index].etaForRelease != '' ?
                                  props.values.allRequirements[index].etaForRelease :
                                  props.setFieldValue(`allRequirements[${index}].etaForRelease`, new Date())
                              }
                              handleChange={(date: any) => props.setFieldValue(`allRequirements[${index}].etaForRelease`, new Date(date))}
                            />
                          </FormControl>
                        </div>
                      ) : (
                        ''
                      )}

                      {index > 0 ? (
                        <div>
                          <Typography style={{ visibility: 'hidden' }}>To make proper alignment</Typography>
                          <Button onClick={() => remove(index)}>Delete</Button>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  ))}
                  <div className={Styles.xcx}>
                    <Button
                      onClick={() =>
                        push({
                          requirementName: '',
                          requirementStatus: 'red',
                          etaForRelease: '',
                        })
                      }
                    >
                      Add Requirement
                    </Button>
                  </div>
                </div>
              )}
            </FieldArray>

            {/* buttons for furthur form actions */}
            <div className={Styles.AddNewProduct__formActionButtonsContainer}>
              {/* save and cancel this step buttons  */}
              <CognisaasButton
                label={'Cancel'}
                isOutlined={true}
                clickHandler={handleCancel.bind(null, props.resetForm)}
              />
              <span style={{ width: '20px' }}></span>
              <CognisaasButton
                sx={{ width: '77px' }}
                label={'Save'}
                isOutlined={false}
                clickHandler={props.handleSubmit}
              />
            </div>

            {/* this is alerts */}
            <Snackbar
              open={alertDetails.isVisible}
              autoHideDuration={6000}
              onClose={() => setAlertDetails({ isVisible: false, severity: 'error', message: '' })}
            >
              <Alert
                elevation={6}
                variant="filled"
                onClose={() => setAlertDetails({ isVisible: false, severity: 'error', message: '' })}
                severity={alertDetails.severity as AlertColor}
                sx={{ width: '100%' }}
              >
                {alertDetails.message}
              </Alert>
            </Snackbar>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default AddNewProduct;
