import { taskEntityStandardFieldsDisplayName } from '../Config/EntityDisplayNames';
import { taskEntityStandardFieldsKey } from '../Config/EntityKeys';
import { taskEntityStandardFieldsAggregatable } from '../Config/EntityAggregatable';
import { taskEntityStandardFieldsType } from '../Config/EntityTypes';
import { getAllowedOperations } from '../UtilityFunction';

export const taskEntityStandardFields = {
  task_name: {
    displayName: taskEntityStandardFieldsDisplayName.name,
    key: taskEntityStandardFieldsKey.name,
    type: taskEntityStandardFieldsType.name,
    isAggregatable: taskEntityStandardFieldsAggregatable.name,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(taskEntityStandardFieldsType.name),
    toAggregate: false,
    aggregationToPerform: null,
  },
  task_owner: {
    displayName: taskEntityStandardFieldsDisplayName.owner,
    key: taskEntityStandardFieldsKey.owner,
    type: taskEntityStandardFieldsType.owner,
    isAggregatable: taskEntityStandardFieldsAggregatable.owner,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(taskEntityStandardFieldsType.owner),
    toAggregate: false,
    aggregationToPerform: null,
  },
  is_team_task: {
    displayName: taskEntityStandardFieldsDisplayName.type,
    key: taskEntityStandardFieldsKey.type,
    type: taskEntityStandardFieldsType.type,
    isAggregatable: taskEntityStandardFieldsAggregatable.type,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(taskEntityStandardFieldsType.type),
    toAggregate: false,
    aggregationToPerform: null,
  },
  task_priority: {
    displayName: taskEntityStandardFieldsDisplayName.priority,
    key: taskEntityStandardFieldsKey.priority,
    type: taskEntityStandardFieldsType.priority,
    isAggregatable: taskEntityStandardFieldsAggregatable.priority,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(taskEntityStandardFieldsType.priority),
    toAggregate: false,
    aggregationToPerform: null,
  },
  task_description: {
    displayName: taskEntityStandardFieldsDisplayName.description,
    key: taskEntityStandardFieldsKey.description,
    type: taskEntityStandardFieldsType.description,
    isAggregatable: taskEntityStandardFieldsAggregatable.description,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(taskEntityStandardFieldsType.description),
    toAggregate: false,
    aggregationToPerform: null,
  },
  task_stage: {
    displayName: taskEntityStandardFieldsDisplayName.stage,
    key: taskEntityStandardFieldsKey.stage,
    type: taskEntityStandardFieldsType.stage,
    isAggregatable: taskEntityStandardFieldsAggregatable.stage,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(taskEntityStandardFieldsType.stage),
    toAggregate: false,
    aggregationToPerform: null,
  },
  task_status: {
    displayName: taskEntityStandardFieldsDisplayName.status,
    key: taskEntityStandardFieldsKey.status,
    type: taskEntityStandardFieldsType.status,
    isAggregatable: taskEntityStandardFieldsAggregatable.status,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(taskEntityStandardFieldsType.status),
    toAggregate: false,
    aggregationToPerform: null,
  },
  task_due_date: {
    displayName: taskEntityStandardFieldsDisplayName.duedate,
    key: taskEntityStandardFieldsKey.duedate,
    type: taskEntityStandardFieldsType.duedate,
    isAggregatable: taskEntityStandardFieldsAggregatable.duedate,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(taskEntityStandardFieldsType.duedate),
    toAggregate: false,
    aggregationToPerform: null,
  },
  task_closed_date: {
    displayName: taskEntityStandardFieldsDisplayName.closedDate,
    key: taskEntityStandardFieldsKey.closedDate,
    type: taskEntityStandardFieldsType.closedDate,
    isAggregatable: taskEntityStandardFieldsAggregatable.closedDate,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(taskEntityStandardFieldsType.closedDate),
    toAggregate: false,
    aggregationToPerform: null,
  },
  task_start_date: {
    displayName: taskEntityStandardFieldsDisplayName.startDate,
    key: taskEntityStandardFieldsKey.startDate,
    type: taskEntityStandardFieldsType.startDate,
    isAggregatable: taskEntityStandardFieldsAggregatable.startDate,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(taskEntityStandardFieldsType.startDate),
    toAggregate: false,
    aggregationToPerform: null,
  },
  task_planned_start_date: {
    displayName: taskEntityStandardFieldsDisplayName.plannedStartDate,
    key: taskEntityStandardFieldsKey.plannedStartDate,
    type: taskEntityStandardFieldsType.plannedStartDate,
    isAggregatable: taskEntityStandardFieldsAggregatable.plannedStartDate,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(taskEntityStandardFieldsType.plannedStartDate),
    toAggregate: false,
    aggregationToPerform: null,
  },
  task_created_on: {
    displayName: taskEntityStandardFieldsDisplayName.createdOn,
    key: taskEntityStandardFieldsKey.createdOn,
    type: taskEntityStandardFieldsType.createdOn,
    isAggregatable: taskEntityStandardFieldsAggregatable.createdOn,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(taskEntityStandardFieldsType.createdOn),
    toAggregate: false,
    aggregationToPerform: null,
  },
  effort_estimate: {
    displayName: taskEntityStandardFieldsDisplayName.effortEstimate,
    key: taskEntityStandardFieldsKey.effortEstimate,
    type: taskEntityStandardFieldsType.effortEstimate,
    isAggregatable: taskEntityStandardFieldsAggregatable.effortEstimate,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(taskEntityStandardFieldsType.effortEstimate),
    toAggregate: false,
    aggregationToPerform: null,
  },
  total_time_spent: {
    displayName: taskEntityStandardFieldsDisplayName.totalTimeSpent,
    key: taskEntityStandardFieldsKey.totalTimeSpent,
    type: taskEntityStandardFieldsType.totalTimeSpent,
    isAggregatable: taskEntityStandardFieldsAggregatable.totalTimeSpent,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(taskEntityStandardFieldsType.totalTimeSpent),
    toAggregate: false,
    aggregationToPerform: null,
  },
  time_remaining: {
    displayName: taskEntityStandardFieldsDisplayName.totalTimeRemaining,
    key: taskEntityStandardFieldsKey.totalTimeRemaining,
    type: taskEntityStandardFieldsType.totalTimeRemaining,
    isAggregatable: taskEntityStandardFieldsAggregatable.totalTimeRemaining,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(taskEntityStandardFieldsType.totalTimeRemaining),
    toAggregate: false,
    aggregationToPerform: null,
  },
  client_check: {
    displayName: taskEntityStandardFieldsDisplayName.clientCheck,
    key: taskEntityStandardFieldsKey.clientCheck,
    type: taskEntityStandardFieldsType.clientCheck,
    isAggregatable: taskEntityStandardFieldsAggregatable.clientCheck,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(taskEntityStandardFieldsType.clientCheck),
    toAggregate: false,
    aggregationToPerform: null,
  },
  client_initiate: {
    displayName: taskEntityStandardFieldsDisplayName.clientInitiated,
    key: taskEntityStandardFieldsKey.clientInitiated,
    type: taskEntityStandardFieldsType.clientInitiated,
    isAggregatable: taskEntityStandardFieldsAggregatable.clientInitiated,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(taskEntityStandardFieldsType.clientInitiated),
    toAggregate: false,
    aggregationToPerform: null,
  },
  milestone_task: {
    displayName: taskEntityStandardFieldsDisplayName.milestoneTask,
    key: taskEntityStandardFieldsKey.milestoneTask,
    type: taskEntityStandardFieldsType.milestoneTask,
    isAggregatable: taskEntityStandardFieldsAggregatable.milestoneTask,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(taskEntityStandardFieldsType.milestoneTask),
    toAggregate: false,
    aggregationToPerform: null,
  },
  csat_indicator: {
    displayName: taskEntityStandardFieldsDisplayName.csatIndicator,
    key: taskEntityStandardFieldsKey.csatIndicator,
    type: taskEntityStandardFieldsType.csatIndicator,
    isAggregatable: taskEntityStandardFieldsAggregatable.csatIndicator,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(taskEntityStandardFieldsType.csatIndicator),
    toAggregate: false,
    aggregationToPerform: null,
  },
  // last_user_modified: {
  //   displayName: taskEntityStandardFieldsDisplayName.lastModifiedBy,
  //   key: taskEntityStandardFieldsKey.lastModifiedBy,
  //   type: taskEntityStandardFieldsType.lastModifiedBy,
  //   isAggregatable: taskEntityStandardFieldsAggregatable.lastModifiedBy,
  //   isCustomField: false,
  //   allowedOperationsList: getAllowedOperations(taskEntityStandardFieldsType.lastModifiedBy),
  //   toAggregate: false,
  //   aggregationToPerform: null,
  // },
  // jira_ind: {
  //   displayName: taskEntityStandardFieldsDisplayName.jiraIndicator,
  //   key: taskEntityStandardFieldsKey.jiraIndicator,
  //   type: taskEntityStandardFieldsType.jiraIndicator,
  //   isAggregatable: taskEntityStandardFieldsAggregatable.jiraIndicator,
  //   isCustomField: false,
  //   allowedOperationsList: getAllowedOperations(taskEntityStandardFieldsType.jiraIndicator),
  //   toAggregate: false,
  //   aggregationToPerform: null,
  // },
  task_implementation_fee_percentage: {
    displayName: taskEntityStandardFieldsDisplayName.implementationFeePercentage,
    key: taskEntityStandardFieldsKey.implementationFeePercentage,
    type: taskEntityStandardFieldsType.implementationFeePercentage,
    isAggregatable: taskEntityStandardFieldsAggregatable.implementationFeePercentage,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(taskEntityStandardFieldsType.implementationFeePercentage),
    toAggregate: false,
    aggregationToPerform: null,
  },
};
