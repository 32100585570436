import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../index';

const selectProductRequirementState = (state: RootState) => state.productReqState;

// select all data and error and status from product requirement
export const selectPrAndReqDataState = createSelector(
  selectProductRequirementState,
  (selectPrReqState) => selectPrReqState.productAndTheirRequirement
);
export const selectNewProductAndReqState = createSelector(
  selectProductRequirementState,
  (selectProductReqState) => selectProductReqState.newProductAndReqToBeAdded
);
export const selectNewRequirementState = createSelector(
  selectProductRequirementState,
  (selectProductReqState) => selectProductReqState.newRequirement
);

export const selectUpdateNewRequirementState = createSelector(
  selectProductRequirementState,
  (selectProductReqState) => selectProductReqState.updateRequirement
);

export const selectRequirementCommentsState = createSelector(
  selectProductRequirementState,
  (selectProductReqState) => selectProductReqState.requirementComments
);

export const selectCustomerDetailsState = createSelector(
  selectProductRequirementState,
  (selectProductReqState) => selectProductReqState.customerDetail
);

export const selectJiraMappedProjectKeyState = createSelector(
  selectProductRequirementState,
  (selectProductReqState) => selectProductReqState.jiraMappedProjectToProductKey
);

export const selectJiraCustomFieldsState = createSelector(
  selectProductRequirementState,
  (selectProductReqState) => selectProductReqState.jiraCustomFields
);

export const selectJiraFullCustomFieldsState = createSelector(
  selectProductRequirementState,
  (selectProductReqState) => selectProductReqState.jiraFullCustomFields
);

export const selectJiraIssueState = createSelector(
  selectProductRequirementState,
  (selectProductReqState) => selectProductReqState.creatingJiraIssue
);

export const selectAddedNewRequirementFeatureUid = createSelector(
  selectProductRequirementState,
  (selectProductReqState) => selectProductReqState.newAddedRequirementCommentUid
);

// syncingJiraProductAndRequirement
export const selectSyncingRequirement = createSelector(
  selectProductRequirementState,
  (selectProductReqState) => selectProductReqState.syncingJiraProductAndRequirement
);
