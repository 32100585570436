import { OutlinedInput, InputAdornment, Chip, Typography, Stack, Button, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';

import Skeleton from '@mui/material/Skeleton';
import { getTaskByTaskUid } from 'store/modules/Task/slice';
import UpdateTaskDrawer from 'components/TaskModule/TaskView/UpdateTaskDrawer';
import SearchIcon from '@mui/icons-material/Search';
import React, { useEffect } from 'react';
import { getGlobalSearchContentByCustomerUid } from '../../../store/modules/GlobalSearch/GlobalSearch';

import { ReactComponent as SearchLogo } from '../../../assets/svgs/search.svg';
import { useAppSelector, useAppDispatch } from '../../../store/hooks';

import { ReactComponent as IoRecords } from '../../../assets/svgs/no_records.svg';
import { useNavigate } from 'react-router-dom';

import {
  selectResultState,
  selectLoadingStatus,
  selectDataStatus,
  selectIsClient,
  selectIsProject,
  selectIsUseCase,
  selectIsTeamTask,
  selectIsClientTask,
} from '../../../store/modules/GlobalSearch/selector';

// import { useDispatch } from 'react-redux';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const GlobalSearch = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [updateTaskDrawer, setUpdateTaskDrawer] = React.useState<boolean>(false);
  const [selectedFilter, setSelectedFilter] = React.useState('All');
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [openTaskWithDetail, setOpenTaskWithDetail] = React.useState<any>(null);

  const qureyResults = useAppSelector(selectResultState);
  const isLoading = useAppSelector(selectLoadingStatus);
  const dataStatus = useAppSelector(selectDataStatus);
  const showClient = useAppSelector(selectIsClient);
  const showProject = useAppSelector(selectIsProject);
  const showUseCase = useAppSelector(selectIsUseCase);
  const showTeamTask = useAppSelector(selectIsTeamTask);
  const showClientTask = useAppSelector(selectIsClientTask);

  const [requestObject, setRequestObject] = React.useState({});
  const [inputData, setInputData] = React.useState('');
  const [inputDataHelpter, setInputDataHelper] = React.useState('');
  const [isGlobalNavActive, setIsGlobalNavActive] = React.useState(false);
  const [data, setData] = React.useState<any>(false);
  const [isDataLoading, setIsDataLoading] = React.useState<any>(false);

  const handleClick = () => {
    setIsGlobalNavActive(true);
  };

  const handleClickOutlied = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (e: any) => {
    if (e.target.value.length > 1) setAnchorEl(e.currentTarget);
    if (e.target.value.length < 3) setAnchorEl(null);
    console.log(e.target.value);
    setInputData(e.target.value);
    setInputDataHelper(e.target.value);
    setRequestObject({
      tag: selectedFilter == 'All' ? '' : selectedFilter,
      value: e.target.value,
      customer_uid: cookies.get('cogni_customer_uid') as string,
    });
  };

  const handelClickAll = () => {
    setSelectedFilter('All');
    setInputData(inputDataHelpter);
    setRequestObject({
      tag: '',
      value: inputDataHelpter,
      customer_uid: cookies.get('cogni_customer_uid') as string,
    });
    console.log('reached');
    // dispatch(getGlobalSearchContentByCustomerUid(requestObject));
  };

  const handelClickClient = () => {
    setSelectedFilter('Client');

    setInputData(inputDataHelpter);
    setRequestObject({
      tag: 'Client',
      value: inputDataHelpter,
      customer_uid: cookies.get('cogni_customer_uid') as string,
    });
    console.log('reached');
    // dispatch(getGlobalSearchContentByCustomerUid(requestObject));
  };

  const handelClickProjects = () => {
    setSelectedFilter('Project');
    setInputData(inputDataHelpter);
    setRequestObject({
      tag: 'Project',
      value: inputDataHelpter,
      customer_uid: cookies.get('cogni_customer_uid') as string,
    });
    console.log('reached');
    // dispatch(getGlobalSearchContentByCustomerUid(requestObject));
  };

  const handelClickUsecase = () => {
    setSelectedFilter('Usecase');
    setInputData(inputDataHelpter);
    setRequestObject({
      tag: 'Usecase',
      value: inputDataHelpter,
      customer_uid: cookies.get('cogni_customer_uid') as string,
    });
    console.log('reached');
    // dispatch(getGlobalSearchContentByCustomerUid(requestObject));
  };

  const handelClickTask = () => {
    setSelectedFilter('Task');
    setInputData(inputDataHelpter);
    setRequestObject({
      tag: 'Task',
      value: inputDataHelpter,
      customer_uid: cookies.get('cogni_customer_uid') as string,
    });
    console.log('reached');
    // dispatch(getGlobalSearchContentByCustomerUid(requestObject));
  };

  const handelClickClientTask = () => {
    setSelectedFilter('Client Task');
    setInputData(inputDataHelpter);
    setRequestObject({
      tag: 'Client Task',
      value: inputDataHelpter,
      customer_uid: cookies.get('cogni_customer_uid') as string,
    });
    console.log('reached');
    // dispatch(getGlobalSearchContentByCustomerUid(requestObject));
  };

  // console.log(dataStatus, 'show data');
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      // console.log(inputData);
      if (inputData.length > 2 && !isLoading) {
        dispatch(getGlobalSearchContentByCustomerUid(requestObject));
        console.log(requestObject, 'reqobj');
        setInputData('');
      }
    }, 300);

    if (isLoading === true) {
      setIsDataLoading(true);
    }
    if (isLoading === false) {
      setIsDataLoading(false);
    }
    if (qureyResults) {
      setData(qureyResults);
    }
    return () => clearTimeout(delayDebounceFn);
  }, [qureyResults, inputData, isLoading, isDataLoading, requestObject]);

  const openTaskPanel = async (task: any) => {
    const ids: any[] = task.id.split(',');
    const taskResult = await dispatch(getTaskByTaskUid(ids[ids.length - 1])).unwrap();
    console.log(taskResult);
    setOpenTaskWithDetail({ ...taskResult, client_uid: ids[0] });
    setUpdateTaskDrawer(!updateTaskDrawer);
  };

  return (
    <div>
      {isGlobalNavActive ? (
        <>
          <OutlinedInput
            size="small"
            id="outlined-basic"
            placeholder="Search by Client, Project, Use case, Task"
            sx={{ width: '485px', borderRadius: '4px 4px 0px 0px', border: '1px solid #E5E5E5' }}
            autoComplete="off"
            endAdornment={
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            }
            autoFocus={false}
            aria-describedby={id}
            onClick={(event) => handleClickOutlied(event)}
            // onChange={(e) => {
            //   // setInputData(e.target.value);
            //   setRequestObject({ tag: selectedFilter == 'All' ? '' : selectedFilter, value: e.target.value });
            // }}
            onChange={(e) => handleChange(e)}
          />

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            disableAutoFocus
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <Box sx={{ maxWidth: '485px', minWidth: '485px', paddingTop: '40px' }}>
              <Box
                style={{
                  padding: '12px',
                  position: 'fixed',
                  top: '52px',
                  zIndex: '1000',
                  backgroundColor: '#FFFFFF',
                  paddingBottom: '10px',
                }}
              >
                {selectedFilter === 'All' ? (
                  <Chip
                    label={<Typography sx={{ fontSize: '12px', fontWeight: '400' }}>All</Typography>}
                    variant="outlined"
                    sx={{ width: '40px', height: '26px', backgroundColor: '#2D60F6', color: '#FFFFFF' }}
                  />
                ) : (
                  <Chip
                    label={<Typography sx={{ fontSize: '12px', fontWeight: '400' }}>All</Typography>}
                    variant="outlined"
                    sx={{ width: '40px', height: '26px', borderColor: '#2D60F6', color: '#2D60F6' }}
                    onClick={() => handelClickAll()}
                  />
                )}
                {selectedFilter === 'Client' ? (
                  <Chip
                    label={<Typography sx={{ fontSize: '12px', fontWeight: '400' }}>Clients</Typography>}
                    variant="outlined"
                    sx={{ width: '63px', height: '26px', ml: '8px', backgroundColor: '#2D60F6', color: '#FFFFFF' }}
                  />
                ) : (
                  <Chip
                    label={<Typography sx={{ fontSize: '12px', fontWeight: '400' }}>Clients</Typography>}
                    variant="outlined"
                    sx={{ width: '63px', height: '26px', ml: '8px', borderColor: '#2D60F6', color: '#2D60F6' }}
                    onClick={() => handelClickClient()}
                  />
                )}
                {selectedFilter === 'Project' ? (
                  <Chip
                    label={<Typography sx={{ fontSize: '12px', fontWeight: '400' }}>Projects</Typography>}
                    variant="outlined"
                    sx={{ width: '69px', height: '26px', ml: '8px', backgroundColor: '#2D60F6', color: '#FFFFFF' }}
                  />
                ) : (
                  <Chip
                    label={<Typography sx={{ fontSize: '12px', fontWeight: '400' }}>Projects</Typography>}
                    variant="outlined"
                    sx={{ width: '69px', height: '26px', ml: '8px', borderColor: '#2D60F6', color: '#2D60F6' }}
                    onClick={() => handelClickProjects()}
                  />
                )}
                {selectedFilter === 'Usecase' ? (
                  <Chip
                    label={<Typography sx={{ fontSize: '12px', fontWeight: '400' }}>Use Cases</Typography>}
                    variant="outlined"
                    sx={{ width: '81px', height: '26px', ml: '8px', backgroundColor: '#2D60F6', color: '#FFFFFF' }}
                  />
                ) : (
                  <Chip
                    label={<Typography sx={{ fontSize: '12px', fontWeight: '400' }}>Use Cases</Typography>}
                    variant="outlined"
                    sx={{ width: '81px', height: '26px', ml: '8px', borderColor: '#2D60F6', color: '#2D60F6' }}
                    onClick={() => handelClickUsecase()}
                  />
                )}
                {selectedFilter === 'Task' ? (
                  <Chip
                    label={<Typography sx={{ fontSize: '12px', fontWeight: '400' }}>Team Task</Typography>}
                    variant="outlined"
                    sx={{ width: '83px', height: '26px', ml: '8px', backgroundColor: '#2D60F6', color: '#FFFFFF' }}
                  />
                ) : (
                  <Chip
                    label={<Typography sx={{ fontSize: '12px', fontWeight: '400' }}>Team Task</Typography>}
                    variant="outlined"
                    sx={{ width: '83px', height: '26px', ml: '8px', borderColor: '#2D60F6', color: '#2D60F6' }}
                    onClick={() => handelClickTask()}
                  />
                )}
                {selectedFilter === 'Client Task' ? (
                  <Chip
                    label={<Typography sx={{ fontSize: '12px', fontWeight: '400' }}>Client Task</Typography>}
                    variant="outlined"
                    sx={{ width: '84px', height: '26px', ml: '8px', backgroundColor: '#2D60F6', color: '#FFFFFF' }}
                  />
                ) : (
                  <Chip
                    label={<Typography sx={{ fontSize: '12px', fontWeight: '400' }}>Client Task</Typography>}
                    variant="outlined"
                    sx={{ width: '84px', height: '26px', ml: '8px', borderColor: '#2D60F6', color: '#2D60F6' }}
                    onClick={() => handelClickClientTask()}
                  />
                )}
              </Box>
            </Box>
            <Box
              sx={{
                paddingLeft: '12px',
                paddingRight: '12px',
                maxHeight: '222px',
                maxWidth: '480px',
                paddingBottom: '10px',
              }}
            >
              {isDataLoading ? (
                <Box sx={{ padding: '12px', maxHeight: '222px' }}>
                  {/* <Grid container xs={12} justifyContent="space-between">
                    <Grid item>
                      <Skeleton sx={{ width: '100px' }} />
                    </Grid>

                    <Grid item>
                      <Skeleton sx={{ width: '100px' }} />
                    </Grid>
                  </Grid> */}
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                </Box>
              ) : (
                <>
                  {showClient && selectedFilter === 'All' ? (
                    <>
                      <Grid container xs={12} justifyContent="space-between">
                        <Grid item>
                          <Typography sx={{ marginTop: '8px', fontSize: '14px', fontWeight: '500' }}>Client</Typography>
                        </Grid>

                        <Grid item>
                          <Button
                            onClick={() => handelClickClient()}
                            variant="text"
                            sx={{ fontSize: '14px', fontWeight: '400' }}
                          >
                            View all
                          </Button>
                        </Grid>
                      </Grid>
                    </>
                  ) : null}
                  {showClient && selectedFilter === 'All'
                    ? data?.client.map((ele: any, i: number) => {
                        return (
                          <Stack direction="row" key={i} sx={{ marginTop: '5px' }}>
                            <Box
                              sx={{
                                backgroundColor: ele.status,
                                height: '17px',
                                width: '3px',
                                mt: '3px',
                                borderRadius: '4px',
                              }}
                            ></Box>
                            <Typography
                              className="cognisaas-pointer"
                              onClick={() => {
                                navigate(`/client-list/client/${ele.id}`);
                              }}
                              sx={{ ml: '12px', fontSize: '14px', fontWeight: '400' }}
                            >
                              {ele.name}
                            </Typography>
                          </Stack>
                        );
                      })
                    : null}
                  {dataStatus &&
                  selectedFilter === 'All' &&
                  data.client.length === 0 &&
                  data?.project.length === 0 &&
                  data?.usecase.length === 0 &&
                  data?.task.length === 0 &&
                  data?.client_task.length === 0 ? (
                    <>
                      <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        sx={{ marginTop: '10px' }}
                      >
                        <Typography>No results found</Typography>
                        <IoRecords style={{ height: '180px', marginTop: '10px' }} />
                      </Grid>
                    </>
                  ) : null}
                  {console.log(dataStatus && selectedFilter === 'All' && data.client.length === 0)}
                  {dataStatus && selectedFilter === 'Client' && data?.client.length === 0 ? (
                    <>
                      <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        sx={{ marginTop: '10px' }}
                      >
                        <Typography>No results found</Typography>
                        <IoRecords style={{ height: '180px', marginTop: '10px' }} />
                      </Grid>
                    </>
                  ) : null}
                  {showProject && selectedFilter === 'All' ? (
                    <>
                      <Grid container xs={12} justifyContent="space-between">
                        <Grid item>
                          <Typography sx={{ marginTop: '8px', fontSize: '14px', fontWeight: '500' }}>
                            Project
                          </Typography>
                        </Grid>

                        <Grid item>
                          <Button
                            onClick={() => handelClickProjects()}
                            variant="text"
                            sx={{ fontSize: '14px', fontWeight: '400' }}
                          >
                            View all
                          </Button>
                        </Grid>
                      </Grid>
                    </>
                  ) : null}
                  {showProject && selectedFilter === 'All'
                    ? data?.project.map((ele: any, i: number) => {
                        return (
                          <Stack direction="row" key={i} sx={{ marginTop: '5px' }}>
                            <Box
                              sx={{
                                backgroundColor: ele.status,
                                height: '17px',
                                width: '3px',
                                mt: '5px',
                                borderRadius: '4px',
                              }}
                            ></Box>
                            <Typography
                              className="cognisaas-pointer"
                              onClick={() => {
                                const ids = [...ele.id.split(',')];
                                navigate(`/projects/project/${ids[1]}/${ids[2]}`);
                              }}
                              sx={{ ml: '12px', fontSize: '14px', fontWeight: '400' }}
                            >
                              {ele.name}
                            </Typography>
                          </Stack>
                        );
                      })
                    : null}
                  {dataStatus && selectedFilter === 'Project' && data?.project.length === 0 ? (
                    <>
                      <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        sx={{ marginTop: '10px' }}
                      >
                        <Typography>No results found</Typography>
                        <IoRecords style={{ height: '180px', marginTop: '10px' }} />
                      </Grid>
                    </>
                  ) : null}
                  {showUseCase && selectedFilter === 'All' ? (
                    <>
                      <Grid container xs={12} justifyContent="space-between">
                        <Grid item>
                          <Typography sx={{ marginTop: '8px', fontSize: '14px', fontWeight: '500' }}>
                            Use case
                          </Typography>
                        </Grid>

                        <Grid item>
                          <Button
                            onClick={() => handelClickUsecase()}
                            variant="text"
                            sx={{ fontSize: '14px', fontWeight: '400' }}
                          >
                            View all
                          </Button>
                        </Grid>
                      </Grid>
                    </>
                  ) : null}
                  {showUseCase && selectedFilter === 'All'
                    ? data?.usecase.map((ele: any, i: number) => {
                        return (
                          <Stack direction="row" key={i} sx={{ marginTop: '5px' }}>
                            <Box
                              sx={{
                                backgroundColor: ele.status,
                                height: '17px',
                                width: '3px',
                                mt: '5px',
                                borderRadius: '4px',
                              }}
                            ></Box>
                            <Typography
                              className="cognisaas-pointer"
                              onClick={() => {
                                const ids = [...ele.id.split(',')];
                                navigate(`/projects/project/${ids[1]}/${ids[2]}/${ids[3]}`, {
                                  state: { clientUid: ids[0], clientName: ele.client_name },
                                });
                              }}
                              sx={{ ml: '12px', fontSize: '14px', fontWeight: '400' }}
                            >
                              {ele.name}
                            </Typography>
                          </Stack>
                        );
                      })
                    : null}
                  {dataStatus && selectedFilter === 'Usecase' && data?.usecase.length === 0 ? (
                    <>
                      <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        sx={{ marginTop: '10px' }}
                      >
                        <Typography>No results found</Typography>
                        <IoRecords style={{ height: '180px', marginTop: '10px' }} />
                      </Grid>
                    </>
                  ) : null}
                  {showTeamTask && selectedFilter === 'All' ? (
                    <>
                      <Grid container xs={12} justifyContent="space-between">
                        <Grid item>
                          <Typography sx={{ marginTop: '8px', fontSize: '14px', fontWeight: '500' }}>
                            Team Task
                          </Typography>
                        </Grid>

                        <Grid item>
                          <Button
                            onClick={() => handelClickTask()}
                            variant="text"
                            sx={{ fontSize: '14px', fontWeight: '400' }}
                          >
                            View all
                          </Button>
                        </Grid>
                      </Grid>
                    </>
                  ) : null}
                  {showTeamTask && selectedFilter === 'All'
                    ? data?.task.map((ele: any, i: number) => {
                        return (
                          <Stack direction="row" key={i} sx={{ marginTop: '5px' }}>
                            <Box
                              sx={{
                                backgroundColor: ele.status,
                                height: '17px',
                                width: '3px',
                                mt: '5px',
                                borderRadius: '4px',
                              }}
                            ></Box>
                            <Typography
                              className="cognisaas-pointer"
                              onClick={() => openTaskPanel(ele)}
                              sx={{ ml: '12px', fontSize: '14px', fontWeight: '400' }}
                            >
                              {ele.name}
                            </Typography>
                          </Stack>
                        );
                      })
                    : null}
                  {dataStatus && selectedFilter === 'Task' && data?.task.length === 0 ? (
                    <>
                      <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        sx={{ marginTop: '10px' }}
                      >
                        <Typography>No results found</Typography>
                        <IoRecords style={{ height: '180px', marginTop: '10px' }} />
                      </Grid>
                    </>
                  ) : null}
                  {showClientTask && selectedFilter === 'All' ? (
                    <>
                      <Grid container xs={12} justifyContent="space-between">
                        <Grid item>
                          <Typography sx={{ marginTop: '8px', fontSize: '14px', fontWeight: '500' }}>
                            Client Task
                          </Typography>
                        </Grid>

                        <Grid item>
                          <Button
                            onClick={() => handelClickClientTask()}
                            variant="text"
                            sx={{ fontSize: '14px', fontWeight: '400' }}
                          >
                            View all
                          </Button>
                        </Grid>
                      </Grid>
                    </>
                  ) : null}
                  {showClientTask && selectedFilter === 'All'
                    ? data?.client_task.map((ele: any, i: number) => {
                        return (
                          <Stack direction="row" key={i} sx={{ marginTop: '5px' }}>
                            <Box
                              sx={{
                                backgroundColor: ele.status,
                                height: '17px',
                                width: '3px',
                                mt: '5px',
                                borderRadius: '4px',
                              }}
                            ></Box>
                            <Typography
                              className="cognisaas-pointer"
                              onClick={() => openTaskPanel(ele)}
                              sx={{ ml: '12px', fontSize: '14px', fontWeight: '400' }}
                            >
                              {ele.name}
                            </Typography>
                          </Stack>
                        );
                      })
                    : null}
                  {dataStatus && selectedFilter === 'Client Task' && data?.client_task.length === 0 ? (
                    <>
                      <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        sx={{ marginTop: '10px' }}
                      >
                        <Typography>No results found</Typography>
                        <IoRecords style={{ height: '180px', marginTop: '10px' }} />
                      </Grid>
                    </>
                  ) : null}
                  {showClient && selectedFilter === 'Client'
                    ? data?.client.map((ele: any, i: number) => {
                        return (
                          <Stack direction="row" key={i} sx={{ marginTop: '5px' }}>
                            <Box
                              sx={{
                                backgroundColor: ele.status,
                                height: '17px',
                                width: '3px',
                                mt: '5px',
                                borderRadius: '4px',
                              }}
                            ></Box>
                            <Typography
                              className="cognisaas-pointer"
                              onClick={() => {
                                navigate(`/client-list/client/${ele.id}`);
                              }}
                              sx={{ ml: '12px', fontSize: '14px', fontWeight: '400' }}
                            >
                              {ele.name}
                            </Typography>
                          </Stack>
                        );
                      })
                    : null}
                  {showProject && selectedFilter === 'Project'
                    ? data?.project.map((ele: any, i: number) => {
                        return (
                          <Stack direction="row" key={i} sx={{ marginTop: '5px' }}>
                            <Box
                              sx={{
                                backgroundColor: ele.status,
                                height: '17px',
                                width: '3px',
                                mt: '5px',
                                borderRadius: '4px',
                              }}
                            ></Box>
                            <Typography
                              className="cognisaas-pointer"
                              onClick={() => {
                                const ids = [...ele.id.split(',')];
                                navigate(`/projects/project/${ids[1]}/${ids[2]}`);
                              }}
                              sx={{ ml: '12px' }}
                            >
                              {ele.name}
                            </Typography>
                          </Stack>
                        );
                      })
                    : null}
                  {showUseCase && selectedFilter === 'Usecase'
                    ? data?.usecase.map((ele: any, i: number) => {
                        return (
                          <Stack direction="row" key={i} sx={{ marginTop: '5px' }}>
                            <Box
                              sx={{
                                backgroundColor: ele.status,
                                height: '17px',
                                width: '3px',
                                mt: '5px',
                                borderRadius: '4px',
                              }}
                            ></Box>
                            <Typography
                              className="cognisaas-pointer"
                              onClick={() => {
                                const ids = [...ele.id.split(',')];
                                navigate(`/projects/project/${ids[1]}/${ids[2]}/${ids[3]}`, {
                                  state: { clientUid: ids[0], clientName: ele.client_name },
                                });
                              }}
                              sx={{ ml: '12px', fontSize: '14px', fontWeight: '400' }}
                            >
                              {ele.name}
                            </Typography>
                          </Stack>
                        );
                      })
                    : null}
                  {showTeamTask && selectedFilter === 'Task'
                    ? data?.task.map((ele: any, i: number) => {
                        return (
                          <Stack direction="row" key={i} sx={{ marginTop: '5px' }}>
                            <Box
                              sx={{
                                backgroundColor: ele.status,
                                height: '17px',
                                width: '3px',
                                mt: '5px',
                                borderRadius: '4px',
                              }}
                            ></Box>
                            <Typography
                              className="cognisaas-pointer"
                              onClick={() => openTaskPanel(ele)}
                              sx={{ ml: '12px', fontSize: '14px', fontWeight: '400' }}
                            >
                              {ele.name}
                            </Typography>
                          </Stack>
                        );
                      })
                    : null}
                  {showClientTask && selectedFilter === 'Client Task'
                    ? data?.client_task.map((ele: any, i: number) => {
                        return (
                          <Stack direction="row" key={i} sx={{ marginTop: '5px' }}>
                            <Box
                              sx={{
                                backgroundColor: ele.status,
                                height: '17px',
                                width: '3px',
                                mt: '5px',
                                borderRadius: '4px',
                              }}
                            ></Box>
                            <Typography
                              className="cognisaas-pointer"
                              onClick={() => openTaskPanel(ele)}
                              sx={{ ml: '12px', fontSize: '14px', fontWeight: '400' }}
                            >
                              {ele.name}
                            </Typography>
                          </Stack>
                        );
                      })
                    : null}
                </>
              )}
            </Box>
          </Popover>
        </>
      ) : (
        <SearchLogo aria-describedby={id} onClick={handleClick} />
      )}
      {updateTaskDrawer && (
        <UpdateTaskDrawer
          client={{ client_uid: openTaskWithDetail.client_uid }}
          taskDetail={openTaskWithDetail}
          drawerState={updateTaskDrawer}
          onClose={() => setUpdateTaskDrawer(!updateTaskDrawer)}
        />
      )}
    </div>
  );
};

export default GlobalSearch;
