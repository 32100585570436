import { Stack } from '@mui/material';
import { GridColDef, GridRowsProp } from '@mui/x-data-grid';
import { DataGridPremium } from '@mui/x-data-grid-premium';

const SampleExcel = () => {
  const rows: GridRowsProp = [
    {
      id: 1,
      phase_name: 'Discovery',
      task_name: 'Inital kickoff',
      task_type: 'Internal',
      duration: '',
      predecessors: '',
      task_owner: '',
      share_with_client: '',
      milestone: '',
      implementation_fee: '',
    },
    {
      id: 2,
      task_name: 'Sales Handover Call',
      phase_name: 'Discovery',
      task_type: 'Internal',
      duration: '1d',
      predecessors: '',
      task_owner: 'Not Assigned',
      share_with_client: 'Yes',
      milestone: 'Yes',
      implementation_fee: '0',
    },
    {
      id: 3,
      task_name: 'Pre-Kick Off Call',
      phase_name: 'Discovery',
      task_type: 'External',
      duration: '2d',
      predecessors: '',
      task_owner: 'Not Assigned',
      share_with_client: 'Yes',
      milestone: 'Yes',
      implementation_fee: '0',
    },
    {
      id: 4,
      phase_name: 'Design',
      task_name: 'Design req',
      task_type: 'Internal',
      duration: '',
      predecessors: '',
      task_owner: '',
      share_with_client: '',
      milestone: '',
      implementation_fee: '',
    },
    {
      id: 5,
      task_name: 'FRD Sign Off and Plan',
      phase_name: 'Design',
      task_type: 'Internal',
      duration: '1d',
      predecessors: '',
      task_owner: 'Not Assigned',
      share_with_client: 'Yes',
      milestone: 'Yes',
      implementation_fee: '0',
    },
    {
      id: 6,
      phase_name: 'Build',
      task_name: 'Build plan and pipeline',
      task_type: 'Internal',
      duration: '',
      predecessors: '',
      task_owner: '',
      share_with_client: '',
      milestone: '',
      implementation_fee: '',
    },
    {
      id: 7,
      task_name: 'Requirement Walkthrough',
      phase_name: 'Build',
      task_type: 'External',
      duration: '7d',
      predecessors: '',
      task_owner: 'Not Assigned',
      share_with_client: 'Yes',
      milestone: 'Yes',
      implementation_fee: '0',
    },
    {
      id: 8,
      task_name: 'Build and Verification (Internal)',
      phase_name: 'Build',
      task_type: 'Internal',
      duration: '3d',
      predecessors: '',
      task_owner: 'Not Assigned',
      share_with_client: 'Yes',
      milestone: 'No',
      implementation_fee: '0',
    },
    {
      id: 9,
      task_name: 'UAT (Internal)',
      phase_name: 'Build',
      task_type: 'Internal',
      duration: '1d',
      predecessors: '7,8',
      task_owner: 'Not Assigned',
      share_with_client: 'Yes',
      milestone: 'Yes',
      implementation_fee: '0',
    },
  ];

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 80 },
    {
      field: 'phase_name',
      headerName: 'Phase Name',
      width: 250,
      editable: true,
    },
    {
      field: 'task_name',
      headerName: 'Task Name',
      width: 250,
    },
    {
      field: 'task_type',
      headerName: 'Task Type',
      width: 250,
    },
    {
      field: 'duration',
      headerName: 'Target Duration',
      width: 250,
    },
    {
      field: 'predecessors',
      headerName: 'Dependency',
      width: 150,
    },
    {
      field: 'implementation_fee',
      headerName: 'Implementation Fee',
      width: 150,
    },
    {
      field: 'task_owner',
      headerName: 'Task Owner',
      width: 250,
    },
    {
      field: 'share_with_client',
      headerName: 'Share with client',
      width: 150,
      editable: true,
    },
    {
      field: 'milestone',
      headerName: 'Milestone Task',
      width: 150,
    },
  ];
  return (
    <Stack mt={2} mx={2}>
      <div style={{ height: 800, width: '100%' }}>
        <DataGridPremium
          // editMode="row"
          initialState={{ pinnedColumns: { left: ['id'] } }}
          // cellModesModel={cellModesModel}
          // onCellModesModelChange={handleCellModesModelChange}
          // onCellClick={handleCellClick}
          experimentalFeatures={{ newEditingApi: true }}
          rowHeight={43}
          rows={rows}
          columns={columns}
          // rowReordering
          showCellRightBorder={true}
        />
      </div>
    </Stack>
  );
};

export default SampleExcel;
