import { useEffect, useState } from 'react';
import { getUsers } from 'store/modules/features/common/common';
import { useAppDispatch } from 'store/hooks';
import { useAppSelector } from 'store/hooks';
import { selectUsers } from 'store/modules/features/common/selector';

const useUsers = () => {
  const dispatch = useAppDispatch();
  const allUsers = useAppSelector(selectUsers);
  const [users, setUsers] = useState<any[]>([]);

  useEffect(() => {
    if (allUsers.data.length == 0) {
      dispatch(getUsers());
    }
    if (allUsers.data.length > 0) {
      setUsers([...allUsers.data]);
    }
  }, [allUsers]);

  const getUsersDropDownData = () => {
    const usersDropdown: any[] = [];
    if (allUsers.data.length) {
      for (let i = 0; i < users.length; i++) {
        usersDropdown.push({ ...users[i], name: users[i].user_name, is_active: 1 });
      }
    }
    return usersDropdown;
  };

  const getUserIdByName = (name: string) => {
    if (allUsers.data.length) {
      for (let i = 0; i < users.length; i++) {
        if (name == users[i].user_name) {
          return users[i].id;
        }
      }
    }
  };

  const getUserNameById = (id: string) => {
    if (allUsers.data.length) {
      for (let i = 0; i < users.length; i++) {
        if (id == users[i].id) {
          return users[i].user_name;
        }
      }
    }
  };

  return {
    users,
    getUsersDropDownData,
    getUserIdByName,
    getUserNameById,
  };
};

export default useUsers;
