import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { requestState } from '../types';
import { settingsAPI } from '../../../api/modules/home';

interface SettingsProps {
  notificationData: {
    data: Record<string, any>;
    status: requestState;
    error: null | undefined | string;
  };
}

const initialState: SettingsProps = {
  notificationData: {
    data: [],
    status: requestState.idle,
    error: null,
  },
};

interface notificationData_payload {
  user_uid: string;
}
export const getNotificationData_slice = createAsyncThunk(
  'getNotificationData',
  async (payload: notificationData_payload) => {
    const { data } = await settingsAPI.getNotificationData(payload);
    return data.result;
  }
);

export const homeSlice = createSlice({
  name: 'setting data',
  initialState,
  reducers: {
    //without api calls
  },
  extraReducers(builder) {
    builder
      // journey stages
      // get customer field task records
      .addCase(getNotificationData_slice.pending, (state) => {
        state.notificationData.status = requestState.loading;
      })
      .addCase(getNotificationData_slice.fulfilled, (state, action: PayloadAction<any>) => {
        state.notificationData.status = requestState.success;
        state.notificationData.data = [...action.payload];
      })
      .addCase(getNotificationData_slice.rejected, (state, action: any) => {
        state.notificationData.status = requestState.failed;
        state.notificationData.error = action.error.message;
      });
  },
});

// export const {
//     getProfileData,
// } = homeSlice.actions;

const homeReducer = homeSlice.reducer;
export default homeReducer;
