import { Typography, TypographyProps } from '@mui/material';
import { Box } from '@mui/system';
import CognisaaasTooltip from '../CognisaasTooltip/CognisaasTooltip';

interface Props extends TypographyProps {
  maxLength?: number;
  children: string;
}

const TypographyWithTooltip = (props: Props) => {
  const typographyProps: any = { ...props };
  delete typographyProps.maxLength;
  delete typographyProps.children;

  return (
    <>
      {props.maxLength && props.children.length > props.maxLength ? (
        <CognisaaasTooltip
          title={
            <Box m={1}>
              <Typography {...typographyProps}>{props.children}</Typography>
            </Box>
          }
        >
          <Typography {...typographyProps}>{props.children.substr(0, props.maxLength)}...</Typography>
        </CognisaaasTooltip>
      ) : (
        <Typography {...typographyProps}>{props.children}</Typography>
      )}
    </>
  );
};

export default TypographyWithTooltip;
