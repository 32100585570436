import { useAppDispatch } from 'store/hooks';
import { getAllAggregatedCustomReports } from 'store/modules/Reports/AggregatedCustomReports/slice';
import { useEffect, useState } from 'react';
import { getCustomerUid, getLoggedInUserUid } from 'utils/getUid';

const useAggregatedCustomReports = () => {
  // entities
  const [aggregatedReports, setAggregatedReports] = useState<any[]>([]);
  // const { clientEntityWithCustomFields } = clientEntity();
  // const { projectEntityWithCustomFields } = projectEntity();
  // const { usecaseEntityWithCustomFields } = usecaseEntity();
  // const { taskEntityWithCustomFields } = taskEntity();
  // const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    // setLoading(true);
    getAggregatedReports();
  }, []);

  const getAggregatedReports = async () => {
    const details = {
      customer_uid: getCustomerUid(),
      user_uid: getLoggedInUserUid(),
    };
    const reports = await dispatch(getAllAggregatedCustomReports(details)).unwrap();
    setAggregatedReports([...reports]);
    console.log(reports);
    // setLoading(false);
  };

  // console.log(
  //   clientEntityWithCustomFields,
  //   projectEntityWithCustomFields,
  //   usecaseEntityWithCustomFields,
  //   taskEntityWithCustomFields
  // );

  {
    /* <Typography onClick={() => navigate(`/reports/aggregated-custom-report/${report.report_uid}`)} key={index}>
              {report.report_name}
            </Typography> */
  }

  return {
    aggregatedReports,
    getAggregatedReports,
  };

  // return (
  //   <>
  //     {loading ? (
  //       <CognisaasLoader />
  //     ) : (
  //       <Stack direction={'row'} flexWrap={'wrap'} mt={3}>
  //         {aggregatedReports?.map((reportInfo: any, index: number) => {
  //           return (
  //             <ReportInfoCard
  //               title={reportInfo.report_name}
  //               description={reportInfo.report_description}
  //               onClick={() => navigate(`/reports/aggregated-custom-report/${reportInfo.report_uid}`)}
  //               private={reportInfo.is_private === '1'}
  //               editableByEveryone={reportInfo.global_write_access === '1'}
  //               entities={['aggregated report']}
  //               key={index}
  //             />
  //           );
  //         })}
  //       </Stack>
  //     )}

  //     {/* <div>AggregatedCustomReports</div>
  //     {reports.map((report: any, index: number) => {
  //       return (
  //         <ReportInfoCard
  //           title={report.report_name}
  //           description={report.report_description}
  //           onClick={() => navigate(`/reports/custom-report/${report.report_uid}`)}
  //           private={report.is_private === '1'}
  //           editableByEveryone={report.global_write_access === '1'}
  //           // entities={getEntitiesFromConfig(JSON.parse(report.report_config))}
  //           key={index}
  //         />
  //       );
  //     })} */}
  //   </>
  // );
};

export default useAggregatedCustomReports;
