import { Typography, Stack } from '@mui/material';
import CognisaasSelect from '../../shared/CognisaasSelect/CognisaasSelect';
import { useDispatch } from 'react-redux';
// import { useAppSelector } from '../../../store/hooks';
import { useEffect, useState } from 'react';
// import { fetchClientContacts } from '../../../store/modules/ManageClient/ClientList/slice';
import { useParams } from 'react-router-dom';
import { getAllUsers } from '../../../store/modules/Common/slice';
// import { selectUsersOfCompany } from '../../../store/modules/Common/selector';
// import { selectClientContacts } from '../../../store/modules/ManageClient/ClientList/selector';
import CognisaasButton from '../../shared/CognisaasButton/CognisaasButton';
import { label_task } from 'components/Settings/Preferences/CustomLabelCookieHelper';

const AssignTaskToTeamModal = (props: any) => {
  //   const client = props.client;
  //   const [clientContacts, setClientContacts] = useState<any[]>([]);
  const [selectedUser, setSelectedUser] = useState<string>('Not Assigned');
  const dispatch = useDispatch();
  const [usersOfCompany, setUsersOfCompany] = useState<any[]>([]);
  //   const clientContactList = useAppSelector(selectClientContacts);
  // const allUsers = useAppSelector(selectUsersOfCompany);
  const { preselectedProjectMembers } = props;

  const params: any = useParams();

  useEffect(() => {
    console.log(params);
    dispatch(getAllUsers());

    // dispatch(fetchClientContacts(client.client_uid));
  }, []);

  useEffect(() => {
    if (preselectedProjectMembers.length > 0 || preselectedProjectMembers.length == 0) {
      getUsersList();
    }
  }, [preselectedProjectMembers]);

  const getUsersList = () => {
    const list = preselectedProjectMembers.map((user: any) => user.user_username);
    list.unshift('Not Assigned');
    setUsersOfCompany(list);
  };

  //   useEffect(() => {
  //     if (clientContactList.length > 0 || clientContactList.length == 0) {
  //       getClientList();
  //     }
  //   }, [clientContactList]);

  //   const getClientList = () => {
  //     const list = clientContactList.map((item: any) => item.contact_name);
  //     list.unshift('Not Assigned');
  //     setClientContacts(list);
  //   };

  //   const changeTaskToClient = () => {
  //     // console.log(selectedClientContact);
  //     console.log(clientContacts);
  //   };

  return (
    <Stack>
      <Stack my={2}>
        <Typography variant="h2">Assign {label_task} to team</Typography>
      </Stack>
      <Stack>
        <CognisaasSelect
          shouldBeOpenByDefault={true}
          onChange={(e: any) => {
            console.log(selectedUser, e.target.value);

            setSelectedUser(e.target.value);
          }}
          width="310px"
          value={selectedUser}
          validValues={[...usersOfCompany]}
        ></CognisaasSelect>
      </Stack>
      <Stack sx={{ marginTop: '240px' }} spacing={2} justifyContent="flex-end" alignItems="flex-end" direction="row">
        <CognisaasButton clickHandler={props.onClose} isOutlined={true} label="Cancel" />
        <CognisaasButton
          clickHandler={() => {
            console.log(selectedUser);
            props.onClickingContinue(selectedUser);
            props.onClose();
          }}
          isOutlined={false}
          label="Continue"
        />
      </Stack>
    </Stack>
  );
};

export default AssignTaskToTeamModal;
