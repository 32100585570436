import { apis } from '../axiosInstance';
import { aggregatedCustomReportRoutes } from '../route';

const getGeneratedAggregatedCustomReportUsingUid = (report_uid: string) =>
  apis.phpAxiosInstance.get(aggregatedCustomReportRoutes.getGeneratedAggregatedCustomReportUsingUid(report_uid), {
    headers: { JWT: 'true' },
  });

const getAllAggregatedCustomReports = (details: any) =>
  apis.phpAxiosInstance.get(aggregatedCustomReportRoutes.getAllAggregatedCustomReports(details), {
    headers: { JWT: 'true' },
  });

const getAggregatedCustomReportConfigByUid = (report_uid: string) =>
  apis.phpAxiosInstance.get(aggregatedCustomReportRoutes.getAggregatedCustomReportConfigByUid(report_uid), {
    headers: { JWT: 'true' },
  });

const createAggregatedCustomReport = (detail: any) =>
  apis.phpAxiosInstance.post(aggregatedCustomReportRoutes.createAggregatedCustomReport(), detail, {
    headers: { JWT: 'true' },
  });

const updateAggregatedCustomReport = (detail: any) =>
  apis.phpAxiosInstance.post(aggregatedCustomReportRoutes.updateAggregatedCustomReport(), detail, {
    headers: { JWT: 'true' },
  });

const deleteAggregatedCustomReport = (detail: any) =>
  apis.phpAxiosInstance.post(aggregatedCustomReportRoutes.deleteAggregatedCustomReport(), detail, {
    headers: { JWT: 'true' },
  });

export const AggregatedCustomReportAPI = {
  getGeneratedAggregatedCustomReportUsingUid,
  getAllAggregatedCustomReports,
  getAggregatedCustomReportConfigByUid,
  createAggregatedCustomReport,
  updateAggregatedCustomReport,
  deleteAggregatedCustomReport,
};
