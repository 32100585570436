import { label_client, makeLabelCookies, refreshLabelCookies } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import CognisaasLoader from 'components/shared/CognisaasLoader/CognisaasLoader';
import useCustomNames from 'components/shared/CustomNames/useCustomNames';
import jwtDecode from 'jwt-decode';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { fetchCustomLabel } from 'store/modules/Common/slice';
import {
  client_getClientContactsByClientUid,
  client_getsingleProjectInformationWithTaskClientUser,
  client_getTaskByTaskUid,
  client_userSignIn_withjwttoken,
} from 'store/modules/CustomerPortal/CustomerPortal';
import { selectUserObj, selectUserObjStatus } from 'store/modules/CustomerPortal/selector';
import Cookies from 'universal-cookie';

const env: string = process.env.REACT_APP_ENV ?? 'local';

import { Account, JWTToken } from '../../src/store/types/index';
function setLoginCredentialsInCookies(cookies: Cookies, jwtToken: string, customerCompanyName: string) {
  const maxAge = 596160; //6.9 days ( validity of token is 7 days )
  const decoded_token = jwtDecode<JWTToken>(jwtToken);
  const account = decoded_token.account as Account;
  const clientContactUid = decoded_token.user_id;
  const customerUid = account.account_id;
  const email = decoded_token.email;
  const subdomain = decoded_token.account.subdomain;
  const user_name = decoded_token.username;
  if (env == 'local') {
    cookies.set('customerCompanyName', customerCompanyName, {
      path: '/',
      domain: 'localhost',
      maxAge: maxAge,
    });
    cookies.set('jwt-token', jwtToken, {
      path: '/',
      domain: 'localhost',
      maxAge: maxAge,
    });
    cookies.set('cogni_user_uid', clientContactUid, {
      path: '/',
      domain: 'localhost',
      maxAge: maxAge,
    });
    cookies.set('cogni_customer_uid', customerUid, {
      path: '/',
      domain: 'localhost',
      maxAge: maxAge,
    });
    cookies.set('cogni_user_email', decoded_token.email, {
      path: '/',
      domain: 'localhost',
      maxAge: maxAge,
    });
    cookies.set('isCustomerPortal', 'true', {
      path: '/',
      domain: 'localhost',
      maxAge: maxAge,
    });
    cookies.set('email', email, {
      path: '/',
      domain: 'localhost',
      maxAge: maxAge,
    });
    cookies.set('url', window.location.host + window.location.pathname, {
      path: '/',
      domain: 'localhost',
      maxAge: maxAge,
    });
    cookies.set('subdomain', subdomain, {
      path: '/',
      domain: 'localhost',
      maxAge: maxAge,
    });
    cookies.set('client_user_username', user_name, {
      path: '/',
      domain: 'localhost',
      maxAge: maxAge,
    });
    cookies.set('client_uid', '', {
      path: '/',
      domain: 'localhost',
      maxAge: maxAge,
    });
    cookies.set('user_name', user_name, {
      path: '/',
      domain: 'localhost',
      maxAge: maxAge,
    });
    cookies.set('oneToManyClient', false);

  } else if (env == 'dev') {
    cookies.set('isCustomerPortal', true, {
      path: '/',
      domain: '.devng.cognisaas.com',
      maxAge: maxAge,
    });
    cookies.set('customerCompanyName', customerCompanyName, {
      path: '/',
      domain: '.devng.cognisaas.com',
      maxAge: maxAge,
    });
    cookies.set('jwt-token', jwtToken, {
      path: '/',
      domain: '.devng.cognisaas.com',
      maxAge: maxAge,
    });
    cookies.set('cogni_user_uid', clientContactUid, {
      path: '/',
      domain: '.devng.cognisaas.com',
      maxAge: maxAge,
    });
    cookies.set('cogni_user_email', decoded_token.email, {
      path: '/',
      domain: '.devng.cognisaas.com',
      maxAge: maxAge,
    });
    cookies.set('cogni_customer_uid', customerUid, {
      path: '/',
      domain: '.devng.cognisaas.com',
      maxAge: maxAge,
    });
    cookies.set('isCustomerPortal', 'true', {
      path: '/',
      domain: '.devng.cognisaas.com',
      maxAge: maxAge,
    });
    cookies.set('email', email, {
      path: '/',
      domain: '.devng.cognisaas.com',
      maxAge: maxAge,
    });
    cookies.set('url', window.location.host + window.location.pathname, {
      path: '/',
      domain: '.devng.cognisaas.com',
      maxAge: maxAge,
    });
    cookies.set('subdomain', subdomain, {
      path: '/',
      domain: '.devng.cognisaas.com',
      maxAge: maxAge,
    });
    cookies.set('client_user_username', user_name, {
      path: '/',
      domain: '.devng.cognisaas.com',
      maxAge: maxAge,
    });
    cookies.set('client_uid', '', {
      path: '/',
      domain: '.devng.cognisaas.com',
      maxAge: maxAge,
    });
    cookies.set('user_name', user_name, {
      path: '/',
      domain: '.devng.cognisaas.com',
      maxAge: maxAge,
    });
    cookies.set('oneToManyClient', false);
  } else if (env == 'prod') {
    cookies.set('isCustomerPortal', true, {
      path: '/',
      domain: '.ng.cognisaas.com',
      maxAge: maxAge,
    });
    cookies.set('customerCompanyName', customerCompanyName, {
      path: '/',
      domain: '.ng.cognisaas.com',
      maxAge: maxAge,
    });
    cookies.set('jwt-token', jwtToken, {
      path: '/',
      domain: '.ng.cognisaas.com',
      maxAge: maxAge,
    });
    cookies.set('cogni_user_uid', clientContactUid, {
      path: '/',
      domain: '.ng.cognisaas.com',
      maxAge: maxAge,
    });
    cookies.set('cogni_user_email', decoded_token.email, {
      path: '/',
      domain: '.ng.cognisaas.com',
      maxAge: maxAge,
    });
    cookies.set('cogni_customer_uid', customerUid, {
      path: '/',
      domain: '.ng.cognisaas.com',
      maxAge: maxAge,
    });
    cookies.set('isCustomerPortal', 'true', {
      path: '/',
      domain: '.ng.cognisaas.com',
      maxAge: maxAge,
    });
    cookies.set('email', email, {
      path: '/',
      domain: '.ng.cognisaas.com',
      maxAge: maxAge,
    });
    cookies.set('url', window.location.host + window.location.pathname, {
      path: '/',
      domain: '.ng.cognisaas.com',
      maxAge: maxAge,
    });
    cookies.set('subdomain', subdomain, {
      path: '/',
      domain: '.ng.cognisaas.com',
      maxAge: maxAge,
    });
    cookies.set('client_user_username', user_name, {
      path: '/',
      domain: '.ng.cognisaas.com',
      maxAge: maxAge,
    });
    cookies.set('client_uid', '', {
      path: '/',
      domain: '.ng.cognisaas.com',
      maxAge: maxAge,
    });
    cookies.set('user_name', user_name, {
      path: '/',
      domain: '.ng.cognisaas.com',
      maxAge: maxAge,
    });
    cookies.set('oneToManyClient', false);
  } else if (env == 'new-prod') {
    cookies.set('isCustomerPortal', true, {
      path: '/',
      domain: '.cognisaas.net',
      maxAge: maxAge,
    });
    cookies.set('customerCompanyName', customerCompanyName, {
      path: '/',
      domain: '.cognisaas.net',
      maxAge: maxAge,
    });
    cookies.set('jwt-token', jwtToken, {
      path: '/',
      domain: '.cognisaas.net',
      maxAge: maxAge,
    });
    cookies.set('cogni_user_uid', clientContactUid, {
      path: '/',
      domain: '.cognisaas.net',
      maxAge: maxAge,
    });
    cookies.set('cogni_user_email', decoded_token.email, {
      path: '/',
      domain: '.cognisaas.net',
      maxAge: maxAge,
    });
    cookies.set('cogni_customer_uid', customerUid, {
      path: '/',
      domain: '.cognisaas.net',
      maxAge: maxAge,
    });
    cookies.set('isCustomerPortal', 'true', {
      path: '/',
      domain: '.cognisaas.net',
      maxAge: maxAge,
    });
    cookies.set('email', email, {
      path: '/',
      domain: '.cognisaas.net',
      maxAge: maxAge,
    });
    cookies.set('url', window.location.host + window.location.pathname, {
      path: '/',
      domain: '.cognisaas.net',
      maxAge: maxAge,
    });
    cookies.set('subdomain', subdomain, {
      path: '/',
      domain: '.cognisaas.net',
      maxAge: maxAge,
    });
    cookies.set('client_user_username', user_name, {
      path: '/',
      domain: '.cognisaas.net',
      maxAge: maxAge,
    });
    cookies.set('client_uid', '', {
      path: '/',
      domain: '.cognisaas.net',
      maxAge: maxAge,
    });
    cookies.set('user_name', user_name, {
      path: '/',
      domain: '.cognisaas.net',
      maxAge: maxAge,
    });
    cookies.set('oneToManyClient', false);
  }
}

const CustomerPortal = () => {
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const url: any = window.location.pathname;
  const navigate: any = useNavigate();
  const appDispatch = useAppDispatch();
  const { loadCustomNamesModule } = useCustomNames();
  const userObject: any = useAppSelector(selectUserObj);
  const userObjectStatus: any = useAppSelector(selectUserObjStatus);
  const setCustomLabelCookies = async () => {
    await appDispatch(fetchCustomLabel())
      .unwrap()
      .then((data: any) => {
        console.log('data after success', data);
        if (data.status === 200) {
          if (data.data.result.length > 0) {
            makeLabelCookies(data.data.result);
            refreshLabelCookies();
            loadCustomNamesModule();
          } else if (data.data.result.length === 0) {
            makeLabelCookies([]);
            refreshLabelCookies();
            loadCustomNamesModule();
          }
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const getprojectInfoWithTaskUid = async (taskUid: string) => {

    const taskDetail: any = await dispatch(client_getTaskByTaskUid(taskUid));
    console.log(taskDetail.payload);
    loadCustomNamesModule();
    dispatch(client_getClientContactsByClientUid(taskDetail.payload.client_uid));
    const projectDetail: any = await dispatch(
      client_getsingleProjectInformationWithTaskClientUser(taskDetail.payload.project_uid)
    );
    navigate('/customer-portal/project/dashboard', {
      state: { selectedProject: { ...projectDetail.payload.result }, openDrawer: { ...taskDetail.payload } },
    });

  };

  React.useEffect(() => {
    const params = url.split('/');
    const customerCompanyName = params[2];
    const subUrl = params[3];
    let isAskingForTaskAccess = '';
    if (params.length > 4 && params[5] == 'taskUid') {
      isAskingForTaskAccess = params[params.length - 1];
    }

    //If signup call

    if (subUrl == 'magic-link' || subUrl == 'magic_link') {
      if (isAskingForTaskAccess.length) {
        const jwt = params[params.length - 3];
        setLoginCredentialsInCookies(cookies, jwt, customerCompanyName);
        dispatch(client_userSignIn_withjwttoken(params[params.length - 3]));
        // loadCustomNamesModule();
        if (isAskingForTaskAccess.length) {
          getprojectInfoWithTaskUid(params[params.length - 1]);
        }
      } else {
        const jwt = params[params.length - 1];
        setLoginCredentialsInCookies(cookies, jwt, customerCompanyName);
        dispatch(client_userSignIn_withjwttoken(params[params.length - 1]));
        // loadCustomNamesModule();
      }
    }


    //If any other call
    if (subUrl === 'milestone-csat-rating') {
      const jwtToken = params[8];
      console.log('jwtToken', jwtToken);
      setLoginCredentialsInCookies(cookies, jwtToken, customerCompanyName);
      loadCustomNamesModule();
      navigate('/customer-portal/csat-rating', {
        state: {
          csat_rating_type: params[3],
          task_type: params[4],
          task_type_uid: params[5],
          task_uid: params[6],
          csat_uid: params[7],
          jwt_token: jwtToken,
          stars: params[9] ? params[9] : null,
        },
      });
      return;
    }
    if (subUrl === 'csat-rating') {
      const jwtToken = params[7];
      setLoginCredentialsInCookies(cookies, jwtToken, customerCompanyName);
      loadCustomNamesModule();
      navigate('/customer-portal/csat-rating', {
        state: {
          csat_rating_type: params[3],
          task_type: params[4],
          task_uid: params[5],
          csat_uid: params[6],
          jwt_token: jwtToken,
          stars: params[8] ? params[8] : null,
        },
      });
      return;
    }
    if (subUrl == 'task-progress-change') {
      const jwtToken = params[7];
      setLoginCredentialsInCookies(cookies, jwtToken, customerCompanyName);
      loadCustomNamesModule();
      console.log('Navigating');
      navigate('/customer-portal/task-progress-change/', {
        state: {
          task_uid: params[4],
          contact_uid: params[5],
          stage: params[6],
        },
      });
      return;
    }
  }, []);

  useEffect(() => {
    setCustomLabelCookies();
  }, []);

  useEffect(() => {
    if (userObjectStatus == 'success' && cookies.get('jwt-token') && label_client) {
      if (userObject.length > 1) {
        setCustomLabelCookies();
        cookies.set('oneToManyClient', true);
        navigate('/customer-portal/landing');
      } else {
        navigate('/customer-portal/dashboard', { replace: true });
      }
    }
  }, [userObject, userObjectStatus, cookies.get('jwt-token'), label_client]);

  return (
    <>
      <CognisaasLoader />
    </>
  );
};

export default CustomerPortal;
