import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  GridRowsProp,
  GridColDef,
  GridRenderEditCellParams,
  GridCellParams,
  // GridCellEditStopParams,
  // MuiEvent,
  // GridRowEditStopParams,
  // GridRenderCellParams,
  // useGridApiContext,
  // useGridApiRef,
  // GridRowId,
  // GridRowParams,
  GridCellModesModel,
  GridCellModes,
  // GridRenderCellParams,
} from '@mui/x-data-grid-premium';
import { DataGridPremium } from '@mui/x-data-grid-premium';
// import ExcelAutoComplete from './editors/ExcelAutoComplete';
import { ExcelDropdown } from './editors/ExcelDropdown';
// import { SelectChangeEvent, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
// import { SelectEditDropdownCell } from 'components/shared/Tables/InlineEditTable';
import { ExcelTextEditor } from './editors/ExcelTextEditor';
import { updateProjectTemplateExcelData } from 'store/modules/Templates/Templates';
import { useAppDispatch } from 'store/hooks';
import { selectCreateProjectTemplateExcelData } from 'store/modules/Templates/selector';
import { useAppSelector } from 'store/hooks';
import { selectUsersOfCompany } from 'store/modules/Common/selector';
// import CognisaasButton from 'components/shared/CognisaasButton/CognisaasButton';
import { Button, IconButton, Stack } from '@mui/material';
// import { Checkbox } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ExcelAutoComplete from './editors/ExcelAutoComplete';
import { ExcelUserSelect } from './editors/ExcelUserSelect';
import useUserDropDownData from 'components/shared/Hooks/useUserDropDownData';
// import { useNavigate } from 'react-router-dom';

export default function AddProjectTemplatePlanExcel() {
  // const [rows, setRows] = useState<any[]>([]);
  const [usersOfCompany, setUsersOfCompany] = useState<any[]>([]);
  const excelData = useAppSelector(selectCreateProjectTemplateExcelData);
  const users = useAppSelector(selectUsersOfCompany);
  const [cellModesModel, setCellModesModel] = React.useState<GridCellModesModel>({});
  const companyUsersDropDownData = useUserDropDownData('company');
  const [phaseNames, setPhaseNames] = useState<any[]>([]);
  const dispatch = useAppDispatch();
  // const navigate = useNavigate();

  useEffect(() => {
    initiateTable();
    setUsersData();
  }, []);

  const makePhaseNamesArray = (data: any) => {
    // const phases = rows.map((r: any) => {
    //   console.log(r);
    //   if (r?.phase_name.length > 0) {
    //     return r.phase_name;
    //   }
    //   return;
    // });
    console.log(data, usersOfCompany);
    const phases: any = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i].phase_name.length > 0) {
        phases.push(data[i].phase_name);
      }
    }
    console.log(phases);
    // const myArray = [phases];

    const unique = Array.from(new Set(phases));

    console.log(unique);
    setPhaseNames([...unique]);
  };

  const setUsersData = () => {
    const data = users.filter((user: any) => user.is_active === '1').map((user: any) => user.user_username);
    data.unshift('Not Assigned');
    setUsersOfCompany([...data]);
  };

  // useEffect(() => {
  //   dispatch(updateProjectTemplateExcelData([...rows]));
  // }, [rows]);

  // function SelectSmall(props: any) {
  //   const { id, value, field } = props;
  //   // const apiRef = useGridApiContext();

  //   const [age, setAge] = React.useState('');

  //   const handleChange = (event: SelectChangeEvent, newValue: any | null) => {
  //     console.log(newValue);
  //     apiRef.current.setEditCellValue({ id, field, value: newValue });
  //   };

  //   return (
  //     <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
  //       {/* <InputLabel id="demo-select-small">Age</InputLabel> */}
  //       <Select
  //         labelId="demo-select-small"
  //         id="demo-select-small"
  //         value={value}
  //         label="Age"
  //         onChange={(e: SelectChangeEvent, v: any) => handleChange(e, v)}
  //       >
  //         <MenuItem>Internal</MenuItem>
  //         <MenuItem>External</MenuItem>
  //       </Select>
  //     </FormControl>
  //   );
  // }

  // const RenderSelectEditInputCell: any = (params: any) => {
  //   return <SelectSmall {...params} />;
  // };

  const handleInlineEditValue = (value: any, id: string, field: string) => {
    console.log('djkejfke');
    // if (+id != 0) {
    console.log(value, id, field);
    // console.log(rows);
    const t = JSON.parse(JSON.stringify(excelData));
    const index: number = +id;
    console.log(t[index - 1]);
    t[index - 1][field] = value;
    // Object.assign(obj1, obj2)
    console.log(t);
    // setRows([...t]);
    // const storeRows = [...t];
    dispatch(updateProjectTemplateExcelData([...t]));
    makePhaseNamesArray(t);
    // }
  };

  const rowst: GridRowsProp = [
    {
      id: 1,
      phase_name: 'Phase 1',
      task_name: 'Task 1',
      task_type: '',
      duration: '',
      predecessors: '',
      task_owner: '',
      share_with_client: '',
      milestone: '',
      implementation_fee: '',
    },
    { id: 2, phase_name: 'Phase 1', task_name: 'Task 1', task_type: '0', duration: '10', predecessors: '' },
    { id: 3, phase_name: 'Phase 1', task_name: 'Task 1', task_type: '0', duration: '10', predecessors: '' },
  ];

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 80 },
    // {
    //   field: 'milestone',
    //   headerName: 'Milestone task',
    //   width: 50,
    //   renderCell: (params: GridRenderCellParams) => {
    //     console.log(params.value);
    //     return (
    //       <Checkbox
    //         // onChange={(e: any) => {
    //         //   console.log(e.target.checked);
    //         //   handleInlineEditValue(e.target.checked, params.id as string, 'milestone');
    //         // }}
    //         checked={params.value}
    //       />
    //     );
    //   },
    //   editable: true,
    //   renderEditCell: (params: GridRenderEditCellParams) => (
    //     <Checkbox
    //       onChange={(e: any) => {
    //         console.log(e.target.checked);
    //         handleInlineEditValue(e.target.checked, params.id as string, 'milestone');
    //       }}
    //     />
    //   ),
    // },
    {
      field: 'task_name',
      headerName: 'Task Name',
      width: 250,
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams) => ExcelTextEditor(params, handleInlineEditValue),
    },
    {
      field: 'phase_name',
      headerName: 'Phase Name',
      width: 250,
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams) =>
        ExcelAutoComplete(params, phaseNames, handleInlineEditValue),
    },

    {
      field: 'task_type',
      headerName: 'Task Type',
      width: 250,
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams) =>
        ExcelDropdown(params, ['Internal', 'External'] || [], handleInlineEditValue),
    },
    {
      field: 'duration',
      headerName: 'Target Duration',
      width: 150,
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams) => ExcelTextEditor(params, handleInlineEditValue, 'duration'),
    },
    {
      field: 'predecessors',
      headerName: 'Dependency',
      width: 150,
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams) => ExcelTextEditor(params, handleInlineEditValue),
    },
    {
      field: 'implementation_fee',
      headerName: 'Implementation Fee',
      width: 150,
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams) => ExcelTextEditor(params, handleInlineEditValue),
    },
    {
      field: 'task_owner',
      headerName: 'Task Owner',
      width: 250,
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams) =>
        ExcelUserSelect(params, companyUsersDropDownData || [], handleInlineEditValue),
    },
    {
      field: 'task_priority',
      headerName: 'Priority',
      width: 150,
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams) =>
        ExcelDropdown(params, ['Critical', 'High', 'Medium', 'Low'] || [], handleInlineEditValue),
    },
    {
      field: 'share_with_client',
      headerName: 'Share with client',
      width: 150,
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams) =>
        ExcelDropdown(params, ['Yes', 'No'] || [], handleInlineEditValue),
    },
    {
      field: 'milestone',
      headerName: 'Milestone Task',
      width: 150,
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams) =>
        ExcelDropdown(params, ['Yes', 'No'] || [], handleInlineEditValue),
    },
  ];

  // const updateTaskType = (params: GridCellParams, value: string) => {
  //   // console.log(params, value);
  //   // const data: any[] = [];
  //   // for (let i = 1; i <= rows.length; i++) {
  //   //   console.log(rows[i]);
  //   //   if (rows[i].id == params.id) {
  //   //     console.log('SAME ID');
  //   //     rows[i].task_type = value;
  //   //     data.push(rows[i]);
  //   //   } else {
  //   //     data.push(rows[i]);
  //   //   }
  //   // }
  //   // console.log(data);
  //   const data: any[] = [...rows];
  //   const ind: number = params.id as number;
  //   console.log(ind);
  //   data[ind - 1].task_type = value;
  //   console.log(data);
  //   setRows([...data]);
  // };

  const initiateTable = () => {
    // insert 200 rows
    const data: any = [];
    for (let i = 1; i <= 200; i++) {
      data.push({ ...rowst[0], id: i, phase_name: '', task_name: '', task_priority: '' });
    }
    dispatch(updateProjectTemplateExcelData([...data]));
    // setRows([...data]);
  };

  // const handleClick = (params: GridRowParams<any>) => {
  //   console.log(params);
  //   const apiRef = useGridApiContext();
  //   // const apiRef = useGridApiRef();
  //   apiRef.current.startRowEditMode({ id: params.id });
  // };

  // const addPhaseNameToCell = (params: any) => {
  //   const t = [...rows];
  //   console.log(params.id);
  //   if (t.length > 0) {
  //     if (params.id > 1) {
  //       console.log('ID', params.id);
  //       const id = (params.id as number) - 1;
  //       const prevPhaseId: number = (params.id as number) - 2;
  //       console.log(t, prevPhaseId);
  //       const prevPhaseName = t[prevPhaseId]?.phase_name ? t[prevPhaseId]?.phase_name : '';
  //       console.log('PREV', prevPhaseName, id, prevPhaseId);
  //       // if (t[id]?.phase_name) {
  //       t[id].phase_name = prevPhaseName;
  //       // }

  //       setRows([...t]);
  //       console.log(rows);
  //     } else {
  //       console.log('ELSEE');
  //       // setRows([...t]);
  //     }
  //   }
  // };

  // const handleUpdate = (value: any, id: any, field: any) => {
  //   console.log('UPDATED VALUES:', value, id, field);
  // };

  const handleCellClick = React.useCallback((params: GridCellParams) => {
    setCellModesModel((prevModel) => {
      return {
        // Revert the mode of the other cells from other rows
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: Object.keys(prevModel[id]).reduce(
              (acc2, field) => ({
                ...acc2,
                [field]: { mode: GridCellModes.View },
              }),
              {}
            ),
          }),
          {}
        ),
        [params.id]: {
          // Revert the mode of other cells in the same row
          ...Object.keys(prevModel[params.id] || {}).reduce(
            (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
            {}
          ),
          [params.field]: { mode: GridCellModes.Edit },
        },
      };
    });
    // addPhaseNameToCell(params);
  }, []);

  const handleCellModesModelChange = React.useCallback((newModel) => {
    console.log(newModel);
    // const det = {
    //   id: Object.keys(newModel)[Object.keys(newModel).length - 1],
    // };
    // addPhaseNameToCell(det);

    setCellModesModel(newModel);
  }, []);

  return (
    <div style={{ height: 800, width: '100%' }}>
      <Stack my={2} ml={'85%'} direction="column" justifyContent="flex-start" alignItems="flex-end">
        <Stack
          onClick={() => {
            console.log('OPEN');
            window.open('/plan/sample-excel-view', '_blank');
          }}
          direction="row"
        >
          <Button variant="text">Show sample</Button>
          <IconButton disableRipple size="small">
            <OpenInNewIcon fontSize="inherit" />
          </IconButton>
        </Stack>
      </Stack>

      <DataGridPremium
        // editMode="row"
        initialState={{ pinnedColumns: { left: ['id'] } }}
        cellModesModel={cellModesModel}
        onCellModesModelChange={handleCellModesModelChange}
        onCellClick={handleCellClick}
        experimentalFeatures={{ newEditingApi: true }}
        rowHeight={43}
        rows={excelData}
        columns={columns}
        // rowReordering
        showCellRightBorder={true}
      />
    </div>
  );
}
