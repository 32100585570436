import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { taskAPI } from '../../../../api/modules/tasks';
import { requestState } from '../../types';

interface closedTasksType {
  getClosedTasks: Array<any>;
  getClosedTasksStatus: requestState;
  getClosedTasksError: null | undefined | string;
}

export const getClosedTasks = createAsyncThunk('/getClosedTasks', async () => {
  const openClientTasksData = await taskAPI.getClientOpenTasks();
  const closedClientTasksData = await taskAPI.getClientClosedTasks();
  const data = [...openClientTasksData.data.result, ...closedClientTasksData.data.result];
  return data ?? [];
});

const initialState: closedTasksType = {
  getClosedTasks: [],
  getClosedTasksStatus: requestState.idle,
  getClosedTasksError: null,
};

const closedTasksSlice = createSlice({
  name: 'getClosedTasks',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getClosedTasks.pending, (state) => {
        state.getClosedTasksStatus = requestState.loading;
      })
      .addCase(getClosedTasks.fulfilled, (state, action) => {
        state.getClosedTasksStatus = requestState.success;
        state.getClosedTasks = action.payload;
      })
      .addCase(getClosedTasks.rejected, (state, action) => {
        state.getClosedTasksError = action.error.message;
        state.getClosedTasksStatus = requestState.failed;
      });
  },
});

export default closedTasksSlice.reducer;
