export const tempData = [
    {
      project_name: 'Onboarding Project',
      project_uid: '6172c3b9e8730',
      association_uid: '6082d17bbb552',
      case_status_reason: 'Product feature mapping is not done for this use case',
      case_expansionvalue: '0',
      case_priority: 'Medium',
      case_uid: '60c5c42805aba',
      case_description: '',
      case_comment: '',
      case_name: 'MI2',
      case_readinessstate_status: 'red',
      case_needed_status: 'Expand',
      case_requiredbydate: '2111-03-03 00:00:00',
      case_stage: 'Design',
      case_deliverymanager: 'Not Assigned',
      client_status_reason: 'Delivery of one or more projects of this client is not planned or is overdue',
      client_uid: '6082d17bbb548',
      client_account_owner: 'shubham',
      client_name: 'SIP',
      client_account_worth: '1000',
      client_readiness_status: 'red',
      imported_template: '1',
      user_uid: '612f56652e9c6',
      case_planned_startdate: '2021-06-13 00:00:00',
      case_startdate: '2021-06-13 00:00:00',
      case_deliverydate: '0000-00-00 00:00:00',
      eta_forcasedelivery: '2111-03-03 00:00:00',
      custom_values: [
        {
          usecase_field_id: '462',
          field_uid: '60ed2baaa1418',
          customer_uid: '5ef9a05fa797e',
          case_uid: '60c5c42805aba',
          field_seq_num: '1',
          field_type: 'RAG status',
          field_name: 'Scope Product',
          field_value: '',
        },
        {
          usecase_field_id: '1714',
          field_uid: '60eeafc709bdb',
          customer_uid: '5ef9a05fa797e',
          case_uid: '60c5c42805aba',
          field_seq_num: '2',
          field_type: 'String',
          field_name: 'R12',
          field_value: '',
        },
        {
          usecase_field_id: '3183',
          field_uid: '60f16ef5c98bf',
          customer_uid: '5ef9a05fa797e',
          case_uid: '60c5c42805aba',
          field_seq_num: '3',
          field_type: 'RAG status',
          field_name: 'sub',
          field_value: '',
        },
        {
          usecase_field_id: '4449',
          field_uid: '60f52acb5cca6',
          customer_uid: '5ef9a05fa797e',
          case_uid: '60c5c42805aba',
          field_seq_num: '5',
          field_type: 'Dropdown',
          field_name: 'cd',
          field_value: '',
        },
        {
          usecase_field_id: '8240',
          field_uid: '6124cab26b9a6',
          customer_uid: '5ef9a05fa797e',
          case_uid: '60c5c42805aba',
          field_seq_num: '6',
          field_type: 'Number',
          field_name: 'Numb',
          field_value: '',
        },
        {
          usecase_field_id: '8938',
          field_uid: '6124cacbd9f87',
          customer_uid: '5ef9a05fa797e',
          case_uid: '60c5c42805aba',
          field_seq_num: '7',
          field_type: 'Date',
          field_name: 'Estimated Date',
          field_value: '',
        },
        {
          usecase_field_id: '9636',
          field_uid: '6124cb9f8a547',
          customer_uid: '5ef9a05fa797e',
          case_uid: '60c5c42805aba',
          field_seq_num: '8',
          field_type: 'Hyperlink',
          field_name: 'Hyper',
          field_value: '',
        },
        {
          usecase_field_id: '10877',
          field_uid: '613718a80b9f1',
          customer_uid: '5ef9a05fa797e',
          case_uid: '60c5c42805aba',
          field_seq_num: '9',
          field_type: 'RAG status',
          field_name: 'nik',
          field_value: '',
        },
        {
          usecase_field_id: '11601',
          field_uid: '613720c421d73',
          customer_uid: '5ef9a05fa797e',
          case_uid: '60c5c42805aba',
          field_seq_num: '10',
          field_type: 'String',
          field_name: 'nkp',
          field_value: '',
        },
        {
          usecase_field_id: '12426',
          field_uid: '613b591dd5bee',
          customer_uid: '5ef9a05fa797e',
          case_uid: '60c5c42805aba',
          field_seq_num: '11',
          field_type: 'RAG status',
          field_name: 'Check',
          field_value: '',
        },
        {
          usecase_field_id: '14079',
          field_uid: '61642c89a72f6',
          customer_uid: '5ef9a05fa797e',
          case_uid: '60c5c42805aba',
          field_seq_num: '12',
          field_type: 'String',
          field_name: 'gfg',
          field_value: '',
        },
        {
          usecase_field_id: '21443',
          field_uid: '623e9baeea87d',
          customer_uid: '5ef9a05fa797e',
          case_uid: '60c5c42805aba',
          field_seq_num: '13',
          field_type: 'RAG status',
          field_name: 'kmk,m,m',
          field_value: '',
        },
        {
          usecase_field_id: '22491',
          field_uid: '623e9bcb85ae3',
          customer_uid: '5ef9a05fa797e',
          case_uid: '60c5c42805aba',
          field_seq_num: '14',
          field_type: 'RAG status',
          field_name: 'kmk,m,m',
          field_value: '',
        },
        {
          usecase_field_id: '23565',
          field_uid: '62419fc187208',
          customer_uid: '5ef9a05fa797e',
          case_uid: '60c5c42805aba',
          field_seq_num: '15',
          field_type: 'RAG status',
          field_name: 'new12',
          field_value: '',
        },
      ],
    },
    {
      project_name: 'Onboarding Project',
      project_uid: '6172c3ba2ed73',
      association_uid: '608c0cf95ad8f',
      case_status_reason: 'Product feature mapping is not done for this use case',
      case_expansionvalue: '0',
      case_priority: 'Medium',
      case_uid: '60c2ddcf878ee',
      case_description: '',
      case_comment: '',
      case_name: 'MI2',
      case_readinessstate_status: 'red',
      case_needed_status: 'Expand',
      case_requiredbydate: '2111-02-27 00:00:00',
      case_stage: 'Pre-Sales',
      case_deliverymanager: 'Not Assigned',
      client_status_reason: 'Delivery of one or more projects of this client is not planned or is overdue',
      client_uid: '608c0cf95ad85',
      client_account_owner: 'Aditya Bagal',
      client_name: 'A123',
      client_account_worth: '0',
      client_readiness_status: 'red',
      imported_template: '1',
      user_uid: '5f0c256808981',
      case_planned_startdate: '2021-06-11 00:00:00',
      case_startdate: '2021-06-11 00:00:00',
      case_deliverydate: '0000-00-00 00:00:00',
      eta_forcasedelivery: '2111-02-27 00:00:00',
      custom_values: [
        {
          usecase_field_id: '496',
          field_uid: '60ed2baaa1418',
          customer_uid: '5ef9a05fa797e',
          case_uid: '60c2ddcf878ee',
          field_seq_num: '1',
          field_type: 'RAG status',
          field_name: 'Scope Product',
          field_value: '',
        },
        {
          usecase_field_id: '1748',
          field_uid: '60eeafc709bdb',
          customer_uid: '5ef9a05fa797e',
          case_uid: '60c2ddcf878ee',
          field_seq_num: '2',
          field_type: 'String',
          field_name: 'R12',
          field_value: '',
        },
        {
          usecase_field_id: '3217',
          field_uid: '60f16ef5c98bf',
          customer_uid: '5ef9a05fa797e',
          case_uid: '60c2ddcf878ee',
          field_seq_num: '3',
          field_type: 'RAG status',
          field_name: 'sub',
          field_value: '',
        },
        {
          usecase_field_id: '4483',
          field_uid: '60f52acb5cca6',
          customer_uid: '5ef9a05fa797e',
          case_uid: '60c2ddcf878ee',
          field_seq_num: '5',
          field_type: 'Dropdown',
          field_name: 'cd',
          field_value: '',
        },
        {
          usecase_field_id: '8274',
          field_uid: '6124cab26b9a6',
          customer_uid: '5ef9a05fa797e',
          case_uid: '60c2ddcf878ee',
          field_seq_num: '6',
          field_type: 'Number',
          field_name: 'Numb',
          field_value: '',
        },
        {
          usecase_field_id: '8972',
          field_uid: '6124cacbd9f87',
          customer_uid: '5ef9a05fa797e',
          case_uid: '60c2ddcf878ee',
          field_seq_num: '7',
          field_type: 'Date',
          field_name: 'Estimated Date',
          field_value: '',
        },
        {
          usecase_field_id: '9670',
          field_uid: '6124cb9f8a547',
          customer_uid: '5ef9a05fa797e',
          case_uid: '60c2ddcf878ee',
          field_seq_num: '8',
          field_type: 'Hyperlink',
          field_name: 'Hyper',
          field_value: '',
        },
        {
          usecase_field_id: '10912',
          field_uid: '613718a80b9f1',
          customer_uid: '5ef9a05fa797e',
          case_uid: '60c2ddcf878ee',
          field_seq_num: '9',
          field_type: 'RAG status',
          field_name: 'nik',
          field_value: '',
        },
        {
          usecase_field_id: '11636',
          field_uid: '613720c421d73',
          customer_uid: '5ef9a05fa797e',
          case_uid: '60c2ddcf878ee',
          field_seq_num: '10',
          field_type: 'String',
          field_name: 'nkp',
          field_value: '',
        },
        {
          usecase_field_id: '12461',
          field_uid: '613b591dd5bee',
          customer_uid: '5ef9a05fa797e',
          case_uid: '60c2ddcf878ee',
          field_seq_num: '11',
          field_type: 'RAG status',
          field_name: 'Check',
          field_value: '',
        },
        {
          usecase_field_id: '14114',
          field_uid: '61642c89a72f6',
          customer_uid: '5ef9a05fa797e',
          case_uid: '60c2ddcf878ee',
          field_seq_num: '12',
          field_type: 'String',
          field_name: 'gfg',
          field_value: '',
        },
        {
          usecase_field_id: '21502',
          field_uid: '623e9baeea87d',
          customer_uid: '5ef9a05fa797e',
          case_uid: '60c2ddcf878ee',
          field_seq_num: '13',
          field_type: 'RAG status',
          field_name: 'kmk,m,m',
          field_value: '',
        },
        {
          usecase_field_id: '22550',
          field_uid: '623e9bcb85ae3',
          customer_uid: '5ef9a05fa797e',
          case_uid: '60c2ddcf878ee',
          field_seq_num: '14',
          field_type: 'RAG status',
          field_name: 'kmk,m,m',
          field_value: '',
        },
        {
          usecase_field_id: '23624',
          field_uid: '62419fc187208',
          customer_uid: '5ef9a05fa797e',
          case_uid: '60c2ddcf878ee',
          field_seq_num: '15',
          field_type: 'RAG status',
          field_name: 'new12',
          field_value: '',
        },
      ],
    },
    {
      project_name: 'Onboarding Project',
      project_uid: '6172c3bae828a',
      association_uid: '60c2e8ad5204f',
      case_status_reason: 'Product feature mapping is not done for this use case',
      case_expansionvalue: '0',
      case_priority: 'Medium',
      case_uid: '60c2e9ae120f1',
      case_description: '',
      case_comment: '',
      case_name: 'MI2',
      case_readinessstate_status: 'red',
      case_needed_status: 'Land',
      case_requiredbydate: '2111-02-27 00:00:00',
      case_stage: 'Pre-Sales',
      case_deliverymanager: 'Not Assigned',
      client_status_reason: 'Delivery of one or more projects of this client is not planned or is overdue',
      client_uid: '60c2e8ad52045',
      client_account_owner: 'Aditya Bagal',
      client_name: 'CongniSaaS Technologies Pvt Limited',
      client_account_worth: '0',
      client_readiness_status: 'red',
      imported_template: '1',
      user_uid: '5f0c256808981',
      case_planned_startdate: '2021-06-11 00:00:00',
      case_startdate: '2021-06-11 00:00:00',
      case_deliverydate: '0000-00-00 00:00:00',
      eta_forcasedelivery: '2111-02-27 00:00:00',
      custom_values: [
        {
          usecase_field_id: '582',
          field_uid: '60ed2baaa1418',
          customer_uid: '5ef9a05fa797e',
          case_uid: '60c2e9ae120f1',
          field_seq_num: '1',
          field_type: 'RAG status',
          field_name: 'Scope Product',
          field_value: '',
        },
        {
          usecase_field_id: '1837',
          field_uid: '60eeafc709bdb',
          customer_uid: '5ef9a05fa797e',
          case_uid: '60c2e9ae120f1',
          field_seq_num: '2',
          field_type: 'String',
          field_name: 'R12',
          field_value: '',
        },
        {
          usecase_field_id: '3309',
          field_uid: '60f16ef5c98bf',
          customer_uid: '5ef9a05fa797e',
          case_uid: '60c2e9ae120f1',
          field_seq_num: '3',
          field_type: 'RAG status',
          field_name: 'sub',
          field_value: '',
        },
        {
          usecase_field_id: '4575',
          field_uid: '60f52acb5cca6',
          customer_uid: '5ef9a05fa797e',
          case_uid: '60c2e9ae120f1',
          field_seq_num: '5',
          field_type: 'Dropdown',
          field_name: 'cd',
          field_value: '',
        },
        {
          usecase_field_id: '8374',
          field_uid: '6124cab26b9a6',
          customer_uid: '5ef9a05fa797e',
          case_uid: '60c2e9ae120f1',
          field_seq_num: '6',
          field_type: 'Number',
          field_name: 'Numb',
          field_value: '',
        },
        {
          usecase_field_id: '9072',
          field_uid: '6124cacbd9f87',
          customer_uid: '5ef9a05fa797e',
          case_uid: '60c2e9ae120f1',
          field_seq_num: '7',
          field_type: 'Date',
          field_name: 'Estimated Date',
          field_value: '',
        },
        {
          usecase_field_id: '9770',
          field_uid: '6124cb9f8a547',
          customer_uid: '5ef9a05fa797e',
          case_uid: '60c2e9ae120f1',
          field_seq_num: '8',
          field_type: 'Hyperlink',
          field_name: 'Hyper',
          field_value: '',
        },
        {
          usecase_field_id: '11016',
          field_uid: '613718a80b9f1',
          customer_uid: '5ef9a05fa797e',
          case_uid: '60c2e9ae120f1',
          field_seq_num: '9',
          field_type: 'RAG status',
          field_name: 'nik',
          field_value: '',
        },
        {
          usecase_field_id: '11740',
          field_uid: '613720c421d73',
          customer_uid: '5ef9a05fa797e',
          case_uid: '60c2e9ae120f1',
          field_seq_num: '10',
          field_type: 'String',
          field_name: 'nkp',
          field_value: '',
        },
        {
          usecase_field_id: '12566',
          field_uid: '613b591dd5bee',
          customer_uid: '5ef9a05fa797e',
          case_uid: '60c2e9ae120f1',
          field_seq_num: '11',
          field_type: 'RAG status',
          field_name: 'Check',
          field_value: '',
        },
        {
          usecase_field_id: '14219',
          field_uid: '61642c89a72f6',
          customer_uid: '5ef9a05fa797e',
          case_uid: '60c2e9ae120f1',
          field_seq_num: '12',
          field_type: 'String',
          field_name: 'gfg',
          field_value: '',
        },
        {
          usecase_field_id: '21618',
          field_uid: '623e9baeea87d',
          customer_uid: '5ef9a05fa797e',
          case_uid: '60c2e9ae120f1',
          field_seq_num: '13',
          field_type: 'RAG status',
          field_name: 'kmk,m,m',
          field_value: '',
        },
        {
          usecase_field_id: '22666',
          field_uid: '623e9bcb85ae3',
          customer_uid: '5ef9a05fa797e',
          case_uid: '60c2e9ae120f1',
          field_seq_num: '14',
          field_type: 'RAG status',
          field_name: 'kmk,m,m',
          field_value: '',
        },
        {
          usecase_field_id: '23740',
          field_uid: '62419fc187208',
          customer_uid: '5ef9a05fa797e',
          case_uid: '60c2e9ae120f1',
          field_seq_num: '15',
          field_type: 'RAG status',
          field_name: 'new12',
          field_value: '',
        },
      ],
    },
    {
      project_name: 'Onboarding Project',
      project_uid: '6172c3b9df77f',
      association_uid: '60810dd245542',
      case_status_reason: 'Product feature mapping is not done for this use case',
      case_expansionvalue: '0',
      case_priority: 'Medium',
      case_uid: '6086bfd38bae8',
      case_description: '',
      case_comment: '',
      case_name: 'MI2',
      case_readinessstate_status: 'red',
      case_needed_status: 'Land',
      case_requiredbydate: '2111-01-12 00:00:00',
      case_stage: 'Pre-Sales',
      case_deliverymanager: 'Not Assigned',
      client_status_reason: 'Delivery of one or more projects of this client is not planned or is overdue',
      client_uid: '60810dd245537',
      client_account_owner: 'Abhay Agarwal',
      client_name: 'Swiggyh',
      client_account_worth: '0',
      client_readiness_status: 'red',
      imported_template: '1',
      user_uid: '5f0c256808981',
      case_planned_startdate: '2021-04-26 00:00:00',
      case_startdate: '2021-04-26 00:00:00',
      case_deliverydate: '0000-00-00 00:00:00',
      eta_forcasedelivery: '2111-01-12 00:00:00',
      custom_values: [
        {
          usecase_field_id: '447',
          field_uid: '60ed2baaa1418',
          customer_uid: '5ef9a05fa797e',
          case_uid: '6086bfd38bae8',
          field_seq_num: '1',
          field_type: 'RAG status',
          field_name: 'Scope Product',
          field_value: 'Green',
        },
        {
          usecase_field_id: '1699',
          field_uid: '60eeafc709bdb',
          customer_uid: '5ef9a05fa797e',
          case_uid: '6086bfd38bae8',
          field_seq_num: '2',
          field_type: 'String',
          field_name: 'R12',
          field_value: '',
        },
        {
          usecase_field_id: '3168',
          field_uid: '60f16ef5c98bf',
          customer_uid: '5ef9a05fa797e',
          case_uid: '6086bfd38bae8',
          field_seq_num: '3',
          field_type: 'RAG status',
          field_name: 'sub',
          field_value: '',
        },
        {
          usecase_field_id: '4434',
          field_uid: '60f52acb5cca6',
          customer_uid: '5ef9a05fa797e',
          case_uid: '6086bfd38bae8',
          field_seq_num: '5',
          field_type: 'Dropdown',
          field_name: 'cd',
          field_value: '',
        },
        {
          usecase_field_id: '8225',
          field_uid: '6124cab26b9a6',
          customer_uid: '5ef9a05fa797e',
          case_uid: '6086bfd38bae8',
          field_seq_num: '6',
          field_type: 'Number',
          field_name: 'Numb',
          field_value: '',
        },
        {
          usecase_field_id: '8923',
          field_uid: '6124cacbd9f87',
          customer_uid: '5ef9a05fa797e',
          case_uid: '6086bfd38bae8',
          field_seq_num: '7',
          field_type: 'Date',
          field_name: 'Estimated Date',
          field_value: '',
        },
        {
          usecase_field_id: '9621',
          field_uid: '6124cb9f8a547',
          customer_uid: '5ef9a05fa797e',
          case_uid: '6086bfd38bae8',
          field_seq_num: '8',
          field_type: 'Hyperlink',
          field_name: 'Hyper',
          field_value: '',
        },
        {
          usecase_field_id: '10862',
          field_uid: '613718a80b9f1',
          customer_uid: '5ef9a05fa797e',
          case_uid: '6086bfd38bae8',
          field_seq_num: '9',
          field_type: 'RAG status',
          field_name: 'nik',
          field_value: '',
        },
        {
          usecase_field_id: '11586',
          field_uid: '613720c421d73',
          customer_uid: '5ef9a05fa797e',
          case_uid: '6086bfd38bae8',
          field_seq_num: '10',
          field_type: 'String',
          field_name: 'nkp',
          field_value: '',
        },
        {
          usecase_field_id: '12411',
          field_uid: '613b591dd5bee',
          customer_uid: '5ef9a05fa797e',
          case_uid: '6086bfd38bae8',
          field_seq_num: '11',
          field_type: 'RAG status',
          field_name: 'Check',
          field_value: '',
        },
        {
          usecase_field_id: '14064',
          field_uid: '61642c89a72f6',
          customer_uid: '5ef9a05fa797e',
          case_uid: '6086bfd38bae8',
          field_seq_num: '12',
          field_type: 'String',
          field_name: 'gfg',
          field_value: '',
        },
        {
          usecase_field_id: '21428',
          field_uid: '623e9baeea87d',
          customer_uid: '5ef9a05fa797e',
          case_uid: '6086bfd38bae8',
          field_seq_num: '13',
          field_type: 'RAG status',
          field_name: 'kmk,m,m',
          field_value: '',
        },
        {
          usecase_field_id: '22476',
          field_uid: '623e9bcb85ae3',
          customer_uid: '5ef9a05fa797e',
          case_uid: '6086bfd38bae8',
          field_seq_num: '14',
          field_type: 'RAG status',
          field_name: 'kmk,m,m',
          field_value: '',
        },
        {
          usecase_field_id: '23550',
          field_uid: '62419fc187208',
          customer_uid: '5ef9a05fa797e',
          case_uid: '6086bfd38bae8',
          field_seq_num: '15',
          field_type: 'RAG status',
          field_name: 'new12',
          field_value: '',
        },
      ],
    },
    {
      project_name: 'Onboarding Project',
      project_uid: '6172c3b9e8730',
      association_uid: '6082d17bbb552',
      case_status_reason: 'Product feature mapping is not done for this use case',
      case_expansionvalue: '0',
      case_priority: 'Medium',
      case_uid: '6086d03049130',
      case_description: '',
      case_comment: '',
      case_name: 'MI2',
      case_readinessstate_status: 'red',
      case_needed_status: 'Land',
      case_requiredbydate: '2111-01-12 00:00:00',
      case_stage: 'Pre-Sales',
      case_deliverymanager: 'Not Assigned',
      client_status_reason: 'Delivery of one or more projects of this client is not planned or is overdue',
      client_uid: '6082d17bbb548',
      client_account_owner: 'shubham',
      client_name: 'SIP',
      client_account_worth: '1000',
      client_readiness_status: 'red',
      imported_template: '1',
      user_uid: '5f0c256808981',
      case_planned_startdate: '2021-04-26 00:00:00',
      case_startdate: '2021-04-26 00:00:00',
      case_deliverydate: '0000-00-00 00:00:00',
      eta_forcasedelivery: '2111-01-12 00:00:00',
      custom_values: [
        {
          usecase_field_id: '458',
          field_uid: '60ed2baaa1418',
          customer_uid: '5ef9a05fa797e',
          case_uid: '6086d03049130',
          field_seq_num: '1',
          field_type: 'RAG status',
          field_name: 'Scope Product',
          field_value: 'Red',
        },
        {
          usecase_field_id: '1710',
          field_uid: '60eeafc709bdb',
          customer_uid: '5ef9a05fa797e',
          case_uid: '6086d03049130',
          field_seq_num: '2',
          field_type: 'String',
          field_name: 'R12',
          field_value: '',
        },
        {
          usecase_field_id: '3179',
          field_uid: '60f16ef5c98bf',
          customer_uid: '5ef9a05fa797e',
          case_uid: '6086d03049130',
          field_seq_num: '3',
          field_type: 'RAG status',
          field_name: 'sub',
          field_value: 'Green',
        },
        {
          usecase_field_id: '4445',
          field_uid: '60f52acb5cca6',
          customer_uid: '5ef9a05fa797e',
          case_uid: '6086d03049130',
          field_seq_num: '5',
          field_type: 'Dropdown',
          field_name: 'cd',
          field_value: '',
        },
        {
          usecase_field_id: '8236',
          field_uid: '6124cab26b9a6',
          customer_uid: '5ef9a05fa797e',
          case_uid: '6086d03049130',
          field_seq_num: '6',
          field_type: 'Number',
          field_name: 'Numb',
          field_value: '',
        },
        {
          usecase_field_id: '8934',
          field_uid: '6124cacbd9f87',
          customer_uid: '5ef9a05fa797e',
          case_uid: '6086d03049130',
          field_seq_num: '7',
          field_type: 'Date',
          field_name: 'Estimated Date',
          field_value: '',
        },
        {
          usecase_field_id: '9632',
          field_uid: '6124cb9f8a547',
          customer_uid: '5ef9a05fa797e',
          case_uid: '6086d03049130',
          field_seq_num: '8',
          field_type: 'Hyperlink',
          field_name: 'Hyper',
          field_value: '',
        },
        {
          usecase_field_id: '10873',
          field_uid: '613718a80b9f1',
          customer_uid: '5ef9a05fa797e',
          case_uid: '6086d03049130',
          field_seq_num: '9',
          field_type: 'RAG status',
          field_name: 'nik',
          field_value: '',
        },
        {
          usecase_field_id: '11597',
          field_uid: '613720c421d73',
          customer_uid: '5ef9a05fa797e',
          case_uid: '6086d03049130',
          field_seq_num: '10',
          field_type: 'String',
          field_name: 'nkp',
          field_value: '',
        },
        {
          usecase_field_id: '12422',
          field_uid: '613b591dd5bee',
          customer_uid: '5ef9a05fa797e',
          case_uid: '6086d03049130',
          field_seq_num: '11',
          field_type: 'RAG status',
          field_name: 'Check',
          field_value: '',
        },
        {
          usecase_field_id: '14075',
          field_uid: '61642c89a72f6',
          customer_uid: '5ef9a05fa797e',
          case_uid: '6086d03049130',
          field_seq_num: '12',
          field_type: 'String',
          field_name: 'gfg',
          field_value: '',
        },
        {
          usecase_field_id: '21439',
          field_uid: '623e9baeea87d',
          customer_uid: '5ef9a05fa797e',
          case_uid: '6086d03049130',
          field_seq_num: '13',
          field_type: 'RAG status',
          field_name: 'kmk,m,m',
          field_value: 'Green',
        },
        {
          usecase_field_id: '22487',
          field_uid: '623e9bcb85ae3',
          customer_uid: '5ef9a05fa797e',
          case_uid: '6086d03049130',
          field_seq_num: '14',
          field_type: 'RAG status',
          field_name: 'kmk,m,m',
          field_value: 'Red',
        },
        {
          usecase_field_id: '23561',
          field_uid: '62419fc187208',
          customer_uid: '5ef9a05fa797e',
          case_uid: '6086d03049130',
          field_seq_num: '15',
          field_type: 'RAG status',
          field_name: 'new12',
          field_value: '',
        },
      ],
    },
  ];