import { BarComponent } from 'components/ResourceManagement/types/bar-component';
import { Component } from 'components/ResourceManagement/types/public-types';

export function isBarComponent(component: Component | BarComponent): component is BarComponent {
  return (component as BarComponent).x1 !== undefined;
}

export function removeHiddenComponents(list: Component[]) {
  let _list = [...list];
  const Items = _list.filter((t) => t.hideChildren && t.type === 'resource');

  if (Items.length > 0) {
    Items.forEach((pItem) => {
      const children = getChildren(_list, pItem);
      _list = _list.filter((t) => children.indexOf(t) === -1);
    });
  }

  return _list;
}

function getChildren(list: Component[], component: Component) {
  let _list: Component[] = [...list];
  _list = list.filter((targetItem) => targetItem.componentLinkedTo && targetItem.componentLinkedTo === component.id);
  const componentChildren = _list.reduce((children: Component[], t) => {
    return [...children, ...getChildren(list, t)];
  }, []);
  _list = _list.concat(_list, componentChildren);
  return _list;
}

export const sortComponents = (componentA: Component, componentB: Component) => {
  const orderA = componentA.displayOrder || Number.MAX_VALUE;
  const orderB = componentB.displayOrder || Number.MAX_VALUE;
  if (orderA > orderB) {
    return 1;
  } else if (orderA < orderB) {
    return -1;
  } else {
    return 0;
  }
};
