import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { clientAPI } from '../../../../api/modules/client-new';
import { requestState } from '../../types';

interface clientListType {
  clients: {
    data: any;
    status: requestState;
    error: null | undefined | string;
    count: number;
  };
  updateClient: {
    status: requestState;
    error: null | undefined | string;
  };
  industries: {
    data: any;
    status: requestState;
    error: null | undefined | string;
  };
  stages: {
    data: any;
    status: requestState;
    error: null | undefined | string;
  };
  segments: {
    data: any;
    status: requestState;
    error: null | undefined | string;
  };
  // clientDetail: {
  //   data: [];
  //   status: requestState;
  //   error: null | undefined | string;
  // };
  clientCutomFieldsInformation: {
    data: any;
    status: requestState;
    error: null | undefined | string;
  };
}

const initialState: clientListType = {
  clients: {
    data: [],
    status: requestState.loading,
    error: null,
    count: 0,
  },
  updateClient: {
    status: requestState.loading,
    error: null,
  },
  clientCutomFieldsInformation: {
    data: [],
    status: requestState.loading,
    error: null,
  },
  industries: {
    data: [],
    status: requestState.loading,
    error: null,
  },
  stages: {
    data: [],
    status: requestState.loading,
    error: null,
  },
  segments: {
    data: [],
    status: requestState.loading,
    error: null,
  },
};

export const getClientList = createAsyncThunk(
  'manageClient/getClientList',
  async (pageModel: { page: number; pageSize: number }) => {
    const { data } = await clientAPI.getClientList(pageModel);
    return data;
  }
);

export const updateClient = createAsyncThunk('manageClient/updateClient', async (info: any) => {
  const id = info.id;
  const payload = info.payload;
  const { data } = await clientAPI.updateClient(id, payload);
  return data;
});

export const getClientIndustries = createAsyncThunk('manageClient/getClientIndustries', async () => {
  const { data } = await clientAPI.getClientIndustries();
  return data.results;
});

export const getClientSegments = createAsyncThunk('manageClient/getClientSegments', async () => {
  const { data } = await clientAPI.getClientSegments();
  return data.results;
});

export const getClientStages = createAsyncThunk('manageClient/getClientStages', async () => {
  const { data } = await clientAPI.getClientStages();
  return data.results;
});

export const getClientCustomFields = createAsyncThunk(
  'manageClient/getClientCustomFields',
  async (client_id: string) => {
    const { data } = await clientAPI.getClientCustomFields(client_id);
    return data.results;
  }
);

const clientListSlice = createSlice({
  name: 'clientList',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getClientList.pending, (state) => {
        state.clients.status = requestState.loading;
      })
      .addCase(getClientList.fulfilled, (state, action) => {
        state.clients.status = requestState.success;
        if (action.payload) {
          state.clients.data = [...action.payload.results];
          state.clients.count = action.payload.count;
        } else {
          state.clients.data = [];
        }
      })
      .addCase(getClientList.rejected, (state, action) => {
        state.clients.status = requestState.failed;
        state.clients.error = action.error.message;
      })
      .addCase(getClientCustomFields.pending, (state) => {
        state.clientCutomFieldsInformation.status = requestState.loading;
      })
      .addCase(getClientCustomFields.fulfilled, (state, action) => {
        state.clientCutomFieldsInformation.status = requestState.success;
        if (action.payload) {
          state.clientCutomFieldsInformation.data = [...action.payload];
        } else {
          state.clientCutomFieldsInformation.data = [];
        }
      })
      .addCase(getClientCustomFields.rejected, (state, action) => {
        state.clientCutomFieldsInformation.status = requestState.failed;
        state.clientCutomFieldsInformation.error = action.error.message;
      })
      .addCase(getClientIndustries.pending, (state) => {
        state.industries.status = requestState.loading;
      })
      .addCase(getClientIndustries.fulfilled, (state, action) => {
        state.industries.status = requestState.success;
        if (action.payload) {
          state.industries.data = [...action.payload];
        } else {
          state.industries.data = [];
        }
      })
      .addCase(getClientIndustries.rejected, (state, action) => {
        state.industries.status = requestState.failed;
        state.industries.error = action.error.message;
      })
      .addCase(getClientSegments.pending, (state) => {
        state.segments.status = requestState.loading;
      })
      .addCase(getClientSegments.fulfilled, (state, action) => {
        state.segments.status = requestState.success;
        if (action.payload) {
          state.segments.data = [...action.payload];
        } else {
          state.segments.data = [];
        }
      })
      .addCase(getClientSegments.rejected, (state, action) => {
        state.segments.status = requestState.failed;
        state.segments.error = action.error.message;
      })
      .addCase(getClientStages.pending, (state) => {
        state.stages.status = requestState.loading;
      })
      .addCase(getClientStages.fulfilled, (state, action) => {
        state.stages.status = requestState.success;
        if (action.payload) {
          state.stages.data = [...action.payload];
        } else {
          state.stages.data = [];
        }
      })
      .addCase(getClientStages.rejected, (state, action) => {
        state.stages.status = requestState.failed;
        state.stages.error = action.error.message;
      })
      .addCase(updateClient.pending, (state) => {
        state.updateClient.status = requestState.loading;
      })
      .addCase(updateClient.fulfilled, (state, action) => {
        state.updateClient.status = requestState.success;
        const updatedClient = action.payload;
        state.clients.data = state.clients.data.map((client: any) => {
          if (client.id === updatedClient.id) return updatedClient;
          return client;
        });
      })
      .addCase(updateClient.rejected, (state, action) => {
        state.updateClient.status = requestState.failed;
        state.updateClient.error = action.error.message;
      });
  },
});

const clientListReducer = clientListSlice.reducer;

export default clientListReducer;
