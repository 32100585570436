import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import {
  addUsecaseAuditTrial,
  getAllUsecaseCustomFields,
  getAllUsecaseCustomFieldsWithValue,
  updateUsecaseCustomField,
} from '../../../../../../store/modules/Usecase/slice';
import { useParams } from 'react-router-dom';
import // getAllUsecaseCustomFields, getAllUsecaseCustomFieldsWithValue
// getUsecaseCustomFields,
// getUsecaseCustomFieldsDetail,
// updateUsecaseCustomField,
'../../../../../../store/modules/Usecase/slice';
import { useState } from 'react';
import {
  selectUsecaseCustomFields,
  selectUsecaseCustomFieldsDetail,
} from '../../../../../../store/modules/Usecase/selector';
import { useAppSelector } from '../../../../../../store/hooks';
import CustomFieldsForm from '../../../../../shared/CustomfieldsForm/CustomfieldsForm';
import { selectProjectInformation } from 'store/modules/Project/selector';
import CognisaasToast from 'components/shared/CognisaasToast/CognisaasToast';
import { addAttachmentToServer, resetAttachmentQueue } from 'store/modules/Attachment/slice';
import { selectAttachmentQueue } from 'store/modules/Attachment/selector';
import { getLoggedInUserUid } from 'utils/getUid';
import { format } from 'date-fns';

const UsecaseCustomfields = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const projectCustomFields = useAppSelector(selectUsecaseCustomFields);
  const projectCustomFieldsWithDetail = useAppSelector(selectUsecaseCustomFieldsDetail);
  const projectInformation = useAppSelector(selectProjectInformation);
  const attachmentQueue = useAppSelector(selectAttachmentQueue);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [alertDetails, setAlertDetails] = useState<{
    isVisible: boolean;
    type: 'success' | 'failure';
    message: string;
  }>({ isVisible: false, type: 'success', message: '' });

  let cf: any[] = [];
  let cfv: any[] = [];

  useEffect(() => {
    if (projectCustomFields.length == projectCustomFieldsWithDetail.length) {
      mergeBothSelectors();
    }
  }, [projectCustomFields, projectCustomFieldsWithDetail]);

  useEffect(() => {
    dispatch(getAllUsecaseCustomFields());
    dispatch(getAllUsecaseCustomFieldsWithValue(params.caseId as string));
  }, []);

  const mergeBothSelectors = () => {
    cf = [...projectCustomFields];
    cfv = [...projectCustomFieldsWithDetail];

    for (let i = 0; i < cf.length; i++) {
      for (let j = 0; j < cfv.length; j++) {
        if (cf[i].field_uid == cfv[j].field_uid) {
          cf[j] = { ...cfv[j], ...cf[i] };
        }
      }
    }
  };

  const attachmentQueueHelper = (attachmentQueue: any) => {
    attachmentQueue.forEach((attachment: any) => {
      dispatch(addAttachmentToServer(attachment));
    });
  };

  const updateProjectCustomFields = async (fields: any) => {
    setIsLoading(true);
    attachmentQueueHelper(attachmentQueue);
    for (const i in fields) {
      for (let j = 0; j < projectCustomFields.length; j++) {
        if (projectCustomFields[j].field_name == i) {
          let detail = projectCustomFields[j];
          if (fields[i].length === 0 && projectCustomFields[j].field_type === 'String') {
            const attachment = attachmentQueue.find(
              (attachment: any) => attachment.id === projectCustomFields[j].field_uid
            );
            detail = {
              ...projectCustomFields[j],
              field_value: attachment?.file?.name || '',
              case_uid: params.caseId as string,
            };
          } else {
            detail = { ...projectCustomFields[j], field_value: fields[i], case_uid: params.caseId as string };
          }
          const detail2 = {
            case_uid: params.caseId,
            user_uid: getLoggedInUserUid(),
            field: 'Custom Field ' + detail.field_name,
            modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
            old_value: projectCustomFieldsWithDetail[j].field_value,
            new_value: detail.field_value,
          };
          if (detail2.old_value != detail2.new_value) {
            if (detail.field_type === 'Date') {
              detail.field_value = format(new Date(detail.field_value), 'yyyy-MM-dd');
            }
            await dispatch(updateUsecaseCustomField(detail));
            await dispatch(addUsecaseAuditTrial(detail2));
          }
        }
      }
    }

    dispatch(resetAttachmentQueue());
    await dispatch(getAllUsecaseCustomFieldsWithValue(params.caseId as string));
    setIsLoading(false);
    setAlertDetails({
      isVisible: true,
      type: 'success',
      message: 'Custom fields updated successfully',
    });
    await dispatch(getAllUsecaseCustomFields());
    // await dispatch(getAllUsecaseCustomFieldsWithValue(params.caseId as string));
  };

  const reloadCustomfieldsData = async () => {
    await dispatch(getAllUsecaseCustomFields());
    await dispatch(getAllUsecaseCustomFieldsWithValue(params.caseId as string));
  };

  return (
    <>
      <CustomFieldsForm
        onSaveClickHandler={updateProjectCustomFields}
        userPermissions={projectInformation.__user_permissions}
        customFields={projectCustomFieldsWithDetail}
        isLoading={isLoading}
        clientUid={projectInformation.client_uid}
        projectUid={projectInformation.project_uid}
        caseUid={params.caseId}
        callbackToReloadData={reloadCustomfieldsData}
        attachmentQueue={attachmentQueue}
      />

      <CognisaasToast
        message={alertDetails.message}
        type={alertDetails.type}
        open={alertDetails.isVisible}
        onClose={() => {
          setAlertDetails({ isVisible: false, type: alertDetails.type, message: '' });
        }}
      />
    </>
  );
};

export default UsecaseCustomfields;
