import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { usecaseAPI } from '../../../../api/modules/usecase';

interface closedUsecasesType {
  closedUsecases: Array<any>;
  status: 'idle' | 'loading' | 'success' | 'failed';
  error: null | undefined | string;
}

export const fetchClosedUsecases = createAsyncThunk('/closedUsecases', async () => {
  const { data } = await usecaseAPI.getClosedUsecases();
  return data.result ?? [];
});

const initialState: closedUsecasesType = {
  closedUsecases: [],
  status: 'idle',
  error: null,
};

const closedUsecasesSlice = createSlice({
  name: 'closedUsecases',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchClosedUsecases.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchClosedUsecases.fulfilled, (state, action) => {
        state.status = 'success';
        state.closedUsecases = action.payload;
      })
      .addCase(fetchClosedUsecases.rejected, (state, { error }) => {
        state.error = error.message;
        state.status = 'failed';
      });
  },
});
export default closedUsecasesSlice.reducer;
