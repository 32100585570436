import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { raidApi } from '../../../api/modules/raid';
import { requestState } from '../types';

interface RAIDType {
    createRaidStatus: requestState;
    createRaidError: null | undefined | string;

    getRaidByProjectId: Array<any>
    getRaidByProjectIdStatus: requestState;
    getRaidByProjectIdError: null | undefined | string;

    updateRaidStatus: requestState;
    updateRaidError: null | undefined | string;

    deleteRaidStatus: requestState;
    deleteRaidError: null | undefined | string;

    getRaidComments: Array<any>;
    getRaidCommentsStatus: requestState;
    getRaidComentsError: null | undefined | string;

    updateRaidComment: requestState;
    updateRaidCommentError: null | undefined | string;

    deleteRaidComment: requestState;
    deleteRaidCommentError: null | undefined | string;

    addRaidComment: requestState;
    addRaidCommentError: null | undefined | string;
}

const initialState: RAIDType = {
    createRaidStatus: requestState.idle,
    createRaidError: null,

    getRaidByProjectId: [],
    getRaidByProjectIdStatus: requestState.idle,
    getRaidByProjectIdError: null,

    updateRaidStatus: requestState.idle,
    updateRaidError: null,

    deleteRaidStatus: requestState.idle,
    deleteRaidError: null,


    getRaidComments: [],
    getRaidCommentsStatus: requestState.idle,
    getRaidComentsError: '',

    updateRaidComment: requestState.idle,
    updateRaidCommentError: null,

    deleteRaidComment: requestState.idle,
    deleteRaidCommentError: null,

    addRaidComment: requestState.idle,
    addRaidCommentError: null,

};

export const createRaid = createAsyncThunk(
    'raid/createRaid',
    async(detail:any) => {
        const {data} = await raidApi.createRaid(detail);
        return data.result;
    }
);

export const getRaidByProjectID = createAsyncThunk(
    'raid/getRaidByProjectId',
    async(project_uid:string) => {
        const {data} = await raidApi.getRaidByProjectUid(project_uid);
        return data.result || [];
    }
);

export const updateRaid = createAsyncThunk(
    'raid/updateRaid',
    async (detail: any) => {
        const {data} = await raidApi.updateRaid(detail);
        return data.result;
    }
);

export const deleteRaid = createAsyncThunk(
    'raid/deleteRaid',
    async (detail: any) => {
        const {data} = await raidApi.deleteRaid(detail);
        return data.result;
    }
);


export const getRaidComments = createAsyncThunk(
    'raid/getRaidComments',
    async (raid_uid: string) => {
        const {data} = await raidApi.getProjectRaidCommentByRaidUid(raid_uid);
        return data.result || [];
    }
);

export const updateRaidComment = createAsyncThunk(
    'raid/updateRaidComment',
    async (detail: any) => {
        const {data} = await raidApi.updateRaidComment(detail);
        return data.result;
    }
);

export const deleteRaidComment = createAsyncThunk(
    'raid/deleteRaidComment',
    async (detail: any) => {
        const {data} = await raidApi.deleteRaidComment(detail);
        return data.result;
    }
);

export const addRaidComment = createAsyncThunk(
    'raid/addRaidComment',
    async (detail: any) => {
        const {data} = await raidApi.addRaidComment(detail);
        return data.result;
    }
);

const raidSlice = createSlice({
    name: 'raid',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(getRaidByProjectID.pending, (state) => {
            state.getRaidByProjectIdStatus = requestState.loading;
            }).addCase(getRaidByProjectID.fulfilled, (state, action) => {
                state.getRaidByProjectIdStatus = requestState.success;
                state.getRaidByProjectId = action.payload;
            }).addCase(getRaidByProjectID.rejected, (state, action) => {
                state.getRaidByProjectIdStatus = requestState.failed;
                state.getRaidByProjectIdError = action.error.message || '';
            }).addCase(createRaid.pending, (state) => {
                state.createRaidStatus = requestState.loading;
            }).addCase(createRaid.fulfilled, (state) => {
                state.createRaidStatus = requestState.success;
            }).addCase(createRaid.rejected, (state, action) => {
                state.createRaidStatus = requestState.failed;
                state.createRaidError = action.error.message || '';
            }).addCase(updateRaid.pending, (state) => {
                state.updateRaidStatus = requestState.loading;
            }).addCase(updateRaid.fulfilled, (state) => {
                state.updateRaidStatus = requestState.success;
            }).addCase(updateRaid.rejected, (state, action) => {
                state.updateRaidStatus = requestState.failed;
                state.updateRaidError = action.error.message || '';
            }).addCase(deleteRaid.pending, (state) => {
                state.deleteRaidStatus = requestState.loading;
                state.deleteRaidError = null;
            }).addCase(deleteRaid.fulfilled, (state) => {
                state.deleteRaidStatus = requestState.success;
            }).addCase(deleteRaid.rejected, (state, action) => {
                state.deleteRaidStatus = requestState.failed;
                state.deleteRaidError = action.error.message || '';
            }).addCase(getRaidComments.pending, (state) => {
            state.getRaidCommentsStatus = requestState.loading;
        }).addCase(getRaidComments.fulfilled, (state, action) => {
            state.getRaidCommentsStatus = requestState.success;
            state.getRaidComments = action.payload;
        }
        ).addCase(getRaidComments.rejected, (state, action) => {
            state.getRaidCommentsStatus = requestState.failed;
            state.getRaidComentsError = action.error.message || '';
        }).addCase(updateRaidComment.pending, (state) => {
            state.updateRaidComment = requestState.loading;
        }).addCase(updateRaidComment.fulfilled, (state) => {
            state.updateRaidComment = requestState.success;
        }).addCase(updateRaidComment.rejected, (state, action) => {
            state.updateRaidComment = requestState.failed;
            state.updateRaidCommentError = action.error.message || '';
        }).addCase(deleteRaidComment.pending, (state) => {
            state.deleteRaidComment = requestState.loading;
        }).addCase(deleteRaidComment.fulfilled, (state) => {
            state.deleteRaidComment = requestState.success;
        }).addCase(deleteRaidComment.rejected, (state, action) => {
            state.deleteRaidComment = requestState.failed;
            state.deleteRaidCommentError = action.error.message || '';
        }).addCase(addRaidComment.pending, (state) => {
            state.addRaidComment = requestState.loading;
        }).addCase(addRaidComment.fulfilled, (state) => {
            state.addRaidComment = requestState.success;
        }).addCase(addRaidComment.rejected, (state, action) => {
            state.addRaidComment = requestState.failed;
            state.addRaidCommentError = action.error.message || '';
        });
    },
});

export const raidReducer = raidSlice.reducer;

export default raidReducer;
