import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../..';

export const selectReportsUsecasesState = (state: RootState) => state.reportsAllUsecasesState;
export const selectReportsUsecases = createSelector(
  selectReportsUsecasesState,
  (reportsUsecasesState) => reportsUsecasesState.usecases
);
export const selectReportsUsecasesStatus = createSelector(
  selectReportsUsecasesState,
  (reportsUsecasesState) => reportsUsecasesState.status
);
export const selectReportsUsecasesError = createSelector(
  selectReportsUsecasesState,
  (reportsUsecasesState) => reportsUsecasesState.error
);
