import Dexie from 'dexie';

class MyAppDatabase extends Dexie {
  // Declare implicit table properties.
  // (just to inform Typescript. Instantiated by Dexie in stores() method)
  configs!: Dexie.Table<Config, string>; // number = type of the primary key

  constructor() {
    super('MyAppDatabase');
    this.version(1).stores({
      configs: 'id, name, value',
    });

    this.configs.mapToClass(Config);
  }
}

export class Config {
  id: string;
  name: string;
  value: string;

  constructor(id: string, name: string, value: string) {
    this.id = id;
    this.name = name;
    this.value = value;
  }

  save() {
    return db.transaction('rw', db.configs, () => async () => {
      // Add or update our selves. If add, record this.id.
      this.id = await db.configs.put(this);
    });
  }
}

export const db = new MyAppDatabase();
