import {
  label_client,
  label_project,
  label_task,
  label_use_case,
} from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { allowedOperationsList } from './Config/AllowedOperationsList';

export const getAllowedOperations = (dataType: string) => {
  if (dataType === 'string') return allowedOperationsList.string;
  else if (dataType === 'date') return allowedOperationsList.date;
  else if (dataType === 'link') return allowedOperationsList.link;
  else if (dataType === 'rag') return allowedOperationsList.rag;
  else if (dataType === 'progressBar') return allowedOperationsList.progressBar;
  else if (dataType === 'number') return allowedOperationsList.number;
  else if (dataType === 'dropDown') return allowedOperationsList.dropDown;
  else if (dataType === 'userName') return allowedOperationsList.userName;
  else if (dataType === 'taskPanel') return allowedOperationsList.taskPanel;
  else if (dataType === 'dialog') return allowedOperationsList.dialog;
  else if (dataType === 'boolean') return allowedOperationsList.boolean;
  else return allowedOperationsList.nothing;
};

// currently keeping only numer custom fields as aggregatable
export const isAggregatable = (dataType: string) => {
  if (dataType === 'number') return true;
  else return false;
};

// old
export const transformCustomFieldDataForEntityStructure = (data: any[]) => {
  const transformed: any = {};
  for (let i = 0; i < data.length; i++) {
    transformed[data[i].field_name] = {
      displayName: data[i].field_name,
      key: data[i].field_name,
      type: data[i].field_type,
      isAggregatable: isAggregatable(data[i].field_type),
      toAggregate: false, //by default no aggregation
      aggregationToPerform: null, //by default no aggregation to perform
      isCustomField: true,
      allowedOperationsList: getAllowedOperations(data[i].field_type),
      validValues:
        data[i].field_type == 'RAG status' || data[i].field_type == 'Dropdown'
          ? [...data[i].valid_values.split(',')]
          : undefined,
    };
  }
  return transformed;
};

export const getDisplayByKey = (obj: any, k: string) => {
  console.log(k);
  let KEY = '';
  console.log('edfefeefe');
  for (const i of Object.keys(obj)) {
    console.log(i, obj[i].key);
    if (obj[i].key == k) {
      KEY = obj[i].displayName;
      console.log(KEY);
      return KEY;
    }
  }
  return KEY;
};

export const getKeyByDisplayName = (obj: any, k: string) => {
  console.log(k, obj);
  let DISPLAY_NAME = '';
  for (const i of Object.keys(obj)) {
    if (obj[i].displayName == k) {
      DISPLAY_NAME = obj[i].key;
      return DISPLAY_NAME;
    }
  }
  // console.log('efwfefef');
  // return DISPLAY_NAME;
};

export const getStandardEntityName = (name: string) => {
  if (name == 'None') {
    return 'None';
  } else if (name == label_client) {
    return 'client';
  } else if (name == label_project) {
    return 'project';
  } else if (name == label_use_case) {
    return 'use case';
  } else if (name == label_task) {
    return 'task';
  }
};

export const getCustomEntityName = (name: string) => {
  if (name == 'client') {
    return label_client;
  } else if (name == 'project') {
    return label_project;
  } else if (name == 'use case') {
    return label_use_case;
  } else if (name == 'task') {
    return label_task;
  }
};

// new
// export const transformStandardFieldDataForEntityStructure = (
//   clientEntityStandardFieldsDisplayName: any,
//   clientEntityStandardFieldsKey: any,
//   clientEntityStandardFieldsAggregatable: any,
//   clientEntityStandardFieldsType: any
// ) => {
//   const transformed: any = {};
//   for (let i = 0; i < clientEntityStandardFieldsKey.length; i++) {
//     transformed[clientEntityStandardFieldsKey[i]] = {
//       displayName: clientEntityStandardFieldsDisplayName[i],
//       key: clientEntityStandardFieldsKey[i],
//       type: clientEntityStandardFieldsType[i],
//       aggregatable: clientEntityStandardFieldsAggregatable[i],
//       isCustomField: false,
//       allowedOperationsList: getAllowedOperations(clientEntityStandardFieldsType[i]),
//     };
//   }
//   console.log(' new transformed = ', transformed);
//   return transformed;
// };
