import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { requestState } from '../../types';
import { productRequirementAPI } from '../../../../api/modules/productRequirement';
import { isNull, isUndefined } from '../../../../utils/dataHelpers';
import { format } from 'date-fns';
import { settingsAPI } from '../../../../api/modules/Settings';
import { DeleteRequirementCommentType, UpdateReqPayload } from './types';

// fetching product and requirement from server
export const fetchProductAndRequirements = createAsyncThunk('getProductAndRequirements', async () => {
  const { data } = await productRequirementAPI.getProductAndTheirRequirementsData();
  // console.log(data);
  return data.result ?? [];
});

export const addNewProductAndRequirementToDb = createAsyncThunk(
  'addNewProductAndRequirements',
  async (newProductAndReq: any) => {
    const { data } = await productRequirementAPI.addNewProductAndRequirements(newProductAndReq);
    // console.log(data);
    return data;
  }
);

interface newRequirementPayloadType {
  feature_content: string;
  feature_description: string;
  feature_estimateddeliverydate: string;
  feature_readinessstate_status: 'red' | 'green' | 'amber';
  product_uid: string;
}
export const addNewRequirement = createAsyncThunk(
  'addNewRequirement',
  async (newRequirement: newRequirementPayloadType) => {
    const { data } = await productRequirementAPI.adddNewRequirement(newRequirement);
    return data.result;
  }
);

export const updateNewRequirement = createAsyncThunk('updateNewRequirement', async (payload: UpdateReqPayload) => {
  const { data } = await productRequirementAPI.updateNewRequirement(payload);
  // console.log('newRequirement', data);
  return data.result;
});

interface UpdateReqOnDbAndStorePayload {
  feature_content: string;
  feature_description: string;
  feature_estimateddeliverydate: string;
  feature_readinessstate_status: 'red' | 'amber' | 'green';
  feature_uid: string;
  product_uid: string;
}

export const updateRequirementOnDbAndStore = createAsyncThunk(
  'updateRequirementOnDbAndStoreBoth',
  async (payload: UpdateReqOnDbAndStorePayload) => {
    const { data } = await productRequirementAPI.updateNewRequirement(payload);
    // console.log('newRequirement', data);
    return { data: data.result, updatedDataPayload: payload };
  }
);

interface DeleteRequirementPayload {
  customer_uid: string;
  feature_uid: string;
}

export const deleteRequirementById = createAsyncThunk(
  'deleteRequirementById',
  async (payload: DeleteRequirementPayload) => {
    const { data } = await productRequirementAPI.deleteRequirement(payload);
    // console.log('deleteRequirement', data);
    return data.result;
  }
);

interface DeleteProductPayload {
  customer_uid: string;
  product_uid: string;
}

export const deleteProduct = createAsyncThunk('deleteProduct', async (payload: DeleteProductPayload) => {
  const { data } = await productRequirementAPI.deleteProduct(payload);
  // console.log('delete Product', data);
  return data.result;
});

interface addRequirementCommentPayload {
  comment: string;
  requirement_uid: string;
  user_uid: string;
  user_username: string;
}

export const addRequirementComment = createAsyncThunk(
  'addRequirementComment',
  async (payload: addRequirementCommentPayload) => {
    const { data } = await productRequirementAPI.addRequirementComment(payload);
    // console.log('delete Product', data);
    return data.result ?? '';
  }
);

export const fetchRequirementComment = createAsyncThunk(
  'productRequirement/fetchRequirementComment',
  async (feature_uid: any) => {
    console.log(feature_uid, 'slice');
    const { data } = await productRequirementAPI.getRequirementComments(feature_uid);
    // console.log('delete Product', data);
    return { featureUid: feature_uid, data: data.result };
  }
);

export const getCustomerDetail = createAsyncThunk('productRequirement/getCustomerDetail', async () => {
  const { data } = await settingsAPI.getCustomerDetails();
  // console.log('delete Product', data);
  return data.result;
});

interface GetProjectKeyPayload {
  customer_uid: string;
  product_id: string;
}
// product id is product issue id in data

export const getProjectKeyMappedToThisProduct = createAsyncThunk(
  'productRequirement/getProjectKeyMappedToThisProduct',
  async (payload: GetProjectKeyPayload) => {
    const { data } = await productRequirementAPI.getJiraProjectKeyMappedToProduct(payload);
    // console.log('delete Product', data);
    return data.result;
  }
);

interface GetJiraCustomFields {
  customer_uid: string;
  key: string;
  project_key: string;
}
// product id is product issue id in data

export const getJiraCustomFields = createAsyncThunk(
  'productRequirement/getCustomFields',
  async (payload: GetJiraCustomFields) => {
    const { data } = await productRequirementAPI.getJiraCustomFields({
      customer_uid: payload.customer_uid,
      key: payload.key,
      project_key: payload.project_key,
    });
    return data.result;
  }
);

interface CreateJiraIssuePayloadType {
  custom_field_id: string;
  customer_uid: string;
  description: string;
  formValues: any[];
  id: string;
  key: string;
  name: string;
  product_name: string;
  reporter: string;
  required_fields: any[];
  summary: string;
}
export const createJiraIssueEquivalentToRequirement = createAsyncThunk(
  'productRequirement/createJiraIssueEquivalentToRequirement',
  async (payload: CreateJiraIssuePayloadType) => {
    const { data } = await productRequirementAPI.createJiraIssue(payload);
    return data.result;
  }
);

interface UpdateRequirementIssueIdPayloadType {
  feature_issue_id: string;
  feature_issue_type_name: string;
  feature_uid: string;
  jira_issue_key: string;
}
export const updateRequirementIssueId = createAsyncThunk(
  'productRequirement/UpdateRequirementIssueId',
  async (payload: UpdateRequirementIssueIdPayloadType) => {
    const { data } = await productRequirementAPI.updateRequirementIssueId(payload);
    return data.result;
  }
);

interface AddRequiremetAttachmentsToJiraPayloadType {
  customer_uid: string;
  feature_uid: string;
  issueID: string;
  key: string;
}
export const addRequiremetAttachmentsToJira = createAsyncThunk(
  'productRequirement/addRequiremetAttachmentsToJira',
  async (payload: AddRequiremetAttachmentsToJiraPayloadType) => {
    const { data } = await productRequirementAPI.addRequiremetAttachmentsToJira(payload);
    return data.result;
  }
);

interface DeleteRequirementByDescriptionPayloadType {
  feature_description: string;
}

export const deleteRequirementByDescription = createAsyncThunk(
  'productRequirement/deleteRequirementByDescription',
  async (payload: DeleteRequirementByDescriptionPayloadType) => {
    const { data } = await productRequirementAPI.deleteRequirementByDescription(payload);
    return data.result;
  }
);

interface deleteRequirementCommentByUidPayload {
  comment_uid: string;
}

export const deleteRequirementCommentByUid = createAsyncThunk(
  'deleteRequirementCommentByUid',
  async (payload: deleteRequirementCommentByUidPayload) => {
    const { data } = await productRequirementAPI.deleteRequirementCommentByUid(payload);
    // console.log('delete Product', data);
    return data.result;
  }
);

interface updateRequirementCommentByUidPayload {
  comment: string;
  comment_uid: string;
  user_uid: string;
  user_username: string;
}

export const updateRequirementCommentByUid = createAsyncThunk(
  'updateRequirementCommentByUid',
  async (payload: updateRequirementCommentByUidPayload) => {
    const { data } = await productRequirementAPI.updateRequirementCommentByUid(payload);
    // console.log('delete Product', data);
    return data.result;
  }
);
interface updateProductByProductUidPayloadType {
  customer_uid: string;
  product_uid: string;
  product_name: string;
}
export const updateProductByProductUid = createAsyncThunk(
  'productRequirement/updateProductByProductUid',
  async (payload: updateProductByProductUidPayloadType) => {
    const { data } = await productRequirementAPI.updateProductByProductUid(payload);
    return data.result;
  }
);

export const syncProductAndRequirementToJira = createAsyncThunk(
  'productRequirement/syncProductAndRequirementToJira',
  async () => {
    const { data } = await productRequirementAPI.syncProductRequirementByCustomerUid();
    return data.status;
  }
);

// createJiraCommentOfFeature
interface createJiraRequirementCommentType {
  customer_uid: string;
  feature_comment_uid: string;
  feature_uid: string;
  jira_issue_id: string;
  key: string;
}
export const createJiraRequirementComment = createAsyncThunk(
  'productRequirement/createJiraRequirementComment',
  async (payload: createJiraRequirementCommentType) => {
    const { data } = await productRequirementAPI.createJiraCommentOfFeature(payload);
    return data.result;
  }
);

//getRequirementCommentsFromJira
interface GetJiraRequirementCommentType {
  feature_uid: string;
  jira_issue_id: string;
}
export const getAllRequriementCommentsIncludingJira = createAsyncThunk(
  'productRequirement/getAllRequriementCommentsIncludingJira',
  async (payload: GetJiraRequirementCommentType) => {
    const { data } = await productRequirementAPI.getRequirementCommentsFromJira(
      payload.feature_uid,
      payload.jira_issue_id
    );
    return data.result ?? [];
  }
);

// deleteRequirmentCommentFromJira
export const deleteRequirementCommentFromJira = createAsyncThunk(
  'productRequirement/deleteRequirementCommentFromJira',
  async (payload: DeleteRequirementCommentType) => {
    const { data } = await productRequirementAPI.deleteRequirmentCommentFromJira(payload);
    return data.result ?? [];
  }
);

interface StateProps {
  productAndTheirRequirement: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };
  newProductAndReqToBeAdded: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };
  newRequirement: {
    data: Record<string, any>;
    status: requestState;
    error: null | undefined | string;
  };
  updateRequirement: {
    data: Record<string, any>;
    status: requestState;
    error: null | undefined | string;
  };
  deleteRequirementTrack: {
    data: Record<string, any>;
    status: requestState;
    error: null | undefined | string;
  };
  deletingProduct: {
    data: Record<string, any>;
    status: requestState;
    error: null | undefined | string;
  };
  requirementComments: {
    [key: string]: {
      data: Record<string, any>;
      status: requestState;
      error: null | undefined | string;
    };
  };
  customerDetail: {
    data: Record<string, any>;
    status: requestState;
    error: null | undefined | string;
  };
  jiraMappedProjectToProductKey: {
    data: Record<string, any>;
    status: requestState;
    error: null | undefined | string;
  };
  jiraCustomFields: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };
  jiraFullCustomFields: {
    data: any;
    status: requestState;
    error: null | undefined | string;
  };
  creatingJiraIssue: {
    data: any;
    status: requestState;
    error: null | undefined | string;
  };
  updatingRequirementIssueId: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };
  addingAttachmentsToJira: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };
  deletingReqFromJira: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };
  newAddedRequirementCommentUid: {
    data: any;
    status: requestState;
    error: null | undefined | string;
  };
  syncingJiraProductAndRequirement: {
    data: any;
    status: requestState;
    error: null | undefined | string;
  };
}

const initialState: StateProps = {
  productAndTheirRequirement: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  newProductAndReqToBeAdded: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  newRequirement: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  updateRequirement: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  deleteRequirementTrack: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  deletingProduct: {
    data: {},
    status: requestState.idle,
    error: null,
  },
  requirementComments: {
    default: {
      data: {},
      status: requestState.idle,
      error: null,
    },
  },
  customerDetail: {
    data: {},
    status: requestState.idle,
    error: null,
  },
  jiraMappedProjectToProductKey: {
    data: {},
    status: requestState.idle,
    error: null,
  },
  jiraCustomFields: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  jiraFullCustomFields: {
    data: {},
    status: requestState.idle,
    error: null,
  },
  creatingJiraIssue: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  updatingRequirementIssueId: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  addingAttachmentsToJira: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  deletingReqFromJira: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  newAddedRequirementCommentUid: {
    data: '',
    status: requestState.idle,
    error: null,
  },
  syncingJiraProductAndRequirement: {
    data: '',
    status: requestState.idle,
    error: null,
  },
};

export const productRequirementSlice = createSlice({
  name: 'product and requirement',
  initialState,
  reducers: {
    resetNewProductAndRequirementSubState: (state) => {
      state.newProductAndReqToBeAdded = initialState.newProductAndReqToBeAdded;
    },
    resetUpdateNewRequirementForFurthurUse: (state) => {
      state.updateRequirement = initialState.updateRequirement;
    },
    resetNewRequirement: (state) => {
      state.newRequirement = initialState.newRequirement;
    },
    pretedUpdateRequirementFailed: (state) => {
      state.newRequirement.status = requestState.failed;
    },
    removeThisRequirementFromStore: (state: StateProps, action: PayloadAction<any>) => {
      // console.log('removing requirement from store', current(state.productAndTheirRequirement));
      // console.log('removign payload', action.payload);
      state.productAndTheirRequirement.data.map((product) => {
        if (isUndefined(product.feature[action.payload.featureIndex]?.feature_uid)) {
          return product;
        } else if (product.feature[action.payload.featureIndex].feature_uid === action.payload.featureUid) {
          // console.log(current(product));
          const tempProduct = product;
          tempProduct.feature = [
            ...product.feature.slice(0, action.payload.featureIndex),
            ...product.feature.slice(action.payload.featureIndex + 1),
          ];
          return tempProduct;
        }
      });
    },
    deleteProductFromLocalStore: (state: StateProps, action: PayloadAction<any>) => {
      state.productAndTheirRequirement.data = [
        ...state.productAndTheirRequirement.data.slice(0, action.payload),
        ...state.productAndTheirRequirement.data.slice(action.payload + 1),
      ];
    },
    resetAddedCommentFeatureUid: (state: StateProps) => {
      state = { ...state, newAddedRequirementCommentUid: initialState.newAddedRequirementCommentUid };
      console.log(state);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchProductAndRequirements.pending, (state) => {
        state.productAndTheirRequirement.status = requestState.loading;
      })
      .addCase(fetchProductAndRequirements.fulfilled, (state, action: PayloadAction<any>) => {
        state.productAndTheirRequirement.status = requestState.success;
        state.productAndTheirRequirement.data = action.payload;
      })
      .addCase(fetchProductAndRequirements.rejected, (state, action: any) => {
        state.productAndTheirRequirement.status = requestState.failed;
        state.productAndTheirRequirement.error = action.error.message;
      })
      .addCase(addNewProductAndRequirementToDb.pending, (state) => {
        state.newProductAndReqToBeAdded.status = requestState.loading;
      })
      .addCase(addNewProductAndRequirementToDb.fulfilled, (state, action: PayloadAction<any>) => {
        state.newProductAndReqToBeAdded.status = requestState.success;
        if (isNull(action.payload.result)) {
          state.newProductAndReqToBeAdded.error = action.payload.message;
        } else {
          state.newProductAndReqToBeAdded.data = action.payload.result;
        }
      })
      .addCase(addNewProductAndRequirementToDb.rejected, (state, action: any) => {
        state.newProductAndReqToBeAdded.status = requestState.failed;
        state.newProductAndReqToBeAdded.error = action.error.message;
      })
      .addCase(addNewRequirement.pending, (state) => {
        state.newRequirement.status = requestState.loading;
      })
      .addCase(addNewRequirement.fulfilled, (state, action: PayloadAction<any>) => {
        state.newRequirement.status = requestState.success;
        state.newRequirement.data = action.payload;
        // console.log('requirement added success to server', action.payload);
      })
      .addCase(addNewRequirement.rejected, (state, action: any) => {
        state.newRequirement.status = requestState.failed;
        state.newRequirement.error = action.error.message;
      })
      .addCase(updateNewRequirement.pending, (state) => {
        state.updateRequirement.status = requestState.loading;
      })
      .addCase(updateNewRequirement.fulfilled, (state, action: PayloadAction<any>) => {
        state.updateRequirement.status = requestState.success;
        state.updateRequirement.data = action.payload;
        // console.log('requirement added success to server', action.payload);
      })
      .addCase(updateNewRequirement.rejected, (state, action: any) => {
        state.updateRequirement.status = requestState.failed;
        state.updateRequirement.error = action.error.message;
      })
      .addCase(updateRequirementOnDbAndStore.pending, (state) => {
        state.updateRequirement.status = requestState.loading;
      })
      .addCase(updateRequirementOnDbAndStore.fulfilled, (state, action: PayloadAction<any>) => {
        state.updateRequirement.status = requestState.success;
        // state.updateRequirement.data = action.payload;
        console.log('requirement updated', action.payload);

        state.productAndTheirRequirement.data.forEach((product) => {
          if (product.product_uid === action.payload.updatedDataPayload.product_uid) {
            product.feature.forEach((requirement: any) => {
              if (requirement.feature_uid === action.payload.updatedDataPayload.feature_uid) {
                // 0000-00-00 00:00:00
                const serverLikeDateTime = action.payload.updatedDataPayload.feature_estimateddeliverydate ? format(
                  new Date(action.payload.updatedDataPayload.feature_estimateddeliverydate.replace(/-/g, '/')),
                  'yyyy-MM-dd 00:00:00'
                ) : action.payload.updatedDataPayload.feature_estimateddeliverydate;
                // console.log(serverLikeDateTime);
                requirement.feature_content = action.payload.updatedDataPayload.feature_content;
                requirement.feature_description = action.payload.updatedDataPayload.feature_description;
                requirement.feature_estimateddeliverydate = serverLikeDateTime;
                requirement.feature_readinessstate_status =
                  action.payload.updatedDataPayload.feature_readinessstate_status;
                requirement.feature_uid = action.payload.updatedDataPayload.feature_uid;
                requirement.product_uid = action.payload.updatedDataPayload.product_uid;
              }
            });
          }
        });
      })
      .addCase(updateRequirementOnDbAndStore.rejected, (state, action: any) => {
        state.updateRequirement.status = requestState.failed;
        state.updateRequirement.error = action.error.message;
      })
      .addCase(deleteRequirementById.pending, (state) => {
        state.deleteRequirementTrack.status = requestState.loading;
      })
      .addCase(deleteRequirementById.fulfilled, (state, action: PayloadAction<any>) => {
        state.deleteRequirementTrack.status = requestState.success;
        state.deleteRequirementTrack.data = action.payload;
        // console.log('requirement deleted', action.payload);
      })
      .addCase(deleteRequirementById.rejected, (state, action: any) => {
        state.deleteRequirementTrack.status = requestState.failed;
        state.deleteRequirementTrack.error = action.error.message;
      })
      .addCase(deleteProduct.pending, (state) => {
        state.deletingProduct.status = requestState.loading;
      })
      .addCase(deleteProduct.fulfilled, (state, action: PayloadAction<any>) => {
        state.deletingProduct.status = requestState.success;
        state.deletingProduct.data = action.payload;
        // console.log('product deleted', action.payload);
      })
      .addCase(deleteProduct.rejected, (state, action: any) => {
        state.deletingProduct.status = requestState.failed;
        state.deletingProduct.error = action.error.message;
      })
      .addCase(fetchRequirementComment.pending, (state, action: PayloadAction<any>) => {
        // state.requirementComments[action.payload.feature_uid].status = requestState.loading;
        const localRequirementComment = {
          ...state.requirementComments,
          [action.payload.feature_uid]: {
            data: [],
            status: requestState.loading,
            error: null,
          },
        };
        state.requirementComments = { ...localRequirementComment };

        // localRequirementComment = {...localRequirementComment,}

        // if(Object.keys(state.requirementComments).includes(action.payload.feature_uid)) {}
      })
      .addCase(fetchRequirementComment.fulfilled, (state, action: PayloadAction<any>) => {
        state.requirementComments[action.payload.feature_uid].status = requestState.success;
        state.requirementComments[action.payload.feature_uid].data = action.payload;
        console.log('comments recieved', action.payload);
      })
      .addCase(fetchRequirementComment.rejected, (state, action: any) => {
        console.log(action);
        // const localState = {
        //   ...state,
        //   [action.payload.feature_uid]: {
        //     data: [],
        //     status: requestState.failed,
        //     error: action.error.message,
        //   },
        // };
        // state.requirementComments[action.payload.feature_uid].status = requestState.failed;
        // state.requirementComments[action.payload.feature_uid].error = action.error.message;
      })
      .addCase(getCustomerDetail.pending, (state) => {
        state.customerDetail.status = requestState.loading;
      })
      .addCase(getCustomerDetail.fulfilled, (state, action: PayloadAction<any>) => {
        state.customerDetail.status = requestState.success;
        state.customerDetail.data = action.payload;
        // console.log('product deleted', action.payload);
      })
      .addCase(getCustomerDetail.rejected, (state, action: any) => {
        state.customerDetail.status = requestState.failed;
        state.customerDetail.error = action.error.message;
      })
      .addCase(getProjectKeyMappedToThisProduct.pending, (state) => {
        state.jiraMappedProjectToProductKey.status = requestState.loading;
      })
      .addCase(getProjectKeyMappedToThisProduct.fulfilled, (state, action: PayloadAction<any>) => {
        state.jiraMappedProjectToProductKey.status = requestState.success;
        state.jiraMappedProjectToProductKey.data = action.payload;
        // console.log('product deleted', action.payload);
      })
      .addCase(getProjectKeyMappedToThisProduct.rejected, (state, action: any) => {
        state.jiraMappedProjectToProductKey.status = requestState.failed;
        state.jiraMappedProjectToProductKey.error = action.error.message;
      })
      .addCase(getJiraCustomFields.pending, (state) => {
        state.jiraCustomFields.status = requestState.loading;

        // in future drecate the above state and use only below one
        state.jiraFullCustomFields.status = requestState.loading;
      })
      .addCase(getJiraCustomFields.fulfilled, (state, action: PayloadAction<any>) => {
        state.jiraCustomFields.status = requestState.success;
        // state.jiraCustomFields.data = action.payload;
        const localJiraCustomFieldsData = { ...state.jiraCustomFields.data };

        const myPayload = JSON.parse(action.payload);
        // console.log(myPayload['projects']);
        myPayload['projects'].forEach((projectItem: any) => {
          localJiraCustomFieldsData[projectItem.key] = projectItem.issuetypes.map((item: any) => item.name);
          // console.log(localJiraCustomFieldsData[projectItem.key]);
        });

        state.jiraCustomFields.data = localJiraCustomFieldsData;
        // console.log('product deleted', action.payload);

        // in future deprecate the above functionality and use below one
        state.jiraFullCustomFields.data = JSON.parse(action.payload);
      })
      .addCase(getJiraCustomFields.rejected, (state, action: any) => {
        state.jiraCustomFields.status = requestState.failed;
        state.jiraCustomFields.error = action.error.message;

        // in future drecate the above state and use only below one
        state.jiraFullCustomFields.status = requestState.failed;
        state.jiraFullCustomFields.error = action.error.message;
      })
      // create jira issue
      .addCase(createJiraIssueEquivalentToRequirement.pending, (state) => {
        state.creatingJiraIssue.status = requestState.loading;
      })
      .addCase(createJiraIssueEquivalentToRequirement.fulfilled, (state, action: PayloadAction<any>) => {
        state.creatingJiraIssue.status = requestState.success;
        if (!isNull(action.payload)) {
          state.creatingJiraIssue.data = action.payload;
        }
      })
      .addCase(createJiraIssueEquivalentToRequirement.rejected, (state, action: any) => {
        state.creatingJiraIssue.status = requestState.failed;
        state.creatingJiraIssue.error = action.error.message;
      })
      // update requirement issue id on jira
      .addCase(updateRequirementIssueId.pending, (state) => {
        state.updatingRequirementIssueId.status = requestState.loading;
      })
      .addCase(updateRequirementIssueId.fulfilled, (state, action: PayloadAction<any>) => {
        state.updatingRequirementIssueId.status = requestState.success;
        if (!isNull(action.payload)) {
          state.updatingRequirementIssueId.data = action.payload;
        }
      })
      .addCase(updateRequirementIssueId.rejected, (state, action: any) => {
        state.updatingRequirementIssueId.status = requestState.failed;
        state.updatingRequirementIssueId.error = action.error.message;
      })
      // adding attachmen to jira
      .addCase(addRequiremetAttachmentsToJira.pending, (state) => {
        state.addingAttachmentsToJira.status = requestState.loading;
      })
      .addCase(addRequiremetAttachmentsToJira.fulfilled, (state, action: PayloadAction<any>) => {
        state.addingAttachmentsToJira.status = requestState.success;
        if (!isNull(action.payload)) {
          state.addingAttachmentsToJira.data = action.payload;
        }
      })
      .addCase(addRequiremetAttachmentsToJira.rejected, (state, action: any) => {
        state.addingAttachmentsToJira.status = requestState.failed;
        state.addingAttachmentsToJira.error = action.error.message;
      })
      // delete requirement by description
      .addCase(deleteRequirementByDescription.pending, (state) => {
        state.deletingReqFromJira.status = requestState.loading;
      })
      .addCase(deleteRequirementByDescription.fulfilled, (state, action: PayloadAction<any>) => {
        state.deletingReqFromJira.status = requestState.success;
        if (!isNull(action.payload)) {
          state.deletingReqFromJira.data = action.payload;
        }
      })
      .addCase(deleteRequirementByDescription.rejected, (state, action: any) => {
        state.deletingReqFromJira.status = requestState.failed;
        state.deletingReqFromJira.error = action.error.message;
      })
      //syncProductAndRequirementToJira
      .addCase(syncProductAndRequirementToJira.pending, (state) => {
        state.syncingJiraProductAndRequirement.status = requestState.loading;
      })
      .addCase(syncProductAndRequirementToJira.fulfilled, (state, action: PayloadAction<any>) => {
        state.deletingReqFromJira.status = requestState.success;
        state.syncingJiraProductAndRequirement.data = action.payload;
      })
      .addCase(syncProductAndRequirementToJira.rejected, (state, action: any) => {
        state.syncingJiraProductAndRequirement.status = requestState.failed;
        state.syncingJiraProductAndRequirement.error = action.error.message;
      })
      // addRequirementComment
      .addCase(addRequirementComment.pending, (state) => {
        state.newAddedRequirementCommentUid.status = requestState.loading;
      })
      .addCase(addRequirementComment.fulfilled, (state, action: PayloadAction<any>) => {
        state.newAddedRequirementCommentUid.status = requestState.success;
        if (!isNull(action.payload)) {
          state.newAddedRequirementCommentUid.data = action.payload;
        }
      })
      .addCase(addRequirementComment.rejected, (state, action: any) => {
        state.newAddedRequirementCommentUid.status = requestState.failed;
        state.newAddedRequirementCommentUid.error = action.error.message;
      })
      .addCase(getAllRequriementCommentsIncludingJira.fulfilled, (state, action: PayloadAction<any>) => {
        const localRequirementComment = {
          ...state.requirementComments,
          [action.payload.feature_uid]: {
            data: [...action.payload],
            status: requestState.success,
            error: null,
          },
        };
        state.requirementComments = { ...localRequirementComment };
      })
      .addCase(createJiraRequirementComment.fulfilled, (state) => {
        state.newAddedRequirementCommentUid.status = requestState.idle;
        state.newAddedRequirementCommentUid.data = '';
      });
  },
});
//
// exporting manual reducers
export const {
  resetNewRequirement,
  resetNewProductAndRequirementSubState,
  resetUpdateNewRequirementForFurthurUse,
  pretedUpdateRequirementFailed,
  removeThisRequirementFromStore,
  deleteProductFromLocalStore,
  resetAddedCommentFeatureUid,
} = productRequirementSlice.actions;

const productRequirementReducer = productRequirementSlice.reducer;
export default productRequirementReducer;
