import React, { ReactNode } from 'react';
import { Component } from 'components/ResourceManagement/types/public-types';
import styles from './grid.module.css';
import styled from 'styled-components';

export type GridBodyProps = {
  primeComponents: Component[];
  dates: Date[];
  svgWidth: number;
  rowHeight: number;
  columnWidth: number;
};

const StyledSvgLine = styled('line')`
    stroke: ${({ theme }) => theme.colors.grey_3};
    stroke-width: 1;
`;
export const GridBody: React.FC<GridBodyProps> = ({
  primeComponents,
  dates,
  rowHeight,
  svgWidth,
  columnWidth,
}) => {

  let y = 0;
  const gridCells: ReactNode[] = [];
  const rowLines: ReactNode[] = [
    <StyledSvgLine
      key='RowLineFirst'
      x1={0}
      y1={0}
      x2={svgWidth}
      y2={0}
    />,
  ];
  const ticks: ReactNode[] = [];

  primeComponents.forEach((component, index) => {
    rowLines.push(
      <StyledSvgLine
        key={`RowLine-${component.id}-${index}`}
        x1={0}
        y1={y + rowHeight}
        x2={svgWidth}
        y2={y + rowHeight}
      />
    );
    y += rowHeight;
  });

  let tickX = 0;
  for (let dateIndex = 0; dateIndex < dates.length; dateIndex++) {
    const date = dates[dateIndex];
    ticks.push(
      <StyledSvgLine
        key={`tick-${date.getTime()}`}
        x1={tickX}
        y1={0}
        x2={tickX}
        y2={y}
        className={styles.gridTick}
      />
    );

    y += rowHeight;
    tickX += columnWidth;
  }

  return (
    <>
      <g className='gridBody'>
        <g className='gridCells'>{gridCells}</g>
        <g>{ticks}</g>
        <g className='rowLines'>{rowLines}</g>
      </g>
    </>
  );
};
