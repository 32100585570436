import { Typography } from '@mui/material';
import { status } from 'store/modules/types';

interface props {
  status: string;
}

const CognisaasStatusValue = (props: props) => {
  return (
    <Typography>
      {props.status == status.RED
        ? 'Overdue'
        : props.status == status.GREEN
        ? 'On Track'
        : props.status == status.BLUE
        ? 'Completed'
        : props.status == status.AMBER
        ? 'At Risk'
        : ''}
    </Typography>
  );
};

export default CognisaasStatusValue;
