import CognisaasCard from '../CognisaasCard';
import { Typography, Grid } from '@mui/material';
import { parseISO, format } from 'date-fns';
import { Box } from '@mui/system';

const CognisaasProductRequiremetsCardForUsecase = (props: any) => {
  const formatDateField = (date: any) => {
    if (date.length > 0) {
      if (date.includes('T')) {
        return format(new Date(parseISO(date)), 'yyyy MMM dd');
      } else if (Date.parse(date)) {
        return format(new Date(date.replace(/-/g, '/')), 'yyyy MMM dd');
      } else if (date === '0000-00-00 00:00:00') {
        return '-';
      }
    } else {
      return date;
    }
  };
  return (
    <CognisaasCard clientStatusColor={props.feature.feature_readinessstate_status} width="95%" height="126">
      <Box sx={{ padding: '4px 0px 4px 12px' }}>
        <Grid mt={1} mb={2} mx={1} container direction="row" xs={12} md={12}>
          <Grid item xs={5} md={5}>
            <Typography variant="body2" width={'40%'}>{props.feature.feature_description}</Typography>
          </Grid>
          <Grid item xs={6} md={6} sx={{ lineHeight: '1.25' }}>
            <span
              style={{ marginRight: '4px', marginLeft: '2px', marginTop: '1px' }}
              className={
                props.feature.feature_readinessstate_status == 'red'
                  ? 'red_status_box_task'
                  : props.feature.feature_readinessstate_status == 'amber'
                    ? 'amber_status_box_task'
                    : props.feature.feature_readinessstate_status == 'green'
                      ? 'green_status_box_task'
                      : ''
              }
            ></span>
            <Typography variant={'subtitle1'}>
              {props.feature.feature_readinessstate_status == 'green'
                ? 'Released'
                : props.feature.feature_readinessstate_status == 'red'
                  ? 'New Requirement'
                  : props.feature.feature_readinessstate_status == 'amber' ? 'In Progress' : ''}
            </Typography>
          </Grid>

          <Grid item xs={4} md={4}>
            <Typography variant="body2">{props.feature.product_name}</Typography>
          </Grid>
        </Grid>
        <Grid my={1} mx={1} container direction="row" xs={12} md={12}>
          <Grid item xs={5} md={5}>
            <Typography variant="subtitle2" className="label-text">
              Requirements Type
            </Typography>
            <Typography variant="body2">{props.feature.feature_issue_type_name}</Typography>
          </Grid>
          <Grid item xs={3} md={3}>
            <Typography variant="subtitle2" className="label-text">
              ETA Release
            </Typography>
            <Typography variant="body2">
              {formatDateField(props.feature.feature_estimateddeliverydate)}
            </Typography>
          </Grid>

          <Grid item xs={4} md={4} sx={{ paddingLeft: '6%' }}>
            <Typography variant="subtitle2" className="label-text">
              Configuartion details
            </Typography>
            {props.feature.feature_content.length !== 0 ? (
              <Typography variant="body2">{props.feature.feature_content}</Typography>
            ) : (
              <Typography variant="body2">Not Specified</Typography>
            )}
          </Grid>
        </Grid>

      </Box>
    </CognisaasCard>
  );
};

export default CognisaasProductRequiremetsCardForUsecase;
