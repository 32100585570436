import { useState } from 'react';

export const useModal = () => {
  const [isModalOpen, setIsOpen] = useState(false);

  function toggleModal() {
    setIsOpen(!isModalOpen);
  }
  return { isModalOpen, toggleModal };
};
