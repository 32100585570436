import { ReactElement } from 'react';
import { SubTitle, Small, Frame, Box, Text } from './CustomiseDrawer.styles';
import { ReactComponent as Arrow } from '../../../../assets/svgs/arrow.svg';

interface Props {
  onClick: () => void;
  heading: string;
  subHeading: string;
  description: string;
  icon: ReactElement;
}
const ChartInfoBox = (props: Props) => {
  const { onClick, heading, subHeading, description, icon } = props;

  return (
    <Box style={{ height: '140px' }} onClick={onClick}>
      <SubTitle>{heading}</SubTitle>
      <Small>{subHeading}</Small>
      <Frame style={{ marginTop: '15px', alignItems: 'center' }}>
        {icon}
        <Text style={{ marginLeft: '10px' }}>{description}</Text>
        <Arrow />
      </Frame>
    </Box>
  );
};

export default ChartInfoBox;
