export const operation = {
  count: 'count',
  sum: 'sum',
  average: 'average',
  min: 'min',
  max: 'max',
  uniqueList: 'uniqueList', //gives a list where each object is distinct
  uniqueListWithFrequency: 'uniqueListWithFrequency', // gives a list where each object is distinct along with its frequency
  list: 'list', //gives a list of all the values
  mean: 'mean',
  median: 'median',
  nothing: 'nothing', //does nothing and just returns the same value as provided
  maxOccurance: 'maxOccurance', //finds the max occurance in a list of strings
  minOccurance: 'minOccurance', //finds the min occurance in a list of strings
};
