import {
  Drawer,
  Typography,
  Stack,
  Avatar,
  Grid,
  TextField,
  TextareaAutosize,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material';
import { Formik } from 'formik';
import CognisaasButton from '../../../../../shared/CognisaasButton/CognisaasButton';
import CognisaasSelect from '../../../../../shared/CognisaasSelect/CognisaasSelect';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../../../store/hooks';
import { useDispatch } from 'react-redux';
import { selectCustomerDetail } from '../../../../../../store/modules/Common/selector';
import CognisaasCalenderNew from '../../../../../shared/CognisaasCalenderNew/CognisaasCalenderNew';
// import { projectStageValues } from '../../../../utils';
import {
  addUsecaseAuditTrial,
  deleteUsecase,
  getAllUsecaseCustomFieldsWithValue,
} from '../../../../../../store/modules/Usecase/slice';
import { getLoggedInUserUid, getUserUid } from '../../../../../../utils/getUid';
import { differenceInDays, format } from 'date-fns';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate, useParams } from 'react-router-dom';
import { getProjectInformation, handleProjectPlannedDateUpdate } from '../../../../../../store/modules/Project/slice';
import { updateCaseWithfeatureStatus } from '../../../../../../store/modules/Common/slice';
import { updateUsecaseByUid } from '../../../../../../store/modules/Usecase/slice';
import { getUsecaseAudit } from '../../../../../../store/modules/Usecase/slice';
import { selectProjectInformation, selectProjectMembers } from '../../../../../../store/modules/Project/selector';
import getSymbolFromCurrency from 'currency-symbol-map';
import CognisaasUserSelect from 'components/shared/CognisaasSelect/CognisaasUserSelect';
import { getProjectMembers } from 'store/modules/Project/slice';
import { editProjectSchema } from '../../../../../../utils/validations';
import {
  label_arr,
  label_delivery_date,
  label_due_date,
  label_use_case,
  label_use_case_delivery_manager,
} from 'components/Settings/Preferences/CustomLabelCookieHelper';
import CognisaasToast from 'components/shared/CognisaasToast/CognisaasToast';
import DeleteModal from 'components/shared/CognisaasModal/DeleteModal';
import { selectUsecaseCustomFieldsDetail } from 'store/modules/Usecase/selector';
import useCustomNames from 'components/shared/CustomNames/useCustomNames';
import NAMED_CONSTATNTS from 'components/shared/CustomNames/Constants';
import React from 'react';
import CognisaaasTooltip from 'components/shared/CognisaasTooltip/CognisaasTooltip';
import { getUserActiveState } from 'utils/userHelpers';
import { isSuperUser } from '../../../../../../utils/getUid';
import { makeDate } from 'components/ProjectModule/utils';

interface props {
  drawerState: boolean;
  onClose: any;
  usecaseDetails: any;
  client: any;
  tasks: any;
}

const EditUsecaseDrawer = (props: props) => {
  const usecaseDetails = props.usecaseDetails;
  useEffect(() => {
    dispatch(getProjectMembers(usecaseDetails?.project_uid));
  }, [usecaseDetails]);
  const projectMembers = useAppSelector(selectProjectMembers);
  const usecaseCustomFieldsWithDetail = useAppSelector(selectUsecaseCustomFieldsDetail);
  const [projectMembersList, setprojectMembersList] = useState<{ id: string; name: string }[]>([]);
  useEffect(() => {
    const tempArr: { id: string; name: string; is_active: string; user_uid: string }[] = [];
    projectMembers.map((ele: any) => {
      if (ele.is_client_contact == 0)
        tempArr.push({ id: ele.user_uid, name: ele.user_username, is_active: ele.is_active, user_uid: ele.user_uid });
    });
    setprojectMembersList(tempArr);
  }, [projectMembers]);

  const navigate = useNavigate();
  const params = useParams();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [alertDetails, setAlertDetails] = useState<{
    isVisible: boolean;
    type: 'success' | 'failure';
    message: string;
  }>({ isVisible: false, type: 'success', message: '' });
  const selectedProject = useAppSelector(selectProjectInformation);
  const customerDetail = useAppSelector(selectCustomerDetail);
  const dispatch = useDispatch();
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const {
    usecaseCategoryDropdownValues,
    getStandardNameMappedValue,
    getCustomNameMappedValue,
    usecaseStagesDropdownValues,
    usecasePrioritiesDropdownValues,
  } = useCustomNames();
  const usecaseCategories = usecaseCategoryDropdownValues.map((item: any) => item.custom_category_name);
  const usecaseStages = usecaseStagesDropdownValues.map((item: any) => item.custom_stage_name);
  const usecasePriorities = usecasePrioritiesDropdownValues.map((item: any) => item.custom_priority_name);
  const parseLines = (value: string) => value?.replace(/(\\n)/g, '\n').replace(/(\\)/g, '');
  const [isPlannedDateDisable, setIsPlannedDateDisable] = useState(customerDetail.freeze_planned_date_usecase == '1');

  useEffect(() => {
    setIsPlannedDateDisable(checkIsPlannedDateDisable);
  }, []);

  useEffect(() => {
    dispatch(getAllUsecaseCustomFieldsWithValue(usecaseDetails.case_uid as string));
  }, []);

  const handleDelete = () => {
    const details = {
      case_uid: usecaseDetails.case_uid,
    };

    dispatch(deleteUsecase(details));
    const auditDetail = {
      case_uid: usecaseDetails.case_uid,
      user_uid: getLoggedInUserUid(),
      field: 'Usecase',
      modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      old_value: '',
      new_value: '',
    };
    dispatch(addUsecaseAuditTrial(auditDetail));
    navigate(`/client-list/client/${props.client.client_uid}`);
  };

  const checkIsPlannedDateDisable = () => {
    if (isSuperUser()) {
      return false;
    }
    if (customerDetail.freeze_planned_date_usecase == '1') {
      for (let i = 0; i < props.tasks.length; i++) {
        if (props.tasks[i].task_stage.toLowerCase() !== 'not started') {
          return true;
        }
      }
    }
    return false;
  };

  const initialValues = {
    projectName: usecaseDetails.case_name,
    projectStage: getCustomNameMappedValue(usecaseDetails.case_stage, NAMED_CONSTATNTS.usecase_stage_key),
    projectPriority: getCustomNameMappedValue(usecaseDetails.case_priority, NAMED_CONSTATNTS.usecase_priority_key),
    projectCategory: getCustomNameMappedValue(usecaseDetails.case_needed_status, NAMED_CONSTATNTS.usecase_category_key),
    projectDeliveryManager: {
      id: getUserUid(usecaseDetails.case_deliverymanager),
      name: usecaseDetails.case_deliverymanager,
    },
    projectFees: usecaseDetails.case_fees == null ? 0 : usecaseDetails.case_fees,
    projectPlannedStartDate:
      usecaseDetails.case_planned_startdate != '0000-00-00 00:00:00' && usecaseDetails.case_planned_startdate != null
        ? usecaseDetails.case_planned_startdate
        : '01-01-1970 00:00:00',
    projectStartDate: usecaseDetails.case_startdate,
    projectDueDate: usecaseDetails.case_requiredbydate,
    projectDeliverydate: usecaseDetails.case_deliverydate,
    projectDescription: usecaseDetails.case_description,
    projectReference: usecaseDetails.case_reference,
    projectEtaForDelivery: usecaseDetails.eta_forcasedelivery,
    projectExpansionValue: usecaseDetails.case_expansionvalue,
    projectARR: usecaseDetails.case_ARR,
  };

  const sendAuditTrialForProject = async (newForm: any, oldForm: any) => {
    for (const i in newForm) {
      if (newForm[i] != oldForm[i]) {
        let detail: any = {};
        if (i == 'projectName') {
          detail = {
            case_uid: usecaseDetails.case_uid,
            user_uid: getLoggedInUserUid(),
            field: 'Use case Name',
            modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
            old_value: oldForm[i],
            new_value: newForm[i],
          };
          await dispatch(addUsecaseAuditTrial(detail));
        } else if (i == 'projectStage') {
          detail = {
            case_uid: usecaseDetails.case_uid,
            user_uid: getLoggedInUserUid(),
            field: 'Stage',
            modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
            old_value: oldForm[i],
            new_value: newForm[i],
          };
          await dispatch(addUsecaseAuditTrial(detail));
        } else if (i == 'projectCategory') {
          detail = {
            case_uid: usecaseDetails.case_uid,
            user_uid: getLoggedInUserUid(),
            field: 'Category',
            modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
            old_value: oldForm[i],
            new_value: newForm[i],
          };
          dispatch(addUsecaseAuditTrial(detail));
        } else if (i == 'projectPriority') {
          detail = {
            case_uid: usecaseDetails.case_uid,
            user_uid: getLoggedInUserUid(),
            field: 'Priority',
            modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
            old_value: oldForm[i],
            new_value: newForm[i],
          };
          await dispatch(addUsecaseAuditTrial(detail));
        } else if (i == 'projectDescription') {
          detail = {
            case_uid: usecaseDetails.case_uid,
            user_uid: getLoggedInUserUid(),
            field: 'Description',
            modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
            old_value: oldForm[i],
            new_value: newForm[i],
          };
          await dispatch(addUsecaseAuditTrial(detail));
        } else if (i == 'projectDeliveryDate') {
          detail = {
            case_uid: usecaseDetails.case_uid,
            user_uid: getLoggedInUserUid(),
            field: 'Delivery Date',
            modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
            old_date: oldForm[i],
            new_date: newForm[i],
          };
          await dispatch(addUsecaseAuditTrial(detail));
        } else if (i == 'projectDeliveryManager') {
          if (newForm['projectDeliveryManager'] != oldForm['projectDeliveryManager'].name) {
            detail = {
              case_uid: usecaseDetails.case_uid,
              user_uid: getLoggedInUserUid(),
              field: 'Delivery Manager',
              modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
              old_value: getUserUid(oldForm[i].name),
              new_value: getUserUid(newForm[i]),
            };
            await dispatch(addUsecaseAuditTrial(detail));
          }
        } else if (i == 'projectPlannedStartDate') {
          detail = {
            case_uid: usecaseDetails.case_uid,
            user_uid: getLoggedInUserUid(),
            field: 'Planned Start Date',
            modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
            old_date: oldForm[i],
            new_date: newForm[i],
          };
          dispatch(addUsecaseAuditTrial(detail));
        } else if (i == 'projectStartDate') {
          detail = {
            case_uid: usecaseDetails.case_uid,
            user_uid: getLoggedInUserUid(),
            field: 'Start Date',
            modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
            old_date: oldForm[i],
            new_date: newForm[i],
          };
          await dispatch(addUsecaseAuditTrial(detail));
        } else if (i == 'projectDueDate') {
          detail = {
            case_uid: usecaseDetails.case_uid,
            user_uid: getLoggedInUserUid(),
            field: 'Due Date',
            modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
            old_date: oldForm[i],
            new_date: newForm[i],
          };
          await dispatch(addUsecaseAuditTrial(detail));
        } else if (i == 'projectFees') {
          detail = {
            case_uid: usecaseDetails.case_uid,
            user_uid: getLoggedInUserUid(),
            field: 'Implementation Fee',
            modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
            old_value: oldForm[i],
            new_value: newForm[i],
          };
          dispatch(addUsecaseAuditTrial(detail));
        } else if (i == 'projectEtaForDelivery') {
          detail = {
            case_uid: usecaseDetails.case_uid,
            user_uid: getLoggedInUserUid(),
            field: 'Estimated Delivery Date',
            modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
            old_date: oldForm[i],
            new_date: newForm[i],
          };
          dispatch(addUsecaseAuditTrial(detail));
        } else if (i == 'projectDeliverydate') {
          detail = {
            case_uid: usecaseDetails.case_uid,
            user_uid: getLoggedInUserUid(),
            field: 'Delivery Date',
            modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
            old_date: oldForm[i],
            new_date: newForm[i],
          };
          dispatch(addUsecaseAuditTrial(detail));
        }
      }
    }
  };

  const updateProjectDetail = async (form: any) => {
    const value = {
      association_uid: params.associationId,
      case_uid: usecaseDetails.case_uid,
      case_name: form.projectName,
      case_fees: form.projectFees,
      case_ARR: form.projectARR,
      case_comment: usecaseDetails.case_comment,
      case_description: form.projectDescription,
      case_reference: form.projectReference,
      case_value: usecaseDetails.project_value,
      case_needed_status: form.projectCategory,
      case_stage: form.projectStage,
      user_uid: getUserUid(form.projectDeliveryManager),
      case_deliverymanager: form.projectDeliveryManager,
      case_redinessstate_status: usecaseDetails.case_readinessstate_status,
      case_expansionvalue: form.projectExpansionValue,
      case_startdate: form.projectStartDate,
      case_planned_startdate: form.projectPlannedStartDate,
      case_deliverydate: form.projectDeliverydate,
      eta_forcasedelivery: form.projectEtaForDelivery,
      case_requiredbydate: form.projectDueDate,
      case_priority: form.projectPriority,
      feature: usecaseDetails.feature,
    };
    await dispatch(updateUsecaseByUid(value));
    const detail = {
      project_uid: usecaseDetails.project_uid,
      association_uid: params.associationId,
    };
    isPlannedDatesOfProjectChanges(usecaseDetails, value);
    await dispatch(getProjectInformation(detail));
    await dispatch(getUsecaseAudit(usecaseDetails.case_uid));
    await dispatch(updateCaseWithfeatureStatus(selectedProject.client_uid));
    props.onClose();
  };

  const closeDrawer = () => {
    props.onClose();
  };

  const isMandatoryFieldsEmpty = () => {
    for (const value of usecaseCustomFieldsWithDetail) {
      if (value.mandatory == '1' && value.field_value.length === 0) return true;
    }
    return false;
  };

  const isPlannedDatesOfProjectChanges = async (projectDetails: any, newFormData: any) => {
    if (makeDate(projectDetails.case_planned_startdate) != makeDate(newFormData.case_planned_startdate)) {
      const difference = differenceInDays(
        makeDate(newFormData.case_planned_startdate),
        makeDate(projectDetails.case_planned_startdate)
      );
      const detail = {
        project_uid: projectDetails.case_uid,
        shifted_by: difference,
        updated_date: makeDate(projectDetails.case_planned_startdate),
        entity: 'USECASE',
      };
      // TODO: API will handle for both project and usecase
      await dispatch(handleProjectPlannedDateUpdate(detail));
    }
  };

  const projectDetailForm = () => {
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={editProjectSchema}
        onSubmit={(values) => {
          if (isMandatoryFieldsEmpty()) {
            setAlertDetails({
              isVisible: true,
              type: 'failure',
              message: `Please fill out mandatory Custom fields before editing this ${label_use_case}`,
            });
            return;
          }
          if (values.projectName.trim()) {
            const newValues = {
              ...values,
              projectCategory: getStandardNameMappedValue(
                values.projectCategory,
                NAMED_CONSTATNTS.usecase_category_key
              ),
              projectStage: getStandardNameMappedValue(values.projectStage, NAMED_CONSTATNTS.usecase_stage_key),
              projectPriority: getStandardNameMappedValue(
                values.projectPriority,
                NAMED_CONSTATNTS.usecase_priority_key
              ),
              projectDeliveryManager: values.projectDeliveryManager
                ? values.projectDeliveryManager.name
                : 'Not Assigned',
              projectPlannedStartDate: format(new Date(values.projectPlannedStartDate), 'yyyy-MM-dd HH:mm:ss'),
              projectStartDate: format(new Date(values.projectStartDate), 'yyyy-MM-dd HH:mm:ss'),
              projectDueDate: format(new Date(values.projectDueDate), 'yyyy-MM-dd HH:mm:ss'),
              projectEtaForDelivery: format(new Date(values.projectEtaForDelivery), 'yyyy-MM-dd HH:mm:ss'),
              projectDeliverydate: format(new Date(values.projectDeliverydate), 'yyyy-MM-dd HH:mm:ss'),
            };
            sendAuditTrialForProject(newValues, {
              ...initialValues,
              projectCategory: getStandardNameMappedValue(
                initialValues.projectCategory,
                NAMED_CONSTATNTS.usecase_category_key
              ),
              projectStage: getStandardNameMappedValue(initialValues.projectStage, NAMED_CONSTATNTS.usecase_stage_key),
              projectPriority: getStandardNameMappedValue(
                values.projectPriority,
                NAMED_CONSTATNTS.usecase_priority_key
              ),
            });
            updateProjectDetail(newValues);
          } else {
            setAlertDetails({
              isVisible: true,
              type: 'failure',
              message: 'Use Case Name cannot be empty.',
            });
          }
        }}
      >
        {(props: any) => {
          return (
            <form>
              <Stack mt={2} direction="row">
                <Stack>
                  <Typography variant="subtitle2">{label_use_case} Name*</Typography>
                  <TextField
                    id="projectName"
                    name="projectName"
                    size="small"
                    onChange={props.handleChange}
                    value={props.values.projectName}
                    onBlur={props.handleBlur}
                    error={props.touched.projectName && Boolean(props.errors.projectName)}
                    helperText={props.touched.projectName && props.errors.projectName}
                  ></TextField>
                </Stack>
              </Stack>
              <Stack direction="row" mt={2} spacing={2}>
                <Stack>
                  <Typography variant="subtitle2">Priority</Typography>
                  <CognisaasSelect
                    width="200px"
                    name="projectPriority"
                    value={props.values.projectPriority}
                    // placeholder={'Select'}
                    onChange={props.handleChange}
                    validValues={usecasePriorities}
                  />
                </Stack>
                <Stack>
                  <Typography variant="subtitle2">Category</Typography>
                  <CognisaasSelect
                    width="200px"
                    id="projectCategory"
                    name="projectCategory"
                    value={props.values.projectCategory}
                    validValues={usecaseCategories}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    error={props.touched.projectCategory && Boolean(props.errors.projectCategory)}
                    helperText={props.touched.projectCategory && props.errors.projectCategory}
                  />
                </Stack>
              </Stack>
              <Stack>
                {getStandardNameMappedValue(props.values.projectCategory, NAMED_CONSTATNTS.usecase_category_key) ==
                  'Expand' && (
                  <Stack sx={{ mt: '10px' }}>
                    <Typography variant="subtitle2">{`${getCustomNameMappedValue(
                      'Expand',
                      NAMED_CONSTATNTS.usecase_category_key
                    )} ${label_arr} (${getSymbolFromCurrency(customerDetail?.currency_code)})`}</Typography>
                    <TextField
                      type="number"
                      style={{ width: '200px' }}
                      size="small"
                      placeholder={`${getSymbolFromCurrency(customerDetail?.currency_code)} 0`}
                      id="projectExpansionValue"
                      name="projectExpansionValue"
                      value={props.values.projectExpansionValue}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      error={props.touched.projectExpansionValue && Boolean(props.errors.projectExpansionValue)}
                      helperText={props.touched.projectExpansionValue && props.errors.projectExpansionValue}
                    ></TextField>
                  </Stack>
                )}
              </Stack>
              <Stack mt={2} direction="row" spacing={2}>
                <Stack>
                  <Typography variant="subtitle2">{label_use_case_delivery_manager} </Typography>
                  <CognisaasUserSelect
                    width="200px"
                    name="projectDeliveryManager"
                    id="projectDeliveryManager"
                    selectedOption={{
                      id: props.values.projectDeliveryManager.id,
                      name:
                        getUserActiveState(getUserUid(props.values.projectDeliveryManager.name), projectMembersList) ===
                        '0'
                          ? `${props.values.projectDeliveryManager.name} (Inactive)`
                          : props.values.projectDeliveryManager.name,
                      is_active: getUserActiveState(
                        getUserUid(props.values.projectDeliveryManager.name),
                        projectMembersList
                      ),
                    }}
                    allOptions={projectMembersList}
                    onChange={(val: any) => {
                      props.setFieldValue('projectDeliveryManager', {
                        name: val.target.textContent,
                        id: getUserUid(val.target.textContent),
                        is_active: getUserActiveState(getUserUid(val.target.textContent), projectMembersList),
                      });
                    }}
                    onBlur={props.handleBlur}
                    error={props.touched.projectDeliveryManager && Boolean(props.errors.projectDeliveryManager)}
                    helperText={props.touched.projectDeliveryManager && props.errors.projectDeliveryManager}
                  />
                </Stack>
                <Stack>
                  <Typography variant="subtitle2">
                    {`Implementation Fee (${getSymbolFromCurrency(customerDetail?.currency_code)})`}
                  </Typography>
                  <TextField
                    type="number"
                    style={{ width: '200px' }}
                    size="small"
                    placeholder={`${getSymbolFromCurrency(customerDetail?.currency_code)}0`}
                    id="projectFees"
                    name="projectFees"
                    value={props.values.projectFees}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    error={props.touched.projectFees && Boolean(props.errors.projectFees)}
                    helperText={props.touched.projectFees && props.errors.projectFees}
                  ></TextField>
                </Stack>
              </Stack>
              <Stack spacing={2} mt={2} direction="row">
                <Stack>
                  <Stack>
                    <Typography variant="subtitle2">Stage</Typography>
                    <CognisaasSelect
                      width="200px"
                      name="projectStage"
                      value={props.values.projectStage}
                      validValues={usecaseStages}
                      onChange={props.handleChange}
                    />
                  </Stack>
                </Stack>

                <Stack>
                  {!isPlannedDateDisable ? (
                    <>
                      <Typography variant="subtitle2">Planned Start Date*</Typography>
                      <CognisaasCalenderNew
                        width="200px"
                        name="projectPlannedStartDate"
                        value={
                          props.values.projectPlannedStartDate ? new Date(props.values.projectPlannedStartDate) : null
                        }
                        handleChange={(e: any) => props.setFieldValue('projectPlannedStartDate', new Date(e))}
                        disabled={isPlannedDateDisable}
                      />
                    </>
                  ) : (
                    <>
                      <CognisaaasTooltip
                        title={
                          <Typography variant="subtitle1" style={{ padding: '10px' }}>
                            Planned start date cannot be changed once the {label_use_case.toLowerCase()} has been
                            started. Contact an admin if you want to change the planned start date.
                          </Typography>
                        }
                      >
                        <div>
                          <React.Fragment>
                            <Typography variant="subtitle2">Planned Start Date*</Typography>
                            <CognisaasCalenderNew
                              width="200px"
                              name="projectPlannedStartDate"
                              value={
                                props.values.projectPlannedStartDate
                                  ? new Date(props.values.projectPlannedStartDate)
                                  : null
                              }
                              handleChange={(e: any) => props.setFieldValue('projectPlannedStartDate', new Date(e))}
                              disabled={isPlannedDateDisable}
                            />
                          </React.Fragment>
                        </div>
                      </CognisaaasTooltip>
                    </>
                  )}
                </Stack>
              </Stack>
              <Stack mt={2} direction="row" spacing={2}>
                <Stack>
                  <Typography variant="subtitle2">Actual Start Date</Typography>
                  <CognisaasCalenderNew
                    width="200px"
                    name="projectStartDate"
                    value={props.values.projectStartDate ? new Date(props.values.projectStartDate) : null}
                    handleChange={(e: any) => props.setFieldValue('projectStartDate', new Date(e))}
                  />
                </Stack>
                <Stack>
                  {!isPlannedDateDisable ? (
                    <>
                      <Typography variant="subtitle2">{label_due_date}*</Typography>
                      <CognisaasCalenderNew
                        width="200px"
                        name="projectDueDate"
                        value={props.values.projectDueDate ? new Date(props.values.projectDueDate) : null}
                        handleChange={(e: any) => props.setFieldValue('projectDueDate', new Date(e))}
                        disabled={isPlannedDateDisable}
                      />
                    </>
                  ) : (
                    <>
                      <CognisaaasTooltip
                        title={
                          <Typography variant="subtitle1" style={{ padding: '10px' }}>
                            {label_due_date} cannot be changed once the ${label_use_case.toLowerCase()} has been
                            started. Contact an admin if you want to change the {label_due_date.toLowerCase()}.
                          </Typography>
                        }
                      >
                        <div>
                          <React.Fragment>
                            <Typography variant="subtitle2">{label_due_date}*</Typography>
                            <CognisaasCalenderNew
                              width="200px"
                              name="projectDueDate"
                              value={props.values.projectDueDate ? new Date(props.values.projectDueDate) : null}
                              handleChange={(e: any) => props.setFieldValue('projectDueDate', new Date(e))}
                              disabled={isPlannedDateDisable}
                            />
                          </React.Fragment>
                        </div>
                      </CognisaaasTooltip>
                    </>
                  )}
                </Stack>
              </Stack>
              <Stack mt={2} direction="row" spacing={2}>
                <Stack>
                  <Typography variant="subtitle2">Estimated Delivery Date</Typography>
                  <CognisaasCalenderNew
                    name="projectEtaForDelivery"
                    width="200px"
                    value={props.values.projectEtaForDelivery ? new Date(props.values.projectEtaForDelivery) : null}
                    handleChange={(e: any) => props.setFieldValue('projectEtaForDelivery', new Date(e))}
                  />
                </Stack>
                <Stack mt={2} direction="row" spacing={2}>
                  {props.values.projectStage !== getCustomNameMappedValue('Go-Live', 'case_stage') &&
                  props.values.projectStage !== getCustomNameMappedValue('Adoption', 'case_stage') ? (
                    <div>
                      <CognisaaasTooltip
                        title={
                          <Typography variant="subtitle1" style={{ padding: '10px' }}>
                            {label_use_case} {label_delivery_date.toLowerCase()} date will be system generated when this{' '}
                            {label_use_case.toLowerCase()} is delivered.
                          </Typography>
                        }
                      >
                        <div>
                          <React.Fragment>
                            <Typography variant="subtitle2">{label_delivery_date}</Typography>
                            <CognisaasCalenderNew
                              disabled={
                                props.values.projectStage !== getCustomNameMappedValue('Go-Live', 'case_stage') &&
                                props.values.projectStage !== getCustomNameMappedValue('Adoption', 'case_stage')
                              }
                              name="projectDeliverydate"
                              width="200px"
                              value={
                                props.values.projectDeliverydate ? new Date(props.values.projectDeliverydate) : null
                              }
                              handleChange={(e: any) => props.setFieldValue('projectDeliverydate', new Date(e))}
                            />
                          </React.Fragment>
                        </div>
                      </CognisaaasTooltip>
                    </div>
                  ) : (
                    <Stack>
                      <Typography variant="subtitle2">{label_delivery_date}</Typography>
                      <CognisaasCalenderNew
                        disabled={
                          props.values.projectStage !== getCustomNameMappedValue('Go-Live', 'case_stage') &&
                          props.values.projectStage !== getCustomNameMappedValue('Adoption', 'case_stage')
                        }
                        name="projectDeliverydate"
                        width="200px"
                        value={props.values.projectDeliverydate ? new Date(props.values.projectDeliverydate) : null}
                        handleChange={(e: any) => props.setFieldValue('projectDeliverydate', new Date(e))}
                      />
                    </Stack>
                  )}
                </Stack>
              </Stack>
              <Stack mt={2} direction="row">
                <Stack>
                  <Typography variant="subtitle2">{label_use_case} Description</Typography>
                  <TextareaAutosize
                    name="projectDescription"
                    maxRows={15}
                    aria-label="maximum height"
                    placeholder={label_use_case + ' decsription'}
                    defaultValue={parseLines(props.values.projectDescription)}
                    style={{ width: 420, height: 88 }}
                    onChange={props.handleChange}
                  />
                </Stack>
              </Stack>
              <Grid mt={3} mb={2} spacing={1} container direction="row" justifyContent="flex-end" alignItems="flex-end">
                <Grid item>
                  <CognisaasButton
                    clickHandler={props.handleSubmit}
                    isOutlined={false}
                    disabled={!props.isValid || props.dirty == false}
                    label="Save"
                  />
                </Grid>
                <Grid item>
                  <CognisaasButton clickHandler={closeDrawer} isOutlined={true} label="Cancel" />
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Formik>
    );
  };

  return (
    <>
      <CognisaasToast
        message={alertDetails.message}
        type={alertDetails.type}
        open={alertDetails.isVisible}
        onClose={() => {
          setAlertDetails({ isVisible: false, type: alertDetails.type, message: '' });
        }}
      />
      <Drawer open={props.drawerState} onClose={props.onClose} anchor="right">
        <Grid spacing={2} mx={2}>
          <Stack direction="row" justifyContent={'space-between'}>
            <Typography my={3} sx={{ marginTop: '70px' }} variant="h2">
              Edit {label_use_case} Details
            </Typography>
            <IconButton disableRipple sx={{ marginTop: '60px' }} onClick={handleClick}>
              <MoreVertIcon />
            </IconButton>
          </Stack>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            style={{ padding: '0px' }}
          >
            <MenuItem
              onClick={() => {
                setOpenDeleteModal(true);
                // handleDelete();
              }}
            >
              <Typography variant="inherit">Delete {label_use_case}</Typography>
            </MenuItem>
          </Menu>
          <Stack direction="row">
            <Avatar> {props.usecaseDetails.case_name.slice(0, 1)}</Avatar>
            <Typography ml={1} mt={1} variant="subtitle1">
              {props.usecaseDetails.case_name}
            </Typography>
          </Stack>

          {projectDetailForm()}
        </Grid>
      </Drawer>
      <DeleteModal
        isOpen={openDeleteModal}
        setIsOpen={setOpenDeleteModal}
        message={`Are you sure you want to delete this ${label_use_case.toLowerCase()}`}
        onDelete={handleDelete}
      />
    </>
  );
};

export default EditUsecaseDrawer;
