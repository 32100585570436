import { Box } from '@mui/system';
import { ResponsiveScatterPlot } from '@nivo/scatterplot';

interface BubbleChartProps {
  height: number;
  width: number;
  data: any[];
}

const tempData = [
  {
    id: 'myData',
    data: [
      {
        x: 7,
        y: 34,
        size: 0,
      },
      {
        x: 61,
        y: 13,
        size: 4,
      },
      {
        x: 90,
        y: 29,
        size: 4,
      },
    ],
  },
];

const BubbleChart = (props: BubbleChartProps) => {
  return (
    <Box sx={{ height: props.height, width: props.width }}>
      <ResponsiveScatterPlot
        data={tempData}
        nodeSize={{ key: 'data.size', values: [0, 4], sizes: [9, 32] }}
        margin={{ top: 60, right: 140, bottom: 70, left: 90 }}
        xScale={{ type: 'linear', min: 0, max: 'auto' }}
        xFormat=">-.2f"
        yScale={{ type: 'linear', min: 0, max: 'auto' }}
        yFormat=">-.2f"
        blendMode="multiply"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'weight',
          legendPosition: 'middle',
          legendOffset: 46,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'size',
          legendPosition: 'middle',
          legendOffset: -60,
        }}
        legends={[
          {
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 130,
            translateY: 0,
            itemWidth: 100,
            itemHeight: 12,
            itemsSpacing: 5,
            itemDirection: 'left-to-right',
            symbolSize: 12,
            symbolShape: 'circle',
            effects: [
              {
                on: 'hover',
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    </Box>
  );
};

export default BubbleChart;
