import { GanttStatic } from 'dhtmlx-gantt';
import { TGanttItem } from './types';

export const statusColorMap: any = {
  blue: '#4390E1',
  green: '#4F9F52',
  amber: '#EFA958',
  grey: '#7a7a7a',
  red: '#E75B5C',
  grey1: '#3a3a3a',
};

export function getGanttColumns(gantt: GanttStatic, customLabels: any) {
  const componentColumns = [
    {
      name: 'text',
      label: `${customLabels.taskNameColLabel}`,
      tree: true,
      width: 140,
      editor: false,
      resize: true,
      align: 'left',
    },
    {
      name: 'holder',
      label: `${customLabels.taskOwnerColLabel}`,
      width: 100,
      editor: false,
      resize: true,
      align: 'center',
    },
    {
      name: 'task_stage',
      label: 'Stage',
      width: 100,
      editor: false,
      resize: true,
      align: 'center',
      template: function (item: any) {
        return item.entity === 'task' && item.task_stage ? item.task_stage : '-';
      },
    },
    {
      name: 'planned_start',
      label: 'Planned start date',
      width: 120,
      editor: false,
      resize: true,
      align: 'center',
      type: 'date',
      template: function getPlannedStartDate(item: TGanttItem) {
        return item.planned_start ? item.planned_start : null;
      },
    },
    {
      name: 'planned_end',
      label: 'Planned end date',
      width: 120,
      editor: false,
      resize: true,
      align: 'center',
      type: 'date',
      template: function getPlannedEndDate(item: TGanttItem) {
        return item.planned_end ? item.planned_end : null;
      },
    },
    {
      name: 'planned_duration',
      label: 'Duration',
      width: 70,
      editor: false,
      resize: false,
      align: 'center',
      template: function getPlannedDuration(item: any) {
        if (typeof item.planned_duration === 'string') {
          return item.planned_duration;
        } else {
          const formatter = gantt.ext.formatters.durationFormatter({
            enter: 'day',
            store: 'day',
            format: 'day',
          });
          return item.planned_duration !== undefined ? formatter.format(item.planned_duration) : null;
        }
      },
    },
  ];
  return componentColumns;
}

export const dayNameList: string[] = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
export const dayNameMap: Record<string, number> = {
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
  sunday: 7,
};

function getGanttResourceColumns(gantt: GanttStatic, customLabels: any, workingHours: number) {
  const resourceColumns = [
    {
      name: 'name',
      label: `${customLabels.taskOwnerColLabel}`,
      tree: true,
      template: function getResourceNameTemplate(resource: { text: string }) {
        return resource.text;
      },
      resize: true,
    },
    {
      name: 'workload',
      label: 'Workload',
      align: 'center',
      template: function getResourceWorkloadTemplate(resource: { id: string }) {
        let tasks = [];
        const store = gantt.getDatastore(gantt.config.resource_store),
          field = gantt.config.resource_property;

        if (store.hasChild(resource.id)) {
          tasks = gantt.getTaskBy(field, store.getChildren(resource.id));
        } else {
          tasks = gantt.getTaskBy(field, resource.id);
        }

        let totalDuration = 0;
        for (let i = 0; i < tasks.length; i++) {
          totalDuration += tasks[i].duration;
        }

        return (totalDuration || 0) * workingHours + 'h';
      },
      resize: true,
    },
    {
      name: 'capacity',
      label: 'Capacity',
      align: 'center',
      template: function (resource: { id: string | number }) {
        const store = gantt.getDatastore(gantt.config.resource_store);
        const n = store.hasChild(resource.id) ? store.getChildren(resource.id).length : 1;

        const state = gantt.getState();

        return (
          gantt.calculateDuration({
            start_date: state.min_date,
            end_date: state.max_date,
          }) *
            n *
            workingHours +
          'h'
        );
      },
      resize: true,
    },
  ];
  return resourceColumns;
}

export function getGanttResourceLayout(gantt: GanttStatic, customLabels: any, workingHours: number) {
  const componentLayout = {
    css: 'gantt_container',
    rows: [
      {
        cols: [
          { view: 'grid', group: 'grids', scrollY: 'scrollVer' },
          { resizer: true, width: 1 },
          { view: 'timeline', scrollX: 'scrollHor', scrollY: 'scrollVer' },
          { view: 'scrollbar', id: 'scrollVer', group: 'vertical' },
        ],
        gravity: 1,
      },
      { resizer: true, width: 1 },
      {
        config: {
          columns: getGanttResourceColumns(gantt, customLabels, workingHours),
        },
        cols: [
          {
            view: 'resourceGrid',
            group: 'grids',
            width: 435,
            scrollY: 'resourceVScroll',
          },
          { resizer: true, width: 1 },
          {
            view: 'resourceTimeline',
            // view: 'resourceHistogram',
            // capacity: workingHours,
            scrollX: 'scrollHor',
            scrollY: 'resourceVScroll',
          },
          { view: 'scrollbar', id: 'resourceVScroll', group: 'vertical' },
        ],
        gravity: 1,
      },
      { view: 'scrollbar', id: 'scrollHor' },
    ],
  };
  return componentLayout;
}

export function getZoomConfig(gantt: GanttStatic) {
  const zoomConfig = {
    levels: [
      {
        name: 'day',
        scale_height: 60,
        min_column_width: 80,
        scales: [
          { unit: 'month', format: '%F, %Y' },
          {
            unit: 'day',
            step: 1,
            format: '%D',
            css: function daysStyle(date: Date) {
              if (!gantt.isWorkTime({ date: date, unit: 'day' })) {
                return 'weekend';
              }
              return '';
            },
          },
          {
            unit: 'day',
            step: 1,
            format: '%d',
            css: function daysStyle(date: Date) {
              if (!gantt.isWorkTime({ date: date, unit: 'day' })) {
                return 'weekend';
              }
              return '';
            },
          },
        ],
      },
      {
        name: 'month',
        scale_height: 60,
        min_column_width: 120,
        scales: [
          { unit: 'month', format: '%F, %Y' },
          { unit: 'week', format: 'Week %W' },
        ],
      },
      {
        name: 'quarter',
        height: 60,
        min_column_width: 90,
        scales: [
          { unit: 'month', step: 1, format: '%M' },
          {
            unit: 'quarter',
            step: 1,
            format: function getZoomFormat(date: Date) {
              const dateToStr = gantt.date.date_to_str('%M');
              const endDate = gantt.date.add(gantt.date.add(date, 3, 'month'), -1, 'day');
              return dateToStr(date) + ' - ' + dateToStr(endDate);
            },
          },
        ],
      },
      {
        name: 'year',
        scale_height: 60,
        min_column_width: 30,
        scales: [{ unit: 'year', step: 1, format: '%Y' }],
      },
    ],
  };
  return zoomConfig;
}

export const ganttPluginsConfig = {
  tooltip: true, // Adding plugin for gantt tooltip
  undo: true, // Adding plugin for gantt undo
};
