import { apis } from '../axiosInstance';
import { commonRoutes } from '../route';

import Cookies from 'universal-cookie';

const cookies = new Cookies();

const getGlobalSearchContentByCustomerUid = (requestObject: any) =>
  apis.phpAxiosInstance.post(
    commonRoutes.getGlobalSearchContentByCustomerUid(),
    {
      customer_uid: cookies.get('cogni_customer_uid') as string,
      tag: requestObject?.tag,
      value: requestObject.value,
    },
    { headers: { JWT: 'true' } }
  );

export const GlobalSearchAPI = {
  getGlobalSearchContentByCustomerUid,
};
