import { Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';

interface LegendProps {
  color: string;
  name: string;
}
const SmallLegend = (props: LegendProps) => {
  return (
    <Stack
      direction={'row'}
      spacing={1}
      alignItems={'center'}
      sx={{
        // border: '1px solid #E5E5E5',
        // borderRadius: '4px',
        padding: '4px 8px',
        width: '140px',
        marginRight: '8px',
        margin: '0px 0px',
      }}
    >
      <Box
        component={'span'}
        sx={{
          height: '16px',
          width: '15px',
          background: props.color,
          borderRadius: '4px',
          border: `1px solid ${props.color}`,
        }}
      ></Box>

      <Typography variant={'h2'}>{props.name}</Typography>
    </Stack>
  );
};

export default SmallLegend;
