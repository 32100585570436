import {
  Box,
  Grid,
  Typography,
  Button,
  ToggleButton,
  Stack,
  ToggleButtonGroup,
  MenuItem,
  Select,
  TextField,
  //   styled,
  Drawer,
} from '@mui/material';
import { Formik } from 'formik';
// import Switch, { SwitchProps } from '@mui/material/Switch';
import { label_client, label_task } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { newTaskTemplateSchema } from 'utils/validations';
import { userPermissionSchema } from 'components/Reports/SideDrawer/types';
import CognisaasTabs from 'components/shared/CognisaasTabs';
import { useState } from 'react';
import {
  //   addTemplateTaskInitialValues,
  updateTaskInitialValues,
  valueToMinutes,
} from '../Project/AddProjectTemplate/helper';
import { templateTaskTabs } from '../../types';
// import { CLIENT_TASK, TEAM_TASK } from '../../types';
// import { getUserUid } from 'utils/getUid';
import CognisaasUserSelect from 'components/shared/CognisaasSelect/CognisaasUserSelect';
import useUserDropDownData from 'components/shared/Hooks/useUserDropDownData';
import { getUserUid } from 'utils/getUid';
import IOSSwitch from './IOSSwitch';
import Dependency from 'components/Templates/Dependency/Dependency';
import DeleteModal from 'components/shared/CognisaasModal/DeleteModal';
import useCustomNames from 'components/shared/CustomNames/useCustomNames';
import NAMED_CONSTATNTS from 'components/shared/CustomNames/Constants';
import { getUserActiveState } from 'utils/userHelpers';

const UpdateTemplateTaskDrawer = (props: any) => {
  const userPermissions = props.userPermissions;
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const { taskPrioritiesDropdownValues, getCustomNameMappedValue, getStandardNameMappedValue } = useCustomNames();
  const taskAllPriorities = taskPrioritiesDropdownValues.map((item: any) => item.custom_priority_name);

  let initialValues;
  if (props.taskDetail) {
    initialValues = updateTaskInitialValues(props.taskDetail);
  }

  const companyUsersDropDownData = useUserDropDownData('company');
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const deleteThisTask = () => {
    console.log('props task delete 123', props);
    props.handleDeleteTask(
      props.taskDetail.template_task_uid,
      props.taskDetail.task_type,
      props.taskDetail.template_project_uid ? true : false,
      props.taskDetail.template_project_uid ? props.taskDetail.template_project_uid : props.taskDetail.template_case_uid
    );
  };

  return (
    <>
      <DeleteModal
        isOpen={openDeleteModal}
        setIsOpen={setOpenDeleteModal}
        message={`Are you sure you want to delete this ${label_task.toLowerCase()}`}
        onDelete={deleteThisTask}
      />
      <Drawer
        open={props.drawerState}
        onClose={() => {
          props.onClose();
          //   resetValues();
        }}
        anchor="right"
      >
        <Box
          sx={{ width: '460px', marginTop: '60px', padding: '20px' }}
          // role="presentation"
          // onClick={toggleDrawer(anchor, false)}
          // onKeyDown={toggleDrawer(anchor, false)}
        >
          <Grid mb={2} container justifyContent="space-between">
            <Grid item>
              <Typography sx={{ fontSize: '18px', fontWeight: '500', mt: '5px' }}>Update {label_task}</Typography>
            </Grid>
            <Grid item>
              {userPermissions?.includes(userPermissionSchema.DELETE) && (
                <Button disabled={!userPermissions?.includes(userPermissionSchema.DELETE)} variant="text">
                  <Typography sx={{ color: '#E75B5C' }} onClick={() => setOpenDeleteModal(true)}>
                    delete {label_task}
                  </Typography>
                </Button>
              )}
            </Grid>
          </Grid>
          <CognisaasTabs
            tabs={[...templateTaskTabs]}
            handleTabSwitch={(e: any, index: number) => setSelectedTab(index)}
          />
          {selectedTab == 0 ? (
            <Formik
              initialValues={{ ...initialValues }}
              onSubmit={(values: any) => {
                console.log(values, 'values');
                props.addProjectTask({
                  ...values,
                  durationTime: valueToMinutes(values.durationTime),
                  sectionUid: props.taskDetail.template_section_uid,
                  ...props.taskDetail,
                });
              }}
              validationSchema={newTaskTemplateSchema}
            >
              {(formik: any) => (
                <>
                  <Typography sx={{ fontSize: '12px', fontWeight: '400', mt: '24px' }}>{label_task} Type</Typography>
                  <ToggleButtonGroup
                    color="primary"
                    value={formik.values.isTeamTask ? 'Team' : 'Client'}
                    exclusive
                    onChange={() => formik.setFieldValue('isTeamTask', !formik.values.isTeamTask)}
                    sx={{ mt: '4px' }}
                  >
                    {formik.values.isTeamTask ? (
                      <ToggleButton value="Team">Team</ToggleButton>
                    ) : (
                      <ToggleButton value="Client">{label_client}</ToggleButton>
                    )}
                  </ToggleButtonGroup>
                  <Typography sx={{ mt: '24px', fontSize: '12px', fontWeight: '400' }}>{label_task} Name*</Typography>
                  <TextField
                    disabled={!userPermissions?.includes(userPermissionSchema.EDIT)}
                    sx={{ width: '310px', mt: '4px' }}
                    placeholder={`Enter ${label_task} Name`}
                    size="small"
                    // value={taskName}
                    // onChange={(e) => setTaskName(e.target.value)}
                    id="taskName"
                    name="taskName"
                    value={formik.values.taskName}
                    onBlur={formik.handleBlur}
                    onChange={(params: any) => {
                      console.log(params.target.value, 'params');
                      formik.handleChange(params);
                      //   setTaskName(params.target.value);
                    }}
                    error={formik.touched.taskName && Boolean(formik.errors.taskName)}
                    helperText={formik.touched.taskName && formik.errors.taskName}
                  />
                  <Stack direction="row" spacing={2} sx={{ mt: '4px' }}>
                    {formik.values.isTeamTask == false ? null : (
                      <Box>
                        <Typography sx={{ fontSize: '12px', fontWeight: '400' }}>{label_task} Owner</Typography>
                        {/* <TextField placeholder="Enter task name" size="small" /> */}
                        {/* <Select
                          value={taskOwner}
                          onChange={handleChangeTaskOwner}
                          displayEmpty
                          inputProps={{ 'aria-label': 'Without label' }}
                          size="small"
                          sx={{ width: '210px', mt: '4px' }}
                        >
                          {AllCompanyUsers &&
                            AllCompanyUsers.map((ele, i) => {
                              return (
                                <MenuItem key={i} value={ele.user_username}>
                                  {ele.user_username}
                                </MenuItem>
                              );
                            })}
                          <MenuItem value="Not Assigned">Not Assigned</MenuItem>
                        </Select> */}
                        {/* <CognisaasAutocompleteSelect
                          width="210px"
                          sx={{ mt: '4px' }}
                          value={{ id: getUserUid(formik.values.taskOwner), name: taskOwner }}
                          allOptions={companyUsersDropDownData}
                          onChange={(data: any) => {
                            setTaskOwner(data.name);
                          }}
                        /> */}
                        <CognisaasUserSelect
                          disabled={!userPermissions?.includes(userPermissionSchema.EDIT)}
                          width="200px"
                          id="taskOwner"
                          name="taskOwner"
                          allOptions={companyUsersDropDownData}
                          selectedOption={{
                            id: getUserUid(formik.values.taskOwner.name),
                            name:
                              getUserActiveState(getUserUid(formik.values.taskOwner.name), companyUsersDropDownData) ===
                              '0'
                                ? `${formik.values.taskOwner.name} (Inactive)`
                                : formik.values.taskOwner.name,
                            is_active: getUserActiveState(
                              getUserUid(formik.values.taskOwner.name),
                              companyUsersDropDownData
                            ),
                          }}
                          onChange={(val: any) => {
                            console.log(val.target.textContent);
                            formik.setFieldValue('taskOwner', {
                              name: val.target.textContent,
                              id: getUserUid(val.target.textContent),
                            });
                          }}
                          onBlur={formik.handleBlur}
                          error={formik.touched.taskOwner && Boolean(formik.errors.taskOwner)}
                          helperText={formik.touched.taskOwner && formik.errors.taskOwner}
                        />
                      </Box>
                    )}
                    <Box>
                      <Typography sx={{ fontSize: '12px', fontWeight: '400' }}>Priority</Typography>
                      <Select
                        disabled={!userPermissions?.includes(userPermissionSchema.EDIT)}
                        value={getCustomNameMappedValue(formik.values.priority, NAMED_CONSTATNTS.task_priority_key)}
                        onChange={(e: any) =>
                          formik.setFieldValue(
                            'priority',
                            getStandardNameMappedValue(e.target.value, NAMED_CONSTATNTS.task_priority_key)
                          )
                        }
                        name="priority"
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        size="small"
                        sx={{ width: '210px', mt: '4px' }}
                      >
                        {taskAllPriorities.map((item: string, index: number) => (
                          <MenuItem key={index} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                  </Stack>
                  <Stack direction="row" spacing={2} sx={{ mt: '4px' }}>
                    <Box>
                      <Typography sx={{ fontSize: '12px', fontWeight: '400' }}>Target Duration</Typography>
                      <TextField
                        disabled={!userPermissions?.includes(userPermissionSchema.EDIT)}
                        placeholder="2w 3d 5h 10m"
                        sx={{ mt: '4px' }}
                        fullWidth
                        // value={duration}
                        size="small"
                        // value={totalMinuites}
                        // onChange={(e) => {
                        //   setTotalMinuites(valueToMinutes(e.target.value));
                        //   setDuration(e.target.value);
                        // }}
                        id="durationTime"
                        name="durationTime"
                        value={formik.values.durationTime}
                        onBlur={formik.handleBlur}
                        onChange={(params: any) => {
                          console.log(params.target.value, 'params');
                          formik.handleChange(params);
                          //   setTotalMinuites(valueToMinutes(params.target.value));
                          formik.setFieldValue('durationTime', params.target.value);
                          //   setDuration(params.target.value);
                        }}
                        error={formik.touched.durationTime && Boolean(formik.errors.durationTime)}
                        helperText={formik.touched.durationTime && formik.errors.durationTime}
                      />
                    </Box>
                    {formik.values.isTeamTask == false ? null : (
                      <Box>
                        <Typography sx={{ fontSize: '12px', fontWeight: '400' }}>Implementation Fee %</Typography>
                        <TextField
                          disabled={!userPermissions?.includes(userPermissionSchema.EDIT)}
                          type="number"
                          size="small"
                          sx={{ mt: '4px' }}
                          fullWidth
                          name="implementationFee"
                          value={formik.values.implementationFee}
                          onChange={(e) => {
                            formik.setFieldValue('implementationFee', e.target.value);
                          }}
                        />
                      </Box>
                    )}
                  </Stack>
                  <Typography sx={{ fontSize: '12px', fontWeight: '400', mt: '4px' }}>
                    {label_task} Description
                  </Typography>
                  <TextField
                    multiline
                    disabled={!userPermissions?.includes(userPermissionSchema.EDIT)}
                    rows={3}
                    size="small"
                    fullWidth
                    sx={{ mt: '4px' }}
                    placeholder="Enter description here"
                    name={'description'}
                    value={formik.values.description}
                    onChange={(e: any) => {
                      formik.setFieldValue('description', e.target.value);
                    }}
                  />
                  <Stack spacing={2} direction="row">
                    {formik.values.isTeamTask == false ? null : (
                      <Stack>
                        {/* <Checkbox
                      checked={shareTaskWithClient}
                      onChange={handleChangeShareWithClient}
                      inputProps={{ 'aria-label': 'controlled' }}
                    /> */}
                        <Typography sx={{ fontSize: '12px', fontWeight: '400', mt: '24px' }}>
                          Share with {label_client.toLowerCase()}
                        </Typography>
                        <IOSSwitch
                          disabled={!userPermissions?.includes(userPermissionSchema.EDIT)}
                          sx={{ mt: '4px' }}
                          name="shareWithClient"
                          checked={formik.values.shareWithClient}
                          onChange={() => formik.setFieldValue('shareWithClient', !formik.values.shareWithClient)}
                        />
                        {/* {formik.values.shareWithClient == true ? (
                        <IOSSwitch sx={{ mt: '4px' }} defaultChecked onChange={() => console.log('Switch status')} />
                      ) : (
                        <IOSSwitch sx={{ mt: '4px' }} onChange={() => console.log('Switch status')} />
                      )} */}
                      </Stack>
                    )}
                    <Stack>
                      <Typography sx={{ fontSize: '12px', fontWeight: '400', mt: '24px' }}>Its a milestone</Typography>
                      <IOSSwitch
                        disabled={!userPermissions?.includes(userPermissionSchema.EDIT)}
                        name="isMilestone"
                        sx={{ mt: '4px' }}
                        checked={formik.values.isMilestone}
                        onChange={() => formik.setFieldValue('isMilestone', !formik.values.isMilestone)}
                      />
                    </Stack>
                  </Stack>
                  <Grid container justifyContent="flex-end">
                    {console.log(formik)}
                    <Button
                      variant="contained"
                      disabled={
                        !formik.isValid ||
                        formik.values.taskName?.length == 0 ||
                        !userPermissions?.includes(userPermissionSchema.EDIT)
                      }
                      onClick={() => {
                        formik.handleSubmit();
                        props.onClose();
                        // setStateDrawerOpen(false);
                        // handleSubmitTask();
                      }}
                      sx={{ mt: '10px' }}
                    >
                      Save
                    </Button>
                  </Grid>
                </>
              )}
            </Formik>
          ) : (
            <Dependency
              detail={props.taskDetail.template_project_uid ? 'project' : 'usecase'}
              taskComesUnderUid={
                props.taskDetail.template_project_uid
                  ? props.taskDetail.template_project_uid
                  : props.taskDetail.template_case_uid
              }
              taskType={props.taskDetail.template_project_uid ? 'project' : 'usecase'}
              taskUid={props.taskDetail.template_task_uid}
            ></Dependency>
          )}
        </Box>
      </Drawer>
    </>
  );
};

export default UpdateTemplateTaskDrawer;
