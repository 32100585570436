import { Box, Text } from 'components/ResourceManagement/design-system/primitives';
import { label_project, label_task } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import styled from 'styled-components';

export type TComponentListHeader = {
  headerHeight: number;
  rowWidth: number;
  mode: string;
};

const StyledDiv = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ height }) => `${height}px`};
  border: ${({ theme }) => theme.borders[4]};
  outline: ${({ theme }) => theme.borders[4]};
  background-color: ${({ theme }) => theme.colors.grey_5};
`;

const modeMap: Record<string, string> = {
  'main-view': 'Team Members',
  'resource-view': `${label_project}s`,
  'project-view': `${label_task}s`,
};

export const ComponentListHeader = ({ headerHeight, mode }: TComponentListHeader) => {
  const resourceName = modeMap[mode];

  return (
    <StyledDiv height={headerHeight}>
      <Text variant="heading3">{resourceName}</Text>
    </StyledDiv>
  );
};
