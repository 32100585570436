import React from 'react';

export const usePopover = () => {
  const [anchorEl, setAnchorEl] = React.useState<SVGRectElement | SVGElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClick = (event: React.MouseEvent<SVGRectElement | SVGElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePopoverOpen = (event: React.MouseEvent<SVGRectElement | SVGElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return {
    anchorEl,
    handlePopoverClick: handleClick,
    handlePopoverClose: handleClose,
    handlePopoverOpen,
    popoverId: id,
    popoverOpen: open,
  };
};
