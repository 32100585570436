import { Stack, Paper, Typography } from '@mui/material';
import { ReactComponent as NoRecords } from '../../../../../../assets/svgs/no_records.svg';
import CognisaasProductRequiremetsCardForUsecase from 'components/shared/CognisaasCard/CognisaasProductRequirementsCardForUsecase/CognisaasProductRequirementsForUsecase';
const OnGoingProductRequirements = (props: any) => {

  console.log('OnGoingProductRequirements', props);
  return (
    <Paper sx={{ margin: '0px 0', width: '100%', overflowY: 'scroll', overflowX: 'clip', maxHeight: '344px', boxShadow: 'none' }}>
      <Stack spacing={2} py={1}>
        <Stack mx={1} my={1}>
          <Typography variant="h2">Ongoing product requirements</Typography>
        </Stack>
        {props.requirements.length == 0 ? (
          <Stack justifyContent="center" alignItems="center">
            <NoRecords style={{ marginLeft: '6px', marginTop: '10px', width: '150px', height: '150px' }}></NoRecords>
          </Stack>
        ) : (
          <>
            {props.requirements.map((feature: any, index: number) => (
              <Stack key={index} style={{ marginLeft: '8px' }} direction="column"
                justifyContent="space-evenly"
                alignItems="center"
                spacing={4}>
                <CognisaasProductRequiremetsCardForUsecase feature={feature} />
              </Stack>
            ))}
          </>
        )}
      </Stack>
    </Paper>
  );
};

export default OnGoingProductRequirements;
