import { Button, Typography, IconButton, TextField, Snackbar, Alert, AlertColor } from '@mui/material';
import Styles from './RequirementTabs.module.css';
import CognisaasRequirementStatusDropdown from '../../shared/CognisaasDropdown/CognisaasRequirementStatusDropdown/CognisaasRequirementStatusDropdown';
import { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import CognisaasCalenderNew from '../../shared/CognisaasCalenderNew/CognisaasCalenderNew';
import { format } from 'date-fns';
import { updateRequirementOnDbAndStore } from '../../../store/modules/ProductRequirements/ProductDetails/slice';
import { useDispatch } from 'react-redux';
import {
  selectCustomerDetailsState,
  selectPrAndReqDataState,
} from '../../../store/modules/ProductRequirements/ProductDetails/selector';
import { useAppSelector } from '../../../store/hooks';
import { isNull } from 'utils/dataHelpers';

interface Props {
  data: Record<string, any>;
  selectedProductIndex: number;
  selectedRequirementIndex: number;
}

const RequirementsDetailsTab = (props: Props) => {
  const dispatch = useDispatch();
  const [alertDetails, setAlertDetails] = useState({ isVisible: false, severity: 'error', message: '' });
  const [isEditingModeEnabled, setIsEditingModeEnabled] = useState(false);
  const productAndReqState = useAppSelector(selectPrAndReqDataState);

  const handlingThisRequirementStatus = (e: any) => {
    setThisRequirementStatus(e.target.value);
  };
  // new edited values
  const [requirementName, setRequirementName] = useState<string>(props.data.feature_description);
  const [requirementContent, setRequirementContent] = useState<string>(props.data.feature_content);
  const customerDetailState = useAppSelector(selectCustomerDetailsState);

  const [thisRequirementStatus, setThisRequirementStatus] = useState<'red' | 'amber' | 'green'>(
    props.data.feature_readinessstate_status
  );
  const [etaForRelease, setEtaForRelease] = useState<string | Date>(() => {
    if (props.data.feature_estimateddeliverydate === '0000-00-00 00:00:00') {
      return '';
    }
    return new Date(props.data.feature_estimateddeliverydate);
  });

  const doWeNeedToUpdate = (): boolean => {
    // requirement name validations
    if (requirementName.trim() === '') {
      setAlertDetails({ isVisible: true, severity: 'warning', message: 'Requirement Name can\'t be empty' });
      return false;
    }

    // requrement and description and status if all are not changend then no need to update
    if (
      requirementName.trim() === props.data.feature_description.trim() &&
      requirementContent.trim() === props.data.feature_content.trim() &&
      thisRequirementStatus === props.data.feature_readinessstate_status.trim() &&
      etaForRelease === props.data.feature_estimateddeliverydate
    ) {
      return false;
    }

    let isNameAlreadyOccupied = false;

    // check if requirement name already exits in this product
    productAndReqState.data[props.selectedProductIndex].feature.forEach((requirement: any) => {
      if (requirementName == requirement.feature_description && props.data.feature_uid != requirement.feature_uid) {
        isNameAlreadyOccupied = true;
        setAlertDetails({ isVisible: true, severity: 'warning', message: 'Requirement Name already exits' });
        return;
      }
    });

    if (isNameAlreadyOccupied) {
      return false;
    }
    return true;
  };

  console.log('customer details', customerDetailState, props.data);

  const handleLinkToJiraButtonClick = () => {
    if (customerDetailState.data.customer_jira_api_url && customerDetailState.data.customer_jira_api_url.length > 0) {
      window.open(`${customerDetailState.data.customer_jira_api_url}browse/${props.data.jira_issue_key}`, '_blank');
    }
  };

  const handleUpdateRequirement = async () => {
    // update in store
    // update in db
    if (doWeNeedToUpdate()) {
      const serverPayload = {
        feature_content: requirementContent.trim(),
        feature_description: requirementName.trim(),
        feature_estimateddeliverydate:
          thisRequirementStatus != 'green'
            ? etaForRelease === ''
              ? ''
              : format(etaForRelease as Date, 'yyyy-MM-dd')
            : '',
        feature_readinessstate_status: thisRequirementStatus,
        feature_uid: props?.data.feature_uid,
        product_uid: props.data.product_uid,
      };

      await dispatch(updateRequirementOnDbAndStore(serverPayload));
      setAlertDetails({ isVisible: true, severity: 'success', message: 'Requirement Updated Successfully' });
    } else {
      // no need to update requirement since nothing is changed
    }
    setIsEditingModeEnabled(false);
  };

  const isRequirementStatusReadOnly = () => {
    if (props.data.feature_readinessstate_status === 'green') {
      return true;
    } else if (
      !isNull(customerDetailState.data.customer_jira_token) &&
      customerDetailState.data.customer_jira_token.length > 0
    ) {
      return true;
    }
    return !isEditingModeEnabled;
  };

  return (
    <div className={Styles.RequirementDetailsTab__container}>
      {/* editing mode */}
      {customerDetailState.status === 'success' &&
      customerDetailState.data.customer_jira_fetch_mode &&
      customerDetailState.data.customer_jira_fetch_mode === 'pull' ? (
        <></>
      ) : (
        <div className={Styles.RequirementDetailsTab__editOptions_container}>
          {isEditingModeEnabled ? (
            <>
              <IconButton onClick={() => setIsEditingModeEnabled(false)}>
                <EditOffIcon />
              </IconButton>
              <Button sx={{ marginLeft: '20px' }} variant="contained" onClick={handleUpdateRequirement}>
                Save
              </Button>
            </>
          ) : (
            <IconButton onClick={() => setIsEditingModeEnabled(true)}>
              <EditIcon />
            </IconButton>
          )}
        </div>
      )}

      {isEditingModeEnabled ? (
        <TextField
          autoFocus
          placeholder="Requirement Name"
          onChange={(e) => {
            console.log(e.target.value);
            setRequirementName(e.target.value);
          }}
          sx={{ marginBottom: '12px' }}
          variant="standard"
          value={requirementName}
        />
      ) : (
        <Typography variant="h2" sx={{ marginBottom: '12px' }}>
          {requirementName}
        </Typography>
      )}

      {isEditingModeEnabled ? (
        <TextField
          placeholder="Requirement Description"
          multiline
          rows={2}
          sx={{ marginBottom: '12px' }}
          fullWidth
          variant="standard"
          value={requirementContent}
          onChange={(e) => setRequirementContent(e.target.value)}
        />
      ) : (
        <Typography variant="subtitle1" sx={{ marginBottom: '24px' }}>
          {requirementContent}
        </Typography>
      )}

      {/* requirement status dropdown here */}
      <CognisaasRequirementStatusDropdown
        readOnly={isRequirementStatusReadOnly()}
        value={thisRequirementStatus}
        changeHandler={handlingThisRequirementStatus}
      />

      {/* meta data container */}
      <div className={Styles.RequirementDetailsTab__metadataContainer}>
        <div className={Styles.RequirementDetailsTab__metadataSection}>
          {thisRequirementStatus != 'green' ? (
            <Typography variant="subtitle2" className="label-text">
              ETA for release
            </Typography>
          ) : null}
          {isEditingModeEnabled && thisRequirementStatus != 'green' && thisRequirementStatus != 'red' ? (
            <CognisaasCalenderNew
              disabled={
                !isNull(customerDetailState.data.customer_jira_token) &&
                customerDetailState.data.customer_jira_token.length > 0
              }
              value={(etaForRelease && new Date(etaForRelease)) || undefined}
              handleChange={(date: Date) => {
                setEtaForRelease(date);
              }}
            />
          ) : thisRequirementStatus != 'green' ? (
            <Typography variant="subtitle1">
              {etaForRelease === '' ? '-' : format(new Date(etaForRelease), 'dd MMM yyyy')}
            </Typography>
          ) : null}
        </div>
        {!isNull(customerDetailState.data.customer_jira_token) &&
        customerDetailState.data.customer_jira_token.length > 0 ? (
          <>
            <div className={Styles.RequirementDetailsTab__metadataSection}>
              <Typography variant="subtitle2" className="label-text">
                jira issue type
              </Typography>
              <Typography variant="subtitle1">
                {props.data.feature_issue_type_name === '' ? '-' : props.data.feature_issue_type_name}
              </Typography>
            </div>

            <div className={Styles.RequirementDetailsTab__metadataSection}>
              <Typography
                variant="subtitle1"
                className="link-text cognisaas-pointer"
                onClick={handleLinkToJiraButtonClick}
              >
                Link To Jira
              </Typography>
            </div>
          </>
        ) : null}
      </div>

      {/* some data */}
      {/* <Typography variant="subtitle2" className="label-text">
        Configuration details
      </Typography>
      <Typography variant="subtitle1" sx={{ marginBottom: '24px' }}>
        Jira integration sync all requirements between the teams
      </Typography>

      <Divider sx={{ marginBottom: '24px' }} /> */}

      {/* clients view header */}
      {/* <div className={Styles.RequirementDetailsTab__clientSection_header}>
        <Typography variant="h2">Clients</Typography>
        <Button>Add Clients</Button>
      </div> */}

      {/* one client view card */}
      {/* <div className={Styles.RequirementDetailsTab__clientViewCard_container}>
        <div className={Styles.RequirementDetailsTab__clientViewCard_header}>
          <div className={Styles.RequirementDetailsTab__clientViewCard_headerLeftSection}>
            <Avatar
              alt="vivek sir"
              src="https://res.cloudinary.com/practicaldev/image/fetch/s--3I2ESo2E--/c_fill,f_auto,fl_progressive,h_320,q_auto,w_320/https://dev-to-uploads.s3.amazonaws.com/uploads/user/profile_image/443207/7feac988-6ace-4809-a9ef-77fe0252548b.jpg"
              sx={{ width: '34.5px', height: '34.5px' }}
            >
              A
            </Avatar>

            <Typography sx={{ paddingLeft: '8.3px' }} variant="h2">
              Amway
            </Typography>
          </div>

          <IconButton>
            <MoreVertIcon />
          </IconButton>
        </div>

        <div>
          <Typography variant="subtitle2" className="label-text">
            Use Case
          </Typography>
          <div className={Styles.RequirementDetailsTab__clientViewCard_usecaseContainer}>
            <Typography variant="subtitle1" className="link-text">
              Use Case 1
            </Typography>
            <IconButton>
              <DeleteIcon />
            </IconButton>
          </div>
          <div className={Styles.RequirementDetailsTab__clientViewCard_usecaseContainer}>
            <Typography variant="subtitle1" className="link-text">
              Use Case 2
            </Typography>
            <IconButton>
              <DeleteIcon />
            </IconButton>
          </div>
        </div>
      </div> */}

      {/* this is alerts */}
      <Snackbar
        open={alertDetails.isVisible}
        autoHideDuration={6000}
        onClose={() => setAlertDetails({ isVisible: false, severity: 'error', message: '' })}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => setAlertDetails({ isVisible: false, severity: 'error', message: '' })}
          severity={alertDetails.severity as AlertColor}
          sx={{ width: '100%' }}
        >
          {alertDetails.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default RequirementsDetailsTab;
