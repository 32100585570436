import { Avatar, Grid, IconButton, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { stringToHslColor } from 'components/ResourceManagement/utils/transformers';
function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToHslColor(name, 30, 80),
    },
    children: (
      <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#000000' }}>
        {name.split(' ')[0][0]}
        {name.indexOf(' ') >= 0 ? name.split(' ')[1][0] : null}
      </Typography>
    ),
  };
}
const ClientCard = (props: any) => {
  return (
    <Box
      sx={{
        maxWidth: '28vw',
        boxShadow: '0px 4px 26px rgba(0, 0, 0, 0.05)',
        border: '1px solid #E5E5E5',
        borderRadius: '4px',
        marginBottom: '10px',
      }}
    >
      <Grid container sx={{ padding: '7px' }}>
        <Grid item xs={12}>
          <Grid container justifyContent={'space-between'}>
            <Grid item >
              {props.customHeader ? props.customHeader : <Typography variant="subtitle1">{props.header}</Typography>}
            </Grid>
            {props.menuRequired == true ? (
              <Grid item xs={1}>
                <IconButton>
                  <MoreVertIcon />
                </IconButton>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: '5px' }}>
          <Stack direction={'row'} alignItems={'center'} spacing={2}>
            <Avatar style={{ height: '34px', width: '34px', marginTop: '3px' }} {...stringAvatar(props.ownerName)} />
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="subtitle2" className="label-text">
                  Owner
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1">{props.ownerName}</Typography>
              </Grid>
            </Grid>
          </Stack>
        </Grid>
        {/* <Grid item xs={12}>
          <Typography variant="subtitle1" className="link-text cognisaas-pointer">
            Contact Owner
          </Typography>
        </Grid> */}
      </Grid>
    </Box>
  );
};

export default ClientCard;
