import { Divider, Stack, Typography } from '@mui/material';
import { label_client, label_project, label_use_case } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import CognisaasAutocompleteSelect from 'components/shared/CognisaasSelect/CognisaasAutocompleteSelect';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectClientList } from 'store/modules/ManageClient/ClientList/selector';
import { fetchClientList } from 'store/modules/ManageClient/ClientList/slice';
import { selectProjectList } from 'store/modules/Project/selector';
import { getAllProjectsOfCustomer, getProjectInformationWithCaseTaskByUid } from 'store/modules/Project/slice';
import { isNull } from 'utils/dataHelpers';

const CONSTANTS = {
  defaultUsecaseNotSelectedOptions: { id: '-15243', name: 'None' },
};

interface Props {
  onSubmit: (result: {
    clientDetails: Record<string, any>;
    projectDetails: Record<string, any>;
    usecaseDetails: Record<string, any>;
    phaseDetails: Record<string, any>;
  }) => void;
  updateIsValidated: React.Dispatch<React.SetStateAction<boolean>>;
}

const GetOtherInfoBeforeStandaloneTask = (props: Props) => {
  // client
  const allClients = useAppSelector(selectClientList);
  const [selectedClient, setSelectedClient] = useState<{ id: string; name: string }>({ id: '', name: '' });
  const [clientOptions, setClientOptions] = useState<{ id: string; name: string }[]>([]);

  //   projects
  const projectsList = useAppSelector(selectProjectList);
  const [projectOptions, setProjectOptions] = useState<{ id: string; name: string }[]>([]);
  const [selectedProject, setSelectedProject] = useState<{ id: string; name: string }>({ id: '', name: '' });

  //   usecase
  const [usecaseOptions, setUsecaseOptions] = useState<{ id: string; name: string }[]>([]);
  const [selectedUsecase, setSelectedUsecase] = useState<{ id: string; name: string }>(
    CONSTANTS.defaultUsecaseNotSelectedOptions
  );

  //   phases
  const [phaseOptions, setPhaseOptions] = useState<{ id: string; name: string }[]>([]);
  const [selectedPhase, setSelectedPhase] = useState<{ id: string; name: string }>({ id: '', name: '' });

  // others
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const [selectedProjectInfo, setSelectedProjectInfo] = useState<Record<string, any>>({});

  const getDataForDropdowns = async () => {
    if (allClients.length === 0) {
      await dispatch(fetchClientList());
    }
    if (projectsList.length === 0) {
      await dispatch(getAllProjectsOfCustomer());
    }
  };

  console.log('came here');

  useEffect(() => {
    makeClientOptions();
  }, [allClients]);

  useEffect(() => {
    if (selectedClient.id.length > 0) {
      makeProjectOptions();
      props.updateIsValidated(false);
    }
  }, [selectedClient]);

  useEffect(() => {
    if (selectedProject.id.length > 0) {
      makeUsecaseOptions();
      props.updateIsValidated(false);
    }
  }, [selectedProject]);

  useEffect(() => {
    if (selectedUsecase.id !== CONSTANTS.defaultUsecaseNotSelectedOptions.id) {
      makePhaseOptions('usecase');
    } else if (selectedProject.id.length > 0) {
      makePhaseOptions('project');
    }
    props.updateIsValidated(false);
  }, [selectedUsecase]);

  useEffect(() => {
    if (selectedPhase.id.length > 0) {
      updateDetailsEssentialForTask();
      props.updateIsValidated(true);
    }
  }, [selectedPhase]);

  const makeClientOptions = () => {
    const list = allClients.map((client: any) => {
      return { id: client.client_uid, name: client.client_name };
    });
    setClientOptions([...list] as { id: string; name: string }[]);
  };

  const makeProjectOptions = () => {
    const filteredProjects = projectsList.filter((project: any) => project.client_uid === selectedClient.id);
    const list = filteredProjects.map((project: any) => {
      if (project.__user_permissions.includes('edit')) {
        return { id: project.project_uid, name: project.project_name };
      }
    });
    setProjectOptions([...list] as { id: string; name: string }[]);
  };

  const getAndSetProjectInfo = async () => {
    const detail = {
      project_uid: selectedProject.id,
    };
    const projectInfo = await appDispatch(getProjectInformationWithCaseTaskByUid(detail));
    await setSelectedProjectInfo(projectInfo.payload);
    return projectInfo.payload;
  };

  const makeUsecaseOptions = async () => {
    const projectInfo = await getAndSetProjectInfo();
    const usecases = projectInfo.usecase;

    const list = usecases.map((usecase: any) => {
      if (usecase.__user_permissions.includes('edit')) {
        return { id: usecase.case_uid, name: usecase.case_name };
      }
    });
    setUsecaseOptions([CONSTANTS.defaultUsecaseNotSelectedOptions, ...list] as { id: string; name: string }[]);
    makePhaseOptions('project', projectInfo);
  };

  const makePhaseOptions = (optionsFor: 'project' | 'usecase', passedProjInfo?: any[]) => {
    const projectInfo = passedProjInfo ? passedProjInfo : selectedProjectInfo;
    let phases: any[] = [];
    if (optionsFor === 'project') {
      phases = projectInfo.section;
    } else if (optionsFor === 'usecase') {
      const allUsecases = projectInfo.usecase;
      const selectedUsecaseFullInfo = allUsecases.filter((usecase: any) => usecase.case_uid === selectedUsecase.id)[0];
      phases = selectedUsecaseFullInfo.section;
    }
    const list = phases.map((phase: any) => ({ id: phase.section_uid, name: phase.section_detail }));
    setPhaseOptions([...list] as { id: string; name: string }[]);
  };

  // const clearAllFields = () => {
  //   setSelectedClient({ id: '', name: '' });
  //   setSelectedProject({ id: '', name: '' });
  //   setSelectedUsecase(CONSTANTS.defaultUsecaseNotSelectedOptions);
  //   setSelectedPhase({ id: '', name: '' });
  // };

  // const disableSaveButtonSinceEssentialInfoIsNotPreset = (): boolean =>
  //   selectedClient.id.length === 0 || selectedProject.id.length === 0 || selectedPhase.id.length === 0;

  const updateDetailsEssentialForTask = () => {
    const clientDetails = allClients.filter((client: any) => client.client_uid === selectedClient.id)[0];
    const projectDetails = selectedProjectInfo;
    let usecaseDetails = null;
    if (selectedUsecase.id !== CONSTANTS.defaultUsecaseNotSelectedOptions.id) {
      usecaseDetails = selectedProjectInfo.usecase.filter((usecase: any) => usecase.case_uid === selectedUsecase.id)[0];
    }
    let phaseDetails = null;
    if (isNull(usecaseDetails)) {
      phaseDetails = selectedProjectInfo.section.filter((phase: any) => phase.section_uid === selectedPhase.id)[0];
    } else {
      phaseDetails = usecaseDetails.section.filter((phase: any) => phase.section_uid === selectedPhase.id)[0];
    }
    const result = {
      clientDetails,
      projectDetails,
      usecaseDetails,
      phaseDetails,
    };
    props.onSubmit(result);
  };

  useEffect(() => {
    getDataForDropdowns();
  }, []);

  return (
    <Stack>
      <Stack>
        <Stack spacing={2} direction={'row'} sx={{ marginTop: '16px' }}>
          {/* client info */}
          <Stack>
            <Typography variant="subtitle2">
              {label_client}&nbsp;<sup style={{ color: 'red' }}>*</sup>
            </Typography>
            <CognisaasAutocompleteSelect
              width="220px"
              value={{ id: selectedClient.id, name: selectedClient.name }}
              allOptions={clientOptions}
              onChange={(data: any) => {
                setSelectedClient({ id: data.id, name: data.name });
                setSelectedProject({ id: '', name: '' });
                setSelectedUsecase(CONSTANTS.defaultUsecaseNotSelectedOptions);
                setSelectedPhase({ id: '', name: '' });
              }}
            />
          </Stack>
          {/* project info */}
          {selectedClient.id.length > 0 && (
            <Stack>
              <Typography variant="subtitle2">
                {label_project}&nbsp;<sup style={{ color: 'red' }}>*</sup>
              </Typography>
              <CognisaasAutocompleteSelect
                width="220px"
                value={{ id: selectedProject.id, name: selectedProject.name }}
                allOptions={projectOptions}
                onChange={(data: any) => {
                  setSelectedProject({ id: data.id, name: data.name });
                  setSelectedUsecase(CONSTANTS.defaultUsecaseNotSelectedOptions);
                  setSelectedPhase({ id: '', name: '' });
                }}
              />
            </Stack>
          )}

          {/* usecase info */}
          {selectedProject.id.length > 0 && (
            <Stack>
              <Typography variant="subtitle2">
                {label_use_case}&nbsp;<sup style={{ color: 'red', visibility: 'hidden' }}>*</sup>
              </Typography>
              <CognisaasAutocompleteSelect
                width="220px"
                value={{ id: selectedUsecase.id, name: selectedUsecase.name }}
                allOptions={usecaseOptions}
                onChange={(data: any) => {
                  setSelectedUsecase({ id: data.id, name: data.name });
                  setSelectedPhase({ id: '', name: '' });
                }}
              />
            </Stack>
          )}
        </Stack>

        <Stack spacing={2} direction={'row'} sx={{ marginTop: '16px' }}>
          {/* phase info */}
          {selectedProject.id.length > 0 && (
            <Stack>
              <Typography variant="subtitle2">
                Phase Name&nbsp;<sup style={{ color: 'red' }}>*</sup>
              </Typography>
              <CognisaasAutocompleteSelect
                width="220px"
                value={{ id: selectedPhase.id, name: selectedPhase.name }}
                allOptions={phaseOptions}
                onChange={(data: any) => {
                  setSelectedPhase({ id: data.id, name: data.name });
                }}
              />
            </Stack>
          )}
        </Stack>

        <Divider sx={{ margin: '16px 0px' }} />
      </Stack>
      {/* <Stack direction={'row'} justifyContent={'flex-end'}>
        <Stack direction={'row'} spacing={1}>
          <CognisaasButton label="Clear" isOutlined={true} clickHandler={clearAllFields} />
          <CognisaasButton label="Next" isOutlined={false} clickHandler={onClickNext} disabled={disableNextButton()} />
        </Stack>
      </Stack> */}
    </Stack>
  );
};

export default GetOtherInfoBeforeStandaloneTask;
