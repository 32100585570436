import { useController as useFormController, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  addSoftAllocation,
  selectMainViewData,
  selectSoftAllocationDrawer,
  setSoftAllocationDrawerState,
  updateSoftAllocation,
  deleteSoftAllocation,
} from 'store/modules/ResourceManagement';
import { getDateInYYYYMMDD } from 'components/ResourceManagement/utils/date';
import { label_project } from 'components/Settings/Preferences/CustomLabelCookieHelper';

const validationMessages = {
  required: 'Required',
  endDateBeforeStartDate: 'End date can\'t be before Start date',
};

const schema = yup
  .object()
  .shape({
    softAllocationType: yup.string().required(validationMessages.required),
    softAllocationStartDate: yup.date().required(validationMessages.required).nullable(),
    softAllocationEndDate: yup
      .date()
      .required(validationMessages.required)
      .nullable()
      .test(
        'is-softAllocationEndDate',
        () => 'Must be after the Start dates',
        function validateDates(value) {
          const { softAllocationStartDate, softAllocationEndDate } = this.parent;
          return value !== undefined && softAllocationStartDate < softAllocationEndDate;
        }
      ),
  })
  .when((values, schema) => {
    // console.log({ values });
    if (values.softAllocationType === '1') {
      return schema.shape({
        softAllocationProjectName: yup.string().required(validationMessages.required),
      });
    }
  });

export const softAllocationTypes = [
  { id: '1', name: label_project, value: 100 },
  { id: '2', name: 'Leave', value: 200 },
];

const initialSoftAllocationDrawerState = {
  isOpen: false,
  formMode: '',
  id: '',
  resourceId: '',
  startDate: null,
  endDate: null,
  allocationType: '',
  projectId: '',
  isError: false,
  errMsg: '',
};

export const useSoftAllocationForm = () => {
  const dispatch = useAppDispatch();
  const selectedMainViewData = useAppSelector(selectMainViewData);
  const selectedSoftAllocationDrawer = useAppSelector(selectSoftAllocationDrawer);
  // const [softAllocationFormData, setSoftAllocationFormData] = useState<any>({});
  const { isOpen: isSoftAllocationDrawerOpen, isError, errMsg, id: softAllocationId } = selectedSoftAllocationDrawer;
  const allProjectList = selectedMainViewData.events.filter((item: any) => item.entity === 'project');
  const selectedProjectId = allProjectList.find((item: any) => item.id === selectedSoftAllocationDrawer.projectId)?.id;

  const useFormObj = {
    defaultValues: {
      softAllocationType: '',
      softAllocationProjectName: '',
      softAllocationStartDate: null,
      softAllocationEndDate: null,
    },
    resolver: yupResolver(schema),
  };
  const hookFormProps = useForm(useFormObj);

  const handleClose = () => {
    dispatch(setSoftAllocationDrawerState(initialSoftAllocationDrawerState));
  };

  const softAllocationTypeValue = useWatch({
    control: hookFormProps.control,
    name: 'softAllocationType',
  });

  const isProjectDropdownShown = softAllocationTypeValue === '1';

  const constructAPIBody = (formFields: any) => {
    return {
      soft_allocation_uid: selectedSoftAllocationDrawer?.id ?? null,
      user_uid: selectedSoftAllocationDrawer?.resourceId ?? null,
      soft_allocation_type: formFields.softAllocationType === '2' ? 'leave' : 'project',
      project_uid: formFields.softAllocationProjectName,
      start_date: getDateInYYYYMMDD(formFields.softAllocationStartDate),
      end_date: getDateInYYYYMMDD(formFields.softAllocationEndDate),
    };
  };

  const onFormSubmit = async (submittedFormData: any) => {
    const softAllocationAPIBody = constructAPIBody(submittedFormData);
    // console.log({ softAllocationAPIBody, submittedFormData });
    if (selectedSoftAllocationDrawer.formMode === 'add') {
      dispatch(addSoftAllocation(softAllocationAPIBody))
        .unwrap()
        .then(() => {
          handleClose();
          // console.log({ 'add': originalPromiseResult });
        })
        .catch((err) => {
          console.log({ err });
          dispatch(
            setSoftAllocationDrawerState({ ...selectedSoftAllocationDrawer, isError: true, errMsg: err.message })
          );
        });
    } else {
      dispatch(updateSoftAllocation(softAllocationAPIBody))
        .unwrap()
        .then(() => {
          handleClose();
          // console.log({ 'update': originalPromiseResult });
        })
        .catch((err) => {
          console.log({ err });
          dispatch(
            setSoftAllocationDrawerState({ ...selectedSoftAllocationDrawer, isError: true, errMsg: err.message })
          );
        });
    }
  };

  const handleDeleteClick = () => {
    if (softAllocationId) {
      dispatch(deleteSoftAllocation({ soft_allocation_uid: softAllocationId }))
        .unwrap()
        .then(() => {
          dispatch(setSoftAllocationDrawerState({ initialSoftAllocationDrawerState }));
          // console.log({ 'delete': originalPromiseResult });
        })
        .catch((err) => {
          // console.log({ 'delete': err });
          dispatch(
            setSoftAllocationDrawerState({ ...selectedSoftAllocationDrawer, isError: true, errMsg: err.message })
          );
        });
    }
  };

  // console.log({ isProjectDropdownShown, selectedSoftAllocationDrawer, softAllocationTypeValue });

  return {
    hookFormProps: { ...hookFormProps, onFormSubmit, useFormController },
    softAllocationTypes,
    resourceId: selectedSoftAllocationDrawer.resourceId,
    startDate: selectedSoftAllocationDrawer.startDate,
    endDate: selectedSoftAllocationDrawer.endDate,
    selectedAllocationType: selectedSoftAllocationDrawer.allocationType,
    formMode: selectedSoftAllocationDrawer.formMode,
    selectedProjectId,
    isError,
    errMsg,
    isSoftAllocationDrawerOpen,
    isProjectDropdownShown,
    allProjectList,
    handleClose,
    handleDeleteClick,
  };
};
