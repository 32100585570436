import ReactDOM from 'react-dom';
import App from './App';
import { ThemeProvider } from '@mui/material';
import theme from './plugins/materialUI';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store/index';
const env: string = process.env.REACT_APP_ENV ?? 'local';
if (env === 'new-prod') {
  console.log = () => { }; // eslint-disable-line
  console.warn = () => { }; // eslint-disable-line
  console.error = () => { }; // eslint-disable-line
}

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThemeProvider>
  </Provider>,
  document.querySelector('#root')
);
