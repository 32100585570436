import { Box, Grid, Stack, Typography, Avatar, IconButton, Chip } from '@mui/material';
import ReportsHeader from 'components/Reports/ReportsHeader/ReportsHeader';
import React, { useEffect, useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import { useAppSelector } from 'store/hooks';
import { selectAllFilters } from 'store/modules/AllFilters/selector';
import { DataGridPremium, GridRenderCellParams, GridToolbar, gridClasses } from '@mui/x-data-grid-premium';
import { customFieldDataTypes, dataTypes } from 'components/Reports/CustomReports/types';
// import ViewDate from 'components/shared/ViewDate/ViewDate';
import { CustomizedProgressBars } from 'components/shared/CognisaasProgressBar/ProgressBar';
import CognisaasButton from 'components/shared/CognisaasButton/CognisaasButton';
// import UpdateReport from './update';
import customReportFilter from 'components/Reports/CustomReports/Filter/filter';
// import FilterTypeSelector from 'components/shared/CognisaasFilters/FilterTypeSelector/FilterTypeSelector';
import SelectedGroupFilterList from 'components/shared/CognisaasFilters/SelectedGroupFilterList/SelectedGroupFilterList';
import { isNull, isUndefined } from 'utils/dataHelpers';
import { addFilterGroup, deleteFilterGroup } from 'store/modules/AllFilters/slice';
import { FilterableData } from 'utils/filteringFunctions';
import { getAllUsers } from 'store/modules/Common/slice';
import CognisaasLoader from 'components/shared/CognisaasLoader/CognisaasLoader';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { getCustomerUid, getLoggedInUserUid, getUserNameByUserUid } from 'utils/getUid';
import { useNavigate } from 'react-router-dom';
import { alpha, styled } from '@mui/material/styles';
import {
  getAggregatedCustomReportConfigByUid,
  getGeneratedAggregatedCustomReportUsingUid,
} from 'store/modules/Reports/AggregatedCustomReports/slice';
import UpdateAggregatedCustomReports from '../Update/UpdateAggregatedReportConfig';
import NoRecordsFound from 'components/shared/NoRecords/NoRecordsFound';
import CognisaaasTooltip from 'components/shared/CognisaasTooltip/CognisaasTooltip';
import { ReactComponent as Info } from 'assets/svgs/info.svg';
import { REPORT_TYPE_AGGREGATED_REPORT, REPORT_TYPE_CUSTOM_REPORT } from 'components/Reports/Shared/constants';
import { graphSchema } from 'components/shared/Charts/CustomiseDrawer/types';
import { selectAllChartsConfig } from 'store/modules/Charts/selector';
import CustomiseDrawer from 'components/shared/Charts/CustomiseDrawer/CustomiseDrawer';
import ChartView from 'components/shared/Charts/ChartView/ChartView';
import useChartCustomizeDrawer from 'components/shared/Charts/useChartCustomizeDrawer';
import { getGraphConfigurations } from 'store/modules/Charts/slice';
import useCustomNames from 'components/shared/CustomNames/useCustomNames';
import ViewDate from 'components/shared/ViewDate/ViewDate';
import { label_task } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { USER_UID_FIELD_KEYS } from '../Config/constants';
import FilterTypeSelector from 'components/shared/CognisaasFilters/FilterTypeSelector/FilterTypeSelector';
// import { config } from 'process';
// import clientEntity from '../Entity/ClientEntity';
// import projectEntity from '../Entity/projectEntity';
// import taskEntity from '../Entity/taskEntity';
// import usecaseEntity from '../Entity/usecaseEntity';
// import { getDisplayByKey } from '../UtilityFunction';

function makeid(length: any) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGridPremium)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
        },
      },
    },
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: 600,
  width: '100%',
  background: 'white',
  borderRadius: '4px',
  boxShadow: '0px 4px 26px rgba(0, 0, 0, 0.05)',
  '& .MuiFormGroup-options': {
    alignItems: 'center',
    paddingBottom: theme.spacing(1),
    '& > div': {
      minWidth: 100,
      margin: theme.spacing(2),
      marginLeft: 0,
    },
  },
}));

const Report = () => {
  // for api calls
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [response, setResponse] = useState<any>(null);
  const [columns, setColumns] = useState<any[]>([]);
  // const [NeededHeaders, setNeededHeaders] = useState<any[]>([]);
  const [reportDetail, setReportDetail] = useState<any>(null);
  const [showEditButton, setShowEditButton] = useState<boolean>(false);
  const [updateDrawer, setUpdateDrawer] = useState<boolean>(false);
  const [filters, setFilters] = useState<any[]>([]);
  const [filteredReport, setFilteredReport] = useState<any[]>([]);
  // const [transformedReportDataForChrt, setTransformedReportDataForChrt] = useState<any[]>([]);
  const [properlyKeyMappedData, setProperlyKeyMappedData] = useState<any[]>([]);

  const allFilters = useAppSelector(selectAllFilters);
  const [pageSize, setPageSize] = useState<number>(10);
  const { transformDataForCustomReport } = useChartCustomizeDrawer();

  // uri parameters
  const url: any = window.location.pathname;
  const params = url.split('/');
  const reportId = params[params.length - 1];

  // charts
  const idNameForCustomReport = `customReport_${reportId}`;
  const [drawerState, setDrawerState] = useState<boolean>(false);
  const graphConfigs: graphSchema[] = useAppSelector(selectAllChartsConfig);
  const graphs: graphSchema[] = graphConfigs.filter((graph: graphSchema) => graph.idName === idNameForCustomReport);
  // const customerDetail = useAppSelector(selectCustomerDetails).data;

  const [graphData, setGraphData] = useState<any>({});
  const [formActionType, setFormActionType] = useState<'save' | 'update'>('save');
  const { displayDataWithCustomNames } = useCustomNames();

  // const { clientEntityWithCustomFields } = clientEntity();
  // const { projectEntityWithCustomFields } = projectEntity();
  // console.log(projectEntityWithCustomFields);
  // const { usecaseEntityWithCustomFields } = usecaseEntity();
  // const { taskEntityWithCustomFields } = taskEntity();

  const {
    clientFilters,
    projectFilters,
    usecaseFilters,
    taskFilters,
    additionalDataDependentFilters,
    implimentAggregationInReports,
  } = customReportFilter();

  const showEditButtonIfNecessary = () => {
    const userUid = getLoggedInUserUid();
    if (
      reportDetail.created_by == userUid ||
      (reportDetail.is_private == '0' && reportDetail.global_write_access == '1')
    )
      setShowEditButton(true);
    else setShowEditButton(false);
  };

  // const mapNeededHeaders = (response: any) => {
  //   const keys: any[] = [];
  //   for (let i = 0; i < response.config.client.length; i++) {
  //     keys.push(response.config.client[i].displayName);
  //   }

  //   for (let i = 0; i < response.config.project.length; i++) {
  //     keys.push(response.config.project[i].displayName);
  //   }

  //   for (let i = 0; i < response.config.usecase.length; i++) {
  //     keys.push(response.config.usecase[i].displayName);
  //   }

  //   for (let i = 0; i < response.config.task.length; i++) {
  //     keys.push(response.config.task[i].displayName);
  //   }
  //   setNeededHeaders([...keys]);
  // };

  const assembleData = (responseData: any, detail: any) => {
    const data: any[] = JSON.parse(JSON.stringify(responseData.data));
    const displayNameKeys: any[] = [];
    const allConfigs: any[] = [];
    for (let clientIndex = 0; clientIndex < detail[0].report_config.client.length; clientIndex++) {
      displayNameKeys.push(detail[0].report_config.client[clientIndex].displayName);
      allConfigs.push(detail[0].report_config.client[clientIndex]);
    }
    for (let projectIndex = 0; projectIndex < detail[0].report_config.project.length; projectIndex++) {
      displayNameKeys.push(detail[0].report_config.project[projectIndex].displayName);
      allConfigs.push(detail[0].report_config.project[projectIndex]);
    }
    for (let usecaseIndex = 0; usecaseIndex < detail[0].report_config.usecase.length; usecaseIndex++) {
      displayNameKeys.push(detail[0].report_config.usecase[usecaseIndex].displayName);
      allConfigs.push(detail[0].report_config.usecase[usecaseIndex]);
    }
    for (let taskIndex = 0; taskIndex < detail[0].report_config.task.length; taskIndex++) {
      displayNameKeys.push(detail[0].report_config.task[taskIndex].displayName);
      allConfigs.push(detail[0].report_config.task[taskIndex]);
    }
    const uids = ['client_uid', 'project_uid', 'case_uid', 'association_uid', 'customer_uid', 'task_uid'];

    const newData = data.map((item: any) => {
      const keysInCurrentRow = [...Object.keys(item)];
      const missingKeys = displayNameKeys.filter((item) => keysInCurrentRow.indexOf(item) == -1);
      for (let i = 0; i < missingKeys.length; i++) {
        if (!uids.includes(missingKeys[i])) {
          const isThisKeyCountValue: boolean =
            detail[0].report_type === REPORT_TYPE_CUSTOM_REPORT ? false : isCountValue(missingKeys[i], allConfigs);
          if (isThisKeyCountValue) {
            item[missingKeys[i]] = 0;
          } else {
            item[missingKeys[i]] = '-';
          }
        }
      }
      return item;
    });
    return { ...responseData, data: [...newData] };
  };

  const isCountValue = (key: string, configs: any[]) => {
    for (let i = 0; i < configs.length; i++) {
      if (key == configs[i].displayName) {
        if (
          configs[i]?.aggregationToPerform == 'count' ||
          configs[i]?.aggregationToPerform == 'sum' ||
          configs[i]?.aggregationToPerform == 'average' ||
          configs[i]?.aggregationToPerform == 'nothing' ||
          configs[i]?.aggregationToPerform == '' ||
          configs[i]?.allowedOperationsList.includes('count')
        ) {
          return true;
        }
        return false;
      }
    }
    return false;
  };

  // const initSelect = (data: any, prop: string) => {
  //   return data.map((item: any) => ({
  //     ...item,
  //     [prop]: '',
  //   }));
  // };

  const getReport = async () => {
    if (reportId) {
      const detail = await dispatch(getAggregatedCustomReportConfigByUid(reportId)).unwrap();
      setReportDetail(detail[0]);
      const res: any = await dispatch(getGeneratedAggregatedCustomReportUsingUid(reportId)).unwrap();
      const data = assembleData(res, detail);
      // mapNeededHeaders(res);
      // res.data = [...data];
      setResponse(data);
      await dispatch(getAllUsers());
    }
  };

  const getChartData = () => {
    dispatch(
      getGraphConfigurations({
        type: 'any',
        customer_uid: getCustomerUid(),
        user_uid: getLoggedInUserUid(),
      })
    );
  };

  useEffect(() => {
    getChartData();
    getReport();
  }, []);

  useEffect(() => {
    if (reportDetail) {
      if (
        clientFilters.length > 0 &&
        projectFilters.length > 0 &&
        usecaseFilters.length > 0 &&
        taskFilters.length > 0
      ) {
        const transformedData = properlyKeyMappedData[0];
        if (properlyKeyMappedData.length > 0) {
          const availableKeys = Object.keys(transformedData);
          const allFilterValues =
            reportDetail.report_type === REPORT_TYPE_AGGREGATED_REPORT
              ? implimentAggregationInReports(reportDetail.top_entity)
              : [
                  ...additionalDataDependentFilters(transformedData),
                  ...clientFilters,
                  ...projectFilters,
                  ...usecaseFilters,
                  ...taskFilters,
                ];
          const allNeededFilters: any[] = [];
          for (let i = 0; i < allFilterValues.length; i++) {
            if (
              !isUndefined(allFilterValues[i].dataKey.split('#$#')[1]) &&
              availableKeys.includes(allFilterValues[i].dataKey.split('#$#')[0])
            ) {
              allNeededFilters.push(allFilterValues[i]);
            } else if (availableKeys.includes(allFilterValues[i].dataKey)) {
              allNeededFilters.push(allFilterValues[i]);
            }
          }
          setFilters([...allNeededFilters]);
        }
      }
    }
  }, [clientFilters, projectFilters, usecaseFilters, taskFilters, properlyKeyMappedData, reportDetail]);

  useEffect(() => {
    if (reportDetail) {
      showEditButtonIfNecessary();
    }
    if (reportDetail && filters.length > 0) {
      if (isUndefined(allFilters[idNameForCustomReport]) || isNull(allFilters[idNameForCustomReport])) {
        dispatch(
          addFilterGroup({
            idName: idNameForCustomReport,
            appliedFilters: [...filters],
          })
        );
      }
    }
  }, [reportDetail, filters]);

  // const getHeaderName = (field: any) => {
  //   for (const clientKey of response.config.client) {
  //     if (clientKey.key == field) {
  //       return clientKey.displayName;
  //     }
  //   }

  //   for (const projectKey of response.config.project) {
  //     if (projectKey.key == field) {
  //       return projectKey.displayName;
  //     }
  //   }

  //   for (const usecaseKey of response.config.usecase) {
  //     if (usecaseKey.key == field) {
  //       return usecaseKey.displayName;
  //     }
  //   }

  //   for (const taskKey of response.config.task) {
  //     if (taskKey.key == field) {
  //       return taskKey.displayName;
  //     }
  //   }
  //   // return false;
  // };

  // const getDataType = (field: any) => {
  //   for (const clientKey of response.config.client) {
  //     if (clientKey.key == field) {
  //       return clientKey.type;
  //     }
  //   }

  //   for (const projectKey of response.config.project) {
  //     if (projectKey.key == field) {
  //       return projectKey.type;
  //     }
  //   }

  //   for (const usecaseKey of response.config.usecase) {
  //     if (usecaseKey.key == field) {
  //       return usecaseKey.type;
  //     }
  //   }

  //   for (const taskKey of response.config.task) {
  //     if (taskKey.key == field) {
  //       return taskKey.type;
  //     }
  //   }
  // };

  // const getKeyName = (name: string) => {
  //   const config = reportDetail?.report_config;
  //   let keyName = '';

  //   if (config) {
  //     for (let i = 0; i < config.client.length; i++) {
  //       if (name == config.client[i].displayName) {
  //         keyName = config.client[i].key;
  //       }
  //     }

  //     for (let i = 0; i < config.project.length; i++) {
  //       if (name == config.project[i].displayName) {
  //         keyName = config.project[i].key;
  //       }
  //     }

  //     for (let i = 0; i < config.usecase.length; i++) {
  //       if (name == config.usecase[i].displayName) {
  //         keyName = config.usecase[i].key;
  //       }
  //     }

  //     for (let i = 0; i < config.task.length; i++) {
  //       if (name == config.task[i].displayName) {
  //         keyName = config.task[i].key;
  //       }
  //     }
  //   }

  //   return keyName;
  // };

  const generatedTableColumnsByConfig = (reportInfo: any) => {
    const newColumns: any = [];
    const allColumnsConfigs = [
      ...reportInfo.report_config.client,
      ...reportInfo.report_config.project,
      ...reportInfo.report_config.usecase,
      ...reportInfo.report_config.task,
    ];
    allColumnsConfigs.forEach((columnConfig: any) => {
      const key = columnConfig.key;
      const headerName = columnConfig.displayName;
      const dataType = columnConfig.type;
      const reportType = reportInfo.report_type;
      if (
        key != 'client_uid' &&
        key != 'project_uid' &&
        key != 'case_uid' &&
        key != 'task_uid' &&
        key != 'association_uid' &&
        key != 'customer_uid' &&
        key != '__user_permissions'
      ) {
        const data = {
          field: headerName,
          headerName: headerName,
          width: 240,
          // valueFormatter: ({ value }: any) =>
          //   value == 'red'
          //     ? 'Overdue'
          //     : value == 'amber'
          //     ? 'At Risk'
          //     : value == 'green'
          //     ? 'On Track'
          //     : value == 'blue'
          //     ? 'Completed'
          //     : value == 'grey'
          //     ? 'Deferred'
          //     : value,
          renderCell: (params: GridRenderCellParams) => {
            if (reportType === REPORT_TYPE_CUSTOM_REPORT && key === 'is_team_task' && params.value == '1') {
              return <Typography variant="subtitle1">Team {label_task.toLowerCase()} </Typography>;
            } else if (reportType === REPORT_TYPE_CUSTOM_REPORT && key === 'is_team_task' && params.value == '0') {
              return <Typography variant="subtitle1">Client {label_task.toLowerCase()} </Typography>;
            } else if (
              reportType === REPORT_TYPE_CUSTOM_REPORT &&
              ['csat_indicator', 'milestone_task', 'client_initiate', 'client_check'].includes(key)
            ) {
              // handling speacial fields of yes and no
              if (params.value == '1') {
                return <Typography variant="subtitle1">Yes</Typography>;
              } else {
                return <Typography variant="subtitle1">No</Typography>;
              }
            } else if (
              (reportType === REPORT_TYPE_CUSTOM_REPORT && USER_UID_FIELD_KEYS.includes(key)) ||
              dataType == customFieldDataTypes.userList
            ) {
              return (
                <>
                  <Stack direction="row" style={{ padding: '5px 0' }}>
                    <Stack spacing={1} direction={'row'}>
                      <Avatar
                        style={{ height: '34px', width: '34px', marginTop: '3px' }}
                        {...stringAvatar(getUserNameByUserUid(params.value))}
                      />
                      <Typography style={{ marginTop: '8px' }} variant="subtitle1">
                        {getUserNameByUserUid(params.value)}
                      </Typography>
                    </Stack>
                  </Stack>
                </>
              );
            } else if (dataType == dataTypes.string || dataType == customFieldDataTypes.string) {
              return <Typography variant="subtitle1">{params.value} </Typography>;
            } else if (dataType == dataTypes.date || dataType == customFieldDataTypes.date) {
              return <ViewDate date={params.value} />;
            } else if (dataType == dataTypes.link || dataType == customFieldDataTypes.link) {
              return (
                <Typography
                  onClick={() => {
                    if (key == 'project_name') {
                      navigate(`/projects/project/${params.row.association_uid}/${params.row.project_uid}`);
                    } else if (key == 'case_name') {
                      navigate(
                        `/projects/project/${params.row.association_uid}/${params.row.project_uid}/${params.row.case_uid}`
                      );
                    } else if (key == 'client_name') {
                      navigate(`/client-list/client/${params.row.client_uid}`);
                    } else if (key == 'task_name') {
                      // console.log('task link');
                    }
                  }}
                  className="cognisaas-pointer"
                  variant="subtitle1"
                  sx={{ color: '#093BCD', fontWeight: 400, fontSize: '14px' }}
                >
                  {params.value}
                </Typography>
              );
            } else if (
              reportType === REPORT_TYPE_CUSTOM_REPORT &&
              ['project_progress', 'case_progress'].includes(key)
            ) {
              // console.log('progree', key);
              return <CustomizedProgressBars value={params.value} statusColor={'green'} hidePercentage={false} />;
            } else if (dataType == dataTypes.progressBar) {
              return <CustomizedProgressBars value={params.value} statusColor={'green'} hidePercentage={false} />;
            } else if (dataType == dataTypes.userName) {
              return (
                <Stack direction="row" style={{ padding: '5px 0' }}>
                  <Stack spacing={1} direction={'row'}>
                    <Avatar
                      style={{ height: '34px', width: '34px', marginTop: '3px' }}
                      {...stringAvatar(params.value)}
                    />
                    <Typography style={{ marginTop: '8px' }} variant="subtitle1">
                      {params.value}
                    </Typography>
                  </Stack>
                </Stack>
              );
            } else if ((dataType == dataTypes.rag || dataType == customFieldDataTypes.rag) && isNaN(params.value * 0)) {
              return (
                <Stack style={{ padding: '7px 0' }}>
                  <Stack spacing={1} direction="row">
                    <span
                      style={{ marginRight: '4px' }}
                      className={
                        params.value == 'red'
                          ? 'red_status_box_task'
                          : params.value == 'amber'
                          ? 'amber_status_box_task'
                          : params.value == 'green'
                          ? 'green_status_box_task'
                          : params.value == 'blue'
                          ? 'blue_status_box_task'
                          : ''
                      }
                    ></span>
                    {params.value == 'red'
                      ? 'Overdue'
                      : params.value == 'amber'
                      ? 'At Risk'
                      : params.value == 'green'
                      ? 'On Track'
                      : params.value == 'blue'
                      ? 'Completed'
                      : ''}
                  </Stack>
                </Stack>
              );
            } else if (['implementation_fee_pending', 'implementation_fee_billed'].includes(key)) {
              return (
                <Typography variant="subtitle1">
                  {isNull(params.value) || isUndefined(params.value) ? 0 : parseFloat(params.value).toFixed(2)}{' '}
                </Typography>
              );
            } else {
              return <Typography variant="subtitle1">{params.value} </Typography>;
            }
          },
        };
        newColumns.push(data);
      }
      setColumns([...newColumns]);
    });
  };

  useEffect(() => {
    if (reportDetail && response) {
      generatedTableColumnsByConfig(reportDetail);
      setProperlyKeyMappedData(transformDataForCustomReport(response.data, reportDetail));
      setFilteredReport(displayDataWithCustomNames(transformDataForCustomReport(response.data, reportDetail)));
    }
  }, [response, reportDetail]);

  useEffect(() => {
    if (reportDetail) {
      if (!isUndefined(allFilters[idNameForCustomReport]) && properlyKeyMappedData.length > 0) {
        const newFilteredData = new FilterableData(properlyKeyMappedData);
        newFilteredData.bulkFilter(allFilters[idNameForCustomReport].appliedFilters);
        setFilteredReport(newFilteredData.toArray());
        // setFilteredReport(displayDataWithCustomNames(newFilteredData.toArray()));
      }
    }
  }, [allFilters, properlyKeyMappedData]);

  function stringToHslColor(str: string, s: number, l: number) {
    let hash = 0;

    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    const h = hash % 360;
    return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
  }

  function stringAvatar(name: string) {
    if (name == undefined || name == null || name == '') name = 'Not Assigned';
    return {
      sx: {
        bgcolor: stringToHslColor(name, 30, 80),
      },
      children: (
        <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#000000' }}>
          {name.split(' ')[0][0]}
          {name.indexOf(' ') >= 0 ? name.split(' ')[1][0] : null}
        </Typography>
      ),
    };
  }

  const updateReport = async () => {
    // navigate(`/reports/update-aggregated-custom-report/${reportId}`);
    // const res = await dispatch(getAggregatedCustomReportConfigByUid(reportId)).unwrap();
    // setReportDetail({ ...res[0] });
    setUpdateDrawer(true);
  };

  const refreshFilters = async () => {
    if (reportDetail && filters.length > 0) {
      await dispatch(
        deleteFilterGroup({
          idName: idNameForCustomReport,
        })
      );
    }
  };

  return (
    <React.Fragment>
      {reportDetail && response ? (
        <Box sx={{ display: 'grid', gridTemplateRows: '70px auto', height: '100%' }}>
          <ReportsHeader pageName={'Custom report'} setDrawerState={setDrawerState} />
          <Box mx={1}>
            <Grid container direction="row" alignItems="center" justifyContent={'space-between'}>
              <Grid>
                <Typography variant="h5" sx={{ fontWeight: 500 }}>
                  <IconButton color="primary" aria-label="SMTP">
                    <AssessmentIcon fontSize="large" />
                  </IconButton>
                  {reportDetail?.report_name}&nbsp;
                  {reportDetail?.report_type === REPORT_TYPE_AGGREGATED_REPORT && (
                    <Chip
                      sx={{ marginRight: '4px' }}
                      label={'aggregated'}
                      size="small"
                      color="secondary"
                      // variant="outlined"
                    />
                  )}
                </Typography>
              </Grid>
              <Grid>
                <FilterTypeSelector idName={idNameForCustomReport} />
                {showEditButton && (
                  <CognisaasButton clickHandler={() => updateReport()} label="Report Config" isOutlined={true} />
                )}
              </Grid>
            </Grid>

            {allFilters[idNameForCustomReport]?.appliedFilters.filter((item: any) => item.isSelected).length > 0 && (
              <SelectedGroupFilterList idName={idNameForCustomReport} isResetEnabled={true} />
            )}

            {graphs !== undefined && graphs.length > 0 && filteredReport.length > 0 && (
              <Box>
                <ChartView
                  idName={idNameForCustomReport}
                  data={filteredReport}
                  numberOfcharts={2}
                  setDrawerState={setDrawerState}
                  setGraphData={setGraphData}
                  setFormActionType={setFormActionType}
                  configType={'any'}
                />
              </Box>
            )}
            <>
              {response && response?.data?.length == 0 ? (
                <Stack style={{ marginTop: '32px' }} justifyContent={'center'} alignItems={'center'}>
                  <Stack direction={'row'}>
                    <Typography variant="h5" className="label-text">
                      Nothing to show!
                    </Typography>
                    <CognisaaasTooltip
                      title={
                        <Typography sx={{ margin: '8px' }} variant="subtitle1">
                          Either you don&apos;t have relevant data for the report or you don&apos;t have access to the
                          data in the report.
                        </Typography>
                      }
                    >
                      <IconButton disableRipple size="small">
                        <Info />
                      </IconButton>
                    </CognisaaasTooltip>
                  </Stack>
                  <Stack sx={{ marginTop: '24px' }}>
                    <NoRecordsFound />
                  </Stack>
                </Stack>
              ) : (
                <Grid sx={{ marginTop: '24px' }}>
                  <Grid item>
                    <StyledBox>
                      <StripedDataGrid
                        columns={columns}
                        components={{
                          Toolbar: GridToolbar,
                        }}
                        componentsProps={{
                          toolbar: { showQuickFilter: true },
                        }}
                        rows={filteredReport}
                        getRowId={() => {
                          return makeid(5);
                        }}
                        pageSize={pageSize}
                        rowThreshold={0}
                        rowsPerPageOptions={[5, 10, 20, 50, 100]}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        pagination
                        disableSelectionOnClick
                        getRowClassName={(params: any) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'odd' : 'odd')}
                      />
                    </StyledBox>
                  </Grid>
                </Grid>
              )}
            </>
          </Box>
        </Box>
      ) : (
        <CognisaasLoader />
      )}

      {updateDrawer && (
        <UpdateAggregatedCustomReports
          reportDetails={reportDetail}
          reportId={reportId}
          onClose={() => {
            refreshFilters();
            setUpdateDrawer(false);
            getReport();
          }}
          refreshFilters={[]}
        />
      )}

      <CustomiseDrawer
        idName={idNameForCustomReport}
        configType="any"
        setDrawerState={setDrawerState}
        drawerState={drawerState}
        sampleData={filteredReport[0]}
        formActionType={formActionType}
        setFormActionType={setFormActionType}
        graphData={graphData}
        customReportConfig={reportDetail}
      />
    </React.Fragment>
  );
};

export default Report;
