import PhaseCard from './Phase/PhaseCard';
import { Grid, Stack } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectProjectInformation } from '../../store/modules/Project/selector';
import EmptyPhase from './Phase/EmptyPhase';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import NewTaskDrawer from './AddTask/NewTaskDrawer';
import { useDispatch } from 'react-redux';
import {
  getJiraPriorityList,
  getJiraProjectIssueTypesList,
  getJiraProjects,
  incrementCaseSectionSequence,
  incrementProjectSectionSequence,
  syncAllJiraTasks,
} from '../../store/modules/Task/slice';
import { getCustomerDetail, updateCaseWithfeatureStatus } from '../../store/modules/Common/slice';
import { selectCustomerDetail } from '../../store/modules/Common/selector';
import UpdateTaskDrawer from './TaskView/UpdateTaskDrawer';
import { incrementProjectTaskSequence, incrementCaseTaskSequence } from '../../store/modules/Task/slice';

import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import {
  selectIncrementTaskSequenceStatus,
  selectIncrementCaseTaskSequenceStatus,
  selectJiraProjects,
  selectJiraSyncedTasksStatus,
} from 'store/modules/Task/selector';
import { requestState } from 'store/modules/types';
import OverlaySpinner from 'components/shared/CognisaasOverlaySpinner/CognisaasOverlaySpinner';
import { getProjectInformation, getProjectInformationWithCaseTaskByUid } from 'store/modules/Project/slice';
import { selectProjectInformationStatus } from '../../store/modules/Project/selector';
import { getJiraCustomFieldNames } from './jiraHelpers/jiraUtil';
import { getJiraJsdServicesRequestLists } from 'store/modules/Settings/slice';
import { selectClientByAssociationUid } from 'store/modules/ManageClient/ClientList/selector';
import { fetchClientContacts, fetchClientInformation } from 'store/modules/ManageClient/ClientList/slice';
import { userPermissionSchema } from 'components/Reports/SideDrawer/types';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
interface WrapperType {
  addScrollbar: boolean;
}

const Wrapper = styled.div<WrapperType>`
  min-height: 500px;
  overflow-y: hidden;
  overflow-x: scroll;
  width: 100%;
  display: flex;
  margin-top: 10px;
  ::-webkit-scrollbar-track {
    box-shadow: inset 'none';
    background: 'transparent';
  }
  -ms-overflow-style: none; /* IE 10+ */
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: none !important;
    background-color: transparent;
  }
  ::-webkit-scrollbar {
    width: ${(props) => (props.addScrollbar === true ? '6px !important' : '0px !important')};
    background-color: #fff;
  }
  ::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
`;

const PlanHome = (props: any) => {
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const customerDetail = useAppSelector(selectCustomerDetail);
  const incrementTaskSequenceStatus = useAppSelector(selectIncrementTaskSequenceStatus);
  const incrementCaseTaskSequenceStatus = useAppSelector(selectIncrementCaseTaskSequenceStatus);
  const projectInformationStatus = useAppSelector(selectProjectInformationStatus);
  const projectInformation = useAppSelector(selectProjectInformation);

  const [addTaskDrawer, setAddTaskDrawer] = useState<boolean>(false);
  const [updateTaskDrawer, setUpdateTaskDrawer] = useState<boolean>(false);
  const [addTaskForPhase, setAddTaskForPhase] = useState<string | null>(null);
  const [caseDetails, setCaseDetails] = useState<any>(null);
  const [addScrollbar, setAddScrollbar] = useState<boolean>(false);
  const [taskDetailForUpdateTaskDrawer, setTaskDetailForUpdateTaskDrawer] = useState<any>(null);
  const jiraProjectList = useAppSelector(selectJiraProjects);
  const syncedJiraTasks = useAppSelector(selectJiraSyncedTasksStatus);
  const clientInformation = useAppSelector(selectClientByAssociationUid);

  const { projectId, associationId, caseId } = useParams();

  let isProject = false; //! Will decide if it is project or usecase
  let isScrolling: any;
  const client = props.client;

  const projectLoading: boolean =
    incrementTaskSequenceStatus === requestState.loading || projectInformationStatus == requestState.loading;
  const caseLoading: boolean =
    incrementCaseTaskSequenceStatus === requestState.loading || projectInformationStatus == requestState.loading;

  useEffect(() => {
    if (customerDetail == null) {
      dispatch(getCustomerDetail());
      syncTaskFromJiraByClient();
    }
  }, []);

  // const syncTasks = async () => {
  //   if (customerDetail.customer_jira_user && customerDetail.customer_jira_user.length > 0) {
  //     const key = btoa(btoa(customerDetail.customer_jira_user + ':' + customerDetail.customer_jira_token));

  //     const customfield = await getJiraCustomFieldNames(
  //       customerDetail.customer_jira_token,
  //       customerDetail.customer_jira_user,
  //       customerDetail.customer_uid,
  //       jiraProjectList[0].project_key,
  //       issueTypes.filter((item) => item.id === '10002')[0]
  //     );

  //     dispatch(
  //       syncAllJiraTasks({
  //         client_uid: clientInformation.client_uid,
  //         customDeliveryDate: customfield.customDeliveryDate,
  //         customer_uid: customerDetail.customer_uid,
  //         key: key,
  //       })
  //     );
  //   }
  // };

  const syncTaskFromJiraByClient = async () => {
    // setWaitingForTasksync(true);
    await dispatch(getCustomerDetail());
    const key = btoa(btoa(`${customerDetail.customer_jira_user}:${customerDetail.customer_jira_token}`));

    await appDispatch(getJiraProjects()).then(async (res: any) => {
      const taskJiraProject = res.payload[0];
      const data = {
        customer_uid: cookies.get('cogni_customer_uid'),
        key: key,
        project_key: taskJiraProject.project_key,
      };
      appDispatch(getJiraProjectIssueTypesList(data)).then(async (isuueTypeRes: any) => {
        const issueParsedRes = JSON.parse(isuueTypeRes.payload);
        const taskIssueType = issueParsedRes.issueTypes[0];

        const customfield = await getJiraCustomFieldNames(
          customerDetail.customer_jira_token,
          customerDetail.customer_jira_user,
          customerDetail.customer_uid,
          taskJiraProject.project_key,
          taskIssueType
        );
        dispatch(
          syncAllJiraTasks({
            client_uid: clientInformation.client_uid,
            customDeliveryDate: customfield.customDeliveryDate,
            customer_uid: customerDetail.customer_uid,
            key: key,
          })
        );
      });
    });
  };

  useEffect(() => {
    // dispatch(
    //   getProjectInformation({
    //     project_uid: projectInformation.project_uid,
    //     association_uid: projectInformation.association_uid,
    //   })
    // );
    const detail = {
      association_uid: projectInformation.association_uid,
      project_uid: projectInformation.project_uid,
    };
    const test = {
      project_uid: projectInformation.project_uid,
    };
    dispatch(updateCaseWithfeatureStatus(projectInformation.client_uid));

    dispatch(fetchClientInformation(projectInformation.client_uid));

    dispatch(fetchClientContacts(projectInformation.client_uid));

    dispatch(getProjectInformationWithCaseTaskByUid(test));

    dispatch(getProjectInformation(detail));
  }, [syncedJiraTasks.data]);

  useEffect(() => {
    if (jiraProjectList.length > 0) {
      const key = btoa(btoa(`${customerDetail.customer_jira_user}:${customerDetail.customer_jira_token}`));

      if (customerDetail.customer_JSD === '1') {
        dispatch(
          getJiraJsdServicesRequestLists({
            customer_uid: customerDetail.customer_uid,
            key: key,
            jira_service_desk_id: jiraProjectList[0].jira_service_desk_id,
          })
        );
      } else {
        const data = {
          customer_uid: customerDetail.customer_uid,
          key: key,
          project_key: jiraProjectList[0].project_key,
        };
        // console.log('DDDDDDDDDDDDDD', data1);
        dispatch(getJiraProjectIssueTypesList(data));
      }
    }
  }, [jiraProjectList]);

  // useEffect(() => {
  //   if (issueTypes.length > 0 && jiraProjectList.length > 0) {
  //     syncTasks();
  //   }
  // }, [issueTypes]);

  useEffect(() => {
    if (customerDetail) {
      if (customerDetail.customer_jira_user.length > 0) {
        const detail = {
          key: btoa(btoa(`${customerDetail.customer_jira_user}:${customerDetail.customer_jira_token}`)),
          customer_uid: customerDetail.customer_uid,
        };
        dispatch(getJiraPriorityList(detail));
        dispatch(getJiraProjects());
      }
      // console.log('CUSTOMER DETAIL', customerDetail);
    }
  }, [customerDetail]);

  if (!caseId) {
    isProject = true;
  }

  useEffect(() => {
    if (!isProject) {
      setUsecaseDetails();
    }
  }, [projectInformation]);

  const setUsecaseDetails = () => {
    if (!isProject) {
      const usecase = projectInformation.usecase.filter((uc: any) => uc.case_uid == caseId);
      console.log(usecase);
      setCaseDetails({ ...usecase[0] });
    }
  };

  const toggleAddTaskDrawerState = () => {
    setAddTaskDrawer(!addTaskDrawer);
  };
  const setPhase = (phase: string) => {
    // console.log(phase);
    setAddTaskForPhase(phase);
  };

  const toggleUpdateTaskDrawer = (detail: any) => {
    // console.log('update task drawer', updateTaskDrawer, detail);
    setTaskDetailForUpdateTaskDrawer({ ...detail });

    setUpdateTaskDrawer(!updateTaskDrawer);
  };

  const handleScroll = () => {
    setAddScrollbar(true);
    clearTimeout(isScrolling);
    isScrolling = window.setTimeout(() => {
      setAddScrollbar(false);
    }, 500);
  };

  const handleProjectDragEnd = async (result: any) => {
    const { draggableId, type } = result;
    const { index: destinationIndex, droppableId } = result.destination;
    const { index: sourceIndex } = result.source;
    const project_uid = projectInformation.project_uid;
    if (destinationIndex === sourceIndex) return;
    if (type === 'phase') {
      await dispatch(
        incrementProjectSectionSequence({
          project_uid,
          section_sequence: destinationIndex < sourceIndex ? destinationIndex : destinationIndex + 1,
          section_uid: draggableId,
        })
      );
    } else {
      await dispatch(
        incrementProjectTaskSequence({
          project_uid,
          task_sequence: destinationIndex < sourceIndex ? destinationIndex : destinationIndex + 1,
          task_uid: draggableId,
          section_uid: droppableId,
        })
      );
    }
    await dispatch(
      dispatch(
        getProjectInformation({
          project_uid: projectInformation.project_uid,
          association_uid: projectInformation.association_uid,
        })
      )
    );
  };

  const handleCaseDragEnd = async (result: any) => {
    const { draggableId, type } = result;
    const { index: destinationIndex, droppableId } = result.destination;
    const { index: sourceIndex } = result.source;
    const case_uid = caseId;
    const project_uid = projectInformation.project_uid;
    if (destinationIndex === sourceIndex) return;
    if (type === 'phase') {
      await dispatch(
        incrementCaseSectionSequence({
          case_uid,
          section_sequence: destinationIndex < sourceIndex ? destinationIndex : destinationIndex + 1,
          section_uid: draggableId,
        })
      );
    } else {
      await dispatch(
        incrementCaseTaskSequence({
          project_uid,
          case_uid,
          task_sequence: destinationIndex < sourceIndex ? destinationIndex : destinationIndex + 1,
          task_uid: draggableId,
          section_uid: droppableId,
        })
      );
    }
    await dispatch(
      dispatch(
        getProjectInformation({
          project_uid: projectInformation.project_uid,
          association_uid: projectInformation.association_uid,
        })
      )
    );
  };

  return (
    <>
      <Wrapper onScroll={handleScroll} addScrollbar={addScrollbar}>
        <>
          {(projectLoading || caseLoading) && <OverlaySpinner />}
          {isProject && (
            <>
              <DragDropContext onDragEnd={handleProjectDragEnd}>
                <Droppable droppableId="columns" direction="horizontal" type="phase">
                  {(provided) => (
                    <Stack {...provided.droppableProps} ref={provided.innerRef} direction="row">
                      {projectInformation?.section?.map((phase: any) => {
                        return (
                          <Grid key={phase.section_uid} item>
                            <PhaseCard
                              openUpdateTaskDrawer={toggleUpdateTaskDrawer}
                              setPhase={setPhase}
                              addTaskDrawerToggle={toggleAddTaskDrawerState}
                              projectInfo={projectInformation}
                              phase={phase}
                              key={phase.section_uid}
                              clientInformation={clientInformation}
                              projectInformation={projectInformation}
                              userPermissions={projectInformation.__user_permissions}
                              index={parseInt(phase.section_sequence)}
                            />
                          </Grid>
                        );
                      })}
                      {provided.placeholder}
                    </Stack>
                  )}
                </Droppable>
                {projectInformation?.__user_permissions?.includes(userPermissionSchema.EDIT) && (
                  <EmptyPhase
                    associationUid={associationId}
                    projectUid={projectId}
                    sections={projectInformation.section || []}
                  />
                )}
              </DragDropContext>
            </>
          )}
          {!isProject && (
            <DragDropContext onDragEnd={handleCaseDragEnd}>
              <Droppable droppableId="columns" direction="horizontal" type="phase">
                {(provided) => (
                  <Stack {...provided.droppableProps} ref={provided.innerRef} direction="row">
                    {caseDetails?.section?.map((phase: any) => {
                      return (
                        <Grid key={phase.section_uid}>
                          <PhaseCard
                            openUpdateTaskDrawer={toggleUpdateTaskDrawer}
                            setPhase={setPhase}
                            addTaskDrawerToggle={toggleAddTaskDrawerState}
                            projectInfo={projectInformation}
                            phase={phase}
                            key={phase.section_uid}
                            clientInformation={clientInformation}
                            projectInformation={projectInformation}
                            userPermissions={props.useCaseInfo.__user_permissions}
                            index={parseInt(phase.section_sequence)}
                          />
                        </Grid>
                      );
                    })}
                    {provided.placeholder}
                  </Stack>
                )}
              </Droppable>
              {props.useCaseInfo.__user_permissions?.includes(userPermissionSchema.EDIT) && (
                <EmptyPhase
                  caseUid={caseId}
                  associationUid={associationId}
                  projectUid={projectId}
                  sections={caseDetails?.section || []}
                />
              )}
            </DragDropContext>
          )}
        </>
      </Wrapper>

      {
        <NewTaskDrawer
          // key={Date.now()}
          client={projectInformation.client_uid}
          associationUid={associationId}
          phaseUid={addTaskForPhase}
          isProjectTask={isProject}
          projectUid={projectId}
          caseUid={caseId}
          drawerState={addTaskDrawer}
          onClose={() => setAddTaskDrawer(!addTaskDrawer)}
          caseOrProjectName={caseDetails?.case_name}
        />
      }
      {updateTaskDrawer && (
        <UpdateTaskDrawer
          phaseUid={addTaskForPhase}
          client={client}
          taskDetail={taskDetailForUpdateTaskDrawer}
          drawerState={updateTaskDrawer}
          onClose={() => {
            setUpdateTaskDrawer(!updateTaskDrawer);
            setUsecaseDetails();
          }}
          caseOrProjectName={caseDetails?.case_name}
          projectInformation={projectInformation}
          userPermissions={projectInformation.__user_permissions || props.useCaseInfo.__user_permissions}
        />
      )}
    </>
  );
};

export default PlanHome;
