import { format } from 'date-fns';

export const groupByPhase = (items: any[]) => {
  for (let i = 0; i < items.length; i++) {
    const clientTasksPhaseUids: string[] = [];
    const clientTasksByPhase: any = {};
    for (let j = 0; j < items[i].clientTask.length; j++) {
      clientTasksPhaseUids.push(items[i].clientTask[j].section_uid);
    }
    for (let uid = 0; uid < clientTasksPhaseUids.length; uid++) {
      const tasksOfUid: any[] = [];
      for (let task = 0; task < items[i].clientTask.length; task++) {
        if (clientTasksPhaseUids[uid] == items[i].clientTask[task].section_uid) {
          tasksOfUid.push(items[i].clientTask[task]);
        }
      }
      clientTasksByPhase[tasksOfUid[0].section_detail] = [...tasksOfUid];
    }

    items[i].clientTask = clientTasksByPhase;

    const tasksPhaseUids: string[] = [];
    const tasksByPhase: any = {};
    for (let j = 0; j < items[i].task.length; j++) {
      tasksPhaseUids.push(items[i].task[j].section_uid);
    }
    for (let uid = 0; uid < tasksPhaseUids.length; uid++) {
      const tasksOfUid: any[] = [];
      for (let t = 0; t < items[i].task.length; t++) {
        if (tasksPhaseUids[uid] == items[i].task[t].section_uid) {
          tasksOfUid.push(items[i].task[t]);
        }
      }
      tasksByPhase[tasksOfUid[0].section_detail] = [...tasksOfUid];
    }

    items[i].task = tasksByPhase;

    // For usecase
    for (let uc = 0; uc < items[i].usecase.length; uc++) {
      const clientTasksPhaseUids: string[] = [];
      const clientTasksByPhase: any = {};
      for (let j = 0; j < items[i].usecase[uc].clientTask.length; j++) {
        clientTasksPhaseUids.push(items[i].usecase[uc].clientTask[j].section_uid);
      }
      for (let uid = 0; uid < clientTasksPhaseUids.length; uid++) {
        const tasksOfUid: any[] = [];
        for (let task = 0; task < items[i].usecase[uc].clientTask.length; task++) {
          if (clientTasksPhaseUids[uid] == items[i].usecase[uc].clientTask[task].section_uid) {
            tasksOfUid.push(items[i].usecase[uc].clientTask[task]);
          }
        }
        clientTasksByPhase[tasksOfUid[0].section_detail] = [...tasksOfUid];
      }
      items[i].usecase[uc].clientTask = clientTasksByPhase;

      const tasksPhaseUids: string[] = [];
      const tasksByPhase: any = {};
      for (let j = 0; j < items[i].usecase[uc].task.length; j++) {
        tasksPhaseUids.push(items[i].usecase[uc].task[j].section_uid);
      }
      for (let uid = 0; uid < tasksPhaseUids.length; uid++) {
        const tasksOfUid: any[] = [];
        for (let t = 0; t < items[i].usecase[uc].task.length; t++) {
          if (tasksPhaseUids[uid] == items[i].usecase[uc].task[t].section_uid) {
            tasksOfUid.push(items[i].usecase[uc].task[t]);
          }
        }
        tasksByPhase[tasksOfUid[0].section_detail] = [...tasksOfUid];
      }

      items[i].usecase[uc].task = tasksByPhase;
    }
  }
  return items;
};
export const normalFilteringProject = (projectInfo: any, statuses: any) => {
  let filteredArray1: any = [];
  filteredArray1 = projectInfo.map((element: any) => {
    let tempArray: any = [];
    const tempElement: any = { ...element };
    tempElement.client_tasks = [];
    tempElement.taskCount = 0;
    tempElement.doneTaskCount = 0;
    tempElement.pendingTaskCount = 0;
    tempElement.redTaskCount = 0;
    tempElement.ucsecaseCount = 0;
    tempElement.task = tempElement.task.filter(function (el: any) {
      return statuses.indexOf(el.task_status) >= 0;
    });
    tempElement.task.forEach((task: any) => {
      tempArray.push(task);
      tempElement.taskCount += 1;
      if (task.task_stage === 'Done') tempElement.doneTaskCount += 1;
      if (task.task_status !== 'red' && task.task_status !== 'blue') tempElement.pendingTaskCount += 1;
      if (task.task_status === 'red') tempElement.redTaskCount += 1;
    });
    tempElement.task = [];
    tempElement.task = tempArray;
    tempArray = [];
    // Shared with client
    tempElement.shared_with_client.forEach((task: any) => {
      tempArray.push(task);
    });
    tempElement.shared_with_client = [];
    tempElement.shared_with_client = tempArray;
    tempArray = [];
    // client_initiate
    tempElement.client_initiate.forEach((task: any) => {
      tempArray.push(task);
    });
    tempElement.client_initiate = [];
    tempElement.client_initiate = tempArray;
    tempArray = [];
    tempElement.clientTask = tempElement.clientTask.filter(function (el: any) {
      return statuses.indexOf(el.task_status) >= 0;
    });
    tempElement.clientTask.forEach((clientTask: any) => {
      tempArray.push(clientTask);
      if (clientTask.client_task == 0) {
        tempElement.shared_with_client.push(clientTask);
      } else {
        tempElement.client_tasks.push(clientTask);
      }
      tempElement.taskCount += 1;
      if (clientTask.task_stage === 'Done') tempElement.doneTaskCount += 1;
      if (clientTask.task_status !== 'red' && clientTask.task_status !== 'blue') tempElement.pendingTaskCount += 1;
      if (clientTask.task_status === 'red') tempElement.redTaskCount += 1;
    });
    tempElement.clientTask = [];
    tempElement.clientTask = tempArray;
    tempArray = [];
    tempElement.usecaseCount = tempElement.usecase.length;
    tempElement.usecase = tempElement.usecase.map((usec: any) => {
      const tempUsec: any = { ...usec };
      tempArray = [];
      tempUsec.client_tasks = [];
      tempUsec.task = tempUsec.task.filter(function (el: any) {
        return statuses.indexOf(el.task_status) >= 0;
      });
      tempUsec.task.forEach((uctask: any) => {
        tempArray.push(uctask);
        tempElement.taskCount += 1;
        if (uctask.task_stage === 'Done') tempElement.doneTaskCount += 1;
        if (uctask.task_status !== 'red' && uctask.task_status !== 'blue') tempElement.pendingTaskCount += 1;
        if (uctask.task_status === 'red') tempElement.redTaskCount += 1;
      });
      tempUsec.task = [];
      tempUsec.task = tempArray;
      tempArray = [];
      // Shared with client
      tempUsec.shared_with_client.forEach((uctask: any) => {
        tempArray.push(uctask);
      });
      tempUsec.shared_with_client = [];
      tempUsec.shared_with_client = tempArray;
      tempArray = [];
      // client_initiate
      tempUsec.client_initiate.forEach((uctask: any) => {
        tempArray.push(uctask);
      });
      tempUsec.client_initiate = [];
      tempUsec.client_initiate = tempArray;
      tempArray = [];
      tempUsec.clientTask = tempUsec.clientTask.filter(function (el: any) {
        return statuses.indexOf(el.task_status) >= 0;
      });
      tempUsec.clientTask.forEach((clientTask: any) => {
        tempArray.push(clientTask);
        tempElement.taskCount += 1;
        if (clientTask.task_stage === 'Done') tempElement.doneTaskCount += 1;
        if (clientTask.task_status !== 'red' && clientTask.task_status !== 'blue') tempElement.pendingTaskCount += 1;
        if (clientTask.task_status === 'red') tempElement.redTaskCount += 1;
      });
      tempUsec.clientTask = [];
      tempUsec.clientTask = tempArray;
      return tempUsec;
    });
    return tempElement;
  });
  return filteredArray1;
};
export const dateRangeFilteringProject = (
  projectInfo: any,
  today: string,
  maxDate: string,
  minDate: string,
  statuses: any
) => {
  console.log('dateRangeFilteringProject', minDate, maxDate);
  const filteredArray1 = projectInfo.map((element: any) => {
    const tempElement: any = { ...element };
    tempElement.client_tasks = [];
    tempElement.taskCount = 0;
    tempElement.doneTaskCount = 0;
    tempElement.pendingTaskCount = 0;
    tempElement.redTaskCount = 0;
    tempElement.ucsecaseCount = 0;
    let tempArray: any = [];
    tempElement.task = tempElement.task.filter(function (el: any) {
      return statuses.indexOf(el.task_status) >= 0;
    });
    tempElement.task.forEach((task: any) => {
      // if (task.task_stage == 'Done') {
      // if (
      //   format(new Date(task.task_closed_date.replace(/-/g, '/')), 'yyyy-MM-dd') < today &&
      //   format(new Date(task.task_closed_date.replace(/-/g, '/')), 'yyyy-MM-dd') >= minDate
      // ) {
      //   tempArray.push(task);
      //   tempElement.taskCount += 1;
      //   if (task.task_stage === 'Done') tempElement.doneTaskCount += 1;
      //   if (task.task_status !== 'red' && task.task_status !== 'blue') tempElement.pendingTaskCount += 1;
      //   if (task.task_status === 'red') tempElement.redTaskCount += 1;
      // }
      // } else {
      if (task.task_stage != 'Done' &&
        ((format(new Date(task.task_due_date.replace(/-/g, '/')), 'yyyy-MM-dd') >= minDate &&
          format(new Date(task.task_due_date.replace(/-/g, '/')), 'yyyy-MM-dd') < maxDate) ||
          (format(new Date(task.task_closed_date.replace(/-/g, '/')), 'yyyy-MM-dd') >= minDate &&
            format(new Date(task.task_closed_date.replace(/-/g, '/')), 'yyyy-MM-dd') < maxDate))
      ) {
        tempArray.push(task);
        tempElement.taskCount += 1;
        if (task.task_stage === 'Done') tempElement.doneTaskCount += 1;
        if (task.task_status !== 'red' && task.task_status !== 'blue') tempElement.pendingTaskCount += 1;
        if (task.task_status === 'red') tempElement.redTaskCount += 1;
      }
      else if (task.task_stage == 'Done' &&
        ((format(new Date(task.task_closed_date.replace(/-/g, '/')), 'yyyy-MM-dd') >= minDate &&
          format(new Date(task.task_closed_date.replace(/-/g, '/')), 'yyyy-MM-dd') < maxDate))
      ) {
        tempArray.push(task);
        tempElement.taskCount += 1;
        if (task.task_stage === 'Done') tempElement.doneTaskCount += 1;
        if (task.task_status !== 'red' && task.task_status !== 'blue') tempElement.pendingTaskCount += 1;
        if (task.task_status === 'red') tempElement.redTaskCount += 1;
      }
      // }
    });
    tempElement.task = [];
    tempElement.task = tempArray;
    tempArray = [];
    // Shared with client
    tempElement.shared_with_client.forEach((task: any) => {
      // if (task.task_stage == 'Done') {
      if (
        format(new Date(task.task_closed_date.replace(/-/g, '/')), 'yyyy-MM-dd') < today &&
        format(new Date(task.task_closed_date.replace(/-/g, '/')), 'yyyy-MM-dd') >= minDate
      ) {
        tempArray.push(task);
      }
      // } else {
      if (
        (format(new Date(task.task_due_date.replace(/-/g, '/')), 'yyyy-MM-dd') >= today &&
          format(new Date(task.task_due_date.replace(/-/g, '/')), 'yyyy-MM-dd') < maxDate) ||
        (format(new Date(task.task_closed_date.replace(/-/g, '/')), 'yyyy-MM-dd') >= today &&
          format(new Date(task.task_closed_date.replace(/-/g, '/')), 'yyyy-MM-dd') < maxDate)
      ) {
        tempArray.push(task);
      }
      // }
    });
    tempElement.shared_with_client = [];
    tempElement.shared_with_client = tempArray;
    tempArray = [];
    // client_initiate
    tempElement.client_initiate.forEach((task: any) => {
      // if (task.task_stage == 'Done') {
      if (
        format(new Date(task.task_closed_date.replace(/-/g, '/')), 'yyyy-MM-dd') < today &&
        format(new Date(task.task_closed_date.replace(/-/g, '/')), 'yyyy-MM-dd') >= minDate
      ) {
        tempArray.push(task);
      }
      // } else {
      if (
        (format(new Date(task.task_due_date.replace(/-/g, '/')), 'yyyy-MM-dd') >= today &&
          format(new Date(task.task_due_date.replace(/-/g, '/')), 'yyyy-MM-dd') < maxDate) ||
        (format(new Date(task.task_closed_date.replace(/-/g, '/')), 'yyyy-MM-dd') >= today &&
          format(new Date(task.task_closed_date.replace(/-/g, '/')), 'yyyy-MM-dd') < maxDate)
      ) {
        tempArray.push(task);
      }
      // }
    });
    tempElement.client_initiate = [];
    tempElement.client_initiate = tempArray;
    tempArray = [];
    tempElement.clientTask = tempElement.clientTask.filter(function (el: any) {
      return statuses.indexOf(el.task_status) >= 0;
    });
    tempElement.clientTask = tempElement.clientTask.filter(function (el: any) {
      return statuses.indexOf(el.task_status) >= 0;
    });
    tempElement.clientTask.forEach((clientTask: any) => {
      // if (clientTask.task_stage == 'Done') {
      // if (
      //   format(new Date(clientTask.task_closed_date.replace(/-/g, '/')), 'yyyy-MM-dd') < today &&
      //   format(new Date(clientTask.task_closed_date.replace(/-/g, '/')), 'yyyy-MM-dd') >= minDate
      // ) {
      //   tempArray.push(clientTask);
      //   // if (clientTask.client_task == 0) {
      //   //   tempElement.shared_with_client.push(clientTask);
      //   // } else {
      //   //   tempElement.client_tasks.push(clientTask);
      //   // }
      //   tempElement.taskCount += 1;
      //   if (clientTask.task_stage === 'Done') tempElement.doneTaskCount += 1;
      //   if (clientTask.task_status !== 'red' && clientTask.task_status !== 'blue') tempElement.pendingTaskCount += 1;
      //   if (clientTask.task_status === 'red') tempElement.redTaskCount += 1;
      // }
      // } else {
      if (clientTask.task_stage != 'Done' &&
        ((format(new Date(clientTask.task_due_date.replace(/-/g, '/')), 'yyyy-MM-dd') >= minDate &&
          format(new Date(clientTask.task_due_date.replace(/-/g, '/')), 'yyyy-MM-dd') < maxDate) ||
          (format(new Date(clientTask.task_closed_date.replace(/-/g, '/')), 'yyyy-MM-dd') >= minDate &&
            format(new Date(clientTask.task_closed_date.replace(/-/g, '/')), 'yyyy-MM-dd') < maxDate))
      ) {
        tempArray.push(clientTask);
        // if (clientTask.client_task == 0) {
        //   tempElement.shared_with_client.push(clientTask);
        // } else {
        //   tempElement.client_tasks.push(clientTask);
        // }
        tempElement.taskCount += 1;
        if (clientTask.task_stage === 'Done') tempElement.doneTaskCount += 1;
        if (clientTask.task_status !== 'red' && clientTask.task_status !== 'blue') tempElement.pendingTaskCount += 1;
        if (clientTask.task_status === 'red') tempElement.redTaskCount += 1;
      }
      else if (clientTask.task_stage == 'Done' &&
        (
          (format(new Date(clientTask.task_closed_date.replace(/-/g, '/')), 'yyyy-MM-dd') >= minDate &&
            format(new Date(clientTask.task_closed_date.replace(/-/g, '/')), 'yyyy-MM-dd') < maxDate))
      ) {
        tempArray.push(clientTask);
        // if (clientTask.client_task == 0) {
        //   tempElement.shared_with_client.push(clientTask);
        // } else {
        //   tempElement.client_tasks.push(clientTask);
        // }
        tempElement.taskCount += 1;
        if (clientTask.task_stage === 'Done') tempElement.doneTaskCount += 1;
        if (clientTask.task_status !== 'red' && clientTask.task_status !== 'blue') tempElement.pendingTaskCount += 1;
        if (clientTask.task_status === 'red') tempElement.redTaskCount += 1;
      }
      // }
    });
    tempElement.clientTask = [];
    tempElement.clientTask = tempArray;
    tempArray = [];
    tempElement.usecaseCount = tempElement.usecase.length;
    tempElement.usecase = tempElement.usecase.map((usec: any) => {
      const tempUsec: any = { ...usec };
      tempArray = [];
      tempUsec.client_tasks = [];
      tempUsec.task = tempUsec.task.filter(function (el: any) {
        return statuses.indexOf(el.task_status) >= 0;
      });
      tempUsec.task.forEach((uctask: any) => {
        // if (
        //   format(new Date(uctask.task_closed_date.replace(/-/g, '/')), 'yyyy-MM-dd') < today &&
        //   format(new Date(uctask.task_closed_date.replace(/-/g, '/')), 'yyyy-MM-dd') >= minDate
        // ) {
        //   tempArray.push(uctask);
        //   tempElement.taskCount += 1;
        //   if (uctask.task_stage === 'Done') tempElement.doneTaskCount += 1;
        //   if (uctask.task_status !== 'red' && uctask.task_status !== 'blue') tempElement.pendingTaskCount += 1;
        //   if (uctask.task_status === 'red') tempElement.redTaskCount += 1;
        // }
        // } else {
        if (uctask.task_stage != 'Done' &&
          ((format(new Date(uctask.task_due_date.replace(/-/g, '/')), 'yyyy-MM-dd') >= minDate &&
            format(new Date(uctask.task_due_date.replace(/-/g, '/')), 'yyyy-MM-dd') < maxDate) ||
            (format(new Date(uctask.task_closed_date.replace(/-/g, '/')), 'yyyy-MM-dd') >= minDate &&
              format(new Date(uctask.task_closed_date.replace(/-/g, '/')), 'yyyy-MM-dd') < maxDate))
        ) {
          tempArray.push(uctask);
          tempElement.taskCount += 1;
          if (uctask.task_stage === 'Done') tempElement.doneTaskCount += 1;
          if (uctask.task_status !== 'red' && uctask.task_status !== 'blue') tempElement.pendingTaskCount += 1;
          if (uctask.task_status === 'red') tempElement.redTaskCount += 1;
        }
        else if (uctask.task_stage == 'Done' &&
          ((format(new Date(uctask.task_closed_date.replace(/-/g, '/')), 'yyyy-MM-dd') >= minDate &&
            format(new Date(uctask.task_closed_date.replace(/-/g, '/')), 'yyyy-MM-dd') < maxDate))
        ) {
          tempArray.push(uctask);
          tempElement.taskCount += 1;
          if (uctask.task_stage === 'Done') tempElement.doneTaskCount += 1;
          if (uctask.task_status !== 'red' && uctask.task_status !== 'blue') tempElement.pendingTaskCount += 1;
          if (uctask.task_status === 'red') tempElement.redTaskCount += 1;
        }
        // }
      });
      tempUsec.task = [];
      tempUsec.task = tempArray;
      tempArray = [];
      // Shared with client
      tempUsec.shared_with_client.forEach((uctask: any) => {
        // if (uctask.task_stage == 'Done') {
        if (
          format(new Date(uctask.task_closed_date.replace(/-/g, '/')), 'yyyy-MM-dd') < today &&
          format(new Date(uctask.task_closed_date.replace(/-/g, '/')), 'yyyy-MM-dd') >= minDate
        ) {
          tempArray.push(uctask);
        }
        // } else {
        if (
          (format(new Date(uctask.task_due_date.replace(/-/g, '/')), 'yyyy-MM-dd') >= today &&
            format(new Date(uctask.task_due_date.replace(/-/g, '/')), 'yyyy-MM-dd') < maxDate) ||
          (format(new Date(uctask.task_closed_date.replace(/-/g, '/')), 'yyyy-MM-dd') >= today &&
            format(new Date(uctask.task_closed_date.replace(/-/g, '/')), 'yyyy-MM-dd') < maxDate)
        ) {
          tempArray.push(uctask);
        }
        // }
      });
      tempUsec.shared_with_client = [];
      tempUsec.shared_with_client = tempArray;
      tempArray = [];
      // client_initiate
      tempUsec.client_initiate.forEach((uctask: any) => {
        // if (uctask.task_stage == 'Done') {
        if (
          format(new Date(uctask.task_closed_date.replace(/-/g, '/')), 'yyyy-MM-dd') < today &&
          format(new Date(uctask.task_closed_date.replace(/-/g, '/')), 'yyyy-MM-dd') >= minDate
        ) {
          tempArray.push(uctask);
        }
        // } else {
        if (
          (format(new Date(uctask.task_closed_date.replace(/-/g, '/')), 'yyyy-MM-dd') >= today &&
            format(new Date(uctask.task_closed_date.replace(/-/g, '/')), 'yyyy-MM-dd') < maxDate) ||
          (format(new Date(uctask.task_closed_date.replace(/-/g, '/')), 'yyyy-MM-dd') >= today &&
            format(new Date(uctask.task_closed_date.replace(/-/g, '/')), 'yyyy-MM-dd') < maxDate)
        ) {
          tempArray.push(uctask);
        }
        // }
      });
      tempUsec.client_initiate = [];
      tempUsec.client_initiate = tempArray;
      tempArray = [];
      tempUsec.clientTask = tempUsec.clientTask.filter(function (el: any) {
        return statuses.indexOf(el.task_status) >= 0;
      });
      tempUsec.clientTask.forEach((clientTask: any) => {
        // if (clientTask.task_stage == 'Done') {
        // if (
        //   format(new Date(clientTask.task_closed_date.replace(/-/g, '/')), 'yyyy-MM-dd') < today &&
        //   format(new Date(clientTask.task_closed_date.replace(/-/g, '/')), 'yyyy-MM-dd') >= minDate
        // ) {
        //   tempArray.push(clientTask);
        //   if (clientTask.client_task == 0) {
        //     tempUsec.shared_with_client.push(clientTask);
        //   } else {
        //     tempUsec.client_tasks.push(clientTask);
        //   }
        //   tempElement.taskCount += 1;
        //   if (clientTask.task_stage === 'Done') tempElement.doneTaskCount += 1;
        //   if (clientTask.task_status !== 'red' && clientTask.task_status !== 'blue') tempElement.pendingTaskCount += 1;
        //   if (clientTask.task_status === 'red') tempElement.redTaskCount += 1;
        // }
        // } else {
        if (clientTask.task_stage != 'Done' &&
          ((format(new Date(clientTask.task_due_date.replace(/-/g, '/')), 'yyyy-MM-dd') >= minDate &&
            format(new Date(clientTask.task_due_date.replace(/-/g, '/')), 'yyyy-MM-dd') < maxDate) ||
            (format(new Date(clientTask.task_closed_date.replace(/-/g, '/')), 'yyyy-MM-dd') >= minDate &&
              format(new Date(clientTask.task_closed_date.replace(/-/g, '/')), 'yyyy-MM-dd') < maxDate))
        ) {
          tempArray.push(clientTask);
          // if (clientTask.client_task == 0) {
          //   tempUsec.shared_with_client.push(clientTask);
          // } else {
          //   tempUsec.client_tasks.push(clientTask);
          // }
          tempElement.taskCount += 1;
          if (clientTask.task_stage === 'Done') tempElement.doneTaskCount += 1;
          if (clientTask.task_status !== 'red' && clientTask.task_status !== 'blue') tempElement.pendingTaskCount += 1;
          if (clientTask.task_status === 'red') tempElement.redTaskCount += 1;
        }
        else if (clientTask.task_stage == 'Done' &&
          ((format(new Date(clientTask.task_closed_date.replace(/-/g, '/')), 'yyyy-MM-dd') >= minDate &&
            format(new Date(clientTask.task_closed_date.replace(/-/g, '/')), 'yyyy-MM-dd') < maxDate))
        ) {
          tempArray.push(clientTask);
          // if (clientTask.client_task == 0) {
          //   tempUsec.shared_with_client.push(clientTask);
          // } else {
          //   tempUsec.client_tasks.push(clientTask);
          // }
          tempElement.taskCount += 1;
          if (clientTask.task_stage === 'Done') tempElement.doneTaskCount += 1;
          if (clientTask.task_status !== 'red' && clientTask.task_status !== 'blue') tempElement.pendingTaskCount += 1;
          if (clientTask.task_status === 'red') tempElement.redTaskCount += 1;
        }
        // }
      });
      tempUsec.clientTask = [];
      tempUsec.clientTask = tempArray;
      return tempUsec;
    });
    return tempElement;
  });
  return filteredArray1;
};
export const summaryOfTheReport = (projectInfo: any) => {
  const projectCount = projectInfo.length;
  let taskCount = 0;
  let doneTaskCount = 0;
  let pendingTaskCount = 0;
  let redTaskCount = 0;
  let usecaseCount = 0;
  projectInfo.map((element: any) => {
    taskCount += element.taskCount;
    doneTaskCount += element.doneTaskCount;
    pendingTaskCount += element.pendingTaskCount;
    redTaskCount += element.redTaskCount;
    usecaseCount += element.usecaseCount;
  });
  return { projectCount, taskCount, doneTaskCount, pendingTaskCount, redTaskCount, usecaseCount };
};
