// import { getAllTasksOfAUseCase } from '../../utils';
import CognisaasCard from '../../../shared/CognisaasCard/CognisaasCard';
import { Typography, Stack, Avatar, Divider } from '@mui/material';
// import CognisaasTaskStageIndicator from '../../../shared/CognisaasTaskStageIndicator/CognisaasTaskStageIndicator';
// import { taskStage } from '../../../shared/CognisaasTaskStageIndicator/CognisaasTaskStageIndicator';
import UsecasesCardContent from './UsecasesCardContent';
// import { useParams } from 'react-router-dom';
// import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import format from 'date-fns/format';
import { ReactComponent as NoRecordsFound } from '../../../../assets/svgs/no_records.svg';
import {
  label_due_date,
  label_use_case_delivery_manager,
} from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { getUserActiveState, INACTIVE_STRING, INACTIVE_USER_COLOR } from 'utils/userHelpers';
import { getUserUid } from 'utils/getUid';
import TypographyWithTooltip from 'components/shared/UI/TypographyWithTooltip';
import useUserDropDownData from 'components/shared/Hooks/useUserDropDownData';

const UsecasesCard = (props: any) => {
  // const params = useParams();
  // const navigate = useNavigate();
  const usecases = props.project.usecase;
  // const testTasks = getAllTasksOfAUseCase(props.project.usecase[0]);
  const companyUsersDropDownData = useUserDropDownData('company');

  const openThisUsecase = (usecase: any) => {
    return `${usecase.case_uid}`;
  };

  function stringToHslColor(str: string, s: number, l: number) {
    let hash = 0;

    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    const h = hash % 360;
    return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
  }

  function stringAvatar(name: string) {
    return {
      sx: {
        bgcolor: stringToHslColor(name, 30, 80),
      },
      children: (
        <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#000000' }}>
          {name.split(' ')[0][0]}
          {name.indexOf(' ') >= 0 ? name.split(' ')[1][0] : null}
        </Typography>
      ),
    };
  }

  const renderItem = () => {
    if (usecases.length > 0) {
      return (
        <>
          {usecases.map((usecase: any, index: number) => {
            return (
              <Stack p={1} key={index}>
                <CognisaasCard
                  key={index}
                  clientStatusColor={usecase.case_stage == 'On Hold' ? 'grey' : usecase.case_readinessstate_status}
                  height="268"
                  width="600"
                  customStyle={{ border: '1px solid #E5E5E5' }}
                  boxShadow={'0px 4px 26px rgba(0, 0, 0, 0.05)'}
                >
                  <Stack py={2} px={2} justifyContent="space-between" direction="row">
                    <Stack my={1}>
                      <Link
                        style={{ fontFamily: 'Noto Sans,sans-serif' }}
                        state={{ clientName: props.client.client_name, projectName: props.project.project_name }}
                        to={openThisUsecase(usecase)}
                      >
                        {usecase.case_name}
                      </Link>
                      <Stack spacing={1} direction="row">
                        <Typography className="label-text" variant="h3">
                          {label_due_date}
                        </Typography>
                        <Typography variant="h3">
                          {' '}
                          {format(new Date(usecase.case_requiredbydate.replace(/-/g, '/')), 'dd MMM yyyy')}
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack direction="row">
                      <Avatar
                        style={{ height: '38px', width: '38px', marginTop: '13px' }}
                        {...stringAvatar(usecase.case_deliverymanager)}
                      />
                      {/* <Avatar sx={{ marginTop: '6px' }}>S</Avatar> */}
                      <Stack>
                        <Typography className="label-text" ml={1} mt={1} variant="subtitle1">
                          {label_use_case_delivery_manager}
                        </Typography>
                        {getUserActiveState(getUserUid(usecase.case_deliverymanager), companyUsersDropDownData) ===
                        '0' ? (
                          <TypographyWithTooltip
                            style={{ color: INACTIVE_USER_COLOR }}
                            maxLength={13}
                            variant={'subtitle1'}
                            ml={1}
                            mt={0}
                          >
                            {`${usecase.case_deliverymanager} (${INACTIVE_STRING})`}
                          </TypographyWithTooltip>
                        ) : (
                          <TypographyWithTooltip ml={1} mt={0} maxLength={13} variant={'subtitle1'}>
                            {usecase.case_deliverymanager}
                          </TypographyWithTooltip>
                        )}
                      </Stack>
                    </Stack>
                  </Stack>
                  <Divider />
                  <UsecasesCardContent usecase={usecase} />
                </CognisaasCard>
              </Stack>
            );
          })}
        </>
      );
    } else {
      return (
        <Stack alignItems={'center'}>
          <Stack>
            <NoRecordsFound style={{ marginTop: '40%', width: '200px', height: '200px' }} />
          </Stack>
        </Stack>
      );
    }
  };

  return <>{renderItem()}</>;
};

export default UsecasesCard;
