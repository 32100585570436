import styled from 'styled-components';

export const Container = styled.div`
  width: 340px;
  height: 700px;
  border-radius: 4px;
  background-color: #ffffff;
  margin-right: 15px;
  font-family: 'Noto Sans';
`;

export const EmptyPhaseContainer = styled.div`
  width: 340px;
  height: 700px;
  font-family: 'Noto Sans';
  background-color: rgba(0, 0, 0, 0.01);
  border: 1px dashed #bababa;
  border-radius: 4px;
`;

export const TaskList = styled.div`
  width: 100%;
`;

export const TaskButton = styled.button`
  width: 92%;
  padding: 0px 10px 0px 10px;
  height: 44px;
  background-color: #fff;
  border: 1px solid #2d60f6;
  margin-left: 10px;
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-top: 10px;
  color: #2d60f6;
`;

interface TaskWrapperType {
  addScrollbar: boolean;
}

export const TaskWrapper = styled.div<TaskWrapperType>`
  max-height: 90%;
  overflow-y: auto;
  padding-top: 10px;
  overflow-x: hidden;
  scroll-behavior: smooth;
  scrollbar-width: none; /* Firefox */
  width: '100%';
  transition: 500ms linear scroll;
  -ms-overflow-style: none; /* IE 10+ */
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: none !important;
    background-color: transparent;
  }
  ::-webkit-scrollbar {
    width: ${(props) => (props.addScrollbar === true ? '6px !important' : '0px !important')};
    background-color: #fff;
  }
  ::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
`;
