import { Typography } from '@mui/material';
import { Box } from '@mui/system';
// import { ResponsivePie } from '@nivo/pie';
// import BarChart from './BarCharts/BarChart';
import BarChartWithTrendline from './BarCharts/BarChartWithTrendline';
import BubbleChart from './BubbleCharts/BubbleChart';
import data from './data.js';
// import DonutChart from './DonutCharts/DonutChart';

// bar graph things

const DemoIndex = () => {
  return (
    <Box>
      <Typography variant={'h1'}>All Posssible Graphs</Typography>

      <Typography sx={{ margin: '20px 0px' }} variant="subtitle1">
        Noramal Bar graph single and stacked just add multiple otherAxisKeys to have stacked chart and layout =
        &lsquo;horizonral&lsquo; and vertical
      </Typography>
      <Box sx={{ height: '400px' }}>
        {/* <ResponsiveBar data={data as unknown as BarDatum[]} keys={['client_account_worth']} indexBy="client_name" /> */}
        {/* <BarChart
          data={data}
          indexAxisKey="client_account_worth"
          otherAxisKeys={['client_account_worth', 'user_uid']}
          legend={'right'}
          axisLeftLegend={'UserUid'}
          axisBottomLegend={'Account Worth'}
          colors={['red', 'amber', 'green']}
        /> */}

        <Typography sx={{ margin: '20px 0px' }} variant="subtitle1">
          Trendline Bar chart
        </Typography>
        <BarChartWithTrendline
          data={data}
          indexAxisKey="client_account_worth"
          otherAxisKeys={['client_account_worth', 'user_uid']}
          legend={{ anchor: 'right', translateX: 120, translateY: 0, direction: 'column' }}
          axisLeftLegend={'UserUid'}
          axisBottomLegend={'Account Worth'}
        />
        <Typography sx={{ margin: '20px 0px' }} variant="subtitle1">
          Donut chart
        </Typography>
        {/* 
        <DonutChart
          height={400}
          width={500}
          data={data}
          dataConfig={{ idKey: 'client_name', dataKey: 'client_account_worth' }}
          colors={['red', 'green', 'blue']}
        /> */}

        <Typography sx={{ margin: '20px 0px' }} variant="subtitle1">
          Bubble chart
        </Typography>

        <BubbleChart height={400} width={500} data={data} />
      </Box>
    </Box>
  );
};

export default DemoIndex;
