import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../..';

export const selectOpenProjectsState = (state: RootState) => state.openProjectsState;
export const selectOpenProjects = createSelector(
    selectOpenProjectsState,
    (openProjectsState) => openProjectsState.openProjects
);
export const selectOpenProjectsStatus = createSelector(
    selectOpenProjectsState,
    (openProjectsState) => openProjectsState.status
);
export const selectOpenProjectsError = createSelector(
    selectOpenProjectsState,
    (openProjectsState) => openProjectsState.error
);
