import React from 'react';
import { ViewMode } from 'components/ResourceManagement/types/public-types';
import { Radio } from 'components/ResourceManagement/design-system/elements';
import { FlexBox, Label } from 'components/ResourceManagement/design-system/primitives';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { selectComponentState, setComponentState } from 'store/modules/ResourceManagement';
import styled from 'styled-components';
import moment from 'moment';
import { DATE_FORMAT } from 'components/ResourceManagement/utils/date';

const StyledLabel = styled(Label)`
    cursor: pointer;
    pointer-events: all;
`;

export const ProjectViewSwitcher: React.FC = () => {
    const dispatch = useAppDispatch();
    const selectedComponentState = useAppSelector(selectComponentState);
    const { viewMode: selectedViewType, selectDate } = selectedComponentState;
    const selectedRadioOption = selectedViewType;

    const radioGroupName = 'project-view-switcher';
    const radioDayId = 'day-project-view-switcher';
    const radioWeekId = 'week-project-view-switcher';
    const radioYearId = 'year-project-view-switcher';
    const currentDate = selectDate;

    return (
        <FlexBox variant='flexRow' alignItems='center' justifyContent='space-between'>
            <FlexBox variant='flexRow' alignItems='center' justifyContent='flex-start' px={3}>
                <Radio
                    id={radioYearId}
                    type='radio'
                    name={radioGroupName}
                    value={ViewMode.Month}
                    checked={selectedRadioOption === ViewMode.Month}
                    onChange={() => {
                        dispatch(setComponentState({
                            ...selectedComponentState,
                            viewMode: ViewMode.Month,
                            startDate: moment(currentDate).startOf('year').format(DATE_FORMAT),
                            selectDate: moment(currentDate).startOf('year').format(DATE_FORMAT),
                            endDate: moment(currentDate).endOf('year').format(DATE_FORMAT),
                        }));
                    }}
                />
                <StyledLabel htmlFor={radioYearId} variant='paragraph1'>Year</StyledLabel>
            </FlexBox>
            <FlexBox variant='flexRow' alignItems='center' justifyContent='flex-start' px={3}>
                <Radio
                    id={radioDayId}
                    type='radio'
                    name={radioGroupName}
                    value={ViewMode.Day}
                    checked={selectedRadioOption === ViewMode.Day}
                    onChange={() => {
                        dispatch(setComponentState({
                            ...selectedComponentState,
                            viewMode: ViewMode.Day,
                            startDate: moment(currentDate).startOf('month').format(DATE_FORMAT),
                            selectDate: moment(currentDate).startOf('month').format(DATE_FORMAT),
                            endDate: moment(currentDate).endOf('month').format(DATE_FORMAT),
                        }));
                    }}
                />
                <StyledLabel htmlFor={radioDayId} variant='paragraph1'>Month</StyledLabel>
            </FlexBox>
            <FlexBox variant='flexRow' alignItems='center' justifyContent='flex-start' px={3}>
                <Radio
                    id={radioWeekId}
                    type='radio'
                    name={radioGroupName}
                    value={ViewMode.Week}
                    checked={selectedRadioOption === ViewMode.Week}
                    onChange={() => {
                        dispatch(setComponentState({
                            ...selectedComponentState,
                            viewMode: ViewMode.Week,
                            startDate: moment(currentDate).startOf('month').format(DATE_FORMAT),
                            selectDate: moment(currentDate).startOf('month').format(DATE_FORMAT),
                            endDate: moment(currentDate).endOf('month').format(DATE_FORMAT),
                        }));
                    }}
                />
                <StyledLabel htmlFor={radioWeekId} variant='paragraph1'>Week</StyledLabel>
            </FlexBox>
        </FlexBox>
    );
};
