import { MenuItem, Select } from '@mui/material';
import { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { FlexBox, GridBox, Label, Text } from '../primitives';

export function FormSelect({
  id = '',
  name = '',
  customLabel = '',
  defaultOptions = [],
  targetIdField = 'id',
  targetNameField = 'name',
  size = 'small',
  control,
  selectedItem,
  formState: { errors },
  resetField,
  setValue,
  isDisabled
}: any) {

  useEffect(() => {
    setValue(name, selectedItem);
    return () => resetField(name);
  }, []);

  const errName = errors?.[name];
  const errType = errName?.type;
  const errMessage = errName?.message;
  const isError = !!(errType && errMessage);

  return (
    <GridBox
      gridTemplateRows='30px auto 20px'
      gridTemplateColumns='1fr'
      justifyContent='flex-start'
      alignItems='center'
    >
      {customLabel && (
        <Label
          id={id}
          variant='heading3'
          htmlFor={name}
        >
          {customLabel}
        </Label>
      )}
      <Controller
        name={name}
        control={control}
        render={({
          field: { onChange, onBlur, value, name, ref },
        }) => {
          return (
            <Select
              id={id}
              key={id}
              name={name}
              value={value}
              label={customLabel}
              size={size}
              onChange={onChange}
              onBlur={onBlur}
              inputRef={ref}
              disabled={isDisabled}
              notched={false}
            >
              {defaultOptions.map((option: any) => {
                return (
                  <MenuItem key={option[targetIdField]} value={option[targetIdField]}>
                    <Text variant='paragraph1'>
                      {option[targetNameField]}
                    </Text>
                  </MenuItem>
                );
              })}
            </Select>
          );
        }}
      />
      {isError ? (
        <FlexBox pt={3}>
          <Text variant='paragraph2' color='red'>{errors[name].message}</Text>
        </FlexBox>
      ) : null}
    </GridBox >
  );
}
