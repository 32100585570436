import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { Stack, Typography } from '@mui/material';

interface props {
  value: number;
  statusColor: string;
  hidePercentage: boolean;
}

export const CustomizedProgressBars = (props: props) => {
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: progressColor,
    },
  }));

  // const [value, setValue] = React.useState(90);
  const [progressColor, setProgressColor] = React.useState('green');
  const [hidePercentangeValue, setHidePercentageValue] = React.useState(false);
  useEffect(() => {
    // if (props.value) setValue(props.value);
    if (props.statusColor) {
      console.log(props.statusColor, 'color');
      if (props.statusColor === 'red') setProgressColor('#E75B5C');
    }
    if (props.statusColor === 'amber') setProgressColor('#EFA958');
    if (props.statusColor === 'green') setProgressColor('#4F9F52');
    if (props.statusColor === 'blue') setProgressColor('#4390E1');
    if (props.hidePercentage === true) setHidePercentageValue(true);
  }, []);

  return hidePercentangeValue === true ? (
    <Stack direction="row" sx={{ flexGrow: 1 }}>
      <BorderLinearProgress variant="determinate" value={props.value} sx={{ width: '100%', marginTop: '5px' }} />
      {/* <Box>
        <Typography sx={{ marginLeft: '10px' }} variant="body2" color="text.secondary">{`${Math.round(
          value
        )}%`}</Typography>
      </Box> */}
    </Stack>
  ) : (
    <Stack direction="row" sx={{ flexGrow: 1 }}>
      <BorderLinearProgress variant="determinate" value={props.value} sx={{ width: '90%', marginTop: '5px' }} />
      <Box>
        <Typography sx={{ marginLeft: '10px' }} variant="body2" color="text.secondary">{`${Math.trunc(
          props.value
        )}%`}</Typography>
      </Box>
    </Stack>
  );
};
