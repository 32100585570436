import { Link, useLocation } from 'react-router-dom';

import Box from '@mui/material/Box';
import { Typography, Stack, Avatar } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import {
  DataGridPremium,
  gridClasses,
  GridColumnOrderChangeParams,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridToolbar,
} from '@mui/x-data-grid-premium';
import '../../ClientModule/ClientContacts/ContactsTable.css';
import { CustomizedProgressBars } from '../../shared/CognisaasProgressBar/ProgressBar';
import { getUserLogoLinkByUid, getUserUid } from 'utils/getUid';
import { default as AvatarImage } from '../../shared/Avatar/Avatar';
import ViewDate, { ViewDateTooltip } from 'components/shared/ViewDate/ViewDate';
import {
  label_arr,
  label_client,
  label_delivery_date,
  label_due_date,
  label_project,
  label_project_delivery_manager,
  label_use_case,
} from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { useEffect, useState } from 'react';
import { generateTableColumnsWithCustomFiltersMapped } from 'components/shared/Tables/CogniTable';
import { selectProjectCustomFieldsDetail, selectProjectCustomFieldsDetailStatus } from 'store/modules/Project/selector';
import { useAppSelector } from 'store/hooks';
import { requestState } from 'store/modules/types';
import { inlineEditPropType } from 'components/shared/Tables/types';
import {
  getAllProjectCustomFields,
  getProjectCustomFieldsDetail,
  handleProjectPlannedDateUpdate,
  updateProject,
  updateProjectCustomField,
} from 'store/modules/Project/slice';
import { useDispatch } from 'react-redux';
import { SelectEditDateInputCell, SelectEditDropdownCell } from 'components/shared/Tables/InlineEditTable';
import { selectUsersOfCompany, selectCustomerDetail } from 'store/modules/Common/selector';
import { getAllUsers } from 'store/modules/Common/slice';
import { formatCurrency, generateOrderedColumn, isNull, isUndefined } from 'utils/dataHelpers';
import CognisaaasTooltip from 'components/shared/CognisaasTooltip/CognisaasTooltip';
import { formatDistance } from 'date-fns/esm';
import {
  getDaysRemainingHeader,
  getProjectAgeingHeader,
  getProjectDaysRemainingWithColorCell,
  formatProjectDaysRemainingField,
} from 'components/shared/Tables/TableCells';
import useCustomNames from 'components/shared/CustomNames/useCustomNames';
import {
  fetchClientList,
  resetClientListState,
  updatedcasewithfeaturestatusClient,
} from 'store/modules/ManageClient/ClientList/slice';
import NAMED_CONSTATNTS from 'components/shared/CustomNames/Constants';
import { selectClientList } from 'store/modules/ManageClient/ClientList/selector';
import checkAccessForRouting from 'components/shared/Hooks/checkAccessForRouting';
import useCommonAppConfig from 'components/Common/useCommonAppConfig';
import { getUserActiveState, INACTIVE_USER_COLOR } from 'utils/userHelpers';
import { checkFreezePlannedDate } from 'utils/checkFreezePlannedDate';
import { getMaxColumnWidth } from 'utils/getMaxColumnWidth';
import { getCustomerDetail } from 'store/modules/Common/slice';
import { format } from 'date-fns';
import { makeDate } from '../utils';
import { differenceInDays } from 'date-fns';

const ODD_OPACITY = 0.2;

function stringToHslColor(str: string, s: number, l: number) {
  let hash = 0;

  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const h = hash % 360;
  return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToHslColor(name, 30, 80),
    },
    children: (
      <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#000000' }}>
        {name.split(' ')[0][0]}
        {name.indexOf(' ') >= 0 ? name.split(' ')[1][0] : null}
      </Typography>
    ),
  };
}

const StripedDataGrid = styled(DataGridPremium)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
        },
      },
    },
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: 600,
  borderRadius: '4px',
  boxShadow: '0px 4px 26px rgba(0, 0, 0, 0.05)',
  width: '100%',
  '& .MuiFormGroup-options': {
    alignItems: 'center',
    paddingBottom: theme.spacing(1),
    '& > div': {
      minWidth: 100,
      margin: theme.spacing(2),
      marginLeft: 0,
    },
  },
}));

const projectTable = (props: any) => {
  const [tableColumns, setTableColumns] = useState<any[]>([]);
  const location = useLocation();
  const projectCustomFields = useAppSelector(selectProjectCustomFieldsDetail);
  // const [pageSize, setPageSize] = useState<number>(20);
  const projectCustomFieldsStatus = useAppSelector(selectProjectCustomFieldsDetailStatus);
  const customerDetail = useAppSelector(selectCustomerDetail);
  const clientList = useAppSelector(selectClientList);
  const users = useAppSelector(selectUsersOfCompany);
  const {
    displayDataWithCustomNames,
    projectStagesDropdownValues,
    getStandardNameMappedValue,
    projectPrioritiesDropdownValues,
  } = useCustomNames();
  const customProjectStages = projectStagesDropdownValues.map((item: any) => item.custom_stage_name);
  const [usersOfCompany, setUsersOfCompany] = useState<any[]>([]);
  const projectPriorities = projectPrioritiesDropdownValues.map((item: any) => item.custom_priority_name);

  console.log({ customerDetail });

  console.log({ customerDetail });

  // const [filterModel, setFilterModel] = useLocalStorage<GridFilterModel>('clientFilters', {
  //   items: [],
  // } as GridFilterModel);

  const uniqueProjectColumnId = 'projectListTable';

  let myTableState: any = {};
  const { saveAppTableState, getTableState } = useCommonAppConfig();
  let initialState: any = {};

  if (!isNull(location.state)) {
    initialState = {
      columns: {
        columnVisibilityModel: {
          project_uid: false,
          implementation_fee_billed: false,
          implementation_fee_pending: false,
        },
      },
      pinnedColumns: {
        left: ['project_name'],
      },
      pagination: {
        page: 0,
        pageSize: 20,
      },
      filter: {
        filterModel: {
          items: [],
        },
      },
    };
    console.log('aman jain', location);

    saveAppTableState('projectListTablePage', initialState);
  } else {
    initialState = {
      columns: {
        columnVisibilityModel: {
          project_uid: false,
          implementation_fee_billed: false,
          implementation_fee_pending: false,
        },
      },
      pinnedColumns: {
        left: ['project_name'],
      },
      pagination: {
        page: 0,
        pageSize: 20,
      },
      ...getTableState('projectListTablePage'),
    };
  }

  const dispatch = useDispatch();

  const openProject = (data: any) => {
    return `project/${data.association_uid}/${data.project_uid}`;
  };
  const { getStandardRowDataWithoutCustomNames } = useCustomNames();

  const generateCommentUi = (comment: string) => {
    const splitForUsers = comment.split('@[');
    return splitForUsers.map((item, index) => {
      if (index === 0) {
        return <span key={index}>{item}</span>;
      } else {
        // index more than 0 something like this expected => vivek](customer:324234) great
        const strContainingUser = item;
        const arrContainingUser = strContainingUser.split('](');
        const username = arrContainingUser[0];
        const restStrArr = arrContainingUser[1].split(')');
        const customerUid = restStrArr[0];
        const restString = [...restStrArr.filter((item, index) => index > 0)].join('');
        return (
          <span key={index}>
            &nbsp;
            <span
              style={{
                backgroundColor: 'rgb(45, 96, 246, 0.1)',
                color: '#2D60F6',
                padding: '2px 10px',
                borderRadius: '33px',
                fontSize: '13px',
                cursor: 'pointer',
              }}
              id={customerUid}
            >
              {username}
            </span>
            {restString}
          </span>
        );
      }
    });
  };

  const generateCommentText = (comment: string): string => {
    const splitForUsers = comment.split('@[');
    const finalCommentStrArr = splitForUsers.map((item, index) => {
      if (index === 0) {
        return item;
      } else {
        const strContainingUser = item;
        const arrContainingUser = strContainingUser.split('](');
        const username = arrContainingUser[0];
        const restStrArr = arrContainingUser[1].split(')');
        const restString = [...restStrArr.filter((item, index) => index > 0)].join('');
        return `${username} ${restString}`;
      }
    });
    return finalCommentStrArr.join(' ');
  };

  useEffect(() => {
    dispatch(getCustomerDetail());
    dispatch(getAllUsers());
    dispatch(getProjectCustomFieldsDetail());
    dispatch(resetClientListState());
    dispatch(fetchClientList());
  }, []);

  useEffect(() => {
    if (users.length > 0) {
      setUsersData();
    }
  }, [users]);

  const setUsersData = () => {
    const data = users.filter((user: any) => user.is_active === '1').map((user: any) => user.user_username);
    data.unshift('Not Assigned');
    setUsersOfCompany([...data]);
  };

  const handleClientAccess = (params: GridRenderCellParams) => {
    const arrayToCheck = clientList.map((ele) => ele.client_uid);
    const hasAccessToClient = checkAccessForRouting({ arrayToCheck: arrayToCheck, uid: params.row.client_uid });
    if (hasAccessToClient == true) {
      return (
        <Link state={{ clientName: params.row.client_name }} to={`/client-list/client/${params.row.client_uid}`}>
          {params.value}
        </Link>
      );
    }
    if (hasAccessToClient == false) {
      return (
        <CognisaaasTooltip
          title={<Typography sx={{ padding: '5px' }}>You do not have access to this {label_client}</Typography>}
        >
          <Typography variant="subtitle1">{params.value}</Typography>
        </CognisaaasTooltip>
      );
    }
  };

  const columns: any = [
    {
      field: 'project_name',
      headerName: `${label_project} Name`,
      width: 240,
      renderCell: (params: GridRenderCellParams) => (
        <Link
          state={{ clientName: params.row.client_name, projecName: params.row.project_name }}
          to={openProject(params.row)}
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: 'client_name',
      headerName: `${label_client} Name`,
      width: 240,
      renderCell: (params: GridRenderCellParams) => handleClientAccess(params),
    },
    {
      field: 'project_age',
      renderHeader: () => getProjectAgeingHeader(),
      width: 240,
      renderCell: (params: GridRenderCellParams) => (
        <Stack style={{ padding: '7px 0', fontSize: '14px' }}>
          <Stack spacing={1} direction="row">
            {params.value ? `${params.value} Days` : 'Not Started'}
          </Stack>
        </Stack>
      ),
    },
    {
      field: 'project_days_remaining',
      renderHeader: () => getDaysRemainingHeader(),
      width: 240,
      renderCell: (params: GridRenderCellParams) => getProjectDaysRemainingWithColorCell(params),
    },
    {
      field: 'project_progress',
      headerName: 'Progress (in %)',
      width: 220,
      renderCell: (params: GridRenderCellParams) => (
        <CustomizedProgressBars value={params.value} statusColor={'grees'} hidePercentage={false} />
      ),
    },
    {
      headerName: 'Last updated',
      field: 'project_modified_on',
      editable: false,
      width: 200,
      type: 'date',
      // valueGetter: ({ value }: any) => value && new Date(value),
      renderCell: (params: GridRenderCellParams) => {
        return <ViewDate date={params.row.project_modified_on} />;
      },
    },
    {
      headerName: 'Last status email sent',
      field: 'project_last_status_report_sent_time',
      editable: false,
      width: 200,
      type: 'date',
      // valueGetter: ({ value }: any) => value && new Date(value),
      renderCell: (params: GridRenderCellParams) => {
        return <ViewDate date={params.row.project_last_status_report_sent_time} />;
      },
    },
    {
      field: 'project_planned_startdate',
      headerName: 'Planned Start Date',
      width: 180,
      editable: true,
      type: 'date',
      // valueGetter: ({ value }: any) => value && new Date(value),
      renderCell: (params: GridRenderCellParams) => {
        return checkFreezePlannedDate(
          customerDetail?.freeze_planned_date_project == '1',
          params.row?.is_project_started
        ) ? (
          <ViewDateTooltip
            date={params.row?.project_planned_startdate}
            text={`Planned start date cannot be changed once the ${label_project.toLowerCase()} has been started. Contact an admin if you want to change the planned start date.`}
          />
        ) : (
          <ViewDate date={params.row?.project_planned_startdate} />
        );
      },
      renderEditCell: (params: GridRenderEditCellParams) =>
        !checkFreezePlannedDate(customerDetail?.freeze_planned_date_project == '1', params.row?.is_project_started) ? (
          SelectEditDateInputCell(params, handleInlineEditValue)
        ) : (
          <ViewDate date={params.row.project_planned_startdate} />
        ),
    },
    {
      field: 'project_startdate',
      headerName: 'Start Date',
      width: 150,
      editable: true,
      type: 'date',
      // valueGetter: ({ value }: any) => value && new Date(value),
      renderCell: (params: GridRenderCellParams) => {
        return <ViewDate date={params.row.project_startdate} />;
      },
      renderEditCell: (params: GridRenderEditCellParams) => SelectEditDateInputCell(params, handleInlineEditValue),
    },
    {
      field: 'eta_forprojectdelivery',
      headerName: 'Estimated Delivery Date',
      width: 180,
      editable: true,
      type: 'date',
      // valueGetter: ({ value }: any) => value && new Date(value),
      renderCell: (params: GridRenderCellParams) => {
        return <ViewDate date={params.row.eta_forprojectdelivery} />;
      },
      renderEditCell: (params: GridRenderEditCellParams) => SelectEditDateInputCell(params, handleInlineEditValue),
    },
    {
      field: 'project_requiredbydate',
      headerName: label_due_date,
      width: 180,
      editable: true,
      type: 'date',
      // valueGetter: ({ value }: any) => value && new Date(value),
      renderCell: (params: GridRenderCellParams) => {
        return checkFreezePlannedDate(
          customerDetail?.freeze_planned_date_project == '1',
          params.row?.is_project_started
        ) ? (
          <ViewDateTooltip
            date={params.row?.project_requiredbydate}
            text={`${label_due_date} cannot be changed once the ${label_project.toLowerCase()} has been started. Contact an admin if
            you want to change the ${label_due_date.toLowerCase()}.`}
          />
        ) : (
          <ViewDate date={params.row?.project_requiredbydate} />
        );
      },
      renderEditCell: (params: GridRenderEditCellParams) =>
        !checkFreezePlannedDate(customerDetail?.freeze_planned_date_project == '1', params.row?.is_project_started) ? (
          SelectEditDateInputCell(params, handleInlineEditValue)
        ) : (
          <ViewDate date={params.row?.project_requiredbydate} />
        ),
    },
    {
      field: 'project_deliverydate',
      headerName: label_delivery_date,
      width: 180,
      editable: false,
      type: 'date',
      // valueGetter: ({ value }: any) => value && new Date(value),
      renderCell: (params: GridRenderCellParams) => {
        return <ViewDate date={params.row.project_deliverydate} />;
      },
      renderEditCell: (params: GridRenderEditCellParams) => SelectEditDateInputCell(params, handleInlineEditValue),
    },
    {
      field: 'project_readinessstate_status',
      headerName: 'Status',
      width: 150,
      renderCell: (params: GridRenderCellParams) => (
        <Stack style={{ padding: '7px 0' }}>
          <Stack spacing={1} direction="row">
            <span
              style={{ marginRight: '4px' }}
              className={
                params.value == 'grey'
                  ? 'grey_status_box_task'
                  : params.value == 'red'
                  ? 'red_status_box_task'
                  : params.value == 'amber'
                  ? 'amber_status_box_task'
                  : params.value == 'green'
                  ? 'green_status_box_task'
                  : params.value == 'blue'
                  ? 'blue_status_box_task'
                  : ''
              }
            ></span>
            {params.value == 'grey'
              ? 'On Hold'
              : params.value == 'red'
              ? 'Overdue'
              : params.value == 'amber'
              ? 'At Risk'
              : params.value == 'green'
              ? 'On Track'
              : params.value == 'blue'
              ? 'Completed'
              : ''}
          </Stack>
        </Stack>
      ),
      // renderCell: (params: GridRenderCellParams) => <Typography variant="subtitle1">{params.value}</Typography>,
    },
    {
      field: 'total_usecase',
      headerName: `Total ${label_use_case}s `,
      width: 150,
      renderCell: (params: GridRenderCellParams) => <Typography variant="subtitle1">{params.value}</Typography>,
    },
    {
      field: 'red_usecase',
      renderHeader: () => (
        <>
          <span style={{ marginRight: '4px' }} className={'red_status_box_task'}></span>
          {`Overdue ${label_use_case}s `}
        </>
      ),
      width: 200,
      renderCell: (params: GridRenderCellParams) => (
        <Stack style={{ padding: '7px 0' }}>
          <Stack spacing={1} direction="row">
            {params.value}
          </Stack>
        </Stack>
      ),
    },
    {
      field: 'amber_usecase',
      renderHeader: () => (
        <>
          <span style={{ marginRight: '4px' }} className={'amber_status_box_task'}></span>
          {`At Risk ${label_use_case}s `}
        </>
      ),
      width: 200,
      renderCell: (params: GridRenderCellParams) => (
        <Stack style={{ padding: '7px 0' }}>
          <Stack spacing={1} direction="row">
            {params.value}
          </Stack>
        </Stack>
      ),
    },
    {
      field: 'green_usecase',
      renderHeader: () => (
        <>
          <span style={{ marginRight: '4px' }} className={'green_status_box_task'}></span>
          {`On Track ${label_use_case}s `}
        </>
      ),
      width: 200,
      renderCell: (params: GridRenderCellParams) => (
        <Stack style={{ padding: '7px 0' }}>
          <Stack spacing={1} direction="row">
            {params.value}
          </Stack>
        </Stack>
      ),
    },
    {
      field: 'blue_usecase',
      renderHeader: () => (
        <>
          <span style={{ marginRight: '4px' }} className={'blue_status_box_task'}></span>
          {`Completed ${label_use_case}s `}
        </>
      ),
      width: 210,
      renderCell: (params: GridRenderCellParams) => (
        <Stack style={{ padding: '7px 0' }}>
          <Stack spacing={1} direction="row">
            {params.value}
          </Stack>
        </Stack>
      ),
    },
    {
      headerName: `${label_project} ${label_arr}`,
      width: 200,
      field: 'project_ARR',
      renderCell: (params: any) => {
        if (params?.row[params.field].length > 0) {
          return formatCurrency(params.row[params.field], customerDetail?.currency_code);
        } else {
          return <>-</>;
        }
      },
    },
    {
      headerName: 'Implementation fee',
      width: 200,
      field: 'project_fees',
      renderCell: (params: any) => {
        if (isNull(params?.row[params.field])) {
          return formatCurrency(0, customerDetail?.currency_code);
        } else if (!isNull(params?.row[params.field]) && params?.row[params.field].length > 0) {
          return formatCurrency(params.row[params.field], customerDetail?.currency_code);
        } else {
          return <>-</>;
        }
      },
    },
    {
      headerName: 'Implementation fee billed',
      width: 200,
      field: 'implementation_fee_billed',
      renderCell: (params: any) => {
        if (isNull(params.row[params.field])) {
          return formatCurrency(0, customerDetail?.currency_code);
        } else if (
          !isNull(params?.row[params.field]) &&
          params?.row[params.field].length > 0 &&
          !isNaN(params?.row[params.field])
        ) {
          return formatCurrency(parseInt(params?.row[params.field]), customerDetail?.currency_code);
        } else {
          return <>-</>;
        }
      },
    },
    {
      headerName: 'Implementation fee pending',
      width: 200,
      field: 'implementation_fee_pending',
      renderCell: (params: any) => {
        if (isNull(params.row['implementation_fee_billed'])) {
          const totalFee = isNull(params.row['project_fees']) ? 0 : params.row['project_fees'];
          return formatCurrency(totalFee, customerDetail?.currency_code);
        } else if (
          !isNull(params?.row[params.field]) &&
          params?.row[params.field].length > 0 &&
          !isNaN(params?.row[params.field])
        ) {
          const implemetationFeePending = params?.row[params.field];
          return formatCurrency(implemetationFeePending, customerDetail?.currency_code);
        } else {
          return <>-</>;
        }
      },
    },
    {
      headerName: 'Description',
      field: 'project_description',
      width: 240,
    },
    {
      headerName: 'Stage',
      field: 'project_stage',
      width: getMaxColumnWidth(customProjectStages),
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams) =>
        SelectEditDropdownCell(params, customProjectStages || [], handleInlineEditValue),
    },
    {
      headerName: 'Category',
      field: 'project_needed_status',
      width: 240,
      // editable: true,
      // renderEditCell: (params: GridRenderEditCellParams) =>
      //   SelectEditDropdownCell(params, customProjectStages || [], handleInlineEditValue),
    },
    {
      headerName: 'Priority',
      field: 'project_priority',
      width: 240,
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams) =>
        SelectEditDropdownCell(params, projectPriorities || [], handleInlineEditValue),
    },
    {
      field: 'project_deliverymanager',
      headerName: label_project_delivery_manager,
      width: getMaxColumnWidth(usersOfCompany),
      editable: true,
      renderCell: (params: GridRenderCellParams) => {
        const userUid = getUserUid(params.row.project_deliverymanager);
        const deliveryManagerProfileLink = getUserLogoLinkByUid(userUid);
        return (
          <Stack direction="row">
            {deliveryManagerProfileLink.length > 0 ? (
              <AvatarImage src={deliveryManagerProfileLink} height="34px" width="34px" alt="Delivery manager profile" />
            ) : (
              <Avatar style={{ height: '34px', width: '34px', marginTop: '3px' }} {...stringAvatar(params.value)} />
            )}

            {getUserActiveState(getUserUid(params.value), users) === '0' ? (
              <Typography style={{ color: INACTIVE_USER_COLOR }} m={1} variant="subtitle1">
                {params.value}&nbsp;(Inactive)
              </Typography>
            ) : (
              <Typography m={1} variant="subtitle1">
                {params.value}
              </Typography>
            )}
          </Stack>
        );
      },
      renderEditCell: (params: GridRenderEditCellParams) =>
        SelectEditDropdownCell(params, usersOfCompany || [], handleInlineEditValue),
    },
    {
      field: 'recent_comment',
      headerName: 'Comment',
      width: 240,
      renderCell: (params: GridRenderCellParams) => (
        <>
          {isUndefined(params.row.recent_comment.comment) ? (
            ''
          ) : (
            <CognisaaasTooltip
              title={
                <Stack sx={{ padding: '16px', width: '240px', maxHeight: 200, overflow: 'scroll' }}>
                  <Stack>
                    <Stack direction={'row'} justifyContent={'space-between'}>
                      {/* user avatar and name */}
                      <Stack direction={'row'} alignItems={'center'}>
                        {getUserLogoLinkByUid(params.row.recent_comment.user_uid).length > 0 ? (
                          <AvatarImage
                            src={getUserLogoLinkByUid(params.row.recent_comment.user_uid)}
                            alt="user profile"
                          />
                        ) : (
                          <Avatar
                            style={{ height: '40px', width: '40px', marginTop: '3px' }}
                            {...stringAvatar(params.row.recent_comment.user_username)}
                          />
                        )}
                        <Typography ml={1} variant={'h3'}>
                          {params.row.recent_comment.user_username}
                        </Typography>
                      </Stack>

                      <Typography className="label-text" variant="subtitle1" style={{ fontSize: '12px' }}>
                        {formatDistance(new Date(params.row.recent_comment.created_on.replace(/-/g, '/')), new Date(), {
                          includeSeconds: true,
                        })}
                        <br />
                        ago
                      </Typography>
                    </Stack>
                    <Typography mt={1} variant={'subtitle1'} sx={{ whiteSpace: 'pre-line' }}>
                      {generateCommentUi(params.row.recent_comment.comment)}
                    </Typography>
                  </Stack>
                </Stack>
              }
            >
              <Typography variant={'subtitle1'} className="aman">
                {generateCommentText(params.row.recent_comment.comment).length > 30
                  ? generateCommentText(params.row.recent_comment.comment).slice(0, 30).trim() + '...'
                  : generateCommentText(params.row.recent_comment.comment)}
              </Typography>
            </CognisaaasTooltip>
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    if (props.data?.length > 0) {
      if (projectCustomFieldsStatus === requestState.success && projectCustomFields.length > 0 && users.length > 0) {
        setTableColumns(
          generateOrderedColumn(
            [
              ...generateTableColumnsWithCustomFiltersMapped(
                props.data[0],
                columns,
                'Project',
                projectCustomFields,
                handleInlineEditCustomValue,
                users
              ),
            ],
            uniqueProjectColumnId
          )
        );
      } else {
        setTableColumns(columns);
      }
    }
  }, [projectCustomFields, users]);

  const handleInlineEditValue = async (
    params: GridRenderEditCellParams,
    editableField: string,
    editableValue: string
  ) => {
    const newRow: any = {
      ...getStandardRowDataWithoutCustomNames({ ...params.row }),
      project_startdate: format(new Date(params.row.project_startdate), 'yyyy-MM-dd HH:mm:ss'),
      project_planned_startdate: format(new Date(params.row.project_planned_startdate), 'yyyy-MM-dd HH:mm:ss'),
      project_deliverydate: format(new Date(params.row.project_deliverydate), 'yyyy-MM-dd HH:mm:ss'),
      eta_forprojectdelivery: format(new Date(params.row.eta_forprojectdelivery), 'yyyy-MM-dd HH:mm:ss'),
      project_requiredbydate: format(new Date(params.row.project_requiredbydate), 'yyyy-MM-dd HH:mm:ss'),
    };
    let newEditableValue = editableValue;
    if (editableField === NAMED_CONSTATNTS.project_category_key) {
      newEditableValue = getStandardNameMappedValue(editableValue, NAMED_CONSTATNTS.project_category_key);
    } else if (editableField === NAMED_CONSTATNTS.project_stage_key) {
      newEditableValue = getStandardNameMappedValue(editableValue, NAMED_CONSTATNTS.project_stage_key);
    }
    await dispatch(
      updateProject({
        ...newRow,
        [editableField]: newEditableValue,
        user_uid: editableField === 'project_deliverymanager' ? getUserUid(newEditableValue) : params.row.user_uid,
      })
    );

    if (editableField === 'project_stage') {
      await dispatch(updatedcasewithfeaturestatusClient(params.row.client_uid));
    }
    if (editableField == 'project_planned_startdate') {
      const proj = props.data.filter((data: any) => data.project_uid == params.row.project_uid);
      if (
        makeDate(proj[0].project_planned_startdate).getTime() !=
        new Date(convert(params.row.project_planned_startdate)).getTime()
      ) {
        const diff = differenceInDays(
          new Date(convert(params.row.project_planned_startdate)),
          makeDate(proj[0].project_planned_startdate)
        );
        const detail = {
          project_uid: params.row.project_uid,
          shifted_by: diff,
          updated_date: makeDate(params.row.project_planned_startdate),
          entity: 'PROJECT',
        };
        await dispatch(handleProjectPlannedDateUpdate(detail));
      }
    }
  };

  const convert = (str: any) => {
    const date = new Date(str),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join('-');
  };

  const handleInlineEditCustomValue = async (
    params: GridRenderEditCellParams,
    editableField: string,
    editableValue: string,
    type?: inlineEditPropType
  ) => {
    const index = params.indexofCustomField;
    const customFieldData = params.row.custom_values[index];
    if (type === 'Project') {
      await dispatch(updateProjectCustomField({ ...customFieldData, field_value: editableValue }));
      await dispatch(getAllProjectCustomFields());
    }
  };

  const handleColumnOrderChange = (params: GridColumnOrderChangeParams) => {
    const oldArrayIndex = params.oldIndex;
    const targetArrayIndex = params.targetIndex;
    setTableColumns(generateOrderedColumn(tableColumns, uniqueProjectColumnId, oldArrayIndex, targetArrayIndex));
  };

  const handleTableSave = (idName: string) => {
    saveAppTableState(idName, myTableState);
  };

  return (
    <>
      {tableColumns.length > 0 && (
        <StyledBox>
          <StripedDataGrid
            components={{
              Toolbar: GridToolbar,
            }}
            componentsProps={{
              toolbar: { showQuickFilter: true },
            }}
            columns={tableColumns}
            initialState={initialState}
            onStateChange={(tblState: any) => {
              myTableState = tblState;
            }}
            onColumnVisibilityModelChange={() => {
              handleTableSave('projectListTablePage');
            }}
            onPageSizeChange={() => {
              handleTableSave('projectListTablePage');
            }}
            rows={displayDataWithCustomNames(formatProjectDaysRemainingField(props.data))}
            loading={false}
            getRowId={(row) => row.project_uid}
            disableSelectionOnClick
            rowThreshold={0}
            // pageSize={pageSize}
            // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            pagination
            getRowHeight={() => 'auto'}
            sx={{
              '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
              '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '7px' },
              '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
            }}
            getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'odd' : 'odd')}
            // filterModel={filterModel}
            onFilterModelChange={() => {
              handleTableSave('projectListTablePage');
              // setFilterModel(newFilterModel);
            }}
            experimentalFeatures={{ newEditingApi: true }}
            onColumnOrderChange={handleColumnOrderChange}
          />
        </StyledBox>
      )}
    </>
  );
};

export default projectTable;
