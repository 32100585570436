import { Box, Paper, Typography, Grid, IconButton, Stack, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { templateHomeTabs, toUpdateProjectTemplate, toUpdateUsecaseTemplate } from '../../types';
import { useNavigate } from 'react-router';
import BasicModal from 'components/shared/CognisaasModal/CognisaasModal';
import DocumentTemplate from '../../../DocumentTemplate/DocumentTemplate';
import CreateDocumentTemplate from '../../../DocumentTemplate/CreateDocumentTemplate';
import { useState, useEffect } from 'react';
import {
  getProjectTemplates,
  deleteTemplateUsecasebyuid,
  getUseCaseTemplatesbycustomer,
  deleteTemplateProjectbyuid,
  cloneProjectTemplate,
  cloneUsecaseTemplate,
  closeNewDocumentTemplateModal,
} from '../../../../../store/modules/Templates/Templates';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { selectNewDocumentModalStatus } from '../../../../../store/modules/Templates/selector';
import DeleteModal from 'components/shared/CognisaasModal/DeleteModal';
import { label_project, label_use_case } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import CognisaasToast from 'components/shared/CognisaasToast/CognisaasToast';

interface props {
  templates: any[];
  templateType: string;
}

const TemplateNameCard = (props: props) => {
  console.log(props);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [selectedMenuItemTemplateUid, setSelectedMenuItemTemplateUid] = useState('');
  const [selectedMenuItemTemplateType, setSelectedMenuItemTemplateType] = useState('');
  const documentModalStatus = useAppSelector(selectNewDocumentModalStatus);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [alertDetails, setAlertDetails] = useState<{
    isVisible: boolean;
    type: 'success' | 'failure';
    message: string;
  }>({ isVisible: false, type: 'success', message: '' });

  const [selectedMenuItem, setSelectedMenuItem] = useState('');
  const options = ['Clone Template', 'Delete Template'];

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>, templateUid: string, type: string) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setSelectedMenuItemTemplateUid(templateUid);
    setSelectedMenuItemTemplateType(type);
    console.log(selectedMenuItem);

    console.log(templateUid, 'temp udud');
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    console.log(selectedMenuItem);
    // takeActionForSelectedItem();
  }, [selectedMenuItem]);

  const takeActionForSelectedItem = async (option: string) => {
    console.log(option, selectedMenuItemTemplateType);
    if (selectedMenuItemTemplateType == 'project') {
      if (option === 'Delete Template') {
        setOpenDeleteModal(true);
      }
      if (option === 'Clone Template') {
        await dispatch(cloneProjectTemplate({ template_project_uid: selectedMenuItemTemplateUid }));
        await dispatch(getProjectTemplates());
      }
      handleClose();
    } else {
      if (option === 'Delete Template') {
        setOpenDeleteModal(true);
      }
      if (option === 'Clone Template') {
        await dispatch(cloneUsecaseTemplate({ template_case_uid: selectedMenuItemTemplateUid }));
        await dispatch(getUseCaseTemplatesbycustomer());
      }
      handleClose();
    }
    setSelectedMenuItem('');
  };

  const deleteSelectedProjectTemplate = async () => {
    await dispatch(deleteTemplateProjectbyuid(selectedMenuItemTemplateUid));
    setSelectedMenuItem('');
    await dispatch(getProjectTemplates());
  };

  const deleteSelectedUsecaseTemplate = async () => {
    await dispatch(deleteTemplateUsecasebyuid(selectedMenuItemTemplateUid));
    setSelectedMenuItem('');
    await dispatch(getUseCaseTemplatesbycustomer());
  };

  if (props.templateType === templateHomeTabs[0]) {
    return (
      <Box ml={3} key={'1'} sx={{ marginTop: '10px', marginRight: '10px' }}>
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          {options.map((option) => (
            <MenuItem
              key={option}
              onClick={() => {
                takeActionForSelectedItem(option);
              }}
            >
              {option}
            </MenuItem>
          ))}
        </Menu>
        <Grid container direction="row">
          {props.templates.map((template: any, index: number) => {
            return (
              <Stack key={index} sx={{ margin: '8px 16px 8px 0px' }} spacing={3}>
                <Paper
                  onClick={() =>
                    navigate(toUpdateProjectTemplate, {
                      state: {
                        projectUid: template.template_project_uid,
                        userPermissions: [...template.__user_permissions],
                      },
                    })
                  }
                  sx={{ width: '310px', height: '68px' }}
                >
                  <Grid
                    container
                    // justifyContent="space-between"
                    className="cognisaas-pointer"
                    sx={{ padding: '15px 12px' }}
                  >
                    <Grid item sx={{ width: '85%', overflowY: 'scroll' }}>
                      <Typography>{template.template_project_name}</Typography>
                    </Grid>
                    <Grid item>
                      <IconButton
                        disableRipple
                        aria-haspopup="true"
                        sx={{ marginTop: '-5px' }}
                        onClick={(event) => handleClick(event, template.template_project_uid, 'project')}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Paper>
              </Stack>
            );
          })}
        </Grid>
        <DeleteModal
          isOpen={openDeleteModal}
          setIsOpen={setOpenDeleteModal}
          message={`Are you sure you want to delete this ${label_project.toLowerCase()} template`}
          onDelete={deleteSelectedProjectTemplate}
        />
      </Box>
    );
  } else if (props.templateType === templateHomeTabs[1]) {
    return (
      <Box ml={3} key={'1'} sx={{ marginTop: '10px', marginRight: '10px' }}>
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          {options.map((option) => (
            <MenuItem
              key={option}
              onClick={() => {
                // handleClose();
                takeActionForSelectedItem(option);
              }}
            >
              {option}
            </MenuItem>
          ))}
        </Menu>
        <Grid container direction="row">
          {props.templates.map((template: any, index: number) => {
            return (
              <Stack key={index} sx={{ margin: '8px 16px 8px 0px' }} spacing={3}>
                <Paper
                  onClick={() =>
                    navigate(toUpdateUsecaseTemplate, {
                      state: {
                        projectUid: template.template_case_uid,
                        userPermissions: [...template.__user_permissions],
                      },
                    })
                  }
                  sx={{ width: '310px', height: '68px' }}
                >
                  <Grid
                    container
                    // justifyContent="space-between"
                    className="cognisaas-pointer"
                    sx={{ padding: '15px 12px' }}
                  >
                    <Grid item sx={{ width: '85%', overflowY: 'scroll' }}>
                      <Typography>{template.template_case_name}</Typography>
                    </Grid>
                    <Grid item>
                      <IconButton
                        disableRipple
                        aria-haspopup="true"
                        sx={{ marginTop: '-5px' }}
                        onClick={(event) => handleClick(event, template.template_case_uid, 'usecase')}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Paper>
              </Stack>
            );
          })}
        </Grid>
        <DeleteModal
          isOpen={openDeleteModal}
          setIsOpen={setOpenDeleteModal}
          message={`Are you sure you want to delete this ${label_use_case.toLowerCase()} template`}
          onDelete={deleteSelectedUsecaseTemplate}
        />
      </Box>
    );
  } else if (props.templateType === templateHomeTabs[2]) {
    return (
      <>
        <CognisaasToast
          open={alertDetails.isVisible}
          onClose={() => setAlertDetails({ isVisible: false, type: alertDetails.type, message: '' })}
          message={alertDetails.message}
          type={alertDetails.type}
        />
        <Grid container direction="row" sx={{ marginLeft: '24px' }}>
          {props.templates.length > 0 && props.templates[0].template_document_uid ? (
            <>
              {props.templates.map((templateDetail: any, index: number) => (
                <DocumentTemplate key={index} templateDetail={templateDetail} />
              ))}
            </>
          ) : null}

          <BasicModal
            height={274}
            width={570}
            open={documentModalStatus}
            onClose={() => dispatch(closeNewDocumentTemplateModal())}
          >
            <CreateDocumentTemplate
              openModal={() => dispatch(closeNewDocumentTemplateModal())}
              setAlertDetails={setAlertDetails}
            />
          </BasicModal>
        </Grid>
      </>
    );
  } else {
    return <></>;
  }
};

export default TemplateNameCard;
