import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { getFiltered_phasesAndTaskData } from './phasesAndTasksHelper';
import { GridColDef, GridRenderCellParams, GridToolbar, GridColumnOrderChangeParams } from '@mui/x-data-grid';
import { DataGridPro } from '@mui/x-data-grid-pro';
import CognisaasLoader from '../../../shared/CognisaasLoader/CognisaasLoader';
import { Link } from 'react-router-dom';
import {
  label_client,
  label_project,
  label_project_delivery_manager,
  label_task,
  label_use_case,
} from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectAllFilters } from 'store/modules/AllFilters/selector';
import { isNull, isUndefined, generateOrderedColumn } from 'utils/dataHelpers';
import { useDispatch } from 'react-redux';
import { addFilterGroup } from 'store/modules/AllFilters/slice';
import { FilterableData, generateOptionsForThisDataKey } from 'utils/filteringFunctions';
import { fetchAllJourneyStages, getAllUsers } from 'store/modules/Common/slice';
import { selectJourneyStage, selectUsersOfCompany, selectUsersOfCompanyStatus } from 'store/modules/Common/selector';
import { fetchClientList } from 'store/modules/ManageClient/ClientList/slice';
import { selectClientList } from 'store/modules/ManageClient/ClientList/selector';
import checkAccessForRouting from 'components/shared/Hooks/checkAccessForRouting';
import CognisaaasTooltip from 'components/shared/CognisaasTooltip/CognisaasTooltip';
import useCommonAppConfig from 'components/Common/useCommonAppConfig';

interface PerformaCards_payload {
  total_projects: string;
  average_delay: string;
  percent_value: string;
  top_text: string;
}

const PerformaCards = (props: PerformaCards_payload) => {
  return (
    <Box
      sx={{
        display: 'grid',
        borderRadius: '4px',
        backgroundColor: '#EBEBEB93',
        gridTemplateColumns: '200px 1fr',
        padding: '5px',
        gridTemplateRows: '120px',
      }}
    >
      <Box
        sx={{
          margin: '5px',
          padding: '25px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'white',
          borderRadius: '4px',
        }}
      >
        <Typography variant="h2">{props.total_projects}</Typography>
        <Typography variant="h3">Total {props.top_text}</Typography>
      </Box>
      <Box sx={{ display: 'grid', justifyContent: 'flex-start', alignItems: 'center', margin: '5px' }}>
        <Typography variant="h2">{props.top_text} Completed on Time</Typography>
        <Typography variant="h2">{props.percent_value}</Typography>
        <Typography variant="subtitle1">
          <span style={{ color: 'red' }}>{props.average_delay}</span> Average Delay
        </Typography>
      </Box>
    </Box>
  );
};

interface phasesAndTasksPayload {
  data: any;
}

const PhasesAndTasksTable = (props: any) => {
  const [dataToShow, setDataToShow] = useState<Record<string, any>[]>([]);
  // const [pageSize, setPageSize] = useState(5);
  const [tableColumns, setTableColumns] = useState<any[]>([]);
  const uniqueColumnId = 'reportPerformancePhasesAndTasksListTable';
  const dispatch = useAppDispatch();
  const clientList = useAppSelector(selectClientList);

  let myTableState: any = {};
  const { saveAppTableState, getTableState } = useCommonAppConfig();
  const initialState: any = {
    columns: {},
    pagination: {
      page: 0,
      pageSize: 5,
    },
    ...getTableState('performenceReportProjectPhaseAndTask'),
  };

  const handleTableSave = (idName: string, thisTableState: any) => {
    saveAppTableState(idName, thisTableState);
  };

  useEffect(() => {
    dispatch(fetchClientList());
  }, []);

  const getClientPageURL = (row: any) => {
    return `/client-list/client/${row.client_uid}`;
  };

  const handleClientAccess = (params: GridRenderCellParams) => {
    const arrayToCheck = clientList.map((ele) => ele.client_uid);
    const hasAccessToClient = checkAccessForRouting({ arrayToCheck: arrayToCheck, uid: params.row.client_uid });
    console.log(params.value);
    if (hasAccessToClient == true) {
      return <Link to={getClientPageURL(params.row)}>{params.value}</Link>;
    }
    if (hasAccessToClient == false) {
      return (
        <CognisaaasTooltip
          title={<Typography sx={{ padding: '5px' }}>You do not have access to this {label_client}</Typography>}
        >
          <Typography variant="subtitle1">{params.value}</Typography>
        </CognisaaasTooltip>
      );
    }
  };

  useEffect(() => {
    setDataToShow(props.data);
    setTableColumns(generateOrderedColumn(columns, uniqueColumnId));
  }, [props]);

  const getProjectPageURL = (row: any) => {
    return `/projects/project/${row.association_uid}/${row.project_uid}`;
  };

  const columns: GridColDef[] = [
    {
      field: 'project_name',
      headerName: `${label_project} Name`,
      width: 190,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: GridRenderCellParams) => {
        return <Link to={getProjectPageURL(params.row)}>{params.value}</Link>;
      },
    },
    {
      field: 'client_name',
      headerName: `${label_client} Name`,
      width: 160,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: GridRenderCellParams) => handleClientAccess(params),
    },
    {
      field: 'phases',
      headerName: 'Phases',
      width: 100,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (obj: any) => <Typography variant="h3">{obj.row.phases}</Typography>,
    },
    {
      field: 'tasks',
      headerName: `${label_task}s`,
      width: 100,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (obj: any) => <Typography variant="h3">{obj.row.tasks}</Typography>,
    },
    {
      field: 'tasksComletedOnTime',
      headerName: `${label_task} completed on-time`,
      width: 180,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (obj: any) => <Typography variant="h3">{obj.row.tasksComletedOnTime}</Typography>,
    },
    {
      field: 'tasksCompletedLate',
      headerName: `${label_task}s completed late`,
      width: 160,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (obj: any) => <Typography variant="h3">{obj.row.tasksCompletedLate}</Typography>,
    },
    {
      field: 'tasksNotCompleted',
      headerName: `${label_task}s not completed`,
      width: 160,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (obj: any) => <Typography variant="h3">{obj.row.tasksNotCompleted}</Typography>,
    },
    {
      field: 'milestoneTask',
      headerName: 'Milestones',
      width: 120,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (obj: any) => <Typography variant="h3">{obj.row.milestoneTask}</Typography>,
    },
    {
      field: 'milestoneTaskCompletedOnTime',
      headerName: 'Milestone completed on time',
      width: 180,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (obj: any) => <Typography variant="h3">{obj.row.milestoneTaskCompletedOnTime}</Typography>,
    },
    {
      field: 'milestoneTaskCompletedLate',
      headerName: 'Milestone completed late',
      width: 180,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (obj: any) => <Typography variant="h3">{obj.row.milestoneTaskCompletedLate}</Typography>,
    },
    {
      field: 'milestoneTaskNotCompleted',
      headerName: 'Milestone not completed',
      width: 180,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (obj: any) => <Typography variant="h3">{obj.row.milestoneTaskNotCompleted}</Typography>,
    },
    {
      field: 'usecases',
      headerName: `${label_use_case}s`,
      width: 130,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (obj: any) => <Typography variant="h3">{obj.row.usecases}</Typography>,
    },
    {
      field: 'usecaseTasks',
      headerName: `${label_use_case} ${label_task}s`,
      width: 130,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (obj: any) => <Typography variant="h3">{obj.row.usecaseTasks}</Typography>,
    },
    {
      field: 'usecaseTasksCompletedOnTime',
      headerName: `${label_use_case} ${label_task}s completed on time`,
      width: 130,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (obj: any) => <Typography variant="h3">{obj.row.usecaseTasksCompletedOnTime}</Typography>,
    },
    {
      field: 'usecaseTasksCompletedLate',
      headerName: `${label_use_case} ${label_task}s completed late`,
      width: 180,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (obj: any) => <Typography variant="h3">{obj.row.usecaseTasksCompletedLate}</Typography>,
    },
    {
      field: 'usecaseTasksNotCompleted',
      headerName: `${label_use_case} ${label_task}s not completed`,
      width: 180,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (obj: any) => <Typography variant="h3">{obj.row.usecaseTasksNotCompleted}</Typography>,
    },
    {
      field: 'usecaseMilestone',
      headerName: `${label_use_case} Milestones`,
      width: 160,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (obj: any) => <Typography variant="h3">{obj.row.usecaseMilestone}</Typography>,
    },
    {
      field: 'usecaseMilestoneCompletedOnTime',
      headerName: `${label_use_case} Milestone completed on time`,
      width: 180,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (obj: any) => <Typography variant="h3">{obj.row.usecaseMilestoneCompletedOnTime}</Typography>,
    },
    {
      field: 'usecaseMilestoneCompletedLate',
      headerName: `${label_use_case} Milestone completed late`,
      width: 180,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (obj: any) => <Typography variant="h3">{obj.row.usecaseMilestoneCompletedLate}</Typography>,
    },
    {
      field: 'usecaseMilestoneNotCompleted',
      headerName: `${label_use_case} Milestone not completed`,
      width: 180,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (obj: any) => <Typography variant="h3">{obj.row.usecaseMilestoneNotCompleted}</Typography>,
    },
    {
      field: 'project_stage',
      headerName: 'Stage',
      width: 160,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (obj: any) => <Typography variant="h3">{obj.row.project_stage}</Typography>,
    },
    {
      field: 'project_deliverymanager',
      headerName: label_project_delivery_manager,
      width: 160,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (obj: any) => <Typography variant="h3">{obj.row.project_deliverymanager}</Typography>,
    },
  ];

  const handleColumnOrderChange = (params: GridColumnOrderChangeParams) => {
    const oldArrayIndex = params.oldIndex;
    const targetArrayIndex = params.targetIndex;

    setTableColumns(generateOrderedColumn(tableColumns, uniqueColumnId, oldArrayIndex, targetArrayIndex));
  };

  return !props.showLoader ? (
    <DataGridPro
      rows={dataToShow}
      initialState={initialState}
      onStateChange={(tblState: any) => {
        myTableState = tblState;
      }}
      onColumnVisibilityModelChange={() => {
        handleTableSave('performenceReportProjectPhaseAndTask', myTableState);
      }}
      onFilterModelChange={() => {
        handleTableSave('performenceReportProjectPhaseAndTask', myTableState);
      }}
      onPageSizeChange={() => {
        handleTableSave('performenceReportProjectPhaseAndTask', myTableState);
      }}
      components={{
        Toolbar: GridToolbar,
      }}
      componentsProps={{
        toolbar: { showQuickFilter: true },
      }}
      autoHeight={true}
      columns={tableColumns}
      getRowId={(obj: any) => obj.project_uid}
      pagination
      rowsPerPageOptions={[5, 10, 25, 50, 100]}
      // pageSize={pageSize}
      // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      rowHeight={52}
      onColumnOrderChange={handleColumnOrderChange}
    />
  ) : (
    <CognisaasLoader />
  );
};

const PhaseAndTasks = (props: phasesAndTasksPayload) => {
  const [finalData, setFinalData] = useState<any>([]);
  const [showLoader, setShowLoader] = useState(true);
  const allFilters = useAppSelector(selectAllFilters);
  const dispatch = useDispatch();
  const [filteredPerfPhasesAndTasks, setFilteredPerfPhasesAndTasks] = useState<any>([]);
  const allUsersStatus = useAppSelector(selectUsersOfCompanyStatus);
  const allUsers = useAppSelector(selectUsersOfCompany);
  const journeyStages = useAppSelector(selectJourneyStage);

  const [milestone_completedOnTime, setMilestone_completedOnTime] = useState<Record<string, any>>({
    total_projects: 0,
    average_delay: 0,
    percent_value: 0,
    top_text: '',
  });
  const [usecase_completedOnTime, setUsecase_completedOnTime] = useState<Record<string, any>>({
    total_projects: 0,
    average_delay: 0,
    percent_value: 0,
    top_text: '',
  });

  useEffect(() => {
    if (allUsersStatus === 'success' && journeyStages.status === 'success' && finalData.length > 0) {
      if (
        isUndefined(allFilters['performanceProjectphaseAndTasksReport']) ||
        isNull(allFilters['performanceProjectphaseAndTasksReport'])
      ) {
        dispatch(
          addFilterGroup({
            idName: 'performanceProjectphaseAndTasksReport',
            appliedFilters: [
              {
                name: `${label_project} Name`,
                dataKey: 'project_name',
                isSelected: false,
                equalToValue: [],
                canBeCleared: true,
                availableOptions: generateOptionsForThisDataKey(finalData, 'project_name'),
              },
              {
                name: `${label_client} Name`,
                dataKey: 'client_name',
                isSelected: false,
                equalToValue: [],
                canBeCleared: true,
                availableOptions: generateOptionsForThisDataKey(finalData, 'client_name'),
              },
              {
                name: 'Phases$$number-range',
                dataKey: 'phases',
                isSelected: false,
                equalToValue: [],
                canBeCleared: true,
                availableOptions: [],
              },
              {
                name: `${label_task}s$$number-range`,
                dataKey: 'tasks',
                isSelected: false,
                equalToValue: [],
                canBeCleared: true,
                availableOptions: [],
              },
              {
                name: `${label_task}s Completed On-Time$$number-range`,
                dataKey: 'tasksComletedOnTime',
                isSelected: false,
                equalToValue: [],
                canBeCleared: true,
                availableOptions: [],
              },
              {
                name: `${label_task}s Completed Late$$number-range`,
                dataKey: 'tasksCompletedLate',
                isSelected: false,
                equalToValue: [],
                canBeCleared: true,
                availableOptions: [],
              },
              {
                name: `${label_task}s Not Completed$$number-range`,
                dataKey: 'tasksNotCompleted',
                isSelected: false,
                equalToValue: [],
                canBeCleared: true,
                availableOptions: [],
              },
              {
                name: 'Milestones$$number-range',
                dataKey: 'milestoneTask',
                isSelected: false,
                equalToValue: [],
                canBeCleared: true,
                availableOptions: [],
              },
              {
                name: 'Milestone Completed On Time$$number-range',
                dataKey: 'milestoneTaskCompletedOnTime',
                isSelected: false,
                equalToValue: [],
                canBeCleared: true,
                availableOptions: [],
              },
              {
                name: 'Milestone Completed Late$$number-range',
                dataKey: 'milestoneTaskCompletedLate',
                isSelected: false,
                equalToValue: [],
                canBeCleared: true,
                availableOptions: [],
              },
              {
                name: 'Milestone Not Completed$$number-range',
                dataKey: 'milestoneTaskNotCompleted',
                isSelected: false,
                equalToValue: [],
                canBeCleared: true,
                availableOptions: [],
              },
              {
                name: `${label_use_case}s$$number-range`,
                dataKey: 'usecases',
                isSelected: false,
                equalToValue: [],
                canBeCleared: true,
                availableOptions: [],
              },
              {
                name: `${label_use_case} ${label_task}s$$number-range`,
                dataKey: 'usecaseTasks',
                isSelected: false,
                equalToValue: [],
                canBeCleared: true,
                availableOptions: [],
              },
              {
                name: `${label_use_case} ${label_task}s Completed On Time$$number-range`,
                dataKey: 'usecaseTasksCompletedOnTime',
                isSelected: false,
                equalToValue: [],
                canBeCleared: true,
                availableOptions: [],
              },
              {
                name: `${label_use_case} ${label_task}s Completed Late$$number-range`,
                dataKey: 'usecaseTasksCompletedLate',
                isSelected: false,
                equalToValue: [],
                canBeCleared: true,
                availableOptions: [],
              },
              {
                name: `${label_use_case} ${label_task}s Not Completed$$number-range`,
                dataKey: 'usecaseTasksNotCompleted',
                isSelected: false,
                equalToValue: [],
                canBeCleared: true,
                availableOptions: [],
              },
              {
                name: `${label_use_case} Milestones$$number-range`,
                dataKey: 'usecaseMilestone',
                isSelected: false,
                equalToValue: [],
                canBeCleared: true,
                availableOptions: [],
              },
              //fg
              {
                name: `${label_use_case} Milestone Completed On Time$$number-range`,
                dataKey: 'usecaseMilestoneCompletedOnTime',
                isSelected: false,
                equalToValue: [],
                canBeCleared: true,
                availableOptions: [],
              },

              {
                name: `${label_use_case} Milestone Completed Late$$number-range`,
                dataKey: 'usecaseMilestoneCompletedLate',
                isSelected: false,
                equalToValue: [],
                canBeCleared: true,
                availableOptions: [],
              },
              {
                name: `${label_use_case} Milestone Not Completed$$number-range`,
                dataKey: 'usecaseMilestoneNotCompleted',
                isSelected: false,
                equalToValue: [],
                canBeCleared: true,
                availableOptions: [],
              },
              // {
              //   name: 'client name$$text-contains',
              //   dataKey: 'client_name',
              //   isSelected: false,
              //   equalToValue: [],
              //   canBeCleared: true,
              //   availableOptions: [],
              // },
              {
                name: 'Stage',
                dataKey: 'project_stage',
                isSelected: false,
                equalToValue: [],
                canBeCleared: true,
                availableOptions: journeyStages.data.map((item: any) => item.stage_journerystage),
              },
              {
                name: label_project_delivery_manager,
                dataKey: 'project_deliverymanager',
                isSelected: false,
                equalToValue: [],
                canBeCleared: true,
                availableOptions: ['Not Assigned', ...allUsers.map((item: any) => item.user_username)],
              },
            ],
          })
        );
      }
    }
  }, [allFilters, allUsersStatus, journeyStages, finalData]);

  const getDatesDifference = (obj1: any, obj2: any) => {
    const time1 = new Date(obj1.replace(/-/g, '/').slice(0, 10));
    const time2 = new Date(obj2.replace(/-/g, '/').slice(0, 10));

    const diff = (time1.getTime() - time2.getTime()) / (24 * 60 * 60 * 1000);

    return diff;
  };

  const calculate_milestoneCompletedOnTime = () => {
    let totalMile = 0;
    let mileCompleted = 0;
    let delay = 0;
    let delayCount = 0;
    filteredPerfPhasesAndTasks.map((obj: any) => {
      obj.project_tasks.map((ins: any) => {
        if (ins.milestone_task == '1') {
          ++totalMile;
          const diff = getDatesDifference(ins.task_due_date, ins.task_closed_date);
          if (diff >= 0 && ins.task_stage == 'Done') {
            mileCompleted += 1;
          }
          if (ins.task_closed_date != '0000-00-00 00:00:00') {
            if (diff < 0) {
              delay += Math.abs(diff);
              delayCount += 1;
            }
          }
        }
      });
      obj.project_clientTasks.map((ins: any) => {
        if (ins.milestone_task == '1') {
          ++totalMile;
          const diff = getDatesDifference(ins.task_due_date, ins.task_closed_date);
          if (diff >= 0 && ins.task_stage == 'Done') {
            mileCompleted += 1;
          }
          if (ins.task_closed_date != '0000-00-00 00:00:00') {
            if (diff < 0) {
              delay += Math.abs(diff);
              delayCount += 1;
            }
          }
        }
      });
      obj.usecase.map((usecaseObj: any) => {
        usecaseObj.task?.map((ins: any) => {
          if (ins.milestone_task == '1') {
            ++totalMile;
            const diff = getDatesDifference(ins.task_due_date, ins.task_closed_date);
            if (diff >= 0 && ins.task_stage == 'Done') {
              mileCompleted += 1;
            }
            if (ins.task_closed_date != '0000-00-00 00:00:00') {
              if (diff < 0) {
                delay += Math.abs(diff);
                delayCount += 1;
              }
            }
          }
        });
        usecaseObj.clientTask?.map((ins: any) => {
          if (ins.milestone_task == '1') {
            ++totalMile;
            const diff = getDatesDifference(ins.task_due_date, ins.task_closed_date);
            if (diff >= 0 && ins.task_stage == 'Done') {
              mileCompleted += 1;
            }
            if (ins.task_closed_date != '0000-00-00 00:00:00') {
              if (diff < 0) {
                delay += Math.abs(diff);
                delayCount += 1;
              }
            }
          }
        });
      });
    });

    setMilestone_completedOnTime({
      total_projects: totalMile,
      average_delay: delayCount == 0 ? 0 : (delay / delayCount).toFixed(2),
      percent_value: ((mileCompleted * 100) / totalMile).toFixed(0),
      top_text: 'Milestones',
    });
  };

  const calculate_usecaseCompletedOnTime = () => {
    let totalUsecase = 0;
    let useCaseCompleted = 0;
    let delay = 0;
    let delayCount = 0;
    filteredPerfPhasesAndTasks.map((obj: any) => {
      obj.usecase.map((usecaseObj: any) => {
        totalUsecase = totalUsecase + 1;
        if (usecaseObj.case_readinessstate_status == 'blue') {
          useCaseCompleted += 1;
        } else {
          const diff = getDatesDifference(usecaseObj.case_requiredbydate, usecaseObj.case_deliverydate);
          if (diff < 0) {
            delayCount += 0;
            delay += diff;
          }
        }
        // usecaseObj.task.map((ins: any) => {
        //     if (ins.task_stage == 'Done' || ins.task_stage == 'done') {
        //         useCaseCompleted += 1;
        //     }
        // });
        // usecaseObj.clientTask.map((ins: any) => {
        //     if (ins.task_stage == 'Done' || ins.task_stage == 'done') {
        //         useCaseCompleted += 1;
        //     } else {
        //         const diff = getDatesDifference(ins.task_due_date, ins.task_closed_date);
        //         if (diff < 0) {
        //             delayCount += 0;
        //             delay += diff;
        //         }
        //     }
        // });
      });
    });
    setUsecase_completedOnTime({
      total_projects: totalUsecase,
      average_delay: delayCount == 0 ? 0 : (delay / delayCount).toFixed(2),
      percent_value: ((useCaseCompleted / totalUsecase) * 100).toFixed(0),
      top_text: 'Use Case',
    });
  };

  useEffect(() => {
    calculate_milestoneCompletedOnTime();
    calculate_usecaseCompletedOnTime();
  }, [filteredPerfPhasesAndTasks]);

  useEffect(() => {
    if (allUsersStatus === 'idle' || allUsersStatus === 'failed') {
      dispatch(getAllUsers());
    }

    if (journeyStages.status === 'idle' || journeyStages.status === 'failed') {
      dispatch(fetchAllJourneyStages());
    }
  }, [allUsersStatus, journeyStages]);

  useEffect(() => {
    if (props.data != undefined && props.data?.length != 0) {
      const temp = getFiltered_phasesAndTaskData(props.data ? props.data : []);
      setFinalData(temp);
      setShowLoader(false);
    }
  }, [props.data]);

  useEffect(() => {
    if (allFilters['performanceProjectphaseAndTasksReport'] && finalData) {
      const newFilteredData = new FilterableData([...finalData]);
      newFilteredData.bulkFilter(allFilters['performanceProjectphaseAndTasksReport'].appliedFilters);
      setFilteredPerfPhasesAndTasks([...newFilteredData.toArray()]);
    }
  }, [allFilters, finalData]);

  return (
    <Box
      sx={{
        display: 'grid',
        width: '100%',
        backgroundColor: 'white',
        gridGap: '20px',
        justifyContent: 'space-between',
        alignItems: 'center',
        gridTemplateRows: 'auto auto',
        gridTemplateColumns: '1fr',
        padding: '20px',
      }}
    >
      <Box
        sx={{
          display: 'grid',
          justifyContent: 'space-evenly',
          alignItems: 'stretch',
          gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 500px))',
          gridGap: '10px',
        }}
      >
        <PerformaCards
          total_projects={milestone_completedOnTime.total_projects}
          average_delay={`${milestone_completedOnTime.average_delay} days`}
          percent_value={`${milestone_completedOnTime.percent_value}%`}
          top_text={milestone_completedOnTime.top_text}
        />
        <PerformaCards
          total_projects={usecase_completedOnTime.total_projects}
          average_delay={`${usecase_completedOnTime.average_delay} days`}
          percent_value={`${usecase_completedOnTime.percent_value}%`}
          top_text={usecase_completedOnTime.top_text}
        />
      </Box>
      <Box sx={{ display: 'grid', justifyContent: 'center', alignItems: 'center', gridTemplateColumns: '1fr' }}>
        <PhasesAndTasksTable data={filteredPerfPhasesAndTasks} showLoader={showLoader} />
      </Box>
    </Box>
  );
};

export default PhaseAndTasks;
