export type chart = 'Bar Chart' | 'Donut Chart' | 'Bubble Chart' | '';
export type expandValueType = 'Chart' | 'Trendline' | 'Labels' | 'Colours' | '';
export type trendlineType = 'All' | 'Cumulative' | 'Expand';
export type labelPositionType = 'Left' | 'Right' | 'Top' | 'Bottom';

export interface graphSchema {
  chartType: chart;
  verticalBar?: boolean;
  labelPosition?: labelPositionType;
  flipAxis?: boolean;
  chartName?: string;
  trendLine?: trendlineType;
}

export enum userPermissionSchema {
  VIEW = 'view',
  EDIT = 'edit',
  DELETE = 'delete',
}

export const userPermissionTypes = ['view', 'edit', 'delete'];
