import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { Stack, Typography } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import CognisaasButton from '../../../shared/CognisaasButton/CognisaasButton';
import { useDispatch } from 'react-redux';
import {
  getCustomerDetails,
  resetConfigurationUpdateState,
  updateUseCase_Configuration,
} from '../../../../store/modules/Settings/slice';
import { selectConfigurationUpdateState, selectCustomerDetails } from '../../../../store/modules/Settings/selector';
import { useAppSelector } from '../../../../store/hooks';
import { requestState } from 'store/modules/types';
import CognisaasToast from 'components/shared/CognisaasToast/CognisaasToast';
import { label_task, label_use_case } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { setUsecaseTaskdashboardView } from 'utils/viewHelper';
import CustomUsecaseCategory from './Usecase/CustomUsecaseCategory';
import CustomUsecaseStage from './Usecase/CustomUsecaseStage';
import CustomUsecasePriority from './Usecase/CustomUsecasePriority';
import SwitchBadge from '../SwitchBadge';
import { isSuperUser } from '../../../../utils/getUid';

const Usecase = () => {
  const [radioValue, setRadioValue] = useState('1');
  const [planView, setPlanView] = useState<string | '0' | '1' | '2'>('0');

  const [radioPreviousValue, setRadioPreviousValue] = useState('1');
  const dispatch = useDispatch();
  const customerDetails = useAppSelector(selectCustomerDetails).data;
  const [freezePlannedDate, setFreezePlannedDate] = useState(false);
  const configurationUpdateStatus = useAppSelector(selectConfigurationUpdateState);
  const [alertDetails, setAlertDetails] = useState<{
    isVisible: boolean;
    type: 'success' | 'failure';
    message: string;
  }>({ isVisible: false, type: 'success', message: '' });

  // for alerts
  useEffect(() => {
    if (configurationUpdateStatus.status === requestState.success) {
      setAlertDetails({ isVisible: true, type: 'success', message: 'Updated Successfully!' });
      dispatch(resetConfigurationUpdateState());
    }
  }, [configurationUpdateStatus]);

  useEffect(() => {
    dispatch(getCustomerDetails());
  }, []);

  useEffect(() => {
    setRadioValue(customerDetails.usecase_calc_ind);
    setPlanView(customerDetails.usecase_plan_view);
    setFreezePlannedDate(customerDetails.freeze_planned_date_usecase == '1');
  }, [customerDetails]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioPreviousValue(radioValue);
    setRadioValue((event.target as HTMLInputElement).value);
  };

  const onClickUpdateButton = () => {
    dispatch(
      updateUseCase_Configuration({
        client_mail: customerDetails.client_mail,
        client_task_ind: customerDetails.client_task_ind,
        currency_code: customerDetails.currency_code,
        customer_amber_threshold: customerDetails.customer_amber_threshold,
        customer_uid: customerDetails.customer_uid,
        renewal_notification: customerDetails.renewal_notification,
        subtask_enable: customerDetails.subtask_enable,
        task_deletion_mail: customerDetails.task_deletion_mail,
        task_dependency_date_updation: customerDetails.task_dependency_date_updation,
        task_inprogress_indicator: customerDetails.task_inprogress_indicator,
        task_mail: customerDetails.task_mail,
        task_mail_copy: customerDetails.task_mail_copy,
        task_status_account_owner_mail: customerDetails.task_status_accountowner_mail,
        task_status_mail: customerDetails.task_status_mail,
        task_status_client_contact_mail: customerDetails.task_status_client_contact_mail,
        task_status_manager_mail: customerDetails.task_status_manager_mail,
        time_track: customerDetails.time_track,
        usecase_calc_ind: radioValue,
        task_assign_mail_dm_copy: customerDetails.task_assign_mail_dm_copy,
        task_deletion_delivery_manager_mail: customerDetails.task_deletion_delivery_manager_mail,
        project_progress_indicator: customerDetails.project_progress_indicator,
        status_report_client: customerDetails.status_report_client,
        primary_contact_as_default_owner: customerDetails.primary_contact_as_default_owner,
        delivery_manager_as_default_owner: customerDetails.delivery_manager_as_default_owner,
        client_contact_added_mail: customerDetails.client_contact_added_mail,
        project_plan_view: customerDetails.project_plan_view,
        usecase_plan_view: planView,
        freeze_planned_date_project: customerDetails.freeze_planned_date_project,
        freeze_planned_date_usecase: customerDetails.freeze_planned_date_usecase,
      })
    );
    if (customerDetails?.usecase_plan_view != planView) {
      const taskView = planView == '0' ? 'table' : planView == '1' ? 'plan' : 'resourse';
      setUsecaseTaskdashboardView(taskView);
    }
  };

  const onClickCancelButton = () => {
    setRadioValue(radioPreviousValue);
  };

  const handlePlanViewChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // setRadioPreviousValue(radioValue);
    setPlanView((event.target as HTMLInputElement).value);
  };

  const onChickSwitch = async () => {
    await dispatch(
      updateUseCase_Configuration({
        client_mail: customerDetails.client_mail,
        client_task_ind: customerDetails.client_task_ind,
        currency_code: customerDetails.currency_code,
        customer_amber_threshold: customerDetails.customer_amber_threshold,
        customer_uid: customerDetails.customer_uid,
        renewal_notification: customerDetails.renewal_notification,
        subtask_enable: customerDetails.subtask_enable,
        task_deletion_mail: customerDetails.task_deletion_mail,
        task_dependency_date_updation: customerDetails.task_dependency_date_updation,
        task_inprogress_indicator: customerDetails.task_inprogress_indicator,
        task_mail: customerDetails.task_mail,
        task_mail_copy: customerDetails.task_mail_copy,
        task_status_account_owner_mail: customerDetails.task_status_accountowner_mail,
        task_status_mail: customerDetails.task_status_mail,
        task_status_client_contact_mail: customerDetails.task_status_client_contact_mail,
        task_status_manager_mail: customerDetails.task_status_manager_mail,
        time_track: customerDetails.time_track,
        usecase_calc_ind: customerDetails.usecase_calc_ind,
        task_assign_mail_dm_copy: customerDetails.task_assign_mail_dm_copy,
        task_deletion_delivery_manager_mail: customerDetails.task_deletion_delivery_manager_mail,
        project_progress_indicator: customerDetails.project_progress_indicator,
        status_report_client: customerDetails.status_report_client,
        primary_contact_as_default_owner: customerDetails.primary_contact_as_default_owner,
        delivery_manager_as_default_owner: customerDetails.delivery_manager_as_default_owner,
        client_contact_added_mail: customerDetails.client_contact_added_mail,
        project_plan_view: customerDetails.project_plan_view,
        usecase_plan_view: customerDetails.usecase_plan_view,
        freeze_planned_date_project: customerDetails.freeze_planned_date_project,
        freeze_planned_date_usecase: !freezePlannedDate,
      })
    );
    setFreezePlannedDate(!freezePlannedDate);
    await dispatch(getCustomerDetails());
  };

  return (
    <Grid>
      <Grid sx={{ margin: '24px 20px' }}>
        <Typography variant={'h2'}>
          Calculation of &quot;{label_use_case} delivery status&quot; should be based on
        </Typography>
      </Grid>
      <Grid sx={{ margin: '0 20px' }}>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="female"
          name="radio-buttons-group"
          value={radioValue}
          onChange={handleChange}
          sx={{ marginLeft: '10px' }}
        >
          <FormControlLabel
            sx={{ marginTop: '10px' }}
            value="0"
            control={<Radio size="small" />}
            label={
              <Typography
                variant={'subtitle1'}
                color={radioValue == '0' ? '#2D60F6' : '#7A7A7A'}
                sx={{ marginLeft: '6px' }}
              >
                Status of product features and implementation {label_task.toLowerCase()}s
              </Typography>
            }
          />
          <FormControlLabel
            sx={{ marginTop: '10px' }}
            value="1"
            control={<Radio size="small" />}
            label={
              <Typography
                variant={'subtitle1'}
                color={radioValue == '1' ? '#2D60F6' : '#7A7A7A'}
                sx={{ marginLeft: '6px' }}
              >
                Status of implementation {label_task.toLowerCase()}s only
              </Typography>
            }
          />
          <FormControlLabel
            sx={{ marginTop: '10px' }}
            value="2"
            control={<Radio size="small" />}
            label={
              <Typography
                variant={'subtitle1'}
                color={radioValue == '2' ? '#2D60F6' : '#7A7A7A'}
                sx={{ marginLeft: '6px' }}
              >
                Status of product features only
              </Typography>
            }
          />
        </RadioGroup>
      </Grid>
      <Grid sx={{ margin: '15px 20px' }}>
        <Typography style={{ marginBottom: '10px' }} variant={'h2'}>
          Default &quot;{label_use_case} Plan&quot; view should be
        </Typography>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="female"
          name="radio-buttons-group"
          value={planView}
          onChange={handlePlanViewChange}
          sx={{ marginLeft: '10px' }}
        >
          <FormControlLabel
            sx={{ marginTop: '10px' }}
            value="0"
            control={<Radio size="small" />}
            label={
              <Typography
                variant={'subtitle1'}
                color={planView == '0' ? '#2D60F6' : '#7A7A7A'}
                sx={{ marginLeft: '6px' }}
              >
                List View
              </Typography>
            }
          />
          <FormControlLabel
            sx={{ marginTop: '10px' }}
            value="1"
            control={<Radio size="small" />}
            label={
              <Typography
                variant={'subtitle1'}
                color={planView == '1' ? '#2D60F6' : '#7A7A7A'}
                sx={{ marginLeft: '6px' }}
              >
                Kanban View
              </Typography>
            }
          />
          <FormControlLabel
            sx={{ marginTop: '10px' }}
            value="2"
            control={<Radio size="small" />}
            label={
              <Typography
                variant={'subtitle1'}
                color={planView == '2' ? '#2D60F6' : '#7A7A7A'}
                sx={{ marginLeft: '6px' }}
              >
                Gantt View
              </Typography>
            }
          />
        </RadioGroup>
      </Grid>

      <Grid sx={{ margin: '24px 20px' }}>
        <CognisaasButton
          label={'Update'}
          isOutlined={false}
          clickHandler={onClickUpdateButton}
          sx={{ marginRight: '20px' }}
        />
        <CognisaasButton label={'Cancel'} isOutlined={true} clickHandler={onClickCancelButton} />
      </Grid>

      {isSuperUser() && (
        <SwitchBadge
          text={'Freeze Planned Date'}
          checked={freezePlannedDate}
          handleChange={onChickSwitch}
          margin={'20px 16px 16px 20px'}
        />
      )}

      <Stack style={{ marginTop: '24px', width: '480px', marginLeft: '20px' }}>
        <Typography variant="h6">Custom {label_use_case.toLowerCase()} category names</Typography>
        <CustomUsecaseCategory />
      </Stack>

      <Stack style={{ marginTop: '24px', width: '480px', marginLeft: '20px' }}>
        <Typography variant="h6">Custom {label_use_case.toLowerCase()} stage names</Typography>
        <CustomUsecaseStage />
      </Stack>

      <Stack style={{ marginTop: '24px', width: '480px', marginLeft: '20px' }}>
        <Typography variant="h6">Custom {label_use_case.toLowerCase()} priority names</Typography>
        <CustomUsecasePriority />
      </Stack>

      {/* this is new alerts */}
      <CognisaasToast
        open={alertDetails.isVisible}
        onClose={() => setAlertDetails({ isVisible: false, type: alertDetails.type, message: '' })}
        message={alertDetails.message}
        type={alertDetails.type}
      />
    </Grid>
  );
};
export default Usecase;
