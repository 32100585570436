import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../..';

export const selectClosedProjectsState = (state: RootState) => state.closedProjectsState;
export const selectClosedProjects = createSelector(
  selectClosedProjectsState,
  (closedProjectsState) => closedProjectsState.closedProjects
);
export const selectClosedProjectsStatus = createSelector(
  selectClosedProjectsState,
  (closedProjectsState) => closedProjectsState.status
);
export const selectClosedProjectsError = createSelector(
  selectClosedProjectsState,
  (closedProjectsState) => closedProjectsState.error
);
