import { isNull, isUndefined } from 'utils/dataHelpers';

const useCommonAppConfig = () => {
  const saveAppTableState = (idName: string, tableState: any) => {
    const stateInLocalStorage = JSON.parse(localStorage.getItem('cognisaas_app_configs') as string);
    console.log('appppp', stateInLocalStorage);
    if (!isNull(stateInLocalStorage) && Object.keys(stateInLocalStorage).length > 0) {
      localStorage.setItem(
        'cognisaas_app_configs',
        JSON.stringify({
          ...stateInLocalStorage,
          [idName]: {
            tableConfig: {
              columns: {
                columnVisibilityModel: tableState.columns.columnVisibilityModel,
              },
              filter: {
                filterModel: {
                  items: tableState.filter.filterModel.items,
                },
              },
              pagination: { ...tableState.pagination },
            },
          },
        })
      );
    } else {
      localStorage.setItem(
        'cognisaas_app_configs',
        JSON.stringify({
          [idName]: {
            tableConfig: {
              columns: {
                columnVisibilityModel: tableState.columns.columnVisibilityModel,
              },
              filter: {
                filterModel: {
                  items: tableState.filter.filterModel.items,
                },
              },
              pagination: { ...tableState.pagination },
            },
          },
        })
      );
    }
    // localStorage.setItem(
    //   'cognisaas_app_configs',
    //   JSON.stringify({
    //     [idName]: {
    //       tableConfig: tableState,
    //     },
    //   })
    // );
  };

  const getTableState = (idName: string): Record<any, any> => {
    const stateInLocalStorage = JSON.parse(localStorage.getItem('cognisaas_app_configs') as string);
    console.log('get appppp', stateInLocalStorage);
    if (isNull(stateInLocalStorage) || isUndefined(stateInLocalStorage[idName])) {
      return {};
    } else {
      const currentIdTableState = stateInLocalStorage[idName]['tableConfig'];
      return {
        columns: {
          columnVisibilityModel: currentIdTableState.columns.columnVisibilityModel,
        },
        filter: {
          filterModel: {
            items: currentIdTableState.filter.filterModel.items,
          },
        },
        pagination: { ...currentIdTableState.pagination },
      };
      // return { ...currentIdTableState };
    }
  };

  return {
    saveAppTableState,
    getTableState,
  };
};

export default useCommonAppConfig;
