import { Box, Stack, Typography } from '@mui/material';
import CognisaasToast from 'components/shared/CognisaasToast/CognisaasToast';
import EditableInlineTextField from 'components/shared/EditableInlineTextField/EditableInlineTextField';
import PictureUpload from 'components/shared/PictureUpload/PictureUpload';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { requestForgotPasswordEmail, resetAuthStatus } from 'store/modules/auth/auth';
import { selectAuthStatus } from 'store/modules/auth/selector';
import { getUserData_selector, selectProfileUpdateState } from 'store/modules/Settings/selector';
import {
  getUserDetails_slice,
  resetProfileUpdateState,
  updateUserEmail,
  updateUsername,
} from 'store/modules/Settings/slice';
import { requestState } from 'store/modules/types';
import Cookies from 'universal-cookie';
import { validateEmail } from 'utils/dataHelpers';
import BasicModal from 'components/shared/CognisaasModal/CognisaasModal';
import EmailUpdateModal from './EmailUpdateModal';

const cookies = new Cookies();
const hostname = location.hostname;

const ProfileV2 = () => {
  const [isEmailEditingEnabled, setIsEmailEditingEnabled] = useState(false);
  const [updateEmail, setUpdateEmail] = useState<boolean>(false);
  const dispatch = useDispatch();
  const userDetails = useAppSelector(getUserData_selector);
  const updatingProfile = useAppSelector(selectProfileUpdateState);
  const passwordRequestStatus = useAppSelector(selectAuthStatus);

  const [alertDetails, setAlertDetails] = useState<{
    isVisible: boolean;
    type: 'success' | 'failure';
    message: string;
  }>({ isVisible: false, type: 'success', message: '' });

  useEffect(() => {
    dispatch(getUserDetails_slice((cookies.get('cogni_user_uid') as string) || ''));
    dispatch(resetAuthStatus());
  }, []);

  useEffect(() => {
    if (updatingProfile.status === requestState.success) {
      setAlertDetails({
        isVisible: true,
        type: 'success',
        message: updatingProfile.message ? updatingProfile.message : '',
      });
      dispatch(resetProfileUpdateState());
      dispatch(getUserDetails_slice((cookies.get('cogni_user_uid') as string) || ''));
    }
  }, [updatingProfile]);

  useEffect(() => {
    if (passwordRequestStatus === requestState.success) {
      setAlertDetails({
        isVisible: true,
        type: 'success',
        message: 'Password Reset Email sent successfully',
      });
      dispatch(resetAuthStatus());
      dispatch(getUserDetails_slice((cookies.get('cogni_user_uid') as string) || ''));
    }
  }, [passwordRequestStatus]);

  // useEffect(() => {
  //   if (
  //     addingUserLogoStatus === requestState.success ||
  //     addingCustomerLogoStatus === requestState.success ||
  //     addingClientLogoStatus === requestState.success
  //   ) {
  //     setAlertDetails({
  //       isVisible: true,
  //       type: 'success',
  //       message: 'Profile Photo Updated Successfully!',
  //     });
  //     resetLogoUpdateStatus();
  //     resetLogoUpdateStatus();
  //   }
  // }, [addingUserLogoStatus, addingCustomerLogoStatus, addingClientLogoStatus]);

  const handleUsernameUpdate = (newUsername: string) => {
    const payload = {
      user_uid: cookies.get('cogni_user_uid') as string,
      user_username: newUsername,
    };
    dispatch(updateUsername(payload));
  };

  const handleEmailUpdate = (newEmail: string) => {
    if (validateEmail(newEmail)) {
      const payload = {
        base_url: `${hostname}:3000/verify-user`,
        user_email: userDetails.data.user_email,
        user_new_email: newEmail,
        user_uid: userDetails.data.user_uid,
        user_username: userDetails.data.user_username,
      };
      dispatch(updateUserEmail(payload));
    } else {
      setAlertDetails({ isVisible: true, type: 'failure', message: 'Enter a valid email' });
    }
    console.log('email updtae');
  };

  const requestPasswordUpdate = () => {
    const requestPasswordDetails = {
      email: cookies.get('cogni_user_email'),
      application: 'cognisaas_app',
    };
    dispatch(requestForgotPasswordEmail(requestPasswordDetails));
  };

  return (
    <Box sx={{ padding: '16px' }}>
      <Stack>
        {/* header */}
        <Typography variant="h2" sx={{ marginBottom: '32px' }}>
          Profile
        </Typography>

        <Stack direction={'row'}>
          <PictureUpload
            user_uid={cookies.get('cogni_user_uid') as string}
            type="user"
            setAlertDetails={setAlertDetails}
          />
          <EditableInlineTextField
            containerSx={{ position: 'relative', top: '-20px' }}
            viewType="text-only"
            value={userDetails.data?.user_username}
            disableEditPenButton={false}
            handleSave={(newValue: string) => handleUsernameUpdate(newValue)}
            customEditingModeOperation={null}
          />
        </Stack>
        <Typography sx={{ marginTop: '24px' }} variant={'h2'}>
          Personal Info
        </Typography>

        {/* email */}
        <Typography sx={{ marginTop: '16px' }} variant={'subtitle2'}>
          Email
        </Typography>
        <EditableInlineTextField
          viewType="disabled-input"
          value={userDetails.data.user_email}
          handleSave={(newValue: string) => handleEmailUpdate(newValue)}
          disableEditPenButton={true}
          customEditingModeOperation={{
            enableEditingMode: isEmailEditingEnabled,
            setEnabledEditingMode: setIsEmailEditingEnabled,
          }}
        />
        <Stack alignItems={'flex-end'} sx={{ width: '310px' }}>
          {/* <Typography
            onClick={() => setUpdateEmail(true)}
            className={'link-text cognisaas-pointer'}
            sx={{ marginTop: '8px' }}
            variant={'subtitle2'}
          >
            Change Email
          </Typography> */}
        </Stack>
      </Stack>

      {/* if new user email is present and not verified */}
      {userDetails.data.user_new_email && userDetails.data.user_new_email.length > 0 && (
        <>
          <Typography sx={{ marginTop: '16px' }} variant={'subtitle2'}>
            New Email
          </Typography>
          <EditableInlineTextField
            viewType="disabled-input"
            value={userDetails.data.user_new_email}
            handleSave={() => {
              console.log('nothing');
            }}
            disableEditPenButton={true}
            customEditingModeOperation={null}
          />
          <Typography variant={'subtitle2'} sx={{ color: '#E75B5C' }}>
            Email Verification is Pending
          </Typography>
        </>
      )}

      {/* comapany */}
      <Typography sx={{ marginTop: '16px' }} variant={'subtitle2'}>
        Company
      </Typography>
      <EditableInlineTextField
        viewType="disabled-input"
        value={userDetails.data.user_company}
        handleSave={() => {
          console.log('someone');
        }}
        disableEditPenButton={true}
        customEditingModeOperation={null}
      />

      {/* reset pasword */}
      <Typography sx={{ marginTop: '24px' }} variant={'h2'}>
        Password
      </Typography>
      <Typography onClick={requestPasswordUpdate} className={'link-text cognisaas-pointer'} variant={'subtitle2'}>
        Change Password
      </Typography>
      {console.log(userDetails.data)}
      {userDetails.data.role !== null && (
        <>
          <Typography sx={{ marginTop: '14px' }} variant={'h2'}>
            Role
          </Typography>
          <EditableInlineTextField
            viewType="disabled-input"
            value={userDetails.data.role?.name}
            handleSave={() => {
              console.log('');
            }}
            disableEditPenButton={true}
            customEditingModeOperation={null}
          />
        </>
      )}
      {userDetails.data.reportees !== null && (
        <>
          {userDetails.data.reportees?.length > 0 ? (
            <>
              <Typography sx={{ marginTop: '14px' }} variant={'h2'}>
                Reportees
              </Typography>
              <Box
                style={{
                  maxHeight: '200px',
                  width: '290px',
                  overflowY: 'scroll',
                  overflowX: 'hidden',
                  border: '1px solid #E5E5E5',
                  borderRadius: '4px',
                  padding: '10px',
                  marginTop: '15px',
                }}
              >
                {userDetails.data.reportees &&
                  userDetails.data.reportees.map((reportees: any) => (
                    <>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        key={reportees.id}
                        style={{
                          borderRadius: '4px',
                          border: '1px solid #E5E5E5',
                          boxShadow: '0px 4px 26px rgba(0, 0, 0, 0.05)',
                          background: '#fff',
                          padding: '5px 10px',
                          marginBottom: '7px',
                        }}
                      >
                        <Typography variant="h2">{reportees.user_name}</Typography>
                      </Stack>
                    </>
                  ))}
              </Box>
            </>
          ) : (
            <>
              <Typography sx={{ marginTop: '14px' }} variant={'h2'}>
                Reportees
              </Typography>
              <Box
                style={{
                  maxHeight: '200px',
                  width: '290px',
                  overflowY: 'scroll',
                  overflowX: 'hidden',
                  border: '1px solid #E5E5E5',
                  borderRadius: '4px',
                  padding: '10px',
                  marginTop: '15px',
                }}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  // key={reportees.id}
                  style={{
                    borderRadius: '4px',
                    border: '1px solid #E5E5E5',
                    boxShadow: '0px 4px 26px rgba(0, 0, 0, 0.05)',
                    background: '#fff',
                    padding: '5px 10px',
                    marginBottom: '7px',
                  }}
                >
                  <Typography variant="h2">No Reportees Assigned</Typography>
                </Stack>
              </Box>
            </>
          )}
        </>
      )}

      {/* alerts used */}
      <CognisaasToast
        open={alertDetails.isVisible}
        onClose={() => setAlertDetails({ isVisible: false, type: alertDetails.type, message: '' })}
        message={alertDetails.message}
        type={alertDetails.type}
      />
      {updateEmail && (
        <BasicModal height={224} width={570} open={true} onClose={() => setUpdateEmail(!updateEmail)}>
          <EmailUpdateModal
            email={userDetails.data.user_email}
            closeModal={() => setUpdateEmail(false)}
            closeModalAfterAction={(updateDone: boolean, newEmail: string) => {
              if (updateDone) {
                setAlertDetails({ isVisible: true, type: 'success', message: 'Updated successfully!' });
                handleEmailUpdate(newEmail);
                setUpdateEmail(false);
                return;
              } else {
                setAlertDetails({ isVisible: true, type: 'failure', message: 'Please try again!' });
                return;
              }
            }}
          />
        </BasicModal>
      )}
    </Box>
  );
};

export default ProfileV2;
