import { BarDatum, ResponsiveBar } from '@nivo/bar';
// import RoundedCornersBar from './RoundedCornersBar';
// import { line, curveCatmullRom } from 'd3-shape';

interface BarChartWithTrendlineProps {
  data: any[];
  layout?: 'horizontal' | 'vertical';
  indexAxisKey: string;
  otherAxisKeys: string[];
  axisTopLegend?: string;
  axisRightLegend?: string;
  axisBottomLegend?: string;
  axisLeftLegend?: string;
  legend: {
    anchor: 'top-right' | 'right' | 'bottom-right' | 'bottom' | 'bottom-left' | 'left' | 'top-left' | 'top' | 'center';
    translateX: number;
    translateY: number;
    direction: 'column' | 'row';
  };
}

const BarChartWithTrendline = (props: BarChartWithTrendlineProps) => {
  return (
    <ResponsiveBar
      data={props.data as unknown as BarDatum[]}
      keys={props.otherAxisKeys}
      indexBy={props.indexAxisKey}
      margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
      padding={0.69}
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      colors={{ scheme: 'nivo' }}
      layout={props.layout ? props.layout : 'vertical'}
      defs={[
        {
          id: 'dots',
          type: 'patternDots',
          background: 'inherit',
          color: '#38bcb2',
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: 'lines',
          type: 'patternLines',
          background: 'inherit',
          color: '#eed312',
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      fill={[
        {
          match: {
            id: 'fries',
          },
          id: 'dots',
        },
        {
          match: {
            id: 'sandwich',
          },
          id: 'lines',
        },
      ]}
      borderColor={{
        from: 'color',
        modifiers: [['darker', 1.6]],
      }}
      axisTop={
        props.axisTopLegend
          ? {
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: props.axisTopLegend,
              legendPosition: 'middle',
              legendOffset: 32,
            }
          : null
      }
      axisRight={
        props.axisRightLegend
          ? {
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: props.axisRightLegend,
              legendPosition: 'middle',
              legendOffset: 32,
            }
          : null
      }
      axisBottom={
        props.axisBottomLegend
          ? {
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: props.axisBottomLegend,
              legendPosition: 'middle',
              legendOffset: 32,
            }
          : null
      }
      axisLeft={
        props.axisLeftLegend
          ? {
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: props.axisLeftLegend,
              legendPosition: 'middle',
              legendOffset: -40,
            }
          : null
      }
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{
        from: 'color',
        modifiers: [['darker', 1.6]],
      }}
      legends={[
        {
          dataFrom: 'keys',
          anchor: props.legend.anchor,
          justify: false,
          translateX: props.legend.translateX,
          translateY: props.legend.translateY,
          itemsSpacing: 2,
          itemWidth: 130,
          itemHeight: 20,
          itemDirection: 'left-to-right',
          itemOpacity: 0.85,
          direction: props.legend ? props.legend.direction : 'column',
          symbolSize: 20,

          effects: [
            {
              on: 'hover',
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      role="application"
      // barComponent={RoundedCornersBar}
      ariaLabel="Nivo bar chart demo"
      borderRadius={4}
      barAriaLabel={function (e) {
        return e.id + ': ' + e.formattedValue + ' in country: ' + e.indexValue;
      }}
    />
  );
};

export default BarChartWithTrendline;
