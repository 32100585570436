import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { customerPortalApi } from '../../../api/modules/CustomerPortal';
import { requestState } from '../types';

interface authType {
  results: any;
  status: requestState;
  error: null | undefined | string;

  userObjIsLoading: requestState;
  userObj: any;
  userObjError: any;

  projectWithTasksLoading: boolean;
  projectWithTasks: any;
  projectWithTasksError: any;

  projectUpgradationLoading: boolean;
  projectUpgradation: any;
  projectUpgradationError: any;

  useCaseUpgradationLoading: boolean;
  useCaseUpgradation: any;
  useCaseError: any;

  projectDegradationLoading: boolean;
  projectDegradation: any;
  projectDegradationError: any;

  useCaseDegradationLoading: boolean;
  useCaseDegradation: any;
  useCaseDegradationError: any;

  projectStatusCountLoading: boolean;
  projectStatusCount: any;
  projectStatusCountError: any;

  taskStatusCountLoading: boolean;
  taskStatusCount: any;
  taskStatusCountError: any;

  useCaseStatusCountLoading: boolean;
  useCaseStatusCount: any;
  useCaseStatusCountError: any;

  signleProjectInformationLoading: boolean;
  signleProjectInformation: any;
  signleProjectInformationError: any;

  signleUsecaseInformationLoading: boolean;
  signleUsecaseInformation: any;
  signleUsecaseInformationError: any;

  allCompanyUsersLoading: boolean;
  allCompanyUsers: any;
  allCompanyUsersError: any;

  allClientUsersLoading: boolean;
  allClientUsersUsers: any;
  allClientUsersUsersError: any;

  createdTaskUidLoading: boolean;
  createdTaskUid: any;
  createdTaskUidError: any;

  isMagicLinkExpiredLoading: boolean;
  isMagicLinkExpired: any;
  isMagicLinkExpiredError: any;

  clientUserInformationLoading: boolean;
  clientUserInformation: any;
  clientUserInformationError: any;

  customerInformationLoading: boolean;
  customerInformation: any;
  customerInformationError: any;
}

const initialState: authType = {
  results: {},
  status: requestState.idle,
  error: null,

  userObjIsLoading: requestState.idle,
  userObj: {},
  userObjError: {},

  projectWithTasksLoading: false,
  projectWithTasks: {},
  projectWithTasksError: {},

  projectUpgradationLoading: false,
  projectUpgradation: {},
  projectUpgradationError: {},

  useCaseUpgradationLoading: false,
  useCaseUpgradation: {},
  useCaseError: {},

  projectDegradationLoading: false,
  projectDegradation: {},
  projectDegradationError: {},

  useCaseDegradationLoading: false,
  useCaseDegradation: {},
  useCaseDegradationError: {},

  projectStatusCountLoading: false,
  projectStatusCount: {},
  projectStatusCountError: {},

  taskStatusCountLoading: false,
  taskStatusCount: {},
  taskStatusCountError: {},

  useCaseStatusCountLoading: false,
  useCaseStatusCount: {},
  useCaseStatusCountError: {},

  signleProjectInformationLoading: false,
  signleProjectInformation: {},
  signleProjectInformationError: {},

  signleUsecaseInformationLoading: false,
  signleUsecaseInformation: {},
  signleUsecaseInformationError: {},

  allCompanyUsersLoading: false,
  allCompanyUsers: [],
  allCompanyUsersError: {},

  allClientUsersLoading: false,
  allClientUsersUsers: [],
  allClientUsersUsersError: {},

  createdTaskUidLoading: false,
  createdTaskUid: '',
  createdTaskUidError: '',

  isMagicLinkExpiredLoading: false,
  isMagicLinkExpired: '',
  isMagicLinkExpiredError: {},

  clientUserInformationLoading: false,
  clientUserInformation: {},
  clientUserInformationError: {},

  customerInformationLoading: false,
  customerInformation: {},
  customerInformationError: {},
};

export const clientCheckClient = createAsyncThunk('clientCheckClient', async (client_name: string) => {
  const { data } = await customerPortalApi.clientCheckClient(client_name);
  return data;
});

export const client_userSignIn_withjwttoken = createAsyncThunk(
  'client_userSignIn_withjwttoken',
  async (magic_jwt_token: string) => {
    const { data } = await customerPortalApi.client_userSignIn_withjwttoken(magic_jwt_token);
    return data;
  }
);

export const client_getCustomerDetail = createAsyncThunk('client_getCustomerDetail', async () => {
  const { data } = await customerPortalApi.client_getCustomerDetail();
  return data;
});

export const client_getProjectsWithTasks = createAsyncThunk(
  'client_getProjectsWithTasks',
  async (client_uid: string) => {
    const { data } = await customerPortalApi.client_getProjectsWithTasks(client_uid);
    return data;
  }
);

export const client_getProjectUpgradationAlert = createAsyncThunk(
  'client_getProjectUpgradationAlert',
  async (client_uid: string) => {
    const { data } = await customerPortalApi.client_getProjectUpgradationAlert(client_uid);
    return data;
  }
);

export const client_getUsecaseUpgradationAlert = createAsyncThunk(
  'client_getUsecaseUpgradationAlert',
  async (client_uid: string) => {
    const { data } = await customerPortalApi.client_getUsecaseUpgradationAlert(client_uid);
    return data;
  }
);

export const client_getProjectDegradationAlert = createAsyncThunk(
  'client_getProjectDegradationAlert',
  async (client_uid: string) => {
    const { data } = await customerPortalApi.client_getProjectDegradationAlert(client_uid);
    return data;
  }
);

export const client_getUsecaseDegradationAlert = createAsyncThunk(
  'client_getUsecaseDegradationAlert',
  async (client_uid: string) => {
    const { data } = await customerPortalApi.client_getUsecaseDegradationAlert(client_uid);
    return data;
  }
);

export const client_getProjectStatusCountByClientUid = createAsyncThunk(
  'client_getProjectStatusCountByClientUid',
  async (client_uid: string) => {
    const { data } = await customerPortalApi.client_getProjectStatusCountByClientUid(client_uid);
    return data;
  }
);

export const client_getTaskStatusCountByClientUid = createAsyncThunk(
  'client_getTaskStatusCountByClientUid',
  async (client_uid: string) => {
    const { data } = await customerPortalApi.client_getTaskStatusCountByClientUid(client_uid);
    return data;
  }
);

export const client_getUseCaseStatusCount = createAsyncThunk('client_getUseCaseStatusCount', async (reqObj: any) => {
  const { data } = await customerPortalApi.client_getUseCaseStatusCount(reqObj);
  return data;
});

export const client_getsingleProjectInformationWithTaskClientUser = createAsyncThunk(
  'client_getsingleProjectInformationWithTaskClientUser',
  async (project_uid: any) => {
    const { data } = await customerPortalApi.client_getsingleProjectInformationWithTaskClientUser(project_uid);
    return data;
  }
);

export const client_getsingleUsecaseInformationWithTaskClientUser = createAsyncThunk(
  'client_getsingleUsecaseInformationWithTaskClientUser',
  async (usecase_uid: any) => {
    const { data } = await customerPortalApi.client_getsingleUsecaseInformationWithTaskClientUser(usecase_uid);
    return data;
  }
);

export const getAllUsers = createAsyncThunk('commin/getAllUsers', async (customer_uid: any) => {
  const { data } = await customerPortalApi.getCompanyUsers(customer_uid);
  console.log(data);
  return data.result ?? [];
});

export const getClientContactsByContactUid = createAsyncThunk(
  'commin/getClientContactsByContactUid',
  async (contact_uid: any) => {
    const { data } = await customerPortalApi.getClientContactsByContactUid(contact_uid);
    console.log(data);
    return data.result;
  }
);

export const client_getClientContactsByClientUid = createAsyncThunk(
  'commin/client_getClientContactsByClientUid',
  async (contact_uid: any) => {
    const { data } = await customerPortalApi.client_getClientContactsByClientUid(contact_uid);
    console.log(data);
    return data.result;
  }
);

export const client_addProjectTask = createAsyncThunk('commin/client_addProjectTask', async (reqObj: any) => {
  const { data } = await customerPortalApi.client_addProjectTask(reqObj);
  console.log(data);
  return data.result;
});

export const client_addUsecaseTask = createAsyncThunk('commin/client_addUsecaseTask', async (reqObj: any) => {
  const { data } = await customerPortalApi.client_addUsecaseTask(reqObj);
  console.log(data);
  return data.result;
});

export const client_updateProjecttaskbyuid = createAsyncThunk(
  'commin/client_updateProjecttaskbyuid',
  async (reqObj: any) => {
    const { data } = await customerPortalApi.client_updateProjecttaskbyuid(reqObj);
    console.log(data);
    return data.result;
  }
);

export const client_updateUsecasetaskbyuid = createAsyncThunk(
  'commin/client_updateUsecasetaskbyuid',
  async (reqObj: any) => {
    const { data } = await customerPortalApi.client_updateUsecasetaskbyuid(reqObj);
    console.log(data);
    return data.result;
  }
);

export const client_addProjectTaskAuditTrail = createAsyncThunk(
  'commin/client_addProjectTaskAuditTrail',
  async (reqObj: any) => {
    const { data } = await customerPortalApi.client_addProjectTaskAuditTrail(reqObj);
    console.log(data);
    return data.result;
  }
);

export const client_sendmagiclink = createAsyncThunk('commin/client_sendmagiclink', async (reqObj: any) => {
  const { data } = await customerPortalApi.client_sendmagiclink(reqObj);
  console.log(data);
  return data.result;
});
export const client_getClientinformationapi = createAsyncThunk(
  'commin/client_getClientinformationapi',
  async (client_uid: string) => {
    const { data } = await customerPortalApi.client_getClientinformationapi(client_uid);
    console.log(data);
    return data.result;
  }
);

export const client_getTaskByTaskUid = createAsyncThunk('customerp[ortal/', async (task_uid: string) => {
  const { data } = await customerPortalApi.client_getTaskByTaskUid(task_uid);
  return data.result;
});

export const client_updateTaskAcknowledgedStatus = createAsyncThunk(
  'client_updateTaskAcknowledgedStatus',
  async (reqObj: any) => {
    const { data } = await customerPortalApi.client_updateTaskAcknowledgedStatus(reqObj);
    console.log(data);
    return data.result;
  }
);

const customerPortalSlice = createSlice({
  name: 'customerPortal',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(clientCheckClient.pending, (state) => {
        state.status = requestState.loading;
      })
      .addCase(clientCheckClient.fulfilled, (state: authType, { payload }) => {
        console.log(payload);
        state.customerInformation = payload.result;
      })
      .addCase(clientCheckClient.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      })
      .addCase(client_userSignIn_withjwttoken.pending, (state) => {
        state.status = requestState.loading;
        state.userObjIsLoading = requestState.loading;
      })
      .addCase(client_userSignIn_withjwttoken.fulfilled, (state: authType, { payload }) => {
        if (payload.message == 'Magic Link Expired') state.isMagicLinkExpired = payload.message;
        state.userObj = payload.result;
        state.userObjIsLoading = requestState.success;
      })
      .addCase(client_userSignIn_withjwttoken.rejected, (state, action) => {
        state.status = requestState.failed;
        state.userObjIsLoading = requestState.failed;
        state.error = action.error.message;
      })
      .addCase(client_getProjectsWithTasks.pending, (state) => {
        state.status = requestState.loading;
      })
      .addCase(client_getProjectsWithTasks.fulfilled, (state: authType, { payload }) => {
        console.log(payload);
        state.projectWithTasks = payload.result;
      })
      .addCase(client_getProjectsWithTasks.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      })
      .addCase(client_getProjectUpgradationAlert.pending, (state) => {
        state.status = requestState.loading;
      })
      .addCase(client_getProjectUpgradationAlert.fulfilled, (state: authType, { payload }) => {
        console.log(payload);
        state.projectUpgradation = payload.result;
      })
      .addCase(client_getProjectUpgradationAlert.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      })
      .addCase(client_getUsecaseUpgradationAlert.pending, (state) => {
        state.status = requestState.loading;
      })
      .addCase(client_getUsecaseUpgradationAlert.fulfilled, (state: authType, { payload }) => {
        console.log(payload);
        state.useCaseUpgradation = payload.result;
      })
      .addCase(client_getUsecaseUpgradationAlert.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      })
      .addCase(client_getProjectDegradationAlert.pending, (state) => {
        state.status = requestState.loading;
      })
      .addCase(client_getProjectDegradationAlert.fulfilled, (state: authType, { payload }) => {
        console.log(payload);
        state.projectDegradation = payload.result;
      })
      .addCase(client_getProjectDegradationAlert.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      })
      .addCase(client_getUsecaseDegradationAlert.pending, (state) => {
        state.status = requestState.loading;
      })
      .addCase(client_getUsecaseDegradationAlert.fulfilled, (state: authType, { payload }) => {
        console.log(payload);
        state.useCaseDegradation = payload.result;
      })
      .addCase(client_getUsecaseDegradationAlert.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      })
      .addCase(client_getProjectStatusCountByClientUid.pending, (state) => {
        state.status = requestState.loading;
      })
      .addCase(client_getProjectStatusCountByClientUid.fulfilled, (state: authType, { payload }) => {
        console.log(payload);
        state.projectStatusCount = payload.result;
      })
      .addCase(client_getProjectStatusCountByClientUid.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      })
      .addCase(client_getTaskStatusCountByClientUid.pending, (state) => {
        state.status = requestState.loading;
      })
      .addCase(client_getTaskStatusCountByClientUid.fulfilled, (state: authType, { payload }) => {
        console.log(payload);
        state.taskStatusCount = payload.result;
      })
      .addCase(client_getTaskStatusCountByClientUid.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      })
      .addCase(client_getUseCaseStatusCount.pending, (state) => {
        state.status = requestState.loading;
      })
      .addCase(client_getUseCaseStatusCount.fulfilled, (state: authType, { payload }) => {
        console.log(payload);
        state.useCaseStatusCount = payload.result;
      })
      .addCase(client_getUseCaseStatusCount.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      })
      .addCase(client_getsingleProjectInformationWithTaskClientUser.pending, (state) => {
        state.status = requestState.loading;
      })
      .addCase(client_getsingleProjectInformationWithTaskClientUser.fulfilled, (state: authType, { payload }) => {
        console.log(payload);
        state.signleProjectInformation = payload.result;
      })
      .addCase(client_getsingleProjectInformationWithTaskClientUser.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      })
      .addCase(client_getsingleUsecaseInformationWithTaskClientUser.pending, (state) => {
        state.status = requestState.loading;
      })
      .addCase(client_getsingleUsecaseInformationWithTaskClientUser.fulfilled, (state: authType, { payload }) => {
        console.log(payload);
        state.signleUsecaseInformation = payload.result;
      })
      .addCase(client_getsingleUsecaseInformationWithTaskClientUser.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      })
      .addCase(getAllUsers.pending, (state) => {
        state.status = requestState.loading;
      })
      .addCase(getAllUsers.fulfilled, (state: authType, { payload }) => {
        console.log(payload);
        state.allCompanyUsers = payload;
      })
      .addCase(getAllUsers.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      })
      .addCase(client_getClientContactsByClientUid.pending, (state) => {
        state.status = requestState.loading;
      })
      .addCase(client_getClientContactsByClientUid.fulfilled, (state: authType, { payload }) => {
        console.log(payload);
        state.allClientUsersUsers = payload;
      })
      .addCase(client_getClientContactsByClientUid.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      })
      .addCase(client_addProjectTask.pending, (state) => {
        state.status = requestState.loading;
      })
      .addCase(client_addProjectTask.fulfilled, (state: authType, { payload }) => {
        console.log(payload);
        state.createdTaskUid = payload;
      })
      .addCase(client_addProjectTask.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      })
      .addCase(client_getClientinformationapi.pending, (state) => {
        state.status = requestState.loading;
      })
      .addCase(client_getClientinformationapi.fulfilled, (state: authType, { payload }) => {
        console.log(payload);
        state.clientUserInformation = payload;
      })
      .addCase(client_getClientinformationapi.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      });
  },
});

const customerPortal = customerPortalSlice.reducer;
export default customerPortal;
