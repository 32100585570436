import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Stack, Grid, Typography, SelectChangeEvent } from '@mui/material';

import { useAppSelector } from 'store/hooks';
import {
  getAllResources_selector,
  getGeneralFieldValues_selector,
  getResourcesFieldValues_selector,
  getResourcesField_selector,
} from 'store/modules/RBAC/selector';
import {
  getAllResources_slice,
  getGeneralFieldValue_slice,
  getPolicyOnResources_slice,
  getResourcesField_slice,
} from 'store/modules/RBAC/slice';
import CognisaasDropdown from 'components/shared/CognisaasDropdown/CognisaasDropdown';
import ConditionDetail from 'components/RBACv2/shared/ConditionDetail';
import { operatorType } from 'components/RBACv2/types';
import { operatorMapping } from 'components/RBACv2/utils/filters';
import { ConditionProps } from 'components/RBACv2/interfaces';

export default function Condition(props: ConditionProps) {
  const [resourcesDropdown, setResourcesDropdown] = useState<string>('');
  const [allResources, setAllResources] = useState<string[]>([]);
  const [resourceField, setResourceField] = useState<string>('');
  const [operator, setOperator] = useState<operatorType>('' as operatorType);

  const getAllResources = useAppSelector(getAllResources_selector).data;
  const getResourcesField = useAppSelector(getResourcesField_selector).data;
  const getResourcesFieldValues = useAppSelector(getResourcesFieldValues_selector).data;
  const getGeneralFieldValues = useAppSelector(getGeneralFieldValues_selector).data;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllResources_slice());
    dispatch(getGeneralFieldValue_slice());
    setOperator(operatorMapping[props.selectedCondition?.operator as keyof typeof operatorMapping] as operatorType);
  }, []);

  useEffect(() => {
    const temp = getAllResources?.map((obj: any) => {
      return obj.display_name;
    });
    const resourceOnCondition = getAllResources.find((resource:any) => resource.id === props.selectedCondition.resource);
    setAllResources(temp);
    setResourcesDropdown(resourceOnCondition.display_name);
  }, [getAllResources]);

  useEffect(() => {
    const resourceFieldId = getAllResources?.filter((obj: any) => {
      return obj.display_name === resourcesDropdown;
    });
    if (resourceFieldId.length > 0) {
      dispatch(getPolicyOnResources_slice({ resource: resourceFieldId[0]?.id }));
      dispatch(getResourcesField_slice({ resource: resourceFieldId[0]?.id || '' }));
    }
  }, [resourcesDropdown]);

  return (
    <Stack>
      <Grid sx={{ marginTop: '25px' }}>
        <Typography variant={'h2'}>Resources</Typography>
        <CognisaasDropdown
          sx={{ marginTop: '10px' }}
          name="resources-dropdown"
          type="dropdown"
          validvalues={allResources}
          onChange={(e: SelectChangeEvent<unknown | string>) => setResourcesDropdown(e.target.value as string)}
          value={resourcesDropdown}
          readOnly={props?.selectedCondition?.is_public || false}
        />
      </Grid>
      <Grid sx={{ marginTop: '20px' }}>
        <ConditionDetail
          conditionData={props.selectedCondition}
          resourceName={resourcesDropdown}
          resourcesFieldObject={getResourcesField}
          selectedPermission={props.selectedCondition}
          selectedOperator={operator}
          setSelectedOperator={setOperator}
          resourceField={resourceField}
          setResourceField={setResourceField}
          conditionResourceGeneralFieldValue={getGeneralFieldValues}
          conditionResourceFieldValue={getResourcesFieldValues}
        />
      </Grid>
    </Stack>
  );
}
