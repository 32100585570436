import styled, { ThemeProvider } from 'styled-components';
import { ModalProvider, BaseModalBackground } from 'styled-react-modal';

import { resourceManagementTheme } from './design-system/theme';
import { GridBox, Box, Text, FlexBox } from './design-system/primitives';
// import { StyledIcon } from './design-system/primitives/icon';
import { useResourceManagement } from './useResourceManagement';
import CognisaasLoader from 'components/shared/CognisaasLoader/CognisaasLoader';
import ErrorPage from '../shared/Error/Error';
// import filterIconPath from 'assets/svgs/filter.svg';
// import searchIconPath from 'assets/svgs/search.svg';
// import { ElementWithAdornment } from './design-system/elements/ElementWithAdornment';
// import { InputHtml } from './design-system/elements/InputHtml';
import { ResourceGantt } from './components/resource-gantt';
import { ViewSwitcher } from './components/ViewSwitcher';
// import { DateSwitcher } from './components/DateSwitcher';
// import UpdateTask from './components/Task/UpdateTask';
import SoftAllocationFormDrawer from './components/soft-allocation';
import { UpdateTask } from './components/update-task';
import { ProjectView } from './views/ProjectView/project-view';
import { label_project } from 'components/Settings/Preferences/CustomLabelCookieHelper';

const StyledLegendsGrid = styled(GridBox)(({ theme }) => {
  return {
    justifyContent: 'flex-end',
    alignItems: 'center',
    gridTemplateColumns: 'repeat(2, 180px) 80px repeat(2, 80px)',
    gridTemplateRows: `${theme.space[21]}`,
    marginTop: `${theme.space[10]}`,
  };
});
const StyledColorLegend = styled(Box)(({ theme }) => {
  return {
    height: `${theme.space[7]}`,
    width: `${theme.space[7]}`,
    borderRadius: `${theme.radii[2]}`,
    marginRight: `${theme.space[3]}`,
  };
});

const StyledSwitcherGrid = styled(GridBox)(({ theme }) => {
  return {
    justifyContent: 'space-between',
    alignItems: 'center',
    gridTemplateColumns: '325px 240px',
    gridTemplateRows: `${theme.space[22]}`,
    backgroundColor: `${theme.colors.grey_5}`,
    padding: `0px ${theme.space[7]}`,
    border: `${theme.borders[3]}`,
    borderTopLeftRadius: `${theme.space[2]}`,
    borderTopRightRadius: `${theme.space[2]}`,
    borderBottom: `${theme.borders[5]}`,
  };
});

const StyledRootGrid = styled(GridBox)(({ theme }) => {
  return {
    justifyContent: 'center',
    alignItems: 'center',
    gridTemplateColumns: '100%',
    borderWidth: `${theme.space[1]}`,
    border: `${theme.borders[3]}`,
  };
});

export const ResourceManagement = () => {
  const resourceManagementHeading = 'Resource Management';
  const occupancyText = 'Occupancy:';
  const softLeaveAllocationText = 'Soft leave allocation';
  const softProjectAllocationText = `Soft ${label_project.toLowerCase()} allocation`;
  const lessThan100Text = '< 100%';
  const moreThan100Text = '> 100%';
  // const searchText = 'Search';
  const {
    viewMode,
    primeComponents,
    isError,
    isLoading,
    mode,
    mainViewEventsForDateRange,
    handleSelect,
    handleExpanderClick,
  } = useResourceManagement();

  if (isError) {
    return <ErrorPage />;
  }

  if (isLoading) {
    return <CognisaasLoader />;
  }

  return (
    <ThemeProvider theme={resourceManagementTheme}>
      <ModalProvider backgroundComponent={BaseModalBackground}>
        <Box pr={5} p={12} bg="additional_4" id="resource-management">
          <Box>
            <GridBox justifyContent="center" alignItems="center" gridTemplateColumns="2fr 300px 40px">
              <Text variant="heading1" style={{ fontWeight: 500 }}>
                {resourceManagementHeading}
              </Text>
              {/* <ElementWithAdornment
                                main={<InputHtml
                                    id="resource-mgmt-search"
                                    name="resource-mgmt-search"
                                    type="text"
                                    placeholder={searchText}
                                />}
                                after={<StyledIcon src={searchIconPath} />}
                            />
                            <StyledIcon src={filterIconPath} /> */}
              <span></span>
              <span></span>
            </GridBox>
            <StyledLegendsGrid>
              <FlexBox variant="flexRow">
                <StyledColorLegend bg="pastel_2" />
                <Text variant="paragraph1">{softProjectAllocationText}</Text>
              </FlexBox>
              <FlexBox variant="flexRow">
                <StyledColorLegend bg="grey_3" />
                <Text variant="paragraph1">{softLeaveAllocationText}</Text>
              </FlexBox>
              <Text variant="heading3">{occupancyText}</Text>
              <FlexBox variant="flexRow">
                <StyledColorLegend bg="bragLite_4" />
                <Text variant="paragraph1">{lessThan100Text}</Text>
              </FlexBox>
              <FlexBox variant="flexRow">
                <StyledColorLegend bg="bragLite_2" />
                <Text variant="paragraph1">{moreThan100Text}</Text>
              </FlexBox>
            </StyledLegendsGrid>
            <StyledSwitcherGrid>
              {/* <DateSwitcher /> */}
              <span></span>
              <ViewSwitcher />
            </StyledSwitcherGrid>
            <StyledRootGrid id="resource-management-main-view">
              <ResourceGantt
                primeComponents={primeComponents}
                onSelect={handleSelect}
                onExpanderClick={handleExpanderClick}
                viewMode={viewMode}
                mode={mode}
                events={mainViewEventsForDateRange}
              />
            </StyledRootGrid>
          </Box>
        </Box>
        <UpdateTask />
        <SoftAllocationFormDrawer />
        <ProjectView />
      </ModalProvider>
    </ThemeProvider>
  );
};
