import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../';

export const dashboardSlice = (state: RootState) => state.dashboardState;

export const selectClientCount = createSelector(dashboardSlice, (dashboardSlice) => dashboardSlice.clientCount);
export const selectTeamClientCount = createSelector(dashboardSlice, (dashboardSlice) => dashboardSlice.teamClientCount);
export const selectUseCaseCount = createSelector(dashboardSlice, (dashboardSlice) => dashboardSlice.useCaseCount);
export const selectTeamUseCaseCount = createSelector(
  dashboardSlice,
  (dashboardSlice) => dashboardSlice.teamUseCaseCount
);
export const selectTaskStatus = createSelector(dashboardSlice, (dashboardSlice) => dashboardSlice.taskStatusCount);
export const selectTeamTaskStatus = createSelector(
  dashboardSlice,
  (dashboardSlice) => dashboardSlice.teamTaskStatusCount
);

export const selectProjectCount = createSelector(dashboardSlice, (dashboardSlice) => dashboardSlice.projectsCount);
export const selectTeamProjectCount = createSelector(
  dashboardSlice,
  (dashboardSlice) => dashboardSlice.teamProjectsCount
);
export const selectUserDetails = createSelector(dashboardSlice, (dashboardSlice) => dashboardSlice.userDetials);
export const selectDashboardStatus = createSelector(dashboardSlice, (dashboardSlice) => dashboardSlice.status);
export const selectClientUpgradationAlert = createSelector(
  dashboardSlice,
  (dashboardSlice) => dashboardSlice.ClientUpgradationAlert
);
export const selectTeamClientUpgradationAlert = createSelector(
  dashboardSlice,
  (dashboardSlice) => dashboardSlice.teamClientUpgradationAlert
);
export const selectProjectUpgradationAlert = createSelector(
  dashboardSlice,
  (dashboardSlice) => dashboardSlice.ProjectUpgradationAlert
);
export const selectTeamProjectUpgradationAlert = createSelector(
  dashboardSlice,
  (dashboardSlice) => dashboardSlice.teamProjectUpgradationAlert
);
export const selectUsecaseUpgradationAlert = createSelector(
  dashboardSlice,
  (dashboardSlice) => dashboardSlice.UsecaseUpgradationAlert
);
export const selectTeamUsecaseUpgradationAlert = createSelector(
  dashboardSlice,
  (dashboardSlice) => dashboardSlice.teamUsecaseUpgradationAlert
);
export const selectClientDegradationAlert = createSelector(
  dashboardSlice,
  (dashboardSlice) => dashboardSlice.ClientDegradationAlert
);
export const selectTeamClientDegradationAlert = createSelector(
  dashboardSlice,
  (dashboardSlice) => dashboardSlice.teamClientDegradationAlert
);
export const selectProjectDegradationAlert = createSelector(
  dashboardSlice,
  (dashboardSlice) => dashboardSlice.ProjectDegradationAlert
);
export const selectTeamProjectDegradationAlert = createSelector(
  dashboardSlice,
  (dashboardSlice) => dashboardSlice.teamProjectDegradationAlert
);
export const selectUsecaseDegradationAlert = createSelector(
  dashboardSlice,
  (dashboardSlice) => dashboardSlice.UsecaseDegradationAlert
);
export const selectTeamUsecaseDegradationAlert = createSelector(
  dashboardSlice,
  (dashboardSlice) => dashboardSlice.teamUsecaseDegradationAlert
);
export const selectAllTeamTasks = createSelector(dashboardSlice, (dashboardSlice) => dashboardSlice.allTeamTasks);
export const selectAllTeamTasksLoading = createSelector(
  dashboardSlice,
  (dashboardSlice) => dashboardSlice.allTeamTasksLoading
);
export const selectAllReportees = createSelector(dashboardSlice, (dashboardSlice) => dashboardSlice.allReportees);
export const selectAllTeamClients = createSelector(dashboardSlice, (dashboardSlice) => dashboardSlice.allTeamClients);
