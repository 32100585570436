import { Alert, Avatar, Box, Collapse, IconButton, Link, Paper, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import NewRaidDrawer from './NewRaidDrawer';
import {
  DataGridPremium,
  useGridApiRef,
  GridRenderCellParams,
  GridSelectionModel,
  useGridApiContext,
  GridRenderEditCellParams,
} from '@mui/x-data-grid-premium';
import { selectProjectInformation } from '../../../../store/modules/Project/selector';
import { raidApi } from '../../../../api/modules/raid';
import { default as AvatarImage } from '../../../shared/Avatar/Avatar';
import { getAllUsers, updateCaseWithfeatureStatus } from 'store/modules/Common/slice';

import {
  getUserLogoLinkByUid,
} from '../../../../utils/getUid';
import { getUserActiveState, INACTIVE_USER_COLOR } from 'utils/userHelpers';
import CognisaasDropdown from 'components/shared/CognisaasDropdown/CognisaasDropdown';
import { ReactComponent as EditSVG } from '../../../../assets/svgs/TableEditButton.svg';
import { SelectChangeEvent } from '@mui/material/Select';
import { handleInlineEditClickType, inlineEditPropType } from 'components/shared/Tables/types';
import format from 'date-fns/format';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import CognisaasButton from 'components/shared/CognisaasButton/CognisaasButton';
import { getMaxColumnWidth } from 'utils/getMaxColumnWidth';
import ViewDate from 'components/shared/ViewDate/ViewDate';
import CloseIcon from '@mui/icons-material/Close';
import DeleteModal from 'components/shared/CognisaasModal/DeleteModal';
import { SelectEditDateInputCell } from 'components/shared/Tables/InlineEditTable';
import UpdateRaidDrawer from './UpdateRaidDrawer';
import CognisaasToast from 'components/shared/CognisaasToast/CognisaasToast';
import CognisaasLoader from 'components/shared/CognisaasLoader/CognisaasLoader';
import { selectGetRAIDByProjectId, selectGetRAIDByProjectIdStatus } from 'store/modules/RAID/selector';
import { getRaidByProjectID } from 'store/modules/RAID/slice';
import { requestState } from 'store/modules/types';
import { isUndefined } from 'utils/dataHelpers';
import CognisaaasTooltip from 'components/shared/CognisaasTooltip/CognisaasTooltip';
import { formatDistance } from 'date-fns';
import { usePreselectedProjectMembers } from 'components/shared/Hooks/usePreselectedProjectMembers';
import { getProjectInformation } from 'store/modules/Project/slice';
import CognisaasTaskStageIndicator, { raidStage, taskStage } from 'components/shared/CognisaasTaskStageIndicator/CognisaasTaskStageIndicator';

// const raidTypes = ['Risk', 'Issue', 'Assumption', 'Dependency'];
function stringToHslColor(str: string, s: number, l: number) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const h = hash % 360;
  return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
}
function stringAvatar(name: string) {
  if (typeof name === 'undefined') return <></>;
  return {
    sx: {
      bgcolor: stringToHslColor(name, 30, 80),
    },
    children: (
      <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#000000' }}>
        {name.split(' ')[0][0]}
        {name.indexOf(' ') >= 0 ? name.split(' ')[1][0] : null}
      </Typography>
    ),
  };
}


const RAIDTableView = () => {
  const [addTaskDrawer, setAddTaskDrawer] = useState(false);
  const [updateRaidDrawer, setUpdateRaidDrawer] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [raidSelected, setRaidSelected] = useState<GridSelectionModel>([]);
  const [openDeleteRaids, setOpenDeleteRaids] = useState<boolean>(false);
  const projectInfo = useAppSelector(selectProjectInformation);
  const phaseOptions = projectInfo.section.map((phase: any) => ({ id: phase.section_uid, name: phase.section_detail }));
  const { preSelectedCombinedMembers } = usePreselectedProjectMembers(projectInfo.project_uid, projectInfo?.client_uid);
  const preSelectedCombinedMembersWithNotAssigned = preSelectedCombinedMembers?.length
    ? [...preSelectedCombinedMembers, { name: 'Not Assigned', id: '15243' }]
    : [{ name: 'Not Assigned', id: '15243' }];
  // const users = preSelectedCombinedMembers;
  const notAssignedStr = 'Not Assigned';
  const preselectedProjectMembersNameListWithNotAssigned = preSelectedCombinedMembers?.length
    ? [
      ...preSelectedCombinedMembers.filter((user: any) => user.is_active === '1').map((item: any) => item.name),
      notAssignedStr,
    ]
    : [notAssignedStr];
  // const [usersOfCompany, setUsersOfCompany] = useState<any[]>([]);
  const [raidClicked, setRaidClicked] = useState<any>(null);
  const dispatch = useAppDispatch();

  const raidTasks = useAppSelector(selectGetRAIDByProjectId);
  const getRaidTaskStatus = useAppSelector(selectGetRAIDByProjectIdStatus);
  const isLoading = getRaidTaskStatus === requestState.loading;
  const apiRef = useGridApiRef();

  useEffect(() => {
    dispatch(getAllUsers());
  }, []);

  const [alertDetails, setAlertDetails] = useState<{
    isVisible: boolean;
    type: 'success' | 'failure';
    message: string;
  }>({ isVisible: false, type: 'success', message: '' });
  const fetchInitialRaidInfo = async () => {
    dispatch(updateCaseWithfeatureStatus(projectInfo.client_uid));
    await dispatch(getRaidByProjectID(projectInfo?.project_uid));
  };
  useEffect(() => {
    if (projectInfo)
      fetchInitialRaidInfo();
  }, [projectInfo]);


  const SelectEditDropdownCell = (
    params: any,
    dropdownValues: string[],
    handleOnClick: handleInlineEditClickType,
    type?: inlineEditPropType
  ) => {
    const dropdownOptions = dropdownValues;
    const apiRef = useGridApiContext();
    const { id, field, value } = params;
    const dropdownValue = preSelectedCombinedMembersWithNotAssigned.find((item: any) => item.user_uid === value)?.name || notAssignedStr;
    const handleChange = (value: string) => {
      const uid = preSelectedCombinedMembersWithNotAssigned.find((item: any) => item.name === value)?.id;
      apiRef.current.setEditCellValue({ id, field, value: uid });
    };

    const handleClick = async () => {
      console.log('params2;;');
      await handleOnClick(params, field, value, type);
      apiRef.current.stopCellEditMode({ id, field });
    };

    return (
      <>
        <div style={{ padding: '0 4px', display: 'flex', alignItems: 'center', width: 'inherit' }}>
          <CognisaasDropdown
            validvalues={dropdownOptions}
            onChange={(event: SelectChangeEvent<string | unknown>) => handleChange(event.target.value as string)}
            value={dropdownValue}
            placeholder="Not Selected"
          />
          <button
            style={{
              border: '1px solid #4F9F52',
              background: '#BDF2BF',
              width: '40px',
              borderRadius: '0px 5px 5px 0px',
              height: '40px',
              marginLeft: '-1px',
              cursor: 'pointer',
            }}
            onClick={handleClick}
          >
            <EditSVG />
          </button>
        </div>
      </>
    );
  };

  const editProjectPhase = (params: any, dropdownOptions: any) => {
    const apiRef = useGridApiContext();
    const { id, field, value } = params;
    const valueName = phaseOptions.find((item: any) => item.id === value)?.name;
    const handleChange = async (event: SelectChangeEvent<unknown>) => {
      const uid = phaseOptions.find((item: any) => item.name === event.target.value)?.id;
      apiRef.current.setEditCellValue({ id, field, value: uid as string });
      await handleInlineEditValue(params, field, uid as string);
    };
    return (
      <>
        <>
          <div style={{ padding: '0 4px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CognisaasDropdown
              validvalues={dropdownOptions}
              onChange={(event: SelectChangeEvent<string | unknown>) => handleChange(event)}
              value={valueName}
              placeholder="Not Selected"
            />
          </div>
        </>
      </>
    );
  };

  const editCellRaidStage = (params: any, dropdownOptions: string[]) => {
    const apiRef = useGridApiContext();
    const { id, field, value } = params;
    const handleChange = async (event: SelectChangeEvent<unknown>) => {
      apiRef.current.setEditCellValue({ id, field, value: event.target.value as string });
      await handleInlineEditValue(params, field, event.target.value as string);
    };

    return (
      (params.row.type != 'Assumption' &&
        <>
          <>
            <div style={{ padding: '0 4px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <CognisaasDropdown
                validvalues={dropdownOptions}
                onChange={(event: SelectChangeEvent<string | unknown>) => handleChange(event)}
                value={value}
                placeholder="Not Selected"
              />
            </div>
          </>
        </>
      )
    );
  };

  const handleInlineEditValue = async (
    params: GridRenderEditCellParams,
    editableField: string,
    editableValue: string
  ) => {
    const taskPayload = {
      ...params.row,
      due_date: format(new Date(params.row.due_date), 'yyyy-MM-dd HH:mm:ss'),
      [editableField]: editableValue,
    };
    const res = await raidApi.updateRaid(taskPayload);
    console.log('res', res);
    if (res?.data?.status === 0) {
      setAlertDetails({
        isVisible: true,
        type: 'failure',
        message: res?.data?.message,
      });
    } else {
      setAlertDetails({
        isVisible: true,
        type: 'success',
        message: 'RAID updated successfully',
      });
      await dispatch(updateCaseWithfeatureStatus(projectInfo.client_uid));
      const detail = {
        project_uid: projectInfo.project_uid,
        association_uid: projectInfo.association_uid,
      };
      await dispatch(getProjectInformation(detail));
      await dispatch(getRaidByProjectID(projectInfo?.project_uid));
    }
  };

  const generateCommentUi = (comment: string) => {
    const splitForUsers = comment.split('@[');
    return splitForUsers.map((item, index) => {
      if (index === 0) {
        return <span key={index}>{item}</span>;
      } else {
        // index more than 0 something like this expected => vivek](customer:324234) great
        const strContainingUser = item;
        const arrContainingUser = strContainingUser.split('](');
        const username = arrContainingUser[0];
        const restStrArr = arrContainingUser[1].split(')');
        const customerUid = restStrArr[0];
        const restString = [...restStrArr.filter((item, index) => index > 0)].join('');
        return (
          <span key={index}>
            &nbsp;
            <span
              style={{
                backgroundColor: 'rgb(45, 96, 246, 0.1)',
                color: '#2D60F6',
                padding: '2px 10px',
                borderRadius: '33px',
                fontSize: '13px',
                cursor: 'pointer',
              }}
              id={customerUid}
            >
              {username}
            </span>
            {restString}
          </span>
        );
      }
    });
  };

  const generateCommentText = (comment: string): string => {
    const splitForUsers = comment.split('@[');
    const finalCommentStrArr = splitForUsers.map((item, index) => {
      if (index === 0) {
        return item;
      } else {
        const strContainingUser = item;
        const arrContainingUser = strContainingUser.split('](');
        const username = arrContainingUser[0];
        const restStrArr = arrContainingUser[1].split(')');
        const restString = [...restStrArr.filter((item, index) => index > 0)].join('');
        return `${username} ${restString}`;
      }
    });
    return finalCommentStrArr.join(' ');
  };

  const bulkDeleteRaid = async () => {
    const raidUids = raidSelected;
    const res = await raidApi.bulkDeleteRaid({ raid_uids: raidUids });
    if (res?.data?.status === 0) {
      setAlertDetails({
        isVisible: true,
        type: 'failure',
        message: res?.data?.message,
      });
    } else {
      setAlertDetails({
        isVisible: true,
        type: 'success',
        message: 'RAID deleted successfully',
      });
      setRaidSelected([]);
      await dispatch(updateCaseWithfeatureStatus(projectInfo.client_uid));
      const detail = {
        project_uid: projectInfo.project_uid,
        association_uid: projectInfo.association_uid,
      };
      await dispatch(getProjectInformation(detail));
      await dispatch(getRaidByProjectID(projectInfo?.project_uid));
    }
  };


  const columns = [
    // {
    //   field: 'raid_uid',
    //   hide: true,
    // },
    {
      field: 'name',
      headerName: 'Name',
      width: 160,
      renderCell: (params: GridRenderCellParams) => {
        return (<Typography
          onClick={() => {
            setRaidClicked(params.row);
            setUpdateRaidDrawer(!updateRaidDrawer);
          }}
          className="cognisaas-pointer"
          variant="subtitle1"
          sx={{ color: '#093BCD', fontWeight: 400, fontSize: '14px' }}
        >
          {params.row.name}
        </Typography>);
      },
    },
    {
      field: 'owner_uid',
      headerName: 'Owner',
      width: getMaxColumnWidth(preselectedProjectMembersNameListWithNotAssigned),
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams) =>
        SelectEditDropdownCell(params, preselectedProjectMembersNameListWithNotAssigned, handleInlineEditValue),
      renderCell: (params: GridRenderCellParams) => {
        const ownerLogoLink = params.value !== '15243' ? getUserLogoLinkByUid(params.row.owner_uid) : '';
        const name = preSelectedCombinedMembersWithNotAssigned.find((item) => item.id === params.row.owner_uid)?.name || 'Not Assigned';
        return (
          <>
            {ownerLogoLink?.length > 0 ? (
              <AvatarImage src={ownerLogoLink} alt="owner-logo" height="34px" width="34px" />
            ) : (
              <Avatar
                style={{ height: '34px', width: '34px' }}
                {...stringAvatar(
                  name
                )}
              />
            )}
            {getUserActiveState(params.row.owner_uid, preselectedProjectMembersNameListWithNotAssigned) === '0' ? (
              <Typography
                style={{ marginTop: '4px', marginLeft: '8px', color: INACTIVE_USER_COLOR }}
                variant="subtitle1"
              >
                {name}&nbsp;(Inactive)
              </Typography>
            ) : (
              <Typography style={{ marginTop: '4px', marginLeft: '8px' }} variant="subtitle1">
                {name}
              </Typography>
            )}
          </>
        );
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 200,
      editable: false,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Stack>
            <Stack
              spacing={1}
              direction="row"
              sx={{ fontWeight: 400, fontSize: '14px', display: 'flex', alignItems: 'center' }}
            >
              <span
                style={{ marginRight: '4px' }}
                className={
                  params.row.status == 'red'
                    ? 'red_status_box_task'
                    : params.row.status == 'amber'
                      ? 'amber_status_box_task'
                      : params.row.status == 'green'
                        ? 'green_status_box_task'
                        : params.row.status == 'blue'
                          ? 'blue_status_box_task'
                          : params.row.status == 'grey'
                            ? 'grey_status_box_task'
                            : ''
                }
              ></span>
              <span>
                {params.row.status == 'red'
                  ? 'Overdue'
                  : params.row.status == 'amber'
                    ? 'At Risk'
                    : params.row.status == 'green'
                      ? 'On Track'
                      : params.row.status == 'blue'
                        ? 'Completed'
                        : params.row.status == 'grey'
                          ? 'Deferred'
                          : '--'}
              </span>
            </Stack>
          </Stack>
        );
      },
    },
    {
      field: 'stage',
      headerName: 'Stage',
      width: 200,
      editable: true,
      renderEditCell: (params: any) => editCellRaidStage(params, ['Not Mitigated', 'Mitigated']),
      renderCell: (params: GridRenderCellParams) => (
        <Stack>
          <Stack
            spacing={1}
            direction="row"
            sx={{ fontWeight: 500, fontSize: '12px', display: 'flex', alignItems: 'center', flexDirection: 'row' }}
          >
            {params.row.type !== 'Assumption' && (
              <>
                <span style={{ marginRight: '4px' }}>
                  {params.row.stage === raidStage.NOT_MITIGATED && (
                    <CognisaasTaskStageIndicator
                      isMileStoneTask={false}
                      stage={taskStage.IN_PROGRESS}
                    />
                  )}
                  {params.row.stage === raidStage.MITIGATED && (
                    <CognisaasTaskStageIndicator
                      isMileStoneTask={false}
                      stage={taskStage.DONE}
                    />
                  )}
                </span>
                <span>{params.row.stage}</span>
              </>
            )}
            {params.row.type === 'Assumption' && (
              <Stack
              spacing={1}
              direction="row"
              sx={{ fontWeight: 400, fontSize: '14px', display: 'flex', alignItems: 'center' }}
            >
              <span></span>
              <span>--</span>
              </Stack>
            )}
          </Stack>
        </Stack>
      ),
    },

    {
      field: 'section_uid',
      headerName: 'Project Phase',
      width: 200,
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams) => editProjectPhase(params, phaseOptions.map((item: any) => item.name)),
      renderCell: (params: GridRenderCellParams) => {
        const phase = phaseOptions.find((item: any) => item.id === params.row.section_uid);
        return (
          <Stack>
            <Stack
              spacing={1}
              direction="row"
              sx={{ fontWeight: 400, fontSize: '14px', display: 'flex', alignItems: 'center', flexDirection: 'row' }}
            >
              <span
                style={{ marginRight: '4px' }}
              />
              <span>
                {phase?.name}
              </span>
            </Stack>
          </Stack>
        );
      },
    },
    //due date
    {
      field: 'due_date',
      headerName: 'Due Date',
      width: 200,
      editable: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <ViewDate date={params.row.due_date} />
        );
      },
      renderEditCell: (params: GridRenderEditCellParams) => SelectEditDateInputCell(params, handleInlineEditValue),
    },
    //type
    {
      field: 'type',
      headerName: 'Type',
      width: 200,
      // editable: true,
      // renderEditCell: (params: GridRenderEditCellParams) => editCellRaidType(params, raidTypes),
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Stack>
            <Stack
              spacing={1}
              direction="row"
              sx={{ fontWeight: 400, fontSize: '14px', display: 'flex', alignItems: 'center', flexDirection: 'row' }}
            >
              <span
                style={{ marginRight: '4px' }}
              />
              <span>
                {params.value}
              </span>
            </Stack>
          </Stack>
        );
      },
    },
    //share with client, boolean edit
    {
      field: 'share_with_client',
      headerName: 'Share with Client',
      width: 200,
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams) => {
        const { id, field } = params;
        const apiRef = useGridApiContext();

        return (
          <CognisaasDropdown
            validvalues={['Yes', 'No']}
            width='190px'
            value={params.value === '1' ? 'Yes' : 'No'}
            onChange={(e) => {
              console.log('cvfvfbf', e.target.value);
              apiRef.current.setEditCellValue({ id, field, value: e.target.value as string });
              handleInlineEditValue(params, params.field, e.target.value === 'Yes' ? '1' : '0');
              apiRef.current.stopCellEditMode({ id, field });
            }}

          />

        );
      },
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Typography
            variant="subtitle1"
            sx={{ color: '#093BCD', fontWeight: 400, fontSize: '14px' }}
          >
            {params.row.share_with_client === '1' ? 'Yes' : 'No'}
          </Typography>
        );
      },
    },
    {
      field: 'recent_comment',
      headerName: 'Comment',
      width: 240,
      renderCell: (params: GridRenderCellParams) => (
        <>
          {isUndefined(params.row.recent_comment.comment) ? (
            ''
          ) : (
            <CognisaaasTooltip
              title={
                <Stack sx={{ padding: '16px', width: '240px', maxHeight: 200, overflow: 'scroll' }}>
                  <Stack>
                    <Stack direction={'row'} justifyContent={'space-between'}>
                      {/* user avatar and name */}
                      <Stack direction={'row'} alignItems={'center'}>
                        {getUserLogoLinkByUid(params.row.recent_comment.user_uid).length > 0 ? (
                          <AvatarImage
                            src={getUserLogoLinkByUid(params.row.recent_comment.user_uid)}
                            alt="user profile"
                          />
                        ) : (
                          <Avatar
                            style={{ height: '40px', width: '40px', marginTop: '3px' }}
                            {...stringAvatar(params.row.recent_comment.user_username)}
                          />
                        )}
                        <Typography ml={1} variant={'h3'}>
                          {params.row.recent_comment.user_username}
                        </Typography>
                      </Stack>

                      <Typography className="label-text" variant="subtitle1" style={{ fontSize: '12px' }}>
                        {formatDistance(new Date(params.row.recent_comment.created_on.replace(/-/g, '/')), new Date(), {
                          includeSeconds: true,
                        })}
                        <br />
                        ago
                      </Typography>
                    </Stack>
                    <Typography mt={1} variant={'subtitle1'} sx={{ whiteSpace: 'pre-line' }}>
                      {generateCommentUi(params.row.recent_comment.comment)}
                    </Typography>
                  </Stack>
                </Stack>
              }
            >
              <Typography variant={'subtitle1'}>
                {generateCommentText(params.row.recent_comment.comment).length > 30
                  ? generateCommentText(params.row.recent_comment.comment).slice(0, 30).trim() + '...'
                  : generateCommentText(params.row.recent_comment.comment)}
              </Typography>
            </CognisaaasTooltip>
          )}
        </>
      ),
    },

  ];


  return <>
    {addTaskDrawer && <NewRaidDrawer
      open={addTaskDrawer}
      onClose={() => setAddTaskDrawer(false)}
    />}

    <Stack my={2} mx={2} direction={'row'} justifyContent={'flex-end'}>
      <Stack direction={'row'}>
        <CognisaasButton
          isOutlined={false}
          label={'Add New RAID'}
          clickHandler={() => setAddTaskDrawer(true)}
        />
      </Stack>
    </Stack>
    <DeleteModal
      isOpen={openDeleteRaids}
      message={
        raidSelected.length > 1
          ? `Are you sure you want to permanently delete these ${raidSelected?.length
          } RAIDs?`
          : `Are you sure you want to permanently delete this ${raidSelected?.length
          } RAID?`
      }
      onDelete={() => bulkDeleteRaid()}
      setIsOpen={setOpenDeleteRaids}
    />

    <Box sx={{ padding: 0 }} className="sticky-div">
      <Collapse in={raidSelected.length > 0}>
        <Paper>
          <Alert
            sx={{ borderRadius: 0, border: '0 solid' }}
            variant="outlined"
            severity="info"
            icon={false}
            action={
              <IconButton aria-label="close" color="inherit" size="small">
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {raidSelected.length} row selected &nbsp;
            <Link
              component="button"
              underline="none"
              variant="body2"
              sx={{ color: 'red' }}
              onClick={() => setOpenDeleteRaids(true)}
            >
              Delete RAID
            </Link>
          </Alert>
        </Paper>
      </Collapse>
    </Box>
    <Box
      sx={{ padding: '10px' }}
      style={{
        background: '#fff',
        boxShadow: '0px 4px 26px rgba(0, 0, 0, 0.05)',
        borderRadius: '4px',
        minHeight: '75vh',
      }}
    >
      {isLoading == true ? (<CognisaasLoader />) : (

        <DataGridPremium
          rows={raidTasks}
          columns={[...columns]}
          checkboxSelection
          sx={{
            backgroundColor: '#fff',
            borderRadius: '4px 4px 0px 0px',
            boxShadow: '0px 4px 26px rgba(0, 0, 0, 0.05)',
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: '#E1E1E6',
              color: '#000000',
              fontSize: 14,
              fontWeight: 'bold',
            },
          }}
          editMode="cell"
          experimentalFeatures={{ newEditingApi: true }}
          rowHeight={60}
          autoHeight
          disableSelectionOnClick
          getRowId={(row) => row.raid_uid}
          apiRef={apiRef}
          onSelectionModelChange={(newSelectionModel) => {
            setRaidSelected(newSelectionModel);
          }}
          selectionModel={raidSelected}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10, 20, 50, 100]}
          pagination
        />
      )}
    </Box>

    {updateRaidDrawer && <UpdateRaidDrawer
      open={updateRaidDrawer}
      raidDetails={{ ...raidClicked, owner_name: preSelectedCombinedMembersWithNotAssigned.find((item) => item.id === raidClicked.owner_uid)?.name || 'Not Assigned' }}
      onClose={() => {
        setUpdateRaidDrawer(false);
        dispatch(getRaidByProjectID(projectInfo.project_uid));
      }}
    />}
    <CognisaasToast
      open={alertDetails.isVisible}
      onClose={() => setAlertDetails({ isVisible: false, type: alertDetails.type, message: '' })}
      message={alertDetails.message}
      type={alertDetails.type}
    />
  </>;
};

export default RAIDTableView;