import { useMemo, useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  TextField,
  Checkbox,
  useFormControl,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Button,
} from '@mui/material';
import CognisaasDropdown from '../../shared/CognisaasDropdown/CognisaasDropdown';
import CognisaasButton from '../../shared/CognisaasButton/CognisaasButton';
import { useDispatch } from 'react-redux';
// import { useAppSelector } from '../../../store/hooks';
import { addUser_slice, getAllRoles_slice } from '../../../store/modules/RBAC/slice';
import { useNavigate } from 'react-router-dom';
import { getAllRoles_selector, getUserAndRoles_selector } from '../../../store/modules/RBAC/selector';
import { useAppSelector } from '../../../store/hooks';

interface helperText_payload {
  text: string;
  textToCheck: string;
}

const AddUser = () => {
  // const location = useLocation();
  const [email, setEmail] = useState('');
  const [userName, setUserName] = useState('');
  const [rolesDropdown, setRolesDropdown] = useState('');
  const [teamDropdown, setTeamDropdown] = useState('Engineering');
  const [notifyUser, setNotifyUser] = useState(true);
  const [roles, setRoles] = useState<any[]>([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userAndRoleData_fromSelector = useAppSelector(getUserAndRoles_selector).data;
  const allRoles = useAppSelector(getAllRoles_selector).data;

  useEffect(() => {
    // setRolesDropdown(uniqueRoles[0]?.role?.name);
    setTeamDropdown('Engineering');
  }, [userAndRoleData_fromSelector]);

  useEffect(() => {
    dispatch(getAllRoles_slice());
  }, []);

  useEffect(() => {
    const roles = allRoles.map((role: any) => role.name);
    setRoles(roles);
  }, [allRoles]);

  const MyFormHelperText = (props: helperText_payload) => {
    const { focused } = useFormControl() || {};

    const helperText = useMemo(() => {
      if (focused) {
        return null;
      } else {
        return props.textToCheck == '' ? props.text : null;
      }
    }, [focused]);

    return <FormHelperText>{helperText}</FormHelperText>;
  };

  const onClickCreate = async () => {
    await dispatch(
      addUser_slice({
        firstname: '',
        lastname: '',
        email: email || '',
        role: rolesDropdown,
        team: teamDropdown || '',
        invited: notifyUser || true,
        username: userName || '',
      })
    );
    navigate('/access-management');
  };

  const onRoleChange = (obj: any) => {
    setRolesDropdown(obj.target.value);
  };

  return (
    <Grid sx={{ margin: '20px 100px' }}>
      <Typography variant="h1">Create New User</Typography>
      <Grid
        sx={{
          marginTop: '18px',
          position: 'relative',
          padding: '30px',
          minHeight: '80vh',
          width: '70vw !important',
          backgroundColor: 'white',
          borderRadius: '5px',
        }}
      >
        <Grid>
          <Typography variant="h2" fontSize="20px">
            Basic Information
          </Typography>
          <Grid sx={{ minHeight: '95px', height: 'auto', marginTop: '15px' }}>
            <FormControl>
              <Typography variant="h3" marginBottom="4px">
                Username
              </Typography>
              <TextField
                size="small"
                value={userName}
                onChange={(e: any) => {
                  setUserName(e.target.value);
                }}
                placeholder="Enter Username"
              />
              <MyFormHelperText textToCheck={email} text="Fill the Username" />
            </FormControl>
            <FormControl sx={{ marginLeft: '40px' }}>
              <Typography variant="h3" marginBottom="4px">
                Email
              </Typography>
              <TextField
                size="small"
                value={email}
                onChange={(e: any) => {
                  setEmail(e.target.value);
                }}
                placeholder="Enter Email"
              />
              <MyFormHelperText textToCheck={email} text="Fill the Email" />
            </FormControl>
          </Grid>
        </Grid>
        <Grid sx={{ marginTop: '25px' }}>
          <Typography variant="h2" fontSize="20px">
            Additional Information
          </Typography>
          <Grid sx={{ marginTop: '25px' }}>
            <Typography variant="h3" marginBottom="4px">
              Role
            </Typography>
            <CognisaasDropdown
              name="duration"
              type="dropdown"
              validvalues={roles}
              onChange={onRoleChange}
              value={rolesDropdown}
              sx={{ width: '250px' }}
            />
            <CognisaasButton isOutlined={false} sx={{ marginLeft: '25px' }} label="Create New Role" />
          </Grid>

          {/* <Grid sx={{marginTop: '15px'}}>
                      <Typography sx={{display: 'flex', alignItems: 'center'}} variant='h3' marginBottom='4px'>Team &nbsp; <Typography variant='subtitle1'>(optional)</Typography></Typography>
                      <CognisaasDropdown
                        name="duration"
                        type="dropdown"
                        validvalues={['Marketing', 'Engineering', 'HR']}
                        onChange={(e: any) => setTeamDropdown(e.target.value)}
                        value={teamDropdown}
                        sx={{width: '250px'}}
                      />
                      <CognisaasButton isOutlined={false} sx={{marginLeft: '25px'}} label="Create New Role" />
                    </Grid> */}
        </Grid>
        <Grid sx={{ marginTop: '40px' }}>
          <Typography variant="h2" fontSize="20px">
            Invite User
          </Typography>
          <FormControlLabel
            sx={{ marginTop: '20px', marginLeft: '0px' }}
            control={
              <Checkbox
                size="small"
                checked={notifyUser}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setNotifyUser(event.target.checked);
                }}
              />
            }
            label={
              <Typography marginLeft="13px" variant="h3">
                Send invite email to user{' '}
              </Typography>
            }
          />
        </Grid>
        <Grid sx={{ position: 'absolute', bottom: 20, right: 40 }}>
          <CognisaasButton isOutlined={true} sx={{ marginLeft: '25px' }} label="Cancel" clickHandler={() => navigate(-1)} />
          <Button onClick={() => onClickCreate()} sx={{ marginLeft: '25px' }} variant="contained">
            Create
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddUser;
