import styled from 'styled-components';

export const Wrapper = styled.div`
  min-height:500px;
  overflow-y: hidden;
  overflow-x: scroll;
  width:100%;
  display:flex;
  margin-top:10px;
`;
