import { Paper, Box, Stack, Typography, IconButton, Avatar, Divider } from '@mui/material';
import EditProjectDrawer from './EditProjectDrawer';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  selectProjectInformation,
  selectedProjectInformationForGantt,
} from '../../../../store/modules/Project/selector';
import { useAppSelector } from '../../../../store/hooks';
// import format from 'date-fns/format';
// import isFuture from 'date-fns/isFuture';
import { selectCustomerDetail } from 'store/modules/Common/selector';
import { getCustomerDetail } from 'store/modules/Common/slice';
import { userPermissionSchema } from 'components/Reports/SideDrawer/types';
import {
  label_arr,
  label_delivery_date,
  label_due_date,
  label_project,
} from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { ReactComponent as EditPen } from '../../../../assets/svgs/edit_pen.svg';
import { formatCurrency } from 'utils/dataHelpers';
import useCustomNames from 'components/shared/CustomNames/useCustomNames';
import NAMED_CONSTATNTS from 'components/shared/CustomNames/Constants';
import { getUserActiveState, INACTIVE_USER_COLOR } from 'utils/userHelpers';
import { getUserUid } from 'utils/getUid';
import useUserDropDownData from 'components/shared/Hooks/useUserDropDownData';
import ViewDate from 'components/shared/ViewDate/ViewDate';

const ProjectDetail = (props: any) => {
  const [editProjectDrawer, setEditProjectDrawer] = useState<boolean>(false);
  const [projectInfo, setProjectInfo] = useState<any>(null);
  const projectDetails = useAppSelector(selectProjectInformation);
  const projectDetailsForTemplateName = useAppSelector(selectedProjectInformationForGantt);
  const customerDetail = useAppSelector(selectCustomerDetail);
  const dispatch = useDispatch();
  const { getCustomNameMappedValue } = useCustomNames();
  const companyUsersDropDownData = useUserDropDownData('company');

  function stringToHslColor(str: string, s: number, l: number) {
    let hash = 0;

    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    const h = hash % 360;
    return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
  }

  function stringAvatar(name: string) {
    return {
      sx: {
        bgcolor: stringToHslColor(name, 30, 80),
      },
      children: (
        <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#000000' }}>
          {name.split(' ')[0][0]}
          {name.indexOf(' ') >= 0 ? name.split(' ')[1][0] : null}
        </Typography>
      ),
    };
  }

  useEffect(() => {
    dispatch(getCustomerDetail());
  }, []);

  useEffect(() => {
    if (projectDetails) {
      setProjectInfo(projectDetails);
    }
  }, [projectDetails]);

  console.log('timeeeeeee', projectInfo);

  const renderItem = () => {
    if (projectInfo) {
      return (
        <Box sx={{ maxHeight: '480', maxWidth: '310', marginTop: '16px' }}>
          <Paper
            sx={{ padding: '12px', minHeight: '480', minWidth: '310', backgroundColor: '#fff', boxShadow: 'none' }}
          >
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h2">{label_project} Detail</Typography>
              {props.project.__user_permissions?.includes(userPermissionSchema.EDIT) && (
                <IconButton
                  onClick={() => setEditProjectDrawer(!editProjectDrawer)}
                  disableRipple
                  style={{ paddingTop: '0px' }}
                >
                  <EditPen />
                </IconButton>
              )}
            </Stack>
            <Stack my={2} direction="row" alignItems="center" justifyContent="space-between">
              <Stack>
                <Typography className="label-text" variant="subtitle2">
                  Actual Start Date
                </Typography>
                <ViewDate date={projectInfo.project_startdate} />

                {/* <Typography variant="subtitle1">
                  {projectInfo.project_startdate == '0000-00-00 00:00:00' ? (
                    <>
                      <Typography variant="subtitle1">-</Typography>
                    </>
                  ) : (
                    <>{format(new Date(projectInfo.project_startdate.replace(/-/g, '/')), 'dd MMM yyyy')}</>
                  )}
                </Typography> */}
              </Stack>
              <Stack sx={{ minWidth: '110px' }}>
                <Typography className="label-text" variant="subtitle2">
                  Planned Start Date
                </Typography>
                <ViewDate date={projectInfo.project_planned_startdate} />

                {/* <Typography variant="subtitle1">
                  {projectInfo.project_planned_startdate == '0000-00-00 00:00:00' ? (
                    <>
                      <Typography variant="subtitle1">-</Typography>
                    </>
                  ) : (
                    <>{format(new Date(projectInfo.project_planned_startdate.replace(/-/g, '/')), 'dd MMM yyyy')}</>
                  )}
                </Typography> */}
              </Stack>
            </Stack>
            <Stack my={1} direction="row" alignItems="center" justifyContent="space-between">
              <Stack>
                <Typography className="label-text" variant="subtitle2">
                  {label_delivery_date}
                </Typography>
                {projectInfo.project_deliverydate == '0000-00-00 00:00:00' ? (
                  <>
                    <Typography variant="subtitle1">-</Typography>
                  </>
                ) : (
                  <>
                    {projectInfo.project_stage !== 'Go-Live' && projectInfo.project_stage !== 'Adption' ? (
                      <Typography variant="subtitle1">Not yet delivered</Typography>
                    ) : (
                      <ViewDate date={projectInfo.project_deliverydate} />
                    )}
                  </>
                )}

                {/* <Typography variant="subtitle1">Not yet delivered</Typography> */}
              </Stack>

              <Stack sx={{ minWidth: '110px' }}>
                <Typography className="label-text" variant="subtitle2">
                  {label_due_date}
                </Typography>
                <ViewDate date={projectInfo.project_requiredbydate} />

                {/* <Typography variant="subtitle1">
                  {format(new Date(projectInfo.project_requiredbydate.replace(/-/g, '/')), 'dd MMM yyyy')}
                </Typography> */}

                {/* <Typography variant="subtitle1">Not yet delivered</Typography> */}
              </Stack>
            </Stack>

            <Stack my={1} direction="column" justifyContent="space-between">
              <Typography variant="subtitle2" className="label-text">
                Estimated Delivery Date
              </Typography>
              <Typography sx={{ color: '#E75E5C' }} variant="subtitle1">
                {projectInfo.eta_forprojectdelivery == '0000-00-00 00:00:00' ? (
                  <>
                    <Typography variant="subtitle1">-</Typography>
                  </>
                ) : (
                  <ViewDate date={projectInfo.eta_forprojectdelivery} />
                )}
              </Typography>
            </Stack>

            <Stack my={1} direction="column" justifyContent="space-between">
              <Typography variant="subtitle2" className="label-text">
                Last updated
              </Typography>
              <ViewDate date={projectInfo.project_modified_on} />

              {/* <Typography variant="subtitle1">
                {projectInfo.project_modified_on
                  ? format(new Date(projectInfo.project_modified_on.replace(/-/g, '/')), 'MMM dd hh:mm aaa')
                  : '-'}
              </Typography> */}
            </Stack>
            <Stack my={1} direction="column" justifyContent="space-between">
              <Typography variant="subtitle2" className="label-text">
                Last Status Email Sent
              </Typography>
              <ViewDate date={projectInfo.last_status_report_sent_time} />

              {/* <Typography variant="subtitle1">
                {projectInfo.last_status_report_sent_time
                  ? format(new Date(projectInfo.last_status_report_sent_time.replace(/-/g, '/')), 'MMM dd hh:mm aaa')
                  : '-'}
              </Typography> */}
            </Stack>
            <Divider />

            <Stack>
              {projectInfo?.imported_template == '1' && (
                <>
                  <Typography className="label-text" variant="subtitle2">
                    Imported from
                  </Typography>
                  <Typography variant="subtitle1">{projectDetailsForTemplateName?.template_project_name}</Typography>
                </>
              )}
              {projectInfo?.imported_template == '2' && (
                <>
                  <Typography className="label-text" variant="subtitle2">
                    Imported from
                  </Typography>
                  <Typography variant="subtitle1">{projectDetailsForTemplateName?.template_project_name}</Typography>
                </>
              )}
            </Stack>

            <Stack my={1} direction="row" alignItems="center" justifyContent="space-between">
              <Stack>
                <Typography className="label-text" variant="subtitle2">
                  Stage
                </Typography>
                <Typography variant="subtitle1">
                  {getCustomNameMappedValue(projectInfo.project_stage, NAMED_CONSTATNTS.project_stage_key)}
                </Typography>
              </Stack>
              <Stack sx={{ minWidth: '110px' }}>
                <Typography className="label-text" variant="subtitle2">
                  Category
                </Typography>
                <Typography variant="subtitle1">
                  {getCustomNameMappedValue(projectInfo.project_needed_status, NAMED_CONSTATNTS.project_category_key)}
                </Typography>
              </Stack>
            </Stack>
            <Stack my={1} direction="row" alignItems="center" justifyContent="space-between">
              <Stack>
                <Typography className="label-text" variant="subtitle2">
                  Implementation Fee
                </Typography>
                <Typography variant="subtitle1">
                  {formatCurrency(projectInfo.project_fees, customerDetail?.currency_code)}
                </Typography>
              </Stack>
            </Stack>
            <Stack my={1} direction="row" alignItems="center" justifyContent="space-between">
              <Stack>
                <Typography className="label-text" variant="subtitle2">
                  {label_project} {label_arr}
                </Typography>
                <Typography variant="subtitle1">
                  {formatCurrency(projectInfo.project_ARR, customerDetail?.currency_code)}
                </Typography>
              </Stack>
            </Stack>
            <Divider />

            <Stack direction="row" sx={{ marginTop: '8px' }}>
              <Stack>
                <Avatar
                  style={{ height: '34px', width: '34px', marginTop: '3px' }}
                  {...stringAvatar(projectInfo.project_deliverymanager)}
                />
              </Stack>
              <Stack>
                {!projectInfo.project_deliverymanager ? (
                  <Typography m={1} variant="subtitle1">
                    -
                  </Typography>
                ) : (
                  <>
                    {getUserActiveState(getUserUid(projectInfo.project_deliverymanager), companyUsersDropDownData) ===
                    '0' ? (
                      <Typography style={{ color: INACTIVE_USER_COLOR }} m={1} variant="subtitle1">
                        {projectInfo.project_deliverymanager}&nbsp;(Inactive)
                      </Typography>
                    ) : (
                      <Typography m={1} variant="subtitle1">
                        {projectInfo.project_deliverymanager}
                      </Typography>
                    )}
                  </>
                )}
              </Stack>
            </Stack>
          </Paper>
          {editProjectDrawer && (
            <EditProjectDrawer
              client={props.client}
              projectDetails={projectInfo}
              onClose={() => setEditProjectDrawer(!editProjectDrawer)}
              drawerState={editProjectDrawer}
            />
          )}
        </Box>
      );
    } else {
      return <>loading</>;
    }
  };

  return <>{renderItem()}</>;
};

export default ProjectDetail;
