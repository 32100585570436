import { Container, Grid, ColourBox, Title } from './ColourPalette.styles';
import { GRAPH_COLORS as colours } from './constants';

interface ColourPaletteProps {
  selectedColours: string[];
  setSelectedColours: React.Dispatch<React.SetStateAction<string[]>>;
}

const ColourPalette = (props: ColourPaletteProps) => {
  const { selectedColours, setSelectedColours } = props;

  return (
    <Container>
      <Grid>
        {colours.map((colour, index) => (
          <ColourBox
            key={index}
            colour={colour}
            onClick={() =>
              setSelectedColours((prevState: string[]) => {
                if (prevState.includes(colour)) {
                  return prevState;
                }
                return [...prevState, colour];
              })
            }
          />
        ))}
      </Grid>
      {selectedColours.length > 0 && (
        <>
          <Title>Selected Colours</Title>
          <Grid>
            {selectedColours.map((colour, index) => (
              <ColourBox
                onClick={() =>
                  setSelectedColours((prevState: string[]) => {
                    return prevState.filter((item: string) => item !== colour);
                  })
                }
                key={index}
                colour={colour}
              />
            ))}
          </Grid>
        </>
      )}
    </Container>
  );
};

export default ColourPalette;
