import { ReactComponent as Icon } from '../../../assets/svgs/fileIcon.svg';
import { ReactComponent as PDFIcon } from '../../../assets/svgs/pdfType.svg';
import { ReactComponent as WordIcon } from '../../../assets/svgs/wordType.svg';
import { ReactComponent as ExcelIcon } from '../../../assets/svgs/excelType.svg';
import { ReactComponent as DocIcon } from '../../../assets/svgs/docxType.svg';
import { ReactComponent as PowerPointIcon } from '../../../assets/svgs/powerpointType.svg';
import { ReactComponent as ImageIcon } from '../../../assets/svgs/imageType.svg';
import { ReactComponent as SVGIcon } from '../../../assets/svgs/svgType.svg';

interface Props {
  fileType: string;
} 


const FileIcon = (props: Props) => {
  switch(props.fileType) {
    case 'pdf':
      return <PDFIcon />;
    case 'doc':
       return <WordIcon />;
    case 'xlsx':
        return <ExcelIcon />;
    case 'csv':
        return <ExcelIcon />;
    case 'docx':
        return <DocIcon />;
    case 'svg':
        return <SVGIcon />;
    case 'png':
        return <ImageIcon />;
    case 'jpg':
        return <ImageIcon />;
    case 'jpeg':
        return <ImageIcon />;
    case 'pptx':
        return <PowerPointIcon />;
    default:
        return (
          <span style={{ height: 'fit-content', position: 'relative', display: 'flex', transform: 'scale(0.8)' }}>
            <span style={{ height: '40px' }}>
              <Icon />
            </span>
            <span
              style={{
                fontSize: '10px',
                position: 'absolute',
                top: '20px',
                left: '8px',
                color: '#546E7A',
              }}
            >
              {props.fileType}
            </span>
          </span>
        );
  }
};

export default FileIcon;
