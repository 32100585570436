import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../index';

export const selectProjectState = (state: RootState) => state.projectState;

export const selectProjectList = createSelector(selectProjectState, (projectState) => projectState.getAllProjectsList);

export const selectProjectListStatus = createSelector(
  selectProjectState,
  (projectState) => projectState.getAllProjectsStatus
);

export const selectProjectsOfClient = createSelector(
  selectProjectState,
  (projectState) => projectState.getProjectsOfClient
);

export const selectProjectCustomFields = createSelector(
  selectProjectState,
  (projectState) => projectState.projectCustomFields
);

export const selectProjectCustomFieldsDetail = createSelector(
  selectProjectState,
  (projectState) => projectState.projectCustomFieldsDetail
);

export const selectProjectCustomFieldsDetailStatus = createSelector(
  selectProjectState,
  (projectState) => projectState.getProjectCustomFieldsDetailStatus
);

export const selectProjectInformation = createSelector(
  selectProjectState,
  (projectState) => projectState.selectedProject
);

export const selectedProjectInformationForGantt = createSelector(
  selectProjectState,
  (projectState) => projectState.selectedProjectInformationForGantt
);

export const selectProjectMembers = createSelector(
  selectProjectState,
  (projectState) => projectState.getProjectMembers
);

export const selectProjectAudit = createSelector(selectProjectState, (projectState) => projectState.getProjectAudit);

export const selectProjectInformationStatus = createSelector(
  selectProjectState,
  (projectState) => projectState.selectedProjectInformationStatus
);

export const selectProjectInformationData = createSelector(
  selectProjectState,
  (projectState) => projectState.selectedProjectInformation
);

export const selectHighlightCriticalPathInProjectGantt = createSelector(
  selectProjectState,
  (projectState) => projectState.highlightCriticalPathInProjectGantt
);

export const selectHighlightCriticalPathInUsecaseGantt = createSelector(
  selectProjectState,
  (projectState) => projectState.highlightCriticalPathInUsecaseGantt
);

// allProjectCustomFields
export const selectAllProjectCustomFields = createSelector(
  selectProjectState,
  (projectState) => projectState.allProjectCustomFields
);

export const selectProjectLevelComments = createSelector(
  selectProjectState,
  (projectState) => projectState.allProjectComments
);

export const selectOpportunitiesOfClient = createSelector(
  selectProjectState,
  (projectState) => projectState.opportunitiesOfClient.data
);
export const selectOpportunitiesStateOfClient = createSelector(
  selectProjectState,
  (projectState) => projectState.opportunitiesOfClient.status
);
