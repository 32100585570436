import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { Box, Typography, Stack } from '@mui/material';
import StatusBadge from '../../../shared/LabeledBadge/LabeledBadge';
import TextField from '@mui/material/TextField';
import CognisaasCheckBox from '../../../shared/CognisaasCheckbox/CognisaasCheckbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { ReactComponent as EditPen } from '../../../../assets/svgs/edit_pen_fieldPage_custom.svg';
import { useDispatch } from 'react-redux';
import {
  getCustomerDetails,
  resetConfigurationUpdateState,
  updateUseCase_Configuration,
  updateUsecasePayload,
} from '../../../../store/modules/Settings/slice';
import { selectConfigurationUpdateState, selectCustomerDetails } from '../../../../store/modules/Settings/selector';
import { useAppSelector } from '../../../../store/hooks';
import CognisaasToast from 'components/shared/CognisaasToast/CognisaasToast';
import { requestState } from 'store/modules/types';
import {
  label_client,
  label_due_date,
  label_project,
  label_project_delivery_manager,
  label_task,
  label_use_case,
  label_use_case_delivery_manager,
} from 'components/Settings/Preferences/CustomLabelCookieHelper';
import CustomTaskStage from './Task/CustomTaskStage';
import CustomTaskPriority from './Task/CustomTaskPriority';

interface checkBoxProps {
  content: string;
  isChecked: boolean;
  value: number;
  handleOnClickCheckbox: (checkBoxNumber: number) => void;
}

const CheckBox = (props: checkBoxProps) => {
  return (
    <FormControlLabel
      sx={{ marginLeft: '2px', marginTop: '10px' }}
      control={
        <CognisaasCheckBox
          checked={props.isChecked}
          value={`${props.value}`}
          onCheckBoxClick={() => {
            props.handleOnClickCheckbox(props.value);
          }}
          size="small"
        ></CognisaasCheckBox>
      }
      label={
        <Typography variant={'subtitle1'} sx={{ marginLeft: '8px' }}>
          {props.content}
        </Typography>
      }
    />
  );
};

const Task = () => {
  const [showTextField, setShowTextField] = useState(false);
  const [textFieldvalue, setTextFieldvalue] = useState(20);
  const [disableAmberTextField, setDisableAmberTextField] = useState(false);
  const [checkBox1, setCheckBox1] = useState(true);
  const [checkBox2, setCheckBox2] = useState(false);
  const [checkBox3, setCheckBox3] = useState(true);
  const [checkBox4, setCheckBox4] = useState(false);
  const [checkBox5, setCheckBox5] = useState(true);
  const [mandatoryAttachmentWhenMilestoneTask, setMandatoryAttachmentWhenMilestoneTask] = useState<'0' | '1'>('0');
  const [lastTaskGetsDoneSetting, setLastTaskGetsDoneSetting] = useState<'0' | '1'>('0');
  const [clientTaskAcknowledgement, setclientTaskAcknowledgement] = useState<'0' | '1'>('0');
  const dispatch = useDispatch();
  const customerDetails = useAppSelector(selectCustomerDetails).data;
  const configurationUpdateStatus = useAppSelector(selectConfigurationUpdateState);
  const [alertDetails, setAlertDetails] = useState<{
    isVisible: boolean;
    type: 'success' | 'failure';
    message: string;
  }>({ isVisible: false, type: 'success', message: '' });

  // for alerts
  useEffect(() => {
    if (configurationUpdateStatus.status === requestState.success) {
      setAlertDetails({ isVisible: true, type: 'success', message: 'Updated Successfully!' });
      dispatch(resetConfigurationUpdateState());
    }
  }, [configurationUpdateStatus]);

  useEffect(() => {
    dispatch(getCustomerDetails());
  }, []);

  useEffect(() => {
    setTextFieldvalue(100 - customerDetails.customer_amber_threshold);
    setCheckBox1(customerDetails.time_track == '1' ? true : false);
    setCheckBox2(customerDetails.client_task_ind == '1' ? true : false);
    setCheckBox3(customerDetails.task_inprogress_indicator == '1' ? true : false);
    setCheckBox4(customerDetails.subtask_enable == '1' ? true : false);
    setCheckBox5(customerDetails.task_dependency_date_updation == '1' ? true : false);
    setMandatoryAttachmentWhenMilestoneTask(customerDetails.attachment_mandatory_when_marking_milestone_task_as_done);
    setLastTaskGetsDoneSetting(customerDetails.last_done_task_will_update_delivery_date_of_project_or_usecase);
    setclientTaskAcknowledgement(customerDetails.task_acknowledgement);
  }, [customerDetails]);

  const onChangeAmberValue = async () => {
    setDisableAmberTextField(true);
    await dispatch(
      updateUseCase_Configuration({
        client_mail: customerDetails.client_mail,
        client_task_ind: customerDetails.client_task_ind,
        currency_code: customerDetails.currency_code,
        customer_amber_threshold: 100 - textFieldvalue,
        customer_uid: customerDetails.customer_uid,
        renewal_notification: customerDetails.renewal_notification,
        subtask_enable: customerDetails.subtask_enable,
        task_deletion_mail: customerDetails.task_deletion_mail,
        task_dependency_date_updation: customerDetails.task_dependency_date_updation,
        task_inprogress_indicator: customerDetails.task_inprogress_indicator,
        task_mail: customerDetails.task_mail,
        task_mail_copy: customerDetails.task_mail_copy,
        task_status_account_owner_mail: customerDetails.task_status_accountowner_mail,
        task_status_mail: customerDetails.task_status_mail,
        task_status_client_contact_mail: customerDetails.task_status_client_contact_mail,
        task_status_manager_mail: customerDetails.task_status_manager_mail,
        time_track: customerDetails.time_track,
        usecase_calc_ind: customerDetails.usecase_calc_ind,
        task_assign_mail_dm_copy: customerDetails.task_assign_mail_dm_copy,
        task_deletion_delivery_manager_mail: customerDetails.task_deletion_delivery_manager_mail,
        project_progress_indicator: customerDetails.project_progress_indicator,
        status_report_client: customerDetails.status_report_client,
        primary_contact_as_default_owner: customerDetails.primary_contact_as_default_owner,
        delivery_manager_as_default_owner: customerDetails.delivery_manager_as_default_owner,
        attachment_mandatory_when_marking_milestone_task_as_done: mandatoryAttachmentWhenMilestoneTask,
        client_contact_added_mail: customerDetails.client_contact_added_mail,
        project_plan_view: customerDetails.project_plan_view,
        usecase_plan_view: customerDetails.usecase_plan_view,
        last_done_task_will_update_delivery_date_of_project_or_usecase: lastTaskGetsDoneSetting,
        task_acknowledgement: clientTaskAcknowledgement,
        freeze_planned_date_project: customerDetails.freeze_planned_date_project,
        freeze_planned_date_usecase: customerDetails.freeze_planned_date_usecase,
      })
    );
    setDisableAmberTextField(false);
    setShowTextField(false);
    dispatch(getCustomerDetails());
  };

  const detectEnterPress = (e: any) => {
    if (e.key == 'Enter') {
      onChangeAmberValue();
    }
  };

  const onChangeCheckbox = async (checkBoxNumber: number) => {
    let finalPay = { ...customerDetails };

    if (checkBoxNumber == 1) {
      finalPay = { ...finalPay, ['time_track']: checkBox1 == true ? '0' : '1' };

      setCheckBox1(checkBox1 == true ? false : true);
    } else if (checkBoxNumber == 2) {
      finalPay = { ...finalPay, ['client_task_ind']: checkBox2 == true ? '0' : '1' };

      setCheckBox2(checkBox2 == true ? false : true);
    } else if (checkBoxNumber == 3) {
      finalPay = { ...finalPay, ['task_inprogress_indicator']: checkBox3 == true ? '0' : '1' };

      setCheckBox3(checkBox3 == true ? false : true);
    } else if (checkBoxNumber == 4) {
      finalPay = { ...finalPay, ['subtask_enable']: checkBox4 == true ? '0' : '1' };

      setCheckBox4(checkBox4 == true ? false : true);
    } else if (checkBoxNumber == 5) {
      finalPay = { ...finalPay, ['task_dependency_date_updation']: checkBox5 == true ? '0' : '1' };

      setCheckBox5(checkBox5 == true ? false : true);
    } else if (checkBoxNumber == 6) {
      finalPay = {
        ...finalPay,
        ['attachment_mandatory_when_marking_milestone_task_as_done']:
          mandatoryAttachmentWhenMilestoneTask === '0' ? '1' : '0',
      };

      setMandatoryAttachmentWhenMilestoneTask(mandatoryAttachmentWhenMilestoneTask === '0' ? '1' : '0');
    } else if (checkBoxNumber == 7) {
      finalPay = {
        ...finalPay,
        ['last_done_task_will_update_delivery_date_of_project_or_usecase']: lastTaskGetsDoneSetting === '0' ? '1' : '0',
      };

      setLastTaskGetsDoneSetting(lastTaskGetsDoneSetting === '0' ? '1' : '0');
    } else if (checkBoxNumber == 8) {
      finalPay = {
        ...finalPay,
        ['task_acknowledgement']: clientTaskAcknowledgement === '0' ? '1' : '0',
      };
      setclientTaskAcknowledgement(clientTaskAcknowledgement === '0' ? '1' : '0');
    }

    await dispatch(updateUseCase_Configuration(finalPay as updateUsecasePayload));
    dispatch(getCustomerDetails());
  };

  const onTemplateConfigChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const currentState = e.target.checked === true ? '1' : '0';
    await dispatch(
      updateUseCase_Configuration({
        ...customerDetails,
        [(e.target as HTMLInputElement).value]: currentState,
      } as updateUsecasePayload)
    );
    dispatch(getCustomerDetails());
  };

  return (
    <Grid sx={{ padding: '24px 20px' }}>
      <Grid>
        <Typography variant={'h2'}>{label_task} status configuration</Typography>
        <Typography
          sx={{ display: 'flex', alignItems: 'center', marginTop: '12px', color: '#7A7A7A' }}
          variant={'subtitle1'}
        >
          {label_task} status should be automatically updated from &nbsp;
          <StatusBadge
            fontSize="14px"
            fontWeight="400"
            text="green"
            isActive={true}
            statusColor="#4F9F52"
            textColor="black"
          />{' '}
          &nbsp; to &nbsp;
          <StatusBadge
            fontSize="14px"
            fontWeight="400"
            text="amber"
            isActive={true}
            statusColor="#EFA958"
            textColor="black"
          />{' '}
          &nbsp; based on the &nbsp;
          <Typography variant={'h3'}>percentage</Typography> &nbsp;of time remaining for &nbsp;
          <Typography variant={'h3'}>due date</Typography>
        </Typography>
      </Grid>
      <Grid sx={{ marginTop: '24px' }}>
        <Typography variant={'subtitle1'} sx={{ color: '#7A7A7A' }}>
          Specify the percentage below
        </Typography>
        <Grid sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            sx={{ marginTop: '7px', backgroundColor: '#F3F3F3', padding: '10px', borderRadius: '4px', maxWidth: '60%' }}
          >
            <Typography variant={'subtitle1'} sx={{ display: 'flex', alignItems: 'center', color: '#7A7A7A' }}>
              Turn {label_task.toLowerCase()} status &nbsp;
              <StatusBadge
                fontSize="14px"
                fontWeight="400"
                text="amber"
                isActive={true}
                statusColor="#EFA958"
                textColor="black"
              />{' '}
              &nbsp;when &nbsp;
              <span
                onClick={() => {
                  setShowTextField(true);
                }}
                onBlur={onChangeAmberValue}
                onKeyPress={detectEnterPress}
              >
                {showTextField ? (
                  <TextField
                    disabled={disableAmberTextField}
                    inputProps={{ style: { paddingTop: 5, paddingBlock: 5, paddingLeft: 10, width: 80 } }}
                    size="small"
                    value={textFieldvalue}
                    onChange={(e: any) => {
                      setTextFieldvalue(e.target.value);
                    }}
                  />
                ) : (
                  textFieldvalue
                )}
              </span>
              % of the time left in {label_task.toLowerCase()} due date
            </Typography>
          </Box>
          <Typography
            sx={{ color: '#2D60F6', marginLeft: '30px', cursor: 'pointer' }}
            variant={'subtitle1'}
            onClick={() => {
              setShowTextField(!showTextField);
            }}
          >
            <EditPen /> {showTextField ? 'Close Percentage input' : 'Edit percentage'}
          </Typography>
        </Grid>
        <Typography variant={'subtitle2'} sx={{ marginTop: '18px', color: '#7A7A7A' }}>
          If 20% is specified above, then for a {label_task.toLowerCase()} with 10 days duration between the start date
          and the due date - the {label_task.toLowerCase()} status would be shown as green until the 8th day. On the 9th
          day if the {label_task.toLowerCase()} is still not completed then the status would be automatically changed to
          amber
        </Typography>
      </Grid>
      <Grid sx={{ marginTop: '34px', display: 'flex', flexDirection: 'column' }}>
        <Typography variant={'h2'}>{label_task} management configuration</Typography>
        <CheckBox
          value={1}
          handleOnClickCheckbox={onChangeCheckbox}
          content={`Enable automatic time tracking of ${label_task.toLowerCase()} based on duration of ‘in progress’ stage`}
          isChecked={checkBox1}
        />
        <CheckBox
          value={2}
          handleOnClickCheckbox={onChangeCheckbox}
          content={`Task assigned to ${label_client.toLowerCase()} contact should be factored in the revenue impact analysis`}
          isChecked={checkBox2}
        />
        <CheckBox
          value={3}
          handleOnClickCheckbox={onChangeCheckbox}
          content={`Disable update to ${label_task.toLowerCase()} ${label_due_date.toLowerCase()} once the ${label_task.toLowerCase()} is ‘in progress’`}
          isChecked={checkBox3}
        />
        <CheckBox
          value={4}
          handleOnClickCheckbox={onChangeCheckbox}
          content={`Automatically mark a ${label_task.toLowerCase()} as ‘Done’ when all its sub${label_task.toLowerCase()}s are marked as ‘Done’ `}
          isChecked={checkBox4}
        />
        <CheckBox
          value={5}
          handleOnClickCheckbox={onChangeCheckbox}
          content={`Update the ETA or ${label_due_date.toLowerCase()} of dependent ${label_task.toLowerCase()}s automatically based on the delay in precedent ${label_task.toLowerCase()}`}
          isChecked={checkBox5}
        />
        <CheckBox
          value={6}
          handleOnClickCheckbox={onChangeCheckbox}
          content={`For a milestone ${label_task.toLowerCase()}, make attachment mandatory before the ${label_task.toLowerCase()} is marked as ‘Done’`}
          isChecked={mandatoryAttachmentWhenMilestoneTask === '0' ? false : true}
        />
        <CheckBox
          value={7}
          handleOnClickCheckbox={onChangeCheckbox}
          content={`Automatically mark the ${label_project}/${label_use_case} live on the completion of the last ${label_task.toLowerCase()} in the ${label_project}/${label_use_case} plan`}
          isChecked={lastTaskGetsDoneSetting === '0' ? false : true}
        />
        <CheckBox
          value={8}
          handleOnClickCheckbox={onChangeCheckbox}
          content={`Allow customers to acknowledge ${label_task.toLowerCase()} shared with them`}
          isChecked={clientTaskAcknowledgement === '0' ? false : true}
        />
      </Grid>
      <Grid sx={{ marginTop: '30px' }}>
        <Grid>
          <Typography variant={'h2'}> Import template configuration</Typography>
          <Stack>
            <FormControlLabel
              sx={{ marginTop: '15px', marginLeft: '1px' }}
              value="primary_contact_as_default_owner"
              control={
                <CognisaasCheckBox
                  checked={customerDetails.primary_contact_as_default_owner === '1' ? true : false}
                  onCheckBoxClick={onTemplateConfigChange}
                  size="small"
                  value="primary_contact_as_default_owner"
                />
              }
              label={
                <Typography variant={'subtitle1'} sx={{ marginLeft: '5px' }}>
                  Set primary {label_client.toLowerCase()} contact as default owner of imported{' '}
                  {label_client.toLowerCase()} {label_task.toLowerCase()}s
                </Typography>
              }
            />
          </Stack>
          <Stack>
            <FormControlLabel
              sx={{ marginTop: '7px', marginLeft: '1px' }}
              value="delivery_manager_as_default_owner"
              control={
                <CognisaasCheckBox
                  checked={customerDetails.delivery_manager_as_default_owner === '1' ? true : false}
                  onCheckBoxClick={onTemplateConfigChange}
                  size="small"
                  value="delivery_manager_as_default_owner"
                />
              }
              label={
                <Typography variant={'subtitle1'} sx={{ marginLeft: '5px' }}>
                  Set{' '}
                  {label_use_case_delivery_manager === label_project_delivery_manager
                    ? label_project_delivery_manager
                    : `respective ${label_project_delivery_manager} or ${label_project_delivery_manager}`}{' '}
                  as default owner of imported {label_task.toLowerCase()}s
                </Typography>
              }
            />
          </Stack>

          <Stack style={{ marginTop: '24px', width: '480px' }}>
            <Typography variant="h6">Custom {label_task.toLowerCase()} stage names</Typography>
            <CustomTaskStage />
          </Stack>

          <Stack style={{ marginTop: '24px', width: '480px' }}>
            <Typography variant="h6">Custom {label_task.toLowerCase()} priority names</Typography>
            <CustomTaskPriority />
          </Stack>
        </Grid>
      </Grid>

      {/* this is new alerts */}
      <CognisaasToast
        open={alertDetails.isVisible}
        onClose={() => setAlertDetails({ isVisible: false, type: alertDetails.type, message: '' })}
        message={alertDetails.message}
        type={alertDetails.type}
      />
    </Grid>
  );
};
export default Task;
