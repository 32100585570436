import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { format } from 'date-fns';
import {
  getUserNameByUserUid,
  getStageName,
  getSegmentName,
  getIndustryName,
  getContactNameByContactUid,
} from 'utils/getUid';
import { isValidDate } from 'utils/dataHelpers';
import {
  label_arr,
  label_client,
  label_delivery_date,
  label_due_date,
  label_project,
  label_task,
  label_use_case,
} from 'components/Settings/Preferences/CustomLabelCookieHelper';
export const excelExportData = (jsonData: any, fileName: string, clientName: any) => {
  const fileExtension = '.xlsx';
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const wb = XLSX.utils.book_new();
  let excelBuffer: any = '';
  const getTextOfTheChange = (data: any, type: string) => {
    let text = '';
    if (data.field != 'Created') {
      // text += 'Updated ';
      if (data.field.includes('status')) {
        text += 'Updated ';
        text += 'status ';
        text += 'from ';
      } else if (
        data.field.includes('Delivery') ||
        data.field.includes('delivery') ||
        data.field.includes(label_delivery_date)
      ) {
        text += 'Updated ';
        text += `${label_delivery_date.toLowerCase()} `;
        text += 'from ';
      } else if (data.field.includes('due') || data.field.includes('Due') || data.field.includes(label_due_date)) {
        text += 'Updated ';
        text += `${label_due_date.toLowerCase()} `;
        text += 'from ';
      } else if (data.field.includes('Account ARR')) {
        text += 'Updated ';
        text += `${label_client} ${label_arr} `;
        text += 'from ';
      } else if (data.field.includes('Project ARR')) {
        text += 'Updated ';
        text += `${label_project} ${label_arr} `;
        text += 'from ';
      } else if (
        data.field.includes('comment') ||
        data.field.includes('Description') ||
        data.field.includes('Comment')
      ) {
        if (data.old_value != '') {
          text += 'Updated ';
          text += 'from ';
        } else {
          text += 'Added ';
        }
        text += `${data.field} `;
      } else {
        text += 'Updated ';
        text += `${data.field.toLowerCase()} `;
        text += 'from ';
      }
      if (
        data.field.includes('Date') ||
        data.field.includes('ETA') ||
        data.field.includes(label_due_date) ||
        data.field.includes(label_delivery_date)
      ) {
        if (isValidDate(new Date(data.old_date))) {
          text += `${format(new Date(data.old_date), 'dd MMM yyyy')} to `;
        } else {
          text += ' to ';
        }
        if (isValidDate(new Date(data.new_date))) {
          text += `${format(new Date(data.new_date), 'dd MMM yyyy')}`;
        } else {
          text += ' ';
        }
      } else if (
        data.field.includes('Owner') ||
        data.field.includes('owner') ||
        data.field.includes('Manager') ||
        data.field.includes('manager') ||
        data.field.includes('Reporter')
      ) {
        if (getUserNameByUserUid(data.old_value)) {
          text += `${getUserNameByUserUid(data.old_value)} `;
        } else if (getContactNameByContactUid(data.old_value)) {
          text += `${getContactNameByContactUid(data.old_value)} `;
        } else {
          text += `${data.old_value} `;
        }
        text += 'to ';
        if (getUserNameByUserUid(data.new_value)) {
          text += `${getUserNameByUserUid(data.new_value)} `;
        } else if (getContactNameByContactUid(data.new_value)) {
          text += `${getContactNameByContactUid(data.new_value)} `;
        } else {
          text += `${data.new_value} `;
        }
      } else if (data.field.includes('status')) {
        if (data.old_value === 'red') {
          text += 'Overdue';
        } else if (data.old_value === 'green') {
          text += 'On Track';
        } else if (data.old_value === 'amber') {
          text += 'At Risk';
        } else if (data.old_value === 'blue') {
          text += 'Completed';
        } else if (data.old_value === 'grey') {
          text += 'Deferred';
        } else {
          text += '';
        }
        text += ' to ';
        if (data.new_value === 'red') {
          text += 'Overdue';
        } else if (data.new_value === 'green') {
          text += 'On Track';
        } else if (data.new_value === 'amber') {
          text += 'At Risk';
        } else if (data.new_value === 'blue') {
          text += 'Completed';
        } else if (data.new_value === 'grey') {
          text += 'Deferred';
        } else {
          text += '';
        }
      } else if (data.field.includes('Segment')) {
        if (getSegmentName(data.old_value)) {
          text += `${getSegmentName(data.old_value)} `;
        } else {
          text += `${data.old_value} `;
        }
        text += 'to ';
        if (getSegmentName(data.new_value)) {
          text += `${getSegmentName(data.new_value)} `;
        } else {
          text += `${data.new_value} `;
        }
      } else if (data.field.includes('Industry')) {
        if (getIndustryName(data.old_value)) {
          text += `${getIndustryName(data.old_value)} `;
        } else {
          text += `${data.old_value} `;
        }
        text += 'to ';
        if (getIndustryName(data.new_value)) {
          text += `${getIndustryName(data.new_value)} `;
        } else {
          text += `${data.new_value} `;
        }
      } else if (data.field.includes('Stage')) {
        if (getStageName(data.old_value)) {
          text += `${getStageName(data.old_value)} `;
        } else {
          text += `${data.old_value} `;
        }
        text += 'to ';
        if (getStageName(data.new_value)) {
          text += `${getStageName(data.new_value)} `;
        } else {
          text += `${data.new_value} `;
        }
      } else if (
        data.field.includes('comment') ||
        data.field.includes('Comment') ||
        data.field.includes('Desciption')
      ) {
        if (data.old_value != '') {
          text += `${data.old_value} `;
          text += 'to ';
          text += `${data.new_value} `;
        } else {
          text += `${data.new_value} `;
        }
      } else {
        if (data.old_value === '') {
          text += 'None ';
        } else if (data.old_value === '0' && (!data.field.includes('ARR') || !data.field.includes('Implementation'))) {
          text += `${data.old_value} `;
        } else if (data.old_value === '1' && (!data.field.includes('ARR') || !data.field.includes('Implementation'))) {
          text += `${data.old_value} `;
        } else {
          text += `${data.old_value} `;
        }
        text += 'to ';
        if (data.new_value === '') {
          text += 'None ';
        } else if (data.new_value === '0' && (!data.field.includes('ARR') || !data.field.includes('Implementation'))) {
          text += `${data.new_value} `;
        } else if (data.new_value === '1' && (!data.field.includes('ARR') || !data.field.includes('Implementation'))) {
          text += `${data.new_value} `;
        } else {
          text += `${data.new_value} `;
        }
      }
    } else {
      if (data.user_uid != 'CogniSaaS Platform') {
        text += `${getUserNameByUserUid(data.user_uid)}`;
      } else {
        text += `${data.user_uid}`;
      }
      text += ` created ${type} `;
    }
    return text;
  };
  const trailData = (clientTrail: any, type: string) => {
    let final: any = [];
    final = clientTrail.map(function (obj: any) {
      return {
        Date: format(new Date(obj.modified_on.replace(/-/g, '/').slice(0, 10)), 'yyyy/MM/dd'),
        'Who made the change': obj.user_uid != 'CogniSaaS Platform' ? getUserNameByUserUid(obj.user_uid) : obj.user_uid,
        'What was changed': `${getTextOfTheChange(obj, type)}`,
      };
    });
    return final;
  };
  const tasktrailData = (taskTrail: any) => {
    let final: any = [];
    const projectusecasename = `${label_project}/${label_use_case}`;
    const task_name = `${label_task}`;
    final = taskTrail.map(function (obj: any) {
      return {
        Date: format(new Date(obj.modified_on.replace(/-/g, '/').slice(0, 10)), 'yyyy/MM/dd'),
        'Who made the change': obj.user_uid != 'CogniSaaS Platform' ? getUserNameByUserUid(obj.user_uid) : obj.user_uid,
        'What was changed': `${getTextOfTheChange(obj, label_task)}`,
      };
    });
    taskTrail.forEach((element: any, index: number) => {
      final[index][task_name] = element.task_name;
      final[index][projectusecasename] = element['PROJECT/USECASE'];
    });
    return final;
  };

  const updateClientDataValues = (jsonData: any) => {
    const clientTrail = trailData(jsonData[0], label_client);
    const ws = XLSX.utils.json_to_sheet(clientTrail);
    XLSX.utils.book_append_sheet(wb, ws, clientName.replace(/[^a-zA-Z0-9 ]/g, ' '));
  };
  const updateProjectValues = (jsonData: any) => {
    jsonData[1].forEach((value: any) => {
      const project_name: string = Object.keys(value)[0];
      const projectTrail = trailData(value[project_name], label_project);
      const ws = XLSX.utils.json_to_sheet(projectTrail);
      XLSX.utils.book_append_sheet(wb, ws, project_name.replace(/[^a-zA-Z0-9 ]/g, ' '));
    });
  };
  const updateUsecaseValues = (jsonData: any) => {
    jsonData[2].forEach((value: any) => {
      const usecase_name: string = Object.keys(value)[0];
      const usecaseTrail = trailData(value[usecase_name], label_use_case);
      const ws = XLSX.utils.json_to_sheet(usecaseTrail);
      XLSX.utils.book_append_sheet(wb, ws, usecase_name.replace(/[^a-zA-Z0-9 ]/g, ' '));
    });
  };
  const updateTaskDataValues = (jsonData: any) => {
    const taskTrailData = tasktrailData(jsonData[3]);
    const ws = XLSX.utils.json_to_sheet(taskTrailData);
    XLSX.utils.book_append_sheet(wb, ws, `${label_task} trail`);
  };
  updateClientDataValues(jsonData);
  updateProjectValues(jsonData);
  updateUsecaseValues(jsonData);
  updateTaskDataValues(jsonData);
  excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data: Blob = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + fileExtension);
};
// function sortByProperty(property: any) {
//     return function (a: any, b: any) {
//         if (new Date(a[property]) < new Date(b[property])) return 1;
//         else if (new Date(a[property]) > new Date(b[property])) return -1;
//         return 0;
//     };
// }
