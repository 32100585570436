import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';

import authReducer from './modules/auth/auth';
import openProjectsReducer from './modules/Reports/OpenProjects/slice';
import openTasksReducer from './modules/Reports/OpenTasks/slice';
import closedTasksReducer from './modules/Reports/ClosedTasks/slice';
import closedProjectsReducer from './modules/Reports/ClosedProjects/slice';
import closedUsecasesReducer from './modules/Reports/ClosedUsecases/slice';
import renewalTasksReducer from './modules/Reports/RenewalTasks/slice';
import revenueProjectTasks from './modules/Reports/RevenueProjectTasks/slice';
import reportsAllProjectsReducer from './modules/Reports/AllProjects/slice';
import reportsAllUsecasesReducer from './modules/Reports/AllUsecases/slice';
('./modules/Reports/allProjects/slice');
import openUsecasesReducer from './modules/Reports/OpenUsecases/slice';
('./modules/Reports/openUsecases/slice');
import usersReducer from './modules/Reports/Users/slice';
import reportsClientsReducer from './modules/Reports/Clients/slice';
import reportsTasksReducer from './modules/Reports/AllTasks/slice';
import { clientListReducer } from './modules/ManageClient/ClientList/slice';
import { default as newClientListReducer } from './modules/ManageClient/ClientList-new/slice';
import { onboardFormValuesReducer } from './modules/ManageClient/OnboardFormValues/slice';
import manageClientsUiReducer from './modules/ui/ManageClient/slice';
import { manageClientTableColumnOptionsReducer } from './modules/ui/ManageClient/slice';
import { filtersReducer } from './modules/ui/Filters/slice';
import globalSearchSlice from './modules/GlobalSearch/GlobalSearch';
import dashboardSlice from './modules/Dashboard/Dashboard';
import templateSlice from './modules/Templates/Templates';
import attachmentReducer from './modules/Attachment/slice';
import productRequirementReducer from './modules/ProductRequirements/ProductDetails/slice';
import actionableInsightsReducer from './modules/ActionableInsights/slice';
import allFiltersReducer from './modules/AllFilters/slice';
import commonDataReducer from './modules/ui/CommonData/slice';
import dataViewReducer from './modules/ui/DataViews/slice';
import projectsReducer from './modules/Project/slice';
import usecaseReducer from './modules/Usecase/slice';
import commonReducer from './modules/Common/slice';
import tasksReducer from './modules/Task/slice';
import raidReducer from './modules/RAID/slice';
import settingsReducer from './modules/Settings/slice';
import RBACReducer from './modules/RBAC/slice';
import csatReducer from './modules/Reports/Performance/slice';
import phaseReducer from './modules/Reports/Phases/slice';
import customerPortal from './modules/CustomerPortal/CustomerPortal';
import homeReducer from './modules/HomePage/slice';
import adminPanelReducer from './modules/AdminPanel/slice';
import projectReportsReducer from './modules/ProjectReport/slice';
import chartsReducer from './modules/Charts/slice';
import { resourceManagementReducer } from './modules/ResourceManagement';
import { customReportReducer } from './modules/Reports/CustomReports/slice';
import { aggregatedCustomReportReducer } from './modules/Reports/AggregatedCustomReports/slice';

import { clientReducer } from './modules/features/client/client';
import { commonReducerNew } from './modules/features/common/common';

const rootReducer = combineReducers({
  filterState: filtersReducer,
  clientListState: clientListReducer,
  newClientListState: newClientListReducer,
  reportsClientsState: reportsClientsReducer,
  reportsAllProjectsState: reportsAllProjectsReducer,
  reportsAllUsecasesState: reportsAllUsecasesReducer,
  usersState: usersReducer,
  revenueProjectTasksState: revenueProjectTasks,
  renewalTasksState: renewalTasksReducer,
  openUsecasesState: openUsecasesReducer,
  openProjectsState: openProjectsReducer,
  closedUsecasesState: closedUsecasesReducer,
  openTasksState: openTasksReducer,
  closedTasksState: closedTasksReducer,
  closedProjectsState: closedProjectsReducer,
  manageClientsUiState: manageClientsUiReducer,
  authState: authReducer,
  manageClientTableColumnOptionsState: manageClientTableColumnOptionsReducer,
  // onboardFormValuesState: onboardFormValuesReducer,
  globalSearchState: globalSearchSlice,
  dashboardState: dashboardSlice,
  templateSlice: templateSlice,
  onboardFormValuesState: onboardFormValuesReducer,
  attachmentState: attachmentReducer,
  productReqState: productRequirementReducer,
  actionableInsights: actionableInsightsReducer,
  allFiltersState: allFiltersReducer,
  commonDataState: commonDataReducer,
  dataViewState: dataViewReducer,
  projectState: projectsReducer,
  usecaseReducer: usecaseReducer,
  commonState: commonReducer,
  taskState: tasksReducer,
  settingState: settingsReducer,
  RBACState: RBACReducer,
  csatState: csatReducer,
  phaseState: phaseReducer,
  customerPortal: customerPortal,
  homeState: homeReducer,
  adminPanelState: adminPanelReducer,
  projectReportsReducer: projectReportsReducer,
  resourceManagementState: resourceManagementReducer,
  chartsState: chartsReducer,
  reportsTasksState: reportsTasksReducer,
  customReportsState: customReportReducer,
  aggregatedCustomReportsState: aggregatedCustomReportReducer,
  raidState: raidReducer,

  clientFeature: clientReducer,
  commonFeature: commonReducerNew,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),

  devTools: true,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
