import React, { useEffect, useRef } from 'react';
import { BarComponent } from 'components/ResourceManagement/types/bar-component';
import { Component } from 'components/ResourceManagement/types/public-types';
import { ComponentListHeader } from './component-list-header';
import { ComponentListTable } from './component-list-table';
import styled from 'styled-components';
import { Box } from 'components/ResourceManagement/design-system/primitives';

export type ComponentListProps = {
  headerHeight: number;
  rowHeight: number;
  ganttHeight: number;
  scrollY: number;
  locale: string;
  primeComponents: Component[];
  componentListRef: React.RefObject<HTMLDivElement>;
  horizontalContainerClass?: string;
  selectedComponent: BarComponent | undefined;
  mode: string;
  setSelectedComponent: (component: string) => void;
  onExpanderClick: (component: Component) => void;
};

const StyledTableWrap = styled(Box)<{ ganttHeight: number }>`
  margin: 0;
  padding: 0;
  overflow: hidden;
  ${({ ganttHeight }) => (ganttHeight ? `height: ${ganttHeight}px` : '')};
`;

const StyledDiv = styled(Box)``;

export const ComponentList: React.FC<ComponentListProps> = ({
  headerHeight,
  rowHeight,
  scrollY,
  primeComponents,
  selectedComponent,
  setSelectedComponent,
  onExpanderClick,
  locale,
  ganttHeight,
  componentListRef,
  mode,
}) => {
  const horizontalContainerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (horizontalContainerRef.current) {
      horizontalContainerRef.current.scrollTop = scrollY;
    }
  }, [scrollY]);

  const rowWidth = 320;
  const headerProps = {
    headerHeight,
    rowWidth,
    mode,
  };
  const selectedComponentId = selectedComponent ? selectedComponent.id : '';
  const tableProps = {
    rowHeight,
    rowWidth,
    primeComponents,
    locale,
    selectedComponentId,
    mode,
    setSelectedComponent,
    onExpanderClick,
  };

  return (
    <StyledDiv ref={componentListRef}>
      <ComponentListHeader {...headerProps} />
      <StyledTableWrap ref={horizontalContainerRef} ganttHeight={ganttHeight}>
        <ComponentListTable {...tableProps} />
      </StyledTableWrap>
    </StyledDiv>
  );
};
