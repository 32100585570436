import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import CognisaasDropdown from 'components/shared/CognisaasDropdown/CognisaasDropdown';
import { useState, useEffect } from 'react';
import {
  createCondition_slice,
  getAllResources_slice,
  getGeneralFieldValue_slice,
  getResourcesFieldValue_slice,
  getResourcesField_slice,
} from 'store/modules/RBAC/slice';
// import {useLocation, useNavigate} from 'react-router-dom';
import {
  getAllResources_selector,
  getGeneralFieldValues_selector,
  getResourcesFieldValues_selector,
  getResourcesField_selector,
} from 'store/modules/RBAC/selector';
import { useAppSelector } from 'store/hooks';
import { useDispatch } from 'react-redux';

import CognisaasSelect from 'components/shared/CognisaasSelect/CognisaasSelect';
import { AddRBACProps } from 'components/RBACv2/interfaces';
import { operatorMapping } from 'components/RBACv2/utils/filters';
import { operatorType } from 'components/RBACv2/types';
import { LoadingButton } from '@mui/lab';
import CognisaasButton from 'components/shared/CognisaasButton/CognisaasButton';
import { requestState } from 'store/modules/types';
import CognisaasSpinner from 'components/shared/CognisaasSpinner/CognisaasSpinner';

const AddCondition = (props: AddRBACProps) => {
  const [conditionName, setConditionName] = useState<string>('');
  const [conditionDescription, setConditionDescription] = useState<string>('');
  const [resourcesDropdown, setResourcesDropdown] = useState<string>('');
  const [resourcePriority, setResourcePriority] = useState<string>('');
  const [allResources, setAllResources] = useState<string[]>([]);
  const [fieldValueDropDownValue, setFieldValueDropDownValue] = useState<any>('');
  const [conditionResourceGeneralFieldValue, setConditionResourceGeneralFieldValue] = useState<any>([]);
  const [conditionResourceFieldValue, setConditionResourceFieldValue] = useState<any>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [selectedOperator, setSelectedOperator] = useState<operatorType>('EQ' as operatorType);
  const [selectedFieldType, setSelectedFieldType] = useState<string>('');
  const [resourcesField, setResourcesField] = useState<any[]>([]);
  const [loader, setLoader] = useState<boolean>(false);

  const getAllResources = useAppSelector(getAllResources_selector).data;
  const getGeneralFieldValues = useAppSelector(getGeneralFieldValues_selector).data;
  const getResourcesFieldValues = useAppSelector(getResourcesFieldValues_selector).data;
  const getResourcesField = useAppSelector(getResourcesField_selector).data;
  const getResourcesFieldStatus = useAppSelector(getResourcesField_selector).status;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllResources_slice());
    dispatch(getGeneralFieldValue_slice());
  }, []);

  useEffect(() => {
    const resources = getAllResources?.map((resource: any) => resource.display_name);
    setAllResources(resources);
    setResourcesDropdown(resources[0]);
  }, [getAllResources]);

  useEffect(() => {
    const resourceFieldId = getAllResources?.find((resource: any) => resource.display_name === resourcesDropdown);
    dispatch(getResourcesField_slice({ resource: resourceFieldId?.id || '' }));
  }, [resourcesDropdown]);

  useEffect(() => {
    if (getGeneralFieldValues) {
      setConditionResourceGeneralFieldValue(getGeneralFieldValues);
      setFieldValueDropDownValue(getGeneralFieldValues[0]?.display_name);
    }
  }, [getGeneralFieldValues]);

  useEffect(() => {
    setResourcesField(getResourcesField);
  }, [getResourcesField]);

  useEffect(() => {
    if (getResourcesFieldValues) {
      setConditionResourceFieldValue(getResourcesFieldValues);
      if (getResourcesFieldValues[0]?.display_name) {
        setFieldValueDropDownValue(getResourcesFieldValues[0]?.display_name);
      }
    }
  }, [getResourcesFieldValues]);

  useEffect(() => {
    const tempFilter = resourcesField?.find((resourceField: any) => resourceField.display_name === resourcePriority);

    const resourceFieldId = getAllResources?.find((resource: any) => resource.display_name === resourcesDropdown);

    const dropDownForSelectedField = resourcesField.find(
      (resourceField: any) => resourceField.display_name === resourcePriority
    );

    if (dropDownForSelectedField) setSelectedFieldType(dropDownForSelectedField.type);

    dispatch(
      getResourcesFieldValue_slice({
        resource: resourceFieldId?.id || '',
        field: tempFilter?.id || '',
      })
    );
  }, [resourcesDropdown, resourcePriority]);

  const onClickCreate = async () => {
    const selectedResource = getAllResources?.find((resource: any) => resource.display_name === resourcesDropdown);

    const tempFilter = resourcesField?.find((resourceField: any) => resourceField.display_name == resourcePriority);

    const conditionalKey_temp = {
      type: 'resource',
      keyName: tempFilter.name || '',
    };

    const fallInWhichCategory = conditionResourceGeneralFieldValue?.find(
      (generalField: any) => generalField?.display_name === fieldValueDropDownValue
    );

    let valueCategory;

    if (fallInWhichCategory) {
      valueCategory = 'contextual-variable';
    } else {
      valueCategory = 'simple';
    }

    let fieldValueTemp;
    fieldValueTemp = conditionResourceGeneralFieldValue?.find(
      (generalField: any) => generalField.display_name == fieldValueDropDownValue
    );

    if (fieldValueTemp === undefined) {
      fieldValueTemp = conditionResourceFieldValue?.find(
        (resourceField: any) => resourceField.display_name == fieldValueDropDownValue
      );
    }

    const conditionalValue_temp = {
      type: valueCategory,
      value: fieldValueTemp.name || '',
    };
    const payload = {
      name: conditionName,
      description: conditionDescription,
      resourceId: selectedResource?.id || '',
      operator: selectedOperator,
      conditionalKey: JSON.stringify(conditionalKey_temp),
      conditionalValue: JSON.stringify(conditionalValue_temp),
    };
    setLoader(true);
    await dispatch(createCondition_slice(payload));
    setLoader(false);
    props.setAddState(false);
  };

  return (
    <Grid sx={{ padding: '18px 23px' }}>
      <Typography variant={'h1'}>Create New Condition</Typography>
      <Grid>
        <Grid>
          <Typography variant="h3" fontSize="15px" marginBottom="6px" marginTop="30px">
            Condition Name *
          </Typography>
          <TextField
            size="small"
            value={conditionName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setConditionName(e.target.value)}
            placeholder="Enter Condition Name"
          />
        </Grid>
        <Grid>
          <Typography variant="h3" fontSize="15px" marginBottom="6px" marginTop="15px">
            Condition Description
          </Typography>
          <TextField
            size="small"
            value={conditionDescription}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setConditionDescription(e.target.value)}
            placeholder="Enter Condition Description"
          />
        </Grid>
        <Grid>
          <Typography variant="h3" fontSize="15px" marginTop="20px" marginBottom="5px">
            Resources
          </Typography>
          <CognisaasDropdown
            name="resources-dropdown"
            type="dropdown"
            sx={{ marginBottom: '15px' }}
            validvalues={allResources}
            onChange={(e: SelectChangeEvent<unknown | string>) => setResourcesDropdown(e.target.value as string)}
            value={resourcesDropdown}
          />
        </Grid>
        {getResourcesFieldStatus === requestState.loading ? (
          <CognisaasSpinner />
        ) : (
          <>
            <Grid>
              <Typography variant="h3" fontSize="15px" marginTop="45px" marginBottom="5px">
                Key
              </Typography>
              <Grid sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography sx={{ marginRight: '10px' }} variant="subtitle1">
                  Where {resourcesDropdown}
                </Typography>
                <Grid>
                  <FormControl>
                    <Select
                      size="small"
                      value={resourcePriority}
                      onChange={(e: SelectChangeEvent<unknown | string>) =>
                        setResourcePriority(e.target.value as string)
                      }
                    >
                      {resourcesField?.map((resourceField: any) => {
                        return (
                          <MenuItem key={resourceField} value={resourceField?.display_name}>
                            <Typography variant="subtitle1">{resourceField?.display_name}</Typography>
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid sx={{ marginTop: '20px' }}>
              <Typography>Operator</Typography>
              <CognisaasSelect
                width="310px"
                value={operatorMapping[selectedOperator]}
                validValues={['is', 'is_not', 'is_in', 'is_not_in', 'has', 'has_not']}
                disabledValues={
                  selectedFieldType === 'ARR' ? ['is', 'is_not', 'is_in', 'is_not_in'] : ['has', 'has_not']
                }
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setSelectedOperator((e.target as HTMLInputElement).value as operatorType)
                }
              />
            </Grid>
            <Grid>
              <Typography variant="h3" fontSize="15px" marginTop="20px" marginBottom="10px">
                Value
              </Typography>
              <Grid>
                <FormControl>
                  <Select
                    size="small"
                    value={fieldValueDropDownValue}
                    onChange={(e: any) => setFieldValueDropDownValue(e.target.value)}
                  >
                    <Typography sx={{ color: '#3978FF', margin: '5px 8px' }} variant="h3">
                      General
                    </Typography>
                    {conditionResourceGeneralFieldValue?.map((obj: any) => {
                      return (
                        <MenuItem key={obj} value={obj?.display_name}>
                          <Typography variant="subtitle1">{obj?.display_name}</Typography>
                        </MenuItem>
                      );
                    })}
                    <Typography sx={{ color: '#3978FF', margin: '5px 8px' }} variant="h3">
                      Auto Values
                    </Typography>
                    {conditionResourceFieldValue?.map((obj: any) => {
                      return (
                        <MenuItem key={obj} value={obj?.display_name}>
                          <Typography variant="subtitle1">{obj?.display_name}</Typography>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </>
        )}
        <Grid sx={{ marginTop: '15px', display: 'flex', justifyContent: 'flex-end' }}>
          <CognisaasButton
            isOutlined={true}
            label="Cancel"
            clickHandler={() => setOpen(true)}
            sx={{ marginRight: '20px' }}
          />
          {loader ? (
            <LoadingButton loading loadingPosition="start" startIcon={<SaveIcon />} variant="outlined">
              Save
            </LoadingButton>
          ) : (
            <Button onClick={() => onClickCreate()} disabled={conditionName.length === 0} variant="contained">
              Save
            </Button>
          )}
          <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              <Typography variant="h1">Alert!</Typography>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Typography variant="h2">
                  You will be redirected to User Management and all your data filled in the form will be lost !
                </Typography>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpen(false)}>Cancel</Button>
              <Button onClick={() => props.setAddState(false)} autoFocus>
                Agree
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddCondition;
