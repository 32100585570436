import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../index';

// onboard form values selectors
export const selectOnboardFormValuesState = (state: RootState) => state.onboardFormValuesState;
export const selectOnboardFormValues = createSelector(
  selectOnboardFormValuesState,
  (selectOnboardFormValues) => selectOnboardFormValues.onboardFormValues
);

// selector to get custom fields data from server and status and error also
export const selectCustomFieldsFromServer = createSelector(
  selectOnboardFormValuesState,
  (selectOnboardFormValues) => selectOnboardFormValues.customFieldsForOnboarding
);

// selector for getting addClient upload status
export const selectAddClientStatusState = createSelector(
  selectOnboardFormValuesState,
  (selectAddClientStatus) => selectAddClientStatus.status
);
export const selectAddClientErrorState = createSelector(
  selectOnboardFormValuesState,
  (selectAddClientError) => selectAddClientError.error
);

// get client uid
export const selectAddedClientUidState = createSelector(
  selectOnboardFormValuesState,
  (selectAddedClientUid) => selectAddedClientUid.clientUid
);

// get client onboard to draft data
export const selectAddAccountToDraftInDb = createSelector(
  selectOnboardFormValuesState,
  (selectClientSlice) => selectClientSlice.onBoardNewAccountToDraft
);

// select custom fields update to server state for all
export const selectCustomFieldsUpdateToDbState = createSelector(
  selectOnboardFormValuesState,
  (selectClientSlice) => selectClientSlice.onboardedFormCustomFieldsUpdate
);

export const selectNewClientUid = createSelector(
  selectOnboardFormValuesState,
  (selectClientSlice) => selectClientSlice.clientUid
);

export const selectAssociationUid = createSelector(
  selectOnboardFormValuesState,
  (selectClientSlice) => selectClientSlice.associationUid
);

export const selectProjectUid = createSelector(
  selectOnboardFormValuesState,
  (selectOnboardState) => selectOnboardState.projectUid
);
export const selectAddingProjectInformation = createSelector(
  selectOnboardFormValuesState,
  (selectOnboardState) => selectOnboardState.addingProjectInformation
);
export const selectCreatingProjectFromProjectTemplate = createSelector(
  selectOnboardFormValuesState,
  (selectOnboardState) => selectOnboardState.creatingProjectFromProjectTemplate
);

export const selectCreatingUsecaseFromUsecaseTemplate = createSelector(
  selectOnboardFormValuesState,
  (selectOnboardState) => selectOnboardState.creatingUsecaseFromUsecaseTemplate
);

export const selectCreatingUsecaseFromUsecaseTemplateInBulk = createSelector(
  selectOnboardFormValuesState,
  (selectOnboardState) => selectOnboardState.creatingUsecaseFromUsecaseTemplateInBulk
);

export const selectOnboardProjectStatusButton = createSelector(
  selectOnboardFormValuesState,
  (selectOnboardState) => selectOnboardState.onboardFormValues.addProject.saveProjectStatus
);
