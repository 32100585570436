import React from 'react';
import { Component } from 'components/ResourceManagement/types/public-types';
import { Box, FlexBox, Text } from 'components/ResourceManagement/design-system/primitives';
import styled from 'styled-components';
import { getUserLetters, stringToHslColor } from 'components/ResourceManagement/utils/transformers';
import { StyledIcon } from '../../design-system/primitives/icon';
import chevronRight from 'assets/svgs/rightArrow.svg';
import AddIcon from '@mui/icons-material/Add';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { GenericModal } from '../shared/Modal';
import {
  selectMainViewData,
  setSelectedProjectById,
  setSelectedResourceById,
  setSelectedTaskById,
  setUpdateTaskDrawerState,
} from 'store/modules/ResourceManagement';
import { ResourceView } from 'components/ResourceManagement/views/ResourceView/resource-view';
import { useModal } from '../shared/useModal';
import { ProjectTile } from './project-tile';
import { setSoftAllocationDrawerState } from 'store/modules/ResourceManagement';
import { getDateInYYYYMMDD } from 'components/ResourceManagement/utils/date';

const StyledMemberText = styled(Text)``;
const StyledMemberFullText = styled(Text) <{
  isMainView: boolean;
}>`
  ${({ isMainView }) => {
    return isMainView
      ? {
        width: '180px',
      }
      : {
        width: '230px',
      };
  }}
`;
const StyledSlotIconWrap = styled(Box)<{
  isVisible: boolean;
}>(({ isVisible }) => {
  return isVisible
    ? {}
    : {
      display: 'none',
    };
});
const StyledSlotBox = styled(Box)`
  background-color: ${({ theme }) => theme.colors.grey_5};
  outline: ${({ theme }) => theme.borders[4]};
  border-left: ${({ theme }) => theme.borders[4]};
  border-right: ${({ theme }) => theme.borders[4]};
`;
const StyledGridBox = styled(Box)(({ theme }) => {
  return {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gridGap: `${theme.space[5]}`,
  };
});
const StyledNameTile = styled(Box) <{
  itemNameLetters: string;
}>`
  ${({ theme, itemNameLetters }) => {
    return `
        background-color: ${stringToHslColor(itemNameLetters, 30, 80)};
        color: ${theme.colors.grey_1};
        border-radius: 50%;
        width: ${theme.space[16]};
        height: ${theme.space[16]};
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
      `;
  }}
`;

const StyledWrapper = styled(Box)`
  display: table;
`;
const StyledBox = styled(Box)<{
  isVisible: boolean;
}>(({ isVisible }) => {
  return isVisible
    ? {}
    : {
      display: 'none',
    };
});

const StyledIconBox = styled(Box)`
  cursor: pointer;
  opacity: 0.5;
`;

export const ComponentListTable: React.FC<{
  rowHeight: number;
  locale: string;
  primeComponents: Component[];
  selectedComponentId: string;
  mode: string;
  setSelectedComponent: (componentId: string) => void;
  onExpanderClick: (component: Component) => void;
}> = ({ rowHeight, primeComponents, mode, onExpanderClick }) => {
  const dispatch = useAppDispatch();
  const selectedMainViewData = useAppSelector(selectMainViewData);

  const { isModalOpen, toggleModal } = useModal();

  const _rowWidth = 320;
  const _rowHeight = Number(rowHeight);

  const isMainView = mode === 'main-view';
  const isProjectView = mode === 'project-view';

  const itemClickedFunc = ({ id, entity }: { id: string; entity: string }) => {
    // console.log('table', { id, mode, entity, });
    if (entity === 'resource') {
      const resourceId = id;
      dispatch(setSelectedResourceById({ resourceId }));
      toggleModal();
    } else if (entity === 'project') {
      const projectId = id;
      const projectObj = selectedMainViewData?.events.length
        ? selectedMainViewData.events.find((item: any) => item.id === projectId)
        : null;
      if (projectObj) {
        const resourceId = projectObj?.componentLinkedTo;
        dispatch(setSelectedResourceById({ resourceId }));
        dispatch(setSelectedProjectById({ projectId }));
        toggleModal();
      }
    } else if (entity === 'task') {
      const taskId = id;
      dispatch(setSelectedTaskById({ taskId }));
      dispatch(setUpdateTaskDrawerState(true));
    }
  };

  const handleSlotClick = (resourceId: string) => {
    const startDate = getDateInYYYYMMDD(new Date());
    dispatch(
      setSoftAllocationDrawerState({
        isOpen: true,
        formMode: 'add',
        id: '',
        resourceId,
        startDate,
        endDate: null,
        allocationType: '',
        projectId: '',
        isError: false,
        errMsg: ''
      })
    );
  };

  return (
    <>
      <StyledWrapper>
        {primeComponents.map((item) => {
          let expanderSymbol = <></>;
          const softAllocationSymbol = <StyledIconBox onClick={() => handleSlotClick(item.id)}><AddIcon /></StyledIconBox>;

          if (item.hideChildren === false) {
            expanderSymbol = <StyledIcon src={chevronRight} deg={270} onClick={() => onExpanderClick(item)} />;
          } else if (item.hideChildren === true) {
            expanderSymbol = <StyledIcon src={chevronRight} deg={90} onClick={() => onExpanderClick(item)} />;
          }
          const itemNameLetters: string = item.name !== undefined ? getUserLetters(item.name) : 'N/A';
          const isItemAvailable = !!(item?.count !== undefined && item.count > 0);
          const isExpanderVisible = isItemAvailable;
          const textVariant = isItemAvailable && (isMainView || isProjectView) ? 'link' : 'paragraph1';
          const isSoftAllocatorVisible = isMainView;
          return (
            <StyledSlotBox key={`${item.id}row`}>
              {item.type === 'resource' ? (
                <FlexBox
                  variant="flexRow"
                  alignItems="center"
                  justifyContent="space-between"
                  mx={15}
                  height={_rowHeight}
                  width={_rowWidth}
                >
                  <StyledGridBox title={item.name}>
                    <StyledNameTile itemNameLetters={itemNameLetters}>
                      <StyledMemberText variant="paragraph2">{itemNameLetters}</StyledMemberText>
                    </StyledNameTile>
                    <StyledMemberFullText
                      variant={textVariant}
                      isMainView={isMainView}
                      onClick={() => itemClickedFunc(item)}
                    >
                      {item.name}
                    </StyledMemberFullText>
                  </StyledGridBox>
                  <StyledSlotIconWrap isVisible={isExpanderVisible}>{expanderSymbol}</StyledSlotIconWrap>
                  <StyledBox isVisible={isSoftAllocatorVisible}>{softAllocationSymbol}</StyledBox>
                </FlexBox>
              ) : (
                <ProjectTile rowHeight={_rowHeight} item={item} isItemAvailable={isItemAvailable} />
              )}
            </StyledSlotBox>
          );
        })}
      </StyledWrapper>
      <GenericModal isModalOpen={isModalOpen} toggleFn={toggleModal}>
        <Box id="resource-management-resource-view">
          <ResourceView />
        </Box>
      </GenericModal>
    </>
  );
};
