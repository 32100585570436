import { projectEntityStandardFieldsDisplayName } from '../Config/EntityDisplayNames';
import { projectEntityStandardFieldsKey } from '../Config/EntityKeys';
import { projectEntityStandardFieldsAggregatable } from '../Config/EntityAggregatable';
import { projectEntityStandardFieldsType } from '../Config/EntityTypes';
import { getAllowedOperations } from '../UtilityFunction';

export const projectEntityStandardFields: any = {
  project_name: {
    displayName: projectEntityStandardFieldsDisplayName.name,
    key: projectEntityStandardFieldsKey.name,
    type: projectEntityStandardFieldsType.name,
    isAggregatable: projectEntityStandardFieldsAggregatable.name,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(projectEntityStandardFieldsType.name),
    toAggregate: false,
    aggregationToPerform: null,
  },
  project_progress: {
    displayName: projectEntityStandardFieldsDisplayName.progress,
    key: projectEntityStandardFieldsKey.progress,
    type: projectEntityStandardFieldsType.progress,
    isAggregatable: projectEntityStandardFieldsAggregatable.progress,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(projectEntityStandardFieldsType.progress),
    toAggregate: false,
    aggregationToPerform: null,
  },
  project_description: {
    displayName: projectEntityStandardFieldsDisplayName.description,
    key: projectEntityStandardFieldsKey.description,
    type: projectEntityStandardFieldsType.description,
    isAggregatable: projectEntityStandardFieldsAggregatable.description,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(projectEntityStandardFieldsType.description),
    toAggregate: false,
    aggregationToPerform: null,
  },
  project_fees: {
    displayName: projectEntityStandardFieldsDisplayName.fee,
    key: projectEntityStandardFieldsKey.fee,
    type: projectEntityStandardFieldsType.fee,
    isAggregatable: projectEntityStandardFieldsAggregatable.fee,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(projectEntityStandardFieldsType.fee),
    toAggregate: false,
    aggregationToPerform: null,
  },
  implementation_fee_billed: {
    displayName: projectEntityStandardFieldsDisplayName.implementationFeeBilled,
    key: projectEntityStandardFieldsKey.implementationFeeBilled,
    type: projectEntityStandardFieldsType.implementationFeeBilled,
    isAggregatable: projectEntityStandardFieldsAggregatable.implementationFeeBilled,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(projectEntityStandardFieldsType.implementationFeeBilled),
    toAggregate: false,
    aggregationToPerform: null,
  },
  implementation_fee_pending: {
    displayName: projectEntityStandardFieldsDisplayName.implementationFeePending,
    key: projectEntityStandardFieldsKey.implementationFeePending,
    type: projectEntityStandardFieldsType.implementationFeePending,
    isAggregatable: projectEntityStandardFieldsAggregatable.implementationFeePending,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(projectEntityStandardFieldsType.implementationFeePending),
    toAggregate: false,
    aggregationToPerform: null,
  },
  project_ARR: {
    displayName: projectEntityStandardFieldsDisplayName.arr,
    key: projectEntityStandardFieldsKey.arr,
    type: projectEntityStandardFieldsType.arr,
    isAggregatable: projectEntityStandardFieldsAggregatable.arr,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(projectEntityStandardFieldsType.arr),
    toAggregate: false,
    aggregationToPerform: null,
  },
  project_reference: {
    displayName: projectEntityStandardFieldsDisplayName.reference,
    key: projectEntityStandardFieldsKey.reference,
    type: projectEntityStandardFieldsType.reference,
    isAggregatable: projectEntityStandardFieldsAggregatable.reference,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(projectEntityStandardFieldsType.reference),
    toAggregate: false,
    aggregationToPerform: null,
  },
  project_value: {
    displayName: projectEntityStandardFieldsDisplayName.value,
    key: projectEntityStandardFieldsKey.value,
    type: projectEntityStandardFieldsType.value,
    isAggregatable: projectEntityStandardFieldsAggregatable.value,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(projectEntityStandardFieldsType.value),
    toAggregate: false,
    aggregationToPerform: null,
  },
  project_needed_status: {
    displayName: projectEntityStandardFieldsDisplayName.neededStatus,
    key: projectEntityStandardFieldsKey.neededStatus,
    type: projectEntityStandardFieldsType.neededStatus,
    isAggregatable: projectEntityStandardFieldsAggregatable.neededStatus,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(projectEntityStandardFieldsType.neededStatus),
    toAggregate: false,
    aggregationToPerform: null,
  },
  project_readinessstate_status: {
    displayName: projectEntityStandardFieldsDisplayName.status,
    key: projectEntityStandardFieldsKey.status,
    type: projectEntityStandardFieldsType.status,
    isAggregatable: projectEntityStandardFieldsAggregatable.status,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(projectEntityStandardFieldsType.status),
    toAggregate: false,
    aggregationToPerform: null,
  },
  project_expansionvalue: {
    displayName: projectEntityStandardFieldsDisplayName.expansionValue,
    key: projectEntityStandardFieldsKey.expansionValue,
    type: projectEntityStandardFieldsType.expansionValue,
    isAggregatable: projectEntityStandardFieldsAggregatable.expansionValue,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(projectEntityStandardFieldsType.expansionValue),
    toAggregate: false,
    aggregationToPerform: null,
  },
  project_priority: {
    displayName: projectEntityStandardFieldsDisplayName.priority,
    key: projectEntityStandardFieldsKey.priority,
    type: projectEntityStandardFieldsType.priority,
    isAggregatable: projectEntityStandardFieldsAggregatable.priority,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(projectEntityStandardFieldsType.priority),
    toAggregate: false,
    aggregationToPerform: null,
  },
  project_planned_startdate: {
    displayName: projectEntityStandardFieldsDisplayName.plannedStartDate,
    key: projectEntityStandardFieldsKey.plannedStartDate,
    type: projectEntityStandardFieldsType.plannedStartDate,
    isAggregatable: projectEntityStandardFieldsAggregatable.plannedStartDate,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(projectEntityStandardFieldsType.plannedStartDate),
    toAggregate: false,
    aggregationToPerform: null,
  },
  project_startdate: {
    displayName: projectEntityStandardFieldsDisplayName.startDate,
    key: projectEntityStandardFieldsKey.startDate,
    type: projectEntityStandardFieldsType.startDate,
    isAggregatable: projectEntityStandardFieldsAggregatable.startDate,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(projectEntityStandardFieldsType.startDate),
    toAggregate: false,
    aggregationToPerform: null,
  },
  eta_forprojectdelivery: {
    displayName: projectEntityStandardFieldsDisplayName.etaForDelivery,
    key: projectEntityStandardFieldsKey.etaForDelivery,
    type: projectEntityStandardFieldsType.etaForDelivery,
    isAggregatable: projectEntityStandardFieldsAggregatable.etaForDelivery,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(projectEntityStandardFieldsType.etaForDelivery),
    toAggregate: false,
    aggregationToPerform: null,
  },
  project_requiredbydate: {
    displayName: projectEntityStandardFieldsDisplayName.requiredByDate,
    key: projectEntityStandardFieldsKey.requiredByDate,
    type: projectEntityStandardFieldsType.requiredByDate,
    isAggregatable: projectEntityStandardFieldsAggregatable.requiredByDate,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(projectEntityStandardFieldsType.requiredByDate),
    toAggregate: false,
    aggregationToPerform: null,
  },
  project_deliverydate: {
    displayName: projectEntityStandardFieldsDisplayName.deliveryDate,
    key: projectEntityStandardFieldsKey.deliveryDate,
    type: projectEntityStandardFieldsType.deliveryDate,
    isAggregatable: projectEntityStandardFieldsAggregatable.deliveryDate,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(projectEntityStandardFieldsType.deliveryDate),
    toAggregate: false,
    aggregationToPerform: null,
  },
  project_created_on: {
    displayName: projectEntityStandardFieldsDisplayName.createdOn,
    key: projectEntityStandardFieldsKey.createdOn,
    type: projectEntityStandardFieldsType.createdOn,
    isAggregatable: projectEntityStandardFieldsAggregatable.createdOn,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(projectEntityStandardFieldsType.createdOn),
    toAggregate: false,
    aggregationToPerform: null,
  },
  project_stage: {
    displayName: projectEntityStandardFieldsDisplayName.stage,
    key: projectEntityStandardFieldsKey.stage,
    type: projectEntityStandardFieldsType.stage,
    isAggregatable: projectEntityStandardFieldsAggregatable.stage,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(projectEntityStandardFieldsType.stage),
    toAggregate: false,
    aggregationToPerform: null,
    validValues: [],
  },
  project_deliverymanager: {
    displayName: projectEntityStandardFieldsDisplayName.deliveryManager,
    key: projectEntityStandardFieldsKey.deliveryManager,
    type: projectEntityStandardFieldsType.deliveryManager,
    isAggregatable: projectEntityStandardFieldsAggregatable.deliveryManager,
    isCustomField: false,
    allowedOperationsList: getAllowedOperations(projectEntityStandardFieldsType.deliveryManager),
    toAggregate: false,
    aggregationToPerform: null,
  },
  // project_comment: {
  //   displayName: projectEntityStandardFieldsDisplayName.comment,
  //   key: projectEntityStandardFieldsKey.comment,
  //   type: projectEntityStandardFieldsType.comment,
  //   isAggregatable: projectEntityStandardFieldsAggregatable.comment,
  //   isCustomField: false,
  //   allowedOperationsList: getAllowedOperations(projectEntityStandardFieldsType.comment),
  //   toAggregate: false,
  //   aggregationToPerform: null,
  // },
};
