import { ganttPluginsConfig, getGanttColumns, getZoomConfig } from './config';
import { TGanttMilestoneTask, TGanttTask } from './types';
import { GanttStatic } from 'dhtmlx-gantt';
import { endOfDay } from 'date-fns';

function getGanttDateBoundaryObj({ startDate, endDate }: { startDate: string; endDate: string }): {
  ganttStartDate: Date;
  ganttEndDate: Date;
} {
  const ganttStartDate = startDate ? new Date(startDate) : new Date();
  const ganttEndDate = endDate ? new Date(endDate) : new Date();
  const preStepCount = 2;
  const postStepCount = 6;

  ganttStartDate.setDate(1);
  ganttStartDate.setMonth(ganttStartDate.getMonth() - preStepCount);
  ganttEndDate.setDate(0);
  ganttEndDate.setMonth(ganttEndDate.getMonth() + postStepCount);
  return { ganttStartDate, ganttEndDate };
}

export function loadConfig(
  gantt: GanttStatic,
  customLabels: any,
  workingHours: number,
  ganttDateBoundaryObj: { startDate: string; endDate: string },
  isWorkTimeOn: boolean
) {
  // console.log('CP HIGHLIGHT:', highlightCriticalPath);
  const { ganttStartDate, ganttEndDate } = getGanttDateBoundaryObj(ganttDateBoundaryObj);
  // gantt.config.layout = getGanttResourceLayout(gantt, customLabels, workingHours);
  gantt.config.columns = getGanttColumns(gantt, customLabels);
  gantt.locale.labels.section_owner = 'Owner';
  gantt.config.resource_store = 'resource';
  gantt.config.resource_property = 'owner_id';
  gantt.config.order_branch = true;
  gantt.config.show_errors = false;
  gantt.config.open_tree_initially = true;
  gantt.config.order_branch = true;
  gantt.config.open_tree_initially = true;
  gantt.config.drag_progress = false;
  gantt.config.drag_links = true;
  gantt.config.links.readonly_property = false;
  gantt.config.date_format = '%Y-%m-%d %H:%i';
  gantt.config.date_grid = '%d-%m-%Y';
  gantt.config.bar_height = 25;
  gantt.config.row_height = 60;
  gantt.config.scale_height = 60;
  gantt.config.work_time = isWorkTimeOn;
  gantt.config.initial_scroll = false;
  gantt.config.min_duration = 24 * 60 * 60 * 1000;
  gantt.config.drag_project = false;
  gantt.config.autoscroll = true;
  gantt.config.autoscroll_speed = 50;
  gantt.config.fit_tasks = true;
  gantt.config.duration_unit = 'hour';
  gantt.config.duration_step = 1;
  gantt.config.show_tasks_outside_timescale = true;
  gantt.config.start_date = ganttStartDate;
  gantt.config.end_date = ganttEndDate;
  // gantt.config.xml_date = '%d-%M-%Y'; // Here the dates are parsed accordinly
  // gantt.config.date_format = '%Y-%m-%d';
  gantt.config.time_step = 1440;
  gantt.config.round_dnd_dates = true;
  gantt.ext.zoom.init(getZoomConfig(gantt)); //initializing the zooming in the gant object
  gantt.ext.zoom.setLevel('day'); //default view for the gantt
  gantt.plugins(ganttPluginsConfig);
  // gantt.config.lightbox.sections = [
  //     {
  //       name: 'description',
  //       height: 38,
  //       map_to: 'text',
  //       type: 'textarea',
  //       focus: true,
  //     },
  //     {
  //       name: 'owner',
  //       height: 22,
  //       map_to: 'owner_id',
  //       type: 'select',
  //       options: gantt.serverList('people'),
  //     },
  //     { name: 'time', type: 'duration', map_to: 'auto' },
  //   ];
}

export function loadTemplates(gantt: GanttStatic, workingHours: number, customerAmberThreshold: number) {
  gantt.templates.tooltip_date_format = function getTooltipDateFormat(date: Date) {
    const formatFunc = gantt.date.date_to_str('%d-%M-%Y');
    return formatFunc(date);
  }; // Formatting the tooltip dates as consistent in all over the product
  gantt.templates.resource_cell_class = function (start_date, end_date, resource, tasks) {
    const css = [];
    css.push('resource_marker');
    const taskTotalHours = tasks.length * workingHours;
    const customerAmberThresholdDecimalVal = customerAmberThreshold / 100 + 1;

    const amberLowerVal = Math.floor(workingHours * customerAmberThresholdDecimalVal);
    const amberUpperVal = Math.floor(amberLowerVal * customerAmberThresholdDecimalVal);

    if (taskTotalHours > amberUpperVal) {
      css.push('workday_red');
    } else if (taskTotalHours >= amberLowerVal && taskTotalHours <= amberUpperVal) {
      css.push('workday_amber');
    } else {
      css.push('workday_green');
    }
    return css.join(' ');
  };

  gantt.templates.resource_cell_value = function getResourceCellValue(
    start_date: Date,
    end_date: Date,
    resource: any,
    tasks: any[]
  ): string {
    return '<div>' + tasks.length * workingHours + '</div>';
  };
  gantt.templates.task_class = function (start, end, task): string {
    if (task.planned_end) {
      const classes = ['has-baseline'];
      if (end.getTime() > new Date(task.planned_end).getTime()) {
        classes.push('overdue');
      }
      return classes.join(' ');
    }
    return '';
  };

  gantt.templates.task_class = function (start, end, task): string {
    return task.color;
  };

  gantt.templates.rightside_text = function (
    start: any,
    end: { getTime: () => number },
    task: { planned_end: string | number | Date }
  ): string {
    if (task.planned_end) {
      if (end.getTime() > endOfDay(new Date(task.planned_end)).getTime()) {
        const overdue = Math.ceil(
          Math.abs((end.getTime() - endOfDay(new Date(task.planned_end)).getTime()) / (24 * 60 * 60 * 1000))
        );
        const text = 'Overdue: ' + overdue + ' days';
        return text;
      }
    }
    return '';
  };

  const pointerEventEnabledEntities = ['task'];
  gantt.templates.task_class = (start, end, task) => {
    if (pointerEventEnabledEntities.includes(task.entity)) {
      return 'with-pointer-events';
    }
    // if ([...pointerEventEnabledEntities, 'project'].includes(task.entity)) {
    //   return 'with-default-pointer-events';
    // }
    return 'without-pointer-events';
  };

  gantt.templates.grid_row_class = function (start, end, task) {
    if (pointerEventEnabledEntities.includes(task.entity)) {
      return 'with-pointer-events';
    }
    return '';
  };
  gantt.templates.timeline_cell_class = function (task, date) {
    if (!gantt.isWorkTime({ date: date, task: task })) return 'week_end';
    return '';
  };
}

export function loadResourcesStore(gantt: GanttStatic) {
  function _initItem(item: { [x: string]: any; parent: string | number; open: boolean }) {
    item.parent = item.parent || gantt.config.root_id;
    item[gantt.config.resource_property] = item.parent;
    item.open = true;
    return item;
  }

  const resourcesStore: any = gantt.createDatastore({
    name: gantt.config.resource_store,
    type: 'treeDatastore',
    initItem: (item: { [x: string]: any; parent: string | number; open: boolean }) => _initItem(item),
  });

  resourcesStore.attachEvent('onParse', function () {
    const people: any[] = [];
    resourcesStore.eachItem(function (res: { id: any; text: any }) {
      if (!resourcesStore.hasChild(res.id)) {
        const copy = gantt.copy(res);
        copy.key = res.id;
        copy.label = res.text;
        copy.unit = 'hours';
        people.push(copy);
      }
    });
    gantt.updateCollection('people', people);
  });

  return resourcesStore;
}

export function loadAttachEvents(gantt: GanttStatic) {
  gantt.attachEvent(
    'onLoadEnd',
    function () {
      return false;
    },
    {}
  );

  gantt.attachEvent(
    'onRowDragEnd',
    function () {
      return false;
    },
    {}
  );

  gantt.attachEvent(
    'onTaskDblClick',
    function () {
      return false;
    },
    {}
  );

  gantt.attachEvent(
    'onTaskLoading',
    function (task: { planned_start: any; planned_end: any }) {
      task.planned_start = gantt.date.parseDate(task.planned_start, 'xml_date');
      task.planned_end = gantt.date.parseDate(task.planned_end, 'xml_date');
      return true;
    },
    {}
  );

  const draggableEntitiesList = ['task'];
  gantt.attachEvent(
    'onBeforeTaskDrag',
    function (id) {
      const taskObj = gantt.getTask(id);
      if (!draggableEntitiesList.includes(taskObj.entity)) {
        taskObj.readonly = true;
        return false;
      }
      return true;
    },
    {}
  );

  gantt.attachEvent(
    'onTaskRowClick',
    function () {
      return false;
    },
    {}
  );

  gantt.attachEvent(
    'onEmptyClick',
    function () {
      return false;
    },
    {}
  );

  // gantt.attachEvent(
  //   'onError',
  //   function (errorMessage) {
  //     // eslint-disable-next-line no-debugger
  //     debugger;
  //     return true;
  //   },
  //   {}
  // );

  // gantt.attachEvent(
  //   'onGanttReady',
  //   function () {
  //     const domUtils = gantt.utils.dom;
  //     let taskId: any = null;
  //     let clickOffset: any = null;
  //     let dndRequested: any = false;
  //     let dndActivated: any = false;
  //     let startTimestamp: any = null;
  //     let startPosition: any = null;

  //     // @ts-expect-error: Let's ignore a compile
  //     gantt.event(gantt.$task_data, 'mousedown', function (e) {
  //       const draggableElement = domUtils.closest(e.target, '.baseline');
  //       if (draggableElement) {
  //         taskId = draggableElement.getAttribute('data-task-id');
  //         clickOffset = domUtils.getRelativeEventPosition(e, draggableElement).x;

  //         dndRequested = true;
  //         startTimestamp = Date.now();
  //         // @ts-expect-error: Let's ignore a compile
  //         startPosition = domUtils.getRelativeEventPosition(e, gantt.$task_data);
  //       }
  //     });
  //     // @ts-expect-error: Let's ignore a compile
  //     gantt.event(gantt.$container, 'mousemove', function (e) {
  //       if (dndRequested && gantt.isTaskExists(taskId)) {
  //         // @ts-expect-error: Let's ignore a compile
  //         const position = domUtils.getRelativeEventPosition(e, gantt.$task_data).x;
  //         if (!dndActivated) {
  //           if (Math.abs(position - startPosition.x) > 2 || Date.now() - startTimestamp > 500) {
  //             dndActivated = true;
  //           }
  //         }

  //         const datePosition = position - clickOffset;
  //         if (dndActivated && datePosition > 0) {
  //           const task = gantt.getTask(taskId);
  //           const plannedDuration = gantt.calculateDuration({
  //             start_date: task.planned_start,
  //             end_date: task.planned_end,
  //             task: task,
  //           });

  //           task.planned_start = gantt.dateFromPos(datePosition);
  //           task.planned_end = gantt.calculateEndDate({
  //             start_date: task.planned_start,
  //             duration: plannedDuration,
  //             task: task,
  //           });
  //           gantt.refreshTask(taskId);
  //         }
  //       }
  //     });
  //     // @ts-expect-error: Let's ignore a compile
  //     gantt.event(gantt.$container, 'mouseup', function () {
  //       if (dndActivated) {
  //         // @ts-expect-error: Let's ignore a compile
  //         gantt.updateTask(taskId);
  //       }

  //       taskId = null;
  //       clickOffset = null;
  //       dndRequested = false;
  //       dndActivated = false;
  //       startTimestamp = null;
  //       startPosition = null;
  //     });
  //   },
  //   {}
  // );
}

function getArePlannedDatesSame(task: TGanttMilestoneTask) {
  let check = false;
  if (new Date(task.planned_start).getTime() === new Date(task.planned_end).getTime()) {
    check = true;
  }
  return check;
}

export function loadBaselineLayer(gantt: GanttStatic, customLabels: any) {
  // baseline addition is done here
  gantt.addTaskLayer({
    renderer: {
      render: function drawBaselineByPlannedDates(task: TGanttTask | TGanttMilestoneTask) {
        if (task.planned_start && task.planned_end && task.type === 'milestone') {
          const sizes = gantt.getTaskPosition(task, task.planned_start, task.planned_end);
          const el = document.createElement('div');
          const allowedEntitiesList = ['task'];
          const arePlannedDatesSame = getArePlannedDatesSame(task);
          let cssOfBaseline = 'baseline';
          let left = sizes.left;
          if (arePlannedDatesSame) {
            left = sizes.left - 7;
            cssOfBaseline = 'baseline-milestone';
          }
          el.className = allowedEntitiesList.includes(task.entity)
            ? `${cssOfBaseline} with-pointer-events`
            : `${cssOfBaseline} without-pointer-events`;
          el.style.left = left + 'px';
          el.style.width = sizes.width - 3 + 'px';
          el.style.top = sizes.top + gantt.config.bar_height + 12 + 'px';
          // Adding tooltip for the baselines
          el.setAttribute(gantt.config.task_attribute, task.id);
          gantt.ext.tooltips.tooltipFor({
            selector: `.${cssOfBaseline}`,
            html: function (event: any, node: { getAttribute: (arg0: string) => any }) {
              const taskId = node.getAttribute(gantt.config.task_attribute);
              const task = gantt.getTask(taskId);
              return (
                `<b>${customLabels.taskTooltipLabel}:</b> ` +
                task.text +
                '<br/><b>Planned Start date:</b> ' +
                gantt.templates.tooltip_date_format(task.planned_start) +
                '<br/><b>Planned End date:</b> ' +
                gantt.templates.tooltip_date_format(task.planned_end)
              );
            },
          });
          return el;
        }

        if (task.planned_start && task.planned_end && task.type !== 'milestone') {
          const sizes = gantt.getTaskPosition(task, task.planned_start, task.planned_end);
          const el = document.createElement('div');
          const allowedEntitiesList = ['task'];
          el.className = allowedEntitiesList.includes(task.entity)
            ? 'baseline with-pointer-events'
            : 'baseline without-pointer-events';
          el.style.left = sizes.left + 'px';
          el.style.width = sizes.width - 3 + 'px';
          el.style.top = sizes.top + gantt.config.bar_height + 12 + 'px';
          // Adding tooltip for the baselines
          el.setAttribute(gantt.config.task_attribute, task.id);
          gantt.ext.tooltips.tooltipFor({
            selector: '.baseline',
            html: function (event: any, node: { getAttribute: (arg0: string) => any }) {
              const taskId = node.getAttribute(gantt.config.task_attribute);
              const task = gantt.getTask(taskId);
              return (
                `<b>${customLabels.taskTooltipLabel}:</b> ` +
                task.text +
                '<br/><b>Planned Start date:</b> ' +
                gantt.templates.tooltip_date_format(task.planned_start) +
                '<br/><b>Planned End date:</b> ' +
                gantt.templates.tooltip_date_format(task.planned_end)
              );
            },
          });
          return el;
        }
        return false;
      },
      // define getRectangle in order to hook layer with the smart rendering
      // getRectangle: function (task: TGanttItem) {
      //   if (task.planned_start && task.planned_end) {
      //     return gantt.getTaskPosition(task, task.planned_start, task.planned_end);
      //   }
      //   return null;
      // },
    },
  });
}

export function loadWorkTime(gantt: GanttStatic, nonWorkingDaysInNumber: any[], holidays: any[]) {
  holidays.forEach((holidayDate: string) => {
    if (holidayDate) gantt.setWorkTime({ date: new Date(holidayDate), hours: false });
  });
  nonWorkingDaysInNumber.forEach((dayNumber: number) => {
    if (dayNumber) gantt.setWorkTime({ day: dayNumber, hours: false });
  });
}
