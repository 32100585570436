import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../..';

export const selectReportsClientsState = (state: RootState) => state.reportsClientsState;
export const selectReportsClients = createSelector(
  selectReportsClientsState,
  (reportsClientsState) => reportsClientsState.clients
);
export const selectReportsClientsStatus = createSelector(
  selectReportsClientsState,
  (reportsClientsState) => reportsClientsState.status
);
export const selectReportsClientsError = createSelector(
  selectReportsClientsState,
  (reportsClientsState) => reportsClientsState.error
);
