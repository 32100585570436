import { TextField } from '@mui/material';
import { useState } from 'react';

interface props {
  value: string;
  changedValue: any;
  width?: string;
  type: 'text' | 'number';
}

const CognisaasTextEditor = (props: props) => {
  const [val, setVal] = useState<string>('');
  return (
    <TextField
      type={props.type}
      size="small"
      sx={{ width: props.width ? props.width : '310px' }}
      value={props.value}
      onChange={(e: any) => props.changedValue(e.target.value)}
    />
  );
};

export default CognisaasTextEditor;
