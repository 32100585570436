import 'dhtmlx-gantt';
import 'dhtmlx-gantt/codebase/skins/dhtmlxgantt_terrace.css';
import './Gantt.css';
import { GanttToolbar } from './GanttToolbar';
import UpdateTaskDrawer from 'components/TaskModule/TaskView/UpdateTaskDrawer';
import { useUsecaseGantt } from './useUsecaseGantt';

function GanttUsecase(usecaseDetail: any) {
  const {
    taskData,
    isUpdateTaskDrawerOpen,
    isWorkTimeOn,
    handleClose,
    handleOnZoomIn,
    handleOnZoomOut,
    handleOnExportToExcel,
    handleOnTodayClick,
    handleOnToogleWorkTime,
    handleCriticalPathHighlight,
  } = useUsecaseGantt(usecaseDetail);

  const ganttToolbarProps = {
    isWorkTimeOn,
    handleOnZoomIn,
    handleOnZoomOut,
    handleOnExportToExcel,
    handleOnTodayClick,
    handleOnToogleWorkTime,
    handleCriticalPathHighlight,
  };

  return (
    <>
      <GanttToolbar {...ganttToolbarProps} />
      <div className="gantt_usecase" id="gantt_usecase"></div>
      {isUpdateTaskDrawerOpen && taskData?.client_uid ? (
        <UpdateTaskDrawer
          client={{ client_uid: taskData.client_uid }}
          taskDetail={taskData}
          drawerState={isUpdateTaskDrawerOpen}
          onClose={handleClose}
        />
      ) : null}
    </>
  );
}

export default GanttUsecase;
