// ! THIS MODAL WILL APPEAR WHEN USER CLICKS ON SUBTASK NAME IN SUBTASK LIST

import * as React from 'react';
import { Formik } from 'formik';
import { Stack, Typography, TextField, TextareaAutosize, FormControl } from '@mui/material';
import CognisaasSelect from '../../../shared/CognisaasSelect/CognisaasSelect';
import { useState, useEffect } from 'react';
import { CognisaaasTooltip } from '../../../shared/CognisaasTooltip/CognisaasTooltip';
import CognisaasButton from '../../../shared/CognisaasButton/CognisaasButton';
import { useDispatch } from 'react-redux';
import format from 'date-fns/format';
// import { ReactComponent as EmptyMileStone } from '../../../../assets/svgs/EmptyMileStone.svg';
import { taskPriorityValues } from '../../taskUtils';
import { updateProjectSubtask, updateUsecaseSubtask } from '../../../../store/modules/Task/slice';
import { getUserUid } from 'utils/getUid';
import CognisaasCalenderNew from '../../../shared/CognisaasCalenderNew/CognisaasCalenderNew';
import { usePreselectedProjectMembers } from 'components/shared/Hooks/usePreselectedProjectMembers';
import {
  label_delivery_date,
  label_due_date,
  label_task,
} from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { selectProjectInformation } from 'store/modules/Project/selector';
import { useAppSelector } from 'store/hooks';

const UpdateSubtackModal = (props: any) => {
  const taskDetail = props.taskDetail;
  const closeModal = props.onClose;
  console.log(taskDetail);
  const [users, setUsers] = useState<any[]>([]);
  const mileStoneTask = taskDetail.milestone_subtask == '0' ? false : true;
  const shareWithClient = taskDetail.client_check == '0' ? false : true;
  const dispatch = useDispatch();
  const project = useAppSelector(selectProjectInformation);

  const initialValues = {
    taskName: taskDetail.subtask_detail,
    stage: taskDetail.subtask_stage,
    // plannedStartDate: ,
    actualStartDate: taskDetail.subtask_startdate,
    priority: taskDetail.subtask_priority,
    taskOwner: taskDetail.subtask_owner,
    implementationFee: taskDetail.subtask_percentage,
    etaForDelivery: taskDetail.subtaskclosed_date,
    pushToJira: taskDetail.jira_ind == '0' ? false : true,
    mileStone: mileStoneTask,
    shareWithClient: shareWithClient,
    taskDescription: taskDetail.subtask_description,
    dueDate: taskDetail.subtask_due_date,
    startDate: taskDetail.subtask_start_date,
    deliveryDate: taskDetail.subtask_closed_date,
  };

  console.log(initialValues, users);

  const { preselectedProjectMembers, preselectedClientContacts } = usePreselectedProjectMembers(taskDetail.project_uid,project?.client_uid);
  const taskOwnerDropdownOptions =
    taskDetail.is_team_task === '1'
      ? preselectedProjectMembers.map((user: any) => user.user_username)
      : preselectedClientContacts.map((user: any) => user.user_username);
  console.log('@SubTask', {
    preselectedProjectMembers,
    preselectedClientContacts,
    taskDetail,
    taskOwnerDropdownOptions,
  });
  const notAssignedStr = 'Not Assigned';
  const taskOwnerDropdownOptionsWithNotAssigned = taskOwnerDropdownOptions?.length
    ? [...taskOwnerDropdownOptions, notAssignedStr]
    : [notAssignedStr];

  const saveSubtask = (values: any) => {
    const detail = {
      blocked_by_dependency: '0',
      dependency_details: [],
      jira_ind: values.pushToJira == false ? '0' : '1',
      jira_priority: '',
      jira_project_id: '',
      last_user_modified: '',
      project_uid: taskDetail.project_uid,
      starting_time: 0,
      subtask_closed_date: values.deliveryDate,
      subtask_description: values.taskDescription,
      subtask_detail: values.taskName,
      subtask_due_date: values.dueDate,
      subtask_planned_start_date: '',
      subtask_owner: values.taskOwner,
      subtask_percentage: '0',
      subtask_priority: values.priority,
      subtask_stage: values.stage,
      subtask_start_date: values.startDate,
      subtask_uid: taskDetail.subtask_uid,
      time_remaining: '0',
      total_time_spent: '0',
      user_uid: getUserUid(values.taskOwner),
      case_uid: taskDetail.case_uid ? taskDetail.case_uid : null,
    };
    if (taskDetail.project_uid && !taskDetail.case_uid) {
      dispatch(updateProjectSubtask(detail));
    } else {
      dispatch(updateUsecaseSubtask(detail));
    }
    closeModal();

    console.log(detail);
  };

  useEffect(() => {
    if (preselectedProjectMembers.length > 0) {
      getUserListArray();
    }
  }, [preselectedProjectMembers]);

  const getUserListArray = () => {
    const list = preselectedProjectMembers.map((user: any) => {
      return user.user_username;
    });
    console.log(list);
    list.push('Not Assigned');
    setUsers(list);
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          saveSubtask({
            ...values,
            startDate: format(new Date(values.startDate), 'yyyy-MM-dd HH:mm:ss'),
            dueDate: format(new Date(values.dueDate), 'yyyy-MM-dd HH:mm:ss'),
            deliveryDate: format(new Date(values.deliveryDate), 'yyyy-MM-dd HH:mm:ss'),
          });
        }}
      >
        {(props: any) => (
          <form>
            <Stack ml={2} my={2}>
              <Stack mb={2}>
                <Typography>Update Sub {label_task}</Typography>
              </Stack>
              <Typography variant="subtitle2">{label_task} Name</Typography>
              <Stack direction="row">
                <FormControl>
                  <TextField
                    name="taskName"
                    value={props.values.taskName}
                    autoComplete="off"
                    onChange={props.handleChange}
                    style={{ width: '310px' }}
                    size="small"
                  ></TextField>
                </FormControl>
              </Stack>

              <Stack my={2} spacing={2} direction="row">
                <Stack>
                  <Typography variant="subtitle2">{label_task} Stage</Typography>
                  <FormControl>
                    <CognisaasSelect
                      name="stage"
                      width="200px"
                      onChange={props.handleChange}
                      value={props.values.stage}
                      validValues={['Not Started', 'In Progress', 'Blocked', 'Done']}
                    ></CognisaasSelect>
                  </FormControl>
                </Stack>
                <Stack>
                  <Typography variant="subtitle2">{label_task} Owner</Typography>
                  <CognisaasSelect
                    name="taskOwner"
                    width="200px"
                    value={props.values.taskOwner}
                    onChange={props.handleChange}
                    validValues={taskOwnerDropdownOptionsWithNotAssigned}
                  ></CognisaasSelect>
                </Stack>
                <Stack>
                  <Typography variant="subtitle2">Priority</Typography>
                  <CognisaasSelect
                    name="priority"
                    width="200px"
                    onChange={props.handleChange}
                    value={props.values.priority}
                    validValues={taskPriorityValues()}
                  ></CognisaasSelect>
                </Stack>
              </Stack>
              <Stack my={2} spacing={2} direction="row">
                <CognisaaasTooltip
                  title={
                    <Typography variant="subtitle1">
                      {label_task} Start date will be system generated when this {label_task} moves to In progress
                    </Typography>
                  }
                >
                  <Stack>
                    <React.Fragment>
                      <Typography variant="subtitle2">Actual Start Date</Typography>
                      <CognisaasCalenderNew
                        width="200px"
                        name="startDate"
                        disabled={true}
                        value={new Date(props.values.startDate)}
                        handleChange={(e: any) => props.setFieldValue('startDate', new Date(e))}
                      />
                    </React.Fragment>
                  </Stack>
                </CognisaaasTooltip>

                <Stack>
                  <Typography variant="subtitle2">{label_due_date}*</Typography>
                  <CognisaasCalenderNew
                    name="dueDate"
                    width="200px"
                    value={new Date(props.values.dueDate)}
                    handleChange={(e: any) => props.setFieldValue('dueDate', new Date(e))}
                  />
                </Stack>
                <Stack>
                  <Typography variant="subtitle2">{label_delivery_date}</Typography>
                  <CognisaasCalenderNew
                    name="deliveryDate"
                    width="200px"
                    value={new Date(props.values.deliveryDate)}
                    handleChange={(e: any) => props.setFieldValue('deliveryDate', new Date(e))}
                  />
                </Stack>
              </Stack>
              <Stack my={2} spacing={2} direction="row">
                <Stack>
                  <Typography variant="subtitle2">Implementation Fee</Typography>
                  <TextField
                    name="implementationFee"
                    onChange={props.handleChange}
                    type="number"
                    style={{ width: '200px' }}
                    size="small"
                    placeholder="0%"
                  ></TextField>
                </Stack>
              </Stack>
              <Stack my={2} direction="row">
                <Stack direction="column">
                  <Typography variant="subtitle2">{label_task} Description</Typography>
                  <TextareaAutosize
                    name="taskDescription"
                    value={props.values.taskDescription}
                    onChange={props.handleChange}
                    maxRows={15}
                    aria-label="maximum height"
                    placeholder="Enter description here"
                    defaultValue=""
                    style={{ width: 420, height: 88 }}
                  />
                </Stack>
              </Stack>
              {/* <Stack my={2} direction="row" spacing={6}>
                <Stack>
                  <Stack spacing={1} direction="row">
                    <EmptyMileStone />
                    <Typography variant="subtitle2">Its a milestone</Typography>
                  </Stack>
                  <Stack>
                    <Switch name="mileStone" checked={props.values.mileStone} onChange={props.handleChange}></Switch>
                  </Stack>
                </Stack>

                <Stack>
                  <Stack spacing={1} direction="row">
                    <Typography variant="subtitle2">Share with client</Typography>
                  </Stack>
                  <Stack>
                    <Switch
                      name="shareWithClient"
                      checked={props.values.shareWithClient}
                      onChange={props.handleChange}
                    ></Switch>
                  </Stack>
                </Stack>
              </Stack> */}
              <Stack my={2} justifyContent="flex-end" direction="row">
                <Stack mb={2} spacing={1} direction="row">
                  <CognisaasButton isOutlined={true} clickHandler={() => closeModal()} label="Cancel" />
                  <CognisaasButton type="submit" clickHandler={props.handleSubmit} isOutlined={false} label="Save" />
                </Stack>
              </Stack>
            </Stack>
          </form>
        )}
      </Formik>
    </>
  );
};

export default UpdateSubtackModal;
