import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
  Autocomplete,
  Button,
  ButtonGroup,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
  IconButton,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';

import CognisaasDropdown from 'components/shared/CognisaasDropdown/CognisaasDropdown';
import {
  createPolicy_slice,
  getAllConditions_slice,
  getAllResources_slice,
  getGeneralFieldValue_slice,
  getResourcesField_slice,
} from 'store/modules/RBAC/slice';
import { getAllConditions_selector, getAllResources_selector } from 'store/modules/RBAC/selector';
import { useAppSelector } from 'store/hooks';
import { requestState } from 'store/modules/types';
import CognisaasSpinner from 'components/shared/CognisaasSpinner/CognisaasSpinner';
import { AddPolicyPayload, AddRBACProps } from 'components/RBACv2/interfaces';
import { LoadingButton } from '@mui/lab';
import CognisaasButton from 'components/shared/CognisaasButton/CognisaasButton';

const AddPolicy = (props: AddRBACProps) => {
  const [policyName, setPolicyName] = useState<string>('');
  const [policyDescription, setPolicyDescription] = useState<string>('');
  const [activeButton, setActiveButton] = useState<1 | 2>(1);
  const [resourcesDropdown, setResourcesDropdown] = useState<string>('');
  const [selectedRolesDropdown, setSelectedAccessDropdown] = useState<any[]>([]);
  const [allConditions, setAllConditions] = useState<any[]>([]);
  const [autoCompleteValue, setAutoCompleteValue] = useState<string>('');
  const [selectedConditions, setSelectedConditions] = useState<any[]>([]);
  const [allResources, setAllResources] = useState<string[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false); // used as form loader

  const getAllResources = useAppSelector(getAllResources_selector).data;
  const getAllConditions = useAppSelector(getAllConditions_selector).data;
  const getAllConditionsStatus = useAppSelector(getAllConditions_selector).status;

  const dispatch = useDispatch();

  const onClickCreate = async () => {
    console.log('HELLO HEREE');
    const actionIds = selectedRolesDropdown?.map((role: any) => {
      const resource = getAllResources[0]?.actions?.find((resource: any) => {
        return resource?.name === role;
      });
      return resource?.id || '';
    });
    const selectedResource = getAllResources?.find((resource: any) => resource.display_name === resourcesDropdown);
    const selectedConditionsIds = selectedConditions?.map((condition: any) => condition.id);
    const payload: AddPolicyPayload = {
      name: policyName,
      description: policyDescription,
      resourceId: selectedResource?.id || '',
      effect: activeButton === 1 ? 'A' : 'D',
      access: actionIds,
      conditionIds: selectedConditionsIds,
    };
    console.log(payload);
    setLoader(true);
    await dispatch(createPolicy_slice(payload));
    setLoader(false);
    props.setAddState(false);
  };

  useEffect(() => {
    dispatch(getAllResources_slice());
    dispatch(getGeneralFieldValue_slice());
  }, []);

  useEffect(() => {
    const temp = getAllResources?.map((obj: any) => {
      return obj.display_name;
    });
    setAllResources(temp);
    setResourcesDropdown(temp[0]);
  }, [getAllResources]);

  useEffect(() => {
    setAllConditions(getAllConditions);
    // if(getAllConditions){
    // }
  }, [getAllConditions]);

  useEffect(() => {
    const resourceFieldId = getAllResources?.filter((obj: any) => {
      return obj.display_name === resourcesDropdown;
    });
    if (resourceFieldId.length > 0) {
      dispatch(getAllConditions_slice({ resource: resourceFieldId[0]?.id || '' }));
      dispatch(getResourcesField_slice({ resource: resourceFieldId[0]?.id || '' }));
    }
  }, [resourcesDropdown]);

  const isCheckBoxChecked = (option: any) => {
    const temp = selectedRolesDropdown.filter((roles: any) => {
      return roles === option?.name;
    });
    return temp.length > 0;
  };

  const onClickAutoCompleteDropdown = async (e: React.SyntheticEvent, value: any) => {
    const condition = allConditions?.find((condition: any) => {
      return condition?.id === value.id;
    });
    const selectedCondition = selectedConditions?.find((condition: any) => {
      return condition?.id === value.id;
    }); // checks if condition is already added to the policy
    if (condition && selectedCondition === undefined) {
      setSelectedConditions((prevState) => [...prevState, condition]);
    }
    setAutoCompleteValue('');
    // await dispatch(getAllPermissions_slice({ role: '' }));
  };

  const onClickClearCondition = (selectedCondition: any) => {
    const filteredCondition = selectedConditions?.filter((condition: any) => selectedCondition.id !== condition.id);
    setSelectedConditions([...filteredCondition]);
  };

  return (
    <Grid sx={{ padding: '18px 23px' }}>
      <Typography marginBottom="20px" variant={'h1'}>
        Create New Policy
      </Typography>
      <Grid>
        <Stack direction="row">
          <Stack style={{ marginRight: '15px' }}>
            <Typography variant="h3" fontSize="15px" marginBottom="4px">
              Policy Name *
            </Typography>
            <TextField
              size="small"
              value={policyName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPolicyName(e.target.value)}
              placeholder="Enter Policy Name"
            />
          </Stack>
          <Stack>
            <Typography variant="h3" fontSize="15px" marginBottom="4px">
              Policy Description
            </Typography>
            <TextField
              size="small"
              value={policyDescription}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPolicyDescription(e.target.value)}
              placeholder="Enter Policy Description"
            />
          </Stack>
        </Stack>
        <Grid>
          <Typography variant="h2" fontSize="19px" marginTop="15px">
            Effect
          </Typography>
          <ButtonGroup sx={{ marginTop: '15px' }} variant="outlined" aria-label="outlined button group">
            <Button className={activeButton == 1 ? 'active-btn' : undefined} onClick={() => setActiveButton(1)}>
              <Typography variant={'subtitle1'}>Allow</Typography>
            </Button>
            <Button className={activeButton == 2 ? 'active-btn' : undefined} onClick={() => setActiveButton(2)}>
              <Typography variant={'subtitle1'}>Deny</Typography>
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid>
          <Typography variant="h2" fontSize="19px" marginTop="25px" marginBottom="10px">
            Resources
          </Typography>
          <CognisaasDropdown
            sx={{ marginTop: '10px' }}
            name="resources-dropdown"
            type="dropdown"
            validvalues={allResources}
            onChange={(e: SelectChangeEvent<unknown | string>) => setResourcesDropdown(e.target.value as string)}
            value={resourcesDropdown}
          />
        </Grid>
        {/*  */}
        <Grid>
          <Typography variant="h2" fontSize="19px" marginTop="25px" marginBottom="10px">
            Access
          </Typography>
          <FormControl>
            <Select
              size="small"
              labelId="mutiple-select-label"
              multiple
              value={selectedRolesDropdown}
              onChange={(event: SelectChangeEvent<any>) => setSelectedAccessDropdown(event.target.value)}
              renderValue={(selected: any) => {
                const temp = selected?.map((obj: any) => {
                  return obj + ', ';
                });
                return temp;
              }}
              // MenuProps={MenuProps}
            >
              {getAllResources[0]?.actions?.map((option: any) => (
                <MenuItem key={option.id} value={option.name}>
                  <Checkbox checked={isCheckBoxChecked(option)} />
                  <ListItemText primary={option.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid>
          <Typography variant={'h2'} marginTop="20px">
            Add Condition{' '}
          </Typography>
          <Autocomplete
            sx={{ width: '500px', marginTop: '5px' }}
            size="small"
            options={allConditions}
            fullWidth
            value={autoCompleteValue}
            getOptionLabel={(option: any) => option['description'] || ''}
            renderInput={(params: any) => {
              return <TextField {...params} placeholder="search condition..." />;
            }}
            onChange={onClickAutoCompleteDropdown}
          />
        </Grid>
        {getAllConditionsStatus === requestState.loading ? (
          <Stack style={{ marginTop: '20px' }}>
            <CognisaasSpinner />
          </Stack>
        ) : (
          selectedConditions?.map((condition: any) => {
            return (
              <Stack
                key={condition.id}
                direction="row"
                style={{
                  width: '500px',
                  height: '50px',
                  display: 'flex',
                  alignItems: 'center',
                  boxSizing: 'border-box',
                  justifyContent: 'space-between',
                  border: '1px solid #E5E5E5',
                  padding: '0 10px',
                  borderRadius: '4px',
                  marginTop: '10px',
                }}
              >
                <Typography>{condition.name}</Typography>
                <IconButton aria-label="delete" size="small">
                  <ClearIcon fontSize="inherit" onClick={() => onClickClearCondition(condition)} />
                </IconButton>
              </Stack>
            );
          })
        )}

        <Grid sx={{ marginTop: '15px', display: 'flex', justifyContent: 'flex-end' }}>
          <CognisaasButton
            isOutlined={true}
            label="Cancel"
            clickHandler={() => setOpen(true)}
            sx={{ marginRight: '20px' }}
          />
          {loader ? (
            <LoadingButton loading loadingPosition="start" startIcon={<SaveIcon />} variant="outlined">
              Save
            </LoadingButton>
          ) : (
            <Button onClick={() => onClickCreate()} disabled={policyName.length === 0} variant="contained">
              Save
            </Button>
          )}
          <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              <Typography variant="h1">Alert!</Typography>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Typography variant="h2">
                  You will be redirected to Access Management and all your data filled in the form will be lost !
                </Typography>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpen(true)}>Cancel</Button>
              <Button onClick={() => props.setAddState(false)} autoFocus>
                Agree
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddPolicy;
