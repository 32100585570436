import { Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import CognisaasTabs from '../shared/CognisaasTabs/index';
import Roles from './Roles/Roles';
import User from './User/User';
import CustomPermission from './CustomPermissions/CustomPermission';
import CognisaasButton from '../shared/CognisaasButton/CognisaasButton';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../store/hooks';
import {
  getAllUsersAndRoles_slice,
  // getAllPermissions_slice,
  // getGeneralFieldValue_slice,
  // getConditions_slice
} from '../../store/modules/RBAC/slice';
import { getUserAndRoles_selector, getAllPermissions_selector } from '../../store/modules/RBAC/selector';
import { useNavigate } from 'react-router-dom';

const RBAC = () => {
  const navigate = useNavigate();
  // const [userAndRolesData, setUserAndRolesData] = useState([{}]);
  const [allRoles, setAllRoles] = useState([{}]);
  // const [allUsers, setAllUsers] = useState([{}]);
  const [allPermissions, setAllPermissions] = useState([{}]);
  const [userAndRolesCombinedData, setUserAndRolesCombinedData] = useState<any[]>([]);
  const dispatch = useDispatch();
  const userAndRoleData_fromSelector = useAppSelector(getUserAndRoles_selector).data;
  // const getResourcesField_fromSelector = useAppSelector(getResourcesField_selector).data;
  // const getResourcesFieldValues_fromSelector = useAppSelector(getResourcesFieldValues_selector).data;
  const getAllPermissions_fromSelector = useAppSelector(getAllPermissions_selector).data;
  // const getPolicyOnResources_fromSelector = useAppSelector(getPolicyOnResources_selector).data;
  // const getAllResources_fromSelector = useAppSelector(getAllResources_selector).data;
  // const getGeneralFieldValues_fromSelector = useAppSelector(getGeneralFieldValues_selector).data;
  // const getAllPages_fromSelector = useAppSelector(getAllPages_selector).data;
  const [selectedUserObj, setSelectedUserObj] = useState<any>({});
  useEffect(() => {
    if (
      getAllPermissions_fromSelector != undefined &&
      getAllPermissions_fromSelector[0] != undefined &&
      getAllPermissions_fromSelector[0] != null
    ) {
      const tempList = getAllPermissions_fromSelector?.map((obj: any) => {
        return { id: obj.id, name: obj.description };
      });
      setAllPermissions(tempList);
    }
  }, [getAllPermissions_fromSelector]);
  // console.log(userAndRolesData, allUsers);

  useEffect(() => {
    // dispatch(getConditions_slice());
    dispatch(getAllUsersAndRoles_slice());
    // dispatch(getResourcesField_slice({ resource: 'project' }));
    // dispatch(
    //   getResourcesFieldValue_slice({
    //     resource: 'project',
    //     field: 'project_priority',
    //   })
    // );
    // dispatch(
    //   getAllPermissions_slice({
    //     role: 'cs-owner',
    //   })
    // );
    // dispatch(
    //   getPolicyOnResources_slice({
    //     resource: 'Projects',
    //   })
    // );
    // dispatch(getAllResources_slice());
    // dispatch(getGeneralFieldValue_slice());
    // dispatch(getAllPages_slice());
  }, []);

  useEffect(() => {
    const tempObj: any = userAndRolesCombinedData?.filter((obj: any) => {
      return obj?.id == selectedUserObj?.id;
    });
    localStorage.setItem('currentName', tempObj[0]?.first_name);
  }, [selectedUserObj]);

  const [tabIndex, setTabIndex] = useState(0);

  const switchInsightTabs = (event: any, tabIndex: number) => {
    setTabIndex(tabIndex);
  };

  const addUserHandler = () => {
    if (tabIndex == 0) {
      navigate('/access-management/add-user', {
        state: {
          data: userAndRolesCombinedData,
        },
      });
    } else if (tabIndex == 1) {
      navigate('/access-management/add-role');
    }
  };

  // useEffect(()=>{
  // }, [tabIndex]);

  // useEffect(()=>{
  // }, [userAndRoleData_fromSelector, getAllPermissions_fromSelector]);

  useEffect(() => {
    console.log('userAndRoleData_fromSelector: ', userAndRoleData_fromSelector);
    const temp = userAndRoleData_fromSelector?.map((obj: any) => {
      if (obj.role == null || obj.role == undefined) {
        return {
          ...obj,
          role: {
            id: obj.created_at,
            name: 'Not Assigned',
          },
        };
      } else {
        return obj;
      }
    });
    console.log('temp: ', temp);
    setUserAndRolesCombinedData(temp);
  }, [userAndRoleData_fromSelector]);

  useEffect(() => {
    // setUserAndRolesData(userAndRolesCombinedData);
    // let tempUsers = []
    // const tempUsers = userAndRolesCombinedData?.map((e: any) => {
    //   return { id: e?.id, name: e?.first_name };
    // });
    const tempRoles = userAndRolesCombinedData?.map((e: any) => {
      return { id: e?.role?.id, role: e.role?.name, name: e?.first_name, userID: e?.id };
    });
    // setAllUsers(tempUsers);
    setAllRoles(tempRoles);
    setSelectedUserObj(userAndRolesCombinedData[0]);
  }, [userAndRolesCombinedData]);

  return (
    <Grid sx={{ padding: '18px 23px' }}>
      <Grid>
        <Typography marginLeft="10px" variant={'h1'} style={{ fontWeight: 500 }}>
          Access Management
        </Typography>
      </Grid>
      <Grid sx={{ margin: '22px 0 18px 0', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Grid>
          <CognisaasTabs
            handleTabSwitch={switchInsightTabs}
            selectedTabIndex={tabIndex}
            tabs={['Users', 'Roles', 'Permissions']}
          />
        </Grid>
        <Grid>
          {tabIndex == 0 || tabIndex == 1 ? (
            <CognisaasButton
              style={{ marginLeft: '12px' }}
              isOutlined={false}
              clickHandler={addUserHandler}
              label={`Add ${['Users', 'Roles'][tabIndex]}`}
            />
          ) : null}
        </Grid>
      </Grid>
      <Grid>
        {tabIndex == 1 ? (
          <Roles
            allData={userAndRolesCombinedData}
            rolesData={allRoles}
            selectedUser={selectedUserObj}
            setSelectedUser={setSelectedUserObj}
          />
        ) : // <Typography>Temp</Typography>
        tabIndex == 0 ? (
          // <Typography>Temp</Typography>
          <User
            selectedUser={selectedUserObj}
            setSelectedUser={setSelectedUserObj}
            usersData={userAndRolesCombinedData}
            setTabIndex={setTabIndex}
          />
        ) : tabIndex == 2 ? (
          // <Typography>Temp</Typography>
          <CustomPermission
            usersData={userAndRolesCombinedData}
            allPermissions={allPermissions}
            selectedUser={selectedUserObj}
            setSelectedUser={setSelectedUserObj}
          />
        ) : null}
      </Grid>
    </Grid>
  );
};

export default RBAC;
