import { Box } from '@mui/material';
import { status } from 'store/modules/types';

interface props {
  status: string;
}

const CognisaasStatus = (props: props) => {
  return (
    <>
      {props.status == status.RED ? (
        <Box
          mt={1}
          ml={2}
          component="span"
          sx={{
            backgroundColor: '#E75B5C',
            display: 'inline-block',
            width: '16px',
            height: '16px',
            borderRadius: '60%',
            marginRight: '2px',
          }}
        ></Box>
      ) : props.status == status.GREEN ? (
        <Box
          mt={1}
          ml={2}
          component="span"
          sx={{
            backgroundColor: '#4F9F52',
            display: 'inline-block',
            width: '16px',
            height: '16px',
            borderRadius: '60%',
            marginRight: '2px',
          }}
        ></Box>
      ) : props.status == status.BLUE ? (
        <Box
          mt={1}
          ml={2}
          component="span"
          sx={{
            backgroundColor: '#4390E1',
            display: 'inline-block',
            width: '16px',
            height: '16px',
            borderRadius: '60%',
            marginRight: '2px',
          }}
        ></Box>
      ) : props.status == status.AMBER ? (
        <Box
          mt={1}
          ml={2}
          component="span"
          sx={{
            backgroundColor: '#EFA958',
            display: 'inline-block',
            width: '16px',
            height: '16px',
            borderRadius: '60%',
            marginRight: '2px',
          }}
        ></Box>
      ) : (
        ''
      )}
    </>
  );
};

export default CognisaasStatus;
