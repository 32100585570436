import React, { useState, useEffect } from 'react';
// import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
// import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { useDispatch } from 'react-redux';
// import IconButton from '@mui/material/IconButton';
// import Tooltip from '@mui/material/Tooltip';
// import DeleteIcon from '@mui/icons-material/Delete';
// import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { Card, Grid, Stack, TextField } from '@mui/material';
import { ReactComponent as SearchIcon } from '../../../assets/svgs/search.svg';
import CognisaasTabs from '../../shared/CognisaasTabs/index';
// import MenuItem from '@mui/material/MenuItem';
// import Select from '@mui/material/Select';
import CognisaasButton from '../../shared/CognisaasButton/CognisaasButton';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useAppSelector } from '../../../store/hooks';
import CognisaasToast from '../../shared/CognisaasToast/CognisaasToast';
import {
  getPolicyOnResources_selector,
  getAllResources_selector,
  getAllPages_selector,
  getAllRoles_selector,
} from '../../../store/modules/RBAC/selector';
import {
  getPolicyOnResources_slice,
  getAllResources_slice,
  addPagesToRole_slice,
  addPolicyToRole_slice,
  removePagesFromRole_slice,
  removePolicyFromRole_slice,
  getAllRoles_slice,
  getAllPages_slice,
} from '../../../store/modules/RBAC/slice';
import CognisaasLoader from '../../shared/CognisaasLoader/CognisaasLoader';

interface Data {
  designition: string;
}

type Order = 'asc' | 'desc';

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array: any, order: 'asc' | 'desc') {
  const data = array.sort((a: any, b: any) => {
    if (order === 'asc') {
      return a.name.localeCompare(b.name);
    } else {
      return b.name.localeCompare(a.name);
    }
  });
  return data;
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'designition',
    numeric: false,
    disablePadding: true,
    label: 'Roles',
  },
];

interface UserCardComponentProps {
  designition: string;
  backColor: string;
  global: boolean;
}

const UserCardComponent = (props: UserCardComponentProps) => {
  return (
    <>
      {props.designition != 'Not Assigned' ? (
        <Card
          sx={{
            margin: '10px',
            backgroundColor: props.backColor,
            padding: '18px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Grid sx={{ display: 'flex', backgroundColor: 'grey', alignItems: 'center' }}>
            <Typography variant={'h2'}>{props.designition}</Typography>
          </Grid>
          <Typography variant={'subtitle1'} className="label-text">
            {props.global ? '(Global)' : null}
          </Typography>
        </Card>
      ) : null}
    </>
  );
};

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  searchValue: any;
  setSearchValue: any;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const [showSearchIcon, setShowSearchIcon] = useState(true);
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            sx={{ padding: '20px !important', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <Typography variant={'h2'}>{headCell.label}</Typography>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
            {showSearchIcon ? (
              <SearchIcon
                onClick={() => {
                  setShowSearchIcon(false);
                }}
              />
            ) : (
              <TextField
                placeholder="Start typing name..."
                value={props.searchValue}
                onBlur={() => {
                  setShowSearchIcon(true);
                }}
                onChange={(e: any) => {
                  props.setSearchValue(e.target.value);
                }}
                id="standard-basic"
                variant="standard"
              />
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface RightSideComponent_Resources_payload {
  selectedRole: any;
}
const RightSideComponent_Resources = (props: RightSideComponent_Resources_payload) => {
  const [expanded, setExpanded] = React.useState<string>('');
  const [projectCheckboxes, setProjectCheckboxes] = useState<any[]>([]);
  const [allAccordianTabs, setAllAccordianTabs] = useState<any[]>([]);
  const [checkBoxes, setCheckBoxes] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const dispatch = useDispatch();
  const getPolicyOnResources_fromSelector = useAppSelector(getPolicyOnResources_selector).data;
  const getAllResources_fromSelector = useAppSelector(getAllResources_selector).data;

  useEffect(() => {
    dispatch(getAllResources_slice());
  }, []);

  useEffect(() => {
    dispatch(
      getPolicyOnResources_slice({
        resource: expanded,
      })
    );
  }, [expanded]);

  useEffect(() => {
    // const selectedRolesPolicies = props?.selectedUser?.role?.policies;
    const selectedRolesPolicies = props?.selectedRole?.policies;
    const checkBoxTicksObj = projectCheckboxes?.map((obj: any) => {
      const findedValue = selectedRolesPolicies?.find((o: any) => o.id === obj?.id);
      return findedValue != undefined;
    });
    setCheckBoxes(checkBoxTicksObj);
  }, [projectCheckboxes, props]);

  // useEffect(()=>{
  //   setExpanded('');
  // }, [props.selectedRole]);

  useEffect(() => {
    setProjectCheckboxes(getPolicyOnResources_fromSelector);
  }, [getPolicyOnResources_fromSelector]);

  useEffect(() => {
    // console.log( getAllResources_fromSelector);
    setAllAccordianTabs(getAllResources_fromSelector);
  }, [getAllResources_fromSelector]);

  useEffect(() => {
    const filterNames = getPolicyOnResources_fromSelector?.filter((rowItem: any) => {
      return rowItem?.description?.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1;
    });
    setProjectCheckboxes(filterNames);
  }, [searchValue]);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : '');
  };

  const handleAddCustomPermission = () => {
    console.log('handleAddCustomPermission clicked');
  };

  const onClickCheckBoxState = (index: number) => {
    const tempCheckBoxex = { ...checkBoxes };
    tempCheckBoxex[index] = !checkBoxes[index];
    setCheckBoxes(tempCheckBoxex);
    const policyTobeProccessed = projectCheckboxes[index];
    // const roleId = props?.selectedUser?.role?.id;
    const roleId = props?.selectedRole?.id;
    // console.log(policyTobeProccessed);
    if (tempCheckBoxex[index]) {
      dispatch(
        addPolicyToRole_slice({
          roleId: roleId,
          policyIds: [policyTobeProccessed.id],
        })
      );
    } else {
      dispatch(
        removePolicyFromRole_slice({
          roleId: roleId,
          policyIds: [policyTobeProccessed.id],
        })
      );
    }
  };

  return (
    <>
      {allAccordianTabs.map((obj: any) => {
        return (
          <Card sx={{ marginTop: '30px' }} key={obj.id}>
            <Accordion expanded={expanded === obj.id} onChange={handleChange(obj.id)}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                <Typography variant={'h2'} sx={{ width: '33%', flexShrink: 0 }}>
                  {obj.display_name}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormGroup sx={{ marginLeft: '10px', marginBottom: '20px' }}>
                  <TextField
                    id="outlined-basic"
                    value={searchValue}
                    onChange={(e: any) => {
                      setSearchValue(e.target.value);
                    }}
                    placeholder="search..."
                    sx={{ height: '30px', marginBottom: '25px' }}
                    size="small"
                    variant="outlined"
                  />
                  <Grid
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      maxHeight: '200px',
                      overflowY: 'scroll',
                      padding: '0 10px',
                    }}
                  >
                    {projectCheckboxes?.map((obj: any, index: number) => (
                      <FormControlLabel
                        key={index}
                        control={
                          <Checkbox
                            onClick={() => {
                              onClickCheckBoxState(index);
                            }}
                            size="small"
                            checked={checkBoxes[index] || false}
                            disabled={props.selectedRole?.is_public}
                          />
                        }
                        label={
                          <Typography variant={'h3'} sx={{ marginLeft: '5px' }}>
                            {obj?.description}
                          </Typography>
                        }
                      />
                    ))}
                  </Grid>
                </FormGroup>
                {!props.selectedRole?.is_public && (
                  <CognisaasButton
                    style={{ marginLeft: '12px', marginTop: '10px' }}
                    isOutlined={true}
                    size="small"
                    clickHandler={handleAddCustomPermission}
                    label="Add Custom Permission"
                  />
                )}
              </AccordionDetails>
            </Accordion>
          </Card>
        );
      })}
    </>
  );
};

const NestedCheckbox = ({ obj, props }: any) => {
  const [checked, setChecked] = React.useState<any>(Array(obj.subPages.length).fill(false));
  const dispatch = useDispatch();
  useEffect(() => {
    // const selectedUserPermisions = props?.selectedUser?.role?.pages;
    const selectedUserPermisions = props?.selectedRole?.pages;
    console.log(props?.selectedRole?.pages);
    const temp = obj.subPages?.map((obj: any) => {
      const check = selectedUserPermisions?.find((o: any) => o?.id === obj?.id);
      return check != undefined;
    });
    console.log(temp, 'temp');
    setChecked(temp);
  }, []);

  const roleId = props?.selectedRole?.id;

  const handleChange1 = () => {
    if (checked.includes(true)) {
      setChecked(Array(obj.subPages.length).fill(false));
      obj.subPages.map((ele: any) => {
        console.log(ele, ' ele dis');
        dispatch(
          removePagesFromRole_slice({
            roleId: roleId,
            pageIds: [ele.id],
          })
        );
      });
      dispatch(
        removePagesFromRole_slice({
          roleId: roleId,
          pageIds: [obj.id],
        })
      );
    }
    if (!checked.includes(true)) {
      setChecked(Array(obj.subPages.length).fill(true));
      obj.subPages.map((ele: any) => {
        dispatch(
          addPagesToRole_slice({
            roleId: roleId,
            pageIds: [ele.id],
          })
        );
      });
      dispatch(
        addPagesToRole_slice({
          roleId: roleId,
          pageIds: [obj.id],
        })
      );
    }
  };

  const handleChange2 = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    // setChecked([checked[index], event.target.value[index]]);
    console.log(checked, event.target.checked, index);
    const tempArr = [...checked];
    const newChecked: boolean[] = [];
    tempArr.map((ele: any, i: number) => {
      if (i == index) newChecked.push(!ele);
      else newChecked.push(ele);
    });
    const pagesTobeProccessed = obj.subPages[index];
    // const roleId = props?.selectedUser?.role?.id;

    if (!tempArr[index]) {
      dispatch(
        addPagesToRole_slice({
          roleId: roleId,
          pageIds: [pagesTobeProccessed.id],
        })
      );
      dispatch(
        addPagesToRole_slice({
          roleId: roleId,
          pageIds: [obj.id],
        })
      );
    } else {
      dispatch(
        removePagesFromRole_slice({
          roleId: roleId,
          pageIds: [pagesTobeProccessed.id],
        })
      );
    }
    setChecked(newChecked);
  };
  console.log(obj.name);
  const children = (
    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
      {obj.subPages?.map((ele: any, index: number) => {
        return (
          <FormControlLabel
            key={index}
            label={ele.name}
            sx={{ ml: '2px' }}
            control={
              <Checkbox
                size="small"
                checked={checked[index]}
                disabled={props.selectedRole.is_public == true ? true : false}
                onChange={(event) => {
                  if (props.selectedRole.is_public == false) {
                    handleChange2(event, index);
                  }
                }}
              />
            }
          />
        );
      })}
    </Box>
  );

  return (
    <div>
      <FormControlLabel
        label={obj.name}
        control={
          <Checkbox
            size="small"
            sx={{ margin: '5px 0 0 12px' }}
            checked={!checked.includes(false)}
            disabled={props.selectedRole.is_public == true ? true : false}
            indeterminate={checked.includes(true) && checked.includes(false)}
            onChange={() => {
              if (props.selectedRole.is_public == false) {
                handleChange1();
              }
            }}
          />
        }
      />
      {children}
    </div>
  );
};

const RightSideComponent_Pages = (props: any) => {
  const [allPages, setAllPages] = useState<any[]>([]);
  const [checkBoxex, setCheckBoxex] = useState<any[]>([]);
  const getAllPages_fromSelector = useAppSelector(getAllPages_selector).data;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllPages_slice());
  }, []);

  useEffect(() => {
    console.log('props: ', props);
  }, [props]);

  let nestedAllPagesData: any[] = [];
  const parent: string[] = [];
  const flatPagesArr: any = [];
  const subPagesArr: any = [];
  const parentPagesArr: any = [];

  allPages.map((page: any) => {
    const pageUrlSplit = page.url.split('/');
    if (pageUrlSplit.length == 2) {
      flatPagesArr.push(page);
    } else {
      subPagesArr.push(page);
    }
  });

  subPagesArr.map((subPage: any) => {
    let parentUrl: string = subPage.url.split('/')[1];
    parentUrl = '/' + parentUrl;
    const parentObj: any = flatPagesArr.find((flatPage: any) => flatPage.url === parentUrl);
    const parentObjIndex = flatPagesArr.indexOf(parentObj);

    if (parentObj === undefined) {
      const parentObj: any = parentPagesArr.find((parentPage: any) => parentPage.url === parentUrl);
      parentObj?.subPages.push(subPage);
    } else {
      const formattedParent: any = { ...parentObj };
      formattedParent.subPages = [subPage];
      parentPagesArr.push(formattedParent);
      flatPagesArr.splice(parentObjIndex, 1);
    }
  });
  nestedAllPagesData = [...flatPagesArr, ...parentPagesArr];
  console.log(flatPagesArr, parentPagesArr, 'flatsArr');

  console.log(nestedAllPagesData, parent);

  useEffect(() => {
    // const selectedUserPermisions = props?.selectedUser?.role?.pages;
    const selectedUserPermisions = props?.selectedRole?.pages;
    console.log(allPages, 'all pages');
    const temp = nestedAllPagesData?.map((obj: any) => {
      const check = selectedUserPermisions?.find((o: any) => o?.id === obj?.id);
      return check != undefined;
    });
    console.log(temp, 'temp new');
    setCheckBoxex(temp);
  }, [props, allPages]);

  useEffect(() => {
    if (getAllPages_fromSelector) {
      setAllPages(getAllPages_fromSelector);
    }
  }, [getAllPages_fromSelector]);

  const onClickCheckBoxState = (index: number) => {
    const tempCheckBoxex = { ...checkBoxex };
    tempCheckBoxex[index] = !checkBoxex[index];
    console.log(tempCheckBoxex, index, 'temp chk box');
    setCheckBoxex(tempCheckBoxex);
    const pagesTobeProccessed = nestedAllPagesData[index];
    const roleId = props?.selectedRole?.id;
    if (tempCheckBoxex[index]) {
      dispatch(
        addPagesToRole_slice({
          roleId: roleId,
          pageIds: [pagesTobeProccessed.id],
        })
      );
    } else {
      dispatch(
        removePagesFromRole_slice({
          roleId: roleId,
          pageIds: [pagesTobeProccessed.id],
        })
      );
    }
  };

  return (
    <Grid sx={{ display: 'flex', flexDirection: 'column', marginTop: '15px' }}>
      {nestedAllPagesData?.map((obj: any, index: number) => {
        console.log(obj, 'use this');
        if (typeof obj.subPages === 'undefined') {
          // color is undefined
          console.log(' it r undef');
          return (
            <FormControlLabel
              onClick={() => {
                console.log(props.selectedRole);
                if (props.selectedRole.is_public == false) {
                  onClickCheckBoxState(index);
                  console.log(obj, 'check box obj');
                }
              }}
              key={index}
              sx={{ margin: '5px 0 0 0' }}
              control={
                <Checkbox
                  size="small"
                  checked={checkBoxex[index] || false}
                  disabled={props.selectedRole.is_public == true ? true : false}
                />
              }
              label={
                <Typography variant={'h3'} sx={{ marginLeft: '5px' }}>
                  {obj?.name}
                </Typography>
              }
            />
          );
        } else {
          return <NestedCheckbox obj={obj} props={props} allPages={allPages} key={index} />;
        }
      })}
    </Grid>
  );
};
//

const UserInfo_RightSideComponent = (props: any) => {
  // const [roleDropdown, setRoleDropdown] = useState('1');
  const [tabIndex, setTabIndex] = useState(0);

  const switchInsightTabs = (event: any, tabIndex: number) => {
    setTabIndex(tabIndex);
  };
  console.log('Selected role', props.selectedRole);
  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: '20px',
        height: '72vh',
        width: '55%',
        overflow: 'scroll',
      }}
    >
      {props.selectedRole && Object.keys(props.selectedRole).length > 0 && !props.showLoader && (
        <>
          <Grid>
            <Stack direction="row" alignItems="center" justifyContent={'space-between'}>
              <Typography variant="h2" style={{ marginRight: '15px' }}>
                {props.selectedRole.name}
              </Typography>
              {props?.selectedRole.is_public && (
                <>
                  <Stack direction="row">
                    <div
                      style={{
                        padding: '2px 10px',
                        textAlign: 'center',
                        borderRadius: '4px',
                        border: '1px solid #3D38BA',
                        width: 'fit-content',
                      }}
                    >
                      <Typography variant="subtitle1" color="#3D38BA" style={{ fontSize: '11px' }}>
                        Cognisaas Managed
                      </Typography>
                    </div>
                    <div
                      style={{
                        width: 'fit-content',
                        padding: '2px 10px',
                        borderRadius: '4px',
                        border: '1px solid #0061bb',
                        backgroundColor: '#d8ecff',
                        marginLeft: '5px',
                      }}
                    >
                      <Typography variant="subtitle1" color="#0061bb" style={{ fontSize: '11px' }}>
                        The policy is managed by Cognisaas, cannot be edited
                      </Typography>
                    </div>
                  </Stack>
                </>
              )}
            </Stack>
            <Typography variant={'h3'} sx={{ mt: '15px' }}>
              {props.selectedRole.description}
            </Typography>
            <Typography variant={'h2'} sx={{ mt: '30px' }}>
              Permissions
            </Typography>
          </Grid>
          <Grid sx={{ marginTop: '20px' }}>
            <CognisaasTabs handleTabSwitch={switchInsightTabs} tabs={['Resources', 'Pages']} />
          </Grid>
          <Grid>
            {tabIndex == 0 ? (
              <RightSideComponent_Resources selectedRole={props.selectedRole} />
            ) : (
              <RightSideComponent_Pages selectedRole={props.selectedRole} />
            )}
          </Grid>
        </>
      )}
    </Paper>
  );
};

interface RolesPayload {
  allData: any;
  rolesData: any;
  selectedUser: any;
  setSelectedUser: any;
}

const Roles = (props: RolesPayload) => {
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof Data>('designition');
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [selectedRole, setSelectedRole] = useState<any>({});
  // const [totalData, setTotalData] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [filteredDataBy_UniqueRows, setFilteredDataBy_UniqueRows] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const allRolesData_fromSelector = useAppSelector(getAllRoles_selector).data;
  const [showLoader, setShowLoader] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    setShowLoader(true);
    dispatch(getAllRoles_slice());
  }, []);
  useEffect(() => {
    const filterNames = allRolesData_fromSelector?.filter((rowItem: any) => {
      return rowItem?.name?.toLowerCase().indexOf(searchValue?.toLowerCase()) !== -1;
    });
    const SortedFilterNames = filterNames.sort((a, b) => (a.name > b.name ? 1 : -1));
    console.log(
      filterNames.sort((a, b) => (a.name > b.name ? 1 : -1)),
      'Filter names'
    );
    setFilteredDataBy_UniqueRows(SortedFilterNames);
    setFilteredData(SortedFilterNames);
    setSelected([]); // temp
  }, [searchValue]);

  useEffect(() => {
    if (allRolesData_fromSelector.length == 0) {
      setShowLoader(false);
    } else {
      setSelectedRole(allRolesData_fromSelector[0]);
    }
  }, [allRolesData_fromSelector]);

  useEffect(() => {
    if (filteredDataBy_UniqueRows.length == 0) {
      setShowLoader(true);
      setTimeout(() => {
        setShowLoader(false);
      }, 4000);
    } else {
      setShowLoader(false);
    }
  }, [filteredDataBy_UniqueRows]);

  useEffect(() => {
    setSelectedRole(props?.selectedUser?.role);
    // props.setSelectedUser({});
  }, [props.selectedUser]);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log('handleSelectAllClick', event);
  };

  return (
    <Grid sx={{ display: 'flex', width: '100%' }}>
      <Paper sx={{ height: '77vh', width: '45%', margin: '0 15px 0 0', flexGrow: 1, overflowY: 'hidden' }}>
        {showLoader ? (
          <CognisaasLoader />
        ) : (
          <TableContainer sx={{ maxHeight: '100%' }}>
            <Table stickyHeader={true} aria-labelledby="tableTitle" size={'small'}>
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={filteredDataBy_UniqueRows.length}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
              />
              <TableBody>
                {props?.selectedUser?.role?.name == 'Not Assigned' || selectedRole?.name == 'Not Assigned' ? (
                  <CognisaasToast
                    message={`Role for ${props.selectedUser?.first_name} is not assigned`}
                    type="failure"
                  />
                ) : null}
                {stableSort(filteredData, order).map((row: any, index: number) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      sx={{ cursor: 'pointer' }}
                      onClick={() => {
                        // props.setSelectedUser(row);
                        setSelectedRole(row);
                      }}
                      tabIndex={-1}
                      key={index}
                    >
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                        <UserCardComponent
                          backColor={row?.id == selectedRole?.id ? '#2D5FF627' : 'white'}
                          designition={`${row.name}`}
                          global={row.is_public ? true : false}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>
      <UserInfo_RightSideComponent selectedRole={selectedRole} showLoader={showLoader} />
    </Grid>
  );
};

export default Roles;
