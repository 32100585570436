import {
  Drawer,
  Typography,
  Stack,
  Avatar,
  Grid,
  TextField,
  TextareaAutosize,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material';
import { Formik } from 'formik';
import CognisaasButton from '../../../shared/CognisaasButton/CognisaasButton';
import CognisaasSelect from '../../../shared/CognisaasSelect/CognisaasSelect';
import { useState, useEffect } from 'react';
import { useAppSelector } from '../../../../store/hooks';
import { useDispatch } from 'react-redux';
import { selectCustomerDetail } from 'store/modules/Common/selector';
import CognisaasCalenderNew from '../../../shared/CognisaasCalenderNew/CognisaasCalenderNew';
import {
  addProjectAuditTrial,
  deleteProject,
  getProjectCustomFields,
  handleProjectPlannedDateUpdate,
  updateProject,
} from '../../../../store/modules/Project/slice';
import { getLoggedInUserUid, getUserUid } from '../../../../utils/getUid';
import { differenceInDays, format } from 'date-fns';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate, useParams } from 'react-router-dom';
import { getProjectInformation, getProjectAudit } from '../../../../store/modules/Project/slice';
import { updateCaseWithfeatureStatus } from '../../../../store/modules/Common/slice';
import { default as AvatarImage } from '../../../shared/Avatar/Avatar';
import getSymbolFromCurrency from 'currency-symbol-map';
import CognisaasUserSelect from 'components/shared/CognisaasSelect/CognisaasUserSelect';
import useUserDropDownData from 'components/shared/Hooks/useUserDropDownData';
import { editProjectSchema } from '../../../../utils/validations';
import {
  label_arr,
  label_delivery_date,
  label_due_date,
  label_project,
  label_project_delivery_manager,
} from 'components/Settings/Preferences/CustomLabelCookieHelper';
import CognisaasToast from 'components/shared/CognisaasToast/CognisaasToast';
import DeleteModal from 'components/shared/CognisaasModal/DeleteModal';
import { selectProjectCustomFields } from 'store/modules/Project/selector';
import { isMandatoryFieldsEmpty } from 'utils/dataHelpers';
import useCustomNames from 'components/shared/CustomNames/useCustomNames';
import NAMED_CONSTATNTS from 'components/shared/CustomNames/Constants';
import React from 'react';
import CognisaaasTooltip from 'components/shared/CognisaasTooltip/CognisaasTooltip';
import { getUserActiveState } from 'utils/userHelpers';
import { getAllTasksOfProject, makeDate } from '../../utils';
import { isSuperUser } from '../../../../utils/getUid';
import { getClientUidByAssociationUid } from 'store/modules/ManageClient/ClientList/slice';

interface props {
  drawerState: boolean;
  onClose: any;
  projectDetails: any;
  client: any;
}

function stringToHslColor(str: string, s: number, l: number) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const h = hash % 360;
  return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToHslColor(name, 30, 80),
    },
    children: (
      <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#000000' }}>
        {name.split(' ')[0][0]}
        {name.indexOf(' ') >= 0 ? name.split(' ')[1][0] : null}
      </Typography>
    ),
  };
}

const EditProjectDrawer = (props: props) => {
  const projectDetails = props.projectDetails;
  const navigate = useNavigate();
  const params = useParams();
  const projectCustomFields = useAppSelector(selectProjectCustomFields);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const customerDetail = useAppSelector(selectCustomerDetail);
  const tasks = getAllTasksOfProject(projectDetails);
  const [isPlannedDateDisable, setIsPlannedDateDisable] = useState(customerDetail.freeze_planned_date_project == '1');
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setIsPlannedDateDisable(checkIsPlannedDateDisable);
  }, []);

  const checkIsPlannedDateDisable = () => {
    if (isSuperUser()) {
      return false;
    }
    if (customerDetail.freeze_planned_date_project == '1') {
      for (let i = 0; i < tasks.length; i++) {
        if (tasks[i].task_stage.toLowerCase() !== 'not started') {
          return true;
        }
      }
    }
    return false;
  };
  const companyUsersDropDownData = useUserDropDownData('company');
  const [alertDetails, setAlertDetails] = useState<{
    isVisible: boolean;
    type: 'success' | 'failure';
    message: string;
  }>({ isVisible: false, type: 'success', message: '' });
  const dispatch = useDispatch();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const {
    projectCategoryDropdownValues,
    getStandardNameMappedValue,
    getCustomNameMappedValue,
    projectStagesDropdownValues,
    projectPrioritiesDropdownValues,
  } = useCustomNames();
  const projectCategories = projectCategoryDropdownValues.map((item: any) => item.custom_category_name);
  const projectStages = projectStagesDropdownValues.map((item: any) => item.custom_stage_name);
  const projectPriorities = projectPrioritiesDropdownValues.map((item: any) => item.custom_priority_name);

  useEffect(() => {
    dispatch(getProjectCustomFields(projectDetails.project_uid as string));
  }, []);
  const handleDelete = () => {
    const details = {
      project_uid: projectDetails.project_uid,
    };

    dispatch(deleteProject(details));
    navigate(`/client-list/client/${props.client.client_uid}`);
    dispatch(getClientUidByAssociationUid(params.associationId as string));
  };

  const initialValues = {
    projectName: projectDetails.project_name,
    projectStage: getCustomNameMappedValue(projectDetails.project_stage, NAMED_CONSTATNTS.project_stage_key),
    projectPriority: getCustomNameMappedValue(projectDetails.project_priority, NAMED_CONSTATNTS.project_priority_key),
    projectCategory: getCustomNameMappedValue(
      projectDetails.project_needed_status,
      NAMED_CONSTATNTS.project_category_key
    ),
    projectDeliveryManager: {
      id: getUserUid(projectDetails.project_deliverymanager),
      name: projectDetails.project_deliverymanager,
    },
    projectFees: projectDetails.project_fees,
    projectPlannedStartDate: projectDetails.project_planned_startdate,
    projectStartDate: projectDetails.project_startdate,
    projectDueDate: projectDetails.project_requiredbydate,
    projectDeliverydate: projectDetails.project_deliverydate,
    projectDescription: projectDetails.project_description,
    projectReference: projectDetails.project_reference,
    projectEtaForDelivery: projectDetails.eta_forprojectdelivery,
    projectExpansionValue: projectDetails.project_expansionvalue,
    projectARR: projectDetails.project_ARR,
  };

  const sendAuditTrialForProject = (newForm: any, oldForm: any) => {
    for (const i in newForm) {
      if (newForm[i] != oldForm[i]) {
        let detail: any = {};
        if (i == 'projectName') {
          detail = {
            project_uid: projectDetails.project_uid,
            user_uid: getLoggedInUserUid(),
            field: 'Project Name',
            modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
            old_value: oldForm[i],
            new_value: newForm[i],
          };
          dispatch(addProjectAuditTrial(detail));
        } else if (i == 'projectStage') {
          detail = {
            project_uid: projectDetails.project_uid,
            user_uid: getLoggedInUserUid(),
            field: 'Stage',
            modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
            old_value: oldForm[i],
            new_value: newForm[i],
          };
          dispatch(addProjectAuditTrial(detail));
        } else if (i == 'projectCategory') {
          detail = {
            project_uid: projectDetails.project_uid,
            user_uid: getLoggedInUserUid(),
            field: 'Category',
            modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
            old_value: oldForm[i],
            new_value: newForm[i],
          };
          dispatch(addProjectAuditTrial(detail));
        } else if (i == 'projectPriority') {
          detail = {
            project_uid: projectDetails.project_uid,
            user_uid: getLoggedInUserUid(),
            field: 'Priority',
            modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
            old_value: oldForm[i],
            new_value: newForm[i],
          };
          dispatch(addProjectAuditTrial(detail));
        } else if (i == 'projectDescription') {
          detail = {
            project_uid: projectDetails.project_uid,
            user_uid: getLoggedInUserUid(),
            field: 'Description',
            modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
            old_value: oldForm[i],
            new_value: newForm[i],
          };
          dispatch(addProjectAuditTrial(detail));
        } else if (i == 'projectDeliveryManager') {
          if (newForm['projectDeliveryManager'] != oldForm['projectDeliveryManager'].name) {
            detail = {
              project_uid: projectDetails.project_uid,
              user_uid: getLoggedInUserUid(),
              field: 'Delivery Manager',
              modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
              old_value: getUserUid(oldForm[i].name),
              new_value: getUserUid(newForm[i]),
            };
            dispatch(addProjectAuditTrial(detail));
          }
        } else if (i == 'projectPlannedStartDate') {
          detail = {
            project_uid: projectDetails.project_uid,
            user_uid: getLoggedInUserUid(),
            field: 'Planned Start Date',
            modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
            old_date: oldForm[i],
            new_date: newForm[i],
          };
          dispatch(addProjectAuditTrial(detail));
        } else if (i == 'projectStartDate') {
          detail = {
            project_uid: projectDetails.project_uid,
            user_uid: getLoggedInUserUid(),
            field: 'Start Date',
            modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
            old_date: oldForm[i],
            new_date: newForm[i],
          };
          dispatch(addProjectAuditTrial(detail));
        } else if (i == 'projectDueDate') {
          detail = {
            project_uid: projectDetails.project_uid,
            user_uid: getLoggedInUserUid(),
            field: 'Due Date',
            modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
            old_date: oldForm[i],
            new_date: newForm[i],
          };
          dispatch(addProjectAuditTrial(detail));
        } else if (i == 'projectFees') {
          detail = {
            project_uid: projectDetails.project_uid,
            user_uid: getLoggedInUserUid(),
            field: 'Implementation Fee',
            modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
            old_value: oldForm[i],
            new_value: newForm[i],
          };
          dispatch(addProjectAuditTrial(detail));
        } else if (i == 'projectEtaForDelivery') {
          detail = {
            project_uid: projectDetails.project_uid,
            user_uid: getLoggedInUserUid(),
            field: 'ETA for Delivery',
            modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
            old_date: oldForm[i],
            new_date: newForm[i],
          };
          dispatch(addProjectAuditTrial(detail));
        } else if (i == 'projectDeliverydate') {
          detail = {
            project_uid: projectDetails.project_uid,
            user_uid: getLoggedInUserUid(),
            field: 'Delivery Date',
            modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
            old_date: oldForm[i],
            new_date: newForm[i],
          };
          dispatch(addProjectAuditTrial(detail));
        } else if (i == 'projectARR') {
          detail = {
            project_uid: projectDetails.project_uid,
            user_uid: getLoggedInUserUid(),
            field: 'Project ARR',
            modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
            old_value: oldForm[i],
            new_value: newForm[i],
          };
          dispatch(addProjectAuditTrial(detail));
        }
      }
    }
  };

  const updateProjectDetail = async (form: any) => {
    const value = {
      association_uid: params.associationId,
      project_uid: projectDetails.project_uid,
      project_name: form.projectName,
      project_fees: form.projectFees,
      project_ARR: form.projectARR,
      project_comment: projectDetails.project_comment,
      project_description: form.projectDescription,
      project_reference: form.projectReference,
      project_value: projectDetails.project_value,
      project_needed_status: form.projectCategory,
      project_stage: form.projectStage,
      user_uid: getUserUid(form.projectDeliveryManager),
      project_deliverymanager: form.projectDeliveryManager,
      project_readinessstate_status: projectDetails.project_readinessstate_status,
      project_startdate: form.projectStartDate,
      project_planned_startdate: form.projectPlannedStartDate,
      project_deliverydate: form.projectDeliverydate,
      eta_forprojectdelivery: form.projectEtaForDelivery,
      project_requiredbydate: form.projectDueDate,
      project_priority: form.projectPriority,
      project_expansionvalue: form.projectExpansionValue,
    };

    props.onClose();

    await batchDispatch(value);
  };

  const closeDrawer = () => {
    props.onClose();
  };

  const batchDispatch = async (value: any) => {
    const detail = {
      project_uid: projectDetails.project_uid,
      association_uid: params.associationId,
    };
    await dispatch(updateProject(value));
    // If project planned dates are changes and none of the tasks were started , should update all task dates.
    isPlannedDatesOfProjectChanges(projectDetails, value);
    await dispatch(updateCaseWithfeatureStatus(props.client.client_uid));
    await dispatch(getProjectInformation(detail));
    await dispatch(getProjectAudit(projectDetails.project_uid));
    await dispatch(getClientUidByAssociationUid(params.associationId as string));
  };

  const isPlannedDatesOfProjectChanges = async (projectDetails: any, newFormData: any) => {
    if (makeDate(projectDetails.project_planned_startdate) != makeDate(newFormData.project_planned_startdate)) {
      const difference = differenceInDays(
        makeDate(newFormData.project_planned_startdate),
        makeDate(projectDetails.project_planned_startdate)
      );
      const detail = {
        project_uid: projectDetails.project_uid,
        shifted_by: difference,
        updated_date: makeDate(projectDetails.project_planned_startdate),
        entity: 'PROJECT',
      };
      await dispatch(handleProjectPlannedDateUpdate(detail));
    }
  };

  const parseLines = (value: string) => value?.replace(/(\\n)/g, '\n').replace(/(\\)/g, '');

  const disableField = () => {
    const arr = projectStages.filter(
      (word) =>
        word != getCustomNameMappedValue('On Hold', NAMED_CONSTATNTS.project_stage_key) &&
        word != getCustomNameMappedValue('Not Started', NAMED_CONSTATNTS.project_stage_key)
    );
    console.log('array11', getCustomNameMappedValue('On Hold', NAMED_CONSTATNTS.project_stage_key));
    return arr;
  };
  console.log('projectStage', projectStages);

  const projectDetailForm = () => {
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={editProjectSchema}
        onSubmit={(values) => {
          if (isMandatoryFieldsEmpty(projectCustomFields)) {
            setAlertDetails({
              isVisible: true,
              type: 'failure',
              message: `Please fill out mandatory Custom fields before editing this ${label_project}`,
            });
            return;
          }
          if (values.projectName.trim()) {
            const newValues = {
              ...values,
              projectCategory: getStandardNameMappedValue(
                values.projectCategory,
                NAMED_CONSTATNTS.project_category_key
              ),
              projectStage: getStandardNameMappedValue(values.projectStage, NAMED_CONSTATNTS.project_stage_key),
              projectDeliveryManager: values.projectDeliveryManager
                ? values.projectDeliveryManager.name
                : 'Not Assigned',
              projectPriority: getStandardNameMappedValue(
                values.projectPriority,
                NAMED_CONSTATNTS.project_priority_key
              ),
              projectPlannedStartDate: format(new Date(values.projectPlannedStartDate), 'yyyy-MM-dd HH:mm:ss'),
              projectStartDate: format(new Date(values.projectStartDate), 'yyyy-MM-dd HH:mm:ss'),
              projectDueDate: format(new Date(values.projectDueDate), 'yyyy-MM-dd HH:mm:ss'),
              projectEtaForDelivery: format(new Date(values.projectEtaForDelivery), 'yyyy-MM-dd HH:mm:ss'),
              projectDeliverydate: format(new Date(values.projectDeliverydate), 'yyyy-MM-dd HH:mm:ss'),
            };
            sendAuditTrialForProject(newValues, {
              ...initialValues,
              projectCategory: getStandardNameMappedValue(
                initialValues.projectCategory,
                NAMED_CONSTATNTS.project_category_key
              ),
              projectStage: getStandardNameMappedValue(initialValues.projectStage, NAMED_CONSTATNTS.project_stage_key),
              projectPriority: getStandardNameMappedValue(
                initialValues.projectPriority,
                NAMED_CONSTATNTS.project_priority_key
              ),
            });
            updateProjectDetail(newValues);
          } else {
            setAlertDetails({
              isVisible: true,
              type: 'failure',
              message: 'Project Name cannot be empty.',
            });
          }
        }}
      >
        {(props: any) => {
          return (
            <form>
              <Stack mt={2} direction="row">
                <Stack>
                  <Typography variant="subtitle2">{label_project} Name*</Typography>
                  <TextField
                    id="projectName"
                    name="projectName"
                    size="small"
                    onChange={props.handleChange}
                    value={props.values.projectName}
                    onBlur={props.handleBlur}
                    error={props.touched.projectName && Boolean(props.errors.projectName)}
                    helperText={props.touched.projectName && props.errors.projectName}
                  ></TextField>
                </Stack>
              </Stack>
              <Stack direction="row" mt={2} spacing={2}>
                <Stack>
                  <Typography variant="subtitle2">Priority</Typography>
                  <CognisaasSelect
                    width="200px"
                    name="projectPriority"
                    value={props.values.projectPriority}
                    // placeholder={'Select'}
                    onChange={props.handleChange}
                    validValues={projectPriorities}
                  />
                </Stack>
                <Stack>
                  <Typography variant="subtitle2">Category</Typography>
                  <CognisaasSelect
                    width="200px"
                    id="projectCategory"
                    name="projectCategory"
                    value={props.values.projectCategory}
                    validValues={projectCategories}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    error={props.touched.projectCategory && Boolean(props.errors.projectCategory)}
                    helperText={props.touched.projectCategory && props.errors.projectCategory}
                  />
                </Stack>
                {getStandardNameMappedValue(props.values.projectCategory, NAMED_CONSTATNTS.project_category_key) ==
                  'Expand' && (
                  <Stack>
                    <Typography variant="subtitle2">
                      {`${getCustomNameMappedValue(
                        'Expand',
                        NAMED_CONSTATNTS.project_category_key
                      )} ${label_arr} (${getSymbolFromCurrency(customerDetail?.currency_code)})`}
                    </Typography>
                    <TextField
                      type="number"
                      style={{ width: '200px' }}
                      size="small"
                      placeholder={`${getSymbolFromCurrency(customerDetail?.currency_code)}0`}
                      id="projectExpansionValue"
                      name="projectExpansionValue"
                      value={props.values.projectExpansionValue}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      error={props.touched.projectExpansionValue && Boolean(props.errors.projectExpansionValue)}
                      helperText={props.touched.projectExpansionValue && props.errors.projectExpansionValue}
                    ></TextField>
                  </Stack>
                )}
              </Stack>
              <Stack mt={2} direction="row" spacing={2}>
                <Stack>
                  <Typography variant="subtitle2">{label_project_delivery_manager} </Typography>
                  <CognisaasUserSelect
                    width="200px"
                    name="projectDeliveryManager"
                    id="projectDeliveryManager"
                    allOptions={companyUsersDropDownData}
                    selectedOption={{
                      id: getUserUid(props.values.projectDeliveryManager.name),
                      name:
                        getUserActiveState(
                          getUserUid(props.values.projectDeliveryManager.name),
                          companyUsersDropDownData
                        ) === '0'
                          ? `${props.values.projectDeliveryManager.name} (Inactive)`
                          : props.values.projectDeliveryManager.name,
                      is_active: getUserActiveState(
                        getUserUid(props.values.projectDeliveryManager.name),
                        companyUsersDropDownData
                      ),
                    }}
                    onChange={(val: any) => {
                      props.setFieldValue('projectDeliveryManager', {
                        // name: val.target.textContent,
                        id: getUserUid(val.target.textContent),
                        name:
                          getUserActiveState(getUserUid(val.target.textContent), companyUsersDropDownData) === '0'
                            ? `${val.target.textContent} (Inactive)`
                            : val.target.textContent,
                        is_active: getUserActiveState(getUserUid(val.target.textContent), companyUsersDropDownData),
                      });
                    }}
                    onBlur={props.handleBlur}
                    error={props.touched.projectDeliveryManager && Boolean(props.errors.projectDeliveryManager)}
                    helperText={props.touched.projectDeliveryManager && props.errors.projectDeliveryManager}
                  />
                </Stack>
                <Stack>
                  <Typography variant="subtitle2">
                    {`Implementation Fee (${getSymbolFromCurrency(customerDetail?.currency_code)})`}
                  </Typography>
                  <TextField
                    type="number"
                    style={{ width: '200px' }}
                    size="small"
                    placeholder={`${getSymbolFromCurrency(customerDetail?.currency_code)}0`}
                    id="projectFees"
                    name="projectFees"
                    value={props.values.projectFees}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    error={props.touched.projectFees && Boolean(props.errors.projectFees)}
                    helperText={props.touched.projectFees && props.errors.projectFees}
                  ></TextField>
                </Stack>
              </Stack>
              <Stack spacing={2} mt={2} direction="row">
                <Stack>
                  <Typography variant="subtitle2">
                    {`${label_project} ${label_arr} (${getSymbolFromCurrency(customerDetail?.currency_code)})`}
                  </Typography>
                  <TextField
                    type="number"
                    style={{ width: '200px' }}
                    size="small"
                    placeholder={`${getSymbolFromCurrency(customerDetail?.currency_code)}0`}
                    id="projectARR"
                    name="projectARR"
                    value={props.values.projectARR}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    error={props.touched.projectARR && Boolean(props.errors.projectARR)}
                    helperText={props.touched.projectARR && props.errors.projectARR}
                  ></TextField>
                </Stack>
                <Stack>
                  <Stack>
                    <Typography variant="subtitle2">Stage</Typography>
                    <CognisaasSelect
                      disabledValues={
                        initialValues.projectStage ==
                        getCustomNameMappedValue('On Hold', NAMED_CONSTATNTS.project_stage_key)
                          ? disableField()
                          : []
                      }
                      width="200px"
                      name="projectStage"
                      value={props.values.projectStage}
                      validValues={projectStages}
                      onChange={props.handleChange}
                    />
                  </Stack>
                  {console.log('project', props.values.projectStage)}
                </Stack>
              </Stack>
              <Stack mt={2} direction="row" spacing={2}>
                <Stack>
                  {!isPlannedDateDisable ? (
                    <>
                      <Typography variant="subtitle2">Planned Start Date*</Typography>
                      <CognisaasCalenderNew
                        width="200px"
                        name="projectPlannedStartDate"
                        value={
                          props.values.projectPlannedStartDate ? new Date(props.values.projectPlannedStartDate) : null
                        }
                        handleChange={(e: any) => props.setFieldValue('projectPlannedStartDate', new Date(e))}
                        disabled={isPlannedDateDisable}
                      />
                    </>
                  ) : (
                    <>
                      <CognisaaasTooltip
                        title={
                          <Typography variant="subtitle1" style={{ padding: '10px' }}>
                            Planned start date cannot be changed once the {label_project.toLowerCase()} has been
                            started. Contact an admin if you want to change the planned start date.
                          </Typography>
                        }
                      >
                        <div>
                          <React.Fragment>
                            <Typography variant="subtitle2">Planned Start Date*</Typography>
                            <CognisaasCalenderNew
                              width="200px"
                              name="projectPlannedStartDate"
                              value={
                                props.values.projectPlannedStartDate
                                  ? new Date(props.values.projectPlannedStartDate)
                                  : null
                              }
                              handleChange={(e: any) => props.setFieldValue('projectPlannedStartDate', new Date(e))}
                              disabled={isPlannedDateDisable}
                            />
                          </React.Fragment>
                        </div>
                      </CognisaaasTooltip>
                    </>
                  )}
                </Stack>
                <Stack>
                  <Typography variant="subtitle2">Actual Start Date</Typography>
                  <CognisaasCalenderNew
                    width="200px"
                    name="projectStartDate"
                    value={props.values.projectStartDate ? new Date(props.values.projectStartDate) : null}
                    handleChange={(e: any) => {
                      props.setFieldValue('projectStartDate', new Date(e));
                    }}
                  />
                </Stack>
              </Stack>
              <Stack mt={2} direction="row" spacing={2}>
                <Stack>
                  {!isPlannedDateDisable ? (
                    <>
                      <Typography variant="subtitle2">{label_due_date}*</Typography>
                      <CognisaasCalenderNew
                        width="200px"
                        name="projectDueDate"
                        value={props.values.projectDueDate ? new Date(props.values.projectDueDate) : null}
                        handleChange={(e: any) => props.setFieldValue('projectDueDate', new Date(e))}
                        disabled={isPlannedDateDisable}
                      />
                    </>
                  ) : (
                    <>
                      <CognisaaasTooltip
                        title={
                          <Typography variant="subtitle1" style={{ padding: '10px' }}>
                            {label_due_date} cannot be changed once the {label_project.toLowerCase()} has been started.
                            Contact an admin if you want to change the {label_due_date.toLowerCase()}.
                          </Typography>
                        }
                      >
                        <div>
                          <React.Fragment>
                            <Typography variant="subtitle2">{label_due_date}*</Typography>
                            <CognisaasCalenderNew
                              width="200px"
                              name="projectDueDate"
                              value={props.values.projectDueDate ? new Date(props.values.projectDueDate) : null}
                              handleChange={(e: any) => props.setFieldValue('projectDueDate', new Date(e))}
                              disabled={isPlannedDateDisable}
                            />
                          </React.Fragment>
                        </div>
                      </CognisaaasTooltip>
                    </>
                  )}
                </Stack>
                <Stack>
                  <Typography variant="subtitle2">Estimated Delivery Date</Typography>
                  <CognisaasCalenderNew
                    width="200px"
                    name="projectEtaForDelivery"
                    value={props.values.projectEtaForDelivery ? new Date(props.values.projectEtaForDelivery) : null}
                    handleChange={(e: any) => props.setFieldValue('projectEtaForDelivery', new Date(e))}
                  />
                </Stack>
              </Stack>

              <Stack mt={2}>
                {props.values.projectStage !== getCustomNameMappedValue('Go-Live', 'project_stage') &&
                props.values.projectStage !== getCustomNameMappedValue('Adoption', 'project_stage') ? (
                  <div>
                    <CognisaaasTooltip
                      title={
                        <Typography variant="subtitle1" style={{ padding: '10px' }}>
                          {label_project} {label_delivery_date.toLowerCase()} date will be system generated when this{' '}
                          {label_project.toLowerCase()} is delivered.
                        </Typography>
                      }
                    >
                      <div>
                        <React.Fragment>
                          <Typography variant="subtitle2">{label_delivery_date}</Typography>
                          <CognisaasCalenderNew
                            disabled={
                              props.values.projectStage !== getCustomNameMappedValue('Go-Live', 'project_stage') &&
                              props.values.projectStage !== getCustomNameMappedValue('Adoption', 'project_stage')
                            }
                            width="200px"
                            name="projectDeliverydate"
                            value={props.values.projectDeliverydate ? new Date(props.values.projectDeliverydate) : null}
                            handleChange={(e: any) => props.setFieldValue('projectDeliverydate', new Date(e))}
                          />
                        </React.Fragment>
                      </div>
                    </CognisaaasTooltip>
                  </div>
                ) : (
                  <React.Fragment>
                    <Typography variant="subtitle2">{label_delivery_date}</Typography>
                    <CognisaasCalenderNew
                      disabled={
                        props.values.projectStage !== getCustomNameMappedValue('Go-Live', 'project_stage') &&
                        props.values.projectStage !== getCustomNameMappedValue('Adoption', 'project_stage')
                      }
                      width="200px"
                      name="projectDeliverydate"
                      value={props.values.projectDeliverydate ? new Date(props.values.projectDeliverydate) : null}
                      handleChange={(e: any) => props.setFieldValue('projectDeliverydate', new Date(e))}
                    />
                  </React.Fragment>
                )}
              </Stack>
              <Stack mt={2} direction="row">
                <Stack>
                  <Typography variant="subtitle2">{label_project} Description</Typography>
                  <TextareaAutosize
                    maxRows={15}
                    value={parseLines(props.values.projectDescription)}
                    name="projectDescription"
                    onChange={props.handleChange}
                    aria-label="maximum height"
                    placeholder="Project decsription"
                    defaultValue=""
                    style={{ width: 420, height: 88 }}
                  />
                </Stack>
              </Stack>
              <Grid mt={3} mb={2} spacing={1} container direction="row" justifyContent="flex-end" alignItems="flex-end">
                <Grid item>
                  <CognisaasButton
                    clickHandler={props.handleSubmit}
                    isOutlined={false}
                    disabled={!props.isValid || props.dirty == false}
                    label="Save"
                  />
                </Grid>
                <Grid item>
                  <CognisaasButton clickHandler={closeDrawer} isOutlined={true} label="Cancel" />
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Formik>
    );
  };

  return (
    <>
      <CognisaasToast
        message={alertDetails.message}
        type={alertDetails.type}
        open={alertDetails.isVisible}
        onClose={() => {
          setAlertDetails({ isVisible: false, type: alertDetails.type, message: '' });
        }}
      />
      <Drawer open={props.drawerState} onClose={props.onClose} anchor="right">
        <Grid spacing={2} mx={2}>
          <Stack direction="row" justifyContent={'space-between'}>
            <Typography my={3} sx={{ marginTop: '70px' }} variant="h2">
              Edit {label_project} Details
            </Typography>
            <IconButton disableRipple sx={{ marginTop: '60px' }} onClick={handleClick}>
              <MoreVertIcon />
            </IconButton>
          </Stack>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            style={{ padding: '0px' }}
          >
            <MenuItem
              onClick={() => {
                setOpenDeleteModal(true);
                // handleDelete();
              }}
            >
              {/* <ListItemIcon><DeleteIcon fontSize="small" /></ListItemIcon> */}
              <Typography variant="inherit">Delete {label_project}</Typography>
            </MenuItem>
          </Menu>
          <Stack direction="row">
            {props.client.client_logo_link && props.client.client_logo_link.length > 0 ? (
              <AvatarImage src={props.client.client_logo_link} alt="Client logo" height="38px" width="38px" />
            ) : (
              <Avatar
                style={{ height: '38px', width: '38px', marginTop: '3px' }}
                {...stringAvatar(props.client.client_name)}
              />
            )}
            <Typography ml={1} mt={1} variant="subtitle1">
              {props.client.client_name}
            </Typography>
          </Stack>

          {projectDetailForm()}
        </Grid>
      </Drawer>
      <DeleteModal
        isOpen={openDeleteModal}
        setIsOpen={setOpenDeleteModal}
        message={`Are you sure you want to delete this ${label_project.toLowerCase()}`}
        onDelete={handleDelete}
      />
    </>
  );
};

export default EditProjectDrawer;
