import { Stack, Typography } from '@mui/material';
import CognisaasButton from '../CognisaasButton/CognisaasButton';
import { ReactComponent as NoRecordsfound } from '../../../assets/svgs/no_records_mini.svg';

interface Props {
  clickHandler: () => void;
}
const NoRecordsMapped = (props: Props) => {
  return (
    <Stack
      justifyContent={'center'}
      spacing={2}
      alignItems={'center'}
      sx={{ background: 'white', height: '65vh', borderRadius: '4px', marginTop: '8px' }}
    >
      <Typography variant={'h1'}>No product requirement mapped</Typography>
      <CognisaasButton isOutlined={false} label="Add Requirement" clickHandler={props.clickHandler} />
      <NoRecordsfound />
    </Stack>
  );
};

export default NoRecordsMapped;
