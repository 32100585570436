import { Box } from '@mui/system';
import { ReactComponent as NoRecordIcon } from '../../../assets/svgs/no_records_mini.svg';
const NoRecordsFound = () => {
  return (
    <Box sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <NoRecordIcon />
    </Box>
  );
};

export default NoRecordsFound;
