import React, { useEffect, useRef, useState } from 'react';
import Popover from '@mui/material/Popover';

import { BarProps } from 'components/ResourceManagement/components/bar';
import styles from './simple.module.css';
import { Box, FlexBox, GridBox, SvgRect, SvgText, Text } from 'components/ResourceManagement/design-system/primitives';
import styled from 'styled-components';
import { setSelectedResourceById } from 'store/modules/ResourceManagement';
import { useAppDispatch } from 'store/hooks';
import { useModal } from '../../shared/useModal';
import { GenericModal } from '../../shared/Modal';
import { ResourceView } from 'components/ResourceManagement/views/ResourceView/resource-view';
import { usePopover } from '../../shared/usePopover';
import { getShortDate } from 'components/ResourceManagement/utils/date';

const StyledBarText = styled(SvgText)<{
  isTextInside: boolean;
}>`
  ${({ theme, isTextInside }) => {
    return isTextInside
      ? `
  fill: ${theme.colors.grey_1};
  text-anchor: middle;
  font-weight: ${theme.fontWeights[1]};
  dominant-baseline: central;
  user-select: none;
  pointer-events: none;
  font-size: small;`
      : `
  fill: ${theme.colors.grey_1};
  text-anchor: start;

  user-select: none;
  pointer-events: none;
  font-size: small;
  `;
  }}
`;

const StyledBarRect = styled(SvgRect)<{
  progress: number;
}>`
  ${({ progress, theme }) => {
    if (progress > 100) {
      return {
        fill: `${theme.colors.bragLite_2}`,
        stroke: `${theme.colors.brag_2}`,
      };
    } else if (progress < 100) {
      return {
        fill: `${theme.colors.bragLite_4}`,
        stroke: `${theme.colors.brag_4}`,
      };
    } else if (progress === 100) {
      return {
        fill: `${theme.colors.grey_3}`,
        stroke: `${theme.colors.grey_2}`,
      };
    }
  }}
`;

const StyledG = styled('g')<{
  isMainView: boolean;
}>`
  outline: none;
  ${({ isMainView }) => {
    if (isMainView) {
      return {
        outline: 'none',
        cursor: 'pointer',
        pointerEvents: 'all',
      };
    } else {
      return {
        outline: 'none',
        cursor: 'default',
        pointerEvents: 'all',
      };
    }
  }}
`;

const StyledPopoverGrid = styled(GridBox)`
  grid-template-columns: 1fr;
  padding: ${({ theme }) => theme.space[5]};
  grid-column-gap: ${({ theme }) => theme.space[5]};
`;

export const SimpleBar: React.FC<BarProps> = ({ component, componentHeight, mode, barCornerRadius }) => {
  const textRef = useRef<SVGTextElement>(null);
  const [isTextInside, setIsTextInside] = useState(true);
  const dispatch = useAppDispatch();
  const { isModalOpen, toggleModal } = useModal();
  const { anchorEl, handlePopoverClose, handlePopoverOpen, popoverId, popoverOpen } = usePopover();

  const width = component.x2 - component.x1;
  let actualWidth = width;
  if (actualWidth < 0) actualWidth = ~actualWidth;

  const getBarTextX = () => {
    const width = component.x2 - component.x1;
    let actualWidth = width;
    if (actualWidth < 0) actualWidth = ~actualWidth;
    const hasChild = component.barChildren.length > 0;
    if (isTextInside) {
      return component.x1 + actualWidth * 0.5;
    }
    return component.x1 + actualWidth + +hasChild + 5;
  };

  useEffect(() => {
    if (textRef.current) {
      setIsTextInside(textRef.current.getBBox().width < component.x2 - component.x1);
    }
  }, [textRef, component]);

  // console.log({ component });
  const barTextX = getBarTextX();
  let actualBarTextX = barTextX;
  if (actualBarTextX < 0) actualBarTextX = ~actualBarTextX;

  const isMainView = mode === 'main-view';

  const handleBarClick = (itemId: string) => {
    if (isMainView) {
      const resourceId = itemId;
      dispatch(setSelectedResourceById({ resourceId }));
      toggleModal();
    }
  };

  const isSingleGrid = component.count === 1;
  return (
    <>
      <StyledG
        tabIndex={0}
        onMouseEnter={(e: any) => handlePopoverOpen(e)}
        onMouseLeave={() => handlePopoverClose()}
        onClick={() => handleBarClick(component.id)}
        isMainView={isMainView}
      >
        {actualWidth ? (
          <StyledBarRect
            progress={component.progress}
            x={component.x1}
            width={actualWidth}
            y={component.y}
            height={component.height}
            rx={barCornerRadius}
            ry={barCornerRadius}
            className={styles.componentBackground}
          />
        ) : null}
        {actualBarTextX ? (
          <StyledBarText
            x={actualBarTextX}
            y={isTextInside ? component.y + componentHeight * 0.5 : component.y + componentHeight * 0.7}
            isTextInside={isTextInside}
            ref={textRef}
            variant="paragraph2"
          >
            <>{!isSingleGrid ? `${component.count} items  ` : ''}</>
            {component.progress.toFixed()}%
          </StyledBarText>
        ) : null}
      </StyledG>
      <Popover
        id={popoverId}
        sx={{
          pointerEvents: 'none',
        }}
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        style={{ zIndex: isMainView ? 3 : 31 }}
        disableRestoreFocus
      >
        <StyledPopoverGrid>
          <FlexBox variant="flexCol" justifyContent="center" alignItems="flex-start">
            <Text variant="heading3">{component.name}</Text>

            <FlexBox variant="flexRow" justifyContent="flex-start" alignItems="center">
              <Text variant="paragraph1">Duration:&nbsp;</Text>
              <Text variant="paragraph2">{`${getShortDate(component.start)} - ${getShortDate(component.end)}`}</Text>
            </FlexBox>
          </FlexBox>
        </StyledPopoverGrid>
      </Popover>
      {isModalOpen && (
        <GenericModal isModalOpen={isModalOpen} toggleFn={toggleModal}>
          <Box id="resource-management-resource-view-from-bar">
            <ResourceView />
          </Box>
        </GenericModal>
      )}
    </>
  );
};
