import { format } from 'date-fns';

const getDateGreaterOrLess = (obj1: any, obj2: any) => {
    const time1 = format(new Date(obj1.replace(/-/g, '/').slice(0, 10)),'yyyy/MM/dd');
    const time2 = format(new Date(obj2.replace(/-/g, '/').slice(0, 10)),'yyyy/MM/dd');
    if(time1<time2){ // completed late
        return true;
    }
    else{ // completed on time
        return false;
    }
};

const totalProjects = (data: any) => {
    const lst:any[] = [];
    data?.map((obj: any) => {
        const temp = obj.project_details?.length;
        lst.push(temp);
    });
    return lst;
};
const totalProjectCompletedOnTime = (data: any) => {
    const lst:any[] = [];
    data?.map((obj: any) => {
        let cnt = 0;
        obj?.project_details?.map((ins: any) => {
            if(ins.project_readinessstate_status=='blue' && ins.project_deliverydate != '0000-00-00 00:00:00'){
                if(!getDateGreaterOrLess(ins.project_requiredbydate,ins.project_deliverydate)){
                    ++cnt;
                }
            }
        });
        lst.push(cnt);
    });
    return lst;
};
const totalUsecase = (data: any) => {
    const lst:any[] = [];
    data?.map((obj: any) => {
        const temp = obj.usecase_details?.length;
        lst.push(temp);
    });
    return lst;
};
const totalUsecaseCompletedOnTime = (data: any) => {
    const lst:any[] = [];
    data?.map((obj: any) => {
        let cnt = 0;
        obj?.usecase_details?.map((ins: any) => {
            if(ins.case_readinessstate_status=='blue' && ins.case_deliverydate != '0000-00-00 00:00:00'){
                if(!getDateGreaterOrLess(ins.case_requiredbydate,ins.case_deliverydate)){
                    ++cnt;
                }
            }
        });
        lst.push(cnt);
    });
    return lst;
};

const taskAssigned = (data: any) => {
    const lst:any[] = [];
    data?.map((obj: any) => {
        const temp = obj.project_total_task?.length + obj.usecase_total_task?.length;
        lst.push(temp);
    });
    return lst;
};

const taskCompletedOnTime = (data: any) => {
    const lst:any[] = [];
    let cnt:any;
    data?.map((obj: any) => {
        cnt = 0;
        obj?.project_total_task?.map((ins: any) => {
            if(ins.task_due_date != '0000-00-00 00:00:00' && ins.task_closed_date != '0000-00-00 00:00:00' && ins.task_stage == 'Done'){
                if(!getDateGreaterOrLess(ins.task_due_date,ins.task_closed_date)){
                    ++cnt;
                }
            }
        });
        obj?.usecase_total_task?.map((ins: any) => {
            if(ins.task_due_date != '0000-00-00 00:00:00' && ins.task_closed_date != '0000-00-00 00:00:00' && ins.task_stage == 'Done'){
                if(!getDateGreaterOrLess(ins.task_due_date,ins.task_closed_date)){
                    ++cnt;
                }
            }
        });
        lst.push(cnt);
    });
    return lst;
};

const taskCompletedLate = (data: any) => {
    const lst:any[] = [];
    let cnt:any;
    data?.map((obj: any) => {
        cnt = 0;
        obj?.project_total_task?.map((ins: any) => {
            if(ins.task_due_date != '0000-00-00 00:00:00' && ins.task_closed_date != '0000-00-00 00:00:00' && ins.task_stage == 'Done'){
                if(getDateGreaterOrLess(ins.task_due_date,ins.task_closed_date)){
                    ++cnt;
                }
            }
        });
        obj?.usecase_total_task?.map((ins: any) => {
            if(ins.task_due_date != '0000-00-00 00:00:00' && ins.task_closed_date != '0000-00-00 00:00:00' && ins.task_stage == 'Done'){
                if(getDateGreaterOrLess(ins.task_due_date,ins.task_closed_date)){
                    ++cnt;
                }
            }
        });
        lst.push(cnt);
    });
    return lst;
};

const totalTaskCompleted = (data: any) => {
    const lst:any[] = [];
    let cnt:any;
    data?.map((obj: any) => {
        cnt = 0;
        obj?.project_total_task?.map((ins: any) => {
            if(ins.task_stage == 'Done') cnt++;
        });
        obj?.usecase_total_task?.map((ins: any) => {
            if(ins.task_stage == 'Done') cnt++;
        });
        lst.push(cnt);
    });
    return lst;
};


const totalTaskOverdue = (data: any) => {
    const lst:any[] = [];
    let cnt:any;
    const today = format(new Date(),'yyyy/MM/dd');
    data?.map((obj: any) => {
        cnt = 0;
        obj?.project_total_task?.map((ins: any) => {
            if(ins.task_due_date != '0000-00-00 00:00:00' && ins.task_stage != 'Done'){
            if(getDateGreaterOrLess(today,ins.task_due_date)){
                ++cnt;
            }
        }
            // const planned_end_date = new Date(ins.task_due_date?.replace(/-/g, '/')?.slice(0, 10));
            // const diff = (today?.getTime() - planned_end_date?.getTime()) / (24 * 60 * 60 * 1000);
            // if(diff > 0) cnt++;
        });
        obj?.usecase_total_task?.map((ins: any) => {
            if(ins.task_due_date != '0000-00-00 00:00:00' && ins.task_stage != 'Done'){
                if(getDateGreaterOrLess(today,ins.task_due_date)){
                    ++cnt;
                }
            }
        });
        lst.push(cnt);
    });
    return lst;
};

const totalRiskedTasks = (data: any) => {
    // project_total_task
    // usecase_total_task
    const lst:any[] = [];
    let cnt:any;
    data?.map((obj: any) => {
        cnt = 0;
        obj?.project_total_task?.map((ins: any) => {
            if(ins.task_atrisk_atleast_once == '1') cnt++;
        });
        obj?.usecase_total_task?.map((ins: any) => {
            if(ins.task_atrisk_atleast_once == '1') cnt++;
        });
        lst.push(cnt);
    });
    return lst;
};

const tasksMarkedBlockLists = (data: any) => {
    const lst:any[] = [];
    let cnt:any;
    data?.map((obj: any) => {
        cnt = 0;
        obj?.project_total_task?.map((ins: any) => {
            if(ins.task_blocked_atleast_once == '1') cnt++;
        });
        obj?.usecase_total_task?.map((ins: any) => {
            if(ins.task_blocked_atleast_once == '1') cnt++;
        });
        lst.push(cnt);
    });
    return lst;
};

export const calculateHelperColumns = (data: any) => {
    const totalProjectsList = totalProjects(data);
    const totalUsecaseList = totalUsecase(data);
    const taskAssignedList = taskAssigned(data);
    const taskCompletedOnTimeList = taskCompletedOnTime(data);
    const taskCompletedLateList = taskCompletedLate(data);
    const totalTaskCompletedList = totalTaskCompleted(data);
    const tasksOverdueList = totalTaskOverdue(data);
    const riskedTask = totalRiskedTasks(data);
    const tasksMarkedBlockList = tasksMarkedBlockLists(data);
    const usecaseCompletedOnTime= totalUsecaseCompletedOnTime(data);
    const projectCompletedOnTime= totalProjectCompletedOnTime(data);

    const newData = data?.map((obj: any, ind: number) => {
        return ({
            ...obj,
            'totalProjects': totalProjectsList[ind],
            'totalUsecases': totalUsecaseList[ind],
            'taskAssigned': taskAssignedList[ind],
            'taskCompletedOnTime': taskCompletedOnTimeList[ind],
            'taskCompletedLate': taskCompletedLateList[ind],
            'totalTaskCompleted': totalTaskCompletedList[ind],
            'tasksOverdue': tasksOverdueList[ind],
            'riskedTask': riskedTask[ind],
            'tasksMarkedBlockList': tasksMarkedBlockList[ind],
            'usecaseCompletedOnTime': usecaseCompletedOnTime[ind],
            'projectCompletedOnTime': projectCompletedOnTime[ind],
        });
    });
    return newData;
};