// import BasicModal from 'components/shared/CognisaasModal/CognisaasModal';
import { Stack, Typography, TextField } from '@mui/material';
import CognisaasCalenderNew from 'components/shared/CognisaasCalenderNew/CognisaasCalenderNew';
import CognisaasButton from 'components/shared/CognisaasButton/CognisaasButton';
import { useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import { updateCustomerHolidays } from 'store/modules/Settings/slice';
import format from 'date-fns/format';
import { startOfDay } from 'date-fns';
import { getCustomerUid } from 'utils/getUid';
import { getCustomerDetail } from 'store/modules/ManageClient/ClientList/slice';
import CognisaasToast from 'components/shared/CognisaasToast/CognisaasToast';
import { getCustomerDetail as commonDispatch } from 'store/modules/Common/slice';

const AddHoliday = (props: any) => {
  const dispatch = useAppDispatch();
  const [detail, setDetail] = useState<string>('');
  const [date, setDate] = useState<any>(new Date());
  const [updateStatus, setUpdateStatus] = useState<boolean>(false);

  const addHoliday = async () => {
    let res;
    const oldValue = props.customerDetail.customer_holidays;
    if (!oldValue) {
      let firstAddition: any = {
        detail: detail,
        date: format(startOfDay(date), 'yyyy-MM-dd HH:mm:ss'),
      };
      const array: any = [];
      array.push(firstAddition);
      firstAddition = JSON.stringify(array);
      const data = {
        customer_uid: getCustomerUid(),
        holidays: firstAddition,
      };
      const res = await dispatch(updateCustomerHolidays(data));
      if (res) {
        setUpdateStatus(true);
      }
      dispatch(getCustomerDetail());
    } else {
      const oldValue = JSON.parse(props.customerDetail.customer_holidays);
      const newValue = [
        ...oldValue,
        {
          date: format(startOfDay(date), 'yyyy-MM-dd HH:mm:ss'),
          detail: detail,
        },
      ];
      const data = {
        customer_uid: getCustomerUid(),
        holidays: JSON.stringify(newValue),
      };
      res = await dispatch(updateCustomerHolidays(data)).unwrap();
      dispatch(getCustomerDetail());

      if (res) {
        console.log(res);
        setUpdateStatus(true);
      }
    }
    dispatch(commonDispatch());
    props.onClose(res);
  };

  return (
    <Stack>
      {updateStatus ? (
        <CognisaasToast
          open={updateStatus}
          onClose={() => setUpdateStatus(false)}
          message={'Updated successfully'}
          type="success"
        />
      ) : null}
      <Stack justifyContent={'center'}>
        <Stack>
          <Typography variant="subtitle1">Detail</Typography>
          <TextField
            autoFocus={false}
            style={{ width: '257px' }}
            placeholder="Enter detail"
            size="small"
            value={detail}
            onChange={(e: any) => setDetail(e.target.value)}
            autoComplete=""
          />
        </Stack>
        <Stack>
          <Typography variant="subtitle1">Select day</Typography>
          <CognisaasCalenderNew value={date} handleChange={(date: any) => setDate(new Date(date))} />
        </Stack>
      </Stack>
      <Stack sx={{ marginTop: '60%' }} direction="row" alignItems={'flex-end'} justifyContent={'flex-end'}>
        <CognisaasButton
          sx={{ marginRight: '16px' }}
          label="Cancel"
          isOutlined={true}
          clickHandler={() => props.onClose()}
        />
        <CognisaasButton label="Save" isOutlined={false} clickHandler={addHoliday} />
      </Stack>
    </Stack>
  );
};

export default AddHoliday;
