import { format } from 'date-fns';
import { isNull } from 'utils/dataHelpers';

// const getDatesDifference = (obj1: any, obj2: any) => {
//     const time1 = new Date(obj1.replace(/-/g, '/').slice(0, 10));
//     const time2 = new Date(obj2.replace(/-/g, '/').slice(0, 10));
//     const diff = (time1.getTime() - time2.getTime())/ (24 * 60 * 60 * 1000);
//     if(time2 != undefined || time2 != null || time2 != 'Invalid Date'){
//         return -1;
//     }
//     return diff;
// };

const getDateGreaterOrLess = (obj1: any, obj2: any) => {
  const time1 = format(new Date(obj1.replace(/-/g, '/').slice(0, 10)), 'yyyy/MM/dd');
  const time2 = format(new Date(obj2.replace(/-/g, '/').slice(0, 10)), 'yyyy/MM/dd');
  if (time1 < time2) {
    // completed late
    return true;
  } else {
    // completed on time
    return false;
  }
};

export const getAllPhases = (data: any) => {
  const lst: any = [];
  data &&
    data.map((obj: any) => {
      lst.push(obj.project_section.length);
    });
  return lst;
};

export const getAllTasks = (data: any) => {
  const lst: any = [];
  data &&
    data.map((obj: any) => {
      let temp = 0;
      temp = obj.project_clientTasks?.length + obj.project_tasks?.length;
      lst.push(isNaN(temp) ? 0 : temp);
    });
  return lst;
};

export const getTasksCompletedOnTime = (data: any) => {
  const lst: any = [];
  data &&
    data.map((obj: any) => {
      let temp = 0;
      obj.project_clientTasks?.map((element: any) => {
        if (
          element.task_due_date != '0000-00-00 00:00:00' &&
          element.task_closed_date != '0000-00-00 00:00:00' &&
          element.task_stage === 'Done' &&
          !isNull(element.task_due_date) &&
          !isNull(element.task_closed_date)
        ) {
          if (!getDateGreaterOrLess(element.task_due_date, element.task_closed_date)) {
            temp += 1;
          }
        }
      });
      obj.project_tasks?.map((element: any) => {
        if (
          element.task_due_date != '0000-00-00 00:00:00' &&
          element.task_closed_date != '0000-00-00 00:00:00' &&
          element.task_stage === 'Done' &&
          !isNull(element.task_due_date) &&
          !isNull(element.task_closed_date)
        ) {
          if (!getDateGreaterOrLess(element.task_due_date, element.task_closed_date)) {
            temp += 1;
          }
        }
      });
      lst.push(temp);
    });
  return lst;
};

export const getTasksCompletedLate = (data: any) => {
  const lst: any = [];
  data &&
    data.map((obj: any) => {
      let temp = 0;
      obj.project_clientTasks?.map((element: any) => {
        if (
          element.task_due_date != '0000-00-00 00:00:00' &&
          element.task_closed_date != '0000-00-00 00:00:00' &&
          element.task_stage == 'Done' &&
          !isNull(element.task_due_date) &&
          !isNull(element.task_closed_date)
        ) {
          if (getDateGreaterOrLess(element.task_due_date, element.task_closed_date)) {
            temp += 1;
          }
        }
      });
      obj.project_tasks?.map((element: any) => {
        if (
          element.task_due_date != '0000-00-00 00:00:00' &&
          element.task_closed_date != '0000-00-00 00:00:00' &&
          element.task_stage == 'Done' &&
          !isNull(element.task_due_date) &&
          !isNull(element.task_closed_date)
        ) {
          if (getDateGreaterOrLess(element.task_due_date, element.task_closed_date)) {
            temp += 1;
          }
        }
      });
      lst.push(temp);
    });
  return lst;
};

export const getTasksNotDone = (data: any) => {
  const lst: any = [];
  data &&
    data.map((obj: any) => {
      let temp = 0;
      obj.project_tasks?.map((ins: any) => {
        if (ins.task_stage !== 'Done') {
          temp = temp + 1;
        }
      });
      obj.project_clientTasks?.map((ins: any) => {
        if (ins.task_stage !== 'Done') {
          temp = temp + 1;
        }
      });
      lst.push(temp);
    });
  return lst;
};

export const getMilestoneTask = (data: any) => {
  const lst: any = [];
  data &&
    data.map((obj: any) => {
      let temp = 0;
      obj.project_tasks?.map((ins: any) => {
        if (ins.milestone_task == '1') {
          temp = temp + 1;
        }
      });
      obj.project_clientTasks?.map((ins: any) => {
        if (ins.milestone_task == '1') {
          temp = temp + 1;
        }
      });
      lst.push(temp);
    });
  return lst;
};

export const getMilestoneTaskCompletedOnTime = (data: any) => {
  const lst: any = [];
  data &&
    data.map((obj: any) => {
      let temp = 0;
      obj.project_clientTasks?.map((element: any) => {
        if (element.milestone_task == '1') {
          if (
            element.task_due_date != '0000-00-00 00:00:00' &&
            element.task_closed_date != '0000-00-00 00:00:00' &&
            element.task_stage == 'Done'
          ) {
            if (!getDateGreaterOrLess(element.task_due_date, element.task_closed_date)) {
              temp += 1;
            }
          }
        }
      });
      obj.project_tasks.map((element: any) => {
        if (element.milestone_task == '1') {
          if (
            element.task_due_date != '0000-00-00 00:00:00' &&
            element.task_closed_date != '0000-00-00 00:00:00' &&
            element.task_stage == 'Done'
          ) {
            if (!getDateGreaterOrLess(element.task_due_date, element.task_closed_date)) {
              temp += 1;
            }
          }
        }
      });
      lst.push(temp);
    });
  return lst;
};

export const getMilestoneTaskCompletedLate = (data: any) => {
  const lst: any = [];
  data &&
    data.map((obj: any) => {
      let temp = 0;
      obj.project_clientTasks?.map((element: any) => {
        if (element.milestone_task == '1') {
          if (
            element.task_due_date != '0000-00-00 00:00:00' &&
            element.task_closed_date != '0000-00-00 00:00:00' &&
            element.task_stage == 'Done'
          ) {
            if (getDateGreaterOrLess(element.task_due_date, element.task_closed_date)) {
              temp += 1;
            }
          }
        }
      });
      obj.project_tasks?.map((element: any) => {
        if (element.milestone_task == '1') {
          if (
            element.task_due_date != '0000-00-00 00:00:00' &&
            element.task_closed_date != '0000-00-00 00:00:00' &&
            element.task_stage == 'Done'
          ) {
            if (getDateGreaterOrLess(element.task_due_date, element.task_closed_date)) {
              temp += 1;
            }
          }
        }
      });
      lst.push(temp);
    });
  return lst;
};

export const getMilestoneTaskNotCompleted = (data: any) => {
  const lst: any = [];
  data &&
    data.map((obj: any) => {
      let temp = 0;
      obj.project_clientTasks?.map((element: any) => {
        if (element.milestone_task == '1') {
          if (element.task_stage != 'Done') {
            temp += 1;
          }
        }
      });
      obj.project_tasks?.map((element: any) => {
        if (element.milestone_task == '1') {
          if (element.task_stage != 'Done') {
            temp += 1;
          }
        }
      });
      lst.push(temp);
    });
  return lst;
};

export const getAllUseCases = (data: any) => {
  const lst: any = [];
  data &&
    data.map((obj: any) => {
      let temp = 0;
      temp = obj.usecase?.length;
      lst.push(isNaN(temp) ? 0 : temp);
    });
  return lst;
};

export const getUsecaseTasks = (data: any) => {
  // phases (1st) column
  const lst: any = [];
  data &&
    data.map((obj: any) => {
      let temp = 0;
      obj?.usecase?.map((element: any) => {
        temp = element?.task?.length + element?.clientTask?.length;
        lst.push(isNaN(temp) ? 0 : temp);
      });
    });
  return lst;
};

export const getUsecaseTasksCompletedOnTime = (data: any) => {
  // phases (1st) column
  const lst: any = [];
  data &&
    data.map((obj: any) => {
      obj?.usecase?.map((element: any) => {
        let temp = 0;
        element.task?.map((ins: any) => {
          if (
            ins.task_due_date != '0000-00-00 00:00:00' &&
            ins.task_closed_date != '0000-00-00 00:00:00' &&
            ins.task_stage == 'Done'
          ) {
            if (!getDateGreaterOrLess(ins.task_due_date, ins.task_closed_date)) {
              temp = temp + 1;
            }
          }
        });
        element.clientTask?.map((ins: any) => {
          if (
            ins.task_due_date != '0000-00-00 00:00:00' &&
            ins.task_closed_date != '0000-00-00 00:00:00' &&
            ins.task_stage == 'Done'
          ) {
            if (!getDateGreaterOrLess(ins.task_due_date, ins.task_closed_date)) {
              temp = temp + 1;
            }
          }
        });

        lst.push(temp);
      });
    });
  return lst;
};

export const getUsecaseTasksCompletedLate = (data: any) => {
  // phases (1st) column
  const lst: any = [];
  data &&
    data.map((obj: any) => {
      obj?.usecase?.map((element: any) => {
        let temp = 0;
        element.task?.map((ins: any) => {
          if (
            ins.task_due_date != '0000-00-00 00:00:00' &&
            ins.task_closed_date != '0000-00-00 00:00:00' &&
            ins.task_stage == 'Done'
          ) {
            if (getDateGreaterOrLess(ins.task_due_date, ins.task_closed_date)) {
              temp = temp + 1;
            }
          }
        });
        element.clientTask?.map((ins: any) => {
          if (
            ins.task_due_date != '0000-00-00 00:00:00' &&
            ins.task_closed_date != '0000-00-00 00:00:00' &&
            ins.task_stage == 'Done'
          ) {
            if (getDateGreaterOrLess(ins.task_due_date, ins.task_closed_date)) {
              temp = temp + 1;
            }
          }
        });

        lst.push(temp);
      });
    });
  return lst;
};

export const getUsecaseTasksNotCompleted = (data: any) => {
  // phases (1st) column
  const lst: any = [];
  data &&
    data.map((obj: any) => {
      obj?.usecase?.map((element: any) => {
        let temp = 0;
        element.task?.map((ins: any) => {
          if (ins.task_stage != 'Done' || ins.task_stage != 'done') {
            temp = temp + 1;
          }
        });
        element.clientTask?.map((ins: any) => {
          if (ins.task_stage != 'Done' || ins.task_stage != 'done') {
            temp = temp + 1;
          }
        });

        lst.push(temp);
      });
    });
  return lst;
};

export const getUsecaseMilestone = (data: any) => {
  // phases (1st) column
  const lst: any = [];
  data &&
    data.map((obj: any) => {
      obj?.usecase?.map((element: any) => {
        let temp = 0;
        element.task?.map((ins: any) => {
          if (ins.milestone_task == '1') {
            temp = temp + 1;
          }
        });
        element.clientTask?.map((ins: any) => {
          if (ins.milestone_task == '1') {
            temp = temp + 1;
          }
        });

        lst.push(temp);
      });
    });
  return lst;
};

export const getUsecaseMilestoneCompletedOnTime = (data: any) => {
  // phases (1st) column
  const lst: any = [];
  data &&
    data.map((obj: any) => {
      obj?.usecase?.map((element: any) => {
        let temp = 0;
        element.task?.map((ins: any) => {
          if (ins.milestone_task == '1') {
            if (
              ins.task_due_date != '0000-00-00 00:00:00' &&
              ins.task_closed_date != '0000-00-00 00:00:00' &&
              ins.task_stage == 'Done'
            ) {
              if (!getDateGreaterOrLess(ins.task_due_date, ins.task_closed_date)) {
                temp = temp + 1;
              }
            }
          }
        });
        element.clientTask?.map((ins: any) => {
          if (ins.milestone_task == '1') {
            if (
              ins.task_due_date != '0000-00-00 00:00:00' &&
              ins.task_closed_date != '0000-00-00 00:00:00' &&
              ins.task_stage == 'Done'
            ) {
              if (!getDateGreaterOrLess(ins.task_due_date, ins.task_closed_date)) {
                temp = temp + 1;
              }
            }
          }
        });

        lst.push(temp);
      });
    });
  return lst;
};

export const getUsecaseMilestoneCompletedLate = (data: any) => {
  // phases (1st) column
  const lst: any = [];
  data &&
    data.map((obj: any) => {
      obj?.usecase?.map((element: any) => {
        let temp = 0;
        element.task?.map((ins: any) => {
          if (ins.milestone_task == '1') {
            if (
              ins.task_due_date != '0000-00-00 00:00:00' &&
              ins.task_closed_date != '0000-00-00 00:00:00' &&
              ins.task_stage == 'Done'
            ) {
              if (getDateGreaterOrLess(ins.task_due_date, ins.task_closed_date)) {
                temp = temp + 1;
              }
            }
          }
        });
        element.clientTask?.map((ins: any) => {
          if (ins.milestone_task == '1') {
            if (
              ins.task_due_date != '0000-00-00 00:00:00' &&
              ins.task_closed_date != '0000-00-00 00:00:00' &&
              ins.task_stage == 'Done'
            ) {
              if (getDateGreaterOrLess(ins.task_due_date, ins.task_closed_date)) {
                temp = temp + 1;
              }
            }
          }
        });
        lst.push(temp);
      });
    });
  return lst;
};

export const getUsecaseMilestoneNotCompleted = (data: any) => {
  // phases (1st) column
  const lst: any = [];
  data &&
    data.map((obj: any) => {
      obj?.usecase?.map((element: any) => {
        let temp = 0;
        element.task?.map((ins: any) => {
          if (ins.milestone_task == '1') {
            if (ins.task_stage != 'Done' || ins.task_stage != 'done') {
              temp = temp + 1;
            }
          }
        });
        element.clientTask?.map((ins: any) => {
          if (ins.milestone_task == '1') {
            if (ins.task_stage != 'Done' || ins.task_stage != 'done') {
              temp = temp + 1;
            }
          }
        });

        lst.push(temp);
      });
    });
  return lst;
};

export const getFiltered_phasesAndTaskData = (data: any) => {
  const allPhases = getAllPhases(data);
  const allTasks = getAllTasks(data);
  const tasksComletedOnTime = getTasksCompletedOnTime(data);
  const tasksCompletedLate = getTasksCompletedLate(data);
  const tasksNotDone = getTasksNotDone(data);
  const milestoneTask = getMilestoneTask(data);
  const milestoneTaskCompletedOnTime = getMilestoneTaskCompletedOnTime(data);
  const milestoneTaskCompletedLate = getMilestoneTaskCompletedLate(data);
  const milestoneTaskNotCompleted = getMilestoneTaskNotCompleted(data);
  const usecases = getAllUseCases(data);
  const usecaseTasks = getUsecaseTasks(data);
  const usecaseTasksCompletedOnTime = getUsecaseTasksCompletedOnTime(data);
  const usecaseTasksCompletedLate = getUsecaseTasksCompletedLate(data);
  const usecaseTasksNotCompleted = getUsecaseTasksNotCompleted(data);
  const usecaseMilestone = getUsecaseMilestone(data);
  const usecaseMilestoneCompletedOnTime = getUsecaseMilestoneCompletedOnTime(data);
  const usecaseMilestoneCompletedLate = getUsecaseMilestoneCompletedLate(data);
  const usecaseMilestoneNotCompleted = getUsecaseMilestoneNotCompleted(data);

  let final =
    data &&
    data &&
    data.map((obj: any, index: number) => {
      const temp = {
        ...obj,
        phases: allPhases[index],
        tasks: allTasks[index],
        tasksComletedOnTime: tasksComletedOnTime[index],
        tasksCompletedLate: tasksCompletedLate[index],
        tasksNotCompleted: tasksNotDone[index],
        milestoneTask: milestoneTask[index],
        milestoneTaskCompletedOnTime: milestoneTaskCompletedOnTime[index],
        milestoneTaskCompletedLate: milestoneTaskCompletedLate[index],
        milestoneTaskNotCompleted: milestoneTaskNotCompleted[index],
        usecases: usecases[index],
        usecaseTasks: usecaseTasks[index],
        usecaseTasksCompletedOnTime: usecaseTasksCompletedOnTime[index],
        usecaseTasksCompletedLate: usecaseTasksCompletedLate[index],
        usecaseTasksNotCompleted: usecaseTasksNotCompleted[index],
        usecaseMilestone: usecaseMilestone[index],
        usecaseMilestoneCompletedOnTime: usecaseMilestoneCompletedOnTime[index],
        usecaseMilestoneCompletedLate: usecaseMilestoneCompletedLate[index],
        usecaseMilestoneNotCompleted: usecaseMilestoneNotCompleted[index],
      };
      return temp;
    });
  if (final == undefined) {
    final =
      data &&
      data &&
      data.map((obj: any) => {
        const temp = {
          ...obj,
          allPhases: 0,
          allTasks: 0,
          tasksComletedOnTime: 0,
          tasksCompletedLate: 0,
          tasksNotDone: 0,
          milestoneTask: 0,
          milestoneTaskCompletedOnTime: 0,
          milestoneTaskCompletedLate: 0,
          milestoneTaskNotCompleted: 0,
          usecaseTasks: 0,
          usecaseTasksCompletedOnTime: 0,
          usecaseTasksCompletedLate: 0,
          usecaseTasksNotCompleted: 0,
          usecaseMilestone: 0,
          usecaseMilestoneCompletedOnTime: 0,
          usecaseMilestoneCompletedLate: 0,
          usecaseMilestoneNotCompleted: 0,
        };
        return temp;
      });
  }
  return final;
};
