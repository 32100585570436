import { ViewMode } from 'components/ResourceManagement/types/public-types';
import moment from 'moment';
import DateTimeFormatOptions = Intl.DateTimeFormatOptions;
import DateTimeFormat = Intl.DateTimeFormat;

type DateHelperScales = 'year' | 'month' | 'day' | 'hour' | 'minute' | 'second' | 'millisecond';

const intlDTCache: Record<string, DateTimeFormat> = {};
export const getCachedDateTimeFormat = (
  locString: string | string[],
  opts: DateTimeFormatOptions = {}
): DateTimeFormat => {
  const key = JSON.stringify([locString, opts]);
  let dtf = intlDTCache[key];
  if (!dtf) {
    dtf = new Intl.DateTimeFormat(locString, opts);
    intlDTCache[key] = dtf;
  }
  return dtf;
};

export const addToDate = (date: Date, quantity: number, scale: DateHelperScales) => {
  const targetDate = date;
  if (!targetDate) return new Date();

  const newDate = new Date(
    date.getFullYear() + (scale === 'year' ? quantity : 0),
    date.getMonth() + (scale === 'month' ? quantity : 0),
    date.getDate() + (scale === 'day' ? quantity : 0)
  );
  return newDate;
};

export const startOfDate = (date: Date, scale: DateHelperScales) => {
  const targetDate = date;
  if (!targetDate) return new Date();
  const scores = ['day', 'month', 'year'];

  const shouldReset = (_scale: DateHelperScales) => {
    const maxScore = scores.indexOf(scale);
    return scores.indexOf(_scale) <= maxScore;
  };
  const newDate = new Date(
    targetDate.getFullYear(),
    shouldReset('year') ? 0 : targetDate.getMonth(),
    shouldReset('month') ? 1 : targetDate.getDate(),
    shouldReset('day') ? 0 : targetDate.getHours()
  );
  return newDate;
};

export const ganttDateRangeX = (entities: any[], viewMode: ViewMode, preStepsCount: number) => {
  let newStartDate: Date = new Date();
  let newEndDate: Date = new Date();
  for (const entity of entities) {
    if (entity?.start && entity?.end) {
      if (entity.start < newStartDate) {
        newStartDate = entity.start;
      }
      if (entity.end > newEndDate) {
        newEndDate = entity.end;
      }
    }
  }
  const today = new Date();
  // const oneYearFromNow = today;
  // const exactToday = new Date(today.getFullYear(), today.getMonth() + 1, 1);
  // oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
  // const exactOneYearFromNow = new Date(
  //   oneYearFromNow.getFullYear(),
  //   oneYearFromNow.getMonth() + 1,
  //   oneYearFromNow.getDate()
  // );
  // const endOfNextYear
  // const monthDifference = moment(oneYearFromNow).diff(exactToday, 'months', true);
  // const dayDifference = moment(oneYearFromNow).diff(exactToday, 'days', true);
  // const weekDifference = diff('weeks');

  // console.log('ganttDateRangeX', { monthDifference, dayDifference, weekDifference });

  const preStepsMap = {
    start: {
      Month: -1 * preStepsCount,
      Day: -1 * preStepsCount,
      Week: -7 * preStepsCount,
    },
    end: {
      Month: preStepsCount * 11,
      Day: preStepsCount * 365,
      Week: preStepsCount * 365,
    },
  };
  switch (viewMode) {
    case ViewMode.Month:
      newStartDate = startOfDate(newStartDate, 'month');
      newStartDate = addToDate(newStartDate, preStepsMap.start[ViewMode.Month], 'month');
      newEndDate = startOfDate(today, 'month');
      newEndDate = addToDate(today, preStepsMap.end[ViewMode.Month], 'month');
      break;
    case ViewMode.Week:
      newStartDate = startOfDate(newStartDate, 'day');
      newStartDate = addToDate(getMonday(newStartDate), preStepsMap.start[ViewMode.Week], 'day');
      newEndDate = startOfDate(today, 'day');
      newEndDate = addToDate(today, preStepsMap.end[ViewMode.Week], 'day');
      break;
    case ViewMode.Day:
      newStartDate = startOfDate(newStartDate, 'day');
      newStartDate = addToDate(newStartDate, preStepsMap.start[ViewMode.Day], 'day');
      newEndDate = startOfDate(today, 'day');
      newEndDate = addToDate(today, preStepsMap.end[ViewMode.Day], 'day');
      break;
  }
  return [newStartDate, newEndDate];
};

export const seedDatesX = (startDate: Date, endDate: Date, viewMode: ViewMode) => {
  let currentDate: Date = new Date(startDate);
  const dates: Date[] = [currentDate];
  while (currentDate < endDate) {
    switch (viewMode) {
      case ViewMode.Month:
        currentDate = addToDate(currentDate, 1, 'month');
        break;
      case ViewMode.Week:
        currentDate = addToDate(currentDate, 7, 'day');
        break;
      case ViewMode.Day:
        currentDate = addToDate(currentDate, 1, 'day');
        break;
    }
    dates.push(currentDate);
  }
  return dates;
};

const getMonday = (date: Date) => {
  const day = date.getDay();
  const diff = date.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
  return new Date(date.setDate(diff));
};

function getRange(startDate: string, endDate: string, type: moment.unitOfTime.Diff) {
  const fromDate = moment(startDate);
  const toDate = moment(endDate);
  const diff = toDate.diff(fromDate, type);
  const range = [];
  for (let i = 0; i <= diff; i++) {
    range.push(moment(startDate).add(i, type).toDate());
  }
  return range;
}

export const seedDates = (startDate: string, endDate: string, viewMode: ViewMode) => {
  let dates: Date[] = [];
  switch (viewMode) {
    case ViewMode.Month:
      dates = getRange(startDate, endDate, 'month');
      break;
    case ViewMode.Week:
      dates = getRange(startDate, endDate, 'weeks');
      break;
    case ViewMode.Day:
      dates = getRange(startDate, endDate, 'days');
      break;
  }
  return dates;
};

export const getLocaleMonth = (date: Date, locale: string) => {
  let bottomValue = getCachedDateTimeFormat(locale, {
    month: 'short',
  }).format(date);
  bottomValue = bottomValue.replace(bottomValue[0], bottomValue[0].toLocaleUpperCase());
  return bottomValue;
};

export const getLocalDayOfWeek = (date: Date, locale: string, format?: 'long' | 'short' | 'narrow' | undefined) => {
  let bottomValue = getCachedDateTimeFormat(locale, {
    weekday: format,
  }).format(date);
  bottomValue = bottomValue.replace(bottomValue[0], bottomValue[0].toLocaleUpperCase());
  return bottomValue;
};

export const getWeekNumberISO8601 = (date: Date) => {
  const tmpDate = new Date(date.valueOf());
  const dayNumber = (tmpDate.getDay() + 6) % 7;
  tmpDate.setDate(tmpDate.getDate() - dayNumber + 3);
  const firstThursday = tmpDate.valueOf();
  tmpDate.setMonth(0, 1);
  if (tmpDate.getDay() !== 4) {
    tmpDate.setMonth(0, 1 + ((4 - tmpDate.getDay() + 7) % 7));
  }
  const weekNumber = (1 + Math.ceil((firstThursday - tmpDate.valueOf()) / 604800000)).toString();

  if (weekNumber.length === 1) {
    return `0${weekNumber}`;
  } else {
    return weekNumber;
  }
};

export const getDaysInMonth = (month: number, year: number) => {
  return new Date(year, month + 1, 0).getDate();
};
