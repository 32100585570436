export const PIPEDRIVE_NUMBER_TYPES = ['int', 'double', 'monetary'];
export const PIPEDRIVE_STRING_TYPES = ['varchar', 'varchar_auto', 'text', 'user', 'org', 'people', 'phone', 'address', 'set'];
export const PIPEDRIVE_DROPDOWN_TYPES = ['enum'];
export const PIEPDRIVE_DATE_TYPES = ['time', 'timerange', 'date', 'daterange'];

export const CF_TYPE_DATE = 'Date';
export const CF_TYPE_NUMBER = 'Number';
export const CF_TYPE_DROPDOWN = 'Dropdown';
export const CF_TYPE_RAG = 'RAG status';
export const CF_TYPE_STRING = 'String';

export const DEFAULT_NOT_MAPPED_OPTION = { id: '0000', name: 'None' };
