export const getMaxColumnWidth = (values: any) => {
  let maxLength = 12;
  values.forEach((ele: any) => {
    maxLength = Math.max(maxLength, ele?.length);
  });
  if (maxLength <= 20) {
    maxLength = 12;
  } else if (maxLength >= 30) {
    maxLength = 30 - 8;
  } else {
    maxLength = maxLength - 8;
  }
  return maxLength * 14 * 0.9 + 100;
};
