import { label_project, label_use_case } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { doAverage, doTotal, doCount } from './aggregatableOptionsUtil';
import { EntityOption } from './types';

export const allEntityOptions: EntityOption[] = [
  {
    id: '1',
    label: `${label_project} Name`,
    dataKey: 'project_name',
    aggregableOptions: [doCount],
  },
  {
    id: '2',
    label: `Overdue ${label_use_case}`,
    dataKey: 'overdue_usecase',
    aggregableOptions: [doTotal, doAverage],
  },
  {
    id: '3',
    label: `At Risk ${label_use_case}`,
    dataKey: 'amber_usecase',
    aggregableOptions: [doTotal, doAverage],
  },
  {
    id: '4',
    label: `On Track ${label_use_case}`,
    dataKey: 'green_usecase',
    aggregableOptions: [doTotal, doAverage],
  },
  {
    id: '5',
    label: `Completed ${label_use_case}`,
    dataKey: 'blue_usecase',
    aggregableOptions: [doTotal, doAverage],
  },
  {
    id: '6',
    label: `Total ${label_use_case}s`,
    dataKey: 'total_usecase',
    aggregableOptions: [doTotal, doAverage],
  },
  {
    id: '7',
    label: 'Implementation fee billed',
    dataKey: 'implementation_fee_billed',
    aggregableOptions: [doTotal, doAverage],
  },

  {
    id: '8',
    label: 'Implementation fee pending',
    dataKey: 'implementation_fee_pending',
    aggregableOptions: [doTotal, doAverage],
  },

  {
    id: '9',
    label: `${label_project} age`,
    dataKey: 'project_age',
    aggregableOptions: [doTotal, doAverage],
  },
  {
    id: '10',
    label: `${label_project} Expansion Value`,
    dataKey: 'project_expansionvalue',
    aggregableOptions: [doTotal, doAverage],
  },
  {
    id: '11',
    label: `${label_project} implementation fee`,
    dataKey: 'project_fees',
    aggregableOptions: [doTotal, doAverage],
  },

  {
    id: '12',
    label: `${label_project} progress`,
    dataKey: 'project_progress',
    aggregableOptions: [doTotal, doAverage],
  },
  { id: '13', label: `${label_project} ARR`, dataKey: 'project_ARR', aggregableOptions: [doTotal, doAverage] },
];
