import { apis } from '../axiosInstance';
import { softAllocationRoutes } from '../route';

const getSoftAllocationByUserUid = (user_uid: string) => {
  return apis.phpAxiosInstance.get(softAllocationRoutes.getSoftAllocationByUserUid(user_uid), {
    headers: { JWT: 'true' },
  });
};

const addSoftAllocation = (detail: any) => {
  return apis.phpAxiosInstance.post(softAllocationRoutes.addSoftAllocation(), detail, {
    headers: { JWT: 'true' },
  });
};

const updateSoftAllocation = (detail: any) => {
  return apis.phpAxiosInstance.post(softAllocationRoutes.updateSoftAllocation(), detail, {
    headers: { JWT: 'true' },
  });
};

const deleteSoftAllocation = (detail: any) => {
  return apis.phpAxiosInstance.post(softAllocationRoutes.deleteSoftAllocation(), detail, {
    headers: { JWT: 'true' },
  });
};

export const softAllocationAPI = {
  getSoftAllocationByUserUid,
  addSoftAllocation,
  updateSoftAllocation,
  deleteSoftAllocation,
};
