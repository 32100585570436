import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../index';

const selectClientStore = (state: RootState) => state.clientFeature;

export const selectClientDetail = createSelector(selectClientStore, (clientStore) => clientStore.clientDetail);

export const selectClientCustomFields = createSelector(
  selectClientStore,
  (clientStore) => clientStore.clientCustomFields
);

export const selectClientRenewal = createSelector(selectClientStore, (clientStore) => clientStore.clientRenewal);

export const selectClientContacts = createSelector(selectClientStore, (clientStore) => clientStore.clientContacts);
