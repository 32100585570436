import Comment from './Comment';
import CommentView from './CommentView';
import { Stack, Paper } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  getProjectClientTaskComments,
  getProjectTaskComments,
  getUsecaseClientTaskComments,
  getUsecaseTaskComments,
} from '../../../../store/modules/Task/slice';
import {
  selectProjectClientTaskComments,
  selectProjectTaskComments,
  selectUsecaseTaskComments,
  selectUsecaseClientTaskComments,
  selectProjectTaskCommentsStatus,
  selectAddProjectTaskCommentsStatus,
  selectUpdateProjectTaskCommentsStatus,
  selectProjectClientTaskCommentsStatus,
  selectUpdateUsecaseTaskCommentsStatus,
  selectUsecaseTaskCommentsStatus,
} from '../../../../store/modules/Task/selector';
import { useAppSelector } from '../../../../store/hooks';
import { requestState } from 'store/modules/types';
import CognisaasSpinner from 'components/shared/CognisaasSpinner/CognisaasSpinner';
import CognisaasToast from 'components/shared/CognisaasToast/CognisaasToast';

const CommentHome = (props: any) => {
  const dispatch = useDispatch();
  const projectTaskComments = useAppSelector(selectProjectTaskComments);
  const projectClientTaskComments = useAppSelector(selectProjectClientTaskComments);
  const usecaseTaskComments = useAppSelector(selectUsecaseTaskComments);
  const usecaseClientTaskComments = useAppSelector(selectUsecaseClientTaskComments);
  const projectTaskCommentsStatus = useAppSelector(selectProjectTaskCommentsStatus);
  const usecaseTaskCommentsStatus = useAppSelector(selectUsecaseTaskCommentsStatus);
  const addProjectTaskCommentsStatus = useAppSelector(selectAddProjectTaskCommentsStatus);
  const addProjectClientTaskCommentsSatus = useAppSelector(selectProjectClientTaskCommentsStatus);
  const updateUsecaseCommentStatus = useAppSelector(selectUpdateUsecaseTaskCommentsStatus);
  const updateProjectTaskCommentsStatus = useAppSelector(selectUpdateProjectTaskCommentsStatus);

  const [commentsForProjectTask, setCommentsForProjectTask] = useState<any[]>([]);
  const [commentsForProjectClientTask, setCommentsForProjectClientTask] = useState<any[]>([]);
  const [commentsForUsecaseTask, setCommentForUsecaseTask] = useState<any[]>([]);
  const [commentsForUsecaseClientTask, setCommentsForUsecaseClientTask] = useState<any[]>([]);
  const [alertDetails, setAlertDetails] = useState<{
    isVisible: boolean;
    type: 'success' | 'failure';
    message: string;
  }>({ isVisible: false, type: 'success', message: '' });
  useEffect(() => {
    if (props.taskDetail.project_uid && props.taskDetail.case_uid) {
      if (props.taskDetail.is_team_task == 1) {
        dispatch(getUsecaseTaskComments(props.taskDetail.task_uid));
      } else {
        dispatch(getUsecaseClientTaskComments(props.taskDetail.task_uid));
      }
    } else {
      if (props.taskDetail.is_team_task == 1) {
        dispatch(getProjectTaskComments(props.taskDetail.task_uid));
      } else {
        dispatch(getProjectClientTaskComments(props.taskDetail.task_uid));
      }
    }
  }, []);

  useEffect(() => {
    if (props.taskDetail.project_uid && props.taskDetail.case_uid) {
      if (props.taskDetail.is_team_task == 1) {
        if (props?.isCustomerPortal) {
          const taskComments = usecaseTaskComments.filter((element: any) => element.share_with_client == 1);
          setCommentForUsecaseTask(taskComments);
        } else {
          setCommentForUsecaseTask(usecaseTaskComments);
        }
      } else {
        if (props?.isCustomerPortal) {
          const taskComments = usecaseClientTaskComments.filter((element: any) => element.share_with_client == 1);
          setCommentsForUsecaseClientTask(taskComments);
        } else {
          setCommentsForUsecaseClientTask(usecaseClientTaskComments);
        }
      }
    } else {
      if (props.taskDetail.is_team_task == 1) {
        if (props?.isCustomerPortal) {
          const taskComments = projectTaskComments.filter((element: any) => element.share_with_client == 1);
          setCommentsForProjectTask(taskComments);
        } else {
          setCommentsForProjectTask(projectTaskComments);
        }
      } else {
        if (props?.isCustomerPortal) {
          const taskComments = projectClientTaskComments.filter((element: any) => element.share_with_client == 1);
          setCommentsForProjectClientTask(taskComments);
        } else {
          setCommentsForProjectClientTask(projectClientTaskComments);
        }
      }
    }
  }, [projectTaskComments, projectClientTaskComments, usecaseTaskComments, usecaseClientTaskComments]);
  const renderComments = () => {
    if (props.taskDetail.project_uid && props.taskDetail.case_uid) {
      if (props.taskDetail.is_team_task == 1) {
        return (
          <CommentView
            commentsData={commentsForUsecaseTask}
            taskDetail={props.taskDetail}
            isCustomerPortal={props?.isCustomerPortal}
            clientDetail={props.clientDetail}
            setAlertDetails={setAlertDetails}

          />
        );
      } else {
        return (
          <CommentView
            commentsData={commentsForUsecaseClientTask}
            taskDetail={props.taskDetail}
            isCustomerPortal={props?.isCustomerPortal}
            clientDetail={props.clientDetail}
            setAlertDetails={setAlertDetails}

          />
        );
      }
    } else {
      if (props.taskDetail.is_team_task == 1) {
        return (
          <CommentView
            commentsData={commentsForProjectTask}
            taskDetail={props.taskDetail}
            isCustomerPortal={props?.isCustomerPortal}
            clientDetail={props.clientDetail}
            setAlertDetails={setAlertDetails}

          />
        );
      } else {
        return (
          <CommentView
            commentsData={commentsForProjectClientTask}
            taskDetail={props.taskDetail}
            isCustomerPortal={props?.isCustomerPortal}
            clientDetail={props.clientDetail}
            setAlertDetails={setAlertDetails}
          />
        );
      }
    }
  };

  const loading =
    projectTaskCommentsStatus === requestState.loading ||
    addProjectTaskCommentsStatus === requestState.loading ||
    updateProjectTaskCommentsStatus === requestState.loading ||
    addProjectClientTaskCommentsSatus === requestState.loading ||
    updateUsecaseCommentStatus === requestState.loading ||
    usecaseTaskCommentsStatus === requestState.loading;
  return (
    <>
      <Stack style={{ marginTop: '0px', width: '100%', marginRight: '0px' }}>
        <Stack style={{ padding: '20px 20px 5px 20px' }}>
          <Comment
            taskDetail={props.taskDetail}
            clientDetail={props.clientDetail}
            isCustomerPortal={props?.isCustomerPortal}
            customerPortalClientUserName={props?.customerPortalClientUserName}
          />
        </Stack>
        {loading ? (
          <Stack style={{ width: '100%', height: '100%' }} direction="row" justifyContent="center" alignItems="center">
            <CognisaasSpinner size={50} />
          </Stack>
        ) : (
          <Paper elevation={0} style={{ width: '100%', overflow: 'hidden', marginBottom: '10px' }}>
            {renderComments()}
          </Paper>
        )}
      </Stack>
      <CognisaasToast
        fromPanel={true} message={alertDetails.message}
        type={alertDetails.type}
        open={alertDetails.isVisible}
        onClose={() => {
          setAlertDetails({ isVisible: false, type: alertDetails.type, message: '' });
        }}
      />
    </>
  );
};

export default CommentHome;
