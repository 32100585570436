import { Paper, Typography } from '@mui/material';
import CognisaasSwitchButton from 'components/shared/CognisaasSwitch/CognisaasSwitch';

interface Props {
  text: string;
  checked: boolean;
  handleChange: any;
  margin?: string;
}

const SwitchBadge = (props: Props) => {
  console.log('propssss', props);
  return (
    <Paper
      variant="outlined"
      sx={{
        width: '280px',
        height: '60px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0px 12px',
        margin: props.margin ? props.margin : '0px 12px 0px 0px',
      }}
    >
      <Typography variant={'subtitle1'}>{props.text}</Typography>
      <CognisaasSwitchButton checked={props.checked} handleChange={props.handleChange} />
    </Paper>
  );
};

export default SwitchBadge;
