import { Typography, Box, Grid, Stack } from '@mui/material';

import CircleIcon from '@mui/icons-material/Circle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';

import CognisaasCard from '../../../components/shared/CognisaasCard/CognisaasCard';
import ClientTimelineComponent from './ClientTimelineComponent';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  label_delivery_date,
  label_project,
  label_task,
} from 'components/Settings/Preferences/CustomLabelCookieHelper';
import ViewDate from 'components/shared/ViewDate/ViewDate';

const ClientTimeline = (props: any) => {
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  useEffect(() => {
    if (props && props.length > 0) setProjects(props.projectsWithList);
  }, [props]);

  useEffect(() => {
    console.log(projects.map((ele) => ele));
  }, [projects]);

  return props?.projectsWithList.map((ele: any, i: any) => {
    return (
      <div key={i} style={{ marginTop: '15px' }}>
        <CognisaasCard height={'261px'} width={'100%'} clientStatusColor={ele?.project_readinessstate_status}>
          <Box
            sx={{ padding: '20px' }}
            className="cognisaas-pointer"
            onClick={() => navigate('/customer-portal/project/dashboard', { state: { selectedProject: ele } })}
          >
            <Grid container xs={12} justifyContent="space-between">
              <Grid my={2} item>
                <Typography>{ele.project_name} </Typography>
                {ele?.project_readinessstate_status == 'blue' ? (
                  <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#7A7A7A' }}>
                    This {label_project.toLowerCase()} has been delivered
                  </Typography>
                ) : null}
                {ele?.project_readinessstate_status == 'green' ? (
                  <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#7A7A7A' }}>
                    Delivery of this {label_project.toLowerCase()} is on track
                  </Typography>
                ) : null}
                {ele?.project_readinessstate_status == 'red' || ele?.project_readinessstate_status == 'amber' ? (
                  <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#7A7A7A' }}>
                    Delivery of this {label_project.toLowerCase()} is at risk
                  </Typography>
                ) : null}
              </Grid>
              <Grid item>
                <Grid item>
                  <Stack direction="row">
                    <CircleIcon fontSize="small" sx={{ color: '#3D38BA' }} />
                    <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#7A7A7A' }}>Completed</Typography>
                    <CircleOutlinedIcon fontSize="small" sx={{ color: '#3D38BA', marginLeft: '24px' }} />
                    <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#7A7A7A' }}>Pending</Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
            <Stack direction="row">
              <Box>
                <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#7A7A7A' }}>Start Date</Typography>
                <ViewDate date={ele?.project_startdate} />
              </Box>
              <Box sx={{ marginLeft: '125px' }}>
                <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#7A7A7A' }}>Due Date</Typography>
                <ViewDate date={ele?.project_requiredbydate} />
              </Box>
              <Box sx={{ marginLeft: '125px' }}>
                <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#7A7A7A' }}>
                  Expected {label_delivery_date}
                </Typography>
                <ViewDate date={ele?.eta_forprojectdelivery} />
              </Box>
            </Stack>
            <Typography sx={{ marginTop: '21px' }}>Milestone {label_task} Timeline</Typography>
            <ClientTimelineComponent tasks={ele.tasks} />
          </Box>
        </CognisaasCard>
      </div>
    );
  });
};

export default ClientTimeline;
