import { Stack, Typography, TextField } from '@mui/material';
import { useState, useEffect } from 'react';
import { ReactComponent as EditSVG } from '../../../../../assets/svgs/TableEditButton.svg';
import { selectCustomerDetail } from 'store/modules/Common/selector';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { getCustomerDetail } from 'store/modules/Common/slice';
import { updateMagicLinkDuration } from 'store/modules/Settings/slice';
import { getCustomerUid } from 'utils/getUid';
import CognisaasToast from 'components/shared/CognisaasToast/CognisaasToast';
import CognisaasTooltip from 'components/shared/CognisaasTooltip/CognisaasTooltip';
import InfoIcon from '../../../../shared/Icons/InfoIcon';

const ClientContactMagicLinkConfig = () => {
  const dispatch = useAppDispatch();
  const customerDetail = useAppSelector(selectCustomerDetail);
  const [duration, setDuration] = useState<number>(0);
  const [updateStatus, setUpdateStatus] = useState<boolean>(false);

  useEffect(() => {
    if (!customerDetail) {
      dispatch(getCustomerDetail());
    } else {
      setDuration(customerDetail.magic_link_duration_in_days);
    }
  }, [customerDetail]);

  const updateDuration = async () => {
    const detail = {
      magic_link_duration_in_days: duration,
      customer_uid: getCustomerUid(),
    };
    const res = await dispatch(updateMagicLinkDuration(detail)).unwrap();
    console.log(res);

    if (res) {
      console.log('updated');
      dispatch(getCustomerDetail());
      setUpdateStatus(true);
    }
  };

  return (
    <>
      {updateStatus ? (
        <CognisaasToast
          open={updateStatus}
          onClose={() => setUpdateStatus(false)}
          message={'Updated successfully! '}
          type="success"
        />
      ) : null}
      {customerDetail && (
        <Stack>
          <Stack direction="row">
            <Typography variant="h6" sx={{ fontWeight: '500', margin: '8px 0' }}>
              Magic link configuration
            </Typography>
            <CognisaasTooltip
              title={
                <Typography style={{ padding: '4px' }} variant={'subtitle1'}>
                  Configure the expiration of magic link sent to contacts to access their portal
                </Typography>
              }
            >
              <Stack sx={{ marginLeft: '8px', marginTop: '12px' }}>
                <InfoIcon></InfoIcon>
              </Stack>
            </CognisaasTooltip>
          </Stack>

          <div style={{ padding: '0 4px', display: 'flex' }}>
            <TextField
              value={duration}
              onChange={(e: any) => {
                setDuration(e.target.value as number);
              }}
              style={{ width: '220px' }}
              id="outlined-basic"
              type="number"
              label="Days"
              size="small"
              variant="outlined"
            />
            {customerDetail?.magic_link_duration_in_days != duration && (
              <button
                style={{
                  border: '1px solid #4F9F52',
                  background: '#BDF2BF',
                  width: '40px',
                  borderRadius: '0px 5px 5px 0px',
                  height: '40px',
                  marginLeft: '-1px',
                  cursor: 'pointer',
                }}
                onClick={() => updateDuration()}
              >
                <EditSVG />
              </button>
            )}
          </div>
        </Stack>
      )}
    </>
  );
};

export default ClientContactMagicLinkConfig;
