import UsecaseDetailCard from './UsecaseDetailCard';
import { Grid, Paper, Stack, Box } from '@mui/material';
import ImportantTasks from './ImportantTasks';
import { useEffect, useState } from 'react';
import { selectProjectInformation } from '../../../../../../store/modules/Project/selector';
import { getProjectInformation } from '../../../../../../store/modules/Project/slice';
import { useAppSelector } from '../../../../../../store/hooks';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getTasksWithTheirUsecase } from '../../../../../TaskModule/taskUtils';
import TaskCompletionDaysCard from '../../../../../shared/CognisaasCard/TaskCompletionCard/TaskCompletionDaysCard/TaskCompletionDaysCard';
// import TaskCompletionCard from '../../../../../shared/CognisaasCard/TaskCompletionCard/TaskCompletionCard';
import DeliverablesTimeline from '../../../../../ClientModule/DeliverablesTimeline/DeliverablesTimieline';
import OnGoingProductRequirements from './ProductRequirements';
import { sendOnlyMileStoneTasks } from '../../../../../TaskModule/taskUtils';
import RecentActivity from '../../../../../shared/RecentActivity/RecentActivity';
import { getUsecaseAudit } from '../../../../../../store/modules/Usecase/slice';
import { selectUsecaseAudit } from '../../../../../../store/modules/Usecase/selector';
import UsecaseTaskCompletionCard from 'components/shared/CognisaasCard/TaskCompletionCard/UsecaseTaskCompletionCard';
import { label_use_case } from 'components/Settings/Preferences/CustomLabelCookieHelper';

const UsecaseOverview = (props: any) => {
  // console.log('jehfjehfefkjefOVERVIEWWWWWWWWWWWWWWWWWWWWWWWWWWWWWW');
  const { projectId, associationId, caseId } = useParams();

  const selectedProjectInfo = useAppSelector(selectProjectInformation);
  const usecaseAudit = useAppSelector(selectUsecaseAudit);

  const [projectInfo, setProjectInfo] = useState<any>(null);
  const [usecaseTasks, setUsecaseTasks] = useState<any[]>([]);
  const [selectedUsecaseInfo, setSelectedUsecaseInfo] = useState<any>({});

  const dispatch = useDispatch();
  console.log(projectInfo);

  console.log(projectId, associationId, caseId);

  useEffect(() => {
    const detail = {
      association_uid: associationId,
      project_uid: projectId,
    };
    dispatch(getProjectInformation(detail));
    dispatch(getUsecaseAudit(caseId as string));
  }, []);

  useEffect(() => {
    if (selectedProjectInfo) {
      setProjectInfo({ ...selectedProjectInfo });
      getTasksUnderUsecase(selectedProjectInfo);
      const uc = selectedProjectInfo.usecase.filter((uc: any) => uc.case_uid == caseId);
      console.log(uc);

      setSelectedUsecaseInfo({ ...uc[0] });
      console.log('infooo', selectedProjectInfo);
    } else {
      const detail = {
        association_uid: associationId,
        project_uid: projectId,
      };
      dispatch(getProjectInformation(detail));
    }
  }, [selectedProjectInfo]);

  console.log('caseeeeeeeeeee', selectedUsecaseInfo);

  const getTasksUnderUsecase = (projectInfo: any) => {
    const filteredUsecase = projectInfo.usecase.filter((uc: any) => uc.case_uid == caseId);
    const usecasesWithTask = getTasksWithTheirUsecase(filteredUsecase);
    console.log(usecasesWithTask);
    let tasks: any = [];
    for (const i in usecasesWithTask) {
      tasks = [...tasks, ...usecasesWithTask[i]];
    }
    setUsecaseTasks([...tasks]);
    console.log(tasks, selectedUsecaseInfo);
  };
  return (
    <>
      <Grid mt={2} item xs={3}>
        {Object.entries(selectedUsecaseInfo).length > 0 && (
          <UsecaseDetailCard
            clientDetail={props.clientDetail}
            usecase={selectedUsecaseInfo}
            usecaseInfo={props.selectedUsecaseInfo}
            tasks={usecaseTasks}
          />
        )}
      </Grid>
      <Grid item xs={6}>
        <Stack>
          <Stack spacing={2} direction="row">
            <Grid item xs={6}>
              {Object.entries(selectedUsecaseInfo).length > 0 && (
                <UsecaseTaskCompletionCard
                  totalTasks={usecaseTasks}
                  usecase_progress={selectedUsecaseInfo.project_progress}
                />
              )}
            </Grid>
            <Grid item xs={6}>
              {Object.entries(selectedUsecaseInfo).length > 0 && (
                <TaskCompletionDaysCard
                  status={selectedUsecaseInfo?.case_readinessstate_status}
                  dueDate={selectedUsecaseInfo?.case_requiredbydate}
                  overdueDays={selectedUsecaseInfo?.case_days_overdue}
                  remainingDays={selectedUsecaseInfo?.case_days_remaining}
                  eta={selectedUsecaseInfo?.eta_forcasedelivery}
                />
              )}
            </Grid>
          </Stack>
          <Grid container spacing={2} mt={0.4}>
            <Grid item xs={12}>
              <Box
                sx={{
                  width: '100%',
                  height: '332px',
                  overflowY: 'scroll',
                  boxShadow: 'none',
                  backgroundColor: '#fff',
                }}
              >
                <ImportantTasks clientDetail={props.clientDetail} tasks={usecaseTasks} />
              </Box>
            </Grid>
            <Grid item xs={12}>
              {Object.entries(selectedUsecaseInfo).length > 0 && (
                <OnGoingProductRequirements
                  requirements={selectedUsecaseInfo.feature.length !== 0 ? selectedUsecaseInfo.feature : []}
                />
              )}
            </Grid>
          </Grid>
        </Stack>
      </Grid>
      <Grid item xs={3}>
        <Stack>
          <Grid item md={12}>
            <Paper
              sx={{
                width: '100%',
                height: '384px',
                // marginLeft: '16px',
                marginTop: '16px',
                padding: '10px 20px',
                overflowY: 'scroll',
                boxShadow: 'none',
              }}
            >
              <DeliverablesTimeline tasks={sendOnlyMileStoneTasks([...usecaseTasks])} />
            </Paper>
          </Grid>
          <Grid item md={12} mt={1}>
            <Paper
              sx={{
                width: '100%',
                height: '284px',
                // marginLeft: '16px',
                marginRight: '0px',
                marginTop: '8px',
                padding: '10px 0 10px 15px',
                overflowY: 'scroll',
                boxShadow: 'none',
              }}
            >
              <RecentActivity audit={usecaseAudit} type={label_use_case} />
            </Paper>
          </Grid>
        </Stack>
      </Grid>
    </>
  );
};

export default UsecaseOverview;
