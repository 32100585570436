import { useEffect, useState } from 'react';
import ProductRequirement from '../ProductRequirement/ProductRequirement';
import AddNewProduct from './AddNewProduct/AddNewProduct';
import AddNewRequirement from './AddNewRequirement/AddNewRequirement';

const Index = () => {
  const [prodReqView, setProdReqView] = useState<'landing-page' | 'add-new-product' | 'add-new-requirement' | ''>('');
  const [productUid, setProductUid] = useState();
  const [productDetails, setProductDetails] = useState<Record<string, any>>({});
  const setThisProductUid = (data: any) => {
    setProductUid(data.product_uid);
    setProductDetails(data);
    // console.log(data);
  };

  useEffect(() => {
    console.log('initial mounting');
    setProdReqView('landing-page');
  }, []);

  return (
    <>
      {prodReqView === 'landing-page' ? (
        <ProductRequirement setProductUidDetails={(data) => setThisProductUid(data)} setProdReqView={setProdReqView} />
      ) : prodReqView === 'add-new-product' ? (
        <AddNewProduct setProdReqView={setProdReqView} />
      ) : prodReqView === 'add-new-requirement' ? (
        <AddNewRequirement
          currentProductUid={productUid}
          currentProductDetails={productDetails}
          setProdReqView={setProdReqView}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default Index;
