import { Stack, Typography, Grid, Switch, TextField, Box, IconButton, Divider } from '@mui/material';
import { ReactComponent as GoBack } from '../../../../assets/svgs/go_back_arrow.svg';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    getCustomerIntegrationDetails,
    getCustomerIntegrationMicroService,
    updateCustomerPipedriveIntegrationsDetails,
} from 'store/modules/Settings/slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
    selectCustomerDetailsIntegration,
    selectCustomerDetailsIntegrationMicroService,
} from 'store/modules/Settings/selector';
import { Formik } from 'formik';
import './pipedrive.scss';
import CognisaaasTooltip from 'components/shared/CognisaasTooltip/CognisaasTooltip';
import { ReactComponent as Info } from '../../../../assets/svgs/info.svg';
import CognisaasButton from 'components/shared/CognisaasButton/CognisaasButton';
import { pipedriveFormSchema } from 'utils/validations';
import { getCustomerUid } from 'utils/getUid';
import CognisaasToast from 'components/shared/CognisaasToast/CognisaasToast';
import PipedriveCustomFieldMapping from './pipedrive/PipedriveCustomFieldMapping';
import PipeDriveStatusOrStageSelection from './pipedrive/PipeDriveStatusOrStageSelection';
import { requestState } from 'store/modules/types';
import CognisaasLoader from 'components/shared/CognisaasLoader/CognisaasLoader';
const Pipedrive = () => {
    const dispatch = useAppDispatch();
    const [pipeDriveOn, setpipeDriveOn] = useState<boolean>(false);
    const customerIntegrationDetails = useAppSelector(selectCustomerDetailsIntegration);
    const customerIntegrationDetailsMicroService = useAppSelector(selectCustomerDetailsIntegrationMicroService);
    const navigate = useNavigate();
    const [initialFormValues, setInitialFormValues] = useState<any>({
        api_token: '',
        crm_domain: '',
    });
    const [alertDetails, setAlertDetails] = useState<{
        isVisible: boolean;
        type: 'success' | 'failure';
        message: string;
    }>({ isVisible: false, type: 'success', message: '' });

    useEffect(() => {
        dispatch(getCustomerIntegrationDetails());
        dispatch(getCustomerIntegrationMicroService());
    }, []);
    useEffect(() => {
        if (customerIntegrationDetails?.data?.result != null) {
            if (customerIntegrationDetailsMicroService?.data?.data != null) {
                setpipeDriveOn(true);
                setInitialFormValues({
                    api_token: JSON.parse(customerIntegrationDetailsMicroService.data.data).api_token,
                    crm_domain: JSON.parse(customerIntegrationDetailsMicroService.data.data).crm_domain,
                });
            }
        } else {
            setpipeDriveOn(false);
        }
    }, [customerIntegrationDetails, customerIntegrationDetailsMicroService]);

    const validationForTheFormValues = (values: any) => {
        const details: any = {
            customer_uid: getCustomerUid(),
            api_token: values.api_token,
            crm_domain: values.crm_domain,
            is_pipedrive_on: pipeDriveOn,
        };
        dispatch(updateCustomerPipedriveIntegrationsDetails(details))
            .unwrap()
            .then(async (result: any) => {
                await dispatch(getCustomerIntegrationMicroService());
                await dispatch(getCustomerIntegrationDetails());
                if (result.status == 1) {
                    setAlertDetails({
                        isVisible: true,
                        type: 'success',
                        message: result.message,
                    });
                } else if (result.status == 0) {
                    setInitialFormValues(
                        {
                            api_token: '',
                            crm_domain: '',
                        });
                    setAlertDetails({
                        isVisible: true,
                        type: 'failure',
                        message: result.message,
                    });
                }
            });
    };
    const turnOffPipeDrive = () => {
        const details: any = {
            customer_uid: getCustomerUid(),
            is_pipedrive_on: false,
        };
        dispatch(updateCustomerPipedriveIntegrationsDetails(details))
            .unwrap()
            .then(async (result: any) => {
                console.log(result);
                await dispatch(getCustomerIntegrationMicroService());
                await dispatch(getCustomerIntegrationDetails());
                if (result.status == 1) {
                    setAlertDetails({
                        isVisible: true,
                        type: 'success',
                        message: result.message,
                    });
                    setInitialFormValues({
                        api_token: '',
                        crm_domain: '',
                    });
                } else if (result.status == 0) {
                    setInitialFormValues(
                        {
                            api_token: '',
                            crm_domain: '',
                        });
                    setAlertDetails({
                        isVisible: true,
                        type: 'failure',
                        message: 'Please try again later',
                    });
                }
            });
    };
    return (
        <>
            <CognisaasToast
                message={alertDetails.message}
                type={alertDetails.type}
                open={alertDetails.isVisible}
                onClose={() => {
                    setAlertDetails({ isVisible: false, type: alertDetails.type, message: '' });
                }}
            />
            {
                customerIntegrationDetails.status == requestState.loading || customerIntegrationDetailsMicroService.status == requestState.loading ? (
                    <Box component={'span'} sx={{ height: '50%' }}>
                        <CognisaasLoader />
                    </Box>
                ) : (
                    <>
                        <Grid sx={{ padding: '20px 20px' }}>
                            <Grid>
                                <Typography variant={'subtitle2'} className={'label-text'} sx={{ marginLeft: '15px' }}>
                                    CRM
                                </Typography>
                                <Grid>
                                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                        <GoBack
                                            onClick={() => {
                                                navigate('/settings/integrations');
                                            }}
                                            style={{ cursor: 'pointer' }}
                                        />
                                        <Typography variant={'h2'}>Pipedrive Integration</Typography>
                                    </Stack>
                                </Grid>
                            </Grid>
                            <Grid sx={{ marginTop: '24px' }}>
                                <Typography variant={'subtitle1'}>Pipedrive</Typography>
                                <Switch
                                    sx={{ marginLeft: '-10px' }}
                                    value={pipeDriveOn}
                                    checked={pipeDriveOn}
                                    onChange={() => {
                                        console.log('triggered');
                                        if (pipeDriveOn) {
                                            turnOffPipeDrive();
                                        }
                                        setpipeDriveOn((prev) => !prev);
                                    }}
                                />
                            </Grid>
                            <Formik
                                initialValues={initialFormValues}
                                validationSchema={pipedriveFormSchema}
                                onSubmit={(value) => {
                                    validationForTheFormValues(value);
                                }}
                                enableReinitialize={true}
                            >
                                {(props: any) => (
                                    <form>
                                        <div>
                                            <Typography variant="h2" style={{ marginBottom: '10px' }}>
                                                PipeDrive Configuration
                                            </Typography>
                                            <Stack mt={2} direction="row">
                                                <Stack>
                                                    <Grid container spacing={0} alignItems="center">
                                                        <Typography variant="subtitle2">Pipedrive API token</Typography>
                                                        <CognisaaasTooltip
                                                            title={
                                                                <div style={{ paddingRight: '12px' }}>
                                                                    <p>
                                                                        <ol>
                                                                            <li>Log into your Pipedrive account.</li>
                                                                            <li>Click on your account name on the top right</li>
                                                                            <li>
                                                                                Click on <b>Company Settings</b>
                                                                            </li>
                                                                            <li>
                                                                                Click on <b>Personal Preferences</b>
                                                                            </li>
                                                                            <li>
                                                                                Click on <b>API</b>
                                                                            </li>
                                                                            <li>Copy your API token and paste below</li>
                                                                        </ol>
                                                                    </p>
                                                                </div>
                                                            }
                                                        >
                                                            <IconButton disableRipple size="small">
                                                                <Info />
                                                            </IconButton>
                                                        </CognisaaasTooltip>
                                                    </Grid>
                                                    <TextField
                                                        type={'password'}
                                                        id="api_token"
                                                        name="api_token"
                                                        size="small"
                                                        disabled={!pipeDriveOn}
                                                        placeholder="Pipedrive API Key"
                                                        value={props.values.api_token}
                                                        onChange={props.handleChange}
                                                        onBlur={props.handleBlur}
                                                        error={props.touched.api_token && Boolean(props.errors.api_token)}
                                                        helperText={props.touched.api_token && props.errors.api_token}
                                                    ></TextField>
                                                </Stack>
                                            </Stack>
                                            <Stack mt={2} direction="row">
                                                <Stack>
                                                    <Grid container spacing={0} alignItems="center">
                                                        <Typography variant="subtitle2">Sub Domain</Typography>
                                                        <CognisaaasTooltip
                                                            title={
                                                                <Box sx={{ padding: '8px' }}>
                                                                    <Typography variant="subtitle2">
                                                                        For example, if the URL that you see while logged into your account is
                                                                        &quot;acme.pipedrive.com&quot;, enter &quot;acme&quot;.
                                                                    </Typography>
                                                                </Box>
                                                            }
                                                        >
                                                            <IconButton disableRipple size="small">
                                                                <Info />
                                                            </IconButton>
                                                        </CognisaaasTooltip>
                                                    </Grid>
                                                    <Grid
                                                        container
                                                        spacing={0}
                                                        sx={{ alignItems: 'stretch', border: '1px solid rgb(220, 226, 234)', borderRadius: '4px' }}
                                                    >
                                                        <Grid item>
                                                            <div title="https://" className="divText">
                                                                <span className="divUnderText divDirection">
                                                                    <bdi>https://</bdi>
                                                                </span>
                                                            </div>
                                                        </Grid>
                                                        <Grid item>
                                                            <TextField
                                                                id="crm_domain"
                                                                name="crm_domain"
                                                                size="small"
                                                                placeholder="Enter value"
                                                                value={props.values.crm_domain}
                                                                onChange={props.handleChange}
                                                                onBlur={props.handleBlur}
                                                                disabled={!pipeDriveOn}
                                                                InputProps={{
                                                                    style: { width: '170px' },
                                                                    sx: {
                                                                        '.css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
                                                                            border: 'none',
                                                                        },
                                                                        '&:focus-within': {
                                                                            '.css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
                                                                                border: '1.5px solid blue',
                                                                            },
                                                                        },
                                                                    },
                                                                }}
                                                                error={props.touched.crm_domain && Boolean(props.errors.crm_domain)}
                                                                helperText={props.touched.crm_domain && props.errors.crm_domain}
                                                            ></TextField>
                                                        </Grid>
                                                        <Grid item>
                                                            <div title=".pipedrive.com" className="divText">
                                                                <span className="divUnderText">.pipedrive.com</span>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </Stack>
                                            </Stack>
                                            <Grid
                                                mt={2}
                                                container
                                                direction="row"
                                                alignItems="center"
                                            >
                                                <Grid sx={{ marginRight: '10%', paddingBotton: '16px', alignItems: 'end' }} item>
                                                    <Stack direction="row" spacing={2}>
                                                        <CognisaasButton
                                                            style={{ marginRight: '16px' }}
                                                            isOutlined={true}
                                                            label="Cancel"
                                                            clickHandler={() => {
                                                                props.handleReset();
                                                            }}
                                                        />
                                                        <CognisaasButton
                                                            isOutlined={false}
                                                            disabled={!props.isValid || !props.dirty}
                                                            clickHandler={() => {
                                                                props.handleSubmit();
                                                            }}
                                                            label="Save"
                                                        />
                                                    </Stack>
                                                    <Stack direction="row"></Stack>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </form>
                                )}
                            </Formik>
                        </Grid>
                        <Divider />
                        {initialFormValues.api_token != '' && <PipeDriveStatusOrStageSelection />}
                        <Divider />
                        {initialFormValues.api_token != '' && <PipedriveCustomFieldMapping />}

                    </>
                )
            }
        </>
    );
};

export default Pipedrive;
