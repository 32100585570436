import _Modal from 'styled-react-modal';

export const Modal = _Modal.styled(({ theme }) => {
  return {
    height: 'calc(100% - 200px)',
    marginLeft: '5%',
    width: '90%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.grey_5,
  };
});
