import { Stack, Typography, Alert, TextField, IconButton } from '@mui/material';
import CognisaasSelect from 'components/shared/CognisaasSelect/CognisaasSelect';
import { availableConditions } from '../Config/Conditions';
import { useEffect, useState } from 'react';
import CognisaasButton from 'components/shared/CognisaasButton/CognisaasButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Chain from './Chain';
import CognisaasCheckBox from 'components/shared/CognisaasCheckbox/CognisaasCheckbox';
import CognisaasCalenderNew from 'components/shared/CognisaasCalenderNew/CognisaasCalenderNew';
import { format } from 'date-fns';
import { getDisplayByKey, getKeyByDisplayName } from '../UtilityFunction';
import useCustomNames from 'components/shared/CustomNames/useCustomNames';
// import { getKeyByDisplayName } from '../UtilityFunction';

const AddConditionModal = (props: any) => {
  const { getCustomNameMappedValue } = useCustomNames();
  console.log(props);
  const dropDownStandardFields = [
    'project_stage',
    'project_priority',
    'project_category',
    'case_stage',
    'case_priority',
    'case_category',
  ];

  // const dropDownList = [...availableConditions[props.type]] ? [...availableConditions[props.type]] : [];
  const [dropDownList, setDropDownList] = useState<any[]>(
    availableConditions[props.type.toLowerCase()] ? [...availableConditions[props.type.toLowerCase()]] : []
  );
  const [conditions, setConditions] = useState<any>([
    { type: '', value: '', chainType: null, customValue: false, dropdownFields: [] },
  ]);
  // const [isHovering, setIsHovering] = useState<boolean>(false);

  useEffect(() => {
    if (props.conditionsApplied.length > 0) {
      setConditions([...props.conditionsApplied]);
    }
  }, [props.conditionsApplied]);

  useEffect(() => {
    if (props.conditionsApplied.length == 0) {
      updateDropDownFields(conditions);
    }
  }, []);

  const addCondition = () => {
    // console.log('NEW CON');
    const oldConditions = [...conditions];
    oldConditions.push({ type: '', value: '', chainType: 'AND', customValue: false, dropdownFields: [] });
    setConditions([...oldConditions]);
    updateDropDownFields(oldConditions);
  };

  const updateDropDownFields = (c: any[]) => {
    addValuesForDropdowm(c, props.type);
    // for (let i = 0; i < c.length; i++) {
    //   console.log(c[i].customValue, i, props.type);
    //   addValuesForDropdowm(c[i].customValue, i, props.type);
    // }
  };

  const updateCondition = (value: string, indexNumber: number) => {
    const updatedConditions = conditions.map((condition: any, index: number) => {
      console.log(condition, index, indexNumber, setDropDownList);
      if (index == indexNumber) {
        condition.type = value;
        console.log(condition);
        return condition;
      } else {
        return condition;
      }
    });
    setConditions([...updatedConditions]);

    console.log(updatedConditions);
  };

  const updateValue = (value: string, indexNumber: number) => {
    const newConditions: any[] = [...conditions];
    const updatedConditions = newConditions.map((condition: any, index: number) => {
      console.log(condition, index, indexNumber, value, getCustomNameMappedValue(value, 'project_stage'));
      if (dropDownStandardFields.includes(props.field)) {
        value = getCustomNameMappedValue(value, props.field);
      }
      if (index == indexNumber) {
        if (
          props.type == 'RAG status' ||
          props.type.toLowerCase() == 'dropdown' ||
          props.type == 'rag' ||
          props.type == 'link' ||
          props.type == 'number' ||
          props.type == 'string'
        ) {
          condition = { ...condition, customValue: true };
          // condition.customValu3e = true;
        }
        console.log(condition);
        condition = {
          ...condition,
          value: condition.customValue ? value : getKeyByDisplayName(props.allColumns, value),
        };
        return condition;
      } else {
        return condition;
      }
    });
    setConditions([...updatedConditions]);

    console.log(updatedConditions);
  };

  const addValuesForDropdowm = (conds: any[], type: string) => {
    const updatedConditions = conds.map((condition: any) => {
      // console.log(condition, index, indexNumber);
      if (['number', 'date'].includes(type.toLocaleLowerCase())) {
        // condition.customValue = condition.value;
        // if (value) {
        // console.log(props.allColumns);
        const fields = [];
        for (const key of Object.keys(props.allColumns)) {
          // console.log(props.allColumns[key].type.toLowerCase(), type.toLocaleLowerCase());
          if (props.allColumns[key].type.toLowerCase() == type.toLowerCase()) {
            fields.push(props.allColumns[key].displayName);
          }
        }
        // console.log(fields);
        condition.dropdownFields = [...fields];
        // }
        // console.log(condition);
        return condition;
      } else {
        return condition;
      }
    });
    setConditions([...updatedConditions]);
  };

  const updateCustomValue = (value: boolean, indexNumber: number, type: string) => {
    const updatedConditions = conditions.map((condition: any, index: number) => {
      console.log(condition, index, indexNumber);
      if (index == indexNumber) {
        condition.customValue = value;
        if (value) {
          console.log(props.allColumns);
          const fields = [];
          for (const key of Object.keys(props.allColumns)) {
            console.log(props.allColumns[key].type.toLowerCase(), type.toLocaleLowerCase());
            if (props.allColumns[key].type.toLowerCase() == type.toLowerCase()) {
              fields.push(props.allColumns[key].displayName);
            }
          }
          console.log(fields);
          condition.dropdownFields = [...fields];
        }
        console.log(condition);
        return condition;
      } else {
        return condition;
      }
    });
    setConditions([...updatedConditions]);

    console.log(updatedConditions);
  };

  const removeCondition = (indexNumber: number) => {
    console.log(indexNumber);
    const newConditions = [];
    for (let i = 0; i < conditions.length; i++) {
      if (i != indexNumber) {
        newConditions.push(conditions[i]);
      }
    }
    console.log(newConditions);
    setConditions([...newConditions]);
    // const updatedConditions = conditions.map((condition: any, index: number) => {
    //   if (index != indexNumber) {
    //     return condition;
    //   }
    // });
    // console.log(updatedConditions);
    // setConditions([...updatedConditions]);
  };

  const chainWith = (chainType: any, index: number) => {
    console.log(chainType, index, conditions);
    const temp = [];
    for (let i = 0; i < conditions.length; i++) {
      if (i != index) {
        temp.push(conditions[i]);
      } else {
        const condition = { ...conditions[i], chainType: chainType };
        temp.push(condition);
      }
    }
    console.log(temp);
    setConditions([...temp]);
  };

  return (
    <>
      <Stack style={{ padding: '16px 8px' }}>
        {/* <Typography variant="subtitle1">Add Condition Modal {props.type}</Typography> */}
        <Alert sx={{ marginBottom: '12px' }} severity="info">
          Conditions available for field <strong>{getDisplayByKey(props.allColumns, props.field)}</strong> which is of
          type {props.type}
        </Alert>
        {props.type.toLowerCase() == 'number' && (
          <>
            {conditions.map((condition: any, index: number) => {
              return (
                <>
                  <Stack my={1} mr={4} key={index} direction="row">
                    {/* {index !== 0 && (
                  <Chain />
                  // <ButtonGroup disableElevation variant="contained" aria-label="Disabled elevation buttons">
                  //   <Button>One</Button>
                  //   <Button>Two</Button>
                  // </ButtonGroup>
                )} */}
                    {index !== 0 && (
                      <Stack mr={2}>
                        <Chain
                          chainWith={(type: any) => {
                            chainWith(type, index);
                          }}
                        />
                      </Stack>
                    )}
                    <CognisaasSelect
                      sx={{ marginRight: '16px' }}
                      validValues={[...dropDownList]}
                      value={condition.type}
                      onChange={(e: any) => updateCondition(e.target.value, index)}
                    />
                    <Stack>
                      <Stack ml={2} mb={1} direction={'row'}>
                        <CognisaasCheckBox
                          sx={{ top: '3px' }}
                          onClick={() => updateCustomValue(!condition.customValue, index, props.type)}
                          checked={condition.customValue}
                        />
                        <Typography sx={{ marginLeft: '4px' }} variant="subtitle1">
                          Enter a custom value
                        </Typography>
                      </Stack>
                      {condition.customValue ? (
                        <Stack ml={1}>
                          <TextField
                            type={'number'}
                            // width="200px"
                            name="projectDeliverydate"
                            value={condition.value}
                            onChange={
                              (e: any) => updateValue(e.target.value, index)
                              // props.setFieldValue('projectDeliverydate', format(new Date(e), 'yyyy-MM-dd HH:mm:ss'))
                            }
                          />
                        </Stack>
                      ) : (
                        <CognisaasSelect
                          sx={{ marginRight: '16px' }}
                          validValues={[...condition.dropdownFields]}
                          value={getDisplayByKey(props.allColumns, condition.value)}
                          onChange={(e: any) => updateValue(e.target.value, index)}
                        />
                      )}
                    </Stack>
                    {/* <TextField
                      size="small"
                      type={'number'}
                      onChange={(e: any) => updateValue(e.target.value, index)}
                      value={condition.value}
                    ></TextField> */}

                    {index !== 0 && (
                      <IconButton onClick={() => removeCondition(index)} aria-label="delete">
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Stack>
                </>
              );
            })}
          </>
        )}
        {props.type == 'rag' && (
          <>
            {conditions.map((condition: any, index: number) => {
              return (
                <>
                  <Stack my={1} mr={4} key={index} direction="row">
                    {/* {index !== 0 && (
                          <Chain />
                          // <ButtonGroup disableElevation variant="contained" aria-label="Disabled elevation buttons">
                          //   <Button>One</Button>
                          //   <Button>Two</Button>
                          // </ButtonGroup>
                        )} */}
                    {index !== 0 && (
                      <Stack mr={2}>
                        <Chain
                          chainWith={(type: any) => {
                            chainWith(type, index);
                          }}
                        />
                      </Stack>
                    )}
                    <CognisaasSelect
                      sx={{ marginRight: '16px' }}
                      validValues={[...dropDownList]}
                      value={condition.type}
                      onChange={(e: any) => updateCondition(e.target.value, index)}
                    />
                    <CognisaasSelect
                      sx={{ marginRight: '16px' }}
                      validValues={['red', 'amber', 'green', 'blue']}
                      value={condition.value}
                      onChange={(e: any) => updateValue(e.target.value, index)}
                    />
                    {/* <TextField
                      size="small"
                      type={'number'}
                      onChange={(e: any) => updateValue(e.target.value, index)}
                      value={condition.value}
                    ></TextField> */}

                    {index !== 0 && (
                      <IconButton onClick={() => removeCondition(index)} aria-label="delete">
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Stack>
                </>
              );
            })}
          </>
        )}
        {(props.type == 'RAG status' || props.type.toLowerCase() == 'dropdown') && (
          <>
            {conditions.map((condition: any, index: number) => {
              return (
                <>
                  <Stack my={1} mr={4} key={index} direction="row">
                    {index !== 0 && (
                      <Stack mr={2}>
                        <Chain
                          chainWith={(type: any) => {
                            chainWith(type, index);
                          }}
                        />
                      </Stack>
                    )}
                    <CognisaasSelect
                      sx={{ marginRight: '16px' }}
                      validValues={[...dropDownList]}
                      value={condition.type}
                      onChange={(e: any) => updateCondition(e.target.value, index)}
                    />
                    <CognisaasSelect
                      sx={{ marginRight: '16px' }}
                      validValues={[...props.validValues]}
                      value={condition.value}
                      onChange={(e: any) => updateValue(e.target.value, index)}
                    />
                    {/* <TextField
                      size="small"
                      type={'number'}
                      onChange={(e: any) => updateValue(e.target.value, index)}
                      value={condition.value}
                    ></TextField> */}

                    {index !== 0 && (
                      <IconButton onClick={() => removeCondition(index)} aria-label="delete">
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Stack>
                </>
              );
            })}
          </>
        )}
        {(props.type.toLowerCase() == 'link' ||
          props.type.toLowerCase() == 'string' ||
          props.type.toLowerCase() == 'username') && (
          <>
            {console.log(conditions, props.type)}
            {conditions.map((condition: any, index: number) => {
              return (
                <>
                  <Stack my={1} mr={4} key={index} direction="row">
                    {/* {index !== 0 && (
                  <Chain />
                  // <ButtonGroup disableElevation variant="contained" aria-label="Disabled elevation buttons">
                  //   <Button>One</Button>
                  //   <Button>Two</Button>
                  // </ButtonGroup>
                )} */}
                    {index !== 0 && (
                      <Stack mr={2}>
                        <Chain
                          chainWith={(type: any) => {
                            chainWith(type, index);
                          }}
                        />
                      </Stack>
                    )}
                    <CognisaasSelect
                      sx={{ marginRight: '16px' }}
                      validValues={[...dropDownList]}
                      value={condition.type}
                      onChange={(e: any) => updateCondition(e.target.value, index)}
                    />
                    <TextField
                      size="small"
                      // type={'number'}
                      onChange={(e: any) => updateValue(e.target.value, index)}
                      value={condition.value}
                    ></TextField>

                    {index !== 0 && (
                      <IconButton onClick={() => removeCondition(index)} aria-label="delete">
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Stack>
                </>
              );
            })}
          </>
        )}
        {props.type.toLowerCase() == 'date' && (
          <>
            {console.log(conditions, props.type)}
            {conditions.map((condition: any, index: number) => {
              return (
                <>
                  <Stack my={1} mr={4} key={index} direction="row">
                    {/* {index !== 0 && (
                          <Chain />
                          // <ButtonGroup disableElevation variant="contained" aria-label="Disabled elevation buttons">
                          //   <Button>One</Button>
                          //   <Button>Two</Button>
                          // </ButtonGroup>
                        )} */}
                    {index !== 0 && (
                      <Stack mr={2}>
                        <Chain
                          chainWith={(type: any) => {
                            chainWith(type, index);
                          }}
                        />
                      </Stack>
                    )}
                    <Stack sx={{ marginTop: '32px' }}>
                      <CognisaasSelect
                        sx={{ marginRight: '16px' }}
                        validValues={[...dropDownList]}
                        value={condition.type}
                        onChange={(e: any) => updateCondition(e.target.value, index)}
                      />
                    </Stack>

                    <Stack>
                      <Stack ml={2} mb={1} direction={'row'}>
                        <CognisaasCheckBox
                          sx={{ top: '3px' }}
                          onClick={() => updateCustomValue(!condition.customValue, index, props.type)}
                          checked={condition.customValue}
                        />
                        <Typography sx={{ marginLeft: '4px' }} variant="subtitle1">
                          Enter a custom value
                        </Typography>
                      </Stack>
                      {condition.customValue ? (
                        <Stack ml={1}>
                          <CognisaasCalenderNew
                            width="200px"
                            name="projectDeliverydate"
                            value={condition.value}
                            handleChange={
                              (e: any) => updateValue(format(new Date(e), 'yyyy-MM-dd HH:mm:ss'), index)
                              // props.setFieldValue('projectDeliverydate', format(new Date(e), 'yyyy-MM-dd HH:mm:ss'))
                            }
                          />
                        </Stack>
                      ) : (
                        <CognisaasSelect
                          sx={{ marginRight: '16px' }}
                          validValues={[...condition.dropdownFields]}
                          value={getDisplayByKey(props.allColumns, condition.value)}
                          onChange={(e: any) => updateValue(e.target.value, index)}
                        />
                      )}
                    </Stack>

                    {/* <TextField
                      size="small"
                      type={'number'}
                      onChange={(e: any) => updateValue(e.target.value, index)}
                      value={condition.value}
                    ></TextField> */}

                    {index !== 0 && (
                      <IconButton onClick={() => removeCondition(index)} aria-label="delete">
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Stack>
                </>
              );
            })}
          </>
        )}

        <Stack sx={{ marginLeft: '80%' }}>
          <CognisaasButton
            isOutlined={true}
            label={'Add Condition'}
            clickHandler={() => {
              return addCondition();
              // setConditions([
              //   ...conditions,
              //   { type: '', value: '', chainType: 'AND', customValue: false, dropdownFields: [] },
              // ]);
              // updateDropDownFields([...conditions]);
            }}
          />
        </Stack>
        <Stack my={2} sx={{ marginLeft: '79%' }} direction={'row'}>
          <CognisaasButton
            sx={{ marginRight: '8px' }}
            isOutlined={true}
            label={'Cancel'}
            clickHandler={() => props.onClose(null)}
          />
          <CognisaasButton isOutlined={false} label={'Apply'} clickHandler={() => props.onClose(conditions)} />
        </Stack>
      </Stack>
    </>
  );
};

export default AddConditionModal;
