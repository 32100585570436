import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../';
import { segmentModel, userDetailModel, journeyStageModel, industryModel } from '../../../../api/responseInterface';

export const selectFiltersDataState = (state: RootState) => state.filterState;

export const selectStageFilterData = createSelector(selectFiltersDataState, (filterState) => {
  const stages: journeyStageModel[] = filterState.stageFilters;
  const stageNames = [];
  for (let i = 0; i < stages.length; i++) {
    stageNames.push(stages[i].stage_journerystage);
  }
  return stageNames;
});

export const selectRawStageData = createSelector(selectFiltersDataState, (filterState) => {
  return filterState.stageFilters;
});

export const selectSegmentFilterData = createSelector(selectFiltersDataState, (filterState) => {
  const segments: segmentModel[] = filterState.segmentFilters;
  const segmentNames = [];
  for (let i = 0; i < segments.length; i++) {
    segmentNames.push(segments[i].segment_name);
  }
  return segmentNames;
});

export const selectIndustryFilterData = createSelector(selectFiltersDataState, (filterState) => {
  const industries: industryModel[] = filterState.industryFilters;
  const industryNames = [];
  for (let i = 0; i < industries.length; i++) {
    industryNames.push(industries[i].industry_name);
  }
  return industryNames;
});

export const selectCsOwnerFilterData = createSelector(selectFiltersDataState, (filterState) => {
  const usersData: userDetailModel[] = filterState.csOwnersFilters;
  const csOwnerNames = [];
  for (let i = 0; i < usersData.length; i++) {
    csOwnerNames.push(usersData[i].user_username);
  }
  return csOwnerNames;
});

export const selectSalesOwnerFilterData = createSelector(selectFiltersDataState, (filterState) => {
  const usersData: userDetailModel[] = filterState.salesOwnersFilters;
  const salesOwnerNames = [];
  for (let i = 0; i < usersData.length; i++) {
    salesOwnerNames.push(usersData[i].user_username);
  }
  return salesOwnerNames;
});

export const selectStatusFilterData = createSelector(
  selectFiltersDataState,
  (filterState) => filterState.statusFilters
);

export const selectCustomFieldsOfCustomer = createSelector(
  selectFiltersDataState,
  (filterState) => filterState.customFields
);

export const selectedFiltersInManageClientspage = createSelector(
  selectFiltersDataState,
  (filterState) => filterState.selectedFiltersInManageClientspage
);

export const selectColumnsForClientListPage = createSelector(
  selectFiltersDataState,
  (filterState) => filterState.columnsToShowInClientListPage
);

export const selectManageClientAppliedMainFilters = createSelector(
  selectFiltersDataState,
  (filterState) => filterState.selectedMainFilters
);
