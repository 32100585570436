import CognisaasProjectCard from '../../shared/CognisaasCard/CognisaasProjectCard/CognisaasProjectCard';
import { Grid } from '@mui/material';

const ProjectsCard = (props: any) => {
  // const projects = props.data;
  return (
    <>
      <Grid container rowSpacing={1} columnSpacing={1}  alignItems="center" >
        {props.data.map((project: any, index: number) => {
          return (
            <Grid key={index} item md={3} >
              <CognisaasProjectCard key={index} projectDetail={project} />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default ProjectsCard;
