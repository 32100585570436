import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { authAPI, commonAPI } from '../../../api';
import { requestState } from '../types';
import jwtDecode from 'jwt-decode';
import Cookies from 'universal-cookie';
import { Account, JWTToken } from '../../../store/types/index';
import { db } from 'store/application_store';
import { setProjectTaskdashboardView, setUsecaseTaskdashboardView } from 'utils/viewHelper';

const env: string = process.env.REACT_APP_ENV ?? 'local';

interface authType {
  isAuthed: boolean;
  status: requestState;
  error: null | undefined | string | object;
  doesEmailExist: boolean;
  signupEmailSent: boolean;
  isEmailValid: boolean;
  allowAccess: boolean;
  setUserDataObj: boolean;
  fullDetails: object;
  redirectToLogin: boolean;
  redirectToLoginOnGoogleSignup: boolean;
  redirectToCompleteAccount: boolean;
  signupOnGoogleUID: string;
  signupOnGoogleToken: string;
  signupOnGoogleUserName: string;
  successMessage: string;
  isPasswordUpdated: boolean;
  showSuccessScreenFeedback: boolean;
  passwordUpdated: {
    data: any;
    status: requestState;
    message: string;
  };
}
// {
//   "exp": 1657627287,
//   "iss": "cognisaas:iam",
//   "aud": [
//     "cognisaas_app"
//   ],
//   "user_id": "110905947600674037130",
//   "accounts": [
//     {
//       "account_id": "5ef9a05fa797e",
//       "user_type": "A",
//       "subdomain": "cognisaas"
//     }
//   ],
//   "pages": []
// }

export const redirectAfterAuth = (jwt: string) => {
  const decoded_token = jwtDecode<JWTToken>(jwt);
  let account = <Account>{};
  account = decoded_token.account as Account;
  if (env == 'local') {
    document.location.href = 'http://localhost:3000';
  } else if (env == 'dev') {
    document.location.href = `https://${account.subdomain}.devng.cognisaas.com`;
  } else if (env == 'prod') {
    document.location.href = `https://${account.subdomain}.ng.cognisaas.com`;
  } else if (env == 'new-prod') {
    document.location.href = `https://${account.subdomain}.cognisaas.net`;
  }
};

export const setAuthenticationCookies = (jwt: any) => {
  const decoded_token = jwtDecode<JWTToken>(jwt.access_token);
  const maxAge = 41400;
  let domain = 'localhost';
  if (env == 'dev') {
    domain = '.devng.cognisaas.com';
  } else if (env == 'prod') {
    domain = '.ng.cognisaas.com';
  } else if (env == 'new-prod') {
    domain = '.cognisaas.net';
  }

  setProjectTaskdashboardView('na');
  setUsecaseTaskdashboardView('na');

  cookies.set('jwt-token', jwt.access_token, { path: '/', domain: domain, maxAge: maxAge });
  cookies.set('refresh-token', jwt.refresh_token, { path: '/', domain: domain, maxAge: maxAge });
  cookies.set('access_token_expires_in', jwt.access_token_expires_in, { path: '/', domain: domain, maxAge: maxAge });
  cookies.set('refresh_token_expires_in', jwt.refresh_token_expires_in, { path: '/', domain: domain, maxAge: maxAge });

  const currentTime = new Date();
  const access_token_expires_at = new Date(currentTime.getTime() + (jwt.access_token_expires_in - 10) * 1000); //removed 10 secs for network buffer
  const refresh_token_expires_at = new Date(currentTime.getTime() + (jwt.refresh_token_expires_in - 10) * 1000); //removed 10 secs for network buffer
  cookies.set('access_token_expires_at', access_token_expires_at, { path: '/', domain: domain, maxAge: maxAge });
  cookies.set('refresh_token_expires_at', refresh_token_expires_at, { path: '/', domain: domain, maxAge: maxAge });

  cookies.set('cogni_customer_uid', decoded_token.account.account_id, {
    path: '/',
    domain: domain,
    maxAge: maxAge,
  });
  cookies.set('cogni_user_email', decoded_token.email, {
    path: '/',
    domain: domain,
    maxAge: maxAge,
  });
  cookies.set('cogni_user_uid', decoded_token.user_id, {
    path: '/',
    domain: domain,
    maxAge: maxAge,
  });
  cookies.set('rbac_enabled', decoded_token.account.config?.enable_rbac as any, {
    path: '/',
    domain: domain,
    maxAge: maxAge,
  });
  cookies.set('customerCompanyName', decoded_token.account.account_name, {
    path: '/',
    domain: domain,
    maxAge: maxAge,
  });
  cookies.set('user_type', decoded_token.account.user_type, { path: '/', domain: domain, maxAge: maxAge });
  cookies.set('user_name', decoded_token.username, { path: '/', domain: domain, maxAge: maxAge });
  cookies.set('customer_subdomain', decoded_token.account.subdomain, {
    path: '/',
    domain: domain,
    maxAge: maxAge,
  });
  cookies.set('isCustomerPortal', 'false', {
    path: '/',
    domain: domain,
    maxAge: maxAge,
  });
  cookies.set('enable_custom_reports', decoded_token.account.config?.enable_custom_reports as any, {
    path: '/',
    domain: domain,
    maxAge: maxAge,
  });
  cookies.set('enable_custom_aggregations', decoded_token.account.config?.enable_custom_aggregations as any, {
    path: '/',
    domain: domain,
    maxAge: maxAge,
  });
};

const cookies = new Cookies();

const initialState: authType = {
  isAuthed: cookies.get('jwt-token') ? true : false,
  status: requestState.idle,
  error: null,
  doesEmailExist: false,
  signupEmailSent: false,
  isEmailValid: false,
  allowAccess: false,
  setUserDataObj: false,
  fullDetails: {},
  redirectToLogin: false,
  redirectToLoginOnGoogleSignup: false,
  redirectToCompleteAccount: false,
  signupOnGoogleUID: '',
  signupOnGoogleToken: '',
  signupOnGoogleUserName: '',
  successMessage: '',
  isPasswordUpdated: false,
  showSuccessScreenFeedback: false,
  passwordUpdated: {
    data: null,
    status: requestState.idle,
    message: '',
  },
};

export const loginUser = createAsyncThunk('user/login', async (loginDetails: any, { rejectWithValue }) => {
  // const data = await authAPI.userLogin(loginDetails);
  // console.log(data, 'login res data');
  try {
    const { data } = await authAPI.userLogin(loginDetails);
    console.log(data);
    return data;
  } catch (err: any) {
    // Use `err.response.data` as `action.payload` for a `rejected` action,
    // by explicitly returning it using the `rejectWithValue()` utility
    console.log(err, 'err');
    return rejectWithValue(err.response.data);
  }
  // return data;
});

export const mimicLoginForPasswordCheck = createAsyncThunk('user/miclogin', async (loginDetails: any) => {
  let resData: any;
  try {
    await authAPI.userLogin(loginDetails);
    resData = 1;
  } catch (err) {
    resData = 0;
  }
  return resData;
});

export const loginUserWithSSO = createAsyncThunk('user/login-sso', async (loginDetails: any) => {
  const { data } = await authAPI.userSSOLogin(loginDetails);
  return data;
});

export const requestForgotPasswordEmail = createAsyncThunk('user/check-emailvalid', async (requestDetails: any) => {
  const { data } = await authAPI.userRequestChangePassword(requestDetails);
  return data;
});

export const checkAndValidateStatus = createAsyncThunk('user/validate-status', async (dataObj: any) => {
  const { data } = await commonAPI.checkAndValidateStatus(dataObj);
  return data;
});

export const updatePasswordByUId = createAsyncThunk('user/reset-password', async (updateObj: any) => {
  // DEPRECATED : DO NOT USE
  const { data } = await commonAPI.updatePasswordByUId(updateObj);
  return data;
  // DEPRECATED : DO NOT USE
});

export const getUserInfoByUid = createAsyncThunk('user/get-info-by-uid', async (user_uid: string) => {
  const { data } = await commonAPI.getUserInfoByUid(user_uid);
  return data;
});

export const verifyEmailAddress = createAsyncThunk('user/verify-email', async (user_email: string) => {
  const { data } = await commonAPI.verifyEmailAddress(user_email);
  return data;
});

export const sendForgotPasswordEmail = createAsyncThunk('user/forgot-password', async (userDetails: any) => {
  const { data } = await commonAPI.sendForgotPasswordEmail(userDetails);
  return data;
});

export const sendUserSignupEmail = createAsyncThunk('user/send-email', async (userSignupDetails: any) => {
  const { data } = await commonAPI.sendUserSignupEmail(userSignupDetails);

  return data;
});
export const sendUserSignupEmailGmail = createAsyncThunk('user/send-email-gmail', async (userSignupDetails: any) => {
  const { data } = await commonAPI.sendUserSignupEmailGmail(userSignupDetails);
  return data;
});
export const sendUserSignupNotification = createAsyncThunk('user/send-email-notification', async (userDetails: any) => {
  console.log('dispatched call');
  const { data } = await commonAPI.sendUserSignupNotification(userDetails);
  console.log('dispatched call data', data);
  return data;
});

export const sendUserSignupGoogle = createAsyncThunk('user/signup-with-google', async (googleUserObject: any) => {
  const { data } = await commonAPI.sendUserSignupGoogle(googleUserObject);
  return data;
});

export const createUserPassword = createAsyncThunk('user/create-password', async (dataObj: any) => {
  const { data } = await commonAPI.createUserPassword(dataObj);
  return data;
});

export const completeUserAccount = createAsyncThunk(
  'user/complete-account123',
  async (userSignupDetailsGoogle: any) => {
    const { data } = await commonAPI.completeUserAccount(userSignupDetailsGoogle);
    return data;
  }
);

export const mailToSupport = createAsyncThunk('mailToSupport', async (reqObj: any) => {
  const { data } = await commonAPI.mailToSupport(reqObj);
  return data;
});

export const feedbackMailTosupport = createAsyncThunk('feedbackMailTosupport', async (reqObj: any) => {
  const { data } = await commonAPI.feedbackMailTosupport(reqObj);
  return data;
});

export const invalidateRefreshToken = createAsyncThunk('invalidateRefreshToken', async () => {
  const detail = {
    refresh_token: cookies.get('refresh-token') as string,
    application: 'cognisaas_app',
  };
  await authAPI.invalidateRefreshToken(detail);
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logoutUser(state: authType) {
      sessionStorage.clear();
      localStorage.clear();
      db.configs.clear();
      console.log('logout user called');
      if (env == 'local') {
        cookies.remove('customer_company', { path: '/', domain: 'localhost' });
        cookies.remove('cogni_customer_uid', { path: '/', domain: 'localhost' });
        cookies.remove('cogni_user_uid', { path: '/', domain: 'localhost' });
        cookies.remove('cogni_user_email', { path: '/', domain: 'localhost' });
        cookies.remove('user_name', { path: '/', domain: 'localhost' });
        cookies.remove('jwt-token', { path: '/', domain: 'localhost' });
        cookies.remove('rbac_enabled', { path: '/', domain: 'localhost' });
        cookies.remove('enable_custom_reports', { path: '/', domain: 'localhost' });
        cookies.remove('enable_custom_aggregations', { path: '/', domain: 'localhost' });
        cookies.remove('user_type', { path: '/', domain: 'localhost' });
        cookies.remove('customer_subdomain', { path: '/', domain: 'localhost' });
        cookies.remove('customerCompanyName', { path: '/', domain: 'localhost' });
        cookies.remove('isCustomerPortal', { path: '/', domain: 'localhost' });

        cookies.remove('refresh-token', { path: '/', domain: 'localhost' });
        cookies.remove('access_token_expires_in', { path: '/', domain: 'localhost' });
        cookies.remove('refresh_token_expires_in', { path: '/', domain: 'localhost' });

        cookies.remove('access_token_expires_at', { path: '/', domain: 'localhost' });
        cookies.remove('refresh_token_expires_at', { path: '/', domain: 'localhost' });
      } else if (env == 'dev') {
        cookies.remove('customer_company', { path: '/', domain: '.devng.cognisaas.com' });
        cookies.remove('cogni_customer_uid', { path: '/', domain: '.devng.cognisaas.com' });
        cookies.remove('cogni_user_uid', { path: '/', domain: '.devng.cognisaas.com' });
        cookies.remove('cogni_user_email', { path: '/', domain: '.devng.cognisaas.com' });
        cookies.remove('user_name', { path: '/', domain: '.devng.cognisaas.com' });
        cookies.remove('jwt-token', { path: '/', domain: '.devng.cognisaas.com' });
        cookies.remove('rbac_enabled', { path: '/', domain: '.devng.cognisaas.com' });
        cookies.remove('enable_custom_reports', { path: '/', domain: '.devng.cognisaas.com' });
        cookies.remove('enable_custom_aggregations', { path: '/', domain: '.devng.cognisaas.com' });
        cookies.remove('user_type', { path: '/', domain: '.devng.cognisaas.com' });
        cookies.remove('customer_subdomain', { path: '/', domain: '.devng.cognisaas.com' });
        cookies.remove('customerCompanyName', { path: '/', domain: '.devng.cognisaas.com' });
        cookies.remove('isCustomerPortal', { path: '/', domain: '.devng.cognisaas.com' });
        cookies.remove('refresh-token', { path: '/', domain: '.devng.cognisaas.com' });
        cookies.remove('access_token_expires_in', { path: '/', domain: '.devng.cognisaas.com' });
        cookies.remove('refresh_token_expires_in', { path: '/', domain: '.devng.cognisaas.com' });
        cookies.remove('access_token_expires_at', { path: '/', domain: '.devng.cognisaas.com' });
        cookies.remove('refresh_token_expires_at', { path: '/', domain: '.devng.cognisaas.com' });
      } else if (env == 'prod') {
        cookies.remove('customer_company', { path: '/', domain: '.ng.cognisaas.com' });
        cookies.remove('cogni_customer_uid', { path: '/', domain: '.ng.cognisaas.com' });
        cookies.remove('cogni_user_uid', { path: '/', domain: '.ng.cognisaas.com' });
        cookies.remove('cogni_user_email', { path: '/', domain: '.ng.cognisaas.com' });
        cookies.remove('user_name', { path: '/', domain: '.ng.cognisaas.com' });
        cookies.remove('jwt-token', { path: '/', domain: '.ng.cognisaas.com' });
        cookies.remove('rbac_enabled', { path: '/', domain: '.ng.cognisaas.com' });
        cookies.remove('enable_custom_reports', { path: '/', domain: '.ng.cognisaas.com' });
        cookies.remove('enable_custom_aggregations', { path: '/', domain: '.ng.cognisaas.com' });
        cookies.remove('user_type', { path: '/', domain: '.ng.cognisaas.com' });
        cookies.remove('customer_subdomain', { path: '/', domain: '.ng.cognisaas.com' });
        cookies.remove('customerCompanyName', { path: '/', domain: '.ng.cognisaas.com' });
        cookies.remove('isCustomerPortal', { path: '/', domain: '.ng.cognisaas.com' });
        cookies.remove('refresh-token', { path: '/', domain: '.ng.cognisaas.com' });
        cookies.remove('access_token_expires_in', { path: '/', domain: '.ng.cognisaas.com' });
        cookies.remove('refresh_token_expires_in', { path: '/', domain: '.ng.cognisaas.com' });
        cookies.remove('access_token_expires_at', { path: '/', domain: '.ng.cognisaas.com' });
        cookies.remove('refresh_token_expires_at', { path: '/', domain: '.ng.cognisaas.com' });
      } else if (env == 'new-prod') {
        cookies.remove('customer_company', { path: '/', domain: '.cognisaas.net' });
        cookies.remove('cogni_customer_uid', { path: '/', domain: '.cognisaas.net' });
        cookies.remove('cogni_user_uid', { path: '/', domain: '.cognisaas.net' });
        cookies.remove('cogni_user_email', { path: '/', domain: '.cognisaas.net' });
        cookies.remove('user_name', { path: '/', domain: '.cognisaas.net' });
        cookies.remove('jwt-token', { path: '/', domain: '.cognisaas.net' });
        cookies.remove('rbac_enabled', { path: '/', domain: '.cognisaas.net' });
        cookies.remove('enable_custom_reports', { path: '/', domain: '.cognisaas.net' });
        cookies.remove('enable_custom_aggregations', { path: '/', domain: '.cognisaas.net' });
        cookies.remove('user_type', { path: '/', domain: '.cognisaas.net' });
        cookies.remove('customer_subdomain', { path: '/', domain: '.cognisaas.net' });
        cookies.remove('customerCompanyName', { path: '/', domain: '.cognisaas.net' });
        cookies.remove('isCustomerPortal', { path: '/', domain: '.cognisaas.net' });
        cookies.remove('refresh-token', { path: '/', domain: '.cognisaas.net' });
        cookies.remove('access_token_expires_in', { path: '/', domain: '.cognisaas.net' });
        cookies.remove('refresh_token_expires_in', { path: '/', domain: '.cognisaas.net' });
        cookies.remove('access_token_expires_at', { path: '/', domain: '.cognisaas.net' });
        cookies.remove('refresh_token_expires_at', { path: '/', domain: '.cognisaas.net' });
      }
      cookies.remove('showTeamDashboardTab');
      localStorage.clear();
      console.log('jwt after logout', cookies.get('jwt-token'));
      state.isAuthed = false;
    },
    resetAuthStatus(state: authType) {
      state.status = initialState.status;
    },
    resetErrorMessage(state: authType) {
      state.error = initialState.error;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(loginUser.pending, (state) => {
        state.status = requestState.loading;
      })
      .addCase(loginUser.fulfilled, (state: authType, { payload }) => {
        state.status = requestState.success;
        console.log(payload);
        setAuthenticationCookies(payload);
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action;
        console.log(action);
      })

      // for reset password

      .addCase(requestForgotPasswordEmail.pending, (state) => {
        state.status = requestState.loading;
      })
      .addCase(requestForgotPasswordEmail.fulfilled, (state: any, { payload }) => {
        state.status = requestState.success;
        if (payload.status === 1) {
          state.doesEmailExist = true;
        }
      })
      .addCase(requestForgotPasswordEmail.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      })

      // for reset password

      .addCase(loginUserWithSSO.pending, (state) => {
        state.status = requestState.loading;
      })
      .addCase(loginUserWithSSO.fulfilled, (state: any, { payload }) => {
        //Temporary fix, to avoid re logging in due to session storage of Google and Microsoft
        if (payload['status'] == 1) {
          sessionStorage.clear();
          setAuthenticationCookies(payload.jwt);
        }
      })
      .addCase(loginUserWithSSO.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      })

      // send signup email
      .addCase(sendUserSignupEmail.pending, (state) => {
        state.status = requestState.loading;
      })
      .addCase(sendUserSignupEmail.fulfilled, (state: authType) => {
        state.status = requestState.success;
      })
      .addCase(sendUserSignupEmail.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      })

      // send signup email
      .addCase(sendUserSignupEmailGmail.pending, (state) => {
        state.status = requestState.loading;
      })
      .addCase(sendUserSignupEmailGmail.fulfilled, (state: authType) => {
        state.status = requestState.success;
      })
      .addCase(sendUserSignupEmailGmail.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      })
      // send signup notification
      .addCase(sendUserSignupNotification.pending, (state) => {
        state.status = requestState.loading;
      })
      .addCase(sendUserSignupNotification.fulfilled, (state: authType) => {
        state.status = requestState.success;
      })
      .addCase(sendUserSignupNotification.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      })

      //check if email valid
      .addCase(verifyEmailAddress.pending, (state) => {
        state.status = requestState.loading;
      })
      .addCase(verifyEmailAddress.fulfilled, (state: authType, { payload }) => {
        state.status = requestState.success;
        if (payload.status === 1) {
          state.isEmailValid = true;
        }
        if (payload.status === 0) {
          console.log(payload, 'check email status121');
          state.error = payload.message;
        }
      })
      .addCase(verifyEmailAddress.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      })

      // check allow access before setting password
      .addCase(checkAndValidateStatus.pending, (state) => {
        state.status = requestState.loading;
      })
      .addCase(checkAndValidateStatus.fulfilled, (state: authType, { payload }) => {
        state.status = requestState.success;
        if (payload.status === 1) {
          state.allowAccess = true;
        }
      })
      .addCase(checkAndValidateStatus.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      })
      // get user info by uid on create password screen

      .addCase(getUserInfoByUid.pending, (state) => {
        state.status = requestState.loading;
      })
      .addCase(getUserInfoByUid.fulfilled, (state: authType, { payload }) => {
        state.status = requestState.success;
        if (payload.status === 1) {
          state.setUserDataObj = true;
          state.fullDetails = payload.result;
        }
      })
      .addCase(getUserInfoByUid.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      })

      // if password is set redirect to login page
      .addCase(createUserPassword.pending, (state) => {
        state.status = requestState.loading;
        state.passwordUpdated.status = requestState.loading;
      })
      .addCase(createUserPassword.fulfilled, (state: authType, { payload }) => {
        state.status = requestState.success;
        if (payload.status === 1) {
          state.redirectToLogin = true;
          state.passwordUpdated.status = requestState.success;
          state.passwordUpdated.message = '';
        } else {
          state.passwordUpdated.status = requestState.failed;
          state.passwordUpdated.message = payload?.message;
        }
      })
      .addCase(createUserPassword.rejected, (state, action) => {
        state.passwordUpdated.status = requestState.failed;
        state.status = requestState.failed;
        state.error = action.error.message;
      })

      // complete google user signup
      .addCase(sendUserSignupGoogle.pending, (state) => {
        state.status = requestState.loading;
      })
      .addCase(sendUserSignupGoogle.fulfilled, (state: authType, { payload }) => {
        state.status = requestState.success;
        if (payload.status === 1) {
          if (payload.message === 'Please Complete Your Account.') {
            state.redirectToCompleteAccount = true;
            state.signupOnGoogleUID = payload.user_uid;
            state.signupOnGoogleToken = payload.token;
          }
        }
        if (payload.status === 0) {
          state.error = payload.message;
        }
      })
      .addCase(sendUserSignupGoogle.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      })

      // complete google user signup
      .addCase(completeUserAccount.pending, (state) => {
        state.status = requestState.loading;
      })
      .addCase(completeUserAccount.fulfilled, (state: authType, { payload }) => {
        state.status = requestState.success;
        if (payload.status === 1) {
          if (payload.message === 'Your account setup has been completed successfully.') {
            state.redirectToLoginOnGoogleSignup = true;
          }
          console.log(state.redirectToLoginOnGoogleSignup, 'success check');
        }
      })
      .addCase(completeUserAccount.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      })

      // update password
      .addCase(updatePasswordByUId.pending, (state) => {
        state.status = requestState.loading;
        state.passwordUpdated.status = requestState.loading;
      })
      .addCase(updatePasswordByUId.fulfilled, (state: authType, { payload }) => {
        state.status = requestState.success;
        state.passwordUpdated.status = requestState.success;
        if (payload.status === 1) {
          state.isPasswordUpdated = true;
        }
      })
      .addCase(updatePasswordByUId.rejected, (state, action) => {
        state.status = requestState.failed;
        state.passwordUpdated.status = requestState.failed;
        state.passwordUpdated.message = action.error.message ? action.error.message : 'Something went wrong';
        state.error = action.error.message;
      })
      // check if feedback successful
      .addCase(mailToSupport.pending, (state) => {
        state.status = requestState.loading;
      })
      .addCase(mailToSupport.fulfilled, (state: authType, { payload }) => {
        state.status = requestState.success;
        if (payload) {
          console.log(payload, 'i r ss');
          state.showSuccessScreenFeedback = true;
        }
      })
      .addCase(mailToSupport.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      });
  },
});

export const { logoutUser, resetAuthStatus, resetErrorMessage } = authSlice.actions;
const authReducer = authSlice.reducer;
export default authReducer;
