import styled from 'styled-components';

interface DocumentProps {
    indent: number,
    active: boolean
}

export const Container = styled.div`
  padding-top: 10px;
  box-sizing: border-box;
  font-family: sans-serif;

  &:first-child {
    border-left: 1px dotted lightgrey;
  }

  &:first-child {
    padding-top: 0;
  }
`;

export const Title = styled.p`
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  display: inline;
  padding: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
export const Small = styled.div`
  color: #7A7A7A;
  font-weight: 300;
  font-size: 12px;
`;

export const Document = styled.div<DocumentProps>`
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  height: 70px;
  position: relative;
  border-radius: 5px;
  padding: 10px;
  position: relative;
  z-index: 999;
  background-color: #fff;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  transition: all 300ms linear;
  margin-left: ${({ indent }) => indent * 15}px;

  ${({ active }) => active === true && `
    border: 1px solid #2D60F6;
  `}

  &:hover {
    border: 1px solid #2D60F6;
  }
`;
export const Frame = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const Group = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const Wrapper = styled.div`
 position: relative;
 display: flex;
 flex-direction: column;
`;

export const Intersection = styled.div`
width: 280px;
height: 1px;
background-color: #E5E5E5;
position: absolute;
top: 50%;
z-index: 0;
`;