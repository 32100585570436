import Box from '@mui/material/Box';
import { Typography, Stack, Avatar } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { getClientByUid, updateClientCustomFields } from '../../store/modules/ManageClient/ClientList/slice';
import { selectCustomerDetails } from 'store/modules/Settings/selector';
import { DataGridPremium, gridClasses, GridRenderEditCellParams } from '@mui/x-data-grid-premium';
import CognisaasToast from 'components/shared/CognisaasToast/CognisaasToast';
import '../../components/ClientModule/ClientContacts/ContactsTable.css';
import { Link } from 'react-router-dom';
import { selectCustomFields } from '../../store/modules/Common/selector';
import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import '../../components/ClientModule/ClientContacts/ContactsTable.css';
import { useAppSelector } from '../../store/hooks';
import { selectUsersOfCompany } from '../../store/modules/Common/selector';
import {
  getCustomFields,
  getAllUsers,
  fetchCustomerLevelCustomFields,
  // saveAppStateConfiguration,
  // getAppStateConfigurationsFromDb,
} from '../../store/modules/Common/slice';
// import { updateClient } from '../../store/modules/ManageClient/ClientList/slice';
import { default as AvatarImage } from '../shared/Avatar/Avatar';
import {
  getUserLogoLinkByUid,
  getStageNames,
  getIndustryId,
  getSegmentId,
  getStageId,
  getStageName,
  getIndustryName,
  getSegmentName,
  getUserUid,
} from 'utils/getUid';
import './manageclient.css';

import { GridRenderCellParams, GridColumnOrderChangeParams } from '@mui/x-data-grid-premium';
import { selectClientDetailByClientUid } from 'store/modules/ManageClient/ClientList/selector';
import { getCustomerDetails } from 'store/modules/Settings/slice';
import getSymbolFromCurrency from 'currency-symbol-map';
import {
  label_arr,
  label_client,
  label_cs_owner,
  label_sales_owner,
} from 'components/Settings/Preferences/CustomLabelCookieHelper';
import {
  SelectEditDropdownCell,
  SelectEditNumericInputCell,
  SelectEditStringInputCell,
} from 'components/shared/Tables/InlineEditTable';
import { setUpFiltersForClientListPage } from 'store/modules/ui/Filters/slice';
import { selectIndustryFilterData } from 'store/modules/ui/Filters/selector';
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExportContainer,
  GridToolbarQuickFilter,
  GridPrintExportMenuItem,
  GridCsvExportMenuItem,
  GridColumns,
} from '@mui/x-data-grid';
import CognisaaasTooltip from 'components/shared/CognisaasTooltip/CognisaasTooltip';
import { formatCurrency, generateMappedCustomFieldKey, generateOrderedColumn } from 'utils/dataHelpers';
import ViewDate from 'components/shared/ViewDate/ViewDate';
import {
  generateTableColumnsWithCustomFiltersMapped,
  generateClientTableColumnsWithCustomFieldsMapped,
} from 'components/shared/Tables/CogniTable';
import { inlineEditPropType } from 'components/shared/Tables/types';
import useCustomNames from 'components/shared/CustomNames/useCustomNames';
import useCommonAppConfig from 'components/Common/useCommonAppConfig';
import CognisaasLoader from 'components/shared/CognisaasLoader/CognisaasLoader';
import { getUserActiveState, INACTIVE_USER_COLOR } from 'utils/userHelpers';
import { getMaxColumnWidth } from 'utils/getMaxColumnWidth';
import { requestState } from 'store/modules/types';
import {
  selectClientIndustries,
  selectClientSegments,
  selectClientStages,
} from 'store/modules/ManageClient/ClientList-new/selector';

import {
  updateClient,
} from 'store/modules/ManageClient/ClientList-new/slice';
import { selectUsers } from 'store/modules/features/common/selector';
const ODD_OPACITY = 0.2;

function CustomToolbar({ csvOptions, printOptions, ...other }: any) {
  return (
    <GridToolbarContainer>
      <Stack direction="row" justifyContent="space-between" style={{ width: '100%' }}>
        <Stack direction="row">
          <GridToolbarColumnsButton nonce={undefined} onResize={undefined} onResizeCapture={undefined} />
          <GridToolbarFilterButton nonce={undefined} onResize={undefined} onResizeCapture={undefined} />
          <GridToolbarDensitySelector nonce={undefined} onResize={undefined} onResizeCapture={undefined} />
          <GridToolbarExportContainer {...other}>
            <GridCsvExportMenuItem options={csvOptions} />
            <GridPrintExportMenuItem options={printOptions} />
          </GridToolbarExportContainer>
        </Stack>
        <GridToolbarQuickFilter />
      </Stack>
    </GridToolbarContainer>
  );
}

const StripedDataGrid = styled(DataGridPremium)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
        },
      },
    },
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: 600,
  width: '100%',
  borderRadius: '4px',
  boxShadow: '0px 4px 26px rgba(0, 0, 0, 0.05)',
  '& .MuiFormGroup-options': {
    alignItems: 'center',
    paddingBottom: theme.spacing(1),
    '& > div': {
      minWidth: 100,
      margin: theme.spacing(2),
      marginLeft: 0,
    },
  },
}));

function stringToHslColor(str: string, s: number, l: number) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const h = hash % 360;
  return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToHslColor(name, 30, 80),
    },
    children: (
      <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#000000' }}>
        {name.split(' ')[0][0]}
        {name.indexOf(' ') >= 0 ? name.split(' ')[1][0] : null}
      </Typography>
    ),
  };
}
const parseLines = (value: string) => value?.replace(/(\\n)/g, '\n').replace(/(\\)/g, '');
const ClientListTable = (props: any) => {
  const dispatch = useDispatch();
  const customFields = useAppSelector(selectCustomFields);
  const clientDetailByUid = useAppSelector(selectClientDetailByClientUid);
  // const segments = useAppSelector(selectSegmentFilterData);
  const [tableColumns, setTableColumns] = useState<any[]>([]);
  const [tableRows, setTableRows] = useState<any[]>([...props.data]);
  const industries = useAppSelector(selectClientIndustries);
  const stages = useAppSelector(selectClientStages);
  const segments = useAppSelector(selectClientSegments);
  const users = useAppSelector(selectUsers).data;
  const [preventUserFromEditing, setPreventUserFromEditing] = useState<boolean>(false);
  // const [pageSize, setPageSize] = useState<number>(20);
  const customerDetail = useAppSelector(selectCustomerDetails).data;
  const uniqueClientColumnId = 'clientListTable';
  const {
    displayDataWithCustomNames,
    clientSegmentDropdownValues,
    getStandardNameMappedValue,
    getStandardRowDataWithoutCustomNames,
  } = useCustomNames();

  // saving table / app state
  let myTableState: any = {};
  const { saveAppTableState, getTableState } = useCommonAppConfig();
  // const appStateConfiguration = useAppSelector(selectAppConfigurationsState);
  const initialState: any = {
    columns: {
      columnVisibilityModel: {
        client_uid: false,
      },
    },
    pinnedColumns: {
      left: ['name'],
    },
    pagination: {
      page: 0,
      pageSize: 20,
    },
    ...getTableState('clientListPage'),
  };

  const columns: GridColumns<any> = [
    {
      headerName: `${label_client} Name`,
      field: 'name',
      width: 240,
      renderCell: (params: GridRenderCellParams) => {
        return <Link to={getClient(params.row)}>{params.value}</Link>;
      },
    },
    {
      headerName: label_sales_owner,
      field: 'sales_owner',
      width: getMaxColumnWidth(users),
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams) => {
       return  SelectEditDropdownCell(params, users, handleInlineEditValue,  'user_name', 'clientSalesOwner');
      },

      renderCell: (params: GridRenderCellParams) => {
        const uid = params.row.sales_user_uid;
        // const user_profile_pic_link = uid.length > 0 ? getUserLogoLinkByUid(uid) : '';

        return (
          <Stack direction="row">
            <Stack spacing={1} direction={'row'} alignItems={'center'}>
              {/* {user_profile_pic_link?.length > 0 ? (
                <AvatarImage src={user_profile_pic_link} alt="Sales Owner profile" height="34px" width="34px" />
              ) : (
                <Avatar style={{ height: '34px', width: '34px', marginTop: '3px' }} {...stringAvatar(params.value)} />
              )} */}
              {/* <Typography style={{ marginTop: '8px' }} variant="subtitle1">
                {params.value}
              </Typography> */}
              {/* {getUserActiveState(getUserUid(params.value), users) === '0' ? (
                <Typography style={{ color: INACTIVE_USER_COLOR }} mt={1} variant="subtitle1">
                  {params.value}&nbsp;(Inactive)
                </Typography>
              ) : (
                <Typography mt={1} variant="subtitle1">
                  {params.value}
                </Typography>
              )} */}
              <Typography mt={1} variant="subtitle1">
                {params.value.user_name}
              </Typography>
            </Stack>
          </Stack>
        );
      },
    },
    {
      headerName: label_cs_owner,
      width: getMaxColumnWidth(users),
      field: 'cs_owner',
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams) => {
        return SelectEditDropdownCell(params, users, handleInlineEditValue, 'user_name','clientCsOwner' );
      },
      renderCell: (params: GridRenderCellParams) => {
        // const uid = params.row.user_uid;
        // const user_profile_pic_link = uid.length > 0 ? getUserLogoLinkByUid(uid) : '';
        return (
          <Stack direction="row">
            <Stack spacing={1} direction={'row'} alignItems={'center'}>
              {/* {user_profile_pic_link?.length > 0 ? (
                <AvatarImage src={user_profile_pic_link} alt="CS Owner profile" height="34px" width="34px" />
              ) : (
                <Avatar style={{ height: '34px', width: '34px', marginTop: '3px' }} {...stringAvatar(params.value)} />
              )} */}
              {/* <Typography style={{ marginTop: '8px' }} variant="subtitle1">
                {params.value}
              </Typography> */}
              {/* {getUserActiveState(getUserUid(params.value), users) === '0' ? (
                <Typography style={{ color: INACTIVE_USER_COLOR }} mt={1} variant="subtitle1">
                  {params.value}&nbsp;(Inactive)
                </Typography>
              ) : (
                <Typography mt={1} variant="subtitle1">
                  {params.value}
                </Typography>
              )} */}
              <Typography mt={1} variant="subtitle1">
                {params.value.user_name}
              </Typography>
            </Stack>
          </Stack>
        );
      },
    },
    {
      headerName: 'Status',
      field: 'status',
      width: 150,
      valueFormatter: ({ value }) =>
        value == 'RED'
          ? 'Overdue'
          : value == 'AMBER'
          ? 'At Risk'
          : value == 'GREEN'
          ? 'On Track'
          : value == 'BLUE'
          ? 'Completed'
          : '',
      renderCell: (params: GridRenderCellParams) => (
        <>
          {params.row.client_status == 0 ? (
            <>-</>
          ) : (
            <Stack>
              <Stack spacing={1} direction="row">
                <span
                  style={{ marginRight: '4px' }}
                  className={
                    params.value == 'RED'
                      ? 'red_status_box_task'
                      : params.value == 'AMBER'
                      ? 'amber_status_box_task'
                      : params.value == 'GREEN'
                      ? 'green_status_box_task'
                      : params.value == 'BLUE'
                      ? 'blue_status_box_task'
                      : ''
                  }
                ></span>
                {params.value == 'RED'
                  ? 'Overdue'
                  : params.value == 'AMBER'
                  ? 'At Risk'
                  : params.value == 'GREEN'
                  ? 'On Track'
                  : params.value == 'BLUE'
                  ? 'Completed'
                  : ''}
              </Stack>
            </Stack>
          )}
        </>
      ),
    },
    {
      headerName: 'Segment',
      field: 'segment',
      // width: getMaxColumnWidth(segments),
      width: 250,
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams) =>
        SelectEditDropdownCell(params, segments, handleInlineEditValue, 'name','Segment'),
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Stack direction="row">
            <Typography mt={1} variant="subtitle1">
              {params.value.name}
            </Typography>
          </Stack>
        );
      },
    },
    {
      headerName: 'Industry',
      field: 'industry',
      width: 250,
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams) =>
        SelectEditDropdownCell(params, industries, handleInlineEditValue, 'name','Industry'),
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Stack direction="row">
            <Typography mt={1} variant="subtitle1">
              {params.value.name}
            </Typography>
          </Stack>
        );
      },
    },
    {
      headerName: 'Stage',
      field: 'stage',
      width: 250,
      // width: getMaxColumnWidth(stages),
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams) =>
        SelectEditDropdownCell(params, stages, handleInlineEditValue, 'name','Stage'),
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Stack direction="row">
            <Typography mt={1} variant="subtitle1">
              {params.value.name}
            </Typography>
          </Stack>
        );
      },
    },
  ];

  const handleInlineEditCustomValue = async (
    params: GridRenderEditCellParams,
    editableField: string,
    editableValue: string,
    type?: inlineEditPropType
  ) => {
    const index = params.indexofCustomField;
    if (type === 'Client') {
      await dispatch(updateClientCustomFields({ ...params.row.custom_values[index], field_value: editableValue }));
      await dispatch(getClientByUid(params.row.client_uid));
    }
  };

  const makeColumnsForTable = () => {
    const customColumns: any = [
      ...generateClientTableColumnsWithCustomFieldsMapped(
        props.data[0].custom_fields,
        columns,
        'Client',
        customFields,
        handleInlineEditCustomValue,
        users
      ),
    ];
    setTableColumns(generateOrderedColumn([...customColumns], uniqueClientColumnId));
  };

  useEffect(() => {
    makeColumnsForTable();
  }, []);

  useEffect(() => {
    dispatch(getAllUsers());
    dispatch(getCustomFields());
    dispatch(getCustomerDetails());
  }, []);

  useEffect(() => {
    setTableRows([...props.data]);
  }, [props.data]);

  useEffect(() => {
    if (users.length > 0) {
      setUsersData();
    }
  }, [users]);

  // useEffect(() => {
  //   const updateTableRows = JSON.parse(JSON.stringify(tableRows));

  //   const rows = updateTableRows.map((row: any) => {
  //     if (row?.client_uid === clientDetailByUid?.client_uid) {
  //       const myArray: any = [];
  //       clientDetailByUid.custom_values.map((ele: any) => {
  //         const label = generateMappedCustomFieldKey(ele.field_name, ele.field_uid, ele.field_type);
  //         myArray[label] = ele.field_value;
  //       });
  //       return { ...row, custom_values: { ...clientDetailByUid.custom_values }, ...myArray };
  //     }
  //     return row;
  //   });
  //   setTableRows(rows);
  // }, [clientDetailByUid]);

  const setUsersData = () => {
    const data = users.filter((user: any) => user.is_active === '1').map((user: any) => user.user_username);
    data.unshift('Not Assigned');
  };

  const getClient = (row: any) => {
    return `/client-list/client/${row.id}`;
  };

  const handleInlineEditValue = async (
    params: GridRenderEditCellParams,
    editableField: string,
    editableValue: any,
    type?:
      | 'Industry'
      | 'Stage'
      | 'Segment'
      | 'CUSTOM_FIELD'
      | 'Project'
      | 'Client'
      | 'Usecase'
      | 'Task'
      | 'clientCsOwner'
      | 'clientSalesOwner'
  ) => {
    // const industryUid = getIndustryUid(params?.row?.industry_name);
    // const stageUid = getStageUid(params?.row?.stage_journerystage);
    // const segmentUid = getSegmentUid(getStandardNameMappedValue(params?.row?.segment_name, 'segment_name'));
    // const newRow = {
    //   ...getStandardRowDataWithoutCustomNames({ ...params.row }),
    //   stage_uid: stageUid,
    //   industry_uid: industryUid,
    //   segment_uid: segmentUid,
    // };
    if (type === 'Industry') {
      const id = params.row.id;
      await dispatch(
        updateClient({
          id,
          payload: { industry: editableValue.id },
        })
      );
    }
    if (type === 'Segment') {
      const id = params.row.id;
      await dispatch(
        updateClient({
          id,
          payload: { segment: editableValue.id },
        })
      );
    }
    if (type === 'Stage') {
      const id = params.row.id;
      await dispatch(
        updateClient({
          id,
          payload: { stage: editableValue.id },
        })
      );
    }
    // } else if (type === 'Segment') {
    //   const segmentUid = getSegmentId(getStandardNameMappedValue(editableValue, 'segment_name'));
    //   await dispatch(
    //     updateClient({
    //       ...newRow,
    //       [editableField]: getStandardNameMappedValue(editableValue, 'segment_name'),
    //       original_name: params.row?.client_name || '',
    //       segment_uid: segmentUid,
    //     })
    //   );
    // } else if (type === 'Stage') {
    //   const stageUid = getStageId(editableValue);
    //   await dispatch(
    //     updateClient({
    //       ...newRow,
    //       [editableField]: editableValue,
    //       original_name: params.row?.client_name || '',
    //       stage_uid: stageUid,
    //     })
    //   );}
    // } else if (type === 'CUSTOM_FIELD') {
    //   const index = params.indexofCustomField;
    //   await dispatch(updateClientCustomFields({ ...params.row.custom_values[index], field_value: editableValue }));
    //   await dispatch(getClientByUid(params.row.client_uid));
    // } else if (type === 'clientCsOwner') {
    //   await dispatch(
    //     updateClient({
    //       ...newRow,
    //       [editableField]: editableValue,
    //       user_uid: getUserUid(editableValue),
    //       original_name: params.row?.client_name || '',
    //     })
    //   );
    // } else if (type === 'clientSalesOwner') {
    //   await dispatch(
    //     updateClient({
    //       ...newRow,
    //       [editableField]: editableValue,
    //       sales_user_uid: getUserUid(editableValue),
    //       original_name: params.row?.client_name || '',
    //     })
    //   );
    // } else {
    //   await dispatch(
    //     updateClient({ ...newRow, [editableField]: editableValue, original_name: params.row?.client_name || '' })
    //   );
    // }
  };

  const data = {
    columns: tableColumns,
    // rows: displayDataWithCustomNames(tableRows),
    rows: props.data,
  };

  const closeToast = () => {
    setPreventUserFromEditing(false);
  };

  const handleColumnOrderChange = (params: GridColumnOrderChangeParams) => {
    const oldArrayIndex = params.oldIndex;
    const targetArrayIndex = params.targetIndex;
    setTableColumns(generateOrderedColumn(tableColumns, uniqueClientColumnId, oldArrayIndex, targetArrayIndex));
  };

  const handleTableSave = (idName: string) => {
    saveAppTableState(idName, myTableState);
  };

  return (
    <>
      {preventUserFromEditing ? (
        <CognisaasToast
          open={preventUserFromEditing}
          onClose={closeToast}
          message={'You dont have access to edit this page, contact admin! '}
          type="failure"
        />
      ) : null}
      <StyledBox>
        {data?.columns?.length > 0 ? (
          <StripedDataGrid
            {...data}
            initialState={initialState}
            components={{
              Toolbar: CustomToolbar,
            }}
            onStateChange={(tblState: any) => {
              myTableState = tblState;
            }}
            onColumnVisibilityModelChange={() => {
              handleTableSave('clientListPage');
            }}
            onFilterModelChange={(filter, detail) => {
              handleTableSave('clientListPage');
            }}
            disableSelectionOnClick
            rowThreshold={0}
            // onPageSizeChange={() => {
            //   console.log('model page size');
            //   handleTableSave('clientListPage');
            // }}
            loading={props.status === requestState.loading}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            rowCount={props.count}
            page={props.paginationModel.page}
            pageSize={props.paginationModel.pageSize}
            onPageSizeChange={(pageSize: number) => {
              props.setPaginationModel((prevState: any) => ({ ...prevState, pageSize: pageSize }));
            }}
            onPageChange={(page: number) => {
              props.setPaginationModel((prevState: any) => ({ ...prevState, page: page }));
            }}
            pagination
            paginationMode="server"
            editMode="cell"
            getRowHeight={() => 'auto'}
            sx={{
              '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
              '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '7px' },
              '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
            }}
            experimentalFeatures={{ newEditingApi: true }}
            getRowClassName={(params: any) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'odd' : 'odd')}
            onColumnOrderChange={handleColumnOrderChange}
          />
        ) : (
          <CognisaasLoader />
        )}
      </StyledBox>
    </>
  );
};

export default ClientListTable;
