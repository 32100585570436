import { Paper, Box, Typography, IconButton, Stack, Divider, Avatar, Grid } from '@mui/material';
import { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
// import EditClientDrawer from '../EditClientDrawer';
// import { getIndustryName, getSegmentName, getStageName, getUserUid } from '../../../utils/getUid';
import { useAppSelector } from 'store/hooks';
import { selectUsersOfCompany } from 'store/modules/Common/selector';
import { getUserLogoLinkByUid } from '../../../utils/getUid';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getAllUsers } from 'store/modules/Common/slice';
import { default as AvatarImage } from '../../shared/Avatar/Avatar';
import EditButtonWithDisabledWithTooltip from 'components/shared/RBAC/EditButtonButDisabledWithToolTip';
import CognisaaasTooltip from 'components/shared/CognisaasTooltip/CognisaasTooltip';
import { userPermissionSchema } from 'components/Reports/SideDrawer/types';
import useIndustries from 'components/shared/Hooks/useIndustries';
import useSegments from 'components/shared/Hooks/useSegments';
import useStages from 'components/shared/Hooks/useStages';
// import { selectCustomerDetails } from 'store/modules/Settings/selector';
import {
  label_arr,
  // label_cs_owner,
  label_project,
  // label_sales_owner,
  // label_client,
} from 'components/Settings/Preferences/CustomLabelCookieHelper';
// import { formatCurrency } from 'utils/dataHelpers';
// import ViewDate from 'components/shared/ViewDate/ViewDate';
// import useCustomNames from 'components/shared/CustomNames/useCustomNames';
// import { getUserActiveState, INACTIVE_USER_COLOR } from 'utils/userHelpers';
// import useUserDropDownData from 'components/shared/Hooks/useUserDropDownData';
import { getClientDetail } from 'store/modules/features/client/client';
import { selectClientDetail } from 'store/modules/features/client/selector';
import { useParams } from 'react-router';
import useUsers from '../../../components/shared/Hooks/useUsers';
import EditClientDrawer from '../EditClientDrawer';

const ClientProfile = () => {
  const dispatch = useDispatch();

  const params = useParams();
  const { getSegmentNameById } = useSegments();
  const { getStageNameById } = useStages();
  const { getIndustryNameById } = useIndustries();

  const { users } = useUsers();

  const [clientDrawer, setClientDrawer] = useState<boolean>(false);
  const usersOfCompany = useAppSelector(selectUsersOfCompany);
  // const customerDetail = useAppSelector(selectCustomerDetails).data;
  const clientDetail = useAppSelector(selectClientDetail);
  // const { getCustomNameMappedValue } = useCustomNames();
  // const companyUsersDropDownData = useUserDropDownData('company');

  function stringToHslColor(str: string, s: number, l: number) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    const h = hash % 360;
    return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
  }

  function stringAvatar(name: string) {
    return {
      sx: {
        bgcolor: stringToHslColor(name, 30, 80),
      },
      children: (
        <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#000000' }}>
          {name.split(' ')[0][0]}
          {name.indexOf(' ') >= 0 ? name.split(' ')[1][0] : null}
        </Typography>
      ),
    };
  }

  useEffect(() => {
    if (usersOfCompany.length == 0) {
      dispatch(getAllUsers());
    }
    dispatch(getClientDetail(params.clientId as string));
  }, []);

  useEffect(() => {
    console.log('CLIENTDETAIL', clientDetail);
    console.log('USERS', users);
  }, [clientDetail, users]);

  return (
    <>
      {clientDetail.data && (
        <>
          {Object.entries(clientDetail.data).length > 0 && (
            <Box sx={{ minHeight: '300' }}>
              <Paper
                sx={{ padding: '16px', minHeight: '300', minWidth: '310', background: '#FFFFFF', boxShadow: 'none' }}
              >
                {clientDetail.data?.user_permissions.includes(userPermissionSchema.EDIT) ? (
                  <Stack direction="row" justifyContent={'space-between'} spacing={1}>
                    <Typography variant={'h2'}>Profile Details</Typography>
                    <IconButton
                      onClick={() => setClientDrawer(!clientDrawer)}
                      disableRipple
                      style={{ paddingTop: '0px' }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Stack>
                ) : (
                  clientDetail.data.user_permissions.includes(userPermissionSchema.EDIT) && (
                    <EditButtonWithDisabledWithTooltip></EditButtonWithDisabledWithTooltip>
                  )
                )}

                <Grid container>
                  <Grid item xs={6}>
                    <Stack direction="column">
                      <Typography className="label-text" variant={'subtitle2'}>
                        Journey stage
                      </Typography>
                      <Typography variant={'subtitle1'}>{getStageNameById(clientDetail.data.stage)}</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={6}>
                    <Stack direction="column">
                      <Typography className="label-text" variant={'subtitle2'}>
                        Industry
                      </Typography>
                      <Typography variant={'subtitle1'}>{getIndustryNameById(clientDetail.data.industry)}</Typography>
                    </Stack>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={6}>
                    <Stack direction="column">
                      <Typography className="label-text" variant={'subtitle2'}>
                        Segment
                      </Typography>
                      <Typography variant={'subtitle1'}>{getSegmentNameById(clientDetail.data?.segment)}</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={6} mb={2}>
                    <Stack direction="column">
                      <CognisaaasTooltip
                        title={
                          <Typography variant="subtitle1" style={{ padding: '10px' }}>
                            This is sum of all {label_project} {label_arr}’s
                          </Typography>
                        }
                      >
                        <Stack>
                          <Stack direction="row" alignItems="center">
                            <Typography className="label-text" variant={'subtitle2'} mr={1}>
                              {/* {label_client} {label_arr}  */}
                              Client ARR
                            </Typography>
                            <IconButton size="small">
                              <InfoOutlinedIcon style={{ fontSize: '20px' }} />
                            </IconButton>
                          </Stack>
                          <Typography variant={'subtitle1'}>
                            100
                            {/* {formatCurrency(props.client.client_account_worth, customerDetail?.currency_code)}{' '} */}
                          </Typography>
                        </Stack>
                      </CognisaaasTooltip>
                    </Stack>
                  </Grid>
                </Grid>
                <Divider />
                <Stack direction="row" spacing={2} mt={2}>
                  <Stack direction="column">
                    {getUserLogoLinkByUid(clientDetail.data.sales_owner.id)?.length > 0 ? (
                      <AvatarImage
                        src={getUserLogoLinkByUid(clientDetail.data.sales_owner.id || '')}
                        alt="sales-owner-profile"
                      />
                    ) : (
                      <Avatar
                        style={{ height: '38px', width: '40px', marginTop: '3px' }}
                        {...stringAvatar(clientDetail.data.sales_owner.user_name)}
                      />
                    )}
                  </Stack>
                  <Stack ml={3} pl={0} direction="column">
                    <Typography className="label-text" variant={'subtitle2'}>
                      {/* {label_sales_owner} */}
                      Sales Owner
                    </Typography>
                    <Typography variant="subtitle1">{clientDetail.data.sales_owner.user_name}</Typography>

                    {/* {getUserActiveState(getUserUid(props.client.client_sales_owner), companyUsersDropDownData) ===
                    '0' ? (
                      <Typography style={{ color: INACTIVE_USER_COLOR }} variant="subtitle1">
                        {props.client.client_sales_owner}&nbsp;(Inactive)
                      </Typography>
                    ) : (
                      <Typography variant="subtitle1">{props.client.client_sales_owner}</Typography>
                    )} */}
                  </Stack>
                </Stack>
                <Stack direction="row" spacing={2} mt={2} mb={2}>
                  <Stack direction="column">
                    {getUserLogoLinkByUid(clientDetail.data.sales_owner.id)?.length > 0 ? (
                      <AvatarImage src={getUserLogoLinkByUid('' || '')} alt="client-owner-profile" />
                    ) : (
                      <Avatar
                        style={{ height: '40px', width: '40px', marginTop: '3px' }}
                        {...stringAvatar(clientDetail.data.cs_owner.user_name)}
                      />
                    )}
                  </Stack>
                  <Stack ml={3} pl={0} direction="column">
                    <Typography className="label-text" variant={'subtitle2'}>
                      {/* {label_cs_owner} */}
                      CS Owner
                    </Typography>
                    <Typography variant="subtitle1">{clientDetail.data.sales_owner.user_name}</Typography>
                    {/* {getUserActiveState(getUserUid(props.client.client_account_owner), companyUsersDropDownData) ===
                    '0' ? (
                      <Typography style={{ color: INACTIVE_USER_COLOR }} variant="subtitle1">
                        {props.client.client_account_owner}&nbsp;(Inactive)
                      </Typography>
                    ) : (
                      <Typography variant="subtitle1">{props.client.client_account_owner}</Typography>
                    )} */}
                  </Stack>
                </Stack>
                <Divider />
                <Grid container mt={2}>
                  <Grid item xs={8}>
                    <Stack direction="column">
                      <Typography className="label-text" variant={'subtitle2'}>
                        Last Status Email Sent
                      </Typography>
                      <Typography variant="subtitle1" sx={{ fontWeight: 500, fontSize: '12px' }}>
                        12 June 2022
                        {/* {props.client.last_status_report_sent_time && (
                          <ViewDate date={props.client.last_status_report_sent_time} />
                        )}
                        {props.client.last_status_report_sent_time == null ? '-' : null} */}
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </Paper>
              {clientDrawer && (
                <EditClientDrawer
                  clientInfo={clientDetail.data}
                  onClose={() => setClientDrawer(!clientDrawer)}
                  drawerState={clientDrawer}
                />
              )}
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default ClientProfile;
