import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './CognisaasDatePicker.css';
import { IconButton } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const range = (start: number, end: number) => {
  const arr = [];
  for (let i = 0; i <= end - start + 1; i++) {
    arr.push(start + i);
  }
  return arr;
};

const CognisaasDatePicker = (props: any) => {
  const years = range(1990, new Date().getFullYear() + 1);
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  return (
    <DatePicker
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }: any) => (
        <div
          style={{
            margin: 10,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <IconButton color="primary" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
            <ArrowBackIosIcon sx={{ height: '12px' }} fontSize="small" />
          </IconButton>
          <select
            value={date.getFullYear()}
            onChange={(e: any) => {
              changeYear(e.target.value);
            }}
            className="datepicker__select"
          >
            {years.map((option, index) => (
              <option className="" key={index} value={option}>
                {option}
              </option>
            ))}
          </select>

          <select
            value={months[date.getMonth()]}
            onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
            className="datepicker__select"
          >
            {months.map((option) => (
              <option className="" key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <IconButton color="primary" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
            <ArrowForwardIosIcon sx={{ height: '12px' }} fontSize="small" />
          </IconButton>
        </div>
      )}
      selected={props.startDate}
      dateFormat="dd/MM/yyyy"
      onChange={(date: Date) => props.setStartDate(date)}
      {...props}
      placeholderText="dd/mm/yyyy"
    />
  );
};

export default CognisaasDatePicker;
