import store from '../store/index';
import Cookies from 'universal-cookie';
import { useAppDispatch } from 'store/hooks';
import { getCustomerDetail } from 'store/modules/Common/slice';

const cookies = new Cookies();

// import { journeyStageModel, industryModel } from '../api/responseInterface';

export const getStageId = (name: string) => {
  const stages = store.getState().newClientListState.stages.data;
  const found: any = stages.find((stage: any) => stage['name'] == name);
  if (typeof found === 'undefined') {
    return '';
  } else {
    return found['id'];
  }
};

export const getStageUid = (name: string) => {
  const stages = store.getState().newClientListState.stages.data;
  const found: any = stages.find((stage: any) => stage['name'] == name);
  if (typeof found === 'undefined') {
    return '';
  } else {
    return found['id'];
  }
};

export const getStageNames = () => {
  const stages = store.getState().filterState.stageFilters;
  const stageNames = stages.map((stage: any) => stage.stage_journerystage);
  return stageNames;
};

export const getUserDetail = () => {
  const detail = store.getState().settingState.userDetails;
  return detail.data.result;
};

export const getUserUid = (userName: string) => {
  const users = store.getState().commonState.getAllUsers;
  const found: any = users.find((user) => user['user_username'] == userName);
  if (userName == 'Not Assigned') {
    return '15243';
  } else if (typeof found === 'undefined') {
    return userName;
  } else {
    return found['user_uid'];
  }
};

export const getUserLogoLinkByUid = (user_uid: string) => {
  // const users = store.getState().commonState.getAllUsers;
  // const user = users.find((user) => user.user_uid == user_uid);
  // if (user === undefined) return '';
  // if (user.user_profile_pic_link) {
  //   return user.user_profile_pic_link;
  // } else {
  //   return '';
  // }
  console.log(user_uid);
  return '';
};

// export const getContactNameByContactUid = (uid: string) => {
//   const contacts = store.getState().clientListState.clientContacts;
//   const found: any = contacts.find((user) => user['contact_uid'] == uid);
//   if (uid == '15243') {
//     return 'Not Assigned';
//   } else if (typeof found === 'undefined') {
//     return '';
//   } else {
//     return found['contact_name'];
//   }
// };

export const getContactUidByContactName = (name: string) => {
  const contacts = store.getState().clientListState.clientContacts;
  const found: any = contacts.find((user) => user['contact_name'] == name);
  if (name == 'Not Assigned') {
    return '15243';
  } else if (typeof found === 'undefined') {
    return '';
  } else {
    return found['contact_uid'];
  }
};

export const getContactUidByContactEmail = (email: string) => {
  const contacts = store.getState().clientListState.clientContacts;
  const found: any = contacts.find((user) => user['contact_email'] == email);
  if (typeof found === 'undefined') {
    return '';
  } else {
    return found['contact_uid'];
  }
};

export const getUserNameByUserUid = (uid: string) => {
  const users = store.getState().commonState.getAllUsers;
  const found: any = users.find((user) => user['user_uid'] == uid);

  if (uid === '15243' || uid === '') {
    return 'Not Assigned';
  } else if (typeof found === 'undefined') {
    return '';
  } else {
    return found['user_username'];
  }
};

const updateCommonCustomerDetail = async () => {
  const dispatch = useAppDispatch();
  await dispatch(getCustomerDetail());
};

export const companyHolidays = (): any[] => {
  const customerDetail = store.getState().commonState.getCustomerDetail;
  if (!customerDetail) {
    updateCommonCustomerDetail();
  }
  if (!customerDetail.customer_holidays) {
    return [];
  } else {
    let holidays = customerDetail.customer_holidays;
    holidays = JSON.parse(holidays).map((day: { detail: string; date: string }) => new Date(day.date).getTime());
    return holidays;
  }
};

export const companyHolidaysWithDetail = (): any[] => {
  const customerDetail = store.getState().commonState.getCustomerDetail;
  if (!customerDetail.customer_holidays) {
    return [];
  } else {
    let holidays = customerDetail.customer_holidays;
    holidays = JSON.parse(holidays);
    return holidays;
  }
};

export const getUserEmailByUserUid = (uid: string) => {
  const users = store.getState().filterState.companyUsers;
  const found: any = users.find((user) => user['user_uid'] == uid);
  if (typeof found === 'undefined') {
    return '';
  } else {
    return found['user_email'];
  }
};

export const getContactNameByContactUid = (uid: string) => {
  const users = store.getState().clientListState.clientContacts;
  // const users = store.getState().commonState.getAllClientContacts;
  const found: any = users.find((contact) => contact['contact_uid'] == uid);
  if (uid == '15243') {
    return 'Not Assigned';
  } else if (typeof found === 'undefined') {
    return '';
  } else {
    return found['contact_name'];
  }
};

export const customerPortalGetContactNameByContactUid = (uid: string) => {
  const users = store.getState().customerPortal.allClientUsersUsers;
  const found: any = users?.find((contact: any) => contact['contact_uid'] == uid);
  if (uid == '15243') {
    return 'Not Assigned';
  } else if (typeof found === 'undefined') {
    return '';
  } else {
    return found['contact_name'];
  }
};

export const customerPortalGetUserNameByUserUid = (uid: string) => {
  const users = store.getState().customerPortal.allCompanyUsers;
  const found: any = users.find((user: any) => user['user_uid'] == uid);

  if (uid == '15243') {
    return 'Not Assigned';
  } else if (typeof found === 'undefined') {
    return '';
  } else {
    return found['user_username'];
  }
};
export const getIndustryId = (name: string) => {
  const industries = store.getState().newClientListState.industries.data;
  console.log('INDUSTRIS', name, industries);
  const found: any = industries.find((industry: any) => {
    console.log(industry.name, name);
    return industry.name === name;
  });
  console.log({ found });
  if (found === undefined) {
    return '';
  } else {
    return found['id'];
  }
};

export const getIndustryUid = (name: string) => {
  const industries = store.getState().newClientListState.industries.data;
  const found: any = industries.find((industry: any) => industry.name == name);
  if (found === undefined) {
    return '';
  } else {
    return found['id'];
  }
};

export const getIndustryName = (id: string) => {
  const industries = store.getState().newClientListState.industries.data;
  const found: any = industries.find((industry: any) => industry['id'] == id);
  if (found === undefined) {
    return id;
  } else {
    return found['name'];
  }
};

export const getStageName = (id: string) => {
  const stages = store.getState().newClientListState.stages.data;
  const found: any = stages.find((stage: any) => stage['id'] === id);
  if (found === undefined) {
    return id;
  } else {
    return found['name'];
  }
};

export const getSegmentId = (name: string) => {
  const segments = store.getState().newClientListState.stages.data;
  const found: any = segments.find((segment: any) => segment['name'] === name);
  if (typeof found === 'undefined') {
    return '';
  } else {
    return found['id'];
  }
};

export const getSegmentUid = (name: string) => {
  const segments = store.getState().newClientListState.stages.data;
  const found: any = segments.find((segment: any) => segment['name'] === name);
  if (typeof found === 'undefined') {
    return '';
  } else {
    return found['id'];
  }
};

export const getSegmentName = (id: string) => {
  const segments = store.getState().newClientListState.segments.data;
  const found: any = segments.find((segment: any) => segment['id'] == id);
  if (typeof found === 'undefined') {
    return id;
  } else {
    return found['name'];
  }
};

export const getCustomerUid = () => cookies.get('cogni_customer_uid') as string;

export const getCustomerCompanyName = () => cookies.get('customerCompanyName') as string;

export const getLoggedInUserUid = () => cookies.get('cogni_user_uid') as string;

export const getLoggedInUserName = () => cookies.get('user_name') as string;

export const getLoggedInUserEmail = () => cookies.get('cogni_user_email') as string;

export const getCustomerUrlName = () => cookies.get('customer_url_name') as string;

export const getCustomerSubDomain = () => cookies.get('customer_subdomain') as string;

export const isCustomerPortal = () => cookies.get('isCustomerPortal') as string;

export const isRbacEnabled = () => {
  const rbac_enabled = cookies.get('rbac_enabled') as string;

  if (rbac_enabled == 'true') {
    return true;
  } else {
    return false;
  }
};

export const isSuperUser = () => {
  const user_type = cookies.get('user_type') as string;
  return user_type === 'S';
};
