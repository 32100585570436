import { Paper, Stack, Typography } from '@mui/material';

interface Props {
  productName: string;
  NumOfReq: number;
  isActive: boolean;
  clickHandler?: () => void;
}

const MappedProductCard = (props: Props) => {
  return (
    <Stack onClick={props?.clickHandler}>
      <Paper
        // onClick={() => onProductClick(product)}
        elevation={0}
        sx={{
          marginBottom: '8px',
          padding: '12px',
          border: props.isActive ? '1px solid #2D60F6' : '1px solid #E5E5E5',
          borderRadius: '4px',
          boxShadow: '0px 4px 26px rgba(0, 0, 0, 0.05)',
        }}
      >
        <Typography variant="subtitle1">{props.productName}</Typography>
        <Typography variant="subtitle2" className="label-text">
          {props.NumOfReq} requirements
        </Typography>
      </Paper>
    </Stack>
  );
};

export default MappedProductCard;
