import { apis } from '../axiosInstance';
import { attachmentRoutes } from '../route';

const addAttachment = (oneAttachment: any) => {
  const formData = new FormData();
  const metaData = oneAttachment.metaDataForHeaders;

  // attching file
  formData.append('file_' + oneAttachment.id, oneAttachment.file);
  // formData.append()
  formData.append('metadata', JSON.stringify(metaData));
  return apis.phpAxiosInstance.post(attachmentRoutes.addAttachment(), formData, { headers: { JWT: 'true' } });
};

const addAttachmentToDbWithCustomUrl = (apiUrl: string, oneAttachment: any) => {
  const formData = new FormData();
  const metaData = oneAttachment.metaDataForHeaders;

    // attching file
  if (apiUrl === 'updateAttachment' || apiUrl === 'updateTemplateDocumentAttachment') {
    formData.append('file_1', oneAttachment.file);
  } else {
    formData.append('file_' + oneAttachment.id, oneAttachment.file);
  }

  // formData.append()
  formData.append('metadata', JSON.stringify(metaData));
  return apis.phpAxiosInstance.post(apiUrl, formData, { headers: { JWT: 'true' } });
};

const downloadAttachmentWithCustomApiUrl = (serverGetApiUrlWithQueryString: string) => {
  return apis.phpAxiosInstance.get(serverGetApiUrlWithQueryString, { headers: { JWT: 'true' } });
};

const getOldAttachmentVersions = (serverGetOldAttachmentVersions: string) => {
  return apis.phpAxiosInstance.get(serverGetOldAttachmentVersions, { headers: { JWT: 'true' } });
};

const getAttachmentsWithCustomApiUrl = (serverGetApiUrlWithQueryString: string) => {
  return apis.phpAxiosInstance.get(serverGetApiUrlWithQueryString, { headers: { JWT: 'true' } });
};

const deleteAttachmentWithCustomApiAndPayload = (serverDeleteApi: string, serverPayload: Record<string, any>) => {
  return apis.phpAxiosInstance.post(serverDeleteApi, serverPayload, { headers: { JWT: 'true' } });
};

export const attachmentAPI = {
  addAttachment,
  addAttachmentToDbWithCustomUrl,
  downloadAttachmentWithCustomApiUrl,
  getAttachmentsWithCustomApiUrl,
  deleteAttachmentWithCustomApiAndPayload,
  getOldAttachmentVersions,
};
