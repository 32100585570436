import {
  Box,
  Button,
  // Collapse,
  Drawer,
  IconButton,
  // IconButton,
  Menu,
  MenuItem,
  // Stack,
  styled,
  // TextField,
  Typography,
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import { useEffect, useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../store/hooks';
import {
  selectCustomerDetailsState,
  selectPrAndReqDataState,
  selectSyncingRequirement,
} from '../../store/modules/ProductRequirements/ProductDetails/selector';
import {
  fetchProductAndRequirements,
  deleteRequirementById,
  removeThisRequirementFromStore,
  getCustomerDetail,
  syncProductAndRequirementToJira,
  getAllRequriementCommentsIncludingJira,
} from '../../store/modules/ProductRequirements/ProductDetails/slice';
import {
  DataGrid,
  GridRenderCellParams,
  // useGridApiRef,
  // useKeepGroupedColumnsHidden,
} from '@mui/x-data-grid';
import Cookies from 'universal-cookie';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CognisaasLoader from '../shared/CognisaasLoader/CognisaasLoader';
import Styles from './ProductRequirement.module.css';
import CognisaasTabs from '../shared/CognisaasTabs/index';
import RequirementsCommentTab from './RequirementTabs/RequirementsCommentTab';
import RequirementsAttachmentTab from './RequirementTabs/RequirementsAttachmentTab';
import RequirementsDetailsTab from './RequirementTabs/RequirementsDetailsTab';
import { isNull, isUndefined } from '../../utils/dataHelpers';
import { ReactComponent as NoRecordsIcon } from '../../assets/svgs/no_records_mini.svg';
import ProductCard from './ProductCard/ProductCard';
import { productRequirementAPI } from 'api/modules/productRequirement';
import ViewProductComments from './RequirementTabs/ViewProductComments';
import { requestState } from 'store/modules/types';
import DeleteModal from 'components/shared/CognisaasModal/DeleteModal';
import ViewDate from 'components/shared/ViewDate/ViewDate';
import { getCustomerDetail as getAndSetCustomerDetail } from 'store/modules/Common/slice';

const cookies = new Cookies();
interface PRProps {
  setProdReqView: any;
  setProductUidDetails: (data: any) => void;
}

const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  '& .ant-empty-img-1': {
    fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
  },
  '& .ant-empty-img-2': {
    fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
  },
  '& .ant-empty-img-3': {
    fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
  },
  '& .ant-empty-img-4': {
    fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
  },
  '& .ant-empty-img-5': {
    fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
    fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
  },
}));

const ProductRequirement = (props: PRProps) => {
  const dispatch = useDispatch();
  const customer_uid = cookies.get('cogni_customer_uid') as string;
  const productAndReqState = useAppSelector(selectPrAndReqDataState);
  const customerDetailState = useAppSelector(selectCustomerDetailsState);
  const [selectedProductIndex, setSelectedProductIndex] = useState(0);
  const [isRequirementDrawerToggled, setIsRequirementDrawerToggled] = useState(false);
  const [requirementTabIndex, setIsRequirementTabIndex] = useState<number | undefined>(undefined);
  const [toggledRequirementDetails, setToggledRequirementDetails] = useState({});
  const [toggledRequirementIndex, setToggledRequirementIndex] = useState<number>(-1);
  const [dataForTable, setDataForTable] = useState<any>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [commentsRecieved, setCommentsRecieved] = useState<any>([]);
  const [showOnboardNewAccount, setShowOnboardNewAccount] = useState(false);
  const [paramsHelper, setParamsHelper] = useState<any>();
  const syncingToJira = useAppSelector(selectSyncingRequirement);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [currentRequirementRowToDelete, setCurrentRequirementRowToDelete] = useState<any>({});

  console.log(showOnboardNewAccount);

  useEffect(() => {
    console.log(dataForTable.length);
    if (dataForTable?.length <= 0) setShowOnboardNewAccount(true);
    else setShowOnboardNewAccount(false);
  }, [dataForTable]);

  useEffect(() => {
    if (
      customerDetailState.status == requestState.success &&
      customerDetailState.data.customer_jira_token &&
      customerDetailState.data.customer_jira_token.length > 0 &&
      isRequirementDrawerToggled == false
    ) {
      console.log('customer state', customerDetailState);
      dispatch(syncProductAndRequirementToJira());
    } else if (isRequirementDrawerToggled == false) {
      dispatch(fetchProductAndRequirements());
    }
  }, [customerDetailState, isRequirementDrawerToggled]);

  const getRequirementMetaData = async (feature_uid: string) => {
    const {
      data: { result: commentsRecieved },
    } = await productRequirementAPI.getRequirementComments(feature_uid);
    if (commentsRecieved == null) {
      setCommentsRecieved([]);
    } else {
      setCommentsRecieved(commentsRecieved);
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    dispatch(getCustomerDetail());
    dispatch(getAndSetCustomerDetail());
  }, []);

  useEffect(() => {
    if (syncingToJira.data == 1) {
      dispatch(fetchProductAndRequirements());
    }
  }, [syncingToJira.data]);

  useEffect(() => {
    if (productAndReqState.status === 'success') {
      if (!isNull(productAndReqState.data) && isUndefined(selectedProductIndex) && productAndReqState.data.length > 0) {
        props.setProductUidDetails(productAndReqState?.data[0]);
        setSelectedProductIndex(0);
      } else {
        if (productAndReqState.data.length > 0) {
          props.setProductUidDetails(productAndReqState?.data[selectedProductIndex]);
        }
      }

      productAndReqState.data.forEach((product) => {
        product.feature.forEach((requirement: any, index: number) => {
          if (requirement?.feature_description?.includes('+cgTem')) {
            deleteThisRequirementById(requirement.customer_uid, requirement.feature_uid, index);
          }
        });
      });
    } else if (productAndReqState.status === 'failed') {
      alert(productAndReqState.error);
    }
  }, [productAndReqState]);

  const requirementCardClicked = async (requirementDetails: any, requirementIndex: number) => {
    setIsRequirementDrawerToggled(true);
    setToggledRequirementDetails(requirementDetails);
    setToggledRequirementIndex(requirementIndex);
    if (requirementDetails.feature_issue_id && requirementDetails.feature_issue_id?.length > 0) {
      await dispatch(
        getAllRequriementCommentsIncludingJira({
          feature_uid: requirementDetails.feature_uid,
          jira_issue_id: requirementDetails.feature_issue_id,
        })
      );
    }
    getRequirementMetaData(requirementDetails?.feature_uid);
  };

  const deleteThisRequirementById = async (customerUid: string, featureUid: string, featureIndex: number) => {
    await dispatch(deleteRequirementById({ customer_uid: customerUid, feature_uid: featureUid }));
    await dispatch(removeThisRequirementFromStore({ customerUid, featureUid, featureIndex }));
    handleClose();
  };

  const closeRequirementDrawer = () => {
    setIsRequirementDrawerToggled(false);
    switchRequirementDrawerTabs('e', 0);
  };

  const switchRequirementDrawerTabs = (event: any, tabIndex: number) => {
    setIsRequirementTabIndex(tabIndex);
  };

  const handleProductCardClick = (productInfo: any, productIndex: number) => {
    props.setProductUidDetails(productAndReqState.data[productIndex]);
    setSelectedProductIndex(productIndex);
  };

  const handleDeleteReq = (event: any, params: any) => {
    event.preventDefault();
    event.stopPropagation();
    setCurrentRequirementRowToDelete(params);
    setOpenDeleteModal(true);
  };

  const deleteSelectedRequirement = async () => {
    deleteThisRequirementById(
      customer_uid,
      currentRequirementRowToDelete.row.feature_uid,
      currentRequirementRowToDelete.row.feature_id
    );
    await dispatch(fetchProductAndRequirements());
  };

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <NoRecordsIcon />
        <Box sx={{ mt: 1 }}>No Rows</Box>
      </StyledGridOverlay>
    );
  }

  const columns = [
    {
      field: 'feature_uid',
      hide: true,
    },
    {
      field: 'feature_description',
      headerName: 'Requirement Name',
      flex: 4,
    },
    {
      field: 'feature_readinessstate_status',
      headerName: 'Requirement Status',
      flex: 4,
      renderCell: (params: GridRenderCellParams) => {
        if (params.row.feature_readinessstate_status == 'amber')
          return (
            <>
              <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                <CircleIcon fontSize="small" sx={{ color: '#EFA958' }} />
                <span>&nbsp;&nbsp;In Progress</span>
              </span>
            </>
          );

        if (params.row.feature_readinessstate_status == 'green')
          return (
            <>
              <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                <CircleIcon fontSize="small" sx={{ color: '#4F9F52' }} />
                <span>&nbsp;&nbsp;Released</span>
              </span>
            </>
          );

        if (params.row.feature_readinessstate_status == 'red')
          return (
            <>
              <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                <CircleIcon fontSize="small" sx={{ color: '#E75B5C' }} />
                <span>&nbsp;&nbsp;New Requirement</span>
              </span>
            </>
          );
      },
    },
    {
      field: 'feature_estimateddeliverydate',
      headerName: 'ETA for Release',
      flex: 4,
      renderCell: (params: GridRenderCellParams) => {
        return <ViewDate date={params.row.feature_estimateddeliverydate} />;
      },
    },
    {
      field: 'feature_id',
      headerName: '',
      flex: 1,
      width: 10,
      disableClickEventBubbling: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <>
            <Box>
              <IconButton
                onClick={(event) => {
                  handleClick(event);
                  setParamsHelper(params);
                }}
                disabled={
                  customerDetailState.status === 'success'
                    ? customerDetailState.data.customer_jira_fetch_mode &&
                      customerDetailState.data.customer_jira_fetch_mode === 'pull'
                      ? true
                      : false
                    : false
                }
              >
                <MoreVertIcon />
              </IconButton>
            </Box>
          </>
        );
      },
    },
  ];

  const jiraColumns = [...columns];
  jiraColumns.splice(columns.length - 1, 0, {
    field: '__',
    headerName: 'Link To Jira',
    flex: 4,
    renderCell: (params: GridRenderCellParams) => {
      const handleLinkToJiraButtonClick = () => {
        if (
          customerDetailState.data.customer_jira_api_url &&
          customerDetailState.data.customer_jira_api_url.length > 0
        ) {
          window.open(`${customerDetailState.data.customer_jira_api_url}browse/${params.row.jira_issue_key}`, '_blank');
        }
      };

      return (
        <>
          <Typography className="link-text cognisaas-pointer" variant="subtitle1" onClick={handleLinkToJiraButtonClick}>
            Link
          </Typography>
        </>
      );
    },
  });

  const tempRows: any[] = [];

  useEffect(() => {
    if (productAndReqState.status === 'idle') return;
    productAndReqState.status === 'success' &&
      productAndReqState.data?.map((ele) => {
        console.log(ele, 'dat');
        ele?.feature?.map((req: any) => {
          tempRows.push({ ...req, products: req.product_name });
        });
      });
    console.log(tempRows, 'tmp rwss');
  }, [productAndReqState.status]);

  useEffect(() => {
    if (tempRows?.length > 0) setDataForTable(tempRows);
  }, [tempRows]);

  return (
    <div className={Styles.ProductRequirement__container}>
      {productAndReqState.status == 'loading' ? (
        <CognisaasLoader />
      ) : (
        <>
          {/* header */}
          <Menu anchorEl={anchorEl} open={open} onClose={handleClose} sx={{ border: 'none' }}>
            <MenuItem onClick={(e) => handleDeleteReq(e, paramsHelper)}>
              <Typography variant="inherit">Delete Requirement</Typography>
            </MenuItem>
          </Menu>
          <div className={Styles.ProductRequirement__headerWrapper}>
            <Typography variant="h1" sx={{ fontWeight: 500 }}>
              Product requirements
            </Typography>
            <Button
              variant="outlined"
              sx={{ width: '130px' }}
              disabled={
                customerDetailState.status === 'success'
                  ? customerDetailState.data.customer_jira_user.length > 0
                    ? true
                    : false
                  : false
              }
              fullWidth={true}
              onClick={() => props.setProdReqView('add-new-product')}
            >
              Add Product
            </Button>
          </div>

          {/* when there is no product */}
          {productAndReqState.data.length === 0 && (
            <div className={Styles.ProductRequirement__noProductFoundContainer}>
              <Typography variant="subtitle1" className="label-text" sx={{ marginBottom: '20px' }}>
                {customerDetailState.status === 'success'
                  ? customerDetailState.data.customer_jira_user.length > 0
                    ? 'Configure a jira project in settings tab.'
                    : 'No products added, add one now!'
                  : 'No products added, add one now!'}
              </Typography>

              <NoRecordsIcon />
            </div>
          )}

          {isNull(productAndReqState.data) ? (
            ''
          ) : (
            <>
              {productAndReqState.status == 'success' &&
                productAndReqState.data.map((productInfo, index) => {
                  return (
                    <Box key={index}>
                      <Accordion sx={{ backgroundColor: '#FFFFFF' }} defaultExpanded={index === 0 ? true : false}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          sx={{ backgroundColor: '#FFFFFF', borderBottom: '1px solid #dddddd', borderRadius: '4px' }}
                        >
                          <ProductCard
                            // key={index}
                            productInfo={productInfo}
                            index={index}
                            selectedProductIndex={selectedProductIndex}
                            handleProductCardClick={handleProductCardClick}
                            setProdReqView={props.setProdReqView}
                            disabled={productAndReqState.data.length === 0}
                          />
                        </AccordionSummary>
                        <AccordionDetails>
                          <div style={{ height: '100%', width: '100%' }}>
                            <DataGrid
                              components={{
                                NoRowsOverlay: CustomNoRowsOverlay,
                              }}
                              autoHeight
                              // {...data}
                              rows={dataForTable?.filter((req: any) => {
                                console.log(req.product_uid, productInfo.product_uid);
                                const tempreq =
                                  req.feature_description.split('+') &&
                                  req.feature_description.split('+')[1] == 'cgTem';
                                return req.product_uid == productInfo.product_uid && !tempreq;
                              })}
                              columns={
                                customerDetailState.status === 'success' &&
                                customerDetailState.data.customer_jira_user.length > 0
                                  ? jiraColumns
                                  : columns
                              }
                              getRowId={(row) => row.feature_uid}
                              rowHeight={60}
                              hideFooter={true}
                              onRowClick={(params: any) => {
                                console.log(params);
                                requirementCardClicked(params.row, index);
                              }}
                            />
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                  );
                })}
            </>
          )}
          <Drawer
            PaperProps={{
              sx: { width: '570px' },
            }}
            anchor={'right'}
            open={isRequirementDrawerToggled}
            onClose={closeRequirementDrawer}
          >
            {/* drawer main container */}
            <div className={Styles.ProductRequirement__reqDrawer_container}>
              {/* header tabs */}
              <CognisaasTabs
                handleTabSwitch={switchRequirementDrawerTabs}
                tabs={['Details', 'Attachment', 'Comments']}
              />
              {/* drawer tabs and body */}
              {requirementTabIndex === 2 ? (
                <div style={{ marginTop: '10px' }}>
                  {/* comments tab */}
                  <RequirementsCommentTab
                    data={toggledRequirementDetails}
                    type="Save"
                    getRequirementMetaData={getRequirementMetaData}
                  />
                  {commentsRecieved?.length > 0 ? (
                    <ViewProductComments
                      commentsData={commentsRecieved}
                      getRequirementMetaData={getRequirementMetaData}
                      requirementData={toggledRequirementDetails}
                    />
                  ) : null}
                </div>
              ) : requirementTabIndex === 1 ? (
                <div>
                  {/* attachments tab */}
                  <RequirementsAttachmentTab data={toggledRequirementDetails} />
                </div>
              ) : (
                <div>
                  {/* details tab */}
                  <RequirementsDetailsTab
                    selectedProductIndex={selectedProductIndex}
                    selectedRequirementIndex={toggledRequirementIndex}
                    data={toggledRequirementDetails}
                  />
                </div>
              )}
              <div></div>
            </div>
          </Drawer>
        </>
      )}
      <DeleteModal
        isOpen={openDeleteModal}
        setIsOpen={setOpenDeleteModal}
        message={'Are you sure you want to delete this requirement'}
        onDelete={deleteSelectedRequirement}
      />
    </div>
  );
};

export default ProductRequirement;
