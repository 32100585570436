import { useState } from 'react';
import styled from 'styled-components';
import { Avatar, Typography, Stack, Box } from '@mui/material';
import formatDistance from 'date-fns/formatDistance';

import CognissasCustomMenu from '../../../shared/CognisaasCustomMenu/CognisaasCustomMenu';

import Comment from './Comment';
import {
  // getLoggedInUserUid, 
  getUserLogoLinkByUid
} from 'utils/getUid';
import { default as AvatarImage } from '../../../shared/Avatar/Avatar';

import {
  getProjectTaskComments,
  getProjectClientTaskComments,
  getUsecaseTaskComments,
  getUsecaseClientTaskComments,
  deleteTaskComment,
  deleteCommentFromJira,
  deleteClientTaskComment,
  markingPublicOrInternalTaskCommentSlice,
} from '../../../../store/modules/Task/slice';
import DeleteModal from 'components/shared/CognisaasModal/DeleteModal';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectCustomerDetail } from 'store/modules/Common/selector';
import Cookies from 'universal-cookie';
import CognisaaasTooltip from 'components/shared/CognisaasTooltip/CognisaasTooltip';
import VisibilityIcon  from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
const cookies = new Cookies();
interface CommentViewProps {
  commentsData: any;
  taskDetail: any;
  clientDetail: any;
  isCustomerPortal: any;
  setAlertDetails?: any;
}

const Wrapper = styled.div`
  width: 100%;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: 40px;
    right: 37px;
    bottom: -6px;
    height: 1px;
    width: 100%;
    background-color: #e5e5e5;
  }
`;

const Frame = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 10px 14px;
  margin-top: 17px;

  &:hover {
    background: #f5f5f7;
  }
`;

const CommentView = (props: CommentViewProps) => {
  const [commentId, setCommentId] = useState<string>('');
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedCommentForDeletion, setSelectedCommentForDeletion] = useState<Record<string, any>>({});
  const customerDetail = useAppSelector(selectCustomerDetail);
  const dispatch = useAppDispatch();
  function stringToHslColor(str: string, s: number, l: number) {
    let hash = 0;

    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    const h = hash % 360;
    return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
  }

  function stringAvatar(name: string) {

    return {
      sx: {
        bgcolor: stringToHslColor(name, 30, 80),
      },
      children: (
        <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#000000' }}>
          {name.split(' ')[0][0]}
          {name.indexOf(' ') >= 0 ? name.split(' ')[1][0] : null}
        </Typography>
      ),
    };
  }

  const generateCommentUi = (comment: string) => {
    const splitForUsers = comment.split('@[');
    return splitForUsers.map((item, index) => {
      if (index === 0) {
        return (
          <span key={index} style={{ whiteSpace: 'pre-line' }}>
            {item}
          </span>
        );
      } else {
        // index more than 0 something like this expected => vivek](customer:324234) great
        const strContainingUser = item;
        const arrContainingUser = strContainingUser.split('](');
        const username = arrContainingUser[0];
        const restStrArr = arrContainingUser[1].split(')');
        const customerUid = restStrArr[0];
        const restString = [...restStrArr.filter((item, index) => index > 0)].join('');
        return (
          <span key={index} style={{ whiteSpace: 'pre-line' }}>
            &nbsp;
            <span
              style={{
                backgroundColor: 'rgb(45, 96, 246, 0.1)',
                color: '#2D60F6',
                padding: '2px 10px',
                borderRadius: '33px',
                fontSize: '13px',
                cursor: 'pointer',
              }}
              id={customerUid}
            >
              {username}
            </span>
            {restString}
          </span>
        );
      }
    });
  };

  const handleDelete = async (comment: Record<string, any>) => {
    if (
      props.taskDetail.jira_issue_id &&
      props.taskDetail.jira_issue_id?.length > 0 &&
      props.taskDetail.is_team_task === '1' &&
      props.taskDetail.jira_ind === '1'
    ) {
      const key = btoa(btoa(`${customerDetail.customer_jira_user}:${customerDetail.customer_jira_token}`));
      const payload = {
        comment_uid: comment.comment_uid,
        customer_uid: cookies.get('cogni_customer_uid'),
        jira_comment_id: comment.jira_comment_id,
        jira_issue_id: props.taskDetail.jira_issue_id,
        key: key,
      };
      await dispatch(deleteCommentFromJira(payload));
    }
    if (props.taskDetail.is_team_task == 1) {
      await dispatch(deleteTaskComment({ comment_uid: comment.comment_uid }));
    } else {
      await dispatch(deleteClientTaskComment({ comment_uid: comment.comment_uid }));
    }

    if (props.taskDetail.project_uid && props.taskDetail.case_uid) {
      if (props.taskDetail.is_team_task == 1) {
        await dispatch(getUsecaseTaskComments(props.taskDetail.task_uid));
      } else {
        await dispatch(getUsecaseClientTaskComments(props.taskDetail.task_uid));
      }
    } else {
      if (props.taskDetail.is_team_task == 1) {
        await dispatch(getProjectTaskComments(props.taskDetail.task_uid));
      } else {
        await dispatch(getProjectClientTaskComments(props.taskDetail.task_uid));
      }
    }
  };

  const handleDeleteModal = (comment: Record<string, any>) => {
    setOpenDeleteModal(true);
    setSelectedCommentForDeletion(comment);
  };
  const handleMarkingPublicOrInternalModal = async (comment: Record<string, any>) => {
    const details: Record<string, any> = {
      comment_uid: comment.comment_uid,
      share_with_client: comment.share_with_client == 0 ? 1 : 0,
      is_team_task: props.taskDetail.is_team_task == 1 ? true : false,
      type: props.taskDetail.project_uid && props.taskDetail.case_uid ? 'usecase' : 'project'

    };
    dispatch(markingPublicOrInternalTaskCommentSlice(details)).unwrap().then(async (result: any) => {
      if (result.status == 1) {
        if (props.taskDetail.project_uid && props.taskDetail.case_uid) {
          if (props.taskDetail.is_team_task == 1) {
            await dispatch(getUsecaseTaskComments(props.taskDetail.task_uid));
          } else {
            await dispatch(getUsecaseClientTaskComments(props.taskDetail.task_uid));
          }
        } else {
          if (props.taskDetail.is_team_task == 1) {
            await dispatch(getProjectTaskComments(props.taskDetail.task_uid));
          } else {
            await dispatch(getProjectClientTaskComments(props.taskDetail.task_uid));
          }
        }
        props.setAlertDetails({
          isVisible: true,
          type: 'success',
          message: result.message,
        });
      } else {
        props.setAlertDetails({
          isVisible: true,
          type: 'failure',
          message: result.message,
        });
      }
    });
  };

  return (
    <>

      {[...props.commentsData].reverse().map((comment: any, index: number) => {
        return (
          <Wrapper key={comment.comment_uid || index}>
            <Frame>
              <Stack spacing={2} direction={'row'} sx={{ minHeight: '10px', wordBreak: 'break-all', width: '80%' }}>
                <Stack ml={2} direction="row">
                  {getUserLogoLinkByUid(comment.user_uid).length > 0 ? (
                    <AvatarImage src={getUserLogoLinkByUid(comment.user_uid)} alt="user profile" />
                  ) : (
                    <Avatar
                      style={{ height: '40px', width: '40px', marginTop: '3px' }}
                      {...stringAvatar(comment.user_username)}
                    />
                  )}
                </Stack>

                <Stack
                  direction={'column'}
                  sx={{ marginTop: '5px' }}
                  style={{ width: '100%', fontFamily: 'Noto sans' }}
                >
                  <Typography variant="subtitle1">{comment.user_username}</Typography>
                  {commentId === comment.comment_uid ? (
                    <Comment
                      taskDetail={props.taskDetail}
                      clientDetail={props.clientDetail}
                      setCommentId={setCommentId}
                      commentDetail={comment}
                      type="Edit"
                    />
                  ) : (
                    <Stack className="label-text" direction={'row'}>
                      {generateCommentUi(comment.comment)}
                    </Stack>
                  )}
                </Stack>
              </Stack>
              <Stack direction={'column'} m={2}>
                <Stack direction={'row'}>
                  <Typography className="label-text" variant="subtitle1" style={{ fontSize: '12px' }}>
                    {formatDistance(new Date(comment.created_on.replace(/-/g, '/')), new Date(), {
                      includeSeconds: true,
                    })}{' '}
                    ago
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction={'column'} m={1}>
                <Stack direction={'row'}>
                    {props.isCustomerPortal==true?<></>:<CognisaaasTooltip
                      title={
                        <Box mb={1} sx={{ height: '12px', width: '185px', padding: '12px' }}>
                          <Typography variant="subtitle1">
                          {comment.share_with_client == 0 ?'This is an internal comment':'This is an public comment'}
                          </Typography>
                        </Box>
                      }
                    >
                      {comment.client_commented=='0'?comment.share_with_client == 0 ?
                      <VisibilityOffIcon color='secondary' style={{cursor:'pointer'}} fontSize='medium' onClick={()=>handleMarkingPublicOrInternalModal(comment)} />:
                      <VisibilityIcon color='secondary' style={{cursor:'pointer'}} fontSize='medium' onClick={()=>handleMarkingPublicOrInternalModal(comment)} />
                    :<></>}
                    </CognisaaasTooltip>}
                </Stack>
              </Stack>       
              <Stack sx={{marginRight:'30px'}}>
                {/* {comment.user_uid == getLoggedInUserUid ? ( */}
                <CognissasCustomMenu
                  list={[
                    {
                      value: 'Edit',
                      handleClick: setCommentId,
                      args: [comment.comment_uid],
                    },
                    {
                      value: 'Delete',
                      handleClick: handleDeleteModal,
                      args: [comment],
                    },
                    // !props.isCustomerPortal ? comment.client_commented == 0 ?
                    //   {
                    //     value: `Mark as ${comment.share_with_client == 1 ? 'internal' : 'public'}`,
                    //     handleClick: handleMarkingPublicOrInternalModal,
                    //     args: [comment],
                    //   } : {
                    //     value: '',
                    //     handleClick: (props: any) => { console.log(props); },
                    //     args: [],
                    //   } : {
                    //   value: '',
                    //   handleClick: (props: any) => { console.log(props); },
                    //   args: [],
                    // },
                  ]}
                />
                {/* 
                ) :
                  null
                } */}
              </Stack>
            </Frame>
            <DeleteModal
              isOpen={openDeleteModal}
              setIsOpen={setOpenDeleteModal}
              message={'Are you sure you want to delete this comment'}
              onDelete={() => {
                handleDelete(selectedCommentForDeletion);
              }}
            />
          </Wrapper>
        );
      })}
    </>
  );
};

export default CommentView;
