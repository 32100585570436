import { apis } from '../axiosInstance';
import { pictureRoutes } from '../route';

const addCustomerLogo = (attachment: any) => {
  const formData = new FormData();

  // attching file
  formData.append('file', attachment.file);
  formData.append('metadata', JSON.stringify(attachment.metaData));
  // formData.append()
  return apis.phpAxiosInstance.post(pictureRoutes.addCustomerLogo(), formData, { headers: { JWT: 'true' } });
};

const addCustomerClientLogo = (attachment: any) => {
  const formData = new FormData();

  // attching file
  formData.append('file', attachment.file);
  formData.append('metadata', JSON.stringify(attachment.metaData));
  // formData.append()
  return apis.phpAxiosInstance.post(pictureRoutes.addCustomerClientLogo(), formData, { headers: { JWT: 'true' } });
};

const addUserLogo = (attachment: any) => {
  const formData = new FormData();

  // attching file
  formData.append('file', attachment.file);
  formData.append('metadata', JSON.stringify(attachment.metaData));
  // formData.append()
  return apis.phpAxiosInstance.post(pictureRoutes.addUserLogo(), formData, { headers: { JWT: 'true' } });
};

export const pictureApi = {
  addCustomerLogo,
  addCustomerClientLogo,
  addUserLogo,
};
