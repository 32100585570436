import { TextField } from '@mui/material';
import { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { FlexBox, GridBox, Label, Text } from '../primitives';

export function FormInput({
    id = '',
    name = '',
    customLabel = '',
    size = 'medium',
    control,
    selectedItem,
    formState: { errors },
    resetField,
    setValue,
    isDisabled,
    placeholder,
    type,
    setFun = () => null,
}: any) {

    useEffect(() => {
        setValue(name, selectedItem);
        return () => resetField(name);
    }, []);

    const errName = errors?.[name];
    const errType = errName?.type;
    const errMessage = errName?.message;
    const isError = !!(errType && errMessage);

    return (
        <GridBox
            gridTemplateRows='30px auto 20px'
            gridTemplateColumns='1fr'
            justifyContent='flex-start'
            alignItems='center'
        >
            {customLabel && (
                <Label
                    id={id}
                    variant='heading3'
                    htmlFor={name}
                >
                    {customLabel}
                </Label>
            )}
            <Controller
                name={name}
                control={control}
                render={({
                    field: { onChange, onBlur, value, name, ref },
                }) => {
                    return (
                        <TextField
                            id={id}
                            key={id}
                            name={name}
                            value={value}
                            size={size}
                            onChange={(e) => {
                                onChange(e.target.value);
                                if (setFun) setFun(e.target.value);
                            }}
                            onBlur={onBlur}
                            inputRef={ref}
                            disabled={isDisabled}
                            placeholder={placeholder}
                            type={type}
                        //   style={{ width: '200px', marginTop: '8px' }}
                        />
                    );
                }}
            />
            {isError ? (
                <FlexBox pt={3}>
                    <Text variant='paragraph2' color='red'>{errors[name].message}</Text>
                </FlexBox>
            ) : null}
        </GridBox >
    );
}
