import moment, { unitOfTime } from 'moment';
import { TProjectItemAPI, TResourceItemAPI, TTaskItemAPI } from '../types/public-types';
import { DATE_FORMAT, getDateInYYYYMMDD, getStartDateInYYYYMMDD, getEndDateInYYYYMMDD } from './date';

export function getDurationInX(startDate: string, endDate: string, unitOfTime: unitOfTime.Diff) {
  const start = moment(getDateInYYYYMMDD(new Date(startDate)));
  const end = moment(getDateInYYYYMMDD(new Date(endDate)));
  const diffInDays = end.diff(start, unitOfTime);
  const taskDurationInDays = diffInDays < 0 ? -diffInDays : diffInDays;
  return taskDurationInDays;
}

function getResourceUtilizationFields(taskItem: TTaskItemAPI) {
  const allocatedHoursPerDay = taskItem?.hours_per_day ?? 0;
  const effortEstimate = taskItem?.effort_estimate ?? 0;
  const minutes = 60;

  const allocatedHoursPerDayInNumber = Number(allocatedHoursPerDay);
  const effortEstimateInNumberInHours = Number(effortEstimate) / minutes;

  const taskDurationInDays = getDurationInX(taskItem.task_planned_start_date, taskItem.task_due_date, 'days');
  const taskDurationInHours = getDurationInX(taskItem.task_planned_start_date, taskItem.task_due_date, 'hours');

  const utilizationPercentage =
    effortEstimateInNumberInHours !== 0 && allocatedHoursPerDayInNumber !== 0
      ? (effortEstimateInNumberInHours / allocatedHoursPerDayInNumber) * 100
      : 0;
  const utilizationPercentageInNumber = utilizationPercentage;

  const task_stage = taskItem?.task_stage ?? '-';

  const commonTaskFields = {
    task_utilized_percentage: utilizationPercentageInNumber,
    task_effort_estimate: effortEstimateInNumberInHours,
    task_stage,
    entity: 'task',
  };

  if (taskDurationInHours === 0) {
    const taskPlannedStartDateWithAddedHours = moment(taskItem.task_planned_start_date)
      .startOf('day')
      .format(DATE_FORMAT);
    const taskDueDateWithAddedHours = moment(taskItem.task_due_date).add(1, 'day').endOf('day').format(DATE_FORMAT);

    const taskDurationInDays = getDurationInX(taskPlannedStartDateWithAddedHours, taskDueDateWithAddedHours, 'days');
    const taskDurationInHours = getDurationInX(taskPlannedStartDateWithAddedHours, taskDueDateWithAddedHours, 'hours');

    return {
      task_planned_start_date: taskPlannedStartDateWithAddedHours,
      task_due_date: taskDueDateWithAddedHours,
      task_duration_in_days: taskDurationInDays,
      task_duration_in_hours: taskDurationInHours,
      ...commonTaskFields,
    };
  }
  return {
    task_planned_start_date: moment(taskItem.task_planned_start_date).format(DATE_FORMAT),
    task_due_date: moment(taskItem.task_due_date).format(DATE_FORMAT),
    task_duration_in_days: taskDurationInDays,
    task_duration_in_hours: taskDurationInHours,
    ...commonTaskFields,
  };
}

function getMinMaxDatesOfTasks(targetItems: any) {
  let _minDate = null;
  let _maxDate = null;
  if (targetItems && targetItems.length) {
    _minDate = new Date(
      Math.min(
        ...targetItems
          .filter((item: any) => item.task_planned_start_date)
          .map((item: any) => new Date(item.task_planned_start_date))
      )
    );

    _maxDate = new Date(
      Math.max(
        ...targetItems.filter((item: any) => item.task_due_date).map((item: any) => new Date(item.task_due_date))
      )
    );
  }
  const minDate = _minDate
    ? moment(getDateInYYYYMMDD(new Date(_minDate))).toDate()
    : moment(getDateInYYYYMMDD(new Date())).toDate();
  const maxDate = _maxDate
    ? moment(getDateInYYYYMMDD(new Date(_maxDate))).toDate()
    : moment(getDateInYYYYMMDD(new Date())).toDate();
  return { minDate, maxDate };
}

function getMinMaxDatesOfProjects(targetItems: any) {
  let _minDate = null;
  let _maxDate = null;
  if (targetItems && targetItems.length) {
    _minDate = new Date(
      Math.min(...targetItems.filter((item: any) => item.start).map((item: any) => new Date(item.start)))
    );
    _maxDate = new Date(
      Math.max(...targetItems.filter((item: any) => item.end).map((item: any) => new Date(item.end)))
    );
  }
  const minDate = _minDate
    ? moment(getDateInYYYYMMDD(new Date(_minDate))).toDate()
    : moment(getDateInYYYYMMDD(new Date())).toDate();
  const maxDate = _maxDate
    ? moment(getDateInYYYYMMDD(new Date(_maxDate))).toDate()
    : moment(getDateInYYYYMMDD(new Date())).toDate();
  return { minDate, maxDate };
}

function getTasks(projectItem: TProjectItemAPI): TTaskItemAPI[] {
  const delivery_manager_uid = projectItem?.project_details?.user_uid;
  const tasks = projectItem.tasks.map((taskItem: TTaskItemAPI) => {
    const resourceUtilizationObj = getResourceUtilizationFields(taskItem);
    // console.log({ resourceUtilizationObj });
    return {
      delivery_manager_uid,
      title: taskItem.task_name,
      association_uid: projectItem.project_details.association_uid,
      ...taskItem,
      ...resourceUtilizationObj,
    };
  });
  return tasks;
}

const getProjects = (resourceItem: any): any[] => {
  if (resourceItem.projects === undefined) return [];
  const resourceItemDisplayOrder = resourceItem.displayOrder;
  const projects = resourceItem.projects
    .map((projectItem: any) => {
      return {
        ...projectItem,
        ...projectItem.project_details,
        type: 'project',
        displayOrder: resourceItemDisplayOrder,
      };
    })
    .map((projectItem: any) => {
      const tasks = getTasks(projectItem);
      const tasksCount = tasks.length;
      const { minDate, maxDate } = getMinMaxDatesOfTasks(tasks);
      return {
        ...projectItem,
        count: tasksCount,
        minDate,
        maxDate,
        tasks,
      };
    })
    .map((projectItem: any) => {
      const tasks = projectItem.tasks;
      const count = projectItem.tasks.length;
      const start = projectItem.minDate;
      const end = projectItem.maxDate;
      const consolidatedTasksUtilizedPercentage = tasks.reduce(
        (sum: number, taskItem: TTaskItemAPI) => sum + taskItem.task_utilized_percentage,
        0
      );
      const progress =
        consolidatedTasksUtilizedPercentage !== 0 && count !== 0 ? consolidatedTasksUtilizedPercentage / count : 0;
      return {
        id: projectItem.project_uid,
        name: projectItem.project_name,
        progress,
        consolidatedTasksUtilizedPercentage,
        count,
        start,
        end,
        componentLinkedTo: projectItem.user_uid,
        tasks,
        displayOrder: projectItem.displayOrder,
        entity: 'project',
        hideChildren: true, // used in getResourceWithProjectsAndTasks
      };
    });
  return projects;
};

export const getSoftAllocations = (resourceItem: any) => {
  if (resourceItem === undefined) return [];
  const resourceItemDisplayOrder = resourceItem.displayOrder;
  const rowHeight = 70;
  const softY = Math.floor((resourceItemDisplayOrder - 1) * rowHeight + (rowHeight - 30) / 2);
  const softAllocations = resourceItem
    ? resourceItem.soft_allocation.map((softAllocationItem: any) => {
        const mappedSoftAllocations = {
          id: softAllocationItem.soft_allocation_uid,
          allocationType: softAllocationItem.soft_allocation_type === 'leave' ? '2' : '1',
          projectId: softAllocationItem.project_uid,
          resourceId: softAllocationItem.user_uid,
          start: moment(getStartDateInYYYYMMDD(new Date(softAllocationItem.start_date))).toDate(),
          end: moment(getEndDateInYYYYMMDD(new Date(softAllocationItem.end_date))).toDate(),
          name: '',
          hideChildren: false,
          displayOrder: resourceItemDisplayOrder,
          softY,
          entity: 'soft',
          count: 1,
        };
        return mappedSoftAllocations;
      })
    : [];
  return softAllocations;
};

function getTransformedData(data: TResourceItemAPI[]): any {
  if (data === undefined) return [];

  let allProjects: TProjectItemAPI[] = [];
  let allSoftAllocations: any[] = [];

  const resources = data
    .map((resourceItem: TResourceItemAPI, index) => {
      const displayOrder = index + 1;
      return {
        id: resourceItem.delivery_manager_uid,
        name: resourceItem.delivery_manager_name,
        customer_uid: resourceItem.customer_uid,
        displayOrder,
        type: 'resource',
        projects: resourceItem.projects,
        soft_allocation: resourceItem.soft_allocation,
        entity: 'resource',
      };
    })
    .filter((resourceItem) => {
      const enhancedProjects = getProjects(resourceItem);
      return enhancedProjects.length;
    })
    .map((resourceItem) => {
      const enhancedProjects = getProjects(resourceItem);
      allProjects = [...allProjects, ...enhancedProjects];
      const { minDate, maxDate } = getMinMaxDatesOfProjects(enhancedProjects);
      const count = enhancedProjects.length;
      const consolidatedProjectsUtilizedPercentage = enhancedProjects.reduce(
        (sum: number, projectItem: any) => sum + projectItem.progress,
        0
      );
      const progress =
        consolidatedProjectsUtilizedPercentage !== 0 && count !== 0
          ? consolidatedProjectsUtilizedPercentage / count
          : 0;

      return {
        ...resourceItem,
        projects: enhancedProjects,
        start: minDate,
        end: maxDate,
        count,
        progress,
      };
    })
    .map((resourceItem) => {
      const enhancedSoftAllocations = getSoftAllocations(resourceItem);
      allSoftAllocations = [...allSoftAllocations, ...enhancedSoftAllocations];
      return {
        ...resourceItem,
        hideChildren: true,
        soft_allocation: enhancedSoftAllocations,
      };
    });

  return { resources, allProjects, allSoftAllocations };
}

function getAllTasks(data: any[]): any {
  if (data === undefined) return [];

  let allTasks: any[] = [];
  data.forEach((projectItem: any) => {
    const tasks = projectItem.tasks;
    allTasks = [...allTasks, ...tasks];
  });

  return allTasks;
}

export function transformDataForMainView(data: any[]) {
  const result = getTransformedData(data);
  const { resources, allProjects, allSoftAllocations } = result;

  return {
    resources,
    softAllocations: allSoftAllocations,
    events: allProjects,
  };
}

function getResourceWithProjectsAndTasks(resourceItem: any): any {
  if (!resourceItem) return [];
  let tasksForProjects: any[] = [];

  const projects = getProjects(resourceItem);
  const mappedProjects = projects.map((projectItem: any, index: number) => {
    const projectDisplayOrder = index + 1;
    // console.log({ projectItem });

    const tasks = projectItem.tasks.map((taskItem: any) => {
      const mappedTaskItem = {
        ...taskItem,
        start: moment(getDateInYYYYMMDD(new Date(taskItem.task_planned_start_date))).toDate(),
        end: moment(getDateInYYYYMMDD(new Date(taskItem.task_due_date))).toDate(),
        id: taskItem.task_uid,
        name: taskItem.task_name,
        task_name: taskItem.task_name,
        type: 'project',
        progress: taskItem.task_utilized_percentage,
        componentLinkedTo: taskItem.project_uid,
        displayOrder: projectDisplayOrder,
        count: 1,
        taskStage: taskItem.task_stage,
        taskDuration: taskItem.task_duration_in_days,
        taskEffortEstimate: taskItem.task_effort_estimate,
        entity: 'task',
      };
      // console.log({ mappedTaskItem });
      return mappedTaskItem;
    });
    tasksForProjects = [...tasksForProjects, ...tasks];

    const mappedProjectItem = {
      start: projectItem.start,
      end: projectItem.end,
      id: projectItem.id,
      name: projectItem.name,
      type: 'resource',
      hideChildren: projectItem.hideChildren, // using from getProjects
      displayOrder: projectDisplayOrder,
      count: projectItem.count,
      tasks: projectItem.tasks,
      progress: projectItem.progress,
      entity: 'project',
    };
    // console.log({ mappedProjectItem });
    return mappedProjectItem;
  });
  return { projects: mappedProjects, tasks: tasksForProjects };
}

export function transformDataForResourceView(data: any[], resourceId: string) {
  const oneResourceData = data.find((resource: any) => resource.delivery_manager_uid === resourceId);
  const { projects: resources, tasks: events } = getResourceWithProjectsAndTasks(oneResourceData);

  return {
    resources,
    events,
  };
}

export function transformDataForProjectView(data: any[], projectId: string) {
  const transformedData = getTransformedData(data);
  const { allProjects } = transformedData;
  const tasksForProjectView = getAllTasks(allProjects);

  const tasksByProjectId = tasksForProjectView
    .filter((taskItem: any) => taskItem.project_uid === projectId)
    .map((taskItem: any, index: number) => {
      const taskDisplayOrder = index + 1;

      const mappedTaskItem = {
        ...taskItem,
        start: moment(getDateInYYYYMMDD(new Date(taskItem.task_planned_start_date))).toDate(),
        end: moment(getDateInYYYYMMDD(new Date(taskItem.task_due_date))).toDate(),
        id: taskItem.task_uid,
        name: taskItem.task_name,
        type: 'resource',
        progress: taskItem.task_utilized_percentage,
        componentLinkedTo: taskItem.task_uid,
        displayOrder: taskDisplayOrder,
        count: 1,
        taskStage: taskItem.task_stage,
        taskDuration: taskItem.task_duration_in_days,
        taskEffortEstimate: taskItem.task_effort_estimate,
        entity: 'task',
      };

      return mappedTaskItem;
    });

  return tasksByProjectId;
}

export function stringToHslColor(str: string, s: number, l: number) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const h = hash % 360;
  return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
}

export function getUserLetters(str: string): string {
  const splitStr = str.toLowerCase().split(' ');
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase();
  }
  return splitStr.length > 2 ? splitStr.join('').slice(0, 2) : splitStr.join('');
}

export const valueToMinutes = (val: any): any => {
  const inputLogValue = val.split(' ');
  let valueInMinutes = 0;
  let minute = 0;
  let hour = 0;
  let day = 0;
  let week = 0;
  inputLogValue.forEach((val: any) => {
    const char = val.slice(-1);

    if (char == 'm') {
      minute = parseInt(val.slice(0, -1));
      valueInMinutes += minute;
    }
    if (char == 'h') {
      hour = parseInt(val.slice(0, -1));
      valueInMinutes += hour * 60; //convert hours => 60minutes
    }
    if (char == 'd') {
      day = parseInt(val.slice(0, -1));
      valueInMinutes += day * 8 * 60; //convert days => 8 hours => 60minutes
    }
    if (char == 'w') {
      week = parseInt(val.slice(0, -1));
      valueInMinutes += week * 5 * 8 * 60;
      //convert week to 5days => 8 hours => 60minutes
    }
  });
  return valueInMinutes;
};

// Method used to Convert Database Value to be used in the UI
export const convertMinutes = (num: any): any => {
  if (num == 0) {
    return '0m';
  } else {
    let hour = Math.floor(num / 60); // 60 minutes in hour
    let day = Math.floor(hour / 8); // 8 hours a day office time
    const week = Math.floor(day / 5); // 5 days a week office time
    const minute = num - 60 * hour; // remaining minutes

    hour = hour - day * 8; // remaining hours
    day = day - week * 5; // remaining days

    let timeLogValue = ''; // Var to store Time Log Value

    if (week > 0) {
      timeLogValue = timeLogValue.concat(`${week}w `);
    }
    if (day > 0) {
      timeLogValue = timeLogValue.concat(`${day}d `);
    }
    if (hour > 0) {
      timeLogValue = timeLogValue = timeLogValue.concat(`${hour}h `);
    }
    if (minute > 0) {
      timeLogValue = timeLogValue.concat(`${minute}m `);
    }

    timeLogValue = timeLogValue.slice(0, -1); // To remove the last blank space

    return timeLogValue;
  }
};
