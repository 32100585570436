import * as React from 'react';
import TextField from '@mui/material/TextField';
import { Box, Stack, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker, DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';

interface Props {
    value: DateRange<Date>;
    setValue: React.Dispatch<React.SetStateAction<DateRange<Date>>>;
    startLabel:string;
    endLabel:string;
    width:string;
    marginBetweenDates?:number;
}

export default function BasicDateRangePicker(props:Props) {

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      localeText={{ start: '', end: '' }}
    >
      <DateRangePicker
        value={props.value}
        onChange={(newValue) => props.setValue(newValue)}
        renderInput={(startProps, endProps) => (
          <React.Fragment>
            <Stack style={{width:props.width}}>
            <Typography variant="subtitle2">{props.startLabel}*</Typography>
            <TextField size="small" {...startProps} />
            </Stack>
            <Box sx={{ mx: props.marginBetweenDates || 1 }}/>
            <Stack style={{width:props.width}}>
            <Typography variant="subtitle2">{props.endLabel}*</Typography>
            <TextField size="small"  {...endProps} />
            </Stack>
          </React.Fragment>
        )}
      />
    </LocalizationProvider>
  );
}
