import { apis } from '../axiosInstance';
import { clientRoutes2 as clientRoutes } from '../route-new';
// import { clientRoutes } from 'api/route';

const getClientList = (pageModel: { page: number; pageSize: number }) =>
  apis.phpAxiosInstance.get(clientRoutes.clients(pageModel.page, pageModel.pageSize), { headers: { JWT: 'true' } });

const getClientDetail = (id: string) =>
  apis.phpAxiosInstance.get(clientRoutes.clientDetail(id), { headers: { JWT: 'true' } });

const getClientIndustries = () => apis.phpAxiosInstance.get(clientRoutes.industries(), { headers: { JWT: 'true' } });

const getClientSegments = () => apis.phpAxiosInstance.get(clientRoutes.segments(), { headers: { JWT: 'true' } });

const getClientStages = () => apis.phpAxiosInstance.get(clientRoutes.stages(), { headers: { JWT: 'true' } });

const updateClient = (id: string, payload: any) =>
  apis.phpAxiosInstance.patch(clientRoutes.clientDetail(id), payload, { headers: { JWT: 'true' } });

const getClientCustomFields = (client_id: string) =>
  apis.phpAxiosInstance.get(clientRoutes.clientCustomFields(client_id), { headers: { JWT: 'true' } });

const updateClientCustomfield = (clientId: string, fieldId: string, payload: any) =>
  apis.phpAxiosInstance.patch(
    clientRoutes.updateClientCustomfields(clientId, fieldId),
    { value: payload.value, config: payload },
    {
      headers: { JWT: 'true' },
    }
  );
const getClientRenewals = (client_id: string) =>
  apis.phpAxiosInstance.get(clientRoutes.getRenewals(client_id), { headers: { JWT: 'true' } });

const updateClientRenewal = (payload: any, client_id: string) =>
  apis.phpAxiosInstance.put(clientRoutes.updateRenewals(client_id), payload, { headers: { JWT: 'true' } });

const getClientContacts = (client_id: string) =>
  apis.phpAxiosInstance.get(clientRoutes.getClientContacts(client_id), { headers: { JWT: 'true' } });

const addClientContact = (client_id: string, payload: any) =>
  apis.phpAxiosInstance.post(clientRoutes.addClientContacts(client_id), payload, { headers: { JWT: 'true' } });

const deleteClientContact = (client_id: string, contact_id: any) =>
  apis.phpAxiosInstance.delete(clientRoutes.deleteClientContact(client_id, contact_id), { headers: { JWT: 'true' } });

export const clientAPI = {
  getClientList,
  getClientDetail,
  getClientCustomFields,
  getClientIndustries,
  getClientStages,
  getClientSegments,
  updateClient,
  updateClientCustomfield,
  getClientRenewals,
  updateClientRenewal,
  getClientContacts,
  addClientContact,
  deleteClientContact,
};
