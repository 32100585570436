import { Button, ButtonGroup, Drawer } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../store/hooks';
import { selectBadFitAccounts, selectGoodFitAccounts } from '../../../store/modules/ActionableInsights/selector';
import { fetchBadFitAccounts, fetchGoodFitAccounts } from '../../../store/modules/ActionableInsights/slice';
import BasicClientView from '../../shared/CognisaasCommons/BasicClientView/BasicClientView';
import CognisaasLoader from '../../shared/CognisaasLoader/CognisaasLoader';
// import CognisaasTable, { ColumnMapping } from '../../shared/CognisaasTable/CognisaasTable';
import LabeledBadge from '../../shared/LabeledBadge/LabeledBadge';
import CogniTable, { linkCell, StatusCell } from '../ActionableInsightTable/Table';
import { selectCustomerDetail } from 'store/modules/Common/selector';
import { getCustomerDetail } from 'store/modules/Common/slice';
import { label_arr, label_client } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { formatCurrency } from 'utils/dataHelpers';
import useCustomNames from 'components/shared/CustomNames/useCustomNames';

const TimeToValue = () => {
  const [selectedFitnessTab, setSelectedFitnessTab] = useState<'good' | 'bad'>('good');
  const dispatch = useDispatch();
  const goodFitAccountsState = useAppSelector(selectGoodFitAccounts);
  const badFitAccountsState = useAppSelector(selectBadFitAccounts);
  const customerDetail = useAppSelector(selectCustomerDetail);
  const [isClientDataViewDrawerToggled, setIsClientDataViewDrawerToggled] = useState<boolean>(false);
  const [clientUid, setClientUid] = useState('');
  const { displayDataWithCustomNames } = useCustomNames();

  useEffect(() => {
    dispatch(fetchGoodFitAccounts());
    dispatch(fetchBadFitAccounts());
    dispatch(getCustomerDetail());
  }, []);

  const handleAccountClick = (rowData: Record<string, any>) => {
    const clientUid = rowData.client_uid;
    setClientUid(clientUid);
    setIsClientDataViewDrawerToggled((prevState) => !prevState);
  };

  // const getFitAccountMappings = (): ColumnMapping[] => {
  //   const columnMappings: ColumnMapping[] = [
  //     {
  //       columnName: 'Account',
  //       dataKeyName: 'client_name',
  //       columnType: 'link',
  //       enableSorting: true,
  //     },
  //     {
  //       columnName: 'Account Status',
  //       dataKeyName: 'client_readiness_status',
  //       columnType: 'status',
  //       enableSorting: true,
  //     },
  //     {
  //       columnName: 'Account Segments',
  //       dataKeyName: 'segment_name',
  //       columnType: 'simple',
  //       enableSorting: true,
  //     },
  //     {
  //       columnName: 'Journey Stage',
  //       dataKeyName: 'stage_name',
  //       columnType: 'simple',
  //       enableSorting: true,
  //     },
  //     {
  //       columnName: 'label_arr',
  //       dataKeyName: 'client_account_worth',
  //       columnType: 'cost',
  //       enableSorting: true,
  //     },
  //   ];

  //   return columnMappings;
  // };

  return (
    <Box sx={{ padding: '20px 0px' }}>
      {/* tabs */}
      <ButtonGroup
        variant="outlined"
        aria-label="outlined button group"
        sx={{
          display: 'inline-flex',
          flexWrap: 'wrap',
          backgroundColor: 'white',
          border: '1px solid #E5E5E5',
          borderRadius: '4px',
          marginBottom: '24px',
        }}
      >
        <Button
          onClick={() => {
            setSelectedFitnessTab('good');
          }}
          variant="text"
          sx={selectedFitnessTab === 'good' ? { background: 'rgba(45, 96, 246,0.15)' } : {}}
        >
          <LabeledBadge
            text={`Good Fit ${label_client}s`}
            badgeCount={goodFitAccountsState.data.length}
            isActive={selectedFitnessTab === 'good'}
          />
        </Button>
        <Button
          onClick={() => {
            setSelectedFitnessTab('bad');
          }}
          variant="text"
          sx={selectedFitnessTab === 'bad' ? { background: 'rgba(45, 96, 246,0.15)' } : {}}
        >
          <LabeledBadge
            text={`Bad Fit ${label_client}s`}
            badgeCount={badFitAccountsState.data.length}
            isActive={selectedFitnessTab === 'bad'}
          />
        </Button>
      </ButtonGroup>

      {selectedFitnessTab === 'good' ? (
        <Box>
          {goodFitAccountsState.status === 'success' ? (
            <>
              {/* <CognisaasTable
                uniqueTableName={null}
                columnMappings={getFitAccountMappings()}
                data={goodFitAccountsState.data}
                pageSize={5}
                onClick={handleAccountClick}
              /> */}
              <CogniTable
                hideFooter={false}
                hideHeader={true}
                pageSize={5}
                columns={[
                  {
                    field: 'client_uid',
                    hide: true,
                  },
                  {
                    field: 'client_name',
                    headerName: label_client,
                    minWidth: 240,
                    flex: 1,
                    renderCell: (params) => linkCell(params, handleAccountClick),
                  },
                  {
                    field: 'client_readiness_status',
                    headerName: `${label_client} Status`,
                    minWidth: 200,
                    flex: 1,
                    renderCell: StatusCell,
                  },
                  {
                    field: 'segment_name',
                    headerName: `${label_client} Segments`,
                    minWidth: 140,
                    flex: 1,
                  },
                  {
                    field: 'stage_name',
                    headerName: 'Journey Stage',
                    minWidth: 140,
                    flex: 1,
                  },
                  {
                    field: 'client_account_worth',
                    headerName: `${label_arr}`,
                    minWidth: 140,
                    flex: 1,
                    renderCell: (params) => {
                      return formatCurrency(params.row[params.field], customerDetail?.currency_code);
                    },
                  },
                ]}
                uniquRowId={'client_uid'}
                data={displayDataWithCustomNames(goodFitAccountsState.data)}
              />
            </>
          ) : (
            <CognisaasLoader />
          )}
        </Box>
      ) : (
        <Box>
          {/* bad fit accounts */}
          {goodFitAccountsState.status === 'success' ? (
            <>
              {/* <CognisaasTable
                uniqueTableName={null}
                columnMappings={getFitAccountMappings()}
                onClick={handleAccountClick}
                data={badFitAccountsState.data}
                pageSize={5}
              /> */}
              <CogniTable
                hideFooter={false}
                hideHeader={true}
                pageSize={5}
                columns={[
                  {
                    field: 'client_uid',
                    hide: true,
                  },
                  {
                    field: 'client_name',
                    headerName: label_client,
                    minWidth: 240,
                    flex: 1,
                    renderCell: (params) => linkCell(params, handleAccountClick),
                  },
                  {
                    field: 'client_readiness_status',
                    headerName: `${label_client} Status`,
                    minWidth: 200,
                    flex: 1,
                    renderCell: StatusCell,
                  },
                  {
                    field: 'segment_name',
                    headerName: `${label_client} Segments`,
                    minWidth: 140,
                    flex: 1,
                  },
                  {
                    field: 'stage_name',
                    headerName: 'Journey Stage',
                    minWidth: 140,
                    flex: 1,
                  },
                  {
                    field: 'client_account_worth',
                    headerName: `${label_arr}`,
                    minWidth: 140,
                    flex: 1,
                    renderCell: (params) => {
                      return formatCurrency(params.row[params.field], customerDetail?.currency_code);
                    },
                  },
                ]}
                uniquRowId={'client_uid'}
                data={displayDataWithCustomNames(badFitAccountsState.data)}
              />
            </>
          ) : (
            <CognisaasLoader />
          )}
        </Box>
      )}

      {/* client info drawer */}
      <Drawer
        anchor={'right'}
        open={isClientDataViewDrawerToggled}
        onClose={() => {
          setIsClientDataViewDrawerToggled((prevState) => !prevState);
        }}
        PaperProps={{
          sx: { width: '440px' },
        }}
      >
        <Box sx={{ padding: '95px 20px 20px 20px' }}>
          <BasicClientView clientUid={clientUid} />
        </Box>
      </Drawer>
    </Box>
  );
};

export default TimeToValue;
