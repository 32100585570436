// Note :-  this is not normal date range picker it is a chip date range picker needed in filters
import * as React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { DesktopDateRangePicker } from '@mui/x-date-pickers-pro/DesktopDateRangePicker';
import { DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';
import { Box, Chip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface Props {
  name: string;
  handleDelete: () => void;
  value: DateRange<Date>;
  setValue: React.Dispatch<React.SetStateAction<DateRange<Date>>>;
}
const brandBlue = '#2D60F6';
const dateInputStyle = {
  width: '90px',
  border: 'none',
  background: 'transparent',
  marginLeft: '4px',
  fontFamily: 'Noto Sans',
};

const useStyles = makeStyles({
  chipRoot: {
    '&.Mui-focusVisible': {
      backgroundColor: '#cedafd',
    },
  },
});

export default function DateRangePicker(props: Props) {
  const classes = useStyles();

  //   const [value, setValue] = React.useState<DateRange<Date>>([null, null]); // handle these state externally

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} localeText={{ start: 'Desktop start', end: 'Desktop end' }}>
      <DesktopDateRangePicker
        value={props.value}
        onChange={(newValue) => {
          props.setValue(newValue);
        }}
        calendars={1}
        renderInput={(startProps, endProps) => (
          <React.Fragment>
            <Chip
              classes={{ root: classes.chipRoot }}
              sx={{ background: '#cedafd' }}
              label={
                <Box
                  sx={{
                    background: '#cedafd',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'baseline',
                    flexWrap: 'wrap',
                  }}
                >
                  <Typography variant="h3" sx={{ color: brandBlue }}>
                    {props.name}
                  </Typography>
                  <input
                    ref={startProps.inputRef as React.Ref<HTMLInputElement>}
                    {...startProps.inputProps}
                    onKeyDown={(e: any) => e.preventDefault()}
                    style={dateInputStyle}
                  />
                  To
                  <input
                    style={dateInputStyle}
                    ref={endProps.inputRef as React.Ref<HTMLInputElement>}
                    {...endProps.inputProps}
                    onKeyDown={(e: any) => e.preventDefault()}
                  />
                </Box>
              }
              // variant="outlined"
              //   onClick={props.handleClick}
              onDelete={props.handleDelete}
            />
          </React.Fragment>
        )}
      />
    </LocalizationProvider>
  );
}
