import TextField from '@mui/material/TextField';
import Autocomplete, { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import { SxProps } from '@mui/material';

interface Props {
  allOptions: { id: string; name: string }[];
  value: { id: string; name: string };
  onChange: (data: { id: string; name: string } | null) => void;
  onBlur?: () => void;
  error?: boolean;
  helperText?: string;
  sx?: SxProps;
  width?: string;
}

const CognisaasAutocompleteSelect = (props: Props) => {
  return (
    <Autocomplete
      // disablePortal
      options={props.allOptions}
      sx={{ width: props.width ? props.width : 300 }}
      getOptionLabel={(option: any) => option.name}
      value={props.value}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          sx={props?.sx}
          {...params}
          size="small"
          // error={props.touched[props.name] && !!props.errors[props.name]}
          // helperText={props.touched[props.name] && props.errors[props.name]}
          // label="Autocomplete"
          variant="outlined"
          onBlur={props?.onBlur}
          error={props?.error}
          helperText={props?.helperText}
        />
      )}
      onChange={(e, data) => {
        console.log(e);
        props.onChange(data);
      }}
    />
  );
};

export default CognisaasAutocompleteSelect;
