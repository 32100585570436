import {
  DataGridPremium,
  useGridApiRef,
  // useKeepGroupedColumnsHidden,
  // GridColumnHeaderParams,
  GridRenderCellParams,
} from '@mui/x-data-grid-premium';
import './TeamMembersTable.css';
import { removeUserFromProject, getProjectMembers, addProjectAuditTrial } from 'store/modules/Project/slice';
import { styled } from '@mui/material/styles';
import { Box, Stack, Typography, Button } from '@mui/material';
import { getLoggedInUserUid } from 'utils/getUid';
import { label_project } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { useState } from 'react';
import DeleteModal from 'components/shared/CognisaasModal/DeleteModal';
import { format } from 'date-fns';
import { getUserActiveState, INACTIVE_STRING, INACTIVE_USER_COLOR } from 'utils/userHelpers';
import { useAppDispatch } from 'store/hooks';

const TeamMembersTable = (props: any) => {
  const [openLeaveModal, setOpenLeaveModal] = useState<boolean>(false);
  const [openRemoveModal, setOpenRemoveModal] = useState<boolean>(false);
  const [currentRowToRemove, setCurrentRowToRemove] = useState<Record<any, any>>({});

  console.log('props.members', props);
  const dispatch = useAppDispatch();

  const removeFromProject = async (row: any) => {
    console.log(row);
    const detail: any = {
      project_uid: row.project_uid,
      user_uid: row.user_uid,
    };
    console.log(row);
    await dispatch(removeUserFromProject(detail)).then();
    const details: any = {
      project_uid: row.project_uid,
      client_uid: props.clientInfo.client_uid
    };
    await dispatch(getProjectMembers(details));
    const auditDetail = {
      project_uid: row.project_uid,
      user_uid: getLoggedInUserUid(),
      field: 'members_updated',
      modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      old_value: '',
      new_value: '',
    };
    dispatch(addProjectAuditTrial(auditDetail));
  };

  const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .ant-empty-img-1': {
      fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
      fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
      fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
      fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
      fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
      fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <svg width="120" height="100" viewBox="0 0 184 152" aria-hidden focusable="false">
          <g fill="none" fillRule="evenodd">
            <g transform="translate(24 31.67)">
              <ellipse className="ant-empty-img-5" cx="67.797" cy="106.89" rx="67.797" ry="12.668" />
              <path
                className="ant-empty-img-1"
                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
              />
              <path
                className="ant-empty-img-2"
                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
              />
              <path
                className="ant-empty-img-3"
                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
              />
            </g>
            <path
              className="ant-empty-img-3"
              d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
            />
            <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
              <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
              <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
            </g>
          </g>
        </svg>
        <Box sx={{ mt: 1 }}>No Rows</Box>
      </StyledGridOverlay>
    );
  }

  const StyledBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: 600,
    width: '100%',
    '& .MuiFormGroup-options': {
      alignItems: 'center',
      paddingBottom: theme.spacing(1),
      '& > div': {
        minWidth: 100,
        margin: theme.spacing(2),
        marginLeft: 0,
      },
    },
  }));

  const columns = [
    {
      field: 'user_uid',
      hide: true,
    },
    {
      field: 'user_username',
      headerName: 'Member',
      width: 220,
      renderCell: (params: GridRenderCellParams) => (
        <>
          {getUserActiveState(params.row.user_uid, props.members) === '0' ? (
            <Typography style={{ color: INACTIVE_USER_COLOR }} variant="subtitle1">
              {params.value}&nbsp;({INACTIVE_STRING})
            </Typography>
          ) : (
            <Typography mt={1} variant="subtitle1">
              {params.value}
            </Typography>
          )}
        </>
      ),
    },
    {
      field: 'user_email',
      headerName: 'Email',
      width: 220,
    },
    {
      field: 'user_role',
      headerName: 'Role',
      width: 100,
      renderCell: () => (
        <Stack justifyContent="center">
          <Typography variant={'h6'} className="label-text">
            -
          </Typography>
        </Stack>
      ),
    },
    {
      field: 'user_permission',
      headerName: 'Action',
      width: 200,
      headerClassName: 'add-padding',
      //   renderHeader: (params: GridColumnHeaderParams) => <></>,
      renderCell: (params: GridRenderCellParams) => (
        <Stack sx={{ padding: '0px' }}>
          {params.row.user_uid == getLoggedInUserUid() ? (
            <Button
              onClick={() => {
                setCurrentRowToRemove(params.row);
                setOpenLeaveModal(true);
                // removeFromProject(params.row)
              }}
              size="small"
              sx={{ padding: '6px 0px', color: '#E75B5C' }}
              variant="text"
            >
              Leave
            </Button>
          ) : (
            <Button
              onClick={() => {
                setCurrentRowToRemove(params.row);
                setOpenRemoveModal(true);
                // removeFromProject(params.row)
              }}
              sx={{ padding: '6px 0px' }}
              variant="text"
            >
              Remove
            </Button>
          )}
        </Stack>
      ),
    },
  ];

  const rows = [...props.members];

  const data = {
    rows: [...rows],
    columns: [...columns],
  };

  const apiRef = useGridApiRef();

  const removeOrLeaveAsTeamMate = () => {
    removeFromProject(currentRowToRemove);
  };

  return (
    <>
      <StyledBox>
        <DataGridPremium
          components={{
            NoRowsOverlay: CustomNoRowsOverlay,
          }}
          {...data}
          getRowId={(row) => row.user_uid}
          apiRef={apiRef}
          disableSelectionOnClick
        />
      </StyledBox>
      <DeleteModal
        isOpen={openLeaveModal}
        setIsOpen={setOpenLeaveModal}
        message={`Are you sure you want to leave this ${label_project.toLowerCase()}`}
        onDelete={removeOrLeaveAsTeamMate}
      />
      <DeleteModal
        isOpen={openRemoveModal}
        setIsOpen={setOpenRemoveModal}
        message={`Are you sure you want to remove this member from this ${label_project.toLowerCase()}`}
        onDelete={removeOrLeaveAsTeamMate}
      />
    </>
  );
};

export default TeamMembersTable;
