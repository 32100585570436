import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { commonAPI } from '../../../../api/modules/common-new';
import { requestState } from '../../types';

interface commonDetailsType {
  users: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };
  segments: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };
  industries: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };
  stages: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };
}

const initialState: commonDetailsType = {
  users: {
    data: [],
    status: requestState.loading,
    error: null,
  },
  segments: {
    data: [],
    status: requestState.loading,
    error: null,
  },
  industries: {
    data: [],
    status: requestState.loading,
    error: null,
  },
  stages: {
    data: [],
    status: requestState.loading,
    error: null,
  },
};

export const getUsers = createAsyncThunk('users/getUsers', async () => {
  const { data } = await commonAPI.getUsers();
  console.log(data.results);
  return data.results;
});

export const getSegments = createAsyncThunk('users/getSegments', async () => {
  const { data } = await commonAPI.getSegments();
  console.log(data.results);
  return data.results;
});

export const getIndustries = createAsyncThunk('users/getIndustries', async () => {
  const { data } = await commonAPI.getIndustries();
  console.log(data.results);
  return data.results;
});

export const getStages = createAsyncThunk('users/getStages', async () => {
  const { data } = await commonAPI.getStages();
  console.log(data.results);
  return data.results;
});

const commonSlice = createSlice({
  name: 'commonSlice',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getUsers.pending, (state) => {
        state.users.status = requestState.loading;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.users.status = requestState.success;
        state.users.data = action.payload;
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.users.error = action.error.message;
      })
      // SEGMENTS
      .addCase(getSegments.pending, (state) => {
        state.segments.status = requestState.loading;
      })
      .addCase(getSegments.fulfilled, (state, action) => {
        state.segments.status = requestState.success;
        state.segments.data = action.payload;
      })
      .addCase(getSegments.rejected, (state, action) => {
        state.segments.error = action.error.message;
      })
      // INDUSTRIES
      .addCase(getIndustries.pending, (state) => {
        state.industries.status = requestState.loading;
      })
      .addCase(getIndustries.fulfilled, (state, action) => {
        state.industries.status = requestState.success;
        state.industries.data = action.payload;
      })
      .addCase(getIndustries.rejected, (state, action) => {
        state.industries.error = action.error.message;
      })
      // STAGES
      .addCase(getStages.pending, (state) => {
        state.stages.status = requestState.loading;
      })
      .addCase(getStages.fulfilled, (state, action) => {
        state.stages.status = requestState.success;
        state.stages.data = action.payload;
      })
      .addCase(getStages.rejected, (state, action) => {
        state.stages.error = action.error.message;
      });
  },
});

export const commonReducerNew = commonSlice.reducer;

export default commonReducerNew;
