import * as React from 'react';
import { ReactComponent as Excel } from './../../../assets/svgs/excel.svg';
import { useState } from 'react';
import { IconButton } from '@mui/material';
// import { useAppSelector } from '../../../store/hooks';

import CognisaasMenu from '../../shared/CognisaasMenu/CognisaasMenu';
import { excelExport } from '../../../utils/excelHelpers/excelExport';
// import { selectClientList } from '../../../store/modules/ManageClient/ClientList/selector';
import { label_client } from 'components/Settings/Preferences/CustomLabelCookieHelper';

interface Props {
  data: any[];
  customFieldKey?: string;
  columnNameMapping: {
    dataKey: string;
    columnName: string;
  }[];
}

const ExcelFeature = (props: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  // const clientList = useAppSelector(selectClientList);

  const excelMenuOptions: string[] = [`Bulk Import ${label_client}`, 'Download'];
  const handleExcelClick = (event: React.MouseEvent<HTMLElement>) => {
    console.log(event.currentTarget);

    setAnchorEl(event.currentTarget);
  };

  const handleExcelClose = () => {
    setAnchorEl(null);
  };

  const updateArrayForClientStatusValues = (arr: any) => {
    return arr.map((obj: any) => {
      if (obj['Client Status'] === 'red') {
        obj['Client Status'] = 'Overdue';
      } else if (obj['Client Status'] === 'amber') {
        obj['Client Status'] = 'At Risk';
      } else if (obj['Client Status'] === 'green') {
        obj['Client Status'] = 'On Track';
      } else if (obj['Client Status'] === 'blue') {
        obj['Client Status'] = 'Completed';
      }
      return obj;
    });
  };

  const handleSelectFromMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
    console.log(event.currentTarget.innerText);
    if (event.currentTarget.innerText == 'Download') {
      let finalDataToBeExported = [];
      finalDataToBeExported = props.data.map((item: any) => {
        const newItem: Record<string, string> = {};
        props.columnNameMapping.forEach((ele: any) => {
          newItem[ele.columnName] = item[ele.dataKey];
        });
        // if custom fields are present
        if (props.customFieldKey) {
          item[props.customFieldKey].forEach((ele: any) => {
            newItem[`Custom Field (${ele.field_name})`] = ele.field_value;
          });
        }
        return newItem;
      });
      // changing client values from 'red, green, amber, blue' to 'Overdue, At Risk, On Track, Completed'
      const modifiedStatusDataToBeExported = updateArrayForClientStatusValues(finalDataToBeExported);
      excelExport(modifiedStatusDataToBeExported, `${label_client} List`);
    }
  };

  return (
    <React.Fragment>
      <IconButton onClick={handleExcelClick} color="primary" component="span">
        <Excel />
      </IconButton>
      <CognisaasMenu
        anchorEl={anchorEl}
        open={open}
        handleSelectFromMenu={handleSelectFromMenu}
        handleMenuClose={handleExcelClose}
        list={excelMenuOptions}
      />
    </React.Fragment>
  );
};

export default ExcelFeature;
