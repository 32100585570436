import { commonAPI } from '../../../api/modules/common';
import { requestState } from '../types';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { customFieldsAPI } from 'api/modules/customFields';
import { isNull } from 'utils/dataHelpers';
import { customerPortalApi } from 'api/modules/CustomerPortal';
import { settingsAPI } from 'api/modules/Settings';
import {
  CloneAggregatedCardPayload,
  CreateAggregatedCard,
  DeleteAggregatedCardPayload,
  GetAggregatedCards,
  GetAppStateType,
  SaveAppStateType,
  UpdateAggregatedCard,
} from './types';
import { aggregatedCardAPI } from 'api/modules/aggregatedCard';
interface commonType {
  getAllUsers: Array<any>;
  getAllUsersStatus: requestState;
  getAllUsersError: null | undefined | string;

  getAllClientContacts: Array<any>;
  getAllClientContactsStatus: requestState;
  getAllClientContactsError: null | undefined | string;

  getCustomerDetail: any;
  getCustomerDetailStatus: requestState;
  getCustomerDetailError: null | undefined | string;

  getCustomFields: any;
  getCustomFieldsStatus: requestState;
  getCustomFieldsError: null | undefined | string;
  currentUserDetails: {
    data: any;
    status: requestState;
    error: null | undefined | string;
  };
  customerCustomfields: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };

  usecaseCustomFieldsWithSubFields: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };

  taskCustomFields: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };
  segmentValues: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };
  journeyStageValues: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };
  customJourneyStageValues: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };
  industryValues: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };
  milestoneStatusValues: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };
  allGeneralStatus: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };
  AllRenewalStages: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };
  allCategories: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };
  allTaskStages: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };
  allTaskPriorities: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };
  GlobalFieldLabels: {
    data: any;
    status: requestState;
    error: null | undefined | string;
  };
  clientCustomerDetail: {
    data: any;
    status: requestState;
    error: null | undefined | string;
  };
  clientsByCustomerUid: {
    data: any;
    status: requestState;
    error: null | undefined | string;
  };
  allProjectMembersWhichAreClientContact: {
    data: any[];
    status: requestState;
    message: null | undefined | string;
  };
  customSegmentValues: {
    data: any[];
    status: requestState;
    message: null | undefined | string;
  };
  projectCategoryValues: {
    data: any[];
    status: requestState;
    message: null | undefined | string;
  };
  customProjectCategoryValues: {
    data: any[];
    status: requestState;
    message: null | undefined | string;
  };
  projectStageValues: {
    data: any[];
    status: requestState;
    message: null | undefined | string;
  };
  customProjectStageValues: {
    data: any[];
    status: requestState;
    message: null | undefined | string;
  };

  usecaseCategoryValues: {
    data: any[];
    status: requestState;
    message: null | undefined | string;
  };
  customUsecaseCategoryValues: {
    data: any[];
    status: requestState;
    message: null | undefined | string;
  };
  usecaseStageValues: {
    data: any[];
    status: requestState;
    message: null | undefined | string;
  };
  customUsecaseStageValues: {
    data: any[];
    status: requestState;
    message: null | undefined | string;
  };
  appStates: {
    data: any[];
    status: requestState;
    message: null | undefined | string;
  };

  projectPriorityValues: {
    data: any[];
    status: requestState;
    message: null | undefined | string;
  };
  customProjectPriorityValues: {
    data: any[];
    status: requestState;
    message: null | undefined | string;
  };

  usecasePriorityValues: {
    data: any[];
    status: requestState;
    message: null | undefined | string;
  };
  customUsecasePriorityValues: {
    data: any[];
    status: requestState;
    message: null | undefined | string;
  };

  taskPriorityValues: {
    data: any[];
    status: requestState;
    message: null | undefined | string;
  };
  customTaskPriorityValues: {
    data: any[];
    status: requestState;
    message: null | undefined | string;
  };

  taskStageValues: {
    data: any[];
    status: requestState;
    message: null | undefined | string;
  };
  customTaskStageValues: {
    data: any[];
    status: requestState;
    message: null | undefined | string;
  };
  allAggregatedCards: {
    data: any[];
    status: requestState;
    message: null | undefined | string;
  };
}
// getCustomFieldsOfTaskByCustomerUid

const initialState: commonType = {
  getAllUsers: [],
  getAllUsersStatus: requestState.idle,
  getAllUsersError: null,

  getAllClientContacts: [],
  getAllClientContactsStatus: requestState.idle,
  getAllClientContactsError: null,

  getCustomerDetail: null,
  getCustomerDetailStatus: requestState.idle,
  getCustomerDetailError: null,

  getCustomFields: null,
  getCustomFieldsStatus: requestState.idle,
  getCustomFieldsError: null,

  currentUserDetails: {
    data: {},
    status: requestState.idle,
    error: null,
  },

  customerCustomfields: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  usecaseCustomFieldsWithSubFields: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  taskCustomFields: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  segmentValues: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  journeyStageValues: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  customJourneyStageValues: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  industryValues: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  milestoneStatusValues: {
    data: ['Completed', 'Not Completed'],
    status: requestState.idle,
    error: null,
  },
  allGeneralStatus: {
    data: ['red', 'amber', 'green', 'blue'],
    status: requestState.idle,
    error: null,
  },
  AllRenewalStages: {
    data: ['Not Done', 'Done'],
    status: requestState.idle,
    error: null,
  },
  allCategories: {
    data: ['Land', 'Expand', 'Maintenance', 'Proof of Concept'],
    status: requestState.idle,
    error: null,
  },
  allTaskStages: {
    data: ['Not Started', 'In Progress', 'Blocked', 'Done', 'Deferred'],
    status: requestState.idle,
    error: null,
  },
  allTaskPriorities: {
    data: ['Critical', 'High', 'Medium', 'Low'],
    status: requestState.idle,
    error: null,
  },
  GlobalFieldLabels: {
    data: {},
    status: requestState.idle,
    error: null,
  },
  clientCustomerDetail: {
    data: null,
    status: requestState.idle,
    error: null,
  },
  clientsByCustomerUid: {
    data: null,
    status: requestState.idle,
    error: null,
  },
  allProjectMembersWhichAreClientContact: {
    data: [],
    status: requestState.idle,
    message: null,
  },
  customSegmentValues: {
    data: [],
    status: requestState.idle,
    message: null,
  },
  projectCategoryValues: {
    data: [],
    status: requestState.idle,
    message: null,
  },
  customProjectCategoryValues: {
    data: [],
    status: requestState.idle,
    message: null,
  },
  projectStageValues: {
    data: [],
    status: requestState.idle,
    message: null,
  },
  customProjectStageValues: {
    data: [],
    status: requestState.idle,
    message: null,
  },

  usecaseCategoryValues: {
    data: [],
    status: requestState.idle,
    message: null,
  },
  customUsecaseCategoryValues: {
    data: [],
    status: requestState.idle,
    message: null,
  },
  usecaseStageValues: {
    data: [],
    status: requestState.idle,
    message: null,
  },
  customUsecaseStageValues: {
    data: [],
    status: requestState.idle,
    message: null,
  },
  appStates: {
    data: [],
    status: requestState.idle,
    message: null,
  },

  projectPriorityValues: {
    data: [],
    status: requestState.idle,
    message: null,
  },
  customProjectPriorityValues: {
    data: [],
    status: requestState.idle,
    message: null,
  },

  usecasePriorityValues: {
    data: [],
    status: requestState.idle,
    message: null,
  },
  customUsecasePriorityValues: {
    data: [],
    status: requestState.idle,
    message: null,
  },

  taskPriorityValues: {
    data: [],
    status: requestState.idle,
    message: null,
  },
  customTaskPriorityValues: {
    data: [],
    status: requestState.idle,
    message: null,
  },

  taskStageValues: {
    data: [],
    status: requestState.idle,
    message: null,
  },
  customTaskStageValues: {
    data: [],
    status: requestState.idle,
    message: null,
  },
  allAggregatedCards: {
    data: [],
    status: requestState.idle,
    message: null,
  },
};

export const createAggregationCard = createAsyncThunk(
  'common/createAggregationCard',
  async (payload: CreateAggregatedCard) => {
    const { data } = await aggregatedCardAPI.createAggregationCard(payload);
    return data.result ?? {};
  }
);

export const updateAggregationCard = createAsyncThunk(
  'common/updateAggregationCard',
  async (payload: UpdateAggregatedCard) => {
    const { data } = await aggregatedCardAPI.updateAggregationCard(payload);
    return data.result ?? {};
  }
);

export const getAllAggregatedCards = createAsyncThunk(
  'common/getAllAggregatedCards',
  async (payload: GetAggregatedCards) => {
    const { data } = await aggregatedCardAPI.getAggregationCards(payload);
    return data.result ?? {};
  }
);

export const cloneAggregationCard = createAsyncThunk(
  'common/cloneAggregationCard',
  async (payload: CloneAggregatedCardPayload) => {
    const { data } = await aggregatedCardAPI.cloneAggregationCard(payload);
    return data.result ?? {};
  }
);

export const deleteAggregationCard = createAsyncThunk(
  'common/deleteAggregationCard',
  async (payload: DeleteAggregatedCardPayload) => {
    const { data } = await aggregatedCardAPI.deleteAggregationCard(payload);
    return data.result ?? {};
  }
);

export const getCurrentUserDetails = createAsyncThunk('common/getCurrentUser', async () => {
  const { data } = await commonAPI.getUserDetail();
  return data.result ?? {};
});

export const getAllUsers = createAsyncThunk('commin/getAllUsers', async () => {
  const { data } = await commonAPI.getCompanyUsers();
  return data.result ?? [];
});

export const getAllClientContacts = createAsyncThunk('commin/getAllClientContactsByCustomerUid', async () => {
  const { data } = await commonAPI.getAllClientContactsByCustomerUid();
  return data.result ?? [];
});
export const getAllClientsByCustomerUidForClientContactReport = createAsyncThunk(
  'commin/getAllClientsByCustomerUid',
  async () => {
    const { data } = await commonAPI.getAllClientsByCustomerUid();
    return data.result ?? [];
  }
);

export const fetchAllIndustries = createAsyncThunk('commin/fetchAllIndustries', async () => {
  const { data } = await commonAPI.getIndustryValue();
  return data.result ?? [];
});

export const fetchAllJourneyStages = createAsyncThunk('commin/fetchAllJourneyStages', async () => {
  const { data } = await commonAPI.getJourneyStage();
  return data.result ?? [];
});

export const fetchAllCustomJourneyStages = createAsyncThunk('common/fetchAllCustomJourneyStages', async () => {
  const { data } = await commonAPI.getCustomJourneyState_Data();
  return data.result ?? [];
});

export const getAllSegmentValues = createAsyncThunk('common/getAllSegmentValues', async () => {
  const { data } = await commonAPI.getSegmentValue();
  return data.result ?? [];
});

export const getCustomerDetail = createAsyncThunk('common/getCustomerDetail', async () => {
  const { data } = await commonAPI.getCustomerDetail();
  return data.result ?? [];
});

export const getCustomFields = createAsyncThunk('common/customfields', async () => {
  const { data } = await commonAPI.getCustomFields();
  return data.result ?? [];
});

export const updateCaseWithfeatureStatus = createAsyncThunk(
  'common/updateCaseWithfeatureStatus',
  async (client_uid: string) => {
    await commonAPI.updateCaseWithFeatureStatus(client_uid);
  }
);

export const updateCaseStatusByCustomer = createAsyncThunk('common/updateCaseStatusByCustomer', async () => {
  await commonAPI.updateCaseStatusByCustomer();
});
export const updateTimeTrackingInSettings = createAsyncThunk(
  'common/updateTimeTrackingInSettings',
  async (details: any) => {
    const { data } = await commonAPI.updateTimeTrackingInSettings(details);
    return data;
  }
);

export const fetchCustomerLevelCustomFields = createAsyncThunk('common/fetchCustomerLevelCustomFields', async () => {
  const { data } = await customFieldsAPI.getCustomFieldsByCustomerUid();
  return data.result ?? [];
});

export const fetchCustomFieldsUsecaseAndSubfields = createAsyncThunk(
  'common/fetchCustomFieldsUsecaseAndSubfields',
  async () => {
    const { data } = await customFieldsAPI.getCustomFieldsWithUsecaseAndItsSubFields();
    return data.result;
  }
);

export const fetchCustomFieldsOfTask = createAsyncThunk('common/fetchCustomFieldsOfTask', async () => {
  const { data } = await customFieldsAPI.getCustomFieldsOfTaskByCustomerUid();
  return data.result ?? [];
});

export const fetchCustomLabel = createAsyncThunk('common/fetchCustomLabel', async () => {
  const data = await commonAPI.getRenamedLabels();
  return data;
});

export const client_getCustomerDetail = createAsyncThunk('client_getCustomerDetail', async () => {
  const { data } = await customerPortalApi.client_getCustomerDetail();
  return data;
});

export const getAllProjectMembersThatAreClientContact = createAsyncThunk(
  'common/getAllProjectMembersThatAreClientContact',
  async () => {
    const { data } = await commonAPI.getAllProjectMembersWhichAreClientContact();
    return data;
  }
);

export const getCustomSegmentValues = createAsyncThunk('common/getCustomSegmentValues', async () => {
  const { data } = await settingsAPI.getCustomClientSegmentValues();
  return data ?? [];
});

export const getProjectCategoryValues = createAsyncThunk('common/getProjectCategoryValues', async () => {
  const { data } = await commonAPI.getProjectCategories();
  return data ?? [];
});

export const getProjectCustomCategoryValues = createAsyncThunk('common/getProjectCustomCategoryValues', async () => {
  const { data } = await commonAPI.getProjectCustomCategories();
  return data ?? [];
});

export const getProjectStageValues = createAsyncThunk('common/getProjectStageValues', async () => {
  const { data } = await commonAPI.getProjectStage();
  return data ?? [];
});

export const getProjectCustomStageValues = createAsyncThunk('common/getProjectCustomStageValues', async () => {
  const { data } = await commonAPI.getProjectCustomStage();
  return data ?? [];
});

// usecase
export const getUsecaseCategoryValues = createAsyncThunk('common/getUsecaseCategoryValues', async () => {
  const { data } = await commonAPI.getUsecaseCategories();
  return data ?? [];
});

export const getUsecaseCustomCategoryValues = createAsyncThunk('common/getUsecaseCustomCategoryValues', async () => {
  const { data } = await commonAPI.getUsecaseCustomCategories();
  return data ?? [];
});

export const getUsecaseStageValues = createAsyncThunk('common/getUsecaseStageValues', async () => {
  const { data } = await commonAPI.getUsecaseStage();
  return data ?? [];
});

export const getUsecaseCustomStageValues = createAsyncThunk('common/getUsecaseCustomStageValues', async () => {
  const { data } = await commonAPI.getUsecaseCustomStage();
  return data ?? [];
});

export const saveAppStateConfiguration = createAsyncThunk(
  'common/saveAppStateConfiguration',
  async (payload: SaveAppStateType) => {
    const { data } = await commonAPI.saveAppState(payload);
    return data ?? [];
  }
);

export const getAppStateConfigurationsFromDb = createAsyncThunk(
  'common/getAppStateConfigurationsFromDb',
  async (payload: GetAppStateType) => {
    const { data } = await commonAPI.getAppStateConfigurations(payload);
    return data.result ?? [];
  }
);

export const getProjectPriorityValues = createAsyncThunk('common/getProjectPriorityValues', async () => {
  const { data } = await commonAPI.getProjectPriority();
  return data ?? [];
});

export const getProjectCustomPriorityValues = createAsyncThunk('common/getProjectCustomPriorityValues', async () => {
  const { data } = await commonAPI.getProjectCustomPriority();
  return data ?? [];
});

export const getUsecasePriorityValues = createAsyncThunk('common/getUsecasePriorityValues', async () => {
  const { data } = await commonAPI.getUsecasePriority();
  return data ?? [];
});

export const getUsecaseCustomPriorityValues = createAsyncThunk('common/getUsecaseCustomPriorityValues', async () => {
  const { data } = await commonAPI.getUsecaseCustomPriority();
  return data ?? [];
});

export const getTaskPriorityValues = createAsyncThunk('common/getTaskPriorityValues', async () => {
  const { data } = await commonAPI.getTaskPriority();
  return data ?? [];
});

export const getTaskCustomPriorityValues = createAsyncThunk('common/getTaskCustomPriorityValues', async () => {
  const { data } = await commonAPI.getTaskCustomPriority();
  return data ?? [];
});

export const getTaskStageValues = createAsyncThunk('common/getTaskStageValues', async () => {
  const { data } = await commonAPI.getTaskStage();
  return data ?? [];
});

export const getTaskCustomStageValues = createAsyncThunk('common/getTaskCustomStageValues', async () => {
  const { data } = await commonAPI.getTaskCustomStage();
  return data ?? [];
});

export const getStandardAndCustomNamesOfAllEntites = createAsyncThunk(
  'common/getStandardAndCustomNamesOfAllEntites',
  async () => {
    const { data } = await commonAPI.getStandardAndCustomNames();
    return data ?? [];
  }
);

const commonSlice = createSlice({
  name: 'commonSlice',
  initialState,
  reducers: {
    resetCustomerFieldsStatus: (state: commonType) => {
      state.customerCustomfields.status = initialState.customerCustomfields.status;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAllUsers.pending, (state) => {
        state.getAllUsersStatus = requestState.loading;
      })
      .addCase(getAllUsers.fulfilled, (state, action: any) => {
        state.getAllUsersStatus = requestState.success;
        if (action.payload) {
          state.getAllUsers = [...action.payload];
        } else {
          state.getAllUsers = [];
        }
      })
      .addCase(getAllUsers.rejected, (state, action) => {
        state.getAllUsersStatus = requestState.failed;
        state.getAllUsersError = action.error.message;
      })

      // Get all client Contacts by customer_uid
      .addCase(getAllClientContacts.pending, (state) => {
        state.getAllClientContactsStatus = requestState.loading;
      })
      .addCase(getAllClientContacts.fulfilled, (state, action: any) => {
        state.getAllClientContactsStatus = requestState.success;
        if (action.payload) {
          state.getAllClientContacts = [...action.payload];
        } else {
          state.getAllClientContacts = [];
        }
      })
      .addCase(getAllClientContacts.rejected, (state, action) => {
        state.getAllClientContactsStatus = requestState.failed;
        state.getAllClientContactsError = action.error.message;
      })

      // clients by customer uid
      .addCase(getAllClientsByCustomerUidForClientContactReport.pending, (state) => {
        state.clientsByCustomerUid.status = requestState.loading;
      })
      .addCase(getAllClientsByCustomerUidForClientContactReport.fulfilled, (state, action: PayloadAction<any>) => {
        state.clientsByCustomerUid.status = requestState.success;
        if (action.payload) {
          state.clientsByCustomerUid.data = [...action.payload];
        } else {
          state.clientsByCustomerUid.data = [];
        }
      })
      .addCase(getAllClientsByCustomerUidForClientContactReport.rejected, (state, action) => {
        state.clientsByCustomerUid.status = requestState.failed;
        state.clientsByCustomerUid.error = action.error.message;
      })

      // get customer Details
      .addCase(getCustomerDetail.pending, (state) => {
        state.getCustomerDetailStatus = requestState.loading;
      })
      .addCase(getCustomerDetail.fulfilled, (state, action: any) => {
        state.getCustomerDetailStatus = requestState.success;
        state.getCustomerDetail = { ...action.payload };
      })
      .addCase(getCustomerDetail.rejected, (state, action) => {
        state.getCustomerDetailStatus = requestState.failed;
        state.getCustomerDetailError = action.error.message;
      })

      // get custom field Details
      .addCase(getCustomFields.pending, (state) => {
        state.getCustomFieldsStatus = requestState.loading;
      })
      .addCase(getCustomFields.fulfilled, (state, action: any) => {
        state.getCustomFieldsStatus = requestState.success;
        state.getCustomFields = action.payload ? [...action.payload] : [];
      })
      .addCase(getCustomFields.rejected, (state, action) => {
        state.getCustomFieldsStatus = requestState.failed;
        state.getCustomFieldsError = action.error.message;
      })
      // get customer level custom fields fetchCustomerLevelCustomFields
      .addCase(fetchCustomerLevelCustomFields.pending, (state) => {
        state.customerCustomfields.status = requestState.loading;
      })
      .addCase(fetchCustomerLevelCustomFields.fulfilled, (state, action: PayloadAction<any>) => {
        state.customerCustomfields.status = requestState.success;
        if (!isNull(action.payload)) {
          state.customerCustomfields.data = action.payload;
        } else {
          state.customerCustomfields.data = [];
        }
      })
      .addCase(fetchCustomerLevelCustomFields.rejected, (state, action) => {
        state.customerCustomfields.status = requestState.failed;
        state.customerCustomfields.error = action.error.message;
      })
      // fetchCustomFieldsUsecaseAndSubfields
      .addCase(fetchCustomFieldsUsecaseAndSubfields.pending, (state) => {
        state.usecaseCustomFieldsWithSubFields.status = requestState.loading;
      })
      .addCase(fetchCustomFieldsUsecaseAndSubfields.fulfilled, (state, action: PayloadAction<any>) => {
        state.usecaseCustomFieldsWithSubFields.status = requestState.success;
        if (!isNull(action.payload)) {
          state.usecaseCustomFieldsWithSubFields.data = action.payload;
        } else {
          state.usecaseCustomFieldsWithSubFields.data = [];
        }
      })
      .addCase(fetchCustomFieldsUsecaseAndSubfields.rejected, (state, action) => {
        state.usecaseCustomFieldsWithSubFields.status = requestState.failed;
        state.usecaseCustomFieldsWithSubFields.error = action.error.message;
      })
      // fetchCustomFieldsOfTask
      .addCase(fetchCustomFieldsOfTask.pending, (state) => {
        state.taskCustomFields.status = requestState.loading;
      })
      .addCase(fetchCustomFieldsOfTask.fulfilled, (state, action: PayloadAction<any>) => {
        state.taskCustomFields.status = requestState.success;
        if (!isNull(action.payload)) {
          state.taskCustomFields.data = action.payload;
        } else {
          state.taskCustomFields.data = [];
        }
      })
      .addCase(fetchCustomFieldsOfTask.rejected, (state, action) => {
        state.taskCustomFields.status = requestState.failed;
        state.taskCustomFields.error = action.error.message;
      })
      // getAllSegmentValues
      .addCase(getAllSegmentValues.pending, (state) => {
        state.segmentValues.status = requestState.loading;
      })
      .addCase(getAllSegmentValues.fulfilled, (state, action: PayloadAction<any>) => {
        state.segmentValues.status = requestState.success;

        if (!isNull(action.payload)) {
          state.segmentValues.data = action.payload;
        } else {
          state.segmentValues.data = [];
        }
      })
      .addCase(getAllSegmentValues.rejected, (state, action) => {
        state.segmentValues.status = requestState.failed;
        state.segmentValues.error = action.error.message;
      })
      // fetchAllJourneyStages
      .addCase(fetchAllJourneyStages.pending, (state) => {
        state.journeyStageValues.status = requestState.loading;
      })
      .addCase(fetchAllJourneyStages.fulfilled, (state, action: PayloadAction<any>) => {
        state.journeyStageValues.status = requestState.success;

        if (!isNull(action.payload)) {
          state.journeyStageValues.data = action.payload;
        } else {
          state.journeyStageValues.data = [];
        }
      })
      .addCase(fetchAllJourneyStages.rejected, (state, action) => {
        state.journeyStageValues.status = requestState.failed;
        state.journeyStageValues.error = action.error.message;
      })
      // fetchAllIndustries
      .addCase(fetchAllIndustries.pending, (state) => {
        state.industryValues.status = requestState.loading;
      })
      .addCase(fetchAllIndustries.fulfilled, (state, action: PayloadAction<any>) => {
        state.industryValues.status = requestState.success;

        if (!isNull(action.payload)) {
          state.industryValues.data = action.payload;
        } else {
          state.industryValues.data = [];
        }
      })
      .addCase(fetchAllIndustries.rejected, (state, action) => {
        state.industryValues.status = requestState.failed;
        state.industryValues.error = action.error.message;
      })
      //getCurrentUserDetails
      .addCase(getCurrentUserDetails.pending, (state) => {
        state.currentUserDetails.status = requestState.loading;
      })
      .addCase(getCurrentUserDetails.fulfilled, (state, action: PayloadAction<any>) => {
        state.currentUserDetails.status = requestState.success;

        if (!isNull(action.payload)) {
          state.currentUserDetails.data = action.payload;
        } else {
          state.currentUserDetails.data = {};
        }
      })
      .addCase(getCurrentUserDetails.rejected, (state, action) => {
        state.currentUserDetails.status = requestState.failed;
        state.currentUserDetails.error = action.error.message;
      })
      // GlobalFieldLabels
      .addCase(fetchCustomLabel.pending, (state) => {
        state.GlobalFieldLabels.status = requestState.loading;
      })
      .addCase(fetchCustomLabel.fulfilled, (state, action: PayloadAction<any>) => {
        state.GlobalFieldLabels.status = requestState.success;
        state.GlobalFieldLabels.data = action.payload;
      })
      .addCase(fetchCustomLabel.rejected, (state, action) => {
        state.GlobalFieldLabels.status = requestState.failed;
        state.GlobalFieldLabels.error = action.error.message;
      })
      .addCase(client_getCustomerDetail.pending, (state) => {
        state.getCustomerDetailStatus = requestState.loading;
      })
      .addCase(client_getCustomerDetail.fulfilled, (state, action: any) => {
        state.getCustomerDetailStatus = requestState.success;
        state.getCustomerDetail = { ...action.payload };
      })
      .addCase(client_getCustomerDetail.rejected, (state, action) => {
        state.getCustomerDetailStatus = requestState.failed;
        state.getCustomerDetailError = action.error.message;
      })
      .addCase(getAllProjectMembersThatAreClientContact.pending, (state) => {
        state.allProjectMembersWhichAreClientContact.status = requestState.loading;
      })
      .addCase(getAllProjectMembersThatAreClientContact.fulfilled, (state, action: any) => {
        state.allProjectMembersWhichAreClientContact.status = requestState.success;
        if (isNull(action.payload) || isNull(action.payload.result)) {
          state.allProjectMembersWhichAreClientContact.data = [];
        } else {
          state.allProjectMembersWhichAreClientContact.data = [...action.payload.result];
        }
      })
      .addCase(getAllProjectMembersThatAreClientContact.rejected, (state, action) => {
        state.allProjectMembersWhichAreClientContact.status = requestState.failed;
        state.allProjectMembersWhichAreClientContact.message = action.error.message;
      })
      // getCustomSegmentValues
      .addCase(getCustomSegmentValues.pending, (state) => {
        state.customSegmentValues.status = requestState.loading;
      })
      .addCase(getCustomSegmentValues.fulfilled, (state, action: any) => {
        state.customSegmentValues.status = requestState.success;
        if (isNull(action.payload) || isNull(action.payload.result)) {
          state.customSegmentValues.data = [];
        } else {
          state.customSegmentValues.data = [...action.payload.result];
        }
      })
      .addCase(getCustomSegmentValues.rejected, (state, action) => {
        state.customSegmentValues.status = requestState.failed;
        state.customSegmentValues.message = action.error.message;
      })
      // get project categories
      .addCase(getProjectCategoryValues.pending, (state) => {
        state.projectCategoryValues.status = requestState.loading;
      })
      .addCase(getProjectCategoryValues.fulfilled, (state, action: any) => {
        state.projectCategoryValues.status = requestState.success;
        if (isNull(action.payload) || isNull(action.payload.result)) {
          state.projectCategoryValues.data = [];
        } else {
          state.projectCategoryValues.data = [...action.payload.result];
        }
      })
      .addCase(getProjectCategoryValues.rejected, (state, action) => {
        state.projectCategoryValues.status = requestState.failed;
        state.projectCategoryValues.message = action.error.message;
      })
      // get project custom categories
      .addCase(getProjectCustomCategoryValues.pending, (state) => {
        state.customProjectCategoryValues.status = requestState.loading;
      })
      .addCase(getProjectCustomCategoryValues.fulfilled, (state, action: any) => {
        state.customProjectCategoryValues.status = requestState.success;
        if (isNull(action.payload) || isNull(action.payload.result)) {
          state.customProjectCategoryValues.data = [];
        } else {
          state.customProjectCategoryValues.data = [...action.payload.result];
        }
      })
      .addCase(getProjectCustomCategoryValues.rejected, (state, action) => {
        state.customProjectCategoryValues.status = requestState.failed;
        state.customProjectCategoryValues.message = action.error.message;
      })
      // get project stages
      .addCase(getProjectStageValues.pending, (state) => {
        state.projectStageValues.status = requestState.loading;
      })
      .addCase(getProjectStageValues.fulfilled, (state, action: any) => {
        state.projectStageValues.status = requestState.success;
        if (isNull(action.payload) || isNull(action.payload.result)) {
          state.projectStageValues.data = [];
        } else {
          state.projectStageValues.data = [...action.payload.result];
        }
      })
      .addCase(getProjectStageValues.rejected, (state, action) => {
        state.projectStageValues.status = requestState.failed;
        state.projectStageValues.message = action.error.message;
      })
      // get project custom stages
      .addCase(getProjectCustomStageValues.pending, (state) => {
        state.customProjectStageValues.status = requestState.loading;
      })
      .addCase(getProjectCustomStageValues.fulfilled, (state, action: any) => {
        state.customProjectStageValues.status = requestState.success;
        if (isNull(action.payload) || isNull(action.payload.result)) {
          state.customProjectStageValues.data = [];
        } else {
          state.customProjectStageValues.data = [...action.payload.result];
        }
      })
      .addCase(getProjectCustomStageValues.rejected, (state, action) => {
        state.customProjectStageValues.status = requestState.failed;
        state.customProjectStageValues.message = action.error.message;
      })
      // get usecase categories
      .addCase(getUsecaseCategoryValues.pending, (state) => {
        state.usecaseCategoryValues.status = requestState.loading;
      })
      .addCase(getUsecaseCategoryValues.fulfilled, (state, action: any) => {
        state.usecaseCategoryValues.status = requestState.success;
        if (isNull(action.payload) || isNull(action.payload.result)) {
          state.usecaseCategoryValues.data = [];
        } else {
          state.usecaseCategoryValues.data = [...action.payload.result];
        }
      })
      .addCase(getUsecaseCategoryValues.rejected, (state, action) => {
        state.usecaseCategoryValues.status = requestState.failed;
        state.usecaseCategoryValues.message = action.error.message;
      })
      // get usecase custom categories
      .addCase(getUsecaseCustomCategoryValues.pending, (state) => {
        state.customUsecaseCategoryValues.status = requestState.loading;
      })
      .addCase(getUsecaseCustomCategoryValues.fulfilled, (state, action: any) => {
        state.customUsecaseCategoryValues.status = requestState.success;
        if (isNull(action.payload) || isNull(action.payload.result)) {
          state.customUsecaseCategoryValues.data = [];
        } else {
          state.customUsecaseCategoryValues.data = [...action.payload.result];
        }
      })
      .addCase(getUsecaseCustomCategoryValues.rejected, (state, action) => {
        state.customUsecaseCategoryValues.status = requestState.failed;
        state.customUsecaseCategoryValues.message = action.error.message;
      })
      // get usecase stages
      .addCase(getUsecaseStageValues.pending, (state) => {
        state.usecaseStageValues.status = requestState.loading;
      })
      .addCase(getUsecaseStageValues.fulfilled, (state, action: any) => {
        state.usecaseStageValues.status = requestState.success;
        if (isNull(action.payload) || isNull(action.payload.result)) {
          state.usecaseStageValues.data = [];
        } else {
          state.usecaseStageValues.data = [...action.payload.result];
        }
      })
      .addCase(getUsecaseStageValues.rejected, (state, action) => {
        state.usecaseStageValues.status = requestState.failed;
        state.usecaseStageValues.message = action.error.message;
      })
      // get usecase custom stages
      .addCase(getUsecaseCustomStageValues.pending, (state) => {
        state.customUsecaseStageValues.status = requestState.loading;
      })
      .addCase(getUsecaseCustomStageValues.fulfilled, (state, action: any) => {
        state.customUsecaseStageValues.status = requestState.success;
        if (isNull(action.payload) || isNull(action.payload.result)) {
          state.customUsecaseStageValues.data = [];
        } else {
          state.customUsecaseStageValues.data = [...action.payload.result];
        }
      })
      .addCase(getUsecaseCustomStageValues.rejected, (state, action) => {
        state.customUsecaseStageValues.status = requestState.failed;
        state.customUsecaseStageValues.message = action.error.message;
      })
      // client custom journey stages
      // fetchAllCustomJourneyStages
      .addCase(fetchAllCustomJourneyStages.pending, (state) => {
        state.customJourneyStageValues.status = requestState.loading;
      })
      .addCase(fetchAllCustomJourneyStages.fulfilled, (state, action: any) => {
        state.customJourneyStageValues.status = requestState.success;
        if (isNull(action.payload)) {
          state.customJourneyStageValues.data = [];
        } else {
          state.customJourneyStageValues.data = [...action.payload];
        }
      })
      .addCase(fetchAllCustomJourneyStages.rejected, (state, action) => {
        state.customJourneyStageValues.status = requestState.failed;
        state.customJourneyStageValues.error = action.error.message;
      })
      // save app state
      .addCase(saveAppStateConfiguration.pending, (state) => {
        state.appStates.status = requestState.loading;
      })
      .addCase(saveAppStateConfiguration.fulfilled, (state, action: any) => {
        state.appStates.status = requestState.success;
        console.log('mys pay', action.payload);
        // if (isNull(action.payload)) {
        //   state.appStates.data = [];
        // } else {
        //   state.appStates.data = [...action.payload];
        // }
      })
      .addCase(saveAppStateConfiguration.rejected, (state, action) => {
        state.appStates.status = requestState.failed;
        state.appStates.message = action.error.message;
      })
      // getAppStateConfigurationsFromDb
      .addCase(getAppStateConfigurationsFromDb.pending, (state) => {
        state.appStates.status = requestState.loading;
      })
      .addCase(getAppStateConfigurationsFromDb.fulfilled, (state, action: any) => {
        state.appStates.status = requestState.success;
        console.log('get config', action.payload);
        if (isNull(action.payload)) {
          state.appStates.data = [];
        } else {
          state.appStates.data = [...action.payload];
        }
      })
      .addCase(getAppStateConfigurationsFromDb.rejected, (state, action) => {
        state.appStates.status = requestState.failed;
        state.appStates.message = action.error.message;
      })
      // get project prioritiess
      .addCase(getProjectPriorityValues.pending, (state) => {
        state.projectPriorityValues.status = requestState.loading;
      })
      .addCase(getProjectPriorityValues.fulfilled, (state, action: any) => {
        state.projectPriorityValues.status = requestState.success;
        if (isNull(action.payload) || isNull(action.payload.result)) {
          state.projectPriorityValues.data = [];
        } else {
          state.projectPriorityValues.data = [...action.payload.result];
        }
      })
      .addCase(getProjectPriorityValues.rejected, (state, action) => {
        state.projectPriorityValues.status = requestState.failed;
        state.projectPriorityValues.message = action.error.message;
      })
      // get project custom prioritiess
      .addCase(getProjectCustomPriorityValues.pending, (state) => {
        state.customProjectPriorityValues.status = requestState.loading;
      })
      .addCase(getProjectCustomPriorityValues.fulfilled, (state, action: any) => {
        state.customProjectPriorityValues.status = requestState.success;
        if (isNull(action.payload) || isNull(action.payload.result)) {
          state.customProjectPriorityValues.data = [];
        } else {
          state.customProjectPriorityValues.data = [...action.payload.result];
        }
      })
      .addCase(getProjectCustomPriorityValues.rejected, (state, action) => {
        state.customProjectPriorityValues.status = requestState.failed;
        state.customProjectPriorityValues.message = action.error.message;
      })

      // get usecase prioritiess
      .addCase(getUsecasePriorityValues.pending, (state) => {
        state.usecasePriorityValues.status = requestState.loading;
      })
      .addCase(getUsecasePriorityValues.fulfilled, (state, action: any) => {
        state.usecasePriorityValues.status = requestState.success;
        if (isNull(action.payload) || isNull(action.payload.result)) {
          state.usecasePriorityValues.data = [];
        } else {
          state.usecasePriorityValues.data = [...action.payload.result];
        }
      })
      .addCase(getUsecasePriorityValues.rejected, (state, action) => {
        state.usecasePriorityValues.status = requestState.failed;
        state.usecasePriorityValues.message = action.error.message;
      })
      // get usecase custom prioritiess
      .addCase(getUsecaseCustomPriorityValues.pending, (state) => {
        state.customUsecasePriorityValues.status = requestState.loading;
      })
      .addCase(getUsecaseCustomPriorityValues.fulfilled, (state, action: any) => {
        state.customUsecasePriorityValues.status = requestState.success;
        if (isNull(action.payload) || isNull(action.payload.result)) {
          state.customUsecasePriorityValues.data = [];
        } else {
          state.customUsecasePriorityValues.data = [...action.payload.result];
        }
      })
      .addCase(getUsecaseCustomPriorityValues.rejected, (state, action) => {
        state.customUsecasePriorityValues.status = requestState.failed;
        state.customUsecasePriorityValues.message = action.error.message;
      })

      // get task prioritiess
      .addCase(getTaskPriorityValues.pending, (state) => {
        state.taskPriorityValues.status = requestState.loading;
      })
      .addCase(getTaskPriorityValues.fulfilled, (state, action: any) => {
        state.taskPriorityValues.status = requestState.success;
        if (isNull(action.payload) || isNull(action.payload.result)) {
          state.taskPriorityValues.data = [];
        } else {
          state.taskPriorityValues.data = [...action.payload.result];
        }
      })
      .addCase(getTaskPriorityValues.rejected, (state, action) => {
        state.taskPriorityValues.status = requestState.failed;
        state.taskPriorityValues.message = action.error.message;
      })
      // get task custom prioritiess
      .addCase(getTaskCustomPriorityValues.pending, (state) => {
        state.customTaskPriorityValues.status = requestState.loading;
      })
      .addCase(getTaskCustomPriorityValues.fulfilled, (state, action: any) => {
        state.customTaskPriorityValues.status = requestState.success;
        if (isNull(action.payload) || isNull(action.payload.result)) {
          state.customTaskPriorityValues.data = [];
        } else {
          state.customTaskPriorityValues.data = [...action.payload.result];
        }
      })
      .addCase(getTaskCustomPriorityValues.rejected, (state, action) => {
        state.customTaskPriorityValues.status = requestState.failed;
        state.customTaskPriorityValues.message = action.error.message;
      })

      // get task stages
      .addCase(getTaskStageValues.pending, (state) => {
        state.taskStageValues.status = requestState.loading;
      })
      .addCase(getTaskStageValues.fulfilled, (state, action: any) => {
        state.taskStageValues.status = requestState.success;
        if (isNull(action.payload) || isNull(action.payload.result)) {
          state.taskStageValues.data = [];
        } else {
          state.taskStageValues.data = [...action.payload.result];
        }
      })
      .addCase(getTaskStageValues.rejected, (state, action) => {
        state.taskStageValues.status = requestState.failed;
        state.taskStageValues.message = action.error.message;
      })
      // get task custom stages
      .addCase(getTaskCustomStageValues.pending, (state) => {
        state.customTaskStageValues.status = requestState.loading;
      })
      .addCase(getTaskCustomStageValues.fulfilled, (state, action: any) => {
        state.customTaskStageValues.status = requestState.success;
        if (isNull(action.payload) || isNull(action.payload.result)) {
          state.customTaskStageValues.data = [];
        } else {
          state.customTaskStageValues.data = [...action.payload.result];
        }
      })
      .addCase(getTaskCustomStageValues.rejected, (state, action) => {
        state.customTaskStageValues.status = requestState.failed;
        state.customTaskStageValues.message = action.error.message;
      })
      .addCase(getStandardAndCustomNamesOfAllEntites.fulfilled, (state, action: any) => {
        // state.customTaskStageValues.status = requestState.success;
        if (!isNull(action.payload) || !isNull(action.payload.result)) {
          const data: Record<string, any> = action.payload.result;
          console.log('custom name', action.payload.result);
          state.segmentValues.data = data['standard_client_segments'];
          state.segmentValues.status = requestState.success;
          state.customSegmentValues.data = data['custom_client_segments'];
          state.customSegmentValues.status = requestState.success;
          state.projectCategoryValues.data = data['standard_project_categories'];
          state.projectCategoryValues.status = requestState.success;
          state.customProjectCategoryValues.data = data['custom_project_categories'];
          state.customProjectCategoryValues.status = requestState.success;
          state.projectStageValues.data = data['standard_project_stage'];
          state.projectStageValues.status = requestState.success;
          state.customProjectStageValues.data = data['custom_project_stage'];
          state.customProjectStageValues.status = requestState.success;
          state.usecaseCategoryValues.data = data['standard_usecase_category'];
          state.usecaseCategoryValues.status = requestState.success;
          state.customUsecaseCategoryValues.data = data['custom_usecase_category'];
          state.customUsecaseCategoryValues.status = requestState.success;
          state.usecaseStageValues.data = data['standard_usecase_stage'];
          state.usecaseStageValues.status = requestState.success;
          state.customUsecaseStageValues.data = data['custom_usecase_stage'];
          state.customUsecaseStageValues.status = requestState.success;
          state.projectPriorityValues.data = data['standard_project_priority'];
          state.projectPriorityValues.status = requestState.success;
          state.customProjectPriorityValues.data = data['custom_project_priority'];
          state.customProjectPriorityValues.status = requestState.success;
          state.usecasePriorityValues.data = data['standard_usecase_priority'];
          state.usecasePriorityValues.status = requestState.success;
          state.customUsecasePriorityValues.data = data['custom_usecase_priority'];
          state.customUsecasePriorityValues.status = requestState.success;
          state.taskPriorityValues.data = data['standard_task_priority'];
          state.taskPriorityValues.status = requestState.success;
          state.customTaskPriorityValues.data = data['custom_task_priority'];
          state.customTaskPriorityValues.status = requestState.success;
          state.taskStageValues.data = data['standard_task_stage'];
          state.taskStageValues.status = requestState.success;
          state.customTaskStageValues.data = data['custom_task_stage'];
          state.customTaskStageValues.status = requestState.success;
        }
      })
      // getAllAggregatedCards
      .addCase(getAllAggregatedCards.pending, (state) => {
        state.allAggregatedCards.status = requestState.loading;
      })
      .addCase(getAllAggregatedCards.fulfilled, (state, action: any) => {
        state.allAggregatedCards.status = requestState.success;
        state.allAggregatedCards.data = [...action.payload];
      })
      .addCase(getAllAggregatedCards.rejected, (state, action) => {
        state.allAggregatedCards.status = requestState.failed;
        state.allAggregatedCards.message = action.error.message;
      });
  },
});

export const { resetCustomerFieldsStatus } = commonSlice.actions;
export const commonReducer = commonSlice.reducer;
export default commonReducer;
