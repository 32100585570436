import { apis } from '../axiosInstance';
import { ProjectReport } from '../route';

const getProjectsByCustomerUid = (customer_uid: string) => {
  return apis.phpAxiosInstance.get(ProjectReport.getAllProjectsByCustomerUid(customer_uid), {
    headers: { JWT: 'true' },
  });
};

const getPhasesAndTasks = (customer_uid: string) => {
  return apis.phpAxiosInstance.get(ProjectReport.getPhasesAndTasks(customer_uid), {
    headers: { JWT: 'true' },
  });
};

const getTeamMembersData = (customer_uid: string) => {
  return apis.phpAxiosInstance.get(ProjectReport.getTeamMembers(customer_uid), {
    headers: { JWT: 'true' },
  });
};

export const projectReportsAPI = {
  getProjectsByCustomerUid,
  getPhasesAndTasks,
  getTeamMembersData,
};
