import FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { format } from 'date-fns';
import { label_client } from 'components/Settings/Preferences/CustomLabelCookieHelper';
export const excelExportData = (jsonData: any, fileName: string) => {
  const fileExtension = '.xlsx';
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const wb = XLSX.utils.book_new();
  let excelBuffer: any = '';
  const clientContactsDataTransformerForExporting = (clientContacts: any) => {
    let final: any = [];
    final = clientContacts.map(function (obj: any) {
      return {
        'Member': obj.contact_name,
        'Email': obj.contact_email,
        'Role': obj.contact_role ?? '',
        'Contact Type': obj.primary_contact === '1' || obj.primary_contact === 1 ? 'Primary' : 'Secondary',
        'Last access link sent on': Date.parse(obj.last_magiclink_sent_on) ? format(new Date(obj.last_magiclink_sent_on.replace(/-/g, '/')), 'dd MMM yyyy') : '-',
        'Last accessed portal on': Date.parse(obj.contact_last_accessed_on) ? format(new Date(obj.last_magiclink_sent_on.replace(/-/g, '/')), 'dd MMM yyyy') : '-'
      };
    });
    clientContacts.forEach((element: any, index: number) => {
      final[index][`${label_client} Name`] = element.client_name;
    });
    return final;
  };
  const updateClientContactsValues = (jsonData: any) => {
    const contacts = clientContactsDataTransformerForExporting(jsonData);
    const ws = XLSX.utils.json_to_sheet(contacts);
    XLSX.utils.book_append_sheet(wb, ws, fileName);
  };
  updateClientContactsValues(jsonData);
  excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data: Blob = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + fileExtension);
};

