import { apis } from '../axiosInstance';
import { settingRoutes } from '../route';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const getSettingPage_CustomJourneyState_Data = () => {
  return apis.phpAxiosInstance.get(
    settingRoutes.getCustomJourneyStageData(cookies.get('cogni_customer_uid') as string),
    {
      headers: { JWT: 'true' },
    }
  );
};
const getSettingPage_JourneyState_Data = () => {
  return apis.phpAxiosInstance.get(settingRoutes.getJourneyStage(), {
    headers: { JWT: 'true' },
  });
};

const updateTableForFirstTime = (payload: any) =>
  apis.phpAxiosInstance.post(settingRoutes.updateTableForFirstTime(), payload, {
    headers: { JWT: 'true' },
  });

const updateTable = (payload: any) =>
  apis.phpAxiosInstance.post(settingRoutes.updateTable(), payload, {
    headers: { JWT: 'true' },
  });
const getCustomerDetails = () =>
  apis.phpAxiosInstance.get(settingRoutes.getCustomerDetails(cookies.get('cogni_customer_uid') as string), {
    headers: { JWT: 'true' },
  });

export const enableMandatorySSO = () =>
  apis.phpAxiosInstance.post(settingRoutes.enableMandatorySSO(), {}, { headers: { JWT: 'true' } });

export const disableMandatorySSO = () =>
  apis.phpAxiosInstance.post(settingRoutes.disableMandatorySSO(), {}, { headers: { JWT: 'true' } });

const getUserDetails = (user_uid: string) =>
  apis.phpAxiosInstance.get(settingRoutes.getUserDetaisRoute(user_uid), {
    headers: { JWT: 'true' },
  });

const updateUsername = (payload: any) =>
  apis.phpAxiosInstance.post(settingRoutes.updateUsernameRoute(), payload, {
    headers: { JWT: 'true' },
  });

export const updateUseCase_Configuration = (payload: any) =>
  apis.phpAxiosInstance.post(settingRoutes.updateUsecaseInConfigurationRoute(), payload, {
    headers: { JWT: 'true' },
  });

export const update_user_email = (payload: any) =>
  apis.phpAxiosInstance.post(settingRoutes.updateUserEmail(), payload, {
    headers: { JWT: 'true' },
  });

export const update_user_password = (payload: any) =>
  apis.phpAxiosInstance.post(settingRoutes.updateUserPassword(), payload, {
    headers: { JWT: 'true' },
  });

export const getCustomFieldClientRecords = (customer_uid: string) =>
  apis.phpAxiosInstance.get(settingRoutes.getCustomField_ClientRecords(customer_uid), {
    headers: { JWT: 'true' },
  });

export const getCustomFieldProjectRecords = (customer_uid: string) =>
  apis.phpAxiosInstance.get(settingRoutes.getCustomField_ProjectRecords(customer_uid), {
    headers: { JWT: 'true' },
  });

export const getCustomFieldUsecaseRecords = (customer_uid: string) =>
  apis.phpAxiosInstance.get(settingRoutes.getCustomFIeld_UsecaseRecord(customer_uid), {
    headers: { JWT: 'true' },
  });

export const getCustomFieldTaskRecords = (customer_uid: string) =>
  apis.phpAxiosInstance.get(settingRoutes.getCustomFIeld_TaskRecord(customer_uid), {
    headers: { JWT: 'true' },
  });

export const deleteExisitingProductbyCustomerUid = (customer_uid: any) =>
  apis.phpAxiosInstance.post(
    settingRoutes.deleteExistingProduct(),
    { customer_uid },
    {
      headers: { JWT: 'true' },
    }
  );

export const saveJiraCredentials = (payload: any) =>
  apis.phpAxiosInstance.post(settingRoutes.saveJiraCredentials(), payload, {
    headers: { JWT: 'true' },
  });
export const getMergeLink = (payload: any) =>
  apis.phpAxiosInstance.post(settingRoutes.getmergeLinkToken(), payload, {
    headers: { JWT: 'true' },
  });
export const swapPublicToken = (payload: any) =>
  apis.phpAxiosInstance.post(settingRoutes.swapPublicToken(), payload, {
    headers: { JWT: 'true' },
  });
export const updateCustomerIntegrationPipedriveDetails = (payload: any) =>
  apis.phpAxiosInstance.post(settingRoutes.updateCustomerPipedriveIntegration(), payload, {
    headers: { JWT: 'true' },
  });
export const getCustomerIntegrationDetails = () =>
  apis.phpAxiosInstance.get(settingRoutes.getCustomerIntegrationDetails(cookies.get('cogni_customer_uid') as string), {
    headers: { JWT: 'true' },
  });

export const getCustomerIntegrationMicroService = () =>
  apis.phpAxiosInstance.get(
    settingRoutes.getCustomerIntegrationMicroService(cookies.get('cogni_customer_uid') as string),
    { headers: { JWT: 'true' } }
  );

export const getCRMStages = () =>
  apis.phpAxiosInstance.get(settingRoutes.getCRMStages(cookies.get('cogni_customer_uid') as string), {
    headers: { JWT: 'true' },
  });
export const updateStageAndFetchMode = (payload: any) =>
  apis.phpAxiosInstance.post(settingRoutes.updateStageAndFetchMode(), payload, { headers: { JWT: 'true' } });

export const updateJiraFetchMode = (payload: any) =>
  apis.phpAxiosInstance.post(settingRoutes.updateJiraFetchMode(), payload, {
    headers: { JWT: 'true' },
  });

export const updateJiraTaskMode = (payload: any) =>
  apis.phpAxiosInstance.post(settingRoutes.updateJiraTaskMode(), payload, {
    headers: { JWT: 'true' },
  });

export const getJiraProjectsFromApi = (payload: any) =>
  apis.phpAxiosInstance.post(settingRoutes.addImplementationtaskKey(), payload, {
    headers: { JWT: 'true' },
  });

export const getNotificationData = (payload: any) =>
  apis.phpAxiosInstance.post(settingRoutes.getNotificationData(), payload, {
    headers: { JWT: 'true' },
  });

export const getAllTasks = (payload: any) =>
  apis.phpAxiosInstance.post(settingRoutes.getAllTasks(), payload, {
    headers: { JWT: 'true' },
  });

export const getCustomViewColumns_api = (payload: any) =>
  apis.phpAxiosInstance.post(settingRoutes.getCustomViewColumn(), payload, {
    headers: { JWT: 'true' },
  });

export const updateClientRecordViewColumns_api = (payload: any) =>
  apis.phpAxiosInstance.post(settingRoutes.updateClientRecordViewColumns(), payload, {
    headers: { JWT: 'true' },
  });

export const addCustomFieldProjectByCustomerUid = (payload: any) =>
  apis.phpAxiosInstance.post(settingRoutes.addCustomFieldProjectByCustomerUid(), payload, {
    headers: { JWT: 'true' },
  });

export const updateCustomFieldRAGCheckBoxStatus = (payload: any) =>
  apis.phpAxiosInstance.post(settingRoutes.updateCustomFieldRAGCheckBoxStatus(), payload, {
    headers: { JWT: 'true' },
  });

export const addSubFieldforCustomFieldProject = (payload: any) =>
  apis.phpAxiosInstance.post(settingRoutes.addSubFieldforCustomFieldProject(), payload, {
    headers: { JWT: 'true' },
  });

export const addCustomFieldByCustomerUid = (payload: any) =>
  apis.phpAxiosInstance.post(settingRoutes.addCustomFieldByCustomerUid(), payload, {
    headers: { JWT: 'true' },
  });

export const addCustomFieldUsecaseByCustomerUid = (payload: any) =>
  apis.phpAxiosInstance.post(settingRoutes.addCustomFieldUsecaseByCustomerUid(), payload, {
    headers: { JWT: 'true' },
  });

export const addCustomFieldTaskByCustomerUid = (payload: any) =>
  apis.phpAxiosInstance.post(settingRoutes.addCustomFieldTaskByCustomerUid(), payload, {
    headers: { JWT: 'true' },
  });

export const updateCustomFieldByCustomerUid = (payload: any) =>
  apis.phpAxiosInstance.post(settingRoutes.updateCustomFieldByCustomerUid(), payload, {
    headers: { JWT: 'true' },
  });
export const updateJiraUsernameAndCustomerDetails = (payload: any) =>
  apis.phpAxiosInstance.post(settingRoutes.updateJiraUsernameAndCustomerToken(), payload, {
    headers: { JWT: 'true' },
  });

export const updateJiraAccountIdForUsers = (payload: any) =>
  apis.phpAxiosInstance.post(settingRoutes.updateJiraAccountIdForUsers(), payload, {
    headers: { JWT: 'true' },
  });

export const addJiraProjectKeyToCustomer = (payload: any) =>
  apis.phpAxiosInstance.post(settingRoutes.addProjectKeyToCustomer(), payload, {
    headers: { JWT: 'true' },
  });

export const addJiraProductStageCustomStage = (payload: any) =>
  apis.phpAxiosInstance.post(settingRoutes.addJiraProductStageCustomStage(), payload, {
    headers: { JWT: 'true' },
  });

export const getJiraProjectfromCustomerUid = () =>
  apis.phpAxiosInstance.get(settingRoutes.getJiraProjectFromCustomerUid(cookies.get('cogni_customer_uid') as string), {
    headers: { JWT: 'true' },
  });

export const deleteCustomerProject = (payload: any) =>
  apis.phpAxiosInstance.post(settingRoutes.deleteCustomerProject(), payload, {
    headers: { JWT: 'true' },
  });

export const deleteWholeCustomerProject = (payload: any) =>
  apis.phpAxiosInstance.post(settingRoutes.deleteWholeCustomerProduct(), payload, {
    headers: { JWT: 'true' },
  });

export const updateRemoveJiraToken = () => {
  const customer_uid = cookies.get('cogni_customer_uid') as string;
  return apis.phpAxiosInstance.post(
    settingRoutes.removeJiraTokenByCustomerUid(),
    { customer_uid },
    {
      headers: { JWT: 'true' },
    }
  );
};

export const updateRemoveJiraLinkFromTask = () => {
  const customer_uid = cookies.get('cogni_customer_uid') as string;
  return apis.phpAxiosInstance.post(
    settingRoutes.removeJiraLinkFromTask(),
    { customer_uid },
    {
      headers: { JWT: 'true' },
    }
  );
};

export const getJiraProductCustomStage = (project_key: string) => {
  const customer_uid = cookies.get('cogni_customer_uid') as string;
  return apis.phpAxiosInstance.post(
    settingRoutes.jiraProductCustomStage(),
    {
      customer_uid,
      project_key,
    },
    {
      headers: { JWT: 'true' },
    }
  );
};

export const getJiraTaskCustomStage = (project_key: string) =>
  apis.phpAxiosInstance.get(
    settingRoutes.jiraTaskCustomStage(cookies.get('cogni_customer_uid') as string, project_key),
    {
      headers: { JWT: 'true' },
    }
  );

export const updateJiraProductCustomStage = (details: any) =>
  apis.phpAxiosInstance.post(settingRoutes.updateJiraProductCustomStage(), details, {
    headers: { JWT: 'true' },
  });

export const updateJiraTaskCustomStage = (details: any) =>
  apis.phpAxiosInstance.post(settingRoutes.updateJiraTaskStage(), details, {
    headers: { JWT: 'true' },
  });

export const updateCustomFieldAttachmentStatus = (details: any) =>
  apis.phpAxiosInstance.post(settingRoutes.updateCustomFieldAttachmentStatus(), details, {
    headers: { JWT: 'true' },
  });

export const updateCustomFieldMandatoryStatus = (details: any) =>
  apis.phpAxiosInstance.post(settingRoutes.updateCustomFieldMandatoryStatus(), details, {
    headers: { JWT: 'true' },
  });

export const updateCustomProjectField = (details: any) =>
  apis.phpAxiosInstance.post(settingRoutes.updateCustomProjectField(), details, {
    headers: { JWT: 'true' },
  });

export const updateCustomUsecaseField = (details: any) =>
  apis.phpAxiosInstance.post(settingRoutes.updateCustomUsecaseField(), details, {
    headers: { JWT: 'true' },
  });

export const updateCustomTaskField = (details: any) =>
  apis.phpAxiosInstance.post(settingRoutes.updateCustomTaskField(), details, {
    headers: { JWT: 'true' },
  });

export const deleteCustomProjectField = (details: any) =>
  apis.phpAxiosInstance.post(settingRoutes.deleteCustomProjectField(), details, {
    headers: { JWT: 'true' },
  });

export const deleteCustomUsecaseField = (details: any) =>
  apis.phpAxiosInstance.post(settingRoutes.deleteCustomUsecaseField(), details, {
    headers: { JWT: 'true' },
  });

export const deleteCustomTaskField = (details: any) =>
  apis.phpAxiosInstance.post(settingRoutes.deleteCustomTaskField(), details, {
    headers: { JWT: 'true' },
  });

export const deleteCustomClientField = (details: any) =>
  apis.phpAxiosInstance.post(settingRoutes.deleteCustomClientField(), details, {
    headers: { JWT: 'true' },
  });

const addEmailConfig = (details: any) =>
  apis.phpAxiosInstance.post(settingRoutes.addEmailConfig(), details, {
    headers: { JWT: 'true' },
  });

const getEmailConfig = () =>
  apis.phpAxiosInstance.get(settingRoutes.getEmailConfig(cookies.get('cogni_customer_uid') as string), {
    headers: { JWT: 'true' },
  });

const deleteEmailConfig = () =>
  apis.phpAxiosInstance.post(
    settingRoutes.deleteEmailConfig(),
    { customer_uid: cookies.get('cogni_customer_uid') as string },
    {
      headers: { JWT: 'true' },
    }
  );

const changeEmailConfig = (details: any) =>
  apis.phpAxiosInstance.post(settingRoutes.changeEmailConfig(), details, {
    headers: { JWT: 'true' },
  });

export const updateMagicLinkDuration = (details: any) =>
  apis.phpAxiosInstance.post(settingRoutes.updateMagicLinkDuration(), details, {
    headers: { JWT: 'true' },
  });

export const updateCustomLabelValueByCustomerUidAndFieldUid = (details: any) =>
  apis.phpAxiosInstance.post(settingRoutes.updateCustomLabelvalues(), details, {
    headers: { JWT: 'true' },
  });

export const insertCustomLabelValues = () =>
  apis.phpAxiosInstance.post(
    settingRoutes.insertCustomLabelValues(),
    { customer_uid: cookies.get('cogni_customer_uid') },
    {
      headers: { JWT: 'true' },
    }
  );

export const updateCustomerHolidays = (details: any) =>
  apis.phpAxiosInstance.post(settingRoutes.updateCustomerHolidays(), details, {
    headers: { JWT: 'true' },
  });

export const updateRevenueReportBasis = (details: any) =>
  apis.phpAxiosInstance.post(settingRoutes.updateRevenueReportBasis(), details, {
    headers: { JWT: 'true' },
  });

const updateCustomClientSegmentValue = (details: any) =>
  apis.phpAxiosInstance.post(settingRoutes.updateCustomSegmentValue(), details, {
    headers: { JWT: 'true' },
  });

const getCustomClientSegmentValues = () =>
  apis.phpAxiosInstance.get(settingRoutes.getCustomSegmentValue(cookies.get('cogni_customer_uid') as string), {
    headers: { JWT: 'true' },
  });

export const sendEmailToUsersWithCustomMessage = (details: any) =>
  apis.phpAxiosInstance.post(settingRoutes.sendEmailToUsersWithCustomMessage(), details, {
    headers: { JWT: 'true' },
  });

const updateCustomProjectCategoryValue = (details: any) =>
  apis.phpAxiosInstance.post(settingRoutes.updateCustomProjectCategoryValue(), details, {
    headers: { JWT: 'true' },
  });

const updateCustomProjectStageValue = (details: any) =>
  apis.phpAxiosInstance.post(settingRoutes.updateCustomProjectStageValue(), details, {
    headers: { JWT: 'true' },
  });

const updateCustomUsecaseCategoryValue = (details: any) =>
  apis.phpAxiosInstance.post(settingRoutes.updateCustomUsecaseCategoryValue(), details, {
    headers: { JWT: 'true' },
  });

const updateCustomUsecaseStageValue = (details: any) =>
  apis.phpAxiosInstance.post(settingRoutes.updateCustomUsecaseStageValue(), details, {
    headers: { JWT: 'true' },
  });

const getCrmCustomFieldsFromIntegrations = () =>
  apis.phpAxiosInstance.get(
    settingRoutes.getCrmCustomFieldsFromIntegrations(cookies.get('cogni_customer_uid') as string),
    {
      headers: { JWT: 'true' },
    }
  );

const getCrmCustomFieldsFromDb = () =>
  apis.phpAxiosInstance.get(settingRoutes.getCrmCustomFieldsFromDb(cookies.get('cogni_customer_uid') as string), {
    headers: { JWT: 'true' },
  });

const updateCrmCustomfieldsInDB = (details: any) =>
  apis.phpAxiosInstance.post(settingRoutes.updateCrmCustomfieldsInDB(), details, {
    headers: { JWT: 'true' },
  });

const updateCustomProjectPriorityValue = (details: any) =>
  apis.phpAxiosInstance.post(settingRoutes.updateCustomProjectPriorityValue(), details, {
    headers: { JWT: 'true' },
  });

const updateCustomUsecasePriorityValue = (details: any) =>
  apis.phpAxiosInstance.post(settingRoutes.updateCustomUsecasePriorityValue(), details, {
    headers: { JWT: 'true' },
  });

const updateCustomTaskPriorityValue = (details: any) =>
  apis.phpAxiosInstance.post(settingRoutes.updateCustomTaskPriorityValue(), details, {
    headers: { JWT: 'true' },
  });

const updateCustomTaskStageValue = (details: any) =>
  apis.phpAxiosInstance.post(settingRoutes.updateCustomTaskStageValue(), details, {
    headers: { JWT: 'true' },
  });

export const settingsAPI = {
  getSettingPage_CustomJourneyState_Data,
  getSettingPage_JourneyState_Data,
  updateTableForFirstTime,
  updateTable,
  getCustomerDetails,
  updateUseCase_Configuration,
  getUserDetails,
  updateUsername,
  update_user_email,
  update_user_password,
  getCustomFieldClientRecords,
  getCustomFieldProjectRecords,
  getCustomFieldUsecaseRecords,
  getCustomFieldTaskRecords,
  deleteExisitingProductbyCustomerUid,
  saveJiraCredentials,
  updateJiraFetchMode,
  updateJiraTaskMode,
  getJiraProjectsFromApi,
  getNotificationData,
  getAllTasks,
  getCustomViewColumns_api,
  updateClientRecordViewColumns_api,
  addCustomFieldProjectByCustomerUid,
  updateCustomFieldRAGCheckBoxStatus,
  addSubFieldforCustomFieldProject,
  addCustomFieldByCustomerUid,
  addCustomFieldUsecaseByCustomerUid,
  addCustomFieldTaskByCustomerUid,
  updateJiraUsernameAndCustomerDetails,
  updateJiraAccountIdForUsers,
  addJiraProjectKeyToCustomer,
  addJiraProductStageCustomStage,
  getJiraProjectfromCustomerUid,
  deleteCustomerProject,
  updateRemoveJiraToken,
  updateRemoveJiraLinkFromTask,
  deleteWholeCustomerProject,
  updateCustomFieldByCustomerUid,
  getJiraProductCustomStage,
  getJiraTaskCustomStage,
  updateJiraProductCustomStage,
  updateJiraTaskCustomStage,
  updateCustomFieldAttachmentStatus,
  updateCustomFieldMandatoryStatus,
  updateCustomProjectField,
  updateCustomUsecaseField,
  updateCustomTaskField,
  deleteCustomProjectField,
  deleteCustomUsecaseField,
  deleteCustomTaskField,
  deleteCustomClientField,
  updateCustomerHolidays,
  addEmailConfig,
  getEmailConfig,
  deleteEmailConfig,
  changeEmailConfig,
  updateMagicLinkDuration,
  updateCustomLabelValueByCustomerUidAndFieldUid,
  insertCustomLabelValues,
  getMergeLink,
  swapPublicToken,
  getCustomerIntegrationDetails,
  getCustomerIntegrationMicroService,
  updateCustomerIntegrationPipedriveDetails,
  getCRMStages,
  updateStageAndFetchMode,
  updateRevenueReportBasis,
  updateCustomClientSegmentValue,
  getCustomClientSegmentValues,
  sendEmailToUsersWithCustomMessage,
  updateCustomProjectCategoryValue,
  updateCustomProjectStageValue,
  updateCustomUsecaseCategoryValue,
  updateCustomUsecaseStageValue,
  getCrmCustomFieldsFromIntegrations,
  getCrmCustomFieldsFromDb,
  updateCrmCustomfieldsInDB,
  enableMandatorySSO,
  disableMandatorySSO,
  updateCustomProjectPriorityValue,
  updateCustomUsecasePriorityValue,
  updateCustomTaskPriorityValue,
  updateCustomTaskStageValue,
};
