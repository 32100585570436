import { apis } from '../axiosInstance';
import { clientRoutes, customFieldRoutes } from '../route';

import Cookies from 'universal-cookie';

const cookies = new Cookies();

const getCustomFields = () =>
  apis.phpAxiosInstance.get(clientRoutes.getCustomFieldByCustomerUid(cookies.get('cogni_customer_uid') as string), {
    headers: { JWT: 'true' },
  });
const getCustomFieldsForClient = (client_uid: string) =>
  apis.phpAxiosInstance.get(
    customFieldRoutes.getCustomFieldsForClient(cookies.get('cogni_customer_uid') as string, client_uid),
    {
      headers: { JWT: 'true' },
    }
  );

const getCustomFieldsWithValues = (client_uid: string) =>
  apis.phpAxiosInstance.get(customFieldRoutes.getCustomFieldsWithValues(client_uid), { headers: { JWT: 'true' } });

const updateClientCustomFields = (details: any) =>
  apis.phpAxiosInstance.post(customFieldRoutes.updateClientCustomFields(), details, { headers: { JWT: 'true' } });

const getCustomFieldsWithUsecaseAndItsSubFields = () =>
  apis.phpAxiosInstance.get(
    customFieldRoutes.getCustomFieldsUsecasewithSubFields(cookies.get('cogni_customer_uid') as string),
    {
      headers: { JWT: 'true' },
    }
  );

const getCustomFieldsByCustomerUid = () =>
  apis.phpAxiosInstance.get(customFieldRoutes.getCustomFieldByCustomer(cookies.get('cogni_customer_uid') as string), {
    headers: { JWT: 'true' },
  });

const getCustomFieldsOfTaskByCustomerUid = () =>
  apis.phpAxiosInstance.get(customFieldRoutes.getCustomFieldsOfTasks(cookies.get('cogni_customer_uid') as string), {
    headers: { JWT: 'true' },
  });

export const customFieldsAPI = {
  getCustomFields,
  getCustomFieldsForClient,
  getCustomFieldsWithValues,
  updateClientCustomFields,
  getCustomFieldsWithUsecaseAndItsSubFields,
  getCustomFieldsByCustomerUid,
  getCustomFieldsOfTaskByCustomerUid,
};
