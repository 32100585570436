import { createSlice, PayloadAction, createAsyncThunk, current } from '@reduxjs/toolkit';
import { requestState } from '../types';
import { attachmentAPI } from '../../../api/modules/attachment';

// export const addAttachmentToServer = createAsyncThunk;
export const addAttachmentToServer = createAsyncThunk('addAttachmentToServer', async (oneAttachment: any) => {
  console.log('action dispatched');
  const { data } = await attachmentAPI.addAttachment(oneAttachment);
  return data.result;
});

export const addAttachmentsWithCustomUrlAndPayload = createAsyncThunk(
  'addAttachmentToServerWithCustomUrl',
  async ({ apiUrl, oneAttachment }: { apiUrl: string; oneAttachment: any }) => {
    console.log('custom url attachment saver');
    const { data } = await attachmentAPI.addAttachmentToDbWithCustomUrl(apiUrl, oneAttachment);
    return data ?? [];
  }
);

export const downloadAttachmentFromServer = createAsyncThunk(
  'downloadAttachmentFromServer',
  async ({
    serverGetApiUrlWithQueryString,
    attachmentUid,
  }: {
    serverGetApiUrlWithQueryString: string;
    attachmentUid: string;
  }) => {
    // console.log('downloading attachments');
    const { data } = await attachmentAPI.downloadAttachmentWithCustomApiUrl(serverGetApiUrlWithQueryString);
    // console.log(data);
    return { attachmentUid, data };
  }
);

export const getAttachmentsFromServer = createAsyncThunk(
  'getAttachmentFromServer',
  async (serverGetApiUrlWithQueryString: string) => {
    console.log('getting attachments');
    const { data } = await attachmentAPI.getAttachmentsWithCustomApiUrl(serverGetApiUrlWithQueryString);
    return data.result;
  }
);

export const getOldAttachmentVersions = createAsyncThunk(
  'getOldAttachmentVersions',
  async (serverGetOldAttachmentVersions: string) => {
    const { data } = await attachmentAPI.getOldAttachmentVersions(serverGetOldAttachmentVersions);
    return data.result ?? [];
  }
);

export const deleteAttachmentUsingCustomApiUrlAndPayload = createAsyncThunk(
  'deleteAttachment',
  async ({
    apiDeleteAttachmentUrl,
    apiDeletePayload,
  }: {
    apiDeleteAttachmentUrl: string;
    apiDeletePayload: Record<string, any>;
  }) => {
    const { data } = await attachmentAPI.deleteAttachmentWithCustomApiAndPayload(
      apiDeleteAttachmentUrl,
      apiDeletePayload
    );
    console.log(data);
    return data.result;
  }
);

interface StateProps {
  attachmentQueue: Record<string, any>[];
  status: requestState;
  error: null | undefined | string;
  attachmentInUploadQueueCount: number;
  downloadAttachment: {
    data: {
      [key: string]: any;
    };
    status: requestState;
    error: null | undefined | string;
  };
  attachmentDataDownloadQueue: {
    data: Record<string, any>[];
    status: requestState;
    error: null | undefined | string;
  };
  deleteAttachmentFromServer: {
    data: Record<string, any>[];
    status: requestState;
    error: null | undefined | string;
  };
  attachmentOldVersions: {
    data: [];
    status: requestState;
    error: null | undefined | string;
  };
}

const initialState: StateProps = {
  attachmentQueue: [],
  status: requestState.idle,
  error: null,
  attachmentInUploadQueueCount: 0,
  downloadAttachment: {
    data: {},
    status: requestState.idle,
    error: null,
  },
  attachmentDataDownloadQueue: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  deleteAttachmentFromServer: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  attachmentOldVersions: {
    data: [],
    status: requestState.idle,
    error: null,
  },
};

export const attachmentSlice = createSlice({
  name: 'attachment',
  initialState,
  reducers: {
    addAttachmentToAttachmentQueue: (state: StateProps, action: PayloadAction<any>) => {
      // add this data to attachment que
      console.log(action.payload);
      state.attachmentQueue.push(action.payload);
    },
    deleteAttachmentFromQueue: (state: StateProps, action: PayloadAction<any>) => {
      console.log(action.payload.id);
      state.attachmentQueue = state.attachmentQueue.filter((fileData) => {
        return fileData.id !== action.payload.id;
      });
    },
    resetAttachmentQueue: (state: StateProps) => {
      state.attachmentQueue = initialState.attachmentQueue;
    },
    resetAttachmentDownloadData: (state: StateProps) => {
      state.attachmentDataDownloadQueue = initialState.attachmentDataDownloadQueue;
    },
    resetDownloadedAttachment: (state: StateProps) => {
      state.downloadAttachment = initialState.downloadAttachment;
    },
    setStartCountForNumberOfAttachmentsToBeUploaded: (state: StateProps, action: PayloadAction<any>) => {
      state.attachmentInUploadQueueCount = action.payload;
    },
    resetAttachmentsToBeUploadedCount: (state: StateProps) => {
      state.attachmentInUploadQueueCount = initialState.attachmentInUploadQueueCount;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(addAttachmentToServer.pending, (state) => {
        state.status = requestState.loading;
      })
      .addCase(addAttachmentToServer.fulfilled, (state, action) => {
        state.status = requestState.success;
        console.log(action.payload);
        console.log('attachment added');
        // no need to update state as already done by other action just need to reflect status
      })
      .addCase(addAttachmentToServer.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
        console.log(action.error.message);
      })
      .addCase(getOldAttachmentVersions.pending, (state) => {
        state.status = requestState.loading;
      })
      .addCase(getOldAttachmentVersions.fulfilled, (state, action) => {
        state.status = requestState.success;
        state.attachmentOldVersions.data = action.payload;
        console.log(action.payload);
        console.log('attachment old versions added');
        // no need to update state as already done by other action just need to reflect status
      })
      .addCase(getOldAttachmentVersions.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
        console.log(action.error.message);
      })
      .addCase(downloadAttachmentFromServer.pending, (state) => {
        state.downloadAttachment.status = requestState.loading;
      })
      .addCase(downloadAttachmentFromServer.fulfilled, (state, action) => {
        state.downloadAttachment.status = requestState.success;
        // console.log(action.payload);
        // console.log('attachment added');
        state.downloadAttachment.data = { attachmentUid: action.payload.attachmentUid, url: action.payload.data.url };
      })
      .addCase(downloadAttachmentFromServer.rejected, (state, action) => {
        state.downloadAttachment.status = requestState.failed;
        state.downloadAttachment.error = action.error.message;
        // console.log(action.error.message);
      })
      .addCase(getAttachmentsFromServer.pending, (state) => {
        state.attachmentDataDownloadQueue.status = requestState.loading;
      })
      .addCase(getAttachmentsFromServer.fulfilled, (state, action) => {
        state.attachmentDataDownloadQueue.status = requestState.success;
        // console.log(action.payload);
        // console.log('all attachments get from server');
        state.attachmentDataDownloadQueue.data = action.payload;
      })
      .addCase(getAttachmentsFromServer.rejected, (state, action) => {
        state.attachmentDataDownloadQueue.status = requestState.failed;
        state.attachmentDataDownloadQueue.error = action.error.message;
        console.log(action.error.message);
      })
      .addCase(deleteAttachmentUsingCustomApiUrlAndPayload.pending, (state) => {
        state.deleteAttachmentFromServer.status = requestState.loading;
      })
      .addCase(deleteAttachmentUsingCustomApiUrlAndPayload.fulfilled, (state, action) => {
        state.attachmentDataDownloadQueue.status = requestState.success;
        // console.log(action.payload);
        // console.log('all attachments get from server');
        state.deleteAttachmentFromServer.data = action.payload;
      })
      .addCase(deleteAttachmentUsingCustomApiUrlAndPayload.rejected, (state, action) => {
        state.deleteAttachmentFromServer.status = requestState.failed;
        state.deleteAttachmentFromServer.error = action.error.message;
        console.log(action.error.message);
      })
      .addCase(addAttachmentsWithCustomUrlAndPayload.pending, (state) => {
        state.status = requestState.loading;
      })
      .addCase(addAttachmentsWithCustomUrlAndPayload.fulfilled, (state: StateProps, action) => {
        state.status = requestState.success;
        console.log(action.payload);
        console.log('attachment added');
        console.log(current(state.attachmentQueue));
        // no need to update state as already done by other action just need to reflect status
      })
      .addCase(addAttachmentsWithCustomUrlAndPayload.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
        console.log(action.error.message);
      });
  },
});

export const attachmentReducer = attachmentSlice.reducer;
export const {
  addAttachmentToAttachmentQueue,
  deleteAttachmentFromQueue,
  resetAttachmentQueue,
  resetAttachmentDownloadData,
  resetDownloadedAttachment,
  setStartCountForNumberOfAttachmentsToBeUploaded,
  resetAttachmentsToBeUploadedCount,
} = attachmentSlice.actions;
export default attachmentReducer;
