import * as React from 'react';
import { Chip, MenuItem, Radio } from '@mui/material';
import { makeStyles } from '@mui/styles';

import './FilterChip.css';
import { IconButton } from '@mui/material';

// icons
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
// import { selectedFiltersInManageClientspageInterface } from '../../../store/modules/ui/Filters/slice';

import CognisaasMenuList from '../CognisaasMenu/CognisaasMenuList';
import useCustomNames from '../CustomNames/useCustomNames';
import NAMED_CONSTATNTS from '../CustomNames/Constants';

type FilterChipProps = {
  name: string;
  options: string[];
  checkedItemsForList: string[];
  handleOptionClicked: (value: string, filterName: string) => void;
  context: 'usecase' | 'project';
};

const brandBlue = '#2D60F6';
const FilterChip = (props: FilterChipProps) => {
  // console.log('filter chip', props);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const { options } = props;
  const { getCustomNameMappedValue } = useCustomNames();

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const handleDelete = (e: any, filter: keyof selectedFiltersInManageClientspageInterface) => {
  //   console.log('Deleted', filter);
  // };

  const useStyles = makeStyles({
    chipRoot: {
      '& .MuiChip-icon': {
        order: 1, // the label has a default order of 0, so this icon goes after the label
        marginRight: '10px', // add some space between icon and delete icon
        cursor: 'pointer',
      },
      '& .MuiChip-deleteIcon': {
        order: 2, // since this is greater than an order of 1, it goes after the icon
      },
      '& .MuiChip-label': {
        paddingRight: '0px',
      },
      '&:hover': {
        backgroundColor: '#cedafd',
      },
    },
  });

  const classes = useStyles();

  // const checkedItems: stri = props?.checkedItemsForList as string[]
  const getCustomOptionName = (option: string) => {
    const value = option === 'land' || option === 'Land' ? 'Land' : 'Expand';
    if (props.context === 'project') {
      return getCustomNameMappedValue(value, NAMED_CONSTATNTS.project_category_key);
    } else {
      return getCustomNameMappedValue(value, NAMED_CONSTATNTS.usecase_category_key);
    }
  };

  return (
    <span className="FilterChip__wrapper">
      <CognisaasMenuList open={open} anchorEl={anchorEl} handleDropDownClose={handleClose}>
        {options.map((option, index) => {
          return (
            <MenuItem
              key={index}
              dense={true}
              onClick={() => {
                props.handleOptionClicked(option, props.name);
              }}
              sx={{ padding: '0px 20px 0px 10px' }}
            >
              <Radio
                sx={{ marginRight: '10px' }}
                checked={props.checkedItemsForList?.indexOf(option.toLowerCase()) === 0 ? true : false}
                value={option}
              />{' '}
              {getCustomOptionName(option)}
            </MenuItem>
          );
        })}
      </CognisaasMenuList>

      {props.name != 'Status' && (
        <Chip
          classes={{ root: classes.chipRoot }}
          label={
            <>
              <p>
                {props.name}{' '}
                <span style={{ color: brandBlue }}>{getCustomOptionName(props.checkedItemsForList[0])}</span>
              </p>
            </>
          }
          onClick={(e) => handleClickListItem(e)}
          icon={
            <IconButton
              disableRipple
              style={{ marginRight: '5px', marginLeft: '0px', padding: '0px', color: brandBlue }}
            >
              {anchorEl ? <KeyboardArrowUp /> : <KeyboardArrowDownIcon />}
            </IconButton>
          }
          // variant="outlined"
          // onDelete={(e) => handleDelete(e, props.name as keyof selectedFiltersInManageClientspageInterface)}
          sx={{ background: '#cedafd' }}
        ></Chip>
      )}
    </span>
  );
};

export default FilterChip;
