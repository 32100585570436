import { Stack, Box, Typography, Avatar } from '@mui/material';
import {
  getContactNameByContactUid,
  getUserLogoLinkByUid,
  getUserNameByUserUid,
  getIndustryName,
  getSegmentName,
  getStageName,
} from '../../../utils/getUid';
import format from 'date-fns/format';
import { default as AvatarImage } from '../../shared/Avatar/Avatar';
import {
  label_arr,
  label_client,
  label_delivery_date,
  label_due_date,
  label_project,
  label_task,
} from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { isValidDate } from 'utils/dataHelpers';
interface ContentPropType {
  timelineDetail: any;
  day: string;
  type: string;
}

const convertDateToTime = (date: string) => {
  return Intl.DateTimeFormat('en', { hour: 'numeric', minute: 'numeric' }).format(new Date(date));
};

const convertDateToString = (date: string) => {
  return new Date(date).toLocaleDateString('en-gb', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: 'ist',
  });
};

function stringToHslColor(str: string, s: number, l: number) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const h = hash % 360;
  return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToHslColor(name, 30, 80),
    },
    children: (
      <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#000000' }}>
        {name.split(' ')[0][0]}
        {name.indexOf(' ') >= 0 ? name.split(' ')[1][0] : null}
      </Typography>
    ),
  };
}

const parseLines = (value: string) => value?.replace(/(\\n)/g, '\n').replace(/(\\)/g, '');

const Content = (props: ContentPropType) => {
  const { timelineDetail, day, type } = props;
  return (
    <Box style={{ marginBottom: '60px' }}>
      <Typography variant="h1" style={{ marginBottom: '20px', marginTop: '-3px', fontWeight: '500' }}>
        {convertDateToString(day.replace(/-/g, '/'))}
      </Typography>
      <Box style={{ marginTop: '12px' }}>
        {timelineDetail.reverse().map((timeline: any) => {
          const timelineField = timeline.field
            .replaceAll('Task', label_task)
            .replaceAll('task', label_task)
            .replaceAll('Due Date', label_due_date)
            .replaceAll('Delivery Date', label_delivery_date)
            .replaceAll('Client', label_client);
          return (
            <Stack key={timeline.trail_uid} direction="row" style={{ marginBottom: '15px' }}>
              <Typography variant="h3" color="#7A7A7A" style={{ marginTop: '5px', width: '63px' }}>
                {convertDateToTime(timeline.modified_on.replace(/-/g, '/'))}
              </Typography>
              <Box style={{ marginLeft: '30px' }}>
                <Stack direction="row" style={{ marginBottom: '10px' }} alignItems="center">
                  {getUserLogoLinkByUid(timeline.user_uid).length > 0 ? (
                    <AvatarImage
                      src={getUserLogoLinkByUid(timeline.user_uid)}
                      alt="User  profile"
                      height="40px"
                      width="40px"
                    />
                  ) : (
                    <Avatar
                      style={{ height: '40px', width: '40px' }}
                      {...stringAvatar(
                        timeline.user_uid !== 'CogniSaaS Platform'
                          ? getUserNameByUserUid(timeline.user_uid)
                          : timeline.user_uid
                      )}
                    />
                  )}
                  <Typography variant="h2" style={{ marginLeft: '10px', fontWeight: 500 }}>
                    {timeline.user_uid !== 'CogniSaaS Platform'
                      ? getUserNameByUserUid(timeline.user_uid)
                      : timeline.user_uid}
                    {timeline.field === 'Created' && ` created this ${type}`}
                  </Typography>
                </Stack>
                {timelineField !== 'Created' &&
                (timelineField.includes('Date') || timelineField.includes('date')) &&
                timelineField.includes('Custom Field') ? (
                  <Typography variant="subtitle1" style={{ marginLeft: '48px' }}>
                    <small style={{ color: '#7A7A7A' }}>Updated {timelineField} from </small>
                    {isValidDate(new Date(timeline.old_value))
                      ? ` ${format(new Date(timeline.old_value), 'dd MMM yyyy')} to `
                      : 'None to '}
                    {isValidDate(new Date(timeline.new_value))
                      ? `${format(new Date(timeline.new_value), 'dd MMM yyyy')}`
                      : 'None'}
                  </Typography>
                ) : timelineField.includes('Date') ||
                  timelineField.includes('ETA') ||
                  timelineField.includes(label_due_date) ||
                  timelineField.includes(label_delivery_date) ? (
                  <Typography variant="subtitle1" style={{ marginLeft: '48px' }}>
                    <small style={{ color: '#7A7A7A' }}>Updated {timelineField} from </small>
                    {isValidDate(new Date(timeline.old_date))
                      ? ` ${format(new Date(timeline.old_date), 'dd MMM yyyy')} to `
                      : 'None to '}
                    {isValidDate(new Date(timeline.new_date))
                      ? `${format(new Date(timeline.new_date), 'dd MMM yyyy')}`
                      : 'None'}
                  </Typography>
                ) : timelineField.includes('Owner') || timelineField.includes('Manager') ? (
                  <Typography variant="subtitle1" style={{ marginLeft: '48px' }}>
                    <small style={{ color: '#7A7A7A' }}>Updated {timelineField} from </small>{' '}
                    {getUserNameByUserUid(timeline.old_value)
                      ? getUserNameByUserUid(timeline.old_value)
                      : getContactNameByContactUid(timeline.old_value)
                      ? getContactNameByContactUid(timeline.old_value)
                      : timeline.old_value}{' '}
                    <small style={{ color: '#7A7A7A' }}>to</small>{' '}
                    {getUserNameByUserUid(timeline.new_value)
                      ? getUserNameByUserUid(timeline.new_value)
                      : getContactNameByContactUid(timeline.new_value)
                      ? getContactNameByContactUid(timeline.new_value)
                      : timeline.new_value}
                  </Typography>
                ) : timelineField === 'Comment' || timelineField === 'Description' ? (
                  <>
                    <Typography variant="subtitle1" style={{ marginLeft: '48px' }}>
                      {timeline.old_value != '' ? (
                        <small style={{ color: '#7A7A7A' }}>
                          Updated {timelineField === 'Comment' ? 'comment' : 'description'} from
                        </small>
                      ) : (
                        <small style={{ color: '#7A7A7A' }}>
                          Added {timelineField === 'Comment' ? 'comment' : 'description'}
                        </small>
                      )}
                    </Typography>
                    <Typography variant="subtitle1" style={{ marginLeft: '48px' }}>
                      {timeline.old_value != '' ? (
                        <>
                          {parseLines(timeline.old_value)} <small style={{ color: '#7A7A7A' }}>to</small>{' '}
                          {parseLines(timeline.new_value)}
                        </>
                      ) : (
                        <>{parseLines(timeline.new_value)}</>
                      )}
                    </Typography>
                  </>
                ) : timelineField === 'Dependency' ? (
                  <>
                    <Typography variant="subtitle1" style={{ marginLeft: '48px' }}>
                      <small style={{ color: '#7A7A7A' }}>Added the precedent {label_task.toLowerCase()} </small>
                      {timeline.new_value}
                    </Typography>
                  </>
                ) : timelineField.includes('status') ||
                  ['red', 'amber', 'green', 'blue'].includes(timeline.old_value.toLowerCase()) ||
                  ['red', 'amber', 'green', 'blue'].includes(timeline.new_value.toLowerCase()) ? (
                  <Typography variant="subtitle1" style={{ marginLeft: '48px' }}>
                    <small style={{ color: '#7A7A7A' }}>Updated {timelineField} from</small>
                    <>
                      <span>
                        {timeline.old_value === '' ? (
                          ' None'
                        ) : (
                          <span>
                            {' '}
                            {timeline.old_value.toLowerCase() == 'red' ? (
                              <>
                                <span
                                  style={{
                                    backgroundColor: '#E75B5C',
                                    display: 'inline-block',
                                    width: '8px',
                                    height: '8px',
                                    borderRadius: '60%',
                                    marginRight: '8px',
                                    marginLeft: '4px',
                                  }}
                                ></span>
                                <span>Overdue</span>
                              </>
                            ) : timeline.old_value.toLowerCase() == 'green' ? (
                              <>
                                <span
                                  style={{
                                    backgroundColor: '#4F9F52',
                                    display: 'inline-block',
                                    width: '8px',
                                    height: '8px',
                                    borderRadius: '60%',
                                    marginRight: '8px',
                                    marginLeft: '4px',
                                  }}
                                ></span>
                                <span>On Track</span>
                              </>
                            ) : timeline.old_value.toLowerCase() == 'amber' ? (
                              <>
                                <span
                                  style={{
                                    backgroundColor: '#EFA958',
                                    display: 'inline-block',
                                    width: '8px',
                                    height: '8px',
                                    borderRadius: '60%',
                                    marginRight: '8px',
                                    marginLeft: '4px',
                                  }}
                                ></span>
                                <span>At Risk</span>
                              </>
                            ) : timeline.old_value.toLowerCase() == 'blue' ? (
                              <>
                                <span
                                  style={{
                                    backgroundColor: '#4390E1',
                                    display: 'inline-block',
                                    width: '8px',
                                    height: '8px',
                                    borderRadius: '60%',
                                    marginRight: '8px',
                                    marginLeft: '4px',
                                  }}
                                ></span>
                                <span>Completed</span>
                              </>
                            ) : timeline.old_value.toLowerCase() == 'grey' ? (
                              <>
                                <span
                                  style={{
                                    backgroundColor: '#7a7a7a',
                                    display: 'inline-block',
                                    width: '8px',
                                    height: '8px',
                                    borderRadius: '60%',
                                    marginRight: '8px',
                                    marginLeft: '4px',
                                  }}
                                ></span>
                                <span>Deferred</span>
                              </>
                            ) : (
                              ''
                            )}{' '}
                          </span>
                        )}
                        <span style={{ fontWeight: '400' }} className="label-text">
                          {' '}
                          to{' '}
                        </span>
                        {timeline.new_value === '' ? (
                          <span> None</span>
                        ) : (
                          <span>
                            {' '}
                            {timeline.new_value.toLowerCase() == 'red' ? (
                              <>
                                <span
                                  style={{
                                    backgroundColor: '#E75B5C',
                                    display: 'inline-block',
                                    width: '8px',
                                    height: '8px',
                                    borderRadius: '60%',
                                    marginRight: '8px',
                                    marginLeft: '4px',
                                  }}
                                ></span>
                                <span>Overdue</span>
                              </>
                            ) : timeline.new_value.toLowerCase() == 'green' ? (
                              <>
                                <span
                                  style={{
                                    backgroundColor: '#4F9F52',
                                    display: 'inline-block',
                                    width: '8px',
                                    height: '8px',
                                    borderRadius: '60%',
                                    marginRight: '8px',
                                    marginLeft: '4px',
                                  }}
                                ></span>
                                <span>On Track</span>
                              </>
                            ) : timeline.new_value.toLowerCase() == 'amber' ? (
                              <>
                                <span
                                  style={{
                                    backgroundColor: '#EFA958',
                                    display: 'inline-block',
                                    width: '8px',
                                    height: '8px',
                                    borderRadius: '60%',
                                    marginRight: '8px',
                                    marginLeft: '4px',
                                  }}
                                ></span>
                                <span>At Risk</span>
                              </>
                            ) : timeline.new_value.toLowerCase() == 'blue' ? (
                              <>
                                <span
                                  style={{
                                    backgroundColor: '#4390E1',
                                    display: 'inline-block',
                                    width: '8px',
                                    height: '8px',
                                    borderRadius: '60%',
                                    marginRight: '8px',
                                    marginLeft: '4px',
                                  }}
                                ></span>
                                <span>Completed</span>
                              </>
                            ) : timeline.new_value.toLowerCase() == 'grey' ? (
                              <>
                                <span
                                  style={{
                                    backgroundColor: '#7a7a7a',
                                    display: 'inline-block',
                                    width: '8px',
                                    height: '8px',
                                    borderRadius: '60%',
                                    marginRight: '8px',
                                    marginLeft: '4px',
                                  }}
                                ></span>
                                <span>Deferred</span>
                              </>
                            ) : (
                              ''
                            )}{' '}
                          </span>
                        )}
                      </span>
                    </>

                    {/* {timeline.old_value === '15243' ? 'Not Assigned' : timeline.old_value}{' '}
                    <small style={{ color: '#7A7A7A' }}>to</small>{' '}
                    {timeline.new_value === '15243' ? 'Not Assigned' : timeline.new_value} */}
                  </Typography>
                ) : timelineField.includes('Segment') ? (
                  <Typography variant="subtitle1" style={{ marginLeft: '48px' }}>
                    <small style={{ color: '#7A7A7A' }}>Updated {timelineField} from </small>{' '}
                    {getSegmentName(timeline.old_value) ? getSegmentName(timeline.old_value) : timeline.old_value}{' '}
                    <small style={{ color: '#7A7A7A' }}>to</small>{' '}
                    {getSegmentName(timeline.new_value) ? getSegmentName(timeline.new_value) : timeline.new_value}
                  </Typography>
                ) : timelineField.includes('Industry') ? (
                  <Typography variant="subtitle1" style={{ marginLeft: '48px' }}>
                    <small style={{ color: '#7A7A7A' }}>Updated {timelineField} from </small>{' '}
                    {getIndustryName(timeline.old_value) ? getIndustryName(timeline.old_value) : timeline.old_value}{' '}
                    <small style={{ color: '#7A7A7A' }}>to</small>{' '}
                    {getIndustryName(timeline.new_value) ? getIndustryName(timeline.new_value) : timeline.new_value}
                  </Typography>
                ) : timelineField.includes('Stage') ? (
                  <Typography variant="subtitle1" style={{ marginLeft: '48px' }}>
                    <small style={{ color: '#7A7A7A' }}>Updated {timelineField} from </small>{' '}
                    {getStageName(timeline.old_value) ? getStageName(timeline.old_value) : timeline.old_value}{' '}
                    <small style={{ color: '#7A7A7A' }}>to</small>{' '}
                    {getStageName(timeline.new_value) ? getStageName(timeline.new_value) : timeline.new_value}
                  </Typography>
                ) : timelineField.includes('Created') ? (
                  <Typography variant="subtitle1" style={{ marginLeft: '48px' }}>
                    <small style={{ color: '#7A7A7A' }}>Created this {props.type} </small>
                  </Typography>
                ) : (
                  <Typography variant="subtitle1" style={{ marginLeft: '48px' }}>
                    <small style={{ color: '#7A7A7A' }}>
                      {' '}
                      Updated{' '}
                      {timelineField.includes('Account ARR') ? (
                        <span>
                          {' '}
                          {label_client} {label_arr}
                        </span>
                      ) : timelineField.includes('Project ARR') ? (
                        <span>
                          {' '}
                          {label_project} {label_arr}
                        </span>
                      ) : (
                        <span> {timelineField}</span>
                      )}{' '}
                      from{' '}
                    </small>
                    {timeline.old_value === '15243'
                      ? 'Not Assigned'
                      : timeline.old_value == ''
                      ? 'None'
                      : timeline.old_value}{' '}
                    <small style={{ color: '#7A7A7A' }}>to</small>{' '}
                    {timeline.new_value === '15243'
                      ? 'Not Assigned'
                      : timeline.new_value == ''
                      ? 'None'
                      : timeline.new_value}
                  </Typography>
                )}
              </Box>
            </Stack>
          );
        })}
      </Box>
    </Box>
  );
};

export default Content;
