import React from 'react';
import styled from 'styled-components';

import { SvgRect } from 'components/ResourceManagement/design-system/primitives';

type BarDisplayProps = {
  x: number;
  y: number;
  width: number;
  height: number;
  isSelected: boolean;
  /* progress start point */
  progressX: number;
  progressWidth: number;
  onClick?: () => void;
};

const StyledBarBgRect = styled(SvgRect)`
  ${({ theme }) => {
    return {
      fill: `${theme.colors.pastel_5}`
    };
  }};
`;

// const StyledBarRect = styled(SvgRect)`
//   ${({ theme }) => {
//     return {
//       fill: `${theme.colors.bragLite_1}`
//     };
//   }};
// `;

export const BarDisplay: React.FC<BarDisplayProps> = ({
  x,
  y,
  width,
  height,
  // progressX,
  // progressWidth,
}) => {
  const barCornerRadius = 5;

  return (
    <g>
      <StyledBarBgRect
        x={x}
        width={width}
        y={y}
        height={height}
        ry={barCornerRadius}
        rx={barCornerRadius}
      />
      {/* <StyledBarRect
        x={progressX}
        width={progressWidth}
        y={y}
        height={height}
        ry={barCornerRadius}
        rx={barCornerRadius}
      /> */}
    </g>
  );
};
