import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CognisaasCheckBox from '../CognisaasCheckbox/CognisaasCheckbox';

const CognisaasAutoComplete = (props: any) => {
  return (
    <Autocomplete
      {...props.keepOpen ? { open: props.keepOpen } : {}}
      multiple
      id="checkboxes-tags-demo"
      options={props.allOptions}
      disableCloseOnSelect
      value={props.value ? props.value : []}
      disabled={props.disabled ? props.disabled : false}
      limitTags={2}
      size="small"
      onChange={(e: any, value: any) => props.selectedOption(e, value)}
      getOptionLabel={(option: any) => option.name}
      renderOption={(props: any, option, { selected }) => (
        <li {...props}>
          <CognisaasCheckBox
            sx={{ marginRight: '8px' }}
            checked={selected}
          />
          {option.name}
        </li>
      )}
      style={{ width: props.width ? props.width : 400 }}
      renderInput={(params) => <TextField {...params} placeholder={props.placeholder ? props.placeholder : 'Members'} />}
    />
  );
};

export default CognisaasAutoComplete;
