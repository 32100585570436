import { Button, Divider, FormControl, Stack, TextField, Typography } from '@mui/material';
import { Formik } from 'formik';
import CognisaasButton from '../../shared/CognisaasButton/CognisaasButton';
import Styles from './AddNewRequirement.module.css';
import { ReactComponent as LeftArrowIcon } from '../../../assets/svgs/leftArrow.svg';
import CognisaasRequirementStatusDropdown from '../../shared/CognisaasDropdown/CognisaasRequirementStatusDropdown/CognisaasRequirementStatusDropdown';
import BasicModal from '../../shared/CognisaasModal/CognisaasModal';
import Attachment from '../../shared/Attachment/Attachment';
import { attachmentKeyGenerator } from '../../shared/Attachment/attachmentHelpers';
import {
  addNewRequirement,
  deleteRequirementById,
  resetUpdateNewRequirementForFurthurUse,
  updateNewRequirement,
  resetNewRequirement,
  getProjectKeyMappedToThisProduct,
  createJiraIssueEquivalentToRequirement,
  updateRequirementIssueId,
  addRequiremetAttachmentsToJira,
} from '../../../store/modules/ProductRequirements/ProductDetails/slice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  selectCustomerDetailsState,
  selectJiraCustomFieldsState,
  selectJiraMappedProjectKeyState,
  selectNewRequirementState,
} from '../../../store/modules/ProductRequirements/ProductDetails/selector';
import { selectAttachmentDataDownloadQueue } from '../../../store/modules/Attachment/selector';
import {
  deleteAttachmentUsingCustomApiUrlAndPayload,
  getAttachmentsFromServer,
  resetAttachmentDownloadData,
} from '../../../store/modules/Attachment/slice';
import AttachmentViewer from '../../shared/Attachment/AttachmentFileView/AttachmentViewer';
import { selectUpdateNewRequirementState } from '../../../store/modules/ProductRequirements/ProductDetails/selector';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import { format } from 'date-fns';
import CognisaasSelect from '../../shared/CognisaasSelect/CognisaasSelect';

import Cookies from 'universal-cookie';
import JiraCustomFields from 'components/shared/JiraHelpers/JiraCustomFields';
import { getCustomerUid, getLoggedInUserEmail } from 'utils/getUid';
import { requestState } from 'store/modules/types';
import { getCustomerDetail } from 'store/modules/Common/slice';
import {
  filterByName,
  getJiraCustomFields,
  getMandatoryCustomFieldsForIssue,
} from 'components/TaskModule/jiraHelpers/jiraUtil';
import { selectJiraProjectIssueType } from 'store/modules/Task/selector';
import { getJiraProjectIssueTypesList } from 'store/modules/Task/slice';
import { isUndefined } from 'utils/dataHelpers';
import CognisaasCalenderNew from 'components/shared/CognisaasCalenderNew/CognisaasCalenderNew';
import CognisaasToast from 'components/shared/CognisaasToast/CognisaasToast';
import { UpdateReqPayload } from 'store/modules/ProductRequirements/ProductDetails/types';
import { selectClientList, selectClientListStatus } from 'store/modules/ManageClient/ClientList/selector';
import { fetchClientList } from 'store/modules/ManageClient/ClientList/slice';
import { label_client } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import CognisaasLoader from 'components/shared/CognisaasLoader/CognisaasLoader';

const cookies = new Cookies();

interface Props {
  setProdReqView: any;
  currentProductUid: any;
  currentProductDetails: Record<string, any>;
}

const AddNewRequirement = (componentProps: Props) => {
  const dispatch = useDispatch();
  const [clientsOfCustomer, setClientsOfCustomer] = useState<any[]>([]);
  const allClients = useAppSelector(selectClientList);
  const gettingClientStatus = useAppSelector(selectClientListStatus);
  const newRequirementState = useAppSelector(selectNewRequirementState);
  const [isBulUploadAttachmentOpened, setIsBulUploadAttachmentOpened] = useState(false);
  const attachmentDataDownloadQueueState = useAppSelector(selectAttachmentDataDownloadQueue);
  const [attachmentsData, setAttachmentsData] = useState<Record<string, any>[]>([]);
  const updatedNewRequirementState = useAppSelector(selectUpdateNewRequirementState);
  const [isSavingNewReq, setIsSavingNewReq] = useState(false);
  const [alertDetails, setAlertDetails] = useState<{
    isVisible: boolean;
    type: 'success' | 'failure';
    message: string;
  }>({ isVisible: false, type: 'success', message: '' });
  const customerDetailState = useAppSelector(selectCustomerDetailsState);
  const jiraCustomFieldsState = useAppSelector(selectJiraCustomFieldsState);
  const jiraProjectKeyMappedToThisProductState = useAppSelector(selectJiraMappedProjectKeyState);
  const [isJiraEnabled, setIsJiraEnabled] = useState(false);
  const [jiraIssueType, setJiraIssueType] = useState<string>('');
  const [jiraCustomFieldsModal, setJiraCustomFieldsModal] = useState<boolean>(false);
  const [issueTypesForProject, setIssueTypesForProject] = useState<any>('');
  const issueTypes = useAppSelector(selectJiraProjectIssueType);
  const [jiraCustomFields, setJiraCustomFields] = useState<any[]>([]);
  const [jiraPayload, setJiraPayload] = useState<Record<string, any>>({});
  const [payloadForRequirementUpdateAfterJira, setPayloadForRequirementUpdateAfterJira] = useState<UpdateReqPayload>({
    feature_content: '',
    feature_description: '',
    feature_estimateddeliverydate: '',
    feature_readinessstate_status: 'red',
    feature_uid: '',
    product_uid: '',
  });
  const appDispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      addNewRequirement({
        feature_content: '',
        feature_description: Date.now() + '+cgTem',
        feature_estimateddeliverydate: '',
        feature_readinessstate_status: 'red',
        product_uid: componentProps.currentProductUid,
      })
    );

    return () => {
      dispatch(resetUpdateNewRequirementForFurthurUse());
      dispatch(resetAttachmentDownloadData());
      dispatch(resetNewRequirement());
      setJiraPayload({});
    };
  }, []);

  useEffect(() => {
    if (allClients.length > 0) {
      getClientsOfCustomer();
    } else {
      dispatch(fetchClientList());
    }
  }, [allClients]);

  useEffect(() => {
    if (customerDetailState.status !== requestState.success) {
      if (customerDetailState.data.customer_jira_user && customerDetailState.data.customer_jira_user.length > 1) {
        dispatch(getCustomerDetail());
      }
    } else if (customerDetailState.status === requestState.success) {
      if (customerDetailState.data.customer_jira_user && customerDetailState.data.customer_jira_user.length > 1) {
        setIsJiraEnabled(true);
      } else {
        setIsJiraEnabled(false);
      }
    }
  }, [customerDetailState]);

  useEffect(() => {
    if (
      customerDetailState.status === requestState.success &&
      customerDetailState.data.customer_jira_user &&
      customerDetailState.data.customer_jira_user.length > 1 &&
      !isUndefined(jiraProjectKeyMappedToThisProductState.data.project_key)
    ) {
      const data1 = {
        customer_uid: getCustomerUid(),
        key: btoa(
          btoa(`${customerDetailState.data.customer_jira_user}:${customerDetailState.data.customer_jira_token}`)
        ),
        project_key: jiraProjectKeyMappedToThisProductState.data.project_key,
      };

      dispatch(getJiraProjectIssueTypesList(data1));
    }
  }, [customerDetailState, jiraProjectKeyMappedToThisProductState.data]);

  // * now since we have issue types we will have a list or array of issue types to be rendered on frontend
  useEffect(() => {
    if (issueTypes.length > 0) {
      const issues = issueTypes
        .map((issue: any) => {
          return issue.name;
        })
        .filter((item) => item !== 'Sub-task');
      setIssueTypesForProject([...issues]);
    }
  }, [issueTypes]);

  useEffect(() => {
    if (isJiraEnabled && jiraCustomFieldsState.status === 'idle') {
      dispatch(
        getProjectKeyMappedToThisProduct({
          customer_uid: componentProps.currentProductDetails.customer_uid,
          product_id: componentProps.currentProductDetails.product_issue_id,
        })
      );
    }
  }, [isJiraEnabled, jiraCustomFieldsState, customerDetailState]);

  const handleCancel = (resetForm: any) => {
    deleteRequirementWithAttachment();
    resetForm();
    componentProps.setProdReqView('landing-page');
  };

  const handleBack = () => {
    deleteRequirementWithAttachment();
    componentProps.setProdReqView('landing-page');
  };

  const deleteRequirementWithAttachment = () => {
    const myCustomerUid = cookies.get('cogni_customer_uid') as string;
    const myFeatureUid = newRequirementState.data.feature_uid;
    if (typeof myCustomerUid === 'undefined' || myCustomerUid === '') {
      alert('Error accessing the Customer');
      return;
    }

    if (typeof myFeatureUid === 'undefined' || myFeatureUid === '') {
      alert('Not able to get feature/requirement');
      return;
    }

    dispatch(
      deleteAttachmentUsingCustomApiUrlAndPayload({
        apiDeleteAttachmentUrl: 'deleteAttachmentsByFeatureUid',
        apiDeletePayload: {
          customer_uid: myCustomerUid,
          feature_uid: myFeatureUid,
          key: attachmentKeyGenerator(myCustomerUid as string, myFeatureUid),
        },
      })
    );

    dispatch(
      deleteRequirementById({
        customer_uid: myCustomerUid as string,
        feature_uid: myFeatureUid,
      })
    );
  };

  const goBack = () => {
    componentProps.setProdReqView('landing-page');
  };

  const attachmentCallback = (actionPerformed: string) => {
    console.log(actionPerformed);

    if (actionPerformed === 'uploaded-bulk-attachments') {
      // * get attachments uploaded on server and save in state and later show them
      dispatch(
        getAttachmentsFromServer(`getAttachmentsByFeatureUid?feature_uid=${newRequirementState.data.feature_uid}`)
      );

      setTimeout(() => {
        getAttachmentsFromServer(`getAttachmentsByFeatureUid?feature_uid=${newRequirementState.data.feature_uid}`);
      }, 3000);
    }

    setIsBulUploadAttachmentOpened(false);
  };

  useEffect(() => {
    setAttachmentsData(attachmentDataDownloadQueueState.data);
  }, [attachmentDataDownloadQueueState]);
  const reloadAttachments = () => {
    setAttachmentsData(attachmentDataDownloadQueueState.data);

    dispatch(
      getAttachmentsFromServer(`getAttachmentsByFeatureUid?feature_uid=${newRequirementState.data.feature_uid}`)
    );
  };

  useEffect(() => {
    if (updatedNewRequirementState.status === 'success') {
      setAlertDetails({ isVisible: true, type: 'success', message: 'New requirement added successfully' });
      goBack();
    } else if (updatedNewRequirementState.status === 'failed') {
      setAlertDetails({
        isVisible: true,
        type: 'success',
        message: 'something went wrong while udating in server',
      });
      goBack();
    }
  }, [updatedNewRequirementState, isJiraEnabled]);

  const handleJiraIssueCreation = async (
    selectedIssueName: string,
    jiraPayload: Record<string, any>,
    payloadForRequirementUpdate: UpdateReqPayload
  ) => {
    const detail = {
      customer_uid: getCustomerUid(),
      key: btoa(btoa(`${customerDetailState.data.customer_jira_user}:${customerDetailState.data.customer_jira_token}`)),
      project_key: jiraProjectKeyMappedToThisProductState.data.project_key,
    };

    const jiraCustomFields = await getJiraCustomFields(detail);
    const issue = issueTypes.filter((issue: any) => issue.name == selectedIssueName);
    const issue_id = issue[0].id;
    const rand_array = jiraCustomFields.projects[0].issuetypes.filter(function (item1: any) {
      return item1.id === issue_id;
    });
    const field_obj: any = filterByName(rand_array[0].fields, 'Client Name');
    const customClientName: string = field_obj[0].key;
    const tempJiraPayload: any = { ...jiraPayload, customClientName: customClientName };
    setJiraPayload(tempJiraPayload);
    const mandatoryFields = getMandatoryCustomFieldsForIssue(jiraCustomFields, issue[0]);

    if (mandatoryFields.length > 0) {
      setJiraCustomFields([...mandatoryFields]);
      setJiraCustomFieldsModal(true);
    } else {
      // * it is a noral issue with no custom fields
      await appDispatch(createJiraIssueEquivalentToRequirement(tempJiraPayload)).then(async (res: any) => {
        const result = JSON.parse(res.payload);
        await dispatch(
          updateRequirementIssueId({
            feature_issue_id: result.id,
            feature_issue_type_name: jiraIssueType,
            feature_uid: newRequirementState?.data.feature_uid,
            jira_issue_key: result.key,
          })
        );
        await dispatch(
          addRequiremetAttachmentsToJira({
            customer_uid: customerDetailState.data.customer_uid,
            feature_uid: newRequirementState?.data.feature_uid,
            issueID: result.id,
            key: btoa(
              btoa(`${customerDetailState.data.customer_jira_user}:${customerDetailState.data.customer_jira_token}`)
            ),
          })
        );
      });
      await dispatch(updateNewRequirement(payloadForRequirementUpdate));
      goBack();
      setAlertDetails({
        isVisible: true,
        type: 'success',
        message: 'New Requirement created to Jira successfully',
      });
    }
  };

  const handleCustomMandatoryFieldsSave = async (newMandatoryFieldsWithValue: any[]) => {
    console.log('after clicking save on jira mandatory fields', newMandatoryFieldsWithValue);

    let tempJiraPayload: any = { ...jiraPayload };

    if (newMandatoryFieldsWithValue.length > 0) {
      tempJiraPayload = {
        ...tempJiraPayload,
        required_fields: newMandatoryFieldsWithValue.map((item: any) => {
          const tempItem = { ...item };
          delete tempItem['newValue'];
          return tempItem;
        }),
        formValues: newMandatoryFieldsWithValue.map((item: any) => ({ field_value: item.newValue })),
      };
    }
    await appDispatch(createJiraIssueEquivalentToRequirement(tempJiraPayload)).then(async (res: any) => {
      const result = JSON.parse(res.payload);
      await dispatch(
        updateRequirementIssueId({
          feature_issue_id: result.id,
          feature_issue_type_name: jiraIssueType,
          feature_uid: newRequirementState?.data.feature_uid,
          jira_issue_key: result.key,
        })
      );
      await dispatch(
        addRequiremetAttachmentsToJira({
          customer_uid: customerDetailState.data.customer_uid,
          feature_uid: newRequirementState?.data.feature_uid,
          issueID: result.id,
          key: btoa(
            btoa(`${customerDetailState.data.customer_jira_user}:${customerDetailState.data.customer_jira_token}`)
          ),
        })
      );
    });
    await dispatch(updateNewRequirement({ ...payloadForRequirementUpdateAfterJira }));
    goBack();

    setAlertDetails({
      isVisible: true,
      type: 'success',
      message: 'New Requirement created to Jira successfully',
    });
  };
  const getClientsOfCustomer = () => {
    const list = allClients.map((client: any) => {
      if (client.__user_permissions.includes('edit')) {
        return client.client_name;
      }
    });
    setClientsOfCustomer([...list]);
  };
  return (
    <>
      <CognisaasToast
        fromPanel={true}
        open={alertDetails.isVisible}
        onClose={() => setAlertDetails({ isVisible: false, type: alertDetails.type, message: '' })}
        message={alertDetails.message}
        type={alertDetails.type}
      />
      {gettingClientStatus != requestState.success ? (
        <CognisaasLoader />
      ) : (
        <div className={Styles.AddNewRequirement__bigWrapper}>
          <div className={Styles.AddNewRequirement__container}>
            <Button sx={{ display: 'block' }} onClick={handleBack}>
              Product Requirements
            </Button>
            <Button sx={{ marginBottom: '20px' }} startIcon={<LeftArrowIcon />} onClick={handleBack}>
              <Typography style={{ color: 'black' }} variant="h1">
                &nbsp;New Requirement
              </Typography>
            </Button>
            <Formik
              initialValues={{
                requirementName: '',
                requirementStatus: 'red',
                etaForRelease: '',
                clientName: '',
                requirementDescription: '',
                jiraIssueType: '',
              }}
              onSubmit={async (values: any) => {
                if (isJiraEnabled) {
                  if (values.jiraIssueType.length === 0) {
                    setAlertDetails({ isVisible: true, type: 'failure', message: 'Jira Issue Type is Mandatory!' });
                    setIsSavingNewReq(false);
                    return;
                  } else if (values.clientName.trim() === '') {
                    setAlertDetails({ isVisible: true, type: 'failure', message: `${label_client}Name Is Mandatory` });
                    setIsSavingNewReq(false);
                    return;
                  }
                }
                setIsSavingNewReq(true);

                // * basic validation
                if (values.requirementName.trim() === '') {
                  setAlertDetails({ isVisible: true, type: 'failure', message: 'Requirement Name Is Mandatory' });
                  setIsSavingNewReq(false);
                } else {
                  try {
                    const serverPayload = {
                      feature_content: values.requirementDescription,
                      feature_description: values.requirementName,
                      feature_estimateddeliverydate:
                        values.etaForRelease === '' ? '' : format(values.etaForRelease, 'yyyy-MM-dd'),
                      feature_readinessstate_status: values.requirementStatus,
                      feature_uid: newRequirementState?.data.feature_uid,
                      product_uid: componentProps.currentProductUid,
                    };
                    setIsSavingNewReq(true);
                    if (isJiraEnabled) {
                      const tempJiraPayload: any = {
                        custom_field_id: '',
                        customer_uid: customerDetailState.data.customer_uid,
                        client_name: values.clientName,
                        description: values.requirementDescription,
                        formValues: '',
                        id: componentProps.currentProductDetails.product_issue_id,
                        key: btoa(
                          btoa(
                            `${customerDetailState.data.customer_jira_user}:${customerDetailState.data.customer_jira_token}`
                          )
                        ),
                        name: values.jiraIssueType,
                        product_name: componentProps.currentProductDetails.product_name,
                        reporter: '',
                        required_fields: [],
                        summary: values.requirementName,
                        userEmail: getLoggedInUserEmail(),
                      };
                      setJiraIssueType(values.jiraIssueType);
                      setJiraPayload(tempJiraPayload);
                      setPayloadForRequirementUpdateAfterJira(serverPayload);
                      await handleJiraIssueCreation(values.jiraIssueType, tempJiraPayload, serverPayload);
                    } else {
                      await dispatch(updateNewRequirement(serverPayload));

                      setAlertDetails({
                        isVisible: true,
                        type: 'success',
                        message: 'New Requirement Created To Our Portal Successfully',
                      });
                    }
                  } catch {
                    setAlertDetails({
                      isVisible: true,
                      type: 'failure',
                      message: 'Some Enteries are Not Correct, Try Again!',
                    });
                    setIsSavingNewReq(false);
                  }
                }
              }}
            >
              {(props: any) => (
                <form className={Styles.AddNewRequirement__bodyForm} onSubmit={props.handleSubmit}>
                  {/* form section */}
                  <div className={Styles.AddNewRequirement__formSection}>
                    {/* form field requirement name  */}
                    <div className={Styles.AddNewRequirement__formSingleField}>
                      <Typography variant="subtitle1">Requirement Name*</Typography>
                      <TextField
                        sx={{ width: '310px' }}
                        autoFocus
                        size="small"
                        id="requirementName"
                        name="requirementName"
                        placeholder="Enter Requirement Name"
                        onChange={props.handleChange}
                        value={props.values.requirementName}
                        // onBlur={props.handleBlur}
                        // error={props.touched.requirementName && Boolean(props.errors.requirementName)}
                        // helperText={props.touched.requirementName && props.errors.requirementName}
                      ></TextField>
                    </div>

                    {isJiraEnabled && (
                      <div className={Styles.AddNewRequirement__formSingleField}>
                        <Typography variant="subtitle1">Jira Issue Type*</Typography>

                        <CognisaasSelect
                          width="195px"
                          onChange={props.handleChange}
                          name="jiraIssueType"
                          value={props.values.jiraIssueType}
                          validValues={[...issueTypesForProject]}
                          // onBlur={props.handleBlur}
                          // error={props.touched.jiraIssueType && Boolean(props.errors.jiraIssueType)}
                          // helperText={props.touched.jiraIssueType && props.errors.jiraIssueType}
                        />
                      </div>
                    )}

                    {/* requirement status */}
                    <div className={Styles.AddNewRequirement__formSingleField}>
                      <Typography variant="subtitle1">Requirement Status*</Typography>
                      <FormControl sx={{ minWidth: '250px' }} size="small">
                        <CognisaasRequirementStatusDropdown
                          readOnly={
                            customerDetailState.data.customer_jira_token &&
                            customerDetailState.data.customer_jira_token.length > 0
                          }
                          sx={{ minWidth: '250px' }}
                          placeholder={'Select'}
                          name={'requirementStatus'}
                          value={props.values.requirementStatus}
                          changeHandler={props.handleChange}
                        />
                      </FormControl>
                    </div>
                    {isJiraEnabled && (
                      <div className={Styles.AddNewRequirement__formSingleField}>
                        <Typography variant="subtitle1">{label_client} Name*</Typography>
                        <FormControl sx={{ minWidth: '250px' }} size="small">
                          <CognisaasSelect
                            name="clientName"
                            id="clientName"
                            width="257px"
                            value={props.values.clientName}
                            onChange={props.handleChange}
                            validValues={[...clientsOfCustomer]}
                          />
                        </FormControl>
                      </div>
                    )}

                    {(props.values.requirementStatus as string) == 'amber' ? (
                      <div className={Styles.AddNewRequirement__formSingleField}>
                        <Typography variant="subtitle1">ETA for Release</Typography>

                        <CognisaasCalenderNew
                          name={'etaForRelease'}
                          value={props.values.etaForRelease}
                          handleChange={(e: any) => {
                            props.setFieldValue('etaForRelease', e);
                          }}
                          disabled={
                            customerDetailState.data.customer_jira_token &&
                            customerDetailState.data.customer_jira_token.length > 0
                          }
                        />
                        {/* <DatePickerField
                        name={'etaForRelease'}
                        // selected={props.values.allRequirements[index].etaForRelease}
                        // onChange={(date: Date) => props.handleChange(date)}
                        onChange={props.setFieldValue}
                        value={props.values.etaForRelease}
                      /> */}
                      </div>
                    ) : (
                      ''
                    )}
                  </div>

                  <div>
                    <div className={Styles.AddNewRequirement__formSingleField}>
                      {/* comment component here */}
                      <Typography variant="subtitle2">Requirement Description</Typography>
                      <FormControl sx={{ width: '100%', minWidth: '300px' }}>
                        <TextField
                          id="requirementDescription"
                          name="requirementDescription"
                          placeholder="Enter Requirement Description"
                          onChange={props.handleChange}
                          value={props.values.requirementDescription}
                          multiline
                          rows={4}
                          onBlur={props.handleBlur}
                          error={props.touched.requirementDescription && Boolean(props.errors.requirementDescription)}
                          helperText={props.touched.requirementDescription && props.errors.requirementDescription}
                        ></TextField>
                      </FormControl>
                    </div>
                  </div>

                  {/* attachment header */}
                  <div className={Styles.AddNewRequirement__attachmentHeader}>
                    <Typography variant="subtitle1">Attachment</Typography>
                    <Button onClick={() => setIsBulUploadAttachmentOpened(true)}>
                      <Typography variant="subtitle2">Upload Attachment</Typography>
                    </Button>
                  </div>

                  <BasicModal
                    height={280}
                    width={350}
                    open={isBulUploadAttachmentOpened}
                    onClose={() => setIsBulUploadAttachmentOpened(false)}
                  >
                    {newRequirementState.status === 'success' ? (
                      <Attachment
                        variant="bulk-upload"
                        type="normal"
                        metaDataForHeaders={{
                          featureUid: newRequirementState?.data.feature_uid,
                          product_uid: componentProps.currentProductUid,
                          feature_issue_id: '',
                          customer_uid: cookies.get('cogni_customer_uid') as string,
                          key: attachmentKeyGenerator('customer_uid', 'field_uid'),
                        }}
                        apiUrlForAttachmentUpload={'addFeatureAttachments'}
                        callbackOnWhatIsDoneWithAttachments={(actionPerformed: any) =>
                          attachmentCallback(actionPerformed)
                        }
                        setAlertDetails={setAlertDetails}
                      />
                    ) : (
                      ''
                    )}
                  </BasicModal>

                  {/* attachments display */}
                  <div>
                    {attachmentsData.map((oneAttachment, index) => {
                      return (
                        <div key={index} className={Styles.AddNewRequirement__oneAttachmentWrapper}>
                          <AttachmentViewer
                            fileName={oneAttachment.name}
                            attachmentUid={oneAttachment.attachment_uid}
                            serverGetApiUrlWithQueryString={`downloadFeatureAttachment?attachment_uid=${oneAttachment.attachment_uid}`}
                            deleteApiUrl={'deleteFeatureAttachment'}
                            deleteAttachmentPayload={{
                              attachment_uid: oneAttachment.attachment_uid,
                              customer_uid: cookies.get('cogni_customer_uid') as string,
                              featureUid: '628471d5eec14',
                              feature_issue_id: '',
                              key: attachmentKeyGenerator(cookies.get('cogni_customer_uid') as string, '628471d5eec14'),
                            }}
                            deleteCallbackFunc={reloadAttachments}
                          />
                          <Divider />
                        </div>
                      );
                    })}
                  </div>

                  {/* buttons for furthur form actions */}
                  <div className={Styles.AddNewRequirement__formActionButtonsContainer}>
                    {/* save and cancel this step buttons  */}
                    <CognisaasButton
                      label={'Cancel'}
                      isOutlined={true}
                      clickHandler={handleCancel.bind(null, props.resetForm)}
                    />
                    <span style={{ width: '20px' }}></span>

                    {isSavingNewReq ? (
                      <LoadingButton
                        sx={{ width: '77px' }}
                        loading
                        loadingPosition="start"
                        startIcon={<SaveIcon />}
                        variant="outlined"
                      >
                        Save
                      </LoadingButton>
                    ) : (
                      <CognisaasButton
                        sx={{ width: '77px' }}
                        label={'Save'}
                        isOutlined={false}
                        clickHandler={props.handleSubmit}
                      />
                    )}
                  </div>
                </form>
              )}
            </Formik>

            {/* jira custom fields modal */}
            <BasicModal open={jiraCustomFieldsModal} onClose={() => setJiraCustomFieldsModal(!jiraCustomFieldsModal)}>
              <Stack>
                <JiraCustomFields
                  onClose={() => {
                    setIsSavingNewReq(false);
                    setJiraCustomFieldsModal(!jiraCustomFieldsModal);
                  }}
                  taskDetail={{}}
                  fields={jiraCustomFields}
                  handleCustomFieldsSave={handleCustomMandatoryFieldsSave}
                ></JiraCustomFields>
              </Stack>
            </BasicModal>

            {/* this is alerts */}
            {/* <Snackbar open={alertDetails.isVisible} autoHideDuration={6000}>
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => setAlertDetails({ isVisible: false, severity: '', message: '' })}
              severity={alertDetails.severity as AlertColor}
              sx={{ width: '100%' }}
            >
              {alertDetails.message}
            </Alert>
          </Snackbar> */}
          </div>
        </div>
      )}
    </>
  );
};

export default AddNewRequirement;
