import { label_task } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import DeleteModal from 'components/shared/CognisaasModal/DeleteModal';
import { useDispatch } from 'react-redux';
import { getProjectInformation } from 'store/modules/Project/slice';
import { bulkDeleteTask, deleteTaskFromTemporaryTableAndSendMail } from 'store/modules/Task/slice';

export default function DeleteDialog(props: any) {
  const dispatch = useDispatch();

  const deleteTasks = async () => {
    await dispatch(bulkDeleteTask(props.taskSelected));
    const data = {
      association_uid: props.association_uid,
      project_uid: props.project_uid,
    };
    await dispatch(getProjectInformation(data)); // after the task gets updated, close the dialog
    await dispatch(deleteTaskFromTemporaryTableAndSendMail(props.taskSelectedForDeleteDetails));
    props.setOpen(false);
  };

  console.log('asdasd', props.taskSelected);

  return (
    <DeleteModal
      isOpen={props.open}
      message={
        props.taskSelected.length > 1
          ? `Are you sure you want to permanently delete these ${
              props.taskSelected?.length
            } ${label_task.toLowerCase()}s?`
          : `Are you sure you want to permanently delete this ${
              props.taskSelected?.length
            } ${label_task.toLowerCase()}?`
      }
      onDelete={deleteTasks}
      setIsOpen={props.setOpen}
    />
  );
}
