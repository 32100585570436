import { endOfDay } from 'date-fns';
import { getContactNameByContactUid, getUserNameByUserUid } from 'utils/getUid';
import { statusColorMap } from './config';
import {
  selectHighlightCriticalPathInProjectGantt,
  selectHighlightCriticalPathInUsecaseGantt,
} from 'store/modules/Project/selector';
import {
  TGanttDependency,
  TGanttMilestoneTask,
  TGanttProject,
  TGanttSection,
  TGanttTask,
  TGanttUsecase,
} from './types';
import { convertMinutes, dateDiffInDays } from './utils';
import { useAppSelector } from 'store/hooks';

export function getKeyByValue(object: any, value: string): string {
  return Object.keys(object).find((key: string) => object[key] === value) ?? '';
}

const IsEntityStatusNotDone = (status: string) => status !== 'blue';
const IsDateAvailable = (date: string) => date !== '0000-00-00 00:00:00';
const IsTaskNotDone = (stage: string) => stage !== 'Done';
let highlightCP = false;
let highlightCPUsecase = false;

const getRelevantEntityStartDate = (actualDate: string, plannedDate: string): Date => {
  let date = actualDate;
  const isDateAvailable: boolean = IsDateAvailable(date);
  if (!isDateAvailable) date = plannedDate;
  return new Date(date);
};

const getRelevantEntityEndDate = (actualDate: string, plannedDate: string, status: string): Date => {
  let date = actualDate;
  const isEntityStatusNotDone = IsEntityStatusNotDone(status);
  if (isEntityStatusNotDone) date = plannedDate;
  return new Date(date);
};

export const transformToGanttProjectFormat = ({ ...ganttProject }: any) => {
  const highlightCriticalPathInProjectGantt = useAppSelector(selectHighlightCriticalPathInProjectGantt);
  highlightCP = highlightCriticalPathInProjectGantt;
  const gProject: TGanttProject = {} as TGanttProject;
  gProject.id = ganttProject.project_uid;
  gProject.text = ganttProject.project_name;
  gProject.holder = ganttProject.project_deliverymanager;

  gProject.start_date = getRelevantEntityStartDate(
    ganttProject.project_startdate,
    ganttProject.project_planned_startdate
  );
  gProject.end_date = getRelevantEntityEndDate(
    ganttProject.project_deliverydate,
    ganttProject.project_requiredbydate,
    ganttProject.project_readinessstate_status
  );
  gProject.planned_start = ganttProject.project_planned_startdate;
  gProject.planned_end = ganttProject.project_requiredbydate;

  gProject.color = statusColorMap[ganttProject.project_readinessstate_status];
  gProject.entity = 'project';
  gProject.type = 'project';
  gProject.open = true;
  gProject.locked = true;
  const plannedDuration = dateDiffInDays(
    new Date(ganttProject.project_planned_startdate),
    new Date(ganttProject.eta_forprojectdelivery)
  );
  gProject.planned_duration = plannedDuration + 1;
  return gProject;
};

export const getAvailableStartDate = ({ ...ganttTask }) => {
  let taskStartDate = ganttTask.task_start_date;
  const isActualStartDateAvailable: boolean = IsDateAvailable(taskStartDate);

  if (!isActualStartDateAvailable) {
    taskStartDate = new Date(ganttTask.task_planned_start_date);
  }

  return new Date(taskStartDate);
};

export const getAvailableEndDate = ({ ...ganttTask }) => {
  let taskEndDate = ganttTask.task_due_date;
  const isTaskNotDone = IsTaskNotDone(ganttTask.task_stage);

  if (isTaskNotDone) {
    taskEndDate = new Date(ganttTask.task_closed_date);
  }

  return endOfDay(new Date(taskEndDate));
};

export const includeAvailableClientUid = (task: any, sections: any[]) => {
  if (task?.client_uid) return { ...task };
  const selectedSection = sections.length ? sections.find((item) => item.section_uid === task.section_uid) : null;
  const sectionClientUid = selectedSection?.client_uid ?? null;
  return { ...task, client_uid: sectionClientUid };
};

export const transformToGanttTaskFormat = ({ ...ganttTask }: any) => {
  const gTask: TGanttTask = {} as TGanttTask;
  gTask.id = ganttTask.task_uid;
  gTask.text = ganttTask.task_name;

  gTask.start_date = getAvailableStartDate(ganttTask);
  gTask.end_date = getAvailableEndDate(ganttTask);
  gTask.planned_start = new Date(ganttTask.task_planned_start_date);
  gTask.planned_end = new Date(ganttTask.task_due_date);

  gTask.parent = ganttTask.section_uid;
  gTask.color = ganttTask.case_uid
    ? highlightCPUsecase && ganttTask.is_critical
      ? '#D21404'
      : ''
    : highlightCP && ganttTask.is_critical
    ? '#D21404'
    : '';
  gTask.entity = 'task';
  gTask.type = 'task';
  gTask.open = false;
  gTask.locked = false;
  gTask.owner_id = ganttTask.task_owner; // required for resource management component
  gTask.holder =
    ganttTask.is_team_task === '1'
      ? getUserNameByUserUid(ganttTask.task_owner)
      : getContactNameByContactUid(ganttTask.task_owner);
  const plannedDuration = dateDiffInDays(
    new Date(ganttTask.task_planned_start_date),
    new Date(ganttTask.task_due_date)
  );
  const effortEstimateOrPlannedDuration =
    convertMinutes(parseInt(ganttTask.effort_estimate, 10)) || plannedDuration + 1;
  gTask.planned_duration = effortEstimateOrPlannedDuration;
  gTask.client_uid = ganttTask.client_uid;
  gTask.phase_uid = ganttTask.section_uid;
  gTask.project_uid = ganttTask.project_uid;
  gTask.task_stage = ganttTask.task_stage;
  return gTask;
};

export const transformToAPITaskFormat = ({ ...ganttTask }: any) => {
  const taskForAPI: any = {};

  taskForAPI.task_planned_start_date = new Date(ganttTask.planned_start).toLocaleDateString();
  taskForAPI.task_due_date = new Date(ganttTask.planned_end).toLocaleDateString();
  taskForAPI.task_start_date = new Date(ganttTask.start_date).toLocaleDateString();
  taskForAPI.task_closed_date = new Date(ganttTask.end_date).toLocaleDateString();

  taskForAPI.entity = 'task';
  taskForAPI.type = isMilestoneTask(ganttTask) ? 'milestone' : 'task';
  taskForAPI.client_uid = ganttTask.client_uid;
  taskForAPI.phase_uid = ganttTask.phase_uid;
  taskForAPI.project_uid = ganttTask.project_uid;
  return taskForAPI;
};

export const isMilestoneTask = (item: TGanttMilestoneTask | TGanttTask) => item.milestone_task === '1';
export const filterByMilestoneTask = (item: TGanttMilestoneTask) => item.milestone_task === '1';
export const transformToGanttMileStoneTaskFormat = ({ ...ganttMilestoneTask }: any) => {
  const gMilestoneTask: TGanttMilestoneTask = {} as TGanttMilestoneTask;
  gMilestoneTask.id = ganttMilestoneTask.task_uid;
  gMilestoneTask.text = ganttMilestoneTask.task_name;

  gMilestoneTask.start_date = getAvailableStartDate(ganttMilestoneTask);
  gMilestoneTask.end_date = getAvailableEndDate(ganttMilestoneTask);
  gMilestoneTask.planned_start = ganttMilestoneTask.task_planned_start_date;
  gMilestoneTask.planned_end = ganttMilestoneTask.task_due_date;

  gMilestoneTask.parent = ganttMilestoneTask.section_uid;
  gMilestoneTask.entity = 'task';
  gMilestoneTask.type = 'milestone';
  gMilestoneTask.open = false;
  gMilestoneTask.locked = false;
  gMilestoneTask.owner_id = ganttMilestoneTask.task_owner; // required for resource management component
  gMilestoneTask.holder =
    ganttMilestoneTask.is_team_task === '1'
      ? getUserNameByUserUid(ganttMilestoneTask.task_owner)
      : getContactNameByContactUid(ganttMilestoneTask.task_owner);
  const plannedDuration = dateDiffInDays(
    new Date(ganttMilestoneTask.task_planned_start_date),
    new Date(ganttMilestoneTask.task_due_date)
  );
  const effortEstimateOrPlannedDuration =
    convertMinutes(parseInt(ganttMilestoneTask.effort_estimate, 10)) || plannedDuration + 1;
  gMilestoneTask.planned_duration = effortEstimateOrPlannedDuration;
  gMilestoneTask.client_uid = ganttMilestoneTask.client_uid;
  gMilestoneTask.phase_uid = ganttMilestoneTask.section_uid;
  gMilestoneTask.project_uid = ganttMilestoneTask.project_uid;
  gMilestoneTask.task_stage = ganttMilestoneTask.task_stage;
  return gMilestoneTask;
};

const getTasksEffortEstimate = (ganttSection: any, sectionListBySectionUid: Record<string, any[]>) => {
  if (sectionListBySectionUid[ganttSection?.section_uid]) {
    const sumOfTasksEffortEstimate = sectionListBySectionUid[ganttSection.section_uid].reduce(
      (sum: number, taskItem: any) => {
        const sumOfTasksEffortEstimate: number = sum + parseInt(taskItem.effort_estimate, 10);
        return sumOfTasksEffortEstimate;
      },
      0
    );
    return convertMinutes(sumOfTasksEffortEstimate) || '0m';
  }
  return '0m';
};

export const transformToGanttSectionFormat = (
  { ...ganttSection }: any,
  sectionListBySectionUid: Record<string, any[]>
) => {
  const gSection: TGanttSection = {} as TGanttSection;
  gSection.id = ganttSection.section_uid;
  gSection.text = ganttSection.section_detail;
  gSection.holder = '';

  gSection.start_date = ganttSection.start_date;
  gSection.end_date = ganttSection.delivery_date;
  gSection.planned_start = ganttSection.start_date;
  gSection.planned_end = ganttSection.delivery_date;

  gSection.parent = ganttSection?.case_uid ? ganttSection.case_uid : ganttSection.project_uid;
  gSection.color = '#666666';
  gSection.holder = '';
  gSection.entity = 'section';
  gSection.type = 'section';
  gSection.open = true;
  gSection.locked = true;
  const plannedDuration = dateDiffInDays(new Date(ganttSection.start_date), new Date(ganttSection.delivery_date));
  const allTasksEffortEstimate = getTasksEffortEstimate(ganttSection, sectionListBySectionUid);
  gSection.planned_duration = allTasksEffortEstimate || `${plannedDuration + 1}d`;
  gSection.project_uid = ganttSection.project_uid;
  gSection.readonly = true;
  return gSection;
};

export const transformToGanttUsecaseFormat = ({ ...ganttUsecase }: any) => {
  const highlightCriticalPathInUsecaseGantt = useAppSelector(selectHighlightCriticalPathInUsecaseGantt);
  highlightCPUsecase = highlightCriticalPathInUsecaseGantt;
  const gUsecase: TGanttUsecase = {} as TGanttUsecase;
  gUsecase.id = ganttUsecase.case_uid;
  gUsecase.text = ganttUsecase.case_name;
  gUsecase.holder = ganttUsecase.case_deliverymanager;

  gUsecase.start_date = getRelevantEntityStartDate(ganttUsecase.case_startdate, ganttUsecase.case_planned_startdate);
  gUsecase.end_date = getRelevantEntityEndDate(
    ganttUsecase.case_deliverydate,
    ganttUsecase.case_requiredbydate,
    ganttUsecase.case_readinessstate_status
  );
  gUsecase.planned_start = ganttUsecase.case_planned_startdate;
  gUsecase.planned_end = ganttUsecase.case_requiredbydate;

  gUsecase.color = statusColorMap[ganttUsecase.case_readinessstate_status];
  // gUsecase.parent = ganttUsecase.project_uid;
  gUsecase.entity = 'usecase';
  gUsecase.type = 'project';
  gUsecase.open = true;
  gUsecase.locked = true;
  const plannedDuration = dateDiffInDays(
    new Date(ganttUsecase.case_planned_startdate),
    new Date(ganttUsecase.case_requiredbydate)
  );
  gUsecase.planned_duration = plannedDuration + 1;
  gUsecase.project_uid = ganttUsecase.project_uid;
  return gUsecase;
};

export const transformToGanttDependencyFormat = ({ ...ganttDep }: any) => {
  const gDep: TGanttDependency = {} as TGanttDependency;
  gDep.id = ganttDep.dependency_id;
  gDep.source = ganttDep.task_parent_uid;
  gDep.target = ganttDep.task_uid;
  gDep.type = '0';
  return gDep;
};

export const getResourceData = (item: TGanttTask) => ({ id: item.owner_id, text: item.holder });
