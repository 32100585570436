import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { clientAPI } from '../../../../api/modules/client';
import { requestState } from '../../types';

interface clientsType {
  clients: Array<any>;
  status: requestState.idle | requestState.loading | requestState.success | requestState.failed;
  error: null | undefined | string;
}

const initialState: clientsType = {
  clients: [],
  status: requestState.idle,
  error: null,
} as clientsType;

export const fetchAllClients = createAsyncThunk('projects/fetchAllClients', async () => {
  const { data } = await clientAPI.getClientList();
  return data.result;
});

const clientsSlice = createSlice({
  name: 'openProjects',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchAllClients.pending, (state) => {
        state.status = requestState.loading;
      })
      .addCase(fetchAllClients.fulfilled, (state, action) => {
        state.status = requestState.success;
        state.clients = action.payload;
      })
      .addCase(fetchAllClients.rejected, (state, action) => {
        state.status = requestState.failed;
        state.error = action.error.message;
      });
  },
});

export const getAllClients = (state: clientsType) => state.clients;
const reportsClientsReducer = clientsSlice.reducer;
export default reportsClientsReducer;
