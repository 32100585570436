import { useCallback, useState } from 'react';
// In your React project folder, run:
// npm install --save @mergeapi/react-merge-link
import { useMergeLink } from '@mergeapi/react-merge-link';
import { Card, Grid, Typography } from '@mui/material';
import { ReactComponent as Hubspot } from '../../../../assets/svgs/hubspot.svg';
import { useAppDispatch } from 'store/hooks';
import { getCRMStagesSlice, getCustomerIntegrationDetails, getMergeLink, swapPublicToken } from 'store/modules/Settings/slice';
import { useEffect } from 'react';
import Cookies from 'universal-cookie';
import CognisaasToast from 'components/shared/CognisaasToast/CognisaasToast';
import { isNull, isUndefined } from 'utils/dataHelpers';
const cookies = new Cookies();
const HubspotMergeDialogBox = () => {
    const dispatch = useAppDispatch();
    const [alertDetails, setAlertDetails] = useState<{
        isVisible: boolean;
        type: 'success' | 'failure' | 'info';
        message: string;
    }>({ isVisible: false, type: 'success', message: '' });
    const [link_token, setlink_token] = useState({
        'link_token': '',
        'integration_name': '',
        'magic_link_url': ''
    });
    useEffect(() => {
        getTheMergeLinkToken();
    }, []);
    const getTheMergeLinkToken = (async () => {
        const body = { customer_uid: cookies.get('cogni_customer_uid'), company_name: cookies.get('customerCompanyName'), user_email: cookies.get('cogni_user_email'), integrations_name: 'hubspot' };
        const link = await dispatch(getMergeLink(body)).unwrap();
        if (!isNull(link.data) && !isUndefined(JSON.parse(link.data).link_token))
            setlink_token(JSON.parse(link.data));
    });
    const onSuccess = useCallback(async (token) => {
        // Send public_token to server (Step 3)
        const sent_token = { public_token: token, customer_uid: cookies.get('cogni_customer_uid') };
        console.log(sent_token);
        await dispatch(swapPublicToken(sent_token));
        await dispatch(getCustomerIntegrationDetails());
        dispatch(getCRMStagesSlice());

    }, []);
    const { open } = useMergeLink({
        // Replace ADD_GENERATED_LINK_TOKEN with the token retrieved from your backend (Step 1)
        linkToken: link_token.link_token,
        onSuccess,
        // tenantConfig: {
        // apiBaseURL: "https://api-eu.merge.dev" /* OR your specified single tenant API base URL */
        // },
    });

    const checkLinkPresentOrNot = () => {
        if (link_token.link_token == '') {
            setAlertDetails({ isVisible: true, type: 'info', message: 'Please contact your CogniSaaS success partner to enable this feature.' });
        } else {
            open();
        }
    };

    return (
        <>
            <CognisaasToast
                message={alertDetails.message}
                type={alertDetails.type}
                open={alertDetails.isVisible}
                onClose={() => {
                    setAlertDetails({ isVisible: false, type: alertDetails.type, message: '' });
                }}
            />
            <Card
                onClick={() => { checkLinkPresentOrNot(); }}
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '300px',
                    background: 'white',
                    marginTop: '15px',
                    cursor: 'pointer',
                    boxShadow: '0px 4px 26px rgba(0, 0, 0, 0.05)',
                }}>
                <Grid sx={{ background: '#F3F3F3' }}><Hubspot style={{ width: '70px', height: '65px', margin: '0' }} /></Grid>
                <Grid>
                    <Grid>
                        <Typography sx={{ paddingTop: '16px', paddingLeft: '20px' }} variant={'h3'}>
                            Hubspot
                        </Typography>
                    </Grid>
                    <Grid>
                        <Typography sx={{ paddingTop: '4px', paddingLeft: '20px', color: '#7A7A7A' }} variant={'subtitle1'}>
                            CRM
                        </Typography>
                    </Grid>
                </Grid>
            </Card>

        </>
    );
};

export default HubspotMergeDialogBox;