import { useState, useEffect } from 'react';
import {
  Typography,
  Stack,
  Box,
  Avatar,
  Collapse,
  Paper,
  Alert,
  Link,
  SelectChangeEvent,
  Button,
  Menu,
  MenuItem,
  IconButton,
  TextField,
} from '@mui/material';
import {
  DataGridPremium,
  useGridApiRef,
  GridRenderCellParams,
  GridSelectionModel,
  GridRowOrderChangeParams,
  useGridApiContext,
  GridRenderEditCellParams,
} from '@mui/x-data-grid-premium';
import { styled } from '@mui/material/styles';
import CognisaasTaskStageIndicator, {
  taskStage,
} from '../../../../shared/CognisaasTaskStageIndicator/CognisaasTaskStageIndicator';
import { ReactComponent as NoRecord } from '../../../../../assets/svgs/no_records.svg';
import {
  getContactNameByContactUid,
  // getContactUidByContactName,
  getCustomerCompanyName,
  getCustomerSubDomain,
  getCustomerUid,
  getLoggedInUserName,
  getUserNameByUserUid,
  getUserUid,
} from '../../../../../utils/getUid';
import { ReactComponent as EditSVG } from '../../../../../assets/svgs/TableEditButton.svg';
import '../../../../TaskModule/TaskView/TableView/TableView.css';
import { useParams } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import UpdateTaskDrawer from '../../../../TaskModule/TaskView/UpdateTaskDrawer';
import NewTaskDrawer from '../../../../TaskModule/AddTask/NewTaskDrawer';
import CognisaasDragableDialog from 'components/shared/CognisaasDragableDialog/CognisaasDragableDialog';
import DeleteDialog from 'components/TaskModule/TaskView/Dialog/DeleteDialog';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Cookies from 'universal-cookie';
import './UsecasePlantable.css';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import {
  cloneProjectPhase,
  cloneUsecasePhase,
  deleteProjectSection,
  deleteUsecaseSection,
  incrementCaseTaskSequence,
  upadateUsecaseTaskTimeLog,
  updatePhase,
  updateTask,
} from 'store/modules/Task/slice';
import CognisaasStageSelect from 'components/shared/CognisaasStageSelect/CognisaasStageSelect';
import { getProjectInformation, getProjectInformationWithCaseTaskByUid } from 'store/modules/Project/slice';
import ViewDate from 'components/shared/ViewDate/ViewDate';
import {
  label_delivery_date,
  label_due_date,
  label_task,
} from 'components/Settings/Preferences/CustomLabelCookieHelper';
import CheckIcon from '@mui/icons-material/Check';
import CognisaasToast from 'components/shared/CognisaasToast/CognisaasToast';
import { selectCustomerDetails } from 'store/modules/Settings/selector';
import CognisaaasTooltip from 'components/shared/CognisaasTooltip/CognisaasTooltip';
// import PunchClockIcon from '@mui/icons-material/PunchClock';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteModal from 'components/shared/CognisaasModal/DeleteModal';
import BasicModal from 'components/shared/CognisaasModal/CognisaasModal';
import CognisaasButton from 'components/shared/CognisaasButton/CognisaasButton';
import { selectIncrementCaseTaskSequenceStatus } from 'store/modules/Task/selector';
import { selectProjectInformationStatus } from 'store/modules/Project/selector';
import { requestState } from 'store/modules/types';
import OverlaySpinner from 'components/shared/CognisaasOverlaySpinner/CognisaasOverlaySpinner';
import { handleInlineEditClickType, inlineEditPropType } from 'components/shared/Tables/types';
import CognisaasDropdown from 'components/shared/CognisaasDropdown/CognisaasDropdown';
import { usePreselectedProjectMembers } from 'components/shared/Hooks/usePreselectedProjectMembers';
import { selectUsersOfCompany } from 'store/modules/Common/selector';
import { getAllUsers } from 'store/modules/Common/slice';
import { SelectEditDateInputCell } from 'components/shared/Tables/InlineEditTable';
import NAMED_CONSTATNTS from 'components/shared/CustomNames/Constants';
import useCustomNames from 'components/shared/CustomNames/useCustomNames';
import { getUserActiveState, INACTIVE_USER_COLOR } from 'utils/userHelpers';
import { getMaxColumnWidth } from 'utils/getMaxColumnWidth';
import { format } from 'date-fns';
const cookies = new Cookies();

interface PropType {
  projectInformation: any;
  criticalPath: any;
}

function stringToHslColor(str: string, s: number, l: number) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const h = hash % 360;
  return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
}

function stringAvatar(name: string) {
  if (typeof name === 'undefined') return <></>;
  return {
    sx: {
      bgcolor: stringToHslColor(name, 30, 80),
    },
    children: (
      <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#000000' }}>
        {name.split(' ')[0][0]}
        {name.indexOf(' ') >= 0 ? name.split(' ')[1][0] : null}
      </Typography>
    ),
  };
}

const UsecasePlanTable = ({ projectInformation }: PropType) => {
  const [updateTaskDrawer, setUpdateTaskDrawer] = useState<boolean>(false);
  const [openTaskWithDetail, setOpenTaskWithDetail] = useState<any>(null);
  const [addTaskDrawer, setAddTaskDrawer] = useState<boolean>(false);
  const customerDetails = useAppSelector(selectCustomerDetails);
  const [phase, setPhase] = useState<string>('');
  const { projectId, associationId, caseId } = useParams();
  const [allSelectedTasksForUpdate, setAllSelectedTasksForUpdate] = useState<any[]>([]);
  const [alertDetails, setAlertDetails] = useState<{
    isVisible: boolean;
    type: 'success' | 'failure';
    message: string;
  }>({ isVisible: false, type: 'success', message: '' });
  const dispatch = useAppDispatch();

  const url: any = window.location.pathname;
  const url_params = url.split('/');
  /* for task selected */
  const [taskSelected, setTaskSelected] = useState<GridSelectionModel>([]);
  const [taskSelectedDetails, setTaskSelectedDetails] = useState<any>(null);
  const [taskSelectedForDeleteDetails, setTaskSelectedForDeleteDetails] = useState<any>(null);
  const [sections, setSections] = useState<any>([]);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenuItems = Boolean(anchorEl);
  const [phaseName, setPhaseName] = useState<string>('');
  const [openRenameModal, setOpenRenameModal] = useState<boolean>(false);
  const { preselectedClientContacts } = usePreselectedProjectMembers(projectId != undefined ? projectId : '',projectInformation?.client_uid);
  const preselectedClientContactsWithNotAssigned = preselectedClientContacts?.length;
  const [usersOfCompany, setUsersOfCompany] = useState<any[]>([]);
  const users = useAppSelector(selectUsersOfCompany);
  const projectInformationStatus = useAppSelector(selectProjectInformationStatus);
  const incrementCaseTaskSequenceStatus = useAppSelector(selectIncrementCaseTaskSequenceStatus);
  const caseLoading: boolean =
    incrementCaseTaskSequenceStatus === requestState.loading || projectInformationStatus == requestState.loading;
  const { getCustomNameMappedValue, getStandardRowDataWithoutCustomNames } = useCustomNames();

  useEffect(() => {
    const case_uid = url_params[5];
    const currentCaseDetails: any = projectInformation?.usecase.filter((item: any) => {
      return item.case_uid === case_uid;
    })[0];

    /*filtering the task with the selected uid*/

    const allTasksDetails: any = [];
    const allTaskDeleteArray: any = [];
    const customer_subdomain = cookies.get('customer_subdomain');
    const cogni_customer_uid = cookies.get('cogni_customer_uid') as string;
    const customer_company = cookies.get('customerCompanyName');
    const allTaskForUpdateArr: any[] = [];

    taskSelected.forEach((task) => {
      const filteredTask = currentCaseDetails?.all_task.filter((t: any) => {
        return t.task_uid === task;
      });
      allTaskForUpdateArr.push(filteredTask[0]);

      /*adding all the task in the delete array*/
      const taskDeleteArray = {
        task_uid: filteredTask[0].task_uid,
        task_name: filteredTask[0].task_name,
        client_uid: projectInformation?.client_uid,
        project_uid: filteredTask[0].project_uid,
        case_uid: filteredTask[0].case_uid,
        milestone_task: filteredTask[0].milestone_task,
        customer_uid: cogni_customer_uid,
        customer_company: customer_company,
        deleted_by_user_name: getLoggedInUserName(),
        base_com_url: window.location.host + '/customer-portal/' + customer_subdomain + '/',
        task_stage: filteredTask[0].task_stage,
      };
      allTaskDeleteArray.push(taskDeleteArray);

      /*adding only the task to the array whose csat mail has to send*/

      if (filteredTask[0].csat_indicator == 1) {
        /* adding the task selected to task selected details array */
        const taskArray = {
          task_uid: filteredTask[0].task_uid,
          task_name: filteredTask[0].task_name,
          client_uid: projectInformation?.client_uid,
          project_uid: filteredTask[0].project_uid,
          case_uid: filteredTask[0].case_uid,
          milestone_task: filteredTask[0].milestone_task,
          customer_uid: cogni_customer_uid,
          customer_company: customer_company,
          base_com_url: window.location.host + '/customer-portal/' + customer_subdomain + '/',
          task_stage: filteredTask[0].task_stage,
        };

        allTasksDetails.push(taskArray);
      }
    });
    setAllSelectedTasksForUpdate(allTaskForUpdateArr);
    setTaskSelectedDetails(allTasksDetails);
    setTaskSelectedForDeleteDetails(allTaskDeleteArray);
  }, [taskSelected]);

  const details = {
    association_uid: associationId,
    project_uid: projectId,
  };
  useEffect(() => {
    dispatch(getProjectInformationWithCaseTaskByUid(details));
    dispatch(getProjectInformation(details));
    dispatch(getAllUsers());
  }, []);

  useEffect(() => {
    if (users.length > 0) {
      const data = users.filter((item: any) => item.is_active === '1').map((user: any) => user.user_username);
      data.unshift('Not Assigned');
      setUsersOfCompany([...data]);
    }
  }, [users]);

  // for update task draggable dialog
  const [openUpdateTask, setOpenUpdateTask] = useState<boolean>(false);

  // for delete task draggable dialog
  const [openDeleteTask, setOpenDeleteTask] = useState<boolean>(false);

  // const handleInlineEditValue = async (
  //   params: GridRenderEditCellParams,
  //   editableField: string,
  //   editableValue: string
  // ) => {
  //   const taskObj = {
  //     blocked_by_dependency: params.row.blocked,
  //     customer_company: companyName,
  //     client_uid: clientUid,
  //     case_uid: '',
  //     client_check: props?.selectedTask?.client_check,
  //     client_initiate: props?.selectedTask?.client_initiate,
  //     contact_uid: props?.selectedTask?.is_team_task === '1' ? null : taskOwnerUid,
  //     csat_indicator: '0',
  //     customer_uid: customerUid,
  //     dependency_details: [],
  //     effort_estimate: '',
  //     jira_ind: 0,
  //     jira_issue_type: '',
  //     jira_priority: '',
  //     jira_project_id: '',
  //     jira_issue_id: '',
  //     jira_issue_key: '',
  //     jira_status_name: '',
  //     milestone_task: mileStone == true ? 1 : 0,
  //     project_uid: props?.selectedTask?.project_uid,
  //     section_uid: selectedSectionUid,
  //     task_implementation_fee_percentage: 0,
  //     starting_time: '',
  //     task_closed_date: format(etaForDelivery, 'yyyy-MM-dd HH:mm:ss'),
  //     task_description: taskDescription,
  //     task_name: taskName,
  //     task_due_date: format(dueDate, 'yyyy-MM-dd HH:mm:ss'),
  //     task_start_date: actualStartDate,
  //     task_owner: taskOwnerUid,
  //     task_percentage: 0,
  //     task_planned_start_date: format(plannedStartDate, 'yyyy-MM-dd HH:mm:ss'),
  //     task_created_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
  //     task_modified_on: '',
  //     task_priority: priority,
  //     task_reporter: taskReporterUid,
  //     task_reporter_uid: clientUid,
  //     task_sequence: 0,
  //     task_stage: stage,
  //     task_uid: props?.selectedTask?.task_uid,
  //     time_remaining: '',
  //     total_time_spent: '',
  //     is_team_task: props?.selectedTask?.is_team_task,
  //     last_user_modified: '',
  //     old_status: props?.selectedTask?.task_status,
  //     old_stage: props?.selectedTask?.task_stage,
  //     template_task_uid: '',
  //   };
  //   if(editableField === "contact_uid") {
  //     await dispatch(
  //       updateTask({
  //         ...params.row,
  //         [editableField]: editableValue,
  //         contact_uid: params.row.is_team_task === '0' ? editableValue : params.row.contact_uid,
  //         phase_uid: params.row.section_uid,
  //       })
  //     );
  //   }
  //   await dispatch(
  //     updateTask({
  //       ...params.row,
  //       [editableField]: editableValue,
  //       contact_uid: params.row.is_team_task === '0' ? editableValue : params.row.contact_uid,
  //       phase_uid: params.row.section_uid,
  //     })
  //   );
  //   dispatch(getProjectInformation(details));
  // };
  // const isTaskInCriticalPath = (task_uid: string) => {
  //   for (let i = 0; i < criticalPath.length; i++) {
  //     if (task_uid == criticalPath[i].task_uid) {
  //       return true;
  //     }
  //   }
  //   return false;
  // };

  const handleInlineEditValue = async (
    params: GridRenderEditCellParams,
    editableField: string,
    editableValue: string
  ) => {
    const newRow = getStandardRowDataWithoutCustomNames({ ...params.row });
    const taskPayload = {
      ...newRow,
      task_closed_date: format(new Date(newRow.task_closed_date), 'yyyy-MM-dd HH:mm:ss'),
      task_due_date: format(new Date(newRow.task_due_date), 'yyyy-MM-dd HH:mm:ss'),
      task_start_date: format(new Date(newRow.task_start_date), 'yyyy-MM-dd HH:mm:ss'),
      task_planned_start_date: format(new Date(newRow.task_planned_start_date), 'yyyy-MM-dd HH:mm:ss'),
    };

    await dispatch(
      updateTask({
        ...taskPayload,
        [editableField]: editableValue,
        phase_uid: newRow.section_uid,
      })
    );
  };

  const SelectEditDropdownCell = (
    params: any,
    dropdownValues: string[],
    handleOnClick: handleInlineEditClickType,
    type?: inlineEditPropType
  ) => {
    const dropdownOptions =
      params.row.is_team_task === '0'
        ? preselectedClientContactsWithNotAssigned.map((item: any) => item.name)
        : dropdownValues;
    const apiRef = useGridApiContext();
    const { id, field, value } = params;
    const dropdownValue =
      params.row.is_team_task == 1 ? getUserNameByUserUid(value) : getContactNameByContactUid(value);
    const handleChange = (value: string) => {
      if (params.row.is_team_task == '0') {
        const this_contact_uid = preselectedClientContactsWithNotAssigned.filter((item: any) => item.name === value)[0]
          .id;
        apiRef.current.setEditCellValue({ id, field, value: this_contact_uid });
      } else {
        apiRef.current.setEditCellValue({ id, field, value: getUserUid(value) });
      }
    };

    const handleClick = async () => {
      await handleOnClick(params, field, value, type);
      apiRef.current.stopCellEditMode({ id, field });
    };

    return (
      <>
        <div style={{ padding: '0 4px', display: 'flex', alignItems: 'center', width: 'inherit' }}>
          <CognisaasDropdown
            validvalues={dropdownOptions}
            onChange={(event: SelectChangeEvent<string | unknown>) => handleChange(event.target.value as string)}
            value={dropdownValue}
            placeholder="Not Selected"
          />
          <button
            style={{
              border: '1px solid #4F9F52',
              background: '#BDF2BF',
              width: '40px',
              borderRadius: '0px 5px 5px 0px',
              height: '40px',
              marginLeft: '-1px',
              cursor: 'pointer',
            }}
            onClick={handleClick}
          >
            <EditSVG />
          </button>
        </div>
      </>
    );
  };

  const SelectEditStringInputCell = (params: any) => {
    const apiRef = useGridApiContext();
    const { id, field } = params;
    const value = params.value;
    const handleChange = (event: any) => {
      apiRef.current.setEditCellValue({ id, field, value: event.target.value as string });
    };
    const handleClick = async () => {
      const detail = {
        effort_estimate: params.row.effort_estimate,
        task_uid: params.row.task_uid,
        time_remaining: params.row.effort_estimate - valueToMinutes(params.value),
        total_time_spent: valueToMinutes(params.value),
        hours_per_day: params.row.hours_per_day,
      };
      await dispatch(upadateUsecaseTaskTimeLog(detail));
      const data = {
        association_uid: projectInformation.association_uid,
        project_uid: projectInformation.project_uid,
      };
      await dispatch(getProjectInformation(data));

      apiRef.current.stopCellEditMode({ id, field });
    };

    return (
      <>
        <div style={{ padding: '0 4px', display: 'flex', alignItems: 'center', width: 'inherit' }}>
          <input
            value={value}
            id="string"
            onChange={(e: any) => handleChange(e)}
            style={{ width: '70%', height: '35px', borderRadius: '5px 0 0 5px', border: '1px solid #E5E5E5' }}
          />
          <button
            style={{
              border: '1px solid #4F9F52',
              background: '#BDF2BF',
              width: '40px',
              borderRadius: '0px 5px 5px 0px',
              height: '40px',
              marginLeft: '-1px',
              cursor: 'pointer',
            }}
            onClick={handleClick}
          >
            <EditSVG />
          </button>
        </div>
      </>
    );
  };

  function SelectEditStageCell(params: any) {
    const apiRef = useGridApiContext();
    const { id, field } = params;
    const handleChange = async (event: SelectChangeEvent<unknown>) => {
      apiRef.current.setEditCellValue({ id, field, value: event.target.value as string });
      const detail = { ...params.row, task_stage: event.target.value, phase_uid: params.row.section_uid };
      await dispatch(updateTask(detail)).unwrap();
      const data = {
        association_uid: projectInformation.association_uid,
        project_uid: projectInformation.project_uid,
      };
      dispatch(getProjectInformation(data));
    };
    return (
      <>
        <>
          <div style={{ padding: '0 4px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CognisaasStageSelect
              changeHandler={(e: any) => {
                handleChange(e);
                e.stopPropagation();
              }}
              width="156px"
              value={params.value}
              isMileStoneTask={params.row.milestone_task !== '0'}
            />
          </div>
        </>
      </>
    );
  }

  const valueToMinutes = (val: any) => {
    const inputLogValue = val.split(' ');
    let valueInMinutes = 0;
    let minute = 0;
    let hour = 0;
    let day = 0;
    let week = 0;
    inputLogValue.forEach((val: any) => {
      const char = val.slice(-1);

      if (char == 'm') {
        minute = parseInt(val.slice(0, -1));
        valueInMinutes += minute;
      }
      if (char == 'h') {
        hour = parseInt(val.slice(0, -1));
        valueInMinutes += hour * 60; //convert hours => 60minutes
      }
      if (char == 'd') {
        day = parseInt(val.slice(0, -1));
        valueInMinutes += day * 8 * 60; //convert days => 8 hours => 60minutes
      }
      if (char == 'w') {
        week = parseInt(val.slice(0, -1));
        valueInMinutes += week * 5 * 8 * 60;
        //convert week to 5days => 8 hours => 60minutes
      }
    });
    return valueInMinutes;
  };

  const convertMinutes = (num: any) => {
    if (num == 0) {
      return '0m';
    } else {
      let hour = Math.floor(num / 60); // 60 minutes in hour
      let day = Math.floor(hour / 8); // 8 hours a day office time
      const week = Math.floor(day / 5); // 5 days a week office time
      const minute = num - 60 * hour; // remaining minutes

      hour = hour - day * 8; // remaining hours
      day = day - week * 5; // remaining days

      let timeLogValue = ''; // Var to store Time Log Value

      if (week > 0) {
        timeLogValue = timeLogValue.concat(`${week}w `);
      }
      if (day > 0) {
        timeLogValue = timeLogValue.concat(`${day}d `);
      }
      if (hour > 0) {
        timeLogValue = timeLogValue = timeLogValue.concat(`${hour}h `);
      }
      if (minute > 0) {
        timeLogValue = timeLogValue.concat(`${minute}m `);
      }

      timeLogValue = timeLogValue.slice(0, -1); // To remove the last blank space
      if (!timeLogValue) {
        return num;
      } else {
        return timeLogValue;
      }
    }
  };

  const params = useParams();
  const columns = [
    {
      field: 'task_uid',
      hide: true,
    },
    {
      field: 'task_implementation_fee_percentage',
      headerName: '',
      width: 5,
      renderCell: (params: GridRenderCellParams) => (
        <CognisaaasTooltip
          title={
            <Box sx={{ height: '18px', padding: '12px' }}>
              <Typography variant="subtitle1">Implementation fee</Typography>
            </Box>
          }
        >
          <Stack sx={{ marginTop: '5px' }}>
            {params.row.task_implementation_fee_percentage &&
              parseInt(params.row.task_implementation_fee_percentage) > 0 && (
                <Stack sx={{ marginLeft: '0px' }} ml={0}>
                  <Typography variant="h6" className="label-text">
                    $
                  </Typography>
                </Stack>
              )}
          </Stack>
        </CognisaaasTooltip>
      ),
    },
    {
      field: 'task_name',
      headerName: `${label_task} Name`,
      width: 160,

      renderCell: (params: GridRenderCellParams) => (
        <Stack direction="row">
          {/* {isTaskInCriticalPath(params.row.task_uid) ? (
            <CognisaaasTooltip
              title={
                <Box sx={{ height: '18px', padding: '8px' }}>
                  <Typography variant="subtitle1">Critical {label_task.toLowerCase()}</Typography>
                </Box>
              }
            >
              <PunchClockIcon sx={{ paddingTop: '2px', color: '#E75B5C', height: '0.8em' }} />
            </CognisaaasTooltip>
          ) : (
            <PunchClockIcon sx={{ paddingTop: '2px', color: '#E75B5C', height: '0.8em', opacity: 0 }} />
          )} */}
          <Typography
            onClick={() => {
              setOpenTaskWithDetail(params.row);
              setUpdateTaskDrawer(!updateTaskDrawer);
            }}
            variant="subtitle1"
            className="cognisaas-pointer"
            sx={{ color: '#093BCD', fontWeight: 400, fontSize: '14px' }}
          >
            {params.row.task_name}
          </Typography>
        </Stack>
      ),
    },
    {
      field: 'task_owner',
      headerName: 'Owner',
      width: getMaxColumnWidth(usersOfCompany),
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams) =>
        SelectEditDropdownCell(params, usersOfCompany, handleInlineEditValue),
      renderCell: (params: GridRenderCellParams) => (
        <Stack direction="row">
          <Stack spacing={1} direction={'row'}>
            {typeof params.row.task_owner !== 'undefined' && (
              <Avatar
                style={{ height: '34px', width: '34px', marginTop: '3px' }}
                {...stringAvatar(
                  params.row.is_team_task == '1'
                    ? getUserNameByUserUid(params.row.task_owner)
                    : getContactNameByContactUid(params.row.task_owner)
                )}
              />
            )}
            {params.row.is_team_task == '1' ? (
              <>
                {getUserActiveState(params.row.task_owner, users) === '0' ? (
                  <Typography
                    style={{ marginTop: '4px', marginLeft: '8px', color: INACTIVE_USER_COLOR }}
                    variant="subtitle1"
                  >
                    {getUserNameByUserUid(params.row.task_owner)}&nbsp;(Inactive)
                  </Typography>
                ) : (
                  <Typography style={{ marginTop: '4px', marginLeft: '8px' }} variant="subtitle1">
                    {getUserNameByUserUid(params.row.task_owner)}
                  </Typography>
                )}
              </>
            ) : (
              <Typography style={{ marginTop: '8px' }} variant="subtitle1">
                {getContactNameByContactUid(params.row.task_owner)}
              </Typography>
            )}
          </Stack>
        </Stack>
      ),
    },

    {
      headerName: 'Status',
      field: 'task_status',
      width: 180,
      renderCell: (params: GridRenderCellParams) => (
        <Stack>
          <Stack
            spacing={1}
            direction="row"
            sx={{ fontWeight: 500, fontSize: '12px', display: 'flex', alignItems: 'center', flexDirection: 'row' }}
          >
            <span
              style={{ marginRight: '4px' }}
              className={
                params.value == 'red'
                  ? 'red_status_box_task'
                  : params.value == 'amber'
                    ? 'amber_status_box_task'
                    : params.value == 'green'
                      ? 'green_status_box_task'
                      : params.value == 'blue'
                        ? 'blue_status_box_task'
                        : params.value == 'grey'
                          ? 'grey_status_box_task'
                          : ''
              }
            ></span>
            <span>
              {params.value == 'red'
                ? 'Overdue'
                : params.value == 'amber'
                  ? 'At Risk'
                  : params.value == 'green'
                    ? 'On Track'
                    : params.value == 'blue'
                      ? 'Completed'
                      : params.value == 'grey'
                        ? 'Deferred'
                        : ''}
            </span>
          </Stack>
        </Stack>
      ),
    },
    {
      field: 'task_stage',
      headerName: 'Stage',
      width: 200,
      editable: true,
      renderEditCell: (params: any) => SelectEditStageCell(params),
      renderCell: (params: GridRenderCellParams) => (
        <Stack>
          <Stack
            spacing={1}
            direction="row"
            sx={{ fontWeight: 500, fontSize: '12px', display: 'flex', alignItems: 'center', flexDirection: 'row' }}
          >
            <span style={{ marginRight: '4px' }}>
              {params.row.task_stage === taskStage.IN_PROGRESS && (
                <CognisaasTaskStageIndicator
                  isMileStoneTask={params.row.milestone_task == 0 ? false : true}
                  stage={taskStage.IN_PROGRESS}
                />
              )}
              {params.row.task_stage === taskStage.NOT_STARTED && (
                <CognisaasTaskStageIndicator
                  isMileStoneTask={params.row.milestone_task == 0 ? false : true}
                  stage={taskStage.NOT_STARTED}
                />
              )}
              {params.row.task_stage === taskStage.BLOCKED && (
                <CognisaasTaskStageIndicator
                  isMileStoneTask={params.row.milestone_task == 0 ? false : true}
                  stage={taskStage.BLOCKED}
                />
              )}
              {params.row.task_stage === taskStage.DONE && (
                <CognisaasTaskStageIndicator
                  isMileStoneTask={params.row.milestone_task == 0 ? false : true}
                  stage={taskStage.DONE}
                />
              )}
              {params.row.task_stage === taskStage.DEFERRED && (
                <CognisaasTaskStageIndicator
                  isMileStoneTask={params.row.milestone_task == 0 ? false : true}
                  stage={taskStage.DEFERRED}
                />
              )}
            </span>
            <span>{getCustomNameMappedValue(params.row.task_stage, NAMED_CONSTATNTS.task_stage_key)}</span>
          </Stack>
        </Stack>
      ),
    },
    {
      field: 'task_planned_start_date',
      headerName: 'Planned Start Date',
      width: 180,
      editable: true,
      type: 'date',
      renderEditCell: (params: GridRenderEditCellParams) => SelectEditDateInputCell(params, handleInlineEditValue),
      renderCell: (params: GridRenderCellParams) => {
        return <ViewDate date={params.row.task_planned_start_date} />;
      },
    },
    {
      field: 'task_start_date',
      headerName: 'Actual Start Date',
      width: 180,
      editable: true,
      type: 'date',
      renderEditCell: (params: GridRenderEditCellParams) => SelectEditDateInputCell(params, handleInlineEditValue),
      renderCell: (params: GridRenderCellParams) => {
        return <ViewDate date={params.row.task_start_date} />;
      },
    },
    {
      field: 'task_due_date',
      headerName: label_due_date,
      width: 180,
      editable: true,
      type: 'date',
      renderEditCell: (params: GridRenderEditCellParams) => SelectEditDateInputCell(params, handleInlineEditValue),
      renderCell: (params: GridRenderCellParams) => {
        return <ViewDate date={params.row.task_due_date} />;
      },
    },
    {
      field: 'task_closed_date',
      headerName: label_delivery_date,
      width: 180,
      type: 'date',
      renderCell: (params: GridRenderCellParams) => {
        return params.row.task_closed_date == '0000-00-00 00:00:00' ? (
          <Typography variant="subtitle1">-</Typography>
        ) : (
          <Typography variant="subtitle1">
            {format(new Date(params.row.task_closed_date.replace(/-/g, '/')), 'dd MMM yyyy')}
          </Typography>
        );
        // return <ViewDate className="cognisaas-pointer" onClick={() => openPanel(params.row)}  date={params.row.task_closed_date} />;
      },
    },
    {
      field: 'effort_estimate',
      headerName: 'Planned Duration',
      width: 180,
      renderCell: (params: GridRenderCellParams) => {
        return <Typography variant="subtitle1">{convertMinutes(params.value)}</Typography>;
      },
    },
    {
      field: 'total_time_spent',
      headerName: 'Actual Duration',
      width: 180,
      editable: true,
      renderEditCell: (params: any) =>
        SelectEditStringInputCell({ ...params, old_time_spent_value: params.row.total_time_spent }),
      renderCell: (params: GridRenderCellParams) => {
        return <Typography variant="subtitle1">{convertMinutes(valueToMinutes(params.value))}</Typography>;
      },
      // valueParser: setValueForTimeSpent,
    },
    // {
    //   field: 'delayed_by',
    //   headerName: 'Delay',
    //   width: 180,
    //   renderCell: (params: any) => {
    //     return <Typography variant="subtitle1">{params.row.delayed_by}</Typography>;
    //   },
    // },
  ];
  const columnsAcknowledged = [
    {
      field: 'task_uid',
      hide: true,
    },
    {
      field: 'task_name',
      headerName: `${label_task} Name`,
      width: 300,
      renderCell: (params: GridRenderCellParams) => (
        <Typography
          onClick={() => {
            setOpenTaskWithDetail(params.row);
            setUpdateTaskDrawer(!updateTaskDrawer);
          }}
          variant="subtitle1"
          className="cognisaas-pointer"
          sx={{ color: '#093BCD', fontWeight: 400, fontSize: '14px' }}
        >
          {params.row.task_name}
        </Typography>
      ),
    },
    {
      field: 'task_owner',
      headerName: 'Owner',
      width: 200,
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams) =>
        SelectEditDropdownCell(params, usersOfCompany, handleInlineEditValue),
      renderCell: (params: GridRenderCellParams) => (
        <Stack direction="row">
          <Stack spacing={1} direction={'row'}>
            {typeof params.row.task_owner !== 'undefined' && (
              <Avatar
                style={{ height: '34px', width: '34px', marginTop: '3px' }}
                {...stringAvatar(
                  params.row.is_team_task == '1'
                    ? getUserNameByUserUid(params.row.task_owner)
                    : getContactNameByContactUid(params.row.task_owner)
                )}
              />
            )}
            <Typography style={{ marginTop: '8px' }} variant="subtitle1">
              {params.row.is_team_task == '1'
                ? getUserNameByUserUid(params.row.task_owner)
                : getContactNameByContactUid(params.row.task_owner)}
            </Typography>
          </Stack>
        </Stack>
      ),
    },

    {
      headerName: 'Status',
      field: 'task_status',
      width: 180,
      renderCell: (params: GridRenderCellParams) => (
        <Stack>
          <Stack
            spacing={1}
            direction="row"
            sx={{ fontWeight: 500, fontSize: '12px', display: 'flex', alignItems: 'center', flexDirection: 'row' }}
          >
            <span
              style={{ marginRight: '4px' }}
              className={
                params.value == 'red'
                  ? 'red_status_box_task'
                  : params.value == 'amber'
                    ? 'amber_status_box_task'
                    : params.value == 'green'
                      ? 'green_status_box_task'
                      : params.value == 'blue'
                        ? 'blue_status_box_task'
                        : params.value == 'grey'
                          ? 'grey_status_box_task'
                          : ''
              }
            ></span>
            <span>
              {params.value == 'red'
                ? 'Overdue'
                : params.value == 'amber'
                  ? 'At Risk'
                  : params.value == 'green'
                    ? 'On Track'
                    : params.value == 'blue'
                      ? 'Completed'
                      : params.value == 'grey'
                        ? 'Deferred'
                        : ''}
            </span>
          </Stack>
        </Stack>
      ),
    },
    {
      field: 'task_stage',
      headerName: 'Stage',
      width: 200,
      editable: true,
      renderEditCell: (params: any) => SelectEditStageCell(params),
      renderCell: (params: GridRenderCellParams) => (
        <Stack>
          <Stack
            spacing={1}
            direction="row"
            sx={{ fontWeight: 500, fontSize: '12px', display: 'flex', alignItems: 'center', flexDirection: 'row' }}
          >
            <span style={{ marginRight: '4px' }}>
              {params.row.task_stage === taskStage.IN_PROGRESS && (
                <CognisaasTaskStageIndicator
                  isMileStoneTask={params.row.milestone_task == 0 ? false : true}
                  stage={taskStage.IN_PROGRESS}
                />
              )}
              {params.row.task_stage === taskStage.NOT_STARTED && (
                <CognisaasTaskStageIndicator
                  isMileStoneTask={params.row.milestone_task == 0 ? false : true}
                  stage={taskStage.NOT_STARTED}
                />
              )}
              {params.row.task_stage === taskStage.BLOCKED && (
                <CognisaasTaskStageIndicator
                  isMileStoneTask={params.row.milestone_task == 0 ? false : true}
                  stage={taskStage.BLOCKED}
                />
              )}
              {params.row.task_stage === taskStage.DONE && (
                <CognisaasTaskStageIndicator
                  isMileStoneTask={params.row.milestone_task == 0 ? false : true}
                  stage={taskStage.DONE}
                />
              )}
              {params.row.task_stage === taskStage.DEFERRED && (
                <CognisaasTaskStageIndicator
                  isMileStoneTask={params.row.milestone_task == 0 ? false : true}
                  stage={taskStage.DEFERRED}
                />
              )}
            </span>
            <span>{params.row.task_stage}</span>
          </Stack>
        </Stack>
      ),
    },
    {
      field: 'task_planned_start_date',
      headerName: 'Planned Start Date',
      width: 180,
      editable: true,
      type: 'date',
      renderEditCell: (params: GridRenderEditCellParams) => SelectEditDateInputCell(params, handleInlineEditValue),
      renderCell: (params: GridRenderCellParams) => {
        return <ViewDate date={params.row.task_planned_start_date} />;
      },
    },
    {
      field: 'task_start_date',
      headerName: 'Actual Start Date',
      width: 180,
      editable: true,
      type: 'date',
      renderEditCell: (params: GridRenderEditCellParams) => SelectEditDateInputCell(params, handleInlineEditValue),
      renderCell: (params: GridRenderCellParams) => {
        return <ViewDate date={params.row.task_start_date} />;
      },
    },
    {
      field: 'task_due_date',
      headerName: label_due_date,
      width: 180,
      editable: true,
      type: 'date',
      renderEditCell: (params: GridRenderEditCellParams) => SelectEditDateInputCell(params, handleInlineEditValue),
      renderCell: (params: GridRenderCellParams) => {
        return <ViewDate date={params.row.task_due_date} />;
      },
    },
    {
      field: 'task_closed_date',
      headerName: label_delivery_date,
      width: 180,
      type: 'date',
      renderCell: (params: GridRenderCellParams) => {
        return params.row.task_closed_date == '0000-00-00 00:00:00' ? (
          <Typography variant="subtitle1">-</Typography>
        ) : (
          <Typography variant="subtitle1">
            {format(new Date(params.row.task_closed_date.replace(/-/g, '/')), 'dd MMM yyyy')}
          </Typography>
        );
      },
    },
    {
      field: 'effort_estimate',
      headerName: 'Planned Duration',
      width: 180,
      renderCell: (params: GridRenderCellParams) => {
        return <Typography variant="subtitle1">{convertMinutes(params.value)}</Typography>;
      },
    },
    {
      field: 'total_time_spent',
      headerName: 'Actual Duration',
      width: 180,
      editable: true,
      renderEditCell: (params: any) =>
        SelectEditStringInputCell({ ...params, old_time_spent_value: params.row.total_time_spent }),
      renderCell: (params: GridRenderCellParams) => {
        return <Typography variant="subtitle1">{convertMinutes(valueToMinutes(params.value))}</Typography>;
      },
      // valueParser: setValueForTimeSpent,
    },
    {
      field: 'task_acknowledged',
      headerName: 'Acknowledgement Status',
      width: 210,
      alignItems: 'center',
      renderCell: (params: GridRenderCellParams) => {
        return params.row.task_stage == 'Done' && params.row.client_check == '1' ? (
          params.row.task_acknowledged === '1' ? (
            <span style={{ cursor: 'not-allowed' }}>
              <Button
                variant="outlined"
                sx={{ height: '25px', mt: '5px', width: '160px', mr: '5px' }}
                endIcon={<CheckIcon />}
                disableFocusRipple
              >
                Acknowledged
              </Button>
            </span>
          ) : (
            <span style={{ cursor: 'not-allowed' }}>
              <Button
                variant="outlined"
                color="warning"
                sx={{ height: '25px', mt: '5px', width: '160px', mr: '5px' }}
                disableFocusRipple
              >
                Not Acknowledged
              </Button>
            </span>
          )
        ) : (
          <Typography variant="subtitle1">N/A</Typography>
        );
      },
    },
  ];
  const apiRef = useGridApiRef();
  useEffect(() => {
    const uc = projectInformation.usecase.filter((uc: any) => uc.case_uid === params.caseId);
    let sectionsData = uc[0].section;
    // Adding __reorder__ field in each task of each sections. When draging task row it will show task_name instead of default ID.
    sectionsData = sectionsData.map((section: any) => ({
      ...section,
      task: section.task.map((task: any) => ({
        ...task,
        __reorder__: task.task_name,
        total_time_spent: convertMinutes(task.total_time_spent),
      })),
    }));
    setSections(sectionsData);
  }, [projectInformation]);

  const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .ant-empty-img-1': {
      fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
      fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
      fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
      fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
      fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
      fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <NoRecord />
        <Box sx={{ mt: 1 }}>No Rows</Box>
      </StyledGridOverlay>
    );
  }

  const handleRowOrderChange = (params: GridRowOrderChangeParams) => {
    const targetIndex = params.targetIndex;
    const oldIndex = params.oldIndex;
    if (targetIndex === oldIndex) return;
    const section = sections.filter((section: any) => section.section_uid === params.row.section_uid);
    const task = section[0].task.filter((task: any, index: number) => index === targetIndex);
    if (task.length > 0) {
      const task_sequence = task[0].task_sequence;
      const case_uid = params.row.case_uid;
      const section_uid = params.row.section_uid;
      const task_uid = params.row.task_uid;
      const project_uid = params.row.project_uid;

      dispatch(
        incrementCaseTaskSequence({
          project_uid,
          section_uid,
          task_sequence: targetIndex < oldIndex ? task_sequence : task_sequence + 1,
          task_uid,
          case_uid,
        })
      );
    }
  };

  const handleAddTaskButton = async (event: React.MouseEvent<HTMLButtonElement>, section: any) => {
    setAddTaskDrawer(true);
    setPhase(section.section_uid);
    event.stopPropagation();
  };
  // * Phase option handling
  const handleDelete = async () => {
    const details = {
      section_uid: phase,
      // base_url: `${getCustomerUid()}`
      customer_uid: getCustomerUid(),
      customer_company: getCustomerCompanyName(),

      deleted_by_user_name: getLoggedInUserName(),
      base_com_url: window.location.host + '/customer-portal/' + getCustomerSubDomain() + '/',

      // project_uid: projectDetails.project_uid,
    };
    if (projectInformation.project_uid && !params.caseId) {
      dispatch(deleteProjectSection(details));
    } else {
      dispatch(deleteUsecaseSection(details));
    }

    const getUpdatedInfo = {
      association_uid: projectInformation.association_uid,
      project_uid: projectInformation.project_uid,
    };
    handleClose();
    await dispatch(getProjectInformation(getUpdatedInfo));
    setAlertDetails({ isVisible: true, type: 'success', message: 'Phase deleted successfully' });
  };
  const handleClickOpenMenu = (event: React.MouseEvent<HTMLButtonElement>, section: any) => {
    setPhase(section.section_uid);
    setPhaseName(section.section_detail);
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };
  const handleClose = () => {
    setPhase('');
    setPhaseName('');
    setAnchorEl(null);
  };
  const handlePhaseRename = async () => {
    if (!params.caseId) {
      dispatch(updatePhase({ section_uid: phase, section_name: phaseName, type: 'Project' }));
    } else {
      dispatch(updatePhase({ section_uid: phase, section_name: phaseName, type: 'Case' }));
    }
    const getUpdatedInfo = {
      association_uid: projectInformation.association_uid,
      project_uid: projectInformation.project_uid,
    };
    handleClose();
    setOpenRenameModal(false);
    await dispatch(getProjectInformation(getUpdatedInfo));
    setAlertDetails({ isVisible: true, type: 'success', message: 'Phase renamed successfully' });
  };
  const handlePhaseCloning = async () => {
    if (!params.caseId) {
      await dispatch(cloneProjectPhase({ section_uid: phase }));
    } else {
      await dispatch(cloneUsecasePhase({ section_uid: phase }));
    }
    const getUpdatedInfo = {
      association_uid: projectInformation.association_uid,
      project_uid: projectInformation.project_uid,
    };
    handleClose();
    await dispatch(getProjectInformation(getUpdatedInfo));
    setAlertDetails({ isVisible: true, type: 'success', message: 'Phase cloned successfully' });
  };
  return (
    <>
      {caseLoading && <OverlaySpinner />}
      <BasicModal
        height={274}
        width={570}
        open={openRenameModal}
        onClose={() => {
          setOpenRenameModal(false);
          handleClose();
        }}
      >
        <Stack>
          <Stack my={2}>
            <Typography variant="h2">Rename Phase</Typography>
          </Stack>
          {console.log(phaseName)}
          <TextField
            autoComplete=""
            autoFocus={true}
            value={phaseName}
            onChange={(e) => setPhaseName(e.target.value)}
            style={{ width: '410px', marginBottom: '15px' }}
            size="small"
            placeholder="Enter Template name"
          />
          <Stack mt={3} spacing={2} justifyContent="flex-end" direction="row">
            <CognisaasButton
              isOutlined={true}
              clickHandler={() => {
                setOpenRenameModal(false);
                handleClose();
              }}
              label="Cancel"
            />
            <CognisaasButton
              clickHandler={() => handlePhaseRename()}
              isOutlined={false}
              label="Save"
              disabled={phaseName.length === 0}
            />
          </Stack>
        </Stack>
      </BasicModal>
      <Box sx={{ padding: 0 }} className="sticky-div">
        <Collapse in={taskSelected.length > 0}>
          <Paper>
            <Alert
              sx={{ borderRadius: 0, border: '0 solid' }}
              variant="outlined"
              severity="info"
              icon={false}
              action={
                <IconButton aria-label="close" color="inherit" size="small">
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {taskSelected.length} row selected &nbsp;&nbsp;
              {/* <Button onClick={() => setOpen(true)}>click me</Button> */}
              <Link component="button" underline="none" variant="body2" onClick={() => setOpenUpdateTask(true)}>
                Update {label_task}s
              </Link>
              &nbsp;&nbsp;&nbsp;
              <Link
                component="button"
                underline="none"
                variant="body2"
                sx={{ color: 'red' }}
                onClick={() => setOpenDeleteTask(true)}
              >
                Delete {label_task}s
              </Link>
            </Alert>
          </Paper>
        </Collapse>
      </Box>
      <CognisaasDragableDialog
        open={openUpdateTask}
        setOpen={setOpenUpdateTask}
        taskSelected={taskSelected}
        taskSelectedDetails={taskSelectedDetails}
        client_uid={projectInformation.client_uid}
        setTaskSelected={setTaskSelected}
        // onClose={() => setTaskSelected([])}
        association_uid={projectInformation.association_uid}
        project_uid={projectInformation.project_uid}
        allSelectedTasksFullDetails={allSelectedTasksForUpdate}
        setAlertDetails={setAlertDetails}
      />
      {/* for bulk deleting the task */}
      <DeleteDialog
        open={openDeleteTask}
        setOpen={setOpenDeleteTask}
        taskSelected={taskSelected}
        taskSelectedForDeleteDetails={taskSelectedForDeleteDetails}
        association_uid={projectInformation.association_uid}
        project_uid={projectInformation.project_uid}
      />
      <DeleteModal
        isOpen={openDeleteModal}
        setIsOpen={setOpenDeleteModal}
        message={'Are you sure you want to delete this phase'}
        onDelete={handleDelete}
      />
      <Box
        sx={{ padding: '10px' }}
        style={{
          background: '#fff',
          boxShadow: '0px 4px 26px rgba(0, 0, 0, 0.05)',
          borderRadius: '4px',
          minHeight: '75vh',
        }}
      >
        {sections.map((section: any) => (
          <Accordion
            style={{ marginTop: '5px', boxShadow: 'none', border: '1px solid #E5E5E5' }}
            key={section.section_uid}
            defaultExpanded={true}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id={section.section_uid}>
              <Stack
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <Typography variant="h1" style={{ fontWeight: 500 }}>
                  {section.section_detail}
                </Typography>
                <Stack direction="row" justifyContent="space-between">
                  <Button
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => handleAddTaskButton(event, section)}
                    size="small"
                  >
                    Add {label_task}
                  </Button>
                  <IconButton
                    disableRipple
                    id="basic-button"
                    aria-controls={openMenuItems ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMenuItems ? 'true' : undefined}
                    onClick={(event) => {
                      event.preventDefault();
                      handleClickOpenMenu(event, section);
                    }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                </Stack>
              </Stack>
            </AccordionSummary>
            <AccordionDetails style={{ padding: '0' }}>
              <DataGridPremium
                components={{
                  NoRowsOverlay: CustomNoRowsOverlay,
                }}
                // columns={[...columns]}
                columns={customerDetails?.data?.task_acknowledgement === '1' ? [...columnsAcknowledged] : [...columns]}
                rows={section.task}
                sx={{
                  backgroundColor: '#fff',
                  borderRadius: '4px 4px 0px 0px',
                  boxShadow: '0px 4px 26px rgba(0, 0, 0, 0.05)',
                  '& .MuiDataGrid-columnHeaders': {
                    backgroundColor: '#E1E1E6',
                    color: '#000000',
                    fontSize: 14,
                    fontWeight: 'bold',
                  },
                }}
                rowHeight={60}
                autoHeight
                apiRef={apiRef}
                getRowId={(row) => row.task_uid}
                disableColumnSelector
                disableSelectionOnClick
                checkboxSelection={true}
                hideFooterRowCount
                rowReordering
                editMode="cell"
                experimentalFeatures={{ newEditingApi: true }}
                onSelectionModelChange={(newSelectionModel) => {
                  setTaskSelected(newSelectionModel);
                  // console.log('newSelectionModel = ', newSelectionModel);
                  // console.log('task selected = ', taskSelected);
                }}
                selectionModel={taskSelected}
                onRowOrderChange={handleRowOrderChange}
              />
            </AccordionDetails>
          </Accordion>
        ))}
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={openMenuItems}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem
            onClick={() => {
              setOpenRenameModal(true);
            }}
          >
            <Typography variant="inherit">Rename</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handlePhaseCloning();
            }}
          >
            <Typography variant="inherit">Clone Phase</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              setOpenDeleteModal(true);
            }}
          >
            <Typography variant="inherit">Delete Phase</Typography>
          </MenuItem>
        </Menu>
      </Box>
      {
        <NewTaskDrawer
          // key={Date.now()}
          client={projectInformation.client_uid}
          associationUid={associationId}
          phaseUid={phase}
          isProjectTask={false}
          projectUid={projectId}
          caseUid={caseId}
          drawerState={addTaskDrawer}
          onClose={() => setAddTaskDrawer(!addTaskDrawer)}
          caseOrProjectName={projectInformation.project_name}
        />
      }
      {updateTaskDrawer && (
        <UpdateTaskDrawer
          client={{ client_uid: projectInformation?.client_uid }}
          // client_uid={projectInformation?.client_uid}
          taskDetail={openTaskWithDetail}
          drawerState={updateTaskDrawer}
          onClose={() => setUpdateTaskDrawer(!updateTaskDrawer)}
        />
      )}
      <CognisaasToast
        open={alertDetails.isVisible}
        onClose={() => setAlertDetails({ isVisible: false, type: alertDetails.type, message: '' })}
        message={alertDetails.message}
        type={alertDetails.type}
      />
    </>
  );
};

export default UsecasePlanTable;
