import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../..';

export const selectClosedUsecasesState = (state: RootState) => state.closedUsecasesState;
export const selectClosedUsecases = createSelector(
  selectClosedUsecasesState,
  (closedUsecasesState) => closedUsecasesState.closedUsecases
);
export const selectClosedUsecaseStatus = createSelector(
  selectClosedUsecasesState,
  (closedUsecasesState) => closedUsecasesState.status
);
export const selectClosedUsecaseError = createSelector(
  selectClosedUsecasesState,
  (closedUsecasesState) => closedUsecasesState.error
);
