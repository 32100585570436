import { Box, Paper, Typography } from '@mui/material';
import { Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { selectchangeTaskStatus } from 'store/modules/Task/selector';
import { changeTaskStatus } from 'store/modules/Task/slice';

const TaskProgressChange = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const changedStatus = useAppSelector(selectchangeTaskStatus);

  const details = {
    task_uid: params.taskId,
    task_stage:
      params.taskStatus == 'Progress'
        ? 'In Progress'
        : params.taskStatus == 'Complete'
          ? 'Done'
          : params.taskStatus == 'Blocked'
            ? 'Blocked'
            : null,
  };
  useEffect(() => {
    console.log('changing the task status');
    dispatch(changeTaskStatus(details));
  }, []);

  const showData = () => {
    if (changedStatus == 'loading') {
      return (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            '& > :not(style)': {
              m: 1,
              width: 'auto',
            },
            justifyContent: 'center',
            textAlign: 'center',
            verticalAlign: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <Paper>
            <Typography sx={{ color: 'orange', padding: '20px' }}>Changing the status of the task</Typography>
          </Paper>
        </Box>
      );
    } else if (changedStatus == 'success') {
      return (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            '& > :not(style)': {
              m: 1,
              width: 'auto',
            },
            justifyContent: 'center',
            textAlign: 'center',
            verticalAlign: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <Paper sx={{ color: 'green', padding: '20px' }}>
            <Typography>Task status changed Successfully</Typography>
          </Paper>
        </Box>
      );
    } else {
      return (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            '& > :not(style)': {
              m: 1,
              width: 'auto',
            },
            justifyContent: 'center',
            textAlign: 'center',
            verticalAlign: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundColor: 'orange',
          }}
        >
          <Paper sx={{ padding: '20px' }}>Some error occured. Please try again later.</Paper>
        </Box>
      );
    }
  };

  return <Fragment>{showData()}</Fragment>;
};

export default TaskProgressChange;
