import { useAppDispatch } from 'store/hooks';
import { useAppSelector } from 'store/hooks';
import { selectClientCustomFields } from 'store/modules/features/client/selector';
import { getClientCustomFields } from 'store/modules/features/client/client';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Formik } from 'formik';
import Styles from '../../ManageClient/OnboardNewAccount/OnboardNewAccount.module.scss';
import { Box, Stack, TextField, Typography, Paper, Grid } from '@mui/material';
import CognisaasTextEditor from 'components/shared/Editors/CognisaasTextEditor';
import CognisaasButton from 'components/shared/CognisaasButton/CognisaasButton';
import CognisaasButtonWithLoading from 'components/shared/CognisaasButton/CognisaasButtonWithLoading';
import CognisaasCalenderNew from 'components/shared/CognisaasCalenderNew/CognisaasCalenderNew';
import CognisaasSelect from 'components/shared/CognisaasSelect/CognisaasSelect';

const ClientCustomFields = (props: any) => {
  const dispatch = useAppDispatch();
  const customFields = useAppSelector(selectClientCustomFields);
  const params = useParams();
  const initialValues: any = {};

  const covertValidValuesToArray = (values: any) => {
    console.log(values, typeof values);
    return [];
    // const res = JSON.parse(values).map((val: any) => {
    //   return { name: val, id: val };
    // });
    // return res;
  };

  useEffect(() => {
    if (!customFields.data) {
      dispatch(getClientCustomFields(params.clientId as string));
    }
    if (customFields.data) {
      console.log(customFields.data);
      makeFields(customFields.data);
    }
  }, [customFields]);

  const makeFields = (fields: any[]) => {
    const cFields: any = [];

    for (let i = 0; i < fields.length; i++) {
      console.log(fields[i].config.label);
      initialValues[fields[i].config.label] = fields[i]?.value;
    }
    console.log(initialValues);
  };

  // const updateCustomFields = (values: any) => {};

  return (
    <Paper sx={{ minHeight: '90%' }}>
      {/* <>CUSTOM FIELDS FORM</> */}
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          console.log(values);
        }}
      >
        {(props: any) => {
          return (
            <Stack mx={2} my={3}>
              <form action="">
                <Stack mb={3}>
                  <Typography sx={{ magrin: '12px 0px' }} fontWeight={'500'} variant="h2">
                    You can update the values of custom fields for Client.
                  </Typography>
                </Stack>

                {customFields.data &&
                  customFields.data.map((field: any, index: number) => {
                    console.log(field);
                    switch (field.config.data_type) {
                      case 'String':
                        return (
                          <Grid mb={2} container spacing={2}>
                            <Grid item md={3}>
                              <Typography variant="subtitle1">{field.config.label}</Typography>
                            </Grid>
                            <Grid item md={9}>
                              <CognisaasTextEditor
                                type="text"
                                value={props.values[field.config.label]}
                                changedValue={(v: string) => props.setFieldValue(field.config.label, v)}
                              />
                            </Grid>
                          </Grid>
                        );
                      case 'Number':
                        return (
                          <Grid mb={2} container spacing={2}>
                            <Grid item md={3}>
                              <Typography variant="subtitle1">{field.config.label}</Typography>
                            </Grid>
                            <Grid item md={9}>
                              <CognisaasTextEditor
                                type="number"
                                value={props.values[field.config.label]}
                                changedValue={(v: number) => props.setFieldValue(field.config.label, v)}
                              />
                            </Grid>
                          </Grid>
                        );
                      case 'Date':
                        return (
                          <Grid mb={2} container spacing={2}>
                            <Grid item md={3}>
                              <Typography variant="subtitle1">{field.config.label}</Typography>
                            </Grid>
                            <Grid item md={9}>
                              <CognisaasCalenderNew
                                width="310px"
                                name={field.config.label}
                                value={props.values[field.config.label]}
                                handleChange={(e: any) => {
                                  props.setFieldValue(field.config.label, e);
                                }}
                              />
                            </Grid>
                          </Grid>
                        );
                      case 'Hyperlink':
                        return (
                          <Grid mb={2} container spacing={2}>
                            <Grid item md={3}>
                              <Typography variant="subtitle1">{field.config.label}</Typography>
                            </Grid>
                            <Grid item md={9}>
                              <CognisaasTextEditor
                                type="text"
                                value={props.values[field.config.label]}
                                changedValue={(v: string) => props.setFieldValue(field.config.label, v)}
                              />
                            </Grid>
                          </Grid>
                        );
                      case 'Dropdown':
                        return (
                          <Grid mb={2} container spacing={2}>
                            <Grid item md={3}>
                              <Typography variant="subtitle1">{field.config.label}</Typography>
                            </Grid>
                            <Grid item md={9}>
                              <CognisaasSelect
                                width="310px"
                                value={props.values[field.config.label]}
                                validValues={covertValidValuesToArray(field?.config?.valid_values)}
                                onChange={(e: any, v: any) => {
                                  props.setFieldValue('field.config.label', v.props.value);
                                }}
                              />
                            </Grid>
                          </Grid>
                        );
                    }
                  })}
                <Grid ml={10} spacing={2} container justifyContent="flex-end">
                  <Grid item xs={6} md={6} lg={6}>
                    <span style={{ width: '20px' }}></span>
                    <Stack spacing={2} direction="row">
                      <CognisaasButton
                        clickHandler={() => {
                          // closeDrawer();
                        }}
                        label={'Cancel'}
                        isOutlined={true}
                        disabled={!props.dirty}
                      />
                      <CognisaasButtonWithLoading
                        isLoading={false}
                        label={'Save'}
                        isOutlined={false}
                        clickHandler={() => {
                          props.handleSubmit();
                          // componentProps.handleSave('customFields', props.values, 2);
                        }}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={6} md={6} lg={6}></Grid>
                </Grid>
              </form>
            </Stack>
          );
        }}
      </Formik>
    </Paper>
  );
};

export default ClientCustomFields;
