import { Box, Typography } from '@mui/material';
import { useAppSelector } from '../../../../store/hooks';
import { useDispatch } from 'react-redux';
import { selectAllFilters } from '../../../../store/modules/AllFilters/selector';
import FilterChip from '../../FilterChipv2/FilterChipv2';
import {
  updateFilterSelectedValue,
  resetFilterOfThisContext,
  updateFilter,
} from '../../../../store/modules/AllFilters/slice';
// import { selectMenuOptions } from '../../../../store/modules/ui/CommonData/selector';

interface SelectedFilterListProps {
  idName: string;
  isResetEnabled?: boolean;
}
const SelectedFilterList = (props: SelectedFilterListProps) => {
  const allFilters = useAppSelector(selectAllFilters);
  // const menuOptions = useAppSelector(selectMenuOptions);
  const dispatch = useDispatch();

  const handleResetFilters = () => {
    // reset filters
    dispatch(resetFilterOfThisContext({ idName: props.idName }));
  };

  const handleOptionClickedFromMenu = (value: string, filterName: string, filterDataKey: string) => {
    console.log({ value });
    dispatch(
      updateFilterSelectedValue({
        idName: props.idName,
        selectedValue: value,
        filterName: filterName,
        filterDataKey: filterDataKey,
      })
    );
  };

  // console.log(menuOptions);
  // console.log('all filters', allFilters);

  const deleteThisFilter = (filter: { name: string; availableOptions: string[]; dataKey: string }) => {
    // console.log('deleting ..');

    dispatch(
      updateFilter({
        idName: props.idName,
        updatedFilter: {
          name: filter.name,
          dataKey: filter.dataKey,
          isSelected: false,
          equalToValue: [],
          canBeCleared: true,
          availableOptions: filter.availableOptions,
        },
      })
    );
  };

  return (
    <Box
      sx={{
        display: 'grid',
        alignItems: 'baseline',
        justifyContent: 'center',
        gridGap: '10px',
        gridTemplateColumns: 'auto  1fr auto',
      }}
    >
      <Typography variant="subtitle1">Filters</Typography>
      {/* {console.log('after click on filter', allFilters)} */}
      <Box
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gridGap: '10px', flexWrap: 'wrap' }}
      >
        {allFilters[props.idName]
          ? allFilters[props.idName].appliedFilters.map((filter, index) => {
              if (filter.isSelected) {
                return (
                  <FilterChip
                    key={index}
                    dataKey={filter.dataKey}
                    name={filter.name}
                    checkedItemsForList={filter.equalToValue}
                    handleOptionClicked={handleOptionClickedFromMenu}
                    // options={menuOptions[filter.dataKey] ? menuOptions[filter.dataKey] : ['default']}
                    options={filter.availableOptions}
                    handleDeleteFilter={deleteThisFilter}
                    canBeCleared={filter.canBeCleared}
                  />
                );
              }
            })
          : ''}
      </Box>
      {!!props?.isResetEnabled && (
        <Typography
          variant="subtitle2"
          className="link-text cognisaas-pointer"
          sx={{ display: 'inline-block', marginLeft: '10px' }}
          onClick={handleResetFilters}
        >
          Reset Filters
        </Typography>
      )}
    </Box>
  );
};

export default SelectedFilterList;
