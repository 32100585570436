import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import initialSelectedColumnOptions from './initialSelectedColumnOptions';

interface selectedFilterState {
  selectedFilters: string[];
  selectedColumnOptions: Record<string,unknown>;
}

const initialState: selectedFilterState = {
    selectedFilters: [],
    selectedColumnOptions: initialSelectedColumnOptions
};

export const manageClientUiSlice = createSlice({
    name: 'manage-client',
    initialState,
    reducers: {
        addFilter: (state, action: PayloadAction<string>) => {
            state.selectedFilters = [...state.selectedFilters, action.payload];
        },
        removeFilter: (state, action: PayloadAction<string>) => {
            state.selectedFilters = state.selectedFilters.filter((filter) => filter !== action.payload);
        },
        resetFilters: (state) => {
            state.selectedFilters = [];
        },
    },
});

export const manageClientTableColumnOptionsSlice = createSlice({
    name:'manage-client-column-options',
    initialState:initialSelectedColumnOptions,
    reducers: {}
});


// updating cell data when in editable table mode in manage client

export const { addFilter, removeFilter, resetFilters } = manageClientUiSlice.actions;

const manageClientsUiReducer = manageClientUiSlice.reducer;
const manageClientTableColumnOptionsReducer = manageClientTableColumnOptionsSlice.reducer;
export {manageClientTableColumnOptionsReducer};

export default manageClientsUiReducer;
