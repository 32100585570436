import { Radio, alpha, styled } from '@mui/material';
import { GridRenderCellParams, gridClasses, useGridApiContext } from '@mui/x-data-grid';
import CognisaasButton from 'components/shared/CognisaasButton/CognisaasButton';
import { MenuCell } from 'components/shared/Tables/CogniTable';
import { ReactComponent as EditSVG } from '../../../assets/svgs/TableEditButton.svg';
import Box from '@mui/material/Box';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { selectClientContacts } from 'store/modules/features/client/selector';
import { useAppSelector } from 'store/hooks';

const CLientContactsTable = (props: any) => {
  const clientContacts = useAppSelector(selectClientContacts);
  const handleChange = (params: any) => {
    console.log(params, 'clicked');
    // handleOpen();
    // setselectedContact(params.row);
  };

  const StyledBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: 600,
    width: '100%',
    borderRadius: '4px',
    boxShadow: '0px 4px 26px rgba(0, 0, 0, 0.05)',
    '& .MuiFormGroup-options': {
      alignItems: 'center',
      paddingBottom: theme.spacing(1),
      '& > div': {
        minWidth: 100,
        margin: theme.spacing(2),
        marginLeft: 0,
      },
    },
  }));
  const ODD_OPACITY = 0.2;

  const StripedDataGrid = styled(DataGridPremium)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: theme.palette.grey[200],
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
      '&.Mui-selected': {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
        '&:hover, &.Mui-hovered': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity
          ),
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
          },
        },
      },
    },
  }));

  function SelectEditInputCell(params: GridRenderCellParams) {
    const apiRef = useGridApiContext();
    const { id, value, field } = params;
    const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
      apiRef.current.setEditCellValue({ id, field, value: (event.target as HTMLInputElement).value as string });
    };
    const handleClick = () => {
      console.log('handle click func');
      //   if (!phoneRegExp.test(value)) {
      //     setAlertDetails({
      //       isVisible: true,
      //       type: 'failure',
      //       message: 'Please enter a valid contact number.',
      //     });
      //   } else {
      //     dispatch(
      //       updateClientContact({
      //         ...params.row,
      //         [field]: value,
      //         user_email: userFullDetail.data.user_email,
      //         user_username: userFullDetail.data.user_username,
      //         base_url: baseUrl,
      //         type: 'name_role_updation',
      //       })
      //     );
      //     apiRef.current.stopCellEditMode({ id, field });
      //   }
    };
    return (
      <>
        <div style={{ padding: '0 4px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <input
            type="text"
            value={value}
            onChange={handleChange}
            style={{ border: '1px solid lightgrey', height: '40px', fontSize: '14px', borderRadius: '5px 0 0 5px' }}
          />
          <button
            style={{
              border: '1px solid #4F9F52',
              background: '#BDF2BF',
              width: '40px',
              borderRadius: '0px 5px 5px 0px',
              height: '40px',
              marginLeft: '-1px',
              cursor: 'pointer',
            }}
            onClick={handleClick}
          >
            <EditSVG />
          </button>
        </div>
      </>
    );
  }

  const columns = [
    {
      field: 'primary_contact',
      headerName: 'Primary Contact',
      width: 130,
      editable: true,
      renderCell: (params: any) => {
        return (
          <>
            <Radio checked={params.row.primary_contact == 1} value="1" onChange={() => handleChange(params)} />
          </>
        );
      },
    },
    {
      field: 'name',
      headerName: 'Member',
      width: 240,
      editable: true,
      renderEditCell: (params: any) => SelectEditInputCell(params),
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 210,
    },
    {
      field: 'phone_number',
      headerName: 'Phone',
      width: 230,
      editable: true,
      renderEditCell: (params: any) => SelectEditInputCell(params),
    },
    {
      field: 'role',
      headerName: 'Role',
      width: 230,
      editable: true,
      renderEditCell: (params: any) => SelectEditInputCell(params),
    },

    {
      field: 'client_contact',
      headerName: 'Access link',
      width: 150,
      renderCell: (params: any) => {
        return (
          <>
            {/* {customerDetails?.data?.client_contact_added_mail === '0' ? (
                  <CognisaasButton
                    disabled={true}
                    label={'Send access link'}
                    isOutlined={true}
                    sx={{ color: '#E75B5C' }}
                    clickHandler={() => sendAccessLink(params.row)}
                  />
                ) : ( */}
            <CognisaasButton
              label={'Send access link'}
              isOutlined={true}
              sx={{ color: '#E75B5C' }}
              clickHandler={() => console.log('sendAccessLink(params.row)')}
              isText={true}
            />
            {/* )} */}
          </>
        );
      },
    },
    // {
    //   field: 'last_magiclink_sent_on',
    //   headerName: 'Last access link sent on',
    //   minWidth: 200,
    //   renderCell: (params: any) => (
    //     <Typography variant="subtitle1">
    //       {params.value ? format(new Date(params.value), 'dd MMM yyyy hh:mm aaa') : '-'}
    //     </Typography>
    //   ),
    // },
    // {
    //   field: 'contact_last_accessed_on',
    //   headerName: 'Last accessed portal on',
    //   minWidth: 200,
    //   renderCell: (params: any) => (
    //     <Typography variant="subtitle1">
    //       {params.value ? format(new Date(params.value), 'dd MMM yyyy hh:mm aaa') : '-'}
    //     </Typography>
    //   ),
    // },
    {
      field: '.',
      headerName: '',
      minWidth: 50,
      renderCell: (params: any) => MenuCell(params, ['Delete'], props.performThisActionOnClientContact),
    },
  ];
  const perf = () => {
    console.log('PERFFF');
  };

  const data = {
    columns,
    rows: clientContacts.data,
  };

  return (
    <>
      <StyledBox>
        <StripedDataGrid
          {...data}
          editMode="cell"
          getRowId={(row) => row.id}
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          rowThreshold={0}
          //   {...pagination}
          getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'odd' : 'odd')}
        />
      </StyledBox>
    </>
  );
};

export default CLientContactsTable;
