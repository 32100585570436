import Comment from './Comment';
import CommentView from './CommentView';
import { Stack, Paper } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { requestState } from 'store/modules/types';
import CognisaasSpinner from 'components/shared/CognisaasSpinner/CognisaasSpinner';
import NoDataFound from 'components/shared/NoRecords/NoDataFound';
import { useAppSelector } from 'store/hooks';
import { selectUsecaseLevelComments } from 'store/modules/Usecase/selector';
import { getAllUsecaseComments, resetGetUsecaseComments } from 'store/modules/Usecase/slice';

interface Props {
  project_uid: string;
  case_uid: string;
}

const CommentHome = (props: Props) => {
  const dispatch = useDispatch();
  const usecaseComments = useAppSelector(selectUsecaseLevelComments);
  const [comments, setComments] = useState<any[]>([]);

  useEffect(() => {
    dispatch(resetGetUsecaseComments());
    setComments([]);
    dispatch(getAllUsecaseComments(props.case_uid));

    return () => {
      dispatch(resetGetUsecaseComments());
    };
  }, []);

  useEffect(() => {
    setComments(usecaseComments.data);
  }, [usecaseComments]);

  const loading = usecaseComments.status === requestState.loading;

  return (
    <Stack style={{ marginTop: '8px', borderRadius: '4px', background: 'white', padding: '8px' }}>
      <Stack style={{ padding: '20px 20px 5px 20px' }}>
        <Comment projectUid={props.project_uid} caseUid={props.case_uid} />
      </Stack>
      {loading ? (
        <Stack style={{ width: '100%', height: '100%' }} direction="row" justifyContent="center" alignItems="center">
          <CognisaasSpinner size={50} />
        </Stack>
      ) : (
        <Paper elevation={0} style={{ overflow: 'hidden', marginBottom: '10px', padding: '0px 20px' }}>
          <CommentView projectUid={props.project_uid} caseUid={props.case_uid} commentsData={comments} />
        </Paper>
      )}
      {!loading && usecaseComments.data.length === 0 && usecaseComments.status === requestState.success && (
        <NoDataFound title={'No comments yet!'} />
      )}
    </Stack>
  );
};

export default CommentHome;
