import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../index';

export const selectRAIDState = (state: RootState) => state.raidState;

export const selectCreateRAIDStatus = createSelector(selectRAIDState, (raidState) => raidState.createRaidStatus);
export const selectCreateRAIDError = createSelector(selectRAIDState, (raidState) => raidState.createRaidError);

export const selectGetRAIDByProjectId = createSelector(selectRAIDState, (raidState) => raidState.getRaidByProjectId);
export const selectGetRAIDByProjectIdStatus = createSelector(selectRAIDState, (raidState) => raidState.getRaidByProjectIdStatus);
export const selectGetRAIDByProjectIdError = createSelector(selectRAIDState, (raidState) => raidState.getRaidByProjectIdError);

export const selectUpdateRAIDStatus = createSelector(selectRAIDState, (raidState) => raidState.updateRaidStatus);
export const selectUpdateRAIDError = createSelector(selectRAIDState, (raidState) => raidState.updateRaidError);

export const selectDeleteRAIDStatus = createSelector(selectRAIDState, (raidState) => raidState.deleteRaidStatus);
export const selectDeleteRAIDError = createSelector(selectRAIDState, (raidState) => raidState.deleteRaidError);

export const selectRAIDComments = createSelector(selectRAIDState, (raidState) => raidState.getRaidComments);
export const selectRAIDCommentsStatus = createSelector(selectRAIDState, (raidState) => raidState.getRaidCommentsStatus);
export const selectRAIDCommentsError = createSelector(selectRAIDState, (raidState) => raidState.getRaidComentsError);

export const selectUpdateRAIDCommentStatus = createSelector(selectRAIDState, (raidState) => raidState.updateRaidComment);
export const selectUpdateRAIDCommentError = createSelector(selectRAIDState, (raidState) => raidState.updateRaidCommentError);

export const selectDeleteRAIDCommentStatus = createSelector(selectRAIDState, (raidState) => raidState.deleteRaidComment);
export const selectDeleteRAIDCommentError = createSelector(selectRAIDState, (raidState) => raidState.deleteRaidCommentError);

export const selectAddRAIDCommentStatus = createSelector(selectRAIDState, (raidState) => raidState.addRaidComment);
export const selectAddRAIDCommentError = createSelector(selectRAIDState, (raidState) => raidState.addRaidCommentError);