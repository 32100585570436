// Contains all inline editable components

import { SelectChangeEvent, TextField } from '@mui/material';
import CognisaasDropdown from '../CognisaasDropdown/CognisaasDropdown';
import { ReactComponent as EditSVG } from 'assets/svgs/TableEditButton.svg';
import { useGridApiContext } from '@mui/x-data-grid';
import { isUndefined } from 'utils/dataHelpers';
import CognisaasCalenderNew from '../CognisaasCalenderNew/CognisaasCalenderNew';
import { handleInlineEditClickType, inlineEditPropType } from './types';
import { format } from 'date-fns';
import { getIndustryName, getUserNameByUserUid, getUserUid } from 'utils/getUid';

/* Special case to handle customfield undefined or null values*/
const determineCustomValue = (params: any, val: any) => {
  if (isUndefined(val)) {
    const index = params.indexofCustomField;
    if (params.row.custom_values[index].field_value.length > 0) {
      return params.row.custom_values[index].field_value;
    }
    return null;
  }
  return val;
};

const parseLines = (value: string) => value?.replace(/(\\n)/g, '\n');

export const SelectEditDropdownCell = (
  params: any,
  dropdownValues: string[],
  handleOnClick: handleInlineEditClickType,
  identifier?:string,
  type?: inlineEditPropType,
) => {
  const apiRef = useGridApiContext();
  const { id, field, value } = params;
  const dropdownValue = dropdownValues.find((dropdownValue:any) => dropdownValue.id === value.id);
  const handleChange = (value: string) => {
    apiRef.current.setEditCellValue({ id, field, value: value });
  };
  console.log({dropdownValues});
  const handleClick = async () => {
    await handleOnClick(params, field, value, type);
    apiRef.current.stopCellEditMode({ id, field });
  };
  return (
    <>
      <div style={{ padding: '0 4px', display: 'flex', alignItems: 'center', width: 'inherit' }}>
        <CognisaasDropdown
          validvalues={dropdownValues}
          onChange={(event: SelectChangeEvent<string | unknown>) => handleChange(event.target.value as string)}
          value={dropdownValue}
          placeholder="Not Selected"
          identifier={identifier}
        />
        <button
          style={{
            border: '1px solid #4F9F52',
            background: '#BDF2BF',
            width: '40px',
            borderRadius: '0px 5px 5px 0px',
            height: '40px',
            marginLeft: '-1px',
            cursor: 'pointer',
          }}
          onClick={handleClick}
        >
          <EditSVG />
        </button>
      </div>
    </>
  );
};

export const SelectEditDropdownCellUserList = (
  params: any,
  companyUsers: any[],
  handleOnClick: handleInlineEditClickType,
  type?: inlineEditPropType
) => {
  const apiRef = useGridApiContext();
  if (!isUndefined(companyUsers)) {
    companyUsers = companyUsers?.filter((user: any) => user.is_active === '1').map((user: any) => user.user_username);
  }
  companyUsers.unshift('Not Assigned');

  const { id, field, value } = params;
  const handleChange = (value: string) => {
    apiRef.current.setEditCellValue({ id, field, value: value });
  };

  const handleClick = async () => {
    await handleOnClick(params, field, getUserUid(value), type);
    apiRef.current.stopCellEditMode({ id, field });
  };

  return (
    <>
      <div style={{ padding: '0 4px', display: 'flex', alignItems: 'center', width: 'inherit' }}>
        <CognisaasDropdown
          validvalues={companyUsers}
          onChange={(event: SelectChangeEvent<string | unknown>) => handleChange(event.target.value as string)}
          value={
            value == null
              ? 'None'
              : determineCustomValue(params, getUserNameByUserUid(value)) == ''
              ? determineCustomValue(params, value)
              : determineCustomValue(params, getUserNameByUserUid(value))
          }
          placeholder="Not Selected"
          identifier='user_name'
        />
        <button
          style={{
            border: '1px solid #4F9F52',
            background: '#BDF2BF',
            width: '40px',
            borderRadius: '0px 5px 5px 0px',
            height: '40px',
            marginLeft: '-1px',
            cursor: 'pointer',
          }}
          onClick={handleClick}
        >
          <EditSVG />
        </button>
      </div>
    </>
  );
};

export const SelectEditStringInputCell = (
  params: any,
  handleOnClick: handleInlineEditClickType,
  type?: inlineEditPropType
) => {
  const apiRef = useGridApiContext();
  const { id, value, field } = params;
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    event.preventDefault();
    console.log(event.target.value, 'compare');
    apiRef.current.setEditCellValue({ id, field, value: parseLines(event.target.value) });
  };
  const handleClick = async () => {
    await handleOnClick(params, field, parseLines(value), type);
    console.log('val tar 2', value);
    apiRef.current.setEditCellValue({ id, field, value: parseLines(value) });
    apiRef.current.stopCellEditMode({ id, field });
    apiRef.current.resetRowHeights();
  };

  //without auto spacing when clicked out
  // const handleEvent: GridEventListener<'cellFocusOut'> = () => {
  //   apiRef.current.resetRowHeights();
  // };
  // apiRef.current.subscribeEvent('cellFocusOut', handleEvent);

  return (
    <>
      <div style={{ padding: '5px 4px', display: 'flex', alignItems: 'center', width: '300px', position: 'relative' }}>
        <TextField
          value={parseLines(value)}
          type="text"
          size="small"
          id="text"
          multiline
          onKeyDown={(e) => e.stopPropagation()}
          maxRows={5}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(event)}
          style={{
            width: '70%',
            // height: 'inherit',
            borderRadius: '5px 0 0 5px',
            border: '1px solid #E5E5E5',
          }}
        />
        <button
          style={{
            border: '1px solid #4F9F52',
            background: '#BDF2BF',
            width: '40px',
            borderRadius: '0px 5px 5px 0px',
            height: '40px',
            marginLeft: '-1px',
            cursor: 'pointer',
          }}
          onClick={handleClick}
        >
          <EditSVG />{' '}
        </button>
      </div>
    </>
  );
};

export const SelectEditNumericInputCell = (
  params: any,
  handleOnClick: handleInlineEditClickType,
  type?: inlineEditPropType
) => {
  const apiRef = useGridApiContext();
  const { id, value, field } = params;
  const handleChange = (value: string) => {
    apiRef.current.setEditCellValue({ id, field, value: value });
  };

  const handleClick = async () => {
    await handleOnClick(params, field, value, type);
    apiRef.current.stopCellEditMode({ id, field });
  };

  return (
    <>
      <div style={{ padding: '0 4px', display: 'flex', alignItems: 'center', width: 'inherit' }}>
        <input
          value={determineCustomValue(params, value)}
          type="number"
          id="number"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(event.target.value as string)}
          style={{ width: '70%', height: '35px', borderRadius: '5px 0 0 5px', border: '1px solid #E5E5E5' }}
        />
        <button
          style={{
            border: '1px solid #4F9F52',
            background: '#BDF2BF',
            width: '40px',
            borderRadius: '0px 5px 5px 0px',
            height: '40px',
            marginLeft: '-1px',
            cursor: 'pointer',
          }}
          onClick={handleClick}
        >
          <EditSVG />
        </button>
      </div>
    </>
  );
};

export const SelectEditDateInputCell = (
  params: any,
  handleOnClick: handleInlineEditClickType,
  type?: inlineEditPropType
) => {
  const apiRef = useGridApiContext();
  const { id, value, field } = params;

  const handleChange = (date: string) => {
    apiRef.current.setEditCellValue({ id, field, value: '' + new Date(date) });
  };

  const handleClick = async () => {
    await handleOnClick(params, field, format(new Date(value), 'yyyy-MM-dd'), type);
    apiRef.current.stopCellEditMode({ id, field });
  };

  return (
    <>
      <div style={{ padding: '0 4px', display: 'flex', alignItems: 'center', width: 'inherit' }}>
        <CognisaasCalenderNew
          value={determineCustomValue(params, value)}
          handleChange={(value: string) => {
            handleChange(value);
          }}
        />
        <button
          style={{
            border: '1px solid #4F9F52',
            background: '#BDF2BF',
            width: '40px',
            borderRadius: '0px 5px 5px 0px',
            height: '40px',
            marginLeft: '-1px',
            cursor: 'pointer',
          }}
          onClick={handleClick}
        >
          <EditSVG />
        </button>
      </div>
    </>
  );
};
