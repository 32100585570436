import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { requestState } from '../../types';
import { clientAPI } from '../../../../api/modules/client';
import { commonAPI } from 'api/modules/common';

interface StateProps {
  clientDetails: {
    data: Record<string, any>;
    status: requestState.idle | requestState.loading | requestState.success | requestState.failed;
    error: null | undefined | string;
  };
}

const initialState: StateProps = {
  clientDetails: {
    data: {},
    status: requestState.idle,
    error: null,
  },
};

async function getSegmentValueByUid(segment_uid: string) {
  const { data } = await commonAPI.getSegmentValue();
  for (let i = 0; i < data.result.length; i++) {
    if (data.result[i]['segment_uid'] === segment_uid) {
      // console.log(data.result[i]['segment_name']);
      return { segmentName: data.result[i]['segment_name'] };
    }
  }
  return { segmentName: 'Not Assigned' };
}

async function getJourneyStageValueByUid(stage_uid: string) {
  const { data } = await commonAPI.getJourneyStage();
  // console.log(data.result);
  for (let i = 0; i < data.result.length; i++) {
    if (data.result[i]['stage_uid'] === stage_uid) {
      // console.log('stage', data.result[i]['stage_uid'], data.result[i]['stage_journerystage'], '  ', stage_uid);
      return { journeyStage: data.result[i]['stage_journerystage'] };
    }
  }
  return { journeyStage: 'Not Assigned' };
}

async function getIndustryValueByUid(industry_uid: string) {
  const { data } = await commonAPI.getIndustryValue();
  for (let i = 0; i < data.result.length; i++) {
    if (data.result[i]['industry_uid'] === industry_uid) {
      return { industryName: data.result[i]['industry_name'] };
    }
  }
  return { industryName: 'Not Assigned' };
}

export const getClientDetail = createAsyncThunk('dataView/fetchClientDetail', async (client_uid: string) => {
  const { data: clientData } = await clientAPI.getClientDetail(client_uid);
  const { segmentName } = await getSegmentValueByUid(clientData.result.segment_uid);
  const { journeyStage } = await getJourneyStageValueByUid(clientData.result.stage_uid);
  const { industryName } = await getIndustryValueByUid(clientData.result.industry_uid);

  return {
    ...clientData,
    result: {
      ...clientData.result,
      journey_stage: journeyStage,
      segment_name: segmentName,
      industry_name: industryName,
    },
  };
});

const dataViewSlice = createSlice({
  name: 'data views',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getClientDetail.pending, (state) => {
        state.clientDetails.status = requestState.loading;
      })
      .addCase(getClientDetail.fulfilled, (state, action) => {
        state.clientDetails.status = requestState.success;
        console.log(action.payload.result);
        state.clientDetails.data = action.payload.result;
      })
      .addCase(getClientDetail.rejected, (state, action) => {
        state.clientDetails.status = requestState.failed;
        state.clientDetails.error = action.error.message;
      });
  },
});

const dataViewReducer = dataViewSlice.reducer;
export default dataViewReducer;
