import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

export const excelExport = (jsonData: any[], fileName: string) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData);
    const wb: XLSX.WorkBook = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data: Blob = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + fileExtension);
};
