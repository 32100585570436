import CardContent from '@mui/material/CardContent';
import { Typography, Avatar, Divider, Box, Menu, MenuItem, Stack, IconButton, Grid } from '@mui/material';
import { format } from 'date-fns';
import CognisaasTaskStageIndicator from '../../shared/CognisaasTaskStageIndicator/CognisaasTaskStageIndicator';
import { useEffect, useState } from 'react';
import { ReactComponent as RightArrowIcon } from '../../../assets/svgs/rightArrow.svg';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  getContactNameByContactUid,
  getCustomerUid,
  getLoggedInUserUid,
  getUserNameByUserUid,
} from '../../../utils/getUid';
import { useNavigate } from 'react-router-dom';
import { getUserLogoLinkByUid, getUserUid } from 'utils/getUid';
import { default as AvatarImage } from '../../shared/Avatar/Avatar';
import { useParams } from 'react-router-dom';
import UpdateTaskDrawer from '../../TaskModule/TaskView/UpdateTaskDrawer';
import {
  fetchClientInformation,
  getClientByUid,
  getProjecWithClientUid,
  totalTrailClient,
} from 'store/modules/ManageClient/ClientList/slice';
import { useAppDispatch } from 'store/hooks';
import { getTaskByTaskUid } from 'store/modules/Task/slice';
import {
  label_due_date,
  label_project,
  label_project_delivery_manager,
  label_task,
  label_use_case,
} from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { cloneLiveProject, getAllProjectsOfCustomer, resetSelectedProject } from 'store/modules/Project/slice';
import CognisaaasTooltip from '../../shared/CognisaasTooltip/CognisaasTooltip';
import { resetNewProjectData } from 'store/modules/ManageClient/OnboardFormValues/slice';
import { updateCaseWithfeatureStatus } from 'store/modules/Common/slice';
import TypographyWithTooltip from 'components/shared/UI/TypographyWithTooltip';
import { getUserActiveState, INACTIVE_STRING, INACTIVE_USER_COLOR } from 'utils/userHelpers';
import useUserDropDownData from 'components/shared/Hooks/useUserDropDownData';

const ProjectContent = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [updateTaskDrawer, setUpdateTaskDrawer] = useState<boolean>(false);
  // const [showLoaderForCloning, setshowLoaderForCloning] = useState<boolean>(false);
  const [taskForDrawer, setTaskForDrawer] = useState<any>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenuItems = Boolean(anchorEl);
  const [tasks, setTasks] = useState<any[]>([]);
  const params = useParams();
  const clientUid = params.clientId as string;
  const companyUsersDropDownData = useUserDropDownData('company');

  useEffect(() => {
    if (props.allTasksOfClient.length > 0) {
      orderTasksBasedOnPriority();
    }
  }, [props]);

  const orderTasksBasedOnPriority = () => {
    const redTasks = props.allTasksOfClient.filter((task: any) => {
      return task.task_stage == 'Blocked' || task.task_stage == 'Not Started';
    });

    const greenTasks = props.allTasksOfClient.filter((task: any) => {
      return task.task_stage == 'In Progress';
    });

    const blueTasks = props.allTasksOfClient.filter((task: any) => {
      return task.task_stage == 'Done';
    });

    const greyTasks = props.allTasksOfClient.filter((task: any) => {
      return task.task_stage == 'Deferred';
    });

    const orderedTasks = [...redTasks, ...greenTasks, ...blueTasks, ...greyTasks];

    setTasks([...orderedTasks].slice(0, 2));
  };

  function stringToHslColor(str: string, s: number, l: number) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    const h = hash % 360;
    return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
  }

  function stringAvatar(name: string) {
    return {
      sx: {
        bgcolor: stringToHslColor(name, 30, 80),
      },
      children: (
        <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#000000' }}>
          {name.split(' ')[0][0]}
          {name.indexOf(' ') >= 0 ? name.split(' ')[1][0] : null}
        </Typography>
      ),
    };
  }

  const openTaskDrawer = async (task: any) => {
    const taskResult = await dispatch(getTaskByTaskUid(task.task_uid)).unwrap();
    setTaskForDrawer({ ...taskResult });
    setUpdateTaskDrawer(!updateTaskDrawer);
  };

  const userUid = getUserUid(props.projectInfo.project_deliverymanager);
  const deliveryManagerProfileLink = getUserLogoLinkByUid(userUid);
  const handleClickOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const cloneProjectForClient = (project_uid: string) => {
    props.setshowLoaderForCloning(false);
    const details: any = {
      project_uid: project_uid,
      last_user_modified: getLoggedInUserUid(),
      customer_uid: getCustomerUid(),
    };
    dispatch(cloneLiveProject(details))
      .unwrap()
      .then(async (result: any) => {
        if (result.status == 1) {
          resetNewProjectData();
          dispatch(resetSelectedProject());
          await dispatch(updateCaseWithfeatureStatus(clientUid));
          await dispatch(getClientByUid(clientUid));
          await dispatch(totalTrailClient(clientUid));
          await dispatch(getProjecWithClientUid(clientUid));
          await dispatch(fetchClientInformation(clientUid));
          await dispatch(getAllProjectsOfCustomer());
          handleClose();
          props.setshowLoaderForCloning(true);
          navigate(0);
          // await navigate(`/projects/project/${props.projectInfo.association_uid}/${result.result}`);
        }
      });
  };
  return (
    <>
      <CardContent>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item xs={11} md={11}>
            <Box
              display="grid"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={1}
              sx={{ paddingBottom: '16px', marginBottom: '8px' }}
              key={Math.random()}
            >
              <Box
                gridColumn="span 8"
                className="cognisaas-pointer"
                onClick={() => {
                  dispatch(resetSelectedProject());
                  navigate(`/projects/project/${props.projectInfo.association_uid}/${props.projectInfo.project_uid}`);
                }}
              >
                <Box display="flex">
                  <Box>
                    <Typography variant="h2">{props.projectInfo.project_name}</Typography>
                  </Box>
                  <Box>
                    <RightArrowIcon
                      style={{
                        paddingTop: '1px',
                        paddingLeft: '5px',
                        width: '12px',
                        height: '21px',
                        marginLeft: '8px',
                      }}
                    />
                  </Box>
                </Box>
                <Box display="flex">
                  <Typography display={'inline'} className="label-text" variant="subtitle2">
                    {label_due_date}
                  </Typography>
                  <Typography sx={{ paddingLeft: '4px' }} display={'inline'} variant="subtitle2">
                    {format(new Date(props.projectInfo.project_requiredbydate.replace(/-/g, '/')), 'dd MMM yyyy')}
                  </Typography>
                </Box>
              </Box>

              <Box gridColumn="span 4">
                <Box display="flex">
                  <Box display="flex">
                    {deliveryManagerProfileLink.length > 0 ? (
                      <AvatarImage
                        src={deliveryManagerProfileLink}
                        height="40px"
                        width="40px"
                        alt="Delivery manager profile"
                      />
                    ) : (
                      <Avatar
                        style={{ height: '40px', width: '40px', marginTop: '3px' }}
                        {...stringAvatar(props.projectInfo.project_deliverymanager)}
                      />
                    )}
                    <Box>
                      <Typography sx={{ paddingLeft: '4px' }} display={'inline'} variant="subtitle2">
                        {label_project_delivery_manager}
                      </Typography>
                      <Typography sx={{ paddingLeft: '6px' }} variant="subtitle1">
                        <Box display="flex">
                          {getUserActiveState(
                            getUserUid(props.projectInfo.project_deliverymanager),
                            companyUsersDropDownData
                          ) === '0' ? (
                            <TypographyWithTooltip
                              style={{ color: INACTIVE_USER_COLOR }}
                              maxLength={13}
                              variant={'subtitle1'}
                            >
                              {`${props.projectInfo.project_deliverymanager} (${INACTIVE_STRING})`}
                            </TypographyWithTooltip>
                          ) : (
                            <TypographyWithTooltip maxLength={13} variant={'subtitle1'}>
                              {props.projectInfo.project_deliverymanager}
                            </TypographyWithTooltip>
                          )}
                        </Box>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={1} md={1}>
            {!props.crmEnabled && (
              <Stack direction="row" justifyContent="flex-end">
                <IconButton
                  disableRipple
                  id={`${props.projectInfo.project_uid}basic-button`}
                  onClick={(event) => {
                    handleClickOpenMenu(event);
                  }}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id={`${props.projectInfo.project_uid}basic-menu`}
                  anchorEl={anchorEl}
                  open={openMenuItems}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      cloneProjectForClient(props.projectInfo.project_uid);
                    }}
                  >
                    <Typography variant="inherit">Clone {label_project}</Typography>
                  </MenuItem>
                </Menu>
              </Stack>
            )}
          </Grid>
        </Grid>

        <Divider></Divider>
        {tasks.map((task: any) => {
          return (
            <Box
              display="grid"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={1}
              sx={{ paddingTop: '16px', marginTop: '8px' }}
              key={Math.random()}
            >
              <Box gridColumn="span 5">
                <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={1}>
                  <Box gridColumn="span 1">
                    <CognisaasTaskStageIndicator isMileStoneTask={task.milestone_task != '0'} stage={task.task_stage} />
                  </Box>
                  <Box gridColumn="span 11">
                    <Typography
                      style={{
                        color: '#2D60F6',
                        marginTop: '-3px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        width: '190px',
                      }}
                      className="cognisaas-pointer"
                      onClick={() => openTaskDrawer(task)}
                      variant="subtitle1"
                    >
                      {task.task_name}
                    </Typography>
                    <Typography variant="subtitle2">
                      {/* 10 Days{' '} */}
                      {!task.case_uid ? (
                        <span className="label-text">
                          {task.task_stage} ({label_project} Task)
                        </span>
                      ) : (
                        <span className="label-text">
                          {task.task_stage} (
                          <span
                            className="cognisaas-pointer"
                            style={{ color: '#2D60F6' }}
                            onClick={() =>
                              navigate(
                                `/projects/project/${props.projectInfo.association_uid}/${task.project_uid}/${task.case_uid}`,
                                {
                                  state: { clientUid: task.client_uid, clientName: props.client.client_name },
                                }
                              )
                            }
                          >
                            {label_use_case}{' '}
                          </span>
                          Task)
                        </span>
                      )}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box gridColumn="span 3">
                <Typography variant="subtitle1">{label_due_date}</Typography>
                <Typography variant="subtitle2">
                  {/* 10 Days{' '} */}
                  <span className="label-text">
                    {format(new Date(task.task_due_date.replace(/-/g, '/')), 'dd MMM yyyy')}
                  </span>
                </Typography>
              </Box>
              <Box gridColumn="span 4">
                <Box display="flex">
                  <Box>
                    {getUserLogoLinkByUid(task.task_owner).length > 0 ? (
                      <AvatarImage
                        src={getUserLogoLinkByUid(task.task_owner)}
                        height="34px"
                        width="34px"
                        alt="Task owner profile"
                      />
                    ) : (
                      <Avatar
                        style={{ height: '34px', width: '34px', marginTop: '3px' }}
                        {...stringAvatar(
                          task.is_team_task == '1'
                            ? getUserNameByUserUid(task.task_owner)
                            : getContactNameByContactUid(task.task_owner)
                        )}
                      />
                    )}
                  </Box>
                  <Box>
                    <Typography sx={{ paddingLeft: '4px' }} display={'inline'} variant="subtitle2">
                      {label_task} Owner
                    </Typography>

                    {task.is_team_task == '1' ? (
                      <Box display="flex">
                        {getUserActiveState(task.task_owner, companyUsersDropDownData) === '0' ? (
                          <TypographyWithTooltip
                            style={{ color: INACTIVE_USER_COLOR, paddingLeft: '6px' }}
                            maxLength={16}
                            variant={'subtitle1'}
                          >
                            {`${getUserNameByUserUid(task.task_owner)} (${INACTIVE_STRING})`}
                          </TypographyWithTooltip>
                        ) : (
                          <TypographyWithTooltip sx={{ paddingLeft: '6px' }} maxLength={13} variant={'subtitle1'}>
                            {getUserNameByUserUid(task.task_owner)}
                          </TypographyWithTooltip>
                        )}
                      </Box>
                    ) : (
                      <Box display="flex">
                        <Typography sx={{ paddingLeft: '6px' }} variant="subtitle1">
                          {getContactNameByContactUid(task.task_owner).substr(0, 16)}
                        </Typography>
                        {getContactNameByContactUid(task.task_owner).length > 16 ? (
                          <CognisaaasTooltip
                            title={
                              <Box sx={{ height: '30px', width: '300px', padding: '12px' }}>
                                <Typography variant="subtitle1">
                                  {getContactNameByContactUid(task.task_owner)}
                                </Typography>
                              </Box>
                            }
                          >
                            <Typography>...</Typography>
                          </CognisaaasTooltip>
                        ) : null}
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          );
        })}
      </CardContent>
      {updateTaskDrawer && (
        <UpdateTaskDrawer
          client={props.client}
          taskDetail={taskForDrawer}
          drawerState={updateTaskDrawer}
          onClose={async () => {
            await fetchClientInformation(props.client.client_uid);
            await dispatch(getClientByUid(props.client.client_uid));
            setUpdateTaskDrawer(!updateTaskDrawer);
          }}
        />
      )}
    </>
  );
};

export default ProjectContent;
