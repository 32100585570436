// import { handleInlineEditClickType, inlineEditPropType } from 'components/shared/Tables/types';
import { useGridApiContext } from '@mui/x-data-grid';
import { TextField } from '@mui/material';

export const ExcelTextEditor = (params: any, handleUpdate: any, isDuration?: string) => {
  const apiRef = useGridApiContext();
  const { id, value, field } = params;
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    event.preventDefault();
    console.log(event.target.value, 'compare', field, isDuration);
    handleUpdate(event.target.value, id, field);
    apiRef.current.setEditCellValue({ id, field, value: event.target.value });
  };
  // const handleClick = async () => {
  //   // await handleOnClick(params, field, value, type);
  //   console.log('val tar 2', value);
  //   apiRef.current.setEditCellValue({ id, field, value: value });
  //   apiRef.current.stopCellEditMode({ id, field });
  //   apiRef.current.resetRowHeights();
  // };

  //without auto spacing when clicked out
  // const handleEvent: GridEventListener<'cellFocusOut'> = () => {
  //   apiRef.current.resetRowHeights();
  // };
  // apiRef.current.subscribeEvent('cellFocusOut', handleEvent);

  return (
    <>
      {/* <div style={{ padding: '5px 4px', display: 'flex', alignItems: 'center', width: '90%', position: 'relative' }}> */}
      <TextField
        autoFocus
        value={value}
        type="text"
        size="small"
        id="text"
        multiline
        placeholder={isDuration == 'duration' ? '2w 3d' : ''}
        onKeyDown={(e) => e.stopPropagation()}
        maxRows={5}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(event)}
        style={{
          width: '100%',
          // height: 'inherit',
          borderRadius: '2px 0 0 5px',
          border: '1px solid #E5E5E5',
        }}
      />
      {/* <button
            style={{
              border: '1px solid #4F9F52',
              background: '#BDF2BF',
              width: '40px',
              borderRadius: '0px 5px 5px 0px',
              height: '40px',
              marginLeft: '-1px',
              cursor: 'pointer',
            }}
            onClick={handleClick}
          >
            <EditSVG />{' '}
          </button> */}
      {/* </div> */}
    </>
  );
};
