import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../index';

const selectSettingDataState = (state: RootState) => state.adminPanelState;

export const allCustomerDetails_selector = createSelector(
    selectSettingDataState,
    (settingSlice) => {
        return settingSlice.allCustomerDetails;
    }
);

// getAllClientCounts
// getUserCount

export const getAllClientCounts_selector = createSelector(
    selectSettingDataState,
    (settingSlice) => {
        return settingSlice.allClientCounts;
    }
);

export const getUserCount_selector = createSelector(
    selectSettingDataState,
    (settingSlice) => {
        return settingSlice.allUserCount;
    }
);

export const getAllUserActivity_selector = createSelector(
    selectSettingDataState,
    (settingSlice) => {
        return settingSlice.allUserActivity;
    }
);

export const getProjectCount_selector = createSelector(
    selectSettingDataState,
    (settingSlice) => {
        return settingSlice.projectCount;
    }
);

export const getUsecase_selector = createSelector(
    selectSettingDataState,
    (settingSlice) => {
        return settingSlice.usecaseCount;
    }
);

export const getProjectCountByUser_selector = createSelector(
    selectSettingDataState,
    (settingSlice) => {
        return settingSlice.projectCount;
    }
);

export const getUsecaseByUser_selector = createSelector(
    selectSettingDataState,
    (settingSlice) => {
        return settingSlice.usecaseCount;
    }
);

export const adminLogin_selector = createSelector(
    selectSettingDataState,
    (settingSlice) => {
        return settingSlice.adminLogin;
    }
);

export const otpVerification_selector = createSelector(
    selectSettingDataState,
    (settingSlice) => {
        return settingSlice.otpVerification;
    }
);

export const saveAdminToken_selector = createSelector(
    selectSettingDataState,
    (settingSlice) => {
        return settingSlice.saveAdminToken;
    }
);

export const checkAdminToken_selector = createSelector(
    selectSettingDataState,
    (settingSlice) => {
        
        return settingSlice.checkAdminToken;
    }
);