import { apis } from '../axiosInstance';
import { dashboardRoutes } from '../route';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const getCountByUserUid = () =>
  apis.phpAxiosInstance.get(
    dashboardRoutes.getCountByUserUid(
      cookies.get('cogni_customer_uid') as string,
      cookies.get('cogni_user_uid') as string
    ),
    {
      headers: { JWT: 'true' },
    }
  );

const getTeamCountByUserUid = () =>
  apis.phpAxiosInstance.get(
    dashboardRoutes.getTeamCountByUserUid(
      cookies.get('cogni_customer_uid') as string,
      cookies.get('cogni_user_uid') as string
    ),
    {
      headers: { JWT: 'true' },
    }
  );

const getUseCaseCountUid = () =>
  apis.phpAxiosInstance.get(
    dashboardRoutes.getUseCaseCountUid(
      cookies.get('cogni_customer_uid') as string,
      cookies.get('cogni_user_uid') as string
    ),
    {
      headers: { JWT: 'true' },
    }
  );

const getTeamUseCaseCountUid = () =>
  apis.phpAxiosInstance.get(
    dashboardRoutes.getTeamUseCaseCountUid(
      cookies.get('cogni_customer_uid') as string,
      cookies.get('cogni_user_uid') as string
    ),
    {
      headers: { JWT: 'true' },
    }
  );

const getTaskStatusCount = () =>
  apis.phpAxiosInstance.get(
    dashboardRoutes.getTaskStatusCount(
      cookies.get('cogni_customer_uid') as string,
      cookies.get('cogni_user_uid') as string
    ),
    {
      headers: { JWT: 'true' },
    }
  );

const getTeamTaskStatusCount = () =>
  apis.phpAxiosInstance.get(
    dashboardRoutes.getTeamTaskStatusCount(
      cookies.get('cogni_customer_uid') as string,
      cookies.get('cogni_user_uid') as string
    ),
    {
      headers: { JWT: 'true' },
    }
  );

const getProjectStatusCountByCustomerUid = (detail: any) =>
  apis.phpAxiosInstance.get(dashboardRoutes.getProjectStatusCountByCustomerUid(detail.customer_uid, detail.user_uid), {
    headers: { JWT: 'true' },
  });

const getAllTeamTasksByUseruidForCustomer = (detail: any) =>
  apis.phpAxiosInstance.get(dashboardRoutes.getAllTeamTasksByUseruidForCustomer(detail.customer_uid, detail.user_uid), {
    headers: { JWT: 'true' },
  });

const getClientsbyUserTeam = (detail: any) =>
  apis.phpAxiosInstance.get(dashboardRoutes.getClientsbyUserTeam(detail.customer_uid, detail.user_uid), {
    headers: { JWT: 'true' },
  });

const getTeamProjectStatusCountByCustomerUid = (detail: any) =>
  apis.phpAxiosInstance.get(
    dashboardRoutes.getTeamProjectStatusCountByCustomerUid(detail.customer_uid, detail.user_uid),
    {
      headers: { JWT: 'true' },
    }
  );

const getClientUpgradationAlert = () =>
  apis.phpAxiosInstance.get(dashboardRoutes.getClientUpgradationAlert(cookies.get('cogni_user_uid') as string), {
    headers: { JWT: 'true' },
  });

const getTeamClientUpgradationAlert = () =>
  apis.phpAxiosInstance.get(dashboardRoutes.getTeamClientUpgradationAlert(cookies.get('cogni_user_uid') as string), {
    headers: { JWT: 'true' },
  });

const getProjectUpgradationAlert = () =>
  apis.phpAxiosInstance.get(dashboardRoutes.getProjectUpgradationAlert(cookies.get('cogni_user_uid') as string), {
    headers: { JWT: 'true' },
  });

const getTeamProjectUpgradationAlert = () =>
  apis.phpAxiosInstance.get(dashboardRoutes.getTeamProjectUpgradationAlert(cookies.get('cogni_user_uid') as string), {
    headers: { JWT: 'true' },
  });

const getUsecaseUpgradationAlert = () =>
  apis.phpAxiosInstance.get(dashboardRoutes.getUsecaseUpgradationAlert(cookies.get('cogni_user_uid') as string), {
    headers: { JWT: 'true' },
  });

const getTeamUsecaseUpgradationAlert = () =>
  apis.phpAxiosInstance.get(dashboardRoutes.getTeamUsecaseUpgradationAlert(cookies.get('cogni_user_uid') as string), {
    headers: { JWT: 'true' },
  });

const getClientDegradationAlert = () =>
  apis.phpAxiosInstance.get(dashboardRoutes.getClientDegradationAlert(cookies.get('cogni_user_uid') as string), {
    headers: { JWT: 'true' },
  });

const getTeamClientDegradationAlert = () =>
  apis.phpAxiosInstance.get(dashboardRoutes.getTeamClientDegradationAlert(cookies.get('cogni_user_uid') as string), {
    headers: { JWT: 'true' },
  });

const getProjectDegradationAlert = () =>
  apis.phpAxiosInstance.get(dashboardRoutes.getProjectDegradationAlert(cookies.get('cogni_user_uid') as string), {
    headers: { JWT: 'true' },
  });

const getTeamProjectDegradationAlert = () =>
  apis.phpAxiosInstance.get(dashboardRoutes.getTeamProjectDegradationAlert(cookies.get('cogni_user_uid') as string), {
    headers: { JWT: 'true' },
  });

const getUsecaseDegradationAlert = () =>
  apis.phpAxiosInstance.get(dashboardRoutes.getUsecaseDegradationAlert(cookies.get('cogni_user_uid') as string), {
    headers: { JWT: 'true' },
  });

const getTeamUsecaseDegradationAlert = () =>
  apis.phpAxiosInstance.get(dashboardRoutes.getTeamUsecaseDegradationAlert(cookies.get('cogni_user_uid') as string), {
    headers: { JWT: 'true' },
  });

const getAllReporties = () =>
  apis.phpAxiosInstance.get(dashboardRoutes.getAllReporties(cookies.get('cogni_user_uid') as string), {
    headers: { JWT: 'true' },
  });

const getUserInfoByUid = (user_uid_dashboard: string) =>
  apis.phpAxiosInstance.get(dashboardRoutes.getUserDetail(user_uid_dashboard), { headers: { JWT: 'true' } });

export const DashboardApi = {
  getCountByUserUid,
  getTeamCountByUserUid,
  getUseCaseCountUid,
  getTeamUseCaseCountUid,
  getTaskStatusCount,
  getTeamTaskStatusCount,
  getProjectStatusCountByCustomerUid,
  getTeamProjectStatusCountByCustomerUid,
  getClientUpgradationAlert,
  getTeamClientUpgradationAlert,
  getProjectUpgradationAlert,
  getTeamProjectUpgradationAlert,
  getUsecaseUpgradationAlert,
  getTeamUsecaseUpgradationAlert,
  getClientDegradationAlert,
  getTeamClientDegradationAlert,
  getProjectDegradationAlert,
  getTeamProjectDegradationAlert,
  getUsecaseDegradationAlert,
  getTeamUsecaseDegradationAlert,
  getUserInfoByUid,
  getAllTeamTasksByUseruidForCustomer,
  getAllReporties,
  getClientsbyUserTeam,
};
