import { Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { label_task } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import CognisaasButton from 'components/shared/CognisaasButton/CognisaasButton';
import FilterTypeSelector from 'components/shared/CognisaasFilters/FilterTypeSelector/FilterTypeSelector';
import SelectedFilterList from 'components/shared/CognisaasFilters/SelectedFilterList/SelectedFilterList';
import NewTaskDrawer from 'components/TaskModule/AddTask/NewTaskDrawer';
import UpdateTaskDrawer from 'components/TaskModule/TaskView/UpdateTaskDrawer';
import { useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { selectAllFilters } from 'store/modules/AllFilters/selector';
import TaskTableView from './TaskTableView';
import useTaskDashboard from './useTaskDashboard';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import CognisaasTabs from '../shared/CognisaasTabs';

const tabs = ['All', 'Our Team', 'Clients Team'];

const View = () => {
  const allFilters = useAppSelector(selectAllFilters);
  const { mappedAllTasks, filteredTasks, setInnerTab, selectedInnerTab, selectedMainTab, setSelectedMainTab } =
    useTaskDashboard();
  const [updateTaskDrawer, setUpdateTaskDrawer] = useState<boolean>(false);
  const [openTaskWithDetail, setOpenTaskWithDetail] = useState<Record<string, any>>({});
  const [addTaskDrawer, setAddTaskDrawer] = useState<boolean>(false);

  const openUpdateTaskDrawer = (taskDetail: Record<string, any>) => {
    setOpenTaskWithDetail({ ...taskDetail });
    setUpdateTaskDrawer(true);
  };

  function handleTabSelection(_event: any, tabIndex: number) {
    setSelectedMainTab(tabIndex);
  }

  function getTabSwitcher(selectedMainTab: number) {
    return (
      <Stack direction={'row'} justifyContent={'space-between'}>
        <CognisaasTabs
          size="squeezed"
          selectedTabIndex={selectedMainTab}
          handleTabSwitch={handleTabSelection}
          tabs={tabs}
        />
      </Stack>
    );
  }

  return (
    <>
      <Box sx={{ paddingLeft: '20px', paddingRight: '20px', paddingTop: '20px' }}>
        <Stack justifyContent={'space-between'} direction="row" alignItems={'center'}>
          <Typography component="span" sx={{ fontWeight: '500' }} variant="h1">
            {label_task}s
          </Typography>
          <Stack direction={'row'} spacing={1}>
            <FilterTypeSelector idName="taskDashboardHome" />
            <CognisaasButton
              clickHandler={() => {
                setAddTaskDrawer(true);
              }}
              isOutlined={false}
              label={'New ' + label_task}
            />
          </Stack>
        </Stack>
        <Box sx={{ display: 'grid', m: '10px 0px 0px 0px', gridRowGap: '20px' }}>
          {getTabSwitcher(selectedMainTab)}

          <Box>
            <ButtonGroup
              variant="outlined"
              aria-label="outlined button group"
              sx={{
                display: 'inline-flex',
                flexWrap: 'wrap',
                backgroundColor: 'white',
                border: '1px solid #E5E5E5',
                borderRadius: '4px',
              }}
            >
              <Button
                variant="text"
                sx={selectedInnerTab === 'all' ? { background: 'rgba(45, 96, 246,0.15)' } : {}}
                onClick={() => setInnerTab('all')}
              >
                All {label_task}s
              </Button>
              <Button
                variant="text"
                sx={selectedInnerTab === 'open' ? { background: 'rgba(45, 96, 246,0.15)' } : {}}
                onClick={() => setInnerTab('open')}
              >
                Open {label_task}s
              </Button>
              <Button
                variant="text"
                sx={selectedInnerTab === 'closed' ? { background: 'rgba(45, 96, 246,0.15)' } : {}}
                onClick={() => setInnerTab('closed')}
              >
                Closed {label_task}s
              </Button>
            </ButtonGroup>
          </Box>
        </Box>

        <Stack direction="row" justifyContent="flex-end"></Stack>
        <Box sx={{ margin: '8px 0px' }}>
          {allFilters['taskDashboardHome']?.appliedFilters.filter((item: any) => item.isSelected).length > 0 && (
            <SelectedFilterList idName="taskDashboardHome" isResetEnabled={true} />
          )}
        </Box>
        <Box mt={2}>
          <TaskTableView
            data={mappedAllTasks}
            filteredData={filteredTasks}
            openUpdateTaskDrawer={openUpdateTaskDrawer}
            innerTab={selectedInnerTab}
          />
        </Box>

        {updateTaskDrawer && (
          <UpdateTaskDrawer
            client={{ client_uid: openTaskWithDetail.client_uid }}
            taskDetail={openTaskWithDetail}
            drawerState={updateTaskDrawer}
            onClose={() => setUpdateTaskDrawer(!updateTaskDrawer)}
          />
        )}

        <NewTaskDrawer
          type="standalone"
          isProjectTask={true}
          drawerState={addTaskDrawer}
          onClose={() => setAddTaskDrawer(!addTaskDrawer)}
        />
      </Box>
    </>
  );
};

export default View;
