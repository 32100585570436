import { useState } from 'react';

import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import { visuallyHidden } from '@mui/utils';
import { TextField } from '@mui/material';
import { ReactComponent as SearchIcon } from 'assets/svgs/search.svg';
import { ListTableHeadProps } from 'components/RBACv2/interfaces';

export default function ListTableHead(props: ListTableHeadProps) {
  const [showSearchIcon, setShowSearchIcon] = useState<boolean>(true);
  const { order, setOrder } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell
          align="left"
          sx={{ height: '30px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        >
          <TableSortLabel direction={order} active={true} onClick={() => setOrder(order === 'asc' ? 'desc' : 'asc')}>
            <Typography variant={'h2'}>{props.label}</Typography>
            <Box component="span" sx={visuallyHidden}>
              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
            </Box>
          </TableSortLabel>
          {showSearchIcon ? (
            <SearchIcon
              onClick={() => {
                setShowSearchIcon(false);
              }}
              style={{ cursor: 'pointer' }}
            />
          ) : (
            <TextField
              placeholder="Search"
              value={props.searchValue}
              onBlur={() => {
                setShowSearchIcon(true);
              }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                props.setSearchValue(e.target.value);
              }}
              id="standard-basic"
              variant="standard"
            />
          )}
        </TableCell>
      </TableRow>
    </TableHead>
  );
}
