import { Menu, MenuItem, Typography, IconButton, TextField, Stack } from '@mui/material';
import { Box } from '@mui/system';
// import DeleteIcon from '@mui/icons-material/Delete';
import Styles from './ProductCard.module.css';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  deleteProduct,
  deleteProductFromLocalStore,
  fetchProductAndRequirements,
  updateProductByProductUid,
} from '../../../store/modules/ProductRequirements/ProductDetails/slice';
import { useAppSelector } from '../../../store/hooks';
import { selectCustomerDetailsState } from '../../../store/modules/ProductRequirements/ProductDetails/selector';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import Cookies from 'universal-cookie';
import DeleteModal from 'components/shared/CognisaasModal/DeleteModal';

interface Props {
  productInfo: Record<string, any>;
  index: number;
  selectedProductIndex: number;
  handleProductCardClick: (productInfo: any, productIndex: number) => void;
  setProdReqView: any;
  disabled: boolean;
}

const cookies = new Cookies();

const ProductCard = (props: Props) => {
  const customer_uid = cookies.get('cogni_customer_uid') as string;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const customerDetailState = useAppSelector(selectCustomerDetailsState);
  const [newProductName, setNewProductName] = useState(props?.productInfo?.product_name);
  const [editProductName, setEditProductName] = useState(false);
  const dispatch = useDispatch();
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    // console.log(props);
  };

  const handleProductEdit = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    console.log('edit button clicked');
    setEditProductName(true);
  };

  const submitProductEdit = () => {
    const submitObj = {
      customer_uid: customer_uid,
      product_uid: props?.productInfo?.product_uid,
      product_name: newProductName,
    };
    dispatch(updateProductByProductUid(submitObj));
    setTimeout(() => dispatch(fetchProductAndRequirements()), 300);
    setEditProductName(false);
  };

  const productDeleteHandler = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    handleClose();
    setOpenDeleteModal(true);
  };

  const deleteWholeProduct = () => {
    dispatch(
      deleteProduct({ customer_uid: props.productInfo.customer_uid, product_uid: props.productInfo.product_uid })
    );
    dispatch(deleteProductFromLocalStore(props.selectedProductIndex));
  };

  const handleCancelEditProductName = () => {
    setNewProductName(props?.productInfo?.product_name);
    setEditProductName(false);
  };

  const newRequirementHandler = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    console.log('add req');
    props.setProdReqView('add-new-requirement');
  };

  return (
    <>
      <DeleteModal
        isOpen={openDeleteModal}
        setIsOpen={setOpenDeleteModal}
        message={'Are you sure you want to delete this product'}
        onDelete={deleteWholeProduct}
      />
      <div
        onClick={() => props.handleProductCardClick(props.productInfo, props.index)}
        key={props.index}
        className={
          Styles.ProductRequirement__productView_listCardCore +
          ' ' +
          (props.index === props.selectedProductIndex
            ? Styles.ProductRequirement__productView_listCardActive
            : Styles.ProductRequirement__productView_listCardCoreInactive)
        }
      >
        {editProductName === true ? (
          <Stack direction="row">
            <TextField
              value={newProductName}
              onChange={(e) => setNewProductName(e.target.value)}
              sx={{ width: '250px' }}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
              size="small"
            />
            <CheckIcon
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                submitProductEdit();
              }}
              sx={{ mt: '5px', color: 'green', ml: '10px' }}
            />
            <ClearIcon
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleCancelEditProductName();
              }}
              sx={{ mt: '5px', color: 'red', ml: '10px' }}
            />
          </Stack>
        ) : (
          <Typography
            style={{ color: '#000000', fontWeight: '400', marginTop: '5px', paddingBottom: '5px' }}
            variant="h2"
          >
            {props.productInfo.product_name}
          </Typography>
        )}
        {editProductName === true ? (
          <Box component={'span'} sx={{ position: 'absolute', right: '0' }}>
            <IconButton
              onClick={handleClick}
              disabled={
                customerDetailState.status === 'success'
                  ? customerDetailState.data.customer_jira_user.length > 0
                    ? true
                    : false
                  : false
              }
            >
              <MoreVertIcon />
            </IconButton>
          </Box>
        ) : (
          <Box component={'span'} sx={{ position: 'absolute', right: '0', mt: '-5px' }}>
            <IconButton
              onClick={handleClick}
              // disabled={
              //   customerDetailState.status === 'success'
              //     ? customerDetailState.data.customer_jira_user.length > 0
              //       ? true
              //       : false
              //     : false
              // }
            >
              <MoreVertIcon />
            </IconButton>
          </Box>
        )}

        {customerDetailState.status === 'success' &&
        customerDetailState.data.customer_jira_fetch_mode &&
        customerDetailState.data.customer_jira_fetch_mode === 'pull' ? (
          <></>
        ) : (
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={newRequirementHandler}>
              <Typography variant="inherit">New Requirement</Typography>
            </MenuItem>
            <MenuItem
              onClick={handleProductEdit}
              disabled={
                customerDetailState.status === 'success'
                  ? customerDetailState.data.customer_jira_user.length > 0
                    ? true
                    : false
                  : false
              }
            >
              <Typography variant="inherit">Edit Product name</Typography>
            </MenuItem>
            <MenuItem
              onClick={productDeleteHandler}
              disabled={
                customerDetailState.status === 'success'
                  ? customerDetailState.data.customer_jira_user.length > 0
                    ? true
                    : false
                  : false
              }
            >
              <Typography variant="inherit">Delete Product</Typography>
            </MenuItem>
          </Menu>
        )}
      </div>
    </>
  );
};

export default ProductCard;
