import { Typography, Stack } from '@mui/material';
import CognisaasSelect from '../../shared/CognisaasSelect/CognisaasSelect';
// import { useDispatch } from 'react-redux';
// import { useAppSelector } from '../../../store/hooks';
import { useEffect, useState } from 'react';
// import { fetchClientContacts } from '../../../store/modules/ManageClient/ClientList/slice';
// import { useParams } from 'react-router-dom';
// import { selectClientContacts } from '../../../store/modules/ManageClient/ClientList/selector';
import CognisaasButton from '../../shared/CognisaasButton/CognisaasButton';
import { label_task } from 'components/Settings/Preferences/CustomLabelCookieHelper';

const AssignTaskToClientModal = (props: any) => {
  // const client = props.client;
  const [clientContacts, setClientContacts] = useState<any[]>([]);
  const [selectedClientContact, setSelectedClientContact] = useState<string>('Not Assigned');
  // const dispatch = useDispatch();
  // const clientContactList = useAppSelector(selectClientContacts);
  // const params: any = useParams();
  const { preselectedClientContacts } = props;

  // useEffect(() => {
  //   console.log(params);

  //   dispatch(fetchClientContacts(client.client_uid));
  // }, []);

  useEffect(() => {
    if (preselectedClientContacts.length > 0 || preselectedClientContacts.length == 0) {
      getClientList();
    }
  }, [preselectedClientContacts]);

  const getClientList = () => {
    const list = preselectedClientContacts.map((item: any) => item.contact_name);
    list.unshift('Not Assigned');
    setClientContacts(list);
  };

  // const changeTaskToClient = () => {
  //   console.log(selectedClientContact);
  //   console.log(clientContacts);
  // };

  return (
    <Stack>
      <Stack my={2}>
        <Typography variant="h2">Assign {label_task} to client</Typography>
      </Stack>
      <Stack>
        <CognisaasSelect
          shouldBeOpenByDefault={true}
          onChange={(e: any) => setSelectedClientContact(e.target.value)}
          width="310px"
          value={selectedClientContact}
          validValues={[...clientContacts]}
        ></CognisaasSelect>
      </Stack>
      <Stack sx={{ marginTop: '240px' }} spacing={2} justifyContent="flex-end" alignItems="flex-end" direction="row">
        <CognisaasButton clickHandler={props.onClose} isOutlined={true} label="Cancel" />
        <CognisaasButton
          clickHandler={() => {
            props.onClickingContinue(selectedClientContact);
            props.onClose();
          }}
          isOutlined={false}
          label="Continue"
        />
      </Stack>
    </Stack>
  );
};

export default AssignTaskToClientModal;
