import { ReactComponent as Icon } from '../../../assets/svgs/fileIcon.svg';

interface Props {
  fileType: string;
}

const FileIcon = (props: Props) => {
  return (
    <span style={{ height: 'fit-content', position: 'relative', display: 'flex', transform: 'scale(0.8)' }}>
      <span style={{ height: '40px' }}>
        <Icon />
      </span>
      <span
        style={{
          fontSize: '10px',
          position: 'absolute',
          top: '20px',
          left: '8px',
          color: '#546E7A',
        }}
      >
        {props.fileType}
      </span>
    </span>
  );
};

export default FileIcon;
