import { useState, useEffect } from 'react';
import CustomizedTreeView from '../../../../../shared/DocumentExplorer/DocumentExplorer';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../../../store/hooks';
import { selectAttachmentDataDownloadQueue } from '../../../../../../store/modules/Attachment/selector';
import {
  getAttachmentsFromServer,
  resetAttachmentDownloadData,
} from '../../../../../../store/modules/Attachment/slice';
import { attachmentKeyGenerator } from '../../../../../shared/Attachment/attachmentHelpers';
import FullWidthAttachmentViewer from '../../../../../shared/Attachment/AttachmentFileView/FullWidthAttachmentViewer';
import { ReactComponent as NoRecordsIcon } from '../../../../../../assets/svgs/no_records_mini.svg';
import BasicModal from '../../../../../shared/CognisaasModal/CognisaasModal';
import { Typography, Stack, TextField } from '@mui/material';
import Attachment from '../../../../../shared/Attachment/Attachment';
import CognisaasButton from '../../../../../shared/CognisaasButton/CognisaasButton';
import VersionTable from './Version';

import { Sidebar, Container, Main, Frame, Grid } from './UsecaseAttachment.styles';
import Cookies from 'universal-cookie';
import CognisaasToast from 'components/shared/CognisaasToast/CognisaasToast';
import { getLoggedInUserUid } from 'utils/getUid';
import { format } from 'date-fns';
import { addUsecaseAuditTrial } from 'store/modules/Usecase/slice';

const cookies = new Cookies();

interface typeProps {
  clientInfo: any;
  usecaseInfo: any;
  projectInfo: any;
}

interface document {
  id: string;
  title: string;
  type: 'Task' | 'Usecase' | 'Project' | 'Phase';
  children: document[];
  [key: string]: any;
}

interface selectedDocument {
  id: string;
  title: string;
  type: 'Task' | 'Usecase' | 'Project' | 'Phase';
  project_uid: string;
  case_uid: string;
}

const buildDocumentTree = (usecaseInfo: any): document[] => {
  usecaseInfo = Object.assign({ children: [] }, usecaseInfo);
  usecaseInfo.type = 'Usecase';
  usecaseInfo.title = usecaseInfo.case_name;
  usecaseInfo.id = usecaseInfo.case_uid;
  const tempData: document[] = [usecaseInfo];
  for (let section of usecaseInfo.section) {
    section = Object.assign({ children: [] }, section);
    section.type = 'Phase';
    section.title = section.section_detail;
    section.id = section.section_uid;
    for (let task of section.task) {
      task = Object.assign({ type: 'Task' }, task);
      task.title = task.task_name;
      task.id = task.task_uid;
      section.children.push(task);
    }
    usecaseInfo.children.push(section);
  }
  return tempData;
};

const UsecaseAttachment = ({ usecaseInfo, clientInfo, projectInfo }: typeProps) => {
  const { client_uid, customer_uid } = clientInfo;
  const { case_uid: id, case_name: title } = usecaseInfo;
  const dispatch = useDispatch();

  const [modalIndex, setModalIndex] = useState<number>(0);
  const [documents, setDocuments] = useState<document[]>([] as document[]);
  const [selectedDocument, setSelectedDocument] = useState<selectedDocument>({
    id,
    title,
    type: 'Usecase',
    project_uid: '',
    case_uid: '',
  });
  const [attachmentDetail, setAttachmentDetail] = useState<any>({});
  const [attachmentName, setAttachmentName] = useState<string>('');

  const attachmentDataQueueState = useAppSelector(selectAttachmentDataDownloadQueue);
  const [attachmentsData, setAttachmentsData] = useState<Record<string, any>[]>([]);
  const [isBulUploadAttachmentOpened, setIsBulUploadAttachmentOpened] = useState(false);
  const [alertDetails, setAlertDetails] = useState<{
    isVisible: boolean;
    type: 'success' | 'failure';
    message: string;
  }>({ isVisible: false, type: 'success', message: '' });

  useEffect(() => {
    const data: document[] = buildDocumentTree(usecaseInfo);
    setDocuments(data);
  }, []);

  useEffect(() => {
    if (selectedDocument.type === 'Phase') {
      dispatch(getAttachmentsFromServer(`getAttachmentsBySection?level_uid=${selectedDocument.id}`));
    } else {
      dispatch(getAttachmentsFromServer(`getAttachmentsByLevelUid?level_uid=${selectedDocument.id}`));
    }
  }, [selectedDocument]);

  useEffect(() => {
    if (attachmentDataQueueState.status === 'success') {
      setAttachmentsData(attachmentDataQueueState.data || []);
      setTimeout(() => {
        dispatch(resetAttachmentDownloadData());
      }, 1000);
    }
  }, [attachmentDataQueueState]);

  const reloadAttachments = () => {
    const detail = {
      case_uid: selectedDocument.case_uid,
      user_uid: getLoggedInUserUid(),
      field: 'Attachment',
      modified_on: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      old_value: '',
      new_value: '',
    };
    dispatch(addUsecaseAuditTrial(detail));
    if (selectedDocument.type === 'Phase') {
      dispatch(getAttachmentsFromServer(`getAttachmentsBySection?level_uid=${selectedDocument.id}`));
    } else {
      dispatch(getAttachmentsFromServer(`getAttachmentsByLevelUid?level_uid=${selectedDocument.id}`));
    }
  };

  const attachmentCallback = (actionPerformed: string) => {
    if (actionPerformed === 'uploaded-bulk-attachments') {
      // get attachments uploaded on server and save in state and later show them
      if (selectedDocument.type === 'Phase') {
        dispatch(getAttachmentsFromServer(`getAttachmentsBySection?level_uid=${selectedDocument.id}`));
      } else {
        dispatch(getAttachmentsFromServer(`getAttachmentsByLevelUid?level_uid=${selectedDocument.id}`));
      }

      setTimeout(() => {
        // dispatch(resetAttachmentDownloadData());
        if (selectedDocument.type === 'Phase') {
          dispatch(getAttachmentsFromServer(`getAttachmentsBySection?level_uid=${selectedDocument.id}`));
        } else {
          dispatch(getAttachmentsFromServer(`getAttachmentsByLevelUid?level_uid=${selectedDocument.id}`));
        }
      }, 3000);
    }

    // if (actionPerformed === 'canceled-bulk-attachments') {
    // }
    setIsBulUploadAttachmentOpened(false);
  };

  return (
    <>
      <CognisaasToast
        open={alertDetails.isVisible}
        onClose={() => setAlertDetails({ isVisible: false, type: alertDetails.type, message: '' })}
        message={alertDetails.message}
        type={alertDetails.type}
      />
      <Container>
        <Sidebar>
          {documents.length > 0 &&
            documents.map((document: any, index: number) => (
              <CustomizedTreeView
                {...document}
                indent={1}
                key={index}
                selectedDocument={selectedDocument}
                setSelectedDocument={setSelectedDocument}
              />
            ))}
        </Sidebar>
        <Main>
          <Frame>
            <Typography variant="h6">{selectedDocument.title}</Typography>
            {selectedDocument.type !== 'Phase' && (
              <CognisaasButton
                isOutlined={true}
                label="Upload Attachment"
                clickHandler={() => setIsBulUploadAttachmentOpened(true)}
                sx={{ marginRight: '10px', height: '40px' }}
              />
            )}
          </Frame>
          <Grid>
            {attachmentsData.map((oneAttachment) => {
              return (
                <>
                  <FullWidthAttachmentViewer
                    key={oneAttachment.attachment_uid}
                    fileName={oneAttachment.name}
                    attachmentUid={oneAttachment.attachment_uid}
                    serverGetApiUrlWithQueryString={`downloadAttachment?attachment_uid=${oneAttachment.attachment_uid}`}
                    deleteApiUrl={'deleteAttachment'}
                    deleteAttachmentPayload={{
                      attachment_uid: oneAttachment.attachment_uid,
                    }}
                    attachmentData={oneAttachment}
                    deleteCallbackFunc={reloadAttachments}
                    setAttachmentInfo={setAttachmentDetail}
                    setModalIndex={setModalIndex}
                    userPermissions={projectInfo.__user_permissions}
                  />
                </>
              );
            })}
          </Grid>
          {attachmentsData.length < 1 && (
            <div style={{ width: '100%' }}>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginTop: '100px',
                }}
              >
                <Typography sx={{ marginBottom: '20px' }} variant="subtitle1" className="label-text">
                  {selectedDocument.type === 'Phase' ? 'No Attachments Exist' : 'No Attachments Found'}
                </Typography>

                <NoRecordsIcon />
              </div>
            </div>
          )}
          <BasicModal
            height={280}
            width={350}
            open={isBulUploadAttachmentOpened}
            onClose={() => setIsBulUploadAttachmentOpened(false)}
          >
            <Attachment
              variant="bulk-upload"
              type="normal"
              metaDataForHeaders={{
                category: `${selectedDocument.type}_level_attachment`,
                taskUid: selectedDocument.type === 'Task' ? selectedDocument.id : '',
                level_uid: selectedDocument.id,
                level_name: selectedDocument.type,
                project_uid: selectedDocument.type === 'Project' ? selectedDocument.id : selectedDocument.project_uid,
                client_uid: client_uid,
                case_uid: selectedDocument.type === 'Usecase' ? selectedDocument.id : selectedDocument.case_uid,
                customer_uid: cookies.get('cogni_customer_uid'),
                current_version_uploaded_by_user_uid: cookies.get('cogni_user_uid'),
                current_version_uploaded_by_user_name: cookies.get('user_name'),
                key: attachmentKeyGenerator(customer_uid, selectedDocument.id),
              }}
              setModalIndex={setModalIndex}
              apiUrlForAttachmentUpload={'addAttachments'}
              callbackOnWhatIsDoneWithAttachments={(actionPerformed: any) => attachmentCallback(actionPerformed)}
              setAlertDetails={setAlertDetails}
            />
          </BasicModal>
          <BasicModal onClose={() => setModalIndex(0)} open={modalIndex === 1} height={200}>
            <Stack>
              <Stack my={2}>
                <Typography variant="h2">Rename</Typography>
              </Stack>
              <Stack direction="row">
                <TextField
                  autoComplete=""
                  autoFocus={true}
                  onChange={(e) => setAttachmentName(e.target.value)}
                  style={{ width: '410px' }}
                  value={attachmentName}
                  size="small"
                  placeholder="Enter name"
                ></TextField>
              </Stack>
              <Stack mt={3} spacing={2} justifyContent="flex-end" direction="row">
                <CognisaasButton isOutlined={true} label="Cancel" clickHandler={() => setModalIndex(0)} />
                <CognisaasButton isOutlined={false} label="Save" clickHandler={() => setModalIndex(0)} />
              </Stack>
            </Stack>
          </BasicModal>
          <BasicModal onClose={() => setModalIndex(0)} open={modalIndex === 2} height={280} width={350}>
            <Attachment
              variant="bulk-upload"
              type="normal"
              metaDataForHeaders={{
                attachment_uid: attachmentDetail.attachment_uid,
                category: `${selectedDocument.type}_level_attachment`,
                taskUid: selectedDocument.type === 'Task' ? selectedDocument.id : '',
                level_uid: selectedDocument.id,
                level_name: selectedDocument.type,
                project_uid: selectedDocument.type === 'Project' ? selectedDocument.id : '',
                client_uid: client_uid,
                case_uid: selectedDocument.type === 'Usecase' ? selectedDocument.id : '',
                customer_uid: cookies.get('cogni_customer_uid'),
                current_version_uploaded_by_user_uid: cookies.get('cogni_user_uid'),
                current_version_uploaded_by_user_name: cookies.get('user_name'),
                key: attachmentKeyGenerator(customer_uid, selectedDocument.id),
              }}
              setModalIndex={setModalIndex}
              apiUrlForAttachmentUpload={'updateAttachment'}
              callbackOnWhatIsDoneWithAttachments={(actionPerformed: any) => attachmentCallback(actionPerformed)}
              setAlertDetails={setAlertDetails}
            />
          </BasicModal>
          <BasicModal onClose={() => setModalIndex(0)} open={modalIndex === 3} width={1000} height={400}>
            <VersionTable attachmentDetail={attachmentDetail} />
          </BasicModal>
        </Main>
      </Container>
    </>
  );
};

export default UsecaseAttachment;
