import { useEffect, useState } from 'react';

import { useAppSelector } from 'store/hooks';
import { selectComponentState, selectMainViewData, selectSoftAllocationData } from 'store/modules/ResourceManagement';
import { convertToSoftAllocationBarComponents } from 'components/ResourceManagement/helpers/soft-allocation-bar-helper';
import { ganttDateRangeX, seedDates } from 'components/ResourceManagement/helpers/date-helper';
import { Bar } from '../bar';
import { BarComponent } from 'components/ResourceManagement/types/bar-component';
import { GanttContentMoveAction } from 'components/ResourceManagement/types/gantt-component-actions';
import { getStartDateInYYYYMMDD, getEndDateInYYYYMMDD } from 'components/ResourceManagement/utils/date';

interface IComponentSoftAllocation {
    primeComponents: BarComponent[],
    mode: string,
    columnWidth: number,
    rowHeight: number,
    componentHeight: number,
    barCornerRadius: number,
    selectedComponent: BarComponent | undefined;
    onEventStart: (
        action: GanttContentMoveAction,
        selectedComponent: BarComponent,
        event?: React.MouseEvent | React.KeyboardEvent
    ) => any;
}
const stepCount = 1;

export const ComponentSoftAllocation = ({
    primeComponents,
    mode,
    columnWidth,
    rowHeight,
    barCornerRadius,
    selectedComponent,
    componentHeight,
    onEventStart: handleBarEventStart,
}: IComponentSoftAllocation) => {
    const isMainView = mode === 'main-view';
    const selectedSoftAllocationData = useAppSelector(selectSoftAllocationData);
    const selectedComponentState = useAppSelector(selectComponentState);
    const selectedMainViewData = useAppSelector(selectMainViewData);
    const { events: mainViewEventsForDateRange } = selectedMainViewData;
    const softAllocations = selectedSoftAllocationData;
    const { viewMode } = selectedComponentState;
    const [convertedSoftAllocations, setConvertedSoftAllocations] = useState<any[]>([]);

    // console.log('CSA', { softAllocations });

    let yMap: Record<string, number> = {};
    primeComponents.forEach((resourceItem) => {
        yMap = {
            ...yMap,
            [resourceItem.id]: resourceItem.y,
        };
    });

    useEffect(() => {
        const updatedSoftAllocationWithY = softAllocations ? softAllocations.map((softAllocationItem: any) => {
            let targetItem = softAllocationItem;
            const projectName = selectedMainViewData.events.find(
                (item: any) => item.id === softAllocationItem.projectId
            )?.name;
            targetItem = softAllocationItem.allocationType === '1' ? {
                ...softAllocationItem,
                name: projectName,
                softY: yMap[softAllocationItem.resourceId] - 20,
            } : {
                ...softAllocationItem,
                name: 'Leave',
                softY: yMap[softAllocationItem.resourceId] + 24,
            };
            return targetItem;
        }) : [];
        const [startDate, endDate] = ganttDateRangeX(mainViewEventsForDateRange, viewMode, stepCount);
        const newDates = seedDates(getStartDateInYYYYMMDD(new Date(startDate)), getEndDateInYYYYMMDD(new Date(endDate)), viewMode);
        const convertedSoftAllocationBars = convertToSoftAllocationBarComponents(updatedSoftAllocationWithY, newDates, columnWidth, rowHeight);
        setConvertedSoftAllocations(convertedSoftAllocationBars);
    }, [primeComponents, viewMode]);

    // console.log({ convertedSoftAllocations });

    return isMainView ?
        <>
            {convertedSoftAllocations.map(component => <Bar
                key={component.id}
                component={component}
                barCornerRadius={barCornerRadius}
                componentHeight={componentHeight}
                onEventStart={handleBarEventStart}
                isSelected={!!selectedComponent && component.id === selectedComponent.id}
                mode={mode}
            />)}
        </> : null;
};
