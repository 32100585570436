export const segmentFilters = ['Enterprise', 'Mid-Market', 'SMB'];

export const statusFilters = ['Red', 'Amber', 'Green', 'Blue'];

// export interface statusFilter {
//   RED: 'Red';
//   AMBER: 'Amber';
//   GREEN: 'Green';
//   BLUE: 'Blue';
// }
