import { useState, useEffect } from 'react';
import { Stack, Typography } from '@mui/material';
import CognisaasButton from 'components/shared/CognisaasButton/CognisaasButton';
import { addUserModalFor } from '../types';
import { addReportsTo } from '../../../store/modules/RBAC/slice';
import { useDispatch } from 'react-redux';
import CognisaasMultiSelectWithSearchUid from '../../shared/CognisaasMultiSelectWithSearch/CognisaasMultiSelectWithSearchUid';

const CommonAddUser = (props: any) => {
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOptions] = useState<any[]>([]);
  const [companyUsersDropDownData, setCompanyUsersDropDownData] = useState<any[]>([]);

  useEffect(() => {
    setCompanyUsersDropDownData(props.existingReportees);
  }, [props]);

  const onSave = () => {
    const reportees: any[] = [];
    const reporteesObject: any[] = [];

    if (props.id == addUserModalFor.whoIReportTo) {
      for (let i = 0; i < selectedOption.length; i++) {
        reportees.push(selectedOption[i]);
        const reporteeObject = companyUsersDropDownData.find(
          (reportee: any) => reportee.id === selectedOption[i] && reportee.name !== 'Not Assigned'
        );
        reporteesObject.push({ ...reporteeObject, user_name: reporteeObject.name });
      }
      const user_id = {
        reportees: reportees,
        userId: props.userId,
      };
      if (props.setReportees && reporteesObject.length > 0) {
        props.setReportees((prevState: any) => [...prevState, ...reporteesObject]);
      }
      dispatch(addReportsTo(user_id));
    }
  };

  return (
    <Stack>
      <Stack my={2}>
        <Typography variant="h2">Add Reportees</Typography>
      </Stack>
      <Stack>
        <CognisaasMultiSelectWithSearchUid
          shouldBeOpenByDefault={true}
          allOptions={companyUsersDropDownData}
          selectedOption={selectedOption}
          setSelectedOptions={setSelectedOptions}
        />
      </Stack>
      <Stack sx={{ marginTop: '270px' }} spacing={2} justifyContent="flex-end" alignItems="flex-end" direction="row">
        <CognisaasButton clickHandler={props.onClose} isOutlined={true} label="Cancel" />
        <CognisaasButton
          clickHandler={() => {
            onSave();
            props.onClose();
          }}
          isOutlined={false}
          label="Continue"
        />
      </Stack>
    </Stack>
  );
};

export default CommonAddUser;
