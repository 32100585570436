import { apis } from '../axiosInstance';
import { jiraRoutes } from '../route';

import Cookies from 'universal-cookie';

const cookies = new Cookies();

const customer_uid = cookies.get('cogni_customer_uid') as string;

const getJiraStatusByProjectKey = (payload: any) =>
  apis.phpAxiosInstance.post(jiraRoutes.getJiraStatusByProjectKey(), payload, {
    headers: { JWT: 'true' },
  });

const getJiraProductInfoByJiraIssueId = (product_issue_id: any) =>
  apis.phpAxiosInstance.get(jiraRoutes.getProductByIssueId(customer_uid, product_issue_id), {
    headers: { JWT: 'true' },
  });

const getJiraServiceDesks = (payload: any) =>
  apis.phpAxiosInstance.post(jiraRoutes.getJiraServiceDesk(), payload, {
    headers: { JWT: 'true' },
  });

const getJiraJsdServiceRequestList = (payload: any) =>
  apis.phpAxiosInstance.post(jiraRoutes.getJiraJSDprojectRequestList(), payload, {
    headers: { JWT: 'true' },
  });

const getJiraJsdCustomFieldsById = (payload: any) =>
  apis.phpAxiosInstance.post(jiraRoutes.getJiraJsdCustomFields(), payload, {
    headers: { JWT: 'true' },
  });

// getProductByIssueId

export const jiraAPI = {
  getJiraStatusByProjectKey,
  getJiraProductInfoByJiraIssueId,
  getJiraServiceDesks,
  getJiraJsdServiceRequestList,
  getJiraJsdCustomFieldsById,
};
