import { dataTypes } from './DataType';

export const clientEntityStandardFieldsType = {
  // name: dataTypes.link,
  // salesOwner: dataTypes.userName,
  // csOwner: dataTypes.userName,
  // status: dataTypes.rag,
  // segment: dataTypes.string,
  // industry: dataTypes.string,
  // stage: dataTypes.string,
  // accountWorth: dataTypes.string,
  // comment: dataTypes.string,

  name: dataTypes.link,
  segment: dataTypes.string,
  industry: dataTypes.string,
  status: dataTypes.rag,
  salesOwner: dataTypes.userName,
  accountOwner: dataTypes.userName,
  accountWorth: dataTypes.number,
  stage: dataTypes.dropDown,
  createdOn: dataTypes.date,
  renewalDate: dataTypes.date,
  renewalDuration: dataTypes.string,
  lastStatusReportSentTime: dataTypes.date,
  comment: dataTypes.string,
};

export const projectEntityStandardFieldsType = {
  // name: dataTypes.link,
  // progress: dataTypes.progressBar,
  // plannedStartDate: dataTypes.date,
  // startDate: dataTypes.date,
  // etaForDelivery: dataTypes.date,
  // dueDate: dataTypes.date,
  // deliveryDate: dataTypes.date,
  // status: dataTypes.rag,
  // deliveryManager: dataTypes.userName,

  name: dataTypes.link,
  progress: dataTypes.number,
  description: dataTypes.string,
  fee: dataTypes.number,
  arr: dataTypes.number,
  reference: dataTypes.link,
  value: dataTypes.number,
  neededStatus: dataTypes.string,
  status: dataTypes.rag,
  expansionValue: dataTypes.number,
  priority: dataTypes.dropDown,
  plannedStartDate: dataTypes.date,
  startDate: dataTypes.date,
  etaForDelivery: dataTypes.date,
  requiredByDate: dataTypes.date,
  deliveryDate: dataTypes.date,
  createdOn: dataTypes.date,
  stage: dataTypes.dropDown,
  deliveryManager: dataTypes.userName,
  comment: dataTypes.string,
  implementationFeeBilled: dataTypes.number,
  implementationFeePending: dataTypes.number,
};

export const usecaseEntityStandardFieldsType = {
  // name: dataTypes.link,
  // progress: dataTypes.progressBar,
  // plannedStartDate: dataTypes.date,
  // startDate: dataTypes.date,
  // etaForDelivery: dataTypes.date,
  // dueDate: dataTypes.date,
  // deliveryDate: dataTypes.date,
  // status: dataTypes.rag,
  // deliveryManager: dataTypes.userName,

  name: dataTypes.link,
  progress: dataTypes.number,
  description: dataTypes.string,
  fee: dataTypes.number,
  reference: dataTypes.link,
  neededStatus: dataTypes.string,
  status: dataTypes.rag,
  expansionValue: dataTypes.number,
  requiredByDate: dataTypes.date,
  priority: dataTypes.dropDown,
  startDate: dataTypes.date,
  plannedStartDate: dataTypes.date,
  deliveryDate: dataTypes.date,
  createdOn: dataTypes.date,
  stage: dataTypes.dropDown,
  deliveryManager: dataTypes.userName,
  comment: dataTypes.string,
  etaForDelivery: dataTypes.date,
};

export const taskEntityStandardFieldsType = {
  // name: dataTypes.taskPanel,
  // owner: dataTypes.userName,
  // status: dataTypes.rag,
  // stage: dataTypes.string,
  // priority: dataTypes.string,
  // plannedStartDate: dataTypes.date,
  // startDate: dataTypes.date,
  // dueDate: dataTypes.date,
  // deliveryDate: dataTypes.date,
  // effortEstimate: dataTypes.string,
  // timeSpent: dataTypes.string,

  name: dataTypes.link,
  owner: dataTypes.userName,
  type: dataTypes.number, // 0 = client task; 1= team task
  priority: dataTypes.string,
  description: dataTypes.string,
  stage: dataTypes.string,
  status: dataTypes.rag,
  duedate: dataTypes.date,
  closedDate: dataTypes.date,
  startDate: dataTypes.date,
  plannedStartDate: dataTypes.date,
  createdOn: dataTypes.date,
  effortEstimate: dataTypes.number,
  totalTimeSpent: dataTypes.number,
  totalTimeRemaining: dataTypes.number,
  clientCheck: dataTypes.number,
  clientInitiated: dataTypes.number,
  milestoneTask: dataTypes.number,
  csatIndicator: dataTypes.number,
  lastModifiedBy: dataTypes.userName,
  jiraIndicator: dataTypes.number,
  implementationFeePercentage: dataTypes.number,
};
