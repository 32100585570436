import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
// import CognisaasStageSelect from '../CognisaasStageSelect/CognisaasStageSelect';
import { Box, Grid, MenuItem, Select, Stack, Typography } from '@mui/material';
import CognisaasSelect from '../CognisaasSelect/CognisaasSelect';
import CognisaasCalenderNew from '../CognisaasCalenderNew/CognisaasCalenderNew';
// import { taskStage } from '../CognisaasTaskStageIndicator/CognisaasTaskStageIndicator';
import { useEffect } from 'react';
import { getAllEligibleTaskOwnerDetailsOfClient, taskBulkCsatMail, taskBulkUpdate } from 'store/modules/Task/slice';
import { useDispatch } from 'react-redux';
// import { useAppSelector } from 'store/hooks';
// import { selectGetAllEligibleTaskOwnerDetailsOfClient } from 'store/modules/Task/selector';
import format from 'date-fns/format';
import { getProjectInformation, getProjectInformationWithCaseTaskByUid } from 'store/modules/Project/slice';
import { usePreselectedProjectMembers } from '../Hooks/usePreselectedProjectMembers';
import { label_due_date, label_task } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { useAppSelector } from 'store/hooks';
import { selectCustomerDetail } from 'store/modules/Common/selector';
import { getCustomerUid } from 'utils/getUid';
import { updateCaseWithfeatureStatus } from 'store/modules/Common/slice';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import useCustomNames from '../CustomNames/useCustomNames';
import NAMED_CONSTATNTS from '../CustomNames/Constants';

function PaperComponent(props: PaperProps) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

export default function DraggableDialog(props: any) {
  //   const [open, setOpen] = React.useState(false);
  console.log('Update props = ', props);

  const dispatch = useDispatch();
  const customerDetail = useAppSelector(selectCustomerDetail);
  // const taskOwnerDetails = useAppSelector(selectGetAllEligibleTaskOwnerDetailsOfClient);
  const { preSelectedCombinedMembers } = usePreselectedProjectMembers(props.project_uid,props.client_uid);
  // console.log('@BulkUpdate', { props, preselectedProjectMembers, taskOwnerDetails });
  // const [taskOwnerDetailsList, setTaskOwnerDetailsList] = React.useState<any>(null);

  // const validValuesForTaskStage = ['Not Started', 'In Progress', 'Blocked', 'Deferred', 'Done'];
  const [updateInProgress, setUpdateInProgress] = React.useState<boolean>(false);
  const [taskStage, setTaskStage] = React.useState<string>('');
  const [taskOwner, setTaskOwner] = React.useState({
    // task_owner_name: '',
    // task_owner_uid: '',
    user_username: '',
    user_uid: '',
  });
  const [taskActualStartDate, setTaskActualStartDate] = React.useState<Date | undefined | null>(null);
  const [taskDueDate, setTaskDueDate] = React.useState<Date | undefined | null>(null);
  const { taskStagesDropdownValues, getCustomNameMappedValue, getStandardNameMappedValue } = useCustomNames();
  const taskAllStages = taskStagesDropdownValues.map((item: any) => item.custom_stage_name);

  useEffect(() => {
    // console.log('fetching task owner name');
    dispatch(getAllEligibleTaskOwnerDetailsOfClient(props.client_uid));
    setTaskStage('');
    setTaskOwner({
      // task_owner_name: '',
      // task_owner_uid: '',
      user_username: '',
      user_uid: '',
    });
    setTaskActualStartDate(null);
    setTaskDueDate(null);
  }, []);

  // useEffect(() => {
  //   console.log('taskActualStartDate = ', taskActualStartDate);
  // }, [taskActualStartDate]);

  // useEffect(() => {
  //   console.log('taskOwner = ', taskOwner);
  // }, [taskOwner]);

  // useEffect(() => {
  //   console.log('taskDueDate = ', taskDueDate);
  // }, [taskDueDate]);

  //   const handleClickOpen = () => {
  //     props.setOpen(true);
  //   };

  const handleClose = () => {
    props.setOpen(false);
  };

  const showToast = () => {
    const filteredMilestoneTasks = props.allSelectedTasksFullDetails.filter((task: any) => task.milestone_task !== '0');

    if (
      filteredMilestoneTasks.length > 0 &&
      customerDetail?.attachment_mandatory_when_marking_milestone_task_as_done === '1' &&
      taskStage === 'Done'
    ) {
      props.setAlertDetails({
        isVisible: true,
        type: 'failure',
        message: 'Milestone Tasks cannot be updated in bulk.',
      });
    } else {
      props.setAlertDetails({
        isVisible: true,
        type: 'success',
        message: 'Task in bulk updated successfully.',
      });
    }
  };

  const updateTasks = async () => {
    // update all the task out here
    setUpdateInProgress(true);
    console.log('updating tasks', setUpdateInProgress);

    const taskToUpdate = {
      // eslint-disable-next-line quotes
      task_selected: [...props.taskSelected].join("','"),
      // task_selected_details: props.taskSelectedDetails,
      task_stage: taskStage,
      task_actual_start_date: taskActualStartDate != null ? format(taskActualStartDate, 'yyyy-MM-dd HH:mm:ss') : null,
      task_due_date: taskDueDate != null ? format(taskDueDate, 'yyyy-MM-dd HH:mm:ss') : null,
      task_owner: taskOwner.user_uid,
      customer_uid: getCustomerUid(),
    };
    const taskSendCsatMail = {
      task_selected_details: props.taskSelectedDetails,
      task_stage: taskStage,
      task_actual_start_date: taskActualStartDate != null ? format(taskActualStartDate, 'yyyy-MM-dd HH:mm:ss') : null,
      task_due_date: taskDueDate != null ? format(taskDueDate, 'yyyy-MM-dd HH:mm:ss') : null,
      task_owner: taskOwner.user_uid,
    };
    // console.log('taskToUpdate = ', taskToUpdate);
    const filteredMilestoneTasks = props.allSelectedTasksFullDetails.filter((task: any) => task.milestone_task !== '0');
    if (
      filteredMilestoneTasks.length > 0 &&
      customerDetail?.attachment_mandatory_when_marking_milestone_task_as_done === '1' &&
      taskStage === 'Done'
    ) {
      showToast();
    } else {
      await dispatch(taskBulkUpdate(taskToUpdate));
      const data = {
        association_uid: props.association_uid,
        project_uid: props.project_uid,
      };
      await dispatch(getProjectInformation(data)); //patching the details first then sending the mail
      showToast();
    }
    props.setTaskSelected([]);
    // after the task gets updated, close the dialog
    setTaskStage('');
    setTaskOwner({
      // task_owner_name: '',
      // task_owner_uid: '',
      user_username: '',
      user_uid: '',
    });
    setTaskActualStartDate(null);
    setTaskDueDate(null);

    const detail = {
      association_uid: props.association_uid,
      project_uid: props.project_uid,
    };
    await dispatch(getProjectInformation(detail));

    const detail2 = {
      project_uid: props.project_uid,
    };
    await dispatch(getProjectInformationWithCaseTaskByUid(detail2));
    setUpdateInProgress(false);
    props.setOpen(false);
    await dispatch(updateCaseWithfeatureStatus(props.client_uid));
    if (
      !(
        filteredMilestoneTasks.length > 0 &&
        customerDetail?.attachment_mandatory_when_marking_milestone_task_as_done === '1' &&
        taskStage === 'Done'
      )
    ) {
      dispatch(taskBulkCsatMail(taskSendCsatMail));
    }
  };

  const notAssignedStr = 'Not Assigned';
  const notAssignedId = '15243';
  const notAssignedObj = {
    user_uid: notAssignedId,
    user_username: notAssignedStr,
  };
  const taskOwnerDropdownOptionsWithNotAssigned = preSelectedCombinedMembers?.length
    ? [...preSelectedCombinedMembers.filter((item: any) => item.is_active === '1'), notAssignedObj]
    : [notAssignedObj];
  // console.log({ taskOwnerDropdownOptionsWithNotAssigned, taskOwner, preSelectedCombinedMembers });

  const handleOptionChange = (e: any) => {
    const selectedObj = taskOwnerDropdownOptionsWithNotAssigned?.length
      ? taskOwnerDropdownOptionsWithNotAssigned.find((item) => item.user_uid === e.target.value)
      : { user_username: '', user_uid: '' };
    return setTaskOwner(selectedObj);
  };

  return (
    <div>
      <Dialog
        sx={{ zIndex: 1202 }}
        open={props.open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {props.taskSelect?.length} {label_task} Selected
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Box>
              <Grid container>
                <Stack spacing={3} direction="column">
                  <Stack>
                    <Typography variant="subtitle2">{label_task} Stage</Typography>
                    <CognisaasSelect
                      onChange={(e: any) =>
                        setTaskStage(getStandardNameMappedValue(e.target.value, NAMED_CONSTATNTS.task_stage_key))
                      }
                      value={getCustomNameMappedValue(taskStage, NAMED_CONSTATNTS.task_stage_key)}
                      validValues={taskAllStages}
                      width={200}
                    ></CognisaasSelect>
                  </Stack>

                  <Stack>
                    <Typography variant="subtitle2">{label_task} Owner</Typography>
                    {/* <CognisaasSelect
                      onChange={(e: any) => {
                        setTaskOwner({
                          task_owner_name: e.target.value,
                          task_owner_uid: e.target.id,
                        });
                      }}
                      value={taskOwner.task_owner_name}
                      validValues={taskOwnerDetails.map((value, index) => {
                        // setTaskOwner(value);
                        return value.task_owner_name;
                      })}
                      // validValues={taskOwnerDetails}
                    ></CognisaasSelect> */}

                    <Select
                      name="taskOwner"
                      id="taskOwner"
                      size="small"
                      value={taskOwner.user_uid}
                      onChange={handleOptionChange}
                      sx={{ width: '280px' }}
                    >
                      {taskOwnerDropdownOptionsWithNotAssigned.map((optionObj: any) => {
                        return (
                          <MenuItem key={optionObj.user_uid} value={optionObj.user_uid}>
                            {optionObj.user_username}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Stack>

                  <Stack>
                    <Typography variant="subtitle2">Actual Start Date</Typography>
                    <Stack className="customDatePickerWidth">
                      <CognisaasCalenderNew
                        // label="Helper text example"
                        value={taskActualStartDate}
                        handleChange={(date: Date) => setTaskActualStartDate(date)}
                      />
                    </Stack>
                  </Stack>

                  <Stack>
                    <Typography variant="subtitle2">{label_due_date}</Typography>
                    <Stack className="customDatePickerWidth">
                      <CognisaasCalenderNew value={taskDueDate} handleChange={(date: Date) => setTaskDueDate(date)} />
                    </Stack>
                  </Stack>
                </Stack>
              </Grid>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          {updateInProgress ? (
            <LoadingButton loading loadingPosition="start" startIcon={<SaveIcon />} variant="outlined">
              Update
            </LoadingButton>
          ) : (
            <Button variant="contained" onClick={updateTasks}>
              Update
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
