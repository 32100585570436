import { useState, useEffect } from 'react';
import { Box, Typography, Stack, Avatar } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import CognisaasTabs from '../../shared/CognisaasTabs';
import { useAppSelector } from '../../../store/hooks';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { fetchOpenUsecases } from '../../../store/modules/Reports/OpenUsecases/slice';
import { useDispatch } from 'react-redux';
import { selectOpenUsecases, selectOpenUsecasesStatus } from '../../../store/modules/Reports/OpenUsecases/selector';
import {
  selectClosedUsecases,
  selectClosedUsecaseStatus,
} from '../../../store/modules/Reports/ClosedUsecases/selector';
import { fetchClosedUsecases } from '../../../store/modules/Reports/ClosedUsecases/slice';
import { requestState } from '../../../store/modules/types';
import ReportsHeader, { ReportsPages } from '../ReportsHeader/ReportsHeader';
import CognisaasLoader from '../../shared/CognisaasLoader/CognisaasLoader';
import {
  DataGridPremium,
  GridToolbar,
  gridClasses,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridColumnOrderChangeParams,
} from '@mui/x-data-grid-premium';
// import { Container } from './Usecases.styles';
import { selectAllChartsConfig } from '../../../store/modules/Charts/selector';
// import { ReactComponent as NoRecords } from '../../../assets/svgs/no_records.svg';
import ChartView from '../../shared/Charts/ChartView/ChartView';
import CustomiseDrawer from '../../shared/Charts/CustomiseDrawer/CustomiseDrawer';
import { selectUsecaseCustomFields, selectUsecaseCustomfieldsStatus } from '../../../store/modules/Usecase/selector';

import { graphSchema } from '../../shared/Charts/CustomiseDrawer/types';
import { getGraphConfigurations } from '../../../store/modules/Charts/slice';
import {
  formatCurrency,
  generateDataWhileMappingCustomFieldsAsNormalFields,
  generateMappedCustomFieldKey,
  generateOrderedColumn,
  isNull,
  isUndefined,
} from '../../../utils/dataHelpers';

import { availableDateRangeOptions, FilterableData, generateOptionsForThisDataKey } from 'utils/filteringFunctions';
import { addFilterGroup, updateFilter } from 'store/modules/AllFilters/slice';
import { selectAllFilters } from 'store/modules/AllFilters/selector';
import { generateTableColumnsWithCustomFiltersMapped } from 'components/shared/Tables/CogniTable';
import FilterTypeSelector from 'components/shared/CognisaasFilters/FilterTypeSelector/FilterTypeSelector';
import SelectedFilterList from 'components/shared/CognisaasFilters/SelectedFilterList/SelectedFilterList';
import { getAllUsecaseCustomFields, updateUsecaseByUid, updateUsecaseCustomField } from 'store/modules/Usecase/slice';
import {
  selectAllGeneralStatus,
  selectAllIndustries,
  selectJourneyStage,
  selectSegmentValues,
  selectUsersOfCompany,
  selectUsersOfCompanyStatus,
} from 'store/modules/Common/selector';
import {
  fetchAllIndustries,
  fetchAllJourneyStages,
  getAllSegmentValues,
  getAllUsers,
} from 'store/modules/Common/slice';
import Cookies from 'universal-cookie';
import { selectCustomerDetails } from 'store/modules/Settings/selector';
import { getCustomerDetails } from 'store/modules/Settings/slice';
import ViewDate, { ViewDateTooltip } from 'components/shared/ViewDate/ViewDate';
import {
  label_client,
  label_delivery_date,
  label_due_date,
  label_project,
  label_use_case,
  label_use_case_delivery_manager,
} from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { SelectEditDateInputCell, SelectEditDropdownCell } from 'components/shared/Tables/InlineEditTable';
import CognisaasToast from 'components/shared/CognisaasToast/CognisaasToast';
import { getUserLogoLinkByUid, getUserUid } from 'utils/getUid';
import { default as AvatarImage } from 'components/shared/Avatar/Avatar';
import CognisaaasTooltip from 'components/shared/CognisaasTooltip/CognisaasTooltip';
import { differenceInDays, format, formatDistance } from 'date-fns';
import { CustomizedProgressBars } from 'components/shared/CognisaasProgressBar/ProgressBar';
import {
  getDaysRemainingHeader,
  getUsecaseAgeingHeader,
  getUsecaseDaysRemainingWithColorCell,
  formatUsecaseDaysRemainingField,
} from 'components/shared/Tables/TableCells';
import NAMED_CONSTATNTS from 'components/shared/CustomNames/Constants';
import useCustomNames from 'components/shared/CustomNames/useCustomNames';
import { fetchClientList } from 'store/modules/ManageClient/ClientList/slice';
import { selectClientList } from 'store/modules/ManageClient/ClientList/selector';
import checkAccessForRouting from 'components/shared/Hooks/checkAccessForRouting';
import useCommonAppConfig from 'components/Common/useCommonAppConfig';
import { getUserActiveState, INACTIVE_USER_COLOR } from 'utils/userHelpers';
import { checkFreezePlannedDate } from 'utils/checkFreezePlannedDate';
import { getMaxColumnWidth } from 'utils/getMaxColumnWidth';

import { makeDate } from 'components/ProjectModule/utils';
import { handleProjectPlannedDateUpdate } from 'store/modules/Project/slice';
const cookies = new Cookies();

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGridPremium)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
        },
      },
    },
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: 600,
  width: '100%',
  background: 'white',
  margin: '8px 0px',
  borderRadius: '4px',
  boxShadow: '0px 4px 26px rgba(0, 0, 0, 0.05)',
  '& .MuiFormGroup-options': {
    alignItems: 'center',
    paddingBottom: theme.spacing(1),
    '& > div': {
      minWidth: 100,
      margin: theme.spacing(2),
      marginLeft: 0,
    },
  },
}));

function stringToHslColor(str: string, s: number, l: number) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const h = hash % 360;
  return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToHslColor(name, 30, 80),
    },
    children: (
      <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#000000' }}>
        {name.split(' ')[0][0]}
        {name.indexOf(' ') >= 0 ? name.split(' ')[1][0] : null}
      </Typography>
    ),
  };
}

interface LocationParams {
  pathname: string;
  state: any;
  search: string;
  hash: string;
  key: string;
}

const Usecases = () => {
  const dispatch = useDispatch();
  const location: LocationParams = useLocation();
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState(0);
  const tabs = ['All', `Open ${label_use_case}s`, `Closed ${label_use_case}s`];
  const [idName, setIdName] = useState<string>('');
  const [usersOfCompany, setUsersOfCompany] = useState<any[]>([]);

  /* used to prevent loading page while inline editing custom fields(e.g if we edit custom value it will trigger page reload this will prevent it.) */
  const [customFieldStatus, setCustomFieldStatus] = useState<boolean>(false);

  const allOpenUseCases = useAppSelector(selectOpenUsecases);

  const [openUsecases, setOpenUseCases] = useState<any>([]);
  useEffect(() => {
    if (allOpenUseCases.length != 0) {
      const filterUsecases = allOpenUseCases.filter((element: any) => element.case_stage != 'On Hold');
      console.log('filter', filterUsecases);
      setOpenUseCases(filterUsecases);
    }
  }, [allOpenUseCases]);

  const openUsecasesState = useAppSelector(selectOpenUsecasesStatus);
  const closedUsecases = useAppSelector(selectClosedUsecases);
  const closedUsecasesState = useAppSelector(selectClosedUsecaseStatus);
  const users = useAppSelector(selectUsersOfCompany);

  const allFilters = useAppSelector(selectAllFilters);
  const usecaseCustomFields = useAppSelector(selectUsecaseCustomFields);
  const usecaseCustomFieldsStatus = useAppSelector(selectUsecaseCustomfieldsStatus);
  const allUsers = useAppSelector(selectUsersOfCompany);
  const allUsersStatus = useAppSelector(selectUsersOfCompanyStatus);
  const allSegments = useAppSelector(selectSegmentValues);
  const journeyStages = useAppSelector(selectJourneyStage);
  const allIndustries = useAppSelector(selectAllIndustries);
  const clientList = useAppSelector(selectClientList);
  const allGeneralStatus = useAppSelector(selectAllGeneralStatus);
  // const allCategories = useAppSelector(selectAllCategories);

  const [cfMappedOpenUsecases, setCfMappedOpenUsecases] = useState<any[]>([]);
  const [cfMappedClosedUsecases, setCfMappedClosedUsecases] = useState<any[]>([]);
  const [cfMappedAllUsecases, setCfMappedAllUsecases] = useState<any[]>([]);

  const [filteredUsecases, setFilteredUsecases] = useState<any[]>([]);
  const [cfMappedTableColumns, setCfMappedTableColumns] = useState<any[]>([]);

  const [drawerState, setDrawerState] = useState<boolean>(false);
  const graphConfigs: graphSchema[] = useAppSelector(selectAllChartsConfig);
  const graphs: graphSchema[] = graphConfigs.filter((graph: graphSchema) => graph.idName === idName);
  const customerDetail = useAppSelector(selectCustomerDetails).data;

  const [graphData, setGraphData] = useState<any>({});
  const [formActionType, setFormActionType] = useState<'save' | 'update'>('save');

  // const [filterOpenModel, setFilterOpenModel] = useLocalStorage<GridFilterModel>('usecaseOpenFilters', {
  //   items: [],
  // } as GridFilterModel);
  const [countCameFromChartClick, setCountCameFromChartClick] = useState<number>(-1);
  const [alertDetails, setAlertDetails] = useState<{
    isVisible: boolean;
    type: 'success' | 'failure';
    message: string;
  }>({ isVisible: false, type: 'success', message: '' });
  const uniqueColumnId = 'usecaseReportListTable';
  // const [allUsecasesPageSize, setAllUsecasesPageSize] = useState<number>(10);
  // const [openUsecasesPageSize, setOpenUsecasesPageSize] = useState<number>(10);
  // const [closedUsecasesPageSize, setClosedUsecasesPageSize] = useState<number>(10);
  const {
    displayDataWithCustomNames,
    usecaseCategoryDropdownValues,
    getStandardNameMappedValue,
    usecaseStagesDropdownValues,
    getStandardRowDataWithoutCustomNames,
    usecasePrioritiesDropdownValues,
  } = useCustomNames();
  const customUsecaseCategories = usecaseCategoryDropdownValues.map((item: any) => item.custom_category_name);
  const standardUsecaseCategories = usecaseCategoryDropdownValues.map((item: any) => item.category_name);
  const standardUsecaseStages = usecaseStagesDropdownValues.map((item: any) => item.stage_name);
  const customUseCaseStages = usecaseStagesDropdownValues.map((item: any) => item.custom_stage_name);
  const customUseCasePriorities = usecasePrioritiesDropdownValues.map((item: any) => item.custom_priority_name);

  let myTableStateForAllUsecaseTab: any = {};
  let myTableStateForOpenUsecaseTab: any = {};
  let myTableStateForClosedUsecaseTab: any = {};
  const { saveAppTableState, getTableState } = useCommonAppConfig();
  let initialStateAllUsecases: any = {
    columns: {
      columnVisibilityModel: {},
    },
    pinnedColumns: {
      left: ['case_name'],
    },
    pagination: {
      page: 0,
      pageSize: 10,
    },
    filter: {},
    ...getTableState('projectReportAllUsecasePage'),
  };

  const initialStateOpenUsecases = {
    columns: {
      columnVisibilityModel: {},
    },
    pinnedColumns: {
      left: ['case_name'],
    },
    pagination: {
      page: 0,
      pageSize: 10,
    },
    ...getTableState('projectReportOpenUsecasePage'),
  };

  const initialStateClosedUsecases = {
    columns: {
      columnVisibilityModel: {},
    },
    pinnedColumns: {
      left: ['case_name'],
    },
    pagination: {
      page: 0,
      pageSize: 10,
    },
    ...getTableState('projectReportClosedUsecasePage'),
  };

  const handleTableSave = (idName: string, thisTableState: any) => {
    saveAppTableState(idName, thisTableState);
  };

  useEffect(() => {
    const customer_uid = cookies.get('cogni_customer_uid');
    const user_uid = cookies.get('cogni_user_uid');

    dispatch(getCustomerDetails());
    dispatch(fetchOpenUsecases());
    dispatch(fetchClosedUsecases());
    dispatch(getAllUsers());
    dispatch(
      getGraphConfigurations({
        type: 'usecase',
        customer_uid,
        user_uid,
      })
    );
    dispatch(fetchClientList());
    dispatch(getAllUsecaseCustomFields());
  }, []);

  useEffect(() => {
    if (users.length > 0) {
      const data = users.filter((user: any) => user.is_active === '1').map((user: any) => user.user_username);
      data.unshift('Not Assigned');
      setUsersOfCompany([...data]);
    }
  }, [users]);

  useEffect(() => {
    if (allUsersStatus === 'idle' || allUsersStatus === 'failed') {
      dispatch(getAllUsers());
    }
    if (allSegments.status === 'idle' || allSegments.status === 'failed') {
      dispatch(getAllSegmentValues());
    }

    if (journeyStages.status === 'idle' || allSegments.status === 'failed') {
      dispatch(fetchAllJourneyStages());
    }

    if (allIndustries.status === 'idle' || allIndustries.status === 'failed') {
      dispatch(fetchAllIndustries());
    }
  }, [allUsersStatus, allSegments, journeyStages, allIndustries]);

  useEffect(() => {
    if (selectedTab === 0) {
      setIdName('reportsPageAllUsecases');
      setFilteredUsecases(cfMappedAllUsecases);
      if (allFilters['reportsUsecasesModulePage']) {
        const newFilteredData = new FilterableData(cfMappedAllUsecases);
        newFilteredData.bulkFilter(allFilters['reportsUsecasesModulePage'].appliedFilters);
        const formattedNewFilteredData = formatUsecaseDaysRemainingField(newFilteredData.toArray());
        setFilteredUsecases(formattedNewFilteredData);
        if (countCameFromChartClick >= 0) {
          if (countCameFromChartClick !== formattedNewFilteredData.length) {
            setAlertDetails({
              isVisible: true,
              message: `You can't see all ${label_use_case}s of your reportees as role not properly configured in RBAC, please contact admin.`,
              type: 'failure',
            });
          }
          setCountCameFromChartClick(-1);
        }
      }
    } else if (selectedTab === 1) {
      setIdName('reportsPageOpenUsecases');
      setFilteredUsecases(cfMappedOpenUsecases);
      if (allFilters['reportsUsecasesModulePage']) {
        const newFilteredData = new FilterableData(cfMappedOpenUsecases);
        newFilteredData.bulkFilter(allFilters['reportsUsecasesModulePage'].appliedFilters);
        const formattedNewFilteredData = formatUsecaseDaysRemainingField(newFilteredData.toArray());
        setFilteredUsecases(formattedNewFilteredData);
      }
    } else if (selectedTab === 2) {
      setIdName('reportsPageClosedUsecases');
      setFilteredUsecases(cfMappedClosedUsecases);
      if (allFilters['reportsUsecasesModulePage']) {
        const newFilteredData = new FilterableData(cfMappedClosedUsecases);
        newFilteredData.bulkFilter(allFilters['reportsUsecasesModulePage'].appliedFilters);
        const formattedNewFilteredData = formatUsecaseDaysRemainingField(newFilteredData.toArray());
        setFilteredUsecases(formattedNewFilteredData);
      }
    }
  }, [allFilters, selectedTab, cfMappedAllUsecases]);

  useEffect(() => {
    if (openUsecasesState === 'success') {
      setCfMappedOpenUsecases(
        generateDataWhileMappingCustomFieldsAsNormalFields(
          [...openUsecases],
          'custom_values',
          'field_name',
          'field_value',
          'field_uid',
          'field_type'
        )
      );
    }

    if (closedUsecasesState === 'success') {
      setCfMappedClosedUsecases(
        generateDataWhileMappingCustomFieldsAsNormalFields(
          [...closedUsecases],
          'custom_values',
          'field_name',
          'field_value',
          'field_uid',
          'field_type'
        )
      );
    }

    if (openUsecasesState === 'success' && closedUsecasesState === 'success') {
      setCfMappedAllUsecases(
        generateDataWhileMappingCustomFieldsAsNormalFields(
          [...openUsecases, ...closedUsecases],
          'custom_values',
          'field_name',
          'field_value',
          'field_uid',
          'field_type'
        )
      );

      // intially for first render set filtered data to be open usecases
      setFilteredUsecases(
        generateDataWhileMappingCustomFieldsAsNormalFields(
          [...openUsecases, ...closedUsecases],
          'custom_values',
          'field_name',
          'field_value',
          'field_uid',
          'field_type'
        )
      );
    }
  }, [openUsecasesState, closedUsecasesState, openUsecases, closedUsecases]);

  const handleClientAccess = (params: GridRenderCellParams) => {
    const arrayToCheck = clientList.map((ele) => ele.client_uid);
    const hasAccessToClient = checkAccessForRouting({ arrayToCheck: arrayToCheck, uid: params.row.client_uid });
    console.log(params.value);
    if (hasAccessToClient == true) {
      return <Link to={getClient(params.row)}>{params.value}</Link>;
    }
    if (hasAccessToClient == false) {
      return (
        <CognisaaasTooltip
          title={<Typography sx={{ padding: '5px' }}>You do not have access to this {label_client}</Typography>}
        >
          <Typography variant="subtitle1">{params.value}</Typography>
        </CognisaaasTooltip>
      );
    }
  };

  const generateCommentUi = (comment: string) => {
    const splitForUsers = comment.split('@[');
    return splitForUsers.map((item, index) => {
      if (index === 0) {
        return <span key={index}>{item}</span>;
      } else {
        // index more than 0 something like this expected => vivek](customer:324234) great
        const strContainingUser = item;
        const arrContainingUser = strContainingUser.split('](');
        const username = arrContainingUser[0];
        const restStrArr = arrContainingUser[1].split(')');
        const customerUid = restStrArr[0];
        const restString = [...restStrArr.filter((item, index) => index > 0)].join('');
        return (
          <span key={index}>
            &nbsp;
            <span
              style={{
                backgroundColor: 'rgb(45, 96, 246, 0.1)',
                color: '#2D60F6',
                padding: '2px 10px',
                borderRadius: '33px',
                fontSize: '13px',
                cursor: 'pointer',
              }}
              id={customerUid}
            >
              {username}
            </span>
            {restString}
          </span>
        );
      }
    });
  };

  const generateCommentText = (comment: string): string => {
    const splitForUsers = comment.split('@[');
    const finalCommentStrArr = splitForUsers.map((item, index) => {
      if (index === 0) {
        return item;
      } else {
        const strContainingUser = item;
        const arrContainingUser = strContainingUser.split('](');
        const username = arrContainingUser[0];
        const restStrArr = arrContainingUser[1].split(')');
        const restString = [...restStrArr.filter((item, index) => index > 0)].join('');
        return `${username} ${restString}`;
      }
    });
    return finalCommentStrArr.join(' ');
  };

  const columns = [
    {
      headerName: `${label_use_case} Name`,
      field: 'case_name',
      width: 240,
      renderCell: (params: GridRenderCellParams) => (
        <Typography
          className="cognisaas-pointer"
          variant="subtitle1"
          sx={{ color: '#2D60F6', padding: '7px 0' }}
          onClick={() =>
            navigate(
              `/projects/project/${params.row.association_uid}/${params.row.project_uid}/${params.row.case_uid}`,
              { state: { clientUid: params.row.client_uid, clientName: params.row.client_name } }
            )
          }
        >
          {params.value}
        </Typography>
      ),
    },
    {
      headerName: 'Status',
      field: 'case_readinessstate_status',
      width: 240,
      renderCell: (params: GridRenderCellParams) => (
        <Stack style={{ padding: '7px 0' }}>
          <Stack spacing={1} direction="row">
            <span
              style={{ marginRight: '4px' }}
              className={
                params.value == 'red'
                  ? 'red_status_box_task'
                  : params.value == 'amber'
                  ? 'amber_status_box_task'
                  : params.value == 'green'
                  ? 'green_status_box_task'
                  : params.value == 'blue'
                  ? 'blue_status_box_task'
                  : ''
              }
            ></span>
            {params.value == 'red'
              ? 'Overdue'
              : params.value == 'amber'
              ? 'At Risk'
              : params.value == 'green'
              ? 'On Track'
              : params.value == 'blue'
              ? 'Completed'
              : ''}
          </Stack>
        </Stack>
      ),
    },
    {
      headerName: label_client,
      field: 'client_name',
      width: 240,
      renderCell: (params: GridRenderCellParams) => handleClientAccess(params),
    },
    {
      headerName: `${label_project} name`,
      field: 'project_name',
      width: 240,
      renderCell: (params: any) => (
        <Typography
          variant="subtitle1"
          className="cognisaas-pointer"
          sx={{ color: '#2D60F6' }}
          onClick={() => navigate(`/projects/project/${params.row.association_uid}/${params.row.project_uid}`)}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      headerName: 'Category',
      field: 'case_needed_status',
      width: getMaxColumnWidth(customUsecaseCategories),
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams) =>
        SelectEditDropdownCell(params, customUsecaseCategories || [], handleInlineEditValue),
    },
    {
      headerName: 'Stage',
      field: 'case_stage',
      width: getMaxColumnWidth(customUseCaseStages),
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams) =>
        SelectEditDropdownCell(params, customUseCaseStages || [], handleInlineEditValue),
    },
    {
      headerName: label_use_case_delivery_manager,
      field: 'case_deliverymanager',
      width: getMaxColumnWidth(usersOfCompany),
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams) =>
        SelectEditDropdownCell(params, usersOfCompany || [], handleInlineEditValue),
      renderCell: (params: GridRenderCellParams) => (
        <Stack direction="row" style={{ padding: '7px 0' }}>
          <Stack spacing={1} direction={'row'} alignItems={'center'}>
            <Avatar style={{ height: '34px', width: '34px', marginTop: '3px' }} {...stringAvatar(params.value)} />
            {getUserActiveState(getUserUid(params.value), users) === '0' ? (
              <Typography style={{ color: INACTIVE_USER_COLOR }} mt={1} variant="subtitle1">
                {params.value}&nbsp;(Inactive)
              </Typography>
            ) : (
              <Typography mt={1} variant="subtitle1">
                {params.value}
              </Typography>
            )}
          </Stack>
        </Stack>
      ),
    },
    {
      field: 'usecase_progress',
      headerName: 'Progress (in %)',
      width: 260,
      renderCell: (params: GridRenderCellParams) => (
        <CustomizedProgressBars
          value={isNaN(params.row.usecase_progress) ? 0 : params.row.usecase_progress}
          statusColor={'grees'}
          hidePercentage={false}
        />
      ),
    },
    {
      field: 'case_age',
      renderHeader: () => getUsecaseAgeingHeader(),
      width: 240,
      renderCell: (params: GridRenderCellParams) => (
        <Stack style={{ padding: '7px 0' }}>
          <Stack spacing={1} direction="row">
            {params.value ? `${params.value} Days` : 'Not Started'}
          </Stack>
        </Stack>
      ),
    },
    {
      field: 'case_days_remaining',
      renderHeader: () => getDaysRemainingHeader(),
      width: 240,
      renderCell: (params: GridRenderCellParams) => getUsecaseDaysRemainingWithColorCell(params),
    },
    {
      headerName: 'Priority',
      field: 'case_priority',
      width: 240,
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams) =>
        SelectEditDropdownCell(params, customUseCasePriorities || [], handleInlineEditValue),
    },
    // {
    //   headerName: 'Revenue Impact',
    //   field: 'client_account_worth',
    //   width: 200,
    //   renderCell: (params: any) => {
    //     return formatCurrency(params.row[params.field], customerDetail?.currency_code);
    //   },
    // },
    {
      headerName: 'Implementation fee',
      width: 200,
      field: 'case_fees',
      renderCell: (params: any) => {
        if (isNull(params?.row[params.field])) {
          return formatCurrency(0, customerDetail?.currency_code);
        } else if (!isNull(params?.row[params.field]) && params?.row[params.field].length > 0) {
          return formatCurrency(params.row[params.field], customerDetail?.currency_code);
        } else {
          return <>-</>;
        }
      },
    },
    {
      headerName: 'Description',
      field: 'case_description',
      width: 240,
    },
    {
      field: 'case_planned_startdate',
      headerName: 'Planned Start Date',
      width: 200,
      editable: true,
      type: 'date',
      // valueGetter: ({ value }: any) => value && new Date(value),
      renderCell: (params: GridRenderCellParams) => {
        return checkFreezePlannedDate(
          customerDetail?.freeze_planned_date_usecase == '1',
          params.row?.is_case_started
        ) ? (
          <ViewDateTooltip
            date={params.row?.case_planned_startdate}
            text={`Planned start date cannot be changed once the ${label_use_case.toLowerCase()} has been started. Contact an admin if you want to change the planned start date.`}
          />
        ) : (
          <ViewDate date={params.row?.case_planned_startdate} />
        );
      },
      renderEditCell: (params: GridRenderEditCellParams) =>
        !checkFreezePlannedDate(customerDetail?.freeze_planned_date_usecase == '1', params.row?.is_case_started) ? (
          SelectEditDateInputCell(params, handleInlineEditValue)
        ) : (
          <ViewDate date={params.row?.case_planned_startdate} />
        ),
    },
    {
      headerName: 'Start Date',
      field: 'case_startdate',
      width: 200,
      editable: true,
      type: 'date',
      // valueGetter: ({ value }: any) => value && new Date(value),
      renderCell: (params: GridRenderCellParams) => {
        return <ViewDate date={params.row.case_startdate} />;
      },
      renderEditCell: (params: GridRenderEditCellParams) => SelectEditDateInputCell(params, handleInlineEditValue),
    },
    {
      headerName: label_due_date,
      field: 'case_requiredbydate',
      width: 200,
      editable: true,
      type: 'date',
      // valueGetter: ({ value }: any) => value && new Date(value),
      renderCell: (params: GridRenderCellParams) => {
        return checkFreezePlannedDate(
          customerDetail?.freeze_planned_date_usecase == '1',
          params.row?.is_case_started
        ) ? (
          <ViewDateTooltip
            date={params.row?.case_requiredbydate}
            text={`${label_due_date} cannot be changed once the ${label_use_case.toLowerCase()} has been started. Contact an admin if you want to change the ${label_due_date.toLowerCase()}.`}
          />
        ) : (
          <ViewDate date={params.row?.case_requiredbydate} />
        );
      },
      renderEditCell: (params: GridRenderEditCellParams) =>
        !checkFreezePlannedDate(customerDetail?.freeze_planned_date_usecase == '1', params.row?.is_case_started) ? (
          SelectEditDateInputCell(params, handleInlineEditValue)
        ) : (
          <ViewDate date={params.row?.case_requiredbydate} />
        ),
    },
    {
      headerName: 'Estimated Delivery Date',
      field: 'eta_forcasedelivery',
      width: 200,
      editable: true,
      type: 'date',
      // valueGetter: ({ value }: any) => value && new Date(value),
      renderCell: (params: GridRenderCellParams) => {
        return <ViewDate date={params.row.eta_forcasedelivery} />;
      },
      renderEditCell: (params: GridRenderEditCellParams) => SelectEditDateInputCell(params, handleInlineEditValue),
    },
    {
      field: 'case_deliverydate',
      hide: false,
      headerName: label_delivery_date,
      width: 180,
      editable: false,
      type: 'date',
      renderCell: (params: GridRenderCellParams) => {
        return <ViewDate date={params.row.case_deliverydate} />;
      },
      renderEditCell: (params: GridRenderEditCellParams) => SelectEditDateInputCell(params, handleInlineEditValue),
    },
    {
      field: 'recent_comment',
      headerName: 'Comment',
      width: 240,
      renderCell: (params: GridRenderCellParams) => (
        <>
          {isUndefined(params.row.recent_comment.comment) ? (
            ''
          ) : (
            <CognisaaasTooltip
              title={
                <Stack sx={{ padding: '16px', width: '240px', maxHeight: 200, overflow: 'scroll' }}>
                  <Stack>
                    <Stack direction={'row'} justifyContent={'space-between'}>
                      {/* user avatar and name */}
                      <Stack direction={'row'} alignItems={'center'}>
                        {getUserLogoLinkByUid(params.row.recent_comment.user_uid).length > 0 ? (
                          <AvatarImage
                            src={getUserLogoLinkByUid(params.row.recent_comment.user_uid)}
                            alt="user profile"
                          />
                        ) : (
                          <Avatar
                            style={{ height: '40px', width: '40px', marginTop: '3px' }}
                            {...stringAvatar(params.row.recent_comment.user_username)}
                          />
                        )}
                        <Typography ml={1} variant={'h3'}>
                          {params.row.recent_comment.user_username}
                        </Typography>
                      </Stack>

                      <Typography className="label-text" variant="subtitle1" style={{ fontSize: '12px' }}>
                        {formatDistance(new Date(params.row.recent_comment.created_on.replace(/-/g, '/')), new Date(), {
                          includeSeconds: true,
                        })}
                        <br />
                        ago
                      </Typography>
                    </Stack>
                    <Typography mt={1} variant={'subtitle1'} sx={{ whiteSpace: 'pre-line' }}>
                      {generateCommentUi(params.row.recent_comment.comment)}
                    </Typography>
                  </Stack>
                </Stack>
              }
            >
              <Typography variant={'subtitle1'}>
                {generateCommentText(params.row.recent_comment.comment).length > 30
                  ? generateCommentText(params.row.recent_comment.comment).slice(0, 30).trim() + '...'
                  : generateCommentText(params.row.recent_comment.comment)}
              </Typography>
            </CognisaaasTooltip>
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    if (cfMappedOpenUsecases.length > 0 || cfMappedClosedUsecases.length > 0) {
      const allUsecasesData = [...cfMappedClosedUsecases, ...cfMappedOpenUsecases];

      if (usecaseCustomFieldsStatus === 'success') {
        if (
          allUsersStatus === 'success' &&
          allSegments.status == 'success' &&
          journeyStages.status == 'success' &&
          allIndustries.status == 'success'
        ) {
          const defaultProjectFilters = [
            {
              name: label_use_case_delivery_manager,
              dataKey: 'case_deliverymanager',
              isSelected: false,
              equalToValue: [],
              canBeCleared: true,
              availableOptions: allUsers.map((item: any) => item.user_username),
            },
            {
              name: `${label_client} Name`,
              dataKey: 'client_name',
              isSelected: false,
              equalToValue: [],
              canBeCleared: true,
              availableOptions: generateOptionsForThisDataKey(allUsecasesData, 'client_name'),
            },
            {
              name: 'Category',
              dataKey: 'case_needed_status',
              isSelected: false,
              equalToValue: [],
              canBeCleared: true,
              availableOptions: standardUsecaseCategories,
            },
            {
              name: 'Status',
              dataKey: 'case_readinessstate_status',
              isSelected: false,
              equalToValue: [],
              canBeCleared: true,
              availableOptions: allGeneralStatus.data,
            },
            {
              name: `${label_use_case} Stage`,
              dataKey: 'case_stage',
              isSelected: false,
              equalToValue: [],
              canBeCleared: true,
              availableOptions: standardUsecaseStages,
            },
            {
              name: 'Start date$$date-range-picker',
              dataKey: 'case_startdate',
              isSelected: false,
              equalToValue: [],
              canBeCleared: true,
              availableOptions: availableDateRangeOptions,
            },
            {
              name: label_due_date + '$$date-range-picker',
              dataKey: 'case_requiredbydate',
              isSelected: false,
              equalToValue: [],
              canBeCleared: true,
              availableOptions: availableDateRangeOptions,
            },
          ];

          const cfUsecases: any[] = [];

          usecaseCustomFields.forEach((cf) => {
            if (['RAG status', 'Dropdown'].includes(cf.field_type)) {
              cfUsecases.push({
                name: cf.field_name,
                dataKey: generateMappedCustomFieldKey(cf.field_name, cf.field_uid, cf.field_type),
                isSelected: false,
                equalToValue: [],
                canBeCleared: true,
                availableOptions: cf.valid_values.split(','),
              });
            } else if (cf.field_type === 'Date') {
              cfUsecases.push({
                name: `${cf.field_name}$$date-range-picker`,
                dataKey: generateMappedCustomFieldKey(cf.field_name, cf.field_uid, cf.field_type),
                isSelected: false,
                equalToValue: [],
                canBeCleared: true,
                availableOptions: availableDateRangeOptions,
              });
            }
          });

          if (isUndefined(allFilters['reportsUsecasesModulePage']) || isNull(allFilters['reportsUsecasesModulePage'])) {
            dispatch(
              addFilterGroup({
                idName: 'reportsUsecasesModulePage',
                appliedFilters: [...defaultProjectFilters, ...cfUsecases],
              })
            );
          }
        }
      }

      if (usecaseCustomFieldsStatus === 'success' && usecaseCustomFields.length > 0 && users.length > 0) {
        setCfMappedTableColumns(
          generateOrderedColumn(
            [
              ...generateTableColumnsWithCustomFiltersMapped(
                allUsecasesData[0],
                columns,
                'Usecase',
                usecaseCustomFields,
                handleInlineEditCustomValue,
                users
              ),
            ],
            uniqueColumnId
          )
        );
      } else {
        setCfMappedTableColumns(columns);
      }
    }
  }, [
    cfMappedClosedUsecases,
    cfMappedOpenUsecases,
    usecaseCustomFields,
    allUsersStatus,
    allSegments,
    journeyStages,
    allIndustries,
    users,
  ]);

  useEffect(() => {
    if (!isNull(location.state)) {
      if (
        !isNull(location.state.user) &&
        !isNull(location.state.label) &&
        !isUndefined(allFilters['reportsUsecasesModulePage']) &&
        !isNull(allFilters['reportsUsecasesModulePage'])
      ) {
        // console.log('usecaseLocation', location.state.value);
        dispatch(
          updateFilter({
            idName: 'reportsUsecasesModulePage',
            updatedFilter: {
              name: 'Status',
              dataKey: 'case_readinessstate_status',
              isSelected: true,
              equalToValue: location.state.label ? [location.state.label] : [],
              canBeCleared: true,
              availableOptions: allGeneralStatus.data,
            },
          })
        );
        if (location.state.reportees) {
          dispatch(
            updateFilter({
              idName: 'reportsUsecasesModulePage',
              updatedFilter: {
                name: label_use_case_delivery_manager,
                dataKey: 'case_deliverymanager',
                isSelected: true,
                equalToValue: [...location.state.reportees.map((ele: any) => ele.user_name)],
                canBeCleared: true,
                availableOptions: allUsers.map((item: any) => item.user_username),
              },
            })
          );
          setCountCameFromChartClick(parseInt(location.state.value));
        } else if (location.state.user) {
          dispatch(
            updateFilter({
              idName: 'reportsUsecasesModulePage',
              updatedFilter: {
                name: label_use_case_delivery_manager,
                dataKey: 'case_deliverymanager',
                isSelected: true,
                equalToValue: location.state.user ? [location.state.user] : [],
                canBeCleared: true,
                availableOptions: allUsers.map((item: any) => item.user_username),
              },
            })
          );
        }

        setSelectedTab(0);

        location.state = null;
      }
    }
  }, [location, allFilters, cfMappedAllUsecases]);

  // Handlers

  function handleTabSelection(event: any, tabIndex: number) {
    setSelectedTab(tabIndex);
  }

  const getClient = (row: any) => {
    return `/client-list/client/${row.client_uid}`;
  };

  function getTableHeader(selectedTab: number) {
    return (
      <Stack direction={'row'} justifyContent={'space-between'}>
        <CognisaasTabs
          size="squeezed"
          selectedTabIndex={selectedTab}
          handleTabSwitch={handleTabSelection}
          tabs={tabs}
        />
        <Box>
          <FilterTypeSelector idName="reportsUsecasesModulePage" />
        </Box>
      </Stack>
    );
  }

  // const initialState = {};

  if (location?.state !== null) {
    initialStateAllUsecases = {
      columns: {
        columnVisibilityModel: {
          client_uid: false,
        },
      },
      pinnedColumns: {
        left: ['case_name'],
      },
      filter: {
        filterModel: {
          items: [{ columnField: 'client_readiness_status', operatorValue: 'equals', value: location?.state?.label }],
        },
      },
    };
  } else {
    // let initialState = {
    //   columns: {
    //     columnVisibilityModel: {
    //       client_uid: false,
    //     },
    //   },
    //   pinnedColumns: {
    //     left: ['case_name'],
    //   },
    // };
  }

  const handleInlineEditValue = async (
    params: GridRenderEditCellParams,
    editableField: string,
    editableValue: string
  ) => {
    const newRow = {
      ...getStandardRowDataWithoutCustomNames({ ...params.row }),
      case_startdate: format(new Date(params.row.case_startdate), 'yyyy-MM-dd HH:mm:ss'),
      case_planned_startdate: format(new Date(params.row.case_planned_startdate), 'yyyy-MM-dd HH:mm:ss'),
      case_deliverydate: format(new Date(params.row.case_deliverydate), 'yyyy-MM-dd HH:mm:ss'),
      eta_forcasedelivery: format(new Date(params.row.eta_forcasedelivery), 'yyyy-MM-dd HH:mm:ss'),
      case_requiredbydate: format(new Date(params.row.case_requiredbydate), 'yyyy-MM-dd HH:mm:ss'),
    };
    let newEditableValue = editableValue;
    if (editableField === NAMED_CONSTATNTS.usecase_category_key) {
      newEditableValue = getStandardNameMappedValue(editableValue, NAMED_CONSTATNTS.usecase_category_key);
    } else if (editableField === NAMED_CONSTATNTS.usecase_stage_key) {
      newEditableValue = getStandardNameMappedValue(editableValue, NAMED_CONSTATNTS.usecase_stage_key);
    } else if (editableField === NAMED_CONSTATNTS.usecase_priority_key) {
      newEditableValue = getStandardNameMappedValue(editableValue, NAMED_CONSTATNTS.usecase_priority_key);
    }
    await dispatch(
      updateUsecaseByUid({
        ...newRow,
        [editableField]: newEditableValue,
        case_redinessstate_status: params.row.case_readinessstate_status,
        user_uid: editableField === 'case_deliverymanager' ? getUserUid(editableValue) : params.row.user_uid,
      })
    );

    if (editableField == 'case_planned_startdate') {
      const proj = filteredUsecases.filter((data: any) => data.case_uid == params.row.case_uid);
      if (
        makeDate(proj[0].case_planned_startdate).getTime() !=
        new Date(convert(params.row.case_planned_startdate)).getTime()
      ) {
        console.log(
          makeDate(proj[0].case_planned_startdate).getTime() ==
            new Date(convert(params.row.case_planned_startdate)).getTime()
        );
        const diff = differenceInDays(
          new Date(convert(params.row.case_planned_startdate)),
          makeDate(proj[0].case_planned_startdate)
        );
        const detail = {
          project_uid: params.row.case_uid,
          shifted_by: diff,
          updated_date: makeDate(params.row.case_planned_startdate),
          entity: 'USECASE',
        };
        await dispatch(handleProjectPlannedDateUpdate(detail));
      }
    }
  };

  const convert = (str: any) => {
    const date = new Date(str),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join('-');
  };

  const handleInlineEditCustomValue = async (
    params: GridRenderEditCellParams,
    editableField: string,
    editableValue: string
  ) => {
    const index = params.indexofCustomField;
    const customFieldData = params.row.custom_values[index];
    setCustomFieldStatus(true);
    await dispatch(updateUsecaseCustomField({ ...customFieldData, field_value: editableValue }));
    await dispatch(fetchOpenUsecases());
    await dispatch(fetchClosedUsecases());
    setCustomFieldStatus(false);
  };

  const handleColumnOrderChange = (params: GridColumnOrderChangeParams) => {
    const oldArrayIndex = params.oldIndex;
    const targetArrayIndex = params.targetIndex;
    setCfMappedTableColumns(
      generateOrderedColumn(cfMappedTableColumns, uniqueColumnId, oldArrayIndex, targetArrayIndex)
    );
  };

  return (
    <>
      {!isNull(openUsecases) && openUsecases.length > 0 && idName === 'reportsPageAllUsecases' && (
        <CustomiseDrawer
          idName={idName}
          configType="usecase"
          setDrawerState={setDrawerState}
          drawerState={drawerState}
          sampleData={openUsecases[0]}
          formActionType={formActionType}
          setFormActionType={setFormActionType}
          graphData={graphData}
        />
      )}
      {!isNull(openUsecases) && openUsecases.length > 0 && idName === 'reportsPageOpenUsecases' && (
        <CustomiseDrawer
          idName={idName}
          configType="usecase"
          setDrawerState={setDrawerState}
          drawerState={drawerState}
          sampleData={openUsecases[0]}
          formActionType={formActionType}
          setFormActionType={setFormActionType}
          graphData={graphData}
        />
      )}
      {!isNull(closedUsecases) && closedUsecases.length > 0 && idName === 'reportsPageClosedUsecases' && (
        <CustomiseDrawer
          idName={idName}
          configType="usecase"
          setDrawerState={setDrawerState}
          drawerState={drawerState}
          sampleData={closedUsecases[0]}
          formActionType={formActionType}
          setFormActionType={setFormActionType}
          graphData={graphData}
        />
      )}
      <Box sx={{ display: 'grid', gridTemplateRows: '70px auto' }}>
        <ReportsHeader pageName={ReportsPages.Usecases} setDrawerState={setDrawerState} />

        <Box sx={{ display: 'grid', m: '0px 20px', gridRowGap: '20px' }}>
          {getTableHeader(selectedTab)}

          {allFilters['reportsUsecasesModulePage']?.appliedFilters.filter((item: any) => item.isSelected).length >
            0 && (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
              <SelectedFilterList idName="reportsUsecasesModulePage" isResetEnabled={true} />
            </Box>
          )}

          {selectedTab == 0 ? (
            customFieldStatus ||
            (openUsecasesState === requestState.success && closedUsecasesState === requestState.success) ? (
              <>
                {/* {(graphs === undefined || graphs.length === 0) && (
                  <Container>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <NoRecords />
                      <p>
                        <span style={{ color: '#2D60F6', cursor: 'pointer' }} onClick={() => setDrawerState(true)}>
                          Choose
                        </span>{' '}
                        a data set to visualize
                      </p>
                    </div>
                  </Container>
                )} */}
                {graphs !== undefined && graphs.length > 0 && (
                  <Box>
                    <ChartView
                      idName={'reportsPageAllUsecases'}
                      data={filteredUsecases}
                      numberOfcharts={2}
                      setDrawerState={setDrawerState}
                      setGraphData={setGraphData}
                      setFormActionType={setFormActionType}
                      configType="usecase"
                    />
                  </Box>
                )}

                {cfMappedTableColumns.length > 0 ? (
                  <>
                    <StyledBox>
                      <StripedDataGrid
                        // initialState={initialState}
                        initialState={initialStateAllUsecases}
                        onStateChange={(tblState: any) => {
                          myTableStateForAllUsecaseTab = tblState;
                        }}
                        onColumnVisibilityModelChange={() => {
                          handleTableSave('projectReportAllUsecasePage', myTableStateForAllUsecaseTab);
                        }}
                        onFilterModelChange={() => {
                          handleTableSave('projectReportAllUsecasePage', myTableStateForAllUsecaseTab);
                        }}
                        onPageSizeChange={() => {
                          handleTableSave('projectReportAllUsecasePage', myTableStateForAllUsecaseTab);
                        }}
                        rows={displayDataWithCustomNames(filteredUsecases)}
                        columns={cfMappedTableColumns}
                        components={{
                          Toolbar: GridToolbar,
                        }}
                        componentsProps={{
                          toolbar: { showQuickFilter: true },
                        }}
                        loading={false}
                        getRowId={(row) => row.case_uid}
                        disableSelectionOnClick
                        rowThreshold={0}
                        // pageSize={allUsecasesPageSize}
                        // onPageSizeChange={(newPageSize) => setAllUsecasesPageSize(newPageSize)}
                        rowsPerPageOptions={[5, 10, 20, 50, 100]}
                        getRowHeight={() => 'auto'}
                        sx={{
                          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
                          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '7px' },
                          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
                        }}
                        pagination
                        getRowClassName={(params: any) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'odd' : 'odd')}
                        experimentalFeatures={{ newEditingApi: true }}
                        onColumnOrderChange={handleColumnOrderChange}
                      />
                    </StyledBox>
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <div>
                <CognisaasLoader />
              </div>
            )
          ) : null}

          {selectedTab == 1 ? (
            customFieldStatus || openUsecasesState === requestState.success ? (
              <>
                {/* {(graphs === undefined || graphs.length === 0) && (
                  <Container>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <NoRecords />
                      <p>
                        <span style={{ color: '#2D60F6', cursor: 'pointer' }} onClick={() => setDrawerState(true)}>
                          Choose
                        </span>{' '}
                        a data set to visualize
                      </p>
                    </div>
                  </Container>
                )} */}
                {graphs !== undefined && graphs.length > 0 && (
                  <Box>
                    <ChartView
                      idName={'reportsPageOpenUsecases'}
                      data={closedUsecases}
                      numberOfcharts={2}
                      setDrawerState={setDrawerState}
                      setGraphData={setGraphData}
                      setFormActionType={setFormActionType}
                      configType="usecase"
                    />
                  </Box>
                )}

                {cfMappedTableColumns.length > 0 ? (
                  <>
                    <StyledBox>
                      <StripedDataGrid
                        // initialState={initialState}
                        initialState={initialStateOpenUsecases}
                        onStateChange={(tblState: any) => {
                          myTableStateForOpenUsecaseTab = tblState;
                        }}
                        onColumnVisibilityModelChange={() => {
                          handleTableSave('projectReportOpenUsecasePage', myTableStateForOpenUsecaseTab);
                        }}
                        onFilterModelChange={() => {
                          handleTableSave('projectReportOpenUsecasePage', myTableStateForOpenUsecaseTab);
                        }}
                        onPageSizeChange={() => {
                          handleTableSave('projectReportOpenUsecasePage', myTableStateForOpenUsecaseTab);
                        }}
                        rows={displayDataWithCustomNames(filteredUsecases)}
                        columns={cfMappedTableColumns}
                        components={{
                          Toolbar: GridToolbar,
                        }}
                        componentsProps={{
                          toolbar: { showQuickFilter: true },
                        }}
                        loading={false}
                        getRowId={(row) => row.case_uid}
                        disableSelectionOnClick
                        rowThreshold={0}
                        // pageSize={openUsecasesPageSize}
                        // onPageSizeChange={(newPageSize) => setOpenUsecasesPageSize(newPageSize)}
                        rowsPerPageOptions={[5, 10, 20, 50, 100]}
                        pagination
                        getRowClassName={(params: any) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'odd' : 'odd')}
                        // filterModel={filterOpenModel}
                        getRowHeight={() => 'auto'}
                        sx={{
                          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
                          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '7px' },
                          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
                        }}
                        // onFilterModelChange={(newFilterModel) => setFilterOpenModel(newFilterModel)}
                        experimentalFeatures={{ newEditingApi: true }}
                        onColumnOrderChange={handleColumnOrderChange}
                      />
                    </StyledBox>
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <div>
                <CognisaasLoader />
              </div>
            )
          ) : null}

          {selectedTab == 2 ? (
            customFieldStatus || closedUsecasesState === requestState.success ? (
              <>
                {/* {(graphs === undefined || graphs.length === 0) && (
                  <Container>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <NoRecords />
                      <p>
                        <span style={{ color: '#2D60F6', cursor: 'pointer' }} onClick={() => setDrawerState(true)}>
                          Choose
                        </span>{' '}
                        a data set to visualize
                      </p>
                    </div>
                  </Container>
                )} */}
                {graphs !== undefined && graphs.length > 0 && (
                  <Box>
                    <ChartView
                      idName={'reportsPageClosedUsecases'}
                      data={closedUsecases}
                      numberOfcharts={2}
                      setDrawerState={setDrawerState}
                      setGraphData={setGraphData}
                      setFormActionType={setFormActionType}
                      configType="usecase"
                    />
                  </Box>
                )}
                {cfMappedTableColumns.length > 0 ? (
                  <>
                    <StyledBox>
                      <StripedDataGrid
                        // initialState={initialState}
                        initialState={initialStateClosedUsecases}
                        onStateChange={(tblState: any) => {
                          myTableStateForClosedUsecaseTab = tblState;
                        }}
                        onColumnVisibilityModelChange={() => {
                          handleTableSave('projectReportClosedUsecasePage', myTableStateForClosedUsecaseTab);
                        }}
                        onFilterModelChange={() => {
                          handleTableSave('projectReportClosedUsecasePage', myTableStateForClosedUsecaseTab);
                        }}
                        onPageSizeChange={() => {
                          handleTableSave('projectReportClosedUsecasePage', myTableStateForClosedUsecaseTab);
                        }}
                        components={{
                          Toolbar: GridToolbar,
                        }}
                        componentsProps={{
                          toolbar: { showQuickFilter: true },
                        }}
                        rows={displayDataWithCustomNames(filteredUsecases)}
                        columns={cfMappedTableColumns}
                        loading={false}
                        getRowId={(row) => row.case_uid}
                        disableSelectionOnClick
                        rowThreshold={0}
                        // pageSize={closedUsecasesPageSize}
                        // onPageSizeChange={(newPageSize) => setClosedUsecasesPageSize(newPageSize)}
                        rowsPerPageOptions={[5, 10, 20, 50, 100]}
                        pagination
                        getRowClassName={(params: any) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'odd' : 'odd')}
                        getRowHeight={() => 'auto'}
                        sx={{
                          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
                          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '7px' },
                          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
                        }}
                        // filterModel={filterOpenModel}
                        // onFilterModelChange={(newFilterModel) => setFilterOpenModel(newFilterModel)}
                        experimentalFeatures={{ newEditingApi: true }}
                        onColumnOrderChange={handleColumnOrderChange}
                      />
                    </StyledBox>
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <div>
                <CognisaasLoader />
              </div>
            )
          ) : null}
        </Box>
      </Box>
      <CognisaasToast
        message={alertDetails.message}
        type={alertDetails.type}
        open={alertDetails.isVisible}
        onClose={() => {
          setAlertDetails({ isVisible: false, type: alertDetails.type, message: '' });
        }}
      />
    </>
  );
};

export default Usecases;
