import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { requestState } from '../types';
import { settingsAPI } from '../../../api/modules/Settings';
import { jiraAPI } from '../../../api/modules/jira';
import { pictureApi } from 'api/modules/picture';
import { isNull } from '../../../utils/dataHelpers';
import {
  UpdateCrmCustomFieldMapping,
  PriorityNamePayload,
  UpdateCustomLabelValue,
  UpdateProjectCategoryNamePayload,
  UpdateSegmentNamePayload,
  UpdateStageNamePayload,
  UpdateUsecaseCategoryNamePayload,
} from './types';
import {
  label_client,
  label_project,
  label_task,
  label_use_case,
} from 'components/Settings/Preferences/CustomLabelCookieHelper';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
interface SettingsProps {
  customJourneyStageClientConfigurations: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };
  journeyStageClientConfigurations: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };
  customerDetails: {
    data: Record<string, any>;
    status: requestState;
    error: null | undefined | string;
  };
  userDetails: {
    data: Record<string, any>;
    status: requestState;
    error: null | undefined | string;
  };
  customerFieldClientRecords: {
    data: any;
    status: requestState;
    error: null | undefined | string;
  };
  customerFieldProjectRecords: {
    data: Record<string, any>;
    status: requestState;
    error: null | undefined | string;
  };
  customerFieldUsecaseRecords: {
    data: Record<string, any>;
    status: requestState;
    error: null | undefined | string;
  };
  customerFieldTaskRecords: {
    data: Record<string, any>;
    status: requestState;
    error: null | undefined | string;
  };
  notificationData: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };
  taskDetails: {
    data: Record<string, any>;
    status: requestState;
    error: null | undefined | string;
  };
  clientListColumns: {
    data: string[];
    status: requestState;
    error: null | undefined | string;
  };
  updatingJiraDetailsToCustomer: {
    data: string;
    status: requestState;
    error: null | undefined | string;
  };
  updateJiraAccountIdOfUsers: {
    data: string;
    status: requestState;
    error: null | undefined | string;
  };
  addNewJiraProjectKeyToCustomer: {
    data: Record<string, any>;
    status: requestState;
    error: null | undefined | string;
  };
  allJiraProjectsDetails: {
    data: Record<string, any>;
    status: requestState;
    error: null | undefined | string;
  };
  jiraProjectsAlreadyAdded: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };
  deleteAlreadyAddedJiraProjects: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };

  addCustomerLogo: {
    status: requestState;
    error: null | undefined | string;
  };

  addUserLogo: {
    status: requestState;
    error: null | undefined | string;
  };
  jiraCustomProductStages: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };
  jiraCustomTaskStages: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };
  updatingProductCustomStage: {
    status: requestState;
    error: null | undefined | string;
  };
  updatingTaskCustomStage: {
    status: requestState;
    error: null | undefined | string;
  };
  updateCustomFieldAttachmentStatus: {
    status: requestState;
    error: null | undefined | string;
  };
  updateCustomFieldMandatoryStatus: {
    status: requestState;
    error: null | undefined | string;
  };
  updateCustomProjectField: {
    status: requestState;
    error: null | undefined | string;
  };
  updateCustomUsecaseField: {
    status: requestState;
    error: null | undefined | string;
  };
  updateCustomTaskField: {
    status: requestState;
    error: null | undefined | string;
  };
  deleteCustomProjectField: {
    status: requestState;
    error: null | undefined | string;
  };
  deleteCustomUsecaseField: {
    status: requestState;
    error: null | undefined | string;
  };
  deleteCustomTaskField: {
    status: requestState;
    error: null | undefined | string;
  };
  deleteCustomClientField: {
    status: requestState;
    error: null | undefined | string;
  };
  jiraStatuses: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };
  productInfo: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };
  serviceDesks: {
    data: any;
    status: requestState;
    error: null | undefined | string;
  };
  jiraServiceRequestList: {
    data: any;
    status: requestState;
    error: null | undefined | string;
  };
  jsdCustomFields: {
    data: any;
    status: requestState;
    error: null | undefined | string;
  };
  updateJourneyTable: {
    data: any;
    status: requestState;
    error: null | undefined | string;
  };
  updateJourneyTableExsiting: {
    data: any;
    status: requestState;
    error: null | undefined | string;
  };
  addEmailConfig: {
    data: any;
    status: requestState;
    error: null | undefined | string;
  };
  getEmailConfig: {
    data: any;
    status: requestState;
    error: null | undefined | string;
  };
  deleteEmailConfig: {
    data: any;
    status: requestState;
    error: null | undefined | string;
  };
  changeEmailConfig: {
    data: any;
    status: requestState;
    error: null | undefined | string;
  };
  updatingProfile: {
    message: null | string;
    status: requestState;
  };
  updatingConfiguration: {
    message: null | string;
    status: requestState;
  };
  updatingCustomLabels: {
    data: any;
    status: requestState;
    error: null | undefined | string;
  };
  insertingCustomLabels: {
    data: any;
    status: requestState;
    error: null | undefined | string;
  };
  updateCustomerHolidays: {
    data: any;
    status: requestState;
    error: null | undefined | string;
  };
  manipulatingFields: {
    data: any;
    status: requestState;
    message: null | undefined | string;
  };
  revenueReportBasis: {
    data: any;
    status: requestState;
    message: null | undefined | string;
  };
  customerIntegrationDetails: {
    data: any;
    status: requestState;
    message: null | undefined | string;
  };
  customerIntegrationMicroDetails: {
    data: any;
    status: requestState;
    message: null | undefined | string;
  };
  sendEmailToUsersWithCustomMessage: {
    data: any;
    status: requestState;
    message: null | undefined | string;
  };
  crmIntegrationStages: {
    data: any;
    status: requestState;
    message: null | undefined | string;
  };
  crmCustomFieldsFromIntegrations: {
    data: any;
    status: requestState;
    message: null | undefined | string;
  };
  crmCustomFieldsFromDb: {
    data: any;
    status: requestState;
    message: null | undefined | string;
  };
}

const initialState: SettingsProps = {
  customJourneyStageClientConfigurations: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  journeyStageClientConfigurations: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  customerDetails: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  userDetails: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  customerFieldClientRecords: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  customerFieldProjectRecords: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  customerFieldUsecaseRecords: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  customerFieldTaskRecords: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  notificationData: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  taskDetails: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  clientListColumns: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  updatingJiraDetailsToCustomer: {
    data: '',
    status: requestState.idle,
    error: null,
  },
  updateJiraAccountIdOfUsers: {
    data: '',
    status: requestState.idle,
    error: null,
  },
  addNewJiraProjectKeyToCustomer: {
    data: {},
    status: requestState.idle,
    error: null,
  },
  allJiraProjectsDetails: {
    data: {},
    status: requestState.idle,
    error: null,
  },
  jiraProjectsAlreadyAdded: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  deleteAlreadyAddedJiraProjects: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  addCustomerLogo: {
    status: requestState.idle,
    error: null,
  },

  addUserLogo: {
    status: requestState.idle,
    error: null,
  },
  jiraCustomProductStages: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  jiraCustomTaskStages: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  updatingProductCustomStage: {
    status: requestState.idle,
    error: null,
  },
  updatingTaskCustomStage: {
    status: requestState.idle,
    error: null,
  },
  updateCustomFieldAttachmentStatus: {
    status: requestState.idle,
    error: null,
  },
  updateCustomFieldMandatoryStatus: {
    status: requestState.idle,
    error: null,
  },
  updateCustomProjectField: {
    status: requestState.idle,
    error: null,
  },
  updateCustomUsecaseField: {
    status: requestState.idle,
    error: null,
  },
  updateCustomTaskField: {
    status: requestState.idle,
    error: null,
  },
  deleteCustomProjectField: {
    status: requestState.idle,
    error: null,
  },
  deleteCustomUsecaseField: {
    status: requestState.idle,
    error: null,
  },
  deleteCustomTaskField: {
    status: requestState.idle,
    error: null,
  },
  deleteCustomClientField: {
    status: requestState.idle,
    error: null,
  },
  jiraStatuses: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  productInfo: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  serviceDesks: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  jiraServiceRequestList: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  jsdCustomFields: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  updateJourneyTable: {
    data: 0,
    status: requestState.idle,
    error: null,
  },
  updateJourneyTableExsiting: {
    data: 0,
    status: requestState.idle,
    error: null,
  },
  addEmailConfig: {
    data: 0,
    status: requestState.idle,
    error: null,
  },
  getEmailConfig: {
    data: 0,
    status: requestState.idle,
    error: null,
  },
  deleteEmailConfig: {
    data: 0,
    status: requestState.idle,
    error: null,
  },
  changeEmailConfig: {
    data: 0,
    status: requestState.idle,
    error: null,
  },
  updatingProfile: {
    message: null,
    status: requestState.idle,
  },
  updatingConfiguration: {
    message: null,
    status: requestState.idle,
  },
  updatingCustomLabels: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  insertingCustomLabels: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  updateCustomerHolidays: {
    data: [],
    status: requestState.idle,
    error: null,
  },
  manipulatingFields: {
    data: [],
    status: requestState.idle,
    message: null,
  },
  revenueReportBasis: {
    data: [],
    status: requestState.idle,
    message: null,
  },
  customerIntegrationDetails: {
    data: [],
    status: requestState.idle,
    message: null,
  },
  customerIntegrationMicroDetails: {
    data: [],
    status: requestState.idle,
    message: null,
  },
  sendEmailToUsersWithCustomMessage: {
    data: [],
    status: requestState.idle,
    message: null,
  },
  crmIntegrationStages: {
    data: [],
    status: requestState.idle,
    message: null,
  },
  crmCustomFieldsFromIntegrations: {
    data: [],
    status: requestState.idle,
    message: null,
  },
  crmCustomFieldsFromDb: {
    data: [],
    status: requestState.idle,
    message: null,
  },
};

export const fetchCustomJourneyStagesForClientConfigurations = createAsyncThunk(
  'getCustomJourneyStageData',
  async () => {
    const { data } = await settingsAPI.getSettingPage_CustomJourneyState_Data();
    return data.result;
  }
);

export const fetchJourneyStagesForClientConfigurations = createAsyncThunk('getJourneyStageData', async () => {
  const { data } = await settingsAPI.getSettingPage_JourneyState_Data();
  return data.result;
});

// interface UpdateTablePayload {
//   customer_uid?: string;
//   stage_id?: string;
//   stage_uid?: string;
//   stage_journerystage?: string;
//   original_journeystage?: string;
// }

export const updateTableForFirstTime = createAsyncThunk('updateTableForFirstTime', async (payload: any) => {
  const { data } = await settingsAPI.updateTableForFirstTime(payload);
  return data.result;
});

export const updateTable = createAsyncThunk('updateTable', async (payload: any) => {
  const data = await settingsAPI.updateTable(payload);
  return data;
});

export const getCustomerDetails = createAsyncThunk('getCustomerDetails', async () => {
  const { data } = await settingsAPI.getCustomerDetails();
  return data.result;
});

export const updateMagicLinkDuration = createAsyncThunk('updateMagicLinkDuration', async (detail: any) => {
  const { data } = await settingsAPI.updateMagicLinkDuration(detail);
  return data.result;
});

export interface updateUsecasePayload {
  client_mail: boolean | string;
  client_task_ind: number | string;
  currency_code: string | string;
  customer_amber_threshold: number;
  customer_uid: string | string;
  renewal_notification: boolean | string;
  subtask_enable: boolean | string;
  task_deletion_mail: boolean | string;
  task_dependency_date_updation: boolean | string;
  task_inprogress_indicator: boolean | string;
  task_mail: boolean | string;
  task_mail_copy: boolean | string;
  task_status_account_owner_mail: boolean | string;
  task_status_mail: boolean | string;
  task_status_client_contact_mail: boolean | string;
  task_status_manager_mail: boolean | string;
  time_track: boolean | string;
  usecase_calc_ind: string;
  task_assign_mail_dm_copy: boolean | string;
  task_deletion_delivery_manager_mail: boolean | string;
  project_progress_indicator: any;
  status_report_client: any;
  primary_contact_as_default_owner: boolean | string;
  delivery_manager_as_default_owner: boolean | string;
  attachment_mandatory_when_marking_milestone_task_as_done?: string;
  client_contact_added_mail: boolean | string;
  project_plan_view: string;
  usecase_plan_view: string;
  last_done_task_will_update_delivery_date_of_project_or_usecase?: string;
  task_acknowledgement?: string;
  freeze_planned_date_usecase: boolean | string;
  freeze_planned_date_project: boolean | string;
}

export const updateUseCase_Configuration = createAsyncThunk(
  'updateUsecaseInConfiguration',
  async (payload: updateUsecasePayload) => {
    const data = await settingsAPI.updateUseCase_Configuration(payload);
    return data;
  }
);

export const getUserDetails_slice = createAsyncThunk('getUserDetails', async (user_uid: string) => {
  const { data } = await settingsAPI.getUserDetails(user_uid);
  return data.result;
});

interface updateUser_payload {
  user_uid: string;
  user_username: string;
}

export const updateUserName_slice = createAsyncThunk('updateUsername', async (payload: updateUser_payload) => {
  const data = await settingsAPI.updateUsername(payload);
  return data;
});

export const updateUsername = createAsyncThunk(
  'settings/profile/updateusername',
  async (payload: updateUser_payload) => {
    const { data } = await settingsAPI.updateUsername(payload);
    return data;
  }
);

export const enableMandatorySSO_slice = createAsyncThunk('enableMandatorySSO', async () => {
  const data = await settingsAPI.enableMandatorySSO();
  return data;
});

export const enableMandatorySSO = createAsyncThunk('settings/enableMandatorySSO', async () => {
  const { data } = await settingsAPI.enableMandatorySSO();
  return data;
});

export const disableMandatorySSO_slice = createAsyncThunk('disableMandatorySSO', async () => {
  const data = await settingsAPI.disableMandatorySSO();
  return data;
});

export const disableMandatorySSO = createAsyncThunk('settings/disableMandatorySSO', async () => {
  const { data } = await settingsAPI.disableMandatorySSO();
  return data;
});

interface updateEmail_payload {
  base_url: string;
  user_email: string;
  user_new_email: string;
  user_uid: string;
  user_username: string;
}

export const update_user_email_slice = createAsyncThunk('update_user_email', async (payload: updateEmail_payload) => {
  const data = await settingsAPI.update_user_email(payload);
  return data;
});

export const updateUserEmail = createAsyncThunk(
  'settings/profile/updateUserEmail',
  async (payload: updateEmail_payload) => {
    const { data } = await settingsAPI.update_user_email(payload);
    return data.result;
  }
);

interface updateUserPassword_payload {
  user_new_password: string;
  user_password: string;
  user_uid: string;
}

export const update_user_password_slice = createAsyncThunk(
  'updateUserPassword',
  async (payload: updateUserPassword_payload) => {
    const data = await settingsAPI.update_user_password(payload);
    return data;
  }
);

export const getCustomFieldClientRecords_slice = createAsyncThunk(
  'getCustomFieldClientRecords',
  async (customer_uid: string) => {
    const { data } = await settingsAPI.getCustomFieldClientRecords(customer_uid);
    return data.result ?? [];
  }
);

export const getCustomFieldProjectRecords_slice = createAsyncThunk(
  'getCustomFieldProjectRecords',
  async (customer_uid: string) => {
    const data = await settingsAPI.getCustomFieldProjectRecords(customer_uid);
    return data;
  }
);

export const getCustomFieldUsecaseRecords_slice = createAsyncThunk(
  'getCustomFieldUsecaseRecords',
  async (customer_uid: string) => {
    const data = await settingsAPI.getCustomFieldUsecaseRecords(customer_uid);
    return data;
  }
);

export const getCustomFieldTaskRecords_slice = createAsyncThunk(
  'getCustomFieldTaskRecords',
  async (customer_uid: string) => {
    const data = await settingsAPI.getCustomFieldTaskRecords(customer_uid);
    return data;
  }
);

export const deleteExistingProduct_slice = createAsyncThunk('deleteExistingProduct', async (customer_uid: string) => {
  const data = await settingsAPI.deleteExisitingProductbyCustomerUid(customer_uid);
  return data;
});

export const addCustomerLogo = createAsyncThunk('settings/addCustomerLogo', async (attachment: any) => {
  const { data } = await pictureApi.addCustomerLogo(attachment);
  return data;
});

export const addUserLogo = createAsyncThunk('settings/addUserLogo', async (attachment: any) => {
  const { data } = await pictureApi.addUserLogo(attachment);
  return data;
});

interface UpdateJiraDetailsToCustomerPayloadType {
  customer_jira_api_url: string;
  customer_jira_token: string;
  customer_jira_user: string;
  customer_uid: string;
}

export const updateJiraDetailsToCustomer = createAsyncThunk(
  'settings/updateJiraDetailsToCustomer',
  async (payload: UpdateJiraDetailsToCustomerPayloadType) => {
    const { data } = await settingsAPI.updateJiraUsernameAndCustomerDetails(payload);
    return data;
  }
);

interface UpdateJiraAccountIdPayloadType {
  customer_uid: string;
  key: string;
}

export const updateJiraAccountIdForUsers = createAsyncThunk(
  'settings/updateJiraAccountId',
  async (payload: UpdateJiraAccountIdPayloadType) => {
    const { data } = await settingsAPI.updateJiraAccountIdForUsers(payload);
    return data;
  }
);

interface AddJiraProjectKeyPayloadType {
  custom_field: string | null;
  custom_field_name: string | null;
  customer_uid: string;
  jira_project_id: string;
  jira_project_name: string;
  jira_service_desk_id: string | null;
  oneTomany: boolean;
  project_key: string;
  pull_issuetype: string | null;
  pull_status: string | null;
  task_ind: number;
}

export const addJiraProjectKeyToCustomer = createAsyncThunk(
  'settings/addJiraProjectKeyToCustomer',
  async (payload: AddJiraProjectKeyPayloadType) => {
    const { data } = await settingsAPI.addJiraProjectKeyToCustomer(payload);
    return data.result;
  }
);

export const fetchProjectAddedToCustomerAndJira = createAsyncThunk(
  'settings/fetchProjectAddedToCustomerAndJira',
  async () => {
    const { data } = await settingsAPI.getJiraProjectfromCustomerUid();
    return data.result;
  }
);

interface saveJiraCredentials_payload {
  key: string;
  url: string;
}

export const saveJiraCredentials_slice = createAsyncThunk(
  'saveJiraCredentials',
  async (payload: saveJiraCredentials_payload) => {
    const data = await settingsAPI.saveJiraCredentials(payload);
    return data;
  }
);

interface addJiraCustomProductStageCustomStage_payload {
  customer_uid: string;
  key: string;
  project_key: string;
}

export const addJiraCustomProductStageCustomStage = createAsyncThunk(
  'settings/addJiraCustomProductStageCustomStage',
  async (payload: addJiraCustomProductStageCustomStage_payload) => {
    const { data } = await settingsAPI.addJiraProductStageCustomStage(payload);
    return data.result;
  }
);

interface updateJiraFetchMode_payload {
  customer_jira_fetch_mode: string;
  customer_uid: string;
}

export const updateJiraFetchMode_slice = createAsyncThunk(
  'updateJiraFetchMode',
  async (payload: updateJiraFetchMode_payload) => {
    const data = await settingsAPI.updateJiraFetchMode(payload);
    return data;
  }
);

interface updateJiraTaskMode_payload {
  customer_JSD: boolean;
  customer_uid: string;
}

export const updateJiraTaskMode_slice = createAsyncThunk(
  'updateJiraTaskMode',
  async (payload: updateJiraTaskMode_payload) => {
    const data = await settingsAPI.updateJiraTaskMode(payload);
    return data;
  }
);

interface getJiraProjectsFromApi_payload {
  customer_uid: string;
  key: string;
}

export const getJiraProjectsFromApi = createAsyncThunk(
  'settings/getJiraProjectsFromApi',
  async (payload: getJiraProjectsFromApi_payload) => {
    const { data } = await settingsAPI.getJiraProjectsFromApi(payload);
    return data;
  }
);

interface notificationData_payload {
  user_uid: string;
}
export const getNotificationData_slice = createAsyncThunk(
  'getNotificationData',
  async (payload: notificationData_payload) => {
    const { data } = await settingsAPI.getNotificationData(payload);
    return data.result;
  }
);

interface getAllTasksData_payload {
  customer_uid: string;
  user_uid: string;
}
export const getAllTasksData_slice = createAsyncThunk('getAllTasksData', async (payload: getAllTasksData_payload) => {
  const { data } = await settingsAPI.getAllTasks(payload);
  return data.result;
});

interface getCustomViewColumn_payload {
  customer_uid: string;
}

export const getClientRecordColumnView = createAsyncThunk(
  'getCustomViewColumn',
  async (payload: getCustomViewColumn_payload) => {
    const { data } = await settingsAPI.getCustomViewColumns_api(payload);
    return data.result;
  }
);

interface updateClientRecordViewColumns_payload {
  customer_uid: string;
  updatedList: string;
}

export const updateClientRecordViewColumns_slice = createAsyncThunk(
  'updateClientRecordViewColumns',
  async (payload: updateClientRecordViewColumns_payload) => {
    const { data } = await settingsAPI.updateClientRecordViewColumns_api(payload);
    return data.result;
  }
);

export const addCustomFieldProjectByCustomerUid = createAsyncThunk(
  'addCustomFieldProjectByCustomerUid',
  async (payload: updateClientRecordViewColumns_payload) => {
    const { data } = await settingsAPI.addCustomFieldProjectByCustomerUid(payload);
    return data;
  }
);

export const updateCustomFieldRAGCheckBoxStatus = createAsyncThunk(
  'updateCustomFieldRAGCheckBoxStatus',
  async (payload: updateClientRecordViewColumns_payload) => {
    const { data } = await settingsAPI.updateCustomFieldRAGCheckBoxStatus(payload);
    return data.result;
  }
);

export const addSubFieldforCustomFieldProject = createAsyncThunk(
  'addSubFieldforCustomFieldProject',
  async (payload: updateClientRecordViewColumns_payload) => {
    const { data } = await settingsAPI.addSubFieldforCustomFieldProject(payload);
    return data.result;
  }
);

export const addCustomFieldByCustomerUid = createAsyncThunk(
  'addCustomFieldByCustomerUid',
  async (payload: updateClientRecordViewColumns_payload) => {
    const { data } = await settingsAPI.addCustomFieldByCustomerUid(payload);
    return data;
  }
);

export const addCustomFieldUsecaseByCustomerUid = createAsyncThunk(
  'addCustomFieldUsecaseByCustomerUid',
  async (payload: updateClientRecordViewColumns_payload) => {
    const { data } = await settingsAPI.addCustomFieldUsecaseByCustomerUid(payload);
    return data;
  }
);

export const updateCustomFieldByCustomerUid = createAsyncThunk(
  'updateCustomFieldByCustomerUid',
  async (payload: updateClientRecordViewColumns_payload) => {
    const { data } = await settingsAPI.updateCustomFieldByCustomerUid(payload);
    return data.result;
  }
);

export const addCustomFieldTaskByCustomerUid = createAsyncThunk(
  'addCustomFieldTaskByCustomerUid',
  async (payload: updateClientRecordViewColumns_payload) => {
    const { data } = await settingsAPI.addCustomFieldTaskByCustomerUid(payload);
    return data;
  }
);

interface DeleteCustomerJiraProject_payload {
  customer_uid: string;
  project_key: string;
}

export const deleteCustomerJiraProject = createAsyncThunk(
  'settings/deleteCustomerJiraProject',
  async (payload: DeleteCustomerJiraProject_payload) => {
    const { data } = await settingsAPI.deleteCustomerProject(payload);
    return data.result;
  }
);

export const deleteWholeCustomerProduct = createAsyncThunk(
  'settings/deleteWholeCustomerProduct',
  async (payload: DeleteCustomerJiraProject_payload) => {
    const { data } = await settingsAPI.deleteWholeCustomerProject(payload);
    return data.result;
  }
);

//removing jira token
export const removeJiraToken = createAsyncThunk('settings/removeJiraToken', async () => {
  const { data } = await settingsAPI.updateRemoveJiraToken();
  return data.result;
});

export const removeJiraLinkFromTask = createAsyncThunk('settings/removeJiraLinkFromTask', async () => {
  const { data } = await settingsAPI.updateRemoveJiraLinkFromTask();
  return data.result;
});

export const fetchJiraCustomProductStages = createAsyncThunk(
  'settings/fetchJiraCustomProductStages',
  async (project_key: string) => {
    const { data } = await settingsAPI.getJiraProductCustomStage(project_key);
    return data.result ?? [];
  }
);

export const fetchJiraCustomTaskStages = createAsyncThunk(
  'settings/fetchJiraCustomTaskStages',
  async (project_key: string) => {
    const { data } = await settingsAPI.getJiraTaskCustomStage(project_key);
    return data.result ?? [];
  }
);

interface UpdateJiraProductCustomStagePayloadType {
  color_in_our_product: string;
  customer_uid: string;
  jira_product_project_key: string;
  jira_product_stage_id: string;
  jira_product_stage_name: string;
  jira_product_stage_uid: string;
  product_stage_color_id: string;
}

export const updateJiraProductCustomStage = createAsyncThunk(
  'settings/updateJiraProductCustomStage',
  async (payload: UpdateJiraProductCustomStagePayloadType[]) => {
    const { data } = await settingsAPI.updateJiraProductCustomStage(payload);
    return data.result ?? [];
  }
);

interface UpdateJiraTaskCustomStagePayloadType {
  jira_status_id: string;
  jira_status_name: string;
  mapped_task_stage: string;
  task_map_uid: string;
}

export const updateJiraTaskCustomStage = createAsyncThunk(
  'settings/updateJiraTaskCustomStage',
  async (payload: UpdateJiraTaskCustomStagePayloadType[]) => {
    const { data } = await settingsAPI.updateJiraTaskCustomStage(payload);
    return data.result ?? [];
  }
);

//getJiraStatusByProjectKey
interface GetJiraStatusesPayloadType {
  customer_uid: string;
  key: string;
  project_key: string;
}

export const getJiraStatusByJiraProjectKey = createAsyncThunk(
  'settings/getJiraStatusByJiraProjectKey',
  async (payload: GetJiraStatusesPayloadType) => {
    const { data } = await jiraAPI.getJiraStatusByProjectKey(payload);
    return data.result ?? {};
  }
);

//

export const getProductInfoByJiraProductIssueId = createAsyncThunk(
  'settings/getProductInfoByJiraProductIssueId',
  async (product_jira_issue_id: string) => {
    const { data } = await jiraAPI.getJiraStatusByProjectKey(product_jira_issue_id);
    return data.result ?? [];
  }
);

export const updateCustomFieldAttachmentStatus = createAsyncThunk(
  'settings/updateCustomFieldAttachmentStatus',
  async (payload: any) => {
    const { data } = await settingsAPI.updateCustomFieldAttachmentStatus(payload);
    return data.result ?? [];
  }
);
interface GetJiraServiceDeskPayloadType {
  customer_uid: string;
  key: string;
}

export const getJiraServiceDesksByJiraProjectKey = createAsyncThunk(
  'settings/getJiraServiceDesksByJiraProjectKey',
  async (payload: GetJiraServiceDeskPayloadType) => {
    const { data } = await jiraAPI.getJiraServiceDesks(payload);
    return data.result ?? [];
  }
);

export const updateCustomFieldMandatoryStatus = createAsyncThunk(
  'settings/updateCustomFieldMandatoryStatus',
  async (payload: any) => {
    const { data } = await settingsAPI.updateCustomFieldMandatoryStatus(payload);
    return data.result ?? [];
  }
);

// getJiraJsdServiceRequestList

interface GetJiraServiceDeskRequestlistPayloadType {
  customer_uid: string;
  jira_service_desk_id: string;
  key: string;
}

export const getJiraJsdServicesRequestLists = createAsyncThunk(
  'settings/getJiraJsdServicesRequestLists',
  async (payload: GetJiraServiceDeskRequestlistPayloadType) => {
    const { data } = await jiraAPI.getJiraJsdServiceRequestList(payload);
    return data.result ?? [];
  }
);

export const updateCustomProjectField = createAsyncThunk('settings/updateCustomProjectField', async (payload: any) => {
  const { data } = await settingsAPI.updateCustomProjectField(payload);
  return data.result ?? [];
});

interface GetJiraJsdCustomFieldsPayloadType {
  customer_uid: string;
  jira_service_desk_id: string;
  key: string;
  requestTypeId: string;
}

export const getJiraJsdCustomFields = createAsyncThunk(
  'settings/getJiraJsdCustomFields',
  async (payload: GetJiraJsdCustomFieldsPayloadType) => {
    const { data } = await jiraAPI.getJiraJsdCustomFieldsById(payload);
    return data.result ?? null;
  }
);

export const updateCustomUsecaseField = createAsyncThunk('settings/updateCustomUsecaseField', async (payload: any) => {
  const { data } = await settingsAPI.updateCustomUsecaseField(payload);
  return data.result ?? [];
});

export const updateCustomTaskField = createAsyncThunk('settings/updateCustomTaskField', async (payload: any) => {
  const { data } = await settingsAPI.updateCustomTaskField(payload);
  return data.result ?? [];
});

export const deleteCustomProjectField = createAsyncThunk('settings/deleteCustomProjectField', async (payload: any) => {
  const { data } = await settingsAPI.deleteCustomProjectField(payload);
  return data.result ?? [];
});

export const deleteCustomUsecaseField = createAsyncThunk('settings/deleteCustomUsecaseField', async (payload: any) => {
  const { data } = await settingsAPI.deleteCustomUsecaseField(payload);
  return data.result ?? [];
});

export const deleteCustomTaskField = createAsyncThunk('settings/deleteCustomTaskField', async (payload: any) => {
  const { data } = await settingsAPI.deleteCustomTaskField(payload);
  return data.result ?? [];
});

export const deleteCustomClientField = createAsyncThunk('settings/deleteCustomClientField', async (payload: any) => {
  const { data } = await settingsAPI.deleteCustomClientField(payload);
  return data.result ?? [];
});

export const updateCustomerHolidays = createAsyncThunk('settings/updateCustomerHolidays', async (payload: any) => {
  const { data } = await settingsAPI.updateCustomerHolidays(payload);
  return data.result ?? [];
});

export const updateRevenueReportBasis = createAsyncThunk('settings/updateRevenueReportBasis', async (payload: any) => {
  const { data } = await settingsAPI.updateRevenueReportBasis(payload);
  return data.result ?? [];
});

export const addEmailConfig = createAsyncThunk('addEmailConfig', async (payload: any) => {
  const { data } = await settingsAPI.addEmailConfig(payload);
  console.log(data);
  return data.result ?? [];
});

export const getEmailConfig = createAsyncThunk('getEmailConfig', async () => {
  const { data } = await settingsAPI.getEmailConfig();
  console.log('getEmailConfig = ', data);
  return data.result ?? {};
});

export const deleteEmailConfig = createAsyncThunk('deleteEmailConfig', async () => {
  const { data } = await settingsAPI.deleteEmailConfig();
  console.log(data);
  return data.result ?? [];
});

export const changeEmailConfig = createAsyncThunk('changeEmailConfig', async (payload: any) => {
  const { data } = await settingsAPI.changeEmailConfig(payload);
  console.log(data);
  return data.result ?? [];
});

export const sendEmailToUsersWithCustomMessage = createAsyncThunk(
  'sendEmailToUsersWithCustomMessage',
  async (payload: any) => {
    const { data } = await settingsAPI.sendEmailToUsersWithCustomMessage(payload);
    return data;
  }
);

// updating rename labels to first time in db
export const updateCustomLabelValue = createAsyncThunk(
  'settings/updateCustomLabelValue',
  async (payload: UpdateCustomLabelValue) => {
    const { data } = await settingsAPI.updateCustomLabelValueByCustomerUidAndFieldUid(payload);
    console.log(data);
    return data ?? [];
  }
);

// insertCustomLabelValues
export const insertCustomLabelValues = createAsyncThunk('settings/insertCustomLabelValues', async () => {
  const { data } = await settingsAPI.insertCustomLabelValues();
  console.log(data);
  return data ?? [];
});

// custom names
export const updateCustomClientSegmentValue = createAsyncThunk(
  'settings/updateCustomClientSegmentValue',
  async (payload: UpdateSegmentNamePayload) => {
    const { data } = await settingsAPI.updateCustomClientSegmentValue(payload);
    return data ?? [];
  }
);

export const updateCustomProjectCategoryValue = createAsyncThunk(
  'settings/updateCustomProjectCategoryValue',
  async (payload: UpdateProjectCategoryNamePayload) => {
    const { data } = await settingsAPI.updateCustomProjectCategoryValue(payload);
    return data ?? [];
  }
);

export const updateCustomProjectStageValue = createAsyncThunk(
  'settings/updateCustomProjectStageValue',
  async (payload: UpdateStageNamePayload) => {
    const { data } = await settingsAPI.updateCustomProjectStageValue(payload);
    return data ?? [];
  }
);

export const updateCustomUsecaseCategoryValue = createAsyncThunk(
  'settings/updateCustomUsecaseCategoryValue',
  async (payload: UpdateUsecaseCategoryNamePayload) => {
    const { data } = await settingsAPI.updateCustomUsecaseCategoryValue(payload);
    return data ?? [];
  }
);

export const updateCustomUsecaseStageValue = createAsyncThunk(
  'settings/updateCustomUsecaseStageValue',
  async (payload: UpdateStageNamePayload) => {
    const { data } = await settingsAPI.updateCustomUsecaseStageValue(payload);
    return data ?? [];
  }
);

export const updateCustomProjectPriorityValue = createAsyncThunk(
  'settings/updateCustomProjectPriorityValue',
  async (payload: PriorityNamePayload) => {
    const { data } = await settingsAPI.updateCustomProjectPriorityValue(payload);
    return data ?? [];
  }
);

export const updateCustomUsecasePriorityValue = createAsyncThunk(
  'settings/updateCustomUsecasePriorityValue',
  async (payload: PriorityNamePayload) => {
    const { data } = await settingsAPI.updateCustomUsecasePriorityValue(payload);
    return data ?? [];
  }
);

export const updateCustomTaskPriorityValue = createAsyncThunk(
  'settings/updateCustomTaskPriorityValue',
  async (payload: PriorityNamePayload) => {
    const { data } = await settingsAPI.updateCustomTaskPriorityValue(payload);
    return data ?? [];
  }
);

export const updateCustomTaskStageValue = createAsyncThunk(
  'settings/updateCustomTaskStageValue',
  async (payload: UpdateStageNamePayload) => {
    const { data } = await settingsAPI.updateCustomTaskStageValue(payload);
    return data ?? [];
  }
);

/*-----------------------------Integration calls---------------------- */
export const getMergeLink = createAsyncThunk('settings/getMergeLink', async (frontEndData: any) => {
  const { data } = await settingsAPI.getMergeLink(frontEndData);
  return data ?? [];
});
export const swapPublicToken = createAsyncThunk('settings/swapPublicToken', async (frontEndData: any) => {
  const { data } = await settingsAPI.swapPublicToken(frontEndData);
  return data ?? [];
});
export const updateCustomerPipedriveIntegrationsDetails = createAsyncThunk(
  'settings/updateCustomerIntegrationPipedriveDetails',
  async (frontEndData: any) => {
    const { data } = await settingsAPI.updateCustomerIntegrationPipedriveDetails(frontEndData);
    return data ?? [];
  }
);

export const getCustomerIntegrationDetails = createAsyncThunk('settings/getCustomerIntegrationDetails', async () => {
  const { data } = await settingsAPI.getCustomerIntegrationDetails();
  return data ?? [];
});
export const getCustomerIntegrationMicroService = createAsyncThunk(
  'settings/getCustomerIntegrationMicroService',
  async () => {
    const { data } = await settingsAPI.getCustomerIntegrationMicroService();
    return data ?? [];
  }
);

export const getCRMStagesSlice = createAsyncThunk('settings/getCRMStagesSlice', async () => {
  const { data } = await settingsAPI.getCRMStages();
  return data ?? [];
});
export const updateStageAndFetchModeSlice = createAsyncThunk(
  'settings/updateStageAndFetchMode',
  async (payload: any) => {
    const { data } = await settingsAPI.updateStageAndFetchMode(payload);
    return data ?? [];
  }
);

export const getPipedriveCrmCustomFieldsFromIntegrationsService = createAsyncThunk(
  'settings/getPipedriveCrmCustomFieldsFromIntegrationsService',
  async () => {
    const { data } = await settingsAPI.getCrmCustomFieldsFromIntegrations();
    return data;
  }
);
export const getPipedriveCrmCustomFieldsFromDb = createAsyncThunk(
  'settings/getPipedriveCrmCustomFieldsFromDb',
  async () => {
    const { data } = await settingsAPI.getCrmCustomFieldsFromDb();
    return data;
  }
);
export const updateCrmCustomFieldMappingInDb = createAsyncThunk(
  'settings/updateCrmCustomFieldMappingInDb',
  async (payload: UpdateCrmCustomFieldMapping) => {
    const { data } = await settingsAPI.updateCrmCustomfieldsInDB(payload);
    return data ?? [];
  }
);

export const settingsSlice = createSlice({
  name: 'setting data',
  initialState,
  reducers: {
    //without api calls
    resetProfileUpdateState: (state) => {
      state.updatingProfile = initialState.updatingProfile;
    },
    resetConfigurationUpdateState: (state) => {
      state.updatingConfiguration = initialState.updatingConfiguration;
    },
    resetUpdatedJourneyStageStatus: (state) => {
      state.updateJourneyTable = initialState.updateJourneyTable;
      state.updateJourneyTableExsiting = initialState.updateJourneyTableExsiting;
    },
    resetCustomLabelvalueUpdation: (state) => {
      state.updatingCustomLabels = initialState.updatingCustomLabels;
    },
    resetRevenueReportBasis: (state) => {
      state.revenueReportBasis = initialState.revenueReportBasis;
    },
    resetLogoUpdateStatus: (state) => {
      state.addUserLogo = initialState.addUserLogo;
      state.addCustomerLogo = initialState.addCustomerLogo;
    },
    resetManipulatedNewCustomFields: (state) => {
      state.manipulatingFields = initialState.manipulatingFields;
      state.manipulatingFields = initialState.manipulatingFields;
    },
    resetSendEmailToUsersWithCustomMessage: (state) => {
      state.sendEmailToUsersWithCustomMessage = initialState.manipulatingFields;
    },
  },
  extraReducers(builder) {
    builder
      //custom journey stages
      .addCase(fetchCustomJourneyStagesForClientConfigurations.pending, (state) => {
        state.customJourneyStageClientConfigurations.status = requestState.loading;
      })
      .addCase(fetchCustomJourneyStagesForClientConfigurations.fulfilled, (state, action: PayloadAction<any>) => {
        state.customJourneyStageClientConfigurations.status = requestState.success;
        state.customJourneyStageClientConfigurations.data = action.payload;
        console.log('CUSTOM JOURNERY STAGE', action);
      })
      .addCase(fetchCustomJourneyStagesForClientConfigurations.rejected, (state, action: any) => {
        state.customJourneyStageClientConfigurations.status = requestState.failed;
        state.customJourneyStageClientConfigurations.error = action.error.message;
      })

      .addCase(fetchJourneyStagesForClientConfigurations.pending, (state) => {
        state.journeyStageClientConfigurations.status = requestState.loading;
      })
      .addCase(fetchJourneyStagesForClientConfigurations.fulfilled, (state, action: PayloadAction<any>) => {
        state.journeyStageClientConfigurations.status = requestState.success;
        state.journeyStageClientConfigurations.data = action.payload;
        console.log(' JOURNERY STAGE', action);
      })
      .addCase(fetchJourneyStagesForClientConfigurations.rejected, (state, action: any) => {
        state.journeyStageClientConfigurations.status = requestState.failed;
        state.journeyStageClientConfigurations.error = action.error.message;
      })
      .addCase(updateTableForFirstTime.pending, (state) => {
        state.updateJourneyTable.status = requestState.loading;
      })
      .addCase(updateTableForFirstTime.fulfilled, (state, action: PayloadAction<any>) => {
        state.updateJourneyTable.status = requestState.success;
        console.log('sssssssssssssssss');
        state.updateJourneyTable.data = action.payload;
      })
      .addCase(updateTableForFirstTime.rejected, (state, action: any) => {
        state.updateJourneyTable.status = requestState.failed;
        state.updateJourneyTable.error = action.error.message;
      })

      .addCase(updateTable.pending, (state) => {
        state.updateJourneyTableExsiting.status = requestState.loading;
      })
      .addCase(updateTable.fulfilled, (state, action: PayloadAction<any>) => {
        state.updateJourneyTableExsiting.status = requestState.success;
        state.updateJourneyTableExsiting.data = action.payload;
      })
      .addCase(updateTable.rejected, (state, action: any) => {
        state.updateJourneyTableExsiting.status = requestState.failed;
        state.updateJourneyTableExsiting.error = action.error.message;
      })
      // get customer details
      .addCase(getCustomerDetails.pending, (state) => {
        state.customerDetails.status = requestState.loading;
      })
      .addCase(getCustomerDetails.fulfilled, (state, action: PayloadAction<any>) => {
        state.customerDetails.status = requestState.success;
        state.customerDetails.data = action.payload;
      })
      .addCase(getCustomerDetails.rejected, (state, action: any) => {
        state.customerDetails.status = requestState.failed;
        state.customerDetails.error = action.error.message;
      })
      // get user details
      .addCase(getUserDetails_slice.pending, (state) => {
        state.userDetails.status = requestState.loading;
      })
      .addCase(getUserDetails_slice.fulfilled, (state, action: PayloadAction<any>) => {
        state.userDetails.status = requestState.success;
        console.log(action.payload.reportees, 'user details settting');
        if (action.payload.reportees?.length > 0) {
          cookies.set('showTeamDashboardTab', true);
        } else {
          cookies.set('showTeamDashboardTab', false);
        }
        state.userDetails.data = action.payload;
      })
      .addCase(getUserDetails_slice.rejected, (state, action: any) => {
        state.userDetails.status = requestState.failed;
        state.userDetails.error = action.error.message;
      })

      .addCase(addCustomerLogo.pending, (state) => {
        state.addCustomerLogo.status = requestState.loading;
      })
      .addCase(addCustomerLogo.fulfilled, (state, action) => {
        state.addCustomerLogo.status = requestState.success;
        state.addCustomerLogo.error = action.payload.message;
        state.customerDetails.data = action.payload;
      })
      .addCase(addCustomerLogo.rejected, (state, action) => {
        state.addCustomerLogo.status = requestState.failed;
        state.addCustomerLogo.error = action.error.message;
      })

      .addCase(addUserLogo.pending, (state) => {
        state.addUserLogo.status = requestState.loading;
      })
      .addCase(addUserLogo.fulfilled, (state, action) => {
        state.addUserLogo.status = requestState.success;
        state.userDetails.data = action.payload;
      })
      .addCase(addUserLogo.rejected, (state, action) => {
        state.addUserLogo.status = requestState.failed;
        state.addUserLogo.error = action.error.message;
      })

      // get customer field client records
      .addCase(getCustomFieldClientRecords_slice.pending, (state) => {
        state.customerFieldClientRecords.status = requestState.loading;
      })
      .addCase(getCustomFieldClientRecords_slice.fulfilled, (state, action: PayloadAction<any>) => {
        state.customerFieldClientRecords.status = requestState.success;
        state.customerFieldClientRecords.data = action.payload;
      })
      .addCase(getCustomFieldClientRecords_slice.rejected, (state, action: any) => {
        state.customerFieldClientRecords.status = requestState.failed;
        state.customerFieldClientRecords.error = action.error.message;
      })
      // get customer field project records
      .addCase(getCustomFieldProjectRecords_slice.pending, (state) => {
        state.customerFieldProjectRecords.status = requestState.loading;
      })
      .addCase(getCustomFieldProjectRecords_slice.fulfilled, (state, action: PayloadAction<any>) => {
        state.customerFieldProjectRecords.status = requestState.success;
        state.customerFieldProjectRecords.data = [];
        state.customerFieldProjectRecords.data = action.payload;
      })
      .addCase(getCustomFieldProjectRecords_slice.rejected, (state, action: any) => {
        state.customerFieldProjectRecords.status = requestState.failed;
        state.customerFieldProjectRecords.error = action.error.message;
      })
      // get customer field usecase records
      .addCase(getCustomFieldUsecaseRecords_slice.pending, (state) => {
        state.customerFieldUsecaseRecords.status = requestState.loading;
      })
      .addCase(getCustomFieldUsecaseRecords_slice.fulfilled, (state, action: PayloadAction<any>) => {
        state.customerFieldUsecaseRecords.status = requestState.success;
        state.customerFieldUsecaseRecords.data = action.payload;
      })
      .addCase(getCustomFieldUsecaseRecords_slice.rejected, (state, action: any) => {
        state.customerFieldUsecaseRecords.status = requestState.failed;
        state.customerFieldUsecaseRecords.error = action.error.message;
      })
      // get customer field task records
      .addCase(getCustomFieldTaskRecords_slice.pending, (state) => {
        state.customerFieldTaskRecords.status = requestState.loading;
      })
      .addCase(getCustomFieldTaskRecords_slice.fulfilled, (state, action: PayloadAction<any>) => {
        state.customerFieldTaskRecords.status = requestState.success;
        state.customerFieldTaskRecords.data = action.payload;
      })
      .addCase(getCustomFieldTaskRecords_slice.rejected, (state, action: any) => {
        state.customerFieldTaskRecords.status = requestState.failed;
        state.customerFieldTaskRecords.error = action.error.message;
      })
      // get customer field task records
      .addCase(getNotificationData_slice.pending, (state) => {
        state.notificationData.status = requestState.loading;
      })
      .addCase(getNotificationData_slice.fulfilled, (state, action: PayloadAction<any>) => {
        state.notificationData.status = requestState.success;
        state.notificationData.data = action.payload;
      })
      .addCase(getNotificationData_slice.rejected, (state, action: any) => {
        state.notificationData.status = requestState.failed;
        state.notificationData.error = action.error.message;
      })
      // get all tasks due data
      .addCase(getAllTasksData_slice.pending, (state) => {
        state.taskDetails.status = requestState.loading;
      })
      .addCase(getAllTasksData_slice.fulfilled, (state, action: PayloadAction<any>) => {
        state.taskDetails.status = requestState.success;
        state.taskDetails.data = action.payload;
      })
      .addCase(getAllTasksData_slice.rejected, (state, action: any) => {
        state.taskDetails.status = requestState.failed;
        state.taskDetails.error = action.error.message;
      })
      // get custom view columns
      .addCase(getClientRecordColumnView.pending, (state) => {
        state.clientListColumns.status = requestState.loading;
      })
      .addCase(getClientRecordColumnView.fulfilled, (state, action: PayloadAction<any>) => {
        state.clientListColumns.status = requestState.success;
        console.log('mydata', action.payload);
        state.clientListColumns.data = action.payload.client_list_columns.split(',');
      })
      .addCase(getClientRecordColumnView.rejected, (state, action: any) => {
        state.clientListColumns.status = requestState.failed;
        state.clientListColumns.error = action.error.message;
      })
      // update jira token, url etc details to customer of cognisaas
      .addCase(updateJiraDetailsToCustomer.pending, (state) => {
        state.updatingJiraDetailsToCustomer.status = requestState.loading;
      })
      .addCase(updateJiraDetailsToCustomer.fulfilled, (state, action: PayloadAction<any>) => {
        state.updatingJiraDetailsToCustomer.status = requestState.success;
        console.log('update jira details', action.payload);
        state.updatingJiraDetailsToCustomer.data = action.payload;
      })
      .addCase(updateJiraDetailsToCustomer.rejected, (state, action: any) => {
        state.updatingJiraDetailsToCustomer.status = requestState.failed;
        state.updatingJiraDetailsToCustomer.error = action.error.message;
      })
      // updating jira account id for users
      .addCase(updateJiraAccountIdForUsers.pending, (state) => {
        state.updateJiraAccountIdOfUsers.status = requestState.loading;
      })
      .addCase(updateJiraAccountIdForUsers.fulfilled, (state, action: PayloadAction<any>) => {
        state.updateJiraAccountIdOfUsers.status = requestState.success;
        // console.log('update jira details', action.payload);
        state.updateJiraAccountIdOfUsers.data = action.payload;
      })
      .addCase(updateJiraAccountIdForUsers.rejected, (state, action: any) => {
        state.updateJiraAccountIdOfUsers.status = requestState.failed;
        state.updateJiraAccountIdOfUsers.error = action.error.message;
      })
      // add jira project key
      .addCase(addJiraProjectKeyToCustomer.pending, (state) => {
        state.addNewJiraProjectKeyToCustomer.status = requestState.loading;
      })
      .addCase(addJiraProjectKeyToCustomer.fulfilled, (state, action: PayloadAction<any>) => {
        state.addNewJiraProjectKeyToCustomer.status = requestState.success;
        // console.log('update jira details', action.payload);
        state.addNewJiraProjectKeyToCustomer.data = action.payload;
      })
      .addCase(addJiraProjectKeyToCustomer.rejected, (state, action: any) => {
        state.addNewJiraProjectKeyToCustomer.status = requestState.failed;
        state.addNewJiraProjectKeyToCustomer.error = action.error.message;
      })
      // jira projects from api
      .addCase(getJiraProjectsFromApi.pending, (state) => {
        state.allJiraProjectsDetails.status = requestState.loading;
      })
      .addCase(getJiraProjectsFromApi.fulfilled, (state, action: PayloadAction<any>) => {
        state.allJiraProjectsDetails.status = requestState.success;
        // console.log('update jira details', action.payload);
        state.allJiraProjectsDetails.data = action.payload;
      })
      .addCase(getJiraProjectsFromApi.rejected, (state, action: any) => {
        state.allJiraProjectsDetails.status = requestState.failed;
        state.allJiraProjectsDetails.error = action.error.message;
      })
      // project added to jira from customer
      .addCase(fetchProjectAddedToCustomerAndJira.pending, (state) => {
        state.jiraProjectsAlreadyAdded.status = requestState.loading;
      })
      .addCase(fetchProjectAddedToCustomerAndJira.fulfilled, (state, action: PayloadAction<any>) => {
        state.jiraProjectsAlreadyAdded.status = requestState.success;
        // console.log('update jira details', action.payload)
        if (!isNull(action.payload)) {
          state.jiraProjectsAlreadyAdded.data = action.payload;
        } else {
          state.jiraProjectsAlreadyAdded.data = [];
        }
      })
      .addCase(fetchProjectAddedToCustomerAndJira.rejected, (state, action: any) => {
        state.jiraProjectsAlreadyAdded.status = requestState.failed;
        state.jiraProjectsAlreadyAdded.error = action.error.message;
      })
      // deleteCustomerJiraProject
      .addCase(deleteCustomerJiraProject.pending, (state) => {
        state.deleteAlreadyAddedJiraProjects.status = requestState.loading;
      })
      .addCase(deleteCustomerJiraProject.fulfilled, (state, action: PayloadAction<any>) => {
        state.deleteAlreadyAddedJiraProjects.status = requestState.success;
        state.deleteAlreadyAddedJiraProjects.data = action.payload;
      })
      .addCase(deleteCustomerJiraProject.rejected, (state, action: any) => {
        state.deleteAlreadyAddedJiraProjects.status = requestState.failed;
        state.deleteAlreadyAddedJiraProjects.error = action.error.message;
      })
      // fetchJiraCustomProductStages
      .addCase(fetchJiraCustomProductStages.pending, (state) => {
        state.jiraCustomProductStages.status = requestState.loading;
      })
      .addCase(fetchJiraCustomProductStages.fulfilled, (state, action: PayloadAction<any>) => {
        state.jiraCustomProductStages.status = requestState.success;
        state.jiraCustomProductStages.data = action.payload;
      })
      .addCase(fetchJiraCustomProductStages.rejected, (state, action: any) => {
        state.jiraCustomProductStages.status = requestState.failed;
        state.jiraCustomProductStages.error = action.error.message;
      })
      // fetchJiraCustomTaskStages
      .addCase(fetchJiraCustomTaskStages.pending, (state) => {
        state.jiraCustomTaskStages.status = requestState.loading;
      })
      .addCase(fetchJiraCustomTaskStages.fulfilled, (state, action: PayloadAction<any>) => {
        state.jiraCustomTaskStages.status = requestState.success;
        state.jiraCustomTaskStages.data = action.payload;
      })
      .addCase(fetchJiraCustomTaskStages.rejected, (state, action: any) => {
        state.jiraCustomTaskStages.status = requestState.failed;
        state.jiraCustomTaskStages.error = action.error.message;
      })
      // updateJiraProductCustomStage
      .addCase(updateJiraProductCustomStage.pending, (state) => {
        state.updatingProductCustomStage.status = requestState.loading;
      })
      .addCase(updateJiraProductCustomStage.fulfilled, (state) => {
        state.updatingProductCustomStage.status = requestState.success;
      })
      .addCase(updateJiraProductCustomStage.rejected, (state, action: any) => {
        state.updatingProductCustomStage.status = requestState.failed;
        state.updatingProductCustomStage.error = action.error.message;
      })
      // updateJiraTaskCustomStage
      .addCase(updateJiraTaskCustomStage.pending, (state) => {
        state.updatingTaskCustomStage.status = requestState.loading;
      })
      .addCase(updateJiraTaskCustomStage.fulfilled, (state) => {
        state.updatingTaskCustomStage.status = requestState.success;
      })
      .addCase(updateJiraTaskCustomStage.rejected, (state, action: any) => {
        state.updatingTaskCustomStage.status = requestState.failed;
        state.updatingTaskCustomStage.error = action.error.message;
      })
      // getJiraStatusByJiraProjectKey
      .addCase(getJiraStatusByJiraProjectKey.pending, (state) => {
        state.jiraStatuses.status = requestState.loading;
      })
      .addCase(getJiraStatusByJiraProjectKey.fulfilled, (state, action: PayloadAction<any>) => {
        state.jiraStatuses.status = requestState.success;
        state.jiraStatuses.data = JSON.parse(action.payload);
      })
      .addCase(getJiraStatusByJiraProjectKey.rejected, (state, action: any) => {
        state.jiraStatuses.status = requestState.failed;
        state.jiraStatuses.error = action.error.message;
      })
      // getProductInfoByJiraProductIssueId
      .addCase(getProductInfoByJiraProductIssueId.pending, (state) => {
        state.productInfo.status = requestState.loading;
      })
      .addCase(getProductInfoByJiraProductIssueId.fulfilled, (state, action: PayloadAction<any>) => {
        state.productInfo.status = requestState.success;
        state.productInfo.data = action.payload;
      })
      .addCase(getProductInfoByJiraProductIssueId.rejected, (state, action: any) => {
        state.productInfo.status = requestState.failed;
        state.productInfo.error = action.error.message;
      })
      //getJiraServiceDesksByJiraProjectKey
      .addCase(getJiraServiceDesksByJiraProjectKey.pending, (state) => {
        state.serviceDesks.status = requestState.loading;
      })
      .addCase(getJiraServiceDesksByJiraProjectKey.fulfilled, (state, action: PayloadAction<any>) => {
        state.serviceDesks.status = requestState.success;
        console.log(action.payload, 'ttttt');
        state.serviceDesks.data = JSON.parse(action.payload);
      })
      .addCase(getJiraServiceDesksByJiraProjectKey.rejected, (state, action: any) => {
        state.serviceDesks.status = requestState.failed;
        state.serviceDesks.error = action.error.message;
      })
      // getJiraJsdServicesRequestLists
      .addCase(getJiraJsdServicesRequestLists.pending, (state) => {
        state.jiraServiceRequestList.status = requestState.loading;
      })
      .addCase(getJiraJsdServicesRequestLists.fulfilled, (state, action: PayloadAction<any>) => {
        state.jiraServiceRequestList.status = requestState.success;
        console.log(action.payload, 'ttttt');
        state.jiraServiceRequestList.data = JSON.parse(action.payload);
      })
      .addCase(getJiraJsdServicesRequestLists.rejected, (state, action: any) => {
        state.jiraServiceRequestList.status = requestState.failed;
        state.jiraServiceRequestList.error = action.error.message;
      })
      //getJiraJsdCustomFields
      .addCase(getJiraJsdCustomFields.pending, (state) => {
        state.jsdCustomFields.status = requestState.loading;
      })
      .addCase(getJiraJsdCustomFields.fulfilled, (state, action: PayloadAction<any>) => {
        state.jsdCustomFields.status = requestState.success;
        console.log(action.payload, 'ttttt');
        state.jsdCustomFields.data = JSON.parse(action.payload);
      })
      .addCase(getJiraJsdCustomFields.rejected, (state, action: any) => {
        state.jsdCustomFields.status = requestState.failed;
        state.jsdCustomFields.error = action.error.message;
      })
      // updateCustomFieldAttachmentStatus
      .addCase(updateCustomFieldAttachmentStatus.pending, (state) => {
        state.updateCustomFieldAttachmentStatus.status = requestState.loading;
        state.manipulatingFields.status = requestState.loading;
      })
      .addCase(updateCustomFieldAttachmentStatus.fulfilled, (state) => {
        state.updateCustomFieldAttachmentStatus.status = requestState.success;
        state.manipulatingFields.status = requestState.success;
        state.manipulatingFields.message = 'Attachment Status Updated';
      })
      .addCase(updateCustomFieldAttachmentStatus.rejected, (state, action: any) => {
        state.updateCustomFieldAttachmentStatus.status = requestState.failed;
        state.updateCustomFieldAttachmentStatus.error = action.error.message;
        state.manipulatingFields.status = requestState.failed;
      })
      // updateCustomFieldMandatoryStatus
      .addCase(updateCustomFieldMandatoryStatus.pending, (state) => {
        state.updateCustomFieldMandatoryStatus.status = requestState.loading;
        state.manipulatingFields.status = requestState.loading;
      })
      .addCase(updateCustomFieldMandatoryStatus.fulfilled, (state) => {
        state.manipulatingFields.status = requestState.success;
        state.manipulatingFields.message = 'Mandatory Status Updated';
      })
      .addCase(updateCustomFieldMandatoryStatus.rejected, (state, action: any) => {
        state.updateCustomFieldMandatoryStatus.status = requestState.failed;
        state.updateCustomFieldMandatoryStatus.error = action.error.message;
        state.manipulatingFields.status = requestState.failed;
      })
      // updateCustomProjectField
      .addCase(updateCustomProjectField.pending, (state) => {
        state.updateCustomProjectField.status = requestState.loading;
        state.manipulatingFields.status = requestState.loading;
      })
      .addCase(updateCustomProjectField.fulfilled, (state) => {
        state.updateCustomProjectField.status = requestState.success;
        state.manipulatingFields.status = requestState.success;
        state.manipulatingFields.message = `Custom field for ${label_project.toLowerCase()} updated successfully`;
      })
      .addCase(updateCustomProjectField.rejected, (state, action: any) => {
        state.updateCustomProjectField.status = requestState.failed;
        state.updateCustomProjectField.error = action.error.message;
        state.manipulatingFields.status = requestState.failed;
      })
      .addCase(updateCustomUsecaseField.pending, (state) => {
        state.updateCustomUsecaseField.status = requestState.loading;
        state.manipulatingFields.status = requestState.loading;
      })
      .addCase(updateCustomUsecaseField.fulfilled, (state) => {
        state.updateCustomUsecaseField.status = requestState.success;
        state.manipulatingFields.status = requestState.success;
        state.manipulatingFields.message = `Custom field for ${label_use_case.toLowerCase()} updated successfully`;
      })
      .addCase(updateCustomUsecaseField.rejected, (state, action: any) => {
        state.updateCustomUsecaseField.status = requestState.failed;
        state.updateCustomUsecaseField.error = action.error.message;
        state.manipulatingFields.status = requestState.failed;
      })
      .addCase(updateCustomTaskField.pending, (state) => {
        state.updateCustomTaskField.status = requestState.loading;
        state.manipulatingFields.status = requestState.loading;
      })
      .addCase(updateCustomTaskField.fulfilled, (state) => {
        state.updateCustomTaskField.status = requestState.success;
        state.manipulatingFields.status = requestState.success;
        state.manipulatingFields.message = `Custom field for ${label_task.toLowerCase()} updated successfully`;
      })
      .addCase(updateCustomTaskField.rejected, (state, action: any) => {
        state.updateCustomTaskField.status = requestState.failed;
        state.updateCustomTaskField.error = action.error.message;
        state.manipulatingFields.status = requestState.failed;
      })
      .addCase(deleteCustomProjectField.pending, (state) => {
        state.deleteCustomProjectField.status = requestState.loading;
        state.manipulatingFields.status = requestState.loading;
      })
      .addCase(deleteCustomProjectField.fulfilled, (state) => {
        state.deleteCustomProjectField.status = requestState.success;
        state.manipulatingFields.status = requestState.success;
        state.manipulatingFields.message = 'Deleted successfully';
      })
      .addCase(deleteCustomProjectField.rejected, (state, action: any) => {
        state.deleteCustomProjectField.status = requestState.failed;
        state.deleteCustomProjectField.error = action.error.message;
        state.manipulatingFields.status = requestState.failed;
      })
      .addCase(deleteCustomUsecaseField.pending, (state) => {
        state.deleteCustomUsecaseField.status = requestState.loading;
        state.manipulatingFields.status = requestState.loading;
      })
      .addCase(deleteCustomUsecaseField.fulfilled, (state) => {
        state.deleteCustomUsecaseField.status = requestState.success;
        state.manipulatingFields.status = requestState.success;
        state.manipulatingFields.message = 'Deleted successfully';
      })
      .addCase(deleteCustomUsecaseField.rejected, (state, action: any) => {
        state.deleteCustomUsecaseField.status = requestState.failed;
        state.deleteCustomUsecaseField.error = action.error.message;
        state.manipulatingFields.status = requestState.failed;
      })
      .addCase(deleteCustomTaskField.pending, (state) => {
        state.deleteCustomTaskField.status = requestState.loading;
        state.manipulatingFields.status = requestState.failed;
      })
      .addCase(deleteCustomTaskField.fulfilled, (state) => {
        state.deleteCustomTaskField.status = requestState.success;
        state.manipulatingFields.status = requestState.success;
        state.manipulatingFields.message = 'Deleted successfully';
      })
      .addCase(deleteCustomTaskField.rejected, (state, action: any) => {
        state.deleteCustomTaskField.status = requestState.failed;
        state.deleteCustomTaskField.error = action.error.message;
        state.manipulatingFields.status = requestState.failed;
      })
      .addCase(deleteCustomClientField.pending, (state) => {
        state.deleteCustomClientField.status = requestState.loading;
        state.manipulatingFields.status = requestState.loading;
      })
      .addCase(deleteCustomClientField.fulfilled, (state) => {
        state.deleteCustomClientField.status = requestState.success;
        state.manipulatingFields.status = requestState.success;
        state.manipulatingFields.message = `Custom fields for ${label_client.toLowerCase()} deleted successfully`;
      })
      .addCase(deleteCustomClientField.rejected, (state, action: any) => {
        state.deleteCustomClientField.status = requestState.failed;
        state.deleteCustomClientField.error = action.error.message;
        state.manipulatingFields.status = requestState.failed;
      })
      // email config
      .addCase(addEmailConfig.pending, (state) => {
        state.addEmailConfig.status = requestState.loading;
      })
      .addCase(addEmailConfig.fulfilled, (state, action) => {
        state.addEmailConfig.status = requestState.success;
        // console.log('action = ', action);
        if (isNull(action.payload)) state.addEmailConfig.data = '';
        else state.addEmailConfig.data = action.payload;
      })
      .addCase(addEmailConfig.rejected, (state, action) => {
        state.addEmailConfig.status = requestState.failed;
        state.addEmailConfig.error = action.error.message;
      })

      .addCase(getEmailConfig.pending, (state) => {
        state.getEmailConfig.status = requestState.loading;
      })
      .addCase(getEmailConfig.fulfilled, (state, action) => {
        state.getEmailConfig.status = requestState.success;
        // console.log('action = ', action);
        if (isNull(action.payload)) state.getEmailConfig.data = '';
        else state.getEmailConfig.data = action.payload;
      })
      .addCase(getEmailConfig.rejected, (state, action) => {
        state.getEmailConfig.status = requestState.failed;
        state.getEmailConfig.error = action.error.message;
      })

      .addCase(deleteEmailConfig.pending, (state) => {
        state.deleteEmailConfig.status = requestState.loading;
      })
      .addCase(deleteEmailConfig.fulfilled, (state, action) => {
        state.deleteEmailConfig.status = requestState.success;
        // console.log('action = ', action);
        if (isNull(action.payload)) state.deleteEmailConfig.data = '';
        else state.deleteEmailConfig.data = action.payload;
      })
      .addCase(deleteEmailConfig.rejected, (state, action) => {
        state.deleteEmailConfig.status = requestState.failed;
        state.deleteEmailConfig.error = action.error.message;
      })

      .addCase(changeEmailConfig.pending, (state) => {
        state.changeEmailConfig.status = requestState.loading;
      })
      .addCase(changeEmailConfig.fulfilled, (state, action) => {
        state.changeEmailConfig.status = requestState.success;
        // console.log('action = ', action);
        if (isNull(action.payload)) state.changeEmailConfig.data = '';
        else state.changeEmailConfig.data = action.payload;
      })
      .addCase(changeEmailConfig.rejected, (state, action) => {
        state.changeEmailConfig.status = requestState.failed;
        state.changeEmailConfig.error = action.error.message;
      })
      // profile user name update
      .addCase(updateUsername.pending, (state) => {
        state.updatingProfile.status = requestState.loading;
      })
      .addCase(updateUsername.fulfilled, (state) => {
        state.updatingProfile.status = requestState.success;
        state.updatingProfile.message = 'Updated Successfully!';
      })
      .addCase(updateUsername.rejected, (state, action: any) => {
        state.updatingProfile.status = requestState.failed;
        state.updatingProfile.message = action.error.message;
      })
      // profile user email update
      .addCase(updateUserEmail.pending, (state) => {
        state.updatingProfile.status = requestState.loading;
      })
      .addCase(updateUserEmail.fulfilled, (state) => {
        state.updatingProfile.status = requestState.success;
        state.updatingProfile.message = 'Updated Successfully!';
      })
      .addCase(updateUserEmail.rejected, (state, action: any) => {
        state.updatingProfile.status = requestState.failed;
        state.updatingProfile.message = action.error.message;
      })
      // update column order slice updateClientRecordViewColumns_slice
      .addCase(updateClientRecordViewColumns_slice.pending, (state) => {
        state.updatingConfiguration.status = requestState.loading;
      })
      .addCase(updateClientRecordViewColumns_slice.fulfilled, (state) => {
        state.updatingConfiguration.status = requestState.success;
        state.updatingConfiguration.message = 'Updated Successfully!';
      })
      .addCase(updateClientRecordViewColumns_slice.rejected, (state, action: any) => {
        state.updatingConfiguration.status = requestState.failed;
        state.updatingConfiguration.message = action.error.message;
      })
      // updateUseCase_Configuration
      .addCase(updateUseCase_Configuration.pending, (state) => {
        state.updatingConfiguration.status = requestState.loading;
      })
      .addCase(updateUseCase_Configuration.fulfilled, (state) => {
        state.updatingConfiguration.status = requestState.success;
        state.updatingConfiguration.message = 'Updated Successfully!';
      })
      .addCase(updateUseCase_Configuration.rejected, (state, action: any) => {
        state.updatingConfiguration.status = requestState.failed;
        state.updatingConfiguration.message = action.error.message;
      })
      // setupRenamedLabels
      .addCase(updateCustomLabelValue.pending, (state) => {
        state.updatingCustomLabels.status = requestState.loading;
      })
      .addCase(updateCustomLabelValue.fulfilled, (state, action) => {
        state.updatingCustomLabels.status = requestState.success;
        console.log('my payload', action.payload);
        state.updatingCustomLabels.error = 'Updated Successfully!';
      })
      .addCase(updateCustomLabelValue.rejected, (state, action: any) => {
        state.updatingCustomLabels.status = requestState.failed;
        state.updatingCustomLabels.error = action.error.message;
      })
      // insertCustomLabelValues
      .addCase(insertCustomLabelValues.pending, (state) => {
        state.insertingCustomLabels.status = requestState.loading;
      })
      .addCase(insertCustomLabelValues.fulfilled, (state, action) => {
        state.insertingCustomLabels.status = requestState.success;
        console.log('my payload', action.payload);
        state.insertingCustomLabels.data = action.payload;
        state.insertingCustomLabels.error = 'Updated Successfully!';
      })
      .addCase(insertCustomLabelValues.rejected, (state, action: any) => {
        state.insertingCustomLabels.status = requestState.failed;
        state.insertingCustomLabels.error = action.error.message;
      })

      // Update Customer Holidays
      .addCase(updateCustomerHolidays.pending, (state) => {
        state.updateCustomerHolidays.status = requestState.loading;
      })
      .addCase(updateCustomerHolidays.fulfilled, (state) => {
        state.updateCustomerHolidays.status = requestState.success;
      })
      .addCase(updateCustomerHolidays.rejected, (state, action: any) => {
        state.updateCustomerHolidays.status = requestState.failed;
        state.updateCustomerHolidays.error = action.error.message;
      })
      // custom field adding in settings addCustomFieldUsecaseByCustomerUid
      .addCase(addCustomFieldByCustomerUid.pending, (state) => {
        state.manipulatingFields.status = requestState.loading;
      })
      .addCase(addCustomFieldByCustomerUid.fulfilled, (state, action) => {
        if (action.payload.status == 0) {
          state.manipulatingFields.status = requestState.failed;
          state.manipulatingFields.message = action.payload.message;
        } else {
          state.manipulatingFields.status = requestState.success;
          state.manipulatingFields.message = `Custom field for ${label_client.toLowerCase()} added successfully`;
        }
      })
      .addCase(addCustomFieldByCustomerUid.rejected, (state, action: any) => {
        state.manipulatingFields.status = requestState.failed;
        state.manipulatingFields.message = action.error.message;
      })
      .addCase(addCustomFieldUsecaseByCustomerUid.pending, (state) => {
        state.manipulatingFields.status = requestState.loading;
      })
      .addCase(addCustomFieldUsecaseByCustomerUid.fulfilled, (state, action) => {
        if (action.payload.status == 0) {
          state.manipulatingFields.status = requestState.failed;
          state.manipulatingFields.message = action.payload.message;
        } else {
          state.manipulatingFields.status = requestState.success;
          state.manipulatingFields.message = `Custom field for ${label_use_case} added successfully`;
        }
      })
      .addCase(addCustomFieldUsecaseByCustomerUid.rejected, (state, action: any) => {
        state.manipulatingFields.status = requestState.failed;
        state.manipulatingFields.message = action.error.message;
      })
      .addCase(addCustomFieldProjectByCustomerUid.pending, (state) => {
        state.manipulatingFields.status = requestState.loading;
      })
      .addCase(addCustomFieldProjectByCustomerUid.fulfilled, (state, action) => {
        if (action.payload.status == 0) {
          state.manipulatingFields.status = requestState.failed;
          state.manipulatingFields.message = action.payload.message;
        } else {
          state.manipulatingFields.status = requestState.success;
          state.manipulatingFields.message = `Custom field for ${label_project.toLowerCase()} added successfully`;
        }
      })
      .addCase(addCustomFieldProjectByCustomerUid.rejected, (state, action: any) => {
        state.manipulatingFields.status = requestState.failed;
        state.manipulatingFields.message = action.error.message;
      })
      .addCase(addCustomFieldTaskByCustomerUid.pending, (state) => {
        state.manipulatingFields.status = requestState.loading;
      })
      .addCase(addCustomFieldTaskByCustomerUid.fulfilled, (state, action) => {
        if (action.payload.status == 0) {
          state.manipulatingFields.status = requestState.failed;
          state.manipulatingFields.message = action.payload.message;
        } else {
          state.manipulatingFields.status = requestState.success;
          state.manipulatingFields.message = `Custom field for ${label_task.toLowerCase()} added successfully`;
        }
      })
      .addCase(addCustomFieldTaskByCustomerUid.rejected, (state, action: any) => {
        state.manipulatingFields.status = requestState.failed;
        state.manipulatingFields.message = action.error.message;
      })
      // updateCustomFieldByCustomerUid
      .addCase(updateCustomFieldByCustomerUid.pending, (state) => {
        state.manipulatingFields.status = requestState.loading;
      })
      .addCase(updateCustomFieldByCustomerUid.fulfilled, (state) => {
        state.manipulatingFields.status = requestState.success;
        state.manipulatingFields.message = `Custom field for ${label_client.toLowerCase()} updated successfully`;
      })
      .addCase(updateCustomFieldByCustomerUid.rejected, (state, action: any) => {
        state.manipulatingFields.status = requestState.failed;
        state.manipulatingFields.message = action.error.message;
      })
      // updateRevenueReportBasis
      .addCase(updateRevenueReportBasis.pending, (state) => {
        state.revenueReportBasis.status = requestState.loading;
      })
      .addCase(updateRevenueReportBasis.fulfilled, (state) => {
        state.revenueReportBasis.status = requestState.success;
        state.revenueReportBasis.message = 'Updated Successfully';
      })
      .addCase(updateRevenueReportBasis.rejected, (state, action: any) => {
        state.revenueReportBasis.status = requestState.failed;
        state.revenueReportBasis.message = action.error.message;
      })
      // update segment vales
      .addCase(updateCustomClientSegmentValue.pending, (state) => {
        state.updatingConfiguration.status = requestState.loading;
      })
      .addCase(updateCustomClientSegmentValue.fulfilled, (state) => {
        state.updatingConfiguration.status = requestState.success;
        state.updatingConfiguration.message = 'Custom name updated successfully';
      })
      .addCase(updateCustomClientSegmentValue.rejected, (state, action: any) => {
        state.updatingConfiguration.status = requestState.failed;
        state.updatingConfiguration.message = action.error.message;
      })
      // updateCustomProjectCategoryValue
      .addCase(updateCustomProjectCategoryValue.pending, (state) => {
        state.updatingConfiguration.status = requestState.loading;
      })
      .addCase(updateCustomProjectCategoryValue.fulfilled, (state) => {
        state.updatingConfiguration.status = requestState.success;
        state.updatingConfiguration.message = 'Custom name updated successfully';
      })
      .addCase(updateCustomProjectCategoryValue.rejected, (state, action: any) => {
        state.updatingConfiguration.status = requestState.failed;
        state.updatingConfiguration.message = action.error.message;
      })
      // updateCustomProjectStageValue
      .addCase(updateCustomProjectStageValue.pending, (state) => {
        state.updatingConfiguration.status = requestState.loading;
      })
      .addCase(updateCustomProjectStageValue.fulfilled, (state) => {
        state.updatingConfiguration.status = requestState.success;
        state.updatingConfiguration.message = 'Custom name updated successfully';
      })
      .addCase(updateCustomProjectStageValue.rejected, (state, action: any) => {
        state.updatingConfiguration.status = requestState.failed;
        state.updatingConfiguration.message = action.error.message;
      })
      // sendEmailToUsers
      .addCase(sendEmailToUsersWithCustomMessage.pending, (state) => {
        state.sendEmailToUsersWithCustomMessage.status = requestState.loading;
      })
      .addCase(sendEmailToUsersWithCustomMessage.fulfilled, (state) => {
        state.sendEmailToUsersWithCustomMessage.status = requestState.success;
        state.sendEmailToUsersWithCustomMessage.message = 'Email sent successfully';
      })
      .addCase(sendEmailToUsersWithCustomMessage.rejected, (state, action: any) => {
        state.sendEmailToUsersWithCustomMessage.status = requestState.failed;
        state.sendEmailToUsersWithCustomMessage.message = action.error.message;
      })
      // updateCustomUsecaseCategoryValue
      .addCase(updateCustomUsecaseCategoryValue.pending, (state) => {
        state.updatingConfiguration.status = requestState.loading;
      })
      .addCase(updateCustomUsecaseCategoryValue.fulfilled, (state) => {
        state.updatingConfiguration.status = requestState.success;
        state.updatingConfiguration.message = 'Custom name updated successfully';
      })
      .addCase(updateCustomUsecaseCategoryValue.rejected, (state, action: any) => {
        state.updatingConfiguration.status = requestState.failed;
        state.updatingConfiguration.message = action.error.message;
      })
      // updateCustomUsecaseStageValue
      .addCase(updateCustomUsecaseStageValue.pending, (state) => {
        state.updatingConfiguration.status = requestState.loading;
      })
      .addCase(updateCustomUsecaseStageValue.fulfilled, (state) => {
        state.updatingConfiguration.status = requestState.success;
        state.updatingConfiguration.message = 'Custom name updated successfully';
      })
      .addCase(updateCustomUsecaseStageValue.rejected, (state, action: any) => {
        state.updatingConfiguration.status = requestState.failed;
        state.updatingConfiguration.message = action.error.message;
      })
      /*----------------------Integration details------------------------*/
      .addCase(getCustomerIntegrationDetails.pending, (state) => {
        state.customerIntegrationDetails.status = requestState.loading;
      })
      .addCase(getCustomerIntegrationDetails.fulfilled, (state, action) => {
        state.customerIntegrationDetails.status = requestState.success;
        state.customerIntegrationDetails.data = action.payload;
        state.customerIntegrationDetails.message = 'Fetched Successfully';
      })
      .addCase(getCustomerIntegrationDetails.rejected, (state, action: any) => {
        state.customerIntegrationDetails.status = requestState.failed;
        state.customerIntegrationDetails.message = action.error.message;
      })
      .addCase(getCustomerIntegrationMicroService.pending, (state) => {
        state.customerIntegrationMicroDetails.status = requestState.loading;
      })
      .addCase(getCustomerIntegrationMicroService.fulfilled, (state, action) => {
        state.customerIntegrationMicroDetails.status = requestState.success;
        state.customerIntegrationMicroDetails.data = action.payload;
        state.customerIntegrationMicroDetails.message = 'Fetched Successfully';
      })
      .addCase(getCustomerIntegrationMicroService.rejected, (state, action: any) => {
        state.customerIntegrationMicroDetails.status = requestState.failed;
        state.customerIntegrationMicroDetails.message = action.error.message;
      })
      .addCase(getCRMStagesSlice.pending, (state) => {
        state.crmIntegrationStages.status = requestState.loading;
      })
      .addCase(getCRMStagesSlice.fulfilled, (state, action) => {
        state.crmIntegrationStages.status = requestState.success;
        state.crmIntegrationStages.data = JSON.parse(action.payload.data);
        state.crmIntegrationStages.message = 'Fetched Successfully';
      })
      .addCase(getCRMStagesSlice.rejected, (state, action: any) => {
        state.crmIntegrationStages.status = requestState.failed;
        state.crmIntegrationStages.message = action.error.message;
      })
      // getPipedriveCrmCustomFieldsFromIntegrationsService
      .addCase(getPipedriveCrmCustomFieldsFromIntegrationsService.pending, (state) => {
        state.crmCustomFieldsFromIntegrations.status = requestState.loading;
      })
      .addCase(getPipedriveCrmCustomFieldsFromIntegrationsService.fulfilled, (state, action) => {
        state.crmCustomFieldsFromIntegrations.status = requestState.success;
        if (action.payload.status == '1') {
          state.crmCustomFieldsFromIntegrations.data = JSON.parse(action.payload.result).data;
        } else {
          state.crmCustomFieldsFromIntegrations.data = [];
        }
        state.crmCustomFieldsFromIntegrations.message = 'Fetched Successfully';
      })
      .addCase(getPipedriveCrmCustomFieldsFromIntegrationsService.rejected, (state, action: any) => {
        state.crmCustomFieldsFromIntegrations.status = requestState.failed;
        state.crmCustomFieldsFromIntegrations.message = action.error.message;
      })

      // getCrmCustomFieldsFromDb
      .addCase(getPipedriveCrmCustomFieldsFromDb.pending, (state) => {
        state.crmCustomFieldsFromDb.status = requestState.loading;
      })
      .addCase(getPipedriveCrmCustomFieldsFromDb.fulfilled, (state, action) => {
        state.crmCustomFieldsFromDb.status = requestState.success;
        if (!isNull(action.payload.result)) {
          state.crmCustomFieldsFromDb.data = action.payload.result;
        } else {
          state.crmCustomFieldsFromDb.data = [];
        }
        state.crmCustomFieldsFromDb.message = 'Fetched Successfully';
      })
      .addCase(getPipedriveCrmCustomFieldsFromDb.rejected, (state, action: any) => {
        state.crmCustomFieldsFromDb.status = requestState.failed;
        state.crmCustomFieldsFromDb.message = action.error.message;
      });
  },
});
const settingsReducer = settingsSlice.reducer;
export const {
  resetProfileUpdateState,
  resetConfigurationUpdateState,
  resetUpdatedJourneyStageStatus,
  resetCustomLabelvalueUpdation,
  resetRevenueReportBasis,
  resetLogoUpdateStatus,
  resetManipulatedNewCustomFields,
  resetSendEmailToUsersWithCustomMessage,
} = settingsSlice.actions;
export default settingsReducer;
