import { createSelector } from '@reduxjs/toolkit';
import { requestState } from 'store/modules/types';
import { RootState } from '../../..';

export const selectReportsTasksState = (state: RootState) => state.reportsTasksState;
export const selectReportsClients = createSelector(
  selectReportsTasksState,
  (reportsClientsState) => reportsClientsState.clients
);
export const selectReportsClientsStatus = createSelector(
  selectReportsTasksState,
  (reportsClientsState) => reportsClientsState.status
);
export const selectReportsClientsError = createSelector(
  selectReportsTasksState,
  (reportsClientsState) => reportsClientsState.error
);

// actual reports tasks
export const selectTeamOpenTasks = createSelector(
  selectReportsTasksState,
  (reportsClientsState) => filterDataForUid(reportsClientsState.teamOpenTasks)
);
export const selectTeamClosedTasks = createSelector(
  selectReportsTasksState,
  (reportsClientsState) => filterDataForUid(reportsClientsState.teamClosedTasks)
);
export const selectClientOpenTasks = createSelector(
  selectReportsTasksState,
  (reportsClientsState) => filterDataForUid(reportsClientsState.clientOpenTasks)
);
export const selectClientClosedTasks = createSelector(
  selectReportsTasksState,
  (reportsClientsState) => filterDataForUid(reportsClientsState.clientClosedTasks)
);

export const selectAllSubtasks = createSelector(
  selectReportsTasksState,
  (reportsClientsState) => reportsClientsState.subtasks.data
);

export const selectTasksSliceStatus= createSelector(
  selectReportsTasksState,
  (reportsClientsState) => reportsClientsState.status
);


interface selectedDataType {
  data: any[];
  status: requestState;
  error: null | undefined | string;
}

// filters task uid with duplicate task uids
const filterDataForUid = (selectedData:selectedDataType) => {
  const taskUids = new Set();
  const data = selectedData.data;
  const filteredData = data.filter((item:any) => {
    if(!taskUids.has(item.task_uid)) {
      taskUids.add(item.task_uid);
      return item;
    }
  });
  return {...selectedData, data:filteredData};
};