import { useState, useMemo, useEffect } from 'react';
import {
  FormControl,
  Select,
  MenuItem,
  ListSubheader,
  TextField,
  InputAdornment,
  SelectChangeEvent,
  // Checkbox,
  ListItemText,
  SelectProps,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CognisaasCheckBox from '../CognisaasCheckbox/CognisaasCheckbox';

const containsText = (text: string, searchText: string) => text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

// interface Props {
//   placeholder?: string;
//   selectedOption: string[];
//   setSelectedOptions: React.Dispatch<React.SetStateAction<string[]>>;
//   allOptions: any[];
// }

export default function CognisaasMultiSelectWithSearchUid(props: SelectProps & any) {
  //   const [selectedOption, setSelectedOption] = useState<string[]>([]);

  const [searchText, setSearchText] = useState('');
  const displayedOptions = useMemo(
    () => props.allOptions.filter((option: any) => containsText(option.name, searchText)),
    [searchText]
  );

  const handleChange = (event: SelectChangeEvent<typeof props.selectedOption>) => {
    const {
      target: { value },
    } = event;
    props.setSelectedOptions(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };
  const [mappingObject, setmappingObject] = useState<Record<string, string>>({});
  useEffect(() => {
    const tempObj: any = {};
    props.allOptions.forEach((element: any) => {
      tempObj[`${element.id}`] = element.name;
    });
    setmappingObject(tempObj);
    setSearchText('');
  }, [props.allOptions]);

  return (
    <FormControl fullWidth size="small">
      <Select
        // Disables auto focus on MenuItems and allows TextField to be in focus
        defaultOpen={props.shouldBeOpenByDefault ? true : false}
        // displayEmpty
        MenuProps={{ autoFocus: false, PaperProps: { sx: { maxHeight: 250 } } }}
        inputProps={{ 'aria-label': 'Without label' }}
        labelId="search-select-label"
        id="search-select"
        value={props.selectedOption}
        onChange={handleChange}
        onClose={() => setSearchText('')}
        multiple={props.multiple === false ? undefined : true}
        disabled={props?.disabled}
        // This prevents rendering empty string in Select's value
        // if search text would exclude currently selected option.
        renderValue={(selected) => {
          if (selected.length === 0) {
            return props.placeholder ? <em>Placeholder</em> : <em>Select</em>;
          }
          if (props.multiple) {
            selected = [selected[0]];
            return selected.map((element: any) => mappingObject[element]).join(', ');
          }
          return selected.map((element: any) => mappingObject[element]).join(', ');
        }}
      >
        <ListSubheader>
          <TextField
            size="small"
            // Autofocus on textfield
            autoFocus
            placeholder="Type to search..."
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key !== 'Escape') {
                e.stopPropagation();
              }
            }}
          />
        </ListSubheader>
        {searchText === ''
          ? props?.allOptions?.map((option: any) => (
              <MenuItem key={option.id} value={option.id}>
                <CognisaasCheckBox checked={props.selectedOption?.indexOf(option.id) > -1} />
                <ListItemText sx={{ marginLeft: '8px' }} primary={option.name} />
              </MenuItem>
            ))
          : displayedOptions.map((option: any) => (
              <MenuItem key={option.id} value={option.id}>
                <CognisaasCheckBox checked={props.selectedOption?.indexOf(option.id) > -1} />
                <ListItemText sx={{ marginLeft: '8px' }} primary={option.name} />
              </MenuItem>
            ))}
      </Select>
    </FormControl>
  );
}
