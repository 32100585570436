import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { isNull } from 'utils/dataHelpers';
import { csatAPI } from '../../../../api/modules/Csat';
import { requestState } from '../../types';

interface StateProps {
  csatReviews: Array<any>;
  csatReviewsStatus: requestState.idle | requestState.loading | requestState.success | requestState.failed;
  csatReviewsError: null | undefined | string;

  taskCsatRating: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };

  csatTarget: {
    data: number;
    status: requestState;
    error: null | undefined | string;
  };

  updateCsatTarget: {
    data: any[];
    status: requestState;
    error: null | undefined | string;
  };

  CsatMailToClientContacts: {
    data: any;
    status: requestState;
    error: null | undefined | string;
  };

  CsatUseCaseMailToClientContacts: {
    data: any;
    status: requestState;
    error: null | undefined | string;
  };

  updateCsatRating: {
    data: any;
    status: requestState;
    error: null | undefined | string;
  };

  bulkUpdateCsatRating: {
    data: any;
    status: requestState;
    error: null | undefined | string;
  };

  csatDetailsByCsatUid: {
    data: any;
    status: requestState;
    error: null | undefined | string;
  };
}

const initialState: StateProps = {
  csatReviews: [],
  csatReviewsStatus: requestState.idle,
  csatReviewsError: null,

  taskCsatRating: {
    data: [],
    status: requestState.idle,
    error: null,
  },

  csatTarget: {
    data: 0,
    status: requestState.idle,
    error: null,
  },

  updateCsatTarget: {
    data: [],
    status: requestState.idle,
    error: null,
  },

  CsatMailToClientContacts: {
    data: '',
    status: requestState.idle,
    error: null,
  },

  CsatUseCaseMailToClientContacts: {
    data: '',
    status: requestState.idle,
    error: null,
  },

  updateCsatRating: {
    data: '',
    status: requestState.idle,
    error: null,
  },

  bulkUpdateCsatRating: {
    data: '',
    status: requestState.idle,
    error: null,
  },

  csatDetailsByCsatUid: {
    data: [],
    status: requestState.idle,
    error: null,
  },
};

export const getCsatReviewsByCustomerUid = createAsyncThunk('csat/getCsatReviewsByCustomerUid', async () => {
  const { data } = await csatAPI.getCsatReviewsByCustomerUid();
  console.log(data);
  return data.result ?? [];
});

export const getCsatByTaskUid_slice = createAsyncThunk(
  'csat/getCsatByTaskUid_createAsyncThunk',
  async (task_uid: string) => {
    const { data } = await csatAPI.getCsatByTaskUid_modules(task_uid);
    console.log(data);
    return data.result ?? [];
  }
);

export const getCsatTargetByCustomerUid_slice = createAsyncThunk(
  'csat/getCsatTargetByCustomerUid_createAsyncThunk',
  async () => {
    const { data } = await csatAPI.getCsatTargetByCustomerUid_modules();
    console.log(data);
    return data.result ?? [];
  }
);

export const updateCsatTargetByCustomerUid_slice = createAsyncThunk(
  'csat/updateCsatTargetByCustomerUid_createAsyncThunk',
  async (payload: any) => {
    const { data } = await csatAPI.updateCsatTargetByCustomerUid_modules(payload);
    console.log(data);
    return data.result ?? [];
  }
);

export const sendCsatMailToClientContacts_slice = createAsyncThunk(
  'csat/sendCsatMailToClientContacts_createAsyncThunk',
  async (payload: any) => {
    const { data } = await csatAPI.sendCsatMailToClientContacts_modules(payload);
    console.log(data);
    return data.result ?? [];
  }
);

export const sendUseCaseMailToClientContacts_slice = createAsyncThunk(
  'csat/sendUseCaseMailToClientContacts_createAsyncThunk',
  async (payload: any) => {
    const { data } = await csatAPI.sendUseCaseMailToClientContacts_modules(payload);
    console.log(data);
    return data.result ?? [];
  }
);

export const clientUpdateCsatRating_slice = createAsyncThunk(
  'csat/clientUpdateCsatRating_createAsyncThunk',
  async (payload: any) => {
    const { data } = await csatAPI.clientUpdateCsatRating_modules(payload);
    console.log(data);
    return data.result ?? [];
  }
);

export const clientBulkUpdateCsatRating_slice = createAsyncThunk(
  'csat/clientBulkUpdateCsatRating_createAsyncThunk',
  async (payload: any) => {
    const { data } = await csatAPI.clientBulkUpdateCsatRating_modules(payload);
    console.log(data);
    return data.result ?? [];
  }
);

export const getCsatByCsatUid_slice = createAsyncThunk(
  'csat/getCsatByCsatUid_createAsyncThunk',
  async (csat_uid: string) => {
    const { data } = await csatAPI.getCsatByCsatUid_modules(csat_uid);
    console.log(data);
    return data.result ?? [];
  }
);

const csatPerformanceSlice = createSlice({
  name: 'csatPerformance',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      // csat reviews -> getCsatReviewsByCustomerUid
      .addCase(getCsatReviewsByCustomerUid.pending, (state) => {
        state.csatReviewsStatus = requestState.loading;
      })
      .addCase(getCsatReviewsByCustomerUid.fulfilled, (state, action) => {
        state.csatReviewsStatus = requestState.success;
        state.csatReviews = action.payload;
        console.log('csatReviews', action.payload);
      })
      .addCase(getCsatReviewsByCustomerUid.rejected, (state, action) => {
        state.csatReviewsStatus = requestState.failed;
        state.csatReviewsError = action.error.message;
      })

      // csat -> getCsatByTaskUid_slice
      .addCase(getCsatByTaskUid_slice.pending, (state) => {
        state.taskCsatRating.status = requestState.loading;
      })
      .addCase(getCsatByTaskUid_slice.fulfilled, (state, action) => {
        state.taskCsatRating.status = requestState.success;
        // console.log('action = ', action);
        if (isNull(action.payload)) state.taskCsatRating.data = [];
        else state.taskCsatRating.data = action.payload;
      })
      .addCase(getCsatByTaskUid_slice.rejected, (state, action) => {
        state.taskCsatRating.status = requestState.failed;
        state.taskCsatRating.error = action.error.message;
      })

      // csat -> getCsatTargetByCustomerUid_slice
      .addCase(getCsatTargetByCustomerUid_slice.pending, (state) => {
        state.csatTarget.status = requestState.loading;
      })
      .addCase(getCsatTargetByCustomerUid_slice.fulfilled, (state, action) => {
        state.csatTarget.status = requestState.success;
        // console.log('action = ', action);
        if (isNull(action.payload)) state.csatTarget.data = 0;
        else state.csatTarget.data = parseFloat(action.payload[0].csat_target);
      })
      .addCase(getCsatTargetByCustomerUid_slice.rejected, (state, action) => {
        state.csatTarget.status = requestState.failed;
        state.csatTarget.error = action.error.message;
      })

      // csat -> updateCsatTargetByCustomerUid_slice
      .addCase(updateCsatTargetByCustomerUid_slice.pending, (state) => {
        state.updateCsatTarget.status = requestState.loading;
      })
      .addCase(updateCsatTargetByCustomerUid_slice.fulfilled, (state, action) => {
        state.updateCsatTarget.status = requestState.success;
        // console.log('action = ', action);
        if (isNull(action.payload)) state.updateCsatTarget.data = [];
        else state.updateCsatTarget.data = action.payload;
      })
      .addCase(updateCsatTargetByCustomerUid_slice.rejected, (state, action) => {
        state.updateCsatTarget.status = requestState.failed;
        state.updateCsatTarget.error = action.error.message;
      })

      // csat -> sendCsatMailToClientContacts_slice
      .addCase(sendCsatMailToClientContacts_slice.pending, (state) => {
        state.CsatMailToClientContacts.status = requestState.loading;
      })
      .addCase(sendCsatMailToClientContacts_slice.fulfilled, (state, action) => {
        state.CsatMailToClientContacts.status = requestState.success;
        // console.log('action = ', action);
        if (isNull(action.payload)) state.CsatMailToClientContacts.data = '';
        else state.CsatMailToClientContacts.data = action.payload;
      })
      .addCase(sendCsatMailToClientContacts_slice.rejected, (state, action) => {
        state.CsatMailToClientContacts.status = requestState.failed;
        state.CsatMailToClientContacts.error = action.error.message;
      })

      // csat -> sendUseCaseMailToClientContacts_slice
      .addCase(sendUseCaseMailToClientContacts_slice.pending, (state) => {
        state.CsatUseCaseMailToClientContacts.status = requestState.loading;
      })
      .addCase(sendUseCaseMailToClientContacts_slice.fulfilled, (state, action) => {
        state.CsatUseCaseMailToClientContacts.status = requestState.success;
        // console.log('action = ', action);
        if (isNull(action.payload)) state.CsatUseCaseMailToClientContacts.data = '';
        else state.CsatUseCaseMailToClientContacts.data = action.payload;
      })
      .addCase(sendUseCaseMailToClientContacts_slice.rejected, (state, action) => {
        state.CsatUseCaseMailToClientContacts.status = requestState.failed;
        state.CsatUseCaseMailToClientContacts.error = action.error.message;
      })

      // csat -> clientUpdateCsatRating_slice
      .addCase(clientUpdateCsatRating_slice.pending, (state) => {
        state.updateCsatRating.status = requestState.loading;
      })
      .addCase(clientUpdateCsatRating_slice.fulfilled, (state, action) => {
        state.updateCsatRating.status = requestState.success;
        // console.log('action = ', action);
        if (isNull(action.payload)) state.updateCsatRating.data = '';
        else state.updateCsatRating.data = action.payload;
      })
      .addCase(clientUpdateCsatRating_slice.rejected, (state, action) => {
        state.updateCsatRating.status = requestState.failed;
        state.updateCsatRating.error = action.error.message;
      })

      // csat -> clientBulkUpdateCsatRating_slice
      .addCase(clientBulkUpdateCsatRating_slice.pending, (state) => {
        state.bulkUpdateCsatRating.status = requestState.loading;
      })
      .addCase(clientBulkUpdateCsatRating_slice.fulfilled, (state, action) => {
        state.bulkUpdateCsatRating.status = requestState.success;
        // console.log('action = ', action);
        if (isNull(action.payload)) state.bulkUpdateCsatRating.data = '';
        else state.bulkUpdateCsatRating.data = action.payload;
      })
      .addCase(clientBulkUpdateCsatRating_slice.rejected, (state, action) => {
        state.bulkUpdateCsatRating.status = requestState.failed;
        state.bulkUpdateCsatRating.error = action.error.message;
      })

      // csat -> getCsatByCsatUid_slice
      .addCase(getCsatByCsatUid_slice.pending, (state) => {
        state.csatDetailsByCsatUid.status = requestState.loading;
      })
      .addCase(getCsatByCsatUid_slice.fulfilled, (state, action) => {
        state.csatDetailsByCsatUid.status = requestState.success;
        // console.log('action = ', action);
        if (isNull(action.payload)) state.csatDetailsByCsatUid.data = '';
        else state.csatDetailsByCsatUid.data = action.payload;
      })
      .addCase(getCsatByCsatUid_slice.rejected, (state, action) => {
        state.csatDetailsByCsatUid.status = requestState.failed;
        state.csatDetailsByCsatUid.error = action.error.message;
      });
  },
});

export const csatReducer = csatPerformanceSlice.reducer;
export default csatReducer;
