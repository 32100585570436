import * as React from 'react';
import { useState } from 'react';
import { FormControl, TextField, Typography, Button, Stack, Backdrop, CircularProgress } from '@mui/material';
import { Formik, Form } from 'formik';
import Styles from '../../ManageClient/OnboardNewAccount/OnboardNewAccount.module.scss';
import CognisaasButton from '../../shared/CognisaasButton/CognisaasButton';
import { addProjectSchema, onboardAddProjectSchema } from '../../../utils/validations';
import CognisaasSelect from '../../shared/CognisaasSelect/CognisaasSelect';
import { useAppSelector, useAppDispatch } from '../../../store/hooks';
import { useEffect } from 'react';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
// import { useDispatch } from 'react-redux';
import CognisaasCalenderNew from '../../shared/CognisaasCalenderNew/CognisaasCalenderNew';
import { selectClientList } from '../../../store/modules/ManageClient/ClientList/selector';
import { selectCreatingProjectFromProjectTemplate } from '../../../store/modules/ManageClient/OnboardFormValues/selector';
import { format, add } from 'date-fns';
import { getLoggedInUserName, getLoggedInUserUid, getUserUid } from '../../../utils/getUid';
import {
  createProjectFromProjectTemplate,
  resetNewProjectData,
} from '../../../store/modules/ManageClient/OnboardFormValues/slice';
import { updateClientStatus } from '../../../store/modules/ManageClient/ClientList/slice';
import { CognisaaasTooltip } from '../../shared/CognisaasTooltip/CognisaasTooltip';
import {
  getProjectTemplates,
  getTemplateProjectInformation,
  // sendTaskAssignmentMailImportingTemplate,
} from '../../../store/modules/Templates/Templates';
import { selectProjectTemplates, selectTemplateprojectInformation } from '../../../store/modules/Templates/selector';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  addMembersForProject,
  deleteBlankProject,
  // getOpportunitiesOfClient,
  resetGetAllProjects,
} from 'store/modules/Project/slice';
// import useCompanyUsers from 'components/shared/Hooks/useCompanyUsers';
import { fetchClientContacts, fetchClientList } from '../../../store/modules/ManageClient/ClientList/slice';
import { selectClientContacts } from '../../../store/modules/ManageClient/ClientList/selector';
import CognisaasAutoComplete from '../../shared/CognisaasMultiSelectWithSearch/CognisaasAutoComplete';
import { selectCustomerDetail } from 'store/modules/Common/selector';
import getSymbolFromCurrency from 'currency-symbol-map';
import CognisaasUserSelect from 'components/shared/CognisaasSelect/CognisaasUserSelect';
import useUserDropDownData from 'components/shared/Hooks/useUserDropDownData';
import { CognisaasMultiSelectWithSearchAndValidation } from 'components/shared/CognisaasMultiSelectWithSearch/CognisaasMultiSelectWithSearchAndValidation';
import CognisaasToast from 'components/shared/CognisaasToast/CognisaasToast';
import {
  label_arr,
  label_client,
  label_delivery_date,
  label_due_date,
  label_project,
  label_project_delivery_manager,
} from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { useNextDayIfItIsHoliday } from 'components/shared/Hooks/useNextDayIfItIsHoliday';
// import { selectOpportunitiesOfClient } from 'store/modules/Project/selector';
import useCustomNames from 'components/shared/CustomNames/useCustomNames';
import NAMED_CONSTATNTS from 'components/shared/CustomNames/Constants';
import { capitalizeString } from 'utils/dataHelpers';

// import { selectCustomerDetail } from 'store/modules/Common/selector';

const AddNewProject = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location: any = useLocation();
  const initialValues = {
    clientName: '',
    projectTemplate: '',
    opportunity: '',
    projectName: '',
    priority: 'Medium',
    category: 'Land',
    deliveryManager: {
      id: '15243',
      name: 'Not Assigned',
    },
    implementationFee: 0,
    projectARR: 0,
    projectDescription: '',
    comment: '',
    plannedStartDate: useNextDayIfItIsHoliday(new Date()),
    actualStartDate: useNextDayIfItIsHoliday(new Date()),
    dueDate: useNextDayIfItIsHoliday(new Date()),
    etaForDeliveryDate: useNextDayIfItIsHoliday(new Date()),
    projectStage: 'Pre-Sales',
    deliveryDate: useNextDayIfItIsHoliday(new Date()),
    link: '',
    expandARR: 0,
    teamMembers: [],
  };

  const [clientsOfCustomer, setClientsOfCustomer] = useState<any[]>([]);
  // const [opportunityResponse, setOpportunityResponse] = useState<any[]>([]);
  // const [opportunities, setOpportunities] = useState<any>([]);
  const allClients = useAppSelector(selectClientList);
  const newProjectData = useAppSelector(selectCreatingProjectFromProjectTemplate);
  const clientContacts = useAppSelector(selectClientContacts);
  const [client, setClient] = useState<any>(null);
  const projectTemplates = useAppSelector(selectProjectTemplates);
  const templateProjectInfo = useAppSelector(selectTemplateprojectInformation);
  // const opportunitiesOfClient = useAppSelector(selectOpportunitiesOfClient);
  // const clientInfoWithProjects = useAppSelector(selectSingleClientInfo);
  const customerDetail = useAppSelector(selectCustomerDetail);
  const [allProjectTemplates, setAllProjectTemplates] = useState<any[]>([]);
  const [templateList, setTemplateList] = useState<any[]>([]);
  const [isSaveInProgress, setIsSaveInProgress] = useState<boolean>(false);
  const [importInProgress, setImportInProgress] = useState<boolean>(false);
  const [selectedProjectTemplate, setSelectedProjectTemplate] = useState<string>('');
  const [initialFormValues, setInitialFormValues] = useState<any>({ ...initialValues });
  // const [companyUsersDropDownData, setCompanyUsersDropDownData] = useState<any[]>([]);
  const [clientContactsDropDownData, setClientContactsDropDownData] = useState<any[]>([]);
  const [selectedContactOption, setSelectedContactOptions] = useState<any>([]);
  const allCompanyUsersDropDownData = useUserDropDownData('company');
  // const clientUsersDropDownData = useUserDropDownData('client', client ? client.client_uid : '');
  // const [selectedTeamMemberOptions, setSelectedTeamMemberOptions] = useState<any>([]);
  const [alertDetails, setAlertDetails] = useState<{
    isVisible: boolean;
    type: 'success' | 'failure';
    message: string;
  }>({ isVisible: false, type: 'success', message: '' });

  const {
    projectCategoryDropdownValues,
    projectStagesDropdownValues,
    getStandardNameMappedValue,
    getCustomNameMappedValue,
    projectPrioritiesDropdownValues,
  } = useCustomNames();
  const projectCategories = projectCategoryDropdownValues.map((item: any) => item.custom_category_name);
  const projectStages = projectStagesDropdownValues.map((item: any) => item.custom_stage_name);
  const projectPriorities = projectPrioritiesDropdownValues.map((item: any) => item.custom_priority_name);

  function loadTeamMembers(templateProjectInfo: any) {
    let templateTaskOwnerList: any[] = [];
    let allTasks: any[] = [];
    templateProjectInfo?.section.forEach((section: any) => {
      allTasks = [...allTasks, ...section.task];
    });
    templateTaskOwnerList = allTasks
      .filter((templateItem) => templateItem.user_uid && templateItem.template_task_owner)
      .map((templateItem: any) => ({
        name: templateItem.template_task_owner,
        id: templateItem.user_uid,
        label: templateItem.template_task_owner,
        value: templateItem.user_uid,
      }));

    // const getUniqueListBy = (arr: any, key: string) => {
    //   const list = arr.map((item: any) => [item[key], item]);
    //   const target = Array.from(new Map(list).values());
    //   return target;
    // };
    const selectedTeamMemberOptions = [...templateTaskOwnerList];
    return selectedTeamMemberOptions;
  }

  useEffect(() => {
    if (clientContacts.length > 0) {
      const array: any = [];
      for (let i = 0; i < clientContacts.length; i++) {
        const data: any = {};
        data.id = clientContacts[i]?.contact_uid || clientContacts[i]?.user_uid;
        data.name = clientContacts[i]?.contact_name || clientContacts[i]?.user_username;
        array.push(data);
      }
      console.log(array);
      setClientContactsDropDownData([...array]);
    }
  }, [clientContacts]);

  useEffect(() => {
    if (location?.state && allClients.length) {
      console.log(location?.state, location?.state?.clientDetail?.client_name, 'passed state');
      clientAdded(location?.state?.clientDetail?.client_name);
      // if (location?.state?.clientDetail?.crm_client_id) {
      //   mapOpportunities();
      // }
    }
  }, [location, allClients]);

  useEffect(() => {
    if (projectTemplates.length > 0) {
      setAllProjectTemplates([...projectTemplates]);
      setDataForTemplatesDropDown(projectTemplates);
      console.log(projectTemplates);
    } else {
      dispatch(getProjectTemplates());
    }
  }, [projectTemplates]);

  useEffect(() => {
    if (selectedProjectTemplate.length > 0) {
      const template = allProjectTemplates.filter((t: any) => t.template_project_name == selectedProjectTemplate);
      dispatch(getTemplateProjectInformation(template[0].template_project_uid));
    }
  }, [selectedProjectTemplate]);

  useEffect(() => {
    if (templateProjectInfo) {
      console.log(templateProjectInfo);
    }
  }, [templateProjectInfo]);

  useEffect(() => {
    if (allClients.length > 0) {
      getClientsOfCustomer();
    } else {
      dispatch(fetchClientList());
    }
    console.log(allClients, 'all clients');
  }, [allClients]);

  useEffect(() => {
    resetNewProjectData();
    dispatch(resetGetAllProjects());
    return () => {
      dispatch(deleteBlankProject());
    };
  }, []);

  // const mapOpportunities = async () => {
  //   if (clientInfoWithProjects.length == 0) {
  //     const projects = await dispatch(fetchClientInformation(location?.state?.clientDetail?.client_uid)).unwrap();
  //     // console.log(projects, location?.state?.opportunitiesOfClient);
  //     let opportunities: any[] = [];
  //     if (opportunitiesOfClient.length > 0) {
  //       const res = await dispatch(getOpportunitiesOfClient(location?.state?.clientDetail?.client_uid));
  //       opportunities = [...res.payload];
  //       setOpportunityResponse([...res.payload]);
  //     } else {
  //       opportunities = [...opportunitiesOfClient];
  //     }
  //     const opportinitiesList: any = {};
  //     for (let i = 0; i < opportunities.length; i++) {
  //       console.log(i);
  //       if (projects.length > 0) {
  //         for (let j = 0; j < projects.payload; j++) {
  //           if (opportunities[i].opportunity_id == projects.payload[j].opportunity_id) {
  //             opportinitiesList[opportunities[i].opportunity_name] = projects.payload[j];
  //           } else {
  //             console.log(opportinitiesList[opportunities[i].opportunity_name]);
  //             opportinitiesList[opportunities[i].opportunity_name] = null;
  //           }
  //         }
  //       } else {
  //         console.log('elseeeee');
  //         opportinitiesList[opportunities[i].opportunity_name] = null;
  //       }
  //     }
  //     console.log(opportinitiesList);
  //     const opportunitiesWithoutProject = Object.keys(opportinitiesList).map((key: any) => {
  //       if (!opportinitiesList[key]) {
  //         return key;
  //       }
  //     });
  //     console.log(opportunitiesWithoutProject);
  //     setOpportunities([...opportunitiesWithoutProject]);
  //   }
  // };

  const getClientsOfCustomer = () => {
    const list = allClients.map((client: any) => {
      if (client.__user_permissions.includes('edit')) {
        return client.client_name;
      }
    });
    setClientsOfCustomer([...list]);
  };

  const setDataForTemplatesDropDown = async (templates: any) => {
    const template = templates.map((t: any) => t.template_project_name);
    console.log(template);
    setTemplateList([...template]);
  };

  const importThisTemplate = async (name: any, setFieldValue: any) => {
    setImportInProgress(true);
    console.log(name, setFieldValue);
    setSelectedProjectTemplate(name);
    const temp: any = allProjectTemplates.filter((t: any) => t.template_project_name == name);
    console.log(temp);
    // const templateInfo = allProjectTemplates.filter((t: any) => t.template_project_name == selectedProjectTemplate);
    const res = await dispatch(getTemplateProjectInformation(temp[0].template_project_uid)).unwrap();
    console.log(res);

    setInitialFormValues((prevState: any) => {
      return { ...prevState, projectName: '' };
    });
    setFieldValue('importedTemplateProjectUid', temp[0].template_project_uid);
    setFieldValue('projectTemplate', name);
    // setFieldValue('projectName', temp[0].template_project_name);
    setFieldValue('deliveryManager', {
      id: '15243',
      name: 'Not Assigned',
    });
    setFieldValue('implementationFee', temp[0].template_fees);
    setFieldValue('dueDate', add(new Date(), { days: temp[0].template_project_target_days - 1 }));
    setFieldValue('etaForDeliveryDate', add(new Date(), { days: temp[0].template_project_target_days - 1 }));
    let selectedTeamMemberOptions = loadTeamMembers(res[0]);
    selectedTeamMemberOptions = selectedTeamMemberOptions.filter(
      (a, i) => selectedTeamMemberOptions.findIndex((s) => a.id === s.id) === i
    ); // [{"name":"Joe", "age":17}, {"name":"Carl", "age": 35}]

    // selectedTeamMemberOptions = Array.from(new Set(selectedTeamMemberOptions.map((item: any) => item.id)));
    setFieldValue('teamMembers', selectedTeamMemberOptions);
    console.log('importThisTemplate inside', { selectedTeamMemberOptions });
    setImportInProgress(false);
  };

  // function newLoadTeamMembers(setFieldValue: any) {
  //   const selectedTeamMemberOptions = loadTeamMembers();
  //   setFieldValue('teamMembers', selectedTeamMemberOptions);
  //   console.log('newLoadTeamMembers', { selectedTeamMemberOptions });
  // }

  // const _selectedTeamMemberOptions = loadTeamMembers();
  // console.log('importThisTemplate outside', { _selectedTeamMemberOptions });

  const clientAdded = async (name: any) => {
    const selectedClient = allClients.filter((client: any) => {
      // console.log(client);
      return client.client_name == name;
    });
    setClient(selectedClient[0]);
    // setInitialFormValues({ ...initialValues, projectName: '' });
    if (location.state) {
      setInitialFormValues((prevState: any) => {
        return { ...prevState, clientName: selectedClient[0]?.client_name };
      });
    }
    console.log('was going to create a blank project for the client');
    await dispatch(fetchClientContacts(selectedClient[0].client_uid));
    console.log(clientContacts);

    console.log(selectedClient);
  };

  const getMembersData = (members: any) => {
    const team: any = [];
    const teamData = members.map((option: any) => {
      const detail: any = {};
      detail.is_client_contact = '0';
      detail.user_uid = option.id;
      team.push(detail);
    });

    const client: any = [];
    const clientData = selectedContactOption.map((option: any) => {
      const detail: any = {};
      detail.is_client_contact = '1';
      detail.user_uid = option;
      client.push(detail);
    });

    console.log(teamData, clientData, selectedContactOption);

    return [...team, ...client];
  };

  // const getOpportunityId = (name: string) => {
  //   const selectedOpportunity = opportunityResponse.filter((o: any) => o.opportunity_name == name);
  //   console.log(selectedOpportunity);
  //   if (selectedOpportunity.length > 0) {
  //     return selectedOpportunity[0].opportunity_id;
  //   }
  //   return null;
  // };

  const saveProject = async (newForm: any) => {
    setIsSaveInProgress(true);
    console.log(format(new Date(newForm.actualStartDate), 'yyyy-MM-dd'));
    console.log('save=', newForm);
    console.log('client 433 =', client, newForm.teamMembers);

    const deliveryMangerAsAMemberForProject = {
      is_client_contact: '0',
      user_uid: newForm.deliveryManager === 'Not Assigned' ? '15243' : newForm.deliveryManager.id,
    };
    const members = [...getMembersData(newForm.teamMembers), deliveryMangerAsAMemberForProject];

    console.log({ newForm });
    const projectInformation = {
      members: [...members],
      template_project_uid: newForm.importedTemplateProjectUid,
      association_uid: client.association_uid,
      project_name: newForm.projectName,
      project_fees: newForm.implementationFee,
      project_ARR: newForm.projectARR,
      project_description: newForm.projectDescription,
      project_comment: newForm.comment,
      project_reference: newForm.link,
      project_value: null,
      project_needed_status: newForm.category,
      project_stage: newForm.projectStage,
      project_priority: newForm.priority,
      project_readinessstate_status: 'green',
      project_expansionvalue: newForm.expandARR,
      project_status_client: 'red',
      project_status_reason: `The ${label_due_date.toLowerCase()} of this ${label_project.toLowerCase()} has passed`,
      project_status_reason_client: `The ${label_due_date.toLowerCase()} of this ${label_project.toLowerCase()} has passed`,
      project_startdate: format(new Date(newForm.actualStartDate), 'yyyy-MM-dd'),
      project_planned_startdate: format(new Date(newForm.plannedStartDate), 'yyyy-MM-dd'),
      project_deliverydate: format(new Date(), 'yyyy-MM-dd'),
      project_created_on: format(new Date(), 'yyyy-MM-dd'),
      project_modified_on: format(new Date(), 'yyyy-MM-dd'),
      eta_forprojectdelivery: format(new Date(newForm.etaForDeliveryDate), 'yyyy-MM-dd'),
      project_requiredbydate: format(new Date(newForm.dueDate), 'yyyy-MM-dd'),
      project_deliverymanager: newForm.deliveryManager.name,
      user_uid: newForm.deliveryManager.id,
      imported_template: newForm.projectTemplate.length > 0 ? 1 : 0,
      project_rollout: 0,
      opportunity_id: location?.state?.opportunitiesMappedToProject,
      last_user_modified: getLoggedInUserUid(),
      last_user_modified_name: getLoggedInUserName(),
      base_url: `https://${window.location.host}/`,
    };
    // console.log(newForm.projectTemplate);

    console.log('project information = ', projectInformation);

    const addedTemplate = await dispatch(createProjectFromProjectTemplate(projectInformation)).unwrap();
    console.log(addedTemplate);
    const projectUid = addedTemplate.result;
    const data: any = {
      members: [...members],
      association_uid: client?.association_uid,
      project_uid: projectUid,
    };
    await dispatch(addMembersForProject({ ...data }));

    if (client.client_status == '0') {
      const detail = {
        status: 1,
        clientUid: client.client_uid,
      };
      await dispatch(updateClientStatus(detail));
    }
    setIsSaveInProgress(false);
  };

  useEffect(() => {
    console.log('newProjectData = ', newProjectData);
    if (newProjectData?.status == 'success') {
      navigate(`/projects/project/${client?.association_uid}/${newProjectData.data}`, {
        state: { newlyAddedProjectUid: newProjectData.data },
      });
      dispatch(resetNewProjectData());
    } else {
      console.error('Error = ', newProjectData?.error);
    }
  }, [newProjectData]);

  const availableTeamMemberOptions = allCompanyUsersDropDownData.map((item: any) => ({
    ...item,
    label: item.name,
    value: item.id,
  }));
  // console.log('Main', { selectedTeamMemberOptions });

  return (
    <>
      <CognisaasToast
        message={alertDetails.message}
        type={alertDetails.type}
        open={alertDetails.isVisible}
        onClose={() => {
          setAlertDetails({ isVisible: false, type: alertDetails.type, message: '' });
        }}
      />
      <Formik
        enableReinitialize={true}
        initialValues={initialFormValues}
        validationSchema={location.pathname == '/projects/add-project' ? addProjectSchema : onboardAddProjectSchema}
        onSubmit={(values) => {
          if (values.projectName.trim()) {
            saveProject({
              ...values,
              category: getStandardNameMappedValue(values.category, NAMED_CONSTATNTS.project_category_key),
              projectStage: getStandardNameMappedValue(values.projectStage, NAMED_CONSTATNTS.project_stage_key),
              projectPriority: getStandardNameMappedValue(
                values.projectPriority,
                NAMED_CONSTATNTS.project_priority_key
              ),
              plannedStartDate: format(new Date(values.plannedStartDate), 'yyyy-MM-dd HH:mm:ss'),
              actualStartDate: format(new Date(values.actualStartDate), 'yyyy-MM-dd HH:mm:ss'),
              dueDate: format(new Date(values.dueDate), 'yyyy-MM-dd HH:mm:ss'),
              etaForDeliveryDate: format(new Date(values.etaForDeliveryDate), 'MM-dd-yyyy HH:mm:ss'),
            });
          } else {
            setAlertDetails({
              isVisible: true,
              type: 'failure',
              message: `${label_project} Name cannot be empty.`,
            });
          }
        }}
      >
        {(props: any) => (
          <Form className={Styles.OnboardAccountProfile__container}>
            <div className={Styles.OnboardAccountProfile__formAlternateTopSection}>
              <Typography sx={{ magrin: '12px 0px' }} variant="h6" component="span" gutterBottom>
                Choose a {label_project} Template
              </Typography>
              {/* <Button onClick={() => componentProps.handleSkip(0) }>Skip</Button> */}
            </div>

            <div className={Styles.OnboardAccount__section}>
              <div className={Styles.OnboardAccountProfile__formField}>
                <FormControl sx={{ minWidth: '260px' }} size="small">
                  <CognisaasSelect
                    width="257px"
                    onChange={(e: any) => {
                      props.handleChange;
                      importThisTemplate(e.target.value, props.setFieldValue);
                      // newLoadTeamMembers(props.setFieldValue);
                    }}
                    name="SelectTemplate"
                    value={props.values.projectTemplate}
                    validValues={[...templateList]}
                    onBlur={props.handleBlur}
                    error={props.touched.SelectTemplate && Boolean(props.errors.SelectTemplate)}
                    helperText={props.touched.SelectTemplate && props.errors.SelectTemplate}
                  />
                </FormControl>
              </div>
              <div className={Styles.OnboardAccountProfile__formField}>
                <Button disabled>Create Template</Button>
              </div>
            </div>
            {/* {location?.state?.clientDetail?.crm_client_id && (
              <FormControl sx={{ minWidth: '260px' }} size="small">
                <Typography variant="subtitle2">Opportunity Name</Typography>
                <CognisaasSelect
                  width="257px"
                  onChange={(e: any) => {
                    props.handleChange;
                    console.log(e.target.value);
                    props.setFieldValue('opportunity', e.target.value);
                  }}
                  name="opportunity"
                  value={props.values.opportunity}
                  validValues={[...opportunities]}
                  // onBlur={props.handleBlur}
                  // error={props.touched.opportunity && Boolean(props.errors.opportunity)}
                  // helperText={props.touched.opportunity && props.errors.opportunity}
                />
              </FormControl>
            )} */}

            <div className={Styles.OnboardAccount__section}>
              <div className={Styles.OnboardAccountProfile__formField}>
                <Typography variant="subtitle2">{label_client} Name*</Typography>
                {/* <FormControl sx={{ minWidth: '260px' }}> */}
                <CognisaasSelect
                  name="clientName"
                  id="clientName"
                  width="257px"
                  value={props.values.clientName}
                  onChange={(e: any) => {
                    props.handleChange(e);
                    // props.setFieldValue('clientName', e.target.value);
                    clientAdded(e.target.value);
                  }}
                  validValues={[...clientsOfCustomer]}
                  onBlur={props.handleBlur}
                  disabled={location?.state ? true : false}
                  error={props.touched.clientName && Boolean(props.errors.clientName)}
                  helperText={props.touched.clientName && props.errors.clientName}
                />
                {/* </FormControl> */}
              </div>
              <div className={Styles.OnboardAccountProfile__formField}>
                <Typography variant="subtitle2">{label_project} Name*</Typography>
                <TextField
                  size="small"
                  id="projectName"
                  name="projectName"
                  placeholder={`Enter ${label_project.toLowerCase()} name`}
                  onChange={props.handleChange}
                  value={props.values.projectName}
                  onBlur={props.handleBlur}
                  error={props.touched.projectName && Boolean(props.errors.projectName)}
                  helperText={props.touched.projectName && props.errors.projectName}
                  style={{ width: '257px' }}
                ></TextField>
              </div>
              <div className={Styles.OnboardAccountProfile__formField}>
                <Typography variant="subtitle2">Priority</Typography>
                <FormControl sx={{ minWidth: '260px' }} size="small">
                  <CognisaasSelect
                    name="priority"
                    width="257px"
                    value={
                      props.values.priority == 'Medium'
                        ? getCustomNameMappedValue('Medium', NAMED_CONSTATNTS.project_priority_key)
                        : props.values.priority
                    }
                    onChange={props.handleChange}
                    validValues={projectPriorities}
                    onBlur={props.handleBlur}
                    error={props.touched.priority && Boolean(props.errors.priority)}
                    helperText={props.touched.priority && props.errors.priority}
                  />
                </FormControl>
              </div>
              <div className={Styles.OnboardAccountProfile__formField}>
                <Typography variant="subtitle2">Category</Typography>
                <FormControl sx={{ minWidth: '310px' }} size="small">
                  <CognisaasSelect
                    width="257px"
                    name="category"
                    value={
                      props.values.category == 'Land'
                        ? getCustomNameMappedValue('Land', NAMED_CONSTATNTS.project_category_key)
                        : props.values.category
                    }
                    onChange={props.handleChange}
                    validValues={projectCategories}
                    error={props.touched.category && Boolean(props.errors.category)}
                    helperText={props.touched.category && props.errors.category}
                  />
                </FormControl>
              </div>
            </div>

            <div className={Styles.OnboardAccount__section}>
              <div className={Styles.OnboardAccountProfile__formField}>
                <Typography variant="subtitle2">Stage</Typography>
                <FormControl sx={{ minWidth: '257px' }} size="small">
                  <CognisaasSelect
                    width="257px"
                    name="projectStage"
                    value={
                      props.values.projectStage == 'Pre-Sales'
                        ? getCustomNameMappedValue('Pre-Sales', NAMED_CONSTATNTS.project_stage_key)
                        : props.values.projectStage
                    }
                    onChange={props.handleChange}
                    validValues={[...projectStages]}
                    onBlur={props.handleBlur}
                    error={props.touched.projectStage && Boolean(props.errors.projectStage)}
                    helperText={props.touched.projectStage && props.errors.projectStage}
                  />
                </FormControl>
              </div>

              <div className={Styles.OnboardAccountProfile__formField}>
                <Typography variant="subtitle2">{label_project_delivery_manager}</Typography>
                <FormControl sx={{ minWidth: '257px' }} size="small">
                  <CognisaasUserSelect
                    width="257px"
                    name="deliveryManager"
                    allOptions={allCompanyUsersDropDownData}
                    selectedOption={props.values.deliveryManager}
                    onChange={(val: any) => {
                      // props.setFieldValue('deliveryManager', val);
                      props.setFieldValue('deliveryManager', {
                        name: val.target.textContent,
                        id: getUserUid(val.target.textContent),
                      });
                    }}
                  />
                </FormControl>
              </div>

              <div className={Styles.OnboardAccountProfile__formField}>
                <Typography variant="subtitle2">
                  {`Implementation Fee (${getSymbolFromCurrency(customerDetail?.currency_code)})`}
                </Typography>
                <TextField
                  type="number"
                  size="small"
                  id="implementationFee"
                  name="implementationFee"
                  placeholder="0"
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  value={props.values.implementationFee}
                  style={{ width: '257px' }}
                  error={props.touched.implementationFee && Boolean(props.errors.implementationFee)}
                  helperText={props.touched.implementationFee && props.errors.implementationFee}
                ></TextField>
              </div>
              {getStandardNameMappedValue(props.values.category, NAMED_CONSTATNTS.project_category_key) == 'Expand' && (
                <div className={Styles.OnboardAccountProfile__formField}>
                  <Typography variant="subtitle2">
                    {/* Expand label_arr {customerDetail && `(${customerDetail.currency_code})`} */}
                    {`${getCustomNameMappedValue(
                      'Expand',
                      NAMED_CONSTATNTS.project_category_key
                    )} ${label_arr} (${getSymbolFromCurrency(customerDetail?.currency_code)})`}
                  </Typography>
                  <TextField
                    type="number"
                    size="small"
                    id="expandARR"
                    name="expandARR"
                    placeholder="0"
                    onChange={props.handleChange}
                    value={props.values.expandARR}
                    onBlur={props.handleBlur}
                    error={props.touched.expandARR && Boolean(props.errors.expandARR)}
                    helperText={props.touched.expandARR && props.errors.expandARR}
                  ></TextField>
                </div>
              )}
              <div className={Styles.OnboardAccountProfile__formField}>
                <Typography variant="subtitle2">Link to {label_project} Reference Document</Typography>
                <TextField
                  type="url"
                  size="small"
                  id="link"
                  name="link"
                  style={{ width: '257px' }}
                  placeholder="https://example.com"
                  onChange={props.handleChange}
                  value={props.values.link}
                  error={props.touched.link && Boolean(props.errors.link)}
                  helperText={props.touched.link && props.errors.link}
                ></TextField>
              </div>
            </div>

            {/* date component */}

            <div className={Styles.OnboardAccount__section}>
              <div className={Styles.OnboardAccountProfile__formField}>
                <Typography variant="subtitle2">Planned Start Date*</Typography>

                <CognisaasCalenderNew
                  name="plannedStartDate"
                  value={props.values.plannedStartDate ? new Date(props.values.plannedStartDate) : null}
                  handleChange={(date: any) => props.setFieldValue('plannedStartDate', new Date(date))}
                  width="257px"
                  // error={props.touched.plannedStartDate && Boolean(props.errors.plannedStartDate)}
                  // helperText={props.touched.plannedStartDate && props.errors.plannedStartDate}
                />
              </div>

              <div className={Styles.OnboardAccountProfile__formField}>
                <Typography variant="subtitle2">Actual Start Date</Typography>
                <CognisaasCalenderNew
                  name="actualStartDate"
                  value={new Date(props.values.actualStartDate)}
                  handleChange={(date: any) => props.setFieldValue('actualStartDate', new Date(date))}
                  width="257px"
                />
              </div>

              <div className={Styles.OnboardAccountProfile__formField}>
                <Typography variant="subtitle2">{label_due_date}*</Typography>
                <CognisaasCalenderNew
                  name="dueDate"
                  value={new Date(props.values.dueDate)}
                  handleChange={(date: any) => props.setFieldValue('dueDate', new Date(date))}
                  width="257px"
                />
              </div>

              <div className={Styles.OnboardAccountProfile__formField}>
                <Typography variant="subtitle2">Estimated Delivery Date</Typography>
                <CognisaasCalenderNew
                  name="etaForDeliveryDate"
                  value={new Date(props.values.etaForDeliveryDate)}
                  handleChange={(date: any) => props.setFieldValue('etaForDeliveryDate', new Date(date))}
                  width="257px"
                />
              </div>

              <CognisaaasTooltip
                title={
                  <Typography variant="subtitle1">
                    {capitalizeString(label_delivery_date)} would be system generated when this{' '}
                    {label_project.toLowerCase()} is delivered
                  </Typography>
                }
              >
                <div className={Styles.OnboardAccountProfile__formField}>
                  <React.Fragment>
                    <Typography variant="subtitle2">{label_delivery_date}</Typography>

                    <CognisaasCalenderNew
                      disabled={true}
                      value={useNextDayIfItIsHoliday(props.values.deliveryDate)}
                      handleChange={(date: any) => props.setFieldValue('deliveryDate', new Date(date))}
                      width="257px"
                    />
                  </React.Fragment>
                </div>
              </CognisaaasTooltip>

              <div className={Styles.OnboardAccountProfile__formField}>
                <Typography variant="subtitle2">
                  {/* label_arr {customerDetail && `(${customerDetail.currency_code})`} */}
                  {`${label_project} ${label_arr} (${getSymbolFromCurrency(customerDetail?.currency_code)})`}
                </Typography>
                <TextField
                  type="number"
                  size="small"
                  id="projectARR"
                  name="projectARR"
                  placeholder="0"
                  onChange={props.handleChange}
                  value={props.values.projectARR}
                  //   onBlur={props.handleBlur}
                  error={props.touched.projectARR && Boolean(props.errors.projectARR)}
                  helperText={props.touched.projectARR && props.errors.projectARR}
                  style={{ width: '257px' }}
                ></TextField>
              </div>
            </div>

            {/* Add Project Members */}
            {
              <>
                <Stack sx={{ margin: '16px 0' }}>
                  <Typography variant="subtitle1">Add members for this {label_project.toLowerCase()}</Typography>
                </Stack>

                <Stack mb={3} spacing={2} direction="row">
                  <Stack sx={{ marginRight: '4px' }}>
                    <Typography variant="subtitle1">
                      Select team members for this {label_project.toLowerCase()}
                    </Typography>
                    <CognisaasMultiSelectWithSearchAndValidation
                      name="teamMembers"
                      selectedOptions={props.values.teamMembers}
                      allOptions={availableTeamMemberOptions}
                      errText="Choose at least one team member"
                      placeholder="Choose a team member"
                      width="257px"
                    />
                  </Stack>

                  <Stack>
                    <Typography variant="subtitle1">
                      Add {label_client.toLowerCase()} contact as members for this {label_project.toLowerCase()}
                    </Typography>
                    <CognisaasAutoComplete
                      keepOpen={false}
                      allOptions={clientContactsDropDownData}
                      selectedOption={(e: any, value: any) => {
                        const selectedId: any = [];
                        for (let i = 0; i < value.length; i++) {
                          selectedId.push(value[i].id);
                        }
                        setSelectedContactOptions([...selectedId]);
                      }}
                      width="257px"
                      // setSelectedOptions={setSelectedOptions}
                    />
                  </Stack>
                </Stack>
              </>
            }

            {/* project description */}
            <div className={Styles.OnboardAccount__section}>
              <div className={Styles.OnboardAccountProfile__formField}>
                <Typography variant="subtitle2">{label_project} Description</Typography>
                <FormControl sx={{ width: '47vw', minWidth: '250px' }}>
                  <TextField
                    id="projectDescription"
                    name="projectDescription"
                    placeholder={`Enter ${label_project.toLowerCase()} description`}
                    onChange={props.handleChange}
                    value={props.values.projectDescription}
                    multiline
                    rows={4}
                    onBlur={props.handleBlur}
                    error={props.touched.projectDescription && Boolean(props.errors.projectDescription)}
                    helperText={props.touched.projectDescription && props.errors.projectDescription}
                  ></TextField>
                </FormControl>
              </div>
            </div>

            {/* comments section */}
            <div className={Styles.OnboardAccount__section}>
              <div className={Styles.OnboardAccountProfile__formField}>
                {/* comment component here */}
                <Typography variant="subtitle2">Comments</Typography>
                <FormControl sx={{ width: '47vw', minWidth: '250px' }}>
                  <TextField
                    id="comment"
                    name="comment"
                    placeholder="Enter Comment"
                    onChange={props.handleChange}
                    value={props.values.comment}
                    multiline
                    rows={4}
                    onBlur={props.handleBlur}
                    error={props.touched.comment && Boolean(props.errors.comment)}
                    helperText={props.touched.comment && props.errors.comment}
                  ></TextField>
                </FormControl>
              </div>
            </div>

            {/* buttons for furthur form actions */}
            <div className={Styles.OnboardAccount__formBottomBarWrapper}>
              <div className={Styles.OnboardAccountProfile__formActionButtonsContainer}>
                {/* save and cancel this step buttons  */}
                <CognisaasButton label={'Cancel'} isOutlined={true} clickHandler={() => navigate('/projects')} />
                <span style={{ width: '20px' }}></span>
                {isSaveInProgress ? (
                  <LoadingButton loading loadingPosition="start" startIcon={<SaveIcon />} variant="outlined">
                    Save
                  </LoadingButton>
                ) : (
                  <CognisaasButton
                    disabled={!props.isValid || !props.dirty}
                    label={'Save'}
                    isOutlined={false}
                    clickHandler={props.handleSubmit}
                  />
                )}
                {/* <CognisaasButton
                  disabled={!props.isValid || !props.dirty}
                  label={'Save'}
                  isOutlined={false}
                  clickHandler={props.handleSubmit}
                /> */}
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <Backdrop sx={{ color: '#fff', zIndex: 1201 }} open={importInProgress}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default AddNewProject;
