import { Alert, Stack, Typography } from '@mui/material';
import { addDays, subDays } from 'date-fns';
import format from 'date-fns/format';
import CognisaasButton from 'components/shared/CognisaasButton/CognisaasButton';

const DateShiftConfirmationModal = (props: any) => {
  console.log(props);
  return (
    <Stack style={{ padding: '15px' }}>
      <Alert sx={{ marginBottom: '12px' }} severity="warning">
        Shifting this task date will affect {props.type == 'PROJECT' ? 'project' : 'usecase'} delivery
      </Alert>
      <Typography variant="h6" style={{ fontSize: '16px', lineHeight: '1.6rem' }}></Typography>
      <Typography style={{ paddingLeft: '4px' }} variant="subtitle1">
        Estimated date of {props.type == 'PROJECT' ? 'project' : 'usecase'} delivery will be updated from{' '}
        <span style={{ fontWeight: 'bold' }}> {format(new Date(props.fromDate), 'dd MMM yyyy')} </span>to{' '}
        <span style={{ fontWeight: 'bold' }}>
          {props.shiftedBy >= 0 ? (
            <>{format(addDays(new Date(props.fromDate), props.shiftedBy), 'dd MMM yyyy')} </>
          ) : (
            <>{format(subDays(new Date(props.fromDate), props.shiftedBy * -1), 'dd MMM yyyy')} </>
          )}
        </span>
      </Typography>
      <Stack direction="row-reverse" style={{ marginTop: '30px' }}>
        <CognisaasButton
          clickHandler={() => {
            props.doContinue(true);
          }}
          // clickHandler={() =>
          //   setTabIndex(() => {
          //     const index = tabs.findIndex((tab: string) => tab === 'Custom Fields');
          //     setAlertModalOpened(true);
          //     setAlertModal(false);
          //     return index;
          //   })
          // }
          sx={{ padding: '8px 32px', marginLeft: '10px', height: '35px' }}
          isOutlined={false}
          label="Yes"
        />
        <CognisaasButton
          clickHandler={() => {
            props.doContinue(false);
          }}
          // clickHandler={() =>
          //   setAlertModal(() => {
          //     setAlertModalOpened(true);
          //     return false;
          //   })
          // }
          sx={{ padding: '8px 32px', height: '35px' }}
          isOutlined={true}
          label="No"
        />
      </Stack>
    </Stack>
  );
};

export default DateShiftConfirmationModal;
