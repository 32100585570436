const NAMED_CONSTATNTS = {
  segment_name: 'segment_name',
  segment_uid: 'segment_uid',
  client_stage_key: 'stage_journerystage',
  project_category_key: 'project_needed_status',
  project_stage_key: 'project_stage',
  project_priority_key: 'project_priority',
  usecase_category_key: 'case_needed_status',
  usecase_stage_key: 'case_stage',
  usecase_priority_key: 'case_priority',
  task_stage_key: 'task_stage',
  task_priority_key: 'task_priority',
};

export default NAMED_CONSTATNTS;
