import { useEffect, useState } from 'react';
import 'dhtmlx-gantt';
import { GanttStatic } from 'dhtmlx-gantt';

import { label_task } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import {
  loadResourcesStore,
  loadAttachEvents,
  loadBaselineLayer,
  loadConfig,
  loadTemplates,
  loadWorkTime,
} from './core';
import { getExportToX } from './exportToX';
import { transformToAPITaskFormat } from './transformers';
import { useAPIForGantt } from './useAPIForGantt';
import { useAppDispatch } from 'store/hooks';
import { useParams } from 'react-router-dom';
import {
  toggleCriticalPathHighlightInProjectGantt,
  toggleCriticalPathHighlightInUsecaseGantt,
} from 'store/modules/Project/slice';

export const useGantt = (gantt: GanttStatic, ganttDataObj: any) => {
  // const highlightCriticalPathInProjectGantt = useAppSelector(selectHighlightCriticalPathInProjectGantt);
  const {
    allTasksFromAPI,
    nonWorkingDaysInNumber,
    holidays,
    workingHours,
    customerAmberThreshold,
    updateTaskInGantt,
    updateLinkInGantt,
    deleteLinkInGantt,
  } = useAPIForGantt();
  const params = useParams();

  const {
    gDependencies,
    allData,
    resourceData,
    customExportExcelFilename,
    ganttRootElemId,
    ganttStartDateBoundary,
    ganttEndDateBoundary,
  } = ganttDataObj;

  const [taskData, setTaskData] = useState<any>({});
  const dispatch = useAppDispatch();
  const [isUpdateTaskDrawerOpen, setUpdateTaskDrawerState] = useState(false);

  const [isWorkTimeOn, setIsWorkTimeOn] = useState(false);

  function preExcelExportSteps(gantt: GanttStatic) {
    gantt.config.work_time = true;
    // gantt.ext.zoom.setLevel('year');
  }

  function postExcelExportSteps(gantt: GanttStatic) {
    gantt.config.work_time = false;
    // gantt.ext.zoom.setLevel('day');
  }

  const handleOnZoomIn = () => gantt.ext.zoom.zoomIn();
  const handleOnZoomOut = () => gantt.ext.zoom.zoomOut();
  const handleOnExportToExcel = () => {
    if (gantt) {
      preExcelExportSteps(gantt);
      gantt.exportToExcel({ visual: 'base-colors' });
      postExcelExportSteps(gantt);
    }
  };
  const handleOnTodayClick = () => gantt.showDate(new Date());
  const handleOnToogleWorkTime = () => setIsWorkTimeOn(!isWorkTimeOn);

  const handleCriticalPathHighlight = () => {
    console.log(params);
    if (params.caseId) {
      dispatch(toggleCriticalPathHighlightInUsecaseGantt());
    } else {
      dispatch(toggleCriticalPathHighlightInProjectGantt());
    }
  };

  const handleClose = () => {
    setUpdateTaskDrawerState(false);
  };

  useEffect(() => {
    function handleOnAfterTaskDrag() {
      return function (id: string) {
        gantt.confirm({
          text: 'Do you want to change task position?',
          ok: 'Yes',
          cancel: 'No',
          callback: function (result: boolean) {
            if (!result) {
              gantt.undo();
            } else {
              if (id) updateTaskInGantt(gantt, id);
            }
          },
        });
      };
    }

    function handleOnTaskSingleClick(id: string, e: any) {
      const updatedTaskFromGantt = gantt.getTask(id);
      const allowedEntitiesList = ['task'];
      if (allowedEntitiesList.includes(updatedTaskFromGantt.entity)) {
        const existingTaskFromAPI = allTasksFromAPI.filter((item: any) => item.task_uid === id)?.[0];
        const finalUpdatedTask = {
          ...existingTaskFromAPI,
          ...transformToAPITaskFormat(updatedTaskFromGantt),
        };
        setTaskData(finalUpdatedTask);
        setUpdateTaskDrawerState(true);
      } else {
        if (e.target.className.includes('gantt_tree_icon')) {
          return true;
        }
      }
    }

    function handleOnAfterLinkAdd() {
      return function (id: string, link: any) {
        if (link.source != undefined && link.target != undefined) updateLinkInGantt(gantt, id, link);
      };
    }
    function handleLinkDeletion() {
      return function (id: string) {
        const link = gantt.getLink(id);
        const linkTitle =
          'Are you sure, you want to delete ' +
          gantt.getTask(link.source).text +
          ' -> ' +
          gantt.getTask(link.target).text +
          ' link?';

        gantt.confirm({
          text: linkTitle,
          ok: 'Yes',
          cancel: 'No',
          callback: function (result: boolean) {
            if (!result) {
              gantt.undo();
            } else {
              deleteLinkInGantt(link);
            }
          },
        });
      };
    }
    gantt.attachEvent('onAfterTaskDrag', handleOnAfterTaskDrag(), {});
    gantt.attachEvent('onAfterLinkAdd', handleOnAfterLinkAdd(), {});
    gantt.attachEvent('onTaskClick', (id, e) => handleOnTaskSingleClick(id, e), {});
    gantt.attachEvent('onLinkDblClick', handleLinkDeletion(), {});
    getExportToX(gantt, customExportExcelFilename); // Single source of Truth - export feature

    const customLabels = {
      taskNameColLabel: label_task ? `${label_task} name` : 'Task name',
      taskOwnerColLabel: label_task ? `${label_task} owner` : 'Task owner',
      taskTooltipLabel: label_task ?? 'Task',
    };

    const ganttDateBoundaryObj = { startDate: ganttStartDateBoundary, endDate: ganttEndDateBoundary };
    loadConfig(gantt, customLabels, workingHours, ganttDateBoundaryObj, isWorkTimeOn);
    loadAttachEvents(gantt);
    loadTemplates(gantt, workingHours, customerAmberThreshold);
    loadBaselineLayer(gantt, customLabels);
    loadWorkTime(gantt, nonWorkingDaysInNumber, holidays);

    const resourcesStore = loadResourcesStore(gantt);
    gantt.init(ganttRootElemId);
    resourcesStore.parse(resourceData);
    gantt.parse({
      data: allData,
      links: gDependencies,
    });
    gantt.showDate(ganttStartDateBoundary);

    return () => {
      gantt.destructor();
    };
  }, [
    gantt,
    allData,
    gDependencies,
    resourceData,
    taskData,
    label_task,
    customExportExcelFilename,
    ganttStartDateBoundary,
    isWorkTimeOn,
  ]);

  return {
    taskData,
    isUpdateTaskDrawerOpen,
    isWorkTimeOn,
    handleClose,
    handleOnZoomIn,
    handleOnZoomOut,
    handleOnExportToExcel,
    handleOnTodayClick,
    handleOnToogleWorkTime,
    handleCriticalPathHighlight,
  };
};
