import { Typography, Stack, TextField } from '@mui/material';
import CognisaasButton from '../../shared/CognisaasButton/CognisaasButton';
import { useState } from 'react';
import { addProjectPhase, addUsecasePhase } from '../../../store/modules/Task/slice';
import { getProjectInformation } from '../../../store/modules/Project/slice';
import { useDispatch } from 'react-redux';
import { getProjectInformationWithCaseTaskByUid } from '../../../store/modules/Project/slice';

const CreatePhase = (props: any) => {
  const [phaseName, setPhaseName] = useState<string>('');
  const [disableSave, setDisableSave] = useState<boolean>(true);
  const dispatch = useDispatch();
  console.log({ props });
  console.log(props.sections.length);
  console.log(props.sections.length + 1);
  const savePhaseData = async () => {
    console.log('dljfkwfj');

    if (props.caseUid) {
      if (!phaseName.replace(/\s/g, '').length) {
        console.log('Phase Name contains only spaces');
        setDisableSave(true);
      } else {
        const phaseData = {
          case_uid: props.caseUid,
          Section_detail: phaseName,
          section_sequence: props.sections.length + 1,
        };
        console.log('Save data to backend', phaseData);
        await dispatch(addUsecasePhase(phaseData));
        const detail = {
          case_uid: props.caseUid,
          association_uid: props.associationUid,
          project_uid: props.projectUid,
        };
        // here or in main component?
        const test = {
          project_uid: props.projectUid,
        };
        await dispatch(getProjectInformation(detail));
        await dispatch(getProjectInformationWithCaseTaskByUid(test));
      }
      props.closeModal();
    } else {
      if (!phaseName.replace(/\s/g, '').length) {
        console.log('Phase Name contains only spaces');
      } else {
        const phaseData = {
          Project_uid: props.projectUid,
          Section_detail: phaseName,
          section_sequence: props.sections.length + 1,
        };
        console.log('Save data to backend', phaseData);
        await dispatch(addProjectPhase(phaseData));
        const detail = {
          association_uid: props.associationUid,
          project_uid: props.projectUid,
        };
        // here or in main component?
        await dispatch(getProjectInformation(detail));
      }
      props.closeModal();
      setDisableSave(true);
    }
  };

  const validateName = (name: string) => {
    if (name.trim() === '') {
      setDisableSave(true);
    } else {
      setDisableSave(false);
    }
  };

  return (
    <>
      <Stack>
        <Stack my={2}>
          <Typography variant="h2">Create Phase</Typography>
        </Stack>
        <Stack direction="row">
          <TextField
            autoComplete=""
            autoFocus={true}
            onChange={(e) => {
              setPhaseName(e.target.value);
              validateName(e.target.value);
            }}
            style={{ width: '410px' }}
            size="small"
            placeholder="Enter phase name"
          ></TextField>
        </Stack>
        <Stack mt={3} spacing={2} justifyContent="flex-end" direction="row">
          <CognisaasButton isOutlined={true} clickHandler={() => props.closeModal()} label="Cancel" />
          <CognisaasButton clickHandler={savePhaseData} isOutlined={false} disabled={disableSave} label="Save" />
        </Stack>
      </Stack>
    </>
  );
};

export default CreatePhase;
