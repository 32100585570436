export const resourceManagementResponseTransformer = (result: any) => {
  const transformedResponse: any[] = [];
  for (let i = 0; i < result.users.length; i++) {
    const userData: any = {};
    userData.customer_uid = result.users[i].customer_uid;
    userData.delivery_manager_email = result.users[i].user_email;
    userData.delivery_manager_name = result.users[i].user_username;
    userData.delivery_manager_uid = result.users[i].user_uid;
    userData.user_company = result.users[i].user_company;
    userData.soft_allocation = result.users[i].soft_allocation;
    userData.user_created_on = result.users[i].user_created_on;
    userData.user_status = result.users[i].user_status;
    userData.__user_permissions = result.users[i].__user_permissions;

    const projectsOfUser: any = [];
    for (let j = 0; j < result.projects.length; j++) {
      if (result.projects[j].user_uid == result.users[i].user_uid) {
        const project: any = {};
        // project = {};
        project.project_details = { ...result.projects[j] };
        const allTasksUnderThisProjectForThisUser: any = [];
        for (let k = 0; k < result.tasks.length; k++) {
          if (
            result.tasks[k].project_uid == result.projects[j].project_uid
            //  &&
            // result.tasks[k].task_owner == result.projects[j].user_uid
          ) {
            allTasksUnderThisProjectForThisUser.push({ ...result.tasks[k], ...result.projects[j], ...result.users[i] });
          }
        }
        project.tasks = [...allTasksUnderThisProjectForThisUser];
        projectsOfUser.push(project);
      }
    }
    userData.projects = [...projectsOfUser];

    transformedResponse.push(userData);
  }
  return transformedResponse;
};
