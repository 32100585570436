import { Grid, Typography } from '@mui/material';
import { ReactComponent as Jira } from '../../../assets/svgs/Jira.svg';
import { label_task } from '../Preferences/CustomLabelCookieHelper';
import IntegrationBox from './IntegrationBox';
// import PipeDriveMergeDialogBox from './CRM/PipeDriveMergeDialogBox';
import { ReactComponent as PipedriveImage } from '../../../assets/svgs/pipedrive-com.svg';
import HubspotMergeDialogBox from './CRM/HubspotMergeDialogBox';
// import FetchandStage from './CRM/FetchandStage';
import { getCustomerIntegrationDetails } from 'store/modules/Settings/slice';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
// import SalesforceMergeDialogBox from './CRM/SalesforceMergeDialogBox';
const Integration = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCustomerIntegrationDetails());
  }, []);

  return (
    <Grid sx={{ padding: '20px' }}>
      <Grid>
        <Grid>
          <Typography variant={'h2'}>Product Management</Typography>
        </Grid>
        <IntegrationBox
          image={<Jira style={{ width: '65px', margin: '0' }} />}
          primaryText="Jira Software Cloud"
          secondaryText="Product Management"
          navigateTo="/settings/integrations/jira/product"
        />
      </Grid>
      <Grid sx={{ marginTop: '36px' }}>
        <Grid>
          <Typography variant={'h2'}>CRM</Typography>
        </Grid>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid direction="row" container spacing={1}>
            <Grid item xs={6}>
              <IntegrationBox
                navigateTo="/settings/CRM/Pipedrive/"
                image={<PipedriveImage style={{ width: '70px', height: '65px', margin: '0' }} />}
                primaryText="Pipedrive"
                secondaryText='CRM'
              />
            </Grid>
            <Grid item xs={4}>
              <HubspotMergeDialogBox />
            </Grid>
          </Grid>
          {/* <Grid direction="row" container spacing={1}>
            <Grid item xs={4}>
              <SalesforceMergeDialogBox />
            </Grid>
          </Grid> */}
          {/* <Grid direction="row" container>
            <FetchandStage />
          </Grid> */}


        </Grid>
      </Grid>
      <Grid sx={{ marginTop: '36px' }}>
        <Grid>
          <Typography variant={'h2'}>{label_task} Management</Typography>
        </Grid>
        <Grid>
          <IntegrationBox
            navigateTo="/settings/integrations/jira/task"
            image={<Jira style={{ width: '65px', margin: '0' }} />}
            primaryText="Jira Software Cloud"
            secondaryText={`${label_task} Management`}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Integration;
