import { Button, Drawer } from '@mui/material';
import { FormDatePicker } from 'components/ResourceManagement/design-system/form/date-picker';
import { FormSelect } from 'components/ResourceManagement/design-system/form/select';
import { Box, GridBox, Text } from 'components/ResourceManagement/design-system/primitives';
import { memo } from 'react';
import styled from 'styled-components';
import { useSoftAllocationForm } from './useSoftAllocationForm';
import IconButton from '@mui/material/IconButton';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { label_project } from 'components/Settings/Preferences/CustomLabelCookieHelper';

const StyledFormGrid = styled(GridBox)`
  grid-template-columns: 1fr;
  grid-template-rows: repeat(auto-fit, 50px);
  grid-gap: ${({ theme }) => theme.space[4]};
  margin: ${({ theme }) => theme.space[20]};
  padding-top: ${({ theme }) => theme.space[23]};
  width: 500px;
  justify-content: center;
  align-items: center;
`;

const StyledDateWrap = styled(GridBox)`
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.space[8]};
  justify-content: space-between;
  align-items: center;
`;

const StyledButtonWrap = styled(GridBox)`
  grid-template-columns: 1fr 1fr 1fr;
  padding-top: ${({ theme }) => theme.space[12]};
  grid-gap: ${({ theme }) => theme.space[8]};
  justify-content: flex-end;
  align-items: center;
`;

const SoftAllocationFormDrawer = () => {
  const {
    hookFormProps,
    softAllocationTypes,
    isSoftAllocationDrawerOpen,
    isProjectDropdownShown,
    startDate,
    endDate,
    selectedAllocationType,
    selectedProjectId,
    allProjectList,
    formMode,
    isError,
    errMsg,
    handleDeleteClick,
    handleClose,
  } = useSoftAllocationForm();

  const { onFormSubmit, handleSubmit } = hookFormProps;

  const isAddForm = formMode === 'add';
  return (
    <Drawer open={isSoftAllocationDrawerOpen} onClose={handleClose} anchor="right">
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <StyledFormGrid>
          {isAddForm ? (
            <Text variant="heading2">Add Soft Allocation</Text>
          ) : (
            <Text variant="heading2">Update Soft Allocation</Text>
          )}
          <Box>
            {isError && (
              <Text variant="paragraph1" color="brag_2">
                {errMsg}
              </Text>
            )}
          </Box>
          <Box>
            <FormSelect
              id="softAllocationType"
              name="softAllocationType"
              size="medium"
              selectedItem={selectedAllocationType}
              defaultOptions={softAllocationTypes}
              targetIdField="id"
              targetNameField="name"
              customLabel="Allocation Type"
              {...hookFormProps}
            />
            {isProjectDropdownShown ? (
              <FormSelect
                id="softAllocationProjectName"
                name="softAllocationProjectName"
                size="medium"
                selectedItem={selectedProjectId}
                defaultOptions={allProjectList}
                targetIdField="id"
                targetNameField="name"
                customLabel={`${label_project} Name`}
                {...hookFormProps}
              />
            ) : null}
            <StyledDateWrap>
              <FormDatePicker
                id="softAllocationStartDate"
                name="softAllocationStartDate"
                selectedDateItem={startDate}
                customLabel="Start Date"
                {...hookFormProps}
              />
              <FormDatePicker
                id="softAllocationEndDate"
                name="softAllocationEndDate"
                selectedDateItem={endDate}
                customLabel="End Date"
                {...hookFormProps}
              />
            </StyledDateWrap>
          </Box>
          <StyledButtonWrap>
            <Box>
              {!isAddForm && (
                <IconButton aria-label="Delete soft allocation" onClick={handleDeleteClick}>
                  <DeleteOutlinedIcon color="error" />
                </IconButton>
              )}
            </Box>
            <Button type="reset" size="large" variant="outlined" onClick={handleClose}>
              CANCEL
            </Button>
            <Button type="submit" size="large" variant="contained" disabled={!hookFormProps.formState.isDirty}>
              {isAddForm ? 'ADD' : 'UPDATE'}
            </Button>
          </StyledButtonWrap>
        </StyledFormGrid>
      </form>
    </Drawer>
  );
};

export default memo(SoftAllocationFormDrawer);
