import { isString } from 'formik';
import { isNull, isUndefined, isValidDate } from './dataHelpers';
export enum Operator {
  Equals,
  NotEquals,
}

/* 
Example How To Use Function: filterDataHavingValueThis,
data = [
  {
            "project_status_reason": "Delivery of one or more land use cases of this project is not planned or is overdue",
            "project_expansionvalue": "0",
            "project_priority": "Medium",
            "association_uid": "6263e94234b55",
            "project_readinessstate_status": "red",
            "project_needed_status": "Land",
            
            "custom_values": [
                {
                    "project_field_id": "14614",
                    "field_uid": "623ea2bcd1fef",
                    "customer_uid": "5ef9a05fa797e",
                    "project_uid": "6263eb6e10d66",
                    "field_seq_num": "10",
                    "field_type": "RAG status",
                    "field_name": "e",
                    "field_value": ""
                }
            ]
        },
        {
          "project_status_reason": "One or more client tasks for this project are overdue.",
          "project_readinessstate_status": "green",
          "project_needed_status": "Land",
          "project_requiredbydate": "2022-07-31 00:00:00",
          "eta_forprojectdelivery": "2022-07-31 00:00:00",
          "custom_values": []
        }
]

const filteredData = filterDataHavingValueThis(data,'project_readinessstate_status', 'red');
this will return all filtered data having red as value with respect to project_readinessstate_status in data

*/
export const filterDataHavingValueThis = (data: any[], dataKeyToBeChecked: string, value: string): any[] => {
  const filteredData = data.filter((element) => {
    return element[dataKeyToBeChecked] === value;
  });

  return filteredData;
};

export class FilterableData extends Array {
  myData: any[];
  constructor(data: any[]) {
    super();
    this.myData = data;
  }

  customFilter(dataKeyToBeChecked: string, value: string, operator: Operator = Operator.Equals) {
    if (operator == Operator.Equals) {
      this.myData = this.myData.filter((element) => {
        return element[dataKeyToBeChecked] === value;
      });
    } else if (operator == Operator.NotEquals) {
      this.myData = this.myData.filter((element) => {
        return element[dataKeyToBeChecked] !== value;
      });
    }
    return this;
  }

  filterInDateOptions(dataKeyToBeChecked: string, selectedTimePeriodOption: typeof availabeTimePeriodOptions[number]) {
    if (selectedTimePeriodOption === 'Last Month') {
      const tempData = this.myData.filter((item: any) => {
        if (isValidDate(new Date(item[dataKeyToBeChecked].replace(/-/g, '/')))) {
          if (isThisDateOf(new Date(item[dataKeyToBeChecked].replace(/-/g, '/')), 'Last Month')) {
            return true;
          }
        }
      });
      this.myData = [...tempData];
      return this;
    } else if (selectedTimePeriodOption === 'This Month') {
      const tempData = this.myData.filter((item: any) => {
        if (isValidDate(new Date(item[dataKeyToBeChecked].replace(/-/g, '/')))) {
          if (isThisDateOf(new Date(item[dataKeyToBeChecked].replace(/-/g, '/')), 'This Month')) {
            return true;
          }
        }
      });
      this.myData = [...tempData];
      return this;
    } else if (selectedTimePeriodOption === 'Next Month') {
      const tempData = this.myData.filter((item: any) => {
        if (isValidDate(new Date(item[dataKeyToBeChecked].replace(/-/g, '/')))) {
          if (isThisDateOf(new Date(item[dataKeyToBeChecked].replace(/-/g, '/')), 'Next Month')) {
            return true;
          }
        }
      });
      this.myData = [...tempData];
      return this;
    } else if (selectedTimePeriodOption === 'Last Quarter') {
      const tempData = this.myData.filter((item: any) => {
        if (isValidDate(new Date(item[dataKeyToBeChecked].replace(/-/g, '/')))) {
          if (isThisDateOf(new Date(item[dataKeyToBeChecked].replace(/-/g, '/')), 'Last Quarter')) {
            return true;
          }
        }
      });
      this.myData = [...tempData];
      return this;
    } else if (selectedTimePeriodOption === 'This Quarter') {
      const tempData = this.myData.filter((item: any) => {
        if (isValidDate(new Date(item[dataKeyToBeChecked].replace(/-/g, '/')))) {
          if (isThisDateOf(new Date(item[dataKeyToBeChecked].replace(/-/g, '/')), 'This Quarter')) {
            return true;
          }
        }
      });
      this.myData = [...tempData];
      return this;
    } else if (selectedTimePeriodOption === 'Next Quarter') {
      const tempData = this.myData.filter((item: any) => {
        if (isValidDate(new Date(item[dataKeyToBeChecked].replace(/-/g, '/')))) {
          if (isThisDateOf(new Date(item[dataKeyToBeChecked].replace(/-/g, '/')), 'Next Quarter')) {
            return true;
          }
        }
      });
      this.myData = [...tempData];
      return this;
    } else {
      return this;
    }
  }

  containsThisList(dataKeyToBeChecked: string, list: string[]) {
    this.myData = this.myData.filter((element) => {
      return list.length === 0 ? true : list.includes(element[dataKeyToBeChecked]);
    });
    return this;
  }

  bulkFilter(
    allFilters: {
      name: string;
      dataKey: string;
      isSelected: boolean;
      equalToValue: string[];
      availableOptions: string[];
    }[]
  ) {
    let filteredData = this.myData;
    filteredData = this.myData.filter((dataEntry) => {
      const dataEntryKeys = Object.keys(dataEntry);
      let thsiDataEntryIsAllowed = true;
      for (let i = 0; i < dataEntryKeys.length; i++) {
        const entryKey = dataEntryKeys[i];

        // console.log(allFilters);

        // checking for particular key in data Entry not all filters at a time
        for (let j = 0; j < allFilters.length; j++) {
          const eachFilter = allFilters[j];
          // console.log(eachFilter.dataKey);
          // console.log(entryKey === eachFilter.dataKey);
          // console.log(entryKey === eachFilter['dataKey'] && eachFilter.isSelected);
          if (eachFilter.isSelected) {
            if (
              eachFilter.equalToValue.length > 0 &&
              (isUndefined(dataEntry[eachFilter.dataKey]) || isNull(dataEntry[eachFilter.dataKey]))
            ) {
              thsiDataEntryIsAllowed = false;
            } else if (entryKey === eachFilter['dataKey']) {
              if (isUndefined(dataEntry[entryKey]) || isNull(dataEntry[entryKey])) {
                thsiDataEntryIsAllowed = false;
              } else if (
                availabeTimePeriodOptions.includes(eachFilter.equalToValue[0]) ||
                availableExtendedTimePeriodOptions.includes(eachFilter.equalToValue[0]) ||
                availabeTimePeriodOptions.includes(eachFilter.availableOptions[0]) ||
                availabeTimePeriodOptions.includes(eachFilter.availableOptions[1]) ||
                availableExtendedTimePeriodOptions.includes(eachFilter.availableOptions[0]) ||
                availableExtendedTimePeriodOptions.includes(eachFilter.availableOptions[1])
              ) {
                // time based filters
                // console.log('tiem based', dataEntry[entryKey], entryKey);
                if (!(eachFilter.equalToValue.length == 0)) {
                  let isValueAlowedOnTimeBasedFiltering = false;
                  eachFilter.equalToValue.forEach((filterName: string) => {
                    if (filterName === 'Today') {
                      if (isValidDate(new Date(dataEntry[entryKey].replace(/-/g, '/')))) {
                        if (isThisDateOf(new Date(dataEntry[entryKey].replace(/-/g, '/')), 'Today')) {
                          isValueAlowedOnTimeBasedFiltering = true;
                          return;
                        }
                      }
                    } else if (filterName === 'Overdue') {
                      if (isValidDate(new Date(dataEntry[entryKey].replace(/-/g, '/')))) {
                        if (isThisDateOf(new Date(dataEntry[entryKey].replace(/-/g, '/')), 'Overdue')) {
                          isValueAlowedOnTimeBasedFiltering = true;
                          return;
                        }
                      }
                    } else if (filterName === 'Week') {
                      if (isValidDate(new Date(dataEntry[entryKey].replace(/-/g, '/')))) {
                        if (isThisDateOf(new Date(dataEntry[entryKey].replace(/-/g, '/')), 'Week')) {
                          isValueAlowedOnTimeBasedFiltering = true;
                          return;
                        }
                      }
                    } else if (filterName === 'Last Month') {
                      if (isValidDate(new Date(dataEntry[entryKey].replace(/-/g, '/')))) {
                        if (isThisDateOf(new Date(dataEntry[entryKey].replace(/-/g, '/')), 'Last Month')) {
                          isValueAlowedOnTimeBasedFiltering = true;
                          return;
                        }
                      }
                    } else if (filterName === 'This Month') {
                      if (isValidDate(new Date(dataEntry[entryKey].replace(/-/g, '/')))) {
                        if (isThisDateOf(new Date(dataEntry[entryKey].replace(/-/g, '/')), 'This Month')) {
                          isValueAlowedOnTimeBasedFiltering = true;
                          return;
                        }
                      }
                    } else if (filterName === 'Next Month') {
                      if (isValidDate(new Date(dataEntry[entryKey].replace(/-/g, '/')))) {
                        if (isThisDateOf(new Date(dataEntry[entryKey].replace(/-/g, '/')), 'Next Month')) {
                          isValueAlowedOnTimeBasedFiltering = true;
                          return;
                        }
                      }
                    } else if (filterName === 'Later Than A Month') {
                      if (isValidDate(new Date(dataEntry[entryKey].replace(/-/g, '/')))) {
                        if (isThisDateOf(new Date(dataEntry[entryKey].replace(/-/g, '/')), 'Later Than A Month')) {
                          isValueAlowedOnTimeBasedFiltering = true;
                          return;
                        }
                      }
                    } else if (filterName === 'Last Quarter') {
                      if (isValidDate(new Date(dataEntry[entryKey].replace(/-/g, '/')))) {
                        if (isThisDateOf(new Date(dataEntry[entryKey].replace(/-/g, '/')), 'Last Quarter')) {
                          isValueAlowedOnTimeBasedFiltering = true;
                          return;
                        }
                      }
                    } else if (filterName === 'This Quarter') {
                      if (isValidDate(new Date(dataEntry[entryKey].replace(/-/g, '/')))) {
                        if (isThisDateOf(new Date(dataEntry[entryKey].replace(/-/g, '/')), 'This Quarter')) {
                          isValueAlowedOnTimeBasedFiltering = true;
                          return;
                        }
                      }
                    } else if (filterName === 'Next Quarter') {
                      if (isValidDate(new Date(dataEntry[entryKey].replace(/-/g, '/')))) {
                        if (isThisDateOf(new Date(dataEntry[entryKey].replace(/-/g, '/')), 'Next Quarter')) {
                          isValueAlowedOnTimeBasedFiltering = true;
                          return;
                        }
                      }
                    } else {
                      console.log('filter name', filterName, 'each fileter', eachFilter);

                      // logic for embedded date range
                      if (!isUndefined(filterName.split('$$')[1])) {
                        console.log(entryKey, dataEntry[entryKey], 'each allowed 1');
                        // normal filtering
                        // console.log('normal filtering');
                        // console.log(eachFilter.equalToValue.includes(dataEntry[entryKey]));
                        if (
                          new Date(addDays(new Date(filterName.split('$$')[0]), -1)) <
                            new Date(dataEntry[entryKey].replace(/-/g, '/')) &&
                          new Date(dataEntry[entryKey].replace(/-/g, '/')) <
                            new Date(addDays(new Date(filterName.split('$$')[1]), 1))
                        ) {
                          // if it comes here that means the data entry is needed
                          console.log(entryKey, dataEntry[entryKey], 'each allowed');
                          // thsiDataEntryIsAllowed = true;
                          isValueAlowedOnTimeBasedFiltering = true;
                        } else {
                          // console.log(entryKey, dataEntry[entryKey]);
                          isValueAlowedOnTimeBasedFiltering = false;
                        }
                      }
                    }
                  });
                  if (!isValueAlowedOnTimeBasedFiltering) {
                    thsiDataEntryIsAllowed = false;
                  }
                }
              } else if (!isUndefined(eachFilter.name.split('$$')[1])) {
                // normal filtering
                // console.log('normal filtering');
                if (eachFilter.equalToValue.length == 0) {
                  // doesnt matter its already allowed by default
                  // console.log(entryKey, dataEntry[entryKey], 'empty equal to');
                } else if (eachFilter.name.split('$$')[1] === 'number-range') {
                  // console.log(eachFilter.equalToValue, 'equal to number');
                  const minNum =
                    eachFilter.equalToValue[0].split('$N$')[0] !== 'undefined'
                      ? parseInt(eachFilter.equalToValue[0].split('$N$')[0])
                      : -99999;
                  const maxNum =
                    eachFilter.equalToValue[0].split('$N$')[0] !== 'undefined'
                      ? parseInt(eachFilter.equalToValue[0].split('$N$')[1])
                      : 99999;
                  const valueToCheck = parseInt(dataEntry[entryKey]);
                  if (valueToCheck < minNum || valueToCheck > maxNum) {
                    thsiDataEntryIsAllowed = false;
                  }
                } else if (eachFilter.name.split('$$')[1] === 'text-contains') {
                  const pattern = eachFilter.equalToValue[0].split('$C$')[0].toLowerCase();
                  const valueToCheck: string = dataEntry[entryKey].toLowerCase();
                  if (valueToCheck.indexOf(pattern) === -1) {
                    thsiDataEntryIsAllowed = false;
                  }
                } else if (
                  new Date(addDays(new Date(eachFilter.equalToValue[0].split('$$')[0]), -1)) <
                    new Date(dataEntry[entryKey].replace(/-/g, '/')) &&
                  new Date(dataEntry[entryKey].replace(/-/g, '/')) <
                    new Date(addDays(new Date(eachFilter.equalToValue[0].split('$$')[1]), 1))
                ) {
                  // if it comes here that means the data entry is needed
                } else {
                  thsiDataEntryIsAllowed = false;
                }
              } else {
                // normal filtering
                // console.log('normal filtering');
                // console.log(eachFilter.equalToValue.includes(dataEntry[entryKey]));
                if (eachFilter.equalToValue.length == 0) {
                  // doesnt matter its already allowed by default
                  // console.log(entryKey, dataEntry[entryKey], 'empty equal to');
                } else if (eachFilter.equalToValue.includes(dataEntry[entryKey])) {
                  // console.log(entryKey, dataEntry[entryKey], 'each allowed');
                } else {
                  // console.log(entryKey, dataEntry[entryKey]);
                  thsiDataEntryIsAllowed = false;
                }
              }

              break;
            }
          }
        }

        if (thsiDataEntryIsAllowed === true) {
          continue;
        } else {
          break;
        }
      }

      if (thsiDataEntryIsAllowed) {
        return true;
      } else {
        return false;
      }
    });

    // console.log('filteredData', filteredData);
    this.myData = filteredData;
    return this;
  }

  // mathematicalFilter(dataKeyToBeChecked: string, mathematicalOperation: string, value: string) {
  //   this.myData = this.myData.filter((element) => {
  //     return element[dataKeyToBeChecked] === value;
  //   });
  //   return this;
  // }

  toArray() {
    return this.myData;
  }
}

export const generateOptionsForThisDataKey = (data: Record<string, any>[], dataKey: string): string[] => {
  const tempUniqueOptions: string[] = [];
  data.forEach((item: Record<string, any>) => {
    if (!tempUniqueOptions.includes(item[dataKey])) {
      if (isString(item[dataKey]) && !(item[dataKey].trim() === '')) {
        tempUniqueOptions.push(item[dataKey]);
      }
    }
  });
  return tempUniqueOptions;
};

export const availabeTimePeriodOptions = [
  'Last Month',
  'This Month',
  'Next Month',
  'Last Quarter',
  'This Quarter',
  'Next Quarter',
];

export const availableExtendedTimePeriodOptions = ['Overdue', 'Today', 'Week', 'This Month', 'Later Than A Month'];

export const getQuarter = (month: number): number => {
  if ([0, 1, 2].includes(month)) return 1;
  if ([3, 4, 5].includes(month)) return 2;
  if ([6, 7, 8].includes(month)) return 3;
  if ([9, 10, 11].includes(month)) return 4;

  return 0;
};

export const isThisDateOf = (
  date: Date,
  filterOption:
    | 'Today'
    | 'Week'
    | 'Overdue'
    | 'Last Month'
    | 'This Month'
    | 'Next Month'
    | 'Later Than A Month'
    | 'Last Quarter'
    | 'This Quarter'
    | 'Next Quarter'
): boolean => {
  const today = new Date();

  switch (filterOption) {
    case 'Today': {
      return date.setHours(0, 0, 0, 0) === today.setHours(0, 0, 0, 0);
      break;
    }
    case 'Overdue': {
      return date < today;
      break;
    }
    case 'Week': {
      const todayDate = today.getDate();
      const todayDay = today.getDay();

      // get first date of week
      const firstDayOfWeek = new Date(today.setDate(todayDate - todayDay));

      // get last date of week
      const lastDayOfWeek = new Date(firstDayOfWeek);
      lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);

      // if date is equal or within the first and last dates of the week
      return date >= firstDayOfWeek && date <= lastDayOfWeek;
      break;
    }
    case 'Last Month':
      {
        if (today.getMonth() == 0) {
          return date.getFullYear() == today.getFullYear() - 1 && date.getMonth() == 11;
        } else {
          return date.getFullYear() == today.getFullYear() && date.getMonth() == today.getMonth() - 1;
        }
      }
      break;
    case 'This Month':
      return date.getFullYear() == today.getFullYear() && date.getMonth() == today.getMonth();
      break;
    case 'Next Month':
      {
        if (today.getMonth() == 11) {
          return date.getFullYear() == today.getFullYear() + 1 && date.getMonth() == 0;
        } else {
          return date.getFullYear() == today.getFullYear() && date.getMonth() == today.getMonth() + 1;
        }
      }
      break;
    case 'Last Quarter':
      {
        const todaysQuarter = getQuarter(today.getMonth());
        const dateQuarter = getQuarter(date.getMonth());

        if (todaysQuarter == 1) {
          return date.getFullYear() == today.getFullYear() - 1 && dateQuarter == 4;
        } else {
          return date.getFullYear() == today.getFullYear() && dateQuarter == todaysQuarter - 1;
        }
      }
      break;
    case 'This Quarter':
      {
        const todaysQuarter = getQuarter(today.getMonth());
        const dateQuarter = getQuarter(date.getMonth());
        return date.getFullYear() == today.getFullYear() && dateQuarter == todaysQuarter;
      }
      break;
    case 'Next Quarter': {
      const todaysQuarter = getQuarter(today.getMonth());
      const dateQuarter = getQuarter(date.getMonth());

      if (todaysQuarter == 4) {
        return date.getFullYear() == today.getFullYear() + 1 && dateQuarter == 1;
      } else {
        return date.getFullYear() == today.getFullYear() && dateQuarter == todaysQuarter + 1;
      }
    }
    case 'Later Than A Month': {
      const current = new Date(today.getFullYear(), today.getMonth() + 1, 1);
      return date > current;
      break;
    }
  }
};

export function addDays(date: Date, days: number) {
  date.setDate(date.getDate() + days);
  return date;
}

export const availableDateRangeOptions = ['startdate', 'enddate'];
