// import Typography from '@mui/material/Typography';
import { getProjectMembers } from 'store/modules/Project/slice';
import { selectProjectMembers } from 'store/modules/Project/selector';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { useParams } from 'react-router-dom';
import TeamMembersTable from './TeamMembersTable';
import { Stack, ButtonGroup } from '@mui/material';
import CognisaasButton from 'components/shared/CognisaasButton/CognisaasButton';
import AddMember from './AddMember';
import BasicModal from 'components/shared/CognisaasModal/CognisaasModal';
import { userPermissionSchema } from 'components/Reports/SideDrawer/types';
import { label_client } from 'components/Settings/Preferences/CustomLabelCookieHelper';
const TeamMatesHome = (props: any) => {
  const { projectInfo, clientInfo } = props;
  const userPermissions = projectInfo.__user_permissions;
  const dispatch = useDispatch();
  const params = useParams();
  const projectMembers = useAppSelector(selectProjectMembers);

  const project_uid = params.projectId as string;

  const [addMembersModalState, setAddMembersModalState] = useState<boolean>(false);
  const [internalTeamView, setInternalTeamView] = useState<boolean>(true);
  const [projectTeamMembers, setProjectTeamMembers] = useState<any[]>([]);
  const [projectClientMembers, setProjectClientMembers] = useState<any[]>([]);

  useEffect(() => {
    const details = {
      project_uid: project_uid as string,
      client_uid: props.clientInfo.client_uid as string
    };
    dispatch(getProjectMembers(details));
    console.log('dispaching');
  }, []);

  useEffect(() => {
    if (projectMembers.length > 0) {
      const team: any = [];
      const client: any = [];
      for (let i = 0; i < projectMembers.length; i++) {
        if (projectMembers[i].is_client_contact == 0) {
          team.push(projectMembers[i]);
        } else {
          client.push(projectMembers[i]);
        }
      }
      console.log('teammates', team, client);

      setProjectTeamMembers([...team]);
      setProjectClientMembers([...client]);
    } else {
      console.log('in else', projectMembers);
      setProjectTeamMembers([]);
      setProjectClientMembers([]);
    }
  }, [projectMembers]);

  return (
    <>
      <Stack>
        <Stack my={3}>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Stack>
              <ButtonGroup
                variant="outlined"
                aria-label="outlined button group"
                sx={{
                  display: 'inline-flex',
                  flexWrap: 'wrap',
                  backgroundColor: 'white',
                  border: '1px solid #E5E5E5',
                  borderRadius: '4px',
                }}
              >
                <CognisaasButton
                  isOutlined={true}
                  label="Internal Team"
                  sx={internalTeamView ? { background: 'rgba(45, 96, 246,0.15)' } : {}}
                  clickHandler={() => setInternalTeamView(true)}
                ></CognisaasButton>
                <CognisaasButton
                  isOutlined={true}
                  label={`${label_client} Team`}
                  sx={!internalTeamView ? { background: 'rgba(45, 96, 246,0.15)' } : {}}
                  clickHandler={() => setInternalTeamView(false)}
                ></CognisaasButton>
              </ButtonGroup>
            </Stack>
            {userPermissions?.includes(userPermissionSchema.EDIT) && (
              <CognisaasButton
                isOutlined={true}
                label="Add Member"
                clickHandler={() => setAddMembersModalState(!addMembersModalState)}
              />
            )}
          </Stack>
        </Stack>
      </Stack>
      {/* <Typography variant="subtitle1">TeamMates Home</Typography> */}
      <TeamMembersTable members={internalTeamView ? projectTeamMembers : projectClientMembers} clientInfo={clientInfo}></TeamMembersTable>
      {addMembersModalState && (
        <BasicModal open={true} onClose={() => setAddMembersModalState(!addMembersModalState)}>
          <AddMember
            isUserInTeamView={internalTeamView}
            onClose={() => setAddMembersModalState(!addMembersModalState)}
            projectMembers={internalTeamView ? projectTeamMembers : projectClientMembers}
            clientInfo={clientInfo}
          ></AddMember>
        </BasicModal>
      )}
    </>
  );
};

export default TeamMatesHome;
