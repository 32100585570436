import { operatorType, SortingOrder } from '../types';

export function stableSort(array: any, order: SortingOrder) {
  const data = array.sort((a: any, b: any) => {
    if (order === 'asc') {
      return a.name.localeCompare(b.name);
    } else {
      return b.name.localeCompare(a.name);
    }
  });
  return data;
}

export const operatorMapping: Record<operatorType, string> = {
  EQ: 'is',
  IN: 'is in',
  NEQ: 'is not',
  NIN: 'is not in',
  HAS: 'has',
  HASN: 'has not',
};
