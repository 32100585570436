import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../..';

export const selectRevenueTasksState = (state: RootState) => state.revenueProjectTasksState;
export const selectRevenueTasks = createSelector(selectRevenueTasksState, (revenueTasks) => revenueTasks.revenueTasks);
export const selectRevenueTasksStatus = createSelector(selectRevenueTasksState, (revenueTasks) => revenueTasks.status);
export const selectRevenueTasksError = createSelector(selectRevenueTasksState, (revenueTasks) => revenueTasks.error);
export const selectRevenueUsecaseTaskState = createSelector(
  selectRevenueTasksState,
  (revenueTasks) => revenueTasks.revenueUsecaseTask
);
export const selectRevenueProjectTaskState = createSelector(
  selectRevenueTasksState,
  (revenueTasks) => revenueTasks.revenueProjectTask
);
