import reportStyles from '../reports.module.css';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
// import { ReactComponent as VisualiseChart } from '../../../assets/svgs/visualiseChart.svg';
import { ReactComponent as VisualiseChart } from '../../../assets/svgs/visualiseChartsIcon.svg';
import { IconButton, Typography } from '@mui/material';
import CognisaasMenu from '../../shared/CognisaasMenu/CognisaasMenu';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import {
  label_client,
  label_project,
  label_task,
  label_use_case,
} from 'components/Settings/Preferences/CustomLabelCookieHelper';

export const ReportsPages = {
  RevenueReports: 'Revenue Reports',
  Resources: 'Resources',
  Projects: `${label_project}s`,
  Usecases: `${label_use_case}s`,
  Tasks: `${label_task}s`,
  Performance: 'Performance',
  Phases: 'Phases',
  Client_Contacts: `${label_client} Contacts`,
};

const pages = [
  'Revenue Reports',
  'Resources',
  `${label_project}s`,
  `${label_use_case}s`,
  `${label_task}s`,
  'Performance',
  'Phases',
  `${label_client} Contacts`,
];

type ReportsHeaderProps = {
  pageName: any;
  setDrawerState?: React.Dispatch<React.SetStateAction<boolean>>;
};

const ReportsHeader = (props: ReportsHeaderProps) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const pathName = window.location.pathname;

  const handleDropDownClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropDownClose = () => {
    setAnchorEl(null);
  };

  const handleDropDownSelect = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
    switch (event.currentTarget.innerText) {
      case ReportsPages.RevenueReports:
        navigate('/reports/Revenue');
        break;
      case ReportsPages.Resources:
        navigate('/reports/Resources');
        break;
      case ReportsPages.Projects:
        navigate('/reports/Projects');
        break;
      case ReportsPages.Usecases:
        navigate('/reports/UseCases');
        break;
      case ReportsPages.Tasks:
        navigate('/reports/Tasks');
        break;
      case ReportsPages.Performance:
        navigate('/reports/Performance');
        break;
      case ReportsPages.Phases:
        navigate('/reports/Phases');
        break;
      case ReportsPages.Client_Contacts:
        navigate('/reports/Client-Contacts');
        break;
    }
  };

  return (
    <div className={reportStyles.reports_detailsHeader}>
      <span className={reportStyles.reports_detailsHeaderSpan}>
        <div className={reportStyles.reports_group}>
          <IconButton
            onClick={() => {
              navigate(-1);
            }}
            component="span"
          >
            <KeyboardArrowLeftIcon />
          </IconButton>
          <Typography variant="h6">{props.pageName}</Typography>
          <IconButton onClick={handleDropDownClick} component="span">
            <KeyboardArrowDownIcon />
          </IconButton>
          <CognisaasMenu
            anchorEl={anchorEl}
            open={open}
            handleSelectFromMenu={handleDropDownSelect}
            handleMenuClose={handleDropDownClose}
            list={pages}
          />
        </div>
        {console.log('path', pathName)}
        {pathName.includes('/reports/Projects') ||
        pathName.includes('/reports/UseCases') ||
        pathName.includes('/reports/Tasks') ||
        pathName.includes('/reports/custom-report') ||
        pathName.includes('/reports/aggregated-custom-report') ? (
          <div
            className={reportStyles.reports_visualiseIcon}
            onClick={() => props.setDrawerState !== undefined && props.setDrawerState((prevState) => !prevState)}
          >
            Visualize data <VisualiseChart style={{ width: '24px', height: '24px' }} />
          </div>
        ) : null}
      </span>
    </div>
  );
};
export default ReportsHeader;
