import { apis } from '../axiosInstance';
import { csatRoutes } from '../route';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const getCsatReviewsByCustomerUid = () =>
  apis.phpAxiosInstance.get(csatRoutes.getCsatReviewsByCustomerUid(cookies.get('cogni_customer_uid') as string), {
    headers: { JWT: 'true' },
  });

const getCsatByTaskUid_modules = (task_uid: string) =>
  apis.phpAxiosInstance.get(csatRoutes.getCsatByTaskUid(task_uid), { headers: { JWT: 'true' } });

const getCsatTargetByCustomerUid_modules = () =>
  apis.phpAxiosInstance.get(csatRoutes.getCsatTargetByCustomerUid(cookies.get('cogni_customer_uid') as string), {
    headers: { JWT: 'true' },
  });

const updateCsatTargetByCustomerUid_modules = (payload: any) =>
  apis.phpAxiosInstance.post(csatRoutes.updateCsatTargetByCustomerUid(), payload, { headers: { JWT: 'true' } });

const sendCsatMailToClientContacts_modules = (payload: any) =>
  apis.phpAxiosInstance.post(csatRoutes.sendCsatMailToClientContacts(), payload, { headers: { JWT: 'true' } });

const sendUseCaseMailToClientContacts_modules = (payload: any) =>
  apis.phpAxiosInstance.post(csatRoutes.sendUseCaseMailToClientContacts(), payload, { headers: { JWT: 'true' } });

const clientUpdateCsatRating_modules = (payload: any) =>
  apis.phpAxiosInstance.post(csatRoutes.clientUpdateCsatRating(), payload, { headers: { JWT: 'true' } });

const clientBulkUpdateCsatRating_modules = (payload: any) =>
  apis.phpAxiosInstance.post(csatRoutes.clientBulkUpdateCsatRating(), payload, { headers: { JWT: 'true' } });

const getCsatByCsatUid_modules = (csat_uid: string) =>
  apis.phpAxiosInstance.get(csatRoutes.getCsatByCsatUid(csat_uid), { headers: { JWT: 'true' } });

// const getCsatByTaskUid_modules = (payload:any) =>
// axiosInstance.post(csatRoutes.getCsatByTaskUid(task_uid),payload, { headers: { JWT: 'true' } });

export const csatAPI = {
  getCsatReviewsByCustomerUid,
  getCsatByTaskUid_modules,
  getCsatTargetByCustomerUid_modules,
  updateCsatTargetByCustomerUid_modules,
  sendCsatMailToClientContacts_modules,
  sendUseCaseMailToClientContacts_modules,
  clientUpdateCsatRating_modules,
  clientBulkUpdateCsatRating_modules,
  getCsatByCsatUid_modules,
};
