import React, { useEffect, useRef, useState } from 'react';
import Popover from '@mui/material/Popover';

import { BarProps } from 'components/ResourceManagement/components/bar';
import { Box, FlexBox, GridBox, SvgRect, SvgText, Text } from 'components/ResourceManagement/design-system/primitives';
import styled from 'styled-components';
import {
  selectSoftAllocationData,
  setSelectedSoftAllocationById,
  setSoftAllocationDrawerState,
} from 'store/modules/ResourceManagement';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { usePopover } from '../../shared/usePopover';
import { getShortDate } from 'components/ResourceManagement/utils/date';

const StyledBarText = styled(SvgText)<{
  isTextInside: boolean;
}>`
  ${({ theme, isTextInside }) => {
    return isTextInside
      ? `
  fill: ${theme.colors.grey_1};
  text-anchor: middle;
  font-weight: ${theme.fontWeights[1]};
  dominant-baseline: central;
  user-select: none;
  pointer-events: none;
  font-size: small;`
      : `
  fill: ${theme.colors.grey_1};
  text-anchor: start;

  user-select: none;
  pointer-events: none;
  font-size: small;
  `;
  }}
`;

const StyledBarRect = styled(SvgRect)<{
  allocationType: string;
}>`
  ${({ theme, allocationType }) => {
    if (allocationType === '2') {
      return {
        fill: `${theme.colors.grey_3}`,
        stroke: `${theme.colors.grey_2}`,
      };
    } else {
      return {
        fill: `${theme.colors.pastel_2}`,
        stroke: `${theme.colors.bragLite_3}`,
      };
    }
  }}
`;

const StyledSvgGroup = styled('g')`
  cursor: pointer;
  outline: none;
  pointer-events: auto;
`;

const StyledPopoverGrid = styled(GridBox)`
  grid-template-columns: 1fr;
  padding: ${({ theme }) => theme.space[5]};
  grid-column-gap: ${({ theme }) => theme.space[5]};
`;

export const SoftAllocationBar: React.FC<BarProps> = ({ component, barCornerRadius }) => {
  const textRef = useRef<SVGTextElement>(null);
  const [isTextInside, setIsTextInside] = useState(true);
  const dispatch = useAppDispatch();
  const selectedSoftAllocationData: any = useAppSelector(selectSoftAllocationData);
  const { anchorEl, handlePopoverOpen, handlePopoverClose, popoverId, popoverOpen } = usePopover();

  const componentHeight = 17;

  const width = component.x2 - component.x1;
  let actualBarRectWidth = width;
  if (actualBarRectWidth < 0) actualBarRectWidth = ~actualBarRectWidth;

  const getBarTextX = () => {
    const width = component.x2 - component.x1;
    // let actualWidth = width;
    // if (actualWidth < 0) actualWidth = ~actualWidth;

    // const hasChild = component.barChildren.length > 0;
    if (isTextInside) {
      return component.x1 + width * 0.5;
    }
    return component.x1 + width + 5;
  };

  useEffect(() => {
    if (textRef.current) {
      setIsTextInside(textRef.current.getBBox().width < component.x2 - component.x1);
    }
  }, [textRef, component]);

  // console.log({ component });
  const barTextX = getBarTextX();
  let actualBarTextX = barTextX;
  if (actualBarTextX < 0) actualBarTextX = ~actualBarTextX;

  const handleBarClick = ({ id, entity }: { id: string; entity: string }) => {
    // console.log({ id, entity, });
    if (entity === 'soft') {
      const softAllocationId = id;
      const softAllocationObj = selectedSoftAllocationData.find((item: any) => item.id === softAllocationId);
      // console.log('SoftBar', { softAllocationObj });
      dispatch(
        setSoftAllocationDrawerState({
          isOpen: true,
          formMode: 'update',
          id: softAllocationObj?.id,
          resourceId: softAllocationObj?.resourceId,
          startDate: softAllocationObj?.start,
          endDate: softAllocationObj?.end,
          allocationType: softAllocationObj?.allocationType,
          projectId: softAllocationObj?.projectId,
          isError: false,
          errMsg: '',
        })
      );
      dispatch(setSelectedSoftAllocationById({ softAllocationId }));
    }
  };

  // console.log({ actualBarTextX, actualBarRectWidth, component });

  const ceilBarTextX = Math.ceil(actualBarTextX);

  return (
    <>
      <StyledSvgGroup
        tabIndex={0}
        onClick={() => handleBarClick(component)}
        onMouseEnter={(e: any) => handlePopoverOpen(e)}
        onMouseLeave={handlePopoverClose}
      >
        {actualBarRectWidth ? (
          <StyledBarRect
            x={component.x1}
            width={actualBarRectWidth}
            y={component.softY}
            height={componentHeight}
            rx={barCornerRadius}
            ry={barCornerRadius}
            allocationType={component?.allocationType !== undefined ? component?.allocationType : '0'}
          />
        ) : null}
        {ceilBarTextX ? (
          <StyledBarText
            x={ceilBarTextX}
            y={isTextInside ? component.y + componentHeight * 0.5 : component.y + componentHeight * 0.75}
            isTextInside={isTextInside}
            ref={textRef}
            variant="paragraph2"
          >
            <>{component.name}</>
          </StyledBarText>
        ) : null}
      </StyledSvgGroup>
      <Popover
        id={popoverId}
        sx={{
          pointerEvents: 'none',
        }}
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        style={{ zIndex: 3 }}
      >
        <StyledPopoverGrid>
          <FlexBox variant="flexCol" justifyContent="center" alignItems="center">
            <Text variant="heading3"> {component.name}</Text>
            <Text variant="paragraph2">{`${getShortDate(component.start)} - ${getShortDate(component.end)}`}</Text>
          </FlexBox>
        </StyledPopoverGrid>
      </Popover>
      <Box></Box>
    </>
  );
};
