
// actual delay = actual_start_date > planned_start_date ? planned_start_date - actual_start_date : 0
// 0 --> equal (0 delay), 1 -> planned_start > actual_start (0 delay), -1 --> planned_start < actual_start (some delay)
export const calculate_startingDelay = (data: any) => {
    const lst: any = [];
    data && data.forEach((obj: any) => {
        const planned_start_date = new Date(obj.project_planned_startdate.replace(/-/g, '/').slice(0, 10));
        const actual_start_date = new Date(obj.project_startdate.replace(/-/g, '/').slice(0, 10));
        const diff = (planned_start_date.getTime() - actual_start_date.getTime()) / (24 * 60 * 60 * 1000);
        if(isNaN(diff)){
            lst.push(0);
        }else{
            if(diff < 0){
                lst.push(Math.abs(diff));
            }else{
                lst.push(0);
            }
        }
    });
    return lst;
};

export const calculate_endingDelay = (data: any) => {
    const lst: any = [];
    data && data.forEach((obj: any) => {
        const planned_finish_date = new Date(obj.eta_forprojectdelivery.replace(/-/g, '/').slice(0, 10));
        const actual_finish_date = new Date(obj.project_deliverydate.replace(/-/g, '/').slice(0, 10));
        const diff = (planned_finish_date.getTime() - actual_finish_date.getTime()) / (24 * 60 * 60 * 1000);
        if(isNaN(diff)){
            lst.push(0);
        }else{
            if(diff < 0){
                lst.push(Math.abs(diff));
            }else{
                lst.push(0);
            }
        }
    });
    return lst;
};

export const calculate_durationPlanned = (data: any) => {
    const lst: any = [];
    data && data.forEach((obj: any) => {
        const planned_start_date = new Date(obj.project_planned_startdate.replace(/-/g, '/').slice(0, 10));
        const planned_finish_date = new Date(obj.eta_forprojectdelivery.replace(/-/g, '/').slice(0, 10));
        const diff = (planned_finish_date.getTime() - planned_start_date.getTime()) / (24 * 60 * 60 * 1000);
        if(isNaN(diff)){
            lst.push(0);
        }else{
            lst.push(diff);
        }
        // lst.push(diff);
    });
    return lst;
};

export const calculate_durationActual = (data: any) => {
    const lst: any = [];
    data && data.forEach((obj: any) => {
        const actual_start_date = new Date(obj.project_startdate.replace(/-/g, '/').slice(0, 10));
        const actual_finish_date = new Date(obj.project_deliverydate.replace(/-/g, '/').slice(0, 10));
        const diff = (actual_finish_date.getTime() - actual_start_date.getTime()) / (24 * 60 * 60 * 1000);
        if(isNaN(diff)){
            lst.push(0);
        }else{
            lst.push(diff);
        }
    });
    return lst;
};

export const calculate_project_completedOnTime = (data: any) => {
    const lst: any = [];
    data && data.forEach((obj: any) => {
        const planned_finish_date = new Date(obj.eta_forprojectdelivery.replace(/-/g, '/').slice(0, 10));
        const actual_finish_date = new Date(obj.project_deliverydate.replace(/-/g, '/').slice(0, 10));
        const diff = (planned_finish_date.getTime() - actual_finish_date.getTime()) / (24 * 60 * 60 * 1000);
        if(isNaN(diff) || diff==0){
            lst.push(0);
        }else{
            if(diff < 0){
                lst.push(0);
            }else{
                lst.push(1);
            }
        }
    });
    return lst;
};

export const calculate_projectStartedOnTime = (data: any) => {
    const lst: any = [];
    data && data.forEach((obj: any) => {
        const planned_start_date = new Date(obj.project_planned_startdate.replace(/-/g, '/').slice(0, 10));
        const actual_start_date = new Date(obj.project_startdate.replace(/-/g, '/').slice(0, 10));
        const diff = (planned_start_date.getTime() - actual_start_date.getTime()) / (24 * 60 * 60 * 1000);
        if(isNaN(diff)){
            lst.push(0);
        }else{
            if(diff < 0){
                lst.push(0);
            }else{
                lst.push(1);
            }
        }
    });
    return lst;
};


export const getFilteredData = (data: any) => {
    const startingDelay = calculate_startingDelay(data);
    const endingDelay = calculate_endingDelay(data);
    const plannedDuration = calculate_durationPlanned(data);
    const actualDuration = calculate_durationActual(data);
    const projectCompletedOnTime = calculate_project_completedOnTime(data);
    const projectStartedOnTime = calculate_projectStartedOnTime(data);
    const final = data && data.map((obj: any, index: number) => {
        const temp = {
            ...obj, 
            'starting_delay': startingDelay[index],
            'ending_delay': endingDelay[index],
            'planned_duration': plannedDuration[index],
            'actual_duration': actualDuration[index],
            'isProjectCompletedOnTime': projectCompletedOnTime[index],
            'isProjectStartedOnTime': projectStartedOnTime[index]
        };
        return temp;
    });
    // if(final == undefined){
    //     final = [{...temp}];
    // }
    return final;
};
// 