export const dataTypes = {
  string: 'string',
  date: 'date',
  link: 'link',
  rag: 'rag',
  progressBar: 'progressBar',
  number: 'number',
  dropDown: 'dropDown',
  userName: 'userName',
  taskPanel: 'taskPanel',
  dialog: 'dialog',
  boolean: 'boolean',
};
