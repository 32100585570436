import {
  Drawer,
  // Accordion,
  // AccordionDetails,
  // AccordionSummary,
  // Badge,
  // Checkbox,
  // FormControlLabel,
  IconButton,
  InputLabel,
  Paper,
  Stack,
  TextareaAutosize,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CognisaasButton from 'components/shared/CognisaasButton/CognisaasButton';
import CognisaasButtonWithLoading from 'components/shared/CognisaasButton/CognisaasButtonWithLoading';
import CognisaasSwitchButton from 'components/shared/CognisaasSwitch/CognisaasSwitch';
import ProjectAggregration from '../AggregrationEntity/ProjectAggregration';
import InfoIcon from 'components/shared/Icons/InfoIcon';
import { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import { getCustomerUid, getLoggedInUserUid } from 'utils/getUid';
import clientEntity from '../Entity/ClientEntity';
import projectEntity from '../Entity/projectEntity';
import taskEntity from '../Entity/taskEntity';
import usecaseEntity from '../Entity/usecaseEntity';

// import {
//   label_client,
//   label_project,
//   label_task,
//   label_use_case,
// } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import {
  getAggregatedCustomReportConfigByUid,
  updateAggregatedCustomReport,
} from 'store/modules/Reports/AggregatedCustomReports/slice';
import CognisaasSelect from 'components/shared/CognisaasSelect/CognisaasSelect';
import ClientAggregration from '../AggregrationEntity/ClientAggregration';
import UsecaseAggregration from '../AggregrationEntity/UsecaseAggregration';
import TaskAggregration from '../AggregrationEntity/TaskAggregration';
import { label_client, label_project, label_use_case } from 'components/Settings/Preferences/CustomLabelCookieHelper';
import { getCustomEntityName, getStandardEntityName } from '../UtilityFunction';
import { REPORT_TYPE_AGGREGATED_REPORT, REPORT_TYPE_CUSTOM_REPORT } from 'components/Reports/Shared/constants';

export const entities = ['None', label_client, label_project, label_use_case];

interface Props {
  onClose: () => void;
  reportDetails: any;
  refreshFilters: any;
  reportId: string;
}

const UpdateAggregatedCustomReports = (props: Props) => {
  // entities
  const { clientEntityWithCustomFields } = clientEntity();
  const { projectEntityWithCustomFields } = projectEntity();
  const { usecaseEntityWithCustomFields } = usecaseEntity();
  const { taskEntityWithCustomFields } = taskEntity();

  const dispatch = useAppDispatch();
  // const navigate = useNavigate();

  const [reportName, setReportName] = useState<string>('');
  const [reportDescription, setReportDescription] = useState<string>();
  const [isPrivate, setIsPrivate] = useState<boolean>(true);
  const [topEntity, setTopEntity] = useState<any>(entities[0]);
  const [writeAccess, setWriteAccess] = useState<boolean>(false);
  const [selectedProjectEntities, setSelectedProjectEntities] = useState<any[]>([]);
  const [selectedClientEntities, setSelectedClientEntities] = useState<any[]>([]);
  const [selectedUsecaseEntities, setSelectedUsecaseEntities] = useState<any[]>([]);
  const [selectedTaskEntities, setSelectedTaskEntities] = useState<any[]>([]);
  const [selectedDataForClient, setSelectedDataForClient] = useState<any[]>([]);
  const [selectedDataForProject, setSelectedDataForProject] = useState<any[]>([]);
  const [selectedDataForUsecase, setSelectedDataForUsecase] = useState<any[]>([]);
  const [selectedDataForTask, setSelectedDataForTask] = useState<any[]>([]);
  const [reportDetail, setReportDetail] = useState<any>(null);

  // uri parameters
  // const url: any = window.location.pathname;
  // const params = url.split('/');
  const reportId = props.reportId;

  useEffect(() => {
    if (reportDetail) {
      setReportName(reportDetail.report_name);
      if (reportDetail.report_type === REPORT_TYPE_CUSTOM_REPORT) {
        setTopEntity('None');
      } else {
        setTopEntity(getCustomEntityName(reportDetail.top_entity));
      }
      setReportDescription(reportDetail.report_description);
      // setTopEntity(reportDetail.top_entity);
      setIsPrivate(reportDetail.is_private === '0' ? false : true);
      setWriteAccess(reportDetail.global_write_access === '0' ? false : true);
    }
  }, [reportDetail]);

  useEffect(() => {
    makeDataPropsForUpdate();
  }, [
    clientEntityWithCustomFields,
    projectEntityWithCustomFields,
    usecaseEntityWithCustomFields,
    taskEntityWithCustomFields,
  ]);

  const makeDataPropsForUpdate = async () => {
    const detail = await dispatch(getAggregatedCustomReportConfigByUid(reportId)).unwrap();
    setReportDetail({ ...detail[0] });

    const config: any[] = [...detail];
    const clientTemp: any[] = [];
    for (const property in clientEntityWithCustomFields) {
      const obj: any = {
        columnName: clientEntityWithCustomFields[property].displayName,
        displayName: clientEntityWithCustomFields[property].displayName,
        selected: false,
        fieldName: clientEntityWithCustomFields[property].key,
        aggregations: [],
      };
      for (let clientConfig = 0; clientConfig < config[0].report_config.client.length; clientConfig++) {
        if (
          config[0].top_entity == 'client' &&
          config[0].report_config.client[clientConfig].displayName == clientEntityWithCustomFields[property].displayName
        ) {
          obj.selected = true;
        } else if (
          config[0].top_entity != 'client' &&
          config[0].report_config.client[clientConfig].displayName == clientEntityWithCustomFields[property].displayName
        ) {
          obj.aggregations.push({
            columnName: config[0].report_config.client[clientConfig].displayName,
            aggregationType: config[0].report_config.client[clientConfig].aggregationToPerform,
            fieldName: config[0].report_config.client[clientConfig].key,
            conditions: config[0].report_config.client[clientConfig].conditions,
          });
          obj.selected = true;
        }
      }
      clientTemp.push(obj);
    }
    setSelectedDataForClient([...clientTemp]);
    setSelectedClientEntities([...clientTemp]);

    const projectTemp: any[] = [];
    for (const property in projectEntityWithCustomFields) {
      const obj: any = {
        displayName: projectEntityWithCustomFields[property].displayName,
        selected: false,
        fieldName: projectEntityWithCustomFields[property].key,
        aggregations: [],
      };
      for (let projectConfig = 0; projectConfig < config[0].report_config.project.length; projectConfig++) {
        if (config[0].top_entity == 'project' && config[0].report_config.project[projectConfig].key == property) {
          obj.selected = true;
        } else if (
          config[0].top_entity != 'project' &&
          config[0].report_config.project[projectConfig].key == property
        ) {
          obj.aggregations.push({
            columnName: config[0].report_config.project[projectConfig].displayName,
            aggregationType: config[0].report_config.project[projectConfig].aggregationToPerform,
            fieldName: config[0].report_config.project[projectConfig].key,
            conditions: config[0].report_config.project[projectConfig].conditions,
          });
          obj.selected = true;
        }
      }
      projectTemp.push(obj);
    }
    setSelectedDataForProject([...projectTemp]);
    setSelectedProjectEntities([...projectTemp]);

    const usecaseTemp: any[] = [];
    for (const property in usecaseEntityWithCustomFields) {
      const obj: any = {
        displayName: usecaseEntityWithCustomFields[property].displayName,
        selected: false,
        fieldName: usecaseEntityWithCustomFields[property].key,
        aggregations: [],
      };
      for (let usecaseConfig = 0; usecaseConfig < config[0].report_config.usecase.length; usecaseConfig++) {
        if (config[0].top_entity == 'usecase' && config[0].report_config.usecase[usecaseConfig].key == property) {
          obj.selected = true;
        } else if (
          config[0].top_entity != 'usecase' &&
          config[0].report_config.usecase[usecaseConfig].key == property
        ) {
          obj.aggregations.push({
            columnName: config[0].report_config.usecase[usecaseConfig].displayName,
            aggregationType: config[0].report_config.usecase[usecaseConfig].aggregationToPerform,
            fieldName: config[0].report_config.usecase[usecaseConfig].key,
            conditions: config[0].report_config.usecase[usecaseConfig].conditions,
          });
          obj.selected = true;
        }
      }
      usecaseTemp.push(obj);
    }
    setSelectedDataForUsecase([...usecaseTemp]);
    setSelectedUsecaseEntities([...usecaseTemp]);

    const taskTemp: any[] = [];
    for (const property in taskEntityWithCustomFields) {
      const obj: any = {
        displayName: taskEntityWithCustomFields[property].displayName,
        selected: false,
        fieldName: taskEntityWithCustomFields[property].key,
        aggregations: [],
      };
      for (let taskConfig = 0; taskConfig < config[0].report_config.task.length; taskConfig++) {
        if (config[0].top_entity == 'task' && config[0].report_config.task[taskConfig].key == property) {
          obj.selected = true;
        } else if (config[0].top_entity != 'task' && config[0].report_config.task[taskConfig].key == property) {
          // obj.aggregations.push(config[0].report_config.task[taskConfig]);
          obj.aggregations.push({
            columnName: config[0].report_config.task[taskConfig].displayName,
            aggregationType: config[0].report_config.task[taskConfig].aggregationToPerform,
            fieldName: config[0].report_config.task[taskConfig].key,
            conditions: config[0].report_config.task[taskConfig].conditions,
          });
          obj.selected = true;
        }
      }
      taskTemp.push(obj);
    }
    setSelectedDataForTask([...taskTemp]);
    setSelectedTaskEntities([...taskTemp]);
  };

  // loader for save button
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const makeReportConfig = () => {
    const clientFields: any = [];
    console.log(
      'make report config',
      selectedClientEntities,
      selectedProjectEntities,
      selectedUsecaseEntities,
      selectedTaskEntities
    );
    for (let client = 0; client < selectedClientEntities.length; client++) {
      if (selectedClientEntities[client].selected) {
        if (selectedClientEntities[client].aggregations.length > 0) {
          for (let j = 0; j < selectedClientEntities[client].aggregations.length; j++) {
            const field = {
              ...clientEntityWithCustomFields[selectedClientEntities[client].aggregations[j].fieldName],
              displayName: selectedClientEntities[client].aggregations[j].columnName,
              aggregationToPerform:
                topEntity === 'None' ? 'nothing' : selectedClientEntities[client].aggregations[j].aggregationType,
              isAggregatable: topEntity === 'None' ? false : true,
              toAggregate: topEntity === 'None' ? false : true,
              conditions: topEntity === 'None' ? [] : selectedClientEntities[client].aggregations[j].conditions,
            };
            clientFields.push(field);
          }
        } else {
          const field = {
            ...clientEntityWithCustomFields[selectedClientEntities[client].fieldName],
            displayName: selectedClientEntities[client].displayName,
            aggregationToPerform: 'nothing',
            isAggregatable: topEntity === 'None' ? false : true,
            toAggregate: topEntity === 'None' ? false : true,
            conditions: [],
          };
          clientFields.push(field);
        }
      }
    }

    const projectFields: any = [];
    for (let project = 0; project < selectedProjectEntities.length; project++) {
      if (selectedProjectEntities[project].selected) {
        if (selectedProjectEntities[project].aggregations.length > 0) {
          for (let j = 0; j < selectedProjectEntities[project].aggregations.length; j++) {
            const field = {
              ...projectEntityWithCustomFields[selectedProjectEntities[project].aggregations[j].fieldName],
              displayName: selectedProjectEntities[project].aggregations[j].columnName,
              aggregationToPerform:
                topEntity === 'None' ? 'nothing' : selectedProjectEntities[project].aggregations[j].aggregationType,
              isAggregatable: topEntity === 'None' ? false : true,
              toAggregate: topEntity === 'None' ? false : true,
              conditions: topEntity === 'None' ? [] : selectedProjectEntities[project].aggregations[j].conditions,
            };
            projectFields.push(field);
          }
        } else {
          const field = {
            ...projectEntityWithCustomFields[selectedProjectEntities[project].fieldName],
            displayName: selectedProjectEntities[project].displayName,
            aggregationToPerform: 'nothing',
            isAggregatable: topEntity === 'None' ? false : true,
            toAggregate: topEntity === 'None' ? false : true,
            conditions: [],
          };
          projectFields.push(field);
        }
      }
    }
    // TEST
    // projectFields.push({
    //   displayName: 'Scheme Date of Delivery2',
    //   key: 'project_deliverydate',
    //   type: 'date',
    //   isAggregatable: true,
    //   isCustomField: false,
    //   allowedOperationsList: ['count'],
    //   toAggregate: true,
    //   aggregationToPerform: 'nothing',
    //   conditions: [
    //     {
    //       type: '>',
    //       value: 'eta_forprojectdelivery',
    //       chainType: null,
    //       customValue: false,
    //       dropdownFields: [
    //         'Scheme Planned Start Date',
    //         'Scheme Start Date',
    //         'Scheme ETA for Delivery',
    //         'Scheme ETA',
    //         'Scheme Date of Delivery',
    //         'Scheme Created on',
    //         'eta',
    //       ],
    //     },
    //   ],
    // });

    const usecaseFields: any = [];
    for (let usecase = 0; usecase < selectedUsecaseEntities.length; usecase++) {
      if (selectedUsecaseEntities[usecase].selected) {
        if (selectedUsecaseEntities[usecase].aggregations.length > 0) {
          for (let j = 0; j < selectedUsecaseEntities[usecase].aggregations.length; j++) {
            const field = {
              ...usecaseEntityWithCustomFields[selectedUsecaseEntities[usecase].aggregations[j].fieldName],
              displayName: selectedUsecaseEntities[usecase].aggregations[j].columnName,
              aggregationToPerform:
                topEntity === 'None' ? 'nothing' : selectedUsecaseEntities[usecase].aggregations[j].aggregationType,
              isAggregatable: topEntity === 'None' ? false : true,
              toAggregate: topEntity === 'None' ? false : true,
              conditions: topEntity === 'None' ? [] : selectedUsecaseEntities[usecase].aggregations[j].conditions,
            };
            usecaseFields.push(field);
          }
        } else {
          const field = {
            ...usecaseEntityWithCustomFields[selectedUsecaseEntities[usecase].fieldName],
            displayName: selectedUsecaseEntities[usecase].displayName,
            aggregationToPerform: 'nothing',
            isAggregatable: topEntity === 'None' ? false : true,
            toAggregate: topEntity === 'None' ? false : true,
            conditions: [],
          };
          usecaseFields.push(field);
        }
      }
    }

    const taskFields: any = [];
    for (let task = 0; task < selectedTaskEntities.length; task++) {
      if (selectedTaskEntities[task].selected) {
        if (selectedTaskEntities[task].aggregations.length > 0) {
          for (let j = 0; j < selectedTaskEntities[task].aggregations.length; j++) {
            const field = {
              ...taskEntityWithCustomFields[selectedTaskEntities[task].aggregations[j].fieldName],
              displayName: selectedTaskEntities[task].aggregations[j].columnName,
              aggregationToPerform:
                topEntity === 'None' ? 'nothing' : selectedTaskEntities[task].aggregations[j].aggregationType,
              isAggregatable: topEntity === 'None' ? false : true,
              toAggregate: topEntity === 'None' ? false : true,
              conditions: topEntity === 'None' ? [] : selectedTaskEntities[task].aggregations[j].conditions,
            };
            taskFields.push(field);
          }
        } else {
          const field = {
            ...taskEntityWithCustomFields[selectedTaskEntities[task].fieldName],
            displayName: selectedTaskEntities[task].displayName,
            aggregationToPerform: 'nothing',
            isAggregatable: topEntity === 'None' ? false : true,
            toAggregate: topEntity === 'None' ? false : true,
            conditions: [],
          };
          taskFields.push(field);
        }
      }
    }

    const data = {
      project: projectFields,
      client: clientFields,
      usecase: usecaseFields,
      task: taskFields,
    };
    return JSON.stringify(data);
  };

  const saveReport = async () => {
    setIsLoading(true);
    const detail = {
      report_uid: reportId,
      userUid: getLoggedInUserUid(),
      customerUid: getCustomerUid(),
      lastUpdateBy: getLoggedInUserUid(),
      reportName: reportName,
      topEntity: getStandardEntityName(topEntity),
      reportDescription: reportDescription,
      reportConfig: makeReportConfig(),
      isPrivate: isPrivate,
      writeAccess: writeAccess,
      reportType: topEntity === 'None' ? REPORT_TYPE_CUSTOM_REPORT : REPORT_TYPE_AGGREGATED_REPORT,
    };
    const data = await dispatch(updateAggregatedCustomReport(detail)).unwrap();
    if (data.status == 1)
      // navigate(`/reports/custom-report/${data.result.report_uid}`, {
      //   state: {
      //     showCustomReports: 2,
      //   },
      // });
      setIsLoading(false);
    // navigate(`/reports/aggregated-custom-report/${reportId}`);

    props.onClose();
  };

  return (
    <Drawer open={true} onClose={props.onClose} anchor="right">
      <Stack sx={{ margin: '8px 0px' }}>
        <Stack mx={1} mt={1}>
          <Typography variant="h6" sx={{ fontWeight: 500 }}>
            Update Aggregated Custom Report
          </Typography>
        </Stack>

        <Stack mx={1} my={1}>
          <Paper sx={{ padding: '16px 24px 16px 0px' }} elevation={0}>
            <Stack ml={3}>
              <Stack direction={'row'} flexWrap={'wrap'}>
                <Stack my={1} mt={1} mr={3}>
                  <InputLabel htmlFor="component-simple">
                    Report Name*{' '}
                    <Tooltip title="Provide a unique report name." placement="right-start">
                      <IconButton color="primary" aria-label="SMTP">
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </InputLabel>
                  <TextField
                    style={{ width: '310px' }}
                    size="small"
                    placeholder={'Enter Report Name'}
                    required={true}
                    value={reportName}
                    onChange={(e: any) => setReportName(e.target.value)}
                  ></TextField>
                </Stack>

                <Stack my={1} mt={1}>
                  <InputLabel htmlFor="component-simple">
                    Aggregate On{' '}
                    <Tooltip title="Choose on which level this report has to be aggregated." placement="right-start">
                      <IconButton color="primary" aria-label="SMTP">
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </InputLabel>
                  {/* <Typography variant="subtitle1" className="label-text">
                    Top Level Entity
                  </Typography> */}
                  <CognisaasSelect
                    width="310px"
                    id="TopLevelEntity"
                    name="TopLevelEntity"
                    onChange={(e: any) => setTopEntity(e.target.value)}
                    value={topEntity}
                    // onChange={props.}
                    validValues={[...entities]}
                    // onBlur={props.handleBlur}
                    // error={props.touched.industry_name && Boolean(props.errors.industry_name)}
                    // helperText={props.touched.industry_name && props.errors.industry_name}
                  />
                </Stack>
              </Stack>
              <Stack my={1} mt={1} mr={3}>
                <InputLabel htmlFor="component-simple">
                  Report Description{' '}
                  <Tooltip
                    title="Provide a description here which enables others to understand what information this report gives out, in case its shared."
                    placement="right-start"
                  >
                    <IconButton color="primary" aria-label="SMTP">
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </InputLabel>
                <TextareaAutosize
                  onChange={(e) => setReportDescription(e.target.value)}
                  minRows={4}
                  value={reportDescription}
                  aria-label="maximum height"
                  placeholder="Enter description here"
                  defaultValue=""
                  style={{ width: 310, borderRadius: '4px', borderColor: '#c0c0c0' }}
                />
              </Stack>
              <InputLabel sx={{ marginLeft: '8px' }} htmlFor="component-simple">
                Report Entities{' '}
                <Tooltip
                  title="Select all the fields which you want to include in this report."
                  placement="right-start"
                >
                  <IconButton color="primary" aria-label="SMTP">
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </InputLabel>
              <ClientAggregration
                clientData={selectedDataForClient}
                page="UPDATE"
                topEntityIndex={entities.indexOf(topEntity)}
                selectedItemsInClient={(items: any[]) => setSelectedClientEntities([...items])}
                entityFields={clientEntityWithCustomFields}
              />
              <ProjectAggregration
                projectData={selectedDataForProject}
                page="UPDATE"
                topEntityIndex={entities.indexOf(topEntity)}
                selectedItemsInProject={(items: any[]) => setSelectedProjectEntities([...items])}
                entityFields={projectEntityWithCustomFields}
              />
              <UsecaseAggregration
                usecaseData={selectedDataForUsecase}
                page="UPDATE"
                topEntityIndex={entities.indexOf(topEntity)}
                selectedItemsInUsecase={(items: any[]) => setSelectedUsecaseEntities([...items])}
                entityFields={usecaseEntityWithCustomFields}
              />
              <TaskAggregration
                taskData={selectedDataForTask}
                page="UPDATE"
                topEntityIndex={entities.indexOf(topEntity)}
                selectedItemsInTask={(items: any[]) => setSelectedTaskEntities([...items])}
                entityFields={taskEntityWithCustomFields}
              />

              <Stack my={0} mx={1}>
                <InputLabel htmlFor="component-simple">
                  Private Report
                  <Tooltip
                    title="By default custom reports are only visible to you. If you disable this button, custom reports are visible to everyone."
                    placement="right-start"
                  >
                    <IconButton color="primary" aria-label="SMTP">
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </InputLabel>
                <CognisaasSwitchButton
                  handleChange={(e: any) => {
                    setIsPrivate(e.target.checked);
                    if (e.target.checked) {
                      setWriteAccess(false);
                    }
                  }}
                  checked={isPrivate}
                />
              </Stack>
              {!isPrivate && (
                <Stack my={0} mx={1}>
                  <InputLabel htmlFor="component-simple">
                    Allow others to edit report config{' '}
                    <Tooltip
                      title="If you enable this button, then everyone in your organization can edit this report."
                      placement="right-start"
                    >
                      <IconButton color="primary" aria-label="SMTP">
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </InputLabel>
                  <CognisaasSwitchButton
                    handleChange={(e: any) => {
                      setWriteAccess(e.target.checked);
                    }}
                    checked={writeAccess}
                  />
                </Stack>
              )}

              <Stack direction={'row'} flexWrap={'wrap'} sx={{ marginLeft: 'auto' }}>
                <CognisaasButton
                  sx={{ marginRight: '16px' }}
                  isOutlined={true}
                  clickHandler={() =>
                    // navigate('/reports', {
                    //   state: {
                    //     showAggregatedCustomReports: true,
                    //   },
                    // })
                    {
                      props.onClose();
                    }
                  }
                  label={'Cancel'}
                />
                <CognisaasButtonWithLoading
                  isLoading={isLoading}
                  disabled={reportName.length > 0 ? false : true}
                  label={'Update Report'}
                  isOutlined={false}
                  clickHandler={() => {
                    saveReport();
                  }}
                />
              </Stack>
            </Stack>
          </Paper>
        </Stack>
      </Stack>
    </Drawer>
  );
};

export default UpdateAggregatedCustomReports;
