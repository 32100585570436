import { Grid, Typography, Paper, TextField, Button, CircularProgress } from '@mui/material';
import { ReactComponent as BrandLogo } from '../../assets/svgs/BrandLogo.svg';
import * as CryptoJS from 'crypto-js';
import { useEffect, useState } from 'react';
import {
    adminLogin_slice,
    // saveAdminToken_slice
} from '../../store/modules/AdminPanel/slice';
import { 
    adminLogin_selector, 
    // saveAdminToken_selector
} from '../../store/modules/AdminPanel/selector';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';

const AdminLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const adminLogin_fromSelector = useAppSelector(adminLogin_selector);
//   const saveAdminToken_fromSelector = useAppSelector(saveAdminToken_selector);
  const stateFromOtpScreen = useLocation();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showLoader, setShowLoader] = useState(false);

  useEffect(()=>{
      if(stateFromOtpScreen){
          const {state} = stateFromOtpScreen;
          if(state){
              window.location.reload();
          }
      }
  }, []);

  useEffect(()=>{
      if(adminLogin_fromSelector){
          if(adminLogin_fromSelector.status=='success' && adminLogin_fromSelector?.data[0]?.status==0){
            setShowLoader(false);
            alert('Invalid Credentials!!');
          }else if(adminLogin_fromSelector.status=='success'){
              sessionStorage.setItem('admin_jwt', adminLogin_fromSelector.data[0].jwt);
              sessionStorage.setItem('admin_name', email);
            //   navigate('/d4rUvIkI8Hd');
              navigate('/9e43cSe40K');
            //   console.log('adminLogin_fromSelector.data[0].jwt', adminLogin_fromSelector.data[0].jwt);
            //   const payload = {
            //     'jwt_token': adminLogin_fromSelector.data[0].jwt,
            //     'admin_name': email
            //  };
            //   dispatch(saveAdminToken_slice(payload));
          }
      }
  }, [adminLogin_fromSelector]);

  window.onbeforeunload = function() {
      console.log('closing the browser');
      alert('closing');
  };


//   useEffect(()=>{
//     if(saveAdminToken_fromSelector){
//         if(saveAdminToken_fromSelector.status=='success'){
//             // navigate('/9e43cSe40K');
//             navigate('/d4rUvIkI8Hd');
//         }
//     }
// }, [saveAdminToken_fromSelector]);

  const onSubmit = () => {
      setShowLoader(true);
      if(email === '' || password === ''){
          alert('please enter all details');
          return;
      }
      const hash = CryptoJS.SHA3(password, { outputLength: 512 }).toString(CryptoJS.enc.Hex);

      const payload = {
        'user_email': email,
        'user_password': hash
      };
      dispatch(adminLogin_slice(payload));      
  };

    return (
        <Grid container>
            <Grid item xs={9} sx={{ backgroundColor: '#F5F5F7', height: '100vh', paddingLeft: '110px' }}>
                <BrandLogo style={{ width: '326px', height: '60px', marginTop: '40vh', marginLeft: '15vw' }} />
            </Grid>
            <Grid item xs={3} sx={{ backgroundColor: '#3D38BA', margin: '0px', padding: '0px', height: '100vh' }}>
            <Paper
                // elevation={1}
                sx={{
                    position: 'absolute',
                    backgroundColor: '#FFFFFF',
                    width: '410px',
                    height: '55vh',
                    left: '60vw',
                    top: '10vh',
                    padding: '40px 20px 0 20px',
                    borderRadius: '4px',
                    boxShadow: '0px 4px 26px rgba(0, 0, 0, 0.05);',
                    overflowY: 'scroll',
                }}
            >
                <Typography sx={{ fontSize: '20px', fontWeight: '600' }}>Admin Login</Typography>
                <Typography sx={{ marginTop: '10px', fontSize: '12px', fontWeight: '400' }}>User Name</Typography>
                <TextField
                    sx={{ marginTop: '4px' }}
                    fullWidth
                    size="small"
                    placeholder="User Name"
                    variant="outlined"
                    id="user_email"
                    name="user_email"
                    onChange={(e: any) => {setEmail(e.target.value);}}
                />
                <Typography sx={{ marginTop: '24px', fontSize: '12px', fontWeight: '400' }}>Password</Typography>
                <TextField
                    sx={{ marginTop: '4px' }}
                        fullWidth
                        size="small"
                        placeholder="Password"
                        variant="outlined"
                        id="user_password"
                        name="user_password"
                        type="password"
                    onChange={(e: any) => {setPassword(e.target.value);}}
                />
                <Button
                    sx={{
                    backgroundColor: '#2D60F6',
                        borderRadius: '4px',
                        width: '400px',
                        height: '44px',
                        marginTop: '32px',
                    }}
                    variant="contained"
                    type="submit"
                    onClick={onSubmit}
                >
                    {
                        showLoader ? <Grid sx={{ display: 'flex' }}>
                            <CircularProgress color="inherit" size="1.2rem" />
                        </Grid> : <Typography variant='h2'>Login</Typography>
                    }
                </Button>
            </Paper>
            </Grid>
        </Grid>
    );
};

export default AdminLogin;