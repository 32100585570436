import React, { useRef, useEffect } from 'react';
import { GridProps, Grid } from 'components/ResourceManagement/components/grid';
import { CalendarProps, Calendar } from 'components/ResourceManagement/components/calendar';
import { ComponentGanttContentProps, ComponentGanttContent } from './component-gantt-content';
import styles from './gantt.module.css';
import styled from 'styled-components';

export type ComponentGanttProps = {
  gridProps: GridProps;
  calendarProps: CalendarProps;
  barProps: ComponentGanttContentProps;
  ganttHeight: number;
  scrollY: number;
  scrollX: number;
  componentListWidth: number;
  svgWidth: number;
};

const StyledVerticalContainerDiv = styled('div')`
  overflow: auto;
  font-size: ${({ theme }) => theme.fontSizes[0]};
  margin: 0;
  padding: 0;
  background-color: ${({ theme }) => theme.colors.grey_5};
`;

export const ComponentGantt: React.FC<ComponentGanttProps> = ({
  gridProps,
  calendarProps,
  barProps,
  ganttHeight,
  scrollY,
  scrollX,
}) => {
  const ganttSVGRef = useRef<SVGSVGElement>(null);
  const verticalContainerRef = useRef<HTMLDivElement>(null);
  const horizontalGanttContainerRef = useRef<HTMLDivElement>(null);
  const newBarProps = { ...barProps, svg: ganttSVGRef };
  useEffect(() => {
    if (verticalContainerRef.current) {
      verticalContainerRef.current.scrollTop = scrollY;
    }
  }, [scrollY]);

  useEffect(() => {
    if (horizontalGanttContainerRef.current) {
      horizontalGanttContainerRef.current.scrollLeft = scrollX;
    }
  }, [scrollX]);

  return (
    <StyledVerticalContainerDiv ref={horizontalGanttContainerRef} dir="ltr">
      <svg xmlns="http://www.w3.org/2000/svg" width={gridProps.svgWidth} height={calendarProps.headerHeight}>
        <Calendar {...calendarProps} />
      </svg>
      <div
        ref={verticalContainerRef}
        className={styles.horizontalContainer}
        style={{ height: ganttHeight, width: gridProps.svgWidth }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={gridProps.svgWidth}
          height={barProps.rowHeight * barProps.primeComponents.length}
          ref={ganttSVGRef}
        >
          <Grid {...gridProps} />
          <ComponentGanttContent {...newBarProps} />
        </svg>
      </div>
    </StyledVerticalContainerDiv>
  );
};
